const word_list = {
  undecomposed: [
    { word: 'undecomposed', word_class: 'AJ' },
    { word: 'undecomposable', word_class: 'AJ' },
    { word: 'undecomposability', word_class: 'N' }
  ],
  thrilled: [
    { word: 'thrill', word_class: 'N' },
    { word: 'thrill', word_class: 'V' },
    { word: 'thriller', word_class: 'N' },
    { word: 'thrilled', word_class: 'AJ' },
    { word: 'thrillful', word_class: 'AJ' },
    { word: 'thrilling', word_class: 'AJ' }
  ],
  pot: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  travelling: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  genealogic: [
    { word: 'genealogy', word_class: 'N' },
    { word: 'genealogic', word_class: 'AJ' },
    { word: 'genealogical', word_class: 'AJ' },
    { word: 'genealogically', word_class: 'AV' }
  ],
  ruralization: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  trained: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  cope: [
    { word: 'cope', word_class: 'N' },
    { word: 'cope', word_class: 'V' },
    { word: 'copal', word_class: 'N' },
    { word: 'coping', word_class: 'N' }
  ],
  ascertainment: [
    { word: 'ascertain', word_class: 'V' },
    { word: 'ascertained', word_class: 'AJ' },
    { word: 'ascertainment', word_class: 'N' },
    { word: 'ascertainable', word_class: 'AJ' }
  ],
  favouritism: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  quaking: [
    { word: 'quake', word_class: 'N' },
    { word: 'quake', word_class: 'V' },
    { word: 'quaker', word_class: 'N' },
    { word: 'quakers', word_class: 'N' },
    { word: 'quaking', word_class: 'AJ' },
    { word: 'quakerism', word_class: 'N' }
  ],
  munitions: [
    { word: 'munition', word_class: 'N' },
    { word: 'munition', word_class: 'V' },
    { word: 'munitions', word_class: 'N' }
  ],
  extirpation: [
    { word: 'extirpate', word_class: 'V' },
    { word: 'extirpable', word_class: 'AJ' },
    { word: 'extirpation', word_class: 'N' }
  ],
  elusion: [
    { word: 'elude', word_class: 'V' },
    { word: 'eluding', word_class: 'N' },
    { word: 'elusion', word_class: 'N' },
    { word: 'elusive', word_class: 'AJ' },
    { word: 'elusiveness', word_class: 'N' }
  ],
  brazen: [
    { word: 'brazen', word_class: 'V' },
    { word: 'brazen', word_class: 'AJ' },
    { word: 'brazenness', word_class: 'N' }
  ],
  depilous: [
    { word: 'depilate', word_class: 'V' },
    { word: 'depilous', word_class: 'AJ' },
    { word: 'depilation', word_class: 'N' }
  ],
  disentangled: [
    { word: 'disentangle', word_class: 'V' },
    { word: 'disentangled', word_class: 'AJ' },
    { word: 'disentanglement', word_class: 'N' }
  ],
  deny: [{ word: 'deny', word_class: 'V' }, { word: 'denial', word_class: 'N' }, { word: 'denier', word_class: 'N' }],
  brood: [
    { word: 'brood', word_class: 'N' },
    { word: 'brood', word_class: 'V' },
    { word: 'brood', word_class: 'AJ' },
    { word: 'brooder', word_class: 'N' },
    { word: 'brooding', word_class: 'N' },
    { word: 'brooding', word_class: 'AJ' }
  ],
  obsequious: [
    { word: 'obsequy', word_class: 'N' },
    { word: 'obsequious', word_class: 'AJ' },
    { word: 'obsequiously', word_class: 'AV' },
    { word: 'obsequiousness', word_class: 'N' }
  ],
  hatful: [
    { word: 'hat', word_class: 'N' },
    { word: 'hat', word_class: 'V' },
    { word: 'hatful', word_class: 'N' },
    { word: 'hatter', word_class: 'N' },
    { word: 'hatful', word_class: 'AJ' },
    { word: 'hatted', word_class: 'AJ' }
  ],
  metallization: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  blende: [
    { word: 'blend', word_class: 'N' },
    { word: 'blend', word_class: 'V' },
    { word: 'blende', word_class: 'N' },
    { word: 'blender', word_class: 'N' },
    { word: 'blended', word_class: 'AJ' },
    { word: 'blending', word_class: 'N' },
    { word: 'blending', word_class: 'AJ' }
  ],
  supersedure: [
    { word: 'supersede', word_class: 'V' },
    { word: 'supersedure', word_class: 'N' },
    { word: 'supersession', word_class: 'N' }
  ],
  costing: [
    { word: 'cost', word_class: 'N' },
    { word: 'cost', word_class: 'V' },
    { word: 'costs', word_class: 'N' },
    { word: 'costing', word_class: 'N' }
  ],
  utterable: [
    { word: 'utter', word_class: 'V' },
    { word: 'utter', word_class: 'AJ' },
    { word: 'uttered', word_class: 'AJ' },
    { word: 'utterance', word_class: 'N' },
    { word: 'utterable', word_class: 'AJ' }
  ],
  fold: [
    { word: 'fold', word_class: 'N' },
    { word: 'fold', word_class: 'V' },
    { word: 'fold', word_class: 'AJ' },
    { word: 'folder', word_class: 'N' },
    { word: 'folded', word_class: 'AJ' },
    { word: 'folding', word_class: 'N' },
    { word: 'folding', word_class: 'AJ' }
  ],
  affix: [
    { word: 'affix', word_class: 'N' },
    { word: 'affix', word_class: 'V' },
    { word: 'affixal', word_class: 'AJ' },
    { word: 'affixed', word_class: 'AJ' },
    { word: 'affixation', word_class: 'N' }
  ],
  revalue: [
    { word: 'revalue', word_class: 'V' },
    { word: 'revaluate', word_class: 'V' },
    { word: 'revaluation', word_class: 'N' }
  ],
  coronate: [
    { word: 'coronal', word_class: 'N' },
    { word: 'coroner', word_class: 'N' },
    { word: 'coronate', word_class: 'V' },
    { word: 'coronation', word_class: 'N' }
  ],
  inhospitably: [
    { word: 'inhospitable', word_class: 'AJ' },
    { word: 'inhospitably', word_class: 'AV' },
    { word: 'inhospitableness', word_class: 'N' }
  ],
  terminator: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  minutes: [
    { word: 'minute', word_class: 'N' },
    { word: 'minute', word_class: 'V' },
    { word: 'minute', word_class: 'AJ' },
    { word: 'minutes', word_class: 'N' },
    { word: 'minutely', word_class: 'AV' },
    { word: 'minuteman', word_class: 'N' },
    { word: 'minuteness', word_class: 'N' }
  ],
  pealing: [{ word: 'peal', word_class: 'N' }, { word: 'peal', word_class: 'V' }, { word: 'pealing', word_class: 'N' }],
  balloon: [
    { word: 'balloon', word_class: 'N' },
    { word: 'balloon', word_class: 'V' },
    { word: 'ballooning', word_class: 'N' },
    { word: 'balloonist', word_class: 'N' },
    { word: 'ballooning', word_class: 'AJ' }
  ],
  flavourful: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  rubber: [
    { word: 'rubber', word_class: 'N' },
    { word: 'rubber', word_class: 'V' },
    { word: 'rubber', word_class: 'AJ' },
    { word: 'rubberize', word_class: 'V' },
    { word: 'rubberized', word_class: 'AJ' },
    { word: 'rubberization', word_class: 'N' }
  ],
  resplendently: [
    { word: 'resplendence', word_class: 'N' },
    { word: 'resplendency', word_class: 'N' },
    { word: 'resplendent', word_class: 'AJ' },
    { word: 'resplendently', word_class: 'AV' }
  ],
  achromatic: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  last: [
    { word: 'last', word_class: 'N' },
    { word: 'last', word_class: 'V' },
    { word: 'last', word_class: 'AJ' },
    { word: 'last', word_class: 'AV' },
    { word: 'lasting', word_class: 'AJ' }
  ],
  prove: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  chromate: [
    { word: 'chromate', word_class: 'N' },
    { word: 'chromatic', word_class: 'AJ' },
    { word: 'chromatism', word_class: 'N' },
    { word: 'chromaticity', word_class: 'N' },
    { word: 'chromatically', word_class: 'AV' }
  ],
  determinate: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  shoplift: [
    { word: 'shoplift', word_class: 'V' },
    { word: 'shoplifter', word_class: 'N' },
    { word: 'shoplifting', word_class: 'N' }
  ],
  cavernous: [
    { word: 'cavern', word_class: 'N' },
    { word: 'cavern', word_class: 'V' },
    { word: 'cavernous', word_class: 'AJ' }
  ],
  marshal: [
    { word: 'marshal', word_class: 'N' },
    { word: 'marshal', word_class: 'V' },
    { word: 'marshal', word_class: 'AJ' },
    { word: 'marshall', word_class: 'N' },
    { word: 'marshaling', word_class: 'N' },
    { word: 'marshaling', word_class: 'V' },
    { word: 'marshalship', word_class: 'N' }
  ],
  yawning: [
    { word: 'yawn', word_class: 'N' },
    { word: 'yawn', word_class: 'V' },
    { word: 'yawning', word_class: 'AJ' }
  ],
  identifiably: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  sculpture: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  order: [
    { word: 'order', word_class: 'N' },
    { word: 'order', word_class: 'V' },
    { word: 'ordered', word_class: 'AJ' },
    { word: 'ordering', word_class: 'N' }
  ],
  authoritative: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  promptness: [
    { word: 'prompt', word_class: 'N' },
    { word: 'prompt', word_class: 'V' },
    { word: 'prompt', word_class: 'AJ' },
    { word: 'prompter', word_class: 'N' },
    { word: 'prompting', word_class: 'N' },
    { word: 'promptness', word_class: 'N' }
  ],
  amidship: [
    { word: 'amidship', word_class: 'AJ' },
    { word: 'amidship', word_class: 'AV' },
    { word: 'amidships', word_class: 'AV' }
  ],
  durability: [
    { word: 'durable', word_class: 'AJ' },
    { word: 'durables', word_class: 'N' },
    { word: 'durably', word_class: 'AV' },
    { word: 'durability', word_class: 'N' }
  ],
  memorization: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  paleness: [
    { word: 'pale', word_class: 'N' },
    { word: 'pale', word_class: 'V' },
    { word: 'pale', word_class: 'AJ' },
    { word: 'paling', word_class: 'N' },
    { word: 'palely', word_class: 'AV' },
    { word: 'paling', word_class: 'AJ' },
    { word: 'paleness', word_class: 'N' }
  ],
  wrinkled: [
    { word: 'wrinkle', word_class: 'N' },
    { word: 'wrinkle', word_class: 'V' },
    { word: 'wrinkled', word_class: 'AJ' }
  ],
  hatted: [
    { word: 'hat', word_class: 'N' },
    { word: 'hat', word_class: 'V' },
    { word: 'hatful', word_class: 'N' },
    { word: 'hatter', word_class: 'N' },
    { word: 'hatful', word_class: 'AJ' },
    { word: 'hatted', word_class: 'AJ' }
  ],
  shooter: [
    { word: 'shot', word_class: 'N' },
    { word: 'shoot', word_class: 'N' },
    { word: 'shoot', word_class: 'V' },
    { word: 'shot', word_class: 'AJ' },
    { word: 'shooter', word_class: 'N' },
    { word: 'shooting', word_class: 'N' }
  ],
  consent: [
    { word: 'consent', word_class: 'N' },
    { word: 'consent', word_class: 'V' },
    { word: 'consensus', word_class: 'N' },
    { word: 'consensual', word_class: 'AJ' },
    { word: 'consenting', word_class: 'AJ' }
  ],
  salutation: [
    { word: 'salute', word_class: 'N' },
    { word: 'salute', word_class: 'V' },
    { word: 'salutation', word_class: 'N' }
  ],
  penury: [
    { word: 'penury', word_class: 'N' },
    { word: 'penurious', word_class: 'AJ' },
    { word: 'penuriously', word_class: 'AV' },
    { word: 'penuriousness', word_class: 'N' }
  ],
  harmonization: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  processional: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  aspirator: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  gynecological: [
    { word: 'gynecology', word_class: 'N' },
    { word: 'gynecologic', word_class: 'AJ' },
    { word: 'gynecological', word_class: 'AJ' }
  ],
  popularization: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  allurement: [
    { word: 'allure', word_class: 'N' },
    { word: 'allure', word_class: 'V' },
    { word: 'alluring', word_class: 'AJ' },
    { word: 'allurement', word_class: 'N' }
  ],
  mirth: [
    { word: 'mirth', word_class: 'N' },
    { word: 'mirthful', word_class: 'AJ' },
    { word: 'mirthfulness', word_class: 'N' }
  ],
  paddler: [
    { word: 'paddle', word_class: 'N' },
    { word: 'paddle', word_class: 'V' },
    { word: 'paddler', word_class: 'N' }
  ],
  ideate: [
    { word: 'idea', word_class: 'N' },
    { word: 'ideate', word_class: 'V' },
    { word: 'ideation', word_class: 'N' },
    { word: 'ideational', word_class: 'AJ' }
  ],
  barbellate: [
    { word: 'barbel', word_class: 'N' },
    { word: 'barbell', word_class: 'N' },
    { word: 'barbellate', word_class: 'AJ' }
  ],
  auspiciousness: [
    { word: 'auspicious', word_class: 'AJ' },
    { word: 'auspiciously', word_class: 'AV' },
    { word: 'auspiciousness', word_class: 'N' }
  ],
  strive: [
    { word: 'strife', word_class: 'N' },
    { word: 'strive', word_class: 'V' },
    { word: 'striver', word_class: 'N' },
    { word: 'striven', word_class: 'AJ' },
    { word: 'striving', word_class: 'N' }
  ],
  riddance: [
    { word: 'rid', word_class: 'V' },
    { word: 'rid', word_class: 'AJ' },
    { word: 'riddance', word_class: 'N' }
  ],
  contemp: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  astonishment: [
    { word: 'astonish', word_class: 'V' },
    { word: 'astonished', word_class: 'AJ' },
    { word: 'astonishing', word_class: 'AJ' },
    { word: 'astonishment', word_class: 'N' }
  ],
  heartbreaking: [
    { word: 'heartbreak', word_class: 'N' },
    { word: 'heartbreaker', word_class: 'N' },
    { word: 'heartbreaking', word_class: 'AJ' }
  ],
  confrontment: [
    { word: 'confront', word_class: 'V' },
    { word: 'confronting', word_class: 'N' },
    { word: 'confrontment', word_class: 'N' },
    { word: 'confrontation', word_class: 'N' },
    { word: 'confrontational', word_class: 'AJ' }
  ],
  etching: [
    { word: 'etch', word_class: 'N' },
    { word: 'etch', word_class: 'V' },
    { word: 'etcher', word_class: 'N' },
    { word: 'etched', word_class: 'AJ' },
    { word: 'etching', word_class: 'N' }
  ],
  bark: [{ word: 'bark', word_class: 'N' }, { word: 'bark', word_class: 'V' }, { word: 'barker', word_class: 'N' }],
  nasale: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  iterance: [
    { word: 'iterate', word_class: 'V' },
    { word: 'iterance', word_class: 'N' },
    { word: 'iteration', word_class: 'N' },
    { word: 'iterative', word_class: 'AJ' }
  ],
  insubstantially: [
    { word: 'insubstantial', word_class: 'AJ' },
    { word: 'insubstantiality', word_class: 'N' },
    { word: 'insubstantially', word_class: 'AV' }
  ],
  knowledge: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  charterage: [
    { word: 'charter', word_class: 'N' },
    { word: 'charter', word_class: 'V' },
    { word: 'charterage', word_class: 'N' },
    { word: 'chartered', word_class: 'AJ' }
  ],
  ducking: [{ word: 'duck', word_class: 'N' }, { word: 'duck', word_class: 'V' }, { word: 'ducking', word_class: 'N' }],
  disseveration: [
    { word: 'dissever', word_class: 'V' },
    { word: 'disseverance', word_class: 'N' },
    { word: 'disseverment', word_class: 'N' },
    { word: 'disseveration', word_class: 'N' }
  ],
  untruthful: [
    { word: 'untruth', word_class: 'N' },
    { word: 'untruthful', word_class: 'AJ' },
    { word: 'untruthfulness', word_class: 'N' }
  ],
  redeemable: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  economist: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  hat: [
    { word: 'hat', word_class: 'N' },
    { word: 'hat', word_class: 'V' },
    { word: 'hatful', word_class: 'N' },
    { word: 'hatter', word_class: 'N' },
    { word: 'hatful', word_class: 'AJ' },
    { word: 'hatted', word_class: 'AJ' }
  ],
  fragmentation: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  inveterately: [
    { word: 'inveteracy', word_class: 'N' },
    { word: 'inveterate', word_class: 'AJ' },
    { word: 'inveterately', word_class: 'AV' }
  ],
  metalwork: [
    { word: 'metalwork', word_class: 'N' },
    { word: 'metalworks', word_class: 'N' },
    { word: 'metalworker', word_class: 'N' },
    { word: 'metalworking', word_class: 'N' }
  ],
  incidental: [
    { word: 'incidental', word_class: 'N' },
    { word: 'incidental', word_class: 'AJ' },
    { word: 'incidentally', word_class: 'AV' }
  ],
  subordinating: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  honour: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  commentator: [
    { word: 'comment', word_class: 'N' },
    { word: 'comment', word_class: 'V' },
    { word: 'commentary', word_class: 'N' },
    { word: 'commentate', word_class: 'V' },
    { word: 'commentator', word_class: 'N' },
    { word: 'commentation', word_class: 'N' }
  ],
  disorient: [
    { word: 'disorient', word_class: 'V' },
    { word: 'disorientate', word_class: 'V' },
    { word: 'disoriented', word_class: 'AJ' },
    { word: 'disorienting', word_class: 'AJ' },
    { word: 'disorientation', word_class: 'N' }
  ],
  blessed: [
    { word: 'bless', word_class: 'V' },
    { word: 'blessed', word_class: 'AJ' },
    { word: 'blessing', word_class: 'N' }
  ],
  deferment: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  discuss: [
    { word: 'discuss', word_class: 'V' },
    { word: 'discussant', word_class: 'N' },
    { word: 'discussion', word_class: 'N' }
  ],
  cheerful: [
    { word: 'cheer', word_class: 'N' },
    { word: 'cheer', word_class: 'V' },
    { word: 'cheering', word_class: 'N' },
    { word: 'cheerful', word_class: 'AJ' },
    { word: 'cheering', word_class: 'AJ' },
    { word: 'cheerfulness', word_class: 'N' }
  ],
  locale: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  depersonalization: [
    { word: 'depersonalise', word_class: 'V' },
    { word: 'depersonalize', word_class: 'V' },
    { word: 'depersonalisation', word_class: 'N' },
    { word: 'depersonalization', word_class: 'N' }
  ],
  popularism: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  hierarchically: [
    { word: 'hierarch', word_class: 'N' },
    { word: 'hierarchal', word_class: 'AJ' },
    { word: 'hierarchic', word_class: 'AJ' },
    { word: 'hierarchical', word_class: 'AJ' },
    { word: 'hierarchically', word_class: 'AV' }
  ],
  house: [
    { word: 'house', word_class: 'N' },
    { word: 'house', word_class: 'V' },
    { word: 'housing', word_class: 'N' },
    { word: 'houseful', word_class: 'N' },
    { word: 'houseman', word_class: 'N' },
    { word: 'housings', word_class: 'N' },
    { word: 'houseful', word_class: 'AJ' }
  ],
  papers: [
    { word: 'paper', word_class: 'N' },
    { word: 'paper', word_class: 'V' },
    { word: 'papers', word_class: 'N' },
    { word: 'paperer', word_class: 'N' },
    { word: 'papering', word_class: 'N' }
  ],
  anticipative: [
    { word: 'anticipate', word_class: 'V' },
    { word: 'anticipant', word_class: 'AJ' },
    { word: 'anticipated', word_class: 'AJ' },
    { word: 'anticipation', word_class: 'N' },
    { word: 'anticipative', word_class: 'AJ' }
  ],
  populate: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  seepage: [
    { word: 'seep', word_class: 'V' },
    { word: 'seepage', word_class: 'N' },
    { word: 'seeping', word_class: 'AJ' }
  ],
  imposition: [
    { word: 'impose', word_class: 'N' },
    { word: 'impose', word_class: 'V' },
    { word: 'imposed', word_class: 'AJ' },
    { word: 'imposing', word_class: 'AJ' },
    { word: 'imposition', word_class: 'N' }
  ],
  cushioned: [
    { word: 'cushion', word_class: 'N' },
    { word: 'cushion', word_class: 'V' },
    { word: 'cushioned', word_class: 'AJ' },
    { word: 'cushioning', word_class: 'N' }
  ],
  corresponding: [
    { word: 'correspond', word_class: 'V' },
    { word: 'correspondent', word_class: 'N' },
    { word: 'correspondence', word_class: 'N' },
    { word: 'correspondent', word_class: 'AJ' },
    { word: 'corresponding', word_class: 'AJ' }
  ],
  belligerence: [
    { word: 'belligerent', word_class: 'N' },
    { word: 'belligerence', word_class: 'N' },
    { word: 'belligerency', word_class: 'N' },
    { word: 'belligerent', word_class: 'AJ' },
    { word: 'belligerently', word_class: 'AV' }
  ],
  acceptability: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  repetitiousness: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  mates: [
    { word: 'mate', word_class: 'V' },
    { word: 'mate', word_class: 'N' },
    { word: 'mater', word_class: 'N' },
    { word: 'mates', word_class: 'N' },
    { word: 'mated', word_class: 'AJ' },
    { word: 'mating', word_class: 'N' }
  ],
  mechanisation: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  audible: [
    { word: 'audible', word_class: 'AJ' },
    { word: 'audibly', word_class: 'AV' },
    { word: 'audibility', word_class: 'N' },
    { word: 'audibleness', word_class: 'N' }
  ],
  bilges: [{ word: 'bilge', word_class: 'N' }, { word: 'bilge', word_class: 'V' }, { word: 'bilges', word_class: 'N' }],
  declaim: [
    { word: 'declaim', word_class: 'N' },
    { word: 'declaim', word_class: 'V' },
    { word: 'declamation', word_class: 'N' },
    { word: 'declamatory', word_class: 'AJ' }
  ],
  scribble: [
    { word: 'scribble', word_class: 'N' },
    { word: 'scribble', word_class: 'V' },
    { word: 'scribbler', word_class: 'N' }
  ],
  excellency: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  forejudge: [
    { word: 'forjudge', word_class: 'V' },
    { word: 'forejudge', word_class: 'V' },
    { word: 'forjudgment', word_class: 'N' },
    { word: 'forejudgment', word_class: 'N' },
    { word: 'forejudgement', word_class: 'N' }
  ],
  boon: [{ word: 'boon', word_class: 'N' }, { word: 'boon', word_class: 'AJ' }, { word: 'boone', word_class: 'N' }],
  celibate: [
    { word: 'celibacy', word_class: 'N' },
    { word: 'celibate', word_class: 'N' },
    { word: 'celibate', word_class: 'AJ' }
  ],
  predicator: [
    { word: 'predicate', word_class: 'N' },
    { word: 'predicate', word_class: 'V' },
    { word: 'predicate', word_class: 'AJ' },
    { word: 'predicator', word_class: 'N' },
    { word: 'predication', word_class: 'N' },
    { word: 'predicative', word_class: 'AJ' },
    { word: 'predicatively', word_class: 'AV' }
  ],
  gyp: [{ word: 'gyp', word_class: 'N' }, { word: 'gyp', word_class: 'V' }, { word: 'gyps', word_class: 'N' }],
  predictability: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  papering: [
    { word: 'paper', word_class: 'N' },
    { word: 'paper', word_class: 'V' },
    { word: 'papers', word_class: 'N' },
    { word: 'paperer', word_class: 'N' },
    { word: 'papering', word_class: 'N' }
  ],
  housekeep: [
    { word: 'housekeep', word_class: 'V' },
    { word: 'housekeeper', word_class: 'N' },
    { word: 'housekeeping', word_class: 'N' }
  ],
  romp: [{ word: 'romp', word_class: 'N' }, { word: 'romp', word_class: 'V' }, { word: 'romper', word_class: 'N' }],
  marvelously: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  incase: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  disarray: [
    { word: 'disarray', word_class: 'N' },
    { word: 'disarray', word_class: 'V' },
    { word: 'disarrayed', word_class: 'AJ' }
  ],
  intellectualise: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  reckoner: [
    { word: 'reckon', word_class: 'V' },
    { word: 'reckoner', word_class: 'N' },
    { word: 'reckoning', word_class: 'N' }
  ],
  lightship: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  constricted: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  truncation: [
    { word: 'truncate', word_class: 'V' },
    { word: 'truncate', word_class: 'AJ' },
    { word: 'truncated', word_class: 'AJ' },
    { word: 'truncation', word_class: 'N' }
  ],
  proximity: [
    { word: 'proximal', word_class: 'AJ' },
    { word: 'proximity', word_class: 'N' },
    { word: 'proximate', word_class: 'AJ' }
  ],
  contradictoriness: [
    { word: 'contradictory', word_class: 'N' },
    { word: 'contradictory', word_class: 'AJ' },
    { word: 'contradictoriness', word_class: 'N' }
  ],
  gluttonize: [
    { word: 'glutton', word_class: 'N' },
    { word: 'gluttonize', word_class: 'V' },
    { word: 'gluttonous', word_class: 'AJ' },
    { word: 'gluttonously', word_class: 'AV' }
  ],
  mutilate: [
    { word: 'mutilate', word_class: 'V' },
    { word: 'mutilated', word_class: 'AJ' },
    { word: 'mutilation', word_class: 'N' }
  ],
  tempered: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  continually: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  motor: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  clarify: [
    { word: 'clarify', word_class: 'V' },
    { word: 'clarified', word_class: 'AJ' },
    { word: 'clarifying', word_class: 'AJ' },
    { word: 'clarification', word_class: 'N' }
  ],
  hermaphroditism: [
    { word: 'hermaphrodite', word_class: 'N' },
    { word: 'hermaphrodite', word_class: 'AJ' },
    { word: 'hermaphroditic', word_class: 'AJ' },
    { word: 'hermaphroditism', word_class: 'N' }
  ],
  punctured: [
    { word: 'puncture', word_class: 'N' },
    { word: 'puncture', word_class: 'V' },
    { word: 'punctured', word_class: 'AJ' },
    { word: 'puncturing', word_class: 'N' },
    { word: 'puncturable', word_class: 'AJ' }
  ],
  adjustive: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  hybridisation: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  blather: [
    { word: 'blather', word_class: 'N' },
    { word: 'blather', word_class: 'V' },
    { word: 'blathering', word_class: 'AJ' }
  ],
  mending: [
    { word: 'mend', word_class: 'N' },
    { word: 'mend', word_class: 'V' },
    { word: 'mender', word_class: 'N' },
    { word: 'mending', word_class: 'N' }
  ],
  party: [{ word: 'party', word_class: 'N' }, { word: 'party', word_class: 'V' }, { word: 'party', word_class: 'AJ' }],
  symbolization: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  rubification: [
    { word: 'rubefy', word_class: 'V' },
    { word: 'rubifcation', word_class: 'N' },
    { word: 'rubification', word_class: 'N' }
  ],
  defilement: [
    { word: 'defile', word_class: 'N' },
    { word: 'defile', word_class: 'V' },
    { word: 'defiled', word_class: 'AJ' },
    { word: 'defilement', word_class: 'N' }
  ],
  suppression: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  icy: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  balled: [{ word: 'ball', word_class: 'N' }, { word: 'ball', word_class: 'V' }, { word: 'balled', word_class: 'AJ' }],
  decrepitation: [
    { word: 'decrepit', word_class: 'AJ' },
    { word: 'decrepitate', word_class: 'V' },
    { word: 'decrepitation', word_class: 'N' }
  ],
  barebacked: [
    { word: 'bareback', word_class: 'AJ' },
    { word: 'bareback', word_class: 'AV' },
    { word: 'barebacked', word_class: 'AJ' },
    { word: 'barebacked', word_class: 'AV' }
  ],
  cohesiveness: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  'place-kicking': [
    { word: 'place-kick', word_class: 'V' },
    { word: 'place-kicker', word_class: 'N' },
    { word: 'place-kicking', word_class: 'N' }
  ],
  terminative: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  brag: [
    { word: 'brag', word_class: 'N' },
    { word: 'brag', word_class: 'V' },
    { word: 'brag', word_class: 'AJ' },
    { word: 'bragging', word_class: 'N' },
    { word: 'bragging', word_class: 'AJ' },
    { word: 'braggadocio', word_class: 'N' }
  ],
  pharmacology: [
    { word: 'pharmacology', word_class: 'N' },
    { word: 'pharmacologic', word_class: 'AJ' },
    { word: 'pharmacological', word_class: 'AJ' },
    { word: 'pharmacologically', word_class: 'AV' }
  ],
  posteriority: [
    { word: 'posterior', word_class: 'N' },
    { word: 'posterior', word_class: 'AJ' },
    { word: 'posteriority', word_class: 'N' }
  ],
  alienating: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  propose: [
    { word: 'propose', word_class: 'N' },
    { word: 'propose', word_class: 'V' },
    { word: 'proposal', word_class: 'N' },
    { word: 'proposer', word_class: 'N' },
    { word: 'proposed', word_class: 'AJ' }
  ],
  delectation: [
    { word: 'delectate', word_class: 'AJ' },
    { word: 'delectable', word_class: 'AJ' },
    { word: 'delectation', word_class: 'N' },
    { word: 'delectability', word_class: 'N' }
  ],
  abolishment: [
    { word: 'abolish', word_class: 'V' },
    { word: 'abolition', word_class: 'N' },
    { word: 'abolishment', word_class: 'N' },
    { word: 'abolishable', word_class: 'AJ' }
  ],
  resemble: [
    { word: 'resemble', word_class: 'V' },
    { word: 'resemblant', word_class: 'N' },
    { word: 'resemblance', word_class: 'N' },
    { word: 'resemblant', word_class: 'AJ' }
  ],
  argumentation: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  conversancy: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  occidental: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  interlaced: [
    { word: 'interlace', word_class: 'V' },
    { word: 'interlaced', word_class: 'AJ' },
    { word: 'interlacing', word_class: 'AJ' },
    { word: 'interlacement', word_class: 'N' }
  ],
  speed: [
    { word: 'speed', word_class: 'N' },
    { word: 'speed', word_class: 'V' },
    { word: 'speeder', word_class: 'N' },
    { word: 'speeding', word_class: 'N' },
    { word: 'speeding', word_class: 'AJ' }
  ],
  masochistic: [
    { word: 'masochist', word_class: 'N' },
    { word: 'masochistic', word_class: 'AJ' },
    { word: 'masochistically', word_class: 'AV' }
  ],
  pricking: [
    { word: 'prick', word_class: 'N' },
    { word: 'prick', word_class: 'V' },
    { word: 'pricker', word_class: 'N' },
    { word: 'pricking', word_class: 'N' }
  ],
  spiritize: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  correlated: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  convene: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  excruciation: [
    { word: 'excruciate', word_class: 'V' },
    { word: 'excruciation', word_class: 'N' },
    { word: 'excruciating', word_class: 'AJ' }
  ],
  skeptically: [
    { word: 'skeptic', word_class: 'N' },
    { word: 'skeptical', word_class: 'AJ' },
    { word: 'skepticism', word_class: 'N' },
    { word: 'skeptically', word_class: 'AV' }
  ],
  disingenuous: [
    { word: 'disingenuous', word_class: 'AJ' },
    { word: 'disingenuously', word_class: 'AV' },
    { word: 'disingenuousness', word_class: 'N' }
  ],
  marinate: [
    { word: 'marine', word_class: 'N' },
    { word: 'marine', word_class: 'AJ' },
    { word: 'mariner', word_class: 'N' },
    { word: 'marinade', word_class: 'N' },
    { word: 'marinade', word_class: 'V' },
    { word: 'marinate', word_class: 'V' },
    { word: 'marination', word_class: 'N' }
  ],
  treat: [
    { word: 'treat', word_class: 'N' },
    { word: 'treat', word_class: 'V' },
    { word: 'treated', word_class: 'AJ' },
    { word: 'treatment', word_class: 'N' }
  ],
  concession: [
    { word: 'concede', word_class: 'V' },
    { word: 'conceded', word_class: 'AJ' },
    { word: 'conceding', word_class: 'N' },
    { word: 'conceding', word_class: 'AJ' },
    { word: 'concession', word_class: 'N' },
    { word: 'concessive', word_class: 'AJ' }
  ],
  watering: [
    { word: 'water', word_class: 'N' },
    { word: 'water', word_class: 'V' },
    { word: 'waters', word_class: 'N' },
    { word: 'watered', word_class: 'AJ' },
    { word: 'watering', word_class: 'N' },
    { word: 'waterman', word_class: 'N' }
  ],
  basically: [
    { word: 'basic', word_class: 'N' },
    { word: 'basic', word_class: 'AJ' },
    { word: 'basics', word_class: 'N' },
    { word: 'basically', word_class: 'AV' }
  ],
  breeches: [
    { word: 'breech', word_class: 'N' },
    { word: 'breeches', word_class: 'N' },
    { word: 'breeched', word_class: 'AJ' }
  ],
  coiner: [
    { word: 'coin', word_class: 'N' },
    { word: 'coin', word_class: 'V' },
    { word: 'coiner', word_class: 'N' },
    { word: 'coinage', word_class: 'N' }
  ],
  sensory: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  abstinence: [
    { word: 'abstain', word_class: 'V' },
    { word: 'abstainer', word_class: 'N' },
    { word: 'abstinent', word_class: 'N' },
    { word: 'abstention', word_class: 'N' },
    { word: 'abstinence', word_class: 'N' },
    { word: 'abstinent', word_class: 'AJ' }
  ],
  delegating: [
    { word: 'delegate', word_class: 'N' },
    { word: 'delegate', word_class: 'V' },
    { word: 'delegable', word_class: 'AJ' },
    { word: 'delegating', word_class: 'N' },
    { word: 'delegation', word_class: 'N' }
  ],
  emasculate: [
    { word: 'emasculate', word_class: 'V' },
    { word: 'emasculate', word_class: 'AJ' },
    { word: 'emasculated', word_class: 'AJ' },
    { word: 'emasculation', word_class: 'N' }
  ],
  mooring: [{ word: 'moor', word_class: 'N' }, { word: 'moor', word_class: 'V' }, { word: 'mooring', word_class: 'N' }],
  tourer: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  solicitous: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  anesthetization: [
    { word: 'anesthetic', word_class: 'N' },
    { word: 'anesthetic', word_class: 'AJ' },
    { word: 'anesthetize', word_class: 'V' },
    { word: 'anesthetized', word_class: 'AJ' },
    { word: 'anesthetization', word_class: 'N' }
  ],
  emaciate: [
    { word: 'emaciate', word_class: 'V' },
    { word: 'emaciated', word_class: 'AJ' },
    { word: 'emaciation', word_class: 'N' }
  ],
  procession: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  brindled: [
    { word: 'brindle', word_class: 'N' },
    { word: 'brindle', word_class: 'AJ' },
    { word: 'brindled', word_class: 'AJ' }
  ],
  concurrent: [
    { word: 'concur', word_class: 'V' },
    { word: 'concurrence', word_class: 'N' },
    { word: 'concurrent', word_class: 'AJ' },
    { word: 'concurring', word_class: 'AJ' },
    { word: 'concurrently', word_class: 'AV' }
  ],
  emphasized: [
    { word: 'emphasis', word_class: 'N' },
    { word: 'emphasize', word_class: 'V' },
    { word: 'emphasized', word_class: 'AJ' },
    { word: 'emphasizing', word_class: 'N' }
  ],
  gelatinize: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  novelization: [
    { word: 'novel', word_class: 'N' },
    { word: 'novel', word_class: 'AJ' },
    { word: 'novelty', word_class: 'N' },
    { word: 'novelize', word_class: 'V' },
    { word: 'novelties', word_class: 'N' },
    { word: 'novelization', word_class: 'N' }
  ],
  indeterminable: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  immortalization: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  inactivity: [
    { word: 'inaction', word_class: 'N' },
    { word: 'inactive', word_class: 'AJ' },
    { word: 'inactivity', word_class: 'N' },
    { word: 'inactiveness', word_class: 'N' }
  ],
  emulous: [
    { word: 'emulate', word_class: 'V' },
    { word: 'emulous', word_class: 'AJ' },
    { word: 'emulation', word_class: 'N' },
    { word: 'emulously', word_class: 'AV' }
  ],
  transitional: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  filmmaking: [
    { word: 'film', word_class: 'N' },
    { word: 'film', word_class: 'V' },
    { word: 'filmed', word_class: 'AJ' },
    { word: 'filming', word_class: 'N' },
    { word: 'filmmaker', word_class: 'N' },
    { word: 'filmmaking', word_class: 'N' }
  ],
  surveying: [
    { word: 'survey', word_class: 'N' },
    { word: 'survey', word_class: 'V' },
    { word: 'surveyor', word_class: 'N' },
    { word: 'surveying', word_class: 'N' }
  ],
  negativeness: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  hand: [
    { word: 'hand', word_class: 'N' },
    { word: 'hand', word_class: 'V' },
    { word: 'hands', word_class: 'N' },
    { word: 'handed', word_class: 'AJ' },
    { word: 'handful', word_class: 'N' },
    { word: 'handful', word_class: 'AJ' }
  ],
  resolvent: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  maturational: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  venereal: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  fuck: [
    { word: 'fuck', word_class: 'N' },
    { word: 'fuck', word_class: 'V' },
    { word: 'fucker', word_class: 'N' },
    { word: 'fucking', word_class: 'N' },
    { word: 'fucking', word_class: 'AJ' },
    { word: 'fucking', word_class: 'AV' }
  ],
  portray: [
    { word: 'portray', word_class: 'V' },
    { word: 'portrayal', word_class: 'N' },
    { word: 'portrayed', word_class: 'AJ' },
    { word: 'portraying', word_class: 'N' }
  ],
  theater: [
    { word: 'theater', word_class: 'N' },
    { word: 'theatric', word_class: 'AJ' },
    { word: 'theatrical', word_class: 'N' },
    { word: 'theatrical', word_class: 'AJ' },
    { word: 'theatricality', word_class: 'N' },
    { word: 'theatrically', word_class: 'AV' }
  ],
  acerbate: [
    { word: 'acerb', word_class: 'AJ' },
    { word: 'acerbate', word_class: 'V' },
    { word: 'acerbic', word_class: 'AJ' },
    { word: 'acerbity', word_class: 'N' },
    { word: 'acerbation', word_class: 'N' }
  ],
  misogynic: [
    { word: 'misogynic', word_class: 'AJ' },
    { word: 'misogynism', word_class: 'N' },
    { word: 'misogynous', word_class: 'AJ' }
  ],
  categorization: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  resuscitation: [
    { word: 'resuscitate', word_class: 'V' },
    { word: 'resuscitated', word_class: 'AJ' },
    { word: 'resuscitation', word_class: 'N' }
  ],
  accuser: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  resonating: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  humbug: [
    { word: 'humbug', word_class: 'N' },
    { word: 'humbug', word_class: 'V' },
    { word: 'humbuggery', word_class: 'N' }
  ],
  enameled: [
    { word: 'enamel', word_class: 'N' },
    { word: 'enamel', word_class: 'V' },
    { word: 'enameled', word_class: 'AJ' }
  ],
  slapped: [
    { word: 'slap', word_class: 'N' },
    { word: 'slap', word_class: 'V' },
    { word: 'slap', word_class: 'AV' },
    { word: 'slapped', word_class: 'AJ' }
  ],
  flirtation: [
    { word: 'flirt', word_class: 'N' },
    { word: 'flirt', word_class: 'V' },
    { word: 'flirting', word_class: 'N' },
    { word: 'flirtation', word_class: 'N' },
    { word: 'flirtatious', word_class: 'AJ' },
    { word: 'flirtatiously', word_class: 'AV' }
  ],
  boxing: [
    { word: 'box', word_class: 'N' },
    { word: 'box', word_class: 'V' },
    { word: 'boxer', word_class: 'N' },
    { word: 'boxed', word_class: 'AJ' },
    { word: 'boxful', word_class: 'N' },
    { word: 'boxing', word_class: 'N' },
    { word: 'boxful', word_class: 'AJ' }
  ],
  narcotic: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  informality: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  investigate: [
    { word: 'investigate', word_class: 'V' },
    { word: 'investigator', word_class: 'N' },
    { word: 'investigating', word_class: 'N' },
    { word: 'investigation', word_class: 'N' },
    { word: 'investigative', word_class: 'AJ' }
  ],
  adoptable: [
    { word: 'adopt', word_class: 'V' },
    { word: 'adopted', word_class: 'AJ' },
    { word: 'adoption', word_class: 'N' },
    { word: 'adoptive', word_class: 'AJ' },
    { word: 'adoptable', word_class: 'AJ' }
  ],
  assortment: [
    { word: 'assort', word_class: 'V' },
    { word: 'assorted', word_class: 'AJ' },
    { word: 'assortment', word_class: 'N' }
  ],
  rabbinic: [
    { word: 'rabbi', word_class: 'N' },
    { word: 'rabbinate', word_class: 'N' },
    { word: 'rabbinic', word_class: 'AJ' },
    { word: 'rabbinical', word_class: 'AJ' }
  ],
  nag: [{ word: 'nag', word_class: 'N' }, { word: 'nag', word_class: 'V' }, { word: 'nagging', word_class: 'AJ' }],
  'self-seeking': [
    { word: 'self-seeker', word_class: 'N' },
    { word: 'self-seeking', word_class: 'N' },
    { word: 'self-seeking', word_class: 'AJ' }
  ],
  suppressor: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  imperfective: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  hieroglyph: [
    { word: 'hieroglyph', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'AJ' },
    { word: 'hieroglyphical', word_class: 'AJ' },
    { word: 'hieroglyphically', word_class: 'AV' }
  ],
  unsuited: [
    { word: 'unsuited', word_class: 'AJ' },
    { word: 'unsuitable', word_class: 'AJ' },
    { word: 'unsuitably', word_class: 'AV' },
    { word: 'unsuitability', word_class: 'N' },
    { word: 'unsuitableness', word_class: 'N' }
  ],
  'self-assertive': [
    { word: 'self-assertion', word_class: 'N' },
    { word: 'self-asserting', word_class: 'AJ' },
    { word: 'self-assertive', word_class: 'AJ' },
    { word: 'self-assertiveness', word_class: 'N' }
  ],
  braise: [
    { word: 'braise', word_class: 'V' },
    { word: 'braised', word_class: 'AJ' },
    { word: 'braising', word_class: 'N' }
  ],
  vegetate: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  typist: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  overseas: [
    { word: 'oversea', word_class: 'AJ' },
    { word: 'oversea', word_class: 'AV' },
    { word: 'overseas', word_class: 'AJ' },
    { word: 'overseas', word_class: 'AV' }
  ],
  winsomely: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  eulogist: [
    { word: 'eulogy', word_class: 'N' },
    { word: 'eulogist', word_class: 'N' },
    { word: 'eulogize', word_class: 'V' },
    { word: 'eulogistic', word_class: 'AJ' },
    { word: 'eulogization', word_class: 'N' }
  ],
  dispensing: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  key: [
    { word: 'key', word_class: 'N' },
    { word: 'key', word_class: 'V' },
    { word: 'key', word_class: 'AJ' },
    { word: 'keyed', word_class: 'AJ' }
  ],
  skeletonize: [
    { word: 'skeleton', word_class: 'N' },
    { word: 'skeletal', word_class: 'AJ' },
    { word: 'skeletonise', word_class: 'V' },
    { word: 'skeletonize', word_class: 'V' },
    { word: 'skeletonization', word_class: 'N' }
  ],
  disjoint: [
    { word: 'disjoint', word_class: 'N' },
    { word: 'disjoint', word_class: 'V' },
    { word: 'disjoint', word_class: 'AJ' },
    { word: 'disjointed', word_class: 'AJ' }
  ],
  aligning: [
    { word: 'align', word_class: 'V' },
    { word: 'aligned', word_class: 'AJ' },
    { word: 'aligning', word_class: 'AJ' },
    { word: 'alignment', word_class: 'N' }
  ],
  speculation: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  dashed: [
    { word: 'dash', word_class: 'N' },
    { word: 'dash', word_class: 'V' },
    { word: 'dashed', word_class: 'AJ' },
    { word: 'dashing', word_class: 'AJ' }
  ],
  done: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  perturbate: [
    { word: 'perturb', word_class: 'V' },
    { word: 'perturbate', word_class: 'V' },
    { word: 'perturbed', word_class: 'AJ' },
    { word: 'perturbing', word_class: 'AJ' },
    { word: 'perturbation', word_class: 'N' }
  ],
  unproductiveness: [
    { word: 'unproductive', word_class: 'AJ' },
    { word: 'unproductively', word_class: 'AV' },
    { word: 'unproductiveness', word_class: 'N' }
  ],
  perseverance: [
    { word: 'persevere', word_class: 'V' },
    { word: 'perseverance', word_class: 'N' },
    { word: 'perseverence', word_class: 'N' },
    { word: 'perseveration', word_class: 'N' }
  ],
  smallness: [
    { word: 'small', word_class: 'N' },
    { word: 'small', word_class: 'AJ' },
    { word: 'small', word_class: 'AV' },
    { word: 'smallness', word_class: 'N' }
  ],
  import: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  biting: [
    { word: 'bite', word_class: 'N' },
    { word: 'bite', word_class: 'V' },
    { word: 'biter', word_class: 'N' },
    { word: 'biting', word_class: 'AJ' },
    { word: 'bitten', word_class: 'AJ' }
  ],
  crenel: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  careerist: [
    { word: 'career', word_class: 'N' },
    { word: 'career', word_class: 'V' },
    { word: 'careerism', word_class: 'N' },
    { word: 'careerist', word_class: 'N' }
  ],
  naturalisation: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  guttural: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  hibernal: [
    { word: 'hibernal', word_class: 'AJ' },
    { word: 'hibernate', word_class: 'V' },
    { word: 'hibernation', word_class: 'N' },
    { word: 'hibernating', word_class: 'AJ' }
  ],
  patness: [
    { word: 'pat', word_class: 'N' },
    { word: 'pat', word_class: 'V' },
    { word: 'pat', word_class: 'AJ' },
    { word: 'pat', word_class: 'AV' },
    { word: 'pater', word_class: 'N' },
    { word: 'patness', word_class: 'N' }
  ],
  unfavorableness: [
    { word: 'unfavorable', word_class: 'AJ' },
    { word: 'unfavorably', word_class: 'AV' },
    { word: 'unfavorableness', word_class: 'N' }
  ],
  hospitableness: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  chromatographic: [
    { word: 'chromatographic', word_class: 'AJ' },
    { word: 'chromatographical', word_class: 'AJ' },
    { word: 'chromatographically', word_class: 'AV' }
  ],
  dislocate: [
    { word: 'dislocate', word_class: 'V' },
    { word: 'dislocated', word_class: 'AJ' },
    { word: 'dislocation', word_class: 'N' }
  ],
  sonorant: [
    { word: 'sonorant', word_class: 'N' },
    { word: 'sonority', word_class: 'N' },
    { word: 'sonorant', word_class: 'AJ' },
    { word: 'sonorous', word_class: 'AJ' },
    { word: 'sonorously', word_class: 'AV' },
    { word: 'sonorousness', word_class: 'N' }
  ],
  unattainably: [
    { word: 'unattainable', word_class: 'AJ' },
    { word: 'unattainably', word_class: 'AV' },
    { word: 'unattainableness', word_class: 'N' }
  ],
  connect: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  accomplishable: [
    { word: 'accomplish', word_class: 'V' },
    { word: 'accomplished', word_class: 'AJ' },
    { word: 'accomplishment', word_class: 'N' },
    { word: 'accomplishable', word_class: 'AJ' }
  ],
  probabilist: [
    { word: 'probabilist', word_class: 'N' },
    { word: 'probabilistic', word_class: 'AJ' },
    { word: 'probabilistically', word_class: 'AV' }
  ],
  gangrene: [
    { word: 'gangrene', word_class: 'N' },
    { word: 'gangrene', word_class: 'V' },
    { word: 'gangrenous', word_class: 'AJ' }
  ],
  fluting: [
    { word: 'flute', word_class: 'N' },
    { word: 'flute', word_class: 'V' },
    { word: 'fluting', word_class: 'N' },
    { word: 'flutist', word_class: 'N' },
    { word: 'flautist', word_class: 'N' }
  ],
  grilled: [
    { word: 'grill', word_class: 'N' },
    { word: 'grill', word_class: 'V' },
    { word: 'grille', word_class: 'N' },
    { word: 'grilled', word_class: 'AJ' },
    { word: 'grilling', word_class: 'N' }
  ],
  salience: [
    { word: 'salient', word_class: 'N' },
    { word: 'salience', word_class: 'N' },
    { word: 'saliency', word_class: 'N' },
    { word: 'salient', word_class: 'AJ' }
  ],
  enough: [
    { word: 'enough', word_class: 'N' },
    { word: 'enough', word_class: 'AJ' },
    { word: 'enough', word_class: 'AV' }
  ],
  shambling: [
    { word: 'shamble', word_class: 'N' },
    { word: 'shamble', word_class: 'V' },
    { word: 'shambles', word_class: 'N' },
    { word: 'shambling', word_class: 'N' }
  ],
  gustative: [
    { word: 'gust', word_class: 'N' },
    { word: 'gust', word_class: 'V' },
    { word: 'gustative', word_class: 'AJ' }
  ],
  nomination: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  frame: [
    { word: 'frame', word_class: 'N' },
    { word: 'frame', word_class: 'V' },
    { word: 'framer', word_class: 'N' },
    { word: 'frames', word_class: 'V' },
    { word: 'framed', word_class: 'AJ' },
    { word: 'framing', word_class: 'N' }
  ],
  antinomianism: [
    { word: 'antinomian', word_class: 'N' },
    { word: 'antinomian', word_class: 'AJ' },
    { word: 'antinomianism', word_class: 'N' }
  ],
  shorts: [
    { word: 'short', word_class: 'N' },
    { word: 'short', word_class: 'V' },
    { word: 'short', word_class: 'AJ' },
    { word: 'short', word_class: 'AV' },
    { word: 'shorts', word_class: 'N' },
    { word: 'shortage', word_class: 'N' },
    { word: 'shortness', word_class: 'N' }
  ],
  angelical: [
    { word: 'angel', word_class: 'N' },
    { word: 'angelic', word_class: 'AJ' },
    { word: 'angelical', word_class: 'AJ' },
    { word: 'angelically', word_class: 'AV' }
  ],
  impressure: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  regulated: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  demurral: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  institutionalize: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  exposit: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  disinclined: [
    { word: 'disincline', word_class: 'V' },
    { word: 'disinclined', word_class: 'AJ' },
    { word: 'disinclination', word_class: 'N' }
  ],
  assault: [
    { word: 'assault', word_class: 'N' },
    { word: 'assault', word_class: 'V' },
    { word: 'assault', word_class: 'AJ' },
    { word: 'assaulted', word_class: 'AJ' },
    { word: 'assaultive', word_class: 'AJ' }
  ],
  mime: [{ word: 'mime', word_class: 'N' }, { word: 'mime', word_class: 'V' }, { word: 'mimer', word_class: 'N' }],
  peddle: [
    { word: 'peddle', word_class: 'V' },
    { word: 'peddler', word_class: 'N' },
    { word: 'peddling', word_class: 'N' }
  ],
  product: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  deviate: [
    { word: 'deviant', word_class: 'N' },
    { word: 'deviate', word_class: 'N' },
    { word: 'deviate', word_class: 'V' },
    { word: 'deviance', word_class: 'N' },
    { word: 'deviant', word_class: 'AJ' },
    { word: 'deviancy', word_class: 'N' },
    { word: 'deviation', word_class: 'N' }
  ],
  enabling: [
    { word: 'enable', word_class: 'V' },
    { word: 'enabling', word_class: 'AJ' },
    { word: 'enablement', word_class: 'N' }
  ],
  venerating: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  maternal: [
    { word: 'maternal', word_class: 'AJ' },
    { word: 'maternity', word_class: 'N' },
    { word: 'maternally', word_class: 'AV' }
  ],
  mensurable: [
    { word: 'mensural', word_class: 'AJ' },
    { word: 'mensurable', word_class: 'AJ' },
    { word: 'mensuration', word_class: 'N' }
  ],
  poeticize: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  cityfy: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  disreputableness: [
    { word: 'disrepute', word_class: 'N' },
    { word: 'disreputable', word_class: 'AJ' },
    { word: 'disreputably', word_class: 'AV' },
    { word: 'disreputability', word_class: 'N' },
    { word: 'disreputableness', word_class: 'N' }
  ],
  photoelectric: [
    { word: 'photoelectric', word_class: 'AJ' },
    { word: 'photoelectrical', word_class: 'AJ' },
    { word: 'photoelectrically', word_class: 'AV' }
  ],
  refute: [
    { word: 'refute', word_class: 'V' },
    { word: 'refuting', word_class: 'N' },
    { word: 'refutable', word_class: 'AJ' },
    { word: 'refutation', word_class: 'N' }
  ],
  sandwichman: [
    { word: 'sandwich', word_class: 'N' },
    { word: 'sandwich', word_class: 'V' },
    { word: 'sandwichman', word_class: 'N' }
  ],
  builder: [
    { word: 'build', word_class: 'N' },
    { word: 'build', word_class: 'V' },
    { word: 'builder', word_class: 'N' },
    { word: 'building', word_class: 'N' },
    { word: 'building', word_class: 'AJ' }
  ],
  flavouring: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  engaging: [
    { word: 'engage', word_class: 'V' },
    { word: 'engaged', word_class: 'AJ' },
    { word: 'engaging', word_class: 'AJ' },
    { word: 'engagement', word_class: 'N' }
  ],
  comforted: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  nonsensical: [
    { word: 'nonsense', word_class: 'N' },
    { word: 'nonsense', word_class: 'AJ' },
    { word: 'nonsensical', word_class: 'AJ' },
    { word: 'nonsensicality', word_class: 'N' }
  ],
  ail: [
    { word: 'ail', word_class: 'N' },
    { word: 'ail', word_class: 'V' },
    { word: 'ailing', word_class: 'AJ' },
    { word: 'ailment', word_class: 'N' }
  ],
  activator: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  fagged: [{ word: 'fag', word_class: 'N' }, { word: 'fag', word_class: 'V' }, { word: 'fagged', word_class: 'AJ' }],
  molestation: [
    { word: 'molest', word_class: 'V' },
    { word: 'molester', word_class: 'N' },
    { word: 'molested', word_class: 'AJ' },
    { word: 'molestation', word_class: 'N' }
  ],
  jerking: [
    { word: 'jerk', word_class: 'N' },
    { word: 'jerk', word_class: 'V' },
    { word: 'jerky', word_class: 'N' },
    { word: 'jerky', word_class: 'AJ' },
    { word: 'jerking', word_class: 'N' },
    { word: 'jerking', word_class: 'AJ' },
    { word: 'jerkiness', word_class: 'N' }
  ],
  stridor: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  adjunction: [
    { word: 'adjunct', word_class: 'N' },
    { word: 'adjunct', word_class: 'AJ' },
    { word: 'adjunction', word_class: 'N' },
    { word: 'adjunctive', word_class: 'AJ' }
  ],
  lengthened: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  verbose: [
    { word: 'verbose', word_class: 'AJ' },
    { word: 'verbosity', word_class: 'N' },
    { word: 'verbosely', word_class: 'AV' },
    { word: 'verboseness', word_class: 'N' }
  ],
  substantivize: [
    { word: 'substantival', word_class: 'AJ' },
    { word: 'substantivise', word_class: 'V' },
    { word: 'substantivize', word_class: 'V' },
    { word: 'substantivisation', word_class: 'N' },
    { word: 'substantivization', word_class: 'N' }
  ],
  contrariness: [
    { word: 'contrary', word_class: 'N' },
    { word: 'contrary', word_class: 'AJ' },
    { word: 'contrariness', word_class: 'N' },
    { word: 'contrarious', word_class: 'AJ' },
    { word: 'contrariwise', word_class: 'AV' }
  ],
  subjectiveness: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  lax: [
    { word: 'lax', word_class: 'AJ' },
    { word: 'laxness', word_class: 'N' },
    { word: 'laxative', word_class: 'N' },
    { word: 'laxative', word_class: 'AJ' }
  ],
  inverted: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  moderatism: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  sway: [{ word: 'sway', word_class: 'N' }, { word: 'sway', word_class: 'V' }, { word: 'swaying', word_class: 'AJ' }],
  replacing: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  portentously: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  fraternization: [
    { word: 'fraternal', word_class: 'AJ' },
    { word: 'fraternity', word_class: 'N' },
    { word: 'fraternize', word_class: 'V' },
    { word: 'fraternally', word_class: 'AV' },
    { word: 'fraternization', word_class: 'N' }
  ],
  lexicographical: [
    { word: 'lexicographer', word_class: 'N' },
    { word: 'lexicographic', word_class: 'AJ' },
    { word: 'lexicographical', word_class: 'AJ' }
  ],
  attachment: [
    { word: 'attach', word_class: 'V' },
    { word: 'attache', word_class: 'N' },
    { word: 'attached', word_class: 'AJ' },
    { word: 'attachment', word_class: 'N' },
    { word: 'attachable', word_class: 'AJ' }
  ],
  authenticity: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  commercialise: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  words: [
    { word: 'word', word_class: 'N' },
    { word: 'word', word_class: 'V' },
    { word: 'words', word_class: 'N' },
    { word: 'wording', word_class: 'N' }
  ],
  chlorinate: [
    { word: 'chlorine', word_class: 'N' },
    { word: 'chlorinate', word_class: 'V' },
    { word: 'chlorination', word_class: 'N' }
  ],
  coat: [
    { word: 'coat', word_class: 'N' },
    { word: 'coat', word_class: 'V' },
    { word: 'coatee', word_class: 'N' },
    { word: 'coated', word_class: 'AJ' },
    { word: 'coating', word_class: 'N' }
  ],
  forester: [
    { word: 'forest', word_class: 'N' },
    { word: 'forest', word_class: 'V' },
    { word: 'forester', word_class: 'N' },
    { word: 'forestry', word_class: 'N' },
    { word: 'forested', word_class: 'AJ' }
  ],
  utopian: [
    { word: 'utopia', word_class: 'N' },
    { word: 'utopian', word_class: 'N' },
    { word: 'utopian', word_class: 'AJ' },
    { word: 'utopianism', word_class: 'N' }
  ],
  ar: [{ word: 'ar', word_class: 'N' }, { word: 'are', word_class: 'N' }, { word: 'ares', word_class: 'N' }],
  fabricated: [
    { word: 'fabric', word_class: 'N' },
    { word: 'fabricate', word_class: 'V' },
    { word: 'fabricator', word_class: 'N' },
    { word: 'fabricated', word_class: 'AJ' },
    { word: 'fabrication', word_class: 'N' }
  ],
  scantiness: [
    { word: 'scanty', word_class: 'N' },
    { word: 'scanty', word_class: 'AJ' },
    { word: 'scantiness', word_class: 'N' }
  ],
  serologic: [
    { word: 'serology', word_class: 'N' },
    { word: 'serologic', word_class: 'AJ' },
    { word: 'serological', word_class: 'AJ' }
  ],
  covetously: [
    { word: 'covet', word_class: 'N' },
    { word: 'covet', word_class: 'V' },
    { word: 'coveted', word_class: 'AJ' },
    { word: 'covetous', word_class: 'AJ' },
    { word: 'covetously', word_class: 'AV' },
    { word: 'covetousness', word_class: 'N' }
  ],
  abraded: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  summer: [
    { word: 'summer', word_class: 'N' },
    { word: 'summer', word_class: 'V' },
    { word: 'summer', word_class: 'AJ' },
    { word: 'summerize', word_class: 'V' }
  ],
  mispronunciation: [
    { word: 'mispronounce', word_class: 'V' },
    { word: 'mispronouncement', word_class: 'N' },
    { word: 'mispronunciation', word_class: 'N' }
  ],
  intellectualize: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  acquirement: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  massed: [
    { word: 'mass', word_class: 'N' },
    { word: 'mass', word_class: 'V' },
    { word: 'mass', word_class: 'AJ' },
    { word: 'masse', word_class: 'N' },
    { word: 'masses', word_class: 'N' },
    { word: 'massed', word_class: 'AJ' }
  ],
  coping: [
    { word: 'cope', word_class: 'N' },
    { word: 'cope', word_class: 'V' },
    { word: 'copal', word_class: 'N' },
    { word: 'coping', word_class: 'N' }
  ],
  assur: [
    { word: 'assur', word_class: 'N' },
    { word: 'assure', word_class: 'V' },
    { word: 'assured', word_class: 'AJ' },
    { word: 'assurance', word_class: 'N' },
    { word: 'assuring', word_class: 'AJ' }
  ],
  deck: [
    { word: 'deck', word_class: 'N' },
    { word: 'deck', word_class: 'V' },
    { word: 'decked', word_class: 'AJ' },
    { word: 'decking', word_class: 'N' }
  ],
  benevolence: [
    { word: 'benevolence', word_class: 'N' },
    { word: 'benevolent', word_class: 'AJ' },
    { word: 'benevolently', word_class: 'AV' }
  ],
  moldering: [
    { word: 'molder', word_class: 'V' },
    { word: 'moldered', word_class: 'AJ' },
    { word: 'moldering', word_class: 'AJ' }
  ],
  altruistic: [
    { word: 'altruist', word_class: 'N' },
    { word: 'altruistic', word_class: 'AJ' },
    { word: 'altruistically', word_class: 'AV' }
  ],
  hunchback: [
    { word: 'hunchback', word_class: 'N' },
    { word: 'hunchback', word_class: 'AJ' },
    { word: 'hunchbacked', word_class: 'AJ' }
  ],
  bituminization: [
    { word: 'bituminise', word_class: 'V' },
    { word: 'bituminize', word_class: 'V' },
    { word: 'bituminous', word_class: 'AJ' },
    { word: 'bituminisation', word_class: 'N' },
    { word: 'bituminization', word_class: 'N' }
  ],
  identical: [
    { word: 'identity', word_class: 'N' },
    { word: 'identical', word_class: 'AJ' },
    { word: 'identically', word_class: 'AV' }
  ],
  busyness: [
    { word: 'busy', word_class: 'V' },
    { word: 'busy', word_class: 'AJ' },
    { word: 'busying', word_class: 'N' },
    { word: 'business', word_class: 'N' },
    { word: 'busyness', word_class: 'N' },
    { word: 'business', word_class: 'AJ' }
  ],
  bug: [{ word: 'bug', word_class: 'N' }, { word: 'bug', word_class: 'V' }, { word: 'bugged', word_class: 'AJ' }],
  eastwards: [
    { word: 'eastward', word_class: 'AJ' },
    { word: 'eastward', word_class: 'AV' },
    { word: 'eastwards', word_class: 'AV' }
  ],
  prejudgment: [
    { word: 'prejudge', word_class: 'V' },
    { word: 'prejudgment', word_class: 'N' },
    { word: 'prejudgement', word_class: 'N' }
  ],
  reformatory: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  multiplicity: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  liquescent: [
    { word: 'liquesce', word_class: 'V' },
    { word: 'liquescence', word_class: 'N' },
    { word: 'liquescent', word_class: 'AJ' }
  ],
  syllable: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  extrovert: [
    { word: 'extrovert', word_class: 'N' },
    { word: 'extrovert', word_class: 'AJ' },
    { word: 'extroversion', word_class: 'N' },
    { word: 'extroverted', word_class: 'AJ' },
    { word: 'extroversive', word_class: 'AJ' },
    { word: 'extrovertive', word_class: 'AJ' }
  ],
  desertion: [
    { word: 'desert', word_class: 'N' },
    { word: 'desert', word_class: 'V' },
    { word: 'desert', word_class: 'AJ' },
    { word: 'deserter', word_class: 'N' },
    { word: 'deserted', word_class: 'AJ' },
    { word: 'desertion', word_class: 'N' }
  ],
  dishonourably: [
    { word: 'dishonour', word_class: 'N' },
    { word: 'dishonour', word_class: 'V' },
    { word: 'dishonourable', word_class: 'AJ' },
    { word: 'dishonourably', word_class: 'AV' },
    { word: 'dishonourableness', word_class: 'N' }
  ],
  announcement: [
    { word: 'announce', word_class: 'V' },
    { word: 'announcer', word_class: 'N' },
    { word: 'announced', word_class: 'AJ' },
    { word: 'anunciation', word_class: 'N' },
    { word: 'announcement', word_class: 'N' }
  ],
  demure: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  behavioural: [
    { word: 'behaviour', word_class: 'N' },
    { word: 'behavioural', word_class: 'AJ' },
    { word: 'behaviourism', word_class: 'N' }
  ],
  pessimistic: [
    { word: 'pessimist', word_class: 'N' },
    { word: 'pessimistic', word_class: 'AJ' },
    { word: 'pessimistically', word_class: 'AV' }
  ],
  constriction: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  discretionary: [
    { word: 'discreet', word_class: 'AJ' },
    { word: 'discreetness', word_class: 'N' },
    { word: 'discretionary', word_class: 'AJ' }
  ],
  gravitation: [
    { word: 'gravitate', word_class: 'V' },
    { word: 'gravitation', word_class: 'N' },
    { word: 'gravitative', word_class: 'AJ' },
    { word: 'gravitational', word_class: 'AJ' }
  ],
  single: [
    { word: 'single', word_class: 'N' },
    { word: 'single', word_class: 'V' },
    { word: 'single', word_class: 'AJ' },
    { word: 'singles', word_class: 'N' },
    { word: 'singly', word_class: 'AV' },
    { word: 'singleness', word_class: 'N' }
  ],
  shoe: [
    { word: 'shoe', word_class: 'N' },
    { word: 'shoe', word_class: 'V' },
    { word: 'shoal', word_class: 'N' },
    { word: 'shoal', word_class: 'V' },
    { word: 'shoes', word_class: 'N' },
    { word: 'shoal', word_class: 'AJ' }
  ],
  dream: [
    { word: 'dream', word_class: 'N' },
    { word: 'dream', word_class: 'V' },
    { word: 'dreamer', word_class: 'N' },
    { word: 'dreamed', word_class: 'AJ' },
    { word: 'dreaming', word_class: 'N' }
  ],
  precipitously: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  communism: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  shoddiness: [
    { word: 'shoddy', word_class: 'N' },
    { word: 'shoddy', word_class: 'AJ' },
    { word: 'shoddiness', word_class: 'N' }
  ],
  pusillanimously: [
    { word: 'pusillanimity', word_class: 'N' },
    { word: 'pusillanimous', word_class: 'AJ' },
    { word: 'pusillanimously', word_class: 'AV' },
    { word: 'pusillanimousness', word_class: 'N' }
  ],
  cryptogamous: [
    { word: 'cryptogam', word_class: 'N' },
    { word: 'cryptogamic', word_class: 'AJ' },
    { word: 'cryptogamous', word_class: 'AJ' }
  ],
  maculate: [
    { word: 'macule', word_class: 'N' },
    { word: 'maculate', word_class: 'V' },
    { word: 'maculate', word_class: 'AJ' },
    { word: 'maculation', word_class: 'N' }
  ],
  rudimentary: [
    { word: 'rudiment', word_class: 'N' },
    { word: 'rudiments', word_class: 'N' },
    { word: 'rudimentary', word_class: 'AJ' }
  ],
  adulteress: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  graphitize: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  orally: [{ word: 'oral', word_class: 'N' }, { word: 'oral', word_class: 'AJ' }, { word: 'orally', word_class: 'AV' }],
  animalisation: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  ravenousness: [
    { word: 'raven', word_class: 'N' },
    { word: 'raven', word_class: 'V' },
    { word: 'ravening', word_class: 'AJ' },
    { word: 'ravenous', word_class: 'AJ' },
    { word: 'ravenously', word_class: 'AV' },
    { word: 'ravenousness', word_class: 'N' }
  ],
  understandable: [
    { word: 'understand', word_class: 'N' },
    { word: 'understand', word_class: 'V' },
    { word: 'understanding', word_class: 'N' },
    { word: 'understanding', word_class: 'AJ' },
    { word: 'understandable', word_class: 'AJ' },
    { word: 'understandably', word_class: 'AV' },
    { word: 'understandability', word_class: 'N' }
  ],
  errant: [
    { word: 'errant', word_class: 'N' },
    { word: 'errancy', word_class: 'N' },
    { word: 'errant', word_class: 'AJ' }
  ],
  antagonisation: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  whizz: [
    { word: 'whiz', word_class: 'N' },
    { word: 'whiz', word_class: 'V' },
    { word: 'whizz', word_class: 'N' },
    { word: 'whizz', word_class: 'V' },
    { word: 'whizzed', word_class: 'AJ' },
    { word: 'whizzing', word_class: 'AJ' }
  ],
  sibilate: [
    { word: 'sibilant', word_class: 'N' },
    { word: 'sibilate', word_class: 'V' },
    { word: 'sibilant', word_class: 'AJ' },
    { word: 'sibilation', word_class: 'N' }
  ],
  willfulness: [
    { word: 'will', word_class: 'N' },
    { word: 'will', word_class: 'V' },
    { word: 'willing', word_class: 'N' },
    { word: 'willful', word_class: 'AJ' },
    { word: 'willing', word_class: 'AJ' },
    { word: 'willfulness', word_class: 'N' }
  ],
  meaning: [
    { word: 'mean', word_class: 'N' },
    { word: 'mean', word_class: 'V' },
    { word: 'mean', word_class: 'AJ' },
    { word: 'means', word_class: 'N' },
    { word: 'meaning', word_class: 'N' },
    { word: 'meaning', word_class: 'AJ' },
    { word: 'meanness', word_class: 'N' }
  ],
  galvanise: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  fictionalisation: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  levitation: [
    { word: 'levitate', word_class: 'V' },
    { word: 'levitation', word_class: 'N' },
    { word: 'levitical', word_class: 'AJ' }
  ],
  crenellated: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  puffed: [
    { word: 'puffed', word_class: 'AJ' },
    { word: 'puffing', word_class: 'N' },
    { word: 'puffing', word_class: 'AJ' }
  ],
  miscellaneous: [
    { word: 'miscellany', word_class: 'N' },
    { word: 'miscellany', word_class: 'AJ' },
    { word: 'miscellaneous', word_class: 'AJ' }
  ],
  birth: [
    { word: 'birth', word_class: 'N' },
    { word: 'birth', word_class: 'V' },
    { word: 'birthe', word_class: 'V' },
    { word: 'birthing', word_class: 'N' },
    { word: 'birthing', word_class: 'AJ' }
  ],
  uxorial: [
    { word: 'uxorial', word_class: 'AJ' },
    { word: 'uxorious', word_class: 'AJ' },
    { word: 'uxoriously', word_class: 'AV' },
    { word: 'uxoriousness', word_class: 'N' }
  ],
  wheels: [
    { word: 'wheel', word_class: 'N' },
    { word: 'wheel', word_class: 'V' },
    { word: 'wheels', word_class: 'N' },
    { word: 'wheeler', word_class: 'N' },
    { word: 'wheeled', word_class: 'AJ' },
    { word: 'wheeling', word_class: 'N' },
    { word: 'wheeling', word_class: 'AJ' }
  ],
  pothole: [
    { word: 'pothole', word_class: 'N' },
    { word: 'potholer', word_class: 'N' },
    { word: 'potholed', word_class: 'AJ' }
  ],
  isolable: [
    { word: 'isolate', word_class: 'V' },
    { word: 'isolable', word_class: 'AJ' },
    { word: 'isolated', word_class: 'AJ' },
    { word: 'isolation', word_class: 'N' },
    { word: 'isolating', word_class: 'AJ' }
  ],
  sinuation: [
    { word: 'sinuate', word_class: 'V' },
    { word: 'sinuate', word_class: 'AJ' },
    { word: 'sinuation', word_class: 'N' }
  ],
  supplementary: [
    { word: 'supplement', word_class: 'N' },
    { word: 'supplement', word_class: 'V' },
    { word: 'supplemental', word_class: 'AJ' },
    { word: 'supplementary', word_class: 'AJ' },
    { word: 'supplementation', word_class: 'N' }
  ],
  astronautics: [
    { word: 'astronaut', word_class: 'N' },
    { word: 'astronautic', word_class: 'AJ' },
    { word: 'astronautics', word_class: 'N' },
    { word: 'astronautical', word_class: 'AJ' }
  ],
  vest: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  evangelize: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  carinal: [
    { word: 'carinal', word_class: 'AJ' },
    { word: 'carinate', word_class: 'N' },
    { word: 'carinate', word_class: 'AJ' },
    { word: 'carinated', word_class: 'AJ' }
  ],
  wafture: [
    { word: 'waft', word_class: 'N' },
    { word: 'waft', word_class: 'V' },
    { word: 'waftage', word_class: 'N' },
    { word: 'wafture', word_class: 'N' }
  ],
  brawler: [
    { word: 'brawl', word_class: 'N' },
    { word: 'brawl', word_class: 'V' },
    { word: 'brawler', word_class: 'N' },
    { word: 'brawling', word_class: 'AJ' }
  ],
  pardonably: [
    { word: 'pardon', word_class: 'N' },
    { word: 'pardon', word_class: 'V' },
    { word: 'pardoner', word_class: 'N' },
    { word: 'pardonable', word_class: 'AJ' },
    { word: 'pardonably', word_class: 'AV' }
  ],
  incurrence: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  brandishment: [
    { word: 'brandish', word_class: 'N' },
    { word: 'brandish', word_class: 'V' },
    { word: 'brandish', word_class: 'AJ' },
    { word: 'brandishment', word_class: 'N' }
  ],
  vindicable: [
    { word: 'vindicate', word_class: 'V' },
    { word: 'vindicator', word_class: 'N' },
    { word: 'vindicable', word_class: 'AJ' },
    { word: 'vindicated', word_class: 'AJ' },
    { word: 'vindication', word_class: 'N' }
  ],
  poisonously: [
    { word: 'poison', word_class: 'N' },
    { word: 'poison', word_class: 'V' },
    { word: 'poisoner', word_class: 'N' },
    { word: 'poisoning', word_class: 'N' },
    { word: 'poisonous', word_class: 'AJ' },
    { word: 'poisonously', word_class: 'AV' }
  ],
  jeweled: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  inebriate: [
    { word: 'inebriant', word_class: 'N' },
    { word: 'inebriate', word_class: 'N' },
    { word: 'inebriate', word_class: 'V' },
    { word: 'inebriated', word_class: 'AJ' },
    { word: 'inebriation', word_class: 'N' }
  ],
  structuralization: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  regret: [
    { word: 'regret', word_class: 'N' },
    { word: 'regret', word_class: 'V' },
    { word: 'regrets', word_class: 'N' },
    { word: 'regretful', word_class: 'AJ' }
  ],
  impeding: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  swashbuckling: [
    { word: 'swashbuckle', word_class: 'V' },
    { word: 'swashbuckler', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'AJ' }
  ],
  weakening: [
    { word: 'weaken', word_class: 'V' },
    { word: 'weakened', word_class: 'AJ' },
    { word: 'weakening', word_class: 'N' },
    { word: 'weakening', word_class: 'AJ' }
  ],
  intolerable: [
    { word: 'intolerance', word_class: 'N' },
    { word: 'intolerant', word_class: 'AJ' },
    { word: 'intolerable', word_class: 'AJ' },
    { word: 'intolerably', word_class: 'AV' }
  ],
  debit: [{ word: 'debit', word_class: 'N' }, { word: 'debit', word_class: 'V' }, { word: 'debite', word_class: 'V' }],
  elating: [
    { word: 'elate', word_class: 'V' },
    { word: 'elater', word_class: 'N' },
    { word: 'elated', word_class: 'AJ' },
    { word: 'elation', word_class: 'N' },
    { word: 'elating', word_class: 'AJ' }
  ],
  shrilly: [
    { word: 'shrill', word_class: 'V' },
    { word: 'shrill', word_class: 'AJ' },
    { word: 'shrilly', word_class: 'AV' },
    { word: 'shrilling', word_class: 'N' },
    { word: 'shrilling', word_class: 'AJ' },
    { word: 'shrillness', word_class: 'N' }
  ],
  unforgivable: [
    { word: 'unforgiving', word_class: 'AJ' },
    { word: 'unforgivable', word_class: 'AJ' },
    { word: 'unforgivably', word_class: 'AV' }
  ],
  alertness: [
    { word: 'alert', word_class: 'N' },
    { word: 'alert', word_class: 'V' },
    { word: 'alert', word_class: 'AJ' },
    { word: 'alerting', word_class: 'N' },
    { word: 'alertness', word_class: 'N' }
  ],
  pampering: [
    { word: 'pamper', word_class: 'V' },
    { word: 'pampered', word_class: 'AJ' },
    { word: 'pampering', word_class: 'N' },
    { word: 'pampering', word_class: 'AJ' }
  ],
  founder: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  harmonise: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  underdeveloped: [
    { word: 'underdevelop', word_class: 'V' },
    { word: 'underdeveloped', word_class: 'AJ' },
    { word: 'underdevelopment', word_class: 'N' }
  ],
  killer: [
    { word: 'kill', word_class: 'N' },
    { word: 'kill', word_class: 'V' },
    { word: 'killer', word_class: 'N' },
    { word: 'killing', word_class: 'N' },
    { word: 'killing', word_class: 'AJ' }
  ],
  toxicant: [
    { word: 'toxic', word_class: 'AJ' },
    { word: 'toxicity', word_class: 'N' },
    { word: 'toxicant', word_class: 'AJ' }
  ],
  reefer: [{ word: 'reef', word_class: 'N' }, { word: 'reef', word_class: 'V' }, { word: 'reefer', word_class: 'N' }],
  basophilic: [
    { word: 'basophil', word_class: 'N' },
    { word: 'basophile', word_class: 'N' },
    { word: 'basophilic', word_class: 'AJ' }
  ],
  satyrical: [
    { word: 'satyr', word_class: 'N' },
    { word: 'satyric', word_class: 'AJ' },
    { word: 'satyrical', word_class: 'AJ' }
  ],
  isomerization: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  ginger: [
    { word: 'ginger', word_class: 'N' },
    { word: 'ginger', word_class: 'V' },
    { word: 'ginger', word_class: 'AJ' }
  ],
  cogitation: [
    { word: 'cogitate', word_class: 'V' },
    { word: 'cogitable', word_class: 'AJ' },
    { word: 'cogitation', word_class: 'N' },
    { word: 'cogitative', word_class: 'AJ' }
  ],
  approximant: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  impeachment: [
    { word: 'impeach', word_class: 'N' },
    { word: 'impeach', word_class: 'V' },
    { word: 'impeachment', word_class: 'N' },
    { word: 'impeachability', word_class: 'N' }
  ],
  incontinence: [
    { word: 'incontinence', word_class: 'N' },
    { word: 'incontinency', word_class: 'N' },
    { word: 'incontinent', word_class: 'AJ' }
  ],
  proving: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  unanimity: [
    { word: 'unanimity', word_class: 'N' },
    { word: 'unanimous', word_class: 'AJ' },
    { word: 'unanimated', word_class: 'AJ' },
    { word: 'unanimously', word_class: 'AV' }
  ],
  adjunct: [
    { word: 'adjunct', word_class: 'N' },
    { word: 'adjunct', word_class: 'AJ' },
    { word: 'adjunction', word_class: 'N' },
    { word: 'adjunctive', word_class: 'AJ' }
  ],
  cabled: [
    { word: 'cable', word_class: 'N' },
    { word: 'cable', word_class: 'V' },
    { word: 'cabled', word_class: 'AJ' }
  ],
  suggestive: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  unfolding: [
    { word: 'unfold', word_class: 'V' },
    { word: 'unfolded', word_class: 'AJ' },
    { word: 'unfolding', word_class: 'N' }
  ],
  breeding: [
    { word: 'breed', word_class: 'N' },
    { word: 'breed', word_class: 'V' },
    { word: 'breeder', word_class: 'N' },
    { word: 'breeding', word_class: 'N' },
    { word: 'breeding', word_class: 'AJ' }
  ],
  westernise: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  frequency: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  deviser: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  demonstrative: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  imprecision: [
    { word: 'imprecise', word_class: 'AJ' },
    { word: 'imprecision', word_class: 'N' },
    { word: 'imprecisely', word_class: 'AV' },
    { word: 'impreciseness', word_class: 'N' }
  ],
  bluntness: [
    { word: 'blunt', word_class: 'V' },
    { word: 'blunt', word_class: 'AJ' },
    { word: 'blunted', word_class: 'AJ' },
    { word: 'bluntness', word_class: 'N' }
  ],
  undulate: [
    { word: 'undulate', word_class: 'V' },
    { word: 'undulant', word_class: 'AJ' },
    { word: 'undulate', word_class: 'AJ' },
    { word: 'undulation', word_class: 'N' }
  ],
  parabolization: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  reflection: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  weekend: [
    { word: 'weekend', word_class: 'N' },
    { word: 'weekend', word_class: 'V' },
    { word: 'weekender', word_class: 'N' }
  ],
  dozen: [{ word: 'dozen', word_class: 'N' }, { word: 'dozen', word_class: 'AJ' }, { word: 'dozens', word_class: 'N' }],
  haunted: [
    { word: 'haunt', word_class: 'N' },
    { word: 'haunt', word_class: 'V' },
    { word: 'haunted', word_class: 'AJ' },
    { word: 'haunting', word_class: 'AJ' }
  ],
  animality: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  intoxication: [
    { word: 'intoxicant', word_class: 'N' },
    { word: 'intoxicate', word_class: 'V' },
    { word: 'intoxicant', word_class: 'AJ' },
    { word: 'intoxicated', word_class: 'AJ' },
    { word: 'intoxication', word_class: 'N' },
    { word: 'intoxicating', word_class: 'AJ' }
  ],
  conceiver: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  sacrilegiously: [
    { word: 'sacrilege', word_class: 'N' },
    { word: 'sacrilegious', word_class: 'AJ' },
    { word: 'sacrilegiously', word_class: 'AV' },
    { word: 'sacrilegiousness', word_class: 'N' }
  ],
  familiarization: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  monoply: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  appall: [
    { word: 'appal', word_class: 'V' },
    { word: 'appall', word_class: 'V' },
    { word: 'appalled', word_class: 'AJ' },
    { word: 'appalling', word_class: 'N' },
    { word: 'appalling', word_class: 'AJ' }
  ],
  barely: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  connivance: [
    { word: 'connive', word_class: 'V' },
    { word: 'connivance', word_class: 'N' },
    { word: 'conniving', word_class: 'AJ' }
  ],
  respectful: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  expressiveness: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  consultative: [
    { word: 'consult', word_class: 'V' },
    { word: 'consultant', word_class: 'N' },
    { word: 'consultive', word_class: 'AJ' },
    { word: 'consultation', word_class: 'N' },
    { word: 'consultative', word_class: 'AJ' }
  ],
  permed: [
    { word: 'perm', word_class: 'N' },
    { word: 'perm', word_class: 'V' },
    { word: 'permed', word_class: 'AJ' },
    { word: 'permeative', word_class: 'AJ' },
    { word: 'perlocutionary', word_class: 'N' }
  ],
  officiousness: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  laced: [
    { word: 'lace', word_class: 'N' },
    { word: 'lace', word_class: 'V' },
    { word: 'laced', word_class: 'AJ' },
    { word: 'lacing', word_class: 'N' }
  ],
  anglicanism: [
    { word: 'anglican', word_class: 'N' },
    { word: 'anglican', word_class: 'AJ' },
    { word: 'anglicanism', word_class: 'N' }
  ],
  instantaneously: [
    { word: 'instantaneous', word_class: 'AJ' },
    { word: 'instantaneously', word_class: 'AV' },
    { word: 'instantaneousness', word_class: 'N' }
  ],
  continent: [
    { word: 'continent', word_class: 'N' },
    { word: 'continence', word_class: 'N' },
    { word: 'continency', word_class: 'N' },
    { word: 'continent', word_class: 'AJ' }
  ],
  distribution: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  brainwashed: [
    { word: 'brainwash', word_class: 'V' },
    { word: 'brainwashed', word_class: 'AJ' },
    { word: 'brainwashing', word_class: 'N' }
  ],
  ascendency: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  operation: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  transversal: [
    { word: 'transverse', word_class: 'N' },
    { word: 'transverse', word_class: 'V' },
    { word: 'transversal', word_class: 'N' },
    { word: 'transverse', word_class: 'AJ' },
    { word: 'transversal', word_class: 'AJ' },
    { word: 'transversely', word_class: 'AV' },
    { word: 'transversally', word_class: 'AV' }
  ],
  imagism: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  drenched: [
    { word: 'drench', word_class: 'V' },
    { word: 'drenched', word_class: 'AJ' },
    { word: 'drenching', word_class: 'N' }
  ],
  circularity: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  parts: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  perambulate: [
    { word: 'perambulate', word_class: 'V' },
    { word: 'perambulator', word_class: 'N' },
    { word: 'perambulation', word_class: 'N' },
    { word: 'perambulating', word_class: 'AJ' }
  ],
  smooth: [
    { word: 'smooth', word_class: 'N' },
    { word: 'smooth', word_class: 'V' },
    { word: 'smooth', word_class: 'AJ' },
    { word: 'smoother', word_class: 'N' },
    { word: 'smoothed', word_class: 'AJ' },
    { word: 'smoothness', word_class: 'N' }
  ],
  disruptive: [
    { word: 'disrupt', word_class: 'V' },
    { word: 'disrupted', word_class: 'AJ' },
    { word: 'disruption', word_class: 'N' },
    { word: 'disruptive', word_class: 'AJ' },
    { word: 'disruptively', word_class: 'AV' }
  ],
  manliness: [
    { word: 'manly', word_class: 'AJ' },
    { word: 'manly', word_class: 'AV' },
    { word: 'manliness', word_class: 'N' }
  ],
  dehydrogenation: [
    { word: 'dehydrogenate', word_class: 'V' },
    { word: 'dehydrogenation', word_class: 'N' },
    { word: 'dehydrogenisation', word_class: 'N' },
    { word: 'dehydrogenization', word_class: 'N' }
  ],
  fuel: [
    { word: 'fuel', word_class: 'N' },
    { word: 'fuel', word_class: 'V' },
    { word: 'fueled', word_class: 'AJ' },
    { word: 'fueling', word_class: 'N' }
  ],
  fraternally: [
    { word: 'fraternal', word_class: 'AJ' },
    { word: 'fraternity', word_class: 'N' },
    { word: 'fraternize', word_class: 'V' },
    { word: 'fraternally', word_class: 'AV' },
    { word: 'fraternization', word_class: 'N' }
  ],
  middling: [
    { word: 'middle', word_class: 'N' },
    { word: 'middle', word_class: 'V' },
    { word: 'middle', word_class: 'AJ' },
    { word: 'middling', word_class: 'N' },
    { word: 'middleman', word_class: 'N' },
    { word: 'middling', word_class: 'AJ' },
    { word: 'middling', word_class: 'AV' }
  ],
  derangement: [
    { word: 'derange', word_class: 'V' },
    { word: 'deranged', word_class: 'AJ' },
    { word: 'derangement', word_class: 'N' }
  ],
  seismological: [
    { word: 'seismology', word_class: 'N' },
    { word: 'seismologic', word_class: 'AJ' },
    { word: 'seismological', word_class: 'AJ' }
  ],
  inexpressive: [
    { word: 'inexpressive', word_class: 'AJ' },
    { word: 'inexpressible', word_class: 'AJ' },
    { word: 'inexpressively', word_class: 'AV' }
  ],
  abundance: [
    { word: 'abound', word_class: 'V' },
    { word: 'abundance', word_class: 'N' },
    { word: 'abundant', word_class: 'AJ' },
    { word: 'abounding', word_class: 'AJ' },
    { word: 'abundantly', word_class: 'AV' }
  ],
  thwarting: [
    { word: 'thwart', word_class: 'N' },
    { word: 'thwart', word_class: 'V' },
    { word: 'thwarted', word_class: 'AJ' },
    { word: 'thwarting', word_class: 'N' },
    { word: 'thwarting', word_class: 'AJ' }
  ],
  faintness: [
    { word: 'faint', word_class: 'N' },
    { word: 'faint', word_class: 'V' },
    { word: 'faint', word_class: 'AJ' },
    { word: 'faintness', word_class: 'N' }
  ],
  donation: [
    { word: 'donate', word_class: 'V' },
    { word: 'donated', word_class: 'AJ' },
    { word: 'donation', word_class: 'N' }
  ],
  erotism: [
    { word: 'erotic', word_class: 'AJ' },
    { word: 'erotism', word_class: 'N' },
    { word: 'erotically', word_class: 'AV' }
  ],
  invincibly: [
    { word: 'invincible', word_class: 'AJ' },
    { word: 'invincibly', word_class: 'AV' },
    { word: 'invincibility', word_class: 'N' }
  ],
  stomach: [
    { word: 'stomach', word_class: 'N' },
    { word: 'stomach', word_class: 'V' },
    { word: 'stomacher', word_class: 'N' },
    { word: 'stomachal', word_class: 'AJ' },
    { word: 'stomachic', word_class: 'AJ' }
  ],
  abstract: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  synthetically: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  moved: [
    { word: 'move', word_class: 'N' },
    { word: 'move', word_class: 'V' },
    { word: 'mover', word_class: 'N' },
    { word: 'moved', word_class: 'AJ' },
    { word: 'moving', word_class: 'N' },
    { word: 'moving', word_class: 'AJ' },
    { word: 'movement', word_class: 'N' }
  ],
  drawing: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  'die-cast': [
    { word: 'die-cast', word_class: 'V' },
    { word: 'die-cast', word_class: 'AJ' },
    { word: 'die-casting', word_class: 'N' }
  ],
  autocracy: [
    { word: 'autocrat', word_class: 'N' },
    { word: 'autocracy', word_class: 'N' },
    { word: 'autocratic', word_class: 'AJ' },
    { word: 'autocratical', word_class: 'AJ' },
    { word: 'autocratically', word_class: 'AV' }
  ],
  borrowing: [
    { word: 'borrow', word_class: 'V' },
    { word: 'borrower', word_class: 'N' },
    { word: 'borrowing', word_class: 'N' }
  ],
  superscribe: [
    { word: 'superscribe', word_class: 'V' },
    { word: 'superscript', word_class: 'N' },
    { word: 'superscript', word_class: 'AJ' },
    { word: 'superscription', word_class: 'N' }
  ],
  lathe: [
    { word: 'lath', word_class: 'N' },
    { word: 'lathe', word_class: 'N' },
    { word: 'lathe', word_class: 'V' },
    { word: 'lathing', word_class: 'N' }
  ],
  endorsed: [
    { word: 'endorse', word_class: 'V' },
    { word: 'endorser', word_class: 'N' },
    { word: 'endorsed', word_class: 'AJ' },
    { word: 'endorsement', word_class: 'N' }
  ],
  general: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  alcoholic: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  jollity: [
    { word: 'jolly', word_class: 'N' },
    { word: 'jolly', word_class: 'V' },
    { word: 'jolly', word_class: 'AJ' },
    { word: 'jolly', word_class: 'AV' },
    { word: 'jollity', word_class: 'N' }
  ],
  appliance: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  maneuverability: [
    { word: 'maneuverer', word_class: 'N' },
    { word: 'maneuverable', word_class: 'AJ' },
    { word: 'maneuverability', word_class: 'N' }
  ],
  chaotically: [
    { word: 'chaos', word_class: 'N' },
    { word: 'chaotic', word_class: 'AJ' },
    { word: 'chaotically', word_class: 'AV' }
  ],
  passing: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  unassertiveness: [
    { word: 'unassertive', word_class: 'AJ' },
    { word: 'unassertively', word_class: 'AV' },
    { word: 'unassertiveness', word_class: 'N' }
  ],
  substitution: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  educational: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  cauterisation: [
    { word: 'cauterise', word_class: 'V' },
    { word: 'cauterize', word_class: 'V' },
    { word: 'cauterisation', word_class: 'N' },
    { word: 'cauterization', word_class: 'N' }
  ],
  introduce: [
    { word: 'introduce', word_class: 'V' },
    { word: 'introduction', word_class: 'N' },
    { word: 'introductory', word_class: 'AJ' }
  ],
  jolted: [
    { word: 'jolt', word_class: 'N' },
    { word: 'jolt', word_class: 'V' },
    { word: 'jolted', word_class: 'AJ' },
    { word: 'jolting', word_class: 'AJ' }
  ],
  canter: [
    { word: 'canter', word_class: 'N' },
    { word: 'canter', word_class: 'V' },
    { word: 'cantering', word_class: 'AJ' }
  ],
  obstructively: [
    { word: 'obstruct', word_class: 'V' },
    { word: 'obstructed', word_class: 'AJ' },
    { word: 'obstruction', word_class: 'N' },
    { word: 'obstructive', word_class: 'AJ' },
    { word: 'obstructively', word_class: 'AV' }
  ],
  distinctness: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  experimentation: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  confirming: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  sweep: [
    { word: 'sweep', word_class: 'N' },
    { word: 'sweep', word_class: 'V' },
    { word: 'sweeper', word_class: 'N' },
    { word: 'sweeping', word_class: 'N' },
    { word: 'sweeping', word_class: 'AJ' }
  ],
  memorialisation: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  astringency: [
    { word: 'astringent', word_class: 'N' },
    { word: 'astringence', word_class: 'N' },
    { word: 'astringency', word_class: 'N' },
    { word: 'astringent', word_class: 'AJ' }
  ],
  indirection: [
    { word: 'indirect', word_class: 'AJ' },
    { word: 'indirection', word_class: 'N' },
    { word: 'indirectness', word_class: 'N' }
  ],
  solute: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  scanning: [
    { word: 'scan', word_class: 'N' },
    { word: 'scan', word_class: 'V' },
    { word: 'scanning', word_class: 'N' }
  ],
  ironic: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  russian: [
    { word: 'russian', word_class: 'N' },
    { word: 'russian', word_class: 'AJ' },
    { word: 'russianise', word_class: 'V' },
    { word: 'russianize', word_class: 'V' },
    { word: 'russianisation', word_class: 'N' },
    { word: 'russianization', word_class: 'N' }
  ],
  graphitise: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  becoming: [
    { word: 'become', word_class: 'V' },
    { word: 'become', word_class: 'AJ' },
    { word: 'becoming', word_class: 'AJ' }
  ],
  hooker: [
    { word: 'hook', word_class: 'N' },
    { word: 'hook', word_class: 'V' },
    { word: 'hooker', word_class: 'N' },
    { word: 'hooked', word_class: 'AJ' }
  ],
  leading: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  succulent: [
    { word: 'succulent', word_class: 'N' },
    { word: 'succulence', word_class: 'N' },
    { word: 'succulent', word_class: 'AJ' }
  ],
  forjudgment: [
    { word: 'forjudge', word_class: 'V' },
    { word: 'forejudge', word_class: 'V' },
    { word: 'forjudgment', word_class: 'N' },
    { word: 'forejudgment', word_class: 'N' },
    { word: 'forejudgement', word_class: 'N' }
  ],
  doctorate: [
    { word: 'doctor', word_class: 'N' },
    { word: 'doctor', word_class: 'V' },
    { word: 'doctoral', word_class: 'AJ' },
    { word: 'doctorate', word_class: 'N' }
  ],
  gust: [{ word: 'gust', word_class: 'N' }, { word: 'gust', word_class: 'V' }, { word: 'gustative', word_class: 'AJ' }],
  brim: [
    { word: 'brim', word_class: 'N' },
    { word: 'brim', word_class: 'V' },
    { word: 'brimful', word_class: 'AJ' },
    { word: 'brimming', word_class: 'AJ' }
  ],
  readable: [
    { word: 'readable', word_class: 'AJ' },
    { word: 'readably', word_class: 'AV' },
    { word: 'readability', word_class: 'N' }
  ],
  placentation: [
    { word: 'placenta', word_class: 'N' },
    { word: 'placental', word_class: 'N' },
    { word: 'placental', word_class: 'AJ' },
    { word: 'placentation', word_class: 'N' }
  ],
  solder: [
    { word: 'solder', word_class: 'N' },
    { word: 'solder', word_class: 'V' },
    { word: 'soldering', word_class: 'N' }
  ],
  core: [
    { word: 'core', word_class: 'N' },
    { word: 'core', word_class: 'V' },
    { word: 'coral', word_class: 'N' },
    { word: 'coral', word_class: 'AJ' }
  ],
  chafing: [
    { word: 'chafe', word_class: 'N' },
    { word: 'chafe', word_class: 'V' },
    { word: 'chafed', word_class: 'AJ' },
    { word: 'chafing', word_class: 'N' }
  ],
  admirable: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  mensuration: [
    { word: 'mensural', word_class: 'AJ' },
    { word: 'mensurable', word_class: 'AJ' },
    { word: 'mensuration', word_class: 'N' }
  ],
  discomposure: [
    { word: 'discompose', word_class: 'V' },
    { word: 'discomposed', word_class: 'AJ' },
    { word: 'discomposure', word_class: 'N' }
  ],
  enduring: [
    { word: 'endure', word_class: 'V' },
    { word: 'endurance', word_class: 'N' },
    { word: 'enduring', word_class: 'AJ' },
    { word: 'endurable', word_class: 'AJ' }
  ],
  gossiping: [
    { word: 'gossip', word_class: 'N' },
    { word: 'gossip', word_class: 'V' },
    { word: 'gossiper', word_class: 'N' },
    { word: 'gossiping', word_class: 'N' }
  ],
  climatic: [
    { word: 'climate', word_class: 'N' },
    { word: 'climatic', word_class: 'AJ' },
    { word: 'climatical', word_class: 'AJ' },
    { word: 'climatically', word_class: 'AV' }
  ],
  contritely: [
    { word: 'contrite', word_class: 'AJ' },
    { word: 'contrition', word_class: 'N' },
    { word: 'contritely', word_class: 'AV' },
    { word: 'contriteness', word_class: 'N' }
  ],
  imbuement: [
    { word: 'imbue', word_class: 'V' },
    { word: 'imbued', word_class: 'AJ' },
    { word: 'imbuement', word_class: 'N' }
  ],
  addict: [
    { word: 'addict', word_class: 'N' },
    { word: 'addict', word_class: 'V' },
    { word: 'addicted', word_class: 'AJ' },
    { word: 'addiction', word_class: 'N' },
    { word: 'addictive', word_class: 'AJ' }
  ],
  quakers: [
    { word: 'quake', word_class: 'N' },
    { word: 'quake', word_class: 'V' },
    { word: 'quaker', word_class: 'N' },
    { word: 'quakers', word_class: 'N' },
    { word: 'quaking', word_class: 'AJ' },
    { word: 'quakerism', word_class: 'N' }
  ],
  subsidize: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  chewing: [{ word: 'chew', word_class: 'N' }, { word: 'chew', word_class: 'V' }, { word: 'chewing', word_class: 'N' }],
  proportionable: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  mallow: [
    { word: 'mallow', word_class: 'N' },
    { word: 'mallow', word_class: 'V' },
    { word: 'mallow', word_class: 'AJ' }
  ],
  malt: [
    { word: 'malt', word_class: 'N' },
    { word: 'malt', word_class: 'V' },
    { word: 'malted', word_class: 'N' },
    { word: 'maltman', word_class: 'N' }
  ],
  reciprocality: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  illuminating: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  accepting: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  encroacher: [
    { word: 'encroach', word_class: 'V' },
    { word: 'encroacher', word_class: 'N' },
    { word: 'encroaching', word_class: 'AJ' },
    { word: 'encroachment', word_class: 'N' }
  ],
  hang: [
    { word: 'hang', word_class: 'N' },
    { word: 'hang', word_class: 'V' },
    { word: 'hanger', word_class: 'N' },
    { word: 'hanging', word_class: 'N' },
    { word: 'hanging', word_class: 'V' },
    { word: 'hangman', word_class: 'N' },
    { word: 'hanging', word_class: 'AJ' }
  ],
  recurved: [
    { word: 'recurve', word_class: 'V' },
    { word: 'recurved', word_class: 'AJ' },
    { word: 'recurvate', word_class: 'AJ' }
  ],
  thin: [
    { word: 'thin', word_class: 'V' },
    { word: 'thin', word_class: 'AJ' },
    { word: 'thin', word_class: 'AV' },
    { word: 'thinned', word_class: 'AJ' },
    { word: 'thinness', word_class: 'N' },
    { word: 'thinning', word_class: 'N' }
  ],
  lefthand: [
    { word: 'lefthand', word_class: 'AJ' },
    { word: 'lefthanded', word_class: 'AJ' },
    { word: 'lefthandedness', word_class: 'N' }
  ],
  insensible: [
    { word: 'insensate', word_class: 'AJ' },
    { word: 'insensible', word_class: 'AJ' },
    { word: 'insensibly', word_class: 'AV' },
    { word: 'insensibility', word_class: 'N' }
  ],
  chip: [
    { word: 'chip', word_class: 'N' },
    { word: 'chip', word_class: 'V' },
    { word: 'chipped', word_class: 'AJ' },
    { word: 'chipping', word_class: 'N' }
  ],
  ascension: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  backwards: [
    { word: 'backward', word_class: 'AJ' },
    { word: 'backward', word_class: 'AV' },
    { word: 'backwards', word_class: 'AV' },
    { word: 'backwardness', word_class: 'N' }
  ],
  artist: [
    { word: 'artist', word_class: 'N' },
    { word: 'artiste', word_class: 'N' },
    { word: 'artistic', word_class: 'AJ' },
    { word: 'artistically', word_class: 'AV' }
  ],
  beheaded: [
    { word: 'behead', word_class: 'V' },
    { word: 'beheaded', word_class: 'AJ' },
    { word: 'beheading', word_class: 'N' }
  ],
  isomeric: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  pacing: [
    { word: 'pace', word_class: 'N' },
    { word: 'pace', word_class: 'V' },
    { word: 'pacer', word_class: 'N' },
    { word: 'pacing', word_class: 'N' }
  ],
  furrowed: [
    { word: 'furrow', word_class: 'N' },
    { word: 'furrow', word_class: 'V' },
    { word: 'furrowed', word_class: 'AJ' }
  ],
  expansively: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  survey: [
    { word: 'survey', word_class: 'N' },
    { word: 'survey', word_class: 'V' },
    { word: 'surveyor', word_class: 'N' },
    { word: 'surveying', word_class: 'N' }
  ],
  aridness: [
    { word: 'arid', word_class: 'AJ' },
    { word: 'aridity', word_class: 'N' },
    { word: 'aridness', word_class: 'N' }
  ],
  segment: [
    { word: 'segment', word_class: 'N' },
    { word: 'segment', word_class: 'V' },
    { word: 'segmental', word_class: 'AJ' },
    { word: 'segmented', word_class: 'AJ' },
    { word: 'segmentation', word_class: 'N' }
  ],
  packed: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  notions: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  revolting: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  advent: [
    { word: 'advent', word_class: 'N' },
    { word: 'adventism', word_class: 'N' },
    { word: 'adventive', word_class: 'AJ' }
  ],
  embellishment: [
    { word: 'embellish', word_class: 'V' },
    { word: 'embellished', word_class: 'AJ' },
    { word: 'embellishment', word_class: 'N' }
  ],
  slickness: [
    { word: 'slick', word_class: 'N' },
    { word: 'slick', word_class: 'V' },
    { word: 'slick', word_class: 'AJ' },
    { word: 'slicked', word_class: 'AJ' },
    { word: 'slickness', word_class: 'N' }
  ],
  censorship: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  contending: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  soberness: [
    { word: 'sober', word_class: 'V' },
    { word: 'sober', word_class: 'AJ' },
    { word: 'sobriety', word_class: 'N' },
    { word: 'sobering', word_class: 'AJ' },
    { word: 'soberness', word_class: 'N' }
  ],
  botheration: [
    { word: 'bother', word_class: 'N' },
    { word: 'bother', word_class: 'V' },
    { word: 'bothered', word_class: 'AJ' },
    { word: 'botheration', word_class: 'N' },
    { word: 'bothersome', word_class: 'AJ' }
  ],
  crushed: [
    { word: 'crush', word_class: 'N' },
    { word: 'crush', word_class: 'V' },
    { word: 'crusher', word_class: 'N' },
    { word: 'crushed', word_class: 'AJ' },
    { word: 'crushing', word_class: 'N' },
    { word: 'crushing', word_class: 'AJ' }
  ],
  sociolinguistically: [
    { word: 'sociolinguist', word_class: 'N' },
    { word: 'sociolinguistic', word_class: 'AJ' },
    { word: 'sociolinguistics', word_class: 'N' },
    { word: 'sociolinguistically', word_class: 'AV' }
  ],
  professionalism: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  monocotyledonous: [
    { word: 'monocotyledon', word_class: 'N' },
    { word: 'monocotyledones', word_class: 'N' },
    { word: 'monocotyledonous', word_class: 'AJ' }
  ],
  satiated: [
    { word: 'satiate', word_class: 'V' },
    { word: 'satiate', word_class: 'AJ' },
    { word: 'satiated', word_class: 'AJ' },
    { word: 'satiation', word_class: 'N' }
  ],
  parish: [
    { word: 'parish', word_class: 'N' },
    { word: 'parochial', word_class: 'AJ' },
    { word: 'parochialism', word_class: 'N' },
    { word: 'parochially', word_class: 'AV' }
  ],
  recession: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  obtuse: [
    { word: 'obtuse', word_class: 'AJ' },
    { word: 'obtusely', word_class: 'AV' },
    { word: 'obtuseness', word_class: 'N' }
  ],
  brahminism: [
    { word: 'brahmin', word_class: 'N' },
    { word: 'brahminic', word_class: 'AJ' },
    { word: 'brahminism', word_class: 'N' },
    { word: 'brahminical', word_class: 'AJ' }
  ],
  pentecost: [
    { word: 'pentecost', word_class: 'N' },
    { word: 'pentecostal', word_class: 'N' },
    { word: 'pentecostal', word_class: 'AJ' },
    { word: 'pentecostalism', word_class: 'N' }
  ],
  withdrawn: [
    { word: 'withdraw', word_class: 'V' },
    { word: 'withdrawal', word_class: 'N' },
    { word: 'withdrawn', word_class: 'AJ' },
    { word: 'withdrawing', word_class: 'AJ' },
    { word: 'withdrawnness', word_class: 'N' }
  ],
  exponent: [
    { word: 'exponent', word_class: 'N' },
    { word: 'exponential', word_class: 'N' },
    { word: 'exponential', word_class: 'AJ' },
    { word: 'exponentiate', word_class: 'V' },
    { word: 'exponentially', word_class: 'AV' },
    { word: 'exponentiation', word_class: 'N' }
  ],
  conviction: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  shamefulness: [
    { word: 'shame', word_class: 'N' },
    { word: 'shame', word_class: 'V' },
    { word: 'shamed', word_class: 'AJ' },
    { word: 'shameful', word_class: 'AJ' },
    { word: 'shamefulness', word_class: 'N' }
  ],
  eulogistic: [
    { word: 'eulogy', word_class: 'N' },
    { word: 'eulogist', word_class: 'N' },
    { word: 'eulogize', word_class: 'V' },
    { word: 'eulogistic', word_class: 'AJ' },
    { word: 'eulogization', word_class: 'N' }
  ],
  land: [
    { word: 'land', word_class: 'N' },
    { word: 'land', word_class: 'V' },
    { word: 'land', word_class: 'AJ' },
    { word: 'landed', word_class: 'AJ' },
    { word: 'landing', word_class: 'N' },
    { word: 'landman', word_class: 'N' }
  ],
  claimant: [
    { word: 'claim', word_class: 'N' },
    { word: 'claim', word_class: 'V' },
    { word: 'claimant', word_class: 'N' }
  ],
  select: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  irregular: [
    { word: 'irregular', word_class: 'N' },
    { word: 'irregular', word_class: 'AJ' },
    { word: 'irregularity', word_class: 'N' }
  ],
  morone: [
    { word: 'moron', word_class: 'N' },
    { word: 'morone', word_class: 'N' },
    { word: 'moronic', word_class: 'AJ' },
    { word: 'moronity', word_class: 'N' }
  ],
  succeed: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  kiddy: [
    { word: 'kid', word_class: 'N' },
    { word: 'kid', word_class: 'V' },
    { word: 'kiddy', word_class: 'N' },
    { word: 'kiddie', word_class: 'N' }
  ],
  excuse: [
    { word: 'excuse', word_class: 'N' },
    { word: 'excuse', word_class: 'V' },
    { word: 'excused', word_class: 'AJ' },
    { word: 'excusable', word_class: 'AJ' },
    { word: 'excusably', word_class: 'AV' }
  ],
  gravely: [
    { word: 'grave', word_class: 'N' },
    { word: 'grave', word_class: 'V' },
    { word: 'grave', word_class: 'AJ' },
    { word: 'gravely', word_class: 'AV' },
    { word: 'graveness', word_class: 'N' }
  ],
  injure: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  agreeableness: [
    { word: 'agreeable', word_class: 'AJ' },
    { word: 'agreeably', word_class: 'AV' },
    { word: 'agreeability', word_class: 'N' },
    { word: 'agreeableness', word_class: 'N' }
  ],
  ordination: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  deductive: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  popularise: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  crystalized: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  subtracter: [
    { word: 'subtract', word_class: 'N' },
    { word: 'subtract', word_class: 'V' },
    { word: 'subtracter', word_class: 'N' },
    { word: 'subtracted', word_class: 'AJ' },
    { word: 'subtraction', word_class: 'N' },
    { word: 'subtractive', word_class: 'AJ' }
  ],
  grouping: [
    { word: 'group', word_class: 'N' },
    { word: 'group', word_class: 'V' },
    { word: 'group', word_class: 'AJ' },
    { word: 'grouper', word_class: 'N' },
    { word: 'grouped', word_class: 'AJ' },
    { word: 'grouping', word_class: 'N' }
  ],
  digitalize: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  infringe: [
    { word: 'infringe', word_class: 'N' },
    { word: 'infringe', word_class: 'V' },
    { word: 'infringement', word_class: 'N' }
  ],
  humbleness: [
    { word: 'humble', word_class: 'V' },
    { word: 'humble', word_class: 'AJ' },
    { word: 'humbly', word_class: 'AV' },
    { word: 'humbled', word_class: 'AJ' },
    { word: 'humbling', word_class: 'N' },
    { word: 'humbling', word_class: 'AJ' },
    { word: 'humbleness', word_class: 'N' }
  ],
  doomed: [
    { word: 'doom', word_class: 'N' },
    { word: 'doom', word_class: 'V' },
    { word: 'doomed', word_class: 'N' },
    { word: 'doomed', word_class: 'AJ' }
  ],
  automatization: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  minimal: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  telephoner: [
    { word: 'telephone', word_class: 'N' },
    { word: 'telephone', word_class: 'V' },
    { word: 'telephoner', word_class: 'N' },
    { word: 'telephonic', word_class: 'AJ' },
    { word: 'telephonist', word_class: 'N' }
  ],
  woven: [
    { word: 'weave', word_class: 'N' },
    { word: 'weave', word_class: 'V' },
    { word: 'woven', word_class: 'AJ' },
    { word: 'weaver', word_class: 'N' },
    { word: 'weaving', word_class: 'N' },
    { word: 'weaving', word_class: 'AJ' }
  ],
  write: [
    { word: 'write', word_class: 'V' },
    { word: 'writer', word_class: 'N' },
    { word: 'writing', word_class: 'N' },
    { word: 'writings', word_class: 'N' },
    { word: 'written', word_class: 'AJ' }
  ],
  dauber: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  catechistic: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  damp: [
    { word: 'damp', word_class: 'N' },
    { word: 'damp', word_class: 'V' },
    { word: 'damp', word_class: 'AJ' },
    { word: 'damper', word_class: 'N' },
    { word: 'dampness', word_class: 'N' }
  ],
  freethinking: [
    { word: 'freethinker', word_class: 'N' },
    { word: 'freethinking', word_class: 'N' },
    { word: 'freethinking', word_class: 'AJ' }
  ],
  affirmativeness: [
    { word: 'affirm', word_class: 'V' },
    { word: 'affirmation', word_class: 'N' },
    { word: 'affirmable', word_class: 'AJ' },
    { word: 'affirmative', word_class: 'N' },
    { word: 'affirmative', word_class: 'AJ' },
    { word: 'affirmativeness', word_class: 'N' }
  ],
  can: [
    { word: 'can', word_class: 'N' },
    { word: 'can', word_class: 'V' },
    { word: 'canful', word_class: 'N' },
    { word: 'canful', word_class: 'AJ' },
    { word: 'canned', word_class: 'AJ' },
    { word: 'cannery', word_class: 'N' },
    { word: 'canning', word_class: 'N' },
    { word: 'cannery', word_class: 'AJ' }
  ],
  educative: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  constitutive: [
    { word: 'constitute', word_class: 'V' },
    { word: 'consitution', word_class: 'N' },
    { word: 'constitution', word_class: 'N' },
    { word: 'constitutive', word_class: 'AJ' },
    { word: 'constitutional', word_class: 'N' },
    { word: 'constitutional', word_class: 'AJ' }
  ],
  poeticization: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  crier: [
    { word: 'cry', word_class: 'N' },
    { word: 'cry', word_class: 'V' },
    { word: 'crier', word_class: 'N' },
    { word: 'crying', word_class: 'N' },
    { word: 'crying', word_class: 'AJ' }
  ],
  fernery: [
    { word: 'fern', word_class: 'N' },
    { word: 'ferned', word_class: 'AJ' },
    { word: 'fernery', word_class: 'N' }
  ],
  glaciated: [
    { word: 'glaciate', word_class: 'V' },
    { word: 'glaciated', word_class: 'AJ' },
    { word: 'glaciation', word_class: 'N' }
  ],
  ordinate: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  copulative: [
    { word: 'copulate', word_class: 'V' },
    { word: 'copulation', word_class: 'N' },
    { word: 'copulative', word_class: 'N' },
    { word: 'copulative', word_class: 'AJ' }
  ],
  militate: [
    { word: 'militant', word_class: 'N' },
    { word: 'militate', word_class: 'V' },
    { word: 'militance', word_class: 'N' },
    { word: 'militancy', word_class: 'N' },
    { word: 'militant', word_class: 'AJ' },
    { word: 'militation', word_class: 'N' }
  ],
  denationalise: [
    { word: 'denationalise', word_class: 'V' },
    { word: 'denationalize', word_class: 'V' },
    { word: 'denationalisation', word_class: 'N' },
    { word: 'denationalization', word_class: 'N' }
  ],
  piano: [
    { word: 'piano', word_class: 'N' },
    { word: 'piano', word_class: 'AJ' },
    { word: 'piano', word_class: 'AV' },
    { word: 'pianist', word_class: 'N' },
    { word: 'pianistic', word_class: 'AJ' }
  ],
  insistency: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  carper: [
    { word: 'carp', word_class: 'N' },
    { word: 'carp', word_class: 'V' },
    { word: 'carpal', word_class: 'N' },
    { word: 'carper', word_class: 'N' },
    { word: 'carping', word_class: 'N' },
    { word: 'carpal', word_class: 'AJ' },
    { word: 'carping', word_class: 'AJ' }
  ],
  less: [{ word: 'less', word_class: 'N' }, { word: 'less', word_class: 'AJ' }, { word: 'less', word_class: 'AV' }],
  watchfulness: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  knowledgeable: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  perverse: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  hypocrite: [
    { word: 'hypocrite', word_class: 'N' },
    { word: 'hypocritical', word_class: 'AJ' },
    { word: 'hypocritically', word_class: 'AV' }
  ],
  elements: [
    { word: 'element', word_class: 'N' },
    { word: 'elements', word_class: 'N' },
    { word: 'elemental', word_class: 'AJ' },
    { word: 'elementary', word_class: 'AJ' }
  ],
  apologist: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  incensement: [
    { word: 'incense', word_class: 'N' },
    { word: 'incense', word_class: 'V' },
    { word: 'incensed', word_class: 'AJ' },
    { word: 'incensation', word_class: 'N' },
    { word: 'incensement', word_class: 'N' }
  ],
  disassociate: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  seduced: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  muteness: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  sentimentality: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  immunosuppressant: [
    { word: 'immunosuppressant', word_class: 'N' },
    { word: 'immunosuppression', word_class: 'N' },
    { word: 'immunosuppressive', word_class: 'AJ' }
  ],
  broadness: [
    { word: 'broad', word_class: 'N' },
    { word: 'broad', word_class: 'AJ' },
    { word: 'breadth', word_class: 'N' },
    { word: 'broadness', word_class: 'N' }
  ],
  submerse: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  shadiness: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  confiscation: [
    { word: 'confiscate', word_class: 'V' },
    { word: 'confiscate', word_class: 'AJ' },
    { word: 'confiscable', word_class: 'AJ' },
    { word: 'confiscated', word_class: 'AJ' },
    { word: 'confiscation', word_class: 'N' }
  ],
  sportively: [
    { word: 'sportive', word_class: 'AJ' },
    { word: 'sportively', word_class: 'AV' },
    { word: 'sportiveness', word_class: 'N' }
  ],
  dozens: [
    { word: 'dozen', word_class: 'N' },
    { word: 'dozen', word_class: 'AJ' },
    { word: 'dozens', word_class: 'N' }
  ],
  announcer: [
    { word: 'announce', word_class: 'V' },
    { word: 'announcer', word_class: 'N' },
    { word: 'announced', word_class: 'AJ' },
    { word: 'anunciation', word_class: 'N' },
    { word: 'announcement', word_class: 'N' }
  ],
  typographically: [
    { word: 'typographer', word_class: 'N' },
    { word: 'typographic', word_class: 'AJ' },
    { word: 'typographical', word_class: 'AJ' },
    { word: 'typographically', word_class: 'AV' }
  ],
  comforts: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  alternation: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  canalisation: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  totalisator: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  condense: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  irreverent: [
    { word: 'irreverence', word_class: 'N' },
    { word: 'irreverent', word_class: 'AJ' },
    { word: 'irreverently', word_class: 'AV' }
  ],
  licentiate: [
    { word: 'licentiate', word_class: 'N' },
    { word: 'licentious', word_class: 'AJ' },
    { word: 'licentiously', word_class: 'AV' },
    { word: 'licentiousness', word_class: 'N' }
  ],
  misrepresent: [
    { word: 'misrepresent', word_class: 'V' },
    { word: 'misrepresented', word_class: 'AJ' },
    { word: 'misrepresentation', word_class: 'N' }
  ],
  camphorated: [
    { word: 'camphor', word_class: 'N' },
    { word: 'camphorate', word_class: 'V' },
    { word: 'camphoric', word_class: 'AJ' },
    { word: 'camphorated', word_class: 'AJ' },
    { word: 'camphoration', word_class: 'N' }
  ],
  panegyric: [
    { word: 'panegyric', word_class: 'N' },
    { word: 'panegyric', word_class: 'AJ' },
    { word: 'panegyrical', word_class: 'AJ' }
  ],
  crumble: [
    { word: 'crumble', word_class: 'V' },
    { word: 'crumbly', word_class: 'AJ' },
    { word: 'crumbled', word_class: 'AJ' }
  ],
  conceding: [
    { word: 'concede', word_class: 'V' },
    { word: 'conceded', word_class: 'AJ' },
    { word: 'conceding', word_class: 'N' },
    { word: 'conceding', word_class: 'AJ' },
    { word: 'concession', word_class: 'N' },
    { word: 'concessive', word_class: 'AJ' }
  ],
  retainer: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  tog: [{ word: 'tog', word_class: 'V' }, { word: 'togs', word_class: 'N' }, { word: 'togged', word_class: 'AJ' }],
  delectable: [
    { word: 'delectate', word_class: 'AJ' },
    { word: 'delectable', word_class: 'AJ' },
    { word: 'delectation', word_class: 'N' },
    { word: 'delectability', word_class: 'N' }
  ],
  approachability: [
    { word: 'approach', word_class: 'N' },
    { word: 'approach', word_class: 'V' },
    { word: 'approaching', word_class: 'N' },
    { word: 'approaching', word_class: 'AJ' },
    { word: 'approachable', word_class: 'AJ' },
    { word: 'approachability', word_class: 'N' }
  ],
  preconization: [
    { word: 'preconise', word_class: 'V' },
    { word: 'preconize', word_class: 'V' },
    { word: 'preconisation', word_class: 'N' },
    { word: 'preconization', word_class: 'N' }
  ],
  punishable: [
    { word: 'punish', word_class: 'V' },
    { word: 'punished', word_class: 'AJ' },
    { word: 'punishing', word_class: 'AJ' },
    { word: 'punishment', word_class: 'N' },
    { word: 'punishable', word_class: 'AJ' }
  ],
  archer: [
    { word: 'arch', word_class: 'N' },
    { word: 'arch', word_class: 'V' },
    { word: 'arch', word_class: 'AJ' },
    { word: 'archer', word_class: 'N' },
    { word: 'arched', word_class: 'AJ' },
    { word: 'arching', word_class: 'AJ' },
    { word: 'archness', word_class: 'N' }
  ],
  concentricity: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  suffering: [
    { word: 'suffer', word_class: 'V' },
    { word: 'sufferer', word_class: 'N' },
    { word: 'suffering', word_class: 'N' },
    { word: 'sufferance', word_class: 'N' },
    { word: 'suffering', word_class: 'AJ' },
    { word: 'sufferable', word_class: 'AJ' }
  ],
  inquisitive: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  digital: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  disjunctive: [
    { word: 'disjoin', word_class: 'V' },
    { word: 'disjunct', word_class: 'N' },
    { word: 'disjunct', word_class: 'AJ' },
    { word: 'disjoined', word_class: 'AJ' },
    { word: 'disjunction', word_class: 'N' },
    { word: 'disjunctive', word_class: 'AJ' }
  ],
  protrusion: [
    { word: 'protrude', word_class: 'V' },
    { word: 'protrusion', word_class: 'N' },
    { word: 'protruding', word_class: 'AJ' },
    { word: 'protrusive', word_class: 'AJ' },
    { word: 'protrusible', word_class: 'AJ' }
  ],
  labourite: [
    { word: 'labour', word_class: 'N' },
    { word: 'labour', word_class: 'V' },
    { word: 'labourer', word_class: 'N' },
    { word: 'laboured', word_class: 'AJ' },
    { word: 'labourite', word_class: 'N' },
    { word: 'labouring', word_class: 'AJ' }
  ],
  cyclonical: [
    { word: 'cyclone', word_class: 'N' },
    { word: 'cyclonal', word_class: 'AJ' },
    { word: 'cyclonic', word_class: 'AJ' },
    { word: 'cyclonical', word_class: 'AJ' }
  ],
  sunburn: [
    { word: 'sunburn', word_class: 'N' },
    { word: 'sunburn', word_class: 'V' },
    { word: 'sunburned', word_class: 'AJ' }
  ],
  epicyclical: [
    { word: 'epicycle', word_class: 'N' },
    { word: 'epicyclic', word_class: 'AJ' },
    { word: 'epicyclical', word_class: 'AJ' }
  ],
  unpredicted: [
    { word: 'unpredicted', word_class: 'AJ' },
    { word: 'unpredictive', word_class: 'AJ' },
    { word: 'unpredictable', word_class: 'AJ' },
    { word: 'unpredictably', word_class: 'AV' },
    { word: 'unpredictability', word_class: 'N' }
  ],
  leniently: [
    { word: 'lenience', word_class: 'N' },
    { word: 'leniency', word_class: 'N' },
    { word: 'lenient', word_class: 'AJ' },
    { word: 'leniently', word_class: 'AV' }
  ],
  tessellate: [
    { word: 'tesselate', word_class: 'V' },
    { word: 'tessellate', word_class: 'V' },
    { word: 'tessellated', word_class: 'AJ' },
    { word: 'tessellation', word_class: 'N' }
  ],
  incantation: [
    { word: 'incant', word_class: 'N' },
    { word: 'incant', word_class: 'V' },
    { word: 'incantation', word_class: 'N' }
  ],
  fallacious: [
    { word: 'fallacy', word_class: 'N' },
    { word: 'fallacious', word_class: 'AJ' },
    { word: 'fallaciousness', word_class: 'N' }
  ],
  draped: [
    { word: 'drape', word_class: 'N' },
    { word: 'drape', word_class: 'V' },
    { word: 'draper', word_class: 'N' },
    { word: 'draped', word_class: 'AJ' },
    { word: 'drapery', word_class: 'N' },
    { word: 'drapery', word_class: 'AJ' }
  ],
  fucking: [
    { word: 'fuck', word_class: 'N' },
    { word: 'fuck', word_class: 'V' },
    { word: 'fucker', word_class: 'N' },
    { word: 'fucking', word_class: 'N' },
    { word: 'fucking', word_class: 'AJ' },
    { word: 'fucking', word_class: 'AV' }
  ],
  enjoining: [
    { word: 'enjoin', word_class: 'V' },
    { word: 'enjoining', word_class: 'N' },
    { word: 'enjoinment', word_class: 'N' }
  ],
  cosmetic: [
    { word: 'cosmetic', word_class: 'N' },
    { word: 'cosmetic', word_class: 'AJ' },
    { word: 'cosmetics', word_class: 'N' },
    { word: 'cosmetically', word_class: 'AV' }
  ],
  lionize: [
    { word: 'lionise', word_class: 'V' },
    { word: 'lionize', word_class: 'V' },
    { word: 'lionisation', word_class: 'N' },
    { word: 'lionization', word_class: 'N' }
  ],
  deliberate: [
    { word: 'deliberate', word_class: 'V' },
    { word: 'deliberate', word_class: 'AJ' },
    { word: 'deliberation', word_class: 'N' },
    { word: 'deliberately', word_class: 'AV' },
    { word: 'deliberateness', word_class: 'N' }
  ],
  astronomic: [
    { word: 'astronomy', word_class: 'N' },
    { word: 'astronomer', word_class: 'N' },
    { word: 'astronomic', word_class: 'AJ' },
    { word: 'astronomical', word_class: 'AJ' },
    { word: 'astronomically', word_class: 'AV' }
  ],
  caricaturist: [
    { word: 'caricature', word_class: 'N' },
    { word: 'caricature', word_class: 'V' },
    { word: 'caricaturist', word_class: 'N' }
  ],
  unchangeability: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  allure: [
    { word: 'allure', word_class: 'N' },
    { word: 'allure', word_class: 'V' },
    { word: 'alluring', word_class: 'AJ' },
    { word: 'allurement', word_class: 'N' }
  ],
  diffusion: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  terms: [
    { word: 'term', word_class: 'N' },
    { word: 'term', word_class: 'V' },
    { word: 'terms', word_class: 'N' },
    { word: 'termes', word_class: 'N' },
    { word: 'termite', word_class: 'N' }
  ],
  taste: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  appetizing: [
    { word: 'appetence', word_class: 'N' },
    { word: 'appetency', word_class: 'N' },
    { word: 'appetent', word_class: 'AJ' },
    { word: 'appetizer', word_class: 'N' },
    { word: 'appetizing', word_class: 'AJ' }
  ],
  mechanism: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  subsidence: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  inhumanely: [
    { word: 'inhuman', word_class: 'AJ' },
    { word: 'inhumane', word_class: 'AJ' },
    { word: 'inhumanity', word_class: 'N' },
    { word: 'inhumanely', word_class: 'AV' },
    { word: 'inhumaneness', word_class: 'N' }
  ],
  affectionate: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  unexpressed: [
    { word: 'unexpressed', word_class: 'AJ' },
    { word: 'unexpressive', word_class: 'AJ' },
    { word: 'unexpressible', word_class: 'AJ' }
  ],
  frying: [
    { word: 'fry', word_class: 'N' },
    { word: 'fry', word_class: 'V' },
    { word: 'frier', word_class: 'N' },
    { word: 'fryer', word_class: 'N' },
    { word: 'frying', word_class: 'N' }
  ],
  industrialise: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  probate: [
    { word: 'probate', word_class: 'N' },
    { word: 'probate', word_class: 'V' },
    { word: 'probation', word_class: 'N' },
    { word: 'probationary', word_class: 'AJ' }
  ],
  assimilation: [
    { word: 'assimilate', word_class: 'V' },
    { word: 'assimilable', word_class: 'AJ' },
    { word: 'assimilation', word_class: 'N' },
    { word: 'assimilating', word_class: 'AJ' },
    { word: 'assimilative', word_class: 'AJ' }
  ],
  symmetry: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  chromaticity: [
    { word: 'chromate', word_class: 'N' },
    { word: 'chromatic', word_class: 'AJ' },
    { word: 'chromatism', word_class: 'N' },
    { word: 'chromaticity', word_class: 'N' },
    { word: 'chromatically', word_class: 'AV' }
  ],
  antitypic: [
    { word: 'antitype', word_class: 'N' },
    { word: 'antitypic', word_class: 'AJ' },
    { word: 'antitypical', word_class: 'AJ' }
  ],
  identifying: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  ceremonial: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  misplace: [
    { word: 'misplace', word_class: 'V' },
    { word: 'misplaced', word_class: 'AJ' },
    { word: 'misplacement', word_class: 'N' }
  ],
  illusionary: [
    { word: 'illude', word_class: 'V' },
    { word: 'illusion', word_class: 'N' },
    { word: 'illusive', word_class: 'AJ' },
    { word: 'illusionary', word_class: 'AJ' }
  ],
  travelable: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  motherliness: [
    { word: 'motherly', word_class: 'AJ' },
    { word: 'motherly', word_class: 'AV' },
    { word: 'motherliness', word_class: 'N' }
  ],
  calved: [
    { word: 'calve', word_class: 'V' },
    { word: 'calved', word_class: 'AJ' },
    { word: 'calving', word_class: 'N' }
  ],
  tatt: [
    { word: 'tat', word_class: 'N' },
    { word: 'tat', word_class: 'V' },
    { word: 'tatt', word_class: 'V' },
    { word: 'tater', word_class: 'N' },
    { word: 'tatting', word_class: 'N' }
  ],
  estuarine: [
    { word: 'estuary', word_class: 'N' },
    { word: 'estuarial', word_class: 'AJ' },
    { word: 'estuarine', word_class: 'AJ' }
  ],
  platinize: [
    { word: 'platinise', word_class: 'V' },
    { word: 'platinize', word_class: 'V' },
    { word: 'platinisation', word_class: 'N' },
    { word: 'platinization', word_class: 'N' }
  ],
  untypicality: [
    { word: 'untypical', word_class: 'AJ' },
    { word: 'untypicality', word_class: 'N' },
    { word: 'untypically', word_class: 'AV' }
  ],
  poised: [
    { word: 'poise', word_class: 'N' },
    { word: 'poise', word_class: 'V' },
    { word: 'poised', word_class: 'AJ' }
  ],
  appositional: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  flaring: [
    { word: 'flare', word_class: 'N' },
    { word: 'flare', word_class: 'V' },
    { word: 'flared', word_class: 'AJ' },
    { word: 'flaring', word_class: 'AJ' }
  ],
  rhyme: [
    { word: 'rhyme', word_class: 'N' },
    { word: 'rhyme', word_class: 'V' },
    { word: 'rhymed', word_class: 'AJ' },
    { word: 'rhyming', word_class: 'AJ' }
  ],
  idealization: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  reprobate: [
    { word: 'reprobate', word_class: 'N' },
    { word: 'reprobate', word_class: 'V' },
    { word: 'reprobate', word_class: 'AJ' },
    { word: 'reprobation', word_class: 'N' }
  ],
  oxidized: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  discompose: [
    { word: 'discompose', word_class: 'V' },
    { word: 'discomposed', word_class: 'AJ' },
    { word: 'discomposure', word_class: 'N' }
  ],
  native: [
    { word: 'native', word_class: 'N' },
    { word: 'native', word_class: 'AJ' },
    { word: 'nativism', word_class: 'N' },
    { word: 'nativity', word_class: 'N' },
    { word: 'nativeness', word_class: 'N' }
  ],
  prosecution: [
    { word: 'prosecute', word_class: 'V' },
    { word: 'prosecutor', word_class: 'N' },
    { word: 'prosecution', word_class: 'N' }
  ],
  attempted: [
    { word: 'attempt', word_class: 'N' },
    { word: 'attempt', word_class: 'V' },
    { word: 'attempted', word_class: 'AJ' },
    { word: 'attemptable', word_class: 'AJ' }
  ],
  triviality: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  congratulations: [
    { word: 'congratulate', word_class: 'V' },
    { word: 'congratulation', word_class: 'N' },
    { word: 'congratulations', word_class: 'N' }
  ],
  heaps: [
    { word: 'heap', word_class: 'N' },
    { word: 'heap', word_class: 'V' },
    { word: 'heaps', word_class: 'N' },
    { word: 'heaps', word_class: 'AV' },
    { word: 'heaped', word_class: 'AJ' }
  ],
  stocks: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  undesirous: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  linguistically: [
    { word: 'linguist', word_class: 'N' },
    { word: 'linguistic', word_class: 'AJ' },
    { word: 'linguistics', word_class: 'N' },
    { word: 'linguistically', word_class: 'AV' }
  ],
  abusive: [
    { word: 'abuse', word_class: 'N' },
    { word: 'abuse', word_class: 'V' },
    { word: 'abuser', word_class: 'N' },
    { word: 'abused', word_class: 'AJ' },
    { word: 'abusive', word_class: 'AJ' },
    { word: 'abusively', word_class: 'AV' }
  ],
  reactivation: [
    { word: 'reactive', word_class: 'AJ' },
    { word: 'reactivate', word_class: 'V' },
    { word: 'reactivity', word_class: 'N' },
    { word: 'reactivation', word_class: 'N' }
  ],
  malleable: [
    { word: 'malleable', word_class: 'AJ' },
    { word: 'malleably', word_class: 'AV' },
    { word: 'malleability', word_class: 'N' }
  ],
  toss: [{ word: 'toss', word_class: 'N' }, { word: 'toss', word_class: 'V' }, { word: 'tossing', word_class: 'AJ' }],
  tenaciously: [
    { word: 'tenacious', word_class: 'AJ' },
    { word: 'tenaciously', word_class: 'AV' },
    { word: 'tenaciousness', word_class: 'N' }
  ],
  lubricated: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  sharpened: [
    { word: 'sharpen', word_class: 'V' },
    { word: 'sharpener', word_class: 'N' },
    { word: 'sharpened', word_class: 'AJ' }
  ],
  lee: [{ word: 'lee', word_class: 'N' }, { word: 'lee', word_class: 'AJ' }, { word: 'lees', word_class: 'N' }],
  enunciable: [
    { word: 'enunciate', word_class: 'V' },
    { word: 'enunciable', word_class: 'AJ' },
    { word: 'enunciation', word_class: 'N' }
  ],
  evolvement: [
    { word: 'evolve', word_class: 'V' },
    { word: 'evolution', word_class: 'N' },
    { word: 'evolvement', word_class: 'N' },
    { word: 'evolutionary', word_class: 'AJ' }
  ],
  architecture: [
    { word: 'architecture', word_class: 'N' },
    { word: 'architectural', word_class: 'AJ' },
    { word: 'architecturally', word_class: 'AV' }
  ],
  system: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  naturalism: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  gift: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  bail: [{ word: 'bail', word_class: 'N' }, { word: 'bail', word_class: 'V' }, { word: 'bailment', word_class: 'N' }],
  subscribe: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  hunter: [
    { word: 'hunt', word_class: 'N' },
    { word: 'hunt', word_class: 'V' },
    { word: 'hunter', word_class: 'N' },
    { word: 'hunted', word_class: 'AJ' },
    { word: 'hunting', word_class: 'N' }
  ],
  physiologic: [
    { word: 'physiology', word_class: 'N' },
    { word: 'physiologic', word_class: 'AJ' },
    { word: 'physiological', word_class: 'AJ' },
    { word: 'physiologically', word_class: 'AV' }
  ],
  fosterage: [
    { word: 'foster', word_class: 'V' },
    { word: 'foster', word_class: 'AJ' },
    { word: 'fosterage', word_class: 'N' },
    { word: 'fostered', word_class: 'AJ' },
    { word: 'fostering', word_class: 'N' }
  ],
  coursing: [
    { word: 'course', word_class: 'N' },
    { word: 'course', word_class: 'V' },
    { word: 'course', word_class: 'AV' },
    { word: 'courser', word_class: 'N' },
    { word: 'coursing', word_class: 'N' }
  ],
  sketch: [
    { word: 'sketch', word_class: 'N' },
    { word: 'sketch', word_class: 'V' },
    { word: 'sketcher', word_class: 'N' }
  ],
  conic: [
    { word: 'conic', word_class: 'N' },
    { word: 'conic', word_class: 'AJ' },
    { word: 'conical', word_class: 'AJ' },
    { word: 'conically', word_class: 'AV' }
  ],
  tramping: [
    { word: 'tramp', word_class: 'N' },
    { word: 'tramp', word_class: 'V' },
    { word: 'tramping', word_class: 'AJ' }
  ],
  deceptiveness: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  latch: [
    { word: 'latch', word_class: 'N' },
    { word: 'latch', word_class: 'V' },
    { word: 'latched', word_class: 'AJ' }
  ],
  countermovement: [
    { word: 'countermove', word_class: 'N' },
    { word: 'countermove', word_class: 'V' },
    { word: 'countermovement', word_class: 'N' }
  ],
  snap: [{ word: 'snap', word_class: 'N' }, { word: 'snap', word_class: 'V' }, { word: 'snapping', word_class: 'AJ' }],
  perforate: [
    { word: 'perforate', word_class: 'V' },
    { word: 'perforate', word_class: 'AJ' },
    { word: 'perforated', word_class: 'AJ' },
    { word: 'perforation', word_class: 'N' }
  ],
  collapsable: [
    { word: 'collapse', word_class: 'N' },
    { word: 'collapse', word_class: 'V' },
    { word: 'collapsed', word_class: 'AJ' },
    { word: 'collapsable', word_class: 'AJ' },
    { word: 'collapsible', word_class: 'AJ' }
  ],
  retrench: [
    { word: 'retrench', word_class: 'N' },
    { word: 'retrench', word_class: 'V' },
    { word: 'retrenchment', word_class: 'N' }
  ],
  cyclonal: [
    { word: 'cyclone', word_class: 'N' },
    { word: 'cyclonal', word_class: 'AJ' },
    { word: 'cyclonic', word_class: 'AJ' },
    { word: 'cyclonical', word_class: 'AJ' }
  ],
  understated: [
    { word: 'understate', word_class: 'V' },
    { word: 'understated', word_class: 'AJ' },
    { word: 'understatement', word_class: 'N' }
  ],
  furtively: [
    { word: 'furtive', word_class: 'AJ' },
    { word: 'furtively', word_class: 'AV' },
    { word: 'furtiveness', word_class: 'N' }
  ],
  inaction: [
    { word: 'inaction', word_class: 'N' },
    { word: 'inactive', word_class: 'AJ' },
    { word: 'inactivity', word_class: 'N' },
    { word: 'inactiveness', word_class: 'N' }
  ],
  expulsion: [
    { word: 'expel', word_class: 'V' },
    { word: 'expell', word_class: 'V' },
    { word: 'expelling', word_class: 'N' },
    { word: 'expulsion', word_class: 'N' }
  ],
  banger: [
    { word: 'bang', word_class: 'V' },
    { word: 'bang', word_class: 'N' },
    { word: 'bang', word_class: 'AV' },
    { word: 'banger', word_class: 'N' },
    { word: 'banging', word_class: 'N' },
    { word: 'banging', word_class: 'AJ' }
  ],
  appreciate: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  ecologically: [
    { word: 'ecology', word_class: 'N' },
    { word: 'ecologic', word_class: 'AJ' },
    { word: 'ecological', word_class: 'AJ' },
    { word: 'ecologically', word_class: 'AV' }
  ],
  promiscuous: [
    { word: 'promiscuity', word_class: 'N' },
    { word: 'promiscuous', word_class: 'AJ' },
    { word: 'promiscuously', word_class: 'AV' },
    { word: 'promiscuousness', word_class: 'N' }
  ],
  bespectacled: [
    { word: 'spectacle', word_class: 'N' },
    { word: 'spectacles', word_class: 'N' },
    { word: 'spectacled', word_class: 'AJ' },
    { word: 'bespectacled', word_class: 'AJ' }
  ],
  decentralized: [
    { word: 'decentralize', word_class: 'V' },
    { word: 'decentralized', word_class: 'AJ' },
    { word: 'decentralizing', word_class: 'AJ' },
    { word: 'decentralization', word_class: 'N' }
  ],
  antic: [{ word: 'antic', word_class: 'N' }, { word: 'antic', word_class: 'V' }, { word: 'antic', word_class: 'AJ' }],
  apparent: [
    { word: 'apparency', word_class: 'N' },
    { word: 'apparent', word_class: 'AJ' },
    { word: 'apparently', word_class: 'AV' },
    { word: 'apparentness', word_class: 'N' }
  ],
  impacted: [
    { word: 'impact', word_class: 'N' },
    { word: 'impact', word_class: 'V' },
    { word: 'impacted', word_class: 'AJ' },
    { word: 'impaction', word_class: 'N' }
  ],
  export: [
    { word: 'export', word_class: 'N' },
    { word: 'export', word_class: 'V' },
    { word: 'exporter', word_class: 'N' },
    { word: 'exporting', word_class: 'N' },
    { word: 'exportable', word_class: 'AJ' },
    { word: 'exportation', word_class: 'N' }
  ],
  compelling: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  squandered: [
    { word: 'squander', word_class: 'V' },
    { word: 'squanderer', word_class: 'N' },
    { word: 'squandered', word_class: 'AJ' },
    { word: 'squandering', word_class: 'N' }
  ],
  volatilize: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  facilitation: [
    { word: 'facilitate', word_class: 'V' },
    { word: 'facilitator', word_class: 'N' },
    { word: 'facilitated', word_class: 'AJ' },
    { word: 'facilitation', word_class: 'N' },
    { word: 'facilitative', word_class: 'AJ' }
  ],
  central: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  invisibleness: [
    { word: 'invisible', word_class: 'AJ' },
    { word: 'invisibly', word_class: 'AV' },
    { word: 'invisibility', word_class: 'N' },
    { word: 'invisibleness', word_class: 'N' }
  ],
  conductor: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  neutralization: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  ageing: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  accented: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  detoxication: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  allowance: [
    { word: 'allow', word_class: 'V' },
    { word: 'allowance', word_class: 'N' },
    { word: 'allowance', word_class: 'V' },
    { word: 'allowable', word_class: 'AJ' },
    { word: 'allowably', word_class: 'AV' }
  ],
  mimicking: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  southernness: [
    { word: 'southern', word_class: 'AJ' },
    { word: 'southerner', word_class: 'N' },
    { word: 'southernism', word_class: 'N' },
    { word: 'southernness', word_class: 'N' }
  ],
  exhilarate: [
    { word: 'exhilarate', word_class: 'V' },
    { word: 'exhilarated', word_class: 'AJ' },
    { word: 'exhilaration', word_class: 'N' },
    { word: 'exhilarating', word_class: 'AJ' }
  ],
  shrift: [
    { word: 'shrift', word_class: 'N' },
    { word: 'shrive', word_class: 'V' },
    { word: 'shriven', word_class: 'AJ' }
  ],
  logistical: [
    { word: 'logistic', word_class: 'AJ' },
    { word: 'logistics', word_class: 'N' },
    { word: 'logistical', word_class: 'AJ' }
  ],
  regenerate: [
    { word: 'regenerate', word_class: 'V' },
    { word: 'regenerate', word_class: 'AJ' },
    { word: 'regeneration', word_class: 'N' },
    { word: 'regenerating', word_class: 'AJ' }
  ],
  candidacy: [
    { word: 'candid', word_class: 'AJ' },
    { word: 'candidacy', word_class: 'N' },
    { word: 'candidate', word_class: 'N' },
    { word: 'candidness', word_class: 'N' }
  ],
  appalling: [
    { word: 'appal', word_class: 'V' },
    { word: 'appall', word_class: 'V' },
    { word: 'appalled', word_class: 'AJ' },
    { word: 'appalling', word_class: 'N' },
    { word: 'appalling', word_class: 'AJ' }
  ],
  nose: [
    { word: 'nose', word_class: 'N' },
    { word: 'nose', word_class: 'V' },
    { word: 'nosy', word_class: 'AJ' },
    { word: 'nosed', word_class: 'AJ' },
    { word: 'nosiness', word_class: 'N' }
  ],
  etch: [
    { word: 'etch', word_class: 'N' },
    { word: 'etch', word_class: 'V' },
    { word: 'etcher', word_class: 'N' },
    { word: 'etched', word_class: 'AJ' },
    { word: 'etching', word_class: 'N' }
  ],
  fungicidal: [
    { word: 'fungus', word_class: 'N' },
    { word: 'fungal', word_class: 'AJ' },
    { word: 'fungicide', word_class: 'N' },
    { word: 'fungicidal', word_class: 'AJ' }
  ],
  vatful: [{ word: 'vat', word_class: 'N' }, { word: 'vatful', word_class: 'N' }, { word: 'vatful', word_class: 'AJ' }],
  strategics: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  diagnosable: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  photographer: [
    { word: 'photograph', word_class: 'N' },
    { word: 'photograph', word_class: 'V' },
    { word: 'photographer', word_class: 'N' },
    { word: 'photographic', word_class: 'AJ' },
    { word: 'photographically', word_class: 'AV' }
  ],
  rotatable: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  deform: [
    { word: 'deform', word_class: 'V' },
    { word: 'deformed', word_class: 'AJ' },
    { word: 'deformity', word_class: 'N' },
    { word: 'deformation', word_class: 'N' },
    { word: 'deformational', word_class: 'AJ' }
  ],
  derat: [{ word: 'derat', word_class: 'V' }, { word: 'derate', word_class: 'N' }, { word: 'derate', word_class: 'V' }],
  betraying: [
    { word: 'betray', word_class: 'V' },
    { word: 'betrayal', word_class: 'N' },
    { word: 'betrayer', word_class: 'N' },
    { word: 'betraying', word_class: 'AJ' }
  ],
  precariousness: [
    { word: 'precarious', word_class: 'AJ' },
    { word: 'precariously', word_class: 'AV' },
    { word: 'precariousness', word_class: 'N' }
  ],
  checkers: [
    { word: 'check', word_class: 'N' },
    { word: 'check', word_class: 'V' },
    { word: 'checker', word_class: 'N' },
    { word: 'checker', word_class: 'V' },
    { word: 'checked', word_class: 'AJ' },
    { word: 'checkers', word_class: 'N' },
    { word: 'checkered', word_class: 'AJ' }
  ],
  catoptrical: [
    { word: 'catoptric', word_class: 'AJ' },
    { word: 'catoptrics', word_class: 'N' },
    { word: 'catoptrical', word_class: 'AJ' }
  ],
  condescending: [
    { word: 'condescend', word_class: 'V' },
    { word: 'condescension', word_class: 'N' },
    { word: 'condescendence', word_class: 'N' },
    { word: 'condescending', word_class: 'AJ' }
  ],
  contaminated: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  trimer: [
    { word: 'trim', word_class: 'N' },
    { word: 'trim', word_class: 'V' },
    { word: 'trim', word_class: 'AJ' },
    { word: 'trimer', word_class: 'N' },
    { word: 'trimmed', word_class: 'AJ' },
    { word: 'trimming', word_class: 'N' },
    { word: 'trimness', word_class: 'N' }
  ],
  frills: [
    { word: 'frill', word_class: 'N' },
    { word: 'frills', word_class: 'N' },
    { word: 'frilled', word_class: 'AJ' }
  ],
  freezing: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  methodically: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  marbleized: [
    { word: 'marbleize', word_class: 'V' },
    { word: 'marbleized', word_class: 'AJ' },
    { word: 'marbleizing', word_class: 'N' },
    { word: 'marbleization', word_class: 'N' }
  ],
  hurtling: [
    { word: 'hurtle', word_class: 'N' },
    { word: 'hurtle', word_class: 'V' },
    { word: 'hurtling', word_class: 'AJ' }
  ],
  apomict: [
    { word: 'apomict', word_class: 'N' },
    { word: 'apomictic', word_class: 'AJ' },
    { word: 'apomictical', word_class: 'AJ' }
  ],
  ways: [
    { word: 'way', word_class: 'N' },
    { word: 'way', word_class: 'AV' },
    { word: 'ways', word_class: 'N' },
    { word: 'ways', word_class: 'AV' }
  ],
  corroded: [
    { word: 'corrode', word_class: 'V' },
    { word: 'corroded', word_class: 'AJ' },
    { word: 'corroding', word_class: 'N' },
    { word: 'corrosion', word_class: 'N' },
    { word: 'corrosive', word_class: 'N' },
    { word: 'corrosive', word_class: 'AJ' }
  ],
  traced: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  unfavourably: [
    { word: 'unfavourable', word_class: 'AJ' },
    { word: 'unfavourably', word_class: 'AV' },
    { word: 'unfavourableness', word_class: 'N' }
  ],
  squanderer: [
    { word: 'squander', word_class: 'V' },
    { word: 'squanderer', word_class: 'N' },
    { word: 'squandered', word_class: 'AJ' },
    { word: 'squandering', word_class: 'N' }
  ],
  blueness: [
    { word: 'blue', word_class: 'N' },
    { word: 'blue', word_class: 'V' },
    { word: 'blue', word_class: 'AJ' },
    { word: 'blues', word_class: 'N' },
    { word: 'blueing', word_class: 'N' },
    { word: 'blueness', word_class: 'N' }
  ],
  altruist: [
    { word: 'altruist', word_class: 'N' },
    { word: 'altruistic', word_class: 'AJ' },
    { word: 'altruistically', word_class: 'AV' }
  ],
  persist: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  energetically: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  rejoice: [
    { word: 'rejoice', word_class: 'V' },
    { word: 'rejoicing', word_class: 'N' },
    { word: 'rejoicing', word_class: 'AJ' }
  ],
  urging: [
    { word: 'urge', word_class: 'N' },
    { word: 'urge', word_class: 'V' },
    { word: 'urging', word_class: 'N' },
    { word: 'urgency', word_class: 'N' }
  ],
  articulative: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  candidness: [
    { word: 'candid', word_class: 'AJ' },
    { word: 'candidacy', word_class: 'N' },
    { word: 'candidate', word_class: 'N' },
    { word: 'candidness', word_class: 'N' }
  ],
  penalise: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  blossom: [
    { word: 'blossom', word_class: 'N' },
    { word: 'blossom', word_class: 'V' },
    { word: 'blossoming', word_class: 'N' }
  ],
  monopolization: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  mastered: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  article: [
    { word: 'article', word_class: 'N' },
    { word: 'article', word_class: 'V' },
    { word: 'articled', word_class: 'AJ' }
  ],
  requirement: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  andante: [
    { word: 'andante', word_class: 'N' },
    { word: 'andante', word_class: 'AJ' },
    { word: 'andante', word_class: 'AV' }
  ],
  finalist: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  runny: [
    { word: 'run', word_class: 'N' },
    { word: 'run', word_class: 'V' },
    { word: 'run', word_class: 'AJ' },
    { word: 'runny', word_class: 'AJ' },
    { word: 'running', word_class: 'N' },
    { word: 'running', word_class: 'AJ' },
    { word: 'runniness', word_class: 'N' }
  ],
  embroilment: [
    { word: 'embroil', word_class: 'V' },
    { word: 'embroiled', word_class: 'AJ' },
    { word: 'embroilment', word_class: 'N' }
  ],
  procure: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  eaglet: [{ word: 'eagle', word_class: 'N' }, { word: 'eagle', word_class: 'V' }, { word: 'eaglet', word_class: 'N' }],
  jounce: [
    { word: 'jounce', word_class: 'N' },
    { word: 'jounce', word_class: 'V' },
    { word: 'jouncing', word_class: 'AJ' }
  ],
  minor: [
    { word: 'minor', word_class: 'AJ' },
    { word: 'minors', word_class: 'N' },
    { word: 'minority', word_class: 'N' }
  ],
  naming: [
    { word: 'name', word_class: 'N' },
    { word: 'name', word_class: 'V' },
    { word: 'names', word_class: 'N' },
    { word: 'named', word_class: 'AJ' },
    { word: 'naming', word_class: 'N' },
    { word: 'namely', word_class: 'AV' },
    { word: 'naming', word_class: 'AJ' }
  ],
  ungulate: [
    { word: 'ungulate', word_class: 'N' },
    { word: 'ungulate', word_class: 'AJ' },
    { word: 'ungulated', word_class: 'AJ' }
  ],
  cognitively: [
    { word: 'cognition', word_class: 'N' },
    { word: 'cognitive', word_class: 'AJ' },
    { word: 'cognitively', word_class: 'AV' }
  ],
  integrally: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  pictured: [
    { word: 'picture', word_class: 'N' },
    { word: 'picture', word_class: 'V' },
    { word: 'pictural', word_class: 'AJ' },
    { word: 'pictured', word_class: 'AJ' },
    { word: 'picturing', word_class: 'N' }
  ],
  expound: [
    { word: 'expound', word_class: 'N' },
    { word: 'expound', word_class: 'V' },
    { word: 'expounding', word_class: 'N' }
  ],
  audibleness: [
    { word: 'audible', word_class: 'AJ' },
    { word: 'audibly', word_class: 'AV' },
    { word: 'audibility', word_class: 'N' },
    { word: 'audibleness', word_class: 'N' }
  ],
  loser: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  quarantined: [
    { word: 'quarantine', word_class: 'N' },
    { word: 'quarantine', word_class: 'V' },
    { word: 'quarantined', word_class: 'AJ' }
  ],
  manner: [
    { word: 'manner', word_class: 'N' },
    { word: 'manners', word_class: 'N' },
    { word: 'mannered', word_class: 'AJ' },
    { word: 'mannerism', word_class: 'N' }
  ],
  optative: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  polymorphic: [
    { word: 'polymorph', word_class: 'N' },
    { word: 'polymorphic', word_class: 'AJ' },
    { word: 'polymorphism', word_class: 'N' },
    { word: 'polymorphous', word_class: 'AJ' }
  ],
  scaling: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  reflected: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  degustation: [
    { word: 'degust', word_class: 'V' },
    { word: 'degustate', word_class: 'V' },
    { word: 'degustation', word_class: 'N' }
  ],
  riddle: [
    { word: 'riddle', word_class: 'N' },
    { word: 'riddle', word_class: 'V' },
    { word: 'riddled', word_class: 'AJ' }
  ],
  incompatibility: [
    { word: 'incompatible', word_class: 'AJ' },
    { word: 'incompatibly', word_class: 'AV' },
    { word: 'incompatibility', word_class: 'N' }
  ],
  deepening: [
    { word: 'deepen', word_class: 'V' },
    { word: 'deepened', word_class: 'AJ' },
    { word: 'deepening', word_class: 'N' },
    { word: 'deepening', word_class: 'AJ' }
  ],
  enveloped: [
    { word: 'envelop', word_class: 'V' },
    { word: 'envelope', word_class: 'N' },
    { word: 'envelope', word_class: 'V' },
    { word: 'enveloped', word_class: 'AJ' },
    { word: 'enveloping', word_class: 'N' },
    { word: 'enveloping', word_class: 'AJ' },
    { word: 'envelopment', word_class: 'N' }
  ],
  fallaciousness: [
    { word: 'fallacy', word_class: 'N' },
    { word: 'fallacious', word_class: 'AJ' },
    { word: 'fallaciousness', word_class: 'N' }
  ],
  transmission: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  turbulency: [
    { word: 'turbulence', word_class: 'N' },
    { word: 'turbulency', word_class: 'N' },
    { word: 'turbulent', word_class: 'AJ' },
    { word: 'turbulently', word_class: 'AV' }
  ],
  beneficially: [
    { word: 'beneficial', word_class: 'AJ' },
    { word: 'beneficially', word_class: 'AV' },
    { word: 'beneficiation', word_class: 'N' }
  ],
  perversive: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  altering: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  surrogation: [
    { word: 'surrogate', word_class: 'N' },
    { word: 'surrogate', word_class: 'V' },
    { word: 'surrogate', word_class: 'AJ' },
    { word: 'surrogation', word_class: 'N' }
  ],
  forebode: [
    { word: 'forebode', word_class: 'V' },
    { word: 'foreboding', word_class: 'N' },
    { word: 'foreboding', word_class: 'AJ' }
  ],
  astrological: [
    { word: 'astrology', word_class: 'N' },
    { word: 'astrologer', word_class: 'N' },
    { word: 'astrological', word_class: 'AJ' }
  ],
  flavoring: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  disconsolateness: [
    { word: 'disconsole', word_class: 'V' },
    { word: 'disconsolate', word_class: 'AJ' },
    { word: 'disconsolately', word_class: 'AV' },
    { word: 'disconsolateness', word_class: 'N' }
  ],
  inflexible: [
    { word: 'inflexible', word_class: 'AJ' },
    { word: 'inflexibly', word_class: 'AV' },
    { word: 'inflexibility', word_class: 'N' },
    { word: 'inflexibleness', word_class: 'N' }
  ],
  ignore: [
    { word: 'ignore', word_class: 'V' },
    { word: 'ignorant', word_class: 'N' },
    { word: 'ignored', word_class: 'AJ' },
    { word: 'ignorance', word_class: 'N' },
    { word: 'ignorant', word_class: 'AJ' },
    { word: 'ignorantness', word_class: 'N' }
  ],
  tolerant: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  loathing: [
    { word: 'loath', word_class: 'V' },
    { word: 'loath', word_class: 'AJ' },
    { word: 'loathe', word_class: 'V' },
    { word: 'loathing', word_class: 'N' },
    { word: 'loathsome', word_class: 'AJ' },
    { word: 'loathsomeness', word_class: 'N' }
  ],
  processed: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  yelp: [{ word: 'yelp', word_class: 'N' }, { word: 'yelp', word_class: 'V' }, { word: 'yelping', word_class: 'N' }],
  shivering: [
    { word: 'shiver', word_class: 'N' },
    { word: 'shiver', word_class: 'V' },
    { word: 'shivering', word_class: 'N' },
    { word: 'shivering', word_class: 'AJ' }
  ],
  riverine: [
    { word: 'rive', word_class: 'V' },
    { word: 'river', word_class: 'N' },
    { word: 'riverine', word_class: 'AJ' }
  ],
  western: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  hurrying: [
    { word: 'hurry', word_class: 'N' },
    { word: 'hurry', word_class: 'V' },
    { word: 'hurried', word_class: 'AJ' },
    { word: 'hurrying', word_class: 'N' },
    { word: 'hurrying', word_class: 'AJ' }
  ],
  gnarled: [
    { word: 'gnarl', word_class: 'N' },
    { word: 'gnarl', word_class: 'V' },
    { word: 'gnarled', word_class: 'AJ' }
  ],
  parachute: [
    { word: 'parachute', word_class: 'N' },
    { word: 'parachute', word_class: 'V' },
    { word: 'parachuter', word_class: 'N' },
    { word: 'parachuting', word_class: 'N' },
    { word: 'parachutist', word_class: 'N' }
  ],
  rubric: [
    { word: 'rubric', word_class: 'N' },
    { word: 'rubric', word_class: 'V' },
    { word: 'rubricate', word_class: 'V' },
    { word: 'rubrication', word_class: 'N' }
  ],
  ignobly: [
    { word: 'ignoble', word_class: 'AJ' },
    { word: 'ignobly', word_class: 'AV' },
    { word: 'ignobleness', word_class: 'N' }
  ],
  mispronouncement: [
    { word: 'mispronounce', word_class: 'V' },
    { word: 'mispronouncement', word_class: 'N' },
    { word: 'mispronunciation', word_class: 'N' }
  ],
  interpretable: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  piper: [
    { word: 'pipe', word_class: 'N' },
    { word: 'pipe', word_class: 'V' },
    { word: 'piper', word_class: 'N' },
    { word: 'pipes', word_class: 'N' },
    { word: 'piping', word_class: 'N' },
    { word: 'pipeful', word_class: 'N' },
    { word: 'piping', word_class: 'AJ' },
    { word: 'piping', word_class: 'AV' },
    { word: 'pipeful', word_class: 'AJ' }
  ],
  candid: [
    { word: 'candid', word_class: 'AJ' },
    { word: 'candidacy', word_class: 'N' },
    { word: 'candidate', word_class: 'N' },
    { word: 'candidness', word_class: 'N' }
  ],
  unenlightened: [
    { word: 'unenlightened', word_class: 'AJ' },
    { word: 'unenlightening', word_class: 'AJ' },
    { word: 'unenlightenment', word_class: 'N' }
  ],
  folksy: [
    { word: 'folk', word_class: 'N' },
    { word: 'folk', word_class: 'AJ' },
    { word: 'folks', word_class: 'N' },
    { word: 'folksy', word_class: 'AJ' }
  ],
  strip: [
    { word: 'strip', word_class: 'N' },
    { word: 'strip', word_class: 'V' },
    { word: 'strip', word_class: 'AJ' },
    { word: 'striper', word_class: 'N' },
    { word: 'stripped', word_class: 'AJ' },
    { word: 'stripping', word_class: 'N' }
  ],
  soft: [{ word: 'soft', word_class: 'AJ' }, { word: 'soft', word_class: 'AV' }, { word: 'softness', word_class: 'N' }],
  feel: [
    { word: 'feel', word_class: 'N' },
    { word: 'feel', word_class: 'V' },
    { word: 'feeler', word_class: 'N' },
    { word: 'feeling', word_class: 'N' },
    { word: 'feelings', word_class: 'N' }
  ],
  timer: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  unjustifiable: [
    { word: 'unjustified', word_class: 'AJ' },
    { word: 'unjustifiable', word_class: 'AJ' },
    { word: 'unjustifiably', word_class: 'AV' }
  ],
  ebonics: [
    { word: 'ebon', word_class: 'AJ' },
    { word: 'ebonics', word_class: 'N' },
    { word: 'ebonize', word_class: 'V' },
    { word: 'ebonization', word_class: 'N' }
  ],
  fetishism: [
    { word: 'fetish', word_class: 'N' },
    { word: 'fetish', word_class: 'AJ' },
    { word: 'fetishism', word_class: 'N' }
  ],
  tonsure: [
    { word: 'tonsure', word_class: 'N' },
    { word: 'tonsure', word_class: 'V' },
    { word: 'tonsured', word_class: 'AJ' }
  ],
  metaphoric: [
    { word: 'metaphor', word_class: 'N' },
    { word: 'metaphoric', word_class: 'AJ' },
    { word: 'metaphorical', word_class: 'AJ' },
    { word: 'metaphorically', word_class: 'AV' }
  ],
  recognisable: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  synergy: [
    { word: 'synergy', word_class: 'N' },
    { word: 'synergism', word_class: 'N' },
    { word: 'synergist', word_class: 'N' },
    { word: 'synergistic', word_class: 'AJ' }
  ],
  scheming: [
    { word: 'scheme', word_class: 'N' },
    { word: 'scheme', word_class: 'V' },
    { word: 'schemer', word_class: 'N' },
    { word: 'scheming', word_class: 'AJ' }
  ],
  burglar: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  circuitous: [
    { word: 'circuit', word_class: 'N' },
    { word: 'circuit', word_class: 'V' },
    { word: 'circuitous', word_class: 'AJ' }
  ],
  sheet: [
    { word: 'sheet', word_class: 'N' },
    { word: 'sheet', word_class: 'V' },
    { word: 'sheeting', word_class: 'N' }
  ],
  lapse: [
    { word: 'lapse', word_class: 'N' },
    { word: 'lapse', word_class: 'V' },
    { word: 'lapsed', word_class: 'AJ' },
    { word: 'lapsing', word_class: 'N' }
  ],
  mind: [
    { word: 'mind', word_class: 'N' },
    { word: 'mind', word_class: 'V' },
    { word: 'minder', word_class: 'N' },
    { word: 'minded', word_class: 'AJ' },
    { word: 'mindful', word_class: 'AJ' },
    { word: 'mindfulness', word_class: 'N' }
  ],
  reputation: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  revers: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  penny: [{ word: 'penni', word_class: 'N' }, { word: 'penny', word_class: 'N' }, { word: 'penny', word_class: 'AJ' }],
  unreadably: [
    { word: 'unread', word_class: 'AJ' },
    { word: 'unreadable', word_class: 'AJ' },
    { word: 'unreadably', word_class: 'AV' }
  ],
  actual: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  touched: [
    { word: 'touch', word_class: 'N' },
    { word: 'touch', word_class: 'V' },
    { word: 'touched', word_class: 'AJ' },
    { word: 'touching', word_class: 'N' },
    { word: 'touching', word_class: 'AJ' }
  ],
  felt: [
    { word: 'felt', word_class: 'N' },
    { word: 'felt', word_class: 'V' },
    { word: 'felt', word_class: 'AJ' },
    { word: 'felted', word_class: 'AJ' }
  ],
  ostracization: [
    { word: 'ostracism', word_class: 'N' },
    { word: 'ostracize', word_class: 'V' },
    { word: 'ostracization', word_class: 'N' }
  ],
  listing: [
    { word: 'list', word_class: 'N' },
    { word: 'list', word_class: 'V' },
    { word: 'lister', word_class: 'N' },
    { word: 'listed', word_class: 'AJ' },
    { word: 'listing', word_class: 'N' }
  ],
  invigilation: [
    { word: 'invigilate', word_class: 'V' },
    { word: 'invigilator', word_class: 'N' },
    { word: 'invigilation', word_class: 'N' }
  ],
  disgraced: [
    { word: 'disgrace', word_class: 'N' },
    { word: 'disgrace', word_class: 'V' },
    { word: 'disgraced', word_class: 'AJ' },
    { word: 'disgraceful', word_class: 'AJ' },
    { word: 'disgracefulness', word_class: 'N' }
  ],
  surge: [
    { word: 'surge', word_class: 'N' },
    { word: 'surge', word_class: 'V' },
    { word: 'surging', word_class: 'AJ' }
  ],
  daintiness: [
    { word: 'dainty', word_class: 'N' },
    { word: 'dainty', word_class: 'AJ' },
    { word: 'daintiness', word_class: 'N' }
  ],
  confidant: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  redemptive: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  wholly: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  sunday: [
    { word: 'sunday', word_class: 'N' },
    { word: 'sunday', word_class: 'V' },
    { word: 'sunday', word_class: 'AJ' }
  ],
  troopship: [
    { word: 'troop', word_class: 'N' },
    { word: 'troop', word_class: 'V' },
    { word: 'troops', word_class: 'N' },
    { word: 'trooper', word_class: 'N' },
    { word: 'troopship', word_class: 'N' }
  ],
  grizzle: [
    { word: 'grizzle', word_class: 'N' },
    { word: 'grizzle', word_class: 'V' },
    { word: 'grizzled', word_class: 'AJ' }
  ],
  inseminated: [
    { word: 'inseminate', word_class: 'V' },
    { word: 'inseminated', word_class: 'AJ' },
    { word: 'insemination', word_class: 'N' }
  ],
  keratinisation: [
    { word: 'keratin', word_class: 'N' },
    { word: 'keratinise', word_class: 'V' },
    { word: 'keratinize', word_class: 'V' },
    { word: 'keratinisation', word_class: 'N' },
    { word: 'keratinization', word_class: 'N' }
  ],
  littleness: [
    { word: 'little', word_class: 'N' },
    { word: 'little', word_class: 'AJ' },
    { word: 'little', word_class: 'AV' },
    { word: 'littleness', word_class: 'N' }
  ],
  tenability: [
    { word: 'tenable', word_class: 'AJ' },
    { word: 'tenably', word_class: 'AV' },
    { word: 'tenability', word_class: 'N' },
    { word: 'tenableness', word_class: 'N' }
  ],
  belt: [
    { word: 'belt', word_class: 'N' },
    { word: 'belt', word_class: 'V' },
    { word: 'belted', word_class: 'AJ' },
    { word: 'belting', word_class: 'N' }
  ],
  nonviolently: [
    { word: 'nonviolence', word_class: 'N' },
    { word: 'nonviolent', word_class: 'AJ' },
    { word: 'nonviolently', word_class: 'AV' }
  ],
  iceman: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  slitted: [
    { word: 'slit', word_class: 'N' },
    { word: 'slit', word_class: 'V' },
    { word: 'slit', word_class: 'AJ' },
    { word: 'slitted', word_class: 'AJ' }
  ],
  lively: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  comfortableness: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  pleasureful: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  watch: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  brazenness: [
    { word: 'brazen', word_class: 'V' },
    { word: 'brazen', word_class: 'AJ' },
    { word: 'brazenness', word_class: 'N' }
  ],
  wooer: [{ word: 'woo', word_class: 'V' }, { word: 'wooer', word_class: 'N' }, { word: 'wooing', word_class: 'N' }],
  elective: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  pleasantry: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  vacationer: [
    { word: 'vacate', word_class: 'V' },
    { word: 'vacation', word_class: 'N' },
    { word: 'vacation', word_class: 'V' },
    { word: 'vacationer', word_class: 'N' },
    { word: 'vacationing', word_class: 'N' },
    { word: 'vacationist', word_class: 'N' }
  ],
  foreordained: [
    { word: 'foreordain', word_class: 'V' },
    { word: 'foreordained', word_class: 'AJ' },
    { word: 'foreordainment', word_class: 'N' },
    { word: 'foreordination', word_class: 'N' }
  ],
  replicable: [
    { word: 'replicate', word_class: 'V' },
    { word: 'replicable', word_class: 'AJ' },
    { word: 'replication', word_class: 'N' }
  ],
  exclusionary: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  freewheel: [
    { word: 'freewheel', word_class: 'V' },
    { word: 'freewheeler', word_class: 'N' },
    { word: 'freewheeling', word_class: 'AJ' }
  ],
  risking: [{ word: 'risk', word_class: 'N' }, { word: 'risk', word_class: 'V' }, { word: 'risking', word_class: 'N' }],
  bisect: [
    { word: 'bisect', word_class: 'N' },
    { word: 'bisect', word_class: 'V' },
    { word: 'bisection', word_class: 'N' },
    { word: 'bisectional', word_class: 'AJ' }
  ],
  homeward: [
    { word: 'homeward', word_class: 'AJ' },
    { word: 'homeward', word_class: 'AV' },
    { word: 'homewards', word_class: 'AV' }
  ],
  hostel: [
    { word: 'hostel', word_class: 'N' },
    { word: 'hostelry', word_class: 'N' },
    { word: 'hosteller', word_class: 'N' }
  ],
  hopeful: [
    { word: 'hope', word_class: 'N' },
    { word: 'hope', word_class: 'V' },
    { word: 'hopeful', word_class: 'N' },
    { word: 'hopeful', word_class: 'AJ' },
    { word: 'hopefulness', word_class: 'N' }
  ],
  building: [
    { word: 'build', word_class: 'N' },
    { word: 'build', word_class: 'V' },
    { word: 'builder', word_class: 'N' },
    { word: 'building', word_class: 'N' },
    { word: 'building', word_class: 'AJ' }
  ],
  hide: [
    { word: 'hide', word_class: 'N' },
    { word: 'hide', word_class: 'V' },
    { word: 'hiding', word_class: 'N' },
    { word: 'hidden', word_class: 'AJ' }
  ],
  detribalization: [
    { word: 'detribalise', word_class: 'V' },
    { word: 'detribalize', word_class: 'V' },
    { word: 'detribalisation', word_class: 'N' },
    { word: 'detribalization', word_class: 'N' }
  ],
  murderousness: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  munition: [
    { word: 'munition', word_class: 'N' },
    { word: 'munition', word_class: 'V' },
    { word: 'munitions', word_class: 'N' }
  ],
  astronomy: [
    { word: 'astronomy', word_class: 'N' },
    { word: 'astronomer', word_class: 'N' },
    { word: 'astronomic', word_class: 'AJ' },
    { word: 'astronomical', word_class: 'AJ' },
    { word: 'astronomically', word_class: 'AV' }
  ],
  enslaving: [
    { word: 'enslave', word_class: 'V' },
    { word: 'enslaved', word_class: 'AJ' },
    { word: 'enslaving', word_class: 'N' },
    { word: 'enslavement', word_class: 'N' }
  ],
  semiotics: [
    { word: 'semiotic', word_class: 'AJ' },
    { word: 'semiotics', word_class: 'N' },
    { word: 'semiotical', word_class: 'AJ' }
  ],
  noisy: [
    { word: 'noise', word_class: 'N' },
    { word: 'noise', word_class: 'V' },
    { word: 'noisy', word_class: 'AJ' },
    { word: 'noisiness', word_class: 'N' }
  ],
  liked: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  fording: [{ word: 'ford', word_class: 'N' }, { word: 'ford', word_class: 'V' }, { word: 'fording', word_class: 'N' }],
  material: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  atheistic: [
    { word: 'atheist', word_class: 'N' },
    { word: 'atheist', word_class: 'AJ' },
    { word: 'atheistic', word_class: 'AJ' },
    { word: 'atheistical', word_class: 'AJ' }
  ],
  contemporaries: [
    { word: 'contemporary', word_class: 'N' },
    { word: 'contemporary', word_class: 'AJ' },
    { word: 'contemporaries', word_class: 'N' }
  ],
  relieved: [
    { word: 'relief', word_class: 'N' },
    { word: 'relieve', word_class: 'V' },
    { word: 'reliever', word_class: 'N' },
    { word: 'relieved', word_class: 'AJ' }
  ],
  ailing: [
    { word: 'ail', word_class: 'N' },
    { word: 'ail', word_class: 'V' },
    { word: 'ailing', word_class: 'AJ' },
    { word: 'ailment', word_class: 'N' }
  ],
  piercing: [
    { word: 'pierce', word_class: 'N' },
    { word: 'pierce', word_class: 'V' },
    { word: 'pierced', word_class: 'AJ' },
    { word: 'piercing', word_class: 'AJ' }
  ],
  loquaciousness: [
    { word: 'loquacious', word_class: 'AJ' },
    { word: 'loquaciously', word_class: 'AV' },
    { word: 'loquaciousness', word_class: 'N' }
  ],
  unbend: [
    { word: 'unbend', word_class: 'V' },
    { word: 'unbending', word_class: 'AJ' },
    { word: 'unbendable', word_class: 'AJ' }
  ],
  perambulation: [
    { word: 'perambulate', word_class: 'V' },
    { word: 'perambulator', word_class: 'N' },
    { word: 'perambulation', word_class: 'N' },
    { word: 'perambulating', word_class: 'AJ' }
  ],
  booties: [
    { word: 'booty', word_class: 'N' },
    { word: 'bootie', word_class: 'N' },
    { word: 'booty', word_class: 'AJ' },
    { word: 'booties', word_class: 'N' }
  ],
  impeccant: [
    { word: 'impeccant', word_class: 'AJ' },
    { word: 'impeccable', word_class: 'AJ' },
    { word: 'impeccably', word_class: 'AV' }
  ],
  disconnect: [
    { word: 'disconnect', word_class: 'V' },
    { word: 'disconnexion', word_class: 'N' },
    { word: 'disconnected', word_class: 'AJ' },
    { word: 'disconnection', word_class: 'N' }
  ],
  mold: [
    { word: 'mold', word_class: 'N' },
    { word: 'mold', word_class: 'V' },
    { word: 'molded', word_class: 'AJ' },
    { word: 'molding', word_class: 'N' }
  ],
  obsessivity: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  enhancement: [
    { word: 'enhance', word_class: 'V' },
    { word: 'enhanced', word_class: 'AJ' },
    { word: 'enhancive', word_class: 'AJ' },
    { word: 'enhancement', word_class: 'N' }
  ],
  ungulated: [
    { word: 'ungulate', word_class: 'N' },
    { word: 'ungulate', word_class: 'AJ' },
    { word: 'ungulated', word_class: 'AJ' }
  ],
  surcease: [
    { word: 'surcease', word_class: 'N' },
    { word: 'surcease', word_class: 'V' },
    { word: 'surcession', word_class: 'N' }
  ],
  troubled: [
    { word: 'trouble', word_class: 'N' },
    { word: 'trouble', word_class: 'V' },
    { word: 'troubled', word_class: 'AJ' },
    { word: 'troubling', word_class: 'N' },
    { word: 'troubling', word_class: 'AJ' },
    { word: 'troublesome', word_class: 'AJ' },
    { word: 'troublesomeness', word_class: 'N' }
  ],
  noticed: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  basin: [
    { word: 'basin', word_class: 'N' },
    { word: 'basinal', word_class: 'AJ' },
    { word: 'basined', word_class: 'AJ' },
    { word: 'basinful', word_class: 'N' }
  ],
  territorialize: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  problem: [
    { word: 'problem', word_class: 'N' },
    { word: 'problematic', word_class: 'AJ' },
    { word: 'problematical', word_class: 'AJ' },
    { word: 'problematically', word_class: 'AV' }
  ],
  ace: [
    { word: 'ac', word_class: 'N' },
    { word: 'ace', word_class: 'N' },
    { word: 'ace', word_class: 'V' },
    { word: 'ace', word_class: 'AJ' }
  ],
  edge: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  objectiveness: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  disbelieve: [
    { word: 'disbelief', word_class: 'N' },
    { word: 'disbelieve', word_class: 'V' },
    { word: 'disbeliever', word_class: 'N' },
    { word: 'disbelieving', word_class: 'AJ' }
  ],
  winker: [
    { word: 'wink', word_class: 'N' },
    { word: 'wink', word_class: 'V' },
    { word: 'winker', word_class: 'N' },
    { word: 'winking', word_class: 'AJ' }
  ],
  rightist: [
    { word: 'right', word_class: 'V' },
    { word: 'right', word_class: 'N' },
    { word: 'right', word_class: 'AJ' },
    { word: 'right', word_class: 'AV' },
    { word: 'rightist', word_class: 'N' },
    { word: 'rightful', word_class: 'AJ' },
    { word: 'rightist', word_class: 'AJ' },
    { word: 'rightness', word_class: 'N' },
    { word: 'rightfulness', word_class: 'N' }
  ],
  fixative: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  harmonical: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  motivate: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  amort: [
    { word: 'amort', word_class: 'AJ' },
    { word: 'amortize', word_class: 'V' },
    { word: 'amortization', word_class: 'N' }
  ],
  snarl: [
    { word: 'snarl', word_class: 'N' },
    { word: 'snarl', word_class: 'V' },
    { word: 'snarled', word_class: 'AJ' }
  ],
  divinise: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  music: [
    { word: 'music', word_class: 'N' },
    { word: 'musical', word_class: 'N' },
    { word: 'musical', word_class: 'AJ' },
    { word: 'musicality', word_class: 'N' },
    { word: 'musically', word_class: 'AV' },
    { word: 'musicalness', word_class: 'N' }
  ],
  sharpen: [
    { word: 'sharpen', word_class: 'V' },
    { word: 'sharpener', word_class: 'N' },
    { word: 'sharpened', word_class: 'AJ' }
  ],
  controvert: [
    { word: 'controvert', word_class: 'V' },
    { word: 'controversy', word_class: 'N' },
    { word: 'controversion', word_class: 'N' },
    { word: 'controversial', word_class: 'AJ' },
    { word: 'controversially', word_class: 'AV' }
  ],
  fluorination: [
    { word: 'fluorine', word_class: 'N' },
    { word: 'fluorinate', word_class: 'V' },
    { word: 'fluorination', word_class: 'N' }
  ],
  stink: [
    { word: 'stink', word_class: 'N' },
    { word: 'stink', word_class: 'V' },
    { word: 'stinker', word_class: 'N' },
    { word: 'stinking', word_class: 'AJ' }
  ],
  carminative: [
    { word: 'carmine', word_class: 'N' },
    { word: 'carmine', word_class: 'V' },
    { word: 'carmine', word_class: 'AJ' },
    { word: 'carminative', word_class: 'N' },
    { word: 'carminative', word_class: 'AJ' }
  ],
  jumper: [
    { word: 'jump', word_class: 'N' },
    { word: 'jump', word_class: 'V' },
    { word: 'jumper', word_class: 'N' },
    { word: 'jumpers', word_class: 'N' },
    { word: 'jumping', word_class: 'N' },
    { word: 'jumping', word_class: 'AJ' }
  ],
  kaleidoscope: [
    { word: 'kaleidoscope', word_class: 'N' },
    { word: 'kaleidoscopic', word_class: 'AJ' },
    { word: 'kaleidoscopical', word_class: 'AJ' }
  ],
  drapery: [
    { word: 'drape', word_class: 'N' },
    { word: 'drape', word_class: 'V' },
    { word: 'draper', word_class: 'N' },
    { word: 'draped', word_class: 'AJ' },
    { word: 'drapery', word_class: 'N' },
    { word: 'drapery', word_class: 'AJ' }
  ],
  illumine: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  unimpressed: [
    { word: 'unimpressed', word_class: 'AJ' },
    { word: 'unimpressive', word_class: 'AJ' },
    { word: 'unimpressively', word_class: 'AV' }
  ],
  inflamed: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  unnaturally: [
    { word: 'unnatural', word_class: 'AJ' },
    { word: 'unnaturally', word_class: 'AV' },
    { word: 'unnaturalness', word_class: 'N' },
    { word: 'unnaturalized', word_class: 'AJ' }
  ],
  expedition: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  demotion: [
    { word: 'demote', word_class: 'V' },
    { word: 'demotic', word_class: 'N' },
    { word: 'demotic', word_class: 'AJ' },
    { word: 'demotion', word_class: 'N' }
  ],
  mysterious: [
    { word: 'mystery', word_class: 'N' },
    { word: 'mysterious', word_class: 'AJ' },
    { word: 'mysteriously', word_class: 'AV' }
  ],
  vocalisation: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  transposition: [
    { word: 'transpose', word_class: 'N' },
    { word: 'transpose', word_class: 'V' },
    { word: 'transposed', word_class: 'AJ' },
    { word: 'transposition', word_class: 'N' },
    { word: 'transposability', word_class: 'N' }
  ],
  cyclicity: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  lading: [
    { word: 'lade', word_class: 'V' },
    { word: 'laden', word_class: 'V' },
    { word: 'laden', word_class: 'AJ' },
    { word: 'lading', word_class: 'N' },
    { word: 'ladened', word_class: 'AJ' }
  ],
  compounding: [
    { word: 'compound', word_class: 'N' },
    { word: 'compound', word_class: 'V' },
    { word: 'compound', word_class: 'AJ' },
    { word: 'compounded', word_class: 'AJ' },
    { word: 'compounding', word_class: 'N' }
  ],
  disarrangement: [
    { word: 'disarrange', word_class: 'V' },
    { word: 'disarranged', word_class: 'AJ' },
    { word: 'disarrangement', word_class: 'N' }
  ],
  reproduction: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  framing: [
    { word: 'frame', word_class: 'N' },
    { word: 'frame', word_class: 'V' },
    { word: 'framer', word_class: 'N' },
    { word: 'frames', word_class: 'V' },
    { word: 'framed', word_class: 'AJ' },
    { word: 'framing', word_class: 'N' }
  ],
  attestation: [
    { word: 'attest', word_class: 'V' },
    { word: 'attestant', word_class: 'N' },
    { word: 'attested', word_class: 'AJ' },
    { word: 'attestation', word_class: 'N' }
  ],
  imagination: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  lettering: [
    { word: 'letter', word_class: 'N' },
    { word: 'letter', word_class: 'V' },
    { word: 'letters', word_class: 'N' },
    { word: 'lettered', word_class: 'AJ' },
    { word: 'lettering', word_class: 'N' },
    { word: 'letterman', word_class: 'N' }
  ],
  remediation: [
    { word: 'remedy', word_class: 'N' },
    { word: 'remedy', word_class: 'V' },
    { word: 'remedial', word_class: 'AJ' },
    { word: 'remediable', word_class: 'AJ' },
    { word: 'remediation', word_class: 'N' }
  ],
  nebulously: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  dissent: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  pertain: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  heap: [
    { word: 'heap', word_class: 'N' },
    { word: 'heap', word_class: 'V' },
    { word: 'heaps', word_class: 'N' },
    { word: 'heaps', word_class: 'AV' },
    { word: 'heaped', word_class: 'AJ' }
  ],
  responsibly: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  incorruption: [
    { word: 'incorrupt', word_class: 'AJ' },
    { word: 'incorruption', word_class: 'N' },
    { word: 'incorruptness', word_class: 'N' },
    { word: 'incorruptible', word_class: 'AJ' },
    { word: 'incorruptibility', word_class: 'N' }
  ],
  observed: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  pessimist: [
    { word: 'pessimist', word_class: 'N' },
    { word: 'pessimistic', word_class: 'AJ' },
    { word: 'pessimistically', word_class: 'AV' }
  ],
  feigned: [
    { word: 'feign', word_class: 'V' },
    { word: 'feigned', word_class: 'AJ' },
    { word: 'feigning', word_class: 'N' }
  ],
  motile: [
    { word: 'motile', word_class: 'N' },
    { word: 'motile', word_class: 'AJ' },
    { word: 'motility', word_class: 'N' }
  ],
  pacify: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  indecisively: [
    { word: 'indecision', word_class: 'N' },
    { word: 'indecisive', word_class: 'AJ' },
    { word: 'indecisively', word_class: 'AV' },
    { word: 'indecisiveness', word_class: 'N' }
  ],
  sprinkled: [
    { word: 'sprinkle', word_class: 'N' },
    { word: 'sprinkle', word_class: 'V' },
    { word: 'sprinkler', word_class: 'N' },
    { word: 'sprinkles', word_class: 'N' },
    { word: 'sprinkled', word_class: 'AJ' },
    { word: 'sprinkling', word_class: 'N' }
  ],
  mellowed: [
    { word: 'mellow', word_class: 'V' },
    { word: 'mellow', word_class: 'AJ' },
    { word: 'mellow', word_class: 'AV' },
    { word: 'mellowed', word_class: 'AJ' },
    { word: 'mellowing', word_class: 'N' },
    { word: 'mellowing', word_class: 'AJ' },
    { word: 'mellowness', word_class: 'N' }
  ],
  implacably: [
    { word: 'implacate', word_class: 'V' },
    { word: 'implacable', word_class: 'AJ' },
    { word: 'implacably', word_class: 'AV' }
  ],
  habituate: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  truncated: [
    { word: 'truncate', word_class: 'V' },
    { word: 'truncate', word_class: 'AJ' },
    { word: 'truncated', word_class: 'AJ' },
    { word: 'truncation', word_class: 'N' }
  ],
  charitableness: [
    { word: 'charitable', word_class: 'AJ' },
    { word: 'charitably', word_class: 'AV' },
    { word: 'charitableness', word_class: 'N' }
  ],
  hydrolyze: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  zillions: [
    { word: 'zillion', word_class: 'N' },
    { word: 'zillion', word_class: 'AJ' },
    { word: 'zillions', word_class: 'N' }
  ],
  correct: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  genetical: [
    { word: 'genet', word_class: 'N' },
    { word: 'genetic', word_class: 'AJ' },
    { word: 'genetics', word_class: 'N' },
    { word: 'genetical', word_class: 'AJ' },
    { word: 'genetically', word_class: 'AV' }
  ],
  scaly: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  vacuolate: [
    { word: 'vacuole', word_class: 'N' },
    { word: 'vacuolate', word_class: 'AJ' },
    { word: 'vacuolated', word_class: 'AJ' },
    { word: 'vacuolation', word_class: 'N' },
    { word: 'vacuolization', word_class: 'N' }
  ],
  judaization: [
    { word: 'judaise', word_class: 'V' },
    { word: 'judaize', word_class: 'V' },
    { word: 'judaisation', word_class: 'N' },
    { word: 'judaization', word_class: 'N' }
  ],
  shrivel: [
    { word: 'shrivel', word_class: 'V' },
    { word: 'shriveled', word_class: 'AJ' },
    { word: 'shrivelled', word_class: 'AJ' }
  ],
  hypnotisation: [
    { word: 'hypnotise', word_class: 'V' },
    { word: 'hypnotised', word_class: 'AJ' },
    { word: 'hypnotisation', word_class: 'N' }
  ],
  violation: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  commemorate: [
    { word: 'commemorate', word_class: 'V' },
    { word: 'commemoration', word_class: 'N' },
    { word: 'commemorating', word_class: 'AJ' },
    { word: 'commemorative', word_class: 'AJ' }
  ],
  fated: [
    { word: 'fate', word_class: 'N' },
    { word: 'fate', word_class: 'V' },
    { word: 'fates', word_class: 'N' },
    { word: 'fated', word_class: 'AJ' },
    { word: 'fateful', word_class: 'AJ' }
  ],
  constipate: [
    { word: 'constipate', word_class: 'V' },
    { word: 'constipated', word_class: 'AJ' },
    { word: 'constipation', word_class: 'N' },
    { word: 'constipating', word_class: 'AJ' }
  ],
  positivist: [
    { word: 'positivist', word_class: 'N' },
    { word: 'positivist', word_class: 'AJ' },
    { word: 'positivistic', word_class: 'AJ' }
  ],
  abduction: [
    { word: 'abduct', word_class: 'V' },
    { word: 'abductor', word_class: 'N' },
    { word: 'abducted', word_class: 'AJ' },
    { word: 'abduction', word_class: 'N' },
    { word: 'abducting', word_class: 'AJ' }
  ],
  absorbency: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  realness: [
    { word: 'real', word_class: 'N' },
    { word: 'real', word_class: 'AJ' },
    { word: 'real', word_class: 'AV' },
    { word: 'realness', word_class: 'N' }
  ],
  startling: [
    { word: 'startle', word_class: 'N' },
    { word: 'startle', word_class: 'V' },
    { word: 'startled', word_class: 'AJ' },
    { word: 'startling', word_class: 'AJ' }
  ],
  chemically: [
    { word: 'chemic', word_class: 'AJ' },
    { word: 'chemical', word_class: 'N' },
    { word: 'chemical', word_class: 'AJ' },
    { word: 'chemically', word_class: 'AV' }
  ],
  getting: [
    { word: 'get', word_class: 'V' },
    { word: 'getting', word_class: 'N' },
    { word: 'gotten', word_class: 'AJ' }
  ],
  mockery: [
    { word: 'mock', word_class: 'N' },
    { word: 'mock', word_class: 'V' },
    { word: 'mock', word_class: 'AJ' },
    { word: 'mocker', word_class: 'N' },
    { word: 'mockery', word_class: 'N' },
    { word: 'mockery', word_class: 'AJ' },
    { word: 'mocking', word_class: 'AJ' }
  ],
  refutation: [
    { word: 'refute', word_class: 'V' },
    { word: 'refuting', word_class: 'N' },
    { word: 'refutable', word_class: 'AJ' },
    { word: 'refutation', word_class: 'N' }
  ],
  spacing: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  geologic: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  suavely: [
    { word: 'suave', word_class: 'AJ' },
    { word: 'suavely', word_class: 'AV' },
    { word: 'suaveness', word_class: 'N' }
  ],
  recoverable: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  pipes: [
    { word: 'pipe', word_class: 'N' },
    { word: 'pipe', word_class: 'V' },
    { word: 'piper', word_class: 'N' },
    { word: 'pipes', word_class: 'N' },
    { word: 'piping', word_class: 'N' },
    { word: 'pipeful', word_class: 'N' },
    { word: 'piping', word_class: 'AJ' },
    { word: 'piping', word_class: 'AV' },
    { word: 'pipeful', word_class: 'AJ' }
  ],
  planetoid: [
    { word: 'planet', word_class: 'N' },
    { word: 'planetal', word_class: 'AJ' },
    { word: 'planetoid', word_class: 'N' },
    { word: 'planetary', word_class: 'AJ' }
  ],
  wiliness: [
    { word: 'wile', word_class: 'N' },
    { word: 'wily', word_class: 'AJ' },
    { word: 'wiliness', word_class: 'N' }
  ],
  shape: [
    { word: 'shape', word_class: 'N' },
    { word: 'shape', word_class: 'V' },
    { word: 'shaper', word_class: 'N' },
    { word: 'shaped', word_class: 'AJ' },
    { word: 'shaping', word_class: 'N' },
    { word: 'shapely', word_class: 'AJ' },
    { word: 'shaping', word_class: 'AJ' }
  ],
  fading: [
    { word: 'fade', word_class: 'N' },
    { word: 'fade', word_class: 'V' },
    { word: 'faded', word_class: 'AJ' },
    { word: 'fading', word_class: 'N' },
    { word: 'fading', word_class: 'AJ' },
    { word: 'fadeless', word_class: 'AJ' }
  ],
  criminalism: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  indiscriminate: [
    { word: 'indiscriminate', word_class: 'AJ' },
    { word: 'indiscriminately', word_class: 'AV' },
    { word: 'indiscriminating', word_class: 'AJ' }
  ],
  caustically: [
    { word: 'caustic', word_class: 'N' },
    { word: 'caustic', word_class: 'AJ' },
    { word: 'caustically', word_class: 'AV' }
  ],
  bather: [
    { word: 'bath', word_class: 'N' },
    { word: 'bath', word_class: 'V' },
    { word: 'bathe', word_class: 'N' },
    { word: 'bathe', word_class: 'V' },
    { word: 'bather', word_class: 'N' },
    { word: 'bathing', word_class: 'N' }
  ],
  prosperity: [
    { word: 'prosper', word_class: 'V' },
    { word: 'prosperity', word_class: 'N' },
    { word: 'prospering', word_class: 'AJ' },
    { word: 'prosperous', word_class: 'AJ' },
    { word: 'prosperously', word_class: 'AV' }
  ],
  orate: [
    { word: 'orate', word_class: 'V' },
    { word: 'orator', word_class: 'N' },
    { word: 'oration', word_class: 'N' }
  ],
  patched: [
    { word: 'patch', word_class: 'N' },
    { word: 'patch', word_class: 'V' },
    { word: 'patched', word_class: 'AJ' },
    { word: 'patching', word_class: 'N' }
  ],
  divizive: [
    { word: 'divizive', word_class: 'AJ' },
    { word: 'divizible', word_class: 'AJ' },
    { word: 'divizibility', word_class: 'N' }
  ],
  translator: [
    { word: 'translate', word_class: 'V' },
    { word: 'translate', word_class: 'AJ' },
    { word: 'translator', word_class: 'N' },
    { word: 'translation', word_class: 'N' },
    { word: 'translatable', word_class: 'AJ' },
    { word: 'translational', word_class: 'AJ' }
  ],
  thermostat: [
    { word: 'thermostat', word_class: 'N' },
    { word: 'thermostat', word_class: 'V' },
    { word: 'thermostatic', word_class: 'AJ' },
    { word: 'thermostatics', word_class: 'N' },
    { word: 'thermostatically', word_class: 'AV' }
  ],
  certificated: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  syntactic: [
    { word: 'syntax', word_class: 'N' },
    { word: 'syntactic', word_class: 'AJ' },
    { word: 'syntactical', word_class: 'AJ' },
    { word: 'syntactically', word_class: 'AV' }
  ],
  uttered: [
    { word: 'utter', word_class: 'V' },
    { word: 'utter', word_class: 'AJ' },
    { word: 'uttered', word_class: 'AJ' },
    { word: 'utterance', word_class: 'N' },
    { word: 'utterable', word_class: 'AJ' }
  ],
  disliked: [
    { word: 'dislike', word_class: 'N' },
    { word: 'dislike', word_class: 'V' },
    { word: 'disliked', word_class: 'AJ' },
    { word: 'dislikable', word_class: 'AJ' }
  ],
  venture: [
    { word: 'venture', word_class: 'N' },
    { word: 'venture', word_class: 'V' },
    { word: 'venturous', word_class: 'AJ' },
    { word: 'venturesome', word_class: 'AJ' },
    { word: 'venturesomeness', word_class: 'N' }
  ],
  tutelage: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  fertilizer: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  snogging: [
    { word: 'snog', word_class: 'N' },
    { word: 'snog', word_class: 'V' },
    { word: 'snogging', word_class: 'N' }
  ],
  licked: [
    { word: 'lick', word_class: 'N' },
    { word: 'lick', word_class: 'V' },
    { word: 'licked', word_class: 'AJ' },
    { word: 'licking', word_class: 'N' }
  ],
  troubling: [
    { word: 'trouble', word_class: 'N' },
    { word: 'trouble', word_class: 'V' },
    { word: 'troubled', word_class: 'AJ' },
    { word: 'troubling', word_class: 'N' },
    { word: 'troubling', word_class: 'AJ' },
    { word: 'troublesome', word_class: 'AJ' },
    { word: 'troublesomeness', word_class: 'N' }
  ],
  buffeting: [
    { word: 'buffet', word_class: 'N' },
    { word: 'buffet', word_class: 'V' },
    { word: 'buffeted', word_class: 'AJ' },
    { word: 'buffeting', word_class: 'N' }
  ],
  depreciable: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  preferred: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  favour: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  seamed: [
    { word: 'seam', word_class: 'N' },
    { word: 'seam', word_class: 'V' },
    { word: 'seamed', word_class: 'AJ' },
    { word: 'seamstress', word_class: 'N' }
  ],
  tetanize: [
    { word: 'tetanise', word_class: 'V' },
    { word: 'tetanize', word_class: 'V' },
    { word: 'tetanisation', word_class: 'N' },
    { word: 'tetanization', word_class: 'N' }
  ],
  commendation: [
    { word: 'commend', word_class: 'V' },
    { word: 'commendable', word_class: 'AJ' },
    { word: 'commendable', word_class: 'AV' },
    { word: 'commendation', word_class: 'N' },
    { word: 'commendatory', word_class: 'AJ' }
  ],
  trekked: [
    { word: 'trek', word_class: 'N' },
    { word: 'trek', word_class: 'V' },
    { word: 'trekked', word_class: 'AJ' },
    { word: 'trekking', word_class: 'AJ' }
  ],
  nausea: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  kayo: [{ word: 'kayo', word_class: 'N' }, { word: 'kayo', word_class: 'V' }, { word: 'kayoed', word_class: 'AJ' }],
  exhortative: [
    { word: 'exhort', word_class: 'V' },
    { word: 'exhortation', word_class: 'N' },
    { word: 'exhortative', word_class: 'AJ' }
  ],
  conventionalized: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  rigorous: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  devalue: [
    { word: 'devalue', word_class: 'V' },
    { word: 'devaluate', word_class: 'V' },
    { word: 'devalued', word_class: 'AJ' },
    { word: 'devaluation', word_class: 'N' }
  ],
  proficient: [
    { word: 'proficiency', word_class: 'N' },
    { word: 'proficient', word_class: 'AJ' },
    { word: 'proficiently', word_class: 'AV' }
  ],
  vitriolically: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  cozie: [
    { word: 'cozy', word_class: 'N' },
    { word: 'cozie', word_class: 'N' },
    { word: 'cozy', word_class: 'AJ' },
    { word: 'coziness', word_class: 'N' }
  ],
  victory: [
    { word: 'victory', word_class: 'N' },
    { word: 'victory', word_class: 'AJ' },
    { word: 'victorious', word_class: 'AJ' },
    { word: 'victoriously', word_class: 'AV' }
  ],
  swinger: [
    { word: 'swing', word_class: 'N' },
    { word: 'swing', word_class: 'V' },
    { word: 'swinge', word_class: 'V' },
    { word: 'swinger', word_class: 'N' },
    { word: 'swinging', word_class: 'N' },
    { word: 'swinging', word_class: 'AJ' },
    { word: 'swingeing', word_class: 'AJ' }
  ],
  jugglery: [
    { word: 'juggle', word_class: 'N' },
    { word: 'juggle', word_class: 'V' },
    { word: 'juggler', word_class: 'N' },
    { word: 'jugglery', word_class: 'N' },
    { word: 'juggling', word_class: 'N' }
  ],
  bats: [
    { word: 'bat', word_class: 'N' },
    { word: 'bat', word_class: 'V' },
    { word: 'bats', word_class: 'AJ' },
    { word: 'batman', word_class: 'N' },
    { word: 'batting', word_class: 'N' }
  ],
  secureness: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  guarded: [
    { word: 'guard', word_class: 'N' },
    { word: 'guard', word_class: 'V' },
    { word: 'guarded', word_class: 'AJ' },
    { word: 'guarding', word_class: 'N' }
  ],
  nonstop: [
    { word: 'nonstop', word_class: 'N' },
    { word: 'nonstop', word_class: 'AJ' },
    { word: 'nonstop', word_class: 'AV' }
  ],
  excrescent: [
    { word: 'excrescent', word_class: 'N' },
    { word: 'excrescence', word_class: 'N' },
    { word: 'excrescent', word_class: 'AJ' }
  ],
  pounder: [
    { word: 'pound', word_class: 'N' },
    { word: 'pound', word_class: 'V' },
    { word: 'poundal', word_class: 'N' },
    { word: 'pounder', word_class: 'N' },
    { word: 'pounding', word_class: 'N' }
  ],
  racer: [
    { word: 'race', word_class: 'N' },
    { word: 'race', word_class: 'V' },
    { word: 'racer', word_class: 'N' },
    { word: 'racism', word_class: 'N' },
    { word: 'racing', word_class: 'N' },
    { word: 'racist', word_class: 'N' },
    { word: 'racist', word_class: 'AJ' }
  ],
  physicality: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  restrictiveness: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  barbarously: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  tantalise: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  protruding: [
    { word: 'protrude', word_class: 'V' },
    { word: 'protrusion', word_class: 'N' },
    { word: 'protruding', word_class: 'AJ' },
    { word: 'protrusive', word_class: 'AJ' },
    { word: 'protrusible', word_class: 'AJ' }
  ],
  glacially: [
    { word: 'glacier', word_class: 'N' },
    { word: 'glacial', word_class: 'AJ' },
    { word: 'glacially', word_class: 'AV' }
  ],
  munificently: [
    { word: 'munificence', word_class: 'N' },
    { word: 'munificent', word_class: 'AJ' },
    { word: 'munificently', word_class: 'AV' }
  ],
  danger: [
    { word: 'danger', word_class: 'N' },
    { word: 'dangerous', word_class: 'AJ' },
    { word: 'dangerously', word_class: 'AV' },
    { word: 'dangerousness', word_class: 'N' }
  ],
  repelling: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  hurdle: [
    { word: 'hurdle', word_class: 'N' },
    { word: 'hurdle', word_class: 'V' },
    { word: 'hurdler', word_class: 'N' },
    { word: 'hurdles', word_class: 'N' },
    { word: 'hurdling', word_class: 'N' }
  ],
  indictment: [
    { word: 'indict', word_class: 'V' },
    { word: 'indiction', word_class: 'N' },
    { word: 'indictment', word_class: 'N' },
    { word: 'indictable', word_class: 'AJ' },
    { word: 'indictability', word_class: 'N' }
  ],
  stability: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  prescribed: [
    { word: 'prescribe', word_class: 'N' },
    { word: 'prescribe', word_class: 'V' },
    { word: 'prescript', word_class: 'N' },
    { word: 'prescript', word_class: 'V' },
    { word: 'prescript', word_class: 'AJ' },
    { word: 'prescribed', word_class: 'AJ' },
    { word: 'prescription', word_class: 'N' },
    { word: 'prescription', word_class: 'AJ' },
    { word: 'prescriptive', word_class: 'AJ' }
  ],
  farrowing: [
    { word: 'farrow', word_class: 'N' },
    { word: 'farrow', word_class: 'V' },
    { word: 'farrowing', word_class: 'N' }
  ],
  hirsutism: [
    { word: 'hirsute', word_class: 'AJ' },
    { word: 'hirsutism', word_class: 'N' },
    { word: 'hirsuteness', word_class: 'N' }
  ],
  propitious: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  punctuality: [
    { word: 'punctual', word_class: 'AJ' },
    { word: 'punctuality', word_class: 'N' },
    { word: 'punctually', word_class: 'AV' }
  ],
  sloganeer: [
    { word: 'slogan', word_class: 'N' },
    { word: 'sloganeer', word_class: 'N' },
    { word: 'sloganeer', word_class: 'V' },
    { word: 'sloganeering', word_class: 'N' }
  ],
  horrify: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  faultfinding: [
    { word: 'faultfinder', word_class: 'N' },
    { word: 'faultfinding', word_class: 'N' },
    { word: 'faultfinding', word_class: 'AJ' }
  ],
  atrocious: [
    { word: 'atrocious', word_class: 'AJ' },
    { word: 'atrociously', word_class: 'AV' },
    { word: 'atrociousness', word_class: 'N' }
  ],
  essentially: [
    { word: 'essential', word_class: 'N' },
    { word: 'essential', word_class: 'AJ' },
    { word: 'essentiality', word_class: 'N' },
    { word: 'essentially', word_class: 'AV' },
    { word: 'essentialness', word_class: 'N' }
  ],
  premises: [
    { word: 'premise', word_class: 'N' },
    { word: 'premise', word_class: 'V' },
    { word: 'premises', word_class: 'N' }
  ],
  genuflection: [
    { word: 'genuflect', word_class: 'V' },
    { word: 'genuflexion', word_class: 'N' },
    { word: 'genuflection', word_class: 'N' }
  ],
  wages: [{ word: 'wage', word_class: 'N' }, { word: 'wage', word_class: 'V' }, { word: 'wages', word_class: 'N' }],
  detain: [
    { word: 'detain', word_class: 'V' },
    { word: 'detent', word_class: 'N' },
    { word: 'detente', word_class: 'N' },
    { word: 'detainee', word_class: 'N' },
    { word: 'detention', word_class: 'N' }
  ],
  coincidently: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  autochthonous: [
    { word: 'autochthonal', word_class: 'AJ' },
    { word: 'autochthonic', word_class: 'AJ' },
    { word: 'autochthonous', word_class: 'AJ' }
  ],
  sympathetic: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  attend: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  device: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  constipated: [
    { word: 'constipate', word_class: 'V' },
    { word: 'constipated', word_class: 'AJ' },
    { word: 'constipation', word_class: 'N' },
    { word: 'constipating', word_class: 'AJ' }
  ],
  traumatic: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  syncretise: [
    { word: 'syncretise', word_class: 'V' },
    { word: 'syncretize', word_class: 'V' },
    { word: 'syncretisation', word_class: 'N' },
    { word: 'syncretization', word_class: 'N' }
  ],
  purloined: [
    { word: 'purloin', word_class: 'V' },
    { word: 'purloined', word_class: 'AJ' },
    { word: 'purloinment', word_class: 'N' }
  ],
  alchemical: [
    { word: 'alchemic', word_class: 'AJ' },
    { word: 'alchemize', word_class: 'V' },
    { word: 'alchemical', word_class: 'AJ' },
    { word: 'alchemization', word_class: 'N' }
  ],
  foiled: [
    { word: 'foil', word_class: 'N' },
    { word: 'foil', word_class: 'V' },
    { word: 'foiled', word_class: 'AJ' },
    { word: 'foiling', word_class: 'N' }
  ],
  economizer: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  thirty: [
    { word: 'thirty', word_class: 'N' },
    { word: 'thirty', word_class: 'AJ' },
    { word: 'thirties', word_class: 'N' }
  ],
  refinance: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  concrete: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  habituation: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  chink: [
    { word: 'chink', word_class: 'N' },
    { word: 'chink', word_class: 'V' },
    { word: 'chinked', word_class: 'AJ' }
  ],
  therapy: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  impenetrableness: [
    { word: 'impenetrable', word_class: 'AJ' },
    { word: 'impenetrability', word_class: 'N' },
    { word: 'impenetrableness', word_class: 'N' }
  ],
  herder: [
    { word: 'herd', word_class: 'N' },
    { word: 'herd', word_class: 'V' },
    { word: 'herder', word_class: 'N' },
    { word: 'herding', word_class: 'AJ' }
  ],
  fashionably: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  apprehensible: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  glamour: [
    { word: 'glamour', word_class: 'N' },
    { word: 'glamour', word_class: 'V' },
    { word: 'glamourous', word_class: 'AJ' }
  ],
  validating: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  corticate: [
    { word: 'cortical', word_class: 'AJ' },
    { word: 'corticate', word_class: 'V' },
    { word: 'cortically', word_class: 'AV' },
    { word: 'cortication', word_class: 'N' }
  ],
  explore: [
    { word: 'explore', word_class: 'V' },
    { word: 'explorer', word_class: 'N' },
    { word: 'exploration', word_class: 'N' },
    { word: 'explorative', word_class: 'AJ' },
    { word: 'exploratory', word_class: 'AJ' }
  ],
  jailed: [
    { word: 'jail', word_class: 'N' },
    { word: 'jail', word_class: 'V' },
    { word: 'jailer', word_class: 'N' },
    { word: 'jailed', word_class: 'AJ' },
    { word: 'jailing', word_class: 'N' }
  ],
  parasitic: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  sloughing: [
    { word: 'slough', word_class: 'N' },
    { word: 'slough', word_class: 'V' },
    { word: 'sloughing', word_class: 'N' }
  ],
  easy: [{ word: 'easy', word_class: 'AJ' }, { word: 'easy', word_class: 'AV' }, { word: 'easiness', word_class: 'N' }],
  incinerate: [
    { word: 'incinerate', word_class: 'V' },
    { word: 'incinerator', word_class: 'N' },
    { word: 'incineration', word_class: 'N' }
  ],
  holograph: [
    { word: 'holograph', word_class: 'N' },
    { word: 'holograph', word_class: 'AJ' },
    { word: 'holographic', word_class: 'AJ' },
    { word: 'holographical', word_class: 'AJ' }
  ],
  idiomatical: [
    { word: 'idiomatic', word_class: 'AJ' },
    { word: 'idiomatical', word_class: 'AJ' },
    { word: 'idiomatically', word_class: 'AV' }
  ],
  wheeze: [
    { word: 'wheeze', word_class: 'N' },
    { word: 'wheeze', word_class: 'V' },
    { word: 'wheezy', word_class: 'AJ' },
    { word: 'wheezing', word_class: 'AJ' },
    { word: 'wheeziness', word_class: 'N' }
  ],
  geographics: [
    { word: 'geographer', word_class: 'N' },
    { word: 'geographic', word_class: 'AJ' },
    { word: 'geographics', word_class: 'N' },
    { word: 'geographical', word_class: 'AJ' },
    { word: 'geographically', word_class: 'AV' }
  ],
  catapult: [
    { word: 'catapult', word_class: 'N' },
    { word: 'catapult', word_class: 'V' },
    { word: 'catapultic', word_class: 'AJ' }
  ],
  slap: [
    { word: 'slap', word_class: 'N' },
    { word: 'slap', word_class: 'V' },
    { word: 'slap', word_class: 'AV' },
    { word: 'slapped', word_class: 'AJ' }
  ],
  illegalize: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  citification: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  federal: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  prosecute: [
    { word: 'prosecute', word_class: 'V' },
    { word: 'prosecutor', word_class: 'N' },
    { word: 'prosecution', word_class: 'N' }
  ],
  arched: [
    { word: 'arch', word_class: 'N' },
    { word: 'arch', word_class: 'V' },
    { word: 'arch', word_class: 'AJ' },
    { word: 'archer', word_class: 'N' },
    { word: 'arched', word_class: 'AJ' },
    { word: 'arching', word_class: 'AJ' },
    { word: 'archness', word_class: 'N' }
  ],
  holder: [
    { word: 'hold', word_class: 'N' },
    { word: 'hold', word_class: 'V' },
    { word: 'holder', word_class: 'N' },
    { word: 'holding', word_class: 'N' },
    { word: 'holding', word_class: 'AJ' }
  ],
  goddamn: [
    { word: 'goddamn', word_class: 'AJ' },
    { word: 'goddamn', word_class: 'AV' },
    { word: 'goddamned', word_class: 'AJ' },
    { word: 'goddamned', word_class: 'AV' }
  ],
  mercurialization: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  admire: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  nocturne: [
    { word: 'nocturne', word_class: 'N' },
    { word: 'nocturnal', word_class: 'AJ' },
    { word: 'nocturnally', word_class: 'AV' }
  ],
  honeyed: [
    { word: 'honey', word_class: 'N' },
    { word: 'honey', word_class: 'V' },
    { word: 'honey', word_class: 'AJ' },
    { word: 'honeyed', word_class: 'AJ' }
  ],
  erode: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  knocking: [
    { word: 'knock', word_class: 'N' },
    { word: 'knock', word_class: 'V' },
    { word: 'knocker', word_class: 'N' },
    { word: 'knocking', word_class: 'N' }
  ],
  training: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  resolve: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  profess: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  prohibit: [
    { word: 'prohibit', word_class: 'V' },
    { word: 'prohibition', word_class: 'N' },
    { word: 'prohibited', word_class: 'AJ' },
    { word: 'prohibitive', word_class: 'AJ' },
    { word: 'prohibitively', word_class: 'AV' }
  ],
  verbalized: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  reliever: [
    { word: 'relief', word_class: 'N' },
    { word: 'relieve', word_class: 'V' },
    { word: 'reliever', word_class: 'N' },
    { word: 'relieved', word_class: 'AJ' }
  ],
  hypersensitisation: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  vibration: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  grammatically: [
    { word: 'grammatic', word_class: 'AJ' },
    { word: 'grammatical', word_class: 'AJ' },
    { word: 'grammatically', word_class: 'AV' }
  ],
  tarrying: [
    { word: 'tarry', word_class: 'V' },
    { word: 'tarry', word_class: 'AJ' },
    { word: 'tarrying', word_class: 'N' },
    { word: 'tarriance', word_class: 'N' }
  ],
  liberalisation: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  antidote: [
    { word: 'antidote', word_class: 'N' },
    { word: 'antidote', word_class: 'V' },
    { word: 'antidotal', word_class: 'AJ' }
  ],
  testimonial: [
    { word: 'testimony', word_class: 'N' },
    { word: 'testimonial', word_class: 'N' },
    { word: 'testimonial', word_class: 'AJ' }
  ],
  carburation: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  canful: [
    { word: 'can', word_class: 'N' },
    { word: 'can', word_class: 'V' },
    { word: 'canful', word_class: 'N' },
    { word: 'canful', word_class: 'AJ' },
    { word: 'canned', word_class: 'AJ' },
    { word: 'cannery', word_class: 'N' },
    { word: 'canning', word_class: 'N' },
    { word: 'cannery', word_class: 'AJ' }
  ],
  lamentable: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  testiness: [
    { word: 'testis', word_class: 'N' },
    { word: 'testy', word_class: 'AJ' },
    { word: 'testiness', word_class: 'N' }
  ],
  decently: [
    { word: 'decent', word_class: 'AJ' },
    { word: 'decent', word_class: 'AV' },
    { word: 'decently', word_class: 'AV' }
  ],
  denaturant: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  attach: [
    { word: 'attach', word_class: 'V' },
    { word: 'attache', word_class: 'N' },
    { word: 'attached', word_class: 'AJ' },
    { word: 'attachment', word_class: 'N' },
    { word: 'attachable', word_class: 'AJ' }
  ],
  credited: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  farrow: [
    { word: 'farrow', word_class: 'N' },
    { word: 'farrow', word_class: 'V' },
    { word: 'farrowing', word_class: 'N' }
  ],
  bullying: [
    { word: 'bully', word_class: 'N' },
    { word: 'bully', word_class: 'V' },
    { word: 'bully', word_class: 'AJ' },
    { word: 'bullied', word_class: 'AJ' },
    { word: 'bullying', word_class: 'N' },
    { word: 'bullying', word_class: 'AJ' }
  ],
  tailored: [
    { word: 'tailor', word_class: 'N' },
    { word: 'tailor', word_class: 'V' },
    { word: 'tailored', word_class: 'AJ' },
    { word: 'tailoring', word_class: 'N' }
  ],
  stewing: [
    { word: 'stew', word_class: 'N' },
    { word: 'stew', word_class: 'V' },
    { word: 'stewed', word_class: 'AJ' },
    { word: 'stewing', word_class: 'N' }
  ],
  whirling: [
    { word: 'whirl', word_class: 'N' },
    { word: 'whirl', word_class: 'V' },
    { word: 'whirling', word_class: 'N' },
    { word: 'whirling', word_class: 'AJ' }
  ],
  leaden: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  persecutor: [
    { word: 'persecute', word_class: 'V' },
    { word: 'persecutor', word_class: 'N' },
    { word: 'persecuted', word_class: 'AJ' },
    { word: 'persecution', word_class: 'N' }
  ],
  vitiated: [
    { word: 'vitiate', word_class: 'V' },
    { word: 'vitiated', word_class: 'AJ' },
    { word: 'vitiation', word_class: 'N' }
  ],
  oxygenize: [
    { word: 'oxygen', word_class: 'N' },
    { word: 'oxygenate', word_class: 'V' },
    { word: 'oxygenize', word_class: 'V' },
    { word: 'oxygenated', word_class: 'AJ' },
    { word: 'oxygenation', word_class: 'N' }
  ],
  tangled: [
    { word: 'tangle', word_class: 'N' },
    { word: 'tangle', word_class: 'V' },
    { word: 'tangled', word_class: 'AJ' },
    { word: 'tanglement', word_class: 'N' }
  ],
  loaner: [{ word: 'loan', word_class: 'N' }, { word: 'loan', word_class: 'V' }, { word: 'loaner', word_class: 'N' }],
  encystation: [
    { word: 'encyst', word_class: 'V' },
    { word: 'encysted', word_class: 'AJ' },
    { word: 'encystment', word_class: 'N' },
    { word: 'encystation', word_class: 'N' }
  ],
  sanctify: [
    { word: 'sanctify', word_class: 'V' },
    { word: 'sanctified', word_class: 'AJ' },
    { word: 'sanctification', word_class: 'N' }
  ],
  cordiality: [
    { word: 'cordial', word_class: 'N' },
    { word: 'cordial', word_class: 'AJ' },
    { word: 'cordiality', word_class: 'N' },
    { word: 'cordially', word_class: 'AV' }
  ],
  zone: [{ word: 'zone', word_class: 'N' }, { word: 'zone', word_class: 'V' }, { word: 'zoning', word_class: 'N' }],
  pick: [
    { word: 'pick', word_class: 'N' },
    { word: 'pick', word_class: 'V' },
    { word: 'picker', word_class: 'N' },
    { word: 'picking', word_class: 'N' }
  ],
  extend: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  adducent: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  enlivening: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  greens: [
    { word: 'green', word_class: 'N' },
    { word: 'green', word_class: 'V' },
    { word: 'green', word_class: 'AJ' },
    { word: 'greens', word_class: 'N' },
    { word: 'greenery', word_class: 'N' },
    { word: 'greening', word_class: 'N' },
    { word: 'greening', word_class: 'AJ' },
    { word: 'greenness', word_class: 'N' }
  ],
  secretion: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  presently: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  incomprehension: [
    { word: 'incomprehension', word_class: 'N' },
    { word: 'incomprehensive', word_class: 'AJ' },
    { word: 'incomprehensible', word_class: 'AJ' },
    { word: 'incomprehensibility', word_class: 'N' }
  ],
  annexe: [
    { word: 'annex', word_class: 'N' },
    { word: 'annex', word_class: 'V' },
    { word: 'annexe', word_class: 'N' },
    { word: 'annexal', word_class: 'AJ' },
    { word: 'annexation', word_class: 'N' },
    { word: 'annexational', word_class: 'AJ' }
  ],
  curbed: [
    { word: 'curb', word_class: 'N' },
    { word: 'curb', word_class: 'V' },
    { word: 'curbed', word_class: 'AJ' },
    { word: 'curbing', word_class: 'N' }
  ],
  grayed: [
    { word: 'gray', word_class: 'N' },
    { word: 'gray', word_class: 'V' },
    { word: 'gray', word_class: 'AJ' },
    { word: 'grayed', word_class: 'AJ' },
    { word: 'grayness', word_class: 'N' }
  ],
  borrow: [
    { word: 'borrow', word_class: 'V' },
    { word: 'borrower', word_class: 'N' },
    { word: 'borrowing', word_class: 'N' }
  ],
  daze: [{ word: 'daze', word_class: 'N' }, { word: 'daze', word_class: 'V' }, { word: 'dazed', word_class: 'AJ' }],
  villainousness: [
    { word: 'villain', word_class: 'N' },
    { word: 'villainous', word_class: 'AJ' },
    { word: 'villainousness', word_class: 'N' }
  ],
  evolve: [
    { word: 'evolve', word_class: 'V' },
    { word: 'evolution', word_class: 'N' },
    { word: 'evolvement', word_class: 'N' },
    { word: 'evolutionary', word_class: 'AJ' }
  ],
  reprobation: [
    { word: 'reprobate', word_class: 'N' },
    { word: 'reprobate', word_class: 'V' },
    { word: 'reprobate', word_class: 'AJ' },
    { word: 'reprobation', word_class: 'N' }
  ],
  tested: [
    { word: 'test', word_class: 'N' },
    { word: 'test', word_class: 'V' },
    { word: 'test', word_class: 'AJ' },
    { word: 'testee', word_class: 'N' },
    { word: 'tester', word_class: 'N' },
    { word: 'tested', word_class: 'AJ' },
    { word: 'testing', word_class: 'N' }
  ],
  form: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  domine: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  campaign: [
    { word: 'campaign', word_class: 'N' },
    { word: 'campaign', word_class: 'V' },
    { word: 'campaigner', word_class: 'N' },
    { word: 'campaigning', word_class: 'N' }
  ],
  intrusiveness: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  invitee: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  ceramics: [
    { word: 'ceramic', word_class: 'N' },
    { word: 'ceramic', word_class: 'AJ' },
    { word: 'ceramics', word_class: 'N' }
  ],
  stolidness: [
    { word: 'stolid', word_class: 'AJ' },
    { word: 'stolidity', word_class: 'N' },
    { word: 'stolidness', word_class: 'N' }
  ],
  poleax: [
    { word: 'poleax', word_class: 'N' },
    { word: 'poleax', word_class: 'V' },
    { word: 'poleaxe', word_class: 'N' },
    { word: 'poleaxe', word_class: 'V' }
  ],
  pungent: [
    { word: 'pungency', word_class: 'N' },
    { word: 'pungent', word_class: 'AJ' },
    { word: 'pungently', word_class: 'AV' }
  ],
  forecasting: [
    { word: 'forecast', word_class: 'N' },
    { word: 'forecast', word_class: 'V' },
    { word: 'forecaster', word_class: 'N' },
    { word: 'forecasting', word_class: 'N' }
  ],
  incidentally: [
    { word: 'incidental', word_class: 'N' },
    { word: 'incidental', word_class: 'AJ' },
    { word: 'incidentally', word_class: 'AV' }
  ],
  vaporise: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  equip: [
    { word: 'equip', word_class: 'V' },
    { word: 'equipment', word_class: 'N' },
    { word: 'equipped', word_class: 'AJ' },
    { word: 'equipping', word_class: 'N' }
  ],
  penman: [
    { word: 'pen', word_class: 'V' },
    { word: 'pen', word_class: 'N' },
    { word: 'penman', word_class: 'N' },
    { word: 'penance', word_class: 'N' },
    { word: 'penance', word_class: 'V' },
    { word: 'penned', word_class: 'AJ' },
    { word: 'penning', word_class: 'N' }
  ],
  illegibility: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  structural: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  honorable: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  constrictor: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  mischievous: [
    { word: 'mischief', word_class: 'N' },
    { word: 'mischievous', word_class: 'AJ' },
    { word: 'mischievously', word_class: 'AV' },
    { word: 'mischievousness', word_class: 'N' }
  ],
  unequivocalness: [
    { word: 'unequivocal', word_class: 'AJ' },
    { word: 'unequivocally', word_class: 'AV' },
    { word: 'unequivocalness', word_class: 'N' }
  ],
  nerves: [
    { word: 'nerve', word_class: 'N' },
    { word: 'nerve', word_class: 'V' },
    { word: 'nerves', word_class: 'N' },
    { word: 'nervy', word_class: 'AJ' }
  ],
  raised: [
    { word: 'raise', word_class: 'N' },
    { word: 'raise', word_class: 'V' },
    { word: 'raiser', word_class: 'N' },
    { word: 'raised', word_class: 'AJ' },
    { word: 'raising', word_class: 'N' },
    { word: 'raising', word_class: 'AJ' }
  ],
  sedation: [
    { word: 'sedate', word_class: 'V' },
    { word: 'sedate', word_class: 'AJ' },
    { word: 'sedated', word_class: 'AJ' },
    { word: 'sedation', word_class: 'N' },
    { word: 'sedately', word_class: 'AV' },
    { word: 'sedateness', word_class: 'N' }
  ],
  coalesced: [
    { word: 'coalesce', word_class: 'V' },
    { word: 'coalesced', word_class: 'AJ' },
    { word: 'coalescence', word_class: 'N' },
    { word: 'coalescency', word_class: 'N' }
  ],
  anal: [{ word: 'anu', word_class: 'N' }, { word: 'anus', word_class: 'N' }, { word: 'anal', word_class: 'AJ' }],
  telecasting: [
    { word: 'telecast', word_class: 'N' },
    { word: 'telecast', word_class: 'V' },
    { word: 'telecasting', word_class: 'N' }
  ],
  murdered: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  lame: [
    { word: 'lame', word_class: 'N' },
    { word: 'lame', word_class: 'V' },
    { word: 'lame', word_class: 'AJ' },
    { word: 'lamely', word_class: 'AV' },
    { word: 'lameness', word_class: 'N' }
  ],
  erroneously: [
    { word: 'erroneous', word_class: 'AJ' },
    { word: 'erroneously', word_class: 'AV' },
    { word: 'erroneousness', word_class: 'N' }
  ],
  conveyable: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  divizible: [
    { word: 'divizive', word_class: 'AJ' },
    { word: 'divizible', word_class: 'AJ' },
    { word: 'divizibility', word_class: 'N' }
  ],
  await: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  aphasia: [
    { word: 'aphasia', word_class: 'N' },
    { word: 'aphasic', word_class: 'N' },
    { word: 'aphasic', word_class: 'AJ' }
  ],
  muffled: [
    { word: 'muffle', word_class: 'N' },
    { word: 'muffle', word_class: 'V' },
    { word: 'muffler', word_class: 'N' },
    { word: 'muffled', word_class: 'AJ' }
  ],
  sailplaning: [
    { word: 'sailplane', word_class: 'N' },
    { word: 'sailplane', word_class: 'V' },
    { word: 'sailplaning', word_class: 'N' }
  ],
  compassion: [
    { word: 'compass', word_class: 'N' },
    { word: 'compass', word_class: 'V' },
    { word: 'compassion', word_class: 'N' },
    { word: 'compassionate', word_class: 'V' },
    { word: 'compassionate', word_class: 'AJ' },
    { word: 'compassionately', word_class: 'AV' },
    { word: 'compassionateness', word_class: 'N' }
  ],
  rejective: [
    { word: 'reject', word_class: 'N' },
    { word: 'reject', word_class: 'V' },
    { word: 'rejected', word_class: 'AJ' },
    { word: 'rejection', word_class: 'N' },
    { word: 'rejective', word_class: 'AJ' }
  ],
  dance: [
    { word: 'dance', word_class: 'N' },
    { word: 'dance', word_class: 'V' },
    { word: 'dancer', word_class: 'N' },
    { word: 'dancing', word_class: 'N' },
    { word: 'dancing', word_class: 'AJ' }
  ],
  flatulent: [
    { word: 'flatulence', word_class: 'N' },
    { word: 'flatulency', word_class: 'N' },
    { word: 'flatulent', word_class: 'AJ' }
  ],
  deserted: [
    { word: 'desert', word_class: 'N' },
    { word: 'desert', word_class: 'V' },
    { word: 'desert', word_class: 'AJ' },
    { word: 'deserter', word_class: 'N' },
    { word: 'deserted', word_class: 'AJ' },
    { word: 'desertion', word_class: 'N' }
  ],
  scours: [
    { word: 'scour', word_class: 'N' },
    { word: 'scour', word_class: 'V' },
    { word: 'scours', word_class: 'N' },
    { word: 'scourer', word_class: 'N' },
    { word: 'scoured', word_class: 'AJ' },
    { word: 'scouring', word_class: 'N' }
  ],
  stargaze: [
    { word: 'stargaze', word_class: 'V' },
    { word: 'stargazer', word_class: 'N' },
    { word: 'stargazing', word_class: 'N' }
  ],
  electress: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  gullible: [
    { word: 'gullible', word_class: 'AJ' },
    { word: 'gullibly', word_class: 'AV' },
    { word: 'gullibility', word_class: 'N' }
  ],
  recover: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  bibliographic: [
    { word: 'bibliographer', word_class: 'N' },
    { word: 'bibliographic', word_class: 'AJ' },
    { word: 'bibliographical', word_class: 'AJ' }
  ],
  perceptively: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  revocable: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  sob: [{ word: 'sob', word_class: 'N' }, { word: 'sob', word_class: 'V' }, { word: 'sobbing', word_class: 'N' }],
  red: [{ word: 'red', word_class: 'N' }, { word: 'red', word_class: 'AJ' }, { word: 'redness', word_class: 'N' }],
  wastefulness: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  stirred: [
    { word: 'stir', word_class: 'N' },
    { word: 'stir', word_class: 'V' },
    { word: 'stirred', word_class: 'AJ' },
    { word: 'stirring', word_class: 'N' },
    { word: 'stirring', word_class: 'AJ' }
  ],
  litigate: [
    { word: 'litigant', word_class: 'N' },
    { word: 'litigate', word_class: 'V' },
    { word: 'litigator', word_class: 'N' },
    { word: 'litigation', word_class: 'N' }
  ],
  nativeness: [
    { word: 'native', word_class: 'N' },
    { word: 'native', word_class: 'AJ' },
    { word: 'nativism', word_class: 'N' },
    { word: 'nativity', word_class: 'N' },
    { word: 'nativeness', word_class: 'N' }
  ],
  pleating: [
    { word: 'pleat', word_class: 'N' },
    { word: 'pleat', word_class: 'V' },
    { word: 'pleated', word_class: 'AJ' },
    { word: 'pleating', word_class: 'N' }
  ],
  reward: [
    { word: 'reward', word_class: 'N' },
    { word: 'reward', word_class: 'V' },
    { word: 'rewardful', word_class: 'AJ' },
    { word: 'rewarding', word_class: 'AJ' },
    { word: 'rewardable', word_class: 'AJ' }
  ],
  snugness: [
    { word: 'snug', word_class: 'N' },
    { word: 'snug', word_class: 'AJ' },
    { word: 'snugness', word_class: 'N' }
  ],
  sparely: [
    { word: 'spare', word_class: 'N' },
    { word: 'spare', word_class: 'V' },
    { word: 'spare', word_class: 'AJ' },
    { word: 'sparely', word_class: 'AV' },
    { word: 'sparing', word_class: 'AJ' },
    { word: 'spareness', word_class: 'N' }
  ],
  accusal: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  turnery: [
    { word: 'turn', word_class: 'N' },
    { word: 'turn', word_class: 'V' },
    { word: 'turner', word_class: 'N' },
    { word: 'turned', word_class: 'AJ' },
    { word: 'turnery', word_class: 'N' },
    { word: 'turning', word_class: 'N' },
    { word: 'turnery', word_class: 'AJ' },
    { word: 'turning', word_class: 'AJ' }
  ],
  imaginative: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  appear: [
    { word: 'appear', word_class: 'V' },
    { word: 'appearing', word_class: 'N' },
    { word: 'appearance', word_class: 'N' }
  ],
  contentment: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  commons: [
    { word: 'common', word_class: 'N' },
    { word: 'common', word_class: 'AJ' },
    { word: 'commons', word_class: 'N' },
    { word: 'commoner', word_class: 'N' },
    { word: 'commonness', word_class: 'N' },
    { word: 'commonality', word_class: 'N' }
  ],
  sorption: [
    { word: 'sorb', word_class: 'N' },
    { word: 'sorb', word_class: 'V' },
    { word: 'sorbed', word_class: 'AJ' },
    { word: 'sorption', word_class: 'N' }
  ],
  tactically: [
    { word: 'tactic', word_class: 'N' },
    { word: 'tactic', word_class: 'AJ' },
    { word: 'tactics', word_class: 'N' },
    { word: 'tactical', word_class: 'AJ' },
    { word: 'tactically', word_class: 'AV' }
  ],
  integrator: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  blasphemy: [
    { word: 'blaspheme', word_class: 'V' },
    { word: 'blasphemy', word_class: 'N' },
    { word: 'blasphemer', word_class: 'N' },
    { word: 'blasphemous', word_class: 'AJ' },
    { word: 'blasphemously', word_class: 'AV' }
  ],
  coordinate: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  dutiable: [
    { word: 'duty', word_class: 'N' },
    { word: 'dutiful', word_class: 'AJ' },
    { word: 'dutiable', word_class: 'AJ' },
    { word: 'dutifulness', word_class: 'N' }
  ],
  notched: [
    { word: 'notch', word_class: 'N' },
    { word: 'notch', word_class: 'V' },
    { word: 'notched', word_class: 'AJ' }
  ],
  saute: [
    { word: 'saute', word_class: 'V' },
    { word: 'saute', word_class: 'AJ' },
    { word: 'sauteing', word_class: 'N' }
  ],
  switchman: [
    { word: 'switch', word_class: 'N' },
    { word: 'switch', word_class: 'V' },
    { word: 'switch', word_class: 'AJ' },
    { word: 'switching', word_class: 'N' },
    { word: 'switchman', word_class: 'N' }
  ],
  luminescent: [
    { word: 'luminesce', word_class: 'V' },
    { word: 'luminescence', word_class: 'N' },
    { word: 'luminescent', word_class: 'AJ' }
  ],
  grate: [
    { word: 'grate', word_class: 'N' },
    { word: 'grate', word_class: 'V' },
    { word: 'grater', word_class: 'N' },
    { word: 'grating', word_class: 'N' },
    { word: 'grating', word_class: 'AJ' },
    { word: 'grateful', word_class: 'AJ' },
    { word: 'gratefulness', word_class: 'N' }
  ],
  bionomic: [
    { word: 'bionomic', word_class: 'AJ' },
    { word: 'bionomics', word_class: 'N' },
    { word: 'bionomical', word_class: 'AJ' }
  ],
  discontinuation: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  unprofitably: [
    { word: 'unprofitable', word_class: 'AJ' },
    { word: 'unprofitably', word_class: 'AV' },
    { word: 'unprofitability', word_class: 'N' },
    { word: 'unprofitableness', word_class: 'N' }
  ],
  falsifying: [
    { word: 'falsify', word_class: 'V' },
    { word: 'falsifier', word_class: 'N' },
    { word: 'falsifying', word_class: 'N' },
    { word: 'falsification', word_class: 'N' }
  ],
  orchestra: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  hebrew: [
    { word: 'hebrew', word_class: 'N' },
    { word: 'hebrew', word_class: 'AJ' },
    { word: 'hebrews', word_class: 'N' }
  ],
  maneuverer: [
    { word: 'maneuverer', word_class: 'N' },
    { word: 'maneuverable', word_class: 'AJ' },
    { word: 'maneuverability', word_class: 'N' }
  ],
  devitalization: [
    { word: 'devitalise', word_class: 'V' },
    { word: 'devitalize', word_class: 'V' },
    { word: 'devitalisation', word_class: 'N' },
    { word: 'devitalization', word_class: 'N' }
  ],
  oblongness: [
    { word: 'oblong', word_class: 'N' },
    { word: 'oblong', word_class: 'AJ' },
    { word: 'oblongness', word_class: 'N' }
  ],
  solidifying: [
    { word: 'solidify', word_class: 'V' },
    { word: 'solidified', word_class: 'AJ' },
    { word: 'solidifying', word_class: 'N' },
    { word: 'solidification', word_class: 'N' }
  ],
  completeness: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  advantageously: [
    { word: 'advantage', word_class: 'N' },
    { word: 'advantage', word_class: 'V' },
    { word: 'advantaged', word_class: 'AJ' },
    { word: 'advantageous', word_class: 'AJ' },
    { word: 'advantageously', word_class: 'AV' },
    { word: 'advantageousness', word_class: 'N' }
  ],
  closing: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  stocked: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  transition: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  mobilisation: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  abound: [
    { word: 'abound', word_class: 'V' },
    { word: 'abundance', word_class: 'N' },
    { word: 'abundant', word_class: 'AJ' },
    { word: 'abounding', word_class: 'AJ' },
    { word: 'abundantly', word_class: 'AV' }
  ],
  absolved: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  retractor: [
    { word: 'retract', word_class: 'N' },
    { word: 'retract', word_class: 'V' },
    { word: 'retractor', word_class: 'N' },
    { word: 'retracted', word_class: 'AJ' },
    { word: 'retraction', word_class: 'N' },
    { word: 'retractable', word_class: 'AJ' }
  ],
  concentrated: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  mouldered: [
    { word: 'moulder', word_class: 'V' },
    { word: 'mouldered', word_class: 'AJ' },
    { word: 'mouldering', word_class: 'AJ' }
  ],
  shut: [{ word: 'shut', word_class: 'V' }, { word: 'shut', word_class: 'AJ' }, { word: 'shutting', word_class: 'N' }],
  caparisoned: [
    { word: 'caparison', word_class: 'N' },
    { word: 'caparison', word_class: 'V' },
    { word: 'caparisoned', word_class: 'AJ' }
  ],
  hip: [{ word: 'hip', word_class: 'N' }, { word: 'hip', word_class: 'AJ' }, { word: 'hipped', word_class: 'AJ' }],
  cytologic: [
    { word: 'cytology', word_class: 'N' },
    { word: 'cytologic', word_class: 'AJ' },
    { word: 'cytological', word_class: 'AJ' }
  ],
  cocaine: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  requited: [
    { word: 'requite', word_class: 'V' },
    { word: 'requital', word_class: 'N' },
    { word: 'requited', word_class: 'AJ' },
    { word: 'requitement', word_class: 'N' }
  ],
  innovator: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  sender: [
    { word: 'send', word_class: 'V' },
    { word: 'sender', word_class: 'N' },
    { word: 'sending', word_class: 'N' }
  ],
  fret: [
    { word: 'fret', word_class: 'N' },
    { word: 'fret', word_class: 'V' },
    { word: 'fretful', word_class: 'AJ' },
    { word: 'fretted', word_class: 'AJ' },
    { word: 'fretfulness', word_class: 'N' }
  ],
  disconcert: [
    { word: 'disconcert', word_class: 'N' },
    { word: 'disconcert', word_class: 'V' },
    { word: 'disconcerted', word_class: 'AJ' },
    { word: 'disconcertion', word_class: 'N' },
    { word: 'disconcerting', word_class: 'AJ' },
    { word: 'disconcertment', word_class: 'N' }
  ],
  editorially: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  collectivize: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  schematization: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  indispensable: [
    { word: 'indispensable', word_class: 'AJ' },
    { word: 'indispensability', word_class: 'N' },
    { word: 'indispensableness', word_class: 'N' }
  ],
  assumption: [
    { word: 'assume', word_class: 'V' },
    { word: 'assumed', word_class: 'AJ' },
    { word: 'assuming', word_class: 'AJ' },
    { word: 'assumption', word_class: 'N' },
    { word: 'assumptive', word_class: 'AJ' }
  ],
  verification: [
    { word: 'verify', word_class: 'V' },
    { word: 'verified', word_class: 'AJ' },
    { word: 'verifying', word_class: 'AJ' },
    { word: 'verifiable', word_class: 'AJ' },
    { word: 'verification', word_class: 'N' }
  ],
  termes: [
    { word: 'term', word_class: 'N' },
    { word: 'term', word_class: 'V' },
    { word: 'terms', word_class: 'N' },
    { word: 'termes', word_class: 'N' },
    { word: 'termite', word_class: 'N' }
  ],
  rack: [{ word: 'rack', word_class: 'N' }, { word: 'rack', word_class: 'V' }, { word: 'racking', word_class: 'AJ' }],
  cretin: [
    { word: 'cretin', word_class: 'N' },
    { word: 'cretinism', word_class: 'N' },
    { word: 'cretinous', word_class: 'AJ' }
  ],
  materialist: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  assailable: [
    { word: 'assail', word_class: 'V' },
    { word: 'assailant', word_class: 'N' },
    { word: 'assailment', word_class: 'N' },
    { word: 'assailable', word_class: 'AJ' },
    { word: 'assailability', word_class: 'N' }
  ],
  morphophonemics: [
    { word: 'morphophoneme', word_class: 'N' },
    { word: 'morphophonemic', word_class: 'AJ' },
    { word: 'morphophonemics', word_class: 'N' }
  ],
  bogey: [
    { word: 'bogey', word_class: 'N' },
    { word: 'bogey', word_class: 'V' },
    { word: 'bogeyman', word_class: 'N' }
  ],
  proportionation: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  guess: [
    { word: 'guess', word_class: 'N' },
    { word: 'guess', word_class: 'V' },
    { word: 'guessing', word_class: 'N' }
  ],
  violator: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  egocentrism: [
    { word: 'egocentric', word_class: 'N' },
    { word: 'egocentric', word_class: 'AJ' },
    { word: 'egocentrism', word_class: 'N' }
  ],
  toilsomeness: [
    { word: 'toil', word_class: 'N' },
    { word: 'toil', word_class: 'V' },
    { word: 'toiler', word_class: 'N' },
    { word: 'toiling', word_class: 'AJ' },
    { word: 'toilsome', word_class: 'AJ' },
    { word: 'toilsomeness', word_class: 'N' }
  ],
  magnificently: [
    { word: 'magnificence', word_class: 'N' },
    { word: 'magnificent', word_class: 'AJ' },
    { word: 'magnificently', word_class: 'AV' }
  ],
  notch: [
    { word: 'notch', word_class: 'N' },
    { word: 'notch', word_class: 'V' },
    { word: 'notched', word_class: 'AJ' }
  ],
  justify: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  recollective: [
    { word: 'recollect', word_class: 'V' },
    { word: 'recollection', word_class: 'N' },
    { word: 'recollective', word_class: 'AJ' }
  ],
  ope: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  meeting: [
    { word: 'meet', word_class: 'N' },
    { word: 'meet', word_class: 'V' },
    { word: 'meet', word_class: 'AJ' },
    { word: 'meeting', word_class: 'N' }
  ],
  terrorization: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  redhead: [
    { word: 'redhead', word_class: 'N' },
    { word: 'redheader', word_class: 'N' },
    { word: 'redheaded', word_class: 'AJ' }
  ],
  truculence: [
    { word: 'truculence', word_class: 'N' },
    { word: 'truculency', word_class: 'N' },
    { word: 'truculent', word_class: 'AJ' },
    { word: 'truculently', word_class: 'AV' }
  ],
  gardening: [
    { word: 'garden', word_class: 'N' },
    { word: 'garden', word_class: 'V' },
    { word: 'gardener', word_class: 'N' },
    { word: 'gardening', word_class: 'N' }
  ],
  snorter: [
    { word: 'snort', word_class: 'N' },
    { word: 'snort', word_class: 'V' },
    { word: 'snorter', word_class: 'N' },
    { word: 'snorting', word_class: 'N' },
    { word: 'snorting', word_class: 'AJ' }
  ],
  agree: [
    { word: 'agree', word_class: 'V' },
    { word: 'agreed', word_class: 'AJ' },
    { word: 'agreeing', word_class: 'AJ' },
    { word: 'agreement', word_class: 'N' }
  ],
  saddle: [
    { word: 'saddle', word_class: 'N' },
    { word: 'saddle', word_class: 'V' },
    { word: 'saddler', word_class: 'N' },
    { word: 'saddled', word_class: 'AJ' }
  ],
  stiffening: [
    { word: 'stiffen', word_class: 'V' },
    { word: 'stiffener', word_class: 'N' },
    { word: 'stiffened', word_class: 'AJ' },
    { word: 'stiffening', word_class: 'N' }
  ],
  preventable: [
    { word: 'prevent', word_class: 'N' },
    { word: 'prevent', word_class: 'V' },
    { word: 'prevention', word_class: 'N' },
    { word: 'preventive', word_class: 'N' },
    { word: 'preventive', word_class: 'AJ' },
    { word: 'preventable', word_class: 'AJ' },
    { word: 'preventative', word_class: 'N' },
    { word: 'preventative', word_class: 'AJ' }
  ],
  apocalyptical: [
    { word: 'apocalypse', word_class: 'N' },
    { word: 'apocalyptic', word_class: 'AJ' },
    { word: 'apocalyptical', word_class: 'AJ' }
  ],
  permissive: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  resile: [
    { word: 'resile', word_class: 'V' },
    { word: 'resilement', word_class: 'N' },
    { word: 'resilience', word_class: 'N' },
    { word: 'resiliency', word_class: 'N' },
    { word: 'resilient', word_class: 'AJ' }
  ],
  marvellous: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  gaming: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  lulling: [
    { word: 'lull', word_class: 'N' },
    { word: 'lull', word_class: 'V' },
    { word: 'lulling', word_class: 'AJ' }
  ],
  sculpturer: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  test: [
    { word: 'test', word_class: 'N' },
    { word: 'test', word_class: 'V' },
    { word: 'test', word_class: 'AJ' },
    { word: 'testee', word_class: 'N' },
    { word: 'tester', word_class: 'N' },
    { word: 'tested', word_class: 'AJ' },
    { word: 'testing', word_class: 'N' }
  ],
  universe: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  osculator: [
    { word: 'osculate', word_class: 'V' },
    { word: 'osculator', word_class: 'N' },
    { word: 'osculation', word_class: 'N' }
  ],
  creator: [
    { word: 'create', word_class: 'V' },
    { word: 'creator', word_class: 'N' },
    { word: 'creation', word_class: 'N' },
    { word: 'creationism', word_class: 'N' }
  ],
  grotesquely: [
    { word: 'grotesque', word_class: 'N' },
    { word: 'grotesque', word_class: 'AJ' },
    { word: 'grotesquely', word_class: 'AV' },
    { word: 'grotesqueness', word_class: 'N' }
  ],
  opulence: [
    { word: 'opulence', word_class: 'N' },
    { word: 'opulent', word_class: 'AJ' },
    { word: 'opulently', word_class: 'AV' }
  ],
  corrugate: [
    { word: 'corrugate', word_class: 'V' },
    { word: 'corrugated', word_class: 'AJ' },
    { word: 'corrugation', word_class: 'N' }
  ],
  subverter: [
    { word: 'subvert', word_class: 'V' },
    { word: 'subverter', word_class: 'N' },
    { word: 'subversion', word_class: 'N' },
    { word: 'subversive', word_class: 'N' },
    { word: 'subversive', word_class: 'AJ' },
    { word: 'subversiveness', word_class: 'N' }
  ],
  brilliance: [
    { word: 'brilliance', word_class: 'N' },
    { word: 'brilliancy', word_class: 'N' },
    { word: 'brilliant', word_class: 'AJ' }
  ],
  cocain: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  sterilisation: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  cut: [
    { word: 'cut', word_class: 'N' },
    { word: 'cut', word_class: 'V' },
    { word: 'cut', word_class: 'AJ' },
    { word: 'cutting', word_class: 'N' },
    { word: 'cutting', word_class: 'AJ' }
  ],
  contrariwise: [
    { word: 'contrary', word_class: 'N' },
    { word: 'contrary', word_class: 'AJ' },
    { word: 'contrariness', word_class: 'N' },
    { word: 'contrarious', word_class: 'AJ' },
    { word: 'contrariwise', word_class: 'AV' }
  ],
  renew: [
    { word: 'renew', word_class: 'N' },
    { word: 'renew', word_class: 'V' },
    { word: 'renew', word_class: 'AJ' },
    { word: 'renewal', word_class: 'N' },
    { word: 'renewal', word_class: 'V' },
    { word: 'renewed', word_class: 'AJ' },
    { word: 'renewing', word_class: 'AJ' },
    { word: 'renewable', word_class: 'AJ' }
  ],
  juice: [
    { word: 'juice', word_class: 'N' },
    { word: 'juice', word_class: 'V' },
    { word: 'juicer', word_class: 'N' },
    { word: 'juicy', word_class: 'AJ' },
    { word: 'juiciness', word_class: 'N' }
  ],
  pyramidal: [
    { word: 'pyramid', word_class: 'N' },
    { word: 'pyramid', word_class: 'V' },
    { word: 'pyramidal', word_class: 'AJ' }
  ],
  apologetically: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  missionary: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  postponement: [
    { word: 'postpone', word_class: 'V' },
    { word: 'postponer', word_class: 'N' },
    { word: 'postponed', word_class: 'AJ' },
    { word: 'postponement', word_class: 'N' }
  ],
  borer: [
    { word: 'bore', word_class: 'N' },
    { word: 'bore', word_class: 'V' },
    { word: 'borer', word_class: 'N' },
    { word: 'bored', word_class: 'AJ' },
    { word: 'boring', word_class: 'N' },
    { word: 'boredom', word_class: 'N' },
    { word: 'boring', word_class: 'AJ' }
  ],
  complaint: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  trousering: [
    { word: 'trouser', word_class: 'N' },
    { word: 'trousers', word_class: 'N' },
    { word: 'trousered', word_class: 'AJ' },
    { word: 'trousering', word_class: 'N' }
  ],
  automated: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  typification: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  overhang: [
    { word: 'overhang', word_class: 'N' },
    { word: 'overhang', word_class: 'V' },
    { word: 'overhanging', word_class: 'AJ' }
  ],
  cheater: [
    { word: 'cheat', word_class: 'N' },
    { word: 'cheat', word_class: 'V' },
    { word: 'cheater', word_class: 'N' },
    { word: 'cheating', word_class: 'N' },
    { word: 'cheating', word_class: 'AJ' }
  ],
  infiltrator: [
    { word: 'infiltrate', word_class: 'V' },
    { word: 'infiltrator', word_class: 'N' },
    { word: 'infiltration', word_class: 'N' }
  ],
  informing: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  seized: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  talks: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  disheartened: [
    { word: 'dishearten', word_class: 'V' },
    { word: 'disheartened', word_class: 'AJ' },
    { word: 'disheartening', word_class: 'AJ' },
    { word: 'disheartenment', word_class: 'N' }
  ],
  dictation: [
    { word: 'dictate', word_class: 'N' },
    { word: 'dictate', word_class: 'V' },
    { word: 'dication', word_class: 'N' },
    { word: 'dictator', word_class: 'N' },
    { word: 'dictated', word_class: 'AJ' },
    { word: 'dictation', word_class: 'N' }
  ],
  horizontality: [
    { word: 'horizontal', word_class: 'N' },
    { word: 'horizontal', word_class: 'AJ' },
    { word: 'horizontality', word_class: 'N' },
    { word: 'horizontally', word_class: 'AV' }
  ],
  abiding: [
    { word: 'abide', word_class: 'V' },
    { word: 'abidance', word_class: 'N' },
    { word: 'abiding', word_class: 'AJ' }
  ],
  complainer: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  sufficiently: [
    { word: 'suffice', word_class: 'V' },
    { word: 'sufficiency', word_class: 'N' },
    { word: 'sufficient', word_class: 'AJ' },
    { word: 'sufficiently', word_class: 'AV' }
  ],
  gutturalise: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  halting: [
    { word: 'halt', word_class: 'N' },
    { word: 'halt', word_class: 'V' },
    { word: 'halt', word_class: 'AJ' },
    { word: 'halter', word_class: 'N' },
    { word: 'halter', word_class: 'V' },
    { word: 'halted', word_class: 'AJ' },
    { word: 'haltere', word_class: 'N' },
    { word: 'halting', word_class: 'AJ' }
  ],
  enation: [
    { word: 'enate', word_class: 'N' },
    { word: 'enate', word_class: 'AJ' },
    { word: 'enatic', word_class: 'AJ' },
    { word: 'enation', word_class: 'N' }
  ],
  perfectible: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  earnings: [
    { word: 'earn', word_class: 'V' },
    { word: 'earner', word_class: 'N' },
    { word: 'earned', word_class: 'AJ' },
    { word: 'earning', word_class: 'N' },
    { word: 'earnings', word_class: 'N' }
  ],
  maculation: [
    { word: 'macule', word_class: 'N' },
    { word: 'maculate', word_class: 'V' },
    { word: 'maculate', word_class: 'AJ' },
    { word: 'maculation', word_class: 'N' }
  ],
  pouring: [
    { word: 'pour', word_class: 'V' },
    { word: 'pouring', word_class: 'N' },
    { word: 'pouring', word_class: 'AJ' }
  ],
  leaver: [
    { word: 'leave', word_class: 'N' },
    { word: 'leave', word_class: 'V' },
    { word: 'leaver', word_class: 'N' },
    { word: 'leaved', word_class: 'AJ' },
    { word: 'leaving', word_class: 'N' }
  ],
  didactical: [
    { word: 'didactic', word_class: 'AJ' },
    { word: 'didactical', word_class: 'AJ' },
    { word: 'didactically', word_class: 'AV' }
  ],
  abandon: [
    { word: 'abandon', word_class: 'N' },
    { word: 'abandon', word_class: 'V' },
    { word: 'abandoned', word_class: 'AJ' },
    { word: 'abandonment', word_class: 'N' }
  ],
  socialise: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  ventilator: [
    { word: 'ventilate', word_class: 'V' },
    { word: 'ventilator', word_class: 'N' },
    { word: 'ventilated', word_class: 'AJ' },
    { word: 'ventilation', word_class: 'N' }
  ],
  eagerness: [
    { word: 'eager', word_class: 'N' },
    { word: 'eager', word_class: 'AJ' },
    { word: 'eagerness', word_class: 'N' }
  ],
  savagely: [
    { word: 'savage', word_class: 'V' },
    { word: 'savage', word_class: 'N' },
    { word: 'savage', word_class: 'AJ' },
    { word: 'savagery', word_class: 'N' },
    { word: 'savagely', word_class: 'AV' },
    { word: 'savagery', word_class: 'AJ' },
    { word: 'savageness', word_class: 'N' }
  ],
  computational: [
    { word: 'compute', word_class: 'V' },
    { word: 'computer', word_class: 'N' },
    { word: 'computable', word_class: 'AJ' },
    { word: 'computation', word_class: 'N' },
    { word: 'computational', word_class: 'AJ' }
  ],
  disqualifying: [
    { word: 'disqualify', word_class: 'V' },
    { word: 'disqualified', word_class: 'AJ' },
    { word: 'disqualifying', word_class: 'N' },
    { word: 'disqualifying', word_class: 'AJ' },
    { word: 'disqualification', word_class: 'N' }
  ],
  woman: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  prurient: [
    { word: 'prurience', word_class: 'N' },
    { word: 'pruriency', word_class: 'N' },
    { word: 'prurient', word_class: 'AJ' },
    { word: 'pruriently', word_class: 'AV' }
  ],
  conduct: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  disclosing: [
    { word: 'disclose', word_class: 'V' },
    { word: 'disclosed', word_class: 'AJ' },
    { word: 'disclosure', word_class: 'N' },
    { word: 'disclosing', word_class: 'AJ' }
  ],
  attainability: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  underseal: [
    { word: 'underseal', word_class: 'N' },
    { word: 'undersize', word_class: 'AJ' },
    { word: 'undersized', word_class: 'AJ' },
    { word: 'undersealed', word_class: 'AJ' }
  ],
  intreatment: [
    { word: 'entreat', word_class: 'V' },
    { word: 'intreat', word_class: 'V' },
    { word: 'entreaty', word_class: 'N' },
    { word: 'entreatment', word_class: 'N' },
    { word: 'intreatment', word_class: 'N' }
  ],
  encodement: [
    { word: 'encode', word_class: 'V' },
    { word: 'encoding', word_class: 'N' },
    { word: 'encodement', word_class: 'N' }
  ],
  exposition: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  importing: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  foundation: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  personable: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  alternate: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  gruesomeness: [
    { word: 'gruesome', word_class: 'AJ' },
    { word: 'gruesomely', word_class: 'AV' },
    { word: 'gruesomeness', word_class: 'N' }
  ],
  supposable: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  unreliably: [
    { word: 'unreliable', word_class: 'AJ' },
    { word: 'unreliably', word_class: 'AV' },
    { word: 'unreliability', word_class: 'N' },
    { word: 'unreliableness', word_class: 'N' }
  ],
  funniness: [
    { word: 'funny', word_class: 'AJ' },
    { word: 'funny', word_class: 'AV' },
    { word: 'funniness', word_class: 'N' }
  ],
  enjoy: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  oldy: [
    { word: 'old', word_class: 'N' },
    { word: 'old', word_class: 'AJ' },
    { word: 'oldy', word_class: 'N' },
    { word: 'oldness', word_class: 'N' }
  ],
  distinct: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  heaver: [
    { word: 'heave', word_class: 'N' },
    { word: 'heave', word_class: 'V' },
    { word: 'heaver', word_class: 'N' },
    { word: 'heaves', word_class: 'N' },
    { word: 'heaving', word_class: 'N' },
    { word: 'heaving', word_class: 'AJ' }
  ],
  croon: [
    { word: 'croon', word_class: 'N' },
    { word: 'croon', word_class: 'V' },
    { word: 'crooner', word_class: 'N' },
    { word: 'crooning', word_class: 'N' }
  ],
  drifting: [
    { word: 'drift', word_class: 'N' },
    { word: 'drift', word_class: 'V' },
    { word: 'drifter', word_class: 'N' },
    { word: 'drifting', word_class: 'N' },
    { word: 'drifting', word_class: 'AJ' }
  ],
  prosper: [
    { word: 'prosper', word_class: 'V' },
    { word: 'prosperity', word_class: 'N' },
    { word: 'prospering', word_class: 'AJ' },
    { word: 'prosperous', word_class: 'AJ' },
    { word: 'prosperously', word_class: 'AV' }
  ],
  curative: [
    { word: 'cur', word_class: 'N' },
    { word: 'cur', word_class: 'AJ' },
    { word: 'curative', word_class: 'N' },
    { word: 'curative', word_class: 'AJ' }
  ],
  scooping: [
    { word: 'scoop', word_class: 'N' },
    { word: 'scoop', word_class: 'V' },
    { word: 'scoopful', word_class: 'N' },
    { word: 'scooping', word_class: 'N' },
    { word: 'scoopful', word_class: 'AJ' }
  ],
  asserttion: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  prohibitive: [
    { word: 'prohibit', word_class: 'V' },
    { word: 'prohibition', word_class: 'N' },
    { word: 'prohibited', word_class: 'AJ' },
    { word: 'prohibitive', word_class: 'AJ' },
    { word: 'prohibitively', word_class: 'AV' }
  ],
  baptism: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  domino: [
    { word: 'domino', word_class: 'N' },
    { word: 'dominos', word_class: 'N' },
    { word: 'dominoes', word_class: 'N' }
  ],
  ripply: [
    { word: 'ripple', word_class: 'N' },
    { word: 'ripple', word_class: 'V' },
    { word: 'ripply', word_class: 'AJ' },
    { word: 'rippled', word_class: 'AJ' },
    { word: 'rippling', word_class: 'N' }
  ],
  swab: [{ word: 'swab', word_class: 'N' }, { word: 'swab', word_class: 'V' }, { word: 'swabbing', word_class: 'N' }],
  fornication: [
    { word: 'fornicate', word_class: 'V' },
    { word: 'fornicator', word_class: 'N' },
    { word: 'fornication', word_class: 'N' }
  ],
  hardback: [
    { word: 'hardback', word_class: 'N' },
    { word: 'hardback', word_class: 'AJ' },
    { word: 'hardbacked', word_class: 'AJ' }
  ],
  equalizer: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  frothing: [
    { word: 'froth', word_class: 'N' },
    { word: 'froth', word_class: 'V' },
    { word: 'frothing', word_class: 'AJ' }
  ],
  taking: [
    { word: 'take', word_class: 'N' },
    { word: 'take', word_class: 'V' },
    { word: 'taker', word_class: 'N' },
    { word: 'taken', word_class: 'AJ' },
    { word: 'taking', word_class: 'N' },
    { word: 'taking', word_class: 'AJ' },
    { word: 'takings', word_class: 'N' }
  ],
  whisperer: [
    { word: 'whisper', word_class: 'N' },
    { word: 'whisper', word_class: 'V' },
    { word: 'whisperer', word_class: 'N' },
    { word: 'whispered', word_class: 'AJ' },
    { word: 'whispering', word_class: 'N' },
    { word: 'whispering', word_class: 'AJ' }
  ],
  dilatation: [
    { word: 'dilate', word_class: 'V' },
    { word: 'dilate', word_class: 'AJ' },
    { word: 'dilated', word_class: 'AJ' },
    { word: 'dilation', word_class: 'N' },
    { word: 'dilatation', word_class: 'N' }
  ],
  loading: [
    { word: 'load', word_class: 'N' },
    { word: 'load', word_class: 'V' },
    { word: 'loads', word_class: 'N' },
    { word: 'loader', word_class: 'N' },
    { word: 'loaded', word_class: 'AJ' },
    { word: 'loading', word_class: 'N' },
    { word: 'loading', word_class: 'AJ' }
  ],
  barbecuing: [
    { word: 'barbecue', word_class: 'N' },
    { word: 'barbecue', word_class: 'V' },
    { word: 'barbecued', word_class: 'AJ' },
    { word: 'barbecuing', word_class: 'N' }
  ],
  work: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  sanctionative: [
    { word: 'sanction', word_class: 'N' },
    { word: 'sanction', word_class: 'V' },
    { word: 'sanctioned', word_class: 'AJ' },
    { word: 'sanctioning', word_class: 'AJ' },
    { word: 'sanctionative', word_class: 'AJ' }
  ],
  perturbation: [
    { word: 'perturb', word_class: 'V' },
    { word: 'perturbate', word_class: 'V' },
    { word: 'perturbed', word_class: 'AJ' },
    { word: 'perturbing', word_class: 'AJ' },
    { word: 'perturbation', word_class: 'N' }
  ],
  culture: [
    { word: 'culture', word_class: 'N' },
    { word: 'culture', word_class: 'V' },
    { word: 'cultural', word_class: 'AJ' },
    { word: 'cultured', word_class: 'AJ' },
    { word: 'culturally', word_class: 'AV' }
  ],
  elegize: [
    { word: 'elegize', word_class: 'V' },
    { word: 'elegance', word_class: 'N' },
    { word: 'elegant', word_class: 'AJ' },
    { word: 'elegization', word_class: 'N' }
  ],
  pomade: [
    { word: 'pomade', word_class: 'N' },
    { word: 'pomade', word_class: 'V' },
    { word: 'pomaded', word_class: 'AJ' }
  ],
  bottomed: [
    { word: 'bottom', word_class: 'N' },
    { word: 'bottom', word_class: 'V' },
    { word: 'bottom', word_class: 'AJ' },
    { word: 'bottomed', word_class: 'AJ' }
  ],
  barrenness: [
    { word: 'barren', word_class: 'N' },
    { word: 'barren', word_class: 'AJ' },
    { word: 'barrenness', word_class: 'N' }
  ],
  adore: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  wager: [{ word: 'wager', word_class: 'N' }, { word: 'wager', word_class: 'V' }, { word: 'wagerer', word_class: 'N' }],
  expanding: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  invoke: [
    { word: 'invoke', word_class: 'V' },
    { word: 'invocate', word_class: 'V' },
    { word: 'invocation', word_class: 'N' }
  ],
  nimbly: [
    { word: 'nimble', word_class: 'AJ' },
    { word: 'nimbly', word_class: 'AV' },
    { word: 'nimbleness', word_class: 'N' }
  ],
  publically: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  moroccan: [
    { word: 'moroccan', word_class: 'N' },
    { word: 'moroccan', word_class: 'AJ' },
    { word: 'moroccanize', word_class: 'V' }
  ],
  damning: [
    { word: 'damn', word_class: 'N' },
    { word: 'damn', word_class: 'V' },
    { word: 'damn', word_class: 'AJ' },
    { word: 'damn', word_class: 'AV' },
    { word: 'damned', word_class: 'N' },
    { word: 'damned', word_class: 'AJ' },
    { word: 'damned', word_class: 'AV' },
    { word: 'damning', word_class: 'AJ' },
    { word: 'damnation', word_class: 'N' }
  ],
  mangy: [
    { word: 'mange', word_class: 'N' },
    { word: 'mangy', word_class: 'AJ' },
    { word: 'manginess', word_class: 'N' }
  ],
  mythology: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  devaluate: [
    { word: 'devalue', word_class: 'V' },
    { word: 'devaluate', word_class: 'V' },
    { word: 'devalued', word_class: 'AJ' },
    { word: 'devaluation', word_class: 'N' }
  ],
  familiarisation: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  parenthesize: [
    { word: 'parenthesis', word_class: 'N' },
    { word: 'parenthesize', word_class: 'V' },
    { word: 'parenthesization', word_class: 'N' }
  ],
  puritanical: [
    { word: 'puritan', word_class: 'N' },
    { word: 'puritan', word_class: 'AJ' },
    { word: 'puritanic', word_class: 'AJ' },
    { word: 'puritanism', word_class: 'N' },
    { word: 'puritanical', word_class: 'AJ' },
    { word: 'puritanically', word_class: 'AV' }
  ],
  lecher: [
    { word: 'lech', word_class: 'V' },
    { word: 'lecher', word_class: 'N' },
    { word: 'lecherous', word_class: 'AJ' },
    { word: 'lecherousness', word_class: 'N' }
  ],
  classificatory: [
    { word: 'class', word_class: 'N' },
    { word: 'class', word_class: 'V' },
    { word: 'classificatory', word_class: 'AJ' }
  ],
  bisectional: [
    { word: 'bisect', word_class: 'N' },
    { word: 'bisect', word_class: 'V' },
    { word: 'bisection', word_class: 'N' },
    { word: 'bisectional', word_class: 'AJ' }
  ],
  mortification: [
    { word: 'mortify', word_class: 'V' },
    { word: 'mortified', word_class: 'AJ' },
    { word: 'mortifying', word_class: 'AJ' },
    { word: 'mortification', word_class: 'N' }
  ],
  oilman: [
    { word: 'oil', word_class: 'N' },
    { word: 'oil', word_class: 'V' },
    { word: 'oiler', word_class: 'N' },
    { word: 'oiled', word_class: 'AJ' },
    { word: 'oilman', word_class: 'N' }
  ],
  reticulation: [
    { word: 'reticule', word_class: 'N' },
    { word: 'reticulate', word_class: 'V' },
    { word: 'reticulate', word_class: 'AJ' },
    { word: 'reticulated', word_class: 'AJ' },
    { word: 'reticulation', word_class: 'N' }
  ],
  anathematize: [
    { word: 'anathematise', word_class: 'V' },
    { word: 'anathematize', word_class: 'V' },
    { word: 'anathematisation', word_class: 'N' },
    { word: 'anathematization', word_class: 'N' }
  ],
  accentuation: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  fair: [
    { word: 'fair', word_class: 'N' },
    { word: 'fair', word_class: 'V' },
    { word: 'fair', word_class: 'AJ' },
    { word: 'fair', word_class: 'AV' },
    { word: 'fairness', word_class: 'N' }
  ],
  liquidity: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  reposition: [
    { word: 'reposit', word_class: 'V' },
    { word: 'repositing', word_class: 'N' },
    { word: 'reposition', word_class: 'N' },
    { word: 'reposition', word_class: 'V' },
    { word: 'repositioning', word_class: 'N' }
  ],
  whack: [
    { word: 'whack', word_class: 'N' },
    { word: 'whack', word_class: 'V' },
    { word: 'whacker', word_class: 'N' },
    { word: 'whacked', word_class: 'AJ' },
    { word: 'whacking', word_class: 'N' },
    { word: 'whacking', word_class: 'AJ' },
    { word: 'whacking', word_class: 'AV' }
  ],
  parking: [
    { word: 'park', word_class: 'N' },
    { word: 'park', word_class: 'V' },
    { word: 'parked', word_class: 'AJ' },
    { word: 'parking', word_class: 'N' }
  ],
  academe: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  wheeling: [
    { word: 'wheel', word_class: 'N' },
    { word: 'wheel', word_class: 'V' },
    { word: 'wheels', word_class: 'N' },
    { word: 'wheeler', word_class: 'N' },
    { word: 'wheeled', word_class: 'AJ' },
    { word: 'wheeling', word_class: 'N' },
    { word: 'wheeling', word_class: 'AJ' }
  ],
  worshipped: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  wobbler: [
    { word: 'wobble', word_class: 'N' },
    { word: 'wobble', word_class: 'V' },
    { word: 'wobbler', word_class: 'N' },
    { word: 'wobbly', word_class: 'AJ' },
    { word: 'wobbling', word_class: 'AJ' }
  ],
  tragical: [
    { word: 'tragic', word_class: 'AJ' },
    { word: 'tragical', word_class: 'AJ' },
    { word: 'tragically', word_class: 'AV' }
  ],
  coil: [
    { word: 'coil', word_class: 'N' },
    { word: 'coil', word_class: 'V' },
    { word: 'coiled', word_class: 'AJ' },
    { word: 'coiling', word_class: 'AJ' }
  ],
  realise: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  probabilistic: [
    { word: 'probabilist', word_class: 'N' },
    { word: 'probabilistic', word_class: 'AJ' },
    { word: 'probabilistically', word_class: 'AV' }
  ],
  percuss: [
    { word: 'percuss', word_class: 'V' },
    { word: 'percussion', word_class: 'N' },
    { word: 'percussive', word_class: 'AJ' }
  ],
  apomictic: [
    { word: 'apomict', word_class: 'N' },
    { word: 'apomictic', word_class: 'AJ' },
    { word: 'apomictical', word_class: 'AJ' }
  ],
  arabic: [
    { word: 'arab', word_class: 'N' },
    { word: 'arabic', word_class: 'N' },
    { word: 'arabic', word_class: 'AJ' }
  ],
  crazed: [
    { word: 'craze', word_class: 'N' },
    { word: 'craze', word_class: 'V' },
    { word: 'crazed', word_class: 'AJ' }
  ],
  mortal: [
    { word: 'mortal', word_class: 'N' },
    { word: 'mortal', word_class: 'AJ' },
    { word: 'mortality', word_class: 'N' },
    { word: 'mortally', word_class: 'AV' }
  ],
  choose: [
    { word: 'choice', word_class: 'N' },
    { word: 'choose', word_class: 'V' },
    { word: 'chosen', word_class: 'N' },
    { word: 'choosy', word_class: 'AJ' },
    { word: 'choice', word_class: 'AJ' },
    { word: 'chosen', word_class: 'AJ' },
    { word: 'choiceness', word_class: 'N' }
  ],
  genealogy: [
    { word: 'genealogy', word_class: 'N' },
    { word: 'genealogic', word_class: 'AJ' },
    { word: 'genealogical', word_class: 'AJ' },
    { word: 'genealogically', word_class: 'AV' }
  ],
  hermaphrodite: [
    { word: 'hermaphrodite', word_class: 'N' },
    { word: 'hermaphrodite', word_class: 'AJ' },
    { word: 'hermaphroditic', word_class: 'AJ' },
    { word: 'hermaphroditism', word_class: 'N' }
  ],
  waftage: [
    { word: 'waft', word_class: 'N' },
    { word: 'waft', word_class: 'V' },
    { word: 'waftage', word_class: 'N' },
    { word: 'wafture', word_class: 'N' }
  ],
  impute: [
    { word: 'impute', word_class: 'V' },
    { word: 'imputable', word_class: 'AJ' },
    { word: 'imputation', word_class: 'N' }
  ],
  sinter: [
    { word: 'sinter', word_class: 'N' },
    { word: 'sinter', word_class: 'V' },
    { word: 'sintered', word_class: 'AJ' }
  ],
  calculator: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  surviving: [
    { word: 'survive', word_class: 'V' },
    { word: 'survival', word_class: 'N' },
    { word: 'survivor', word_class: 'N' },
    { word: 'surviving', word_class: 'AJ' }
  ],
  fraudulence: [
    { word: 'fraud', word_class: 'N' },
    { word: 'fraudulence', word_class: 'N' },
    { word: 'fraudulent', word_class: 'AJ' },
    { word: 'fraudulently', word_class: 'AV' }
  ],
  inviting: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  souse: [
    { word: 'souse', word_class: 'N' },
    { word: 'souse', word_class: 'V' },
    { word: 'soused', word_class: 'AJ' },
    { word: 'sousing', word_class: 'N' }
  ],
  'self-consciously': [
    { word: 'self-conscious', word_class: 'AJ' },
    { word: 'self-consciously', word_class: 'AV' },
    { word: 'self-consciousness', word_class: 'N' }
  ],
  autochthonal: [
    { word: 'autochthonal', word_class: 'AJ' },
    { word: 'autochthonic', word_class: 'AJ' },
    { word: 'autochthonous', word_class: 'AJ' }
  ],
  consumed: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  trade: [
    { word: 'trade', word_class: 'N' },
    { word: 'trade', word_class: 'V' },
    { word: 'trade', word_class: 'AJ' },
    { word: 'trader', word_class: 'N' },
    { word: 'trading', word_class: 'N' },
    { word: 'traded', word_class: 'AJ' }
  ],
  blank: [
    { word: 'blank', word_class: 'N' },
    { word: 'blank', word_class: 'V' },
    { word: 'blank', word_class: 'AJ' },
    { word: 'blankness', word_class: 'N' }
  ],
  assenting: [
    { word: 'assent', word_class: 'N' },
    { word: 'assent', word_class: 'V' },
    { word: 'assenting', word_class: 'N' },
    { word: 'assenting', word_class: 'AJ' }
  ],
  brine: [
    { word: 'brine', word_class: 'N' },
    { word: 'brine', word_class: 'V' },
    { word: 'briny', word_class: 'N' },
    { word: 'briny', word_class: 'AJ' },
    { word: 'brininess', word_class: 'N' }
  ],
  schoolman: [
    { word: 'school', word_class: 'N' },
    { word: 'school', word_class: 'V' },
    { word: 'schooling', word_class: 'N' },
    { word: 'schooled', word_class: 'AJ' },
    { word: 'schoolman', word_class: 'N' }
  ],
  imminency: [
    { word: 'imminence', word_class: 'N' },
    { word: 'imminency', word_class: 'N' },
    { word: 'imminent', word_class: 'AJ' },
    { word: 'imminently', word_class: 'AV' }
  ],
  achromatism: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  rumbling: [
    { word: 'rumble', word_class: 'N' },
    { word: 'rumble', word_class: 'V' },
    { word: 'rumbling', word_class: 'N' },
    { word: 'rumbling', word_class: 'AJ' }
  ],
  lethargy: [
    { word: 'lethargy', word_class: 'N' },
    { word: 'lethargic', word_class: 'AJ' },
    { word: 'lethargically', word_class: 'AV' }
  ],
  bickering: [
    { word: 'bicker', word_class: 'N' },
    { word: 'bicker', word_class: 'V' },
    { word: 'bickering', word_class: 'N' }
  ],
  disobediently: [
    { word: 'disobey', word_class: 'V' },
    { word: 'disobedience', word_class: 'N' },
    { word: 'disobedient', word_class: 'AJ' },
    { word: 'disobediently', word_class: 'AV' }
  ],
  ozonise: [
    { word: 'ozone', word_class: 'N' },
    { word: 'ozonise', word_class: 'V' },
    { word: 'ozonize', word_class: 'V' },
    { word: 'ozonisation', word_class: 'N' },
    { word: 'ozonization', word_class: 'N' }
  ],
  transmitting: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  encoding: [
    { word: 'encode', word_class: 'V' },
    { word: 'encoding', word_class: 'N' },
    { word: 'encodement', word_class: 'N' }
  ],
  bookbinder: [
    { word: 'bookbind', word_class: 'V' },
    { word: 'bookbinder', word_class: 'N' },
    { word: 'bookbindery', word_class: 'N' },
    { word: 'bookbinding', word_class: 'N' },
    { word: 'bookbindery', word_class: 'AJ' }
  ],
  oppressed: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  welshman: [
    { word: 'welsh', word_class: 'N' },
    { word: 'welsh', word_class: 'V' },
    { word: 'welsh', word_class: 'AJ' },
    { word: 'welsher', word_class: 'N' },
    { word: 'welshman', word_class: 'N' }
  ],
  apply: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  tracery: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  erupt: [
    { word: 'erupt', word_class: 'V' },
    { word: 'eruption', word_class: 'N' },
    { word: 'erupting', word_class: 'AJ' },
    { word: 'eruptive', word_class: 'AJ' }
  ],
  deepfreeze: [
    { word: 'deepfreeze', word_class: 'N' },
    { word: 'deepfreeze', word_class: 'V' },
    { word: 'deepfrozen', word_class: 'AJ' }
  ],
  irresolute: [
    { word: 'irresolute', word_class: 'AJ' },
    { word: 'irresolution', word_class: 'N' },
    { word: 'irresolutely', word_class: 'AV' },
    { word: 'irresoluteness', word_class: 'N' }
  ],
  fiber: [
    { word: 'fib', word_class: 'N' },
    { word: 'fib', word_class: 'V' },
    { word: 'fiber', word_class: 'N' },
    { word: 'fibbing', word_class: 'N' },
    { word: 'fibrous', word_class: 'AJ' }
  ],
  quack: [
    { word: 'quack', word_class: 'N' },
    { word: 'quack', word_class: 'V' },
    { word: 'quack', word_class: 'AJ' },
    { word: 'quackery', word_class: 'N' },
    { word: 'quackery', word_class: 'AJ' }
  ],
  toboggan: [
    { word: 'toboggan', word_class: 'N' },
    { word: 'toboggan', word_class: 'V' },
    { word: 'tobogganing', word_class: 'N' },
    { word: 'tobogganist', word_class: 'N' }
  ],
  booty: [
    { word: 'booty', word_class: 'N' },
    { word: 'bootie', word_class: 'N' },
    { word: 'booty', word_class: 'AJ' },
    { word: 'booties', word_class: 'N' }
  ],
  plainness: [
    { word: 'plain', word_class: 'N' },
    { word: 'plain', word_class: 'V' },
    { word: 'plain', word_class: 'AJ' },
    { word: 'plain', word_class: 'AV' },
    { word: 'plainness', word_class: 'N' }
  ],
  legible: [
    { word: 'legible', word_class: 'AJ' },
    { word: 'legibly', word_class: 'AV' },
    { word: 'legibility', word_class: 'N' }
  ],
  skinniness: [
    { word: 'skinny', word_class: 'N' },
    { word: 'skinny', word_class: 'AJ' },
    { word: 'skinniness', word_class: 'N' }
  ],
  moulder: [
    { word: 'moulder', word_class: 'V' },
    { word: 'mouldered', word_class: 'AJ' },
    { word: 'mouldering', word_class: 'AJ' }
  ],
  mistreat: [
    { word: 'mistreat', word_class: 'V' },
    { word: 'mistreated', word_class: 'AJ' },
    { word: 'mistreatment', word_class: 'N' }
  ],
  promiscuity: [
    { word: 'promiscuity', word_class: 'N' },
    { word: 'promiscuous', word_class: 'AJ' },
    { word: 'promiscuously', word_class: 'AV' },
    { word: 'promiscuousness', word_class: 'N' }
  ],
  crouched: [
    { word: 'crouch', word_class: 'N' },
    { word: 'crouch', word_class: 'V' },
    { word: 'crouched', word_class: 'AJ' },
    { word: 'crouching', word_class: 'AJ' }
  ],
  unacceptableness: [
    { word: 'unaccepted', word_class: 'AJ' },
    { word: 'unacceptable', word_class: 'AJ' },
    { word: 'unacceptably', word_class: 'AV' },
    { word: 'unacceptability', word_class: 'N' },
    { word: 'unacceptableness', word_class: 'N' }
  ],
  bald: [
    { word: 'bald', word_class: 'V' },
    { word: 'bald', word_class: 'AJ' },
    { word: 'baldy', word_class: 'N' },
    { word: 'balding', word_class: 'AJ' },
    { word: 'baldness', word_class: 'N' }
  ],
  agile: [
    { word: 'agile', word_class: 'AJ' },
    { word: 'agility', word_class: 'N' },
    { word: 'agilely', word_class: 'AV' }
  ],
  lowbrow: [
    { word: 'lowbrow', word_class: 'N' },
    { word: 'lowbrow', word_class: 'AJ' },
    { word: 'lowbrowed', word_class: 'AJ' }
  ],
  ultimateness: [
    { word: 'ultimate', word_class: 'AJ' },
    { word: 'ultimately', word_class: 'AV' },
    { word: 'ultimateness', word_class: 'N' }
  ],
  correlative: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  goral: [{ word: 'gore', word_class: 'N' }, { word: 'gore', word_class: 'V' }, { word: 'goral', word_class: 'N' }],
  clad: [{ word: 'clad', word_class: 'V' }, { word: 'clad', word_class: 'AJ' }, { word: 'cladding', word_class: 'N' }],
  bastardized: [
    { word: 'bastard', word_class: 'N' },
    { word: 'bastard', word_class: 'AJ' },
    { word: 'bastardize', word_class: 'V' },
    { word: 'bastardized', word_class: 'AJ' },
    { word: 'bastardization', word_class: 'N' }
  ],
  editorialisation: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  reparable: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  nominalism: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  globular: [
    { word: 'globe', word_class: 'N' },
    { word: 'globular', word_class: 'AJ' },
    { word: 'globularness', word_class: 'N' }
  ],
  icing: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  passer: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  damper: [
    { word: 'damp', word_class: 'N' },
    { word: 'damp', word_class: 'V' },
    { word: 'damp', word_class: 'AJ' },
    { word: 'damper', word_class: 'N' },
    { word: 'dampness', word_class: 'N' }
  ],
  aberrancy: [
    { word: 'aberrant', word_class: 'N' },
    { word: 'aberrate', word_class: 'V' },
    { word: 'aberrance', word_class: 'N' },
    { word: 'aberrancy', word_class: 'N' },
    { word: 'aberrant', word_class: 'AJ' },
    { word: 'aberration', word_class: 'N' }
  ],
  disputed: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  infrequent: [
    { word: 'infrequency', word_class: 'N' },
    { word: 'infrequent', word_class: 'AJ' },
    { word: 'infrequently', word_class: 'AV' }
  ],
  etherealization: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  burled: [{ word: 'burl', word_class: 'N' }, { word: 'burl', word_class: 'V' }, { word: 'burled', word_class: 'AJ' }],
  animalize: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  insured: [
    { word: 'insure', word_class: 'V' },
    { word: 'insure', word_class: 'AJ' },
    { word: 'insurer', word_class: 'N' },
    { word: 'insured', word_class: 'AJ' },
    { word: 'insurance', word_class: 'N' }
  ],
  conceptualism: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  phosphatise: [
    { word: 'phosphate', word_class: 'N' },
    { word: 'phosphatise', word_class: 'V' },
    { word: 'phosphatize', word_class: 'V' },
    { word: 'phosphatisation', word_class: 'N' },
    { word: 'phosphatization', word_class: 'N' }
  ],
  epical: [{ word: 'epic', word_class: 'N' }, { word: 'epic', word_class: 'AJ' }, { word: 'epical', word_class: 'AJ' }],
  normative: [
    { word: 'norm', word_class: 'N' },
    { word: 'normalcy', word_class: 'N' },
    { word: 'normative', word_class: 'AJ' }
  ],
  sorrowful: [
    { word: 'sorrow', word_class: 'N' },
    { word: 'sorrow', word_class: 'V' },
    { word: 'sorrowful', word_class: 'AJ' },
    { word: 'sorrowing', word_class: 'AJ' },
    { word: 'sorrowfulness', word_class: 'N' }
  ],
  voice: [{ word: 'voice', word_class: 'N' }, { word: 'voice', word_class: 'V' }, { word: 'voiced', word_class: 'AJ' }],
  mine: [
    { word: 'mine', word_class: 'N' },
    { word: 'mine', word_class: 'V' },
    { word: 'miner', word_class: 'N' },
    { word: 'mined', word_class: 'AJ' },
    { word: 'mining', word_class: 'N' },
    { word: 'minion', word_class: 'N' },
    { word: 'minor', word_class: 'N' }
  ],
  forswearing: [
    { word: 'forswear', word_class: 'V' },
    { word: 'forsworn', word_class: 'AJ' },
    { word: 'forswearing', word_class: 'N' }
  ],
  inappropriate: [
    { word: 'inappropriate', word_class: 'AJ' },
    { word: 'inappropriately', word_class: 'AV' },
    { word: 'inappropriateness', word_class: 'N' }
  ],
  gashed: [{ word: 'gash', word_class: 'N' }, { word: 'gash', word_class: 'V' }, { word: 'gashed', word_class: 'AJ' }],
  resent: [
    { word: 'resent', word_class: 'V' },
    { word: 'resentful', word_class: 'AJ' },
    { word: 'resentment', word_class: 'N' }
  ],
  impel: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  burdensomeness: [
    { word: 'burden', word_class: 'N' },
    { word: 'burden', word_class: 'V' },
    { word: 'burdened', word_class: 'AJ' },
    { word: 'burdensome', word_class: 'AJ' },
    { word: 'burdensomeness', word_class: 'N' }
  ],
  serval: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  improbableness: [
    { word: 'improbable', word_class: 'AJ' },
    { word: 'improbably', word_class: 'AV' },
    { word: 'improbability', word_class: 'N' },
    { word: 'improbableness', word_class: 'N' }
  ],
  customer: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  cooperator: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  expressionistic: [
    { word: 'expressionist', word_class: 'N' },
    { word: 'expressionist', word_class: 'AJ' },
    { word: 'expressionistic', word_class: 'AJ' }
  ],
  blubber: [
    { word: 'blubber', word_class: 'N' },
    { word: 'blubber', word_class: 'V' },
    { word: 'blubber', word_class: 'AJ' }
  ],
  liquifaction: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  admit: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  amidships: [
    { word: 'amidship', word_class: 'AJ' },
    { word: 'amidship', word_class: 'AV' },
    { word: 'amidships', word_class: 'AV' }
  ],
  conciseness: [
    { word: 'concise', word_class: 'AJ' },
    { word: 'concision', word_class: 'N' },
    { word: 'concisely', word_class: 'AV' },
    { word: 'conciseness', word_class: 'N' }
  ],
  captive: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  remote: [
    { word: 'remote', word_class: 'N' },
    { word: 'remote', word_class: 'AJ' },
    { word: 'remotion', word_class: 'N' },
    { word: 'remotely', word_class: 'AV' },
    { word: 'remoteness', word_class: 'N' }
  ],
  sterilise: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  frustrating: [
    { word: 'frustrate', word_class: 'V' },
    { word: 'frustrated', word_class: 'AJ' },
    { word: 'frustrating', word_class: 'N' },
    { word: 'frustration', word_class: 'N' },
    { word: 'frustrating', word_class: 'AJ' }
  ],
  pretend: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  coherent: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  decadent: [
    { word: 'decade', word_class: 'N' },
    { word: 'decadent', word_class: 'N' },
    { word: 'decadence', word_class: 'N' },
    { word: 'decadent', word_class: 'AJ' }
  ],
  materialistically: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  eruption: [
    { word: 'erupt', word_class: 'V' },
    { word: 'eruption', word_class: 'N' },
    { word: 'erupting', word_class: 'AJ' },
    { word: 'eruptive', word_class: 'AJ' }
  ],
  weighted: [
    { word: 'weigh', word_class: 'V' },
    { word: 'weight', word_class: 'N' },
    { word: 'weight', word_class: 'V' },
    { word: 'weighing', word_class: 'N' },
    { word: 'weighted', word_class: 'AJ' },
    { word: 'weighting', word_class: 'N' }
  ],
  legalise: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  catholicism: [
    { word: 'catholicise', word_class: 'V' },
    { word: 'catholicism', word_class: 'N' },
    { word: 'catholicize', word_class: 'V' },
    { word: 'catholicisation', word_class: 'N' },
    { word: 'catholicization', word_class: 'N' }
  ],
  cede: [{ word: 'cede', word_class: 'V' }, { word: 'ceding', word_class: 'N' }, { word: 'cession', word_class: 'N' }],
  restraint: [
    { word: 'restrain', word_class: 'N' },
    { word: 'restrain', word_class: 'V' },
    { word: 'restraint', word_class: 'N' },
    { word: 'restrained', word_class: 'AJ' }
  ],
  centroidal: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  rigged: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  bestiality: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  hogged: [{ word: 'hog', word_class: 'N' }, { word: 'hog', word_class: 'V' }, { word: 'hogged', word_class: 'AJ' }],
  croaker: [
    { word: 'croak', word_class: 'N' },
    { word: 'croak', word_class: 'V' },
    { word: 'croaker', word_class: 'N' },
    { word: 'croaking', word_class: 'N' },
    { word: 'croaking', word_class: 'AJ' }
  ],
  pharmacological: [
    { word: 'pharmacology', word_class: 'N' },
    { word: 'pharmacologic', word_class: 'AJ' },
    { word: 'pharmacological', word_class: 'AJ' },
    { word: 'pharmacologically', word_class: 'AV' }
  ],
  vegetal: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  panicky: [
    { word: 'panic', word_class: 'N' },
    { word: 'panic', word_class: 'V' },
    { word: 'panic', word_class: 'AJ' },
    { word: 'panicky', word_class: 'AJ' }
  ],
  attitudinal: [
    { word: 'attitude', word_class: 'N' },
    { word: 'attitudinal', word_class: 'AJ' },
    { word: 'attitudinize', word_class: 'V' },
    { word: 'attitudinization', word_class: 'N' }
  ],
  cause: [
    { word: 'cause', word_class: 'N' },
    { word: 'cause', word_class: 'V' },
    { word: 'causing', word_class: 'N' },
    { word: 'causation', word_class: 'N' },
    { word: 'causative', word_class: 'AJ' }
  ],
  adhesiveness: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  'fire-resistant': [
    { word: 'fire-resistant', word_class: 'AJ' },
    { word: 'fire-resisting', word_class: 'AJ' },
    { word: 'fire-resistive', word_class: 'AJ' }
  ],
  arboreous: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  containment: [
    { word: 'contain', word_class: 'V' },
    { word: 'container', word_class: 'N' },
    { word: 'contained', word_class: 'AJ' },
    { word: 'containment', word_class: 'N' },
    { word: 'containerful', word_class: 'N' }
  ],
  perceptive: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  cylinder: [
    { word: 'cylinder', word_class: 'N' },
    { word: 'cylindric', word_class: 'AJ' },
    { word: 'cylindrical', word_class: 'AJ' },
    { word: 'cylindricality', word_class: 'N' }
  ],
  financing: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  dizziness: [
    { word: 'dizzy', word_class: 'V' },
    { word: 'dizzy', word_class: 'AJ' },
    { word: 'dizziness', word_class: 'N' }
  ],
  agrobiologic: [
    { word: 'agrobiology', word_class: 'N' },
    { word: 'agrobiologic', word_class: 'AJ' },
    { word: 'agrobiological', word_class: 'AJ' }
  ],
  agonizing: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  combinable: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  tears: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  conspirative: [
    { word: 'conspire', word_class: 'V' },
    { word: 'conspiracy', word_class: 'N' },
    { word: 'conspirator', word_class: 'N' },
    { word: 'conspirative', word_class: 'AJ' }
  ],
  vitriolic: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  hypothetical: [
    { word: 'hypothetic', word_class: 'AJ' },
    { word: 'hypothetical', word_class: 'AJ' },
    { word: 'hypothetically', word_class: 'AV' }
  ],
  lacing: [
    { word: 'lace', word_class: 'N' },
    { word: 'lace', word_class: 'V' },
    { word: 'laced', word_class: 'AJ' },
    { word: 'lacing', word_class: 'N' }
  ],
  deeds: [{ word: 'deed', word_class: 'N' }, { word: 'deed', word_class: 'V' }, { word: 'deeds', word_class: 'N' }],
  assurance: [
    { word: 'assur', word_class: 'N' },
    { word: 'assure', word_class: 'V' },
    { word: 'assured', word_class: 'AJ' },
    { word: 'assurance', word_class: 'N' },
    { word: 'assuring', word_class: 'AJ' }
  ],
  conspiracy: [
    { word: 'conspire', word_class: 'V' },
    { word: 'conspiracy', word_class: 'N' },
    { word: 'conspirator', word_class: 'N' },
    { word: 'conspirative', word_class: 'AJ' }
  ],
  enormity: [
    { word: 'enormity', word_class: 'N' },
    { word: 'enormous', word_class: 'AJ' },
    { word: 'enormously', word_class: 'AV' },
    { word: 'enormousness', word_class: 'N' }
  ],
  discomfiture: [
    { word: 'discomfit', word_class: 'N' },
    { word: 'discomfit', word_class: 'V' },
    { word: 'discomfited', word_class: 'N' },
    { word: 'discomfited', word_class: 'AJ' },
    { word: 'discomfiture', word_class: 'N' }
  ],
  congratulation: [
    { word: 'congratulate', word_class: 'V' },
    { word: 'congratulation', word_class: 'N' },
    { word: 'congratulations', word_class: 'N' }
  ],
  thinness: [
    { word: 'thin', word_class: 'V' },
    { word: 'thin', word_class: 'AJ' },
    { word: 'thin', word_class: 'AV' },
    { word: 'thinned', word_class: 'AJ' },
    { word: 'thinness', word_class: 'N' },
    { word: 'thinning', word_class: 'N' }
  ],
  header: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  ash: [{ word: 'ash', word_class: 'N' }, { word: 'ash', word_class: 'V' }, { word: 'ashen', word_class: 'AJ' }],
  recluse: [
    { word: 'recluse', word_class: 'N' },
    { word: 'recluse', word_class: 'AJ' },
    { word: 'reclusive', word_class: 'AJ' },
    { word: 'reclusiveness', word_class: 'N' }
  ],
  rim: [{ word: 'rim', word_class: 'N' }, { word: 'rim', word_class: 'V' }, { word: 'rimmed', word_class: 'AJ' }],
  evert: [
    { word: 'evert', word_class: 'V' },
    { word: 'eversion', word_class: 'N' },
    { word: 'everting', word_class: 'N' }
  ],
  smuggler: [
    { word: 'smuggle', word_class: 'V' },
    { word: 'smuggler', word_class: 'N' },
    { word: 'smuggled', word_class: 'AJ' },
    { word: 'smuggling', word_class: 'N' }
  ],
  municipality: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  classically: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  flopping: [
    { word: 'flop', word_class: 'N' },
    { word: 'flop', word_class: 'V' },
    { word: 'flop', word_class: 'AV' },
    { word: 'flopping', word_class: 'N' }
  ],
  photoconductivity: [
    { word: 'photoconduction', word_class: 'N' },
    { word: 'photoconductive', word_class: 'AJ' },
    { word: 'photoconductivity', word_class: 'N' }
  ],
  motivated: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  divizibility: [
    { word: 'divizive', word_class: 'AJ' },
    { word: 'divizible', word_class: 'AJ' },
    { word: 'divizibility', word_class: 'N' }
  ],
  retailer: [
    { word: 'retail', word_class: 'N' },
    { word: 'retail', word_class: 'V' },
    { word: 'retail', word_class: 'AJ' },
    { word: 'retail', word_class: 'AV' },
    { word: 'retailer', word_class: 'N' },
    { word: 'retailing', word_class: 'N' }
  ],
  trump: [
    { word: 'trump', word_class: 'N' },
    { word: 'trump', word_class: 'V' },
    { word: 'trumping', word_class: 'N' }
  ],
  blunder: [
    { word: 'blunder', word_class: 'N' },
    { word: 'blunder', word_class: 'V' },
    { word: 'blunderer', word_class: 'N' }
  ],
  cyclonic: [
    { word: 'cyclone', word_class: 'N' },
    { word: 'cyclonal', word_class: 'AJ' },
    { word: 'cyclonic', word_class: 'AJ' },
    { word: 'cyclonical', word_class: 'AJ' }
  ],
  charger: [
    { word: 'charge', word_class: 'N' },
    { word: 'charge', word_class: 'V' },
    { word: 'charger', word_class: 'N' },
    { word: 'charged', word_class: 'AJ' }
  ],
  bite: [
    { word: 'bite', word_class: 'N' },
    { word: 'bite', word_class: 'V' },
    { word: 'biter', word_class: 'N' },
    { word: 'biting', word_class: 'AJ' },
    { word: 'bitten', word_class: 'AJ' }
  ],
  docker: [
    { word: 'dock', word_class: 'N' },
    { word: 'dock', word_class: 'V' },
    { word: 'docker', word_class: 'N' },
    { word: 'dockage', word_class: 'N' },
    { word: 'docked', word_class: 'AJ' },
    { word: 'docking', word_class: 'N' }
  ],
  curvature: [
    { word: 'curve', word_class: 'N' },
    { word: 'curve', word_class: 'V' },
    { word: 'curvy', word_class: 'AJ' },
    { word: 'curved', word_class: 'AJ' },
    { word: 'curving', word_class: 'AJ' },
    { word: 'curvature', word_class: 'N' }
  ],
  heedfulness: [
    { word: 'heed', word_class: 'N' },
    { word: 'heed', word_class: 'V' },
    { word: 'heedful', word_class: 'AJ' },
    { word: 'heedfulness', word_class: 'N' }
  ],
  dunkers: [
    { word: 'dunk', word_class: 'N' },
    { word: 'dunk', word_class: 'V' },
    { word: 'dunker', word_class: 'N' },
    { word: 'dunked', word_class: 'AJ' },
    { word: 'dunkers', word_class: 'N' }
  ],
  sanitize: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  spoilage: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  contrastive: [
    { word: 'contrast', word_class: 'N' },
    { word: 'contrast', word_class: 'V' },
    { word: 'contrasting', word_class: 'AJ' },
    { word: 'contrastive', word_class: 'AJ' }
  ],
  avid: [
    { word: 'avid', word_class: 'AJ' },
    { word: 'avidity', word_class: 'N' },
    { word: 'avidness', word_class: 'N' }
  ],
  formality: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  bewildering: [
    { word: 'bewilder', word_class: 'V' },
    { word: 'bewildered', word_class: 'AJ' },
    { word: 'bewildering', word_class: 'AJ' },
    { word: 'bewilderment', word_class: 'N' }
  ],
  lead: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  temperateness: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  shintoistic: [
    { word: 'shintoist', word_class: 'N' },
    { word: 'shintoist', word_class: 'AJ' },
    { word: 'shintoistic', word_class: 'AJ' }
  ],
  execrate: [
    { word: 'execrate', word_class: 'V' },
    { word: 'execrable', word_class: 'AJ' },
    { word: 'execration', word_class: 'N' }
  ],
  beaked: [{ word: 'beak', word_class: 'N' }, { word: 'beak', word_class: 'V' }, { word: 'beaked', word_class: 'AJ' }],
  rectangularity: [
    { word: 'rectangle', word_class: 'N' },
    { word: 'rectangular', word_class: 'AJ' },
    { word: 'rectangularity', word_class: 'N' }
  ],
  cricket: [
    { word: 'cricket', word_class: 'N' },
    { word: 'cricket', word_class: 'V' },
    { word: 'cricketer', word_class: 'N' }
  ],
  extensibility: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  ineffectuality: [
    { word: 'ineffectual', word_class: 'AJ' },
    { word: 'ineffectuality', word_class: 'N' },
    { word: 'ineffectually', word_class: 'AV' },
    { word: 'ineffectualness', word_class: 'N' }
  ],
  ribbing: [
    { word: 'rib', word_class: 'N' },
    { word: 'rib', word_class: 'V' },
    { word: 'ribbed', word_class: 'AJ' },
    { word: 'ribbing', word_class: 'N' }
  ],
  lithographic: [
    { word: 'lithograph', word_class: 'N' },
    { word: 'lithograph', word_class: 'V' },
    { word: 'lithography', word_class: 'N' },
    { word: 'lithographer', word_class: 'N' },
    { word: 'lithography', word_class: 'AJ' },
    { word: 'lithographic', word_class: 'AJ' }
  ],
  alligator: [
    { word: 'alligator', word_class: 'N' },
    { word: 'alligator', word_class: 'V' },
    { word: 'alligatored', word_class: 'AJ' }
  ],
  catabolize: [
    { word: 'catabolic', word_class: 'AJ' },
    { word: 'catabolism', word_class: 'N' },
    { word: 'catabolize', word_class: 'V' }
  ],
  dogmatize: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  doubt: [
    { word: 'doubt', word_class: 'N' },
    { word: 'doubt', word_class: 'V' },
    { word: 'doubter', word_class: 'N' },
    { word: 'doubtful', word_class: 'AJ' },
    { word: 'doubting', word_class: 'AJ' },
    { word: 'doubtfulness', word_class: 'N' }
  ],
  maniacal: [
    { word: 'maniac', word_class: 'N' },
    { word: 'maniac', word_class: 'AJ' },
    { word: 'maniacal', word_class: 'AJ' },
    { word: 'maniacally', word_class: 'AV' }
  ],
  hypostatize: [
    { word: 'hypostasis', word_class: 'N' },
    { word: 'hypostatise', word_class: 'V' },
    { word: 'hypostatize', word_class: 'V' },
    { word: 'hypostatisation', word_class: 'N' },
    { word: 'hypostatization', word_class: 'N' }
  ],
  holding: [
    { word: 'hold', word_class: 'N' },
    { word: 'hold', word_class: 'V' },
    { word: 'holder', word_class: 'N' },
    { word: 'holding', word_class: 'N' },
    { word: 'holding', word_class: 'AJ' }
  ],
  obvert: [
    { word: 'obvert', word_class: 'V' },
    { word: 'obverse', word_class: 'N' },
    { word: 'obverse', word_class: 'AJ' },
    { word: 'obversion', word_class: 'N' }
  ],
  speeder: [
    { word: 'speed', word_class: 'N' },
    { word: 'speed', word_class: 'V' },
    { word: 'speeder', word_class: 'N' },
    { word: 'speeding', word_class: 'N' },
    { word: 'speeding', word_class: 'AJ' }
  ],
  flap: [
    { word: 'flap', word_class: 'N' },
    { word: 'flap', word_class: 'V' },
    { word: 'flaps', word_class: 'N' },
    { word: 'flapping', word_class: 'N' }
  ],
  extensive: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  etymologizing: [
    { word: 'etymology', word_class: 'N' },
    { word: 'etymologize', word_class: 'V' },
    { word: 'etymological', word_class: 'AJ' },
    { word: 'etymologizing', word_class: 'N' },
    { word: 'etymologization', word_class: 'N' }
  ],
  parvenue: [
    { word: 'parvenu', word_class: 'N' },
    { word: 'parvenu', word_class: 'AJ' },
    { word: 'parvenue', word_class: 'AJ' }
  ],
  metonymic: [
    { word: 'metonym', word_class: 'N' },
    { word: 'metonymic', word_class: 'AJ' },
    { word: 'metonymical', word_class: 'AJ' },
    { word: 'metonymically', word_class: 'AV' }
  ],
  federalisation: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  divest: [
    { word: 'devest', word_class: 'V' },
    { word: 'divest', word_class: 'N' },
    { word: 'divest', word_class: 'V' },
    { word: 'divesture', word_class: 'N' },
    { word: 'divestment', word_class: 'N' },
    { word: 'divestiture', word_class: 'N' }
  ],
  habitably: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  negativism: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  beetling: [
    { word: 'beetle', word_class: 'N' },
    { word: 'beetle', word_class: 'V' },
    { word: 'beetle', word_class: 'AJ' },
    { word: 'beetling', word_class: 'AJ' }
  ],
  'place-kicker': [
    { word: 'place-kick', word_class: 'V' },
    { word: 'place-kicker', word_class: 'N' },
    { word: 'place-kicking', word_class: 'N' }
  ],
  continued: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  launching: [
    { word: 'launch', word_class: 'N' },
    { word: 'launch', word_class: 'V' },
    { word: 'launcher', word_class: 'N' },
    { word: 'launching', word_class: 'N' }
  ],
  informer: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  pivot: [
    { word: 'pivot', word_class: 'N' },
    { word: 'pivot', word_class: 'V' },
    { word: 'pivotal', word_class: 'AJ' }
  ],
  advantage: [
    { word: 'advantage', word_class: 'N' },
    { word: 'advantage', word_class: 'V' },
    { word: 'advantaged', word_class: 'AJ' },
    { word: 'advantageous', word_class: 'AJ' },
    { word: 'advantageously', word_class: 'AV' },
    { word: 'advantageousness', word_class: 'N' }
  ],
  advertent: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  amenableness: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  asynchrony: [
    { word: 'asynchrony', word_class: 'N' },
    { word: 'asynchronism', word_class: 'N' },
    { word: 'asynchronous', word_class: 'AJ' }
  ],
  creditable: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  atone: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  infixation: [
    { word: 'infix', word_class: 'N' },
    { word: 'infix', word_class: 'V' },
    { word: 'infixion', word_class: 'N' },
    { word: 'infixation', word_class: 'N' }
  ],
  reactant: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  hydrogenization: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  rarely: [
    { word: 'rare', word_class: 'AJ' },
    { word: 'rarely', word_class: 'AV' },
    { word: 'raring', word_class: 'AJ' },
    { word: 'rareness', word_class: 'N' }
  ],
  queerness: [
    { word: 'queer', word_class: 'N' },
    { word: 'queer', word_class: 'V' },
    { word: 'queer', word_class: 'AJ' },
    { word: 'queerness', word_class: 'N' }
  ],
  belliful: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  fumble: [
    { word: 'fumble', word_class: 'N' },
    { word: 'fumble', word_class: 'V' },
    { word: 'fumbler', word_class: 'N' },
    { word: 'fumbling', word_class: 'AJ' }
  ],
  energizing: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  verbosity: [
    { word: 'verbose', word_class: 'AJ' },
    { word: 'verbosity', word_class: 'N' },
    { word: 'verbosely', word_class: 'AV' },
    { word: 'verboseness', word_class: 'N' }
  ],
  department: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  convertibility: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  bid: [
    { word: 'bid', word_class: 'N' },
    { word: 'bid', word_class: 'V' },
    { word: 'bidden', word_class: 'AJ' },
    { word: 'bidding', word_class: 'N' }
  ],
  amicable: [
    { word: 'amicable', word_class: 'AJ' },
    { word: 'amicably', word_class: 'AV' },
    { word: 'amicability', word_class: 'N' },
    { word: 'amicableness', word_class: 'N' }
  ],
  faith: [
    { word: 'faith', word_class: 'N' },
    { word: 'faithful', word_class: 'N' },
    { word: 'faithful', word_class: 'AJ' },
    { word: 'faithfulness', word_class: 'N' }
  ],
  executioner: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  dialyze: [
    { word: 'dialyze', word_class: 'V' },
    { word: 'dialysis', word_class: 'N' },
    { word: 'dialyzer', word_class: 'N' }
  ],
  hypertrophied: [
    { word: 'hypertrophy', word_class: 'N' },
    { word: 'hypertrophy', word_class: 'V' },
    { word: 'hypertrophied', word_class: 'AJ' }
  ],
  cracklings: [
    { word: 'crackle', word_class: 'N' },
    { word: 'crackle', word_class: 'V' },
    { word: 'crackle', word_class: 'AJ' },
    { word: 'crackling', word_class: 'N' },
    { word: 'crackled', word_class: 'AJ' },
    { word: 'crackling', word_class: 'AJ' },
    { word: 'cracklings', word_class: 'N' }
  ],
  latinate: [
    { word: 'latin', word_class: 'N' },
    { word: 'latin', word_class: 'AJ' },
    { word: 'latinize', word_class: 'V' },
    { word: 'latinist', word_class: 'N' },
    { word: 'latinate', word_class: 'AJ' },
    { word: 'latinization', word_class: 'N' }
  ],
  longness: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  encirclement: [
    { word: 'encircle', word_class: 'V' },
    { word: 'encircled', word_class: 'AJ' },
    { word: 'encircling', word_class: 'AJ' },
    { word: 'encirclement', word_class: 'N' }
  ],
  barbarity: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  mender: [
    { word: 'mend', word_class: 'N' },
    { word: 'mend', word_class: 'V' },
    { word: 'mender', word_class: 'N' },
    { word: 'mending', word_class: 'N' }
  ],
  jester: [
    { word: 'jest', word_class: 'N' },
    { word: 'jest', word_class: 'V' },
    { word: 'jester', word_class: 'N' },
    { word: 'jesting', word_class: 'AJ' }
  ],
  velarize: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  altered: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  ascendible: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  quiz: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  autobiographer: [
    { word: 'autobiographer', word_class: 'N' },
    { word: 'autobiographic', word_class: 'AJ' },
    { word: 'autobiographical', word_class: 'AJ' }
  ],
  unloved: [
    { word: 'unloved', word_class: 'AJ' },
    { word: 'unlovely', word_class: 'AJ' },
    { word: 'unloving', word_class: 'AJ' },
    { word: 'unlovable', word_class: 'AJ' }
  ],
  integrable: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  quintuplicate: [
    { word: 'quintuple', word_class: 'V' },
    { word: 'quintuple', word_class: 'AJ' },
    { word: 'quintupling', word_class: 'N' },
    { word: 'quintuplicate', word_class: 'V' },
    { word: 'quintuplication', word_class: 'N' }
  ],
  tidings: [
    { word: 'tide', word_class: 'N' },
    { word: 'tide', word_class: 'V' },
    { word: 'tiding', word_class: 'N' },
    { word: 'tidings', word_class: 'N' }
  ],
  ingratiate: [
    { word: 'ingratiate', word_class: 'V' },
    { word: 'ingratiation', word_class: 'N' },
    { word: 'ingratiating', word_class: 'AJ' }
  ],
  spurting: [
    { word: 'spurt', word_class: 'N' },
    { word: 'spurt', word_class: 'V' },
    { word: 'spurting', word_class: 'AJ' }
  ],
  actualized: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  agonization: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  ordain: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  oozing: [
    { word: 'ooze', word_class: 'N' },
    { word: 'ooze', word_class: 'V' },
    { word: 'oozy', word_class: 'AJ' },
    { word: 'oozing', word_class: 'N' },
    { word: 'oozing', word_class: 'AJ' }
  ],
  pureblooded: [
    { word: 'pureblood', word_class: 'N' },
    { word: 'pureblood', word_class: 'AJ' },
    { word: 'pureblooded', word_class: 'AJ' }
  ],
  dowdy: [
    { word: 'dowdy', word_class: 'N' },
    { word: 'dowdy', word_class: 'AJ' },
    { word: 'dowdiness', word_class: 'N' }
  ],
  eater: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  alphanumeric: [
    { word: 'alphanumeric', word_class: 'AJ' },
    { word: 'alphanumerics', word_class: 'N' },
    { word: 'alphanumerical', word_class: 'AJ' }
  ],
  inexpedient: [
    { word: 'inexpedience', word_class: 'N' },
    { word: 'inexpediency', word_class: 'N' },
    { word: 'inexpedient', word_class: 'AJ' },
    { word: 'inexpediently', word_class: 'AV' }
  ],
  decarbonise: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  distress: [
    { word: 'distress', word_class: 'N' },
    { word: 'distress', word_class: 'V' },
    { word: 'distressed', word_class: 'AJ' },
    { word: 'distressful', word_class: 'AJ' },
    { word: 'distressing', word_class: 'AJ' },
    { word: 'distressfulness', word_class: 'N' }
  ],
  dowered: [
    { word: 'dower', word_class: 'N' },
    { word: 'dower', word_class: 'V' },
    { word: 'dowered', word_class: 'AJ' }
  ],
  insipid: [
    { word: 'insipid', word_class: 'AJ' },
    { word: 'insipidity', word_class: 'N' },
    { word: 'insipidness', word_class: 'N' }
  ],
  laconic: [
    { word: 'laconic', word_class: 'AJ' },
    { word: 'laconism', word_class: 'N' },
    { word: 'laconically', word_class: 'AV' }
  ],
  uphill: [
    { word: 'uphill', word_class: 'N' },
    { word: 'uphill', word_class: 'AJ' },
    { word: 'uphill', word_class: 'AV' }
  ],
  fineness: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  diphthongize: [
    { word: 'diphthong', word_class: 'N' },
    { word: 'diphthongise', word_class: 'V' },
    { word: 'diphthongize', word_class: 'V' },
    { word: 'diphthongisation', word_class: 'N' },
    { word: 'diphthongization', word_class: 'N' }
  ],
  tubular: [
    { word: 'tube', word_class: 'N' },
    { word: 'tube', word_class: 'V' },
    { word: 'tubed', word_class: 'AJ' },
    { word: 'tubing', word_class: 'N' },
    { word: 'tubular', word_class: 'AJ' }
  ],
  mark: [
    { word: 'mark', word_class: 'N' },
    { word: 'mark', word_class: 'V' },
    { word: 'marker', word_class: 'N' },
    { word: 'marked', word_class: 'AJ' },
    { word: 'marking', word_class: 'N' }
  ],
  wrestling: [
    { word: 'wrestle', word_class: 'N' },
    { word: 'wrestle', word_class: 'V' },
    { word: 'wrestler', word_class: 'N' },
    { word: 'wrestling', word_class: 'N' }
  ],
  unbelievable: [
    { word: 'unbeliever', word_class: 'N' },
    { word: 'unbelieving', word_class: 'AJ' },
    { word: 'unbelievable', word_class: 'AJ' },
    { word: 'unbelievably', word_class: 'AV' }
  ],
  accordion: [
    { word: 'accord', word_class: 'N' },
    { word: 'accord', word_class: 'V' },
    { word: 'accordion', word_class: 'N' },
    { word: 'accordance', word_class: 'N' },
    { word: 'accordant', word_class: 'AJ' },
    { word: 'according', word_class: 'AJ' },
    { word: 'accordion', word_class: 'AJ' }
  ],
  contemporize: [
    { word: 'contemporize', word_class: 'V' },
    { word: 'contemporaneity', word_class: 'N' },
    { word: 'contemporaneous', word_class: 'AJ' },
    { word: 'contemporaneously', word_class: 'AV' },
    { word: 'contemporaneousness', word_class: 'N' }
  ],
  clairvoyant: [
    { word: 'clairvoyant', word_class: 'N' },
    { word: 'clairvoyance', word_class: 'N' },
    { word: 'clairvoyant', word_class: 'AJ' }
  ],
  biologic: [
    { word: 'biologic', word_class: 'AJ' },
    { word: 'biologism', word_class: 'N' },
    { word: 'biological', word_class: 'AJ' },
    { word: 'biologically', word_class: 'AV' }
  ],
  singly: [
    { word: 'single', word_class: 'N' },
    { word: 'single', word_class: 'V' },
    { word: 'single', word_class: 'AJ' },
    { word: 'singles', word_class: 'N' },
    { word: 'singly', word_class: 'AV' },
    { word: 'singleness', word_class: 'N' }
  ],
  responder: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  inhibiting: [
    { word: 'inhibit', word_class: 'V' },
    { word: 'inhibitor', word_class: 'N' },
    { word: 'inhibited', word_class: 'AJ' },
    { word: 'inhibition', word_class: 'N' },
    { word: 'inhibiting', word_class: 'AJ' }
  ],
  intumesce: [
    { word: 'intumesce', word_class: 'V' },
    { word: 'intumescence', word_class: 'N' },
    { word: 'intumescency', word_class: 'N' }
  ],
  defender: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  rendering: [
    { word: 'render', word_class: 'N' },
    { word: 'render', word_class: 'V' },
    { word: 'rendering', word_class: 'N' },
    { word: 'rendition', word_class: 'N' }
  ],
  oxygenation: [
    { word: 'oxygen', word_class: 'N' },
    { word: 'oxygenate', word_class: 'V' },
    { word: 'oxygenize', word_class: 'V' },
    { word: 'oxygenated', word_class: 'AJ' },
    { word: 'oxygenation', word_class: 'N' }
  ],
  magnificent: [
    { word: 'magnificence', word_class: 'N' },
    { word: 'magnificent', word_class: 'AJ' },
    { word: 'magnificently', word_class: 'AV' }
  ],
  hostelry: [
    { word: 'hostel', word_class: 'N' },
    { word: 'hostelry', word_class: 'N' },
    { word: 'hosteller', word_class: 'N' }
  ],
  ozonize: [
    { word: 'ozone', word_class: 'N' },
    { word: 'ozonise', word_class: 'V' },
    { word: 'ozonize', word_class: 'V' },
    { word: 'ozonisation', word_class: 'N' },
    { word: 'ozonization', word_class: 'N' }
  ],
  boozing: [
    { word: 'booze', word_class: 'N' },
    { word: 'booze', word_class: 'V' },
    { word: 'boozer', word_class: 'N' },
    { word: 'boozy', word_class: 'AJ' },
    { word: 'boozing', word_class: 'N' }
  ],
  souring: [
    { word: 'sour', word_class: 'N' },
    { word: 'sour', word_class: 'V' },
    { word: 'sour', word_class: 'AJ' },
    { word: 'soured', word_class: 'AJ' },
    { word: 'souring', word_class: 'N' },
    { word: 'sourness', word_class: 'N' }
  ],
  negligently: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  chamber: [
    { word: 'chamber', word_class: 'N' },
    { word: 'chamber', word_class: 'V' },
    { word: 'chambered', word_class: 'AJ' }
  ],
  closet: [
    { word: 'closet', word_class: 'N' },
    { word: 'closet', word_class: 'V' },
    { word: 'closet', word_class: 'AJ' }
  ],
  refer: [
    { word: 'refer', word_class: 'V' },
    { word: 'referent', word_class: 'N' },
    { word: 'referral', word_class: 'N' },
    { word: 'reference', word_class: 'N' },
    { word: 'reference', word_class: 'V' },
    { word: 'referable', word_class: 'AJ' }
  ],
  marry: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  treason: [
    { word: 'treason', word_class: 'N' },
    { word: 'treasonous', word_class: 'AJ' },
    { word: 'treasonable', word_class: 'AJ' },
    { word: 'treasonably', word_class: 'AV' }
  ],
  shellac: [
    { word: 'shellac', word_class: 'N' },
    { word: 'shellac', word_class: 'V' },
    { word: 'shellacking', word_class: 'AJ' }
  ],
  values: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  endorser: [
    { word: 'endorse', word_class: 'V' },
    { word: 'endorser', word_class: 'N' },
    { word: 'endorsed', word_class: 'AJ' },
    { word: 'endorsement', word_class: 'N' }
  ],
  bewilderment: [
    { word: 'bewilder', word_class: 'V' },
    { word: 'bewildered', word_class: 'AJ' },
    { word: 'bewildering', word_class: 'AJ' },
    { word: 'bewilderment', word_class: 'N' }
  ],
  strengthening: [
    { word: 'strong', word_class: 'AJ' },
    { word: 'strengthen', word_class: 'V' },
    { word: 'strengthener', word_class: 'N' },
    { word: 'strengthened', word_class: 'AJ' },
    { word: 'strengthening', word_class: 'N' },
    { word: 'strengthening', word_class: 'AJ' }
  ],
  oxidised: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  changeful: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  squelch: [
    { word: 'squelch', word_class: 'N' },
    { word: 'squelch', word_class: 'V' },
    { word: 'squelched', word_class: 'AJ' }
  ],
  congest: [
    { word: 'congest', word_class: 'V' },
    { word: 'congested', word_class: 'AJ' },
    { word: 'congestion', word_class: 'N' },
    { word: 'congestive', word_class: 'AJ' }
  ],
  bestowed: [
    { word: 'bestow', word_class: 'V' },
    { word: 'bestowal', word_class: 'N' },
    { word: 'bestowed', word_class: 'AJ' },
    { word: 'bestowment', word_class: 'N' }
  ],
  attrited: [
    { word: 'attrited', word_class: 'AJ' },
    { word: 'attrition', word_class: 'N' },
    { word: 'attritional', word_class: 'AJ' }
  ],
  hairdress: [
    { word: 'hairdress', word_class: 'V' },
    { word: 'hairdresser', word_class: 'N' },
    { word: 'hairdressing', word_class: 'N' }
  ],
  thermoelectrical: [
    { word: 'thermoelectric', word_class: 'AJ' },
    { word: 'thermoelectrical', word_class: 'AJ' },
    { word: 'thermoelectricity', word_class: 'N' }
  ],
  caulk: [
    { word: 'caulk', word_class: 'N' },
    { word: 'caulk', word_class: 'V' },
    { word: 'caulked', word_class: 'AJ' },
    { word: 'caulking', word_class: 'N' }
  ],
  emendment: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  boldface: [
    { word: 'boldface', word_class: 'N' },
    { word: 'boldface', word_class: 'V' },
    { word: 'boldface', word_class: 'AJ' }
  ],
  adsorbent: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  devotional: [
    { word: 'devote', word_class: 'V' },
    { word: 'devoted', word_class: 'AJ' },
    { word: 'devotion', word_class: 'N' },
    { word: 'devotement', word_class: 'N' },
    { word: 'devotional', word_class: 'AJ' }
  ],
  schematic: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  cry: [
    { word: 'cry', word_class: 'N' },
    { word: 'cry', word_class: 'V' },
    { word: 'crier', word_class: 'N' },
    { word: 'crying', word_class: 'N' },
    { word: 'crying', word_class: 'AJ' }
  ],
  wobbly: [
    { word: 'wobble', word_class: 'N' },
    { word: 'wobble', word_class: 'V' },
    { word: 'wobbler', word_class: 'N' },
    { word: 'wobbly', word_class: 'AJ' },
    { word: 'wobbling', word_class: 'AJ' }
  ],
  parenthesization: [
    { word: 'parenthesis', word_class: 'N' },
    { word: 'parenthesize', word_class: 'V' },
    { word: 'parenthesization', word_class: 'N' }
  ],
  preachment: [
    { word: 'preach', word_class: 'V' },
    { word: 'preacher', word_class: 'N' },
    { word: 'preachy', word_class: 'AJ' },
    { word: 'preaching', word_class: 'N' },
    { word: 'preachment', word_class: 'N' }
  ],
  synopsis: [
    { word: 'synopsis', word_class: 'N' },
    { word: 'synopsise', word_class: 'V' },
    { word: 'synopsize', word_class: 'V' },
    { word: 'synopsization', word_class: 'N' }
  ],
  superordinate: [
    { word: 'superordinate', word_class: 'N' },
    { word: 'superordinate', word_class: 'AJ' },
    { word: 'superordination', word_class: 'N' }
  ],
  strewing: [
    { word: 'strew', word_class: 'V' },
    { word: 'strewn', word_class: 'AJ' },
    { word: 'strewing', word_class: 'N' }
  ],
  governing: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  repairable: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  malingerer: [
    { word: 'malinger', word_class: 'V' },
    { word: 'malingerer', word_class: 'N' },
    { word: 'malingering', word_class: 'N' }
  ],
  velarization: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  coupling: [
    { word: 'couple', word_class: 'N' },
    { word: 'couple', word_class: 'V' },
    { word: 'couple', word_class: 'AJ' },
    { word: 'coupler', word_class: 'N' },
    { word: 'coupled', word_class: 'AJ' },
    { word: 'coupling', word_class: 'N' }
  ],
  tongued: [
    { word: 'tongue', word_class: 'N' },
    { word: 'tongue', word_class: 'V' },
    { word: 'tongued', word_class: 'AJ' }
  ],
  asexually: [
    { word: 'asexual', word_class: 'AJ' },
    { word: 'asexuality', word_class: 'N' },
    { word: 'asexually', word_class: 'AV' },
    { word: 'nonsexual', word_class: 'AJ' }
  ],
  flustered: [
    { word: 'fluster', word_class: 'N' },
    { word: 'fluster', word_class: 'V' },
    { word: 'flustered', word_class: 'AJ' }
  ],
  distinguishable: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  glycerine: [
    { word: 'glycerin', word_class: 'N' },
    { word: 'glycerine', word_class: 'N' },
    { word: 'glycerinate', word_class: 'V' }
  ],
  forbear: [
    { word: 'forbear', word_class: 'N' },
    { word: 'forbear', word_class: 'V' },
    { word: 'forborne', word_class: 'AJ' },
    { word: 'forbearance', word_class: 'N' },
    { word: 'forbearing', word_class: 'AJ' }
  ],
  intermediate: [
    { word: 'intermediate', word_class: 'N' },
    { word: 'intermediate', word_class: 'V' },
    { word: 'intermediate', word_class: 'AJ' },
    { word: 'intermediator', word_class: 'N' },
    { word: 'intermediation', word_class: 'N' },
    { word: 'intermediately', word_class: 'AV' }
  ],
  enthralled: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  berry: [
    { word: 'berry', word_class: 'N' },
    { word: 'berry', word_class: 'V' },
    { word: 'berried', word_class: 'AJ' }
  ],
  recurrent: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  behaviouristic: [
    { word: 'behaviourist', word_class: 'N' },
    { word: 'behaviourist', word_class: 'AJ' },
    { word: 'behaviouristic', word_class: 'AJ' }
  ],
  scurrilous: [
    { word: 'scurrility', word_class: 'N' },
    { word: 'scurrilous', word_class: 'AJ' },
    { word: 'scurrilously', word_class: 'AV' }
  ],
  melodious: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  dedicated: [
    { word: 'dedicate', word_class: 'V' },
    { word: 'dedicated', word_class: 'AJ' },
    { word: 'dedication', word_class: 'N' }
  ],
  saneness: [
    { word: 'sane', word_class: 'AJ' },
    { word: 'sanely', word_class: 'AV' },
    { word: 'saneness', word_class: 'N' }
  ],
  deliberately: [
    { word: 'deliberate', word_class: 'V' },
    { word: 'deliberate', word_class: 'AJ' },
    { word: 'deliberation', word_class: 'N' },
    { word: 'deliberately', word_class: 'AV' },
    { word: 'deliberateness', word_class: 'N' }
  ],
  skulker: [
    { word: 'skulk', word_class: 'V' },
    { word: 'skulker', word_class: 'N' },
    { word: 'skulking', word_class: 'N' },
    { word: 'skulking', word_class: 'AJ' }
  ],
  incensed: [
    { word: 'incense', word_class: 'N' },
    { word: 'incense', word_class: 'V' },
    { word: 'incensed', word_class: 'AJ' },
    { word: 'incensation', word_class: 'N' },
    { word: 'incensement', word_class: 'N' }
  ],
  unusualness: [
    { word: 'unusual', word_class: 'AJ' },
    { word: 'unusually', word_class: 'AV' },
    { word: 'unusualness', word_class: 'N' }
  ],
  bias: [
    { word: 'bias', word_class: 'N' },
    { word: 'bias', word_class: 'V' },
    { word: 'bias', word_class: 'AJ' },
    { word: 'bias', word_class: 'AV' }
  ],
  seizing: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  asynchronous: [
    { word: 'asynchrony', word_class: 'N' },
    { word: 'asynchronism', word_class: 'N' },
    { word: 'asynchronous', word_class: 'AJ' }
  ],
  subtracted: [
    { word: 'subtract', word_class: 'N' },
    { word: 'subtract', word_class: 'V' },
    { word: 'subtracter', word_class: 'N' },
    { word: 'subtracted', word_class: 'AJ' },
    { word: 'subtraction', word_class: 'N' },
    { word: 'subtractive', word_class: 'AJ' }
  ],
  adorer: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  commutate: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  amorous: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  softener: [
    { word: 'soften', word_class: 'V' },
    { word: 'softener', word_class: 'N' },
    { word: 'softened', word_class: 'AJ' },
    { word: 'softening', word_class: 'N' },
    { word: 'softening', word_class: 'AJ' }
  ],
  descriptively: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  mews: [{ word: 'mew', word_class: 'N' }, { word: 'mew', word_class: 'V' }, { word: 'mews', word_class: 'N' }],
  vanish: [
    { word: 'vanish', word_class: 'V' },
    { word: 'vanish', word_class: 'AJ' },
    { word: 'vanished', word_class: 'AJ' },
    { word: 'vanishing', word_class: 'N' },
    { word: 'vanishing', word_class: 'AJ' },
    { word: 'vanishment', word_class: 'N' }
  ],
  infant: [
    { word: 'infant', word_class: 'N' },
    { word: 'infancy', word_class: 'N' },
    { word: 'infancy', word_class: 'V' },
    { word: 'infant', word_class: 'AJ' },
    { word: 'infancy', word_class: 'AJ' },
    { word: 'infantile', word_class: 'AJ' },
    { word: 'infantilism', word_class: 'N' }
  ],
  troop: [
    { word: 'troop', word_class: 'N' },
    { word: 'troop', word_class: 'V' },
    { word: 'troops', word_class: 'N' },
    { word: 'trooper', word_class: 'N' },
    { word: 'troopship', word_class: 'N' }
  ],
  vaccinating: [
    { word: 'vaccine', word_class: 'N' },
    { word: 'vaccinate', word_class: 'V' },
    { word: 'vaccinated', word_class: 'AJ' },
    { word: 'vaccinating', word_class: 'N' },
    { word: 'vaccination', word_class: 'N' }
  ],
  heedful: [
    { word: 'heed', word_class: 'N' },
    { word: 'heed', word_class: 'V' },
    { word: 'heedful', word_class: 'AJ' },
    { word: 'heedfulness', word_class: 'N' }
  ],
  admonished: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  downrightness: [
    { word: 'downright', word_class: 'AJ' },
    { word: 'downright', word_class: 'AV' },
    { word: 'downrightness', word_class: 'N' }
  ],
  burier: [
    { word: 'bury', word_class: 'V' },
    { word: 'burial', word_class: 'N' },
    { word: 'burier', word_class: 'N' },
    { word: 'buried', word_class: 'AJ' },
    { word: 'burying', word_class: 'N' }
  ],
  pragmatical: [
    { word: 'pragmatic', word_class: 'AJ' },
    { word: 'pragmatics', word_class: 'N' },
    { word: 'pragmatism', word_class: 'N' },
    { word: 'pragmatical', word_class: 'AJ' },
    { word: 'pragmatically', word_class: 'AV' }
  ],
  justificative: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  clerk: [
    { word: 'clerk', word_class: 'N' },
    { word: 'clerk', word_class: 'V' },
    { word: 'clerking', word_class: 'N' },
    { word: 'clerkship', word_class: 'N' }
  ],
  fart: [{ word: 'fart', word_class: 'N' }, { word: 'fart', word_class: 'V' }, { word: 'farting', word_class: 'N' }],
  evacuate: [
    { word: 'evacuee', word_class: 'N' },
    { word: 'evacuate', word_class: 'V' },
    { word: 'evacuant', word_class: 'AJ' },
    { word: 'evacuated', word_class: 'AJ' },
    { word: 'evacuation', word_class: 'N' }
  ],
  provence: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  genuineness: [
    { word: 'genuine', word_class: 'AJ' },
    { word: 'genuinely', word_class: 'AV' },
    { word: 'genuineness', word_class: 'N' }
  ],
  infancy: [
    { word: 'infant', word_class: 'N' },
    { word: 'infancy', word_class: 'N' },
    { word: 'infancy', word_class: 'V' },
    { word: 'infant', word_class: 'AJ' },
    { word: 'infancy', word_class: 'AJ' },
    { word: 'infantile', word_class: 'AJ' },
    { word: 'infantilism', word_class: 'N' }
  ],
  turbulence: [
    { word: 'turbulence', word_class: 'N' },
    { word: 'turbulency', word_class: 'N' },
    { word: 'turbulent', word_class: 'AJ' },
    { word: 'turbulently', word_class: 'AV' }
  ],
  taxation: [
    { word: 'tax', word_class: 'N' },
    { word: 'tax', word_class: 'V' },
    { word: 'taxman', word_class: 'N' },
    { word: 'taxing', word_class: 'AJ' },
    { word: 'taxation', word_class: 'N' }
  ],
  extinguished: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  inculpative: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  derived: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  flavorful: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  quietist: [
    { word: 'quiet', word_class: 'N' },
    { word: 'quiet', word_class: 'V' },
    { word: 'quiet', word_class: 'AJ' },
    { word: 'quiet', word_class: 'AV' },
    { word: 'quietism', word_class: 'N' },
    { word: 'quietist', word_class: 'N' },
    { word: 'quietness', word_class: 'N' }
  ],
  tamp: [
    { word: 'tamp', word_class: 'N' },
    { word: 'tamp', word_class: 'V' },
    { word: 'tamper', word_class: 'N' },
    { word: 'tamper', word_class: 'V' },
    { word: 'tampering', word_class: 'N' }
  ],
  boner: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  intent: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  legalisation: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  grounds: [
    { word: 'ground', word_class: 'N' },
    { word: 'ground', word_class: 'V' },
    { word: 'ground', word_class: 'AJ' },
    { word: 'grounds', word_class: 'N' },
    { word: 'grounder', word_class: 'N' },
    { word: 'grounding', word_class: 'N' }
  ],
  photocopier: [
    { word: 'photocopy', word_class: 'N' },
    { word: 'photocopy', word_class: 'V' },
    { word: 'photocopier', word_class: 'N' }
  ],
  verify: [
    { word: 'verify', word_class: 'V' },
    { word: 'verified', word_class: 'AJ' },
    { word: 'verifying', word_class: 'AJ' },
    { word: 'verifiable', word_class: 'AJ' },
    { word: 'verification', word_class: 'N' }
  ],
  violable: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  disgruntlement: [
    { word: 'disgruntle', word_class: 'V' },
    { word: 'disgruntled', word_class: 'AJ' },
    { word: 'disgruntlement', word_class: 'N' }
  ],
  crenellate: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  stutterer: [
    { word: 'stutter', word_class: 'N' },
    { word: 'stutter', word_class: 'V' },
    { word: 'stutterer', word_class: 'N' },
    { word: 'stuttering', word_class: 'N' },
    { word: 'stuttering', word_class: 'AJ' }
  ],
  peeved: [
    { word: 'peeve', word_class: 'N' },
    { word: 'peeve', word_class: 'V' },
    { word: 'peeved', word_class: 'AJ' }
  ],
  humorously: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  systemic: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  splinter: [
    { word: 'splinter', word_class: 'N' },
    { word: 'splinter', word_class: 'V' },
    { word: 'splinters', word_class: 'N' },
    { word: 'splintered', word_class: 'AJ' },
    { word: 'splintering', word_class: 'N' }
  ],
  slicked: [
    { word: 'slick', word_class: 'N' },
    { word: 'slick', word_class: 'V' },
    { word: 'slick', word_class: 'AJ' },
    { word: 'slicked', word_class: 'AJ' },
    { word: 'slickness', word_class: 'N' }
  ],
  suppressive: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  devilize: [
    { word: 'devil', word_class: 'N' },
    { word: 'devil', word_class: 'V' },
    { word: 'devilize', word_class: 'V' },
    { word: 'devilment', word_class: 'N' }
  ],
  thankfulness: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  thrashing: [
    { word: 'thrash', word_class: 'N' },
    { word: 'thrash', word_class: 'V' },
    { word: 'thrasher', word_class: 'N' },
    { word: 'thrashing', word_class: 'N' },
    { word: 'thrashing', word_class: 'AJ' }
  ],
  unavailable: [
    { word: 'unavailing', word_class: 'AJ' },
    { word: 'unavailable', word_class: 'AJ' },
    { word: 'unavailability', word_class: 'N' }
  ],
  puritanism: [
    { word: 'puritan', word_class: 'N' },
    { word: 'puritan', word_class: 'AJ' },
    { word: 'puritanic', word_class: 'AJ' },
    { word: 'puritanism', word_class: 'N' },
    { word: 'puritanical', word_class: 'AJ' },
    { word: 'puritanically', word_class: 'AV' }
  ],
  gastronomical: [
    { word: 'gastronome', word_class: 'N' },
    { word: 'gastronomic', word_class: 'AJ' },
    { word: 'gastronomical', word_class: 'AJ' }
  ],
  ranked: [
    { word: 'rank', word_class: 'N' },
    { word: 'rank', word_class: 'V' },
    { word: 'rank', word_class: 'AJ' },
    { word: 'ranker', word_class: 'N' },
    { word: 'ranked', word_class: 'AJ' },
    { word: 'ranking', word_class: 'N' },
    { word: 'ranking', word_class: 'AJ' },
    { word: 'rankness', word_class: 'N' }
  ],
  teach: [
    { word: 'teach', word_class: 'N' },
    { word: 'teach', word_class: 'V' },
    { word: 'teacher', word_class: 'N' },
    { word: 'teaching', word_class: 'N' }
  ],
  petaled: [
    { word: 'petal', word_class: 'N' },
    { word: 'petaled', word_class: 'AJ' },
    { word: 'petalled', word_class: 'AJ' },
    { word: 'petalous', word_class: 'AJ' }
  ],
  effervescing: [
    { word: 'effervesce', word_class: 'V' },
    { word: 'effervescence', word_class: 'N' },
    { word: 'effervescent', word_class: 'AJ' },
    { word: 'effervescing', word_class: 'AJ' }
  ],
  tendril: [
    { word: 'tendril', word_class: 'N' },
    { word: 'tendriled', word_class: 'AJ' },
    { word: 'tendrilled', word_class: 'AJ' }
  ],
  innervation: [
    { word: 'innerve', word_class: 'V' },
    { word: 'innervate', word_class: 'V' },
    { word: 'innervation', word_class: 'N' }
  ],
  prevaricate: [
    { word: 'prevaricate', word_class: 'V' },
    { word: 'prevaricator', word_class: 'N' },
    { word: 'prevarication', word_class: 'N' }
  ],
  detect: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  sedately: [
    { word: 'sedate', word_class: 'V' },
    { word: 'sedate', word_class: 'AJ' },
    { word: 'sedated', word_class: 'AJ' },
    { word: 'sedation', word_class: 'N' },
    { word: 'sedately', word_class: 'AV' },
    { word: 'sedateness', word_class: 'N' }
  ],
  egoistic: [
    { word: 'egoist', word_class: 'N' },
    { word: 'egoistic', word_class: 'AJ' },
    { word: 'egoistical', word_class: 'AJ' }
  ],
  maladjusted: [
    { word: 'maladjusted', word_class: 'AJ' },
    { word: 'maladjustive', word_class: 'AJ' },
    { word: 'maladjustment', word_class: 'N' }
  ],
  turbine: [
    { word: 'turbine', word_class: 'N' },
    { word: 'turbinal', word_class: 'N' },
    { word: 'turbinate', word_class: 'N' },
    { word: 'turbinate', word_class: 'AJ' }
  ],
  boundary: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  casement: [
    { word: 'case', word_class: 'N' },
    { word: 'case', word_class: 'V' },
    { word: 'cased', word_class: 'AJ' },
    { word: 'casing', word_class: 'N' },
    { word: 'caseful', word_class: 'N' },
    { word: 'casement', word_class: 'N' }
  ],
  libeler: [
    { word: 'libel', word_class: 'N' },
    { word: 'libel', word_class: 'V' },
    { word: 'libeler', word_class: 'N' },
    { word: 'libelous', word_class: 'AJ' },
    { word: 'libellous', word_class: 'AJ' }
  ],
  felony: [
    { word: 'felony', word_class: 'N' },
    { word: 'felony', word_class: 'AJ' },
    { word: 'felonious', word_class: 'AJ' }
  ],
  entranced: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  anneal: [
    { word: 'anneal', word_class: 'V' },
    { word: 'annealed', word_class: 'AJ' },
    { word: 'annealing', word_class: 'N' }
  ],
  registrate: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  cadence: [
    { word: 'cadence', word_class: 'N' },
    { word: 'cadency', word_class: 'N' },
    { word: 'cadenced', word_class: 'AJ' }
  ],
  strangler: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  pictorial: [
    { word: 'pictorial', word_class: 'N' },
    { word: 'pictorial', word_class: 'AJ' },
    { word: 'pictorially', word_class: 'AV' }
  ],
  putrescible: [
    { word: 'putrescence', word_class: 'N' },
    { word: 'putrescent', word_class: 'AJ' },
    { word: 'putrescible', word_class: 'AJ' }
  ],
  testing: [
    { word: 'test', word_class: 'N' },
    { word: 'test', word_class: 'V' },
    { word: 'test', word_class: 'AJ' },
    { word: 'testee', word_class: 'N' },
    { word: 'tester', word_class: 'N' },
    { word: 'tested', word_class: 'AJ' },
    { word: 'testing', word_class: 'N' }
  ],
  interpose: [
    { word: 'interpose', word_class: 'V' },
    { word: 'interposal', word_class: 'N' },
    { word: 'interposition', word_class: 'N' }
  ],
  pinpoint: [
    { word: 'pinpoint', word_class: 'N' },
    { word: 'pinpoint', word_class: 'V' },
    { word: 'pinpoint', word_class: 'AJ' }
  ],
  superinduce: [
    { word: 'superinduce', word_class: 'V' },
    { word: 'superinduction', word_class: 'N' },
    { word: 'superinducement', word_class: 'N' }
  ],
  tape: [
    { word: 'tape', word_class: 'N' },
    { word: 'tape', word_class: 'V' },
    { word: 'taped', word_class: 'AJ' },
    { word: 'taping', word_class: 'N' }
  ],
  augury: [{ word: 'augur', word_class: 'N' }, { word: 'augur', word_class: 'V' }, { word: 'augury', word_class: 'N' }],
  indented: [
    { word: 'indent', word_class: 'N' },
    { word: 'indent', word_class: 'V' },
    { word: 'indenture', word_class: 'N' },
    { word: 'indenture', word_class: 'V' },
    { word: 'indented', word_class: 'AJ' },
    { word: 'indentation', word_class: 'N' },
    { word: 'indentured', word_class: 'AJ' }
  ],
  tormentor: [
    { word: 'torment', word_class: 'N' },
    { word: 'torment', word_class: 'V' },
    { word: 'tormenter', word_class: 'N' },
    { word: 'tormentor', word_class: 'N' },
    { word: 'tormented', word_class: 'AJ' }
  ],
  dropping: [
    { word: 'drop', word_class: 'N' },
    { word: 'drop', word_class: 'V' },
    { word: 'dropped', word_class: 'AJ' },
    { word: 'dropping', word_class: 'N' },
    { word: 'dropping', word_class: 'AJ' },
    { word: 'droppings', word_class: 'N' }
  ],
  ornamentation: [
    { word: 'ornament', word_class: 'N' },
    { word: 'ornament', word_class: 'V' },
    { word: 'ornamental', word_class: 'N' },
    { word: 'ornamental', word_class: 'AJ' },
    { word: 'ornamented', word_class: 'AJ' },
    { word: 'ornamentation', word_class: 'N' }
  ],
  vegetable: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  colony: [
    { word: 'colony', word_class: 'N' },
    { word: 'colony', word_class: 'AJ' },
    { word: 'colonial', word_class: 'N' },
    { word: 'colonial', word_class: 'AJ' },
    { word: 'colonialism', word_class: 'N' }
  ],
  flag: [
    { word: 'flag', word_class: 'N' },
    { word: 'flag', word_class: 'V' },
    { word: 'flagship', word_class: 'N' },
    { word: 'flagging', word_class: 'AJ' }
  ],
  dropped: [
    { word: 'drop', word_class: 'N' },
    { word: 'drop', word_class: 'V' },
    { word: 'dropped', word_class: 'AJ' },
    { word: 'dropping', word_class: 'N' },
    { word: 'dropping', word_class: 'AJ' },
    { word: 'droppings', word_class: 'N' }
  ],
  twisted: [
    { word: 'twist', word_class: 'N' },
    { word: 'twist', word_class: 'V' },
    { word: 'twister', word_class: 'N' },
    { word: 'twisted', word_class: 'AJ' },
    { word: 'twisting', word_class: 'N' },
    { word: 'twisting', word_class: 'AJ' }
  ],
  bigot: [
    { word: 'bigot', word_class: 'N' },
    { word: 'bigotry', word_class: 'N' },
    { word: 'bigoted', word_class: 'AJ' }
  ],
  multifarious: [
    { word: 'multifarious', word_class: 'AJ' },
    { word: 'multifariously', word_class: 'AV' },
    { word: 'multifariousness', word_class: 'N' }
  ],
  fervently: [
    { word: 'fervency', word_class: 'N' },
    { word: 'fervent', word_class: 'AJ' },
    { word: 'fervently', word_class: 'AV' }
  ],
  haphazardness: [
    { word: 'haphazard', word_class: 'AJ' },
    { word: 'haphazard', word_class: 'AV' },
    { word: 'haphazardness', word_class: 'N' }
  ],
  backpacking: [
    { word: 'backpack', word_class: 'N' },
    { word: 'backpack', word_class: 'V' },
    { word: 'backpacking', word_class: 'N' }
  ],
  imperfectability: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  example: [
    { word: 'example', word_class: 'N' },
    { word: 'example', word_class: 'V' },
    { word: 'example', word_class: 'AJ' }
  ],
  dilapidation: [
    { word: 'dilapidate', word_class: 'V' },
    { word: 'dilapidated', word_class: 'AJ' },
    { word: 'dilapidation', word_class: 'N' }
  ],
  inhabitation: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  divide: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  stiffen: [
    { word: 'stiffen', word_class: 'V' },
    { word: 'stiffener', word_class: 'N' },
    { word: 'stiffened', word_class: 'AJ' },
    { word: 'stiffening', word_class: 'N' }
  ],
  recognisance: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  arranging: [
    { word: 'arrange', word_class: 'V' },
    { word: 'arranger', word_class: 'N' },
    { word: 'arranged', word_class: 'AJ' },
    { word: 'arranging', word_class: 'N' },
    { word: 'arrangement', word_class: 'N' }
  ],
  canopied: [
    { word: 'canopy', word_class: 'N' },
    { word: 'canopy', word_class: 'V' },
    { word: 'canopied', word_class: 'AJ' }
  ],
  puppet: [
    { word: 'puppet', word_class: 'N' },
    { word: 'puppetry', word_class: 'N' },
    { word: 'puppeteer', word_class: 'N' }
  ],
  oppressively: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  garrulously: [
    { word: 'garrulity', word_class: 'N' },
    { word: 'garrulous', word_class: 'AJ' },
    { word: 'garrulously', word_class: 'AV' },
    { word: 'garrulousness', word_class: 'N' }
  ],
  chivalrously: [
    { word: 'chivalry', word_class: 'N' },
    { word: 'chivalric', word_class: 'AJ' },
    { word: 'chivalrous', word_class: 'AJ' },
    { word: 'chivalrously', word_class: 'AV' }
  ],
  retrocedence: [
    { word: 'retrocede', word_class: 'V' },
    { word: 'retrocedence', word_class: 'N' },
    { word: 'retrocession', word_class: 'N' }
  ],
  ventriloquization: [
    { word: 'ventriloquise', word_class: 'V' },
    { word: 'ventriloquism', word_class: 'N' },
    { word: 'ventriloquize', word_class: 'V' },
    { word: 'ventriloquization', word_class: 'N' }
  ],
  alcohol: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  conscientiousness: [
    { word: 'conscience', word_class: 'N' },
    { word: 'conscientious', word_class: 'AJ' },
    { word: 'conscientiously', word_class: 'AV' },
    { word: 'conscientiousness', word_class: 'N' }
  ],
  sulphonate: [
    { word: 'sulfonate', word_class: 'N' },
    { word: 'sulfonate', word_class: 'V' },
    { word: 'sulphonate', word_class: 'V' },
    { word: 'sulfonation', word_class: 'N' },
    { word: 'sulphonation', word_class: 'N' }
  ],
  rigging: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  tartarise: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  oral: [{ word: 'oral', word_class: 'N' }, { word: 'oral', word_class: 'AJ' }, { word: 'orally', word_class: 'AV' }],
  fiddling: [
    { word: 'fiddle', word_class: 'N' },
    { word: 'fiddle', word_class: 'V' },
    { word: 'fiddler', word_class: 'N' },
    { word: 'fiddling', word_class: 'AJ' }
  ],
  enhancive: [
    { word: 'enhance', word_class: 'V' },
    { word: 'enhanced', word_class: 'AJ' },
    { word: 'enhancive', word_class: 'AJ' },
    { word: 'enhancement', word_class: 'N' }
  ],
  sell: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  formulaic: [
    { word: 'formula', word_class: 'N' },
    { word: 'formulaic', word_class: 'AJ' },
    { word: 'formulaize', word_class: 'V' }
  ],
  allowable: [
    { word: 'allow', word_class: 'V' },
    { word: 'allowance', word_class: 'N' },
    { word: 'allowance', word_class: 'V' },
    { word: 'allowable', word_class: 'AJ' },
    { word: 'allowably', word_class: 'AV' }
  ],
  'first-rater': [
    { word: 'first-rate', word_class: 'AJ' },
    { word: 'first-rate', word_class: 'AV' },
    { word: 'first-rater', word_class: 'N' }
  ],
  alkalinity: [
    { word: 'alkali', word_class: 'N' },
    { word: 'alkaline', word_class: 'AJ' },
    { word: 'alkalinity', word_class: 'N' },
    { word: 'alkalinize', word_class: 'V' },
    { word: 'alkalinization', word_class: 'N' }
  ],
  deafening: [
    { word: 'deafen', word_class: 'V' },
    { word: 'deafened', word_class: 'AJ' },
    { word: 'deafening', word_class: 'AJ' }
  ],
  surmountable: [
    { word: 'surmount', word_class: 'V' },
    { word: 'surmounted', word_class: 'AJ' },
    { word: 'surmountable', word_class: 'AJ' }
  ],
  tester: [
    { word: 'test', word_class: 'N' },
    { word: 'test', word_class: 'V' },
    { word: 'test', word_class: 'AJ' },
    { word: 'testee', word_class: 'N' },
    { word: 'tester', word_class: 'N' },
    { word: 'tested', word_class: 'AJ' },
    { word: 'testing', word_class: 'N' }
  ],
  dogmatism: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  unlikeable: [
    { word: 'unlike', word_class: 'N' },
    { word: 'unlike', word_class: 'V' },
    { word: 'unlike', word_class: 'AJ' },
    { word: 'unlikely', word_class: 'AJ' },
    { word: 'unlikable', word_class: 'AJ' },
    { word: 'unlikeness', word_class: 'N' },
    { word: 'unlikeable', word_class: 'AJ' }
  ],
  particulars: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  forger: [
    { word: 'forge', word_class: 'N' },
    { word: 'forge', word_class: 'V' },
    { word: 'forger', word_class: 'N' },
    { word: 'forged', word_class: 'AJ' },
    { word: 'forgery', word_class: 'N' },
    { word: 'forging', word_class: 'N' },
    { word: 'forgery', word_class: 'AJ' }
  ],
  shelfful: [
    { word: 'shelf', word_class: 'N' },
    { word: 'shelve', word_class: 'V' },
    { word: 'shelfful', word_class: 'N' },
    { word: 'shelving', word_class: 'N' },
    { word: 'shelfful', word_class: 'AJ' }
  ],
  going: [
    { word: 'go', word_class: 'N' },
    { word: 'go', word_class: 'V' },
    { word: 'go', word_class: 'AJ' },
    { word: 'goal', word_class: 'N' },
    { word: 'goer', word_class: 'N' },
    { word: 'going', word_class: 'N' },
    { word: 'gone', word_class: 'AJ' },
    { word: 'going', word_class: 'AJ' }
  ],
  imitator: [
    { word: 'imitate', word_class: 'V' },
    { word: 'imitator', word_class: 'N' },
    { word: 'imitable', word_class: 'AJ' },
    { word: 'imitation', word_class: 'N' },
    { word: 'imitation', word_class: 'AJ' },
    { word: 'imitative', word_class: 'AJ' }
  ],
  inadvertently: [
    { word: 'inadvertence', word_class: 'N' },
    { word: 'inadvertency', word_class: 'N' },
    { word: 'inadvertent', word_class: 'AJ' },
    { word: 'inadvertently', word_class: 'AV' }
  ],
  enured: [
    { word: 'enure', word_class: 'V' },
    { word: 'enured', word_class: 'AJ' },
    { word: 'enurement', word_class: 'N' }
  ],
  laboring: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  tranquillizer: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  departed: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  disinfect: [
    { word: 'disinfect', word_class: 'V' },
    { word: 'disinfectant', word_class: 'N' },
    { word: 'disinfection', word_class: 'N' },
    { word: 'disinfectant', word_class: 'AJ' }
  ],
  oblateness: [
    { word: 'oblate', word_class: 'N' },
    { word: 'oblate', word_class: 'AJ' },
    { word: 'oblation', word_class: 'N' },
    { word: 'oblateness', word_class: 'N' }
  ],
  surety: [
    { word: 'sure', word_class: 'AJ' },
    { word: 'sure', word_class: 'AV' },
    { word: 'surety', word_class: 'N' },
    { word: 'surely', word_class: 'AV' },
    { word: 'sureness', word_class: 'N' }
  ],
  supremely: [
    { word: 'supreme', word_class: 'AJ' },
    { word: 'supremacy', word_class: 'N' },
    { word: 'supremely', word_class: 'AV' },
    { word: 'supremacist', word_class: 'N' }
  ],
  mince: [
    { word: 'mince', word_class: 'N' },
    { word: 'mince', word_class: 'V' },
    { word: 'mincer', word_class: 'N' },
    { word: 'mincing', word_class: 'AJ' }
  ],
  repulsion: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  intimidate: [
    { word: 'intimidate', word_class: 'V' },
    { word: 'intimidated', word_class: 'AJ' },
    { word: 'intimidation', word_class: 'N' },
    { word: 'intimidating', word_class: 'AJ' }
  ],
  nourish: [
    { word: 'nourish', word_class: 'V' },
    { word: 'nourished', word_class: 'AJ' },
    { word: 'nourishing', word_class: 'N' },
    { word: 'nourishing', word_class: 'AJ' },
    { word: 'nourishment', word_class: 'N' }
  ],
  manicurist: [
    { word: 'manicure', word_class: 'N' },
    { word: 'manicure', word_class: 'V' },
    { word: 'manicurist', word_class: 'N' }
  ],
  adventism: [
    { word: 'advent', word_class: 'N' },
    { word: 'adventism', word_class: 'N' },
    { word: 'adventive', word_class: 'AJ' }
  ],
  bestialization: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  welder: [
    { word: 'weld', word_class: 'N' },
    { word: 'weld', word_class: 'V' },
    { word: 'welder', word_class: 'N' },
    { word: 'welding', word_class: 'N' },
    { word: 'weldment', word_class: 'N' }
  ],
  croaking: [
    { word: 'croak', word_class: 'N' },
    { word: 'croak', word_class: 'V' },
    { word: 'croaker', word_class: 'N' },
    { word: 'croaking', word_class: 'N' },
    { word: 'croaking', word_class: 'AJ' }
  ],
  suberization: [
    { word: 'suberise', word_class: 'V' },
    { word: 'suberize', word_class: 'V' },
    { word: 'suberisation', word_class: 'N' },
    { word: 'suberization', word_class: 'N' }
  ],
  detonate: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  depletion: [
    { word: 'deplete', word_class: 'V' },
    { word: 'depleted', word_class: 'AJ' },
    { word: 'depletion', word_class: 'N' },
    { word: 'depletable', word_class: 'AJ' }
  ],
  inveterate: [
    { word: 'inveteracy', word_class: 'N' },
    { word: 'inveterate', word_class: 'AJ' },
    { word: 'inveterately', word_class: 'AV' }
  ],
  pomaded: [
    { word: 'pomade', word_class: 'N' },
    { word: 'pomade', word_class: 'V' },
    { word: 'pomaded', word_class: 'AJ' }
  ],
  rearwards: [
    { word: 'rearward', word_class: 'N' },
    { word: 'rearward', word_class: 'AJ' },
    { word: 'rearward', word_class: 'AV' },
    { word: 'rearwards', word_class: 'AV' }
  ],
  honker: [{ word: 'honk', word_class: 'N' }, { word: 'honk', word_class: 'V' }, { word: 'honker', word_class: 'N' }],
  participant: [
    { word: 'participant', word_class: 'N' },
    { word: 'participate', word_class: 'V' },
    { word: 'participance', word_class: 'N' },
    { word: 'participation', word_class: 'N' },
    { word: 'participating', word_class: 'AJ' }
  ],
  gravitate: [
    { word: 'gravitate', word_class: 'V' },
    { word: 'gravitation', word_class: 'N' },
    { word: 'gravitative', word_class: 'AJ' },
    { word: 'gravitational', word_class: 'AJ' }
  ],
  callused: [
    { word: 'callus', word_class: 'N' },
    { word: 'callus', word_class: 'V' },
    { word: 'callused', word_class: 'AJ' }
  ],
  foam: [{ word: 'foam', word_class: 'N' }, { word: 'foam', word_class: 'V' }, { word: 'foaming', word_class: 'AJ' }],
  worriment: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  equivocalness: [
    { word: 'equivocal', word_class: 'AJ' },
    { word: 'equivocate', word_class: 'V' },
    { word: 'equivocally', word_class: 'AV' },
    { word: 'equivocation', word_class: 'N' },
    { word: 'equivocalness', word_class: 'N' }
  ],
  coarseness: [
    { word: 'coarse', word_class: 'AJ' },
    { word: 'coarsely', word_class: 'AV' },
    { word: 'coarseness', word_class: 'N' }
  ],
  broadcast: [
    { word: 'broadcast', word_class: 'N' },
    { word: 'broadcast', word_class: 'V' },
    { word: 'broadcast', word_class: 'AJ' },
    { word: 'broadcast', word_class: 'AV' },
    { word: 'broadcaster', word_class: 'N' },
    { word: 'broadcasting', word_class: 'N' }
  ],
  constipating: [
    { word: 'constipate', word_class: 'V' },
    { word: 'constipated', word_class: 'AJ' },
    { word: 'constipation', word_class: 'N' },
    { word: 'constipating', word_class: 'AJ' }
  ],
  hump: [{ word: 'hump', word_class: 'N' }, { word: 'hump', word_class: 'V' }, { word: 'humped', word_class: 'AJ' }],
  amateur: [
    { word: 'amateur', word_class: 'N' },
    { word: 'amateur', word_class: 'AJ' },
    { word: 'amateurism', word_class: 'N' }
  ],
  velar: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  fugue: [
    { word: 'fugu', word_class: 'N' },
    { word: 'fugue', word_class: 'N' },
    { word: 'fugal', word_class: 'AJ' },
    { word: 'fugally', word_class: 'AV' }
  ],
  opacity: [
    { word: 'opacity', word_class: 'N' },
    { word: 'opaque', word_class: 'AJ' },
    { word: 'opaquely', word_class: 'AV' },
    { word: 'opaqueness', word_class: 'N' }
  ],
  assign: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  putter: [
    { word: 'putt', word_class: 'N' },
    { word: 'putt', word_class: 'V' },
    { word: 'puttee', word_class: 'N' },
    { word: 'putter', word_class: 'N' },
    { word: 'putter', word_class: 'V' },
    { word: 'puttees', word_class: 'N' },
    { word: 'putterer', word_class: 'N' }
  ],
  running: [
    { word: 'run', word_class: 'N' },
    { word: 'run', word_class: 'V' },
    { word: 'run', word_class: 'AJ' },
    { word: 'runny', word_class: 'AJ' },
    { word: 'running', word_class: 'N' },
    { word: 'running', word_class: 'AJ' },
    { word: 'runniness', word_class: 'N' }
  ],
  extraverted: [
    { word: 'extravert', word_class: 'N' },
    { word: 'extravert', word_class: 'AJ' },
    { word: 'extraverted', word_class: 'AJ' },
    { word: 'extravertive', word_class: 'AJ' }
  ],
  piteous: [
    { word: 'pity', word_class: 'N' },
    { word: 'pity', word_class: 'V' },
    { word: 'piteous', word_class: 'AJ' },
    { word: 'pitiful', word_class: 'AJ' },
    { word: 'pitying', word_class: 'AJ' }
  ],
  promotional: [
    { word: 'promote', word_class: 'N' },
    { word: 'promote', word_class: 'V' },
    { word: 'promoter', word_class: 'N' },
    { word: 'promotion', word_class: 'N' },
    { word: 'promotive', word_class: 'AJ' },
    { word: 'promotional', word_class: 'AJ' }
  ],
  exact: [
    { word: 'exact', word_class: 'N' },
    { word: 'exact', word_class: 'V' },
    { word: 'exact', word_class: 'AJ' },
    { word: 'exaction', word_class: 'N' },
    { word: 'exacting', word_class: 'AJ' },
    { word: 'exactness', word_class: 'N' },
    { word: 'exactitude', word_class: 'N' }
  ],
  criminalness: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  liking: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  bismuth: [
    { word: 'bismuth', word_class: 'N' },
    { word: 'bismuthal', word_class: 'AJ' },
    { word: 'bismuthic', word_class: 'AJ' }
  ],
  hastening: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  desecrated: [
    { word: 'desecrate', word_class: 'V' },
    { word: 'desecrated', word_class: 'AJ' },
    { word: 'desecration', word_class: 'N' }
  ],
  failed: [
    { word: 'fail', word_class: 'V' },
    { word: 'failed', word_class: 'AJ' },
    { word: 'failing', word_class: 'N' },
    { word: 'failure', word_class: 'N' },
    { word: 'failing', word_class: 'AJ' }
  ],
  frightfulness: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  combining: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  whiskers: [
    { word: 'whisk', word_class: 'N' },
    { word: 'whisk', word_class: 'V' },
    { word: 'whisker', word_class: 'N' },
    { word: 'whisker', word_class: 'V' },
    { word: 'whiskers', word_class: 'N' },
    { word: 'whiskered', word_class: 'AJ' }
  ],
  shot: [
    { word: 'shot', word_class: 'N' },
    { word: 'shoot', word_class: 'N' },
    { word: 'shoot', word_class: 'V' },
    { word: 'shot', word_class: 'AJ' },
    { word: 'shooter', word_class: 'N' },
    { word: 'shooting', word_class: 'N' }
  ],
  rebellious: [
    { word: 'rebellious', word_class: 'AJ' },
    { word: 'rebelliously', word_class: 'AV' },
    { word: 'rebelliousness', word_class: 'N' }
  ],
  referral: [
    { word: 'refer', word_class: 'V' },
    { word: 'referent', word_class: 'N' },
    { word: 'referral', word_class: 'N' },
    { word: 'reference', word_class: 'N' },
    { word: 'reference', word_class: 'V' },
    { word: 'referable', word_class: 'AJ' }
  ],
  vanishment: [
    { word: 'vanish', word_class: 'V' },
    { word: 'vanish', word_class: 'AJ' },
    { word: 'vanished', word_class: 'AJ' },
    { word: 'vanishing', word_class: 'N' },
    { word: 'vanishing', word_class: 'AJ' },
    { word: 'vanishment', word_class: 'N' }
  ],
  bony: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  interrogate: [
    { word: 'interrogate', word_class: 'V' },
    { word: 'interrogator', word_class: 'N' },
    { word: 'interrogation', word_class: 'N' },
    { word: 'interrogative', word_class: 'N' },
    { word: 'interrogative', word_class: 'AJ' },
    { word: 'interrogatively', word_class: 'AV' }
  ],
  molest: [
    { word: 'molest', word_class: 'V' },
    { word: 'molester', word_class: 'N' },
    { word: 'molested', word_class: 'AJ' },
    { word: 'molestation', word_class: 'N' }
  ],
  amative: [
    { word: 'am', word_class: 'N' },
    { word: 'am', word_class: 'AJ' },
    { word: 'amative', word_class: 'AJ' },
    { word: 'amativeness', word_class: 'N' }
  ],
  relocate: [
    { word: 'relocate', word_class: 'V' },
    { word: 'relocated', word_class: 'AJ' },
    { word: 'relocation', word_class: 'N' }
  ],
  repast: [
    { word: 'repast', word_class: 'N' },
    { word: 'repast', word_class: 'V' },
    { word: 'repast', word_class: 'AJ' }
  ],
  molten: [
    { word: 'melt', word_class: 'N' },
    { word: 'melt', word_class: 'V' },
    { word: 'molten', word_class: 'AJ' },
    { word: 'meltage', word_class: 'N' },
    { word: 'melted', word_class: 'AJ' },
    { word: 'melting', word_class: 'N' },
    { word: 'melting', word_class: 'AJ' }
  ],
  deport: [
    { word: 'deport', word_class: 'V' },
    { word: 'deportee', word_class: 'N' },
    { word: 'deportment', word_class: 'N' },
    { word: 'deportation', word_class: 'N' }
  ],
  caddis: [
    { word: 'caddy', word_class: 'N' },
    { word: 'caddy', word_class: 'V' },
    { word: 'caddie', word_class: 'N' },
    { word: 'caddie', word_class: 'V' },
    { word: 'caddis', word_class: 'N' },
    { word: 'caddy', word_class: 'AJ' }
  ],
  inexpedience: [
    { word: 'inexpedience', word_class: 'N' },
    { word: 'inexpediency', word_class: 'N' },
    { word: 'inexpedient', word_class: 'AJ' },
    { word: 'inexpediently', word_class: 'AV' }
  ],
  implausibility: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  impellent: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  performing: [
    { word: 'perform', word_class: 'V' },
    { word: 'performer', word_class: 'N' },
    { word: 'performing', word_class: 'N' },
    { word: 'performance', word_class: 'N' }
  ],
  turbaned: [
    { word: 'turban', word_class: 'N' },
    { word: 'turbaned', word_class: 'AJ' },
    { word: 'turbanned', word_class: 'AJ' }
  ],
  salvager: [
    { word: 'salvage', word_class: 'N' },
    { word: 'salvage', word_class: 'V' },
    { word: 'salvager', word_class: 'N' },
    { word: 'salvageable', word_class: 'AJ' }
  ],
  weightlifter: [
    { word: 'weightlift', word_class: 'V' },
    { word: 'weightlifter', word_class: 'N' },
    { word: 'weightlifting', word_class: 'N' }
  ],
  beholder: [
    { word: 'behold', word_class: 'V' },
    { word: 'beholder', word_class: 'N' },
    { word: 'beholding', word_class: 'N' }
  ],
  revertible: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  maddening: [
    { word: 'madden', word_class: 'V' },
    { word: 'maddened', word_class: 'AJ' },
    { word: 'maddening', word_class: 'AJ' }
  ],
  scolding: [
    { word: 'scold', word_class: 'N' },
    { word: 'scold', word_class: 'V' },
    { word: 'scolder', word_class: 'N' },
    { word: 'scolding', word_class: 'N' },
    { word: 'scolding', word_class: 'AJ' }
  ],
  clothing: [
    { word: 'cloth', word_class: 'N' },
    { word: 'clothe', word_class: 'V' },
    { word: 'clothes', word_class: 'N' },
    { word: 'clothed', word_class: 'AJ' },
    { word: 'clothing', word_class: 'N' }
  ],
  precautional: [
    { word: 'precaution', word_class: 'N' },
    { word: 'precaution', word_class: 'V' },
    { word: 'precautional', word_class: 'AJ' }
  ],
  noisiness: [
    { word: 'noise', word_class: 'N' },
    { word: 'noise', word_class: 'V' },
    { word: 'noisy', word_class: 'AJ' },
    { word: 'noisiness', word_class: 'N' }
  ],
  percolator: [
    { word: 'percolate', word_class: 'V' },
    { word: 'percolator', word_class: 'N' },
    { word: 'percolation', word_class: 'N' }
  ],
  space: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  macadamise: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  postulational: [
    { word: 'postulant', word_class: 'N' },
    { word: 'postulate', word_class: 'N' },
    { word: 'postulate', word_class: 'V' },
    { word: 'postulation', word_class: 'N' },
    { word: 'postulational', word_class: 'AJ' }
  ],
  mesmerize: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  voraciousness: [
    { word: 'voracious', word_class: 'AJ' },
    { word: 'voraciously', word_class: 'AV' },
    { word: 'voraciousness', word_class: 'N' }
  ],
  profligacy: [
    { word: 'profligacy', word_class: 'N' },
    { word: 'profligate', word_class: 'N' },
    { word: 'profligate', word_class: 'AJ' },
    { word: 'profligately', word_class: 'AV' }
  ],
  screwing: [
    { word: 'screw', word_class: 'N' },
    { word: 'screw', word_class: 'V' },
    { word: 'screwing', word_class: 'N' }
  ],
  migrant: [
    { word: 'migrant', word_class: 'N' },
    { word: 'migrate', word_class: 'V' },
    { word: 'migrant', word_class: 'AJ' },
    { word: 'migration', word_class: 'N' }
  ],
  pacification: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  atomise: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  sealer: [
    { word: 'seal', word_class: 'N' },
    { word: 'seal', word_class: 'V' },
    { word: 'sealer', word_class: 'N' },
    { word: 'sealant', word_class: 'N' },
    { word: 'sealed', word_class: 'AJ' },
    { word: 'sealing', word_class: 'N' }
  ],
  paralytic: [
    { word: 'paralytic', word_class: 'N' },
    { word: 'paralytic', word_class: 'AJ' },
    { word: 'paralytical', word_class: 'AJ' }
  ],
  compression: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  antipathetical: [
    { word: 'antipathy', word_class: 'N' },
    { word: 'antipathy', word_class: 'AJ' },
    { word: 'antipathetic', word_class: 'AJ' },
    { word: 'antipathetical', word_class: 'AJ' }
  ],
  recusal: [
    { word: 'recuse', word_class: 'V' },
    { word: 'recusal', word_class: 'N' },
    { word: 'recusant', word_class: 'N' },
    { word: 'recusancy', word_class: 'N' },
    { word: 'recusant', word_class: 'AJ' },
    { word: 'recusation', word_class: 'N' }
  ],
  obey: [
    { word: 'obey', word_class: 'V' },
    { word: 'obedience', word_class: 'N' },
    { word: 'obedient', word_class: 'AJ' },
    { word: 'obediently', word_class: 'AV' }
  ],
  surpassing: [
    { word: 'surpass', word_class: 'N' },
    { word: 'surpass', word_class: 'V' },
    { word: 'surpassing', word_class: 'AJ' }
  ],
  vitalism: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  impeachability: [
    { word: 'impeach', word_class: 'N' },
    { word: 'impeach', word_class: 'V' },
    { word: 'impeachment', word_class: 'N' },
    { word: 'impeachability', word_class: 'N' }
  ],
  reactance: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  bituminize: [
    { word: 'bituminise', word_class: 'V' },
    { word: 'bituminize', word_class: 'V' },
    { word: 'bituminous', word_class: 'AJ' },
    { word: 'bituminisation', word_class: 'N' },
    { word: 'bituminization', word_class: 'N' }
  ],
  ruin: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  morality: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  sequential: [
    { word: 'sequence', word_class: 'N' },
    { word: 'sequence', word_class: 'V' },
    { word: 'sequential', word_class: 'AJ' },
    { word: 'sequentially', word_class: 'AV' }
  ],
  remediable: [
    { word: 'remedy', word_class: 'N' },
    { word: 'remedy', word_class: 'V' },
    { word: 'remedial', word_class: 'AJ' },
    { word: 'remediable', word_class: 'AJ' },
    { word: 'remediation', word_class: 'N' }
  ],
  intensively: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  presentational: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  hurried: [
    { word: 'hurry', word_class: 'N' },
    { word: 'hurry', word_class: 'V' },
    { word: 'hurried', word_class: 'AJ' },
    { word: 'hurrying', word_class: 'N' },
    { word: 'hurrying', word_class: 'AJ' }
  ],
  comprehend: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  negatively: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  ligature: [
    { word: 'ligate', word_class: 'V' },
    { word: 'ligation', word_class: 'N' },
    { word: 'ligature', word_class: 'N' }
  ],
  germination: [
    { word: 'germ', word_class: 'N' },
    { word: 'germinal', word_class: 'N' },
    { word: 'germinal', word_class: 'AJ' },
    { word: 'germinate', word_class: 'V' },
    { word: 'germination', word_class: 'N' }
  ],
  subjoining: [
    { word: 'subjoin', word_class: 'V' },
    { word: 'subjoining', word_class: 'N' },
    { word: 'subjunction', word_class: 'N' },
    { word: 'subjunctive', word_class: 'N' },
    { word: 'subjunctive', word_class: 'AJ' }
  ],
  gospel: [
    { word: 'gospel', word_class: 'N' },
    { word: 'gospels', word_class: 'N' },
    { word: 'gospeler', word_class: 'N' },
    { word: 'gospeller', word_class: 'N' }
  ],
  familiarized: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  maximally: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  impetuous: [
    { word: 'impetus', word_class: 'N' },
    { word: 'impetuous', word_class: 'AJ' },
    { word: 'impetuously', word_class: 'AV' },
    { word: 'impetuousness', word_class: 'N' }
  ],
  appendant: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  rifling: [
    { word: 'rifle', word_class: 'N' },
    { word: 'rifle', word_class: 'V' },
    { word: 'rifled', word_class: 'AJ' },
    { word: 'rifling', word_class: 'N' },
    { word: 'rifleman', word_class: 'N' }
  ],
  quintuplication: [
    { word: 'quintuple', word_class: 'V' },
    { word: 'quintuple', word_class: 'AJ' },
    { word: 'quintupling', word_class: 'N' },
    { word: 'quintuplicate', word_class: 'V' },
    { word: 'quintuplication', word_class: 'N' }
  ],
  dissociable: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  vexation: [
    { word: 'vex', word_class: 'V' },
    { word: 'vexed', word_class: 'AJ' },
    { word: 'vexing', word_class: 'AJ' },
    { word: 'vexation', word_class: 'N' },
    { word: 'vexatious', word_class: 'AJ' }
  ],
  simulated: [
    { word: 'simulate', word_class: 'V' },
    { word: 'simulator', word_class: 'N' },
    { word: 'simulated', word_class: 'AJ' },
    { word: 'simulation', word_class: 'N' }
  ],
  bemadden: [
    { word: 'mad', word_class: 'AJ' },
    { word: 'madness', word_class: 'N' },
    { word: 'bemadden', word_class: 'V' }
  ],
  saline: [
    { word: 'saline', word_class: 'N' },
    { word: 'saline', word_class: 'AJ' },
    { word: 'salinate', word_class: 'V' },
    { word: 'salinity', word_class: 'N' }
  ],
  tormenter: [
    { word: 'torment', word_class: 'N' },
    { word: 'torment', word_class: 'V' },
    { word: 'tormenter', word_class: 'N' },
    { word: 'tormentor', word_class: 'N' },
    { word: 'tormented', word_class: 'AJ' }
  ],
  industry: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  pants: [
    { word: 'pant', word_class: 'N' },
    { word: 'pant', word_class: 'V' },
    { word: 'pants', word_class: 'N' },
    { word: 'panting', word_class: 'N' },
    { word: 'panting', word_class: 'AJ' }
  ],
  inconceivably: [
    { word: 'inconceivable', word_class: 'AJ' },
    { word: 'inconceivably', word_class: 'AV' },
    { word: 'inconceivability', word_class: 'N' },
    { word: 'inconceivableness', word_class: 'N' }
  ],
  skeletal: [
    { word: 'skeleton', word_class: 'N' },
    { word: 'skeletal', word_class: 'AJ' },
    { word: 'skeletonise', word_class: 'V' },
    { word: 'skeletonize', word_class: 'V' },
    { word: 'skeletonization', word_class: 'N' }
  ],
  listener: [
    { word: 'listen', word_class: 'V' },
    { word: 'listener', word_class: 'N' },
    { word: 'listening', word_class: 'N' },
    { word: 'listening', word_class: 'AJ' }
  ],
  deliquescence: [
    { word: 'deliquesce', word_class: 'V' },
    { word: 'deliquescence', word_class: 'N' },
    { word: 'deliquescent', word_class: 'AJ' }
  ],
  ethnographical: [
    { word: 'ethnographer', word_class: 'N' },
    { word: 'ethnographic', word_class: 'AJ' },
    { word: 'ethnographical', word_class: 'AJ' }
  ],
  planetary: [
    { word: 'planet', word_class: 'N' },
    { word: 'planetal', word_class: 'AJ' },
    { word: 'planetoid', word_class: 'N' },
    { word: 'planetary', word_class: 'AJ' }
  ],
  caramelization: [
    { word: 'caramel', word_class: 'N' },
    { word: 'caramel', word_class: 'AJ' },
    { word: 'caramelize', word_class: 'V' },
    { word: 'caramelization', word_class: 'N' }
  ],
  defaced: [
    { word: 'deface', word_class: 'V' },
    { word: 'defaced', word_class: 'AJ' },
    { word: 'defacement', word_class: 'N' }
  ],
  dabbled: [
    { word: 'dabble', word_class: 'V' },
    { word: 'dabbler', word_class: 'N' },
    { word: 'dabbled', word_class: 'AJ' }
  ],
  voyager: [
    { word: 'voyage', word_class: 'N' },
    { word: 'voyage', word_class: 'V' },
    { word: 'voyager', word_class: 'N' }
  ],
  supported: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  extrusion: [
    { word: 'extrude', word_class: 'V' },
    { word: 'extrusion', word_class: 'N' },
    { word: 'extrusive', word_class: 'AJ' }
  ],
  freeze: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  chasten: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  early: [
    { word: 'early', word_class: 'AJ' },
    { word: 'early', word_class: 'AV' },
    { word: 'earliness', word_class: 'N' }
  ],
  obversion: [
    { word: 'obvert', word_class: 'V' },
    { word: 'obverse', word_class: 'N' },
    { word: 'obverse', word_class: 'AJ' },
    { word: 'obversion', word_class: 'N' }
  ],
  chisel: [
    { word: 'chisel', word_class: 'N' },
    { word: 'chisel', word_class: 'V' },
    { word: 'chiseler', word_class: 'N' },
    { word: 'chiseled', word_class: 'AJ' },
    { word: 'chiseller', word_class: 'N' }
  ],
  cosmologic: [
    { word: 'cosmology', word_class: 'N' },
    { word: 'cosmologic', word_class: 'AJ' },
    { word: 'cosmological', word_class: 'AJ' }
  ],
  ostensible: [
    { word: 'ostensive', word_class: 'AJ' },
    { word: 'ostensible', word_class: 'AJ' },
    { word: 'ostensibly', word_class: 'AV' }
  ],
  toothsome: [
    { word: 'tooth', word_class: 'N' },
    { word: 'toothed', word_class: 'AJ' },
    { word: 'toothsome', word_class: 'AJ' },
    { word: 'toothsomeness', word_class: 'N' }
  ],
  justifiably: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  predetermined: [
    { word: 'predetermine', word_class: 'V' },
    { word: 'predetermined', word_class: 'AJ' },
    { word: 'predetermination', word_class: 'N' }
  ],
  circulating: [
    { word: 'circulant', word_class: 'N' },
    { word: 'circulate', word_class: 'V' },
    { word: 'circulation', word_class: 'N' },
    { word: 'circulating', word_class: 'AJ' },
    { word: 'circulative', word_class: 'AJ' }
  ],
  cowman: [
    { word: 'cow', word_class: 'N' },
    { word: 'cow', word_class: 'V' },
    { word: 'cows', word_class: 'N' },
    { word: 'cowed', word_class: 'AJ' },
    { word: 'cowman', word_class: 'N' }
  ],
  malign: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  deviance: [
    { word: 'deviant', word_class: 'N' },
    { word: 'deviate', word_class: 'N' },
    { word: 'deviate', word_class: 'V' },
    { word: 'deviance', word_class: 'N' },
    { word: 'deviant', word_class: 'AJ' },
    { word: 'deviancy', word_class: 'N' },
    { word: 'deviation', word_class: 'N' }
  ],
  rape: [
    { word: 'rape', word_class: 'N' },
    { word: 'rape', word_class: 'V' },
    { word: 'raper', word_class: 'N' },
    { word: 'raped', word_class: 'AJ' },
    { word: 'rapist', word_class: 'N' }
  ],
  tilted: [{ word: 'tilt', word_class: 'N' }, { word: 'tilt', word_class: 'V' }, { word: 'tilted', word_class: 'AJ' }],
  adsorbed: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  effort: [
    { word: 'effort', word_class: 'N' },
    { word: 'effortful', word_class: 'AJ' },
    { word: 'effortfulness', word_class: 'N' }
  ],
  blasphemous: [
    { word: 'blaspheme', word_class: 'V' },
    { word: 'blasphemy', word_class: 'N' },
    { word: 'blasphemer', word_class: 'N' },
    { word: 'blasphemous', word_class: 'AJ' },
    { word: 'blasphemously', word_class: 'AV' }
  ],
  stripes: [
    { word: 'stripe', word_class: 'N' },
    { word: 'stripe', word_class: 'V' },
    { word: 'stripes', word_class: 'N' },
    { word: 'stripy', word_class: 'AJ' },
    { word: 'striped', word_class: 'AJ' },
    { word: 'striping', word_class: 'N' }
  ],
  bosomed: [
    { word: 'bosom', word_class: 'N' },
    { word: 'bosom', word_class: 'V' },
    { word: 'bosom', word_class: 'AJ' },
    { word: 'bosomed', word_class: 'AJ' }
  ],
  binocular: [
    { word: 'binocular', word_class: 'N' },
    { word: 'binocular', word_class: 'AJ' },
    { word: 'binoculars', word_class: 'N' }
  ],
  vaulter: [
    { word: 'vault', word_class: 'N' },
    { word: 'vault', word_class: 'V' },
    { word: 'vaulter', word_class: 'N' },
    { word: 'vaulted', word_class: 'AJ' },
    { word: 'vaulting', word_class: 'N' },
    { word: 'vaulting', word_class: 'AJ' }
  ],
  basidiomycete: [
    { word: 'basidiomycete', word_class: 'N' },
    { word: 'basidiomycetes', word_class: 'N' },
    { word: 'basidiomycetous', word_class: 'AJ' }
  ],
  logically: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  divisible: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  chemical: [
    { word: 'chemic', word_class: 'AJ' },
    { word: 'chemical', word_class: 'N' },
    { word: 'chemical', word_class: 'AJ' },
    { word: 'chemically', word_class: 'AV' }
  ],
  refrain: [
    { word: 'refrain', word_class: 'N' },
    { word: 'refrain', word_class: 'V' },
    { word: 'refrainment', word_class: 'N' }
  ],
  iodin: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  purloinment: [
    { word: 'purloin', word_class: 'V' },
    { word: 'purloined', word_class: 'AJ' },
    { word: 'purloinment', word_class: 'N' }
  ],
  falcon: [
    { word: 'falcon', word_class: 'N' },
    { word: 'falcon', word_class: 'V' },
    { word: 'falconer', word_class: 'N' },
    { word: 'falconry', word_class: 'N' }
  ],
  ploughman: [
    { word: 'plough', word_class: 'N' },
    { word: 'plough', word_class: 'V' },
    { word: 'ploughed', word_class: 'AJ' },
    { word: 'ploughing', word_class: 'N' },
    { word: 'ploughman', word_class: 'N' }
  ],
  mummify: [
    { word: 'mummy', word_class: 'N' },
    { word: 'mummify', word_class: 'V' },
    { word: 'mummification', word_class: 'N' }
  ],
  gamble: [
    { word: 'gamble', word_class: 'N' },
    { word: 'gamble', word_class: 'V' },
    { word: 'gambler', word_class: 'N' },
    { word: 'gambling', word_class: 'N' },
    { word: 'gambling', word_class: 'AJ' }
  ],
  damaged: [
    { word: 'damage', word_class: 'N' },
    { word: 'damage', word_class: 'V' },
    { word: 'damages', word_class: 'N' },
    { word: 'damaged', word_class: 'AJ' },
    { word: 'damaging', word_class: 'AJ' }
  ],
  aggressively: [
    { word: 'aggress', word_class: 'V' },
    { word: 'aggression', word_class: 'N' },
    { word: 'aggressive', word_class: 'AJ' },
    { word: 'aggressively', word_class: 'AV' },
    { word: 'aggressiveness', word_class: 'N' }
  ],
  breathe: [
    { word: 'breath', word_class: 'N' },
    { word: 'breathe', word_class: 'V' },
    { word: 'breather', word_class: 'N' },
    { word: 'breathed', word_class: 'AJ' },
    { word: 'breathing', word_class: 'N' },
    { word: 'breathing', word_class: 'AJ' }
  ],
  eventuation: [
    { word: 'eventual', word_class: 'AJ' },
    { word: 'eventuate', word_class: 'V' },
    { word: 'eventuality', word_class: 'N' },
    { word: 'eventually', word_class: 'AV' },
    { word: 'eventuation', word_class: 'N' }
  ],
  rumored: [
    { word: 'rumor', word_class: 'N' },
    { word: 'rumor', word_class: 'V' },
    { word: 'rumored', word_class: 'AJ' }
  ],
  vending: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  commutability: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  bruising: [
    { word: 'bruise', word_class: 'N' },
    { word: 'bruise', word_class: 'V' },
    { word: 'bruiser', word_class: 'N' },
    { word: 'bruised', word_class: 'AJ' },
    { word: 'bruising', word_class: 'AJ' }
  ],
  sagging: [{ word: 'sag', word_class: 'N' }, { word: 'sag', word_class: 'V' }, { word: 'sagging', word_class: 'AJ' }],
  phonemic: [
    { word: 'phoneme', word_class: 'N' },
    { word: 'phonemic', word_class: 'AJ' },
    { word: 'phonemic', word_class: 'AV' },
    { word: 'phonemics', word_class: 'N' }
  ],
  victimization: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  ataraxic: [
    { word: 'atarax', word_class: 'N' },
    { word: 'ataraxic', word_class: 'N' },
    { word: 'ataraxic', word_class: 'AJ' }
  ],
  decent: [
    { word: 'decent', word_class: 'AJ' },
    { word: 'decent', word_class: 'AV' },
    { word: 'decently', word_class: 'AV' }
  ],
  catty: [
    { word: 'catty', word_class: 'N' },
    { word: 'cattie', word_class: 'N' },
    { word: 'catty', word_class: 'AJ' },
    { word: 'cattiness', word_class: 'N' }
  ],
  acknowledged: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  soldier: [
    { word: 'soldier', word_class: 'N' },
    { word: 'soldier', word_class: 'V' },
    { word: 'soldiery', word_class: 'N' },
    { word: 'soldiery', word_class: 'AJ' },
    { word: 'soldiering', word_class: 'N' },
    { word: 'soldiership', word_class: 'N' }
  ],
  righthanded: [
    { word: 'righthand', word_class: 'AJ' },
    { word: 'righthanded', word_class: 'AJ' },
    { word: 'righthandedness', word_class: 'N' }
  ],
  unskillfulness: [
    { word: 'unskilled', word_class: 'AJ' },
    { word: 'unskillful', word_class: 'AJ' },
    { word: 'unskillfulness', word_class: 'N' }
  ],
  wound: [
    { word: 'wound', word_class: 'N' },
    { word: 'wound', word_class: 'V' },
    { word: 'wound', word_class: 'AJ' },
    { word: 'wounded', word_class: 'N' },
    { word: 'wounded', word_class: 'AJ' },
    { word: 'wounding', word_class: 'N' },
    { word: 'wounding', word_class: 'AJ' }
  ],
  torn: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  centric: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  pointer: [
    { word: 'point', word_class: 'N' },
    { word: 'point', word_class: 'V' },
    { word: 'pointer', word_class: 'N' },
    { word: 'pointed', word_class: 'AJ' },
    { word: 'pointing', word_class: 'N' },
    { word: 'pointing', word_class: 'AJ' }
  ],
  'anti-semitism': [
    { word: 'anti-semite', word_class: 'N' },
    { word: 'anti-semite', word_class: 'AJ' },
    { word: 'anti-semitic', word_class: 'AJ' },
    { word: 'anti-semitism', word_class: 'N' }
  ],
  vultur: [
    { word: 'vultur', word_class: 'N' },
    { word: 'vulture', word_class: 'N' },
    { word: 'vulturous', word_class: 'AJ' }
  ],
  blender: [
    { word: 'blend', word_class: 'N' },
    { word: 'blend', word_class: 'V' },
    { word: 'blende', word_class: 'N' },
    { word: 'blender', word_class: 'N' },
    { word: 'blended', word_class: 'AJ' },
    { word: 'blending', word_class: 'N' },
    { word: 'blending', word_class: 'AJ' }
  ],
  connecter: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  diluted: [
    { word: 'dilute', word_class: 'N' },
    { word: 'dilute', word_class: 'V' },
    { word: 'dilute', word_class: 'AJ' },
    { word: 'dilutant', word_class: 'N' },
    { word: 'diluted', word_class: 'AJ' },
    { word: 'diluting', word_class: 'N' },
    { word: 'dilution', word_class: 'N' }
  ],
  shudder: [
    { word: 'shudder', word_class: 'N' },
    { word: 'shudder', word_class: 'V' },
    { word: 'shuddering', word_class: 'AJ' }
  ],
  imported: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  efflorescent: [
    { word: 'effloresce', word_class: 'V' },
    { word: 'efflorescence', word_class: 'N' },
    { word: 'efflorescent', word_class: 'AJ' }
  ],
  baptise: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  punished: [
    { word: 'punish', word_class: 'V' },
    { word: 'punished', word_class: 'AJ' },
    { word: 'punishing', word_class: 'AJ' },
    { word: 'punishment', word_class: 'N' },
    { word: 'punishable', word_class: 'AJ' }
  ],
  severed: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  informally: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  bloom: [
    { word: 'bloom', word_class: 'N' },
    { word: 'bloom', word_class: 'V' },
    { word: 'blooming', word_class: 'N' },
    { word: 'blooming', word_class: 'AJ' }
  ],
  uprise: [
    { word: 'uprise', word_class: 'V' },
    { word: 'uprisen', word_class: 'AJ' },
    { word: 'uprising', word_class: 'N' }
  ],
  closure: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  blonde: [
    { word: 'blond', word_class: 'N' },
    { word: 'blond', word_class: 'AJ' },
    { word: 'blonde', word_class: 'N' },
    { word: 'blonde', word_class: 'AJ' },
    { word: 'blondness', word_class: 'N' }
  ],
  alphabetized: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  enrolled: [
    { word: 'enrol', word_class: 'V' },
    { word: 'enroll', word_class: 'V' },
    { word: 'enrolled', word_class: 'AJ' },
    { word: 'enrolment', word_class: 'N' },
    { word: 'enrollment', word_class: 'N' }
  ],
  responsive: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  maturity: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  deepness: [
    { word: 'deep', word_class: 'N' },
    { word: 'deep', word_class: 'AJ' },
    { word: 'deep', word_class: 'AV' },
    { word: 'deepness', word_class: 'N' }
  ],
  conversation: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  telescope: [
    { word: 'telescope', word_class: 'N' },
    { word: 'telescope', word_class: 'V' },
    { word: 'telescoped', word_class: 'AJ' },
    { word: 'telescopic', word_class: 'AJ' },
    { word: 'telescopically', word_class: 'AV' }
  ],
  prolong: [
    { word: 'prolong', word_class: 'V' },
    { word: 'prolong', word_class: 'AJ' },
    { word: 'prolongate', word_class: 'V' },
    { word: 'prolonged', word_class: 'AJ' },
    { word: 'prolongment', word_class: 'N' },
    { word: 'prolongation', word_class: 'N' }
  ],
  municipalize: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  thruster: [
    { word: 'thrust', word_class: 'N' },
    { word: 'thrust', word_class: 'V' },
    { word: 'thruster', word_class: 'N' },
    { word: 'thrusting', word_class: 'N' }
  ],
  paternal: [
    { word: 'paternal', word_class: 'AJ' },
    { word: 'paternity', word_class: 'N' },
    { word: 'paternalism', word_class: 'N' },
    { word: 'paternally', word_class: 'AV' }
  ],
  capon: [
    { word: 'capon', word_class: 'N' },
    { word: 'caponize', word_class: 'V' },
    { word: 'caponization', word_class: 'N' }
  ],
  duplicity: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  airman: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  ephemeral: [
    { word: 'ephemeral', word_class: 'AJ' },
    { word: 'ephemerality', word_class: 'N' },
    { word: 'ephemeralness', word_class: 'N' }
  ],
  ammoniac: [
    { word: 'ammoniac', word_class: 'N' },
    { word: 'ammoniac', word_class: 'AJ' },
    { word: 'ammoniacal', word_class: 'AJ' }
  ],
  incumbency: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  vaunt: [{ word: 'vaunt', word_class: 'N' }, { word: 'vaunt', word_class: 'V' }, { word: 'vaunter', word_class: 'N' }],
  vicarious: [
    { word: 'vicarial', word_class: 'AJ' },
    { word: 'vicariate', word_class: 'N' },
    { word: 'vicarious', word_class: 'AJ' },
    { word: 'vicariously', word_class: 'AV' }
  ],
  collaborate: [
    { word: 'collaborate', word_class: 'V' },
    { word: 'collaborator', word_class: 'N' },
    { word: 'collaboration', word_class: 'N' }
  ],
  solidification: [
    { word: 'solidify', word_class: 'V' },
    { word: 'solidified', word_class: 'AJ' },
    { word: 'solidifying', word_class: 'N' },
    { word: 'solidification', word_class: 'N' }
  ],
  evident: [
    { word: 'evidence', word_class: 'N' },
    { word: 'evidence', word_class: 'V' },
    { word: 'evident', word_class: 'AJ' },
    { word: 'evidently', word_class: 'AV' },
    { word: 'evidential', word_class: 'AJ' }
  ],
  instantaneousness: [
    { word: 'instantaneous', word_class: 'AJ' },
    { word: 'instantaneously', word_class: 'AV' },
    { word: 'instantaneousness', word_class: 'N' }
  ],
  progressiveness: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  diver: [
    { word: 'dive', word_class: 'N' },
    { word: 'dive', word_class: 'V' },
    { word: 'diver', word_class: 'N' },
    { word: 'diving', word_class: 'N' },
    { word: 'divers', word_class: 'AJ' }
  ],
  tense: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  sibilant: [
    { word: 'sibilant', word_class: 'N' },
    { word: 'sibilate', word_class: 'V' },
    { word: 'sibilant', word_class: 'AJ' },
    { word: 'sibilation', word_class: 'N' }
  ],
  affably: [
    { word: 'affable', word_class: 'AJ' },
    { word: 'affably', word_class: 'AV' },
    { word: 'affability', word_class: 'N' },
    { word: 'affableness', word_class: 'N' }
  ],
  fool: [
    { word: 'fool', word_class: 'N' },
    { word: 'fool', word_class: 'V' },
    { word: 'fool', word_class: 'AJ' },
    { word: 'fooling', word_class: 'AJ' }
  ],
  insider: [
    { word: 'inside', word_class: 'N' },
    { word: 'inside', word_class: 'V' },
    { word: 'inside', word_class: 'AJ' },
    { word: 'inside', word_class: 'AV' },
    { word: 'insider', word_class: 'N' }
  ],
  choral: [
    { word: 'choral', word_class: 'N' },
    { word: 'choral', word_class: 'AJ' },
    { word: 'chorale', word_class: 'N' },
    { word: 'chorally', word_class: 'AV' }
  ],
  tablespoon: [
    { word: 'tablespoon', word_class: 'N' },
    { word: 'tablespoonful', word_class: 'N' },
    { word: 'tablespoonful', word_class: 'AJ' }
  ],
  seasonal: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  arbitrable: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  hurling: [
    { word: 'hurl', word_class: 'N' },
    { word: 'hurl', word_class: 'V' },
    { word: 'hurler', word_class: 'N' },
    { word: 'hurling', word_class: 'N' },
    { word: 'hurling', word_class: 'AJ' }
  ],
  flown: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  conscience: [
    { word: 'conscience', word_class: 'N' },
    { word: 'conscientious', word_class: 'AJ' },
    { word: 'conscientiously', word_class: 'AV' },
    { word: 'conscientiousness', word_class: 'N' }
  ],
  decussate: [
    { word: 'decussate', word_class: 'V' },
    { word: 'decussate', word_class: 'AJ' },
    { word: 'decussation', word_class: 'N' }
  ],
  seed: [
    { word: 'seed', word_class: 'N' },
    { word: 'seed', word_class: 'V' },
    { word: 'seeder', word_class: 'N' },
    { word: 'seeded', word_class: 'AJ' },
    { word: 'seedman', word_class: 'N' }
  ],
  sensible: [
    { word: 'sensible', word_class: 'AJ' },
    { word: 'sensibly', word_class: 'AV' },
    { word: 'sensibility', word_class: 'N' },
    { word: 'sensibleness', word_class: 'N' }
  ],
  bricklayer: [
    { word: 'bricklay', word_class: 'V' },
    { word: 'bricklayer', word_class: 'N' },
    { word: 'bricklaying', word_class: 'N' }
  ],
  detente: [
    { word: 'detain', word_class: 'V' },
    { word: 'detent', word_class: 'N' },
    { word: 'detente', word_class: 'N' },
    { word: 'detainee', word_class: 'N' },
    { word: 'detention', word_class: 'N' }
  ],
  socialization: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  aggrandize: [
    { word: 'aggrandise', word_class: 'V' },
    { word: 'aggrandize', word_class: 'V' },
    { word: 'aggrandisement', word_class: 'N' },
    { word: 'aggrandizement', word_class: 'N' }
  ],
  honoured: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  populous: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  extricate: [
    { word: 'extricate', word_class: 'V' },
    { word: 'extricable', word_class: 'AJ' },
    { word: 'extricated', word_class: 'AJ' },
    { word: 'extrication', word_class: 'N' }
  ],
  'ill-used': [
    { word: 'ill-use', word_class: 'N' },
    { word: 'ill-use', word_class: 'V' },
    { word: 'ill-used', word_class: 'AJ' }
  ],
  mix: [
    { word: 'mix', word_class: 'N' },
    { word: 'mix', word_class: 'V' },
    { word: 'mixer', word_class: 'N' },
    { word: 'mixed', word_class: 'AJ' },
    { word: 'mixing', word_class: 'N' },
    { word: 'mixture', word_class: 'N' }
  ],
  hatefulness: [
    { word: 'hate', word_class: 'N' },
    { word: 'hate', word_class: 'V' },
    { word: 'hater', word_class: 'N' },
    { word: 'hated', word_class: 'AJ' },
    { word: 'hateful', word_class: 'AJ' },
    { word: 'hatefulness', word_class: 'N' }
  ],
  splattering: [
    { word: 'splatter', word_class: 'N' },
    { word: 'splatter', word_class: 'V' },
    { word: 'splattered', word_class: 'AJ' },
    { word: 'splattering', word_class: 'N' }
  ],
  marche: [
    { word: 'march', word_class: 'N' },
    { word: 'march', word_class: 'V' },
    { word: 'marche', word_class: 'N' },
    { word: 'marcher', word_class: 'N' },
    { word: 'marches', word_class: 'N' },
    { word: 'marching', word_class: 'N' },
    { word: 'marching', word_class: 'AJ' }
  ],
  accumulator: [
    { word: 'accumulate', word_class: 'V' },
    { word: 'accumulator', word_class: 'N' },
    { word: 'accumulated', word_class: 'AJ' },
    { word: 'accumulation', word_class: 'N' },
    { word: 'accumulative', word_class: 'AJ' }
  ],
  first: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  austerely: [
    { word: 'austere', word_class: 'AJ' },
    { word: 'austerity', word_class: 'N' },
    { word: 'austerely', word_class: 'AV' },
    { word: 'austereness', word_class: 'N' }
  ],
  battery: [
    { word: 'batter', word_class: 'N' },
    { word: 'batter', word_class: 'V' },
    { word: 'battery', word_class: 'N' },
    { word: 'battery', word_class: 'AJ' },
    { word: 'battered', word_class: 'AJ' },
    { word: 'battering', word_class: 'N' }
  ],
  convincible: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  affectional: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  salt: [
    { word: 'salt', word_class: 'N' },
    { word: 'salt', word_class: 'V' },
    { word: 'salt', word_class: 'AJ' },
    { word: 'salted', word_class: 'AJ' },
    { word: 'salting', word_class: 'N' }
  ],
  glassful: [
    { word: 'glass', word_class: 'N' },
    { word: 'glass', word_class: 'V' },
    { word: 'glasses', word_class: 'N' },
    { word: 'glassed', word_class: 'AJ' },
    { word: 'glassful', word_class: 'N' },
    { word: 'glassful', word_class: 'AJ' }
  ],
  agonistic: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  lurching: [
    { word: 'lurch', word_class: 'N' },
    { word: 'lurch', word_class: 'V' },
    { word: 'lurcher', word_class: 'N' },
    { word: 'lurching', word_class: 'AJ' }
  ],
  cascade: [
    { word: 'cascade', word_class: 'N' },
    { word: 'cascade', word_class: 'V' },
    { word: 'cascades', word_class: 'N' }
  ],
  kneel: [
    { word: 'kneel', word_class: 'N' },
    { word: 'kneel', word_class: 'V' },
    { word: 'kneeler', word_class: 'N' },
    { word: 'kneeling', word_class: 'N' },
    { word: 'kneeling', word_class: 'AJ' }
  ],
  frenzied: [
    { word: 'frenzy', word_class: 'N' },
    { word: 'frenzy', word_class: 'V' },
    { word: 'frenzied', word_class: 'AJ' }
  ],
  precipitance: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  own: [
    { word: 'own', word_class: 'V' },
    { word: 'own', word_class: 'AJ' },
    { word: 'owner', word_class: 'N' },
    { word: 'owned', word_class: 'AJ' }
  ],
  abusively: [
    { word: 'abuse', word_class: 'N' },
    { word: 'abuse', word_class: 'V' },
    { word: 'abuser', word_class: 'N' },
    { word: 'abused', word_class: 'AJ' },
    { word: 'abusive', word_class: 'AJ' },
    { word: 'abusively', word_class: 'AV' }
  ],
  summarise: [
    { word: 'summary', word_class: 'N' },
    { word: 'summary', word_class: 'AJ' },
    { word: 'summarise', word_class: 'V' },
    { word: 'summarize', word_class: 'V' },
    { word: 'summarisation', word_class: 'N' },
    { word: 'summarization', word_class: 'N' }
  ],
  abhorrence: [
    { word: 'abhor', word_class: 'V' },
    { word: 'abhorrer', word_class: 'N' },
    { word: 'abhorrence', word_class: 'N' },
    { word: 'abhorrent', word_class: 'AJ' }
  ],
  salient: [
    { word: 'salient', word_class: 'N' },
    { word: 'salience', word_class: 'N' },
    { word: 'saliency', word_class: 'N' },
    { word: 'salient', word_class: 'AJ' }
  ],
  ramble: [
    { word: 'ramble', word_class: 'N' },
    { word: 'ramble', word_class: 'V' },
    { word: 'rambler', word_class: 'N' },
    { word: 'rambling', word_class: 'AJ' }
  ],
  vitalist: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  exemplifying: [
    { word: 'exemplify', word_class: 'V' },
    { word: 'exemplifying', word_class: 'AJ' },
    { word: 'exemplification', word_class: 'N' }
  ],
  cooperate: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  caffeinism: [
    { word: 'caffein', word_class: 'N' },
    { word: 'caffeine', word_class: 'N' },
    { word: 'caffeinic', word_class: 'AJ' },
    { word: 'caffeinism', word_class: 'N' }
  ],
  revived: [
    { word: 'revive', word_class: 'V' },
    { word: 'revival', word_class: 'N' },
    { word: 'revive', word_class: 'AJ' },
    { word: 'revived', word_class: 'AJ' },
    { word: 'reviving', word_class: 'AJ' },
    { word: 'revivalism', word_class: 'N' }
  ],
  maturation: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  entomologic: [
    { word: 'entomology', word_class: 'N' },
    { word: 'entomologic', word_class: 'AJ' },
    { word: 'entomological', word_class: 'AJ' }
  ],
  exploding: [
    { word: 'explode', word_class: 'V' },
    { word: 'explosion', word_class: 'N' },
    { word: 'explosive', word_class: 'N' },
    { word: 'exploding', word_class: 'AJ' },
    { word: 'explosive', word_class: 'AJ' },
    { word: 'explosively', word_class: 'AV' }
  ],
  sustinence: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  humorous: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  pauperism: [
    { word: 'pauper', word_class: 'N' },
    { word: 'pauper', word_class: 'V' },
    { word: 'pauperism', word_class: 'N' },
    { word: 'pauperize', word_class: 'V' },
    { word: 'pauperization', word_class: 'N' }
  ],
  bale: [
    { word: 'bale', word_class: 'N' },
    { word: 'bale', word_class: 'V' },
    { word: 'baleful', word_class: 'AJ' },
    { word: 'balefulness', word_class: 'N' }
  ],
  utopia: [
    { word: 'utopia', word_class: 'N' },
    { word: 'utopian', word_class: 'N' },
    { word: 'utopian', word_class: 'AJ' },
    { word: 'utopianism', word_class: 'N' }
  ],
  plasterer: [
    { word: 'plaster', word_class: 'N' },
    { word: 'plaster', word_class: 'V' },
    { word: 'plasterer', word_class: 'N' },
    { word: 'plastered', word_class: 'AJ' },
    { word: 'plastering', word_class: 'N' }
  ],
  palpably: [
    { word: 'palpable', word_class: 'AJ' },
    { word: 'palpably', word_class: 'AV' },
    { word: 'palpability', word_class: 'N' }
  ],
  voraciously: [
    { word: 'voracious', word_class: 'AJ' },
    { word: 'voraciously', word_class: 'AV' },
    { word: 'voraciousness', word_class: 'N' }
  ],
  sexually: [
    { word: 'sexual', word_class: 'AJ' },
    { word: 'sexuality', word_class: 'N' },
    { word: 'sexually', word_class: 'AV' }
  ],
  acquiring: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  sign: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  quantise: [
    { word: 'quantise', word_class: 'V' },
    { word: 'quantize', word_class: 'V' },
    { word: 'quantisation', word_class: 'N' },
    { word: 'quantization', word_class: 'N' }
  ],
  timorously: [
    { word: 'timorous', word_class: 'AJ' },
    { word: 'timorously', word_class: 'AV' },
    { word: 'timorousness', word_class: 'N' }
  ],
  tastefulness: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  excellent: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  cabable: [
    { word: 'cabable', word_class: 'AJ' },
    { word: 'capable', word_class: 'AJ' },
    { word: 'capably', word_class: 'AV' },
    { word: 'capability', word_class: 'N' },
    { word: 'capableness', word_class: 'N' }
  ],
  acquirer: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  circumscription: [
    { word: 'circumscribe', word_class: 'N' },
    { word: 'circumscribe', word_class: 'V' },
    { word: 'circumscribed', word_class: 'AJ' },
    { word: 'circumscription', word_class: 'N' }
  ],
  facial: [
    { word: 'facial', word_class: 'N' },
    { word: 'facial', word_class: 'AJ' },
    { word: 'facially', word_class: 'AV' }
  ],
  coaxing: [
    { word: 'coax', word_class: 'N' },
    { word: 'coax', word_class: 'V' },
    { word: 'coaxing', word_class: 'N' },
    { word: 'coaxing', word_class: 'AJ' }
  ],
  excision: [
    { word: 'excide', word_class: 'V' },
    { word: 'excise', word_class: 'N' },
    { word: 'excise', word_class: 'V' },
    { word: 'excision', word_class: 'N' },
    { word: 'exciseman', word_class: 'N' }
  ],
  infinitely: [
    { word: 'infinite', word_class: 'AJ' },
    { word: 'infinitive', word_class: 'N' },
    { word: 'infinitely', word_class: 'AV' },
    { word: 'infinitive', word_class: 'AJ' },
    { word: 'infiniteness', word_class: 'N' }
  ],
  gnawer: [{ word: 'gnaw', word_class: 'N' }, { word: 'gnaw', word_class: 'V' }, { word: 'gnawer', word_class: 'N' }],
  interlacement: [
    { word: 'interlace', word_class: 'V' },
    { word: 'interlaced', word_class: 'AJ' },
    { word: 'interlacing', word_class: 'AJ' },
    { word: 'interlacement', word_class: 'N' }
  ],
  obstructed: [
    { word: 'obstruct', word_class: 'V' },
    { word: 'obstructed', word_class: 'AJ' },
    { word: 'obstruction', word_class: 'N' },
    { word: 'obstructive', word_class: 'AJ' },
    { word: 'obstructively', word_class: 'AV' }
  ],
  vesicate: [
    { word: 'vesical', word_class: 'AJ' },
    { word: 'vesicant', word_class: 'N' },
    { word: 'vesicate', word_class: 'V' },
    { word: 'vesicant', word_class: 'AJ' },
    { word: 'vesication', word_class: 'N' }
  ],
  portraying: [
    { word: 'portray', word_class: 'V' },
    { word: 'portrayal', word_class: 'N' },
    { word: 'portrayed', word_class: 'AJ' },
    { word: 'portraying', word_class: 'N' }
  ],
  millenarianism: [
    { word: 'millenarian', word_class: 'N' },
    { word: 'millenarian', word_class: 'AJ' },
    { word: 'millenarianism', word_class: 'N' }
  ],
  banqueting: [
    { word: 'banquet', word_class: 'N' },
    { word: 'banquet', word_class: 'V' },
    { word: 'banqueting', word_class: 'N' }
  ],
  jubilate: [
    { word: 'jubilate', word_class: 'V' },
    { word: 'jubilance', word_class: 'N' },
    { word: 'jubilancy', word_class: 'N' },
    { word: 'jubilant', word_class: 'AJ' },
    { word: 'jubilation', word_class: 'N' }
  ],
  enforceable: [
    { word: 'enforce', word_class: 'V' },
    { word: 'enforcer', word_class: 'N' },
    { word: 'enforced', word_class: 'AJ' },
    { word: 'enforcement', word_class: 'N' },
    { word: 'enforceable', word_class: 'AJ' }
  ],
  upend: [
    { word: 'upend', word_class: 'V' },
    { word: 'upended', word_class: 'AJ' },
    { word: 'upending', word_class: 'N' }
  ],
  cultural: [
    { word: 'culture', word_class: 'N' },
    { word: 'culture', word_class: 'V' },
    { word: 'cultural', word_class: 'AJ' },
    { word: 'cultured', word_class: 'AJ' },
    { word: 'culturally', word_class: 'AV' }
  ],
  legerity: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  resistance: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  staging: [
    { word: 'stage', word_class: 'N' },
    { word: 'stage', word_class: 'V' },
    { word: 'stager', word_class: 'N' },
    { word: 'staged', word_class: 'AJ' },
    { word: 'staging', word_class: 'N' }
  ],
  diminishing: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  pyrotechnical: [
    { word: 'pyrotechnic', word_class: 'AJ' },
    { word: 'pyrotechnics', word_class: 'N' },
    { word: 'pyrotechnical', word_class: 'AJ' }
  ],
  quantifiable: [
    { word: 'quantify', word_class: 'V' },
    { word: 'quantifier', word_class: 'N' },
    { word: 'quantifiable', word_class: 'AJ' },
    { word: 'quantification', word_class: 'N' },
    { word: 'quantifiability', word_class: 'N' }
  ],
  allegoric: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  misplacement: [
    { word: 'misplace', word_class: 'V' },
    { word: 'misplaced', word_class: 'AJ' },
    { word: 'misplacement', word_class: 'N' }
  ],
  identically: [
    { word: 'identity', word_class: 'N' },
    { word: 'identical', word_class: 'AJ' },
    { word: 'identically', word_class: 'AV' }
  ],
  dogged: [
    { word: 'dog', word_class: 'N' },
    { word: 'dog', word_class: 'V' },
    { word: 'dogged', word_class: 'AJ' },
    { word: 'dogging', word_class: 'AJ' }
  ],
  slothful: [
    { word: 'sloth', word_class: 'N' },
    { word: 'slothful', word_class: 'AJ' },
    { word: 'slothfulness', word_class: 'N' }
  ],
  computerize: [
    { word: 'computerise', word_class: 'V' },
    { word: 'computerize', word_class: 'V' },
    { word: 'computerized', word_class: 'AJ' },
    { word: 'computerisation', word_class: 'N' },
    { word: 'computerization', word_class: 'N' }
  ],
  decarburization: [
    { word: 'decarburise', word_class: 'V' },
    { word: 'decarburize', word_class: 'V' },
    { word: 'decarburation', word_class: 'N' },
    { word: 'decarburisation', word_class: 'N' },
    { word: 'decarburization', word_class: 'N' }
  ],
  trepanation: [
    { word: 'trepan', word_class: 'N' },
    { word: 'trepan', word_class: 'V' },
    { word: 'trepanation', word_class: 'N' }
  ],
  peaceable: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  tuft: [{ word: 'tuft', word_class: 'N' }, { word: 'tuft', word_class: 'V' }, { word: 'tufted', word_class: 'AJ' }],
  ineffectual: [
    { word: 'ineffectual', word_class: 'AJ' },
    { word: 'ineffectuality', word_class: 'N' },
    { word: 'ineffectually', word_class: 'AV' },
    { word: 'ineffectualness', word_class: 'N' }
  ],
  collider: [
    { word: 'collide', word_class: 'V' },
    { word: 'collider', word_class: 'N' },
    { word: 'collision', word_class: 'N' }
  ],
  unreal: [
    { word: 'unreal', word_class: 'AJ' },
    { word: 'unrealism', word_class: 'N' },
    { word: 'unreality', word_class: 'N' },
    { word: 'unrealized', word_class: 'AJ' }
  ],
  liquidness: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  circumferential: [
    { word: 'circumference', word_class: 'N' },
    { word: 'circumferential', word_class: 'N' },
    { word: 'circumferential', word_class: 'AJ' }
  ],
  anachronism: [
    { word: 'anachronic', word_class: 'AJ' },
    { word: 'anachronism', word_class: 'N' },
    { word: 'anachronous', word_class: 'AJ' }
  ],
  trimness: [
    { word: 'trim', word_class: 'N' },
    { word: 'trim', word_class: 'V' },
    { word: 'trim', word_class: 'AJ' },
    { word: 'trimer', word_class: 'N' },
    { word: 'trimmed', word_class: 'AJ' },
    { word: 'trimming', word_class: 'N' },
    { word: 'trimness', word_class: 'N' }
  ],
  cabinetry: [
    { word: 'cabinet', word_class: 'N' },
    { word: 'cabinet', word_class: 'AJ' },
    { word: 'cabinetry', word_class: 'N' },
    { word: 'cabinetmaker', word_class: 'N' },
    { word: 'cabinetmaking', word_class: 'N' }
  ],
  situated: [
    { word: 'situate', word_class: 'V' },
    { word: 'situated', word_class: 'AJ' },
    { word: 'situating', word_class: 'N' },
    { word: 'situation', word_class: 'N' }
  ],
  dangle: [
    { word: 'dangle', word_class: 'N' },
    { word: 'dangle', word_class: 'V' },
    { word: 'dangling', word_class: 'N' },
    { word: 'dangling', word_class: 'AJ' }
  ],
  turbinal: [
    { word: 'turbine', word_class: 'N' },
    { word: 'turbinal', word_class: 'N' },
    { word: 'turbinate', word_class: 'N' },
    { word: 'turbinate', word_class: 'AJ' }
  ],
  blandish: [
    { word: 'blandish', word_class: 'V' },
    { word: 'blandish', word_class: 'AJ' },
    { word: 'blandishment', word_class: 'N' }
  ],
  bacterially: [
    { word: 'bacteria', word_class: 'N' },
    { word: 'bacterial', word_class: 'AJ' },
    { word: 'bacterially', word_class: 'AV' }
  ],
  oppositive: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  disparate: [
    { word: 'disparity', word_class: 'N' },
    { word: 'disparate', word_class: 'AJ' },
    { word: 'disparateness', word_class: 'N' }
  ],
  subjugable: [
    { word: 'subjugate', word_class: 'V' },
    { word: 'subjugate', word_class: 'AJ' },
    { word: 'subjugable', word_class: 'AJ' },
    { word: 'subjugated', word_class: 'AJ' },
    { word: 'subjugation', word_class: 'N' }
  ],
  caliber: [
    { word: 'caliber', word_class: 'N' },
    { word: 'calibre', word_class: 'N' },
    { word: 'calibrate', word_class: 'V' },
    { word: 'calibration', word_class: 'N' }
  ],
  bellis: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  luxuriously: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  squirmy: [
    { word: 'squirm', word_class: 'N' },
    { word: 'squirm', word_class: 'V' },
    { word: 'squirmy', word_class: 'AJ' },
    { word: 'squirming', word_class: 'AJ' }
  ],
  demagogical: [
    { word: 'demagog', word_class: 'N' },
    { word: 'demagogic', word_class: 'AJ' },
    { word: 'demagogical', word_class: 'AJ' }
  ],
  diphthong: [
    { word: 'diphthong', word_class: 'N' },
    { word: 'diphthongise', word_class: 'V' },
    { word: 'diphthongize', word_class: 'V' },
    { word: 'diphthongisation', word_class: 'N' },
    { word: 'diphthongization', word_class: 'N' }
  ],
  knuckle: [
    { word: 'knuckle', word_class: 'N' },
    { word: 'knuckle', word_class: 'V' },
    { word: 'knuckles', word_class: 'N' }
  ],
  ashen: [{ word: 'ash', word_class: 'N' }, { word: 'ash', word_class: 'V' }, { word: 'ashen', word_class: 'AJ' }],
  climactical: [
    { word: 'climax', word_class: 'N' },
    { word: 'climax', word_class: 'V' },
    { word: 'climactic', word_class: 'AJ' },
    { word: 'climactical', word_class: 'AJ' }
  ],
  leaking: [
    { word: 'leak', word_class: 'N' },
    { word: 'leak', word_class: 'V' },
    { word: 'leakage', word_class: 'N' },
    { word: 'leaking', word_class: 'AJ' }
  ],
  forgivably: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  contrived: [
    { word: 'contrive', word_class: 'V' },
    { word: 'contriver', word_class: 'N' },
    { word: 'contrived', word_class: 'AJ' },
    { word: 'contrivance', word_class: 'N' }
  ],
  sugar: [
    { word: 'sugar', word_class: 'N' },
    { word: 'sugar', word_class: 'V' },
    { word: 'sugared', word_class: 'AJ' }
  ],
  subminiaturise: [
    { word: 'subminiaturise', word_class: 'V' },
    { word: 'subminiaturize', word_class: 'V' },
    { word: 'subminiaturisation', word_class: 'N' },
    { word: 'subminiaturization', word_class: 'N' }
  ],
  gun: [
    { word: 'gun', word_class: 'N' },
    { word: 'gun', word_class: 'V' },
    { word: 'gunnery', word_class: 'N' },
    { word: 'gunnery', word_class: 'AJ' }
  ],
  adolescent: [
    { word: 'adolescent', word_class: 'N' },
    { word: 'adolescence', word_class: 'N' },
    { word: 'adolescent', word_class: 'AJ' }
  ],
  empirically: [
    { word: 'empire', word_class: 'N' },
    { word: 'empiric', word_class: 'AJ' },
    { word: 'empirical', word_class: 'AJ' },
    { word: 'empirically', word_class: 'AV' }
  ],
  imperfectibility: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  concise: [
    { word: 'concise', word_class: 'AJ' },
    { word: 'concision', word_class: 'N' },
    { word: 'concisely', word_class: 'AV' },
    { word: 'conciseness', word_class: 'N' }
  ],
  corporal: [
    { word: 'corporal', word_class: 'N' },
    { word: 'corporal', word_class: 'AJ' },
    { word: 'corporate', word_class: 'AJ' },
    { word: 'corporeal', word_class: 'AJ' },
    { word: 'corporation', word_class: 'N' },
    { word: 'corporeality', word_class: 'N' }
  ],
  consolation: [
    { word: 'console', word_class: 'N' },
    { word: 'console', word_class: 'V' },
    { word: 'consoling', word_class: 'AJ' },
    { word: 'consolable', word_class: 'AJ' },
    { word: 'consolation', word_class: 'N' },
    { word: 'consolement', word_class: 'N' }
  ],
  incapable: [
    { word: 'incapable', word_class: 'AJ' },
    { word: 'incapability', word_class: 'N' },
    { word: 'incapableness', word_class: 'N' }
  ],
  pleasant: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  incandescence: [
    { word: 'incandesce', word_class: 'V' },
    { word: 'incandescence', word_class: 'N' },
    { word: 'incandescent', word_class: 'AJ' }
  ],
  discussion: [
    { word: 'discuss', word_class: 'V' },
    { word: 'discussant', word_class: 'N' },
    { word: 'discussion', word_class: 'N' }
  ],
  cautionary: [
    { word: 'caution', word_class: 'N' },
    { word: 'caution', word_class: 'V' },
    { word: 'cautionary', word_class: 'AJ' }
  ],
  haziness: [
    { word: 'haze', word_class: 'N' },
    { word: 'haze', word_class: 'V' },
    { word: 'hazy', word_class: 'AJ' },
    { word: 'haziness', word_class: 'N' }
  ],
  tillage: [
    { word: 'till', word_class: 'V' },
    { word: 'till', word_class: 'N' },
    { word: 'tiller', word_class: 'N' },
    { word: 'tiller', word_class: 'V' },
    { word: 'tillage', word_class: 'N' },
    { word: 'tilled', word_class: 'AJ' },
    { word: 'tilling', word_class: 'N' }
  ],
  perched: [
    { word: 'perch', word_class: 'N' },
    { word: 'perch', word_class: 'V' },
    { word: 'perched', word_class: 'AJ' }
  ],
  dissenting: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  dye: [
    { word: 'dye', word_class: 'N' },
    { word: 'dye', word_class: 'V' },
    { word: 'dyer', word_class: 'N' },
    { word: 'dyeing', word_class: 'N' }
  ],
  dutiful: [
    { word: 'duty', word_class: 'N' },
    { word: 'dutiful', word_class: 'AJ' },
    { word: 'dutiable', word_class: 'AJ' },
    { word: 'dutifulness', word_class: 'N' }
  ],
  implausible: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  variableness: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  oversea: [
    { word: 'oversea', word_class: 'AJ' },
    { word: 'oversea', word_class: 'AV' },
    { word: 'overseas', word_class: 'AJ' },
    { word: 'overseas', word_class: 'AV' }
  ],
  discontinue: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  setting: [
    { word: 'set', word_class: 'N' },
    { word: 'set', word_class: 'V' },
    { word: 'set', word_class: 'AJ' },
    { word: 'setting', word_class: 'N' },
    { word: 'setting', word_class: 'AJ' }
  ],
  maligned: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  transistor: [
    { word: 'transistor', word_class: 'N' },
    { word: 'transistorise', word_class: 'V' },
    { word: 'transistorize', word_class: 'V' },
    { word: 'transistorized', word_class: 'AJ' },
    { word: 'transistorization', word_class: 'N' }
  ],
  moist: [
    { word: 'moist', word_class: 'AJ' },
    { word: 'moisture', word_class: 'N' },
    { word: 'moistness', word_class: 'N' },
    { word: 'moisturize', word_class: 'V' }
  ],
  twinkle: [
    { word: 'twinkle', word_class: 'N' },
    { word: 'twinkle', word_class: 'V' },
    { word: 'twinkly', word_class: 'AJ' },
    { word: 'twinkling', word_class: 'N' },
    { word: 'twinkling', word_class: 'AJ' }
  ],
  undecomposability: [
    { word: 'undecomposed', word_class: 'AJ' },
    { word: 'undecomposable', word_class: 'AJ' },
    { word: 'undecomposability', word_class: 'N' }
  ],
  bacchante: [
    { word: 'bacchant', word_class: 'N' },
    { word: 'bacchante', word_class: 'N' },
    { word: 'bacchantic', word_class: 'AJ' }
  ],
  denunciative: [
    { word: 'denounce', word_class: 'V' },
    { word: 'denunciate', word_class: 'V' },
    { word: 'denouncement', word_class: 'N' },
    { word: 'denunciation', word_class: 'N' },
    { word: 'denunciative', word_class: 'AJ' }
  ],
  beastliness: [
    { word: 'beastly', word_class: 'AJ' },
    { word: 'beastly', word_class: 'AV' },
    { word: 'beastliness', word_class: 'N' }
  ],
  animatism: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  vigorous: [
    { word: 'vigor', word_class: 'N' },
    { word: 'vigorous', word_class: 'AJ' },
    { word: 'vigorously', word_class: 'AV' }
  ],
  atmospheric: [
    { word: 'atmosphere', word_class: 'N' },
    { word: 'atmospheric', word_class: 'AJ' },
    { word: 'atmospherics', word_class: 'N' },
    { word: 'atmospherical', word_class: 'AJ' }
  ],
  narcotization: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  socialistic: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  dispassionate: [
    { word: 'dispassionate', word_class: 'AJ' },
    { word: 'dispassionately', word_class: 'AV' },
    { word: 'dispassionateness', word_class: 'N' }
  ],
  optimistically: [
    { word: 'optimist', word_class: 'N' },
    { word: 'optimistic', word_class: 'AJ' },
    { word: 'optimistically', word_class: 'AV' }
  ],
  backing: [
    { word: 'back', word_class: 'N' },
    { word: 'back', word_class: 'V' },
    { word: 'back', word_class: 'AJ' },
    { word: 'back', word_class: 'AV' },
    { word: 'backer', word_class: 'N' },
    { word: 'backed', word_class: 'AJ' },
    { word: 'backing', word_class: 'N' }
  ],
  'self-consciousness': [
    { word: 'self-conscious', word_class: 'AJ' },
    { word: 'self-consciously', word_class: 'AV' },
    { word: 'self-consciousness', word_class: 'N' }
  ],
  curving: [
    { word: 'curve', word_class: 'N' },
    { word: 'curve', word_class: 'V' },
    { word: 'curvy', word_class: 'AJ' },
    { word: 'curved', word_class: 'AJ' },
    { word: 'curving', word_class: 'AJ' },
    { word: 'curvature', word_class: 'N' }
  ],
  conserves: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  omnipresent: [
    { word: 'omnipresent', word_class: 'N' },
    { word: 'omnipresent', word_class: 'V' },
    { word: 'omnipresence', word_class: 'N' },
    { word: 'omnipresent', word_class: 'AJ' }
  ],
  radiograph: [
    { word: 'radiograph', word_class: 'N' },
    { word: 'radiographer', word_class: 'N' },
    { word: 'radiographic', word_class: 'AJ' }
  ],
  duty: [
    { word: 'duty', word_class: 'N' },
    { word: 'dutiful', word_class: 'AJ' },
    { word: 'dutiable', word_class: 'AJ' },
    { word: 'dutifulness', word_class: 'N' }
  ],
  academism: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  obdurately: [
    { word: 'obduracy', word_class: 'N' },
    { word: 'obdurate', word_class: 'AJ' },
    { word: 'obdurately', word_class: 'AV' }
  ],
  literate: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  landownership: [
    { word: 'landowner', word_class: 'N' },
    { word: 'landowning', word_class: 'N' },
    { word: 'landowning', word_class: 'AJ' },
    { word: 'landownership', word_class: 'N' }
  ],
  illustration: [
    { word: 'illustrate', word_class: 'V' },
    { word: 'illustrator', word_class: 'N' },
    { word: 'illustrated', word_class: 'AJ' },
    { word: 'illustration', word_class: 'N' },
    { word: 'illustrative', word_class: 'AJ' }
  ],
  condemned: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  paneled: [
    { word: 'panel', word_class: 'N' },
    { word: 'panel', word_class: 'V' },
    { word: 'paneling', word_class: 'N' },
    { word: 'panelist', word_class: 'N' },
    { word: 'paneled', word_class: 'AJ' },
    { word: 'panelling', word_class: 'N' }
  ],
  fingerprint: [
    { word: 'fingerprint', word_class: 'N' },
    { word: 'fingerprint', word_class: 'V' },
    { word: 'fingerprinting', word_class: 'N' }
  ],
  total: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  forte: [
    { word: 'fort', word_class: 'N' },
    { word: 'fort', word_class: 'V' },
    { word: 'forte', word_class: 'N' },
    { word: 'forte', word_class: 'AJ' },
    { word: 'forte', word_class: 'AV' }
  ],
  implode: [
    { word: 'implode', word_class: 'V' },
    { word: 'implosion', word_class: 'N' },
    { word: 'implosive', word_class: 'AJ' }
  ],
  garment: [
    { word: 'garment', word_class: 'N' },
    { word: 'garment', word_class: 'V' },
    { word: 'garmented', word_class: 'AJ' }
  ],
  eudemonic: [
    { word: 'eudemon', word_class: 'N' },
    { word: 'eudemonic', word_class: 'AJ' },
    { word: 'eudemonism', word_class: 'N' }
  ],
  melodiousness: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  dear: [
    { word: 'dear', word_class: 'N' },
    { word: 'dear', word_class: 'AJ' },
    { word: 'dear', word_class: 'AV' },
    { word: 'dearness', word_class: 'N' }
  ],
  believability: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  raftered: [
    { word: 'raft', word_class: 'N' },
    { word: 'raft', word_class: 'V' },
    { word: 'rafts', word_class: 'N' },
    { word: 'rafter', word_class: 'N' },
    { word: 'rafter', word_class: 'V' },
    { word: 'raftman', word_class: 'N' },
    { word: 'raftered', word_class: 'AJ' }
  ],
  incensation: [
    { word: 'incense', word_class: 'N' },
    { word: 'incense', word_class: 'V' },
    { word: 'incensed', word_class: 'AJ' },
    { word: 'incensation', word_class: 'N' },
    { word: 'incensement', word_class: 'N' }
  ],
  uncontrollably: [
    { word: 'uncontrolled', word_class: 'AJ' },
    { word: 'uncontrollable', word_class: 'AJ' },
    { word: 'uncontrollably', word_class: 'AV' }
  ],
  calliper: [
    { word: 'calliper', word_class: 'N' },
    { word: 'calliper', word_class: 'V' },
    { word: 'callipers', word_class: 'N' }
  ],
  mesmerist: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  plaintiveness: [
    { word: 'plaintive', word_class: 'AJ' },
    { word: 'plaintively', word_class: 'AV' },
    { word: 'plaintiveness', word_class: 'N' }
  ],
  obtain: [
    { word: 'obtain', word_class: 'V' },
    { word: 'obtainment', word_class: 'N' },
    { word: 'obtainable', word_class: 'AJ' }
  ],
  voidance: [
    { word: 'void', word_class: 'N' },
    { word: 'void', word_class: 'V' },
    { word: 'void', word_class: 'AJ' },
    { word: 'voiding', word_class: 'N' },
    { word: 'voidance', word_class: 'N' }
  ],
  annoy: [
    { word: 'annoy', word_class: 'V' },
    { word: 'annoyer', word_class: 'N' },
    { word: 'annoyed', word_class: 'AJ' },
    { word: 'annoying', word_class: 'N' },
    { word: 'annoyance', word_class: 'N' },
    { word: 'annoying', word_class: 'AJ' }
  ],
  officiate: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  cesarean: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  bullfight: [
    { word: 'bullfight', word_class: 'N' },
    { word: 'bullfighter', word_class: 'N' },
    { word: 'bullfighting', word_class: 'N' }
  ],
  sterile: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  buzz: [
    { word: 'buzz', word_class: 'N' },
    { word: 'buzz', word_class: 'V' },
    { word: 'buzzer', word_class: 'N' },
    { word: 'buzzing', word_class: 'AJ' }
  ],
  suggested: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  intuit: [
    { word: 'intuit', word_class: 'V' },
    { word: 'intuition', word_class: 'N' },
    { word: 'intuitive', word_class: 'AJ' },
    { word: 'intuitively', word_class: 'AV' }
  ],
  trusted: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  detribalise: [
    { word: 'detribalise', word_class: 'V' },
    { word: 'detribalize', word_class: 'V' },
    { word: 'detribalisation', word_class: 'N' },
    { word: 'detribalization', word_class: 'N' }
  ],
  paralytical: [
    { word: 'paralytic', word_class: 'N' },
    { word: 'paralytic', word_class: 'AJ' },
    { word: 'paralytical', word_class: 'AJ' }
  ],
  hybridise: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  pellucid: [
    { word: 'pellucid', word_class: 'AJ' },
    { word: 'pellucidity', word_class: 'N' },
    { word: 'pellucidness', word_class: 'N' }
  ],
  deformation: [
    { word: 'deform', word_class: 'V' },
    { word: 'deformed', word_class: 'AJ' },
    { word: 'deformity', word_class: 'N' },
    { word: 'deformation', word_class: 'N' },
    { word: 'deformational', word_class: 'AJ' }
  ],
  censoring: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  brave: [
    { word: 'brave', word_class: 'V' },
    { word: 'brave', word_class: 'N' },
    { word: 'brave', word_class: 'AJ' },
    { word: 'bravery', word_class: 'N' },
    { word: 'braving', word_class: 'N' },
    { word: 'bravely', word_class: 'AV' },
    { word: 'bravery', word_class: 'AJ' }
  ],
  supernaturally: [
    { word: 'supernatural', word_class: 'N' },
    { word: 'supernatural', word_class: 'AJ' },
    { word: 'supernaturalism', word_class: 'N' },
    { word: 'supernaturally', word_class: 'AV' },
    { word: 'supernaturalness', word_class: 'N' }
  ],
  cornered: [
    { word: 'corner', word_class: 'N' },
    { word: 'corner', word_class: 'V' },
    { word: 'cornered', word_class: 'AJ' }
  ],
  flawed: [{ word: 'flaw', word_class: 'N' }, { word: 'flaw', word_class: 'V' }, { word: 'flawed', word_class: 'AJ' }],
  deadened: [
    { word: 'deaden', word_class: 'V' },
    { word: 'deadened', word_class: 'AJ' },
    { word: 'deadening', word_class: 'N' },
    { word: 'deadening', word_class: 'AJ' }
  ],
  timorousness: [
    { word: 'timorous', word_class: 'AJ' },
    { word: 'timorously', word_class: 'AV' },
    { word: 'timorousness', word_class: 'N' }
  ],
  disorientate: [
    { word: 'disorient', word_class: 'V' },
    { word: 'disorientate', word_class: 'V' },
    { word: 'disoriented', word_class: 'AJ' },
    { word: 'disorienting', word_class: 'AJ' },
    { word: 'disorientation', word_class: 'N' }
  ],
  cannibalize: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  received: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  reductive: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  taxonomic: [
    { word: 'taxonomic', word_class: 'AJ' },
    { word: 'taxonomical', word_class: 'AJ' },
    { word: 'taxonomically', word_class: 'AV' }
  ],
  deprave: [
    { word: 'deprave', word_class: 'V' },
    { word: 'depraved', word_class: 'AJ' },
    { word: 'depravity', word_class: 'N' },
    { word: 'depravation', word_class: 'N' }
  ],
  terrorise: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  spinally: [
    { word: 'spin', word_class: 'N' },
    { word: 'spin', word_class: 'V' },
    { word: 'spinal', word_class: 'N' },
    { word: 'spinal', word_class: 'AJ' },
    { word: 'spinning', word_class: 'N' },
    { word: 'spinally', word_class: 'AV' },
    { word: 'spinning', word_class: 'AJ' }
  ],
  exploitation: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  sacking: [
    { word: 'sack', word_class: 'N' },
    { word: 'sack', word_class: 'V' },
    { word: 'sacked', word_class: 'AJ' },
    { word: 'sackful', word_class: 'N' },
    { word: 'sacking', word_class: 'N' },
    { word: 'sackful', word_class: 'AJ' }
  ],
  prop: [{ word: 'prop', word_class: 'N' }, { word: 'prop', word_class: 'V' }, { word: 'propman', word_class: 'N' }],
  praiseful: [
    { word: 'praise', word_class: 'N' },
    { word: 'praise', word_class: 'V' },
    { word: 'praising', word_class: 'AJ' },
    { word: 'praiseful', word_class: 'AJ' }
  ],
  remonstration: [
    { word: 'remonstrant', word_class: 'N' },
    { word: 'remonstrate', word_class: 'V' },
    { word: 'remonstrance', word_class: 'N' },
    { word: 'remonstrant', word_class: 'AJ' },
    { word: 'remonstration', word_class: 'N' }
  ],
  decorativeness: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  denaturalisation: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  receptor: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  despondently: [
    { word: 'despond', word_class: 'V' },
    { word: 'despondence', word_class: 'N' },
    { word: 'despondency', word_class: 'N' },
    { word: 'despondent', word_class: 'AJ' },
    { word: 'despondently', word_class: 'AV' }
  ],
  expunge: [
    { word: 'expunge', word_class: 'V' },
    { word: 'expunging', word_class: 'N' },
    { word: 'expunction', word_class: 'N' }
  ],
  acting: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  impassable: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  figurer: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  bevel: [
    { word: 'bevel', word_class: 'N' },
    { word: 'bevel', word_class: 'V' },
    { word: 'bevel', word_class: 'AJ' },
    { word: 'beveled', word_class: 'AJ' }
  ],
  commitment: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  conjuration: [
    { word: 'conjure', word_class: 'V' },
    { word: 'conjurer', word_class: 'N' },
    { word: 'conjuror', word_class: 'N' },
    { word: 'conjuring', word_class: 'N' },
    { word: 'conjuration', word_class: 'N' }
  ],
  apologise: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  emergency: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  buster: [
    { word: 'bust', word_class: 'N' },
    { word: 'bust', word_class: 'V' },
    { word: 'bust', word_class: 'AJ' },
    { word: 'buster', word_class: 'N' },
    { word: 'busted', word_class: 'AJ' }
  ],
  crowing: [
    { word: 'crow', word_class: 'N' },
    { word: 'crow', word_class: 'V' },
    { word: 'crowing', word_class: 'N' },
    { word: 'crowing', word_class: 'AJ' }
  ],
  waiter: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  enragement: [
    { word: 'enrage', word_class: 'V' },
    { word: 'enraged', word_class: 'AJ' },
    { word: 'enragement', word_class: 'N' }
  ],
  beggarman: [
    { word: 'beg', word_class: 'V' },
    { word: 'beggar', word_class: 'N' },
    { word: 'beggar', word_class: 'V' },
    { word: 'beggary', word_class: 'N' },
    { word: 'begging', word_class: 'N' },
    { word: 'beggary', word_class: 'AJ' },
    { word: 'beggarman', word_class: 'N' }
  ],
  bearded: [
    { word: 'beard', word_class: 'N' },
    { word: 'beard', word_class: 'V' },
    { word: 'bearded', word_class: 'AJ' }
  ],
  castor: [
    { word: 'cast', word_class: 'N' },
    { word: 'cast', word_class: 'V' },
    { word: 'cast', word_class: 'AJ' },
    { word: 'caste', word_class: 'N' },
    { word: 'caster', word_class: 'N' },
    { word: 'castor', word_class: 'N' },
    { word: 'casting', word_class: 'N' }
  ],
  studied: [
    { word: 'study', word_class: 'N' },
    { word: 'study', word_class: 'V' },
    { word: 'studied', word_class: 'AJ' },
    { word: 'studying', word_class: 'N' }
  ],
  elevation: [
    { word: 'elevate', word_class: 'V' },
    { word: 'elevator', word_class: 'N' },
    { word: 'elevated', word_class: 'AJ' },
    { word: 'elevation', word_class: 'N' }
  ],
  smoldering: [
    { word: 'smolder', word_class: 'N' },
    { word: 'smolder', word_class: 'V' },
    { word: 'smoldering', word_class: 'AJ' }
  ],
  drainage: [
    { word: 'drain', word_class: 'N' },
    { word: 'drain', word_class: 'V' },
    { word: 'drainage', word_class: 'N' },
    { word: 'drained', word_class: 'AJ' },
    { word: 'draining', word_class: 'AJ' }
  ],
  digressive: [
    { word: 'digress', word_class: 'V' },
    { word: 'digression', word_class: 'N' },
    { word: 'digressive', word_class: 'AJ' }
  ],
  esthetically: [
    { word: 'esthete', word_class: 'N' },
    { word: 'esthetic', word_class: 'N' },
    { word: 'esthetic', word_class: 'AJ' },
    { word: 'esthetics', word_class: 'N' },
    { word: 'esthetical', word_class: 'AJ' },
    { word: 'esthetically', word_class: 'AV' }
  ],
  coating: [
    { word: 'coat', word_class: 'N' },
    { word: 'coat', word_class: 'V' },
    { word: 'coatee', word_class: 'N' },
    { word: 'coated', word_class: 'AJ' },
    { word: 'coating', word_class: 'N' }
  ],
  womanize: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  humoral: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  depopulated: [
    { word: 'depopulate', word_class: 'V' },
    { word: 'depopulated', word_class: 'AJ' },
    { word: 'depopulation', word_class: 'N' }
  ],
  indubitable: [
    { word: 'indubitable', word_class: 'AJ' },
    { word: 'indubitably', word_class: 'AV' },
    { word: 'indubitability', word_class: 'N' }
  ],
  chang: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  capitation: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  polish: [
    { word: 'polish', word_class: 'N' },
    { word: 'polish', word_class: 'V' },
    { word: 'polish', word_class: 'AJ' },
    { word: 'polisher', word_class: 'N' },
    { word: 'polished', word_class: 'AJ' },
    { word: 'polishing', word_class: 'N' }
  ],
  synthetic: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  jamming: [
    { word: 'jam', word_class: 'N' },
    { word: 'jam', word_class: 'V' },
    { word: 'jammed', word_class: 'AJ' },
    { word: 'jamming', word_class: 'N' }
  ],
  ruralisation: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  lap: [
    { word: 'lap', word_class: 'N' },
    { word: 'lap', word_class: 'V' },
    { word: 'lapful', word_class: 'N' },
    { word: 'lapful', word_class: 'AJ' },
    { word: 'lapping', word_class: 'N' }
  ],
  splashed: [
    { word: 'splash', word_class: 'N' },
    { word: 'splash', word_class: 'V' },
    { word: 'splashed', word_class: 'AJ' },
    { word: 'splashing', word_class: 'N' }
  ],
  symmetric: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  thickened: [
    { word: 'thicken', word_class: 'V' },
    { word: 'thickener', word_class: 'N' },
    { word: 'thickened', word_class: 'AJ' },
    { word: 'thickening', word_class: 'N' },
    { word: 'thickening', word_class: 'AJ' }
  ],
  refereeing: [
    { word: 'referee', word_class: 'N' },
    { word: 'referee', word_class: 'V' },
    { word: 'refereeing', word_class: 'N' }
  ],
  scorch: [
    { word: 'scorch', word_class: 'N' },
    { word: 'scorch', word_class: 'V' },
    { word: 'scorcher', word_class: 'N' },
    { word: 'scorched', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AV' }
  ],
  seller: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  lyricality: [
    { word: 'lyric', word_class: 'N' },
    { word: 'lyric', word_class: 'V' },
    { word: 'lyric', word_class: 'AJ' },
    { word: 'lyrical', word_class: 'AJ' },
    { word: 'lyricism', word_class: 'N' },
    { word: 'lyricality', word_class: 'N' },
    { word: 'lyrically', word_class: 'AV' }
  ],
  allegement: [
    { word: 'allege', word_class: 'V' },
    { word: 'alleged', word_class: 'AJ' },
    { word: 'allegation', word_class: 'N' },
    { word: 'allegement', word_class: 'N' },
    { word: 'allegeable', word_class: 'AJ' }
  ],
  quibbling: [
    { word: 'quibble', word_class: 'N' },
    { word: 'quibble', word_class: 'V' },
    { word: 'quibbler', word_class: 'N' },
    { word: 'quibbling', word_class: 'AJ' }
  ],
  matriculation: [
    { word: 'matriculate', word_class: 'N' },
    { word: 'matriculate', word_class: 'V' },
    { word: 'matriculation', word_class: 'N' }
  ],
  militarization: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  overhead: [
    { word: 'overhead', word_class: 'N' },
    { word: 'overhead', word_class: 'V' },
    { word: 'overhead', word_class: 'AJ' },
    { word: 'overhead', word_class: 'AV' }
  ],
  predisposed: [
    { word: 'predispose', word_class: 'V' },
    { word: 'predisposal', word_class: 'N' },
    { word: 'predisposed', word_class: 'AJ' },
    { word: 'predisposition', word_class: 'N' }
  ],
  balkanize: [
    { word: 'balkans', word_class: 'N' },
    { word: 'balkanise', word_class: 'V' },
    { word: 'balkanize', word_class: 'V' },
    { word: 'balkanisation', word_class: 'N' },
    { word: 'balkanization', word_class: 'N' }
  ],
  conceptualize: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  libellous: [
    { word: 'libel', word_class: 'N' },
    { word: 'libel', word_class: 'V' },
    { word: 'libeler', word_class: 'N' },
    { word: 'libelous', word_class: 'AJ' },
    { word: 'libellous', word_class: 'AJ' }
  ],
  disinclination: [
    { word: 'disincline', word_class: 'V' },
    { word: 'disinclined', word_class: 'AJ' },
    { word: 'disinclination', word_class: 'N' }
  ],
  prudence: [
    { word: 'prudence', word_class: 'N' },
    { word: 'prudent', word_class: 'AJ' },
    { word: 'prudently', word_class: 'AV' }
  ],
  dependant: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  frequent: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  monetarily: [
    { word: 'monetary', word_class: 'N' },
    { word: 'monetary', word_class: 'AJ' },
    { word: 'monetarism', word_class: 'N' },
    { word: 'monetarize', word_class: 'V' },
    { word: 'monetarily', word_class: 'AJ' }
  ],
  roentgenise: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  freedom: [
    { word: 'free', word_class: 'N' },
    { word: 'free', word_class: 'V' },
    { word: 'free', word_class: 'AJ' },
    { word: 'free', word_class: 'AV' },
    { word: 'freedom', word_class: 'N' },
    { word: 'freeing', word_class: 'N' },
    { word: 'freeman', word_class: 'N' },
    { word: 'freeing', word_class: 'AJ' }
  ],
  introject: [
    { word: 'introject', word_class: 'N' },
    { word: 'introject', word_class: 'V' },
    { word: 'introjected', word_class: 'AJ' },
    { word: 'introjection', word_class: 'N' }
  ],
  antecede: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  barren: [
    { word: 'barren', word_class: 'N' },
    { word: 'barren', word_class: 'AJ' },
    { word: 'barrenness', word_class: 'N' }
  ],
  iodize: [
    { word: 'iodise', word_class: 'V' },
    { word: 'iodize', word_class: 'V' },
    { word: 'iodized', word_class: 'AJ' },
    { word: 'iodisation', word_class: 'N' },
    { word: 'iodization', word_class: 'N' }
  ],
  minder: [
    { word: 'mind', word_class: 'N' },
    { word: 'mind', word_class: 'V' },
    { word: 'minder', word_class: 'N' },
    { word: 'minded', word_class: 'AJ' },
    { word: 'mindful', word_class: 'AJ' },
    { word: 'mindfulness', word_class: 'N' }
  ],
  insaneness: [
    { word: 'insane', word_class: 'AJ' },
    { word: 'insanity', word_class: 'N' },
    { word: 'insanely', word_class: 'AV' },
    { word: 'insaneness', word_class: 'N' }
  ],
  insatiable: [
    { word: 'insatiate', word_class: 'AJ' },
    { word: 'insatiable', word_class: 'AJ' },
    { word: 'insatiably', word_class: 'AV' }
  ],
  inverse: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  attenuated: [
    { word: 'attenuate', word_class: 'V' },
    { word: 'attenuate', word_class: 'AJ' },
    { word: 'attenuated', word_class: 'AJ' },
    { word: 'attenuation', word_class: 'N' }
  ],
  diversion: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  discarded: [
    { word: 'discard', word_class: 'N' },
    { word: 'discard', word_class: 'V' },
    { word: 'discarded', word_class: 'AJ' },
    { word: 'discarding', word_class: 'N' }
  ],
  deaminize: [
    { word: 'deaminate', word_class: 'V' },
    { word: 'deaminize', word_class: 'V' },
    { word: 'deamination', word_class: 'N' },
    { word: 'deaminisation', word_class: 'N' },
    { word: 'deaminization', word_class: 'N' }
  ],
  homing: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  mystify: [
    { word: 'mystify', word_class: 'V' },
    { word: 'mystified', word_class: 'AJ' },
    { word: 'mystifying', word_class: 'AJ' },
    { word: 'mystification', word_class: 'N' }
  ],
  foreshadow: [
    { word: 'foreshadow', word_class: 'V' },
    { word: 'foreshadowing', word_class: 'N' },
    { word: 'foreshadowing', word_class: 'AJ' }
  ],
  afrikanerization: [
    { word: 'afrikanerise', word_class: 'V' },
    { word: 'afrikanerize', word_class: 'V' },
    { word: 'afrikanerisation', word_class: 'N' },
    { word: 'afrikanerization', word_class: 'N' }
  ],
  ethnical: [
    { word: 'ethnic', word_class: 'AJ' },
    { word: 'ethnical', word_class: 'AJ' },
    { word: 'ethnicity', word_class: 'N' },
    { word: 'ethnically', word_class: 'AV' }
  ],
  agnatic: [
    { word: 'agnate', word_class: 'N' },
    { word: 'agnate', word_class: 'V' },
    { word: 'agnate', word_class: 'AJ' },
    { word: 'agnatic', word_class: 'AJ' },
    { word: 'agnation', word_class: 'N' }
  ],
  specificity: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  collegial: [
    { word: 'college', word_class: 'N' },
    { word: 'collegial', word_class: 'AJ' },
    { word: 'collegiate', word_class: 'AJ' }
  ],
  fasting: [
    { word: 'fast', word_class: 'N' },
    { word: 'fast', word_class: 'V' },
    { word: 'fast', word_class: 'AJ' },
    { word: 'fast', word_class: 'AV' },
    { word: 'fasting', word_class: 'N' },
    { word: 'fastness', word_class: 'N' }
  ],
  dishonorable: [
    { word: 'dishonor', word_class: 'N' },
    { word: 'dishonor', word_class: 'V' },
    { word: 'dishonored', word_class: 'AJ' },
    { word: 'dishonorable', word_class: 'AJ' },
    { word: 'dishonorably', word_class: 'AV' },
    { word: 'dishonorableness', word_class: 'N' }
  ],
  exhaustible: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  evaluation: [
    { word: 'evaluate', word_class: 'V' },
    { word: 'evaluator', word_class: 'N' },
    { word: 'evaluable', word_class: 'AJ' },
    { word: 'evaluation', word_class: 'N' },
    { word: 'evaluative', word_class: 'AJ' }
  ],
  organized: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  alphanumerics: [
    { word: 'alphanumeric', word_class: 'AJ' },
    { word: 'alphanumerics', word_class: 'N' },
    { word: 'alphanumerical', word_class: 'AJ' }
  ],
  admitted: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  miraculous: [
    { word: 'miracle', word_class: 'N' },
    { word: 'miraculous', word_class: 'AJ' },
    { word: 'miraculously', word_class: 'AV' }
  ],
  perfectability: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  butchering: [
    { word: 'butcher', word_class: 'N' },
    { word: 'butcher', word_class: 'V' },
    { word: 'butchery', word_class: 'N' },
    { word: 'butchering', word_class: 'N' }
  ],
  ejection: [
    { word: 'eject', word_class: 'V' },
    { word: 'ejector', word_class: 'N' },
    { word: 'ejection', word_class: 'N' }
  ],
  indic: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  reinforcer: [
    { word: 'reinforce', word_class: 'V' },
    { word: 'reinforcer', word_class: 'N' },
    { word: 'reinforced', word_class: 'AJ' },
    { word: 'reinforcement', word_class: 'N' }
  ],
  defining: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  sentimentalise: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  savor: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  mown: [
    { word: 'mow', word_class: 'N' },
    { word: 'mow', word_class: 'V' },
    { word: 'mower', word_class: 'N' },
    { word: 'mown', word_class: 'AJ' }
  ],
  loyally: [
    { word: 'loyal', word_class: 'AJ' },
    { word: 'loyalty', word_class: 'N' },
    { word: 'loyally', word_class: 'AV' }
  ],
  electrify: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  edition: [
    { word: 'edit', word_class: 'V' },
    { word: 'editor', word_class: 'N' },
    { word: 'edited', word_class: 'AJ' },
    { word: 'editing', word_class: 'N' },
    { word: 'edition', word_class: 'N' }
  ],
  diet: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  imply: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  germane: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  unacceptably: [
    { word: 'unaccepted', word_class: 'AJ' },
    { word: 'unacceptable', word_class: 'AJ' },
    { word: 'unacceptably', word_class: 'AV' },
    { word: 'unacceptability', word_class: 'N' },
    { word: 'unacceptableness', word_class: 'N' }
  ],
  eventual: [
    { word: 'eventual', word_class: 'AJ' },
    { word: 'eventuate', word_class: 'V' },
    { word: 'eventuality', word_class: 'N' },
    { word: 'eventually', word_class: 'AV' },
    { word: 'eventuation', word_class: 'N' }
  ],
  chanted: [
    { word: 'chant', word_class: 'N' },
    { word: 'chant', word_class: 'V' },
    { word: 'chanter', word_class: 'N' },
    { word: 'chantry', word_class: 'N' },
    { word: 'chanted', word_class: 'AJ' },
    { word: 'chanting', word_class: 'N' }
  ],
  intolerance: [
    { word: 'intolerance', word_class: 'N' },
    { word: 'intolerant', word_class: 'AJ' },
    { word: 'intolerable', word_class: 'AJ' },
    { word: 'intolerably', word_class: 'AV' }
  ],
  muddled: [
    { word: 'muddle', word_class: 'N' },
    { word: 'muddle', word_class: 'V' },
    { word: 'muddled', word_class: 'AJ' }
  ],
  telephone: [
    { word: 'telephone', word_class: 'N' },
    { word: 'telephone', word_class: 'V' },
    { word: 'telephoner', word_class: 'N' },
    { word: 'telephonic', word_class: 'AJ' },
    { word: 'telephonist', word_class: 'N' }
  ],
  overcasting: [
    { word: 'overcast', word_class: 'N' },
    { word: 'overcast', word_class: 'V' },
    { word: 'overcast', word_class: 'AJ' },
    { word: 'overcasting', word_class: 'N' }
  ],
  puzzlement: [
    { word: 'puzzle', word_class: 'N' },
    { word: 'puzzle', word_class: 'V' },
    { word: 'puzzler', word_class: 'N' },
    { word: 'puzzled', word_class: 'AJ' },
    { word: 'puzzling', word_class: 'AJ' },
    { word: 'puzzlement', word_class: 'N' }
  ],
  byzantine: [
    { word: 'byzantine', word_class: 'N' },
    { word: 'byzantine', word_class: 'AJ' },
    { word: 'byzantinism', word_class: 'N' }
  ],
  transformer: [
    { word: 'transform', word_class: 'N' },
    { word: 'transform', word_class: 'V' },
    { word: 'transformer', word_class: 'N' },
    { word: 'transformed', word_class: 'AJ' },
    { word: 'transformable', word_class: 'AJ' },
    { word: 'transformation', word_class: 'N' }
  ],
  specializer: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  christianise: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  municipally: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  secularisation: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  smoky: [
    { word: 'smoke', word_class: 'N' },
    { word: 'smoke', word_class: 'V' },
    { word: 'smoker', word_class: 'N' },
    { word: 'smoky', word_class: 'AJ' },
    { word: 'smoked', word_class: 'AJ' },
    { word: 'smoking', word_class: 'N' },
    { word: 'smoking', word_class: 'AJ' }
  ],
  raising: [
    { word: 'raise', word_class: 'N' },
    { word: 'raise', word_class: 'V' },
    { word: 'raiser', word_class: 'N' },
    { word: 'raised', word_class: 'AJ' },
    { word: 'raising', word_class: 'N' },
    { word: 'raising', word_class: 'AJ' }
  ],
  automation: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  mutinous: [
    { word: 'mutiny', word_class: 'N' },
    { word: 'mutiny', word_class: 'V' },
    { word: 'mutineer', word_class: 'N' },
    { word: 'mutinous', word_class: 'AJ' }
  ],
  marching: [
    { word: 'march', word_class: 'N' },
    { word: 'march', word_class: 'V' },
    { word: 'marche', word_class: 'N' },
    { word: 'marcher', word_class: 'N' },
    { word: 'marches', word_class: 'N' },
    { word: 'marching', word_class: 'N' },
    { word: 'marching', word_class: 'AJ' }
  ],
  comparable: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  vowelization: [
    { word: 'vowel', word_class: 'N' },
    { word: 'vowelise', word_class: 'V' },
    { word: 'vowelize', word_class: 'V' },
    { word: 'vowelisation', word_class: 'N' },
    { word: 'vowelization', word_class: 'N' }
  ],
  archetypic: [
    { word: 'archetype', word_class: 'N' },
    { word: 'archetypal', word_class: 'AJ' },
    { word: 'archetypic', word_class: 'AJ' },
    { word: 'archetypical', word_class: 'AJ' }
  ],
  transfix: [
    { word: 'transfix', word_class: 'N' },
    { word: 'transfix', word_class: 'V' },
    { word: 'transfixed', word_class: 'AJ' },
    { word: 'transfixion', word_class: 'N' }
  ],
  constituent: [
    { word: 'constituent', word_class: 'N' },
    { word: 'constituency', word_class: 'N' },
    { word: 'constituent', word_class: 'AJ' }
  ],
  balk: [
    { word: 'balk', word_class: 'N' },
    { word: 'balk', word_class: 'V' },
    { word: 'balked', word_class: 'AJ' },
    { word: 'balking', word_class: 'AJ' }
  ],
  ebullience: [
    { word: 'ebullience', word_class: 'N' },
    { word: 'ebullient', word_class: 'AJ' },
    { word: 'ebulliently', word_class: 'AV' }
  ],
  lord: [{ word: 'lord', word_class: 'V' }, { word: 'lord', word_class: 'N' }, { word: 'lordship', word_class: 'N' }],
  derive: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  peripherally: [
    { word: 'periphery', word_class: 'N' },
    { word: 'peripheral', word_class: 'N' },
    { word: 'peripheral', word_class: 'AJ' },
    { word: 'peripherally', word_class: 'AV' }
  ],
  mechanically: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  partitionist: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  digitally: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  queer: [
    { word: 'queer', word_class: 'N' },
    { word: 'queer', word_class: 'V' },
    { word: 'queer', word_class: 'AJ' },
    { word: 'queerness', word_class: 'N' }
  ],
  androgenous: [
    { word: 'androgen', word_class: 'N' },
    { word: 'androgenic', word_class: 'AJ' },
    { word: 'androgenous', word_class: 'AJ' }
  ],
  recycling: [
    { word: 'recycle', word_class: 'V' },
    { word: 'recycling', word_class: 'N' },
    { word: 'recyclable', word_class: 'AJ' }
  ],
  loader: [
    { word: 'load', word_class: 'N' },
    { word: 'load', word_class: 'V' },
    { word: 'loads', word_class: 'N' },
    { word: 'loader', word_class: 'N' },
    { word: 'loaded', word_class: 'AJ' },
    { word: 'loading', word_class: 'N' },
    { word: 'loading', word_class: 'AJ' }
  ],
  neurotic: [
    { word: 'neurotic', word_class: 'N' },
    { word: 'neurotic', word_class: 'AJ' },
    { word: 'neurotically', word_class: 'AV' }
  ],
  aromatic: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  expansivity: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  tractably: [
    { word: 'tractable', word_class: 'AJ' },
    { word: 'tractably', word_class: 'AV' },
    { word: 'tractability', word_class: 'N' },
    { word: 'tractableness', word_class: 'N' }
  ],
  nidify: [
    { word: 'nidify', word_class: 'V' },
    { word: 'nidificate', word_class: 'V' },
    { word: 'nidification', word_class: 'N' }
  ],
  peptise: [
    { word: 'peptise', word_class: 'V' },
    { word: 'peptize', word_class: 'V' },
    { word: 'peptizing', word_class: 'N' },
    { word: 'peptizing', word_class: 'AJ' },
    { word: 'peptisation', word_class: 'N' },
    { word: 'peptization', word_class: 'N' }
  ],
  pharyngeal: [
    { word: 'pharynx', word_class: 'N' },
    { word: 'pharyngeal', word_class: 'AJ' },
    { word: 'pharyngitis', word_class: 'N' }
  ],
  continuously: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  parolee: [
    { word: 'parole', word_class: 'N' },
    { word: 'parole', word_class: 'V' },
    { word: 'parolee', word_class: 'N' }
  ],
  allergy: [
    { word: 'allergy', word_class: 'N' },
    { word: 'allergic', word_class: 'AJ' },
    { word: 'allergist', word_class: 'N' }
  ],
  mortified: [
    { word: 'mortify', word_class: 'V' },
    { word: 'mortified', word_class: 'AJ' },
    { word: 'mortifying', word_class: 'AJ' },
    { word: 'mortification', word_class: 'N' }
  ],
  hue: [{ word: 'hue', word_class: 'N' }, { word: 'hue', word_class: 'V' }, { word: 'hued', word_class: 'AJ' }],
  plagiarisation: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  cultivate: [
    { word: 'cultivate', word_class: 'V' },
    { word: 'cultivator', word_class: 'N' },
    { word: 'cultivable', word_class: 'AJ' },
    { word: 'cultivated', word_class: 'AJ' },
    { word: 'cultivation', word_class: 'N' }
  ],
  commanding: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  linearize: [
    { word: 'linear', word_class: 'AJ' },
    { word: 'linearity', word_class: 'N' },
    { word: 'linearize', word_class: 'V' }
  ],
  advertizing: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  ripping: [
    { word: 'rip', word_class: 'N' },
    { word: 'rip', word_class: 'V' },
    { word: 'ripped', word_class: 'AJ' },
    { word: 'ripping', word_class: 'AJ' }
  ],
  triplication: [
    { word: 'triplicate', word_class: 'N' },
    { word: 'triplicate', word_class: 'V' },
    { word: 'triplicate', word_class: 'AJ' },
    { word: 'triplication', word_class: 'N' }
  ],
  mortgaged: [
    { word: 'mortgage', word_class: 'N' },
    { word: 'mortgage', word_class: 'V' },
    { word: 'mortgagee', word_class: 'N' },
    { word: 'mortgagor', word_class: 'N' },
    { word: 'mortgaged', word_class: 'AJ' }
  ],
  inhold: [
    { word: 'inhold', word_class: 'V' },
    { word: 'inholding', word_class: 'N' },
    { word: 'inholding', word_class: 'AJ' }
  ],
  authorise: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  irresponsibility: [
    { word: 'irresponsible', word_class: 'AJ' },
    { word: 'irresponsibly', word_class: 'AV' },
    { word: 'irresponsibility', word_class: 'N' },
    { word: 'irresponsibleness', word_class: 'N' }
  ],
  fast: [
    { word: 'fast', word_class: 'N' },
    { word: 'fast', word_class: 'V' },
    { word: 'fast', word_class: 'AJ' },
    { word: 'fast', word_class: 'AV' },
    { word: 'fasting', word_class: 'N' },
    { word: 'fastness', word_class: 'N' }
  ],
  bellied: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  sociolinguist: [
    { word: 'sociolinguist', word_class: 'N' },
    { word: 'sociolinguistic', word_class: 'AJ' },
    { word: 'sociolinguistics', word_class: 'N' },
    { word: 'sociolinguistically', word_class: 'AV' }
  ],
  barrelled: [
    { word: 'barrel', word_class: 'N' },
    { word: 'barrel', word_class: 'V' },
    { word: 'barrels', word_class: 'N' },
    { word: 'barreled', word_class: 'AJ' },
    { word: 'barrelful', word_class: 'N' },
    { word: 'barrelled', word_class: 'AJ' }
  ],
  distended: [
    { word: 'distend', word_class: 'V' },
    { word: 'distended', word_class: 'AJ' },
    { word: 'distension', word_class: 'N' },
    { word: 'distention', word_class: 'N' },
    { word: 'distensible', word_class: 'AJ' }
  ],
  imagined: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  insanity: [
    { word: 'insane', word_class: 'AJ' },
    { word: 'insanity', word_class: 'N' },
    { word: 'insanely', word_class: 'AV' },
    { word: 'insaneness', word_class: 'N' }
  ],
  unionize: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  leisurely: [
    { word: 'leisure', word_class: 'N' },
    { word: 'leisure', word_class: 'AJ' },
    { word: 'leisured', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AV' }
  ],
  unseasonable: [
    { word: 'unseasoned', word_class: 'AJ' },
    { word: 'unseasonable', word_class: 'AJ' },
    { word: 'unseasonably', word_class: 'AV' },
    { word: 'unseasonableness', word_class: 'N' }
  ],
  egotist: [
    { word: 'egotist', word_class: 'N' },
    { word: 'egotistic', word_class: 'AJ' },
    { word: 'egotistical', word_class: 'AJ' },
    { word: 'egotistically', word_class: 'AV' }
  ],
  repugnant: [
    { word: 'repugn', word_class: 'V' },
    { word: 'repugnance', word_class: 'N' },
    { word: 'repugnancy', word_class: 'N' },
    { word: 'repugnant', word_class: 'AJ' }
  ],
  strain: [
    { word: 'strain', word_class: 'N' },
    { word: 'strain', word_class: 'V' },
    { word: 'strainer', word_class: 'N' },
    { word: 'strained', word_class: 'AJ' },
    { word: 'straining', word_class: 'N' },
    { word: 'straining', word_class: 'AJ' }
  ],
  detail: [
    { word: 'detail', word_class: 'N' },
    { word: 'detail', word_class: 'V' },
    { word: 'details', word_class: 'N' },
    { word: 'detailed', word_class: 'AJ' },
    { word: 'detailing', word_class: 'N' }
  ],
  infringement: [
    { word: 'infringe', word_class: 'N' },
    { word: 'infringe', word_class: 'V' },
    { word: 'infringement', word_class: 'N' }
  ],
  liftman: [
    { word: 'lift', word_class: 'N' },
    { word: 'lift', word_class: 'V' },
    { word: 'lifter', word_class: 'N' },
    { word: 'lifted', word_class: 'AJ' },
    { word: 'liftman', word_class: 'N' }
  ],
  geodesic: [
    { word: 'geodesic', word_class: 'N' },
    { word: 'geodesic', word_class: 'AJ' },
    { word: 'geodesical', word_class: 'AJ' }
  ],
  fungicide: [
    { word: 'fungus', word_class: 'N' },
    { word: 'fungal', word_class: 'AJ' },
    { word: 'fungicide', word_class: 'N' },
    { word: 'fungicidal', word_class: 'AJ' }
  ],
  homogeneous: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  include: [
    { word: 'include', word_class: 'V' },
    { word: 'included', word_class: 'AJ' },
    { word: 'inclusion', word_class: 'N' },
    { word: 'inclusive', word_class: 'AJ' }
  ],
  intermediately: [
    { word: 'intermediate', word_class: 'N' },
    { word: 'intermediate', word_class: 'V' },
    { word: 'intermediate', word_class: 'AJ' },
    { word: 'intermediator', word_class: 'N' },
    { word: 'intermediation', word_class: 'N' },
    { word: 'intermediately', word_class: 'AV' }
  ],
  skulking: [
    { word: 'skulk', word_class: 'V' },
    { word: 'skulker', word_class: 'N' },
    { word: 'skulking', word_class: 'N' },
    { word: 'skulking', word_class: 'AJ' }
  ],
  floater: [
    { word: 'float', word_class: 'N' },
    { word: 'float', word_class: 'V' },
    { word: 'floater', word_class: 'N' },
    { word: 'floating', word_class: 'AJ' },
    { word: 'flotation', word_class: 'N' },
    { word: 'floatation', word_class: 'N' }
  ],
  remarkable: [
    { word: 'remark', word_class: 'N' },
    { word: 'remark', word_class: 'V' },
    { word: 'remarkable', word_class: 'AJ' },
    { word: 'remarkably', word_class: 'AV' }
  ],
  palmer: [
    { word: 'palm', word_class: 'N' },
    { word: 'palm', word_class: 'V' },
    { word: 'palmer', word_class: 'N' },
    { word: 'palmist', word_class: 'N' }
  ],
  excitability: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  devote: [
    { word: 'devote', word_class: 'V' },
    { word: 'devoted', word_class: 'AJ' },
    { word: 'devotion', word_class: 'N' },
    { word: 'devotement', word_class: 'N' },
    { word: 'devotional', word_class: 'AJ' }
  ],
  lack: [{ word: 'lack', word_class: 'N' }, { word: 'lack', word_class: 'V' }, { word: 'lacking', word_class: 'AJ' }],
  hydraulics: [
    { word: 'hydraulic', word_class: 'AJ' },
    { word: 'hydraulics', word_class: 'N' },
    { word: 'hydraulically', word_class: 'AV' }
  ],
  impertinence: [
    { word: 'impertinence', word_class: 'N' },
    { word: 'impertinent', word_class: 'AJ' },
    { word: 'impertinently', word_class: 'AV' }
  ],
  anthropocentricity: [
    { word: 'anthropocentric', word_class: 'AJ' },
    { word: 'anthropocentrism', word_class: 'N' },
    { word: 'anthropocentricity', word_class: 'N' }
  ],
  terrifying: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  intrude: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  synonymization: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  esthetical: [
    { word: 'esthete', word_class: 'N' },
    { word: 'esthetic', word_class: 'N' },
    { word: 'esthetic', word_class: 'AJ' },
    { word: 'esthetics', word_class: 'N' },
    { word: 'esthetical', word_class: 'AJ' },
    { word: 'esthetically', word_class: 'AV' }
  ],
  exhausting: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  suite: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  transportable: [
    { word: 'transport', word_class: 'N' },
    { word: 'transport', word_class: 'V' },
    { word: 'transporter', word_class: 'N' },
    { word: 'transportable', word_class: 'AJ' },
    { word: 'transportation', word_class: 'N' }
  ],
  placental: [
    { word: 'placenta', word_class: 'N' },
    { word: 'placental', word_class: 'N' },
    { word: 'placental', word_class: 'AJ' },
    { word: 'placentation', word_class: 'N' }
  ],
  victorious: [
    { word: 'victory', word_class: 'N' },
    { word: 'victory', word_class: 'AJ' },
    { word: 'victorious', word_class: 'AJ' },
    { word: 'victoriously', word_class: 'AV' }
  ],
  oversimplified: [
    { word: 'oversimplify', word_class: 'V' },
    { word: 'oversimplified', word_class: 'AJ' },
    { word: 'oversimplification', word_class: 'N' }
  ],
  utilise: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  pigment: [
    { word: 'pig', word_class: 'N' },
    { word: 'pig', word_class: 'V' },
    { word: 'pigman', word_class: 'N' },
    { word: 'piggery', word_class: 'N' },
    { word: 'pigment', word_class: 'N' },
    { word: 'pigment', word_class: 'V' },
    { word: 'pigmentation', word_class: 'N' }
  ],
  prancer: [
    { word: 'prance', word_class: 'N' },
    { word: 'prance', word_class: 'V' },
    { word: 'prancer', word_class: 'N' }
  ],
  talisman: [
    { word: 'talisman', word_class: 'N' },
    { word: 'talisman', word_class: 'AJ' },
    { word: 'talismanic', word_class: 'AJ' }
  ],
  radiator: [
    { word: 'radiate', word_class: 'V' },
    { word: 'radiator', word_class: 'N' },
    { word: 'radiation', word_class: 'N' },
    { word: 'radiating', word_class: 'AJ' }
  ],
  pretentiousness: [
    { word: 'pretentious', word_class: 'AJ' },
    { word: 'pretentiously', word_class: 'AV' },
    { word: 'pretentiousness', word_class: 'N' }
  ],
  betroth: [
    { word: 'betroth', word_class: 'V' },
    { word: 'betrothal', word_class: 'N' },
    { word: 'betrothed', word_class: 'N' },
    { word: 'betrothed', word_class: 'AJ' }
  ],
  showing: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  extrication: [
    { word: 'extricate', word_class: 'V' },
    { word: 'extricable', word_class: 'AJ' },
    { word: 'extricated', word_class: 'AJ' },
    { word: 'extrication', word_class: 'N' }
  ],
  renunciant: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  aspiring: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  quantify: [
    { word: 'quantify', word_class: 'V' },
    { word: 'quantifier', word_class: 'N' },
    { word: 'quantifiable', word_class: 'AJ' },
    { word: 'quantification', word_class: 'N' },
    { word: 'quantifiability', word_class: 'N' }
  ],
  dispersive: [
    { word: 'disperse', word_class: 'V' },
    { word: 'dispersal', word_class: 'N' },
    { word: 'dispersed', word_class: 'AJ' },
    { word: 'dispersion', word_class: 'N' },
    { word: 'dispersive', word_class: 'AJ' }
  ],
  metonymical: [
    { word: 'metonym', word_class: 'N' },
    { word: 'metonymic', word_class: 'AJ' },
    { word: 'metonymical', word_class: 'AJ' },
    { word: 'metonymically', word_class: 'AV' }
  ],
  grossness: [
    { word: 'gross', word_class: 'N' },
    { word: 'gross', word_class: 'V' },
    { word: 'gross', word_class: 'AJ' },
    { word: 'grossness', word_class: 'N' }
  ],
  archangelical: [
    { word: 'archangel', word_class: 'N' },
    { word: 'archangelic', word_class: 'AJ' },
    { word: 'archangelical', word_class: 'AJ' }
  ],
  malthusianism: [
    { word: 'malthusian', word_class: 'N' },
    { word: 'malthusian', word_class: 'AJ' },
    { word: 'malthusianism', word_class: 'N' }
  ],
  volatilise: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  glad: [
    { word: 'glad', word_class: 'AJ' },
    { word: 'gladness', word_class: 'N' },
    { word: 'gladfulness', word_class: 'N' }
  ],
  flushed: [
    { word: 'flush', word_class: 'N' },
    { word: 'flush', word_class: 'V' },
    { word: 'flush', word_class: 'AJ' },
    { word: 'flush', word_class: 'AV' },
    { word: 'flushed', word_class: 'AJ' }
  ],
  evangelisation: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  histrion: [
    { word: 'histrion', word_class: 'N' },
    { word: 'histrionic', word_class: 'AJ' },
    { word: 'histrionics', word_class: 'N' }
  ],
  steeplechasing: [
    { word: 'steeplechase', word_class: 'N' },
    { word: 'steeplechase', word_class: 'V' },
    { word: 'steeplechaser', word_class: 'N' },
    { word: 'steeplechasing', word_class: 'N' }
  ],
  nauseous: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  given: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  carpent: [
    { word: 'carpent', word_class: 'AJ' },
    { word: 'carpenter', word_class: 'N' },
    { word: 'carpenter', word_class: 'V' },
    { word: 'carpentry', word_class: 'N' }
  ],
  accoutre: [
    { word: 'accoutre', word_class: 'V' },
    { word: 'accoutred', word_class: 'AJ' },
    { word: 'accoutrement', word_class: 'N' }
  ],
  frivolous: [
    { word: 'frivol', word_class: 'V' },
    { word: 'frivolity', word_class: 'N' },
    { word: 'frivolous', word_class: 'AJ' },
    { word: 'frivolously', word_class: 'AV' },
    { word: 'frivolousness', word_class: 'N' }
  ],
  disadvantaged: [
    { word: 'disadvantage', word_class: 'N' },
    { word: 'disadvantage', word_class: 'V' },
    { word: 'disadvantaged', word_class: 'AJ' },
    { word: 'disadvantageous', word_class: 'AJ' },
    { word: 'disadvantageously', word_class: 'AV' }
  ],
  secluded: [
    { word: 'seclude', word_class: 'V' },
    { word: 'secluded', word_class: 'AJ' },
    { word: 'seclusion', word_class: 'N' }
  ],
  acquittal: [
    { word: 'acquit', word_class: 'V' },
    { word: 'acquittal', word_class: 'N' },
    { word: 'acquitted', word_class: 'AJ' },
    { word: 'acquittance', word_class: 'N' }
  ],
  starting: [
    { word: 'start', word_class: 'N' },
    { word: 'start', word_class: 'V' },
    { word: 'starter', word_class: 'N' },
    { word: 'starting', word_class: 'N' },
    { word: 'starting', word_class: 'AJ' }
  ],
  architectural: [
    { word: 'architecture', word_class: 'N' },
    { word: 'architectural', word_class: 'AJ' },
    { word: 'architecturally', word_class: 'AV' }
  ],
  dangerous: [
    { word: 'danger', word_class: 'N' },
    { word: 'dangerous', word_class: 'AJ' },
    { word: 'dangerously', word_class: 'AV' },
    { word: 'dangerousness', word_class: 'N' }
  ],
  curarization: [
    { word: 'curare', word_class: 'N' },
    { word: 'curarise', word_class: 'V' },
    { word: 'curarize', word_class: 'V' },
    { word: 'curarisation', word_class: 'N' },
    { word: 'curarization', word_class: 'N' }
  ],
  beast: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  unsociability: [
    { word: 'unsocial', word_class: 'AJ' },
    { word: 'unsociable', word_class: 'AJ' },
    { word: 'unsociably', word_class: 'AV' },
    { word: 'unsociability', word_class: 'N' },
    { word: 'unsociableness', word_class: 'N' }
  ],
  proved: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  neuronic: [
    { word: 'neuron', word_class: 'N' },
    { word: 'neuronal', word_class: 'AJ' },
    { word: 'neuronic', word_class: 'AJ' }
  ],
  tangible: [
    { word: 'tangible', word_class: 'AJ' },
    { word: 'tangibly', word_class: 'AV' },
    { word: 'tangibility', word_class: 'N' },
    { word: 'tangibleness', word_class: 'N' }
  ],
  erudite: [
    { word: 'erudite', word_class: 'AJ' },
    { word: 'erudition', word_class: 'N' },
    { word: 'eruditely', word_class: 'AV' },
    { word: 'eruditeness', word_class: 'N' }
  ],
  remain: [
    { word: 'remain', word_class: 'V' },
    { word: 'remain', word_class: 'AJ' },
    { word: 'remains', word_class: 'N' },
    { word: 'remainder', word_class: 'N' },
    { word: 'remanence', word_class: 'N' },
    { word: 'remainder', word_class: 'V' },
    { word: 'remaining', word_class: 'AJ' }
  ],
  smash: [
    { word: 'smash', word_class: 'N' },
    { word: 'smash', word_class: 'V' },
    { word: 'smash', word_class: 'AV' },
    { word: 'smasher', word_class: 'N' },
    { word: 'smashed', word_class: 'AJ' },
    { word: 'smashing', word_class: 'N' },
    { word: 'smashing', word_class: 'AJ' }
  ],
  tractable: [
    { word: 'tractable', word_class: 'AJ' },
    { word: 'tractably', word_class: 'AV' },
    { word: 'tractability', word_class: 'N' },
    { word: 'tractableness', word_class: 'N' }
  ],
  biennial: [
    { word: 'biennial', word_class: 'N' },
    { word: 'biennial', word_class: 'AJ' },
    { word: 'biennially', word_class: 'AV' }
  ],
  totter: [
    { word: 'totter', word_class: 'N' },
    { word: 'totter', word_class: 'V' },
    { word: 'tottering', word_class: 'AJ' }
  ],
  vegetarianism: [
    { word: 'vegetarian', word_class: 'N' },
    { word: 'vegetarian', word_class: 'AJ' },
    { word: 'vegetarianism', word_class: 'N' }
  ],
  indicating: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  optics: [
    { word: 'optic', word_class: 'N' },
    { word: 'optic', word_class: 'AJ' },
    { word: 'optics', word_class: 'N' },
    { word: 'optical', word_class: 'AJ' },
    { word: 'optically', word_class: 'AV' }
  ],
  abominator: [
    { word: 'abominate', word_class: 'V' },
    { word: 'abominator', word_class: 'N' },
    { word: 'abominable', word_class: 'AJ' },
    { word: 'abominably', word_class: 'AV' },
    { word: 'abomination', word_class: 'N' }
  ],
  numerous: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  degradation: [
    { word: 'degrade', word_class: 'N' },
    { word: 'degrade', word_class: 'V' },
    { word: 'degraded', word_class: 'AJ' },
    { word: 'degrading', word_class: 'AJ' },
    { word: 'degradation', word_class: 'N' }
  ],
  thimble: [
    { word: 'thimble', word_class: 'N' },
    { word: 'thimbleful', word_class: 'N' },
    { word: 'thimbleful', word_class: 'AJ' }
  ],
  evilness: [
    { word: 'evil', word_class: 'N' },
    { word: 'evil', word_class: 'AJ' },
    { word: 'evilness', word_class: 'N' }
  ],
  computer: [
    { word: 'compute', word_class: 'V' },
    { word: 'computer', word_class: 'N' },
    { word: 'computable', word_class: 'AJ' },
    { word: 'computation', word_class: 'N' },
    { word: 'computational', word_class: 'AJ' }
  ],
  abuse: [
    { word: 'abuse', word_class: 'N' },
    { word: 'abuse', word_class: 'V' },
    { word: 'abuser', word_class: 'N' },
    { word: 'abused', word_class: 'AJ' },
    { word: 'abusive', word_class: 'AJ' },
    { word: 'abusively', word_class: 'AV' }
  ],
  journalese: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  importunacy: [
    { word: 'importune', word_class: 'V' },
    { word: 'importunacy', word_class: 'N' },
    { word: 'importunity', word_class: 'N' },
    { word: 'importunate', word_class: 'AJ' },
    { word: 'importunately', word_class: 'AV' }
  ],
  cannibalise: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  prominence: [
    { word: 'prominence', word_class: 'N' },
    { word: 'prominent', word_class: 'AJ' },
    { word: 'prominently', word_class: 'AV' }
  ],
  enlistment: [
    { word: 'enlist', word_class: 'V' },
    { word: 'enlistee', word_class: 'N' },
    { word: 'enlisted', word_class: 'AJ' },
    { word: 'enlisting', word_class: 'N' },
    { word: 'enlistment', word_class: 'N' }
  ],
  blushing: [
    { word: 'blush', word_class: 'N' },
    { word: 'blush', word_class: 'V' },
    { word: 'blusher', word_class: 'N' },
    { word: 'blushful', word_class: 'AJ' },
    { word: 'blushing', word_class: 'AJ' }
  ],
  tranquilize: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  discriminately: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  advisable: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  tinkle: [
    { word: 'tinkle', word_class: 'N' },
    { word: 'tinkle', word_class: 'V' },
    { word: 'tinkling', word_class: 'AJ' }
  ],
  satirical: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  fatty: [
    { word: 'fatty', word_class: 'N' },
    { word: 'fatty', word_class: 'AJ' },
    { word: 'fattiness', word_class: 'N' }
  ],
  tend: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  constitutionally: [
    { word: 'constitutionalism', word_class: 'N' },
    { word: 'constitutionalize', word_class: 'V' },
    { word: 'constitutionally', word_class: 'AV' }
  ],
  unliveable: [
    { word: 'unlive', word_class: 'V' },
    { word: 'unlivable', word_class: 'AJ' },
    { word: 'unliveable', word_class: 'AJ' }
  ],
  collusive: [
    { word: 'collude', word_class: 'V' },
    { word: 'collusion', word_class: 'N' },
    { word: 'collusive', word_class: 'AJ' }
  ],
  trite: [
    { word: 'trite', word_class: 'AJ' },
    { word: 'tritely', word_class: 'AV' },
    { word: 'triteness', word_class: 'N' }
  ],
  homely: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  tighten: [
    { word: 'tighten', word_class: 'V' },
    { word: 'tightened', word_class: 'AJ' },
    { word: 'tightening', word_class: 'N' }
  ],
  traitor: [
    { word: 'traitor', word_class: 'N' },
    { word: 'traitorous', word_class: 'AJ' },
    { word: 'traitorously', word_class: 'AV' },
    { word: 'traitorousness', word_class: 'N' }
  ],
  unscrupulously: [
    { word: 'unscrupulous', word_class: 'AJ' },
    { word: 'unscrupulously', word_class: 'AV' },
    { word: 'unscrupulousness', word_class: 'N' }
  ],
  doctor: [
    { word: 'doctor', word_class: 'N' },
    { word: 'doctor', word_class: 'V' },
    { word: 'doctoral', word_class: 'AJ' },
    { word: 'doctorate', word_class: 'N' }
  ],
  pretended: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  benignant: [
    { word: 'benign', word_class: 'AJ' },
    { word: 'benignity', word_class: 'N' },
    { word: 'benignancy', word_class: 'N' },
    { word: 'benignant', word_class: 'AJ' }
  ],
  unimpressively: [
    { word: 'unimpressed', word_class: 'AJ' },
    { word: 'unimpressive', word_class: 'AJ' },
    { word: 'unimpressively', word_class: 'AV' }
  ],
  climate: [
    { word: 'climate', word_class: 'N' },
    { word: 'climatic', word_class: 'AJ' },
    { word: 'climatical', word_class: 'AJ' },
    { word: 'climatically', word_class: 'AV' }
  ],
  lineman: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  luxuriation: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  putrid: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  memorialise: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  traveled: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  ritualization: [
    { word: 'ritual', word_class: 'N' },
    { word: 'ritual', word_class: 'AJ' },
    { word: 'ritualism', word_class: 'N' },
    { word: 'ritualize', word_class: 'V' },
    { word: 'ritually', word_class: 'AV' },
    { word: 'ritualization', word_class: 'N' }
  ],
  subversiveness: [
    { word: 'subvert', word_class: 'V' },
    { word: 'subverter', word_class: 'N' },
    { word: 'subversion', word_class: 'N' },
    { word: 'subversive', word_class: 'N' },
    { word: 'subversive', word_class: 'AJ' },
    { word: 'subversiveness', word_class: 'N' }
  ],
  foiling: [
    { word: 'foil', word_class: 'N' },
    { word: 'foil', word_class: 'V' },
    { word: 'foiled', word_class: 'AJ' },
    { word: 'foiling', word_class: 'N' }
  ],
  winding: [
    { word: 'wind', word_class: 'N' },
    { word: 'wind', word_class: 'V' },
    { word: 'winder', word_class: 'N' },
    { word: 'winded', word_class: 'AJ' },
    { word: 'winding', word_class: 'N' },
    { word: 'winding', word_class: 'AJ' }
  ],
  presumably: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  absorbent: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  rubrication: [
    { word: 'rubric', word_class: 'N' },
    { word: 'rubric', word_class: 'V' },
    { word: 'rubricate', word_class: 'V' },
    { word: 'rubrication', word_class: 'N' }
  ],
  various: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  concaveness: [
    { word: 'concave', word_class: 'AJ' },
    { word: 'concavity', word_class: 'N' },
    { word: 'concavely', word_class: 'AV' },
    { word: 'concaveness', word_class: 'N' }
  ],
  diamagnet: [
    { word: 'diamagnet', word_class: 'N' },
    { word: 'diamagnetic', word_class: 'AJ' },
    { word: 'diamagnetism', word_class: 'N' }
  ],
  phenotype: [
    { word: 'phenotype', word_class: 'N' },
    { word: 'phenotypic', word_class: 'AJ' },
    { word: 'phenotypical', word_class: 'AJ' }
  ],
  enslave: [
    { word: 'enslave', word_class: 'V' },
    { word: 'enslaved', word_class: 'AJ' },
    { word: 'enslaving', word_class: 'N' },
    { word: 'enslavement', word_class: 'N' }
  ],
  thwarted: [
    { word: 'thwart', word_class: 'N' },
    { word: 'thwart', word_class: 'V' },
    { word: 'thwarted', word_class: 'AJ' },
    { word: 'thwarting', word_class: 'N' },
    { word: 'thwarting', word_class: 'AJ' }
  ],
  politeness: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  prophet: [
    { word: 'prophet', word_class: 'N' },
    { word: 'prophets', word_class: 'N' },
    { word: 'prophetic', word_class: 'AJ' },
    { word: 'prophetical', word_class: 'AJ' },
    { word: 'prophetically', word_class: 'AV' }
  ],
  nidificate: [
    { word: 'nidify', word_class: 'V' },
    { word: 'nidificate', word_class: 'V' },
    { word: 'nidification', word_class: 'N' }
  ],
  inclosure: [
    { word: 'inclose', word_class: 'V' },
    { word: 'inclose', word_class: 'AJ' },
    { word: 'inclosure', word_class: 'N' }
  ],
  floating: [
    { word: 'float', word_class: 'N' },
    { word: 'float', word_class: 'V' },
    { word: 'floater', word_class: 'N' },
    { word: 'floating', word_class: 'AJ' },
    { word: 'flotation', word_class: 'N' },
    { word: 'floatation', word_class: 'N' }
  ],
  prowler: [
    { word: 'prowl', word_class: 'N' },
    { word: 'prowl', word_class: 'V' },
    { word: 'prowler', word_class: 'N' }
  ],
  ambivalence: [
    { word: 'ambivalence', word_class: 'N' },
    { word: 'ambivalency', word_class: 'N' },
    { word: 'ambivalent', word_class: 'AJ' }
  ],
  generousness: [
    { word: 'generous', word_class: 'AJ' },
    { word: 'generosity', word_class: 'N' },
    { word: 'generously', word_class: 'AV' },
    { word: 'generousness', word_class: 'N' }
  ],
  symmetrization: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  criticise: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  sulphate: [
    { word: 'sulphate', word_class: 'N' },
    { word: 'sulphate', word_class: 'V' },
    { word: 'sulphation', word_class: 'N' }
  ],
  canulate: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  nullification: [
    { word: 'nullify', word_class: 'V' },
    { word: 'nullified', word_class: 'AJ' },
    { word: 'nullification', word_class: 'N' }
  ],
  chelicerous: [
    { word: 'cheliceral', word_class: 'AJ' },
    { word: 'chelicerate', word_class: 'AJ' },
    { word: 'chelicerous', word_class: 'AJ' }
  ],
  desirous: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  antipodes: [
    { word: 'antipode', word_class: 'N' },
    { word: 'antipodal', word_class: 'N' },
    { word: 'antipodes', word_class: 'N' },
    { word: 'antipodal', word_class: 'AJ' }
  ],
  hallucinate: [
    { word: 'hallucinate', word_class: 'V' },
    { word: 'hallucination', word_class: 'N' },
    { word: 'hallucinating', word_class: 'AJ' }
  ],
  matting: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  behaviorism: [
    { word: 'behave', word_class: 'V' },
    { word: 'behavior', word_class: 'N' },
    { word: 'behavioral', word_class: 'AJ' },
    { word: 'behaviorism', word_class: 'N' }
  ],
  rabbinate: [
    { word: 'rabbi', word_class: 'N' },
    { word: 'rabbinate', word_class: 'N' },
    { word: 'rabbinic', word_class: 'AJ' },
    { word: 'rabbinical', word_class: 'AJ' }
  ],
  ratified: [
    { word: 'ratify', word_class: 'V' },
    { word: 'ratifier', word_class: 'N' },
    { word: 'ratified', word_class: 'AJ' },
    { word: 'ratification', word_class: 'N' }
  ],
  communicator: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  committed: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  teetotalism: [
    { word: 'teetotal', word_class: 'AJ' },
    { word: 'teetotaling', word_class: 'N' },
    { word: 'teetotalism', word_class: 'N' }
  ],
  parallelize: [
    { word: 'parallel', word_class: 'N' },
    { word: 'parallel', word_class: 'V' },
    { word: 'parallel', word_class: 'AJ' },
    { word: 'parallelism', word_class: 'N' },
    { word: 'parallelize', word_class: 'V' }
  ],
  quilting: [
    { word: 'quilt', word_class: 'N' },
    { word: 'quilt', word_class: 'V' },
    { word: 'quilted', word_class: 'AJ' },
    { word: 'quilting', word_class: 'N' }
  ],
  disputatious: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  renovation: [
    { word: 'renovate', word_class: 'V' },
    { word: 'renovator', word_class: 'N' },
    { word: 'renovated', word_class: 'AJ' },
    { word: 'renovation', word_class: 'N' }
  ],
  exploited: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  skate: [
    { word: 'skate', word_class: 'N' },
    { word: 'skate', word_class: 'V' },
    { word: 'skater', word_class: 'N' },
    { word: 'skates', word_class: 'N' },
    { word: 'skating', word_class: 'N' }
  ],
  usually: [
    { word: 'usual', word_class: 'AJ' },
    { word: 'usually', word_class: 'AV' },
    { word: 'usualness', word_class: 'N' }
  ],
  acerbity: [
    { word: 'acerb', word_class: 'AJ' },
    { word: 'acerbate', word_class: 'V' },
    { word: 'acerbic', word_class: 'AJ' },
    { word: 'acerbity', word_class: 'N' },
    { word: 'acerbation', word_class: 'N' }
  ],
  solarise: [
    { word: 'solar', word_class: 'AJ' },
    { word: 'solarise', word_class: 'V' },
    { word: 'solarize', word_class: 'V' },
    { word: 'solarisation', word_class: 'N' },
    { word: 'solarization', word_class: 'N' }
  ],
  puffer: [
    { word: 'puff', word_class: 'N' },
    { word: 'puff', word_class: 'V' },
    { word: 'puff', word_class: 'AJ' },
    { word: 'puffer', word_class: 'N' }
  ],
  strange: [
    { word: 'strange', word_class: 'AJ' },
    { word: 'strangely', word_class: 'AV' },
    { word: 'strangeness', word_class: 'N' }
  ],
  shitting: [
    { word: 'shit', word_class: 'N' },
    { word: 'shit', word_class: 'V' },
    { word: 'shitting', word_class: 'N' }
  ],
  monopolizer: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  polished: [
    { word: 'polish', word_class: 'N' },
    { word: 'polish', word_class: 'V' },
    { word: 'polish', word_class: 'AJ' },
    { word: 'polisher', word_class: 'N' },
    { word: 'polished', word_class: 'AJ' },
    { word: 'polishing', word_class: 'N' }
  ],
  mouthed: [
    { word: 'mouth', word_class: 'N' },
    { word: 'mouth', word_class: 'V' },
    { word: 'mouthed', word_class: 'AJ' },
    { word: 'mouthful', word_class: 'N' },
    { word: 'mouthful', word_class: 'AJ' }
  ],
  drawers: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  flagship: [
    { word: 'flag', word_class: 'N' },
    { word: 'flag', word_class: 'V' },
    { word: 'flagship', word_class: 'N' },
    { word: 'flagging', word_class: 'AJ' }
  ],
  fumbling: [
    { word: 'fumble', word_class: 'N' },
    { word: 'fumble', word_class: 'V' },
    { word: 'fumbler', word_class: 'N' },
    { word: 'fumbling', word_class: 'AJ' }
  ],
  monarchic: [
    { word: 'monarch', word_class: 'N' },
    { word: 'monarchal', word_class: 'AJ' },
    { word: 'monarchic', word_class: 'AJ' },
    { word: 'monarchism', word_class: 'N' },
    { word: 'monarchical', word_class: 'AJ' }
  ],
  interchangeability: [
    { word: 'interchange', word_class: 'N' },
    { word: 'interchange', word_class: 'V' },
    { word: 'interchangeable', word_class: 'AJ' },
    { word: 'interchangeably', word_class: 'AV' },
    { word: 'interchangeability', word_class: 'N' },
    { word: 'interchangeableness', word_class: 'N' }
  ],
  idol: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  brahmin: [
    { word: 'brahmin', word_class: 'N' },
    { word: 'brahminic', word_class: 'AJ' },
    { word: 'brahminism', word_class: 'N' },
    { word: 'brahminical', word_class: 'AJ' }
  ],
  claim: [
    { word: 'claim', word_class: 'N' },
    { word: 'claim', word_class: 'V' },
    { word: 'claimant', word_class: 'N' }
  ],
  lacrimal: [
    { word: 'lacrimal', word_class: 'AJ' },
    { word: 'lacrimator', word_class: 'N' },
    { word: 'lacrimation', word_class: 'N' }
  ],
  convexity: [
    { word: 'convex', word_class: 'AJ' },
    { word: 'convexity', word_class: 'N' },
    { word: 'convexness', word_class: 'N' }
  ],
  vanishing: [
    { word: 'vanish', word_class: 'V' },
    { word: 'vanish', word_class: 'AJ' },
    { word: 'vanished', word_class: 'AJ' },
    { word: 'vanishing', word_class: 'N' },
    { word: 'vanishing', word_class: 'AJ' },
    { word: 'vanishment', word_class: 'N' }
  ],
  comparably: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  calibration: [
    { word: 'caliber', word_class: 'N' },
    { word: 'calibre', word_class: 'N' },
    { word: 'calibrate', word_class: 'V' },
    { word: 'calibration', word_class: 'N' }
  ],
  quench: [
    { word: 'quench', word_class: 'V' },
    { word: 'quenched', word_class: 'AJ' },
    { word: 'quenching', word_class: 'N' }
  ],
  command: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  disapproval: [
    { word: 'disapprove', word_class: 'V' },
    { word: 'disapproval', word_class: 'N' },
    { word: 'disapproved', word_class: 'AJ' },
    { word: 'disapproving', word_class: 'AJ' }
  ],
  instigator: [
    { word: 'instigant', word_class: 'N' },
    { word: 'instigate', word_class: 'V' },
    { word: 'instigator', word_class: 'N' },
    { word: 'instigation', word_class: 'N' },
    { word: 'instigative', word_class: 'AJ' }
  ],
  ache: [
    { word: 'ache', word_class: 'N' },
    { word: 'ache', word_class: 'V' },
    { word: 'aching', word_class: 'N' },
    { word: 'aching', word_class: 'AJ' }
  ],
  exhibitionist: [
    { word: 'exhibitionist', word_class: 'N' },
    { word: 'exhibitionist', word_class: 'AJ' },
    { word: 'exhibitionistic', word_class: 'AJ' }
  ],
  lengthening: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  convener: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  twisting: [
    { word: 'twist', word_class: 'N' },
    { word: 'twist', word_class: 'V' },
    { word: 'twister', word_class: 'N' },
    { word: 'twisted', word_class: 'AJ' },
    { word: 'twisting', word_class: 'N' },
    { word: 'twisting', word_class: 'AJ' }
  ],
  communication: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  complementation: [
    { word: 'complement', word_class: 'N' },
    { word: 'complement', word_class: 'V' },
    { word: 'complemental', word_class: 'AJ' },
    { word: 'complementary', word_class: 'AJ' },
    { word: 'complementation', word_class: 'N' }
  ],
  registration: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  lance: [
    { word: 'lance', word_class: 'N' },
    { word: 'lance', word_class: 'V' },
    { word: 'lancer', word_class: 'N' },
    { word: 'lancers', word_class: 'N' }
  ],
  supplying: [
    { word: 'supply', word_class: 'N' },
    { word: 'supply', word_class: 'V' },
    { word: 'supplier', word_class: 'N' },
    { word: 'supplies', word_class: 'N' },
    { word: 'suppliant', word_class: 'N' },
    { word: 'supplying', word_class: 'N' },
    { word: 'suppliant', word_class: 'AJ' }
  ],
  defaulter: [
    { word: 'default', word_class: 'N' },
    { word: 'default', word_class: 'V' },
    { word: 'default', word_class: 'AJ' },
    { word: 'defaulter', word_class: 'N' }
  ],
  profitability: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  crediting: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  misconduct: [
    { word: 'misconduct', word_class: 'N' },
    { word: 'misconduct', word_class: 'V' },
    { word: 'misconduction', word_class: 'N' }
  ],
  mirthful: [
    { word: 'mirth', word_class: 'N' },
    { word: 'mirthful', word_class: 'AJ' },
    { word: 'mirthfulness', word_class: 'N' }
  ],
  hill: [{ word: 'hill', word_class: 'N' }, { word: 'hill', word_class: 'V' }, { word: 'hills', word_class: 'N' }],
  feminization: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  bothersome: [
    { word: 'bother', word_class: 'N' },
    { word: 'bother', word_class: 'V' },
    { word: 'bothered', word_class: 'AJ' },
    { word: 'botheration', word_class: 'N' },
    { word: 'bothersome', word_class: 'AJ' }
  ],
  wanness: [
    { word: 'wan', word_class: 'N' },
    { word: 'wan', word_class: 'V' },
    { word: 'wane', word_class: 'N' },
    { word: 'wane', word_class: 'V' },
    { word: 'wan', word_class: 'AJ' },
    { word: 'waning', word_class: 'N' },
    { word: 'waning', word_class: 'AJ' },
    { word: 'wanness', word_class: 'N' }
  ],
  impulsive: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  benni: [{ word: 'benni', word_class: 'N' }, { word: 'benny', word_class: 'N' }, { word: 'bennie', word_class: 'N' }],
  spit: [{ word: 'spit', word_class: 'N' }, { word: 'spit', word_class: 'V' }, { word: 'spitting', word_class: 'N' }],
  purvey: [
    { word: 'purvey', word_class: 'V' },
    { word: 'purveyor', word_class: 'N' },
    { word: 'purveyance', word_class: 'N' }
  ],
  congestive: [
    { word: 'congest', word_class: 'V' },
    { word: 'congested', word_class: 'AJ' },
    { word: 'congestion', word_class: 'N' },
    { word: 'congestive', word_class: 'AJ' }
  ],
  construction: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  ancestor: [
    { word: 'ancestor', word_class: 'N' },
    { word: 'ancestral', word_class: 'AJ' },
    { word: 'ancestress', word_class: 'N' }
  ],
  cabinetmaking: [
    { word: 'cabinet', word_class: 'N' },
    { word: 'cabinet', word_class: 'AJ' },
    { word: 'cabinetry', word_class: 'N' },
    { word: 'cabinetmaker', word_class: 'N' },
    { word: 'cabinetmaking', word_class: 'N' }
  ],
  augmented: [
    { word: 'augment', word_class: 'V' },
    { word: 'augmented', word_class: 'AJ' },
    { word: 'augmenting', word_class: 'AJ' },
    { word: 'augmentation', word_class: 'N' },
    { word: 'augmentative', word_class: 'AJ' }
  ],
  plasticizer: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  extemporate: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  cathartes: [
    { word: 'cathartes', word_class: 'N' },
    { word: 'cathartic', word_class: 'N' },
    { word: 'cathartic', word_class: 'AJ' }
  ],
  dare: [
    { word: 'dare', word_class: 'N' },
    { word: 'dare', word_class: 'V' },
    { word: 'daring', word_class: 'N' },
    { word: 'daring', word_class: 'AJ' }
  ],
  graduation: [
    { word: 'graduate', word_class: 'N' },
    { word: 'graduate', word_class: 'V' },
    { word: 'graduate', word_class: 'AJ' },
    { word: 'graduated', word_class: 'AJ' },
    { word: 'graduation', word_class: 'N' }
  ],
  garrulousness: [
    { word: 'garrulity', word_class: 'N' },
    { word: 'garrulous', word_class: 'AJ' },
    { word: 'garrulously', word_class: 'AV' },
    { word: 'garrulousness', word_class: 'N' }
  ],
  spastic: [
    { word: 'spastic', word_class: 'N' },
    { word: 'spastic', word_class: 'AJ' },
    { word: 'spasticity', word_class: 'N' }
  ],
  balance: [
    { word: 'balance', word_class: 'N' },
    { word: 'balance', word_class: 'V' },
    { word: 'balancer', word_class: 'N' },
    { word: 'balanced', word_class: 'AJ' },
    { word: 'balancing', word_class: 'N' }
  ],
  emulsification: [
    { word: 'emulsify', word_class: 'V' },
    { word: 'emulsifier', word_class: 'N' },
    { word: 'emulsified', word_class: 'AJ' },
    { word: 'emulsification', word_class: 'N' }
  ],
  separable: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  expanded: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  holiness: [
    { word: 'holy', word_class: 'N' },
    { word: 'holy', word_class: 'AJ' },
    { word: 'holiness', word_class: 'N' }
  ],
  premeditate: [
    { word: 'premeditate', word_class: 'V' },
    { word: 'premeditated', word_class: 'AJ' },
    { word: 'premeditation', word_class: 'N' }
  ],
  ozonisation: [
    { word: 'ozone', word_class: 'N' },
    { word: 'ozonise', word_class: 'V' },
    { word: 'ozonize', word_class: 'V' },
    { word: 'ozonisation', word_class: 'N' },
    { word: 'ozonization', word_class: 'N' }
  ],
  allergic: [
    { word: 'allergy', word_class: 'N' },
    { word: 'allergic', word_class: 'AJ' },
    { word: 'allergist', word_class: 'N' }
  ],
  valuables: [
    { word: 'valuable', word_class: 'N' },
    { word: 'valuable', word_class: 'AJ' },
    { word: 'valuables', word_class: 'N' },
    { word: 'valuableness', word_class: 'N' }
  ],
  park: [
    { word: 'park', word_class: 'N' },
    { word: 'park', word_class: 'V' },
    { word: 'parked', word_class: 'AJ' },
    { word: 'parking', word_class: 'N' }
  ],
  forkful: [
    { word: 'fork', word_class: 'N' },
    { word: 'fork', word_class: 'V' },
    { word: 'forked', word_class: 'AJ' },
    { word: 'forkful', word_class: 'N' },
    { word: 'forking', word_class: 'N' },
    { word: 'forkful', word_class: 'AJ' }
  ],
  deoxidization: [
    { word: 'deoxidise', word_class: 'V' },
    { word: 'deoxidize', word_class: 'V' },
    { word: 'deoxidisation', word_class: 'N' },
    { word: 'deoxidization', word_class: 'N' }
  ],
  thresh: [
    { word: 'thresh', word_class: 'V' },
    { word: 'thresher', word_class: 'N' },
    { word: 'threshing', word_class: 'N' }
  ],
  boding: [
    { word: 'bode', word_class: 'V' },
    { word: 'boding', word_class: 'N' },
    { word: 'bodement', word_class: 'N' }
  ],
  insensitivity: [
    { word: 'insensitive', word_class: 'AJ' },
    { word: 'insensitivity', word_class: 'N' },
    { word: 'insensitively', word_class: 'AV' },
    { word: 'insensitiveness', word_class: 'N' }
  ],
  located: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  officiation: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  grimness: [
    { word: 'grime', word_class: 'N' },
    { word: 'grim', word_class: 'AJ' },
    { word: 'grime', word_class: 'V' },
    { word: 'grimy', word_class: 'AJ' },
    { word: 'grimness', word_class: 'N' },
    { word: 'griminess', word_class: 'N' }
  ],
  retract: [
    { word: 'retract', word_class: 'N' },
    { word: 'retract', word_class: 'V' },
    { word: 'retractor', word_class: 'N' },
    { word: 'retracted', word_class: 'AJ' },
    { word: 'retraction', word_class: 'N' },
    { word: 'retractable', word_class: 'AJ' }
  ],
  fulmination: [
    { word: 'fulminate', word_class: 'N' },
    { word: 'fulminate', word_class: 'V' },
    { word: 'fulmination', word_class: 'N' }
  ],
  invested: [
    { word: 'invest', word_class: 'N' },
    { word: 'invest', word_class: 'V' },
    { word: 'investor', word_class: 'N' },
    { word: 'invested', word_class: 'AJ' },
    { word: 'investing', word_class: 'N' },
    { word: 'investment', word_class: 'N' }
  ],
  breakableness: [
    { word: 'breakable', word_class: 'N' },
    { word: 'breakable', word_class: 'AJ' },
    { word: 'breakability', word_class: 'N' },
    { word: 'breakableness', word_class: 'N' }
  ],
  egalitarian: [
    { word: 'egalitarian', word_class: 'N' },
    { word: 'egalitarian', word_class: 'AJ' },
    { word: 'egalitarianism', word_class: 'N' }
  ],
  odoriferous: [
    { word: 'odor', word_class: 'N' },
    { word: 'odorize', word_class: 'V' },
    { word: 'odorous', word_class: 'AJ' },
    { word: 'odoriferous', word_class: 'AJ' }
  ],
  preaching: [
    { word: 'preach', word_class: 'V' },
    { word: 'preacher', word_class: 'N' },
    { word: 'preachy', word_class: 'AJ' },
    { word: 'preaching', word_class: 'N' },
    { word: 'preachment', word_class: 'N' }
  ],
  clutches: [
    { word: 'clutch', word_class: 'N' },
    { word: 'clutch', word_class: 'V' },
    { word: 'clutches', word_class: 'N' }
  ],
  lavation: [
    { word: 'lave', word_class: 'V' },
    { word: 'laver', word_class: 'N' },
    { word: 'lavation', word_class: 'N' }
  ],
  esteemed: [
    { word: 'esteem', word_class: 'N' },
    { word: 'esteem', word_class: 'V' },
    { word: 'esteemed', word_class: 'AJ' }
  ],
  wet: [
    { word: 'wet', word_class: 'N' },
    { word: 'wet', word_class: 'V' },
    { word: 'wet', word_class: 'AJ' },
    { word: 'wetness', word_class: 'N' },
    { word: 'wetting', word_class: 'N' }
  ],
  ventilate: [
    { word: 'ventilate', word_class: 'V' },
    { word: 'ventilator', word_class: 'N' },
    { word: 'ventilated', word_class: 'AJ' },
    { word: 'ventilation', word_class: 'N' }
  ],
  transfusion: [
    { word: 'transfuse', word_class: 'N' },
    { word: 'transfuse', word_class: 'V' },
    { word: 'transfusion', word_class: 'N' }
  ],
  stubble: [
    { word: 'stubble', word_class: 'N' },
    { word: 'stubbly', word_class: 'AJ' },
    { word: 'stubbled', word_class: 'AJ' }
  ],
  fertilize: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  linear: [
    { word: 'linear', word_class: 'AJ' },
    { word: 'linearity', word_class: 'N' },
    { word: 'linearize', word_class: 'V' }
  ],
  lots: [
    { word: 'lot', word_class: 'N' },
    { word: 'lot', word_class: 'V' },
    { word: 'lot', word_class: 'AJ' },
    { word: 'lots', word_class: 'N' },
    { word: 'lots', word_class: 'AJ' }
  ],
  glycerol: [
    { word: 'glycerol', word_class: 'N' },
    { word: 'glycerole', word_class: 'N' },
    { word: 'glycerolize', word_class: 'V' }
  ],
  permeated: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  favoritism: [
    { word: 'favorite', word_class: 'N' },
    { word: 'favorite', word_class: 'AJ' },
    { word: 'favoritism', word_class: 'N' }
  ],
  baptismal: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  schema: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  teaspoonful: [
    { word: 'teaspoon', word_class: 'N' },
    { word: 'teaspoonful', word_class: 'N' },
    { word: 'teaspoonful', word_class: 'AJ' }
  ],
  astrophysical: [
    { word: 'astrophysics', word_class: 'N' },
    { word: 'astrophysical', word_class: 'AJ' },
    { word: 'astrophysicist', word_class: 'N' }
  ],
  zippered: [
    { word: 'zipper', word_class: 'N' },
    { word: 'zipper', word_class: 'V' },
    { word: 'zippered', word_class: 'AJ' }
  ],
  moderated: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  tranquility: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  hackle: [
    { word: 'hackle', word_class: 'N' },
    { word: 'hackle', word_class: 'V' },
    { word: 'hackles', word_class: 'N' }
  ],
  emulate: [
    { word: 'emulate', word_class: 'V' },
    { word: 'emulous', word_class: 'AJ' },
    { word: 'emulation', word_class: 'N' },
    { word: 'emulously', word_class: 'AV' }
  ],
  meretriciously: [
    { word: 'meretricious', word_class: 'AJ' },
    { word: 'meretriciously', word_class: 'AV' },
    { word: 'meretriciousness', word_class: 'N' }
  ],
  application: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  encircle: [
    { word: 'encircle', word_class: 'V' },
    { word: 'encircled', word_class: 'AJ' },
    { word: 'encircling', word_class: 'AJ' },
    { word: 'encirclement', word_class: 'N' }
  ],
  groveling: [
    { word: 'grovel', word_class: 'V' },
    { word: 'groveller', word_class: 'N' },
    { word: 'groveling', word_class: 'AJ' },
    { word: 'grovelling', word_class: 'AJ' }
  ],
  atomizer: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  swamp: [
    { word: 'swamp', word_class: 'N' },
    { word: 'swamp', word_class: 'V' },
    { word: 'swamped', word_class: 'AJ' }
  ],
  impassioned: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  adequately: [
    { word: 'adequacy', word_class: 'N' },
    { word: 'adequate', word_class: 'AJ' },
    { word: 'adequately', word_class: 'AV' },
    { word: 'adequateness', word_class: 'N' }
  ],
  malodorous: [
    { word: 'malodor', word_class: 'N' },
    { word: 'malodorous', word_class: 'AJ' },
    { word: 'malodorousness', word_class: 'N' }
  ],
  precede: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  accredited: [
    { word: 'accredit', word_class: 'V' },
    { word: 'accredited', word_class: 'AJ' },
    { word: 'accreditation', word_class: 'N' }
  ],
  sonorously: [
    { word: 'sonorant', word_class: 'N' },
    { word: 'sonority', word_class: 'N' },
    { word: 'sonorant', word_class: 'AJ' },
    { word: 'sonorous', word_class: 'AJ' },
    { word: 'sonorously', word_class: 'AV' },
    { word: 'sonorousness', word_class: 'N' }
  ],
  insistently: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  pinned: [
    { word: 'pin', word_class: 'N' },
    { word: 'pin', word_class: 'V' },
    { word: 'pinned', word_class: 'AJ' },
    { word: 'pinning', word_class: 'N' }
  ],
  uncomfortableness: [
    { word: 'uncomfortable', word_class: 'AJ' },
    { word: 'uncomfortably', word_class: 'AV' },
    { word: 'uncomfortableness', word_class: 'N' }
  ],
  bear: [
    { word: 'bear', word_class: 'N' },
    { word: 'bear', word_class: 'V' },
    { word: 'born', word_class: 'AJ' },
    { word: 'bearer', word_class: 'N' },
    { word: 'borne', word_class: 'AJ' },
    { word: 'bearing', word_class: 'N' },
    { word: 'bearing', word_class: 'AJ' }
  ],
  insatiably: [
    { word: 'insatiate', word_class: 'AJ' },
    { word: 'insatiable', word_class: 'AJ' },
    { word: 'insatiably', word_class: 'AV' }
  ],
  mismatched: [
    { word: 'mismatch', word_class: 'N' },
    { word: 'mismatch', word_class: 'V' },
    { word: 'mismatched', word_class: 'AJ' }
  ],
  jetting: [
    { word: 'jet', word_class: 'N' },
    { word: 'jet', word_class: 'V' },
    { word: 'jet', word_class: 'AJ' },
    { word: 'jets', word_class: 'N' },
    { word: 'jetting', word_class: 'AJ' }
  ],
  cumulate: [
    { word: 'cumulate', word_class: 'V' },
    { word: 'cumulation', word_class: 'N' },
    { word: 'cumulative', word_class: 'AJ' },
    { word: 'cumulatively', word_class: 'AV' }
  ],
  recital: [
    { word: 'recite', word_class: 'V' },
    { word: 'recital', word_class: 'N' },
    { word: 'recitation', word_class: 'N' },
    { word: 'recitative', word_class: 'N' }
  ],
  hypostasisation: [
    { word: 'hypostasize', word_class: 'V' },
    { word: 'hypostasisation', word_class: 'N' },
    { word: 'hypostasization', word_class: 'N' }
  ],
  federation: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  stager: [
    { word: 'stage', word_class: 'N' },
    { word: 'stage', word_class: 'V' },
    { word: 'stager', word_class: 'N' },
    { word: 'staged', word_class: 'AJ' },
    { word: 'staging', word_class: 'N' }
  ],
  marginate: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  fiddle: [
    { word: 'fiddle', word_class: 'N' },
    { word: 'fiddle', word_class: 'V' },
    { word: 'fiddler', word_class: 'N' },
    { word: 'fiddling', word_class: 'AJ' }
  ],
  decrescendo: [
    { word: 'decrescendo', word_class: 'N' },
    { word: 'decrescendo', word_class: 'V' },
    { word: 'decrescendo', word_class: 'AJ' }
  ],
  befoulment: [
    { word: 'befoul', word_class: 'V' },
    { word: 'befouled', word_class: 'AJ' },
    { word: 'befoulment', word_class: 'N' }
  ],
  episcopate: [
    { word: 'episcopal', word_class: 'N' },
    { word: 'episcopal', word_class: 'AJ' },
    { word: 'episcopate', word_class: 'N' }
  ],
  denaturized: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  coagulated: [
    { word: 'coagulate', word_class: 'V' },
    { word: 'coagulate', word_class: 'AJ' },
    { word: 'coagulable', word_class: 'AJ' },
    { word: 'coagulated', word_class: 'AJ' },
    { word: 'coagulation', word_class: 'N' }
  ],
  operant: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  interiorization: [
    { word: 'interior', word_class: 'N' },
    { word: 'interior', word_class: 'AJ' },
    { word: 'interiorize', word_class: 'V' },
    { word: 'interiorization', word_class: 'N' }
  ],
  miserably: [
    { word: 'miser', word_class: 'N' },
    { word: 'miserable', word_class: 'AJ' },
    { word: 'miserably', word_class: 'AV' }
  ],
  feeble: [
    { word: 'feeble', word_class: 'AJ' },
    { word: 'feebly', word_class: 'AV' },
    { word: 'feebleness', word_class: 'N' }
  ],
  valiance: [
    { word: 'valiance', word_class: 'N' },
    { word: 'valiancy', word_class: 'N' },
    { word: 'valiant', word_class: 'AJ' }
  ],
  rhapsody: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  bagful: [
    { word: 'bag', word_class: 'N' },
    { word: 'bag', word_class: 'V' },
    { word: 'bagful', word_class: 'N' },
    { word: 'bagman', word_class: 'N' },
    { word: 'bagful', word_class: 'AJ' },
    { word: 'baggage', word_class: 'N' },
    { word: 'bagging', word_class: 'N' }
  ],
  amoral: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  declaration: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  admissible: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  mountebank: [
    { word: 'mountebank', word_class: 'N' },
    { word: 'mountebank', word_class: 'V' },
    { word: 'mountebankery', word_class: 'N' }
  ],
  predicative: [
    { word: 'predicate', word_class: 'N' },
    { word: 'predicate', word_class: 'V' },
    { word: 'predicate', word_class: 'AJ' },
    { word: 'predicator', word_class: 'N' },
    { word: 'predication', word_class: 'N' },
    { word: 'predicative', word_class: 'AJ' },
    { word: 'predicatively', word_class: 'AV' }
  ],
  explicate: [
    { word: 'explicate', word_class: 'V' },
    { word: 'explicable', word_class: 'AJ' },
    { word: 'explication', word_class: 'N' }
  ],
  introversive: [
    { word: 'introvert', word_class: 'N' },
    { word: 'introvert', word_class: 'V' },
    { word: 'introvert', word_class: 'AJ' },
    { word: 'introversion', word_class: 'N' },
    { word: 'introverted', word_class: 'AJ' },
    { word: 'introversive', word_class: 'AJ' },
    { word: 'introvertive', word_class: 'AJ' }
  ],
  magical: [
    { word: 'magic', word_class: 'N' },
    { word: 'magic', word_class: 'AJ' },
    { word: 'magical', word_class: 'AJ' },
    { word: 'magically', word_class: 'AV' }
  ],
  leave: [
    { word: 'leave', word_class: 'N' },
    { word: 'leave', word_class: 'V' },
    { word: 'leaver', word_class: 'N' },
    { word: 'leaved', word_class: 'AJ' },
    { word: 'leaving', word_class: 'N' }
  ],
  aroma: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  dressing: [
    { word: 'dress', word_class: 'N' },
    { word: 'dress', word_class: 'V' },
    { word: 'dress', word_class: 'AJ' },
    { word: 'dresser', word_class: 'N' },
    { word: 'dressed', word_class: 'AJ' },
    { word: 'dressing', word_class: 'N' }
  ],
  nagging: [{ word: 'nag', word_class: 'N' }, { word: 'nag', word_class: 'V' }, { word: 'nagging', word_class: 'AJ' }],
  mountainous: [
    { word: 'mountain', word_class: 'N' },
    { word: 'mountain', word_class: 'AJ' },
    { word: 'mountaineer', word_class: 'N' },
    { word: 'mountaineer', word_class: 'V' },
    { word: 'mountainous', word_class: 'AJ' },
    { word: 'mountaineering', word_class: 'N' }
  ],
  cushion: [
    { word: 'cushion', word_class: 'N' },
    { word: 'cushion', word_class: 'V' },
    { word: 'cushioned', word_class: 'AJ' },
    { word: 'cushioning', word_class: 'N' }
  ],
  preparation: [
    { word: 'prepare', word_class: 'V' },
    { word: 'prepared', word_class: 'AJ' },
    { word: 'preparation', word_class: 'N' },
    { word: 'preparative', word_class: 'N' },
    { word: 'preparative', word_class: 'AJ' }
  ],
  bounce: [
    { word: 'bounce', word_class: 'N' },
    { word: 'bounce', word_class: 'V' },
    { word: 'bouncy', word_class: 'AJ' },
    { word: 'bouncer', word_class: 'N' },
    { word: 'bouncing', word_class: 'N' },
    { word: 'bouncing', word_class: 'AJ' },
    { word: 'bounciness', word_class: 'N' }
  ],
  uncontrollable: [
    { word: 'uncontrolled', word_class: 'AJ' },
    { word: 'uncontrollable', word_class: 'AJ' },
    { word: 'uncontrollably', word_class: 'AV' }
  ],
  foaled: [{ word: 'foal', word_class: 'N' }, { word: 'foal', word_class: 'V' }, { word: 'foaled', word_class: 'AJ' }],
  stained: [
    { word: 'stain', word_class: 'N' },
    { word: 'stain', word_class: 'V' },
    { word: 'stained', word_class: 'AJ' },
    { word: 'staining', word_class: 'N' }
  ],
  dalliance: [
    { word: 'dally', word_class: 'V' },
    { word: 'dallier', word_class: 'N' },
    { word: 'dalliance', word_class: 'N' }
  ],
  physicist: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  greased: [
    { word: 'grease', word_class: 'N' },
    { word: 'grease', word_class: 'V' },
    { word: 'greaser', word_class: 'N' },
    { word: 'greasy', word_class: 'AJ' },
    { word: 'greased', word_class: 'AJ' },
    { word: 'greasiness', word_class: 'N' }
  ],
  correctable: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  hustle: [
    { word: 'hustle', word_class: 'N' },
    { word: 'hustle', word_class: 'V' },
    { word: 'hustler', word_class: 'N' }
  ],
  jerk: [
    { word: 'jerk', word_class: 'N' },
    { word: 'jerk', word_class: 'V' },
    { word: 'jerky', word_class: 'N' },
    { word: 'jerky', word_class: 'AJ' },
    { word: 'jerking', word_class: 'N' },
    { word: 'jerking', word_class: 'AJ' },
    { word: 'jerkiness', word_class: 'N' }
  ],
  widow: [
    { word: 'widow', word_class: 'N' },
    { word: 'widow', word_class: 'V' },
    { word: 'widower', word_class: 'N' },
    { word: 'widowed', word_class: 'AJ' }
  ],
  preferable: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  systematize: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  dwelling: [
    { word: 'dwell', word_class: 'V' },
    { word: 'dweller', word_class: 'N' },
    { word: 'dwelling', word_class: 'N' }
  ],
  dispirit: [
    { word: 'dispirit', word_class: 'V' },
    { word: 'dispirited', word_class: 'AJ' },
    { word: 'dispiriting', word_class: 'AJ' }
  ],
  symmetrise: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  slippage: [
    { word: 'slip', word_class: 'N' },
    { word: 'slip', word_class: 'V' },
    { word: 'slippage', word_class: 'N' },
    { word: 'slippery', word_class: 'AJ' },
    { word: 'slipping', word_class: 'AJ' },
    { word: 'slipperiness', word_class: 'N' }
  ],
  persuasively: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  petrify: [
    { word: 'petrify', word_class: 'V' },
    { word: 'petrified', word_class: 'AJ' },
    { word: 'petrifying', word_class: 'AJ' },
    { word: 'petrifaction', word_class: 'N' },
    { word: 'petrification', word_class: 'N' }
  ],
  reprehensibly: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  totality: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  elect: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  loosening: [
    { word: 'loosen', word_class: 'V' },
    { word: 'loosened', word_class: 'AJ' },
    { word: 'loosening', word_class: 'N' }
  ],
  contrivance: [
    { word: 'contrive', word_class: 'V' },
    { word: 'contriver', word_class: 'N' },
    { word: 'contrived', word_class: 'AJ' },
    { word: 'contrivance', word_class: 'N' }
  ],
  divergent: [
    { word: 'diverge', word_class: 'N' },
    { word: 'diverge', word_class: 'V' },
    { word: 'divergence', word_class: 'N' },
    { word: 'divergency', word_class: 'N' },
    { word: 'divergent', word_class: 'AJ' },
    { word: 'diverging', word_class: 'AJ' }
  ],
  dictated: [
    { word: 'dictate', word_class: 'N' },
    { word: 'dictate', word_class: 'V' },
    { word: 'dication', word_class: 'N' },
    { word: 'dictator', word_class: 'N' },
    { word: 'dictated', word_class: 'AJ' },
    { word: 'dictation', word_class: 'N' }
  ],
  progressivity: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  materiality: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  historical: [
    { word: 'history', word_class: 'N' },
    { word: 'historic', word_class: 'AJ' },
    { word: 'historied', word_class: 'AJ' },
    { word: 'historical', word_class: 'AJ' },
    { word: 'historically', word_class: 'AV' }
  ],
  crushing: [
    { word: 'crush', word_class: 'N' },
    { word: 'crush', word_class: 'V' },
    { word: 'crusher', word_class: 'N' },
    { word: 'crushed', word_class: 'AJ' },
    { word: 'crushing', word_class: 'N' },
    { word: 'crushing', word_class: 'AJ' }
  ],
  preponderation: [
    { word: 'preponderant', word_class: 'N' },
    { word: 'preponderate', word_class: 'V' },
    { word: 'preponderance', word_class: 'N' },
    { word: 'preponderant', word_class: 'AJ' },
    { word: 'preponderation', word_class: 'N' },
    { word: 'preponderating', word_class: 'AJ' }
  ],
  stop: [
    { word: 'stop', word_class: 'N' },
    { word: 'stop', word_class: 'V' },
    { word: 'stops', word_class: 'N' },
    { word: 'stoppage', word_class: 'N' },
    { word: 'stopped', word_class: 'AJ' },
    { word: 'stopping', word_class: 'N' }
  ],
  formication: [
    { word: 'formic', word_class: 'AJ' },
    { word: 'formicate', word_class: 'V' },
    { word: 'formication', word_class: 'N' }
  ],
  stayer: [
    { word: 'stay', word_class: 'N' },
    { word: 'stay', word_class: 'V' },
    { word: 'stays', word_class: 'N' },
    { word: 'stayer', word_class: 'N' },
    { word: 'stayman', word_class: 'N' }
  ],
  logarithmically: [
    { word: 'logarithm', word_class: 'N' },
    { word: 'logarithmic', word_class: 'AJ' },
    { word: 'logarithmically', word_class: 'AV' }
  ],
  incongruent: [
    { word: 'incongruity', word_class: 'N' },
    { word: 'incongruent', word_class: 'AJ' },
    { word: 'incongruous', word_class: 'AJ' },
    { word: 'incongruously', word_class: 'AV' },
    { word: 'incongruousness', word_class: 'N' }
  ],
  magnetize: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  platitudinize: [
    { word: 'platitude', word_class: 'N' },
    { word: 'platitudinal', word_class: 'AJ' },
    { word: 'platitudinize', word_class: 'V' },
    { word: 'platitudinous', word_class: 'AJ' },
    { word: 'platitudinization', word_class: 'N' }
  ],
  instrumentality: [
    { word: 'instrument', word_class: 'N' },
    { word: 'instrument', word_class: 'V' },
    { word: 'instrumental', word_class: 'N' },
    { word: 'instrumental', word_class: 'AJ' },
    { word: 'instrumentate', word_class: 'V' },
    { word: 'instrumentality', word_class: 'N' },
    { word: 'instrumentation', word_class: 'N' }
  ],
  thaw: [
    { word: 'thaw', word_class: 'N' },
    { word: 'thaw', word_class: 'V' },
    { word: 'thawed', word_class: 'AJ' },
    { word: 'thawing', word_class: 'N' }
  ],
  loving: [
    { word: 'love', word_class: 'N' },
    { word: 'love', word_class: 'V' },
    { word: 'lover', word_class: 'N' },
    { word: 'loved', word_class: 'AJ' },
    { word: 'lovely', word_class: 'N' },
    { word: 'lovely', word_class: 'AJ' },
    { word: 'loving', word_class: 'AJ' }
  ],
  usefulness: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  forceful: [
    { word: 'force', word_class: 'N' },
    { word: 'force', word_class: 'V' },
    { word: 'forced', word_class: 'AJ' },
    { word: 'forceful', word_class: 'AJ' },
    { word: 'forcefulness', word_class: 'N' }
  ],
  irs: [
    { word: 'ir', word_class: 'N' },
    { word: 'ire', word_class: 'N' },
    { word: 'irs', word_class: 'N' },
    { word: 'ireful', word_class: 'AJ' }
  ],
  misconduction: [
    { word: 'misconduct', word_class: 'N' },
    { word: 'misconduct', word_class: 'V' },
    { word: 'misconduction', word_class: 'N' }
  ],
  benefice: [
    { word: 'benefice', word_class: 'N' },
    { word: 'benefice', word_class: 'V' },
    { word: 'beneficence', word_class: 'N' },
    { word: 'beneficent', word_class: 'AJ' },
    { word: 'beneficiary', word_class: 'N' },
    { word: 'beneficiary', word_class: 'AJ' }
  ],
  squander: [
    { word: 'squander', word_class: 'V' },
    { word: 'squanderer', word_class: 'N' },
    { word: 'squandered', word_class: 'AJ' },
    { word: 'squandering', word_class: 'N' }
  ],
  humanize: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  plutocratic: [
    { word: 'plutocrat', word_class: 'N' },
    { word: 'plutocratic', word_class: 'AJ' },
    { word: 'plutocratical', word_class: 'AJ' }
  ],
  pedantry: [
    { word: 'pedant', word_class: 'N' },
    { word: 'pedantry', word_class: 'N' },
    { word: 'pedantic', word_class: 'AJ' },
    { word: 'pedantically', word_class: 'AV' }
  ],
  jargonize: [
    { word: 'jargon', word_class: 'N' },
    { word: 'jargon', word_class: 'V' },
    { word: 'jargonise', word_class: 'V' },
    { word: 'jargonize', word_class: 'V' },
    { word: 'jargonisation', word_class: 'N' },
    { word: 'jargonization', word_class: 'N' }
  ],
  dot: [{ word: 'dot', word_class: 'N' }, { word: 'dot', word_class: 'V' }, { word: 'dotted', word_class: 'AJ' }],
  patronising: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  thrombose: [
    { word: 'thrombose', word_class: 'V' },
    { word: 'thrombosis', word_class: 'N' },
    { word: 'thrombosed', word_class: 'AJ' }
  ],
  malevolency: [
    { word: 'malevolence', word_class: 'N' },
    { word: 'malevolency', word_class: 'N' },
    { word: 'malevolent', word_class: 'AJ' },
    { word: 'malevolently', word_class: 'AV' }
  ],
  modernise: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  excell: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  swelling: [
    { word: 'swell', word_class: 'N' },
    { word: 'swell', word_class: 'V' },
    { word: 'swell', word_class: 'AJ' },
    { word: 'swelled', word_class: 'AJ' },
    { word: 'swelling', word_class: 'N' },
    { word: 'swollen', word_class: 'AJ' },
    { word: 'swelling', word_class: 'AJ' }
  ],
  dealing: [
    { word: 'deal', word_class: 'N' },
    { word: 'deal', word_class: 'V' },
    { word: 'deal', word_class: 'AJ' },
    { word: 'dealer', word_class: 'N' },
    { word: 'dealing', word_class: 'N' },
    { word: 'dealings', word_class: 'N' }
  ],
  intransitive: [
    { word: 'intransitive', word_class: 'AJ' },
    { word: 'intransitivity', word_class: 'N' },
    { word: 'intransitively', word_class: 'AV' },
    { word: 'intransitiveness', word_class: 'N' }
  ],
  anointing: [
    { word: 'anoint', word_class: 'V' },
    { word: 'anointing', word_class: 'N' },
    { word: 'inunction', word_class: 'N' },
    { word: 'anointment', word_class: 'N' }
  ],
  drench: [
    { word: 'drench', word_class: 'V' },
    { word: 'drenched', word_class: 'AJ' },
    { word: 'drenching', word_class: 'N' }
  ],
  overburdened: [
    { word: 'overburden', word_class: 'N' },
    { word: 'overburden', word_class: 'V' },
    { word: 'overburdened', word_class: 'AJ' }
  ],
  hirer: [
    { word: 'hire', word_class: 'N' },
    { word: 'hire', word_class: 'V' },
    { word: 'hirer', word_class: 'N' },
    { word: 'hired', word_class: 'AJ' },
    { word: 'hiring', word_class: 'N' }
  ],
  nonplus: [
    { word: 'nonplus', word_class: 'N' },
    { word: 'nonplus', word_class: 'V' },
    { word: 'nonplus', word_class: 'AJ' }
  ],
  opalesce: [
    { word: 'opalesce', word_class: 'V' },
    { word: 'opalescence', word_class: 'N' },
    { word: 'opalescent', word_class: 'AJ' }
  ],
  forties: [
    { word: 'forty', word_class: 'N' },
    { word: 'forty', word_class: 'AJ' },
    { word: 'forties', word_class: 'N' }
  ],
  somnambulism: [
    { word: 'somnambulate', word_class: 'V' },
    { word: 'somnambulism', word_class: 'N' },
    { word: 'somnambulance', word_class: 'N' },
    { word: 'somnambulation', word_class: 'N' }
  ],
  advantaged: [
    { word: 'advantage', word_class: 'N' },
    { word: 'advantage', word_class: 'V' },
    { word: 'advantaged', word_class: 'AJ' },
    { word: 'advantageous', word_class: 'AJ' },
    { word: 'advantageously', word_class: 'AV' },
    { word: 'advantageousness', word_class: 'N' }
  ],
  preservable: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  anthropoidal: [
    { word: 'anthropoid', word_class: 'N' },
    { word: 'anthropoid', word_class: 'AJ' },
    { word: 'anthropoidal', word_class: 'AJ' }
  ],
  whistler: [
    { word: 'whistle', word_class: 'N' },
    { word: 'whistle', word_class: 'V' },
    { word: 'whistler', word_class: 'N' },
    { word: 'whistling', word_class: 'N' }
  ],
  overnight: [
    { word: 'overnight', word_class: 'V' },
    { word: 'overnight', word_class: 'AJ' },
    { word: 'overnight', word_class: 'AV' },
    { word: 'overnighter', word_class: 'N' }
  ],
  arthropodal: [
    { word: 'arthropod', word_class: 'N' },
    { word: 'arthropodal', word_class: 'AJ' },
    { word: 'arthropodous', word_class: 'AJ' }
  ],
  locality: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  jaws: [
    { word: 'jaw', word_class: 'N' },
    { word: 'jaw', word_class: 'V' },
    { word: 'jaws', word_class: 'N' },
    { word: 'jawed', word_class: 'AJ' }
  ],
  blaze: [
    { word: 'blaze', word_class: 'N' },
    { word: 'blaze', word_class: 'V' },
    { word: 'blazer', word_class: 'N' },
    { word: 'blazing', word_class: 'N' },
    { word: 'blazing', word_class: 'AJ' }
  ],
  speck: [
    { word: 'speck', word_class: 'N' },
    { word: 'speck', word_class: 'V' },
    { word: 'specked', word_class: 'AJ' }
  ],
  allegeable: [
    { word: 'allege', word_class: 'V' },
    { word: 'alleged', word_class: 'AJ' },
    { word: 'allegation', word_class: 'N' },
    { word: 'allegement', word_class: 'N' },
    { word: 'allegeable', word_class: 'AJ' }
  ],
  boxful: [
    { word: 'box', word_class: 'N' },
    { word: 'box', word_class: 'V' },
    { word: 'boxer', word_class: 'N' },
    { word: 'boxed', word_class: 'AJ' },
    { word: 'boxful', word_class: 'N' },
    { word: 'boxing', word_class: 'N' },
    { word: 'boxful', word_class: 'AJ' }
  ],
  exculpation: [
    { word: 'exculpate', word_class: 'V' },
    { word: 'exculpated', word_class: 'AJ' },
    { word: 'exculpation', word_class: 'N' }
  ],
  crumbled: [
    { word: 'crumble', word_class: 'V' },
    { word: 'crumbly', word_class: 'AJ' },
    { word: 'crumbled', word_class: 'AJ' }
  ],
  nationalize: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  consultant: [
    { word: 'consult', word_class: 'V' },
    { word: 'consultant', word_class: 'N' },
    { word: 'consultive', word_class: 'AJ' },
    { word: 'consultation', word_class: 'N' },
    { word: 'consultative', word_class: 'AJ' }
  ],
  secondary: [
    { word: 'second', word_class: 'N' },
    { word: 'second', word_class: 'V' },
    { word: 'second', word_class: 'AJ' },
    { word: 'second', word_class: 'AV' },
    { word: 'seconder', word_class: 'N' },
    { word: 'secondary', word_class: 'N' },
    { word: 'secondary', word_class: 'AJ' },
    { word: 'secondment', word_class: 'N' }
  ],
  pleasance: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  territory: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  retentiveness: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  vocally: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  photosensitization: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  culpable: [
    { word: 'culpable', word_class: 'AJ' },
    { word: 'culpably', word_class: 'AV' },
    { word: 'culpability', word_class: 'N' },
    { word: 'culpableness', word_class: 'N' }
  ],
  suppose: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  hooked: [
    { word: 'hook', word_class: 'N' },
    { word: 'hook', word_class: 'V' },
    { word: 'hooker', word_class: 'N' },
    { word: 'hooked', word_class: 'AJ' }
  ],
  waxen: [
    { word: 'wax', word_class: 'N' },
    { word: 'wax', word_class: 'V' },
    { word: 'waxed', word_class: 'AJ' },
    { word: 'waxen', word_class: 'AJ' },
    { word: 'waxing', word_class: 'N' },
    { word: 'waxing', word_class: 'AJ' }
  ],
  statement: [
    { word: 'state', word_class: 'N' },
    { word: 'state', word_class: 'V' },
    { word: 'state', word_class: 'AJ' },
    { word: 'stator', word_class: 'N' },
    { word: 'stated', word_class: 'AJ' },
    { word: 'stately', word_class: 'AJ' },
    { word: 'statement', word_class: 'N' }
  ],
  unmoved: [
    { word: 'unmoved', word_class: 'AJ' },
    { word: 'unmoving', word_class: 'AJ' },
    { word: 'unmovable', word_class: 'AJ' }
  ],
  embattled: [
    { word: 'embattle', word_class: 'V' },
    { word: 'embattled', word_class: 'AJ' },
    { word: 'embattlement', word_class: 'N' }
  ],
  packer: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  afrikanerize: [
    { word: 'afrikanerise', word_class: 'V' },
    { word: 'afrikanerize', word_class: 'V' },
    { word: 'afrikanerisation', word_class: 'N' },
    { word: 'afrikanerization', word_class: 'N' }
  ],
  append: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  environment: [
    { word: 'environ', word_class: 'N' },
    { word: 'environ', word_class: 'V' },
    { word: 'environs', word_class: 'N' },
    { word: 'environment', word_class: 'N' }
  ],
  inhumanity: [
    { word: 'inhuman', word_class: 'AJ' },
    { word: 'inhumane', word_class: 'AJ' },
    { word: 'inhumanity', word_class: 'N' },
    { word: 'inhumanely', word_class: 'AV' },
    { word: 'inhumaneness', word_class: 'N' }
  ],
  escarp: [
    { word: 'escarp', word_class: 'N' },
    { word: 'escarp', word_class: 'V' },
    { word: 'escarpment', word_class: 'N' }
  ],
  censor: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  profusely: [
    { word: 'profuse', word_class: 'N' },
    { word: 'profuse', word_class: 'V' },
    { word: 'profuse', word_class: 'AJ' },
    { word: 'profusion', word_class: 'N' },
    { word: 'profusely', word_class: 'AV' },
    { word: 'profuseness', word_class: 'N' }
  ],
  strangling: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  common: [
    { word: 'common', word_class: 'N' },
    { word: 'common', word_class: 'AJ' },
    { word: 'commons', word_class: 'N' },
    { word: 'commoner', word_class: 'N' },
    { word: 'commonness', word_class: 'N' },
    { word: 'commonality', word_class: 'N' }
  ],
  clubbing: [
    { word: 'club', word_class: 'N' },
    { word: 'club', word_class: 'V' },
    { word: 'clubbing', word_class: 'N' }
  ],
  contestable: [
    { word: 'contest', word_class: 'N' },
    { word: 'contest', word_class: 'V' },
    { word: 'contestant', word_class: 'N' },
    { word: 'contestable', word_class: 'AJ' },
    { word: 'contestation', word_class: 'N' }
  ],
  disabusal: [
    { word: 'disabuse', word_class: 'N' },
    { word: 'disabuse', word_class: 'V' },
    { word: 'disabusal', word_class: 'N' },
    { word: 'disabused', word_class: 'AJ' }
  ],
  repletion: [
    { word: 'replete', word_class: 'V' },
    { word: 'replete', word_class: 'AJ' },
    { word: 'repletion', word_class: 'N' }
  ],
  cleansed: [
    { word: 'cleanse', word_class: 'V' },
    { word: 'cleanser', word_class: 'N' },
    { word: 'cleansed', word_class: 'AJ' },
    { word: 'cleansing', word_class: 'N' },
    { word: 'cleansing', word_class: 'AJ' }
  ],
  ebon: [
    { word: 'ebon', word_class: 'AJ' },
    { word: 'ebonics', word_class: 'N' },
    { word: 'ebonize', word_class: 'V' },
    { word: 'ebonization', word_class: 'N' }
  ],
  haltere: [
    { word: 'halt', word_class: 'N' },
    { word: 'halt', word_class: 'V' },
    { word: 'halt', word_class: 'AJ' },
    { word: 'halter', word_class: 'N' },
    { word: 'halter', word_class: 'V' },
    { word: 'halted', word_class: 'AJ' },
    { word: 'haltere', word_class: 'N' },
    { word: 'halting', word_class: 'AJ' }
  ],
  hyphenate: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  goose: [{ word: 'goose', word_class: 'N' }, { word: 'goose', word_class: 'V' }, { word: 'gosling', word_class: 'N' }],
  transpirate: [
    { word: 'transpire', word_class: 'V' },
    { word: 'transpirate', word_class: 'V' },
    { word: 'transpiring', word_class: 'AJ' },
    { word: 'transpiration', word_class: 'N' }
  ],
  mire: [{ word: 'mire', word_class: 'N' }, { word: 'mire', word_class: 'V' }, { word: 'mired', word_class: 'AJ' }],
  lefthandedness: [
    { word: 'lefthand', word_class: 'AJ' },
    { word: 'lefthanded', word_class: 'AJ' },
    { word: 'lefthandedness', word_class: 'N' }
  ],
  volcanise: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  warranted: [
    { word: 'warrant', word_class: 'N' },
    { word: 'warrant', word_class: 'V' },
    { word: 'warrantee', word_class: 'N' },
    { word: 'warrantor', word_class: 'N' },
    { word: 'warranted', word_class: 'AJ' }
  ],
  compartmentalized: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  dynamitist: [
    { word: 'dynamite', word_class: 'N' },
    { word: 'dynamite', word_class: 'V' },
    { word: 'dynamiter', word_class: 'N' },
    { word: 'dynamitist', word_class: 'N' }
  ],
  hearing: [
    { word: 'hear', word_class: 'V' },
    { word: 'hearer', word_class: 'N' },
    { word: 'hearing', word_class: 'N' },
    { word: 'hearing', word_class: 'AJ' }
  ],
  foresightfulness: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  divinisation: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  cooking: [
    { word: 'cook', word_class: 'N' },
    { word: 'cook', word_class: 'V' },
    { word: 'cook', word_class: 'AJ' },
    { word: 'cooker', word_class: 'N' },
    { word: 'cookery', word_class: 'N' },
    { word: 'cooking', word_class: 'N' },
    { word: 'cooked', word_class: 'AJ' },
    { word: 'cookery', word_class: 'AJ' }
  ],
  recite: [
    { word: 'recite', word_class: 'V' },
    { word: 'recital', word_class: 'N' },
    { word: 'recitation', word_class: 'N' },
    { word: 'recitative', word_class: 'N' }
  ],
  garble: [
    { word: 'garble', word_class: 'N' },
    { word: 'garble', word_class: 'V' },
    { word: 'garbled', word_class: 'AJ' }
  ],
  impound: [
    { word: 'impound', word_class: 'N' },
    { word: 'impound', word_class: 'V' },
    { word: 'impounding', word_class: 'N' },
    { word: 'impoundment', word_class: 'N' }
  ],
  reprehension: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  complex: [
    { word: 'complex', word_class: 'N' },
    { word: 'complex', word_class: 'AJ' },
    { word: 'complexity', word_class: 'N' },
    { word: 'complexness', word_class: 'N' }
  ],
  wiggler: [
    { word: 'wiggle', word_class: 'N' },
    { word: 'wiggle', word_class: 'V' },
    { word: 'wiggler', word_class: 'N' },
    { word: 'wiggly', word_class: 'AJ' },
    { word: 'wiggling', word_class: 'AJ' },
    { word: 'wiggliness', word_class: 'N' }
  ],
  tetanisation: [
    { word: 'tetanise', word_class: 'V' },
    { word: 'tetanize', word_class: 'V' },
    { word: 'tetanisation', word_class: 'N' },
    { word: 'tetanization', word_class: 'N' }
  ],
  seal: [
    { word: 'seal', word_class: 'N' },
    { word: 'seal', word_class: 'V' },
    { word: 'sealer', word_class: 'N' },
    { word: 'sealant', word_class: 'N' },
    { word: 'sealed', word_class: 'AJ' },
    { word: 'sealing', word_class: 'N' }
  ],
  govern: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  revet: [
    { word: 'revet', word_class: 'V' },
    { word: 'revetment', word_class: 'N' },
    { word: 'revetement', word_class: 'N' }
  ],
  choreographer: [
    { word: 'choreograph', word_class: 'V' },
    { word: 'choreography', word_class: 'N' },
    { word: 'choreographer', word_class: 'N' },
    { word: 'choreographic', word_class: 'AJ' }
  ],
  juggling: [
    { word: 'juggle', word_class: 'N' },
    { word: 'juggle', word_class: 'V' },
    { word: 'juggler', word_class: 'N' },
    { word: 'jugglery', word_class: 'N' },
    { word: 'juggling', word_class: 'N' }
  ],
  beauteous: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  directing: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  caesarian: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  jag: [{ word: 'jag', word_class: 'N' }, { word: 'jag', word_class: 'V' }, { word: 'jagged', word_class: 'AJ' }],
  untouched: [
    { word: 'untouched', word_class: 'AJ' },
    { word: 'untouchable', word_class: 'N' },
    { word: 'untouchable', word_class: 'AJ' }
  ],
  tesselate: [
    { word: 'tesselate', word_class: 'V' },
    { word: 'tessellate', word_class: 'V' },
    { word: 'tessellated', word_class: 'AJ' },
    { word: 'tessellation', word_class: 'N' }
  ],
  milk: [
    { word: 'milk', word_class: 'N' },
    { word: 'milk', word_class: 'V' },
    { word: 'milk', word_class: 'AJ' },
    { word: 'milker', word_class: 'N' },
    { word: 'milkman', word_class: 'N' }
  ],
  extinction: [
    { word: 'extinct', word_class: 'V' },
    { word: 'extinct', word_class: 'AJ' },
    { word: 'extinction', word_class: 'N' }
  ],
  proselyte: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  compliancy: [
    { word: 'comply', word_class: 'V' },
    { word: 'compliance', word_class: 'N' },
    { word: 'compliancy', word_class: 'N' },
    { word: 'complying', word_class: 'AJ' }
  ],
  peaceableness: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  elegant: [
    { word: 'elegize', word_class: 'V' },
    { word: 'elegance', word_class: 'N' },
    { word: 'elegant', word_class: 'AJ' },
    { word: 'elegization', word_class: 'N' }
  ],
  spender: [
    { word: 'spend', word_class: 'V' },
    { word: 'spender', word_class: 'N' },
    { word: 'spending', word_class: 'N' }
  ],
  conceptualization: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  imbedment: [
    { word: 'embed', word_class: 'V' },
    { word: 'imbed', word_class: 'N' },
    { word: 'imbed', word_class: 'V' },
    { word: 'embedded', word_class: 'AJ' },
    { word: 'embedment', word_class: 'N' },
    { word: 'imbedment', word_class: 'N' }
  ],
  subsuming: [
    { word: 'subsume', word_class: 'V' },
    { word: 'subsuming', word_class: 'N' },
    { word: 'subsumption', word_class: 'N' }
  ],
  protractible: [
    { word: 'protract', word_class: 'N' },
    { word: 'protract', word_class: 'V' },
    { word: 'protractor', word_class: 'N' },
    { word: 'protracted', word_class: 'AJ' },
    { word: 'protraction', word_class: 'N' },
    { word: 'protractible', word_class: 'AJ' }
  ],
  enervated: [
    { word: 'enervate', word_class: 'V' },
    { word: 'enervated', word_class: 'AJ' },
    { word: 'enervation', word_class: 'N' },
    { word: 'enervating', word_class: 'AJ' }
  ],
  warring: [
    { word: 'war', word_class: 'N' },
    { word: 'war', word_class: 'V' },
    { word: 'warship', word_class: 'N' },
    { word: 'warring', word_class: 'AJ' }
  ],
  proposer: [
    { word: 'propose', word_class: 'N' },
    { word: 'propose', word_class: 'V' },
    { word: 'proposal', word_class: 'N' },
    { word: 'proposer', word_class: 'N' },
    { word: 'proposed', word_class: 'AJ' }
  ],
  punch: [{ word: 'punch', word_class: 'N' }, { word: 'punch', word_class: 'V' }, { word: 'puncher', word_class: 'N' }],
  boarding: [
    { word: 'board', word_class: 'N' },
    { word: 'board', word_class: 'V' },
    { word: 'boards', word_class: 'N' },
    { word: 'boarder', word_class: 'N' },
    { word: 'boarding', word_class: 'N' }
  ],
  modern: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  unreasonable: [
    { word: 'unreason', word_class: 'N' },
    { word: 'unreasoning', word_class: 'AJ' },
    { word: 'unreasonable', word_class: 'AJ' },
    { word: 'unreasonably', word_class: 'AV' }
  ],
  automatically: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  seclusion: [
    { word: 'seclude', word_class: 'V' },
    { word: 'secluded', word_class: 'AJ' },
    { word: 'seclusion', word_class: 'N' }
  ],
  meditation: [
    { word: 'meditate', word_class: 'V' },
    { word: 'meditation', word_class: 'N' },
    { word: 'meditative', word_class: 'AJ' },
    { word: 'meditatively', word_class: 'AV' },
    { word: 'meditativeness', word_class: 'N' }
  ],
  bounder: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  entrench: [
    { word: 'entrench', word_class: 'V' },
    { word: 'entrenched', word_class: 'AJ' },
    { word: 'entrenchment', word_class: 'N' }
  ],
  philosophical: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  thrilling: [
    { word: 'thrill', word_class: 'N' },
    { word: 'thrill', word_class: 'V' },
    { word: 'thriller', word_class: 'N' },
    { word: 'thrilled', word_class: 'AJ' },
    { word: 'thrillful', word_class: 'AJ' },
    { word: 'thrilling', word_class: 'AJ' }
  ],
  graduated: [
    { word: 'graduate', word_class: 'N' },
    { word: 'graduate', word_class: 'V' },
    { word: 'graduate', word_class: 'AJ' },
    { word: 'graduated', word_class: 'AJ' },
    { word: 'graduation', word_class: 'N' }
  ],
  perspire: [
    { word: 'perspire', word_class: 'V' },
    { word: 'perspiring', word_class: 'N' },
    { word: 'perspiring', word_class: 'AJ' },
    { word: 'perspiration', word_class: 'N' }
  ],
  grooved: [
    { word: 'groove', word_class: 'N' },
    { word: 'groove', word_class: 'V' },
    { word: 'groover', word_class: 'N' },
    { word: 'grooved', word_class: 'AJ' },
    { word: 'grooving', word_class: 'N' }
  ],
  demotic: [
    { word: 'demote', word_class: 'V' },
    { word: 'demotic', word_class: 'N' },
    { word: 'demotic', word_class: 'AJ' },
    { word: 'demotion', word_class: 'N' }
  ],
  normanisation: [
    { word: 'norman', word_class: 'N' },
    { word: 'norman', word_class: 'AJ' },
    { word: 'normanise', word_class: 'V' },
    { word: 'normanize', word_class: 'V' },
    { word: 'normanisation', word_class: 'N' },
    { word: 'normanization', word_class: 'N' }
  ],
  adorability: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  glycerin: [
    { word: 'glycerin', word_class: 'N' },
    { word: 'glycerine', word_class: 'N' },
    { word: 'glycerinate', word_class: 'V' }
  ],
  extensible: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  blockading: [
    { word: 'blockade', word_class: 'N' },
    { word: 'blockade', word_class: 'V' },
    { word: 'blockaded', word_class: 'AJ' },
    { word: 'blockading', word_class: 'AJ' }
  ],
  rapaciously: [
    { word: 'rapacious', word_class: 'AJ' },
    { word: 'rapaciously', word_class: 'AV' },
    { word: 'rapaciousness', word_class: 'N' }
  ],
  investigator: [
    { word: 'investigate', word_class: 'V' },
    { word: 'investigator', word_class: 'N' },
    { word: 'investigating', word_class: 'N' },
    { word: 'investigation', word_class: 'N' },
    { word: 'investigative', word_class: 'AJ' }
  ],
  dimer: [
    { word: 'dim', word_class: 'V' },
    { word: 'dim', word_class: 'AJ' },
    { word: 'dimer', word_class: 'N' },
    { word: 'dimmed', word_class: 'AJ' },
    { word: 'dimness', word_class: 'N' }
  ],
  tanned: [
    { word: 'tan', word_class: 'N' },
    { word: 'tan', word_class: 'V' },
    { word: 'tan', word_class: 'AJ' },
    { word: 'tannage', word_class: 'N' },
    { word: 'tanned', word_class: 'AJ' }
  ],
  revelry: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  vesta: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  believer: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  juicer: [
    { word: 'juice', word_class: 'N' },
    { word: 'juice', word_class: 'V' },
    { word: 'juicer', word_class: 'N' },
    { word: 'juicy', word_class: 'AJ' },
    { word: 'juiciness', word_class: 'N' }
  ],
  disenfranchised: [
    { word: 'disenfranchise', word_class: 'V' },
    { word: 'disenfranchised', word_class: 'AJ' },
    { word: 'disenfranchisement', word_class: 'N' }
  ],
  surefootedly: [
    { word: 'surefooted', word_class: 'AJ' },
    { word: 'surefootedly', word_class: 'AV' },
    { word: 'surefootedness', word_class: 'N' }
  ],
  relativity: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  ejaculate: [
    { word: 'ejaculate', word_class: 'N' },
    { word: 'ejaculate', word_class: 'V' },
    { word: 'ejaculator', word_class: 'N' },
    { word: 'ejaculation', word_class: 'N' }
  ],
  clearing: [
    { word: 'clear', word_class: 'N' },
    { word: 'clear', word_class: 'V' },
    { word: 'clear', word_class: 'AJ' },
    { word: 'clear', word_class: 'AV' },
    { word: 'cleared', word_class: 'AJ' },
    { word: 'clearing', word_class: 'N' },
    { word: 'clearance', word_class: 'N' },
    { word: 'clearness', word_class: 'N' }
  ],
  cyclist: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  oversized: [
    { word: 'oversee', word_class: 'V' },
    { word: 'overseer', word_class: 'N' },
    { word: 'oversize', word_class: 'AJ' },
    { word: 'oversized', word_class: 'AJ' }
  ],
  activation: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  attachable: [
    { word: 'attach', word_class: 'V' },
    { word: 'attache', word_class: 'N' },
    { word: 'attached', word_class: 'AJ' },
    { word: 'attachment', word_class: 'N' },
    { word: 'attachable', word_class: 'AJ' }
  ],
  prostatitis: [
    { word: 'prostate', word_class: 'N' },
    { word: 'prostate', word_class: 'V' },
    { word: 'prostate', word_class: 'AJ' },
    { word: 'prostatic', word_class: 'AJ' },
    { word: 'prostatitis', word_class: 'N' }
  ],
  semantics: [
    { word: 'semantic', word_class: 'AJ' },
    { word: 'semantics', word_class: 'N' },
    { word: 'semantically', word_class: 'AV' }
  ],
  shoring: [
    { word: 'shore', word_class: 'N' },
    { word: 'shore', word_class: 'V' },
    { word: 'shore', word_class: 'AJ' },
    { word: 'shoring', word_class: 'N' }
  ],
  background: [
    { word: 'background', word_class: 'N' },
    { word: 'background', word_class: 'V' },
    { word: 'background', word_class: 'AJ' },
    { word: 'backgrounding', word_class: 'N' }
  ],
  snore: [
    { word: 'snore', word_class: 'N' },
    { word: 'snore', word_class: 'V' },
    { word: 'snorer', word_class: 'N' },
    { word: 'snoring', word_class: 'N' },
    { word: 'snoring', word_class: 'AJ' }
  ],
  familiarizing: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  inconvenience: [
    { word: 'inconvenience', word_class: 'N' },
    { word: 'inconvenience', word_class: 'V' },
    { word: 'inconvenient', word_class: 'AJ' },
    { word: 'inconveniently', word_class: 'AV' }
  ],
  mourner: [
    { word: 'mourn', word_class: 'V' },
    { word: 'mourner', word_class: 'N' },
    { word: 'mourning', word_class: 'N' },
    { word: 'mournful', word_class: 'AJ' },
    { word: 'mourning', word_class: 'AJ' },
    { word: 'mournfulness', word_class: 'N' }
  ],
  restrictive: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  proportionately: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  forthright: [
    { word: 'forthright', word_class: 'AJ' },
    { word: 'forthright', word_class: 'AV' },
    { word: 'forthrightness', word_class: 'N' }
  ],
  rattle: [
    { word: 'rattle', word_class: 'N' },
    { word: 'rattle', word_class: 'V' },
    { word: 'rattly', word_class: 'AJ' },
    { word: 'rattler', word_class: 'N' },
    { word: 'rattled', word_class: 'AJ' },
    { word: 'rattling', word_class: 'N' },
    { word: 'rattling', word_class: 'AJ' },
    { word: 'rattling', word_class: 'AV' }
  ],
  snipping: [
    { word: 'snip', word_class: 'N' },
    { word: 'snip', word_class: 'V' },
    { word: 'snips', word_class: 'N' },
    { word: 'snipping', word_class: 'N' }
  ],
  transfuse: [
    { word: 'transfuse', word_class: 'N' },
    { word: 'transfuse', word_class: 'V' },
    { word: 'transfusion', word_class: 'N' }
  ],
  quantisation: [
    { word: 'quantise', word_class: 'V' },
    { word: 'quantize', word_class: 'V' },
    { word: 'quantisation', word_class: 'N' },
    { word: 'quantization', word_class: 'N' }
  ],
  quits: [
    { word: 'quit', word_class: 'V' },
    { word: 'quit', word_class: 'AJ' },
    { word: 'quite', word_class: 'AV' },
    { word: 'quits', word_class: 'AJ' }
  ],
  proportionality: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  retroactive: [
    { word: 'retroact', word_class: 'V' },
    { word: 'retroaction', word_class: 'N' },
    { word: 'retroactive', word_class: 'AJ' },
    { word: 'retroactively', word_class: 'AV' }
  ],
  shrive: [
    { word: 'shrift', word_class: 'N' },
    { word: 'shrive', word_class: 'V' },
    { word: 'shriven', word_class: 'AJ' }
  ],
  discerning: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  hater: [
    { word: 'hate', word_class: 'N' },
    { word: 'hate', word_class: 'V' },
    { word: 'hater', word_class: 'N' },
    { word: 'hated', word_class: 'AJ' },
    { word: 'hateful', word_class: 'AJ' },
    { word: 'hatefulness', word_class: 'N' }
  ],
  planation: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  hypnotizer: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  impressionistic: [
    { word: 'impressionist', word_class: 'N' },
    { word: 'impressionist', word_class: 'AJ' },
    { word: 'impressionistic', word_class: 'AJ' }
  ],
  sententious: [
    { word: 'sentence', word_class: 'N' },
    { word: 'sentence', word_class: 'V' },
    { word: 'sentential', word_class: 'AJ' },
    { word: 'sententious', word_class: 'AJ' },
    { word: 'sententiously', word_class: 'AV' }
  ],
  vegetation: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  liveness: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  clocks: [
    { word: 'clock', word_class: 'N' },
    { word: 'clock', word_class: 'V' },
    { word: 'clocks', word_class: 'N' },
    { word: 'clocking', word_class: 'N' }
  ],
  shifting: [
    { word: 'shift', word_class: 'N' },
    { word: 'shift', word_class: 'V' },
    { word: 'shifting', word_class: 'N' },
    { word: 'shifting', word_class: 'AJ' }
  ],
  disconnexion: [
    { word: 'disconnect', word_class: 'V' },
    { word: 'disconnexion', word_class: 'N' },
    { word: 'disconnected', word_class: 'AJ' },
    { word: 'disconnection', word_class: 'N' }
  ],
  attest: [
    { word: 'attest', word_class: 'V' },
    { word: 'attestant', word_class: 'N' },
    { word: 'attested', word_class: 'AJ' },
    { word: 'attestation', word_class: 'N' }
  ],
  dangerousness: [
    { word: 'danger', word_class: 'N' },
    { word: 'dangerous', word_class: 'AJ' },
    { word: 'dangerously', word_class: 'AV' },
    { word: 'dangerousness', word_class: 'N' }
  ],
  counsel: [
    { word: 'counsel', word_class: 'N' },
    { word: 'counsel', word_class: 'V' },
    { word: 'counselor', word_class: 'N' },
    { word: 'counseling', word_class: 'N' }
  ],
  prototypical: [
    { word: 'prototype', word_class: 'N' },
    { word: 'prototype', word_class: 'V' },
    { word: 'prototypal', word_class: 'AJ' },
    { word: 'prototypic', word_class: 'AJ' },
    { word: 'prototypical', word_class: 'AJ' }
  ],
  archaeological: [
    { word: 'archaeology', word_class: 'N' },
    { word: 'archaeologic', word_class: 'AJ' },
    { word: 'archaeological', word_class: 'AJ' }
  ],
  dispossess: [
    { word: 'dispossess', word_class: 'V' },
    { word: 'dispossessed', word_class: 'AJ' },
    { word: 'dispossession', word_class: 'N' }
  ],
  demonetize: [
    { word: 'demonetise', word_class: 'V' },
    { word: 'demonetize', word_class: 'V' },
    { word: 'demonetisation', word_class: 'N' },
    { word: 'demonetization', word_class: 'N' }
  ],
  italianize: [
    { word: 'italian', word_class: 'N' },
    { word: 'italian', word_class: 'AJ' },
    { word: 'italianise', word_class: 'V' },
    { word: 'italianize', word_class: 'V' },
    { word: 'italianisation', word_class: 'N' },
    { word: 'italianization', word_class: 'N' }
  ],
  bray: [{ word: 'bray', word_class: 'N' }, { word: 'bray', word_class: 'V' }, { word: 'bray', word_class: 'AJ' }],
  bituminise: [
    { word: 'bituminise', word_class: 'V' },
    { word: 'bituminize', word_class: 'V' },
    { word: 'bituminous', word_class: 'AJ' },
    { word: 'bituminisation', word_class: 'N' },
    { word: 'bituminization', word_class: 'N' }
  ],
  armorer: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  electrodeposit: [
    { word: 'electrodeposit', word_class: 'V' },
    { word: 'elecrodeposition', word_class: 'N' },
    { word: 'electrodeposition', word_class: 'N' }
  ],
  proven: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  detrimental: [
    { word: 'detriment', word_class: 'N' },
    { word: 'detrimental', word_class: 'AJ' },
    { word: 'detrimentally', word_class: 'AV' }
  ],
  hydrolysis: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  arriving: [
    { word: 'arrive', word_class: 'V' },
    { word: 'arrival', word_class: 'N' },
    { word: 'arriver', word_class: 'N' },
    { word: 'arriving', word_class: 'AJ' }
  ],
  maroon: [
    { word: 'maroon', word_class: 'N' },
    { word: 'maroon', word_class: 'V' },
    { word: 'maroon', word_class: 'AJ' },
    { word: 'marooned', word_class: 'AJ' }
  ],
  elater: [
    { word: 'elate', word_class: 'V' },
    { word: 'elater', word_class: 'N' },
    { word: 'elated', word_class: 'AJ' },
    { word: 'elation', word_class: 'N' },
    { word: 'elating', word_class: 'AJ' }
  ],
  humanization: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  tragic: [
    { word: 'tragic', word_class: 'AJ' },
    { word: 'tragical', word_class: 'AJ' },
    { word: 'tragically', word_class: 'AV' }
  ],
  intensiveness: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  desirableness: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  conclusiveness: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  galvanisation: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  steal: [
    { word: 'steal', word_class: 'N' },
    { word: 'steal', word_class: 'V' },
    { word: 'stolen', word_class: 'AJ' },
    { word: 'stealing', word_class: 'N' }
  ],
  indefinite: [
    { word: 'indefinite', word_class: 'AJ' },
    { word: 'indefinitely', word_class: 'AV' },
    { word: 'indefiniteness', word_class: 'N' }
  ],
  go: [
    { word: 'go', word_class: 'N' },
    { word: 'go', word_class: 'V' },
    { word: 'go', word_class: 'AJ' },
    { word: 'goal', word_class: 'N' },
    { word: 'goer', word_class: 'N' },
    { word: 'going', word_class: 'N' },
    { word: 'gone', word_class: 'AJ' },
    { word: 'going', word_class: 'AJ' }
  ],
  mouldering: [
    { word: 'moulder', word_class: 'V' },
    { word: 'mouldered', word_class: 'AJ' },
    { word: 'mouldering', word_class: 'AJ' }
  ],
  abscondment: [
    { word: 'abscond', word_class: 'V' },
    { word: 'absconder', word_class: 'N' },
    { word: 'abscondment', word_class: 'N' }
  ],
  disfranchisement: [
    { word: 'disfranchise', word_class: 'V' },
    { word: 'disfranchised', word_class: 'AJ' },
    { word: 'disfranchisement', word_class: 'N' }
  ],
  smartness: [
    { word: 'smart', word_class: 'N' },
    { word: 'smart', word_class: 'V' },
    { word: 'smart', word_class: 'AJ' },
    { word: 'smarting', word_class: 'N' },
    { word: 'smarting', word_class: 'AJ' },
    { word: 'smartness', word_class: 'N' }
  ],
  encapsulate: [
    { word: 'encapsulate', word_class: 'V' },
    { word: 'incapsulate', word_class: 'V' },
    { word: 'encapsulation', word_class: 'N' },
    { word: 'incapsulation', word_class: 'N' }
  ],
  veneer: [
    { word: 'veneer', word_class: 'N' },
    { word: 'veneer', word_class: 'V' },
    { word: 'veneering', word_class: 'N' }
  ],
  stripping: [
    { word: 'strip', word_class: 'N' },
    { word: 'strip', word_class: 'V' },
    { word: 'strip', word_class: 'AJ' },
    { word: 'striper', word_class: 'N' },
    { word: 'stripped', word_class: 'AJ' },
    { word: 'stripping', word_class: 'N' }
  ],
  supervenience: [
    { word: 'supervene', word_class: 'V' },
    { word: 'supervention', word_class: 'N' },
    { word: 'supervenience', word_class: 'N' }
  ],
  supplier: [
    { word: 'supply', word_class: 'N' },
    { word: 'supply', word_class: 'V' },
    { word: 'supplier', word_class: 'N' },
    { word: 'supplies', word_class: 'N' },
    { word: 'suppliant', word_class: 'N' },
    { word: 'supplying', word_class: 'N' },
    { word: 'suppliant', word_class: 'AJ' }
  ],
  abscond: [
    { word: 'abscond', word_class: 'V' },
    { word: 'absconder', word_class: 'N' },
    { word: 'abscondment', word_class: 'N' }
  ],
  asker: [
    { word: 'ask', word_class: 'V' },
    { word: 'asker', word_class: 'N' },
    { word: 'asking', word_class: 'N' },
    { word: 'asking', word_class: 'AJ' }
  ],
  arranged: [
    { word: 'arrange', word_class: 'V' },
    { word: 'arranger', word_class: 'N' },
    { word: 'arranged', word_class: 'AJ' },
    { word: 'arranging', word_class: 'N' },
    { word: 'arrangement', word_class: 'N' }
  ],
  credulousness: [
    { word: 'credulity', word_class: 'N' },
    { word: 'credulous', word_class: 'AJ' },
    { word: 'credulously', word_class: 'AV' },
    { word: 'credulousness', word_class: 'N' }
  ],
  printed: [
    { word: 'print', word_class: 'N' },
    { word: 'print', word_class: 'V' },
    { word: 'print', word_class: 'AJ' },
    { word: 'printer', word_class: 'N' },
    { word: 'printed', word_class: 'AJ' },
    { word: 'printing', word_class: 'N' }
  ],
  boost: [
    { word: 'boost', word_class: 'N' },
    { word: 'boost', word_class: 'V' },
    { word: 'booster', word_class: 'N' },
    { word: 'boosters', word_class: 'N' }
  ],
  recommitment: [
    { word: 'recommit', word_class: 'V' },
    { word: 'recommittal', word_class: 'N' },
    { word: 'recommitment', word_class: 'N' }
  ],
  withdrawal: [
    { word: 'withdraw', word_class: 'V' },
    { word: 'withdrawal', word_class: 'N' },
    { word: 'withdrawn', word_class: 'AJ' },
    { word: 'withdrawing', word_class: 'AJ' },
    { word: 'withdrawnness', word_class: 'N' }
  ],
  ignobleness: [
    { word: 'ignoble', word_class: 'AJ' },
    { word: 'ignobly', word_class: 'AV' },
    { word: 'ignobleness', word_class: 'N' }
  ],
  vulcanized: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  articulateness: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  stallion: [
    { word: 'stall', word_class: 'N' },
    { word: 'stall', word_class: 'V' },
    { word: 'stalls', word_class: 'N' },
    { word: 'stalling', word_class: 'N' },
    { word: 'stallion', word_class: 'N' }
  ],
  haploidic: [
    { word: 'haploid', word_class: 'N' },
    { word: 'haploid', word_class: 'AJ' },
    { word: 'haploidic', word_class: 'AJ' }
  ],
  flick: [
    { word: 'flick', word_class: 'N' },
    { word: 'flick', word_class: 'V' },
    { word: 'flicker', word_class: 'N' },
    { word: 'flicker', word_class: 'V' },
    { word: 'flickering', word_class: 'AJ' }
  ],
  fanned: [
    { word: 'fan', word_class: 'N' },
    { word: 'fan', word_class: 'V' },
    { word: 'fanion', word_class: 'N' },
    { word: 'fanned', word_class: 'AJ' }
  ],
  ignited: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  yowl: [{ word: 'yowl', word_class: 'N' }, { word: 'yowl', word_class: 'V' }, { word: 'yowling', word_class: 'N' }],
  growler: [
    { word: 'growl', word_class: 'N' },
    { word: 'growl', word_class: 'V' },
    { word: 'growler', word_class: 'N' },
    { word: 'growling', word_class: 'N' }
  ],
  backspace: [
    { word: 'backspace', word_class: 'N' },
    { word: 'backspace', word_class: 'V' },
    { word: 'backspacer', word_class: 'N' }
  ],
  stumble: [
    { word: 'stumble', word_class: 'N' },
    { word: 'stumble', word_class: 'V' },
    { word: 'stumbling', word_class: 'AJ' }
  ],
  present: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  edgy: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  reproducibility: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  decoder: [
    { word: 'decode', word_class: 'V' },
    { word: 'decoder', word_class: 'N' },
    { word: 'decoding', word_class: 'N' }
  ],
  plurality: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  sedated: [
    { word: 'sedate', word_class: 'V' },
    { word: 'sedate', word_class: 'AJ' },
    { word: 'sedated', word_class: 'AJ' },
    { word: 'sedation', word_class: 'N' },
    { word: 'sedately', word_class: 'AV' },
    { word: 'sedateness', word_class: 'N' }
  ],
  infrequency: [
    { word: 'infrequency', word_class: 'N' },
    { word: 'infrequent', word_class: 'AJ' },
    { word: 'infrequently', word_class: 'AV' }
  ],
  pebbly: [
    { word: 'pebble', word_class: 'N' },
    { word: 'pebbly', word_class: 'AJ' },
    { word: 'pebbled', word_class: 'AJ' }
  ],
  commensally: [
    { word: 'commensal', word_class: 'N' },
    { word: 'commensal', word_class: 'AJ' },
    { word: 'commensalism', word_class: 'N' },
    { word: 'commensally', word_class: 'AV' }
  ],
  dilate: [
    { word: 'dilate', word_class: 'V' },
    { word: 'dilate', word_class: 'AJ' },
    { word: 'dilated', word_class: 'AJ' },
    { word: 'dilation', word_class: 'N' },
    { word: 'dilatation', word_class: 'N' }
  ],
  pusillanimous: [
    { word: 'pusillanimity', word_class: 'N' },
    { word: 'pusillanimous', word_class: 'AJ' },
    { word: 'pusillanimously', word_class: 'AV' },
    { word: 'pusillanimousness', word_class: 'N' }
  ],
  challenging: [
    { word: 'challenge', word_class: 'N' },
    { word: 'challenge', word_class: 'V' },
    { word: 'challenger', word_class: 'N' },
    { word: 'challenging', word_class: 'AJ' },
    { word: 'challengeable', word_class: 'AJ' }
  ],
  disorganized: [
    { word: 'disorganize', word_class: 'V' },
    { word: 'disorganized', word_class: 'AJ' },
    { word: 'disorganisation', word_class: 'N' },
    { word: 'disorganization', word_class: 'N' }
  ],
  transcendent: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  susurrous: [
    { word: 'susurrate', word_class: 'V' },
    { word: 'susurrant', word_class: 'AJ' },
    { word: 'susurrous', word_class: 'AJ' },
    { word: 'susurration', word_class: 'N' }
  ],
  assertion: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  hallucinating: [
    { word: 'hallucinate', word_class: 'V' },
    { word: 'hallucination', word_class: 'N' },
    { word: 'hallucinating', word_class: 'AJ' }
  ],
  stockpiling: [
    { word: 'stockpile', word_class: 'N' },
    { word: 'stockpile', word_class: 'V' },
    { word: 'stockpiling', word_class: 'N' }
  ],
  idealist: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  raving: [
    { word: 'rave', word_class: 'N' },
    { word: 'rave', word_class: 'V' },
    { word: 'raver', word_class: 'N' },
    { word: 'raving', word_class: 'N' },
    { word: 'raving', word_class: 'AJ' },
    { word: 'raving', word_class: 'AV' }
  ],
  enchanting: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  millions: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  deceitful: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  americanisation: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  attraction: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  leverage: [
    { word: 'lever', word_class: 'N' },
    { word: 'lever', word_class: 'V' },
    { word: 'leverage', word_class: 'N' },
    { word: 'leverage', word_class: 'V' },
    { word: 'leveraging', word_class: 'N' }
  ],
  desire: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  inunction: [
    { word: 'anoint', word_class: 'V' },
    { word: 'anointing', word_class: 'N' },
    { word: 'inunction', word_class: 'N' },
    { word: 'anointment', word_class: 'N' }
  ],
  cobblestone: [
    { word: 'cobblestone', word_class: 'N' },
    { word: 'cobblestone', word_class: 'AJ' },
    { word: 'cobblestoned', word_class: 'AJ' }
  ],
  obstinance: [
    { word: 'obstinacy', word_class: 'N' },
    { word: 'obstinance', word_class: 'N' },
    { word: 'obstinate', word_class: 'AJ' },
    { word: 'obstinately', word_class: 'AV' }
  ],
  resolute: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  accumulative: [
    { word: 'accumulate', word_class: 'V' },
    { word: 'accumulator', word_class: 'N' },
    { word: 'accumulated', word_class: 'AJ' },
    { word: 'accumulation', word_class: 'N' },
    { word: 'accumulative', word_class: 'AJ' }
  ],
  prod: [{ word: 'prod', word_class: 'N' }, { word: 'prod', word_class: 'V' }, { word: 'prodding', word_class: 'N' }],
  chimerical: [
    { word: 'chimeral', word_class: 'AJ' },
    { word: 'chimeric', word_class: 'AJ' },
    { word: 'chimerical', word_class: 'AJ' }
  ],
  angelically: [
    { word: 'angel', word_class: 'N' },
    { word: 'angelic', word_class: 'AJ' },
    { word: 'angelical', word_class: 'AJ' },
    { word: 'angelically', word_class: 'AV' }
  ],
  hebdomadally: [
    { word: 'hebdomad', word_class: 'N' },
    { word: 'hebdomadal', word_class: 'AJ' },
    { word: 'hebdomadally', word_class: 'AV' }
  ],
  diametrical: [
    { word: 'diameter', word_class: 'N' },
    { word: 'diametral', word_class: 'AJ' },
    { word: 'diametric', word_class: 'AJ' },
    { word: 'diametrical', word_class: 'AJ' },
    { word: 'diametrically', word_class: 'AV' }
  ],
  geologize: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  wine: [
    { word: 'wine', word_class: 'N' },
    { word: 'wine', word_class: 'V' },
    { word: 'winy', word_class: 'AJ' },
    { word: 'winery', word_class: 'N' },
    { word: 'winemaker', word_class: 'N' },
    { word: 'winemaking', word_class: 'N' }
  ],
  harrowing: [
    { word: 'harrow', word_class: 'N' },
    { word: 'harrow', word_class: 'V' },
    { word: 'harrowing', word_class: 'AJ' },
    { word: 'harrowment', word_class: 'N' }
  ],
  dismantlement: [
    { word: 'dismantle', word_class: 'N' },
    { word: 'dismantle', word_class: 'V' },
    { word: 'dismantled', word_class: 'AJ' },
    { word: 'dismantling', word_class: 'N' },
    { word: 'dismantlement', word_class: 'N' }
  ],
  oscillator: [
    { word: 'oscillate', word_class: 'V' },
    { word: 'oscillator', word_class: 'N' },
    { word: 'oscillation', word_class: 'N' },
    { word: 'oscillating', word_class: 'AJ' }
  ],
  availability: [
    { word: 'avail', word_class: 'N' },
    { word: 'avail', word_class: 'V' },
    { word: 'available', word_class: 'AJ' },
    { word: 'availability', word_class: 'N' },
    { word: 'availableness', word_class: 'N' }
  ],
  quizzes: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  irritating: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  suckling: [
    { word: 'suckle', word_class: 'V' },
    { word: 'suckled', word_class: 'AJ' },
    { word: 'suckling', word_class: 'N' }
  ],
  tepidity: [
    { word: 'tepid', word_class: 'AJ' },
    { word: 'tepidity', word_class: 'N' },
    { word: 'tepidness', word_class: 'N' }
  ],
  expand: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  desexualise: [
    { word: 'desexualise', word_class: 'V' },
    { word: 'desexualize', word_class: 'V' },
    { word: 'desexualisation', word_class: 'N' },
    { word: 'desexualization', word_class: 'N' }
  ],
  veiling: [
    { word: 'veil', word_class: 'N' },
    { word: 'veil', word_class: 'V' },
    { word: 'veiled', word_class: 'AJ' },
    { word: 'veiling', word_class: 'N' }
  ],
  chairman: [
    { word: 'chair', word_class: 'N' },
    { word: 'chair', word_class: 'V' },
    { word: 'chairman', word_class: 'N' },
    { word: 'chairman', word_class: 'V' }
  ],
  refect: [
    { word: 'refect', word_class: 'V' },
    { word: 'refection', word_class: 'N' },
    { word: 'refectory', word_class: 'N' },
    { word: 'refectory', word_class: 'AJ' }
  ],
  estimated: [
    { word: 'estimate', word_class: 'N' },
    { word: 'estimate', word_class: 'V' },
    { word: 'estimator', word_class: 'N' },
    { word: 'estimable', word_class: 'AJ' },
    { word: 'estimated', word_class: 'AJ' },
    { word: 'estimation', word_class: 'N' }
  ],
  nowness: [{ word: 'now', word_class: 'N' }, { word: 'now', word_class: 'AV' }, { word: 'nowness', word_class: 'N' }],
  snuggled: [
    { word: 'snuggle', word_class: 'N' },
    { word: 'snuggle', word_class: 'V' },
    { word: 'snuggly', word_class: 'AJ' },
    { word: 'snuggled', word_class: 'AJ' },
    { word: 'snuggling', word_class: 'N' }
  ],
  study: [
    { word: 'study', word_class: 'N' },
    { word: 'study', word_class: 'V' },
    { word: 'studied', word_class: 'AJ' },
    { word: 'studying', word_class: 'N' }
  ],
  genealogically: [
    { word: 'genealogy', word_class: 'N' },
    { word: 'genealogic', word_class: 'AJ' },
    { word: 'genealogical', word_class: 'AJ' },
    { word: 'genealogically', word_class: 'AV' }
  ],
  indeterminably: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  past: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  ga: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  peregrine: [
    { word: 'peregrine', word_class: 'N' },
    { word: 'peregrine', word_class: 'AJ' },
    { word: 'peregrinate', word_class: 'V' },
    { word: 'peregrination', word_class: 'N' }
  ],
  oversubscription: [
    { word: 'oversubscribe', word_class: 'V' },
    { word: 'oversubscribed', word_class: 'AJ' },
    { word: 'oversubscription', word_class: 'N' }
  ],
  vindication: [
    { word: 'vindicate', word_class: 'V' },
    { word: 'vindicator', word_class: 'N' },
    { word: 'vindicable', word_class: 'AJ' },
    { word: 'vindicated', word_class: 'AJ' },
    { word: 'vindication', word_class: 'N' }
  ],
  mediatization: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  jacks: [{ word: 'jack', word_class: 'N' }, { word: 'jack', word_class: 'V' }, { word: 'jacks', word_class: 'N' }],
  bacteriological: [
    { word: 'bacteriology', word_class: 'N' },
    { word: 'bacteriologic', word_class: 'AJ' },
    { word: 'bacteriological', word_class: 'AJ' }
  ],
  relaxant: [
    { word: 'relax', word_class: 'V' },
    { word: 'relax', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'N' },
    { word: 'relaxed', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'AJ' },
    { word: 'relaxing', word_class: 'AJ' },
    { word: 'relaxation', word_class: 'N' }
  ],
  recordation: [
    { word: 'record', word_class: 'N' },
    { word: 'record', word_class: 'V' },
    { word: 'record', word_class: 'AJ' },
    { word: 'recorder', word_class: 'N' },
    { word: 'recording', word_class: 'N' },
    { word: 'recorded', word_class: 'AJ' },
    { word: 'recordation', word_class: 'N' }
  ],
  technological: [
    { word: 'technology', word_class: 'N' },
    { word: 'technological', word_class: 'AJ' },
    { word: 'technologically', word_class: 'AV' }
  ],
  seventy: [
    { word: 'seventy', word_class: 'N' },
    { word: 'seventy', word_class: 'AJ' },
    { word: 'seventies', word_class: 'N' }
  ],
  cook: [
    { word: 'cook', word_class: 'N' },
    { word: 'cook', word_class: 'V' },
    { word: 'cook', word_class: 'AJ' },
    { word: 'cooker', word_class: 'N' },
    { word: 'cookery', word_class: 'N' },
    { word: 'cooking', word_class: 'N' },
    { word: 'cooked', word_class: 'AJ' },
    { word: 'cookery', word_class: 'AJ' }
  ],
  caroler: [
    { word: 'carol', word_class: 'N' },
    { word: 'carol', word_class: 'V' },
    { word: 'caroler', word_class: 'N' },
    { word: 'caroling', word_class: 'N' },
    { word: 'caroller', word_class: 'N' }
  ],
  stabilize: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  fierceness: [
    { word: 'fierce', word_class: 'AJ' },
    { word: 'fiercely', word_class: 'AV' },
    { word: 'fierceness', word_class: 'N' }
  ],
  jets: [
    { word: 'jet', word_class: 'N' },
    { word: 'jet', word_class: 'V' },
    { word: 'jet', word_class: 'AJ' },
    { word: 'jets', word_class: 'N' },
    { word: 'jetting', word_class: 'AJ' }
  ],
  idiomatically: [
    { word: 'idiomatic', word_class: 'AJ' },
    { word: 'idiomatical', word_class: 'AJ' },
    { word: 'idiomatically', word_class: 'AV' }
  ],
  confrontation: [
    { word: 'confront', word_class: 'V' },
    { word: 'confronting', word_class: 'N' },
    { word: 'confrontment', word_class: 'N' },
    { word: 'confrontation', word_class: 'N' },
    { word: 'confrontational', word_class: 'AJ' }
  ],
  enliven: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  enticing: [
    { word: 'entice', word_class: 'V' },
    { word: 'enticing', word_class: 'AJ' },
    { word: 'enticement', word_class: 'N' }
  ],
  artistically: [
    { word: 'artist', word_class: 'N' },
    { word: 'artiste', word_class: 'N' },
    { word: 'artistic', word_class: 'AJ' },
    { word: 'artistically', word_class: 'AV' }
  ],
  disqualification: [
    { word: 'disqualify', word_class: 'V' },
    { word: 'disqualified', word_class: 'AJ' },
    { word: 'disqualifying', word_class: 'N' },
    { word: 'disqualifying', word_class: 'AJ' },
    { word: 'disqualification', word_class: 'N' }
  ],
  birle: [{ word: 'birl', word_class: 'V' }, { word: 'birle', word_class: 'V' }, { word: 'birling', word_class: 'N' }],
  perplexing: [
    { word: 'perplex', word_class: 'V' },
    { word: 'perplexed', word_class: 'AJ' },
    { word: 'perplexity', word_class: 'N' },
    { word: 'perplexing', word_class: 'AJ' }
  ],
  superintend: [
    { word: 'superintend', word_class: 'V' },
    { word: 'superintendent', word_class: 'N' },
    { word: 'superintendence', word_class: 'N' },
    { word: 'superintendent', word_class: 'AJ' }
  ],
  honeymooner: [
    { word: 'honeymoon', word_class: 'N' },
    { word: 'honeymoon', word_class: 'V' },
    { word: 'honeymooner', word_class: 'N' }
  ],
  legislating: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  diabolize: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  admonitory: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  cage: [
    { word: 'cage', word_class: 'N' },
    { word: 'cage', word_class: 'V' },
    { word: 'cager', word_class: 'N' },
    { word: 'caged', word_class: 'AJ' }
  ],
  ripely: [
    { word: 'ripe', word_class: 'AJ' },
    { word: 'ripely', word_class: 'AV' },
    { word: 'ripeness', word_class: 'N' }
  ],
  cumulative: [
    { word: 'cumulate', word_class: 'V' },
    { word: 'cumulation', word_class: 'N' },
    { word: 'cumulative', word_class: 'AJ' },
    { word: 'cumulatively', word_class: 'AV' }
  ],
  switch: [
    { word: 'switch', word_class: 'N' },
    { word: 'switch', word_class: 'V' },
    { word: 'switch', word_class: 'AJ' },
    { word: 'switching', word_class: 'N' },
    { word: 'switchman', word_class: 'N' }
  ],
  futility: [
    { word: 'futile', word_class: 'AJ' },
    { word: 'futility', word_class: 'N' },
    { word: 'futilely', word_class: 'AV' }
  ],
  dragee: [
    { word: 'drag', word_class: 'N' },
    { word: 'drag', word_class: 'V' },
    { word: 'dragee', word_class: 'N' },
    { word: 'dragging', word_class: 'AJ' }
  ],
  duplicability: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  consanguine: [
    { word: 'consanguine', word_class: 'AJ' },
    { word: 'consanguinity', word_class: 'N' },
    { word: 'consanguineous', word_class: 'AJ' }
  ],
  inebriated: [
    { word: 'inebriant', word_class: 'N' },
    { word: 'inebriate', word_class: 'N' },
    { word: 'inebriate', word_class: 'V' },
    { word: 'inebriated', word_class: 'AJ' },
    { word: 'inebriation', word_class: 'N' }
  ],
  maraud: [
    { word: 'maraud', word_class: 'N' },
    { word: 'maraud', word_class: 'V' },
    { word: 'marauder', word_class: 'N' },
    { word: 'marauding', word_class: 'N' },
    { word: 'marauding', word_class: 'AJ' }
  ],
  aeronaut: [
    { word: 'aeronaut', word_class: 'N' },
    { word: 'aeronautic', word_class: 'AJ' },
    { word: 'aeronautics', word_class: 'N' },
    { word: 'aeronautical', word_class: 'AJ' }
  ],
  particularity: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  violence: [
    { word: 'violence', word_class: 'N' },
    { word: 'violent', word_class: 'AJ' },
    { word: 'violently', word_class: 'AV' }
  ],
  'harum-scarum': [
    { word: 'harum-scarum', word_class: 'N' },
    { word: 'harum-scarum', word_class: 'AJ' },
    { word: 'harum-scarum', word_class: 'AV' }
  ],
  invocate: [
    { word: 'invoke', word_class: 'V' },
    { word: 'invocate', word_class: 'V' },
    { word: 'invocation', word_class: 'N' }
  ],
  ectoderm: [
    { word: 'ectoderm', word_class: 'N' },
    { word: 'ectodermal', word_class: 'AJ' },
    { word: 'ectodermic', word_class: 'AJ' }
  ],
  gasp: [{ word: 'gasp', word_class: 'N' }, { word: 'gasp', word_class: 'V' }, { word: 'gasping', word_class: 'AJ' }],
  illogical: [
    { word: 'illogic', word_class: 'N' },
    { word: 'illogical', word_class: 'AJ' },
    { word: 'illogicality', word_class: 'N' },
    { word: 'illogically', word_class: 'AV' }
  ],
  cohesive: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  appointee: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  nosed: [
    { word: 'nose', word_class: 'N' },
    { word: 'nose', word_class: 'V' },
    { word: 'nosy', word_class: 'AJ' },
    { word: 'nosed', word_class: 'AJ' },
    { word: 'nosiness', word_class: 'N' }
  ],
  synchronous: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  recondite: [
    { word: 'recondite', word_class: 'AJ' },
    { word: 'recondition', word_class: 'V' },
    { word: 'reconditeness', word_class: 'N' },
    { word: 'reconditioned', word_class: 'AJ' }
  ],
  mount: [
    { word: 'mount', word_class: 'N' },
    { word: 'mount', word_class: 'V' },
    { word: 'mounted', word_class: 'AJ' },
    { word: 'mounting', word_class: 'N' }
  ],
  maltreat: [
    { word: 'maltreat', word_class: 'V' },
    { word: 'maltreated', word_class: 'AJ' },
    { word: 'maltreatment', word_class: 'N' }
  ],
  tantalization: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  affective: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  premiership: [
    { word: 'premier', word_class: 'N' },
    { word: 'premier', word_class: 'V' },
    { word: 'premiere', word_class: 'N' },
    { word: 'premier', word_class: 'AJ' },
    { word: 'premiere', word_class: 'V' },
    { word: 'premiere', word_class: 'AJ' },
    { word: 'premiership', word_class: 'N' }
  ],
  symmetrical: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  extoll: [
    { word: 'extol', word_class: 'V' },
    { word: 'extoll', word_class: 'V' },
    { word: 'extoller', word_class: 'N' },
    { word: 'extolment', word_class: 'N' }
  ],
  sometime: [
    { word: 'sometime', word_class: 'AJ' },
    { word: 'sometime', word_class: 'AV' },
    { word: 'sometimes', word_class: 'AV' }
  ],
  singles: [
    { word: 'single', word_class: 'N' },
    { word: 'single', word_class: 'V' },
    { word: 'single', word_class: 'AJ' },
    { word: 'singles', word_class: 'N' },
    { word: 'singly', word_class: 'AV' },
    { word: 'singleness', word_class: 'N' }
  ],
  blinker: [
    { word: 'blink', word_class: 'N' },
    { word: 'blink', word_class: 'V' },
    { word: 'blinks', word_class: 'N' },
    { word: 'blinker', word_class: 'N' },
    { word: 'blinker', word_class: 'V' },
    { word: 'blinking', word_class: 'AJ' }
  ],
  juxtaposed: [
    { word: 'juxtapose', word_class: 'V' },
    { word: 'juxtaposed', word_class: 'AJ' },
    { word: 'juxtaposition', word_class: 'N' }
  ],
  winery: [
    { word: 'wine', word_class: 'N' },
    { word: 'wine', word_class: 'V' },
    { word: 'winy', word_class: 'AJ' },
    { word: 'winery', word_class: 'N' },
    { word: 'winemaker', word_class: 'N' },
    { word: 'winemaking', word_class: 'N' }
  ],
  cinchonisation: [
    { word: 'cinchonise', word_class: 'V' },
    { word: 'cinchonize', word_class: 'V' },
    { word: 'cinchonisation', word_class: 'N' },
    { word: 'cinchonization', word_class: 'N' }
  ],
  invariably: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  gravidness: [
    { word: 'gravid', word_class: 'AJ' },
    { word: 'gravidity', word_class: 'N' },
    { word: 'gravidness', word_class: 'N' }
  ],
  ecumenism: [
    { word: 'ecumenic', word_class: 'AJ' },
    { word: 'ecumenism', word_class: 'N' },
    { word: 'ecumenical', word_class: 'AJ' },
    { word: 'ecumenicalism', word_class: 'N' }
  ],
  reciprocate: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  financially: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  amorousness: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  inspirational: [
    { word: 'inspire', word_class: 'N' },
    { word: 'inspire', word_class: 'V' },
    { word: 'inspired', word_class: 'AJ' },
    { word: 'inspiring', word_class: 'AJ' },
    { word: 'inspiration', word_class: 'N' },
    { word: 'inspirational', word_class: 'AJ' }
  ],
  hospitalize: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  wreath: [
    { word: 'wreath', word_class: 'N' },
    { word: 'wreath', word_class: 'V' },
    { word: 'wreathe', word_class: 'V' },
    { word: 'wreathed', word_class: 'AJ' }
  ],
  aerosolize: [
    { word: 'aerosol', word_class: 'N' },
    { word: 'aerosolize', word_class: 'V' },
    { word: 'aerosolized', word_class: 'AJ' }
  ],
  corrosion: [
    { word: 'corrode', word_class: 'V' },
    { word: 'corroded', word_class: 'AJ' },
    { word: 'corroding', word_class: 'N' },
    { word: 'corrosion', word_class: 'N' },
    { word: 'corrosive', word_class: 'N' },
    { word: 'corrosive', word_class: 'AJ' }
  ],
  decrease: [
    { word: 'decrease', word_class: 'N' },
    { word: 'decrease', word_class: 'V' },
    { word: 'decreased', word_class: 'AJ' },
    { word: 'decreasing', word_class: 'AJ' }
  ],
  corking: [
    { word: 'cork', word_class: 'N' },
    { word: 'cork', word_class: 'V' },
    { word: 'corker', word_class: 'N' },
    { word: 'corked', word_class: 'AJ' },
    { word: 'corking', word_class: 'AJ' }
  ],
  impede: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  embarrassed: [
    { word: 'embarrass', word_class: 'V' },
    { word: 'embarrassed', word_class: 'AJ' },
    { word: 'embarrassing', word_class: 'AJ' },
    { word: 'embarrassment', word_class: 'N' }
  ],
  adopt: [
    { word: 'adopt', word_class: 'V' },
    { word: 'adopted', word_class: 'AJ' },
    { word: 'adoption', word_class: 'N' },
    { word: 'adoptive', word_class: 'AJ' },
    { word: 'adoptable', word_class: 'AJ' }
  ],
  luge: [{ word: 'luge', word_class: 'N' }, { word: 'luge', word_class: 'V' }, { word: 'luging', word_class: 'N' }],
  adorn: [
    { word: 'adorn', word_class: 'V' },
    { word: 'adorned', word_class: 'AJ' },
    { word: 'adornment', word_class: 'N' }
  ],
  impeccably: [
    { word: 'impeccant', word_class: 'AJ' },
    { word: 'impeccable', word_class: 'AJ' },
    { word: 'impeccably', word_class: 'AV' }
  ],
  theologize: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  commuting: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  imperil: [
    { word: 'imperil', word_class: 'N' },
    { word: 'imperil', word_class: 'V' },
    { word: 'imperilment', word_class: 'N' }
  ],
  neglectfulness: [
    { word: 'neglect', word_class: 'N' },
    { word: 'neglect', word_class: 'V' },
    { word: 'neglected', word_class: 'AJ' },
    { word: 'neglectful', word_class: 'AJ' },
    { word: 'neglectfulness', word_class: 'N' }
  ],
  gaff: [
    { word: 'gaff', word_class: 'N' },
    { word: 'gaff', word_class: 'V' },
    { word: 'gaffe', word_class: 'N' },
    { word: 'gaffer', word_class: 'N' }
  ],
  supersession: [
    { word: 'supersede', word_class: 'V' },
    { word: 'supersedure', word_class: 'N' },
    { word: 'supersession', word_class: 'N' }
  ],
  lamely: [
    { word: 'lame', word_class: 'N' },
    { word: 'lame', word_class: 'V' },
    { word: 'lame', word_class: 'AJ' },
    { word: 'lamely', word_class: 'AV' },
    { word: 'lameness', word_class: 'N' }
  ],
  grammatic: [
    { word: 'grammatic', word_class: 'AJ' },
    { word: 'grammatical', word_class: 'AJ' },
    { word: 'grammatically', word_class: 'AV' }
  ],
  federalize: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  discursively: [
    { word: 'discursive', word_class: 'AJ' },
    { word: 'discursively', word_class: 'AV' },
    { word: 'discursiveness', word_class: 'N' }
  ],
  vexed: [
    { word: 'vex', word_class: 'V' },
    { word: 'vexed', word_class: 'AJ' },
    { word: 'vexing', word_class: 'AJ' },
    { word: 'vexation', word_class: 'N' },
    { word: 'vexatious', word_class: 'AJ' }
  ],
  watcher: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  misappropriation: [
    { word: 'misappropriate', word_class: 'V' },
    { word: 'misappropriated', word_class: 'AJ' },
    { word: 'misappropriation', word_class: 'N' }
  ],
  suction: [
    { word: 'suck', word_class: 'N' },
    { word: 'suck', word_class: 'V' },
    { word: 'sucker', word_class: 'N' },
    { word: 'sucking', word_class: 'N' },
    { word: 'suction', word_class: 'N' },
    { word: 'suction', word_class: 'V' }
  ],
  owe: [{ word: 'owe', word_class: 'V' }, { word: 'owed', word_class: 'AJ' }, { word: 'owing', word_class: 'AJ' }],
  submissiveness: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  jointure: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  progress: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  bristled: [
    { word: 'bristle', word_class: 'N' },
    { word: 'bristle', word_class: 'V' },
    { word: 'bristled', word_class: 'AJ' }
  ],
  militarily: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  feudalization: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  savoring: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  chemic: [
    { word: 'chemic', word_class: 'AJ' },
    { word: 'chemical', word_class: 'N' },
    { word: 'chemical', word_class: 'AJ' },
    { word: 'chemically', word_class: 'AV' }
  ],
  explication: [
    { word: 'explicate', word_class: 'V' },
    { word: 'explicable', word_class: 'AJ' },
    { word: 'explication', word_class: 'N' }
  ],
  receptive: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  lag: [
    { word: 'lag', word_class: 'N' },
    { word: 'lag', word_class: 'V' },
    { word: 'lager', word_class: 'N' },
    { word: 'lagging', word_class: 'N' }
  ],
  pare: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  panhandle: [
    { word: 'panhandle', word_class: 'N' },
    { word: 'panhandle', word_class: 'V' },
    { word: 'panhandler', word_class: 'N' }
  ],
  prearranged: [
    { word: 'prearrange', word_class: 'V' },
    { word: 'prearranged', word_class: 'AJ' },
    { word: 'prearrangement', word_class: 'N' }
  ],
  photographically: [
    { word: 'photograph', word_class: 'N' },
    { word: 'photograph', word_class: 'V' },
    { word: 'photographer', word_class: 'N' },
    { word: 'photographic', word_class: 'AJ' },
    { word: 'photographically', word_class: 'AV' }
  ],
  impersonalization: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  sphere: [
    { word: 'sphere', word_class: 'N' },
    { word: 'spheroid', word_class: 'N' },
    { word: 'spheroid', word_class: 'AJ' },
    { word: 'spheroidal', word_class: 'AJ' }
  ],
  homiletic: [
    { word: 'homiletic', word_class: 'AJ' },
    { word: 'homiletics', word_class: 'N' },
    { word: 'homiletical', word_class: 'AJ' }
  ],
  agreement: [
    { word: 'agree', word_class: 'V' },
    { word: 'agreed', word_class: 'AJ' },
    { word: 'agreeing', word_class: 'AJ' },
    { word: 'agreement', word_class: 'N' }
  ],
  modeled: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  pronunciation: [
    { word: 'pronounce', word_class: 'V' },
    { word: 'pronounced', word_class: 'AJ' },
    { word: 'pronouncement', word_class: 'N' },
    { word: 'pronunciation', word_class: 'N' },
    { word: 'pronounceable', word_class: 'AJ' }
  ],
  slim: [{ word: 'slim', word_class: 'V' }, { word: 'slim', word_class: 'AJ' }, { word: 'slimness', word_class: 'N' }],
  pianistic: [
    { word: 'piano', word_class: 'N' },
    { word: 'piano', word_class: 'AJ' },
    { word: 'piano', word_class: 'AV' },
    { word: 'pianist', word_class: 'N' },
    { word: 'pianistic', word_class: 'AJ' }
  ],
  admonishment: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  cubical: [
    { word: 'cubic', word_class: 'AJ' },
    { word: 'cubical', word_class: 'AJ' },
    { word: 'cubicity', word_class: 'N' }
  ],
  telescopic: [
    { word: 'telescope', word_class: 'N' },
    { word: 'telescope', word_class: 'V' },
    { word: 'telescoped', word_class: 'AJ' },
    { word: 'telescopic', word_class: 'AJ' },
    { word: 'telescopically', word_class: 'AV' }
  ],
  abused: [
    { word: 'abuse', word_class: 'N' },
    { word: 'abuse', word_class: 'V' },
    { word: 'abuser', word_class: 'N' },
    { word: 'abused', word_class: 'AJ' },
    { word: 'abusive', word_class: 'AJ' },
    { word: 'abusively', word_class: 'AV' }
  ],
  strong: [
    { word: 'strong', word_class: 'AJ' },
    { word: 'strengthen', word_class: 'V' },
    { word: 'strengthener', word_class: 'N' },
    { word: 'strengthened', word_class: 'AJ' },
    { word: 'strengthening', word_class: 'N' },
    { word: 'strengthening', word_class: 'AJ' }
  ],
  demeaning: [
    { word: 'demean', word_class: 'V' },
    { word: 'demeanor', word_class: 'N' },
    { word: 'demeaning', word_class: 'AJ' }
  ],
  motoring: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  economical: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  unacceptability: [
    { word: 'unaccepted', word_class: 'AJ' },
    { word: 'unacceptable', word_class: 'AJ' },
    { word: 'unacceptably', word_class: 'AV' },
    { word: 'unacceptability', word_class: 'N' },
    { word: 'unacceptableness', word_class: 'N' }
  ],
  enquire: [
    { word: 'enquire', word_class: 'V' },
    { word: 'enquiry', word_class: 'N' },
    { word: 'enquirer', word_class: 'N' }
  ],
  unfastening: [
    { word: 'unfasten', word_class: 'V' },
    { word: 'unfastened', word_class: 'AJ' },
    { word: 'unfastening', word_class: 'N' }
  ],
  bluffness: [
    { word: 'bluff', word_class: 'N' },
    { word: 'bluff', word_class: 'V' },
    { word: 'bluff', word_class: 'AJ' },
    { word: 'bluffer', word_class: 'N' },
    { word: 'bluffness', word_class: 'N' }
  ],
  theologically: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  skivvy: [
    { word: 'skivvy', word_class: 'N' },
    { word: 'skivvy', word_class: 'V' },
    { word: 'skivvies', word_class: 'N' }
  ],
  inspire: [
    { word: 'inspire', word_class: 'N' },
    { word: 'inspire', word_class: 'V' },
    { word: 'inspired', word_class: 'AJ' },
    { word: 'inspiring', word_class: 'AJ' },
    { word: 'inspiration', word_class: 'N' },
    { word: 'inspirational', word_class: 'AJ' }
  ],
  headline: [
    { word: 'headline', word_class: 'N' },
    { word: 'headline', word_class: 'V' },
    { word: 'headliner', word_class: 'N' }
  ],
  paneling: [
    { word: 'panel', word_class: 'N' },
    { word: 'panel', word_class: 'V' },
    { word: 'paneling', word_class: 'N' },
    { word: 'panelist', word_class: 'N' },
    { word: 'paneled', word_class: 'AJ' },
    { word: 'panelling', word_class: 'N' }
  ],
  respect: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  variability: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  frolicky: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  rank: [
    { word: 'rank', word_class: 'N' },
    { word: 'rank', word_class: 'V' },
    { word: 'rank', word_class: 'AJ' },
    { word: 'ranker', word_class: 'N' },
    { word: 'ranked', word_class: 'AJ' },
    { word: 'ranking', word_class: 'N' },
    { word: 'ranking', word_class: 'AJ' },
    { word: 'rankness', word_class: 'N' }
  ],
  reek: [{ word: 'reek', word_class: 'N' }, { word: 'reek', word_class: 'V' }, { word: 'reeking', word_class: 'AJ' }],
  undressed: [
    { word: 'undress', word_class: 'N' },
    { word: 'undress', word_class: 'V' },
    { word: 'undressed', word_class: 'AJ' }
  ],
  dispatch: [
    { word: 'dispatch', word_class: 'N' },
    { word: 'dispatch', word_class: 'V' },
    { word: 'dispatcher', word_class: 'N' },
    { word: 'dispatched', word_class: 'AJ' }
  ],
  activate: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  scores: [
    { word: 'score', word_class: 'N' },
    { word: 'score', word_class: 'V' },
    { word: 'score', word_class: 'AJ' },
    { word: 'scorer', word_class: 'N' },
    { word: 'scores', word_class: 'N' }
  ],
  cooing: [{ word: 'coo', word_class: 'N' }, { word: 'coo', word_class: 'V' }, { word: 'cooing', word_class: 'AJ' }],
  police: [
    { word: 'police', word_class: 'N' },
    { word: 'police', word_class: 'V' },
    { word: 'policeman', word_class: 'N' }
  ],
  farmer: [
    { word: 'farm', word_class: 'N' },
    { word: 'farm', word_class: 'V' },
    { word: 'farm', word_class: 'AJ' },
    { word: 'farmer', word_class: 'N' },
    { word: 'farming', word_class: 'N' },
    { word: 'farming', word_class: 'AJ' }
  ],
  recoverer: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  specialist: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  response: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  undies: [
    { word: 'undies', word_class: 'N' },
    { word: 'undyed', word_class: 'AJ' },
    { word: 'undying', word_class: 'AJ' }
  ],
  gearing: [
    { word: 'gear', word_class: 'N' },
    { word: 'gear', word_class: 'V' },
    { word: 'gears', word_class: 'N' },
    { word: 'geared', word_class: 'AJ' },
    { word: 'gearing', word_class: 'N' }
  ],
  sprinkling: [
    { word: 'sprinkle', word_class: 'N' },
    { word: 'sprinkle', word_class: 'V' },
    { word: 'sprinkler', word_class: 'N' },
    { word: 'sprinkles', word_class: 'N' },
    { word: 'sprinkled', word_class: 'AJ' },
    { word: 'sprinkling', word_class: 'N' }
  ],
  lobbyist: [
    { word: 'lobby', word_class: 'N' },
    { word: 'lobby', word_class: 'V' },
    { word: 'lobbyist', word_class: 'N' }
  ],
  grantee: [
    { word: 'grant', word_class: 'N' },
    { word: 'grant', word_class: 'V' },
    { word: 'grantee', word_class: 'N' },
    { word: 'grantor', word_class: 'N' },
    { word: 'granted', word_class: 'AJ' }
  ],
  liquefy: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  serve: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  brutality: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  manufacture: [
    { word: 'manufacture', word_class: 'N' },
    { word: 'manufacture', word_class: 'V' },
    { word: 'manufacturer', word_class: 'N' },
    { word: 'manufactured', word_class: 'AJ' },
    { word: 'manufacturing', word_class: 'N' }
  ],
  sentimentalist: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  peregrination: [
    { word: 'peregrine', word_class: 'N' },
    { word: 'peregrine', word_class: 'AJ' },
    { word: 'peregrinate', word_class: 'V' },
    { word: 'peregrination', word_class: 'N' }
  ],
  refraction: [
    { word: 'refract', word_class: 'V' },
    { word: 'refraction', word_class: 'N' },
    { word: 'refractive', word_class: 'AJ' },
    { word: 'refractivity', word_class: 'N' },
    { word: 'refractiveness', word_class: 'N' }
  ],
  northernness: [
    { word: 'northern', word_class: 'N' },
    { word: 'northern', word_class: 'AJ' },
    { word: 'northerner', word_class: 'N' },
    { word: 'northernness', word_class: 'N' }
  ],
  indifferently: [
    { word: 'indifference', word_class: 'N' },
    { word: 'indifferent', word_class: 'AJ' },
    { word: 'indifferently', word_class: 'AV' }
  ],
  recitation: [
    { word: 'recite', word_class: 'V' },
    { word: 'recital', word_class: 'N' },
    { word: 'recitation', word_class: 'N' },
    { word: 'recitative', word_class: 'N' }
  ],
  figuring: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  crouch: [
    { word: 'crouch', word_class: 'N' },
    { word: 'crouch', word_class: 'V' },
    { word: 'crouched', word_class: 'AJ' },
    { word: 'crouching', word_class: 'AJ' }
  ],
  education: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  terminal: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  harrier: [
    { word: 'harry', word_class: 'V' },
    { word: 'harrier', word_class: 'N' },
    { word: 'harried', word_class: 'AJ' }
  ],
  revokable: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  student: [
    { word: 'stud', word_class: 'N' },
    { word: 'stud', word_class: 'V' },
    { word: 'student', word_class: 'N' },
    { word: 'studded', word_class: 'AJ' }
  ],
  columnar: [
    { word: 'column', word_class: 'N' },
    { word: 'columnar', word_class: 'AJ' },
    { word: 'columned', word_class: 'AJ' }
  ],
  hypnotised: [
    { word: 'hypnotise', word_class: 'V' },
    { word: 'hypnotised', word_class: 'AJ' },
    { word: 'hypnotisation', word_class: 'N' }
  ],
  diffusive: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  decentralizing: [
    { word: 'decentralize', word_class: 'V' },
    { word: 'decentralized', word_class: 'AJ' },
    { word: 'decentralizing', word_class: 'AJ' },
    { word: 'decentralization', word_class: 'N' }
  ],
  acerbation: [
    { word: 'acerb', word_class: 'AJ' },
    { word: 'acerbate', word_class: 'V' },
    { word: 'acerbic', word_class: 'AJ' },
    { word: 'acerbity', word_class: 'N' },
    { word: 'acerbation', word_class: 'N' }
  ],
  acetylate: [
    { word: 'acetyl', word_class: 'N' },
    { word: 'acetylate', word_class: 'V' },
    { word: 'acetylic', word_class: 'AJ' },
    { word: 'acetylize', word_class: 'V' },
    { word: 'acetylation', word_class: 'N' }
  ],
  unsubstantialize: [
    { word: 'unsubstantial', word_class: 'AJ' },
    { word: 'unsubstantialize', word_class: 'V' },
    { word: 'unsubstantiated', word_class: 'AJ' }
  ],
  gotten: [
    { word: 'get', word_class: 'V' },
    { word: 'getting', word_class: 'N' },
    { word: 'gotten', word_class: 'AJ' }
  ],
  annunciator: [
    { word: 'annunciate', word_class: 'V' },
    { word: 'annunciator', word_class: 'N' },
    { word: 'annunciation', word_class: 'N' }
  ],
  inspector: [
    { word: 'inspect', word_class: 'V' },
    { word: 'inspector', word_class: 'N' },
    { word: 'inspection', word_class: 'N' },
    { word: 'inspectorate', word_class: 'N' }
  ],
  tone: [{ word: 'tone', word_class: 'N' }, { word: 'tone', word_class: 'V' }, { word: 'toned', word_class: 'AJ' }],
  comparative: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  latinist: [
    { word: 'latin', word_class: 'N' },
    { word: 'latin', word_class: 'AJ' },
    { word: 'latinize', word_class: 'V' },
    { word: 'latinist', word_class: 'N' },
    { word: 'latinate', word_class: 'AJ' },
    { word: 'latinization', word_class: 'N' }
  ],
  reflectivity: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  uxoriousness: [
    { word: 'uxorial', word_class: 'AJ' },
    { word: 'uxorious', word_class: 'AJ' },
    { word: 'uxoriously', word_class: 'AV' },
    { word: 'uxoriousness', word_class: 'N' }
  ],
  classicize: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  innumerous: [
    { word: 'innumerate', word_class: 'AJ' },
    { word: 'innumerous', word_class: 'AJ' },
    { word: 'innumerable', word_class: 'AJ' },
    { word: 'innumerableness', word_class: 'N' }
  ],
  moped: [
    { word: 'mope', word_class: 'N' },
    { word: 'mope', word_class: 'V' },
    { word: 'moped', word_class: 'N' },
    { word: 'moped', word_class: 'V' },
    { word: 'mopes', word_class: 'N' }
  ],
  scouting: [
    { word: 'scout', word_class: 'N' },
    { word: 'scout', word_class: 'V' },
    { word: 'scouting', word_class: 'N' }
  ],
  imbrication: [
    { word: 'imbricate', word_class: 'V' },
    { word: 'imbricate', word_class: 'AJ' },
    { word: 'imbricated', word_class: 'AJ' },
    { word: 'imbrication', word_class: 'N' }
  ],
  elaborately: [
    { word: 'elaborate', word_class: 'V' },
    { word: 'elaborate', word_class: 'AJ' },
    { word: 'elaborated', word_class: 'AJ' },
    { word: 'elaboration', word_class: 'N' },
    { word: 'elaborately', word_class: 'AV' },
    { word: 'elaborateness', word_class: 'N' }
  ],
  dauntlessness: [
    { word: 'daunt', word_class: 'V' },
    { word: 'daunted', word_class: 'AJ' },
    { word: 'daunting', word_class: 'AJ' },
    { word: 'dauntless', word_class: 'AJ' },
    { word: 'dauntlessness', word_class: 'N' }
  ],
  ape: [{ word: 'ape', word_class: 'N' }, { word: 'ape', word_class: 'V' }, { word: 'aper', word_class: 'N' }],
  gullibility: [
    { word: 'gullible', word_class: 'AJ' },
    { word: 'gullibly', word_class: 'AV' },
    { word: 'gullibility', word_class: 'N' }
  ],
  make: [
    { word: 'make', word_class: 'N' },
    { word: 'make', word_class: 'V' },
    { word: 'maker', word_class: 'N' },
    { word: 'making', word_class: 'N' }
  ],
  honourableness: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  notation: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  innovational: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  familiarise: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  grumbling: [
    { word: 'grumble', word_class: 'N' },
    { word: 'grumble', word_class: 'V' },
    { word: 'grumbler', word_class: 'N' },
    { word: 'grumbling', word_class: 'N' },
    { word: 'grumbling', word_class: 'AJ' }
  ],
  graze: [
    { word: 'graze', word_class: 'N' },
    { word: 'graze', word_class: 'V' },
    { word: 'grazed', word_class: 'AJ' },
    { word: 'grazing', word_class: 'N' }
  ],
  earnest: [
    { word: 'earnest', word_class: 'N' },
    { word: 'earnest', word_class: 'AJ' },
    { word: 'earnestness', word_class: 'N' }
  ],
  lubricate: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  needs: [
    { word: 'need', word_class: 'N' },
    { word: 'need', word_class: 'V' },
    { word: 'needs', word_class: 'AV' },
    { word: 'needed', word_class: 'AJ' },
    { word: 'needful', word_class: 'AJ' }
  ],
  teasing: [
    { word: 'tease', word_class: 'N' },
    { word: 'tease', word_class: 'V' },
    { word: 'teaser', word_class: 'N' },
    { word: 'teased', word_class: 'AJ' },
    { word: 'teasing', word_class: 'N' },
    { word: 'teasing', word_class: 'AJ' }
  ],
  capacitance: [
    { word: 'capacitate', word_class: 'V' },
    { word: 'capacitance', word_class: 'N' },
    { word: 'capacitive', word_class: 'AJ' },
    { word: 'capacitation', word_class: 'N' }
  ],
  uncovered: [
    { word: 'uncover', word_class: 'V' },
    { word: 'uncovered', word_class: 'AJ' },
    { word: 'uncovering', word_class: 'N' }
  ],
  gaping: [{ word: 'gape', word_class: 'N' }, { word: 'gape', word_class: 'V' }, { word: 'gaping', word_class: 'AJ' }],
  duplicator: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  immunosuppressive: [
    { word: 'immunosuppressant', word_class: 'N' },
    { word: 'immunosuppression', word_class: 'N' },
    { word: 'immunosuppressive', word_class: 'AJ' }
  ],
  venal: [
    { word: 'venal', word_class: 'AJ' },
    { word: 'venality', word_class: 'N' },
    { word: 'venally', word_class: 'AV' }
  ],
  preciseness: [
    { word: 'precis', word_class: 'V' },
    { word: 'precis', word_class: 'N' },
    { word: 'precise', word_class: 'AJ' },
    { word: 'precision', word_class: 'N' },
    { word: 'precisely', word_class: 'AV' },
    { word: 'preciseness', word_class: 'N' }
  ],
  bawl: [
    { word: 'bawl', word_class: 'V' },
    { word: 'bawling', word_class: 'N' },
    { word: 'bawling', word_class: 'AJ' }
  ],
  ripped: [
    { word: 'rip', word_class: 'N' },
    { word: 'rip', word_class: 'V' },
    { word: 'ripped', word_class: 'AJ' },
    { word: 'ripping', word_class: 'AJ' }
  ],
  unpropitiously: [
    { word: 'unpropitious', word_class: 'AJ' },
    { word: 'unpropitiously', word_class: 'AV' },
    { word: 'unpropitiousness', word_class: 'N' }
  ],
  fix: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  carburet: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  slightness: [
    { word: 'slight', word_class: 'N' },
    { word: 'slight', word_class: 'V' },
    { word: 'slight', word_class: 'AJ' },
    { word: 'slighting', word_class: 'AJ' },
    { word: 'slightness', word_class: 'N' }
  ],
  dip: [{ word: 'dip', word_class: 'N' }, { word: 'dip', word_class: 'V' }, { word: 'dipped', word_class: 'AJ' }],
  mariner: [
    { word: 'marine', word_class: 'N' },
    { word: 'marine', word_class: 'AJ' },
    { word: 'mariner', word_class: 'N' },
    { word: 'marinade', word_class: 'N' },
    { word: 'marinade', word_class: 'V' },
    { word: 'marinate', word_class: 'V' },
    { word: 'marination', word_class: 'N' }
  ],
  precipitousness: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  deific: [
    { word: 'deify', word_class: 'V' },
    { word: 'deific', word_class: 'AJ' },
    { word: 'deification', word_class: 'N' }
  ],
  pursuer: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  defrayal: [
    { word: 'defray', word_class: 'V' },
    { word: 'defrayal', word_class: 'N' },
    { word: 'defrayment', word_class: 'N' }
  ],
  discontentment: [
    { word: 'discontent', word_class: 'N' },
    { word: 'discontent', word_class: 'V' },
    { word: 'discontent', word_class: 'AJ' },
    { word: 'discontented', word_class: 'AJ' },
    { word: 'discontentment', word_class: 'N' }
  ],
  interline: [
    { word: 'interline', word_class: 'V' },
    { word: 'interlining', word_class: 'N' },
    { word: 'interlineal', word_class: 'AJ' },
    { word: 'interlineate', word_class: 'V' },
    { word: 'interlineation', word_class: 'N' }
  ],
  vibrance: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  locomotive: [
    { word: 'locomote', word_class: 'V' },
    { word: 'locomotion', word_class: 'N' },
    { word: 'locomotive', word_class: 'N' },
    { word: 'locomotive', word_class: 'AJ' }
  ],
  hitting: [{ word: 'hit', word_class: 'N' }, { word: 'hit', word_class: 'V' }, { word: 'hitting', word_class: 'N' }],
  dutifulness: [
    { word: 'duty', word_class: 'N' },
    { word: 'dutiful', word_class: 'AJ' },
    { word: 'dutiable', word_class: 'AJ' },
    { word: 'dutifulness', word_class: 'N' }
  ],
  string: [
    { word: 'string', word_class: 'N' },
    { word: 'string', word_class: 'V' },
    { word: 'stringer', word_class: 'N' }
  ],
  sulfurisation: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  episodic: [
    { word: 'episode', word_class: 'N' },
    { word: 'episodic', word_class: 'AJ' },
    { word: 'episodically', word_class: 'AV' }
  ],
  revelment: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  adiposeness: [
    { word: 'adipose', word_class: 'AJ' },
    { word: 'adiposity', word_class: 'N' },
    { word: 'adiposeness', word_class: 'N' }
  ],
  co: [{ word: 'co', word_class: 'N' }, { word: 'cos', word_class: 'N' }, { word: 'coed', word_class: 'N' }],
  dialectic: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  filling: [
    { word: 'fill', word_class: 'N' },
    { word: 'fill', word_class: 'V' },
    { word: 'fille', word_class: 'N' },
    { word: 'filler', word_class: 'N' },
    { word: 'filled', word_class: 'AJ' },
    { word: 'filling', word_class: 'N' }
  ],
  thrive: [
    { word: 'thrive', word_class: 'V' },
    { word: 'thriven', word_class: 'AJ' },
    { word: 'thriving', word_class: 'AJ' }
  ],
  adhere: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  chemisorptive: [
    { word: 'chemisorb', word_class: 'V' },
    { word: 'chemisorption', word_class: 'N' },
    { word: 'chemisorptive', word_class: 'AJ' }
  ],
  anthology: [
    { word: 'anthology', word_class: 'N' },
    { word: 'anthologize', word_class: 'V' },
    { word: 'anthologization', word_class: 'N' }
  ],
  factor: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  shaded: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  assembler: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  statistics: [
    { word: 'statistic', word_class: 'N' },
    { word: 'statistic', word_class: 'AJ' },
    { word: 'statistics', word_class: 'N' },
    { word: 'statistical', word_class: 'AJ' },
    { word: 'statistically', word_class: 'AV' }
  ],
  emasculation: [
    { word: 'emasculate', word_class: 'V' },
    { word: 'emasculate', word_class: 'AJ' },
    { word: 'emasculated', word_class: 'AJ' },
    { word: 'emasculation', word_class: 'N' }
  ],
  tenderisation: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  unlovable: [
    { word: 'unloved', word_class: 'AJ' },
    { word: 'unlovely', word_class: 'AJ' },
    { word: 'unloving', word_class: 'AJ' },
    { word: 'unlovable', word_class: 'AJ' }
  ],
  gauntleted: [
    { word: 'gauntlet', word_class: 'N' },
    { word: 'gauntlets', word_class: 'N' },
    { word: 'gauntleted', word_class: 'AJ' }
  ],
  irresponsibly: [
    { word: 'irresponsible', word_class: 'AJ' },
    { word: 'irresponsibly', word_class: 'AV' },
    { word: 'irresponsibility', word_class: 'N' },
    { word: 'irresponsibleness', word_class: 'N' }
  ],
  affaire: [
    { word: 'affair', word_class: 'N' },
    { word: 'affaire', word_class: 'N' },
    { word: 'affairs', word_class: 'N' }
  ],
  ascertain: [
    { word: 'ascertain', word_class: 'V' },
    { word: 'ascertained', word_class: 'AJ' },
    { word: 'ascertainment', word_class: 'N' },
    { word: 'ascertainable', word_class: 'AJ' }
  ],
  hypostasization: [
    { word: 'hypostasize', word_class: 'V' },
    { word: 'hypostasisation', word_class: 'N' },
    { word: 'hypostasization', word_class: 'N' }
  ],
  emergent: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  fractionization: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  disciplinary: [
    { word: 'discipline', word_class: 'N' },
    { word: 'discipline', word_class: 'V' },
    { word: 'disciplinal', word_class: 'AJ' },
    { word: 'disciplined', word_class: 'AJ' },
    { word: 'disciplinary', word_class: 'AJ' }
  ],
  simplified: [
    { word: 'simplify', word_class: 'V' },
    { word: 'simplified', word_class: 'AJ' },
    { word: 'simplification', word_class: 'N' }
  ],
  fulness: [
    { word: 'full', word_class: 'V' },
    { word: 'full', word_class: 'AJ' },
    { word: 'full', word_class: 'AV' },
    { word: 'fully', word_class: 'AV' },
    { word: 'fulness', word_class: 'N' },
    { word: 'fullness', word_class: 'N' }
  ],
  swishing: [
    { word: 'swish', word_class: 'N' },
    { word: 'swish', word_class: 'V' },
    { word: 'swish', word_class: 'AJ' },
    { word: 'swishy', word_class: 'AJ' },
    { word: 'swishing', word_class: 'AJ' }
  ],
  spoonful: [
    { word: 'spoon', word_class: 'N' },
    { word: 'spoon', word_class: 'V' },
    { word: 'spoonful', word_class: 'N' },
    { word: 'spoonful', word_class: 'AJ' }
  ],
  fringe: [
    { word: 'fringe', word_class: 'N' },
    { word: 'fringe', word_class: 'V' },
    { word: 'fringe', word_class: 'AJ' },
    { word: 'fringed', word_class: 'AJ' }
  ],
  universalisation: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  dumbfounded: [
    { word: 'dumbfound', word_class: 'V' },
    { word: 'dumbfounded', word_class: 'AJ' },
    { word: 'dumbfounding', word_class: 'AJ' }
  ],
  alloyed: [
    { word: 'alloy', word_class: 'N' },
    { word: 'alloy', word_class: 'V' },
    { word: 'alloyed', word_class: 'AJ' }
  ],
  registrant: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  certify: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  toiling: [
    { word: 'toil', word_class: 'N' },
    { word: 'toil', word_class: 'V' },
    { word: 'toiler', word_class: 'N' },
    { word: 'toiling', word_class: 'AJ' },
    { word: 'toilsome', word_class: 'AJ' },
    { word: 'toilsomeness', word_class: 'N' }
  ],
  fragmented: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  dump: [
    { word: 'dump', word_class: 'N' },
    { word: 'dump', word_class: 'V' },
    { word: 'dumps', word_class: 'N' },
    { word: 'dumper', word_class: 'N' },
    { word: 'dumped', word_class: 'AJ' },
    { word: 'dumping', word_class: 'N' }
  ],
  boater: [
    { word: 'boat', word_class: 'N' },
    { word: 'boat', word_class: 'V' },
    { word: 'boater', word_class: 'N' },
    { word: 'boating', word_class: 'N' },
    { word: 'boatman', word_class: 'N' }
  ],
  worship: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  imperialist: [
    { word: 'imperialist', word_class: 'N' },
    { word: 'imperialist', word_class: 'AJ' },
    { word: 'imperialistic', word_class: 'AJ' }
  ],
  mitigated: [
    { word: 'mitigate', word_class: 'V' },
    { word: 'mitigable', word_class: 'AJ' },
    { word: 'mitigated', word_class: 'AJ' },
    { word: 'mitigation', word_class: 'N' },
    { word: 'mitigative', word_class: 'AJ' }
  ],
  beggary: [
    { word: 'beg', word_class: 'V' },
    { word: 'beggar', word_class: 'N' },
    { word: 'beggar', word_class: 'V' },
    { word: 'beggary', word_class: 'N' },
    { word: 'begging', word_class: 'N' },
    { word: 'beggary', word_class: 'AJ' },
    { word: 'beggarman', word_class: 'N' }
  ],
  civilized: [
    { word: 'civil', word_class: 'AJ' },
    { word: 'civility', word_class: 'N' },
    { word: 'civilize', word_class: 'V' },
    { word: 'civilized', word_class: 'AJ' },
    { word: 'civilization', word_class: 'N' }
  ],
  astonished: [
    { word: 'astonish', word_class: 'V' },
    { word: 'astonished', word_class: 'AJ' },
    { word: 'astonishing', word_class: 'AJ' },
    { word: 'astonishment', word_class: 'N' }
  ],
  tin: [
    { word: 'tin', word_class: 'N' },
    { word: 'tin', word_class: 'V' },
    { word: 'tinful', word_class: 'N' },
    { word: 'tinful', word_class: 'AJ' },
    { word: 'tinned', word_class: 'AJ' },
    { word: 'tinning', word_class: 'N' }
  ],
  philippine: [
    { word: 'philippine', word_class: 'N' },
    { word: 'philippine', word_class: 'AJ' },
    { word: 'philippines', word_class: 'N' }
  ],
  insufficiency: [
    { word: 'insufficiency', word_class: 'N' },
    { word: 'insufficient', word_class: 'AJ' },
    { word: 'insufficiently', word_class: 'AV' }
  ],
  surprised: [
    { word: 'surprise', word_class: 'N' },
    { word: 'surprise', word_class: 'V' },
    { word: 'surprisal', word_class: 'N' },
    { word: 'surprised', word_class: 'AJ' },
    { word: 'surprising', word_class: 'N' },
    { word: 'surprising', word_class: 'AJ' }
  ],
  blathering: [
    { word: 'blather', word_class: 'N' },
    { word: 'blather', word_class: 'V' },
    { word: 'blathering', word_class: 'AJ' }
  ],
  volcanization: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  annexation: [
    { word: 'annex', word_class: 'N' },
    { word: 'annex', word_class: 'V' },
    { word: 'annexe', word_class: 'N' },
    { word: 'annexal', word_class: 'AJ' },
    { word: 'annexation', word_class: 'N' },
    { word: 'annexational', word_class: 'AJ' }
  ],
  moan: [
    { word: 'moan', word_class: 'N' },
    { word: 'moan', word_class: 'V' },
    { word: 'moaner', word_class: 'N' },
    { word: 'moaning', word_class: 'AJ' }
  ],
  numeral: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  ambivalent: [
    { word: 'ambivalence', word_class: 'N' },
    { word: 'ambivalency', word_class: 'N' },
    { word: 'ambivalent', word_class: 'AJ' }
  ],
  inattentive: [
    { word: 'inattention', word_class: 'N' },
    { word: 'inattentive', word_class: 'AJ' },
    { word: 'inattentively', word_class: 'AV' },
    { word: 'inattentiveness', word_class: 'N' }
  ],
  cocainize: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  unacceptable: [
    { word: 'unaccepted', word_class: 'AJ' },
    { word: 'unacceptable', word_class: 'AJ' },
    { word: 'unacceptably', word_class: 'AV' },
    { word: 'unacceptability', word_class: 'N' },
    { word: 'unacceptableness', word_class: 'N' }
  ],
  graining: [
    { word: 'grain', word_class: 'N' },
    { word: 'grain', word_class: 'V' },
    { word: 'grained', word_class: 'AJ' },
    { word: 'graining', word_class: 'N' },
    { word: 'granular', word_class: 'AJ' },
    { word: 'granularity', word_class: 'N' }
  ],
  quaker: [
    { word: 'quake', word_class: 'N' },
    { word: 'quake', word_class: 'V' },
    { word: 'quaker', word_class: 'N' },
    { word: 'quakers', word_class: 'N' },
    { word: 'quaking', word_class: 'AJ' },
    { word: 'quakerism', word_class: 'N' }
  ],
  elbowing: [
    { word: 'elbow', word_class: 'N' },
    { word: 'elbow', word_class: 'V' },
    { word: 'elbowing', word_class: 'N' }
  ],
  render: [
    { word: 'render', word_class: 'N' },
    { word: 'render', word_class: 'V' },
    { word: 'rendering', word_class: 'N' },
    { word: 'rendition', word_class: 'N' }
  ],
  codified: [
    { word: 'codify', word_class: 'V' },
    { word: 'codified', word_class: 'AJ' },
    { word: 'codification', word_class: 'N' }
  ],
  palatalisation: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  suntan: [
    { word: 'suntan', word_class: 'N' },
    { word: 'suntan', word_class: 'V' },
    { word: 'suntanned', word_class: 'AJ' }
  ],
  galvanism: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  detonating: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  blistered: [
    { word: 'blister', word_class: 'N' },
    { word: 'blister', word_class: 'V' },
    { word: 'blistered', word_class: 'AJ' },
    { word: 'blistering', word_class: 'AJ' }
  ],
  skywards: [
    { word: 'skyward', word_class: 'AJ' },
    { word: 'skyward', word_class: 'AV' },
    { word: 'skywards', word_class: 'AV' }
  ],
  hirsuteness: [
    { word: 'hirsute', word_class: 'AJ' },
    { word: 'hirsutism', word_class: 'N' },
    { word: 'hirsuteness', word_class: 'N' }
  ],
  peptone: [
    { word: 'peptone', word_class: 'N' },
    { word: 'peptonise', word_class: 'V' },
    { word: 'peptonize', word_class: 'V' },
    { word: 'peptonisation', word_class: 'N' },
    { word: 'peptonization', word_class: 'N' }
  ],
  starred: [
    { word: 'star', word_class: 'N' },
    { word: 'star', word_class: 'V' },
    { word: 'star', word_class: 'AJ' },
    { word: 'starred', word_class: 'AJ' },
    { word: 'starring', word_class: 'AJ' }
  ],
  securement: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  unsatiably: [
    { word: 'unsatiable', word_class: 'AJ' },
    { word: 'unsatiably', word_class: 'AV' },
    { word: 'unsatiated', word_class: 'AJ' }
  ],
  anachronous: [
    { word: 'anachronic', word_class: 'AJ' },
    { word: 'anachronism', word_class: 'N' },
    { word: 'anachronous', word_class: 'AJ' }
  ],
  affricative: [
    { word: 'affricate', word_class: 'N' },
    { word: 'affrication', word_class: 'N' },
    { word: 'affricative', word_class: 'N' }
  ],
  deafen: [
    { word: 'deafen', word_class: 'V' },
    { word: 'deafened', word_class: 'AJ' },
    { word: 'deafening', word_class: 'AJ' }
  ],
  opportune: [
    { word: 'opportune', word_class: 'AJ' },
    { word: 'opportunism', word_class: 'N' },
    { word: 'opportunity', word_class: 'N' },
    { word: 'opportunely', word_class: 'AV' },
    { word: 'opportuneness', word_class: 'N' }
  ],
  vitamine: [
    { word: 'vitamin', word_class: 'N' },
    { word: 'vitamine', word_class: 'N' },
    { word: 'vitaminize', word_class: 'V' }
  ],
  revitalization: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  plantation: [
    { word: 'plant', word_class: 'N' },
    { word: 'plant', word_class: 'V' },
    { word: 'planter', word_class: 'N' },
    { word: 'planted', word_class: 'AJ' },
    { word: 'planting', word_class: 'N' },
    { word: 'plantation', word_class: 'N' }
  ],
  interstice: [
    { word: 'interstice', word_class: 'N' },
    { word: 'interstice', word_class: 'V' },
    { word: 'interstitial', word_class: 'AJ' }
  ],
  piratically: [
    { word: 'piracy', word_class: 'N' },
    { word: 'pirate', word_class: 'N' },
    { word: 'pirate', word_class: 'V' },
    { word: 'piratical', word_class: 'AJ' },
    { word: 'piratically', word_class: 'AV' }
  ],
  escapism: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  lurk: [{ word: 'lurk', word_class: 'V' }, { word: 'lurker', word_class: 'N' }, { word: 'lurking', word_class: 'AJ' }],
  induced: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  checked: [
    { word: 'check', word_class: 'N' },
    { word: 'check', word_class: 'V' },
    { word: 'checker', word_class: 'N' },
    { word: 'checker', word_class: 'V' },
    { word: 'checked', word_class: 'AJ' },
    { word: 'checkers', word_class: 'N' },
    { word: 'checkered', word_class: 'AJ' }
  ],
  influence: [
    { word: 'influence', word_class: 'N' },
    { word: 'influence', word_class: 'V' },
    { word: 'influential', word_class: 'AJ' },
    { word: 'influentially', word_class: 'AV' }
  ],
  potently: [
    { word: 'potence', word_class: 'N' },
    { word: 'potency', word_class: 'N' },
    { word: 'potent', word_class: 'AJ' },
    { word: 'potentate', word_class: 'N' },
    { word: 'potently', word_class: 'AV' }
  ],
  suspender: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  harmoniousness: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  bobtail: [
    { word: 'bobtail', word_class: 'N' },
    { word: 'bobtail', word_class: 'AJ' },
    { word: 'bobtailed', word_class: 'AJ' }
  ],
  demonstrably: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  range: [
    { word: 'range', word_class: 'N' },
    { word: 'range', word_class: 'V' },
    { word: 'ranger', word_class: 'N' },
    { word: 'ranging', word_class: 'AJ' }
  ],
  particularization: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  mysticism: [
    { word: 'mystic', word_class: 'N' },
    { word: 'mystic', word_class: 'AJ' },
    { word: 'mystical', word_class: 'AJ' },
    { word: 'mysticism', word_class: 'N' },
    { word: 'mystically', word_class: 'AV' }
  ],
  drowsy: [
    { word: 'drowse', word_class: 'N' },
    { word: 'drowse', word_class: 'V' },
    { word: 'drowsy', word_class: 'AJ' },
    { word: 'drowsing', word_class: 'AJ' },
    { word: 'drowsiness', word_class: 'N' }
  ],
  spoil: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  significative: [
    { word: 'signify', word_class: 'V' },
    { word: 'signification', word_class: 'N' },
    { word: 'significative', word_class: 'AJ' }
  ],
  boast: [
    { word: 'boast', word_class: 'N' },
    { word: 'boast', word_class: 'V' },
    { word: 'boaster', word_class: 'N' },
    { word: 'boasting', word_class: 'N' },
    { word: 'boastful', word_class: 'AJ' },
    { word: 'boastfulness', word_class: 'N' }
  ],
  graphically: [
    { word: 'graphic', word_class: 'N' },
    { word: 'graphic', word_class: 'AJ' },
    { word: 'graphics', word_class: 'N' },
    { word: 'graphical', word_class: 'AJ' },
    { word: 'graphically', word_class: 'AV' }
  ],
  disrupted: [
    { word: 'disrupt', word_class: 'V' },
    { word: 'disrupted', word_class: 'AJ' },
    { word: 'disruption', word_class: 'N' },
    { word: 'disruptive', word_class: 'AJ' },
    { word: 'disruptively', word_class: 'AV' }
  ],
  finitely: [
    { word: 'finite', word_class: 'N' },
    { word: 'finite', word_class: 'AJ' },
    { word: 'finitely', word_class: 'AV' },
    { word: 'finiteness', word_class: 'N' }
  ],
  term: [
    { word: 'term', word_class: 'N' },
    { word: 'term', word_class: 'V' },
    { word: 'terms', word_class: 'N' },
    { word: 'termes', word_class: 'N' },
    { word: 'termite', word_class: 'N' }
  ],
  purser: [{ word: 'purse', word_class: 'N' }, { word: 'purse', word_class: 'V' }, { word: 'purser', word_class: 'N' }],
  prototypal: [
    { word: 'prototype', word_class: 'N' },
    { word: 'prototype', word_class: 'V' },
    { word: 'prototypal', word_class: 'AJ' },
    { word: 'prototypic', word_class: 'AJ' },
    { word: 'prototypical', word_class: 'AJ' }
  ],
  actionable: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  distillation: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  weatherman: [
    { word: 'weather', word_class: 'N' },
    { word: 'weather', word_class: 'V' },
    { word: 'weather', word_class: 'AJ' },
    { word: 'weathered', word_class: 'AJ' },
    { word: 'weatherman', word_class: 'N' }
  ],
  troll: [{ word: 'troll', word_class: 'N' }, { word: 'troll', word_class: 'V' }, { word: 'troller', word_class: 'N' }],
  connotative: [
    { word: 'connote', word_class: 'V' },
    { word: 'connotation', word_class: 'N' },
    { word: 'connotative', word_class: 'AJ' },
    { word: 'connotational', word_class: 'AJ' }
  ],
  canonize: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  cathartic: [
    { word: 'cathartes', word_class: 'N' },
    { word: 'cathartic', word_class: 'N' },
    { word: 'cathartic', word_class: 'AJ' }
  ],
  destructible: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  despoil: [
    { word: 'despoil', word_class: 'V' },
    { word: 'despoiler', word_class: 'N' },
    { word: 'despoiled', word_class: 'AJ' },
    { word: 'despoilment', word_class: 'N' },
    { word: 'despoliation', word_class: 'N' }
  ],
  jumping: [
    { word: 'jump', word_class: 'N' },
    { word: 'jump', word_class: 'V' },
    { word: 'jumper', word_class: 'N' },
    { word: 'jumpers', word_class: 'N' },
    { word: 'jumping', word_class: 'N' },
    { word: 'jumping', word_class: 'AJ' }
  ],
  irresponsible: [
    { word: 'irresponsible', word_class: 'AJ' },
    { word: 'irresponsibly', word_class: 'AV' },
    { word: 'irresponsibility', word_class: 'N' },
    { word: 'irresponsibleness', word_class: 'N' }
  ],
  agitation: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  apportioning: [
    { word: 'apportion', word_class: 'V' },
    { word: 'apportioned', word_class: 'AJ' },
    { word: 'apportioning', word_class: 'N' },
    { word: 'apportionment', word_class: 'N' },
    { word: 'apportionable', word_class: 'AJ' }
  ],
  prematurely: [
    { word: 'premature', word_class: 'AJ' },
    { word: 'prematurity', word_class: 'N' },
    { word: 'prematurely', word_class: 'AV' },
    { word: 'prematureness', word_class: 'N' }
  ],
  grime: [
    { word: 'grime', word_class: 'N' },
    { word: 'grim', word_class: 'AJ' },
    { word: 'grime', word_class: 'V' },
    { word: 'grimy', word_class: 'AJ' },
    { word: 'grimness', word_class: 'N' },
    { word: 'griminess', word_class: 'N' }
  ],
  unpleasantness: [
    { word: 'unpleasant', word_class: 'AJ' },
    { word: 'unpleasing', word_class: 'AJ' },
    { word: 'unpleasantness', word_class: 'N' }
  ],
  division: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  equinoctial: [
    { word: 'equinox', word_class: 'N' },
    { word: 'equinoctial', word_class: 'N' },
    { word: 'equinoctial', word_class: 'AJ' }
  ],
  brimful: [
    { word: 'brim', word_class: 'N' },
    { word: 'brim', word_class: 'V' },
    { word: 'brimful', word_class: 'AJ' },
    { word: 'brimming', word_class: 'AJ' }
  ],
  decoloration: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  premiere: [
    { word: 'premier', word_class: 'N' },
    { word: 'premier', word_class: 'V' },
    { word: 'premiere', word_class: 'N' },
    { word: 'premier', word_class: 'AJ' },
    { word: 'premiere', word_class: 'V' },
    { word: 'premiere', word_class: 'AJ' },
    { word: 'premiership', word_class: 'N' }
  ],
  congeniality: [
    { word: 'congenial', word_class: 'AJ' },
    { word: 'congeniality', word_class: 'N' },
    { word: 'congenially', word_class: 'AV' },
    { word: 'congenialness', word_class: 'N' }
  ],
  inanity: [
    { word: 'inane', word_class: 'AJ' },
    { word: 'inanity', word_class: 'N' },
    { word: 'inanely', word_class: 'AV' }
  ],
  mediation: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  gestural: [
    { word: 'gesture', word_class: 'N' },
    { word: 'gesture', word_class: 'V' },
    { word: 'gestural', word_class: 'AJ' }
  ],
  intervene: [
    { word: 'intervene', word_class: 'V' },
    { word: 'intervening', word_class: 'AJ' },
    { word: 'intervention', word_class: 'N' }
  ],
  mutilated: [
    { word: 'mutilate', word_class: 'V' },
    { word: 'mutilated', word_class: 'AJ' },
    { word: 'mutilation', word_class: 'N' }
  ],
  eccentric: [
    { word: 'eccentric', word_class: 'N' },
    { word: 'eccentric', word_class: 'AJ' },
    { word: 'eccentricity', word_class: 'N' }
  ],
  encumbered: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  ruinate: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  spray: [
    { word: 'spray', word_class: 'N' },
    { word: 'spray', word_class: 'V' },
    { word: 'sprayer', word_class: 'N' },
    { word: 'spraying', word_class: 'N' }
  ],
  galvanizing: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  trampling: [
    { word: 'trample', word_class: 'N' },
    { word: 'trample', word_class: 'V' },
    { word: 'trampled', word_class: 'AJ' },
    { word: 'trampling', word_class: 'N' },
    { word: 'trampling', word_class: 'AJ' }
  ],
  backwardness: [
    { word: 'backward', word_class: 'AJ' },
    { word: 'backward', word_class: 'AV' },
    { word: 'backwards', word_class: 'AV' },
    { word: 'backwardness', word_class: 'N' }
  ],
  contracting: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  defectively: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  venter: [
    { word: 'vent', word_class: 'N' },
    { word: 'vent', word_class: 'V' },
    { word: 'venter', word_class: 'N' },
    { word: 'vented', word_class: 'AJ' },
    { word: 'venting', word_class: 'N' }
  ],
  woodsy: [
    { word: 'wood', word_class: 'N' },
    { word: 'wood', word_class: 'AJ' },
    { word: 'woods', word_class: 'N' },
    { word: 'woods', word_class: 'AJ' },
    { word: 'wooded', word_class: 'AJ' },
    { word: 'wooden', word_class: 'AJ' },
    { word: 'woodsy', word_class: 'AJ' }
  ],
  parent: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  geometrical: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  specialistic: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  dumper: [
    { word: 'dump', word_class: 'N' },
    { word: 'dump', word_class: 'V' },
    { word: 'dumps', word_class: 'N' },
    { word: 'dumper', word_class: 'N' },
    { word: 'dumped', word_class: 'AJ' },
    { word: 'dumping', word_class: 'N' }
  ],
  comparability: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  declination: [
    { word: 'decline', word_class: 'N' },
    { word: 'decline', word_class: 'V' },
    { word: 'declining', word_class: 'AJ' },
    { word: 'declination', word_class: 'N' }
  ],
  denseness: [
    { word: 'dense', word_class: 'AJ' },
    { word: 'densely', word_class: 'AV' },
    { word: 'denseness', word_class: 'N' }
  ],
  color: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  basket: [
    { word: 'basket', word_class: 'N' },
    { word: 'basketeer', word_class: 'N' },
    { word: 'basketful', word_class: 'N' },
    { word: 'basketful', word_class: 'AJ' }
  ],
  executing: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  twitch: [
    { word: 'twitch', word_class: 'N' },
    { word: 'twitch', word_class: 'V' },
    { word: 'twitching', word_class: 'N' }
  ],
  drink: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  bucked: [
    { word: 'buck', word_class: 'N' },
    { word: 'buck', word_class: 'V' },
    { word: 'buck', word_class: 'AJ' },
    { word: 'bucked', word_class: 'AJ' }
  ],
  prodigal: [
    { word: 'prodigal', word_class: 'N' },
    { word: 'prodigal', word_class: 'AJ' },
    { word: 'prodigality', word_class: 'N' },
    { word: 'prodigally', word_class: 'AV' }
  ],
  displeasing: [
    { word: 'displease', word_class: 'V' },
    { word: 'displeased', word_class: 'AJ' },
    { word: 'displeasure', word_class: 'N' },
    { word: 'displeasing', word_class: 'AJ' }
  ],
  west: [
    { word: 'wee', word_class: 'N' },
    { word: 'wee', word_class: 'V' },
    { word: 'weal', word_class: 'N' },
    { word: 'west', word_class: 'N' },
    { word: 'wee', word_class: 'AJ' },
    { word: 'west', word_class: 'AJ' },
    { word: 'west', word_class: 'AV' },
    { word: 'weeness', word_class: 'N' }
  ],
  prototype: [
    { word: 'prototype', word_class: 'N' },
    { word: 'prototype', word_class: 'V' },
    { word: 'prototypal', word_class: 'AJ' },
    { word: 'prototypic', word_class: 'AJ' },
    { word: 'prototypical', word_class: 'AJ' }
  ],
  consumer: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  calculably: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  breast: [
    { word: 'breast', word_class: 'N' },
    { word: 'breast', word_class: 'V' },
    { word: 'breasted', word_class: 'AJ' }
  ],
  presentably: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  sovietize: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  headed: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  wasted: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  provenance: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  termite: [
    { word: 'term', word_class: 'N' },
    { word: 'term', word_class: 'V' },
    { word: 'terms', word_class: 'N' },
    { word: 'termes', word_class: 'N' },
    { word: 'termite', word_class: 'N' }
  ],
  accoutred: [
    { word: 'accoutre', word_class: 'V' },
    { word: 'accoutred', word_class: 'AJ' },
    { word: 'accoutrement', word_class: 'N' }
  ],
  annealing: [
    { word: 'anneal', word_class: 'V' },
    { word: 'annealed', word_class: 'AJ' },
    { word: 'annealing', word_class: 'N' }
  ],
  assailant: [
    { word: 'assail', word_class: 'V' },
    { word: 'assailant', word_class: 'N' },
    { word: 'assailment', word_class: 'N' },
    { word: 'assailable', word_class: 'AJ' },
    { word: 'assailability', word_class: 'N' }
  ],
  telescopically: [
    { word: 'telescope', word_class: 'N' },
    { word: 'telescope', word_class: 'V' },
    { word: 'telescoped', word_class: 'AJ' },
    { word: 'telescopic', word_class: 'AJ' },
    { word: 'telescopically', word_class: 'AV' }
  ],
  agitative: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  standardise: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  oxide: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  chelation: [
    { word: 'chelate', word_class: 'N' },
    { word: 'chelate', word_class: 'V' },
    { word: 'chelate', word_class: 'AJ' },
    { word: 'chelated', word_class: 'AJ' },
    { word: 'chelation', word_class: 'N' }
  ],
  initialize: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  shiny: [
    { word: 'shine', word_class: 'N' },
    { word: 'shine', word_class: 'V' },
    { word: 'shiny', word_class: 'AJ' },
    { word: 'shiner', word_class: 'N' },
    { word: 'shining', word_class: 'N' },
    { word: 'shining', word_class: 'AJ' },
    { word: 'shininess', word_class: 'N' }
  ],
  systemize: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  flaps: [
    { word: 'flap', word_class: 'N' },
    { word: 'flap', word_class: 'V' },
    { word: 'flaps', word_class: 'N' },
    { word: 'flapping', word_class: 'N' }
  ],
  instinct: [
    { word: 'instinct', word_class: 'N' },
    { word: 'instinct', word_class: 'AJ' },
    { word: 'instinctive', word_class: 'AJ' },
    { word: 'instinctively', word_class: 'AV' }
  ],
  petite: [
    { word: 'petite', word_class: 'N' },
    { word: 'petit', word_class: 'AJ' },
    { word: 'petite', word_class: 'AJ' },
    { word: 'petiteness', word_class: 'N' }
  ],
  dredge: [
    { word: 'dredge', word_class: 'N' },
    { word: 'dredge', word_class: 'V' },
    { word: 'dredger', word_class: 'N' }
  ],
  dissemination: [
    { word: 'disseminate', word_class: 'V' },
    { word: 'disseminator', word_class: 'N' },
    { word: 'dissemination', word_class: 'N' },
    { word: 'disseminating', word_class: 'AJ' },
    { word: 'disseminative', word_class: 'AJ' }
  ],
  vindictive: [
    { word: 'vindictive', word_class: 'AJ' },
    { word: 'vindictively', word_class: 'AV' },
    { word: 'vindictiveness', word_class: 'N' }
  ],
  capriciously: [
    { word: 'caprice', word_class: 'N' },
    { word: 'capricious', word_class: 'AJ' },
    { word: 'capriciously', word_class: 'AV' },
    { word: 'capriciousness', word_class: 'N' }
  ],
  shambles: [
    { word: 'shamble', word_class: 'N' },
    { word: 'shamble', word_class: 'V' },
    { word: 'shambles', word_class: 'N' },
    { word: 'shambling', word_class: 'N' }
  ],
  deceiver: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  transship: [
    { word: 'tranship', word_class: 'V' },
    { word: 'transship', word_class: 'N' },
    { word: 'transship', word_class: 'V' },
    { word: 'transhipment', word_class: 'N' },
    { word: 'transshipment', word_class: 'N' }
  ],
  dishonour: [
    { word: 'dishonour', word_class: 'N' },
    { word: 'dishonour', word_class: 'V' },
    { word: 'dishonourable', word_class: 'AJ' },
    { word: 'dishonourably', word_class: 'AV' },
    { word: 'dishonourableness', word_class: 'N' }
  ],
  unarguable: [
    { word: 'unarguable', word_class: 'AJ' },
    { word: 'unarguably', word_class: 'AV' },
    { word: 'unargumentative', word_class: 'AJ' }
  ],
  reportorial: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  variable: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  instatement: [
    { word: 'instate', word_class: 'N' },
    { word: 'instate', word_class: 'V' },
    { word: 'instatment', word_class: 'N' },
    { word: 'instatement', word_class: 'N' }
  ],
  regularization: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  presentment: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  disunited: [
    { word: 'disunion', word_class: 'N' },
    { word: 'disunite', word_class: 'V' },
    { word: 'disunited', word_class: 'AJ' }
  ],
  brutalization: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  jaywalking: [
    { word: 'jaywalk', word_class: 'V' },
    { word: 'jaywalker', word_class: 'N' },
    { word: 'jaywalking', word_class: 'N' }
  ],
  nidification: [
    { word: 'nidify', word_class: 'V' },
    { word: 'nidificate', word_class: 'V' },
    { word: 'nidification', word_class: 'N' }
  ],
  employ: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  architecturally: [
    { word: 'architecture', word_class: 'N' },
    { word: 'architectural', word_class: 'AJ' },
    { word: 'architecturally', word_class: 'AV' }
  ],
  restoration: [
    { word: 'restore', word_class: 'N' },
    { word: 'restore', word_class: 'V' },
    { word: 'restorer', word_class: 'N' },
    { word: 'restored', word_class: 'AJ' },
    { word: 'restoration', word_class: 'N' },
    { word: 'restorative', word_class: 'N' },
    { word: 'restorative', word_class: 'AJ' }
  ],
  salivary: [
    { word: 'saliva', word_class: 'N' },
    { word: 'salivate', word_class: 'V' },
    { word: 'salivary', word_class: 'AJ' },
    { word: 'salivation', word_class: 'N' }
  ],
  ingratiating: [
    { word: 'ingratiate', word_class: 'V' },
    { word: 'ingratiation', word_class: 'N' },
    { word: 'ingratiating', word_class: 'AJ' }
  ],
  turning: [
    { word: 'turn', word_class: 'N' },
    { word: 'turn', word_class: 'V' },
    { word: 'turner', word_class: 'N' },
    { word: 'turned', word_class: 'AJ' },
    { word: 'turnery', word_class: 'N' },
    { word: 'turning', word_class: 'N' },
    { word: 'turnery', word_class: 'AJ' },
    { word: 'turning', word_class: 'AJ' }
  ],
  beastly: [
    { word: 'beastly', word_class: 'AJ' },
    { word: 'beastly', word_class: 'AV' },
    { word: 'beastliness', word_class: 'N' }
  ],
  impossible: [
    { word: 'impossible', word_class: 'N' },
    { word: 'impossible', word_class: 'AJ' },
    { word: 'impossibly', word_class: 'AV' },
    { word: 'impossibility', word_class: 'N' },
    { word: 'impossibleness', word_class: 'N' }
  ],
  vulcanise: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  cooperativeness: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  excerption: [
    { word: 'excerpt', word_class: 'N' },
    { word: 'excerpt', word_class: 'V' },
    { word: 'excerption', word_class: 'N' }
  ],
  lath: [
    { word: 'lath', word_class: 'N' },
    { word: 'lathe', word_class: 'N' },
    { word: 'lathe', word_class: 'V' },
    { word: 'lathing', word_class: 'N' }
  ],
  heartbreak: [
    { word: 'heartbreak', word_class: 'N' },
    { word: 'heartbreaker', word_class: 'N' },
    { word: 'heartbreaking', word_class: 'AJ' }
  ],
  maul: [{ word: 'maul', word_class: 'N' }, { word: 'maul', word_class: 'V' }, { word: 'mauler', word_class: 'N' }],
  refuse: [
    { word: 'refuse', word_class: 'N' },
    { word: 'refuse', word_class: 'V' },
    { word: 'refusal', word_class: 'N' }
  ],
  crackers: [
    { word: 'crack', word_class: 'N' },
    { word: 'crack', word_class: 'V' },
    { word: 'crack', word_class: 'AJ' },
    { word: 'cracker', word_class: 'N' },
    { word: 'cracked', word_class: 'AJ' },
    { word: 'cracking', word_class: 'N' },
    { word: 'crackers', word_class: 'AJ' },
    { word: 'cracking', word_class: 'AJ' }
  ],
  gelatinise: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  renouncement: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  ruiner: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  prefabrication: [
    { word: 'prefabricate', word_class: 'V' },
    { word: 'prefabricated', word_class: 'AJ' },
    { word: 'prefabrication', word_class: 'N' }
  ],
  emulsify: [
    { word: 'emulsify', word_class: 'V' },
    { word: 'emulsifier', word_class: 'N' },
    { word: 'emulsified', word_class: 'AJ' },
    { word: 'emulsification', word_class: 'N' }
  ],
  stimulated: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  cattiness: [
    { word: 'catty', word_class: 'N' },
    { word: 'cattie', word_class: 'N' },
    { word: 'catty', word_class: 'AJ' },
    { word: 'cattiness', word_class: 'N' }
  ],
  acetyl: [
    { word: 'acetyl', word_class: 'N' },
    { word: 'acetylate', word_class: 'V' },
    { word: 'acetylic', word_class: 'AJ' },
    { word: 'acetylize', word_class: 'V' },
    { word: 'acetylation', word_class: 'N' }
  ],
  evanesce: [
    { word: 'evanesce', word_class: 'V' },
    { word: 'evanescence', word_class: 'N' },
    { word: 'evanescent', word_class: 'AJ' }
  ],
  resilient: [
    { word: 'resile', word_class: 'V' },
    { word: 'resilement', word_class: 'N' },
    { word: 'resilience', word_class: 'N' },
    { word: 'resiliency', word_class: 'N' },
    { word: 'resilient', word_class: 'AJ' }
  ],
  softened: [
    { word: 'soften', word_class: 'V' },
    { word: 'softener', word_class: 'N' },
    { word: 'softened', word_class: 'AJ' },
    { word: 'softening', word_class: 'N' },
    { word: 'softening', word_class: 'AJ' }
  ],
  catastrophic: [
    { word: 'catastrophe', word_class: 'N' },
    { word: 'catastrophic', word_class: 'AJ' },
    { word: 'catastrophically', word_class: 'AV' }
  ],
  decompositional: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  culturally: [
    { word: 'culture', word_class: 'N' },
    { word: 'culture', word_class: 'V' },
    { word: 'cultural', word_class: 'AJ' },
    { word: 'cultured', word_class: 'AJ' },
    { word: 'culturally', word_class: 'AV' }
  ],
  virulence: [
    { word: 'virulence', word_class: 'N' },
    { word: 'virulency', word_class: 'N' },
    { word: 'virulent', word_class: 'AJ' },
    { word: 'virulently', word_class: 'AV' }
  ],
  unpredictably: [
    { word: 'unpredicted', word_class: 'AJ' },
    { word: 'unpredictive', word_class: 'AJ' },
    { word: 'unpredictable', word_class: 'AJ' },
    { word: 'unpredictably', word_class: 'AV' },
    { word: 'unpredictability', word_class: 'N' }
  ],
  fives: [{ word: 'five', word_class: 'N' }, { word: 'five', word_class: 'AJ' }, { word: 'fives', word_class: 'N' }],
  eponym: [
    { word: 'eponym', word_class: 'N' },
    { word: 'eponymic', word_class: 'AJ' },
    { word: 'eponymous', word_class: 'AJ' }
  ],
  abrasive: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  gallicize: [
    { word: 'gallic', word_class: 'AJ' },
    { word: 'gallicism', word_class: 'N' },
    { word: 'gallicize', word_class: 'V' },
    { word: 'gallicization', word_class: 'N' }
  ],
  sighting: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  haggle: [
    { word: 'haggle', word_class: 'N' },
    { word: 'haggle', word_class: 'V' },
    { word: 'haggling', word_class: 'N' }
  ],
  revivalism: [
    { word: 'revive', word_class: 'V' },
    { word: 'revival', word_class: 'N' },
    { word: 'revive', word_class: 'AJ' },
    { word: 'revived', word_class: 'AJ' },
    { word: 'reviving', word_class: 'AJ' },
    { word: 'revivalism', word_class: 'N' }
  ],
  shining: [
    { word: 'shine', word_class: 'N' },
    { word: 'shine', word_class: 'V' },
    { word: 'shiny', word_class: 'AJ' },
    { word: 'shiner', word_class: 'N' },
    { word: 'shining', word_class: 'N' },
    { word: 'shining', word_class: 'AJ' },
    { word: 'shininess', word_class: 'N' }
  ],
  bubble: [
    { word: 'bubble', word_class: 'N' },
    { word: 'bubble', word_class: 'V' },
    { word: 'bubbly', word_class: 'N' },
    { word: 'bubbler', word_class: 'N' },
    { word: 'bubbly', word_class: 'AJ' },
    { word: 'bubbling', word_class: 'AJ' }
  ],
  archaeology: [
    { word: 'archaeology', word_class: 'N' },
    { word: 'archaeologic', word_class: 'AJ' },
    { word: 'archaeological', word_class: 'AJ' }
  ],
  unguiculate: [
    { word: 'unguiculate', word_class: 'N' },
    { word: 'unguiculate', word_class: 'AJ' },
    { word: 'unguiculated', word_class: 'AJ' }
  ],
  deformed: [
    { word: 'deform', word_class: 'V' },
    { word: 'deformed', word_class: 'AJ' },
    { word: 'deformity', word_class: 'N' },
    { word: 'deformation', word_class: 'N' },
    { word: 'deformational', word_class: 'AJ' }
  ],
  attack: [
    { word: 'attack', word_class: 'N' },
    { word: 'attack', word_class: 'V' },
    { word: 'attack', word_class: 'AJ' },
    { word: 'attacker', word_class: 'N' },
    { word: 'attacking', word_class: 'AJ' }
  ],
  defraud: [
    { word: 'defraud', word_class: 'V' },
    { word: 'defraudment', word_class: 'N' },
    { word: 'defraudation', word_class: 'N' }
  ],
  complexioned: [
    { word: 'complexion', word_class: 'N' },
    { word: 'complexion', word_class: 'V' },
    { word: 'complexioned', word_class: 'AJ' }
  ],
  softness: [
    { word: 'soft', word_class: 'AJ' },
    { word: 'soft', word_class: 'AV' },
    { word: 'softness', word_class: 'N' }
  ],
  association: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  implicating: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  canaries: [
    { word: 'canary', word_class: 'N' },
    { word: 'canary', word_class: 'AJ' },
    { word: 'canaries', word_class: 'N' }
  ],
  suffusive: [
    { word: 'suffuse', word_class: 'V' },
    { word: 'suffused', word_class: 'AJ' },
    { word: 'suffusion', word_class: 'N' },
    { word: 'suffusive', word_class: 'AJ' }
  ],
  frivolousness: [
    { word: 'frivol', word_class: 'V' },
    { word: 'frivolity', word_class: 'N' },
    { word: 'frivolous', word_class: 'AJ' },
    { word: 'frivolously', word_class: 'AV' },
    { word: 'frivolousness', word_class: 'N' }
  ],
  americanize: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  divorce: [
    { word: 'divorce', word_class: 'N' },
    { word: 'divorce', word_class: 'V' },
    { word: 'divorcee', word_class: 'N' },
    { word: 'divorced', word_class: 'AJ' },
    { word: 'divorcement', word_class: 'N' }
  ],
  inhalant: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  quilt: [
    { word: 'quilt', word_class: 'N' },
    { word: 'quilt', word_class: 'V' },
    { word: 'quilted', word_class: 'AJ' },
    { word: 'quilting', word_class: 'N' }
  ],
  libel: [
    { word: 'libel', word_class: 'N' },
    { word: 'libel', word_class: 'V' },
    { word: 'libeler', word_class: 'N' },
    { word: 'libelous', word_class: 'AJ' },
    { word: 'libellous', word_class: 'AJ' }
  ],
  sophistical: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  inaudibility: [
    { word: 'inaudible', word_class: 'AJ' },
    { word: 'inaudibly', word_class: 'AV' },
    { word: 'inaudibility', word_class: 'N' },
    { word: 'inaudibleness', word_class: 'N' }
  ],
  idolized: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  favor: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  sectionalization: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  coenobite: [
    { word: 'coenobite', word_class: 'N' },
    { word: 'coenobitic', word_class: 'AJ' },
    { word: 'coenobitical', word_class: 'AJ' }
  ],
  weed: [
    { word: 'weed', word_class: 'N' },
    { word: 'weed', word_class: 'V' },
    { word: 'weeds', word_class: 'N' },
    { word: 'weeded', word_class: 'AJ' }
  ],
  intransigency: [
    { word: 'intransigence', word_class: 'N' },
    { word: 'intransigency', word_class: 'N' },
    { word: 'intransigent', word_class: 'AJ' }
  ],
  stupefied: [
    { word: 'stupefy', word_class: 'V' },
    { word: 'stupify', word_class: 'V' },
    { word: 'stupefied', word_class: 'AJ' },
    { word: 'stupefying', word_class: 'AJ' },
    { word: 'stupefaction', word_class: 'N' }
  ],
  polemic: [
    { word: 'polemic', word_class: 'N' },
    { word: 'polemic', word_class: 'AJ' },
    { word: 'polemize', word_class: 'V' },
    { word: 'polemical', word_class: 'AJ' },
    { word: 'polemically', word_class: 'AV' }
  ],
  firmness: [
    { word: 'firm', word_class: 'N' },
    { word: 'firm', word_class: 'V' },
    { word: 'firm', word_class: 'AJ' },
    { word: 'firm', word_class: 'AV' },
    { word: 'firmness', word_class: 'N' }
  ],
  middleman: [
    { word: 'middle', word_class: 'N' },
    { word: 'middle', word_class: 'V' },
    { word: 'middle', word_class: 'AJ' },
    { word: 'middling', word_class: 'N' },
    { word: 'middleman', word_class: 'N' },
    { word: 'middling', word_class: 'AJ' },
    { word: 'middling', word_class: 'AV' }
  ],
  fattiness: [
    { word: 'fatty', word_class: 'N' },
    { word: 'fatty', word_class: 'AJ' },
    { word: 'fattiness', word_class: 'N' }
  ],
  profferment: [
    { word: 'proffer', word_class: 'N' },
    { word: 'proffer', word_class: 'V' },
    { word: 'profferment', word_class: 'N' }
  ],
  sulphur: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  adjudicator: [
    { word: 'adjudicate', word_class: 'V' },
    { word: 'adjudicator', word_class: 'N' },
    { word: 'adjudication', word_class: 'N' },
    { word: 'adjudicative', word_class: 'AJ' }
  ],
  auscultate: [
    { word: 'auscultate', word_class: 'V' },
    { word: 'ausultation', word_class: 'N' },
    { word: 'auscultation', word_class: 'N' }
  ],
  gagman: [{ word: 'gag', word_class: 'N' }, { word: 'gag', word_class: 'V' }, { word: 'gagman', word_class: 'N' }],
  apportionment: [
    { word: 'apportion', word_class: 'V' },
    { word: 'apportioned', word_class: 'AJ' },
    { word: 'apportioning', word_class: 'N' },
    { word: 'apportionment', word_class: 'N' },
    { word: 'apportionable', word_class: 'AJ' }
  ],
  affectation: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  mantled: [
    { word: 'mantle', word_class: 'N' },
    { word: 'mantle', word_class: 'V' },
    { word: 'mantled', word_class: 'AJ' }
  ],
  superannuate: [
    { word: 'superannuate', word_class: 'V' },
    { word: 'superannuated', word_class: 'AJ' },
    { word: 'superannuation', word_class: 'N' }
  ],
  attitude: [
    { word: 'attitude', word_class: 'N' },
    { word: 'attitudinal', word_class: 'AJ' },
    { word: 'attitudinize', word_class: 'V' },
    { word: 'attitudinization', word_class: 'N' }
  ],
  choke: [
    { word: 'choke', word_class: 'N' },
    { word: 'choke', word_class: 'V' },
    { word: 'choker', word_class: 'N' },
    { word: 'choked', word_class: 'AJ' },
    { word: 'choking', word_class: 'N' },
    { word: 'choking', word_class: 'AJ' }
  ],
  parader: [
    { word: 'parade', word_class: 'N' },
    { word: 'parade', word_class: 'V' },
    { word: 'parader', word_class: 'N' },
    { word: 'paradize', word_class: 'V' }
  ],
  starving: [
    { word: 'starve', word_class: 'V' },
    { word: 'starved', word_class: 'AJ' },
    { word: 'starving', word_class: 'N' },
    { word: 'starving', word_class: 'AJ' },
    { word: 'starvation', word_class: 'N' }
  ],
  expect: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  neutralisation: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  puking: [{ word: 'puke', word_class: 'N' }, { word: 'puke', word_class: 'V' }, { word: 'puking', word_class: 'N' }],
  commissioner: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  vernalize: [
    { word: 'vernal', word_class: 'AJ' },
    { word: 'vernalise', word_class: 'V' },
    { word: 'vernalize', word_class: 'V' },
    { word: 'vernalisation', word_class: 'N' },
    { word: 'vernalization', word_class: 'N' }
  ],
  cork: [
    { word: 'cork', word_class: 'N' },
    { word: 'cork', word_class: 'V' },
    { word: 'corker', word_class: 'N' },
    { word: 'corked', word_class: 'AJ' },
    { word: 'corking', word_class: 'AJ' }
  ],
  blemish: [
    { word: 'blemish', word_class: 'N' },
    { word: 'blemish', word_class: 'V' },
    { word: 'blemished', word_class: 'AJ' },
    { word: 'blemishment', word_class: 'N' }
  ],
  forge: [
    { word: 'forge', word_class: 'N' },
    { word: 'forge', word_class: 'V' },
    { word: 'forger', word_class: 'N' },
    { word: 'forged', word_class: 'AJ' },
    { word: 'forgery', word_class: 'N' },
    { word: 'forging', word_class: 'N' },
    { word: 'forgery', word_class: 'AJ' }
  ],
  ingraftment: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  dispersed: [
    { word: 'disperse', word_class: 'V' },
    { word: 'dispersal', word_class: 'N' },
    { word: 'dispersed', word_class: 'AJ' },
    { word: 'dispersion', word_class: 'N' },
    { word: 'dispersive', word_class: 'AJ' }
  ],
  testis: [
    { word: 'testis', word_class: 'N' },
    { word: 'testy', word_class: 'AJ' },
    { word: 'testiness', word_class: 'N' }
  ],
  practically: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  inordinateness: [
    { word: 'inordinate', word_class: 'N' },
    { word: 'inordinate', word_class: 'AJ' },
    { word: 'inordinately', word_class: 'AV' },
    { word: 'inordinateness', word_class: 'N' }
  ],
  diagnosing: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  matt: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  codification: [
    { word: 'codify', word_class: 'V' },
    { word: 'codified', word_class: 'AJ' },
    { word: 'codification', word_class: 'N' }
  ],
  soliloquize: [
    { word: 'soliloquy', word_class: 'N' },
    { word: 'soliloquize', word_class: 'V' },
    { word: 'soliloquization', word_class: 'N' }
  ],
  hijacker: [
    { word: 'hijack', word_class: 'N' },
    { word: 'hijack', word_class: 'V' },
    { word: 'hijacker', word_class: 'N' },
    { word: 'hijacking', word_class: 'N' },
    { word: 'hijacking', word_class: 'AJ' }
  ],
  reticule: [
    { word: 'reticule', word_class: 'N' },
    { word: 'reticulate', word_class: 'V' },
    { word: 'reticulate', word_class: 'AJ' },
    { word: 'reticulated', word_class: 'AJ' },
    { word: 'reticulation', word_class: 'N' }
  ],
  monumentalize: [
    { word: 'monument', word_class: 'N' },
    { word: 'monumental', word_class: 'AJ' },
    { word: 'monumentalize', word_class: 'V' }
  ],
  tumultuous: [
    { word: 'tumult', word_class: 'N' },
    { word: 'tumultuous', word_class: 'AJ' },
    { word: 'tumultuously', word_class: 'AV' },
    { word: 'tumultuousness', word_class: 'N' }
  ],
  squeal: [
    { word: 'squeal', word_class: 'N' },
    { word: 'squeal', word_class: 'V' },
    { word: 'squealer', word_class: 'N' },
    { word: 'squealing', word_class: 'AJ' }
  ],
  belligerently: [
    { word: 'belligerent', word_class: 'N' },
    { word: 'belligerence', word_class: 'N' },
    { word: 'belligerency', word_class: 'N' },
    { word: 'belligerent', word_class: 'AJ' },
    { word: 'belligerently', word_class: 'AV' }
  ],
  shapely: [
    { word: 'shape', word_class: 'N' },
    { word: 'shape', word_class: 'V' },
    { word: 'shaper', word_class: 'N' },
    { word: 'shaped', word_class: 'AJ' },
    { word: 'shaping', word_class: 'N' },
    { word: 'shapely', word_class: 'AJ' },
    { word: 'shaping', word_class: 'AJ' }
  ],
  nasal: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  saved: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  cramped: [
    { word: 'cramp', word_class: 'N' },
    { word: 'cramp', word_class: 'V' },
    { word: 'cramped', word_class: 'AJ' }
  ],
  hoax: [{ word: 'hoax', word_class: 'N' }, { word: 'hoax', word_class: 'V' }, { word: 'hoaxer', word_class: 'N' }],
  clanging: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  unnaturalized: [
    { word: 'unnatural', word_class: 'AJ' },
    { word: 'unnaturally', word_class: 'AV' },
    { word: 'unnaturalness', word_class: 'N' },
    { word: 'unnaturalized', word_class: 'AJ' }
  ],
  plastered: [
    { word: 'plaster', word_class: 'N' },
    { word: 'plaster', word_class: 'V' },
    { word: 'plasterer', word_class: 'N' },
    { word: 'plastered', word_class: 'AJ' },
    { word: 'plastering', word_class: 'N' }
  ],
  slenderize: [
    { word: 'slender', word_class: 'AJ' },
    { word: 'slenderize', word_class: 'V' },
    { word: 'slenderness', word_class: 'N' },
    { word: 'slenderization', word_class: 'N' }
  ],
  cataclysmic: [
    { word: 'cataclysm', word_class: 'N' },
    { word: 'cataclysmal', word_class: 'AJ' },
    { word: 'cataclysmic', word_class: 'AJ' }
  ],
  intensify: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  issuer: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  automat: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  encrust: [
    { word: 'encrust', word_class: 'V' },
    { word: 'incrust', word_class: 'V' },
    { word: 'encrusted', word_class: 'AJ' },
    { word: 'encrustation', word_class: 'N' },
    { word: 'incrustation', word_class: 'N' }
  ],
  muckrake: [
    { word: 'muckrake', word_class: 'V' },
    { word: 'muckraker', word_class: 'N' },
    { word: 'muckraking', word_class: 'N' }
  ],
  foster: [
    { word: 'foster', word_class: 'V' },
    { word: 'foster', word_class: 'AJ' },
    { word: 'fosterage', word_class: 'N' },
    { word: 'fostered', word_class: 'AJ' },
    { word: 'fostering', word_class: 'N' }
  ],
  solicitously: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  incursive: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  aerially: [
    { word: 'aerial', word_class: 'N' },
    { word: 'aerial', word_class: 'AJ' },
    { word: 'aerially', word_class: 'AV' }
  ],
  addiction: [
    { word: 'addict', word_class: 'N' },
    { word: 'addict', word_class: 'V' },
    { word: 'addicted', word_class: 'AJ' },
    { word: 'addiction', word_class: 'N' },
    { word: 'addictive', word_class: 'AJ' }
  ],
  justified: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  flavored: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  clam: [
    { word: 'clam', word_class: 'N' },
    { word: 'clam', word_class: 'V' },
    { word: 'clamor', word_class: 'N' },
    { word: 'clamor', word_class: 'V' },
    { word: 'clamoring', word_class: 'N' },
    { word: 'clamorous', word_class: 'AJ' },
    { word: 'clamorously', word_class: 'AV' }
  ],
  numerical: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  verifying: [
    { word: 'verify', word_class: 'V' },
    { word: 'verified', word_class: 'AJ' },
    { word: 'verifying', word_class: 'AJ' },
    { word: 'verifiable', word_class: 'AJ' },
    { word: 'verification', word_class: 'N' }
  ],
  flavourous: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  gelding: [
    { word: 'geld', word_class: 'V' },
    { word: 'gelded', word_class: 'AJ' },
    { word: 'gelding', word_class: 'N' }
  ],
  accouter: [
    { word: 'accouter', word_class: 'V' },
    { word: 'accoutered', word_class: 'AJ' },
    { word: 'accouterment', word_class: 'N' }
  ],
  disagree: [
    { word: 'disagree', word_class: 'V' },
    { word: 'disagreement', word_class: 'N' },
    { word: 'disagreeable', word_class: 'AJ' },
    { word: 'disagreeably', word_class: 'AV' },
    { word: 'disagreeableness', word_class: 'N' }
  ],
  triangulate: [
    { word: 'triangulate', word_class: 'V' },
    { word: 'triangulate', word_class: 'AJ' },
    { word: 'triangulation', word_class: 'N' }
  ],
  calumny: [
    { word: 'calumny', word_class: 'N' },
    { word: 'calumniate', word_class: 'V' },
    { word: 'calumnious', word_class: 'AJ' },
    { word: 'calumniation', word_class: 'N' }
  ],
  chaotic: [
    { word: 'chaos', word_class: 'N' },
    { word: 'chaotic', word_class: 'AJ' },
    { word: 'chaotically', word_class: 'AV' }
  ],
  periodically: [
    { word: 'period', word_class: 'N' },
    { word: 'periodic', word_class: 'AJ' },
    { word: 'periodical', word_class: 'N' },
    { word: 'periodical', word_class: 'AJ' },
    { word: 'periodicity', word_class: 'N' },
    { word: 'periodically', word_class: 'AV' }
  ],
  inefficiently: [
    { word: 'inefficiency', word_class: 'N' },
    { word: 'inefficient', word_class: 'AJ' },
    { word: 'inefficiently', word_class: 'AV' }
  ],
  numb: [
    { word: 'numb', word_class: 'V' },
    { word: 'numb', word_class: 'AJ' },
    { word: 'numbing', word_class: 'AJ' },
    { word: 'numbness', word_class: 'N' }
  ],
  exclamation: [
    { word: 'exclaim', word_class: 'N' },
    { word: 'exclaim', word_class: 'V' },
    { word: 'exclaiming', word_class: 'N' },
    { word: 'exclamation', word_class: 'N' },
    { word: 'exclamatory', word_class: 'AJ' }
  ],
  imitate: [
    { word: 'imitate', word_class: 'V' },
    { word: 'imitator', word_class: 'N' },
    { word: 'imitable', word_class: 'AJ' },
    { word: 'imitation', word_class: 'N' },
    { word: 'imitation', word_class: 'AJ' },
    { word: 'imitative', word_class: 'AJ' }
  ],
  pianissimo: [
    { word: 'pianissimo', word_class: 'N' },
    { word: 'pianissimo', word_class: 'AJ' },
    { word: 'pianissimo', word_class: 'AV' }
  ],
  frailty: [
    { word: 'frail', word_class: 'N' },
    { word: 'frail', word_class: 'AJ' },
    { word: 'frailty', word_class: 'N' },
    { word: 'frailness', word_class: 'N' }
  ],
  ironically: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  geared: [
    { word: 'gear', word_class: 'N' },
    { word: 'gear', word_class: 'V' },
    { word: 'gears', word_class: 'N' },
    { word: 'geared', word_class: 'AJ' },
    { word: 'gearing', word_class: 'N' }
  ],
  summate: [
    { word: 'sum', word_class: 'N' },
    { word: 'sum', word_class: 'V' },
    { word: 'summate', word_class: 'V' },
    { word: 'summation', word_class: 'N' },
    { word: 'summational', word_class: 'AJ' }
  ],
  lissom: [
    { word: 'lissom', word_class: 'AJ' },
    { word: 'lissome', word_class: 'AJ' },
    { word: 'lissomeness', word_class: 'N' }
  ],
  carbonate: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  barterer: [
    { word: 'barter', word_class: 'N' },
    { word: 'barter', word_class: 'V' },
    { word: 'barterer', word_class: 'N' }
  ],
  endanger: [
    { word: 'endanger', word_class: 'V' },
    { word: 'endangered', word_class: 'AJ' },
    { word: 'endangerment', word_class: 'N' }
  ],
  myelinic: [
    { word: 'myelin', word_class: 'N' },
    { word: 'myeline', word_class: 'N' },
    { word: 'myelinic', word_class: 'AJ' },
    { word: 'myelinated', word_class: 'AJ' }
  ],
  flexibility: [
    { word: 'flexible', word_class: 'AJ' },
    { word: 'flexibly', word_class: 'AV' },
    { word: 'flexibility', word_class: 'N' },
    { word: 'flexibleness', word_class: 'N' }
  ],
  proportion: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  precise: [
    { word: 'precis', word_class: 'V' },
    { word: 'precis', word_class: 'N' },
    { word: 'precise', word_class: 'AJ' },
    { word: 'precision', word_class: 'N' },
    { word: 'precisely', word_class: 'AV' },
    { word: 'preciseness', word_class: 'N' }
  ],
  sintered: [
    { word: 'sinter', word_class: 'N' },
    { word: 'sinter', word_class: 'V' },
    { word: 'sintered', word_class: 'AJ' }
  ],
  learned: [
    { word: 'learn', word_class: 'V' },
    { word: 'learner', word_class: 'N' },
    { word: 'learned', word_class: 'AJ' },
    { word: 'learning', word_class: 'N' }
  ],
  convolute: [
    { word: 'convolve', word_class: 'V' },
    { word: 'convolute', word_class: 'V' },
    { word: 'convolute', word_class: 'AJ' },
    { word: 'convoluted', word_class: 'AJ' },
    { word: 'convolution', word_class: 'N' }
  ],
  sourness: [
    { word: 'sour', word_class: 'N' },
    { word: 'sour', word_class: 'V' },
    { word: 'sour', word_class: 'AJ' },
    { word: 'soured', word_class: 'AJ' },
    { word: 'souring', word_class: 'N' },
    { word: 'sourness', word_class: 'N' }
  ],
  exclusive: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  sentimental: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  resolution: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  voyeuristical: [
    { word: 'voyeuristic', word_class: 'AJ' },
    { word: 'voyeuristical', word_class: 'AJ' },
    { word: 'voyeuristically', word_class: 'AV' }
  ],
  splash: [
    { word: 'splash', word_class: 'N' },
    { word: 'splash', word_class: 'V' },
    { word: 'splashed', word_class: 'AJ' },
    { word: 'splashing', word_class: 'N' }
  ],
  approbate: [
    { word: 'approbate', word_class: 'V' },
    { word: 'approbation', word_class: 'N' },
    { word: 'approbative', word_class: 'AJ' }
  ],
  paging: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  perplexity: [
    { word: 'perplex', word_class: 'V' },
    { word: 'perplexed', word_class: 'AJ' },
    { word: 'perplexity', word_class: 'N' },
    { word: 'perplexing', word_class: 'AJ' }
  ],
  shedding: [
    { word: 'shed', word_class: 'N' },
    { word: 'shed', word_class: 'V' },
    { word: 'shed', word_class: 'AJ' },
    { word: 'shedding', word_class: 'N' }
  ],
  pollenate: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  custom: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  converse: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  sufficient: [
    { word: 'suffice', word_class: 'V' },
    { word: 'sufficiency', word_class: 'N' },
    { word: 'sufficient', word_class: 'AJ' },
    { word: 'sufficiently', word_class: 'AV' }
  ],
  deceased: [
    { word: 'decease', word_class: 'N' },
    { word: 'decease', word_class: 'V' },
    { word: 'deceased', word_class: 'N' },
    { word: 'deceased', word_class: 'AJ' }
  ],
  italicize: [
    { word: 'italicise', word_class: 'V' },
    { word: 'italicize', word_class: 'V' },
    { word: 'italicisation', word_class: 'N' },
    { word: 'italicization', word_class: 'N' }
  ],
  propagandist: [
    { word: 'propaganda', word_class: 'N' },
    { word: 'propagandist', word_class: 'N' },
    { word: 'propagandize', word_class: 'V' },
    { word: 'propagandist', word_class: 'AJ' },
    { word: 'propagandistic', word_class: 'AJ' },
    { word: 'propagandization', word_class: 'N' }
  ],
  perspicuousness: [
    { word: 'perspicuity', word_class: 'N' },
    { word: 'perspicuous', word_class: 'AJ' },
    { word: 'perspicuously', word_class: 'AV' },
    { word: 'perspicuousness', word_class: 'N' }
  ],
  cohesion: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  phosphorescent: [
    { word: 'phosphoresce', word_class: 'V' },
    { word: 'phosphorescence', word_class: 'N' },
    { word: 'phosphorescent', word_class: 'AJ' }
  ],
  scatter: [
    { word: 'scatter', word_class: 'N' },
    { word: 'scatter', word_class: 'V' },
    { word: 'scattered', word_class: 'AJ' },
    { word: 'scattering', word_class: 'N' },
    { word: 'scattering', word_class: 'AJ' }
  ],
  humbling: [
    { word: 'humble', word_class: 'V' },
    { word: 'humble', word_class: 'AJ' },
    { word: 'humbly', word_class: 'AV' },
    { word: 'humbled', word_class: 'AJ' },
    { word: 'humbling', word_class: 'N' },
    { word: 'humbling', word_class: 'AJ' },
    { word: 'humbleness', word_class: 'N' }
  ],
  denationalization: [
    { word: 'denationalise', word_class: 'V' },
    { word: 'denationalize', word_class: 'V' },
    { word: 'denationalisation', word_class: 'N' },
    { word: 'denationalization', word_class: 'N' }
  ],
  ford: [{ word: 'ford', word_class: 'N' }, { word: 'ford', word_class: 'V' }, { word: 'fording', word_class: 'N' }],
  comparatively: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  surveyor: [
    { word: 'survey', word_class: 'N' },
    { word: 'survey', word_class: 'V' },
    { word: 'surveyor', word_class: 'N' },
    { word: 'surveying', word_class: 'N' }
  ],
  institutionalization: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  systematic: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  placating: [
    { word: 'placate', word_class: 'V' },
    { word: 'placation', word_class: 'N' },
    { word: 'placating', word_class: 'AJ' }
  ],
  perjurer: [
    { word: 'perjure', word_class: 'V' },
    { word: 'perjury', word_class: 'N' },
    { word: 'perjurer', word_class: 'N' }
  ],
  immovable: [
    { word: 'immovable', word_class: 'AJ' },
    { word: 'immovably', word_class: 'AV' },
    { word: 'immoveable', word_class: 'AJ' },
    { word: 'immovability', word_class: 'N' },
    { word: 'immovableness', word_class: 'N' }
  ],
  bilateralism: [
    { word: 'bilateral', word_class: 'AJ' },
    { word: 'bilateralism', word_class: 'N' },
    { word: 'bilaterality', word_class: 'N' },
    { word: 'bilaterally', word_class: 'AV' }
  ],
  unobservant: [
    { word: 'unobserved', word_class: 'AJ' },
    { word: 'unobservant', word_class: 'AJ' },
    { word: 'unobservable', word_class: 'AJ' }
  ],
  presented: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  severeness: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  traitorously: [
    { word: 'traitor', word_class: 'N' },
    { word: 'traitorous', word_class: 'AJ' },
    { word: 'traitorously', word_class: 'AV' },
    { word: 'traitorousness', word_class: 'N' }
  ],
  gaffe: [
    { word: 'gaff', word_class: 'N' },
    { word: 'gaff', word_class: 'V' },
    { word: 'gaffe', word_class: 'N' },
    { word: 'gaffer', word_class: 'N' }
  ],
  unbelievably: [
    { word: 'unbeliever', word_class: 'N' },
    { word: 'unbelieving', word_class: 'AJ' },
    { word: 'unbelievable', word_class: 'AJ' },
    { word: 'unbelievably', word_class: 'AV' }
  ],
  modest: [
    { word: 'modest', word_class: 'AJ' },
    { word: 'modesty', word_class: 'N' },
    { word: 'modestness', word_class: 'N' }
  ],
  hypochondriac: [
    { word: 'hypochondria', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'AJ' },
    { word: 'hypochondriacal', word_class: 'AJ' }
  ],
  cleanse: [
    { word: 'cleanse', word_class: 'V' },
    { word: 'cleanser', word_class: 'N' },
    { word: 'cleansed', word_class: 'AJ' },
    { word: 'cleansing', word_class: 'N' },
    { word: 'cleansing', word_class: 'AJ' }
  ],
  exactness: [
    { word: 'exact', word_class: 'N' },
    { word: 'exact', word_class: 'V' },
    { word: 'exact', word_class: 'AJ' },
    { word: 'exaction', word_class: 'N' },
    { word: 'exacting', word_class: 'AJ' },
    { word: 'exactness', word_class: 'N' },
    { word: 'exactitude', word_class: 'N' }
  ],
  automatic: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  pithy: [
    { word: 'pithy', word_class: 'AJ' },
    { word: 'pithy', word_class: 'AV' },
    { word: 'pithiness', word_class: 'N' }
  ],
  examination: [
    { word: 'examine', word_class: 'N' },
    { word: 'examine', word_class: 'V' },
    { word: 'examinee', word_class: 'N' },
    { word: 'examiner', word_class: 'N' },
    { word: 'examination', word_class: 'N' }
  ],
  framed: [
    { word: 'frame', word_class: 'N' },
    { word: 'frame', word_class: 'V' },
    { word: 'framer', word_class: 'N' },
    { word: 'frames', word_class: 'V' },
    { word: 'framed', word_class: 'AJ' },
    { word: 'framing', word_class: 'N' }
  ],
  consecration: [
    { word: 'consecrate', word_class: 'V' },
    { word: 'consecrate', word_class: 'AJ' },
    { word: 'consecrated', word_class: 'AJ' },
    { word: 'consecration', word_class: 'N' }
  ],
  maneuvering: [
    { word: 'maneuver', word_class: 'N' },
    { word: 'maneuver', word_class: 'V' },
    { word: 'maneuvering', word_class: 'N' }
  ],
  formulization: [
    { word: 'formulate', word_class: 'V' },
    { word: 'formulize', word_class: 'V' },
    { word: 'formulated', word_class: 'AJ' },
    { word: 'formulation', word_class: 'N' },
    { word: 'formulization', word_class: 'N' }
  ],
  subdued: [
    { word: 'subdue', word_class: 'N' },
    { word: 'subdue', word_class: 'V' },
    { word: 'subdual', word_class: 'N' },
    { word: 'subdue', word_class: 'AJ' },
    { word: 'subdued', word_class: 'AJ' }
  ],
  refrigerator: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  homewards: [
    { word: 'homeward', word_class: 'AJ' },
    { word: 'homeward', word_class: 'AV' },
    { word: 'homewards', word_class: 'AV' }
  ],
  goods: [
    { word: 'good', word_class: 'N' },
    { word: 'good', word_class: 'AJ' },
    { word: 'good', word_class: 'AV' },
    { word: 'goods', word_class: 'N' },
    { word: 'goodness', word_class: 'N' }
  ],
  blessing: [
    { word: 'bless', word_class: 'V' },
    { word: 'blessed', word_class: 'AJ' },
    { word: 'blessing', word_class: 'N' }
  ],
  concrn: [
    { word: 'concrn', word_class: 'V' },
    { word: 'concern', word_class: 'N' },
    { word: 'concern', word_class: 'V' },
    { word: 'concerned', word_class: 'AJ' },
    { word: 'concerning', word_class: 'AJ' }
  ],
  autumnal: [
    { word: 'autumn', word_class: 'N' },
    { word: 'autumn', word_class: 'AJ' },
    { word: 'autumnal', word_class: 'AJ' }
  ],
  waterproofing: [
    { word: 'waterproof', word_class: 'N' },
    { word: 'waterproof', word_class: 'V' },
    { word: 'waterproof', word_class: 'AJ' },
    { word: 'waterproofed', word_class: 'AJ' },
    { word: 'waterproofing', word_class: 'N' }
  ],
  cream: [
    { word: 'cream', word_class: 'N' },
    { word: 'cream', word_class: 'V' },
    { word: 'cream', word_class: 'AJ' },
    { word: 'creamer', word_class: 'N' }
  ],
  imprudently: [
    { word: 'imprudence', word_class: 'N' },
    { word: 'imprudent', word_class: 'AJ' },
    { word: 'imprudently', word_class: 'AV' }
  ],
  checkered: [
    { word: 'check', word_class: 'N' },
    { word: 'check', word_class: 'V' },
    { word: 'checker', word_class: 'N' },
    { word: 'checker', word_class: 'V' },
    { word: 'checked', word_class: 'AJ' },
    { word: 'checkers', word_class: 'N' },
    { word: 'checkered', word_class: 'AJ' }
  ],
  aide: [
    { word: 'aid', word_class: 'N' },
    { word: 'aid', word_class: 'V' },
    { word: 'aide', word_class: 'N' },
    { word: 'aids', word_class: 'N' },
    { word: 'aides', word_class: 'N' },
    { word: 'aided', word_class: 'AJ' }
  ],
  sale: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  dragoonage: [
    { word: 'dragoon', word_class: 'N' },
    { word: 'dragoon', word_class: 'V' },
    { word: 'dragoonage', word_class: 'N' }
  ],
  taper: [
    { word: 'taper', word_class: 'N' },
    { word: 'taper', word_class: 'V' },
    { word: 'tapered', word_class: 'AJ' },
    { word: 'tapering', word_class: 'N' },
    { word: 'tapering', word_class: 'AJ' }
  ],
  menace: [
    { word: 'menace', word_class: 'N' },
    { word: 'menace', word_class: 'V' },
    { word: 'menacing', word_class: 'AJ' }
  ],
  defame: [
    { word: 'defame', word_class: 'V' },
    { word: 'defamer', word_class: 'N' },
    { word: 'defamation', word_class: 'N' },
    { word: 'defamatory', word_class: 'AJ' }
  ],
  wisely: [
    { word: 'wise', word_class: 'N' },
    { word: 'wise', word_class: 'AJ' },
    { word: 'wisdom', word_class: 'N' },
    { word: 'wisely', word_class: 'AV' },
    { word: 'wiseness', word_class: 'N' }
  ],
  competitively: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  penetrability: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  possessive: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  ariled: [
    { word: 'aril', word_class: 'N' },
    { word: 'ariled', word_class: 'AJ' },
    { word: 'arillate', word_class: 'AJ' }
  ],
  matte: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  scandalize: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  vitrification: [
    { word: 'vitrify', word_class: 'V' },
    { word: 'vitrified', word_class: 'AJ' },
    { word: 'vitrification', word_class: 'N' }
  ],
  appropriateness: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  occluded: [
    { word: 'occlude', word_class: 'V' },
    { word: 'occluded', word_class: 'AJ' },
    { word: 'occlusion', word_class: 'N' },
    { word: 'occlusive', word_class: 'N' },
    { word: 'occlusive', word_class: 'AJ' }
  ],
  pawning: [
    { word: 'pawn', word_class: 'N' },
    { word: 'pawn', word_class: 'V' },
    { word: 'pawnee', word_class: 'N' },
    { word: 'pawnage', word_class: 'N' },
    { word: 'pawning', word_class: 'N' }
  ],
  mail: [
    { word: 'mail', word_class: 'N' },
    { word: 'mail', word_class: 'V' },
    { word: 'mailer', word_class: 'N' },
    { word: 'mailman', word_class: 'N' },
    { word: 'mailing', word_class: 'N' },
    { word: 'mailed', word_class: 'AJ' }
  ],
  superimposition: [
    { word: 'superimpose', word_class: 'V' },
    { word: 'superimposed', word_class: 'AJ' },
    { word: 'superimposition', word_class: 'N' }
  ],
  keratinise: [
    { word: 'keratin', word_class: 'N' },
    { word: 'keratinise', word_class: 'V' },
    { word: 'keratinize', word_class: 'V' },
    { word: 'keratinisation', word_class: 'N' },
    { word: 'keratinization', word_class: 'N' }
  ],
  defiled: [
    { word: 'defile', word_class: 'N' },
    { word: 'defile', word_class: 'V' },
    { word: 'defiled', word_class: 'AJ' },
    { word: 'defilement', word_class: 'N' }
  ],
  separably: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  agrobiological: [
    { word: 'agrobiology', word_class: 'N' },
    { word: 'agrobiologic', word_class: 'AJ' },
    { word: 'agrobiological', word_class: 'AJ' }
  ],
  panhandler: [
    { word: 'panhandle', word_class: 'N' },
    { word: 'panhandle', word_class: 'V' },
    { word: 'panhandler', word_class: 'N' }
  ],
  perceptibly: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  compelled: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  piling: [
    { word: 'pile', word_class: 'N' },
    { word: 'pile', word_class: 'V' },
    { word: 'piles', word_class: 'N' },
    { word: 'piled', word_class: 'AJ' },
    { word: 'piling', word_class: 'N' }
  ],
  approximate: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  dehiscence: [
    { word: 'dehisce', word_class: 'V' },
    { word: 'dehiscence', word_class: 'N' },
    { word: 'dehiscent', word_class: 'AJ' }
  ],
  rabbinical: [
    { word: 'rabbi', word_class: 'N' },
    { word: 'rabbinate', word_class: 'N' },
    { word: 'rabbinic', word_class: 'AJ' },
    { word: 'rabbinical', word_class: 'AJ' }
  ],
  symbol: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  momently: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  handwrite: [
    { word: 'handwrite', word_class: 'V' },
    { word: 'handwriting', word_class: 'N' },
    { word: 'handwritten', word_class: 'AJ' }
  ],
  manipulation: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  grandiloquently: [
    { word: 'grandiloquence', word_class: 'N' },
    { word: 'grandiloquent', word_class: 'AJ' },
    { word: 'grandiloquently', word_class: 'AV' }
  ],
  convincing: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  mischievousness: [
    { word: 'mischief', word_class: 'N' },
    { word: 'mischievous', word_class: 'AJ' },
    { word: 'mischievously', word_class: 'AV' },
    { word: 'mischievousness', word_class: 'N' }
  ],
  potion: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  gliding: [
    { word: 'glide', word_class: 'N' },
    { word: 'glide', word_class: 'V' },
    { word: 'glider', word_class: 'N' },
    { word: 'gliding', word_class: 'N' }
  ],
  provincially: [
    { word: 'provincial', word_class: 'N' },
    { word: 'provincial', word_class: 'AJ' },
    { word: 'provincialism', word_class: 'N' },
    { word: 'provincially', word_class: 'AV' }
  ],
  furrow: [
    { word: 'furrow', word_class: 'N' },
    { word: 'furrow', word_class: 'V' },
    { word: 'furrowed', word_class: 'AJ' }
  ],
  illegalise: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  antiquated: [
    { word: 'antique', word_class: 'N' },
    { word: 'antique', word_class: 'V' },
    { word: 'antique', word_class: 'AJ' },
    { word: 'antiquate', word_class: 'V' },
    { word: 'antiquity', word_class: 'N' },
    { word: 'antiquated', word_class: 'AJ' },
    { word: 'antiquation', word_class: 'N' }
  ],
  drilled: [
    { word: 'drill', word_class: 'N' },
    { word: 'drill', word_class: 'V' },
    { word: 'drilled', word_class: 'AJ' },
    { word: 'drilling', word_class: 'N' }
  ],
  ulcerative: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  hypostatization: [
    { word: 'hypostasis', word_class: 'N' },
    { word: 'hypostatise', word_class: 'V' },
    { word: 'hypostatize', word_class: 'V' },
    { word: 'hypostatisation', word_class: 'N' },
    { word: 'hypostatization', word_class: 'N' }
  ],
  exotic: [
    { word: 'exotic', word_class: 'AJ' },
    { word: 'exotism', word_class: 'N' },
    { word: 'exoticness', word_class: 'N' }
  ],
  impovempoverishment: [
    { word: 'impoverish', word_class: 'V' },
    { word: 'impoverished', word_class: 'AJ' },
    { word: 'impoverishment', word_class: 'N' },
    { word: 'impovempoverish', word_class: 'V' },
    { word: 'impovempoverishment', word_class: 'N' }
  ],
  oxygenated: [
    { word: 'oxygen', word_class: 'N' },
    { word: 'oxygenate', word_class: 'V' },
    { word: 'oxygenize', word_class: 'V' },
    { word: 'oxygenated', word_class: 'AJ' },
    { word: 'oxygenation', word_class: 'N' }
  ],
  withering: [
    { word: 'wither', word_class: 'V' },
    { word: 'withers', word_class: 'N' },
    { word: 'withered', word_class: 'AJ' },
    { word: 'withering', word_class: 'N' },
    { word: 'withering', word_class: 'AJ' }
  ],
  webbed: [
    { word: 'web', word_class: 'N' },
    { word: 'web', word_class: 'V' },
    { word: 'webbed', word_class: 'AJ' },
    { word: 'webbing', word_class: 'N' }
  ],
  negotiation: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  metabolization: [
    { word: 'metabolic', word_class: 'AJ' },
    { word: 'metabolism', word_class: 'N' },
    { word: 'metabolize', word_class: 'V' },
    { word: 'metabolous', word_class: 'AJ' },
    { word: 'metabolization', word_class: 'N' }
  ],
  loosen: [
    { word: 'loosen', word_class: 'V' },
    { word: 'loosened', word_class: 'AJ' },
    { word: 'loosening', word_class: 'N' }
  ],
  boot: [
    { word: 'boot', word_class: 'N' },
    { word: 'boot', word_class: 'V' },
    { word: 'bootee', word_class: 'N' },
    { word: 'bootes', word_class: 'N' },
    { word: 'booted', word_class: 'AJ' },
    { word: 'bootees', word_class: 'N' }
  ],
  refrigerating: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  glycerole: [
    { word: 'glycerol', word_class: 'N' },
    { word: 'glycerole', word_class: 'N' },
    { word: 'glycerolize', word_class: 'V' }
  ],
  lamination: [
    { word: 'laminate', word_class: 'N' },
    { word: 'laminate', word_class: 'V' },
    { word: 'laminitis', word_class: 'N' },
    { word: 'lamination', word_class: 'N' }
  ],
  sunken: [
    { word: 'sink', word_class: 'N' },
    { word: 'sink', word_class: 'V' },
    { word: 'sinker', word_class: 'N' },
    { word: 'sinkage', word_class: 'N' },
    { word: 'sinking', word_class: 'N' },
    { word: 'sunken', word_class: 'AJ' }
  ],
  reformation: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  retrogress: [
    { word: 'retrogress', word_class: 'V' },
    { word: 'retrogression', word_class: 'N' },
    { word: 'retrogressive', word_class: 'AJ' }
  ],
  uniformness: [
    { word: 'uniform', word_class: 'N' },
    { word: 'uniform', word_class: 'V' },
    { word: 'uniform', word_class: 'AJ' },
    { word: 'uniformed', word_class: 'AJ' },
    { word: 'uniformity', word_class: 'N' },
    { word: 'uniformize', word_class: 'V' },
    { word: 'uniformness', word_class: 'N' }
  ],
  hospitably: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  acidulated: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  precedent: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  redundancy: [
    { word: 'redundance', word_class: 'N' },
    { word: 'redundancy', word_class: 'N' },
    { word: 'redundant', word_class: 'AJ' }
  ],
  clamoring: [
    { word: 'clam', word_class: 'N' },
    { word: 'clam', word_class: 'V' },
    { word: 'clamor', word_class: 'N' },
    { word: 'clamor', word_class: 'V' },
    { word: 'clamoring', word_class: 'N' },
    { word: 'clamorous', word_class: 'AJ' },
    { word: 'clamorously', word_class: 'AV' }
  ],
  correlational: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  amendment: [
    { word: 'amend', word_class: 'V' },
    { word: 'amends', word_class: 'N' },
    { word: 'amended', word_class: 'AJ' },
    { word: 'amendment', word_class: 'N' },
    { word: 'amendable', word_class: 'AJ' }
  ],
  homogenisation: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  unmoving: [
    { word: 'unmoved', word_class: 'AJ' },
    { word: 'unmoving', word_class: 'AJ' },
    { word: 'unmovable', word_class: 'AJ' }
  ],
  worsted: [
    { word: 'worst', word_class: 'N' },
    { word: 'worst', word_class: 'V' },
    { word: 'worst', word_class: 'AJ' },
    { word: 'worst', word_class: 'AV' },
    { word: 'worsted', word_class: 'N' }
  ],
  feudalise: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  enfoldment: [
    { word: 'enfold', word_class: 'V' },
    { word: 'enfolding', word_class: 'N' },
    { word: 'enfoldment', word_class: 'N' }
  ],
  labored: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  date: [{ word: 'date', word_class: 'N' }, { word: 'date', word_class: 'V' }, { word: 'dated', word_class: 'AJ' }],
  foot: [
    { word: 'foot', word_class: 'N' },
    { word: 'foot', word_class: 'V' },
    { word: 'footer', word_class: 'N' },
    { word: 'footed', word_class: 'AJ' },
    { word: 'footing', word_class: 'N' },
    { word: 'footman', word_class: 'N' }
  ],
  intelligent: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  draw: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  estrangement: [
    { word: 'estrange', word_class: 'V' },
    { word: 'estranged', word_class: 'AJ' },
    { word: 'estranging', word_class: 'AJ' },
    { word: 'estrangement', word_class: 'N' }
  ],
  compliment: [
    { word: 'compliment', word_class: 'N' },
    { word: 'compliment', word_class: 'V' },
    { word: 'compliments', word_class: 'N' },
    { word: 'complimentary', word_class: 'AJ' }
  ],
  lampoon: [
    { word: 'lampoon', word_class: 'N' },
    { word: 'lampoon', word_class: 'V' },
    { word: 'lampooner', word_class: 'N' }
  ],
  phosphoric: [
    { word: 'phosphor', word_class: 'N' },
    { word: 'phosphorus', word_class: 'N' },
    { word: 'phosphoric', word_class: 'AJ' },
    { word: 'phosphorous', word_class: 'AJ' }
  ],
  ministrant: [
    { word: 'minister', word_class: 'V' },
    { word: 'minister', word_class: 'N' },
    { word: 'ministry', word_class: 'N' },
    { word: 'ministrant', word_class: 'N' },
    { word: 'ministrant', word_class: 'AJ' },
    { word: 'ministering', word_class: 'AJ' },
    { word: 'ministration', word_class: 'N' }
  ],
  renewable: [
    { word: 'renew', word_class: 'N' },
    { word: 'renew', word_class: 'V' },
    { word: 'renew', word_class: 'AJ' },
    { word: 'renewal', word_class: 'N' },
    { word: 'renewal', word_class: 'V' },
    { word: 'renewed', word_class: 'AJ' },
    { word: 'renewing', word_class: 'AJ' },
    { word: 'renewable', word_class: 'AJ' }
  ],
  list: [
    { word: 'list', word_class: 'N' },
    { word: 'list', word_class: 'V' },
    { word: 'lister', word_class: 'N' },
    { word: 'listed', word_class: 'AJ' },
    { word: 'listing', word_class: 'N' }
  ],
  sombrely: [
    { word: 'sombre', word_class: 'AJ' },
    { word: 'sombrely', word_class: 'AV' },
    { word: 'sombreness', word_class: 'N' }
  ],
  tactical: [
    { word: 'tactic', word_class: 'N' },
    { word: 'tactic', word_class: 'AJ' },
    { word: 'tactics', word_class: 'N' },
    { word: 'tactical', word_class: 'AJ' },
    { word: 'tactically', word_class: 'AV' }
  ],
  osculation: [
    { word: 'osculate', word_class: 'V' },
    { word: 'osculator', word_class: 'N' },
    { word: 'osculation', word_class: 'N' }
  ],
  configurational: [
    { word: 'configure', word_class: 'V' },
    { word: 'configured', word_class: 'AJ' },
    { word: 'configuration', word_class: 'N' },
    { word: 'configurational', word_class: 'AJ' }
  ],
  carver: [
    { word: 'carve', word_class: 'V' },
    { word: 'carver', word_class: 'N' },
    { word: 'carved', word_class: 'AJ' },
    { word: 'carving', word_class: 'N' }
  ],
  palm: [
    { word: 'palm', word_class: 'N' },
    { word: 'palm', word_class: 'V' },
    { word: 'palmer', word_class: 'N' },
    { word: 'palmist', word_class: 'N' }
  ],
  bugger: [
    { word: 'bugger', word_class: 'N' },
    { word: 'bugger', word_class: 'V' },
    { word: 'buggery', word_class: 'N' }
  ],
  oftenness: [
    { word: 'often', word_class: 'AV' },
    { word: 'oftener', word_class: 'AV' },
    { word: 'oftenness', word_class: 'N' }
  ],
  align: [
    { word: 'align', word_class: 'V' },
    { word: 'aligned', word_class: 'AJ' },
    { word: 'aligning', word_class: 'AJ' },
    { word: 'alignment', word_class: 'N' }
  ],
  damascened: [
    { word: 'damascene', word_class: 'N' },
    { word: 'damascene', word_class: 'V' },
    { word: 'damascene', word_class: 'AJ' },
    { word: 'damascened', word_class: 'AJ' }
  ],
  centrifugal: [
    { word: 'centrifuge', word_class: 'N' },
    { word: 'centrifuge', word_class: 'V' },
    { word: 'centrifugal', word_class: 'AJ' },
    { word: 'centrifugate', word_class: 'V' },
    { word: 'centrifugation', word_class: 'N' }
  ],
  traduction: [
    { word: 'traduce', word_class: 'V' },
    { word: 'traducer', word_class: 'N' },
    { word: 'traduction', word_class: 'N' },
    { word: 'traducement', word_class: 'N' }
  ],
  fortissimo: [
    { word: 'fortissimo', word_class: 'N' },
    { word: 'fortissimo', word_class: 'AJ' },
    { word: 'fortissimo', word_class: 'AV' }
  ],
  explosion: [
    { word: 'explode', word_class: 'V' },
    { word: 'explosion', word_class: 'N' },
    { word: 'explosive', word_class: 'N' },
    { word: 'exploding', word_class: 'AJ' },
    { word: 'explosive', word_class: 'AJ' },
    { word: 'explosively', word_class: 'AV' }
  ],
  vacillating: [
    { word: 'vacillate', word_class: 'V' },
    { word: 'vacillant', word_class: 'AJ' },
    { word: 'vacillator', word_class: 'N' },
    { word: 'vacillation', word_class: 'N' },
    { word: 'vacillating', word_class: 'AJ' }
  ],
  inattention: [
    { word: 'inattention', word_class: 'N' },
    { word: 'inattentive', word_class: 'AJ' },
    { word: 'inattentively', word_class: 'AV' },
    { word: 'inattentiveness', word_class: 'N' }
  ],
  nationalistic: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  categorical: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  inbreeding: [
    { word: 'inbreed', word_class: 'N' },
    { word: 'inbreed', word_class: 'V' },
    { word: 'inbreeding', word_class: 'N' }
  ],
  impressment: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  sorcerer: [
    { word: 'sorcery', word_class: 'N' },
    { word: 'sorcerer', word_class: 'N' },
    { word: 'sorceress', word_class: 'N' },
    { word: 'sorcerize', word_class: 'V' },
    { word: 'sorcerous', word_class: 'AJ' }
  ],
  advertising: [
    { word: 'advertise', word_class: 'V' },
    { word: 'advertiser', word_class: 'N' },
    { word: 'advertised', word_class: 'AJ' },
    { word: 'advertising', word_class: 'N' },
    { word: 'advertisement', word_class: 'N' }
  ],
  pitch: [
    { word: 'pitch', word_class: 'N' },
    { word: 'pitch', word_class: 'V' },
    { word: 'pitcher', word_class: 'N' },
    { word: 'pitched', word_class: 'AJ' },
    { word: 'pitching', word_class: 'N' },
    { word: 'pitcherful', word_class: 'N' }
  ],
  coatee: [
    { word: 'coat', word_class: 'N' },
    { word: 'coat', word_class: 'V' },
    { word: 'coatee', word_class: 'N' },
    { word: 'coated', word_class: 'AJ' },
    { word: 'coating', word_class: 'N' }
  ],
  slopped: [
    { word: 'slop', word_class: 'N' },
    { word: 'slop', word_class: 'V' },
    { word: 'slops', word_class: 'N' },
    { word: 'slopped', word_class: 'AJ' }
  ],
  poking: [
    { word: 'poke', word_class: 'N' },
    { word: 'poke', word_class: 'V' },
    { word: 'poker', word_class: 'N' },
    { word: 'poking', word_class: 'N' }
  ],
  pawnage: [
    { word: 'pawn', word_class: 'N' },
    { word: 'pawn', word_class: 'V' },
    { word: 'pawnee', word_class: 'N' },
    { word: 'pawnage', word_class: 'N' },
    { word: 'pawning', word_class: 'N' }
  ],
  quibble: [
    { word: 'quibble', word_class: 'N' },
    { word: 'quibble', word_class: 'V' },
    { word: 'quibbler', word_class: 'N' },
    { word: 'quibbling', word_class: 'AJ' }
  ],
  lobate: [
    { word: 'lobate', word_class: 'V' },
    { word: 'lobate', word_class: 'AJ' },
    { word: 'lobated', word_class: 'AJ' },
    { word: 'lobation', word_class: 'N' }
  ],
  tingle: [
    { word: 'tingle', word_class: 'N' },
    { word: 'tingle', word_class: 'V' },
    { word: 'tingly', word_class: 'AJ' },
    { word: 'tingling', word_class: 'N' },
    { word: 'tingling', word_class: 'AJ' }
  ],
  gelatinization: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  carunculated: [
    { word: 'carunculate', word_class: 'AJ' },
    { word: 'carunculous', word_class: 'AJ' },
    { word: 'carunculated', word_class: 'AJ' }
  ],
  dryer: [
    { word: 'dry', word_class: 'N' },
    { word: 'dry', word_class: 'V' },
    { word: 'dry', word_class: 'AJ' },
    { word: 'drier', word_class: 'N' },
    { word: 'dryer', word_class: 'N' },
    { word: 'dryness', word_class: 'N' }
  ],
  macadamize: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  accusive: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  palatal: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  dissimulate: [
    { word: 'dissimulate', word_class: 'V' },
    { word: 'dissimulation', word_class: 'N' },
    { word: 'dissimulating', word_class: 'AJ' },
    { word: 'dissimulative', word_class: 'AJ' }
  ],
  rateables: [
    { word: 'rateable', word_class: 'AJ' },
    { word: 'rateables', word_class: 'N' },
    { word: 'rateability', word_class: 'N' }
  ],
  wallop: [
    { word: 'wallop', word_class: 'N' },
    { word: 'wallop', word_class: 'V' },
    { word: 'walloping', word_class: 'N' },
    { word: 'walloping', word_class: 'AJ' }
  ],
  arouse: [
    { word: 'arouse', word_class: 'V' },
    { word: 'arousal', word_class: 'N' },
    { word: 'aroused', word_class: 'AJ' }
  ],
  psychic: [
    { word: 'psychic', word_class: 'N' },
    { word: 'psychic', word_class: 'AJ' },
    { word: 'psychical', word_class: 'AJ' },
    { word: 'psychically', word_class: 'AV' }
  ],
  critically: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  larynx: [
    { word: 'larynx', word_class: 'N' },
    { word: 'laryngeal', word_class: 'AJ' },
    { word: 'laryngitis', word_class: 'N' }
  ],
  dispensed: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  preterition: [
    { word: 'preterit', word_class: 'N' },
    { word: 'preterit', word_class: 'AJ' },
    { word: 'preterite', word_class: 'N' },
    { word: 'preterite', word_class: 'AJ' },
    { word: 'preterition', word_class: 'N' }
  ],
  walking: [
    { word: 'walk', word_class: 'N' },
    { word: 'walk', word_class: 'V' },
    { word: 'walker', word_class: 'N' },
    { word: 'walking', word_class: 'N' },
    { word: 'walking', word_class: 'AJ' }
  ],
  conquered: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  rhomboidal: [
    { word: 'rhomboid', word_class: 'N' },
    { word: 'rhomboid', word_class: 'AJ' },
    { word: 'rhomboidal', word_class: 'AJ' }
  ],
  artfulness: [
    { word: 'art', word_class: 'N' },
    { word: 'arts', word_class: 'N' },
    { word: 'artful', word_class: 'AJ' },
    { word: 'artistry', word_class: 'N' },
    { word: 'artfulness', word_class: 'N' }
  ],
  feebly: [
    { word: 'feeble', word_class: 'AJ' },
    { word: 'feebly', word_class: 'AV' },
    { word: 'feebleness', word_class: 'N' }
  ],
  pleasurable: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  interesting: [
    { word: 'interest', word_class: 'N' },
    { word: 'interest', word_class: 'V' },
    { word: 'interested', word_class: 'AJ' },
    { word: 'interesting', word_class: 'AJ' }
  ],
  astonish: [
    { word: 'astonish', word_class: 'V' },
    { word: 'astonished', word_class: 'AJ' },
    { word: 'astonishing', word_class: 'AJ' },
    { word: 'astonishment', word_class: 'N' }
  ],
  inaudibleness: [
    { word: 'inaudible', word_class: 'AJ' },
    { word: 'inaudibly', word_class: 'AV' },
    { word: 'inaudibility', word_class: 'N' },
    { word: 'inaudibleness', word_class: 'N' }
  ],
  stabling: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  implemental: [
    { word: 'implement', word_class: 'N' },
    { word: 'implement', word_class: 'V' },
    { word: 'implemental', word_class: 'AJ' },
    { word: 'implemented', word_class: 'AJ' },
    { word: 'implementation', word_class: 'N' }
  ],
  barter: [
    { word: 'barter', word_class: 'N' },
    { word: 'barter', word_class: 'V' },
    { word: 'barterer', word_class: 'N' }
  ],
  dispensable: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  retortion: [
    { word: 'retort', word_class: 'N' },
    { word: 'retort', word_class: 'V' },
    { word: 'retorsion', word_class: 'N' },
    { word: 'retortion', word_class: 'N' }
  ],
  bilge: [{ word: 'bilge', word_class: 'N' }, { word: 'bilge', word_class: 'V' }, { word: 'bilges', word_class: 'N' }],
  earning: [
    { word: 'earn', word_class: 'V' },
    { word: 'earner', word_class: 'N' },
    { word: 'earned', word_class: 'AJ' },
    { word: 'earning', word_class: 'N' },
    { word: 'earnings', word_class: 'N' }
  ],
  shrunken: [
    { word: 'shrink', word_class: 'N' },
    { word: 'shrink', word_class: 'V' },
    { word: 'shrunk', word_class: 'AJ' },
    { word: 'shrinkage', word_class: 'N' },
    { word: 'shrinking', word_class: 'N' },
    { word: 'shrunken', word_class: 'AJ' }
  ],
  lute: [
    { word: 'lute', word_class: 'N' },
    { word: 'lute', word_class: 'V' },
    { word: 'luting', word_class: 'N' },
    { word: 'lutist', word_class: 'N' },
    { word: 'lutanist', word_class: 'N' }
  ],
  classifiable: [
    { word: 'classify', word_class: 'V' },
    { word: 'classifier', word_class: 'N' },
    { word: 'classified', word_class: 'AJ' },
    { word: 'classifiable', word_class: 'AJ' },
    { word: 'classification', word_class: 'N' }
  ],
  equivalent: [
    { word: 'equivalent', word_class: 'N' },
    { word: 'equivalence', word_class: 'N' },
    { word: 'equivalency', word_class: 'N' },
    { word: 'equivalent', word_class: 'AJ' }
  ],
  imperturbableness: [
    { word: 'imperturbable', word_class: 'AJ' },
    { word: 'imperturbability', word_class: 'N' },
    { word: 'imperturbableness', word_class: 'N' }
  ],
  africanisation: [
    { word: 'african', word_class: 'N' },
    { word: 'african', word_class: 'AJ' },
    { word: 'africanise', word_class: 'V' },
    { word: 'africanize', word_class: 'V' },
    { word: 'africanisation', word_class: 'N' },
    { word: 'africanization', word_class: 'N' }
  ],
  deaf: [
    { word: 'deaf', word_class: 'N' },
    { word: 'deaf', word_class: 'V' },
    { word: 'deaf', word_class: 'AJ' },
    { word: 'deafness', word_class: 'N' }
  ],
  periodic: [
    { word: 'period', word_class: 'N' },
    { word: 'periodic', word_class: 'AJ' },
    { word: 'periodical', word_class: 'N' },
    { word: 'periodical', word_class: 'AJ' },
    { word: 'periodicity', word_class: 'N' },
    { word: 'periodically', word_class: 'AV' }
  ],
  personation: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  calculating: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  splitting: [
    { word: 'split', word_class: 'N' },
    { word: 'split', word_class: 'V' },
    { word: 'split', word_class: 'AJ' },
    { word: 'splitting', word_class: 'AJ' }
  ],
  extemporisation: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  tantalisation: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  enormousness: [
    { word: 'enormity', word_class: 'N' },
    { word: 'enormous', word_class: 'AJ' },
    { word: 'enormously', word_class: 'AV' },
    { word: 'enormousness', word_class: 'N' }
  ],
  cordial: [
    { word: 'cordial', word_class: 'N' },
    { word: 'cordial', word_class: 'AJ' },
    { word: 'cordiality', word_class: 'N' },
    { word: 'cordially', word_class: 'AV' }
  ],
  navigational: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  lot: [
    { word: 'lot', word_class: 'N' },
    { word: 'lot', word_class: 'V' },
    { word: 'lot', word_class: 'AJ' },
    { word: 'lots', word_class: 'N' },
    { word: 'lots', word_class: 'AJ' }
  ],
  compel: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  trafficking: [
    { word: 'traffic', word_class: 'N' },
    { word: 'traffic', word_class: 'V' },
    { word: 'trafficker', word_class: 'N' },
    { word: 'trafficator', word_class: 'N' },
    { word: 'trafficking', word_class: 'AJ' }
  ],
  tourist: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  tobogganist: [
    { word: 'toboggan', word_class: 'N' },
    { word: 'toboggan', word_class: 'V' },
    { word: 'tobogganing', word_class: 'N' },
    { word: 'tobogganist', word_class: 'N' }
  ],
  densely: [
    { word: 'dense', word_class: 'AJ' },
    { word: 'densely', word_class: 'AV' },
    { word: 'denseness', word_class: 'N' }
  ],
  barite: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  anchoritic: [
    { word: 'anchor', word_class: 'N' },
    { word: 'anchor', word_class: 'V' },
    { word: 'anchorage', word_class: 'N' },
    { word: 'anchorite', word_class: 'N' },
    { word: 'anchorman', word_class: 'N' },
    { word: 'anchoritic', word_class: 'AJ' }
  ],
  flimsy: [
    { word: 'flimsy', word_class: 'N' },
    { word: 'flimsy', word_class: 'AJ' },
    { word: 'flimsiness', word_class: 'N' }
  ],
  grab: [{ word: 'grab', word_class: 'N' }, { word: 'grab', word_class: 'V' }, { word: 'grab', word_class: 'AJ' }],
  disgracefulness: [
    { word: 'disgrace', word_class: 'N' },
    { word: 'disgrace', word_class: 'V' },
    { word: 'disgraced', word_class: 'AJ' },
    { word: 'disgraceful', word_class: 'AJ' },
    { word: 'disgracefulness', word_class: 'N' }
  ],
  patriotic: [
    { word: 'patriot', word_class: 'N' },
    { word: 'patriotic', word_class: 'AJ' },
    { word: 'patriotism', word_class: 'N' },
    { word: 'patriotically', word_class: 'AV' }
  ],
  recrimination: [
    { word: 'recriminate', word_class: 'V' },
    { word: 'recrimination', word_class: 'N' },
    { word: 'recriminative', word_class: 'AJ' }
  ],
  illogicality: [
    { word: 'illogic', word_class: 'N' },
    { word: 'illogical', word_class: 'AJ' },
    { word: 'illogicality', word_class: 'N' },
    { word: 'illogically', word_class: 'AV' }
  ],
  transplantable: [
    { word: 'transplant', word_class: 'N' },
    { word: 'transplant', word_class: 'V' },
    { word: 'transplanting', word_class: 'N' },
    { word: 'transplantable', word_class: 'AJ' },
    { word: 'transplantation', word_class: 'N' }
  ],
  disrupt: [
    { word: 'disrupt', word_class: 'V' },
    { word: 'disrupted', word_class: 'AJ' },
    { word: 'disruption', word_class: 'N' },
    { word: 'disruptive', word_class: 'AJ' },
    { word: 'disruptively', word_class: 'AV' }
  ],
  assiduous: [
    { word: 'assiduity', word_class: 'N' },
    { word: 'assiduous', word_class: 'AJ' },
    { word: 'assiduously', word_class: 'AV' },
    { word: 'assiduousness', word_class: 'N' }
  ],
  mayoral: [
    { word: 'may', word_class: 'N' },
    { word: 'may', word_class: 'V' },
    { word: 'mayor', word_class: 'N' },
    { word: 'mayoral', word_class: 'AJ' }
  ],
  cloven: [
    { word: 'cleave', word_class: 'V' },
    { word: 'cleaver', word_class: 'N' },
    { word: 'cloven', word_class: 'AJ' },
    { word: 'cleavers', word_class: 'N' }
  ],
  pharmacologically: [
    { word: 'pharmacology', word_class: 'N' },
    { word: 'pharmacologic', word_class: 'AJ' },
    { word: 'pharmacological', word_class: 'AJ' },
    { word: 'pharmacologically', word_class: 'AV' }
  ],
  abed: [{ word: 'ab', word_class: 'N' }, { word: 'abed', word_class: 'AJ' }, { word: 'abed', word_class: 'AV' }],
  socializing: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  dissolve: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  wonder: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  reminiscently: [
    { word: 'reminisce', word_class: 'V' },
    { word: 'reminiscence', word_class: 'N' },
    { word: 'reminiscent', word_class: 'AJ' },
    { word: 'reminiscently', word_class: 'AV' }
  ],
  lyophilization: [
    { word: 'lyophilize', word_class: 'V' },
    { word: 'lyophilized', word_class: 'AJ' },
    { word: 'lyophilization', word_class: 'N' }
  ],
  immortal: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  nutritive: [
    { word: 'nutrition', word_class: 'N' },
    { word: 'nutritive', word_class: 'AJ' },
    { word: 'nutritional', word_class: 'AJ' },
    { word: 'nutritiveness', word_class: 'N' }
  ],
  restrain: [
    { word: 'restrain', word_class: 'N' },
    { word: 'restrain', word_class: 'V' },
    { word: 'restraint', word_class: 'N' },
    { word: 'restrained', word_class: 'AJ' }
  ],
  bestridden: [
    { word: 'bestrid', word_class: 'AJ' },
    { word: 'bestride', word_class: 'V' },
    { word: 'bestridden', word_class: 'AJ' }
  ],
  advisee: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  infatuate: [
    { word: 'infatuate', word_class: 'V' },
    { word: 'infatuated', word_class: 'AJ' },
    { word: 'infatuation', word_class: 'N' }
  ],
  synthesizer: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  oncological: [
    { word: 'oncology', word_class: 'N' },
    { word: 'oncologic', word_class: 'AJ' },
    { word: 'oncological', word_class: 'AJ' }
  ],
  privateer: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  deviation: [
    { word: 'deviant', word_class: 'N' },
    { word: 'deviate', word_class: 'N' },
    { word: 'deviate', word_class: 'V' },
    { word: 'deviance', word_class: 'N' },
    { word: 'deviant', word_class: 'AJ' },
    { word: 'deviancy', word_class: 'N' },
    { word: 'deviation', word_class: 'N' }
  ],
  conflicting: [
    { word: 'conflict', word_class: 'N' },
    { word: 'conflict', word_class: 'V' },
    { word: 'confliction', word_class: 'N' },
    { word: 'conflicting', word_class: 'AJ' }
  ],
  redundance: [
    { word: 'redundance', word_class: 'N' },
    { word: 'redundancy', word_class: 'N' },
    { word: 'redundant', word_class: 'AJ' }
  ],
  sextuplication: [
    { word: 'sextuple', word_class: 'N' },
    { word: 'sextuple', word_class: 'AJ' },
    { word: 'sextuplicate', word_class: 'V' },
    { word: 'sextuplication', word_class: 'N' }
  ],
  sociolinguistic: [
    { word: 'sociolinguist', word_class: 'N' },
    { word: 'sociolinguistic', word_class: 'AJ' },
    { word: 'sociolinguistics', word_class: 'N' },
    { word: 'sociolinguistically', word_class: 'AV' }
  ],
  perfectibility: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  egalitarianism: [
    { word: 'egalitarian', word_class: 'N' },
    { word: 'egalitarian', word_class: 'AJ' },
    { word: 'egalitarianism', word_class: 'N' }
  ],
  fateful: [
    { word: 'fate', word_class: 'N' },
    { word: 'fate', word_class: 'V' },
    { word: 'fates', word_class: 'N' },
    { word: 'fated', word_class: 'AJ' },
    { word: 'fateful', word_class: 'AJ' }
  ],
  dehumanize: [
    { word: 'dehumanise', word_class: 'V' },
    { word: 'dehumanize', word_class: 'V' },
    { word: 'dehumanized', word_class: 'AJ' },
    { word: 'dehumanisation', word_class: 'N' },
    { word: 'dehumanization', word_class: 'N' }
  ],
  fleecy: [
    { word: 'fleece', word_class: 'N' },
    { word: 'fleece', word_class: 'V' },
    { word: 'fleecy', word_class: 'AJ' }
  ],
  whimsically: [
    { word: 'whimsy', word_class: 'N' },
    { word: 'whimsical', word_class: 'AJ' },
    { word: 'whimsicality', word_class: 'N' },
    { word: 'whimsically', word_class: 'AV' }
  ],
  precedented: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  clanger: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  acquisitiveness: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  analogist: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  contractor: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  dislocated: [
    { word: 'dislocate', word_class: 'V' },
    { word: 'dislocated', word_class: 'AJ' },
    { word: 'dislocation', word_class: 'N' }
  ],
  cataloguer: [
    { word: 'catalogue', word_class: 'N' },
    { word: 'catalogue', word_class: 'V' },
    { word: 'cataloguer', word_class: 'N' },
    { word: 'catalogued', word_class: 'AJ' }
  ],
  raining: [
    { word: 'rain', word_class: 'N' },
    { word: 'rain', word_class: 'V' },
    { word: 'raining', word_class: 'AJ' }
  ],
  inappropriateness: [
    { word: 'inappropriate', word_class: 'AJ' },
    { word: 'inappropriately', word_class: 'AV' },
    { word: 'inappropriateness', word_class: 'N' }
  ],
  debased: [
    { word: 'debase', word_class: 'V' },
    { word: 'debased', word_class: 'AJ' },
    { word: 'debasing', word_class: 'AJ' },
    { word: 'debasement', word_class: 'N' }
  ],
  shrilling: [
    { word: 'shrill', word_class: 'V' },
    { word: 'shrill', word_class: 'AJ' },
    { word: 'shrilly', word_class: 'AV' },
    { word: 'shrilling', word_class: 'N' },
    { word: 'shrilling', word_class: 'AJ' },
    { word: 'shrillness', word_class: 'N' }
  ],
  spouse: [
    { word: 'spouse', word_class: 'N' },
    { word: 'spouse', word_class: 'V' },
    { word: 'spousal', word_class: 'N' },
    { word: 'spousal', word_class: 'AJ' }
  ],
  handwritten: [
    { word: 'handwrite', word_class: 'V' },
    { word: 'handwriting', word_class: 'N' },
    { word: 'handwritten', word_class: 'AJ' }
  ],
  inefficient: [
    { word: 'inefficiency', word_class: 'N' },
    { word: 'inefficient', word_class: 'AJ' },
    { word: 'inefficiently', word_class: 'AV' }
  ],
  soul: [
    { word: 'soul', word_class: 'N' },
    { word: 'soulful', word_class: 'AJ' },
    { word: 'soulfulness', word_class: 'N' }
  ],
  voluted: [
    { word: 'volute', word_class: 'N' },
    { word: 'volute', word_class: 'AJ' },
    { word: 'voluted', word_class: 'AJ' },
    { word: 'volution', word_class: 'N' }
  ],
  awe: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  upliftment: [
    { word: 'uplift', word_class: 'N' },
    { word: 'uplift', word_class: 'V' },
    { word: 'uplifted', word_class: 'AJ' },
    { word: 'uplifting', word_class: 'N' },
    { word: 'upliftment', word_class: 'N' }
  ],
  attribute: [
    { word: 'attribute', word_class: 'N' },
    { word: 'attribute', word_class: 'V' },
    { word: 'attribution', word_class: 'N' },
    { word: 'attributive', word_class: 'AJ' },
    { word: 'attributable', word_class: 'AJ' },
    { word: 'attributively', word_class: 'AV' }
  ],
  unbeliever: [
    { word: 'unbeliever', word_class: 'N' },
    { word: 'unbelieving', word_class: 'AJ' },
    { word: 'unbelievable', word_class: 'AJ' },
    { word: 'unbelievably', word_class: 'AV' }
  ],
  racialism: [
    { word: 'racial', word_class: 'AJ' },
    { word: 'racialism', word_class: 'N' },
    { word: 'racially', word_class: 'AV' }
  ],
  doubled: [
    { word: 'double', word_class: 'N' },
    { word: 'double', word_class: 'V' },
    { word: 'double', word_class: 'AJ' },
    { word: 'double', word_class: 'AV' },
    { word: 'doubles', word_class: 'N' },
    { word: 'doubly', word_class: 'AV' },
    { word: 'doubled', word_class: 'AJ' },
    { word: 'doubling', word_class: 'N' }
  ],
  isometrical: [
    { word: 'isometric', word_class: 'AJ' },
    { word: 'isometrics', word_class: 'N' },
    { word: 'isometrical', word_class: 'AJ' }
  ],
  lyricism: [
    { word: 'lyric', word_class: 'N' },
    { word: 'lyric', word_class: 'V' },
    { word: 'lyric', word_class: 'AJ' },
    { word: 'lyrical', word_class: 'AJ' },
    { word: 'lyricism', word_class: 'N' },
    { word: 'lyricality', word_class: 'N' },
    { word: 'lyrically', word_class: 'AV' }
  ],
  scurrilously: [
    { word: 'scurrility', word_class: 'N' },
    { word: 'scurrilous', word_class: 'AJ' },
    { word: 'scurrilously', word_class: 'AV' }
  ],
  lexicographer: [
    { word: 'lexicographer', word_class: 'N' },
    { word: 'lexicographic', word_class: 'AJ' },
    { word: 'lexicographical', word_class: 'AJ' }
  ],
  geometrically: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  crescendo: [
    { word: 'crescendo', word_class: 'N' },
    { word: 'crescendo', word_class: 'V' },
    { word: 'crescendo', word_class: 'AJ' }
  ],
  popularisation: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  snarled: [
    { word: 'snarl', word_class: 'N' },
    { word: 'snarl', word_class: 'V' },
    { word: 'snarled', word_class: 'AJ' }
  ],
  relays: [{ word: 'relay', word_class: 'N' }, { word: 'relay', word_class: 'V' }, { word: 'relays', word_class: 'N' }],
  ruggedize: [
    { word: 'ruggedize', word_class: 'V' },
    { word: 'ruggedness', word_class: 'N' },
    { word: 'ruggedization', word_class: 'N' }
  ],
  astronautic: [
    { word: 'astronaut', word_class: 'N' },
    { word: 'astronautic', word_class: 'AJ' },
    { word: 'astronautics', word_class: 'N' },
    { word: 'astronautical', word_class: 'AJ' }
  ],
  wobble: [
    { word: 'wobble', word_class: 'N' },
    { word: 'wobble', word_class: 'V' },
    { word: 'wobbler', word_class: 'N' },
    { word: 'wobbly', word_class: 'AJ' },
    { word: 'wobbling', word_class: 'AJ' }
  ],
  carbonation: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  tolerance: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  gimbal: [
    { word: 'gimbal', word_class: 'N' },
    { word: 'gimbal', word_class: 'V' },
    { word: 'gimbals', word_class: 'N' },
    { word: 'gimbaled', word_class: 'AJ' }
  ],
  telegraphic: [
    { word: 'telegraph', word_class: 'N' },
    { word: 'telegraph', word_class: 'V' },
    { word: 'telegrapher', word_class: 'N' },
    { word: 'telegraphic', word_class: 'AJ' },
    { word: 'telegraphist', word_class: 'N' },
    { word: 'telegraphically', word_class: 'AV' }
  ],
  indignity: [
    { word: 'indignity', word_class: 'N' },
    { word: 'indignant', word_class: 'AJ' },
    { word: 'indignation', word_class: 'N' }
  ],
  admittance: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  containerise: [
    { word: 'containerise', word_class: 'V' },
    { word: 'containerize', word_class: 'V' },
    { word: 'containerized', word_class: 'AJ' },
    { word: 'containerisation', word_class: 'N' },
    { word: 'containerization', word_class: 'N' }
  ],
  interviewee: [
    { word: 'interview', word_class: 'N' },
    { word: 'interview', word_class: 'V' },
    { word: 'interviewee', word_class: 'N' },
    { word: 'interviewer', word_class: 'N' }
  ],
  chaperonage: [
    { word: 'chaperon', word_class: 'N' },
    { word: 'chaperon', word_class: 'V' },
    { word: 'chaperone', word_class: 'N' },
    { word: 'chaperone', word_class: 'V' },
    { word: 'chaperonage', word_class: 'N' }
  ],
  lonely: [
    { word: 'lone', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AV' },
    { word: 'loneliness', word_class: 'N' }
  ],
  sleepwalker: [
    { word: 'sleepwalk', word_class: 'V' },
    { word: 'sleepwalker', word_class: 'N' },
    { word: 'sleepwalking', word_class: 'N' }
  ],
  harrowment: [
    { word: 'harrow', word_class: 'N' },
    { word: 'harrow', word_class: 'V' },
    { word: 'harrowing', word_class: 'AJ' },
    { word: 'harrowment', word_class: 'N' }
  ],
  ascendance: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  shipwreck: [
    { word: 'shipwreck', word_class: 'N' },
    { word: 'shipwreck', word_class: 'V' },
    { word: 'shipwrecked', word_class: 'AJ' }
  ],
  spending: [
    { word: 'spend', word_class: 'V' },
    { word: 'spender', word_class: 'N' },
    { word: 'spending', word_class: 'N' }
  ],
  pathologic: [
    { word: 'pathology', word_class: 'N' },
    { word: 'pathologic', word_class: 'AJ' },
    { word: 'pathological', word_class: 'AJ' },
    { word: 'pathologically', word_class: 'AV' }
  ],
  concentric: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  oligarchic: [
    { word: 'oligarch', word_class: 'N' },
    { word: 'oligarchic', word_class: 'AJ' },
    { word: 'oligarchical', word_class: 'AJ' }
  ],
  confute: [
    { word: 'confute', word_class: 'V' },
    { word: 'confuting', word_class: 'N' },
    { word: 'confutation', word_class: 'N' }
  ],
  flatulence: [
    { word: 'flatulence', word_class: 'N' },
    { word: 'flatulency', word_class: 'N' },
    { word: 'flatulent', word_class: 'AJ' }
  ],
  impersonalisation: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  ritualism: [
    { word: 'ritual', word_class: 'N' },
    { word: 'ritual', word_class: 'AJ' },
    { word: 'ritualism', word_class: 'N' },
    { word: 'ritualize', word_class: 'V' },
    { word: 'ritually', word_class: 'AV' },
    { word: 'ritualization', word_class: 'N' }
  ],
  apprize: [
    { word: 'apprise', word_class: 'V' },
    { word: 'apprize', word_class: 'V' },
    { word: 'apprisal', word_class: 'N' }
  ],
  retroact: [
    { word: 'retroact', word_class: 'V' },
    { word: 'retroaction', word_class: 'N' },
    { word: 'retroactive', word_class: 'AJ' },
    { word: 'retroactively', word_class: 'AV' }
  ],
  punctually: [
    { word: 'punctual', word_class: 'AJ' },
    { word: 'punctuality', word_class: 'N' },
    { word: 'punctually', word_class: 'AV' }
  ],
  feminism: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  evacuant: [
    { word: 'evacuee', word_class: 'N' },
    { word: 'evacuate', word_class: 'V' },
    { word: 'evacuant', word_class: 'AJ' },
    { word: 'evacuated', word_class: 'AJ' },
    { word: 'evacuation', word_class: 'N' }
  ],
  barbarization: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  snuffling: [
    { word: 'snuffle', word_class: 'N' },
    { word: 'snuffle', word_class: 'V' },
    { word: 'snuffly', word_class: 'AJ' },
    { word: 'snuffling', word_class: 'AJ' }
  ],
  anglicization: [
    { word: 'anglicise', word_class: 'V' },
    { word: 'anglicism', word_class: 'N' },
    { word: 'anglicize', word_class: 'V' },
    { word: 'anglicisation', word_class: 'N' },
    { word: 'anglicization', word_class: 'N' }
  ],
  worshiping: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  bismuthal: [
    { word: 'bismuth', word_class: 'N' },
    { word: 'bismuthal', word_class: 'AJ' },
    { word: 'bismuthic', word_class: 'AJ' }
  ],
  ugli: [
    { word: 'ugli', word_class: 'N' },
    { word: 'ugly', word_class: 'AJ' },
    { word: 'uglify', word_class: 'V' },
    { word: 'ugliness', word_class: 'N' },
    { word: 'uglification', word_class: 'N' }
  ],
  shriveled: [
    { word: 'shrivel', word_class: 'V' },
    { word: 'shriveled', word_class: 'AJ' },
    { word: 'shrivelled', word_class: 'AJ' }
  ],
  sunni: [
    { word: 'sunni', word_class: 'N' },
    { word: 'sunny', word_class: 'AJ' },
    { word: 'sunniness', word_class: 'N' }
  ],
  miniaturization: [
    { word: 'miniature', word_class: 'N' },
    { word: 'miniature', word_class: 'AJ' },
    { word: 'miniaturise', word_class: 'V' },
    { word: 'miniaturize', word_class: 'V' },
    { word: 'miniaturisation', word_class: 'N' },
    { word: 'miniaturization', word_class: 'N' }
  ],
  striate: [
    { word: 'striate', word_class: 'V' },
    { word: 'striate', word_class: 'AJ' },
    { word: 'striated', word_class: 'AJ' },
    { word: 'striation', word_class: 'N' }
  ],
  enlisted: [
    { word: 'enlist', word_class: 'V' },
    { word: 'enlistee', word_class: 'N' },
    { word: 'enlisted', word_class: 'AJ' },
    { word: 'enlisting', word_class: 'N' },
    { word: 'enlistment', word_class: 'N' }
  ],
  length: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  deliciously: [
    { word: 'delicious', word_class: 'N' },
    { word: 'delicious', word_class: 'AJ' },
    { word: 'deliciously', word_class: 'AV' },
    { word: 'deliciousness', word_class: 'N' }
  ],
  offense: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  curette: [
    { word: 'curet', word_class: 'N' },
    { word: 'curet', word_class: 'V' },
    { word: 'curette', word_class: 'N' },
    { word: 'curettement', word_class: 'N' }
  ],
  inappropriately: [
    { word: 'inappropriate', word_class: 'AJ' },
    { word: 'inappropriately', word_class: 'AV' },
    { word: 'inappropriateness', word_class: 'N' }
  ],
  thickening: [
    { word: 'thicken', word_class: 'V' },
    { word: 'thickener', word_class: 'N' },
    { word: 'thickened', word_class: 'AJ' },
    { word: 'thickening', word_class: 'N' },
    { word: 'thickening', word_class: 'AJ' }
  ],
  nitrogen: [
    { word: 'nitrogen', word_class: 'N' },
    { word: 'nitrogenize', word_class: 'V' },
    { word: 'nitrogenous', word_class: 'AJ' },
    { word: 'nitrogenization', word_class: 'N' }
  ],
  lightness: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  retarded: [
    { word: 'retard', word_class: 'N' },
    { word: 'retard', word_class: 'V' },
    { word: 'retarded', word_class: 'AJ' },
    { word: 'retardment', word_class: 'N' },
    { word: 'retardation', word_class: 'N' }
  ],
  apathy: [
    { word: 'apathy', word_class: 'N' },
    { word: 'apathetic', word_class: 'AJ' },
    { word: 'apathetically', word_class: 'AV' }
  ],
  enlightenment: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  russianize: [
    { word: 'russian', word_class: 'N' },
    { word: 'russian', word_class: 'AJ' },
    { word: 'russianise', word_class: 'V' },
    { word: 'russianize', word_class: 'V' },
    { word: 'russianisation', word_class: 'N' },
    { word: 'russianization', word_class: 'N' }
  ],
  buddhistic: [
    { word: 'buddhist', word_class: 'N' },
    { word: 'buddhist', word_class: 'AJ' },
    { word: 'buddhistic', word_class: 'AJ' }
  ],
  personage: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  retain: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  marooned: [
    { word: 'maroon', word_class: 'N' },
    { word: 'maroon', word_class: 'V' },
    { word: 'maroon', word_class: 'AJ' },
    { word: 'marooned', word_class: 'AJ' }
  ],
  commensurate: [
    { word: 'commensurate', word_class: 'AJ' },
    { word: 'commensurable', word_class: 'AJ' },
    { word: 'commensurateness', word_class: 'N' }
  ],
  escheat: [
    { word: 'escheat', word_class: 'N' },
    { word: 'escheat', word_class: 'V' },
    { word: 'escheatage', word_class: 'N' }
  ],
  pragmatics: [
    { word: 'pragmatic', word_class: 'AJ' },
    { word: 'pragmatics', word_class: 'N' },
    { word: 'pragmatism', word_class: 'N' },
    { word: 'pragmatical', word_class: 'AJ' },
    { word: 'pragmatically', word_class: 'AV' }
  ],
  conquer: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  persuadable: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  copyright: [
    { word: 'copyright', word_class: 'N' },
    { word: 'copyright', word_class: 'V' },
    { word: 'copyright', word_class: 'AJ' },
    { word: 'copyrighted', word_class: 'AJ' }
  ],
  ceramic: [
    { word: 'ceramic', word_class: 'N' },
    { word: 'ceramic', word_class: 'AJ' },
    { word: 'ceramics', word_class: 'N' }
  ],
  acknowldgement: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  sawyer: [
    { word: 'saw', word_class: 'N' },
    { word: 'saw', word_class: 'V' },
    { word: 'sawn', word_class: 'AJ' },
    { word: 'sawyer', word_class: 'N' }
  ],
  hydrogenate: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  maximization: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  acceptably: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  equalization: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  cap: [
    { word: 'cap', word_class: 'N' },
    { word: 'cap', word_class: 'V' },
    { word: 'capful', word_class: 'N' },
    { word: 'caption', word_class: 'N' },
    { word: 'capful', word_class: 'AJ' },
    { word: 'capped', word_class: 'AJ' },
    { word: 'caption', word_class: 'V' }
  ],
  topped: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  button: [
    { word: 'button', word_class: 'N' },
    { word: 'button', word_class: 'V' },
    { word: 'buttoned', word_class: 'AJ' }
  ],
  yelping: [{ word: 'yelp', word_class: 'N' }, { word: 'yelp', word_class: 'V' }, { word: 'yelping', word_class: 'N' }],
  equilibrate: [
    { word: 'equilibrate', word_class: 'V' },
    { word: 'equilibrize', word_class: 'V' },
    { word: 'equilibration', word_class: 'N' }
  ],
  somnambulance: [
    { word: 'somnambulate', word_class: 'V' },
    { word: 'somnambulism', word_class: 'N' },
    { word: 'somnambulance', word_class: 'N' },
    { word: 'somnambulation', word_class: 'N' }
  ],
  pollution: [
    { word: 'pollute', word_class: 'V' },
    { word: 'pollutant', word_class: 'N' },
    { word: 'polluted', word_class: 'AJ' },
    { word: 'pollution', word_class: 'N' }
  ],
  aromatisation: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  procrastination: [
    { word: 'procrastinate', word_class: 'V' },
    { word: 'procrastinator', word_class: 'N' },
    { word: 'procrastination', word_class: 'N' }
  ],
  localized: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  straightness: [
    { word: 'straight', word_class: 'N' },
    { word: 'straight', word_class: 'AJ' },
    { word: 'straight', word_class: 'AV' },
    { word: 'straightness', word_class: 'N' }
  ],
  laud: [
    { word: 'laud', word_class: 'V' },
    { word: 'lauder', word_class: 'N' },
    { word: 'laudator', word_class: 'N' },
    { word: 'laudation', word_class: 'N' },
    { word: 'laudatory', word_class: 'AJ' }
  ],
  domestically: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  bilateral: [
    { word: 'bilateral', word_class: 'AJ' },
    { word: 'bilateralism', word_class: 'N' },
    { word: 'bilaterality', word_class: 'N' },
    { word: 'bilaterally', word_class: 'AV' }
  ],
  invitation: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  homophone: [
    { word: 'homophone', word_class: 'N' },
    { word: 'homophonic', word_class: 'AJ' },
    { word: 'homophonous', word_class: 'AJ' }
  ],
  categorize: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  constructor: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  slope: [
    { word: 'slope', word_class: 'N' },
    { word: 'slope', word_class: 'V' },
    { word: 'sloped', word_class: 'AJ' },
    { word: 'sloping', word_class: 'AJ' }
  ],
  transgression: [
    { word: 'transgress', word_class: 'V' },
    { word: 'transgressor', word_class: 'N' },
    { word: 'transgression', word_class: 'N' }
  ],
  diametral: [
    { word: 'diameter', word_class: 'N' },
    { word: 'diametral', word_class: 'AJ' },
    { word: 'diametric', word_class: 'AJ' },
    { word: 'diametrical', word_class: 'AJ' },
    { word: 'diametrically', word_class: 'AV' }
  ],
  flagellation: [
    { word: 'flagellant', word_class: 'N' },
    { word: 'flagellate', word_class: 'N' },
    { word: 'flagellate', word_class: 'V' },
    { word: 'flagellate', word_class: 'AJ' },
    { word: 'flagellated', word_class: 'AJ' },
    { word: 'flagellation', word_class: 'N' }
  ],
  graft: [
    { word: 'graft', word_class: 'N' },
    { word: 'graft', word_class: 'V' },
    { word: 'grafting', word_class: 'N' }
  ],
  unpretentiousness: [
    { word: 'unpretentious', word_class: 'AJ' },
    { word: 'unpretentiously', word_class: 'AV' },
    { word: 'unpretentiousness', word_class: 'N' }
  ],
  recollection: [
    { word: 'recollect', word_class: 'V' },
    { word: 'recollection', word_class: 'N' },
    { word: 'recollective', word_class: 'AJ' }
  ],
  plumping: [
    { word: 'plump', word_class: 'N' },
    { word: 'plump', word_class: 'V' },
    { word: 'plump', word_class: 'AJ' },
    { word: 'plump', word_class: 'AV' },
    { word: 'plumping', word_class: 'AJ' },
    { word: 'plumpness', word_class: 'N' }
  ],
  luxuriousness: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  cheerleader: [
    { word: 'cheerlead', word_class: 'V' },
    { word: 'cheerleader', word_class: 'N' },
    { word: 'cheerleading', word_class: 'N' }
  ],
  infectiously: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  dividers: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  tenableness: [
    { word: 'tenable', word_class: 'AJ' },
    { word: 'tenably', word_class: 'AV' },
    { word: 'tenability', word_class: 'N' },
    { word: 'tenableness', word_class: 'N' }
  ],
  cooled: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  hauling: [
    { word: 'haul', word_class: 'N' },
    { word: 'haul', word_class: 'V' },
    { word: 'hauler', word_class: 'N' },
    { word: 'haulage', word_class: 'N' },
    { word: 'hauling', word_class: 'N' }
  ],
  subcription: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  emancipative: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  soliloquization: [
    { word: 'soliloquy', word_class: 'N' },
    { word: 'soliloquize', word_class: 'V' },
    { word: 'soliloquization', word_class: 'N' }
  ],
  crystallized: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  presentation: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  disturbing: [
    { word: 'disturb', word_class: 'V' },
    { word: 'disturbed', word_class: 'AJ' },
    { word: 'disturbance', word_class: 'N' },
    { word: 'disturbing', word_class: 'AJ' }
  ],
  engulf: [
    { word: 'engulf', word_class: 'V' },
    { word: 'engulfed', word_class: 'AJ' },
    { word: 'engulfment', word_class: 'N' }
  ],
  jostle: [
    { word: 'jostle', word_class: 'N' },
    { word: 'jostle', word_class: 'V' },
    { word: 'justle', word_class: 'V' },
    { word: 'jostling', word_class: 'N' },
    { word: 'jostlement', word_class: 'N' },
    { word: 'justlement', word_class: 'N' }
  ],
  shortness: [
    { word: 'short', word_class: 'N' },
    { word: 'short', word_class: 'V' },
    { word: 'short', word_class: 'AJ' },
    { word: 'short', word_class: 'AV' },
    { word: 'shorts', word_class: 'N' },
    { word: 'shortage', word_class: 'N' },
    { word: 'shortness', word_class: 'N' }
  ],
  hunt: [
    { word: 'hunt', word_class: 'N' },
    { word: 'hunt', word_class: 'V' },
    { word: 'hunter', word_class: 'N' },
    { word: 'hunted', word_class: 'AJ' },
    { word: 'hunting', word_class: 'N' }
  ],
  tenably: [
    { word: 'tenable', word_class: 'AJ' },
    { word: 'tenably', word_class: 'AV' },
    { word: 'tenability', word_class: 'N' },
    { word: 'tenableness', word_class: 'N' }
  ],
  predomination: [
    { word: 'predominate', word_class: 'V' },
    { word: 'predominance', word_class: 'N' },
    { word: 'predominant', word_class: 'AJ' },
    { word: 'predominate', word_class: 'AJ' },
    { word: 'predomination', word_class: 'N' }
  ],
  rioter: [
    { word: 'riot', word_class: 'N' },
    { word: 'riot', word_class: 'V' },
    { word: 'rioter', word_class: 'N' },
    { word: 'rioting', word_class: 'N' }
  ],
  generate: [
    { word: 'generate', word_class: 'V' },
    { word: 'generator', word_class: 'N' },
    { word: 'generation', word_class: 'N' },
    { word: 'generative', word_class: 'AJ' }
  ],
  inspectorate: [
    { word: 'inspect', word_class: 'V' },
    { word: 'inspector', word_class: 'N' },
    { word: 'inspection', word_class: 'N' },
    { word: 'inspectorate', word_class: 'N' }
  ],
  incurvature: [
    { word: 'incurvate', word_class: 'V' },
    { word: 'incurved', word_class: 'AJ' },
    { word: 'incurvate', word_class: 'AJ' },
    { word: 'incurvation', word_class: 'N' },
    { word: 'incurvature', word_class: 'N' }
  ],
  pose: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  marriageability: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  severe: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  unsymmetrical: [
    { word: 'unsymmetric', word_class: 'AJ' },
    { word: 'unsymmetrical', word_class: 'AJ' },
    { word: 'unsymmetrically', word_class: 'AV' }
  ],
  murder: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  bestially: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  blacktopped: [
    { word: 'blacktop', word_class: 'N' },
    { word: 'blacktop', word_class: 'V' },
    { word: 'blacktopped', word_class: 'AJ' },
    { word: 'blacktopping', word_class: 'N' }
  ],
  treacherously: [
    { word: 'treachery', word_class: 'N' },
    { word: 'treacherous', word_class: 'AJ' },
    { word: 'treacherously', word_class: 'AV' }
  ],
  vesicant: [
    { word: 'vesical', word_class: 'AJ' },
    { word: 'vesicant', word_class: 'N' },
    { word: 'vesicate', word_class: 'V' },
    { word: 'vesicant', word_class: 'AJ' },
    { word: 'vesication', word_class: 'N' }
  ],
  jabbing: [{ word: 'jab', word_class: 'N' }, { word: 'jab', word_class: 'V' }, { word: 'jabbing', word_class: 'N' }],
  islamic: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  territorial: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  archetypical: [
    { word: 'archetype', word_class: 'N' },
    { word: 'archetypal', word_class: 'AJ' },
    { word: 'archetypic', word_class: 'AJ' },
    { word: 'archetypical', word_class: 'AJ' }
  ],
  automate: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  safe: [
    { word: 'safe', word_class: 'N' },
    { word: 'safe', word_class: 'AJ' },
    { word: 'safety', word_class: 'N' },
    { word: 'safely', word_class: 'AV' },
    { word: 'safeness', word_class: 'N' }
  ],
  disgruntled: [
    { word: 'disgruntle', word_class: 'V' },
    { word: 'disgruntled', word_class: 'AJ' },
    { word: 'disgruntlement', word_class: 'N' }
  ],
  soften: [
    { word: 'soften', word_class: 'V' },
    { word: 'softener', word_class: 'N' },
    { word: 'softened', word_class: 'AJ' },
    { word: 'softening', word_class: 'N' },
    { word: 'softening', word_class: 'AJ' }
  ],
  barricaded: [
    { word: 'barricade', word_class: 'N' },
    { word: 'barricade', word_class: 'V' },
    { word: 'barricaded', word_class: 'AJ' }
  ],
  debuting: [
    { word: 'debut', word_class: 'N' },
    { word: 'debut', word_class: 'V' },
    { word: 'debuted', word_class: 'AJ' },
    { word: 'debuting', word_class: 'AJ' }
  ],
  sulky: [
    { word: 'sulky', word_class: 'N' },
    { word: 'sulky', word_class: 'AJ' },
    { word: 'sulkiness', word_class: 'N' }
  ],
  condemnable: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  morale: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  wedged: [
    { word: 'wedge', word_class: 'N' },
    { word: 'wedge', word_class: 'V' },
    { word: 'wedged', word_class: 'AJ' }
  ],
  clamorous: [
    { word: 'clam', word_class: 'N' },
    { word: 'clam', word_class: 'V' },
    { word: 'clamor', word_class: 'N' },
    { word: 'clamor', word_class: 'V' },
    { word: 'clamoring', word_class: 'N' },
    { word: 'clamorous', word_class: 'AJ' },
    { word: 'clamorously', word_class: 'AV' }
  ],
  embolden: [
    { word: 'embold', word_class: 'V' },
    { word: 'embolden', word_class: 'V' },
    { word: 'embolden', word_class: 'AJ' },
    { word: 'emboldened', word_class: 'AJ' },
    { word: 'emboldenment', word_class: 'N' }
  ],
  multitudinousness: [
    { word: 'multitude', word_class: 'N' },
    { word: 'multitudinous', word_class: 'AJ' },
    { word: 'multitudinousness', word_class: 'N' }
  ],
  lobated: [
    { word: 'lobate', word_class: 'V' },
    { word: 'lobate', word_class: 'AJ' },
    { word: 'lobated', word_class: 'AJ' },
    { word: 'lobation', word_class: 'N' }
  ],
  maledict: [
    { word: 'maledict', word_class: 'N' },
    { word: 'maledict', word_class: 'V' },
    { word: 'maledict', word_class: 'AJ' },
    { word: 'malediction', word_class: 'N' }
  ],
  skates: [
    { word: 'skate', word_class: 'N' },
    { word: 'skate', word_class: 'V' },
    { word: 'skater', word_class: 'N' },
    { word: 'skates', word_class: 'N' },
    { word: 'skating', word_class: 'N' }
  ],
  pillager: [
    { word: 'pillage', word_class: 'N' },
    { word: 'pillage', word_class: 'V' },
    { word: 'pillager', word_class: 'N' },
    { word: 'pillaged', word_class: 'AJ' },
    { word: 'pillaging', word_class: 'N' }
  ],
  wrongness: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  acutely: [
    { word: 'acute', word_class: 'N' },
    { word: 'acute', word_class: 'AJ' },
    { word: 'acutely', word_class: 'AV' },
    { word: 'acuteness', word_class: 'N' }
  ],
  immoderateness: [
    { word: 'immoderate', word_class: 'AJ' },
    { word: 'immoderation', word_class: 'N' },
    { word: 'immoderately', word_class: 'AV' },
    { word: 'immoderateness', word_class: 'N' }
  ],
  enjoyment: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  sore: [
    { word: 'sore', word_class: 'N' },
    { word: 'sore', word_class: 'AJ' },
    { word: 'sorely', word_class: 'AV' },
    { word: 'soreness', word_class: 'N' }
  ],
  instantiation: [
    { word: 'instance', word_class: 'N' },
    { word: 'instance', word_class: 'V' },
    { word: 'instancy', word_class: 'N' },
    { word: 'instantiate', word_class: 'V' },
    { word: 'instantiation', word_class: 'N' }
  ],
  misrepresented: [
    { word: 'misrepresent', word_class: 'V' },
    { word: 'misrepresented', word_class: 'AJ' },
    { word: 'misrepresentation', word_class: 'N' }
  ],
  chide: [
    { word: 'chide', word_class: 'V' },
    { word: 'chiding', word_class: 'N' },
    { word: 'chidden', word_class: 'AJ' }
  ],
  earthen: [
    { word: 'earth', word_class: 'N' },
    { word: 'earth', word_class: 'V' },
    { word: 'earthen', word_class: 'AJ' },
    { word: 'earthing', word_class: 'N' },
    { word: 'earthman', word_class: 'N' }
  ],
  enrollment: [
    { word: 'enrol', word_class: 'V' },
    { word: 'enroll', word_class: 'V' },
    { word: 'enrolled', word_class: 'AJ' },
    { word: 'enrolment', word_class: 'N' },
    { word: 'enrollment', word_class: 'N' }
  ],
  storing: [
    { word: 'store', word_class: 'N' },
    { word: 'store', word_class: 'V' },
    { word: 'store', word_class: 'AJ' },
    { word: 'stores', word_class: 'N' },
    { word: 'storage', word_class: 'N' },
    { word: 'storing', word_class: 'N' }
  ],
  misprize: [
    { word: 'misprise', word_class: 'V' },
    { word: 'misprize', word_class: 'V' },
    { word: 'misprision', word_class: 'N' }
  ],
  disarranged: [
    { word: 'disarrange', word_class: 'V' },
    { word: 'disarranged', word_class: 'AJ' },
    { word: 'disarrangement', word_class: 'N' }
  ],
  majesty: [
    { word: 'majesty', word_class: 'N' },
    { word: 'majestic', word_class: 'AJ' },
    { word: 'majestically', word_class: 'AV' }
  ],
  synopsize: [
    { word: 'synopsis', word_class: 'N' },
    { word: 'synopsise', word_class: 'V' },
    { word: 'synopsize', word_class: 'V' },
    { word: 'synopsization', word_class: 'N' }
  ],
  meningeal: [
    { word: 'meninges', word_class: 'N' },
    { word: 'meningism', word_class: 'N' },
    { word: 'meningeal', word_class: 'AJ' },
    { word: 'meningitis', word_class: 'N' }
  ],
  militancy: [
    { word: 'militant', word_class: 'N' },
    { word: 'militate', word_class: 'V' },
    { word: 'militance', word_class: 'N' },
    { word: 'militancy', word_class: 'N' },
    { word: 'militant', word_class: 'AJ' },
    { word: 'militation', word_class: 'N' }
  ],
  damaging: [
    { word: 'damage', word_class: 'N' },
    { word: 'damage', word_class: 'V' },
    { word: 'damages', word_class: 'N' },
    { word: 'damaged', word_class: 'AJ' },
    { word: 'damaging', word_class: 'AJ' }
  ],
  categorically: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  soldiery: [
    { word: 'soldier', word_class: 'N' },
    { word: 'soldier', word_class: 'V' },
    { word: 'soldiery', word_class: 'N' },
    { word: 'soldiery', word_class: 'AJ' },
    { word: 'soldiering', word_class: 'N' },
    { word: 'soldiership', word_class: 'N' }
  ],
  efficacious: [
    { word: 'efficacy', word_class: 'N' },
    { word: 'efficacious', word_class: 'AJ' },
    { word: 'efficaciously', word_class: 'AV' },
    { word: 'efficaciousness', word_class: 'N' }
  ],
  post: [
    { word: 'post', word_class: 'N' },
    { word: 'post', word_class: 'V' },
    { word: 'post', word_class: 'AJ' },
    { word: 'poster', word_class: 'N' },
    { word: 'poster', word_class: 'V' },
    { word: 'posting', word_class: 'N' },
    { word: 'postman', word_class: 'N' },
    { word: 'posterity', word_class: 'N' }
  ],
  scarcely: [
    { word: 'scarce', word_class: 'AJ' },
    { word: 'scarce', word_class: 'AV' },
    { word: 'scarcely', word_class: 'AV' },
    { word: 'scarceness', word_class: 'N' }
  ],
  attendance: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  rippling: [
    { word: 'ripple', word_class: 'N' },
    { word: 'ripple', word_class: 'V' },
    { word: 'ripply', word_class: 'AJ' },
    { word: 'rippled', word_class: 'AJ' },
    { word: 'rippling', word_class: 'N' }
  ],
  jeering: [
    { word: 'jeer', word_class: 'N' },
    { word: 'jeer', word_class: 'V' },
    { word: 'jeering', word_class: 'N' },
    { word: 'jeering', word_class: 'AJ' }
  ],
  enterprising: [
    { word: 'enterprise', word_class: 'N' },
    { word: 'enterpriser', word_class: 'N' },
    { word: 'enterprising', word_class: 'AJ' }
  ],
  demythologization: [
    { word: 'demythologise', word_class: 'V' },
    { word: 'demythologize', word_class: 'V' },
    { word: 'demythologized', word_class: 'AJ' },
    { word: 'demythologisation', word_class: 'N' },
    { word: 'demythologization', word_class: 'N' }
  ],
  loafer: [
    { word: 'loaf', word_class: 'N' },
    { word: 'loaf', word_class: 'V' },
    { word: 'loafer', word_class: 'N' },
    { word: 'loafing', word_class: 'N' }
  ],
  restriction: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  snog: [{ word: 'snog', word_class: 'N' }, { word: 'snog', word_class: 'V' }, { word: 'snogging', word_class: 'N' }],
  moistness: [
    { word: 'moist', word_class: 'AJ' },
    { word: 'moisture', word_class: 'N' },
    { word: 'moistness', word_class: 'N' },
    { word: 'moisturize', word_class: 'V' }
  ],
  analogize: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  migrate: [
    { word: 'migrant', word_class: 'N' },
    { word: 'migrate', word_class: 'V' },
    { word: 'migrant', word_class: 'AJ' },
    { word: 'migration', word_class: 'N' }
  ],
  iterative: [
    { word: 'iterate', word_class: 'V' },
    { word: 'iterance', word_class: 'N' },
    { word: 'iteration', word_class: 'N' },
    { word: 'iterative', word_class: 'AJ' }
  ],
  extenuate: [
    { word: 'extenuate', word_class: 'V' },
    { word: 'extenuation', word_class: 'N' },
    { word: 'extenuating', word_class: 'AJ' }
  ],
  resolvable: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  ornately: [
    { word: 'ornate', word_class: 'AJ' },
    { word: 'ornately', word_class: 'AV' },
    { word: 'ornateness', word_class: 'N' }
  ],
  extolment: [
    { word: 'extol', word_class: 'V' },
    { word: 'extoll', word_class: 'V' },
    { word: 'extoller', word_class: 'N' },
    { word: 'extolment', word_class: 'N' }
  ],
  losings: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  trifle: [
    { word: 'trifle', word_class: 'N' },
    { word: 'trifle', word_class: 'V' },
    { word: 'trifling', word_class: 'N' },
    { word: 'trifling', word_class: 'AJ' }
  ],
  dialyzer: [
    { word: 'dialyze', word_class: 'V' },
    { word: 'dialysis', word_class: 'N' },
    { word: 'dialyzer', word_class: 'N' }
  ],
  joining: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  mediatise: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  circularise: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  definitely: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  diverted: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  interrogation: [
    { word: 'interrogate', word_class: 'V' },
    { word: 'interrogator', word_class: 'N' },
    { word: 'interrogation', word_class: 'N' },
    { word: 'interrogative', word_class: 'N' },
    { word: 'interrogative', word_class: 'AJ' },
    { word: 'interrogatively', word_class: 'AV' }
  ],
  insisting: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  dismaying: [
    { word: 'dismay', word_class: 'N' },
    { word: 'dismay', word_class: 'V' },
    { word: 'dismayed', word_class: 'AJ' },
    { word: 'dismaying', word_class: 'AJ' }
  ],
  dermatology: [
    { word: 'dermatology', word_class: 'N' },
    { word: 'dermatologic', word_class: 'AJ' },
    { word: 'dermatological', word_class: 'AJ' }
  ],
  insightful: [
    { word: 'insight', word_class: 'N' },
    { word: 'insight', word_class: 'V' },
    { word: 'insightful', word_class: 'AJ' },
    { word: 'insightfulness', word_class: 'N' }
  ],
  accurate: [
    { word: 'accuracy', word_class: 'N' },
    { word: 'accurate', word_class: 'AJ' },
    { word: 'accurately', word_class: 'AV' }
  ],
  driven: [
    { word: 'drive', word_class: 'N' },
    { word: 'drive', word_class: 'V' },
    { word: 'driver', word_class: 'N' },
    { word: 'driven', word_class: 'AJ' },
    { word: 'driving', word_class: 'N' },
    { word: 'driving', word_class: 'AJ' }
  ],
  miracle: [
    { word: 'miracle', word_class: 'N' },
    { word: 'miraculous', word_class: 'AJ' },
    { word: 'miraculously', word_class: 'AV' }
  ],
  moor: [{ word: 'moor', word_class: 'N' }, { word: 'moor', word_class: 'V' }, { word: 'mooring', word_class: 'N' }],
  cinchonize: [
    { word: 'cinchonise', word_class: 'V' },
    { word: 'cinchonize', word_class: 'V' },
    { word: 'cinchonisation', word_class: 'N' },
    { word: 'cinchonization', word_class: 'N' }
  ],
  tiresomely: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  sanctimoniousness: [
    { word: 'sanctimony', word_class: 'N' },
    { word: 'sanctimonious', word_class: 'AJ' },
    { word: 'sanctimoniously', word_class: 'AV' },
    { word: 'sanctimoniousness', word_class: 'N' }
  ],
  construct: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  socialist: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  attenuate: [
    { word: 'attenuate', word_class: 'V' },
    { word: 'attenuate', word_class: 'AJ' },
    { word: 'attenuated', word_class: 'AJ' },
    { word: 'attenuation', word_class: 'N' }
  ],
  diagrammatical: [
    { word: 'diagram', word_class: 'N' },
    { word: 'diagram', word_class: 'V' },
    { word: 'diagramming', word_class: 'N' },
    { word: 'diagrammatic', word_class: 'AJ' },
    { word: 'diagrammatical', word_class: 'AJ' },
    { word: 'diagrammatically', word_class: 'AV' }
  ],
  explorative: [
    { word: 'explore', word_class: 'V' },
    { word: 'explorer', word_class: 'N' },
    { word: 'exploration', word_class: 'N' },
    { word: 'explorative', word_class: 'AJ' },
    { word: 'exploratory', word_class: 'AJ' }
  ],
  complicity: [
    { word: 'complicate', word_class: 'V' },
    { word: 'complicity', word_class: 'N' },
    { word: 'complicated', word_class: 'AJ' },
    { word: 'complicating', word_class: 'N' },
    { word: 'complication', word_class: 'N' }
  ],
  ham: [{ word: 'ham', word_class: 'N' }, { word: 'ham', word_class: 'V' }, { word: 'hamming', word_class: 'N' }],
  dogmatisation: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  efficaciously: [
    { word: 'efficacy', word_class: 'N' },
    { word: 'efficacious', word_class: 'AJ' },
    { word: 'efficaciously', word_class: 'AV' },
    { word: 'efficaciousness', word_class: 'N' }
  ],
  fin: [{ word: 'fin', word_class: 'N' }, { word: 'fin', word_class: 'V' }, { word: 'fins', word_class: 'N' }],
  impenetrable: [
    { word: 'impenetrable', word_class: 'AJ' },
    { word: 'impenetrability', word_class: 'N' },
    { word: 'impenetrableness', word_class: 'N' }
  ],
  prepositional: [
    { word: 'preposition', word_class: 'N' },
    { word: 'preposition', word_class: 'V' },
    { word: 'prepositional', word_class: 'AJ' }
  ],
  rotation: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  estuarial: [
    { word: 'estuary', word_class: 'N' },
    { word: 'estuarial', word_class: 'AJ' },
    { word: 'estuarine', word_class: 'AJ' }
  ],
  aestivate: [
    { word: 'aestival', word_class: 'AJ' },
    { word: 'aestivate', word_class: 'V' },
    { word: 'aestivation', word_class: 'N' }
  ],
  invigorated: [
    { word: 'invigorate', word_class: 'V' },
    { word: 'invigorated', word_class: 'AJ' },
    { word: 'invigoration', word_class: 'N' },
    { word: 'invigorating', word_class: 'AJ' }
  ],
  russianization: [
    { word: 'russian', word_class: 'N' },
    { word: 'russian', word_class: 'AJ' },
    { word: 'russianise', word_class: 'V' },
    { word: 'russianize', word_class: 'V' },
    { word: 'russianisation', word_class: 'N' },
    { word: 'russianization', word_class: 'N' }
  ],
  sparsely: [
    { word: 'sparse', word_class: 'AJ' },
    { word: 'sparsely', word_class: 'AV' },
    { word: 'sparseness', word_class: 'N' }
  ],
  fattened: [
    { word: 'fatten', word_class: 'V' },
    { word: 'fattened', word_class: 'AJ' },
    { word: 'fattening', word_class: 'AJ' }
  ],
  banishment: [
    { word: 'banish', word_class: 'V' },
    { word: 'banish', word_class: 'AJ' },
    { word: 'banishment', word_class: 'N' }
  ],
  founded: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  stick: [
    { word: 'stick', word_class: 'N' },
    { word: 'stick', word_class: 'V' },
    { word: 'sticker', word_class: 'N' },
    { word: 'sticky', word_class: 'AJ' },
    { word: 'sticking', word_class: 'AJ' },
    { word: 'stickiness', word_class: 'N' }
  ],
  irritation: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  slenderization: [
    { word: 'slender', word_class: 'AJ' },
    { word: 'slenderize', word_class: 'V' },
    { word: 'slenderness', word_class: 'N' },
    { word: 'slenderization', word_class: 'N' }
  ],
  goldbrick: [
    { word: 'goldbrick', word_class: 'N' },
    { word: 'goldbricking', word_class: 'N' },
    { word: 'goldbricking', word_class: 'AJ' }
  ],
  brain: [
    { word: 'brain', word_class: 'N' },
    { word: 'brain', word_class: 'V' },
    { word: 'brained', word_class: 'AJ' }
  ],
  backhanded: [
    { word: 'backhand', word_class: 'N' },
    { word: 'backhand', word_class: 'V' },
    { word: 'backhand', word_class: 'AJ' },
    { word: 'backhanded', word_class: 'AJ' }
  ],
  escapist: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  carriage: [
    { word: 'carry', word_class: 'N' },
    { word: 'carry', word_class: 'V' },
    { word: 'carry', word_class: 'AJ' },
    { word: 'carrier', word_class: 'N' },
    { word: 'carriage', word_class: 'N' }
  ],
  fulfillment: [
    { word: 'fulfil', word_class: 'V' },
    { word: 'fulfill', word_class: 'V' },
    { word: 'fulfilled', word_class: 'AJ' },
    { word: 'fulfilment', word_class: 'N' },
    { word: 'fulfillment', word_class: 'N' }
  ],
  sweet: [
    { word: 'sweet', word_class: 'N' },
    { word: 'sweet', word_class: 'AJ' },
    { word: 'sweet', word_class: 'AV' },
    { word: 'sweetness', word_class: 'N' }
  ],
  rhythmicity: [
    { word: 'rhythmic', word_class: 'AJ' },
    { word: 'rhythmical', word_class: 'AJ' },
    { word: 'rhythmicity', word_class: 'N' },
    { word: 'rhythmically', word_class: 'AV' }
  ],
  intradermally: [
    { word: 'intradermal', word_class: 'AJ' },
    { word: 'intradermic', word_class: 'AJ' },
    { word: 'intradermally', word_class: 'AV' }
  ],
  infestation: [
    { word: 'infest', word_class: 'N' },
    { word: 'infest', word_class: 'V' },
    { word: 'infested', word_class: 'AJ' },
    { word: 'infestation', word_class: 'N' }
  ],
  irruption: [
    { word: 'irrupt', word_class: 'V' },
    { word: 'irruption', word_class: 'N' },
    { word: 'irruptive', word_class: 'AJ' }
  ],
  rareness: [
    { word: 'rare', word_class: 'AJ' },
    { word: 'rarely', word_class: 'AV' },
    { word: 'raring', word_class: 'AJ' },
    { word: 'rareness', word_class: 'N' }
  ],
  urbanity: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  evolutionary: [
    { word: 'evolve', word_class: 'V' },
    { word: 'evolution', word_class: 'N' },
    { word: 'evolvement', word_class: 'N' },
    { word: 'evolutionary', word_class: 'AJ' }
  ],
  bound: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  superposable: [
    { word: 'superpose', word_class: 'N' },
    { word: 'superpose', word_class: 'V' },
    { word: 'superposable', word_class: 'AJ' },
    { word: 'superposition', word_class: 'N' }
  ],
  chaste: [
    { word: 'chaste', word_class: 'AJ' },
    { word: 'chastely', word_class: 'AV' },
    { word: 'chasteness', word_class: 'N' }
  ],
  unadaptability: [
    { word: 'unadapted', word_class: 'AJ' },
    { word: 'unadaptable', word_class: 'AJ' },
    { word: 'unadaptability', word_class: 'N' }
  ],
  intensification: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  empurpled: [
    { word: 'empurple', word_class: 'V' },
    { word: 'empurpled', word_class: 'N' },
    { word: 'empurpled', word_class: 'AJ' }
  ],
  conformance: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  entrust: [
    { word: 'entrust', word_class: 'V' },
    { word: 'intrust', word_class: 'V' },
    { word: 'entrustment', word_class: 'N' },
    { word: 'intrustment', word_class: 'N' }
  ],
  distention: [
    { word: 'distend', word_class: 'V' },
    { word: 'distended', word_class: 'AJ' },
    { word: 'distension', word_class: 'N' },
    { word: 'distention', word_class: 'N' },
    { word: 'distensible', word_class: 'AJ' }
  ],
  blinks: [
    { word: 'blink', word_class: 'N' },
    { word: 'blink', word_class: 'V' },
    { word: 'blinks', word_class: 'N' },
    { word: 'blinker', word_class: 'N' },
    { word: 'blinker', word_class: 'V' },
    { word: 'blinking', word_class: 'AJ' }
  ],
  impotently: [
    { word: 'impotence', word_class: 'N' },
    { word: 'impotency', word_class: 'N' },
    { word: 'impotent', word_class: 'AJ' },
    { word: 'impotently', word_class: 'AV' }
  ],
  applaudable: [
    { word: 'applaud', word_class: 'V' },
    { word: 'applause', word_class: 'N' },
    { word: 'applauder', word_class: 'N' },
    { word: 'applaudable', word_class: 'AJ' }
  ],
  expense: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  racketeering: [
    { word: 'racket', word_class: 'N' },
    { word: 'racket', word_class: 'V' },
    { word: 'racketeer', word_class: 'N' },
    { word: 'racketeer', word_class: 'V' },
    { word: 'racketeering', word_class: 'N' }
  ],
  portability: [
    { word: 'portable', word_class: 'N' },
    { word: 'portable', word_class: 'AJ' },
    { word: 'portably', word_class: 'AV' },
    { word: 'portability', word_class: 'N' }
  ],
  consolidate: [
    { word: 'consolidate', word_class: 'V' },
    { word: 'consolidated', word_class: 'AJ' },
    { word: 'consolidation', word_class: 'N' },
    { word: 'consolidative', word_class: 'AJ' }
  ],
  exegetic: [
    { word: 'exegete', word_class: 'N' },
    { word: 'exegetic', word_class: 'AJ' },
    { word: 'exegetical', word_class: 'AJ' }
  ],
  paramagnetism: [
    { word: 'paramagnet', word_class: 'N' },
    { word: 'paramagnetic', word_class: 'AJ' },
    { word: 'paramagnetism', word_class: 'N' }
  ],
  explanation: [
    { word: 'explain', word_class: 'N' },
    { word: 'explain', word_class: 'V' },
    { word: 'explain', word_class: 'AJ' },
    { word: 'explanation', word_class: 'N' },
    { word: 'explainable', word_class: 'AJ' },
    { word: 'explanatory', word_class: 'AJ' }
  ],
  credulity: [
    { word: 'credulity', word_class: 'N' },
    { word: 'credulous', word_class: 'AJ' },
    { word: 'credulously', word_class: 'AV' },
    { word: 'credulousness', word_class: 'N' }
  ],
  boastful: [
    { word: 'boast', word_class: 'N' },
    { word: 'boast', word_class: 'V' },
    { word: 'boaster', word_class: 'N' },
    { word: 'boasting', word_class: 'N' },
    { word: 'boastful', word_class: 'AJ' },
    { word: 'boastfulness', word_class: 'N' }
  ],
  whiting: [
    { word: 'white', word_class: 'N' },
    { word: 'white', word_class: 'V' },
    { word: 'white', word_class: 'AJ' },
    { word: 'whiting', word_class: 'N' },
    { word: 'whiteness', word_class: 'N' }
  ],
  perishable: [
    { word: 'perish', word_class: 'V' },
    { word: 'perisher', word_class: 'N' },
    { word: 'perishable', word_class: 'AJ' },
    { word: 'perishables', word_class: 'N' }
  ],
  exist: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  anesthetize: [
    { word: 'anesthetic', word_class: 'N' },
    { word: 'anesthetic', word_class: 'AJ' },
    { word: 'anesthetize', word_class: 'V' },
    { word: 'anesthetized', word_class: 'AJ' },
    { word: 'anesthetization', word_class: 'N' }
  ],
  pail: [
    { word: 'pail', word_class: 'N' },
    { word: 'pailful', word_class: 'N' },
    { word: 'pailful', word_class: 'AJ' }
  ],
  invention: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  suspensive: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  crab: [
    { word: 'crab', word_class: 'N' },
    { word: 'crab', word_class: 'V' },
    { word: 'crabs', word_class: 'N' },
    { word: 'crabbed', word_class: 'AJ' }
  ],
  aggressiveness: [
    { word: 'aggress', word_class: 'V' },
    { word: 'aggression', word_class: 'N' },
    { word: 'aggressive', word_class: 'AJ' },
    { word: 'aggressively', word_class: 'AV' },
    { word: 'aggressiveness', word_class: 'N' }
  ],
  disseverment: [
    { word: 'dissever', word_class: 'V' },
    { word: 'disseverance', word_class: 'N' },
    { word: 'disseverment', word_class: 'N' },
    { word: 'disseveration', word_class: 'N' }
  ],
  suffixion: [
    { word: 'suffix', word_class: 'N' },
    { word: 'suffix', word_class: 'V' },
    { word: 'suffixion', word_class: 'N' },
    { word: 'suffixation', word_class: 'N' }
  ],
  destined: [
    { word: 'destine', word_class: 'V' },
    { word: 'destiny', word_class: 'N' },
    { word: 'destined', word_class: 'AJ' },
    { word: 'destination', word_class: 'N' }
  ],
  fractured: [
    { word: 'fracture', word_class: 'N' },
    { word: 'fracture', word_class: 'V' },
    { word: 'fractured', word_class: 'AJ' }
  ],
  problematical: [
    { word: 'problem', word_class: 'N' },
    { word: 'problematic', word_class: 'AJ' },
    { word: 'problematical', word_class: 'AJ' },
    { word: 'problematically', word_class: 'AV' }
  ],
  verbalization: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  occasionment: [
    { word: 'occasion', word_class: 'N' },
    { word: 'occasion', word_class: 'V' },
    { word: 'occasions', word_class: 'N' },
    { word: 'occasional', word_class: 'AJ' },
    { word: 'occasionment', word_class: 'N' },
    { word: 'occasionally', word_class: 'AV' }
  ],
  humanisation: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  nut: [
    { word: 'nut', word_class: 'N' },
    { word: 'nut', word_class: 'V' },
    { word: 'nuts', word_class: 'AJ' },
    { word: 'nutate', word_class: 'V' },
    { word: 'nutation', word_class: 'N' }
  ],
  hesitation: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  bawdiness: [
    { word: 'bawdy', word_class: 'N' },
    { word: 'bawdy', word_class: 'AJ' },
    { word: 'bawdiness', word_class: 'N' }
  ],
  bucket: [
    { word: 'bucket', word_class: 'N' },
    { word: 'bucket', word_class: 'V' },
    { word: 'bucketful', word_class: 'N' },
    { word: 'bucketful', word_class: 'AJ' }
  ],
  fostering: [
    { word: 'foster', word_class: 'V' },
    { word: 'foster', word_class: 'AJ' },
    { word: 'fosterage', word_class: 'N' },
    { word: 'fostered', word_class: 'AJ' },
    { word: 'fostering', word_class: 'N' }
  ],
  manifest: [
    { word: 'manifest', word_class: 'N' },
    { word: 'manifest', word_class: 'V' },
    { word: 'manifest', word_class: 'AJ' },
    { word: 'manifestation', word_class: 'N' }
  ],
  major: [
    { word: 'major', word_class: 'N' },
    { word: 'major', word_class: 'V' },
    { word: 'major', word_class: 'AJ' },
    { word: 'majors', word_class: 'N' },
    { word: 'majority', word_class: 'N' }
  ],
  gratified: [
    { word: 'gratify', word_class: 'V' },
    { word: 'gratified', word_class: 'AJ' },
    { word: 'gratifying', word_class: 'AJ' },
    { word: 'gratification', word_class: 'N' }
  ],
  cylindrical: [
    { word: 'cylinder', word_class: 'N' },
    { word: 'cylindric', word_class: 'AJ' },
    { word: 'cylindrical', word_class: 'AJ' },
    { word: 'cylindricality', word_class: 'N' }
  ],
  'double-spacing': [
    { word: 'double-space', word_class: 'V' },
    { word: 'double-spaced', word_class: 'AJ' },
    { word: 'double-spacing', word_class: 'N' }
  ],
  belle: [
    { word: 'bell', word_class: 'N' },
    { word: 'bell', word_class: 'V' },
    { word: 'belle', word_class: 'N' },
    { word: 'belling', word_class: 'N' },
    { word: 'bellman', word_class: 'N' }
  ],
  imbruembrue: [
    { word: 'imbrue', word_class: 'V' },
    { word: 'imbruement', word_class: 'N' },
    { word: 'imbruembrue', word_class: 'V' },
    { word: 'imbruembruement', word_class: 'N' }
  ],
  nucleus: [
    { word: 'nucleus', word_class: 'N' },
    { word: 'nucleate', word_class: 'V' },
    { word: 'nucleation', word_class: 'N' }
  ],
  digested: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  supplemental: [
    { word: 'supplement', word_class: 'N' },
    { word: 'supplement', word_class: 'V' },
    { word: 'supplemental', word_class: 'AJ' },
    { word: 'supplementary', word_class: 'AJ' },
    { word: 'supplementation', word_class: 'N' }
  ],
  fund: [
    { word: 'fund', word_class: 'N' },
    { word: 'fund', word_class: 'V' },
    { word: 'funds', word_class: 'N' },
    { word: 'funded', word_class: 'AJ' },
    { word: 'funding', word_class: 'N' }
  ],
  anaphor: [
    { word: 'anaphor', word_class: 'N' },
    { word: 'anaphora', word_class: 'N' },
    { word: 'anaphoric', word_class: 'AJ' }
  ],
  suture: [
    { word: 'suture', word_class: 'N' },
    { word: 'suture', word_class: 'V' },
    { word: 'suturing', word_class: 'N' }
  ],
  seethe: [
    { word: 'seethe', word_class: 'N' },
    { word: 'seethe', word_class: 'V' },
    { word: 'seething', word_class: 'AJ' }
  ],
  defeasance: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  boiler: [
    { word: 'boil', word_class: 'N' },
    { word: 'boil', word_class: 'V' },
    { word: 'boiler', word_class: 'N' },
    { word: 'boiled', word_class: 'AJ' },
    { word: 'boiling', word_class: 'N' },
    { word: 'boiling', word_class: 'AJ' },
    { word: 'boiling', word_class: 'AV' }
  ],
  adoration: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  garlicky: [
    { word: 'garlic', word_class: 'N' },
    { word: 'garlicky', word_class: 'AJ' },
    { word: 'garlicked', word_class: 'AJ' }
  ],
  billions: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  dominated: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  criminate: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  appendaged: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  coroner: [
    { word: 'coronal', word_class: 'N' },
    { word: 'coroner', word_class: 'N' },
    { word: 'coronate', word_class: 'V' },
    { word: 'coronation', word_class: 'N' }
  ],
  predominate: [
    { word: 'predominate', word_class: 'V' },
    { word: 'predominance', word_class: 'N' },
    { word: 'predominant', word_class: 'AJ' },
    { word: 'predominate', word_class: 'AJ' },
    { word: 'predomination', word_class: 'N' }
  ],
  merely: [{ word: 'mere', word_class: 'N' }, { word: 'mere', word_class: 'AJ' }, { word: 'merely', word_class: 'AV' }],
  plop: [{ word: 'plop', word_class: 'N' }, { word: 'plop', word_class: 'V' }, { word: 'plop', word_class: 'AV' }],
  throbbing: [
    { word: 'throb', word_class: 'N' },
    { word: 'throb', word_class: 'V' },
    { word: 'throbbing', word_class: 'N' },
    { word: 'throbbing', word_class: 'AJ' }
  ],
  idiocy: [
    { word: 'idiot', word_class: 'N' },
    { word: 'idiocy', word_class: 'N' },
    { word: 'idiotic', word_class: 'AJ' },
    { word: 'idiotically', word_class: 'AV' }
  ],
  achievable: [
    { word: 'achieve', word_class: 'V' },
    { word: 'achiever', word_class: 'N' },
    { word: 'achievable', word_class: 'AJ' },
    { word: 'achievement', word_class: 'N' },
    { word: 'achievability', word_class: 'N' }
  ],
  'co-option': [
    { word: 'coopt', word_class: 'V' },
    { word: 'co-opt', word_class: 'V' },
    { word: 'co-option', word_class: 'N' },
    { word: 'co-optation', word_class: 'N' }
  ],
  estuary: [
    { word: 'estuary', word_class: 'N' },
    { word: 'estuarial', word_class: 'AJ' },
    { word: 'estuarine', word_class: 'AJ' }
  ],
  taxis: [
    { word: 'taxi', word_class: 'N' },
    { word: 'taxi', word_class: 'V' },
    { word: 'taxis', word_class: 'N' },
    { word: 'taximan', word_class: 'N' }
  ],
  updating: [
    { word: 'update', word_class: 'N' },
    { word: 'update', word_class: 'V' },
    { word: 'updating', word_class: 'N' }
  ],
  fellation: [
    { word: 'fell', word_class: 'N' },
    { word: 'fell', word_class: 'V' },
    { word: 'fell', word_class: 'AJ' },
    { word: 'fellate', word_class: 'V' },
    { word: 'felled', word_class: 'AJ' },
    { word: 'fellation', word_class: 'N' }
  ],
  equalise: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  delegation: [
    { word: 'delegate', word_class: 'N' },
    { word: 'delegate', word_class: 'V' },
    { word: 'delegable', word_class: 'AJ' },
    { word: 'delegating', word_class: 'N' },
    { word: 'delegation', word_class: 'N' }
  ],
  ice: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  peeve: [{ word: 'peeve', word_class: 'N' }, { word: 'peeve', word_class: 'V' }, { word: 'peeved', word_class: 'AJ' }],
  execute: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  consuming: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  enervation: [
    { word: 'enervate', word_class: 'V' },
    { word: 'enervated', word_class: 'AJ' },
    { word: 'enervation', word_class: 'N' },
    { word: 'enervating', word_class: 'AJ' }
  ],
  spatially: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  bricklay: [
    { word: 'bricklay', word_class: 'V' },
    { word: 'bricklayer', word_class: 'N' },
    { word: 'bricklaying', word_class: 'N' }
  ],
  counterman: [
    { word: 'count', word_class: 'N' },
    { word: 'count', word_class: 'V' },
    { word: 'counter', word_class: 'N' },
    { word: 'counter', word_class: 'V' },
    { word: 'counter', word_class: 'AV' },
    { word: 'counter', word_class: 'AJ' },
    { word: 'counting', word_class: 'N' },
    { word: 'counterman', word_class: 'N' }
  ],
  convening: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  feasibleness: [
    { word: 'feasible', word_class: 'AJ' },
    { word: 'feasible', word_class: 'AV' },
    { word: 'feasibility', word_class: 'N' },
    { word: 'feasibleness', word_class: 'N' }
  ],
  alternator: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  ozonization: [
    { word: 'ozone', word_class: 'N' },
    { word: 'ozonise', word_class: 'V' },
    { word: 'ozonize', word_class: 'V' },
    { word: 'ozonisation', word_class: 'N' },
    { word: 'ozonization', word_class: 'N' }
  ],
  fence: [
    { word: 'fence', word_class: 'N' },
    { word: 'fence', word_class: 'V' },
    { word: 'fencer', word_class: 'N' },
    { word: 'fencing', word_class: 'N' }
  ],
  gymnast: [
    { word: 'gymnast', word_class: 'N' },
    { word: 'gymnastic', word_class: 'AJ' },
    { word: 'gymnastics', word_class: 'N' }
  ],
  matman: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  benedict: [
    { word: 'benedict', word_class: 'N' },
    { word: 'benediction', word_class: 'N' },
    { word: 'benedictive', word_class: 'AJ' }
  ],
  wrestler: [
    { word: 'wrestle', word_class: 'N' },
    { word: 'wrestle', word_class: 'V' },
    { word: 'wrestler', word_class: 'N' },
    { word: 'wrestling', word_class: 'N' }
  ],
  dodder: [
    { word: 'dodder', word_class: 'N' },
    { word: 'dodder', word_class: 'V' },
    { word: 'dodderer', word_class: 'N' },
    { word: 'doddering', word_class: 'AJ' }
  ],
  irregularity: [
    { word: 'irregular', word_class: 'N' },
    { word: 'irregular', word_class: 'AJ' },
    { word: 'irregularity', word_class: 'N' }
  ],
  tunicate: [
    { word: 'tunic', word_class: 'N' },
    { word: 'tunic', word_class: 'AJ' },
    { word: 'tunicate', word_class: 'N' }
  ],
  arrogant: [
    { word: 'arrogate', word_class: 'V' },
    { word: 'arrogance', word_class: 'N' },
    { word: 'arrogant', word_class: 'AJ' },
    { word: 'arrogated', word_class: 'AJ' },
    { word: 'arrogation', word_class: 'N' }
  ],
  promisee: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  belittle: [
    { word: 'belittle', word_class: 'V' },
    { word: 'belittled', word_class: 'AJ' },
    { word: 'belittling', word_class: 'N' },
    { word: 'belittling', word_class: 'AJ' },
    { word: 'belittlement', word_class: 'N' }
  ],
  swearing: [
    { word: 'swear', word_class: 'V' },
    { word: 'sworn', word_class: 'AJ' },
    { word: 'swearer', word_class: 'N' },
    { word: 'swearing', word_class: 'N' }
  ],
  batting: [
    { word: 'bat', word_class: 'N' },
    { word: 'bat', word_class: 'V' },
    { word: 'bats', word_class: 'AJ' },
    { word: 'batman', word_class: 'N' },
    { word: 'batting', word_class: 'N' }
  ],
  incense: [
    { word: 'incense', word_class: 'N' },
    { word: 'incense', word_class: 'V' },
    { word: 'incensed', word_class: 'AJ' },
    { word: 'incensation', word_class: 'N' },
    { word: 'incensement', word_class: 'N' }
  ],
  bigoted: [
    { word: 'bigot', word_class: 'N' },
    { word: 'bigotry', word_class: 'N' },
    { word: 'bigoted', word_class: 'AJ' }
  ],
  vapidness: [
    { word: 'vapid', word_class: 'AJ' },
    { word: 'vapidity', word_class: 'N' },
    { word: 'vapidness', word_class: 'N' }
  ],
  melodization: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  sow: [
    { word: 'sow', word_class: 'N' },
    { word: 'sow', word_class: 'V' },
    { word: 'sower', word_class: 'N' },
    { word: 'sown', word_class: 'AJ' }
  ],
  attitudinization: [
    { word: 'attitude', word_class: 'N' },
    { word: 'attitudinal', word_class: 'AJ' },
    { word: 'attitudinize', word_class: 'V' },
    { word: 'attitudinization', word_class: 'N' }
  ],
  evangelization: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  adsorbate: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  ammoniate: [
    { word: 'ammoniate', word_class: 'V' },
    { word: 'ammoniated', word_class: 'AJ' },
    { word: 'ammoniation', word_class: 'N' }
  ],
  caroller: [
    { word: 'carol', word_class: 'N' },
    { word: 'carol', word_class: 'V' },
    { word: 'caroler', word_class: 'N' },
    { word: 'caroling', word_class: 'N' },
    { word: 'caroller', word_class: 'N' }
  ],
  tuning: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  tubed: [
    { word: 'tube', word_class: 'N' },
    { word: 'tube', word_class: 'V' },
    { word: 'tubed', word_class: 'AJ' },
    { word: 'tubing', word_class: 'N' },
    { word: 'tubular', word_class: 'AJ' }
  ],
  collaboration: [
    { word: 'collaborate', word_class: 'V' },
    { word: 'collaborator', word_class: 'N' },
    { word: 'collaboration', word_class: 'N' }
  ],
  absorptive: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  oiled: [
    { word: 'oil', word_class: 'N' },
    { word: 'oil', word_class: 'V' },
    { word: 'oiler', word_class: 'N' },
    { word: 'oiled', word_class: 'AJ' },
    { word: 'oilman', word_class: 'N' }
  ],
  justlement: [
    { word: 'jostle', word_class: 'N' },
    { word: 'jostle', word_class: 'V' },
    { word: 'justle', word_class: 'V' },
    { word: 'jostling', word_class: 'N' },
    { word: 'jostlement', word_class: 'N' },
    { word: 'justlement', word_class: 'N' }
  ],
  discovered: [
    { word: 'discover', word_class: 'N' },
    { word: 'discover', word_class: 'V' },
    { word: 'discovery', word_class: 'N' },
    { word: 'discoverer', word_class: 'N' },
    { word: 'discovery', word_class: 'AJ' },
    { word: 'discovered', word_class: 'AJ' },
    { word: 'discoverable', word_class: 'AJ' }
  ],
  grieve: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  kyanisation: [
    { word: 'kyanise', word_class: 'V' },
    { word: 'kyanize', word_class: 'V' },
    { word: 'kyanisation', word_class: 'N' },
    { word: 'kyanization', word_class: 'N' }
  ],
  blunt: [
    { word: 'blunt', word_class: 'V' },
    { word: 'blunt', word_class: 'AJ' },
    { word: 'blunted', word_class: 'AJ' },
    { word: 'bluntness', word_class: 'N' }
  ],
  confidentially: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  sophistic: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  disreputability: [
    { word: 'disrepute', word_class: 'N' },
    { word: 'disreputable', word_class: 'AJ' },
    { word: 'disreputably', word_class: 'AV' },
    { word: 'disreputability', word_class: 'N' },
    { word: 'disreputableness', word_class: 'N' }
  ],
  reassurance: [
    { word: 'reassure', word_class: 'V' },
    { word: 'reassured', word_class: 'AJ' },
    { word: 'reassurance', word_class: 'N' },
    { word: 'reassuring', word_class: 'AJ' }
  ],
  frequenter: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  permanently: [
    { word: 'permanent', word_class: 'N' },
    { word: 'permanence', word_class: 'N' },
    { word: 'permanency', word_class: 'N' },
    { word: 'permanent', word_class: 'AJ' },
    { word: 'permanently', word_class: 'AV' }
  ],
  steadied: [
    { word: 'steady', word_class: 'N' },
    { word: 'steady', word_class: 'V' },
    { word: 'steady', word_class: 'AJ' },
    { word: 'steady', word_class: 'AV' },
    { word: 'steadied', word_class: 'AJ' },
    { word: 'steadiness', word_class: 'N' },
    { word: 'steadying', word_class: 'AJ' }
  ],
  bowed: [
    { word: 'bow', word_class: 'N' },
    { word: 'bow', word_class: 'V' },
    { word: 'bow', word_class: 'AJ' },
    { word: 'bower', word_class: 'N' },
    { word: 'bower', word_class: 'V' },
    { word: 'bowed', word_class: 'AJ' },
    { word: 'bowman', word_class: 'N' },
    { word: 'bowing', word_class: 'N' },
    { word: 'bowing', word_class: 'AJ' }
  ],
  desistance: [
    { word: 'desist', word_class: 'V' },
    { word: 'desistance', word_class: 'N' },
    { word: 'desistence', word_class: 'N' }
  ],
  handicap: [
    { word: 'handicap', word_class: 'N' },
    { word: 'handicap', word_class: 'V' },
    { word: 'handicapped', word_class: 'AJ' }
  ],
  bedded: [
    { word: 'bed', word_class: 'N' },
    { word: 'bed', word_class: 'V' },
    { word: 'bedded', word_class: 'AJ' },
    { word: 'bedding', word_class: 'N' }
  ],
  hoarse: [
    { word: 'hoarse', word_class: 'AJ' },
    { word: 'hoarsely', word_class: 'AV' },
    { word: 'hoarseness', word_class: 'N' }
  ],
  photosensitize: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  solvent: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  fiery: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  affect: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  ignitor: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  appraisal: [
    { word: 'appraise', word_class: 'V' },
    { word: 'appraisal', word_class: 'N' },
    { word: 'appraiser', word_class: 'N' },
    { word: 'appraising', word_class: 'AJ' }
  ],
  empowerment: [
    { word: 'empower', word_class: 'V' },
    { word: 'empowered', word_class: 'AJ' },
    { word: 'empowerment', word_class: 'N' }
  ],
  raid: [
    { word: 'raid', word_class: 'N' },
    { word: 'raid', word_class: 'V' },
    { word: 'raider', word_class: 'N' },
    { word: 'raiding', word_class: 'AJ' }
  ],
  aggregation: [
    { word: 'aggregate', word_class: 'N' },
    { word: 'aggregate', word_class: 'V' },
    { word: 'aggregate', word_class: 'AJ' },
    { word: 'aggregation', word_class: 'N' },
    { word: 'aggregative', word_class: 'AJ' }
  ],
  deodorise: [
    { word: 'deodorant', word_class: 'N' },
    { word: 'deodorise', word_class: 'V' },
    { word: 'deodorize', word_class: 'V' },
    { word: 'deodorisation', word_class: 'N' },
    { word: 'deodorization', word_class: 'N' }
  ],
  oxidative: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  rubifcation: [
    { word: 'rubefy', word_class: 'V' },
    { word: 'rubifcation', word_class: 'N' },
    { word: 'rubification', word_class: 'N' }
  ],
  topple: [
    { word: 'topple', word_class: 'N' },
    { word: 'topple', word_class: 'V' },
    { word: 'toppling', word_class: 'AJ' }
  ],
  dress: [
    { word: 'dress', word_class: 'N' },
    { word: 'dress', word_class: 'V' },
    { word: 'dress', word_class: 'AJ' },
    { word: 'dresser', word_class: 'N' },
    { word: 'dressed', word_class: 'AJ' },
    { word: 'dressing', word_class: 'N' }
  ],
  fulgurating: [
    { word: 'fulgurate', word_class: 'V' },
    { word: 'fulgurant', word_class: 'AJ' },
    { word: 'fulgurous', word_class: 'AJ' },
    { word: 'fulguration', word_class: 'N' },
    { word: 'fulgurating', word_class: 'AJ' }
  ],
  tampering: [
    { word: 'tamp', word_class: 'N' },
    { word: 'tamp', word_class: 'V' },
    { word: 'tamper', word_class: 'N' },
    { word: 'tamper', word_class: 'V' },
    { word: 'tampering', word_class: 'N' }
  ],
  abstracted: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  chaperone: [
    { word: 'chaperon', word_class: 'N' },
    { word: 'chaperon', word_class: 'V' },
    { word: 'chaperone', word_class: 'N' },
    { word: 'chaperone', word_class: 'V' },
    { word: 'chaperonage', word_class: 'N' }
  ],
  miller: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  impermanent: [
    { word: 'impermanence', word_class: 'N' },
    { word: 'impermanency', word_class: 'N' },
    { word: 'impermanent', word_class: 'AJ' }
  ],
  tracked: [
    { word: 'track', word_class: 'N' },
    { word: 'track', word_class: 'V' },
    { word: 'tracker', word_class: 'N' },
    { word: 'tracked', word_class: 'AJ' },
    { word: 'tracking', word_class: 'N' }
  ],
  indoor: [
    { word: 'indoor', word_class: 'N' },
    { word: 'indoor', word_class: 'AJ' },
    { word: 'indoors', word_class: 'AV' }
  ],
  alined: [
    { word: 'aline', word_class: 'V' },
    { word: 'alined', word_class: 'AJ' },
    { word: 'alinement', word_class: 'N' }
  ],
  ratiocinator: [
    { word: 'ratiocinate', word_class: 'V' },
    { word: 'ratiocinator', word_class: 'N' },
    { word: 'ratiocination', word_class: 'N' },
    { word: 'ratiocinative', word_class: 'AJ' }
  ],
  discouraged: [
    { word: 'discourage', word_class: 'N' },
    { word: 'discourage', word_class: 'V' },
    { word: 'discouraged', word_class: 'AJ' },
    { word: 'discouraging', word_class: 'AJ' },
    { word: 'discouragement', word_class: 'N' }
  ],
  honesty: [
    { word: 'honest', word_class: 'AJ' },
    { word: 'honesty', word_class: 'N' },
    { word: 'honestness', word_class: 'N' }
  ],
  electronics: [
    { word: 'electron', word_class: 'N' },
    { word: 'electronic', word_class: 'AJ' },
    { word: 'electronics', word_class: 'N' },
    { word: 'electronically', word_class: 'AV' }
  ],
  dy: [
    { word: 'dy', word_class: 'N' },
    { word: 'die', word_class: 'N' },
    { word: 'die', word_class: 'V' },
    { word: 'death', word_class: 'N' },
    { word: 'dyed', word_class: 'AJ' },
    { word: 'dying', word_class: 'V' },
    { word: 'dying', word_class: 'N' },
    { word: 'dying', word_class: 'AJ' }
  ],
  gossip: [
    { word: 'gossip', word_class: 'N' },
    { word: 'gossip', word_class: 'V' },
    { word: 'gossiper', word_class: 'N' },
    { word: 'gossiping', word_class: 'N' }
  ],
  accompany: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  glacier: [
    { word: 'glacier', word_class: 'N' },
    { word: 'glacial', word_class: 'AJ' },
    { word: 'glacially', word_class: 'AV' }
  ],
  venerate: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  tent: [
    { word: 'tent', word_class: 'N' },
    { word: 'tent', word_class: 'V' },
    { word: 'tenting', word_class: 'N' },
    { word: 'tentative', word_class: 'AJ' },
    { word: 'tentatively', word_class: 'AV' }
  ],
  adiposity: [
    { word: 'adipose', word_class: 'AJ' },
    { word: 'adiposity', word_class: 'N' },
    { word: 'adiposeness', word_class: 'N' }
  ],
  convinced: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  bombastically: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  gelatin: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  blinding: [
    { word: 'blind', word_class: 'N' },
    { word: 'blind', word_class: 'V' },
    { word: 'blind', word_class: 'AJ' },
    { word: 'blinder', word_class: 'N' },
    { word: 'blinded', word_class: 'AJ' },
    { word: 'blinding', word_class: 'AJ' },
    { word: 'blindness', word_class: 'N' }
  ],
  inspirit: [
    { word: 'inspirit', word_class: 'V' },
    { word: 'inspiriting', word_class: 'AJ' },
    { word: 'inspiritment', word_class: 'N' }
  ],
  rapturously: [
    { word: 'rapture', word_class: 'N' },
    { word: 'rapture', word_class: 'V' },
    { word: 'rapturous', word_class: 'AJ' },
    { word: 'rapturously', word_class: 'AV' }
  ],
  etymology: [
    { word: 'etymology', word_class: 'N' },
    { word: 'etymologize', word_class: 'V' },
    { word: 'etymological', word_class: 'AJ' },
    { word: 'etymologizing', word_class: 'N' },
    { word: 'etymologization', word_class: 'N' }
  ],
  'ill-treated': [
    { word: 'ill-treat', word_class: 'V' },
    { word: 'ill-treated', word_class: 'AJ' },
    { word: 'ill-treatment', word_class: 'N' }
  ],
  curdled: [
    { word: 'curdle', word_class: 'V' },
    { word: 'curdled', word_class: 'AJ' },
    { word: 'curdling', word_class: 'N' },
    { word: 'curdling', word_class: 'AJ' }
  ],
  principality: [
    { word: 'principe', word_class: 'N' },
    { word: 'principal', word_class: 'N' },
    { word: 'principal', word_class: 'AJ' },
    { word: 'principality', word_class: 'N' },
    { word: 'principally', word_class: 'AV' }
  ],
  enormously: [
    { word: 'enormity', word_class: 'N' },
    { word: 'enormous', word_class: 'AJ' },
    { word: 'enormously', word_class: 'AV' },
    { word: 'enormousness', word_class: 'N' }
  ],
  'co-opt': [
    { word: 'coopt', word_class: 'V' },
    { word: 'co-opt', word_class: 'V' },
    { word: 'co-option', word_class: 'N' },
    { word: 'co-optation', word_class: 'N' }
  ],
  statutory: [
    { word: 'statute', word_class: 'N' },
    { word: 'statute', word_class: 'AJ' },
    { word: 'statutory', word_class: 'AJ' }
  ],
  finger: [
    { word: 'finger', word_class: 'N' },
    { word: 'finger', word_class: 'V' },
    { word: 'fingered', word_class: 'AJ' },
    { word: 'fingering', word_class: 'N' }
  ],
  forty: [
    { word: 'forty', word_class: 'N' },
    { word: 'forty', word_class: 'AJ' },
    { word: 'forties', word_class: 'N' }
  ],
  reproved: [
    { word: 'reprove', word_class: 'V' },
    { word: 'reproval', word_class: 'N' },
    { word: 'reproved', word_class: 'AJ' },
    { word: 'reproving', word_class: 'AJ' }
  ],
  nictitate: [
    { word: 'nictate', word_class: 'V' },
    { word: 'nictation', word_class: 'N' },
    { word: 'nictitate', word_class: 'V' },
    { word: 'nictitation', word_class: 'N' }
  ],
  superintendent: [
    { word: 'superintend', word_class: 'V' },
    { word: 'superintendent', word_class: 'N' },
    { word: 'superintendence', word_class: 'N' },
    { word: 'superintendent', word_class: 'AJ' }
  ],
  incriminating: [
    { word: 'incriminate', word_class: 'V' },
    { word: 'incrimination', word_class: 'N' },
    { word: 'incriminating', word_class: 'AJ' }
  ],
  journeyman: [
    { word: 'journey', word_class: 'N' },
    { word: 'journey', word_class: 'V' },
    { word: 'journeyer', word_class: 'N' },
    { word: 'journeying', word_class: 'N' },
    { word: 'journeyman', word_class: 'N' }
  ],
  proximal: [
    { word: 'proximal', word_class: 'AJ' },
    { word: 'proximity', word_class: 'N' },
    { word: 'proximate', word_class: 'AJ' }
  ],
  expired: [
    { word: 'expire', word_class: 'V' },
    { word: 'expiry', word_class: 'N' },
    { word: 'expired', word_class: 'AJ' },
    { word: 'expiring', word_class: 'AJ' },
    { word: 'expiration', word_class: 'N' }
  ],
  confide: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  inspiring: [
    { word: 'inspire', word_class: 'N' },
    { word: 'inspire', word_class: 'V' },
    { word: 'inspired', word_class: 'AJ' },
    { word: 'inspiring', word_class: 'AJ' },
    { word: 'inspiration', word_class: 'N' },
    { word: 'inspirational', word_class: 'AJ' }
  ],
  crystalization: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  monstrosity: [
    { word: 'monster', word_class: 'N' },
    { word: 'monstrous', word_class: 'AJ' },
    { word: 'monstrosity', word_class: 'N' }
  ],
  crocked: [
    { word: 'crock', word_class: 'N' },
    { word: 'crock', word_class: 'V' },
    { word: 'crocked', word_class: 'AJ' }
  ],
  habitation: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  devotion: [
    { word: 'devote', word_class: 'V' },
    { word: 'devoted', word_class: 'AJ' },
    { word: 'devotion', word_class: 'N' },
    { word: 'devotement', word_class: 'N' },
    { word: 'devotional', word_class: 'AJ' }
  ],
  testy: [
    { word: 'testis', word_class: 'N' },
    { word: 'testy', word_class: 'AJ' },
    { word: 'testiness', word_class: 'N' }
  ],
  realized: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  interrogatively: [
    { word: 'interrogate', word_class: 'V' },
    { word: 'interrogator', word_class: 'N' },
    { word: 'interrogation', word_class: 'N' },
    { word: 'interrogative', word_class: 'N' },
    { word: 'interrogative', word_class: 'AJ' },
    { word: 'interrogatively', word_class: 'AV' }
  ],
  speculatively: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  sedulity: [
    { word: 'sedulity', word_class: 'N' },
    { word: 'sedulous', word_class: 'AJ' },
    { word: 'sedulously', word_class: 'AV' },
    { word: 'sedulousness', word_class: 'N' }
  ],
  dweller: [
    { word: 'dwell', word_class: 'V' },
    { word: 'dweller', word_class: 'N' },
    { word: 'dwelling', word_class: 'N' }
  ],
  interpret: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  disappearing: [
    { word: 'disappear', word_class: 'V' },
    { word: 'disappearing', word_class: 'N' },
    { word: 'disappearance', word_class: 'N' },
    { word: 'disappearing', word_class: 'AJ' }
  ],
  sucking: [
    { word: 'suck', word_class: 'N' },
    { word: 'suck', word_class: 'V' },
    { word: 'sucker', word_class: 'N' },
    { word: 'sucking', word_class: 'N' },
    { word: 'suction', word_class: 'N' },
    { word: 'suction', word_class: 'V' }
  ],
  crowning: [
    { word: 'crown', word_class: 'N' },
    { word: 'crown', word_class: 'V' },
    { word: 'crowned', word_class: 'AJ' },
    { word: 'crowning', word_class: 'AJ' }
  ],
  vamp: [{ word: 'vamp', word_class: 'N' }, { word: 'vamp', word_class: 'V' }, { word: 'vamper', word_class: 'N' }],
  symbolizing: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  scintillating: [
    { word: 'scintillate', word_class: 'V' },
    { word: 'scintillant', word_class: 'AJ' },
    { word: 'scintillation', word_class: 'N' },
    { word: 'scintillating', word_class: 'AJ' }
  ],
  allocation: [
    { word: 'allocate', word_class: 'V' },
    { word: 'allocable', word_class: 'AJ' },
    { word: 'allocation', word_class: 'N' }
  ],
  fluster: [
    { word: 'fluster', word_class: 'N' },
    { word: 'fluster', word_class: 'V' },
    { word: 'flustered', word_class: 'AJ' }
  ],
  transparence: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  unsupportable: [
    { word: 'unsupported', word_class: 'AJ' },
    { word: 'unsupportive', word_class: 'AJ' },
    { word: 'unsupportable', word_class: 'AJ' }
  ],
  intersecting: [
    { word: 'intersect', word_class: 'N' },
    { word: 'intersect', word_class: 'V' },
    { word: 'intersection', word_class: 'N' },
    { word: 'intersectant', word_class: 'AJ' },
    { word: 'intersecting', word_class: 'AJ' }
  ],
  drawler: [
    { word: 'drawl', word_class: 'N' },
    { word: 'drawl', word_class: 'V' },
    { word: 'drawler', word_class: 'N' },
    { word: 'drawling', word_class: 'AJ' }
  ],
  rhapsodic: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  suspicious: [
    { word: 'suspicious', word_class: 'AJ' },
    { word: 'suspiciously', word_class: 'AV' },
    { word: 'suspiciousness', word_class: 'N' }
  ],
  sedulousness: [
    { word: 'sedulity', word_class: 'N' },
    { word: 'sedulous', word_class: 'AJ' },
    { word: 'sedulously', word_class: 'AV' },
    { word: 'sedulousness', word_class: 'N' }
  ],
  distributer: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  trivially: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  compensable: [
    { word: 'compensate', word_class: 'V' },
    { word: 'compensable', word_class: 'AJ' },
    { word: 'compensated', word_class: 'AJ' },
    { word: 'compensation', word_class: 'N' },
    { word: 'compensative', word_class: 'AJ' }
  ],
  protection: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  buggy: [
    { word: 'buggy', word_class: 'N' },
    { word: 'buggy', word_class: 'AJ' },
    { word: 'bugginess', word_class: 'N' }
  ],
  twister: [
    { word: 'twist', word_class: 'N' },
    { word: 'twist', word_class: 'V' },
    { word: 'twister', word_class: 'N' },
    { word: 'twisted', word_class: 'AJ' },
    { word: 'twisting', word_class: 'N' },
    { word: 'twisting', word_class: 'AJ' }
  ],
  prodigy: [
    { word: 'prodigy', word_class: 'N' },
    { word: 'prodigious', word_class: 'AJ' },
    { word: 'prodigiously', word_class: 'AV' }
  ],
  hypertrophy: [
    { word: 'hypertrophy', word_class: 'N' },
    { word: 'hypertrophy', word_class: 'V' },
    { word: 'hypertrophied', word_class: 'AJ' }
  ],
  absorbed: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  typical: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  appreciatively: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  mellow: [
    { word: 'mellow', word_class: 'V' },
    { word: 'mellow', word_class: 'AJ' },
    { word: 'mellow', word_class: 'AV' },
    { word: 'mellowed', word_class: 'AJ' },
    { word: 'mellowing', word_class: 'N' },
    { word: 'mellowing', word_class: 'AJ' },
    { word: 'mellowness', word_class: 'N' }
  ],
  sneeze: [
    { word: 'sneeze', word_class: 'N' },
    { word: 'sneeze', word_class: 'V' },
    { word: 'sneezy', word_class: 'AJ' },
    { word: 'sneezing', word_class: 'N' }
  ],
  prospect: [
    { word: 'prospect', word_class: 'N' },
    { word: 'prospect', word_class: 'V' },
    { word: 'prospector', word_class: 'N' },
    { word: 'prospective', word_class: 'AJ' }
  ],
  encapsulation: [
    { word: 'encapsulate', word_class: 'V' },
    { word: 'incapsulate', word_class: 'V' },
    { word: 'encapsulation', word_class: 'N' },
    { word: 'incapsulation', word_class: 'N' }
  ],
  festering: [
    { word: 'fester', word_class: 'N' },
    { word: 'fester', word_class: 'V' },
    { word: 'festering', word_class: 'N' },
    { word: 'festering', word_class: 'AJ' }
  ],
  neglected: [
    { word: 'neglect', word_class: 'N' },
    { word: 'neglect', word_class: 'V' },
    { word: 'neglected', word_class: 'AJ' },
    { word: 'neglectful', word_class: 'AJ' },
    { word: 'neglectfulness', word_class: 'N' }
  ],
  blow: [
    { word: 'blow', word_class: 'N' },
    { word: 'blow', word_class: 'V' },
    { word: 'blower', word_class: 'N' },
    { word: 'blown', word_class: 'AJ' },
    { word: 'blowing', word_class: 'N' }
  ],
  keenness: [
    { word: 'keen', word_class: 'N' },
    { word: 'keen', word_class: 'V' },
    { word: 'keen', word_class: 'AJ' },
    { word: 'keenness', word_class: 'N' }
  ],
  phonetically: [
    { word: 'phonetic', word_class: 'AJ' },
    { word: 'phonetics', word_class: 'N' },
    { word: 'phonetically', word_class: 'AV' }
  ],
  severity: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  squall: [
    { word: 'squall', word_class: 'N' },
    { word: 'squall', word_class: 'V' },
    { word: 'squalling', word_class: 'AJ' }
  ],
  exhilarated: [
    { word: 'exhilarate', word_class: 'V' },
    { word: 'exhilarated', word_class: 'AJ' },
    { word: 'exhilaration', word_class: 'N' },
    { word: 'exhilarating', word_class: 'AJ' }
  ],
  supporting: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  withdrawnness: [
    { word: 'withdraw', word_class: 'V' },
    { word: 'withdrawal', word_class: 'N' },
    { word: 'withdrawn', word_class: 'AJ' },
    { word: 'withdrawing', word_class: 'AJ' },
    { word: 'withdrawnness', word_class: 'N' }
  ],
  rat: [{ word: 'rat', word_class: 'N' }, { word: 'rat', word_class: 'V' }, { word: 'ratting', word_class: 'N' }],
  show: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  vermiculate: [
    { word: 'vermiculate', word_class: 'V' },
    { word: 'vermiculate', word_class: 'AJ' },
    { word: 'vermiculated', word_class: 'AJ' },
    { word: 'vermiculation', word_class: 'N' }
  ],
  providing: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  following: [
    { word: 'follow', word_class: 'V' },
    { word: 'follower', word_class: 'N' },
    { word: 'followers', word_class: 'N' },
    { word: 'following', word_class: 'N' },
    { word: 'following', word_class: 'AJ' }
  ],
  untangle: [
    { word: 'untangle', word_class: 'V' },
    { word: 'untangled', word_class: 'AJ' },
    { word: 'untangling', word_class: 'N' }
  ],
  favorable: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  resinous: [
    { word: 'resin', word_class: 'N' },
    { word: 'resin', word_class: 'V' },
    { word: 'resinate', word_class: 'V' },
    { word: 'resinous', word_class: 'AJ' },
    { word: 'resinated', word_class: 'AJ' },
    { word: 'resination', word_class: 'N' }
  ],
  invalidated: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  disabused: [
    { word: 'disabuse', word_class: 'N' },
    { word: 'disabuse', word_class: 'V' },
    { word: 'disabusal', word_class: 'N' },
    { word: 'disabused', word_class: 'AJ' }
  ],
  aristotelianism: [
    { word: 'aristotelian', word_class: 'N' },
    { word: 'aristotelian', word_class: 'AJ' },
    { word: 'aristotelianism', word_class: 'N' }
  ],
  impoundment: [
    { word: 'impound', word_class: 'N' },
    { word: 'impound', word_class: 'V' },
    { word: 'impounding', word_class: 'N' },
    { word: 'impoundment', word_class: 'N' }
  ],
  tying: [
    { word: 'tie', word_class: 'N' },
    { word: 'tie', word_class: 'V' },
    { word: 'tier', word_class: 'N' },
    { word: 'tying', word_class: 'N' },
    { word: 'tying', word_class: 'V' }
  ],
  slacks: [
    { word: 'slack', word_class: 'N' },
    { word: 'slack', word_class: 'V' },
    { word: 'slack', word_class: 'AJ' },
    { word: 'slacks', word_class: 'N' },
    { word: 'slacker', word_class: 'N' },
    { word: 'slacking', word_class: 'N' },
    { word: 'slackness', word_class: 'N' }
  ],
  revocability: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  escapade: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  dissolute: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  carburize: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  inebriation: [
    { word: 'inebriant', word_class: 'N' },
    { word: 'inebriate', word_class: 'N' },
    { word: 'inebriate', word_class: 'V' },
    { word: 'inebriated', word_class: 'AJ' },
    { word: 'inebriation', word_class: 'N' }
  ],
  complying: [
    { word: 'comply', word_class: 'V' },
    { word: 'compliance', word_class: 'N' },
    { word: 'compliancy', word_class: 'N' },
    { word: 'complying', word_class: 'AJ' }
  ],
  laurels: [
    { word: 'laurel', word_class: 'N' },
    { word: 'laurels', word_class: 'N' },
    { word: 'laureled', word_class: 'AJ' },
    { word: 'laurelled', word_class: 'AJ' }
  ],
  wildcatting: [
    { word: 'wildcat', word_class: 'N' },
    { word: 'wildcat', word_class: 'V' },
    { word: 'wildcat', word_class: 'AJ' },
    { word: 'wildcatting', word_class: 'N' }
  ],
  liberalness: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  sensitizing: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  hollow: [
    { word: 'hollow', word_class: 'N' },
    { word: 'hollow', word_class: 'V' },
    { word: 'hollow', word_class: 'AJ' },
    { word: 'hollowed', word_class: 'AJ' },
    { word: 'hollowness', word_class: 'N' }
  ],
  phosphorous: [
    { word: 'phosphor', word_class: 'N' },
    { word: 'phosphorus', word_class: 'N' },
    { word: 'phosphoric', word_class: 'AJ' },
    { word: 'phosphorous', word_class: 'AJ' }
  ],
  enatic: [
    { word: 'enate', word_class: 'N' },
    { word: 'enate', word_class: 'AJ' },
    { word: 'enatic', word_class: 'AJ' },
    { word: 'enation', word_class: 'N' }
  ],
  effusive: [
    { word: 'effuse', word_class: 'V' },
    { word: 'effusion', word_class: 'N' },
    { word: 'effusive', word_class: 'AJ' },
    { word: 'effusively', word_class: 'AV' },
    { word: 'effusiveness', word_class: 'N' }
  ],
  imaginary: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  brushed: [
    { word: 'brush', word_class: 'N' },
    { word: 'brush', word_class: 'V' },
    { word: 'brushed', word_class: 'AJ' },
    { word: 'brushing', word_class: 'N' }
  ],
  eggs: [{ word: 'egg', word_class: 'N' }, { word: 'egg', word_class: 'V' }, { word: 'eggs', word_class: 'N' }],
  subsequentness: [
    { word: 'subsequence', word_class: 'N' },
    { word: 'subsequent', word_class: 'AJ' },
    { word: 'subsequently', word_class: 'AV' },
    { word: 'subsequentness', word_class: 'N' }
  ],
  floccule: [
    { word: 'floccule', word_class: 'N' },
    { word: 'flocculate', word_class: 'V' },
    { word: 'flocculent', word_class: 'AJ' },
    { word: 'flocculation', word_class: 'N' }
  ],
  invariability: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  isolation: [
    { word: 'isolate', word_class: 'V' },
    { word: 'isolable', word_class: 'AJ' },
    { word: 'isolated', word_class: 'AJ' },
    { word: 'isolation', word_class: 'N' },
    { word: 'isolating', word_class: 'AJ' }
  ],
  martyrisation: [
    { word: 'martyr', word_class: 'N' },
    { word: 'martyr', word_class: 'V' },
    { word: 'martyrize', word_class: 'V' },
    { word: 'martyrisation', word_class: 'N' },
    { word: 'martyrization', word_class: 'N' }
  ],
  militation: [
    { word: 'militant', word_class: 'N' },
    { word: 'militate', word_class: 'V' },
    { word: 'militance', word_class: 'N' },
    { word: 'militancy', word_class: 'N' },
    { word: 'militant', word_class: 'AJ' },
    { word: 'militation', word_class: 'N' }
  ],
  spanker: [
    { word: 'spank', word_class: 'N' },
    { word: 'spank', word_class: 'V' },
    { word: 'spanker', word_class: 'N' },
    { word: 'spanking', word_class: 'N' },
    { word: 'spanking', word_class: 'AJ' }
  ],
  repulse: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  concealing: [
    { word: 'conceal', word_class: 'V' },
    { word: 'concealed', word_class: 'AJ' },
    { word: 'concealing', word_class: 'N' },
    { word: 'concealing', word_class: 'AJ' },
    { word: 'concealment', word_class: 'N' }
  ],
  angler: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  toleration: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  advertence: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  unmovable: [
    { word: 'unmoved', word_class: 'AJ' },
    { word: 'unmoving', word_class: 'AJ' },
    { word: 'unmovable', word_class: 'AJ' }
  ],
  ridiculously: [
    { word: 'ridicule', word_class: 'N' },
    { word: 'ridicule', word_class: 'V' },
    { word: 'ridiculous', word_class: 'AJ' },
    { word: 'ridiculously', word_class: 'AV' },
    { word: 'ridiculousness', word_class: 'N' }
  ],
  scots: [{ word: 'scot', word_class: 'N' }, { word: 'scots', word_class: 'N' }, { word: 'scots', word_class: 'AJ' }],
  humanist: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  liter: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  magniloquent: [
    { word: 'magniloquence', word_class: 'N' },
    { word: 'magniloquent', word_class: 'AJ' },
    { word: 'magniloquently', word_class: 'AV' }
  ],
  sculler: [
    { word: 'scull', word_class: 'N' },
    { word: 'scull', word_class: 'V' },
    { word: 'sculler', word_class: 'N' },
    { word: 'sculling', word_class: 'N' },
    { word: 'scullion', word_class: 'N' }
  ],
  ramman: [
    { word: 'ram', word_class: 'N' },
    { word: 'ram', word_class: 'V' },
    { word: 'ramee', word_class: 'N' },
    { word: 'ramman', word_class: 'N' }
  ],
  glamorization: [
    { word: 'glamor', word_class: 'N' },
    { word: 'glamorize', word_class: 'V' },
    { word: 'glamorous', word_class: 'AJ' },
    { word: 'glamorisation', word_class: 'N' },
    { word: 'glamorization', word_class: 'N' }
  ],
  precipitator: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  small: [
    { word: 'small', word_class: 'N' },
    { word: 'small', word_class: 'AJ' },
    { word: 'small', word_class: 'AV' },
    { word: 'smallness', word_class: 'N' }
  ],
  villainous: [
    { word: 'villain', word_class: 'N' },
    { word: 'villainous', word_class: 'AJ' },
    { word: 'villainousness', word_class: 'N' }
  ],
  separateness: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  assistive: [
    { word: 'assist', word_class: 'N' },
    { word: 'assist', word_class: 'V' },
    { word: 'assistant', word_class: 'N' },
    { word: 'assisted', word_class: 'AJ' },
    { word: 'assistance', word_class: 'N' },
    { word: 'assistant', word_class: 'AJ' },
    { word: 'assistive', word_class: 'AJ' }
  ],
  diamagnetic: [
    { word: 'diamagnet', word_class: 'N' },
    { word: 'diamagnetic', word_class: 'AJ' },
    { word: 'diamagnetism', word_class: 'N' }
  ],
  muhammadanism: [
    { word: 'muhammadan', word_class: 'N' },
    { word: 'muhammadan', word_class: 'AJ' },
    { word: 'muhammadanism', word_class: 'N' }
  ],
  formalism: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  organize: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  thriven: [
    { word: 'thrive', word_class: 'V' },
    { word: 'thriven', word_class: 'AJ' },
    { word: 'thriving', word_class: 'AJ' }
  ],
  acceptant: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  concretion: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  threaten: [
    { word: 'threat', word_class: 'N' },
    { word: 'threaten', word_class: 'V' },
    { word: 'threatened', word_class: 'AJ' },
    { word: 'threatening', word_class: 'AJ' }
  ],
  wrinkle: [
    { word: 'wrinkle', word_class: 'N' },
    { word: 'wrinkle', word_class: 'V' },
    { word: 'wrinkled', word_class: 'AJ' }
  ],
  fisher: [
    { word: 'fish', word_class: 'N' },
    { word: 'fish', word_class: 'V' },
    { word: 'fisher', word_class: 'N' },
    { word: 'fishery', word_class: 'N' },
    { word: 'fishing', word_class: 'N' },
    { word: 'fishery', word_class: 'AJ' }
  ],
  tormented: [
    { word: 'torment', word_class: 'N' },
    { word: 'torment', word_class: 'V' },
    { word: 'tormenter', word_class: 'N' },
    { word: 'tormentor', word_class: 'N' },
    { word: 'tormented', word_class: 'AJ' }
  ],
  pectization: [
    { word: 'pectise', word_class: 'V' },
    { word: 'pectize', word_class: 'V' },
    { word: 'pectisation', word_class: 'N' },
    { word: 'pectization', word_class: 'N' }
  ],
  young: [
    { word: 'young', word_class: 'N' },
    { word: 'young', word_class: 'AJ' },
    { word: 'youngness', word_class: 'N' }
  ],
  celibacy: [
    { word: 'celibacy', word_class: 'N' },
    { word: 'celibate', word_class: 'N' },
    { word: 'celibate', word_class: 'AJ' }
  ],
  innovation: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  exorbitance: [
    { word: 'exorbitant', word_class: 'N' },
    { word: 'exorbitance', word_class: 'N' },
    { word: 'exorbitant', word_class: 'AJ' }
  ],
  windward: [
    { word: 'windward', word_class: 'N' },
    { word: 'windward', word_class: 'AJ' },
    { word: 'windward', word_class: 'AV' }
  ],
  applier: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  epidemiological: [
    { word: 'epidemiology', word_class: 'N' },
    { word: 'epidemiologic', word_class: 'AJ' },
    { word: 'epidemiological', word_class: 'AJ' }
  ],
  venting: [
    { word: 'vent', word_class: 'N' },
    { word: 'vent', word_class: 'V' },
    { word: 'venter', word_class: 'N' },
    { word: 'vented', word_class: 'AJ' },
    { word: 'venting', word_class: 'N' }
  ],
  meriting: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  seizer: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  burned: [
    { word: 'burn', word_class: 'N' },
    { word: 'burn', word_class: 'V' },
    { word: 'burner', word_class: 'N' },
    { word: 'burned', word_class: 'AJ' },
    { word: 'burning', word_class: 'N' },
    { word: 'burning', word_class: 'AJ' }
  ],
  fretfulness: [
    { word: 'fret', word_class: 'N' },
    { word: 'fret', word_class: 'V' },
    { word: 'fretful', word_class: 'AJ' },
    { word: 'fretted', word_class: 'AJ' },
    { word: 'fretfulness', word_class: 'N' }
  ],
  sensitized: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  disgruntle: [
    { word: 'disgruntle', word_class: 'V' },
    { word: 'disgruntled', word_class: 'AJ' },
    { word: 'disgruntlement', word_class: 'N' }
  ],
  briefing: [
    { word: 'brief', word_class: 'N' },
    { word: 'brief', word_class: 'V' },
    { word: 'brief', word_class: 'AJ' },
    { word: 'briefs', word_class: 'N' },
    { word: 'brevity', word_class: 'N' },
    { word: 'briefing', word_class: 'N' },
    { word: 'briefness', word_class: 'N' }
  ],
  sharp: [
    { word: 'sharp', word_class: 'N' },
    { word: 'sharp', word_class: 'AJ' },
    { word: 'sharp', word_class: 'AV' },
    { word: 'sharpness', word_class: 'N' }
  ],
  shouting: [
    { word: 'shout', word_class: 'N' },
    { word: 'shout', word_class: 'V' },
    { word: 'shouter', word_class: 'N' },
    { word: 'shouted', word_class: 'AJ' },
    { word: 'shouting', word_class: 'N' },
    { word: 'shouting', word_class: 'AJ' }
  ],
  wholeness: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  counting: [
    { word: 'count', word_class: 'N' },
    { word: 'count', word_class: 'V' },
    { word: 'counter', word_class: 'N' },
    { word: 'counter', word_class: 'V' },
    { word: 'counter', word_class: 'AV' },
    { word: 'counter', word_class: 'AJ' },
    { word: 'counting', word_class: 'N' },
    { word: 'counterman', word_class: 'N' }
  ],
  discriminate: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  autobiographical: [
    { word: 'autobiographer', word_class: 'N' },
    { word: 'autobiographic', word_class: 'AJ' },
    { word: 'autobiographical', word_class: 'AJ' }
  ],
  tittivation: [
    { word: 'titivate', word_class: 'V' },
    { word: 'tittivate', word_class: 'V' },
    { word: 'titivation', word_class: 'N' },
    { word: 'tittivation', word_class: 'N' }
  ],
  authentication: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  semitropical: [
    { word: 'semitropic', word_class: 'AJ' },
    { word: 'semitropics', word_class: 'N' },
    { word: 'semitropical', word_class: 'AJ' }
  ],
  tightened: [
    { word: 'tighten', word_class: 'V' },
    { word: 'tightened', word_class: 'AJ' },
    { word: 'tightening', word_class: 'N' }
  ],
  coddle: [
    { word: 'coddle', word_class: 'V' },
    { word: 'codling', word_class: 'N' },
    { word: 'coddled', word_class: 'AJ' }
  ],
  sink: [
    { word: 'sink', word_class: 'N' },
    { word: 'sink', word_class: 'V' },
    { word: 'sinker', word_class: 'N' },
    { word: 'sinkage', word_class: 'N' },
    { word: 'sinking', word_class: 'N' },
    { word: 'sunken', word_class: 'AJ' }
  ],
  scampering: [
    { word: 'scamper', word_class: 'N' },
    { word: 'scamper', word_class: 'V' },
    { word: 'scampering', word_class: 'AJ' }
  ],
  transferrable: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  coup: [
    { word: 'coup', word_class: 'N' },
    { word: 'coup', word_class: 'V' },
    { word: 'coup', word_class: 'AJ' },
    { word: 'coupe', word_class: 'N' }
  ],
  causal: [
    { word: 'causal', word_class: 'AJ' },
    { word: 'causality', word_class: 'N' },
    { word: 'causally', word_class: 'AV' }
  ],
  undertaker: [
    { word: 'undertake', word_class: 'V' },
    { word: 'undertaker', word_class: 'N' },
    { word: 'undertaking', word_class: 'N' }
  ],
  writings: [
    { word: 'write', word_class: 'V' },
    { word: 'writer', word_class: 'N' },
    { word: 'writing', word_class: 'N' },
    { word: 'writings', word_class: 'N' },
    { word: 'written', word_class: 'AJ' }
  ],
  feather: [
    { word: 'feather', word_class: 'N' },
    { word: 'feather', word_class: 'V' },
    { word: 'feathered', word_class: 'AJ' },
    { word: 'feathering', word_class: 'N' }
  ],
  associableness: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  atmosphere: [
    { word: 'atmosphere', word_class: 'N' },
    { word: 'atmospheric', word_class: 'AJ' },
    { word: 'atmospherics', word_class: 'N' },
    { word: 'atmospherical', word_class: 'AJ' }
  ],
  collectivized: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  syllabize: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  inexpensively: [
    { word: 'inexpensive', word_class: 'AJ' },
    { word: 'inexpensively', word_class: 'AV' },
    { word: 'inexpensiveness', word_class: 'N' }
  ],
  immunology: [
    { word: 'immunology', word_class: 'N' },
    { word: 'immunologic', word_class: 'AJ' },
    { word: 'immunological', word_class: 'AJ' }
  ],
  laicization: [
    { word: 'laicise', word_class: 'V' },
    { word: 'laicize', word_class: 'V' },
    { word: 'laicisation', word_class: 'N' },
    { word: 'laicization', word_class: 'N' }
  ],
  dainty: [
    { word: 'dainty', word_class: 'N' },
    { word: 'dainty', word_class: 'AJ' },
    { word: 'daintiness', word_class: 'N' }
  ],
  axiomatically: [
    { word: 'axiom', word_class: 'N' },
    { word: 'axiomatic', word_class: 'AJ' },
    { word: 'axiomatical', word_class: 'AJ' },
    { word: 'axiomatically', word_class: 'AV' }
  ],
  quiescency: [
    { word: 'quiescence', word_class: 'N' },
    { word: 'quiescency', word_class: 'N' },
    { word: 'quiescent', word_class: 'AJ' }
  ],
  collect: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  gentle: [
    { word: 'gentle', word_class: 'V' },
    { word: 'gentle', word_class: 'AJ' },
    { word: 'gentleness', word_class: 'N' }
  ],
  catholicize: [
    { word: 'catholicise', word_class: 'V' },
    { word: 'catholicism', word_class: 'N' },
    { word: 'catholicize', word_class: 'V' },
    { word: 'catholicisation', word_class: 'N' },
    { word: 'catholicization', word_class: 'N' }
  ],
  differentiated: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  scrupulousness: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  tai: [{ word: 'tai', word_class: 'N' }, { word: 'tay', word_class: 'N' }, { word: 'tai', word_class: 'AJ' }],
  gaiety: [
    { word: 'gay', word_class: 'N' },
    { word: 'gay', word_class: 'AJ' },
    { word: 'gaiety', word_class: 'N' },
    { word: 'gaily', word_class: 'AV' }
  ],
  glasswork: [
    { word: 'glasswork', word_class: 'N' },
    { word: 'glassworks', word_class: 'N' },
    { word: 'glassworker', word_class: 'N' }
  ],
  gall: [
    { word: 'gall', word_class: 'V' },
    { word: 'gall', word_class: 'N' },
    { word: 'gallant', word_class: 'N' },
    { word: 'gallant', word_class: 'V' },
    { word: 'galled', word_class: 'AJ' },
    { word: 'gallant', word_class: 'AJ' },
    { word: 'galling', word_class: 'AJ' },
    { word: 'gallantry', word_class: 'N' }
  ],
  spite: [
    { word: 'spite', word_class: 'N' },
    { word: 'spite', word_class: 'V' },
    { word: 'spiteful', word_class: 'AJ' },
    { word: 'spitefulness', word_class: 'N' }
  ],
  rimed: [
    { word: 'rime', word_class: 'N' },
    { word: 'rime', word_class: 'V' },
    { word: 'rimy', word_class: 'AJ' },
    { word: 'rimed', word_class: 'AJ' },
    { word: 'riming', word_class: 'AJ' }
  ],
  alga: [{ word: 'alga', word_class: 'N' }, { word: 'algae', word_class: 'N' }, { word: 'algal', word_class: 'AJ' }],
  anticoagulative: [
    { word: 'anticoagulant', word_class: 'N' },
    { word: 'anticoagulation', word_class: 'N' },
    { word: 'anticoagulative', word_class: 'AJ' }
  ],
  carved: [
    { word: 'carve', word_class: 'V' },
    { word: 'carver', word_class: 'N' },
    { word: 'carved', word_class: 'AJ' },
    { word: 'carving', word_class: 'N' }
  ],
  horror: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  provisionally: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  trustful: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  attestant: [
    { word: 'attest', word_class: 'V' },
    { word: 'attestant', word_class: 'N' },
    { word: 'attested', word_class: 'AJ' },
    { word: 'attestation', word_class: 'N' }
  ],
  conveniently: [
    { word: 'convenience', word_class: 'N' },
    { word: 'convenience', word_class: 'V' },
    { word: 'convenient', word_class: 'AJ' },
    { word: 'conveniences', word_class: 'N' },
    { word: 'conveniently', word_class: 'AV' }
  ],
  puzzle: [
    { word: 'puzzle', word_class: 'N' },
    { word: 'puzzle', word_class: 'V' },
    { word: 'puzzler', word_class: 'N' },
    { word: 'puzzled', word_class: 'AJ' },
    { word: 'puzzling', word_class: 'AJ' },
    { word: 'puzzlement', word_class: 'N' }
  ],
  beneficiary: [
    { word: 'benefice', word_class: 'N' },
    { word: 'benefice', word_class: 'V' },
    { word: 'beneficence', word_class: 'N' },
    { word: 'beneficent', word_class: 'AJ' },
    { word: 'beneficiary', word_class: 'N' },
    { word: 'beneficiary', word_class: 'AJ' }
  ],
  adaptable: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  reserves: [
    { word: 'reserve', word_class: 'N' },
    { word: 'reserve', word_class: 'V' },
    { word: 'reserve', word_class: 'AJ' },
    { word: 'reserves', word_class: 'N' },
    { word: 'reserved', word_class: 'AJ' },
    { word: 'reservist', word_class: 'N' },
    { word: 'reservation', word_class: 'N' }
  ],
  oversubscribed: [
    { word: 'oversubscribe', word_class: 'V' },
    { word: 'oversubscribed', word_class: 'AJ' },
    { word: 'oversubscription', word_class: 'N' }
  ],
  chambered: [
    { word: 'chamber', word_class: 'N' },
    { word: 'chamber', word_class: 'V' },
    { word: 'chambered', word_class: 'AJ' }
  ],
  coloring: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  discreditably: [
    { word: 'discredit', word_class: 'N' },
    { word: 'discredit', word_class: 'V' },
    { word: 'discredited', word_class: 'AJ' },
    { word: 'discreditable', word_class: 'AJ' },
    { word: 'discreditably', word_class: 'AV' }
  ],
  disparateness: [
    { word: 'disparity', word_class: 'N' },
    { word: 'disparate', word_class: 'AJ' },
    { word: 'disparateness', word_class: 'N' }
  ],
  staining: [
    { word: 'stain', word_class: 'N' },
    { word: 'stain', word_class: 'V' },
    { word: 'stained', word_class: 'AJ' },
    { word: 'staining', word_class: 'N' }
  ],
  'dry-cleaned': [
    { word: 'dry-clean', word_class: 'V' },
    { word: 'dry-cleaned', word_class: 'AJ' },
    { word: 'dry-cleaning', word_class: 'N' }
  ],
  rely: [
    { word: 'rely', word_class: 'V' },
    { word: 'relie', word_class: 'V' },
    { word: 'reliant', word_class: 'N' },
    { word: 'reliance', word_class: 'N' },
    { word: 'reliant', word_class: 'AJ' }
  ],
  prong: [
    { word: 'prong', word_class: 'N' },
    { word: 'prong', word_class: 'V' },
    { word: 'pronged', word_class: 'AJ' }
  ],
  strangeness: [
    { word: 'strange', word_class: 'AJ' },
    { word: 'strangely', word_class: 'AV' },
    { word: 'strangeness', word_class: 'N' }
  ],
  aerator: [
    { word: 'aerate', word_class: 'V' },
    { word: 'aerator', word_class: 'N' },
    { word: 'aerated', word_class: 'AJ' },
    { word: 'aeration', word_class: 'N' }
  ],
  functional: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  mazement: [
    { word: 'maze', word_class: 'N' },
    { word: 'maze', word_class: 'V' },
    { word: 'mazed', word_class: 'AJ' },
    { word: 'mazement', word_class: 'N' }
  ],
  affrication: [
    { word: 'affricate', word_class: 'N' },
    { word: 'affrication', word_class: 'N' },
    { word: 'affricative', word_class: 'N' }
  ],
  personal: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  tableful: [
    { word: 'table', word_class: 'N' },
    { word: 'table', word_class: 'V' },
    { word: 'tableful', word_class: 'N' },
    { word: 'tabular', word_class: 'AJ' },
    { word: 'tableful', word_class: 'AJ' },
    { word: 'tabularize', word_class: 'V' }
  ],
  diffusing: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  legation: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  scintillant: [
    { word: 'scintillate', word_class: 'V' },
    { word: 'scintillant', word_class: 'AJ' },
    { word: 'scintillation', word_class: 'N' },
    { word: 'scintillating', word_class: 'AJ' }
  ],
  renunciation: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  recountal: [
    { word: 'recount', word_class: 'N' },
    { word: 'recount', word_class: 'V' },
    { word: 'recountal', word_class: 'N' },
    { word: 'recounting', word_class: 'N' }
  ],
  exterminate: [
    { word: 'exterminate', word_class: 'V' },
    { word: 'exterminator', word_class: 'N' },
    { word: 'exterminable', word_class: 'AJ' },
    { word: 'exterminated', word_class: 'AJ' },
    { word: 'extermination', word_class: 'N' }
  ],
  burnish: [
    { word: 'burnish', word_class: 'N' },
    { word: 'burnish', word_class: 'V' },
    { word: 'burnish', word_class: 'AJ' },
    { word: 'burnished', word_class: 'AJ' }
  ],
  monochromous: [
    { word: 'monochrome', word_class: 'N' },
    { word: 'monochrome', word_class: 'AJ' },
    { word: 'monochromic', word_class: 'AJ' },
    { word: 'monochromous', word_class: 'AJ' }
  ],
  formularise: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  denote: [
    { word: 'denote', word_class: 'N' },
    { word: 'denote', word_class: 'V' },
    { word: 'denotive', word_class: 'AJ' },
    { word: 'denotation', word_class: 'N' },
    { word: 'denotement', word_class: 'N' },
    { word: 'denotative', word_class: 'AJ' }
  ],
  rusticate: [
    { word: 'rustic', word_class: 'N' },
    { word: 'rustic', word_class: 'AJ' },
    { word: 'rusticate', word_class: 'V' },
    { word: 'rusticity', word_class: 'N' },
    { word: 'rustication', word_class: 'N' }
  ],
  scatological: [
    { word: 'scatology', word_class: 'N' },
    { word: 'scatologic', word_class: 'AJ' },
    { word: 'scatological', word_class: 'AJ' }
  ],
  blustering: [
    { word: 'bluster', word_class: 'N' },
    { word: 'bluster', word_class: 'V' },
    { word: 'blusterer', word_class: 'N' },
    { word: 'blustering', word_class: 'AJ' },
    { word: 'blusterous', word_class: 'AJ' }
  ],
  moroccanize: [
    { word: 'moroccan', word_class: 'N' },
    { word: 'moroccan', word_class: 'AJ' },
    { word: 'moroccanize', word_class: 'V' }
  ],
  register: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  smouldering: [
    { word: 'smoulder', word_class: 'N' },
    { word: 'smoulder', word_class: 'V' },
    { word: 'smouldering', word_class: 'AJ' }
  ],
  derivate: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  restored: [
    { word: 'restore', word_class: 'N' },
    { word: 'restore', word_class: 'V' },
    { word: 'restorer', word_class: 'N' },
    { word: 'restored', word_class: 'AJ' },
    { word: 'restoration', word_class: 'N' },
    { word: 'restorative', word_class: 'N' },
    { word: 'restorative', word_class: 'AJ' }
  ],
  algal: [{ word: 'alga', word_class: 'N' }, { word: 'algae', word_class: 'N' }, { word: 'algal', word_class: 'AJ' }],
  thawed: [
    { word: 'thaw', word_class: 'N' },
    { word: 'thaw', word_class: 'V' },
    { word: 'thawed', word_class: 'AJ' },
    { word: 'thawing', word_class: 'N' }
  ],
  advanced: [
    { word: 'advance', word_class: 'N' },
    { word: 'advance', word_class: 'V' },
    { word: 'advance', word_class: 'AJ' },
    { word: 'advanced', word_class: 'AJ' },
    { word: 'advancing', word_class: 'AJ' },
    { word: 'advancement', word_class: 'N' }
  ],
  dynamiter: [
    { word: 'dynamite', word_class: 'N' },
    { word: 'dynamite', word_class: 'V' },
    { word: 'dynamiter', word_class: 'N' },
    { word: 'dynamitist', word_class: 'N' }
  ],
  medially: [
    { word: 'medial', word_class: 'N' },
    { word: 'medial', word_class: 'AJ' },
    { word: 'medially', word_class: 'AV' }
  ],
  stonewalling: [
    { word: 'stonewall', word_class: 'V' },
    { word: 'stonewaller', word_class: 'N' },
    { word: 'stonewalling', word_class: 'N' }
  ],
  synecdochic: [
    { word: 'synecdoche', word_class: 'N' },
    { word: 'synecdochic', word_class: 'AJ' },
    { word: 'synecdochical', word_class: 'AJ' }
  ],
  ruling: [
    { word: 'rule', word_class: 'N' },
    { word: 'rule', word_class: 'V' },
    { word: 'ruler', word_class: 'N' },
    { word: 'ruled', word_class: 'AJ' },
    { word: 'ruling', word_class: 'N' },
    { word: 'ruling', word_class: 'AJ' }
  ],
  toddler: [
    { word: 'toddle', word_class: 'N' },
    { word: 'toddle', word_class: 'V' },
    { word: 'toddler', word_class: 'N' }
  ],
  politics: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  incautiously: [
    { word: 'incautious', word_class: 'AJ' },
    { word: 'incautiously', word_class: 'AV' },
    { word: 'incautiousness', word_class: 'N' }
  ],
  eject: [
    { word: 'eject', word_class: 'V' },
    { word: 'ejector', word_class: 'N' },
    { word: 'ejection', word_class: 'N' }
  ],
  colouring: [
    { word: 'colour', word_class: 'N' },
    { word: 'colour', word_class: 'V' },
    { word: 'colour', word_class: 'AJ' },
    { word: 'colours', word_class: 'N' },
    { word: 'coloured', word_class: 'AJ' },
    { word: 'colouring', word_class: 'N' },
    { word: 'colourful', word_class: 'AJ' }
  ],
  consenting: [
    { word: 'consent', word_class: 'N' },
    { word: 'consent', word_class: 'V' },
    { word: 'consensus', word_class: 'N' },
    { word: 'consensual', word_class: 'AJ' },
    { word: 'consenting', word_class: 'AJ' }
  ],
  stigmatise: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  evanescent: [
    { word: 'evanesce', word_class: 'V' },
    { word: 'evanescence', word_class: 'N' },
    { word: 'evanescent', word_class: 'AJ' }
  ],
  symbolic: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  synchronization: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  hydraulic: [
    { word: 'hydraulic', word_class: 'AJ' },
    { word: 'hydraulics', word_class: 'N' },
    { word: 'hydraulically', word_class: 'AV' }
  ],
  intelligible: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  reflex: [
    { word: 'reflex', word_class: 'N' },
    { word: 'reflex', word_class: 'V' },
    { word: 'reflex', word_class: 'AJ' },
    { word: 'reflexive', word_class: 'N' },
    { word: 'reflexive', word_class: 'AJ' },
    { word: 'reflexivity', word_class: 'N' },
    { word: 'reflexiveness', word_class: 'N' }
  ],
  straight: [
    { word: 'straight', word_class: 'N' },
    { word: 'straight', word_class: 'AJ' },
    { word: 'straight', word_class: 'AV' },
    { word: 'straightness', word_class: 'N' }
  ],
  prelusion: [
    { word: 'prelude', word_class: 'N' },
    { word: 'prelude', word_class: 'V' },
    { word: 'prelusion', word_class: 'N' }
  ],
  gelded: [
    { word: 'geld', word_class: 'V' },
    { word: 'gelded', word_class: 'AJ' },
    { word: 'gelding', word_class: 'N' }
  ],
  frivol: [
    { word: 'frivol', word_class: 'V' },
    { word: 'frivolity', word_class: 'N' },
    { word: 'frivolous', word_class: 'AJ' },
    { word: 'frivolously', word_class: 'AV' },
    { word: 'frivolousness', word_class: 'N' }
  ],
  refrigeration: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  immenseness: [
    { word: 'immense', word_class: 'AJ' },
    { word: 'immensity', word_class: 'N' },
    { word: 'immensely', word_class: 'AV' },
    { word: 'immenseness', word_class: 'N' }
  ],
  combatant: [
    { word: 'combat', word_class: 'N' },
    { word: 'combat', word_class: 'V' },
    { word: 'combatant', word_class: 'N' },
    { word: 'combatant', word_class: 'AJ' }
  ],
  intention: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  systematized: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  reproachment: [
    { word: 'reproach', word_class: 'N' },
    { word: 'reproach', word_class: 'V' },
    { word: 'reproachful', word_class: 'AJ' },
    { word: 'reproachment', word_class: 'N' }
  ],
  brisk: [
    { word: 'brisk', word_class: 'V' },
    { word: 'brisk', word_class: 'AJ' },
    { word: 'briskness', word_class: 'N' }
  ],
  curl: [
    { word: 'curl', word_class: 'N' },
    { word: 'curl', word_class: 'V' },
    { word: 'curler', word_class: 'N' },
    { word: 'curled', word_class: 'AJ' },
    { word: 'curling', word_class: 'N' },
    { word: 'curling', word_class: 'AJ' }
  ],
  philosophizing: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  insubstantial: [
    { word: 'insubstantial', word_class: 'AJ' },
    { word: 'insubstantiality', word_class: 'N' },
    { word: 'insubstantially', word_class: 'AV' }
  ],
  revised: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  confrontational: [
    { word: 'confront', word_class: 'V' },
    { word: 'confronting', word_class: 'N' },
    { word: 'confrontment', word_class: 'N' },
    { word: 'confrontation', word_class: 'N' },
    { word: 'confrontational', word_class: 'AJ' }
  ],
  pleasurably: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  unfavourable: [
    { word: 'unfavourable', word_class: 'AJ' },
    { word: 'unfavourably', word_class: 'AV' },
    { word: 'unfavourableness', word_class: 'N' }
  ],
  sniffle: [
    { word: 'sniffle', word_class: 'N' },
    { word: 'sniffle', word_class: 'V' },
    { word: 'sniffling', word_class: 'AJ' }
  ],
  vibrancy: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  mailman: [
    { word: 'mail', word_class: 'N' },
    { word: 'mail', word_class: 'V' },
    { word: 'mailer', word_class: 'N' },
    { word: 'mailman', word_class: 'N' },
    { word: 'mailing', word_class: 'N' },
    { word: 'mailed', word_class: 'AJ' }
  ],
  aviate: [
    { word: 'aviate', word_class: 'V' },
    { word: 'aviator', word_class: 'N' },
    { word: 'aviation', word_class: 'N' }
  ],
  macule: [
    { word: 'macule', word_class: 'N' },
    { word: 'maculate', word_class: 'V' },
    { word: 'maculate', word_class: 'AJ' },
    { word: 'maculation', word_class: 'N' }
  ],
  tenure: [
    { word: 'tenure', word_class: 'N' },
    { word: 'tenure', word_class: 'V' },
    { word: 'tenured', word_class: 'AJ' }
  ],
  wondrous: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  fatalistic: [
    { word: 'fatalist', word_class: 'N' },
    { word: 'fatalist', word_class: 'AJ' },
    { word: 'fatalistic', word_class: 'AJ' }
  ],
  instruct: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  interrelated: [
    { word: 'interrelate', word_class: 'V' },
    { word: 'interrelated', word_class: 'AJ' },
    { word: 'interrelation', word_class: 'N' },
    { word: 'interrelationship', word_class: 'N' }
  ],
  enchained: [
    { word: 'enchain', word_class: 'V' },
    { word: 'enchained', word_class: 'AJ' },
    { word: 'enchainment', word_class: 'N' }
  ],
  tainted: [
    { word: 'taint', word_class: 'N' },
    { word: 'taint', word_class: 'V' },
    { word: 'tainted', word_class: 'AJ' }
  ],
  maniac: [
    { word: 'maniac', word_class: 'N' },
    { word: 'maniac', word_class: 'AJ' },
    { word: 'maniacal', word_class: 'AJ' },
    { word: 'maniacally', word_class: 'AV' }
  ],
  burping: [{ word: 'burp', word_class: 'N' }, { word: 'burp', word_class: 'V' }, { word: 'burping', word_class: 'N' }],
  valuate: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  torturously: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  bookbind: [
    { word: 'bookbind', word_class: 'V' },
    { word: 'bookbinder', word_class: 'N' },
    { word: 'bookbindery', word_class: 'N' },
    { word: 'bookbinding', word_class: 'N' },
    { word: 'bookbindery', word_class: 'AJ' }
  ],
  metabolous: [
    { word: 'metabolic', word_class: 'AJ' },
    { word: 'metabolism', word_class: 'N' },
    { word: 'metabolize', word_class: 'V' },
    { word: 'metabolous', word_class: 'AJ' },
    { word: 'metabolization', word_class: 'N' }
  ],
  scamper: [
    { word: 'scamper', word_class: 'N' },
    { word: 'scamper', word_class: 'V' },
    { word: 'scampering', word_class: 'AJ' }
  ],
  'air-conditioning': [
    { word: 'air-condition', word_class: 'V' },
    { word: 'air-conditioned', word_class: 'AJ' },
    { word: 'air-conditioning', word_class: 'N' }
  ],
  corduroys: [
    { word: 'corduroy', word_class: 'N' },
    { word: 'corduroy', word_class: 'V' },
    { word: 'corduroy', word_class: 'AJ' },
    { word: 'corduroys', word_class: 'N' }
  ],
  contrast: [
    { word: 'contrast', word_class: 'N' },
    { word: 'contrast', word_class: 'V' },
    { word: 'contrasting', word_class: 'AJ' },
    { word: 'contrastive', word_class: 'AJ' }
  ],
  wreathed: [
    { word: 'wreath', word_class: 'N' },
    { word: 'wreath', word_class: 'V' },
    { word: 'wreathe', word_class: 'V' },
    { word: 'wreathed', word_class: 'AJ' }
  ],
  lone: [
    { word: 'lone', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AV' },
    { word: 'loneliness', word_class: 'N' }
  ],
  loneliness: [
    { word: 'lone', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AJ' },
    { word: 'lonely', word_class: 'AV' },
    { word: 'loneliness', word_class: 'N' }
  ],
  reverberation: [
    { word: 'reverberate', word_class: 'V' },
    { word: 'reverberance', word_class: 'N' },
    { word: 'reverberant', word_class: 'AJ' },
    { word: 'reverberation', word_class: 'N' },
    { word: 'reverberating', word_class: 'AJ' }
  ],
  eternalisation: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  hemstitch: [
    { word: 'hemstitch', word_class: 'N' },
    { word: 'hemstitch', word_class: 'V' },
    { word: 'hemstitching', word_class: 'N' }
  ],
  restful: [
    { word: 'rest', word_class: 'N' },
    { word: 'rest', word_class: 'V' },
    { word: 'rested', word_class: 'AJ' },
    { word: 'restful', word_class: 'AJ' },
    { word: 'restfulness', word_class: 'N' }
  ],
  oneness: [
    { word: 'on', word_class: 'AV' },
    { word: 'one', word_class: 'N' },
    { word: 'on', word_class: 'AJ' },
    { word: 'one', word_class: 'AJ' },
    { word: 'oneness', word_class: 'N' }
  ],
  crasher: [
    { word: 'crash', word_class: 'N' },
    { word: 'crash', word_class: 'V' },
    { word: 'crash', word_class: 'AJ' },
    { word: 'crasher', word_class: 'N' },
    { word: 'crashing', word_class: 'AJ' }
  ],
  annihilate: [
    { word: 'annihilate', word_class: 'V' },
    { word: 'annihilated', word_class: 'AJ' },
    { word: 'annihilation', word_class: 'N' },
    { word: 'annihilating', word_class: 'AJ' },
    { word: 'annihilative', word_class: 'AJ' }
  ],
  bending: [
    { word: 'bend', word_class: 'N' },
    { word: 'bend', word_class: 'V' },
    { word: 'bends', word_class: 'N' },
    { word: 'bender', word_class: 'N' },
    { word: 'bended', word_class: 'AJ' },
    { word: 'bending', word_class: 'N' },
    { word: 'bending', word_class: 'AJ' }
  ],
  hosteller: [
    { word: 'hostel', word_class: 'N' },
    { word: 'hostelry', word_class: 'N' },
    { word: 'hosteller', word_class: 'N' }
  ],
  wrenching: [
    { word: 'wrench', word_class: 'N' },
    { word: 'wrench', word_class: 'V' },
    { word: 'wrenching', word_class: 'AJ' }
  ],
  insist: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  factually: [
    { word: 'factual', word_class: 'AJ' },
    { word: 'factuality', word_class: 'N' },
    { word: 'factually', word_class: 'AV' },
    { word: 'factualness', word_class: 'N' }
  ],
  lose: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  intrench: [
    { word: 'intrench', word_class: 'N' },
    { word: 'intrench', word_class: 'V' },
    { word: 'intrenchment', word_class: 'N' }
  ],
  impotency: [
    { word: 'impotence', word_class: 'N' },
    { word: 'impotency', word_class: 'N' },
    { word: 'impotent', word_class: 'AJ' },
    { word: 'impotently', word_class: 'AV' }
  ],
  act: [
    { word: 'act', word_class: 'N' },
    { word: 'act', word_class: 'V' },
    { word: 'acts', word_class: 'N' },
    { word: 'actor', word_class: 'N' },
    { word: 'acting', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'actress', word_class: 'N' }
  ],
  volution: [
    { word: 'volute', word_class: 'N' },
    { word: 'volute', word_class: 'AJ' },
    { word: 'voluted', word_class: 'AJ' },
    { word: 'volution', word_class: 'N' }
  ],
  troublesomeness: [
    { word: 'trouble', word_class: 'N' },
    { word: 'trouble', word_class: 'V' },
    { word: 'troubled', word_class: 'AJ' },
    { word: 'troubling', word_class: 'N' },
    { word: 'troubling', word_class: 'AJ' },
    { word: 'troublesome', word_class: 'AJ' },
    { word: 'troublesomeness', word_class: 'N' }
  ],
  formidably: [
    { word: 'formidable', word_class: 'AJ' },
    { word: 'formidably', word_class: 'AV' },
    { word: 'formidability', word_class: 'N' }
  ],
  literally: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  nictation: [
    { word: 'nictate', word_class: 'V' },
    { word: 'nictation', word_class: 'N' },
    { word: 'nictitate', word_class: 'V' },
    { word: 'nictitation', word_class: 'N' }
  ],
  downhill: [
    { word: 'downhill', word_class: 'N' },
    { word: 'downhill', word_class: 'AJ' },
    { word: 'downhill', word_class: 'AV' }
  ],
  stupefy: [
    { word: 'stupefy', word_class: 'V' },
    { word: 'stupify', word_class: 'V' },
    { word: 'stupefied', word_class: 'AJ' },
    { word: 'stupefying', word_class: 'AJ' },
    { word: 'stupefaction', word_class: 'N' }
  ],
  passivism: [
    { word: 'passive', word_class: 'N' },
    { word: 'passive', word_class: 'AJ' },
    { word: 'passivism', word_class: 'N' },
    { word: 'passivity', word_class: 'N' },
    { word: 'passively', word_class: 'AV' },
    { word: 'passiveness', word_class: 'N' }
  ],
  slash: [
    { word: 'slash', word_class: 'N' },
    { word: 'slash', word_class: 'V' },
    { word: 'slashed', word_class: 'AJ' },
    { word: 'slashing', word_class: 'N' },
    { word: 'slashing', word_class: 'AJ' }
  ],
  taped: [
    { word: 'tape', word_class: 'N' },
    { word: 'tape', word_class: 'V' },
    { word: 'taped', word_class: 'AJ' },
    { word: 'taping', word_class: 'N' }
  ],
  transitiveness: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  continuous: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  anaesthetisation: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  thyroid: [
    { word: 'thyroid', word_class: 'N' },
    { word: 'thyroid', word_class: 'AJ' },
    { word: 'thyroidal', word_class: 'AJ' }
  ],
  casually: [
    { word: 'casual', word_class: 'AJ' },
    { word: 'casually', word_class: 'AV' },
    { word: 'casualness', word_class: 'N' }
  ],
  kinesthetic: [
    { word: 'kinesthetic', word_class: 'AJ' },
    { word: 'kinesthetics', word_class: 'N' },
    { word: 'kinesthetically', word_class: 'AV' }
  ],
  bashfulness: [
    { word: 'bash', word_class: 'N' },
    { word: 'bash', word_class: 'V' },
    { word: 'bashful', word_class: 'AJ' },
    { word: 'bashfulness', word_class: 'N' }
  ],
  intension: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  amplification: [
    { word: 'amplify', word_class: 'V' },
    { word: 'amplifier', word_class: 'N' },
    { word: 'amplification', word_class: 'N' }
  ],
  navigation: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  preclusion: [
    { word: 'preclude', word_class: 'V' },
    { word: 'preclusion', word_class: 'N' },
    { word: 'preclusive', word_class: 'AJ' }
  ],
  diphthongise: [
    { word: 'diphthong', word_class: 'N' },
    { word: 'diphthongise', word_class: 'V' },
    { word: 'diphthongize', word_class: 'V' },
    { word: 'diphthongisation', word_class: 'N' },
    { word: 'diphthongization', word_class: 'N' }
  ],
  plateful: [
    { word: 'plate', word_class: 'N' },
    { word: 'plate', word_class: 'V' },
    { word: 'plating', word_class: 'N' },
    { word: 'plateful', word_class: 'N' },
    { word: 'plateful', word_class: 'AJ' }
  ],
  disreputable: [
    { word: 'disrepute', word_class: 'N' },
    { word: 'disreputable', word_class: 'AJ' },
    { word: 'disreputably', word_class: 'AV' },
    { word: 'disreputability', word_class: 'N' },
    { word: 'disreputableness', word_class: 'N' }
  ],
  humbuggery: [
    { word: 'humbug', word_class: 'N' },
    { word: 'humbug', word_class: 'V' },
    { word: 'humbuggery', word_class: 'N' }
  ],
  atmospherical: [
    { word: 'atmosphere', word_class: 'N' },
    { word: 'atmospheric', word_class: 'AJ' },
    { word: 'atmospherics', word_class: 'N' },
    { word: 'atmospherical', word_class: 'AJ' }
  ],
  financier: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  formulation: [
    { word: 'formulate', word_class: 'V' },
    { word: 'formulize', word_class: 'V' },
    { word: 'formulated', word_class: 'AJ' },
    { word: 'formulation', word_class: 'N' },
    { word: 'formulization', word_class: 'N' }
  ],
  jack: [{ word: 'jack', word_class: 'N' }, { word: 'jack', word_class: 'V' }, { word: 'jacks', word_class: 'N' }],
  supercharged: [
    { word: 'supercharge', word_class: 'V' },
    { word: 'supercharger', word_class: 'N' },
    { word: 'supercharged', word_class: 'AJ' }
  ],
  beach: [
    { word: 'beach', word_class: 'N' },
    { word: 'beach', word_class: 'V' },
    { word: 'beached', word_class: 'AJ' }
  ],
  demoniacal: [
    { word: 'demoniac', word_class: 'N' },
    { word: 'demoniac', word_class: 'AJ' },
    { word: 'demoniacal', word_class: 'AJ' },
    { word: 'demoniacally', word_class: 'AV' }
  ],
  barge: [
    { word: 'barge', word_class: 'N' },
    { word: 'barge', word_class: 'V' },
    { word: 'bargee', word_class: 'N' },
    { word: 'bargeman', word_class: 'N' }
  ],
  caterer: [
    { word: 'cater', word_class: 'V' },
    { word: 'caterer', word_class: 'N' },
    { word: 'catering', word_class: 'N' }
  ],
  droop: [
    { word: 'droop', word_class: 'N' },
    { word: 'droop', word_class: 'V' },
    { word: 'droopy', word_class: 'AJ' },
    { word: 'drooping', word_class: 'AJ' }
  ],
  posting: [
    { word: 'post', word_class: 'N' },
    { word: 'post', word_class: 'V' },
    { word: 'post', word_class: 'AJ' },
    { word: 'poster', word_class: 'N' },
    { word: 'poster', word_class: 'V' },
    { word: 'posting', word_class: 'N' },
    { word: 'postman', word_class: 'N' },
    { word: 'posterity', word_class: 'N' }
  ],
  statics: [
    { word: 'static', word_class: 'N' },
    { word: 'static', word_class: 'AJ' },
    { word: 'statice', word_class: 'N' },
    { word: 'statics', word_class: 'N' },
    { word: 'statically', word_class: 'AV' }
  ],
  tranquil: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  placation: [
    { word: 'placate', word_class: 'V' },
    { word: 'placation', word_class: 'N' },
    { word: 'placating', word_class: 'AJ' }
  ],
  agility: [
    { word: 'agile', word_class: 'AJ' },
    { word: 'agility', word_class: 'N' },
    { word: 'agilely', word_class: 'AV' }
  ],
  verbalise: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  fooling: [
    { word: 'fool', word_class: 'N' },
    { word: 'fool', word_class: 'V' },
    { word: 'fool', word_class: 'AJ' },
    { word: 'fooling', word_class: 'AJ' }
  ],
  warmness: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  assignation: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  irony: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  replication: [
    { word: 'replicate', word_class: 'V' },
    { word: 'replicable', word_class: 'AJ' },
    { word: 'replication', word_class: 'N' }
  ],
  rambler: [
    { word: 'ramble', word_class: 'N' },
    { word: 'ramble', word_class: 'V' },
    { word: 'rambler', word_class: 'N' },
    { word: 'rambling', word_class: 'AJ' }
  ],
  masonry: [
    { word: 'mason', word_class: 'N' },
    { word: 'masonry', word_class: 'N' },
    { word: 'masonic', word_class: 'AJ' }
  ],
  bicorne: [
    { word: 'bicorn', word_class: 'N' },
    { word: 'bicorn', word_class: 'AJ' },
    { word: 'bicorne', word_class: 'N' },
    { word: 'bicorned', word_class: 'AJ' },
    { word: 'bicornate', word_class: 'AJ' }
  ],
  acknowledgment: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  stratification: [
    { word: 'stratify', word_class: 'V' },
    { word: 'stratified', word_class: 'AJ' },
    { word: 'stratification', word_class: 'N' }
  ],
  clotted: [
    { word: 'clot', word_class: 'N' },
    { word: 'clot', word_class: 'V' },
    { word: 'clotted', word_class: 'AJ' },
    { word: 'clotting', word_class: 'N' }
  ],
  camphoration: [
    { word: 'camphor', word_class: 'N' },
    { word: 'camphorate', word_class: 'V' },
    { word: 'camphoric', word_class: 'AJ' },
    { word: 'camphorated', word_class: 'AJ' },
    { word: 'camphoration', word_class: 'N' }
  ],
  abysmal: [
    { word: 'abysm', word_class: 'N' },
    { word: 'abyss', word_class: 'N' },
    { word: 'abysmal', word_class: 'AJ' },
    { word: 'abyssal', word_class: 'AJ' },
    { word: 'abysmally', word_class: 'AV' }
  ],
  brusquely: [
    { word: 'brusque', word_class: 'AJ' },
    { word: 'brusquely', word_class: 'AV' },
    { word: 'brusqueness', word_class: 'N' }
  ],
  drive: [
    { word: 'drive', word_class: 'N' },
    { word: 'drive', word_class: 'V' },
    { word: 'driver', word_class: 'N' },
    { word: 'driven', word_class: 'AJ' },
    { word: 'driving', word_class: 'N' },
    { word: 'driving', word_class: 'AJ' }
  ],
  candied: [
    { word: 'candy', word_class: 'N' },
    { word: 'candy', word_class: 'V' },
    { word: 'candied', word_class: 'AJ' }
  ],
  benedictive: [
    { word: 'benedict', word_class: 'N' },
    { word: 'benediction', word_class: 'N' },
    { word: 'benedictive', word_class: 'AJ' }
  ],
  calendar: [
    { word: 'calendar', word_class: 'N' },
    { word: 'calendar', word_class: 'V' },
    { word: 'calendric', word_class: 'AJ' },
    { word: 'calendrical', word_class: 'AJ' }
  ],
  sorter: [
    { word: 'sort', word_class: 'N' },
    { word: 'sort', word_class: 'V' },
    { word: 'sorter', word_class: 'N' },
    { word: 'sorted', word_class: 'AJ' },
    { word: 'sorting', word_class: 'N' }
  ],
  macerative: [
    { word: 'macer', word_class: 'N' },
    { word: 'macerate', word_class: 'V' },
    { word: 'maceration', word_class: 'N' },
    { word: 'macerative', word_class: 'AJ' }
  ],
  avoid: [
    { word: 'avoid', word_class: 'V' },
    { word: 'avoidance', word_class: 'N' },
    { word: 'avoidable', word_class: 'AJ' }
  ],
  unloving: [
    { word: 'unloved', word_class: 'AJ' },
    { word: 'unlovely', word_class: 'AJ' },
    { word: 'unloving', word_class: 'AJ' },
    { word: 'unlovable', word_class: 'AJ' }
  ],
  mindfulness: [
    { word: 'mind', word_class: 'N' },
    { word: 'mind', word_class: 'V' },
    { word: 'minder', word_class: 'N' },
    { word: 'minded', word_class: 'AJ' },
    { word: 'mindful', word_class: 'AJ' },
    { word: 'mindfulness', word_class: 'N' }
  ],
  jutting: [
    { word: 'jut', word_class: 'N' },
    { word: 'jut', word_class: 'V' },
    { word: 'jutting', word_class: 'N' },
    { word: 'jutting', word_class: 'AJ' }
  ],
  guessing: [
    { word: 'guess', word_class: 'N' },
    { word: 'guess', word_class: 'V' },
    { word: 'guessing', word_class: 'N' }
  ],
  oxidation: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  raped: [
    { word: 'rape', word_class: 'N' },
    { word: 'rape', word_class: 'V' },
    { word: 'raper', word_class: 'N' },
    { word: 'raped', word_class: 'AJ' },
    { word: 'rapist', word_class: 'N' }
  ],
  voyage: [
    { word: 'voyage', word_class: 'N' },
    { word: 'voyage', word_class: 'V' },
    { word: 'voyager', word_class: 'N' }
  ],
  rearward: [
    { word: 'rearward', word_class: 'N' },
    { word: 'rearward', word_class: 'AJ' },
    { word: 'rearward', word_class: 'AV' },
    { word: 'rearwards', word_class: 'AV' }
  ],
  mater: [
    { word: 'mate', word_class: 'V' },
    { word: 'mate', word_class: 'N' },
    { word: 'mater', word_class: 'N' },
    { word: 'mates', word_class: 'N' },
    { word: 'mated', word_class: 'AJ' },
    { word: 'mating', word_class: 'N' }
  ],
  applicative: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  raft: [
    { word: 'raft', word_class: 'N' },
    { word: 'raft', word_class: 'V' },
    { word: 'rafts', word_class: 'N' },
    { word: 'rafter', word_class: 'N' },
    { word: 'rafter', word_class: 'V' },
    { word: 'raftman', word_class: 'N' },
    { word: 'raftered', word_class: 'AJ' }
  ],
  baroqueness: [
    { word: 'baroque', word_class: 'N' },
    { word: 'baroque', word_class: 'AJ' },
    { word: 'baroqueness', word_class: 'N' }
  ],
  racially: [
    { word: 'racial', word_class: 'AJ' },
    { word: 'racialism', word_class: 'N' },
    { word: 'racially', word_class: 'AV' }
  ],
  reaching: [
    { word: 'reach', word_class: 'N' },
    { word: 'reach', word_class: 'V' },
    { word: 'reaching', word_class: 'N' }
  ],
  instance: [
    { word: 'instance', word_class: 'N' },
    { word: 'instance', word_class: 'V' },
    { word: 'instancy', word_class: 'N' },
    { word: 'instantiate', word_class: 'V' },
    { word: 'instantiation', word_class: 'N' }
  ],
  bronzed: [
    { word: 'bronze', word_class: 'N' },
    { word: 'bronze', word_class: 'V' },
    { word: 'bronze', word_class: 'AJ' },
    { word: 'bronzed', word_class: 'AJ' }
  ],
  lurcher: [
    { word: 'lurch', word_class: 'N' },
    { word: 'lurch', word_class: 'V' },
    { word: 'lurcher', word_class: 'N' },
    { word: 'lurching', word_class: 'AJ' }
  ],
  jacobinism: [
    { word: 'jacobin', word_class: 'N' },
    { word: 'jacobinic', word_class: 'AJ' },
    { word: 'jacobinism', word_class: 'N' },
    { word: 'jacobinical', word_class: 'AJ' }
  ],
  repatriate: [
    { word: 'repatriate', word_class: 'N' },
    { word: 'repatriate', word_class: 'V' },
    { word: 'repatriation', word_class: 'N' }
  ],
  tendencious: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  symphony: [
    { word: 'symphony', word_class: 'N' },
    { word: 'symphonic', word_class: 'AJ' },
    { word: 'symphonize', word_class: 'V' },
    { word: 'symphonious', word_class: 'AJ' }
  ],
  breath: [
    { word: 'breath', word_class: 'N' },
    { word: 'breathe', word_class: 'V' },
    { word: 'breather', word_class: 'N' },
    { word: 'breathed', word_class: 'AJ' },
    { word: 'breathing', word_class: 'N' },
    { word: 'breathing', word_class: 'AJ' }
  ],
  anatomic: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  expensiveness: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  legislative: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  simplify: [
    { word: 'simplify', word_class: 'V' },
    { word: 'simplified', word_class: 'AJ' },
    { word: 'simplification', word_class: 'N' }
  ],
  arterial: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  lifter: [
    { word: 'lift', word_class: 'N' },
    { word: 'lift', word_class: 'V' },
    { word: 'lifter', word_class: 'N' },
    { word: 'lifted', word_class: 'AJ' },
    { word: 'liftman', word_class: 'N' }
  ],
  brutal: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  subscript: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  ecumenicalism: [
    { word: 'ecumenic', word_class: 'AJ' },
    { word: 'ecumenism', word_class: 'N' },
    { word: 'ecumenical', word_class: 'AJ' },
    { word: 'ecumenicalism', word_class: 'N' }
  ],
  sightseer: [
    { word: 'sightsee', word_class: 'V' },
    { word: 'sightseer', word_class: 'N' },
    { word: 'sightseeing', word_class: 'N' }
  ],
  rustication: [
    { word: 'rustic', word_class: 'N' },
    { word: 'rustic', word_class: 'AJ' },
    { word: 'rusticate', word_class: 'V' },
    { word: 'rusticity', word_class: 'N' },
    { word: 'rustication', word_class: 'N' }
  ],
  retorsion: [
    { word: 'retort', word_class: 'N' },
    { word: 'retort', word_class: 'V' },
    { word: 'retorsion', word_class: 'N' },
    { word: 'retortion', word_class: 'N' }
  ],
  dinge: [{ word: 'ding', word_class: 'N' }, { word: 'ding', word_class: 'V' }, { word: 'dinge', word_class: 'V' }],
  puff: [
    { word: 'puff', word_class: 'N' },
    { word: 'puff', word_class: 'V' },
    { word: 'puff', word_class: 'AJ' },
    { word: 'puffer', word_class: 'N' }
  ],
  potentate: [
    { word: 'potence', word_class: 'N' },
    { word: 'potency', word_class: 'N' },
    { word: 'potent', word_class: 'AJ' },
    { word: 'potentate', word_class: 'N' },
    { word: 'potently', word_class: 'AV' }
  ],
  ruination: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  studying: [
    { word: 'study', word_class: 'N' },
    { word: 'study', word_class: 'V' },
    { word: 'studied', word_class: 'AJ' },
    { word: 'studying', word_class: 'N' }
  ],
  circling: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  aesthetics: [
    { word: 'aesthete', word_class: 'N' },
    { word: 'aesthetic', word_class: 'N' },
    { word: 'aesthetic', word_class: 'AJ' },
    { word: 'aesthetics', word_class: 'N' },
    { word: 'aesthetical', word_class: 'AJ' },
    { word: 'aesthetically', word_class: 'AV' }
  ],
  hatch: [
    { word: 'hatch', word_class: 'N' },
    { word: 'hatch', word_class: 'V' },
    { word: 'hatched', word_class: 'AJ' },
    { word: 'hatching', word_class: 'N' },
    { word: 'hatching', word_class: 'AJ' }
  ],
  defeasible: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  piloting: [
    { word: 'pilot', word_class: 'N' },
    { word: 'pilot', word_class: 'V' },
    { word: 'pilot', word_class: 'AJ' },
    { word: 'pilotage', word_class: 'N' },
    { word: 'piloting', word_class: 'N' }
  ],
  kidnaper: [
    { word: 'kidnap', word_class: 'V' },
    { word: 'kidnaper', word_class: 'N' },
    { word: 'kidnaped', word_class: 'AJ' },
    { word: 'kidnapped', word_class: 'AJ' },
    { word: 'kidnapping', word_class: 'N' }
  ],
  beleaguer: [
    { word: 'beleaguer', word_class: 'V' },
    { word: 'beleaguering', word_class: 'N' },
    { word: 'beleaguerment', word_class: 'N' }
  ],
  barred: [
    { word: 'bar', word_class: 'N' },
    { word: 'bar', word_class: 'V' },
    { word: 'bars', word_class: 'N' },
    { word: 'barman', word_class: 'N' },
    { word: 'barred', word_class: 'AJ' },
    { word: 'barring', word_class: 'N' }
  ],
  thumb: [
    { word: 'thumb', word_class: 'N' },
    { word: 'thumb', word_class: 'V' },
    { word: 'thumbed', word_class: 'AJ' }
  ],
  chartist: [
    { word: 'chart', word_class: 'N' },
    { word: 'chart', word_class: 'V' },
    { word: 'chartism', word_class: 'N' },
    { word: 'chartist', word_class: 'N' }
  ],
  extroverted: [
    { word: 'extrovert', word_class: 'N' },
    { word: 'extrovert', word_class: 'AJ' },
    { word: 'extroversion', word_class: 'N' },
    { word: 'extroverted', word_class: 'AJ' },
    { word: 'extroversive', word_class: 'AJ' },
    { word: 'extrovertive', word_class: 'AJ' }
  ],
  buttressed: [
    { word: 'buttress', word_class: 'N' },
    { word: 'buttress', word_class: 'V' },
    { word: 'buttressed', word_class: 'AJ' },
    { word: 'buttressing', word_class: 'N' }
  ],
  emptying: [
    { word: 'empty', word_class: 'N' },
    { word: 'empty', word_class: 'V' },
    { word: 'empty', word_class: 'AJ' },
    { word: 'emptied', word_class: 'AJ' },
    { word: 'emptying', word_class: 'N' },
    { word: 'emptiness', word_class: 'N' }
  ],
  plot: [{ word: 'plot', word_class: 'N' }, { word: 'plot', word_class: 'V' }, { word: 'plotted', word_class: 'AJ' }],
  sanitization: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  erratum: [{ word: 'err', word_class: 'V' }, { word: 'error', word_class: 'N' }, { word: 'erratum', word_class: 'N' }],
  collectible: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  sloping: [
    { word: 'slope', word_class: 'N' },
    { word: 'slope', word_class: 'V' },
    { word: 'sloped', word_class: 'AJ' },
    { word: 'sloping', word_class: 'AJ' }
  ],
  conformist: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  mystical: [
    { word: 'mystic', word_class: 'N' },
    { word: 'mystic', word_class: 'AJ' },
    { word: 'mystical', word_class: 'AJ' },
    { word: 'mysticism', word_class: 'N' },
    { word: 'mystically', word_class: 'AV' }
  ],
  conserved: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  twit: [{ word: 'twit', word_class: 'N' }, { word: 'twit', word_class: 'V' }, { word: 'twitting', word_class: 'N' }],
  homogenise: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  cement: [
    { word: 'cement', word_class: 'N' },
    { word: 'cement', word_class: 'V' },
    { word: 'cementite', word_class: 'N' },
    { word: 'cementation', word_class: 'N' }
  ],
  pretty: [
    { word: 'pretty', word_class: 'AJ' },
    { word: 'pretty', word_class: 'AV' },
    { word: 'prettiness', word_class: 'N' }
  ],
  magnanimous: [
    { word: 'magnanimity', word_class: 'N' },
    { word: 'magnanimous', word_class: 'AJ' },
    { word: 'magnanimously', word_class: 'AV' },
    { word: 'magnanimousness', word_class: 'N' }
  ],
  verbalisation: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  conclusive: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  palpitant: [
    { word: 'palpitate', word_class: 'V' },
    { word: 'palpitant', word_class: 'AJ' },
    { word: 'palpitation', word_class: 'N' },
    { word: 'palpitating', word_class: 'AJ' }
  ],
  variation: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  erasure: [
    { word: 'erase', word_class: 'V' },
    { word: 'eraser', word_class: 'N' },
    { word: 'erasure', word_class: 'N' },
    { word: 'erasable', word_class: 'AJ' }
  ],
  barbarism: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  'air-condition': [
    { word: 'air-condition', word_class: 'V' },
    { word: 'air-conditioned', word_class: 'AJ' },
    { word: 'air-conditioning', word_class: 'N' }
  ],
  blame: [
    { word: 'blame', word_class: 'N' },
    { word: 'blame', word_class: 'V' },
    { word: 'blame', word_class: 'AJ' },
    { word: 'blamed', word_class: 'AJ' },
    { word: 'blameful', word_class: 'AJ' }
  ],
  drunken: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  emotionalize: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  goer: [
    { word: 'go', word_class: 'N' },
    { word: 'go', word_class: 'V' },
    { word: 'go', word_class: 'AJ' },
    { word: 'goal', word_class: 'N' },
    { word: 'goer', word_class: 'N' },
    { word: 'going', word_class: 'N' },
    { word: 'gone', word_class: 'AJ' },
    { word: 'going', word_class: 'AJ' }
  ],
  atarax: [
    { word: 'atarax', word_class: 'N' },
    { word: 'ataraxic', word_class: 'N' },
    { word: 'ataraxic', word_class: 'AJ' }
  ],
  links: [
    { word: 'link', word_class: 'N' },
    { word: 'link', word_class: 'V' },
    { word: 'links', word_class: 'N' },
    { word: 'linkage', word_class: 'N' },
    { word: 'linkman', word_class: 'N' },
    { word: 'linked', word_class: 'AJ' }
  ],
  aspirant: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  certificate: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  unfit: [
    { word: 'unfit', word_class: 'V' },
    { word: 'unfit', word_class: 'AJ' },
    { word: 'unfitness', word_class: 'N' },
    { word: 'unfitting', word_class: 'AJ' }
  ],
  felicitousness: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  mentally: [
    { word: 'mental', word_class: 'AJ' },
    { word: 'mentalism', word_class: 'N' },
    { word: 'mentality', word_class: 'N' },
    { word: 'mentally', word_class: 'AV' }
  ],
  etiolation: [
    { word: 'etiolate', word_class: 'V' },
    { word: 'etiolate', word_class: 'AJ' },
    { word: 'etiolated', word_class: 'AJ' },
    { word: 'etiolation', word_class: 'N' }
  ],
  massive: [
    { word: 'massive', word_class: 'AJ' },
    { word: 'massively', word_class: 'AV' },
    { word: 'massiveness', word_class: 'N' }
  ],
  ambiguous: [
    { word: 'ambiguity', word_class: 'N' },
    { word: 'ambiguous', word_class: 'AJ' },
    { word: 'ambiguously', word_class: 'AV' }
  ],
  retrograde: [
    { word: 'retrograde', word_class: 'N' },
    { word: 'retrograde', word_class: 'V' },
    { word: 'retrograde', word_class: 'AJ' },
    { word: 'retrogradation', word_class: 'N' }
  ],
  acquainted: [
    { word: 'acquaint', word_class: 'V' },
    { word: 'acquainted', word_class: 'AJ' },
    { word: 'acquaintance', word_class: 'N' }
  ],
  substantiative: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  personality: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  deciphered: [
    { word: 'decipher', word_class: 'V' },
    { word: 'deciphered', word_class: 'AJ' },
    { word: 'decipherment', word_class: 'N' },
    { word: 'decipherable', word_class: 'AJ' },
    { word: 'decipherably', word_class: 'AV' }
  ],
  discharged: [
    { word: 'discharge', word_class: 'N' },
    { word: 'discharge', word_class: 'V' },
    { word: 'discharged', word_class: 'AJ' }
  ],
  vitaminize: [
    { word: 'vitamin', word_class: 'N' },
    { word: 'vitamine', word_class: 'N' },
    { word: 'vitaminize', word_class: 'V' }
  ],
  forwarding: [
    { word: 'forward', word_class: 'N' },
    { word: 'forward', word_class: 'V' },
    { word: 'forward', word_class: 'AJ' },
    { word: 'forward', word_class: 'AV' },
    { word: 'forwards', word_class: 'AV' },
    { word: 'forwarding', word_class: 'N' },
    { word: 'forwardness', word_class: 'N' }
  ],
  brainwash: [
    { word: 'brainwash', word_class: 'V' },
    { word: 'brainwashed', word_class: 'AJ' },
    { word: 'brainwashing', word_class: 'N' }
  ],
  gaudiness: [
    { word: 'gaudy', word_class: 'N' },
    { word: 'gaudy', word_class: 'AJ' },
    { word: 'gaudiness', word_class: 'N' }
  ],
  foresee: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  pave: [
    { word: 'pave', word_class: 'V' },
    { word: 'paved', word_class: 'AJ' },
    { word: 'paving', word_class: 'N' },
    { word: 'pavement', word_class: 'N' }
  ],
  effuse: [
    { word: 'effuse', word_class: 'V' },
    { word: 'effusion', word_class: 'N' },
    { word: 'effusive', word_class: 'AJ' },
    { word: 'effusively', word_class: 'AV' },
    { word: 'effusiveness', word_class: 'N' }
  ],
  mercurialize: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  cackle: [
    { word: 'cackle', word_class: 'N' },
    { word: 'cackle', word_class: 'V' },
    { word: 'cackler', word_class: 'N' }
  ],
  proscribed: [
    { word: 'proscribe', word_class: 'N' },
    { word: 'proscribe', word_class: 'V' },
    { word: 'proscribed', word_class: 'AJ' },
    { word: 'proscription', word_class: 'N' },
    { word: 'proscriptive', word_class: 'AJ' }
  ],
  validated: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  loquaciously: [
    { word: 'loquacious', word_class: 'AJ' },
    { word: 'loquaciously', word_class: 'AV' },
    { word: 'loquaciousness', word_class: 'N' }
  ],
  ok: [
    { word: 'ok', word_class: 'N' },
    { word: 'ok', word_class: 'V' },
    { word: 'ok', word_class: 'AJ' },
    { word: 'ok', word_class: 'AV' }
  ],
  monotone: [
    { word: 'monotone', word_class: 'N' },
    { word: 'monotone', word_class: 'AJ' },
    { word: 'monotonic', word_class: 'AJ' },
    { word: 'monotonous', word_class: 'AJ' },
    { word: 'monotonously', word_class: 'AV' }
  ],
  're-created': [
    { word: 're-create', word_class: 'V' },
    { word: 're-created', word_class: 'AJ' },
    { word: 're-creation', word_class: 'N' }
  ],
  bootleg: [
    { word: 'bootleg', word_class: 'N' },
    { word: 'bootleg', word_class: 'V' },
    { word: 'bootleg', word_class: 'AJ' },
    { word: 'bootlegging', word_class: 'N' }
  ],
  rove: [
    { word: 'rove', word_class: 'N' },
    { word: 'rove', word_class: 'V' },
    { word: 'rover', word_class: 'N' },
    { word: 'roving', word_class: 'N' },
    { word: 'roving', word_class: 'AJ' }
  ],
  occasions: [
    { word: 'occasion', word_class: 'N' },
    { word: 'occasion', word_class: 'V' },
    { word: 'occasions', word_class: 'N' },
    { word: 'occasional', word_class: 'AJ' },
    { word: 'occasionment', word_class: 'N' },
    { word: 'occasionally', word_class: 'AV' }
  ],
  playact: [
    { word: 'playact', word_class: 'V' },
    { word: 'playacting', word_class: 'N' },
    { word: 'playaction', word_class: 'N' }
  ],
  recurse: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  auditive: [
    { word: 'audit', word_class: 'N' },
    { word: 'audit', word_class: 'V' },
    { word: 'auditor', word_class: 'N' },
    { word: 'audition', word_class: 'N' },
    { word: 'audition', word_class: 'V' },
    { word: 'auditive', word_class: 'AJ' }
  ],
  apportion: [
    { word: 'apportion', word_class: 'V' },
    { word: 'apportioned', word_class: 'AJ' },
    { word: 'apportioning', word_class: 'N' },
    { word: 'apportionment', word_class: 'N' },
    { word: 'apportionable', word_class: 'AJ' }
  ],
  devising: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  minimise: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  flush: [
    { word: 'flush', word_class: 'N' },
    { word: 'flush', word_class: 'V' },
    { word: 'flush', word_class: 'AJ' },
    { word: 'flush', word_class: 'AV' },
    { word: 'flushed', word_class: 'AJ' }
  ],
  glycerolize: [
    { word: 'glycerol', word_class: 'N' },
    { word: 'glycerole', word_class: 'N' },
    { word: 'glycerolize', word_class: 'V' }
  ],
  bunting: [{ word: 'bunt', word_class: 'N' }, { word: 'bunt', word_class: 'V' }, { word: 'bunting', word_class: 'N' }],
  colon: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  intrinsical: [
    { word: 'intrinsic', word_class: 'AJ' },
    { word: 'intrinsical', word_class: 'AJ' },
    { word: 'intrinsically', word_class: 'AV' }
  ],
  insolvent: [
    { word: 'insolvent', word_class: 'N' },
    { word: 'insolvency', word_class: 'N' },
    { word: 'insolvent', word_class: 'AJ' },
    { word: 'insolvable', word_class: 'AJ' }
  ],
  frier: [
    { word: 'fry', word_class: 'N' },
    { word: 'fry', word_class: 'V' },
    { word: 'frier', word_class: 'N' },
    { word: 'fryer', word_class: 'N' },
    { word: 'frying', word_class: 'N' }
  ],
  clot: [
    { word: 'clot', word_class: 'N' },
    { word: 'clot', word_class: 'V' },
    { word: 'clotted', word_class: 'AJ' },
    { word: 'clotting', word_class: 'N' }
  ],
  'part-timer': [
    { word: 'part-time', word_class: 'AJ' },
    { word: 'part-time', word_class: 'AV' },
    { word: 'part-timer', word_class: 'N' }
  ],
  penning: [
    { word: 'pen', word_class: 'V' },
    { word: 'pen', word_class: 'N' },
    { word: 'penman', word_class: 'N' },
    { word: 'penance', word_class: 'N' },
    { word: 'penance', word_class: 'V' },
    { word: 'penned', word_class: 'AJ' },
    { word: 'penning', word_class: 'N' }
  ],
  elecrodeposition: [
    { word: 'electrodeposit', word_class: 'V' },
    { word: 'elecrodeposition', word_class: 'N' },
    { word: 'electrodeposition', word_class: 'N' }
  ],
  unattainable: [
    { word: 'unattainable', word_class: 'AJ' },
    { word: 'unattainably', word_class: 'AV' },
    { word: 'unattainableness', word_class: 'N' }
  ],
  untying: [
    { word: 'untie', word_class: 'V' },
    { word: 'untied', word_class: 'AJ' },
    { word: 'untying', word_class: 'N' }
  ],
  wesleyan: [
    { word: 'wesleyan', word_class: 'N' },
    { word: 'wesleyan', word_class: 'AJ' },
    { word: 'wesleyanism', word_class: 'N' }
  ],
  impetus: [
    { word: 'impetus', word_class: 'N' },
    { word: 'impetuous', word_class: 'AJ' },
    { word: 'impetuously', word_class: 'AV' },
    { word: 'impetuousness', word_class: 'N' }
  ],
  unuseable: [
    { word: 'unused', word_class: 'AJ' },
    { word: 'unusable', word_class: 'AJ' },
    { word: 'unuseable', word_class: 'AJ' },
    { word: 'unusefulness', word_class: 'N' }
  ],
  circumstantiation: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  nuptials: [
    { word: 'nuptial', word_class: 'N' },
    { word: 'nuptial', word_class: 'AJ' },
    { word: 'nuptials', word_class: 'N' }
  ],
  vitalizing: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  adams: [
    { word: 'adam', word_class: 'N' },
    { word: 'adams', word_class: 'N' },
    { word: 'adamant', word_class: 'N' },
    { word: 'adamance', word_class: 'N' },
    { word: 'adamant', word_class: 'AJ' }
  ],
  sanctioned: [
    { word: 'sanction', word_class: 'N' },
    { word: 'sanction', word_class: 'V' },
    { word: 'sanctioned', word_class: 'AJ' },
    { word: 'sanctioning', word_class: 'AJ' },
    { word: 'sanctionative', word_class: 'AJ' }
  ],
  mesmerism: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  horseshoe: [
    { word: 'horseshoe', word_class: 'N' },
    { word: 'horseshoer', word_class: 'N' },
    { word: 'horseshoes', word_class: 'N' }
  ],
  dumping: [
    { word: 'dump', word_class: 'N' },
    { word: 'dump', word_class: 'V' },
    { word: 'dumps', word_class: 'N' },
    { word: 'dumper', word_class: 'N' },
    { word: 'dumped', word_class: 'AJ' },
    { word: 'dumping', word_class: 'N' }
  ],
  static: [
    { word: 'static', word_class: 'N' },
    { word: 'static', word_class: 'AJ' },
    { word: 'statice', word_class: 'N' },
    { word: 'statics', word_class: 'N' },
    { word: 'statically', word_class: 'AV' }
  ],
  perch: [
    { word: 'perch', word_class: 'N' },
    { word: 'perch', word_class: 'V' },
    { word: 'perched', word_class: 'AJ' }
  ],
  sustainment: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  heckle: [
    { word: 'heckle', word_class: 'V' },
    { word: 'heckler', word_class: 'N' },
    { word: 'heckling', word_class: 'N' }
  ],
  suspend: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  befuddled: [
    { word: 'befuddle', word_class: 'V' },
    { word: 'befuddled', word_class: 'AJ' },
    { word: 'befuddlement', word_class: 'N' }
  ],
  shamed: [
    { word: 'shame', word_class: 'N' },
    { word: 'shame', word_class: 'V' },
    { word: 'shamed', word_class: 'AJ' },
    { word: 'shameful', word_class: 'AJ' },
    { word: 'shamefulness', word_class: 'N' }
  ],
  compromising: [
    { word: 'compromise', word_class: 'N' },
    { word: 'compromise', word_class: 'V' },
    { word: 'compromising', word_class: 'AJ' }
  ],
  effortful: [
    { word: 'effort', word_class: 'N' },
    { word: 'effortful', word_class: 'AJ' },
    { word: 'effortfulness', word_class: 'N' }
  ],
  mythologic: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  disastrously: [
    { word: 'disaster', word_class: 'N' },
    { word: 'disastrous', word_class: 'AJ' },
    { word: 'disastrously', word_class: 'AV' }
  ],
  alkaline: [
    { word: 'alkali', word_class: 'N' },
    { word: 'alkaline', word_class: 'AJ' },
    { word: 'alkalinity', word_class: 'N' },
    { word: 'alkalinize', word_class: 'V' },
    { word: 'alkalinization', word_class: 'N' }
  ],
  receptivity: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  propagandistic: [
    { word: 'propaganda', word_class: 'N' },
    { word: 'propagandist', word_class: 'N' },
    { word: 'propagandize', word_class: 'V' },
    { word: 'propagandist', word_class: 'AJ' },
    { word: 'propagandistic', word_class: 'AJ' },
    { word: 'propagandization', word_class: 'N' }
  ],
  subsidized: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  emotionalisation: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  modulate: [
    { word: 'module', word_class: 'N' },
    { word: 'modular', word_class: 'AJ' },
    { word: 'modulate', word_class: 'V' },
    { word: 'modulated', word_class: 'AJ' },
    { word: 'modulation', word_class: 'N' }
  ],
  hallucination: [
    { word: 'hallucinate', word_class: 'V' },
    { word: 'hallucination', word_class: 'N' },
    { word: 'hallucinating', word_class: 'AJ' }
  ],
  medic: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  buccaneer: [
    { word: 'buccaneer', word_class: 'N' },
    { word: 'buccaneer', word_class: 'V' },
    { word: 'buccaneering', word_class: 'N' }
  ],
  geologically: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  consignor: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  permitted: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  large: [
    { word: 'large', word_class: 'N' },
    { word: 'large', word_class: 'AJ' },
    { word: 'large', word_class: 'AV' },
    { word: 'largely', word_class: 'AV' },
    { word: 'largeness', word_class: 'N' }
  ],
  decimation: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  homologization: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  march: [
    { word: 'march', word_class: 'N' },
    { word: 'march', word_class: 'V' },
    { word: 'marche', word_class: 'N' },
    { word: 'marcher', word_class: 'N' },
    { word: 'marches', word_class: 'N' },
    { word: 'marching', word_class: 'N' },
    { word: 'marching', word_class: 'AJ' }
  ],
  vein: [{ word: 'vein', word_class: 'N' }, { word: 'vein', word_class: 'V' }, { word: 'veined', word_class: 'AJ' }],
  nitrogenous: [
    { word: 'nitrogen', word_class: 'N' },
    { word: 'nitrogenize', word_class: 'V' },
    { word: 'nitrogenous', word_class: 'AJ' },
    { word: 'nitrogenization', word_class: 'N' }
  ],
  impact: [
    { word: 'impact', word_class: 'N' },
    { word: 'impact', word_class: 'V' },
    { word: 'impacted', word_class: 'AJ' },
    { word: 'impaction', word_class: 'N' }
  ],
  immovably: [
    { word: 'immovable', word_class: 'AJ' },
    { word: 'immovably', word_class: 'AV' },
    { word: 'immoveable', word_class: 'AJ' },
    { word: 'immovability', word_class: 'N' },
    { word: 'immovableness', word_class: 'N' }
  ],
  casing: [
    { word: 'case', word_class: 'N' },
    { word: 'case', word_class: 'V' },
    { word: 'cased', word_class: 'AJ' },
    { word: 'casing', word_class: 'N' },
    { word: 'caseful', word_class: 'N' },
    { word: 'casement', word_class: 'N' }
  ],
  housing: [
    { word: 'house', word_class: 'N' },
    { word: 'house', word_class: 'V' },
    { word: 'housing', word_class: 'N' },
    { word: 'houseful', word_class: 'N' },
    { word: 'houseman', word_class: 'N' },
    { word: 'housings', word_class: 'N' },
    { word: 'houseful', word_class: 'AJ' }
  ],
  tartaric: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  femininity: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  amaze: [
    { word: 'amaze', word_class: 'V' },
    { word: 'amazed', word_class: 'AJ' },
    { word: 'amazing', word_class: 'AJ' },
    { word: 'amazement', word_class: 'N' }
  ],
  sceptered: [
    { word: 'scepter', word_class: 'N' },
    { word: 'scepter', word_class: 'V' },
    { word: 'sceptered', word_class: 'AJ' }
  ],
  apprehensively: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  elate: [
    { word: 'elate', word_class: 'V' },
    { word: 'elater', word_class: 'N' },
    { word: 'elated', word_class: 'AJ' },
    { word: 'elation', word_class: 'N' },
    { word: 'elating', word_class: 'AJ' }
  ],
  immutably: [
    { word: 'immutable', word_class: 'AJ' },
    { word: 'immutably', word_class: 'AV' },
    { word: 'immutability', word_class: 'N' },
    { word: 'immutableness', word_class: 'N' }
  ],
  impairment: [
    { word: 'impair', word_class: 'N' },
    { word: 'impair', word_class: 'V' },
    { word: 'impaired', word_class: 'AJ' },
    { word: 'impairment', word_class: 'N' }
  ],
  bodybuild: [
    { word: 'bodybuild', word_class: 'V' },
    { word: 'bodybuilder', word_class: 'N' },
    { word: 'bodybuilding', word_class: 'N' }
  ],
  futurist: [
    { word: 'futurist', word_class: 'N' },
    { word: 'futurist', word_class: 'AJ' },
    { word: 'futuristic', word_class: 'AJ' }
  ],
  adorable: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  liquidizer: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  offender: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  thrash: [
    { word: 'thrash', word_class: 'N' },
    { word: 'thrash', word_class: 'V' },
    { word: 'thrasher', word_class: 'N' },
    { word: 'thrashing', word_class: 'N' },
    { word: 'thrashing', word_class: 'AJ' }
  ],
  frivolously: [
    { word: 'frivol', word_class: 'V' },
    { word: 'frivolity', word_class: 'N' },
    { word: 'frivolous', word_class: 'AJ' },
    { word: 'frivolously', word_class: 'AV' },
    { word: 'frivolousness', word_class: 'N' }
  ],
  criminalize: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  territorialise: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  throb: [
    { word: 'throb', word_class: 'N' },
    { word: 'throb', word_class: 'V' },
    { word: 'throbbing', word_class: 'N' },
    { word: 'throbbing', word_class: 'AJ' }
  ],
  jeweller: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  bleeding: [
    { word: 'bleed', word_class: 'V' },
    { word: 'bleeder', word_class: 'N' },
    { word: 'bleeding', word_class: 'N' },
    { word: 'bleeding', word_class: 'AJ' }
  ],
  theme: [
    { word: 'theme', word_class: 'N' },
    { word: 'thematic', word_class: 'AJ' },
    { word: 'thematically', word_class: 'AV' }
  ],
  demon: [
    { word: 'demon', word_class: 'N' },
    { word: 'demonic', word_class: 'AJ' },
    { word: 'demonism', word_class: 'N' },
    { word: 'demonize', word_class: 'V' },
    { word: 'demonization', word_class: 'N' }
  ],
  extricated: [
    { word: 'extricate', word_class: 'V' },
    { word: 'extricable', word_class: 'AJ' },
    { word: 'extricated', word_class: 'AJ' },
    { word: 'extrication', word_class: 'N' }
  ],
  incurability: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  minimize: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  bisexual: [
    { word: 'bisexual', word_class: 'N' },
    { word: 'bisexual', word_class: 'AJ' },
    { word: 'bisexuality', word_class: 'N' }
  ],
  rung: [
    { word: 'ring', word_class: 'N' },
    { word: 'ring', word_class: 'V' },
    { word: 'rung', word_class: 'N' },
    { word: 'rung', word_class: 'AJ' },
    { word: 'ringer', word_class: 'N' },
    { word: 'ringed', word_class: 'AJ' },
    { word: 'ringing', word_class: 'N' },
    { word: 'ringing', word_class: 'AJ' }
  ],
  adventuresome: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  calve: [
    { word: 'calve', word_class: 'V' },
    { word: 'calved', word_class: 'AJ' },
    { word: 'calving', word_class: 'N' }
  ],
  highlight: [
    { word: 'highlight', word_class: 'N' },
    { word: 'highlight', word_class: 'V' },
    { word: 'highlighting', word_class: 'N' }
  ],
  ironwork: [
    { word: 'ironwork', word_class: 'N' },
    { word: 'ironworks', word_class: 'N' },
    { word: 'ironworker', word_class: 'N' }
  ],
  assiduously: [
    { word: 'assiduity', word_class: 'N' },
    { word: 'assiduous', word_class: 'AJ' },
    { word: 'assiduously', word_class: 'AV' },
    { word: 'assiduousness', word_class: 'N' }
  ],
  turgidity: [
    { word: 'turgid', word_class: 'AJ' },
    { word: 'turgidity', word_class: 'N' },
    { word: 'turgidness', word_class: 'N' }
  ],
  adequate: [
    { word: 'adequacy', word_class: 'N' },
    { word: 'adequate', word_class: 'AJ' },
    { word: 'adequately', word_class: 'AV' },
    { word: 'adequateness', word_class: 'N' }
  ],
  impenitence: [
    { word: 'impenitence', word_class: 'N' },
    { word: 'impenitency', word_class: 'N' },
    { word: 'impenitent', word_class: 'AJ' },
    { word: 'impenitently', word_class: 'AV' }
  ],
  elastication: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  actin: [
    { word: 'actin', word_class: 'N' },
    { word: 'actinal', word_class: 'AJ' },
    { word: 'actinic', word_class: 'AJ' },
    { word: 'actinism', word_class: 'N' }
  ],
  witchery: [
    { word: 'witch', word_class: 'N' },
    { word: 'witch', word_class: 'V' },
    { word: 'witchery', word_class: 'N' },
    { word: 'witching', word_class: 'AJ' }
  ],
  war: [
    { word: 'war', word_class: 'N' },
    { word: 'war', word_class: 'V' },
    { word: 'warship', word_class: 'N' },
    { word: 'warring', word_class: 'AJ' }
  ],
  oddness: [{ word: 'odd', word_class: 'AJ' }, { word: 'odds', word_class: 'N' }, { word: 'oddness', word_class: 'N' }],
  esthetic: [
    { word: 'esthete', word_class: 'N' },
    { word: 'esthetic', word_class: 'N' },
    { word: 'esthetic', word_class: 'AJ' },
    { word: 'esthetics', word_class: 'N' },
    { word: 'esthetical', word_class: 'AJ' },
    { word: 'esthetically', word_class: 'AV' }
  ],
  out: [
    { word: 'out', word_class: 'N' },
    { word: 'out', word_class: 'V' },
    { word: 'out', word_class: 'AJ' },
    { word: 'out', word_class: 'AV' },
    { word: 'outing', word_class: 'N' }
  ],
  publish: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  seven: [
    { word: 'seven', word_class: 'N' },
    { word: 'seven', word_class: 'AJ' },
    { word: 'sevens', word_class: 'N' },
    { word: 'sevener', word_class: 'N' }
  ],
  wild: [
    { word: 'wild', word_class: 'N' },
    { word: 'wild', word_class: 'AJ' },
    { word: 'wild', word_class: 'AV' },
    { word: 'wildness', word_class: 'N' }
  ],
  abridged: [
    { word: 'abridge', word_class: 'V' },
    { word: 'abridger', word_class: 'N' },
    { word: 'abridged', word_class: 'AJ' },
    { word: 'abridgment', word_class: 'N' },
    { word: 'abridgement', word_class: 'N' }
  ],
  configuration: [
    { word: 'configure', word_class: 'V' },
    { word: 'configured', word_class: 'AJ' },
    { word: 'configuration', word_class: 'N' },
    { word: 'configurational', word_class: 'AJ' }
  ],
  collide: [
    { word: 'collide', word_class: 'V' },
    { word: 'collider', word_class: 'N' },
    { word: 'collision', word_class: 'N' }
  ],
  autocratically: [
    { word: 'autocrat', word_class: 'N' },
    { word: 'autocracy', word_class: 'N' },
    { word: 'autocratic', word_class: 'AJ' },
    { word: 'autocratical', word_class: 'AJ' },
    { word: 'autocratically', word_class: 'AV' }
  ],
  involvement: [
    { word: 'involve', word_class: 'V' },
    { word: 'involved', word_class: 'AJ' },
    { word: 'involvement', word_class: 'N' }
  ],
  impregnable: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  nasality: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  uninitiated: [
    { word: 'uninitiate', word_class: 'N' },
    { word: 'uninitiate', word_class: 'AJ' },
    { word: 'uninitiated', word_class: 'AJ' }
  ],
  smear: [
    { word: 'smear', word_class: 'N' },
    { word: 'smear', word_class: 'V' },
    { word: 'smeared', word_class: 'AJ' }
  ],
  assayal: [
    { word: 'assay', word_class: 'N' },
    { word: 'assay', word_class: 'V' },
    { word: 'assai', word_class: 'AV' },
    { word: 'assayal', word_class: 'N' }
  ],
  twine: [{ word: 'twine', word_class: 'N' }, { word: 'twine', word_class: 'V' }, { word: 'twined', word_class: 'AJ' }],
  bacteroides: [
    { word: 'bacteroid', word_class: 'N' },
    { word: 'bacteroid', word_class: 'AJ' },
    { word: 'bacteroides', word_class: 'N' },
    { word: 'bacteroidal', word_class: 'AJ' }
  ],
  semitic: [
    { word: 'semite', word_class: 'N' },
    { word: 'semite', word_class: 'AJ' },
    { word: 'semitic', word_class: 'N' },
    { word: 'semitic', word_class: 'AJ' }
  ],
  offstage: [
    { word: 'offstage', word_class: 'N' },
    { word: 'offstage', word_class: 'AJ' },
    { word: 'offstage', word_class: 'AV' }
  ],
  flocculation: [
    { word: 'floccule', word_class: 'N' },
    { word: 'flocculate', word_class: 'V' },
    { word: 'flocculent', word_class: 'AJ' },
    { word: 'flocculation', word_class: 'N' }
  ],
  fatten: [
    { word: 'fatten', word_class: 'V' },
    { word: 'fattened', word_class: 'AJ' },
    { word: 'fattening', word_class: 'AJ' }
  ],
  coarsening: [
    { word: 'coarsen', word_class: 'V' },
    { word: 'coarsened', word_class: 'AJ' },
    { word: 'coarsening', word_class: 'N' }
  ],
  performance: [
    { word: 'perform', word_class: 'V' },
    { word: 'performer', word_class: 'N' },
    { word: 'performing', word_class: 'N' },
    { word: 'performance', word_class: 'N' }
  ],
  unremarked: [
    { word: 'unremarked', word_class: 'AJ' },
    { word: 'unremarkable', word_class: 'AJ' },
    { word: 'unremarkably', word_class: 'AV' }
  ],
  imitable: [
    { word: 'imitate', word_class: 'V' },
    { word: 'imitator', word_class: 'N' },
    { word: 'imitable', word_class: 'AJ' },
    { word: 'imitation', word_class: 'N' },
    { word: 'imitation', word_class: 'AJ' },
    { word: 'imitative', word_class: 'AJ' }
  ],
  dimensioning: [
    { word: 'dimension', word_class: 'N' },
    { word: 'dimension', word_class: 'V' },
    { word: 'dimensioning', word_class: 'AJ' }
  ],
  alternative: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  muted: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  community: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  fixture: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  forgive: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  sulphurize: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  yelled: [
    { word: 'yell', word_class: 'N' },
    { word: 'yell', word_class: 'V' },
    { word: 'yeller', word_class: 'N' },
    { word: 'yelled', word_class: 'AJ' },
    { word: 'yelling', word_class: 'N' },
    { word: 'yelling', word_class: 'AJ' }
  ],
  improving: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  research: [
    { word: 'research', word_class: 'N' },
    { word: 'research', word_class: 'V' },
    { word: 'researcher', word_class: 'N' },
    { word: 'researchable', word_class: 'AJ' }
  ],
  maze: [
    { word: 'maze', word_class: 'N' },
    { word: 'maze', word_class: 'V' },
    { word: 'mazed', word_class: 'AJ' },
    { word: 'mazement', word_class: 'N' }
  ],
  prognosticator: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  sawn: [
    { word: 'saw', word_class: 'N' },
    { word: 'saw', word_class: 'V' },
    { word: 'sawn', word_class: 'AJ' },
    { word: 'sawyer', word_class: 'N' }
  ],
  diffuse: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  surely: [
    { word: 'sure', word_class: 'AJ' },
    { word: 'sure', word_class: 'AV' },
    { word: 'surety', word_class: 'N' },
    { word: 'surely', word_class: 'AV' },
    { word: 'sureness', word_class: 'N' }
  ],
  final: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  fist: [
    { word: 'fist', word_class: 'N' },
    { word: 'fistful', word_class: 'N' },
    { word: 'fistful', word_class: 'AJ' }
  ],
  appearance: [
    { word: 'appear', word_class: 'V' },
    { word: 'appearing', word_class: 'N' },
    { word: 'appearance', word_class: 'N' }
  ],
  enfeebled: [
    { word: 'enfeeble', word_class: 'V' },
    { word: 'enfeebled', word_class: 'AJ' },
    { word: 'enfeebling', word_class: 'AJ' },
    { word: 'enfeeblement', word_class: 'N' }
  ],
  conjoined: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  pliantness: [
    { word: 'ply', word_class: 'N' },
    { word: 'ply', word_class: 'V' },
    { word: 'pliant', word_class: 'N' },
    { word: 'pliancy', word_class: 'N' },
    { word: 'pliant', word_class: 'AJ' },
    { word: 'pliantness', word_class: 'N' }
  ],
  immaterialize: [
    { word: 'immaterial', word_class: 'AJ' },
    { word: 'immateriality', word_class: 'N' },
    { word: 'immaterialize', word_class: 'V' },
    { word: 'immaterialization', word_class: 'N' }
  ],
  autocrat: [
    { word: 'autocrat', word_class: 'N' },
    { word: 'autocracy', word_class: 'N' },
    { word: 'autocratic', word_class: 'AJ' },
    { word: 'autocratical', word_class: 'AJ' },
    { word: 'autocratically', word_class: 'AV' }
  ],
  cantonment: [
    { word: 'canton', word_class: 'N' },
    { word: 'canton', word_class: 'V' },
    { word: 'cantonal', word_class: 'AJ' },
    { word: 'cantonment', word_class: 'N' }
  ],
  rascally: [
    { word: 'rascal', word_class: 'N' },
    { word: 'rascality', word_class: 'N' },
    { word: 'rascally', word_class: 'AJ' }
  ],
  wainscoted: [
    { word: 'wainscot', word_class: 'N' },
    { word: 'wainscot', word_class: 'V' },
    { word: 'wainscoted', word_class: 'AJ' },
    { word: 'wainscoting', word_class: 'N' },
    { word: 'wainscotting', word_class: 'N' }
  ],
  solo: [{ word: 'solo', word_class: 'N' }, { word: 'solo', word_class: 'V' }, { word: 'soloist', word_class: 'N' }],
  mightiness: [
    { word: 'mighty', word_class: 'AJ' },
    { word: 'mighty', word_class: 'AV' },
    { word: 'mightiness', word_class: 'N' }
  ],
  munificent: [
    { word: 'munificence', word_class: 'N' },
    { word: 'munificent', word_class: 'AJ' },
    { word: 'munificently', word_class: 'AV' }
  ],
  location: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  inflater: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  salinate: [
    { word: 'saline', word_class: 'N' },
    { word: 'saline', word_class: 'AJ' },
    { word: 'salinate', word_class: 'V' },
    { word: 'salinity', word_class: 'N' }
  ],
  death: [
    { word: 'dy', word_class: 'N' },
    { word: 'die', word_class: 'N' },
    { word: 'die', word_class: 'V' },
    { word: 'death', word_class: 'N' },
    { word: 'dyed', word_class: 'AJ' },
    { word: 'dying', word_class: 'V' },
    { word: 'dying', word_class: 'N' },
    { word: 'dying', word_class: 'AJ' }
  ],
  volumetrical: [
    { word: 'volumetric', word_class: 'AJ' },
    { word: 'volumetrical', word_class: 'AJ' },
    { word: 'volumetrically', word_class: 'AV' }
  ],
  asteroidal: [
    { word: 'asteroid', word_class: 'N' },
    { word: 'asteroid', word_class: 'AJ' },
    { word: 'asteroidal', word_class: 'AJ' }
  ],
  reclination: [
    { word: 'recline', word_class: 'N' },
    { word: 'recline', word_class: 'V' },
    { word: 'recliner', word_class: 'N' },
    { word: 'reclining', word_class: 'N' },
    { word: 'reclining', word_class: 'AJ' },
    { word: 'reclination', word_class: 'N' }
  ],
  monarchism: [
    { word: 'monarch', word_class: 'N' },
    { word: 'monarchal', word_class: 'AJ' },
    { word: 'monarchic', word_class: 'AJ' },
    { word: 'monarchism', word_class: 'N' },
    { word: 'monarchical', word_class: 'AJ' }
  ],
  osmosis: [
    { word: 'osmose', word_class: 'V' },
    { word: 'osmosis', word_class: 'N' },
    { word: 'osmotic', word_class: 'AJ' },
    { word: 'osmotically', word_class: 'AV' }
  ],
  rental: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  nasalization: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  impregnant: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  directness: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  stapled: [
    { word: 'staple', word_class: 'N' },
    { word: 'staple', word_class: 'V' },
    { word: 'staple', word_class: 'AJ' },
    { word: 'stapler', word_class: 'N' },
    { word: 'stapled', word_class: 'AJ' }
  ],
  caliper: [
    { word: 'caliper', word_class: 'N' },
    { word: 'caliper', word_class: 'V' },
    { word: 'calipers', word_class: 'N' }
  ],
  sublimed: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  blasted: [
    { word: 'blast', word_class: 'N' },
    { word: 'blast', word_class: 'V' },
    { word: 'blasted', word_class: 'AJ' },
    { word: 'blasting', word_class: 'AJ' }
  ],
  independent: [
    { word: 'independent', word_class: 'N' },
    { word: 'independence', word_class: 'N' },
    { word: 'independent', word_class: 'AJ' },
    { word: 'independency', word_class: 'N' },
    { word: 'independently', word_class: 'AV' }
  ],
  representation: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  gui: [{ word: 'gui', word_class: 'N' }, { word: 'guy', word_class: 'N' }, { word: 'guy', word_class: 'V' }],
  moderator: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  arbitrator: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  alcoholism: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  ingratiation: [
    { word: 'ingratiate', word_class: 'V' },
    { word: 'ingratiation', word_class: 'N' },
    { word: 'ingratiating', word_class: 'AJ' }
  ],
  antagonistically: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  plan: [
    { word: 'plan', word_class: 'N' },
    { word: 'plan', word_class: 'V' },
    { word: 'planned', word_class: 'AJ' },
    { word: 'planning', word_class: 'N' }
  ],
  bejewel: [
    { word: 'bejewel', word_class: 'V' },
    { word: 'bejeweled', word_class: 'AJ' },
    { word: 'bejewelled', word_class: 'AJ' }
  ],
  amenable: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  attemptable: [
    { word: 'attempt', word_class: 'N' },
    { word: 'attempt', word_class: 'V' },
    { word: 'attempted', word_class: 'AJ' },
    { word: 'attemptable', word_class: 'AJ' }
  ],
  sinusoidal: [
    { word: 'sinusoid', word_class: 'N' },
    { word: 'sinusoidal', word_class: 'AJ' },
    { word: 'sinusoidally', word_class: 'AV' }
  ],
  additive: [
    { word: 'add', word_class: 'V' },
    { word: 'adder', word_class: 'N' },
    { word: 'addition', word_class: 'N' },
    { word: 'additive', word_class: 'N' },
    { word: 'additive', word_class: 'AJ' },
    { word: 'additional', word_class: 'AJ' }
  ],
  immunity: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  impendency: [
    { word: 'impend', word_class: 'V' },
    { word: 'impendence', word_class: 'N' },
    { word: 'impendency', word_class: 'N' },
    { word: 'impending', word_class: 'AJ' }
  ],
  pacifying: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  do: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  neoliberal: [
    { word: 'neoliberal', word_class: 'N' },
    { word: 'neoliberal', word_class: 'AJ' },
    { word: 'neoliberalism', word_class: 'N' }
  ],
  seep: [
    { word: 'seep', word_class: 'V' },
    { word: 'seepage', word_class: 'N' },
    { word: 'seeping', word_class: 'AJ' }
  ],
  hesitator: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  unselfconsciously: [
    { word: 'unselfconscious', word_class: 'AJ' },
    { word: 'unselfconsciously', word_class: 'AV' },
    { word: 'unselfconsciousness', word_class: 'N' }
  ],
  vesical: [
    { word: 'vesical', word_class: 'AJ' },
    { word: 'vesicant', word_class: 'N' },
    { word: 'vesicate', word_class: 'V' },
    { word: 'vesicant', word_class: 'AJ' },
    { word: 'vesication', word_class: 'N' }
  ],
  initiative: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  calculative: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  achievement: [
    { word: 'achieve', word_class: 'V' },
    { word: 'achiever', word_class: 'N' },
    { word: 'achievable', word_class: 'AJ' },
    { word: 'achievement', word_class: 'N' },
    { word: 'achievability', word_class: 'N' }
  ],
  crawler: [
    { word: 'crawl', word_class: 'N' },
    { word: 'crawl', word_class: 'V' },
    { word: 'crawler', word_class: 'N' },
    { word: 'crawling', word_class: 'N' },
    { word: 'crawling', word_class: 'AJ' }
  ],
  mottle: [
    { word: 'mottle', word_class: 'N' },
    { word: 'mottle', word_class: 'V' },
    { word: 'mottled', word_class: 'AJ' },
    { word: 'mottling', word_class: 'N' }
  ],
  stranded: [
    { word: 'strand', word_class: 'N' },
    { word: 'strand', word_class: 'V' },
    { word: 'stranded', word_class: 'AJ' }
  ],
  embroiled: [
    { word: 'embroil', word_class: 'V' },
    { word: 'embroiled', word_class: 'AJ' },
    { word: 'embroilment', word_class: 'N' }
  ],
  peal: [{ word: 'peal', word_class: 'N' }, { word: 'peal', word_class: 'V' }, { word: 'pealing', word_class: 'N' }],
  barbarous: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  incisively: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  prevail: [
    { word: 'prevail', word_class: 'V' },
    { word: 'prevalence', word_class: 'N' },
    { word: 'prevalent', word_class: 'AJ' },
    { word: 'prevailing', word_class: 'AJ' }
  ],
  rash: [{ word: 'rash', word_class: 'N' }, { word: 'rash', word_class: 'AJ' }, { word: 'rashness', word_class: 'N' }],
  emancipation: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  spicy: [
    { word: 'spice', word_class: 'N' },
    { word: 'spice', word_class: 'V' },
    { word: 'spicy', word_class: 'AJ' },
    { word: 'spiciness', word_class: 'N' }
  ],
  edible: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  pretentiously: [
    { word: 'pretentious', word_class: 'AJ' },
    { word: 'pretentiously', word_class: 'AV' },
    { word: 'pretentiousness', word_class: 'N' }
  ],
  rheumatism: [
    { word: 'rheuma', word_class: 'N' },
    { word: 'rheumatic', word_class: 'N' },
    { word: 'rheumatic', word_class: 'AJ' },
    { word: 'rheumatism', word_class: 'N' }
  ],
  exaggeration: [
    { word: 'exaggerate', word_class: 'V' },
    { word: 'exaggerated', word_class: 'AJ' },
    { word: 'exaggeration', word_class: 'N' }
  ],
  electronically: [
    { word: 'electron', word_class: 'N' },
    { word: 'electronic', word_class: 'AJ' },
    { word: 'electronics', word_class: 'N' },
    { word: 'electronically', word_class: 'AV' }
  ],
  fugally: [
    { word: 'fugu', word_class: 'N' },
    { word: 'fugue', word_class: 'N' },
    { word: 'fugal', word_class: 'AJ' },
    { word: 'fugally', word_class: 'AV' }
  ],
  brachiopodous: [
    { word: 'brachiopod', word_class: 'N' },
    { word: 'brachiopod', word_class: 'AJ' },
    { word: 'brachiopodous', word_class: 'AJ' }
  ],
  busy: [
    { word: 'busy', word_class: 'V' },
    { word: 'busy', word_class: 'AJ' },
    { word: 'busying', word_class: 'N' },
    { word: 'business', word_class: 'N' },
    { word: 'busyness', word_class: 'N' },
    { word: 'business', word_class: 'AJ' }
  ],
  imbricated: [
    { word: 'imbricate', word_class: 'V' },
    { word: 'imbricate', word_class: 'AJ' },
    { word: 'imbricated', word_class: 'AJ' },
    { word: 'imbrication', word_class: 'N' }
  ],
  share: [
    { word: 'share', word_class: 'N' },
    { word: 'share', word_class: 'V' },
    { word: 'sharer', word_class: 'N' },
    { word: 'shared', word_class: 'AJ' },
    { word: 'sharing', word_class: 'N' },
    { word: 'sharing', word_class: 'AJ' }
  ],
  forbiddance: [
    { word: 'forbid', word_class: 'V' },
    { word: 'forbid', word_class: 'AJ' },
    { word: 'forbidden', word_class: 'AJ' },
    { word: 'forbidding', word_class: 'N' },
    { word: 'forbiddance', word_class: 'N' },
    { word: 'forbidding', word_class: 'AJ' }
  ],
  ring: [
    { word: 'ring', word_class: 'N' },
    { word: 'ring', word_class: 'V' },
    { word: 'rung', word_class: 'N' },
    { word: 'rung', word_class: 'AJ' },
    { word: 'ringer', word_class: 'N' },
    { word: 'ringed', word_class: 'AJ' },
    { word: 'ringing', word_class: 'N' },
    { word: 'ringing', word_class: 'AJ' }
  ],
  notional: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  ethnically: [
    { word: 'ethnic', word_class: 'AJ' },
    { word: 'ethnical', word_class: 'AJ' },
    { word: 'ethnicity', word_class: 'N' },
    { word: 'ethnically', word_class: 'AV' }
  ],
  thanking: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  codify: [
    { word: 'codify', word_class: 'V' },
    { word: 'codified', word_class: 'AJ' },
    { word: 'codification', word_class: 'N' }
  ],
  dose: [{ word: 'dose', word_class: 'N' }, { word: 'dose', word_class: 'V' }, { word: 'dosed', word_class: 'AJ' }],
  disastrous: [
    { word: 'disaster', word_class: 'N' },
    { word: 'disastrous', word_class: 'AJ' },
    { word: 'disastrously', word_class: 'AV' }
  ],
  overprotect: [
    { word: 'overprotect', word_class: 'V' },
    { word: 'overprotection', word_class: 'N' },
    { word: 'overprotective', word_class: 'AJ' }
  ],
  editorialist: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  convivially: [
    { word: 'convivial', word_class: 'AJ' },
    { word: 'conviviality', word_class: 'N' },
    { word: 'convivially', word_class: 'AV' }
  ],
  purification: [
    { word: 'purify', word_class: 'V' },
    { word: 'purifier', word_class: 'N' },
    { word: 'purified', word_class: 'AJ' },
    { word: 'purifying', word_class: 'AJ' },
    { word: 'purification', word_class: 'N' }
  ],
  redistribution: [
    { word: 'redistribute', word_class: 'V' },
    { word: 'redistributed', word_class: 'AJ' },
    { word: 'redistribution', word_class: 'N' }
  ],
  categorisation: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  spousal: [
    { word: 'spouse', word_class: 'N' },
    { word: 'spouse', word_class: 'V' },
    { word: 'spousal', word_class: 'N' },
    { word: 'spousal', word_class: 'AJ' }
  ],
  revengeful: [
    { word: 'revenge', word_class: 'N' },
    { word: 'revenge', word_class: 'V' },
    { word: 'revengeful', word_class: 'AJ' }
  ],
  apathetically: [
    { word: 'apathy', word_class: 'N' },
    { word: 'apathetic', word_class: 'AJ' },
    { word: 'apathetically', word_class: 'AV' }
  ],
  ploughing: [
    { word: 'plough', word_class: 'N' },
    { word: 'plough', word_class: 'V' },
    { word: 'ploughed', word_class: 'AJ' },
    { word: 'ploughing', word_class: 'N' },
    { word: 'ploughman', word_class: 'N' }
  ],
  chopped: [
    { word: 'chop', word_class: 'N' },
    { word: 'chop', word_class: 'V' },
    { word: 'chopped', word_class: 'AJ' }
  ],
  covalency: [
    { word: 'covalence', word_class: 'N' },
    { word: 'covalency', word_class: 'N' },
    { word: 'covalent', word_class: 'AJ' }
  ],
  forbidden: [
    { word: 'forbid', word_class: 'V' },
    { word: 'forbid', word_class: 'AJ' },
    { word: 'forbidden', word_class: 'AJ' },
    { word: 'forbidding', word_class: 'N' },
    { word: 'forbiddance', word_class: 'N' },
    { word: 'forbidding', word_class: 'AJ' }
  ],
  hostilely: [
    { word: 'hostile', word_class: 'AJ' },
    { word: 'hostility', word_class: 'N' },
    { word: 'hostilely', word_class: 'AV' }
  ],
  outcaste: [
    { word: 'outcast', word_class: 'N' },
    { word: 'outcast', word_class: 'AJ' },
    { word: 'outcaste', word_class: 'N' },
    { word: 'outcaste', word_class: 'AJ' }
  ],
  unquestionableness: [
    { word: 'unquestionable', word_class: 'AJ' },
    { word: 'unquestionably', word_class: 'AV' },
    { word: 'unquestionability', word_class: 'N' },
    { word: 'unquestionableness', word_class: 'N' }
  ],
  irresponsibleness: [
    { word: 'irresponsible', word_class: 'AJ' },
    { word: 'irresponsibly', word_class: 'AV' },
    { word: 'irresponsibility', word_class: 'N' },
    { word: 'irresponsibleness', word_class: 'N' }
  ],
  proselytization: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  fortification: [
    { word: 'fortify', word_class: 'V' },
    { word: 'fortified', word_class: 'AJ' },
    { word: 'fortifying', word_class: 'AJ' },
    { word: 'fortification', word_class: 'N' }
  ],
  earnestness: [
    { word: 'earnest', word_class: 'N' },
    { word: 'earnest', word_class: 'AJ' },
    { word: 'earnestness', word_class: 'N' }
  ],
  impoverished: [
    { word: 'impoverish', word_class: 'V' },
    { word: 'impoverished', word_class: 'AJ' },
    { word: 'impoverishment', word_class: 'N' },
    { word: 'impovempoverish', word_class: 'V' },
    { word: 'impovempoverishment', word_class: 'N' }
  ],
  decimalization: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  harness: [
    { word: 'harness', word_class: 'N' },
    { word: 'harness', word_class: 'V' },
    { word: 'harnessed', word_class: 'AJ' }
  ],
  caving: [{ word: 'cave', word_class: 'N' }, { word: 'cave', word_class: 'V' }, { word: 'caving', word_class: 'N' }],
  entertain: [
    { word: 'entertain', word_class: 'V' },
    { word: 'entertainer', word_class: 'N' },
    { word: 'entertained', word_class: 'AJ' },
    { word: 'entertaining', word_class: 'AJ' },
    { word: 'entertainment', word_class: 'N' }
  ],
  operations: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  assaulted: [
    { word: 'assault', word_class: 'N' },
    { word: 'assault', word_class: 'V' },
    { word: 'assault', word_class: 'AJ' },
    { word: 'assaulted', word_class: 'AJ' },
    { word: 'assaultive', word_class: 'AJ' }
  ],
  unjustifiably: [
    { word: 'unjustified', word_class: 'AJ' },
    { word: 'unjustifiable', word_class: 'AJ' },
    { word: 'unjustifiably', word_class: 'AV' }
  ],
  agonized: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  disgusting: [
    { word: 'disgust', word_class: 'N' },
    { word: 'disgust', word_class: 'V' },
    { word: 'disgusted', word_class: 'AJ' },
    { word: 'disgustful', word_class: 'AJ' },
    { word: 'disgusting', word_class: 'AJ' }
  ],
  celebrate: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  actualisation: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  tamed: [
    { word: 'tame', word_class: 'V' },
    { word: 'tame', word_class: 'AJ' },
    { word: 'tamer', word_class: 'N' },
    { word: 'tamed', word_class: 'AJ' },
    { word: 'tamely', word_class: 'AV' },
    { word: 'tameness', word_class: 'N' }
  ],
  financed: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  throttling: [
    { word: 'throttle', word_class: 'N' },
    { word: 'throttle', word_class: 'V' },
    { word: 'throttling', word_class: 'N' }
  ],
  necessitous: [
    { word: 'necessity', word_class: 'N' },
    { word: 'necessitate', word_class: 'V' },
    { word: 'necessitous', word_class: 'AJ' },
    { word: 'necessitation', word_class: 'N' }
  ],
  shuffle: [
    { word: 'shuffle', word_class: 'N' },
    { word: 'shuffle', word_class: 'V' },
    { word: 'shuffler', word_class: 'N' },
    { word: 'shuffling', word_class: 'N' },
    { word: 'shuffling', word_class: 'AJ' }
  ],
  idling: [
    { word: 'idle', word_class: 'V' },
    { word: 'idle', word_class: 'AJ' },
    { word: 'idler', word_class: 'N' },
    { word: 'idly', word_class: 'AV' },
    { word: 'idling', word_class: 'N' },
    { word: 'idleness', word_class: 'N' }
  ],
  volcano: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  revolt: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  crazy: [
    { word: 'crazy', word_class: 'N' },
    { word: 'crazy', word_class: 'AJ' },
    { word: 'craziness', word_class: 'N' }
  ],
  turtlenecked: [
    { word: 'turtleneck', word_class: 'N' },
    { word: 'turtleneck', word_class: 'AJ' },
    { word: 'turtlenecked', word_class: 'AJ' }
  ],
  scribbler: [
    { word: 'scribble', word_class: 'N' },
    { word: 'scribble', word_class: 'V' },
    { word: 'scribbler', word_class: 'N' }
  ],
  thwart: [
    { word: 'thwart', word_class: 'N' },
    { word: 'thwart', word_class: 'V' },
    { word: 'thwarted', word_class: 'AJ' },
    { word: 'thwarting', word_class: 'N' },
    { word: 'thwarting', word_class: 'AJ' }
  ],
  civility: [
    { word: 'civil', word_class: 'AJ' },
    { word: 'civility', word_class: 'N' },
    { word: 'civilize', word_class: 'V' },
    { word: 'civilized', word_class: 'AJ' },
    { word: 'civilization', word_class: 'N' }
  ],
  published: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  manager: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  augmentation: [
    { word: 'augment', word_class: 'V' },
    { word: 'augmented', word_class: 'AJ' },
    { word: 'augmenting', word_class: 'AJ' },
    { word: 'augmentation', word_class: 'N' },
    { word: 'augmentative', word_class: 'AJ' }
  ],
  scoot: [{ word: 'scoot', word_class: 'N' }, { word: 'scoot', word_class: 'V' }, { word: 'scooter', word_class: 'N' }],
  disputation: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  fates: [
    { word: 'fate', word_class: 'N' },
    { word: 'fate', word_class: 'V' },
    { word: 'fates', word_class: 'N' },
    { word: 'fated', word_class: 'AJ' },
    { word: 'fateful', word_class: 'AJ' }
  ],
  encumber: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  husking: [{ word: 'husk', word_class: 'N' }, { word: 'husk', word_class: 'V' }, { word: 'husking', word_class: 'N' }],
  falsification: [
    { word: 'falsify', word_class: 'V' },
    { word: 'falsifier', word_class: 'N' },
    { word: 'falsifying', word_class: 'N' },
    { word: 'falsification', word_class: 'N' }
  ],
  abashed: [
    { word: 'abash', word_class: 'V' },
    { word: 'abashed', word_class: 'AJ' },
    { word: 'abashment', word_class: 'N' }
  ],
  dunker: [
    { word: 'dunk', word_class: 'N' },
    { word: 'dunk', word_class: 'V' },
    { word: 'dunker', word_class: 'N' },
    { word: 'dunked', word_class: 'AJ' },
    { word: 'dunkers', word_class: 'N' }
  ],
  redundant: [
    { word: 'redundance', word_class: 'N' },
    { word: 'redundancy', word_class: 'N' },
    { word: 'redundant', word_class: 'AJ' }
  ],
  eternal: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  machinist: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  fern: [{ word: 'fern', word_class: 'N' }, { word: 'ferned', word_class: 'AJ' }, { word: 'fernery', word_class: 'N' }],
  submerging: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  bank: [
    { word: 'bank', word_class: 'N' },
    { word: 'bank', word_class: 'V' },
    { word: 'banker', word_class: 'N' },
    { word: 'banking', word_class: 'N' }
  ],
  due: [{ word: 'due', word_class: 'N' }, { word: 'due', word_class: 'AJ' }, { word: 'due', word_class: 'AV' }],
  paganisation: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  philosophise: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  affecting: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  guidance: [
    { word: 'guide', word_class: 'N' },
    { word: 'guide', word_class: 'V' },
    { word: 'guided', word_class: 'AJ' },
    { word: 'guiding', word_class: 'N' },
    { word: 'guidance', word_class: 'N' },
    { word: 'guiding', word_class: 'AJ' }
  ],
  womanise: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  folded: [
    { word: 'fold', word_class: 'N' },
    { word: 'fold', word_class: 'V' },
    { word: 'fold', word_class: 'AJ' },
    { word: 'folder', word_class: 'N' },
    { word: 'folded', word_class: 'AJ' },
    { word: 'folding', word_class: 'N' },
    { word: 'folding', word_class: 'AJ' }
  ],
  tidy: [
    { word: 'tidy', word_class: 'N' },
    { word: 'tidy', word_class: 'V' },
    { word: 'tidy', word_class: 'AJ' },
    { word: 'tidiness', word_class: 'N' }
  ],
  assemblage: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  forecaster: [
    { word: 'forecast', word_class: 'N' },
    { word: 'forecast', word_class: 'V' },
    { word: 'forecaster', word_class: 'N' },
    { word: 'forecasting', word_class: 'N' }
  ],
  trammel: [
    { word: 'trammel', word_class: 'N' },
    { word: 'trammel', word_class: 'V' },
    { word: 'trammels', word_class: 'N' }
  ],
  dissolvent: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  fetch: [
    { word: 'fetch', word_class: 'N' },
    { word: 'fetch', word_class: 'V' },
    { word: 'fetching', word_class: 'AJ' }
  ],
  theorisation: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  chemisorption: [
    { word: 'chemisorb', word_class: 'V' },
    { word: 'chemisorption', word_class: 'N' },
    { word: 'chemisorptive', word_class: 'AJ' }
  ],
  whine: [
    { word: 'whine', word_class: 'N' },
    { word: 'whine', word_class: 'V' },
    { word: 'whiner', word_class: 'N' },
    { word: 'whiny', word_class: 'AJ' },
    { word: 'whining', word_class: 'AJ' }
  ],
  create: [
    { word: 'create', word_class: 'V' },
    { word: 'creator', word_class: 'N' },
    { word: 'creation', word_class: 'N' },
    { word: 'creationism', word_class: 'N' }
  ],
  hackles: [
    { word: 'hackle', word_class: 'N' },
    { word: 'hackle', word_class: 'V' },
    { word: 'hackles', word_class: 'N' }
  ],
  suborner: [
    { word: 'suborn', word_class: 'V' },
    { word: 'suborner', word_class: 'N' },
    { word: 'subornation', word_class: 'N' }
  ],
  diagonalize: [
    { word: 'diagonal', word_class: 'N' },
    { word: 'diagonal', word_class: 'AJ' },
    { word: 'diagonalize', word_class: 'V' },
    { word: 'diagonally', word_class: 'AV' },
    { word: 'diagonalization', word_class: 'N' }
  ],
  empirical: [
    { word: 'empire', word_class: 'N' },
    { word: 'empiric', word_class: 'AJ' },
    { word: 'empirical', word_class: 'AJ' },
    { word: 'empirically', word_class: 'AV' }
  ],
  homiletics: [
    { word: 'homiletic', word_class: 'AJ' },
    { word: 'homiletics', word_class: 'N' },
    { word: 'homiletical', word_class: 'AJ' }
  ],
  selling: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  anachronic: [
    { word: 'anachronic', word_class: 'AJ' },
    { word: 'anachronism', word_class: 'N' },
    { word: 'anachronous', word_class: 'AJ' }
  ],
  inconspicuously: [
    { word: 'inconspicuous', word_class: 'AJ' },
    { word: 'inconspicuously', word_class: 'AV' },
    { word: 'inconspicuousness', word_class: 'N' }
  ],
  youngness: [
    { word: 'young', word_class: 'N' },
    { word: 'young', word_class: 'AJ' },
    { word: 'youngness', word_class: 'N' }
  ],
  palsy: [
    { word: 'palsy', word_class: 'N' },
    { word: 'palsy', word_class: 'V' },
    { word: 'palsied', word_class: 'AJ' }
  ],
  cryptogam: [
    { word: 'cryptogam', word_class: 'N' },
    { word: 'cryptogamic', word_class: 'AJ' },
    { word: 'cryptogamous', word_class: 'AJ' }
  ],
  conquest: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  fragment: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  collimation: [
    { word: 'collimate', word_class: 'V' },
    { word: 'collimator', word_class: 'N' },
    { word: 'collimation', word_class: 'N' }
  ],
  crystallization: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  toxicologic: [
    { word: 'toxicology', word_class: 'N' },
    { word: 'toxicologic', word_class: 'AJ' },
    { word: 'toxicological', word_class: 'AJ' }
  ],
  historic: [
    { word: 'history', word_class: 'N' },
    { word: 'historic', word_class: 'AJ' },
    { word: 'historied', word_class: 'AJ' },
    { word: 'historical', word_class: 'AJ' },
    { word: 'historically', word_class: 'AV' }
  ],
  interstitial: [
    { word: 'interstice', word_class: 'N' },
    { word: 'interstice', word_class: 'V' },
    { word: 'interstitial', word_class: 'AJ' }
  ],
  admittible: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  catheterise: [
    { word: 'catheterise', word_class: 'V' },
    { word: 'catheterize', word_class: 'V' },
    { word: 'catheterisation', word_class: 'N' },
    { word: 'catheterization', word_class: 'N' }
  ],
  accession: [
    { word: 'access', word_class: 'N' },
    { word: 'access', word_class: 'V' },
    { word: 'accession', word_class: 'N' },
    { word: 'accession', word_class: 'V' },
    { word: 'accessible', word_class: 'AJ' },
    { word: 'accessibility', word_class: 'N' }
  ],
  branched: [
    { word: 'branch', word_class: 'N' },
    { word: 'branch', word_class: 'V' },
    { word: 'branched', word_class: 'AJ' },
    { word: 'branching', word_class: 'N' },
    { word: 'branching', word_class: 'AJ' },
    { word: 'embranchment', word_class: 'N' }
  ],
  monopolistic: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  dais: [
    { word: 'day', word_class: 'N' },
    { word: 'dais', word_class: 'N' },
    { word: 'days', word_class: 'N' },
    { word: 'daily', word_class: 'N' },
    { word: 'daily', word_class: 'AJ' },
    { word: 'daily', word_class: 'AV' }
  ],
  booked: [
    { word: 'book', word_class: 'N' },
    { word: 'book', word_class: 'V' },
    { word: 'booker', word_class: 'N' },
    { word: 'booked', word_class: 'AJ' },
    { word: 'booking', word_class: 'N' }
  ],
  scraps: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  incongruous: [
    { word: 'incongruity', word_class: 'N' },
    { word: 'incongruent', word_class: 'AJ' },
    { word: 'incongruous', word_class: 'AJ' },
    { word: 'incongruously', word_class: 'AV' },
    { word: 'incongruousness', word_class: 'N' }
  ],
  felicitate: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  fraction: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  characteristically: [
    { word: 'characteristic', word_class: 'N' },
    { word: 'characteristic', word_class: 'AJ' },
    { word: 'characteristically', word_class: 'AV' }
  ],
  happening: [
    { word: 'happen', word_class: 'V' },
    { word: 'happening', word_class: 'N' },
    { word: 'happening', word_class: 'AJ' }
  ],
  podzol: [
    { word: 'podzol', word_class: 'N' },
    { word: 'podzolize', word_class: 'V' },
    { word: 'podzolization', word_class: 'N' }
  ],
  controversy: [
    { word: 'controvert', word_class: 'V' },
    { word: 'controversy', word_class: 'N' },
    { word: 'controversion', word_class: 'N' },
    { word: 'controversial', word_class: 'AJ' },
    { word: 'controversially', word_class: 'AV' }
  ],
  westernization: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  sandaled: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  forgetfulness: [
    { word: 'forget', word_class: 'V' },
    { word: 'forgetful', word_class: 'AJ' },
    { word: 'forgotten', word_class: 'AJ' },
    { word: 'forgetfulness', word_class: 'N' }
  ],
  tutor: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  decision: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  caring: [
    { word: 'care', word_class: 'N' },
    { word: 'care', word_class: 'V' },
    { word: 'caring', word_class: 'N' },
    { word: 'caring', word_class: 'AJ' },
    { word: 'careful', word_class: 'AJ' },
    { word: 'carefulness', word_class: 'N' }
  ],
  socialisation: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  patrol: [
    { word: 'patrol', word_class: 'N' },
    { word: 'patrol', word_class: 'V' },
    { word: 'patroller', word_class: 'N' },
    { word: 'patrolman', word_class: 'N' },
    { word: 'patrolled', word_class: 'AJ' },
    { word: 'patrolling', word_class: 'N' },
    { word: 'patrolling', word_class: 'AJ' }
  ],
  mastoidal: [
    { word: 'mastoid', word_class: 'N' },
    { word: 'mastoid', word_class: 'AJ' },
    { word: 'mastoidal', word_class: 'AJ' },
    { word: 'mastoiditis', word_class: 'N' }
  ],
  egoist: [
    { word: 'egoist', word_class: 'N' },
    { word: 'egoistic', word_class: 'AJ' },
    { word: 'egoistical', word_class: 'AJ' }
  ],
  detoxify: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  elongate: [
    { word: 'elongate', word_class: 'V' },
    { word: 'elongate', word_class: 'AJ' },
    { word: 'elongated', word_class: 'AJ' },
    { word: 'elongation', word_class: 'N' }
  ],
  indefatigableness: [
    { word: 'indefatigable', word_class: 'AJ' },
    { word: 'indefatigably', word_class: 'AV' },
    { word: 'indefatigation', word_class: 'N' },
    { word: 'indefatigability', word_class: 'N' },
    { word: 'indefatigableness', word_class: 'N' }
  ],
  droopy: [
    { word: 'droop', word_class: 'N' },
    { word: 'droop', word_class: 'V' },
    { word: 'droopy', word_class: 'AJ' },
    { word: 'drooping', word_class: 'AJ' }
  ],
  concede: [
    { word: 'concede', word_class: 'V' },
    { word: 'conceded', word_class: 'AJ' },
    { word: 'conceding', word_class: 'N' },
    { word: 'conceding', word_class: 'AJ' },
    { word: 'concession', word_class: 'N' },
    { word: 'concessive', word_class: 'AJ' }
  ],
  novel: [
    { word: 'novel', word_class: 'N' },
    { word: 'novel', word_class: 'AJ' },
    { word: 'novelty', word_class: 'N' },
    { word: 'novelize', word_class: 'V' },
    { word: 'novelties', word_class: 'N' },
    { word: 'novelization', word_class: 'N' }
  ],
  fifties: [
    { word: 'fifty', word_class: 'N' },
    { word: 'fifty', word_class: 'AJ' },
    { word: 'fifties', word_class: 'N' }
  ],
  chant: [
    { word: 'chant', word_class: 'N' },
    { word: 'chant', word_class: 'V' },
    { word: 'chanter', word_class: 'N' },
    { word: 'chantry', word_class: 'N' },
    { word: 'chanted', word_class: 'AJ' },
    { word: 'chanting', word_class: 'N' }
  ],
  vagina: [
    { word: 'vagina', word_class: 'N' },
    { word: 'vaginal', word_class: 'AJ' },
    { word: 'vaginitis', word_class: 'N' }
  ],
  malleability: [
    { word: 'malleable', word_class: 'AJ' },
    { word: 'malleably', word_class: 'AV' },
    { word: 'malleability', word_class: 'N' }
  ],
  climactic: [
    { word: 'climax', word_class: 'N' },
    { word: 'climax', word_class: 'V' },
    { word: 'climactic', word_class: 'AJ' },
    { word: 'climactical', word_class: 'AJ' }
  ],
  dramatically: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  calcification: [
    { word: 'calcify', word_class: 'V' },
    { word: 'calcific', word_class: 'AJ' },
    { word: 'calcification', word_class: 'N' }
  ],
  decry: [{ word: 'decry', word_class: 'N' }, { word: 'decry', word_class: 'V' }, { word: 'decrial', word_class: 'N' }],
  spurt: [
    { word: 'spurt', word_class: 'N' },
    { word: 'spurt', word_class: 'V' },
    { word: 'spurting', word_class: 'AJ' }
  ],
  somnambulation: [
    { word: 'somnambulate', word_class: 'V' },
    { word: 'somnambulism', word_class: 'N' },
    { word: 'somnambulance', word_class: 'N' },
    { word: 'somnambulation', word_class: 'N' }
  ],
  confidently: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  sociable: [
    { word: 'sociable', word_class: 'N' },
    { word: 'sociable', word_class: 'AJ' },
    { word: 'sociably', word_class: 'AV' },
    { word: 'sociability', word_class: 'N' },
    { word: 'sociableness', word_class: 'N' }
  ],
  frontal: [
    { word: 'front', word_class: 'N' },
    { word: 'front', word_class: 'V' },
    { word: 'front', word_class: 'AJ' },
    { word: 'frontal', word_class: 'N' },
    { word: 'frontage', word_class: 'N' },
    { word: 'frontal', word_class: 'AJ' },
    { word: 'frontally', word_class: 'AV' }
  ],
  heed: [
    { word: 'heed', word_class: 'N' },
    { word: 'heed', word_class: 'V' },
    { word: 'heedful', word_class: 'AJ' },
    { word: 'heedfulness', word_class: 'N' }
  ],
  welcome: [
    { word: 'welcome', word_class: 'N' },
    { word: 'welcome', word_class: 'V' },
    { word: 'welcome', word_class: 'AJ' },
    { word: 'welcomed', word_class: 'AJ' },
    { word: 'welcoming', word_class: 'N' },
    { word: 'welcoming', word_class: 'AJ' }
  ],
  infested: [
    { word: 'infest', word_class: 'N' },
    { word: 'infest', word_class: 'V' },
    { word: 'infested', word_class: 'AJ' },
    { word: 'infestation', word_class: 'N' }
  ],
  abdominousness: [
    { word: 'abdomen', word_class: 'N' },
    { word: 'abdominal', word_class: 'AJ' },
    { word: 'abdominous', word_class: 'AJ' },
    { word: 'abdominousness', word_class: 'N' }
  ],
  geminate: [
    { word: 'geminate', word_class: 'V' },
    { word: 'geminate', word_class: 'AJ' },
    { word: 'gemination', word_class: 'N' }
  ],
  enfold: [
    { word: 'enfold', word_class: 'V' },
    { word: 'enfolding', word_class: 'N' },
    { word: 'enfoldment', word_class: 'N' }
  ],
  pursuing: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  despicably: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  ideographically: [
    { word: 'ideograph', word_class: 'N' },
    { word: 'ideographic', word_class: 'AJ' },
    { word: 'ideographically', word_class: 'AV' }
  ],
  enforcement: [
    { word: 'enforce', word_class: 'V' },
    { word: 'enforcer', word_class: 'N' },
    { word: 'enforced', word_class: 'AJ' },
    { word: 'enforcement', word_class: 'N' },
    { word: 'enforceable', word_class: 'AJ' }
  ],
  rimy: [
    { word: 'rime', word_class: 'N' },
    { word: 'rime', word_class: 'V' },
    { word: 'rimy', word_class: 'AJ' },
    { word: 'rimed', word_class: 'AJ' },
    { word: 'riming', word_class: 'AJ' }
  ],
  falconry: [
    { word: 'falcon', word_class: 'N' },
    { word: 'falcon', word_class: 'V' },
    { word: 'falconer', word_class: 'N' },
    { word: 'falconry', word_class: 'N' }
  ],
  fluctuation: [
    { word: 'fluctuate', word_class: 'V' },
    { word: 'fluctuation', word_class: 'N' },
    { word: 'fluctuating', word_class: 'AJ' }
  ],
  moisture: [
    { word: 'moist', word_class: 'AJ' },
    { word: 'moisture', word_class: 'N' },
    { word: 'moistness', word_class: 'N' },
    { word: 'moisturize', word_class: 'V' }
  ],
  ridden: [
    { word: 'ride', word_class: 'N' },
    { word: 'ride', word_class: 'V' },
    { word: 'rider', word_class: 'N' },
    { word: 'riding', word_class: 'N' },
    { word: 'ridden', word_class: 'AJ' },
    { word: 'riding', word_class: 'AJ' }
  ],
  sanely: [
    { word: 'sane', word_class: 'AJ' },
    { word: 'sanely', word_class: 'AV' },
    { word: 'saneness', word_class: 'N' }
  ],
  pore: [
    { word: 'pore', word_class: 'N' },
    { word: 'pore', word_class: 'V' },
    { word: 'porose', word_class: 'AJ' },
    { word: 'porosity', word_class: 'N' }
  ],
  nineties: [
    { word: 'ninety', word_class: 'N' },
    { word: 'ninety', word_class: 'AJ' },
    { word: 'nineties', word_class: 'N' }
  ],
  withers: [
    { word: 'wither', word_class: 'V' },
    { word: 'withers', word_class: 'N' },
    { word: 'withered', word_class: 'AJ' },
    { word: 'withering', word_class: 'N' },
    { word: 'withering', word_class: 'AJ' }
  ],
  fashioning: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  green: [
    { word: 'green', word_class: 'N' },
    { word: 'green', word_class: 'V' },
    { word: 'green', word_class: 'AJ' },
    { word: 'greens', word_class: 'N' },
    { word: 'greenery', word_class: 'N' },
    { word: 'greening', word_class: 'N' },
    { word: 'greening', word_class: 'AJ' },
    { word: 'greenness', word_class: 'N' }
  ],
  hoarding: [
    { word: 'hoard', word_class: 'N' },
    { word: 'hoard', word_class: 'V' },
    { word: 'hoarder', word_class: 'N' },
    { word: 'hoarding', word_class: 'N' }
  ],
  savory: [
    { word: 'savory', word_class: 'N' },
    { word: 'savory', word_class: 'AJ' },
    { word: 'savoriness', word_class: 'N' }
  ],
  immaterial: [
    { word: 'immaterial', word_class: 'AJ' },
    { word: 'immateriality', word_class: 'N' },
    { word: 'immaterialize', word_class: 'V' },
    { word: 'immaterialization', word_class: 'N' }
  ],
  revolve: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  eruptive: [
    { word: 'erupt', word_class: 'V' },
    { word: 'eruption', word_class: 'N' },
    { word: 'erupting', word_class: 'AJ' },
    { word: 'eruptive', word_class: 'AJ' }
  ],
  charitably: [
    { word: 'charitable', word_class: 'AJ' },
    { word: 'charitably', word_class: 'AV' },
    { word: 'charitableness', word_class: 'N' }
  ],
  selectman: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  satan: [
    { word: 'satan', word_class: 'N' },
    { word: 'satanic', word_class: 'AJ' },
    { word: 'satanism', word_class: 'N' }
  ],
  attentional: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  exultation: [
    { word: 'exult', word_class: 'V' },
    { word: 'exultant', word_class: 'N' },
    { word: 'exultance', word_class: 'N' },
    { word: 'exultant', word_class: 'AJ' },
    { word: 'exulting', word_class: 'AJ' },
    { word: 'exultation', word_class: 'N' }
  ],
  solace: [
    { word: 'solace', word_class: 'N' },
    { word: 'solace', word_class: 'V' },
    { word: 'solacement', word_class: 'N' }
  ],
  empowered: [
    { word: 'empower', word_class: 'V' },
    { word: 'empowered', word_class: 'AJ' },
    { word: 'empowerment', word_class: 'N' }
  ],
  caption: [
    { word: 'cap', word_class: 'N' },
    { word: 'cap', word_class: 'V' },
    { word: 'capful', word_class: 'N' },
    { word: 'caption', word_class: 'N' },
    { word: 'capful', word_class: 'AJ' },
    { word: 'capped', word_class: 'AJ' },
    { word: 'caption', word_class: 'V' }
  ],
  erect: [
    { word: 'erect', word_class: 'V' },
    { word: 'erect', word_class: 'AJ' },
    { word: 'erecting', word_class: 'N' },
    { word: 'erection', word_class: 'N' },
    { word: 'erectness', word_class: 'N' }
  ],
  entomological: [
    { word: 'entomology', word_class: 'N' },
    { word: 'entomologic', word_class: 'AJ' },
    { word: 'entomological', word_class: 'AJ' }
  ],
  conceptuality: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  exhaled: [
    { word: 'exhale', word_class: 'V' },
    { word: 'exhale', word_class: 'AJ' },
    { word: 'exhaled', word_class: 'AJ' },
    { word: 'exhaling', word_class: 'AJ' },
    { word: 'exhalation', word_class: 'N' }
  ],
  price: [
    { word: 'price', word_class: 'N' },
    { word: 'price', word_class: 'V' },
    { word: 'pricy', word_class: 'AJ' },
    { word: 'priced', word_class: 'AJ' },
    { word: 'pricing', word_class: 'N' }
  ],
  unchangeable: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  germanic: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  multifariously: [
    { word: 'multifarious', word_class: 'AJ' },
    { word: 'multifariously', word_class: 'AV' },
    { word: 'multifariousness', word_class: 'N' }
  ],
  trappings: [
    { word: 'trap', word_class: 'N' },
    { word: 'trap', word_class: 'V' },
    { word: 'trapped', word_class: 'AJ' },
    { word: 'trapping', word_class: 'N' },
    { word: 'trappings', word_class: 'N' }
  ],
  ragee: [
    { word: 'rage', word_class: 'N' },
    { word: 'rage', word_class: 'V' },
    { word: 'ragee', word_class: 'N' },
    { word: 'raging', word_class: 'AJ' }
  ],
  flow: [
    { word: 'flow', word_class: 'N' },
    { word: 'flow', word_class: 'V' },
    { word: 'flowing', word_class: 'N' },
    { word: 'flowing', word_class: 'AJ' }
  ],
  visual: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  inefficacy: [
    { word: 'inefficacy', word_class: 'N' },
    { word: 'inefficacious', word_class: 'AJ' },
    { word: 'inefficaciously', word_class: 'AV' },
    { word: 'inefficaciousness', word_class: 'N' }
  ],
  relatively: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  light: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  relapsing: [
    { word: 'relapse', word_class: 'N' },
    { word: 'relapse', word_class: 'V' },
    { word: 'relapsing', word_class: 'N' }
  ],
  anecdotal: [
    { word: 'anecdote', word_class: 'N' },
    { word: 'anecdotal', word_class: 'AJ' },
    { word: 'anecdotic', word_class: 'AJ' },
    { word: 'anecdotical', word_class: 'AJ' }
  ],
  supervise: [
    { word: 'supervise', word_class: 'N' },
    { word: 'supervise', word_class: 'V' },
    { word: 'supervisor', word_class: 'N' },
    { word: 'supervised', word_class: 'AJ' },
    { word: 'supervising', word_class: 'N' },
    { word: 'supervision', word_class: 'N' }
  ],
  hustler: [
    { word: 'hustle', word_class: 'N' },
    { word: 'hustle', word_class: 'V' },
    { word: 'hustler', word_class: 'N' }
  ],
  obstreperous: [
    { word: 'obstreperous', word_class: 'AJ' },
    { word: 'obstreperously', word_class: 'AV' },
    { word: 'obstreperousness', word_class: 'N' }
  ],
  musicality: [
    { word: 'music', word_class: 'N' },
    { word: 'musical', word_class: 'N' },
    { word: 'musical', word_class: 'AJ' },
    { word: 'musicality', word_class: 'N' },
    { word: 'musically', word_class: 'AV' },
    { word: 'musicalness', word_class: 'N' }
  ],
  international: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  causation: [
    { word: 'cause', word_class: 'N' },
    { word: 'cause', word_class: 'V' },
    { word: 'causing', word_class: 'N' },
    { word: 'causation', word_class: 'N' },
    { word: 'causative', word_class: 'AJ' }
  ],
  blacken: [
    { word: 'blacken', word_class: 'V' },
    { word: 'blackened', word_class: 'AJ' },
    { word: 'blackening', word_class: 'N' }
  ],
  variolisation: [
    { word: 'variolate', word_class: 'V' },
    { word: 'variolation', word_class: 'N' },
    { word: 'variolisation', word_class: 'N' },
    { word: 'variolization', word_class: 'N' }
  ],
  entrustment: [
    { word: 'entrust', word_class: 'V' },
    { word: 'intrust', word_class: 'V' },
    { word: 'entrustment', word_class: 'N' },
    { word: 'intrustment', word_class: 'N' }
  ],
  seen: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  condemnatory: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  communistic: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  defensive: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  indicant: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  piquance: [
    { word: 'pique', word_class: 'N' },
    { word: 'pique', word_class: 'V' },
    { word: 'pique', word_class: 'AJ' },
    { word: 'piquance', word_class: 'N' },
    { word: 'piquancy', word_class: 'N' }
  ],
  arced: [{ word: 'arc', word_class: 'N' }, { word: 'arc', word_class: 'V' }, { word: 'arced', word_class: 'AJ' }],
  ethnographic: [
    { word: 'ethnographer', word_class: 'N' },
    { word: 'ethnographic', word_class: 'AJ' },
    { word: 'ethnographical', word_class: 'AJ' }
  ],
  cancellate: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  forbearing: [
    { word: 'forbear', word_class: 'N' },
    { word: 'forbear', word_class: 'V' },
    { word: 'forborne', word_class: 'AJ' },
    { word: 'forbearance', word_class: 'N' },
    { word: 'forbearing', word_class: 'AJ' }
  ],
  spoiling: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  actor: [
    { word: 'act', word_class: 'N' },
    { word: 'act', word_class: 'V' },
    { word: 'acts', word_class: 'N' },
    { word: 'actor', word_class: 'N' },
    { word: 'acting', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'actress', word_class: 'N' }
  ],
  sissiness: [
    { word: 'sissy', word_class: 'N' },
    { word: 'sissy', word_class: 'AJ' },
    { word: 'sissiness', word_class: 'N' }
  ],
  edental: [
    { word: 'edental', word_class: 'AJ' },
    { word: 'edentate', word_class: 'N' },
    { word: 'edentate', word_class: 'AJ' }
  ],
  pin: [
    { word: 'pin', word_class: 'N' },
    { word: 'pin', word_class: 'V' },
    { word: 'pinned', word_class: 'AJ' },
    { word: 'pinning', word_class: 'N' }
  ],
  bunker: [
    { word: 'bunk', word_class: 'N' },
    { word: 'bunk', word_class: 'V' },
    { word: 'bunker', word_class: 'N' },
    { word: 'bunker', word_class: 'V' }
  ],
  penitential: [
    { word: 'penitent', word_class: 'N' },
    { word: 'penitence', word_class: 'N' },
    { word: 'penitent', word_class: 'AJ' },
    { word: 'penitently', word_class: 'AV' },
    { word: 'penitential', word_class: 'AJ' },
    { word: 'penitentially', word_class: 'AV' }
  ],
  obliquely: [
    { word: 'oblique', word_class: 'N' },
    { word: 'oblique', word_class: 'AJ' },
    { word: 'obliquity', word_class: 'N' },
    { word: 'obliquely', word_class: 'AV' },
    { word: 'obliqueness', word_class: 'N' }
  ],
  premier: [
    { word: 'premier', word_class: 'N' },
    { word: 'premier', word_class: 'V' },
    { word: 'premiere', word_class: 'N' },
    { word: 'premier', word_class: 'AJ' },
    { word: 'premiere', word_class: 'V' },
    { word: 'premiere', word_class: 'AJ' },
    { word: 'premiership', word_class: 'N' }
  ],
  raven: [
    { word: 'raven', word_class: 'N' },
    { word: 'raven', word_class: 'V' },
    { word: 'ravening', word_class: 'AJ' },
    { word: 'ravenous', word_class: 'AJ' },
    { word: 'ravenously', word_class: 'AV' },
    { word: 'ravenousness', word_class: 'N' }
  ],
  oil: [
    { word: 'oil', word_class: 'N' },
    { word: 'oil', word_class: 'V' },
    { word: 'oiler', word_class: 'N' },
    { word: 'oiled', word_class: 'AJ' },
    { word: 'oilman', word_class: 'N' }
  ],
  enormous: [
    { word: 'enormity', word_class: 'N' },
    { word: 'enormous', word_class: 'AJ' },
    { word: 'enormously', word_class: 'AV' },
    { word: 'enormousness', word_class: 'N' }
  ],
  urbanise: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  canonisation: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  periphery: [
    { word: 'periphery', word_class: 'N' },
    { word: 'peripheral', word_class: 'N' },
    { word: 'peripheral', word_class: 'AJ' },
    { word: 'peripherally', word_class: 'AV' }
  ],
  desecrate: [
    { word: 'desecrate', word_class: 'V' },
    { word: 'desecrated', word_class: 'AJ' },
    { word: 'desecration', word_class: 'N' }
  ],
  cuteness: [
    { word: 'cute', word_class: 'AJ' },
    { word: 'cutely', word_class: 'AV' },
    { word: 'cuteness', word_class: 'N' }
  ],
  insolation: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  thermoelectric: [
    { word: 'thermoelectric', word_class: 'AJ' },
    { word: 'thermoelectrical', word_class: 'AJ' },
    { word: 'thermoelectricity', word_class: 'N' }
  ],
  inessential: [
    { word: 'inessential', word_class: 'N' },
    { word: 'inessential', word_class: 'AJ' },
    { word: 'inessentiality', word_class: 'N' }
  ],
  trailer: [
    { word: 'trail', word_class: 'N' },
    { word: 'trail', word_class: 'V' },
    { word: 'trailer', word_class: 'N' },
    { word: 'trailing', word_class: 'N' },
    { word: 'trailing', word_class: 'AJ' }
  ],
  braggadocio: [
    { word: 'brag', word_class: 'N' },
    { word: 'brag', word_class: 'V' },
    { word: 'brag', word_class: 'AJ' },
    { word: 'bragging', word_class: 'N' },
    { word: 'bragging', word_class: 'AJ' },
    { word: 'braggadocio', word_class: 'N' }
  ],
  mettle: [
    { word: 'mettle', word_class: 'N' },
    { word: 'mettlesome', word_class: 'AJ' },
    { word: 'mettlesomeness', word_class: 'N' }
  ],
  rot: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  rearing: [
    { word: 'rear', word_class: 'N' },
    { word: 'rear', word_class: 'V' },
    { word: 'rear', word_class: 'AJ' },
    { word: 'rearing', word_class: 'N' },
    { word: 'rearing', word_class: 'AJ' }
  ],
  stereotype: [
    { word: 'stereotype', word_class: 'N' },
    { word: 'stereotype', word_class: 'V' },
    { word: 'stereotyped', word_class: 'AJ' },
    { word: 'stereotypic', word_class: 'AJ' },
    { word: 'stereotypical', word_class: 'AJ' }
  ],
  chemisorb: [
    { word: 'chemisorb', word_class: 'V' },
    { word: 'chemisorption', word_class: 'N' },
    { word: 'chemisorptive', word_class: 'AJ' }
  ],
  liquescence: [
    { word: 'liquesce', word_class: 'V' },
    { word: 'liquescence', word_class: 'N' },
    { word: 'liquescent', word_class: 'AJ' }
  ],
  trinity: [
    { word: 'trinity', word_class: 'N' },
    { word: 'trinitarian', word_class: 'N' },
    { word: 'trinitarian', word_class: 'AJ' },
    { word: 'trinitarianism', word_class: 'N' }
  ],
  championship: [
    { word: 'champion', word_class: 'N' },
    { word: 'champion', word_class: 'V' },
    { word: 'champion', word_class: 'AJ' },
    { word: 'championship', word_class: 'N' }
  ],
  tempera: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  embezzle: [
    { word: 'embezzle', word_class: 'V' },
    { word: 'embezzler', word_class: 'N' },
    { word: 'embezzled', word_class: 'AJ' },
    { word: 'embezzlement', word_class: 'N' }
  ],
  cookery: [
    { word: 'cook', word_class: 'N' },
    { word: 'cook', word_class: 'V' },
    { word: 'cook', word_class: 'AJ' },
    { word: 'cooker', word_class: 'N' },
    { word: 'cookery', word_class: 'N' },
    { word: 'cooking', word_class: 'N' },
    { word: 'cooked', word_class: 'AJ' },
    { word: 'cookery', word_class: 'AJ' }
  ],
  churchman: [
    { word: 'church', word_class: 'N' },
    { word: 'church', word_class: 'V' },
    { word: 'churchman', word_class: 'N' }
  ],
  sulphation: [
    { word: 'sulphate', word_class: 'N' },
    { word: 'sulphate', word_class: 'V' },
    { word: 'sulphation', word_class: 'N' }
  ],
  dispensableness: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  optional: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  specific: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  platitude: [
    { word: 'platitude', word_class: 'N' },
    { word: 'platitudinal', word_class: 'AJ' },
    { word: 'platitudinize', word_class: 'V' },
    { word: 'platitudinous', word_class: 'AJ' },
    { word: 'platitudinization', word_class: 'N' }
  ],
  creeper: [
    { word: 'creep', word_class: 'N' },
    { word: 'creep', word_class: 'V' },
    { word: 'creeps', word_class: 'N' },
    { word: 'creeper', word_class: 'N' },
    { word: 'creeping', word_class: 'N' },
    { word: 'creeping', word_class: 'AJ' }
  ],
  judaisation: [
    { word: 'judaise', word_class: 'V' },
    { word: 'judaize', word_class: 'V' },
    { word: 'judaisation', word_class: 'N' },
    { word: 'judaization', word_class: 'N' }
  ],
  skulk: [
    { word: 'skulk', word_class: 'V' },
    { word: 'skulker', word_class: 'N' },
    { word: 'skulking', word_class: 'N' },
    { word: 'skulking', word_class: 'AJ' }
  ],
  lech: [
    { word: 'lech', word_class: 'V' },
    { word: 'lecher', word_class: 'N' },
    { word: 'lecherous', word_class: 'AJ' },
    { word: 'lecherousness', word_class: 'N' }
  ],
  illuminated: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  scorching: [
    { word: 'scorch', word_class: 'N' },
    { word: 'scorch', word_class: 'V' },
    { word: 'scorcher', word_class: 'N' },
    { word: 'scorched', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AV' }
  ],
  dissociated: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  reincarnation: [
    { word: 'reincarnate', word_class: 'V' },
    { word: 'reincarnate', word_class: 'AJ' },
    { word: 'reincarnation', word_class: 'N' }
  ],
  localisation: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  cascades: [
    { word: 'cascade', word_class: 'N' },
    { word: 'cascade', word_class: 'V' },
    { word: 'cascades', word_class: 'N' }
  ],
  cinchonise: [
    { word: 'cinchonise', word_class: 'V' },
    { word: 'cinchonize', word_class: 'V' },
    { word: 'cinchonisation', word_class: 'N' },
    { word: 'cinchonization', word_class: 'N' }
  ],
  spar: [{ word: 'spar', word_class: 'N' }, { word: 'spar', word_class: 'V' }, { word: 'sparring', word_class: 'N' }],
  violably: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  fanatic: [
    { word: 'fanatic', word_class: 'N' },
    { word: 'fanatic', word_class: 'AJ' },
    { word: 'fanatical', word_class: 'AJ' },
    { word: 'fanatically', word_class: 'AV' }
  ],
  griever: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  religiously: [
    { word: 'religious', word_class: 'N' },
    { word: 'religious', word_class: 'AJ' },
    { word: 'religiously', word_class: 'AV' },
    { word: 'religiousness', word_class: 'N' }
  ],
  mobilization: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  recombinant: [
    { word: 'recombine', word_class: 'V' },
    { word: 'recombinant', word_class: 'N' },
    { word: 'recombinant', word_class: 'AJ' }
  ],
  penalized: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  realisation: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  disillusioning: [
    { word: 'disillusion', word_class: 'N' },
    { word: 'disillusion', word_class: 'V' },
    { word: 'disillusioned', word_class: 'AJ' },
    { word: 'disillusioning', word_class: 'AJ' },
    { word: 'disillusionment', word_class: 'N' }
  ],
  takings: [
    { word: 'take', word_class: 'N' },
    { word: 'take', word_class: 'V' },
    { word: 'taker', word_class: 'N' },
    { word: 'taken', word_class: 'AJ' },
    { word: 'taking', word_class: 'N' },
    { word: 'taking', word_class: 'AJ' },
    { word: 'takings', word_class: 'N' }
  ],
  damascene: [
    { word: 'damascene', word_class: 'N' },
    { word: 'damascene', word_class: 'V' },
    { word: 'damascene', word_class: 'AJ' },
    { word: 'damascened', word_class: 'AJ' }
  ],
  hearty: [
    { word: 'hearty', word_class: 'N' },
    { word: 'hearty', word_class: 'AJ' },
    { word: 'heartiness', word_class: 'N' }
  ],
  passion: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  substitutionary: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  assassin: [
    { word: 'assassin', word_class: 'N' },
    { word: 'assassinate', word_class: 'V' },
    { word: 'assassinated', word_class: 'AJ' },
    { word: 'assassination', word_class: 'N' }
  ],
  corked: [
    { word: 'cork', word_class: 'N' },
    { word: 'cork', word_class: 'V' },
    { word: 'corker', word_class: 'N' },
    { word: 'corked', word_class: 'AJ' },
    { word: 'corking', word_class: 'AJ' }
  ],
  combined: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  neighbour: [
    { word: 'neighbour', word_class: 'N' },
    { word: 'neighbour', word_class: 'V' },
    { word: 'neighbour', word_class: 'AJ' },
    { word: 'neighbouring', word_class: 'AJ' }
  ],
  punning: [{ word: 'pun', word_class: 'N' }, { word: 'pun', word_class: 'V' }, { word: 'punning', word_class: 'N' }],
  divination: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  change: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  sleep: [
    { word: 'sleep', word_class: 'N' },
    { word: 'sleep', word_class: 'V' },
    { word: 'sleeper', word_class: 'N' },
    { word: 'sleeping', word_class: 'N' },
    { word: 'sleeping', word_class: 'AJ' }
  ],
  plausible: [
    { word: 'plausible', word_class: 'AJ' },
    { word: 'plausibly', word_class: 'AV' },
    { word: 'plausibility', word_class: 'N' },
    { word: 'plausibleness', word_class: 'N' }
  ],
  bustling: [
    { word: 'bustle', word_class: 'N' },
    { word: 'bustle', word_class: 'V' },
    { word: 'bustling', word_class: 'AJ' }
  ],
  mower: [
    { word: 'mow', word_class: 'N' },
    { word: 'mow', word_class: 'V' },
    { word: 'mower', word_class: 'N' },
    { word: 'mown', word_class: 'AJ' }
  ],
  laurel: [
    { word: 'laurel', word_class: 'N' },
    { word: 'laurels', word_class: 'N' },
    { word: 'laureled', word_class: 'AJ' },
    { word: 'laurelled', word_class: 'AJ' }
  ],
  crippling: [
    { word: 'cripple', word_class: 'N' },
    { word: 'cripple', word_class: 'V' },
    { word: 'crippled', word_class: 'AJ' },
    { word: 'crippling', word_class: 'AJ' }
  ],
  dissever: [
    { word: 'dissever', word_class: 'V' },
    { word: 'disseverance', word_class: 'N' },
    { word: 'disseverment', word_class: 'N' },
    { word: 'disseveration', word_class: 'N' }
  ],
  vestal: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  foreordainment: [
    { word: 'foreordain', word_class: 'V' },
    { word: 'foreordained', word_class: 'AJ' },
    { word: 'foreordainment', word_class: 'N' },
    { word: 'foreordination', word_class: 'N' }
  ],
  vitriolize: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  confutation: [
    { word: 'confute', word_class: 'V' },
    { word: 'confuting', word_class: 'N' },
    { word: 'confutation', word_class: 'N' }
  ],
  bibbed: [{ word: 'bib', word_class: 'N' }, { word: 'bib', word_class: 'V' }, { word: 'bibbed', word_class: 'AJ' }],
  animalism: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  internationalism: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  imperturbability: [
    { word: 'imperturbable', word_class: 'AJ' },
    { word: 'imperturbability', word_class: 'N' },
    { word: 'imperturbableness', word_class: 'N' }
  ],
  sequestration: [
    { word: 'sequester', word_class: 'V' },
    { word: 'sequestrate', word_class: 'V' },
    { word: 'sequestered', word_class: 'AJ' },
    { word: 'sequestration', word_class: 'N' }
  ],
  tarriance: [
    { word: 'tarry', word_class: 'V' },
    { word: 'tarry', word_class: 'AJ' },
    { word: 'tarrying', word_class: 'N' },
    { word: 'tarriance', word_class: 'N' }
  ],
  rounded: [
    { word: 'round', word_class: 'N' },
    { word: 'round', word_class: 'V' },
    { word: 'round', word_class: 'AJ' },
    { word: 'round', word_class: 'AV' },
    { word: 'rounded', word_class: 'AJ' },
    { word: 'roundness', word_class: 'N' }
  ],
  armament: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  dissected: [
    { word: 'dissect', word_class: 'N' },
    { word: 'dissect', word_class: 'V' },
    { word: 'dissected', word_class: 'AJ' },
    { word: 'dissection', word_class: 'N' }
  ],
  traumatise: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  colonized: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  suspenseful: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  jawed: [
    { word: 'jaw', word_class: 'N' },
    { word: 'jaw', word_class: 'V' },
    { word: 'jaws', word_class: 'N' },
    { word: 'jawed', word_class: 'AJ' }
  ],
  reversible: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  pallor: [{ word: 'pall', word_class: 'N' }, { word: 'pall', word_class: 'V' }, { word: 'pallor', word_class: 'N' }],
  impregnate: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  clapping: [
    { word: 'clap', word_class: 'N' },
    { word: 'clap', word_class: 'V' },
    { word: 'clapping', word_class: 'N' }
  ],
  stuffed: [
    { word: 'stuff', word_class: 'N' },
    { word: 'stuff', word_class: 'V' },
    { word: 'stuffed', word_class: 'AJ' },
    { word: 'stuffing', word_class: 'N' }
  ],
  hills: [{ word: 'hill', word_class: 'N' }, { word: 'hill', word_class: 'V' }, { word: 'hills', word_class: 'N' }],
  induct: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  fistful: [
    { word: 'fist', word_class: 'N' },
    { word: 'fistful', word_class: 'N' },
    { word: 'fistful', word_class: 'AJ' }
  ],
  conformation: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  ornamental: [
    { word: 'ornament', word_class: 'N' },
    { word: 'ornament', word_class: 'V' },
    { word: 'ornamental', word_class: 'N' },
    { word: 'ornamental', word_class: 'AJ' },
    { word: 'ornamented', word_class: 'AJ' },
    { word: 'ornamentation', word_class: 'N' }
  ],
  bedding: [
    { word: 'bed', word_class: 'N' },
    { word: 'bed', word_class: 'V' },
    { word: 'bedded', word_class: 'AJ' },
    { word: 'bedding', word_class: 'N' }
  ],
  denotement: [
    { word: 'denote', word_class: 'N' },
    { word: 'denote', word_class: 'V' },
    { word: 'denotive', word_class: 'AJ' },
    { word: 'denotation', word_class: 'N' },
    { word: 'denotement', word_class: 'N' },
    { word: 'denotative', word_class: 'AJ' }
  ],
  deducted: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  reconstruction: [
    { word: 'reconstruct', word_class: 'V' },
    { word: 'reconstructed', word_class: 'AJ' },
    { word: 'reconstruction', word_class: 'N' },
    { word: 'reconstructive', word_class: 'AJ' }
  ],
  traditionalistic: [
    { word: 'traditionalist', word_class: 'N' },
    { word: 'traditionalist', word_class: 'AJ' },
    { word: 'traditionalistic', word_class: 'AJ' }
  ],
  southerly: [
    { word: 'southerly', word_class: 'N' },
    { word: 'southerly', word_class: 'AJ' },
    { word: 'southerly', word_class: 'AV' }
  ],
  straggling: [
    { word: 'straggle', word_class: 'N' },
    { word: 'straggle', word_class: 'V' },
    { word: 'straggler', word_class: 'N' },
    { word: 'straggling', word_class: 'AJ' }
  ],
  hardening: [
    { word: 'harden', word_class: 'V' },
    { word: 'hardened', word_class: 'AJ' },
    { word: 'hardening', word_class: 'N' }
  ],
  angel: [
    { word: 'angel', word_class: 'N' },
    { word: 'angelic', word_class: 'AJ' },
    { word: 'angelical', word_class: 'AJ' },
    { word: 'angelically', word_class: 'AV' }
  ],
  reducing: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  crenelation: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  assured: [
    { word: 'assur', word_class: 'N' },
    { word: 'assure', word_class: 'V' },
    { word: 'assured', word_class: 'AJ' },
    { word: 'assurance', word_class: 'N' },
    { word: 'assuring', word_class: 'AJ' }
  ],
  situate: [
    { word: 'situate', word_class: 'V' },
    { word: 'situated', word_class: 'AJ' },
    { word: 'situating', word_class: 'N' },
    { word: 'situation', word_class: 'N' }
  ],
  odd: [{ word: 'odd', word_class: 'AJ' }, { word: 'odds', word_class: 'N' }, { word: 'oddness', word_class: 'N' }],
  pantheist: [
    { word: 'pantheist', word_class: 'N' },
    { word: 'pantheist', word_class: 'AJ' },
    { word: 'pantheistic', word_class: 'AJ' }
  ],
  exteriorization: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  wall: [{ word: 'wall', word_class: 'N' }, { word: 'wall', word_class: 'V' }, { word: 'walled', word_class: 'AJ' }],
  underclothed: [
    { word: 'underclothes', word_class: 'N' },
    { word: 'underclothed', word_class: 'AJ' },
    { word: 'underclothing', word_class: 'N' }
  ],
  breakage: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  turbinate: [
    { word: 'turbine', word_class: 'N' },
    { word: 'turbinal', word_class: 'N' },
    { word: 'turbinate', word_class: 'N' },
    { word: 'turbinate', word_class: 'AJ' }
  ],
  faction: [
    { word: 'face', word_class: 'N' },
    { word: 'face', word_class: 'V' },
    { word: 'facer', word_class: 'N' },
    { word: 'faced', word_class: 'AJ' },
    { word: 'facing', word_class: 'N' },
    { word: 'faction', word_class: 'N' }
  ],
  parked: [
    { word: 'park', word_class: 'N' },
    { word: 'park', word_class: 'V' },
    { word: 'parked', word_class: 'AJ' },
    { word: 'parking', word_class: 'N' }
  ],
  surrender: [
    { word: 'surrender', word_class: 'N' },
    { word: 'surrender', word_class: 'V' },
    { word: 'surrendered', word_class: 'AJ' }
  ],
  slumped: [
    { word: 'slump', word_class: 'N' },
    { word: 'slump', word_class: 'V' },
    { word: 'slumped', word_class: 'AJ' }
  ],
  bill: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  furled: [{ word: 'furl', word_class: 'N' }, { word: 'furl', word_class: 'V' }, { word: 'furled', word_class: 'AJ' }],
  atlantic: [
    { word: 'atlantes', word_class: 'N' },
    { word: 'atlantic', word_class: 'N' },
    { word: 'atlantic', word_class: 'AJ' }
  ],
  motion: [
    { word: 'motion', word_class: 'N' },
    { word: 'motion', word_class: 'V' },
    { word: 'motional', word_class: 'AJ' }
  ],
  striking: [
    { word: 'strike', word_class: 'N' },
    { word: 'strike', word_class: 'V' },
    { word: 'striker', word_class: 'N' },
    { word: 'striking', word_class: 'N' },
    { word: 'stricken', word_class: 'AJ' },
    { word: 'striking', word_class: 'AJ' }
  ],
  decrepit: [
    { word: 'decrepit', word_class: 'AJ' },
    { word: 'decrepitate', word_class: 'V' },
    { word: 'decrepitation', word_class: 'N' }
  ],
  inscribed: [
    { word: 'inscribe', word_class: 'N' },
    { word: 'inscribe', word_class: 'V' },
    { word: 'inscribed', word_class: 'AJ' },
    { word: 'inscription', word_class: 'N' }
  ],
  christianization: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  brewery: [
    { word: 'brew', word_class: 'N' },
    { word: 'brew', word_class: 'V' },
    { word: 'brewer', word_class: 'N' },
    { word: 'brewage', word_class: 'N' },
    { word: 'brewery', word_class: 'N' },
    { word: 'brewing', word_class: 'N' },
    { word: 'brewery', word_class: 'AJ' }
  ],
  apprise: [
    { word: 'apprise', word_class: 'V' },
    { word: 'apprize', word_class: 'V' },
    { word: 'apprisal', word_class: 'N' }
  ],
  breeder: [
    { word: 'breed', word_class: 'N' },
    { word: 'breed', word_class: 'V' },
    { word: 'breeder', word_class: 'N' },
    { word: 'breeding', word_class: 'N' },
    { word: 'breeding', word_class: 'AJ' }
  ],
  elongation: [
    { word: 'elongate', word_class: 'V' },
    { word: 'elongate', word_class: 'AJ' },
    { word: 'elongated', word_class: 'AJ' },
    { word: 'elongation', word_class: 'N' }
  ],
  level: [
    { word: 'level', word_class: 'N' },
    { word: 'level', word_class: 'V' },
    { word: 'level', word_class: 'AJ' },
    { word: 'leveler', word_class: 'N' },
    { word: 'leveling', word_class: 'N' },
    { word: 'leveller', word_class: 'N' }
  ],
  magnanimousness: [
    { word: 'magnanimity', word_class: 'N' },
    { word: 'magnanimous', word_class: 'AJ' },
    { word: 'magnanimously', word_class: 'AV' },
    { word: 'magnanimousness', word_class: 'N' }
  ],
  influential: [
    { word: 'influence', word_class: 'N' },
    { word: 'influence', word_class: 'V' },
    { word: 'influential', word_class: 'AJ' },
    { word: 'influentially', word_class: 'AV' }
  ],
  now: [{ word: 'now', word_class: 'N' }, { word: 'now', word_class: 'AV' }, { word: 'nowness', word_class: 'N' }],
  accouterment: [
    { word: 'accouter', word_class: 'V' },
    { word: 'accoutered', word_class: 'AJ' },
    { word: 'accouterment', word_class: 'N' }
  ],
  passe: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  associated: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  wilderness: [
    { word: 'wilder', word_class: 'V' },
    { word: 'wilderment', word_class: 'N' },
    { word: 'wilderness', word_class: 'N' }
  ],
  condensing: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  internationalisation: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  arbitrament: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  luxuriance: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  tractability: [
    { word: 'tractable', word_class: 'AJ' },
    { word: 'tractably', word_class: 'AV' },
    { word: 'tractability', word_class: 'N' },
    { word: 'tractableness', word_class: 'N' }
  ],
  violate: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  republicanisation: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  external: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  quantifier: [
    { word: 'quantify', word_class: 'V' },
    { word: 'quantifier', word_class: 'N' },
    { word: 'quantifiable', word_class: 'AJ' },
    { word: 'quantification', word_class: 'N' },
    { word: 'quantifiability', word_class: 'N' }
  ],
  colonial: [
    { word: 'colony', word_class: 'N' },
    { word: 'colony', word_class: 'AJ' },
    { word: 'colonial', word_class: 'N' },
    { word: 'colonial', word_class: 'AJ' },
    { word: 'colonialism', word_class: 'N' }
  ],
  exploit: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  corrupted: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  plutocrat: [
    { word: 'plutocrat', word_class: 'N' },
    { word: 'plutocratic', word_class: 'AJ' },
    { word: 'plutocratical', word_class: 'AJ' }
  ],
  shriek: [
    { word: 'shriek', word_class: 'N' },
    { word: 'shriek', word_class: 'V' },
    { word: 'shrieked', word_class: 'AJ' },
    { word: 'shrieking', word_class: 'N' },
    { word: 'shrieking', word_class: 'AJ' }
  ],
  burnished: [
    { word: 'burnish', word_class: 'N' },
    { word: 'burnish', word_class: 'V' },
    { word: 'burnish', word_class: 'AJ' },
    { word: 'burnished', word_class: 'AJ' }
  ],
  trident: [
    { word: 'trident', word_class: 'N' },
    { word: 'trident', word_class: 'V' },
    { word: 'trident', word_class: 'AJ' }
  ],
  surfer: [
    { word: 'surf', word_class: 'N' },
    { word: 'surf', word_class: 'V' },
    { word: 'surfer', word_class: 'N' },
    { word: 'surfing', word_class: 'N' }
  ],
  estivate: [
    { word: 'estival', word_class: 'AJ' },
    { word: 'estivate', word_class: 'V' },
    { word: 'estivation', word_class: 'N' }
  ],
  mouthful: [
    { word: 'mouth', word_class: 'N' },
    { word: 'mouth', word_class: 'V' },
    { word: 'mouthed', word_class: 'AJ' },
    { word: 'mouthful', word_class: 'N' },
    { word: 'mouthful', word_class: 'AJ' }
  ],
  thud: [{ word: 'thud', word_class: 'N' }, { word: 'thud', word_class: 'V' }, { word: 'thudding', word_class: 'AJ' }],
  fete: [{ word: 'fete', word_class: 'N' }, { word: 'fete', word_class: 'V' }, { word: 'fetor', word_class: 'N' }],
  agonal: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  plastically: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  astronaut: [
    { word: 'astronaut', word_class: 'N' },
    { word: 'astronautic', word_class: 'AJ' },
    { word: 'astronautics', word_class: 'N' },
    { word: 'astronautical', word_class: 'AJ' }
  ],
  blasphemer: [
    { word: 'blaspheme', word_class: 'V' },
    { word: 'blasphemy', word_class: 'N' },
    { word: 'blasphemer', word_class: 'N' },
    { word: 'blasphemous', word_class: 'AJ' },
    { word: 'blasphemously', word_class: 'AV' }
  ],
  suberisation: [
    { word: 'suberise', word_class: 'V' },
    { word: 'suberize', word_class: 'V' },
    { word: 'suberisation', word_class: 'N' },
    { word: 'suberization', word_class: 'N' }
  ],
  amphitheatric: [
    { word: 'amphitheatre', word_class: 'N' },
    { word: 'amphitheatric', word_class: 'AJ' },
    { word: 'amphitheatrical', word_class: 'AJ' }
  ],
  prussianization: [
    { word: 'prussian', word_class: 'N' },
    { word: 'prussian', word_class: 'AJ' },
    { word: 'prussianise', word_class: 'V' },
    { word: 'prussianize', word_class: 'V' },
    { word: 'prussianisation', word_class: 'N' },
    { word: 'prussianization', word_class: 'N' }
  ],
  paradox: [
    { word: 'paradox', word_class: 'N' },
    { word: 'paradoxical', word_class: 'AJ' },
    { word: 'paradoxically', word_class: 'AV' }
  ],
  busful: [
    { word: 'bus', word_class: 'N' },
    { word: 'bus', word_class: 'V' },
    { word: 'buss', word_class: 'N' },
    { word: 'buss', word_class: 'V' },
    { word: 'busful', word_class: 'N' },
    { word: 'busman', word_class: 'N' },
    { word: 'busful', word_class: 'AJ' },
    { word: 'bussing', word_class: 'N' }
  ],
  recognizance: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  capitalist: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  wrongdoer: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  sticker: [
    { word: 'stick', word_class: 'N' },
    { word: 'stick', word_class: 'V' },
    { word: 'sticker', word_class: 'N' },
    { word: 'sticky', word_class: 'AJ' },
    { word: 'sticking', word_class: 'AJ' },
    { word: 'stickiness', word_class: 'N' }
  ],
  purified: [
    { word: 'purify', word_class: 'V' },
    { word: 'purifier', word_class: 'N' },
    { word: 'purified', word_class: 'AJ' },
    { word: 'purifying', word_class: 'AJ' },
    { word: 'purification', word_class: 'N' }
  ],
  sealed: [
    { word: 'seal', word_class: 'N' },
    { word: 'seal', word_class: 'V' },
    { word: 'sealer', word_class: 'N' },
    { word: 'sealant', word_class: 'N' },
    { word: 'sealed', word_class: 'AJ' },
    { word: 'sealing', word_class: 'N' }
  ],
  estival: [
    { word: 'estival', word_class: 'AJ' },
    { word: 'estivate', word_class: 'V' },
    { word: 'estivation', word_class: 'N' }
  ],
  ply: [
    { word: 'ply', word_class: 'N' },
    { word: 'ply', word_class: 'V' },
    { word: 'pliant', word_class: 'N' },
    { word: 'pliancy', word_class: 'N' },
    { word: 'pliant', word_class: 'AJ' },
    { word: 'pliantness', word_class: 'N' }
  ],
  expediently: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  diamagnetism: [
    { word: 'diamagnet', word_class: 'N' },
    { word: 'diamagnetic', word_class: 'AJ' },
    { word: 'diamagnetism', word_class: 'N' }
  ],
  utter: [
    { word: 'utter', word_class: 'V' },
    { word: 'utter', word_class: 'AJ' },
    { word: 'uttered', word_class: 'AJ' },
    { word: 'utterance', word_class: 'N' },
    { word: 'utterable', word_class: 'AJ' }
  ],
  oxidant: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  babble: [
    { word: 'babble', word_class: 'N' },
    { word: 'babble', word_class: 'V' },
    { word: 'babbler', word_class: 'N' },
    { word: 'babbling', word_class: 'N' },
    { word: 'babbling', word_class: 'AJ' },
    { word: 'babblement', word_class: 'N' }
  ],
  scales: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  arriver: [
    { word: 'arrive', word_class: 'V' },
    { word: 'arrival', word_class: 'N' },
    { word: 'arriver', word_class: 'N' },
    { word: 'arriving', word_class: 'AJ' }
  ],
  apparently: [
    { word: 'apparency', word_class: 'N' },
    { word: 'apparent', word_class: 'AJ' },
    { word: 'apparently', word_class: 'AV' },
    { word: 'apparentness', word_class: 'N' }
  ],
  orphanage: [
    { word: 'orphan', word_class: 'N' },
    { word: 'orphan', word_class: 'V' },
    { word: 'orphan', word_class: 'AJ' },
    { word: 'orphanage', word_class: 'N' },
    { word: 'orphaned', word_class: 'AJ' }
  ],
  aquatic: [
    { word: 'aquatic', word_class: 'N' },
    { word: 'aquatic', word_class: 'AJ' },
    { word: 'aquatics', word_class: 'N' }
  ],
  duel: [
    { word: 'duel', word_class: 'N' },
    { word: 'duel', word_class: 'V' },
    { word: 'dueler', word_class: 'N' },
    { word: 'duello', word_class: 'N' },
    { word: 'duelist', word_class: 'N' }
  ],
  beam: [{ word: 'beam', word_class: 'N' }, { word: 'beam', word_class: 'V' }, { word: 'beaming', word_class: 'AJ' }],
  nebulous: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  obtuseness: [
    { word: 'obtuse', word_class: 'AJ' },
    { word: 'obtusely', word_class: 'AV' },
    { word: 'obtuseness', word_class: 'N' }
  ],
  parabola: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  glaciate: [
    { word: 'glaciate', word_class: 'V' },
    { word: 'glaciated', word_class: 'AJ' },
    { word: 'glaciation', word_class: 'N' }
  ],
  exploitative: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  communalisation: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  untypically: [
    { word: 'untypical', word_class: 'AJ' },
    { word: 'untypicality', word_class: 'N' },
    { word: 'untypically', word_class: 'AV' }
  ],
  ambition: [
    { word: 'ambit', word_class: 'N' },
    { word: 'ambition', word_class: 'N' },
    { word: 'ambition', word_class: 'V' }
  ],
  driving: [
    { word: 'drive', word_class: 'N' },
    { word: 'drive', word_class: 'V' },
    { word: 'driver', word_class: 'N' },
    { word: 'driven', word_class: 'AJ' },
    { word: 'driving', word_class: 'N' },
    { word: 'driving', word_class: 'AJ' }
  ],
  elongated: [
    { word: 'elongate', word_class: 'V' },
    { word: 'elongate', word_class: 'AJ' },
    { word: 'elongated', word_class: 'AJ' },
    { word: 'elongation', word_class: 'N' }
  ],
  play: [
    { word: 'play', word_class: 'N' },
    { word: 'play', word_class: 'V' },
    { word: 'play', word_class: 'AJ' },
    { word: 'player', word_class: 'N' },
    { word: 'played', word_class: 'AJ' },
    { word: 'playing', word_class: 'N' },
    { word: 'playing', word_class: 'AJ' }
  ],
  anaesthetize: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  guts: [{ word: 'gut', word_class: 'N' }, { word: 'gut', word_class: 'V' }, { word: 'guts', word_class: 'N' }],
  affected: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  splay: [{ word: 'splay', word_class: 'N' }, { word: 'splay', word_class: 'V' }, { word: 'splay', word_class: 'AJ' }],
  intractable: [
    { word: 'intractable', word_class: 'AJ' },
    { word: 'intractability', word_class: 'N' },
    { word: 'intractableness', word_class: 'N' }
  ],
  supplication: [
    { word: 'supplicant', word_class: 'N' },
    { word: 'supplicate', word_class: 'V' },
    { word: 'supplicant', word_class: 'AJ' },
    { word: 'supplication', word_class: 'N' }
  ],
  illegalisation: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  calcific: [
    { word: 'calcify', word_class: 'V' },
    { word: 'calcific', word_class: 'AJ' },
    { word: 'calcification', word_class: 'N' }
  ],
  foreseeable: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  homologous: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  anonymous: [
    { word: 'anonym', word_class: 'N' },
    { word: 'anonymity', word_class: 'N' },
    { word: 'anonymous', word_class: 'AJ' },
    { word: 'anonymously', word_class: 'AV' }
  ],
  overhauling: [
    { word: 'overhaul', word_class: 'N' },
    { word: 'overhaul', word_class: 'V' },
    { word: 'overhauling', word_class: 'N' }
  ],
  expropriated: [
    { word: 'expropriate', word_class: 'V' },
    { word: 'expropriated', word_class: 'AJ' },
    { word: 'expropriation', word_class: 'N' }
  ],
  crystalline: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  predestination: [
    { word: 'predestine', word_class: 'V' },
    { word: 'predestinate', word_class: 'V' },
    { word: 'predestined', word_class: 'AJ' },
    { word: 'predestinate', word_class: 'AJ' },
    { word: 'predestination', word_class: 'N' }
  ],
  converting: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  hysteric: [
    { word: 'hysteric', word_class: 'AJ' },
    { word: 'hysterics', word_class: 'N' },
    { word: 'hysterical', word_class: 'AJ' },
    { word: 'hysterically', word_class: 'AV' }
  ],
  inventory: [
    { word: 'inventory', word_class: 'N' },
    { word: 'inventory', word_class: 'V' },
    { word: 'inventory', word_class: 'AJ' },
    { word: 'inventorying', word_class: 'N' }
  ],
  convexness: [
    { word: 'convex', word_class: 'AJ' },
    { word: 'convexity', word_class: 'N' },
    { word: 'convexness', word_class: 'N' }
  ],
  consonance: [
    { word: 'consonant', word_class: 'N' },
    { word: 'consonance', word_class: 'N' },
    { word: 'consonant', word_class: 'AJ' }
  ],
  staffer: [
    { word: 'staff', word_class: 'N' },
    { word: 'staff', word_class: 'V' },
    { word: 'staffer', word_class: 'N' }
  ],
  electron: [
    { word: 'electron', word_class: 'N' },
    { word: 'electronic', word_class: 'AJ' },
    { word: 'electronics', word_class: 'N' },
    { word: 'electronically', word_class: 'AV' }
  ],
  frightful: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  stroller: [
    { word: 'stroll', word_class: 'N' },
    { word: 'stroll', word_class: 'V' },
    { word: 'stroller', word_class: 'N' }
  ],
  misogynism: [
    { word: 'misogynic', word_class: 'AJ' },
    { word: 'misogynism', word_class: 'N' },
    { word: 'misogynous', word_class: 'AJ' }
  ],
  affairs: [
    { word: 'affair', word_class: 'N' },
    { word: 'affaire', word_class: 'N' },
    { word: 'affairs', word_class: 'N' }
  ],
  fish: [
    { word: 'fish', word_class: 'N' },
    { word: 'fish', word_class: 'V' },
    { word: 'fisher', word_class: 'N' },
    { word: 'fishery', word_class: 'N' },
    { word: 'fishing', word_class: 'N' },
    { word: 'fishery', word_class: 'AJ' }
  ],
  inundated: [
    { word: 'inundate', word_class: 'V' },
    { word: 'inundated', word_class: 'AJ' },
    { word: 'inundation', word_class: 'N' }
  ],
  arranger: [
    { word: 'arrange', word_class: 'V' },
    { word: 'arranger', word_class: 'N' },
    { word: 'arranged', word_class: 'AJ' },
    { word: 'arranging', word_class: 'N' },
    { word: 'arrangement', word_class: 'N' }
  ],
  branching: [
    { word: 'branch', word_class: 'N' },
    { word: 'branch', word_class: 'V' },
    { word: 'branched', word_class: 'AJ' },
    { word: 'branching', word_class: 'N' },
    { word: 'branching', word_class: 'AJ' },
    { word: 'embranchment', word_class: 'N' }
  ],
  beatified: [
    { word: 'beatify', word_class: 'V' },
    { word: 'beatific', word_class: 'AJ' },
    { word: 'beatified', word_class: 'AJ' },
    { word: 'beatification', word_class: 'N' }
  ],
  concave: [
    { word: 'concave', word_class: 'AJ' },
    { word: 'concavity', word_class: 'N' },
    { word: 'concavely', word_class: 'AV' },
    { word: 'concaveness', word_class: 'N' }
  ],
  accruement: [
    { word: 'accrue', word_class: 'V' },
    { word: 'accrual', word_class: 'N' },
    { word: 'accrued', word_class: 'AJ' },
    { word: 'accrument', word_class: 'N' },
    { word: 'accruement', word_class: 'N' }
  ],
  superscription: [
    { word: 'superscribe', word_class: 'V' },
    { word: 'superscript', word_class: 'N' },
    { word: 'superscript', word_class: 'AJ' },
    { word: 'superscription', word_class: 'N' }
  ],
  revile: [
    { word: 'revile', word_class: 'V' },
    { word: 'revile', word_class: 'AJ' },
    { word: 'reviled', word_class: 'AJ' },
    { word: 'revilement', word_class: 'N' }
  ],
  caudated: [
    { word: 'caudate', word_class: 'N' },
    { word: 'caudate', word_class: 'AJ' },
    { word: 'caudated', word_class: 'AJ' }
  ],
  aphrodisiacal: [
    { word: 'aphrodisiac', word_class: 'N' },
    { word: 'aphrodisiac', word_class: 'AJ' },
    { word: 'aphrodisiacal', word_class: 'AJ' }
  ],
  agreeably: [
    { word: 'agreeable', word_class: 'AJ' },
    { word: 'agreeably', word_class: 'AV' },
    { word: 'agreeability', word_class: 'N' },
    { word: 'agreeableness', word_class: 'N' }
  ],
  mesmerise: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  departmentalize: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  amiable: [
    { word: 'amiable', word_class: 'AJ' },
    { word: 'amiably', word_class: 'AV' },
    { word: 'amiability', word_class: 'N' },
    { word: 'amiableness', word_class: 'N' }
  ],
  stylise: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  incidence: [
    { word: 'incident', word_class: 'N' },
    { word: 'incidence', word_class: 'N' },
    { word: 'incident', word_class: 'AJ' }
  ],
  hierarchic: [
    { word: 'hierarch', word_class: 'N' },
    { word: 'hierarchal', word_class: 'AJ' },
    { word: 'hierarchic', word_class: 'AJ' },
    { word: 'hierarchical', word_class: 'AJ' },
    { word: 'hierarchically', word_class: 'AV' }
  ],
  acetal: [
    { word: 'acetal', word_class: 'N' },
    { word: 'acetic', word_class: 'N' },
    { word: 'acetate', word_class: 'N' },
    { word: 'acetic', word_class: 'AJ' },
    { word: 'acetous', word_class: 'AJ' }
  ],
  gleaming: [
    { word: 'gleam', word_class: 'N' },
    { word: 'gleam', word_class: 'V' },
    { word: 'gleaming', word_class: 'N' },
    { word: 'gleaming', word_class: 'AJ' }
  ],
  rebutter: [
    { word: 'rebut', word_class: 'V' },
    { word: 'rebuttal', word_class: 'N' },
    { word: 'rebutter', word_class: 'N' }
  ],
  pronounce: [
    { word: 'pronounce', word_class: 'V' },
    { word: 'pronounced', word_class: 'AJ' },
    { word: 'pronouncement', word_class: 'N' },
    { word: 'pronunciation', word_class: 'N' },
    { word: 'pronounceable', word_class: 'AJ' }
  ],
  demonstrability: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  solemnisation: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  registered: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  subdual: [
    { word: 'subdue', word_class: 'N' },
    { word: 'subdue', word_class: 'V' },
    { word: 'subdual', word_class: 'N' },
    { word: 'subdue', word_class: 'AJ' },
    { word: 'subdued', word_class: 'AJ' }
  ],
  inscription: [
    { word: 'inscribe', word_class: 'N' },
    { word: 'inscribe', word_class: 'V' },
    { word: 'inscribed', word_class: 'AJ' },
    { word: 'inscription', word_class: 'N' }
  ],
  disinheritance: [
    { word: 'disinherit', word_class: 'V' },
    { word: 'disinherited', word_class: 'AJ' },
    { word: 'disinheritance', word_class: 'N' }
  ],
  flat: [
    { word: 'flat', word_class: 'N' },
    { word: 'flat', word_class: 'AJ' },
    { word: 'flat', word_class: 'AV' },
    { word: 'flats', word_class: 'N' },
    { word: 'flatness', word_class: 'N' }
  ],
  legitimize: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  error: [{ word: 'err', word_class: 'V' }, { word: 'error', word_class: 'N' }, { word: 'erratum', word_class: 'N' }],
  dislodgement: [
    { word: 'dislodge', word_class: 'V' },
    { word: 'dislodgment', word_class: 'N' },
    { word: 'dislodgement', word_class: 'N' }
  ],
  entrancing: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  sprouting: [
    { word: 'sprout', word_class: 'N' },
    { word: 'sprout', word_class: 'V' },
    { word: 'sprouted', word_class: 'AJ' },
    { word: 'sprouting', word_class: 'N' }
  ],
  sensitise: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  curb: [
    { word: 'curb', word_class: 'N' },
    { word: 'curb', word_class: 'V' },
    { word: 'curbed', word_class: 'AJ' },
    { word: 'curbing', word_class: 'N' }
  ],
  bury: [
    { word: 'bury', word_class: 'V' },
    { word: 'burial', word_class: 'N' },
    { word: 'burier', word_class: 'N' },
    { word: 'buried', word_class: 'AJ' },
    { word: 'burying', word_class: 'N' }
  ],
  perilous: [
    { word: 'peril', word_class: 'N' },
    { word: 'peril', word_class: 'V' },
    { word: 'perilous', word_class: 'AJ' },
    { word: 'perilously', word_class: 'AV' },
    { word: 'perilousness', word_class: 'N' }
  ],
  innovative: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  surcession: [
    { word: 'surcease', word_class: 'N' },
    { word: 'surcease', word_class: 'V' },
    { word: 'surcession', word_class: 'N' }
  ],
  denature: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  anatomically: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  dismayed: [
    { word: 'dismay', word_class: 'N' },
    { word: 'dismay', word_class: 'V' },
    { word: 'dismayed', word_class: 'AJ' },
    { word: 'dismaying', word_class: 'AJ' }
  ],
  thrust: [
    { word: 'thrust', word_class: 'N' },
    { word: 'thrust', word_class: 'V' },
    { word: 'thruster', word_class: 'N' },
    { word: 'thrusting', word_class: 'N' }
  ],
  author: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  seating: [
    { word: 'seat', word_class: 'N' },
    { word: 'seat', word_class: 'V' },
    { word: 'seats', word_class: 'N' },
    { word: 'seated', word_class: 'AJ' },
    { word: 'seating', word_class: 'N' }
  ],
  filer: [
    { word: 'file', word_class: 'N' },
    { word: 'file', word_class: 'V' },
    { word: 'filer', word_class: 'N' },
    { word: 'filing', word_class: 'N' }
  ],
  livid: [
    { word: 'livid', word_class: 'AJ' },
    { word: 'lividity', word_class: 'N' },
    { word: 'lividness', word_class: 'N' }
  ],
  roughness: [
    { word: 'rough', word_class: 'N' },
    { word: 'rough', word_class: 'V' },
    { word: 'rough', word_class: 'AJ' },
    { word: 'rough', word_class: 'AV' },
    { word: 'roughage', word_class: 'N' },
    { word: 'roughness', word_class: 'N' }
  ],
  leafing: [
    { word: 'leaf', word_class: 'N' },
    { word: 'leaf', word_class: 'V' },
    { word: 'leafed', word_class: 'AJ' },
    { word: 'leafing', word_class: 'N' }
  ],
  germanization: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  salvation: [
    { word: 'salve', word_class: 'N' },
    { word: 'salve', word_class: 'V' },
    { word: 'salver', word_class: 'N' },
    { word: 'salvor', word_class: 'N' },
    { word: 'salving', word_class: 'AJ' },
    { word: 'salvation', word_class: 'N' }
  ],
  incapsulate: [
    { word: 'encapsulate', word_class: 'V' },
    { word: 'incapsulate', word_class: 'V' },
    { word: 'encapsulation', word_class: 'N' },
    { word: 'incapsulation', word_class: 'N' }
  ],
  desired: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  productive: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  solve: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  incompetence: [
    { word: 'incompetent', word_class: 'N' },
    { word: 'incompetence', word_class: 'N' },
    { word: 'incompetency', word_class: 'N' },
    { word: 'incompetent', word_class: 'AJ' },
    { word: 'incompetently', word_class: 'AV' }
  ],
  telling: [
    { word: 'tell', word_class: 'N' },
    { word: 'tell', word_class: 'V' },
    { word: 'teller', word_class: 'N' },
    { word: 'telling', word_class: 'N' },
    { word: 'telling', word_class: 'AJ' }
  ],
  caddie: [
    { word: 'caddy', word_class: 'N' },
    { word: 'caddy', word_class: 'V' },
    { word: 'caddie', word_class: 'N' },
    { word: 'caddie', word_class: 'V' },
    { word: 'caddis', word_class: 'N' },
    { word: 'caddy', word_class: 'AJ' }
  ],
  avert: [
    { word: 'avert', word_class: 'V' },
    { word: 'averse', word_class: 'AJ' },
    { word: 'aversion', word_class: 'N' },
    { word: 'averting', word_class: 'N' },
    { word: 'aversive', word_class: 'AJ' }
  ],
  hybrid: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  exorciser: [
    { word: 'exorcise', word_class: 'V' },
    { word: 'exorcism', word_class: 'N' },
    { word: 'exorcist', word_class: 'N' },
    { word: 'exorcize', word_class: 'V' },
    { word: 'exorciser', word_class: 'N' }
  ],
  kidnapping: [
    { word: 'kidnap', word_class: 'V' },
    { word: 'kidnaper', word_class: 'N' },
    { word: 'kidnaped', word_class: 'AJ' },
    { word: 'kidnapped', word_class: 'AJ' },
    { word: 'kidnapping', word_class: 'N' }
  ],
  bouncy: [
    { word: 'bounce', word_class: 'N' },
    { word: 'bounce', word_class: 'V' },
    { word: 'bouncy', word_class: 'AJ' },
    { word: 'bouncer', word_class: 'N' },
    { word: 'bouncing', word_class: 'N' },
    { word: 'bouncing', word_class: 'AJ' },
    { word: 'bounciness', word_class: 'N' }
  ],
  delinquency: [
    { word: 'delinquent', word_class: 'N' },
    { word: 'delinquency', word_class: 'N' },
    { word: 'delinquent', word_class: 'AJ' }
  ],
  transact: [
    { word: 'transact', word_class: 'N' },
    { word: 'transact', word_class: 'V' },
    { word: 'transaction', word_class: 'N' },
    { word: 'transactions', word_class: 'N' }
  ],
  eosinophile: [
    { word: 'eosinophil', word_class: 'N' },
    { word: 'eosinophile', word_class: 'N' },
    { word: 'eosinophilic', word_class: 'AJ' }
  ],
  individuality: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  covetousness: [
    { word: 'covet', word_class: 'N' },
    { word: 'covet', word_class: 'V' },
    { word: 'coveted', word_class: 'AJ' },
    { word: 'covetous', word_class: 'AJ' },
    { word: 'covetously', word_class: 'AV' },
    { word: 'covetousness', word_class: 'N' }
  ],
  bareness: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  recovered: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  whimper: [
    { word: 'whimper', word_class: 'N' },
    { word: 'whimper', word_class: 'V' },
    { word: 'whimpering', word_class: 'N' }
  ],
  earring: [
    { word: 'ear', word_class: 'N' },
    { word: 'eared', word_class: 'AJ' },
    { word: 'earful', word_class: 'N' },
    { word: 'earful', word_class: 'AJ' },
    { word: 'earring', word_class: 'N' }
  ],
  ponderous: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  blunderer: [
    { word: 'blunder', word_class: 'N' },
    { word: 'blunder', word_class: 'V' },
    { word: 'blunderer', word_class: 'N' }
  ],
  appointment: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  intricate: [
    { word: 'intricacy', word_class: 'N' },
    { word: 'intricate', word_class: 'AJ' },
    { word: 'intricately', word_class: 'AV' }
  ],
  reluctancy: [
    { word: 'reluct', word_class: 'V' },
    { word: 'reluctance', word_class: 'N' },
    { word: 'reluctancy', word_class: 'N' },
    { word: 'reluctant', word_class: 'AJ' },
    { word: 'reluctivity', word_class: 'N' }
  ],
  looted: [
    { word: 'loot', word_class: 'N' },
    { word: 'loot', word_class: 'V' },
    { word: 'looter', word_class: 'N' },
    { word: 'looted', word_class: 'AJ' },
    { word: 'looting', word_class: 'N' }
  ],
  way: [
    { word: 'way', word_class: 'N' },
    { word: 'way', word_class: 'AV' },
    { word: 'ways', word_class: 'N' },
    { word: 'ways', word_class: 'AV' }
  ],
  laudator: [
    { word: 'laud', word_class: 'V' },
    { word: 'lauder', word_class: 'N' },
    { word: 'laudator', word_class: 'N' },
    { word: 'laudation', word_class: 'N' },
    { word: 'laudatory', word_class: 'AJ' }
  ],
  hawking: [
    { word: 'hawk', word_class: 'N' },
    { word: 'hawk', word_class: 'V' },
    { word: 'hawker', word_class: 'N' },
    { word: 'hawking', word_class: 'N' }
  ],
  leaven: [
    { word: 'leaven', word_class: 'N' },
    { word: 'leaven', word_class: 'V' },
    { word: 'leavened', word_class: 'AJ' },
    { word: 'leavening', word_class: 'N' }
  ],
  russianisation: [
    { word: 'russian', word_class: 'N' },
    { word: 'russian', word_class: 'AJ' },
    { word: 'russianise', word_class: 'V' },
    { word: 'russianize', word_class: 'V' },
    { word: 'russianisation', word_class: 'N' },
    { word: 'russianization', word_class: 'N' }
  ],
  'first-rate': [
    { word: 'first-rate', word_class: 'AJ' },
    { word: 'first-rate', word_class: 'AV' },
    { word: 'first-rater', word_class: 'N' }
  ],
  freighter: [
    { word: 'freight', word_class: 'N' },
    { word: 'freight', word_class: 'V' },
    { word: 'freighter', word_class: 'N' }
  ],
  negligibly: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  singularization: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  phenomenally: [
    { word: 'phenomena', word_class: 'N' },
    { word: 'phenomenal', word_class: 'AJ' },
    { word: 'phenomenally', word_class: 'AV' }
  ],
  accidentally: [
    { word: 'accidental', word_class: 'N' },
    { word: 'accidental', word_class: 'AJ' },
    { word: 'accidentally', word_class: 'AV' }
  ],
  modestness: [
    { word: 'modest', word_class: 'AJ' },
    { word: 'modesty', word_class: 'N' },
    { word: 'modestness', word_class: 'N' }
  ],
  moonshiner: [
    { word: 'moonshine', word_class: 'N' },
    { word: 'moonshine', word_class: 'V' },
    { word: 'moonshiner', word_class: 'N' }
  ],
  dissuasion: [
    { word: 'dissuade', word_class: 'V' },
    { word: 'dissuasion', word_class: 'N' },
    { word: 'dissuasive', word_class: 'AJ' }
  ],
  monetarism: [
    { word: 'monetary', word_class: 'N' },
    { word: 'monetary', word_class: 'AJ' },
    { word: 'monetarism', word_class: 'N' },
    { word: 'monetarize', word_class: 'V' },
    { word: 'monetarily', word_class: 'AJ' }
  ],
  nutate: [
    { word: 'nut', word_class: 'N' },
    { word: 'nut', word_class: 'V' },
    { word: 'nuts', word_class: 'AJ' },
    { word: 'nutate', word_class: 'V' },
    { word: 'nutation', word_class: 'N' }
  ],
  synchrony: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  withdrawing: [
    { word: 'withdraw', word_class: 'V' },
    { word: 'withdrawal', word_class: 'N' },
    { word: 'withdrawn', word_class: 'AJ' },
    { word: 'withdrawing', word_class: 'AJ' },
    { word: 'withdrawnness', word_class: 'N' }
  ],
  resumption: [
    { word: 'resume', word_class: 'N' },
    { word: 'resume', word_class: 'V' },
    { word: 'resumption', word_class: 'N' }
  ],
  socialize: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  gore: [{ word: 'gore', word_class: 'N' }, { word: 'gore', word_class: 'V' }, { word: 'goral', word_class: 'N' }],
  magnet: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  obsolescence: [
    { word: 'obsolesce', word_class: 'V' },
    { word: 'obsolescence', word_class: 'N' },
    { word: 'obsolescent', word_class: 'AJ' }
  ],
  recreational: [
    { word: 'recreate', word_class: 'V' },
    { word: 'recreation', word_class: 'N' },
    { word: 'recreational', word_class: 'AJ' }
  ],
  case: [
    { word: 'case', word_class: 'N' },
    { word: 'case', word_class: 'V' },
    { word: 'cased', word_class: 'AJ' },
    { word: 'casing', word_class: 'N' },
    { word: 'caseful', word_class: 'N' },
    { word: 'casement', word_class: 'N' }
  ],
  unsettled: [
    { word: 'unsettle', word_class: 'V' },
    { word: 'unsettled', word_class: 'AJ' },
    { word: 'unsettlement', word_class: 'N' }
  ],
  relegating: [
    { word: 'relegate', word_class: 'V' },
    { word: 'relegating', word_class: 'N' },
    { word: 'relegation', word_class: 'N' }
  ],
  synthesisation: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  vulturous: [
    { word: 'vultur', word_class: 'N' },
    { word: 'vulture', word_class: 'N' },
    { word: 'vulturous', word_class: 'AJ' }
  ],
  aerated: [
    { word: 'aerate', word_class: 'V' },
    { word: 'aerator', word_class: 'N' },
    { word: 'aerated', word_class: 'AJ' },
    { word: 'aeration', word_class: 'N' }
  ],
  rage: [
    { word: 'rage', word_class: 'N' },
    { word: 'rage', word_class: 'V' },
    { word: 'ragee', word_class: 'N' },
    { word: 'raging', word_class: 'AJ' }
  ],
  generalize: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  careful: [
    { word: 'care', word_class: 'N' },
    { word: 'care', word_class: 'V' },
    { word: 'caring', word_class: 'N' },
    { word: 'caring', word_class: 'AJ' },
    { word: 'careful', word_class: 'AJ' },
    { word: 'carefulness', word_class: 'N' }
  ],
  unsatisfying: [
    { word: 'unsatisfied', word_class: 'AJ' },
    { word: 'unsatisfying', word_class: 'AJ' },
    { word: 'unsatisfiable', word_class: 'AJ' }
  ],
  constitute: [
    { word: 'constitute', word_class: 'V' },
    { word: 'consitution', word_class: 'N' },
    { word: 'constitution', word_class: 'N' },
    { word: 'constitutive', word_class: 'AJ' },
    { word: 'constitutional', word_class: 'N' },
    { word: 'constitutional', word_class: 'AJ' }
  ],
  dispensation: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  littered: [
    { word: 'litter', word_class: 'N' },
    { word: 'litter', word_class: 'V' },
    { word: 'litterer', word_class: 'N' },
    { word: 'littered', word_class: 'AJ' }
  ],
  expedient: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  subordinateness: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  apostrophe: [
    { word: 'apostrophe', word_class: 'N' },
    { word: 'apostrophic', word_class: 'AJ' },
    { word: 'apostrophize', word_class: 'V' },
    { word: 'apostrophization', word_class: 'N' }
  ],
  orator: [
    { word: 'orate', word_class: 'V' },
    { word: 'orator', word_class: 'N' },
    { word: 'oration', word_class: 'N' }
  ],
  tray: [
    { word: 'tray', word_class: 'N' },
    { word: 'trayful', word_class: 'N' },
    { word: 'trayful', word_class: 'AJ' }
  ],
  bust: [
    { word: 'bust', word_class: 'N' },
    { word: 'bust', word_class: 'V' },
    { word: 'bust', word_class: 'AJ' },
    { word: 'buster', word_class: 'N' },
    { word: 'busted', word_class: 'AJ' }
  ],
  reconciled: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  sanguineness: [
    { word: 'sanguine', word_class: 'AJ' },
    { word: 'sanguinity', word_class: 'N' },
    { word: 'sanguineness', word_class: 'N' },
    { word: 'sanguineous', word_class: 'AJ' }
  ],
  nuance: [
    { word: 'nuance', word_class: 'N' },
    { word: 'nuance', word_class: 'V' },
    { word: 'nuanced', word_class: 'AJ' }
  ],
  bakery: [
    { word: 'bake', word_class: 'N' },
    { word: 'bake', word_class: 'V' },
    { word: 'baker', word_class: 'N' },
    { word: 'baked', word_class: 'AJ' },
    { word: 'bakery', word_class: 'N' },
    { word: 'baking', word_class: 'N' },
    { word: 'bakery', word_class: 'AJ' },
    { word: 'baking', word_class: 'AJ' }
  ],
  beads: [
    { word: 'bead', word_class: 'N' },
    { word: 'bead', word_class: 'V' },
    { word: 'beads', word_class: 'N' },
    { word: 'beaded', word_class: 'AJ' },
    { word: 'beading', word_class: 'N' }
  ],
  testimony: [
    { word: 'testimony', word_class: 'N' },
    { word: 'testimonial', word_class: 'N' },
    { word: 'testimonial', word_class: 'AJ' }
  ],
  reincarnate: [
    { word: 'reincarnate', word_class: 'V' },
    { word: 'reincarnate', word_class: 'AJ' },
    { word: 'reincarnation', word_class: 'N' }
  ],
  flavorous: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  stroking: [
    { word: 'stroke', word_class: 'N' },
    { word: 'stroke', word_class: 'V' },
    { word: 'stroking', word_class: 'N' }
  ],
  extreme: [
    { word: 'extreme', word_class: 'N' },
    { word: 'extreme', word_class: 'AJ' },
    { word: 'extremism', word_class: 'N' },
    { word: 'extremity', word_class: 'N' },
    { word: 'extremely', word_class: 'AV' }
  ],
  emulation: [
    { word: 'emulate', word_class: 'V' },
    { word: 'emulous', word_class: 'AJ' },
    { word: 'emulation', word_class: 'N' },
    { word: 'emulously', word_class: 'AV' }
  ],
  plump: [
    { word: 'plump', word_class: 'N' },
    { word: 'plump', word_class: 'V' },
    { word: 'plump', word_class: 'AJ' },
    { word: 'plump', word_class: 'AV' },
    { word: 'plumping', word_class: 'AJ' },
    { word: 'plumpness', word_class: 'N' }
  ],
  grained: [
    { word: 'grain', word_class: 'N' },
    { word: 'grain', word_class: 'V' },
    { word: 'grained', word_class: 'AJ' },
    { word: 'graining', word_class: 'N' },
    { word: 'granular', word_class: 'AJ' },
    { word: 'granularity', word_class: 'N' }
  ],
  vision: [
    { word: 'vision', word_class: 'N' },
    { word: 'visionary', word_class: 'N' },
    { word: 'visionary', word_class: 'AJ' }
  ],
  pray: [
    { word: 'pray', word_class: 'V' },
    { word: 'prayer', word_class: 'N' },
    { word: 'prayerful', word_class: 'AJ' }
  ],
  analog: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  breadwinning: [
    { word: 'breadwin', word_class: 'V' },
    { word: 'breadwinner', word_class: 'N' },
    { word: 'breadwinning', word_class: 'N' }
  ],
  coiling: [
    { word: 'coil', word_class: 'N' },
    { word: 'coil', word_class: 'V' },
    { word: 'coiled', word_class: 'AJ' },
    { word: 'coiling', word_class: 'AJ' }
  ],
  surefootedness: [
    { word: 'surefooted', word_class: 'AJ' },
    { word: 'surefootedly', word_class: 'AV' },
    { word: 'surefootedness', word_class: 'N' }
  ],
  extrusive: [
    { word: 'extrude', word_class: 'V' },
    { word: 'extrusion', word_class: 'N' },
    { word: 'extrusive', word_class: 'AJ' }
  ],
  segregate: [
    { word: 'segregant', word_class: 'N' },
    { word: 'segregate', word_class: 'V' },
    { word: 'segregated', word_class: 'AJ' },
    { word: 'segregation', word_class: 'N' }
  ],
  qualification: [
    { word: 'qualify', word_class: 'V' },
    { word: 'qualifier', word_class: 'N' },
    { word: 'qualified', word_class: 'AJ' },
    { word: 'qualifying', word_class: 'N' },
    { word: 'qualifying', word_class: 'AJ' },
    { word: 'qualification', word_class: 'N' }
  ],
  reluctance: [
    { word: 'reluct', word_class: 'V' },
    { word: 'reluctance', word_class: 'N' },
    { word: 'reluctancy', word_class: 'N' },
    { word: 'reluctant', word_class: 'AJ' },
    { word: 'reluctivity', word_class: 'N' }
  ],
  deregulate: [
    { word: 'deregulate', word_class: 'V' },
    { word: 'deregulating', word_class: 'N' },
    { word: 'deregulation', word_class: 'N' }
  ],
  supreme: [
    { word: 'supreme', word_class: 'AJ' },
    { word: 'supremacy', word_class: 'N' },
    { word: 'supremely', word_class: 'AV' },
    { word: 'supremacist', word_class: 'N' }
  ],
  specifying: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  mere: [{ word: 'mere', word_class: 'N' }, { word: 'mere', word_class: 'AJ' }, { word: 'merely', word_class: 'AV' }],
  chantry: [
    { word: 'chant', word_class: 'N' },
    { word: 'chant', word_class: 'V' },
    { word: 'chanter', word_class: 'N' },
    { word: 'chantry', word_class: 'N' },
    { word: 'chanted', word_class: 'AJ' },
    { word: 'chanting', word_class: 'N' }
  ],
  belly: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  richness: [
    { word: 'rich', word_class: 'AJ' },
    { word: 'riches', word_class: 'N' },
    { word: 'richness', word_class: 'N' }
  ],
  purposeful: [
    { word: 'purpose', word_class: 'N' },
    { word: 'purpose', word_class: 'V' },
    { word: 'purposely', word_class: 'AV' },
    { word: 'purposive', word_class: 'AJ' },
    { word: 'purposeful', word_class: 'AJ' },
    { word: 'purposefulness', word_class: 'N' }
  ],
  ravagement: [
    { word: 'ravage', word_class: 'N' },
    { word: 'ravage', word_class: 'V' },
    { word: 'ravaged', word_class: 'AJ' },
    { word: 'ravaging', word_class: 'N' },
    { word: 'ravaging', word_class: 'AJ' },
    { word: 'ravagement', word_class: 'N' }
  ],
  hewer: [{ word: 'hew', word_class: 'V' }, { word: 'hewer', word_class: 'N' }, { word: 'hewn', word_class: 'AJ' }],
  digitization: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  damage: [
    { word: 'damage', word_class: 'N' },
    { word: 'damage', word_class: 'V' },
    { word: 'damages', word_class: 'N' },
    { word: 'damaged', word_class: 'AJ' },
    { word: 'damaging', word_class: 'AJ' }
  ],
  angle: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  stylisation: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  streamline: [
    { word: 'streamline', word_class: 'N' },
    { word: 'streamline', word_class: 'V' },
    { word: 'streamliner', word_class: 'N' },
    { word: 'streamlined', word_class: 'AJ' }
  ],
  titivate: [
    { word: 'titivate', word_class: 'V' },
    { word: 'tittivate', word_class: 'V' },
    { word: 'titivation', word_class: 'N' },
    { word: 'tittivation', word_class: 'N' }
  ],
  minute: [
    { word: 'minute', word_class: 'N' },
    { word: 'minute', word_class: 'V' },
    { word: 'minute', word_class: 'AJ' },
    { word: 'minutes', word_class: 'N' },
    { word: 'minutely', word_class: 'AV' },
    { word: 'minuteman', word_class: 'N' },
    { word: 'minuteness', word_class: 'N' }
  ],
  aetiologic: [
    { word: 'aetiology', word_class: 'N' },
    { word: 'aetiologic', word_class: 'AJ' },
    { word: 'aetiological', word_class: 'AJ' }
  ],
  administration: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  genotype: [
    { word: 'genotype', word_class: 'N' },
    { word: 'genotypic', word_class: 'AJ' },
    { word: 'genotypical', word_class: 'AJ' }
  ],
  husk: [{ word: 'husk', word_class: 'N' }, { word: 'husk', word_class: 'V' }, { word: 'husking', word_class: 'N' }],
  orchestrated: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  arborize: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  seductive: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  aberrant: [
    { word: 'aberrant', word_class: 'N' },
    { word: 'aberrate', word_class: 'V' },
    { word: 'aberrance', word_class: 'N' },
    { word: 'aberrancy', word_class: 'N' },
    { word: 'aberrant', word_class: 'AJ' },
    { word: 'aberration', word_class: 'N' }
  ],
  waste: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  contextually: [
    { word: 'contextual', word_class: 'AJ' },
    { word: 'contextualize', word_class: 'V' },
    { word: 'contextually', word_class: 'AV' }
  ],
  confiscate: [
    { word: 'confiscate', word_class: 'V' },
    { word: 'confiscate', word_class: 'AJ' },
    { word: 'confiscable', word_class: 'AJ' },
    { word: 'confiscated', word_class: 'AJ' },
    { word: 'confiscation', word_class: 'N' }
  ],
  long: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  syllogism: [
    { word: 'syllogise', word_class: 'V' },
    { word: 'syllogism', word_class: 'N' },
    { word: 'syllogize', word_class: 'V' },
    { word: 'syllogisation', word_class: 'N' },
    { word: 'syllogization', word_class: 'N' }
  ],
  excitation: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  definable: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  beauty: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  humanistic: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  judge: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  sociobiologic: [
    { word: 'sociobiology', word_class: 'N' },
    { word: 'sociobiologic', word_class: 'AJ' },
    { word: 'sociobiological', word_class: 'AJ' },
    { word: 'sociobiologically', word_class: 'AV' }
  ],
  preferably: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  german: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  sniveler: [
    { word: 'snivel', word_class: 'N' },
    { word: 'snivel', word_class: 'V' },
    { word: 'sniveler', word_class: 'N' },
    { word: 'sniveling', word_class: 'N' }
  ],
  scout: [
    { word: 'scout', word_class: 'N' },
    { word: 'scout', word_class: 'V' },
    { word: 'scouting', word_class: 'N' }
  ],
  togged: [{ word: 'tog', word_class: 'V' }, { word: 'togs', word_class: 'N' }, { word: 'togged', word_class: 'AJ' }],
  monastic: [
    { word: 'monastic', word_class: 'N' },
    { word: 'monastic', word_class: 'AJ' },
    { word: 'monastical', word_class: 'AJ' }
  ],
  weakened: [
    { word: 'weaken', word_class: 'V' },
    { word: 'weakened', word_class: 'AJ' },
    { word: 'weakening', word_class: 'N' },
    { word: 'weakening', word_class: 'AJ' }
  ],
  wrongful: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  orchestrator: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  sting: [
    { word: 'sting', word_class: 'N' },
    { word: 'sting', word_class: 'V' },
    { word: 'stinger', word_class: 'N' },
    { word: 'stinging', word_class: 'N' },
    { word: 'stinging', word_class: 'AJ' }
  ],
  honestness: [
    { word: 'honest', word_class: 'AJ' },
    { word: 'honesty', word_class: 'N' },
    { word: 'honestness', word_class: 'N' }
  ],
  diazotisation: [
    { word: 'diazotise', word_class: 'V' },
    { word: 'diazotize', word_class: 'V' },
    { word: 'diazotisation', word_class: 'N' },
    { word: 'diazotization', word_class: 'N' }
  ],
  sprayer: [
    { word: 'spray', word_class: 'N' },
    { word: 'spray', word_class: 'V' },
    { word: 'sprayer', word_class: 'N' },
    { word: 'spraying', word_class: 'N' }
  ],
  transmitter: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  musical: [
    { word: 'music', word_class: 'N' },
    { word: 'musical', word_class: 'N' },
    { word: 'musical', word_class: 'AJ' },
    { word: 'musicality', word_class: 'N' },
    { word: 'musically', word_class: 'AV' },
    { word: 'musicalness', word_class: 'N' }
  ],
  photoelectrically: [
    { word: 'photoelectric', word_class: 'AJ' },
    { word: 'photoelectrical', word_class: 'AJ' },
    { word: 'photoelectrically', word_class: 'AV' }
  ],
  opportunely: [
    { word: 'opportune', word_class: 'AJ' },
    { word: 'opportunism', word_class: 'N' },
    { word: 'opportunity', word_class: 'N' },
    { word: 'opportunely', word_class: 'AV' },
    { word: 'opportuneness', word_class: 'N' }
  ],
  retiree: [
    { word: 'retire', word_class: 'N' },
    { word: 'retire', word_class: 'V' },
    { word: 'retiree', word_class: 'N' },
    { word: 'retired', word_class: 'AJ' },
    { word: 'retiring', word_class: 'AJ' },
    { word: 'retirement', word_class: 'N' }
  ],
  designer: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  sentimentalisation: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  relevance: [
    { word: 'relevance', word_class: 'N' },
    { word: 'relevancy', word_class: 'N' },
    { word: 'relevant', word_class: 'AJ' }
  ],
  pharmacologic: [
    { word: 'pharmacology', word_class: 'N' },
    { word: 'pharmacologic', word_class: 'AJ' },
    { word: 'pharmacological', word_class: 'AJ' },
    { word: 'pharmacologically', word_class: 'AV' }
  ],
  respected: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  understandability: [
    { word: 'understand', word_class: 'N' },
    { word: 'understand', word_class: 'V' },
    { word: 'understanding', word_class: 'N' },
    { word: 'understanding', word_class: 'AJ' },
    { word: 'understandable', word_class: 'AJ' },
    { word: 'understandably', word_class: 'AV' },
    { word: 'understandability', word_class: 'N' }
  ],
  annunciate: [
    { word: 'annunciate', word_class: 'V' },
    { word: 'annunciator', word_class: 'N' },
    { word: 'annunciation', word_class: 'N' }
  ],
  signalman: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  homosexualism: [
    { word: 'homosexual', word_class: 'N' },
    { word: 'homosexual', word_class: 'AJ' },
    { word: 'homosexualism', word_class: 'N' },
    { word: 'homosexuality', word_class: 'N' }
  ],
  equality: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  intercept: [
    { word: 'intercept', word_class: 'N' },
    { word: 'intercept', word_class: 'V' },
    { word: 'interceptor', word_class: 'N' },
    { word: 'interception', word_class: 'N' }
  ],
  supplanter: [
    { word: 'supplant', word_class: 'V' },
    { word: 'supplanter', word_class: 'N' },
    { word: 'supplanting', word_class: 'N' },
    { word: 'supplantation', word_class: 'N' }
  ],
  syllabicate: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  jell: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  signature: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  immixture: [
    { word: 'immix', word_class: 'V' },
    { word: 'immingle', word_class: 'V' },
    { word: 'immixture', word_class: 'N' }
  ],
  suck: [
    { word: 'suck', word_class: 'N' },
    { word: 'suck', word_class: 'V' },
    { word: 'sucker', word_class: 'N' },
    { word: 'sucking', word_class: 'N' },
    { word: 'suction', word_class: 'N' },
    { word: 'suction', word_class: 'V' }
  ],
  middle: [
    { word: 'middle', word_class: 'N' },
    { word: 'middle', word_class: 'V' },
    { word: 'middle', word_class: 'AJ' },
    { word: 'middling', word_class: 'N' },
    { word: 'middleman', word_class: 'N' },
    { word: 'middling', word_class: 'AJ' },
    { word: 'middling', word_class: 'AV' }
  ],
  conspicuousness: [
    { word: 'conspicuous', word_class: 'AJ' },
    { word: 'conspicuously', word_class: 'AV' },
    { word: 'conspicuousness', word_class: 'N' }
  ],
  antagonize: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  appeasement: [
    { word: 'appease', word_class: 'V' },
    { word: 'appeaser', word_class: 'N' },
    { word: 'appeasing', word_class: 'AJ' },
    { word: 'appeasable', word_class: 'AJ' },
    { word: 'appeasement', word_class: 'N' }
  ],
  sentential: [
    { word: 'sentence', word_class: 'N' },
    { word: 'sentence', word_class: 'V' },
    { word: 'sentential', word_class: 'AJ' },
    { word: 'sententious', word_class: 'AJ' },
    { word: 'sententiously', word_class: 'AV' }
  ],
  rope: [{ word: 'rope', word_class: 'N' }, { word: 'rope', word_class: 'V' }, { word: 'roping', word_class: 'N' }],
  cordially: [
    { word: 'cordial', word_class: 'N' },
    { word: 'cordial', word_class: 'AJ' },
    { word: 'cordiality', word_class: 'N' },
    { word: 'cordially', word_class: 'AV' }
  ],
  mechanic: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  appreciation: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  virginal: [
    { word: 'virgin', word_class: 'N' },
    { word: 'virgin', word_class: 'AJ' },
    { word: 'virginal', word_class: 'N' },
    { word: 'virginal', word_class: 'AJ' },
    { word: 'virginity', word_class: 'N' }
  ],
  technologically: [
    { word: 'technology', word_class: 'N' },
    { word: 'technological', word_class: 'AJ' },
    { word: 'technologically', word_class: 'AV' }
  ],
  ration: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  barbed: [{ word: 'barb', word_class: 'N' }, { word: 'barb', word_class: 'V' }, { word: 'barbed', word_class: 'AJ' }],
  clothed: [
    { word: 'cloth', word_class: 'N' },
    { word: 'clothe', word_class: 'V' },
    { word: 'clothes', word_class: 'N' },
    { word: 'clothed', word_class: 'AJ' },
    { word: 'clothing', word_class: 'N' }
  ],
  serviceableness: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  sling: [
    { word: 'sling', word_class: 'N' },
    { word: 'sling', word_class: 'V' },
    { word: 'slinger', word_class: 'N' },
    { word: 'slinging', word_class: 'N' }
  ],
  sledding: [
    { word: 'sled', word_class: 'N' },
    { word: 'sled', word_class: 'V' },
    { word: 'sledding', word_class: 'N' }
  ],
  leafed: [
    { word: 'leaf', word_class: 'N' },
    { word: 'leaf', word_class: 'V' },
    { word: 'leafed', word_class: 'AJ' },
    { word: 'leafing', word_class: 'N' }
  ],
  incapacitation: [
    { word: 'incapacity', word_class: 'N' },
    { word: 'incapacitate', word_class: 'V' },
    { word: 'incapacitated', word_class: 'AJ' },
    { word: 'incapacitation', word_class: 'N' },
    { word: 'incapacitating', word_class: 'AJ' }
  ],
  syncope: [
    { word: 'syncope', word_class: 'N' },
    { word: 'syncopate', word_class: 'V' },
    { word: 'syncopated', word_class: 'AJ' },
    { word: 'syncopation', word_class: 'N' }
  ],
  abbreviate: [
    { word: 'abbreviate', word_class: 'V' },
    { word: 'abbreviated', word_class: 'AJ' },
    { word: 'abbreviation', word_class: 'N' }
  ],
  erroneous: [
    { word: 'erroneous', word_class: 'AJ' },
    { word: 'erroneously', word_class: 'AV' },
    { word: 'erroneousness', word_class: 'N' }
  ],
  seat: [
    { word: 'seat', word_class: 'N' },
    { word: 'seat', word_class: 'V' },
    { word: 'seats', word_class: 'N' },
    { word: 'seated', word_class: 'AJ' },
    { word: 'seating', word_class: 'N' }
  ],
  polymeric: [
    { word: 'polymeric', word_class: 'AJ' },
    { word: 'polymerise', word_class: 'V' },
    { word: 'polymerize', word_class: 'V' },
    { word: 'polymerisation', word_class: 'N' },
    { word: 'polymerization', word_class: 'N' }
  ],
  defraudment: [
    { word: 'defraud', word_class: 'V' },
    { word: 'defraudment', word_class: 'N' },
    { word: 'defraudation', word_class: 'N' }
  ],
  impinging: [
    { word: 'impinge', word_class: 'V' },
    { word: 'impinging', word_class: 'N' },
    { word: 'impingement', word_class: 'N' }
  ],
  confront: [
    { word: 'confront', word_class: 'V' },
    { word: 'confronting', word_class: 'N' },
    { word: 'confrontment', word_class: 'N' },
    { word: 'confrontation', word_class: 'N' },
    { word: 'confrontational', word_class: 'AJ' }
  ],
  circulative: [
    { word: 'circulant', word_class: 'N' },
    { word: 'circulate', word_class: 'V' },
    { word: 'circulation', word_class: 'N' },
    { word: 'circulating', word_class: 'AJ' },
    { word: 'circulative', word_class: 'AJ' }
  ],
  broiled: [
    { word: 'broil', word_class: 'N' },
    { word: 'broil', word_class: 'V' },
    { word: 'broiler', word_class: 'N' },
    { word: 'broiled', word_class: 'AJ' },
    { word: 'broiling', word_class: 'N' }
  ],
  geomorphology: [
    { word: 'geomorphology', word_class: 'N' },
    { word: 'geomorphologic', word_class: 'AJ' },
    { word: 'geomorphological', word_class: 'AJ' }
  ],
  extinguish: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  weldment: [
    { word: 'weld', word_class: 'N' },
    { word: 'weld', word_class: 'V' },
    { word: 'welder', word_class: 'N' },
    { word: 'welding', word_class: 'N' },
    { word: 'weldment', word_class: 'N' }
  ],
  behaviourist: [
    { word: 'behaviourist', word_class: 'N' },
    { word: 'behaviourist', word_class: 'AJ' },
    { word: 'behaviouristic', word_class: 'AJ' }
  ],
  criminative: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  negotiability: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  blemished: [
    { word: 'blemish', word_class: 'N' },
    { word: 'blemish', word_class: 'V' },
    { word: 'blemished', word_class: 'AJ' },
    { word: 'blemishment', word_class: 'N' }
  ],
  seize: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  neutrality: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  igniter: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  savvy: [{ word: 'savvy', word_class: 'N' }, { word: 'savvy', word_class: 'V' }, { word: 'savvy', word_class: 'AJ' }],
  explicable: [
    { word: 'explicate', word_class: 'V' },
    { word: 'explicable', word_class: 'AJ' },
    { word: 'explication', word_class: 'N' }
  ],
  falsify: [
    { word: 'falsify', word_class: 'V' },
    { word: 'falsifier', word_class: 'N' },
    { word: 'falsifying', word_class: 'N' },
    { word: 'falsification', word_class: 'N' }
  ],
  prevailing: [
    { word: 'prevail', word_class: 'V' },
    { word: 'prevalence', word_class: 'N' },
    { word: 'prevalent', word_class: 'AJ' },
    { word: 'prevailing', word_class: 'AJ' }
  ],
  consecrated: [
    { word: 'consecrate', word_class: 'V' },
    { word: 'consecrate', word_class: 'AJ' },
    { word: 'consecrated', word_class: 'AJ' },
    { word: 'consecration', word_class: 'N' }
  ],
  deplorable: [
    { word: 'deplore', word_class: 'V' },
    { word: 'deplorable', word_class: 'AJ' },
    { word: 'deplorably', word_class: 'AV' }
  ],
  rotten: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  perpetrate: [
    { word: 'perpetrate', word_class: 'V' },
    { word: 'perpetrator', word_class: 'N' },
    { word: 'perpetration', word_class: 'N' }
  ],
  divisibility: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  acrobat: [
    { word: 'acrobat', word_class: 'N' },
    { word: 'acrobatic', word_class: 'AJ' },
    { word: 'acrobatics', word_class: 'N' }
  ],
  merge: [
    { word: 'merge', word_class: 'V' },
    { word: 'merger', word_class: 'N' },
    { word: 'merged', word_class: 'AJ' },
    { word: 'merging', word_class: 'N' },
    { word: 'mergence', word_class: 'N' },
    { word: 'merging', word_class: 'AJ' }
  ],
  tumult: [
    { word: 'tumult', word_class: 'N' },
    { word: 'tumultuous', word_class: 'AJ' },
    { word: 'tumultuously', word_class: 'AV' },
    { word: 'tumultuousness', word_class: 'N' }
  ],
  putrefactive: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  liberty: [
    { word: 'liberty', word_class: 'N' },
    { word: 'libertarian', word_class: 'N' },
    { word: 'libertarian', word_class: 'AJ' },
    { word: 'libertarianism', word_class: 'N' }
  ],
  congregating: [
    { word: 'congregate', word_class: 'V' },
    { word: 'congregate', word_class: 'AJ' },
    { word: 'congregating', word_class: 'N' },
    { word: 'congregation', word_class: 'N' },
    { word: 'congregational', word_class: 'AJ' }
  ],
  perpendicular: [
    { word: 'perpendicular', word_class: 'N' },
    { word: 'perpendicular', word_class: 'AJ' },
    { word: 'perpendicularity', word_class: 'N' }
  ],
  essentiality: [
    { word: 'essential', word_class: 'N' },
    { word: 'essential', word_class: 'AJ' },
    { word: 'essentiality', word_class: 'N' },
    { word: 'essentially', word_class: 'AV' },
    { word: 'essentialness', word_class: 'N' }
  ],
  curvy: [
    { word: 'curve', word_class: 'N' },
    { word: 'curve', word_class: 'V' },
    { word: 'curvy', word_class: 'AJ' },
    { word: 'curved', word_class: 'AJ' },
    { word: 'curving', word_class: 'AJ' },
    { word: 'curvature', word_class: 'N' }
  ],
  hysterectomy: [
    { word: 'hysterectomy', word_class: 'N' },
    { word: 'hysterectomise', word_class: 'V' },
    { word: 'hysterectomize', word_class: 'V' }
  ],
  paralyzed: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  delectate: [
    { word: 'delectate', word_class: 'AJ' },
    { word: 'delectable', word_class: 'AJ' },
    { word: 'delectation', word_class: 'N' },
    { word: 'delectability', word_class: 'N' }
  ],
  equitably: [
    { word: 'equitable', word_class: 'AJ' },
    { word: 'equitably', word_class: 'AV' },
    { word: 'equitation', word_class: 'N' }
  ],
  subsumption: [
    { word: 'subsume', word_class: 'V' },
    { word: 'subsuming', word_class: 'N' },
    { word: 'subsumption', word_class: 'N' }
  ],
  criminality: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  comprehendible: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  fasten: [
    { word: 'fasten', word_class: 'V' },
    { word: 'fastener', word_class: 'N' },
    { word: 'fastened', word_class: 'AJ' },
    { word: 'fastening', word_class: 'N' }
  ],
  personalisation: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  encouraged: [
    { word: 'encourage', word_class: 'V' },
    { word: 'encouraged', word_class: 'AJ' },
    { word: 'encouraging', word_class: 'AJ' },
    { word: 'encouragement', word_class: 'N' }
  ],
  divulgement: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  church: [
    { word: 'church', word_class: 'N' },
    { word: 'church', word_class: 'V' },
    { word: 'churchman', word_class: 'N' }
  ],
  revitalizing: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  luxuriate: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  cankerous: [
    { word: 'canker', word_class: 'N' },
    { word: 'canker', word_class: 'V' },
    { word: 'cankerous', word_class: 'AJ' }
  ],
  drunk: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  emersion: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  squinty: [
    { word: 'squint', word_class: 'N' },
    { word: 'squint', word_class: 'V' },
    { word: 'squint', word_class: 'AJ' },
    { word: 'squinty', word_class: 'AJ' },
    { word: 'squinting', word_class: 'AJ' }
  ],
  nonviolent: [
    { word: 'nonviolence', word_class: 'N' },
    { word: 'nonviolent', word_class: 'AJ' },
    { word: 'nonviolently', word_class: 'AV' }
  ],
  acclimatisation: [
    { word: 'acclimatise', word_class: 'V' },
    { word: 'acclimatize', word_class: 'V' },
    { word: 'acclimatisation', word_class: 'N' },
    { word: 'acclimatization', word_class: 'N' }
  ],
  orientate: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  dragging: [
    { word: 'drag', word_class: 'N' },
    { word: 'drag', word_class: 'V' },
    { word: 'dragee', word_class: 'N' },
    { word: 'dragging', word_class: 'AJ' }
  ],
  queens: [{ word: 'queen', word_class: 'N' }, { word: 'queen', word_class: 'V' }, { word: 'queens', word_class: 'N' }],
  singularisation: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  tumble: [
    { word: 'tumble', word_class: 'N' },
    { word: 'tumble', word_class: 'V' },
    { word: 'tumbler', word_class: 'N' },
    { word: 'tumbling', word_class: 'N' },
    { word: 'tumbling', word_class: 'AJ' }
  ],
  catechize: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  cards: [{ word: 'card', word_class: 'N' }, { word: 'card', word_class: 'V' }, { word: 'cards', word_class: 'N' }],
  wit: [
    { word: 'wit', word_class: 'N' },
    { word: 'wit', word_class: 'V' },
    { word: 'witness', word_class: 'V' },
    { word: 'witness', word_class: 'N' },
    { word: 'witting', word_class: 'AJ' },
    { word: 'witnessed', word_class: 'AJ' }
  ],
  inpour: [
    { word: 'inpour', word_class: 'N' },
    { word: 'inpouring', word_class: 'N' },
    { word: 'inpouring', word_class: 'AJ' }
  ],
  overseer: [
    { word: 'oversee', word_class: 'V' },
    { word: 'overseer', word_class: 'N' },
    { word: 'oversize', word_class: 'AJ' },
    { word: 'oversized', word_class: 'AJ' }
  ],
  serviceable: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  caulking: [
    { word: 'caulk', word_class: 'N' },
    { word: 'caulk', word_class: 'V' },
    { word: 'caulked', word_class: 'AJ' },
    { word: 'caulking', word_class: 'N' }
  ],
  pharisaic: [
    { word: 'pharisee', word_class: 'N' },
    { word: 'pharisaic', word_class: 'AJ' },
    { word: 'pharisaical', word_class: 'AJ' }
  ],
  botanic: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  standardized: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  exhausted: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  sociolinguistics: [
    { word: 'sociolinguist', word_class: 'N' },
    { word: 'sociolinguistic', word_class: 'AJ' },
    { word: 'sociolinguistics', word_class: 'N' },
    { word: 'sociolinguistically', word_class: 'AV' }
  ],
  temporise: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  sleeping: [
    { word: 'sleep', word_class: 'N' },
    { word: 'sleep', word_class: 'V' },
    { word: 'sleeper', word_class: 'N' },
    { word: 'sleeping', word_class: 'N' },
    { word: 'sleeping', word_class: 'AJ' }
  ],
  farting: [{ word: 'fart', word_class: 'N' }, { word: 'fart', word_class: 'V' }, { word: 'farting', word_class: 'N' }],
  sibilation: [
    { word: 'sibilant', word_class: 'N' },
    { word: 'sibilate', word_class: 'V' },
    { word: 'sibilant', word_class: 'AJ' },
    { word: 'sibilation', word_class: 'N' }
  ],
  hysterical: [
    { word: 'hysteric', word_class: 'AJ' },
    { word: 'hysterics', word_class: 'N' },
    { word: 'hysterical', word_class: 'AJ' },
    { word: 'hysterically', word_class: 'AV' }
  ],
  emulsified: [
    { word: 'emulsify', word_class: 'V' },
    { word: 'emulsifier', word_class: 'N' },
    { word: 'emulsified', word_class: 'AJ' },
    { word: 'emulsification', word_class: 'N' }
  ],
  perceptiveness: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  expectable: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  intact: [
    { word: 'intact', word_class: 'N' },
    { word: 'intact', word_class: 'AJ' },
    { word: 'intactness', word_class: 'N' }
  ],
  vibrations: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  experimentalism: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  bicuspid: [
    { word: 'bicuspid', word_class: 'N' },
    { word: 'bicuspid', word_class: 'AJ' },
    { word: 'bicuspidate', word_class: 'AJ' }
  ],
  underhand: [
    { word: 'underhand', word_class: 'AJ' },
    { word: 'underhand', word_class: 'AV' },
    { word: 'underhanded', word_class: 'AJ' }
  ],
  indelible: [
    { word: 'indelible', word_class: 'AJ' },
    { word: 'indelibly', word_class: 'AV' },
    { word: 'indelicate', word_class: 'AJ' }
  ],
  seawards: [
    { word: 'seaward', word_class: 'AJ' },
    { word: 'seaward', word_class: 'AV' },
    { word: 'seawards', word_class: 'AV' }
  ],
  improvement: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  destine: [
    { word: 'destine', word_class: 'V' },
    { word: 'destiny', word_class: 'N' },
    { word: 'destined', word_class: 'AJ' },
    { word: 'destination', word_class: 'N' }
  ],
  disinfectant: [
    { word: 'disinfect', word_class: 'V' },
    { word: 'disinfectant', word_class: 'N' },
    { word: 'disinfection', word_class: 'N' },
    { word: 'disinfectant', word_class: 'AJ' }
  ],
  titillate: [
    { word: 'titillate', word_class: 'V' },
    { word: 'titillated', word_class: 'AJ' },
    { word: 'titillation', word_class: 'N' },
    { word: 'titillating', word_class: 'AJ' }
  ],
  sew: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  governance: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  labialization: [
    { word: 'labial', word_class: 'N' },
    { word: 'labial', word_class: 'AJ' },
    { word: 'labialise', word_class: 'V' },
    { word: 'labialize', word_class: 'V' },
    { word: 'labialisation', word_class: 'N' },
    { word: 'labialization', word_class: 'N' }
  ],
  applicator: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  care: [
    { word: 'care', word_class: 'N' },
    { word: 'care', word_class: 'V' },
    { word: 'caring', word_class: 'N' },
    { word: 'caring', word_class: 'AJ' },
    { word: 'careful', word_class: 'AJ' },
    { word: 'carefulness', word_class: 'N' }
  ],
  painter: [
    { word: 'paint', word_class: 'N' },
    { word: 'paint', word_class: 'V' },
    { word: 'painter', word_class: 'N' },
    { word: 'painted', word_class: 'AJ' },
    { word: 'painting', word_class: 'N' }
  ],
  pronouncement: [
    { word: 'pronounce', word_class: 'V' },
    { word: 'pronounced', word_class: 'AJ' },
    { word: 'pronouncement', word_class: 'N' },
    { word: 'pronunciation', word_class: 'N' },
    { word: 'pronounceable', word_class: 'AJ' }
  ],
  average: [
    { word: 'average', word_class: 'N' },
    { word: 'average', word_class: 'V' },
    { word: 'average', word_class: 'AJ' },
    { word: 'averageness', word_class: 'N' }
  ],
  salacious: [
    { word: 'salacious', word_class: 'AJ' },
    { word: 'salaciously', word_class: 'AV' },
    { word: 'salaciousness', word_class: 'N' }
  ],
  putridity: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  typographer: [
    { word: 'typographer', word_class: 'N' },
    { word: 'typographic', word_class: 'AJ' },
    { word: 'typographical', word_class: 'AJ' },
    { word: 'typographically', word_class: 'AV' }
  ],
  cool: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  unconsciously: [
    { word: 'unconscious', word_class: 'N' },
    { word: 'unconscious', word_class: 'AJ' },
    { word: 'unconsciously', word_class: 'AV' },
    { word: 'unconsciousness', word_class: 'N' }
  ],
  spoon: [
    { word: 'spoon', word_class: 'N' },
    { word: 'spoon', word_class: 'V' },
    { word: 'spoonful', word_class: 'N' },
    { word: 'spoonful', word_class: 'AJ' }
  ],
  furtiveness: [
    { word: 'furtive', word_class: 'AJ' },
    { word: 'furtively', word_class: 'AV' },
    { word: 'furtiveness', word_class: 'N' }
  ],
  hemorrhagic: [
    { word: 'hemorrhage', word_class: 'N' },
    { word: 'hemorrhage', word_class: 'V' },
    { word: 'hemorrhagic', word_class: 'AJ' }
  ],
  piggyback: [
    { word: 'piggyback', word_class: 'V' },
    { word: 'piggyback', word_class: 'AJ' },
    { word: 'piggyback', word_class: 'AV' }
  ],
  firing: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  gasman: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  backgrounding: [
    { word: 'background', word_class: 'N' },
    { word: 'background', word_class: 'V' },
    { word: 'background', word_class: 'AJ' },
    { word: 'backgrounding', word_class: 'N' }
  ],
  bungee: [{ word: 'bung', word_class: 'N' }, { word: 'bung', word_class: 'V' }, { word: 'bungee', word_class: 'N' }],
  raiser: [
    { word: 'raise', word_class: 'N' },
    { word: 'raise', word_class: 'V' },
    { word: 'raiser', word_class: 'N' },
    { word: 'raised', word_class: 'AJ' },
    { word: 'raising', word_class: 'N' },
    { word: 'raising', word_class: 'AJ' }
  ],
  piffle: [
    { word: 'piffle', word_class: 'N' },
    { word: 'piffle', word_class: 'V' },
    { word: 'piffling', word_class: 'AJ' }
  ],
  concentration: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  buoy: [
    { word: 'buoy', word_class: 'N' },
    { word: 'buoy', word_class: 'V' },
    { word: 'buoyant', word_class: 'N' },
    { word: 'buoyancy', word_class: 'N' },
    { word: 'buoyant', word_class: 'AJ' }
  ],
  conjunctivitis: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  facially: [
    { word: 'facial', word_class: 'N' },
    { word: 'facial', word_class: 'AJ' },
    { word: 'facially', word_class: 'AV' }
  ],
  infarction: [
    { word: 'infarct', word_class: 'N' },
    { word: 'infarct', word_class: 'V' },
    { word: 'infarction', word_class: 'N' }
  ],
  lined: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  cupful: [
    { word: 'cup', word_class: 'N' },
    { word: 'cup', word_class: 'V' },
    { word: 'cupful', word_class: 'N' },
    { word: 'cupful', word_class: 'AJ' },
    { word: 'cupping', word_class: 'N' }
  ],
  labouring: [
    { word: 'labour', word_class: 'N' },
    { word: 'labour', word_class: 'V' },
    { word: 'labourer', word_class: 'N' },
    { word: 'laboured', word_class: 'AJ' },
    { word: 'labourite', word_class: 'N' },
    { word: 'labouring', word_class: 'AJ' }
  ],
  curable: [
    { word: 'curable', word_class: 'AJ' },
    { word: 'curability', word_class: 'N' },
    { word: 'curableness', word_class: 'N' }
  ],
  strainer: [
    { word: 'strain', word_class: 'N' },
    { word: 'strain', word_class: 'V' },
    { word: 'strainer', word_class: 'N' },
    { word: 'strained', word_class: 'AJ' },
    { word: 'straining', word_class: 'N' },
    { word: 'straining', word_class: 'AJ' }
  ],
  pacifistic: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  shorten: [
    { word: 'shorten', word_class: 'V' },
    { word: 'shortened', word_class: 'AJ' },
    { word: 'shortening', word_class: 'N' }
  ],
  microscopical: [
    { word: 'microscope', word_class: 'N' },
    { word: 'microscopic', word_class: 'AJ' },
    { word: 'microscopical', word_class: 'AJ' },
    { word: 'microscopically', word_class: 'AV' }
  ],
  corbelled: [
    { word: 'corbel', word_class: 'N' },
    { word: 'corbel', word_class: 'V' },
    { word: 'corbelled', word_class: 'AJ' }
  ],
  summarize: [
    { word: 'summary', word_class: 'N' },
    { word: 'summary', word_class: 'AJ' },
    { word: 'summarise', word_class: 'V' },
    { word: 'summarize', word_class: 'V' },
    { word: 'summarisation', word_class: 'N' },
    { word: 'summarization', word_class: 'N' }
  ],
  venerably: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  mated: [
    { word: 'mate', word_class: 'V' },
    { word: 'mate', word_class: 'N' },
    { word: 'mater', word_class: 'N' },
    { word: 'mates', word_class: 'N' },
    { word: 'mated', word_class: 'AJ' },
    { word: 'mating', word_class: 'N' }
  ],
  magniloquence: [
    { word: 'magniloquence', word_class: 'N' },
    { word: 'magniloquent', word_class: 'AJ' },
    { word: 'magniloquently', word_class: 'AV' }
  ],
  ninety: [
    { word: 'ninety', word_class: 'N' },
    { word: 'ninety', word_class: 'AJ' },
    { word: 'nineties', word_class: 'N' }
  ],
  drawee: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  usury: [
    { word: 'usury', word_class: 'N' },
    { word: 'usurious', word_class: 'AJ' },
    { word: 'usuriously', word_class: 'AV' }
  ],
  recount: [
    { word: 'recount', word_class: 'N' },
    { word: 'recount', word_class: 'V' },
    { word: 'recountal', word_class: 'N' },
    { word: 'recounting', word_class: 'N' }
  ],
  birch: [{ word: 'birch', word_class: 'N' }, { word: 'birch', word_class: 'V' }, { word: 'birch', word_class: 'AJ' }],
  bemuse: [
    { word: 'bemuse', word_class: 'V' },
    { word: 'bemused', word_class: 'AJ' },
    { word: 'bemusement', word_class: 'N' }
  ],
  vibrate: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  sublimated: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  innings: [
    { word: 'in', word_class: 'N' },
    { word: 'in', word_class: 'AJ' },
    { word: 'in', word_class: 'AV' },
    { word: 'inning', word_class: 'N' },
    { word: 'innings', word_class: 'N' }
  ],
  consensus: [
    { word: 'consent', word_class: 'N' },
    { word: 'consent', word_class: 'V' },
    { word: 'consensus', word_class: 'N' },
    { word: 'consensual', word_class: 'AJ' },
    { word: 'consenting', word_class: 'AJ' }
  ],
  ballade: [
    { word: 'ballad', word_class: 'N' },
    { word: 'ballade', word_class: 'N' },
    { word: 'balladeer', word_class: 'N' }
  ],
  coast: [{ word: 'coast', word_class: 'N' }, { word: 'coast', word_class: 'V' }, { word: 'coaster', word_class: 'N' }],
  furred: [
    { word: 'fur', word_class: 'N' },
    { word: 'fur', word_class: 'V' },
    { word: 'furor', word_class: 'N' },
    { word: 'furore', word_class: 'N' },
    { word: 'furred', word_class: 'AJ' },
    { word: 'furring', word_class: 'N' }
  ],
  voluminous: [
    { word: 'volume', word_class: 'N' },
    { word: 'voluminous', word_class: 'AJ' },
    { word: 'voluminousness', word_class: 'N' }
  ],
  fourth: [
    { word: 'fourth', word_class: 'N' },
    { word: 'fourth', word_class: 'AJ' },
    { word: 'fourth', word_class: 'AV' }
  ],
  corruptible: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  charitable: [
    { word: 'charitable', word_class: 'AJ' },
    { word: 'charitably', word_class: 'AV' },
    { word: 'charitableness', word_class: 'N' }
  ],
  obnoxious: [
    { word: 'obnoxious', word_class: 'AJ' },
    { word: 'obnoxiously', word_class: 'AV' },
    { word: 'obnoxiousness', word_class: 'N' }
  ],
  marbleizing: [
    { word: 'marbleize', word_class: 'V' },
    { word: 'marbleized', word_class: 'AJ' },
    { word: 'marbleizing', word_class: 'N' },
    { word: 'marbleization', word_class: 'N' }
  ],
  astringent: [
    { word: 'astringent', word_class: 'N' },
    { word: 'astringence', word_class: 'N' },
    { word: 'astringency', word_class: 'N' },
    { word: 'astringent', word_class: 'AJ' }
  ],
  sedimentation: [
    { word: 'sediment', word_class: 'N' },
    { word: 'sediment', word_class: 'V' },
    { word: 'sedimentary', word_class: 'AJ' },
    { word: 'sedimentation', word_class: 'N' }
  ],
  bang: [
    { word: 'bang', word_class: 'V' },
    { word: 'bang', word_class: 'N' },
    { word: 'bang', word_class: 'AV' },
    { word: 'banger', word_class: 'N' },
    { word: 'banging', word_class: 'N' },
    { word: 'banging', word_class: 'AJ' }
  ],
  idyllically: [
    { word: 'idyll', word_class: 'N' },
    { word: 'idyllic', word_class: 'AJ' },
    { word: 'idyllically', word_class: 'AV' }
  ],
  geomorphologic: [
    { word: 'geomorphology', word_class: 'N' },
    { word: 'geomorphologic', word_class: 'AJ' },
    { word: 'geomorphological', word_class: 'AJ' }
  ],
  dropkicker: [
    { word: 'dropkick', word_class: 'N' },
    { word: 'dropkick', word_class: 'V' },
    { word: 'dropkicker', word_class: 'N' }
  ],
  exacerbate: [
    { word: 'exacerbate', word_class: 'V' },
    { word: 'exacerbation', word_class: 'N' },
    { word: 'exacerbating', word_class: 'AJ' }
  ],
  asphalt: [
    { word: 'asphalt', word_class: 'N' },
    { word: 'asphalt', word_class: 'V' },
    { word: 'asphalt', word_class: 'AJ' },
    { word: 'asphaltic', word_class: 'AJ' }
  ],
  contraction: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  parting: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  masculinity: [
    { word: 'masculine', word_class: 'N' },
    { word: 'masculine', word_class: 'AJ' },
    { word: 'masculinity', word_class: 'N' },
    { word: 'masculinization', word_class: 'N' }
  ],
  tangibleness: [
    { word: 'tangible', word_class: 'AJ' },
    { word: 'tangibly', word_class: 'AV' },
    { word: 'tangibility', word_class: 'N' },
    { word: 'tangibleness', word_class: 'N' }
  ],
  synonym: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  corrosive: [
    { word: 'corrode', word_class: 'V' },
    { word: 'corroded', word_class: 'AJ' },
    { word: 'corroding', word_class: 'N' },
    { word: 'corrosion', word_class: 'N' },
    { word: 'corrosive', word_class: 'N' },
    { word: 'corrosive', word_class: 'AJ' }
  ],
  spat: [{ word: 'spat', word_class: 'N' }, { word: 'spat', word_class: 'V' }, { word: 'spats', word_class: 'N' }],
  feudal: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  alerting: [
    { word: 'alert', word_class: 'N' },
    { word: 'alert', word_class: 'V' },
    { word: 'alert', word_class: 'AJ' },
    { word: 'alerting', word_class: 'N' },
    { word: 'alertness', word_class: 'N' }
  ],
  cutely: [
    { word: 'cute', word_class: 'AJ' },
    { word: 'cutely', word_class: 'AV' },
    { word: 'cuteness', word_class: 'N' }
  ],
  blinder: [
    { word: 'blind', word_class: 'N' },
    { word: 'blind', word_class: 'V' },
    { word: 'blind', word_class: 'AJ' },
    { word: 'blinder', word_class: 'N' },
    { word: 'blinded', word_class: 'AJ' },
    { word: 'blinding', word_class: 'AJ' },
    { word: 'blindness', word_class: 'N' }
  ],
  sponsorship: [
    { word: 'sponsor', word_class: 'N' },
    { word: 'sponsor', word_class: 'V' },
    { word: 'sponsorship', word_class: 'N' }
  ],
  atonalism: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  intricately: [
    { word: 'intricacy', word_class: 'N' },
    { word: 'intricate', word_class: 'AJ' },
    { word: 'intricately', word_class: 'AV' }
  ],
  spellbinder: [
    { word: 'spellbind', word_class: 'V' },
    { word: 'spellbinder', word_class: 'N' },
    { word: 'spellbinding', word_class: 'AJ' }
  ],
  snuffer: [
    { word: 'snuff', word_class: 'N' },
    { word: 'snuff', word_class: 'V' },
    { word: 'snuff', word_class: 'AJ' },
    { word: 'snuffer', word_class: 'N' },
    { word: 'snuffers', word_class: 'N' }
  ],
  bodied: [{ word: 'body', word_class: 'N' }, { word: 'body', word_class: 'V' }, { word: 'bodied', word_class: 'AJ' }],
  defamer: [
    { word: 'defame', word_class: 'V' },
    { word: 'defamer', word_class: 'N' },
    { word: 'defamation', word_class: 'N' },
    { word: 'defamatory', word_class: 'AJ' }
  ],
  balkanisation: [
    { word: 'balkans', word_class: 'N' },
    { word: 'balkanise', word_class: 'V' },
    { word: 'balkanize', word_class: 'V' },
    { word: 'balkanisation', word_class: 'N' },
    { word: 'balkanization', word_class: 'N' }
  ],
  will: [
    { word: 'will', word_class: 'N' },
    { word: 'will', word_class: 'V' },
    { word: 'willing', word_class: 'N' },
    { word: 'willful', word_class: 'AJ' },
    { word: 'willing', word_class: 'AJ' },
    { word: 'willfulness', word_class: 'N' }
  ],
  thickener: [
    { word: 'thicken', word_class: 'V' },
    { word: 'thickener', word_class: 'N' },
    { word: 'thickened', word_class: 'AJ' },
    { word: 'thickening', word_class: 'N' },
    { word: 'thickening', word_class: 'AJ' }
  ],
  embracing: [
    { word: 'embrace', word_class: 'N' },
    { word: 'embrace', word_class: 'V' },
    { word: 'embracing', word_class: 'N' },
    { word: 'embracement', word_class: 'N' }
  ],
  branchiopodous: [
    { word: 'branchiopod', word_class: 'N' },
    { word: 'branchiopod', word_class: 'AJ' },
    { word: 'branchiopodous', word_class: 'AJ' }
  ],
  pruner: [
    { word: 'prune', word_class: 'N' },
    { word: 'prune', word_class: 'V' },
    { word: 'pruner', word_class: 'N' },
    { word: 'pruned', word_class: 'AJ' },
    { word: 'pruning', word_class: 'N' }
  ],
  vendor: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  predisposal: [
    { word: 'predispose', word_class: 'V' },
    { word: 'predisposal', word_class: 'N' },
    { word: 'predisposed', word_class: 'AJ' },
    { word: 'predisposition', word_class: 'N' }
  ],
  emotionalism: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  revere: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  circumstance: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  reconstruct: [
    { word: 'reconstruct', word_class: 'V' },
    { word: 'reconstructed', word_class: 'AJ' },
    { word: 'reconstruction', word_class: 'N' },
    { word: 'reconstructive', word_class: 'AJ' }
  ],
  molded: [
    { word: 'mold', word_class: 'N' },
    { word: 'mold', word_class: 'V' },
    { word: 'molded', word_class: 'AJ' },
    { word: 'molding', word_class: 'N' }
  ],
  detergence: [
    { word: 'detergent', word_class: 'N' },
    { word: 'detergence', word_class: 'N' },
    { word: 'detergency', word_class: 'N' },
    { word: 'detergent', word_class: 'AJ' }
  ],
  motored: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  governess: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  flannel: [
    { word: 'flannel', word_class: 'N' },
    { word: 'flannel', word_class: 'V' },
    { word: 'flannels', word_class: 'N' }
  ],
  fort: [
    { word: 'fort', word_class: 'N' },
    { word: 'fort', word_class: 'V' },
    { word: 'forte', word_class: 'N' },
    { word: 'forte', word_class: 'AJ' },
    { word: 'forte', word_class: 'AV' }
  ],
  urine: [
    { word: 'urine', word_class: 'N' },
    { word: 'urinal', word_class: 'N' },
    { word: 'urinal', word_class: 'AJ' },
    { word: 'urinate', word_class: 'V' },
    { word: 'urinary', word_class: 'AJ' },
    { word: 'urination', word_class: 'N' }
  ],
  chanter: [
    { word: 'chant', word_class: 'N' },
    { word: 'chant', word_class: 'V' },
    { word: 'chanter', word_class: 'N' },
    { word: 'chantry', word_class: 'N' },
    { word: 'chanted', word_class: 'AJ' },
    { word: 'chanting', word_class: 'N' }
  ],
  ignorantness: [
    { word: 'ignore', word_class: 'V' },
    { word: 'ignorant', word_class: 'N' },
    { word: 'ignored', word_class: 'AJ' },
    { word: 'ignorance', word_class: 'N' },
    { word: 'ignorant', word_class: 'AJ' },
    { word: 'ignorantness', word_class: 'N' }
  ],
  yodel: [
    { word: 'yodel', word_class: 'N' },
    { word: 'yodel', word_class: 'V' },
    { word: 'yodeling', word_class: 'N' },
    { word: 'yodeller', word_class: 'N' }
  ],
  beloved: [
    { word: 'belove', word_class: 'V' },
    { word: 'beloved', word_class: 'N' },
    { word: 'beloved', word_class: 'AJ' }
  ],
  harden: [
    { word: 'harden', word_class: 'V' },
    { word: 'hardened', word_class: 'AJ' },
    { word: 'hardening', word_class: 'N' }
  ],
  execration: [
    { word: 'execrate', word_class: 'V' },
    { word: 'execrable', word_class: 'AJ' },
    { word: 'execration', word_class: 'N' }
  ],
  commercially: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  acetylation: [
    { word: 'acetyl', word_class: 'N' },
    { word: 'acetylate', word_class: 'V' },
    { word: 'acetylic', word_class: 'AJ' },
    { word: 'acetylize', word_class: 'V' },
    { word: 'acetylation', word_class: 'N' }
  ],
  boned: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  shades: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  obsess: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  pure: [
    { word: 'puree', word_class: 'V' },
    { word: 'puree', word_class: 'N' },
    { word: 'pure', word_class: 'AJ' },
    { word: 'purely', word_class: 'AV' },
    { word: 'pureness', word_class: 'N' }
  ],
  discreteness: [
    { word: 'discrete', word_class: 'N' },
    { word: 'discrete', word_class: 'AJ' },
    { word: 'discretion', word_class: 'N' },
    { word: 'discreteness', word_class: 'N' },
    { word: 'discretional', word_class: 'AJ' }
  ],
  gallantry: [
    { word: 'gall', word_class: 'V' },
    { word: 'gall', word_class: 'N' },
    { word: 'gallant', word_class: 'N' },
    { word: 'gallant', word_class: 'V' },
    { word: 'galled', word_class: 'AJ' },
    { word: 'gallant', word_class: 'AJ' },
    { word: 'galling', word_class: 'AJ' },
    { word: 'gallantry', word_class: 'N' }
  ],
  expandable: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  expatriate: [
    { word: 'expatriate', word_class: 'N' },
    { word: 'expatriate', word_class: 'V' },
    { word: 'expatriation', word_class: 'N' }
  ],
  ugly: [
    { word: 'ugli', word_class: 'N' },
    { word: 'ugly', word_class: 'AJ' },
    { word: 'uglify', word_class: 'V' },
    { word: 'ugliness', word_class: 'N' },
    { word: 'uglification', word_class: 'N' }
  ],
  volute: [
    { word: 'volute', word_class: 'N' },
    { word: 'volute', word_class: 'AJ' },
    { word: 'voluted', word_class: 'AJ' },
    { word: 'volution', word_class: 'N' }
  ],
  converter: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  grow: [
    { word: 'grow', word_class: 'V' },
    { word: 'grower', word_class: 'N' },
    { word: 'grown', word_class: 'AJ' },
    { word: 'growth', word_class: 'N' },
    { word: 'growing', word_class: 'N' },
    { word: 'growing', word_class: 'AJ' }
  ],
  moderating: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  lexicalize: [
    { word: 'lexical', word_class: 'AJ' },
    { word: 'lexicalize', word_class: 'V' },
    { word: 'lexically', word_class: 'AV' }
  ],
  questioner: [
    { word: 'question', word_class: 'N' },
    { word: 'question', word_class: 'V' },
    { word: 'questioner', word_class: 'N' },
    { word: 'questioning', word_class: 'N' },
    { word: 'questioning', word_class: 'AJ' },
    { word: 'questionable', word_class: 'AJ' },
    { word: 'questionably', word_class: 'AV' }
  ],
  basely: [
    { word: 'base', word_class: 'N' },
    { word: 'base', word_class: 'V' },
    { word: 'base', word_class: 'AJ' },
    { word: 'based', word_class: 'AJ' },
    { word: 'basely', word_class: 'AV' },
    { word: 'baseness', word_class: 'N' }
  ],
  hurl: [
    { word: 'hurl', word_class: 'N' },
    { word: 'hurl', word_class: 'V' },
    { word: 'hurler', word_class: 'N' },
    { word: 'hurling', word_class: 'N' },
    { word: 'hurling', word_class: 'AJ' }
  ],
  stark: [
    { word: 'stark', word_class: 'AJ' },
    { word: 'stark', word_class: 'AV' },
    { word: 'starkness', word_class: 'N' }
  ],
  facer: [
    { word: 'face', word_class: 'N' },
    { word: 'face', word_class: 'V' },
    { word: 'facer', word_class: 'N' },
    { word: 'faced', word_class: 'AJ' },
    { word: 'facing', word_class: 'N' },
    { word: 'faction', word_class: 'N' }
  ],
  typewriting: [
    { word: 'typewrite', word_class: 'V' },
    { word: 'typewriter', word_class: 'N' },
    { word: 'typewriting', word_class: 'N' },
    { word: 'typewritten', word_class: 'AJ' }
  ],
  trephine: [
    { word: 'trephine', word_class: 'N' },
    { word: 'trephine', word_class: 'V' },
    { word: 'trephination', word_class: 'N' }
  ],
  inordinate: [
    { word: 'inordinate', word_class: 'N' },
    { word: 'inordinate', word_class: 'AJ' },
    { word: 'inordinately', word_class: 'AV' },
    { word: 'inordinateness', word_class: 'N' }
  ],
  announce: [
    { word: 'announce', word_class: 'V' },
    { word: 'announcer', word_class: 'N' },
    { word: 'announced', word_class: 'AJ' },
    { word: 'anunciation', word_class: 'N' },
    { word: 'announcement', word_class: 'N' }
  ],
  reading: [
    { word: 'read', word_class: 'N' },
    { word: 'read', word_class: 'V' },
    { word: 'read', word_class: 'AJ' },
    { word: 'reader', word_class: 'N' },
    { word: 'reading', word_class: 'N' }
  ],
  abstinent: [
    { word: 'abstain', word_class: 'V' },
    { word: 'abstainer', word_class: 'N' },
    { word: 'abstinent', word_class: 'N' },
    { word: 'abstention', word_class: 'N' },
    { word: 'abstinence', word_class: 'N' },
    { word: 'abstinent', word_class: 'AJ' }
  ],
  commit: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  effortfulness: [
    { word: 'effort', word_class: 'N' },
    { word: 'effortful', word_class: 'AJ' },
    { word: 'effortfulness', word_class: 'N' }
  ],
  extinguishing: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  domineering: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  pitman: [
    { word: 'pit', word_class: 'N' },
    { word: 'pit', word_class: 'V' },
    { word: 'pitman', word_class: 'N' },
    { word: 'pitted', word_class: 'AJ' },
    { word: 'pitting', word_class: 'N' }
  ],
  rationing: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  dependance: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  constitution: [
    { word: 'constitute', word_class: 'V' },
    { word: 'consitution', word_class: 'N' },
    { word: 'constitution', word_class: 'N' },
    { word: 'constitutive', word_class: 'AJ' },
    { word: 'constitutional', word_class: 'N' },
    { word: 'constitutional', word_class: 'AJ' }
  ],
  cuboid: [
    { word: 'cuboid', word_class: 'N' },
    { word: 'cuboid', word_class: 'AJ' },
    { word: 'cuboidal', word_class: 'AJ' }
  ],
  beatification: [
    { word: 'beatify', word_class: 'V' },
    { word: 'beatific', word_class: 'AJ' },
    { word: 'beatified', word_class: 'AJ' },
    { word: 'beatification', word_class: 'N' }
  ],
  intemperateness: [
    { word: 'intemperance', word_class: 'N' },
    { word: 'intemperate', word_class: 'AJ' },
    { word: 'intemperately', word_class: 'AV' },
    { word: 'intemperateness', word_class: 'N' }
  ],
  fraudulent: [
    { word: 'fraud', word_class: 'N' },
    { word: 'fraudulence', word_class: 'N' },
    { word: 'fraudulent', word_class: 'AJ' },
    { word: 'fraudulently', word_class: 'AV' }
  ],
  diagrammatic: [
    { word: 'diagram', word_class: 'N' },
    { word: 'diagram', word_class: 'V' },
    { word: 'diagramming', word_class: 'N' },
    { word: 'diagrammatic', word_class: 'AJ' },
    { word: 'diagrammatical', word_class: 'AJ' },
    { word: 'diagrammatically', word_class: 'AV' }
  ],
  permeating: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  reprehensibility: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  unveil: [
    { word: 'unveil', word_class: 'V' },
    { word: 'unveiled', word_class: 'AJ' },
    { word: 'unveiling', word_class: 'N' }
  ],
  condole: [
    { word: 'condole', word_class: 'V' },
    { word: 'condolence', word_class: 'N' },
    { word: 'condolement', word_class: 'N' }
  ],
  usualness: [
    { word: 'usual', word_class: 'AJ' },
    { word: 'usually', word_class: 'AV' },
    { word: 'usualness', word_class: 'N' }
  ],
  confession: [
    { word: 'confess', word_class: 'V' },
    { word: 'confessor', word_class: 'N' },
    { word: 'confessed', word_class: 'AJ' },
    { word: 'confession', word_class: 'N' }
  ],
  subtropical: [
    { word: 'subtropic', word_class: 'AJ' },
    { word: 'subtropics', word_class: 'N' },
    { word: 'subtropical', word_class: 'AJ' }
  ],
  pitcherful: [
    { word: 'pitch', word_class: 'N' },
    { word: 'pitch', word_class: 'V' },
    { word: 'pitcher', word_class: 'N' },
    { word: 'pitched', word_class: 'AJ' },
    { word: 'pitching', word_class: 'N' },
    { word: 'pitcherful', word_class: 'N' }
  ],
  severely: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  eruditeness: [
    { word: 'erudite', word_class: 'AJ' },
    { word: 'erudition', word_class: 'N' },
    { word: 'eruditely', word_class: 'AV' },
    { word: 'eruditeness', word_class: 'N' }
  ],
  slithery: [
    { word: 'slither', word_class: 'V' },
    { word: 'slithery', word_class: 'AJ' },
    { word: 'slithering', word_class: 'AJ' }
  ],
  abortively: [
    { word: 'abort', word_class: 'V' },
    { word: 'abortion', word_class: 'N' },
    { word: 'abortive', word_class: 'AJ' },
    { word: 'abortively', word_class: 'AV' }
  ],
  committal: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  dicotyledon: [
    { word: 'dicotyledon', word_class: 'N' },
    { word: 'dicotyledones', word_class: 'N' },
    { word: 'dicotyledonous', word_class: 'AJ' }
  ],
  tide: [
    { word: 'tide', word_class: 'N' },
    { word: 'tide', word_class: 'V' },
    { word: 'tiding', word_class: 'N' },
    { word: 'tidings', word_class: 'N' }
  ],
  shipping: [
    { word: 'ship', word_class: 'N' },
    { word: 'ship', word_class: 'V' },
    { word: 'shipment', word_class: 'N' },
    { word: 'shipping', word_class: 'N' }
  ],
  politic: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  reign: [
    { word: 'reign', word_class: 'N' },
    { word: 'reign', word_class: 'V' },
    { word: 'reigning', word_class: 'AJ' }
  ],
  puncturing: [
    { word: 'puncture', word_class: 'N' },
    { word: 'puncture', word_class: 'V' },
    { word: 'punctured', word_class: 'AJ' },
    { word: 'puncturing', word_class: 'N' },
    { word: 'puncturable', word_class: 'AJ' }
  ],
  inconveniently: [
    { word: 'inconvenience', word_class: 'N' },
    { word: 'inconvenience', word_class: 'V' },
    { word: 'inconvenient', word_class: 'AJ' },
    { word: 'inconveniently', word_class: 'AV' }
  ],
  dermatological: [
    { word: 'dermatology', word_class: 'N' },
    { word: 'dermatologic', word_class: 'AJ' },
    { word: 'dermatological', word_class: 'AJ' }
  ],
  excitatory: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  despoilment: [
    { word: 'despoil', word_class: 'V' },
    { word: 'despoiler', word_class: 'N' },
    { word: 'despoiled', word_class: 'AJ' },
    { word: 'despoilment', word_class: 'N' },
    { word: 'despoliation', word_class: 'N' }
  ],
  ingression: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  togetherness: [
    { word: 'together', word_class: 'AJ' },
    { word: 'together', word_class: 'AV' },
    { word: 'togetherness', word_class: 'N' }
  ],
  normanize: [
    { word: 'norman', word_class: 'N' },
    { word: 'norman', word_class: 'AJ' },
    { word: 'normanise', word_class: 'V' },
    { word: 'normanize', word_class: 'V' },
    { word: 'normanisation', word_class: 'N' },
    { word: 'normanization', word_class: 'N' }
  ],
  coagulate: [
    { word: 'coagulate', word_class: 'V' },
    { word: 'coagulate', word_class: 'AJ' },
    { word: 'coagulable', word_class: 'AJ' },
    { word: 'coagulated', word_class: 'AJ' },
    { word: 'coagulation', word_class: 'N' }
  ],
  moneymaker: [
    { word: 'moneymaker', word_class: 'N' },
    { word: 'moneymaking', word_class: 'N' },
    { word: 'moneymaking', word_class: 'AJ' }
  ],
  dogmatise: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  beard: [
    { word: 'beard', word_class: 'N' },
    { word: 'beard', word_class: 'V' },
    { word: 'bearded', word_class: 'AJ' }
  ],
  grinning: [
    { word: 'grin', word_class: 'N' },
    { word: 'grin', word_class: 'V' },
    { word: 'grinning', word_class: 'N' }
  ],
  protector: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  swarming: [
    { word: 'swarm', word_class: 'N' },
    { word: 'swarm', word_class: 'V' },
    { word: 'swarming', word_class: 'AJ' }
  ],
  obstinacy: [
    { word: 'obstinacy', word_class: 'N' },
    { word: 'obstinance', word_class: 'N' },
    { word: 'obstinate', word_class: 'AJ' },
    { word: 'obstinately', word_class: 'AV' }
  ],
  topee: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  newness: [
    { word: 'new', word_class: 'AJ' },
    { word: 'new', word_class: 'AV' },
    { word: 'news', word_class: 'N' },
    { word: 'newness', word_class: 'N' }
  ],
  dynamically: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  biennially: [
    { word: 'biennial', word_class: 'N' },
    { word: 'biennial', word_class: 'AJ' },
    { word: 'biennially', word_class: 'AV' }
  ],
  iterate: [
    { word: 'iterate', word_class: 'V' },
    { word: 'iterance', word_class: 'N' },
    { word: 'iteration', word_class: 'N' },
    { word: 'iterative', word_class: 'AJ' }
  ],
  optically: [
    { word: 'optic', word_class: 'N' },
    { word: 'optic', word_class: 'AJ' },
    { word: 'optics', word_class: 'N' },
    { word: 'optical', word_class: 'AJ' },
    { word: 'optically', word_class: 'AV' }
  ],
  eighty: [
    { word: 'eighty', word_class: 'N' },
    { word: 'eighty', word_class: 'AJ' },
    { word: 'eighties', word_class: 'N' }
  ],
  identification: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  forfeiture: [
    { word: 'forfeit', word_class: 'N' },
    { word: 'forfeit', word_class: 'V' },
    { word: 'forfeit', word_class: 'AJ' },
    { word: 'forfeited', word_class: 'AJ' },
    { word: 'forfeiture', word_class: 'N' }
  ],
  inspection: [
    { word: 'inspect', word_class: 'V' },
    { word: 'inspector', word_class: 'N' },
    { word: 'inspection', word_class: 'N' },
    { word: 'inspectorate', word_class: 'N' }
  ],
  loose: [
    { word: 'loose', word_class: 'V' },
    { word: 'loose', word_class: 'AJ' },
    { word: 'loose', word_class: 'AV' },
    { word: 'loosely', word_class: 'AV' },
    { word: 'looseness', word_class: 'N' }
  ],
  wink: [
    { word: 'wink', word_class: 'N' },
    { word: 'wink', word_class: 'V' },
    { word: 'winker', word_class: 'N' },
    { word: 'winking', word_class: 'AJ' }
  ],
  correspondent: [
    { word: 'correspond', word_class: 'V' },
    { word: 'correspondent', word_class: 'N' },
    { word: 'correspondence', word_class: 'N' },
    { word: 'correspondent', word_class: 'AJ' },
    { word: 'corresponding', word_class: 'AJ' }
  ],
  articulation: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  existential: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  ponderable: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  aides: [
    { word: 'aid', word_class: 'N' },
    { word: 'aid', word_class: 'V' },
    { word: 'aide', word_class: 'N' },
    { word: 'aids', word_class: 'N' },
    { word: 'aides', word_class: 'N' },
    { word: 'aided', word_class: 'AJ' }
  ],
  seizure: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  us: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  cuspidate: [
    { word: 'cuspid', word_class: 'N' },
    { word: 'cuspidal', word_class: 'AJ' },
    { word: 'cuspidate', word_class: 'AJ' },
    { word: 'cuspidated', word_class: 'AJ' },
    { word: 'cuspidation', word_class: 'N' }
  ],
  angulate: [
    { word: 'angulate', word_class: 'V' },
    { word: 'angulate', word_class: 'AJ' },
    { word: 'angulation', word_class: 'N' }
  ],
  wiring: [
    { word: 'wire', word_class: 'N' },
    { word: 'wire', word_class: 'V' },
    { word: 'wiry', word_class: 'AJ' },
    { word: 'wired', word_class: 'AJ' },
    { word: 'wiring', word_class: 'N' }
  ],
  syllabic: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  droning: [
    { word: 'drone', word_class: 'N' },
    { word: 'drone', word_class: 'V' },
    { word: 'droning', word_class: 'N' },
    { word: 'droning', word_class: 'AJ' }
  ],
  usurious: [
    { word: 'usury', word_class: 'N' },
    { word: 'usurious', word_class: 'AJ' },
    { word: 'usuriously', word_class: 'AV' }
  ],
  parentally: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  impervious: [
    { word: 'impervious', word_class: 'AJ' },
    { word: 'imperviable', word_class: 'AJ' },
    { word: 'imperviousness', word_class: 'N' }
  ],
  compensated: [
    { word: 'compensate', word_class: 'V' },
    { word: 'compensable', word_class: 'AJ' },
    { word: 'compensated', word_class: 'AJ' },
    { word: 'compensation', word_class: 'N' },
    { word: 'compensative', word_class: 'AJ' }
  ],
  porter: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  mensural: [
    { word: 'mensural', word_class: 'AJ' },
    { word: 'mensurable', word_class: 'AJ' },
    { word: 'mensuration', word_class: 'N' }
  ],
  camper: [
    { word: 'camp', word_class: 'N' },
    { word: 'camp', word_class: 'V' },
    { word: 'camp', word_class: 'AJ' },
    { word: 'camper', word_class: 'N' },
    { word: 'camping', word_class: 'N' },
    { word: 'campion', word_class: 'N' }
  ],
  outspread: [
    { word: 'outspread', word_class: 'N' },
    { word: 'outspread', word_class: 'V' },
    { word: 'outspread', word_class: 'AJ' }
  ],
  undernourished: [
    { word: 'undernourish', word_class: 'V' },
    { word: 'undernourished', word_class: 'AJ' },
    { word: 'undernourishment', word_class: 'N' }
  ],
  inboard: [
    { word: 'inboard', word_class: 'N' },
    { word: 'inboard', word_class: 'V' },
    { word: 'inboard', word_class: 'AJ' }
  ],
  revelatory: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  chockful: [
    { word: 'chock', word_class: 'N' },
    { word: 'chock', word_class: 'V' },
    { word: 'chock', word_class: 'AV' },
    { word: 'chockful', word_class: 'AJ' }
  ],
  agrology: [
    { word: 'agrology', word_class: 'N' },
    { word: 'agrologic', word_class: 'AJ' },
    { word: 'agrological', word_class: 'AJ' }
  ],
  geologise: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  substantivisation: [
    { word: 'substantival', word_class: 'AJ' },
    { word: 'substantivise', word_class: 'V' },
    { word: 'substantivize', word_class: 'V' },
    { word: 'substantivisation', word_class: 'N' },
    { word: 'substantivization', word_class: 'N' }
  ],
  incoherent: [
    { word: 'incoherence', word_class: 'N' },
    { word: 'incoherency', word_class: 'N' },
    { word: 'incoherent', word_class: 'AJ' },
    { word: 'incoherently', word_class: 'AV' }
  ],
  moldered: [
    { word: 'molder', word_class: 'V' },
    { word: 'moldered', word_class: 'AJ' },
    { word: 'moldering', word_class: 'AJ' }
  ],
  contrive: [
    { word: 'contrive', word_class: 'V' },
    { word: 'contriver', word_class: 'N' },
    { word: 'contrived', word_class: 'AJ' },
    { word: 'contrivance', word_class: 'N' }
  ],
  respiration: [
    { word: 'respire', word_class: 'N' },
    { word: 'respire', word_class: 'V' },
    { word: 'respirate', word_class: 'V' },
    { word: 'respirator', word_class: 'N' },
    { word: 'respiration', word_class: 'N' }
  ],
  resemblance: [
    { word: 'resemble', word_class: 'V' },
    { word: 'resemblant', word_class: 'N' },
    { word: 'resemblance', word_class: 'N' },
    { word: 'resemblant', word_class: 'AJ' }
  ],
  extractible: [
    { word: 'extract', word_class: 'N' },
    { word: 'extract', word_class: 'V' },
    { word: 'extractor', word_class: 'N' },
    { word: 'extraction', word_class: 'N' },
    { word: 'extractable', word_class: 'AJ' },
    { word: 'extractible', word_class: 'AJ' }
  ],
  skinned: [
    { word: 'skin', word_class: 'N' },
    { word: 'skin', word_class: 'V' },
    { word: 'skinful', word_class: 'N' },
    { word: 'skinful', word_class: 'AJ' },
    { word: 'skinned', word_class: 'AJ' }
  ],
  britisher: [
    { word: 'british', word_class: 'N' },
    { word: 'british', word_class: 'AJ' },
    { word: 'britisher', word_class: 'N' },
    { word: 'britishism', word_class: 'N' }
  ],
  judiciously: [
    { word: 'judicial', word_class: 'AJ' },
    { word: 'judicious', word_class: 'AJ' },
    { word: 'judicially', word_class: 'AV' },
    { word: 'judiciously', word_class: 'AV' },
    { word: 'judiciousness', word_class: 'N' }
  ],
  roads: [{ word: 'road', word_class: 'N' }, { word: 'road', word_class: 'AJ' }, { word: 'roads', word_class: 'N' }],
  feudally: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  couple: [
    { word: 'couple', word_class: 'N' },
    { word: 'couple', word_class: 'V' },
    { word: 'couple', word_class: 'AJ' },
    { word: 'coupler', word_class: 'N' },
    { word: 'coupled', word_class: 'AJ' },
    { word: 'coupling', word_class: 'N' }
  ],
  appareled: [
    { word: 'apparel', word_class: 'N' },
    { word: 'apparel', word_class: 'V' },
    { word: 'appareled', word_class: 'AJ' }
  ],
  fused: [
    { word: 'fuse', word_class: 'N' },
    { word: 'fuse', word_class: 'V' },
    { word: 'fusee', word_class: 'N' },
    { word: 'fused', word_class: 'AJ' },
    { word: 'fusion', word_class: 'N' }
  ],
  surveil: [
    { word: 'surveil', word_class: 'N' },
    { word: 'surveil', word_class: 'V' },
    { word: 'surveillance', word_class: 'N' }
  ],
  dehisce: [
    { word: 'dehisce', word_class: 'V' },
    { word: 'dehiscence', word_class: 'N' },
    { word: 'dehiscent', word_class: 'AJ' }
  ],
  pillaged: [
    { word: 'pillage', word_class: 'N' },
    { word: 'pillage', word_class: 'V' },
    { word: 'pillager', word_class: 'N' },
    { word: 'pillaged', word_class: 'AJ' },
    { word: 'pillaging', word_class: 'N' }
  ],
  disseminate: [
    { word: 'disseminate', word_class: 'V' },
    { word: 'disseminator', word_class: 'N' },
    { word: 'dissemination', word_class: 'N' },
    { word: 'disseminating', word_class: 'AJ' },
    { word: 'disseminative', word_class: 'AJ' }
  ],
  writer: [
    { word: 'write', word_class: 'V' },
    { word: 'writer', word_class: 'N' },
    { word: 'writing', word_class: 'N' },
    { word: 'writings', word_class: 'N' },
    { word: 'written', word_class: 'AJ' }
  ],
  swaggerer: [
    { word: 'swagger', word_class: 'N' },
    { word: 'swagger', word_class: 'V' },
    { word: 'swagger', word_class: 'AJ' },
    { word: 'swaggerer', word_class: 'N' },
    { word: 'swaggering', word_class: 'AJ' }
  ],
  blotched: [
    { word: 'blotch', word_class: 'N' },
    { word: 'blotch', word_class: 'V' },
    { word: 'blotched', word_class: 'AJ' }
  ],
  ulceration: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  rouge: [{ word: 'rouge', word_class: 'N' }, { word: 'rouge', word_class: 'V' }, { word: 'rouged', word_class: 'AJ' }],
  elegization: [
    { word: 'elegize', word_class: 'V' },
    { word: 'elegance', word_class: 'N' },
    { word: 'elegant', word_class: 'AJ' },
    { word: 'elegization', word_class: 'N' }
  ],
  sightsee: [
    { word: 'sightsee', word_class: 'V' },
    { word: 'sightseer', word_class: 'N' },
    { word: 'sightseeing', word_class: 'N' }
  ],
  quixotic: [
    { word: 'quixotic', word_class: 'AJ' },
    { word: 'quixotism', word_class: 'N' },
    { word: 'quixotically', word_class: 'AV' }
  ],
  practicableness: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  whiner: [
    { word: 'whine', word_class: 'N' },
    { word: 'whine', word_class: 'V' },
    { word: 'whiner', word_class: 'N' },
    { word: 'whiny', word_class: 'AJ' },
    { word: 'whining', word_class: 'AJ' }
  ],
  pervasive: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  transferability: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  pithiness: [
    { word: 'pithy', word_class: 'AJ' },
    { word: 'pithy', word_class: 'AV' },
    { word: 'pithiness', word_class: 'N' }
  ],
  precipitate: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  accrument: [
    { word: 'accrue', word_class: 'V' },
    { word: 'accrual', word_class: 'N' },
    { word: 'accrued', word_class: 'AJ' },
    { word: 'accrument', word_class: 'N' },
    { word: 'accruement', word_class: 'N' }
  ],
  miss: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  tiresome: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  attendant: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  promote: [
    { word: 'promote', word_class: 'N' },
    { word: 'promote', word_class: 'V' },
    { word: 'promoter', word_class: 'N' },
    { word: 'promotion', word_class: 'N' },
    { word: 'promotive', word_class: 'AJ' },
    { word: 'promotional', word_class: 'AJ' }
  ],
  smuggled: [
    { word: 'smuggle', word_class: 'V' },
    { word: 'smuggler', word_class: 'N' },
    { word: 'smuggled', word_class: 'AJ' },
    { word: 'smuggling', word_class: 'N' }
  ],
  assassination: [
    { word: 'assassin', word_class: 'N' },
    { word: 'assassinate', word_class: 'V' },
    { word: 'assassinated', word_class: 'AJ' },
    { word: 'assassination', word_class: 'N' }
  ],
  expropriation: [
    { word: 'expropriate', word_class: 'V' },
    { word: 'expropriated', word_class: 'AJ' },
    { word: 'expropriation', word_class: 'N' }
  ],
  compute: [
    { word: 'compute', word_class: 'V' },
    { word: 'computer', word_class: 'N' },
    { word: 'computable', word_class: 'AJ' },
    { word: 'computation', word_class: 'N' },
    { word: 'computational', word_class: 'AJ' }
  ],
  imbrue: [
    { word: 'imbrue', word_class: 'V' },
    { word: 'imbruement', word_class: 'N' },
    { word: 'imbruembrue', word_class: 'V' },
    { word: 'imbruembruement', word_class: 'N' }
  ],
  parenthetic: [
    { word: 'parenthetic', word_class: 'AJ' },
    { word: 'parenthetical', word_class: 'AJ' },
    { word: 'parenthetically', word_class: 'AV' }
  ],
  classifier: [
    { word: 'classify', word_class: 'V' },
    { word: 'classifier', word_class: 'N' },
    { word: 'classified', word_class: 'AJ' },
    { word: 'classifiable', word_class: 'AJ' },
    { word: 'classification', word_class: 'N' }
  ],
  autograph: [
    { word: 'autograph', word_class: 'N' },
    { word: 'autograph', word_class: 'V' },
    { word: 'autographed', word_class: 'AJ' },
    { word: 'autographic', word_class: 'AJ' }
  ],
  hired: [
    { word: 'hire', word_class: 'N' },
    { word: 'hire', word_class: 'V' },
    { word: 'hirer', word_class: 'N' },
    { word: 'hired', word_class: 'AJ' },
    { word: 'hiring', word_class: 'N' }
  ],
  breeched: [
    { word: 'breech', word_class: 'N' },
    { word: 'breeches', word_class: 'N' },
    { word: 'breeched', word_class: 'AJ' }
  ],
  impedence: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  calculable: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  wonderful: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  retentively: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  melioration: [
    { word: 'meliorate', word_class: 'V' },
    { word: 'meliorism', word_class: 'N' },
    { word: 'melioration', word_class: 'N' },
    { word: 'meliorative', word_class: 'AJ' }
  ],
  quavering: [
    { word: 'quaver', word_class: 'N' },
    { word: 'quaver', word_class: 'V' },
    { word: 'quavery', word_class: 'AJ' },
    { word: 'quavering', word_class: 'AJ' }
  ],
  quadrate: [
    { word: 'quadrate', word_class: 'N' },
    { word: 'quadrate', word_class: 'AJ' },
    { word: 'quadratic', word_class: 'N' },
    { word: 'quadratic', word_class: 'AJ' },
    { word: 'quadratics', word_class: 'N' }
  ],
  forecast: [
    { word: 'forecast', word_class: 'N' },
    { word: 'forecast', word_class: 'V' },
    { word: 'forecaster', word_class: 'N' },
    { word: 'forecasting', word_class: 'N' }
  ],
  'double-date': [
    { word: 'double-date', word_class: 'V' },
    { word: 'double-dealing', word_class: 'N' },
    { word: 'double-dealing', word_class: 'AJ' }
  ],
  methodist: [
    { word: 'methodist', word_class: 'N' },
    { word: 'methodist', word_class: 'AJ' },
    { word: 'methodists', word_class: 'N' }
  ],
  facing: [
    { word: 'face', word_class: 'N' },
    { word: 'face', word_class: 'V' },
    { word: 'facer', word_class: 'N' },
    { word: 'faced', word_class: 'AJ' },
    { word: 'facing', word_class: 'N' },
    { word: 'faction', word_class: 'N' }
  ],
  bituminisation: [
    { word: 'bituminise', word_class: 'V' },
    { word: 'bituminize', word_class: 'V' },
    { word: 'bituminous', word_class: 'AJ' },
    { word: 'bituminisation', word_class: 'N' },
    { word: 'bituminization', word_class: 'N' }
  ],
  trodden: [
    { word: 'tread', word_class: 'N' },
    { word: 'tread', word_class: 'V' },
    { word: 'trodden', word_class: 'AJ' }
  ],
  submissive: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  crumbly: [
    { word: 'crumble', word_class: 'V' },
    { word: 'crumbly', word_class: 'AJ' },
    { word: 'crumbled', word_class: 'AJ' }
  ],
  analogy: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  aversion: [
    { word: 'avert', word_class: 'V' },
    { word: 'averse', word_class: 'AJ' },
    { word: 'aversion', word_class: 'N' },
    { word: 'averting', word_class: 'N' },
    { word: 'aversive', word_class: 'AJ' }
  ],
  sackful: [
    { word: 'sack', word_class: 'N' },
    { word: 'sack', word_class: 'V' },
    { word: 'sacked', word_class: 'AJ' },
    { word: 'sackful', word_class: 'N' },
    { word: 'sacking', word_class: 'N' },
    { word: 'sackful', word_class: 'AJ' }
  ],
  roller: [
    { word: 'roll', word_class: 'N' },
    { word: 'roll', word_class: 'V' },
    { word: 'roller', word_class: 'N' },
    { word: 'rolled', word_class: 'AJ' },
    { word: 'rolling', word_class: 'N' },
    { word: 'rolling', word_class: 'AJ' }
  ],
  contraception: [
    { word: 'contraception', word_class: 'N' },
    { word: 'contraceptive', word_class: 'N' },
    { word: 'contraceptive', word_class: 'AJ' }
  ],
  settling: [
    { word: 'settle', word_class: 'N' },
    { word: 'settle', word_class: 'V' },
    { word: 'settler', word_class: 'N' },
    { word: 'settled', word_class: 'AJ' },
    { word: 'settling', word_class: 'N' },
    { word: 'settlement', word_class: 'N' }
  ],
  huddle: [
    { word: 'huddle', word_class: 'N' },
    { word: 'huddle', word_class: 'V' },
    { word: 'huddled', word_class: 'AJ' }
  ],
  predictive: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  slighting: [
    { word: 'slight', word_class: 'N' },
    { word: 'slight', word_class: 'V' },
    { word: 'slight', word_class: 'AJ' },
    { word: 'slighting', word_class: 'AJ' },
    { word: 'slightness', word_class: 'N' }
  ],
  inculpability: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  descending: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  glycerinate: [
    { word: 'glycerin', word_class: 'N' },
    { word: 'glycerine', word_class: 'N' },
    { word: 'glycerinate', word_class: 'V' }
  ],
  diagonally: [
    { word: 'diagonal', word_class: 'N' },
    { word: 'diagonal', word_class: 'AJ' },
    { word: 'diagonalize', word_class: 'V' },
    { word: 'diagonally', word_class: 'AV' },
    { word: 'diagonalization', word_class: 'N' }
  ],
  microscope: [
    { word: 'microscope', word_class: 'N' },
    { word: 'microscopic', word_class: 'AJ' },
    { word: 'microscopical', word_class: 'AJ' },
    { word: 'microscopically', word_class: 'AV' }
  ],
  racism: [
    { word: 'race', word_class: 'N' },
    { word: 'race', word_class: 'V' },
    { word: 'racer', word_class: 'N' },
    { word: 'racism', word_class: 'N' },
    { word: 'racing', word_class: 'N' },
    { word: 'racist', word_class: 'N' },
    { word: 'racist', word_class: 'AJ' }
  ],
  locking: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  unnaturalness: [
    { word: 'unnatural', word_class: 'AJ' },
    { word: 'unnaturally', word_class: 'AV' },
    { word: 'unnaturalness', word_class: 'N' },
    { word: 'unnaturalized', word_class: 'AJ' }
  ],
  sniveling: [
    { word: 'snivel', word_class: 'N' },
    { word: 'snivel', word_class: 'V' },
    { word: 'sniveler', word_class: 'N' },
    { word: 'sniveling', word_class: 'N' }
  ],
  prepossessing: [
    { word: 'prepossess', word_class: 'V' },
    { word: 'prepossession', word_class: 'N' },
    { word: 'prepossessing', word_class: 'AJ' }
  ],
  industrious: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  gabbling: [
    { word: 'gabble', word_class: 'N' },
    { word: 'gabble', word_class: 'V' },
    { word: 'gabbling', word_class: 'AJ' }
  ],
  bestialize: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  appointive: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  excessively: [
    { word: 'exceed', word_class: 'V' },
    { word: 'excess', word_class: 'N' },
    { word: 'excess', word_class: 'AJ' },
    { word: 'excessive', word_class: 'AJ' },
    { word: 'excessively', word_class: 'AV' },
    { word: 'excessiveness', word_class: 'N' }
  ],
  ferryman: [
    { word: 'ferry', word_class: 'N' },
    { word: 'ferry', word_class: 'V' },
    { word: 'ferris', word_class: 'N' },
    { word: 'ferrying', word_class: 'N' },
    { word: 'ferryman', word_class: 'N' }
  ],
  tepidness: [
    { word: 'tepid', word_class: 'AJ' },
    { word: 'tepidity', word_class: 'N' },
    { word: 'tepidness', word_class: 'N' }
  ],
  wood: [
    { word: 'wood', word_class: 'N' },
    { word: 'wood', word_class: 'AJ' },
    { word: 'woods', word_class: 'N' },
    { word: 'woods', word_class: 'AJ' },
    { word: 'wooded', word_class: 'AJ' },
    { word: 'wooden', word_class: 'AJ' },
    { word: 'woodsy', word_class: 'AJ' }
  ],
  traitorousness: [
    { word: 'traitor', word_class: 'N' },
    { word: 'traitorous', word_class: 'AJ' },
    { word: 'traitorously', word_class: 'AV' },
    { word: 'traitorousness', word_class: 'N' }
  ],
  glow: [
    { word: 'glow', word_class: 'N' },
    { word: 'glow', word_class: 'V' },
    { word: 'glowing', word_class: 'N' },
    { word: 'glowing', word_class: 'AJ' }
  ],
  beaten: [
    { word: 'beat', word_class: 'N' },
    { word: 'beat', word_class: 'V' },
    { word: 'beat', word_class: 'AJ' },
    { word: 'beater', word_class: 'N' },
    { word: 'beaten', word_class: 'AJ' },
    { word: 'beating', word_class: 'N' },
    { word: 'beating', word_class: 'AJ' }
  ],
  ascend: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  syntactical: [
    { word: 'syntax', word_class: 'N' },
    { word: 'syntactic', word_class: 'AJ' },
    { word: 'syntactical', word_class: 'AJ' },
    { word: 'syntactically', word_class: 'AV' }
  ],
  pickled: [
    { word: 'pickle', word_class: 'N' },
    { word: 'pickle', word_class: 'V' },
    { word: 'pickled', word_class: 'AJ' }
  ],
  denudate: [
    { word: 'denude', word_class: 'V' },
    { word: 'denudate', word_class: 'V' },
    { word: 'denuded', word_class: 'AJ' },
    { word: 'denudate', word_class: 'AJ' },
    { word: 'denudation', word_class: 'N' }
  ],
  ravisher: [
    { word: 'ravish', word_class: 'V' },
    { word: 'ravisher', word_class: 'N' },
    { word: 'ravishing', word_class: 'AJ' },
    { word: 'ravishment', word_class: 'N' }
  ],
  battleship: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  decisive: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  course: [
    { word: 'course', word_class: 'N' },
    { word: 'course', word_class: 'V' },
    { word: 'course', word_class: 'AV' },
    { word: 'courser', word_class: 'N' },
    { word: 'coursing', word_class: 'N' }
  ],
  adorableness: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  briny: [
    { word: 'brine', word_class: 'N' },
    { word: 'brine', word_class: 'V' },
    { word: 'briny', word_class: 'N' },
    { word: 'briny', word_class: 'AJ' },
    { word: 'brininess', word_class: 'N' }
  ],
  jellification: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  truant: [
    { word: 'truant', word_class: 'N' },
    { word: 'truant', word_class: 'V' },
    { word: 'truancy', word_class: 'N' },
    { word: 'truant', word_class: 'AJ' }
  ],
  bur: [
    { word: 'bur', word_class: 'N' },
    { word: 'bur', word_class: 'V' },
    { word: 'burr', word_class: 'N' },
    { word: 'burr', word_class: 'V' },
    { word: 'burred', word_class: 'AJ' },
    { word: 'burring', word_class: 'N' }
  ],
  neighboring: [
    { word: 'neighbor', word_class: 'N' },
    { word: 'neighbor', word_class: 'V' },
    { word: 'neighbor', word_class: 'AJ' },
    { word: 'neighboring', word_class: 'AJ' }
  ],
  history: [
    { word: 'history', word_class: 'N' },
    { word: 'historic', word_class: 'AJ' },
    { word: 'historied', word_class: 'AJ' },
    { word: 'historical', word_class: 'AJ' },
    { word: 'historically', word_class: 'AV' }
  ],
  drama: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  divesture: [
    { word: 'devest', word_class: 'V' },
    { word: 'divest', word_class: 'N' },
    { word: 'divest', word_class: 'V' },
    { word: 'divesture', word_class: 'N' },
    { word: 'divestment', word_class: 'N' },
    { word: 'divestiture', word_class: 'N' }
  ],
  interdiction: [
    { word: 'interdict', word_class: 'N' },
    { word: 'interdict', word_class: 'V' },
    { word: 'interdiction', word_class: 'N' }
  ],
  degenerate: [
    { word: 'degenerate', word_class: 'N' },
    { word: 'degenerate', word_class: 'V' },
    { word: 'degenerate', word_class: 'AJ' },
    { word: 'degeneration', word_class: 'N' }
  ],
  purifying: [
    { word: 'purify', word_class: 'V' },
    { word: 'purifier', word_class: 'N' },
    { word: 'purified', word_class: 'AJ' },
    { word: 'purifying', word_class: 'AJ' },
    { word: 'purification', word_class: 'N' }
  ],
  fashion: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  freeman: [
    { word: 'free', word_class: 'N' },
    { word: 'free', word_class: 'V' },
    { word: 'free', word_class: 'AJ' },
    { word: 'free', word_class: 'AV' },
    { word: 'freedom', word_class: 'N' },
    { word: 'freeing', word_class: 'N' },
    { word: 'freeman', word_class: 'N' },
    { word: 'freeing', word_class: 'AJ' }
  ],
  grade: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  adam: [
    { word: 'adam', word_class: 'N' },
    { word: 'adams', word_class: 'N' },
    { word: 'adamant', word_class: 'N' },
    { word: 'adamance', word_class: 'N' },
    { word: 'adamant', word_class: 'AJ' }
  ],
  lacerated: [
    { word: 'lacerate', word_class: 'V' },
    { word: 'lacerate', word_class: 'AJ' },
    { word: 'lacerated', word_class: 'AJ' },
    { word: 'laceration', word_class: 'N' }
  ],
  impaired: [
    { word: 'impair', word_class: 'N' },
    { word: 'impair', word_class: 'V' },
    { word: 'impaired', word_class: 'AJ' },
    { word: 'impairment', word_class: 'N' }
  ],
  landowner: [
    { word: 'landowner', word_class: 'N' },
    { word: 'landowning', word_class: 'N' },
    { word: 'landowning', word_class: 'AJ' },
    { word: 'landownership', word_class: 'N' }
  ],
  symmetrize: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  commemorating: [
    { word: 'commemorate', word_class: 'V' },
    { word: 'commemoration', word_class: 'N' },
    { word: 'commemorating', word_class: 'AJ' },
    { word: 'commemorative', word_class: 'AJ' }
  ],
  extravertive: [
    { word: 'extravert', word_class: 'N' },
    { word: 'extravert', word_class: 'AJ' },
    { word: 'extraverted', word_class: 'AJ' },
    { word: 'extravertive', word_class: 'AJ' }
  ],
  turbulently: [
    { word: 'turbulence', word_class: 'N' },
    { word: 'turbulency', word_class: 'N' },
    { word: 'turbulent', word_class: 'AJ' },
    { word: 'turbulently', word_class: 'AV' }
  ],
  aery: [{ word: 'aery', word_class: 'N' }, { word: 'aerie', word_class: 'N' }, { word: 'aery', word_class: 'AJ' }],
  muddle: [
    { word: 'muddle', word_class: 'N' },
    { word: 'muddle', word_class: 'V' },
    { word: 'muddled', word_class: 'AJ' }
  ],
  inadequate: [
    { word: 'inadequate', word_class: 'AJ' },
    { word: 'inadequately', word_class: 'AV' },
    { word: 'inadequateness', word_class: 'N' }
  ],
  anodize: [
    { word: 'anode', word_class: 'N' },
    { word: 'anodal', word_class: 'AJ' },
    { word: 'anodic', word_class: 'AJ' },
    { word: 'anodize', word_class: 'V' },
    { word: 'anodization', word_class: 'N' }
  ],
  bacteriology: [
    { word: 'bacteriology', word_class: 'N' },
    { word: 'bacteriologic', word_class: 'AJ' },
    { word: 'bacteriological', word_class: 'AJ' }
  ],
  disillusion: [
    { word: 'disillusion', word_class: 'N' },
    { word: 'disillusion', word_class: 'V' },
    { word: 'disillusioned', word_class: 'AJ' },
    { word: 'disillusioning', word_class: 'AJ' },
    { word: 'disillusionment', word_class: 'N' }
  ],
  overly: [
    { word: 'overlie', word_class: 'V' },
    { word: 'overly', word_class: 'AV' },
    { word: 'overlying', word_class: 'AJ' }
  ],
  monetary: [
    { word: 'monetary', word_class: 'N' },
    { word: 'monetary', word_class: 'AJ' },
    { word: 'monetarism', word_class: 'N' },
    { word: 'monetarize', word_class: 'V' },
    { word: 'monetarily', word_class: 'AJ' }
  ],
  smuggle: [
    { word: 'smuggle', word_class: 'V' },
    { word: 'smuggler', word_class: 'N' },
    { word: 'smuggled', word_class: 'AJ' },
    { word: 'smuggling', word_class: 'N' }
  ],
  scar: [{ word: 'scar', word_class: 'N' }, { word: 'scar', word_class: 'V' }, { word: 'scarred', word_class: 'AJ' }],
  fortunately: [
    { word: 'fortune', word_class: 'N' },
    { word: 'fortunate', word_class: 'N' },
    { word: 'fortunate', word_class: 'AJ' },
    { word: 'fortunately', word_class: 'AV' }
  ],
  mopes: [
    { word: 'mope', word_class: 'N' },
    { word: 'mope', word_class: 'V' },
    { word: 'moped', word_class: 'N' },
    { word: 'moped', word_class: 'V' },
    { word: 'mopes', word_class: 'N' }
  ],
  celebrater: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  plunder: [
    { word: 'plunder', word_class: 'N' },
    { word: 'plunder', word_class: 'V' },
    { word: 'plunderer', word_class: 'N' },
    { word: 'plunderage', word_class: 'N' },
    { word: 'plundered', word_class: 'AJ' },
    { word: 'plundering', word_class: 'N' },
    { word: 'plundering', word_class: 'AJ' }
  ],
  striven: [
    { word: 'strife', word_class: 'N' },
    { word: 'strive', word_class: 'V' },
    { word: 'striver', word_class: 'N' },
    { word: 'striven', word_class: 'AJ' },
    { word: 'striving', word_class: 'N' }
  ],
  female: [
    { word: 'female', word_class: 'N' },
    { word: 'female', word_class: 'AJ' },
    { word: 'femaleness', word_class: 'N' }
  ],
  vex: [
    { word: 'vex', word_class: 'V' },
    { word: 'vexed', word_class: 'AJ' },
    { word: 'vexing', word_class: 'AJ' },
    { word: 'vexation', word_class: 'N' },
    { word: 'vexatious', word_class: 'AJ' }
  ],
  winsome: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  resettle: [
    { word: 'resettle', word_class: 'V' },
    { word: 'resettled', word_class: 'AJ' },
    { word: 'resettlement', word_class: 'N' }
  ],
  anguished: [
    { word: 'anguish', word_class: 'N' },
    { word: 'anguish', word_class: 'V' },
    { word: 'anguished', word_class: 'AJ' }
  ],
  exclaiming: [
    { word: 'exclaim', word_class: 'N' },
    { word: 'exclaim', word_class: 'V' },
    { word: 'exclaiming', word_class: 'N' },
    { word: 'exclamation', word_class: 'N' },
    { word: 'exclamatory', word_class: 'AJ' }
  ],
  stylite: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  copied: [
    { word: 'copy', word_class: 'N' },
    { word: 'copy', word_class: 'V' },
    { word: 'copier', word_class: 'N' },
    { word: 'copied', word_class: 'AJ' },
    { word: 'copying', word_class: 'N' },
    { word: 'copyist', word_class: 'N' }
  ],
  inherently: [
    { word: 'inhere', word_class: 'V' },
    { word: 'inhere', word_class: 'AV' },
    { word: 'inherence', word_class: 'N' },
    { word: 'inherent', word_class: 'AJ' },
    { word: 'inherently', word_class: 'AV' }
  ],
  household: [
    { word: 'household', word_class: 'N' },
    { word: 'household', word_class: 'AJ' },
    { word: 'householder', word_class: 'N' }
  ],
  garrulity: [
    { word: 'garrulity', word_class: 'N' },
    { word: 'garrulous', word_class: 'AJ' },
    { word: 'garrulously', word_class: 'AV' },
    { word: 'garrulousness', word_class: 'N' }
  ],
  stinging: [
    { word: 'sting', word_class: 'N' },
    { word: 'sting', word_class: 'V' },
    { word: 'stinger', word_class: 'N' },
    { word: 'stinging', word_class: 'N' },
    { word: 'stinging', word_class: 'AJ' }
  ],
  raise: [
    { word: 'raise', word_class: 'N' },
    { word: 'raise', word_class: 'V' },
    { word: 'raiser', word_class: 'N' },
    { word: 'raised', word_class: 'AJ' },
    { word: 'raising', word_class: 'N' },
    { word: 'raising', word_class: 'AJ' }
  ],
  increase: [
    { word: 'increase', word_class: 'N' },
    { word: 'increase', word_class: 'V' },
    { word: 'increased', word_class: 'AJ' },
    { word: 'increasing', word_class: 'AJ' }
  ],
  inform: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  fogged: [{ word: 'fog', word_class: 'N' }, { word: 'fog', word_class: 'V' }, { word: 'fogged', word_class: 'AJ' }],
  islamise: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  resoluteness: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  derogation: [
    { word: 'derogate', word_class: 'V' },
    { word: 'derogation', word_class: 'N' },
    { word: 'derogative', word_class: 'AJ' }
  ],
  amassed: [
    { word: 'amass', word_class: 'V' },
    { word: 'amassed', word_class: 'AJ' },
    { word: 'amassment', word_class: 'N' }
  ],
  grain: [
    { word: 'grain', word_class: 'N' },
    { word: 'grain', word_class: 'V' },
    { word: 'grained', word_class: 'AJ' },
    { word: 'graining', word_class: 'N' },
    { word: 'granular', word_class: 'AJ' },
    { word: 'granularity', word_class: 'N' }
  ],
  gatherer: [
    { word: 'gather', word_class: 'N' },
    { word: 'gather', word_class: 'V' },
    { word: 'gatherer', word_class: 'N' },
    { word: 'gathering', word_class: 'N' },
    { word: 'gathered', word_class: 'AJ' },
    { word: 'gathering', word_class: 'AJ' }
  ],
  hamming: [{ word: 'ham', word_class: 'N' }, { word: 'ham', word_class: 'V' }, { word: 'hamming', word_class: 'N' }],
  backslide: [
    { word: 'backslide', word_class: 'V' },
    { word: 'backslider', word_class: 'N' },
    { word: 'backsliding', word_class: 'N' }
  ],
  worshiper: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  southwards: [
    { word: 'southward', word_class: 'AJ' },
    { word: 'southward', word_class: 'AV' },
    { word: 'southwards', word_class: 'AV' }
  ],
  propitiate: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  erosive: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  cricketer: [
    { word: 'cricket', word_class: 'N' },
    { word: 'cricket', word_class: 'V' },
    { word: 'cricketer', word_class: 'N' }
  ],
  microscopic: [
    { word: 'microscope', word_class: 'N' },
    { word: 'microscopic', word_class: 'AJ' },
    { word: 'microscopical', word_class: 'AJ' },
    { word: 'microscopically', word_class: 'AV' }
  ],
  dishevel: [
    { word: 'dishevel', word_class: 'V' },
    { word: 'disheveled', word_class: 'AJ' },
    { word: 'dishevelled', word_class: 'AJ' },
    { word: 'dishevelment', word_class: 'N' }
  ],
  motorization: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  supercilious: [
    { word: 'supercilious', word_class: 'AJ' },
    { word: 'superciliously', word_class: 'AV' },
    { word: 'superciliousness', word_class: 'N' }
  ],
  journalistic: [
    { word: 'journalist', word_class: 'N' },
    { word: 'journalistic', word_class: 'AJ' },
    { word: 'journalistically', word_class: 'AV' }
  ],
  disparity: [
    { word: 'disparity', word_class: 'N' },
    { word: 'disparate', word_class: 'AJ' },
    { word: 'disparateness', word_class: 'N' }
  ],
  nestor: [
    { word: 'nest', word_class: 'N' },
    { word: 'nest', word_class: 'V' },
    { word: 'nester', word_class: 'N' },
    { word: 'nestor', word_class: 'N' }
  ],
  racing: [
    { word: 'race', word_class: 'N' },
    { word: 'race', word_class: 'V' },
    { word: 'racer', word_class: 'N' },
    { word: 'racism', word_class: 'N' },
    { word: 'racing', word_class: 'N' },
    { word: 'racist', word_class: 'N' },
    { word: 'racist', word_class: 'AJ' }
  ],
  offensively: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  'self-seeker': [
    { word: 'self-seeker', word_class: 'N' },
    { word: 'self-seeking', word_class: 'N' },
    { word: 'self-seeking', word_class: 'AJ' }
  ],
  bountiful: [
    { word: 'bounty', word_class: 'N' },
    { word: 'bountied', word_class: 'AJ' },
    { word: 'bounteous', word_class: 'AJ' },
    { word: 'bountiful', word_class: 'AJ' },
    { word: 'bountifulness', word_class: 'N' }
  ],
  steps: [{ word: 'step', word_class: 'N' }, { word: 'step', word_class: 'V' }, { word: 'steps', word_class: 'N' }],
  slimed: [
    { word: 'slime', word_class: 'N' },
    { word: 'slime', word_class: 'V' },
    { word: 'slimy', word_class: 'AJ' },
    { word: 'slimed', word_class: 'AJ' },
    { word: 'sliminess', word_class: 'N' }
  ],
  jelly: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  robbery: [
    { word: 'rob', word_class: 'V' },
    { word: 'robbery', word_class: 'N' },
    { word: 'robbery', word_class: 'AJ' }
  ],
  tuneful: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  plutocratical: [
    { word: 'plutocrat', word_class: 'N' },
    { word: 'plutocratic', word_class: 'AJ' },
    { word: 'plutocratical', word_class: 'AJ' }
  ],
  imperial: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  painting: [
    { word: 'paint', word_class: 'N' },
    { word: 'paint', word_class: 'V' },
    { word: 'painter', word_class: 'N' },
    { word: 'painted', word_class: 'AJ' },
    { word: 'painting', word_class: 'N' }
  ],
  ambit: [
    { word: 'ambit', word_class: 'N' },
    { word: 'ambition', word_class: 'N' },
    { word: 'ambition', word_class: 'V' }
  ],
  entail: [
    { word: 'entail', word_class: 'N' },
    { word: 'entail', word_class: 'V' },
    { word: 'entailment', word_class: 'N' }
  ],
  portend: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  succulence: [
    { word: 'succulent', word_class: 'N' },
    { word: 'succulence', word_class: 'N' },
    { word: 'succulent', word_class: 'AJ' }
  ],
  stifling: [
    { word: 'stifle', word_class: 'N' },
    { word: 'stifle', word_class: 'V' },
    { word: 'stifled', word_class: 'AJ' },
    { word: 'stifling', word_class: 'N' },
    { word: 'stifling', word_class: 'AJ' }
  ],
  counterfeit: [
    { word: 'counterfeit', word_class: 'N' },
    { word: 'counterfeit', word_class: 'V' },
    { word: 'counterfeit', word_class: 'AJ' },
    { word: 'counterfeiter', word_class: 'N' }
  ],
  gone: [
    { word: 'go', word_class: 'N' },
    { word: 'go', word_class: 'V' },
    { word: 'go', word_class: 'AJ' },
    { word: 'goal', word_class: 'N' },
    { word: 'goer', word_class: 'N' },
    { word: 'going', word_class: 'N' },
    { word: 'gone', word_class: 'AJ' },
    { word: 'going', word_class: 'AJ' }
  ],
  digit: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  unite: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  sensibleness: [
    { word: 'sensible', word_class: 'AJ' },
    { word: 'sensibly', word_class: 'AV' },
    { word: 'sensibility', word_class: 'N' },
    { word: 'sensibleness', word_class: 'N' }
  ],
  bungling: [
    { word: 'bungle', word_class: 'N' },
    { word: 'bungle', word_class: 'V' },
    { word: 'bungler', word_class: 'N' },
    { word: 'bungled', word_class: 'AJ' },
    { word: 'bungling', word_class: 'N' },
    { word: 'bungling', word_class: 'AJ' }
  ],
  frailness: [
    { word: 'frail', word_class: 'N' },
    { word: 'frail', word_class: 'AJ' },
    { word: 'frailty', word_class: 'N' },
    { word: 'frailness', word_class: 'N' }
  ],
  lashing: [
    { word: 'lash', word_class: 'N' },
    { word: 'lash', word_class: 'V' },
    { word: 'lasher', word_class: 'N' },
    { word: 'lashing', word_class: 'N' },
    { word: 'lashing', word_class: 'AJ' },
    { word: 'lashings', word_class: 'N' }
  ],
  cosmetically: [
    { word: 'cosmetic', word_class: 'N' },
    { word: 'cosmetic', word_class: 'AJ' },
    { word: 'cosmetics', word_class: 'N' },
    { word: 'cosmetically', word_class: 'AV' }
  ],
  prevent: [
    { word: 'prevent', word_class: 'N' },
    { word: 'prevent', word_class: 'V' },
    { word: 'prevention', word_class: 'N' },
    { word: 'preventive', word_class: 'N' },
    { word: 'preventive', word_class: 'AJ' },
    { word: 'preventable', word_class: 'AJ' },
    { word: 'preventative', word_class: 'N' },
    { word: 'preventative', word_class: 'AJ' }
  ],
  emaciation: [
    { word: 'emaciate', word_class: 'V' },
    { word: 'emaciated', word_class: 'AJ' },
    { word: 'emaciation', word_class: 'N' }
  ],
  bulwark: [
    { word: 'bulwark', word_class: 'N' },
    { word: 'bulwark', word_class: 'V' },
    { word: 'bulwarks', word_class: 'N' }
  ],
  rectangular: [
    { word: 'rectangle', word_class: 'N' },
    { word: 'rectangular', word_class: 'AJ' },
    { word: 'rectangularity', word_class: 'N' }
  ],
  abnegator: [
    { word: 'abnegate', word_class: 'V' },
    { word: 'abnegator', word_class: 'N' },
    { word: 'abnegation', word_class: 'N' }
  ],
  piss: [
    { word: 'piss', word_class: 'N' },
    { word: 'piss', word_class: 'V' },
    { word: 'pissed', word_class: 'AJ' },
    { word: 'pissing', word_class: 'N' }
  ],
  valuation: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  cushioning: [
    { word: 'cushion', word_class: 'N' },
    { word: 'cushion', word_class: 'V' },
    { word: 'cushioned', word_class: 'AJ' },
    { word: 'cushioning', word_class: 'N' }
  ],
  toxic: [
    { word: 'toxic', word_class: 'AJ' },
    { word: 'toxicity', word_class: 'N' },
    { word: 'toxicant', word_class: 'AJ' }
  ],
  astuteness: [
    { word: 'astute', word_class: 'AJ' },
    { word: 'astutely', word_class: 'AV' },
    { word: 'astuteness', word_class: 'N' }
  ],
  trench: [
    { word: 'trench', word_class: 'N' },
    { word: 'trench', word_class: 'V' },
    { word: 'trencher', word_class: 'N' }
  ],
  roasting: [
    { word: 'roast', word_class: 'N' },
    { word: 'roast', word_class: 'V' },
    { word: 'roast', word_class: 'AJ' },
    { word: 'roaster', word_class: 'N' },
    { word: 'roasted', word_class: 'AJ' },
    { word: 'roasting', word_class: 'N' }
  ],
  waxed: [
    { word: 'wax', word_class: 'N' },
    { word: 'wax', word_class: 'V' },
    { word: 'waxed', word_class: 'AJ' },
    { word: 'waxen', word_class: 'AJ' },
    { word: 'waxing', word_class: 'N' },
    { word: 'waxing', word_class: 'AJ' }
  ],
  penuriously: [
    { word: 'penury', word_class: 'N' },
    { word: 'penurious', word_class: 'AJ' },
    { word: 'penuriously', word_class: 'AV' },
    { word: 'penuriousness', word_class: 'N' }
  ],
  delimit: [
    { word: 'delimit', word_class: 'N' },
    { word: 'delimit', word_class: 'V' },
    { word: 'delimitate', word_class: 'V' },
    { word: 'delimited', word_class: 'AJ' },
    { word: 'delimitation', word_class: 'N' }
  ],
  prosaically: [
    { word: 'prosaic', word_class: 'AJ' },
    { word: 'prosaicness', word_class: 'N' },
    { word: 'prosaically', word_class: 'AV' }
  ],
  aunt: [{ word: 'aunt', word_class: 'N' }, { word: 'aunty', word_class: 'N' }, { word: 'auntie', word_class: 'N' }],
  mediatize: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  intruding: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  idolatrous: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  floral: [
    { word: 'flower', word_class: 'N' },
    { word: 'flower', word_class: 'V' },
    { word: 'floral', word_class: 'AJ' },
    { word: 'florist', word_class: 'N' },
    { word: 'flowered', word_class: 'AJ' },
    { word: 'flowering', word_class: 'N' },
    { word: 'flowering', word_class: 'AJ' }
  ],
  pacific: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  teutonic: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  subsequence: [
    { word: 'subsequence', word_class: 'N' },
    { word: 'subsequent', word_class: 'AJ' },
    { word: 'subsequently', word_class: 'AV' },
    { word: 'subsequentness', word_class: 'N' }
  ],
  austerity: [
    { word: 'austere', word_class: 'AJ' },
    { word: 'austerity', word_class: 'N' },
    { word: 'austerely', word_class: 'AV' },
    { word: 'austereness', word_class: 'N' }
  ],
  auspice: [
    { word: 'auspice', word_class: 'N' },
    { word: 'auspices', word_class: 'N' },
    { word: 'auspicate', word_class: 'V' }
  ],
  padded: [
    { word: 'pad', word_class: 'N' },
    { word: 'pad', word_class: 'V' },
    { word: 'padded', word_class: 'AJ' },
    { word: 'padding', word_class: 'N' }
  ],
  anarchic: [
    { word: 'anarch', word_class: 'N' },
    { word: 'anarchic', word_class: 'AJ' },
    { word: 'anarchism', word_class: 'N' },
    { word: 'anarchical', word_class: 'AJ' },
    { word: 'anarchically', word_class: 'AV' }
  ],
  separative: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  pathological: [
    { word: 'pathology', word_class: 'N' },
    { word: 'pathologic', word_class: 'AJ' },
    { word: 'pathological', word_class: 'AJ' },
    { word: 'pathologically', word_class: 'AV' }
  ],
  disaffected: [
    { word: 'disaffect', word_class: 'V' },
    { word: 'disaffected', word_class: 'AJ' },
    { word: 'disaffection', word_class: 'N' }
  ],
  mottled: [
    { word: 'mottle', word_class: 'N' },
    { word: 'mottle', word_class: 'V' },
    { word: 'mottled', word_class: 'AJ' },
    { word: 'mottling', word_class: 'N' }
  ],
  sobering: [
    { word: 'sober', word_class: 'V' },
    { word: 'sober', word_class: 'AJ' },
    { word: 'sobriety', word_class: 'N' },
    { word: 'sobering', word_class: 'AJ' },
    { word: 'soberness', word_class: 'N' }
  ],
  array: [
    { word: 'array', word_class: 'N' },
    { word: 'array', word_class: 'V' },
    { word: 'arrayal', word_class: 'N' },
    { word: 'arrayed', word_class: 'AJ' }
  ],
  immanence: [
    { word: 'immanence', word_class: 'N' },
    { word: 'immanency', word_class: 'N' },
    { word: 'immanent', word_class: 'AJ' }
  ],
  weaving: [
    { word: 'weave', word_class: 'N' },
    { word: 'weave', word_class: 'V' },
    { word: 'woven', word_class: 'AJ' },
    { word: 'weaver', word_class: 'N' },
    { word: 'weaving', word_class: 'N' },
    { word: 'weaving', word_class: 'AJ' }
  ],
  fly: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  cinematograph: [
    { word: 'cinematograph', word_class: 'V' },
    { word: 'cinematography', word_class: 'N' },
    { word: 'cinematographer', word_class: 'N' }
  ],
  depart: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  drum: [{ word: 'drum', word_class: 'N' }, { word: 'drum', word_class: 'V' }, { word: 'drumming', word_class: 'N' }],
  subtraction: [
    { word: 'subtract', word_class: 'N' },
    { word: 'subtract', word_class: 'V' },
    { word: 'subtracter', word_class: 'N' },
    { word: 'subtracted', word_class: 'AJ' },
    { word: 'subtraction', word_class: 'N' },
    { word: 'subtractive', word_class: 'AJ' }
  ],
  extemporise: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  decorated: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  angry: [
    { word: 'anger', word_class: 'N' },
    { word: 'anger', word_class: 'V' },
    { word: 'angry', word_class: 'AJ' },
    { word: 'angered', word_class: 'AJ' },
    { word: 'angriness', word_class: 'N' }
  ],
  filming: [
    { word: 'film', word_class: 'N' },
    { word: 'film', word_class: 'V' },
    { word: 'filmed', word_class: 'AJ' },
    { word: 'filming', word_class: 'N' },
    { word: 'filmmaker', word_class: 'N' },
    { word: 'filmmaking', word_class: 'N' }
  ],
  muckraker: [
    { word: 'muckrake', word_class: 'V' },
    { word: 'muckraker', word_class: 'N' },
    { word: 'muckraking', word_class: 'N' }
  ],
  retractable: [
    { word: 'retract', word_class: 'N' },
    { word: 'retract', word_class: 'V' },
    { word: 'retractor', word_class: 'N' },
    { word: 'retracted', word_class: 'AJ' },
    { word: 'retraction', word_class: 'N' },
    { word: 'retractable', word_class: 'AJ' }
  ],
  tart: [{ word: 'tart', word_class: 'N' }, { word: 'tart', word_class: 'AJ' }, { word: 'tartness', word_class: 'N' }],
  bilobated: [
    { word: 'bilobed', word_class: 'AJ' },
    { word: 'bilobate', word_class: 'AJ' },
    { word: 'bilobated', word_class: 'AJ' }
  ],
  resination: [
    { word: 'resin', word_class: 'N' },
    { word: 'resin', word_class: 'V' },
    { word: 'resinate', word_class: 'V' },
    { word: 'resinous', word_class: 'AJ' },
    { word: 'resinated', word_class: 'AJ' },
    { word: 'resination', word_class: 'N' }
  ],
  debasing: [
    { word: 'debase', word_class: 'V' },
    { word: 'debased', word_class: 'AJ' },
    { word: 'debasing', word_class: 'AJ' },
    { word: 'debasement', word_class: 'N' }
  ],
  schooled: [
    { word: 'school', word_class: 'N' },
    { word: 'school', word_class: 'V' },
    { word: 'schooling', word_class: 'N' },
    { word: 'schooled', word_class: 'AJ' },
    { word: 'schoolman', word_class: 'N' }
  ],
  officer: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  annual: [
    { word: 'annual', word_class: 'N' },
    { word: 'annual', word_class: 'AJ' },
    { word: 'annually', word_class: 'AV' }
  ],
  sightseeing: [
    { word: 'sightsee', word_class: 'V' },
    { word: 'sightseer', word_class: 'N' },
    { word: 'sightseeing', word_class: 'N' }
  ],
  compile: [
    { word: 'compile', word_class: 'V' },
    { word: 'compiler', word_class: 'N' },
    { word: 'compiling', word_class: 'N' },
    { word: 'compilation', word_class: 'N' }
  ],
  diviner: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  incandescent: [
    { word: 'incandesce', word_class: 'V' },
    { word: 'incandescence', word_class: 'N' },
    { word: 'incandescent', word_class: 'AJ' }
  ],
  prosaic: [
    { word: 'prosaic', word_class: 'AJ' },
    { word: 'prosaicness', word_class: 'N' },
    { word: 'prosaically', word_class: 'AV' }
  ],
  incontinent: [
    { word: 'incontinence', word_class: 'N' },
    { word: 'incontinency', word_class: 'N' },
    { word: 'incontinent', word_class: 'AJ' }
  ],
  fighter: [
    { word: 'fight', word_class: 'N' },
    { word: 'fight', word_class: 'V' },
    { word: 'fighter', word_class: 'N' },
    { word: 'fighting', word_class: 'N' },
    { word: 'fighting', word_class: 'AJ' }
  ],
  satirization: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  acclimatize: [
    { word: 'acclimatise', word_class: 'V' },
    { word: 'acclimatize', word_class: 'V' },
    { word: 'acclimatisation', word_class: 'N' },
    { word: 'acclimatization', word_class: 'N' }
  ],
  mongrelization: [
    { word: 'mongrel', word_class: 'N' },
    { word: 'mongrel', word_class: 'AJ' },
    { word: 'mongrelise', word_class: 'V' },
    { word: 'mongrelize', word_class: 'V' },
    { word: 'mongrelisation', word_class: 'N' },
    { word: 'mongrelization', word_class: 'N' }
  ],
  twirl: [{ word: 'twirl', word_class: 'N' }, { word: 'twirl', word_class: 'V' }, { word: 'twirler', word_class: 'N' }],
  desynchronization: [
    { word: 'desynchronize', word_class: 'V' },
    { word: 'desynchronizing', word_class: 'N' },
    { word: 'desynchronization', word_class: 'N' }
  ],
  enteritis: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  erudition: [
    { word: 'erudite', word_class: 'AJ' },
    { word: 'erudition', word_class: 'N' },
    { word: 'eruditely', word_class: 'AV' },
    { word: 'eruditeness', word_class: 'N' }
  ],
  perverseness: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  unloaded: [
    { word: 'unload', word_class: 'V' },
    { word: 'unloaded', word_class: 'AJ' },
    { word: 'unloading', word_class: 'N' },
    { word: 'unloading', word_class: 'AJ' }
  ],
  suborn: [
    { word: 'suborn', word_class: 'V' },
    { word: 'suborner', word_class: 'N' },
    { word: 'subornation', word_class: 'N' }
  ],
  archness: [
    { word: 'arch', word_class: 'N' },
    { word: 'arch', word_class: 'V' },
    { word: 'arch', word_class: 'AJ' },
    { word: 'archer', word_class: 'N' },
    { word: 'arched', word_class: 'AJ' },
    { word: 'arching', word_class: 'AJ' },
    { word: 'archness', word_class: 'N' }
  ],
  comprehensively: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  digitate: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  ornament: [
    { word: 'ornament', word_class: 'N' },
    { word: 'ornament', word_class: 'V' },
    { word: 'ornamental', word_class: 'N' },
    { word: 'ornamental', word_class: 'AJ' },
    { word: 'ornamented', word_class: 'AJ' },
    { word: 'ornamentation', word_class: 'N' }
  ],
  coronal: [
    { word: 'coronal', word_class: 'N' },
    { word: 'coroner', word_class: 'N' },
    { word: 'coronate', word_class: 'V' },
    { word: 'coronation', word_class: 'N' }
  ],
  allowably: [
    { word: 'allow', word_class: 'V' },
    { word: 'allowance', word_class: 'N' },
    { word: 'allowance', word_class: 'V' },
    { word: 'allowable', word_class: 'AJ' },
    { word: 'allowably', word_class: 'AV' }
  ],
  typewrite: [
    { word: 'typewrite', word_class: 'V' },
    { word: 'typewriter', word_class: 'N' },
    { word: 'typewriting', word_class: 'N' },
    { word: 'typewritten', word_class: 'AJ' }
  ],
  resistant: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  hairdressing: [
    { word: 'hairdress', word_class: 'V' },
    { word: 'hairdresser', word_class: 'N' },
    { word: 'hairdressing', word_class: 'N' }
  ],
  overawe: [
    { word: 'overawe', word_class: 'N' },
    { word: 'overawe', word_class: 'V' },
    { word: 'overawed', word_class: 'AJ' }
  ],
  dun: [{ word: 'dun', word_class: 'N' }, { word: 'dun', word_class: 'V' }, { word: 'dun', word_class: 'AJ' }],
  sheared: [
    { word: 'shear', word_class: 'N' },
    { word: 'shear', word_class: 'V' },
    { word: 'shears', word_class: 'N' },
    { word: 'shorn', word_class: 'AJ' },
    { word: 'sheared', word_class: 'AJ' },
    { word: 'shearing', word_class: 'N' }
  ],
  methodological: [
    { word: 'methodology', word_class: 'N' },
    { word: 'methodological', word_class: 'AJ' },
    { word: 'methodologically', word_class: 'AV' }
  ],
  symphonious: [
    { word: 'symphony', word_class: 'N' },
    { word: 'symphonic', word_class: 'AJ' },
    { word: 'symphonize', word_class: 'V' },
    { word: 'symphonious', word_class: 'AJ' }
  ],
  predestined: [
    { word: 'predestine', word_class: 'V' },
    { word: 'predestinate', word_class: 'V' },
    { word: 'predestined', word_class: 'AJ' },
    { word: 'predestinate', word_class: 'AJ' },
    { word: 'predestination', word_class: 'N' }
  ],
  impossibly: [
    { word: 'impossible', word_class: 'N' },
    { word: 'impossible', word_class: 'AJ' },
    { word: 'impossibly', word_class: 'AV' },
    { word: 'impossibility', word_class: 'N' },
    { word: 'impossibleness', word_class: 'N' }
  ],
  drooping: [
    { word: 'droop', word_class: 'N' },
    { word: 'droop', word_class: 'V' },
    { word: 'droopy', word_class: 'AJ' },
    { word: 'drooping', word_class: 'AJ' }
  ],
  louse: [
    { word: 'louse', word_class: 'N' },
    { word: 'louse', word_class: 'V' },
    { word: 'lousy', word_class: 'AJ' },
    { word: 'lousiness', word_class: 'N' }
  ],
  limn: [{ word: 'limn', word_class: 'V' }, { word: 'limner', word_class: 'N' }, { word: 'limning', word_class: 'N' }],
  surround: [
    { word: 'surround', word_class: 'N' },
    { word: 'surround', word_class: 'V' },
    { word: 'surround', word_class: 'AJ' },
    { word: 'surrounded', word_class: 'AJ' },
    { word: 'surrounding', word_class: 'N' },
    { word: 'surrounding', word_class: 'AJ' },
    { word: 'surroundings', word_class: 'N' }
  ],
  gestation: [
    { word: 'gestate', word_class: 'V' },
    { word: 'gestation', word_class: 'N' },
    { word: 'gestational', word_class: 'AJ' }
  ],
  arching: [
    { word: 'arch', word_class: 'N' },
    { word: 'arch', word_class: 'V' },
    { word: 'arch', word_class: 'AJ' },
    { word: 'archer', word_class: 'N' },
    { word: 'arched', word_class: 'AJ' },
    { word: 'arching', word_class: 'AJ' },
    { word: 'archness', word_class: 'N' }
  ],
  sceptical: [
    { word: 'sceptic', word_class: 'N' },
    { word: 'sceptical', word_class: 'AJ' },
    { word: 'scepticism', word_class: 'N' },
    { word: 'sceptically', word_class: 'AV' }
  ],
  number: [
    { word: 'number', word_class: 'N' },
    { word: 'number', word_class: 'V' },
    { word: 'numbers', word_class: 'N' },
    { word: 'numbering', word_class: 'N' }
  ],
  singe: [
    { word: 'sing', word_class: 'N' },
    { word: 'sing', word_class: 'V' },
    { word: 'singe', word_class: 'N' },
    { word: 'singe', word_class: 'V' },
    { word: 'singer', word_class: 'N' },
    { word: 'singing', word_class: 'N' },
    { word: 'singing', word_class: 'AJ' }
  ],
  punt: [
    { word: 'punt', word_class: 'N' },
    { word: 'punt', word_class: 'V' },
    { word: 'punter', word_class: 'N' },
    { word: 'punting', word_class: 'N' }
  ],
  chapman: [
    { word: 'chap', word_class: 'N' },
    { word: 'chap', word_class: 'V' },
    { word: 'chapman', word_class: 'N' },
    { word: 'chapped', word_class: 'AJ' }
  ],
  fruiting: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  fortune: [
    { word: 'fortune', word_class: 'N' },
    { word: 'fortunate', word_class: 'N' },
    { word: 'fortunate', word_class: 'AJ' },
    { word: 'fortunately', word_class: 'AV' }
  ],
  terseness: [
    { word: 'terse', word_class: 'AJ' },
    { word: 'tersely', word_class: 'AV' },
    { word: 'terseness', word_class: 'N' }
  ],
  impracticableness: [
    { word: 'impracticable', word_class: 'AJ' },
    { word: 'impracticably', word_class: 'AV' },
    { word: 'impracticability', word_class: 'N' },
    { word: 'impracticableness', word_class: 'N' }
  ],
  adsorb: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  stabilisation: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  historically: [
    { word: 'history', word_class: 'N' },
    { word: 'historic', word_class: 'AJ' },
    { word: 'historied', word_class: 'AJ' },
    { word: 'historical', word_class: 'AJ' },
    { word: 'historically', word_class: 'AV' }
  ],
  patroller: [
    { word: 'patrol', word_class: 'N' },
    { word: 'patrol', word_class: 'V' },
    { word: 'patroller', word_class: 'N' },
    { word: 'patrolman', word_class: 'N' },
    { word: 'patrolled', word_class: 'AJ' },
    { word: 'patrolling', word_class: 'N' },
    { word: 'patrolling', word_class: 'AJ' }
  ],
  hire: [
    { word: 'hire', word_class: 'N' },
    { word: 'hire', word_class: 'V' },
    { word: 'hirer', word_class: 'N' },
    { word: 'hired', word_class: 'AJ' },
    { word: 'hiring', word_class: 'N' }
  ],
  impelled: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  quite: [
    { word: 'quit', word_class: 'V' },
    { word: 'quit', word_class: 'AJ' },
    { word: 'quite', word_class: 'AV' },
    { word: 'quits', word_class: 'AJ' }
  ],
  sufferable: [
    { word: 'suffer', word_class: 'V' },
    { word: 'sufferer', word_class: 'N' },
    { word: 'suffering', word_class: 'N' },
    { word: 'sufferance', word_class: 'N' },
    { word: 'suffering', word_class: 'AJ' },
    { word: 'sufferable', word_class: 'AJ' }
  ],
  impassively: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  slinging: [
    { word: 'sling', word_class: 'N' },
    { word: 'sling', word_class: 'V' },
    { word: 'slinger', word_class: 'N' },
    { word: 'slinging', word_class: 'N' }
  ],
  honeycomb: [
    { word: 'honeycomb', word_class: 'N' },
    { word: 'honeycomb', word_class: 'V' },
    { word: 'honeycombed', word_class: 'AJ' }
  ],
  spruce: [
    { word: 'spruce', word_class: 'N' },
    { word: 'spruce', word_class: 'V' },
    { word: 'spruce', word_class: 'AJ' },
    { word: 'sprucely', word_class: 'AV' },
    { word: 'spruceness', word_class: 'N' }
  ],
  labourer: [
    { word: 'labour', word_class: 'N' },
    { word: 'labour', word_class: 'V' },
    { word: 'labourer', word_class: 'N' },
    { word: 'laboured', word_class: 'AJ' },
    { word: 'labourite', word_class: 'N' },
    { word: 'labouring', word_class: 'AJ' }
  ],
  respecter: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  waver: [
    { word: 'waver', word_class: 'N' },
    { word: 'waver', word_class: 'V' },
    { word: 'waverer', word_class: 'N' },
    { word: 'wavering', word_class: 'N' },
    { word: 'wavering', word_class: 'AJ' }
  ],
  report: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  humane: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  wired: [
    { word: 'wire', word_class: 'N' },
    { word: 'wire', word_class: 'V' },
    { word: 'wiry', word_class: 'AJ' },
    { word: 'wired', word_class: 'AJ' },
    { word: 'wiring', word_class: 'N' }
  ],
  fearsome: [
    { word: 'fear', word_class: 'N' },
    { word: 'fear', word_class: 'V' },
    { word: 'fearful', word_class: 'AJ' },
    { word: 'fearsome', word_class: 'AJ' },
    { word: 'fearfulness', word_class: 'N' },
    { word: 'fearsomely', word_class: 'AV' }
  ],
  remunerate: [
    { word: 'remunerate', word_class: 'V' },
    { word: 'remunerator', word_class: 'N' },
    { word: 'remunerated', word_class: 'AJ' },
    { word: 'remuneration', word_class: 'N' }
  ],
  prolonged: [
    { word: 'prolong', word_class: 'V' },
    { word: 'prolong', word_class: 'AJ' },
    { word: 'prolongate', word_class: 'V' },
    { word: 'prolonged', word_class: 'AJ' },
    { word: 'prolongment', word_class: 'N' },
    { word: 'prolongation', word_class: 'N' }
  ],
  aching: [
    { word: 'ache', word_class: 'N' },
    { word: 'ache', word_class: 'V' },
    { word: 'aching', word_class: 'N' },
    { word: 'aching', word_class: 'AJ' }
  ],
  east: [{ word: 'east', word_class: 'N' }, { word: 'east', word_class: 'AJ' }, { word: 'east', word_class: 'AV' }],
  hurting: [
    { word: 'hurt', word_class: 'N' },
    { word: 'hurt', word_class: 'V' },
    { word: 'hurt', word_class: 'AJ' },
    { word: 'hurting', word_class: 'N' },
    { word: 'hurtful', word_class: 'AJ' }
  ],
  gregariousness: [
    { word: 'gregarious', word_class: 'AJ' },
    { word: 'gregariously', word_class: 'AV' },
    { word: 'gregariousness', word_class: 'N' }
  ],
  industrialize: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  urge: [
    { word: 'urge', word_class: 'N' },
    { word: 'urge', word_class: 'V' },
    { word: 'urging', word_class: 'N' },
    { word: 'urgency', word_class: 'N' }
  ],
  unpleasant: [
    { word: 'unpleasant', word_class: 'AJ' },
    { word: 'unpleasing', word_class: 'AJ' },
    { word: 'unpleasantness', word_class: 'N' }
  ],
  momentously: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  terminably: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  potent: [
    { word: 'potence', word_class: 'N' },
    { word: 'potency', word_class: 'N' },
    { word: 'potent', word_class: 'AJ' },
    { word: 'potentate', word_class: 'N' },
    { word: 'potently', word_class: 'AV' }
  ],
  brick: [{ word: 'brick', word_class: 'N' }, { word: 'brick', word_class: 'V' }, { word: 'brick', word_class: 'AJ' }],
  drift: [
    { word: 'drift', word_class: 'N' },
    { word: 'drift', word_class: 'V' },
    { word: 'drifter', word_class: 'N' },
    { word: 'drifting', word_class: 'N' },
    { word: 'drifting', word_class: 'AJ' }
  ],
  spice: [
    { word: 'spice', word_class: 'N' },
    { word: 'spice', word_class: 'V' },
    { word: 'spicy', word_class: 'AJ' },
    { word: 'spiciness', word_class: 'N' }
  ],
  expression: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  confiscable: [
    { word: 'confiscate', word_class: 'V' },
    { word: 'confiscate', word_class: 'AJ' },
    { word: 'confiscable', word_class: 'AJ' },
    { word: 'confiscated', word_class: 'AJ' },
    { word: 'confiscation', word_class: 'N' }
  ],
  papistical: [
    { word: 'papist', word_class: 'N' },
    { word: 'papist', word_class: 'AJ' },
    { word: 'papistic', word_class: 'AJ' },
    { word: 'papistical', word_class: 'AJ' }
  ],
  vacillant: [
    { word: 'vacillate', word_class: 'V' },
    { word: 'vacillant', word_class: 'AJ' },
    { word: 'vacillator', word_class: 'N' },
    { word: 'vacillation', word_class: 'N' },
    { word: 'vacillating', word_class: 'AJ' }
  ],
  leakage: [
    { word: 'leak', word_class: 'N' },
    { word: 'leak', word_class: 'V' },
    { word: 'leakage', word_class: 'N' },
    { word: 'leaking', word_class: 'AJ' }
  ],
  tribune: [
    { word: 'tribune', word_class: 'N' },
    { word: 'tribunal', word_class: 'N' },
    { word: 'tribunal', word_class: 'AJ' }
  ],
  jimmy: [{ word: 'jimmy', word_class: 'N' }, { word: 'jimmy', word_class: 'V' }, { word: 'jimmies', word_class: 'N' }],
  tutoring: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  hierarchical: [
    { word: 'hierarch', word_class: 'N' },
    { word: 'hierarchal', word_class: 'AJ' },
    { word: 'hierarchic', word_class: 'AJ' },
    { word: 'hierarchical', word_class: 'AJ' },
    { word: 'hierarchically', word_class: 'AV' }
  ],
  betray: [
    { word: 'betray', word_class: 'V' },
    { word: 'betrayal', word_class: 'N' },
    { word: 'betrayer', word_class: 'N' },
    { word: 'betraying', word_class: 'AJ' }
  ],
  conciliating: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  inducing: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  dropkick: [
    { word: 'dropkick', word_class: 'N' },
    { word: 'dropkick', word_class: 'V' },
    { word: 'dropkicker', word_class: 'N' }
  ],
  miraculously: [
    { word: 'miracle', word_class: 'N' },
    { word: 'miraculous', word_class: 'AJ' },
    { word: 'miraculously', word_class: 'AV' }
  ],
  hardheaded: [
    { word: 'hardheads', word_class: 'N' },
    { word: 'hardheaded', word_class: 'AJ' },
    { word: 'hardheadness', word_class: 'N' }
  ],
  perform: [
    { word: 'perform', word_class: 'V' },
    { word: 'performer', word_class: 'N' },
    { word: 'performing', word_class: 'N' },
    { word: 'performance', word_class: 'N' }
  ],
  grimy: [
    { word: 'grime', word_class: 'N' },
    { word: 'grim', word_class: 'AJ' },
    { word: 'grime', word_class: 'V' },
    { word: 'grimy', word_class: 'AJ' },
    { word: 'grimness', word_class: 'N' },
    { word: 'griminess', word_class: 'N' }
  ],
  balefulness: [
    { word: 'bale', word_class: 'N' },
    { word: 'bale', word_class: 'V' },
    { word: 'baleful', word_class: 'AJ' },
    { word: 'balefulness', word_class: 'N' }
  ],
  snapping: [
    { word: 'snap', word_class: 'N' },
    { word: 'snap', word_class: 'V' },
    { word: 'snapping', word_class: 'AJ' }
  ],
  palely: [
    { word: 'pale', word_class: 'N' },
    { word: 'pale', word_class: 'V' },
    { word: 'pale', word_class: 'AJ' },
    { word: 'paling', word_class: 'N' },
    { word: 'palely', word_class: 'AV' },
    { word: 'paling', word_class: 'AJ' },
    { word: 'paleness', word_class: 'N' }
  ],
  favoring: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  conforming: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  eudemon: [
    { word: 'eudemon', word_class: 'N' },
    { word: 'eudemonic', word_class: 'AJ' },
    { word: 'eudemonism', word_class: 'N' }
  ],
  beguilement: [
    { word: 'beguile', word_class: 'V' },
    { word: 'beguiled', word_class: 'AJ' },
    { word: 'beguiling', word_class: 'AJ' },
    { word: 'beguilement', word_class: 'N' }
  ],
  diligent: [
    { word: 'diligence', word_class: 'N' },
    { word: 'diligent', word_class: 'AJ' },
    { word: 'diligently', word_class: 'AV' }
  ],
  honor: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  chivalrous: [
    { word: 'chivalry', word_class: 'N' },
    { word: 'chivalric', word_class: 'AJ' },
    { word: 'chivalrous', word_class: 'AJ' },
    { word: 'chivalrously', word_class: 'AV' }
  ],
  tottering: [
    { word: 'totter', word_class: 'N' },
    { word: 'totter', word_class: 'V' },
    { word: 'tottering', word_class: 'AJ' }
  ],
  negativist: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  dismount: [
    { word: 'dismount', word_class: 'N' },
    { word: 'dismount', word_class: 'V' },
    { word: 'dismounted', word_class: 'AJ' }
  ],
  prophets: [
    { word: 'prophet', word_class: 'N' },
    { word: 'prophets', word_class: 'N' },
    { word: 'prophetic', word_class: 'AJ' },
    { word: 'prophetical', word_class: 'AJ' },
    { word: 'prophetically', word_class: 'AV' }
  ],
  bewitchment: [
    { word: 'bewitch', word_class: 'V' },
    { word: 'bewitched', word_class: 'AJ' },
    { word: 'bewitching', word_class: 'AJ' },
    { word: 'bewitchment', word_class: 'N' }
  ],
  jurisprudential: [
    { word: 'jurisprudent', word_class: 'N' },
    { word: 'jurisprudence', word_class: 'N' },
    { word: 'jurisprudential', word_class: 'AJ' },
    { word: 'jurisprudentially', word_class: 'AV' }
  ],
  deraign: [
    { word: 'deraign', word_class: 'V' },
    { word: 'darraign', word_class: 'V' },
    { word: 'deraignment', word_class: 'N' },
    { word: 'darraignment', word_class: 'N' }
  ],
  recycle: [
    { word: 'recycle', word_class: 'V' },
    { word: 'recycling', word_class: 'N' },
    { word: 'recyclable', word_class: 'AJ' }
  ],
  renunciate: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  saddler: [
    { word: 'saddle', word_class: 'N' },
    { word: 'saddle', word_class: 'V' },
    { word: 'saddler', word_class: 'N' },
    { word: 'saddled', word_class: 'AJ' }
  ],
  hematology: [
    { word: 'hematology', word_class: 'N' },
    { word: 'hematologic', word_class: 'AJ' },
    { word: 'hematological', word_class: 'AJ' }
  ],
  idolatrize: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  port: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  terse: [
    { word: 'terse', word_class: 'AJ' },
    { word: 'tersely', word_class: 'AV' },
    { word: 'terseness', word_class: 'N' }
  ],
  parodist: [
    { word: 'parody', word_class: 'N' },
    { word: 'parody', word_class: 'V' },
    { word: 'parodist', word_class: 'N' }
  ],
  unconscious: [
    { word: 'unconscious', word_class: 'N' },
    { word: 'unconscious', word_class: 'AJ' },
    { word: 'unconsciously', word_class: 'AV' },
    { word: 'unconsciousness', word_class: 'N' }
  ],
  contemptuous: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  diminish: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  disregarding: [
    { word: 'disregard', word_class: 'N' },
    { word: 'disregard', word_class: 'V' },
    { word: 'disregarded', word_class: 'AJ' },
    { word: 'disregarding', word_class: 'AV' }
  ],
  immaterialization: [
    { word: 'immaterial', word_class: 'AJ' },
    { word: 'immateriality', word_class: 'N' },
    { word: 'immaterialize', word_class: 'V' },
    { word: 'immaterialization', word_class: 'N' }
  ],
  winged: [
    { word: 'wing', word_class: 'N' },
    { word: 'wing', word_class: 'V' },
    { word: 'wings', word_class: 'N' },
    { word: 'winger', word_class: 'N' },
    { word: 'winged', word_class: 'AJ' },
    { word: 'wingman', word_class: 'N' }
  ],
  astrophysics: [
    { word: 'astrophysics', word_class: 'N' },
    { word: 'astrophysical', word_class: 'AJ' },
    { word: 'astrophysicist', word_class: 'N' }
  ],
  fibrous: [
    { word: 'fib', word_class: 'N' },
    { word: 'fib', word_class: 'V' },
    { word: 'fiber', word_class: 'N' },
    { word: 'fibbing', word_class: 'N' },
    { word: 'fibrous', word_class: 'AJ' }
  ],
  vacuolation: [
    { word: 'vacuole', word_class: 'N' },
    { word: 'vacuolate', word_class: 'AJ' },
    { word: 'vacuolated', word_class: 'AJ' },
    { word: 'vacuolation', word_class: 'N' },
    { word: 'vacuolization', word_class: 'N' }
  ],
  enrage: [
    { word: 'enrage', word_class: 'V' },
    { word: 'enraged', word_class: 'AJ' },
    { word: 'enragement', word_class: 'N' }
  ],
  stun: [
    { word: 'stun', word_class: 'V' },
    { word: 'stunned', word_class: 'AJ' },
    { word: 'stunning', word_class: 'AJ' }
  ],
  boisterously: [
    { word: 'boisterous', word_class: 'AJ' },
    { word: 'boisterously', word_class: 'AV' },
    { word: 'boisterousness', word_class: 'N' }
  ],
  notifiable: [
    { word: 'notify', word_class: 'V' },
    { word: 'notifying', word_class: 'N' },
    { word: 'notifiable', word_class: 'AJ' },
    { word: 'notification', word_class: 'N' }
  ],
  endearment: [
    { word: 'endear', word_class: 'V' },
    { word: 'endearing', word_class: 'AJ' },
    { word: 'endearment', word_class: 'N' }
  ],
  juggle: [
    { word: 'juggle', word_class: 'N' },
    { word: 'juggle', word_class: 'V' },
    { word: 'juggler', word_class: 'N' },
    { word: 'jugglery', word_class: 'N' },
    { word: 'juggling', word_class: 'N' }
  ],
  remissness: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  encystment: [
    { word: 'encyst', word_class: 'V' },
    { word: 'encysted', word_class: 'AJ' },
    { word: 'encystment', word_class: 'N' },
    { word: 'encystation', word_class: 'N' }
  ],
  parenthesis: [
    { word: 'parenthesis', word_class: 'N' },
    { word: 'parenthesize', word_class: 'V' },
    { word: 'parenthesization', word_class: 'N' }
  ],
  supremacy: [
    { word: 'supreme', word_class: 'AJ' },
    { word: 'supremacy', word_class: 'N' },
    { word: 'supremely', word_class: 'AV' },
    { word: 'supremacist', word_class: 'N' }
  ],
  depreciation: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  acrylic: [
    { word: 'acrylic', word_class: 'N' },
    { word: 'acrylate', word_class: 'N' },
    { word: 'acrylic', word_class: 'AJ' }
  ],
  striper: [
    { word: 'strip', word_class: 'N' },
    { word: 'strip', word_class: 'V' },
    { word: 'strip', word_class: 'AJ' },
    { word: 'striper', word_class: 'N' },
    { word: 'stripped', word_class: 'AJ' },
    { word: 'stripping', word_class: 'N' }
  ],
  solicitation: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  comely: [
    { word: 'come', word_class: 'V' },
    { word: 'come', word_class: 'AJ' },
    { word: 'comer', word_class: 'N' },
    { word: 'coming', word_class: 'N' },
    { word: 'comely', word_class: 'AJ' },
    { word: 'coming', word_class: 'AJ' }
  ],
  institutionalized: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  bookkeep: [
    { word: 'bookkeep', word_class: 'V' },
    { word: 'bookkeeper', word_class: 'N' },
    { word: 'bookkeeping', word_class: 'N' }
  ],
  cicatrization: [
    { word: 'cicatrise', word_class: 'V' },
    { word: 'cicatrize', word_class: 'V' },
    { word: 'cicatrisation', word_class: 'N' },
    { word: 'cicatrization', word_class: 'N' }
  ],
  splintered: [
    { word: 'splinter', word_class: 'N' },
    { word: 'splinter', word_class: 'V' },
    { word: 'splinters', word_class: 'N' },
    { word: 'splintered', word_class: 'AJ' },
    { word: 'splintering', word_class: 'N' }
  ],
  carinated: [
    { word: 'carinal', word_class: 'AJ' },
    { word: 'carinate', word_class: 'N' },
    { word: 'carinate', word_class: 'AJ' },
    { word: 'carinated', word_class: 'AJ' }
  ],
  impend: [
    { word: 'impend', word_class: 'V' },
    { word: 'impendence', word_class: 'N' },
    { word: 'impendency', word_class: 'N' },
    { word: 'impending', word_class: 'AJ' }
  ],
  belittlement: [
    { word: 'belittle', word_class: 'V' },
    { word: 'belittled', word_class: 'AJ' },
    { word: 'belittling', word_class: 'N' },
    { word: 'belittling', word_class: 'AJ' },
    { word: 'belittlement', word_class: 'N' }
  ],
  detection: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  brilliant: [
    { word: 'brilliance', word_class: 'N' },
    { word: 'brilliancy', word_class: 'N' },
    { word: 'brilliant', word_class: 'AJ' }
  ],
  appalled: [
    { word: 'appal', word_class: 'V' },
    { word: 'appall', word_class: 'V' },
    { word: 'appalled', word_class: 'AJ' },
    { word: 'appalling', word_class: 'N' },
    { word: 'appalling', word_class: 'AJ' }
  ],
  fruited: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  macadamisation: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  mop: [{ word: 'mop', word_class: 'N' }, { word: 'mop', word_class: 'V' }, { word: 'mopping', word_class: 'N' }],
  foreignness: [
    { word: 'foreign', word_class: 'AJ' },
    { word: 'foreigner', word_class: 'N' },
    { word: 'foreignness', word_class: 'N' }
  ],
  burglarization: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  maturement: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  implosive: [
    { word: 'implode', word_class: 'V' },
    { word: 'implosion', word_class: 'N' },
    { word: 'implosive', word_class: 'AJ' }
  ],
  bundling: [
    { word: 'bundle', word_class: 'N' },
    { word: 'bundle', word_class: 'V' },
    { word: 'bundling', word_class: 'N' }
  ],
  leaf: [
    { word: 'leaf', word_class: 'N' },
    { word: 'leaf', word_class: 'V' },
    { word: 'leafed', word_class: 'AJ' },
    { word: 'leafing', word_class: 'N' }
  ],
  unreasoning: [
    { word: 'unreason', word_class: 'N' },
    { word: 'unreasoning', word_class: 'AJ' },
    { word: 'unreasonable', word_class: 'AJ' },
    { word: 'unreasonably', word_class: 'AV' }
  ],
  illuminant: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  impromptu: [
    { word: 'impromptu', word_class: 'N' },
    { word: 'impromptu', word_class: 'AJ' },
    { word: 'impromptu', word_class: 'AV' }
  ],
  infest: [
    { word: 'infest', word_class: 'N' },
    { word: 'infest', word_class: 'V' },
    { word: 'infested', word_class: 'AJ' },
    { word: 'infestation', word_class: 'N' }
  ],
  thrusting: [
    { word: 'thrust', word_class: 'N' },
    { word: 'thrust', word_class: 'V' },
    { word: 'thruster', word_class: 'N' },
    { word: 'thrusting', word_class: 'N' }
  ],
  copulation: [
    { word: 'copulate', word_class: 'V' },
    { word: 'copulation', word_class: 'N' },
    { word: 'copulative', word_class: 'N' },
    { word: 'copulative', word_class: 'AJ' }
  ],
  sexist: [
    { word: 'sex', word_class: 'N' },
    { word: 'sex', word_class: 'V' },
    { word: 'sexed', word_class: 'AJ' },
    { word: 'sexism', word_class: 'N' },
    { word: 'sexist', word_class: 'N' },
    { word: 'sexist', word_class: 'AJ' }
  ],
  electromagnetism: [
    { word: 'electromagnet', word_class: 'N' },
    { word: 'electromagnetic', word_class: 'AJ' },
    { word: 'electromagnetics', word_class: 'N' },
    { word: 'electromagnetism', word_class: 'N' }
  ],
  repay: [
    { word: 'repay', word_class: 'V' },
    { word: 'repayment', word_class: 'N' },
    { word: 'repayable', word_class: 'AJ' }
  ],
  diazotization: [
    { word: 'diazotise', word_class: 'V' },
    { word: 'diazotize', word_class: 'V' },
    { word: 'diazotisation', word_class: 'N' },
    { word: 'diazotization', word_class: 'N' }
  ],
  liberalise: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  frowning: [
    { word: 'frown', word_class: 'N' },
    { word: 'frown', word_class: 'V' },
    { word: 'frowning', word_class: 'AJ' }
  ],
  cortication: [
    { word: 'cortical', word_class: 'AJ' },
    { word: 'corticate', word_class: 'V' },
    { word: 'cortically', word_class: 'AV' },
    { word: 'cortication', word_class: 'N' }
  ],
  deodorization: [
    { word: 'deodorant', word_class: 'N' },
    { word: 'deodorise', word_class: 'V' },
    { word: 'deodorize', word_class: 'V' },
    { word: 'deodorisation', word_class: 'N' },
    { word: 'deodorization', word_class: 'N' }
  ],
  aril: [
    { word: 'aril', word_class: 'N' },
    { word: 'ariled', word_class: 'AJ' },
    { word: 'arillate', word_class: 'AJ' }
  ],
  cutinise: [
    { word: 'cutinise', word_class: 'V' },
    { word: 'cutinize', word_class: 'V' },
    { word: 'cutinisation', word_class: 'N' },
    { word: 'cutinization', word_class: 'N' }
  ],
  surf: [
    { word: 'surf', word_class: 'N' },
    { word: 'surf', word_class: 'V' },
    { word: 'surfer', word_class: 'N' },
    { word: 'surfing', word_class: 'N' }
  ],
  tabulator: [
    { word: 'tabulate', word_class: 'V' },
    { word: 'tabulator', word_class: 'N' },
    { word: 'tabulation', word_class: 'N' }
  ],
  optimisation: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  prognositcation: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  awful: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  pro: [{ word: 'pro', word_class: 'N' }, { word: 'pro', word_class: 'AJ' }, { word: 'pro', word_class: 'AV' }],
  preterit: [
    { word: 'preterit', word_class: 'N' },
    { word: 'preterit', word_class: 'AJ' },
    { word: 'preterite', word_class: 'N' },
    { word: 'preterite', word_class: 'AJ' },
    { word: 'preterition', word_class: 'N' }
  ],
  armor: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  commute: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  keratin: [
    { word: 'keratin', word_class: 'N' },
    { word: 'keratinise', word_class: 'V' },
    { word: 'keratinize', word_class: 'V' },
    { word: 'keratinisation', word_class: 'N' },
    { word: 'keratinization', word_class: 'N' }
  ],
  buy: [
    { word: 'buy', word_class: 'N' },
    { word: 'buy', word_class: 'V' },
    { word: 'buyer', word_class: 'N' },
    { word: 'buying', word_class: 'N' }
  ],
  definiteness: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  fume: [
    { word: 'fume', word_class: 'N' },
    { word: 'fume', word_class: 'V' },
    { word: 'fumes', word_class: 'N' },
    { word: 'fumed', word_class: 'AJ' }
  ],
  balding: [
    { word: 'bald', word_class: 'V' },
    { word: 'bald', word_class: 'AJ' },
    { word: 'baldy', word_class: 'N' },
    { word: 'balding', word_class: 'AJ' },
    { word: 'baldness', word_class: 'N' }
  ],
  morbidity: [
    { word: 'morbid', word_class: 'AJ' },
    { word: 'morbidity', word_class: 'N' },
    { word: 'morbidness', word_class: 'N' }
  ],
  victimisation: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  democratic: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  galvanize: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  valorise: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  spinning: [
    { word: 'spin', word_class: 'N' },
    { word: 'spin', word_class: 'V' },
    { word: 'spinal', word_class: 'N' },
    { word: 'spinal', word_class: 'AJ' },
    { word: 'spinning', word_class: 'N' },
    { word: 'spinally', word_class: 'AV' },
    { word: 'spinning', word_class: 'AJ' }
  ],
  glacial: [
    { word: 'glacier', word_class: 'N' },
    { word: 'glacial', word_class: 'AJ' },
    { word: 'glacially', word_class: 'AV' }
  ],
  unargumentative: [
    { word: 'unarguable', word_class: 'AJ' },
    { word: 'unarguably', word_class: 'AV' },
    { word: 'unargumentative', word_class: 'AJ' }
  ],
  disaffection: [
    { word: 'disaffect', word_class: 'V' },
    { word: 'disaffected', word_class: 'AJ' },
    { word: 'disaffection', word_class: 'N' }
  ],
  extinguisher: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  keeled: [{ word: 'keel', word_class: 'N' }, { word: 'keel', word_class: 'V' }, { word: 'keeled', word_class: 'AJ' }],
  monarchial: [
    { word: 'monarchy', word_class: 'N' },
    { word: 'monarchy', word_class: 'AJ' },
    { word: 'monarchial', word_class: 'AJ' }
  ],
  mechanization: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  factorise: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  coexisting: [
    { word: 'coexist', word_class: 'V' },
    { word: 'coexistence', word_class: 'N' },
    { word: 'coexistent', word_class: 'AJ' },
    { word: 'coexisting', word_class: 'AJ' }
  ],
  secularization: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  nymphomaniacal: [
    { word: 'nymphomaniac', word_class: 'N' },
    { word: 'nymphomaniac', word_class: 'AJ' },
    { word: 'nymphomaniacal', word_class: 'AJ' }
  ],
  preclusive: [
    { word: 'preclude', word_class: 'V' },
    { word: 'preclusion', word_class: 'N' },
    { word: 'preclusive', word_class: 'AJ' }
  ],
  mournfulness: [
    { word: 'mourn', word_class: 'V' },
    { word: 'mourner', word_class: 'N' },
    { word: 'mourning', word_class: 'N' },
    { word: 'mournful', word_class: 'AJ' },
    { word: 'mourning', word_class: 'AJ' },
    { word: 'mournfulness', word_class: 'N' }
  ],
  perceiver: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  sleekness: [
    { word: 'sleek', word_class: 'V' },
    { word: 'sleek', word_class: 'AJ' },
    { word: 'sleekness', word_class: 'N' }
  ],
  proclaim: [
    { word: 'proclaim', word_class: 'N' },
    { word: 'proclaim', word_class: 'V' },
    { word: 'proclaimed', word_class: 'AJ' },
    { word: 'proclamation', word_class: 'N' }
  ],
  surfacing: [
    { word: 'surface', word_class: 'N' },
    { word: 'surface', word_class: 'V' },
    { word: 'surface', word_class: 'AJ' },
    { word: 'surfacing', word_class: 'N' }
  ],
  pinched: [
    { word: 'pinch', word_class: 'N' },
    { word: 'pinch', word_class: 'V' },
    { word: 'pinche', word_class: 'N' },
    { word: 'pinched', word_class: 'AJ' }
  ],
  geological: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  inert: [
    { word: 'inert', word_class: 'AJ' },
    { word: 'inertance', word_class: 'N' },
    { word: 'inertness', word_class: 'N' }
  ],
  enforced: [
    { word: 'enforce', word_class: 'V' },
    { word: 'enforcer', word_class: 'N' },
    { word: 'enforced', word_class: 'AJ' },
    { word: 'enforcement', word_class: 'N' },
    { word: 'enforceable', word_class: 'AJ' }
  ],
  manipulate: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  glassworks: [
    { word: 'glasswork', word_class: 'N' },
    { word: 'glassworks', word_class: 'N' },
    { word: 'glassworker', word_class: 'N' }
  ],
  sanitisation: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  impetuously: [
    { word: 'impetus', word_class: 'N' },
    { word: 'impetuous', word_class: 'AJ' },
    { word: 'impetuously', word_class: 'AV' },
    { word: 'impetuousness', word_class: 'N' }
  ],
  sweetness: [
    { word: 'sweet', word_class: 'N' },
    { word: 'sweet', word_class: 'AJ' },
    { word: 'sweet', word_class: 'AV' },
    { word: 'sweetness', word_class: 'N' }
  ],
  paternally: [
    { word: 'paternal', word_class: 'AJ' },
    { word: 'paternity', word_class: 'N' },
    { word: 'paternalism', word_class: 'N' },
    { word: 'paternally', word_class: 'AV' }
  ],
  metrication: [
    { word: 'metric', word_class: 'N' },
    { word: 'metric', word_class: 'AJ' },
    { word: 'metrical', word_class: 'AJ' },
    { word: 'metricize', word_class: 'V' },
    { word: 'metrically', word_class: 'AV' },
    { word: 'metrication', word_class: 'N' }
  ],
  enshrinement: [
    { word: 'enshrine', word_class: 'V' },
    { word: 'inshrine', word_class: 'V' },
    { word: 'enshrinement', word_class: 'N' }
  ],
  officious: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  vilely: [
    { word: 'vile', word_class: 'AJ' },
    { word: 'vilely', word_class: 'AV' },
    { word: 'vileness', word_class: 'N' }
  ],
  vitalisation: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  tempting: [
    { word: 'tempt', word_class: 'V' },
    { word: 'tempter', word_class: 'N' },
    { word: 'tempting', word_class: 'AJ' },
    { word: 'temptress', word_class: 'N' },
    { word: 'temptation', word_class: 'N' }
  ],
  hulking: [
    { word: 'hulk', word_class: 'N' },
    { word: 'hulk', word_class: 'V' },
    { word: 'hulking', word_class: 'AJ' }
  ],
  bird: [{ word: 'bird', word_class: 'N' }, { word: 'bird', word_class: 'V' }, { word: 'birding', word_class: 'N' }],
  printer: [
    { word: 'print', word_class: 'N' },
    { word: 'print', word_class: 'V' },
    { word: 'print', word_class: 'AJ' },
    { word: 'printer', word_class: 'N' },
    { word: 'printed', word_class: 'AJ' },
    { word: 'printing', word_class: 'N' }
  ],
  sweat: [
    { word: 'sweat', word_class: 'N' },
    { word: 'sweat', word_class: 'V' },
    { word: 'sweats', word_class: 'N' },
    { word: 'sweater', word_class: 'N' },
    { word: 'sweating', word_class: 'N' },
    { word: 'sweating', word_class: 'AJ' }
  ],
  admonition: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  singing: [
    { word: 'sing', word_class: 'N' },
    { word: 'sing', word_class: 'V' },
    { word: 'singe', word_class: 'N' },
    { word: 'singe', word_class: 'V' },
    { word: 'singer', word_class: 'N' },
    { word: 'singing', word_class: 'N' },
    { word: 'singing', word_class: 'AJ' }
  ],
  provisioner: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  internationalistic: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  moralisation: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  obligation: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  bunch: [
    { word: 'bunch', word_class: 'N' },
    { word: 'bunch', word_class: 'V' },
    { word: 'bunched', word_class: 'AJ' }
  ],
  irritative: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  ruggedization: [
    { word: 'ruggedize', word_class: 'V' },
    { word: 'ruggedness', word_class: 'N' },
    { word: 'ruggedization', word_class: 'N' }
  ],
  distracted: [
    { word: 'distract', word_class: 'N' },
    { word: 'distract', word_class: 'V' },
    { word: 'distracted', word_class: 'AJ' },
    { word: 'distraction', word_class: 'N' }
  ],
  wolf: [{ word: 'wolf', word_class: 'N' }, { word: 'wolf', word_class: 'V' }, { word: 'wolfman', word_class: 'N' }],
  sharpener: [
    { word: 'sharpen', word_class: 'V' },
    { word: 'sharpener', word_class: 'N' },
    { word: 'sharpened', word_class: 'AJ' }
  ],
  rightness: [
    { word: 'right', word_class: 'V' },
    { word: 'right', word_class: 'N' },
    { word: 'right', word_class: 'AJ' },
    { word: 'right', word_class: 'AV' },
    { word: 'rightist', word_class: 'N' },
    { word: 'rightful', word_class: 'AJ' },
    { word: 'rightist', word_class: 'AJ' },
    { word: 'rightness', word_class: 'N' },
    { word: 'rightfulness', word_class: 'N' }
  ],
  outward: [
    { word: 'outward', word_class: 'V' },
    { word: 'outward', word_class: 'AJ' },
    { word: 'outward', word_class: 'AV' },
    { word: 'outwards', word_class: 'AV' },
    { word: 'outwardness', word_class: 'N' }
  ],
  diffusor: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  labour: [
    { word: 'labour', word_class: 'N' },
    { word: 'labour', word_class: 'V' },
    { word: 'labourer', word_class: 'N' },
    { word: 'laboured', word_class: 'AJ' },
    { word: 'labourite', word_class: 'N' },
    { word: 'labouring', word_class: 'AJ' }
  ],
  unobtrusive: [
    { word: 'unobtrusive', word_class: 'AJ' },
    { word: 'unobtrusively', word_class: 'AV' },
    { word: 'unobtrusiveness', word_class: 'N' }
  ],
  differentiable: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  misjudgement: [
    { word: 'misjudge', word_class: 'V' },
    { word: 'misjudgment', word_class: 'N' },
    { word: 'misjudgement', word_class: 'N' }
  ],
  fertilised: [
    { word: 'fertilise', word_class: 'V' },
    { word: 'fertilised', word_class: 'AJ' },
    { word: 'fertilisation', word_class: 'N' }
  ],
  disinherit: [
    { word: 'disinherit', word_class: 'V' },
    { word: 'disinherited', word_class: 'AJ' },
    { word: 'disinheritance', word_class: 'N' }
  ],
  hoofed: [
    { word: 'hoof', word_class: 'N' },
    { word: 'hoof', word_class: 'V' },
    { word: 'hoofed', word_class: 'AJ' },
    { word: 'hoofing', word_class: 'N' }
  ],
  alleviate: [
    { word: 'alleviate', word_class: 'V' },
    { word: 'alleviated', word_class: 'AJ' },
    { word: 'alleviation', word_class: 'N' },
    { word: 'alleviative', word_class: 'AJ' }
  ],
  eventuate: [
    { word: 'eventual', word_class: 'AJ' },
    { word: 'eventuate', word_class: 'V' },
    { word: 'eventuality', word_class: 'N' },
    { word: 'eventually', word_class: 'AV' },
    { word: 'eventuation', word_class: 'N' }
  ],
  same: [{ word: 'same', word_class: 'AJ' }, { word: 'same', word_class: 'AV' }, { word: 'sameness', word_class: 'N' }],
  pressman: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  discriminant: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  snuff: [
    { word: 'snuff', word_class: 'N' },
    { word: 'snuff', word_class: 'V' },
    { word: 'snuff', word_class: 'AJ' },
    { word: 'snuffer', word_class: 'N' },
    { word: 'snuffers', word_class: 'N' }
  ],
  locker: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  stow: [{ word: 'stow', word_class: 'V' }, { word: 'stowage', word_class: 'N' }, { word: 'stowing', word_class: 'N' }],
  resist: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  equitation: [
    { word: 'equitable', word_class: 'AJ' },
    { word: 'equitably', word_class: 'AV' },
    { word: 'equitation', word_class: 'N' }
  ],
  absurd: [
    { word: 'absurd', word_class: 'AJ' },
    { word: 'absurdity', word_class: 'N' },
    { word: 'absurdness', word_class: 'N' }
  ],
  standardisation: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  interposition: [
    { word: 'interpose', word_class: 'V' },
    { word: 'interposal', word_class: 'N' },
    { word: 'interposition', word_class: 'N' }
  ],
  jumpers: [
    { word: 'jump', word_class: 'N' },
    { word: 'jump', word_class: 'V' },
    { word: 'jumper', word_class: 'N' },
    { word: 'jumpers', word_class: 'N' },
    { word: 'jumping', word_class: 'N' },
    { word: 'jumping', word_class: 'AJ' }
  ],
  cannulation: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  fiddler: [
    { word: 'fiddle', word_class: 'N' },
    { word: 'fiddle', word_class: 'V' },
    { word: 'fiddler', word_class: 'N' },
    { word: 'fiddling', word_class: 'AJ' }
  ],
  injectable: [
    { word: 'inject', word_class: 'V' },
    { word: 'injectant', word_class: 'N' },
    { word: 'injection', word_class: 'N' },
    { word: 'injectable', word_class: 'AJ' }
  ],
  demobilize: [
    { word: 'demobilise', word_class: 'V' },
    { word: 'demobilize', word_class: 'V' },
    { word: 'demobilisation', word_class: 'N' },
    { word: 'demobilization', word_class: 'N' }
  ],
  turgidness: [
    { word: 'turgid', word_class: 'AJ' },
    { word: 'turgidity', word_class: 'N' },
    { word: 'turgidness', word_class: 'N' }
  ],
  metaphorical: [
    { word: 'metaphor', word_class: 'N' },
    { word: 'metaphoric', word_class: 'AJ' },
    { word: 'metaphorical', word_class: 'AJ' },
    { word: 'metaphorically', word_class: 'AV' }
  ],
  malignant: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  deregulation: [
    { word: 'deregulate', word_class: 'V' },
    { word: 'deregulating', word_class: 'N' },
    { word: 'deregulation', word_class: 'N' }
  ],
  anguish: [
    { word: 'anguish', word_class: 'N' },
    { word: 'anguish', word_class: 'V' },
    { word: 'anguished', word_class: 'AJ' }
  ],
  singleness: [
    { word: 'single', word_class: 'N' },
    { word: 'single', word_class: 'V' },
    { word: 'single', word_class: 'AJ' },
    { word: 'singles', word_class: 'N' },
    { word: 'singly', word_class: 'AV' },
    { word: 'singleness', word_class: 'N' }
  ],
  disability: [
    { word: 'disable', word_class: 'N' },
    { word: 'disable', word_class: 'V' },
    { word: 'disabled', word_class: 'N' },
    { word: 'disable', word_class: 'AJ' },
    { word: 'disabled', word_class: 'AJ' },
    { word: 'disability', word_class: 'N' },
    { word: 'disabling', word_class: 'AJ' },
    { word: 'disablement', word_class: 'N' }
  ],
  abstrusity: [
    { word: 'abstruse', word_class: 'AJ' },
    { word: 'abstrusity', word_class: 'N' },
    { word: 'abstrusely', word_class: 'AV' },
    { word: 'abstruseness', word_class: 'N' }
  ],
  dockage: [
    { word: 'dock', word_class: 'N' },
    { word: 'dock', word_class: 'V' },
    { word: 'docker', word_class: 'N' },
    { word: 'dockage', word_class: 'N' },
    { word: 'docked', word_class: 'AJ' },
    { word: 'docking', word_class: 'N' }
  ],
  translational: [
    { word: 'translate', word_class: 'V' },
    { word: 'translate', word_class: 'AJ' },
    { word: 'translator', word_class: 'N' },
    { word: 'translation', word_class: 'N' },
    { word: 'translatable', word_class: 'AJ' },
    { word: 'translational', word_class: 'AJ' }
  ],
  replace: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  hiring: [
    { word: 'hire', word_class: 'N' },
    { word: 'hire', word_class: 'V' },
    { word: 'hirer', word_class: 'N' },
    { word: 'hired', word_class: 'AJ' },
    { word: 'hiring', word_class: 'N' }
  ],
  decorative: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  seriate: [
    { word: 'seriate', word_class: 'V' },
    { word: 'seriate', word_class: 'AJ' },
    { word: 'seriation', word_class: 'N' }
  ],
  acquitted: [
    { word: 'acquit', word_class: 'V' },
    { word: 'acquittal', word_class: 'N' },
    { word: 'acquitted', word_class: 'AJ' },
    { word: 'acquittance', word_class: 'N' }
  ],
  exhort: [
    { word: 'exhort', word_class: 'V' },
    { word: 'exhortation', word_class: 'N' },
    { word: 'exhortative', word_class: 'AJ' }
  ],
  reproach: [
    { word: 'reproach', word_class: 'N' },
    { word: 'reproach', word_class: 'V' },
    { word: 'reproachful', word_class: 'AJ' },
    { word: 'reproachment', word_class: 'N' }
  ],
  shaker: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  artiodactyl: [
    { word: 'artiodactyl', word_class: 'N' },
    { word: 'artiodactyl', word_class: 'AJ' },
    { word: 'artiodactylous', word_class: 'AJ' }
  ],
  behold: [
    { word: 'behold', word_class: 'V' },
    { word: 'beholder', word_class: 'N' },
    { word: 'beholding', word_class: 'N' }
  ],
  affricate: [
    { word: 'affricate', word_class: 'N' },
    { word: 'affrication', word_class: 'N' },
    { word: 'affricative', word_class: 'N' }
  ],
  cleanliness: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  detract: [
    { word: 'detract', word_class: 'V' },
    { word: 'detraction', word_class: 'N' },
    { word: 'detractive', word_class: 'AJ' }
  ],
  indictability: [
    { word: 'indict', word_class: 'V' },
    { word: 'indiction', word_class: 'N' },
    { word: 'indictment', word_class: 'N' },
    { word: 'indictable', word_class: 'AJ' },
    { word: 'indictability', word_class: 'N' }
  ],
  exquisite: [
    { word: 'exquisite', word_class: 'AJ' },
    { word: 'exquisitely', word_class: 'AV' },
    { word: 'exquisiteness', word_class: 'N' }
  ],
  aromatise: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  scattered: [
    { word: 'scatter', word_class: 'N' },
    { word: 'scatter', word_class: 'V' },
    { word: 'scattered', word_class: 'AJ' },
    { word: 'scattering', word_class: 'N' },
    { word: 'scattering', word_class: 'AJ' }
  ],
  full: [
    { word: 'full', word_class: 'V' },
    { word: 'full', word_class: 'AJ' },
    { word: 'full', word_class: 'AV' },
    { word: 'fully', word_class: 'AV' },
    { word: 'fulness', word_class: 'N' },
    { word: 'fullness', word_class: 'N' }
  ],
  striation: [
    { word: 'striate', word_class: 'V' },
    { word: 'striate', word_class: 'AJ' },
    { word: 'striated', word_class: 'AJ' },
    { word: 'striation', word_class: 'N' }
  ],
  whipping: [
    { word: 'whip', word_class: 'N' },
    { word: 'whip', word_class: 'V' },
    { word: 'whipping', word_class: 'N' },
    { word: 'whipping', word_class: 'AJ' }
  ],
  bolt: [
    { word: 'bolt', word_class: 'N' },
    { word: 'bolt', word_class: 'V' },
    { word: 'bolt', word_class: 'AV' },
    { word: 'bolted', word_class: 'AJ' }
  ],
  embowered: [
    { word: 'embow', word_class: 'V' },
    { word: 'embower', word_class: 'V' },
    { word: 'embowment', word_class: 'N' },
    { word: 'embowered', word_class: 'AJ' }
  ],
  botcher: [
    { word: 'botch', word_class: 'N' },
    { word: 'botch', word_class: 'V' },
    { word: 'botcher', word_class: 'N' },
    { word: 'botched', word_class: 'AJ' }
  ],
  denouncement: [
    { word: 'denounce', word_class: 'V' },
    { word: 'denunciate', word_class: 'V' },
    { word: 'denouncement', word_class: 'N' },
    { word: 'denunciation', word_class: 'N' },
    { word: 'denunciative', word_class: 'AJ' }
  ],
  likelihood: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  acronymous: [
    { word: 'acronym', word_class: 'N' },
    { word: 'acronymic', word_class: 'AJ' },
    { word: 'acronymous', word_class: 'AJ' }
  ],
  digitise: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  saint: [
    { word: 'saint', word_class: 'N' },
    { word: 'saint', word_class: 'V' },
    { word: 'sainted', word_class: 'AJ' }
  ],
  impair: [
    { word: 'impair', word_class: 'N' },
    { word: 'impair', word_class: 'V' },
    { word: 'impaired', word_class: 'AJ' },
    { word: 'impairment', word_class: 'N' }
  ],
  tenting: [
    { word: 'tent', word_class: 'N' },
    { word: 'tent', word_class: 'V' },
    { word: 'tenting', word_class: 'N' },
    { word: 'tentative', word_class: 'AJ' },
    { word: 'tentatively', word_class: 'AV' }
  ],
  cease: [
    { word: 'cease', word_class: 'N' },
    { word: 'cease', word_class: 'V' },
    { word: 'cessation', word_class: 'N' }
  ],
  proportionateness: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  mercerise: [
    { word: 'mercer', word_class: 'N' },
    { word: 'mercerise', word_class: 'V' },
    { word: 'mercerize', word_class: 'V' },
    { word: 'mercerisation', word_class: 'N' },
    { word: 'mercerization', word_class: 'N' }
  ],
  presumptuousness: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  thermodynamically: [
    { word: 'thermodynamic', word_class: 'AJ' },
    { word: 'thermodynamics', word_class: 'N' },
    { word: 'thermodynamical', word_class: 'AJ' },
    { word: 'thermodynamically', word_class: 'AV' }
  ],
  pulverisation: [
    { word: 'pulverise', word_class: 'V' },
    { word: 'pulverize', word_class: 'V' },
    { word: 'pulverized', word_class: 'AJ' },
    { word: 'pulverisation', word_class: 'N' },
    { word: 'pulverization', word_class: 'N' }
  ],
  meshing: [
    { word: 'mesh', word_class: 'N' },
    { word: 'mesh', word_class: 'V' },
    { word: 'meshed', word_class: 'N' },
    { word: 'meshed', word_class: 'AJ' },
    { word: 'meshing', word_class: 'N' }
  ],
  sublime: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  incoherence: [
    { word: 'incoherence', word_class: 'N' },
    { word: 'incoherency', word_class: 'N' },
    { word: 'incoherent', word_class: 'AJ' },
    { word: 'incoherently', word_class: 'AV' }
  ],
  drugging: [
    { word: 'drug', word_class: 'N' },
    { word: 'drug', word_class: 'V' },
    { word: 'drugged', word_class: 'AJ' },
    { word: 'drugging', word_class: 'N' }
  ],
  aggravate: [
    { word: 'aggravate', word_class: 'V' },
    { word: 'aggravated', word_class: 'AJ' },
    { word: 'aggravation', word_class: 'N' },
    { word: 'aggravating', word_class: 'AJ' }
  ],
  delegable: [
    { word: 'delegate', word_class: 'N' },
    { word: 'delegate', word_class: 'V' },
    { word: 'delegable', word_class: 'AJ' },
    { word: 'delegating', word_class: 'N' },
    { word: 'delegation', word_class: 'N' }
  ],
  dated: [{ word: 'date', word_class: 'N' }, { word: 'date', word_class: 'V' }, { word: 'dated', word_class: 'AJ' }],
  instate: [
    { word: 'instate', word_class: 'N' },
    { word: 'instate', word_class: 'V' },
    { word: 'instatment', word_class: 'N' },
    { word: 'instatement', word_class: 'N' }
  ],
  body: [{ word: 'body', word_class: 'N' }, { word: 'body', word_class: 'V' }, { word: 'bodied', word_class: 'AJ' }],
  neurotically: [
    { word: 'neurotic', word_class: 'N' },
    { word: 'neurotic', word_class: 'AJ' },
    { word: 'neurotically', word_class: 'AV' }
  ],
  rout: [
    { word: 'rout', word_class: 'N' },
    { word: 'rout', word_class: 'V' },
    { word: 'route', word_class: 'V' },
    { word: 'route', word_class: 'N' },
    { word: 'routed', word_class: 'AJ' }
  ],
  listen: [
    { word: 'listen', word_class: 'V' },
    { word: 'listener', word_class: 'N' },
    { word: 'listening', word_class: 'N' },
    { word: 'listening', word_class: 'AJ' }
  ],
  conversant: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  smoking: [
    { word: 'smoke', word_class: 'N' },
    { word: 'smoke', word_class: 'V' },
    { word: 'smoker', word_class: 'N' },
    { word: 'smoky', word_class: 'AJ' },
    { word: 'smoked', word_class: 'AJ' },
    { word: 'smoking', word_class: 'N' },
    { word: 'smoking', word_class: 'AJ' }
  ],
  crisscross: [
    { word: 'crisscross', word_class: 'N' },
    { word: 'crisscross', word_class: 'V' },
    { word: 'crisscross', word_class: 'AJ' },
    { word: 'crisscross', word_class: 'AV' },
    { word: 'crisscrossed', word_class: 'AJ' }
  ],
  hollands: [
    { word: 'holland', word_class: 'N' },
    { word: 'hollands', word_class: 'N' },
    { word: 'hollander', word_class: 'N' }
  ],
  breech: [
    { word: 'breech', word_class: 'N' },
    { word: 'breeches', word_class: 'N' },
    { word: 'breeched', word_class: 'AJ' }
  ],
  entrance: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  solvency: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  depiction: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  automatism: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  vestmented: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  slaying: [
    { word: 'slay', word_class: 'V' },
    { word: 'slain', word_class: 'N' },
    { word: 'slain', word_class: 'AJ' },
    { word: 'slayer', word_class: 'N' },
    { word: 'slaying', word_class: 'N' }
  ],
  decarbonization: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  justification: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  relinquish: [
    { word: 'relinquish', word_class: 'V' },
    { word: 'relinquished', word_class: 'AJ' },
    { word: 'relinquishing', word_class: 'N' },
    { word: 'relinquishment', word_class: 'N' }
  ],
  any: [{ word: 'ani', word_class: 'N' }, { word: 'any', word_class: 'AJ' }, { word: 'any', word_class: 'AV' }],
  obscure: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  preventive: [
    { word: 'prevent', word_class: 'N' },
    { word: 'prevent', word_class: 'V' },
    { word: 'prevention', word_class: 'N' },
    { word: 'preventive', word_class: 'N' },
    { word: 'preventive', word_class: 'AJ' },
    { word: 'preventable', word_class: 'AJ' },
    { word: 'preventative', word_class: 'N' },
    { word: 'preventative', word_class: 'AJ' }
  ],
  cog: [{ word: 'cog', word_class: 'N' }, { word: 'cog', word_class: 'V' }, { word: 'cogged', word_class: 'AJ' }],
  jump: [
    { word: 'jump', word_class: 'N' },
    { word: 'jump', word_class: 'V' },
    { word: 'jumper', word_class: 'N' },
    { word: 'jumpers', word_class: 'N' },
    { word: 'jumping', word_class: 'N' },
    { word: 'jumping', word_class: 'AJ' }
  ],
  adapt: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  humped: [{ word: 'hump', word_class: 'N' }, { word: 'hump', word_class: 'V' }, { word: 'humped', word_class: 'AJ' }],
  laudatory: [
    { word: 'laud', word_class: 'V' },
    { word: 'lauder', word_class: 'N' },
    { word: 'laudator', word_class: 'N' },
    { word: 'laudation', word_class: 'N' },
    { word: 'laudatory', word_class: 'AJ' }
  ],
  drape: [
    { word: 'drape', word_class: 'N' },
    { word: 'drape', word_class: 'V' },
    { word: 'draper', word_class: 'N' },
    { word: 'draped', word_class: 'AJ' },
    { word: 'drapery', word_class: 'N' },
    { word: 'drapery', word_class: 'AJ' }
  ],
  accessible: [
    { word: 'access', word_class: 'N' },
    { word: 'access', word_class: 'V' },
    { word: 'accession', word_class: 'N' },
    { word: 'accession', word_class: 'V' },
    { word: 'accessible', word_class: 'AJ' },
    { word: 'accessibility', word_class: 'N' }
  ],
  imperialism: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  prearrangement: [
    { word: 'prearrange', word_class: 'V' },
    { word: 'prearranged', word_class: 'AJ' },
    { word: 'prearrangement', word_class: 'N' }
  ],
  mourn: [
    { word: 'mourn', word_class: 'V' },
    { word: 'mourner', word_class: 'N' },
    { word: 'mourning', word_class: 'N' },
    { word: 'mournful', word_class: 'AJ' },
    { word: 'mourning', word_class: 'AJ' },
    { word: 'mournfulness', word_class: 'N' }
  ],
  needed: [
    { word: 'need', word_class: 'N' },
    { word: 'need', word_class: 'V' },
    { word: 'needs', word_class: 'AV' },
    { word: 'needed', word_class: 'AJ' },
    { word: 'needful', word_class: 'AJ' }
  ],
  curtain: [
    { word: 'curtain', word_class: 'N' },
    { word: 'curtain', word_class: 'V' },
    { word: 'curtained', word_class: 'AJ' }
  ],
  skillfulness: [
    { word: 'skill', word_class: 'N' },
    { word: 'skilful', word_class: 'AJ' },
    { word: 'skilled', word_class: 'AJ' },
    { word: 'skillful', word_class: 'AJ' },
    { word: 'skillfulness', word_class: 'N' }
  ],
  picturesque: [
    { word: 'picturesque', word_class: 'AJ' },
    { word: 'picturesquely', word_class: 'AV' },
    { word: 'picturesqueness', word_class: 'N' }
  ],
  maim: [
    { word: 'maim', word_class: 'V' },
    { word: 'maimed', word_class: 'N' },
    { word: 'maimed', word_class: 'AJ' },
    { word: 'maiming', word_class: 'N' }
  ],
  shocker: [
    { word: 'shock', word_class: 'N' },
    { word: 'shock', word_class: 'V' },
    { word: 'shocker', word_class: 'N' },
    { word: 'shocked', word_class: 'AJ' },
    { word: 'shocking', word_class: 'AJ' }
  ],
  demand: [
    { word: 'demand', word_class: 'N' },
    { word: 'demand', word_class: 'V' },
    { word: 'demander', word_class: 'N' },
    { word: 'demanding', word_class: 'AJ' }
  ],
  concluded: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  bursting: [
    { word: 'burst', word_class: 'N' },
    { word: 'burst', word_class: 'V' },
    { word: 'burst', word_class: 'AJ' },
    { word: 'burster', word_class: 'N' },
    { word: 'bursting', word_class: 'AJ' }
  ],
  lighting: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  guide: [
    { word: 'guide', word_class: 'N' },
    { word: 'guide', word_class: 'V' },
    { word: 'guided', word_class: 'AJ' },
    { word: 'guiding', word_class: 'N' },
    { word: 'guidance', word_class: 'N' },
    { word: 'guiding', word_class: 'AJ' }
  ],
  pantomimer: [
    { word: 'pantomime', word_class: 'N' },
    { word: 'pantomime', word_class: 'V' },
    { word: 'pantomimer', word_class: 'N' }
  ],
  unrighteousness: [
    { word: 'unrighteous', word_class: 'AJ' },
    { word: 'unrighteously', word_class: 'AV' },
    { word: 'unrighteousness', word_class: 'N' }
  ],
  tufted: [{ word: 'tuft', word_class: 'N' }, { word: 'tuft', word_class: 'V' }, { word: 'tufted', word_class: 'AJ' }],
  argent: [
    { word: 'argent', word_class: 'N' },
    { word: 'argent', word_class: 'AJ' },
    { word: 'argentic', word_class: 'AJ' },
    { word: 'argentous', word_class: 'AJ' }
  ],
  fractionisation: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  anchor: [
    { word: 'anchor', word_class: 'N' },
    { word: 'anchor', word_class: 'V' },
    { word: 'anchorage', word_class: 'N' },
    { word: 'anchorite', word_class: 'N' },
    { word: 'anchorman', word_class: 'N' },
    { word: 'anchoritic', word_class: 'AJ' }
  ],
  unceremonial: [
    { word: 'unceremonial', word_class: 'AJ' },
    { word: 'unceremonious', word_class: 'AJ' },
    { word: 'unceremoniously', word_class: 'AV' },
    { word: 'unceremoniousness', word_class: 'N' }
  ],
  predictably: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  apostrophization: [
    { word: 'apostrophe', word_class: 'N' },
    { word: 'apostrophic', word_class: 'AJ' },
    { word: 'apostrophize', word_class: 'V' },
    { word: 'apostrophization', word_class: 'N' }
  ],
  inscribe: [
    { word: 'inscribe', word_class: 'N' },
    { word: 'inscribe', word_class: 'V' },
    { word: 'inscribed', word_class: 'AJ' },
    { word: 'inscription', word_class: 'N' }
  ],
  sequestrate: [
    { word: 'sequester', word_class: 'V' },
    { word: 'sequestrate', word_class: 'V' },
    { word: 'sequestered', word_class: 'AJ' },
    { word: 'sequestration', word_class: 'N' }
  ],
  northeaster: [
    { word: 'northeast', word_class: 'N' },
    { word: 'northeast', word_class: 'AJ' },
    { word: 'northeast', word_class: 'AV' },
    { word: 'northeaster', word_class: 'N' }
  ],
  margin: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  enhanced: [
    { word: 'enhance', word_class: 'V' },
    { word: 'enhanced', word_class: 'AJ' },
    { word: 'enhancive', word_class: 'AJ' },
    { word: 'enhancement', word_class: 'N' }
  ],
  professor: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  formulated: [
    { word: 'formulate', word_class: 'V' },
    { word: 'formulize', word_class: 'V' },
    { word: 'formulated', word_class: 'AJ' },
    { word: 'formulation', word_class: 'N' },
    { word: 'formulization', word_class: 'N' }
  ],
  actually: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  careenage: [
    { word: 'careen', word_class: 'N' },
    { word: 'careen', word_class: 'V' },
    { word: 'careenage', word_class: 'N' }
  ],
  incontrovertibleness: [
    { word: 'incontrovertible', word_class: 'AJ' },
    { word: 'incontrovertibly', word_class: 'AV' },
    { word: 'incontrovertibility', word_class: 'N' },
    { word: 'incontrovertibleness', word_class: 'N' }
  ],
  coquetry: [
    { word: 'coquet', word_class: 'N' },
    { word: 'coquet', word_class: 'V' },
    { word: 'coquetry', word_class: 'N' }
  ],
  wearied: [
    { word: 'weary', word_class: 'V' },
    { word: 'weary', word_class: 'AJ' },
    { word: 'wearied', word_class: 'AJ' },
    { word: 'weariness', word_class: 'N' },
    { word: 'wearying', word_class: 'AJ' }
  ],
  stigmatic: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  percept: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  tyrannize: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  cleansing: [
    { word: 'cleanse', word_class: 'V' },
    { word: 'cleanser', word_class: 'N' },
    { word: 'cleansed', word_class: 'AJ' },
    { word: 'cleansing', word_class: 'N' },
    { word: 'cleansing', word_class: 'AJ' }
  ],
  creak: [
    { word: 'creak', word_class: 'N' },
    { word: 'creak', word_class: 'V' },
    { word: 'creaking', word_class: 'N' },
    { word: 'creaking', word_class: 'AJ' }
  ],
  finiteness: [
    { word: 'finite', word_class: 'N' },
    { word: 'finite', word_class: 'AJ' },
    { word: 'finitely', word_class: 'AV' },
    { word: 'finiteness', word_class: 'N' }
  ],
  soar: [
    { word: 'soar', word_class: 'N' },
    { word: 'soar', word_class: 'V' },
    { word: 'soaring', word_class: 'N' },
    { word: 'soaring', word_class: 'AJ' }
  ],
  whitened: [
    { word: 'whiten', word_class: 'V' },
    { word: 'whitener', word_class: 'N' },
    { word: 'whitened', word_class: 'AJ' },
    { word: 'whitening', word_class: 'N' }
  ],
  trajection: [
    { word: 'traject', word_class: 'V' },
    { word: 'trajection', word_class: 'N' },
    { word: 'trajectory', word_class: 'N' },
    { word: 'trajectory', word_class: 'AJ' }
  ],
  invented: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  entomology: [
    { word: 'entomology', word_class: 'N' },
    { word: 'entomologic', word_class: 'AJ' },
    { word: 'entomological', word_class: 'AJ' }
  ],
  plaintively: [
    { word: 'plaintive', word_class: 'AJ' },
    { word: 'plaintively', word_class: 'AV' },
    { word: 'plaintiveness', word_class: 'N' }
  ],
  siding: [
    { word: 'side', word_class: 'N' },
    { word: 'side', word_class: 'V' },
    { word: 'side', word_class: 'AJ' },
    { word: 'siding', word_class: 'N' }
  ],
  communalize: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  mister: [{ word: 'mist', word_class: 'N' }, { word: 'mist', word_class: 'V' }, { word: 'mister', word_class: 'N' }],
  commercialize: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  delineative: [
    { word: 'delineate', word_class: 'V' },
    { word: 'delineate', word_class: 'AJ' },
    { word: 'delineated', word_class: 'AJ' },
    { word: 'delineation', word_class: 'N' },
    { word: 'delineative', word_class: 'AJ' }
  ],
  oppression: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  ironing: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  condensed: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  prelude: [
    { word: 'prelude', word_class: 'N' },
    { word: 'prelude', word_class: 'V' },
    { word: 'prelusion', word_class: 'N' }
  ],
  rewriter: [
    { word: 'rewrite', word_class: 'N' },
    { word: 'rewrite', word_class: 'V' },
    { word: 'rewriter', word_class: 'N' },
    { word: 'rewriting', word_class: 'N' }
  ],
  jejunitis: [
    { word: 'jejune', word_class: 'AJ' },
    { word: 'jejunity', word_class: 'N' },
    { word: 'jejunely', word_class: 'AV' },
    { word: 'jejunitis', word_class: 'N' },
    { word: 'jejuneness', word_class: 'N' }
  ],
  iciness: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  'co-operate': [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  catering: [
    { word: 'cater', word_class: 'V' },
    { word: 'caterer', word_class: 'N' },
    { word: 'catering', word_class: 'N' }
  ],
  punctual: [
    { word: 'punctual', word_class: 'AJ' },
    { word: 'punctuality', word_class: 'N' },
    { word: 'punctually', word_class: 'AV' }
  ],
  superannuation: [
    { word: 'superannuate', word_class: 'V' },
    { word: 'superannuated', word_class: 'AJ' },
    { word: 'superannuation', word_class: 'N' }
  ],
  complaining: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  harmonize: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  shared: [
    { word: 'share', word_class: 'N' },
    { word: 'share', word_class: 'V' },
    { word: 'sharer', word_class: 'N' },
    { word: 'shared', word_class: 'AJ' },
    { word: 'sharing', word_class: 'N' },
    { word: 'sharing', word_class: 'AJ' }
  ],
  indexing: [
    { word: 'index', word_class: 'N' },
    { word: 'index', word_class: 'V' },
    { word: 'indexer', word_class: 'N' },
    { word: 'indexing', word_class: 'N' },
    { word: 'indexation', word_class: 'N' },
    { word: 'indexical', word_class: 'AJ' }
  ],
  ceremoniousness: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  turbid: [
    { word: 'turbid', word_class: 'AJ' },
    { word: 'turbidity', word_class: 'N' },
    { word: 'turbidness', word_class: 'N' }
  ],
  hope: [
    { word: 'hope', word_class: 'N' },
    { word: 'hope', word_class: 'V' },
    { word: 'hopeful', word_class: 'N' },
    { word: 'hopeful', word_class: 'AJ' },
    { word: 'hopefulness', word_class: 'N' }
  ],
  tasty: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  cantering: [
    { word: 'canter', word_class: 'N' },
    { word: 'canter', word_class: 'V' },
    { word: 'cantering', word_class: 'AJ' }
  ],
  stalling: [
    { word: 'stall', word_class: 'N' },
    { word: 'stall', word_class: 'V' },
    { word: 'stalls', word_class: 'N' },
    { word: 'stalling', word_class: 'N' },
    { word: 'stallion', word_class: 'N' }
  ],
  commonsense: [
    { word: 'commonsense', word_class: 'AJ' },
    { word: 'commonsensible', word_class: 'AJ' },
    { word: 'commonsensical', word_class: 'AJ' }
  ],
  volcanisation: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  sustainable: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  conjecture: [
    { word: 'conjecture', word_class: 'N' },
    { word: 'conjecture', word_class: 'V' },
    { word: 'conjectural', word_class: 'AJ' }
  ],
  pinch: [
    { word: 'pinch', word_class: 'N' },
    { word: 'pinch', word_class: 'V' },
    { word: 'pinche', word_class: 'N' },
    { word: 'pinched', word_class: 'AJ' }
  ],
  relinquishing: [
    { word: 'relinquish', word_class: 'V' },
    { word: 'relinquished', word_class: 'AJ' },
    { word: 'relinquishing', word_class: 'N' },
    { word: 'relinquishment', word_class: 'N' }
  ],
  pursuit: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  outrageously: [
    { word: 'outrage', word_class: 'N' },
    { word: 'outrage', word_class: 'V' },
    { word: 'outraged', word_class: 'AJ' },
    { word: 'outrageous', word_class: 'AJ' },
    { word: 'outrageously', word_class: 'AV' },
    { word: 'outrageousness', word_class: 'N' }
  ],
  platinization: [
    { word: 'platinise', word_class: 'V' },
    { word: 'platinize', word_class: 'V' },
    { word: 'platinisation', word_class: 'N' },
    { word: 'platinization', word_class: 'N' }
  ],
  worrying: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  exemplify: [
    { word: 'exemplify', word_class: 'V' },
    { word: 'exemplifying', word_class: 'AJ' },
    { word: 'exemplification', word_class: 'N' }
  ],
  shoplifting: [
    { word: 'shoplift', word_class: 'V' },
    { word: 'shoplifter', word_class: 'N' },
    { word: 'shoplifting', word_class: 'N' }
  ],
  pertinacity: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  meagreness: [
    { word: 'meagre', word_class: 'AJ' },
    { word: 'meagrely', word_class: 'AV' },
    { word: 'meagreness', word_class: 'N' }
  ],
  cracking: [
    { word: 'crack', word_class: 'N' },
    { word: 'crack', word_class: 'V' },
    { word: 'crack', word_class: 'AJ' },
    { word: 'cracker', word_class: 'N' },
    { word: 'cracked', word_class: 'AJ' },
    { word: 'cracking', word_class: 'N' },
    { word: 'crackers', word_class: 'AJ' },
    { word: 'cracking', word_class: 'AJ' }
  ],
  humourously: [
    { word: 'humour', word_class: 'N' },
    { word: 'humour', word_class: 'V' },
    { word: 'humourist', word_class: 'N' },
    { word: 'humourous', word_class: 'AJ' },
    { word: 'humourously', word_class: 'AV' }
  ],
  journeying: [
    { word: 'journey', word_class: 'N' },
    { word: 'journey', word_class: 'V' },
    { word: 'journeyer', word_class: 'N' },
    { word: 'journeying', word_class: 'N' },
    { word: 'journeyman', word_class: 'N' }
  ],
  partially: [
    { word: 'partial', word_class: 'AJ' },
    { word: 'partiality', word_class: 'N' },
    { word: 'partially', word_class: 'AV' },
    { word: 'partialness', word_class: 'N' }
  ],
  disbursement: [
    { word: 'disburse', word_class: 'V' },
    { word: 'disbursal', word_class: 'N' },
    { word: 'disburser', word_class: 'N' },
    { word: 'disbursement', word_class: 'N' }
  ],
  debatable: [
    { word: 'debate', word_class: 'N' },
    { word: 'debate', word_class: 'V' },
    { word: 'debater', word_class: 'N' },
    { word: 'debatable', word_class: 'AJ' }
  ],
  bundle: [
    { word: 'bundle', word_class: 'N' },
    { word: 'bundle', word_class: 'V' },
    { word: 'bundling', word_class: 'N' }
  ],
  correctitude: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  purify: [
    { word: 'purify', word_class: 'V' },
    { word: 'purifier', word_class: 'N' },
    { word: 'purified', word_class: 'AJ' },
    { word: 'purifying', word_class: 'AJ' },
    { word: 'purification', word_class: 'N' }
  ],
  gorged: [
    { word: 'gorge', word_class: 'N' },
    { word: 'gorge', word_class: 'V' },
    { word: 'gorged', word_class: 'AJ' }
  ],
  pressurize: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  endemic: [
    { word: 'endemic', word_class: 'N' },
    { word: 'endemic', word_class: 'AJ' },
    { word: 'endemism', word_class: 'N' }
  ],
  computerise: [
    { word: 'computerise', word_class: 'V' },
    { word: 'computerize', word_class: 'V' },
    { word: 'computerized', word_class: 'AJ' },
    { word: 'computerisation', word_class: 'N' },
    { word: 'computerization', word_class: 'N' }
  ],
  disheartening: [
    { word: 'dishearten', word_class: 'V' },
    { word: 'disheartened', word_class: 'AJ' },
    { word: 'disheartening', word_class: 'AJ' },
    { word: 'disheartenment', word_class: 'N' }
  ],
  turtleneck: [
    { word: 'turtleneck', word_class: 'N' },
    { word: 'turtleneck', word_class: 'AJ' },
    { word: 'turtlenecked', word_class: 'AJ' }
  ],
  melodically: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  flirting: [
    { word: 'flirt', word_class: 'N' },
    { word: 'flirt', word_class: 'V' },
    { word: 'flirting', word_class: 'N' },
    { word: 'flirtation', word_class: 'N' },
    { word: 'flirtatious', word_class: 'AJ' },
    { word: 'flirtatiously', word_class: 'AV' }
  ],
  garbed: [{ word: 'garb', word_class: 'N' }, { word: 'garb', word_class: 'V' }, { word: 'garbed', word_class: 'AJ' }],
  shirker: [
    { word: 'shirk', word_class: 'V' },
    { word: 'shirker', word_class: 'N' },
    { word: 'shirking', word_class: 'N' }
  ],
  housings: [
    { word: 'house', word_class: 'N' },
    { word: 'house', word_class: 'V' },
    { word: 'housing', word_class: 'N' },
    { word: 'houseful', word_class: 'N' },
    { word: 'houseman', word_class: 'N' },
    { word: 'housings', word_class: 'N' },
    { word: 'houseful', word_class: 'AJ' }
  ],
  countersignature: [
    { word: 'countersign', word_class: 'N' },
    { word: 'countersign', word_class: 'V' },
    { word: 'countersignature', word_class: 'N' }
  ],
  angered: [
    { word: 'anger', word_class: 'N' },
    { word: 'anger', word_class: 'V' },
    { word: 'angry', word_class: 'AJ' },
    { word: 'angered', word_class: 'AJ' },
    { word: 'angriness', word_class: 'N' }
  ],
  saponified: [
    { word: 'saponify', word_class: 'V' },
    { word: 'saponified', word_class: 'AJ' },
    { word: 'saponification', word_class: 'N' }
  ],
  climatical: [
    { word: 'climate', word_class: 'N' },
    { word: 'climatic', word_class: 'AJ' },
    { word: 'climatical', word_class: 'AJ' },
    { word: 'climatically', word_class: 'AV' }
  ],
  fortuitously: [
    { word: 'fortuitous', word_class: 'AJ' },
    { word: 'fortuitously', word_class: 'AV' },
    { word: 'fortuitousness', word_class: 'N' }
  ],
  brutalize: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  pointed: [
    { word: 'point', word_class: 'N' },
    { word: 'point', word_class: 'V' },
    { word: 'pointer', word_class: 'N' },
    { word: 'pointed', word_class: 'AJ' },
    { word: 'pointing', word_class: 'N' },
    { word: 'pointing', word_class: 'AJ' }
  ],
  graphic: [
    { word: 'graphic', word_class: 'N' },
    { word: 'graphic', word_class: 'AJ' },
    { word: 'graphics', word_class: 'N' },
    { word: 'graphical', word_class: 'AJ' },
    { word: 'graphically', word_class: 'AV' }
  ],
  facetiously: [
    { word: 'facetious', word_class: 'AJ' },
    { word: 'facetiously', word_class: 'AV' },
    { word: 'facetiousness', word_class: 'N' }
  ],
  ordering: [
    { word: 'order', word_class: 'N' },
    { word: 'order', word_class: 'V' },
    { word: 'ordered', word_class: 'AJ' },
    { word: 'ordering', word_class: 'N' }
  ],
  precipitating: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  summational: [
    { word: 'sum', word_class: 'N' },
    { word: 'sum', word_class: 'V' },
    { word: 'summate', word_class: 'V' },
    { word: 'summation', word_class: 'N' },
    { word: 'summational', word_class: 'AJ' }
  ],
  iodinating: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  landman: [
    { word: 'land', word_class: 'N' },
    { word: 'land', word_class: 'V' },
    { word: 'land', word_class: 'AJ' },
    { word: 'landed', word_class: 'AJ' },
    { word: 'landing', word_class: 'N' },
    { word: 'landman', word_class: 'N' }
  ],
  hygienically: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  collective: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  climbing: [
    { word: 'climb', word_class: 'N' },
    { word: 'climb', word_class: 'V' },
    { word: 'climber', word_class: 'N' },
    { word: 'climbing', word_class: 'N' },
    { word: 'climbing', word_class: 'AJ' }
  ],
  assassinated: [
    { word: 'assassin', word_class: 'N' },
    { word: 'assassinate', word_class: 'V' },
    { word: 'assassinated', word_class: 'AJ' },
    { word: 'assassination', word_class: 'N' }
  ],
  bitters: [
    { word: 'bitter', word_class: 'N' },
    { word: 'bitter', word_class: 'V' },
    { word: 'bitter', word_class: 'AJ' },
    { word: 'bitter', word_class: 'AV' },
    { word: 'bitters', word_class: 'N' },
    { word: 'bitterness', word_class: 'N' }
  ],
  spangle: [
    { word: 'spangle', word_class: 'N' },
    { word: 'spangle', word_class: 'V' },
    { word: 'spangled', word_class: 'AJ' }
  ],
  cuddling: [
    { word: 'cuddle', word_class: 'N' },
    { word: 'cuddle', word_class: 'V' },
    { word: 'cuddly', word_class: 'AJ' },
    { word: 'cuddling', word_class: 'N' }
  ],
  unscrupulousness: [
    { word: 'unscrupulous', word_class: 'AJ' },
    { word: 'unscrupulously', word_class: 'AV' },
    { word: 'unscrupulousness', word_class: 'N' }
  ],
  deportee: [
    { word: 'deport', word_class: 'V' },
    { word: 'deportee', word_class: 'N' },
    { word: 'deportment', word_class: 'N' },
    { word: 'deportation', word_class: 'N' }
  ],
  reorientate: [
    { word: 'reorient', word_class: 'V' },
    { word: 'reorientate', word_class: 'V' },
    { word: 'reorientation', word_class: 'N' }
  ],
  brightness: [
    { word: 'bright', word_class: 'AJ' },
    { word: 'bright', word_class: 'AV' },
    { word: 'brightness', word_class: 'N' }
  ],
  infolding: [
    { word: 'infold', word_class: 'V' },
    { word: 'infolding', word_class: 'N' },
    { word: 'infoldment', word_class: 'N' }
  ],
  'left-handed': [
    { word: 'left-hand', word_class: 'AJ' },
    { word: 'left-hander', word_class: 'N' },
    { word: 'left-handed', word_class: 'AJ' }
  ],
  deferentially: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  departmentalise: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  mastic: [
    { word: 'mastic', word_class: 'N' },
    { word: 'masticate', word_class: 'V' },
    { word: 'mastication', word_class: 'N' }
  ],
  note: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  wise: [
    { word: 'wise', word_class: 'N' },
    { word: 'wise', word_class: 'AJ' },
    { word: 'wisdom', word_class: 'N' },
    { word: 'wisely', word_class: 'AV' },
    { word: 'wiseness', word_class: 'N' }
  ],
  adulterating: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  unarguably: [
    { word: 'unarguable', word_class: 'AJ' },
    { word: 'unarguably', word_class: 'AV' },
    { word: 'unargumentative', word_class: 'AJ' }
  ],
  irruptive: [
    { word: 'irrupt', word_class: 'V' },
    { word: 'irruption', word_class: 'N' },
    { word: 'irruptive', word_class: 'AJ' }
  ],
  procurer: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  synonymise: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  engross: [
    { word: 'engross', word_class: 'V' },
    { word: 'engrossed', word_class: 'AJ' },
    { word: 'engrossing', word_class: 'AJ' },
    { word: 'engrossment', word_class: 'N' }
  ],
  sympathize: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  randomize: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  electorate: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  called: [
    { word: 'call', word_class: 'N' },
    { word: 'call', word_class: 'V' },
    { word: 'caller', word_class: 'N' },
    { word: 'called', word_class: 'AJ' },
    { word: 'caller', word_class: 'AJ' },
    { word: 'calling', word_class: 'N' }
  ],
  trebly: [
    { word: 'treble', word_class: 'N' },
    { word: 'treble', word_class: 'V' },
    { word: 'treble', word_class: 'AJ' },
    { word: 'trebly', word_class: 'AV' }
  ],
  springing: [
    { word: 'spring', word_class: 'N' },
    { word: 'spring', word_class: 'V' },
    { word: 'spring', word_class: 'AJ' },
    { word: 'sprung', word_class: 'AJ' },
    { word: 'springer', word_class: 'N' },
    { word: 'springing', word_class: 'AJ' }
  ],
  converge: [
    { word: 'converge', word_class: 'V' },
    { word: 'converging', word_class: 'N' },
    { word: 'convergence', word_class: 'N' },
    { word: 'convergency', word_class: 'N' },
    { word: 'convergent', word_class: 'AJ' },
    { word: 'converging', word_class: 'AJ' }
  ],
  constitutional: [
    { word: 'constitute', word_class: 'V' },
    { word: 'consitution', word_class: 'N' },
    { word: 'constitution', word_class: 'N' },
    { word: 'constitutive', word_class: 'AJ' },
    { word: 'constitutional', word_class: 'N' },
    { word: 'constitutional', word_class: 'AJ' }
  ],
  seventies: [
    { word: 'seventy', word_class: 'N' },
    { word: 'seventy', word_class: 'AJ' },
    { word: 'seventies', word_class: 'N' }
  ],
  acquaint: [
    { word: 'acquaint', word_class: 'V' },
    { word: 'acquainted', word_class: 'AJ' },
    { word: 'acquaintance', word_class: 'N' }
  ],
  bravery: [
    { word: 'brave', word_class: 'V' },
    { word: 'brave', word_class: 'N' },
    { word: 'brave', word_class: 'AJ' },
    { word: 'bravery', word_class: 'N' },
    { word: 'braving', word_class: 'N' },
    { word: 'bravely', word_class: 'AV' },
    { word: 'bravery', word_class: 'AJ' }
  ],
  fumed: [
    { word: 'fume', word_class: 'N' },
    { word: 'fume', word_class: 'V' },
    { word: 'fumes', word_class: 'N' },
    { word: 'fumed', word_class: 'AJ' }
  ],
  sod: [{ word: 'sod', word_class: 'N' }, { word: 'sod', word_class: 'V' }, { word: 'sodding', word_class: 'AJ' }],
  infiltration: [
    { word: 'infiltrate', word_class: 'V' },
    { word: 'infiltrator', word_class: 'N' },
    { word: 'infiltration', word_class: 'N' }
  ],
  linearity: [
    { word: 'linear', word_class: 'AJ' },
    { word: 'linearity', word_class: 'N' },
    { word: 'linearize', word_class: 'V' }
  ],
  donated: [
    { word: 'donate', word_class: 'V' },
    { word: 'donated', word_class: 'AJ' },
    { word: 'donation', word_class: 'N' }
  ],
  genial: [
    { word: 'genial', word_class: 'AJ' },
    { word: 'geniality', word_class: 'N' },
    { word: 'genially', word_class: 'AV' }
  ],
  circumference: [
    { word: 'circumference', word_class: 'N' },
    { word: 'circumferential', word_class: 'N' },
    { word: 'circumferential', word_class: 'AJ' }
  ],
  numbing: [
    { word: 'numb', word_class: 'V' },
    { word: 'numb', word_class: 'AJ' },
    { word: 'numbing', word_class: 'AJ' },
    { word: 'numbness', word_class: 'N' }
  ],
  piddling: [
    { word: 'piddle', word_class: 'N' },
    { word: 'piddle', word_class: 'V' },
    { word: 'piddling', word_class: 'AJ' }
  ],
  pock: [{ word: 'pock', word_class: 'N' }, { word: 'pock', word_class: 'V' }, { word: 'pocked', word_class: 'AJ' }],
  blackmail: [
    { word: 'blackmail', word_class: 'N' },
    { word: 'blackmail', word_class: 'V' },
    { word: 'blackmailer', word_class: 'N' }
  ],
  ethylation: [
    { word: 'ethyl', word_class: 'N' },
    { word: 'ethylate', word_class: 'V' },
    { word: 'ethylation', word_class: 'N' }
  ],
  expensively: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  hammered: [
    { word: 'hammer', word_class: 'N' },
    { word: 'hammer', word_class: 'V' },
    { word: 'hammered', word_class: 'AJ' },
    { word: 'hammering', word_class: 'N' }
  ],
  balkans: [
    { word: 'balkans', word_class: 'N' },
    { word: 'balkanise', word_class: 'V' },
    { word: 'balkanize', word_class: 'V' },
    { word: 'balkanisation', word_class: 'N' },
    { word: 'balkanization', word_class: 'N' }
  ],
  frank: [
    { word: 'frank', word_class: 'N' },
    { word: 'frank', word_class: 'V' },
    { word: 'frank', word_class: 'AJ' },
    { word: 'frankness', word_class: 'N' }
  ],
  standing: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  nod: [{ word: 'nod', word_class: 'N' }, { word: 'nod', word_class: 'V' }, { word: 'nodding', word_class: 'AJ' }],
  flapping: [
    { word: 'flap', word_class: 'N' },
    { word: 'flap', word_class: 'V' },
    { word: 'flaps', word_class: 'N' },
    { word: 'flapping', word_class: 'N' }
  ],
  invocation: [
    { word: 'invoke', word_class: 'V' },
    { word: 'invocate', word_class: 'V' },
    { word: 'invocation', word_class: 'N' }
  ],
  fusion: [
    { word: 'fuse', word_class: 'N' },
    { word: 'fuse', word_class: 'V' },
    { word: 'fusee', word_class: 'N' },
    { word: 'fused', word_class: 'AJ' },
    { word: 'fusion', word_class: 'N' }
  ],
  centering: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  peregrinate: [
    { word: 'peregrine', word_class: 'N' },
    { word: 'peregrine', word_class: 'AJ' },
    { word: 'peregrinate', word_class: 'V' },
    { word: 'peregrination', word_class: 'N' }
  ],
  accomplished: [
    { word: 'accomplish', word_class: 'V' },
    { word: 'accomplished', word_class: 'AJ' },
    { word: 'accomplishment', word_class: 'N' },
    { word: 'accomplishable', word_class: 'AJ' }
  ],
  harmonious: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  embark: [
    { word: 'embark', word_class: 'V' },
    { word: 'embarkment', word_class: 'N' },
    { word: 'embarkation', word_class: 'N' }
  ],
  inconsequently: [
    { word: 'inconsequence', word_class: 'N' },
    { word: 'inconsequent', word_class: 'AJ' },
    { word: 'inconsequently', word_class: 'AV' }
  ],
  exaggerated: [
    { word: 'exaggerate', word_class: 'V' },
    { word: 'exaggerated', word_class: 'AJ' },
    { word: 'exaggeration', word_class: 'N' }
  ],
  nourishment: [
    { word: 'nourish', word_class: 'V' },
    { word: 'nourished', word_class: 'AJ' },
    { word: 'nourishing', word_class: 'N' },
    { word: 'nourishing', word_class: 'AJ' },
    { word: 'nourishment', word_class: 'N' }
  ],
  demonic: [
    { word: 'demon', word_class: 'N' },
    { word: 'demonic', word_class: 'AJ' },
    { word: 'demonism', word_class: 'N' },
    { word: 'demonize', word_class: 'V' },
    { word: 'demonization', word_class: 'N' }
  ],
  supplantation: [
    { word: 'supplant', word_class: 'V' },
    { word: 'supplanter', word_class: 'N' },
    { word: 'supplanting', word_class: 'N' },
    { word: 'supplantation', word_class: 'N' }
  ],
  screamer: [
    { word: 'scream', word_class: 'N' },
    { word: 'scream', word_class: 'V' },
    { word: 'screamer', word_class: 'N' },
    { word: 'screaming', word_class: 'N' },
    { word: 'screaming', word_class: 'AJ' }
  ],
  forejudgment: [
    { word: 'forjudge', word_class: 'V' },
    { word: 'forejudge', word_class: 'V' },
    { word: 'forjudgment', word_class: 'N' },
    { word: 'forejudgment', word_class: 'N' },
    { word: 'forejudgement', word_class: 'N' }
  ],
  judaize: [
    { word: 'judaise', word_class: 'V' },
    { word: 'judaize', word_class: 'V' },
    { word: 'judaisation', word_class: 'N' },
    { word: 'judaization', word_class: 'N' }
  ],
  decussation: [
    { word: 'decussate', word_class: 'V' },
    { word: 'decussate', word_class: 'AJ' },
    { word: 'decussation', word_class: 'N' }
  ],
  thieve: [
    { word: 'thieve', word_class: 'V' },
    { word: 'thievery', word_class: 'N' },
    { word: 'thieving', word_class: 'N' },
    { word: 'thievery', word_class: 'AJ' },
    { word: 'thieving', word_class: 'AJ' }
  ],
  volatility: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  craving: [
    { word: 'crave', word_class: 'V' },
    { word: 'craved', word_class: 'AJ' },
    { word: 'craving', word_class: 'N' }
  ],
  actinomycetal: [
    { word: 'actinomycete', word_class: 'N' },
    { word: 'actinomycetal', word_class: 'AJ' },
    { word: 'actinomycetous', word_class: 'AJ' }
  ],
  stylishness: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  perjure: [
    { word: 'perjure', word_class: 'V' },
    { word: 'perjury', word_class: 'N' },
    { word: 'perjurer', word_class: 'N' }
  ],
  discomposed: [
    { word: 'discompose', word_class: 'V' },
    { word: 'discomposed', word_class: 'AJ' },
    { word: 'discomposure', word_class: 'N' }
  ],
  chastise: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  moralizing: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  phosphoresce: [
    { word: 'phosphoresce', word_class: 'V' },
    { word: 'phosphorescence', word_class: 'N' },
    { word: 'phosphorescent', word_class: 'AJ' }
  ],
  smoke: [
    { word: 'smoke', word_class: 'N' },
    { word: 'smoke', word_class: 'V' },
    { word: 'smoker', word_class: 'N' },
    { word: 'smoky', word_class: 'AJ' },
    { word: 'smoked', word_class: 'AJ' },
    { word: 'smoking', word_class: 'N' },
    { word: 'smoking', word_class: 'AJ' }
  ],
  worn: [
    { word: 'wear', word_class: 'N' },
    { word: 'wear', word_class: 'V' },
    { word: 'worn', word_class: 'AJ' },
    { word: 'wearer', word_class: 'N' },
    { word: 'wearing', word_class: 'N' },
    { word: 'wearing', word_class: 'AJ' }
  ],
  profanity: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  palatalized: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  aided: [
    { word: 'aid', word_class: 'N' },
    { word: 'aid', word_class: 'V' },
    { word: 'aide', word_class: 'N' },
    { word: 'aids', word_class: 'N' },
    { word: 'aides', word_class: 'N' },
    { word: 'aided', word_class: 'AJ' }
  ],
  invincibility: [
    { word: 'invincible', word_class: 'AJ' },
    { word: 'invincibly', word_class: 'AV' },
    { word: 'invincibility', word_class: 'N' }
  ],
  slenderness: [
    { word: 'slender', word_class: 'AJ' },
    { word: 'slenderize', word_class: 'V' },
    { word: 'slenderness', word_class: 'N' },
    { word: 'slenderization', word_class: 'N' }
  ],
  upsetting: [
    { word: 'upset', word_class: 'N' },
    { word: 'upset', word_class: 'V' },
    { word: 'upset', word_class: 'AJ' },
    { word: 'upsetting', word_class: 'AJ' }
  ],
  chorally: [
    { word: 'choral', word_class: 'N' },
    { word: 'choral', word_class: 'AJ' },
    { word: 'chorale', word_class: 'N' },
    { word: 'chorally', word_class: 'AV' }
  ],
  steepness: [
    { word: 'steep', word_class: 'N' },
    { word: 'steep', word_class: 'V' },
    { word: 'steep', word_class: 'AJ' },
    { word: 'steepness', word_class: 'N' }
  ],
  tasselled: [
    { word: 'tassel', word_class: 'N' },
    { word: 'tassel', word_class: 'V' },
    { word: 'tasseled', word_class: 'AJ' },
    { word: 'tasselled', word_class: 'AJ' }
  ],
  solarization: [
    { word: 'solar', word_class: 'AJ' },
    { word: 'solarise', word_class: 'V' },
    { word: 'solarize', word_class: 'V' },
    { word: 'solarisation', word_class: 'N' },
    { word: 'solarization', word_class: 'N' }
  ],
  charge: [
    { word: 'charge', word_class: 'N' },
    { word: 'charge', word_class: 'V' },
    { word: 'charger', word_class: 'N' },
    { word: 'charged', word_class: 'AJ' }
  ],
  entirely: [
    { word: 'entire', word_class: 'N' },
    { word: 'entire', word_class: 'AJ' },
    { word: 'entirety', word_class: 'N' },
    { word: 'entirely', word_class: 'AV' },
    { word: 'entireness', word_class: 'N' }
  ],
  hellenize: [
    { word: 'hellene', word_class: 'N' },
    { word: 'hellenic', word_class: 'N' },
    { word: 'hellenic', word_class: 'AJ' },
    { word: 'hellenism', word_class: 'N' },
    { word: 'hellenize', word_class: 'V' },
    { word: 'hellenization', word_class: 'N' }
  ],
  mesmerizing: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  amenity: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  diffuseness: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  smudged: [
    { word: 'smudge', word_class: 'N' },
    { word: 'smudge', word_class: 'V' },
    { word: 'smudgy', word_class: 'AJ' },
    { word: 'smudged', word_class: 'AJ' }
  ],
  compliance: [
    { word: 'comply', word_class: 'V' },
    { word: 'compliance', word_class: 'N' },
    { word: 'compliancy', word_class: 'N' },
    { word: 'complying', word_class: 'AJ' }
  ],
  heathen: [
    { word: 'heathen', word_class: 'N' },
    { word: 'heathen', word_class: 'AJ' },
    { word: 'heathenism', word_class: 'N' },
    { word: 'heathenize', word_class: 'V' },
    { word: 'heathenization', word_class: 'N' }
  ],
  unusable: [
    { word: 'unused', word_class: 'AJ' },
    { word: 'unusable', word_class: 'AJ' },
    { word: 'unuseable', word_class: 'AJ' },
    { word: 'unusefulness', word_class: 'N' }
  ],
  outrage: [
    { word: 'outrage', word_class: 'N' },
    { word: 'outrage', word_class: 'V' },
    { word: 'outraged', word_class: 'AJ' },
    { word: 'outrageous', word_class: 'AJ' },
    { word: 'outrageously', word_class: 'AV' },
    { word: 'outrageousness', word_class: 'N' }
  ],
  basinful: [
    { word: 'basin', word_class: 'N' },
    { word: 'basinal', word_class: 'AJ' },
    { word: 'basined', word_class: 'AJ' },
    { word: 'basinful', word_class: 'N' }
  ],
  brakeman: [
    { word: 'brake', word_class: 'N' },
    { word: 'brake', word_class: 'V' },
    { word: 'brakes', word_class: 'N' },
    { word: 'brakeman', word_class: 'N' }
  ],
  collectivism: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  continuance: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  dive: [
    { word: 'dive', word_class: 'N' },
    { word: 'dive', word_class: 'V' },
    { word: 'diver', word_class: 'N' },
    { word: 'diving', word_class: 'N' },
    { word: 'divers', word_class: 'AJ' }
  ],
  functionally: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  attacker: [
    { word: 'attack', word_class: 'N' },
    { word: 'attack', word_class: 'V' },
    { word: 'attack', word_class: 'AJ' },
    { word: 'attacker', word_class: 'N' },
    { word: 'attacking', word_class: 'AJ' }
  ],
  mugging: [
    { word: 'mug', word_class: 'N' },
    { word: 'mug', word_class: 'V' },
    { word: 'mugful', word_class: 'N' },
    { word: 'mugging', word_class: 'N' }
  ],
  equivocate: [
    { word: 'equivocal', word_class: 'AJ' },
    { word: 'equivocate', word_class: 'V' },
    { word: 'equivocally', word_class: 'AV' },
    { word: 'equivocation', word_class: 'N' },
    { word: 'equivocalness', word_class: 'N' }
  ],
  vermiculated: [
    { word: 'vermiculate', word_class: 'V' },
    { word: 'vermiculate', word_class: 'AJ' },
    { word: 'vermiculated', word_class: 'AJ' },
    { word: 'vermiculation', word_class: 'N' }
  ],
  diggings: [
    { word: 'dig', word_class: 'N' },
    { word: 'dig', word_class: 'V' },
    { word: 'digs', word_class: 'N' },
    { word: 'digging', word_class: 'N' },
    { word: 'diggings', word_class: 'N' }
  ],
  filibusterer: [
    { word: 'filibuster', word_class: 'N' },
    { word: 'filibuster', word_class: 'V' },
    { word: 'filibusterer', word_class: 'N' }
  ],
  industrially: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  rhythmically: [
    { word: 'rhythmic', word_class: 'AJ' },
    { word: 'rhythmical', word_class: 'AJ' },
    { word: 'rhythmicity', word_class: 'N' },
    { word: 'rhythmically', word_class: 'AV' }
  ],
  aeronautic: [
    { word: 'aeronaut', word_class: 'N' },
    { word: 'aeronautic', word_class: 'AJ' },
    { word: 'aeronautics', word_class: 'N' },
    { word: 'aeronautical', word_class: 'AJ' }
  ],
  demagnetization: [
    { word: 'demagnetise', word_class: 'V' },
    { word: 'demagnetize', word_class: 'V' },
    { word: 'demagnetisation', word_class: 'N' },
    { word: 'demagnetization', word_class: 'N' }
  ],
  crucially: [
    { word: 'crucial', word_class: 'AJ' },
    { word: 'cruciality', word_class: 'N' },
    { word: 'crucially', word_class: 'AV' }
  ],
  botch: [
    { word: 'botch', word_class: 'N' },
    { word: 'botch', word_class: 'V' },
    { word: 'botcher', word_class: 'N' },
    { word: 'botched', word_class: 'AJ' }
  ],
  diverting: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  inverter: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  conceptually: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  precedence: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  enunciate: [
    { word: 'enunciate', word_class: 'V' },
    { word: 'enunciable', word_class: 'AJ' },
    { word: 'enunciation', word_class: 'N' }
  ],
  forehanded: [
    { word: 'forehand', word_class: 'N' },
    { word: 'forehand', word_class: 'AJ' },
    { word: 'forehanded', word_class: 'AJ' }
  ],
  idle: [
    { word: 'idle', word_class: 'V' },
    { word: 'idle', word_class: 'AJ' },
    { word: 'idler', word_class: 'N' },
    { word: 'idly', word_class: 'AV' },
    { word: 'idling', word_class: 'N' },
    { word: 'idleness', word_class: 'N' }
  ],
  astronomical: [
    { word: 'astronomy', word_class: 'N' },
    { word: 'astronomer', word_class: 'N' },
    { word: 'astronomic', word_class: 'AJ' },
    { word: 'astronomical', word_class: 'AJ' },
    { word: 'astronomically', word_class: 'AV' }
  ],
  reassure: [
    { word: 'reassure', word_class: 'V' },
    { word: 'reassured', word_class: 'AJ' },
    { word: 'reassurance', word_class: 'N' },
    { word: 'reassuring', word_class: 'AJ' }
  ],
  diametric: [
    { word: 'diameter', word_class: 'N' },
    { word: 'diametral', word_class: 'AJ' },
    { word: 'diametric', word_class: 'AJ' },
    { word: 'diametrical', word_class: 'AJ' },
    { word: 'diametrically', word_class: 'AV' }
  ],
  incubate: [
    { word: 'incubate', word_class: 'V' },
    { word: 'incubator', word_class: 'N' },
    { word: 'incubation', word_class: 'N' }
  ],
  raper: [
    { word: 'rape', word_class: 'N' },
    { word: 'rape', word_class: 'V' },
    { word: 'raper', word_class: 'N' },
    { word: 'raped', word_class: 'AJ' },
    { word: 'rapist', word_class: 'N' }
  ],
  milled: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  psychology: [
    { word: 'psychology', word_class: 'N' },
    { word: 'psychologize', word_class: 'V' },
    { word: 'psychological', word_class: 'AJ' },
    { word: 'psychologically', word_class: 'AV' },
    { word: 'psychologization', word_class: 'N' }
  ],
  importune: [
    { word: 'importune', word_class: 'V' },
    { word: 'importunacy', word_class: 'N' },
    { word: 'importunity', word_class: 'N' },
    { word: 'importunate', word_class: 'AJ' },
    { word: 'importunately', word_class: 'AV' }
  ],
  motivative: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  guarding: [
    { word: 'guard', word_class: 'N' },
    { word: 'guard', word_class: 'V' },
    { word: 'guarded', word_class: 'AJ' },
    { word: 'guarding', word_class: 'N' }
  ],
  fungal: [
    { word: 'fungus', word_class: 'N' },
    { word: 'fungal', word_class: 'AJ' },
    { word: 'fungicide', word_class: 'N' },
    { word: 'fungicidal', word_class: 'AJ' }
  ],
  ultimately: [
    { word: 'ultimate', word_class: 'AJ' },
    { word: 'ultimately', word_class: 'AV' },
    { word: 'ultimateness', word_class: 'N' }
  ],
  zest: [
    { word: 'zest', word_class: 'N' },
    { word: 'zest', word_class: 'V' },
    { word: 'zestful', word_class: 'AJ' },
    { word: 'zestfulness', word_class: 'N' }
  ],
  insole: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  proscription: [
    { word: 'proscribe', word_class: 'N' },
    { word: 'proscribe', word_class: 'V' },
    { word: 'proscribed', word_class: 'AJ' },
    { word: 'proscription', word_class: 'N' },
    { word: 'proscriptive', word_class: 'AJ' }
  ],
  overpowering: [
    { word: 'overpower', word_class: 'V' },
    { word: 'overpowered', word_class: 'AJ' },
    { word: 'overpowering', word_class: 'AJ' }
  ],
  opulently: [
    { word: 'opulence', word_class: 'N' },
    { word: 'opulent', word_class: 'AJ' },
    { word: 'opulently', word_class: 'AV' }
  ],
  carbonization: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  selected: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  confect: [
    { word: 'confect', word_class: 'V' },
    { word: 'confection', word_class: 'N' },
    { word: 'confection', word_class: 'V' }
  ],
  franchise: [
    { word: 'franchise', word_class: 'N' },
    { word: 'franchise', word_class: 'V' },
    { word: 'franchisement', word_class: 'N' }
  ],
  extrapolate: [
    { word: 'extrapolate', word_class: 'V' },
    { word: 'extrapolated', word_class: 'AJ' },
    { word: 'extrapolation', word_class: 'N' }
  ],
  transpose: [
    { word: 'transpose', word_class: 'N' },
    { word: 'transpose', word_class: 'V' },
    { word: 'transposed', word_class: 'AJ' },
    { word: 'transposition', word_class: 'N' },
    { word: 'transposability', word_class: 'N' }
  ],
  cantonal: [
    { word: 'canton', word_class: 'N' },
    { word: 'canton', word_class: 'V' },
    { word: 'cantonal', word_class: 'AJ' },
    { word: 'cantonment', word_class: 'N' }
  ],
  kisser: [
    { word: 'kiss', word_class: 'N' },
    { word: 'kiss', word_class: 'V' },
    { word: 'kisser', word_class: 'N' },
    { word: 'kissing', word_class: 'N' }
  ],
  mobile: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  decomposing: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  suggest: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  sporting: [
    { word: 'sport', word_class: 'N' },
    { word: 'sport', word_class: 'V' },
    { word: 'sport', word_class: 'AJ' },
    { word: 'sports', word_class: 'N' },
    { word: 'sporting', word_class: 'AJ' }
  ],
  optimize: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  equating: [
    { word: 'equate', word_class: 'V' },
    { word: 'equator', word_class: 'N' },
    { word: 'equating', word_class: 'N' },
    { word: 'equation', word_class: 'N' },
    { word: 'equatability', word_class: 'N' }
  ],
  expenditure: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  articulated: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  decompress: [
    { word: 'decompress', word_class: 'V' },
    { word: 'decompressing', word_class: 'N' },
    { word: 'decompression', word_class: 'N' }
  ],
  adequateness: [
    { word: 'adequacy', word_class: 'N' },
    { word: 'adequate', word_class: 'AJ' },
    { word: 'adequately', word_class: 'AV' },
    { word: 'adequateness', word_class: 'N' }
  ],
  calmness: [
    { word: 'calm', word_class: 'N' },
    { word: 'calm', word_class: 'V' },
    { word: 'calm', word_class: 'AJ' },
    { word: 'calming', word_class: 'N' },
    { word: 'calming', word_class: 'AJ' },
    { word: 'calmness', word_class: 'N' }
  ],
  scrutinize: [
    { word: 'scrutiny', word_class: 'N' },
    { word: 'scrutinise', word_class: 'V' },
    { word: 'scrutinize', word_class: 'V' },
    { word: 'scrutineer', word_class: 'N' },
    { word: 'scrutinise', word_class: 'AJ' },
    { word: 'scrutinize', word_class: 'AJ' }
  ],
  coiled: [
    { word: 'coil', word_class: 'N' },
    { word: 'coil', word_class: 'V' },
    { word: 'coiled', word_class: 'AJ' },
    { word: 'coiling', word_class: 'AJ' }
  ],
  matriarchic: [
    { word: 'matriarch', word_class: 'N' },
    { word: 'matriarchal', word_class: 'AJ' },
    { word: 'matriarchate', word_class: 'N' },
    { word: 'matriarchic', word_class: 'AJ' }
  ],
  iodine: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  cartographic: [
    { word: 'cartographer', word_class: 'N' },
    { word: 'cartographic', word_class: 'AJ' },
    { word: 'cartographical', word_class: 'AJ' }
  ],
  pressed: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  demoralized: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  assail: [
    { word: 'assail', word_class: 'V' },
    { word: 'assailant', word_class: 'N' },
    { word: 'assailment', word_class: 'N' },
    { word: 'assailable', word_class: 'AJ' },
    { word: 'assailability', word_class: 'N' }
  ],
  bestow: [
    { word: 'bestow', word_class: 'V' },
    { word: 'bestowal', word_class: 'N' },
    { word: 'bestowed', word_class: 'AJ' },
    { word: 'bestowment', word_class: 'N' }
  ],
  terrorism: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  fetching: [
    { word: 'fetch', word_class: 'N' },
    { word: 'fetch', word_class: 'V' },
    { word: 'fetching', word_class: 'AJ' }
  ],
  simmering: [
    { word: 'simmer', word_class: 'N' },
    { word: 'simmer', word_class: 'V' },
    { word: 'simmering', word_class: 'N' }
  ],
  incorporable: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  depletable: [
    { word: 'deplete', word_class: 'V' },
    { word: 'depleted', word_class: 'AJ' },
    { word: 'depletion', word_class: 'N' },
    { word: 'depletable', word_class: 'AJ' }
  ],
  absurdity: [
    { word: 'absurd', word_class: 'AJ' },
    { word: 'absurdity', word_class: 'N' },
    { word: 'absurdness', word_class: 'N' }
  ],
  ergotic: [
    { word: 'ergot', word_class: 'N' },
    { word: 'ergotic', word_class: 'AJ' },
    { word: 'ergotism', word_class: 'N' }
  ],
  blissful: [
    { word: 'bliss', word_class: 'N' },
    { word: 'blissful', word_class: 'AJ' },
    { word: 'blissfulness', word_class: 'N' }
  ],
  encroaching: [
    { word: 'encroach', word_class: 'V' },
    { word: 'encroacher', word_class: 'N' },
    { word: 'encroaching', word_class: 'AJ' },
    { word: 'encroachment', word_class: 'N' }
  ],
  vagrancy: [
    { word: 'vagrant', word_class: 'N' },
    { word: 'vagrancy', word_class: 'N' },
    { word: 'vagrant', word_class: 'AJ' }
  ],
  presumptuous: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  cable: [{ word: 'cable', word_class: 'N' }, { word: 'cable', word_class: 'V' }, { word: 'cabled', word_class: 'AJ' }],
  rebelliously: [
    { word: 'rebellious', word_class: 'AJ' },
    { word: 'rebelliously', word_class: 'AV' },
    { word: 'rebelliousness', word_class: 'N' }
  ],
  freshness: [
    { word: 'fresh', word_class: 'AJ' },
    { word: 'fresh', word_class: 'AV' },
    { word: 'freshness', word_class: 'N' }
  ],
  concretize: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  subservientness: [
    { word: 'subserve', word_class: 'V' },
    { word: 'subservience', word_class: 'N' },
    { word: 'subservient', word_class: 'AJ' },
    { word: 'subserviently', word_class: 'AV' },
    { word: 'subservientness', word_class: 'N' }
  ],
  roaring: [
    { word: 'roar', word_class: 'N' },
    { word: 'roar', word_class: 'V' },
    { word: 'roarer', word_class: 'N' },
    { word: 'roaring', word_class: 'N' },
    { word: 'roaring', word_class: 'AJ' },
    { word: 'roaring', word_class: 'AV' }
  ],
  'left-hand': [
    { word: 'left-hand', word_class: 'AJ' },
    { word: 'left-hander', word_class: 'N' },
    { word: 'left-handed', word_class: 'AJ' }
  ],
  typed: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  in: [
    { word: 'in', word_class: 'N' },
    { word: 'in', word_class: 'AJ' },
    { word: 'in', word_class: 'AV' },
    { word: 'inning', word_class: 'N' },
    { word: 'innings', word_class: 'N' }
  ],
  motivational: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  intimidating: [
    { word: 'intimidate', word_class: 'V' },
    { word: 'intimidated', word_class: 'AJ' },
    { word: 'intimidation', word_class: 'N' },
    { word: 'intimidating', word_class: 'AJ' }
  ],
  victimize: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  gloved: [
    { word: 'glove', word_class: 'N' },
    { word: 'glove', word_class: 'V' },
    { word: 'gloves', word_class: 'N' },
    { word: 'gloved', word_class: 'AJ' }
  ],
  ciliated: [
    { word: 'ciliate', word_class: 'N' },
    { word: 'ciliate', word_class: 'V' },
    { word: 'ciliate', word_class: 'AJ' },
    { word: 'ciliated', word_class: 'AJ' },
    { word: 'ciliation', word_class: 'N' }
  ],
  randomise: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  consult: [
    { word: 'consult', word_class: 'V' },
    { word: 'consultant', word_class: 'N' },
    { word: 'consultive', word_class: 'AJ' },
    { word: 'consultation', word_class: 'N' },
    { word: 'consultative', word_class: 'AJ' }
  ],
  inquisition: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  bud: [
    { word: 'bud', word_class: 'N' },
    { word: 'bud', word_class: 'V' },
    { word: 'budding', word_class: 'N' },
    { word: 'budding', word_class: 'AJ' }
  ],
  joviality: [
    { word: 'jovial', word_class: 'AJ' },
    { word: 'joviality', word_class: 'N' },
    { word: 'jovially', word_class: 'AV' }
  ],
  inconceivableness: [
    { word: 'inconceivable', word_class: 'AJ' },
    { word: 'inconceivably', word_class: 'AV' },
    { word: 'inconceivability', word_class: 'N' },
    { word: 'inconceivableness', word_class: 'N' }
  ],
  gemination: [
    { word: 'geminate', word_class: 'V' },
    { word: 'geminate', word_class: 'AJ' },
    { word: 'gemination', word_class: 'N' }
  ],
  rationalness: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  methodise: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  aphorize: [
    { word: 'aphorism', word_class: 'N' },
    { word: 'aphorize', word_class: 'V' },
    { word: 'aphorization', word_class: 'N' }
  ],
  astute: [
    { word: 'astute', word_class: 'AJ' },
    { word: 'astutely', word_class: 'AV' },
    { word: 'astuteness', word_class: 'N' }
  ],
  indentation: [
    { word: 'indent', word_class: 'N' },
    { word: 'indent', word_class: 'V' },
    { word: 'indenture', word_class: 'N' },
    { word: 'indenture', word_class: 'V' },
    { word: 'indented', word_class: 'AJ' },
    { word: 'indentation', word_class: 'N' },
    { word: 'indentured', word_class: 'AJ' }
  ],
  isolating: [
    { word: 'isolate', word_class: 'V' },
    { word: 'isolable', word_class: 'AJ' },
    { word: 'isolated', word_class: 'AJ' },
    { word: 'isolation', word_class: 'N' },
    { word: 'isolating', word_class: 'AJ' }
  ],
  disbursal: [
    { word: 'disburse', word_class: 'V' },
    { word: 'disbursal', word_class: 'N' },
    { word: 'disburser', word_class: 'N' },
    { word: 'disbursement', word_class: 'N' }
  ],
  accept: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  reporter: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  stressed: [
    { word: 'stress', word_class: 'N' },
    { word: 'stress', word_class: 'V' },
    { word: 'stressed', word_class: 'AJ' },
    { word: 'stressful', word_class: 'AJ' }
  ],
  irresolution: [
    { word: 'irresolute', word_class: 'AJ' },
    { word: 'irresolution', word_class: 'N' },
    { word: 'irresolutely', word_class: 'AV' },
    { word: 'irresoluteness', word_class: 'N' }
  ],
  unceremoniousness: [
    { word: 'unceremonial', word_class: 'AJ' },
    { word: 'unceremonious', word_class: 'AJ' },
    { word: 'unceremoniously', word_class: 'AV' },
    { word: 'unceremoniousness', word_class: 'N' }
  ],
  allotropical: [
    { word: 'allotrope', word_class: 'N' },
    { word: 'allotropic', word_class: 'AJ' },
    { word: 'allotropism', word_class: 'N' },
    { word: 'allotropical', word_class: 'AJ' }
  ],
  neutralize: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  differ: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  oblong: [
    { word: 'oblong', word_class: 'N' },
    { word: 'oblong', word_class: 'AJ' },
    { word: 'oblongness', word_class: 'N' }
  ],
  aphorization: [
    { word: 'aphorism', word_class: 'N' },
    { word: 'aphorize', word_class: 'V' },
    { word: 'aphorization', word_class: 'N' }
  ],
  intumescence: [
    { word: 'intumesce', word_class: 'V' },
    { word: 'intumescence', word_class: 'N' },
    { word: 'intumescency', word_class: 'N' }
  ],
  impracticability: [
    { word: 'impracticable', word_class: 'AJ' },
    { word: 'impracticably', word_class: 'AV' },
    { word: 'impracticability', word_class: 'N' },
    { word: 'impracticableness', word_class: 'N' }
  ],
  gambler: [
    { word: 'gamble', word_class: 'N' },
    { word: 'gamble', word_class: 'V' },
    { word: 'gambler', word_class: 'N' },
    { word: 'gambling', word_class: 'N' },
    { word: 'gambling', word_class: 'AJ' }
  ],
  remonstrance: [
    { word: 'remonstrant', word_class: 'N' },
    { word: 'remonstrate', word_class: 'V' },
    { word: 'remonstrance', word_class: 'N' },
    { word: 'remonstrant', word_class: 'AJ' },
    { word: 'remonstration', word_class: 'N' }
  ],
  rooky: [{ word: 'rooky', word_class: 'N' }, { word: 'rookie', word_class: 'N' }, { word: 'rooky', word_class: 'AJ' }],
  evaporable: [
    { word: 'evaporate', word_class: 'V' },
    { word: 'evaporable', word_class: 'AJ' },
    { word: 'evaporated', word_class: 'AJ' },
    { word: 'evaporation', word_class: 'N' },
    { word: 'evaporative', word_class: 'AJ' }
  ],
  polls: [{ word: 'poll', word_class: 'N' }, { word: 'poll', word_class: 'V' }, { word: 'polls', word_class: 'N' }],
  irresoluteness: [
    { word: 'irresolute', word_class: 'AJ' },
    { word: 'irresolution', word_class: 'N' },
    { word: 'irresolutely', word_class: 'AV' },
    { word: 'irresoluteness', word_class: 'N' }
  ],
  pirate: [
    { word: 'piracy', word_class: 'N' },
    { word: 'pirate', word_class: 'N' },
    { word: 'pirate', word_class: 'V' },
    { word: 'piratical', word_class: 'AJ' },
    { word: 'piratically', word_class: 'AV' }
  ],
  rigid: [
    { word: 'rigid', word_class: 'AJ' },
    { word: 'rigidity', word_class: 'N' },
    { word: 'rigidness', word_class: 'N' }
  ],
  preferment: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  quadratics: [
    { word: 'quadrate', word_class: 'N' },
    { word: 'quadrate', word_class: 'AJ' },
    { word: 'quadratic', word_class: 'N' },
    { word: 'quadratic', word_class: 'AJ' },
    { word: 'quadratics', word_class: 'N' }
  ],
  inaccessibility: [
    { word: 'inaccessible', word_class: 'AJ' },
    { word: 'inaccessibly', word_class: 'AV' },
    { word: 'inaccessibility', word_class: 'N' }
  ],
  critical: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  imperilment: [
    { word: 'imperil', word_class: 'N' },
    { word: 'imperil', word_class: 'V' },
    { word: 'imperilment', word_class: 'N' }
  ],
  kidnaped: [
    { word: 'kidnap', word_class: 'V' },
    { word: 'kidnaper', word_class: 'N' },
    { word: 'kidnaped', word_class: 'AJ' },
    { word: 'kidnapped', word_class: 'AJ' },
    { word: 'kidnapping', word_class: 'N' }
  ],
  conventioneer: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  unnerve: [
    { word: 'unnerve', word_class: 'V' },
    { word: 'unnerved', word_class: 'AJ' },
    { word: 'unnerving', word_class: 'AJ' }
  ],
  roughage: [
    { word: 'rough', word_class: 'N' },
    { word: 'rough', word_class: 'V' },
    { word: 'rough', word_class: 'AJ' },
    { word: 'rough', word_class: 'AV' },
    { word: 'roughage', word_class: 'N' },
    { word: 'roughness', word_class: 'N' }
  ],
  dismemberment: [
    { word: 'dismember', word_class: 'N' },
    { word: 'dismember', word_class: 'V' },
    { word: 'dismemberment', word_class: 'N' }
  ],
  gambling: [
    { word: 'gamble', word_class: 'N' },
    { word: 'gamble', word_class: 'V' },
    { word: 'gambler', word_class: 'N' },
    { word: 'gambling', word_class: 'N' },
    { word: 'gambling', word_class: 'AJ' }
  ],
  tentative: [
    { word: 'tent', word_class: 'N' },
    { word: 'tent', word_class: 'V' },
    { word: 'tenting', word_class: 'N' },
    { word: 'tentative', word_class: 'AJ' },
    { word: 'tentatively', word_class: 'AV' }
  ],
  exempt: [
    { word: 'exempt', word_class: 'V' },
    { word: 'exempt', word_class: 'AJ' },
    { word: 'exemption', word_class: 'N' }
  ],
  cockneyfication: [
    { word: 'cocknify', word_class: 'V' },
    { word: 'cockneyfy', word_class: 'V' },
    { word: 'cocknification', word_class: 'N' },
    { word: 'cockneyfication', word_class: 'N' }
  ],
  typographical: [
    { word: 'typographer', word_class: 'N' },
    { word: 'typographic', word_class: 'AJ' },
    { word: 'typographical', word_class: 'AJ' },
    { word: 'typographically', word_class: 'AV' }
  ],
  summation: [
    { word: 'sum', word_class: 'N' },
    { word: 'sum', word_class: 'V' },
    { word: 'summate', word_class: 'V' },
    { word: 'summation', word_class: 'N' },
    { word: 'summational', word_class: 'AJ' }
  ],
  rewardful: [
    { word: 'reward', word_class: 'N' },
    { word: 'reward', word_class: 'V' },
    { word: 'rewardful', word_class: 'AJ' },
    { word: 'rewarding', word_class: 'AJ' },
    { word: 'rewardable', word_class: 'AJ' }
  ],
  edification: [
    { word: 'edify', word_class: 'V' },
    { word: 'edified', word_class: 'AJ' },
    { word: 'edifying', word_class: 'AJ' },
    { word: 'edification', word_class: 'N' }
  ],
  skinful: [
    { word: 'skin', word_class: 'N' },
    { word: 'skin', word_class: 'V' },
    { word: 'skinful', word_class: 'N' },
    { word: 'skinful', word_class: 'AJ' },
    { word: 'skinned', word_class: 'AJ' }
  ],
  purulence: [
    { word: 'purulence', word_class: 'N' },
    { word: 'purulency', word_class: 'N' },
    { word: 'purulent', word_class: 'AJ' }
  ],
  downward: [
    { word: 'downward', word_class: 'AJ' },
    { word: 'downward', word_class: 'AV' },
    { word: 'downwards', word_class: 'AV' }
  ],
  speech: [
    { word: 'speak', word_class: 'V' },
    { word: 'speech', word_class: 'N' },
    { word: 'speaker', word_class: 'N' },
    { word: 'spoken', word_class: 'AJ' },
    { word: 'speaking', word_class: 'N' },
    { word: 'speaking', word_class: 'AJ' }
  ],
  placidity: [
    { word: 'placid', word_class: 'AJ' },
    { word: 'placidity', word_class: 'N' },
    { word: 'placidness', word_class: 'N' }
  ],
  spank: [
    { word: 'spank', word_class: 'N' },
    { word: 'spank', word_class: 'V' },
    { word: 'spanker', word_class: 'N' },
    { word: 'spanking', word_class: 'N' },
    { word: 'spanking', word_class: 'AJ' }
  ],
  bookkeeping: [
    { word: 'bookkeep', word_class: 'V' },
    { word: 'bookkeeper', word_class: 'N' },
    { word: 'bookkeeping', word_class: 'N' }
  ],
  'de-iodinating': [
    { word: 'de-iodinate', word_class: 'V' },
    { word: 'de-iodination', word_class: 'N' },
    { word: 'de-iodinating', word_class: 'AJ' }
  ],
  indelibly: [
    { word: 'indelible', word_class: 'AJ' },
    { word: 'indelibly', word_class: 'AV' },
    { word: 'indelicate', word_class: 'AJ' }
  ],
  possessor: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  enlivenment: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  formalness: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  cortically: [
    { word: 'cortical', word_class: 'AJ' },
    { word: 'corticate', word_class: 'V' },
    { word: 'cortically', word_class: 'AV' },
    { word: 'cortication', word_class: 'N' }
  ],
  formalization: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  descendant: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  escarpment: [
    { word: 'escarp', word_class: 'N' },
    { word: 'escarp', word_class: 'V' },
    { word: 'escarpment', word_class: 'N' }
  ],
  avariciously: [
    { word: 'avarice', word_class: 'N' },
    { word: 'avaricious', word_class: 'AJ' },
    { word: 'avariciously', word_class: 'AV' },
    { word: 'avariciousness', word_class: 'N' }
  ],
  tater: [
    { word: 'tat', word_class: 'N' },
    { word: 'tat', word_class: 'V' },
    { word: 'tatt', word_class: 'V' },
    { word: 'tater', word_class: 'N' },
    { word: 'tatting', word_class: 'N' }
  ],
  aline: [
    { word: 'aline', word_class: 'V' },
    { word: 'alined', word_class: 'AJ' },
    { word: 'alinement', word_class: 'N' }
  ],
  courageous: [
    { word: 'courage', word_class: 'N' },
    { word: 'courageous', word_class: 'AJ' },
    { word: 'courageously', word_class: 'AV' },
    { word: 'courageousness', word_class: 'N' }
  ],
  catholicization: [
    { word: 'catholicise', word_class: 'V' },
    { word: 'catholicism', word_class: 'N' },
    { word: 'catholicize', word_class: 'V' },
    { word: 'catholicisation', word_class: 'N' },
    { word: 'catholicization', word_class: 'N' }
  ],
  finder: [
    { word: 'find', word_class: 'N' },
    { word: 'find', word_class: 'V' },
    { word: 'finder', word_class: 'N' },
    { word: 'finding', word_class: 'N' }
  ],
  reception: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  cocainisation: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  cypriot: [
    { word: 'cypriot', word_class: 'N' },
    { word: 'cypriot', word_class: 'AJ' },
    { word: 'cypriote', word_class: 'N' },
    { word: 'cypriote', word_class: 'AJ' }
  ],
  assist: [
    { word: 'assist', word_class: 'N' },
    { word: 'assist', word_class: 'V' },
    { word: 'assistant', word_class: 'N' },
    { word: 'assisted', word_class: 'AJ' },
    { word: 'assistance', word_class: 'N' },
    { word: 'assistant', word_class: 'AJ' },
    { word: 'assistive', word_class: 'AJ' }
  ],
  straw: [{ word: 'straw', word_class: 'N' }, { word: 'straw', word_class: 'V' }, { word: 'straw', word_class: 'AJ' }],
  recessed: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  risk: [{ word: 'risk', word_class: 'N' }, { word: 'risk', word_class: 'V' }, { word: 'risking', word_class: 'N' }],
  characterize: [
    { word: 'character', word_class: 'N' },
    { word: 'character', word_class: 'V' },
    { word: 'characterize', word_class: 'V' },
    { word: 'characterized', word_class: 'AJ' },
    { word: 'characterization', word_class: 'N' }
  ],
  swindle: [
    { word: 'swindle', word_class: 'N' },
    { word: 'swindle', word_class: 'V' },
    { word: 'swindler', word_class: 'N' }
  ],
  ulcerous: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  delineated: [
    { word: 'delineate', word_class: 'V' },
    { word: 'delineate', word_class: 'AJ' },
    { word: 'delineated', word_class: 'AJ' },
    { word: 'delineation', word_class: 'N' },
    { word: 'delineative', word_class: 'AJ' }
  ],
  grip: [
    { word: 'grip', word_class: 'N' },
    { word: 'grip', word_class: 'V' },
    { word: 'gripe', word_class: 'N' },
    { word: 'gripe', word_class: 'V' },
    { word: 'gripping', word_class: 'AJ' }
  ],
  hate: [
    { word: 'hate', word_class: 'N' },
    { word: 'hate', word_class: 'V' },
    { word: 'hater', word_class: 'N' },
    { word: 'hated', word_class: 'AJ' },
    { word: 'hateful', word_class: 'AJ' },
    { word: 'hatefulness', word_class: 'N' }
  ],
  seeking: [
    { word: 'seek', word_class: 'N' },
    { word: 'seek', word_class: 'V' },
    { word: 'seeker', word_class: 'N' },
    { word: 'seeking', word_class: 'N' },
    { word: 'seeking', word_class: 'AJ' }
  ],
  feasible: [
    { word: 'feasible', word_class: 'AJ' },
    { word: 'feasible', word_class: 'AV' },
    { word: 'feasibility', word_class: 'N' },
    { word: 'feasibleness', word_class: 'N' }
  ],
  vender: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  crime: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  wherefore: [
    { word: 'wherefor', word_class: 'AV' },
    { word: 'wherefore', word_class: 'N' },
    { word: 'wherefore', word_class: 'AV' }
  ],
  rectangle: [
    { word: 'rectangle', word_class: 'N' },
    { word: 'rectangular', word_class: 'AJ' },
    { word: 'rectangularity', word_class: 'N' }
  ],
  appositively: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  revitalize: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  partition: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  canon: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  jargonization: [
    { word: 'jargon', word_class: 'N' },
    { word: 'jargon', word_class: 'V' },
    { word: 'jargonise', word_class: 'V' },
    { word: 'jargonize', word_class: 'V' },
    { word: 'jargonisation', word_class: 'N' },
    { word: 'jargonization', word_class: 'N' }
  ],
  integrated: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  abstraction: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  crow: [
    { word: 'crow', word_class: 'N' },
    { word: 'crow', word_class: 'V' },
    { word: 'crowing', word_class: 'N' },
    { word: 'crowing', word_class: 'AJ' }
  ],
  dissociation: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  mimickry: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  insane: [
    { word: 'insane', word_class: 'AJ' },
    { word: 'insanity', word_class: 'N' },
    { word: 'insanely', word_class: 'AV' },
    { word: 'insaneness', word_class: 'N' }
  ],
  handicapped: [
    { word: 'handicap', word_class: 'N' },
    { word: 'handicap', word_class: 'V' },
    { word: 'handicapped', word_class: 'AJ' }
  ],
  incurvation: [
    { word: 'incurvate', word_class: 'V' },
    { word: 'incurved', word_class: 'AJ' },
    { word: 'incurvate', word_class: 'AJ' },
    { word: 'incurvation', word_class: 'N' },
    { word: 'incurvature', word_class: 'N' }
  ],
  thematically: [
    { word: 'theme', word_class: 'N' },
    { word: 'thematic', word_class: 'AJ' },
    { word: 'thematically', word_class: 'AV' }
  ],
  correspondence: [
    { word: 'correspond', word_class: 'V' },
    { word: 'correspondent', word_class: 'N' },
    { word: 'correspondence', word_class: 'N' },
    { word: 'correspondent', word_class: 'AJ' },
    { word: 'corresponding', word_class: 'AJ' }
  ],
  japery: [{ word: 'jape', word_class: 'N' }, { word: 'jape', word_class: 'V' }, { word: 'japery', word_class: 'N' }],
  nocturnal: [
    { word: 'nocturne', word_class: 'N' },
    { word: 'nocturnal', word_class: 'AJ' },
    { word: 'nocturnally', word_class: 'AV' }
  ],
  axonal: [{ word: 'axon', word_class: 'N' }, { word: 'axone', word_class: 'N' }, { word: 'axonal', word_class: 'AJ' }],
  technical: [
    { word: 'technical', word_class: 'AJ' },
    { word: 'technicality', word_class: 'N' },
    { word: 'technically', word_class: 'AV' }
  ],
  pressurized: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  boaster: [
    { word: 'boast', word_class: 'N' },
    { word: 'boast', word_class: 'V' },
    { word: 'boaster', word_class: 'N' },
    { word: 'boasting', word_class: 'N' },
    { word: 'boastful', word_class: 'AJ' },
    { word: 'boastfulness', word_class: 'N' }
  ],
  laminitis: [
    { word: 'laminate', word_class: 'N' },
    { word: 'laminate', word_class: 'V' },
    { word: 'laminitis', word_class: 'N' },
    { word: 'lamination', word_class: 'N' }
  ],
  propagandization: [
    { word: 'propaganda', word_class: 'N' },
    { word: 'propagandist', word_class: 'N' },
    { word: 'propagandize', word_class: 'V' },
    { word: 'propagandist', word_class: 'AJ' },
    { word: 'propagandistic', word_class: 'AJ' },
    { word: 'propagandization', word_class: 'N' }
  ],
  strangulation: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  buckram: [
    { word: 'buckram', word_class: 'N' },
    { word: 'buckram', word_class: 'V' },
    { word: 'buckram', word_class: 'AJ' }
  ],
  detriment: [
    { word: 'detriment', word_class: 'N' },
    { word: 'detrimental', word_class: 'AJ' },
    { word: 'detrimentally', word_class: 'AV' }
  ],
  monopoly: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  squeak: [
    { word: 'squeak', word_class: 'N' },
    { word: 'squeak', word_class: 'V' },
    { word: 'squeaker', word_class: 'N' },
    { word: 'squeaking', word_class: 'AJ' }
  ],
  agone: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  liquid: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  ignominious: [
    { word: 'ignominy', word_class: 'N' },
    { word: 'ignominious', word_class: 'AJ' },
    { word: 'ignominiously', word_class: 'AV' },
    { word: 'ignominiousness', word_class: 'N' }
  ],
  orphan: [
    { word: 'orphan', word_class: 'N' },
    { word: 'orphan', word_class: 'V' },
    { word: 'orphan', word_class: 'AJ' },
    { word: 'orphanage', word_class: 'N' },
    { word: 'orphaned', word_class: 'AJ' }
  ],
  indigestible: [
    { word: 'indigestion', word_class: 'N' },
    { word: 'indigestible', word_class: 'AJ' },
    { word: 'indigestibility', word_class: 'N' },
    { word: 'indigestibleness', word_class: 'N' }
  ],
  coeval: [
    { word: 'coeval', word_class: 'N' },
    { word: 'coeval', word_class: 'AJ' },
    { word: 'coevals', word_class: 'N' }
  ],
  deserved: [
    { word: 'deserve', word_class: 'V' },
    { word: 'deserved', word_class: 'AJ' },
    { word: 'deserving', word_class: 'AJ' }
  ],
  revealing: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  annotate: [
    { word: 'annotate', word_class: 'V' },
    { word: 'annotating', word_class: 'N' },
    { word: 'annotation', word_class: 'N' }
  ],
  transparent: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  importation: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  tourism: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  diagram: [
    { word: 'diagram', word_class: 'N' },
    { word: 'diagram', word_class: 'V' },
    { word: 'diagramming', word_class: 'N' },
    { word: 'diagrammatic', word_class: 'AJ' },
    { word: 'diagrammatical', word_class: 'AJ' },
    { word: 'diagrammatically', word_class: 'AV' }
  ],
  cuckold: [
    { word: 'cuckold', word_class: 'N' },
    { word: 'cuckold', word_class: 'V' },
    { word: 'cuckoldry', word_class: 'N' }
  ],
  malodor: [
    { word: 'malodor', word_class: 'N' },
    { word: 'malodorous', word_class: 'AJ' },
    { word: 'malodorousness', word_class: 'N' }
  ],
  ouster: [
    { word: 'oust', word_class: 'V' },
    { word: 'ouster', word_class: 'N' },
    { word: 'ousting', word_class: 'N' }
  ],
  detribalisation: [
    { word: 'detribalise', word_class: 'V' },
    { word: 'detribalize', word_class: 'V' },
    { word: 'detribalisation', word_class: 'N' },
    { word: 'detribalization', word_class: 'N' }
  ],
  dissolver: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  winemaking: [
    { word: 'wine', word_class: 'N' },
    { word: 'wine', word_class: 'V' },
    { word: 'winy', word_class: 'AJ' },
    { word: 'winery', word_class: 'N' },
    { word: 'winemaker', word_class: 'N' },
    { word: 'winemaking', word_class: 'N' }
  ],
  minister: [
    { word: 'minister', word_class: 'V' },
    { word: 'minister', word_class: 'N' },
    { word: 'ministry', word_class: 'N' },
    { word: 'ministrant', word_class: 'N' },
    { word: 'ministrant', word_class: 'AJ' },
    { word: 'ministering', word_class: 'AJ' },
    { word: 'ministration', word_class: 'N' }
  ],
  principe: [
    { word: 'principe', word_class: 'N' },
    { word: 'principal', word_class: 'N' },
    { word: 'principal', word_class: 'AJ' },
    { word: 'principality', word_class: 'N' },
    { word: 'principally', word_class: 'AV' }
  ],
  polygonally: [
    { word: 'polygon', word_class: 'N' },
    { word: 'polygonal', word_class: 'AJ' },
    { word: 'polygonally', word_class: 'AV' }
  ],
  advertently: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  localization: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  scoured: [
    { word: 'scour', word_class: 'N' },
    { word: 'scour', word_class: 'V' },
    { word: 'scours', word_class: 'N' },
    { word: 'scourer', word_class: 'N' },
    { word: 'scoured', word_class: 'AJ' },
    { word: 'scouring', word_class: 'N' }
  ],
  winterisation: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  onomatopoeic: [
    { word: 'onomatopoeia', word_class: 'N' },
    { word: 'onomatopoeic', word_class: 'AJ' },
    { word: 'onomatopoetic', word_class: 'AJ' },
    { word: 'onomatopoeical', word_class: 'AJ' }
  ],
  chromatically: [
    { word: 'chromate', word_class: 'N' },
    { word: 'chromatic', word_class: 'AJ' },
    { word: 'chromatism', word_class: 'N' },
    { word: 'chromaticity', word_class: 'N' },
    { word: 'chromatically', word_class: 'AV' }
  ],
  massager: [
    { word: 'massage', word_class: 'N' },
    { word: 'massage', word_class: 'V' },
    { word: 'massager', word_class: 'N' }
  ],
  enable: [
    { word: 'enable', word_class: 'V' },
    { word: 'enabling', word_class: 'AJ' },
    { word: 'enablement', word_class: 'N' }
  ],
  stillness: [
    { word: 'still', word_class: 'N' },
    { word: 'still', word_class: 'V' },
    { word: 'still', word_class: 'AJ' },
    { word: 'still', word_class: 'AV' },
    { word: 'stillness', word_class: 'N' }
  ],
  petrified: [
    { word: 'petrify', word_class: 'V' },
    { word: 'petrified', word_class: 'AJ' },
    { word: 'petrifying', word_class: 'AJ' },
    { word: 'petrifaction', word_class: 'N' },
    { word: 'petrification', word_class: 'N' }
  ],
  alphabetization: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  disordered: [
    { word: 'disorder', word_class: 'N' },
    { word: 'disorder', word_class: 'V' },
    { word: 'disordered', word_class: 'AJ' }
  ],
  carunculate: [
    { word: 'carunculate', word_class: 'AJ' },
    { word: 'carunculous', word_class: 'AJ' },
    { word: 'carunculated', word_class: 'AJ' }
  ],
  barefoot: [
    { word: 'barefoot', word_class: 'AJ' },
    { word: 'barefoot', word_class: 'AV' },
    { word: 'barefooted', word_class: 'AJ' },
    { word: 'barefooted', word_class: 'AV' }
  ],
  unpredictive: [
    { word: 'unpredicted', word_class: 'AJ' },
    { word: 'unpredictive', word_class: 'AJ' },
    { word: 'unpredictable', word_class: 'AJ' },
    { word: 'unpredictably', word_class: 'AV' },
    { word: 'unpredictability', word_class: 'N' }
  ],
  sterility: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  vivifying: [
    { word: 'vivify', word_class: 'V' },
    { word: 'vivifying', word_class: 'N' },
    { word: 'vivification', word_class: 'N' }
  ],
  reticently: [
    { word: 'reticence', word_class: 'N' },
    { word: 'reticent', word_class: 'AJ' },
    { word: 'reticently', word_class: 'AV' }
  ],
  opt: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  repugnance: [
    { word: 'repugn', word_class: 'V' },
    { word: 'repugnance', word_class: 'N' },
    { word: 'repugnancy', word_class: 'N' },
    { word: 'repugnant', word_class: 'AJ' }
  ],
  potter: [
    { word: 'potter', word_class: 'N' },
    { word: 'potter', word_class: 'V' },
    { word: 'potterer', word_class: 'N' }
  ],
  accusation: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  vivace: [
    { word: 'vivace', word_class: 'AJ' },
    { word: 'vivace', word_class: 'AV' },
    { word: 'vivacity', word_class: 'N' }
  ],
  episcopal: [
    { word: 'episcopal', word_class: 'N' },
    { word: 'episcopal', word_class: 'AJ' },
    { word: 'episcopate', word_class: 'N' }
  ],
  referent: [
    { word: 'refer', word_class: 'V' },
    { word: 'referent', word_class: 'N' },
    { word: 'referral', word_class: 'N' },
    { word: 'reference', word_class: 'N' },
    { word: 'reference', word_class: 'V' },
    { word: 'referable', word_class: 'AJ' }
  ],
  planter: [
    { word: 'plant', word_class: 'N' },
    { word: 'plant', word_class: 'V' },
    { word: 'planter', word_class: 'N' },
    { word: 'planted', word_class: 'AJ' },
    { word: 'planting', word_class: 'N' },
    { word: 'plantation', word_class: 'N' }
  ],
  refill: [
    { word: 'refill', word_class: 'N' },
    { word: 'refill', word_class: 'V' },
    { word: 'refilling', word_class: 'N' }
  ],
  skewed: [
    { word: 'skew', word_class: 'V' },
    { word: 'skew', word_class: 'AJ' },
    { word: 'skewed', word_class: 'AJ' },
    { word: 'skewness', word_class: 'N' }
  ],
  dandification: [
    { word: 'dandify', word_class: 'V' },
    { word: 'dandified', word_class: 'AJ' },
    { word: 'dandification', word_class: 'N' }
  ],
  valuable: [
    { word: 'valuable', word_class: 'N' },
    { word: 'valuable', word_class: 'AJ' },
    { word: 'valuables', word_class: 'N' },
    { word: 'valuableness', word_class: 'N' }
  ],
  ability: [
    { word: 'abls', word_class: 'N' },
    { word: 'able', word_class: 'AJ' },
    { word: 'ably', word_class: 'AV' },
    { word: 'ability', word_class: 'N' },
    { word: 'ablative', word_class: 'N' },
    { word: 'ablative', word_class: 'AJ' }
  ],
  advisement: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  parabolical: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  infective: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  habitual: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  mystifying: [
    { word: 'mystify', word_class: 'V' },
    { word: 'mystified', word_class: 'AJ' },
    { word: 'mystifying', word_class: 'AJ' },
    { word: 'mystification', word_class: 'N' }
  ],
  observably: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  surprising: [
    { word: 'surprise', word_class: 'N' },
    { word: 'surprise', word_class: 'V' },
    { word: 'surprisal', word_class: 'N' },
    { word: 'surprised', word_class: 'AJ' },
    { word: 'surprising', word_class: 'N' },
    { word: 'surprising', word_class: 'AJ' }
  ],
  platitudinal: [
    { word: 'platitude', word_class: 'N' },
    { word: 'platitudinal', word_class: 'AJ' },
    { word: 'platitudinize', word_class: 'V' },
    { word: 'platitudinous', word_class: 'AJ' },
    { word: 'platitudinization', word_class: 'N' }
  ],
  vibrational: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  picturesquely: [
    { word: 'picturesque', word_class: 'AJ' },
    { word: 'picturesquely', word_class: 'AV' },
    { word: 'picturesqueness', word_class: 'N' }
  ],
  oldness: [
    { word: 'old', word_class: 'N' },
    { word: 'old', word_class: 'AJ' },
    { word: 'oldy', word_class: 'N' },
    { word: 'oldness', word_class: 'N' }
  ],
  stopped: [
    { word: 'stop', word_class: 'N' },
    { word: 'stop', word_class: 'V' },
    { word: 'stops', word_class: 'N' },
    { word: 'stoppage', word_class: 'N' },
    { word: 'stopped', word_class: 'AJ' },
    { word: 'stopping', word_class: 'N' }
  ],
  infield: [
    { word: 'infield', word_class: 'N' },
    { word: 'infield', word_class: 'V' },
    { word: 'infielder', word_class: 'N' }
  ],
  collectable: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  inerrant: [
    { word: 'inerrancy', word_class: 'N' },
    { word: 'inerrant', word_class: 'AJ' },
    { word: 'inerrable', word_class: 'AJ' }
  ],
  hoarseness: [
    { word: 'hoarse', word_class: 'AJ' },
    { word: 'hoarsely', word_class: 'AV' },
    { word: 'hoarseness', word_class: 'N' }
  ],
  empty: [
    { word: 'empty', word_class: 'N' },
    { word: 'empty', word_class: 'V' },
    { word: 'empty', word_class: 'AJ' },
    { word: 'emptied', word_class: 'AJ' },
    { word: 'emptying', word_class: 'N' },
    { word: 'emptiness', word_class: 'N' }
  ],
  portable: [
    { word: 'portable', word_class: 'N' },
    { word: 'portable', word_class: 'AJ' },
    { word: 'portably', word_class: 'AV' },
    { word: 'portability', word_class: 'N' }
  ],
  ardency: [
    { word: 'ardency', word_class: 'N' },
    { word: 'ardent', word_class: 'AJ' },
    { word: 'ardently', word_class: 'AV' }
  ],
  desolation: [
    { word: 'desolate', word_class: 'V' },
    { word: 'desolate', word_class: 'AJ' },
    { word: 'desolated', word_class: 'AJ' },
    { word: 'desolation', word_class: 'N' },
    { word: 'desolately', word_class: 'AV' }
  ],
  barracking: [
    { word: 'barrack', word_class: 'N' },
    { word: 'barrack', word_class: 'V' },
    { word: 'barracks', word_class: 'N' },
    { word: 'barracking', word_class: 'N' }
  ],
  thriving: [
    { word: 'thrive', word_class: 'V' },
    { word: 'thriven', word_class: 'AJ' },
    { word: 'thriving', word_class: 'AJ' }
  ],
  perfected: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  pigeonhole: [
    { word: 'pigeonhole', word_class: 'N' },
    { word: 'pigeonhole', word_class: 'V' },
    { word: 'pigeonholing', word_class: 'N' }
  ],
  tendentious: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  archeologic: [
    { word: 'archeology', word_class: 'N' },
    { word: 'archeologic', word_class: 'AJ' },
    { word: 'archeological', word_class: 'AJ' }
  ],
  baking: [
    { word: 'bake', word_class: 'N' },
    { word: 'bake', word_class: 'V' },
    { word: 'baker', word_class: 'N' },
    { word: 'baked', word_class: 'AJ' },
    { word: 'bakery', word_class: 'N' },
    { word: 'baking', word_class: 'N' },
    { word: 'bakery', word_class: 'AJ' },
    { word: 'baking', word_class: 'AJ' }
  ],
  braised: [
    { word: 'braise', word_class: 'V' },
    { word: 'braised', word_class: 'AJ' },
    { word: 'braising', word_class: 'N' }
  ],
  rectifier: [
    { word: 'rectify', word_class: 'V' },
    { word: 'rectifier', word_class: 'N' },
    { word: 'rectified', word_class: 'AJ' },
    { word: 'rectifiable', word_class: 'AJ' },
    { word: 'rectification', word_class: 'N' }
  ],
  grief: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  lethargic: [
    { word: 'lethargy', word_class: 'N' },
    { word: 'lethargic', word_class: 'AJ' },
    { word: 'lethargically', word_class: 'AV' }
  ],
  curbing: [
    { word: 'curb', word_class: 'N' },
    { word: 'curb', word_class: 'V' },
    { word: 'curbed', word_class: 'AJ' },
    { word: 'curbing', word_class: 'N' }
  ],
  tinning: [
    { word: 'tin', word_class: 'N' },
    { word: 'tin', word_class: 'V' },
    { word: 'tinful', word_class: 'N' },
    { word: 'tinful', word_class: 'AJ' },
    { word: 'tinned', word_class: 'AJ' },
    { word: 'tinning', word_class: 'N' }
  ],
  losses: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  providence: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  crudeness: [
    { word: 'crude', word_class: 'N' },
    { word: 'crude', word_class: 'AJ' },
    { word: 'crudely', word_class: 'AV' },
    { word: 'crudeness', word_class: 'N' }
  ],
  gregarious: [
    { word: 'gregarious', word_class: 'AJ' },
    { word: 'gregariously', word_class: 'AV' },
    { word: 'gregariousness', word_class: 'N' }
  ],
  pleasing: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  theorist: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  scantness: [
    { word: 'scant', word_class: 'V' },
    { word: 'scant', word_class: 'AJ' },
    { word: 'scantness', word_class: 'N' }
  ],
  practicality: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  maleness: [
    { word: 'male', word_class: 'N' },
    { word: 'male', word_class: 'AJ' },
    { word: 'maleness', word_class: 'N' }
  ],
  compressed: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  impatient: [
    { word: 'impatience', word_class: 'N' },
    { word: 'impatient', word_class: 'AJ' },
    { word: 'impatiently', word_class: 'AV' }
  ],
  bathe: [
    { word: 'bath', word_class: 'N' },
    { word: 'bath', word_class: 'V' },
    { word: 'bathe', word_class: 'N' },
    { word: 'bathe', word_class: 'V' },
    { word: 'bather', word_class: 'N' },
    { word: 'bathing', word_class: 'N' }
  ],
  pledge: [
    { word: 'pledge', word_class: 'N' },
    { word: 'pledge', word_class: 'V' },
    { word: 'pledged', word_class: 'AJ' }
  ],
  decolour: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  anchorage: [
    { word: 'anchor', word_class: 'N' },
    { word: 'anchor', word_class: 'V' },
    { word: 'anchorage', word_class: 'N' },
    { word: 'anchorite', word_class: 'N' },
    { word: 'anchorman', word_class: 'N' },
    { word: 'anchoritic', word_class: 'AJ' }
  ],
  appropriative: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  synopsization: [
    { word: 'synopsis', word_class: 'N' },
    { word: 'synopsise', word_class: 'V' },
    { word: 'synopsize', word_class: 'V' },
    { word: 'synopsization', word_class: 'N' }
  ],
  deportment: [
    { word: 'deport', word_class: 'V' },
    { word: 'deportee', word_class: 'N' },
    { word: 'deportment', word_class: 'N' },
    { word: 'deportation', word_class: 'N' }
  ],
  pronominal: [
    { word: 'pronominal', word_class: 'AJ' },
    { word: 'pronominalise', word_class: 'V' },
    { word: 'pronominalize', word_class: 'V' },
    { word: 'pronominalisation', word_class: 'N' },
    { word: 'pronominalization', word_class: 'N' }
  ],
  acronym: [
    { word: 'acronym', word_class: 'N' },
    { word: 'acronymic', word_class: 'AJ' },
    { word: 'acronymous', word_class: 'AJ' }
  ],
  comfortable: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  approximative: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  blusterer: [
    { word: 'bluster', word_class: 'N' },
    { word: 'bluster', word_class: 'V' },
    { word: 'blusterer', word_class: 'N' },
    { word: 'blustering', word_class: 'AJ' },
    { word: 'blusterous', word_class: 'AJ' }
  ],
  crisscrossed: [
    { word: 'crisscross', word_class: 'N' },
    { word: 'crisscross', word_class: 'V' },
    { word: 'crisscross', word_class: 'AJ' },
    { word: 'crisscross', word_class: 'AV' },
    { word: 'crisscrossed', word_class: 'AJ' }
  ],
  acres: [{ word: 'acre', word_class: 'N' }, { word: 'acres', word_class: 'N' }, { word: 'acreage', word_class: 'N' }],
  abolish: [
    { word: 'abolish', word_class: 'V' },
    { word: 'abolition', word_class: 'N' },
    { word: 'abolishment', word_class: 'N' },
    { word: 'abolishable', word_class: 'AJ' }
  ],
  endurance: [
    { word: 'endure', word_class: 'V' },
    { word: 'endurance', word_class: 'N' },
    { word: 'enduring', word_class: 'AJ' },
    { word: 'endurable', word_class: 'AJ' }
  ],
  wads: [
    { word: 'wad', word_class: 'N' },
    { word: 'wad', word_class: 'V' },
    { word: 'wads', word_class: 'N' },
    { word: 'wadding', word_class: 'N' }
  ],
  arithmetic: [
    { word: 'arithmetic', word_class: 'N' },
    { word: 'arithmetic', word_class: 'AJ' },
    { word: 'arithmetical', word_class: 'AJ' },
    { word: 'arithmetically', word_class: 'AV' }
  ],
  brachiopod: [
    { word: 'brachiopod', word_class: 'N' },
    { word: 'brachiopod', word_class: 'AJ' },
    { word: 'brachiopodous', word_class: 'AJ' }
  ],
  conformable: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  traditionalism: [
    { word: 'traditionalism', word_class: 'N' },
    { word: 'traditionality', word_class: 'N' },
    { word: 'traditionally', word_class: 'AV' }
  ],
  incision: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  graces: [
    { word: 'grace', word_class: 'N' },
    { word: 'grace', word_class: 'V' },
    { word: 'graces', word_class: 'N' },
    { word: 'gracious', word_class: 'AJ' },
    { word: 'graceful', word_class: 'AJ' },
    { word: 'gracefulness', word_class: 'N' }
  ],
  cognate: [
    { word: 'cognate', word_class: 'N' },
    { word: 'cognate', word_class: 'AJ' },
    { word: 'cognation', word_class: 'N' }
  ],
  portrayal: [
    { word: 'portray', word_class: 'V' },
    { word: 'portrayal', word_class: 'N' },
    { word: 'portrayed', word_class: 'AJ' },
    { word: 'portraying', word_class: 'N' }
  ],
  deviant: [
    { word: 'deviant', word_class: 'N' },
    { word: 'deviate', word_class: 'N' },
    { word: 'deviate', word_class: 'V' },
    { word: 'deviance', word_class: 'N' },
    { word: 'deviant', word_class: 'AJ' },
    { word: 'deviancy', word_class: 'N' },
    { word: 'deviation', word_class: 'N' }
  ],
  decolorize: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  indolence: [
    { word: 'indolence', word_class: 'N' },
    { word: 'indolent', word_class: 'AJ' },
    { word: 'indolently', word_class: 'AV' }
  ],
  specialization: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  apocopate: [
    { word: 'apocope', word_class: 'N' },
    { word: 'apocopate', word_class: 'V' },
    { word: 'apocopation', word_class: 'N' }
  ],
  immoderate: [
    { word: 'immoderate', word_class: 'AJ' },
    { word: 'immoderation', word_class: 'N' },
    { word: 'immoderately', word_class: 'AV' },
    { word: 'immoderateness', word_class: 'N' }
  ],
  rustling: [
    { word: 'rustle', word_class: 'N' },
    { word: 'rustle', word_class: 'V' },
    { word: 'rustler', word_class: 'N' },
    { word: 'rustling', word_class: 'N' },
    { word: 'rustling', word_class: 'AJ' }
  ],
  adventurousness: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  incubation: [
    { word: 'incubate', word_class: 'V' },
    { word: 'incubator', word_class: 'N' },
    { word: 'incubation', word_class: 'N' }
  ],
  disabuse: [
    { word: 'disabuse', word_class: 'N' },
    { word: 'disabuse', word_class: 'V' },
    { word: 'disabusal', word_class: 'N' },
    { word: 'disabused', word_class: 'AJ' }
  ],
  incorruptible: [
    { word: 'incorrupt', word_class: 'AJ' },
    { word: 'incorruption', word_class: 'N' },
    { word: 'incorruptness', word_class: 'N' },
    { word: 'incorruptible', word_class: 'AJ' },
    { word: 'incorruptibility', word_class: 'N' }
  ],
  trek: [
    { word: 'trek', word_class: 'N' },
    { word: 'trek', word_class: 'V' },
    { word: 'trekked', word_class: 'AJ' },
    { word: 'trekking', word_class: 'AJ' }
  ],
  hospitality: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  rattled: [
    { word: 'rattle', word_class: 'N' },
    { word: 'rattle', word_class: 'V' },
    { word: 'rattly', word_class: 'AJ' },
    { word: 'rattler', word_class: 'N' },
    { word: 'rattled', word_class: 'AJ' },
    { word: 'rattling', word_class: 'N' },
    { word: 'rattling', word_class: 'AJ' },
    { word: 'rattling', word_class: 'AV' }
  ],
  dozer: [
    { word: 'doze', word_class: 'N' },
    { word: 'doze', word_class: 'V' },
    { word: 'dozer', word_class: 'N' },
    { word: 'dozing', word_class: 'AJ' }
  ],
  tap: [
    { word: 'tap', word_class: 'N' },
    { word: 'tap', word_class: 'V' },
    { word: 'taps', word_class: 'N' },
    { word: 'tapped', word_class: 'AJ' },
    { word: 'tapping', word_class: 'N' }
  ],
  spotted: [
    { word: 'spot', word_class: 'N' },
    { word: 'spot', word_class: 'V' },
    { word: 'spotted', word_class: 'AJ' },
    { word: 'spotting', word_class: 'N' }
  ],
  allotment: [
    { word: 'allot', word_class: 'V' },
    { word: 'allotment', word_class: 'N' },
    { word: 'allotted', word_class: 'AJ' }
  ],
  logarithm: [
    { word: 'logarithm', word_class: 'N' },
    { word: 'logarithmic', word_class: 'AJ' },
    { word: 'logarithmically', word_class: 'AV' }
  ],
  flotation: [
    { word: 'float', word_class: 'N' },
    { word: 'float', word_class: 'V' },
    { word: 'floater', word_class: 'N' },
    { word: 'floating', word_class: 'AJ' },
    { word: 'flotation', word_class: 'N' },
    { word: 'floatation', word_class: 'N' }
  ],
  flowing: [
    { word: 'flow', word_class: 'N' },
    { word: 'flow', word_class: 'V' },
    { word: 'flowing', word_class: 'N' },
    { word: 'flowing', word_class: 'AJ' }
  ],
  sulfur: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  ruralize: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  calibre: [
    { word: 'caliber', word_class: 'N' },
    { word: 'calibre', word_class: 'N' },
    { word: 'calibrate', word_class: 'V' },
    { word: 'calibration', word_class: 'N' }
  ],
  sing: [
    { word: 'sing', word_class: 'N' },
    { word: 'sing', word_class: 'V' },
    { word: 'singe', word_class: 'N' },
    { word: 'singe', word_class: 'V' },
    { word: 'singer', word_class: 'N' },
    { word: 'singing', word_class: 'N' },
    { word: 'singing', word_class: 'AJ' }
  ],
  impartiality: [
    { word: 'impartial', word_class: 'AJ' },
    { word: 'impartiality', word_class: 'N' },
    { word: 'impartially', word_class: 'AV' }
  ],
  creamer: [
    { word: 'cream', word_class: 'N' },
    { word: 'cream', word_class: 'V' },
    { word: 'cream', word_class: 'AJ' },
    { word: 'creamer', word_class: 'N' }
  ],
  cementite: [
    { word: 'cement', word_class: 'N' },
    { word: 'cement', word_class: 'V' },
    { word: 'cementite', word_class: 'N' },
    { word: 'cementation', word_class: 'N' }
  ],
  awn: [{ word: 'awn', word_class: 'N' }, { word: 'awned', word_class: 'AJ' }, { word: 'awning', word_class: 'N' }],
  surfboarder: [
    { word: 'surfboard', word_class: 'N' },
    { word: 'surfboarder', word_class: 'N' },
    { word: 'surfboarding', word_class: 'N' }
  ],
  bestial: [
    { word: 'beast', word_class: 'N' },
    { word: 'bestial', word_class: 'AJ' },
    { word: 'bestiality', word_class: 'N' },
    { word: 'bestialize', word_class: 'V' },
    { word: 'bestially', word_class: 'AV' },
    { word: 'bestialization', word_class: 'N' }
  ],
  violated: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  crowd: [
    { word: 'crowd', word_class: 'N' },
    { word: 'crowd', word_class: 'V' },
    { word: 'crowded', word_class: 'AJ' },
    { word: 'crowding', word_class: 'N' }
  ],
  sterilization: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  mullioned: [
    { word: 'mull', word_class: 'N' },
    { word: 'mull', word_class: 'V' },
    { word: 'mullion', word_class: 'N' },
    { word: 'mullioned', word_class: 'AJ' }
  ],
  dower: [
    { word: 'dower', word_class: 'N' },
    { word: 'dower', word_class: 'V' },
    { word: 'dowered', word_class: 'AJ' }
  ],
  colloquy: [
    { word: 'colloquy', word_class: 'N' },
    { word: 'colloquial', word_class: 'AJ' },
    { word: 'colloquialism', word_class: 'N' },
    { word: 'colloquially', word_class: 'AV' }
  ],
  communally: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  hymn: [
    { word: 'hymn', word_class: 'N' },
    { word: 'hymn', word_class: 'V' },
    { word: 'hymnal', word_class: 'N' },
    { word: 'hymnal', word_class: 'AJ' }
  ],
  plaintive: [
    { word: 'plaintive', word_class: 'AJ' },
    { word: 'plaintively', word_class: 'AV' },
    { word: 'plaintiveness', word_class: 'N' }
  ],
  practiced: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  glamorize: [
    { word: 'glamor', word_class: 'N' },
    { word: 'glamorize', word_class: 'V' },
    { word: 'glamorous', word_class: 'AJ' },
    { word: 'glamorisation', word_class: 'N' },
    { word: 'glamorization', word_class: 'N' }
  ],
  motivation: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  supply: [
    { word: 'supply', word_class: 'N' },
    { word: 'supply', word_class: 'V' },
    { word: 'supplier', word_class: 'N' },
    { word: 'supplies', word_class: 'N' },
    { word: 'suppliant', word_class: 'N' },
    { word: 'supplying', word_class: 'N' },
    { word: 'suppliant', word_class: 'AJ' }
  ],
  transcript: [
    { word: 'transcribe', word_class: 'V' },
    { word: 'transcript', word_class: 'N' },
    { word: 'transcribed', word_class: 'AJ' },
    { word: 'transcription', word_class: 'N' }
  ],
  polymerise: [
    { word: 'polymeric', word_class: 'AJ' },
    { word: 'polymerise', word_class: 'V' },
    { word: 'polymerize', word_class: 'V' },
    { word: 'polymerisation', word_class: 'N' },
    { word: 'polymerization', word_class: 'N' }
  ],
  disenchanted: [
    { word: 'disenchant', word_class: 'V' },
    { word: 'disenchanted', word_class: 'AJ' },
    { word: 'disenchanting', word_class: 'AJ' },
    { word: 'disenchantment', word_class: 'N' }
  ],
  politician: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  hit: [{ word: 'hit', word_class: 'N' }, { word: 'hit', word_class: 'V' }, { word: 'hitting', word_class: 'N' }],
  lobby: [
    { word: 'lobby', word_class: 'N' },
    { word: 'lobby', word_class: 'V' },
    { word: 'lobbyist', word_class: 'N' }
  ],
  leanness: [
    { word: 'lean', word_class: 'N' },
    { word: 'lean', word_class: 'V' },
    { word: 'lean', word_class: 'AJ' },
    { word: 'leaning', word_class: 'N' },
    { word: 'leaning', word_class: 'AJ' },
    { word: 'leanness', word_class: 'N' }
  ],
  juvenile: [
    { word: 'juvenile', word_class: 'N' },
    { word: 'juvenile', word_class: 'AJ' },
    { word: 'juvenility', word_class: 'N' }
  ],
  mellowing: [
    { word: 'mellow', word_class: 'V' },
    { word: 'mellow', word_class: 'AJ' },
    { word: 'mellow', word_class: 'AV' },
    { word: 'mellowed', word_class: 'AJ' },
    { word: 'mellowing', word_class: 'N' },
    { word: 'mellowing', word_class: 'AJ' },
    { word: 'mellowness', word_class: 'N' }
  ],
  reproducer: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  recriminative: [
    { word: 'recriminate', word_class: 'V' },
    { word: 'recrimination', word_class: 'N' },
    { word: 'recriminative', word_class: 'AJ' }
  ],
  dwindle: [
    { word: 'dwindle', word_class: 'V' },
    { word: 'dwindling', word_class: 'N' },
    { word: 'dwindling', word_class: 'AJ' }
  ],
  try: [
    { word: 'try', word_class: 'N' },
    { word: 'try', word_class: 'V' },
    { word: 'trial', word_class: 'N' },
    { word: 'trier', word_class: 'N' },
    { word: 'tryst', word_class: 'N' },
    { word: 'trial', word_class: 'AJ' },
    { word: 'trying', word_class: 'AJ' }
  ],
  obtrude: [
    { word: 'obtrude', word_class: 'V' },
    { word: 'obtrusion', word_class: 'N' },
    { word: 'obtrusive', word_class: 'AJ' },
    { word: 'obtrusively', word_class: 'AV' },
    { word: 'obtrusiveness', word_class: 'N' }
  ],
  whelp: [
    { word: 'whelp', word_class: 'N' },
    { word: 'whelp', word_class: 'V' },
    { word: 'whelped', word_class: 'AJ' }
  ],
  vulcanization: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  cant: [
    { word: 'cant', word_class: 'N' },
    { word: 'cant', word_class: 'V' },
    { word: 'cant', word_class: 'AJ' },
    { word: 'cantor', word_class: 'N' },
    { word: 'canted', word_class: 'AJ' }
  ],
  penitent: [
    { word: 'penitent', word_class: 'N' },
    { word: 'penitence', word_class: 'N' },
    { word: 'penitent', word_class: 'AJ' },
    { word: 'penitently', word_class: 'AV' },
    { word: 'penitential', word_class: 'AJ' },
    { word: 'penitentially', word_class: 'AV' }
  ],
  habitue: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  tact: [
    { word: 'tact', word_class: 'N' },
    { word: 'tactful', word_class: 'AJ' },
    { word: 'tactfulness', word_class: 'N' }
  ],
  coaching: [
    { word: 'coach', word_class: 'N' },
    { word: 'coach', word_class: 'V' },
    { word: 'coaching', word_class: 'N' },
    { word: 'coachman', word_class: 'N' }
  ],
  remove: [
    { word: 'remove', word_class: 'N' },
    { word: 'remove', word_class: 'V' },
    { word: 'removal', word_class: 'N' },
    { word: 'remover', word_class: 'N' },
    { word: 'removed', word_class: 'AJ' },
    { word: 'removable', word_class: 'AJ' }
  ],
  languorous: [
    { word: 'languor', word_class: 'N' },
    { word: 'languorous', word_class: 'AJ' },
    { word: 'languorously', word_class: 'AV' }
  ],
  diameter: [
    { word: 'diameter', word_class: 'N' },
    { word: 'diametral', word_class: 'AJ' },
    { word: 'diametric', word_class: 'AJ' },
    { word: 'diametrical', word_class: 'AJ' },
    { word: 'diametrically', word_class: 'AV' }
  ],
  weaponed: [
    { word: 'weapon', word_class: 'N' },
    { word: 'weaponry', word_class: 'N' },
    { word: 'weaponed', word_class: 'AJ' }
  ],
  shatter: [
    { word: 'shatter', word_class: 'V' },
    { word: 'shattered', word_class: 'AJ' },
    { word: 'shattering', word_class: 'N' },
    { word: 'shattering', word_class: 'AJ' }
  ],
  dismissal: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  applicability: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  stammerer: [
    { word: 'stammer', word_class: 'N' },
    { word: 'stammer', word_class: 'V' },
    { word: 'stammerer', word_class: 'N' },
    { word: 'stammering', word_class: 'AJ' }
  ],
  occupancy: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  legislature: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  ordinal: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  solution: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  limit: [
    { word: 'limit', word_class: 'N' },
    { word: 'limit', word_class: 'V' },
    { word: 'limiter', word_class: 'N' },
    { word: 'limited', word_class: 'AJ' },
    { word: 'limiting', word_class: 'N' },
    { word: 'limiting', word_class: 'AJ' },
    { word: 'limitation', word_class: 'N' }
  ],
  exculpated: [
    { word: 'exculpate', word_class: 'V' },
    { word: 'exculpated', word_class: 'AJ' },
    { word: 'exculpation', word_class: 'N' }
  ],
  coincidental: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  metabolize: [
    { word: 'metabolic', word_class: 'AJ' },
    { word: 'metabolism', word_class: 'N' },
    { word: 'metabolize', word_class: 'V' },
    { word: 'metabolous', word_class: 'AJ' },
    { word: 'metabolization', word_class: 'N' }
  ],
  generic: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  pharynx: [
    { word: 'pharynx', word_class: 'N' },
    { word: 'pharyngeal', word_class: 'AJ' },
    { word: 'pharyngitis', word_class: 'N' }
  ],
  designation: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  frostbitten: [
    { word: 'frostbite', word_class: 'N' },
    { word: 'frostbite', word_class: 'V' },
    { word: 'frostbitten', word_class: 'AJ' }
  ],
  dangerously: [
    { word: 'danger', word_class: 'N' },
    { word: 'dangerous', word_class: 'AJ' },
    { word: 'dangerously', word_class: 'AV' },
    { word: 'dangerousness', word_class: 'N' }
  ],
  stress: [
    { word: 'stress', word_class: 'N' },
    { word: 'stress', word_class: 'V' },
    { word: 'stressed', word_class: 'AJ' },
    { word: 'stressful', word_class: 'AJ' }
  ],
  rivet: [
    { word: 'rivet', word_class: 'N' },
    { word: 'rivet', word_class: 'V' },
    { word: 'riveter', word_class: 'N' },
    { word: 'riveting', word_class: 'AJ' }
  ],
  bouncer: [
    { word: 'bounce', word_class: 'N' },
    { word: 'bounce', word_class: 'V' },
    { word: 'bouncy', word_class: 'AJ' },
    { word: 'bouncer', word_class: 'N' },
    { word: 'bouncing', word_class: 'N' },
    { word: 'bouncing', word_class: 'AJ' },
    { word: 'bounciness', word_class: 'N' }
  ],
  adored: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  homophonous: [
    { word: 'homophone', word_class: 'N' },
    { word: 'homophonic', word_class: 'AJ' },
    { word: 'homophonous', word_class: 'AJ' }
  ],
  excise: [
    { word: 'excide', word_class: 'V' },
    { word: 'excise', word_class: 'N' },
    { word: 'excise', word_class: 'V' },
    { word: 'excision', word_class: 'N' },
    { word: 'exciseman', word_class: 'N' }
  ],
  scandalisation: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  topographic: [
    { word: 'topographic', word_class: 'AJ' },
    { word: 'topographical', word_class: 'AJ' },
    { word: 'topographically', word_class: 'AV' }
  ],
  primitiveness: [
    { word: 'primitive', word_class: 'N' },
    { word: 'primitive', word_class: 'AJ' },
    { word: 'primitively', word_class: 'AV' },
    { word: 'primitiveness', word_class: 'N' }
  ],
  stammer: [
    { word: 'stammer', word_class: 'N' },
    { word: 'stammer', word_class: 'V' },
    { word: 'stammerer', word_class: 'N' },
    { word: 'stammering', word_class: 'AJ' }
  ],
  bulldog: [
    { word: 'bulldog', word_class: 'N' },
    { word: 'bulldog', word_class: 'V' },
    { word: 'bulldog', word_class: 'AJ' }
  ],
  westwards: [
    { word: 'westward', word_class: 'AJ' },
    { word: 'westward', word_class: 'AV' },
    { word: 'westwards', word_class: 'AV' }
  ],
  magnetically: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  remonetize: [
    { word: 'remonetise', word_class: 'V' },
    { word: 'remonetize', word_class: 'V' },
    { word: 'remonetisation', word_class: 'N' },
    { word: 'remonetization', word_class: 'N' }
  ],
  devolutionary: [
    { word: 'devolve', word_class: 'V' },
    { word: 'devolution', word_class: 'N' },
    { word: 'devolvement', word_class: 'N' },
    { word: 'devolutionary', word_class: 'AJ' }
  ],
  consist: [
    { word: 'consist', word_class: 'V' },
    { word: 'consistence', word_class: 'N' },
    { word: 'consistency', word_class: 'N' },
    { word: 'consistent', word_class: 'AJ' },
    { word: 'consistently', word_class: 'AV' }
  ],
  perceptible: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  overcapitalization: [
    { word: 'overcapitalise', word_class: 'V' },
    { word: 'overcapitalize', word_class: 'V' },
    { word: 'overcapitalisation', word_class: 'N' },
    { word: 'overcapitalization', word_class: 'N' }
  ],
  ranking: [
    { word: 'rank', word_class: 'N' },
    { word: 'rank', word_class: 'V' },
    { word: 'rank', word_class: 'AJ' },
    { word: 'ranker', word_class: 'N' },
    { word: 'ranked', word_class: 'AJ' },
    { word: 'ranking', word_class: 'N' },
    { word: 'ranking', word_class: 'AJ' },
    { word: 'rankness', word_class: 'N' }
  ],
  law: [
    { word: 'law', word_class: 'N' },
    { word: 'laws', word_class: 'N' },
    { word: 'lawful', word_class: 'AJ' },
    { word: 'lawfulness', word_class: 'N' }
  ],
  eye: [
    { word: 'eye', word_class: 'N' },
    { word: 'eye', word_class: 'V' },
    { word: 'eyes', word_class: 'N' },
    { word: 'eyed', word_class: 'AJ' },
    { word: 'eyeful', word_class: 'N' },
    { word: 'eyeful', word_class: 'AJ' }
  ],
  pastis: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  revolutionary: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  commonsensical: [
    { word: 'commonsense', word_class: 'AJ' },
    { word: 'commonsensible', word_class: 'AJ' },
    { word: 'commonsensical', word_class: 'AJ' }
  ],
  traducer: [
    { word: 'traduce', word_class: 'V' },
    { word: 'traducer', word_class: 'N' },
    { word: 'traduction', word_class: 'N' },
    { word: 'traducement', word_class: 'N' }
  ],
  repentance: [
    { word: 'repent', word_class: 'V' },
    { word: 'repent', word_class: 'AJ' },
    { word: 'repentant', word_class: 'N' },
    { word: 'repentance', word_class: 'N' },
    { word: 'repentant', word_class: 'AJ' }
  ],
  discovery: [
    { word: 'discover', word_class: 'N' },
    { word: 'discover', word_class: 'V' },
    { word: 'discovery', word_class: 'N' },
    { word: 'discoverer', word_class: 'N' },
    { word: 'discovery', word_class: 'AJ' },
    { word: 'discovered', word_class: 'AJ' },
    { word: 'discoverable', word_class: 'AJ' }
  ],
  contraceptive: [
    { word: 'contraception', word_class: 'N' },
    { word: 'contraceptive', word_class: 'N' },
    { word: 'contraceptive', word_class: 'AJ' }
  ],
  eyed: [
    { word: 'eye', word_class: 'N' },
    { word: 'eye', word_class: 'V' },
    { word: 'eyes', word_class: 'N' },
    { word: 'eyed', word_class: 'AJ' },
    { word: 'eyeful', word_class: 'N' },
    { word: 'eyeful', word_class: 'AJ' }
  ],
  intense: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  fry: [
    { word: 'fry', word_class: 'N' },
    { word: 'fry', word_class: 'V' },
    { word: 'frier', word_class: 'N' },
    { word: 'fryer', word_class: 'N' },
    { word: 'frying', word_class: 'N' }
  ],
  excerpt: [
    { word: 'excerpt', word_class: 'N' },
    { word: 'excerpt', word_class: 'V' },
    { word: 'excerption', word_class: 'N' }
  ],
  rattly: [
    { word: 'rattle', word_class: 'N' },
    { word: 'rattle', word_class: 'V' },
    { word: 'rattly', word_class: 'AJ' },
    { word: 'rattler', word_class: 'N' },
    { word: 'rattled', word_class: 'AJ' },
    { word: 'rattling', word_class: 'N' },
    { word: 'rattling', word_class: 'AJ' },
    { word: 'rattling', word_class: 'AV' }
  ],
  jubilation: [
    { word: 'jubilate', word_class: 'V' },
    { word: 'jubilance', word_class: 'N' },
    { word: 'jubilancy', word_class: 'N' },
    { word: 'jubilant', word_class: 'AJ' },
    { word: 'jubilation', word_class: 'N' }
  ],
  submarine: [
    { word: 'submarine', word_class: 'N' },
    { word: 'submarine', word_class: 'AJ' },
    { word: 'submariner', word_class: 'N' }
  ],
  royal: [
    { word: 'royal', word_class: 'N' },
    { word: 'royal', word_class: 'AJ' },
    { word: 'royalty', word_class: 'N' },
    { word: 'royally', word_class: 'AV' }
  ],
  fanaticization: [
    { word: 'fanaticism', word_class: 'N' },
    { word: 'fanaticize', word_class: 'V' },
    { word: 'fanaticization', word_class: 'N' }
  ],
  choreography: [
    { word: 'choreograph', word_class: 'V' },
    { word: 'choreography', word_class: 'N' },
    { word: 'choreographer', word_class: 'N' },
    { word: 'choreographic', word_class: 'AJ' }
  ],
  outcast: [
    { word: 'outcast', word_class: 'N' },
    { word: 'outcast', word_class: 'AJ' },
    { word: 'outcaste', word_class: 'N' },
    { word: 'outcaste', word_class: 'AJ' }
  ],
  disorganize: [
    { word: 'disorganize', word_class: 'V' },
    { word: 'disorganized', word_class: 'AJ' },
    { word: 'disorganisation', word_class: 'N' },
    { word: 'disorganization', word_class: 'N' }
  ],
  grounding: [
    { word: 'ground', word_class: 'N' },
    { word: 'ground', word_class: 'V' },
    { word: 'ground', word_class: 'AJ' },
    { word: 'grounds', word_class: 'N' },
    { word: 'grounder', word_class: 'N' },
    { word: 'grounding', word_class: 'N' }
  ],
  irritably: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  stereotyped: [
    { word: 'stereotype', word_class: 'N' },
    { word: 'stereotype', word_class: 'V' },
    { word: 'stereotyped', word_class: 'AJ' },
    { word: 'stereotypic', word_class: 'AJ' },
    { word: 'stereotypical', word_class: 'AJ' }
  ],
  tetanise: [
    { word: 'tetanise', word_class: 'V' },
    { word: 'tetanize', word_class: 'V' },
    { word: 'tetanisation', word_class: 'N' },
    { word: 'tetanization', word_class: 'N' }
  ],
  despicable: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  rhapsodise: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  strike: [
    { word: 'strike', word_class: 'N' },
    { word: 'strike', word_class: 'V' },
    { word: 'striker', word_class: 'N' },
    { word: 'striking', word_class: 'N' },
    { word: 'stricken', word_class: 'AJ' },
    { word: 'striking', word_class: 'AJ' }
  ],
  escalation: [
    { word: 'escalate', word_class: 'V' },
    { word: 'escalator', word_class: 'N' },
    { word: 'escalation', word_class: 'N' }
  ],
  join: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  spiky: [
    { word: 'spike', word_class: 'N' },
    { word: 'spike', word_class: 'V' },
    { word: 'spiky', word_class: 'AJ' },
    { word: 'spiked', word_class: 'AJ' }
  ],
  internalize: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  surefooted: [
    { word: 'surefooted', word_class: 'AJ' },
    { word: 'surefootedly', word_class: 'AV' },
    { word: 'surefootedness', word_class: 'N' }
  ],
  oxidization: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  chains: [
    { word: 'chain', word_class: 'N' },
    { word: 'chain', word_class: 'V' },
    { word: 'chains', word_class: 'N' },
    { word: 'chained', word_class: 'AJ' }
  ],
  want: [
    { word: 'want', word_class: 'N' },
    { word: 'want', word_class: 'V' },
    { word: 'wanted', word_class: 'AJ' },
    { word: 'wanting', word_class: 'AJ' }
  ],
  berried: [
    { word: 'berry', word_class: 'N' },
    { word: 'berry', word_class: 'V' },
    { word: 'berried', word_class: 'AJ' }
  ],
  expressive: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  confess: [
    { word: 'confess', word_class: 'V' },
    { word: 'confessor', word_class: 'N' },
    { word: 'confessed', word_class: 'AJ' },
    { word: 'confession', word_class: 'N' }
  ],
  desistence: [
    { word: 'desist', word_class: 'V' },
    { word: 'desistance', word_class: 'N' },
    { word: 'desistence', word_class: 'N' }
  ],
  spirituality: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  idolator: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  stylistic: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  enrol: [
    { word: 'enrol', word_class: 'V' },
    { word: 'enroll', word_class: 'V' },
    { word: 'enrolled', word_class: 'AJ' },
    { word: 'enrolment', word_class: 'N' },
    { word: 'enrollment', word_class: 'N' }
  ],
  orientating: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  painted: [
    { word: 'paint', word_class: 'N' },
    { word: 'paint', word_class: 'V' },
    { word: 'painter', word_class: 'N' },
    { word: 'painted', word_class: 'AJ' },
    { word: 'painting', word_class: 'N' }
  ],
  dehydration: [
    { word: 'dehydrate', word_class: 'V' },
    { word: 'dehydrated', word_class: 'AJ' },
    { word: 'dehydration', word_class: 'N' }
  ],
  apotheosization: [
    { word: 'apotheose', word_class: 'V' },
    { word: 'apotheosize', word_class: 'V' },
    { word: 'apotheosization', word_class: 'N' }
  ],
  square: [
    { word: 'square', word_class: 'N' },
    { word: 'square', word_class: 'V' },
    { word: 'square', word_class: 'AJ' },
    { word: 'square', word_class: 'AV' },
    { word: 'squared', word_class: 'AJ' },
    { word: 'squarely', word_class: 'AV' },
    { word: 'squareness', word_class: 'N' }
  ],
  randomized: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  narcotizing: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  vandal: [
    { word: 'vandal', word_class: 'N' },
    { word: 'vandalise', word_class: 'V' },
    { word: 'vandalism', word_class: 'N' },
    { word: 'vandalize', word_class: 'V' },
    { word: 'vandalization', word_class: 'N' }
  ],
  cogged: [{ word: 'cog', word_class: 'N' }, { word: 'cog', word_class: 'V' }, { word: 'cogged', word_class: 'AJ' }],
  gelatinous: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  muddy: [
    { word: 'mud', word_class: 'N' },
    { word: 'mud', word_class: 'V' },
    { word: 'muddy', word_class: 'V' },
    { word: 'muddy', word_class: 'AJ' },
    { word: 'muddied', word_class: 'AJ' },
    { word: 'muddiness', word_class: 'N' }
  ],
  gradation: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  obduracy: [
    { word: 'obduracy', word_class: 'N' },
    { word: 'obdurate', word_class: 'AJ' },
    { word: 'obdurately', word_class: 'AV' }
  ],
  abstainer: [
    { word: 'abstain', word_class: 'V' },
    { word: 'abstainer', word_class: 'N' },
    { word: 'abstinent', word_class: 'N' },
    { word: 'abstention', word_class: 'N' },
    { word: 'abstinence', word_class: 'N' },
    { word: 'abstinent', word_class: 'AJ' }
  ],
  equaliser: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  etherise: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  talking: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  toter: [{ word: 'tote', word_class: 'N' }, { word: 'tote', word_class: 'V' }, { word: 'toter', word_class: 'N' }],
  castrated: [
    { word: 'castrate', word_class: 'N' },
    { word: 'castrate', word_class: 'V' },
    { word: 'castrated', word_class: 'AJ' },
    { word: 'castration', word_class: 'N' }
  ],
  successor: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  purchasing: [
    { word: 'purchase', word_class: 'N' },
    { word: 'purchase', word_class: 'V' },
    { word: 'purchaser', word_class: 'N' },
    { word: 'purchasing', word_class: 'N' },
    { word: 'purchasable', word_class: 'AJ' }
  ],
  boomer: [
    { word: 'boom', word_class: 'N' },
    { word: 'boom', word_class: 'V' },
    { word: 'boomer', word_class: 'N' },
    { word: 'booming', word_class: 'AJ' }
  ],
  unchanged: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  reconcile: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  anticipant: [
    { word: 'anticipate', word_class: 'V' },
    { word: 'anticipant', word_class: 'AJ' },
    { word: 'anticipated', word_class: 'AJ' },
    { word: 'anticipation', word_class: 'N' },
    { word: 'anticipative', word_class: 'AJ' }
  ],
  happen: [
    { word: 'happen', word_class: 'V' },
    { word: 'happening', word_class: 'N' },
    { word: 'happening', word_class: 'AJ' }
  ],
  obscenity: [
    { word: 'obscene', word_class: 'AJ' },
    { word: 'obscenity', word_class: 'N' },
    { word: 'obscenely', word_class: 'AV' }
  ],
  falter: [
    { word: 'falter', word_class: 'N' },
    { word: 'falter', word_class: 'V' },
    { word: 'faltering', word_class: 'N' },
    { word: 'faltering', word_class: 'AJ' }
  ],
  blithe: [
    { word: 'blithe', word_class: 'AJ' },
    { word: 'blithely', word_class: 'AV' },
    { word: 'blitheness', word_class: 'N' }
  ],
  methylation: [
    { word: 'methyl', word_class: 'N' },
    { word: 'methylate', word_class: 'V' },
    { word: 'methylated', word_class: 'AJ' },
    { word: 'methylation', word_class: 'N' }
  ],
  working: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  laterality: [
    { word: 'later', word_class: 'AJ' },
    { word: 'later', word_class: 'AV' },
    { word: 'lateral', word_class: 'N' },
    { word: 'lateral', word_class: 'AJ' },
    { word: 'laterality', word_class: 'N' },
    { word: 'laterally', word_class: 'AV' }
  ],
  disjoined: [
    { word: 'disjoin', word_class: 'V' },
    { word: 'disjunct', word_class: 'N' },
    { word: 'disjunct', word_class: 'AJ' },
    { word: 'disjoined', word_class: 'AJ' },
    { word: 'disjunction', word_class: 'N' },
    { word: 'disjunctive', word_class: 'AJ' }
  ],
  mentalism: [
    { word: 'mental', word_class: 'AJ' },
    { word: 'mentalism', word_class: 'N' },
    { word: 'mentality', word_class: 'N' },
    { word: 'mentally', word_class: 'AV' }
  ],
  misprise: [
    { word: 'misprise', word_class: 'V' },
    { word: 'misprize', word_class: 'V' },
    { word: 'misprision', word_class: 'N' }
  ],
  lithography: [
    { word: 'lithograph', word_class: 'N' },
    { word: 'lithograph', word_class: 'V' },
    { word: 'lithography', word_class: 'N' },
    { word: 'lithographer', word_class: 'N' },
    { word: 'lithography', word_class: 'AJ' },
    { word: 'lithographic', word_class: 'AJ' }
  ],
  unfortunately: [
    { word: 'unfortunate', word_class: 'N' },
    { word: 'unfortunate', word_class: 'AJ' },
    { word: 'unfortunately', word_class: 'AV' }
  ],
  overhaul: [
    { word: 'overhaul', word_class: 'N' },
    { word: 'overhaul', word_class: 'V' },
    { word: 'overhauling', word_class: 'N' }
  ],
  decided: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  congregation: [
    { word: 'congregate', word_class: 'V' },
    { word: 'congregate', word_class: 'AJ' },
    { word: 'congregating', word_class: 'N' },
    { word: 'congregation', word_class: 'N' },
    { word: 'congregational', word_class: 'AJ' }
  ],
  assessable: [
    { word: 'assess', word_class: 'N' },
    { word: 'assess', word_class: 'V' },
    { word: 'assessor', word_class: 'N' },
    { word: 'assessment', word_class: 'N' },
    { word: 'assessable', word_class: 'AJ' }
  ],
  reticulated: [
    { word: 'reticule', word_class: 'N' },
    { word: 'reticulate', word_class: 'V' },
    { word: 'reticulate', word_class: 'AJ' },
    { word: 'reticulated', word_class: 'AJ' },
    { word: 'reticulation', word_class: 'N' }
  ],
  abettal: [
    { word: 'abet', word_class: 'V' },
    { word: 'abettal', word_class: 'N' },
    { word: 'abetter', word_class: 'N' },
    { word: 'abetment', word_class: 'N' }
  ],
  simplification: [
    { word: 'simplify', word_class: 'V' },
    { word: 'simplified', word_class: 'AJ' },
    { word: 'simplification', word_class: 'N' }
  ],
  river: [
    { word: 'rive', word_class: 'V' },
    { word: 'river', word_class: 'N' },
    { word: 'riverine', word_class: 'AJ' }
  ],
  cerebration: [
    { word: 'cerebral', word_class: 'AJ' },
    { word: 'cerebrate', word_class: 'V' },
    { word: 'cerebrally', word_class: 'AV' },
    { word: 'cerebration', word_class: 'N' }
  ],
  invited: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  reasonableness: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  accoutered: [
    { word: 'accouter', word_class: 'V' },
    { word: 'accoutered', word_class: 'AJ' },
    { word: 'accouterment', word_class: 'N' }
  ],
  connective: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  indict: [
    { word: 'indict', word_class: 'V' },
    { word: 'indiction', word_class: 'N' },
    { word: 'indictment', word_class: 'N' },
    { word: 'indictable', word_class: 'AJ' },
    { word: 'indictability', word_class: 'N' }
  ],
  essentialness: [
    { word: 'essential', word_class: 'N' },
    { word: 'essential', word_class: 'AJ' },
    { word: 'essentiality', word_class: 'N' },
    { word: 'essentially', word_class: 'AV' },
    { word: 'essentialness', word_class: 'N' }
  ],
  syllabication: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  disseverance: [
    { word: 'dissever', word_class: 'V' },
    { word: 'disseverance', word_class: 'N' },
    { word: 'disseverment', word_class: 'N' },
    { word: 'disseveration', word_class: 'N' }
  ],
  semiotic: [
    { word: 'semiotic', word_class: 'AJ' },
    { word: 'semiotics', word_class: 'N' },
    { word: 'semiotical', word_class: 'AJ' }
  ],
  bejeweled: [
    { word: 'bejewel', word_class: 'V' },
    { word: 'bejeweled', word_class: 'AJ' },
    { word: 'bejewelled', word_class: 'AJ' }
  ],
  edging: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  well: [
    { word: 'well', word_class: 'N' },
    { word: 'well', word_class: 'V' },
    { word: 'well', word_class: 'AJ' },
    { word: 'well', word_class: 'AV' }
  ],
  stolid: [
    { word: 'stolid', word_class: 'AJ' },
    { word: 'stolidity', word_class: 'N' },
    { word: 'stolidness', word_class: 'N' }
  ],
  pan: [
    { word: 'pan', word_class: 'N' },
    { word: 'pan', word_class: 'V' },
    { word: 'panful', word_class: 'N' },
    { word: 'panful', word_class: 'AJ' }
  ],
  marcher: [
    { word: 'march', word_class: 'N' },
    { word: 'march', word_class: 'V' },
    { word: 'marche', word_class: 'N' },
    { word: 'marcher', word_class: 'N' },
    { word: 'marches', word_class: 'N' },
    { word: 'marching', word_class: 'N' },
    { word: 'marching', word_class: 'AJ' }
  ],
  twist: [
    { word: 'twist', word_class: 'N' },
    { word: 'twist', word_class: 'V' },
    { word: 'twister', word_class: 'N' },
    { word: 'twisted', word_class: 'AJ' },
    { word: 'twisting', word_class: 'N' },
    { word: 'twisting', word_class: 'AJ' }
  ],
  chastisement: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  landholding: [
    { word: 'landholder', word_class: 'N' },
    { word: 'landholding', word_class: 'N' },
    { word: 'landholding', word_class: 'AJ' }
  ],
  lacrimation: [
    { word: 'lacrimal', word_class: 'AJ' },
    { word: 'lacrimator', word_class: 'N' },
    { word: 'lacrimation', word_class: 'N' }
  ],
  snort: [
    { word: 'snort', word_class: 'N' },
    { word: 'snort', word_class: 'V' },
    { word: 'snorter', word_class: 'N' },
    { word: 'snorting', word_class: 'N' },
    { word: 'snorting', word_class: 'AJ' }
  ],
  ergot: [
    { word: 'ergot', word_class: 'N' },
    { word: 'ergotic', word_class: 'AJ' },
    { word: 'ergotism', word_class: 'N' }
  ],
  thatch: [
    { word: 'thatch', word_class: 'N' },
    { word: 'thatch', word_class: 'V' },
    { word: 'thatcher', word_class: 'N' }
  ],
  supernaturalism: [
    { word: 'supernatural', word_class: 'N' },
    { word: 'supernatural', word_class: 'AJ' },
    { word: 'supernaturalism', word_class: 'N' },
    { word: 'supernaturally', word_class: 'AV' },
    { word: 'supernaturalness', word_class: 'N' }
  ],
  emotion: [
    { word: 'emote', word_class: 'V' },
    { word: 'emotion', word_class: 'N' },
    { word: 'emotive', word_class: 'AJ' },
    { word: 'emotional', word_class: 'AJ' }
  ],
  hyphenize: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  encyst: [
    { word: 'encyst', word_class: 'V' },
    { word: 'encysted', word_class: 'AJ' },
    { word: 'encystment', word_class: 'N' },
    { word: 'encystation', word_class: 'N' }
  ],
  hard: [
    { word: 'hard', word_class: 'AJ' },
    { word: 'hard', word_class: 'AV' },
    { word: 'harding', word_class: 'N' },
    { word: 'hardness', word_class: 'N' },
    { word: 'hardship', word_class: 'N' }
  ],
  choreographic: [
    { word: 'choreograph', word_class: 'V' },
    { word: 'choreography', word_class: 'N' },
    { word: 'choreographer', word_class: 'N' },
    { word: 'choreographic', word_class: 'AJ' }
  ],
  philosophisation: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  wreathe: [
    { word: 'wreath', word_class: 'N' },
    { word: 'wreath', word_class: 'V' },
    { word: 'wreathe', word_class: 'V' },
    { word: 'wreathed', word_class: 'AJ' }
  ],
  aeration: [
    { word: 'aerate', word_class: 'V' },
    { word: 'aerator', word_class: 'N' },
    { word: 'aerated', word_class: 'AJ' },
    { word: 'aeration', word_class: 'N' }
  ],
  distillate: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  nuanced: [
    { word: 'nuance', word_class: 'N' },
    { word: 'nuance', word_class: 'V' },
    { word: 'nuanced', word_class: 'AJ' }
  ],
  bacteriophagous: [
    { word: 'bacteriophage', word_class: 'N' },
    { word: 'bacteriophagic', word_class: 'AJ' },
    { word: 'bacteriophagous', word_class: 'AJ' }
  ],
  shrillness: [
    { word: 'shrill', word_class: 'V' },
    { word: 'shrill', word_class: 'AJ' },
    { word: 'shrilly', word_class: 'AV' },
    { word: 'shrilling', word_class: 'N' },
    { word: 'shrilling', word_class: 'AJ' },
    { word: 'shrillness', word_class: 'N' }
  ],
  secernate: [
    { word: 'secern', word_class: 'V' },
    { word: 'secernate', word_class: 'V' },
    { word: 'secernment', word_class: 'N' }
  ],
  violently: [
    { word: 'violence', word_class: 'N' },
    { word: 'violent', word_class: 'AJ' },
    { word: 'violently', word_class: 'AV' }
  ],
  humanitarianism: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  deprivation: [
    { word: 'deprive', word_class: 'V' },
    { word: 'deprived', word_class: 'AJ' },
    { word: 'deprivation', word_class: 'N' }
  ],
  learner: [
    { word: 'learn', word_class: 'V' },
    { word: 'learner', word_class: 'N' },
    { word: 'learned', word_class: 'AJ' },
    { word: 'learning', word_class: 'N' }
  ],
  stimulative: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  bifurcated: [
    { word: 'bifurcate', word_class: 'V' },
    { word: 'bifurcate', word_class: 'AJ' },
    { word: 'bifurcated', word_class: 'AJ' },
    { word: 'bifurcation', word_class: 'N' }
  ],
  scour: [
    { word: 'scour', word_class: 'N' },
    { word: 'scour', word_class: 'V' },
    { word: 'scours', word_class: 'N' },
    { word: 'scourer', word_class: 'N' },
    { word: 'scoured', word_class: 'AJ' },
    { word: 'scouring', word_class: 'N' }
  ],
  center: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  fastened: [
    { word: 'fasten', word_class: 'V' },
    { word: 'fastener', word_class: 'N' },
    { word: 'fastened', word_class: 'AJ' },
    { word: 'fastening', word_class: 'N' }
  ],
  gravitational: [
    { word: 'gravitate', word_class: 'V' },
    { word: 'gravitation', word_class: 'N' },
    { word: 'gravitative', word_class: 'AJ' },
    { word: 'gravitational', word_class: 'AJ' }
  ],
  unfitting: [
    { word: 'unfit', word_class: 'V' },
    { word: 'unfit', word_class: 'AJ' },
    { word: 'unfitness', word_class: 'N' },
    { word: 'unfitting', word_class: 'AJ' }
  ],
  different: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  handled: [
    { word: 'handle', word_class: 'N' },
    { word: 'handle', word_class: 'V' },
    { word: 'handler', word_class: 'N' },
    { word: 'handled', word_class: 'AJ' },
    { word: 'handling', word_class: 'N' }
  ],
  pollutant: [
    { word: 'pollute', word_class: 'V' },
    { word: 'pollutant', word_class: 'N' },
    { word: 'polluted', word_class: 'AJ' },
    { word: 'pollution', word_class: 'N' }
  ],
  nutritiveness: [
    { word: 'nutrition', word_class: 'N' },
    { word: 'nutritive', word_class: 'AJ' },
    { word: 'nutritional', word_class: 'AJ' },
    { word: 'nutritiveness', word_class: 'N' }
  ],
  cobblestoned: [
    { word: 'cobblestone', word_class: 'N' },
    { word: 'cobblestone', word_class: 'AJ' },
    { word: 'cobblestoned', word_class: 'AJ' }
  ],
  activated: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  sureness: [
    { word: 'sure', word_class: 'AJ' },
    { word: 'sure', word_class: 'AV' },
    { word: 'surety', word_class: 'N' },
    { word: 'surely', word_class: 'AV' },
    { word: 'sureness', word_class: 'N' }
  ],
  intersperse: [
    { word: 'intersperse', word_class: 'V' },
    { word: 'interspersal', word_class: 'N' },
    { word: 'interspersion', word_class: 'N' }
  ],
  implemented: [
    { word: 'implement', word_class: 'N' },
    { word: 'implement', word_class: 'V' },
    { word: 'implemental', word_class: 'AJ' },
    { word: 'implemented', word_class: 'AJ' },
    { word: 'implementation', word_class: 'N' }
  ],
  bashful: [
    { word: 'bash', word_class: 'N' },
    { word: 'bash', word_class: 'V' },
    { word: 'bashful', word_class: 'AJ' },
    { word: 'bashfulness', word_class: 'N' }
  ],
  ritual: [
    { word: 'ritual', word_class: 'N' },
    { word: 'ritual', word_class: 'AJ' },
    { word: 'ritualism', word_class: 'N' },
    { word: 'ritualize', word_class: 'V' },
    { word: 'ritually', word_class: 'AV' },
    { word: 'ritualization', word_class: 'N' }
  ],
  slowing: [
    { word: 'slow', word_class: 'V' },
    { word: 'slow', word_class: 'AJ' },
    { word: 'slow', word_class: 'AV' },
    { word: 'slowing', word_class: 'N' },
    { word: 'slowness', word_class: 'N' }
  ],
  subsidization: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  dilute: [
    { word: 'dilute', word_class: 'N' },
    { word: 'dilute', word_class: 'V' },
    { word: 'dilute', word_class: 'AJ' },
    { word: 'dilutant', word_class: 'N' },
    { word: 'diluted', word_class: 'AJ' },
    { word: 'diluting', word_class: 'N' },
    { word: 'dilution', word_class: 'N' }
  ],
  inquire: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  gilding: [
    { word: 'gild', word_class: 'N' },
    { word: 'gild', word_class: 'V' },
    { word: 'gilder', word_class: 'N' },
    { word: 'gilded', word_class: 'AJ' },
    { word: 'gilding', word_class: 'N' }
  ],
  fatigue: [
    { word: 'fatigue', word_class: 'N' },
    { word: 'fatigue', word_class: 'V' },
    { word: 'fatigues', word_class: 'N' },
    { word: 'fatigued', word_class: 'AJ' }
  ],
  moderately: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  faded: [
    { word: 'fade', word_class: 'N' },
    { word: 'fade', word_class: 'V' },
    { word: 'faded', word_class: 'AJ' },
    { word: 'fading', word_class: 'N' },
    { word: 'fading', word_class: 'AJ' },
    { word: 'fadeless', word_class: 'AJ' }
  ],
  whimsicality: [
    { word: 'whimsy', word_class: 'N' },
    { word: 'whimsical', word_class: 'AJ' },
    { word: 'whimsicality', word_class: 'N' },
    { word: 'whimsically', word_class: 'AV' }
  ],
  pediatrician: [
    { word: 'pediatric', word_class: 'AJ' },
    { word: 'pediatrics', word_class: 'N' },
    { word: 'pediatrician', word_class: 'N' }
  ],
  lung: [
    { word: 'lung', word_class: 'N' },
    { word: 'lunge', word_class: 'N' },
    { word: 'lunge', word_class: 'V' },
    { word: 'lunger', word_class: 'N' }
  ],
  vertebrate: [
    { word: 'vertebral', word_class: 'AJ' },
    { word: 'vertebrate', word_class: 'N' },
    { word: 'vertebrate', word_class: 'AJ' }
  ],
  imprudent: [
    { word: 'imprudence', word_class: 'N' },
    { word: 'imprudent', word_class: 'AJ' },
    { word: 'imprudently', word_class: 'AV' }
  ],
  traveler: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  angelic: [
    { word: 'angel', word_class: 'N' },
    { word: 'angelic', word_class: 'AJ' },
    { word: 'angelical', word_class: 'AJ' },
    { word: 'angelically', word_class: 'AV' }
  ],
  invalidate: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  pounding: [
    { word: 'pound', word_class: 'N' },
    { word: 'pound', word_class: 'V' },
    { word: 'poundal', word_class: 'N' },
    { word: 'pounder', word_class: 'N' },
    { word: 'pounding', word_class: 'N' }
  ],
  armored: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  condemning: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  accompanied: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  disunite: [
    { word: 'disunion', word_class: 'N' },
    { word: 'disunite', word_class: 'V' },
    { word: 'disunited', word_class: 'AJ' }
  ],
  profane: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  twenty: [
    { word: 'twenty', word_class: 'N' },
    { word: 'twenty', word_class: 'AJ' },
    { word: 'twenties', word_class: 'N' }
  ],
  mechanized: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  overestimation: [
    { word: 'overestimate', word_class: 'N' },
    { word: 'overestimate', word_class: 'V' },
    { word: 'overestimation', word_class: 'N' }
  ],
  cauterise: [
    { word: 'cauterise', word_class: 'V' },
    { word: 'cauterize', word_class: 'V' },
    { word: 'cauterisation', word_class: 'N' },
    { word: 'cauterization', word_class: 'N' }
  ],
  proselytism: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  combative: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  boom: [
    { word: 'boom', word_class: 'N' },
    { word: 'boom', word_class: 'V' },
    { word: 'boomer', word_class: 'N' },
    { word: 'booming', word_class: 'AJ' }
  ],
  imperturbable: [
    { word: 'imperturbable', word_class: 'AJ' },
    { word: 'imperturbability', word_class: 'N' },
    { word: 'imperturbableness', word_class: 'N' }
  ],
  illegible: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  scraping: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  sectional: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  origin: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  eagle: [{ word: 'eagle', word_class: 'N' }, { word: 'eagle', word_class: 'V' }, { word: 'eaglet', word_class: 'N' }],
  patiently: [
    { word: 'patient', word_class: 'N' },
    { word: 'patience', word_class: 'N' },
    { word: 'patient', word_class: 'AJ' },
    { word: 'patiently', word_class: 'AV' }
  ],
  changefulness: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  divinize: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  discern: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  universalize: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  disintegrate: [
    { word: 'disintegrate', word_class: 'V' },
    { word: 'disintegrable', word_class: 'AJ' },
    { word: 'disintegration', word_class: 'N' },
    { word: 'disintegrative', word_class: 'AJ' }
  ],
  junket: [
    { word: 'junket', word_class: 'N' },
    { word: 'junket', word_class: 'V' },
    { word: 'junketing', word_class: 'N' }
  ],
  mesmerized: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  elasticized: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  cryptogamic: [
    { word: 'cryptogam', word_class: 'N' },
    { word: 'cryptogamic', word_class: 'AJ' },
    { word: 'cryptogamous', word_class: 'AJ' }
  ],
  swill: [
    { word: 'swill', word_class: 'N' },
    { word: 'swill', word_class: 'V' },
    { word: 'swilling', word_class: 'N' }
  ],
  damnation: [
    { word: 'damn', word_class: 'N' },
    { word: 'damn', word_class: 'V' },
    { word: 'damn', word_class: 'AJ' },
    { word: 'damn', word_class: 'AV' },
    { word: 'damned', word_class: 'N' },
    { word: 'damned', word_class: 'AJ' },
    { word: 'damned', word_class: 'AV' },
    { word: 'damning', word_class: 'AJ' },
    { word: 'damnation', word_class: 'N' }
  ],
  mover: [
    { word: 'move', word_class: 'N' },
    { word: 'move', word_class: 'V' },
    { word: 'mover', word_class: 'N' },
    { word: 'moved', word_class: 'AJ' },
    { word: 'moving', word_class: 'N' },
    { word: 'moving', word_class: 'AJ' },
    { word: 'movement', word_class: 'N' }
  ],
  exploiter: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  morphologic: [
    { word: 'morphology', word_class: 'N' },
    { word: 'morphologic', word_class: 'AJ' },
    { word: 'morphological', word_class: 'AJ' },
    { word: 'morphologically', word_class: 'AV' }
  ],
  awaited: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  hypersensitization: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  relay: [{ word: 'relay', word_class: 'N' }, { word: 'relay', word_class: 'V' }, { word: 'relays', word_class: 'N' }],
  experience: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  toasting: [
    { word: 'toast', word_class: 'N' },
    { word: 'toast', word_class: 'V' },
    { word: 'toaster', word_class: 'N' },
    { word: 'toasted', word_class: 'AJ' },
    { word: 'toasting', word_class: 'N' }
  ],
  potation: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  secretary: [
    { word: 'secretary', word_class: 'N' },
    { word: 'secretarial', word_class: 'AJ' },
    { word: 'secretariate', word_class: 'N' }
  ],
  consign: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  hierarchal: [
    { word: 'hierarch', word_class: 'N' },
    { word: 'hierarchal', word_class: 'AJ' },
    { word: 'hierarchic', word_class: 'AJ' },
    { word: 'hierarchical', word_class: 'AJ' },
    { word: 'hierarchically', word_class: 'AV' }
  ],
  volatilisation: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  abidance: [
    { word: 'abide', word_class: 'V' },
    { word: 'abidance', word_class: 'N' },
    { word: 'abiding', word_class: 'AJ' }
  ],
  frequently: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  reclusive: [
    { word: 'recluse', word_class: 'N' },
    { word: 'recluse', word_class: 'AJ' },
    { word: 'reclusive', word_class: 'AJ' },
    { word: 'reclusiveness', word_class: 'N' }
  ],
  symphonize: [
    { word: 'symphony', word_class: 'N' },
    { word: 'symphonic', word_class: 'AJ' },
    { word: 'symphonize', word_class: 'V' },
    { word: 'symphonious', word_class: 'AJ' }
  ],
  apposable: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  suspiciously: [
    { word: 'suspicious', word_class: 'AJ' },
    { word: 'suspiciously', word_class: 'AV' },
    { word: 'suspiciousness', word_class: 'N' }
  ],
  truculently: [
    { word: 'truculence', word_class: 'N' },
    { word: 'truculency', word_class: 'N' },
    { word: 'truculent', word_class: 'AJ' },
    { word: 'truculently', word_class: 'AV' }
  ],
  demagnetise: [
    { word: 'demagnetise', word_class: 'V' },
    { word: 'demagnetize', word_class: 'V' },
    { word: 'demagnetisation', word_class: 'N' },
    { word: 'demagnetization', word_class: 'N' }
  ],
  anchorite: [
    { word: 'anchor', word_class: 'N' },
    { word: 'anchor', word_class: 'V' },
    { word: 'anchorage', word_class: 'N' },
    { word: 'anchorite', word_class: 'N' },
    { word: 'anchorman', word_class: 'N' },
    { word: 'anchoritic', word_class: 'AJ' }
  ],
  find: [
    { word: 'find', word_class: 'N' },
    { word: 'find', word_class: 'V' },
    { word: 'finder', word_class: 'N' },
    { word: 'finding', word_class: 'N' }
  ],
  shipment: [
    { word: 'ship', word_class: 'N' },
    { word: 'ship', word_class: 'V' },
    { word: 'shipment', word_class: 'N' },
    { word: 'shipping', word_class: 'N' }
  ],
  unsounded: [
    { word: 'unsound', word_class: 'AJ' },
    { word: 'unsounded', word_class: 'AJ' },
    { word: 'unsoundness', word_class: 'N' },
    { word: 'unsoundable', word_class: 'AJ' }
  ],
  minim: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  revolutionize: [
    { word: 'revolutionist', word_class: 'N' },
    { word: 'revolutionize', word_class: 'V' },
    { word: 'revolutionization', word_class: 'N' }
  ],
  intensely: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  isolationist: [
    { word: 'isolationist', word_class: 'N' },
    { word: 'isolationist', word_class: 'AJ' },
    { word: 'isolationistic', word_class: 'AJ' }
  ],
  rapid: [
    { word: 'rapid', word_class: 'N' },
    { word: 'rapid', word_class: 'AJ' },
    { word: 'rapids', word_class: 'N' },
    { word: 'rapidity', word_class: 'N' }
  ],
  revalorization: [
    { word: 'revalorise', word_class: 'V' },
    { word: 'revalorize', word_class: 'V' },
    { word: 'revalorization', word_class: 'N' }
  ],
  mineral: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  superiority: [
    { word: 'superior', word_class: 'N' },
    { word: 'superior', word_class: 'AJ' },
    { word: 'superiority', word_class: 'N' }
  ],
  somersaulting: [
    { word: 'somersault', word_class: 'N' },
    { word: 'somersault', word_class: 'V' },
    { word: 'somersaulting', word_class: 'N' }
  ],
  depend: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  gnostic: [
    { word: 'gnostic', word_class: 'N' },
    { word: 'gnostic', word_class: 'AJ' },
    { word: 'gnosticism', word_class: 'N' }
  ],
  hype: [
    { word: 'hype', word_class: 'N' },
    { word: 'hype', word_class: 'V' },
    { word: 'hyped', word_class: 'AJ' },
    { word: 'hyping', word_class: 'AJ' }
  ],
  mixer: [
    { word: 'mix', word_class: 'N' },
    { word: 'mix', word_class: 'V' },
    { word: 'mixer', word_class: 'N' },
    { word: 'mixed', word_class: 'AJ' },
    { word: 'mixing', word_class: 'N' },
    { word: 'mixture', word_class: 'N' }
  ],
  poisoning: [
    { word: 'poison', word_class: 'N' },
    { word: 'poison', word_class: 'V' },
    { word: 'poisoner', word_class: 'N' },
    { word: 'poisoning', word_class: 'N' },
    { word: 'poisonous', word_class: 'AJ' },
    { word: 'poisonously', word_class: 'AV' }
  ],
  stupefying: [
    { word: 'stupefy', word_class: 'V' },
    { word: 'stupify', word_class: 'V' },
    { word: 'stupefied', word_class: 'AJ' },
    { word: 'stupefying', word_class: 'AJ' },
    { word: 'stupefaction', word_class: 'N' }
  ],
  roost: [{ word: 'roost', word_class: 'N' }, { word: 'roost', word_class: 'V' }, { word: 'rooster', word_class: 'N' }],
  immobilize: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  lapping: [
    { word: 'lap', word_class: 'N' },
    { word: 'lap', word_class: 'V' },
    { word: 'lapful', word_class: 'N' },
    { word: 'lapful', word_class: 'AJ' },
    { word: 'lapping', word_class: 'N' }
  ],
  fractionation: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  passionately: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  mendicancy: [
    { word: 'mendicant', word_class: 'N' },
    { word: 'mendicancy', word_class: 'N' },
    { word: 'mendicant', word_class: 'AJ' }
  ],
  oversize: [
    { word: 'oversee', word_class: 'V' },
    { word: 'overseer', word_class: 'N' },
    { word: 'oversize', word_class: 'AJ' },
    { word: 'oversized', word_class: 'AJ' }
  ],
  bribery: [
    { word: 'bribe', word_class: 'N' },
    { word: 'bribe', word_class: 'V' },
    { word: 'briber', word_class: 'N' },
    { word: 'bribery', word_class: 'N' },
    { word: 'bribery', word_class: 'AJ' }
  ],
  archetype: [
    { word: 'archetype', word_class: 'N' },
    { word: 'archetypal', word_class: 'AJ' },
    { word: 'archetypic', word_class: 'AJ' },
    { word: 'archetypical', word_class: 'AJ' }
  ],
  uncontrolled: [
    { word: 'uncontrolled', word_class: 'AJ' },
    { word: 'uncontrollable', word_class: 'AJ' },
    { word: 'uncontrollably', word_class: 'AV' }
  ],
  luxurious: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  flagellated: [
    { word: 'flagellant', word_class: 'N' },
    { word: 'flagellate', word_class: 'N' },
    { word: 'flagellate', word_class: 'V' },
    { word: 'flagellate', word_class: 'AJ' },
    { word: 'flagellated', word_class: 'AJ' },
    { word: 'flagellation', word_class: 'N' }
  ],
  lefthanded: [
    { word: 'lefthand', word_class: 'AJ' },
    { word: 'lefthanded', word_class: 'AJ' },
    { word: 'lefthandedness', word_class: 'N' }
  ],
  compound: [
    { word: 'compound', word_class: 'N' },
    { word: 'compound', word_class: 'V' },
    { word: 'compound', word_class: 'AJ' },
    { word: 'compounded', word_class: 'AJ' },
    { word: 'compounding', word_class: 'N' }
  ],
  hesitant: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  geometrization: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  oblate: [
    { word: 'oblate', word_class: 'N' },
    { word: 'oblate', word_class: 'AJ' },
    { word: 'oblation', word_class: 'N' },
    { word: 'oblateness', word_class: 'N' }
  ],
  impendence: [
    { word: 'impend', word_class: 'V' },
    { word: 'impendence', word_class: 'N' },
    { word: 'impendency', word_class: 'N' },
    { word: 'impending', word_class: 'AJ' }
  ],
  cerebral: [
    { word: 'cerebral', word_class: 'AJ' },
    { word: 'cerebrate', word_class: 'V' },
    { word: 'cerebrally', word_class: 'AV' },
    { word: 'cerebration', word_class: 'N' }
  ],
  calumniate: [
    { word: 'calumny', word_class: 'N' },
    { word: 'calumniate', word_class: 'V' },
    { word: 'calumnious', word_class: 'AJ' },
    { word: 'calumniation', word_class: 'N' }
  ],
  romanization: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  scan: [{ word: 'scan', word_class: 'N' }, { word: 'scan', word_class: 'V' }, { word: 'scanning', word_class: 'N' }],
  unsystematized: [
    { word: 'unsystematic', word_class: 'AJ' },
    { word: 'unsystematized', word_class: 'AJ' },
    { word: 'unsystematically', word_class: 'AV' }
  ],
  weird: [
    { word: 'weird', word_class: 'N' },
    { word: 'weird', word_class: 'AJ' },
    { word: 'weirdness', word_class: 'N' }
  ],
  burglarious: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  italicisation: [
    { word: 'italicise', word_class: 'V' },
    { word: 'italicize', word_class: 'V' },
    { word: 'italicisation', word_class: 'N' },
    { word: 'italicization', word_class: 'N' }
  ],
  fomenter: [
    { word: 'foment', word_class: 'V' },
    { word: 'fomenter', word_class: 'N' },
    { word: 'fomentation', word_class: 'N' }
  ],
  extortionately: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  obliteration: [
    { word: 'obliterate', word_class: 'V' },
    { word: 'obliterate', word_class: 'AJ' },
    { word: 'obliterable', word_class: 'AJ' },
    { word: 'obliterated', word_class: 'AJ' },
    { word: 'obliteration', word_class: 'N' },
    { word: 'obliterating', word_class: 'AJ' }
  ],
  abstemiousness: [
    { word: 'abstemious', word_class: 'AJ' },
    { word: 'abstemiously', word_class: 'AV' },
    { word: 'abstemiousness', word_class: 'N' }
  ],
  depraved: [
    { word: 'deprave', word_class: 'V' },
    { word: 'depraved', word_class: 'AJ' },
    { word: 'depravity', word_class: 'N' },
    { word: 'depravation', word_class: 'N' }
  ],
  dissipation: [
    { word: 'dissipate', word_class: 'V' },
    { word: 'dissipated', word_class: 'AJ' },
    { word: 'dissipation', word_class: 'N' }
  ],
  narrowing: [
    { word: 'narrow', word_class: 'N' },
    { word: 'narrow', word_class: 'V' },
    { word: 'narrow', word_class: 'AJ' },
    { word: 'narrowed', word_class: 'AJ' },
    { word: 'narrowing', word_class: 'N' },
    { word: 'narrowing', word_class: 'AJ' },
    { word: 'narrowness', word_class: 'N' }
  ],
  money: [
    { word: 'money', word_class: 'N' },
    { word: 'monied', word_class: 'AJ' },
    { word: 'moneyed', word_class: 'AJ' }
  ],
  elected: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  cognizant: [
    { word: 'cognize', word_class: 'V' },
    { word: 'cognizance', word_class: 'N' },
    { word: 'cognizant', word_class: 'AJ' },
    { word: 'cognizable', word_class: 'AJ' }
  ],
  extract: [
    { word: 'extract', word_class: 'N' },
    { word: 'extract', word_class: 'V' },
    { word: 'extractor', word_class: 'N' },
    { word: 'extraction', word_class: 'N' },
    { word: 'extractable', word_class: 'AJ' },
    { word: 'extractible', word_class: 'AJ' }
  ],
  infinite: [
    { word: 'infinite', word_class: 'AJ' },
    { word: 'infinitive', word_class: 'N' },
    { word: 'infinitely', word_class: 'AV' },
    { word: 'infinitive', word_class: 'AJ' },
    { word: 'infiniteness', word_class: 'N' }
  ],
  metamorphism: [
    { word: 'metamorphic', word_class: 'AJ' },
    { word: 'metamorphism', word_class: 'N' },
    { word: 'metamorphous', word_class: 'AJ' }
  ],
  bai: [
    { word: 'bai', word_class: 'N' },
    { word: 'bay', word_class: 'N' },
    { word: 'bay', word_class: 'V' },
    { word: 'bay', word_class: 'AJ' },
    { word: 'bayer', word_class: 'N' }
  ],
  evacuee: [
    { word: 'evacuee', word_class: 'N' },
    { word: 'evacuate', word_class: 'V' },
    { word: 'evacuant', word_class: 'AJ' },
    { word: 'evacuated', word_class: 'AJ' },
    { word: 'evacuation', word_class: 'N' }
  ],
  unexcited: [
    { word: 'unexcited', word_class: 'AJ' },
    { word: 'unexciting', word_class: 'AJ' },
    { word: 'unexcitable', word_class: 'AJ' }
  ],
  dawdling: [
    { word: 'dawdle', word_class: 'V' },
    { word: 'dawdler', word_class: 'N' },
    { word: 'dawdling', word_class: 'N' },
    { word: 'dawdling', word_class: 'AJ' }
  ],
  phlegmatic: [
    { word: 'phlegmatic', word_class: 'AJ' },
    { word: 'phlegmatical', word_class: 'AJ' },
    { word: 'phlegmatically', word_class: 'AV' }
  ],
  recusancy: [
    { word: 'recuse', word_class: 'V' },
    { word: 'recusal', word_class: 'N' },
    { word: 'recusant', word_class: 'N' },
    { word: 'recusancy', word_class: 'N' },
    { word: 'recusant', word_class: 'AJ' },
    { word: 'recusation', word_class: 'N' }
  ],
  trill: [
    { word: 'trill', word_class: 'N' },
    { word: 'trill', word_class: 'V' },
    { word: 'trilled', word_class: 'AJ' },
    { word: 'trillion', word_class: 'N' },
    { word: 'trillion', word_class: 'AJ' },
    { word: 'trillions', word_class: 'N' }
  ],
  chilli: [
    { word: 'chilli', word_class: 'N' },
    { word: 'chilly', word_class: 'N' },
    { word: 'chilly', word_class: 'AJ' },
    { word: 'chilliness', word_class: 'N' }
  ],
  restorer: [
    { word: 'restore', word_class: 'N' },
    { word: 'restore', word_class: 'V' },
    { word: 'restorer', word_class: 'N' },
    { word: 'restored', word_class: 'AJ' },
    { word: 'restoration', word_class: 'N' },
    { word: 'restorative', word_class: 'N' },
    { word: 'restorative', word_class: 'AJ' }
  ],
  reprove: [
    { word: 'reprove', word_class: 'V' },
    { word: 'reproval', word_class: 'N' },
    { word: 'reproved', word_class: 'AJ' },
    { word: 'reproving', word_class: 'AJ' }
  ],
  albinal: [
    { word: 'albinal', word_class: 'AJ' },
    { word: 'albinic', word_class: 'AJ' },
    { word: 'albinism', word_class: 'N' }
  ],
  relent: [
    { word: 'relent', word_class: 'N' },
    { word: 'relent', word_class: 'V' },
    { word: 'relentless', word_class: 'AJ' },
    { word: 'relentlessness', word_class: 'N' }
  ],
  funny: [
    { word: 'funny', word_class: 'AJ' },
    { word: 'funny', word_class: 'AV' },
    { word: 'funniness', word_class: 'N' }
  ],
  tittivate: [
    { word: 'titivate', word_class: 'V' },
    { word: 'tittivate', word_class: 'V' },
    { word: 'titivation', word_class: 'N' },
    { word: 'tittivation', word_class: 'N' }
  ],
  declare: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  repetitiveness: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  featured: [
    { word: 'feature', word_class: 'N' },
    { word: 'feature', word_class: 'V' },
    { word: 'featured', word_class: 'AJ' }
  ],
  morn: [
    { word: 'morn', word_class: 'N' },
    { word: 'morn', word_class: 'V' },
    { word: 'morning', word_class: 'N' },
    { word: 'morning', word_class: 'AJ' }
  ],
  improvable: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  bionomics: [
    { word: 'bionomic', word_class: 'AJ' },
    { word: 'bionomics', word_class: 'N' },
    { word: 'bionomical', word_class: 'AJ' }
  ],
  absenteeism: [
    { word: 'absent', word_class: 'V' },
    { word: 'absence', word_class: 'N' },
    { word: 'absent', word_class: 'AJ' },
    { word: 'absentee', word_class: 'N' },
    { word: 'absently', word_class: 'AV' },
    { word: 'absenteeism', word_class: 'N' }
  ],
  probationary: [
    { word: 'probate', word_class: 'N' },
    { word: 'probate', word_class: 'V' },
    { word: 'probation', word_class: 'N' },
    { word: 'probationary', word_class: 'AJ' }
  ],
  juvenility: [
    { word: 'juvenile', word_class: 'N' },
    { word: 'juvenile', word_class: 'AJ' },
    { word: 'juvenility', word_class: 'N' }
  ],
  rightfulness: [
    { word: 'right', word_class: 'V' },
    { word: 'right', word_class: 'N' },
    { word: 'right', word_class: 'AJ' },
    { word: 'right', word_class: 'AV' },
    { word: 'rightist', word_class: 'N' },
    { word: 'rightful', word_class: 'AJ' },
    { word: 'rightist', word_class: 'AJ' },
    { word: 'rightness', word_class: 'N' },
    { word: 'rightfulness', word_class: 'N' }
  ],
  appropriate: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  wade: [{ word: 'wade', word_class: 'V' }, { word: 'wader', word_class: 'N' }, { word: 'wading', word_class: 'N' }],
  bennie: [{ word: 'benni', word_class: 'N' }, { word: 'benny', word_class: 'N' }, { word: 'bennie', word_class: 'N' }],
  directive: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  twitting: [
    { word: 'twit', word_class: 'N' },
    { word: 'twit', word_class: 'V' },
    { word: 'twitting', word_class: 'N' }
  ],
  sexed: [
    { word: 'sex', word_class: 'N' },
    { word: 'sex', word_class: 'V' },
    { word: 'sexed', word_class: 'AJ' },
    { word: 'sexism', word_class: 'N' },
    { word: 'sexist', word_class: 'N' },
    { word: 'sexist', word_class: 'AJ' }
  ],
  revered: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  conjuring: [
    { word: 'conjure', word_class: 'V' },
    { word: 'conjurer', word_class: 'N' },
    { word: 'conjuror', word_class: 'N' },
    { word: 'conjuring', word_class: 'N' },
    { word: 'conjuration', word_class: 'N' }
  ],
  tail: [
    { word: 'tail', word_class: 'N' },
    { word: 'tail', word_class: 'V' },
    { word: 'tail', word_class: 'AJ' },
    { word: 'tails', word_class: 'N' },
    { word: 'tailed', word_class: 'AJ' },
    { word: 'tailing', word_class: 'N' },
    { word: 'tailing', word_class: 'AJ' }
  ],
  alphabetize: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  sower: [
    { word: 'sow', word_class: 'N' },
    { word: 'sow', word_class: 'V' },
    { word: 'sower', word_class: 'N' },
    { word: 'sown', word_class: 'AJ' }
  ],
  digestible: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  bay: [
    { word: 'bai', word_class: 'N' },
    { word: 'bay', word_class: 'N' },
    { word: 'bay', word_class: 'V' },
    { word: 'bay', word_class: 'AJ' },
    { word: 'bayer', word_class: 'N' }
  ],
  tessellation: [
    { word: 'tesselate', word_class: 'V' },
    { word: 'tessellate', word_class: 'V' },
    { word: 'tessellated', word_class: 'AJ' },
    { word: 'tessellation', word_class: 'N' }
  ],
  offhand: [
    { word: 'offhand', word_class: 'AJ' },
    { word: 'offhand', word_class: 'AV' },
    { word: 'offhanded', word_class: 'AJ' },
    { word: 'offhanded', word_class: 'AV' }
  ],
  cochineal: [
    { word: 'cochin', word_class: 'N' },
    { word: 'cochineal', word_class: 'N' },
    { word: 'cochineal', word_class: 'AJ' }
  ],
  acoustic: [
    { word: 'acoustic', word_class: 'N' },
    { word: 'acoustic', word_class: 'AJ' },
    { word: 'acoustics', word_class: 'N' },
    { word: 'acoustical', word_class: 'AJ' },
    { word: 'acoustically', word_class: 'AV' }
  ],
  masked: [
    { word: 'mask', word_class: 'N' },
    { word: 'mask', word_class: 'V' },
    { word: 'masked', word_class: 'AJ' },
    { word: 'masking', word_class: 'N' },
    { word: 'masking', word_class: 'AJ' }
  ],
  unpretentious: [
    { word: 'unpretentious', word_class: 'AJ' },
    { word: 'unpretentiously', word_class: 'AV' },
    { word: 'unpretentiousness', word_class: 'N' }
  ],
  finish: [
    { word: 'finish', word_class: 'N' },
    { word: 'finish', word_class: 'V' },
    { word: 'finish', word_class: 'AJ' },
    { word: 'finisher', word_class: 'N' },
    { word: 'finished', word_class: 'AJ' },
    { word: 'finishing', word_class: 'N' }
  ],
  impersonal: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  dryness: [
    { word: 'dry', word_class: 'N' },
    { word: 'dry', word_class: 'V' },
    { word: 'dry', word_class: 'AJ' },
    { word: 'drier', word_class: 'N' },
    { word: 'dryer', word_class: 'N' },
    { word: 'dryness', word_class: 'N' }
  ],
  mesmerisation: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  carinate: [
    { word: 'carinal', word_class: 'AJ' },
    { word: 'carinate', word_class: 'N' },
    { word: 'carinate', word_class: 'AJ' },
    { word: 'carinated', word_class: 'AJ' }
  ],
  toe: [
    { word: 'toe', word_class: 'N' },
    { word: 'toe', word_class: 'V' },
    { word: 'toe', word_class: 'AJ' },
    { word: 'toed', word_class: 'AJ' }
  ],
  forgo: [
    { word: 'forgo', word_class: 'V' },
    { word: 'forgoing', word_class: 'N' },
    { word: 'forgone', word_class: 'AJ' },
    { word: 'forgoing', word_class: 'AJ' }
  ],
  check: [
    { word: 'check', word_class: 'N' },
    { word: 'check', word_class: 'V' },
    { word: 'checker', word_class: 'N' },
    { word: 'checker', word_class: 'V' },
    { word: 'checked', word_class: 'AJ' },
    { word: 'checkers', word_class: 'N' },
    { word: 'checkered', word_class: 'AJ' }
  ],
  receiver: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  hastings: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  tag: [{ word: 'tag', word_class: 'N' }, { word: 'tag', word_class: 'V' }, { word: 'tagged', word_class: 'AJ' }],
  metonymically: [
    { word: 'metonym', word_class: 'N' },
    { word: 'metonymic', word_class: 'AJ' },
    { word: 'metonymical', word_class: 'AJ' },
    { word: 'metonymically', word_class: 'AV' }
  ],
  saturated: [
    { word: 'saturate', word_class: 'V' },
    { word: 'saturable', word_class: 'AJ' },
    { word: 'saturated', word_class: 'AJ' },
    { word: 'saturation', word_class: 'N' }
  ],
  employable: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  attested: [
    { word: 'attest', word_class: 'V' },
    { word: 'attestant', word_class: 'N' },
    { word: 'attested', word_class: 'AJ' },
    { word: 'attestation', word_class: 'N' }
  ],
  wizened: [
    { word: 'wizen', word_class: 'V' },
    { word: 'wizen', word_class: 'AJ' },
    { word: 'wizened', word_class: 'AJ' }
  ],
  vindicate: [
    { word: 'vindicate', word_class: 'V' },
    { word: 'vindicator', word_class: 'N' },
    { word: 'vindicable', word_class: 'AJ' },
    { word: 'vindicated', word_class: 'AJ' },
    { word: 'vindication', word_class: 'N' }
  ],
  distension: [
    { word: 'distend', word_class: 'V' },
    { word: 'distended', word_class: 'AJ' },
    { word: 'distension', word_class: 'N' },
    { word: 'distention', word_class: 'N' },
    { word: 'distensible', word_class: 'AJ' }
  ],
  caddy: [
    { word: 'caddy', word_class: 'N' },
    { word: 'caddy', word_class: 'V' },
    { word: 'caddie', word_class: 'N' },
    { word: 'caddie', word_class: 'V' },
    { word: 'caddis', word_class: 'N' },
    { word: 'caddy', word_class: 'AJ' }
  ],
  discriminable: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  assisted: [
    { word: 'assist', word_class: 'N' },
    { word: 'assist', word_class: 'V' },
    { word: 'assistant', word_class: 'N' },
    { word: 'assisted', word_class: 'AJ' },
    { word: 'assistance', word_class: 'N' },
    { word: 'assistant', word_class: 'AJ' },
    { word: 'assistive', word_class: 'AJ' }
  ],
  foreboding: [
    { word: 'forebode', word_class: 'V' },
    { word: 'foreboding', word_class: 'N' },
    { word: 'foreboding', word_class: 'AJ' }
  ],
  languorously: [
    { word: 'languor', word_class: 'N' },
    { word: 'languorous', word_class: 'AJ' },
    { word: 'languorously', word_class: 'AV' }
  ],
  completely: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  doable: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  hydraulically: [
    { word: 'hydraulic', word_class: 'AJ' },
    { word: 'hydraulics', word_class: 'N' },
    { word: 'hydraulically', word_class: 'AV' }
  ],
  perjury: [
    { word: 'perjure', word_class: 'V' },
    { word: 'perjury', word_class: 'N' },
    { word: 'perjurer', word_class: 'N' }
  ],
  pursue: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  passionateness: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  tickling: [
    { word: 'tickle', word_class: 'N' },
    { word: 'tickle', word_class: 'V' },
    { word: 'tickler', word_class: 'N' },
    { word: 'tickling', word_class: 'N' },
    { word: 'tickling', word_class: 'AJ' },
    { word: 'ticklish', word_class: 'AJ' }
  ],
  concomitant: [
    { word: 'concomitant', word_class: 'N' },
    { word: 'concomitance', word_class: 'N' },
    { word: 'concomitant', word_class: 'AJ' }
  ],
  routinize: [
    { word: 'routine', word_class: 'N' },
    { word: 'routine', word_class: 'AJ' },
    { word: 'routinize', word_class: 'V' },
    { word: 'routinely', word_class: 'AV' },
    { word: 'routinization', word_class: 'N' }
  ],
  lactation: [
    { word: 'lactate', word_class: 'V' },
    { word: 'lactation', word_class: 'N' },
    { word: 'lactating', word_class: 'AJ' }
  ],
  plenty: [
    { word: 'plenty', word_class: 'N' },
    { word: 'plenty', word_class: 'AJ' },
    { word: 'plenty', word_class: 'AV' },
    { word: 'plenteous', word_class: 'AJ' },
    { word: 'plentiful', word_class: 'AJ' },
    { word: 'plentifulness', word_class: 'N' }
  ],
  pharmaceutics: [
    { word: 'pharmaceutic', word_class: 'AJ' },
    { word: 'pharmaceutics', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'AJ' }
  ],
  rejection: [
    { word: 'reject', word_class: 'N' },
    { word: 'reject', word_class: 'V' },
    { word: 'rejected', word_class: 'AJ' },
    { word: 'rejection', word_class: 'N' },
    { word: 'rejective', word_class: 'AJ' }
  ],
  thematic: [
    { word: 'theme', word_class: 'N' },
    { word: 'thematic', word_class: 'AJ' },
    { word: 'thematically', word_class: 'AV' }
  ],
  enforcer: [
    { word: 'enforce', word_class: 'V' },
    { word: 'enforcer', word_class: 'N' },
    { word: 'enforced', word_class: 'AJ' },
    { word: 'enforcement', word_class: 'N' },
    { word: 'enforceable', word_class: 'AJ' }
  ],
  peddling: [
    { word: 'peddle', word_class: 'V' },
    { word: 'peddler', word_class: 'N' },
    { word: 'peddling', word_class: 'N' }
  ],
  relate: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  vanquishable: [
    { word: 'vanquish', word_class: 'V' },
    { word: 'vanquisher', word_class: 'N' },
    { word: 'vanquished', word_class: 'AJ' },
    { word: 'vanquishment', word_class: 'N' },
    { word: 'vanquishable', word_class: 'AJ' }
  ],
  ennoble: [
    { word: 'ennoble', word_class: 'V' },
    { word: 'ennobling', word_class: 'AJ' },
    { word: 'ennoblement', word_class: 'N' }
  ],
  grateful: [
    { word: 'grate', word_class: 'N' },
    { word: 'grate', word_class: 'V' },
    { word: 'grater', word_class: 'N' },
    { word: 'grating', word_class: 'N' },
    { word: 'grating', word_class: 'AJ' },
    { word: 'grateful', word_class: 'AJ' },
    { word: 'gratefulness', word_class: 'N' }
  ],
  dullness: [
    { word: 'dull', word_class: 'V' },
    { word: 'dull', word_class: 'AJ' },
    { word: 'dully', word_class: 'AV' },
    { word: 'dulled', word_class: 'AJ' },
    { word: 'dullness', word_class: 'N' }
  ],
  adventurism: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  concisely: [
    { word: 'concise', word_class: 'AJ' },
    { word: 'concision', word_class: 'N' },
    { word: 'concisely', word_class: 'AV' },
    { word: 'conciseness', word_class: 'N' }
  ],
  patterned: [
    { word: 'pattern', word_class: 'N' },
    { word: 'pattern', word_class: 'V' },
    { word: 'patterned', word_class: 'AJ' }
  ],
  catoptric: [
    { word: 'catoptric', word_class: 'AJ' },
    { word: 'catoptrics', word_class: 'N' },
    { word: 'catoptrical', word_class: 'AJ' }
  ],
  corrigibility: [
    { word: 'corrigible', word_class: 'AJ' },
    { word: 'corrigibly', word_class: 'AV' },
    { word: 'corrigibility', word_class: 'N' }
  ],
  'dry-clean': [
    { word: 'dry-clean', word_class: 'V' },
    { word: 'dry-cleaned', word_class: 'AJ' },
    { word: 'dry-cleaning', word_class: 'N' }
  ],
  peace: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  inheritance: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  pledged: [
    { word: 'pledge', word_class: 'N' },
    { word: 'pledge', word_class: 'V' },
    { word: 'pledged', word_class: 'AJ' }
  ],
  whizzed: [
    { word: 'whiz', word_class: 'N' },
    { word: 'whiz', word_class: 'V' },
    { word: 'whizz', word_class: 'N' },
    { word: 'whizz', word_class: 'V' },
    { word: 'whizzed', word_class: 'AJ' },
    { word: 'whizzing', word_class: 'AJ' }
  ],
  abnormally: [
    { word: 'abnormal', word_class: 'AJ' },
    { word: 'abnormality', word_class: 'N' },
    { word: 'abnormally', word_class: 'AV' }
  ],
  inflexibleness: [
    { word: 'inflexible', word_class: 'AJ' },
    { word: 'inflexibly', word_class: 'AV' },
    { word: 'inflexibility', word_class: 'N' },
    { word: 'inflexibleness', word_class: 'N' }
  ],
  softening: [
    { word: 'soften', word_class: 'V' },
    { word: 'softener', word_class: 'N' },
    { word: 'softened', word_class: 'AJ' },
    { word: 'softening', word_class: 'N' },
    { word: 'softening', word_class: 'AJ' }
  ],
  delimitation: [
    { word: 'delimit', word_class: 'N' },
    { word: 'delimit', word_class: 'V' },
    { word: 'delimitate', word_class: 'V' },
    { word: 'delimited', word_class: 'AJ' },
    { word: 'delimitation', word_class: 'N' }
  ],
  rebelling: [
    { word: 'rebel', word_class: 'N' },
    { word: 'rebel', word_class: 'V' },
    { word: 'rebel', word_class: 'AJ' },
    { word: 'rebellion', word_class: 'N' },
    { word: 'rebelling', word_class: 'AJ' }
  ],
  gear: [
    { word: 'gear', word_class: 'N' },
    { word: 'gear', word_class: 'V' },
    { word: 'gears', word_class: 'N' },
    { word: 'geared', word_class: 'AJ' },
    { word: 'gearing', word_class: 'N' }
  ],
  rudely: [
    { word: 'rude', word_class: 'AJ' },
    { word: 'rudely', word_class: 'AV' },
    { word: 'rudeness', word_class: 'N' }
  ],
  unimaginable: [
    { word: 'unimagined', word_class: 'AJ' },
    { word: 'unimaginable', word_class: 'AJ' },
    { word: 'unimaginably', word_class: 'AV' },
    { word: 'unimaginative', word_class: 'AJ' },
    { word: 'unimaginatively', word_class: 'AV' }
  ],
  talkatively: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  mongolianism: [
    { word: 'mongolian', word_class: 'N' },
    { word: 'mongolian', word_class: 'AJ' },
    { word: 'mongolianism', word_class: 'N' }
  ],
  drugged: [
    { word: 'drug', word_class: 'N' },
    { word: 'drug', word_class: 'V' },
    { word: 'drugged', word_class: 'AJ' },
    { word: 'drugging', word_class: 'N' }
  ],
  precocial: [
    { word: 'precocial', word_class: 'AJ' },
    { word: 'precocious', word_class: 'AJ' },
    { word: 'precociously', word_class: 'AV' },
    { word: 'precociousness', word_class: 'N' }
  ],
  cornhusking: [
    { word: 'cornhusk', word_class: 'N' },
    { word: 'cornhusker', word_class: 'N' },
    { word: 'cornhusking', word_class: 'N' }
  ],
  enfeeblement: [
    { word: 'enfeeble', word_class: 'V' },
    { word: 'enfeebled', word_class: 'AJ' },
    { word: 'enfeebling', word_class: 'AJ' },
    { word: 'enfeeblement', word_class: 'N' }
  ],
  pulverized: [
    { word: 'pulverise', word_class: 'V' },
    { word: 'pulverize', word_class: 'V' },
    { word: 'pulverized', word_class: 'AJ' },
    { word: 'pulverisation', word_class: 'N' },
    { word: 'pulverization', word_class: 'N' }
  ],
  derision: [
    { word: 'deride', word_class: 'V' },
    { word: 'derision', word_class: 'N' },
    { word: 'derisive', word_class: 'AJ' },
    { word: 'derisively', word_class: 'AV' }
  ],
  oozy: [
    { word: 'ooze', word_class: 'N' },
    { word: 'ooze', word_class: 'V' },
    { word: 'oozy', word_class: 'AJ' },
    { word: 'oozing', word_class: 'N' },
    { word: 'oozing', word_class: 'AJ' }
  ],
  reason: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  probably: [
    { word: 'probable', word_class: 'N' },
    { word: 'probable', word_class: 'AJ' },
    { word: 'probably', word_class: 'AV' },
    { word: 'probability', word_class: 'N' }
  ],
  hauler: [
    { word: 'haul', word_class: 'N' },
    { word: 'haul', word_class: 'V' },
    { word: 'hauler', word_class: 'N' },
    { word: 'haulage', word_class: 'N' },
    { word: 'hauling', word_class: 'N' }
  ],
  apology: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  sticky: [
    { word: 'stick', word_class: 'N' },
    { word: 'stick', word_class: 'V' },
    { word: 'sticker', word_class: 'N' },
    { word: 'sticky', word_class: 'AJ' },
    { word: 'sticking', word_class: 'AJ' },
    { word: 'stickiness', word_class: 'N' }
  ],
  ignorance: [
    { word: 'ignore', word_class: 'V' },
    { word: 'ignorant', word_class: 'N' },
    { word: 'ignored', word_class: 'AJ' },
    { word: 'ignorance', word_class: 'N' },
    { word: 'ignorant', word_class: 'AJ' },
    { word: 'ignorantness', word_class: 'N' }
  ],
  jaw: [
    { word: 'jaw', word_class: 'N' },
    { word: 'jaw', word_class: 'V' },
    { word: 'jaws', word_class: 'N' },
    { word: 'jawed', word_class: 'AJ' }
  ],
  resin: [
    { word: 'resin', word_class: 'N' },
    { word: 'resin', word_class: 'V' },
    { word: 'resinate', word_class: 'V' },
    { word: 'resinous', word_class: 'AJ' },
    { word: 'resinated', word_class: 'AJ' },
    { word: 'resination', word_class: 'N' }
  ],
  tranquilization: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  interrogator: [
    { word: 'interrogate', word_class: 'V' },
    { word: 'interrogator', word_class: 'N' },
    { word: 'interrogation', word_class: 'N' },
    { word: 'interrogative', word_class: 'N' },
    { word: 'interrogative', word_class: 'AJ' },
    { word: 'interrogatively', word_class: 'AV' }
  ],
  subconscious: [
    { word: 'subconscious', word_class: 'N' },
    { word: 'subconscious', word_class: 'AJ' },
    { word: 'subconsciously', word_class: 'AV' },
    { word: 'subconsciousness', word_class: 'N' }
  ],
  sewed: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  entrant: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  assimilable: [
    { word: 'assimilate', word_class: 'V' },
    { word: 'assimilable', word_class: 'AJ' },
    { word: 'assimilation', word_class: 'N' },
    { word: 'assimilating', word_class: 'AJ' },
    { word: 'assimilative', word_class: 'AJ' }
  ],
  kennel: [
    { word: 'kennel', word_class: 'N' },
    { word: 'kennel', word_class: 'V' },
    { word: 'kennels', word_class: 'N' }
  ],
  contemptibility: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  monotonously: [
    { word: 'monotone', word_class: 'N' },
    { word: 'monotone', word_class: 'AJ' },
    { word: 'monotonic', word_class: 'AJ' },
    { word: 'monotonous', word_class: 'AJ' },
    { word: 'monotonously', word_class: 'AV' }
  ],
  despair: [
    { word: 'despair', word_class: 'N' },
    { word: 'despair', word_class: 'V' },
    { word: 'desperate', word_class: 'N' },
    { word: 'desperate', word_class: 'AJ' },
    { word: 'despairing', word_class: 'AJ' },
    { word: 'desperation', word_class: 'N' },
    { word: 'desperately', word_class: 'AV' }
  ],
  diacritic: [
    { word: 'diacritic', word_class: 'N' },
    { word: 'diacritic', word_class: 'AJ' },
    { word: 'diacritical', word_class: 'AJ' }
  ],
  quarterly: [
    { word: 'quarterly', word_class: 'N' },
    { word: 'quarterly', word_class: 'AJ' },
    { word: 'quarterly', word_class: 'AV' }
  ],
  relieve: [
    { word: 'relief', word_class: 'N' },
    { word: 'relieve', word_class: 'V' },
    { word: 'reliever', word_class: 'N' },
    { word: 'relieved', word_class: 'AJ' }
  ],
  exclusiveness: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  wheezy: [
    { word: 'wheeze', word_class: 'N' },
    { word: 'wheeze', word_class: 'V' },
    { word: 'wheezy', word_class: 'AJ' },
    { word: 'wheezing', word_class: 'AJ' },
    { word: 'wheeziness', word_class: 'N' }
  ],
  fierce: [
    { word: 'fierce', word_class: 'AJ' },
    { word: 'fiercely', word_class: 'AV' },
    { word: 'fierceness', word_class: 'N' }
  ],
  clouded: [
    { word: 'cloud', word_class: 'N' },
    { word: 'cloud', word_class: 'V' },
    { word: 'clouded', word_class: 'V' },
    { word: 'clouded', word_class: 'AJ' },
    { word: 'clouding', word_class: 'N' }
  ],
  cenobite: [
    { word: 'cenobite', word_class: 'N' },
    { word: 'cenobitic', word_class: 'AJ' },
    { word: 'cenobitical', word_class: 'AJ' }
  ],
  endure: [
    { word: 'endure', word_class: 'V' },
    { word: 'endurance', word_class: 'N' },
    { word: 'enduring', word_class: 'AJ' },
    { word: 'endurable', word_class: 'AJ' }
  ],
  napped: [
    { word: 'nap', word_class: 'N' },
    { word: 'nap', word_class: 'V' },
    { word: 'napped', word_class: 'AJ' },
    { word: 'napping', word_class: 'AJ' }
  ],
  bowling: [
    { word: 'bowl', word_class: 'N' },
    { word: 'bowl', word_class: 'V' },
    { word: 'bowls', word_class: 'N' },
    { word: 'bowler', word_class: 'N' },
    { word: 'bowlful', word_class: 'N' },
    { word: 'bowling', word_class: 'N' },
    { word: 'bowlful', word_class: 'AJ' }
  ],
  impersonally: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  arrest: [
    { word: 'arrest', word_class: 'N' },
    { word: 'arrest', word_class: 'V' },
    { word: 'arrester', word_class: 'N' },
    { word: 'arrested', word_class: 'AJ' },
    { word: 'arresting', word_class: 'AJ' }
  ],
  commissioned: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  discontent: [
    { word: 'discontent', word_class: 'N' },
    { word: 'discontent', word_class: 'V' },
    { word: 'discontent', word_class: 'AJ' },
    { word: 'discontented', word_class: 'AJ' },
    { word: 'discontentment', word_class: 'N' }
  ],
  toil: [
    { word: 'toil', word_class: 'N' },
    { word: 'toil', word_class: 'V' },
    { word: 'toiler', word_class: 'N' },
    { word: 'toiling', word_class: 'AJ' },
    { word: 'toilsome', word_class: 'AJ' },
    { word: 'toilsomeness', word_class: 'N' }
  ],
  dry: [
    { word: 'dry', word_class: 'N' },
    { word: 'dry', word_class: 'V' },
    { word: 'dry', word_class: 'AJ' },
    { word: 'drier', word_class: 'N' },
    { word: 'dryer', word_class: 'N' },
    { word: 'dryness', word_class: 'N' }
  ],
  ethylate: [
    { word: 'ethyl', word_class: 'N' },
    { word: 'ethylate', word_class: 'V' },
    { word: 'ethylation', word_class: 'N' }
  ],
  cultivated: [
    { word: 'cultivate', word_class: 'V' },
    { word: 'cultivator', word_class: 'N' },
    { word: 'cultivable', word_class: 'AJ' },
    { word: 'cultivated', word_class: 'AJ' },
    { word: 'cultivation', word_class: 'N' }
  ],
  wondering: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  notifying: [
    { word: 'notify', word_class: 'V' },
    { word: 'notifying', word_class: 'N' },
    { word: 'notifiable', word_class: 'AJ' },
    { word: 'notification', word_class: 'N' }
  ],
  entwintwine: [
    { word: 'entwine', word_class: 'V' },
    { word: 'entwinement', word_class: 'N' },
    { word: 'entwintwine', word_class: 'V' },
    { word: 'entwintwinement', word_class: 'N' }
  ],
  equation: [
    { word: 'equate', word_class: 'V' },
    { word: 'equator', word_class: 'N' },
    { word: 'equating', word_class: 'N' },
    { word: 'equation', word_class: 'N' },
    { word: 'equatability', word_class: 'N' }
  ],
  ballot: [
    { word: 'ballot', word_class: 'N' },
    { word: 'ballot', word_class: 'V' },
    { word: 'balloting', word_class: 'N' }
  ],
  success: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  satellite: [
    { word: 'satellite', word_class: 'N' },
    { word: 'satellite', word_class: 'V' },
    { word: 'satellite', word_class: 'AJ' }
  ],
  sear: [{ word: 'sear', word_class: 'V' }, { word: 'sear', word_class: 'AJ' }, { word: 'seared', word_class: 'AJ' }],
  supervising: [
    { word: 'supervise', word_class: 'N' },
    { word: 'supervise', word_class: 'V' },
    { word: 'supervisor', word_class: 'N' },
    { word: 'supervised', word_class: 'AJ' },
    { word: 'supervising', word_class: 'N' },
    { word: 'supervision', word_class: 'N' }
  ],
  phosphor: [
    { word: 'phosphor', word_class: 'N' },
    { word: 'phosphorus', word_class: 'N' },
    { word: 'phosphoric', word_class: 'AJ' },
    { word: 'phosphorous', word_class: 'AJ' }
  ],
  blinded: [
    { word: 'blind', word_class: 'N' },
    { word: 'blind', word_class: 'V' },
    { word: 'blind', word_class: 'AJ' },
    { word: 'blinder', word_class: 'N' },
    { word: 'blinded', word_class: 'AJ' },
    { word: 'blinding', word_class: 'AJ' },
    { word: 'blindness', word_class: 'N' }
  ],
  mitigative: [
    { word: 'mitigate', word_class: 'V' },
    { word: 'mitigable', word_class: 'AJ' },
    { word: 'mitigated', word_class: 'AJ' },
    { word: 'mitigation', word_class: 'N' },
    { word: 'mitigative', word_class: 'AJ' }
  ],
  yodeller: [
    { word: 'yodel', word_class: 'N' },
    { word: 'yodel', word_class: 'V' },
    { word: 'yodeling', word_class: 'N' },
    { word: 'yodeller', word_class: 'N' }
  ],
  bandaging: [
    { word: 'bandage', word_class: 'N' },
    { word: 'bandage', word_class: 'V' },
    { word: 'bandaged', word_class: 'AJ' },
    { word: 'bandaging', word_class: 'N' }
  ],
  riding: [
    { word: 'ride', word_class: 'N' },
    { word: 'ride', word_class: 'V' },
    { word: 'rider', word_class: 'N' },
    { word: 'riding', word_class: 'N' },
    { word: 'ridden', word_class: 'AJ' },
    { word: 'riding', word_class: 'AJ' }
  ],
  anthologize: [
    { word: 'anthology', word_class: 'N' },
    { word: 'anthologize', word_class: 'V' },
    { word: 'anthologization', word_class: 'N' }
  ],
  confirm: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  accessorial: [
    { word: 'accessory', word_class: 'N' },
    { word: 'accessory', word_class: 'AJ' },
    { word: 'accessorial', word_class: 'AJ' }
  ],
  characteristic: [
    { word: 'characteristic', word_class: 'N' },
    { word: 'characteristic', word_class: 'AJ' },
    { word: 'characteristically', word_class: 'AV' }
  ],
  internationally: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  valorize: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  pharyngitis: [
    { word: 'pharynx', word_class: 'N' },
    { word: 'pharyngeal', word_class: 'AJ' },
    { word: 'pharyngitis', word_class: 'N' }
  ],
  reorient: [
    { word: 'reorient', word_class: 'V' },
    { word: 'reorientate', word_class: 'V' },
    { word: 'reorientation', word_class: 'N' }
  ],
  razing: [
    { word: 'raze', word_class: 'V' },
    { word: 'razor', word_class: 'N' },
    { word: 'razor', word_class: 'V' },
    { word: 'razed', word_class: 'AJ' },
    { word: 'razing', word_class: 'N' }
  ],
  washing: [
    { word: 'wash', word_class: 'N' },
    { word: 'wash', word_class: 'V' },
    { word: 'washer', word_class: 'N' },
    { word: 'washed', word_class: 'AJ' },
    { word: 'washing', word_class: 'N' }
  ],
  aluminization: [
    { word: 'aluminize', word_class: 'V' },
    { word: 'aluminous', word_class: 'AJ' },
    { word: 'aluminization', word_class: 'N' }
  ],
  roofer: [
    { word: 'roof', word_class: 'N' },
    { word: 'roof', word_class: 'V' },
    { word: 'roofer', word_class: 'N' },
    { word: 'roofed', word_class: 'AJ' },
    { word: 'roofing', word_class: 'N' }
  ],
  disinterest: [
    { word: 'disinterest', word_class: 'N' },
    { word: 'disinterest', word_class: 'V' },
    { word: 'disinterested', word_class: 'AJ' }
  ],
  crawl: [
    { word: 'crawl', word_class: 'N' },
    { word: 'crawl', word_class: 'V' },
    { word: 'crawler', word_class: 'N' },
    { word: 'crawling', word_class: 'N' },
    { word: 'crawling', word_class: 'AJ' }
  ],
  caressive: [
    { word: 'caress', word_class: 'N' },
    { word: 'caress', word_class: 'V' },
    { word: 'caressing', word_class: 'N' },
    { word: 'caressing', word_class: 'AJ' },
    { word: 'caressive', word_class: 'AJ' }
  ],
  proceedings: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  indulgence: [
    { word: 'indulge', word_class: 'V' },
    { word: 'indulging', word_class: 'N' },
    { word: 'indulgence', word_class: 'N' },
    { word: 'indulgent', word_class: 'AJ' },
    { word: 'indulgently', word_class: 'AV' }
  ],
  contempt: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  coolness: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  encourage: [
    { word: 'encourage', word_class: 'V' },
    { word: 'encouraged', word_class: 'AJ' },
    { word: 'encouraging', word_class: 'AJ' },
    { word: 'encouragement', word_class: 'N' }
  ],
  handling: [
    { word: 'handle', word_class: 'N' },
    { word: 'handle', word_class: 'V' },
    { word: 'handler', word_class: 'N' },
    { word: 'handled', word_class: 'AJ' },
    { word: 'handling', word_class: 'N' }
  ],
  peeling: [
    { word: 'peel', word_class: 'N' },
    { word: 'peel', word_class: 'V' },
    { word: 'peeler', word_class: 'N' },
    { word: 'peeled', word_class: 'AJ' },
    { word: 'peeling', word_class: 'AJ' }
  ],
  correctional: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  predominance: [
    { word: 'predominate', word_class: 'V' },
    { word: 'predominance', word_class: 'N' },
    { word: 'predominant', word_class: 'AJ' },
    { word: 'predominate', word_class: 'AJ' },
    { word: 'predomination', word_class: 'N' }
  ],
  apprehend: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  tendance: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  insistent: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  abysmally: [
    { word: 'abysm', word_class: 'N' },
    { word: 'abyss', word_class: 'N' },
    { word: 'abysmal', word_class: 'AJ' },
    { word: 'abyssal', word_class: 'AJ' },
    { word: 'abysmally', word_class: 'AV' }
  ],
  amazement: [
    { word: 'amaze', word_class: 'V' },
    { word: 'amazed', word_class: 'AJ' },
    { word: 'amazing', word_class: 'AJ' },
    { word: 'amazement', word_class: 'N' }
  ],
  lackadaisically: [
    { word: 'lackadaisic', word_class: 'AJ' },
    { word: 'lackadaisical', word_class: 'AJ' },
    { word: 'lackadaisically', word_class: 'AV' }
  ],
  diagonalization: [
    { word: 'diagonal', word_class: 'N' },
    { word: 'diagonal', word_class: 'AJ' },
    { word: 'diagonalize', word_class: 'V' },
    { word: 'diagonally', word_class: 'AV' },
    { word: 'diagonalization', word_class: 'N' }
  ],
  exponentiate: [
    { word: 'exponent', word_class: 'N' },
    { word: 'exponential', word_class: 'N' },
    { word: 'exponential', word_class: 'AJ' },
    { word: 'exponentiate', word_class: 'V' },
    { word: 'exponentially', word_class: 'AV' },
    { word: 'exponentiation', word_class: 'N' }
  ],
  shoot: [
    { word: 'shot', word_class: 'N' },
    { word: 'shoot', word_class: 'N' },
    { word: 'shoot', word_class: 'V' },
    { word: 'shot', word_class: 'AJ' },
    { word: 'shooter', word_class: 'N' },
    { word: 'shooting', word_class: 'N' }
  ],
  rapture: [
    { word: 'rapture', word_class: 'N' },
    { word: 'rapture', word_class: 'V' },
    { word: 'rapturous', word_class: 'AJ' },
    { word: 'rapturously', word_class: 'AV' }
  ],
  web: [
    { word: 'web', word_class: 'N' },
    { word: 'web', word_class: 'V' },
    { word: 'webbed', word_class: 'AJ' },
    { word: 'webbing', word_class: 'N' }
  ],
  precipitateness: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  purveyor: [
    { word: 'purvey', word_class: 'V' },
    { word: 'purveyor', word_class: 'N' },
    { word: 'purveyance', word_class: 'N' }
  ],
  ambitious: [
    { word: 'ambitious', word_class: 'AJ' },
    { word: 'ambitiously', word_class: 'AV' },
    { word: 'ambitiousness', word_class: 'N' }
  ],
  prosaicness: [
    { word: 'prosaic', word_class: 'AJ' },
    { word: 'prosaicness', word_class: 'N' },
    { word: 'prosaically', word_class: 'AV' }
  ],
  toast: [
    { word: 'toast', word_class: 'N' },
    { word: 'toast', word_class: 'V' },
    { word: 'toaster', word_class: 'N' },
    { word: 'toasted', word_class: 'AJ' },
    { word: 'toasting', word_class: 'N' }
  ],
  calendered: [
    { word: 'calender', word_class: 'N' },
    { word: 'calender', word_class: 'V' },
    { word: 'calendered', word_class: 'AJ' }
  ],
  beguiled: [
    { word: 'beguile', word_class: 'V' },
    { word: 'beguiled', word_class: 'AJ' },
    { word: 'beguiling', word_class: 'AJ' },
    { word: 'beguilement', word_class: 'N' }
  ],
  fixation: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  deaminisation: [
    { word: 'deaminate', word_class: 'V' },
    { word: 'deaminize', word_class: 'V' },
    { word: 'deamination', word_class: 'N' },
    { word: 'deaminisation', word_class: 'N' },
    { word: 'deaminization', word_class: 'N' }
  ],
  keratinization: [
    { word: 'keratin', word_class: 'N' },
    { word: 'keratinise', word_class: 'V' },
    { word: 'keratinize', word_class: 'V' },
    { word: 'keratinisation', word_class: 'N' },
    { word: 'keratinization', word_class: 'N' }
  ],
  invite: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  incontrovertibility: [
    { word: 'incontrovertible', word_class: 'AJ' },
    { word: 'incontrovertibly', word_class: 'AV' },
    { word: 'incontrovertibility', word_class: 'N' },
    { word: 'incontrovertibleness', word_class: 'N' }
  ],
  explain: [
    { word: 'explain', word_class: 'N' },
    { word: 'explain', word_class: 'V' },
    { word: 'explain', word_class: 'AJ' },
    { word: 'explanation', word_class: 'N' },
    { word: 'explainable', word_class: 'AJ' },
    { word: 'explanatory', word_class: 'AJ' }
  ],
  enquiry: [
    { word: 'enquire', word_class: 'V' },
    { word: 'enquiry', word_class: 'N' },
    { word: 'enquirer', word_class: 'N' }
  ],
  partaken: [
    { word: 'partake', word_class: 'V' },
    { word: 'partaker', word_class: 'N' },
    { word: 'partaken', word_class: 'AJ' }
  ],
  prior: [
    { word: 'pry', word_class: 'N' },
    { word: 'pry', word_class: 'V' },
    { word: 'prior', word_class: 'N' },
    { word: 'prior', word_class: 'AJ' },
    { word: 'prying', word_class: 'N' },
    { word: 'prying', word_class: 'AJ' }
  ],
  associative: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  masking: [
    { word: 'mask', word_class: 'N' },
    { word: 'mask', word_class: 'V' },
    { word: 'masked', word_class: 'AJ' },
    { word: 'masking', word_class: 'N' },
    { word: 'masking', word_class: 'AJ' }
  ],
  torrifaction: [
    { word: 'torrefy', word_class: 'V' },
    { word: 'torrify', word_class: 'V' },
    { word: 'torrefaction', word_class: 'N' },
    { word: 'torrifaction', word_class: 'N' }
  ],
  satyr: [
    { word: 'satyr', word_class: 'N' },
    { word: 'satyric', word_class: 'AJ' },
    { word: 'satyrical', word_class: 'AJ' }
  ],
  sceptically: [
    { word: 'sceptic', word_class: 'N' },
    { word: 'sceptical', word_class: 'AJ' },
    { word: 'scepticism', word_class: 'N' },
    { word: 'sceptically', word_class: 'AV' }
  ],
  epitome: [
    { word: 'epitome', word_class: 'N' },
    { word: 'epitomize', word_class: 'V' },
    { word: 'epitomization', word_class: 'N' }
  ],
  generation: [
    { word: 'generate', word_class: 'V' },
    { word: 'generator', word_class: 'N' },
    { word: 'generation', word_class: 'N' },
    { word: 'generative', word_class: 'AJ' }
  ],
  regression: [
    { word: 'regress', word_class: 'N' },
    { word: 'regress', word_class: 'V' },
    { word: 'regression', word_class: 'N' },
    { word: 'regressing', word_class: 'AJ' },
    { word: 'regressive', word_class: 'AJ' }
  ],
  subminiaturize: [
    { word: 'subminiaturise', word_class: 'V' },
    { word: 'subminiaturize', word_class: 'V' },
    { word: 'subminiaturisation', word_class: 'N' },
    { word: 'subminiaturization', word_class: 'N' }
  ],
  nefariously: [
    { word: 'nefarious', word_class: 'AJ' },
    { word: 'nefariously', word_class: 'AV' },
    { word: 'nefariousness', word_class: 'N' }
  ],
  mechanics: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  challengeable: [
    { word: 'challenge', word_class: 'N' },
    { word: 'challenge', word_class: 'V' },
    { word: 'challenger', word_class: 'N' },
    { word: 'challenging', word_class: 'AJ' },
    { word: 'challengeable', word_class: 'AJ' }
  ],
  blissfulness: [
    { word: 'bliss', word_class: 'N' },
    { word: 'blissful', word_class: 'AJ' },
    { word: 'blissfulness', word_class: 'N' }
  ],
  coolant: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  penalisation: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  build: [
    { word: 'build', word_class: 'N' },
    { word: 'build', word_class: 'V' },
    { word: 'builder', word_class: 'N' },
    { word: 'building', word_class: 'N' },
    { word: 'building', word_class: 'AJ' }
  ],
  scornful: [
    { word: 'scorn', word_class: 'N' },
    { word: 'scorn', word_class: 'V' },
    { word: 'scorned', word_class: 'AJ' },
    { word: 'scornful', word_class: 'AJ' }
  ],
  integrate: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  waster: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  rock: [{ word: 'rock', word_class: 'N' }, { word: 'rock', word_class: 'V' }, { word: 'rocker', word_class: 'N' }],
  failure: [
    { word: 'fail', word_class: 'V' },
    { word: 'failed', word_class: 'AJ' },
    { word: 'failing', word_class: 'N' },
    { word: 'failure', word_class: 'N' },
    { word: 'failing', word_class: 'AJ' }
  ],
  perceived: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  ooze: [
    { word: 'ooze', word_class: 'N' },
    { word: 'ooze', word_class: 'V' },
    { word: 'oozy', word_class: 'AJ' },
    { word: 'oozing', word_class: 'N' },
    { word: 'oozing', word_class: 'AJ' }
  ],
  chagrin: [
    { word: 'chagrin', word_class: 'N' },
    { word: 'chagrin', word_class: 'V' },
    { word: 'chagrined', word_class: 'AJ' }
  ],
  funded: [
    { word: 'fund', word_class: 'N' },
    { word: 'fund', word_class: 'V' },
    { word: 'funds', word_class: 'N' },
    { word: 'funded', word_class: 'AJ' },
    { word: 'funding', word_class: 'N' }
  ],
  constructiveness: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  supportive: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  snake: [{ word: 'snake', word_class: 'N' }, { word: 'snake', word_class: 'V' }, { word: 'snaky', word_class: 'AJ' }],
  rebuke: [
    { word: 'rebuke', word_class: 'N' },
    { word: 'rebuke', word_class: 'V' },
    { word: 'rebuked', word_class: 'AJ' }
  ],
  patrolled: [
    { word: 'patrol', word_class: 'N' },
    { word: 'patrol', word_class: 'V' },
    { word: 'patroller', word_class: 'N' },
    { word: 'patrolman', word_class: 'N' },
    { word: 'patrolled', word_class: 'AJ' },
    { word: 'patrolling', word_class: 'N' },
    { word: 'patrolling', word_class: 'AJ' }
  ],
  exquisiteness: [
    { word: 'exquisite', word_class: 'AJ' },
    { word: 'exquisitely', word_class: 'AV' },
    { word: 'exquisiteness', word_class: 'N' }
  ],
  opsonification: [
    { word: 'opsonize', word_class: 'V' },
    { word: 'opsonisation', word_class: 'N' },
    { word: 'opsonization', word_class: 'N' },
    { word: 'opsonification', word_class: 'N' }
  ],
  commuter: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  commandment: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  relinquished: [
    { word: 'relinquish', word_class: 'V' },
    { word: 'relinquished', word_class: 'AJ' },
    { word: 'relinquishing', word_class: 'N' },
    { word: 'relinquishment', word_class: 'N' }
  ],
  dispense: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  feminise: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  override: [
    { word: 'override', word_class: 'N' },
    { word: 'override', word_class: 'V' },
    { word: 'overriding', word_class: 'AJ' }
  ],
  tailor: [
    { word: 'tailor', word_class: 'N' },
    { word: 'tailor', word_class: 'V' },
    { word: 'tailored', word_class: 'AJ' },
    { word: 'tailoring', word_class: 'N' }
  ],
  northwester: [
    { word: 'northwest', word_class: 'N' },
    { word: 'northwest', word_class: 'AJ' },
    { word: 'northwest', word_class: 'AV' },
    { word: 'northwester', word_class: 'N' }
  ],
  intransigence: [
    { word: 'intransigence', word_class: 'N' },
    { word: 'intransigency', word_class: 'N' },
    { word: 'intransigent', word_class: 'AJ' }
  ],
  allegorical: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  acculturational: [
    { word: 'acculturate', word_class: 'V' },
    { word: 'acculturation', word_class: 'N' },
    { word: 'acculturative', word_class: 'AJ' },
    { word: 'acculturational', word_class: 'AJ' }
  ],
  consanguineous: [
    { word: 'consanguine', word_class: 'AJ' },
    { word: 'consanguinity', word_class: 'N' },
    { word: 'consanguineous', word_class: 'AJ' }
  ],
  staggerer: [
    { word: 'stagger', word_class: 'N' },
    { word: 'stagger', word_class: 'V' },
    { word: 'staggerer', word_class: 'N' },
    { word: 'staggering', word_class: 'AJ' }
  ],
  monthly: [
    { word: 'monthly', word_class: 'N' },
    { word: 'monthly', word_class: 'AJ' },
    { word: 'monthly', word_class: 'AV' }
  ],
  consume: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  sitting: [
    { word: 'sit', word_class: 'V' },
    { word: 'sitting', word_class: 'N' },
    { word: 'sitting', word_class: 'AJ' }
  ],
  superior: [
    { word: 'superior', word_class: 'N' },
    { word: 'superior', word_class: 'AJ' },
    { word: 'superiority', word_class: 'N' }
  ],
  intellect: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  dine: [
    { word: 'din', word_class: 'N' },
    { word: 'din', word_class: 'V' },
    { word: 'dine', word_class: 'V' },
    { word: 'diner', word_class: 'N' },
    { word: 'dining', word_class: 'N' }
  ],
  isometric: [
    { word: 'isometric', word_class: 'AJ' },
    { word: 'isometrics', word_class: 'N' },
    { word: 'isometrical', word_class: 'AJ' }
  ],
  decline: [
    { word: 'decline', word_class: 'N' },
    { word: 'decline', word_class: 'V' },
    { word: 'declining', word_class: 'AJ' },
    { word: 'declination', word_class: 'N' }
  ],
  realizable: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  page: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  contrasting: [
    { word: 'contrast', word_class: 'N' },
    { word: 'contrast', word_class: 'V' },
    { word: 'contrasting', word_class: 'AJ' },
    { word: 'contrastive', word_class: 'AJ' }
  ],
  vocalizing: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  wily: [{ word: 'wile', word_class: 'N' }, { word: 'wily', word_class: 'AJ' }, { word: 'wiliness', word_class: 'N' }],
  stab: [
    { word: 'stab', word_class: 'N' },
    { word: 'stab', word_class: 'V' },
    { word: 'stabbed', word_class: 'AJ' },
    { word: 'stabbing', word_class: 'AJ' }
  ],
  converging: [
    { word: 'converge', word_class: 'V' },
    { word: 'converging', word_class: 'N' },
    { word: 'convergence', word_class: 'N' },
    { word: 'convergency', word_class: 'N' },
    { word: 'convergent', word_class: 'AJ' },
    { word: 'converging', word_class: 'AJ' }
  ],
  criminal: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  pretension: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  deal: [
    { word: 'deal', word_class: 'N' },
    { word: 'deal', word_class: 'V' },
    { word: 'deal', word_class: 'AJ' },
    { word: 'dealer', word_class: 'N' },
    { word: 'dealing', word_class: 'N' },
    { word: 'dealings', word_class: 'N' }
  ],
  impurity: [
    { word: 'impure', word_class: 'AJ' },
    { word: 'impurity', word_class: 'N' },
    { word: 'impureness', word_class: 'N' }
  ],
  reprint: [
    { word: 'reprint', word_class: 'N' },
    { word: 'reprint', word_class: 'V' },
    { word: 'reprinting', word_class: 'N' }
  ],
  fraternity: [
    { word: 'fraternal', word_class: 'AJ' },
    { word: 'fraternity', word_class: 'N' },
    { word: 'fraternize', word_class: 'V' },
    { word: 'fraternally', word_class: 'AV' },
    { word: 'fraternization', word_class: 'N' }
  ],
  suspended: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  healed: [
    { word: 'heal', word_class: 'V' },
    { word: 'healer', word_class: 'N' },
    { word: 'healed', word_class: 'AJ' },
    { word: 'healing', word_class: 'N' },
    { word: 'healing', word_class: 'AJ' }
  ],
  corpulency: [
    { word: 'corpulence', word_class: 'N' },
    { word: 'corpulency', word_class: 'N' },
    { word: 'corpulent', word_class: 'AJ' }
  ],
  imprecisely: [
    { word: 'imprecise', word_class: 'AJ' },
    { word: 'imprecision', word_class: 'N' },
    { word: 'imprecisely', word_class: 'AV' },
    { word: 'impreciseness', word_class: 'N' }
  ],
  physic: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  wakeful: [
    { word: 'wake', word_class: 'N' },
    { word: 'wake', word_class: 'V' },
    { word: 'waking', word_class: 'N' },
    { word: 'woken', word_class: 'AJ' },
    { word: 'waking', word_class: 'AJ' },
    { word: 'wakeful', word_class: 'AJ' },
    { word: 'wakefulness', word_class: 'N' }
  ],
  metallisation: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  eminently: [
    { word: 'eminence', word_class: 'N' },
    { word: 'eminent', word_class: 'AJ' },
    { word: 'eminently', word_class: 'AV' }
  ],
  rebellion: [
    { word: 'rebel', word_class: 'N' },
    { word: 'rebel', word_class: 'V' },
    { word: 'rebel', word_class: 'AJ' },
    { word: 'rebellion', word_class: 'N' },
    { word: 'rebelling', word_class: 'AJ' }
  ],
  aesthetic: [
    { word: 'aesthete', word_class: 'N' },
    { word: 'aesthetic', word_class: 'N' },
    { word: 'aesthetic', word_class: 'AJ' },
    { word: 'aesthetics', word_class: 'N' },
    { word: 'aesthetical', word_class: 'AJ' },
    { word: 'aesthetically', word_class: 'AV' }
  ],
  corroborated: [
    { word: 'corroborate', word_class: 'V' },
    { word: 'corroborant', word_class: 'AJ' },
    { word: 'corroborated', word_class: 'AJ' },
    { word: 'corroboration', word_class: 'N' },
    { word: 'corroborative', word_class: 'AJ' }
  ],
  extempore: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  universal: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  lionise: [
    { word: 'lionise', word_class: 'V' },
    { word: 'lionize', word_class: 'V' },
    { word: 'lionisation', word_class: 'N' },
    { word: 'lionization', word_class: 'N' }
  ],
  untouchable: [
    { word: 'untouched', word_class: 'AJ' },
    { word: 'untouchable', word_class: 'N' },
    { word: 'untouchable', word_class: 'AJ' }
  ],
  instruction: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  abdominal: [
    { word: 'abdomen', word_class: 'N' },
    { word: 'abdominal', word_class: 'AJ' },
    { word: 'abdominous', word_class: 'AJ' },
    { word: 'abdominousness', word_class: 'N' }
  ],
  cornhusker: [
    { word: 'cornhusk', word_class: 'N' },
    { word: 'cornhusker', word_class: 'N' },
    { word: 'cornhusking', word_class: 'N' }
  ],
  censored: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  popular: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  upstate: [
    { word: 'upstate', word_class: 'N' },
    { word: 'upstate', word_class: 'AJ' },
    { word: 'upstate', word_class: 'AV' },
    { word: 'upstater', word_class: 'N' }
  ],
  memorialize: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  cows: [
    { word: 'cow', word_class: 'N' },
    { word: 'cow', word_class: 'V' },
    { word: 'cows', word_class: 'N' },
    { word: 'cowed', word_class: 'AJ' },
    { word: 'cowman', word_class: 'N' }
  ],
  assigning: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  problematic: [
    { word: 'problem', word_class: 'N' },
    { word: 'problematic', word_class: 'AJ' },
    { word: 'problematical', word_class: 'AJ' },
    { word: 'problematically', word_class: 'AV' }
  ],
  proposed: [
    { word: 'propose', word_class: 'N' },
    { word: 'propose', word_class: 'V' },
    { word: 'proposal', word_class: 'N' },
    { word: 'proposer', word_class: 'N' },
    { word: 'proposed', word_class: 'AJ' }
  ],
  practical: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  bonded: [
    { word: 'bond', word_class: 'N' },
    { word: 'bond', word_class: 'V' },
    { word: 'bond', word_class: 'AJ' },
    { word: 'bonded', word_class: 'AJ' },
    { word: 'bonding', word_class: 'N' },
    { word: 'bondman', word_class: 'N' }
  ],
  underestimate: [
    { word: 'underestimate', word_class: 'N' },
    { word: 'underestimate', word_class: 'V' },
    { word: 'underestimation', word_class: 'N' }
  ],
  purposely: [
    { word: 'purpose', word_class: 'N' },
    { word: 'purpose', word_class: 'V' },
    { word: 'purposely', word_class: 'AV' },
    { word: 'purposive', word_class: 'AJ' },
    { word: 'purposeful', word_class: 'AJ' },
    { word: 'purposefulness', word_class: 'N' }
  ],
  social: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  separator: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  tumid: [
    { word: 'tumid', word_class: 'AJ' },
    { word: 'tumidity', word_class: 'N' },
    { word: 'tumidness', word_class: 'N' }
  ],
  inflation: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  uxoriously: [
    { word: 'uxorial', word_class: 'AJ' },
    { word: 'uxorious', word_class: 'AJ' },
    { word: 'uxoriously', word_class: 'AV' },
    { word: 'uxoriousness', word_class: 'N' }
  ],
  collapse: [
    { word: 'collapse', word_class: 'N' },
    { word: 'collapse', word_class: 'V' },
    { word: 'collapsed', word_class: 'AJ' },
    { word: 'collapsable', word_class: 'AJ' },
    { word: 'collapsible', word_class: 'AJ' }
  ],
  unavailability: [
    { word: 'unavailing', word_class: 'AJ' },
    { word: 'unavailable', word_class: 'AJ' },
    { word: 'unavailability', word_class: 'N' }
  ],
  labialisation: [
    { word: 'labial', word_class: 'N' },
    { word: 'labial', word_class: 'AJ' },
    { word: 'labialise', word_class: 'V' },
    { word: 'labialize', word_class: 'V' },
    { word: 'labialisation', word_class: 'N' },
    { word: 'labialization', word_class: 'N' }
  ],
  creased: [
    { word: 'crease', word_class: 'N' },
    { word: 'crease', word_class: 'V' },
    { word: 'creased', word_class: 'AJ' }
  ],
  incongruously: [
    { word: 'incongruity', word_class: 'N' },
    { word: 'incongruent', word_class: 'AJ' },
    { word: 'incongruous', word_class: 'AJ' },
    { word: 'incongruously', word_class: 'AV' },
    { word: 'incongruousness', word_class: 'N' }
  ],
  incarnation: [
    { word: 'incarnate', word_class: 'V' },
    { word: 'incarnate', word_class: 'AJ' },
    { word: 'incarnation', word_class: 'N' }
  ],
  biological: [
    { word: 'biologic', word_class: 'AJ' },
    { word: 'biologism', word_class: 'N' },
    { word: 'biological', word_class: 'AJ' },
    { word: 'biologically', word_class: 'AV' }
  ],
  buzzing: [
    { word: 'buzz', word_class: 'N' },
    { word: 'buzz', word_class: 'V' },
    { word: 'buzzer', word_class: 'N' },
    { word: 'buzzing', word_class: 'AJ' }
  ],
  amusing: [
    { word: 'amuse', word_class: 'V' },
    { word: 'amused', word_class: 'AJ' },
    { word: 'amusing', word_class: 'AJ' },
    { word: 'amusive', word_class: 'AJ' },
    { word: 'amusement', word_class: 'N' }
  ],
  smothering: [
    { word: 'smother', word_class: 'N' },
    { word: 'smother', word_class: 'V' },
    { word: 'smothered', word_class: 'AJ' },
    { word: 'smothering', word_class: 'AJ' }
  ],
  concurring: [
    { word: 'concur', word_class: 'V' },
    { word: 'concurrence', word_class: 'N' },
    { word: 'concurrent', word_class: 'AJ' },
    { word: 'concurring', word_class: 'AJ' },
    { word: 'concurrently', word_class: 'AV' }
  ],
  masse: [
    { word: 'mass', word_class: 'N' },
    { word: 'mass', word_class: 'V' },
    { word: 'mass', word_class: 'AJ' },
    { word: 'masse', word_class: 'N' },
    { word: 'masses', word_class: 'N' },
    { word: 'massed', word_class: 'AJ' }
  ],
  mercerize: [
    { word: 'mercer', word_class: 'N' },
    { word: 'mercerise', word_class: 'V' },
    { word: 'mercerize', word_class: 'V' },
    { word: 'mercerisation', word_class: 'N' },
    { word: 'mercerization', word_class: 'N' }
  ],
  horticulture: [
    { word: 'horticulture', word_class: 'N' },
    { word: 'horticultural', word_class: 'AJ' },
    { word: 'horticulturally', word_class: 'AV' }
  ],
  configure: [
    { word: 'configure', word_class: 'V' },
    { word: 'configured', word_class: 'AJ' },
    { word: 'configuration', word_class: 'N' },
    { word: 'configurational', word_class: 'AJ' }
  ],
  ratting: [{ word: 'rat', word_class: 'N' }, { word: 'rat', word_class: 'V' }, { word: 'ratting', word_class: 'N' }],
  latinization: [
    { word: 'latin', word_class: 'N' },
    { word: 'latin', word_class: 'AJ' },
    { word: 'latinize', word_class: 'V' },
    { word: 'latinist', word_class: 'N' },
    { word: 'latinate', word_class: 'AJ' },
    { word: 'latinization', word_class: 'N' }
  ],
  removed: [
    { word: 'remove', word_class: 'N' },
    { word: 'remove', word_class: 'V' },
    { word: 'removal', word_class: 'N' },
    { word: 'remover', word_class: 'N' },
    { word: 'removed', word_class: 'AJ' },
    { word: 'removable', word_class: 'AJ' }
  ],
  cityfied: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  salvage: [
    { word: 'salvage', word_class: 'N' },
    { word: 'salvage', word_class: 'V' },
    { word: 'salvager', word_class: 'N' },
    { word: 'salvageable', word_class: 'AJ' }
  ],
  peptonise: [
    { word: 'peptone', word_class: 'N' },
    { word: 'peptonise', word_class: 'V' },
    { word: 'peptonize', word_class: 'V' },
    { word: 'peptonisation', word_class: 'N' },
    { word: 'peptonization', word_class: 'N' }
  ],
  virulently: [
    { word: 'virulence', word_class: 'N' },
    { word: 'virulency', word_class: 'N' },
    { word: 'virulent', word_class: 'AJ' },
    { word: 'virulently', word_class: 'AV' }
  ],
  bed: [
    { word: 'bed', word_class: 'N' },
    { word: 'bed', word_class: 'V' },
    { word: 'bedded', word_class: 'AJ' },
    { word: 'bedding', word_class: 'N' }
  ],
  drained: [
    { word: 'drain', word_class: 'N' },
    { word: 'drain', word_class: 'V' },
    { word: 'drainage', word_class: 'N' },
    { word: 'drained', word_class: 'AJ' },
    { word: 'draining', word_class: 'AJ' }
  ],
  woolen: [
    { word: 'wool', word_class: 'N' },
    { word: 'woolen', word_class: 'N' },
    { word: 'woolen', word_class: 'AJ' },
    { word: 'woollen', word_class: 'N' },
    { word: 'woollen', word_class: 'AJ' }
  ],
  coquet: [
    { word: 'coquet', word_class: 'N' },
    { word: 'coquet', word_class: 'V' },
    { word: 'coquetry', word_class: 'N' }
  ],
  distorted: [
    { word: 'distort', word_class: 'N' },
    { word: 'distort', word_class: 'V' },
    { word: 'distorted', word_class: 'AJ' },
    { word: 'distortion', word_class: 'N' },
    { word: 'distortable', word_class: 'AJ' }
  ],
  holler: [
    { word: 'holler', word_class: 'N' },
    { word: 'holler', word_class: 'V' },
    { word: 'hollering', word_class: 'N' }
  ],
  resplendency: [
    { word: 'resplendence', word_class: 'N' },
    { word: 'resplendency', word_class: 'N' },
    { word: 'resplendent', word_class: 'AJ' },
    { word: 'resplendently', word_class: 'AV' }
  ],
  subdue: [
    { word: 'subdue', word_class: 'N' },
    { word: 'subdue', word_class: 'V' },
    { word: 'subdual', word_class: 'N' },
    { word: 'subdue', word_class: 'AJ' },
    { word: 'subdued', word_class: 'AJ' }
  ],
  probable: [
    { word: 'probable', word_class: 'N' },
    { word: 'probable', word_class: 'AJ' },
    { word: 'probably', word_class: 'AV' },
    { word: 'probability', word_class: 'N' }
  ],
  reviled: [
    { word: 'revile', word_class: 'V' },
    { word: 'revile', word_class: 'AJ' },
    { word: 'reviled', word_class: 'AJ' },
    { word: 'revilement', word_class: 'N' }
  ],
  syllabify: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  spangled: [
    { word: 'spangle', word_class: 'N' },
    { word: 'spangle', word_class: 'V' },
    { word: 'spangled', word_class: 'AJ' }
  ],
  westward: [
    { word: 'westward', word_class: 'AJ' },
    { word: 'westward', word_class: 'AV' },
    { word: 'westwards', word_class: 'AV' }
  ],
  hygienize: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  frightened: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  halogen: [
    { word: 'halogen', word_class: 'N' },
    { word: 'halogenate', word_class: 'V' },
    { word: 'halogenation', word_class: 'N' }
  ],
  audit: [
    { word: 'audit', word_class: 'N' },
    { word: 'audit', word_class: 'V' },
    { word: 'auditor', word_class: 'N' },
    { word: 'audition', word_class: 'N' },
    { word: 'audition', word_class: 'V' },
    { word: 'auditive', word_class: 'AJ' }
  ],
  fag: [{ word: 'fag', word_class: 'N' }, { word: 'fag', word_class: 'V' }, { word: 'fagged', word_class: 'AJ' }],
  enunciation: [
    { word: 'enunciate', word_class: 'V' },
    { word: 'enunciable', word_class: 'AJ' },
    { word: 'enunciation', word_class: 'N' }
  ],
  effacement: [
    { word: 'efface', word_class: 'V' },
    { word: 'effacement', word_class: 'N' },
    { word: 'effaceable', word_class: 'AJ' }
  ],
  come: [
    { word: 'come', word_class: 'V' },
    { word: 'come', word_class: 'AJ' },
    { word: 'comer', word_class: 'N' },
    { word: 'coming', word_class: 'N' },
    { word: 'comely', word_class: 'AJ' },
    { word: 'coming', word_class: 'AJ' }
  ],
  spheric: [
    { word: 'spheric', word_class: 'AJ' },
    { word: 'spherical', word_class: 'AJ' },
    { word: 'sphericity', word_class: 'N' },
    { word: 'spherically', word_class: 'AV' },
    { word: 'sphericalness', word_class: 'N' }
  ],
  nursed: [
    { word: 'nurse', word_class: 'N' },
    { word: 'nurse', word_class: 'V' },
    { word: 'nursed', word_class: 'AJ' },
    { word: 'nursing', word_class: 'N' }
  ],
  parallel: [
    { word: 'parallel', word_class: 'N' },
    { word: 'parallel', word_class: 'V' },
    { word: 'parallel', word_class: 'AJ' },
    { word: 'parallelism', word_class: 'N' },
    { word: 'parallelize', word_class: 'V' }
  ],
  subvert: [
    { word: 'subvert', word_class: 'V' },
    { word: 'subverter', word_class: 'N' },
    { word: 'subversion', word_class: 'N' },
    { word: 'subversive', word_class: 'N' },
    { word: 'subversive', word_class: 'AJ' },
    { word: 'subversiveness', word_class: 'N' }
  ],
  overeating: [
    { word: 'over', word_class: 'N' },
    { word: 'over', word_class: 'AJ' },
    { word: 'over', word_class: 'AV' },
    { word: 'overeating', word_class: 'N' }
  ],
  circle: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  scavenging: [
    { word: 'scavenge', word_class: 'V' },
    { word: 'scavenger', word_class: 'N' },
    { word: 'scavenging', word_class: 'AJ' }
  ],
  negotiable: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  famish: [
    { word: 'famish', word_class: 'V' },
    { word: 'famish', word_class: 'AJ' },
    { word: 'famished', word_class: 'AJ' },
    { word: 'famishment', word_class: 'N' }
  ],
  pissing: [
    { word: 'piss', word_class: 'N' },
    { word: 'piss', word_class: 'V' },
    { word: 'pissed', word_class: 'AJ' },
    { word: 'pissing', word_class: 'N' }
  ],
  enate: [
    { word: 'enate', word_class: 'N' },
    { word: 'enate', word_class: 'AJ' },
    { word: 'enatic', word_class: 'AJ' },
    { word: 'enation', word_class: 'N' }
  ],
  driver: [
    { word: 'drive', word_class: 'N' },
    { word: 'drive', word_class: 'V' },
    { word: 'driver', word_class: 'N' },
    { word: 'driven', word_class: 'AJ' },
    { word: 'driving', word_class: 'N' },
    { word: 'driving', word_class: 'AJ' }
  ],
  cager: [
    { word: 'cage', word_class: 'N' },
    { word: 'cage', word_class: 'V' },
    { word: 'cager', word_class: 'N' },
    { word: 'caged', word_class: 'AJ' }
  ],
  smoothed: [
    { word: 'smooth', word_class: 'N' },
    { word: 'smooth', word_class: 'V' },
    { word: 'smooth', word_class: 'AJ' },
    { word: 'smoother', word_class: 'N' },
    { word: 'smoothed', word_class: 'AJ' },
    { word: 'smoothness', word_class: 'N' }
  ],
  questionable: [
    { word: 'question', word_class: 'N' },
    { word: 'question', word_class: 'V' },
    { word: 'questioner', word_class: 'N' },
    { word: 'questioning', word_class: 'N' },
    { word: 'questioning', word_class: 'AJ' },
    { word: 'questionable', word_class: 'AJ' },
    { word: 'questionably', word_class: 'AV' }
  ],
  rascal: [
    { word: 'rascal', word_class: 'N' },
    { word: 'rascality', word_class: 'N' },
    { word: 'rascally', word_class: 'AJ' }
  ],
  numerate: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  fabric: [
    { word: 'fabric', word_class: 'N' },
    { word: 'fabricate', word_class: 'V' },
    { word: 'fabricator', word_class: 'N' },
    { word: 'fabricated', word_class: 'AJ' },
    { word: 'fabrication', word_class: 'N' }
  ],
  cypriote: [
    { word: 'cypriot', word_class: 'N' },
    { word: 'cypriot', word_class: 'AJ' },
    { word: 'cypriote', word_class: 'N' },
    { word: 'cypriote', word_class: 'AJ' }
  ],
  conjugally: [
    { word: 'conjugal', word_class: 'AJ' },
    { word: 'conjugate', word_class: 'N' },
    { word: 'conjugate', word_class: 'V' },
    { word: 'conjugate', word_class: 'AJ' },
    { word: 'conjugally', word_class: 'AV' },
    { word: 'conjugated', word_class: 'AJ' },
    { word: 'conjugation', word_class: 'N' }
  ],
  agreeable: [
    { word: 'agreeable', word_class: 'AJ' },
    { word: 'agreeably', word_class: 'AV' },
    { word: 'agreeability', word_class: 'N' },
    { word: 'agreeableness', word_class: 'N' }
  ],
  remittent: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  barker: [{ word: 'bark', word_class: 'N' }, { word: 'bark', word_class: 'V' }, { word: 'barker', word_class: 'N' }],
  musically: [
    { word: 'music', word_class: 'N' },
    { word: 'musical', word_class: 'N' },
    { word: 'musical', word_class: 'AJ' },
    { word: 'musicality', word_class: 'N' },
    { word: 'musically', word_class: 'AV' },
    { word: 'musicalness', word_class: 'N' }
  ],
  quiescent: [
    { word: 'quiescence', word_class: 'N' },
    { word: 'quiescency', word_class: 'N' },
    { word: 'quiescent', word_class: 'AJ' }
  ],
  ordinariness: [
    { word: 'ordinary', word_class: 'N' },
    { word: 'ordinary', word_class: 'AJ' },
    { word: 'ordinariness', word_class: 'N' }
  ],
  bilobed: [
    { word: 'bilobed', word_class: 'AJ' },
    { word: 'bilobate', word_class: 'AJ' },
    { word: 'bilobated', word_class: 'AJ' }
  ],
  recalcitrancy: [
    { word: 'recalcitrance', word_class: 'N' },
    { word: 'recalcitrancy', word_class: 'N' },
    { word: 'recalcitrant', word_class: 'AJ' }
  ],
  disclamation: [
    { word: 'disclaim', word_class: 'V' },
    { word: 'disclaimer', word_class: 'N' },
    { word: 'disclamation', word_class: 'N' }
  ],
  bib: [{ word: 'bib', word_class: 'N' }, { word: 'bib', word_class: 'V' }, { word: 'bibbed', word_class: 'AJ' }],
  unmanageableness: [
    { word: 'unmanageable', word_class: 'AJ' },
    { word: 'unmanageably', word_class: 'AV' },
    { word: 'unmanageableness', word_class: 'N' }
  ],
  arbitrative: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  mum: [{ word: 'mum', word_class: 'N' }, { word: 'mum', word_class: 'V' }, { word: 'mum', word_class: 'AJ' }],
  fundamentalistic: [
    { word: 'fundamentalist', word_class: 'N' },
    { word: 'fundamentalist', word_class: 'AJ' },
    { word: 'fundamentalistic', word_class: 'AJ' }
  ],
  dither: [
    { word: 'dither', word_class: 'N' },
    { word: 'dither', word_class: 'V' },
    { word: 'dithering', word_class: 'N' }
  ],
  rifled: [
    { word: 'rifle', word_class: 'N' },
    { word: 'rifle', word_class: 'V' },
    { word: 'rifled', word_class: 'AJ' },
    { word: 'rifling', word_class: 'N' },
    { word: 'rifleman', word_class: 'N' }
  ],
  bombardment: [
    { word: 'bombard', word_class: 'N' },
    { word: 'bombard', word_class: 'V' },
    { word: 'bombardment', word_class: 'N' }
  ],
  thrasher: [
    { word: 'thrash', word_class: 'N' },
    { word: 'thrash', word_class: 'V' },
    { word: 'thrasher', word_class: 'N' },
    { word: 'thrashing', word_class: 'N' },
    { word: 'thrashing', word_class: 'AJ' }
  ],
  trembly: [
    { word: 'tremble', word_class: 'N' },
    { word: 'tremble', word_class: 'V' },
    { word: 'trembles', word_class: 'N' },
    { word: 'trembly', word_class: 'AJ' },
    { word: 'trembling', word_class: 'N' },
    { word: 'trembling', word_class: 'AJ' }
  ],
  indigestibleness: [
    { word: 'indigestion', word_class: 'N' },
    { word: 'indigestible', word_class: 'AJ' },
    { word: 'indigestibility', word_class: 'N' },
    { word: 'indigestibleness', word_class: 'N' }
  ],
  nimbleness: [
    { word: 'nimble', word_class: 'AJ' },
    { word: 'nimbly', word_class: 'AV' },
    { word: 'nimbleness', word_class: 'N' }
  ],
  upstater: [
    { word: 'upstate', word_class: 'N' },
    { word: 'upstate', word_class: 'AJ' },
    { word: 'upstate', word_class: 'AV' },
    { word: 'upstater', word_class: 'N' }
  ],
  conditioner: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  bifurcation: [
    { word: 'bifurcate', word_class: 'V' },
    { word: 'bifurcate', word_class: 'AJ' },
    { word: 'bifurcated', word_class: 'AJ' },
    { word: 'bifurcation', word_class: 'N' }
  ],
  emerging: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  approaching: [
    { word: 'approach', word_class: 'N' },
    { word: 'approach', word_class: 'V' },
    { word: 'approaching', word_class: 'N' },
    { word: 'approaching', word_class: 'AJ' },
    { word: 'approachable', word_class: 'AJ' },
    { word: 'approachability', word_class: 'N' }
  ],
  guard: [
    { word: 'guard', word_class: 'N' },
    { word: 'guard', word_class: 'V' },
    { word: 'guarded', word_class: 'AJ' },
    { word: 'guarding', word_class: 'N' }
  ],
  vanful: [{ word: 'van', word_class: 'N' }, { word: 'vanful', word_class: 'N' }, { word: 'vanful', word_class: 'AJ' }],
  denominate: [
    { word: 'denominate', word_class: 'V' },
    { word: 'denominator', word_class: 'N' },
    { word: 'denomination', word_class: 'N' },
    { word: 'denominational', word_class: 'AJ' }
  ],
  sneer: [
    { word: 'sneer', word_class: 'N' },
    { word: 'sneer', word_class: 'V' },
    { word: 'sneering', word_class: 'N' },
    { word: 'sneering', word_class: 'AJ' }
  ],
  disjoin: [
    { word: 'disjoin', word_class: 'V' },
    { word: 'disjunct', word_class: 'N' },
    { word: 'disjunct', word_class: 'AJ' },
    { word: 'disjoined', word_class: 'AJ' },
    { word: 'disjunction', word_class: 'N' },
    { word: 'disjunctive', word_class: 'AJ' }
  ],
  incomplete: [
    { word: 'incomplete', word_class: 'AJ' },
    { word: 'incompletion', word_class: 'N' },
    { word: 'incompletely', word_class: 'AV' },
    { word: 'incompleteness', word_class: 'N' }
  ],
  lied: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  humbled: [
    { word: 'humble', word_class: 'V' },
    { word: 'humble', word_class: 'AJ' },
    { word: 'humbly', word_class: 'AV' },
    { word: 'humbled', word_class: 'AJ' },
    { word: 'humbling', word_class: 'N' },
    { word: 'humbling', word_class: 'AJ' },
    { word: 'humbleness', word_class: 'N' }
  ],
  obtainable: [
    { word: 'obtain', word_class: 'V' },
    { word: 'obtainment', word_class: 'N' },
    { word: 'obtainable', word_class: 'AJ' }
  ],
  booted: [
    { word: 'boot', word_class: 'N' },
    { word: 'boot', word_class: 'V' },
    { word: 'bootee', word_class: 'N' },
    { word: 'bootes', word_class: 'N' },
    { word: 'booted', word_class: 'AJ' },
    { word: 'bootees', word_class: 'N' }
  ],
  reputable: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  purge: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  sensitiveness: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  revaluation: [
    { word: 'revalue', word_class: 'V' },
    { word: 'revaluate', word_class: 'V' },
    { word: 'revaluation', word_class: 'N' }
  ],
  infuse: [
    { word: 'infuse', word_class: 'N' },
    { word: 'infuse', word_class: 'V' },
    { word: 'infusion', word_class: 'N' }
  ],
  rumble: [
    { word: 'rumble', word_class: 'N' },
    { word: 'rumble', word_class: 'V' },
    { word: 'rumbling', word_class: 'N' },
    { word: 'rumbling', word_class: 'AJ' }
  ],
  provoking: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  applause: [
    { word: 'applaud', word_class: 'V' },
    { word: 'applause', word_class: 'N' },
    { word: 'applauder', word_class: 'N' },
    { word: 'applaudable', word_class: 'AJ' }
  ],
  aberration: [
    { word: 'aberrant', word_class: 'N' },
    { word: 'aberrate', word_class: 'V' },
    { word: 'aberrance', word_class: 'N' },
    { word: 'aberrancy', word_class: 'N' },
    { word: 'aberrant', word_class: 'AJ' },
    { word: 'aberration', word_class: 'N' }
  ],
  factorize: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  benevolently: [
    { word: 'benevolence', word_class: 'N' },
    { word: 'benevolent', word_class: 'AJ' },
    { word: 'benevolently', word_class: 'AV' }
  ],
  perception: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  electronic: [
    { word: 'electron', word_class: 'N' },
    { word: 'electronic', word_class: 'AJ' },
    { word: 'electronics', word_class: 'N' },
    { word: 'electronically', word_class: 'AV' }
  ],
  hungriness: [
    { word: 'hunger', word_class: 'N' },
    { word: 'hunger', word_class: 'V' },
    { word: 'hungry', word_class: 'AJ' },
    { word: 'hungriness', word_class: 'N' }
  ],
  persuasive: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  braces: [
    { word: 'brace', word_class: 'N' },
    { word: 'brace', word_class: 'V' },
    { word: 'bracer', word_class: 'N' },
    { word: 'braces', word_class: 'N' },
    { word: 'braced', word_class: 'AJ' },
    { word: 'bracing', word_class: 'N' },
    { word: 'bracing', word_class: 'AJ' }
  ],
  circumvention: [
    { word: 'circumvent', word_class: 'N' },
    { word: 'circumvent', word_class: 'V' },
    { word: 'circumvention', word_class: 'N' }
  ],
  demur: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  decorously: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  glaring: [
    { word: 'glare', word_class: 'N' },
    { word: 'glare', word_class: 'V' },
    { word: 'glaring', word_class: 'AJ' }
  ],
  clinch: [
    { word: 'clinch', word_class: 'N' },
    { word: 'clinch', word_class: 'V' },
    { word: 'clincher', word_class: 'N' },
    { word: 'clinched', word_class: 'AJ' }
  ],
  crookback: [
    { word: 'crookback', word_class: 'N' },
    { word: 'crookback', word_class: 'AJ' },
    { word: 'crookbacked', word_class: 'AJ' }
  ],
  city: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  contractable: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  tensed: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  stemmed: [
    { word: 'stem', word_class: 'N' },
    { word: 'stem', word_class: 'V' },
    { word: 'stemmed', word_class: 'AJ' }
  ],
  divorced: [
    { word: 'divorce', word_class: 'N' },
    { word: 'divorce', word_class: 'V' },
    { word: 'divorcee', word_class: 'N' },
    { word: 'divorced', word_class: 'AJ' },
    { word: 'divorcement', word_class: 'N' }
  ],
  stammering: [
    { word: 'stammer', word_class: 'N' },
    { word: 'stammer', word_class: 'V' },
    { word: 'stammerer', word_class: 'N' },
    { word: 'stammering', word_class: 'AJ' }
  ],
  stupidity: [
    { word: 'stupid', word_class: 'N' },
    { word: 'stupid', word_class: 'AJ' },
    { word: 'stupidity', word_class: 'N' }
  ],
  whimsy: [
    { word: 'whimsy', word_class: 'N' },
    { word: 'whimsical', word_class: 'AJ' },
    { word: 'whimsicality', word_class: 'N' },
    { word: 'whimsically', word_class: 'AV' }
  ],
  anglophil: [
    { word: 'anglophil', word_class: 'N' },
    { word: 'anglophile', word_class: 'N' },
    { word: 'anglophilic', word_class: 'AJ' }
  ],
  banning: [
    { word: 'ban', word_class: 'N' },
    { word: 'ban', word_class: 'V' },
    { word: 'banned', word_class: 'AJ' },
    { word: 'banning', word_class: 'N' }
  ],
  gathering: [
    { word: 'gather', word_class: 'N' },
    { word: 'gather', word_class: 'V' },
    { word: 'gatherer', word_class: 'N' },
    { word: 'gathering', word_class: 'N' },
    { word: 'gathered', word_class: 'AJ' },
    { word: 'gathering', word_class: 'AJ' }
  ],
  immaculate: [
    { word: 'immaculate', word_class: 'AJ' },
    { word: 'immaculately', word_class: 'AV' },
    { word: 'immaculateness', word_class: 'N' }
  ],
  filter: [
    { word: 'filter', word_class: 'N' },
    { word: 'filter', word_class: 'V' },
    { word: 'filtrate', word_class: 'N' },
    { word: 'filtrate', word_class: 'V' },
    { word: 'filtration', word_class: 'N' }
  ],
  lustful: [
    { word: 'lust', word_class: 'N' },
    { word: 'lust', word_class: 'V' },
    { word: 'lustful', word_class: 'AJ' },
    { word: 'lustfulness', word_class: 'N' }
  ],
  cursing: [
    { word: 'curse', word_class: 'N' },
    { word: 'curse', word_class: 'V' },
    { word: 'cursor', word_class: 'N' },
    { word: 'cursed', word_class: 'AJ' },
    { word: 'cursing', word_class: 'AJ' }
  ],
  ratiocination: [
    { word: 'ratiocinate', word_class: 'V' },
    { word: 'ratiocinator', word_class: 'N' },
    { word: 'ratiocination', word_class: 'N' },
    { word: 'ratiocinative', word_class: 'AJ' }
  ],
  dawn: [{ word: 'dawn', word_class: 'N' }, { word: 'dawn', word_class: 'V' }, { word: 'dawning', word_class: 'N' }],
  plasticity: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  distressfulness: [
    { word: 'distress', word_class: 'N' },
    { word: 'distress', word_class: 'V' },
    { word: 'distressed', word_class: 'AJ' },
    { word: 'distressful', word_class: 'AJ' },
    { word: 'distressing', word_class: 'AJ' },
    { word: 'distressfulness', word_class: 'N' }
  ],
  hemorrhage: [
    { word: 'hemorrhage', word_class: 'N' },
    { word: 'hemorrhage', word_class: 'V' },
    { word: 'hemorrhagic', word_class: 'AJ' }
  ],
  starring: [
    { word: 'star', word_class: 'N' },
    { word: 'star', word_class: 'V' },
    { word: 'star', word_class: 'AJ' },
    { word: 'starred', word_class: 'AJ' },
    { word: 'starring', word_class: 'AJ' }
  ],
  derange: [
    { word: 'derange', word_class: 'V' },
    { word: 'deranged', word_class: 'AJ' },
    { word: 'derangement', word_class: 'N' }
  ],
  etched: [
    { word: 'etch', word_class: 'N' },
    { word: 'etch', word_class: 'V' },
    { word: 'etcher', word_class: 'N' },
    { word: 'etched', word_class: 'AJ' },
    { word: 'etching', word_class: 'N' }
  ],
  gamy: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  crested: [
    { word: 'crest', word_class: 'N' },
    { word: 'crest', word_class: 'V' },
    { word: 'crested', word_class: 'AJ' }
  ],
  reconstructive: [
    { word: 'reconstruct', word_class: 'V' },
    { word: 'reconstructed', word_class: 'AJ' },
    { word: 'reconstruction', word_class: 'N' },
    { word: 'reconstructive', word_class: 'AJ' }
  ],
  miner: [
    { word: 'mine', word_class: 'N' },
    { word: 'mine', word_class: 'V' },
    { word: 'miner', word_class: 'N' },
    { word: 'mined', word_class: 'AJ' },
    { word: 'mining', word_class: 'N' },
    { word: 'minion', word_class: 'N' },
    { word: 'minor', word_class: 'N' }
  ],
  scullion: [
    { word: 'scull', word_class: 'N' },
    { word: 'scull', word_class: 'V' },
    { word: 'sculler', word_class: 'N' },
    { word: 'sculling', word_class: 'N' },
    { word: 'scullion', word_class: 'N' }
  ],
  disorganization: [
    { word: 'disorganize', word_class: 'V' },
    { word: 'disorganized', word_class: 'AJ' },
    { word: 'disorganisation', word_class: 'N' },
    { word: 'disorganization', word_class: 'N' }
  ],
  zillion: [
    { word: 'zillion', word_class: 'N' },
    { word: 'zillion', word_class: 'AJ' },
    { word: 'zillions', word_class: 'N' }
  ],
  conjugal: [
    { word: 'conjugal', word_class: 'AJ' },
    { word: 'conjugate', word_class: 'N' },
    { word: 'conjugate', word_class: 'V' },
    { word: 'conjugate', word_class: 'AJ' },
    { word: 'conjugally', word_class: 'AV' },
    { word: 'conjugated', word_class: 'AJ' },
    { word: 'conjugation', word_class: 'N' }
  ],
  transfixed: [
    { word: 'transfix', word_class: 'N' },
    { word: 'transfix', word_class: 'V' },
    { word: 'transfixed', word_class: 'AJ' },
    { word: 'transfixion', word_class: 'N' }
  ],
  catheterisation: [
    { word: 'catheterise', word_class: 'V' },
    { word: 'catheterize', word_class: 'V' },
    { word: 'catheterisation', word_class: 'N' },
    { word: 'catheterization', word_class: 'N' }
  ],
  smashing: [
    { word: 'smash', word_class: 'N' },
    { word: 'smash', word_class: 'V' },
    { word: 'smash', word_class: 'AV' },
    { word: 'smasher', word_class: 'N' },
    { word: 'smashed', word_class: 'AJ' },
    { word: 'smashing', word_class: 'N' },
    { word: 'smashing', word_class: 'AJ' }
  ],
  deceive: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  revolved: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  soilage: [
    { word: 'soil', word_class: 'N' },
    { word: 'soil', word_class: 'V' },
    { word: 'soilage', word_class: 'N' },
    { word: 'soiled', word_class: 'AJ' },
    { word: 'soilure', word_class: 'N' },
    { word: 'soiling', word_class: 'N' }
  ],
  pushing: [
    { word: 'push', word_class: 'N' },
    { word: 'push', word_class: 'V' },
    { word: 'pusher', word_class: 'N' },
    { word: 'pushing', word_class: 'N' },
    { word: 'pushful', word_class: 'AJ' },
    { word: 'pushing', word_class: 'AJ' }
  ],
  importance: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  colloidal: [
    { word: 'colloid', word_class: 'N' },
    { word: 'colloid', word_class: 'AJ' },
    { word: 'colloidal', word_class: 'AJ' }
  ],
  anaesthetist: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  totem: [
    { word: 'totem', word_class: 'N' },
    { word: 'totemic', word_class: 'AJ' },
    { word: 'totemism', word_class: 'N' }
  ],
  spectacle: [
    { word: 'spectacle', word_class: 'N' },
    { word: 'spectacles', word_class: 'N' },
    { word: 'spectacled', word_class: 'AJ' },
    { word: 'bespectacled', word_class: 'AJ' }
  ],
  oxidisation: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  adventurous: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  grower: [
    { word: 'grow', word_class: 'V' },
    { word: 'grower', word_class: 'N' },
    { word: 'grown', word_class: 'AJ' },
    { word: 'growth', word_class: 'N' },
    { word: 'growing', word_class: 'N' },
    { word: 'growing', word_class: 'AJ' }
  ],
  derivative: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  exaction: [
    { word: 'exact', word_class: 'N' },
    { word: 'exact', word_class: 'V' },
    { word: 'exact', word_class: 'AJ' },
    { word: 'exaction', word_class: 'N' },
    { word: 'exacting', word_class: 'AJ' },
    { word: 'exactness', word_class: 'N' },
    { word: 'exactitude', word_class: 'N' }
  ],
  cloth: [
    { word: 'cloth', word_class: 'N' },
    { word: 'clothe', word_class: 'V' },
    { word: 'clothes', word_class: 'N' },
    { word: 'clothed', word_class: 'AJ' },
    { word: 'clothing', word_class: 'N' }
  ],
  depreciate: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  introductory: [
    { word: 'introduce', word_class: 'V' },
    { word: 'introduction', word_class: 'N' },
    { word: 'introductory', word_class: 'AJ' }
  ],
  abdicable: [
    { word: 'abdicate', word_class: 'V' },
    { word: 'abdicator', word_class: 'N' },
    { word: 'abdicable', word_class: 'AJ' },
    { word: 'abdication', word_class: 'N' }
  ],
  rastafarian: [
    { word: 'rastafarian', word_class: 'N' },
    { word: 'rastafarian', word_class: 'AJ' },
    { word: 'rastafarianism', word_class: 'N' }
  ],
  stertorously: [
    { word: 'stertor', word_class: 'N' },
    { word: 'stertorous', word_class: 'AJ' },
    { word: 'stertorously', word_class: 'AV' }
  ],
  perceiving: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  ratifier: [
    { word: 'ratify', word_class: 'V' },
    { word: 'ratifier', word_class: 'N' },
    { word: 'ratified', word_class: 'AJ' },
    { word: 'ratification', word_class: 'N' }
  ],
  continue: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  unconsciousness: [
    { word: 'unconscious', word_class: 'N' },
    { word: 'unconscious', word_class: 'AJ' },
    { word: 'unconsciously', word_class: 'AV' },
    { word: 'unconsciousness', word_class: 'N' }
  ],
  individualistic: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  parole: [
    { word: 'parole', word_class: 'N' },
    { word: 'parole', word_class: 'V' },
    { word: 'parolee', word_class: 'N' }
  ],
  familiar: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  victual: [
    { word: 'victual', word_class: 'N' },
    { word: 'victual', word_class: 'V' },
    { word: 'victuals', word_class: 'N' },
    { word: 'victualer', word_class: 'N' },
    { word: 'victualler', word_class: 'N' }
  ],
  shield: [
    { word: 'shield', word_class: 'N' },
    { word: 'shield', word_class: 'V' },
    { word: 'shielded', word_class: 'AJ' },
    { word: 'shielding', word_class: 'N' },
    { word: 'shielding', word_class: 'AJ' }
  ],
  ecumenic: [
    { word: 'ecumenic', word_class: 'AJ' },
    { word: 'ecumenism', word_class: 'N' },
    { word: 'ecumenical', word_class: 'AJ' },
    { word: 'ecumenicalism', word_class: 'N' }
  ],
  commissioning: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  panful: [
    { word: 'pan', word_class: 'N' },
    { word: 'pan', word_class: 'V' },
    { word: 'panful', word_class: 'N' },
    { word: 'panful', word_class: 'AJ' }
  ],
  substantiate: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  security: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  grayness: [
    { word: 'gray', word_class: 'N' },
    { word: 'gray', word_class: 'V' },
    { word: 'gray', word_class: 'AJ' },
    { word: 'grayed', word_class: 'AJ' },
    { word: 'grayness', word_class: 'N' }
  ],
  awfulness: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  focalisation: [
    { word: 'focal', word_class: 'AJ' },
    { word: 'focalize', word_class: 'V' },
    { word: 'focally', word_class: 'AV' },
    { word: 'focalisation', word_class: 'N' },
    { word: 'focalization', word_class: 'N' }
  ],
  tropically: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  desperation: [
    { word: 'despair', word_class: 'N' },
    { word: 'despair', word_class: 'V' },
    { word: 'desperate', word_class: 'N' },
    { word: 'desperate', word_class: 'AJ' },
    { word: 'despairing', word_class: 'AJ' },
    { word: 'desperation', word_class: 'N' },
    { word: 'desperately', word_class: 'AV' }
  ],
  searching: [
    { word: 'search', word_class: 'N' },
    { word: 'search', word_class: 'V' },
    { word: 'searcher', word_class: 'N' },
    { word: 'searching', word_class: 'N' },
    { word: 'searching', word_class: 'AJ' }
  ],
  deducible: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  flexor: [
    { word: 'flex', word_class: 'N' },
    { word: 'flex', word_class: 'V' },
    { word: 'flexor', word_class: 'N' },
    { word: 'flexion', word_class: 'N' },
    { word: 'flection', word_class: 'N' }
  ],
  judgment: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  sepulcher: [
    { word: 'sepulcher', word_class: 'N' },
    { word: 'sepulchre', word_class: 'N' },
    { word: 'sepulchral', word_class: 'AJ' }
  ],
  exuberance: [
    { word: 'exuberate', word_class: 'V' },
    { word: 'exuberance', word_class: 'N' },
    { word: 'exuberant', word_class: 'AJ' },
    { word: 'exuberance', word_class: 'AJ' },
    { word: 'exuberation', word_class: 'N' }
  ],
  dealings: [
    { word: 'deal', word_class: 'N' },
    { word: 'deal', word_class: 'V' },
    { word: 'deal', word_class: 'AJ' },
    { word: 'dealer', word_class: 'N' },
    { word: 'dealing', word_class: 'N' },
    { word: 'dealings', word_class: 'N' }
  ],
  transplantation: [
    { word: 'transplant', word_class: 'N' },
    { word: 'transplant', word_class: 'V' },
    { word: 'transplanting', word_class: 'N' },
    { word: 'transplantable', word_class: 'AJ' },
    { word: 'transplantation', word_class: 'N' }
  ],
  convergence: [
    { word: 'converge', word_class: 'V' },
    { word: 'converging', word_class: 'N' },
    { word: 'convergence', word_class: 'N' },
    { word: 'convergency', word_class: 'N' },
    { word: 'convergent', word_class: 'AJ' },
    { word: 'converging', word_class: 'AJ' }
  ],
  serial: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  edginess: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  opposable: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  visit: [
    { word: 'visit', word_class: 'N' },
    { word: 'visit', word_class: 'V' },
    { word: 'visitor', word_class: 'N' },
    { word: 'visitant', word_class: 'N' },
    { word: 'visiting', word_class: 'N' },
    { word: 'visiting', word_class: 'AJ' },
    { word: 'visitation', word_class: 'N' }
  ],
  'well-wisher': [
    { word: 'well-wisher', word_class: 'N' },
    { word: 'well-wishing', word_class: 'N' },
    { word: 'well-wishing', word_class: 'AJ' }
  ],
  synecdoche: [
    { word: 'synecdoche', word_class: 'N' },
    { word: 'synecdochic', word_class: 'AJ' },
    { word: 'synecdochical', word_class: 'AJ' }
  ],
  insecurely: [
    { word: 'insecure', word_class: 'AJ' },
    { word: 'insecurity', word_class: 'N' },
    { word: 'insecurely', word_class: 'AV' },
    { word: 'insecureness', word_class: 'N' }
  ],
  frisking: [
    { word: 'frisk', word_class: 'N' },
    { word: 'frisk', word_class: 'V' },
    { word: 'frisking', word_class: 'N' }
  ],
  mathematic: [
    { word: 'mathematic', word_class: 'AJ' },
    { word: 'mathematics', word_class: 'N' },
    { word: 'mathematical', word_class: 'AJ' },
    { word: 'mathematically', word_class: 'AV' }
  ],
  optimal: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  stalwart: [
    { word: 'stalwart', word_class: 'N' },
    { word: 'stalwart', word_class: 'AJ' },
    { word: 'stalwartness', word_class: 'N' }
  ],
  homogenization: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  blotch: [
    { word: 'blotch', word_class: 'N' },
    { word: 'blotch', word_class: 'V' },
    { word: 'blotched', word_class: 'AJ' }
  ],
  executor: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  interdependency: [
    { word: 'interdepend', word_class: 'V' },
    { word: 'interdependence', word_class: 'N' },
    { word: 'interdependency', word_class: 'N' },
    { word: 'interdependent', word_class: 'AJ' }
  ],
  designative: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  protrude: [
    { word: 'protrude', word_class: 'V' },
    { word: 'protrusion', word_class: 'N' },
    { word: 'protruding', word_class: 'AJ' },
    { word: 'protrusive', word_class: 'AJ' },
    { word: 'protrusible', word_class: 'AJ' }
  ],
  suffer: [
    { word: 'suffer', word_class: 'V' },
    { word: 'sufferer', word_class: 'N' },
    { word: 'suffering', word_class: 'N' },
    { word: 'sufferance', word_class: 'N' },
    { word: 'suffering', word_class: 'AJ' },
    { word: 'sufferable', word_class: 'AJ' }
  ],
  neglige: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  visibleness: [
    { word: 'visible', word_class: 'AJ' },
    { word: 'visibly', word_class: 'AV' },
    { word: 'visibility', word_class: 'N' },
    { word: 'visibleness', word_class: 'N' }
  ],
  slacker: [
    { word: 'slack', word_class: 'N' },
    { word: 'slack', word_class: 'V' },
    { word: 'slack', word_class: 'AJ' },
    { word: 'slacks', word_class: 'N' },
    { word: 'slacker', word_class: 'N' },
    { word: 'slacking', word_class: 'N' },
    { word: 'slackness', word_class: 'N' }
  ],
  shoes: [
    { word: 'shoe', word_class: 'N' },
    { word: 'shoe', word_class: 'V' },
    { word: 'shoal', word_class: 'N' },
    { word: 'shoal', word_class: 'V' },
    { word: 'shoes', word_class: 'N' },
    { word: 'shoal', word_class: 'AJ' }
  ],
  darkened: [
    { word: 'darken', word_class: 'V' },
    { word: 'darkened', word_class: 'AJ' },
    { word: 'darkening', word_class: 'N' },
    { word: 'darkening', word_class: 'AJ' }
  ],
  filing: [
    { word: 'file', word_class: 'N' },
    { word: 'file', word_class: 'V' },
    { word: 'filer', word_class: 'N' },
    { word: 'filing', word_class: 'N' }
  ],
  supping: [
    { word: 'sup', word_class: 'N' },
    { word: 'sup', word_class: 'V' },
    { word: 'super', word_class: 'N' },
    { word: 'super', word_class: 'AJ' },
    { word: 'super', word_class: 'AV' },
    { word: 'supping', word_class: 'N' },
    { word: 'superable', word_class: 'AJ' }
  ],
  insidiousness: [
    { word: 'insidious', word_class: 'AJ' },
    { word: 'insidiously', word_class: 'AV' },
    { word: 'insidiousness', word_class: 'N' }
  ],
  ramee: [
    { word: 'ram', word_class: 'N' },
    { word: 'ram', word_class: 'V' },
    { word: 'ramee', word_class: 'N' },
    { word: 'ramman', word_class: 'N' }
  ],
  journalisation: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  trillion: [
    { word: 'trill', word_class: 'N' },
    { word: 'trill', word_class: 'V' },
    { word: 'trilled', word_class: 'AJ' },
    { word: 'trillion', word_class: 'N' },
    { word: 'trillion', word_class: 'AJ' },
    { word: 'trillions', word_class: 'N' }
  ],
  teenaged: [
    { word: 'teenage', word_class: 'AJ' },
    { word: 'teenager', word_class: 'N' },
    { word: 'teenaged', word_class: 'AJ' }
  ],
  discouragement: [
    { word: 'discourage', word_class: 'N' },
    { word: 'discourage', word_class: 'V' },
    { word: 'discouraged', word_class: 'AJ' },
    { word: 'discouraging', word_class: 'AJ' },
    { word: 'discouragement', word_class: 'N' }
  ],
  intemperate: [
    { word: 'intemperance', word_class: 'N' },
    { word: 'intemperate', word_class: 'AJ' },
    { word: 'intemperately', word_class: 'AV' },
    { word: 'intemperateness', word_class: 'N' }
  ],
  eventuality: [
    { word: 'eventual', word_class: 'AJ' },
    { word: 'eventuate', word_class: 'V' },
    { word: 'eventuality', word_class: 'N' },
    { word: 'eventually', word_class: 'AV' },
    { word: 'eventuation', word_class: 'N' }
  ],
  survivor: [
    { word: 'survive', word_class: 'V' },
    { word: 'survival', word_class: 'N' },
    { word: 'survivor', word_class: 'N' },
    { word: 'surviving', word_class: 'AJ' }
  ],
  qualify: [
    { word: 'qualify', word_class: 'V' },
    { word: 'qualifier', word_class: 'N' },
    { word: 'qualified', word_class: 'AJ' },
    { word: 'qualifying', word_class: 'N' },
    { word: 'qualifying', word_class: 'AJ' },
    { word: 'qualification', word_class: 'N' }
  ],
  intangibility: [
    { word: 'intangible', word_class: 'N' },
    { word: 'intangible', word_class: 'AJ' },
    { word: 'intangibility', word_class: 'N' },
    { word: 'intangibleness', word_class: 'N' }
  ],
  stockman: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  negotiate: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  chatter: [
    { word: 'chatter', word_class: 'N' },
    { word: 'chatter', word_class: 'V' },
    { word: 'chatterer', word_class: 'N' },
    { word: 'chattering', word_class: 'N' },
    { word: 'chattering', word_class: 'AJ' }
  ],
  bigness: [{ word: 'big', word_class: 'AJ' }, { word: 'big', word_class: 'AV' }, { word: 'bigness', word_class: 'N' }],
  figuration: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  kiss: [
    { word: 'kiss', word_class: 'N' },
    { word: 'kiss', word_class: 'V' },
    { word: 'kisser', word_class: 'N' },
    { word: 'kissing', word_class: 'N' }
  ],
  resettlement: [
    { word: 'resettle', word_class: 'V' },
    { word: 'resettled', word_class: 'AJ' },
    { word: 'resettlement', word_class: 'N' }
  ],
  mores: [
    { word: 'more', word_class: 'N' },
    { word: 'more', word_class: 'AJ' },
    { word: 'more', word_class: 'AV' },
    { word: 'mores', word_class: 'N' }
  ],
  poached: [
    { word: 'poach', word_class: 'V' },
    { word: 'poacher', word_class: 'N' },
    { word: 'poached', word_class: 'AJ' },
    { word: 'poaching', word_class: 'N' }
  ],
  welcoming: [
    { word: 'welcome', word_class: 'N' },
    { word: 'welcome', word_class: 'V' },
    { word: 'welcome', word_class: 'AJ' },
    { word: 'welcomed', word_class: 'AJ' },
    { word: 'welcoming', word_class: 'N' },
    { word: 'welcoming', word_class: 'AJ' }
  ],
  pasties: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  admissive: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  precocious: [
    { word: 'precocial', word_class: 'AJ' },
    { word: 'precocious', word_class: 'AJ' },
    { word: 'precociously', word_class: 'AV' },
    { word: 'precociousness', word_class: 'N' }
  ],
  incredibleness: [
    { word: 'incredible', word_class: 'AJ' },
    { word: 'incredibly', word_class: 'AV' },
    { word: 'incredibility', word_class: 'N' },
    { word: 'incredibleness', word_class: 'N' }
  ],
  theoretically: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  tenacious: [
    { word: 'tenacious', word_class: 'AJ' },
    { word: 'tenaciously', word_class: 'AV' },
    { word: 'tenaciousness', word_class: 'N' }
  ],
  centroid: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  serration: [
    { word: 'serrate', word_class: 'V' },
    { word: 'serrate', word_class: 'AJ' },
    { word: 'serrated', word_class: 'AJ' },
    { word: 'serration', word_class: 'N' }
  ],
  imaginatively: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  substantially: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  eradication: [
    { word: 'eradicate', word_class: 'V' },
    { word: 'eradicator', word_class: 'N' },
    { word: 'eradicable', word_class: 'AJ' },
    { word: 'eradication', word_class: 'N' }
  ],
  compassionateness: [
    { word: 'compass', word_class: 'N' },
    { word: 'compass', word_class: 'V' },
    { word: 'compassion', word_class: 'N' },
    { word: 'compassionate', word_class: 'V' },
    { word: 'compassionate', word_class: 'AJ' },
    { word: 'compassionately', word_class: 'AV' },
    { word: 'compassionateness', word_class: 'N' }
  ],
  thieving: [
    { word: 'thieve', word_class: 'V' },
    { word: 'thievery', word_class: 'N' },
    { word: 'thieving', word_class: 'N' },
    { word: 'thievery', word_class: 'AJ' },
    { word: 'thieving', word_class: 'AJ' }
  ],
  valued: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  nocturnally: [
    { word: 'nocturne', word_class: 'N' },
    { word: 'nocturnal', word_class: 'AJ' },
    { word: 'nocturnally', word_class: 'AV' }
  ],
  figured: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  ball: [{ word: 'ball', word_class: 'N' }, { word: 'ball', word_class: 'V' }, { word: 'balled', word_class: 'AJ' }],
  umpiring: [
    { word: 'umpire', word_class: 'N' },
    { word: 'umpire', word_class: 'V' },
    { word: 'umpirage', word_class: 'N' },
    { word: 'umpiring', word_class: 'N' },
    { word: 'umpireship', word_class: 'N' }
  ],
  stowage: [
    { word: 'stow', word_class: 'V' },
    { word: 'stowage', word_class: 'N' },
    { word: 'stowing', word_class: 'N' }
  ],
  dawning: [{ word: 'dawn', word_class: 'N' }, { word: 'dawn', word_class: 'V' }, { word: 'dawning', word_class: 'N' }],
  canalize: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  grand: [
    { word: 'grand', word_class: 'N' },
    { word: 'grand', word_class: 'AJ' },
    { word: 'grandness', word_class: 'N' }
  ],
  romanal: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  treasonable: [
    { word: 'treason', word_class: 'N' },
    { word: 'treasonous', word_class: 'AJ' },
    { word: 'treasonable', word_class: 'AJ' },
    { word: 'treasonably', word_class: 'AV' }
  ],
  disingenuousness: [
    { word: 'disingenuous', word_class: 'AJ' },
    { word: 'disingenuously', word_class: 'AV' },
    { word: 'disingenuousness', word_class: 'N' }
  ],
  hawk: [
    { word: 'hawk', word_class: 'N' },
    { word: 'hawk', word_class: 'V' },
    { word: 'hawker', word_class: 'N' },
    { word: 'hawking', word_class: 'N' }
  ],
  irritable: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  deposit: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  shoulder: [
    { word: 'shoulder', word_class: 'N' },
    { word: 'shoulder', word_class: 'V' },
    { word: 'shouldered', word_class: 'AJ' }
  ],
  tempest: [
    { word: 'tempest', word_class: 'N' },
    { word: 'tempestuous', word_class: 'AJ' },
    { word: 'tempestuousness', word_class: 'N' }
  ],
  sonorousness: [
    { word: 'sonorant', word_class: 'N' },
    { word: 'sonority', word_class: 'N' },
    { word: 'sonorant', word_class: 'AJ' },
    { word: 'sonorous', word_class: 'AJ' },
    { word: 'sonorously', word_class: 'AV' },
    { word: 'sonorousness', word_class: 'N' }
  ],
  associate: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  betting: [{ word: 'bet', word_class: 'N' }, { word: 'bet', word_class: 'V' }, { word: 'betting', word_class: 'AJ' }],
  abstain: [
    { word: 'abstain', word_class: 'V' },
    { word: 'abstainer', word_class: 'N' },
    { word: 'abstinent', word_class: 'N' },
    { word: 'abstention', word_class: 'N' },
    { word: 'abstinence', word_class: 'N' },
    { word: 'abstinent', word_class: 'AJ' }
  ],
  anglophile: [
    { word: 'anglophil', word_class: 'N' },
    { word: 'anglophile', word_class: 'N' },
    { word: 'anglophilic', word_class: 'AJ' }
  ],
  cognize: [
    { word: 'cognize', word_class: 'V' },
    { word: 'cognizance', word_class: 'N' },
    { word: 'cognizant', word_class: 'AJ' },
    { word: 'cognizable', word_class: 'AJ' }
  ],
  mythical: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  sedulously: [
    { word: 'sedulity', word_class: 'N' },
    { word: 'sedulous', word_class: 'AJ' },
    { word: 'sedulously', word_class: 'AV' },
    { word: 'sedulousness', word_class: 'N' }
  ],
  sprawl: [
    { word: 'sprawl', word_class: 'N' },
    { word: 'sprawl', word_class: 'V' },
    { word: 'sprawled', word_class: 'AJ' },
    { word: 'sprawling', word_class: 'N' },
    { word: 'sprawling', word_class: 'AJ' }
  ],
  option: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  strained: [
    { word: 'strain', word_class: 'N' },
    { word: 'strain', word_class: 'V' },
    { word: 'strainer', word_class: 'N' },
    { word: 'strained', word_class: 'AJ' },
    { word: 'straining', word_class: 'N' },
    { word: 'straining', word_class: 'AJ' }
  ],
  salting: [
    { word: 'salt', word_class: 'N' },
    { word: 'salt', word_class: 'V' },
    { word: 'salt', word_class: 'AJ' },
    { word: 'salted', word_class: 'AJ' },
    { word: 'salting', word_class: 'N' }
  ],
  detraction: [
    { word: 'detract', word_class: 'V' },
    { word: 'detraction', word_class: 'N' },
    { word: 'detractive', word_class: 'AJ' }
  ],
  boozer: [
    { word: 'booze', word_class: 'N' },
    { word: 'booze', word_class: 'V' },
    { word: 'boozer', word_class: 'N' },
    { word: 'boozy', word_class: 'AJ' },
    { word: 'boozing', word_class: 'N' }
  ],
  inhumaneness: [
    { word: 'inhuman', word_class: 'AJ' },
    { word: 'inhumane', word_class: 'AJ' },
    { word: 'inhumanity', word_class: 'N' },
    { word: 'inhumanely', word_class: 'AV' },
    { word: 'inhumaneness', word_class: 'N' }
  ],
  originality: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  liberally: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  regressive: [
    { word: 'regress', word_class: 'N' },
    { word: 'regress', word_class: 'V' },
    { word: 'regression', word_class: 'N' },
    { word: 'regressing', word_class: 'AJ' },
    { word: 'regressive', word_class: 'AJ' }
  ],
  formatting: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  intransitivity: [
    { word: 'intransitive', word_class: 'AJ' },
    { word: 'intransitivity', word_class: 'N' },
    { word: 'intransitively', word_class: 'AV' },
    { word: 'intransitiveness', word_class: 'N' }
  ],
  vaporing: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  manifold: [
    { word: 'manifold', word_class: 'N' },
    { word: 'manifold', word_class: 'V' },
    { word: 'manifold', word_class: 'AJ' }
  ],
  experimental: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  endangered: [
    { word: 'endanger', word_class: 'V' },
    { word: 'endangered', word_class: 'AJ' },
    { word: 'endangerment', word_class: 'N' }
  ],
  prejudgement: [
    { word: 'prejudge', word_class: 'V' },
    { word: 'prejudgment', word_class: 'N' },
    { word: 'prejudgement', word_class: 'N' }
  ],
  repeatable: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  indiscriminating: [
    { word: 'indiscriminate', word_class: 'AJ' },
    { word: 'indiscriminately', word_class: 'AV' },
    { word: 'indiscriminating', word_class: 'AJ' }
  ],
  incapacitated: [
    { word: 'incapacity', word_class: 'N' },
    { word: 'incapacitate', word_class: 'V' },
    { word: 'incapacitated', word_class: 'AJ' },
    { word: 'incapacitation', word_class: 'N' },
    { word: 'incapacitating', word_class: 'AJ' }
  ],
  deoxidisation: [
    { word: 'deoxidise', word_class: 'V' },
    { word: 'deoxidize', word_class: 'V' },
    { word: 'deoxidisation', word_class: 'N' },
    { word: 'deoxidization', word_class: 'N' }
  ],
  daubry: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  nitration: [
    { word: 'nitrate', word_class: 'N' },
    { word: 'nitrate', word_class: 'V' },
    { word: 'nitration', word_class: 'N' }
  ],
  crap: [{ word: 'crap', word_class: 'N' }, { word: 'crap', word_class: 'V' }, { word: 'craps', word_class: 'N' }],
  catchment: [
    { word: 'catch', word_class: 'N' },
    { word: 'catch', word_class: 'V' },
    { word: 'catcher', word_class: 'N' },
    { word: 'catching', word_class: 'N' },
    { word: 'catching', word_class: 'AJ' },
    { word: 'catchment', word_class: 'N' }
  ],
  democratisation: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  orangy: [
    { word: 'orang', word_class: 'N' },
    { word: 'orange', word_class: 'N' },
    { word: 'orange', word_class: 'AJ' },
    { word: 'orangy', word_class: 'AJ' },
    { word: 'orangeness', word_class: 'N' }
  ],
  rationalise: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  disclosure: [
    { word: 'disclose', word_class: 'V' },
    { word: 'disclosed', word_class: 'AJ' },
    { word: 'disclosure', word_class: 'N' },
    { word: 'disclosing', word_class: 'AJ' }
  ],
  discernability: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  localism: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  elastance: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  impingement: [
    { word: 'impinge', word_class: 'V' },
    { word: 'impinging', word_class: 'N' },
    { word: 'impingement', word_class: 'N' }
  ],
  special: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  nip: [{ word: 'nip', word_class: 'N' }, { word: 'nip', word_class: 'V' }, { word: 'nipping', word_class: 'AJ' }],
  hermaphroditic: [
    { word: 'hermaphrodite', word_class: 'N' },
    { word: 'hermaphrodite', word_class: 'AJ' },
    { word: 'hermaphroditic', word_class: 'AJ' },
    { word: 'hermaphroditism', word_class: 'N' }
  ],
  soured: [
    { word: 'sour', word_class: 'N' },
    { word: 'sour', word_class: 'V' },
    { word: 'sour', word_class: 'AJ' },
    { word: 'soured', word_class: 'AJ' },
    { word: 'souring', word_class: 'N' },
    { word: 'sourness', word_class: 'N' }
  ],
  illuminance: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  gnarl: [
    { word: 'gnarl', word_class: 'N' },
    { word: 'gnarl', word_class: 'V' },
    { word: 'gnarled', word_class: 'AJ' }
  ],
  auction: [
    { word: 'auction', word_class: 'N' },
    { word: 'auction', word_class: 'V' },
    { word: 'auctioneer', word_class: 'N' },
    { word: 'auctioneer', word_class: 'V' }
  ],
  operable: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  adverse: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  pronounceable: [
    { word: 'pronounce', word_class: 'V' },
    { word: 'pronounced', word_class: 'AJ' },
    { word: 'pronouncement', word_class: 'N' },
    { word: 'pronunciation', word_class: 'N' },
    { word: 'pronounceable', word_class: 'AJ' }
  ],
  celebrant: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  recompensation: [
    { word: 'recompense', word_class: 'N' },
    { word: 'recompense', word_class: 'V' },
    { word: 'recompensation', word_class: 'N' }
  ],
  rigidity: [
    { word: 'rigid', word_class: 'AJ' },
    { word: 'rigidity', word_class: 'N' },
    { word: 'rigidness', word_class: 'N' }
  ],
  separated: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  stream: [
    { word: 'stream', word_class: 'N' },
    { word: 'stream', word_class: 'V' },
    { word: 'streamer', word_class: 'N' },
    { word: 'streaming', word_class: 'N' },
    { word: 'streaming', word_class: 'AJ' }
  ],
  document: [
    { word: 'document', word_class: 'N' },
    { word: 'document', word_class: 'V' },
    { word: 'documental', word_class: 'AJ' },
    { word: 'documented', word_class: 'AJ' },
    { word: 'documentation', word_class: 'N' }
  ],
  asymmetrically: [
    { word: 'asymmetric', word_class: 'AJ' },
    { word: 'asymmetrical', word_class: 'N' },
    { word: 'asymmetrical', word_class: 'AJ' },
    { word: 'asymmetrically', word_class: 'AV' }
  ],
  righthandedness: [
    { word: 'righthand', word_class: 'AJ' },
    { word: 'righthanded', word_class: 'AJ' },
    { word: 'righthandedness', word_class: 'N' }
  ],
  rushed: [
    { word: 'rush', word_class: 'N' },
    { word: 'rush', word_class: 'V' },
    { word: 'rush', word_class: 'AJ' },
    { word: 'rushed', word_class: 'AJ' },
    { word: 'rushing', word_class: 'N' },
    { word: 'rushing', word_class: 'AJ' }
  ],
  charter: [
    { word: 'charter', word_class: 'N' },
    { word: 'charter', word_class: 'V' },
    { word: 'charterage', word_class: 'N' },
    { word: 'chartered', word_class: 'AJ' }
  ],
  beg: [
    { word: 'beg', word_class: 'V' },
    { word: 'beggar', word_class: 'N' },
    { word: 'beggar', word_class: 'V' },
    { word: 'beggary', word_class: 'N' },
    { word: 'begging', word_class: 'N' },
    { word: 'beggary', word_class: 'AJ' },
    { word: 'beggarman', word_class: 'N' }
  ],
  sponginess: [
    { word: 'sponge', word_class: 'N' },
    { word: 'sponge', word_class: 'V' },
    { word: 'sponger', word_class: 'N' },
    { word: 'spongy', word_class: 'AJ' },
    { word: 'sponginess', word_class: 'N' }
  ],
  tassel: [
    { word: 'tassel', word_class: 'N' },
    { word: 'tassel', word_class: 'V' },
    { word: 'tasseled', word_class: 'AJ' },
    { word: 'tasselled', word_class: 'AJ' }
  ],
  cuss: [{ word: 'cuss', word_class: 'N' }, { word: 'cuss', word_class: 'V' }, { word: 'cussed', word_class: 'AJ' }],
  longitude: [
    { word: 'longitude', word_class: 'N' },
    { word: 'longitudinal', word_class: 'AJ' },
    { word: 'longitudinally', word_class: 'AV' }
  ],
  soiling: [
    { word: 'soil', word_class: 'N' },
    { word: 'soil', word_class: 'V' },
    { word: 'soilage', word_class: 'N' },
    { word: 'soiled', word_class: 'AJ' },
    { word: 'soilure', word_class: 'N' },
    { word: 'soiling', word_class: 'N' }
  ],
  disquieting: [
    { word: 'disquiet', word_class: 'N' },
    { word: 'disquiet', word_class: 'V' },
    { word: 'disquieted', word_class: 'AJ' },
    { word: 'disquieting', word_class: 'AJ' }
  ],
  puzzled: [
    { word: 'puzzle', word_class: 'N' },
    { word: 'puzzle', word_class: 'V' },
    { word: 'puzzler', word_class: 'N' },
    { word: 'puzzled', word_class: 'AJ' },
    { word: 'puzzling', word_class: 'AJ' },
    { word: 'puzzlement', word_class: 'N' }
  ],
  circumstantial: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  effectuality: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  interlocking: [
    { word: 'interlock', word_class: 'N' },
    { word: 'interlock', word_class: 'V' },
    { word: 'interlocking', word_class: 'N' },
    { word: 'interlocking', word_class: 'AJ' }
  ],
  decompression: [
    { word: 'decompress', word_class: 'V' },
    { word: 'decompressing', word_class: 'N' },
    { word: 'decompression', word_class: 'N' }
  ],
  depreciative: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  hippy: [{ word: 'hippy', word_class: 'N' }, { word: 'hippie', word_class: 'N' }, { word: 'hippy', word_class: 'AJ' }],
  pedaler: [
    { word: 'pedal', word_class: 'N' },
    { word: 'pedal', word_class: 'V' },
    { word: 'pedal', word_class: 'AJ' },
    { word: 'pedaler', word_class: 'N' },
    { word: 'pedaller', word_class: 'N' }
  ],
  stopping: [
    { word: 'stop', word_class: 'N' },
    { word: 'stop', word_class: 'V' },
    { word: 'stops', word_class: 'N' },
    { word: 'stoppage', word_class: 'N' },
    { word: 'stopped', word_class: 'AJ' },
    { word: 'stopping', word_class: 'N' }
  ],
  terminable: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  harmonisation: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  mutualization: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  weepiness: [
    { word: 'weep', word_class: 'V' },
    { word: 'weepy', word_class: 'AJ' },
    { word: 'weeper', word_class: 'N' },
    { word: 'weeping', word_class: 'N' },
    { word: 'weeping', word_class: 'AJ' },
    { word: 'weepiness', word_class: 'N' }
  ],
  vitriolisation: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  paradoxical: [
    { word: 'paradox', word_class: 'N' },
    { word: 'paradoxical', word_class: 'AJ' },
    { word: 'paradoxically', word_class: 'AV' }
  ],
  passive: [
    { word: 'passive', word_class: 'N' },
    { word: 'passive', word_class: 'AJ' },
    { word: 'passivism', word_class: 'N' },
    { word: 'passivity', word_class: 'N' },
    { word: 'passively', word_class: 'AV' },
    { word: 'passiveness', word_class: 'N' }
  ],
  detonator: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  intemperance: [
    { word: 'intemperance', word_class: 'N' },
    { word: 'intemperate', word_class: 'AJ' },
    { word: 'intemperately', word_class: 'AV' },
    { word: 'intemperateness', word_class: 'N' }
  ],
  filmed: [
    { word: 'film', word_class: 'N' },
    { word: 'film', word_class: 'V' },
    { word: 'filmed', word_class: 'AJ' },
    { word: 'filming', word_class: 'N' },
    { word: 'filmmaker', word_class: 'N' },
    { word: 'filmmaking', word_class: 'N' }
  ],
  cravenness: [
    { word: 'craven', word_class: 'N' },
    { word: 'craven', word_class: 'AJ' },
    { word: 'cravenness', word_class: 'N' }
  ],
  pastor: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  ken: [{ word: 'ken', word_class: 'N' }, { word: 'ken', word_class: 'V' }, { word: 'kenning', word_class: 'N' }],
  milkman: [
    { word: 'milk', word_class: 'N' },
    { word: 'milk', word_class: 'V' },
    { word: 'milk', word_class: 'AJ' },
    { word: 'milker', word_class: 'N' },
    { word: 'milkman', word_class: 'N' }
  ],
  bistate: [
    { word: 'bistate', word_class: 'N' },
    { word: 'bistate', word_class: 'V' },
    { word: 'bistate', word_class: 'AJ' }
  ],
  exhaling: [
    { word: 'exhale', word_class: 'V' },
    { word: 'exhale', word_class: 'AJ' },
    { word: 'exhaled', word_class: 'AJ' },
    { word: 'exhaling', word_class: 'AJ' },
    { word: 'exhalation', word_class: 'N' }
  ],
  slater: [
    { word: 'slate', word_class: 'N' },
    { word: 'slate', word_class: 'V' },
    { word: 'slater', word_class: 'N' },
    { word: 'slating', word_class: 'N' }
  ],
  treachery: [
    { word: 'treachery', word_class: 'N' },
    { word: 'treacherous', word_class: 'AJ' },
    { word: 'treacherously', word_class: 'AV' }
  ],
  existent: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  fielder: [
    { word: 'field', word_class: 'N' },
    { word: 'field', word_class: 'V' },
    { word: 'fielder', word_class: 'N' },
    { word: 'fielding', word_class: 'N' }
  ],
  graecophile: [
    { word: 'graecophile', word_class: 'N' },
    { word: 'graecophile', word_class: 'AJ' },
    { word: 'graecophilic', word_class: 'AJ' }
  ],
  clamouring: [
    { word: 'clamour', word_class: 'N' },
    { word: 'clamour', word_class: 'V' },
    { word: 'clamouring', word_class: 'N' },
    { word: 'clamourous', word_class: 'AJ' }
  ],
  noble: [
    { word: 'noble', word_class: 'N' },
    { word: 'noble', word_class: 'AJ' },
    { word: 'nobility', word_class: 'N' }
  ],
  splutter: [
    { word: 'splutter', word_class: 'N' },
    { word: 'splutter', word_class: 'V' },
    { word: 'spluttering', word_class: 'AJ' }
  ],
  payee: [
    { word: 'pay', word_class: 'N' },
    { word: 'pay', word_class: 'V' },
    { word: 'payee', word_class: 'N' },
    { word: 'payer', word_class: 'N' },
    { word: 'paying', word_class: 'N' },
    { word: 'paying', word_class: 'AJ' },
    { word: 'payment', word_class: 'N' }
  ],
  democratically: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  prattling: [
    { word: 'prattle', word_class: 'N' },
    { word: 'prattle', word_class: 'V' },
    { word: 'prattler', word_class: 'N' },
    { word: 'prattling', word_class: 'AJ' }
  ],
  sheathing: [
    { word: 'sheath', word_class: 'N' },
    { word: 'sheathe', word_class: 'V' },
    { word: 'sheathed', word_class: 'AJ' },
    { word: 'sheathing', word_class: 'N' }
  ],
  invalidity: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  touristed: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  legislate: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  drowsiness: [
    { word: 'drowse', word_class: 'N' },
    { word: 'drowse', word_class: 'V' },
    { word: 'drowsy', word_class: 'AJ' },
    { word: 'drowsing', word_class: 'AJ' },
    { word: 'drowsiness', word_class: 'N' }
  ],
  jargonise: [
    { word: 'jargon', word_class: 'N' },
    { word: 'jargon', word_class: 'V' },
    { word: 'jargonise', word_class: 'V' },
    { word: 'jargonize', word_class: 'V' },
    { word: 'jargonisation', word_class: 'N' },
    { word: 'jargonization', word_class: 'N' }
  ],
  lengthen: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  dogmatization: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  centrist: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  imprint: [
    { word: 'imprint', word_class: 'N' },
    { word: 'imprint', word_class: 'V' },
    { word: 'imprinting', word_class: 'N' }
  ],
  glazing: [
    { word: 'glaze', word_class: 'N' },
    { word: 'glaze', word_class: 'V' },
    { word: 'glazer', word_class: 'N' },
    { word: 'glazed', word_class: 'AJ' },
    { word: 'glazing', word_class: 'N' }
  ],
  iceland: [
    { word: 'iceland', word_class: 'N' },
    { word: 'icelandic', word_class: 'N' },
    { word: 'icelander', word_class: 'N' },
    { word: 'icelandic', word_class: 'AJ' }
  ],
  trilobed: [
    { word: 'trilobed', word_class: 'AJ' },
    { word: 'trilobate', word_class: 'AJ' },
    { word: 'trilobated', word_class: 'AJ' }
  ],
  identify: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  drippings: [
    { word: 'drip', word_class: 'N' },
    { word: 'drip', word_class: 'V' },
    { word: 'dripping', word_class: 'N' },
    { word: 'dripping', word_class: 'AJ' },
    { word: 'dripping', word_class: 'AV' },
    { word: 'drippings', word_class: 'N' }
  ],
  fanion: [
    { word: 'fan', word_class: 'N' },
    { word: 'fan', word_class: 'V' },
    { word: 'fanion', word_class: 'N' },
    { word: 'fanned', word_class: 'AJ' }
  ],
  coagulation: [
    { word: 'coagulate', word_class: 'V' },
    { word: 'coagulate', word_class: 'AJ' },
    { word: 'coagulable', word_class: 'AJ' },
    { word: 'coagulated', word_class: 'AJ' },
    { word: 'coagulation', word_class: 'N' }
  ],
  puppetry: [
    { word: 'puppet', word_class: 'N' },
    { word: 'puppetry', word_class: 'N' },
    { word: 'puppeteer', word_class: 'N' }
  ],
  hiding: [
    { word: 'hide', word_class: 'N' },
    { word: 'hide', word_class: 'V' },
    { word: 'hiding', word_class: 'N' },
    { word: 'hidden', word_class: 'AJ' }
  ],
  serviced: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  denude: [
    { word: 'denude', word_class: 'V' },
    { word: 'denudate', word_class: 'V' },
    { word: 'denuded', word_class: 'AJ' },
    { word: 'denudate', word_class: 'AJ' },
    { word: 'denudation', word_class: 'N' }
  ],
  zoroastrianism: [
    { word: 'zoroastrian', word_class: 'N' },
    { word: 'zoroastrian', word_class: 'AJ' },
    { word: 'zoroastrianism', word_class: 'N' }
  ],
  musicalness: [
    { word: 'music', word_class: 'N' },
    { word: 'musical', word_class: 'N' },
    { word: 'musical', word_class: 'AJ' },
    { word: 'musicality', word_class: 'N' },
    { word: 'musically', word_class: 'AV' },
    { word: 'musicalness', word_class: 'N' }
  ],
  conference: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  exercise: [
    { word: 'exercise', word_class: 'N' },
    { word: 'exercise', word_class: 'V' },
    { word: 'exerciser', word_class: 'N' },
    { word: 'exercising', word_class: 'N' },
    { word: 'exercitation', word_class: 'N' }
  ],
  behaviourism: [
    { word: 'behaviour', word_class: 'N' },
    { word: 'behavioural', word_class: 'AJ' },
    { word: 'behaviourism', word_class: 'N' }
  ],
  picture: [
    { word: 'picture', word_class: 'N' },
    { word: 'picture', word_class: 'V' },
    { word: 'pictural', word_class: 'AJ' },
    { word: 'pictured', word_class: 'AJ' },
    { word: 'picturing', word_class: 'N' }
  ],
  predict: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  backslider: [
    { word: 'backslide', word_class: 'V' },
    { word: 'backslider', word_class: 'N' },
    { word: 'backsliding', word_class: 'N' }
  ],
  declining: [
    { word: 'decline', word_class: 'N' },
    { word: 'decline', word_class: 'V' },
    { word: 'declining', word_class: 'AJ' },
    { word: 'declination', word_class: 'N' }
  ],
  ferociousness: [
    { word: 'ferocious', word_class: 'AJ' },
    { word: 'ferociously', word_class: 'AV' },
    { word: 'ferociousness', word_class: 'N' }
  ],
  coarsen: [
    { word: 'coarsen', word_class: 'V' },
    { word: 'coarsened', word_class: 'AJ' },
    { word: 'coarsening', word_class: 'N' }
  ],
  air: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  chlorination: [
    { word: 'chlorine', word_class: 'N' },
    { word: 'chlorinate', word_class: 'V' },
    { word: 'chlorination', word_class: 'N' }
  ],
  heterogeneity: [
    { word: 'heterogeneity', word_class: 'N' },
    { word: 'heterogenous', word_class: 'AJ' },
    { word: 'heterogeneous', word_class: 'AJ' },
    { word: 'heterogeneousness', word_class: 'N' }
  ],
  dining: [
    { word: 'din', word_class: 'N' },
    { word: 'din', word_class: 'V' },
    { word: 'dine', word_class: 'V' },
    { word: 'diner', word_class: 'N' },
    { word: 'dining', word_class: 'N' }
  ],
  corrected: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  laws: [
    { word: 'law', word_class: 'N' },
    { word: 'laws', word_class: 'N' },
    { word: 'lawful', word_class: 'AJ' },
    { word: 'lawfulness', word_class: 'N' }
  ],
  covetous: [
    { word: 'covet', word_class: 'N' },
    { word: 'covet', word_class: 'V' },
    { word: 'coveted', word_class: 'AJ' },
    { word: 'covetous', word_class: 'AJ' },
    { word: 'covetously', word_class: 'AV' },
    { word: 'covetousness', word_class: 'N' }
  ],
  favorableness: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  masturbator: [
    { word: 'masturbate', word_class: 'V' },
    { word: 'masturbator', word_class: 'N' },
    { word: 'masturbation', word_class: 'N' }
  ],
  confidence: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  idleness: [
    { word: 'idle', word_class: 'V' },
    { word: 'idle', word_class: 'AJ' },
    { word: 'idler', word_class: 'N' },
    { word: 'idly', word_class: 'AV' },
    { word: 'idling', word_class: 'N' },
    { word: 'idleness', word_class: 'N' }
  ],
  retroactively: [
    { word: 'retroact', word_class: 'V' },
    { word: 'retroaction', word_class: 'N' },
    { word: 'retroactive', word_class: 'AJ' },
    { word: 'retroactively', word_class: 'AV' }
  ],
  develop: [
    { word: 'develop', word_class: 'V' },
    { word: 'developer', word_class: 'N' },
    { word: 'developed', word_class: 'AJ' },
    { word: 'developing', word_class: 'N' },
    { word: 'developing', word_class: 'AJ' },
    { word: 'development', word_class: 'N' }
  ],
  polarisation: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  serrate: [
    { word: 'serrate', word_class: 'V' },
    { word: 'serrate', word_class: 'AJ' },
    { word: 'serrated', word_class: 'AJ' },
    { word: 'serration', word_class: 'N' }
  ],
  lightsomeness: [
    { word: 'lightsome', word_class: 'AJ' },
    { word: 'lightsomely', word_class: 'AV' },
    { word: 'lightsomeness', word_class: 'N' }
  ],
  levitate: [
    { word: 'levitate', word_class: 'V' },
    { word: 'levitation', word_class: 'N' },
    { word: 'levitical', word_class: 'AJ' }
  ],
  atomization: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  defenselessness: [
    { word: 'defenseless', word_class: 'AJ' },
    { word: 'defenseless', word_class: 'AV' },
    { word: 'defenselessness', word_class: 'N' }
  ],
  delocalisation: [
    { word: 'delocalise', word_class: 'V' },
    { word: 'delocalize', word_class: 'V' },
    { word: 'delocalisation', word_class: 'N' },
    { word: 'delocalization', word_class: 'N' }
  ],
  congestion: [
    { word: 'congest', word_class: 'V' },
    { word: 'congested', word_class: 'AJ' },
    { word: 'congestion', word_class: 'N' },
    { word: 'congestive', word_class: 'AJ' }
  ],
  spectacles: [
    { word: 'spectacle', word_class: 'N' },
    { word: 'spectacles', word_class: 'N' },
    { word: 'spectacled', word_class: 'AJ' },
    { word: 'bespectacled', word_class: 'AJ' }
  ],
  craze: [{ word: 'craze', word_class: 'N' }, { word: 'craze', word_class: 'V' }, { word: 'crazed', word_class: 'AJ' }],
  pinnately: [
    { word: 'pinnate', word_class: 'AJ' },
    { word: 'pinnated', word_class: 'AJ' },
    { word: 'pinnately', word_class: 'AV' }
  ],
  cowling: [
    { word: 'cowl', word_class: 'N' },
    { word: 'cowl', word_class: 'V' },
    { word: 'cowled', word_class: 'AJ' },
    { word: 'cowling', word_class: 'N' }
  ],
  saturable: [
    { word: 'saturate', word_class: 'V' },
    { word: 'saturable', word_class: 'AJ' },
    { word: 'saturated', word_class: 'AJ' },
    { word: 'saturation', word_class: 'N' }
  ],
  duplicable: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  trekking: [
    { word: 'trek', word_class: 'N' },
    { word: 'trek', word_class: 'V' },
    { word: 'trekked', word_class: 'AJ' },
    { word: 'trekking', word_class: 'AJ' }
  ],
  entreatment: [
    { word: 'entreat', word_class: 'V' },
    { word: 'intreat', word_class: 'V' },
    { word: 'entreaty', word_class: 'N' },
    { word: 'entreatment', word_class: 'N' },
    { word: 'intreatment', word_class: 'N' }
  ],
  recalcitrance: [
    { word: 'recalcitrance', word_class: 'N' },
    { word: 'recalcitrancy', word_class: 'N' },
    { word: 'recalcitrant', word_class: 'AJ' }
  ],
  family: [
    { word: 'family', word_class: 'N' },
    { word: 'family', word_class: 'AV' },
    { word: 'familial', word_class: 'AJ' }
  ],
  conjure: [
    { word: 'conjure', word_class: 'V' },
    { word: 'conjurer', word_class: 'N' },
    { word: 'conjuror', word_class: 'N' },
    { word: 'conjuring', word_class: 'N' },
    { word: 'conjuration', word_class: 'N' }
  ],
  mummification: [
    { word: 'mummy', word_class: 'N' },
    { word: 'mummify', word_class: 'V' },
    { word: 'mummification', word_class: 'N' }
  ],
  subdivide: [
    { word: 'subdivide', word_class: 'V' },
    { word: 'subdivided', word_class: 'AJ' },
    { word: 'subdivision', word_class: 'N' }
  ],
  stargazing: [
    { word: 'stargaze', word_class: 'V' },
    { word: 'stargazer', word_class: 'N' },
    { word: 'stargazing', word_class: 'N' }
  ],
  admissibility: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  tear: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  humaneness: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  gut: [{ word: 'gut', word_class: 'N' }, { word: 'gut', word_class: 'V' }, { word: 'guts', word_class: 'N' }],
  reportage: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  barracks: [
    { word: 'barrack', word_class: 'N' },
    { word: 'barrack', word_class: 'V' },
    { word: 'barracks', word_class: 'N' },
    { word: 'barracking', word_class: 'N' }
  ],
  plough: [
    { word: 'plough', word_class: 'N' },
    { word: 'plough', word_class: 'V' },
    { word: 'ploughed', word_class: 'AJ' },
    { word: 'ploughing', word_class: 'N' },
    { word: 'ploughman', word_class: 'N' }
  ],
  denaturalise: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  profuse: [
    { word: 'profuse', word_class: 'N' },
    { word: 'profuse', word_class: 'V' },
    { word: 'profuse', word_class: 'AJ' },
    { word: 'profusion', word_class: 'N' },
    { word: 'profusely', word_class: 'AV' },
    { word: 'profuseness', word_class: 'N' }
  ],
  judging: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  bicorn: [
    { word: 'bicorn', word_class: 'N' },
    { word: 'bicorn', word_class: 'AJ' },
    { word: 'bicorne', word_class: 'N' },
    { word: 'bicorned', word_class: 'AJ' },
    { word: 'bicornate', word_class: 'AJ' }
  ],
  hypocritical: [
    { word: 'hypocrite', word_class: 'N' },
    { word: 'hypocritical', word_class: 'AJ' },
    { word: 'hypocritically', word_class: 'AV' }
  ],
  void: [
    { word: 'void', word_class: 'N' },
    { word: 'void', word_class: 'V' },
    { word: 'void', word_class: 'AJ' },
    { word: 'voiding', word_class: 'N' },
    { word: 'voidance', word_class: 'N' }
  ],
  reformed: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  radiate: [
    { word: 'radiate', word_class: 'V' },
    { word: 'radiator', word_class: 'N' },
    { word: 'radiation', word_class: 'N' },
    { word: 'radiating', word_class: 'AJ' }
  ],
  link: [
    { word: 'link', word_class: 'N' },
    { word: 'link', word_class: 'V' },
    { word: 'links', word_class: 'N' },
    { word: 'linkage', word_class: 'N' },
    { word: 'linkman', word_class: 'N' },
    { word: 'linked', word_class: 'AJ' }
  ],
  deriving: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  nation: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  interred: [
    { word: 'inter', word_class: 'V' },
    { word: 'inter', word_class: 'AJ' },
    { word: 'interment', word_class: 'N' },
    { word: 'interred', word_class: 'AJ' }
  ],
  familiarity: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  whisker: [
    { word: 'whisk', word_class: 'N' },
    { word: 'whisk', word_class: 'V' },
    { word: 'whisker', word_class: 'N' },
    { word: 'whisker', word_class: 'V' },
    { word: 'whiskers', word_class: 'N' },
    { word: 'whiskered', word_class: 'AJ' }
  ],
  apprisal: [
    { word: 'apprise', word_class: 'V' },
    { word: 'apprize', word_class: 'V' },
    { word: 'apprisal', word_class: 'N' }
  ],
  egotistical: [
    { word: 'egotist', word_class: 'N' },
    { word: 'egotistic', word_class: 'AJ' },
    { word: 'egotistical', word_class: 'AJ' },
    { word: 'egotistically', word_class: 'AV' }
  ],
  planted: [
    { word: 'plant', word_class: 'N' },
    { word: 'plant', word_class: 'V' },
    { word: 'planter', word_class: 'N' },
    { word: 'planted', word_class: 'AJ' },
    { word: 'planting', word_class: 'N' },
    { word: 'plantation', word_class: 'N' }
  ],
  differentiate: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  exult: [
    { word: 'exult', word_class: 'V' },
    { word: 'exultant', word_class: 'N' },
    { word: 'exultance', word_class: 'N' },
    { word: 'exultant', word_class: 'AJ' },
    { word: 'exulting', word_class: 'AJ' },
    { word: 'exultation', word_class: 'N' }
  ],
  chiseler: [
    { word: 'chisel', word_class: 'N' },
    { word: 'chisel', word_class: 'V' },
    { word: 'chiseler', word_class: 'N' },
    { word: 'chiseled', word_class: 'AJ' },
    { word: 'chiseller', word_class: 'N' }
  ],
  tiptoe: [
    { word: 'tiptoe', word_class: 'N' },
    { word: 'tiptoe', word_class: 'V' },
    { word: 'tiptoe', word_class: 'AJ' },
    { word: 'tiptoe', word_class: 'AV' }
  ],
  splayfooted: [
    { word: 'splayfoot', word_class: 'N' },
    { word: 'splayfoot', word_class: 'AJ' },
    { word: 'splayfooted', word_class: 'AJ' }
  ],
  polluted: [
    { word: 'pollute', word_class: 'V' },
    { word: 'pollutant', word_class: 'N' },
    { word: 'polluted', word_class: 'AJ' },
    { word: 'pollution', word_class: 'N' }
  ],
  plundering: [
    { word: 'plunder', word_class: 'N' },
    { word: 'plunder', word_class: 'V' },
    { word: 'plunderer', word_class: 'N' },
    { word: 'plunderage', word_class: 'N' },
    { word: 'plundered', word_class: 'AJ' },
    { word: 'plundering', word_class: 'N' },
    { word: 'plundering', word_class: 'AJ' }
  ],
  wrangler: [
    { word: 'wrangle', word_class: 'N' },
    { word: 'wrangle', word_class: 'V' },
    { word: 'wrangler', word_class: 'N' },
    { word: 'wrangling', word_class: 'N' }
  ],
  finality: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  exhibitor: [
    { word: 'exhibit', word_class: 'N' },
    { word: 'exhibit', word_class: 'V' },
    { word: 'exhibitor', word_class: 'N' },
    { word: 'exhibition', word_class: 'N' }
  ],
  unthinkable: [
    { word: 'unthinking', word_class: 'AJ' },
    { word: 'unthinking', word_class: 'AV' },
    { word: 'unthinkable', word_class: 'AJ' },
    { word: 'unthinkably', word_class: 'AV' }
  ],
  unaccepted: [
    { word: 'unaccepted', word_class: 'AJ' },
    { word: 'unacceptable', word_class: 'AJ' },
    { word: 'unacceptably', word_class: 'AV' },
    { word: 'unacceptability', word_class: 'N' },
    { word: 'unacceptableness', word_class: 'N' }
  ],
  dim: [
    { word: 'dim', word_class: 'V' },
    { word: 'dim', word_class: 'AJ' },
    { word: 'dimer', word_class: 'N' },
    { word: 'dimmed', word_class: 'AJ' },
    { word: 'dimness', word_class: 'N' }
  ],
  confessed: [
    { word: 'confess', word_class: 'V' },
    { word: 'confessor', word_class: 'N' },
    { word: 'confessed', word_class: 'AJ' },
    { word: 'confession', word_class: 'N' }
  ],
  nefarious: [
    { word: 'nefarious', word_class: 'AJ' },
    { word: 'nefariously', word_class: 'AV' },
    { word: 'nefariousness', word_class: 'N' }
  ],
  slicer: [
    { word: 'slice', word_class: 'N' },
    { word: 'slice', word_class: 'V' },
    { word: 'slicer', word_class: 'N' },
    { word: 'sliced', word_class: 'AJ' },
    { word: 'slicing', word_class: 'N' }
  ],
  defendable: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  subliminal: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  expectation: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  solemnity: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  immensity: [
    { word: 'immense', word_class: 'AJ' },
    { word: 'immensity', word_class: 'N' },
    { word: 'immensely', word_class: 'AV' },
    { word: 'immenseness', word_class: 'N' }
  ],
  fictionalise: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  interrupter: [
    { word: 'interrupt', word_class: 'V' },
    { word: 'interrupter', word_class: 'N' },
    { word: 'interrupted', word_class: 'AJ' },
    { word: 'interruption', word_class: 'N' }
  ],
  sustenance: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  gallop: [
    { word: 'gallop', word_class: 'N' },
    { word: 'gallop', word_class: 'V' },
    { word: 'galloping', word_class: 'AJ' }
  ],
  accordant: [
    { word: 'accord', word_class: 'N' },
    { word: 'accord', word_class: 'V' },
    { word: 'accordion', word_class: 'N' },
    { word: 'accordance', word_class: 'N' },
    { word: 'accordant', word_class: 'AJ' },
    { word: 'according', word_class: 'AJ' },
    { word: 'accordion', word_class: 'AJ' }
  ],
  licence: [
    { word: 'licence', word_class: 'N' },
    { word: 'licence', word_class: 'V' },
    { word: 'licenced', word_class: 'AJ' }
  ],
  stride: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  wishfulness: [
    { word: 'wish', word_class: 'N' },
    { word: 'wish', word_class: 'V' },
    { word: 'wishing', word_class: 'N' },
    { word: 'wishful', word_class: 'AJ' },
    { word: 'wishfulness', word_class: 'N' }
  ],
  lousiness: [
    { word: 'louse', word_class: 'N' },
    { word: 'louse', word_class: 'V' },
    { word: 'lousy', word_class: 'AJ' },
    { word: 'lousiness', word_class: 'N' }
  ],
  exchanged: [
    { word: 'exchange', word_class: 'N' },
    { word: 'exchange', word_class: 'V' },
    { word: 'exchanger', word_class: 'N' },
    { word: 'exchanged', word_class: 'AJ' },
    { word: 'exchangeable', word_class: 'AJ' },
    { word: 'exchangeability', word_class: 'N' }
  ],
  disconcerted: [
    { word: 'disconcert', word_class: 'N' },
    { word: 'disconcert', word_class: 'V' },
    { word: 'disconcerted', word_class: 'AJ' },
    { word: 'disconcertion', word_class: 'N' },
    { word: 'disconcerting', word_class: 'AJ' },
    { word: 'disconcertment', word_class: 'N' }
  ],
  froth: [
    { word: 'froth', word_class: 'N' },
    { word: 'froth', word_class: 'V' },
    { word: 'frothing', word_class: 'AJ' }
  ],
  menacing: [
    { word: 'menace', word_class: 'N' },
    { word: 'menace', word_class: 'V' },
    { word: 'menacing', word_class: 'AJ' }
  ],
  determinative: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  botanize: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  italian: [
    { word: 'italian', word_class: 'N' },
    { word: 'italian', word_class: 'AJ' },
    { word: 'italianise', word_class: 'V' },
    { word: 'italianize', word_class: 'V' },
    { word: 'italianisation', word_class: 'N' },
    { word: 'italianization', word_class: 'N' }
  ],
  murmuring: [
    { word: 'murmur', word_class: 'N' },
    { word: 'murmur', word_class: 'V' },
    { word: 'murmuring', word_class: 'N' },
    { word: 'murmuring', word_class: 'AJ' },
    { word: 'murmurous', word_class: 'AJ' },
    { word: 'murmuration', word_class: 'N' }
  ],
  supplies: [
    { word: 'supply', word_class: 'N' },
    { word: 'supply', word_class: 'V' },
    { word: 'supplier', word_class: 'N' },
    { word: 'supplies', word_class: 'N' },
    { word: 'suppliant', word_class: 'N' },
    { word: 'supplying', word_class: 'N' },
    { word: 'suppliant', word_class: 'AJ' }
  ],
  atonality: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  fabian: [
    { word: 'fabian', word_class: 'N' },
    { word: 'fabian', word_class: 'AJ' },
    { word: 'fabianism', word_class: 'N' }
  ],
  legacy: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  engorged: [
    { word: 'engorge', word_class: 'V' },
    { word: 'engorged', word_class: 'AJ' },
    { word: 'engorgement', word_class: 'N' }
  ],
  abductor: [
    { word: 'abduct', word_class: 'V' },
    { word: 'abductor', word_class: 'N' },
    { word: 'abducted', word_class: 'AJ' },
    { word: 'abduction', word_class: 'N' },
    { word: 'abducting', word_class: 'AJ' }
  ],
  dismantling: [
    { word: 'dismantle', word_class: 'N' },
    { word: 'dismantle', word_class: 'V' },
    { word: 'dismantled', word_class: 'AJ' },
    { word: 'dismantling', word_class: 'N' },
    { word: 'dismantlement', word_class: 'N' }
  ],
  reproducible: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  formularising: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  falsifier: [
    { word: 'falsify', word_class: 'V' },
    { word: 'falsifier', word_class: 'N' },
    { word: 'falsifying', word_class: 'N' },
    { word: 'falsification', word_class: 'N' }
  ],
  late: [
    { word: 'late', word_class: 'AJ' },
    { word: 'late', word_class: 'AV' },
    { word: 'lately', word_class: 'AV' },
    { word: 'lateness', word_class: 'N' }
  ],
  cursive: [
    { word: 'cursive', word_class: 'N' },
    { word: 'cursive', word_class: 'AJ' },
    { word: 'cursively', word_class: 'AV' }
  ],
  coextension: [
    { word: 'coextend', word_class: 'V' },
    { word: 'coextension', word_class: 'N' },
    { word: 'coextensive', word_class: 'AJ' }
  ],
  delusively: [
    { word: 'delude', word_class: 'V' },
    { word: 'delusion', word_class: 'N' },
    { word: 'delusive', word_class: 'AJ' },
    { word: 'delusively', word_class: 'AV' },
    { word: 'delusionary', word_class: 'AJ' }
  ],
  rivalry: [
    { word: 'rival', word_class: 'N' },
    { word: 'rival', word_class: 'V' },
    { word: 'rival', word_class: 'AJ' },
    { word: 'rivalry', word_class: 'N' }
  ],
  competently: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  introduction: [
    { word: 'introduce', word_class: 'V' },
    { word: 'introduction', word_class: 'N' },
    { word: 'introductory', word_class: 'AJ' }
  ],
  stomachal: [
    { word: 'stomach', word_class: 'N' },
    { word: 'stomach', word_class: 'V' },
    { word: 'stomacher', word_class: 'N' },
    { word: 'stomachal', word_class: 'AJ' },
    { word: 'stomachic', word_class: 'AJ' }
  ],
  expatriation: [
    { word: 'expatriate', word_class: 'N' },
    { word: 'expatriate', word_class: 'V' },
    { word: 'expatriation', word_class: 'N' }
  ],
  antithetical: [
    { word: 'antithetic', word_class: 'AJ' },
    { word: 'antithetical', word_class: 'AJ' },
    { word: 'antithetically', word_class: 'AV' }
  ],
  measuring: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  cleaners: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  toll: [
    { word: 'toll', word_class: 'N' },
    { word: 'toll', word_class: 'V' },
    { word: 'toller', word_class: 'N' },
    { word: 'tollman', word_class: 'N' }
  ],
  sloganeering: [
    { word: 'slogan', word_class: 'N' },
    { word: 'sloganeer', word_class: 'N' },
    { word: 'sloganeer', word_class: 'V' },
    { word: 'sloganeering', word_class: 'N' }
  ],
  digestibleness: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  transpire: [
    { word: 'transpire', word_class: 'V' },
    { word: 'transpirate', word_class: 'V' },
    { word: 'transpiring', word_class: 'AJ' },
    { word: 'transpiration', word_class: 'N' }
  ],
  binder: [
    { word: 'bind', word_class: 'N' },
    { word: 'bind', word_class: 'V' },
    { word: 'binder', word_class: 'N' },
    { word: 'bindery', word_class: 'N' },
    { word: 'binding', word_class: 'N' },
    { word: 'bindery', word_class: 'AJ' },
    { word: 'binding', word_class: 'AJ' }
  ],
  type: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  coordinated: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  dependence: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  roomer: [
    { word: 'room', word_class: 'N' },
    { word: 'room', word_class: 'V' },
    { word: 'rooms', word_class: 'N' },
    { word: 'roomer', word_class: 'N' },
    { word: 'roomful', word_class: 'N' },
    { word: 'roomful', word_class: 'AJ' }
  ],
  dallier: [
    { word: 'dally', word_class: 'V' },
    { word: 'dallier', word_class: 'N' },
    { word: 'dalliance', word_class: 'N' }
  ],
  swathing: [
    { word: 'swath', word_class: 'N' },
    { word: 'swathe', word_class: 'N' },
    { word: 'swathe', word_class: 'V' },
    { word: 'swathing', word_class: 'N' }
  ],
  veiled: [
    { word: 'veil', word_class: 'N' },
    { word: 'veil', word_class: 'V' },
    { word: 'veiled', word_class: 'AJ' },
    { word: 'veiling', word_class: 'N' }
  ],
  atheistical: [
    { word: 'atheist', word_class: 'N' },
    { word: 'atheist', word_class: 'AJ' },
    { word: 'atheistic', word_class: 'AJ' },
    { word: 'atheistical', word_class: 'AJ' }
  ],
  ingenuously: [
    { word: 'ingenue', word_class: 'N' },
    { word: 'ingenuity', word_class: 'N' },
    { word: 'ingenuous', word_class: 'AJ' },
    { word: 'ingenuously', word_class: 'AV' },
    { word: 'ingenuousness', word_class: 'N' }
  ],
  depicture: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  feudalize: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  solved: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  peeper: [{ word: 'peep', word_class: 'N' }, { word: 'peep', word_class: 'V' }, { word: 'peeper', word_class: 'N' }],
  massiveness: [
    { word: 'massive', word_class: 'AJ' },
    { word: 'massively', word_class: 'AV' },
    { word: 'massiveness', word_class: 'N' }
  ],
  tabulate: [
    { word: 'tabulate', word_class: 'V' },
    { word: 'tabulator', word_class: 'N' },
    { word: 'tabulation', word_class: 'N' }
  ],
  instalment: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  ruminant: [
    { word: 'ruminant', word_class: 'N' },
    { word: 'ruminate', word_class: 'V' },
    { word: 'ruminant', word_class: 'AJ' },
    { word: 'rumination', word_class: 'N' },
    { word: 'ruminative', word_class: 'AJ' }
  ],
  weightlift: [
    { word: 'weightlift', word_class: 'V' },
    { word: 'weightlifter', word_class: 'N' },
    { word: 'weightlifting', word_class: 'N' }
  ],
  browsing: [
    { word: 'browse', word_class: 'N' },
    { word: 'browse', word_class: 'V' },
    { word: 'browser', word_class: 'N' },
    { word: 'browsing', word_class: 'N' }
  ],
  conductance: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  ingenuity: [
    { word: 'ingenue', word_class: 'N' },
    { word: 'ingenuity', word_class: 'N' },
    { word: 'ingenuous', word_class: 'AJ' },
    { word: 'ingenuously', word_class: 'AV' },
    { word: 'ingenuousness', word_class: 'N' }
  ],
  tint: [
    { word: 'tint', word_class: 'N' },
    { word: 'tint', word_class: 'V' },
    { word: 'tinted', word_class: 'AJ' },
    { word: 'tinting', word_class: 'N' }
  ],
  bowdlerization: [
    { word: 'bowdlerise', word_class: 'V' },
    { word: 'bowdlerism', word_class: 'N' },
    { word: 'bowdlerize', word_class: 'V' },
    { word: 'bowdlerisation', word_class: 'N' },
    { word: 'bowdlerization', word_class: 'N' }
  ],
  preside: [
    { word: 'preside', word_class: 'N' },
    { word: 'preside', word_class: 'V' },
    { word: 'president', word_class: 'N' },
    { word: 'presidency', word_class: 'N' },
    { word: 'president', word_class: 'AJ' }
  ],
  harmoniously: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  propman: [{ word: 'prop', word_class: 'N' }, { word: 'prop', word_class: 'V' }, { word: 'propman', word_class: 'N' }],
  agent: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  trepan: [
    { word: 'trepan', word_class: 'N' },
    { word: 'trepan', word_class: 'V' },
    { word: 'trepanation', word_class: 'N' }
  ],
  impedance: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  pasteur: [
    { word: 'pasteur', word_class: 'N' },
    { word: 'pasteurize', word_class: 'V' },
    { word: 'pasteurized', word_class: 'AJ' },
    { word: 'pasteurization', word_class: 'N' }
  ],
  moderne: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  whore: [
    { word: 'whore', word_class: 'N' },
    { word: 'whore', word_class: 'V' },
    { word: 'whoredom', word_class: 'N' }
  ],
  hyping: [
    { word: 'hype', word_class: 'N' },
    { word: 'hype', word_class: 'V' },
    { word: 'hyped', word_class: 'AJ' },
    { word: 'hyping', word_class: 'AJ' }
  ],
  protectiveness: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  greyness: [
    { word: 'grey', word_class: 'N' },
    { word: 'grey', word_class: 'V' },
    { word: 'grey', word_class: 'AJ' },
    { word: 'greyness', word_class: 'N' }
  ],
  fortify: [
    { word: 'fortify', word_class: 'V' },
    { word: 'fortified', word_class: 'AJ' },
    { word: 'fortifying', word_class: 'AJ' },
    { word: 'fortification', word_class: 'N' }
  ],
  corrupt: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  representable: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  repatriation: [
    { word: 'repatriate', word_class: 'N' },
    { word: 'repatriate', word_class: 'V' },
    { word: 'repatriation', word_class: 'N' }
  ],
  moderateness: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  individuate: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  gallant: [
    { word: 'gall', word_class: 'V' },
    { word: 'gall', word_class: 'N' },
    { word: 'gallant', word_class: 'N' },
    { word: 'gallant', word_class: 'V' },
    { word: 'galled', word_class: 'AJ' },
    { word: 'gallant', word_class: 'AJ' },
    { word: 'galling', word_class: 'AJ' },
    { word: 'gallantry', word_class: 'N' }
  ],
  liechtensteiner: [
    { word: 'liechtenstein', word_class: 'N' },
    { word: 'liechtensteiner', word_class: 'N' },
    { word: 'liechtensteiner', word_class: 'AJ' }
  ],
  grangerization: [
    { word: 'granger', word_class: 'N' },
    { word: 'grangerism', word_class: 'N' },
    { word: 'grangerize', word_class: 'V' },
    { word: 'grangerization', word_class: 'N' }
  ],
  stripe: [
    { word: 'stripe', word_class: 'N' },
    { word: 'stripe', word_class: 'V' },
    { word: 'stripes', word_class: 'N' },
    { word: 'stripy', word_class: 'AJ' },
    { word: 'striped', word_class: 'AJ' },
    { word: 'striping', word_class: 'N' }
  ],
  whisper: [
    { word: 'whisper', word_class: 'N' },
    { word: 'whisper', word_class: 'V' },
    { word: 'whisperer', word_class: 'N' },
    { word: 'whispered', word_class: 'AJ' },
    { word: 'whispering', word_class: 'N' },
    { word: 'whispering', word_class: 'AJ' }
  ],
  'right-hand': [
    { word: 'right-hand', word_class: 'AJ' },
    { word: 'right-hander', word_class: 'N' },
    { word: 'right-handed', word_class: 'AJ' }
  ],
  surmount: [
    { word: 'surmount', word_class: 'V' },
    { word: 'surmounted', word_class: 'AJ' },
    { word: 'surmountable', word_class: 'AJ' }
  ],
  horticulturally: [
    { word: 'horticulture', word_class: 'N' },
    { word: 'horticultural', word_class: 'AJ' },
    { word: 'horticulturally', word_class: 'AV' }
  ],
  lobation: [
    { word: 'lobate', word_class: 'V' },
    { word: 'lobate', word_class: 'AJ' },
    { word: 'lobated', word_class: 'AJ' },
    { word: 'lobation', word_class: 'N' }
  ],
  renewed: [
    { word: 'renew', word_class: 'N' },
    { word: 'renew', word_class: 'V' },
    { word: 'renew', word_class: 'AJ' },
    { word: 'renewal', word_class: 'N' },
    { word: 'renewal', word_class: 'V' },
    { word: 'renewed', word_class: 'AJ' },
    { word: 'renewing', word_class: 'AJ' },
    { word: 'renewable', word_class: 'AJ' }
  ],
  distributive: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  'hugger-mugger': [
    { word: 'hugger-mugger', word_class: 'N' },
    { word: 'hugger-mugger', word_class: 'AJ' },
    { word: 'hugger-mugger', word_class: 'AV' }
  ],
  eternity: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  glaciation: [
    { word: 'glaciate', word_class: 'V' },
    { word: 'glaciated', word_class: 'AJ' },
    { word: 'glaciation', word_class: 'N' }
  ],
  anarchism: [
    { word: 'anarch', word_class: 'N' },
    { word: 'anarchic', word_class: 'AJ' },
    { word: 'anarchism', word_class: 'N' },
    { word: 'anarchical', word_class: 'AJ' },
    { word: 'anarchically', word_class: 'AV' }
  ],
  worshipping: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  commercialized: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  germanisation: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  immanency: [
    { word: 'immanence', word_class: 'N' },
    { word: 'immanency', word_class: 'N' },
    { word: 'immanent', word_class: 'AJ' }
  ],
  intonate: [
    { word: 'intone', word_class: 'N' },
    { word: 'intone', word_class: 'V' },
    { word: 'intonate', word_class: 'V' },
    { word: 'intoned', word_class: 'AJ' },
    { word: 'intonation', word_class: 'N' }
  ],
  insolvable: [
    { word: 'insolvent', word_class: 'N' },
    { word: 'insolvency', word_class: 'N' },
    { word: 'insolvent', word_class: 'AJ' },
    { word: 'insolvable', word_class: 'AJ' }
  ],
  cantor: [
    { word: 'cant', word_class: 'N' },
    { word: 'cant', word_class: 'V' },
    { word: 'cant', word_class: 'AJ' },
    { word: 'cantor', word_class: 'N' },
    { word: 'canted', word_class: 'AJ' }
  ],
  slaughterer: [
    { word: 'slaughter', word_class: 'N' },
    { word: 'slaughter', word_class: 'V' },
    { word: 'slaughterer', word_class: 'N' },
    { word: 'slaughterous', word_class: 'AJ' }
  ],
  appealing: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  theologisation: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  capacitate: [
    { word: 'capacitate', word_class: 'V' },
    { word: 'capacitance', word_class: 'N' },
    { word: 'capacitive', word_class: 'AJ' },
    { word: 'capacitation', word_class: 'N' }
  ],
  resonator: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  swallowed: [
    { word: 'swallow', word_class: 'N' },
    { word: 'swallow', word_class: 'V' },
    { word: 'swallowed', word_class: 'AJ' },
    { word: 'deglutition', word_class: 'N' }
  ],
  romanticisation: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  complicated: [
    { word: 'complicate', word_class: 'V' },
    { word: 'complicity', word_class: 'N' },
    { word: 'complicated', word_class: 'AJ' },
    { word: 'complicating', word_class: 'N' },
    { word: 'complication', word_class: 'N' }
  ],
  shunt: [{ word: 'shunt', word_class: 'N' }, { word: 'shunt', word_class: 'V' }, { word: 'shunter', word_class: 'N' }],
  sensor: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  viscidness: [
    { word: 'viscid', word_class: 'AJ' },
    { word: 'viscidity', word_class: 'N' },
    { word: 'viscidness', word_class: 'N' }
  ],
  issuance: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  maintain: [
    { word: 'maintain', word_class: 'V' },
    { word: 'maintained', word_class: 'AJ' },
    { word: 'maintenance', word_class: 'N' },
    { word: 'maintainable', word_class: 'AJ' }
  ],
  anaphora: [
    { word: 'anaphor', word_class: 'N' },
    { word: 'anaphora', word_class: 'N' },
    { word: 'anaphoric', word_class: 'AJ' }
  ],
  spiritually: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  postulation: [
    { word: 'postulant', word_class: 'N' },
    { word: 'postulate', word_class: 'N' },
    { word: 'postulate', word_class: 'V' },
    { word: 'postulation', word_class: 'N' },
    { word: 'postulational', word_class: 'AJ' }
  ],
  fattening: [
    { word: 'fatten', word_class: 'V' },
    { word: 'fattened', word_class: 'AJ' },
    { word: 'fattening', word_class: 'AJ' }
  ],
  conduction: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  refresh: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  guesstimate: [
    { word: 'guesstimate', word_class: 'N' },
    { word: 'guesstimate', word_class: 'V' },
    { word: 'guesstimation', word_class: 'N' }
  ],
  purposive: [
    { word: 'purpose', word_class: 'N' },
    { word: 'purpose', word_class: 'V' },
    { word: 'purposely', word_class: 'AV' },
    { word: 'purposive', word_class: 'AJ' },
    { word: 'purposeful', word_class: 'AJ' },
    { word: 'purposefulness', word_class: 'N' }
  ],
  reporting: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  teaching: [
    { word: 'teach', word_class: 'N' },
    { word: 'teach', word_class: 'V' },
    { word: 'teacher', word_class: 'N' },
    { word: 'teaching', word_class: 'N' }
  ],
  posit: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  distasteful: [
    { word: 'distaste', word_class: 'N' },
    { word: 'distasteful', word_class: 'AJ' },
    { word: 'distastefulness', word_class: 'N' }
  ],
  ingraftation: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  oncology: [
    { word: 'oncology', word_class: 'N' },
    { word: 'oncologic', word_class: 'AJ' },
    { word: 'oncological', word_class: 'AJ' }
  ],
  drizzle: [
    { word: 'drizzle', word_class: 'N' },
    { word: 'drizzle', word_class: 'V' },
    { word: 'drizzly', word_class: 'AJ' },
    { word: 'drizzling', word_class: 'AJ' }
  ],
  meditative: [
    { word: 'meditate', word_class: 'V' },
    { word: 'meditation', word_class: 'N' },
    { word: 'meditative', word_class: 'AJ' },
    { word: 'meditatively', word_class: 'AV' },
    { word: 'meditativeness', word_class: 'N' }
  ],
  dismounted: [
    { word: 'dismount', word_class: 'N' },
    { word: 'dismount', word_class: 'V' },
    { word: 'dismounted', word_class: 'AJ' }
  ],
  defeature: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  reciprocal: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  impale: [
    { word: 'impale', word_class: 'V' },
    { word: 'impale', word_class: 'AJ' },
    { word: 'impalement', word_class: 'N' },
    { word: 'impalempale', word_class: 'V' },
    { word: 'impalempalement', word_class: 'N' }
  ],
  credibility: [
    { word: 'credible', word_class: 'AJ' },
    { word: 'credibly', word_class: 'AV' },
    { word: 'credibility', word_class: 'N' },
    { word: 'credibleness', word_class: 'N' }
  ],
  indubitably: [
    { word: 'indubitable', word_class: 'AJ' },
    { word: 'indubitably', word_class: 'AV' },
    { word: 'indubitability', word_class: 'N' }
  ],
  squint: [
    { word: 'squint', word_class: 'N' },
    { word: 'squint', word_class: 'V' },
    { word: 'squint', word_class: 'AJ' },
    { word: 'squinty', word_class: 'AJ' },
    { word: 'squinting', word_class: 'AJ' }
  ],
  salving: [
    { word: 'salve', word_class: 'N' },
    { word: 'salve', word_class: 'V' },
    { word: 'salver', word_class: 'N' },
    { word: 'salvor', word_class: 'N' },
    { word: 'salving', word_class: 'AJ' },
    { word: 'salvation', word_class: 'N' }
  ],
  conscript: [
    { word: 'conscript', word_class: 'N' },
    { word: 'conscript', word_class: 'V' },
    { word: 'conscription', word_class: 'N' }
  ],
  refreshing: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  hyped: [
    { word: 'hype', word_class: 'N' },
    { word: 'hype', word_class: 'V' },
    { word: 'hyped', word_class: 'AJ' },
    { word: 'hyping', word_class: 'AJ' }
  ],
  accelerating: [
    { word: 'accelerate', word_class: 'V' },
    { word: 'accelerator', word_class: 'N' },
    { word: 'accelerated', word_class: 'AJ' },
    { word: 'acceleration', word_class: 'N' },
    { word: 'accelerating', word_class: 'AJ' }
  ],
  molar: [
    { word: 'molar', word_class: 'N' },
    { word: 'molar', word_class: 'AJ' },
    { word: 'molarity', word_class: 'N' }
  ],
  betrothed: [
    { word: 'betroth', word_class: 'V' },
    { word: 'betrothal', word_class: 'N' },
    { word: 'betrothed', word_class: 'N' },
    { word: 'betrothed', word_class: 'AJ' }
  ],
  plotted: [
    { word: 'plot', word_class: 'N' },
    { word: 'plot', word_class: 'V' },
    { word: 'plotted', word_class: 'AJ' }
  ],
  utilization: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  adulterated: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  profaneness: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  blacking: [
    { word: 'black', word_class: 'V' },
    { word: 'black', word_class: 'N' },
    { word: 'black', word_class: 'AJ' },
    { word: 'blacking', word_class: 'N' },
    { word: 'blackness', word_class: 'N' }
  ],
  gentile: [
    { word: 'gentile', word_class: 'N' },
    { word: 'gentile', word_class: 'AJ' },
    { word: 'gentility', word_class: 'N' }
  ],
  flimsiness: [
    { word: 'flimsy', word_class: 'N' },
    { word: 'flimsy', word_class: 'AJ' },
    { word: 'flimsiness', word_class: 'N' }
  ],
  countermove: [
    { word: 'countermove', word_class: 'N' },
    { word: 'countermove', word_class: 'V' },
    { word: 'countermovement', word_class: 'N' }
  ],
  ponderousness: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  rottenness: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  improvised: [
    { word: 'improvise', word_class: 'V' },
    { word: 'improvised', word_class: 'AJ' },
    { word: 'improvisation', word_class: 'N' }
  ],
  theatricality: [
    { word: 'theater', word_class: 'N' },
    { word: 'theatric', word_class: 'AJ' },
    { word: 'theatrical', word_class: 'N' },
    { word: 'theatrical', word_class: 'AJ' },
    { word: 'theatricality', word_class: 'N' },
    { word: 'theatrically', word_class: 'AV' }
  ],
  gain: [
    { word: 'gain', word_class: 'N' },
    { word: 'gain', word_class: 'V' },
    { word: 'gainer', word_class: 'N' },
    { word: 'gainful', word_class: 'AJ' }
  ],
  trooper: [
    { word: 'troop', word_class: 'N' },
    { word: 'troop', word_class: 'V' },
    { word: 'troops', word_class: 'N' },
    { word: 'trooper', word_class: 'N' },
    { word: 'troopship', word_class: 'N' }
  ],
  driveller: [
    { word: 'drivel', word_class: 'N' },
    { word: 'drivel', word_class: 'V' },
    { word: 'driveller', word_class: 'N' }
  ],
  hellene: [
    { word: 'hellene', word_class: 'N' },
    { word: 'hellenic', word_class: 'N' },
    { word: 'hellenic', word_class: 'AJ' },
    { word: 'hellenism', word_class: 'N' },
    { word: 'hellenize', word_class: 'V' },
    { word: 'hellenization', word_class: 'N' }
  ],
  sexual: [
    { word: 'sexual', word_class: 'AJ' },
    { word: 'sexuality', word_class: 'N' },
    { word: 'sexually', word_class: 'AV' }
  ],
  grappling: [
    { word: 'grapple', word_class: 'N' },
    { word: 'grapple', word_class: 'V' },
    { word: 'grappling', word_class: 'N' }
  ],
  enamel: [
    { word: 'enamel', word_class: 'N' },
    { word: 'enamel', word_class: 'V' },
    { word: 'enameled', word_class: 'AJ' }
  ],
  jubilance: [
    { word: 'jubilate', word_class: 'V' },
    { word: 'jubilance', word_class: 'N' },
    { word: 'jubilancy', word_class: 'N' },
    { word: 'jubilant', word_class: 'AJ' },
    { word: 'jubilation', word_class: 'N' }
  ],
  dancer: [
    { word: 'dance', word_class: 'N' },
    { word: 'dance', word_class: 'V' },
    { word: 'dancer', word_class: 'N' },
    { word: 'dancing', word_class: 'N' },
    { word: 'dancing', word_class: 'AJ' }
  ],
  decarbonize: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  continence: [
    { word: 'continent', word_class: 'N' },
    { word: 'continence', word_class: 'N' },
    { word: 'continency', word_class: 'N' },
    { word: 'continent', word_class: 'AJ' }
  ],
  salted: [
    { word: 'salt', word_class: 'N' },
    { word: 'salt', word_class: 'V' },
    { word: 'salt', word_class: 'AJ' },
    { word: 'salted', word_class: 'AJ' },
    { word: 'salting', word_class: 'N' }
  ],
  seasonably: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  rouse: [
    { word: 'rouse', word_class: 'N' },
    { word: 'rouse', word_class: 'V' },
    { word: 'rousing', word_class: 'N' },
    { word: 'rousing', word_class: 'AJ' }
  ],
  jejune: [
    { word: 'jejune', word_class: 'AJ' },
    { word: 'jejunity', word_class: 'N' },
    { word: 'jejunely', word_class: 'AV' },
    { word: 'jejunitis', word_class: 'N' },
    { word: 'jejuneness', word_class: 'N' }
  ],
  creativity: [
    { word: 'creative', word_class: 'AJ' },
    { word: 'creativity', word_class: 'N' },
    { word: 'creatively', word_class: 'AV' },
    { word: 'creativeness', word_class: 'N' }
  ],
  obnoxiously: [
    { word: 'obnoxious', word_class: 'AJ' },
    { word: 'obnoxiously', word_class: 'AV' },
    { word: 'obnoxiousness', word_class: 'N' }
  ],
  ingested: [
    { word: 'ingest', word_class: 'V' },
    { word: 'ingested', word_class: 'AJ' },
    { word: 'ingestion', word_class: 'N' }
  ],
  coalesce: [
    { word: 'coalesce', word_class: 'V' },
    { word: 'coalesced', word_class: 'AJ' },
    { word: 'coalescence', word_class: 'N' },
    { word: 'coalescency', word_class: 'N' }
  ],
  metal: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  adumbrate: [
    { word: 'adumbrate', word_class: 'V' },
    { word: 'adumbration', word_class: 'N' },
    { word: 'adumbrative', word_class: 'AJ' }
  ],
  honourably: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  combine: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  recompense: [
    { word: 'recompense', word_class: 'N' },
    { word: 'recompense', word_class: 'V' },
    { word: 'recompensation', word_class: 'N' }
  ],
  diner: [
    { word: 'din', word_class: 'N' },
    { word: 'din', word_class: 'V' },
    { word: 'dine', word_class: 'V' },
    { word: 'diner', word_class: 'N' },
    { word: 'dining', word_class: 'N' }
  ],
  scorched: [
    { word: 'scorch', word_class: 'N' },
    { word: 'scorch', word_class: 'V' },
    { word: 'scorcher', word_class: 'N' },
    { word: 'scorched', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AV' }
  ],
  wolfman: [{ word: 'wolf', word_class: 'N' }, { word: 'wolf', word_class: 'V' }, { word: 'wolfman', word_class: 'N' }],
  oppositely: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  inhibited: [
    { word: 'inhibit', word_class: 'V' },
    { word: 'inhibitor', word_class: 'N' },
    { word: 'inhibited', word_class: 'AJ' },
    { word: 'inhibition', word_class: 'N' },
    { word: 'inhibiting', word_class: 'AJ' }
  ],
  achieve: [
    { word: 'achieve', word_class: 'V' },
    { word: 'achiever', word_class: 'N' },
    { word: 'achievable', word_class: 'AJ' },
    { word: 'achievement', word_class: 'N' },
    { word: 'achievability', word_class: 'N' }
  ],
  spinal: [
    { word: 'spin', word_class: 'N' },
    { word: 'spin', word_class: 'V' },
    { word: 'spinal', word_class: 'N' },
    { word: 'spinal', word_class: 'AJ' },
    { word: 'spinning', word_class: 'N' },
    { word: 'spinally', word_class: 'AV' },
    { word: 'spinning', word_class: 'AJ' }
  ],
  signify: [
    { word: 'signify', word_class: 'V' },
    { word: 'signification', word_class: 'N' },
    { word: 'significative', word_class: 'AJ' }
  ],
  ecclesiastical: [
    { word: 'ecclesiastic', word_class: 'N' },
    { word: 'ecclesiastic', word_class: 'AJ' },
    { word: 'ecclesiastical', word_class: 'AJ' },
    { word: 'ecclesiastically', word_class: 'AV' }
  ],
  loosely: [
    { word: 'loose', word_class: 'V' },
    { word: 'loose', word_class: 'AJ' },
    { word: 'loose', word_class: 'AV' },
    { word: 'loosely', word_class: 'AV' },
    { word: 'looseness', word_class: 'N' }
  ],
  chelate: [
    { word: 'chelate', word_class: 'N' },
    { word: 'chelate', word_class: 'V' },
    { word: 'chelate', word_class: 'AJ' },
    { word: 'chelated', word_class: 'AJ' },
    { word: 'chelation', word_class: 'N' }
  ],
  talkative: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  educable: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  aging: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  contrite: [
    { word: 'contrite', word_class: 'AJ' },
    { word: 'contrition', word_class: 'N' },
    { word: 'contritely', word_class: 'AV' },
    { word: 'contriteness', word_class: 'N' }
  ],
  forgery: [
    { word: 'forge', word_class: 'N' },
    { word: 'forge', word_class: 'V' },
    { word: 'forger', word_class: 'N' },
    { word: 'forged', word_class: 'AJ' },
    { word: 'forgery', word_class: 'N' },
    { word: 'forging', word_class: 'N' },
    { word: 'forgery', word_class: 'AJ' }
  ],
  lathing: [
    { word: 'lath', word_class: 'N' },
    { word: 'lathe', word_class: 'N' },
    { word: 'lathe', word_class: 'V' },
    { word: 'lathing', word_class: 'N' }
  ],
  cheerleading: [
    { word: 'cheerlead', word_class: 'V' },
    { word: 'cheerleader', word_class: 'N' },
    { word: 'cheerleading', word_class: 'N' }
  ],
  relaxing: [
    { word: 'relax', word_class: 'V' },
    { word: 'relax', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'N' },
    { word: 'relaxed', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'AJ' },
    { word: 'relaxing', word_class: 'AJ' },
    { word: 'relaxation', word_class: 'N' }
  ],
  luging: [{ word: 'luge', word_class: 'N' }, { word: 'luge', word_class: 'V' }, { word: 'luging', word_class: 'N' }],
  supplicate: [
    { word: 'supplicant', word_class: 'N' },
    { word: 'supplicate', word_class: 'V' },
    { word: 'supplicant', word_class: 'AJ' },
    { word: 'supplication', word_class: 'N' }
  ],
  anodic: [
    { word: 'anode', word_class: 'N' },
    { word: 'anodal', word_class: 'AJ' },
    { word: 'anodic', word_class: 'AJ' },
    { word: 'anodize', word_class: 'V' },
    { word: 'anodization', word_class: 'N' }
  ],
  wrongfulness: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  seduction: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  internist: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  saunter: [
    { word: 'saunter', word_class: 'N' },
    { word: 'saunter', word_class: 'V' },
    { word: 'saunterer', word_class: 'N' }
  ],
  lurker: [
    { word: 'lurk', word_class: 'V' },
    { word: 'lurker', word_class: 'N' },
    { word: 'lurking', word_class: 'AJ' }
  ],
  ellipsoid: [
    { word: 'ellipsoid', word_class: 'N' },
    { word: 'ellipsoid', word_class: 'AJ' },
    { word: 'ellipsoidal', word_class: 'AJ' }
  ],
  sequined: [
    { word: 'sequin', word_class: 'N' },
    { word: 'sequin', word_class: 'V' },
    { word: 'sequined', word_class: 'AJ' },
    { word: 'sequinned', word_class: 'AJ' }
  ],
  botany: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  relentlessness: [
    { word: 'relent', word_class: 'N' },
    { word: 'relent', word_class: 'V' },
    { word: 'relentless', word_class: 'AJ' },
    { word: 'relentlessness', word_class: 'N' }
  ],
  federate: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  idler: [
    { word: 'idle', word_class: 'V' },
    { word: 'idle', word_class: 'AJ' },
    { word: 'idler', word_class: 'N' },
    { word: 'idly', word_class: 'AV' },
    { word: 'idling', word_class: 'N' },
    { word: 'idleness', word_class: 'N' }
  ],
  vaporized: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  vehemence: [
    { word: 'vehemence', word_class: 'N' },
    { word: 'vehemency', word_class: 'N' },
    { word: 'vehement', word_class: 'AJ' },
    { word: 'vehemently', word_class: 'AV' }
  ],
  phonetic: [
    { word: 'phonetic', word_class: 'AJ' },
    { word: 'phonetics', word_class: 'N' },
    { word: 'phonetically', word_class: 'AV' }
  ],
  unpredictable: [
    { word: 'unpredicted', word_class: 'AJ' },
    { word: 'unpredictive', word_class: 'AJ' },
    { word: 'unpredictable', word_class: 'AJ' },
    { word: 'unpredictably', word_class: 'AV' },
    { word: 'unpredictability', word_class: 'N' }
  ],
  subtractive: [
    { word: 'subtract', word_class: 'N' },
    { word: 'subtract', word_class: 'V' },
    { word: 'subtracter', word_class: 'N' },
    { word: 'subtracted', word_class: 'AJ' },
    { word: 'subtraction', word_class: 'N' },
    { word: 'subtractive', word_class: 'AJ' }
  ],
  heathenization: [
    { word: 'heathen', word_class: 'N' },
    { word: 'heathen', word_class: 'AJ' },
    { word: 'heathenism', word_class: 'N' },
    { word: 'heathenize', word_class: 'V' },
    { word: 'heathenization', word_class: 'N' }
  ],
  sentimentally: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  rascality: [
    { word: 'rascal', word_class: 'N' },
    { word: 'rascality', word_class: 'N' },
    { word: 'rascally', word_class: 'AJ' }
  ],
  adulterer: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  bindery: [
    { word: 'bind', word_class: 'N' },
    { word: 'bind', word_class: 'V' },
    { word: 'binder', word_class: 'N' },
    { word: 'bindery', word_class: 'N' },
    { word: 'binding', word_class: 'N' },
    { word: 'bindery', word_class: 'AJ' },
    { word: 'binding', word_class: 'AJ' }
  ],
  intertwine: [
    { word: 'intertwine', word_class: 'N' },
    { word: 'intertwine', word_class: 'V' },
    { word: 'intertwined', word_class: 'AJ' },
    { word: 'intertwinement', word_class: 'N' }
  ],
  ransack: [
    { word: 'ransack', word_class: 'V' },
    { word: 'ransacked', word_class: 'AJ' },
    { word: 'ransacking', word_class: 'N' }
  ],
  incriminate: [
    { word: 'incriminate', word_class: 'V' },
    { word: 'incrimination', word_class: 'N' },
    { word: 'incriminating', word_class: 'AJ' }
  ],
  disrespectful: [
    { word: 'disrespect', word_class: 'N' },
    { word: 'disrespect', word_class: 'V' },
    { word: 'disrespectful', word_class: 'AJ' }
  ],
  rusting: [
    { word: 'rust', word_class: 'N' },
    { word: 'rust', word_class: 'V' },
    { word: 'rust', word_class: 'AJ' },
    { word: 'rusted', word_class: 'AJ' },
    { word: 'rusting', word_class: 'N' }
  ],
  shelving: [
    { word: 'shelf', word_class: 'N' },
    { word: 'shelve', word_class: 'V' },
    { word: 'shelfful', word_class: 'N' },
    { word: 'shelving', word_class: 'N' },
    { word: 'shelfful', word_class: 'AJ' }
  ],
  extermination: [
    { word: 'exterminate', word_class: 'V' },
    { word: 'exterminator', word_class: 'N' },
    { word: 'exterminable', word_class: 'AJ' },
    { word: 'exterminated', word_class: 'AJ' },
    { word: 'extermination', word_class: 'N' }
  ],
  analyzation: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  bat: [
    { word: 'bat', word_class: 'N' },
    { word: 'bat', word_class: 'V' },
    { word: 'bats', word_class: 'AJ' },
    { word: 'batman', word_class: 'N' },
    { word: 'batting', word_class: 'N' }
  ],
  sensitization: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  hoard: [
    { word: 'hoard', word_class: 'N' },
    { word: 'hoard', word_class: 'V' },
    { word: 'hoarder', word_class: 'N' },
    { word: 'hoarding', word_class: 'N' }
  ],
  festive: [
    { word: 'festival', word_class: 'N' },
    { word: 'festive', word_class: 'AJ' },
    { word: 'festivity', word_class: 'N' }
  ],
  medial: [
    { word: 'medial', word_class: 'N' },
    { word: 'medial', word_class: 'AJ' },
    { word: 'medially', word_class: 'AV' }
  ],
  profoundness: [
    { word: 'profound', word_class: 'V' },
    { word: 'profound', word_class: 'AJ' },
    { word: 'profundity', word_class: 'N' },
    { word: 'profoundness', word_class: 'N' }
  ],
  hyposensitize: [
    { word: 'hyposensitise', word_class: 'V' },
    { word: 'hyposensitize', word_class: 'V' },
    { word: 'hyposensitisation', word_class: 'N' },
    { word: 'hyposensitization', word_class: 'N' }
  ],
  pervasively: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  exceptional: [
    { word: 'except', word_class: 'V' },
    { word: 'exception', word_class: 'N' },
    { word: 'exceptional', word_class: 'AJ' },
    { word: 'exceptionable', word_class: 'AJ' },
    { word: 'exceptionally', word_class: 'AV' }
  ],
  untruth: [
    { word: 'untruth', word_class: 'N' },
    { word: 'untruthful', word_class: 'AJ' },
    { word: 'untruthfulness', word_class: 'N' }
  ],
  forcefulness: [
    { word: 'force', word_class: 'N' },
    { word: 'force', word_class: 'V' },
    { word: 'forced', word_class: 'AJ' },
    { word: 'forceful', word_class: 'AJ' },
    { word: 'forcefulness', word_class: 'N' }
  ],
  wandering: [
    { word: 'wander', word_class: 'N' },
    { word: 'wander', word_class: 'V' },
    { word: 'wanderer', word_class: 'N' },
    { word: 'wandering', word_class: 'N' },
    { word: 'wandering', word_class: 'AJ' }
  ],
  coincidence: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  paragrapher: [
    { word: 'paragraph', word_class: 'N' },
    { word: 'paragraph', word_class: 'V' },
    { word: 'paragrapher', word_class: 'N' }
  ],
  archive: [
    { word: 'archive', word_class: 'N' },
    { word: 'archive', word_class: 'V' },
    { word: 'archive', word_class: 'AJ' },
    { word: 'archives', word_class: 'N' },
    { word: 'archival', word_class: 'AJ' }
  ],
  illusive: [
    { word: 'illude', word_class: 'V' },
    { word: 'illusion', word_class: 'N' },
    { word: 'illusive', word_class: 'AJ' },
    { word: 'illusionary', word_class: 'AJ' }
  ],
  plowing: [
    { word: 'plow', word_class: 'N' },
    { word: 'plow', word_class: 'V' },
    { word: 'plowed', word_class: 'AJ' },
    { word: 'plowing', word_class: 'N' }
  ],
  brevity: [
    { word: 'brief', word_class: 'N' },
    { word: 'brief', word_class: 'V' },
    { word: 'brief', word_class: 'AJ' },
    { word: 'briefs', word_class: 'N' },
    { word: 'brevity', word_class: 'N' },
    { word: 'briefing', word_class: 'N' },
    { word: 'briefness', word_class: 'N' }
  ],
  tortuous: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  state: [
    { word: 'state', word_class: 'N' },
    { word: 'state', word_class: 'V' },
    { word: 'state', word_class: 'AJ' },
    { word: 'stator', word_class: 'N' },
    { word: 'stated', word_class: 'AJ' },
    { word: 'stately', word_class: 'AJ' },
    { word: 'statement', word_class: 'N' }
  ],
  extemporize: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  secularise: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  blockaded: [
    { word: 'blockade', word_class: 'N' },
    { word: 'blockade', word_class: 'V' },
    { word: 'blockaded', word_class: 'AJ' },
    { word: 'blockading', word_class: 'AJ' }
  ],
  moralize: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  tackle: [
    { word: 'tackle', word_class: 'N' },
    { word: 'tackle', word_class: 'V' },
    { word: 'tackler', word_class: 'N' },
    { word: 'tackling', word_class: 'N' }
  ],
  viably: [
    { word: 'viable', word_class: 'AJ' },
    { word: 'viably', word_class: 'AV' },
    { word: 'viability', word_class: 'N' }
  ],
  acceptive: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  aggrandisement: [
    { word: 'aggrandise', word_class: 'V' },
    { word: 'aggrandize', word_class: 'V' },
    { word: 'aggrandisement', word_class: 'N' },
    { word: 'aggrandizement', word_class: 'N' }
  ],
  changeability: [
    { word: 'changeable', word_class: 'AJ' },
    { word: 'changeability', word_class: 'N' },
    { word: 'changeableness', word_class: 'N' }
  ],
  squeeze: [
    { word: 'squeeze', word_class: 'N' },
    { word: 'squeeze', word_class: 'V' },
    { word: 'squeezer', word_class: 'N' }
  ],
  patriotically: [
    { word: 'patriot', word_class: 'N' },
    { word: 'patriotic', word_class: 'AJ' },
    { word: 'patriotism', word_class: 'N' },
    { word: 'patriotically', word_class: 'AV' }
  ],
  kicking: [
    { word: 'kick', word_class: 'N' },
    { word: 'kick', word_class: 'V' },
    { word: 'kicker', word_class: 'N' },
    { word: 'kicking', word_class: 'N' }
  ],
  navigability: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  propagation: [
    { word: 'propagate', word_class: 'V' },
    { word: 'propagator', word_class: 'N' },
    { word: 'propagation', word_class: 'N' }
  ],
  railing: [
    { word: 'rail', word_class: 'N' },
    { word: 'rail', word_class: 'V' },
    { word: 'rails', word_class: 'N' },
    { word: 'railing', word_class: 'N' }
  ],
  immortalize: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  embroider: [
    { word: 'embroider', word_class: 'V' },
    { word: 'embroidery', word_class: 'N' },
    { word: 'embroiderer', word_class: 'N' },
    { word: 'embroidered', word_class: 'AJ' }
  ],
  despotism: [
    { word: 'despot', word_class: 'N' },
    { word: 'despot', word_class: 'V' },
    { word: 'despotic', word_class: 'AJ' },
    { word: 'despotism', word_class: 'N' },
    { word: 'despotical', word_class: 'AJ' }
  ],
  occidentalize: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  itemise: [
    { word: 'item', word_class: 'N' },
    { word: 'item', word_class: 'AV' },
    { word: 'itemise', word_class: 'V' },
    { word: 'itemize', word_class: 'V' },
    { word: 'itemisation', word_class: 'N' },
    { word: 'itemization', word_class: 'N' }
  ],
  valuer: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  tribunal: [
    { word: 'tribune', word_class: 'N' },
    { word: 'tribunal', word_class: 'N' },
    { word: 'tribunal', word_class: 'AJ' }
  ],
  whimsical: [
    { word: 'whimsy', word_class: 'N' },
    { word: 'whimsical', word_class: 'AJ' },
    { word: 'whimsicality', word_class: 'N' },
    { word: 'whimsically', word_class: 'AV' }
  ],
  signaling: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  uniquely: [
    { word: 'unique', word_class: 'AJ' },
    { word: 'uniquely', word_class: 'AV' },
    { word: 'uniqueness', word_class: 'N' }
  ],
  blackness: [
    { word: 'black', word_class: 'V' },
    { word: 'black', word_class: 'N' },
    { word: 'black', word_class: 'AJ' },
    { word: 'blacking', word_class: 'N' },
    { word: 'blackness', word_class: 'N' }
  ],
  derate: [
    { word: 'derat', word_class: 'V' },
    { word: 'derate', word_class: 'N' },
    { word: 'derate', word_class: 'V' }
  ],
  heavy: [
    { word: 'heavy', word_class: 'N' },
    { word: 'heavy', word_class: 'AJ' },
    { word: 'heavy', word_class: 'AV' },
    { word: 'heaviness', word_class: 'N' }
  ],
  shout: [
    { word: 'shout', word_class: 'N' },
    { word: 'shout', word_class: 'V' },
    { word: 'shouter', word_class: 'N' },
    { word: 'shouted', word_class: 'AJ' },
    { word: 'shouting', word_class: 'N' },
    { word: 'shouting', word_class: 'AJ' }
  ],
  instructively: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  apocalypse: [
    { word: 'apocalypse', word_class: 'N' },
    { word: 'apocalyptic', word_class: 'AJ' },
    { word: 'apocalyptical', word_class: 'AJ' }
  ],
  disproof: [
    { word: 'disproof', word_class: 'N' },
    { word: 'disprove', word_class: 'V' },
    { word: 'disproval', word_class: 'N' },
    { word: 'disproving', word_class: 'N' }
  ],
  tooth: [
    { word: 'tooth', word_class: 'N' },
    { word: 'toothed', word_class: 'AJ' },
    { word: 'toothsome', word_class: 'AJ' },
    { word: 'toothsomeness', word_class: 'N' }
  ],
  peck: [{ word: 'peck', word_class: 'N' }, { word: 'peck', word_class: 'V' }, { word: 'pecker', word_class: 'N' }],
  anionic: [
    { word: 'anion', word_class: 'N' },
    { word: 'anionic', word_class: 'N' },
    { word: 'anionic', word_class: 'AJ' }
  ],
  loved: [
    { word: 'love', word_class: 'N' },
    { word: 'love', word_class: 'V' },
    { word: 'lover', word_class: 'N' },
    { word: 'loved', word_class: 'AJ' },
    { word: 'lovely', word_class: 'N' },
    { word: 'lovely', word_class: 'AJ' },
    { word: 'loving', word_class: 'AJ' }
  ],
  gradual: [
    { word: 'gradual', word_class: 'N' },
    { word: 'gradual', word_class: 'AJ' },
    { word: 'graduality', word_class: 'N' },
    { word: 'gradually', word_class: 'AV' },
    { word: 'gradualness', word_class: 'N' }
  ],
  alphabetical: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  heckling: [
    { word: 'heckle', word_class: 'V' },
    { word: 'heckler', word_class: 'N' },
    { word: 'heckling', word_class: 'N' }
  ],
  obliqueness: [
    { word: 'oblique', word_class: 'N' },
    { word: 'oblique', word_class: 'AJ' },
    { word: 'obliquity', word_class: 'N' },
    { word: 'obliquely', word_class: 'AV' },
    { word: 'obliqueness', word_class: 'N' }
  ],
  internment: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  reverberate: [
    { word: 'reverberate', word_class: 'V' },
    { word: 'reverberance', word_class: 'N' },
    { word: 'reverberant', word_class: 'AJ' },
    { word: 'reverberation', word_class: 'N' },
    { word: 'reverberating', word_class: 'AJ' }
  ],
  correctness: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  coach: [
    { word: 'coach', word_class: 'N' },
    { word: 'coach', word_class: 'V' },
    { word: 'coaching', word_class: 'N' },
    { word: 'coachman', word_class: 'N' }
  ],
  domesticize: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  burdened: [
    { word: 'burden', word_class: 'N' },
    { word: 'burden', word_class: 'V' },
    { word: 'burdened', word_class: 'AJ' },
    { word: 'burdensome', word_class: 'AJ' },
    { word: 'burdensomeness', word_class: 'N' }
  ],
  auditor: [
    { word: 'audit', word_class: 'N' },
    { word: 'audit', word_class: 'V' },
    { word: 'auditor', word_class: 'N' },
    { word: 'audition', word_class: 'N' },
    { word: 'audition', word_class: 'V' },
    { word: 'auditive', word_class: 'AJ' }
  ],
  initialise: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  listening: [
    { word: 'listen', word_class: 'V' },
    { word: 'listener', word_class: 'N' },
    { word: 'listening', word_class: 'N' },
    { word: 'listening', word_class: 'AJ' }
  ],
  aggravated: [
    { word: 'aggravate', word_class: 'V' },
    { word: 'aggravated', word_class: 'AJ' },
    { word: 'aggravation', word_class: 'N' },
    { word: 'aggravating', word_class: 'AJ' }
  ],
  declassified: [
    { word: 'declassify', word_class: 'V' },
    { word: 'declassified', word_class: 'AJ' },
    { word: 'declassification', word_class: 'N' }
  ],
  metamorphic: [
    { word: 'metamorphic', word_class: 'AJ' },
    { word: 'metamorphism', word_class: 'N' },
    { word: 'metamorphous', word_class: 'AJ' }
  ],
  oxygen: [
    { word: 'oxygen', word_class: 'N' },
    { word: 'oxygenate', word_class: 'V' },
    { word: 'oxygenize', word_class: 'V' },
    { word: 'oxygenated', word_class: 'AJ' },
    { word: 'oxygenation', word_class: 'N' }
  ],
  lounge: [
    { word: 'lounge', word_class: 'N' },
    { word: 'lounge', word_class: 'V' },
    { word: 'lounger', word_class: 'N' },
    { word: 'lounging', word_class: 'AJ' }
  ],
  shuffler: [
    { word: 'shuffle', word_class: 'N' },
    { word: 'shuffle', word_class: 'V' },
    { word: 'shuffler', word_class: 'N' },
    { word: 'shuffling', word_class: 'N' },
    { word: 'shuffling', word_class: 'AJ' }
  ],
  aristocrat: [
    { word: 'aristocrat', word_class: 'N' },
    { word: 'aristocracy', word_class: 'N' },
    { word: 'aristocratic', word_class: 'AJ' },
    { word: 'aristocratical', word_class: 'AJ' },
    { word: 'aristocratically', word_class: 'AV' }
  ],
  venomous: [
    { word: 'venom', word_class: 'N' },
    { word: 'venomed', word_class: 'AJ' },
    { word: 'venomous', word_class: 'AJ' },
    { word: 'venomously', word_class: 'AV' }
  ],
  renewing: [
    { word: 'renew', word_class: 'N' },
    { word: 'renew', word_class: 'V' },
    { word: 'renew', word_class: 'AJ' },
    { word: 'renewal', word_class: 'N' },
    { word: 'renewal', word_class: 'V' },
    { word: 'renewed', word_class: 'AJ' },
    { word: 'renewing', word_class: 'AJ' },
    { word: 'renewable', word_class: 'AJ' }
  ],
  corpulence: [
    { word: 'corpulence', word_class: 'N' },
    { word: 'corpulency', word_class: 'N' },
    { word: 'corpulent', word_class: 'AJ' }
  ],
  photosensitisation: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  ascribable: [
    { word: 'ascribe', word_class: 'V' },
    { word: 'ascription', word_class: 'N' },
    { word: 'ascribable', word_class: 'AJ' }
  ],
  tight: [
    { word: 'tight', word_class: 'AJ' },
    { word: 'tight', word_class: 'AV' },
    { word: 'tights', word_class: 'N' },
    { word: 'tightness', word_class: 'N' }
  ],
  syntactically: [
    { word: 'syntax', word_class: 'N' },
    { word: 'syntactic', word_class: 'AJ' },
    { word: 'syntactical', word_class: 'AJ' },
    { word: 'syntactically', word_class: 'AV' }
  ],
  clip: [
    { word: 'clip', word_class: 'N' },
    { word: 'clip', word_class: 'V' },
    { word: 'clipped', word_class: 'AJ' },
    { word: 'clipping', word_class: 'N' }
  ],
  blackened: [
    { word: 'blacken', word_class: 'V' },
    { word: 'blackened', word_class: 'AJ' },
    { word: 'blackening', word_class: 'N' }
  ],
  shagging: [
    { word: 'shag', word_class: 'N' },
    { word: 'shag', word_class: 'V' },
    { word: 'shagged', word_class: 'AJ' },
    { word: 'shagging', word_class: 'N' }
  ],
  writhe: [
    { word: 'writhe', word_class: 'N' },
    { word: 'writhe', word_class: 'V' },
    { word: 'writhed', word_class: 'AJ' },
    { word: 'writhing', word_class: 'AJ' }
  ],
  devoured: [
    { word: 'devour', word_class: 'V' },
    { word: 'devourer', word_class: 'N' },
    { word: 'devoured', word_class: 'AJ' },
    { word: 'devouring', word_class: 'AJ' }
  ],
  totalization: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  domestication: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  interconnect: [
    { word: 'interconnect', word_class: 'V' },
    { word: 'interconnected', word_class: 'AJ' },
    { word: 'interconnection', word_class: 'N' }
  ],
  originally: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  'part-time': [
    { word: 'part-time', word_class: 'AJ' },
    { word: 'part-time', word_class: 'AV' },
    { word: 'part-timer', word_class: 'N' }
  ],
  preoccupation: [
    { word: 'preoccupy', word_class: 'V' },
    { word: 'preoccupancy', word_class: 'N' },
    { word: 'preoccupied', word_class: 'AJ' },
    { word: 'preoccupation', word_class: 'N' }
  ],
  laver: [{ word: 'lave', word_class: 'V' }, { word: 'laver', word_class: 'N' }, { word: 'lavation', word_class: 'N' }],
  infuriated: [
    { word: 'infuriate', word_class: 'V' },
    { word: 'infuriated', word_class: 'AJ' },
    { word: 'infuriation', word_class: 'N' },
    { word: 'infuriating', word_class: 'AJ' }
  ],
  analysis: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  atomize: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  attract: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  pesterer: [
    { word: 'pester', word_class: 'V' },
    { word: 'pesterer', word_class: 'N' },
    { word: 'pestered', word_class: 'AJ' },
    { word: 'pestering', word_class: 'AJ' }
  ],
  broadcaster: [
    { word: 'broadcast', word_class: 'N' },
    { word: 'broadcast', word_class: 'V' },
    { word: 'broadcast', word_class: 'AJ' },
    { word: 'broadcast', word_class: 'AV' },
    { word: 'broadcaster', word_class: 'N' },
    { word: 'broadcasting', word_class: 'N' }
  ],
  empurple: [
    { word: 'empurple', word_class: 'V' },
    { word: 'empurpled', word_class: 'N' },
    { word: 'empurpled', word_class: 'AJ' }
  ],
  medicinal: [
    { word: 'medicine', word_class: 'N' },
    { word: 'medicine', word_class: 'V' },
    { word: 'medicinal', word_class: 'AJ' },
    { word: 'medicinally', word_class: 'AV' }
  ],
  fatalism: [
    { word: 'fatal', word_class: 'AJ' },
    { word: 'fatalism', word_class: 'N' },
    { word: 'fatality', word_class: 'N' },
    { word: 'fatally', word_class: 'AV' }
  ],
  intransitiveness: [
    { word: 'intransitive', word_class: 'AJ' },
    { word: 'intransitivity', word_class: 'N' },
    { word: 'intransitively', word_class: 'AV' },
    { word: 'intransitiveness', word_class: 'N' }
  ],
  fastness: [
    { word: 'fast', word_class: 'N' },
    { word: 'fast', word_class: 'V' },
    { word: 'fast', word_class: 'AJ' },
    { word: 'fast', word_class: 'AV' },
    { word: 'fasting', word_class: 'N' },
    { word: 'fastness', word_class: 'N' }
  ],
  narrowed: [
    { word: 'narrow', word_class: 'N' },
    { word: 'narrow', word_class: 'V' },
    { word: 'narrow', word_class: 'AJ' },
    { word: 'narrowed', word_class: 'AJ' },
    { word: 'narrowing', word_class: 'N' },
    { word: 'narrowing', word_class: 'AJ' },
    { word: 'narrowness', word_class: 'N' }
  ],
  released: [
    { word: 'release', word_class: 'N' },
    { word: 'release', word_class: 'V' },
    { word: 'released', word_class: 'AJ' },
    { word: 'releasing', word_class: 'AJ' }
  ],
  wooded: [
    { word: 'wood', word_class: 'N' },
    { word: 'wood', word_class: 'AJ' },
    { word: 'woods', word_class: 'N' },
    { word: 'woods', word_class: 'AJ' },
    { word: 'wooded', word_class: 'AJ' },
    { word: 'wooden', word_class: 'AJ' },
    { word: 'woodsy', word_class: 'AJ' }
  ],
  unfold: [
    { word: 'unfold', word_class: 'V' },
    { word: 'unfolded', word_class: 'AJ' },
    { word: 'unfolding', word_class: 'N' }
  ],
  impatiently: [
    { word: 'impatience', word_class: 'N' },
    { word: 'impatient', word_class: 'AJ' },
    { word: 'impatiently', word_class: 'AV' }
  ],
  extrapolation: [
    { word: 'extrapolate', word_class: 'V' },
    { word: 'extrapolated', word_class: 'AJ' },
    { word: 'extrapolation', word_class: 'N' }
  ],
  commission: [
    { word: 'commit', word_class: 'V' },
    { word: 'committal', word_class: 'N' },
    { word: 'commission', word_class: 'N' },
    { word: 'commitment', word_class: 'N' },
    { word: 'commission', word_class: 'V' },
    { word: 'committed', word_class: 'AJ' },
    { word: 'commissioner', word_class: 'N' },
    { word: 'commissioned', word_class: 'AJ' },
    { word: 'commissioning', word_class: 'N' }
  ],
  tutored: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  delayed: [
    { word: 'delay', word_class: 'N' },
    { word: 'delay', word_class: 'V' },
    { word: 'delayed', word_class: 'AJ' }
  ],
  polarize: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  remind: [
    { word: 'remind', word_class: 'N' },
    { word: 'remind', word_class: 'V' },
    { word: 'reminder', word_class: 'N' },
    { word: 'remindful', word_class: 'AJ' }
  ],
  measured: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  fundamentals: [
    { word: 'fundament', word_class: 'N' },
    { word: 'fundamental', word_class: 'N' },
    { word: 'fundamental', word_class: 'AJ' },
    { word: 'fundamentals', word_class: 'N' },
    { word: 'fundamentalism', word_class: 'N' },
    { word: 'fundamentally', word_class: 'AV' }
  ],
  maker: [
    { word: 'make', word_class: 'N' },
    { word: 'make', word_class: 'V' },
    { word: 'maker', word_class: 'N' },
    { word: 'making', word_class: 'N' }
  ],
  axon: [{ word: 'axon', word_class: 'N' }, { word: 'axone', word_class: 'N' }, { word: 'axonal', word_class: 'AJ' }],
  professed: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  pluralisation: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  womanization: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  gutturally: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  rhetoric: [
    { word: 'rhetoric', word_class: 'N' },
    { word: 'rhetorical', word_class: 'AJ' },
    { word: 'rhetorically', word_class: 'AV' }
  ],
  tropics: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  devourer: [
    { word: 'devour', word_class: 'V' },
    { word: 'devourer', word_class: 'N' },
    { word: 'devoured', word_class: 'AJ' },
    { word: 'devouring', word_class: 'AJ' }
  ],
  digs: [
    { word: 'dig', word_class: 'N' },
    { word: 'dig', word_class: 'V' },
    { word: 'digs', word_class: 'N' },
    { word: 'digging', word_class: 'N' },
    { word: 'diggings', word_class: 'N' }
  ],
  methodisation: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  raider: [
    { word: 'raid', word_class: 'N' },
    { word: 'raid', word_class: 'V' },
    { word: 'raider', word_class: 'N' },
    { word: 'raiding', word_class: 'AJ' }
  ],
  method: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  conveying: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  consummate: [
    { word: 'consummate', word_class: 'V' },
    { word: 'consummate', word_class: 'AJ' },
    { word: 'consummated', word_class: 'AJ' },
    { word: 'consummation', word_class: 'N' }
  ],
  redistributed: [
    { word: 'redistribute', word_class: 'V' },
    { word: 'redistributed', word_class: 'AJ' },
    { word: 'redistribution', word_class: 'N' }
  ],
  sealant: [
    { word: 'seal', word_class: 'N' },
    { word: 'seal', word_class: 'V' },
    { word: 'sealer', word_class: 'N' },
    { word: 'sealant', word_class: 'N' },
    { word: 'sealed', word_class: 'AJ' },
    { word: 'sealing', word_class: 'N' }
  ],
  graph: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  cubistic: [
    { word: 'cube', word_class: 'N' },
    { word: 'cube', word_class: 'V' },
    { word: 'cubism', word_class: 'N' },
    { word: 'cubist', word_class: 'N' },
    { word: 'cubist', word_class: 'AJ' },
    { word: 'cubistic', word_class: 'AJ' }
  ],
  pronominalisation: [
    { word: 'pronominal', word_class: 'AJ' },
    { word: 'pronominalise', word_class: 'V' },
    { word: 'pronominalize', word_class: 'V' },
    { word: 'pronominalisation', word_class: 'N' },
    { word: 'pronominalization', word_class: 'N' }
  ],
  advertised: [
    { word: 'advertise', word_class: 'V' },
    { word: 'advertiser', word_class: 'N' },
    { word: 'advertised', word_class: 'AJ' },
    { word: 'advertising', word_class: 'N' },
    { word: 'advertisement', word_class: 'N' }
  ],
  clamour: [
    { word: 'clamour', word_class: 'N' },
    { word: 'clamour', word_class: 'V' },
    { word: 'clamouring', word_class: 'N' },
    { word: 'clamourous', word_class: 'AJ' }
  ],
  approved: [
    { word: 'approve', word_class: 'V' },
    { word: 'approval', word_class: 'N' },
    { word: 'approved', word_class: 'AJ' },
    { word: 'approving', word_class: 'N' },
    { word: 'approving', word_class: 'AJ' }
  ],
  confliction: [
    { word: 'conflict', word_class: 'N' },
    { word: 'conflict', word_class: 'V' },
    { word: 'confliction', word_class: 'N' },
    { word: 'conflicting', word_class: 'AJ' }
  ],
  lumberman: [
    { word: 'lumber', word_class: 'N' },
    { word: 'lumber', word_class: 'V' },
    { word: 'lumberman', word_class: 'N' },
    { word: 'lumbering', word_class: 'AJ' }
  ],
  cheerlead: [
    { word: 'cheerlead', word_class: 'V' },
    { word: 'cheerleader', word_class: 'N' },
    { word: 'cheerleading', word_class: 'N' }
  ],
  add: [
    { word: 'add', word_class: 'V' },
    { word: 'adder', word_class: 'N' },
    { word: 'addition', word_class: 'N' },
    { word: 'additive', word_class: 'N' },
    { word: 'additive', word_class: 'AJ' },
    { word: 'additional', word_class: 'AJ' }
  ],
  refractoriness: [
    { word: 'refractory', word_class: 'N' },
    { word: 'refractory', word_class: 'AJ' },
    { word: 'refractoriness', word_class: 'N' }
  ],
  rolling: [
    { word: 'roll', word_class: 'N' },
    { word: 'roll', word_class: 'V' },
    { word: 'roller', word_class: 'N' },
    { word: 'rolled', word_class: 'AJ' },
    { word: 'rolling', word_class: 'N' },
    { word: 'rolling', word_class: 'AJ' }
  ],
  androgen: [
    { word: 'androgen', word_class: 'N' },
    { word: 'androgenic', word_class: 'AJ' },
    { word: 'androgenous', word_class: 'AJ' }
  ],
  vaulting: [
    { word: 'vault', word_class: 'N' },
    { word: 'vault', word_class: 'V' },
    { word: 'vaulter', word_class: 'N' },
    { word: 'vaulted', word_class: 'AJ' },
    { word: 'vaulting', word_class: 'N' },
    { word: 'vaulting', word_class: 'AJ' }
  ],
  indolent: [
    { word: 'indolence', word_class: 'N' },
    { word: 'indolent', word_class: 'AJ' },
    { word: 'indolently', word_class: 'AV' }
  ],
  fortified: [
    { word: 'fortify', word_class: 'V' },
    { word: 'fortified', word_class: 'AJ' },
    { word: 'fortifying', word_class: 'AJ' },
    { word: 'fortification', word_class: 'N' }
  ],
  tenderizer: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  bluster: [
    { word: 'bluster', word_class: 'N' },
    { word: 'bluster', word_class: 'V' },
    { word: 'blusterer', word_class: 'N' },
    { word: 'blustering', word_class: 'AJ' },
    { word: 'blusterous', word_class: 'AJ' }
  ],
  impenitency: [
    { word: 'impenitence', word_class: 'N' },
    { word: 'impenitency', word_class: 'N' },
    { word: 'impenitent', word_class: 'AJ' },
    { word: 'impenitently', word_class: 'AV' }
  ],
  transmutable: [
    { word: 'transmute', word_class: 'V' },
    { word: 'transmute', word_class: 'AJ' },
    { word: 'transmutable', word_class: 'AJ' },
    { word: 'transmutation', word_class: 'N' },
    { word: 'transmutability', word_class: 'N' }
  ],
  conjunctive: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  expurgation: [
    { word: 'expurgate', word_class: 'V' },
    { word: 'expurgated', word_class: 'AJ' },
    { word: 'expurgation', word_class: 'N' }
  ],
  inveteracy: [
    { word: 'inveteracy', word_class: 'N' },
    { word: 'inveterate', word_class: 'AJ' },
    { word: 'inveterately', word_class: 'AV' }
  ],
  drudgery: [
    { word: 'drudge', word_class: 'N' },
    { word: 'drudge', word_class: 'V' },
    { word: 'drudgery', word_class: 'N' },
    { word: 'drudging', word_class: 'AJ' }
  ],
  profitable: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  'self-respecting': [
    { word: 'self-respect', word_class: 'N' },
    { word: 'self-respectful', word_class: 'AJ' },
    { word: 'self-respecting', word_class: 'AJ' }
  ],
  workings: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  augmentative: [
    { word: 'augment', word_class: 'V' },
    { word: 'augmented', word_class: 'AJ' },
    { word: 'augmenting', word_class: 'AJ' },
    { word: 'augmentation', word_class: 'N' },
    { word: 'augmentative', word_class: 'AJ' }
  ],
  dehypnotize: [
    { word: 'dehypnotise', word_class: 'V' },
    { word: 'dehypnotize', word_class: 'V' },
    { word: 'dehypnotisation', word_class: 'N' },
    { word: 'dehypnotization', word_class: 'N' }
  ],
  fluidize: [
    { word: 'fluidize', word_class: 'V' },
    { word: 'fluidisation', word_class: 'N' },
    { word: 'fluidization', word_class: 'N' }
  ],
  legitimatisation: [
    { word: 'legitimatise', word_class: 'V' },
    { word: 'legitimatize', word_class: 'V' },
    { word: 'legitimisation', word_class: 'N' },
    { word: 'legitimatisation', word_class: 'N' },
    { word: 'legitimatization', word_class: 'N' }
  ],
  stately: [
    { word: 'state', word_class: 'N' },
    { word: 'state', word_class: 'V' },
    { word: 'state', word_class: 'AJ' },
    { word: 'stator', word_class: 'N' },
    { word: 'stated', word_class: 'AJ' },
    { word: 'stately', word_class: 'AJ' },
    { word: 'statement', word_class: 'N' }
  ],
  tritely: [
    { word: 'trite', word_class: 'AJ' },
    { word: 'tritely', word_class: 'AV' },
    { word: 'triteness', word_class: 'N' }
  ],
  tendentiousness: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  induction: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  jerkiness: [
    { word: 'jerk', word_class: 'N' },
    { word: 'jerk', word_class: 'V' },
    { word: 'jerky', word_class: 'N' },
    { word: 'jerky', word_class: 'AJ' },
    { word: 'jerking', word_class: 'N' },
    { word: 'jerking', word_class: 'AJ' },
    { word: 'jerkiness', word_class: 'N' }
  ],
  mimer: [{ word: 'mime', word_class: 'N' }, { word: 'mime', word_class: 'V' }, { word: 'mimer', word_class: 'N' }],
  haul: [
    { word: 'haul', word_class: 'N' },
    { word: 'haul', word_class: 'V' },
    { word: 'hauler', word_class: 'N' },
    { word: 'haulage', word_class: 'N' },
    { word: 'hauling', word_class: 'N' }
  ],
  feed: [
    { word: 'feed', word_class: 'N' },
    { word: 'feed', word_class: 'V' },
    { word: 'feeder', word_class: 'N' },
    { word: 'feeding', word_class: 'N' }
  ],
  sneezy: [
    { word: 'sneeze', word_class: 'N' },
    { word: 'sneeze', word_class: 'V' },
    { word: 'sneezy', word_class: 'AJ' },
    { word: 'sneezing', word_class: 'N' }
  ],
  repellent: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  patented: [
    { word: 'patent', word_class: 'N' },
    { word: 'patent', word_class: 'V' },
    { word: 'patent', word_class: 'AJ' },
    { word: 'patentee', word_class: 'N' },
    { word: 'patented', word_class: 'AJ' },
    { word: 'patently', word_class: 'AV' }
  ],
  subbing: [{ word: 'sub', word_class: 'N' }, { word: 'sub', word_class: 'V' }, { word: 'subbing', word_class: 'N' }],
  collecting: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  substitutable: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  extinct: [
    { word: 'extinct', word_class: 'V' },
    { word: 'extinct', word_class: 'AJ' },
    { word: 'extinction', word_class: 'N' }
  ],
  echo: [{ word: 'echo', word_class: 'N' }, { word: 'echo', word_class: 'V' }, { word: 'echoing', word_class: 'AJ' }],
  fizz: [{ word: 'fizz', word_class: 'N' }, { word: 'fizz', word_class: 'V' }, { word: 'fizzing', word_class: 'AJ' }],
  judges: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  eternisation: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  simulator: [
    { word: 'simulate', word_class: 'V' },
    { word: 'simulator', word_class: 'N' },
    { word: 'simulated', word_class: 'AJ' },
    { word: 'simulation', word_class: 'N' }
  ],
  balkanise: [
    { word: 'balkans', word_class: 'N' },
    { word: 'balkanise', word_class: 'V' },
    { word: 'balkanize', word_class: 'V' },
    { word: 'balkanisation', word_class: 'N' },
    { word: 'balkanization', word_class: 'N' }
  ],
  wipe: [{ word: 'wipe', word_class: 'N' }, { word: 'wipe', word_class: 'V' }, { word: 'wiper', word_class: 'N' }],
  secreting: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  infected: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  seated: [
    { word: 'seat', word_class: 'N' },
    { word: 'seat', word_class: 'V' },
    { word: 'seats', word_class: 'N' },
    { word: 'seated', word_class: 'AJ' },
    { word: 'seating', word_class: 'N' }
  ],
  decrepitate: [
    { word: 'decrepit', word_class: 'AJ' },
    { word: 'decrepitate', word_class: 'V' },
    { word: 'decrepitation', word_class: 'N' }
  ],
  grit: [{ word: 'grit', word_class: 'N' }, { word: 'grit', word_class: 'V' }, { word: 'grits', word_class: 'N' }],
  spreader: [
    { word: 'spread', word_class: 'N' },
    { word: 'spread', word_class: 'V' },
    { word: 'spread', word_class: 'AJ' },
    { word: 'spreader', word_class: 'N' },
    { word: 'spreading', word_class: 'N' },
    { word: 'spreading', word_class: 'AJ' }
  ],
  concentrate: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  alarmist: [
    { word: 'alarm', word_class: 'N' },
    { word: 'alarm', word_class: 'V' },
    { word: 'alarmism', word_class: 'N' },
    { word: 'alarmist', word_class: 'N' },
    { word: 'alarmed', word_class: 'AJ' },
    { word: 'alarming', word_class: 'AJ' }
  ],
  plunderer: [
    { word: 'plunder', word_class: 'N' },
    { word: 'plunder', word_class: 'V' },
    { word: 'plunderer', word_class: 'N' },
    { word: 'plunderage', word_class: 'N' },
    { word: 'plundered', word_class: 'AJ' },
    { word: 'plundering', word_class: 'N' },
    { word: 'plundering', word_class: 'AJ' }
  ],
  determinable: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  reproductive: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  destructive: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  educated: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  scruple: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  cod: [
    { word: 'cod', word_class: 'N' },
    { word: 'cod', word_class: 'V' },
    { word: 'cod', word_class: 'AJ' },
    { word: 'cod', word_class: 'AV' }
  ],
  arrive: [
    { word: 'arrive', word_class: 'V' },
    { word: 'arrival', word_class: 'N' },
    { word: 'arriver', word_class: 'N' },
    { word: 'arriving', word_class: 'AJ' }
  ],
  extravagation: [
    { word: 'extravagate', word_class: 'V' },
    { word: 'extravagance', word_class: 'N' },
    { word: 'extravagant', word_class: 'AJ' },
    { word: 'extravagation', word_class: 'N' }
  ],
  immobilisation: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  unlikeness: [
    { word: 'unlike', word_class: 'N' },
    { word: 'unlike', word_class: 'V' },
    { word: 'unlike', word_class: 'AJ' },
    { word: 'unlikely', word_class: 'AJ' },
    { word: 'unlikable', word_class: 'AJ' },
    { word: 'unlikeness', word_class: 'N' },
    { word: 'unlikeable', word_class: 'AJ' }
  ],
  recreate: [
    { word: 'recreate', word_class: 'V' },
    { word: 'recreation', word_class: 'N' },
    { word: 'recreational', word_class: 'AJ' }
  ],
  seraphical: [
    { word: 'seraph', word_class: 'N' },
    { word: 'seraphic', word_class: 'AJ' },
    { word: 'seraphical', word_class: 'AJ' }
  ],
  abruptness: [
    { word: 'abrupt', word_class: 'AJ' },
    { word: 'abruption', word_class: 'N' },
    { word: 'abruptness', word_class: 'N' }
  ],
  voter: [
    { word: 'vote', word_class: 'N' },
    { word: 'vote', word_class: 'V' },
    { word: 'voter', word_class: 'N' },
    { word: 'voting', word_class: 'N' }
  ],
  reportable: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  billion: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  cruiser: [
    { word: 'cruise', word_class: 'N' },
    { word: 'cruise', word_class: 'V' },
    { word: 'cruiser', word_class: 'N' }
  ],
  blond: [
    { word: 'blond', word_class: 'N' },
    { word: 'blond', word_class: 'AJ' },
    { word: 'blonde', word_class: 'N' },
    { word: 'blonde', word_class: 'AJ' },
    { word: 'blondness', word_class: 'N' }
  ],
  archaeologic: [
    { word: 'archaeology', word_class: 'N' },
    { word: 'archaeologic', word_class: 'AJ' },
    { word: 'archaeological', word_class: 'AJ' }
  ],
  festival: [
    { word: 'festival', word_class: 'N' },
    { word: 'festive', word_class: 'AJ' },
    { word: 'festivity', word_class: 'N' }
  ],
  golfer: [
    { word: 'golf', word_class: 'N' },
    { word: 'golf', word_class: 'V' },
    { word: 'golfer', word_class: 'N' },
    { word: 'golfing', word_class: 'N' }
  ],
  positivity: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  regardful: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  glamourous: [
    { word: 'glamour', word_class: 'N' },
    { word: 'glamour', word_class: 'V' },
    { word: 'glamourous', word_class: 'AJ' }
  ],
  ironworks: [
    { word: 'ironwork', word_class: 'N' },
    { word: 'ironworks', word_class: 'N' },
    { word: 'ironworker', word_class: 'N' }
  ],
  icelandic: [
    { word: 'iceland', word_class: 'N' },
    { word: 'icelandic', word_class: 'N' },
    { word: 'icelander', word_class: 'N' },
    { word: 'icelandic', word_class: 'AJ' }
  ],
  etherealisation: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  anticoagulant: [
    { word: 'anticoagulant', word_class: 'N' },
    { word: 'anticoagulation', word_class: 'N' },
    { word: 'anticoagulative', word_class: 'AJ' }
  ],
  occult: [
    { word: 'occult', word_class: 'N' },
    { word: 'occult', word_class: 'V' },
    { word: 'occult', word_class: 'AJ' },
    { word: 'occultation', word_class: 'N' }
  ],
  lowland: [
    { word: 'lowland', word_class: 'N' },
    { word: 'lowland', word_class: 'AJ' },
    { word: 'lowlander', word_class: 'N' }
  ],
  bearer: [
    { word: 'bear', word_class: 'N' },
    { word: 'bear', word_class: 'V' },
    { word: 'born', word_class: 'AJ' },
    { word: 'bearer', word_class: 'N' },
    { word: 'borne', word_class: 'AJ' },
    { word: 'bearing', word_class: 'N' },
    { word: 'bearing', word_class: 'AJ' }
  ],
  dominant: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  assumptive: [
    { word: 'assume', word_class: 'V' },
    { word: 'assumed', word_class: 'AJ' },
    { word: 'assuming', word_class: 'AJ' },
    { word: 'assumption', word_class: 'N' },
    { word: 'assumptive', word_class: 'AJ' }
  ],
  discernment: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  grandness: [
    { word: 'grand', word_class: 'N' },
    { word: 'grand', word_class: 'AJ' },
    { word: 'grandness', word_class: 'N' }
  ],
  emendation: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  campaigning: [
    { word: 'campaign', word_class: 'N' },
    { word: 'campaign', word_class: 'V' },
    { word: 'campaigner', word_class: 'N' },
    { word: 'campaigning', word_class: 'N' }
  ],
  absently: [
    { word: 'absent', word_class: 'V' },
    { word: 'absence', word_class: 'N' },
    { word: 'absent', word_class: 'AJ' },
    { word: 'absentee', word_class: 'N' },
    { word: 'absently', word_class: 'AV' },
    { word: 'absenteeism', word_class: 'N' }
  ],
  smashed: [
    { word: 'smash', word_class: 'N' },
    { word: 'smash', word_class: 'V' },
    { word: 'smash', word_class: 'AV' },
    { word: 'smasher', word_class: 'N' },
    { word: 'smashed', word_class: 'AJ' },
    { word: 'smashing', word_class: 'N' },
    { word: 'smashing', word_class: 'AJ' }
  ],
  encumbrance: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  diffidently: [
    { word: 'diffidence', word_class: 'N' },
    { word: 'diffident', word_class: 'AJ' },
    { word: 'diffidently', word_class: 'AV' }
  ],
  eloquently: [
    { word: 'eloquence', word_class: 'N' },
    { word: 'eloquent', word_class: 'AJ' },
    { word: 'eloquently', word_class: 'AV' }
  ],
  thankless: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  catholic: [
    { word: 'catholic', word_class: 'N' },
    { word: 'catholic', word_class: 'AJ' },
    { word: 'catholicity', word_class: 'N' }
  ],
  vacuole: [
    { word: 'vacuole', word_class: 'N' },
    { word: 'vacuolate', word_class: 'AJ' },
    { word: 'vacuolated', word_class: 'AJ' },
    { word: 'vacuolation', word_class: 'N' },
    { word: 'vacuolization', word_class: 'N' }
  ],
  pipeful: [
    { word: 'pipe', word_class: 'N' },
    { word: 'pipe', word_class: 'V' },
    { word: 'piper', word_class: 'N' },
    { word: 'pipes', word_class: 'N' },
    { word: 'piping', word_class: 'N' },
    { word: 'pipeful', word_class: 'N' },
    { word: 'piping', word_class: 'AJ' },
    { word: 'piping', word_class: 'AV' },
    { word: 'pipeful', word_class: 'AJ' }
  ],
  molding: [
    { word: 'mold', word_class: 'N' },
    { word: 'mold', word_class: 'V' },
    { word: 'molded', word_class: 'AJ' },
    { word: 'molding', word_class: 'N' }
  ],
  detestable: [
    { word: 'detest', word_class: 'V' },
    { word: 'detested', word_class: 'AJ' },
    { word: 'detestable', word_class: 'AJ' },
    { word: 'detestably', word_class: 'AV' },
    { word: 'detestation', word_class: 'N' }
  ],
  legislator: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  skilful: [
    { word: 'skill', word_class: 'N' },
    { word: 'skilful', word_class: 'AJ' },
    { word: 'skilled', word_class: 'AJ' },
    { word: 'skillful', word_class: 'AJ' },
    { word: 'skillfulness', word_class: 'N' }
  ],
  limitation: [
    { word: 'limit', word_class: 'N' },
    { word: 'limit', word_class: 'V' },
    { word: 'limiter', word_class: 'N' },
    { word: 'limited', word_class: 'AJ' },
    { word: 'limiting', word_class: 'N' },
    { word: 'limiting', word_class: 'AJ' },
    { word: 'limitation', word_class: 'N' }
  ],
  maliciously: [
    { word: 'malice', word_class: 'N' },
    { word: 'malice', word_class: 'V' },
    { word: 'malicious', word_class: 'AJ' },
    { word: 'maliciously', word_class: 'AV' },
    { word: 'maliciousness', word_class: 'N' }
  ],
  suave: [
    { word: 'suave', word_class: 'AJ' },
    { word: 'suavely', word_class: 'AV' },
    { word: 'suaveness', word_class: 'N' }
  ],
  pensively: [
    { word: 'pensive', word_class: 'AJ' },
    { word: 'pensively', word_class: 'AV' },
    { word: 'pensiveness', word_class: 'N' }
  ],
  squealer: [
    { word: 'squeal', word_class: 'N' },
    { word: 'squeal', word_class: 'V' },
    { word: 'squealer', word_class: 'N' },
    { word: 'squealing', word_class: 'AJ' }
  ],
  impassive: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  incorporative: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  pronounced: [
    { word: 'pronounce', word_class: 'V' },
    { word: 'pronounced', word_class: 'AJ' },
    { word: 'pronouncement', word_class: 'N' },
    { word: 'pronunciation', word_class: 'N' },
    { word: 'pronounceable', word_class: 'AJ' }
  ],
  trample: [
    { word: 'trample', word_class: 'N' },
    { word: 'trample', word_class: 'V' },
    { word: 'trampled', word_class: 'AJ' },
    { word: 'trampling', word_class: 'N' },
    { word: 'trampling', word_class: 'AJ' }
  ],
  interview: [
    { word: 'interview', word_class: 'N' },
    { word: 'interview', word_class: 'V' },
    { word: 'interviewee', word_class: 'N' },
    { word: 'interviewer', word_class: 'N' }
  ],
  mutableness: [
    { word: 'mutable', word_class: 'AJ' },
    { word: 'mutably', word_class: 'AV' },
    { word: 'mutability', word_class: 'N' },
    { word: 'mutableness', word_class: 'N' }
  ],
  reduce: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  onomatopoeia: [
    { word: 'onomatopoeia', word_class: 'N' },
    { word: 'onomatopoeic', word_class: 'AJ' },
    { word: 'onomatopoetic', word_class: 'AJ' },
    { word: 'onomatopoeical', word_class: 'AJ' }
  ],
  debilitating: [
    { word: 'debilitate', word_class: 'V' },
    { word: 'debilitated', word_class: 'AJ' },
    { word: 'debilitation', word_class: 'N' },
    { word: 'debilitating', word_class: 'AJ' },
    { word: 'debilitative', word_class: 'AJ' }
  ],
  star: [
    { word: 'star', word_class: 'N' },
    { word: 'star', word_class: 'V' },
    { word: 'star', word_class: 'AJ' },
    { word: 'starred', word_class: 'AJ' },
    { word: 'starring', word_class: 'AJ' }
  ],
  gum: [
    { word: 'gum', word_class: 'N' },
    { word: 'gum', word_class: 'V' },
    { word: 'gummed', word_class: 'AJ' },
    { word: 'gumming', word_class: 'N' }
  ],
  exhaustion: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  dependency: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  pluck: [
    { word: 'pluck', word_class: 'N' },
    { word: 'pluck', word_class: 'V' },
    { word: 'plucked', word_class: 'AJ' }
  ],
  weep: [
    { word: 'weep', word_class: 'V' },
    { word: 'weepy', word_class: 'AJ' },
    { word: 'weeper', word_class: 'N' },
    { word: 'weeping', word_class: 'N' },
    { word: 'weeping', word_class: 'AJ' },
    { word: 'weepiness', word_class: 'N' }
  ],
  averageness: [
    { word: 'average', word_class: 'N' },
    { word: 'average', word_class: 'V' },
    { word: 'average', word_class: 'AJ' },
    { word: 'averageness', word_class: 'N' }
  ],
  nominated: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  lunching: [
    { word: 'lunch', word_class: 'N' },
    { word: 'lunch', word_class: 'V' },
    { word: 'luncher', word_class: 'N' },
    { word: 'lunching', word_class: 'N' }
  ],
  fullness: [
    { word: 'full', word_class: 'V' },
    { word: 'full', word_class: 'AJ' },
    { word: 'full', word_class: 'AV' },
    { word: 'fully', word_class: 'AV' },
    { word: 'fulness', word_class: 'N' },
    { word: 'fullness', word_class: 'N' }
  ],
  flavour: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  doctrinally: [
    { word: 'doctrine', word_class: 'N' },
    { word: 'doctrinal', word_class: 'AJ' },
    { word: 'doctrinally', word_class: 'AV' }
  ],
  overact: [
    { word: 'overact', word_class: 'V' },
    { word: 'overacting', word_class: 'N' },
    { word: 'overactive', word_class: 'AJ' },
    { word: 'overactivity', word_class: 'N' }
  ],
  pelting: [{ word: 'pelt', word_class: 'N' }, { word: 'pelt', word_class: 'V' }, { word: 'pelting', word_class: 'N' }],
  monopolist: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  profligately: [
    { word: 'profligacy', word_class: 'N' },
    { word: 'profligate', word_class: 'N' },
    { word: 'profligate', word_class: 'AJ' },
    { word: 'profligately', word_class: 'AV' }
  ],
  securely: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  pockmarked: [
    { word: 'pockmark', word_class: 'N' },
    { word: 'pockmark', word_class: 'V' },
    { word: 'pockmarked', word_class: 'AJ' }
  ],
  truthful: [
    { word: 'true', word_class: 'N' },
    { word: 'true', word_class: 'V' },
    { word: 'true', word_class: 'AJ' },
    { word: 'truth', word_class: 'N' },
    { word: 'true', word_class: 'AV' },
    { word: 'truly', word_class: 'AV' },
    { word: 'truthful', word_class: 'AJ' },
    { word: 'truthfulness', word_class: 'N' }
  ],
  high: [
    { word: 'high', word_class: 'N' },
    { word: 'high', word_class: 'AJ' },
    { word: 'high', word_class: 'AV' },
    { word: 'heighten', word_class: 'V' },
    { word: 'highness', word_class: 'N' },
    { word: 'heightening', word_class: 'AJ' }
  ],
  connection: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  stabilized: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  impersonation: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  obsession: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  whitener: [
    { word: 'whiten', word_class: 'V' },
    { word: 'whitener', word_class: 'N' },
    { word: 'whitened', word_class: 'AJ' },
    { word: 'whitening', word_class: 'N' }
  ],
  insolence: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  idiotic: [
    { word: 'idiot', word_class: 'N' },
    { word: 'idiocy', word_class: 'N' },
    { word: 'idiotic', word_class: 'AJ' },
    { word: 'idiotically', word_class: 'AV' }
  ],
  typic: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  observatory: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  journey: [
    { word: 'journey', word_class: 'N' },
    { word: 'journey', word_class: 'V' },
    { word: 'journeyer', word_class: 'N' },
    { word: 'journeying', word_class: 'N' },
    { word: 'journeyman', word_class: 'N' }
  ],
  psychologization: [
    { word: 'psychology', word_class: 'N' },
    { word: 'psychologize', word_class: 'V' },
    { word: 'psychological', word_class: 'AJ' },
    { word: 'psychologically', word_class: 'AV' },
    { word: 'psychologization', word_class: 'N' }
  ],
  providently: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  inhabitancy: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  dissatisfy: [
    { word: 'dissatisfy', word_class: 'V' },
    { word: 'dissatisfied', word_class: 'AJ' },
    { word: 'dissatisfaction', word_class: 'N' }
  ],
  camphoric: [
    { word: 'camphor', word_class: 'N' },
    { word: 'camphorate', word_class: 'V' },
    { word: 'camphoric', word_class: 'AJ' },
    { word: 'camphorated', word_class: 'AJ' },
    { word: 'camphoration', word_class: 'N' }
  ],
  compounded: [
    { word: 'compound', word_class: 'N' },
    { word: 'compound', word_class: 'V' },
    { word: 'compound', word_class: 'AJ' },
    { word: 'compounded', word_class: 'AJ' },
    { word: 'compounding', word_class: 'N' }
  ],
  sprung: [
    { word: 'spring', word_class: 'N' },
    { word: 'spring', word_class: 'V' },
    { word: 'spring', word_class: 'AJ' },
    { word: 'sprung', word_class: 'AJ' },
    { word: 'springer', word_class: 'N' },
    { word: 'springing', word_class: 'AJ' }
  ],
  pulse: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  exterminable: [
    { word: 'exterminate', word_class: 'V' },
    { word: 'exterminator', word_class: 'N' },
    { word: 'exterminable', word_class: 'AJ' },
    { word: 'exterminated', word_class: 'AJ' },
    { word: 'extermination', word_class: 'N' }
  ],
  loath: [
    { word: 'loath', word_class: 'V' },
    { word: 'loath', word_class: 'AJ' },
    { word: 'loathe', word_class: 'V' },
    { word: 'loathing', word_class: 'N' },
    { word: 'loathsome', word_class: 'AJ' },
    { word: 'loathsomeness', word_class: 'N' }
  ],
  synchronized: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  irrupt: [
    { word: 'irrupt', word_class: 'V' },
    { word: 'irruption', word_class: 'N' },
    { word: 'irruptive', word_class: 'AJ' }
  ],
  waitress: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  apologetic: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  ampleness: [
    { word: 'ample', word_class: 'AJ' },
    { word: 'amply', word_class: 'AJ' },
    { word: 'amply', word_class: 'AV' },
    { word: 'ampleness', word_class: 'N' }
  ],
  peaked: [
    { word: 'peak', word_class: 'N' },
    { word: 'peak', word_class: 'V' },
    { word: 'peak', word_class: 'AJ' },
    { word: 'peaked', word_class: 'AJ' }
  ],
  backspacer: [
    { word: 'backspace', word_class: 'N' },
    { word: 'backspace', word_class: 'V' },
    { word: 'backspacer', word_class: 'N' }
  ],
  population: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  cancel: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  detergent: [
    { word: 'detergent', word_class: 'N' },
    { word: 'detergence', word_class: 'N' },
    { word: 'detergency', word_class: 'N' },
    { word: 'detergent', word_class: 'AJ' }
  ],
  virulency: [
    { word: 'virulence', word_class: 'N' },
    { word: 'virulency', word_class: 'N' },
    { word: 'virulent', word_class: 'AJ' },
    { word: 'virulently', word_class: 'AV' }
  ],
  besieger: [
    { word: 'besiege', word_class: 'V' },
    { word: 'besieger', word_class: 'N' },
    { word: 'besieged', word_class: 'AJ' },
    { word: 'besieging', word_class: 'N' }
  ],
  inundate: [
    { word: 'inundate', word_class: 'V' },
    { word: 'inundated', word_class: 'AJ' },
    { word: 'inundation', word_class: 'N' }
  ],
  surprise: [
    { word: 'surprise', word_class: 'N' },
    { word: 'surprise', word_class: 'V' },
    { word: 'surprisal', word_class: 'N' },
    { word: 'surprised', word_class: 'AJ' },
    { word: 'surprising', word_class: 'N' },
    { word: 'surprising', word_class: 'AJ' }
  ],
  unpropitious: [
    { word: 'unpropitious', word_class: 'AJ' },
    { word: 'unpropitiously', word_class: 'AV' },
    { word: 'unpropitiousness', word_class: 'N' }
  ],
  examiner: [
    { word: 'examine', word_class: 'N' },
    { word: 'examine', word_class: 'V' },
    { word: 'examinee', word_class: 'N' },
    { word: 'examiner', word_class: 'N' },
    { word: 'examination', word_class: 'N' }
  ],
  knit: [
    { word: 'knit', word_class: 'N' },
    { word: 'knit', word_class: 'V' },
    { word: 'knitted', word_class: 'AJ' },
    { word: 'knitting', word_class: 'N' }
  ],
  struggling: [
    { word: 'struggle', word_class: 'N' },
    { word: 'struggle', word_class: 'V' },
    { word: 'struggling', word_class: 'AJ' }
  ],
  hurdling: [
    { word: 'hurdle', word_class: 'N' },
    { word: 'hurdle', word_class: 'V' },
    { word: 'hurdler', word_class: 'N' },
    { word: 'hurdles', word_class: 'N' },
    { word: 'hurdling', word_class: 'N' }
  ],
  cessation: [
    { word: 'cease', word_class: 'N' },
    { word: 'cease', word_class: 'V' },
    { word: 'cessation', word_class: 'N' }
  ],
  refinement: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  requested: [
    { word: 'request', word_class: 'N' },
    { word: 'request', word_class: 'V' },
    { word: 'requested', word_class: 'AJ' }
  ],
  allusion: [
    { word: 'allude', word_class: 'V' },
    { word: 'allusion', word_class: 'N' },
    { word: 'allusive', word_class: 'AJ' },
    { word: 'allusiveness', word_class: 'N' }
  ],
  downed: [
    { word: 'down', word_class: 'N' },
    { word: 'down', word_class: 'V' },
    { word: 'down', word_class: 'AJ' },
    { word: 'down', word_class: 'AV' },
    { word: 'downer', word_class: 'N' },
    { word: 'downed', word_class: 'AJ' }
  ],
  logicalness: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  eradicate: [
    { word: 'eradicate', word_class: 'V' },
    { word: 'eradicator', word_class: 'N' },
    { word: 'eradicable', word_class: 'AJ' },
    { word: 'eradication', word_class: 'N' }
  ],
  innocently: [
    { word: 'innocent', word_class: 'N' },
    { word: 'innocence', word_class: 'N' },
    { word: 'innocency', word_class: 'N' },
    { word: 'innocent', word_class: 'AJ' },
    { word: 'innocently', word_class: 'AV' }
  ],
  heroically: [
    { word: 'heroic', word_class: 'AJ' },
    { word: 'heroics', word_class: 'N' },
    { word: 'heroical', word_class: 'AJ' },
    { word: 'heroically', word_class: 'AV' }
  ],
  ideological: [
    { word: 'ideology', word_class: 'N' },
    { word: 'ideologic', word_class: 'AJ' },
    { word: 'ideological', word_class: 'AJ' },
    { word: 'ideologically', word_class: 'AV' }
  ],
  expurgate: [
    { word: 'expurgate', word_class: 'V' },
    { word: 'expurgated', word_class: 'AJ' },
    { word: 'expurgation', word_class: 'N' }
  ],
  metaphysically: [
    { word: 'metaphysics', word_class: 'N' },
    { word: 'metaphysical', word_class: 'AJ' },
    { word: 'metaphysically', word_class: 'AV' }
  ],
  homologation: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  oblige: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  solubleness: [
    { word: 'soluble', word_class: 'AJ' },
    { word: 'solubly', word_class: 'AV' },
    { word: 'solubility', word_class: 'N' },
    { word: 'solubleness', word_class: 'N' }
  ],
  bivalved: [
    { word: 'bivalve', word_class: 'N' },
    { word: 'bivalve', word_class: 'AJ' },
    { word: 'bivalved', word_class: 'AJ' }
  ],
  laying: [
    { word: 'lay', word_class: 'N' },
    { word: 'lay', word_class: 'V' },
    { word: 'lay', word_class: 'AJ' },
    { word: 'layer', word_class: 'N' },
    { word: 'layer', word_class: 'V' },
    { word: 'laying', word_class: 'N' },
    { word: 'layman', word_class: 'N' },
    { word: 'layered', word_class: 'AJ' }
  ],
  elastic: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  catholicisation: [
    { word: 'catholicise', word_class: 'V' },
    { word: 'catholicism', word_class: 'N' },
    { word: 'catholicize', word_class: 'V' },
    { word: 'catholicisation', word_class: 'N' },
    { word: 'catholicization', word_class: 'N' }
  ],
  overnighter: [
    { word: 'overnight', word_class: 'V' },
    { word: 'overnight', word_class: 'AJ' },
    { word: 'overnight', word_class: 'AV' },
    { word: 'overnighter', word_class: 'N' }
  ],
  discoloured: [
    { word: 'discolour', word_class: 'V' },
    { word: 'discoloured', word_class: 'AJ' },
    { word: 'discolouration', word_class: 'N' }
  ],
  disconsolate: [
    { word: 'disconsole', word_class: 'V' },
    { word: 'disconsolate', word_class: 'AJ' },
    { word: 'disconsolately', word_class: 'AV' },
    { word: 'disconsolateness', word_class: 'N' }
  ],
  dud: [{ word: 'dud', word_class: 'N' }, { word: 'dud', word_class: 'AJ' }, { word: 'duds', word_class: 'N' }],
  naturalize: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  maxim: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  forwardness: [
    { word: 'forward', word_class: 'N' },
    { word: 'forward', word_class: 'V' },
    { word: 'forward', word_class: 'AJ' },
    { word: 'forward', word_class: 'AV' },
    { word: 'forwards', word_class: 'AV' },
    { word: 'forwarding', word_class: 'N' },
    { word: 'forwardness', word_class: 'N' }
  ],
  leaping: [
    { word: 'leap', word_class: 'N' },
    { word: 'leap', word_class: 'V' },
    { word: 'leaping', word_class: 'N' },
    { word: 'leaping', word_class: 'AJ' }
  ],
  runniness: [
    { word: 'run', word_class: 'N' },
    { word: 'run', word_class: 'V' },
    { word: 'run', word_class: 'AJ' },
    { word: 'runny', word_class: 'AJ' },
    { word: 'running', word_class: 'N' },
    { word: 'running', word_class: 'AJ' },
    { word: 'runniness', word_class: 'N' }
  ],
  tether: [
    { word: 'tether', word_class: 'N' },
    { word: 'tether', word_class: 'V' },
    { word: 'tethered', word_class: 'AJ' }
  ],
  implicational: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  plastering: [
    { word: 'plaster', word_class: 'N' },
    { word: 'plaster', word_class: 'V' },
    { word: 'plasterer', word_class: 'N' },
    { word: 'plastered', word_class: 'AJ' },
    { word: 'plastering', word_class: 'N' }
  ],
  researchable: [
    { word: 'research', word_class: 'N' },
    { word: 'research', word_class: 'V' },
    { word: 'researcher', word_class: 'N' },
    { word: 'researchable', word_class: 'AJ' }
  ],
  southeaster: [
    { word: 'southeast', word_class: 'N' },
    { word: 'southeast', word_class: 'AJ' },
    { word: 'southeast', word_class: 'AV' },
    { word: 'southeaster', word_class: 'N' }
  ],
  adjustable: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  shaved: [
    { word: 'shave', word_class: 'N' },
    { word: 'shave', word_class: 'V' },
    { word: 'shaver', word_class: 'N' },
    { word: 'shaved', word_class: 'AJ' },
    { word: 'shaven', word_class: 'AJ' },
    { word: 'shaving', word_class: 'N' }
  ],
  draft: [
    { word: 'draft', word_class: 'N' },
    { word: 'draft', word_class: 'V' },
    { word: 'draftee', word_class: 'N' },
    { word: 'drafter', word_class: 'N' },
    { word: 'drafting', word_class: 'N' }
  ],
  twin: [
    { word: 'twin', word_class: 'N' },
    { word: 'twin', word_class: 'V' },
    { word: 'twin', word_class: 'AJ' },
    { word: 'twinned', word_class: 'AJ' },
    { word: 'twinning', word_class: 'AJ' }
  ],
  laryngeal: [
    { word: 'larynx', word_class: 'N' },
    { word: 'laryngeal', word_class: 'AJ' },
    { word: 'laryngitis', word_class: 'N' }
  ],
  brusqueness: [
    { word: 'brusque', word_class: 'AJ' },
    { word: 'brusquely', word_class: 'AV' },
    { word: 'brusqueness', word_class: 'N' }
  ],
  intently: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  confine: [
    { word: 'confine', word_class: 'V' },
    { word: 'confines', word_class: 'N' },
    { word: 'confined', word_class: 'AJ' },
    { word: 'confining', word_class: 'AJ' },
    { word: 'confinement', word_class: 'N' }
  ],
  gilled: [{ word: 'gill', word_class: 'N' }, { word: 'gill', word_class: 'V' }, { word: 'gilled', word_class: 'AJ' }],
  solidified: [
    { word: 'solidify', word_class: 'V' },
    { word: 'solidified', word_class: 'AJ' },
    { word: 'solidifying', word_class: 'N' },
    { word: 'solidification', word_class: 'N' }
  ],
  snoring: [
    { word: 'snore', word_class: 'N' },
    { word: 'snore', word_class: 'V' },
    { word: 'snorer', word_class: 'N' },
    { word: 'snoring', word_class: 'N' },
    { word: 'snoring', word_class: 'AJ' }
  ],
  resurgence: [
    { word: 'resurge', word_class: 'N' },
    { word: 'resurge', word_class: 'V' },
    { word: 'resurgence', word_class: 'N' },
    { word: 'resurgent', word_class: 'AJ' }
  ],
  deviancy: [
    { word: 'deviant', word_class: 'N' },
    { word: 'deviate', word_class: 'N' },
    { word: 'deviate', word_class: 'V' },
    { word: 'deviance', word_class: 'N' },
    { word: 'deviant', word_class: 'AJ' },
    { word: 'deviancy', word_class: 'N' },
    { word: 'deviation', word_class: 'N' }
  ],
  vibrator: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  peeler: [
    { word: 'peel', word_class: 'N' },
    { word: 'peel', word_class: 'V' },
    { word: 'peeler', word_class: 'N' },
    { word: 'peeled', word_class: 'AJ' },
    { word: 'peeling', word_class: 'AJ' }
  ],
  solemn: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  unnerving: [
    { word: 'unnerve', word_class: 'V' },
    { word: 'unnerved', word_class: 'AJ' },
    { word: 'unnerving', word_class: 'AJ' }
  ],
  sandblast: [
    { word: 'sandblast', word_class: 'N' },
    { word: 'sandblast', word_class: 'V' },
    { word: 'sandblaster', word_class: 'N' }
  ],
  abashment: [
    { word: 'abash', word_class: 'V' },
    { word: 'abashed', word_class: 'AJ' },
    { word: 'abashment', word_class: 'N' }
  ],
  truly: [
    { word: 'true', word_class: 'N' },
    { word: 'true', word_class: 'V' },
    { word: 'true', word_class: 'AJ' },
    { word: 'truth', word_class: 'N' },
    { word: 'true', word_class: 'AV' },
    { word: 'truly', word_class: 'AV' },
    { word: 'truthful', word_class: 'AJ' },
    { word: 'truthfulness', word_class: 'N' }
  ],
  feminine: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  resident: [
    { word: 'reside', word_class: 'V' },
    { word: 'resident', word_class: 'N' },
    { word: 'residence', word_class: 'N' },
    { word: 'residency', word_class: 'N' },
    { word: 'resident', word_class: 'AJ' }
  ],
  therapeutically: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  sculling: [
    { word: 'scull', word_class: 'N' },
    { word: 'scull', word_class: 'V' },
    { word: 'sculler', word_class: 'N' },
    { word: 'sculling', word_class: 'N' },
    { word: 'scullion', word_class: 'N' }
  ],
  slackness: [
    { word: 'slack', word_class: 'N' },
    { word: 'slack', word_class: 'V' },
    { word: 'slack', word_class: 'AJ' },
    { word: 'slacks', word_class: 'N' },
    { word: 'slacker', word_class: 'N' },
    { word: 'slacking', word_class: 'N' },
    { word: 'slackness', word_class: 'N' }
  ],
  equivocal: [
    { word: 'equivocal', word_class: 'AJ' },
    { word: 'equivocate', word_class: 'V' },
    { word: 'equivocally', word_class: 'AV' },
    { word: 'equivocation', word_class: 'N' },
    { word: 'equivocalness', word_class: 'N' }
  ],
  putt: [
    { word: 'putt', word_class: 'N' },
    { word: 'putt', word_class: 'V' },
    { word: 'puttee', word_class: 'N' },
    { word: 'putter', word_class: 'N' },
    { word: 'putter', word_class: 'V' },
    { word: 'puttees', word_class: 'N' },
    { word: 'putterer', word_class: 'N' }
  ],
  velarise: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  squareness: [
    { word: 'square', word_class: 'N' },
    { word: 'square', word_class: 'V' },
    { word: 'square', word_class: 'AJ' },
    { word: 'square', word_class: 'AV' },
    { word: 'squared', word_class: 'AJ' },
    { word: 'squarely', word_class: 'AV' },
    { word: 'squareness', word_class: 'N' }
  ],
  arc: [{ word: 'arc', word_class: 'N' }, { word: 'arc', word_class: 'V' }, { word: 'arced', word_class: 'AJ' }],
  rustic: [
    { word: 'rustic', word_class: 'N' },
    { word: 'rustic', word_class: 'AJ' },
    { word: 'rusticate', word_class: 'V' },
    { word: 'rusticity', word_class: 'N' },
    { word: 'rustication', word_class: 'N' }
  ],
  savant: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  fever: [
    { word: 'fever', word_class: 'N' },
    { word: 'fever', word_class: 'V' },
    { word: 'fevered', word_class: 'AJ' },
    { word: 'feverous', word_class: 'AJ' }
  ],
  trainer: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  diligence: [
    { word: 'diligence', word_class: 'N' },
    { word: 'diligent', word_class: 'AJ' },
    { word: 'diligently', word_class: 'AV' }
  ],
  dyeing: [
    { word: 'dye', word_class: 'N' },
    { word: 'dye', word_class: 'V' },
    { word: 'dyer', word_class: 'N' },
    { word: 'dyeing', word_class: 'N' }
  ],
  twenties: [
    { word: 'twenty', word_class: 'N' },
    { word: 'twenty', word_class: 'AJ' },
    { word: 'twenties', word_class: 'N' }
  ],
  gash: [{ word: 'gash', word_class: 'N' }, { word: 'gash', word_class: 'V' }, { word: 'gashed', word_class: 'AJ' }],
  element: [
    { word: 'element', word_class: 'N' },
    { word: 'elements', word_class: 'N' },
    { word: 'elemental', word_class: 'AJ' },
    { word: 'elementary', word_class: 'AJ' }
  ],
  foundering: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  plasticisation: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  beguiling: [
    { word: 'beguile', word_class: 'V' },
    { word: 'beguiled', word_class: 'AJ' },
    { word: 'beguiling', word_class: 'AJ' },
    { word: 'beguilement', word_class: 'N' }
  ],
  refection: [
    { word: 'refect', word_class: 'V' },
    { word: 'refection', word_class: 'N' },
    { word: 'refectory', word_class: 'N' },
    { word: 'refectory', word_class: 'AJ' }
  ],
  profanation: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  scepticism: [
    { word: 'sceptic', word_class: 'N' },
    { word: 'sceptical', word_class: 'AJ' },
    { word: 'scepticism', word_class: 'N' },
    { word: 'sceptically', word_class: 'AV' }
  ],
  leg: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  repent: [
    { word: 'repent', word_class: 'V' },
    { word: 'repent', word_class: 'AJ' },
    { word: 'repentant', word_class: 'N' },
    { word: 'repentance', word_class: 'N' },
    { word: 'repentant', word_class: 'AJ' }
  ],
  blazer: [
    { word: 'blaze', word_class: 'N' },
    { word: 'blaze', word_class: 'V' },
    { word: 'blazer', word_class: 'N' },
    { word: 'blazing', word_class: 'N' },
    { word: 'blazing', word_class: 'AJ' }
  ],
  repairer: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  portent: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  boniness: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  philosophically: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  glue: [{ word: 'glue', word_class: 'N' }, { word: 'glue', word_class: 'V' }, { word: 'gluey', word_class: 'AJ' }],
  ended: [
    { word: 'end', word_class: 'N' },
    { word: 'end', word_class: 'V' },
    { word: 'ended', word_class: 'AJ' },
    { word: 'ending', word_class: 'N' }
  ],
  promiser: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  discarding: [
    { word: 'discard', word_class: 'N' },
    { word: 'discard', word_class: 'V' },
    { word: 'discarded', word_class: 'AJ' },
    { word: 'discarding', word_class: 'N' }
  ],
  cover: [
    { word: 'cover', word_class: 'N' },
    { word: 'cover', word_class: 'V' },
    { word: 'covered', word_class: 'AJ' },
    { word: 'covering', word_class: 'N' },
    { word: 'covering', word_class: 'AJ' }
  ],
  incompatibly: [
    { word: 'incompatible', word_class: 'AJ' },
    { word: 'incompatibly', word_class: 'AV' },
    { word: 'incompatibility', word_class: 'N' }
  ],
  resource: [
    { word: 'resource', word_class: 'N' },
    { word: 'resourceful', word_class: 'AJ' },
    { word: 'resourcefulness', word_class: 'N' }
  ],
  grading: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  incapableness: [
    { word: 'incapable', word_class: 'AJ' },
    { word: 'incapability', word_class: 'N' },
    { word: 'incapableness', word_class: 'N' }
  ],
  geometrise: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  enroll: [
    { word: 'enrol', word_class: 'V' },
    { word: 'enroll', word_class: 'V' },
    { word: 'enrolled', word_class: 'AJ' },
    { word: 'enrolment', word_class: 'N' },
    { word: 'enrollment', word_class: 'N' }
  ],
  temptation: [
    { word: 'tempt', word_class: 'V' },
    { word: 'tempter', word_class: 'N' },
    { word: 'tempting', word_class: 'AJ' },
    { word: 'temptress', word_class: 'N' },
    { word: 'temptation', word_class: 'N' }
  ],
  theoretical: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  pissed: [
    { word: 'piss', word_class: 'N' },
    { word: 'piss', word_class: 'V' },
    { word: 'pissed', word_class: 'AJ' },
    { word: 'pissing', word_class: 'N' }
  ],
  embarkation: [
    { word: 'embark', word_class: 'V' },
    { word: 'embarkment', word_class: 'N' },
    { word: 'embarkation', word_class: 'N' }
  ],
  ciliate: [
    { word: 'ciliate', word_class: 'N' },
    { word: 'ciliate', word_class: 'V' },
    { word: 'ciliate', word_class: 'AJ' },
    { word: 'ciliated', word_class: 'AJ' },
    { word: 'ciliation', word_class: 'N' }
  ],
  femaleness: [
    { word: 'female', word_class: 'N' },
    { word: 'female', word_class: 'AJ' },
    { word: 'femaleness', word_class: 'N' }
  ],
  coordination: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  emancipator: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  accretion: [
    { word: 'accrete', word_class: 'V' },
    { word: 'accretion', word_class: 'N' },
    { word: 'accretive', word_class: 'AJ' }
  ],
  massage: [
    { word: 'massage', word_class: 'N' },
    { word: 'massage', word_class: 'V' },
    { word: 'massager', word_class: 'N' }
  ],
  serialize: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  articulately: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  gifted: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  instructor: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  concertize: [
    { word: 'concert', word_class: 'N' },
    { word: 'concert', word_class: 'V' },
    { word: 'concerted', word_class: 'AJ' },
    { word: 'concertize', word_class: 'V' }
  ],
  revolutionist: [
    { word: 'revolutionist', word_class: 'N' },
    { word: 'revolutionize', word_class: 'V' },
    { word: 'revolutionization', word_class: 'N' }
  ],
  didactic: [
    { word: 'didactic', word_class: 'AJ' },
    { word: 'didactical', word_class: 'AJ' },
    { word: 'didactically', word_class: 'AV' }
  ],
  deliciousness: [
    { word: 'delicious', word_class: 'N' },
    { word: 'delicious', word_class: 'AJ' },
    { word: 'deliciously', word_class: 'AV' },
    { word: 'deliciousness', word_class: 'N' }
  ],
  mismatch: [
    { word: 'mismatch', word_class: 'N' },
    { word: 'mismatch', word_class: 'V' },
    { word: 'mismatched', word_class: 'AJ' }
  ],
  resistor: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  delineate: [
    { word: 'delineate', word_class: 'V' },
    { word: 'delineate', word_class: 'AJ' },
    { word: 'delineated', word_class: 'AJ' },
    { word: 'delineation', word_class: 'N' },
    { word: 'delineative', word_class: 'AJ' }
  ],
  faradization: [
    { word: 'farad', word_class: 'N' },
    { word: 'faradise', word_class: 'V' },
    { word: 'faradize', word_class: 'V' },
    { word: 'faradisation', word_class: 'N' },
    { word: 'faradization', word_class: 'N' }
  ],
  provincialism: [
    { word: 'provincial', word_class: 'N' },
    { word: 'provincial', word_class: 'AJ' },
    { word: 'provincialism', word_class: 'N' },
    { word: 'provincially', word_class: 'AV' }
  ],
  salability: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  waking: [
    { word: 'wake', word_class: 'N' },
    { word: 'wake', word_class: 'V' },
    { word: 'waking', word_class: 'N' },
    { word: 'woken', word_class: 'AJ' },
    { word: 'waking', word_class: 'AJ' },
    { word: 'wakeful', word_class: 'AJ' },
    { word: 'wakefulness', word_class: 'N' }
  ],
  vacuolated: [
    { word: 'vacuole', word_class: 'N' },
    { word: 'vacuolate', word_class: 'AJ' },
    { word: 'vacuolated', word_class: 'AJ' },
    { word: 'vacuolation', word_class: 'N' },
    { word: 'vacuolization', word_class: 'N' }
  ],
  valorousness: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  generally: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  filibuster: [
    { word: 'filibuster', word_class: 'N' },
    { word: 'filibuster', word_class: 'V' },
    { word: 'filibusterer', word_class: 'N' }
  ],
  brocaded: [
    { word: 'brocade', word_class: 'N' },
    { word: 'brocade', word_class: 'V' },
    { word: 'brocaded', word_class: 'AJ' }
  ],
  sallow: [
    { word: 'sallow', word_class: 'N' },
    { word: 'sallow', word_class: 'V' },
    { word: 'sallow', word_class: 'AJ' },
    { word: 'sallowness', word_class: 'N' }
  ],
  ingeniously: [
    { word: 'ingenious', word_class: 'AJ' },
    { word: 'ingeniously', word_class: 'AV' },
    { word: 'ingeniousness', word_class: 'N' }
  ],
  remaining: [
    { word: 'remain', word_class: 'V' },
    { word: 'remain', word_class: 'AJ' },
    { word: 'remains', word_class: 'N' },
    { word: 'remainder', word_class: 'N' },
    { word: 'remanence', word_class: 'N' },
    { word: 'remainder', word_class: 'V' },
    { word: 'remaining', word_class: 'AJ' }
  ],
  dirtiness: [
    { word: 'dirty', word_class: 'V' },
    { word: 'dirty', word_class: 'AJ' },
    { word: 'dirtying', word_class: 'N' },
    { word: 'dirtiness', word_class: 'N' }
  ],
  contented: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  sanctified: [
    { word: 'sanctify', word_class: 'V' },
    { word: 'sanctified', word_class: 'AJ' },
    { word: 'sanctification', word_class: 'N' }
  ],
  colloid: [
    { word: 'colloid', word_class: 'N' },
    { word: 'colloid', word_class: 'AJ' },
    { word: 'colloidal', word_class: 'AJ' }
  ],
  underpinning: [
    { word: 'underpin', word_class: 'N' },
    { word: 'underpin', word_class: 'V' },
    { word: 'underpinning', word_class: 'N' }
  ],
  civilize: [
    { word: 'civil', word_class: 'AJ' },
    { word: 'civility', word_class: 'N' },
    { word: 'civilize', word_class: 'V' },
    { word: 'civilized', word_class: 'AJ' },
    { word: 'civilization', word_class: 'N' }
  ],
  puritanically: [
    { word: 'puritan', word_class: 'N' },
    { word: 'puritan', word_class: 'AJ' },
    { word: 'puritanic', word_class: 'AJ' },
    { word: 'puritanism', word_class: 'N' },
    { word: 'puritanical', word_class: 'AJ' },
    { word: 'puritanically', word_class: 'AV' }
  ],
  artistic: [
    { word: 'artist', word_class: 'N' },
    { word: 'artiste', word_class: 'N' },
    { word: 'artistic', word_class: 'AJ' },
    { word: 'artistically', word_class: 'AV' }
  ],
  stereotypical: [
    { word: 'stereotype', word_class: 'N' },
    { word: 'stereotype', word_class: 'V' },
    { word: 'stereotyped', word_class: 'AJ' },
    { word: 'stereotypic', word_class: 'AJ' },
    { word: 'stereotypical', word_class: 'AJ' }
  ],
  puzzling: [
    { word: 'puzzle', word_class: 'N' },
    { word: 'puzzle', word_class: 'V' },
    { word: 'puzzler', word_class: 'N' },
    { word: 'puzzled', word_class: 'AJ' },
    { word: 'puzzling', word_class: 'AJ' },
    { word: 'puzzlement', word_class: 'N' }
  ],
  rumor: [
    { word: 'rumor', word_class: 'N' },
    { word: 'rumor', word_class: 'V' },
    { word: 'rumored', word_class: 'AJ' }
  ],
  teutonization: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  petit: [
    { word: 'petite', word_class: 'N' },
    { word: 'petit', word_class: 'AJ' },
    { word: 'petite', word_class: 'AJ' },
    { word: 'petiteness', word_class: 'N' }
  ],
  graveled: [
    { word: 'gravel', word_class: 'N' },
    { word: 'gravel', word_class: 'V' },
    { word: 'gravel', word_class: 'AJ' },
    { word: 'graveled', word_class: 'AJ' }
  ],
  particularisation: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  improvidence: [
    { word: 'improvidence', word_class: 'N' },
    { word: 'improvident', word_class: 'AJ' },
    { word: 'improvidently', word_class: 'AV' }
  ],
  asserting: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  inexpressively: [
    { word: 'inexpressive', word_class: 'AJ' },
    { word: 'inexpressible', word_class: 'AJ' },
    { word: 'inexpressively', word_class: 'AV' }
  ],
  arthrospore: [
    { word: 'arthrospore', word_class: 'N' },
    { word: 'arthrosporic', word_class: 'AJ' },
    { word: 'arthrosporous', word_class: 'AJ' }
  ],
  tower: [
    { word: 'tower', word_class: 'N' },
    { word: 'tower', word_class: 'V' },
    { word: 'towering', word_class: 'AJ' }
  ],
  extricable: [
    { word: 'extricate', word_class: 'V' },
    { word: 'extricable', word_class: 'AJ' },
    { word: 'extricated', word_class: 'AJ' },
    { word: 'extrication', word_class: 'N' }
  ],
  intersection: [
    { word: 'intersect', word_class: 'N' },
    { word: 'intersect', word_class: 'V' },
    { word: 'intersection', word_class: 'N' },
    { word: 'intersectant', word_class: 'AJ' },
    { word: 'intersecting', word_class: 'AJ' }
  ],
  responsibility: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  uncomfortably: [
    { word: 'uncomfortable', word_class: 'AJ' },
    { word: 'uncomfortably', word_class: 'AV' },
    { word: 'uncomfortableness', word_class: 'N' }
  ],
  bustle: [
    { word: 'bustle', word_class: 'N' },
    { word: 'bustle', word_class: 'V' },
    { word: 'bustling', word_class: 'AJ' }
  ],
  soundness: [
    { word: 'sound', word_class: 'N' },
    { word: 'sound', word_class: 'V' },
    { word: 'sound', word_class: 'AJ' },
    { word: 'sound', word_class: 'AV' },
    { word: 'sounded', word_class: 'AJ' },
    { word: 'sounding', word_class: 'N' },
    { word: 'soundman', word_class: 'N' },
    { word: 'sounding', word_class: 'AJ' },
    { word: 'soundness', word_class: 'N' }
  ],
  brainwashing: [
    { word: 'brainwash', word_class: 'V' },
    { word: 'brainwashed', word_class: 'AJ' },
    { word: 'brainwashing', word_class: 'N' }
  ],
  insurance: [
    { word: 'insure', word_class: 'V' },
    { word: 'insure', word_class: 'AJ' },
    { word: 'insurer', word_class: 'N' },
    { word: 'insured', word_class: 'AJ' },
    { word: 'insurance', word_class: 'N' }
  ],
  malicious: [
    { word: 'malice', word_class: 'N' },
    { word: 'malice', word_class: 'V' },
    { word: 'malicious', word_class: 'AJ' },
    { word: 'maliciously', word_class: 'AV' },
    { word: 'maliciousness', word_class: 'N' }
  ],
  subjectivity: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  played: [
    { word: 'play', word_class: 'N' },
    { word: 'play', word_class: 'V' },
    { word: 'play', word_class: 'AJ' },
    { word: 'player', word_class: 'N' },
    { word: 'played', word_class: 'AJ' },
    { word: 'playing', word_class: 'N' },
    { word: 'playing', word_class: 'AJ' }
  ],
  lionisation: [
    { word: 'lionise', word_class: 'V' },
    { word: 'lionize', word_class: 'V' },
    { word: 'lionisation', word_class: 'N' },
    { word: 'lionization', word_class: 'N' }
  ],
  purpose: [
    { word: 'purpose', word_class: 'N' },
    { word: 'purpose', word_class: 'V' },
    { word: 'purposely', word_class: 'AV' },
    { word: 'purposive', word_class: 'AJ' },
    { word: 'purposeful', word_class: 'AJ' },
    { word: 'purposefulness', word_class: 'N' }
  ],
  teenager: [
    { word: 'teenage', word_class: 'AJ' },
    { word: 'teenager', word_class: 'N' },
    { word: 'teenaged', word_class: 'AJ' }
  ],
  comprising: [
    { word: 'comprise', word_class: 'V' },
    { word: 'comprisal', word_class: 'N' },
    { word: 'comprising', word_class: 'N' }
  ],
  backward: [
    { word: 'backward', word_class: 'AJ' },
    { word: 'backward', word_class: 'AV' },
    { word: 'backwards', word_class: 'AV' },
    { word: 'backwardness', word_class: 'N' }
  ],
  dissoluble: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  loll: [{ word: 'loll', word_class: 'N' }, { word: 'loll', word_class: 'V' }, { word: 'lolling', word_class: 'AJ' }],
  side: [
    { word: 'side', word_class: 'N' },
    { word: 'side', word_class: 'V' },
    { word: 'side', word_class: 'AJ' },
    { word: 'siding', word_class: 'N' }
  ],
  balladeer: [
    { word: 'ballad', word_class: 'N' },
    { word: 'ballade', word_class: 'N' },
    { word: 'balladeer', word_class: 'N' }
  ],
  expandible: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  substantiality: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  lifted: [
    { word: 'lift', word_class: 'N' },
    { word: 'lift', word_class: 'V' },
    { word: 'lifter', word_class: 'N' },
    { word: 'lifted', word_class: 'AJ' },
    { word: 'liftman', word_class: 'N' }
  ],
  antecedent: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  associability: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  atonement: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  attributive: [
    { word: 'attribute', word_class: 'N' },
    { word: 'attribute', word_class: 'V' },
    { word: 'attribution', word_class: 'N' },
    { word: 'attributive', word_class: 'AJ' },
    { word: 'attributable', word_class: 'AJ' },
    { word: 'attributively', word_class: 'AV' }
  ],
  ambulant: [
    { word: 'ambulate', word_class: 'V' },
    { word: 'ambulance', word_class: 'N' },
    { word: 'ambulant', word_class: 'AJ' },
    { word: 'ambulation', word_class: 'N' }
  ],
  remains: [
    { word: 'remain', word_class: 'V' },
    { word: 'remain', word_class: 'AJ' },
    { word: 'remains', word_class: 'N' },
    { word: 'remainder', word_class: 'N' },
    { word: 'remanence', word_class: 'N' },
    { word: 'remainder', word_class: 'V' },
    { word: 'remaining', word_class: 'AJ' }
  ],
  organist: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  pianist: [
    { word: 'piano', word_class: 'N' },
    { word: 'piano', word_class: 'AJ' },
    { word: 'piano', word_class: 'AV' },
    { word: 'pianist', word_class: 'N' },
    { word: 'pianistic', word_class: 'AJ' }
  ],
  altruistically: [
    { word: 'altruist', word_class: 'N' },
    { word: 'altruistic', word_class: 'AJ' },
    { word: 'altruistically', word_class: 'AV' }
  ],
  usurp: [
    { word: 'usurp', word_class: 'V' },
    { word: 'usurper', word_class: 'N' },
    { word: 'usurped', word_class: 'AJ' },
    { word: 'usurpation', word_class: 'N' }
  ],
  nicety: [
    { word: 'nice', word_class: 'N' },
    { word: 'nice', word_class: 'AJ' },
    { word: 'nicety', word_class: 'N' },
    { word: 'nicely', word_class: 'AV' },
    { word: 'niceness', word_class: 'N' }
  ],
  brindle: [
    { word: 'brindle', word_class: 'N' },
    { word: 'brindle', word_class: 'AJ' },
    { word: 'brindled', word_class: 'AJ' }
  ],
  atonally: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  feasibility: [
    { word: 'feasible', word_class: 'AJ' },
    { word: 'feasible', word_class: 'AV' },
    { word: 'feasibility', word_class: 'N' },
    { word: 'feasibleness', word_class: 'N' }
  ],
  oxidize: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  prognostication: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  asymmetric: [
    { word: 'asymmetric', word_class: 'AJ' },
    { word: 'asymmetrical', word_class: 'N' },
    { word: 'asymmetrical', word_class: 'AJ' },
    { word: 'asymmetrically', word_class: 'AV' }
  ],
  despoliation: [
    { word: 'despoil', word_class: 'V' },
    { word: 'despoiler', word_class: 'N' },
    { word: 'despoiled', word_class: 'AJ' },
    { word: 'despoilment', word_class: 'N' },
    { word: 'despoliation', word_class: 'N' }
  ],
  melodiously: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  national: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  reorganize: [
    { word: 'reorganize', word_class: 'V' },
    { word: 'reorganized', word_class: 'AJ' },
    { word: 'reorganization', word_class: 'N' }
  ],
  scoff: [{ word: 'scoff', word_class: 'N' }, { word: 'scoff', word_class: 'V' }, { word: 'scoffer', word_class: 'N' }],
  boat: [
    { word: 'boat', word_class: 'N' },
    { word: 'boat', word_class: 'V' },
    { word: 'boater', word_class: 'N' },
    { word: 'boating', word_class: 'N' },
    { word: 'boatman', word_class: 'N' }
  ],
  syncopate: [
    { word: 'syncope', word_class: 'N' },
    { word: 'syncopate', word_class: 'V' },
    { word: 'syncopated', word_class: 'AJ' },
    { word: 'syncopation', word_class: 'N' }
  ],
  unsocial: [
    { word: 'unsocial', word_class: 'AJ' },
    { word: 'unsociable', word_class: 'AJ' },
    { word: 'unsociably', word_class: 'AV' },
    { word: 'unsociability', word_class: 'N' },
    { word: 'unsociableness', word_class: 'N' }
  ],
  strangles: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  narcotisation: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  antitypical: [
    { word: 'antitype', word_class: 'N' },
    { word: 'antitypic', word_class: 'AJ' },
    { word: 'antitypical', word_class: 'AJ' }
  ],
  synergist: [
    { word: 'synergy', word_class: 'N' },
    { word: 'synergism', word_class: 'N' },
    { word: 'synergist', word_class: 'N' },
    { word: 'synergistic', word_class: 'AJ' }
  ],
  reflector: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  flailing: [
    { word: 'flail', word_class: 'N' },
    { word: 'flail', word_class: 'V' },
    { word: 'flailing', word_class: 'AJ' }
  ],
  charm: [
    { word: 'charm', word_class: 'N' },
    { word: 'charm', word_class: 'V' },
    { word: 'charmer', word_class: 'N' },
    { word: 'charmed', word_class: 'AJ' },
    { word: 'charming', word_class: 'AJ' }
  ],
  aperient: [
    { word: 'apery', word_class: 'N' },
    { word: 'aperient', word_class: 'N' },
    { word: 'aperient', word_class: 'AJ' }
  ],
  weightlifting: [
    { word: 'weightlift', word_class: 'V' },
    { word: 'weightlifter', word_class: 'N' },
    { word: 'weightlifting', word_class: 'N' }
  ],
  shed: [
    { word: 'shed', word_class: 'N' },
    { word: 'shed', word_class: 'V' },
    { word: 'shed', word_class: 'AJ' },
    { word: 'shedding', word_class: 'N' }
  ],
  volumetrically: [
    { word: 'volumetric', word_class: 'AJ' },
    { word: 'volumetrical', word_class: 'AJ' },
    { word: 'volumetrically', word_class: 'AV' }
  ],
  inhuman: [
    { word: 'inhuman', word_class: 'AJ' },
    { word: 'inhumane', word_class: 'AJ' },
    { word: 'inhumanity', word_class: 'N' },
    { word: 'inhumanely', word_class: 'AV' },
    { word: 'inhumaneness', word_class: 'N' }
  ],
  courser: [
    { word: 'course', word_class: 'N' },
    { word: 'course', word_class: 'V' },
    { word: 'course', word_class: 'AV' },
    { word: 'courser', word_class: 'N' },
    { word: 'coursing', word_class: 'N' }
  ],
  chic: [{ word: 'chic', word_class: 'N' }, { word: 'chic', word_class: 'AJ' }, { word: 'chicness', word_class: 'N' }],
  volcanically: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  ladleful: [
    { word: 'ladle', word_class: 'N' },
    { word: 'ladle', word_class: 'V' },
    { word: 'ladleful', word_class: 'N' },
    { word: 'ladleful', word_class: 'AJ' }
  ],
  ingestion: [
    { word: 'ingest', word_class: 'V' },
    { word: 'ingested', word_class: 'AJ' },
    { word: 'ingestion', word_class: 'N' }
  ],
  prefatory: [
    { word: 'preface', word_class: 'N' },
    { word: 'preface', word_class: 'V' },
    { word: 'prefatory', word_class: 'AJ' },
    { word: 'prefatorial', word_class: 'AJ' }
  ],
  travelled: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  constituency: [
    { word: 'constituent', word_class: 'N' },
    { word: 'constituency', word_class: 'N' },
    { word: 'constituent', word_class: 'AJ' }
  ],
  psychopathological: [
    { word: 'psychopathology', word_class: 'N' },
    { word: 'psychopathologic', word_class: 'AJ' },
    { word: 'psychopathological', word_class: 'AJ' }
  ],
  circumstantiate: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  aunty: [{ word: 'aunt', word_class: 'N' }, { word: 'aunty', word_class: 'N' }, { word: 'auntie', word_class: 'N' }],
  closely: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  ranting: [
    { word: 'rant', word_class: 'N' },
    { word: 'rant', word_class: 'V' },
    { word: 'ranter', word_class: 'N' },
    { word: 'ranting', word_class: 'N' }
  ],
  moneyed: [
    { word: 'money', word_class: 'N' },
    { word: 'monied', word_class: 'AJ' },
    { word: 'moneyed', word_class: 'AJ' }
  ],
  backstair: [
    { word: 'backstair', word_class: 'AJ' },
    { word: 'backstairs', word_class: 'N' },
    { word: 'backstairs', word_class: 'AJ' }
  ],
  contumacious: [
    { word: 'contumacy', word_class: 'N' },
    { word: 'contumacious', word_class: 'AJ' },
    { word: 'contumaciously', word_class: 'AV' }
  ],
  valiancy: [
    { word: 'valiance', word_class: 'N' },
    { word: 'valiancy', word_class: 'N' },
    { word: 'valiant', word_class: 'AJ' }
  ],
  ratiocinative: [
    { word: 'ratiocinate', word_class: 'V' },
    { word: 'ratiocinator', word_class: 'N' },
    { word: 'ratiocination', word_class: 'N' },
    { word: 'ratiocinative', word_class: 'AJ' }
  ],
  bisexuality: [
    { word: 'bisexual', word_class: 'N' },
    { word: 'bisexual', word_class: 'AJ' },
    { word: 'bisexuality', word_class: 'N' }
  ],
  glistering: [
    { word: 'glister', word_class: 'N' },
    { word: 'glister', word_class: 'V' },
    { word: 'glistering', word_class: 'AJ' }
  ],
  concision: [
    { word: 'concise', word_class: 'AJ' },
    { word: 'concision', word_class: 'N' },
    { word: 'concisely', word_class: 'AV' },
    { word: 'conciseness', word_class: 'N' }
  ],
  denomination: [
    { word: 'denominate', word_class: 'V' },
    { word: 'denominator', word_class: 'N' },
    { word: 'denomination', word_class: 'N' },
    { word: 'denominational', word_class: 'AJ' }
  ],
  aerate: [
    { word: 'aerate', word_class: 'V' },
    { word: 'aerator', word_class: 'N' },
    { word: 'aerated', word_class: 'AJ' },
    { word: 'aeration', word_class: 'N' }
  ],
  potted: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  confusion: [
    { word: 'confuse', word_class: 'V' },
    { word: 'confused', word_class: 'AJ' },
    { word: 'confusion', word_class: 'N' },
    { word: 'confusing', word_class: 'AJ' },
    { word: 'confusable', word_class: 'AJ' }
  ],
  misanthropic: [
    { word: 'misanthrope', word_class: 'N' },
    { word: 'misanthropic', word_class: 'AJ' },
    { word: 'misanthropical', word_class: 'AJ' }
  ],
  clinical: [
    { word: 'clinic', word_class: 'N' },
    { word: 'clinic', word_class: 'AJ' },
    { word: 'clinical', word_class: 'AJ' },
    { word: 'clinically', word_class: 'AV' }
  ],
  aeronautical: [
    { word: 'aeronaut', word_class: 'N' },
    { word: 'aeronautic', word_class: 'AJ' },
    { word: 'aeronautics', word_class: 'N' },
    { word: 'aeronautical', word_class: 'AJ' }
  ],
  tolerability: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  nervy: [
    { word: 'nerve', word_class: 'N' },
    { word: 'nerve', word_class: 'V' },
    { word: 'nerves', word_class: 'N' },
    { word: 'nervy', word_class: 'AJ' }
  ],
  soulful: [
    { word: 'soul', word_class: 'N' },
    { word: 'soulful', word_class: 'AJ' },
    { word: 'soulfulness', word_class: 'N' }
  ],
  answering: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  presentness: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  hearted: [
    { word: 'heart', word_class: 'N' },
    { word: 'hearts', word_class: 'N' },
    { word: 'hearted', word_class: 'AJ' }
  ],
  regionally: [
    { word: 'region', word_class: 'N' },
    { word: 'regional', word_class: 'AJ' },
    { word: 'regionalize', word_class: 'V' },
    { word: 'regionally', word_class: 'AV' }
  ],
  immutableness: [
    { word: 'immutable', word_class: 'AJ' },
    { word: 'immutably', word_class: 'AV' },
    { word: 'immutability', word_class: 'N' },
    { word: 'immutableness', word_class: 'N' }
  ],
  exculpate: [
    { word: 'exculpate', word_class: 'V' },
    { word: 'exculpated', word_class: 'AJ' },
    { word: 'exculpation', word_class: 'N' }
  ],
  quadruplicate: [
    { word: 'quadruple', word_class: 'N' },
    { word: 'quadruple', word_class: 'V' },
    { word: 'quadruple', word_class: 'AJ' },
    { word: 'quadrupling', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'V' },
    { word: 'quadruplicate', word_class: 'AJ' },
    { word: 'quadruplication', word_class: 'N' }
  ],
  imperceptible: [
    { word: 'imperceptible', word_class: 'AJ' },
    { word: 'imperceptibly', word_class: 'AV' },
    { word: 'imperceptibility', word_class: 'N' }
  ],
  conversational: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  hairdresser: [
    { word: 'hairdress', word_class: 'V' },
    { word: 'hairdresser', word_class: 'N' },
    { word: 'hairdressing', word_class: 'N' }
  ],
  swarm: [
    { word: 'swarm', word_class: 'N' },
    { word: 'swarm', word_class: 'V' },
    { word: 'swarming', word_class: 'AJ' }
  ],
  acidophilic: [
    { word: 'acidophil', word_class: 'N' },
    { word: 'acidophile', word_class: 'N' },
    { word: 'acidophilic', word_class: 'AJ' },
    { word: 'acidophilous', word_class: 'AJ' }
  ],
  orientalization: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  hook: [
    { word: 'hook', word_class: 'N' },
    { word: 'hook', word_class: 'V' },
    { word: 'hooker', word_class: 'N' },
    { word: 'hooked', word_class: 'AJ' }
  ],
  parentage: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  contributing: [
    { word: 'contribute', word_class: 'V' },
    { word: 'contributor', word_class: 'N' },
    { word: 'contribution', word_class: 'N' },
    { word: 'contributing', word_class: 'AJ' },
    { word: 'contributive', word_class: 'AJ' }
  ],
  avoidance: [
    { word: 'avoid', word_class: 'V' },
    { word: 'avoidance', word_class: 'N' },
    { word: 'avoidable', word_class: 'AJ' }
  ],
  murderer: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  crateful: [
    { word: 'crate', word_class: 'N' },
    { word: 'crate', word_class: 'V' },
    { word: 'crater', word_class: 'N' },
    { word: 'crater', word_class: 'V' },
    { word: 'crateful', word_class: 'N' }
  ],
  etiology: [
    { word: 'etiology', word_class: 'N' },
    { word: 'etiologic', word_class: 'AJ' },
    { word: 'etiological', word_class: 'AJ' }
  ],
  bribe: [
    { word: 'bribe', word_class: 'N' },
    { word: 'bribe', word_class: 'V' },
    { word: 'briber', word_class: 'N' },
    { word: 'bribery', word_class: 'N' },
    { word: 'bribery', word_class: 'AJ' }
  ],
  apprenticeship: [
    { word: 'apprentice', word_class: 'N' },
    { word: 'apprentice', word_class: 'V' },
    { word: 'apprentice', word_class: 'AJ' },
    { word: 'apprenticeship', word_class: 'N' }
  ],
  view: [
    { word: 'view', word_class: 'N' },
    { word: 'view', word_class: 'V' },
    { word: 'viewer', word_class: 'N' },
    { word: 'viewers', word_class: 'N' },
    { word: 'viewing', word_class: 'N' }
  ],
  secernment: [
    { word: 'secern', word_class: 'V' },
    { word: 'secernate', word_class: 'V' },
    { word: 'secernment', word_class: 'N' }
  ],
  incoming: [
    { word: 'income', word_class: 'N' },
    { word: 'income', word_class: 'V' },
    { word: 'incoming', word_class: 'N' },
    { word: 'incoming', word_class: 'AJ' }
  ],
  preconise: [
    { word: 'preconise', word_class: 'V' },
    { word: 'preconize', word_class: 'V' },
    { word: 'preconisation', word_class: 'N' },
    { word: 'preconization', word_class: 'N' }
  ],
  phrase: [
    { word: 'phrase', word_class: 'N' },
    { word: 'phrase', word_class: 'V' },
    { word: 'phrasing', word_class: 'N' }
  ],
  arrogation: [
    { word: 'arrogate', word_class: 'V' },
    { word: 'arrogance', word_class: 'N' },
    { word: 'arrogant', word_class: 'AJ' },
    { word: 'arrogated', word_class: 'AJ' },
    { word: 'arrogation', word_class: 'N' }
  ],
  futurity: [
    { word: 'future', word_class: 'N' },
    { word: 'future', word_class: 'AJ' },
    { word: 'futurism', word_class: 'N' },
    { word: 'futurity', word_class: 'N' }
  ],
  delinquent: [
    { word: 'delinquent', word_class: 'N' },
    { word: 'delinquency', word_class: 'N' },
    { word: 'delinquent', word_class: 'AJ' }
  ],
  disorganisation: [
    { word: 'disorganize', word_class: 'V' },
    { word: 'disorganized', word_class: 'AJ' },
    { word: 'disorganisation', word_class: 'N' },
    { word: 'disorganization', word_class: 'N' }
  ],
  enticement: [
    { word: 'entice', word_class: 'V' },
    { word: 'enticing', word_class: 'AJ' },
    { word: 'enticement', word_class: 'N' }
  ],
  bewilder: [
    { word: 'bewilder', word_class: 'V' },
    { word: 'bewildered', word_class: 'AJ' },
    { word: 'bewildering', word_class: 'AJ' },
    { word: 'bewilderment', word_class: 'N' }
  ],
  magnetization: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  oxidate: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  wring: [{ word: 'wring', word_class: 'N' }, { word: 'wring', word_class: 'V' }, { word: 'wringer', word_class: 'N' }],
  accrue: [
    { word: 'accrue', word_class: 'V' },
    { word: 'accrual', word_class: 'N' },
    { word: 'accrued', word_class: 'AJ' },
    { word: 'accrument', word_class: 'N' },
    { word: 'accruement', word_class: 'N' }
  ],
  enshrine: [
    { word: 'enshrine', word_class: 'V' },
    { word: 'inshrine', word_class: 'V' },
    { word: 'enshrinement', word_class: 'N' }
  ],
  elope: [
    { word: 'elope', word_class: 'V' },
    { word: 'elops', word_class: 'N' },
    { word: 'elopement', word_class: 'N' }
  ],
  obviating: [
    { word: 'obviate', word_class: 'V' },
    { word: 'obviation', word_class: 'N' },
    { word: 'obviating', word_class: 'AJ' }
  ],
  required: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  miserable: [
    { word: 'miser', word_class: 'N' },
    { word: 'miserable', word_class: 'AJ' },
    { word: 'miserably', word_class: 'AV' }
  ],
  itemize: [
    { word: 'item', word_class: 'N' },
    { word: 'item', word_class: 'AV' },
    { word: 'itemise', word_class: 'V' },
    { word: 'itemize', word_class: 'V' },
    { word: 'itemisation', word_class: 'N' },
    { word: 'itemization', word_class: 'N' }
  ],
  obstreperously: [
    { word: 'obstreperous', word_class: 'AJ' },
    { word: 'obstreperously', word_class: 'AV' },
    { word: 'obstreperousness', word_class: 'N' }
  ],
  angularity: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  interior: [
    { word: 'interior', word_class: 'N' },
    { word: 'interior', word_class: 'AJ' },
    { word: 'interiorize', word_class: 'V' },
    { word: 'interiorization', word_class: 'N' }
  ],
  anthropomorphization: [
    { word: 'anthropomorphic', word_class: 'AJ' },
    { word: 'anthropomorphism', word_class: 'N' },
    { word: 'anthropomorphize', word_class: 'V' },
    { word: 'anthropomorphous', word_class: 'AJ' },
    { word: 'anthropomorphization', word_class: 'N' }
  ],
  spectroscopical: [
    { word: 'spectroscope', word_class: 'N' },
    { word: 'spectroscopic', word_class: 'AJ' },
    { word: 'spectroscopical', word_class: 'AJ' }
  ],
  cognation: [
    { word: 'cognate', word_class: 'N' },
    { word: 'cognate', word_class: 'AJ' },
    { word: 'cognation', word_class: 'N' }
  ],
  tearful: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  nationality: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  penciled: [
    { word: 'pencil', word_class: 'N' },
    { word: 'pencil', word_class: 'V' },
    { word: 'penciled', word_class: 'AJ' },
    { word: 'pencilled', word_class: 'AJ' }
  ],
  dashing: [
    { word: 'dash', word_class: 'N' },
    { word: 'dash', word_class: 'V' },
    { word: 'dashed', word_class: 'AJ' },
    { word: 'dashing', word_class: 'AJ' }
  ],
  traversable: [
    { word: 'traverse', word_class: 'N' },
    { word: 'traverse', word_class: 'V' },
    { word: 'traversal', word_class: 'N' },
    { word: 'traversable', word_class: 'AJ' }
  ],
  daring: [
    { word: 'dare', word_class: 'N' },
    { word: 'dare', word_class: 'V' },
    { word: 'daring', word_class: 'N' },
    { word: 'daring', word_class: 'AJ' }
  ],
  weave: [
    { word: 'weave', word_class: 'N' },
    { word: 'weave', word_class: 'V' },
    { word: 'woven', word_class: 'AJ' },
    { word: 'weaver', word_class: 'N' },
    { word: 'weaving', word_class: 'N' },
    { word: 'weaving', word_class: 'AJ' }
  ],
  dimness: [
    { word: 'dim', word_class: 'V' },
    { word: 'dim', word_class: 'AJ' },
    { word: 'dimer', word_class: 'N' },
    { word: 'dimmed', word_class: 'AJ' },
    { word: 'dimness', word_class: 'N' }
  ],
  stridden: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  amyloidal: [
    { word: 'amyloid', word_class: 'N' },
    { word: 'amyloid', word_class: 'AJ' },
    { word: 'amyloidal', word_class: 'AJ' }
  ],
  defoliated: [
    { word: 'defoliant', word_class: 'N' },
    { word: 'defoliate', word_class: 'V' },
    { word: 'defoliate', word_class: 'AJ' },
    { word: 'defoliator', word_class: 'N' },
    { word: 'defoliated', word_class: 'AJ' },
    { word: 'defoliation', word_class: 'N' }
  ],
  perk: [{ word: 'perk', word_class: 'N' }, { word: 'perk', word_class: 'V' }, { word: 'perked', word_class: 'AJ' }],
  appellation: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  departer: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  appellative: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  sublimely: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  shriven: [
    { word: 'shrift', word_class: 'N' },
    { word: 'shrive', word_class: 'V' },
    { word: 'shriven', word_class: 'AJ' }
  ],
  services: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  illume: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  spillage: [
    { word: 'spill', word_class: 'N' },
    { word: 'spill', word_class: 'V' },
    { word: 'spilth', word_class: 'N' },
    { word: 'spillage', word_class: 'N' }
  ],
  mimicker: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  synchroneity: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  intellectually: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  shingle: [
    { word: 'shingle', word_class: 'N' },
    { word: 'shingle', word_class: 'V' },
    { word: 'shingles', word_class: 'N' },
    { word: 'shingling', word_class: 'N' }
  ],
  subordinative: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  verticil: [
    { word: 'verticil', word_class: 'N' },
    { word: 'verticillate', word_class: 'AJ' },
    { word: 'verticillated', word_class: 'AJ' }
  ],
  reader: [
    { word: 'read', word_class: 'N' },
    { word: 'read', word_class: 'V' },
    { word: 'read', word_class: 'AJ' },
    { word: 'reader', word_class: 'N' },
    { word: 'reading', word_class: 'N' }
  ],
  model: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  sovietise: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  calligraph: [
    { word: 'calligraph', word_class: 'N' },
    { word: 'calligrapher', word_class: 'N' },
    { word: 'calligraphic', word_class: 'AJ' },
    { word: 'calligraphical', word_class: 'AJ' }
  ],
  logic: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  pardoner: [
    { word: 'pardon', word_class: 'N' },
    { word: 'pardon', word_class: 'V' },
    { word: 'pardoner', word_class: 'N' },
    { word: 'pardonable', word_class: 'AJ' },
    { word: 'pardonably', word_class: 'AV' }
  ],
  astrophysicist: [
    { word: 'astrophysics', word_class: 'N' },
    { word: 'astrophysical', word_class: 'AJ' },
    { word: 'astrophysicist', word_class: 'N' }
  ],
  dirty: [
    { word: 'dirty', word_class: 'V' },
    { word: 'dirty', word_class: 'AJ' },
    { word: 'dirtying', word_class: 'N' },
    { word: 'dirtiness', word_class: 'N' }
  ],
  methodization: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  road: [{ word: 'road', word_class: 'N' }, { word: 'road', word_class: 'AJ' }, { word: 'roads', word_class: 'N' }],
  forestall: [
    { word: 'forestall', word_class: 'V' },
    { word: 'forestalling', word_class: 'N' },
    { word: 'forestalment', word_class: 'N' },
    { word: 'forestallment', word_class: 'N' }
  ],
  colorful: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  injection: [
    { word: 'inject', word_class: 'V' },
    { word: 'injectant', word_class: 'N' },
    { word: 'injection', word_class: 'N' },
    { word: 'injectable', word_class: 'AJ' }
  ],
  satyric: [
    { word: 'satyr', word_class: 'N' },
    { word: 'satyric', word_class: 'AJ' },
    { word: 'satyrical', word_class: 'AJ' }
  ],
  dunk: [
    { word: 'dunk', word_class: 'N' },
    { word: 'dunk', word_class: 'V' },
    { word: 'dunker', word_class: 'N' },
    { word: 'dunked', word_class: 'AJ' },
    { word: 'dunkers', word_class: 'N' }
  ],
  partial: [
    { word: 'partial', word_class: 'AJ' },
    { word: 'partiality', word_class: 'N' },
    { word: 'partially', word_class: 'AV' },
    { word: 'partialness', word_class: 'N' }
  ],
  nestle: [
    { word: 'nestle', word_class: 'N' },
    { word: 'nestle', word_class: 'V' },
    { word: 'nestled', word_class: 'AJ' },
    { word: 'nestling', word_class: 'N' }
  ],
  functionary: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  levis: [
    { word: 'levi', word_class: 'N' },
    { word: 'levy', word_class: 'N' },
    { word: 'levy', word_class: 'V' },
    { word: 'levis', word_class: 'N' }
  ],
  educate: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  exceptionable: [
    { word: 'except', word_class: 'V' },
    { word: 'exception', word_class: 'N' },
    { word: 'exceptional', word_class: 'AJ' },
    { word: 'exceptionable', word_class: 'AJ' },
    { word: 'exceptionally', word_class: 'AV' }
  ],
  testate: [
    { word: 'testacy', word_class: 'N' },
    { word: 'testate', word_class: 'N' },
    { word: 'testate', word_class: 'AJ' },
    { word: 'testator', word_class: 'N' }
  ],
  ear: [
    { word: 'ear', word_class: 'N' },
    { word: 'eared', word_class: 'AJ' },
    { word: 'earful', word_class: 'N' },
    { word: 'earful', word_class: 'AJ' },
    { word: 'earring', word_class: 'N' }
  ],
  imprecise: [
    { word: 'imprecise', word_class: 'AJ' },
    { word: 'imprecision', word_class: 'N' },
    { word: 'imprecisely', word_class: 'AV' },
    { word: 'impreciseness', word_class: 'N' }
  ],
  systematisation: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  bravely: [
    { word: 'brave', word_class: 'V' },
    { word: 'brave', word_class: 'N' },
    { word: 'brave', word_class: 'AJ' },
    { word: 'bravery', word_class: 'N' },
    { word: 'braving', word_class: 'N' },
    { word: 'bravely', word_class: 'AV' },
    { word: 'bravery', word_class: 'AJ' }
  ],
  sectionalism: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  disenchantment: [
    { word: 'disenchant', word_class: 'V' },
    { word: 'disenchanted', word_class: 'AJ' },
    { word: 'disenchanting', word_class: 'AJ' },
    { word: 'disenchantment', word_class: 'N' }
  ],
  threat: [
    { word: 'threat', word_class: 'N' },
    { word: 'threaten', word_class: 'V' },
    { word: 'threatened', word_class: 'AJ' },
    { word: 'threatening', word_class: 'AJ' }
  ],
  protracted: [
    { word: 'protract', word_class: 'N' },
    { word: 'protract', word_class: 'V' },
    { word: 'protractor', word_class: 'N' },
    { word: 'protracted', word_class: 'AJ' },
    { word: 'protraction', word_class: 'N' },
    { word: 'protractible', word_class: 'AJ' }
  ],
  vagus: [
    { word: 'vagus', word_class: 'N' },
    { word: 'vague', word_class: 'AJ' },
    { word: 'vaguely', word_class: 'AV' },
    { word: 'vagueness', word_class: 'N' }
  ],
  iodise: [
    { word: 'iodise', word_class: 'V' },
    { word: 'iodize', word_class: 'V' },
    { word: 'iodized', word_class: 'AJ' },
    { word: 'iodisation', word_class: 'N' },
    { word: 'iodization', word_class: 'N' }
  ],
  pasted: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  attribution: [
    { word: 'attribute', word_class: 'N' },
    { word: 'attribute', word_class: 'V' },
    { word: 'attribution', word_class: 'N' },
    { word: 'attributive', word_class: 'AJ' },
    { word: 'attributable', word_class: 'AJ' },
    { word: 'attributively', word_class: 'AV' }
  ],
  lancers: [
    { word: 'lance', word_class: 'N' },
    { word: 'lance', word_class: 'V' },
    { word: 'lancer', word_class: 'N' },
    { word: 'lancers', word_class: 'N' }
  ],
  repulsiveness: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  begging: [
    { word: 'beg', word_class: 'V' },
    { word: 'beggar', word_class: 'N' },
    { word: 'beggar', word_class: 'V' },
    { word: 'beggary', word_class: 'N' },
    { word: 'begging', word_class: 'N' },
    { word: 'beggary', word_class: 'AJ' },
    { word: 'beggarman', word_class: 'N' }
  ],
  juicy: [
    { word: 'juice', word_class: 'N' },
    { word: 'juice', word_class: 'V' },
    { word: 'juicer', word_class: 'N' },
    { word: 'juicy', word_class: 'AJ' },
    { word: 'juiciness', word_class: 'N' }
  ],
  defrayment: [
    { word: 'defray', word_class: 'V' },
    { word: 'defrayal', word_class: 'N' },
    { word: 'defrayment', word_class: 'N' }
  ],
  journeyer: [
    { word: 'journey', word_class: 'N' },
    { word: 'journey', word_class: 'V' },
    { word: 'journeyer', word_class: 'N' },
    { word: 'journeying', word_class: 'N' },
    { word: 'journeyman', word_class: 'N' }
  ],
  commentation: [
    { word: 'comment', word_class: 'N' },
    { word: 'comment', word_class: 'V' },
    { word: 'commentary', word_class: 'N' },
    { word: 'commentate', word_class: 'V' },
    { word: 'commentator', word_class: 'N' },
    { word: 'commentation', word_class: 'N' }
  ],
  taxonomically: [
    { word: 'taxonomic', word_class: 'AJ' },
    { word: 'taxonomical', word_class: 'AJ' },
    { word: 'taxonomically', word_class: 'AV' }
  ],
  credentials: [
    { word: 'credential', word_class: 'N' },
    { word: 'credentials', word_class: 'N' },
    { word: 'credentialed', word_class: 'AJ' }
  ],
  container: [
    { word: 'contain', word_class: 'V' },
    { word: 'container', word_class: 'N' },
    { word: 'contained', word_class: 'AJ' },
    { word: 'containment', word_class: 'N' },
    { word: 'containerful', word_class: 'N' }
  ],
  prepossession: [
    { word: 'prepossess', word_class: 'V' },
    { word: 'prepossession', word_class: 'N' },
    { word: 'prepossessing', word_class: 'AJ' }
  ],
  polemically: [
    { word: 'polemic', word_class: 'N' },
    { word: 'polemic', word_class: 'AJ' },
    { word: 'polemize', word_class: 'V' },
    { word: 'polemical', word_class: 'AJ' },
    { word: 'polemically', word_class: 'AV' }
  ],
  endowed: [
    { word: 'endow', word_class: 'V' },
    { word: 'endowed', word_class: 'AJ' },
    { word: 'endowment', word_class: 'N' }
  ],
  kindle: [
    { word: 'kindle', word_class: 'V' },
    { word: 'kindled', word_class: 'AJ' },
    { word: 'kindling', word_class: 'N' }
  ],
  fate: [
    { word: 'fate', word_class: 'N' },
    { word: 'fate', word_class: 'V' },
    { word: 'fates', word_class: 'N' },
    { word: 'fated', word_class: 'AJ' },
    { word: 'fateful', word_class: 'AJ' }
  ],
  scurrying: [
    { word: 'scurry', word_class: 'N' },
    { word: 'scurry', word_class: 'V' },
    { word: 'scurrying', word_class: 'AJ' }
  ],
  tilling: [
    { word: 'till', word_class: 'V' },
    { word: 'till', word_class: 'N' },
    { word: 'tiller', word_class: 'N' },
    { word: 'tiller', word_class: 'V' },
    { word: 'tillage', word_class: 'N' },
    { word: 'tilled', word_class: 'AJ' },
    { word: 'tilling', word_class: 'N' }
  ],
  paralyze: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  demobilisation: [
    { word: 'demobilise', word_class: 'V' },
    { word: 'demobilize', word_class: 'V' },
    { word: 'demobilisation', word_class: 'N' },
    { word: 'demobilization', word_class: 'N' }
  ],
  physiologically: [
    { word: 'physiology', word_class: 'N' },
    { word: 'physiologic', word_class: 'AJ' },
    { word: 'physiological', word_class: 'AJ' },
    { word: 'physiologically', word_class: 'AV' }
  ],
  whisk: [
    { word: 'whisk', word_class: 'N' },
    { word: 'whisk', word_class: 'V' },
    { word: 'whisker', word_class: 'N' },
    { word: 'whisker', word_class: 'V' },
    { word: 'whiskers', word_class: 'N' },
    { word: 'whiskered', word_class: 'AJ' }
  ],
  ornate: [
    { word: 'ornate', word_class: 'AJ' },
    { word: 'ornately', word_class: 'AV' },
    { word: 'ornateness', word_class: 'N' }
  ],
  stops: [
    { word: 'stop', word_class: 'N' },
    { word: 'stop', word_class: 'V' },
    { word: 'stops', word_class: 'N' },
    { word: 'stoppage', word_class: 'N' },
    { word: 'stopped', word_class: 'AJ' },
    { word: 'stopping', word_class: 'N' }
  ],
  retiring: [
    { word: 'retire', word_class: 'N' },
    { word: 'retire', word_class: 'V' },
    { word: 'retiree', word_class: 'N' },
    { word: 'retired', word_class: 'AJ' },
    { word: 'retiring', word_class: 'AJ' },
    { word: 'retirement', word_class: 'N' }
  ],
  intellectualization: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  prestigiousness: [
    { word: 'prestige', word_class: 'N' },
    { word: 'prestigious', word_class: 'AJ' },
    { word: 'prestigiousness', word_class: 'N' }
  ],
  shrinking: [
    { word: 'shrink', word_class: 'N' },
    { word: 'shrink', word_class: 'V' },
    { word: 'shrunk', word_class: 'AJ' },
    { word: 'shrinkage', word_class: 'N' },
    { word: 'shrinking', word_class: 'N' },
    { word: 'shrunken', word_class: 'AJ' }
  ],
  stalls: [
    { word: 'stall', word_class: 'N' },
    { word: 'stall', word_class: 'V' },
    { word: 'stalls', word_class: 'N' },
    { word: 'stalling', word_class: 'N' },
    { word: 'stallion', word_class: 'N' }
  ],
  chicane: [
    { word: 'chicane', word_class: 'N' },
    { word: 'chicane', word_class: 'V' },
    { word: 'chicanery', word_class: 'N' }
  ],
  tire: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  bicycle: [
    { word: 'bicycle', word_class: 'N' },
    { word: 'bicycle', word_class: 'V' },
    { word: 'bicyclic', word_class: 'AJ' },
    { word: 'bicycling', word_class: 'N' },
    { word: 'bicyclist', word_class: 'N' }
  ],
  abort: [
    { word: 'abort', word_class: 'V' },
    { word: 'abortion', word_class: 'N' },
    { word: 'abortive', word_class: 'AJ' },
    { word: 'abortively', word_class: 'AV' }
  ],
  synergism: [
    { word: 'synergy', word_class: 'N' },
    { word: 'synergism', word_class: 'N' },
    { word: 'synergist', word_class: 'N' },
    { word: 'synergistic', word_class: 'AJ' }
  ],
  discipline: [
    { word: 'discipline', word_class: 'N' },
    { word: 'discipline', word_class: 'V' },
    { word: 'disciplinal', word_class: 'AJ' },
    { word: 'disciplined', word_class: 'AJ' },
    { word: 'disciplinary', word_class: 'AJ' }
  ],
  temptress: [
    { word: 'tempt', word_class: 'V' },
    { word: 'tempter', word_class: 'N' },
    { word: 'tempting', word_class: 'AJ' },
    { word: 'temptress', word_class: 'N' },
    { word: 'temptation', word_class: 'N' }
  ],
  liquify: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  substantival: [
    { word: 'substantival', word_class: 'AJ' },
    { word: 'substantivise', word_class: 'V' },
    { word: 'substantivize', word_class: 'V' },
    { word: 'substantivisation', word_class: 'N' },
    { word: 'substantivization', word_class: 'N' }
  ],
  ascending: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  bowing: [
    { word: 'bow', word_class: 'N' },
    { word: 'bow', word_class: 'V' },
    { word: 'bow', word_class: 'AJ' },
    { word: 'bower', word_class: 'N' },
    { word: 'bower', word_class: 'V' },
    { word: 'bowed', word_class: 'AJ' },
    { word: 'bowman', word_class: 'N' },
    { word: 'bowing', word_class: 'N' },
    { word: 'bowing', word_class: 'AJ' }
  ],
  groping: [
    { word: 'grope', word_class: 'N' },
    { word: 'grope', word_class: 'V' },
    { word: 'groping', word_class: 'AJ' }
  ],
  investigation: [
    { word: 'investigate', word_class: 'V' },
    { word: 'investigator', word_class: 'N' },
    { word: 'investigating', word_class: 'N' },
    { word: 'investigation', word_class: 'N' },
    { word: 'investigative', word_class: 'AJ' }
  ],
  saleable: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  canton: [
    { word: 'canton', word_class: 'N' },
    { word: 'canton', word_class: 'V' },
    { word: 'cantonal', word_class: 'AJ' },
    { word: 'cantonment', word_class: 'N' }
  ],
  plenteous: [
    { word: 'plenty', word_class: 'N' },
    { word: 'plenty', word_class: 'AJ' },
    { word: 'plenty', word_class: 'AV' },
    { word: 'plenteous', word_class: 'AJ' },
    { word: 'plentiful', word_class: 'AJ' },
    { word: 'plentifulness', word_class: 'N' }
  ],
  wilted: [
    { word: 'wilt', word_class: 'N' },
    { word: 'wilt', word_class: 'V' },
    { word: 'wilted', word_class: 'AJ' },
    { word: 'wilting', word_class: 'N' }
  ],
  penni: [{ word: 'penni', word_class: 'N' }, { word: 'penny', word_class: 'N' }, { word: 'penny', word_class: 'AJ' }],
  geomorphological: [
    { word: 'geomorphology', word_class: 'N' },
    { word: 'geomorphologic', word_class: 'AJ' },
    { word: 'geomorphological', word_class: 'AJ' }
  ],
  regimentally: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  embarrassing: [
    { word: 'embarrass', word_class: 'V' },
    { word: 'embarrassed', word_class: 'AJ' },
    { word: 'embarrassing', word_class: 'AJ' },
    { word: 'embarrassment', word_class: 'N' }
  ],
  clutter: [
    { word: 'clutter', word_class: 'N' },
    { word: 'clutter', word_class: 'V' },
    { word: 'cluttered', word_class: 'AJ' }
  ],
  scrambler: [
    { word: 'scramble', word_class: 'N' },
    { word: 'scramble', word_class: 'V' },
    { word: 'scrambler', word_class: 'N' },
    { word: 'scrambled', word_class: 'AJ' }
  ],
  debater: [
    { word: 'debate', word_class: 'N' },
    { word: 'debate', word_class: 'V' },
    { word: 'debater', word_class: 'N' },
    { word: 'debatable', word_class: 'AJ' }
  ],
  subnormality: [
    { word: 'subnormal', word_class: 'N' },
    { word: 'subnormal', word_class: 'AJ' },
    { word: 'subnormality', word_class: 'N' }
  ],
  hostile: [
    { word: 'hostile', word_class: 'AJ' },
    { word: 'hostility', word_class: 'N' },
    { word: 'hostilely', word_class: 'AV' }
  ],
  chasteness: [
    { word: 'chaste', word_class: 'AJ' },
    { word: 'chastely', word_class: 'AV' },
    { word: 'chasteness', word_class: 'N' }
  ],
  constrained: [
    { word: 'constrain', word_class: 'V' },
    { word: 'constraint', word_class: 'N' },
    { word: 'constrained', word_class: 'AJ' },
    { word: 'constraining', word_class: 'AJ' }
  ],
  paralysation: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  exhilaration: [
    { word: 'exhilarate', word_class: 'V' },
    { word: 'exhilarated', word_class: 'AJ' },
    { word: 'exhilaration', word_class: 'N' },
    { word: 'exhilarating', word_class: 'AJ' }
  ],
  sedateness: [
    { word: 'sedate', word_class: 'V' },
    { word: 'sedate', word_class: 'AJ' },
    { word: 'sedated', word_class: 'AJ' },
    { word: 'sedation', word_class: 'N' },
    { word: 'sedately', word_class: 'AV' },
    { word: 'sedateness', word_class: 'N' }
  ],
  quarrying: [
    { word: 'quarry', word_class: 'N' },
    { word: 'quarry', word_class: 'V' },
    { word: 'quarrier', word_class: 'N' },
    { word: 'quarrying', word_class: 'N' },
    { word: 'quarryman', word_class: 'N' }
  ],
  regulation: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  conical: [
    { word: 'conic', word_class: 'N' },
    { word: 'conic', word_class: 'AJ' },
    { word: 'conical', word_class: 'AJ' },
    { word: 'conically', word_class: 'AV' }
  ],
  leveller: [
    { word: 'level', word_class: 'N' },
    { word: 'level', word_class: 'V' },
    { word: 'level', word_class: 'AJ' },
    { word: 'leveler', word_class: 'N' },
    { word: 'leveling', word_class: 'N' },
    { word: 'leveller', word_class: 'N' }
  ],
  alchemistic: [
    { word: 'alchemist', word_class: 'N' },
    { word: 'alchemistic', word_class: 'AJ' },
    { word: 'alchemistical', word_class: 'AJ' }
  ],
  caffein: [
    { word: 'caffein', word_class: 'N' },
    { word: 'caffeine', word_class: 'N' },
    { word: 'caffeinic', word_class: 'AJ' },
    { word: 'caffeinism', word_class: 'N' }
  ],
  navigator: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  oversimplification: [
    { word: 'oversimplify', word_class: 'V' },
    { word: 'oversimplified', word_class: 'AJ' },
    { word: 'oversimplification', word_class: 'N' }
  ],
  partitive: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  reconnoiter: [
    { word: 'reconnoiter', word_class: 'V' },
    { word: 'reconnoitre', word_class: 'V' },
    { word: 'reconnaissance', word_class: 'N' },
    { word: 'reconnoitering', word_class: 'N' }
  ],
  cylindric: [
    { word: 'cylinder', word_class: 'N' },
    { word: 'cylindric', word_class: 'AJ' },
    { word: 'cylindrical', word_class: 'AJ' },
    { word: 'cylindricality', word_class: 'N' }
  ],
  conveyor: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  'co-ordinate': [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  wedge: [{ word: 'wedge', word_class: 'N' }, { word: 'wedge', word_class: 'V' }, { word: 'wedged', word_class: 'AJ' }],
  uniting: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  insult: [
    { word: 'insult', word_class: 'N' },
    { word: 'insult', word_class: 'V' },
    { word: 'insulted', word_class: 'AJ' },
    { word: 'insulting', word_class: 'AJ' }
  ],
  flagellate: [
    { word: 'flagellant', word_class: 'N' },
    { word: 'flagellate', word_class: 'N' },
    { word: 'flagellate', word_class: 'V' },
    { word: 'flagellate', word_class: 'AJ' },
    { word: 'flagellated', word_class: 'AJ' },
    { word: 'flagellation', word_class: 'N' }
  ],
  fancier: [
    { word: 'fancy', word_class: 'N' },
    { word: 'fancy', word_class: 'V' },
    { word: 'fancy', word_class: 'AJ' },
    { word: 'fancier', word_class: 'N' },
    { word: 'fancied', word_class: 'AJ' },
    { word: 'fanciful', word_class: 'AJ' }
  ],
  rein: [{ word: 'rein', word_class: 'N' }, { word: 'rein', word_class: 'V' }, { word: 'reins', word_class: 'N' }],
  sociobiology: [
    { word: 'sociobiology', word_class: 'N' },
    { word: 'sociobiologic', word_class: 'AJ' },
    { word: 'sociobiological', word_class: 'AJ' },
    { word: 'sociobiologically', word_class: 'AV' }
  ],
  grille: [
    { word: 'grill', word_class: 'N' },
    { word: 'grill', word_class: 'V' },
    { word: 'grille', word_class: 'N' },
    { word: 'grilled', word_class: 'AJ' },
    { word: 'grilling', word_class: 'N' }
  ],
  rebuked: [
    { word: 'rebuke', word_class: 'N' },
    { word: 'rebuke', word_class: 'V' },
    { word: 'rebuked', word_class: 'AJ' }
  ],
  batter: [
    { word: 'batter', word_class: 'N' },
    { word: 'batter', word_class: 'V' },
    { word: 'battery', word_class: 'N' },
    { word: 'battery', word_class: 'AJ' },
    { word: 'battered', word_class: 'AJ' },
    { word: 'battering', word_class: 'N' }
  ],
  dauntless: [
    { word: 'daunt', word_class: 'V' },
    { word: 'daunted', word_class: 'AJ' },
    { word: 'daunting', word_class: 'AJ' },
    { word: 'dauntless', word_class: 'AJ' },
    { word: 'dauntlessness', word_class: 'N' }
  ],
  invading: [
    { word: 'invade', word_class: 'V' },
    { word: 'invader', word_class: 'N' },
    { word: 'invasion', word_class: 'N' },
    { word: 'invading', word_class: 'AJ' },
    { word: 'invasive', word_class: 'AJ' }
  ],
  overjoyed: [
    { word: 'overjoy', word_class: 'N' },
    { word: 'overjoy', word_class: 'V' },
    { word: 'overjoyed', word_class: 'AJ' }
  ],
  proscriptive: [
    { word: 'proscribe', word_class: 'N' },
    { word: 'proscribe', word_class: 'V' },
    { word: 'proscribed', word_class: 'AJ' },
    { word: 'proscription', word_class: 'N' },
    { word: 'proscriptive', word_class: 'AJ' }
  ],
  contrary: [
    { word: 'contrary', word_class: 'N' },
    { word: 'contrary', word_class: 'AJ' },
    { word: 'contrariness', word_class: 'N' },
    { word: 'contrarious', word_class: 'AJ' },
    { word: 'contrariwise', word_class: 'AV' }
  ],
  controversially: [
    { word: 'controvert', word_class: 'V' },
    { word: 'controversy', word_class: 'N' },
    { word: 'controversion', word_class: 'N' },
    { word: 'controversial', word_class: 'AJ' },
    { word: 'controversially', word_class: 'AV' }
  ],
  stridence: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  pinnated: [
    { word: 'pinnate', word_class: 'AJ' },
    { word: 'pinnated', word_class: 'AJ' },
    { word: 'pinnately', word_class: 'AV' }
  ],
  weld: [
    { word: 'weld', word_class: 'N' },
    { word: 'weld', word_class: 'V' },
    { word: 'welder', word_class: 'N' },
    { word: 'welding', word_class: 'N' },
    { word: 'weldment', word_class: 'N' }
  ],
  babbling: [
    { word: 'babble', word_class: 'N' },
    { word: 'babble', word_class: 'V' },
    { word: 'babbler', word_class: 'N' },
    { word: 'babbling', word_class: 'N' },
    { word: 'babbling', word_class: 'AJ' },
    { word: 'babblement', word_class: 'N' }
  ],
  seduce: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  shuddering: [
    { word: 'shudder', word_class: 'N' },
    { word: 'shudder', word_class: 'V' },
    { word: 'shuddering', word_class: 'AJ' }
  ],
  'pitter-patter': [
    { word: 'pitter-patter', word_class: 'N' },
    { word: 'pitter-patter', word_class: 'V' },
    { word: 'pitter-patter', word_class: 'AV' }
  ],
  wiggle: [
    { word: 'wiggle', word_class: 'N' },
    { word: 'wiggle', word_class: 'V' },
    { word: 'wiggler', word_class: 'N' },
    { word: 'wiggly', word_class: 'AJ' },
    { word: 'wiggling', word_class: 'AJ' },
    { word: 'wiggliness', word_class: 'N' }
  ],
  hanging: [
    { word: 'hang', word_class: 'N' },
    { word: 'hang', word_class: 'V' },
    { word: 'hanger', word_class: 'N' },
    { word: 'hanging', word_class: 'N' },
    { word: 'hanging', word_class: 'V' },
    { word: 'hangman', word_class: 'N' },
    { word: 'hanging', word_class: 'AJ' }
  ],
  moderate: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  reckoning: [
    { word: 'reckon', word_class: 'V' },
    { word: 'reckoner', word_class: 'N' },
    { word: 'reckoning', word_class: 'N' }
  ],
  dynamical: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  prestidigitation: [
    { word: 'prestidigitate', word_class: 'V' },
    { word: 'prestidigitator', word_class: 'N' },
    { word: 'prestidigitation', word_class: 'N' }
  ],
  whiskered: [
    { word: 'whisk', word_class: 'N' },
    { word: 'whisk', word_class: 'V' },
    { word: 'whisker', word_class: 'N' },
    { word: 'whisker', word_class: 'V' },
    { word: 'whiskers', word_class: 'N' },
    { word: 'whiskered', word_class: 'AJ' }
  ],
  phenotypical: [
    { word: 'phenotype', word_class: 'N' },
    { word: 'phenotypic', word_class: 'AJ' },
    { word: 'phenotypical', word_class: 'AJ' }
  ],
  prescribe: [
    { word: 'prescribe', word_class: 'N' },
    { word: 'prescribe', word_class: 'V' },
    { word: 'prescript', word_class: 'N' },
    { word: 'prescript', word_class: 'V' },
    { word: 'prescript', word_class: 'AJ' },
    { word: 'prescribed', word_class: 'AJ' },
    { word: 'prescription', word_class: 'N' },
    { word: 'prescription', word_class: 'AJ' },
    { word: 'prescriptive', word_class: 'AJ' }
  ],
  providentially: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  directorate: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  jarring: [
    { word: 'jar', word_class: 'N' },
    { word: 'jar', word_class: 'V' },
    { word: 'jarful', word_class: 'N' },
    { word: 'jarful', word_class: 'AJ' },
    { word: 'jarring', word_class: 'AJ' }
  ],
  presumptive: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  attire: [
    { word: 'attire', word_class: 'N' },
    { word: 'attire', word_class: 'V' },
    { word: 'attired', word_class: 'AJ' }
  ],
  desensitizing: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  regimented: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  lawfulness: [
    { word: 'law', word_class: 'N' },
    { word: 'laws', word_class: 'N' },
    { word: 'lawful', word_class: 'AJ' },
    { word: 'lawfulness', word_class: 'N' }
  ],
  disorder: [
    { word: 'disorder', word_class: 'N' },
    { word: 'disorder', word_class: 'V' },
    { word: 'disordered', word_class: 'AJ' }
  ],
  alleviated: [
    { word: 'alleviate', word_class: 'V' },
    { word: 'alleviated', word_class: 'AJ' },
    { word: 'alleviation', word_class: 'N' },
    { word: 'alleviative', word_class: 'AJ' }
  ],
  diverging: [
    { word: 'diverge', word_class: 'N' },
    { word: 'diverge', word_class: 'V' },
    { word: 'divergence', word_class: 'N' },
    { word: 'divergency', word_class: 'N' },
    { word: 'divergent', word_class: 'AJ' },
    { word: 'diverging', word_class: 'AJ' }
  ],
  belief: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  refinery: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  deliberation: [
    { word: 'deliberate', word_class: 'V' },
    { word: 'deliberate', word_class: 'AJ' },
    { word: 'deliberation', word_class: 'N' },
    { word: 'deliberately', word_class: 'AV' },
    { word: 'deliberateness', word_class: 'N' }
  ],
  hysterectomize: [
    { word: 'hysterectomy', word_class: 'N' },
    { word: 'hysterectomise', word_class: 'V' },
    { word: 'hysterectomize', word_class: 'V' }
  ],
  exaggerate: [
    { word: 'exaggerate', word_class: 'V' },
    { word: 'exaggerated', word_class: 'AJ' },
    { word: 'exaggeration', word_class: 'N' }
  ],
  venturesomeness: [
    { word: 'venture', word_class: 'N' },
    { word: 'venture', word_class: 'V' },
    { word: 'venturous', word_class: 'AJ' },
    { word: 'venturesome', word_class: 'AJ' },
    { word: 'venturesomeness', word_class: 'N' }
  ],
  emulously: [
    { word: 'emulate', word_class: 'V' },
    { word: 'emulous', word_class: 'AJ' },
    { word: 'emulation', word_class: 'N' },
    { word: 'emulously', word_class: 'AV' }
  ],
  reservation: [
    { word: 'reserve', word_class: 'N' },
    { word: 'reserve', word_class: 'V' },
    { word: 'reserve', word_class: 'AJ' },
    { word: 'reserves', word_class: 'N' },
    { word: 'reserved', word_class: 'AJ' },
    { word: 'reservist', word_class: 'N' },
    { word: 'reservation', word_class: 'N' }
  ],
  cheek: [
    { word: 'cheek', word_class: 'N' },
    { word: 'cheek', word_class: 'V' },
    { word: 'cheek', word_class: 'AJ' },
    { word: 'cheeked', word_class: 'AJ' },
    { word: 'cheekful', word_class: 'N' },
    { word: 'cheekful', word_class: 'AJ' }
  ],
  somersault: [
    { word: 'somersault', word_class: 'N' },
    { word: 'somersault', word_class: 'V' },
    { word: 'somersaulting', word_class: 'N' }
  ],
  teen: [{ word: 'teen', word_class: 'N' }, { word: 'teen', word_class: 'AJ' }, { word: 'teens', word_class: 'N' }],
  tally: [
    { word: 'tally', word_class: 'N' },
    { word: 'tally', word_class: 'V' },
    { word: 'tallyman', word_class: 'N' }
  ],
  fracture: [
    { word: 'fracture', word_class: 'N' },
    { word: 'fracture', word_class: 'V' },
    { word: 'fractured', word_class: 'AJ' }
  ],
  ally: [
    { word: 'ally', word_class: 'N' },
    { word: 'ally', word_class: 'V' },
    { word: 'allis', word_class: 'N' },
    { word: 'allies', word_class: 'N' },
    { word: 'allied', word_class: 'AJ' },
    { word: 'alliance', word_class: 'N' }
  ],
  levy: [
    { word: 'levi', word_class: 'N' },
    { word: 'levy', word_class: 'N' },
    { word: 'levy', word_class: 'V' },
    { word: 'levis', word_class: 'N' }
  ],
  spaceman: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  murderous: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  impolite: [
    { word: 'impolite', word_class: 'AJ' },
    { word: 'impolitic', word_class: 'AJ' },
    { word: 'impolitely', word_class: 'AV' },
    { word: 'impoliteness', word_class: 'N' }
  ],
  excitable: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  departing: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  incautious: [
    { word: 'incautious', word_class: 'AJ' },
    { word: 'incautiously', word_class: 'AV' },
    { word: 'incautiousness', word_class: 'N' }
  ],
  intensifying: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  strew: [
    { word: 'strew', word_class: 'V' },
    { word: 'strewn', word_class: 'AJ' },
    { word: 'strewing', word_class: 'N' }
  ],
  somnambulate: [
    { word: 'somnambulate', word_class: 'V' },
    { word: 'somnambulism', word_class: 'N' },
    { word: 'somnambulance', word_class: 'N' },
    { word: 'somnambulation', word_class: 'N' }
  ],
  filmmaker: [
    { word: 'film', word_class: 'N' },
    { word: 'film', word_class: 'V' },
    { word: 'filmed', word_class: 'AJ' },
    { word: 'filming', word_class: 'N' },
    { word: 'filmmaker', word_class: 'N' },
    { word: 'filmmaking', word_class: 'N' }
  ],
  demural: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  buggery: [
    { word: 'bugger', word_class: 'N' },
    { word: 'bugger', word_class: 'V' },
    { word: 'buggery', word_class: 'N' }
  ],
  moneymaking: [
    { word: 'moneymaker', word_class: 'N' },
    { word: 'moneymaking', word_class: 'N' },
    { word: 'moneymaking', word_class: 'AJ' }
  ],
  invalid: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  laundry: [
    { word: 'launder', word_class: 'V' },
    { word: 'laundry', word_class: 'N' },
    { word: 'laundering', word_class: 'N' }
  ],
  sediment: [
    { word: 'sediment', word_class: 'N' },
    { word: 'sediment', word_class: 'V' },
    { word: 'sedimentary', word_class: 'AJ' },
    { word: 'sedimentation', word_class: 'N' }
  ],
  blare: [
    { word: 'blare', word_class: 'N' },
    { word: 'blare', word_class: 'V' },
    { word: 'blaring', word_class: 'N' },
    { word: 'blaring', word_class: 'AJ' }
  ],
  photosensitise: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  wassailer: [
    { word: 'wassail', word_class: 'N' },
    { word: 'wassail', word_class: 'V' },
    { word: 'wassailer', word_class: 'N' }
  ],
  temper: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  vanished: [
    { word: 'vanish', word_class: 'V' },
    { word: 'vanish', word_class: 'AJ' },
    { word: 'vanished', word_class: 'AJ' },
    { word: 'vanishing', word_class: 'N' },
    { word: 'vanishing', word_class: 'AJ' },
    { word: 'vanishment', word_class: 'N' }
  ],
  fancied: [
    { word: 'fancy', word_class: 'N' },
    { word: 'fancy', word_class: 'V' },
    { word: 'fancy', word_class: 'AJ' },
    { word: 'fancier', word_class: 'N' },
    { word: 'fancied', word_class: 'AJ' },
    { word: 'fanciful', word_class: 'AJ' }
  ],
  pacifist: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  sauce: [{ word: 'sauce', word_class: 'N' }, { word: 'sauce', word_class: 'V' }, { word: 'saucer', word_class: 'N' }],
  signalize: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  informative: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  translucence: [
    { word: 'translucence', word_class: 'N' },
    { word: 'translucency', word_class: 'N' },
    { word: 'translucent', word_class: 'AJ' }
  ],
  retrogradation: [
    { word: 'retrograde', word_class: 'N' },
    { word: 'retrograde', word_class: 'V' },
    { word: 'retrograde', word_class: 'AJ' },
    { word: 'retrogradation', word_class: 'N' }
  ],
  bookselling: [
    { word: 'booksell', word_class: 'V' },
    { word: 'bookseller', word_class: 'N' },
    { word: 'bookselling', word_class: 'N' }
  ],
  subtract: [
    { word: 'subtract', word_class: 'N' },
    { word: 'subtract', word_class: 'V' },
    { word: 'subtracter', word_class: 'N' },
    { word: 'subtracted', word_class: 'AJ' },
    { word: 'subtraction', word_class: 'N' },
    { word: 'subtractive', word_class: 'AJ' }
  ],
  dismissed: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  'self-respectful': [
    { word: 'self-respect', word_class: 'N' },
    { word: 'self-respectful', word_class: 'AJ' },
    { word: 'self-respecting', word_class: 'AJ' }
  ],
  argumentatively: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  envisioning: [
    { word: 'envision', word_class: 'V' },
    { word: 'envisioned', word_class: 'AJ' },
    { word: 'envisioning', word_class: 'N' }
  ],
  irrelevance: [
    { word: 'irrelevance', word_class: 'N' },
    { word: 'irrelevancy', word_class: 'N' },
    { word: 'irrelevant', word_class: 'AJ' }
  ],
  cinematography: [
    { word: 'cinematograph', word_class: 'V' },
    { word: 'cinematography', word_class: 'N' },
    { word: 'cinematographer', word_class: 'N' }
  ],
  unsociably: [
    { word: 'unsocial', word_class: 'AJ' },
    { word: 'unsociable', word_class: 'AJ' },
    { word: 'unsociably', word_class: 'AV' },
    { word: 'unsociability', word_class: 'N' },
    { word: 'unsociableness', word_class: 'N' }
  ],
  stacked: [
    { word: 'stack', word_class: 'N' },
    { word: 'stack', word_class: 'V' },
    { word: 'stacks', word_class: 'N' },
    { word: 'stacked', word_class: 'AJ' }
  ],
  disassociation: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  presumptuously: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  hypothetic: [
    { word: 'hypothetic', word_class: 'AJ' },
    { word: 'hypothetical', word_class: 'AJ' },
    { word: 'hypothetically', word_class: 'AV' }
  ],
  predictor: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  modifier: [
    { word: 'modify', word_class: 'V' },
    { word: 'modifier', word_class: 'N' },
    { word: 'modified', word_class: 'AJ' },
    { word: 'modifiable', word_class: 'AJ' },
    { word: 'modification', word_class: 'N' }
  ],
  sherardisation: [
    { word: 'sherardise', word_class: 'V' },
    { word: 'sherardize', word_class: 'V' },
    { word: 'sherardisation', word_class: 'N' },
    { word: 'sherardization', word_class: 'N' }
  ],
  idolization: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  dabbler: [
    { word: 'dabble', word_class: 'V' },
    { word: 'dabbler', word_class: 'N' },
    { word: 'dabbled', word_class: 'AJ' }
  ],
  communicant: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  incumbent: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  towel: [
    { word: 'towel', word_class: 'N' },
    { word: 'towel', word_class: 'V' },
    { word: 'toweling', word_class: 'N' },
    { word: 'towelling', word_class: 'N' }
  ],
  infernally: [
    { word: 'infernal', word_class: 'N' },
    { word: 'infernal', word_class: 'AJ' },
    { word: 'infernally', word_class: 'AV' }
  ],
  carnally: [
    { word: 'carnal', word_class: 'AJ' },
    { word: 'carnality', word_class: 'N' },
    { word: 'carnalize', word_class: 'V' },
    { word: 'carnally', word_class: 'AV' }
  ],
  superinducement: [
    { word: 'superinduce', word_class: 'V' },
    { word: 'superinduction', word_class: 'N' },
    { word: 'superinducement', word_class: 'N' }
  ],
  penned: [
    { word: 'pen', word_class: 'V' },
    { word: 'pen', word_class: 'N' },
    { word: 'penman', word_class: 'N' },
    { word: 'penance', word_class: 'N' },
    { word: 'penance', word_class: 'V' },
    { word: 'penned', word_class: 'AJ' },
    { word: 'penning', word_class: 'N' }
  ],
  denaturalization: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  spheroidal: [
    { word: 'sphere', word_class: 'N' },
    { word: 'spheroid', word_class: 'N' },
    { word: 'spheroid', word_class: 'AJ' },
    { word: 'spheroidal', word_class: 'AJ' }
  ],
  perturbing: [
    { word: 'perturb', word_class: 'V' },
    { word: 'perturbate', word_class: 'V' },
    { word: 'perturbed', word_class: 'AJ' },
    { word: 'perturbing', word_class: 'AJ' },
    { word: 'perturbation', word_class: 'N' }
  ],
  human: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  bemused: [
    { word: 'bemuse', word_class: 'V' },
    { word: 'bemused', word_class: 'AJ' },
    { word: 'bemusement', word_class: 'N' }
  ],
  leasehold: [
    { word: 'leasehold', word_class: 'N' },
    { word: 'leaseholder', word_class: 'N' },
    { word: 'leaseholding', word_class: 'N' }
  ],
  humble: [
    { word: 'humble', word_class: 'V' },
    { word: 'humble', word_class: 'AJ' },
    { word: 'humbly', word_class: 'AV' },
    { word: 'humbled', word_class: 'AJ' },
    { word: 'humbling', word_class: 'N' },
    { word: 'humbling', word_class: 'AJ' },
    { word: 'humbleness', word_class: 'N' }
  ],
  bountied: [
    { word: 'bounty', word_class: 'N' },
    { word: 'bountied', word_class: 'AJ' },
    { word: 'bounteous', word_class: 'AJ' },
    { word: 'bountiful', word_class: 'AJ' },
    { word: 'bountifulness', word_class: 'N' }
  ],
  nourishing: [
    { word: 'nourish', word_class: 'V' },
    { word: 'nourished', word_class: 'AJ' },
    { word: 'nourishing', word_class: 'N' },
    { word: 'nourishing', word_class: 'AJ' },
    { word: 'nourishment', word_class: 'N' }
  ],
  offended: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  fallacy: [
    { word: 'fallacy', word_class: 'N' },
    { word: 'fallacious', word_class: 'AJ' },
    { word: 'fallaciousness', word_class: 'N' }
  ],
  revert: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  atomisation: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  postponer: [
    { word: 'postpone', word_class: 'V' },
    { word: 'postponer', word_class: 'N' },
    { word: 'postponed', word_class: 'AJ' },
    { word: 'postponement', word_class: 'N' }
  ],
  propeller: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  changeableness: [
    { word: 'changeable', word_class: 'AJ' },
    { word: 'changeability', word_class: 'N' },
    { word: 'changeableness', word_class: 'N' }
  ],
  fictionalize: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  clinically: [
    { word: 'clinic', word_class: 'N' },
    { word: 'clinic', word_class: 'AJ' },
    { word: 'clinical', word_class: 'AJ' },
    { word: 'clinically', word_class: 'AV' }
  ],
  professional: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  southward: [
    { word: 'southward', word_class: 'AJ' },
    { word: 'southward', word_class: 'AV' },
    { word: 'southwards', word_class: 'AV' }
  ],
  levitical: [
    { word: 'levitate', word_class: 'V' },
    { word: 'levitation', word_class: 'N' },
    { word: 'levitical', word_class: 'AJ' }
  ],
  entireness: [
    { word: 'entire', word_class: 'N' },
    { word: 'entire', word_class: 'AJ' },
    { word: 'entirety', word_class: 'N' },
    { word: 'entirely', word_class: 'AV' },
    { word: 'entireness', word_class: 'N' }
  ],
  canvasser: [
    { word: 'canvass', word_class: 'N' },
    { word: 'canvass', word_class: 'V' },
    { word: 'canvasser', word_class: 'N' },
    { word: 'canvassing', word_class: 'N' }
  ],
  foreign: [
    { word: 'foreign', word_class: 'AJ' },
    { word: 'foreigner', word_class: 'N' },
    { word: 'foreignness', word_class: 'N' }
  ],
  dentist: [
    { word: 'dent', word_class: 'N' },
    { word: 'dent', word_class: 'V' },
    { word: 'dented', word_class: 'AJ' },
    { word: 'dentist', word_class: 'N' }
  ],
  modification: [
    { word: 'modify', word_class: 'V' },
    { word: 'modifier', word_class: 'N' },
    { word: 'modified', word_class: 'AJ' },
    { word: 'modifiable', word_class: 'AJ' },
    { word: 'modification', word_class: 'N' }
  ],
  incant: [
    { word: 'incant', word_class: 'N' },
    { word: 'incant', word_class: 'V' },
    { word: 'incantation', word_class: 'N' }
  ],
  shagged: [
    { word: 'shag', word_class: 'N' },
    { word: 'shag', word_class: 'V' },
    { word: 'shagged', word_class: 'AJ' },
    { word: 'shagging', word_class: 'N' }
  ],
  mythologize: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  kinesthetics: [
    { word: 'kinesthetic', word_class: 'AJ' },
    { word: 'kinesthetics', word_class: 'N' },
    { word: 'kinesthetically', word_class: 'AV' }
  ],
  lees: [{ word: 'lee', word_class: 'N' }, { word: 'lee', word_class: 'AJ' }, { word: 'lees', word_class: 'N' }],
  ecumenical: [
    { word: 'ecumenic', word_class: 'AJ' },
    { word: 'ecumenism', word_class: 'N' },
    { word: 'ecumenical', word_class: 'AJ' },
    { word: 'ecumenicalism', word_class: 'N' }
  ],
  stripy: [
    { word: 'stripe', word_class: 'N' },
    { word: 'stripe', word_class: 'V' },
    { word: 'stripes', word_class: 'N' },
    { word: 'stripy', word_class: 'AJ' },
    { word: 'striped', word_class: 'AJ' },
    { word: 'striping', word_class: 'N' }
  ],
  impalement: [
    { word: 'impale', word_class: 'V' },
    { word: 'impale', word_class: 'AJ' },
    { word: 'impalement', word_class: 'N' },
    { word: 'impalempale', word_class: 'V' },
    { word: 'impalempalement', word_class: 'N' }
  ],
  jacobinical: [
    { word: 'jacobin', word_class: 'N' },
    { word: 'jacobinic', word_class: 'AJ' },
    { word: 'jacobinism', word_class: 'N' },
    { word: 'jacobinical', word_class: 'AJ' }
  ],
  distinctive: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  indisposition: [
    { word: 'indispose', word_class: 'V' },
    { word: 'indisposed', word_class: 'AJ' },
    { word: 'indisposition', word_class: 'N' }
  ],
  matted: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  chipped: [
    { word: 'chip', word_class: 'N' },
    { word: 'chip', word_class: 'V' },
    { word: 'chipped', word_class: 'AJ' },
    { word: 'chipping', word_class: 'N' }
  ],
  pressurisation: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  slippery: [
    { word: 'slip', word_class: 'N' },
    { word: 'slip', word_class: 'V' },
    { word: 'slippage', word_class: 'N' },
    { word: 'slippery', word_class: 'AJ' },
    { word: 'slipping', word_class: 'AJ' },
    { word: 'slipperiness', word_class: 'N' }
  ],
  authentically: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  conserve: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  parasite: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  retina: [
    { word: 'retina', word_class: 'N' },
    { word: 'retinal', word_class: 'N' },
    { word: 'retinal', word_class: 'AJ' },
    { word: 'retinitis', word_class: 'N' }
  ],
  bearing: [
    { word: 'bear', word_class: 'N' },
    { word: 'bear', word_class: 'V' },
    { word: 'born', word_class: 'AJ' },
    { word: 'bearer', word_class: 'N' },
    { word: 'borne', word_class: 'AJ' },
    { word: 'bearing', word_class: 'N' },
    { word: 'bearing', word_class: 'AJ' }
  ],
  skepticism: [
    { word: 'skeptic', word_class: 'N' },
    { word: 'skeptical', word_class: 'AJ' },
    { word: 'skepticism', word_class: 'N' },
    { word: 'skeptically', word_class: 'AV' }
  ],
  dissociative: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  darkening: [
    { word: 'darken', word_class: 'V' },
    { word: 'darkened', word_class: 'AJ' },
    { word: 'darkening', word_class: 'N' },
    { word: 'darkening', word_class: 'AJ' }
  ],
  lacrimator: [
    { word: 'lacrimal', word_class: 'AJ' },
    { word: 'lacrimator', word_class: 'N' },
    { word: 'lacrimation', word_class: 'N' }
  ],
  publicizing: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  sternutative: [
    { word: 'sternutator', word_class: 'N' },
    { word: 'sternutation', word_class: 'N' },
    { word: 'sternutative', word_class: 'AJ' }
  ],
  plastic: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  propellent: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  suspension: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  fertilise: [
    { word: 'fertilise', word_class: 'V' },
    { word: 'fertilised', word_class: 'AJ' },
    { word: 'fertilisation', word_class: 'N' }
  ],
  sliminess: [
    { word: 'slime', word_class: 'N' },
    { word: 'slime', word_class: 'V' },
    { word: 'slimy', word_class: 'AJ' },
    { word: 'slimed', word_class: 'AJ' },
    { word: 'sliminess', word_class: 'N' }
  ],
  carp: [
    { word: 'carp', word_class: 'N' },
    { word: 'carp', word_class: 'V' },
    { word: 'carpal', word_class: 'N' },
    { word: 'carper', word_class: 'N' },
    { word: 'carping', word_class: 'N' },
    { word: 'carpal', word_class: 'AJ' },
    { word: 'carping', word_class: 'AJ' }
  ],
  humanity: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  stopper: [
    { word: 'stopper', word_class: 'N' },
    { word: 'stopper', word_class: 'V' },
    { word: 'stoppered', word_class: 'AJ' }
  ],
  ascendence: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  calory: [
    { word: 'calory', word_class: 'N' },
    { word: 'calorie', word_class: 'N' },
    { word: 'caloric', word_class: 'AJ' }
  ],
  inexpediently: [
    { word: 'inexpedience', word_class: 'N' },
    { word: 'inexpediency', word_class: 'N' },
    { word: 'inexpedient', word_class: 'AJ' },
    { word: 'inexpediently', word_class: 'AV' }
  ],
  permanence: [
    { word: 'permanent', word_class: 'N' },
    { word: 'permanence', word_class: 'N' },
    { word: 'permanency', word_class: 'N' },
    { word: 'permanent', word_class: 'AJ' },
    { word: 'permanently', word_class: 'AV' }
  ],
  apocopation: [
    { word: 'apocope', word_class: 'N' },
    { word: 'apocopate', word_class: 'V' },
    { word: 'apocopation', word_class: 'N' }
  ],
  ungracious: [
    { word: 'ungracious', word_class: 'AJ' },
    { word: 'ungraciously', word_class: 'AV' },
    { word: 'ungraciousness', word_class: 'N' }
  ],
  complexity: [
    { word: 'complex', word_class: 'N' },
    { word: 'complex', word_class: 'AJ' },
    { word: 'complexity', word_class: 'N' },
    { word: 'complexness', word_class: 'N' }
  ],
  stoppered: [
    { word: 'stopper', word_class: 'N' },
    { word: 'stopper', word_class: 'V' },
    { word: 'stoppered', word_class: 'AJ' }
  ],
  inadvisable: [
    { word: 'inadvisable', word_class: 'AJ' },
    { word: 'inadvisably', word_class: 'AV' },
    { word: 'inadvisability', word_class: 'N' }
  ],
  blast: [
    { word: 'blast', word_class: 'N' },
    { word: 'blast', word_class: 'V' },
    { word: 'blasted', word_class: 'AJ' },
    { word: 'blasting', word_class: 'AJ' }
  ],
  skittles: [
    { word: 'skittle', word_class: 'N' },
    { word: 'skittle', word_class: 'V' },
    { word: 'skittles', word_class: 'N' }
  ],
  perspiration: [
    { word: 'perspire', word_class: 'V' },
    { word: 'perspiring', word_class: 'N' },
    { word: 'perspiring', word_class: 'AJ' },
    { word: 'perspiration', word_class: 'N' }
  ],
  debauchery: [
    { word: 'debauch', word_class: 'N' },
    { word: 'debauch', word_class: 'V' },
    { word: 'debauchee', word_class: 'N' },
    { word: 'debaucher', word_class: 'N' },
    { word: 'debauched', word_class: 'AJ' },
    { word: 'debauchery', word_class: 'N' },
    { word: 'debauchery', word_class: 'AJ' }
  ],
  shave: [
    { word: 'shave', word_class: 'N' },
    { word: 'shave', word_class: 'V' },
    { word: 'shaver', word_class: 'N' },
    { word: 'shaved', word_class: 'AJ' },
    { word: 'shaven', word_class: 'AJ' },
    { word: 'shaving', word_class: 'N' }
  ],
  vestmental: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  basics: [
    { word: 'basic', word_class: 'N' },
    { word: 'basic', word_class: 'AJ' },
    { word: 'basics', word_class: 'N' },
    { word: 'basically', word_class: 'AV' }
  ],
  subsequently: [
    { word: 'subsequence', word_class: 'N' },
    { word: 'subsequent', word_class: 'AJ' },
    { word: 'subsequently', word_class: 'AV' },
    { word: 'subsequentness', word_class: 'N' }
  ],
  disavow: [
    { word: 'disavow', word_class: 'V' },
    { word: 'disavowal', word_class: 'N' },
    { word: 'disavowable', word_class: 'AJ' }
  ],
  mumbler: [
    { word: 'mumble', word_class: 'V' },
    { word: 'mumbler', word_class: 'N' },
    { word: 'mumbling', word_class: 'N' },
    { word: 'mumbling', word_class: 'AJ' }
  ],
  copolymerize: [
    { word: 'copolymerise', word_class: 'V' },
    { word: 'copolymerize', word_class: 'V' },
    { word: 'copolymerisation', word_class: 'N' },
    { word: 'copolymerization', word_class: 'N' }
  ],
  toiletry: [
    { word: 'toilet', word_class: 'N' },
    { word: 'toiletry', word_class: 'N' },
    { word: 'toiletries', word_class: 'N' }
  ],
  golden: [
    { word: 'gold', word_class: 'N' },
    { word: 'gold', word_class: 'V' },
    { word: 'gold', word_class: 'AJ' },
    { word: 'golden', word_class: 'V' },
    { word: 'golden', word_class: 'AJ' },
    { word: 'gold-mining', word_class: 'N' }
  ],
  feoffment: [
    { word: 'feoff', word_class: 'N' },
    { word: 'feoff', word_class: 'V' },
    { word: 'feoffment', word_class: 'N' }
  ],
  initiate: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  route: [
    { word: 'rout', word_class: 'N' },
    { word: 'rout', word_class: 'V' },
    { word: 'route', word_class: 'V' },
    { word: 'route', word_class: 'N' },
    { word: 'routed', word_class: 'AJ' }
  ],
  youth: [
    { word: 'youth', word_class: 'N' },
    { word: 'youthful', word_class: 'AJ' },
    { word: 'youthfulness', word_class: 'N' }
  ],
  advancing: [
    { word: 'advance', word_class: 'N' },
    { word: 'advance', word_class: 'V' },
    { word: 'advance', word_class: 'AJ' },
    { word: 'advanced', word_class: 'AJ' },
    { word: 'advancing', word_class: 'AJ' },
    { word: 'advancement', word_class: 'N' }
  ],
  attain: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  validness: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  projected: [
    { word: 'project', word_class: 'N' },
    { word: 'project', word_class: 'V' },
    { word: 'projector', word_class: 'N' },
    { word: 'projected', word_class: 'AJ' },
    { word: 'projection', word_class: 'N' },
    { word: 'projecting', word_class: 'AJ' }
  ],
  poignancy: [
    { word: 'poignance', word_class: 'N' },
    { word: 'poignancy', word_class: 'N' },
    { word: 'poignant', word_class: 'AJ' }
  ],
  frivolity: [
    { word: 'frivol', word_class: 'V' },
    { word: 'frivolity', word_class: 'N' },
    { word: 'frivolous', word_class: 'AJ' },
    { word: 'frivolously', word_class: 'AV' },
    { word: 'frivolousness', word_class: 'N' }
  ],
  faker: [
    { word: 'fake', word_class: 'N' },
    { word: 'fake', word_class: 'V' },
    { word: 'fake', word_class: 'AJ' },
    { word: 'faker', word_class: 'N' },
    { word: 'fakeer', word_class: 'N' }
  ],
  indirect: [
    { word: 'indirect', word_class: 'AJ' },
    { word: 'indirection', word_class: 'N' },
    { word: 'indirectness', word_class: 'N' }
  ],
  allegorically: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  race: [
    { word: 'race', word_class: 'N' },
    { word: 'race', word_class: 'V' },
    { word: 'racer', word_class: 'N' },
    { word: 'racism', word_class: 'N' },
    { word: 'racing', word_class: 'N' },
    { word: 'racist', word_class: 'N' },
    { word: 'racist', word_class: 'AJ' }
  ],
  instigate: [
    { word: 'instigant', word_class: 'N' },
    { word: 'instigate', word_class: 'V' },
    { word: 'instigator', word_class: 'N' },
    { word: 'instigation', word_class: 'N' },
    { word: 'instigative', word_class: 'AJ' }
  ],
  suaveness: [
    { word: 'suave', word_class: 'AJ' },
    { word: 'suavely', word_class: 'AV' },
    { word: 'suaveness', word_class: 'N' }
  ],
  obscureness: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  engraving: [
    { word: 'engrave', word_class: 'V' },
    { word: 'engraver', word_class: 'N' },
    { word: 'engraved', word_class: 'AJ' },
    { word: 'engraving', word_class: 'N' }
  ],
  abridgment: [
    { word: 'abridge', word_class: 'V' },
    { word: 'abridger', word_class: 'N' },
    { word: 'abridged', word_class: 'AJ' },
    { word: 'abridgment', word_class: 'N' },
    { word: 'abridgement', word_class: 'N' }
  ],
  affiliate: [
    { word: 'affiliate', word_class: 'N' },
    { word: 'affiliate', word_class: 'V' },
    { word: 'affiliated', word_class: 'AJ' },
    { word: 'affiliation', word_class: 'N' }
  ],
  assignee: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  outsized: [
    { word: 'outsize', word_class: 'N' },
    { word: 'outsize', word_class: 'AJ' },
    { word: 'outsized', word_class: 'AJ' }
  ],
  drinker: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  gestate: [
    { word: 'gestate', word_class: 'V' },
    { word: 'gestation', word_class: 'N' },
    { word: 'gestational', word_class: 'AJ' }
  ],
  unsupported: [
    { word: 'unsupported', word_class: 'AJ' },
    { word: 'unsupportive', word_class: 'AJ' },
    { word: 'unsupportable', word_class: 'AJ' }
  ],
  tapping: [
    { word: 'tap', word_class: 'N' },
    { word: 'tap', word_class: 'V' },
    { word: 'taps', word_class: 'N' },
    { word: 'tapped', word_class: 'AJ' },
    { word: 'tapping', word_class: 'N' }
  ],
  branded: [
    { word: 'brand', word_class: 'N' },
    { word: 'brand', word_class: 'V' },
    { word: 'branded', word_class: 'AJ' },
    { word: 'branding', word_class: 'N' }
  ],
  insemination: [
    { word: 'inseminate', word_class: 'V' },
    { word: 'inseminated', word_class: 'AJ' },
    { word: 'insemination', word_class: 'N' }
  ],
  linguistic: [
    { word: 'linguist', word_class: 'N' },
    { word: 'linguistic', word_class: 'AJ' },
    { word: 'linguistics', word_class: 'N' },
    { word: 'linguistically', word_class: 'AV' }
  ],
  copyrighted: [
    { word: 'copyright', word_class: 'N' },
    { word: 'copyright', word_class: 'V' },
    { word: 'copyright', word_class: 'AJ' },
    { word: 'copyrighted', word_class: 'AJ' }
  ],
  propagate: [
    { word: 'propagate', word_class: 'V' },
    { word: 'propagator', word_class: 'N' },
    { word: 'propagation', word_class: 'N' }
  ],
  roaster: [
    { word: 'roast', word_class: 'N' },
    { word: 'roast', word_class: 'V' },
    { word: 'roast', word_class: 'AJ' },
    { word: 'roaster', word_class: 'N' },
    { word: 'roasted', word_class: 'AJ' },
    { word: 'roasting', word_class: 'N' }
  ],
  reorganized: [
    { word: 'reorganize', word_class: 'V' },
    { word: 'reorganized', word_class: 'AJ' },
    { word: 'reorganization', word_class: 'N' }
  ],
  weathered: [
    { word: 'weather', word_class: 'N' },
    { word: 'weather', word_class: 'V' },
    { word: 'weather', word_class: 'AJ' },
    { word: 'weathered', word_class: 'AJ' },
    { word: 'weatherman', word_class: 'N' }
  ],
  immense: [
    { word: 'immense', word_class: 'AJ' },
    { word: 'immensity', word_class: 'N' },
    { word: 'immensely', word_class: 'AV' },
    { word: 'immenseness', word_class: 'N' }
  ],
  love: [
    { word: 'love', word_class: 'N' },
    { word: 'love', word_class: 'V' },
    { word: 'lover', word_class: 'N' },
    { word: 'loved', word_class: 'AJ' },
    { word: 'lovely', word_class: 'N' },
    { word: 'lovely', word_class: 'AJ' },
    { word: 'loving', word_class: 'AJ' }
  ],
  sparse: [
    { word: 'sparse', word_class: 'AJ' },
    { word: 'sparsely', word_class: 'AV' },
    { word: 'sparseness', word_class: 'N' }
  ],
  thrown: [
    { word: 'throw', word_class: 'N' },
    { word: 'throw', word_class: 'V' },
    { word: 'thrower', word_class: 'N' },
    { word: 'thrown', word_class: 'AJ' }
  ],
  overcapitalisation: [
    { word: 'overcapitalise', word_class: 'V' },
    { word: 'overcapitalize', word_class: 'V' },
    { word: 'overcapitalisation', word_class: 'N' },
    { word: 'overcapitalization', word_class: 'N' }
  ],
  promulgation: [
    { word: 'promulgate', word_class: 'V' },
    { word: 'promulgator', word_class: 'N' },
    { word: 'promulgated', word_class: 'AJ' },
    { word: 'promulgation', word_class: 'N' }
  ],
  minimally: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  fanciful: [
    { word: 'fancy', word_class: 'N' },
    { word: 'fancy', word_class: 'V' },
    { word: 'fancy', word_class: 'AJ' },
    { word: 'fancier', word_class: 'N' },
    { word: 'fancied', word_class: 'AJ' },
    { word: 'fanciful', word_class: 'AJ' }
  ],
  ventriloquism: [
    { word: 'ventriloquise', word_class: 'V' },
    { word: 'ventriloquism', word_class: 'N' },
    { word: 'ventriloquize', word_class: 'V' },
    { word: 'ventriloquization', word_class: 'N' }
  ],
  pragmatism: [
    { word: 'pragmatic', word_class: 'AJ' },
    { word: 'pragmatics', word_class: 'N' },
    { word: 'pragmatism', word_class: 'N' },
    { word: 'pragmatical', word_class: 'AJ' },
    { word: 'pragmatically', word_class: 'AV' }
  ],
  unpredictability: [
    { word: 'unpredicted', word_class: 'AJ' },
    { word: 'unpredictive', word_class: 'AJ' },
    { word: 'unpredictable', word_class: 'AJ' },
    { word: 'unpredictably', word_class: 'AV' },
    { word: 'unpredictability', word_class: 'N' }
  ],
  bromine: [
    { word: 'bromine', word_class: 'N' },
    { word: 'brominate', word_class: 'V' },
    { word: 'bromination', word_class: 'N' }
  ],
  synopsise: [
    { word: 'synopsis', word_class: 'N' },
    { word: 'synopsise', word_class: 'V' },
    { word: 'synopsize', word_class: 'V' },
    { word: 'synopsization', word_class: 'N' }
  ],
  promoter: [
    { word: 'promote', word_class: 'N' },
    { word: 'promote', word_class: 'V' },
    { word: 'promoter', word_class: 'N' },
    { word: 'promotion', word_class: 'N' },
    { word: 'promotive', word_class: 'AJ' },
    { word: 'promotional', word_class: 'AJ' }
  ],
  designated: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  compact: [
    { word: 'compact', word_class: 'N' },
    { word: 'compact', word_class: 'V' },
    { word: 'compact', word_class: 'AJ' },
    { word: 'compaction', word_class: 'N' },
    { word: 'compactness', word_class: 'N' }
  ],
  dichotomize: [
    { word: 'dichotomize', word_class: 'V' },
    { word: 'dichotomous', word_class: 'AJ' },
    { word: 'dichotomously', word_class: 'AV' },
    { word: 'dichotomization', word_class: 'N' }
  ],
  dispatched: [
    { word: 'dispatch', word_class: 'N' },
    { word: 'dispatch', word_class: 'V' },
    { word: 'dispatcher', word_class: 'N' },
    { word: 'dispatched', word_class: 'AJ' }
  ],
  wakefulness: [
    { word: 'wake', word_class: 'N' },
    { word: 'wake', word_class: 'V' },
    { word: 'waking', word_class: 'N' },
    { word: 'woken', word_class: 'AJ' },
    { word: 'waking', word_class: 'AJ' },
    { word: 'wakeful', word_class: 'AJ' },
    { word: 'wakefulness', word_class: 'N' }
  ],
  stabbed: [
    { word: 'stab', word_class: 'N' },
    { word: 'stab', word_class: 'V' },
    { word: 'stabbed', word_class: 'AJ' },
    { word: 'stabbing', word_class: 'AJ' }
  ],
  gospels: [
    { word: 'gospel', word_class: 'N' },
    { word: 'gospels', word_class: 'N' },
    { word: 'gospeler', word_class: 'N' },
    { word: 'gospeller', word_class: 'N' }
  ],
  instil: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  horseshoes: [
    { word: 'horseshoe', word_class: 'N' },
    { word: 'horseshoer', word_class: 'N' },
    { word: 'horseshoes', word_class: 'N' }
  ],
  snuffers: [
    { word: 'snuff', word_class: 'N' },
    { word: 'snuff', word_class: 'V' },
    { word: 'snuff', word_class: 'AJ' },
    { word: 'snuffer', word_class: 'N' },
    { word: 'snuffers', word_class: 'N' }
  ],
  combativeness: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  totalizer: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  linkman: [
    { word: 'link', word_class: 'N' },
    { word: 'link', word_class: 'V' },
    { word: 'links', word_class: 'N' },
    { word: 'linkage', word_class: 'N' },
    { word: 'linkman', word_class: 'N' },
    { word: 'linked', word_class: 'AJ' }
  ],
  sterilized: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  actively: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  snorkeling: [
    { word: 'snorkel', word_class: 'N' },
    { word: 'snorkel', word_class: 'V' },
    { word: 'snorkeling', word_class: 'N' }
  ],
  inflectional: [
    { word: 'inflect', word_class: 'V' },
    { word: 'inflected', word_class: 'AJ' },
    { word: 'inflection', word_class: 'N' },
    { word: 'inflectional', word_class: 'AJ' }
  ],
  dodge: [
    { word: 'dodge', word_class: 'N' },
    { word: 'dodge', word_class: 'V' },
    { word: 'dodger', word_class: 'N' },
    { word: 'dodging', word_class: 'N' }
  ],
  industrialisation: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  disclaimer: [
    { word: 'disclaim', word_class: 'V' },
    { word: 'disclaimer', word_class: 'N' },
    { word: 'disclamation', word_class: 'N' }
  ],
  haulage: [
    { word: 'haul', word_class: 'N' },
    { word: 'haul', word_class: 'V' },
    { word: 'hauler', word_class: 'N' },
    { word: 'haulage', word_class: 'N' },
    { word: 'hauling', word_class: 'N' }
  ],
  normalize: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  burlesque: [
    { word: 'burlesque', word_class: 'N' },
    { word: 'burlesque', word_class: 'V' },
    { word: 'burlesque', word_class: 'AJ' }
  ],
  shiver: [
    { word: 'shiver', word_class: 'N' },
    { word: 'shiver', word_class: 'V' },
    { word: 'shivering', word_class: 'N' },
    { word: 'shivering', word_class: 'AJ' }
  ],
  innumerableness: [
    { word: 'innumerate', word_class: 'AJ' },
    { word: 'innumerous', word_class: 'AJ' },
    { word: 'innumerable', word_class: 'AJ' },
    { word: 'innumerableness', word_class: 'N' }
  ],
  toy: [{ word: 'toy', word_class: 'N' }, { word: 'toy', word_class: 'V' }, { word: 'toying', word_class: 'N' }],
  glassworker: [
    { word: 'glasswork', word_class: 'N' },
    { word: 'glassworks', word_class: 'N' },
    { word: 'glassworker', word_class: 'N' }
  ],
  scriber: [
    { word: 'scribe', word_class: 'N' },
    { word: 'scribe', word_class: 'V' },
    { word: 'scriber', word_class: 'N' }
  ],
  stiff: [
    { word: 'stiff', word_class: 'N' },
    { word: 'stiff', word_class: 'AJ' },
    { word: 'stiff', word_class: 'AV' },
    { word: 'stiffness', word_class: 'N' }
  ],
  direct: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  woollen: [
    { word: 'wool', word_class: 'N' },
    { word: 'woolen', word_class: 'N' },
    { word: 'woolen', word_class: 'AJ' },
    { word: 'woollen', word_class: 'N' },
    { word: 'woollen', word_class: 'AJ' }
  ],
  antagonistic: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  fluoresce: [
    { word: 'fluoresce', word_class: 'V' },
    { word: 'fluorescence', word_class: 'N' },
    { word: 'fluorescent', word_class: 'AJ' }
  ],
  germaneness: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  occupational: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  harried: [
    { word: 'harry', word_class: 'V' },
    { word: 'harrier', word_class: 'N' },
    { word: 'harried', word_class: 'AJ' }
  ],
  repel: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  eversion: [
    { word: 'evert', word_class: 'V' },
    { word: 'eversion', word_class: 'N' },
    { word: 'everting', word_class: 'N' }
  ],
  cuspidal: [
    { word: 'cuspid', word_class: 'N' },
    { word: 'cuspidal', word_class: 'AJ' },
    { word: 'cuspidate', word_class: 'AJ' },
    { word: 'cuspidated', word_class: 'AJ' },
    { word: 'cuspidation', word_class: 'N' }
  ],
  inbreed: [
    { word: 'inbreed', word_class: 'N' },
    { word: 'inbreed', word_class: 'V' },
    { word: 'inbreeding', word_class: 'N' }
  ],
  mulct: [
    { word: 'mulct', word_class: 'N' },
    { word: 'mulct', word_class: 'V' },
    { word: 'mulcting', word_class: 'N' }
  ],
  cerebrally: [
    { word: 'cerebral', word_class: 'AJ' },
    { word: 'cerebrate', word_class: 'V' },
    { word: 'cerebrally', word_class: 'AV' },
    { word: 'cerebration', word_class: 'N' }
  ],
  exterminated: [
    { word: 'exterminate', word_class: 'V' },
    { word: 'exterminator', word_class: 'N' },
    { word: 'exterminable', word_class: 'AJ' },
    { word: 'exterminated', word_class: 'AJ' },
    { word: 'extermination', word_class: 'N' }
  ],
  condescension: [
    { word: 'condescend', word_class: 'V' },
    { word: 'condescension', word_class: 'N' },
    { word: 'condescendence', word_class: 'N' },
    { word: 'condescending', word_class: 'AJ' }
  ],
  chastised: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  doer: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  conquerable: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  inhaled: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  exacerbating: [
    { word: 'exacerbate', word_class: 'V' },
    { word: 'exacerbation', word_class: 'N' },
    { word: 'exacerbating', word_class: 'AJ' }
  ],
  teacup: [
    { word: 'teacup', word_class: 'N' },
    { word: 'teacupful', word_class: 'N' },
    { word: 'teacupful', word_class: 'AJ' }
  ],
  prick: [
    { word: 'prick', word_class: 'N' },
    { word: 'prick', word_class: 'V' },
    { word: 'pricker', word_class: 'N' },
    { word: 'pricking', word_class: 'N' }
  ],
  numeration: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  taxi: [
    { word: 'taxi', word_class: 'N' },
    { word: 'taxi', word_class: 'V' },
    { word: 'taxis', word_class: 'N' },
    { word: 'taximan', word_class: 'N' }
  ],
  bastion: [
    { word: 'bast', word_class: 'N' },
    { word: 'baste', word_class: 'V' },
    { word: 'baste', word_class: 'N' },
    { word: 'basting', word_class: 'N' },
    { word: 'bastion', word_class: 'N' },
    { word: 'bastioned', word_class: 'AJ' }
  ],
  boil: [
    { word: 'boil', word_class: 'N' },
    { word: 'boil', word_class: 'V' },
    { word: 'boiler', word_class: 'N' },
    { word: 'boiled', word_class: 'AJ' },
    { word: 'boiling', word_class: 'N' },
    { word: 'boiling', word_class: 'AJ' },
    { word: 'boiling', word_class: 'AV' }
  ],
  decreed: [
    { word: 'decree', word_class: 'N' },
    { word: 'decree', word_class: 'V' },
    { word: 'decreed', word_class: 'AJ' },
    { word: 'decrement', word_class: 'N' },
    { word: 'decrement', word_class: 'V' }
  ],
  chromatic: [
    { word: 'chromate', word_class: 'N' },
    { word: 'chromatic', word_class: 'AJ' },
    { word: 'chromatism', word_class: 'N' },
    { word: 'chromaticity', word_class: 'N' },
    { word: 'chromatically', word_class: 'AV' }
  ],
  prance: [
    { word: 'prance', word_class: 'N' },
    { word: 'prance', word_class: 'V' },
    { word: 'prancer', word_class: 'N' }
  ],
  exchangeable: [
    { word: 'exchange', word_class: 'N' },
    { word: 'exchange', word_class: 'V' },
    { word: 'exchanger', word_class: 'N' },
    { word: 'exchanged', word_class: 'AJ' },
    { word: 'exchangeable', word_class: 'AJ' },
    { word: 'exchangeability', word_class: 'N' }
  ],
  argue: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  breathing: [
    { word: 'breath', word_class: 'N' },
    { word: 'breathe', word_class: 'V' },
    { word: 'breather', word_class: 'N' },
    { word: 'breathed', word_class: 'AJ' },
    { word: 'breathing', word_class: 'N' },
    { word: 'breathing', word_class: 'AJ' }
  ],
  connected: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  tropicalization: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  provable: [
    { word: 'provable', word_class: 'AJ' },
    { word: 'provably', word_class: 'AV' },
    { word: 'provability', word_class: 'N' }
  ],
  divisive: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  rustle: [
    { word: 'rustle', word_class: 'N' },
    { word: 'rustle', word_class: 'V' },
    { word: 'rustler', word_class: 'N' },
    { word: 'rustling', word_class: 'N' },
    { word: 'rustling', word_class: 'AJ' }
  ],
  warble: [
    { word: 'warble', word_class: 'N' },
    { word: 'warble', word_class: 'V' },
    { word: 'warbler', word_class: 'N' }
  ],
  mechanize: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  tryst: [
    { word: 'try', word_class: 'N' },
    { word: 'try', word_class: 'V' },
    { word: 'trial', word_class: 'N' },
    { word: 'trier', word_class: 'N' },
    { word: 'tryst', word_class: 'N' },
    { word: 'trial', word_class: 'AJ' },
    { word: 'trying', word_class: 'AJ' }
  ],
  patronage: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  municipal: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  ascetical: [
    { word: 'ascetic', word_class: 'N' },
    { word: 'ascetic', word_class: 'AJ' },
    { word: 'ascetical', word_class: 'AJ' },
    { word: 'ascetically', word_class: 'AV' }
  ],
  stacks: [
    { word: 'stack', word_class: 'N' },
    { word: 'stack', word_class: 'V' },
    { word: 'stacks', word_class: 'N' },
    { word: 'stacked', word_class: 'AJ' }
  ],
  retake: [
    { word: 'retake', word_class: 'N' },
    { word: 'retake', word_class: 'V' },
    { word: 'retaking', word_class: 'N' }
  ],
  alkali: [
    { word: 'alkali', word_class: 'N' },
    { word: 'alkaline', word_class: 'AJ' },
    { word: 'alkalinity', word_class: 'N' },
    { word: 'alkalinize', word_class: 'V' },
    { word: 'alkalinization', word_class: 'N' }
  ],
  grits: [{ word: 'grit', word_class: 'N' }, { word: 'grit', word_class: 'V' }, { word: 'grits', word_class: 'N' }],
  pasteurization: [
    { word: 'pasteur', word_class: 'N' },
    { word: 'pasteurize', word_class: 'V' },
    { word: 'pasteurized', word_class: 'AJ' },
    { word: 'pasteurization', word_class: 'N' }
  ],
  twined: [
    { word: 'twine', word_class: 'N' },
    { word: 'twine', word_class: 'V' },
    { word: 'twined', word_class: 'AJ' }
  ],
  creep: [
    { word: 'creep', word_class: 'N' },
    { word: 'creep', word_class: 'V' },
    { word: 'creeps', word_class: 'N' },
    { word: 'creeper', word_class: 'N' },
    { word: 'creeping', word_class: 'N' },
    { word: 'creeping', word_class: 'AJ' }
  ],
  slithering: [
    { word: 'slither', word_class: 'V' },
    { word: 'slithery', word_class: 'AJ' },
    { word: 'slithering', word_class: 'AJ' }
  ],
  demoniac: [
    { word: 'demoniac', word_class: 'N' },
    { word: 'demoniac', word_class: 'AJ' },
    { word: 'demoniacal', word_class: 'AJ' },
    { word: 'demoniacally', word_class: 'AV' }
  ],
  canonization: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  tensor: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  banter: [
    { word: 'banter', word_class: 'N' },
    { word: 'banter', word_class: 'V' },
    { word: 'bantering', word_class: 'AJ' }
  ],
  measurement: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  petiteness: [
    { word: 'petite', word_class: 'N' },
    { word: 'petit', word_class: 'AJ' },
    { word: 'petite', word_class: 'AJ' },
    { word: 'petiteness', word_class: 'N' }
  ],
  saddled: [
    { word: 'saddle', word_class: 'N' },
    { word: 'saddle', word_class: 'V' },
    { word: 'saddler', word_class: 'N' },
    { word: 'saddled', word_class: 'AJ' }
  ],
  desulphurisation: [
    { word: 'desulphurise', word_class: 'V' },
    { word: 'desulphurize', word_class: 'V' },
    { word: 'desulphurisation', word_class: 'N' },
    { word: 'desulphurization', word_class: 'N' }
  ],
  ungraciously: [
    { word: 'ungracious', word_class: 'AJ' },
    { word: 'ungraciously', word_class: 'AV' },
    { word: 'ungraciousness', word_class: 'N' }
  ],
  cleaner: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  obligated: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  purchase: [
    { word: 'purchase', word_class: 'N' },
    { word: 'purchase', word_class: 'V' },
    { word: 'purchaser', word_class: 'N' },
    { word: 'purchasing', word_class: 'N' },
    { word: 'purchasable', word_class: 'AJ' }
  ],
  evoke: [
    { word: 'evoke', word_class: 'V' },
    { word: 'evoked', word_class: 'AJ' },
    { word: 'evocable', word_class: 'AJ' },
    { word: 'evocation', word_class: 'N' },
    { word: 'evocative', word_class: 'AJ' }
  ],
  overlying: [
    { word: 'overlie', word_class: 'V' },
    { word: 'overly', word_class: 'AV' },
    { word: 'overlying', word_class: 'AJ' }
  ],
  declared: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  surmise: [
    { word: 'surmise', word_class: 'N' },
    { word: 'surmise', word_class: 'V' },
    { word: 'surmisable', word_class: 'AJ' }
  ],
  depleted: [
    { word: 'deplete', word_class: 'V' },
    { word: 'depleted', word_class: 'AJ' },
    { word: 'depletion', word_class: 'N' },
    { word: 'depletable', word_class: 'AJ' }
  ],
  sufferance: [
    { word: 'suffer', word_class: 'V' },
    { word: 'sufferer', word_class: 'N' },
    { word: 'suffering', word_class: 'N' },
    { word: 'sufferance', word_class: 'N' },
    { word: 'suffering', word_class: 'AJ' },
    { word: 'sufferable', word_class: 'AJ' }
  ],
  tracking: [
    { word: 'track', word_class: 'N' },
    { word: 'track', word_class: 'V' },
    { word: 'tracker', word_class: 'N' },
    { word: 'tracked', word_class: 'AJ' },
    { word: 'tracking', word_class: 'N' }
  ],
  lustfulness: [
    { word: 'lust', word_class: 'N' },
    { word: 'lust', word_class: 'V' },
    { word: 'lustful', word_class: 'AJ' },
    { word: 'lustfulness', word_class: 'N' }
  ],
  faint: [
    { word: 'faint', word_class: 'N' },
    { word: 'faint', word_class: 'V' },
    { word: 'faint', word_class: 'AJ' },
    { word: 'faintness', word_class: 'N' }
  ],
  mesmeric: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  deflationary: [
    { word: 'deflate', word_class: 'V' },
    { word: 'deflator', word_class: 'N' },
    { word: 'deflated', word_class: 'AJ' },
    { word: 'deflation', word_class: 'N' },
    { word: 'deflationary', word_class: 'AJ' }
  ],
  obsequy: [
    { word: 'obsequy', word_class: 'N' },
    { word: 'obsequious', word_class: 'AJ' },
    { word: 'obsequiously', word_class: 'AV' },
    { word: 'obsequiousness', word_class: 'N' }
  ],
  pique: [
    { word: 'pique', word_class: 'N' },
    { word: 'pique', word_class: 'V' },
    { word: 'pique', word_class: 'AJ' },
    { word: 'piquance', word_class: 'N' },
    { word: 'piquancy', word_class: 'N' }
  ],
  resolved: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  breeze: [
    { word: 'breeze', word_class: 'N' },
    { word: 'breeze', word_class: 'V' },
    { word: 'breezy', word_class: 'AJ' },
    { word: 'breeziness', word_class: 'N' }
  ],
  controversial: [
    { word: 'controvert', word_class: 'V' },
    { word: 'controversy', word_class: 'N' },
    { word: 'controversion', word_class: 'N' },
    { word: 'controversial', word_class: 'AJ' },
    { word: 'controversially', word_class: 'AV' }
  ],
  tropicalize: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  alleged: [
    { word: 'allege', word_class: 'V' },
    { word: 'alleged', word_class: 'AJ' },
    { word: 'allegation', word_class: 'N' },
    { word: 'allegement', word_class: 'N' },
    { word: 'allegeable', word_class: 'AJ' }
  ],
  answerable: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  signaler: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  lace: [
    { word: 'lace', word_class: 'N' },
    { word: 'lace', word_class: 'V' },
    { word: 'laced', word_class: 'AJ' },
    { word: 'lacing', word_class: 'N' }
  ],
  rouged: [
    { word: 'rouge', word_class: 'N' },
    { word: 'rouge', word_class: 'V' },
    { word: 'rouged', word_class: 'AJ' }
  ],
  complete: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  warship: [
    { word: 'war', word_class: 'N' },
    { word: 'war', word_class: 'V' },
    { word: 'warship', word_class: 'N' },
    { word: 'warring', word_class: 'AJ' }
  ],
  wasteful: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  transit: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  achromatisation: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  bargeman: [
    { word: 'barge', word_class: 'N' },
    { word: 'barge', word_class: 'V' },
    { word: 'bargee', word_class: 'N' },
    { word: 'bargeman', word_class: 'N' }
  ],
  ancientness: [
    { word: 'ancient', word_class: 'N' },
    { word: 'ancient', word_class: 'AJ' },
    { word: 'anciently', word_class: 'AV' },
    { word: 'ancientness', word_class: 'N' }
  ],
  cursor: [
    { word: 'curse', word_class: 'N' },
    { word: 'curse', word_class: 'V' },
    { word: 'cursor', word_class: 'N' },
    { word: 'cursed', word_class: 'AJ' },
    { word: 'cursing', word_class: 'AJ' }
  ],
  club: [{ word: 'club', word_class: 'N' }, { word: 'club', word_class: 'V' }, { word: 'clubbing', word_class: 'N' }],
  conclude: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  pervasiveness: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  nutritional: [
    { word: 'nutrition', word_class: 'N' },
    { word: 'nutritive', word_class: 'AJ' },
    { word: 'nutritional', word_class: 'AJ' },
    { word: 'nutritiveness', word_class: 'N' }
  ],
  purple: [
    { word: 'purple', word_class: 'N' },
    { word: 'purple', word_class: 'V' },
    { word: 'purple', word_class: 'AJ' },
    { word: 'purpleness', word_class: 'N' }
  ],
  asphyxiation: [
    { word: 'asphyxiate', word_class: 'V' },
    { word: 'asphyxiated', word_class: 'AJ' },
    { word: 'asphyxiation', word_class: 'N' },
    { word: 'asphyxiating', word_class: 'AJ' }
  ],
  lowered: [
    { word: 'lower', word_class: 'N' },
    { word: 'lower', word_class: 'V' },
    { word: 'lower', word_class: 'AJ' },
    { word: 'lowered', word_class: 'AJ' },
    { word: 'lowering', word_class: 'N' },
    { word: 'lowering', word_class: 'AJ' }
  ],
  audibility: [
    { word: 'audible', word_class: 'AJ' },
    { word: 'audibly', word_class: 'AV' },
    { word: 'audibility', word_class: 'N' },
    { word: 'audibleness', word_class: 'N' }
  ],
  requite: [
    { word: 'requite', word_class: 'V' },
    { word: 'requital', word_class: 'N' },
    { word: 'requited', word_class: 'AJ' },
    { word: 'requitement', word_class: 'N' }
  ],
  deputization: [
    { word: 'depute', word_class: 'V' },
    { word: 'deputy', word_class: 'N' },
    { word: 'deputy', word_class: 'AJ' },
    { word: 'deputize', word_class: 'V' },
    { word: 'deputation', word_class: 'N' },
    { word: 'deputization', word_class: 'N' }
  ],
  disheartenment: [
    { word: 'dishearten', word_class: 'V' },
    { word: 'disheartened', word_class: 'AJ' },
    { word: 'disheartening', word_class: 'AJ' },
    { word: 'disheartenment', word_class: 'N' }
  ],
  deplumation: [
    { word: 'deplume', word_class: 'V' },
    { word: 'deplumate', word_class: 'V' },
    { word: 'deplumation', word_class: 'N' }
  ],
  christianisation: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  dishonourable: [
    { word: 'dishonour', word_class: 'N' },
    { word: 'dishonour', word_class: 'V' },
    { word: 'dishonourable', word_class: 'AJ' },
    { word: 'dishonourably', word_class: 'AV' },
    { word: 'dishonourableness', word_class: 'N' }
  ],
  incontinency: [
    { word: 'incontinence', word_class: 'N' },
    { word: 'incontinency', word_class: 'N' },
    { word: 'incontinent', word_class: 'AJ' }
  ],
  lasciviousness: [
    { word: 'lascivious', word_class: 'AJ' },
    { word: 'lasciviously', word_class: 'AV' },
    { word: 'lasciviousness', word_class: 'N' }
  ],
  tropicalise: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  coalescency: [
    { word: 'coalesce', word_class: 'V' },
    { word: 'coalesced', word_class: 'AJ' },
    { word: 'coalescence', word_class: 'N' },
    { word: 'coalescency', word_class: 'N' }
  ],
  yodeling: [
    { word: 'yodel', word_class: 'N' },
    { word: 'yodel', word_class: 'V' },
    { word: 'yodeling', word_class: 'N' },
    { word: 'yodeller', word_class: 'N' }
  ],
  implicate: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  persuade: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  seraphic: [
    { word: 'seraph', word_class: 'N' },
    { word: 'seraphic', word_class: 'AJ' },
    { word: 'seraphical', word_class: 'AJ' }
  ],
  carte: [
    { word: 'cart', word_class: 'N' },
    { word: 'cart', word_class: 'V' },
    { word: 'carte', word_class: 'N' },
    { word: 'carter', word_class: 'N' },
    { word: 'carting', word_class: 'N' }
  ],
  period: [
    { word: 'period', word_class: 'N' },
    { word: 'periodic', word_class: 'AJ' },
    { word: 'periodical', word_class: 'N' },
    { word: 'periodical', word_class: 'AJ' },
    { word: 'periodicity', word_class: 'N' },
    { word: 'periodically', word_class: 'AV' }
  ],
  argentic: [
    { word: 'argent', word_class: 'N' },
    { word: 'argent', word_class: 'AJ' },
    { word: 'argentic', word_class: 'AJ' },
    { word: 'argentous', word_class: 'AJ' }
  ],
  confer: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  sulphurous: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  mew: [{ word: 'mew', word_class: 'N' }, { word: 'mew', word_class: 'V' }, { word: 'mews', word_class: 'N' }],
  capsulated: [
    { word: 'capsule', word_class: 'N' },
    { word: 'capsulate', word_class: 'V' },
    { word: 'capsulate', word_class: 'AJ' },
    { word: 'capsulated', word_class: 'AJ' },
    { word: 'capsulation', word_class: 'N' }
  ],
  wherewith: [
    { word: 'wherewith', word_class: 'AV' },
    { word: 'wherewithal', word_class: 'N' },
    { word: 'wherewithal', word_class: 'AV' }
  ],
  afrikanerise: [
    { word: 'afrikanerise', word_class: 'V' },
    { word: 'afrikanerize', word_class: 'V' },
    { word: 'afrikanerisation', word_class: 'N' },
    { word: 'afrikanerization', word_class: 'N' }
  ],
  veined: [{ word: 'vein', word_class: 'N' }, { word: 'vein', word_class: 'V' }, { word: 'veined', word_class: 'AJ' }],
  stinting: [
    { word: 'stint', word_class: 'N' },
    { word: 'stint', word_class: 'V' },
    { word: 'stinting', word_class: 'AJ' }
  ],
  ship: [
    { word: 'ship', word_class: 'N' },
    { word: 'ship', word_class: 'V' },
    { word: 'shipment', word_class: 'N' },
    { word: 'shipping', word_class: 'N' }
  ],
  lashings: [
    { word: 'lash', word_class: 'N' },
    { word: 'lash', word_class: 'V' },
    { word: 'lasher', word_class: 'N' },
    { word: 'lashing', word_class: 'N' },
    { word: 'lashing', word_class: 'AJ' },
    { word: 'lashings', word_class: 'N' }
  ],
  apparentness: [
    { word: 'apparency', word_class: 'N' },
    { word: 'apparent', word_class: 'AJ' },
    { word: 'apparently', word_class: 'AV' },
    { word: 'apparentness', word_class: 'N' }
  ],
  sacrilegiousness: [
    { word: 'sacrilege', word_class: 'N' },
    { word: 'sacrilegious', word_class: 'AJ' },
    { word: 'sacrilegiously', word_class: 'AV' },
    { word: 'sacrilegiousness', word_class: 'N' }
  ],
  secured: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  impudently: [
    { word: 'impudence', word_class: 'N' },
    { word: 'impudent', word_class: 'AJ' },
    { word: 'impudently', word_class: 'AV' }
  ],
  buffet: [
    { word: 'buffet', word_class: 'N' },
    { word: 'buffet', word_class: 'V' },
    { word: 'buffeted', word_class: 'AJ' },
    { word: 'buffeting', word_class: 'N' }
  ],
  extorsion: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  declassify: [
    { word: 'declassify', word_class: 'V' },
    { word: 'declassified', word_class: 'AJ' },
    { word: 'declassification', word_class: 'N' }
  ],
  customary: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  normalcy: [
    { word: 'norm', word_class: 'N' },
    { word: 'normalcy', word_class: 'N' },
    { word: 'normative', word_class: 'AJ' }
  ],
  outlawed: [
    { word: 'outlaw', word_class: 'N' },
    { word: 'outlaw', word_class: 'V' },
    { word: 'outlaw', word_class: 'AJ' },
    { word: 'outlawed', word_class: 'AJ' }
  ],
  competitive: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  birthe: [
    { word: 'birth', word_class: 'N' },
    { word: 'birth', word_class: 'V' },
    { word: 'birthe', word_class: 'V' },
    { word: 'birthing', word_class: 'N' },
    { word: 'birthing', word_class: 'AJ' }
  ],
  purchasable: [
    { word: 'purchase', word_class: 'N' },
    { word: 'purchase', word_class: 'V' },
    { word: 'purchaser', word_class: 'N' },
    { word: 'purchasing', word_class: 'N' },
    { word: 'purchasable', word_class: 'AJ' }
  ],
  inhale: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  enraged: [
    { word: 'enrage', word_class: 'V' },
    { word: 'enraged', word_class: 'AJ' },
    { word: 'enragement', word_class: 'N' }
  ],
  despise: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  shears: [
    { word: 'shear', word_class: 'N' },
    { word: 'shear', word_class: 'V' },
    { word: 'shears', word_class: 'N' },
    { word: 'shorn', word_class: 'AJ' },
    { word: 'sheared', word_class: 'AJ' },
    { word: 'shearing', word_class: 'N' }
  ],
  receive: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  symbolical: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  missal: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  headman: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  stalker: [
    { word: 'stalk', word_class: 'N' },
    { word: 'stalk', word_class: 'V' },
    { word: 'stalker', word_class: 'N' },
    { word: 'stalked', word_class: 'AJ' },
    { word: 'stalking', word_class: 'N' },
    { word: 'stalking', word_class: 'AJ' }
  ],
  market: [
    { word: 'market', word_class: 'N' },
    { word: 'market', word_class: 'V' },
    { word: 'marketing', word_class: 'N' },
    { word: 'marketable', word_class: 'AJ' }
  ],
  program: [
    { word: 'program', word_class: 'N' },
    { word: 'program', word_class: 'V' },
    { word: 'programming', word_class: 'N' }
  ],
  peak: [
    { word: 'peak', word_class: 'N' },
    { word: 'peak', word_class: 'V' },
    { word: 'peak', word_class: 'AJ' },
    { word: 'peaked', word_class: 'AJ' }
  ],
  free: [
    { word: 'free', word_class: 'N' },
    { word: 'free', word_class: 'V' },
    { word: 'free', word_class: 'AJ' },
    { word: 'free', word_class: 'AV' },
    { word: 'freedom', word_class: 'N' },
    { word: 'freeing', word_class: 'N' },
    { word: 'freeman', word_class: 'N' },
    { word: 'freeing', word_class: 'AJ' }
  ],
  vacation: [
    { word: 'vacate', word_class: 'V' },
    { word: 'vacation', word_class: 'N' },
    { word: 'vacation', word_class: 'V' },
    { word: 'vacationer', word_class: 'N' },
    { word: 'vacationing', word_class: 'N' },
    { word: 'vacationist', word_class: 'N' }
  ],
  wares: [{ word: 'ware', word_class: 'N' }, { word: 'ware', word_class: 'V' }, { word: 'wares', word_class: 'N' }],
  slop: [
    { word: 'slop', word_class: 'N' },
    { word: 'slop', word_class: 'V' },
    { word: 'slops', word_class: 'N' },
    { word: 'slopped', word_class: 'AJ' }
  ],
  modelling: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  captivity: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  rosiness: [
    { word: 'rose', word_class: 'N' },
    { word: 'rosy', word_class: 'AJ' },
    { word: 'rose', word_class: 'AJ' },
    { word: 'rosiness', word_class: 'N' }
  ],
  umpire: [
    { word: 'umpire', word_class: 'N' },
    { word: 'umpire', word_class: 'V' },
    { word: 'umpirage', word_class: 'N' },
    { word: 'umpiring', word_class: 'N' },
    { word: 'umpireship', word_class: 'N' }
  ],
  streamliner: [
    { word: 'streamline', word_class: 'N' },
    { word: 'streamline', word_class: 'V' },
    { word: 'streamliner', word_class: 'N' },
    { word: 'streamlined', word_class: 'AJ' }
  ],
  elicit: [
    { word: 'elicit', word_class: 'V' },
    { word: 'elicited', word_class: 'AJ' },
    { word: 'elicitation', word_class: 'N' }
  ],
  instatment: [
    { word: 'instate', word_class: 'N' },
    { word: 'instate', word_class: 'V' },
    { word: 'instatment', word_class: 'N' },
    { word: 'instatement', word_class: 'N' }
  ],
  prowl: [{ word: 'prowl', word_class: 'N' }, { word: 'prowl', word_class: 'V' }, { word: 'prowler', word_class: 'N' }],
  confection: [
    { word: 'confect', word_class: 'V' },
    { word: 'confection', word_class: 'N' },
    { word: 'confection', word_class: 'V' }
  ],
  rhetorical: [
    { word: 'rhetoric', word_class: 'N' },
    { word: 'rhetorical', word_class: 'AJ' },
    { word: 'rhetorically', word_class: 'AV' }
  ],
  blacktop: [
    { word: 'blacktop', word_class: 'N' },
    { word: 'blacktop', word_class: 'V' },
    { word: 'blacktopped', word_class: 'AJ' },
    { word: 'blacktopping', word_class: 'N' }
  ],
  alkalinize: [
    { word: 'alkali', word_class: 'N' },
    { word: 'alkaline', word_class: 'AJ' },
    { word: 'alkalinity', word_class: 'N' },
    { word: 'alkalinize', word_class: 'V' },
    { word: 'alkalinization', word_class: 'N' }
  ],
  unalterability: [
    { word: 'unalterable', word_class: 'AJ' },
    { word: 'unalterably', word_class: 'AV' },
    { word: 'unalterability', word_class: 'N' }
  ],
  gazetteer: [
    { word: 'gazette', word_class: 'N' },
    { word: 'gazette', word_class: 'V' },
    { word: 'gazetteer', word_class: 'N' }
  ],
  chanting: [
    { word: 'chant', word_class: 'N' },
    { word: 'chant', word_class: 'V' },
    { word: 'chanter', word_class: 'N' },
    { word: 'chantry', word_class: 'N' },
    { word: 'chanted', word_class: 'AJ' },
    { word: 'chanting', word_class: 'N' }
  ],
  apperception: [
    { word: 'apperceive', word_class: 'V' },
    { word: 'apperception', word_class: 'N' },
    { word: 'apperceptive', word_class: 'AJ' }
  ],
  residence: [
    { word: 'reside', word_class: 'V' },
    { word: 'resident', word_class: 'N' },
    { word: 'residence', word_class: 'N' },
    { word: 'residency', word_class: 'N' },
    { word: 'resident', word_class: 'AJ' }
  ],
  impartation: [
    { word: 'impart', word_class: 'N' },
    { word: 'impart', word_class: 'V' },
    { word: 'imparting', word_class: 'N' },
    { word: 'impartment', word_class: 'N' },
    { word: 'impartation', word_class: 'N' }
  ],
  man: [
    { word: 'man', word_class: 'V' },
    { word: 'man', word_class: 'N' },
    { word: 'manor', word_class: 'N' },
    { word: 'manned', word_class: 'AJ' },
    { word: 'manful', word_class: 'AJ' },
    { word: 'manfulness', word_class: 'N' }
  ],
  spatial: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  acclamation: [
    { word: 'acclaim', word_class: 'N' },
    { word: 'acclaim', word_class: 'V' },
    { word: 'acclamation', word_class: 'N' }
  ],
  sipping: [{ word: 'sip', word_class: 'N' }, { word: 'sip', word_class: 'V' }, { word: 'sipping', word_class: 'N' }],
  internationality: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  mud: [
    { word: 'mud', word_class: 'N' },
    { word: 'mud', word_class: 'V' },
    { word: 'muddy', word_class: 'V' },
    { word: 'muddy', word_class: 'AJ' },
    { word: 'muddied', word_class: 'AJ' },
    { word: 'muddiness', word_class: 'N' }
  ],
  querulously: [
    { word: 'querulous', word_class: 'AJ' },
    { word: 'querulously', word_class: 'AV' },
    { word: 'querulousness', word_class: 'N' }
  ],
  aristotelian: [
    { word: 'aristotelian', word_class: 'N' },
    { word: 'aristotelian', word_class: 'AJ' },
    { word: 'aristotelianism', word_class: 'N' }
  ],
  vested: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  evangelist: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  peptizing: [
    { word: 'peptise', word_class: 'V' },
    { word: 'peptize', word_class: 'V' },
    { word: 'peptizing', word_class: 'N' },
    { word: 'peptizing', word_class: 'AJ' },
    { word: 'peptisation', word_class: 'N' },
    { word: 'peptization', word_class: 'N' }
  ],
  legate: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  controlling: [
    { word: 'control', word_class: 'N' },
    { word: 'control', word_class: 'V' },
    { word: 'controller', word_class: 'N' },
    { word: 'controlled', word_class: 'AJ' },
    { word: 'controlling', word_class: 'N' },
    { word: 'controlling', word_class: 'AJ' },
    { word: 'controllable', word_class: 'AJ' }
  ],
  sparkle: [
    { word: 'sparkle', word_class: 'N' },
    { word: 'sparkle', word_class: 'V' },
    { word: 'sparkler', word_class: 'N' },
    { word: 'sparkling', word_class: 'N' },
    { word: 'sparkling', word_class: 'AJ' }
  ],
  feign: [
    { word: 'feign', word_class: 'V' },
    { word: 'feigned', word_class: 'AJ' },
    { word: 'feigning', word_class: 'N' }
  ],
  motorise: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  credit: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  indisposed: [
    { word: 'indispose', word_class: 'V' },
    { word: 'indisposed', word_class: 'AJ' },
    { word: 'indisposition', word_class: 'N' }
  ],
  welsher: [
    { word: 'welsh', word_class: 'N' },
    { word: 'welsh', word_class: 'V' },
    { word: 'welsh', word_class: 'AJ' },
    { word: 'welsher', word_class: 'N' },
    { word: 'welshman', word_class: 'N' }
  ],
  actinomycete: [
    { word: 'actinomycete', word_class: 'N' },
    { word: 'actinomycetal', word_class: 'AJ' },
    { word: 'actinomycetous', word_class: 'AJ' }
  ],
  debilitated: [
    { word: 'debilitate', word_class: 'V' },
    { word: 'debilitated', word_class: 'AJ' },
    { word: 'debilitation', word_class: 'N' },
    { word: 'debilitating', word_class: 'AJ' },
    { word: 'debilitative', word_class: 'AJ' }
  ],
  swank: [{ word: 'swank', word_class: 'N' }, { word: 'swank', word_class: 'V' }, { word: 'swank', word_class: 'AJ' }],
  surpass: [
    { word: 'surpass', word_class: 'N' },
    { word: 'surpass', word_class: 'V' },
    { word: 'surpassing', word_class: 'AJ' }
  ],
  leaded: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  doubter: [
    { word: 'doubt', word_class: 'N' },
    { word: 'doubt', word_class: 'V' },
    { word: 'doubter', word_class: 'N' },
    { word: 'doubtful', word_class: 'AJ' },
    { word: 'doubting', word_class: 'AJ' },
    { word: 'doubtfulness', word_class: 'N' }
  ],
  heterogenous: [
    { word: 'heterogeneity', word_class: 'N' },
    { word: 'heterogenous', word_class: 'AJ' },
    { word: 'heterogeneous', word_class: 'AJ' },
    { word: 'heterogeneousness', word_class: 'N' }
  ],
  tendentiously: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  'die-casting': [
    { word: 'die-cast', word_class: 'V' },
    { word: 'die-cast', word_class: 'AJ' },
    { word: 'die-casting', word_class: 'N' }
  ],
  taxman: [
    { word: 'tax', word_class: 'N' },
    { word: 'tax', word_class: 'V' },
    { word: 'taxman', word_class: 'N' },
    { word: 'taxing', word_class: 'AJ' },
    { word: 'taxation', word_class: 'N' }
  ],
  generosity: [
    { word: 'generous', word_class: 'AJ' },
    { word: 'generosity', word_class: 'N' },
    { word: 'generously', word_class: 'AV' },
    { word: 'generousness', word_class: 'N' }
  ],
  enthronization: [
    { word: 'enthrone', word_class: 'V' },
    { word: 'enthronement', word_class: 'N' },
    { word: 'enthronization', word_class: 'N' }
  ],
  unseasoned: [
    { word: 'unseasoned', word_class: 'AJ' },
    { word: 'unseasonable', word_class: 'AJ' },
    { word: 'unseasonably', word_class: 'AV' },
    { word: 'unseasonableness', word_class: 'N' }
  ],
  pacifistically: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  chorale: [
    { word: 'choral', word_class: 'N' },
    { word: 'choral', word_class: 'AJ' },
    { word: 'chorale', word_class: 'N' },
    { word: 'chorally', word_class: 'AV' }
  ],
  anagrammatize: [
    { word: 'anagrammatic', word_class: 'AJ' },
    { word: 'anagrammatize', word_class: 'V' },
    { word: 'anagrammatical', word_class: 'AJ' },
    { word: 'anagrammatization', word_class: 'N' }
  ],
  cobbling: [
    { word: 'cobble', word_class: 'N' },
    { word: 'cobble', word_class: 'V' },
    { word: 'cobbler', word_class: 'N' },
    { word: 'cobbling', word_class: 'N' }
  ],
  dazed: [{ word: 'daze', word_class: 'N' }, { word: 'daze', word_class: 'V' }, { word: 'dazed', word_class: 'AJ' }],
  superciliously: [
    { word: 'supercilious', word_class: 'AJ' },
    { word: 'superciliously', word_class: 'AV' },
    { word: 'superciliousness', word_class: 'N' }
  ],
  hazardousness: [
    { word: 'hazard', word_class: 'N' },
    { word: 'hazard', word_class: 'V' },
    { word: 'hazarding', word_class: 'N' },
    { word: 'hazardous', word_class: 'AJ' },
    { word: 'hazardously', word_class: 'AV' },
    { word: 'hazardousness', word_class: 'N' }
  ],
  foreseen: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  medicate: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  epigrammatize: [
    { word: 'epigrammatic', word_class: 'AJ' },
    { word: 'epigrammatize', word_class: 'V' },
    { word: 'epigrammatization', word_class: 'N' }
  ],
  algebraically: [
    { word: 'algebra', word_class: 'N' },
    { word: 'algebraic', word_class: 'AJ' },
    { word: 'algebraical', word_class: 'AJ' },
    { word: 'algebraically', word_class: 'AV' }
  ],
  persuasiveness: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  hesitater: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  flirtatiously: [
    { word: 'flirt', word_class: 'N' },
    { word: 'flirt', word_class: 'V' },
    { word: 'flirting', word_class: 'N' },
    { word: 'flirtation', word_class: 'N' },
    { word: 'flirtatious', word_class: 'AJ' },
    { word: 'flirtatiously', word_class: 'AV' }
  ],
  rateability: [
    { word: 'rateable', word_class: 'AJ' },
    { word: 'rateables', word_class: 'N' },
    { word: 'rateability', word_class: 'N' }
  ],
  designed: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  motional: [
    { word: 'motion', word_class: 'N' },
    { word: 'motion', word_class: 'V' },
    { word: 'motional', word_class: 'AJ' }
  ],
  blameful: [
    { word: 'blame', word_class: 'N' },
    { word: 'blame', word_class: 'V' },
    { word: 'blame', word_class: 'AJ' },
    { word: 'blamed', word_class: 'AJ' },
    { word: 'blameful', word_class: 'AJ' }
  ],
  disgrace: [
    { word: 'disgrace', word_class: 'N' },
    { word: 'disgrace', word_class: 'V' },
    { word: 'disgraced', word_class: 'AJ' },
    { word: 'disgraceful', word_class: 'AJ' },
    { word: 'disgracefulness', word_class: 'N' }
  ],
  archangelic: [
    { word: 'archangel', word_class: 'N' },
    { word: 'archangelic', word_class: 'AJ' },
    { word: 'archangelical', word_class: 'AJ' }
  ],
  ranger: [
    { word: 'range', word_class: 'N' },
    { word: 'range', word_class: 'V' },
    { word: 'ranger', word_class: 'N' },
    { word: 'ranging', word_class: 'AJ' }
  ],
  theological: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  piquancy: [
    { word: 'pique', word_class: 'N' },
    { word: 'pique', word_class: 'V' },
    { word: 'pique', word_class: 'AJ' },
    { word: 'piquance', word_class: 'N' },
    { word: 'piquancy', word_class: 'N' }
  ],
  fetor: [{ word: 'fete', word_class: 'N' }, { word: 'fete', word_class: 'V' }, { word: 'fetor', word_class: 'N' }],
  modular: [
    { word: 'module', word_class: 'N' },
    { word: 'modular', word_class: 'AJ' },
    { word: 'modulate', word_class: 'V' },
    { word: 'modulated', word_class: 'AJ' },
    { word: 'modulation', word_class: 'N' }
  ],
  shaman: [
    { word: 'shaman', word_class: 'N' },
    { word: 'shaman', word_class: 'AJ' },
    { word: 'shamanism', word_class: 'N' },
    { word: 'shamanize', word_class: 'V' }
  ],
  resilience: [
    { word: 'resile', word_class: 'V' },
    { word: 'resilement', word_class: 'N' },
    { word: 'resilience', word_class: 'N' },
    { word: 'resiliency', word_class: 'N' },
    { word: 'resilient', word_class: 'AJ' }
  ],
  boiling: [
    { word: 'boil', word_class: 'N' },
    { word: 'boil', word_class: 'V' },
    { word: 'boiler', word_class: 'N' },
    { word: 'boiled', word_class: 'AJ' },
    { word: 'boiling', word_class: 'N' },
    { word: 'boiling', word_class: 'AJ' },
    { word: 'boiling', word_class: 'AV' }
  ],
  inferral: [
    { word: 'infer', word_class: 'V' },
    { word: 'inferral', word_class: 'N' },
    { word: 'inference', word_class: 'N' },
    { word: 'inferable', word_class: 'AJ' },
    { word: 'inferential', word_class: 'AJ' }
  ],
  broadcasting: [
    { word: 'broadcast', word_class: 'N' },
    { word: 'broadcast', word_class: 'V' },
    { word: 'broadcast', word_class: 'AJ' },
    { word: 'broadcast', word_class: 'AV' },
    { word: 'broadcaster', word_class: 'N' },
    { word: 'broadcasting', word_class: 'N' }
  ],
  prophetical: [
    { word: 'prophet', word_class: 'N' },
    { word: 'prophets', word_class: 'N' },
    { word: 'prophetic', word_class: 'AJ' },
    { word: 'prophetical', word_class: 'AJ' },
    { word: 'prophetically', word_class: 'AV' }
  ],
  drivel: [
    { word: 'drivel', word_class: 'N' },
    { word: 'drivel', word_class: 'V' },
    { word: 'driveller', word_class: 'N' }
  ],
  sabbatical: [
    { word: 'sabbat', word_class: 'N' },
    { word: 'sabbatical', word_class: 'N' },
    { word: 'sabbatical', word_class: 'AJ' }
  ],
  entrancement: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  enjoin: [
    { word: 'enjoin', word_class: 'V' },
    { word: 'enjoining', word_class: 'N' },
    { word: 'enjoinment', word_class: 'N' }
  ],
  sectionalisation: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  detestation: [
    { word: 'detest', word_class: 'V' },
    { word: 'detested', word_class: 'AJ' },
    { word: 'detestable', word_class: 'AJ' },
    { word: 'detestably', word_class: 'AV' },
    { word: 'detestation', word_class: 'N' }
  ],
  surveillance: [
    { word: 'surveil', word_class: 'N' },
    { word: 'surveil', word_class: 'V' },
    { word: 'surveillance', word_class: 'N' }
  ],
  epitomization: [
    { word: 'epitome', word_class: 'N' },
    { word: 'epitomize', word_class: 'V' },
    { word: 'epitomization', word_class: 'N' }
  ],
  bordered: [
    { word: 'border', word_class: 'N' },
    { word: 'border', word_class: 'V' },
    { word: 'borderer', word_class: 'N' },
    { word: 'bordered', word_class: 'AJ' },
    { word: 'bordering', word_class: 'AJ' }
  ],
  congenial: [
    { word: 'congenial', word_class: 'AJ' },
    { word: 'congeniality', word_class: 'N' },
    { word: 'congenially', word_class: 'AV' },
    { word: 'congenialness', word_class: 'N' }
  ],
  privately: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  fibbing: [
    { word: 'fib', word_class: 'N' },
    { word: 'fib', word_class: 'V' },
    { word: 'fiber', word_class: 'N' },
    { word: 'fibbing', word_class: 'N' },
    { word: 'fibrous', word_class: 'AJ' }
  ],
  inhumed: [
    { word: 'inhume', word_class: 'V' },
    { word: 'inhumed', word_class: 'AJ' },
    { word: 'inhumation', word_class: 'N' }
  ],
  towelling: [
    { word: 'towel', word_class: 'N' },
    { word: 'towel', word_class: 'V' },
    { word: 'toweling', word_class: 'N' },
    { word: 'towelling', word_class: 'N' }
  ],
  arbitration: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  stylized: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  tantalizing: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  stoppage: [
    { word: 'stop', word_class: 'N' },
    { word: 'stop', word_class: 'V' },
    { word: 'stops', word_class: 'N' },
    { word: 'stoppage', word_class: 'N' },
    { word: 'stopped', word_class: 'AJ' },
    { word: 'stopping', word_class: 'N' }
  ],
  reprinting: [
    { word: 'reprint', word_class: 'N' },
    { word: 'reprint', word_class: 'V' },
    { word: 'reprinting', word_class: 'N' }
  ],
  tabulation: [
    { word: 'tabulate', word_class: 'V' },
    { word: 'tabulator', word_class: 'N' },
    { word: 'tabulation', word_class: 'N' }
  ],
  excretion: [
    { word: 'excrete', word_class: 'N' },
    { word: 'excrete', word_class: 'V' },
    { word: 'excreting', word_class: 'N' },
    { word: 'excretion', word_class: 'N' }
  ],
  whereabouts: [
    { word: 'whereabout', word_class: 'AV' },
    { word: 'whereabouts', word_class: 'N' },
    { word: 'whereabouts', word_class: 'AV' }
  ],
  truth: [
    { word: 'true', word_class: 'N' },
    { word: 'true', word_class: 'V' },
    { word: 'true', word_class: 'AJ' },
    { word: 'truth', word_class: 'N' },
    { word: 'true', word_class: 'AV' },
    { word: 'truly', word_class: 'AV' },
    { word: 'truthful', word_class: 'AJ' },
    { word: 'truthfulness', word_class: 'N' }
  ],
  industriously: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  amelioration: [
    { word: 'ameliorate', word_class: 'V' },
    { word: 'amelioration', word_class: 'N' },
    { word: 'ameliorating', word_class: 'AJ' },
    { word: 'ameliorative', word_class: 'AJ' }
  ],
  grapple: [
    { word: 'grapple', word_class: 'N' },
    { word: 'grapple', word_class: 'V' },
    { word: 'grappling', word_class: 'N' }
  ],
  intactness: [
    { word: 'intact', word_class: 'N' },
    { word: 'intact', word_class: 'AJ' },
    { word: 'intactness', word_class: 'N' }
  ],
  purposefulness: [
    { word: 'purpose', word_class: 'N' },
    { word: 'purpose', word_class: 'V' },
    { word: 'purposely', word_class: 'AV' },
    { word: 'purposive', word_class: 'AJ' },
    { word: 'purposeful', word_class: 'AJ' },
    { word: 'purposefulness', word_class: 'N' }
  ],
  burner: [
    { word: 'burn', word_class: 'N' },
    { word: 'burn', word_class: 'V' },
    { word: 'burner', word_class: 'N' },
    { word: 'burned', word_class: 'AJ' },
    { word: 'burning', word_class: 'N' },
    { word: 'burning', word_class: 'AJ' }
  ],
  jellied: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  preferential: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  forsworn: [
    { word: 'forswear', word_class: 'V' },
    { word: 'forsworn', word_class: 'AJ' },
    { word: 'forswearing', word_class: 'N' }
  ],
  downtown: [
    { word: 'downtown', word_class: 'N' },
    { word: 'downtown', word_class: 'AJ' },
    { word: 'downtown', word_class: 'AV' }
  ],
  cheer: [
    { word: 'cheer', word_class: 'N' },
    { word: 'cheer', word_class: 'V' },
    { word: 'cheering', word_class: 'N' },
    { word: 'cheerful', word_class: 'AJ' },
    { word: 'cheering', word_class: 'AJ' },
    { word: 'cheerfulness', word_class: 'N' }
  ],
  fouled: [
    { word: 'foul', word_class: 'N' },
    { word: 'foul', word_class: 'V' },
    { word: 'foul', word_class: 'AJ' },
    { word: 'fouled', word_class: 'AJ' },
    { word: 'foulness', word_class: 'N' }
  ],
  plagiarism: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  classify: [
    { word: 'classify', word_class: 'V' },
    { word: 'classifier', word_class: 'N' },
    { word: 'classified', word_class: 'AJ' },
    { word: 'classifiable', word_class: 'AJ' },
    { word: 'classification', word_class: 'N' }
  ],
  impugn: [
    { word: 'impugn', word_class: 'V' },
    { word: 'impugnment', word_class: 'N' },
    { word: 'impugnable', word_class: 'AJ' },
    { word: 'impugnation', word_class: 'N' }
  ],
  substitute: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  irrelevant: [
    { word: 'irrelevance', word_class: 'N' },
    { word: 'irrelevancy', word_class: 'N' },
    { word: 'irrelevant', word_class: 'AJ' }
  ],
  adventure: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  verticality: [
    { word: 'vertical', word_class: 'N' },
    { word: 'vertical', word_class: 'AJ' },
    { word: 'verticality', word_class: 'N' },
    { word: 'vertically', word_class: 'AV' },
    { word: 'verticalness', word_class: 'N' }
  ],
  switching: [
    { word: 'switch', word_class: 'N' },
    { word: 'switch', word_class: 'V' },
    { word: 'switch', word_class: 'AJ' },
    { word: 'switching', word_class: 'N' },
    { word: 'switchman', word_class: 'N' }
  ],
  skillful: [
    { word: 'skill', word_class: 'N' },
    { word: 'skilful', word_class: 'AJ' },
    { word: 'skilled', word_class: 'AJ' },
    { word: 'skillful', word_class: 'AJ' },
    { word: 'skillfulness', word_class: 'N' }
  ],
  sharing: [
    { word: 'share', word_class: 'N' },
    { word: 'share', word_class: 'V' },
    { word: 'sharer', word_class: 'N' },
    { word: 'shared', word_class: 'AJ' },
    { word: 'sharing', word_class: 'N' },
    { word: 'sharing', word_class: 'AJ' }
  ],
  accelerator: [
    { word: 'accelerate', word_class: 'V' },
    { word: 'accelerator', word_class: 'N' },
    { word: 'accelerated', word_class: 'AJ' },
    { word: 'acceleration', word_class: 'N' },
    { word: 'accelerating', word_class: 'AJ' }
  ],
  poster: [
    { word: 'post', word_class: 'N' },
    { word: 'post', word_class: 'V' },
    { word: 'post', word_class: 'AJ' },
    { word: 'poster', word_class: 'N' },
    { word: 'poster', word_class: 'V' },
    { word: 'posting', word_class: 'N' },
    { word: 'postman', word_class: 'N' },
    { word: 'posterity', word_class: 'N' }
  ],
  preoccupy: [
    { word: 'preoccupy', word_class: 'V' },
    { word: 'preoccupancy', word_class: 'N' },
    { word: 'preoccupied', word_class: 'AJ' },
    { word: 'preoccupation', word_class: 'N' }
  ],
  ciliation: [
    { word: 'ciliate', word_class: 'N' },
    { word: 'ciliate', word_class: 'V' },
    { word: 'ciliate', word_class: 'AJ' },
    { word: 'ciliated', word_class: 'AJ' },
    { word: 'ciliation', word_class: 'N' }
  ],
  shakiness: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  despairing: [
    { word: 'despair', word_class: 'N' },
    { word: 'despair', word_class: 'V' },
    { word: 'desperate', word_class: 'N' },
    { word: 'desperate', word_class: 'AJ' },
    { word: 'despairing', word_class: 'AJ' },
    { word: 'desperation', word_class: 'N' },
    { word: 'desperately', word_class: 'AV' }
  ],
  conventionalization: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  harassment: [
    { word: 'harass', word_class: 'V' },
    { word: 'harassed', word_class: 'AJ' },
    { word: 'harassment', word_class: 'N' }
  ],
  meandering: [
    { word: 'meander', word_class: 'N' },
    { word: 'meander', word_class: 'V' },
    { word: 'meandering', word_class: 'AJ' }
  ],
  hugging: [{ word: 'hug', word_class: 'N' }, { word: 'hug', word_class: 'V' }, { word: 'hugging', word_class: 'N' }],
  pentecostal: [
    { word: 'pentecost', word_class: 'N' },
    { word: 'pentecostal', word_class: 'N' },
    { word: 'pentecostal', word_class: 'AJ' },
    { word: 'pentecostalism', word_class: 'N' }
  ],
  rich: [
    { word: 'rich', word_class: 'AJ' },
    { word: 'riches', word_class: 'N' },
    { word: 'richness', word_class: 'N' }
  ],
  deoxidize: [
    { word: 'deoxidise', word_class: 'V' },
    { word: 'deoxidize', word_class: 'V' },
    { word: 'deoxidisation', word_class: 'N' },
    { word: 'deoxidization', word_class: 'N' }
  ],
  republicanize: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  rafter: [
    { word: 'raft', word_class: 'N' },
    { word: 'raft', word_class: 'V' },
    { word: 'rafts', word_class: 'N' },
    { word: 'rafter', word_class: 'N' },
    { word: 'rafter', word_class: 'V' },
    { word: 'raftman', word_class: 'N' },
    { word: 'raftered', word_class: 'AJ' }
  ],
  troller: [
    { word: 'troll', word_class: 'N' },
    { word: 'troll', word_class: 'V' },
    { word: 'troller', word_class: 'N' }
  ],
  channels: [
    { word: 'channel', word_class: 'N' },
    { word: 'channel', word_class: 'V' },
    { word: 'channels', word_class: 'N' },
    { word: 'channelize', word_class: 'V' },
    { word: 'channelization', word_class: 'N' }
  ],
  posturing: [
    { word: 'posture', word_class: 'N' },
    { word: 'posture', word_class: 'V' },
    { word: 'postural', word_class: 'AJ' },
    { word: 'posturing', word_class: 'N' }
  ],
  daunted: [
    { word: 'daunt', word_class: 'V' },
    { word: 'daunted', word_class: 'AJ' },
    { word: 'daunting', word_class: 'AJ' },
    { word: 'dauntless', word_class: 'AJ' },
    { word: 'dauntlessness', word_class: 'N' }
  ],
  dust: [
    { word: 'dust', word_class: 'N' },
    { word: 'dust', word_class: 'V' },
    { word: 'duster', word_class: 'N' },
    { word: 'dustman', word_class: 'N' }
  ],
  snaky: [{ word: 'snake', word_class: 'N' }, { word: 'snake', word_class: 'V' }, { word: 'snaky', word_class: 'AJ' }],
  peep: [{ word: 'peep', word_class: 'N' }, { word: 'peep', word_class: 'V' }, { word: 'peeper', word_class: 'N' }],
  malignment: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  intimidated: [
    { word: 'intimidate', word_class: 'V' },
    { word: 'intimidated', word_class: 'AJ' },
    { word: 'intimidation', word_class: 'N' },
    { word: 'intimidating', word_class: 'AJ' }
  ],
  homologate: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  dash: [
    { word: 'dash', word_class: 'N' },
    { word: 'dash', word_class: 'V' },
    { word: 'dashed', word_class: 'AJ' },
    { word: 'dashing', word_class: 'AJ' }
  ],
  attired: [
    { word: 'attire', word_class: 'N' },
    { word: 'attire', word_class: 'V' },
    { word: 'attired', word_class: 'AJ' }
  ],
  awake: [
    { word: 'awake', word_class: 'V' },
    { word: 'awake', word_class: 'AJ' },
    { word: 'awoken', word_class: 'AJ' }
  ],
  gasping: [
    { word: 'gasp', word_class: 'N' },
    { word: 'gasp', word_class: 'V' },
    { word: 'gasping', word_class: 'AJ' }
  ],
  hijacking: [
    { word: 'hijack', word_class: 'N' },
    { word: 'hijack', word_class: 'V' },
    { word: 'hijacker', word_class: 'N' },
    { word: 'hijacking', word_class: 'N' },
    { word: 'hijacking', word_class: 'AJ' }
  ],
  fatal: [
    { word: 'fatal', word_class: 'AJ' },
    { word: 'fatalism', word_class: 'N' },
    { word: 'fatality', word_class: 'N' },
    { word: 'fatally', word_class: 'AV' }
  ],
  tunefulness: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  periodicity: [
    { word: 'period', word_class: 'N' },
    { word: 'periodic', word_class: 'AJ' },
    { word: 'periodical', word_class: 'N' },
    { word: 'periodical', word_class: 'AJ' },
    { word: 'periodicity', word_class: 'N' },
    { word: 'periodically', word_class: 'AV' }
  ],
  recalcitrant: [
    { word: 'recalcitrance', word_class: 'N' },
    { word: 'recalcitrancy', word_class: 'N' },
    { word: 'recalcitrant', word_class: 'AJ' }
  ],
  spadeful: [
    { word: 'spade', word_class: 'N' },
    { word: 'spade', word_class: 'V' },
    { word: 'spadeful', word_class: 'N' },
    { word: 'spadeful', word_class: 'AJ' }
  ],
  showy: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  goodness: [
    { word: 'good', word_class: 'N' },
    { word: 'good', word_class: 'AJ' },
    { word: 'good', word_class: 'AV' },
    { word: 'goods', word_class: 'N' },
    { word: 'goodness', word_class: 'N' }
  ],
  smothered: [
    { word: 'smother', word_class: 'N' },
    { word: 'smother', word_class: 'V' },
    { word: 'smothered', word_class: 'AJ' },
    { word: 'smothering', word_class: 'AJ' }
  ],
  mousy: [
    { word: 'mouse', word_class: 'N' },
    { word: 'mouse', word_class: 'V' },
    { word: 'mousy', word_class: 'AJ' },
    { word: 'mousing', word_class: 'N' }
  ],
  lessee: [
    { word: 'lease', word_class: 'N' },
    { word: 'lease', word_class: 'V' },
    { word: 'lessee', word_class: 'N' },
    { word: 'lessor', word_class: 'N' },
    { word: 'leased', word_class: 'AJ' }
  ],
  toiletries: [
    { word: 'toilet', word_class: 'N' },
    { word: 'toiletry', word_class: 'N' },
    { word: 'toiletries', word_class: 'N' }
  ],
  predication: [
    { word: 'predicate', word_class: 'N' },
    { word: 'predicate', word_class: 'V' },
    { word: 'predicate', word_class: 'AJ' },
    { word: 'predicator', word_class: 'N' },
    { word: 'predication', word_class: 'N' },
    { word: 'predicative', word_class: 'AJ' },
    { word: 'predicatively', word_class: 'AV' }
  ],
  outline: [
    { word: 'outline', word_class: 'N' },
    { word: 'outline', word_class: 'V' },
    { word: 'outlined', word_class: 'AJ' }
  ],
  hooter: [{ word: 'hoot', word_class: 'N' }, { word: 'hoot', word_class: 'V' }, { word: 'hooter', word_class: 'N' }],
  prostrate: [
    { word: 'prostrate', word_class: 'V' },
    { word: 'prostrate', word_class: 'AJ' },
    { word: 'prostration', word_class: 'N' }
  ],
  inaugural: [
    { word: 'inaugural', word_class: 'N' },
    { word: 'inaugural', word_class: 'AJ' },
    { word: 'inaugurate', word_class: 'V' },
    { word: 'inaugurally', word_class: 'AV' },
    { word: 'inauguration', word_class: 'N' }
  ],
  master: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  totalise: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  whitewashed: [
    { word: 'whitewash', word_class: 'N' },
    { word: 'whitewash', word_class: 'V' },
    { word: 'whitewashed', word_class: 'AJ' }
  ],
  horned: [{ word: 'horn', word_class: 'N' }, { word: 'horn', word_class: 'V' }, { word: 'horned', word_class: 'AJ' }],
  obliged: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  underground: [
    { word: 'underground', word_class: 'N' },
    { word: 'underground', word_class: 'AJ' },
    { word: 'underground', word_class: 'AV' }
  ],
  frugalness: [
    { word: 'frugal', word_class: 'AJ' },
    { word: 'frugality', word_class: 'N' },
    { word: 'frugally', word_class: 'AV' },
    { word: 'frugalness', word_class: 'N' }
  ],
  castrate: [
    { word: 'castrate', word_class: 'N' },
    { word: 'castrate', word_class: 'V' },
    { word: 'castrated', word_class: 'AJ' },
    { word: 'castration', word_class: 'N' }
  ],
  mistaking: [
    { word: 'mistake', word_class: 'N' },
    { word: 'mistake', word_class: 'V' },
    { word: 'mistaking', word_class: 'N' }
  ],
  compartment: [
    { word: 'compart', word_class: 'V' },
    { word: 'compartment', word_class: 'N' },
    { word: 'compartmented', word_class: 'AJ' }
  ],
  magic: [
    { word: 'magic', word_class: 'N' },
    { word: 'magic', word_class: 'AJ' },
    { word: 'magical', word_class: 'AJ' },
    { word: 'magically', word_class: 'AV' }
  ],
  contents: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  groove: [
    { word: 'groove', word_class: 'N' },
    { word: 'groove', word_class: 'V' },
    { word: 'groover', word_class: 'N' },
    { word: 'grooved', word_class: 'AJ' },
    { word: 'grooving', word_class: 'N' }
  ],
  according: [
    { word: 'accord', word_class: 'N' },
    { word: 'accord', word_class: 'V' },
    { word: 'accordion', word_class: 'N' },
    { word: 'accordance', word_class: 'N' },
    { word: 'accordant', word_class: 'AJ' },
    { word: 'according', word_class: 'AJ' },
    { word: 'accordion', word_class: 'AJ' }
  ],
  producer: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  development: [
    { word: 'develop', word_class: 'V' },
    { word: 'developer', word_class: 'N' },
    { word: 'developed', word_class: 'AJ' },
    { word: 'developing', word_class: 'N' },
    { word: 'developing', word_class: 'AJ' },
    { word: 'development', word_class: 'N' }
  ],
  sifting: [
    { word: 'sift', word_class: 'V' },
    { word: 'sifter', word_class: 'N' },
    { word: 'sifting', word_class: 'N' }
  ],
  unsymmetrically: [
    { word: 'unsymmetric', word_class: 'AJ' },
    { word: 'unsymmetrical', word_class: 'AJ' },
    { word: 'unsymmetrically', word_class: 'AV' }
  ],
  electrical: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  united: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  foregrounding: [
    { word: 'foreground', word_class: 'N' },
    { word: 'foreground', word_class: 'V' },
    { word: 'foregrounding', word_class: 'N' }
  ],
  debauch: [
    { word: 'debauch', word_class: 'N' },
    { word: 'debauch', word_class: 'V' },
    { word: 'debauchee', word_class: 'N' },
    { word: 'debaucher', word_class: 'N' },
    { word: 'debauched', word_class: 'AJ' },
    { word: 'debauchery', word_class: 'N' },
    { word: 'debauchery', word_class: 'AJ' }
  ],
  poetical: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  normanization: [
    { word: 'norman', word_class: 'N' },
    { word: 'norman', word_class: 'AJ' },
    { word: 'normanise', word_class: 'V' },
    { word: 'normanize', word_class: 'V' },
    { word: 'normanisation', word_class: 'N' },
    { word: 'normanization', word_class: 'N' }
  ],
  miniaturize: [
    { word: 'miniature', word_class: 'N' },
    { word: 'miniature', word_class: 'AJ' },
    { word: 'miniaturise', word_class: 'V' },
    { word: 'miniaturize', word_class: 'V' },
    { word: 'miniaturisation', word_class: 'N' },
    { word: 'miniaturization', word_class: 'N' }
  ],
  quintupling: [
    { word: 'quintuple', word_class: 'V' },
    { word: 'quintuple', word_class: 'AJ' },
    { word: 'quintupling', word_class: 'N' },
    { word: 'quintuplicate', word_class: 'V' },
    { word: 'quintuplication', word_class: 'N' }
  ],
  fundament: [
    { word: 'fundament', word_class: 'N' },
    { word: 'fundamental', word_class: 'N' },
    { word: 'fundamental', word_class: 'AJ' },
    { word: 'fundamentals', word_class: 'N' },
    { word: 'fundamentalism', word_class: 'N' },
    { word: 'fundamentally', word_class: 'AV' }
  ],
  hops: [{ word: 'hop', word_class: 'N' }, { word: 'hop', word_class: 'V' }, { word: 'hops', word_class: 'N' }],
  derelict: [
    { word: 'derelict', word_class: 'N' },
    { word: 'derelict', word_class: 'AJ' },
    { word: 'dereliction', word_class: 'N' }
  ],
  jugful: [
    { word: 'jug', word_class: 'N' },
    { word: 'jug', word_class: 'V' },
    { word: 'jugful', word_class: 'N' },
    { word: 'jugful', word_class: 'AJ' }
  ],
  curve: [
    { word: 'curve', word_class: 'N' },
    { word: 'curve', word_class: 'V' },
    { word: 'curvy', word_class: 'AJ' },
    { word: 'curved', word_class: 'AJ' },
    { word: 'curving', word_class: 'AJ' },
    { word: 'curvature', word_class: 'N' }
  ],
  person: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  idealize: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  smother: [
    { word: 'smother', word_class: 'N' },
    { word: 'smother', word_class: 'V' },
    { word: 'smothered', word_class: 'AJ' },
    { word: 'smothering', word_class: 'AJ' }
  ],
  preexistence: [
    { word: 'preexist', word_class: 'V' },
    { word: 'preexistence', word_class: 'N' },
    { word: 'preexistent', word_class: 'AJ' }
  ],
  expedited: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  manageably: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  telegrapher: [
    { word: 'telegraph', word_class: 'N' },
    { word: 'telegraph', word_class: 'V' },
    { word: 'telegrapher', word_class: 'N' },
    { word: 'telegraphic', word_class: 'AJ' },
    { word: 'telegraphist', word_class: 'N' },
    { word: 'telegraphically', word_class: 'AV' }
  ],
  mercurialisation: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  'deaf-mute': [
    { word: 'deaf-mute', word_class: 'N' },
    { word: 'deaf-mute', word_class: 'AJ' },
    { word: 'deaf-mutism', word_class: 'N' },
    { word: 'deaf-muteness', word_class: 'N' }
  ],
  terrorisation: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  polyphone: [
    { word: 'polyphone', word_class: 'N' },
    { word: 'polyphonic', word_class: 'AJ' },
    { word: 'polyphonous', word_class: 'AJ' },
    { word: 'polyphonically', word_class: 'AV' }
  ],
  dice: [{ word: 'dice', word_class: 'N' }, { word: 'dice', word_class: 'V' }, { word: 'diction', word_class: 'N' }],
  pockmark: [
    { word: 'pockmark', word_class: 'N' },
    { word: 'pockmark', word_class: 'V' },
    { word: 'pockmarked', word_class: 'AJ' }
  ],
  corruptibility: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  revetement: [
    { word: 'revet', word_class: 'V' },
    { word: 'revetment', word_class: 'N' },
    { word: 'revetement', word_class: 'N' }
  ],
  initialization: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  battler: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  rubberize: [
    { word: 'rubber', word_class: 'N' },
    { word: 'rubber', word_class: 'V' },
    { word: 'rubber', word_class: 'AJ' },
    { word: 'rubberize', word_class: 'V' },
    { word: 'rubberized', word_class: 'AJ' },
    { word: 'rubberization', word_class: 'N' }
  ],
  sissy: [
    { word: 'sissy', word_class: 'N' },
    { word: 'sissy', word_class: 'AJ' },
    { word: 'sissiness', word_class: 'N' }
  ],
  insolvency: [
    { word: 'insolvent', word_class: 'N' },
    { word: 'insolvency', word_class: 'N' },
    { word: 'insolvent', word_class: 'AJ' },
    { word: 'insolvable', word_class: 'AJ' }
  ],
  avaricious: [
    { word: 'avarice', word_class: 'N' },
    { word: 'avaricious', word_class: 'AJ' },
    { word: 'avariciously', word_class: 'AV' },
    { word: 'avariciousness', word_class: 'N' }
  ],
  hunted: [
    { word: 'hunt', word_class: 'N' },
    { word: 'hunt', word_class: 'V' },
    { word: 'hunter', word_class: 'N' },
    { word: 'hunted', word_class: 'AJ' },
    { word: 'hunting', word_class: 'N' }
  ],
  jammed: [
    { word: 'jam', word_class: 'N' },
    { word: 'jam', word_class: 'V' },
    { word: 'jammed', word_class: 'AJ' },
    { word: 'jamming', word_class: 'N' }
  ],
  persuasible: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  warmer: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  tan: [
    { word: 'tan', word_class: 'N' },
    { word: 'tan', word_class: 'V' },
    { word: 'tan', word_class: 'AJ' },
    { word: 'tannage', word_class: 'N' },
    { word: 'tanned', word_class: 'AJ' }
  ],
  sanctimony: [
    { word: 'sanctimony', word_class: 'N' },
    { word: 'sanctimonious', word_class: 'AJ' },
    { word: 'sanctimoniously', word_class: 'AV' },
    { word: 'sanctimoniousness', word_class: 'N' }
  ],
  renovate: [
    { word: 'renovate', word_class: 'V' },
    { word: 'renovator', word_class: 'N' },
    { word: 'renovated', word_class: 'AJ' },
    { word: 'renovation', word_class: 'N' }
  ],
  registry: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  anglicism: [
    { word: 'anglicise', word_class: 'V' },
    { word: 'anglicism', word_class: 'N' },
    { word: 'anglicize', word_class: 'V' },
    { word: 'anglicisation', word_class: 'N' },
    { word: 'anglicization', word_class: 'N' }
  ],
  laryngitis: [
    { word: 'larynx', word_class: 'N' },
    { word: 'laryngeal', word_class: 'AJ' },
    { word: 'laryngitis', word_class: 'N' }
  ],
  departmental: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  romanticist: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  appositeness: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  numerable: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  prorate: [
    { word: 'prorate', word_class: 'N' },
    { word: 'prorate', word_class: 'V' },
    { word: 'proration', word_class: 'N' }
  ],
  creeping: [
    { word: 'creep', word_class: 'N' },
    { word: 'creep', word_class: 'V' },
    { word: 'creeps', word_class: 'N' },
    { word: 'creeper', word_class: 'N' },
    { word: 'creeping', word_class: 'N' },
    { word: 'creeping', word_class: 'AJ' }
  ],
  palpable: [
    { word: 'palpable', word_class: 'AJ' },
    { word: 'palpably', word_class: 'AV' },
    { word: 'palpability', word_class: 'N' }
  ],
  bureaucrat: [
    { word: 'bureaucrat', word_class: 'N' },
    { word: 'bureaucracy', word_class: 'N' },
    { word: 'bureaucratic', word_class: 'AJ' },
    { word: 'bureaucratism', word_class: 'N' },
    { word: 'bureaucratically', word_class: 'AV' }
  ],
  prayerful: [
    { word: 'pray', word_class: 'V' },
    { word: 'prayer', word_class: 'N' },
    { word: 'prayerful', word_class: 'AJ' }
  ],
  scandalousness: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  ebullient: [
    { word: 'ebullience', word_class: 'N' },
    { word: 'ebullient', word_class: 'AJ' },
    { word: 'ebulliently', word_class: 'AV' }
  ],
  musing: [
    { word: 'muse', word_class: 'N' },
    { word: 'muse', word_class: 'V' },
    { word: 'musing', word_class: 'N' },
    { word: 'musing', word_class: 'AJ' }
  ],
  reconciler: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  impediment: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  abducting: [
    { word: 'abduct', word_class: 'V' },
    { word: 'abductor', word_class: 'N' },
    { word: 'abducted', word_class: 'AJ' },
    { word: 'abduction', word_class: 'N' },
    { word: 'abducting', word_class: 'AJ' }
  ],
  autogenous: [
    { word: 'autogenic', word_class: 'AJ' },
    { word: 'autogenics', word_class: 'N' },
    { word: 'autogenous', word_class: 'AJ' }
  ],
  engraftment: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  conceptualisation: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  editing: [
    { word: 'edit', word_class: 'V' },
    { word: 'editor', word_class: 'N' },
    { word: 'edited', word_class: 'AJ' },
    { word: 'editing', word_class: 'N' },
    { word: 'edition', word_class: 'N' }
  ],
  covalent: [
    { word: 'covalence', word_class: 'N' },
    { word: 'covalency', word_class: 'N' },
    { word: 'covalent', word_class: 'AJ' }
  ],
  repetitive: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  stable: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  assembled: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  recollect: [
    { word: 'recollect', word_class: 'V' },
    { word: 'recollection', word_class: 'N' },
    { word: 'recollective', word_class: 'AJ' }
  ],
  callipers: [
    { word: 'calliper', word_class: 'N' },
    { word: 'calliper', word_class: 'V' },
    { word: 'callipers', word_class: 'N' }
  ],
  slacking: [
    { word: 'slack', word_class: 'N' },
    { word: 'slack', word_class: 'V' },
    { word: 'slack', word_class: 'AJ' },
    { word: 'slacks', word_class: 'N' },
    { word: 'slacker', word_class: 'N' },
    { word: 'slacking', word_class: 'N' },
    { word: 'slackness', word_class: 'N' }
  ],
  companion: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  bootlegging: [
    { word: 'bootleg', word_class: 'N' },
    { word: 'bootleg', word_class: 'V' },
    { word: 'bootleg', word_class: 'AJ' },
    { word: 'bootlegging', word_class: 'N' }
  ],
  landing: [
    { word: 'land', word_class: 'N' },
    { word: 'land', word_class: 'V' },
    { word: 'land', word_class: 'AJ' },
    { word: 'landed', word_class: 'AJ' },
    { word: 'landing', word_class: 'N' },
    { word: 'landman', word_class: 'N' }
  ],
  steer: [
    { word: 'steer', word_class: 'N' },
    { word: 'steer', word_class: 'V' },
    { word: 'steering', word_class: 'N' }
  ],
  multiplication: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  presumption: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  sternness: [
    { word: 'stern', word_class: 'N' },
    { word: 'stern', word_class: 'AJ' },
    { word: 'sternness', word_class: 'N' }
  ],
  centralization: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  bullion: [
    { word: 'bull', word_class: 'N' },
    { word: 'bull', word_class: 'V' },
    { word: 'bull', word_class: 'AJ' },
    { word: 'bullion', word_class: 'N' }
  ],
  scot: [{ word: 'scot', word_class: 'N' }, { word: 'scots', word_class: 'N' }, { word: 'scots', word_class: 'AJ' }],
  signing: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  drug: [
    { word: 'drug', word_class: 'N' },
    { word: 'drug', word_class: 'V' },
    { word: 'drugged', word_class: 'AJ' },
    { word: 'drugging', word_class: 'N' }
  ],
  brilliancy: [
    { word: 'brilliance', word_class: 'N' },
    { word: 'brilliancy', word_class: 'N' },
    { word: 'brilliant', word_class: 'AJ' }
  ],
  racking: [
    { word: 'rack', word_class: 'N' },
    { word: 'rack', word_class: 'V' },
    { word: 'racking', word_class: 'AJ' }
  ],
  folder: [
    { word: 'fold', word_class: 'N' },
    { word: 'fold', word_class: 'V' },
    { word: 'fold', word_class: 'AJ' },
    { word: 'folder', word_class: 'N' },
    { word: 'folded', word_class: 'AJ' },
    { word: 'folding', word_class: 'N' },
    { word: 'folding', word_class: 'AJ' }
  ],
  monastical: [
    { word: 'monastic', word_class: 'N' },
    { word: 'monastic', word_class: 'AJ' },
    { word: 'monastical', word_class: 'AJ' }
  ],
  vibrating: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  alkalize: [
    { word: 'alkalic', word_class: 'AJ' },
    { word: 'alkalize', word_class: 'V' },
    { word: 'alkalizer', word_class: 'N' }
  ],
  subtle: [
    { word: 'subtle', word_class: 'AJ' },
    { word: 'subtly', word_class: 'AV' },
    { word: 'subtlety', word_class: 'N' }
  ],
  jovial: [
    { word: 'jovial', word_class: 'AJ' },
    { word: 'joviality', word_class: 'N' },
    { word: 'jovially', word_class: 'AV' }
  ],
  considerable: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  slipping: [
    { word: 'slip', word_class: 'N' },
    { word: 'slip', word_class: 'V' },
    { word: 'slippage', word_class: 'N' },
    { word: 'slippery', word_class: 'AJ' },
    { word: 'slipping', word_class: 'AJ' },
    { word: 'slipperiness', word_class: 'N' }
  ],
  compensation: [
    { word: 'compensate', word_class: 'V' },
    { word: 'compensable', word_class: 'AJ' },
    { word: 'compensated', word_class: 'AJ' },
    { word: 'compensation', word_class: 'N' },
    { word: 'compensative', word_class: 'AJ' }
  ],
  personalized: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  submerge: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  photoelectrical: [
    { word: 'photoelectric', word_class: 'AJ' },
    { word: 'photoelectrical', word_class: 'AJ' },
    { word: 'photoelectrically', word_class: 'AV' }
  ],
  cognisance: [
    { word: 'cognise', word_class: 'V' },
    { word: 'cognisance', word_class: 'N' },
    { word: 'cognisant', word_class: 'AJ' },
    { word: 'cognisable', word_class: 'AJ' }
  ],
  embroidered: [
    { word: 'embroider', word_class: 'V' },
    { word: 'embroidery', word_class: 'N' },
    { word: 'embroiderer', word_class: 'N' },
    { word: 'embroidered', word_class: 'AJ' }
  ],
  formative: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  flannels: [
    { word: 'flannel', word_class: 'N' },
    { word: 'flannel', word_class: 'V' },
    { word: 'flannels', word_class: 'N' }
  ],
  extern: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  oppositeness: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  dietary: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  chimeric: [
    { word: 'chimeral', word_class: 'AJ' },
    { word: 'chimeric', word_class: 'AJ' },
    { word: 'chimerical', word_class: 'AJ' }
  ],
  indurate: [
    { word: 'indurate', word_class: 'V' },
    { word: 'indurate', word_class: 'AJ' },
    { word: 'induration', word_class: 'N' }
  ],
  perspicuity: [
    { word: 'perspicuity', word_class: 'N' },
    { word: 'perspicuous', word_class: 'AJ' },
    { word: 'perspicuously', word_class: 'AV' },
    { word: 'perspicuousness', word_class: 'N' }
  ],
  tactfulness: [
    { word: 'tact', word_class: 'N' },
    { word: 'tactful', word_class: 'AJ' },
    { word: 'tactfulness', word_class: 'N' }
  ],
  divert: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  rescindment: [
    { word: 'rescind', word_class: 'V' },
    { word: 'rescinding', word_class: 'N' },
    { word: 'rescission', word_class: 'N' },
    { word: 'rescindment', word_class: 'N' },
    { word: 'rescindable', word_class: 'AJ' }
  ],
  virtuosity: [
    { word: 'virtuoso', word_class: 'N' },
    { word: 'virtuoso', word_class: 'AJ' },
    { word: 'virtuosity', word_class: 'N' }
  ],
  terrorize: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  intentional: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  celebration: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  necessitate: [
    { word: 'necessity', word_class: 'N' },
    { word: 'necessitate', word_class: 'V' },
    { word: 'necessitous', word_class: 'AJ' },
    { word: 'necessitation', word_class: 'N' }
  ],
  patching: [
    { word: 'patch', word_class: 'N' },
    { word: 'patch', word_class: 'V' },
    { word: 'patched', word_class: 'AJ' },
    { word: 'patching', word_class: 'N' }
  ],
  quatern: [
    { word: 'quatern', word_class: 'N' },
    { word: 'quaternity', word_class: 'N' },
    { word: 'quaternate', word_class: 'AJ' }
  ],
  moral: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  ostentatiousness: [
    { word: 'ostentatious', word_class: 'AJ' },
    { word: 'ostentatiously', word_class: 'AV' },
    { word: 'ostentatiousness', word_class: 'N' }
  ],
  moronic: [
    { word: 'moron', word_class: 'N' },
    { word: 'morone', word_class: 'N' },
    { word: 'moronic', word_class: 'AJ' },
    { word: 'moronity', word_class: 'N' }
  ],
  ineffectualness: [
    { word: 'ineffectual', word_class: 'AJ' },
    { word: 'ineffectuality', word_class: 'N' },
    { word: 'ineffectually', word_class: 'AV' },
    { word: 'ineffectualness', word_class: 'N' }
  ],
  existing: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  wingman: [
    { word: 'wing', word_class: 'N' },
    { word: 'wing', word_class: 'V' },
    { word: 'wings', word_class: 'N' },
    { word: 'winger', word_class: 'N' },
    { word: 'winged', word_class: 'AJ' },
    { word: 'wingman', word_class: 'N' }
  ],
  canulization: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  mystically: [
    { word: 'mystic', word_class: 'N' },
    { word: 'mystic', word_class: 'AJ' },
    { word: 'mystical', word_class: 'AJ' },
    { word: 'mysticism', word_class: 'N' },
    { word: 'mystically', word_class: 'AV' }
  ],
  boards: [
    { word: 'board', word_class: 'N' },
    { word: 'board', word_class: 'V' },
    { word: 'boards', word_class: 'N' },
    { word: 'boarder', word_class: 'N' },
    { word: 'boarding', word_class: 'N' }
  ],
  chlorophyl: [
    { word: 'chlorophyl', word_class: 'N' },
    { word: 'chlorophyll', word_class: 'N' },
    { word: 'chlorophyllous', word_class: 'AJ' }
  ],
  consoling: [
    { word: 'console', word_class: 'N' },
    { word: 'console', word_class: 'V' },
    { word: 'consoling', word_class: 'AJ' },
    { word: 'consolable', word_class: 'AJ' },
    { word: 'consolation', word_class: 'N' },
    { word: 'consolement', word_class: 'N' }
  ],
  singularize: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  retentivity: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  overriding: [
    { word: 'override', word_class: 'N' },
    { word: 'override', word_class: 'V' },
    { word: 'overriding', word_class: 'AJ' }
  ],
  quelled: [
    { word: 'quell', word_class: 'V' },
    { word: 'quelled', word_class: 'AJ' },
    { word: 'quelling', word_class: 'N' }
  ],
  contributive: [
    { word: 'contribute', word_class: 'V' },
    { word: 'contributor', word_class: 'N' },
    { word: 'contribution', word_class: 'N' },
    { word: 'contributing', word_class: 'AJ' },
    { word: 'contributive', word_class: 'AJ' }
  ],
  loathe: [
    { word: 'loath', word_class: 'V' },
    { word: 'loath', word_class: 'AJ' },
    { word: 'loathe', word_class: 'V' },
    { word: 'loathing', word_class: 'N' },
    { word: 'loathsome', word_class: 'AJ' },
    { word: 'loathsomeness', word_class: 'N' }
  ],
  outfit: [
    { word: 'outfit', word_class: 'N' },
    { word: 'outfit', word_class: 'V' },
    { word: 'outfitted', word_class: 'AJ' },
    { word: 'outfitting', word_class: 'N' }
  ],
  information: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  slender: [
    { word: 'slender', word_class: 'AJ' },
    { word: 'slenderize', word_class: 'V' },
    { word: 'slenderness', word_class: 'N' },
    { word: 'slenderization', word_class: 'N' }
  ],
  chastening: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  purveyance: [
    { word: 'purvey', word_class: 'V' },
    { word: 'purveyor', word_class: 'N' },
    { word: 'purveyance', word_class: 'N' }
  ],
  liberator: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  evidential: [
    { word: 'evidence', word_class: 'N' },
    { word: 'evidence', word_class: 'V' },
    { word: 'evident', word_class: 'AJ' },
    { word: 'evidently', word_class: 'AV' },
    { word: 'evidential', word_class: 'AJ' }
  ],
  predicate: [
    { word: 'predicate', word_class: 'N' },
    { word: 'predicate', word_class: 'V' },
    { word: 'predicate', word_class: 'AJ' },
    { word: 'predicator', word_class: 'N' },
    { word: 'predication', word_class: 'N' },
    { word: 'predicative', word_class: 'AJ' },
    { word: 'predicatively', word_class: 'AV' }
  ],
  nestled: [
    { word: 'nestle', word_class: 'N' },
    { word: 'nestle', word_class: 'V' },
    { word: 'nestled', word_class: 'AJ' },
    { word: 'nestling', word_class: 'N' }
  ],
  barring: [
    { word: 'bar', word_class: 'N' },
    { word: 'bar', word_class: 'V' },
    { word: 'bars', word_class: 'N' },
    { word: 'barman', word_class: 'N' },
    { word: 'barred', word_class: 'AJ' },
    { word: 'barring', word_class: 'N' }
  ],
  drier: [
    { word: 'dry', word_class: 'N' },
    { word: 'dry', word_class: 'V' },
    { word: 'dry', word_class: 'AJ' },
    { word: 'drier', word_class: 'N' },
    { word: 'dryer', word_class: 'N' },
    { word: 'dryness', word_class: 'N' }
  ],
  sagaciously: [
    { word: 'sagacious', word_class: 'AJ' },
    { word: 'sagaciously', word_class: 'AV' },
    { word: 'sagaciousness', word_class: 'N' }
  ],
  retail: [
    { word: 'retail', word_class: 'N' },
    { word: 'retail', word_class: 'V' },
    { word: 'retail', word_class: 'AJ' },
    { word: 'retail', word_class: 'AV' },
    { word: 'retailer', word_class: 'N' },
    { word: 'retailing', word_class: 'N' }
  ],
  infoldment: [
    { word: 'infold', word_class: 'V' },
    { word: 'infolding', word_class: 'N' },
    { word: 'infoldment', word_class: 'N' }
  ],
  sanitizing: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  hygienics: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  making: [
    { word: 'make', word_class: 'N' },
    { word: 'make', word_class: 'V' },
    { word: 'maker', word_class: 'N' },
    { word: 'making', word_class: 'N' }
  ],
  declarable: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  marauding: [
    { word: 'maraud', word_class: 'N' },
    { word: 'maraud', word_class: 'V' },
    { word: 'marauder', word_class: 'N' },
    { word: 'marauding', word_class: 'N' },
    { word: 'marauding', word_class: 'AJ' }
  ],
  mineralise: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  sprinter: [
    { word: 'sprint', word_class: 'N' },
    { word: 'sprint', word_class: 'V' },
    { word: 'sprinter', word_class: 'N' }
  ],
  gadgeteer: [
    { word: 'gadget', word_class: 'N' },
    { word: 'gadgetry', word_class: 'N' },
    { word: 'gadgeteer', word_class: 'N' }
  ],
  secularized: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  cowl: [
    { word: 'cowl', word_class: 'N' },
    { word: 'cowl', word_class: 'V' },
    { word: 'cowled', word_class: 'AJ' },
    { word: 'cowling', word_class: 'N' }
  ],
  forgiven: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  suffixation: [
    { word: 'suffix', word_class: 'N' },
    { word: 'suffix', word_class: 'V' },
    { word: 'suffixion', word_class: 'N' },
    { word: 'suffixation', word_class: 'N' }
  ],
  spontaneity: [
    { word: 'spontaneity', word_class: 'N' },
    { word: 'spontaneous', word_class: 'AJ' },
    { word: 'spontaneously', word_class: 'AV' },
    { word: 'spontaneousness', word_class: 'N' }
  ],
  stud: [
    { word: 'stud', word_class: 'N' },
    { word: 'stud', word_class: 'V' },
    { word: 'student', word_class: 'N' },
    { word: 'studded', word_class: 'AJ' }
  ],
  commercialization: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  pessimistically: [
    { word: 'pessimist', word_class: 'N' },
    { word: 'pessimistic', word_class: 'AJ' },
    { word: 'pessimistically', word_class: 'AV' }
  ],
  ordained: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  avarice: [
    { word: 'avarice', word_class: 'N' },
    { word: 'avaricious', word_class: 'AJ' },
    { word: 'avariciously', word_class: 'AV' },
    { word: 'avariciousness', word_class: 'N' }
  ],
  conglomeration: [
    { word: 'conglomerate', word_class: 'N' },
    { word: 'conglomerate', word_class: 'V' },
    { word: 'conglomerate', word_class: 'AJ' },
    { word: 'conglomeration', word_class: 'N' }
  ],
  smolder: [
    { word: 'smolder', word_class: 'N' },
    { word: 'smolder', word_class: 'V' },
    { word: 'smoldering', word_class: 'AJ' }
  ],
  pungently: [
    { word: 'pungency', word_class: 'N' },
    { word: 'pungent', word_class: 'AJ' },
    { word: 'pungently', word_class: 'AV' }
  ],
  profession: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  steadying: [
    { word: 'steady', word_class: 'N' },
    { word: 'steady', word_class: 'V' },
    { word: 'steady', word_class: 'AJ' },
    { word: 'steady', word_class: 'AV' },
    { word: 'steadied', word_class: 'AJ' },
    { word: 'steadiness', word_class: 'N' },
    { word: 'steadying', word_class: 'AJ' }
  ],
  genealogical: [
    { word: 'genealogy', word_class: 'N' },
    { word: 'genealogic', word_class: 'AJ' },
    { word: 'genealogical', word_class: 'AJ' },
    { word: 'genealogically', word_class: 'AV' }
  ],
  dissimulating: [
    { word: 'dissimulate', word_class: 'V' },
    { word: 'dissimulation', word_class: 'N' },
    { word: 'dissimulating', word_class: 'AJ' },
    { word: 'dissimulative', word_class: 'AJ' }
  ],
  fluidisation: [
    { word: 'fluidize', word_class: 'V' },
    { word: 'fluidisation', word_class: 'N' },
    { word: 'fluidization', word_class: 'N' }
  ],
  blocker: [
    { word: 'block', word_class: 'N' },
    { word: 'block', word_class: 'V' },
    { word: 'blocker', word_class: 'N' },
    { word: 'blockage', word_class: 'N' },
    { word: 'blocked', word_class: 'AJ' },
    { word: 'blocking', word_class: 'N' }
  ],
  insertion: [
    { word: 'insert', word_class: 'N' },
    { word: 'insert', word_class: 'V' },
    { word: 'insertion', word_class: 'N' }
  ],
  thirties: [
    { word: 'thirty', word_class: 'N' },
    { word: 'thirty', word_class: 'AJ' },
    { word: 'thirties', word_class: 'N' }
  ],
  egress: [
    { word: 'egress', word_class: 'N' },
    { word: 'egress', word_class: 'V' },
    { word: 'egression', word_class: 'N' }
  ],
  amiably: [
    { word: 'amiable', word_class: 'AJ' },
    { word: 'amiably', word_class: 'AV' },
    { word: 'amiability', word_class: 'N' },
    { word: 'amiableness', word_class: 'N' }
  ],
  islamisation: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  invariant: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  rowing: [
    { word: 'row', word_class: 'N' },
    { word: 'row', word_class: 'V' },
    { word: 'rower', word_class: 'N' },
    { word: 'rowing', word_class: 'N' }
  ],
  modifiable: [
    { word: 'modify', word_class: 'V' },
    { word: 'modifier', word_class: 'N' },
    { word: 'modified', word_class: 'AJ' },
    { word: 'modifiable', word_class: 'AJ' },
    { word: 'modification', word_class: 'N' }
  ],
  equivocally: [
    { word: 'equivocal', word_class: 'AJ' },
    { word: 'equivocate', word_class: 'V' },
    { word: 'equivocally', word_class: 'AV' },
    { word: 'equivocation', word_class: 'N' },
    { word: 'equivocalness', word_class: 'N' }
  ],
  business: [
    { word: 'busy', word_class: 'V' },
    { word: 'busy', word_class: 'AJ' },
    { word: 'busying', word_class: 'N' },
    { word: 'business', word_class: 'N' },
    { word: 'busyness', word_class: 'N' },
    { word: 'business', word_class: 'AJ' }
  ],
  elitism: [
    { word: 'elite', word_class: 'N' },
    { word: 'elite', word_class: 'AJ' },
    { word: 'elitism', word_class: 'N' }
  ],
  reliably: [
    { word: 'reliable', word_class: 'AJ' },
    { word: 'reliably', word_class: 'AV' },
    { word: 'reliability', word_class: 'N' },
    { word: 'reliableness', word_class: 'N' }
  ],
  solidness: [
    { word: 'solid', word_class: 'N' },
    { word: 'solid', word_class: 'AJ' },
    { word: 'solidity', word_class: 'N' },
    { word: 'solidness', word_class: 'N' }
  ],
  pulsed: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  symbolise: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  dramatics: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  hands: [
    { word: 'hand', word_class: 'N' },
    { word: 'hand', word_class: 'V' },
    { word: 'hands', word_class: 'N' },
    { word: 'handed', word_class: 'AJ' },
    { word: 'handful', word_class: 'N' },
    { word: 'handful', word_class: 'AJ' }
  ],
  instilling: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  sterilize: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  unforgivably: [
    { word: 'unforgiving', word_class: 'AJ' },
    { word: 'unforgivable', word_class: 'AJ' },
    { word: 'unforgivably', word_class: 'AV' }
  ],
  bath: [
    { word: 'bath', word_class: 'N' },
    { word: 'bath', word_class: 'V' },
    { word: 'bathe', word_class: 'N' },
    { word: 'bathe', word_class: 'V' },
    { word: 'bather', word_class: 'N' },
    { word: 'bathing', word_class: 'N' }
  ],
  offering: [
    { word: 'offer', word_class: 'N' },
    { word: 'offer', word_class: 'V' },
    { word: 'offering', word_class: 'N' }
  ],
  barricade: [
    { word: 'barricade', word_class: 'N' },
    { word: 'barricade', word_class: 'V' },
    { word: 'barricaded', word_class: 'AJ' }
  ],
  interpreted: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  weather: [
    { word: 'weather', word_class: 'N' },
    { word: 'weather', word_class: 'V' },
    { word: 'weather', word_class: 'AJ' },
    { word: 'weathered', word_class: 'AJ' },
    { word: 'weatherman', word_class: 'N' }
  ],
  internal: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  taxonomical: [
    { word: 'taxonomic', word_class: 'AJ' },
    { word: 'taxonomical', word_class: 'AJ' },
    { word: 'taxonomically', word_class: 'AV' }
  ],
  orbit: [
    { word: 'orbit', word_class: 'N' },
    { word: 'orbit', word_class: 'V' },
    { word: 'orbital', word_class: 'AJ' }
  ],
  cutting: [
    { word: 'cut', word_class: 'N' },
    { word: 'cut', word_class: 'V' },
    { word: 'cut', word_class: 'AJ' },
    { word: 'cutting', word_class: 'N' },
    { word: 'cutting', word_class: 'AJ' }
  ],
  persistency: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  eccentricity: [
    { word: 'eccentric', word_class: 'N' },
    { word: 'eccentric', word_class: 'AJ' },
    { word: 'eccentricity', word_class: 'N' }
  ],
  obsessive: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  dog: [
    { word: 'dog', word_class: 'N' },
    { word: 'dog', word_class: 'V' },
    { word: 'dogged', word_class: 'AJ' },
    { word: 'dogging', word_class: 'AJ' }
  ],
  genitals: [
    { word: 'genital', word_class: 'N' },
    { word: 'genital', word_class: 'AJ' },
    { word: 'genitals', word_class: 'N' },
    { word: 'genitive', word_class: 'N' },
    { word: 'genitive', word_class: 'AJ' }
  ],
  compressing: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  burr: [
    { word: 'bur', word_class: 'N' },
    { word: 'bur', word_class: 'V' },
    { word: 'burr', word_class: 'N' },
    { word: 'burr', word_class: 'V' },
    { word: 'burred', word_class: 'AJ' },
    { word: 'burring', word_class: 'N' }
  ],
  dish: [
    { word: 'dish', word_class: 'N' },
    { word: 'dish', word_class: 'V' },
    { word: 'dished', word_class: 'AJ' },
    { word: 'dishful', word_class: 'N' },
    { word: 'dishful', word_class: 'AJ' }
  ],
  undersealed: [
    { word: 'underseal', word_class: 'N' },
    { word: 'undersize', word_class: 'AJ' },
    { word: 'undersized', word_class: 'AJ' },
    { word: 'undersealed', word_class: 'AJ' }
  ],
  inevitability: [
    { word: 'inevitable', word_class: 'N' },
    { word: 'inevitable', word_class: 'AJ' },
    { word: 'inevitably', word_class: 'AV' },
    { word: 'inevitability', word_class: 'N' },
    { word: 'inevitableness', word_class: 'N' }
  ],
  viewing: [
    { word: 'view', word_class: 'N' },
    { word: 'view', word_class: 'V' },
    { word: 'viewer', word_class: 'N' },
    { word: 'viewers', word_class: 'N' },
    { word: 'viewing', word_class: 'N' }
  ],
  resignation: [
    { word: 'resign', word_class: 'N' },
    { word: 'resign', word_class: 'V' },
    { word: 'resigned', word_class: 'AJ' },
    { word: 'resignation', word_class: 'N' }
  ],
  oppress: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  breached: [
    { word: 'breach', word_class: 'N' },
    { word: 'breach', word_class: 'V' },
    { word: 'broach', word_class: 'N' },
    { word: 'broach', word_class: 'V' },
    { word: 'breached', word_class: 'AJ' },
    { word: 'broached', word_class: 'AJ' }
  ],
  stockholding: [
    { word: 'stockholder', word_class: 'N' },
    { word: 'stockholding', word_class: 'N' },
    { word: 'stockholdings', word_class: 'N' }
  ],
  radiation: [
    { word: 'radiate', word_class: 'V' },
    { word: 'radiator', word_class: 'N' },
    { word: 'radiation', word_class: 'N' },
    { word: 'radiating', word_class: 'AJ' }
  ],
  sociably: [
    { word: 'sociable', word_class: 'N' },
    { word: 'sociable', word_class: 'AJ' },
    { word: 'sociably', word_class: 'AV' },
    { word: 'sociability', word_class: 'N' },
    { word: 'sociableness', word_class: 'N' }
  ],
  detribalize: [
    { word: 'detribalise', word_class: 'V' },
    { word: 'detribalize', word_class: 'V' },
    { word: 'detribalisation', word_class: 'N' },
    { word: 'detribalization', word_class: 'N' }
  ],
  embracement: [
    { word: 'embrace', word_class: 'N' },
    { word: 'embrace', word_class: 'V' },
    { word: 'embracing', word_class: 'N' },
    { word: 'embracement', word_class: 'N' }
  ],
  somnolent: [
    { word: 'somnolence', word_class: 'N' },
    { word: 'somnolent', word_class: 'AJ' },
    { word: 'somnolently', word_class: 'AV' }
  ],
  rive: [{ word: 'rive', word_class: 'V' }, { word: 'river', word_class: 'N' }, { word: 'riverine', word_class: 'AJ' }],
  inshore: [
    { word: 'inshore', word_class: 'N' },
    { word: 'inshore', word_class: 'V' },
    { word: 'inshore', word_class: 'AJ' },
    { word: 'inshore', word_class: 'AV' }
  ],
  braid: [
    { word: 'braid', word_class: 'N' },
    { word: 'braid', word_class: 'V' },
    { word: 'braided', word_class: 'AJ' },
    { word: 'braiding', word_class: 'N' }
  ],
  baiting: [{ word: 'bait', word_class: 'N' }, { word: 'bait', word_class: 'V' }, { word: 'baiting', word_class: 'N' }],
  arguing: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  chase: [
    { word: 'chase', word_class: 'N' },
    { word: 'chase', word_class: 'V' },
    { word: 'chased', word_class: 'N' },
    { word: 'chaser', word_class: 'N' }
  ],
  pertinent: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  coordinating: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  neutralized: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  ascribe: [
    { word: 'ascribe', word_class: 'V' },
    { word: 'ascription', word_class: 'N' },
    { word: 'ascribable', word_class: 'AJ' }
  ],
  sevener: [
    { word: 'seven', word_class: 'N' },
    { word: 'seven', word_class: 'AJ' },
    { word: 'sevens', word_class: 'N' },
    { word: 'sevener', word_class: 'N' }
  ],
  announced: [
    { word: 'announce', word_class: 'V' },
    { word: 'announcer', word_class: 'N' },
    { word: 'announced', word_class: 'AJ' },
    { word: 'anunciation', word_class: 'N' },
    { word: 'announcement', word_class: 'N' }
  ],
  bombastic: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  reinforce: [
    { word: 'reinforce', word_class: 'V' },
    { word: 'reinforcer', word_class: 'N' },
    { word: 'reinforced', word_class: 'AJ' },
    { word: 'reinforcement', word_class: 'N' }
  ],
  etiologic: [
    { word: 'etiology', word_class: 'N' },
    { word: 'etiologic', word_class: 'AJ' },
    { word: 'etiological', word_class: 'AJ' }
  ],
  stigmatisation: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  visualisation: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  slumbering: [
    { word: 'slumber', word_class: 'N' },
    { word: 'slumber', word_class: 'V' },
    { word: 'slumberer', word_class: 'N' },
    { word: 'slumbering', word_class: 'AJ' },
    { word: 'slumberous', word_class: 'AJ' }
  ],
  hewn: [{ word: 'hew', word_class: 'V' }, { word: 'hewer', word_class: 'N' }, { word: 'hewn', word_class: 'AJ' }],
  granted: [
    { word: 'grant', word_class: 'N' },
    { word: 'grant', word_class: 'V' },
    { word: 'grantee', word_class: 'N' },
    { word: 'grantor', word_class: 'N' },
    { word: 'granted', word_class: 'AJ' }
  ],
  transistorized: [
    { word: 'transistor', word_class: 'N' },
    { word: 'transistorise', word_class: 'V' },
    { word: 'transistorize', word_class: 'V' },
    { word: 'transistorized', word_class: 'AJ' },
    { word: 'transistorization', word_class: 'N' }
  ],
  armour: [
    { word: 'armour', word_class: 'N' },
    { word: 'armour', word_class: 'V' },
    { word: 'armourer', word_class: 'N' },
    { word: 'armoured', word_class: 'AJ' }
  ],
  perturbed: [
    { word: 'perturb', word_class: 'V' },
    { word: 'perturbate', word_class: 'V' },
    { word: 'perturbed', word_class: 'AJ' },
    { word: 'perturbing', word_class: 'AJ' },
    { word: 'perturbation', word_class: 'N' }
  ],
  letters: [
    { word: 'letter', word_class: 'N' },
    { word: 'letter', word_class: 'V' },
    { word: 'letters', word_class: 'N' },
    { word: 'lettered', word_class: 'AJ' },
    { word: 'lettering', word_class: 'N' },
    { word: 'letterman', word_class: 'N' }
  ],
  convulsively: [
    { word: 'convulse', word_class: 'V' },
    { word: 'convulsion', word_class: 'N' },
    { word: 'convulsive', word_class: 'AJ' },
    { word: 'convulsively', word_class: 'AV' }
  ],
  importunately: [
    { word: 'importune', word_class: 'V' },
    { word: 'importunacy', word_class: 'N' },
    { word: 'importunity', word_class: 'N' },
    { word: 'importunate', word_class: 'AJ' },
    { word: 'importunately', word_class: 'AV' }
  ],
  stern: [
    { word: 'stern', word_class: 'N' },
    { word: 'stern', word_class: 'AJ' },
    { word: 'sternness', word_class: 'N' }
  ],
  groan: [
    { word: 'groan', word_class: 'N' },
    { word: 'groan', word_class: 'V' },
    { word: 'groaning', word_class: 'AJ' }
  ],
  publicize: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  concerned: [
    { word: 'concrn', word_class: 'V' },
    { word: 'concern', word_class: 'N' },
    { word: 'concern', word_class: 'V' },
    { word: 'concerned', word_class: 'AJ' },
    { word: 'concerning', word_class: 'AJ' }
  ],
  monitor: [
    { word: 'monitor', word_class: 'N' },
    { word: 'monitor', word_class: 'V' },
    { word: 'monitoring', word_class: 'N' }
  ],
  climatically: [
    { word: 'climate', word_class: 'N' },
    { word: 'climatic', word_class: 'AJ' },
    { word: 'climatical', word_class: 'AJ' },
    { word: 'climatically', word_class: 'AV' }
  ],
  shine: [
    { word: 'shine', word_class: 'N' },
    { word: 'shine', word_class: 'V' },
    { word: 'shiny', word_class: 'AJ' },
    { word: 'shiner', word_class: 'N' },
    { word: 'shining', word_class: 'N' },
    { word: 'shining', word_class: 'AJ' },
    { word: 'shininess', word_class: 'N' }
  ],
  litigation: [
    { word: 'litigant', word_class: 'N' },
    { word: 'litigate', word_class: 'V' },
    { word: 'litigator', word_class: 'N' },
    { word: 'litigation', word_class: 'N' }
  ],
  dietetic: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  repentant: [
    { word: 'repent', word_class: 'V' },
    { word: 'repent', word_class: 'AJ' },
    { word: 'repentant', word_class: 'N' },
    { word: 'repentance', word_class: 'N' },
    { word: 'repentant', word_class: 'AJ' }
  ],
  patrolling: [
    { word: 'patrol', word_class: 'N' },
    { word: 'patrol', word_class: 'V' },
    { word: 'patroller', word_class: 'N' },
    { word: 'patrolman', word_class: 'N' },
    { word: 'patrolled', word_class: 'AJ' },
    { word: 'patrolling', word_class: 'N' },
    { word: 'patrolling', word_class: 'AJ' }
  ],
  stayman: [
    { word: 'stay', word_class: 'N' },
    { word: 'stay', word_class: 'V' },
    { word: 'stays', word_class: 'N' },
    { word: 'stayer', word_class: 'N' },
    { word: 'stayman', word_class: 'N' }
  ],
  excess: [
    { word: 'exceed', word_class: 'V' },
    { word: 'excess', word_class: 'N' },
    { word: 'excess', word_class: 'AJ' },
    { word: 'excessive', word_class: 'AJ' },
    { word: 'excessively', word_class: 'AV' },
    { word: 'excessiveness', word_class: 'N' }
  ],
  tiller: [
    { word: 'till', word_class: 'V' },
    { word: 'till', word_class: 'N' },
    { word: 'tiller', word_class: 'N' },
    { word: 'tiller', word_class: 'V' },
    { word: 'tillage', word_class: 'N' },
    { word: 'tilled', word_class: 'AJ' },
    { word: 'tilling', word_class: 'N' }
  ],
  nostalgic: [
    { word: 'nostalgia', word_class: 'N' },
    { word: 'nostalgic', word_class: 'AJ' },
    { word: 'nostalgically', word_class: 'AV' }
  ],
  syncretisation: [
    { word: 'syncretise', word_class: 'V' },
    { word: 'syncretize', word_class: 'V' },
    { word: 'syncretisation', word_class: 'N' },
    { word: 'syncretization', word_class: 'N' }
  ],
  streaker: [
    { word: 'streak', word_class: 'N' },
    { word: 'streak', word_class: 'V' },
    { word: 'streaker', word_class: 'N' },
    { word: 'streaked', word_class: 'AJ' }
  ],
  savage: [
    { word: 'savage', word_class: 'V' },
    { word: 'savage', word_class: 'N' },
    { word: 'savage', word_class: 'AJ' },
    { word: 'savagery', word_class: 'N' },
    { word: 'savagely', word_class: 'AV' },
    { word: 'savagery', word_class: 'AJ' },
    { word: 'savageness', word_class: 'N' }
  ],
  redeem: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  opposition: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  indecision: [
    { word: 'indecision', word_class: 'N' },
    { word: 'indecisive', word_class: 'AJ' },
    { word: 'indecisively', word_class: 'AV' },
    { word: 'indecisiveness', word_class: 'N' }
  ],
  rifleman: [
    { word: 'rifle', word_class: 'N' },
    { word: 'rifle', word_class: 'V' },
    { word: 'rifled', word_class: 'AJ' },
    { word: 'rifling', word_class: 'N' },
    { word: 'rifleman', word_class: 'N' }
  ],
  end: [
    { word: 'end', word_class: 'N' },
    { word: 'end', word_class: 'V' },
    { word: 'ended', word_class: 'AJ' },
    { word: 'ending', word_class: 'N' }
  ],
  denaturation: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  strategist: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  rejected: [
    { word: 'reject', word_class: 'N' },
    { word: 'reject', word_class: 'V' },
    { word: 'rejected', word_class: 'AJ' },
    { word: 'rejection', word_class: 'N' },
    { word: 'rejective', word_class: 'AJ' }
  ],
  plodding: [
    { word: 'plod', word_class: 'N' },
    { word: 'plod', word_class: 'V' },
    { word: 'plodding', word_class: 'N' },
    { word: 'plodding', word_class: 'AJ' }
  ],
  cannibalism: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  micturate: [
    { word: 'micturate', word_class: 'V' },
    { word: 'micturation', word_class: 'N' },
    { word: 'micturition', word_class: 'N' }
  ],
  dustman: [
    { word: 'dust', word_class: 'N' },
    { word: 'dust', word_class: 'V' },
    { word: 'duster', word_class: 'N' },
    { word: 'dustman', word_class: 'N' }
  ],
  hastiness: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  injudiciousness: [
    { word: 'injudicious', word_class: 'AJ' },
    { word: 'injudiciously', word_class: 'AV' },
    { word: 'injudiciousness', word_class: 'N' }
  ],
  eulogy: [
    { word: 'eulogy', word_class: 'N' },
    { word: 'eulogist', word_class: 'N' },
    { word: 'eulogize', word_class: 'V' },
    { word: 'eulogistic', word_class: 'AJ' },
    { word: 'eulogization', word_class: 'N' }
  ],
  affinal: [
    { word: 'affine', word_class: 'N' },
    { word: 'affine', word_class: 'AJ' },
    { word: 'affinal', word_class: 'AJ' },
    { word: 'affined', word_class: 'AJ' },
    { word: 'affinity', word_class: 'N' }
  ],
  anaesthetic: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  intractability: [
    { word: 'intractable', word_class: 'AJ' },
    { word: 'intractability', word_class: 'N' },
    { word: 'intractableness', word_class: 'N' }
  ],
  fill: [
    { word: 'fill', word_class: 'N' },
    { word: 'fill', word_class: 'V' },
    { word: 'fille', word_class: 'N' },
    { word: 'filler', word_class: 'N' },
    { word: 'filled', word_class: 'AJ' },
    { word: 'filling', word_class: 'N' }
  ],
  upright: [
    { word: 'upright', word_class: 'N' },
    { word: 'upright', word_class: 'V' },
    { word: 'upright', word_class: 'AJ' },
    { word: 'uprightness', word_class: 'N' }
  ],
  skeletonise: [
    { word: 'skeleton', word_class: 'N' },
    { word: 'skeletal', word_class: 'AJ' },
    { word: 'skeletonise', word_class: 'V' },
    { word: 'skeletonize', word_class: 'V' },
    { word: 'skeletonization', word_class: 'N' }
  ],
  sexuality: [
    { word: 'sexual', word_class: 'AJ' },
    { word: 'sexuality', word_class: 'N' },
    { word: 'sexually', word_class: 'AV' }
  ],
  companionably: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  overprotection: [
    { word: 'overprotect', word_class: 'V' },
    { word: 'overprotection', word_class: 'N' },
    { word: 'overprotective', word_class: 'AJ' }
  ],
  shuffling: [
    { word: 'shuffle', word_class: 'N' },
    { word: 'shuffle', word_class: 'V' },
    { word: 'shuffler', word_class: 'N' },
    { word: 'shuffling', word_class: 'N' },
    { word: 'shuffling', word_class: 'AJ' }
  ],
  lingerer: [
    { word: 'linger', word_class: 'V' },
    { word: 'lingerer', word_class: 'N' },
    { word: 'lingering', word_class: 'N' },
    { word: 'lingering', word_class: 'AJ' }
  ],
  evasiveness: [
    { word: 'evade', word_class: 'V' },
    { word: 'evasion', word_class: 'N' },
    { word: 'evasive', word_class: 'AJ' },
    { word: 'evasively', word_class: 'AV' },
    { word: 'evasiveness', word_class: 'N' }
  ],
  hollering: [
    { word: 'holler', word_class: 'N' },
    { word: 'holler', word_class: 'V' },
    { word: 'hollering', word_class: 'N' }
  ],
  battering: [
    { word: 'batter', word_class: 'N' },
    { word: 'batter', word_class: 'V' },
    { word: 'battery', word_class: 'N' },
    { word: 'battery', word_class: 'AJ' },
    { word: 'battered', word_class: 'AJ' },
    { word: 'battering', word_class: 'N' }
  ],
  junked: [
    { word: 'junk', word_class: 'N' },
    { word: 'junk', word_class: 'V' },
    { word: 'junker', word_class: 'N' },
    { word: 'junked', word_class: 'AJ' }
  ],
  polymerize: [
    { word: 'polymeric', word_class: 'AJ' },
    { word: 'polymerise', word_class: 'V' },
    { word: 'polymerize', word_class: 'V' },
    { word: 'polymerisation', word_class: 'N' },
    { word: 'polymerization', word_class: 'N' }
  ],
  marvellously: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  decrement: [
    { word: 'decree', word_class: 'N' },
    { word: 'decree', word_class: 'V' },
    { word: 'decreed', word_class: 'AJ' },
    { word: 'decrement', word_class: 'N' },
    { word: 'decrement', word_class: 'V' }
  ],
  interchangeably: [
    { word: 'interchange', word_class: 'N' },
    { word: 'interchange', word_class: 'V' },
    { word: 'interchangeable', word_class: 'AJ' },
    { word: 'interchangeably', word_class: 'AV' },
    { word: 'interchangeability', word_class: 'N' },
    { word: 'interchangeableness', word_class: 'N' }
  ],
  humiliate: [
    { word: 'humiliate', word_class: 'V' },
    { word: 'humiliated', word_class: 'AJ' },
    { word: 'humiliation', word_class: 'N' },
    { word: 'humiliating', word_class: 'AJ' }
  ],
  housekeeping: [
    { word: 'housekeep', word_class: 'V' },
    { word: 'housekeeper', word_class: 'N' },
    { word: 'housekeeping', word_class: 'N' }
  ],
  westernisation: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  're-creation': [
    { word: 're-create', word_class: 'V' },
    { word: 're-created', word_class: 'AJ' },
    { word: 're-creation', word_class: 'N' }
  ],
  exorcist: [
    { word: 'exorcise', word_class: 'V' },
    { word: 'exorcism', word_class: 'N' },
    { word: 'exorcist', word_class: 'N' },
    { word: 'exorcize', word_class: 'V' },
    { word: 'exorciser', word_class: 'N' }
  ],
  stem: [{ word: 'stem', word_class: 'N' }, { word: 'stem', word_class: 'V' }, { word: 'stemmed', word_class: 'AJ' }],
  crown: [
    { word: 'crown', word_class: 'N' },
    { word: 'crown', word_class: 'V' },
    { word: 'crowned', word_class: 'AJ' },
    { word: 'crowning', word_class: 'AJ' }
  ],
  stratify: [
    { word: 'stratify', word_class: 'V' },
    { word: 'stratified', word_class: 'AJ' },
    { word: 'stratification', word_class: 'N' }
  ],
  weeded: [
    { word: 'weed', word_class: 'N' },
    { word: 'weed', word_class: 'V' },
    { word: 'weeds', word_class: 'N' },
    { word: 'weeded', word_class: 'AJ' }
  ],
  palatably: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  planer: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  kindness: [
    { word: 'kind', word_class: 'N' },
    { word: 'kind', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AV' },
    { word: 'kindness', word_class: 'N' },
    { word: 'kindliness', word_class: 'N' }
  ],
  necessity: [
    { word: 'necessity', word_class: 'N' },
    { word: 'necessitate', word_class: 'V' },
    { word: 'necessitous', word_class: 'AJ' },
    { word: 'necessitation', word_class: 'N' }
  ],
  emptied: [
    { word: 'empty', word_class: 'N' },
    { word: 'empty', word_class: 'V' },
    { word: 'empty', word_class: 'AJ' },
    { word: 'emptied', word_class: 'AJ' },
    { word: 'emptying', word_class: 'N' },
    { word: 'emptiness', word_class: 'N' }
  ],
  transcendentalism: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  masculinization: [
    { word: 'masculine', word_class: 'N' },
    { word: 'masculine', word_class: 'AJ' },
    { word: 'masculinity', word_class: 'N' },
    { word: 'masculinization', word_class: 'N' }
  ],
  potentially: [
    { word: 'potential', word_class: 'N' },
    { word: 'potential', word_class: 'AJ' },
    { word: 'potentiate', word_class: 'V' },
    { word: 'potentiality', word_class: 'N' },
    { word: 'potentially', word_class: 'AV' },
    { word: 'potentiation', word_class: 'N' }
  ],
  hypnotism: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  fledgling: [
    { word: 'fledge', word_class: 'V' },
    { word: 'fledged', word_class: 'AJ' },
    { word: 'fledgling', word_class: 'N' },
    { word: 'fledgling', word_class: 'AJ' }
  ],
  shintoist: [
    { word: 'shintoist', word_class: 'N' },
    { word: 'shintoist', word_class: 'AJ' },
    { word: 'shintoistic', word_class: 'AJ' }
  ],
  reputed: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  continuing: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  sham: [{ word: 'sham', word_class: 'N' }, { word: 'sham', word_class: 'V' }, { word: 'sham', word_class: 'AJ' }],
  desirability: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  collectivist: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  augur: [{ word: 'augur', word_class: 'N' }, { word: 'augur', word_class: 'V' }, { word: 'augury', word_class: 'N' }],
  dynamize: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  disreputably: [
    { word: 'disrepute', word_class: 'N' },
    { word: 'disreputable', word_class: 'AJ' },
    { word: 'disreputably', word_class: 'AV' },
    { word: 'disreputability', word_class: 'N' },
    { word: 'disreputableness', word_class: 'N' }
  ],
  impressive: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  acidophil: [
    { word: 'acidophil', word_class: 'N' },
    { word: 'acidophile', word_class: 'N' },
    { word: 'acidophilic', word_class: 'AJ' },
    { word: 'acidophilous', word_class: 'AJ' }
  ],
  corporeality: [
    { word: 'corporal', word_class: 'N' },
    { word: 'corporal', word_class: 'AJ' },
    { word: 'corporate', word_class: 'AJ' },
    { word: 'corporeal', word_class: 'AJ' },
    { word: 'corporation', word_class: 'N' },
    { word: 'corporeality', word_class: 'N' }
  ],
  upset: [
    { word: 'upset', word_class: 'N' },
    { word: 'upset', word_class: 'V' },
    { word: 'upset', word_class: 'AJ' },
    { word: 'upsetting', word_class: 'AJ' }
  ],
  repressed: [
    { word: 'repress', word_class: 'N' },
    { word: 'repress', word_class: 'V' },
    { word: 'repressed', word_class: 'AJ' },
    { word: 'repression', word_class: 'N' },
    { word: 'repressing', word_class: 'AJ' },
    { word: 'repressive', word_class: 'AJ' }
  ],
  subjective: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  interrelate: [
    { word: 'interrelate', word_class: 'V' },
    { word: 'interrelated', word_class: 'AJ' },
    { word: 'interrelation', word_class: 'N' },
    { word: 'interrelationship', word_class: 'N' }
  ],
  refine: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  configured: [
    { word: 'configure', word_class: 'V' },
    { word: 'configured', word_class: 'AJ' },
    { word: 'configuration', word_class: 'N' },
    { word: 'configurational', word_class: 'AJ' }
  ],
  ornateness: [
    { word: 'ornate', word_class: 'AJ' },
    { word: 'ornately', word_class: 'AV' },
    { word: 'ornateness', word_class: 'N' }
  ],
  conferral: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  inure: [
    { word: 'inure', word_class: 'V' },
    { word: 'inured', word_class: 'AJ' },
    { word: 'inurement', word_class: 'N' }
  ],
  impelling: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  tilt: [{ word: 'tilt', word_class: 'N' }, { word: 'tilt', word_class: 'V' }, { word: 'tilted', word_class: 'AJ' }],
  slimness: [
    { word: 'slim', word_class: 'V' },
    { word: 'slim', word_class: 'AJ' },
    { word: 'slimness', word_class: 'N' }
  ],
  clerkship: [
    { word: 'clerk', word_class: 'N' },
    { word: 'clerk', word_class: 'V' },
    { word: 'clerking', word_class: 'N' },
    { word: 'clerkship', word_class: 'N' }
  ],
  bottleful: [
    { word: 'bottle', word_class: 'N' },
    { word: 'bottle', word_class: 'V' },
    { word: 'bottleful', word_class: 'N' },
    { word: 'bottleful', word_class: 'AJ' }
  ],
  iced: [
    { word: 'ice', word_class: 'N' },
    { word: 'ice', word_class: 'V' },
    { word: 'icy', word_class: 'AJ' },
    { word: 'iced', word_class: 'AJ' },
    { word: 'icing', word_class: 'N' },
    { word: 'iceman', word_class: 'N' },
    { word: 'iciness', word_class: 'N' }
  ],
  competence: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  lade: [
    { word: 'lade', word_class: 'V' },
    { word: 'laden', word_class: 'V' },
    { word: 'laden', word_class: 'AJ' },
    { word: 'lading', word_class: 'N' },
    { word: 'ladened', word_class: 'AJ' }
  ],
  miniature: [
    { word: 'miniature', word_class: 'N' },
    { word: 'miniature', word_class: 'AJ' },
    { word: 'miniaturise', word_class: 'V' },
    { word: 'miniaturize', word_class: 'V' },
    { word: 'miniaturisation', word_class: 'N' },
    { word: 'miniaturization', word_class: 'N' }
  ],
  horrific: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  impart: [
    { word: 'impart', word_class: 'N' },
    { word: 'impart', word_class: 'V' },
    { word: 'imparting', word_class: 'N' },
    { word: 'impartment', word_class: 'N' },
    { word: 'impartation', word_class: 'N' }
  ],
  groomed: [
    { word: 'groom', word_class: 'N' },
    { word: 'groom', word_class: 'V' },
    { word: 'groomed', word_class: 'AJ' },
    { word: 'grooming', word_class: 'N' }
  ],
  cartographer: [
    { word: 'cartographer', word_class: 'N' },
    { word: 'cartographic', word_class: 'AJ' },
    { word: 'cartographical', word_class: 'AJ' }
  ],
  percussion: [
    { word: 'percuss', word_class: 'V' },
    { word: 'percussion', word_class: 'N' },
    { word: 'percussive', word_class: 'AJ' }
  ],
  suggestion: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  short: [
    { word: 'short', word_class: 'N' },
    { word: 'short', word_class: 'V' },
    { word: 'short', word_class: 'AJ' },
    { word: 'short', word_class: 'AV' },
    { word: 'shorts', word_class: 'N' },
    { word: 'shortage', word_class: 'N' },
    { word: 'shortness', word_class: 'N' }
  ],
  flowering: [
    { word: 'flower', word_class: 'N' },
    { word: 'flower', word_class: 'V' },
    { word: 'floral', word_class: 'AJ' },
    { word: 'florist', word_class: 'N' },
    { word: 'flowered', word_class: 'AJ' },
    { word: 'flowering', word_class: 'N' },
    { word: 'flowering', word_class: 'AJ' }
  ],
  grind: [
    { word: 'grind', word_class: 'N' },
    { word: 'grind', word_class: 'V' },
    { word: 'grinder', word_class: 'N' },
    { word: 'grinding', word_class: 'N' }
  ],
  deafness: [
    { word: 'deaf', word_class: 'N' },
    { word: 'deaf', word_class: 'V' },
    { word: 'deaf', word_class: 'AJ' },
    { word: 'deafness', word_class: 'N' }
  ],
  spontaneousness: [
    { word: 'spontaneity', word_class: 'N' },
    { word: 'spontaneous', word_class: 'AJ' },
    { word: 'spontaneously', word_class: 'AV' },
    { word: 'spontaneousness', word_class: 'N' }
  ],
  disseminator: [
    { word: 'disseminate', word_class: 'V' },
    { word: 'disseminator', word_class: 'N' },
    { word: 'dissemination', word_class: 'N' },
    { word: 'disseminating', word_class: 'AJ' },
    { word: 'disseminative', word_class: 'AJ' }
  ],
  waxing: [
    { word: 'wax', word_class: 'N' },
    { word: 'wax', word_class: 'V' },
    { word: 'waxed', word_class: 'AJ' },
    { word: 'waxen', word_class: 'AJ' },
    { word: 'waxing', word_class: 'N' },
    { word: 'waxing', word_class: 'AJ' }
  ],
  southern: [
    { word: 'southern', word_class: 'AJ' },
    { word: 'southerner', word_class: 'N' },
    { word: 'southernism', word_class: 'N' },
    { word: 'southernness', word_class: 'N' }
  ],
  tinting: [
    { word: 'tint', word_class: 'N' },
    { word: 'tint', word_class: 'V' },
    { word: 'tinted', word_class: 'AJ' },
    { word: 'tinting', word_class: 'N' }
  ],
  wetting: [
    { word: 'wet', word_class: 'N' },
    { word: 'wet', word_class: 'V' },
    { word: 'wet', word_class: 'AJ' },
    { word: 'wetness', word_class: 'N' },
    { word: 'wetting', word_class: 'N' }
  ],
  shaky: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  scented: [
    { word: 'scent', word_class: 'N' },
    { word: 'scent', word_class: 'V' },
    { word: 'scented', word_class: 'AJ' }
  ],
  lyophilize: [
    { word: 'lyophilize', word_class: 'V' },
    { word: 'lyophilized', word_class: 'AJ' },
    { word: 'lyophilization', word_class: 'N' }
  ],
  discontinuity: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  fecundity: [
    { word: 'fecund', word_class: 'AJ' },
    { word: 'fecundate', word_class: 'V' },
    { word: 'fecundity', word_class: 'N' },
    { word: 'fecundation', word_class: 'N' }
  ],
  eaten: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  rescinding: [
    { word: 'rescind', word_class: 'V' },
    { word: 'rescinding', word_class: 'N' },
    { word: 'rescission', word_class: 'N' },
    { word: 'rescindment', word_class: 'N' },
    { word: 'rescindable', word_class: 'AJ' }
  ],
  idiot: [
    { word: 'idiot', word_class: 'N' },
    { word: 'idiocy', word_class: 'N' },
    { word: 'idiotic', word_class: 'AJ' },
    { word: 'idiotically', word_class: 'AV' }
  ],
  cavern: [
    { word: 'cavern', word_class: 'N' },
    { word: 'cavern', word_class: 'V' },
    { word: 'cavernous', word_class: 'AJ' }
  ],
  tragically: [
    { word: 'tragic', word_class: 'AJ' },
    { word: 'tragical', word_class: 'AJ' },
    { word: 'tragically', word_class: 'AV' }
  ],
  supplanting: [
    { word: 'supplant', word_class: 'V' },
    { word: 'supplanter', word_class: 'N' },
    { word: 'supplanting', word_class: 'N' },
    { word: 'supplantation', word_class: 'N' }
  ],
  suit: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  undependability: [
    { word: 'undependable', word_class: 'AJ' },
    { word: 'undependably', word_class: 'AV' },
    { word: 'undependability', word_class: 'N' },
    { word: 'undependableness', word_class: 'N' }
  ],
  cultivable: [
    { word: 'cultivate', word_class: 'V' },
    { word: 'cultivator', word_class: 'N' },
    { word: 'cultivable', word_class: 'AJ' },
    { word: 'cultivated', word_class: 'AJ' },
    { word: 'cultivation', word_class: 'N' }
  ],
  shunter: [
    { word: 'shunt', word_class: 'N' },
    { word: 'shunt', word_class: 'V' },
    { word: 'shunter', word_class: 'N' }
  ],
  crater: [
    { word: 'crate', word_class: 'N' },
    { word: 'crate', word_class: 'V' },
    { word: 'crater', word_class: 'N' },
    { word: 'crater', word_class: 'V' },
    { word: 'crateful', word_class: 'N' }
  ],
  conscription: [
    { word: 'conscript', word_class: 'N' },
    { word: 'conscript', word_class: 'V' },
    { word: 'conscription', word_class: 'N' }
  ],
  adamance: [
    { word: 'adam', word_class: 'N' },
    { word: 'adams', word_class: 'N' },
    { word: 'adamant', word_class: 'N' },
    { word: 'adamance', word_class: 'N' },
    { word: 'adamant', word_class: 'AJ' }
  ],
  relinquishment: [
    { word: 'relinquish', word_class: 'V' },
    { word: 'relinquished', word_class: 'AJ' },
    { word: 'relinquishing', word_class: 'N' },
    { word: 'relinquishment', word_class: 'N' }
  ],
  smeared: [
    { word: 'smear', word_class: 'N' },
    { word: 'smear', word_class: 'V' },
    { word: 'smeared', word_class: 'AJ' }
  ],
  queue: [
    { word: 'queue', word_class: 'N' },
    { word: 'queue', word_class: 'V' },
    { word: 'queuing', word_class: 'AJ' }
  ],
  lager: [
    { word: 'lag', word_class: 'N' },
    { word: 'lag', word_class: 'V' },
    { word: 'lager', word_class: 'N' },
    { word: 'lagging', word_class: 'N' }
  ],
  replaceable: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  decide: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  disinfection: [
    { word: 'disinfect', word_class: 'V' },
    { word: 'disinfectant', word_class: 'N' },
    { word: 'disinfection', word_class: 'N' },
    { word: 'disinfectant', word_class: 'AJ' }
  ],
  mastication: [
    { word: 'mastic', word_class: 'N' },
    { word: 'masticate', word_class: 'V' },
    { word: 'mastication', word_class: 'N' }
  ],
  consolidation: [
    { word: 'consolidate', word_class: 'V' },
    { word: 'consolidated', word_class: 'AJ' },
    { word: 'consolidation', word_class: 'N' },
    { word: 'consolidative', word_class: 'AJ' }
  ],
  plank: [
    { word: 'plank', word_class: 'N' },
    { word: 'plank', word_class: 'V' },
    { word: 'planking', word_class: 'N' }
  ],
  amalgamate: [
    { word: 'amalgam', word_class: 'N' },
    { word: 'amalgamate', word_class: 'V' },
    { word: 'amalgamate', word_class: 'AJ' },
    { word: 'amalgamated', word_class: 'AJ' },
    { word: 'amalgamation', word_class: 'N' },
    { word: 'amalgamative', word_class: 'AJ' }
  ],
  circulate: [
    { word: 'circulant', word_class: 'N' },
    { word: 'circulate', word_class: 'V' },
    { word: 'circulation', word_class: 'N' },
    { word: 'circulating', word_class: 'AJ' },
    { word: 'circulative', word_class: 'AJ' }
  ],
  freewheeling: [
    { word: 'freewheel', word_class: 'V' },
    { word: 'freewheeler', word_class: 'N' },
    { word: 'freewheeling', word_class: 'AJ' }
  ],
  affable: [
    { word: 'affable', word_class: 'AJ' },
    { word: 'affably', word_class: 'AV' },
    { word: 'affability', word_class: 'N' },
    { word: 'affableness', word_class: 'N' }
  ],
  iridescence: [
    { word: 'iridesce', word_class: 'V' },
    { word: 'iridescence', word_class: 'N' },
    { word: 'iridescent', word_class: 'AJ' }
  ],
  eradicator: [
    { word: 'eradicate', word_class: 'V' },
    { word: 'eradicator', word_class: 'N' },
    { word: 'eradicable', word_class: 'AJ' },
    { word: 'eradication', word_class: 'N' }
  ],
  insensate: [
    { word: 'insensate', word_class: 'AJ' },
    { word: 'insensible', word_class: 'AJ' },
    { word: 'insensibly', word_class: 'AV' },
    { word: 'insensibility', word_class: 'N' }
  ],
  reprehend: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  intrust: [
    { word: 'entrust', word_class: 'V' },
    { word: 'intrust', word_class: 'V' },
    { word: 'entrustment', word_class: 'N' },
    { word: 'intrustment', word_class: 'N' }
  ],
  mainline: [
    { word: 'mainline', word_class: 'N' },
    { word: 'mainline', word_class: 'V' },
    { word: 'mainline', word_class: 'AJ' }
  ],
  meet: [
    { word: 'meet', word_class: 'N' },
    { word: 'meet', word_class: 'V' },
    { word: 'meet', word_class: 'AJ' },
    { word: 'meeting', word_class: 'N' }
  ],
  grey: [
    { word: 'grey', word_class: 'N' },
    { word: 'grey', word_class: 'V' },
    { word: 'grey', word_class: 'AJ' },
    { word: 'greyness', word_class: 'N' }
  ],
  titled: [
    { word: 'title', word_class: 'N' },
    { word: 'title', word_class: 'V' },
    { word: 'titled', word_class: 'AJ' },
    { word: 'titular', word_class: 'AJ' }
  ],
  respirate: [
    { word: 'respire', word_class: 'N' },
    { word: 'respire', word_class: 'V' },
    { word: 'respirate', word_class: 'V' },
    { word: 'respirator', word_class: 'N' },
    { word: 'respiration', word_class: 'N' }
  ],
  manipulator: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  compete: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  idealized: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  prizefighter: [
    { word: 'prizefight', word_class: 'N' },
    { word: 'prizefight', word_class: 'V' },
    { word: 'prizefighter', word_class: 'N' }
  ],
  pawnee: [
    { word: 'pawn', word_class: 'N' },
    { word: 'pawn', word_class: 'V' },
    { word: 'pawnee', word_class: 'N' },
    { word: 'pawnage', word_class: 'N' },
    { word: 'pawning', word_class: 'N' }
  ],
  government: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  halt: [
    { word: 'halt', word_class: 'N' },
    { word: 'halt', word_class: 'V' },
    { word: 'halt', word_class: 'AJ' },
    { word: 'halter', word_class: 'N' },
    { word: 'halter', word_class: 'V' },
    { word: 'halted', word_class: 'AJ' },
    { word: 'haltere', word_class: 'N' },
    { word: 'halting', word_class: 'AJ' }
  ],
  vitalise: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  perpetuation: [
    { word: 'perpetual', word_class: 'AJ' },
    { word: 'perpetuate', word_class: 'V' },
    { word: 'perpetuity', word_class: 'N' },
    { word: 'perpetually', word_class: 'AV' },
    { word: 'perpetuation', word_class: 'N' }
  ],
  dreaming: [
    { word: 'dream', word_class: 'N' },
    { word: 'dream', word_class: 'V' },
    { word: 'dreamer', word_class: 'N' },
    { word: 'dreamed', word_class: 'AJ' },
    { word: 'dreaming', word_class: 'N' }
  ],
  augment: [
    { word: 'augment', word_class: 'V' },
    { word: 'augmented', word_class: 'AJ' },
    { word: 'augmenting', word_class: 'AJ' },
    { word: 'augmentation', word_class: 'N' },
    { word: 'augmentative', word_class: 'AJ' }
  ],
  reluctivity: [
    { word: 'reluct', word_class: 'V' },
    { word: 'reluctance', word_class: 'N' },
    { word: 'reluctancy', word_class: 'N' },
    { word: 'reluctant', word_class: 'AJ' },
    { word: 'reluctivity', word_class: 'N' }
  ],
  caramel: [
    { word: 'caramel', word_class: 'N' },
    { word: 'caramel', word_class: 'AJ' },
    { word: 'caramelize', word_class: 'V' },
    { word: 'caramelization', word_class: 'N' }
  ],
  exhale: [
    { word: 'exhale', word_class: 'V' },
    { word: 'exhale', word_class: 'AJ' },
    { word: 'exhaled', word_class: 'AJ' },
    { word: 'exhaling', word_class: 'AJ' },
    { word: 'exhalation', word_class: 'N' }
  ],
  knotted: [
    { word: 'knot', word_class: 'N' },
    { word: 'knot', word_class: 'V' },
    { word: 'knotted', word_class: 'AJ' }
  ],
  ablation: [
    { word: 'ablate', word_class: 'V' },
    { word: 'ablated', word_class: 'AJ' },
    { word: 'ablation', word_class: 'N' }
  ],
  retreated: [
    { word: 'retreat', word_class: 'N' },
    { word: 'retreat', word_class: 'V' },
    { word: 'retreated', word_class: 'N' },
    { word: 'retreatant', word_class: 'N' },
    { word: 'retreating', word_class: 'AJ' }
  ],
  adapter: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  disturbance: [
    { word: 'disturb', word_class: 'V' },
    { word: 'disturbed', word_class: 'AJ' },
    { word: 'disturbance', word_class: 'N' },
    { word: 'disturbing', word_class: 'AJ' }
  ],
  relation: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  cycle: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  reticence: [
    { word: 'reticence', word_class: 'N' },
    { word: 'reticent', word_class: 'AJ' },
    { word: 'reticently', word_class: 'AV' }
  ],
  constructive: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  winemaker: [
    { word: 'wine', word_class: 'N' },
    { word: 'wine', word_class: 'V' },
    { word: 'winy', word_class: 'AJ' },
    { word: 'winery', word_class: 'N' },
    { word: 'winemaker', word_class: 'N' },
    { word: 'winemaking', word_class: 'N' }
  ],
  squared: [
    { word: 'square', word_class: 'N' },
    { word: 'square', word_class: 'V' },
    { word: 'square', word_class: 'AJ' },
    { word: 'square', word_class: 'AV' },
    { word: 'squared', word_class: 'AJ' },
    { word: 'squarely', word_class: 'AV' },
    { word: 'squareness', word_class: 'N' }
  ],
  pinion: [
    { word: 'pinion', word_class: 'N' },
    { word: 'pinion', word_class: 'V' },
    { word: 'pinioned', word_class: 'AJ' }
  ],
  permissiveness: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  manners: [
    { word: 'manner', word_class: 'N' },
    { word: 'manners', word_class: 'N' },
    { word: 'mannered', word_class: 'AJ' },
    { word: 'mannerism', word_class: 'N' }
  ],
  industrialist: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  desiccate: [
    { word: 'desiccant', word_class: 'N' },
    { word: 'desiccate', word_class: 'V' },
    { word: 'desiccate', word_class: 'AJ' },
    { word: 'desiccated', word_class: 'AJ' },
    { word: 'desiccation', word_class: 'N' }
  ],
  implication: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  crabbed: [
    { word: 'crab', word_class: 'N' },
    { word: 'crab', word_class: 'V' },
    { word: 'crabs', word_class: 'N' },
    { word: 'crabbed', word_class: 'AJ' }
  ],
  scare: [
    { word: 'scare', word_class: 'N' },
    { word: 'scare', word_class: 'V' },
    { word: 'scary', word_class: 'AJ' },
    { word: 'scared', word_class: 'AJ' }
  ],
  chap: [
    { word: 'chap', word_class: 'N' },
    { word: 'chap', word_class: 'V' },
    { word: 'chapman', word_class: 'N' },
    { word: 'chapped', word_class: 'AJ' }
  ],
  keep: [
    { word: 'keep', word_class: 'N' },
    { word: 'keep', word_class: 'V' },
    { word: 'keeper', word_class: 'N' },
    { word: 'keeping', word_class: 'N' }
  ],
  unfortunate: [
    { word: 'unfortunate', word_class: 'N' },
    { word: 'unfortunate', word_class: 'AJ' },
    { word: 'unfortunately', word_class: 'AV' }
  ],
  impinge: [
    { word: 'impinge', word_class: 'V' },
    { word: 'impinging', word_class: 'N' },
    { word: 'impingement', word_class: 'N' }
  ],
  mined: [
    { word: 'mine', word_class: 'N' },
    { word: 'mine', word_class: 'V' },
    { word: 'miner', word_class: 'N' },
    { word: 'mined', word_class: 'AJ' },
    { word: 'mining', word_class: 'N' },
    { word: 'minion', word_class: 'N' },
    { word: 'minor', word_class: 'N' }
  ],
  inevitable: [
    { word: 'inevitable', word_class: 'N' },
    { word: 'inevitable', word_class: 'AJ' },
    { word: 'inevitably', word_class: 'AV' },
    { word: 'inevitability', word_class: 'N' },
    { word: 'inevitableness', word_class: 'N' }
  ],
  propitiation: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  hoodooism: [
    { word: 'hoodoo', word_class: 'N' },
    { word: 'hoodoo', word_class: 'V' },
    { word: 'hoodooism', word_class: 'N' }
  ],
  imaginativeness: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  riot: [
    { word: 'riot', word_class: 'N' },
    { word: 'riot', word_class: 'V' },
    { word: 'rioter', word_class: 'N' },
    { word: 'rioting', word_class: 'N' }
  ],
  physicalism: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  skirting: [
    { word: 'skirt', word_class: 'N' },
    { word: 'skirt', word_class: 'V' },
    { word: 'skirting', word_class: 'AJ' }
  ],
  uniformed: [
    { word: 'uniform', word_class: 'N' },
    { word: 'uniform', word_class: 'V' },
    { word: 'uniform', word_class: 'AJ' },
    { word: 'uniformed', word_class: 'AJ' },
    { word: 'uniformity', word_class: 'N' },
    { word: 'uniformize', word_class: 'V' },
    { word: 'uniformness', word_class: 'N' }
  ],
  abundant: [
    { word: 'abound', word_class: 'V' },
    { word: 'abundance', word_class: 'N' },
    { word: 'abundant', word_class: 'AJ' },
    { word: 'abounding', word_class: 'AJ' },
    { word: 'abundantly', word_class: 'AV' }
  ],
  witting: [
    { word: 'wit', word_class: 'N' },
    { word: 'wit', word_class: 'V' },
    { word: 'witness', word_class: 'V' },
    { word: 'witness', word_class: 'N' },
    { word: 'witting', word_class: 'AJ' },
    { word: 'witnessed', word_class: 'AJ' }
  ],
  stapler: [
    { word: 'staple', word_class: 'N' },
    { word: 'staple', word_class: 'V' },
    { word: 'staple', word_class: 'AJ' },
    { word: 'stapler', word_class: 'N' },
    { word: 'stapled', word_class: 'AJ' }
  ],
  advantageousness: [
    { word: 'advantage', word_class: 'N' },
    { word: 'advantage', word_class: 'V' },
    { word: 'advantaged', word_class: 'AJ' },
    { word: 'advantageous', word_class: 'AJ' },
    { word: 'advantageously', word_class: 'AV' },
    { word: 'advantageousness', word_class: 'N' }
  ],
  antipodal: [
    { word: 'antipode', word_class: 'N' },
    { word: 'antipodal', word_class: 'N' },
    { word: 'antipodes', word_class: 'N' },
    { word: 'antipodal', word_class: 'AJ' }
  ],
  thought: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  coupler: [
    { word: 'couple', word_class: 'N' },
    { word: 'couple', word_class: 'V' },
    { word: 'couple', word_class: 'AJ' },
    { word: 'coupler', word_class: 'N' },
    { word: 'coupled', word_class: 'AJ' },
    { word: 'coupling', word_class: 'N' }
  ],
  experimentally: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  humiliating: [
    { word: 'humiliate', word_class: 'V' },
    { word: 'humiliated', word_class: 'AJ' },
    { word: 'humiliation', word_class: 'N' },
    { word: 'humiliating', word_class: 'AJ' }
  ],
  instillment: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  carnal: [
    { word: 'carnal', word_class: 'AJ' },
    { word: 'carnality', word_class: 'N' },
    { word: 'carnalize', word_class: 'V' },
    { word: 'carnally', word_class: 'AV' }
  ],
  entertained: [
    { word: 'entertain', word_class: 'V' },
    { word: 'entertainer', word_class: 'N' },
    { word: 'entertained', word_class: 'AJ' },
    { word: 'entertaining', word_class: 'AJ' },
    { word: 'entertainment', word_class: 'N' }
  ],
  compaction: [
    { word: 'compact', word_class: 'N' },
    { word: 'compact', word_class: 'V' },
    { word: 'compact', word_class: 'AJ' },
    { word: 'compaction', word_class: 'N' },
    { word: 'compactness', word_class: 'N' }
  ],
  acidulous: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  synchronizing: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  ruminative: [
    { word: 'ruminant', word_class: 'N' },
    { word: 'ruminate', word_class: 'V' },
    { word: 'ruminant', word_class: 'AJ' },
    { word: 'rumination', word_class: 'N' },
    { word: 'ruminative', word_class: 'AJ' }
  ],
  forest: [
    { word: 'forest', word_class: 'N' },
    { word: 'forest', word_class: 'V' },
    { word: 'forester', word_class: 'N' },
    { word: 'forestry', word_class: 'N' },
    { word: 'forested', word_class: 'AJ' }
  ],
  weaned: [
    { word: 'wean', word_class: 'V' },
    { word: 'weaned', word_class: 'AJ' },
    { word: 'weaning', word_class: 'N' }
  ],
  afflicted: [
    { word: 'afflict', word_class: 'V' },
    { word: 'afflicted', word_class: 'AJ' },
    { word: 'affliction', word_class: 'N' },
    { word: 'afflictive', word_class: 'AJ' }
  ],
  accentor: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  intervening: [
    { word: 'intervene', word_class: 'V' },
    { word: 'intervening', word_class: 'AJ' },
    { word: 'intervention', word_class: 'N' }
  ],
  nutation: [
    { word: 'nut', word_class: 'N' },
    { word: 'nut', word_class: 'V' },
    { word: 'nuts', word_class: 'AJ' },
    { word: 'nutate', word_class: 'V' },
    { word: 'nutation', word_class: 'N' }
  ],
  substantial: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  sameness: [
    { word: 'same', word_class: 'AJ' },
    { word: 'same', word_class: 'AV' },
    { word: 'sameness', word_class: 'N' }
  ],
  maximizing: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  conceive: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  laterally: [
    { word: 'later', word_class: 'AJ' },
    { word: 'later', word_class: 'AV' },
    { word: 'lateral', word_class: 'N' },
    { word: 'lateral', word_class: 'AJ' },
    { word: 'laterality', word_class: 'N' },
    { word: 'laterally', word_class: 'AV' }
  ],
  industrialism: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  carve: [
    { word: 'carve', word_class: 'V' },
    { word: 'carver', word_class: 'N' },
    { word: 'carved', word_class: 'AJ' },
    { word: 'carving', word_class: 'N' }
  ],
  comprehensive: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  compressible: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  marination: [
    { word: 'marine', word_class: 'N' },
    { word: 'marine', word_class: 'AJ' },
    { word: 'mariner', word_class: 'N' },
    { word: 'marinade', word_class: 'N' },
    { word: 'marinade', word_class: 'V' },
    { word: 'marinate', word_class: 'V' },
    { word: 'marination', word_class: 'N' }
  ],
  sighted: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  indulge: [
    { word: 'indulge', word_class: 'V' },
    { word: 'indulging', word_class: 'N' },
    { word: 'indulgence', word_class: 'N' },
    { word: 'indulgent', word_class: 'AJ' },
    { word: 'indulgently', word_class: 'AV' }
  ],
  telephonic: [
    { word: 'telephone', word_class: 'N' },
    { word: 'telephone', word_class: 'V' },
    { word: 'telephoner', word_class: 'N' },
    { word: 'telephonic', word_class: 'AJ' },
    { word: 'telephonist', word_class: 'N' }
  ],
  naive: [
    { word: 'naive', word_class: 'AJ' },
    { word: 'naivety', word_class: 'N' },
    { word: 'naively', word_class: 'AV' },
    { word: 'naiveness', word_class: 'N' }
  ],
  orient: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  methodologically: [
    { word: 'methodology', word_class: 'N' },
    { word: 'methodological', word_class: 'AJ' },
    { word: 'methodologically', word_class: 'AV' }
  ],
  artiodactylous: [
    { word: 'artiodactyl', word_class: 'N' },
    { word: 'artiodactyl', word_class: 'AJ' },
    { word: 'artiodactylous', word_class: 'AJ' }
  ],
  pronominalize: [
    { word: 'pronominal', word_class: 'AJ' },
    { word: 'pronominalise', word_class: 'V' },
    { word: 'pronominalize', word_class: 'V' },
    { word: 'pronominalisation', word_class: 'N' },
    { word: 'pronominalization', word_class: 'N' }
  ],
  emphasize: [
    { word: 'emphasis', word_class: 'N' },
    { word: 'emphasize', word_class: 'V' },
    { word: 'emphasized', word_class: 'AJ' },
    { word: 'emphasizing', word_class: 'N' }
  ],
  limner: [
    { word: 'limn', word_class: 'V' },
    { word: 'limner', word_class: 'N' },
    { word: 'limning', word_class: 'N' }
  ],
  chatterer: [
    { word: 'chatter', word_class: 'N' },
    { word: 'chatter', word_class: 'V' },
    { word: 'chatterer', word_class: 'N' },
    { word: 'chattering', word_class: 'N' },
    { word: 'chattering', word_class: 'AJ' }
  ],
  supporter: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  doubtfulness: [
    { word: 'doubt', word_class: 'N' },
    { word: 'doubt', word_class: 'V' },
    { word: 'doubter', word_class: 'N' },
    { word: 'doubtful', word_class: 'AJ' },
    { word: 'doubting', word_class: 'AJ' },
    { word: 'doubtfulness', word_class: 'N' }
  ],
  emblematization: [
    { word: 'emblematic', word_class: 'AJ' },
    { word: 'emblematize', word_class: 'V' },
    { word: 'emblematical', word_class: 'AJ' },
    { word: 'emblematization', word_class: 'N' }
  ],
  straightaway: [
    { word: 'straightaway', word_class: 'N' },
    { word: 'straightaway', word_class: 'AJ' },
    { word: 'straightaway', word_class: 'AV' }
  ],
  anonymously: [
    { word: 'anonym', word_class: 'N' },
    { word: 'anonymity', word_class: 'N' },
    { word: 'anonymous', word_class: 'AJ' },
    { word: 'anonymously', word_class: 'AV' }
  ],
  blush: [
    { word: 'blush', word_class: 'N' },
    { word: 'blush', word_class: 'V' },
    { word: 'blusher', word_class: 'N' },
    { word: 'blushful', word_class: 'AJ' },
    { word: 'blushing', word_class: 'AJ' }
  ],
  peel: [
    { word: 'peel', word_class: 'N' },
    { word: 'peel', word_class: 'V' },
    { word: 'peeler', word_class: 'N' },
    { word: 'peeled', word_class: 'AJ' },
    { word: 'peeling', word_class: 'AJ' }
  ],
  asphyxiating: [
    { word: 'asphyxiate', word_class: 'V' },
    { word: 'asphyxiated', word_class: 'AJ' },
    { word: 'asphyxiation', word_class: 'N' },
    { word: 'asphyxiating', word_class: 'AJ' }
  ],
  inherent: [
    { word: 'inhere', word_class: 'V' },
    { word: 'inhere', word_class: 'AV' },
    { word: 'inherence', word_class: 'N' },
    { word: 'inherent', word_class: 'AJ' },
    { word: 'inherently', word_class: 'AV' }
  ],
  modeling: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  voluptuously: [
    { word: 'voluptuous', word_class: 'AJ' },
    { word: 'voluptuously', word_class: 'AV' },
    { word: 'voluptuousness', word_class: 'N' }
  ],
  extensively: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  precipitant: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  feeling: [
    { word: 'feel', word_class: 'N' },
    { word: 'feel', word_class: 'V' },
    { word: 'feeler', word_class: 'N' },
    { word: 'feeling', word_class: 'N' },
    { word: 'feelings', word_class: 'N' }
  ],
  glorified: [
    { word: 'glory', word_class: 'N' },
    { word: 'glory', word_class: 'V' },
    { word: 'glorify', word_class: 'V' },
    { word: 'glorified', word_class: 'AJ' },
    { word: 'glorification', word_class: 'N' }
  ],
  dejection: [
    { word: 'deject', word_class: 'V' },
    { word: 'dejected', word_class: 'AJ' },
    { word: 'dejection', word_class: 'N' }
  ],
  buyer: [
    { word: 'buy', word_class: 'N' },
    { word: 'buy', word_class: 'V' },
    { word: 'buyer', word_class: 'N' },
    { word: 'buying', word_class: 'N' }
  ],
  temporize: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  bodybuilder: [
    { word: 'bodybuild', word_class: 'V' },
    { word: 'bodybuilder', word_class: 'N' },
    { word: 'bodybuilding', word_class: 'N' }
  ],
  apotheose: [
    { word: 'apotheose', word_class: 'V' },
    { word: 'apotheosize', word_class: 'V' },
    { word: 'apotheosization', word_class: 'N' }
  ],
  intellectualisation: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  blandishment: [
    { word: 'blandish', word_class: 'V' },
    { word: 'blandish', word_class: 'AJ' },
    { word: 'blandishment', word_class: 'N' }
  ],
  houseful: [
    { word: 'house', word_class: 'N' },
    { word: 'house', word_class: 'V' },
    { word: 'housing', word_class: 'N' },
    { word: 'houseful', word_class: 'N' },
    { word: 'houseman', word_class: 'N' },
    { word: 'housings', word_class: 'N' },
    { word: 'houseful', word_class: 'AJ' }
  ],
  extoller: [
    { word: 'extol', word_class: 'V' },
    { word: 'extoll', word_class: 'V' },
    { word: 'extoller', word_class: 'N' },
    { word: 'extolment', word_class: 'N' }
  ],
  illness: [
    { word: 'ill', word_class: 'N' },
    { word: 'ill', word_class: 'AJ' },
    { word: 'ill', word_class: 'AV' },
    { word: 'illness', word_class: 'N' },
    { word: 'illative', word_class: 'AJ' }
  ],
  accord: [
    { word: 'accord', word_class: 'N' },
    { word: 'accord', word_class: 'V' },
    { word: 'accordion', word_class: 'N' },
    { word: 'accordance', word_class: 'N' },
    { word: 'accordant', word_class: 'AJ' },
    { word: 'according', word_class: 'AJ' },
    { word: 'accordion', word_class: 'AJ' }
  ],
  conferred: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  climax: [
    { word: 'climax', word_class: 'N' },
    { word: 'climax', word_class: 'V' },
    { word: 'climactic', word_class: 'AJ' },
    { word: 'climactical', word_class: 'AJ' }
  ],
  tankful: [
    { word: 'tank', word_class: 'N' },
    { word: 'tank', word_class: 'V' },
    { word: 'tankage', word_class: 'N' },
    { word: 'tankful', word_class: 'N' },
    { word: 'tankful', word_class: 'AJ' }
  ],
  rooting: [
    { word: 'root', word_class: 'N' },
    { word: 'root', word_class: 'V' },
    { word: 'root', word_class: 'AJ' },
    { word: 'rooted', word_class: 'AJ' },
    { word: 'rooting', word_class: 'N' }
  ],
  survive: [
    { word: 'survive', word_class: 'V' },
    { word: 'survival', word_class: 'N' },
    { word: 'survivor', word_class: 'N' },
    { word: 'surviving', word_class: 'AJ' }
  ],
  demonetization: [
    { word: 'demonetise', word_class: 'V' },
    { word: 'demonetize', word_class: 'V' },
    { word: 'demonetisation', word_class: 'N' },
    { word: 'demonetization', word_class: 'N' }
  ],
  verbalize: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  inclose: [
    { word: 'inclose', word_class: 'V' },
    { word: 'inclose', word_class: 'AJ' },
    { word: 'inclosure', word_class: 'N' }
  ],
  scotchman: [
    { word: 'scotch', word_class: 'N' },
    { word: 'scotch', word_class: 'V' },
    { word: 'scotch', word_class: 'AJ' },
    { word: 'scotchman', word_class: 'N' }
  ],
  palpitate: [
    { word: 'palpitate', word_class: 'V' },
    { word: 'palpitant', word_class: 'AJ' },
    { word: 'palpitation', word_class: 'N' },
    { word: 'palpitating', word_class: 'AJ' }
  ],
  cripple: [
    { word: 'cripple', word_class: 'N' },
    { word: 'cripple', word_class: 'V' },
    { word: 'crippled', word_class: 'AJ' },
    { word: 'crippling', word_class: 'AJ' }
  ],
  motivating: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  nostalgia: [
    { word: 'nostalgia', word_class: 'N' },
    { word: 'nostalgic', word_class: 'AJ' },
    { word: 'nostalgically', word_class: 'AV' }
  ],
  weirdness: [
    { word: 'weird', word_class: 'N' },
    { word: 'weird', word_class: 'AJ' },
    { word: 'weirdness', word_class: 'N' }
  ],
  impugnation: [
    { word: 'impugn', word_class: 'V' },
    { word: 'impugnment', word_class: 'N' },
    { word: 'impugnable', word_class: 'AJ' },
    { word: 'impugnation', word_class: 'N' }
  ],
  affectionately: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  satiric: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  vertical: [
    { word: 'vertical', word_class: 'N' },
    { word: 'vertical', word_class: 'AJ' },
    { word: 'verticality', word_class: 'N' },
    { word: 'vertically', word_class: 'AV' },
    { word: 'verticalness', word_class: 'N' }
  ],
  clown: [
    { word: 'clown', word_class: 'N' },
    { word: 'clown', word_class: 'V' },
    { word: 'clownery', word_class: 'N' },
    { word: 'clowning', word_class: 'N' },
    { word: 'clownish', word_class: 'AJ' }
  ],
  weariness: [
    { word: 'weary', word_class: 'V' },
    { word: 'weary', word_class: 'AJ' },
    { word: 'wearied', word_class: 'AJ' },
    { word: 'weariness', word_class: 'N' },
    { word: 'wearying', word_class: 'AJ' }
  ],
  bossism: [
    { word: 'boss', word_class: 'N' },
    { word: 'boss', word_class: 'V' },
    { word: 'boss', word_class: 'AJ' },
    { word: 'bossism', word_class: 'N' }
  ],
  incestuously: [
    { word: 'incest', word_class: 'N' },
    { word: 'incestuous', word_class: 'AJ' },
    { word: 'incestuously', word_class: 'AV' }
  ],
  heathenize: [
    { word: 'heathen', word_class: 'N' },
    { word: 'heathen', word_class: 'AJ' },
    { word: 'heathenism', word_class: 'N' },
    { word: 'heathenize', word_class: 'V' },
    { word: 'heathenization', word_class: 'N' }
  ],
  homer: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  ethic: [
    { word: 'ethic', word_class: 'N' },
    { word: 'ethics', word_class: 'N' },
    { word: 'ethical', word_class: 'AJ' },
    { word: 'ethically', word_class: 'AV' }
  ],
  incitive: [
    { word: 'incite', word_class: 'V' },
    { word: 'inciter', word_class: 'N' },
    { word: 'incitive', word_class: 'AJ' },
    { word: 'incitation', word_class: 'N' },
    { word: 'incitement', word_class: 'N' }
  ],
  stonewall: [
    { word: 'stonewall', word_class: 'V' },
    { word: 'stonewaller', word_class: 'N' },
    { word: 'stonewalling', word_class: 'N' }
  ],
  appeaser: [
    { word: 'appease', word_class: 'V' },
    { word: 'appeaser', word_class: 'N' },
    { word: 'appeasing', word_class: 'AJ' },
    { word: 'appeasable', word_class: 'AJ' },
    { word: 'appeasement', word_class: 'N' }
  ],
  sufferer: [
    { word: 'suffer', word_class: 'V' },
    { word: 'sufferer', word_class: 'N' },
    { word: 'suffering', word_class: 'N' },
    { word: 'sufferance', word_class: 'N' },
    { word: 'suffering', word_class: 'AJ' },
    { word: 'sufferable', word_class: 'AJ' }
  ],
  advancement: [
    { word: 'advance', word_class: 'N' },
    { word: 'advance', word_class: 'V' },
    { word: 'advance', word_class: 'AJ' },
    { word: 'advanced', word_class: 'AJ' },
    { word: 'advancing', word_class: 'AJ' },
    { word: 'advancement', word_class: 'N' }
  ],
  fleck: [
    { word: 'fleck', word_class: 'N' },
    { word: 'fleck', word_class: 'V' },
    { word: 'flecked', word_class: 'AJ' }
  ],
  boastfulness: [
    { word: 'boast', word_class: 'N' },
    { word: 'boast', word_class: 'V' },
    { word: 'boaster', word_class: 'N' },
    { word: 'boasting', word_class: 'N' },
    { word: 'boastful', word_class: 'AJ' },
    { word: 'boastfulness', word_class: 'N' }
  ],
  hardheads: [
    { word: 'hardheads', word_class: 'N' },
    { word: 'hardheaded', word_class: 'AJ' },
    { word: 'hardheadness', word_class: 'N' }
  ],
  tonnage: [{ word: 'ton', word_class: 'N' }, { word: 'tons', word_class: 'N' }, { word: 'tonnage', word_class: 'N' }],
  invisibly: [
    { word: 'invisible', word_class: 'AJ' },
    { word: 'invisibly', word_class: 'AV' },
    { word: 'invisibility', word_class: 'N' },
    { word: 'invisibleness', word_class: 'N' }
  ],
  hydrogenize: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  drawl: [
    { word: 'drawl', word_class: 'N' },
    { word: 'drawl', word_class: 'V' },
    { word: 'drawler', word_class: 'N' },
    { word: 'drawling', word_class: 'AJ' }
  ],
  economic: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  burn: [
    { word: 'burn', word_class: 'N' },
    { word: 'burn', word_class: 'V' },
    { word: 'burner', word_class: 'N' },
    { word: 'burned', word_class: 'AJ' },
    { word: 'burning', word_class: 'N' },
    { word: 'burning', word_class: 'AJ' }
  ],
  particularise: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  revolutionization: [
    { word: 'revolutionist', word_class: 'N' },
    { word: 'revolutionize', word_class: 'V' },
    { word: 'revolutionization', word_class: 'N' }
  ],
  attache: [
    { word: 'attach', word_class: 'V' },
    { word: 'attache', word_class: 'N' },
    { word: 'attached', word_class: 'AJ' },
    { word: 'attachment', word_class: 'N' },
    { word: 'attachable', word_class: 'AJ' }
  ],
  destroyable: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  reflexiveness: [
    { word: 'reflex', word_class: 'N' },
    { word: 'reflex', word_class: 'V' },
    { word: 'reflex', word_class: 'AJ' },
    { word: 'reflexive', word_class: 'N' },
    { word: 'reflexive', word_class: 'AJ' },
    { word: 'reflexivity', word_class: 'N' },
    { word: 'reflexiveness', word_class: 'N' }
  ],
  excruciating: [
    { word: 'excruciate', word_class: 'V' },
    { word: 'excruciation', word_class: 'N' },
    { word: 'excruciating', word_class: 'AJ' }
  ],
  estimation: [
    { word: 'estimate', word_class: 'N' },
    { word: 'estimate', word_class: 'V' },
    { word: 'estimator', word_class: 'N' },
    { word: 'estimable', word_class: 'AJ' },
    { word: 'estimated', word_class: 'AJ' },
    { word: 'estimation', word_class: 'N' }
  ],
  repaired: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  lunatic: [
    { word: 'lunate', word_class: 'AJ' },
    { word: 'lunatic', word_class: 'N' },
    { word: 'lunatic', word_class: 'AJ' },
    { word: 'lunation', word_class: 'N' }
  ],
  lash: [
    { word: 'lash', word_class: 'N' },
    { word: 'lash', word_class: 'V' },
    { word: 'lasher', word_class: 'N' },
    { word: 'lashing', word_class: 'N' },
    { word: 'lashing', word_class: 'AJ' },
    { word: 'lashings', word_class: 'N' }
  ],
  details: [
    { word: 'detail', word_class: 'N' },
    { word: 'detail', word_class: 'V' },
    { word: 'details', word_class: 'N' },
    { word: 'detailed', word_class: 'AJ' },
    { word: 'detailing', word_class: 'N' }
  ],
  tolerate: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  trafficker: [
    { word: 'traffic', word_class: 'N' },
    { word: 'traffic', word_class: 'V' },
    { word: 'trafficker', word_class: 'N' },
    { word: 'trafficator', word_class: 'N' },
    { word: 'trafficking', word_class: 'AJ' }
  ],
  smelter: [
    { word: 'smelt', word_class: 'N' },
    { word: 'smelt', word_class: 'V' },
    { word: 'smelter', word_class: 'N' }
  ],
  bureaucratically: [
    { word: 'bureaucrat', word_class: 'N' },
    { word: 'bureaucracy', word_class: 'N' },
    { word: 'bureaucratic', word_class: 'AJ' },
    { word: 'bureaucratism', word_class: 'N' },
    { word: 'bureaucratically', word_class: 'AV' }
  ],
  genotypical: [
    { word: 'genotype', word_class: 'N' },
    { word: 'genotypic', word_class: 'AJ' },
    { word: 'genotypical', word_class: 'AJ' }
  ],
  customize: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  counselor: [
    { word: 'counsel', word_class: 'N' },
    { word: 'counsel', word_class: 'V' },
    { word: 'counselor', word_class: 'N' },
    { word: 'counseling', word_class: 'N' }
  ],
  gutturalize: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  intensified: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  glazer: [
    { word: 'glaze', word_class: 'N' },
    { word: 'glaze', word_class: 'V' },
    { word: 'glazer', word_class: 'N' },
    { word: 'glazed', word_class: 'AJ' },
    { word: 'glazing', word_class: 'N' }
  ],
  quiver: [
    { word: 'quiver', word_class: 'N' },
    { word: 'quiver', word_class: 'V' },
    { word: 'quivering', word_class: 'N' },
    { word: 'quivering', word_class: 'AJ' }
  ],
  parsimonious: [
    { word: 'parsimony', word_class: 'N' },
    { word: 'parsimonious', word_class: 'AJ' },
    { word: 'parsimoniousness', word_class: 'N' }
  ],
  revoke: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  jocoseness: [
    { word: 'jocose', word_class: 'AJ' },
    { word: 'jocosity', word_class: 'N' },
    { word: 'jocosely', word_class: 'AV' },
    { word: 'jocoseness', word_class: 'N' }
  ],
  unequalized: [
    { word: 'unequal', word_class: 'AJ' },
    { word: 'unequaled', word_class: 'AJ' },
    { word: 'unequally', word_class: 'AV' },
    { word: 'unequalized', word_class: 'AJ' }
  ],
  learning: [
    { word: 'learn', word_class: 'V' },
    { word: 'learner', word_class: 'N' },
    { word: 'learned', word_class: 'AJ' },
    { word: 'learning', word_class: 'N' }
  ],
  indefatigability: [
    { word: 'indefatigable', word_class: 'AJ' },
    { word: 'indefatigably', word_class: 'AV' },
    { word: 'indefatigation', word_class: 'N' },
    { word: 'indefatigability', word_class: 'N' },
    { word: 'indefatigableness', word_class: 'N' }
  ],
  derivation: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  neighbouring: [
    { word: 'neighbour', word_class: 'N' },
    { word: 'neighbour', word_class: 'V' },
    { word: 'neighbour', word_class: 'AJ' },
    { word: 'neighbouring', word_class: 'AJ' }
  ],
  belling: [
    { word: 'bell', word_class: 'N' },
    { word: 'bell', word_class: 'V' },
    { word: 'belle', word_class: 'N' },
    { word: 'belling', word_class: 'N' },
    { word: 'bellman', word_class: 'N' }
  ],
  stumbling: [
    { word: 'stumble', word_class: 'N' },
    { word: 'stumble', word_class: 'V' },
    { word: 'stumbling', word_class: 'AJ' }
  ],
  luster: [
    { word: 'luster', word_class: 'N' },
    { word: 'luster', word_class: 'V' },
    { word: 'lustrous', word_class: 'AJ' }
  ],
  debuted: [
    { word: 'debut', word_class: 'N' },
    { word: 'debut', word_class: 'V' },
    { word: 'debuted', word_class: 'AJ' },
    { word: 'debuting', word_class: 'AJ' }
  ],
  thickness: [
    { word: 'thick', word_class: 'N' },
    { word: 'thick', word_class: 'AJ' },
    { word: 'thick', word_class: 'AV' },
    { word: 'thickness', word_class: 'N' }
  ],
  cytology: [
    { word: 'cytology', word_class: 'N' },
    { word: 'cytologic', word_class: 'AJ' },
    { word: 'cytological', word_class: 'AJ' }
  ],
  exasperated: [
    { word: 'exasperate', word_class: 'V' },
    { word: 'exasperated', word_class: 'AJ' },
    { word: 'exasperation', word_class: 'N' },
    { word: 'exasperating', word_class: 'AJ' }
  ],
  verbosely: [
    { word: 'verbose', word_class: 'AJ' },
    { word: 'verbosity', word_class: 'N' },
    { word: 'verbosely', word_class: 'AV' },
    { word: 'verboseness', word_class: 'N' }
  ],
  scorer: [
    { word: 'score', word_class: 'N' },
    { word: 'score', word_class: 'V' },
    { word: 'score', word_class: 'AJ' },
    { word: 'scorer', word_class: 'N' },
    { word: 'scores', word_class: 'N' }
  ],
  choking: [
    { word: 'choke', word_class: 'N' },
    { word: 'choke', word_class: 'V' },
    { word: 'choker', word_class: 'N' },
    { word: 'choked', word_class: 'AJ' },
    { word: 'choking', word_class: 'N' },
    { word: 'choking', word_class: 'AJ' }
  ],
  mock: [
    { word: 'mock', word_class: 'N' },
    { word: 'mock', word_class: 'V' },
    { word: 'mock', word_class: 'AJ' },
    { word: 'mocker', word_class: 'N' },
    { word: 'mockery', word_class: 'N' },
    { word: 'mockery', word_class: 'AJ' },
    { word: 'mocking', word_class: 'AJ' }
  ],
  retreating: [
    { word: 'retreat', word_class: 'N' },
    { word: 'retreat', word_class: 'V' },
    { word: 'retreated', word_class: 'N' },
    { word: 'retreatant', word_class: 'N' },
    { word: 'retreating', word_class: 'AJ' }
  ],
  removable: [
    { word: 'remove', word_class: 'N' },
    { word: 'remove', word_class: 'V' },
    { word: 'removal', word_class: 'N' },
    { word: 'remover', word_class: 'N' },
    { word: 'removed', word_class: 'AJ' },
    { word: 'removable', word_class: 'AJ' }
  ],
  forbearance: [
    { word: 'forbear', word_class: 'N' },
    { word: 'forbear', word_class: 'V' },
    { word: 'forborne', word_class: 'AJ' },
    { word: 'forbearance', word_class: 'N' },
    { word: 'forbearing', word_class: 'AJ' }
  ],
  expeditiousness: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  retrievable: [
    { word: 'retrieve', word_class: 'N' },
    { word: 'retrieve', word_class: 'V' },
    { word: 'retrieval', word_class: 'N' },
    { word: 'retriever', word_class: 'N' },
    { word: 'retrievable', word_class: 'AJ' }
  ],
  superannuated: [
    { word: 'superannuate', word_class: 'V' },
    { word: 'superannuated', word_class: 'AJ' },
    { word: 'superannuation', word_class: 'N' }
  ],
  appointed: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  searcher: [
    { word: 'search', word_class: 'N' },
    { word: 'search', word_class: 'V' },
    { word: 'searcher', word_class: 'N' },
    { word: 'searching', word_class: 'N' },
    { word: 'searching', word_class: 'AJ' }
  ],
  prognosticative: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  sheer: [{ word: 'sheer', word_class: 'V' }, { word: 'sheer', word_class: 'AJ' }, { word: 'sheer', word_class: 'AV' }],
  blight: [
    { word: 'blight', word_class: 'N' },
    { word: 'blight', word_class: 'V' },
    { word: 'blighter', word_class: 'N' },
    { word: 'blighted', word_class: 'AJ' }
  ],
  hieroglyphic: [
    { word: 'hieroglyph', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'AJ' },
    { word: 'hieroglyphical', word_class: 'AJ' },
    { word: 'hieroglyphically', word_class: 'AV' }
  ],
  joinder: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  informant: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  summary: [
    { word: 'summary', word_class: 'N' },
    { word: 'summary', word_class: 'AJ' },
    { word: 'summarise', word_class: 'V' },
    { word: 'summarize', word_class: 'V' },
    { word: 'summarisation', word_class: 'N' },
    { word: 'summarization', word_class: 'N' }
  ],
  voting: [
    { word: 'vote', word_class: 'N' },
    { word: 'vote', word_class: 'V' },
    { word: 'voter', word_class: 'N' },
    { word: 'voting', word_class: 'N' }
  ],
  palmist: [
    { word: 'palm', word_class: 'N' },
    { word: 'palm', word_class: 'V' },
    { word: 'palmer', word_class: 'N' },
    { word: 'palmist', word_class: 'N' }
  ],
  ausultation: [
    { word: 'auscultate', word_class: 'V' },
    { word: 'ausultation', word_class: 'N' },
    { word: 'auscultation', word_class: 'N' }
  ],
  deadness: [
    { word: 'dead', word_class: 'N' },
    { word: 'dead', word_class: 'AJ' },
    { word: 'dead', word_class: 'AV' },
    { word: 'deadly', word_class: 'AJ' },
    { word: 'deadly', word_class: 'AV' },
    { word: 'deadness', word_class: 'N' },
    { word: 'deadliness', word_class: 'N' }
  ],
  accumulate: [
    { word: 'accumulate', word_class: 'V' },
    { word: 'accumulator', word_class: 'N' },
    { word: 'accumulated', word_class: 'AJ' },
    { word: 'accumulation', word_class: 'N' },
    { word: 'accumulative', word_class: 'AJ' }
  ],
  paddle: [
    { word: 'paddle', word_class: 'N' },
    { word: 'paddle', word_class: 'V' },
    { word: 'paddler', word_class: 'N' }
  ],
  toasted: [
    { word: 'toast', word_class: 'N' },
    { word: 'toast', word_class: 'V' },
    { word: 'toaster', word_class: 'N' },
    { word: 'toasted', word_class: 'AJ' },
    { word: 'toasting', word_class: 'N' }
  ],
  physiology: [
    { word: 'physiology', word_class: 'N' },
    { word: 'physiologic', word_class: 'AJ' },
    { word: 'physiological', word_class: 'AJ' },
    { word: 'physiologically', word_class: 'AV' }
  ],
  mixed: [
    { word: 'mix', word_class: 'N' },
    { word: 'mix', word_class: 'V' },
    { word: 'mixer', word_class: 'N' },
    { word: 'mixed', word_class: 'AJ' },
    { word: 'mixing', word_class: 'N' },
    { word: 'mixture', word_class: 'N' }
  ],
  disperse: [
    { word: 'disperse', word_class: 'V' },
    { word: 'dispersal', word_class: 'N' },
    { word: 'dispersed', word_class: 'AJ' },
    { word: 'dispersion', word_class: 'N' },
    { word: 'dispersive', word_class: 'AJ' }
  ],
  nebulisation: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  convulsive: [
    { word: 'convulse', word_class: 'V' },
    { word: 'convulsion', word_class: 'N' },
    { word: 'convulsive', word_class: 'AJ' },
    { word: 'convulsively', word_class: 'AV' }
  ],
  combinative: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  pronged: [
    { word: 'prong', word_class: 'N' },
    { word: 'prong', word_class: 'V' },
    { word: 'pronged', word_class: 'AJ' }
  ],
  clawed: [{ word: 'claw', word_class: 'N' }, { word: 'claw', word_class: 'V' }, { word: 'clawed', word_class: 'AJ' }],
  blocked: [
    { word: 'block', word_class: 'N' },
    { word: 'block', word_class: 'V' },
    { word: 'blocker', word_class: 'N' },
    { word: 'blockage', word_class: 'N' },
    { word: 'blocked', word_class: 'AJ' },
    { word: 'blocking', word_class: 'N' }
  ],
  tipple: [
    { word: 'tipple', word_class: 'N' },
    { word: 'tipple', word_class: 'V' },
    { word: 'tippler', word_class: 'N' }
  ],
  putrefaction: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  confusing: [
    { word: 'confuse', word_class: 'V' },
    { word: 'confused', word_class: 'AJ' },
    { word: 'confusion', word_class: 'N' },
    { word: 'confusing', word_class: 'AJ' },
    { word: 'confusable', word_class: 'AJ' }
  ],
  individualization: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  individualize: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  effaceable: [
    { word: 'efface', word_class: 'V' },
    { word: 'effacement', word_class: 'N' },
    { word: 'effaceable', word_class: 'AJ' }
  ],
  presenter: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  condolence: [
    { word: 'condole', word_class: 'V' },
    { word: 'condolence', word_class: 'N' },
    { word: 'condolement', word_class: 'N' }
  ],
  dogmatic: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  redemptional: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  quilted: [
    { word: 'quilt', word_class: 'N' },
    { word: 'quilt', word_class: 'V' },
    { word: 'quilted', word_class: 'AJ' },
    { word: 'quilting', word_class: 'N' }
  ],
  pyrotechnic: [
    { word: 'pyrotechnic', word_class: 'AJ' },
    { word: 'pyrotechnics', word_class: 'N' },
    { word: 'pyrotechnical', word_class: 'AJ' }
  ],
  losing: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  skirt: [
    { word: 'skirt', word_class: 'N' },
    { word: 'skirt', word_class: 'V' },
    { word: 'skirting', word_class: 'AJ' }
  ],
  cattie: [
    { word: 'catty', word_class: 'N' },
    { word: 'cattie', word_class: 'N' },
    { word: 'catty', word_class: 'AJ' },
    { word: 'cattiness', word_class: 'N' }
  ],
  paired: [
    { word: 'pair', word_class: 'N' },
    { word: 'pair', word_class: 'V' },
    { word: 'paired', word_class: 'AJ' },
    { word: 'pairing', word_class: 'N' }
  ],
  eructate: [
    { word: 'eruct', word_class: 'V' },
    { word: 'eructate', word_class: 'V' },
    { word: 'eructation', word_class: 'N' }
  ],
  disaster: [
    { word: 'disaster', word_class: 'N' },
    { word: 'disastrous', word_class: 'AJ' },
    { word: 'disastrously', word_class: 'AV' }
  ],
  diacritical: [
    { word: 'diacritic', word_class: 'N' },
    { word: 'diacritic', word_class: 'AJ' },
    { word: 'diacritical', word_class: 'AJ' }
  ],
  matured: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  unsystematic: [
    { word: 'unsystematic', word_class: 'AJ' },
    { word: 'unsystematized', word_class: 'AJ' },
    { word: 'unsystematically', word_class: 'AV' }
  ],
  educatee: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  homeric: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  irritability: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  spaced: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  choreograph: [
    { word: 'choreograph', word_class: 'V' },
    { word: 'choreography', word_class: 'N' },
    { word: 'choreographer', word_class: 'N' },
    { word: 'choreographic', word_class: 'AJ' }
  ],
  peripheral: [
    { word: 'periphery', word_class: 'N' },
    { word: 'peripheral', word_class: 'N' },
    { word: 'peripheral', word_class: 'AJ' },
    { word: 'peripherally', word_class: 'AV' }
  ],
  prefigurement: [
    { word: 'prefigure', word_class: 'V' },
    { word: 'prefiguration', word_class: 'N' },
    { word: 'prefigurement', word_class: 'N' },
    { word: 'prefigurative', word_class: 'AJ' }
  ],
  embezzled: [
    { word: 'embezzle', word_class: 'V' },
    { word: 'embezzler', word_class: 'N' },
    { word: 'embezzled', word_class: 'AJ' },
    { word: 'embezzlement', word_class: 'N' }
  ],
  clotting: [
    { word: 'clot', word_class: 'N' },
    { word: 'clot', word_class: 'V' },
    { word: 'clotted', word_class: 'AJ' },
    { word: 'clotting', word_class: 'N' }
  ],
  instrumental: [
    { word: 'instrument', word_class: 'N' },
    { word: 'instrument', word_class: 'V' },
    { word: 'instrumental', word_class: 'N' },
    { word: 'instrumental', word_class: 'AJ' },
    { word: 'instrumentate', word_class: 'V' },
    { word: 'instrumentality', word_class: 'N' },
    { word: 'instrumentation', word_class: 'N' }
  ],
  belting: [
    { word: 'belt', word_class: 'N' },
    { word: 'belt', word_class: 'V' },
    { word: 'belted', word_class: 'AJ' },
    { word: 'belting', word_class: 'N' }
  ],
  curableness: [
    { word: 'curable', word_class: 'AJ' },
    { word: 'curability', word_class: 'N' },
    { word: 'curableness', word_class: 'N' }
  ],
  stabile: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  drafter: [
    { word: 'draft', word_class: 'N' },
    { word: 'draft', word_class: 'V' },
    { word: 'draftee', word_class: 'N' },
    { word: 'drafter', word_class: 'N' },
    { word: 'drafting', word_class: 'N' }
  ],
  conjugation: [
    { word: 'conjugal', word_class: 'AJ' },
    { word: 'conjugate', word_class: 'N' },
    { word: 'conjugate', word_class: 'V' },
    { word: 'conjugate', word_class: 'AJ' },
    { word: 'conjugally', word_class: 'AV' },
    { word: 'conjugated', word_class: 'AJ' },
    { word: 'conjugation', word_class: 'N' }
  ],
  attacking: [
    { word: 'attack', word_class: 'N' },
    { word: 'attack', word_class: 'V' },
    { word: 'attack', word_class: 'AJ' },
    { word: 'attacker', word_class: 'N' },
    { word: 'attacking', word_class: 'AJ' }
  ],
  indispensableness: [
    { word: 'indispensable', word_class: 'AJ' },
    { word: 'indispensability', word_class: 'N' },
    { word: 'indispensableness', word_class: 'N' }
  ],
  understand: [
    { word: 'understand', word_class: 'N' },
    { word: 'understand', word_class: 'V' },
    { word: 'understanding', word_class: 'N' },
    { word: 'understanding', word_class: 'AJ' },
    { word: 'understandable', word_class: 'AJ' },
    { word: 'understandably', word_class: 'AV' },
    { word: 'understandability', word_class: 'N' }
  ],
  rationalize: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  motorcycle: [
    { word: 'motorcycle', word_class: 'N' },
    { word: 'motorcycle', word_class: 'V' },
    { word: 'motorcycling', word_class: 'N' }
  ],
  rehearsal: [
    { word: 'rehearse', word_class: 'N' },
    { word: 'rehearse', word_class: 'V' },
    { word: 'rehearsal', word_class: 'N' }
  ],
  cajolery: [
    { word: 'cajole', word_class: 'V' },
    { word: 'cajolery', word_class: 'N' },
    { word: 'cajolement', word_class: 'N' }
  ],
  plowed: [
    { word: 'plow', word_class: 'N' },
    { word: 'plow', word_class: 'V' },
    { word: 'plowed', word_class: 'AJ' },
    { word: 'plowing', word_class: 'N' }
  ],
  deprecation: [
    { word: 'deprecate', word_class: 'V' },
    { word: 'deprecation', word_class: 'N' },
    { word: 'deprecating', word_class: 'AJ' },
    { word: 'deprecative', word_class: 'AJ' }
  ],
  teuton: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  bung: [{ word: 'bung', word_class: 'N' }, { word: 'bung', word_class: 'V' }, { word: 'bungee', word_class: 'N' }],
  clerking: [
    { word: 'clerk', word_class: 'N' },
    { word: 'clerk', word_class: 'V' },
    { word: 'clerking', word_class: 'N' },
    { word: 'clerkship', word_class: 'N' }
  ],
  inclination: [
    { word: 'incline', word_class: 'N' },
    { word: 'incline', word_class: 'V' },
    { word: 'inclined', word_class: 'AJ' },
    { word: 'inclining', word_class: 'N' },
    { word: 'inclination', word_class: 'N' }
  ],
  uniform: [
    { word: 'uniform', word_class: 'N' },
    { word: 'uniform', word_class: 'V' },
    { word: 'uniform', word_class: 'AJ' },
    { word: 'uniformed', word_class: 'AJ' },
    { word: 'uniformity', word_class: 'N' },
    { word: 'uniformize', word_class: 'V' },
    { word: 'uniformness', word_class: 'N' }
  ],
  magnanimity: [
    { word: 'magnanimity', word_class: 'N' },
    { word: 'magnanimous', word_class: 'AJ' },
    { word: 'magnanimously', word_class: 'AV' },
    { word: 'magnanimousness', word_class: 'N' }
  ],
  volubly: [
    { word: 'voluble', word_class: 'AJ' },
    { word: 'volubly', word_class: 'AV' },
    { word: 'volubility', word_class: 'N' }
  ],
  rain: [{ word: 'rain', word_class: 'N' }, { word: 'rain', word_class: 'V' }, { word: 'raining', word_class: 'AJ' }],
  etherealise: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  excruciate: [
    { word: 'excruciate', word_class: 'V' },
    { word: 'excruciation', word_class: 'N' },
    { word: 'excruciating', word_class: 'AJ' }
  ],
  varied: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  debilitation: [
    { word: 'debilitate', word_class: 'V' },
    { word: 'debilitated', word_class: 'AJ' },
    { word: 'debilitation', word_class: 'N' },
    { word: 'debilitating', word_class: 'AJ' },
    { word: 'debilitative', word_class: 'AJ' }
  ],
  con: [{ word: 'con', word_class: 'N' }, { word: 'con', word_class: 'V' }, { word: 'con', word_class: 'AV' }],
  freeing: [
    { word: 'free', word_class: 'N' },
    { word: 'free', word_class: 'V' },
    { word: 'free', word_class: 'AJ' },
    { word: 'free', word_class: 'AV' },
    { word: 'freedom', word_class: 'N' },
    { word: 'freeing', word_class: 'N' },
    { word: 'freeman', word_class: 'N' },
    { word: 'freeing', word_class: 'AJ' }
  ],
  fizzing: [
    { word: 'fizz', word_class: 'N' },
    { word: 'fizz', word_class: 'V' },
    { word: 'fizzing', word_class: 'AJ' }
  ],
  spellbind: [
    { word: 'spellbind', word_class: 'V' },
    { word: 'spellbinder', word_class: 'N' },
    { word: 'spellbinding', word_class: 'AJ' }
  ],
  stutter: [
    { word: 'stutter', word_class: 'N' },
    { word: 'stutter', word_class: 'V' },
    { word: 'stutterer', word_class: 'N' },
    { word: 'stuttering', word_class: 'N' },
    { word: 'stuttering', word_class: 'AJ' }
  ],
  'snow-blind': [
    { word: 'snow-blind', word_class: 'V' },
    { word: 'snow-blind', word_class: 'AJ' },
    { word: 'snow-blinded', word_class: 'AJ' }
  ],
  excite: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  exuberation: [
    { word: 'exuberate', word_class: 'V' },
    { word: 'exuberance', word_class: 'N' },
    { word: 'exuberant', word_class: 'AJ' },
    { word: 'exuberance', word_class: 'AJ' },
    { word: 'exuberation', word_class: 'N' }
  ],
  waterman: [
    { word: 'water', word_class: 'N' },
    { word: 'water', word_class: 'V' },
    { word: 'waters', word_class: 'N' },
    { word: 'watered', word_class: 'AJ' },
    { word: 'watering', word_class: 'N' },
    { word: 'waterman', word_class: 'N' }
  ],
  treacherous: [
    { word: 'treachery', word_class: 'N' },
    { word: 'treacherous', word_class: 'AJ' },
    { word: 'treacherously', word_class: 'AV' }
  ],
  incomprehensibility: [
    { word: 'incomprehension', word_class: 'N' },
    { word: 'incomprehensive', word_class: 'AJ' },
    { word: 'incomprehensible', word_class: 'AJ' },
    { word: 'incomprehensibility', word_class: 'N' }
  ],
  baffling: [
    { word: 'baffle', word_class: 'N' },
    { word: 'baffle', word_class: 'V' },
    { word: 'baffled', word_class: 'N' },
    { word: 'baffled', word_class: 'AJ' },
    { word: 'baffling', word_class: 'AJ' },
    { word: 'bafflement', word_class: 'N' }
  ],
  oppressive: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  sequester: [
    { word: 'sequester', word_class: 'V' },
    { word: 'sequestrate', word_class: 'V' },
    { word: 'sequestered', word_class: 'AJ' },
    { word: 'sequestration', word_class: 'N' }
  ],
  saturation: [
    { word: 'saturate', word_class: 'V' },
    { word: 'saturable', word_class: 'AJ' },
    { word: 'saturated', word_class: 'AJ' },
    { word: 'saturation', word_class: 'N' }
  ],
  glutton: [
    { word: 'glutton', word_class: 'N' },
    { word: 'gluttonize', word_class: 'V' },
    { word: 'gluttonous', word_class: 'AJ' },
    { word: 'gluttonously', word_class: 'AV' }
  ],
  spur: [{ word: 'spur', word_class: 'N' }, { word: 'spur', word_class: 'V' }, { word: 'spurring', word_class: 'N' }],
  squashed: [
    { word: 'squash', word_class: 'N' },
    { word: 'squash', word_class: 'V' },
    { word: 'squashed', word_class: 'AJ' }
  ],
  reliability: [
    { word: 'reliable', word_class: 'AJ' },
    { word: 'reliably', word_class: 'AV' },
    { word: 'reliability', word_class: 'N' },
    { word: 'reliableness', word_class: 'N' }
  ],
  appellant: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  tumultuousness: [
    { word: 'tumult', word_class: 'N' },
    { word: 'tumultuous', word_class: 'AJ' },
    { word: 'tumultuously', word_class: 'AV' },
    { word: 'tumultuousness', word_class: 'N' }
  ],
  horizontally: [
    { word: 'horizontal', word_class: 'N' },
    { word: 'horizontal', word_class: 'AJ' },
    { word: 'horizontality', word_class: 'N' },
    { word: 'horizontally', word_class: 'AV' }
  ],
  disconsole: [
    { word: 'disconsole', word_class: 'V' },
    { word: 'disconsolate', word_class: 'AJ' },
    { word: 'disconsolately', word_class: 'AV' },
    { word: 'disconsolateness', word_class: 'N' }
  ],
  maximize: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  abut: [
    { word: 'abut', word_class: 'V' },
    { word: 'abuttal', word_class: 'N' },
    { word: 'abutter', word_class: 'N' },
    { word: 'abutment', word_class: 'N' },
    { word: 'abutting', word_class: 'AJ' }
  ],
  kaleidoscopical: [
    { word: 'kaleidoscope', word_class: 'N' },
    { word: 'kaleidoscopic', word_class: 'AJ' },
    { word: 'kaleidoscopical', word_class: 'AJ' }
  ],
  pilotage: [
    { word: 'pilot', word_class: 'N' },
    { word: 'pilot', word_class: 'V' },
    { word: 'pilot', word_class: 'AJ' },
    { word: 'pilotage', word_class: 'N' },
    { word: 'piloting', word_class: 'N' }
  ],
  pacer: [
    { word: 'pace', word_class: 'N' },
    { word: 'pace', word_class: 'V' },
    { word: 'pacer', word_class: 'N' },
    { word: 'pacing', word_class: 'N' }
  ],
  synchronic: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  detach: [
    { word: 'detach', word_class: 'V' },
    { word: 'detached', word_class: 'AJ' },
    { word: 'detachment', word_class: 'N' },
    { word: 'detachable', word_class: 'AJ' }
  ],
  phenomenal: [
    { word: 'phenomena', word_class: 'N' },
    { word: 'phenomenal', word_class: 'AJ' },
    { word: 'phenomenally', word_class: 'AV' }
  ],
  beautiful: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  landowning: [
    { word: 'landowner', word_class: 'N' },
    { word: 'landowning', word_class: 'N' },
    { word: 'landowning', word_class: 'AJ' },
    { word: 'landownership', word_class: 'N' }
  ],
  matriarchate: [
    { word: 'matriarch', word_class: 'N' },
    { word: 'matriarchal', word_class: 'AJ' },
    { word: 'matriarchate', word_class: 'N' },
    { word: 'matriarchic', word_class: 'AJ' }
  ],
  introjected: [
    { word: 'introject', word_class: 'N' },
    { word: 'introject', word_class: 'V' },
    { word: 'introjected', word_class: 'AJ' },
    { word: 'introjection', word_class: 'N' }
  ],
  traditionally: [
    { word: 'traditionalism', word_class: 'N' },
    { word: 'traditionality', word_class: 'N' },
    { word: 'traditionally', word_class: 'AV' }
  ],
  kill: [
    { word: 'kill', word_class: 'N' },
    { word: 'kill', word_class: 'V' },
    { word: 'killer', word_class: 'N' },
    { word: 'killing', word_class: 'N' },
    { word: 'killing', word_class: 'AJ' }
  ],
  spruceness: [
    { word: 'spruce', word_class: 'N' },
    { word: 'spruce', word_class: 'V' },
    { word: 'spruce', word_class: 'AJ' },
    { word: 'sprucely', word_class: 'AV' },
    { word: 'spruceness', word_class: 'N' }
  ],
  burden: [
    { word: 'burden', word_class: 'N' },
    { word: 'burden', word_class: 'V' },
    { word: 'burdened', word_class: 'AJ' },
    { word: 'burdensome', word_class: 'AJ' },
    { word: 'burdensomeness', word_class: 'N' }
  ],
  determinist: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  tay: [{ word: 'tai', word_class: 'N' }, { word: 'tay', word_class: 'N' }, { word: 'tai', word_class: 'AJ' }],
  conditioning: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  commoner: [
    { word: 'common', word_class: 'N' },
    { word: 'common', word_class: 'AJ' },
    { word: 'commons', word_class: 'N' },
    { word: 'commoner', word_class: 'N' },
    { word: 'commonness', word_class: 'N' },
    { word: 'commonality', word_class: 'N' }
  ],
  character: [
    { word: 'character', word_class: 'N' },
    { word: 'character', word_class: 'V' },
    { word: 'characterize', word_class: 'V' },
    { word: 'characterized', word_class: 'AJ' },
    { word: 'characterization', word_class: 'N' }
  ],
  detoxified: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  expedience: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  selectively: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  synthetical: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  inhalation: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  principally: [
    { word: 'principe', word_class: 'N' },
    { word: 'principal', word_class: 'N' },
    { word: 'principal', word_class: 'AJ' },
    { word: 'principality', word_class: 'N' },
    { word: 'principally', word_class: 'AV' }
  ],
  oversimplify: [
    { word: 'oversimplify', word_class: 'V' },
    { word: 'oversimplified', word_class: 'AJ' },
    { word: 'oversimplification', word_class: 'N' }
  ],
  blacktopping: [
    { word: 'blacktop', word_class: 'N' },
    { word: 'blacktop', word_class: 'V' },
    { word: 'blacktopped', word_class: 'AJ' },
    { word: 'blacktopping', word_class: 'N' }
  ],
  ascomycetes: [
    { word: 'ascomycete', word_class: 'N' },
    { word: 'ascomycetes', word_class: 'N' },
    { word: 'ascomycetous', word_class: 'AJ' }
  ],
  jail: [
    { word: 'jail', word_class: 'N' },
    { word: 'jail', word_class: 'V' },
    { word: 'jailer', word_class: 'N' },
    { word: 'jailed', word_class: 'AJ' },
    { word: 'jailing', word_class: 'N' }
  ],
  rheumatic: [
    { word: 'rheuma', word_class: 'N' },
    { word: 'rheumatic', word_class: 'N' },
    { word: 'rheumatic', word_class: 'AJ' },
    { word: 'rheumatism', word_class: 'N' }
  ],
  housebreak: [
    { word: 'housebreak', word_class: 'V' },
    { word: 'housebreaker', word_class: 'N' },
    { word: 'housebroken', word_class: 'AJ' },
    { word: 'housebreaking', word_class: 'N' }
  ],
  subordinate: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  fraternize: [
    { word: 'fraternal', word_class: 'AJ' },
    { word: 'fraternity', word_class: 'N' },
    { word: 'fraternize', word_class: 'V' },
    { word: 'fraternally', word_class: 'AV' },
    { word: 'fraternization', word_class: 'N' }
  ],
  inheriting: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  compatibility: [
    { word: 'compatible', word_class: 'N' },
    { word: 'compatible', word_class: 'AJ' },
    { word: 'compatibly', word_class: 'AV' },
    { word: 'compatibility', word_class: 'N' }
  ],
  weary: [
    { word: 'weary', word_class: 'V' },
    { word: 'weary', word_class: 'AJ' },
    { word: 'wearied', word_class: 'AJ' },
    { word: 'weariness', word_class: 'N' },
    { word: 'wearying', word_class: 'AJ' }
  ],
  introspect: [
    { word: 'introspect', word_class: 'V' },
    { word: 'introspection', word_class: 'N' },
    { word: 'introspective', word_class: 'AJ' },
    { word: 'introspectiveness', word_class: 'N' }
  ],
  witness: [
    { word: 'wit', word_class: 'N' },
    { word: 'wit', word_class: 'V' },
    { word: 'witness', word_class: 'V' },
    { word: 'witness', word_class: 'N' },
    { word: 'witting', word_class: 'AJ' },
    { word: 'witnessed', word_class: 'AJ' }
  ],
  abbreviated: [
    { word: 'abbreviate', word_class: 'V' },
    { word: 'abbreviated', word_class: 'AJ' },
    { word: 'abbreviation', word_class: 'N' }
  ],
  situating: [
    { word: 'situate', word_class: 'V' },
    { word: 'situated', word_class: 'AJ' },
    { word: 'situating', word_class: 'N' },
    { word: 'situation', word_class: 'N' }
  ],
  impoliteness: [
    { word: 'impolite', word_class: 'AJ' },
    { word: 'impolitic', word_class: 'AJ' },
    { word: 'impolitely', word_class: 'AV' },
    { word: 'impoliteness', word_class: 'N' }
  ],
  permutation: [
    { word: 'permute', word_class: 'V' },
    { word: 'permutation', word_class: 'N' },
    { word: 'permutability', word_class: 'N' },
    { word: 'permutableness', word_class: 'N' }
  ],
  spread: [
    { word: 'spread', word_class: 'N' },
    { word: 'spread', word_class: 'V' },
    { word: 'spread', word_class: 'AJ' },
    { word: 'spreader', word_class: 'N' },
    { word: 'spreading', word_class: 'N' },
    { word: 'spreading', word_class: 'AJ' }
  ],
  warm: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  derogate: [
    { word: 'derogate', word_class: 'V' },
    { word: 'derogation', word_class: 'N' },
    { word: 'derogative', word_class: 'AJ' }
  ],
  impartial: [
    { word: 'impartial', word_class: 'AJ' },
    { word: 'impartiality', word_class: 'N' },
    { word: 'impartially', word_class: 'AV' }
  ],
  wire: [
    { word: 'wire', word_class: 'N' },
    { word: 'wire', word_class: 'V' },
    { word: 'wiry', word_class: 'AJ' },
    { word: 'wired', word_class: 'AJ' },
    { word: 'wiring', word_class: 'N' }
  ],
  spot: [
    { word: 'spot', word_class: 'N' },
    { word: 'spot', word_class: 'V' },
    { word: 'spotted', word_class: 'AJ' },
    { word: 'spotting', word_class: 'N' }
  ],
  simulation: [
    { word: 'simulate', word_class: 'V' },
    { word: 'simulator', word_class: 'N' },
    { word: 'simulated', word_class: 'AJ' },
    { word: 'simulation', word_class: 'N' }
  ],
  threads: [
    { word: 'thread', word_class: 'N' },
    { word: 'thread', word_class: 'V' },
    { word: 'threads', word_class: 'N' }
  ],
  centigrade: [
    { word: 'centigrade', word_class: 'N' },
    { word: 'centigrade', word_class: 'V' },
    { word: 'centigrade', word_class: 'AJ' }
  ],
  sensate: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  residual: [
    { word: 'residue', word_class: 'N' },
    { word: 'residual', word_class: 'N' },
    { word: 'residual', word_class: 'AJ' },
    { word: 'residuary', word_class: 'AJ' }
  ],
  comparator: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  beginning: [
    { word: 'begin', word_class: 'V' },
    { word: 'begun', word_class: 'AJ' },
    { word: 'beginning', word_class: 'N' },
    { word: 'beginning', word_class: 'AJ' }
  ],
  heraldic: [
    { word: 'herald', word_class: 'N' },
    { word: 'herald', word_class: 'V' },
    { word: 'heralded', word_class: 'AJ' },
    { word: 'heraldic', word_class: 'AJ' }
  ],
  briber: [
    { word: 'bribe', word_class: 'N' },
    { word: 'bribe', word_class: 'V' },
    { word: 'briber', word_class: 'N' },
    { word: 'bribery', word_class: 'N' },
    { word: 'bribery', word_class: 'AJ' }
  ],
  trammels: [
    { word: 'trammel', word_class: 'N' },
    { word: 'trammel', word_class: 'V' },
    { word: 'trammels', word_class: 'N' }
  ],
  mercer: [
    { word: 'mercer', word_class: 'N' },
    { word: 'mercerise', word_class: 'V' },
    { word: 'mercerize', word_class: 'V' },
    { word: 'mercerisation', word_class: 'N' },
    { word: 'mercerization', word_class: 'N' }
  ],
  slogan: [
    { word: 'slogan', word_class: 'N' },
    { word: 'sloganeer', word_class: 'N' },
    { word: 'sloganeer', word_class: 'V' },
    { word: 'sloganeering', word_class: 'N' }
  ],
  behave: [
    { word: 'behave', word_class: 'V' },
    { word: 'behavior', word_class: 'N' },
    { word: 'behavioral', word_class: 'AJ' },
    { word: 'behaviorism', word_class: 'N' }
  ],
  implausably: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  clangor: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  bodement: [
    { word: 'bode', word_class: 'V' },
    { word: 'boding', word_class: 'N' },
    { word: 'bodement', word_class: 'N' }
  ],
  ade: [{ word: 'ad', word_class: 'N' }, { word: 'ade', word_class: 'N' }, { word: 'added', word_class: 'AJ' }],
  neuter: [
    { word: 'neuter', word_class: 'N' },
    { word: 'neuter', word_class: 'V' },
    { word: 'neuter', word_class: 'AJ' },
    { word: 'neutered', word_class: 'AJ' },
    { word: 'neutering', word_class: 'N' }
  ],
  exonerated: [
    { word: 'exonerate', word_class: 'V' },
    { word: 'exonerated', word_class: 'AJ' },
    { word: 'exoneration', word_class: 'N' }
  ],
  imbruement: [
    { word: 'imbrue', word_class: 'V' },
    { word: 'imbruement', word_class: 'N' },
    { word: 'imbruembrue', word_class: 'V' },
    { word: 'imbruembruement', word_class: 'N' }
  ],
  knockout: [
    { word: 'knockout', word_class: 'N' },
    { word: 'knockout', word_class: 'V' },
    { word: 'knockout', word_class: 'AJ' }
  ],
  comprehensiveness: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  recrystallise: [
    { word: 'recrystallise', word_class: 'V' },
    { word: 'recrystallize', word_class: 'V' },
    { word: 'recrystallisation', word_class: 'N' },
    { word: 'recrystallization', word_class: 'N' }
  ],
  sad: [{ word: 'sad', word_class: 'V' }, { word: 'sad', word_class: 'AJ' }, { word: 'sadness', word_class: 'N' }],
  prosperous: [
    { word: 'prosper', word_class: 'V' },
    { word: 'prosperity', word_class: 'N' },
    { word: 'prospering', word_class: 'AJ' },
    { word: 'prosperous', word_class: 'AJ' },
    { word: 'prosperously', word_class: 'AV' }
  ],
  sin: [
    { word: 'sin', word_class: 'N' },
    { word: 'sin', word_class: 'V' },
    { word: 'sinful', word_class: 'AJ' },
    { word: 'sinning', word_class: 'N' },
    { word: 'sinning', word_class: 'AJ' },
    { word: 'sinfulness', word_class: 'N' }
  ],
  henna: [
    { word: 'henna', word_class: 'N' },
    { word: 'henna', word_class: 'V' },
    { word: 'hennaed', word_class: 'AJ' }
  ],
  pierced: [
    { word: 'pierce', word_class: 'N' },
    { word: 'pierce', word_class: 'V' },
    { word: 'pierced', word_class: 'AJ' },
    { word: 'piercing', word_class: 'AJ' }
  ],
  blushful: [
    { word: 'blush', word_class: 'N' },
    { word: 'blush', word_class: 'V' },
    { word: 'blusher', word_class: 'N' },
    { word: 'blushful', word_class: 'AJ' },
    { word: 'blushing', word_class: 'AJ' }
  ],
  slit: [
    { word: 'slit', word_class: 'N' },
    { word: 'slit', word_class: 'V' },
    { word: 'slit', word_class: 'AJ' },
    { word: 'slitted', word_class: 'AJ' }
  ],
  logistic: [
    { word: 'logistic', word_class: 'AJ' },
    { word: 'logistics', word_class: 'N' },
    { word: 'logistical', word_class: 'AJ' }
  ],
  nester: [
    { word: 'nest', word_class: 'N' },
    { word: 'nest', word_class: 'V' },
    { word: 'nester', word_class: 'N' },
    { word: 'nestor', word_class: 'N' }
  ],
  plagiarise: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  topping: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  relief: [
    { word: 'relief', word_class: 'N' },
    { word: 'relieve', word_class: 'V' },
    { word: 'reliever', word_class: 'N' },
    { word: 'relieved', word_class: 'AJ' }
  ],
  legion: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  review: [
    { word: 'review', word_class: 'N' },
    { word: 'review', word_class: 'V' },
    { word: 'review', word_class: 'AJ' },
    { word: 'reviewer', word_class: 'N' }
  ],
  sled: [{ word: 'sled', word_class: 'N' }, { word: 'sled', word_class: 'V' }, { word: 'sledding', word_class: 'N' }],
  fleeing: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  propaganda: [
    { word: 'propaganda', word_class: 'N' },
    { word: 'propagandist', word_class: 'N' },
    { word: 'propagandize', word_class: 'V' },
    { word: 'propagandist', word_class: 'AJ' },
    { word: 'propagandistic', word_class: 'AJ' },
    { word: 'propagandization', word_class: 'N' }
  ],
  shielding: [
    { word: 'shield', word_class: 'N' },
    { word: 'shield', word_class: 'V' },
    { word: 'shielded', word_class: 'AJ' },
    { word: 'shielding', word_class: 'N' },
    { word: 'shielding', word_class: 'AJ' }
  ],
  abdomen: [
    { word: 'abdomen', word_class: 'N' },
    { word: 'abdominal', word_class: 'AJ' },
    { word: 'abdominous', word_class: 'AJ' },
    { word: 'abdominousness', word_class: 'N' }
  ],
  chronicle: [
    { word: 'chronicle', word_class: 'N' },
    { word: 'chronicle', word_class: 'V' },
    { word: 'chronicler', word_class: 'N' }
  ],
  judaise: [
    { word: 'judaise', word_class: 'V' },
    { word: 'judaize', word_class: 'V' },
    { word: 'judaisation', word_class: 'N' },
    { word: 'judaization', word_class: 'N' }
  ],
  frostbite: [
    { word: 'frostbite', word_class: 'N' },
    { word: 'frostbite', word_class: 'V' },
    { word: 'frostbitten', word_class: 'AJ' }
  ],
  carol: [
    { word: 'carol', word_class: 'N' },
    { word: 'carol', word_class: 'V' },
    { word: 'caroler', word_class: 'N' },
    { word: 'caroling', word_class: 'N' },
    { word: 'caroller', word_class: 'N' }
  ],
  overacting: [
    { word: 'overact', word_class: 'V' },
    { word: 'overacting', word_class: 'N' },
    { word: 'overactive', word_class: 'AJ' },
    { word: 'overactivity', word_class: 'N' }
  ],
  denudation: [
    { word: 'denude', word_class: 'V' },
    { word: 'denudate', word_class: 'V' },
    { word: 'denuded', word_class: 'AJ' },
    { word: 'denudate', word_class: 'AJ' },
    { word: 'denudation', word_class: 'N' }
  ],
  stimulant: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  skating: [
    { word: 'skate', word_class: 'N' },
    { word: 'skate', word_class: 'V' },
    { word: 'skater', word_class: 'N' },
    { word: 'skates', word_class: 'N' },
    { word: 'skating', word_class: 'N' }
  ],
  pharmaceutical: [
    { word: 'pharmaceutic', word_class: 'AJ' },
    { word: 'pharmaceutics', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'AJ' }
  ],
  treated: [
    { word: 'treat', word_class: 'N' },
    { word: 'treat', word_class: 'V' },
    { word: 'treated', word_class: 'AJ' },
    { word: 'treatment', word_class: 'N' }
  ],
  serpentes: [
    { word: 'serpent', word_class: 'N' },
    { word: 'serpentes', word_class: 'N' },
    { word: 'serpentine', word_class: 'AJ' }
  ],
  bellying: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  scintillate: [
    { word: 'scintillate', word_class: 'V' },
    { word: 'scintillant', word_class: 'AJ' },
    { word: 'scintillation', word_class: 'N' },
    { word: 'scintillating', word_class: 'AJ' }
  ],
  infrequently: [
    { word: 'infrequency', word_class: 'N' },
    { word: 'infrequent', word_class: 'AJ' },
    { word: 'infrequently', word_class: 'AV' }
  ],
  sombreness: [
    { word: 'sombre', word_class: 'AJ' },
    { word: 'sombrely', word_class: 'AV' },
    { word: 'sombreness', word_class: 'N' }
  ],
  brewer: [
    { word: 'brew', word_class: 'N' },
    { word: 'brew', word_class: 'V' },
    { word: 'brewer', word_class: 'N' },
    { word: 'brewage', word_class: 'N' },
    { word: 'brewery', word_class: 'N' },
    { word: 'brewing', word_class: 'N' },
    { word: 'brewery', word_class: 'AJ' }
  ],
  beleaguering: [
    { word: 'beleaguer', word_class: 'V' },
    { word: 'beleaguering', word_class: 'N' },
    { word: 'beleaguerment', word_class: 'N' }
  ],
  divine: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  print: [
    { word: 'print', word_class: 'N' },
    { word: 'print', word_class: 'V' },
    { word: 'print', word_class: 'AJ' },
    { word: 'printer', word_class: 'N' },
    { word: 'printed', word_class: 'AJ' },
    { word: 'printing', word_class: 'N' }
  ],
  african: [
    { word: 'african', word_class: 'N' },
    { word: 'african', word_class: 'AJ' },
    { word: 'africanise', word_class: 'V' },
    { word: 'africanize', word_class: 'V' },
    { word: 'africanisation', word_class: 'N' },
    { word: 'africanization', word_class: 'N' }
  ],
  mongolic: [
    { word: 'mongol', word_class: 'N' },
    { word: 'mongol', word_class: 'AJ' },
    { word: 'mongolic', word_class: 'N' },
    { word: 'mongolism', word_class: 'N' }
  ],
  rider: [
    { word: 'ride', word_class: 'N' },
    { word: 'ride', word_class: 'V' },
    { word: 'rider', word_class: 'N' },
    { word: 'riding', word_class: 'N' },
    { word: 'ridden', word_class: 'AJ' },
    { word: 'riding', word_class: 'AJ' }
  ],
  marri: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  achromatous: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  union: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  indispose: [
    { word: 'indispose', word_class: 'V' },
    { word: 'indisposed', word_class: 'AJ' },
    { word: 'indisposition', word_class: 'N' }
  ],
  vile: [
    { word: 'vile', word_class: 'AJ' },
    { word: 'vilely', word_class: 'AV' },
    { word: 'vileness', word_class: 'N' }
  ],
  pitcher: [
    { word: 'pitch', word_class: 'N' },
    { word: 'pitch', word_class: 'V' },
    { word: 'pitcher', word_class: 'N' },
    { word: 'pitched', word_class: 'AJ' },
    { word: 'pitching', word_class: 'N' },
    { word: 'pitcherful', word_class: 'N' }
  ],
  sewing: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  centrality: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  castling: [
    { word: 'castle', word_class: 'N' },
    { word: 'castle', word_class: 'V' },
    { word: 'castled', word_class: 'AJ' },
    { word: 'castling', word_class: 'N' }
  ],
  palatable: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  cough: [
    { word: 'cough', word_class: 'N' },
    { word: 'cough', word_class: 'V' },
    { word: 'coughing', word_class: 'N' }
  ],
  unionise: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  outpouring: [
    { word: 'outpour', word_class: 'N' },
    { word: 'outpour', word_class: 'V' },
    { word: 'outpouring', word_class: 'N' }
  ],
  astonishing: [
    { word: 'astonish', word_class: 'V' },
    { word: 'astonished', word_class: 'AJ' },
    { word: 'astonishing', word_class: 'AJ' },
    { word: 'astonishment', word_class: 'N' }
  ],
  restore: [
    { word: 'restore', word_class: 'N' },
    { word: 'restore', word_class: 'V' },
    { word: 'restorer', word_class: 'N' },
    { word: 'restored', word_class: 'AJ' },
    { word: 'restoration', word_class: 'N' },
    { word: 'restorative', word_class: 'N' },
    { word: 'restorative', word_class: 'AJ' }
  ],
  coordinator: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  pilferer: [
    { word: 'pilfer', word_class: 'V' },
    { word: 'pilferer', word_class: 'N' },
    { word: 'pilferage', word_class: 'N' }
  ],
  journalization: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  petting: [
    { word: 'pet', word_class: 'N' },
    { word: 'pet', word_class: 'V' },
    { word: 'pet', word_class: 'AJ' },
    { word: 'petting', word_class: 'N' }
  ],
  compartmentalise: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  preservative: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  jangling: [
    { word: 'jangle', word_class: 'N' },
    { word: 'jangle', word_class: 'V' },
    { word: 'jangling', word_class: 'AJ' }
  ],
  regress: [
    { word: 'regress', word_class: 'N' },
    { word: 'regress', word_class: 'V' },
    { word: 'regression', word_class: 'N' },
    { word: 'regressing', word_class: 'AJ' },
    { word: 'regressive', word_class: 'AJ' }
  ],
  chopin: [
    { word: 'chopin', word_class: 'N' },
    { word: 'chopine', word_class: 'N' },
    { word: 'chopines', word_class: 'N' }
  ],
  rushing: [
    { word: 'rush', word_class: 'N' },
    { word: 'rush', word_class: 'V' },
    { word: 'rush', word_class: 'AJ' },
    { word: 'rushed', word_class: 'AJ' },
    { word: 'rushing', word_class: 'N' },
    { word: 'rushing', word_class: 'AJ' }
  ],
  sublimation: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  name: [
    { word: 'name', word_class: 'N' },
    { word: 'name', word_class: 'V' },
    { word: 'names', word_class: 'N' },
    { word: 'named', word_class: 'AJ' },
    { word: 'naming', word_class: 'N' },
    { word: 'namely', word_class: 'AV' },
    { word: 'naming', word_class: 'AJ' }
  ],
  sentimentalize: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  retaliation: [
    { word: 'retaliate', word_class: 'V' },
    { word: 'retaliator', word_class: 'N' },
    { word: 'retaliation', word_class: 'N' },
    { word: 'retaliative', word_class: 'AJ' }
  ],
  moult: [
    { word: 'mould', word_class: 'N' },
    { word: 'mould', word_class: 'V' },
    { word: 'moult', word_class: 'N' },
    { word: 'moult', word_class: 'V' },
    { word: 'moulding', word_class: 'N' },
    { word: 'moulting', word_class: 'N' }
  ],
  pervert: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  scrubbing: [
    { word: 'scrub', word_class: 'N' },
    { word: 'scrub', word_class: 'V' },
    { word: 'scrub', word_class: 'AJ' },
    { word: 'scrubs', word_class: 'N' },
    { word: 'scrubbed', word_class: 'AJ' },
    { word: 'scrubbing', word_class: 'N' }
  ],
  speaker: [
    { word: 'speak', word_class: 'V' },
    { word: 'speech', word_class: 'N' },
    { word: 'speaker', word_class: 'N' },
    { word: 'spoken', word_class: 'AJ' },
    { word: 'speaking', word_class: 'N' },
    { word: 'speaking', word_class: 'AJ' }
  ],
  polytonalism: [
    { word: 'polytonal', word_class: 'AJ' },
    { word: 'polytonalism', word_class: 'N' },
    { word: 'polytonality', word_class: 'N' }
  ],
  profundity: [
    { word: 'profound', word_class: 'V' },
    { word: 'profound', word_class: 'AJ' },
    { word: 'profundity', word_class: 'N' },
    { word: 'profoundness', word_class: 'N' }
  ],
  over: [
    { word: 'over', word_class: 'N' },
    { word: 'over', word_class: 'AJ' },
    { word: 'over', word_class: 'AV' },
    { word: 'overeating', word_class: 'N' }
  ],
  superciliousness: [
    { word: 'supercilious', word_class: 'AJ' },
    { word: 'superciliously', word_class: 'AV' },
    { word: 'superciliousness', word_class: 'N' }
  ],
  longitudinally: [
    { word: 'longitude', word_class: 'N' },
    { word: 'longitudinal', word_class: 'AJ' },
    { word: 'longitudinally', word_class: 'AV' }
  ],
  pain: [
    { word: 'pain', word_class: 'N' },
    { word: 'pain', word_class: 'V' },
    { word: 'pains', word_class: 'N' },
    { word: 'pained', word_class: 'AJ' },
    { word: 'painful', word_class: 'AJ' },
    { word: 'painfulness', word_class: 'N' }
  ],
  subscribed: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  accuracy: [
    { word: 'accuracy', word_class: 'N' },
    { word: 'accurate', word_class: 'AJ' },
    { word: 'accurately', word_class: 'AV' }
  ],
  flamboyant: [
    { word: 'flamboyant', word_class: 'N' },
    { word: 'flamboyance', word_class: 'N' },
    { word: 'flamboyancy', word_class: 'N' },
    { word: 'flamboyant', word_class: 'AJ' }
  ],
  bronze: [
    { word: 'bronze', word_class: 'N' },
    { word: 'bronze', word_class: 'V' },
    { word: 'bronze', word_class: 'AJ' },
    { word: 'bronzed', word_class: 'AJ' }
  ],
  multifariousness: [
    { word: 'multifarious', word_class: 'AJ' },
    { word: 'multifariously', word_class: 'AV' },
    { word: 'multifariousness', word_class: 'N' }
  ],
  prattle: [
    { word: 'prattle', word_class: 'N' },
    { word: 'prattle', word_class: 'V' },
    { word: 'prattler', word_class: 'N' },
    { word: 'prattling', word_class: 'AJ' }
  ],
  sorcery: [
    { word: 'sorcery', word_class: 'N' },
    { word: 'sorcerer', word_class: 'N' },
    { word: 'sorceress', word_class: 'N' },
    { word: 'sorcerize', word_class: 'V' },
    { word: 'sorcerous', word_class: 'AJ' }
  ],
  intrinsically: [
    { word: 'intrinsic', word_class: 'AJ' },
    { word: 'intrinsical', word_class: 'AJ' },
    { word: 'intrinsically', word_class: 'AV' }
  ],
  popping: [
    { word: 'pop', word_class: 'N' },
    { word: 'pop', word_class: 'V' },
    { word: 'pop', word_class: 'AJ' },
    { word: 'pop', word_class: 'AV' },
    { word: 'popping', word_class: 'N' }
  ],
  instigative: [
    { word: 'instigant', word_class: 'N' },
    { word: 'instigate', word_class: 'V' },
    { word: 'instigator', word_class: 'N' },
    { word: 'instigation', word_class: 'N' },
    { word: 'instigative', word_class: 'AJ' }
  ],
  abstractness: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  striker: [
    { word: 'strike', word_class: 'N' },
    { word: 'strike', word_class: 'V' },
    { word: 'striker', word_class: 'N' },
    { word: 'striking', word_class: 'N' },
    { word: 'stricken', word_class: 'AJ' },
    { word: 'striking', word_class: 'AJ' }
  ],
  symbolism: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  lenience: [
    { word: 'lenience', word_class: 'N' },
    { word: 'leniency', word_class: 'N' },
    { word: 'lenient', word_class: 'AJ' },
    { word: 'leniently', word_class: 'AV' }
  ],
  trilobate: [
    { word: 'trilobed', word_class: 'AJ' },
    { word: 'trilobate', word_class: 'AJ' },
    { word: 'trilobated', word_class: 'AJ' }
  ],
  mechanistically: [
    { word: 'mechanist', word_class: 'N' },
    { word: 'mechanistic', word_class: 'AJ' },
    { word: 'mechanistically', word_class: 'AV' }
  ],
  prototypic: [
    { word: 'prototype', word_class: 'N' },
    { word: 'prototype', word_class: 'V' },
    { word: 'prototypal', word_class: 'AJ' },
    { word: 'prototypic', word_class: 'AJ' },
    { word: 'prototypical', word_class: 'AJ' }
  ],
  territorialisation: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  violability: [
    { word: 'violate', word_class: 'V' },
    { word: 'violator', word_class: 'N' },
    { word: 'violable', word_class: 'AJ' },
    { word: 'violably', word_class: 'AV' },
    { word: 'violated', word_class: 'AJ' },
    { word: 'violation', word_class: 'N' },
    { word: 'violability', word_class: 'N' },
    { word: 'violability', word_class: 'AJ' }
  ],
  plagiarized: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  battleful: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  perilousness: [
    { word: 'peril', word_class: 'N' },
    { word: 'peril', word_class: 'V' },
    { word: 'perilous', word_class: 'AJ' },
    { word: 'perilously', word_class: 'AV' },
    { word: 'perilousness', word_class: 'N' }
  ],
  befouled: [
    { word: 'befoul', word_class: 'V' },
    { word: 'befouled', word_class: 'AJ' },
    { word: 'befoulment', word_class: 'N' }
  ],
  hulk: [{ word: 'hulk', word_class: 'N' }, { word: 'hulk', word_class: 'V' }, { word: 'hulking', word_class: 'AJ' }],
  systemization: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  variolation: [
    { word: 'variolate', word_class: 'V' },
    { word: 'variolation', word_class: 'N' },
    { word: 'variolisation', word_class: 'N' },
    { word: 'variolization', word_class: 'N' }
  ],
  enjoinment: [
    { word: 'enjoin', word_class: 'V' },
    { word: 'enjoining', word_class: 'N' },
    { word: 'enjoinment', word_class: 'N' }
  ],
  excrete: [
    { word: 'excrete', word_class: 'N' },
    { word: 'excrete', word_class: 'V' },
    { word: 'excreting', word_class: 'N' },
    { word: 'excretion', word_class: 'N' }
  ],
  bootes: [
    { word: 'boot', word_class: 'N' },
    { word: 'boot', word_class: 'V' },
    { word: 'bootee', word_class: 'N' },
    { word: 'bootes', word_class: 'N' },
    { word: 'booted', word_class: 'AJ' },
    { word: 'bootees', word_class: 'N' }
  ],
  diffidence: [
    { word: 'diffidence', word_class: 'N' },
    { word: 'diffident', word_class: 'AJ' },
    { word: 'diffidently', word_class: 'AV' }
  ],
  tusked: [{ word: 'tusk', word_class: 'N' }, { word: 'tusk', word_class: 'V' }, { word: 'tusked', word_class: 'AJ' }],
  formulaize: [
    { word: 'formula', word_class: 'N' },
    { word: 'formulaic', word_class: 'AJ' },
    { word: 'formulaize', word_class: 'V' }
  ],
  imbecile: [
    { word: 'imbecile', word_class: 'N' },
    { word: 'imbecile', word_class: 'AJ' },
    { word: 'imbecilic', word_class: 'AJ' },
    { word: 'imbecility', word_class: 'N' }
  ],
  perfumed: [
    { word: 'perfume', word_class: 'N' },
    { word: 'perfume', word_class: 'V' },
    { word: 'perfumer', word_class: 'N' },
    { word: 'perfumed', word_class: 'AJ' },
    { word: 'perfumery', word_class: 'N' },
    { word: 'perfumery', word_class: 'AJ' }
  ],
  dampness: [
    { word: 'damp', word_class: 'N' },
    { word: 'damp', word_class: 'V' },
    { word: 'damp', word_class: 'AJ' },
    { word: 'damper', word_class: 'N' },
    { word: 'dampness', word_class: 'N' }
  ],
  bureaucracy: [
    { word: 'bureaucrat', word_class: 'N' },
    { word: 'bureaucracy', word_class: 'N' },
    { word: 'bureaucratic', word_class: 'AJ' },
    { word: 'bureaucratism', word_class: 'N' },
    { word: 'bureaucratically', word_class: 'AV' }
  ],
  sermonize: [
    { word: 'sermon', word_class: 'N' },
    { word: 'sermonize', word_class: 'V' },
    { word: 'sermonizer', word_class: 'N' },
    { word: 'sermonization', word_class: 'N' }
  ],
  ani: [{ word: 'ani', word_class: 'N' }, { word: 'any', word_class: 'AJ' }, { word: 'any', word_class: 'AV' }],
  luger: [
    { word: 'lug', word_class: 'N' },
    { word: 'lug', word_class: 'V' },
    { word: 'luger', word_class: 'N' },
    { word: 'luggage', word_class: 'N' }
  ],
  diabolise: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  prompting: [
    { word: 'prompt', word_class: 'N' },
    { word: 'prompt', word_class: 'V' },
    { word: 'prompt', word_class: 'AJ' },
    { word: 'prompter', word_class: 'N' },
    { word: 'prompting', word_class: 'N' },
    { word: 'promptness', word_class: 'N' }
  ],
  crenellation: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  activistic: [
    { word: 'activist', word_class: 'N' },
    { word: 'activist', word_class: 'AJ' },
    { word: 'activistic', word_class: 'AJ' }
  ],
  unilaterally: [
    { word: 'unilateral', word_class: 'AJ' },
    { word: 'unilateralism', word_class: 'N' },
    { word: 'unilaterally', word_class: 'AV' }
  ],
  integration: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  enlargement: [
    { word: 'enlarge', word_class: 'V' },
    { word: 'enlarger', word_class: 'N' },
    { word: 'enlarged', word_class: 'AJ' },
    { word: 'enlargement', word_class: 'N' }
  ],
  jab: [{ word: 'jab', word_class: 'N' }, { word: 'jab', word_class: 'V' }, { word: 'jabbing', word_class: 'N' }],
  drill: [
    { word: 'drill', word_class: 'N' },
    { word: 'drill', word_class: 'V' },
    { word: 'drilled', word_class: 'AJ' },
    { word: 'drilling', word_class: 'N' }
  ],
  mumbling: [
    { word: 'mumble', word_class: 'V' },
    { word: 'mumbler', word_class: 'N' },
    { word: 'mumbling', word_class: 'N' },
    { word: 'mumbling', word_class: 'AJ' }
  ],
  diabolically: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  heading: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  conciliative: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  colonialism: [
    { word: 'colony', word_class: 'N' },
    { word: 'colony', word_class: 'AJ' },
    { word: 'colonial', word_class: 'N' },
    { word: 'colonial', word_class: 'AJ' },
    { word: 'colonialism', word_class: 'N' }
  ],
  accrete: [
    { word: 'accrete', word_class: 'V' },
    { word: 'accretion', word_class: 'N' },
    { word: 'accretive', word_class: 'AJ' }
  ],
  authorize: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  mirthfulness: [
    { word: 'mirth', word_class: 'N' },
    { word: 'mirthful', word_class: 'AJ' },
    { word: 'mirthfulness', word_class: 'N' }
  ],
  sovereignty: [
    { word: 'sovereign', word_class: 'N' },
    { word: 'sovereign', word_class: 'AJ' },
    { word: 'sovereignty', word_class: 'N' }
  ],
  purse: [{ word: 'purse', word_class: 'N' }, { word: 'purse', word_class: 'V' }, { word: 'purser', word_class: 'N' }],
  invent: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  denaturalize: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  foliate: [
    { word: 'foliate', word_class: 'V' },
    { word: 'foliate', word_class: 'AJ' },
    { word: 'foliated', word_class: 'AJ' },
    { word: 'foliation', word_class: 'N' }
  ],
  drilling: [
    { word: 'drill', word_class: 'N' },
    { word: 'drill', word_class: 'V' },
    { word: 'drilled', word_class: 'AJ' },
    { word: 'drilling', word_class: 'N' }
  ],
  bored: [
    { word: 'bore', word_class: 'N' },
    { word: 'bore', word_class: 'V' },
    { word: 'borer', word_class: 'N' },
    { word: 'bored', word_class: 'AJ' },
    { word: 'boring', word_class: 'N' },
    { word: 'boredom', word_class: 'N' },
    { word: 'boring', word_class: 'AJ' }
  ],
  pride: [
    { word: 'pride', word_class: 'N' },
    { word: 'pride', word_class: 'V' },
    { word: 'prideful', word_class: 'AJ' },
    { word: 'pridefulness', word_class: 'N' }
  ],
  cyclable: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  dishevelment: [
    { word: 'dishevel', word_class: 'V' },
    { word: 'disheveled', word_class: 'AJ' },
    { word: 'dishevelled', word_class: 'AJ' },
    { word: 'dishevelment', word_class: 'N' }
  ],
  introspective: [
    { word: 'introspect', word_class: 'V' },
    { word: 'introspection', word_class: 'N' },
    { word: 'introspective', word_class: 'AJ' },
    { word: 'introspectiveness', word_class: 'N' }
  ],
  powered: [
    { word: 'power', word_class: 'N' },
    { word: 'power', word_class: 'V' },
    { word: 'power', word_class: 'AJ' },
    { word: 'powered', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AV' },
    { word: 'powerfulness', word_class: 'N' }
  ],
  glimmer: [
    { word: 'glimmer', word_class: 'N' },
    { word: 'glimmer', word_class: 'V' },
    { word: 'glimmering', word_class: 'N' },
    { word: 'glimmering', word_class: 'AJ' }
  ],
  drizzly: [
    { word: 'drizzle', word_class: 'N' },
    { word: 'drizzle', word_class: 'V' },
    { word: 'drizzly', word_class: 'AJ' },
    { word: 'drizzling', word_class: 'AJ' }
  ],
  confuse: [
    { word: 'confuse', word_class: 'V' },
    { word: 'confused', word_class: 'AJ' },
    { word: 'confusion', word_class: 'N' },
    { word: 'confusing', word_class: 'AJ' },
    { word: 'confusable', word_class: 'AJ' }
  ],
  vulnerable: [
    { word: 'vulnerable', word_class: 'AJ' },
    { word: 'vulnerably', word_class: 'AV' },
    { word: 'vulnerability', word_class: 'N' }
  ],
  quenching: [
    { word: 'quench', word_class: 'V' },
    { word: 'quenched', word_class: 'AJ' },
    { word: 'quenching', word_class: 'N' }
  ],
  concurrently: [
    { word: 'concur', word_class: 'V' },
    { word: 'concurrence', word_class: 'N' },
    { word: 'concurrent', word_class: 'AJ' },
    { word: 'concurring', word_class: 'AJ' },
    { word: 'concurrently', word_class: 'AV' }
  ],
  impressionable: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  adjudication: [
    { word: 'adjudicate', word_class: 'V' },
    { word: 'adjudicator', word_class: 'N' },
    { word: 'adjudication', word_class: 'N' },
    { word: 'adjudicative', word_class: 'AJ' }
  ],
  mortally: [
    { word: 'mortal', word_class: 'N' },
    { word: 'mortal', word_class: 'AJ' },
    { word: 'mortality', word_class: 'N' },
    { word: 'mortally', word_class: 'AV' }
  ],
  objectionability: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  distributional: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  exaltation: [
    { word: 'exalt', word_class: 'V' },
    { word: 'exalted', word_class: 'AJ' },
    { word: 'exalting', word_class: 'AJ' },
    { word: 'exaltation', word_class: 'N' }
  ],
  compartmental: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  impressively: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  tinsel: [
    { word: 'tinsel', word_class: 'N' },
    { word: 'tinsel', word_class: 'V' },
    { word: 'tinsel', word_class: 'AJ' },
    { word: 'tinseled', word_class: 'AJ' }
  ],
  mesh: [
    { word: 'mesh', word_class: 'N' },
    { word: 'mesh', word_class: 'V' },
    { word: 'meshed', word_class: 'N' },
    { word: 'meshed', word_class: 'AJ' },
    { word: 'meshing', word_class: 'N' }
  ],
  quackery: [
    { word: 'quack', word_class: 'N' },
    { word: 'quack', word_class: 'V' },
    { word: 'quack', word_class: 'AJ' },
    { word: 'quackery', word_class: 'N' },
    { word: 'quackery', word_class: 'AJ' }
  ],
  multitudinous: [
    { word: 'multitude', word_class: 'N' },
    { word: 'multitudinous', word_class: 'AJ' },
    { word: 'multitudinousness', word_class: 'N' }
  ],
  ridiculous: [
    { word: 'ridicule', word_class: 'N' },
    { word: 'ridicule', word_class: 'V' },
    { word: 'ridiculous', word_class: 'AJ' },
    { word: 'ridiculously', word_class: 'AV' },
    { word: 'ridiculousness', word_class: 'N' }
  ],
  fleetness: [
    { word: 'fleet', word_class: 'N' },
    { word: 'fleet', word_class: 'V' },
    { word: 'fleet', word_class: 'AJ' },
    { word: 'fleeting', word_class: 'AJ' },
    { word: 'fleetness', word_class: 'N' }
  ],
  dilutant: [
    { word: 'dilute', word_class: 'N' },
    { word: 'dilute', word_class: 'V' },
    { word: 'dilute', word_class: 'AJ' },
    { word: 'dilutant', word_class: 'N' },
    { word: 'diluted', word_class: 'AJ' },
    { word: 'diluting', word_class: 'N' },
    { word: 'dilution', word_class: 'N' }
  ],
  immobilization: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  toed: [
    { word: 'toe', word_class: 'N' },
    { word: 'toe', word_class: 'V' },
    { word: 'toe', word_class: 'AJ' },
    { word: 'toed', word_class: 'AJ' }
  ],
  expelling: [
    { word: 'expel', word_class: 'V' },
    { word: 'expell', word_class: 'V' },
    { word: 'expelling', word_class: 'N' },
    { word: 'expulsion', word_class: 'N' }
  ],
  aphorism: [
    { word: 'aphorism', word_class: 'N' },
    { word: 'aphorize', word_class: 'V' },
    { word: 'aphorization', word_class: 'N' }
  ],
  contribute: [
    { word: 'contribute', word_class: 'V' },
    { word: 'contributor', word_class: 'N' },
    { word: 'contribution', word_class: 'N' },
    { word: 'contributing', word_class: 'AJ' },
    { word: 'contributive', word_class: 'AJ' }
  ],
  calloused: [
    { word: 'calloused', word_class: 'AJ' },
    { word: 'callously', word_class: 'AV' },
    { word: 'callousness', word_class: 'N' }
  ],
  performer: [
    { word: 'perform', word_class: 'V' },
    { word: 'performer', word_class: 'N' },
    { word: 'performing', word_class: 'N' },
    { word: 'performance', word_class: 'N' }
  ],
  exploration: [
    { word: 'explore', word_class: 'V' },
    { word: 'explorer', word_class: 'N' },
    { word: 'exploration', word_class: 'N' },
    { word: 'explorative', word_class: 'AJ' },
    { word: 'exploratory', word_class: 'AJ' }
  ],
  ingenious: [
    { word: 'ingenious', word_class: 'AJ' },
    { word: 'ingeniously', word_class: 'AV' },
    { word: 'ingeniousness', word_class: 'N' }
  ],
  sandalled: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  monarchical: [
    { word: 'monarch', word_class: 'N' },
    { word: 'monarchal', word_class: 'AJ' },
    { word: 'monarchic', word_class: 'AJ' },
    { word: 'monarchism', word_class: 'N' },
    { word: 'monarchical', word_class: 'AJ' }
  ],
  vitrify: [
    { word: 'vitrify', word_class: 'V' },
    { word: 'vitrified', word_class: 'AJ' },
    { word: 'vitrification', word_class: 'N' }
  ],
  winning: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  agnation: [
    { word: 'agnate', word_class: 'N' },
    { word: 'agnate', word_class: 'V' },
    { word: 'agnate', word_class: 'AJ' },
    { word: 'agnatic', word_class: 'AJ' },
    { word: 'agnation', word_class: 'N' }
  ],
  mathematics: [
    { word: 'mathematic', word_class: 'AJ' },
    { word: 'mathematics', word_class: 'N' },
    { word: 'mathematical', word_class: 'AJ' },
    { word: 'mathematically', word_class: 'AV' }
  ],
  seeder: [
    { word: 'seed', word_class: 'N' },
    { word: 'seed', word_class: 'V' },
    { word: 'seeder', word_class: 'N' },
    { word: 'seeded', word_class: 'AJ' },
    { word: 'seedman', word_class: 'N' }
  ],
  intradermic: [
    { word: 'intradermal', word_class: 'AJ' },
    { word: 'intradermic', word_class: 'AJ' },
    { word: 'intradermally', word_class: 'AV' }
  ],
  screech: [
    { word: 'screech', word_class: 'N' },
    { word: 'screech', word_class: 'V' },
    { word: 'screeching', word_class: 'N' },
    { word: 'screeching', word_class: 'AJ' }
  ],
  impulse: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  wedded: [
    { word: 'wed', word_class: 'N' },
    { word: 'wed', word_class: 'V' },
    { word: 'wed', word_class: 'AJ' },
    { word: 'wedding', word_class: 'N' },
    { word: 'wedded', word_class: 'AJ' }
  ],
  serpentine: [
    { word: 'serpent', word_class: 'N' },
    { word: 'serpentes', word_class: 'N' },
    { word: 'serpentine', word_class: 'AJ' }
  ],
  lyric: [
    { word: 'lyric', word_class: 'N' },
    { word: 'lyric', word_class: 'V' },
    { word: 'lyric', word_class: 'AJ' },
    { word: 'lyrical', word_class: 'AJ' },
    { word: 'lyricism', word_class: 'N' },
    { word: 'lyricality', word_class: 'N' },
    { word: 'lyrically', word_class: 'AV' }
  ],
  sub: [{ word: 'sub', word_class: 'N' }, { word: 'sub', word_class: 'V' }, { word: 'subbing', word_class: 'N' }],
  banging: [
    { word: 'bang', word_class: 'V' },
    { word: 'bang', word_class: 'N' },
    { word: 'bang', word_class: 'AV' },
    { word: 'banger', word_class: 'N' },
    { word: 'banging', word_class: 'N' },
    { word: 'banging', word_class: 'AJ' }
  ],
  apathetic: [
    { word: 'apathy', word_class: 'N' },
    { word: 'apathetic', word_class: 'AJ' },
    { word: 'apathetically', word_class: 'AV' }
  ],
  cartographical: [
    { word: 'cartographer', word_class: 'N' },
    { word: 'cartographic', word_class: 'AJ' },
    { word: 'cartographical', word_class: 'AJ' }
  ],
  observance: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  director: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  foursquare: [
    { word: 'foursquare', word_class: 'N' },
    { word: 'foursquare', word_class: 'AJ' },
    { word: 'foursquare', word_class: 'AV' }
  ],
  demonstrate: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  rotate: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  recorded: [
    { word: 'record', word_class: 'N' },
    { word: 'record', word_class: 'V' },
    { word: 'record', word_class: 'AJ' },
    { word: 'recorder', word_class: 'N' },
    { word: 'recording', word_class: 'N' },
    { word: 'recorded', word_class: 'AJ' },
    { word: 'recordation', word_class: 'N' }
  ],
  stationer: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  imagine: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  ridicule: [
    { word: 'ridicule', word_class: 'N' },
    { word: 'ridicule', word_class: 'V' },
    { word: 'ridiculous', word_class: 'AJ' },
    { word: 'ridiculously', word_class: 'AV' },
    { word: 'ridiculousness', word_class: 'N' }
  ],
  jubilant: [
    { word: 'jubilate', word_class: 'V' },
    { word: 'jubilance', word_class: 'N' },
    { word: 'jubilancy', word_class: 'N' },
    { word: 'jubilant', word_class: 'AJ' },
    { word: 'jubilation', word_class: 'N' }
  ],
  deposition: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  pains: [
    { word: 'pain', word_class: 'N' },
    { word: 'pain', word_class: 'V' },
    { word: 'pains', word_class: 'N' },
    { word: 'pained', word_class: 'AJ' },
    { word: 'painful', word_class: 'AJ' },
    { word: 'painfulness', word_class: 'N' }
  ],
  unremarkable: [
    { word: 'unremarked', word_class: 'AJ' },
    { word: 'unremarkable', word_class: 'AJ' },
    { word: 'unremarkably', word_class: 'AV' }
  ],
  objectionable: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  paved: [
    { word: 'pave', word_class: 'V' },
    { word: 'paved', word_class: 'AJ' },
    { word: 'paving', word_class: 'N' },
    { word: 'pavement', word_class: 'N' }
  ],
  engraftation: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  reverent: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  displeased: [
    { word: 'displease', word_class: 'V' },
    { word: 'displeased', word_class: 'AJ' },
    { word: 'displeasure', word_class: 'N' },
    { word: 'displeasing', word_class: 'AJ' }
  ],
  agnate: [
    { word: 'agnate', word_class: 'N' },
    { word: 'agnate', word_class: 'V' },
    { word: 'agnate', word_class: 'AJ' },
    { word: 'agnatic', word_class: 'AJ' },
    { word: 'agnation', word_class: 'N' }
  ],
  preach: [
    { word: 'preach', word_class: 'V' },
    { word: 'preacher', word_class: 'N' },
    { word: 'preachy', word_class: 'AJ' },
    { word: 'preaching', word_class: 'N' },
    { word: 'preachment', word_class: 'N' }
  ],
  lancer: [
    { word: 'lance', word_class: 'N' },
    { word: 'lance', word_class: 'V' },
    { word: 'lancer', word_class: 'N' },
    { word: 'lancers', word_class: 'N' }
  ],
  believing: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  inflect: [
    { word: 'inflect', word_class: 'V' },
    { word: 'inflected', word_class: 'AJ' },
    { word: 'inflection', word_class: 'N' },
    { word: 'inflectional', word_class: 'AJ' }
  ],
  islamism: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  pay: [
    { word: 'pay', word_class: 'N' },
    { word: 'pay', word_class: 'V' },
    { word: 'payee', word_class: 'N' },
    { word: 'payer', word_class: 'N' },
    { word: 'paying', word_class: 'N' },
    { word: 'paying', word_class: 'AJ' },
    { word: 'payment', word_class: 'N' }
  ],
  contestation: [
    { word: 'contest', word_class: 'N' },
    { word: 'contest', word_class: 'V' },
    { word: 'contestant', word_class: 'N' },
    { word: 'contestable', word_class: 'AJ' },
    { word: 'contestation', word_class: 'N' }
  ],
  blindness: [
    { word: 'blind', word_class: 'N' },
    { word: 'blind', word_class: 'V' },
    { word: 'blind', word_class: 'AJ' },
    { word: 'blinder', word_class: 'N' },
    { word: 'blinded', word_class: 'AJ' },
    { word: 'blinding', word_class: 'AJ' },
    { word: 'blindness', word_class: 'N' }
  ],
  indifference: [
    { word: 'indifference', word_class: 'N' },
    { word: 'indifferent', word_class: 'AJ' },
    { word: 'indifferently', word_class: 'AV' }
  ],
  elbow: [
    { word: 'elbow', word_class: 'N' },
    { word: 'elbow', word_class: 'V' },
    { word: 'elbowing', word_class: 'N' }
  ],
  pump: [{ word: 'pump', word_class: 'N' }, { word: 'pump', word_class: 'V' }, { word: 'pumps', word_class: 'N' }],
  basting: [
    { word: 'bast', word_class: 'N' },
    { word: 'baste', word_class: 'V' },
    { word: 'baste', word_class: 'N' },
    { word: 'basting', word_class: 'N' },
    { word: 'bastion', word_class: 'N' },
    { word: 'bastioned', word_class: 'AJ' }
  ],
  ravening: [
    { word: 'raven', word_class: 'N' },
    { word: 'raven', word_class: 'V' },
    { word: 'ravening', word_class: 'AJ' },
    { word: 'ravenous', word_class: 'AJ' },
    { word: 'ravenously', word_class: 'AV' },
    { word: 'ravenousness', word_class: 'N' }
  ],
  wedding: [
    { word: 'wed', word_class: 'N' },
    { word: 'wed', word_class: 'V' },
    { word: 'wed', word_class: 'AJ' },
    { word: 'wedding', word_class: 'N' },
    { word: 'wedded', word_class: 'AJ' }
  ],
  scavenger: [
    { word: 'scavenge', word_class: 'V' },
    { word: 'scavenger', word_class: 'N' },
    { word: 'scavenging', word_class: 'AJ' }
  ],
  answerer: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  latitude: [
    { word: 'latitude', word_class: 'N' },
    { word: 'latitudinal', word_class: 'AJ' },
    { word: 'latitudinary', word_class: 'AJ' }
  ],
  pustule: [
    { word: 'pustule', word_class: 'N' },
    { word: 'pustulate', word_class: 'V' },
    { word: 'pustulate', word_class: 'AJ' },
    { word: 'pustulation', word_class: 'N' }
  ],
  expedite: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  broach: [
    { word: 'breach', word_class: 'N' },
    { word: 'breach', word_class: 'V' },
    { word: 'broach', word_class: 'N' },
    { word: 'broach', word_class: 'V' },
    { word: 'breached', word_class: 'AJ' },
    { word: 'broached', word_class: 'AJ' }
  ],
  covering: [
    { word: 'cover', word_class: 'N' },
    { word: 'cover', word_class: 'V' },
    { word: 'covered', word_class: 'AJ' },
    { word: 'covering', word_class: 'N' },
    { word: 'covering', word_class: 'AJ' }
  ],
  stockpile: [
    { word: 'stockpile', word_class: 'N' },
    { word: 'stockpile', word_class: 'V' },
    { word: 'stockpiling', word_class: 'N' }
  ],
  hydrolyzable: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  utilisation: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  haze: [
    { word: 'haze', word_class: 'N' },
    { word: 'haze', word_class: 'V' },
    { word: 'hazy', word_class: 'AJ' },
    { word: 'haziness', word_class: 'N' }
  ],
  shroud: [
    { word: 'shroud', word_class: 'N' },
    { word: 'shroud', word_class: 'V' },
    { word: 'shrouding', word_class: 'AJ' }
  ],
  crooner: [
    { word: 'croon', word_class: 'N' },
    { word: 'croon', word_class: 'V' },
    { word: 'crooner', word_class: 'N' },
    { word: 'crooning', word_class: 'N' }
  ],
  wading: [{ word: 'wade', word_class: 'V' }, { word: 'wader', word_class: 'N' }, { word: 'wading', word_class: 'N' }],
  timed: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  inventive: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  satisfiable: [
    { word: 'satisfy', word_class: 'V' },
    { word: 'satisfied', word_class: 'AJ' },
    { word: 'satisfying', word_class: 'N' },
    { word: 'satisfying', word_class: 'AJ' },
    { word: 'satisfaction', word_class: 'N' },
    { word: 'satisfiable', word_class: 'AJ' }
  ],
  quantification: [
    { word: 'quantify', word_class: 'V' },
    { word: 'quantifier', word_class: 'N' },
    { word: 'quantifiable', word_class: 'AJ' },
    { word: 'quantification', word_class: 'N' },
    { word: 'quantifiability', word_class: 'N' }
  ],
  growth: [
    { word: 'grow', word_class: 'V' },
    { word: 'grower', word_class: 'N' },
    { word: 'grown', word_class: 'AJ' },
    { word: 'growth', word_class: 'N' },
    { word: 'growing', word_class: 'N' },
    { word: 'growing', word_class: 'AJ' }
  ],
  awoken: [
    { word: 'awake', word_class: 'V' },
    { word: 'awake', word_class: 'AJ' },
    { word: 'awoken', word_class: 'AJ' }
  ],
  acclaim: [
    { word: 'acclaim', word_class: 'N' },
    { word: 'acclaim', word_class: 'V' },
    { word: 'acclamation', word_class: 'N' }
  ],
  hesitating: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  softheartedness: [
    { word: 'softhearted', word_class: 'AJ' },
    { word: 'softheartedness', word_class: 'N' },
    { word: 'softheartedness', word_class: 'AJ' }
  ],
  dissident: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  indefiniteness: [
    { word: 'indefinite', word_class: 'AJ' },
    { word: 'indefinitely', word_class: 'AV' },
    { word: 'indefiniteness', word_class: 'N' }
  ],
  mutate: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  prying: [
    { word: 'pry', word_class: 'N' },
    { word: 'pry', word_class: 'V' },
    { word: 'prior', word_class: 'N' },
    { word: 'prior', word_class: 'AJ' },
    { word: 'prying', word_class: 'N' },
    { word: 'prying', word_class: 'AJ' }
  ],
  patronise: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  polychrome: [
    { word: 'polychrome', word_class: 'V' },
    { word: 'polychrome', word_class: 'AJ' },
    { word: 'polychromic', word_class: 'AJ' },
    { word: 'polychromize', word_class: 'V' }
  ],
  eastward: [
    { word: 'eastward', word_class: 'AJ' },
    { word: 'eastward', word_class: 'AV' },
    { word: 'eastwards', word_class: 'AV' }
  ],
  claw: [{ word: 'claw', word_class: 'N' }, { word: 'claw', word_class: 'V' }, { word: 'clawed', word_class: 'AJ' }],
  fieriness: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  bleed: [
    { word: 'bleed', word_class: 'V' },
    { word: 'bleeder', word_class: 'N' },
    { word: 'bleeding', word_class: 'N' },
    { word: 'bleeding', word_class: 'AJ' }
  ],
  burp: [{ word: 'burp', word_class: 'N' }, { word: 'burp', word_class: 'V' }, { word: 'burping', word_class: 'N' }],
  cuffs: [{ word: 'cuff', word_class: 'N' }, { word: 'cuff', word_class: 'V' }, { word: 'cuffs', word_class: 'N' }],
  overload: [
    { word: 'overload', word_class: 'N' },
    { word: 'overload', word_class: 'V' },
    { word: 'overloaded', word_class: 'AJ' }
  ],
  astronomer: [
    { word: 'astronomy', word_class: 'N' },
    { word: 'astronomer', word_class: 'N' },
    { word: 'astronomic', word_class: 'AJ' },
    { word: 'astronomical', word_class: 'AJ' },
    { word: 'astronomically', word_class: 'AV' }
  ],
  rendition: [
    { word: 'render', word_class: 'N' },
    { word: 'render', word_class: 'V' },
    { word: 'rendering', word_class: 'N' },
    { word: 'rendition', word_class: 'N' }
  ],
  signalization: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  merged: [
    { word: 'merge', word_class: 'V' },
    { word: 'merger', word_class: 'N' },
    { word: 'merged', word_class: 'AJ' },
    { word: 'merging', word_class: 'N' },
    { word: 'mergence', word_class: 'N' },
    { word: 'merging', word_class: 'AJ' }
  ],
  mispronounce: [
    { word: 'mispronounce', word_class: 'V' },
    { word: 'mispronouncement', word_class: 'N' },
    { word: 'mispronunciation', word_class: 'N' }
  ],
  perfidiousness: [
    { word: 'perfidy', word_class: 'N' },
    { word: 'perfidious', word_class: 'AJ' },
    { word: 'perfidiously', word_class: 'AV' },
    { word: 'perfidiousness', word_class: 'N' }
  ],
  perniciousness: [
    { word: 'pernicious', word_class: 'AJ' },
    { word: 'perniciously', word_class: 'AV' },
    { word: 'perniciousness', word_class: 'N' }
  ],
  calendrical: [
    { word: 'calendar', word_class: 'N' },
    { word: 'calendar', word_class: 'V' },
    { word: 'calendric', word_class: 'AJ' },
    { word: 'calendrical', word_class: 'AJ' }
  ],
  rolled: [
    { word: 'roll', word_class: 'N' },
    { word: 'roll', word_class: 'V' },
    { word: 'roller', word_class: 'N' },
    { word: 'rolled', word_class: 'AJ' },
    { word: 'rolling', word_class: 'N' },
    { word: 'rolling', word_class: 'AJ' }
  ],
  italicization: [
    { word: 'italicise', word_class: 'V' },
    { word: 'italicize', word_class: 'V' },
    { word: 'italicisation', word_class: 'N' },
    { word: 'italicization', word_class: 'N' }
  ],
  intemperately: [
    { word: 'intemperance', word_class: 'N' },
    { word: 'intemperate', word_class: 'AJ' },
    { word: 'intemperately', word_class: 'AV' },
    { word: 'intemperateness', word_class: 'N' }
  ],
  reparation: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  prediction: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  tobogganing: [
    { word: 'toboggan', word_class: 'N' },
    { word: 'toboggan', word_class: 'V' },
    { word: 'tobogganing', word_class: 'N' },
    { word: 'tobogganist', word_class: 'N' }
  ],
  drip: [
    { word: 'drip', word_class: 'N' },
    { word: 'drip', word_class: 'V' },
    { word: 'dripping', word_class: 'N' },
    { word: 'dripping', word_class: 'AJ' },
    { word: 'dripping', word_class: 'AV' },
    { word: 'drippings', word_class: 'N' }
  ],
  hypersensitized: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  adequacy: [
    { word: 'adequacy', word_class: 'N' },
    { word: 'adequate', word_class: 'AJ' },
    { word: 'adequately', word_class: 'AV' },
    { word: 'adequateness', word_class: 'N' }
  ],
  operator: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  memorial: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  marginal: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  preference: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  diagramming: [
    { word: 'diagram', word_class: 'N' },
    { word: 'diagram', word_class: 'V' },
    { word: 'diagramming', word_class: 'N' },
    { word: 'diagrammatic', word_class: 'AJ' },
    { word: 'diagrammatical', word_class: 'AJ' },
    { word: 'diagrammatically', word_class: 'AV' }
  ],
  naively: [
    { word: 'naive', word_class: 'AJ' },
    { word: 'naivety', word_class: 'N' },
    { word: 'naively', word_class: 'AV' },
    { word: 'naiveness', word_class: 'N' }
  ],
  fascinated: [
    { word: 'fascinate', word_class: 'V' },
    { word: 'fascinated', word_class: 'AJ' },
    { word: 'fascination', word_class: 'N' },
    { word: 'fascinating', word_class: 'AJ' }
  ],
  macroscopically: [
    { word: 'macroscopic', word_class: 'AJ' },
    { word: 'macroscopical', word_class: 'AJ' },
    { word: 'macroscopically', word_class: 'AV' }
  ],
  inflammation: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  slime: [
    { word: 'slime', word_class: 'N' },
    { word: 'slime', word_class: 'V' },
    { word: 'slimy', word_class: 'AJ' },
    { word: 'slimed', word_class: 'AJ' },
    { word: 'sliminess', word_class: 'N' }
  ],
  periodontitis: [
    { word: 'periodontal', word_class: 'AJ' },
    { word: 'periodontic', word_class: 'AJ' },
    { word: 'periodontics', word_class: 'N' },
    { word: 'periodontitis', word_class: 'N' }
  ],
  evade: [
    { word: 'evade', word_class: 'V' },
    { word: 'evasion', word_class: 'N' },
    { word: 'evasive', word_class: 'AJ' },
    { word: 'evasively', word_class: 'AV' },
    { word: 'evasiveness', word_class: 'N' }
  ],
  juggler: [
    { word: 'juggle', word_class: 'N' },
    { word: 'juggle', word_class: 'V' },
    { word: 'juggler', word_class: 'N' },
    { word: 'jugglery', word_class: 'N' },
    { word: 'juggling', word_class: 'N' }
  ],
  snatcher: [
    { word: 'snatch', word_class: 'N' },
    { word: 'snatch', word_class: 'V' },
    { word: 'snatcher', word_class: 'N' }
  ],
  inquisitiveness: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  psychological: [
    { word: 'psychology', word_class: 'N' },
    { word: 'psychologize', word_class: 'V' },
    { word: 'psychological', word_class: 'AJ' },
    { word: 'psychologically', word_class: 'AV' },
    { word: 'psychologization', word_class: 'N' }
  ],
  related: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  diplomatical: [
    { word: 'diplomat', word_class: 'N' },
    { word: 'diplomatic', word_class: 'AJ' },
    { word: 'diplomatical', word_class: 'AJ' },
    { word: 'diplomatically', word_class: 'AV' }
  ],
  expiring: [
    { word: 'expire', word_class: 'V' },
    { word: 'expiry', word_class: 'N' },
    { word: 'expired', word_class: 'AJ' },
    { word: 'expiring', word_class: 'AJ' },
    { word: 'expiration', word_class: 'N' }
  ],
  'ill-treat': [
    { word: 'ill-treat', word_class: 'V' },
    { word: 'ill-treated', word_class: 'AJ' },
    { word: 'ill-treatment', word_class: 'N' }
  ],
  panel: [
    { word: 'panel', word_class: 'N' },
    { word: 'panel', word_class: 'V' },
    { word: 'paneling', word_class: 'N' },
    { word: 'panelist', word_class: 'N' },
    { word: 'paneled', word_class: 'AJ' },
    { word: 'panelling', word_class: 'N' }
  ],
  polytonality: [
    { word: 'polytonal', word_class: 'AJ' },
    { word: 'polytonalism', word_class: 'N' },
    { word: 'polytonality', word_class: 'N' }
  ],
  mull: [
    { word: 'mull', word_class: 'N' },
    { word: 'mull', word_class: 'V' },
    { word: 'mullion', word_class: 'N' },
    { word: 'mullioned', word_class: 'AJ' }
  ],
  disburse: [
    { word: 'disburse', word_class: 'V' },
    { word: 'disbursal', word_class: 'N' },
    { word: 'disburser', word_class: 'N' },
    { word: 'disbursement', word_class: 'N' }
  ],
  nerve: [
    { word: 'nerve', word_class: 'N' },
    { word: 'nerve', word_class: 'V' },
    { word: 'nerves', word_class: 'N' },
    { word: 'nervy', word_class: 'AJ' }
  ],
  mixing: [
    { word: 'mix', word_class: 'N' },
    { word: 'mix', word_class: 'V' },
    { word: 'mixer', word_class: 'N' },
    { word: 'mixed', word_class: 'AJ' },
    { word: 'mixing', word_class: 'N' },
    { word: 'mixture', word_class: 'N' }
  ],
  vexing: [
    { word: 'vex', word_class: 'V' },
    { word: 'vexed', word_class: 'AJ' },
    { word: 'vexing', word_class: 'AJ' },
    { word: 'vexation', word_class: 'N' },
    { word: 'vexatious', word_class: 'AJ' }
  ],
  expected: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  remonetise: [
    { word: 'remonetise', word_class: 'V' },
    { word: 'remonetize', word_class: 'V' },
    { word: 'remonetisation', word_class: 'N' },
    { word: 'remonetization', word_class: 'N' }
  ],
  chain: [
    { word: 'chain', word_class: 'N' },
    { word: 'chain', word_class: 'V' },
    { word: 'chains', word_class: 'N' },
    { word: 'chained', word_class: 'AJ' }
  ],
  mutational: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  transporter: [
    { word: 'transport', word_class: 'N' },
    { word: 'transport', word_class: 'V' },
    { word: 'transporter', word_class: 'N' },
    { word: 'transportable', word_class: 'AJ' },
    { word: 'transportation', word_class: 'N' }
  ],
  spongy: [
    { word: 'sponge', word_class: 'N' },
    { word: 'sponge', word_class: 'V' },
    { word: 'sponger', word_class: 'N' },
    { word: 'spongy', word_class: 'AJ' },
    { word: 'sponginess', word_class: 'N' }
  ],
  absolver: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  discard: [
    { word: 'discard', word_class: 'N' },
    { word: 'discard', word_class: 'V' },
    { word: 'discarded', word_class: 'AJ' },
    { word: 'discarding', word_class: 'N' }
  ],
  muddied: [
    { word: 'mud', word_class: 'N' },
    { word: 'mud', word_class: 'V' },
    { word: 'muddy', word_class: 'V' },
    { word: 'muddy', word_class: 'AJ' },
    { word: 'muddied', word_class: 'AJ' },
    { word: 'muddiness', word_class: 'N' }
  ],
  vowelize: [
    { word: 'vowel', word_class: 'N' },
    { word: 'vowelise', word_class: 'V' },
    { word: 'vowelize', word_class: 'V' },
    { word: 'vowelisation', word_class: 'N' },
    { word: 'vowelization', word_class: 'N' }
  ],
  formal: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  vying: [{ word: 'vie', word_class: 'V' }, { word: 'vial', word_class: 'N' }, { word: 'vying', word_class: 'V' }],
  inexpensiveness: [
    { word: 'inexpensive', word_class: 'AJ' },
    { word: 'inexpensively', word_class: 'AV' },
    { word: 'inexpensiveness', word_class: 'N' }
  ],
  kennels: [
    { word: 'kennel', word_class: 'N' },
    { word: 'kennel', word_class: 'V' },
    { word: 'kennels', word_class: 'N' }
  ],
  pleasantness: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  agrologic: [
    { word: 'agrology', word_class: 'N' },
    { word: 'agrologic', word_class: 'AJ' },
    { word: 'agrological', word_class: 'AJ' }
  ],
  fascinating: [
    { word: 'fascinate', word_class: 'V' },
    { word: 'fascinated', word_class: 'AJ' },
    { word: 'fascination', word_class: 'N' },
    { word: 'fascinating', word_class: 'AJ' }
  ],
  utopianism: [
    { word: 'utopia', word_class: 'N' },
    { word: 'utopian', word_class: 'N' },
    { word: 'utopian', word_class: 'AJ' },
    { word: 'utopianism', word_class: 'N' }
  ],
  enter: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  safely: [
    { word: 'safe', word_class: 'N' },
    { word: 'safe', word_class: 'AJ' },
    { word: 'safety', word_class: 'N' },
    { word: 'safely', word_class: 'AV' },
    { word: 'safeness', word_class: 'N' }
  ],
  therapeutic: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  sensual: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  seething: [
    { word: 'seethe', word_class: 'N' },
    { word: 'seethe', word_class: 'V' },
    { word: 'seething', word_class: 'AJ' }
  ],
  obstetric: [
    { word: 'obstetric', word_class: 'AJ' },
    { word: 'obstetrics', word_class: 'N' },
    { word: 'obstetrical', word_class: 'AJ' }
  ],
  close: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  bootlicker: [
    { word: 'bootlick', word_class: 'V' },
    { word: 'bootlicker', word_class: 'N' },
    { word: 'bootlicking', word_class: 'AJ' }
  ],
  manipulability: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  overloaded: [
    { word: 'overload', word_class: 'N' },
    { word: 'overload', word_class: 'V' },
    { word: 'overloaded', word_class: 'AJ' }
  ],
  swim: [
    { word: 'swim', word_class: 'N' },
    { word: 'swim', word_class: 'V' },
    { word: 'swimming', word_class: 'N' },
    { word: 'swimming', word_class: 'AJ' }
  ],
  scavenge: [
    { word: 'scavenge', word_class: 'V' },
    { word: 'scavenger', word_class: 'N' },
    { word: 'scavenging', word_class: 'AJ' }
  ],
  autochthonic: [
    { word: 'autochthonal', word_class: 'AJ' },
    { word: 'autochthonic', word_class: 'AJ' },
    { word: 'autochthonous', word_class: 'AJ' }
  ],
  skirmish: [
    { word: 'skirmish', word_class: 'N' },
    { word: 'skirmish', word_class: 'V' },
    { word: 'skirmisher', word_class: 'N' }
  ],
  lecherousness: [
    { word: 'lech', word_class: 'V' },
    { word: 'lecher', word_class: 'N' },
    { word: 'lecherous', word_class: 'AJ' },
    { word: 'lecherousness', word_class: 'N' }
  ],
  become: [
    { word: 'become', word_class: 'V' },
    { word: 'become', word_class: 'AJ' },
    { word: 'becoming', word_class: 'AJ' }
  ],
  strengthen: [
    { word: 'strong', word_class: 'AJ' },
    { word: 'strengthen', word_class: 'V' },
    { word: 'strengthener', word_class: 'N' },
    { word: 'strengthened', word_class: 'AJ' },
    { word: 'strengthening', word_class: 'N' },
    { word: 'strengthening', word_class: 'AJ' }
  ],
  intimate: [
    { word: 'intimacy', word_class: 'N' },
    { word: 'intimate', word_class: 'N' },
    { word: 'intimal', word_class: 'AJ' },
    { word: 'intimate', word_class: 'V' },
    { word: 'intimate', word_class: 'AJ' },
    { word: 'intimation', word_class: 'N' },
    { word: 'intimately', word_class: 'AV' }
  ],
  prorogation: [
    { word: 'prorogue', word_class: 'N' },
    { word: 'prorogue', word_class: 'V' },
    { word: 'prorogation', word_class: 'N' }
  ],
  annexational: [
    { word: 'annex', word_class: 'N' },
    { word: 'annex', word_class: 'V' },
    { word: 'annexe', word_class: 'N' },
    { word: 'annexal', word_class: 'AJ' },
    { word: 'annexation', word_class: 'N' },
    { word: 'annexational', word_class: 'AJ' }
  ],
  bared: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  obscenely: [
    { word: 'obscene', word_class: 'AJ' },
    { word: 'obscenity', word_class: 'N' },
    { word: 'obscenely', word_class: 'AV' }
  ],
  plumbing: [
    { word: 'plumb', word_class: 'N' },
    { word: 'plumb', word_class: 'V' },
    { word: 'plumb', word_class: 'AJ' },
    { word: 'plumb', word_class: 'AV' },
    { word: 'plumber', word_class: 'N' },
    { word: 'plumbed', word_class: 'AJ' },
    { word: 'plumbing', word_class: 'N' },
    { word: 'plumbism', word_class: 'N' }
  ],
  superficial: [
    { word: 'superficies', word_class: 'N' },
    { word: 'superficial', word_class: 'AJ' },
    { word: 'superficiality', word_class: 'N' },
    { word: 'superficially', word_class: 'AV' }
  ],
  attentive: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  billings: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  missioner: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  sympathetically: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  glory: [
    { word: 'glory', word_class: 'N' },
    { word: 'glory', word_class: 'V' },
    { word: 'glorify', word_class: 'V' },
    { word: 'glorified', word_class: 'AJ' },
    { word: 'glorification', word_class: 'N' }
  ],
  hum: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  deflective: [
    { word: 'deflect', word_class: 'V' },
    { word: 'deflector', word_class: 'N' },
    { word: 'deflection', word_class: 'N' },
    { word: 'deflective', word_class: 'AJ' }
  ],
  luminousness: [
    { word: 'luminal', word_class: 'N' },
    { word: 'luminance', word_class: 'N' },
    { word: 'luminous', word_class: 'AJ' },
    { word: 'luminousness', word_class: 'N' }
  ],
  accumulated: [
    { word: 'accumulate', word_class: 'V' },
    { word: 'accumulator', word_class: 'N' },
    { word: 'accumulated', word_class: 'AJ' },
    { word: 'accumulation', word_class: 'N' },
    { word: 'accumulative', word_class: 'AJ' }
  ],
  win: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  leach: [
    { word: 'leach', word_class: 'N' },
    { word: 'leach', word_class: 'V' },
    { word: 'leaching', word_class: 'N' }
  ],
  momentousness: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  substantiation: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  stray: [
    { word: 'stray', word_class: 'N' },
    { word: 'stray', word_class: 'V' },
    { word: 'stray', word_class: 'AJ' },
    { word: 'straying', word_class: 'AJ' }
  ],
  loop: [
    { word: 'loop', word_class: 'N' },
    { word: 'loop', word_class: 'V' },
    { word: 'looper', word_class: 'N' },
    { word: 'looped', word_class: 'AJ' }
  ],
  bother: [
    { word: 'bother', word_class: 'N' },
    { word: 'bother', word_class: 'V' },
    { word: 'bothered', word_class: 'AJ' },
    { word: 'botheration', word_class: 'N' },
    { word: 'bothersome', word_class: 'AJ' }
  ],
  examinee: [
    { word: 'examine', word_class: 'N' },
    { word: 'examine', word_class: 'V' },
    { word: 'examinee', word_class: 'N' },
    { word: 'examiner', word_class: 'N' },
    { word: 'examination', word_class: 'N' }
  ],
  successive: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  wiggliness: [
    { word: 'wiggle', word_class: 'N' },
    { word: 'wiggle', word_class: 'V' },
    { word: 'wiggler', word_class: 'N' },
    { word: 'wiggly', word_class: 'AJ' },
    { word: 'wiggling', word_class: 'AJ' },
    { word: 'wiggliness', word_class: 'N' }
  ],
  tier: [
    { word: 'tie', word_class: 'N' },
    { word: 'tie', word_class: 'V' },
    { word: 'tier', word_class: 'N' },
    { word: 'tying', word_class: 'N' },
    { word: 'tying', word_class: 'V' }
  ],
  retracted: [
    { word: 'retract', word_class: 'N' },
    { word: 'retract', word_class: 'V' },
    { word: 'retractor', word_class: 'N' },
    { word: 'retracted', word_class: 'AJ' },
    { word: 'retraction', word_class: 'N' },
    { word: 'retractable', word_class: 'AJ' }
  ],
  caffeine: [
    { word: 'caffein', word_class: 'N' },
    { word: 'caffeine', word_class: 'N' },
    { word: 'caffeinic', word_class: 'AJ' },
    { word: 'caffeinism', word_class: 'N' }
  ],
  yeller: [
    { word: 'yell', word_class: 'N' },
    { word: 'yell', word_class: 'V' },
    { word: 'yeller', word_class: 'N' },
    { word: 'yelled', word_class: 'AJ' },
    { word: 'yelling', word_class: 'N' },
    { word: 'yelling', word_class: 'AJ' }
  ],
  outraged: [
    { word: 'outrage', word_class: 'N' },
    { word: 'outrage', word_class: 'V' },
    { word: 'outraged', word_class: 'AJ' },
    { word: 'outrageous', word_class: 'AJ' },
    { word: 'outrageously', word_class: 'AV' },
    { word: 'outrageousness', word_class: 'N' }
  ],
  salute: [
    { word: 'salute', word_class: 'N' },
    { word: 'salute', word_class: 'V' },
    { word: 'salutation', word_class: 'N' }
  ],
  autogenics: [
    { word: 'autogenic', word_class: 'AJ' },
    { word: 'autogenics', word_class: 'N' },
    { word: 'autogenous', word_class: 'AJ' }
  ],
  lounger: [
    { word: 'lounge', word_class: 'N' },
    { word: 'lounge', word_class: 'V' },
    { word: 'lounger', word_class: 'N' },
    { word: 'lounging', word_class: 'AJ' }
  ],
  secretariate: [
    { word: 'secretary', word_class: 'N' },
    { word: 'secretarial', word_class: 'AJ' },
    { word: 'secretariate', word_class: 'N' }
  ],
  chinked: [
    { word: 'chink', word_class: 'N' },
    { word: 'chink', word_class: 'V' },
    { word: 'chinked', word_class: 'AJ' }
  ],
  vaulted: [
    { word: 'vault', word_class: 'N' },
    { word: 'vault', word_class: 'V' },
    { word: 'vaulter', word_class: 'N' },
    { word: 'vaulted', word_class: 'AJ' },
    { word: 'vaulting', word_class: 'N' },
    { word: 'vaulting', word_class: 'AJ' }
  ],
  concert: [
    { word: 'concert', word_class: 'N' },
    { word: 'concert', word_class: 'V' },
    { word: 'concerted', word_class: 'AJ' },
    { word: 'concertize', word_class: 'V' }
  ],
  equipped: [
    { word: 'equip', word_class: 'V' },
    { word: 'equipment', word_class: 'N' },
    { word: 'equipped', word_class: 'AJ' },
    { word: 'equipping', word_class: 'N' }
  ],
  bicycling: [
    { word: 'bicycle', word_class: 'N' },
    { word: 'bicycle', word_class: 'V' },
    { word: 'bicyclic', word_class: 'AJ' },
    { word: 'bicycling', word_class: 'N' },
    { word: 'bicyclist', word_class: 'N' }
  ],
  subjugate: [
    { word: 'subjugate', word_class: 'V' },
    { word: 'subjugate', word_class: 'AJ' },
    { word: 'subjugable', word_class: 'AJ' },
    { word: 'subjugated', word_class: 'AJ' },
    { word: 'subjugation', word_class: 'N' }
  ],
  map: [{ word: 'map', word_class: 'N' }, { word: 'map', word_class: 'V' }, { word: 'mapping', word_class: 'N' }],
  mystic: [
    { word: 'mystic', word_class: 'N' },
    { word: 'mystic', word_class: 'AJ' },
    { word: 'mystical', word_class: 'AJ' },
    { word: 'mysticism', word_class: 'N' },
    { word: 'mystically', word_class: 'AV' }
  ],
  aloofness: [
    { word: 'aloof', word_class: 'AJ' },
    { word: 'aloof', word_class: 'AV' },
    { word: 'aloofness', word_class: 'N' }
  ],
  ski: [
    { word: 'ski', word_class: 'N' },
    { word: 'ski', word_class: 'V' },
    { word: 'skis', word_class: 'N' },
    { word: 'skier', word_class: 'N' },
    { word: 'skiing', word_class: 'N' }
  ],
  programmer: [
    { word: 'programme', word_class: 'N' },
    { word: 'programme', word_class: 'V' },
    { word: 'programmer', word_class: 'N' }
  ],
  flaming: [
    { word: 'flame', word_class: 'N' },
    { word: 'flame', word_class: 'V' },
    { word: 'flaming', word_class: 'N' },
    { word: 'flaming', word_class: 'AJ' },
    { word: 'flammable', word_class: 'AJ' },
    { word: 'flammability', word_class: 'N' },
    { word: 'flammability', word_class: 'AJ' }
  ],
  injuriously: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  fanatically: [
    { word: 'fanatic', word_class: 'N' },
    { word: 'fanatic', word_class: 'AJ' },
    { word: 'fanatical', word_class: 'AJ' },
    { word: 'fanatically', word_class: 'AV' }
  ],
  infantilism: [
    { word: 'infant', word_class: 'N' },
    { word: 'infancy', word_class: 'N' },
    { word: 'infancy', word_class: 'V' },
    { word: 'infant', word_class: 'AJ' },
    { word: 'infancy', word_class: 'AJ' },
    { word: 'infantile', word_class: 'AJ' },
    { word: 'infantilism', word_class: 'N' }
  ],
  implicated: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  healthful: [
    { word: 'health', word_class: 'N' },
    { word: 'healthful', word_class: 'AJ' },
    { word: 'healthfulness', word_class: 'N' }
  ],
  osculate: [
    { word: 'osculate', word_class: 'V' },
    { word: 'osculator', word_class: 'N' },
    { word: 'osculation', word_class: 'N' }
  ],
  hazard: [
    { word: 'hazard', word_class: 'N' },
    { word: 'hazard', word_class: 'V' },
    { word: 'hazarding', word_class: 'N' },
    { word: 'hazardous', word_class: 'AJ' },
    { word: 'hazardously', word_class: 'AV' },
    { word: 'hazardousness', word_class: 'N' }
  ],
  transplant: [
    { word: 'transplant', word_class: 'N' },
    { word: 'transplant', word_class: 'V' },
    { word: 'transplanting', word_class: 'N' },
    { word: 'transplantable', word_class: 'AJ' },
    { word: 'transplantation', word_class: 'N' }
  ],
  nativist: [
    { word: 'nativist', word_class: 'N' },
    { word: 'nativist', word_class: 'AJ' },
    { word: 'nativistic', word_class: 'AJ' }
  ],
  contused: [
    { word: 'contuse', word_class: 'V' },
    { word: 'contused', word_class: 'AJ' },
    { word: 'contusion', word_class: 'N' },
    { word: 'contusioned', word_class: 'AJ' }
  ],
  illumination: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  speller: [
    { word: 'spell', word_class: 'N' },
    { word: 'spell', word_class: 'V' },
    { word: 'speller', word_class: 'N' },
    { word: 'spelling', word_class: 'N' }
  ],
  nominative: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  querulousness: [
    { word: 'querulous', word_class: 'AJ' },
    { word: 'querulously', word_class: 'AV' },
    { word: 'querulousness', word_class: 'N' }
  ],
  carunculous: [
    { word: 'carunculate', word_class: 'AJ' },
    { word: 'carunculous', word_class: 'AJ' },
    { word: 'carunculated', word_class: 'AJ' }
  ],
  remindful: [
    { word: 'remind', word_class: 'N' },
    { word: 'remind', word_class: 'V' },
    { word: 'reminder', word_class: 'N' },
    { word: 'remindful', word_class: 'AJ' }
  ],
  easiness: [
    { word: 'easy', word_class: 'AJ' },
    { word: 'easy', word_class: 'AV' },
    { word: 'easiness', word_class: 'N' }
  ],
  accidental: [
    { word: 'accidental', word_class: 'N' },
    { word: 'accidental', word_class: 'AJ' },
    { word: 'accidentally', word_class: 'AV' }
  ],
  heralded: [
    { word: 'herald', word_class: 'N' },
    { word: 'herald', word_class: 'V' },
    { word: 'heralded', word_class: 'AJ' },
    { word: 'heraldic', word_class: 'AJ' }
  ],
  amicableness: [
    { word: 'amicable', word_class: 'AJ' },
    { word: 'amicably', word_class: 'AV' },
    { word: 'amicability', word_class: 'N' },
    { word: 'amicableness', word_class: 'N' }
  ],
  disburser: [
    { word: 'disburse', word_class: 'V' },
    { word: 'disbursal', word_class: 'N' },
    { word: 'disburser', word_class: 'N' },
    { word: 'disbursement', word_class: 'N' }
  ],
  commensal: [
    { word: 'commensal', word_class: 'N' },
    { word: 'commensal', word_class: 'AJ' },
    { word: 'commensalism', word_class: 'N' },
    { word: 'commensally', word_class: 'AV' }
  ],
  elusive: [
    { word: 'elude', word_class: 'V' },
    { word: 'eluding', word_class: 'N' },
    { word: 'elusion', word_class: 'N' },
    { word: 'elusive', word_class: 'AJ' },
    { word: 'elusiveness', word_class: 'N' }
  ],
  methodism: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  prohibition: [
    { word: 'prohibit', word_class: 'V' },
    { word: 'prohibition', word_class: 'N' },
    { word: 'prohibited', word_class: 'AJ' },
    { word: 'prohibitive', word_class: 'AJ' },
    { word: 'prohibitively', word_class: 'AV' }
  ],
  pollinator: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  meddling: [
    { word: 'meddle', word_class: 'V' },
    { word: 'meddler', word_class: 'N' },
    { word: 'meddling', word_class: 'N' },
    { word: 'meddling', word_class: 'AJ' },
    { word: 'meddlesome', word_class: 'AJ' },
    { word: 'meddlesomeness', word_class: 'N' }
  ],
  lisper: [{ word: 'lisp', word_class: 'N' }, { word: 'lisp', word_class: 'V' }, { word: 'lisper', word_class: 'N' }],
  vestment: [
    { word: 'vest', word_class: 'N' },
    { word: 'vest', word_class: 'V' },
    { word: 'vesta', word_class: 'N' },
    { word: 'vestal', word_class: 'N' },
    { word: 'vestal', word_class: 'AJ' },
    { word: 'vested', word_class: 'AJ' },
    { word: 'vestment', word_class: 'N' },
    { word: 'vestmental', word_class: 'AJ' },
    { word: 'vestmented', word_class: 'AJ' }
  ],
  unexciting: [
    { word: 'unexcited', word_class: 'AJ' },
    { word: 'unexciting', word_class: 'AJ' },
    { word: 'unexcitable', word_class: 'AJ' }
  ],
  defector: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  finalize: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  varying: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  sparkler: [
    { word: 'sparkle', word_class: 'N' },
    { word: 'sparkle', word_class: 'V' },
    { word: 'sparkler', word_class: 'N' },
    { word: 'sparkling', word_class: 'N' },
    { word: 'sparkling', word_class: 'AJ' }
  ],
  bulwarks: [
    { word: 'bulwark', word_class: 'N' },
    { word: 'bulwark', word_class: 'V' },
    { word: 'bulwarks', word_class: 'N' }
  ],
  preponderate: [
    { word: 'preponderant', word_class: 'N' },
    { word: 'preponderate', word_class: 'V' },
    { word: 'preponderance', word_class: 'N' },
    { word: 'preponderant', word_class: 'AJ' },
    { word: 'preponderation', word_class: 'N' },
    { word: 'preponderating', word_class: 'AJ' }
  ],
  astronomically: [
    { word: 'astronomy', word_class: 'N' },
    { word: 'astronomer', word_class: 'N' },
    { word: 'astronomic', word_class: 'AJ' },
    { word: 'astronomical', word_class: 'AJ' },
    { word: 'astronomically', word_class: 'AV' }
  ],
  turbulent: [
    { word: 'turbulence', word_class: 'N' },
    { word: 'turbulency', word_class: 'N' },
    { word: 'turbulent', word_class: 'AJ' },
    { word: 'turbulently', word_class: 'AV' }
  ],
  wrestle: [
    { word: 'wrestle', word_class: 'N' },
    { word: 'wrestle', word_class: 'V' },
    { word: 'wrestler', word_class: 'N' },
    { word: 'wrestling', word_class: 'N' }
  ],
  incline: [
    { word: 'incline', word_class: 'N' },
    { word: 'incline', word_class: 'V' },
    { word: 'inclined', word_class: 'AJ' },
    { word: 'inclining', word_class: 'N' },
    { word: 'inclination', word_class: 'N' }
  ],
  magically: [
    { word: 'magic', word_class: 'N' },
    { word: 'magic', word_class: 'AJ' },
    { word: 'magical', word_class: 'AJ' },
    { word: 'magically', word_class: 'AV' }
  ],
  refuting: [
    { word: 'refute', word_class: 'V' },
    { word: 'refuting', word_class: 'N' },
    { word: 'refutable', word_class: 'AJ' },
    { word: 'refutation', word_class: 'N' }
  ],
  synchronously: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  edger: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  uniformize: [
    { word: 'uniform', word_class: 'N' },
    { word: 'uniform', word_class: 'V' },
    { word: 'uniform', word_class: 'AJ' },
    { word: 'uniformed', word_class: 'AJ' },
    { word: 'uniformity', word_class: 'N' },
    { word: 'uniformize', word_class: 'V' },
    { word: 'uniformness', word_class: 'N' }
  ],
  pretense: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  glide: [
    { word: 'glide', word_class: 'N' },
    { word: 'glide', word_class: 'V' },
    { word: 'glider', word_class: 'N' },
    { word: 'gliding', word_class: 'N' }
  ],
  prosperously: [
    { word: 'prosper', word_class: 'V' },
    { word: 'prosperity', word_class: 'N' },
    { word: 'prospering', word_class: 'AJ' },
    { word: 'prosperous', word_class: 'AJ' },
    { word: 'prosperously', word_class: 'AV' }
  ],
  submersion: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  internally: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  quick: [
    { word: 'quick', word_class: 'N' },
    { word: 'quick', word_class: 'AJ' },
    { word: 'quick', word_class: 'AV' },
    { word: 'quickness', word_class: 'N' }
  ],
  benny: [{ word: 'benni', word_class: 'N' }, { word: 'benny', word_class: 'N' }, { word: 'bennie', word_class: 'N' }],
  fawning: [
    { word: 'fawn', word_class: 'N' },
    { word: 'fawn', word_class: 'V' },
    { word: 'fawning', word_class: 'AJ' }
  ],
  complacently: [
    { word: 'complacence', word_class: 'N' },
    { word: 'complacency', word_class: 'N' },
    { word: 'complacent', word_class: 'AJ' },
    { word: 'complacently', word_class: 'AV' }
  ],
  normality: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  philosophize: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  solubility: [
    { word: 'soluble', word_class: 'AJ' },
    { word: 'solubly', word_class: 'AV' },
    { word: 'solubility', word_class: 'N' },
    { word: 'solubleness', word_class: 'N' }
  ],
  pad: [
    { word: 'pad', word_class: 'N' },
    { word: 'pad', word_class: 'V' },
    { word: 'padded', word_class: 'AJ' },
    { word: 'padding', word_class: 'N' }
  ],
  venom: [
    { word: 'venom', word_class: 'N' },
    { word: 'venomed', word_class: 'AJ' },
    { word: 'venomous', word_class: 'AJ' },
    { word: 'venomously', word_class: 'AV' }
  ],
  preponderating: [
    { word: 'preponderant', word_class: 'N' },
    { word: 'preponderate', word_class: 'V' },
    { word: 'preponderance', word_class: 'N' },
    { word: 'preponderant', word_class: 'AJ' },
    { word: 'preponderation', word_class: 'N' },
    { word: 'preponderating', word_class: 'AJ' }
  ],
  infiltrate: [
    { word: 'infiltrate', word_class: 'V' },
    { word: 'infiltrator', word_class: 'N' },
    { word: 'infiltration', word_class: 'N' }
  ],
  arbitrate: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  mistrust: [
    { word: 'mistrust', word_class: 'N' },
    { word: 'mistrust', word_class: 'V' },
    { word: 'mistrustful', word_class: 'AJ' }
  ],
  verifiable: [
    { word: 'verify', word_class: 'V' },
    { word: 'verified', word_class: 'AJ' },
    { word: 'verifying', word_class: 'AJ' },
    { word: 'verifiable', word_class: 'AJ' },
    { word: 'verification', word_class: 'N' }
  ],
  groover: [
    { word: 'groove', word_class: 'N' },
    { word: 'groove', word_class: 'V' },
    { word: 'groover', word_class: 'N' },
    { word: 'grooved', word_class: 'AJ' },
    { word: 'grooving', word_class: 'N' }
  ],
  theologization: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  vitrified: [
    { word: 'vitrify', word_class: 'V' },
    { word: 'vitrified', word_class: 'AJ' },
    { word: 'vitrification', word_class: 'N' }
  ],
  extroversive: [
    { word: 'extrovert', word_class: 'N' },
    { word: 'extrovert', word_class: 'AJ' },
    { word: 'extroversion', word_class: 'N' },
    { word: 'extroverted', word_class: 'AJ' },
    { word: 'extroversive', word_class: 'AJ' },
    { word: 'extrovertive', word_class: 'AJ' }
  ],
  anaphoric: [
    { word: 'anaphor', word_class: 'N' },
    { word: 'anaphora', word_class: 'N' },
    { word: 'anaphoric', word_class: 'AJ' }
  ],
  conditioned: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  inadvisability: [
    { word: 'inadvisable', word_class: 'AJ' },
    { word: 'inadvisably', word_class: 'AV' },
    { word: 'inadvisability', word_class: 'N' }
  ],
  player: [
    { word: 'play', word_class: 'N' },
    { word: 'play', word_class: 'V' },
    { word: 'play', word_class: 'AJ' },
    { word: 'player', word_class: 'N' },
    { word: 'played', word_class: 'AJ' },
    { word: 'playing', word_class: 'N' },
    { word: 'playing', word_class: 'AJ' }
  ],
  semantically: [
    { word: 'semantic', word_class: 'AJ' },
    { word: 'semantics', word_class: 'N' },
    { word: 'semantically', word_class: 'AV' }
  ],
  dimension: [
    { word: 'dimension', word_class: 'N' },
    { word: 'dimension', word_class: 'V' },
    { word: 'dimensioning', word_class: 'AJ' }
  ],
  aggression: [
    { word: 'aggress', word_class: 'V' },
    { word: 'aggression', word_class: 'N' },
    { word: 'aggressive', word_class: 'AJ' },
    { word: 'aggressively', word_class: 'AV' },
    { word: 'aggressiveness', word_class: 'N' }
  ],
  straits: [
    { word: 'strait', word_class: 'N' },
    { word: 'strait', word_class: 'AJ' },
    { word: 'straits', word_class: 'N' }
  ],
  storage: [
    { word: 'store', word_class: 'N' },
    { word: 'store', word_class: 'V' },
    { word: 'store', word_class: 'AJ' },
    { word: 'stores', word_class: 'N' },
    { word: 'storage', word_class: 'N' },
    { word: 'storing', word_class: 'N' }
  ],
  overcapitalise: [
    { word: 'overcapitalise', word_class: 'V' },
    { word: 'overcapitalize', word_class: 'V' },
    { word: 'overcapitalisation', word_class: 'N' },
    { word: 'overcapitalization', word_class: 'N' }
  ],
  latin: [
    { word: 'latin', word_class: 'N' },
    { word: 'latin', word_class: 'AJ' },
    { word: 'latinize', word_class: 'V' },
    { word: 'latinist', word_class: 'N' },
    { word: 'latinate', word_class: 'AJ' },
    { word: 'latinization', word_class: 'N' }
  ],
  imploring: [
    { word: 'implore', word_class: 'V' },
    { word: 'imploring', word_class: 'AJ' },
    { word: 'imploration', word_class: 'N' }
  ],
  maniacally: [
    { word: 'maniac', word_class: 'N' },
    { word: 'maniac', word_class: 'AJ' },
    { word: 'maniacal', word_class: 'AJ' },
    { word: 'maniacally', word_class: 'AV' }
  ],
  lay: [
    { word: 'lay', word_class: 'N' },
    { word: 'lay', word_class: 'V' },
    { word: 'lay', word_class: 'AJ' },
    { word: 'layer', word_class: 'N' },
    { word: 'layer', word_class: 'V' },
    { word: 'laying', word_class: 'N' },
    { word: 'layman', word_class: 'N' },
    { word: 'layered', word_class: 'AJ' }
  ],
  politically: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  parasitization: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  baleful: [
    { word: 'bale', word_class: 'N' },
    { word: 'bale', word_class: 'V' },
    { word: 'baleful', word_class: 'AJ' },
    { word: 'balefulness', word_class: 'N' }
  ],
  appealable: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  slashed: [
    { word: 'slash', word_class: 'N' },
    { word: 'slash', word_class: 'V' },
    { word: 'slashed', word_class: 'AJ' },
    { word: 'slashing', word_class: 'N' },
    { word: 'slashing', word_class: 'AJ' }
  ],
  obstetrics: [
    { word: 'obstetric', word_class: 'AJ' },
    { word: 'obstetrics', word_class: 'N' },
    { word: 'obstetrical', word_class: 'AJ' }
  ],
  exchanger: [
    { word: 'exchange', word_class: 'N' },
    { word: 'exchange', word_class: 'V' },
    { word: 'exchanger', word_class: 'N' },
    { word: 'exchanged', word_class: 'AJ' },
    { word: 'exchangeable', word_class: 'AJ' },
    { word: 'exchangeability', word_class: 'N' }
  ],
  nickel: [
    { word: 'nickel', word_class: 'N' },
    { word: 'nickel', word_class: 'V' },
    { word: 'nickel', word_class: 'AJ' }
  ],
  archeology: [
    { word: 'archeology', word_class: 'N' },
    { word: 'archeologic', word_class: 'AJ' },
    { word: 'archeological', word_class: 'AJ' }
  ],
  essay: [
    { word: 'essay', word_class: 'N' },
    { word: 'essay', word_class: 'V' },
    { word: 'essayist', word_class: 'N' }
  ],
  shading: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  toying: [{ word: 'toy', word_class: 'N' }, { word: 'toy', word_class: 'V' }, { word: 'toying', word_class: 'N' }],
  gladness: [
    { word: 'glad', word_class: 'AJ' },
    { word: 'gladness', word_class: 'N' },
    { word: 'gladfulness', word_class: 'N' }
  ],
  pulsing: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  authorized: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  silencer: [
    { word: 'silence', word_class: 'N' },
    { word: 'silence', word_class: 'V' },
    { word: 'silent', word_class: 'AJ' },
    { word: 'silencer', word_class: 'N' },
    { word: 'silenced', word_class: 'AJ' },
    { word: 'silently', word_class: 'AV' }
  ],
  consolidative: [
    { word: 'consolidate', word_class: 'V' },
    { word: 'consolidated', word_class: 'AJ' },
    { word: 'consolidation', word_class: 'N' },
    { word: 'consolidative', word_class: 'AJ' }
  ],
  request: [
    { word: 'request', word_class: 'N' },
    { word: 'request', word_class: 'V' },
    { word: 'requested', word_class: 'AJ' }
  ],
  austere: [
    { word: 'austere', word_class: 'AJ' },
    { word: 'austerity', word_class: 'N' },
    { word: 'austerely', word_class: 'AV' },
    { word: 'austereness', word_class: 'N' }
  ],
  transfiguration: [
    { word: 'transfigure', word_class: 'N' },
    { word: 'transfigure', word_class: 'V' },
    { word: 'transfiguration', word_class: 'N' },
    { word: 'transfigurement', word_class: 'N' }
  ],
  nosiness: [
    { word: 'nose', word_class: 'N' },
    { word: 'nose', word_class: 'V' },
    { word: 'nosy', word_class: 'AJ' },
    { word: 'nosed', word_class: 'AJ' },
    { word: 'nosiness', word_class: 'N' }
  ],
  oppose: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  counteract: [
    { word: 'counteract', word_class: 'V' },
    { word: 'counteraction', word_class: 'N' },
    { word: 'counteractive', word_class: 'AJ' }
  ],
  rear: [
    { word: 'rear', word_class: 'N' },
    { word: 'rear', word_class: 'V' },
    { word: 'rear', word_class: 'AJ' },
    { word: 'rearing', word_class: 'N' },
    { word: 'rearing', word_class: 'AJ' }
  ],
  verbally: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  distastefulness: [
    { word: 'distaste', word_class: 'N' },
    { word: 'distasteful', word_class: 'AJ' },
    { word: 'distastefulness', word_class: 'N' }
  ],
  acetous: [
    { word: 'acetal', word_class: 'N' },
    { word: 'acetic', word_class: 'N' },
    { word: 'acetate', word_class: 'N' },
    { word: 'acetic', word_class: 'AJ' },
    { word: 'acetous', word_class: 'AJ' }
  ],
  blowing: [
    { word: 'blow', word_class: 'N' },
    { word: 'blow', word_class: 'V' },
    { word: 'blower', word_class: 'N' },
    { word: 'blown', word_class: 'AJ' },
    { word: 'blowing', word_class: 'N' }
  ],
  acetic: [
    { word: 'acetal', word_class: 'N' },
    { word: 'acetic', word_class: 'N' },
    { word: 'acetate', word_class: 'N' },
    { word: 'acetic', word_class: 'AJ' },
    { word: 'acetous', word_class: 'AJ' }
  ],
  embroil: [
    { word: 'embroil', word_class: 'V' },
    { word: 'embroiled', word_class: 'AJ' },
    { word: 'embroilment', word_class: 'N' }
  ],
  tank: [
    { word: 'tank', word_class: 'N' },
    { word: 'tank', word_class: 'V' },
    { word: 'tankage', word_class: 'N' },
    { word: 'tankful', word_class: 'N' },
    { word: 'tankful', word_class: 'AJ' }
  ],
  persecuted: [
    { word: 'persecute', word_class: 'V' },
    { word: 'persecutor', word_class: 'N' },
    { word: 'persecuted', word_class: 'AJ' },
    { word: 'persecution', word_class: 'N' }
  ],
  pleated: [
    { word: 'pleat', word_class: 'N' },
    { word: 'pleat', word_class: 'V' },
    { word: 'pleated', word_class: 'AJ' },
    { word: 'pleating', word_class: 'N' }
  ],
  piracy: [
    { word: 'piracy', word_class: 'N' },
    { word: 'pirate', word_class: 'N' },
    { word: 'pirate', word_class: 'V' },
    { word: 'piratical', word_class: 'AJ' },
    { word: 'piratically', word_class: 'AV' }
  ],
  copier: [
    { word: 'copy', word_class: 'N' },
    { word: 'copy', word_class: 'V' },
    { word: 'copier', word_class: 'N' },
    { word: 'copied', word_class: 'AJ' },
    { word: 'copying', word_class: 'N' },
    { word: 'copyist', word_class: 'N' }
  ],
  marinade: [
    { word: 'marine', word_class: 'N' },
    { word: 'marine', word_class: 'AJ' },
    { word: 'mariner', word_class: 'N' },
    { word: 'marinade', word_class: 'N' },
    { word: 'marinade', word_class: 'V' },
    { word: 'marinate', word_class: 'V' },
    { word: 'marination', word_class: 'N' }
  ],
  belch: [
    { word: 'belch', word_class: 'N' },
    { word: 'belch', word_class: 'V' },
    { word: 'belching', word_class: 'N' }
  ],
  brooding: [
    { word: 'brood', word_class: 'N' },
    { word: 'brood', word_class: 'V' },
    { word: 'brood', word_class: 'AJ' },
    { word: 'brooder', word_class: 'N' },
    { word: 'brooding', word_class: 'N' },
    { word: 'brooding', word_class: 'AJ' }
  ],
  compromise: [
    { word: 'compromise', word_class: 'N' },
    { word: 'compromise', word_class: 'V' },
    { word: 'compromising', word_class: 'AJ' }
  ],
  combust: [
    { word: 'combust', word_class: 'V' },
    { word: 'combustion', word_class: 'N' },
    { word: 'combustible', word_class: 'N' },
    { word: 'combustive', word_class: 'AJ' },
    { word: 'combustible', word_class: 'AJ' }
  ],
  liar: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  seamstress: [
    { word: 'seam', word_class: 'N' },
    { word: 'seam', word_class: 'V' },
    { word: 'seamed', word_class: 'AJ' },
    { word: 'seamstress', word_class: 'N' }
  ],
  disobliging: [
    { word: 'disoblige', word_class: 'V' },
    { word: 'disobliging', word_class: 'AJ' },
    { word: 'disobligation', word_class: 'N' }
  ],
  arterialise: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  demythologized: [
    { word: 'demythologise', word_class: 'V' },
    { word: 'demythologize', word_class: 'V' },
    { word: 'demythologized', word_class: 'AJ' },
    { word: 'demythologisation', word_class: 'N' },
    { word: 'demythologization', word_class: 'N' }
  ],
  ephemeralness: [
    { word: 'ephemeral', word_class: 'AJ' },
    { word: 'ephemerality', word_class: 'N' },
    { word: 'ephemeralness', word_class: 'N' }
  ],
  lounging: [
    { word: 'lounge', word_class: 'N' },
    { word: 'lounge', word_class: 'V' },
    { word: 'lounger', word_class: 'N' },
    { word: 'lounging', word_class: 'AJ' }
  ],
  sorbed: [
    { word: 'sorb', word_class: 'N' },
    { word: 'sorb', word_class: 'V' },
    { word: 'sorbed', word_class: 'AJ' },
    { word: 'sorption', word_class: 'N' }
  ],
  backstairs: [
    { word: 'backstair', word_class: 'AJ' },
    { word: 'backstairs', word_class: 'N' },
    { word: 'backstairs', word_class: 'AJ' }
  ],
  starvation: [
    { word: 'starve', word_class: 'V' },
    { word: 'starved', word_class: 'AJ' },
    { word: 'starving', word_class: 'N' },
    { word: 'starving', word_class: 'AJ' },
    { word: 'starvation', word_class: 'N' }
  ],
  submersed: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  showman: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  incongruity: [
    { word: 'incongruity', word_class: 'N' },
    { word: 'incongruent', word_class: 'AJ' },
    { word: 'incongruous', word_class: 'AJ' },
    { word: 'incongruously', word_class: 'AV' },
    { word: 'incongruousness', word_class: 'N' }
  ],
  pun: [{ word: 'pun', word_class: 'N' }, { word: 'pun', word_class: 'V' }, { word: 'punning', word_class: 'N' }],
  buoyant: [
    { word: 'buoy', word_class: 'N' },
    { word: 'buoy', word_class: 'V' },
    { word: 'buoyant', word_class: 'N' },
    { word: 'buoyancy', word_class: 'N' },
    { word: 'buoyant', word_class: 'AJ' }
  ],
  tote: [{ word: 'tote', word_class: 'N' }, { word: 'tote', word_class: 'V' }, { word: 'toter', word_class: 'N' }],
  criminally: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  whole: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  expert: [
    { word: 'expert', word_class: 'N' },
    { word: 'expert', word_class: 'AJ' },
    { word: 'expertize', word_class: 'V' },
    { word: 'expertness', word_class: 'N' }
  ],
  vaporize: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  gripping: [
    { word: 'grip', word_class: 'N' },
    { word: 'grip', word_class: 'V' },
    { word: 'gripe', word_class: 'N' },
    { word: 'gripe', word_class: 'V' },
    { word: 'gripping', word_class: 'AJ' }
  ],
  impatience: [
    { word: 'impatience', word_class: 'N' },
    { word: 'impatient', word_class: 'AJ' },
    { word: 'impatiently', word_class: 'AV' }
  ],
  immovability: [
    { word: 'immovable', word_class: 'AJ' },
    { word: 'immovably', word_class: 'AV' },
    { word: 'immoveable', word_class: 'AJ' },
    { word: 'immovability', word_class: 'N' },
    { word: 'immovableness', word_class: 'N' }
  ],
  meteorologic: [
    { word: 'meteorology', word_class: 'N' },
    { word: 'meteorologic', word_class: 'AJ' },
    { word: 'meteorological', word_class: 'AJ' },
    { word: 'meteorologically', word_class: 'AV' }
  ],
  stereotypic: [
    { word: 'stereotype', word_class: 'N' },
    { word: 'stereotype', word_class: 'V' },
    { word: 'stereotyped', word_class: 'AJ' },
    { word: 'stereotypic', word_class: 'AJ' },
    { word: 'stereotypical', word_class: 'AJ' }
  ],
  salver: [
    { word: 'salve', word_class: 'N' },
    { word: 'salve', word_class: 'V' },
    { word: 'salver', word_class: 'N' },
    { word: 'salvor', word_class: 'N' },
    { word: 'salving', word_class: 'AJ' },
    { word: 'salvation', word_class: 'N' }
  ],
  extractor: [
    { word: 'extract', word_class: 'N' },
    { word: 'extract', word_class: 'V' },
    { word: 'extractor', word_class: 'N' },
    { word: 'extraction', word_class: 'N' },
    { word: 'extractable', word_class: 'AJ' },
    { word: 'extractible', word_class: 'AJ' }
  ],
  disorienting: [
    { word: 'disorient', word_class: 'V' },
    { word: 'disorientate', word_class: 'V' },
    { word: 'disoriented', word_class: 'AJ' },
    { word: 'disorienting', word_class: 'AJ' },
    { word: 'disorientation', word_class: 'N' }
  ],
  refined: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  franchisement: [
    { word: 'franchise', word_class: 'N' },
    { word: 'franchise', word_class: 'V' },
    { word: 'franchisement', word_class: 'N' }
  ],
  abominable: [
    { word: 'abominate', word_class: 'V' },
    { word: 'abominator', word_class: 'N' },
    { word: 'abominable', word_class: 'AJ' },
    { word: 'abominably', word_class: 'AV' },
    { word: 'abomination', word_class: 'N' }
  ],
  cicatrisation: [
    { word: 'cicatrise', word_class: 'V' },
    { word: 'cicatrize', word_class: 'V' },
    { word: 'cicatrisation', word_class: 'N' },
    { word: 'cicatrization', word_class: 'N' }
  ],
  commentary: [
    { word: 'comment', word_class: 'N' },
    { word: 'comment', word_class: 'V' },
    { word: 'commentary', word_class: 'N' },
    { word: 'commentate', word_class: 'V' },
    { word: 'commentator', word_class: 'N' },
    { word: 'commentation', word_class: 'N' }
  ],
  healing: [
    { word: 'heal', word_class: 'V' },
    { word: 'healer', word_class: 'N' },
    { word: 'healed', word_class: 'AJ' },
    { word: 'healing', word_class: 'N' },
    { word: 'healing', word_class: 'AJ' }
  ],
  prescription: [
    { word: 'prescribe', word_class: 'N' },
    { word: 'prescribe', word_class: 'V' },
    { word: 'prescript', word_class: 'N' },
    { word: 'prescript', word_class: 'V' },
    { word: 'prescript', word_class: 'AJ' },
    { word: 'prescribed', word_class: 'AJ' },
    { word: 'prescription', word_class: 'N' },
    { word: 'prescription', word_class: 'AJ' },
    { word: 'prescriptive', word_class: 'AJ' }
  ],
  cajolement: [
    { word: 'cajole', word_class: 'V' },
    { word: 'cajolery', word_class: 'N' },
    { word: 'cajolement', word_class: 'N' }
  ],
  tick: [
    { word: 'tick', word_class: 'N' },
    { word: 'tick', word_class: 'V' },
    { word: 'ticker', word_class: 'N' },
    { word: 'ticking', word_class: 'N' }
  ],
  philosophic: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  settle: [
    { word: 'settle', word_class: 'N' },
    { word: 'settle', word_class: 'V' },
    { word: 'settler', word_class: 'N' },
    { word: 'settled', word_class: 'AJ' },
    { word: 'settling', word_class: 'N' },
    { word: 'settlement', word_class: 'N' }
  ],
  routine: [
    { word: 'routine', word_class: 'N' },
    { word: 'routine', word_class: 'AJ' },
    { word: 'routinize', word_class: 'V' },
    { word: 'routinely', word_class: 'AV' },
    { word: 'routinization', word_class: 'N' }
  ],
  cabinet: [
    { word: 'cabinet', word_class: 'N' },
    { word: 'cabinet', word_class: 'AJ' },
    { word: 'cabinetry', word_class: 'N' },
    { word: 'cabinetmaker', word_class: 'N' },
    { word: 'cabinetmaking', word_class: 'N' }
  ],
  voracious: [
    { word: 'voracious', word_class: 'AJ' },
    { word: 'voraciously', word_class: 'AV' },
    { word: 'voraciousness', word_class: 'N' }
  ],
  endemism: [
    { word: 'endemic', word_class: 'N' },
    { word: 'endemic', word_class: 'AJ' },
    { word: 'endemism', word_class: 'N' }
  ],
  invincible: [
    { word: 'invincible', word_class: 'AJ' },
    { word: 'invincibly', word_class: 'AV' },
    { word: 'invincibility', word_class: 'N' }
  ],
  discreditable: [
    { word: 'discredit', word_class: 'N' },
    { word: 'discredit', word_class: 'V' },
    { word: 'discredited', word_class: 'AJ' },
    { word: 'discreditable', word_class: 'AJ' },
    { word: 'discreditably', word_class: 'AV' }
  ],
  used: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  spiked: [
    { word: 'spike', word_class: 'N' },
    { word: 'spike', word_class: 'V' },
    { word: 'spiky', word_class: 'AJ' },
    { word: 'spiked', word_class: 'AJ' }
  ],
  untypical: [
    { word: 'untypical', word_class: 'AJ' },
    { word: 'untypicality', word_class: 'N' },
    { word: 'untypically', word_class: 'AV' }
  ],
  spitting: [
    { word: 'spit', word_class: 'N' },
    { word: 'spit', word_class: 'V' },
    { word: 'spitting', word_class: 'N' }
  ],
  mechanical: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  resolutely: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  docked: [
    { word: 'dock', word_class: 'N' },
    { word: 'dock', word_class: 'V' },
    { word: 'docker', word_class: 'N' },
    { word: 'dockage', word_class: 'N' },
    { word: 'docked', word_class: 'AJ' },
    { word: 'docking', word_class: 'N' }
  ],
  kayoed: [{ word: 'kayo', word_class: 'N' }, { word: 'kayo', word_class: 'V' }, { word: 'kayoed', word_class: 'AJ' }],
  straying: [
    { word: 'stray', word_class: 'N' },
    { word: 'stray', word_class: 'V' },
    { word: 'stray', word_class: 'AJ' },
    { word: 'straying', word_class: 'AJ' }
  ],
  revel: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  vernalise: [
    { word: 'vernal', word_class: 'AJ' },
    { word: 'vernalise', word_class: 'V' },
    { word: 'vernalize', word_class: 'V' },
    { word: 'vernalisation', word_class: 'N' },
    { word: 'vernalization', word_class: 'N' }
  ],
  obviate: [
    { word: 'obviate', word_class: 'V' },
    { word: 'obviation', word_class: 'N' },
    { word: 'obviating', word_class: 'AJ' }
  ],
  ambiguity: [
    { word: 'ambiguity', word_class: 'N' },
    { word: 'ambiguous', word_class: 'AJ' },
    { word: 'ambiguously', word_class: 'AV' }
  ],
  dicky: [{ word: 'dicky', word_class: 'N' }, { word: 'dickie', word_class: 'N' }, { word: 'dicky', word_class: 'AJ' }],
  ratability: [
    { word: 'ratable', word_class: 'AJ' },
    { word: 'ratables', word_class: 'N' },
    { word: 'ratability', word_class: 'N' }
  ],
  blurred: [
    { word: 'blur', word_class: 'N' },
    { word: 'blur', word_class: 'V' },
    { word: 'blurred', word_class: 'AJ' }
  ],
  insincere: [
    { word: 'insincere', word_class: 'AJ' },
    { word: 'insincerity', word_class: 'N' },
    { word: 'insincerely', word_class: 'AV' }
  ],
  distiller: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  vitalness: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  ringer: [
    { word: 'ring', word_class: 'N' },
    { word: 'ring', word_class: 'V' },
    { word: 'rung', word_class: 'N' },
    { word: 'rung', word_class: 'AJ' },
    { word: 'ringer', word_class: 'N' },
    { word: 'ringed', word_class: 'AJ' },
    { word: 'ringing', word_class: 'N' },
    { word: 'ringing', word_class: 'AJ' }
  ],
  implacable: [
    { word: 'implacate', word_class: 'V' },
    { word: 'implacable', word_class: 'AJ' },
    { word: 'implacably', word_class: 'AV' }
  ],
  paganism: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  totemic: [
    { word: 'totem', word_class: 'N' },
    { word: 'totemic', word_class: 'AJ' },
    { word: 'totemism', word_class: 'N' }
  ],
  accusative: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  hateful: [
    { word: 'hate', word_class: 'N' },
    { word: 'hate', word_class: 'V' },
    { word: 'hater', word_class: 'N' },
    { word: 'hated', word_class: 'AJ' },
    { word: 'hateful', word_class: 'AJ' },
    { word: 'hatefulness', word_class: 'N' }
  ],
  busted: [
    { word: 'bust', word_class: 'N' },
    { word: 'bust', word_class: 'V' },
    { word: 'bust', word_class: 'AJ' },
    { word: 'buster', word_class: 'N' },
    { word: 'busted', word_class: 'AJ' }
  ],
  sheeting: [
    { word: 'sheet', word_class: 'N' },
    { word: 'sheet', word_class: 'V' },
    { word: 'sheeting', word_class: 'N' }
  ],
  alchemic: [
    { word: 'alchemic', word_class: 'AJ' },
    { word: 'alchemize', word_class: 'V' },
    { word: 'alchemical', word_class: 'AJ' },
    { word: 'alchemization', word_class: 'N' }
  ],
  somnolently: [
    { word: 'somnolence', word_class: 'N' },
    { word: 'somnolent', word_class: 'AJ' },
    { word: 'somnolently', word_class: 'AV' }
  ],
  risen: [
    { word: 'rise', word_class: 'N' },
    { word: 'rise', word_class: 'V' },
    { word: 'riser', word_class: 'N' },
    { word: 'risen', word_class: 'AJ' },
    { word: 'rising', word_class: 'N' },
    { word: 'rising', word_class: 'AJ' }
  ],
  admittable: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  pastness: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  imprisoned: [
    { word: 'imprison', word_class: 'N' },
    { word: 'imprison', word_class: 'V' },
    { word: 'imprisoned', word_class: 'AJ' },
    { word: 'imprisonment', word_class: 'N' }
  ],
  contemporaneously: [
    { word: 'contemporize', word_class: 'V' },
    { word: 'contemporaneity', word_class: 'N' },
    { word: 'contemporaneous', word_class: 'AJ' },
    { word: 'contemporaneously', word_class: 'AV' },
    { word: 'contemporaneousness', word_class: 'N' }
  ],
  annealed: [
    { word: 'anneal', word_class: 'V' },
    { word: 'annealed', word_class: 'AJ' },
    { word: 'annealing', word_class: 'N' }
  ],
  visitation: [
    { word: 'visit', word_class: 'N' },
    { word: 'visit', word_class: 'V' },
    { word: 'visitor', word_class: 'N' },
    { word: 'visitant', word_class: 'N' },
    { word: 'visiting', word_class: 'N' },
    { word: 'visiting', word_class: 'AJ' },
    { word: 'visitation', word_class: 'N' }
  ],
  largely: [
    { word: 'large', word_class: 'N' },
    { word: 'large', word_class: 'AJ' },
    { word: 'large', word_class: 'AV' },
    { word: 'largely', word_class: 'AV' },
    { word: 'largeness', word_class: 'N' }
  ],
  reprimanded: [
    { word: 'reprimand', word_class: 'N' },
    { word: 'reprimand', word_class: 'V' },
    { word: 'reprimanded', word_class: 'AJ' }
  ],
  thermoelectricity: [
    { word: 'thermoelectric', word_class: 'AJ' },
    { word: 'thermoelectrical', word_class: 'AJ' },
    { word: 'thermoelectricity', word_class: 'N' }
  ],
  bruise: [
    { word: 'bruise', word_class: 'N' },
    { word: 'bruise', word_class: 'V' },
    { word: 'bruiser', word_class: 'N' },
    { word: 'bruised', word_class: 'AJ' },
    { word: 'bruising', word_class: 'AJ' }
  ],
  evaporative: [
    { word: 'evaporate', word_class: 'V' },
    { word: 'evaporable', word_class: 'AJ' },
    { word: 'evaporated', word_class: 'AJ' },
    { word: 'evaporation', word_class: 'N' },
    { word: 'evaporative', word_class: 'AJ' }
  ],
  evasively: [
    { word: 'evade', word_class: 'V' },
    { word: 'evasion', word_class: 'N' },
    { word: 'evasive', word_class: 'AJ' },
    { word: 'evasively', word_class: 'AV' },
    { word: 'evasiveness', word_class: 'N' }
  ],
  inaugurally: [
    { word: 'inaugural', word_class: 'N' },
    { word: 'inaugural', word_class: 'AJ' },
    { word: 'inaugurate', word_class: 'V' },
    { word: 'inaugurally', word_class: 'AV' },
    { word: 'inauguration', word_class: 'N' }
  ],
  profiteer: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  silently: [
    { word: 'silence', word_class: 'N' },
    { word: 'silence', word_class: 'V' },
    { word: 'silent', word_class: 'AJ' },
    { word: 'silencer', word_class: 'N' },
    { word: 'silenced', word_class: 'AJ' },
    { word: 'silently', word_class: 'AV' }
  ],
  fabricator: [
    { word: 'fabric', word_class: 'N' },
    { word: 'fabricate', word_class: 'V' },
    { word: 'fabricator', word_class: 'N' },
    { word: 'fabricated', word_class: 'AJ' },
    { word: 'fabrication', word_class: 'N' }
  ],
  unmanly: [
    { word: 'unmanly', word_class: 'AJ' },
    { word: 'unmanly', word_class: 'AV' },
    { word: 'unmanliness', word_class: 'N' }
  ],
  ton: [{ word: 'ton', word_class: 'N' }, { word: 'tons', word_class: 'N' }, { word: 'tonnage', word_class: 'N' }],
  meteorologically: [
    { word: 'meteorology', word_class: 'N' },
    { word: 'meteorologic', word_class: 'AJ' },
    { word: 'meteorological', word_class: 'AJ' },
    { word: 'meteorologically', word_class: 'AV' }
  ],
  voluble: [
    { word: 'voluble', word_class: 'AJ' },
    { word: 'volubly', word_class: 'AV' },
    { word: 'volubility', word_class: 'N' }
  ],
  timidness: [
    { word: 'timid', word_class: 'N' },
    { word: 'timid', word_class: 'AJ' },
    { word: 'timidity', word_class: 'N' },
    { word: 'timidness', word_class: 'N' }
  ],
  aromatize: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  exuviate: [
    { word: 'exuvial', word_class: 'AJ' },
    { word: 'exuviate', word_class: 'V' },
    { word: 'exuviation', word_class: 'N' }
  ],
  thyroidal: [
    { word: 'thyroid', word_class: 'N' },
    { word: 'thyroid', word_class: 'AJ' },
    { word: 'thyroidal', word_class: 'AJ' }
  ],
  silver: [
    { word: 'silver', word_class: 'N' },
    { word: 'silver', word_class: 'V' },
    { word: 'silver', word_class: 'AJ' }
  ],
  inspiritment: [
    { word: 'inspirit', word_class: 'V' },
    { word: 'inspiriting', word_class: 'AJ' },
    { word: 'inspiritment', word_class: 'N' }
  ],
  vociferous: [
    { word: 'vociferate', word_class: 'V' },
    { word: 'vociferous', word_class: 'AJ' },
    { word: 'vociferation', word_class: 'N' },
    { word: 'vociferously', word_class: 'AV' }
  ],
  monotonic: [
    { word: 'monotone', word_class: 'N' },
    { word: 'monotone', word_class: 'AJ' },
    { word: 'monotonic', word_class: 'AJ' },
    { word: 'monotonous', word_class: 'AJ' },
    { word: 'monotonously', word_class: 'AV' }
  ],
  eduction: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  obsessed: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  reject: [
    { word: 'reject', word_class: 'N' },
    { word: 'reject', word_class: 'V' },
    { word: 'rejected', word_class: 'AJ' },
    { word: 'rejection', word_class: 'N' },
    { word: 'rejective', word_class: 'AJ' }
  ],
  demythologise: [
    { word: 'demythologise', word_class: 'V' },
    { word: 'demythologize', word_class: 'V' },
    { word: 'demythologized', word_class: 'AJ' },
    { word: 'demythologisation', word_class: 'N' },
    { word: 'demythologization', word_class: 'N' }
  ],
  jimmies: [
    { word: 'jimmy', word_class: 'N' },
    { word: 'jimmy', word_class: 'V' },
    { word: 'jimmies', word_class: 'N' }
  ],
  fettered: [
    { word: 'fetter', word_class: 'N' },
    { word: 'fetter', word_class: 'V' },
    { word: 'fettered', word_class: 'AJ' }
  ],
  enthusiast: [
    { word: 'enthusiast', word_class: 'N' },
    { word: 'enthusiastic', word_class: 'AJ' },
    { word: 'enthusiastically', word_class: 'AV' }
  ],
  acquiesce: [
    { word: 'acquiesce', word_class: 'V' },
    { word: 'acquiescence', word_class: 'N' },
    { word: 'acquiescent', word_class: 'AJ' }
  ],
  ideation: [
    { word: 'idea', word_class: 'N' },
    { word: 'ideate', word_class: 'V' },
    { word: 'ideation', word_class: 'N' },
    { word: 'ideational', word_class: 'AJ' }
  ],
  cruise: [
    { word: 'cruise', word_class: 'N' },
    { word: 'cruise', word_class: 'V' },
    { word: 'cruiser', word_class: 'N' }
  ],
  therapeutics: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  swirling: [
    { word: 'swirl', word_class: 'N' },
    { word: 'swirl', word_class: 'V' },
    { word: 'swirling', word_class: 'AJ' }
  ],
  plunk: [{ word: 'plunk', word_class: 'N' }, { word: 'plunk', word_class: 'V' }, { word: 'plunk', word_class: 'AV' }],
  fell: [
    { word: 'fell', word_class: 'N' },
    { word: 'fell', word_class: 'V' },
    { word: 'fell', word_class: 'AJ' },
    { word: 'fellate', word_class: 'V' },
    { word: 'felled', word_class: 'AJ' },
    { word: 'fellation', word_class: 'N' }
  ],
  overcapitalize: [
    { word: 'overcapitalise', word_class: 'V' },
    { word: 'overcapitalize', word_class: 'V' },
    { word: 'overcapitalisation', word_class: 'N' },
    { word: 'overcapitalization', word_class: 'N' }
  ],
  adumbrative: [
    { word: 'adumbrate', word_class: 'V' },
    { word: 'adumbration', word_class: 'N' },
    { word: 'adumbrative', word_class: 'AJ' }
  ],
  facetious: [
    { word: 'facetious', word_class: 'AJ' },
    { word: 'facetiously', word_class: 'AV' },
    { word: 'facetiousness', word_class: 'N' }
  ],
  defendant: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  occupy: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  finely: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  desalination: [
    { word: 'desalinate', word_class: 'V' },
    { word: 'desalinize', word_class: 'V' },
    { word: 'desalinated', word_class: 'AJ' },
    { word: 'desalination', word_class: 'N' },
    { word: 'desalinization', word_class: 'N' }
  ],
  fascination: [
    { word: 'fascinate', word_class: 'V' },
    { word: 'fascinated', word_class: 'AJ' },
    { word: 'fascination', word_class: 'N' },
    { word: 'fascinating', word_class: 'AJ' }
  ],
  robe: [{ word: 'robe', word_class: 'N' }, { word: 'robe', word_class: 'V' }, { word: 'robed', word_class: 'AJ' }],
  ecliptic: [
    { word: 'eclipse', word_class: 'N' },
    { word: 'eclipse', word_class: 'V' },
    { word: 'ecliptic', word_class: 'N' },
    { word: 'ecliptic', word_class: 'AJ' }
  ],
  diabolic: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  procrastinate: [
    { word: 'procrastinate', word_class: 'V' },
    { word: 'procrastinator', word_class: 'N' },
    { word: 'procrastination', word_class: 'N' }
  ],
  corporeal: [
    { word: 'corporal', word_class: 'N' },
    { word: 'corporal', word_class: 'AJ' },
    { word: 'corporate', word_class: 'AJ' },
    { word: 'corporeal', word_class: 'AJ' },
    { word: 'corporation', word_class: 'N' },
    { word: 'corporeality', word_class: 'N' }
  ],
  transference: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  carrier: [
    { word: 'carry', word_class: 'N' },
    { word: 'carry', word_class: 'V' },
    { word: 'carry', word_class: 'AJ' },
    { word: 'carrier', word_class: 'N' },
    { word: 'carriage', word_class: 'N' }
  ],
  shanghai: [
    { word: 'shanghai', word_class: 'N' },
    { word: 'shanghai', word_class: 'V' },
    { word: 'shanghaier', word_class: 'N' }
  ],
  hollowness: [
    { word: 'hollow', word_class: 'N' },
    { word: 'hollow', word_class: 'V' },
    { word: 'hollow', word_class: 'AJ' },
    { word: 'hollowed', word_class: 'AJ' },
    { word: 'hollowness', word_class: 'N' }
  ],
  grandiosity: [
    { word: 'grandiose', word_class: 'AJ' },
    { word: 'grandiosity', word_class: 'N' },
    { word: 'grandiosely', word_class: 'AV' }
  ],
  imbecility: [
    { word: 'imbecile', word_class: 'N' },
    { word: 'imbecile', word_class: 'AJ' },
    { word: 'imbecilic', word_class: 'AJ' },
    { word: 'imbecility', word_class: 'N' }
  ],
  carbonated: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  hike: [{ word: 'hike', word_class: 'N' }, { word: 'hike', word_class: 'V' }, { word: 'hiker', word_class: 'N' }],
  remand: [
    { word: 'remand', word_class: 'N' },
    { word: 'remand', word_class: 'V' },
    { word: 'remandment', word_class: 'N' }
  ],
  fomentation: [
    { word: 'foment', word_class: 'V' },
    { word: 'fomenter', word_class: 'N' },
    { word: 'fomentation', word_class: 'N' }
  ],
  admonisher: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  industrialized: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  appropriated: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  molester: [
    { word: 'molest', word_class: 'V' },
    { word: 'molester', word_class: 'N' },
    { word: 'molested', word_class: 'AJ' },
    { word: 'molestation', word_class: 'N' }
  ],
  elops: [
    { word: 'elope', word_class: 'V' },
    { word: 'elops', word_class: 'N' },
    { word: 'elopement', word_class: 'N' }
  ],
  modernity: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  concordance: [
    { word: 'concord', word_class: 'N' },
    { word: 'concord', word_class: 'V' },
    { word: 'concordant', word_class: 'N' },
    { word: 'concordance', word_class: 'N' },
    { word: 'concordant', word_class: 'AJ' }
  ],
  racial: [
    { word: 'racial', word_class: 'AJ' },
    { word: 'racialism', word_class: 'N' },
    { word: 'racially', word_class: 'AV' }
  ],
  exceptionally: [
    { word: 'except', word_class: 'V' },
    { word: 'exception', word_class: 'N' },
    { word: 'exceptional', word_class: 'AJ' },
    { word: 'exceptionable', word_class: 'AJ' },
    { word: 'exceptionally', word_class: 'AV' }
  ],
  inwards: [
    { word: 'inward', word_class: 'N' },
    { word: 'inward', word_class: 'V' },
    { word: 'inward', word_class: 'AJ' },
    { word: 'inward', word_class: 'AV' },
    { word: 'inwards', word_class: 'AV' },
    { word: 'inwardness', word_class: 'N' }
  ],
  invalidation: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  sinful: [
    { word: 'sin', word_class: 'N' },
    { word: 'sin', word_class: 'V' },
    { word: 'sinful', word_class: 'AJ' },
    { word: 'sinning', word_class: 'N' },
    { word: 'sinning', word_class: 'AJ' },
    { word: 'sinfulness', word_class: 'N' }
  ],
  novelties: [
    { word: 'novel', word_class: 'N' },
    { word: 'novel', word_class: 'AJ' },
    { word: 'novelty', word_class: 'N' },
    { word: 'novelize', word_class: 'V' },
    { word: 'novelties', word_class: 'N' },
    { word: 'novelization', word_class: 'N' }
  ],
  sluice: [
    { word: 'sluice', word_class: 'N' },
    { word: 'sluice', word_class: 'V' },
    { word: 'sluicing', word_class: 'AJ' }
  ],
  indexation: [
    { word: 'index', word_class: 'N' },
    { word: 'index', word_class: 'V' },
    { word: 'indexer', word_class: 'N' },
    { word: 'indexing', word_class: 'N' },
    { word: 'indexation', word_class: 'N' },
    { word: 'indexical', word_class: 'AJ' }
  ],
  lave: [{ word: 'lave', word_class: 'V' }, { word: 'laver', word_class: 'N' }, { word: 'lavation', word_class: 'N' }],
  imperiousness: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  aesthetically: [
    { word: 'aesthete', word_class: 'N' },
    { word: 'aesthetic', word_class: 'N' },
    { word: 'aesthetic', word_class: 'AJ' },
    { word: 'aesthetics', word_class: 'N' },
    { word: 'aesthetical', word_class: 'AJ' },
    { word: 'aesthetically', word_class: 'AV' }
  ],
  spheroid: [
    { word: 'sphere', word_class: 'N' },
    { word: 'spheroid', word_class: 'N' },
    { word: 'spheroid', word_class: 'AJ' },
    { word: 'spheroidal', word_class: 'AJ' }
  ],
  uncover: [
    { word: 'uncover', word_class: 'V' },
    { word: 'uncovered', word_class: 'AJ' },
    { word: 'uncovering', word_class: 'N' }
  ],
  gild: [
    { word: 'gild', word_class: 'N' },
    { word: 'gild', word_class: 'V' },
    { word: 'gilder', word_class: 'N' },
    { word: 'gilded', word_class: 'AJ' },
    { word: 'gilding', word_class: 'N' }
  ],
  parasitize: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  boredom: [
    { word: 'bore', word_class: 'N' },
    { word: 'bore', word_class: 'V' },
    { word: 'borer', word_class: 'N' },
    { word: 'bored', word_class: 'AJ' },
    { word: 'boring', word_class: 'N' },
    { word: 'boredom', word_class: 'N' },
    { word: 'boring', word_class: 'AJ' }
  ],
  strait: [
    { word: 'strait', word_class: 'N' },
    { word: 'strait', word_class: 'AJ' },
    { word: 'straits', word_class: 'N' }
  ],
  dwarf: [
    { word: 'dwarf', word_class: 'N' },
    { word: 'dwarf', word_class: 'V' },
    { word: 'dwarf', word_class: 'AJ' },
    { word: 'dwarfism', word_class: 'N' }
  ],
  myelinated: [
    { word: 'myelin', word_class: 'N' },
    { word: 'myeline', word_class: 'N' },
    { word: 'myelinic', word_class: 'AJ' },
    { word: 'myelinated', word_class: 'AJ' }
  ],
  dynamic: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  preserved: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  savoriness: [
    { word: 'savory', word_class: 'N' },
    { word: 'savory', word_class: 'AJ' },
    { word: 'savoriness', word_class: 'N' }
  ],
  throttle: [
    { word: 'throttle', word_class: 'N' },
    { word: 'throttle', word_class: 'V' },
    { word: 'throttling', word_class: 'N' }
  ],
  plausibly: [
    { word: 'plausible', word_class: 'AJ' },
    { word: 'plausibly', word_class: 'AV' },
    { word: 'plausibility', word_class: 'N' },
    { word: 'plausibleness', word_class: 'N' }
  ],
  tonsured: [
    { word: 'tonsure', word_class: 'N' },
    { word: 'tonsure', word_class: 'V' },
    { word: 'tonsured', word_class: 'AJ' }
  ],
  purchaser: [
    { word: 'purchase', word_class: 'N' },
    { word: 'purchase', word_class: 'V' },
    { word: 'purchaser', word_class: 'N' },
    { word: 'purchasing', word_class: 'N' },
    { word: 'purchasable', word_class: 'AJ' }
  ],
  possessed: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  dissatisfaction: [
    { word: 'dissatisfy', word_class: 'V' },
    { word: 'dissatisfied', word_class: 'AJ' },
    { word: 'dissatisfaction', word_class: 'N' }
  ],
  sanguineous: [
    { word: 'sanguine', word_class: 'AJ' },
    { word: 'sanguinity', word_class: 'N' },
    { word: 'sanguineness', word_class: 'N' },
    { word: 'sanguineous', word_class: 'AJ' }
  ],
  sculpted: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  nutrition: [
    { word: 'nutrition', word_class: 'N' },
    { word: 'nutritive', word_class: 'AJ' },
    { word: 'nutritional', word_class: 'AJ' },
    { word: 'nutritiveness', word_class: 'N' }
  ],
  jumbled: [
    { word: 'jumble', word_class: 'N' },
    { word: 'jumble', word_class: 'V' },
    { word: 'jumbled', word_class: 'AJ' }
  ],
  putrescence: [
    { word: 'putrescence', word_class: 'N' },
    { word: 'putrescent', word_class: 'AJ' },
    { word: 'putrescible', word_class: 'AJ' }
  ],
  economization: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  entertainment: [
    { word: 'entertain', word_class: 'V' },
    { word: 'entertainer', word_class: 'N' },
    { word: 'entertained', word_class: 'AJ' },
    { word: 'entertaining', word_class: 'AJ' },
    { word: 'entertainment', word_class: 'N' }
  ],
  passively: [
    { word: 'passive', word_class: 'N' },
    { word: 'passive', word_class: 'AJ' },
    { word: 'passivism', word_class: 'N' },
    { word: 'passivity', word_class: 'N' },
    { word: 'passively', word_class: 'AV' },
    { word: 'passiveness', word_class: 'N' }
  ],
  slouch: [
    { word: 'slouch', word_class: 'N' },
    { word: 'slouch', word_class: 'V' },
    { word: 'slouched', word_class: 'AJ' },
    { word: 'slouching', word_class: 'AJ' }
  ],
  sounding: [
    { word: 'sound', word_class: 'N' },
    { word: 'sound', word_class: 'V' },
    { word: 'sound', word_class: 'AJ' },
    { word: 'sound', word_class: 'AV' },
    { word: 'sounded', word_class: 'AJ' },
    { word: 'sounding', word_class: 'N' },
    { word: 'soundman', word_class: 'N' },
    { word: 'sounding', word_class: 'AJ' },
    { word: 'soundness', word_class: 'N' }
  ],
  resourceful: [
    { word: 'resource', word_class: 'N' },
    { word: 'resourceful', word_class: 'AJ' },
    { word: 'resourcefulness', word_class: 'N' }
  ],
  perforated: [
    { word: 'perforate', word_class: 'V' },
    { word: 'perforate', word_class: 'AJ' },
    { word: 'perforated', word_class: 'AJ' },
    { word: 'perforation', word_class: 'N' }
  ],
  conceptus: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  interpretation: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  chiding: [
    { word: 'chide', word_class: 'V' },
    { word: 'chiding', word_class: 'N' },
    { word: 'chidden', word_class: 'AJ' }
  ],
  virgin: [
    { word: 'virgin', word_class: 'N' },
    { word: 'virgin', word_class: 'AJ' },
    { word: 'virginal', word_class: 'N' },
    { word: 'virginal', word_class: 'AJ' },
    { word: 'virginity', word_class: 'N' }
  ],
  manly: [
    { word: 'manly', word_class: 'AJ' },
    { word: 'manly', word_class: 'AV' },
    { word: 'manliness', word_class: 'N' }
  ],
  disintegrative: [
    { word: 'disintegrate', word_class: 'V' },
    { word: 'disintegrable', word_class: 'AJ' },
    { word: 'disintegration', word_class: 'N' },
    { word: 'disintegrative', word_class: 'AJ' }
  ],
  admiral: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  sloth: [
    { word: 'sloth', word_class: 'N' },
    { word: 'slothful', word_class: 'AJ' },
    { word: 'slothfulness', word_class: 'N' }
  ],
  cheeked: [
    { word: 'cheek', word_class: 'N' },
    { word: 'cheek', word_class: 'V' },
    { word: 'cheek', word_class: 'AJ' },
    { word: 'cheeked', word_class: 'AJ' },
    { word: 'cheekful', word_class: 'N' },
    { word: 'cheekful', word_class: 'AJ' }
  ],
  divorcement: [
    { word: 'divorce', word_class: 'N' },
    { word: 'divorce', word_class: 'V' },
    { word: 'divorcee', word_class: 'N' },
    { word: 'divorced', word_class: 'AJ' },
    { word: 'divorcement', word_class: 'N' }
  ],
  banding: [
    { word: 'band', word_class: 'N' },
    { word: 'band', word_class: 'V' },
    { word: 'banded', word_class: 'AJ' },
    { word: 'banding', word_class: 'N' }
  ],
  attending: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  ringing: [
    { word: 'ring', word_class: 'N' },
    { word: 'ring', word_class: 'V' },
    { word: 'rung', word_class: 'N' },
    { word: 'rung', word_class: 'AJ' },
    { word: 'ringer', word_class: 'N' },
    { word: 'ringed', word_class: 'AJ' },
    { word: 'ringing', word_class: 'N' },
    { word: 'ringing', word_class: 'AJ' }
  ],
  reposal: [
    { word: 'repose', word_class: 'N' },
    { word: 'repose', word_class: 'V' },
    { word: 'reposal', word_class: 'N' },
    { word: 'reposeful', word_class: 'AJ' }
  ],
  sojourn: [
    { word: 'sojourn', word_class: 'N' },
    { word: 'sojourn', word_class: 'V' },
    { word: 'sojourner', word_class: 'N' }
  ],
  stamp: [
    { word: 'stamp', word_class: 'N' },
    { word: 'stamp', word_class: 'V' },
    { word: 'stamped', word_class: 'AJ' }
  ],
  bettering: [
    { word: 'better', word_class: 'N' },
    { word: 'better', word_class: 'V' },
    { word: 'better', word_class: 'AJ' },
    { word: 'better', word_class: 'AV' },
    { word: 'bettering', word_class: 'AJ' },
    { word: 'betterment', word_class: 'N' }
  ],
  volume: [
    { word: 'volume', word_class: 'N' },
    { word: 'voluminous', word_class: 'AJ' },
    { word: 'voluminousness', word_class: 'N' }
  ],
  crenelated: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  infiniteness: [
    { word: 'infinite', word_class: 'AJ' },
    { word: 'infinitive', word_class: 'N' },
    { word: 'infinitely', word_class: 'AV' },
    { word: 'infinitive', word_class: 'AJ' },
    { word: 'infiniteness', word_class: 'N' }
  ],
  dearness: [
    { word: 'dear', word_class: 'N' },
    { word: 'dear', word_class: 'AJ' },
    { word: 'dear', word_class: 'AV' },
    { word: 'dearness', word_class: 'N' }
  ],
  gymnastics: [
    { word: 'gymnast', word_class: 'N' },
    { word: 'gymnastic', word_class: 'AJ' },
    { word: 'gymnastics', word_class: 'N' }
  ],
  meteoritic: [
    { word: 'meteorite', word_class: 'N' },
    { word: 'meteoritic', word_class: 'AJ' },
    { word: 'meteoritical', word_class: 'AJ' }
  ],
  doped: [{ word: 'dope', word_class: 'N' }, { word: 'dope', word_class: 'V' }, { word: 'doped', word_class: 'AJ' }],
  tranquilizer: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  poke: [
    { word: 'poke', word_class: 'N' },
    { word: 'poke', word_class: 'V' },
    { word: 'poker', word_class: 'N' },
    { word: 'poking', word_class: 'N' }
  ],
  anomalously: [
    { word: 'anomaly', word_class: 'N' },
    { word: 'anomalous', word_class: 'AJ' },
    { word: 'anomalously', word_class: 'AV' },
    { word: 'anomalousness', word_class: 'N' }
  ],
  hydrate: [
    { word: 'hydrate', word_class: 'N' },
    { word: 'hydrate', word_class: 'V' },
    { word: 'hydration', word_class: 'N' }
  ],
  heathenism: [
    { word: 'heathen', word_class: 'N' },
    { word: 'heathen', word_class: 'AJ' },
    { word: 'heathenism', word_class: 'N' },
    { word: 'heathenize', word_class: 'V' },
    { word: 'heathenization', word_class: 'N' }
  ],
  systematically: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  appease: [
    { word: 'appease', word_class: 'V' },
    { word: 'appeaser', word_class: 'N' },
    { word: 'appeasing', word_class: 'AJ' },
    { word: 'appeasable', word_class: 'AJ' },
    { word: 'appeasement', word_class: 'N' }
  ],
  jaywalk: [
    { word: 'jaywalk', word_class: 'V' },
    { word: 'jaywalker', word_class: 'N' },
    { word: 'jaywalking', word_class: 'N' }
  ],
  callously: [
    { word: 'calloused', word_class: 'AJ' },
    { word: 'callously', word_class: 'AV' },
    { word: 'callousness', word_class: 'N' }
  ],
  calvinistical: [
    { word: 'calvinist', word_class: 'N' },
    { word: 'calvinist', word_class: 'AJ' },
    { word: 'calvinistic', word_class: 'AJ' },
    { word: 'calvinistical', word_class: 'AJ' }
  ],
  leeward: [
    { word: 'leeward', word_class: 'N' },
    { word: 'leeward', word_class: 'AJ' },
    { word: 'leeward', word_class: 'AV' }
  ],
  decimal: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  modernize: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  obviation: [
    { word: 'obviate', word_class: 'V' },
    { word: 'obviation', word_class: 'N' },
    { word: 'obviating', word_class: 'AJ' }
  ],
  affinity: [
    { word: 'affine', word_class: 'N' },
    { word: 'affine', word_class: 'AJ' },
    { word: 'affinal', word_class: 'AJ' },
    { word: 'affined', word_class: 'AJ' },
    { word: 'affinity', word_class: 'N' }
  ],
  spout: [
    { word: 'spout', word_class: 'N' },
    { word: 'spout', word_class: 'V' },
    { word: 'spouting', word_class: 'AJ' }
  ],
  cuboidal: [
    { word: 'cuboid', word_class: 'N' },
    { word: 'cuboid', word_class: 'AJ' },
    { word: 'cuboidal', word_class: 'AJ' }
  ],
  obtrusive: [
    { word: 'obtrude', word_class: 'V' },
    { word: 'obtrusion', word_class: 'N' },
    { word: 'obtrusive', word_class: 'AJ' },
    { word: 'obtrusively', word_class: 'AV' },
    { word: 'obtrusiveness', word_class: 'N' }
  ],
  specialized: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  lender: [
    { word: 'lend', word_class: 'V' },
    { word: 'lender', word_class: 'N' },
    { word: 'lending', word_class: 'N' }
  ],
  appurtenance: [
    { word: 'appurtenance', word_class: 'N' },
    { word: 'appurtenant', word_class: 'AJ' },
    { word: 'appurtenances', word_class: 'N' }
  ],
  prepossess: [
    { word: 'prepossess', word_class: 'V' },
    { word: 'prepossession', word_class: 'N' },
    { word: 'prepossessing', word_class: 'AJ' }
  ],
  quaternate: [
    { word: 'quatern', word_class: 'N' },
    { word: 'quaternity', word_class: 'N' },
    { word: 'quaternate', word_class: 'AJ' }
  ],
  recrystallization: [
    { word: 'recrystallise', word_class: 'V' },
    { word: 'recrystallize', word_class: 'V' },
    { word: 'recrystallisation', word_class: 'N' },
    { word: 'recrystallization', word_class: 'N' }
  ],
  homy: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  liquefiable: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  acquittance: [
    { word: 'acquit', word_class: 'V' },
    { word: 'acquittal', word_class: 'N' },
    { word: 'acquitted', word_class: 'AJ' },
    { word: 'acquittance', word_class: 'N' }
  ],
  shirking: [
    { word: 'shirk', word_class: 'V' },
    { word: 'shirker', word_class: 'N' },
    { word: 'shirking', word_class: 'N' }
  ],
  demodulator: [
    { word: 'demodulate', word_class: 'V' },
    { word: 'demodulator', word_class: 'N' },
    { word: 'demodulation', word_class: 'N' }
  ],
  repudiate: [
    { word: 'repudiate', word_class: 'V' },
    { word: 'repudiated', word_class: 'AJ' },
    { word: 'repudiation', word_class: 'N' },
    { word: 'repudiative', word_class: 'AJ' }
  ],
  choiceness: [
    { word: 'choice', word_class: 'N' },
    { word: 'choose', word_class: 'V' },
    { word: 'chosen', word_class: 'N' },
    { word: 'choosy', word_class: 'AJ' },
    { word: 'choice', word_class: 'AJ' },
    { word: 'chosen', word_class: 'AJ' },
    { word: 'choiceness', word_class: 'N' }
  ],
  ecclesiastic: [
    { word: 'ecclesiastic', word_class: 'N' },
    { word: 'ecclesiastic', word_class: 'AJ' },
    { word: 'ecclesiastical', word_class: 'AJ' },
    { word: 'ecclesiastically', word_class: 'AV' }
  ],
  expressionist: [
    { word: 'expressionist', word_class: 'N' },
    { word: 'expressionist', word_class: 'AJ' },
    { word: 'expressionistic', word_class: 'AJ' }
  ],
  poignant: [
    { word: 'poignance', word_class: 'N' },
    { word: 'poignancy', word_class: 'N' },
    { word: 'poignant', word_class: 'AJ' }
  ],
  yell: [
    { word: 'yell', word_class: 'N' },
    { word: 'yell', word_class: 'V' },
    { word: 'yeller', word_class: 'N' },
    { word: 'yelled', word_class: 'AJ' },
    { word: 'yelling', word_class: 'N' },
    { word: 'yelling', word_class: 'AJ' }
  ],
  incautiousness: [
    { word: 'incautious', word_class: 'AJ' },
    { word: 'incautiously', word_class: 'AV' },
    { word: 'incautiousness', word_class: 'N' }
  ],
  metrically: [
    { word: 'metric', word_class: 'N' },
    { word: 'metric', word_class: 'AJ' },
    { word: 'metrical', word_class: 'AJ' },
    { word: 'metricize', word_class: 'V' },
    { word: 'metrically', word_class: 'AV' },
    { word: 'metrication', word_class: 'N' }
  ],
  abase: [
    { word: 'abase', word_class: 'V' },
    { word: 'abasic', word_class: 'AJ' },
    { word: 'abasement', word_class: 'N' }
  ],
  refutable: [
    { word: 'refute', word_class: 'V' },
    { word: 'refuting', word_class: 'N' },
    { word: 'refutable', word_class: 'AJ' },
    { word: 'refutation', word_class: 'N' }
  ],
  optimise: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  leniency: [
    { word: 'lenience', word_class: 'N' },
    { word: 'leniency', word_class: 'N' },
    { word: 'lenient', word_class: 'AJ' },
    { word: 'leniently', word_class: 'AV' }
  ],
  swirl: [
    { word: 'swirl', word_class: 'N' },
    { word: 'swirl', word_class: 'V' },
    { word: 'swirling', word_class: 'AJ' }
  ],
  reliant: [
    { word: 'rely', word_class: 'V' },
    { word: 'relie', word_class: 'V' },
    { word: 'reliant', word_class: 'N' },
    { word: 'reliance', word_class: 'N' },
    { word: 'reliant', word_class: 'AJ' }
  ],
  despoiler: [
    { word: 'despoil', word_class: 'V' },
    { word: 'despoiler', word_class: 'N' },
    { word: 'despoiled', word_class: 'AJ' },
    { word: 'despoilment', word_class: 'N' },
    { word: 'despoliation', word_class: 'N' }
  ],
  bowdlerize: [
    { word: 'bowdlerise', word_class: 'V' },
    { word: 'bowdlerism', word_class: 'N' },
    { word: 'bowdlerize', word_class: 'V' },
    { word: 'bowdlerisation', word_class: 'N' },
    { word: 'bowdlerization', word_class: 'N' }
  ],
  refrigerate: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  scotch: [
    { word: 'scotch', word_class: 'N' },
    { word: 'scotch', word_class: 'V' },
    { word: 'scotch', word_class: 'AJ' },
    { word: 'scotchman', word_class: 'N' }
  ],
  tension: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  hurdles: [
    { word: 'hurdle', word_class: 'N' },
    { word: 'hurdle', word_class: 'V' },
    { word: 'hurdler', word_class: 'N' },
    { word: 'hurdles', word_class: 'N' },
    { word: 'hurdling', word_class: 'N' }
  ],
  decomposition: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  spiritualise: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  bacchantic: [
    { word: 'bacchant', word_class: 'N' },
    { word: 'bacchante', word_class: 'N' },
    { word: 'bacchantic', word_class: 'AJ' }
  ],
  occupying: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  magnifier: [
    { word: 'magnify', word_class: 'V' },
    { word: 'magnifier', word_class: 'N' },
    { word: 'magnified', word_class: 'AJ' },
    { word: 'magnification', word_class: 'N' }
  ],
  splotch: [
    { word: 'splotch', word_class: 'N' },
    { word: 'splotch', word_class: 'V' },
    { word: 'splotched', word_class: 'AJ' }
  ],
  biologism: [
    { word: 'biologic', word_class: 'AJ' },
    { word: 'biologism', word_class: 'N' },
    { word: 'biological', word_class: 'AJ' },
    { word: 'biologically', word_class: 'AV' }
  ],
  stewed: [
    { word: 'stew', word_class: 'N' },
    { word: 'stew', word_class: 'V' },
    { word: 'stewed', word_class: 'AJ' },
    { word: 'stewing', word_class: 'N' }
  ],
  ripen: [
    { word: 'ripen', word_class: 'V' },
    { word: 'ripened', word_class: 'AJ' },
    { word: 'ripening', word_class: 'N' }
  ],
  irreversible: [
    { word: 'irreversible', word_class: 'AJ' },
    { word: 'irreversibly', word_class: 'AV' },
    { word: 'irreversibility', word_class: 'N' }
  ],
  intoxicating: [
    { word: 'intoxicant', word_class: 'N' },
    { word: 'intoxicate', word_class: 'V' },
    { word: 'intoxicant', word_class: 'AJ' },
    { word: 'intoxicated', word_class: 'AJ' },
    { word: 'intoxication', word_class: 'N' },
    { word: 'intoxicating', word_class: 'AJ' }
  ],
  streak: [
    { word: 'streak', word_class: 'N' },
    { word: 'streak', word_class: 'V' },
    { word: 'streaker', word_class: 'N' },
    { word: 'streaked', word_class: 'AJ' }
  ],
  anomaly: [
    { word: 'anomaly', word_class: 'N' },
    { word: 'anomalous', word_class: 'AJ' },
    { word: 'anomalously', word_class: 'AV' },
    { word: 'anomalousness', word_class: 'N' }
  ],
  motive: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  stimulation: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  assertiveness: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  rare: [
    { word: 'rare', word_class: 'AJ' },
    { word: 'rarely', word_class: 'AV' },
    { word: 'raring', word_class: 'AJ' },
    { word: 'rareness', word_class: 'N' }
  ],
  inadvertency: [
    { word: 'inadvertence', word_class: 'N' },
    { word: 'inadvertency', word_class: 'N' },
    { word: 'inadvertent', word_class: 'AJ' },
    { word: 'inadvertently', word_class: 'AV' }
  ],
  obfuscation: [
    { word: 'obfuscate', word_class: 'V' },
    { word: 'obfuscation', word_class: 'N' },
    { word: 'obfucscation', word_class: 'N' }
  ],
  dented: [
    { word: 'dent', word_class: 'N' },
    { word: 'dent', word_class: 'V' },
    { word: 'dented', word_class: 'AJ' },
    { word: 'dentist', word_class: 'N' }
  ],
  lapsed: [
    { word: 'lapse', word_class: 'N' },
    { word: 'lapse', word_class: 'V' },
    { word: 'lapsed', word_class: 'AJ' },
    { word: 'lapsing', word_class: 'N' }
  ],
  subdivided: [
    { word: 'subdivide', word_class: 'V' },
    { word: 'subdivided', word_class: 'AJ' },
    { word: 'subdivision', word_class: 'N' }
  ],
  unsatisfiable: [
    { word: 'unsatisfied', word_class: 'AJ' },
    { word: 'unsatisfying', word_class: 'AJ' },
    { word: 'unsatisfiable', word_class: 'AJ' }
  ],
  decade: [
    { word: 'decade', word_class: 'N' },
    { word: 'decadent', word_class: 'N' },
    { word: 'decadence', word_class: 'N' },
    { word: 'decadent', word_class: 'AJ' }
  ],
  emphasis: [
    { word: 'emphasis', word_class: 'N' },
    { word: 'emphasize', word_class: 'V' },
    { word: 'emphasized', word_class: 'AJ' },
    { word: 'emphasizing', word_class: 'N' }
  ],
  computerized: [
    { word: 'computerise', word_class: 'V' },
    { word: 'computerize', word_class: 'V' },
    { word: 'computerized', word_class: 'AJ' },
    { word: 'computerisation', word_class: 'N' },
    { word: 'computerization', word_class: 'N' }
  ],
  diversify: [
    { word: 'diversify', word_class: 'V' },
    { word: 'diversified', word_class: 'AJ' },
    { word: 'diversification', word_class: 'N' }
  ],
  facilitator: [
    { word: 'facilitate', word_class: 'V' },
    { word: 'facilitator', word_class: 'N' },
    { word: 'facilitated', word_class: 'AJ' },
    { word: 'facilitation', word_class: 'N' },
    { word: 'facilitative', word_class: 'AJ' }
  ],
  express: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  edited: [
    { word: 'edit', word_class: 'V' },
    { word: 'editor', word_class: 'N' },
    { word: 'edited', word_class: 'AJ' },
    { word: 'editing', word_class: 'N' },
    { word: 'edition', word_class: 'N' }
  ],
  spatiality: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  strand: [
    { word: 'strand', word_class: 'N' },
    { word: 'strand', word_class: 'V' },
    { word: 'stranded', word_class: 'AJ' }
  ],
  retreatant: [
    { word: 'retreat', word_class: 'N' },
    { word: 'retreat', word_class: 'V' },
    { word: 'retreated', word_class: 'N' },
    { word: 'retreatant', word_class: 'N' },
    { word: 'retreating', word_class: 'AJ' }
  ],
  trailing: [
    { word: 'trail', word_class: 'N' },
    { word: 'trail', word_class: 'V' },
    { word: 'trailer', word_class: 'N' },
    { word: 'trailing', word_class: 'N' },
    { word: 'trailing', word_class: 'AJ' }
  ],
  excavate: [
    { word: 'excavate', word_class: 'V' },
    { word: 'excavator', word_class: 'N' },
    { word: 'excavation', word_class: 'N' }
  ],
  psychopathology: [
    { word: 'psychopathology', word_class: 'N' },
    { word: 'psychopathologic', word_class: 'AJ' },
    { word: 'psychopathological', word_class: 'AJ' }
  ],
  thread: [
    { word: 'thread', word_class: 'N' },
    { word: 'thread', word_class: 'V' },
    { word: 'threads', word_class: 'N' }
  ],
  cretinism: [
    { word: 'cretin', word_class: 'N' },
    { word: 'cretinism', word_class: 'N' },
    { word: 'cretinous', word_class: 'AJ' }
  ],
  venturous: [
    { word: 'venture', word_class: 'N' },
    { word: 'venture', word_class: 'V' },
    { word: 'venturous', word_class: 'AJ' },
    { word: 'venturesome', word_class: 'AJ' },
    { word: 'venturesomeness', word_class: 'N' }
  ],
  actuate: [
    { word: 'actuate', word_class: 'V' },
    { word: 'actuator', word_class: 'N' },
    { word: 'actuated', word_class: 'AJ' },
    { word: 'actuation', word_class: 'N' },
    { word: 'actuating', word_class: 'AJ' }
  ],
  jewel: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  assistance: [
    { word: 'assist', word_class: 'N' },
    { word: 'assist', word_class: 'V' },
    { word: 'assistant', word_class: 'N' },
    { word: 'assisted', word_class: 'AJ' },
    { word: 'assistance', word_class: 'N' },
    { word: 'assistant', word_class: 'AJ' },
    { word: 'assistive', word_class: 'AJ' }
  ],
  blackmailer: [
    { word: 'blackmail', word_class: 'N' },
    { word: 'blackmail', word_class: 'V' },
    { word: 'blackmailer', word_class: 'N' }
  ],
  tough: [
    { word: 'tough', word_class: 'N' },
    { word: 'tough', word_class: 'AJ' },
    { word: 'toughness', word_class: 'N' }
  ],
  slate: [
    { word: 'slate', word_class: 'N' },
    { word: 'slate', word_class: 'V' },
    { word: 'slater', word_class: 'N' },
    { word: 'slating', word_class: 'N' }
  ],
  inside: [
    { word: 'inside', word_class: 'N' },
    { word: 'inside', word_class: 'V' },
    { word: 'inside', word_class: 'AJ' },
    { word: 'inside', word_class: 'AV' },
    { word: 'insider', word_class: 'N' }
  ],
  externality: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  ambiguously: [
    { word: 'ambiguity', word_class: 'N' },
    { word: 'ambiguous', word_class: 'AJ' },
    { word: 'ambiguously', word_class: 'AV' }
  ],
  concentrical: [
    { word: 'concentrate', word_class: 'N' },
    { word: 'concentrate', word_class: 'V' },
    { word: 'concentric', word_class: 'AJ' },
    { word: 'concentrated', word_class: 'AJ' },
    { word: 'concentration', word_class: 'N' },
    { word: 'concentrical', word_class: 'AJ' },
    { word: 'concentricity', word_class: 'N' }
  ],
  elaborated: [
    { word: 'elaborate', word_class: 'V' },
    { word: 'elaborate', word_class: 'AJ' },
    { word: 'elaborated', word_class: 'AJ' },
    { word: 'elaboration', word_class: 'N' },
    { word: 'elaborately', word_class: 'AV' },
    { word: 'elaborateness', word_class: 'N' }
  ],
  agreeability: [
    { word: 'agreeable', word_class: 'AJ' },
    { word: 'agreeably', word_class: 'AV' },
    { word: 'agreeability', word_class: 'N' },
    { word: 'agreeableness', word_class: 'N' }
  ],
  ventriloquise: [
    { word: 'ventriloquise', word_class: 'V' },
    { word: 'ventriloquism', word_class: 'N' },
    { word: 'ventriloquize', word_class: 'V' },
    { word: 'ventriloquization', word_class: 'N' }
  ],
  poetics: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  diving: [
    { word: 'dive', word_class: 'N' },
    { word: 'dive', word_class: 'V' },
    { word: 'diver', word_class: 'N' },
    { word: 'diving', word_class: 'N' },
    { word: 'divers', word_class: 'AJ' }
  ],
  deferred: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  scarceness: [
    { word: 'scarce', word_class: 'AJ' },
    { word: 'scarce', word_class: 'AV' },
    { word: 'scarcely', word_class: 'AV' },
    { word: 'scarceness', word_class: 'N' }
  ],
  heaped: [
    { word: 'heap', word_class: 'N' },
    { word: 'heap', word_class: 'V' },
    { word: 'heaps', word_class: 'N' },
    { word: 'heaps', word_class: 'AV' },
    { word: 'heaped', word_class: 'AJ' }
  ],
  pedaller: [
    { word: 'pedal', word_class: 'N' },
    { word: 'pedal', word_class: 'V' },
    { word: 'pedal', word_class: 'AJ' },
    { word: 'pedaler', word_class: 'N' },
    { word: 'pedaller', word_class: 'N' }
  ],
  attached: [
    { word: 'attach', word_class: 'V' },
    { word: 'attache', word_class: 'N' },
    { word: 'attached', word_class: 'AJ' },
    { word: 'attachment', word_class: 'N' },
    { word: 'attachable', word_class: 'AJ' }
  ],
  unquestionable: [
    { word: 'unquestionable', word_class: 'AJ' },
    { word: 'unquestionably', word_class: 'AV' },
    { word: 'unquestionability', word_class: 'N' },
    { word: 'unquestionableness', word_class: 'N' }
  ],
  backsliding: [
    { word: 'backslide', word_class: 'V' },
    { word: 'backslider', word_class: 'N' },
    { word: 'backsliding', word_class: 'N' }
  ],
  sacrilege: [
    { word: 'sacrilege', word_class: 'N' },
    { word: 'sacrilegious', word_class: 'AJ' },
    { word: 'sacrilegiously', word_class: 'AV' },
    { word: 'sacrilegiousness', word_class: 'N' }
  ],
  euphonical: [
    { word: 'euphonic', word_class: 'AJ' },
    { word: 'euphonize', word_class: 'V' },
    { word: 'euphonous', word_class: 'AJ' },
    { word: 'euphonical', word_class: 'AJ' },
    { word: 'euphonization', word_class: 'N' }
  ],
  protractor: [
    { word: 'protract', word_class: 'N' },
    { word: 'protract', word_class: 'V' },
    { word: 'protractor', word_class: 'N' },
    { word: 'protracted', word_class: 'AJ' },
    { word: 'protraction', word_class: 'N' },
    { word: 'protractible', word_class: 'AJ' }
  ],
  true: [
    { word: 'true', word_class: 'N' },
    { word: 'true', word_class: 'V' },
    { word: 'true', word_class: 'AJ' },
    { word: 'truth', word_class: 'N' },
    { word: 'true', word_class: 'AV' },
    { word: 'truly', word_class: 'AV' },
    { word: 'truthful', word_class: 'AJ' },
    { word: 'truthfulness', word_class: 'N' }
  ],
  inhabited: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  divestment: [
    { word: 'devest', word_class: 'V' },
    { word: 'divest', word_class: 'N' },
    { word: 'divest', word_class: 'V' },
    { word: 'divesture', word_class: 'N' },
    { word: 'divestment', word_class: 'N' },
    { word: 'divestiture', word_class: 'N' }
  ],
  sos: [
    { word: 'so', word_class: 'N' },
    { word: 'so', word_class: 'AJ' },
    { word: 'so', word_class: 'AV' },
    { word: 'sos', word_class: 'N' }
  ],
  assert: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  maltman: [
    { word: 'malt', word_class: 'N' },
    { word: 'malt', word_class: 'V' },
    { word: 'malted', word_class: 'N' },
    { word: 'maltman', word_class: 'N' }
  ],
  strengthener: [
    { word: 'strong', word_class: 'AJ' },
    { word: 'strengthen', word_class: 'V' },
    { word: 'strengthener', word_class: 'N' },
    { word: 'strengthened', word_class: 'AJ' },
    { word: 'strengthening', word_class: 'N' },
    { word: 'strengthening', word_class: 'AJ' }
  ],
  palatalize: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  orthopedic: [
    { word: 'orthopedic', word_class: 'AJ' },
    { word: 'orthopedics', word_class: 'N' },
    { word: 'orthopedical', word_class: 'AJ' }
  ],
  provider: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  attributively: [
    { word: 'attribute', word_class: 'N' },
    { word: 'attribute', word_class: 'V' },
    { word: 'attribution', word_class: 'N' },
    { word: 'attributive', word_class: 'AJ' },
    { word: 'attributable', word_class: 'AJ' },
    { word: 'attributively', word_class: 'AV' }
  ],
  rusticity: [
    { word: 'rustic', word_class: 'N' },
    { word: 'rustic', word_class: 'AJ' },
    { word: 'rusticate', word_class: 'V' },
    { word: 'rusticity', word_class: 'N' },
    { word: 'rustication', word_class: 'N' }
  ],
  trusting: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  sputter: [
    { word: 'sputter', word_class: 'N' },
    { word: 'sputter', word_class: 'V' },
    { word: 'sputtering', word_class: 'N' },
    { word: 'sputtering', word_class: 'AJ' }
  ],
  delivery: [
    { word: 'deliver', word_class: 'N' },
    { word: 'deliver', word_class: 'V' },
    { word: 'delivery', word_class: 'N' },
    { word: 'deliverer', word_class: 'N' },
    { word: 'delivery', word_class: 'AJ' },
    { word: 'deliverance', word_class: 'N' }
  ],
  digestive: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  unnerved: [
    { word: 'unnerve', word_class: 'V' },
    { word: 'unnerved', word_class: 'AJ' },
    { word: 'unnerving', word_class: 'AJ' }
  ],
  curarise: [
    { word: 'curare', word_class: 'N' },
    { word: 'curarise', word_class: 'V' },
    { word: 'curarize', word_class: 'V' },
    { word: 'curarisation', word_class: 'N' },
    { word: 'curarization', word_class: 'N' }
  ],
  invade: [
    { word: 'invade', word_class: 'V' },
    { word: 'invader', word_class: 'N' },
    { word: 'invasion', word_class: 'N' },
    { word: 'invading', word_class: 'AJ' },
    { word: 'invasive', word_class: 'AJ' }
  ],
  seasoned: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  resign: [
    { word: 'resign', word_class: 'N' },
    { word: 'resign', word_class: 'V' },
    { word: 'resigned', word_class: 'AJ' },
    { word: 'resignation', word_class: 'N' }
  ],
  darraignment: [
    { word: 'deraign', word_class: 'V' },
    { word: 'darraign', word_class: 'V' },
    { word: 'deraignment', word_class: 'N' },
    { word: 'darraignment', word_class: 'N' }
  ],
  transversally: [
    { word: 'transverse', word_class: 'N' },
    { word: 'transverse', word_class: 'V' },
    { word: 'transversal', word_class: 'N' },
    { word: 'transverse', word_class: 'AJ' },
    { word: 'transversal', word_class: 'AJ' },
    { word: 'transversely', word_class: 'AV' },
    { word: 'transversally', word_class: 'AV' }
  ],
  soaring: [
    { word: 'soar', word_class: 'N' },
    { word: 'soar', word_class: 'V' },
    { word: 'soaring', word_class: 'N' },
    { word: 'soaring', word_class: 'AJ' }
  ],
  chariot: [
    { word: 'chariot', word_class: 'N' },
    { word: 'chariot', word_class: 'V' },
    { word: 'charioteer', word_class: 'N' }
  ],
  spiraling: [
    { word: 'spiral', word_class: 'N' },
    { word: 'spiral', word_class: 'V' },
    { word: 'spiral', word_class: 'AJ' },
    { word: 'spirally', word_class: 'AV' },
    { word: 'spiraling', word_class: 'AJ' }
  ],
  grim: [
    { word: 'grime', word_class: 'N' },
    { word: 'grim', word_class: 'AJ' },
    { word: 'grime', word_class: 'V' },
    { word: 'grimy', word_class: 'AJ' },
    { word: 'grimness', word_class: 'N' },
    { word: 'griminess', word_class: 'N' }
  ],
  controller: [
    { word: 'control', word_class: 'N' },
    { word: 'control', word_class: 'V' },
    { word: 'controller', word_class: 'N' },
    { word: 'controlled', word_class: 'AJ' },
    { word: 'controlling', word_class: 'N' },
    { word: 'controlling', word_class: 'AJ' },
    { word: 'controllable', word_class: 'AJ' }
  ],
  reshuffling: [
    { word: 'reshuffle', word_class: 'N' },
    { word: 'reshuffle', word_class: 'V' },
    { word: 'reshuffling', word_class: 'N' }
  ],
  advertise: [
    { word: 'advertise', word_class: 'V' },
    { word: 'advertiser', word_class: 'N' },
    { word: 'advertised', word_class: 'AJ' },
    { word: 'advertising', word_class: 'N' },
    { word: 'advertisement', word_class: 'N' }
  ],
  looter: [
    { word: 'loot', word_class: 'N' },
    { word: 'loot', word_class: 'V' },
    { word: 'looter', word_class: 'N' },
    { word: 'looted', word_class: 'AJ' },
    { word: 'looting', word_class: 'N' }
  ],
  blankness: [
    { word: 'blank', word_class: 'N' },
    { word: 'blank', word_class: 'V' },
    { word: 'blank', word_class: 'AJ' },
    { word: 'blankness', word_class: 'N' }
  ],
  mouth: [
    { word: 'mouth', word_class: 'N' },
    { word: 'mouth', word_class: 'V' },
    { word: 'mouthed', word_class: 'AJ' },
    { word: 'mouthful', word_class: 'N' },
    { word: 'mouthful', word_class: 'AJ' }
  ],
  dialectal: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  innocency: [
    { word: 'innocent', word_class: 'N' },
    { word: 'innocence', word_class: 'N' },
    { word: 'innocency', word_class: 'N' },
    { word: 'innocent', word_class: 'AJ' },
    { word: 'innocently', word_class: 'AV' }
  ],
  adhesive: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  pragmatically: [
    { word: 'pragmatic', word_class: 'AJ' },
    { word: 'pragmatics', word_class: 'N' },
    { word: 'pragmatism', word_class: 'N' },
    { word: 'pragmatical', word_class: 'AJ' },
    { word: 'pragmatically', word_class: 'AV' }
  ],
  polytonal: [
    { word: 'polytonal', word_class: 'AJ' },
    { word: 'polytonalism', word_class: 'N' },
    { word: 'polytonality', word_class: 'N' }
  ],
  mutability: [
    { word: 'mutable', word_class: 'AJ' },
    { word: 'mutably', word_class: 'AV' },
    { word: 'mutability', word_class: 'N' },
    { word: 'mutableness', word_class: 'N' }
  ],
  machination: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  representative: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  grumble: [
    { word: 'grumble', word_class: 'N' },
    { word: 'grumble', word_class: 'V' },
    { word: 'grumbler', word_class: 'N' },
    { word: 'grumbling', word_class: 'N' },
    { word: 'grumbling', word_class: 'AJ' }
  ],
  transparentness: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  blistering: [
    { word: 'blister', word_class: 'N' },
    { word: 'blister', word_class: 'V' },
    { word: 'blistered', word_class: 'AJ' },
    { word: 'blistering', word_class: 'AJ' }
  ],
  angiocarp: [
    { word: 'angiocarp', word_class: 'N' },
    { word: 'angiocarpic', word_class: 'AJ' },
    { word: 'angiocarpous', word_class: 'AJ' }
  ],
  counteraction: [
    { word: 'counteract', word_class: 'V' },
    { word: 'counteraction', word_class: 'N' },
    { word: 'counteractive', word_class: 'AJ' }
  ],
  lordship: [
    { word: 'lord', word_class: 'V' },
    { word: 'lord', word_class: 'N' },
    { word: 'lordship', word_class: 'N' }
  ],
  ingest: [
    { word: 'ingest', word_class: 'V' },
    { word: 'ingested', word_class: 'AJ' },
    { word: 'ingestion', word_class: 'N' }
  ],
  item: [
    { word: 'item', word_class: 'N' },
    { word: 'item', word_class: 'AV' },
    { word: 'itemise', word_class: 'V' },
    { word: 'itemize', word_class: 'V' },
    { word: 'itemisation', word_class: 'N' },
    { word: 'itemization', word_class: 'N' }
  ],
  publishing: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  recede: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  prematureness: [
    { word: 'premature', word_class: 'AJ' },
    { word: 'prematurity', word_class: 'N' },
    { word: 'prematurely', word_class: 'AV' },
    { word: 'prematureness', word_class: 'N' }
  ],
  unhorsed: [
    { word: 'unhorse', word_class: 'N' },
    { word: 'unhorse', word_class: 'V' },
    { word: 'unhorsed', word_class: 'AJ' }
  ],
  resourcefulness: [
    { word: 'resource', word_class: 'N' },
    { word: 'resourceful', word_class: 'AJ' },
    { word: 'resourcefulness', word_class: 'N' }
  ],
  corded: [
    { word: 'cord', word_class: 'N' },
    { word: 'cord', word_class: 'V' },
    { word: 'cords', word_class: 'N' },
    { word: 'corded', word_class: 'AJ' },
    { word: 'cording', word_class: 'N' },
    { word: 'cordite', word_class: 'N' }
  ],
  crave: [
    { word: 'crave', word_class: 'V' },
    { word: 'craved', word_class: 'AJ' },
    { word: 'craving', word_class: 'N' }
  ],
  lean: [
    { word: 'lean', word_class: 'N' },
    { word: 'lean', word_class: 'V' },
    { word: 'lean', word_class: 'AJ' },
    { word: 'leaning', word_class: 'N' },
    { word: 'leaning', word_class: 'AJ' },
    { word: 'leanness', word_class: 'N' }
  ],
  artistry: [
    { word: 'art', word_class: 'N' },
    { word: 'arts', word_class: 'N' },
    { word: 'artful', word_class: 'AJ' },
    { word: 'artistry', word_class: 'N' },
    { word: 'artfulness', word_class: 'N' }
  ],
  excavator: [
    { word: 'excavate', word_class: 'V' },
    { word: 'excavator', word_class: 'N' },
    { word: 'excavation', word_class: 'N' }
  ],
  protesting: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  head: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  anthropocentric: [
    { word: 'anthropocentric', word_class: 'AJ' },
    { word: 'anthropocentrism', word_class: 'N' },
    { word: 'anthropocentricity', word_class: 'N' }
  ],
  military: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  sophisticate: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  enteral: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  manage: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  oblique: [
    { word: 'oblique', word_class: 'N' },
    { word: 'oblique', word_class: 'AJ' },
    { word: 'obliquity', word_class: 'N' },
    { word: 'obliquely', word_class: 'AV' },
    { word: 'obliqueness', word_class: 'N' }
  ],
  predigested: [
    { word: 'predigest', word_class: 'V' },
    { word: 'predigested', word_class: 'AJ' },
    { word: 'predigestion', word_class: 'N' }
  ],
  maceration: [
    { word: 'macer', word_class: 'N' },
    { word: 'macerate', word_class: 'V' },
    { word: 'maceration', word_class: 'N' },
    { word: 'macerative', word_class: 'AJ' }
  ],
  ravaging: [
    { word: 'ravage', word_class: 'N' },
    { word: 'ravage', word_class: 'V' },
    { word: 'ravaged', word_class: 'AJ' },
    { word: 'ravaging', word_class: 'N' },
    { word: 'ravaging', word_class: 'AJ' },
    { word: 'ravagement', word_class: 'N' }
  ],
  expansion: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  mercurialise: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  putting: [
    { word: 'put', word_class: 'N' },
    { word: 'put', word_class: 'V' },
    { word: 'putting', word_class: 'N' },
    { word: 'putative', word_class: 'AJ' }
  ],
  inflated: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  disarrange: [
    { word: 'disarrange', word_class: 'V' },
    { word: 'disarranged', word_class: 'AJ' },
    { word: 'disarrangement', word_class: 'N' }
  ],
  staged: [
    { word: 'stage', word_class: 'N' },
    { word: 'stage', word_class: 'V' },
    { word: 'stager', word_class: 'N' },
    { word: 'staged', word_class: 'AJ' },
    { word: 'staging', word_class: 'N' }
  ],
  inducive: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  mohammedan: [
    { word: 'mohammedan', word_class: 'N' },
    { word: 'mohammedan', word_class: 'AJ' },
    { word: 'mohammedanism', word_class: 'N' }
  ],
  flower: [
    { word: 'flower', word_class: 'N' },
    { word: 'flower', word_class: 'V' },
    { word: 'floral', word_class: 'AJ' },
    { word: 'florist', word_class: 'N' },
    { word: 'flowered', word_class: 'AJ' },
    { word: 'flowering', word_class: 'N' },
    { word: 'flowering', word_class: 'AJ' }
  ],
  riveting: [
    { word: 'rivet', word_class: 'N' },
    { word: 'rivet', word_class: 'V' },
    { word: 'riveter', word_class: 'N' },
    { word: 'riveting', word_class: 'AJ' }
  ],
  dignified: [
    { word: 'dignify', word_class: 'V' },
    { word: 'dignified', word_class: 'AJ' },
    { word: 'dignifying', word_class: 'AJ' },
    { word: 'dignification', word_class: 'N' }
  ],
  kindly: [
    { word: 'kind', word_class: 'N' },
    { word: 'kind', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AV' },
    { word: 'kindness', word_class: 'N' },
    { word: 'kindliness', word_class: 'N' }
  ],
  unsubstantial: [
    { word: 'unsubstantial', word_class: 'AJ' },
    { word: 'unsubstantialize', word_class: 'V' },
    { word: 'unsubstantiated', word_class: 'AJ' }
  ],
  neck: [
    { word: 'neck', word_class: 'N' },
    { word: 'neck', word_class: 'V' },
    { word: 'necked', word_class: 'AJ' },
    { word: 'necking', word_class: 'N' }
  ],
  nosy: [
    { word: 'nose', word_class: 'N' },
    { word: 'nose', word_class: 'V' },
    { word: 'nosy', word_class: 'AJ' },
    { word: 'nosed', word_class: 'AJ' },
    { word: 'nosiness', word_class: 'N' }
  ],
  dexterous: [
    { word: 'dexter', word_class: 'AJ' },
    { word: 'dexterity', word_class: 'N' },
    { word: 'dexterous', word_class: 'AJ' },
    { word: 'dexterously', word_class: 'AV' }
  ],
  indigenous: [
    { word: 'indigene', word_class: 'N' },
    { word: 'indigenous', word_class: 'AJ' },
    { word: 'indigenousness', word_class: 'N' }
  ],
  eluding: [
    { word: 'elude', word_class: 'V' },
    { word: 'eluding', word_class: 'N' },
    { word: 'elusion', word_class: 'N' },
    { word: 'elusive', word_class: 'AJ' },
    { word: 'elusiveness', word_class: 'N' }
  ],
  testification: [
    { word: 'testify', word_class: 'V' },
    { word: 'testifier', word_class: 'N' },
    { word: 'testification', word_class: 'N' }
  ],
  ane: [{ word: 'an', word_class: 'N' }, { word: 'ans', word_class: 'N' }, { word: 'ane', word_class: 'AJ' }],
  penuriousness: [
    { word: 'penury', word_class: 'N' },
    { word: 'penurious', word_class: 'AJ' },
    { word: 'penuriously', word_class: 'AV' },
    { word: 'penuriousness', word_class: 'N' }
  ],
  stifled: [
    { word: 'stifle', word_class: 'N' },
    { word: 'stifle', word_class: 'V' },
    { word: 'stifled', word_class: 'AJ' },
    { word: 'stifling', word_class: 'N' },
    { word: 'stifling', word_class: 'AJ' }
  ],
  gorge: [{ word: 'gorge', word_class: 'N' }, { word: 'gorge', word_class: 'V' }, { word: 'gorged', word_class: 'AJ' }],
  essential: [
    { word: 'essential', word_class: 'N' },
    { word: 'essential', word_class: 'AJ' },
    { word: 'essentiality', word_class: 'N' },
    { word: 'essentially', word_class: 'AV' },
    { word: 'essentialness', word_class: 'N' }
  ],
  testator: [
    { word: 'testacy', word_class: 'N' },
    { word: 'testate', word_class: 'N' },
    { word: 'testate', word_class: 'AJ' },
    { word: 'testator', word_class: 'N' }
  ],
  recurve: [
    { word: 'recurve', word_class: 'V' },
    { word: 'recurved', word_class: 'AJ' },
    { word: 'recurvate', word_class: 'AJ' }
  ],
  micturation: [
    { word: 'micturate', word_class: 'V' },
    { word: 'micturation', word_class: 'N' },
    { word: 'micturition', word_class: 'N' }
  ],
  bowls: [
    { word: 'bowl', word_class: 'N' },
    { word: 'bowl', word_class: 'V' },
    { word: 'bowls', word_class: 'N' },
    { word: 'bowler', word_class: 'N' },
    { word: 'bowlful', word_class: 'N' },
    { word: 'bowling', word_class: 'N' },
    { word: 'bowlful', word_class: 'AJ' }
  ],
  transduction: [
    { word: 'transduce', word_class: 'V' },
    { word: 'transducer', word_class: 'N' },
    { word: 'transduction', word_class: 'N' }
  ],
  naturally: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  unthoughtfulness: [
    { word: 'unthought', word_class: 'AJ' },
    { word: 'unthoughtful', word_class: 'AJ' },
    { word: 'unthoughtfulness', word_class: 'N' }
  ],
  parsimony: [
    { word: 'parsimony', word_class: 'N' },
    { word: 'parsimonious', word_class: 'AJ' },
    { word: 'parsimoniousness', word_class: 'N' }
  ],
  donee: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  presume: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  deplete: [
    { word: 'deplete', word_class: 'V' },
    { word: 'depleted', word_class: 'AJ' },
    { word: 'depletion', word_class: 'N' },
    { word: 'depletable', word_class: 'AJ' }
  ],
  braided: [
    { word: 'braid', word_class: 'N' },
    { word: 'braid', word_class: 'V' },
    { word: 'braided', word_class: 'AJ' },
    { word: 'braiding', word_class: 'N' }
  ],
  aristocratical: [
    { word: 'aristocrat', word_class: 'N' },
    { word: 'aristocracy', word_class: 'N' },
    { word: 'aristocratic', word_class: 'AJ' },
    { word: 'aristocratical', word_class: 'AJ' },
    { word: 'aristocratically', word_class: 'AV' }
  ],
  transmittal: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  metaphysics: [
    { word: 'metaphysics', word_class: 'N' },
    { word: 'metaphysical', word_class: 'AJ' },
    { word: 'metaphysically', word_class: 'AV' }
  ],
  categorial: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  buss: [
    { word: 'bus', word_class: 'N' },
    { word: 'bus', word_class: 'V' },
    { word: 'buss', word_class: 'N' },
    { word: 'buss', word_class: 'V' },
    { word: 'busful', word_class: 'N' },
    { word: 'busman', word_class: 'N' },
    { word: 'busful', word_class: 'AJ' },
    { word: 'bussing', word_class: 'N' }
  ],
  circumscribe: [
    { word: 'circumscribe', word_class: 'N' },
    { word: 'circumscribe', word_class: 'V' },
    { word: 'circumscribed', word_class: 'AJ' },
    { word: 'circumscription', word_class: 'N' }
  ],
  desensitization: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  concealed: [
    { word: 'conceal', word_class: 'V' },
    { word: 'concealed', word_class: 'AJ' },
    { word: 'concealing', word_class: 'N' },
    { word: 'concealing', word_class: 'AJ' },
    { word: 'concealment', word_class: 'N' }
  ],
  exudation: [
    { word: 'exude', word_class: 'V' },
    { word: 'exudate', word_class: 'N' },
    { word: 'exudate', word_class: 'V' },
    { word: 'exudation', word_class: 'N' }
  ],
  polisher: [
    { word: 'polish', word_class: 'N' },
    { word: 'polish', word_class: 'V' },
    { word: 'polish', word_class: 'AJ' },
    { word: 'polisher', word_class: 'N' },
    { word: 'polished', word_class: 'AJ' },
    { word: 'polishing', word_class: 'N' }
  ],
  sophist: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  buried: [
    { word: 'bury', word_class: 'V' },
    { word: 'burial', word_class: 'N' },
    { word: 'burier', word_class: 'N' },
    { word: 'buried', word_class: 'AJ' },
    { word: 'burying', word_class: 'N' }
  ],
  husbandry: [
    { word: 'husband', word_class: 'N' },
    { word: 'husband', word_class: 'V' },
    { word: 'husbandry', word_class: 'N' },
    { word: 'husbandman', word_class: 'N' }
  ],
  armed: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  pension: [
    { word: 'pension', word_class: 'N' },
    { word: 'pension', word_class: 'V' },
    { word: 'pensioner', word_class: 'N' },
    { word: 'pensionable', word_class: 'AJ' }
  ],
  fired: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  stitcher: [
    { word: 'stitch', word_class: 'N' },
    { word: 'stitch', word_class: 'V' },
    { word: 'stitcher', word_class: 'N' },
    { word: 'stitched', word_class: 'AJ' },
    { word: 'stitching', word_class: 'N' }
  ],
  recurvate: [
    { word: 'recurve', word_class: 'V' },
    { word: 'recurved', word_class: 'AJ' },
    { word: 'recurvate', word_class: 'AJ' }
  ],
  depictive: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  diagnosis: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  estimable: [
    { word: 'estimate', word_class: 'N' },
    { word: 'estimate', word_class: 'V' },
    { word: 'estimator', word_class: 'N' },
    { word: 'estimable', word_class: 'AJ' },
    { word: 'estimated', word_class: 'AJ' },
    { word: 'estimation', word_class: 'N' }
  ],
  sodding: [{ word: 'sod', word_class: 'N' }, { word: 'sod', word_class: 'V' }, { word: 'sodding', word_class: 'AJ' }],
  payment: [
    { word: 'pay', word_class: 'N' },
    { word: 'pay', word_class: 'V' },
    { word: 'payee', word_class: 'N' },
    { word: 'payer', word_class: 'N' },
    { word: 'paying', word_class: 'N' },
    { word: 'paying', word_class: 'AJ' },
    { word: 'payment', word_class: 'N' }
  ],
  tutorial: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  founding: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  pedantic: [
    { word: 'pedant', word_class: 'N' },
    { word: 'pedantry', word_class: 'N' },
    { word: 'pedantic', word_class: 'AJ' },
    { word: 'pedantically', word_class: 'AV' }
  ],
  formulate: [
    { word: 'formulate', word_class: 'V' },
    { word: 'formulize', word_class: 'V' },
    { word: 'formulated', word_class: 'AJ' },
    { word: 'formulation', word_class: 'N' },
    { word: 'formulization', word_class: 'N' }
  ],
  animation: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  masochistically: [
    { word: 'masochist', word_class: 'N' },
    { word: 'masochistic', word_class: 'AJ' },
    { word: 'masochistically', word_class: 'AV' }
  ],
  discharge: [
    { word: 'discharge', word_class: 'N' },
    { word: 'discharge', word_class: 'V' },
    { word: 'discharged', word_class: 'AJ' }
  ],
  flattering: [
    { word: 'flatter', word_class: 'V' },
    { word: 'flattery', word_class: 'N' },
    { word: 'flatterer', word_class: 'N' },
    { word: 'flattering', word_class: 'AJ' }
  ],
  ravish: [
    { word: 'ravish', word_class: 'V' },
    { word: 'ravisher', word_class: 'N' },
    { word: 'ravishing', word_class: 'AJ' },
    { word: 'ravishment', word_class: 'N' }
  ],
  neurobiological: [
    { word: 'neurobiology', word_class: 'N' },
    { word: 'neurobiological', word_class: 'AJ' },
    { word: 'neurobiological', word_class: 'AV' }
  ],
  vulgarize: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  lactating: [
    { word: 'lactate', word_class: 'V' },
    { word: 'lactation', word_class: 'N' },
    { word: 'lactating', word_class: 'AJ' }
  ],
  braising: [
    { word: 'braise', word_class: 'V' },
    { word: 'braised', word_class: 'AJ' },
    { word: 'braising', word_class: 'N' }
  ],
  darkness: [
    { word: 'dark', word_class: 'N' },
    { word: 'dark', word_class: 'AJ' },
    { word: 'darkness', word_class: 'N' }
  ],
  prostate: [
    { word: 'prostate', word_class: 'N' },
    { word: 'prostate', word_class: 'V' },
    { word: 'prostate', word_class: 'AJ' },
    { word: 'prostatic', word_class: 'AJ' },
    { word: 'prostatitis', word_class: 'N' }
  ],
  euphemism: [
    { word: 'euphemism', word_class: 'N' },
    { word: 'euphemize', word_class: 'V' },
    { word: 'euphemization', word_class: 'N' }
  ],
  immatureness: [
    { word: 'immature', word_class: 'AJ' },
    { word: 'immaturity', word_class: 'N' },
    { word: 'immaturely', word_class: 'AV' },
    { word: 'immatureness', word_class: 'N' }
  ],
  cityfication: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  contracted: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  idly: [
    { word: 'idle', word_class: 'V' },
    { word: 'idle', word_class: 'AJ' },
    { word: 'idler', word_class: 'N' },
    { word: 'idly', word_class: 'AV' },
    { word: 'idling', word_class: 'N' },
    { word: 'idleness', word_class: 'N' }
  ],
  regimentation: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  dummy: [{ word: 'dummy', word_class: 'N' }, { word: 'dummy', word_class: 'V' }, { word: 'dummy', word_class: 'AJ' }],
  perpendicularity: [
    { word: 'perpendicular', word_class: 'N' },
    { word: 'perpendicular', word_class: 'AJ' },
    { word: 'perpendicularity', word_class: 'N' }
  ],
  affixal: [
    { word: 'affix', word_class: 'N' },
    { word: 'affix', word_class: 'V' },
    { word: 'affixal', word_class: 'AJ' },
    { word: 'affixed', word_class: 'AJ' },
    { word: 'affixation', word_class: 'N' }
  ],
  traded: [
    { word: 'trade', word_class: 'N' },
    { word: 'trade', word_class: 'V' },
    { word: 'trade', word_class: 'AJ' },
    { word: 'trader', word_class: 'N' },
    { word: 'trading', word_class: 'N' },
    { word: 'traded', word_class: 'AJ' }
  ],
  structuralize: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  internalization: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  tenderness: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  smiler: [
    { word: 'smile', word_class: 'N' },
    { word: 'smile', word_class: 'V' },
    { word: 'smiler', word_class: 'N' },
    { word: 'smiling', word_class: 'N' },
    { word: 'smiling', word_class: 'AJ' }
  ],
  mettlesomeness: [
    { word: 'mettle', word_class: 'N' },
    { word: 'mettlesome', word_class: 'AJ' },
    { word: 'mettlesomeness', word_class: 'N' }
  ],
  remotion: [
    { word: 'remote', word_class: 'N' },
    { word: 'remote', word_class: 'AJ' },
    { word: 'remotion', word_class: 'N' },
    { word: 'remotely', word_class: 'AV' },
    { word: 'remoteness', word_class: 'N' }
  ],
  splotched: [
    { word: 'splotch', word_class: 'N' },
    { word: 'splotch', word_class: 'V' },
    { word: 'splotched', word_class: 'AJ' }
  ],
  aggrieved: [
    { word: 'aggrieve', word_class: 'V' },
    { word: 'aggrieved', word_class: 'AJ' },
    { word: 'aggrievement', word_class: 'N' }
  ],
  synecdochical: [
    { word: 'synecdoche', word_class: 'N' },
    { word: 'synecdochic', word_class: 'AJ' },
    { word: 'synecdochical', word_class: 'AJ' }
  ],
  flaunt: [
    { word: 'flaunt', word_class: 'N' },
    { word: 'flaunt', word_class: 'V' },
    { word: 'flaunting', word_class: 'N' }
  ],
  foresighted: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  progression: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  publicized: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  reasoned: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  suitable: [
    { word: 'suitable', word_class: 'AJ' },
    { word: 'suitably', word_class: 'AV' },
    { word: 'suitability', word_class: 'N' },
    { word: 'suitableness', word_class: 'N' }
  ],
  countrify: [
    { word: 'country', word_class: 'N' },
    { word: 'country', word_class: 'V' },
    { word: 'country', word_class: 'AJ' },
    { word: 'countrify', word_class: 'V' },
    { word: 'countrified', word_class: 'AJ' }
  ],
  beneficiation: [
    { word: 'beneficial', word_class: 'AJ' },
    { word: 'beneficially', word_class: 'AV' },
    { word: 'beneficiation', word_class: 'N' }
  ],
  retaking: [
    { word: 'retake', word_class: 'N' },
    { word: 'retake', word_class: 'V' },
    { word: 'retaking', word_class: 'N' }
  ],
  exercising: [
    { word: 'exercise', word_class: 'N' },
    { word: 'exercise', word_class: 'V' },
    { word: 'exerciser', word_class: 'N' },
    { word: 'exercising', word_class: 'N' },
    { word: 'exercitation', word_class: 'N' }
  ],
  bodybuilding: [
    { word: 'bodybuild', word_class: 'V' },
    { word: 'bodybuilder', word_class: 'N' },
    { word: 'bodybuilding', word_class: 'N' }
  ],
  serological: [
    { word: 'serology', word_class: 'N' },
    { word: 'serologic', word_class: 'AJ' },
    { word: 'serological', word_class: 'AJ' }
  ],
  wiseness: [
    { word: 'wise', word_class: 'N' },
    { word: 'wise', word_class: 'AJ' },
    { word: 'wisdom', word_class: 'N' },
    { word: 'wisely', word_class: 'AV' },
    { word: 'wiseness', word_class: 'N' }
  ],
  agitated: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  legibility: [
    { word: 'legible', word_class: 'AJ' },
    { word: 'legibly', word_class: 'AV' },
    { word: 'legibility', word_class: 'N' }
  ],
  alcoholise: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  pollen: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  putrefied: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  illegality: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  undying: [
    { word: 'undies', word_class: 'N' },
    { word: 'undyed', word_class: 'AJ' },
    { word: 'undying', word_class: 'AJ' }
  ],
  heavenwards: [
    { word: 'heavenward', word_class: 'AJ' },
    { word: 'heavenward', word_class: 'AV' },
    { word: 'heavenwards', word_class: 'AV' }
  ],
  normalise: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  nasalise: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  effusiveness: [
    { word: 'effuse', word_class: 'V' },
    { word: 'effusion', word_class: 'N' },
    { word: 'effusive', word_class: 'AJ' },
    { word: 'effusively', word_class: 'AV' },
    { word: 'effusiveness', word_class: 'N' }
  ],
  federally: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  wounding: [
    { word: 'wound', word_class: 'N' },
    { word: 'wound', word_class: 'V' },
    { word: 'wound', word_class: 'AJ' },
    { word: 'wounded', word_class: 'N' },
    { word: 'wounded', word_class: 'AJ' },
    { word: 'wounding', word_class: 'N' },
    { word: 'wounding', word_class: 'AJ' }
  ],
  denationalize: [
    { word: 'denationalise', word_class: 'V' },
    { word: 'denationalize', word_class: 'V' },
    { word: 'denationalisation', word_class: 'N' },
    { word: 'denationalization', word_class: 'N' }
  ],
  redness: [{ word: 'red', word_class: 'N' }, { word: 'red', word_class: 'AJ' }, { word: 'redness', word_class: 'N' }],
  isomerism: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  ideally: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  saponification: [
    { word: 'saponify', word_class: 'V' },
    { word: 'saponified', word_class: 'AJ' },
    { word: 'saponification', word_class: 'N' }
  ],
  smasher: [
    { word: 'smash', word_class: 'N' },
    { word: 'smash', word_class: 'V' },
    { word: 'smash', word_class: 'AV' },
    { word: 'smasher', word_class: 'N' },
    { word: 'smashed', word_class: 'AJ' },
    { word: 'smashing', word_class: 'N' },
    { word: 'smashing', word_class: 'AJ' }
  ],
  shattering: [
    { word: 'shatter', word_class: 'V' },
    { word: 'shattered', word_class: 'AJ' },
    { word: 'shattering', word_class: 'N' },
    { word: 'shattering', word_class: 'AJ' }
  ],
  homologic: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  adenoid: [
    { word: 'adenoid', word_class: 'N' },
    { word: 'adenoid', word_class: 'AJ' },
    { word: 'adenoidal', word_class: 'AJ' }
  ],
  superable: [
    { word: 'sup', word_class: 'N' },
    { word: 'sup', word_class: 'V' },
    { word: 'super', word_class: 'N' },
    { word: 'super', word_class: 'AJ' },
    { word: 'super', word_class: 'AV' },
    { word: 'supping', word_class: 'N' },
    { word: 'superable', word_class: 'AJ' }
  ],
  alikeness: [
    { word: 'alike', word_class: 'AJ' },
    { word: 'alike', word_class: 'AV' },
    { word: 'alikeness', word_class: 'N' }
  ],
  wan: [
    { word: 'wan', word_class: 'N' },
    { word: 'wan', word_class: 'V' },
    { word: 'wane', word_class: 'N' },
    { word: 'wane', word_class: 'V' },
    { word: 'wan', word_class: 'AJ' },
    { word: 'waning', word_class: 'N' },
    { word: 'waning', word_class: 'AJ' },
    { word: 'wanness', word_class: 'N' }
  ],
  disrespect: [
    { word: 'disrespect', word_class: 'N' },
    { word: 'disrespect', word_class: 'V' },
    { word: 'disrespectful', word_class: 'AJ' }
  ],
  carboxyl: [
    { word: 'carboxyl', word_class: 'N' },
    { word: 'carboxyl', word_class: 'AJ' },
    { word: 'carboxylate', word_class: 'V' },
    { word: 'carboxylic', word_class: 'AJ' },
    { word: 'carboxylation', word_class: 'N' }
  ],
  ether: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  kyanise: [
    { word: 'kyanise', word_class: 'V' },
    { word: 'kyanize', word_class: 'V' },
    { word: 'kyanisation', word_class: 'N' },
    { word: 'kyanization', word_class: 'N' }
  ],
  organism: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  inducement: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  borrower: [
    { word: 'borrow', word_class: 'V' },
    { word: 'borrower', word_class: 'N' },
    { word: 'borrowing', word_class: 'N' }
  ],
  recommend: [
    { word: 'recommend', word_class: 'V' },
    { word: 'recommended', word_class: 'AJ' },
    { word: 'recommendation', word_class: 'N' }
  ],
  tearing: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  vomiting: [
    { word: 'vomit', word_class: 'N' },
    { word: 'vomit', word_class: 'V' },
    { word: 'vomiting', word_class: 'N' },
    { word: 'vomitive', word_class: 'N' },
    { word: 'vomiturition', word_class: 'N' }
  ],
  petalous: [
    { word: 'petal', word_class: 'N' },
    { word: 'petaled', word_class: 'AJ' },
    { word: 'petalled', word_class: 'AJ' },
    { word: 'petalous', word_class: 'AJ' }
  ],
  tropic: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  penitently: [
    { word: 'penitent', word_class: 'N' },
    { word: 'penitence', word_class: 'N' },
    { word: 'penitent', word_class: 'AJ' },
    { word: 'penitently', word_class: 'AV' },
    { word: 'penitential', word_class: 'AJ' },
    { word: 'penitentially', word_class: 'AV' }
  ],
  teetotal: [
    { word: 'teetotal', word_class: 'AJ' },
    { word: 'teetotaling', word_class: 'N' },
    { word: 'teetotalism', word_class: 'N' }
  ],
  defective: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  comb: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  gutter: [
    { word: 'gutter', word_class: 'N' },
    { word: 'gutter', word_class: 'V' },
    { word: 'guttering', word_class: 'AJ' }
  ],
  radicalism: [
    { word: 'radical', word_class: 'N' },
    { word: 'radical', word_class: 'AJ' },
    { word: 'radicalism', word_class: 'N' },
    { word: 'radically', word_class: 'AV' }
  ],
  fruit: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  opposed: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  extrude: [
    { word: 'extrude', word_class: 'V' },
    { word: 'extrusion', word_class: 'N' },
    { word: 'extrusive', word_class: 'AJ' }
  ],
  fatness: [
    { word: 'fat', word_class: 'N' },
    { word: 'fat', word_class: 'V' },
    { word: 'fat', word_class: 'AJ' },
    { word: 'fatness', word_class: 'N' }
  ],
  sneaker: [
    { word: 'sneak', word_class: 'N' },
    { word: 'sneak', word_class: 'V' },
    { word: 'sneak', word_class: 'AJ' },
    { word: 'sneaker', word_class: 'N' },
    { word: 'sneaky', word_class: 'AJ' },
    { word: 'sneaking', word_class: 'AJ' },
    { word: 'sneakiness', word_class: 'N' }
  ],
  yellowed: [
    { word: 'yellow', word_class: 'N' },
    { word: 'yellow', word_class: 'V' },
    { word: 'yellow', word_class: 'AJ' },
    { word: 'yellowed', word_class: 'AJ' },
    { word: 'yellowness', word_class: 'N' }
  ],
  nostalgically: [
    { word: 'nostalgia', word_class: 'N' },
    { word: 'nostalgic', word_class: 'AJ' },
    { word: 'nostalgically', word_class: 'AV' }
  ],
  latched: [
    { word: 'latch', word_class: 'N' },
    { word: 'latch', word_class: 'V' },
    { word: 'latched', word_class: 'AJ' }
  ],
  positioning: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  winnings: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  parsimoniousness: [
    { word: 'parsimony', word_class: 'N' },
    { word: 'parsimonious', word_class: 'AJ' },
    { word: 'parsimoniousness', word_class: 'N' }
  ],
  wile: [{ word: 'wile', word_class: 'N' }, { word: 'wily', word_class: 'AJ' }, { word: 'wiliness', word_class: 'N' }],
  escheatage: [
    { word: 'escheat', word_class: 'N' },
    { word: 'escheat', word_class: 'V' },
    { word: 'escheatage', word_class: 'N' }
  ],
  asexual: [
    { word: 'asexual', word_class: 'AJ' },
    { word: 'asexuality', word_class: 'N' },
    { word: 'asexually', word_class: 'AV' },
    { word: 'nonsexual', word_class: 'AJ' }
  ],
  exteriorisation: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  tonicity: [
    { word: 'tonic', word_class: 'N' },
    { word: 'tonic', word_class: 'AJ' },
    { word: 'tonicity', word_class: 'N' }
  ],
  recipient: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  piratical: [
    { word: 'piracy', word_class: 'N' },
    { word: 'pirate', word_class: 'N' },
    { word: 'pirate', word_class: 'V' },
    { word: 'piratical', word_class: 'AJ' },
    { word: 'piratically', word_class: 'AV' }
  ],
  reliance: [
    { word: 'rely', word_class: 'V' },
    { word: 'relie', word_class: 'V' },
    { word: 'reliant', word_class: 'N' },
    { word: 'reliance', word_class: 'N' },
    { word: 'reliant', word_class: 'AJ' }
  ],
  cell: [
    { word: 'cell', word_class: 'N' },
    { word: 'cellular', word_class: 'AJ' },
    { word: 'cellularity', word_class: 'N' }
  ],
  photocopy: [
    { word: 'photocopy', word_class: 'N' },
    { word: 'photocopy', word_class: 'V' },
    { word: 'photocopier', word_class: 'N' }
  ],
  militarize: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  be: [{ word: 'be', word_class: 'N' }, { word: 'be', word_class: 'V' }, { word: 'being', word_class: 'N' }],
  melt: [
    { word: 'melt', word_class: 'N' },
    { word: 'melt', word_class: 'V' },
    { word: 'molten', word_class: 'AJ' },
    { word: 'meltage', word_class: 'N' },
    { word: 'melted', word_class: 'AJ' },
    { word: 'melting', word_class: 'N' },
    { word: 'melting', word_class: 'AJ' }
  ],
  mingling: [
    { word: 'mingle', word_class: 'V' },
    { word: 'mingling', word_class: 'N' },
    { word: 'mingling', word_class: 'AJ' }
  ],
  married: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  curved: [
    { word: 'curve', word_class: 'N' },
    { word: 'curve', word_class: 'V' },
    { word: 'curvy', word_class: 'AJ' },
    { word: 'curved', word_class: 'AJ' },
    { word: 'curving', word_class: 'AJ' },
    { word: 'curvature', word_class: 'N' }
  ],
  mope: [
    { word: 'mope', word_class: 'N' },
    { word: 'mope', word_class: 'V' },
    { word: 'moped', word_class: 'N' },
    { word: 'moped', word_class: 'V' },
    { word: 'mopes', word_class: 'N' }
  ],
  critique: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  school: [
    { word: 'school', word_class: 'N' },
    { word: 'school', word_class: 'V' },
    { word: 'schooling', word_class: 'N' },
    { word: 'schooled', word_class: 'AJ' },
    { word: 'schoolman', word_class: 'N' }
  ],
  proselytize: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  sculpt: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  frenzy: [
    { word: 'frenzy', word_class: 'N' },
    { word: 'frenzy', word_class: 'V' },
    { word: 'frenzied', word_class: 'AJ' }
  ],
  laureate: [
    { word: 'laureate', word_class: 'N' },
    { word: 'laureate', word_class: 'V' },
    { word: 'laureate', word_class: 'AJ' },
    { word: 'laureation', word_class: 'N' }
  ],
  contaminative: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  whacking: [
    { word: 'whack', word_class: 'N' },
    { word: 'whack', word_class: 'V' },
    { word: 'whacker', word_class: 'N' },
    { word: 'whacked', word_class: 'AJ' },
    { word: 'whacking', word_class: 'N' },
    { word: 'whacking', word_class: 'AJ' },
    { word: 'whacking', word_class: 'AV' }
  ],
  speculate: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  nominal: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  juiciness: [
    { word: 'juice', word_class: 'N' },
    { word: 'juice', word_class: 'V' },
    { word: 'juicer', word_class: 'N' },
    { word: 'juicy', word_class: 'AJ' },
    { word: 'juiciness', word_class: 'N' }
  ],
  roiled: [
    { word: 'roil', word_class: 'V' },
    { word: 'roiled', word_class: 'AJ' },
    { word: 'roiling', word_class: 'AJ' }
  ],
  consistently: [
    { word: 'consist', word_class: 'V' },
    { word: 'consistence', word_class: 'N' },
    { word: 'consistency', word_class: 'N' },
    { word: 'consistent', word_class: 'AJ' },
    { word: 'consistently', word_class: 'AV' }
  ],
  coordinative: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  inaccessible: [
    { word: 'inaccessible', word_class: 'AJ' },
    { word: 'inaccessibly', word_class: 'AV' },
    { word: 'inaccessibility', word_class: 'N' }
  ],
  outsize: [
    { word: 'outsize', word_class: 'N' },
    { word: 'outsize', word_class: 'AJ' },
    { word: 'outsized', word_class: 'AJ' }
  ],
  severance: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  requisite: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  convalescence: [
    { word: 'convalesce', word_class: 'V' },
    { word: 'convalescent', word_class: 'N' },
    { word: 'convalescence', word_class: 'N' },
    { word: 'convalescent', word_class: 'AJ' }
  ],
  shirt: [
    { word: 'shirt', word_class: 'N' },
    { word: 'shirt', word_class: 'V' },
    { word: 'shirting', word_class: 'N' }
  ],
  tucked: [
    { word: 'tuck', word_class: 'N' },
    { word: 'tuck', word_class: 'V' },
    { word: 'tucker', word_class: 'N' },
    { word: 'tucker', word_class: 'V' },
    { word: 'tucked', word_class: 'AJ' }
  ],
  jewelled: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  sedate: [
    { word: 'sedate', word_class: 'V' },
    { word: 'sedate', word_class: 'AJ' },
    { word: 'sedated', word_class: 'AJ' },
    { word: 'sedation', word_class: 'N' },
    { word: 'sedately', word_class: 'AV' },
    { word: 'sedateness', word_class: 'N' }
  ],
  devices: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  frosted: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  pawn: [
    { word: 'pawn', word_class: 'N' },
    { word: 'pawn', word_class: 'V' },
    { word: 'pawnee', word_class: 'N' },
    { word: 'pawnage', word_class: 'N' },
    { word: 'pawning', word_class: 'N' }
  ],
  clothe: [
    { word: 'cloth', word_class: 'N' },
    { word: 'clothe', word_class: 'V' },
    { word: 'clothes', word_class: 'N' },
    { word: 'clothed', word_class: 'AJ' },
    { word: 'clothing', word_class: 'N' }
  ],
  stomacher: [
    { word: 'stomach', word_class: 'N' },
    { word: 'stomach', word_class: 'V' },
    { word: 'stomacher', word_class: 'N' },
    { word: 'stomachal', word_class: 'AJ' },
    { word: 'stomachic', word_class: 'AJ' }
  ],
  excreting: [
    { word: 'excrete', word_class: 'N' },
    { word: 'excrete', word_class: 'V' },
    { word: 'excreting', word_class: 'N' },
    { word: 'excretion', word_class: 'N' }
  ],
  promised: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  liquifiable: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  capriciousness: [
    { word: 'caprice', word_class: 'N' },
    { word: 'capricious', word_class: 'AJ' },
    { word: 'capriciously', word_class: 'AV' },
    { word: 'capriciousness', word_class: 'N' }
  ],
  rationalist: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  hydrogenisation: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  inventor: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  randomness: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  rarefied: [
    { word: 'rarefy', word_class: 'V' },
    { word: 'rarefied', word_class: 'AJ' },
    { word: 'rarefication', word_class: 'N' }
  ],
  antidotal: [
    { word: 'antidote', word_class: 'N' },
    { word: 'antidote', word_class: 'V' },
    { word: 'antidotal', word_class: 'AJ' }
  ],
  fielding: [
    { word: 'field', word_class: 'N' },
    { word: 'field', word_class: 'V' },
    { word: 'fielder', word_class: 'N' },
    { word: 'fielding', word_class: 'N' }
  ],
  muscular: [
    { word: 'muscle', word_class: 'N' },
    { word: 'muscle', word_class: 'V' },
    { word: 'muscled', word_class: 'AJ' },
    { word: 'muscular', word_class: 'AJ' },
    { word: 'musculature', word_class: 'N' }
  ],
  renown: [
    { word: 'renown', word_class: 'N' },
    { word: 'renown', word_class: 'V' },
    { word: 'renowned', word_class: 'AJ' }
  ],
  deed: [{ word: 'deed', word_class: 'N' }, { word: 'deed', word_class: 'V' }, { word: 'deeds', word_class: 'N' }],
  catapultic: [
    { word: 'catapult', word_class: 'N' },
    { word: 'catapult', word_class: 'V' },
    { word: 'catapultic', word_class: 'AJ' }
  ],
  instigation: [
    { word: 'instigant', word_class: 'N' },
    { word: 'instigate', word_class: 'V' },
    { word: 'instigator', word_class: 'N' },
    { word: 'instigation', word_class: 'N' },
    { word: 'instigative', word_class: 'AJ' }
  ],
  selector: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  clouding: [
    { word: 'cloud', word_class: 'N' },
    { word: 'cloud', word_class: 'V' },
    { word: 'clouded', word_class: 'V' },
    { word: 'clouded', word_class: 'AJ' },
    { word: 'clouding', word_class: 'N' }
  ],
  vulcanize: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  mitigate: [
    { word: 'mitigate', word_class: 'V' },
    { word: 'mitigable', word_class: 'AJ' },
    { word: 'mitigated', word_class: 'AJ' },
    { word: 'mitigation', word_class: 'N' },
    { word: 'mitigative', word_class: 'AJ' }
  ],
  averting: [
    { word: 'avert', word_class: 'V' },
    { word: 'averse', word_class: 'AJ' },
    { word: 'aversion', word_class: 'N' },
    { word: 'averting', word_class: 'N' },
    { word: 'aversive', word_class: 'AJ' }
  ],
  evaporate: [
    { word: 'evaporate', word_class: 'V' },
    { word: 'evaporable', word_class: 'AJ' },
    { word: 'evaporated', word_class: 'AJ' },
    { word: 'evaporation', word_class: 'N' },
    { word: 'evaporative', word_class: 'AJ' }
  ],
  lupine: [
    { word: 'lupin', word_class: 'N' },
    { word: 'lupine', word_class: 'N' },
    { word: 'lupine', word_class: 'AJ' }
  ],
  shake: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  snips: [
    { word: 'snip', word_class: 'N' },
    { word: 'snip', word_class: 'V' },
    { word: 'snips', word_class: 'N' },
    { word: 'snipping', word_class: 'N' }
  ],
  grounder: [
    { word: 'ground', word_class: 'N' },
    { word: 'ground', word_class: 'V' },
    { word: 'ground', word_class: 'AJ' },
    { word: 'grounds', word_class: 'N' },
    { word: 'grounder', word_class: 'N' },
    { word: 'grounding', word_class: 'N' }
  ],
  inquiry: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  unenlightening: [
    { word: 'unenlightened', word_class: 'AJ' },
    { word: 'unenlightening', word_class: 'AJ' },
    { word: 'unenlightenment', word_class: 'N' }
  ],
  infuriate: [
    { word: 'infuriate', word_class: 'V' },
    { word: 'infuriated', word_class: 'AJ' },
    { word: 'infuriation', word_class: 'N' },
    { word: 'infuriating', word_class: 'AJ' }
  ],
  carposporous: [
    { word: 'carpospore', word_class: 'N' },
    { word: 'carposporic', word_class: 'AJ' },
    { word: 'carposporous', word_class: 'AJ' }
  ],
  tat: [
    { word: 'tat', word_class: 'N' },
    { word: 'tat', word_class: 'V' },
    { word: 'tatt', word_class: 'V' },
    { word: 'tater', word_class: 'N' },
    { word: 'tatting', word_class: 'N' }
  ],
  presidency: [
    { word: 'preside', word_class: 'N' },
    { word: 'preside', word_class: 'V' },
    { word: 'president', word_class: 'N' },
    { word: 'presidency', word_class: 'N' },
    { word: 'president', word_class: 'AJ' }
  ],
  shop: [{ word: 'shop', word_class: 'N' }, { word: 'shop', word_class: 'V' }, { word: 'shopping', word_class: 'N' }],
  intrusive: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  conceptual: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  legalization: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  mongrelisation: [
    { word: 'mongrel', word_class: 'N' },
    { word: 'mongrel', word_class: 'AJ' },
    { word: 'mongrelise', word_class: 'V' },
    { word: 'mongrelize', word_class: 'V' },
    { word: 'mongrelisation', word_class: 'N' },
    { word: 'mongrelization', word_class: 'N' }
  ],
  sustainability: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  neuron: [
    { word: 'neuron', word_class: 'N' },
    { word: 'neuronal', word_class: 'AJ' },
    { word: 'neuronic', word_class: 'AJ' }
  ],
  reprehensively: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  intermediation: [
    { word: 'intermediate', word_class: 'N' },
    { word: 'intermediate', word_class: 'V' },
    { word: 'intermediate', word_class: 'AJ' },
    { word: 'intermediator', word_class: 'N' },
    { word: 'intermediation', word_class: 'N' },
    { word: 'intermediately', word_class: 'AV' }
  ],
  genuinely: [
    { word: 'genuine', word_class: 'AJ' },
    { word: 'genuinely', word_class: 'AV' },
    { word: 'genuineness', word_class: 'N' }
  ],
  oligarchical: [
    { word: 'oligarch', word_class: 'N' },
    { word: 'oligarchic', word_class: 'AJ' },
    { word: 'oligarchical', word_class: 'AJ' }
  ],
  obsolesce: [
    { word: 'obsolesce', word_class: 'V' },
    { word: 'obsolescence', word_class: 'N' },
    { word: 'obsolescent', word_class: 'AJ' }
  ],
  wing: [
    { word: 'wing', word_class: 'N' },
    { word: 'wing', word_class: 'V' },
    { word: 'wings', word_class: 'N' },
    { word: 'winger', word_class: 'N' },
    { word: 'winged', word_class: 'AJ' },
    { word: 'wingman', word_class: 'N' }
  ],
  lividness: [
    { word: 'livid', word_class: 'AJ' },
    { word: 'lividity', word_class: 'N' },
    { word: 'lividness', word_class: 'N' }
  ],
  domineer: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  typicality: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  crook: [
    { word: 'crook', word_class: 'N' },
    { word: 'crook', word_class: 'V' },
    { word: 'crooked', word_class: 'AJ' }
  ],
  tamper: [
    { word: 'tamp', word_class: 'N' },
    { word: 'tamp', word_class: 'V' },
    { word: 'tamper', word_class: 'N' },
    { word: 'tamper', word_class: 'V' },
    { word: 'tampering', word_class: 'N' }
  ],
  peptonisation: [
    { word: 'peptone', word_class: 'N' },
    { word: 'peptonise', word_class: 'V' },
    { word: 'peptonize', word_class: 'V' },
    { word: 'peptonisation', word_class: 'N' },
    { word: 'peptonization', word_class: 'N' }
  ],
  environ: [
    { word: 'environ', word_class: 'N' },
    { word: 'environ', word_class: 'V' },
    { word: 'environs', word_class: 'N' },
    { word: 'environment', word_class: 'N' }
  ],
  assiduousness: [
    { word: 'assiduity', word_class: 'N' },
    { word: 'assiduous', word_class: 'AJ' },
    { word: 'assiduously', word_class: 'AV' },
    { word: 'assiduousness', word_class: 'N' }
  ],
  imprinting: [
    { word: 'imprint', word_class: 'N' },
    { word: 'imprint', word_class: 'V' },
    { word: 'imprinting', word_class: 'N' }
  ],
  toothsomeness: [
    { word: 'tooth', word_class: 'N' },
    { word: 'toothed', word_class: 'AJ' },
    { word: 'toothsome', word_class: 'AJ' },
    { word: 'toothsomeness', word_class: 'N' }
  ],
  staring: [
    { word: 'stare', word_class: 'N' },
    { word: 'stare', word_class: 'V' },
    { word: 'staring', word_class: 'AJ' },
    { word: 'staring', word_class: 'AV' }
  ],
  limping: [
    { word: 'limp', word_class: 'N' },
    { word: 'limp', word_class: 'V' },
    { word: 'limp', word_class: 'AJ' },
    { word: 'limping', word_class: 'N' },
    { word: 'limpness', word_class: 'N' }
  ],
  delude: [
    { word: 'delude', word_class: 'V' },
    { word: 'delusion', word_class: 'N' },
    { word: 'delusive', word_class: 'AJ' },
    { word: 'delusively', word_class: 'AV' },
    { word: 'delusionary', word_class: 'AJ' }
  ],
  lover: [
    { word: 'love', word_class: 'N' },
    { word: 'love', word_class: 'V' },
    { word: 'lover', word_class: 'N' },
    { word: 'loved', word_class: 'AJ' },
    { word: 'lovely', word_class: 'N' },
    { word: 'lovely', word_class: 'AJ' },
    { word: 'loving', word_class: 'AJ' }
  ],
  mesmerization: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  incubator: [
    { word: 'incubate', word_class: 'V' },
    { word: 'incubator', word_class: 'N' },
    { word: 'incubation', word_class: 'N' }
  ],
  lend: [{ word: 'lend', word_class: 'V' }, { word: 'lender', word_class: 'N' }, { word: 'lending', word_class: 'N' }],
  calving: [
    { word: 'calve', word_class: 'V' },
    { word: 'calved', word_class: 'AJ' },
    { word: 'calving', word_class: 'N' }
  ],
  facilitate: [
    { word: 'facilitate', word_class: 'V' },
    { word: 'facilitator', word_class: 'N' },
    { word: 'facilitated', word_class: 'AJ' },
    { word: 'facilitation', word_class: 'N' },
    { word: 'facilitative', word_class: 'AJ' }
  ],
  transmitted: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  sweeten: [
    { word: 'sweeten', word_class: 'V' },
    { word: 'sweetener', word_class: 'N' },
    { word: 'sweetened', word_class: 'AJ' },
    { word: 'sweetening', word_class: 'N' }
  ],
  pelecypod: [
    { word: 'pelecypod', word_class: 'N' },
    { word: 'pelecypod', word_class: 'AJ' },
    { word: 'pelecypodous', word_class: 'AJ' }
  ],
  interpretative: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  respire: [
    { word: 'respire', word_class: 'N' },
    { word: 'respire', word_class: 'V' },
    { word: 'respirate', word_class: 'V' },
    { word: 'respirator', word_class: 'N' },
    { word: 'respiration', word_class: 'N' }
  ],
  garmented: [
    { word: 'garment', word_class: 'N' },
    { word: 'garment', word_class: 'V' },
    { word: 'garmented', word_class: 'AJ' }
  ],
  alarmed: [
    { word: 'alarm', word_class: 'N' },
    { word: 'alarm', word_class: 'V' },
    { word: 'alarmism', word_class: 'N' },
    { word: 'alarmist', word_class: 'N' },
    { word: 'alarmed', word_class: 'AJ' },
    { word: 'alarming', word_class: 'AJ' }
  ],
  jocularity: [
    { word: 'jocular', word_class: 'AJ' },
    { word: 'jocular', word_class: 'AV' },
    { word: 'jocularity', word_class: 'N' }
  ],
  transversely: [
    { word: 'transverse', word_class: 'N' },
    { word: 'transverse', word_class: 'V' },
    { word: 'transversal', word_class: 'N' },
    { word: 'transverse', word_class: 'AJ' },
    { word: 'transversal', word_class: 'AJ' },
    { word: 'transversely', word_class: 'AV' },
    { word: 'transversally', word_class: 'AV' }
  ],
  persuader: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  problematically: [
    { word: 'problem', word_class: 'N' },
    { word: 'problematic', word_class: 'AJ' },
    { word: 'problematical', word_class: 'AJ' },
    { word: 'problematically', word_class: 'AV' }
  ],
  rocker: [{ word: 'rock', word_class: 'N' }, { word: 'rock', word_class: 'V' }, { word: 'rocker', word_class: 'N' }],
  affirmable: [
    { word: 'affirm', word_class: 'V' },
    { word: 'affirmation', word_class: 'N' },
    { word: 'affirmable', word_class: 'AJ' },
    { word: 'affirmative', word_class: 'N' },
    { word: 'affirmative', word_class: 'AJ' },
    { word: 'affirmativeness', word_class: 'N' }
  ],
  normally: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  pureness: [
    { word: 'puree', word_class: 'V' },
    { word: 'puree', word_class: 'N' },
    { word: 'pure', word_class: 'AJ' },
    { word: 'purely', word_class: 'AV' },
    { word: 'pureness', word_class: 'N' }
  ],
  unceremoniously: [
    { word: 'unceremonial', word_class: 'AJ' },
    { word: 'unceremonious', word_class: 'AJ' },
    { word: 'unceremoniously', word_class: 'AV' },
    { word: 'unceremoniousness', word_class: 'N' }
  ],
  sex: [
    { word: 'sex', word_class: 'N' },
    { word: 'sex', word_class: 'V' },
    { word: 'sexed', word_class: 'AJ' },
    { word: 'sexism', word_class: 'N' },
    { word: 'sexist', word_class: 'N' },
    { word: 'sexist', word_class: 'AJ' }
  ],
  slouching: [
    { word: 'slouch', word_class: 'N' },
    { word: 'slouch', word_class: 'V' },
    { word: 'slouched', word_class: 'AJ' },
    { word: 'slouching', word_class: 'AJ' }
  ],
  analogously: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  disconnected: [
    { word: 'disconnect', word_class: 'V' },
    { word: 'disconnexion', word_class: 'N' },
    { word: 'disconnected', word_class: 'AJ' },
    { word: 'disconnection', word_class: 'N' }
  ],
  eplacement: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  immunization: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  depolarize: [
    { word: 'depolarise', word_class: 'V' },
    { word: 'depolarize', word_class: 'V' },
    { word: 'depolarisation', word_class: 'N' },
    { word: 'depolarization', word_class: 'N' }
  ],
  inhabit: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  denunciate: [
    { word: 'denounce', word_class: 'V' },
    { word: 'denunciate', word_class: 'V' },
    { word: 'denouncement', word_class: 'N' },
    { word: 'denunciation', word_class: 'N' },
    { word: 'denunciative', word_class: 'AJ' }
  ],
  cupping: [
    { word: 'cup', word_class: 'N' },
    { word: 'cup', word_class: 'V' },
    { word: 'cupful', word_class: 'N' },
    { word: 'cupful', word_class: 'AJ' },
    { word: 'cupping', word_class: 'N' }
  ],
  lever: [
    { word: 'lever', word_class: 'N' },
    { word: 'lever', word_class: 'V' },
    { word: 'leverage', word_class: 'N' },
    { word: 'leverage', word_class: 'V' },
    { word: 'leveraging', word_class: 'N' }
  ],
  anesthetic: [
    { word: 'anesthetic', word_class: 'N' },
    { word: 'anesthetic', word_class: 'AJ' },
    { word: 'anesthetize', word_class: 'V' },
    { word: 'anesthetized', word_class: 'AJ' },
    { word: 'anesthetization', word_class: 'N' }
  ],
  ritually: [
    { word: 'ritual', word_class: 'N' },
    { word: 'ritual', word_class: 'AJ' },
    { word: 'ritualism', word_class: 'N' },
    { word: 'ritualize', word_class: 'V' },
    { word: 'ritually', word_class: 'AV' },
    { word: 'ritualization', word_class: 'N' }
  ],
  profitably: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  anagrammatic: [
    { word: 'anagrammatic', word_class: 'AJ' },
    { word: 'anagrammatize', word_class: 'V' },
    { word: 'anagrammatical', word_class: 'AJ' },
    { word: 'anagrammatization', word_class: 'N' }
  ],
  vernal: [
    { word: 'vernal', word_class: 'AJ' },
    { word: 'vernalise', word_class: 'V' },
    { word: 'vernalize', word_class: 'V' },
    { word: 'vernalisation', word_class: 'N' },
    { word: 'vernalization', word_class: 'N' }
  ],
  canning: [
    { word: 'can', word_class: 'N' },
    { word: 'can', word_class: 'V' },
    { word: 'canful', word_class: 'N' },
    { word: 'canful', word_class: 'AJ' },
    { word: 'canned', word_class: 'AJ' },
    { word: 'cannery', word_class: 'N' },
    { word: 'canning', word_class: 'N' },
    { word: 'cannery', word_class: 'AJ' }
  ],
  pervade: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  multitude: [
    { word: 'multitude', word_class: 'N' },
    { word: 'multitudinous', word_class: 'AJ' },
    { word: 'multitudinousness', word_class: 'N' }
  ],
  flaunting: [
    { word: 'flaunt', word_class: 'N' },
    { word: 'flaunt', word_class: 'V' },
    { word: 'flaunting', word_class: 'N' }
  ],
  quake: [
    { word: 'quake', word_class: 'N' },
    { word: 'quake', word_class: 'V' },
    { word: 'quaker', word_class: 'N' },
    { word: 'quakers', word_class: 'N' },
    { word: 'quaking', word_class: 'AJ' },
    { word: 'quakerism', word_class: 'N' }
  ],
  far: [
    { word: 'fare', word_class: 'V' },
    { word: 'far', word_class: 'AJ' },
    { word: 'far', word_class: 'AV' },
    { word: 'fare', word_class: 'N' },
    { word: 'farness', word_class: 'N' }
  ],
  internalise: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  supremacist: [
    { word: 'supreme', word_class: 'AJ' },
    { word: 'supremacy', word_class: 'N' },
    { word: 'supremely', word_class: 'AV' },
    { word: 'supremacist', word_class: 'N' }
  ],
  immensely: [
    { word: 'immense', word_class: 'AJ' },
    { word: 'immensity', word_class: 'N' },
    { word: 'immensely', word_class: 'AV' },
    { word: 'immenseness', word_class: 'N' }
  ],
  additional: [
    { word: 'add', word_class: 'V' },
    { word: 'adder', word_class: 'N' },
    { word: 'addition', word_class: 'N' },
    { word: 'additive', word_class: 'N' },
    { word: 'additive', word_class: 'AJ' },
    { word: 'additional', word_class: 'AJ' }
  ],
  appetence: [
    { word: 'appetence', word_class: 'N' },
    { word: 'appetency', word_class: 'N' },
    { word: 'appetent', word_class: 'AJ' },
    { word: 'appetizer', word_class: 'N' },
    { word: 'appetizing', word_class: 'AJ' }
  ],
  upstage: [
    { word: 'upstage', word_class: 'V' },
    { word: 'upstage', word_class: 'AJ' },
    { word: 'upstage', word_class: 'AV' }
  ],
  leveler: [
    { word: 'level', word_class: 'N' },
    { word: 'level', word_class: 'V' },
    { word: 'level', word_class: 'AJ' },
    { word: 'leveler', word_class: 'N' },
    { word: 'leveling', word_class: 'N' },
    { word: 'leveller', word_class: 'N' }
  ],
  efficaciousness: [
    { word: 'efficacy', word_class: 'N' },
    { word: 'efficacious', word_class: 'AJ' },
    { word: 'efficaciously', word_class: 'AV' },
    { word: 'efficaciousness', word_class: 'N' }
  ],
  wishful: [
    { word: 'wish', word_class: 'N' },
    { word: 'wish', word_class: 'V' },
    { word: 'wishing', word_class: 'N' },
    { word: 'wishful', word_class: 'AJ' },
    { word: 'wishfulness', word_class: 'N' }
  ],
  squeaking: [
    { word: 'squeak', word_class: 'N' },
    { word: 'squeak', word_class: 'V' },
    { word: 'squeaker', word_class: 'N' },
    { word: 'squeaking', word_class: 'AJ' }
  ],
  aristocracy: [
    { word: 'aristocrat', word_class: 'N' },
    { word: 'aristocracy', word_class: 'N' },
    { word: 'aristocratic', word_class: 'AJ' },
    { word: 'aristocratical', word_class: 'AJ' },
    { word: 'aristocratically', word_class: 'AV' }
  ],
  exultance: [
    { word: 'exult', word_class: 'V' },
    { word: 'exultant', word_class: 'N' },
    { word: 'exultance', word_class: 'N' },
    { word: 'exultant', word_class: 'AJ' },
    { word: 'exulting', word_class: 'AJ' },
    { word: 'exultation', word_class: 'N' }
  ],
  enthralment: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  diphthongization: [
    { word: 'diphthong', word_class: 'N' },
    { word: 'diphthongise', word_class: 'V' },
    { word: 'diphthongize', word_class: 'V' },
    { word: 'diphthongisation', word_class: 'N' },
    { word: 'diphthongization', word_class: 'N' }
  ],
  darn: [
    { word: 'darn', word_class: 'N' },
    { word: 'darn', word_class: 'V' },
    { word: 'darned', word_class: 'AJ' },
    { word: 'darning', word_class: 'N' }
  ],
  undesirability: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  sorcerize: [
    { word: 'sorcery', word_class: 'N' },
    { word: 'sorcerer', word_class: 'N' },
    { word: 'sorceress', word_class: 'N' },
    { word: 'sorcerize', word_class: 'V' },
    { word: 'sorcerous', word_class: 'AJ' }
  ],
  management: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  buccaneering: [
    { word: 'buccaneer', word_class: 'N' },
    { word: 'buccaneer', word_class: 'V' },
    { word: 'buccaneering', word_class: 'N' }
  ],
  mange: [
    { word: 'mange', word_class: 'N' },
    { word: 'mangy', word_class: 'AJ' },
    { word: 'manginess', word_class: 'N' }
  ],
  stenographic: [
    { word: 'stenographer', word_class: 'N' },
    { word: 'stenographic', word_class: 'AJ' },
    { word: 'stenographical', word_class: 'AJ' }
  ],
  tautologic: [
    { word: 'tautology', word_class: 'N' },
    { word: 'tautologic', word_class: 'AJ' },
    { word: 'tautological', word_class: 'AJ' }
  ],
  decode: [
    { word: 'decode', word_class: 'V' },
    { word: 'decoder', word_class: 'N' },
    { word: 'decoding', word_class: 'N' }
  ],
  pathogenically: [
    { word: 'pathogen', word_class: 'N' },
    { word: 'pathogenic', word_class: 'AJ' },
    { word: 'pathogenically', word_class: 'AV' }
  ],
  dueler: [
    { word: 'duel', word_class: 'N' },
    { word: 'duel', word_class: 'V' },
    { word: 'dueler', word_class: 'N' },
    { word: 'duello', word_class: 'N' },
    { word: 'duelist', word_class: 'N' }
  ],
  meddlesomeness: [
    { word: 'meddle', word_class: 'V' },
    { word: 'meddler', word_class: 'N' },
    { word: 'meddling', word_class: 'N' },
    { word: 'meddling', word_class: 'AJ' },
    { word: 'meddlesome', word_class: 'AJ' },
    { word: 'meddlesomeness', word_class: 'N' }
  ],
  topographically: [
    { word: 'topographic', word_class: 'AJ' },
    { word: 'topographical', word_class: 'AJ' },
    { word: 'topographically', word_class: 'AV' }
  ],
  mimicry: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  effectual: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  spill: [
    { word: 'spill', word_class: 'N' },
    { word: 'spill', word_class: 'V' },
    { word: 'spilth', word_class: 'N' },
    { word: 'spillage', word_class: 'N' }
  ],
  allegorise: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  easement: [
    { word: 'ease', word_class: 'N' },
    { word: 'ease', word_class: 'V' },
    { word: 'eased', word_class: 'AJ' },
    { word: 'easing', word_class: 'N' },
    { word: 'easement', word_class: 'N' }
  ],
  eloin: [
    { word: 'eloin', word_class: 'V' },
    { word: 'eloign', word_class: 'V' },
    { word: 'eloinment', word_class: 'N' },
    { word: 'eloignment', word_class: 'N' }
  ],
  proceeding: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  ceding: [
    { word: 'cede', word_class: 'V' },
    { word: 'ceding', word_class: 'N' },
    { word: 'cession', word_class: 'N' }
  ],
  unadaptable: [
    { word: 'unadapted', word_class: 'AJ' },
    { word: 'unadaptable', word_class: 'AJ' },
    { word: 'unadaptability', word_class: 'N' }
  ],
  qualified: [
    { word: 'qualify', word_class: 'V' },
    { word: 'qualifier', word_class: 'N' },
    { word: 'qualified', word_class: 'AJ' },
    { word: 'qualifying', word_class: 'N' },
    { word: 'qualifying', word_class: 'AJ' },
    { word: 'qualification', word_class: 'N' }
  ],
  ironed: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  emaciated: [
    { word: 'emaciate', word_class: 'V' },
    { word: 'emaciated', word_class: 'AJ' },
    { word: 'emaciation', word_class: 'N' }
  ],
  exquisitely: [
    { word: 'exquisite', word_class: 'AJ' },
    { word: 'exquisitely', word_class: 'AV' },
    { word: 'exquisiteness', word_class: 'N' }
  ],
  sectionalise: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  neurology: [
    { word: 'neurology', word_class: 'N' },
    { word: 'neurologic', word_class: 'AJ' },
    { word: 'neurological', word_class: 'AJ' }
  ],
  syllabled: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  nationalisation: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  discomfited: [
    { word: 'discomfit', word_class: 'N' },
    { word: 'discomfit', word_class: 'V' },
    { word: 'discomfited', word_class: 'N' },
    { word: 'discomfited', word_class: 'AJ' },
    { word: 'discomfiture', word_class: 'N' }
  ],
  lithographer: [
    { word: 'lithograph', word_class: 'N' },
    { word: 'lithograph', word_class: 'V' },
    { word: 'lithography', word_class: 'N' },
    { word: 'lithographer', word_class: 'N' },
    { word: 'lithography', word_class: 'AJ' },
    { word: 'lithographic', word_class: 'AJ' }
  ],
  assent: [
    { word: 'assent', word_class: 'N' },
    { word: 'assent', word_class: 'V' },
    { word: 'assenting', word_class: 'N' },
    { word: 'assenting', word_class: 'AJ' }
  ],
  steaming: [
    { word: 'steam', word_class: 'N' },
    { word: 'steam', word_class: 'V' },
    { word: 'steamer', word_class: 'N' },
    { word: 'steamed', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AV' },
    { word: 'steamship', word_class: 'N' }
  ],
  penetrable: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  centralizing: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  languor: [
    { word: 'languor', word_class: 'N' },
    { word: 'languorous', word_class: 'AJ' },
    { word: 'languorously', word_class: 'AV' }
  ],
  resiliency: [
    { word: 'resile', word_class: 'V' },
    { word: 'resilement', word_class: 'N' },
    { word: 'resilience', word_class: 'N' },
    { word: 'resiliency', word_class: 'N' },
    { word: 'resilient', word_class: 'AJ' }
  ],
  vowel: [
    { word: 'vowel', word_class: 'N' },
    { word: 'vowelise', word_class: 'V' },
    { word: 'vowelize', word_class: 'V' },
    { word: 'vowelisation', word_class: 'N' },
    { word: 'vowelization', word_class: 'N' }
  ],
  geology: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  palatine: [
    { word: 'palatine', word_class: 'N' },
    { word: 'palatine', word_class: 'AJ' },
    { word: 'palatinate', word_class: 'N' }
  ],
  ineffectively: [
    { word: 'ineffective', word_class: 'AJ' },
    { word: 'ineffectively', word_class: 'AV' },
    { word: 'ineffectiveness', word_class: 'N' }
  ],
  itineration: [
    { word: 'itinerant', word_class: 'N' },
    { word: 'itinerate', word_class: 'V' },
    { word: 'itinerant', word_class: 'AJ' },
    { word: 'itineration', word_class: 'N' }
  ],
  prefabricated: [
    { word: 'prefabricate', word_class: 'V' },
    { word: 'prefabricated', word_class: 'AJ' },
    { word: 'prefabrication', word_class: 'N' }
  ],
  departure: [
    { word: 'depart', word_class: 'V' },
    { word: 'departed', word_class: 'N' },
    { word: 'departer', word_class: 'N' },
    { word: 'departed', word_class: 'AJ' },
    { word: 'departure', word_class: 'N' },
    { word: 'departing', word_class: 'AJ' },
    { word: 'department', word_class: 'N' }
  ],
  balancing: [
    { word: 'balance', word_class: 'N' },
    { word: 'balance', word_class: 'V' },
    { word: 'balancer', word_class: 'N' },
    { word: 'balanced', word_class: 'AJ' },
    { word: 'balancing', word_class: 'N' }
  ],
  stocking: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  soothing: [
    { word: 'sooth', word_class: 'N' },
    { word: 'soothe', word_class: 'V' },
    { word: 'soothing', word_class: 'AJ' }
  ],
  summarisation: [
    { word: 'summary', word_class: 'N' },
    { word: 'summary', word_class: 'AJ' },
    { word: 'summarise', word_class: 'V' },
    { word: 'summarize', word_class: 'V' },
    { word: 'summarisation', word_class: 'N' },
    { word: 'summarization', word_class: 'N' }
  ],
  sanitized: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  float: [
    { word: 'float', word_class: 'N' },
    { word: 'float', word_class: 'V' },
    { word: 'floater', word_class: 'N' },
    { word: 'floating', word_class: 'AJ' },
    { word: 'flotation', word_class: 'N' },
    { word: 'floatation', word_class: 'N' }
  ],
  lutist: [
    { word: 'lute', word_class: 'N' },
    { word: 'lute', word_class: 'V' },
    { word: 'luting', word_class: 'N' },
    { word: 'lutist', word_class: 'N' },
    { word: 'lutanist', word_class: 'N' }
  ],
  disguise: [
    { word: 'disguise', word_class: 'N' },
    { word: 'disguise', word_class: 'V' },
    { word: 'disguised', word_class: 'AJ' }
  ],
  corporation: [
    { word: 'corporal', word_class: 'N' },
    { word: 'corporal', word_class: 'AJ' },
    { word: 'corporate', word_class: 'AJ' },
    { word: 'corporeal', word_class: 'AJ' },
    { word: 'corporation', word_class: 'N' },
    { word: 'corporeality', word_class: 'N' }
  ],
  encasement: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  vulgar: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  excitant: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  solemnization: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  scent: [
    { word: 'scent', word_class: 'N' },
    { word: 'scent', word_class: 'V' },
    { word: 'scented', word_class: 'AJ' }
  ],
  bacteriophage: [
    { word: 'bacteriophage', word_class: 'N' },
    { word: 'bacteriophagic', word_class: 'AJ' },
    { word: 'bacteriophagous', word_class: 'AJ' }
  ],
  masonic: [
    { word: 'mason', word_class: 'N' },
    { word: 'masonry', word_class: 'N' },
    { word: 'masonic', word_class: 'AJ' }
  ],
  joke: [
    { word: 'joke', word_class: 'N' },
    { word: 'joke', word_class: 'V' },
    { word: 'joker', word_class: 'N' },
    { word: 'joking', word_class: 'AJ' }
  ],
  further: [
    { word: 'further', word_class: 'V' },
    { word: 'further', word_class: 'AJ' },
    { word: 'further', word_class: 'AV' },
    { word: 'furtherance', word_class: 'N' }
  ],
  medicative: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  conciliable: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  toweling: [
    { word: 'towel', word_class: 'N' },
    { word: 'towel', word_class: 'V' },
    { word: 'toweling', word_class: 'N' },
    { word: 'towelling', word_class: 'N' }
  ],
  restiveness: [
    { word: 'restive', word_class: 'AJ' },
    { word: 'restively', word_class: 'AV' },
    { word: 'restiveness', word_class: 'N' }
  ],
  scandal: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  innervate: [
    { word: 'innerve', word_class: 'V' },
    { word: 'innervate', word_class: 'V' },
    { word: 'innervation', word_class: 'N' }
  ],
  bumble: [
    { word: 'bumble', word_class: 'V' },
    { word: 'bumbler', word_class: 'N' },
    { word: 'bumbling', word_class: 'AJ' }
  ],
  bends: [
    { word: 'bend', word_class: 'N' },
    { word: 'bend', word_class: 'V' },
    { word: 'bends', word_class: 'N' },
    { word: 'bender', word_class: 'N' },
    { word: 'bended', word_class: 'AJ' },
    { word: 'bending', word_class: 'N' },
    { word: 'bending', word_class: 'AJ' }
  ],
  unity: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  raver: [
    { word: 'rave', word_class: 'N' },
    { word: 'rave', word_class: 'V' },
    { word: 'raver', word_class: 'N' },
    { word: 'raving', word_class: 'N' },
    { word: 'raving', word_class: 'AJ' },
    { word: 'raving', word_class: 'AV' }
  ],
  sinkage: [
    { word: 'sink', word_class: 'N' },
    { word: 'sink', word_class: 'V' },
    { word: 'sinker', word_class: 'N' },
    { word: 'sinkage', word_class: 'N' },
    { word: 'sinking', word_class: 'N' },
    { word: 'sunken', word_class: 'AJ' }
  ],
  sneezing: [
    { word: 'sneeze', word_class: 'N' },
    { word: 'sneeze', word_class: 'V' },
    { word: 'sneezy', word_class: 'AJ' },
    { word: 'sneezing', word_class: 'N' }
  ],
  flutter: [
    { word: 'flutter', word_class: 'N' },
    { word: 'flutter', word_class: 'V' },
    { word: 'fluttering', word_class: 'N' },
    { word: 'fluttering', word_class: 'AJ' }
  ],
  vicariate: [
    { word: 'vicarial', word_class: 'AJ' },
    { word: 'vicariate', word_class: 'N' },
    { word: 'vicarious', word_class: 'AJ' },
    { word: 'vicariously', word_class: 'AV' }
  ],
  dislike: [
    { word: 'dislike', word_class: 'N' },
    { word: 'dislike', word_class: 'V' },
    { word: 'disliked', word_class: 'AJ' },
    { word: 'dislikable', word_class: 'AJ' }
  ],
  slaughter: [
    { word: 'slaughter', word_class: 'N' },
    { word: 'slaughter', word_class: 'V' },
    { word: 'slaughterer', word_class: 'N' },
    { word: 'slaughterous', word_class: 'AJ' }
  ],
  unambiguous: [
    { word: 'unambiguity', word_class: 'N' },
    { word: 'unambiguous', word_class: 'AJ' },
    { word: 'unambiguously', word_class: 'AV' }
  ],
  euphonize: [
    { word: 'euphonic', word_class: 'AJ' },
    { word: 'euphonize', word_class: 'V' },
    { word: 'euphonous', word_class: 'AJ' },
    { word: 'euphonical', word_class: 'AJ' },
    { word: 'euphonization', word_class: 'N' }
  ],
  legal: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  condescendence: [
    { word: 'condescend', word_class: 'V' },
    { word: 'condescension', word_class: 'N' },
    { word: 'condescendence', word_class: 'N' },
    { word: 'condescending', word_class: 'AJ' }
  ],
  expressed: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  clarifying: [
    { word: 'clarify', word_class: 'V' },
    { word: 'clarified', word_class: 'AJ' },
    { word: 'clarifying', word_class: 'AJ' },
    { word: 'clarification', word_class: 'N' }
  ],
  abrader: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  stunt: [
    { word: 'stunt', word_class: 'N' },
    { word: 'stunt', word_class: 'V' },
    { word: 'stunted', word_class: 'AJ' },
    { word: 'stunting', word_class: 'N' }
  ],
  soled: [
    { word: 'sole', word_class: 'N' },
    { word: 'sole', word_class: 'V' },
    { word: 'sole', word_class: 'AJ' },
    { word: 'soled', word_class: 'AJ' },
    { word: 'solely', word_class: 'AV' }
  ],
  defacement: [
    { word: 'deface', word_class: 'V' },
    { word: 'defaced', word_class: 'AJ' },
    { word: 'defacement', word_class: 'N' }
  ],
  cinematize: [
    { word: 'cinema', word_class: 'N' },
    { word: 'cinematic', word_class: 'AJ' },
    { word: 'cinematize', word_class: 'V' }
  ],
  basketful: [
    { word: 'basket', word_class: 'N' },
    { word: 'basketeer', word_class: 'N' },
    { word: 'basketful', word_class: 'N' },
    { word: 'basketful', word_class: 'AJ' }
  ],
  shelter: [
    { word: 'shelter', word_class: 'N' },
    { word: 'shelter', word_class: 'V' },
    { word: 'sheltered', word_class: 'AJ' }
  ],
  azure: [{ word: 'azure', word_class: 'N' }, { word: 'azure', word_class: 'V' }, { word: 'azure', word_class: 'AJ' }],
  swaying: [
    { word: 'sway', word_class: 'N' },
    { word: 'sway', word_class: 'V' },
    { word: 'swaying', word_class: 'AJ' }
  ],
  avidity: [
    { word: 'avid', word_class: 'AJ' },
    { word: 'avidity', word_class: 'N' },
    { word: 'avidness', word_class: 'N' }
  ],
  hurler: [
    { word: 'hurl', word_class: 'N' },
    { word: 'hurl', word_class: 'V' },
    { word: 'hurler', word_class: 'N' },
    { word: 'hurling', word_class: 'N' },
    { word: 'hurling', word_class: 'AJ' }
  ],
  relaxation: [
    { word: 'relax', word_class: 'V' },
    { word: 'relax', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'N' },
    { word: 'relaxed', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'AJ' },
    { word: 'relaxing', word_class: 'AJ' },
    { word: 'relaxation', word_class: 'N' }
  ],
  reorientation: [
    { word: 'reorient', word_class: 'V' },
    { word: 'reorientate', word_class: 'V' },
    { word: 'reorientation', word_class: 'N' }
  ],
  monumental: [
    { word: 'monument', word_class: 'N' },
    { word: 'monumental', word_class: 'AJ' },
    { word: 'monumentalize', word_class: 'V' }
  ],
  lunger: [
    { word: 'lung', word_class: 'N' },
    { word: 'lunge', word_class: 'N' },
    { word: 'lunge', word_class: 'V' },
    { word: 'lunger', word_class: 'N' }
  ],
  phrasing: [
    { word: 'phrase', word_class: 'N' },
    { word: 'phrase', word_class: 'V' },
    { word: 'phrasing', word_class: 'N' }
  ],
  controllable: [
    { word: 'control', word_class: 'N' },
    { word: 'control', word_class: 'V' },
    { word: 'controller', word_class: 'N' },
    { word: 'controlled', word_class: 'AJ' },
    { word: 'controlling', word_class: 'N' },
    { word: 'controlling', word_class: 'AJ' },
    { word: 'controllable', word_class: 'AJ' }
  ],
  modified: [
    { word: 'modify', word_class: 'V' },
    { word: 'modifier', word_class: 'N' },
    { word: 'modified', word_class: 'AJ' },
    { word: 'modifiable', word_class: 'AJ' },
    { word: 'modification', word_class: 'N' }
  ],
  reluctant: [
    { word: 'reluct', word_class: 'V' },
    { word: 'reluctance', word_class: 'N' },
    { word: 'reluctancy', word_class: 'N' },
    { word: 'reluctant', word_class: 'AJ' },
    { word: 'reluctivity', word_class: 'N' }
  ],
  pee: [
    { word: 'pee', word_class: 'N' },
    { word: 'pee', word_class: 'V' },
    { word: 'pest', word_class: 'N' },
    { word: 'peeing', word_class: 'N' }
  ],
  envelop: [
    { word: 'envelop', word_class: 'V' },
    { word: 'envelope', word_class: 'N' },
    { word: 'envelope', word_class: 'V' },
    { word: 'enveloped', word_class: 'AJ' },
    { word: 'enveloping', word_class: 'N' },
    { word: 'enveloping', word_class: 'AJ' },
    { word: 'envelopment', word_class: 'N' }
  ],
  soiled: [
    { word: 'soil', word_class: 'N' },
    { word: 'soil', word_class: 'V' },
    { word: 'soilage', word_class: 'N' },
    { word: 'soiled', word_class: 'AJ' },
    { word: 'soilure', word_class: 'N' },
    { word: 'soiling', word_class: 'N' }
  ],
  diffusely: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  cowrie: [
    { word: 'cowry', word_class: 'N' },
    { word: 'cowrie', word_class: 'N' },
    { word: 'cowry', word_class: 'AJ' }
  ],
  penurious: [
    { word: 'penury', word_class: 'N' },
    { word: 'penurious', word_class: 'AJ' },
    { word: 'penuriously', word_class: 'AV' },
    { word: 'penuriousness', word_class: 'N' }
  ],
  carpal: [
    { word: 'carp', word_class: 'N' },
    { word: 'carp', word_class: 'V' },
    { word: 'carpal', word_class: 'N' },
    { word: 'carper', word_class: 'N' },
    { word: 'carping', word_class: 'N' },
    { word: 'carpal', word_class: 'AJ' },
    { word: 'carping', word_class: 'AJ' }
  ],
  insolent: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  reactor: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  demoralising: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  canonized: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  ignominiousness: [
    { word: 'ignominy', word_class: 'N' },
    { word: 'ignominious', word_class: 'AJ' },
    { word: 'ignominiously', word_class: 'AV' },
    { word: 'ignominiousness', word_class: 'N' }
  ],
  valiant: [
    { word: 'valiance', word_class: 'N' },
    { word: 'valiancy', word_class: 'N' },
    { word: 'valiant', word_class: 'AJ' }
  ],
  gainer: [
    { word: 'gain', word_class: 'N' },
    { word: 'gain', word_class: 'V' },
    { word: 'gainer', word_class: 'N' },
    { word: 'gainful', word_class: 'AJ' }
  ],
  heroical: [
    { word: 'heroic', word_class: 'AJ' },
    { word: 'heroics', word_class: 'N' },
    { word: 'heroical', word_class: 'AJ' },
    { word: 'heroically', word_class: 'AV' }
  ],
  misguidance: [
    { word: 'misguide', word_class: 'V' },
    { word: 'misguided', word_class: 'AJ' },
    { word: 'misguidance', word_class: 'N' }
  ],
  opposite: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  ascensive: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  conferee: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  flautist: [
    { word: 'flute', word_class: 'N' },
    { word: 'flute', word_class: 'V' },
    { word: 'fluting', word_class: 'N' },
    { word: 'flutist', word_class: 'N' },
    { word: 'flautist', word_class: 'N' }
  ],
  synchronise: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  waving: [
    { word: 'wave', word_class: 'N' },
    { word: 'wave', word_class: 'V' },
    { word: 'wavy', word_class: 'AJ' },
    { word: 'waving', word_class: 'N' },
    { word: 'waving', word_class: 'AJ' }
  ],
  peruse: [
    { word: 'peruse', word_class: 'V' },
    { word: 'perusal', word_class: 'N' },
    { word: 'perusing', word_class: 'N' }
  ],
  devolution: [
    { word: 'devolve', word_class: 'V' },
    { word: 'devolution', word_class: 'N' },
    { word: 'devolvement', word_class: 'N' },
    { word: 'devolutionary', word_class: 'AJ' }
  ],
  seeker: [
    { word: 'seek', word_class: 'N' },
    { word: 'seek', word_class: 'V' },
    { word: 'seeker', word_class: 'N' },
    { word: 'seeking', word_class: 'N' },
    { word: 'seeking', word_class: 'AJ' }
  ],
  focally: [
    { word: 'focal', word_class: 'AJ' },
    { word: 'focalize', word_class: 'V' },
    { word: 'focally', word_class: 'AV' },
    { word: 'focalisation', word_class: 'N' },
    { word: 'focalization', word_class: 'N' }
  ],
  spectrograph: [
    { word: 'spectrograph', word_class: 'N' },
    { word: 'spectrographic', word_class: 'AJ' },
    { word: 'spectrographically', word_class: 'AV' }
  ],
  fossilise: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  trafficator: [
    { word: 'traffic', word_class: 'N' },
    { word: 'traffic', word_class: 'V' },
    { word: 'trafficker', word_class: 'N' },
    { word: 'trafficator', word_class: 'N' },
    { word: 'trafficking', word_class: 'AJ' }
  ],
  fixer: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  incorporeal: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  sorting: [
    { word: 'sort', word_class: 'N' },
    { word: 'sort', word_class: 'V' },
    { word: 'sorter', word_class: 'N' },
    { word: 'sorted', word_class: 'AJ' },
    { word: 'sorting', word_class: 'N' }
  ],
  parget: [
    { word: 'parget', word_class: 'N' },
    { word: 'parget', word_class: 'V' },
    { word: 'pargeting', word_class: 'N' }
  ],
  undersized: [
    { word: 'underseal', word_class: 'N' },
    { word: 'undersize', word_class: 'AJ' },
    { word: 'undersized', word_class: 'AJ' },
    { word: 'undersealed', word_class: 'AJ' }
  ],
  organizer: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  fitful: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  objectivity: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  dispenser: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  spring: [
    { word: 'spring', word_class: 'N' },
    { word: 'spring', word_class: 'V' },
    { word: 'spring', word_class: 'AJ' },
    { word: 'sprung', word_class: 'AJ' },
    { word: 'springer', word_class: 'N' },
    { word: 'springing', word_class: 'AJ' }
  ],
  boarder: [
    { word: 'board', word_class: 'N' },
    { word: 'board', word_class: 'V' },
    { word: 'boards', word_class: 'N' },
    { word: 'boarder', word_class: 'N' },
    { word: 'boarding', word_class: 'N' }
  ],
  semite: [
    { word: 'semite', word_class: 'N' },
    { word: 'semite', word_class: 'AJ' },
    { word: 'semitic', word_class: 'N' },
    { word: 'semitic', word_class: 'AJ' }
  ],
  anatomization: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  unrespectable: [
    { word: 'unrespected', word_class: 'AJ' },
    { word: 'unrespectable', word_class: 'AJ' },
    { word: 'unrespectability', word_class: 'N' }
  ],
  ironworker: [
    { word: 'ironwork', word_class: 'N' },
    { word: 'ironworks', word_class: 'N' },
    { word: 'ironworker', word_class: 'N' }
  ],
  theoretic: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  containerization: [
    { word: 'containerise', word_class: 'V' },
    { word: 'containerize', word_class: 'V' },
    { word: 'containerized', word_class: 'AJ' },
    { word: 'containerisation', word_class: 'N' },
    { word: 'containerization', word_class: 'N' }
  ],
  theorise: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  characterization: [
    { word: 'character', word_class: 'N' },
    { word: 'character', word_class: 'V' },
    { word: 'characterize', word_class: 'V' },
    { word: 'characterized', word_class: 'AJ' },
    { word: 'characterization', word_class: 'N' }
  ],
  decarburation: [
    { word: 'decarburise', word_class: 'V' },
    { word: 'decarburize', word_class: 'V' },
    { word: 'decarburation', word_class: 'N' },
    { word: 'decarburisation', word_class: 'N' },
    { word: 'decarburization', word_class: 'N' }
  ],
  schedule: [
    { word: 'schedule', word_class: 'N' },
    { word: 'schedule', word_class: 'V' },
    { word: 'scheduled', word_class: 'AJ' },
    { word: 'scheduling', word_class: 'N' }
  ],
  sick: [
    { word: 'sick', word_class: 'N' },
    { word: 'sick', word_class: 'V' },
    { word: 'sick', word_class: 'AJ' },
    { word: 'sickness', word_class: 'N' }
  ],
  speaking: [
    { word: 'speak', word_class: 'V' },
    { word: 'speech', word_class: 'N' },
    { word: 'speaker', word_class: 'N' },
    { word: 'spoken', word_class: 'AJ' },
    { word: 'speaking', word_class: 'N' },
    { word: 'speaking', word_class: 'AJ' }
  ],
  autographed: [
    { word: 'autograph', word_class: 'N' },
    { word: 'autograph', word_class: 'V' },
    { word: 'autographed', word_class: 'AJ' },
    { word: 'autographic', word_class: 'AJ' }
  ],
  compartmented: [
    { word: 'compart', word_class: 'V' },
    { word: 'compartment', word_class: 'N' },
    { word: 'compartmented', word_class: 'AJ' }
  ],
  elude: [
    { word: 'elude', word_class: 'V' },
    { word: 'eluding', word_class: 'N' },
    { word: 'elusion', word_class: 'N' },
    { word: 'elusive', word_class: 'AJ' },
    { word: 'elusiveness', word_class: 'N' }
  ],
  pliant: [
    { word: 'ply', word_class: 'N' },
    { word: 'ply', word_class: 'V' },
    { word: 'pliant', word_class: 'N' },
    { word: 'pliancy', word_class: 'N' },
    { word: 'pliant', word_class: 'AJ' },
    { word: 'pliantness', word_class: 'N' }
  ],
  regardant: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  traject: [
    { word: 'traject', word_class: 'V' },
    { word: 'trajection', word_class: 'N' },
    { word: 'trajectory', word_class: 'N' },
    { word: 'trajectory', word_class: 'AJ' }
  ],
  watchman: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  incineration: [
    { word: 'incinerate', word_class: 'V' },
    { word: 'incinerator', word_class: 'N' },
    { word: 'incineration', word_class: 'N' }
  ],
  demonize: [
    { word: 'demon', word_class: 'N' },
    { word: 'demonic', word_class: 'AJ' },
    { word: 'demonism', word_class: 'N' },
    { word: 'demonize', word_class: 'V' },
    { word: 'demonization', word_class: 'N' }
  ],
  impounding: [
    { word: 'impound', word_class: 'N' },
    { word: 'impound', word_class: 'V' },
    { word: 'impounding', word_class: 'N' },
    { word: 'impoundment', word_class: 'N' }
  ],
  wiry: [
    { word: 'wire', word_class: 'N' },
    { word: 'wire', word_class: 'V' },
    { word: 'wiry', word_class: 'AJ' },
    { word: 'wired', word_class: 'AJ' },
    { word: 'wiring', word_class: 'N' }
  ],
  official: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  unremarkably: [
    { word: 'unremarked', word_class: 'AJ' },
    { word: 'unremarkable', word_class: 'AJ' },
    { word: 'unremarkably', word_class: 'AV' }
  ],
  harpooner: [
    { word: 'harpoon', word_class: 'N' },
    { word: 'harpoon', word_class: 'V' },
    { word: 'harpooner', word_class: 'N' },
    { word: 'harpooneer', word_class: 'N' }
  ],
  eraser: [
    { word: 'erase', word_class: 'V' },
    { word: 'eraser', word_class: 'N' },
    { word: 'erasure', word_class: 'N' },
    { word: 'erasable', word_class: 'AJ' }
  ],
  spiritualistic: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  colors: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  fantasist: [
    { word: 'fantasy', word_class: 'N' },
    { word: 'fantasia', word_class: 'N' },
    { word: 'fantasist', word_class: 'N' },
    { word: 'fantasize', word_class: 'V' }
  ],
  hostility: [
    { word: 'hostile', word_class: 'AJ' },
    { word: 'hostility', word_class: 'N' },
    { word: 'hostilely', word_class: 'AV' }
  ],
  entreat: [
    { word: 'entreat', word_class: 'V' },
    { word: 'intreat', word_class: 'V' },
    { word: 'entreaty', word_class: 'N' },
    { word: 'entreatment', word_class: 'N' },
    { word: 'intreatment', word_class: 'N' }
  ],
  virtuoso: [
    { word: 'virtuoso', word_class: 'N' },
    { word: 'virtuoso', word_class: 'AJ' },
    { word: 'virtuosity', word_class: 'N' }
  ],
  overpower: [
    { word: 'overpower', word_class: 'V' },
    { word: 'overpowered', word_class: 'AJ' },
    { word: 'overpowering', word_class: 'AJ' }
  ],
  lagging: [
    { word: 'lag', word_class: 'N' },
    { word: 'lag', word_class: 'V' },
    { word: 'lager', word_class: 'N' },
    { word: 'lagging', word_class: 'N' }
  ],
  territoriality: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  despoiled: [
    { word: 'despoil', word_class: 'V' },
    { word: 'despoiler', word_class: 'N' },
    { word: 'despoiled', word_class: 'AJ' },
    { word: 'despoilment', word_class: 'N' },
    { word: 'despoliation', word_class: 'N' }
  ],
  ranging: [
    { word: 'range', word_class: 'N' },
    { word: 'range', word_class: 'V' },
    { word: 'ranger', word_class: 'N' },
    { word: 'ranging', word_class: 'AJ' }
  ],
  ranter: [
    { word: 'rant', word_class: 'N' },
    { word: 'rant', word_class: 'V' },
    { word: 'ranter', word_class: 'N' },
    { word: 'ranting', word_class: 'N' }
  ],
  assay: [
    { word: 'assay', word_class: 'N' },
    { word: 'assay', word_class: 'V' },
    { word: 'assai', word_class: 'AV' },
    { word: 'assayal', word_class: 'N' }
  ],
  filament: [
    { word: 'filament', word_class: 'N' },
    { word: 'filamentary', word_class: 'AJ' },
    { word: 'filamentous', word_class: 'AJ' }
  ],
  atheist: [
    { word: 'atheist', word_class: 'N' },
    { word: 'atheist', word_class: 'AJ' },
    { word: 'atheistic', word_class: 'AJ' },
    { word: 'atheistical', word_class: 'AJ' }
  ],
  appiontment: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  apparency: [
    { word: 'apparency', word_class: 'N' },
    { word: 'apparent', word_class: 'AJ' },
    { word: 'apparently', word_class: 'AV' },
    { word: 'apparentness', word_class: 'N' }
  ],
  swallow: [
    { word: 'swallow', word_class: 'N' },
    { word: 'swallow', word_class: 'V' },
    { word: 'swallowed', word_class: 'AJ' },
    { word: 'deglutition', word_class: 'N' }
  ],
  alarming: [
    { word: 'alarm', word_class: 'N' },
    { word: 'alarm', word_class: 'V' },
    { word: 'alarmism', word_class: 'N' },
    { word: 'alarmist', word_class: 'N' },
    { word: 'alarmed', word_class: 'AJ' },
    { word: 'alarming', word_class: 'AJ' }
  ],
  depict: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  precipitous: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  idyllic: [
    { word: 'idyll', word_class: 'N' },
    { word: 'idyllic', word_class: 'AJ' },
    { word: 'idyllically', word_class: 'AV' }
  ],
  discolorize: [
    { word: 'discolor', word_class: 'V' },
    { word: 'discolored', word_class: 'AJ' },
    { word: 'discolorize', word_class: 'V' },
    { word: 'discoloration', word_class: 'N' }
  ],
  receptiveness: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  complaintive: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  libido: [
    { word: 'libido', word_class: 'N' },
    { word: 'libidinal', word_class: 'AJ' },
    { word: 'libidinous', word_class: 'AJ' }
  ],
  outfitting: [
    { word: 'outfit', word_class: 'N' },
    { word: 'outfit', word_class: 'V' },
    { word: 'outfitted', word_class: 'AJ' },
    { word: 'outfitting', word_class: 'N' }
  ],
  oscillation: [
    { word: 'oscillate', word_class: 'V' },
    { word: 'oscillator', word_class: 'N' },
    { word: 'oscillation', word_class: 'N' },
    { word: 'oscillating', word_class: 'AJ' }
  ],
  stockholdings: [
    { word: 'stockholder', word_class: 'N' },
    { word: 'stockholding', word_class: 'N' },
    { word: 'stockholdings', word_class: 'N' }
  ],
  monochrome: [
    { word: 'monochrome', word_class: 'N' },
    { word: 'monochrome', word_class: 'AJ' },
    { word: 'monochromic', word_class: 'AJ' },
    { word: 'monochromous', word_class: 'AJ' }
  ],
  unreasonably: [
    { word: 'unreason', word_class: 'N' },
    { word: 'unreasoning', word_class: 'AJ' },
    { word: 'unreasonable', word_class: 'AJ' },
    { word: 'unreasonably', word_class: 'AV' }
  ],
  bacterial: [
    { word: 'bacteria', word_class: 'N' },
    { word: 'bacterial', word_class: 'AJ' },
    { word: 'bacterially', word_class: 'AV' }
  ],
  defect: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  conformity: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  achiever: [
    { word: 'achieve', word_class: 'V' },
    { word: 'achiever', word_class: 'N' },
    { word: 'achievable', word_class: 'AJ' },
    { word: 'achievement', word_class: 'N' },
    { word: 'achievability', word_class: 'N' }
  ],
  adversion: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  approbation: [
    { word: 'approbate', word_class: 'V' },
    { word: 'approbation', word_class: 'N' },
    { word: 'approbative', word_class: 'AJ' }
  ],
  permeability: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  meteorological: [
    { word: 'meteorology', word_class: 'N' },
    { word: 'meteorologic', word_class: 'AJ' },
    { word: 'meteorological', word_class: 'AJ' },
    { word: 'meteorologically', word_class: 'AV' }
  ],
  exorbitant: [
    { word: 'exorbitant', word_class: 'N' },
    { word: 'exorbitance', word_class: 'N' },
    { word: 'exorbitant', word_class: 'AJ' }
  ],
  crush: [
    { word: 'crush', word_class: 'N' },
    { word: 'crush', word_class: 'V' },
    { word: 'crusher', word_class: 'N' },
    { word: 'crushed', word_class: 'AJ' },
    { word: 'crushing', word_class: 'N' },
    { word: 'crushing', word_class: 'AJ' }
  ],
  feverous: [
    { word: 'fever', word_class: 'N' },
    { word: 'fever', word_class: 'V' },
    { word: 'fevered', word_class: 'AJ' },
    { word: 'feverous', word_class: 'AJ' }
  ],
  energization: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  acceptation: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  stiffener: [
    { word: 'stiffen', word_class: 'V' },
    { word: 'stiffener', word_class: 'N' },
    { word: 'stiffened', word_class: 'AJ' },
    { word: 'stiffening', word_class: 'N' }
  ],
  incapability: [
    { word: 'incapable', word_class: 'AJ' },
    { word: 'incapability', word_class: 'N' },
    { word: 'incapableness', word_class: 'N' }
  ],
  liberality: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  fortuitous: [
    { word: 'fortuitous', word_class: 'AJ' },
    { word: 'fortuitously', word_class: 'AV' },
    { word: 'fortuitousness', word_class: 'N' }
  ],
  sure: [
    { word: 'sure', word_class: 'AJ' },
    { word: 'sure', word_class: 'AV' },
    { word: 'surety', word_class: 'N' },
    { word: 'surely', word_class: 'AV' },
    { word: 'sureness', word_class: 'N' }
  ],
  appraising: [
    { word: 'appraise', word_class: 'V' },
    { word: 'appraisal', word_class: 'N' },
    { word: 'appraiser', word_class: 'N' },
    { word: 'appraising', word_class: 'AJ' }
  ],
  looped: [
    { word: 'loop', word_class: 'N' },
    { word: 'loop', word_class: 'V' },
    { word: 'looper', word_class: 'N' },
    { word: 'looped', word_class: 'AJ' }
  ],
  absorb: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  sulphuric: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  owner: [
    { word: 'own', word_class: 'V' },
    { word: 'own', word_class: 'AJ' },
    { word: 'owner', word_class: 'N' },
    { word: 'owned', word_class: 'AJ' }
  ],
  inconsiderable: [
    { word: 'inconsiderate', word_class: 'AJ' },
    { word: 'inconsiderable', word_class: 'AJ' },
    { word: 'inconsideration', word_class: 'N' },
    { word: 'inconsiderately', word_class: 'AV' },
    { word: 'inconsiderateness', word_class: 'N' }
  ],
  flame: [
    { word: 'flame', word_class: 'N' },
    { word: 'flame', word_class: 'V' },
    { word: 'flaming', word_class: 'N' },
    { word: 'flaming', word_class: 'AJ' },
    { word: 'flammable', word_class: 'AJ' },
    { word: 'flammability', word_class: 'N' },
    { word: 'flammability', word_class: 'AJ' }
  ],
  animate: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  regimental: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  favourite: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  hellenic: [
    { word: 'hellene', word_class: 'N' },
    { word: 'hellenic', word_class: 'N' },
    { word: 'hellenic', word_class: 'AJ' },
    { word: 'hellenism', word_class: 'N' },
    { word: 'hellenize', word_class: 'V' },
    { word: 'hellenization', word_class: 'N' }
  ],
  mercuric: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  figurative: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  illegalization: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  schematise: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  thereness: [
    { word: 'there', word_class: 'N' },
    { word: 'there', word_class: 'AV' },
    { word: 'thereness', word_class: 'N' }
  ],
  moron: [
    { word: 'moron', word_class: 'N' },
    { word: 'morone', word_class: 'N' },
    { word: 'moronic', word_class: 'AJ' },
    { word: 'moronity', word_class: 'N' }
  ],
  anagram: [
    { word: 'anagram', word_class: 'N' },
    { word: 'anagram', word_class: 'V' },
    { word: 'anagrams', word_class: 'N' }
  ],
  reputability: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  excusably: [
    { word: 'excuse', word_class: 'N' },
    { word: 'excuse', word_class: 'V' },
    { word: 'excused', word_class: 'AJ' },
    { word: 'excusable', word_class: 'AJ' },
    { word: 'excusably', word_class: 'AV' }
  ],
  fearful: [
    { word: 'fear', word_class: 'N' },
    { word: 'fear', word_class: 'V' },
    { word: 'fearful', word_class: 'AJ' },
    { word: 'fearsome', word_class: 'AJ' },
    { word: 'fearfulness', word_class: 'N' },
    { word: 'fearsomely', word_class: 'AV' }
  ],
  university: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  relational: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  communist: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  starkness: [
    { word: 'stark', word_class: 'AJ' },
    { word: 'stark', word_class: 'AV' },
    { word: 'starkness', word_class: 'N' }
  ],
  pragmatic: [
    { word: 'pragmatic', word_class: 'AJ' },
    { word: 'pragmatics', word_class: 'N' },
    { word: 'pragmatism', word_class: 'N' },
    { word: 'pragmatical', word_class: 'AJ' },
    { word: 'pragmatically', word_class: 'AV' }
  ],
  frighten: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  rotational: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  rooted: [
    { word: 'root', word_class: 'N' },
    { word: 'root', word_class: 'V' },
    { word: 'root', word_class: 'AJ' },
    { word: 'rooted', word_class: 'AJ' },
    { word: 'rooting', word_class: 'N' }
  ],
  effected: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  allotropism: [
    { word: 'allotrope', word_class: 'N' },
    { word: 'allotropic', word_class: 'AJ' },
    { word: 'allotropism', word_class: 'N' },
    { word: 'allotropical', word_class: 'AJ' }
  ],
  brocade: [
    { word: 'brocade', word_class: 'N' },
    { word: 'brocade', word_class: 'V' },
    { word: 'brocaded', word_class: 'AJ' }
  ],
  cession: [
    { word: 'cede', word_class: 'V' },
    { word: 'ceding', word_class: 'N' },
    { word: 'cession', word_class: 'N' }
  ],
  fulfilment: [
    { word: 'fulfil', word_class: 'V' },
    { word: 'fulfill', word_class: 'V' },
    { word: 'fulfilled', word_class: 'AJ' },
    { word: 'fulfilment', word_class: 'N' },
    { word: 'fulfillment', word_class: 'N' }
  ],
  modulation: [
    { word: 'module', word_class: 'N' },
    { word: 'modular', word_class: 'AJ' },
    { word: 'modulate', word_class: 'V' },
    { word: 'modulated', word_class: 'AJ' },
    { word: 'modulation', word_class: 'N' }
  ],
  wide: [
    { word: 'wide', word_class: 'AJ' },
    { word: 'wide', word_class: 'AV' },
    { word: 'width', word_class: 'N' },
    { word: 'widely', word_class: 'AV' },
    { word: 'wideness', word_class: 'N' }
  ],
  abomination: [
    { word: 'abominate', word_class: 'V' },
    { word: 'abominator', word_class: 'N' },
    { word: 'abominable', word_class: 'AJ' },
    { word: 'abominably', word_class: 'AV' },
    { word: 'abomination', word_class: 'N' }
  ],
  bothered: [
    { word: 'bother', word_class: 'N' },
    { word: 'bother', word_class: 'V' },
    { word: 'bothered', word_class: 'AJ' },
    { word: 'botheration', word_class: 'N' },
    { word: 'bothersome', word_class: 'AJ' }
  ],
  chelated: [
    { word: 'chelate', word_class: 'N' },
    { word: 'chelate', word_class: 'V' },
    { word: 'chelate', word_class: 'AJ' },
    { word: 'chelated', word_class: 'AJ' },
    { word: 'chelation', word_class: 'N' }
  ],
  crackled: [
    { word: 'crackle', word_class: 'N' },
    { word: 'crackle', word_class: 'V' },
    { word: 'crackle', word_class: 'AJ' },
    { word: 'crackling', word_class: 'N' },
    { word: 'crackled', word_class: 'AJ' },
    { word: 'crackling', word_class: 'AJ' },
    { word: 'cracklings', word_class: 'N' }
  ],
  unsociableness: [
    { word: 'unsocial', word_class: 'AJ' },
    { word: 'unsociable', word_class: 'AJ' },
    { word: 'unsociably', word_class: 'AV' },
    { word: 'unsociability', word_class: 'N' },
    { word: 'unsociableness', word_class: 'N' }
  ],
  looseness: [
    { word: 'loose', word_class: 'V' },
    { word: 'loose', word_class: 'AJ' },
    { word: 'loose', word_class: 'AV' },
    { word: 'loosely', word_class: 'AV' },
    { word: 'looseness', word_class: 'N' }
  ],
  defection: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  smoker: [
    { word: 'smoke', word_class: 'N' },
    { word: 'smoke', word_class: 'V' },
    { word: 'smoker', word_class: 'N' },
    { word: 'smoky', word_class: 'AJ' },
    { word: 'smoked', word_class: 'AJ' },
    { word: 'smoking', word_class: 'N' },
    { word: 'smoking', word_class: 'AJ' }
  ],
  atrociousness: [
    { word: 'atrocious', word_class: 'AJ' },
    { word: 'atrociously', word_class: 'AV' },
    { word: 'atrociousness', word_class: 'N' }
  ],
  spirits: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  illustrative: [
    { word: 'illustrate', word_class: 'V' },
    { word: 'illustrator', word_class: 'N' },
    { word: 'illustrated', word_class: 'AJ' },
    { word: 'illustration', word_class: 'N' },
    { word: 'illustrative', word_class: 'AJ' }
  ],
  swaggering: [
    { word: 'swagger', word_class: 'N' },
    { word: 'swagger', word_class: 'V' },
    { word: 'swagger', word_class: 'AJ' },
    { word: 'swaggerer', word_class: 'N' },
    { word: 'swaggering', word_class: 'AJ' }
  ],
  incorrupt: [
    { word: 'incorrupt', word_class: 'AJ' },
    { word: 'incorruption', word_class: 'N' },
    { word: 'incorruptness', word_class: 'N' },
    { word: 'incorruptible', word_class: 'AJ' },
    { word: 'incorruptibility', word_class: 'N' }
  ],
  minimization: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  casuistical: [
    { word: 'casuist', word_class: 'N' },
    { word: 'casuistic', word_class: 'AJ' },
    { word: 'casuistical', word_class: 'AJ' }
  ],
  exhortation: [
    { word: 'exhort', word_class: 'V' },
    { word: 'exhortation', word_class: 'N' },
    { word: 'exhortative', word_class: 'AJ' }
  ],
  enthrone: [
    { word: 'enthrone', word_class: 'V' },
    { word: 'enthronement', word_class: 'N' },
    { word: 'enthronization', word_class: 'N' }
  ],
  feminist: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  intensificatiion: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  attractiveness: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  centrifugate: [
    { word: 'centrifuge', word_class: 'N' },
    { word: 'centrifuge', word_class: 'V' },
    { word: 'centrifugal', word_class: 'AJ' },
    { word: 'centrifugate', word_class: 'V' },
    { word: 'centrifugation', word_class: 'N' }
  ],
  institutionalisation: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  jaywalker: [
    { word: 'jaywalk', word_class: 'V' },
    { word: 'jaywalker', word_class: 'N' },
    { word: 'jaywalking', word_class: 'N' }
  ],
  seedman: [
    { word: 'seed', word_class: 'N' },
    { word: 'seed', word_class: 'V' },
    { word: 'seeder', word_class: 'N' },
    { word: 'seeded', word_class: 'AJ' },
    { word: 'seedman', word_class: 'N' }
  ],
  fungus: [
    { word: 'fungus', word_class: 'N' },
    { word: 'fungal', word_class: 'AJ' },
    { word: 'fungicide', word_class: 'N' },
    { word: 'fungicidal', word_class: 'AJ' }
  ],
  commercialism: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  unreliability: [
    { word: 'unreliable', word_class: 'AJ' },
    { word: 'unreliably', word_class: 'AV' },
    { word: 'unreliability', word_class: 'N' },
    { word: 'unreliableness', word_class: 'N' }
  ],
  enthronement: [
    { word: 'enthrone', word_class: 'V' },
    { word: 'enthronement', word_class: 'N' },
    { word: 'enthronization', word_class: 'N' }
  ],
  maligner: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  disembarkment: [
    { word: 'disembark', word_class: 'V' },
    { word: 'disembarkment', word_class: 'N' },
    { word: 'disembarkation', word_class: 'N' }
  ],
  preexist: [
    { word: 'preexist', word_class: 'V' },
    { word: 'preexistence', word_class: 'N' },
    { word: 'preexistent', word_class: 'AJ' }
  ],
  circumvent: [
    { word: 'circumvent', word_class: 'N' },
    { word: 'circumvent', word_class: 'V' },
    { word: 'circumvention', word_class: 'N' }
  ],
  adherent: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  encroach: [
    { word: 'encroach', word_class: 'V' },
    { word: 'encroacher', word_class: 'N' },
    { word: 'encroaching', word_class: 'AJ' },
    { word: 'encroachment', word_class: 'N' }
  ],
  classical: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  mating: [
    { word: 'mate', word_class: 'V' },
    { word: 'mate', word_class: 'N' },
    { word: 'mater', word_class: 'N' },
    { word: 'mates', word_class: 'N' },
    { word: 'mated', word_class: 'AJ' },
    { word: 'mating', word_class: 'N' }
  ],
  adsorbable: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  appraiser: [
    { word: 'appraise', word_class: 'V' },
    { word: 'appraisal', word_class: 'N' },
    { word: 'appraiser', word_class: 'N' },
    { word: 'appraising', word_class: 'AJ' }
  ],
  witnessed: [
    { word: 'wit', word_class: 'N' },
    { word: 'wit', word_class: 'V' },
    { word: 'witness', word_class: 'V' },
    { word: 'witness', word_class: 'N' },
    { word: 'witting', word_class: 'AJ' },
    { word: 'witnessed', word_class: 'AJ' }
  ],
  decarbonisation: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  flare: [
    { word: 'flare', word_class: 'N' },
    { word: 'flare', word_class: 'V' },
    { word: 'flared', word_class: 'AJ' },
    { word: 'flaring', word_class: 'AJ' }
  ],
  idiomatic: [
    { word: 'idiomatic', word_class: 'AJ' },
    { word: 'idiomatical', word_class: 'AJ' },
    { word: 'idiomatically', word_class: 'AV' }
  ],
  dishonorableness: [
    { word: 'dishonor', word_class: 'N' },
    { word: 'dishonor', word_class: 'V' },
    { word: 'dishonored', word_class: 'AJ' },
    { word: 'dishonorable', word_class: 'AJ' },
    { word: 'dishonorably', word_class: 'AV' },
    { word: 'dishonorableness', word_class: 'N' }
  ],
  acronymic: [
    { word: 'acronym', word_class: 'N' },
    { word: 'acronymic', word_class: 'AJ' },
    { word: 'acronymous', word_class: 'AJ' }
  ],
  enure: [
    { word: 'enure', word_class: 'V' },
    { word: 'enured', word_class: 'AJ' },
    { word: 'enurement', word_class: 'N' }
  ],
  assemble: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  serenity: [
    { word: 'serene', word_class: 'AJ' },
    { word: 'serenity', word_class: 'N' },
    { word: 'serenely', word_class: 'AV' }
  ],
  disgusted: [
    { word: 'disgust', word_class: 'N' },
    { word: 'disgust', word_class: 'V' },
    { word: 'disgusted', word_class: 'AJ' },
    { word: 'disgustful', word_class: 'AJ' },
    { word: 'disgusting', word_class: 'AJ' }
  ],
  inauspicious: [
    { word: 'inauspicious', word_class: 'AJ' },
    { word: 'inauspiciously', word_class: 'AV' },
    { word: 'inauspiciousness', word_class: 'N' }
  ],
  car: [{ word: 'car', word_class: 'N' }, { word: 'carful', word_class: 'N' }, { word: 'carful', word_class: 'AJ' }],
  consubstantiation: [
    { word: 'consubstantial', word_class: 'AJ' },
    { word: 'consubstantiate', word_class: 'V' },
    { word: 'consubstantiation', word_class: 'N' }
  ],
  attentively: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  feelings: [
    { word: 'feel', word_class: 'N' },
    { word: 'feel', word_class: 'V' },
    { word: 'feeler', word_class: 'N' },
    { word: 'feeling', word_class: 'N' },
    { word: 'feelings', word_class: 'N' }
  ],
  culpably: [
    { word: 'culpable', word_class: 'AJ' },
    { word: 'culpably', word_class: 'AV' },
    { word: 'culpability', word_class: 'N' },
    { word: 'culpableness', word_class: 'N' }
  ],
  lapful: [
    { word: 'lap', word_class: 'N' },
    { word: 'lap', word_class: 'V' },
    { word: 'lapful', word_class: 'N' },
    { word: 'lapful', word_class: 'AJ' },
    { word: 'lapping', word_class: 'N' }
  ],
  nymphomaniac: [
    { word: 'nymphomaniac', word_class: 'N' },
    { word: 'nymphomaniac', word_class: 'AJ' },
    { word: 'nymphomaniacal', word_class: 'AJ' }
  ],
  pectisation: [
    { word: 'pectise', word_class: 'V' },
    { word: 'pectize', word_class: 'V' },
    { word: 'pectisation', word_class: 'N' },
    { word: 'pectization', word_class: 'N' }
  ],
  artery: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  exchange: [
    { word: 'exchange', word_class: 'N' },
    { word: 'exchange', word_class: 'V' },
    { word: 'exchanger', word_class: 'N' },
    { word: 'exchanged', word_class: 'AJ' },
    { word: 'exchangeable', word_class: 'AJ' },
    { word: 'exchangeability', word_class: 'N' }
  ],
  fretted: [
    { word: 'fret', word_class: 'N' },
    { word: 'fret', word_class: 'V' },
    { word: 'fretful', word_class: 'AJ' },
    { word: 'fretted', word_class: 'AJ' },
    { word: 'fretfulness', word_class: 'N' }
  ],
  foul: [
    { word: 'foul', word_class: 'N' },
    { word: 'foul', word_class: 'V' },
    { word: 'foul', word_class: 'AJ' },
    { word: 'fouled', word_class: 'AJ' },
    { word: 'foulness', word_class: 'N' }
  ],
  craved: [
    { word: 'crave', word_class: 'V' },
    { word: 'craved', word_class: 'AJ' },
    { word: 'craving', word_class: 'N' }
  ],
  tempering: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  injectant: [
    { word: 'inject', word_class: 'V' },
    { word: 'injectant', word_class: 'N' },
    { word: 'injection', word_class: 'N' },
    { word: 'injectable', word_class: 'AJ' }
  ],
  stator: [
    { word: 'state', word_class: 'N' },
    { word: 'state', word_class: 'V' },
    { word: 'state', word_class: 'AJ' },
    { word: 'stator', word_class: 'N' },
    { word: 'stated', word_class: 'AJ' },
    { word: 'stately', word_class: 'AJ' },
    { word: 'statement', word_class: 'N' }
  ],
  stigmatize: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  coarsely: [
    { word: 'coarse', word_class: 'AJ' },
    { word: 'coarsely', word_class: 'AV' },
    { word: 'coarseness', word_class: 'N' }
  ],
  broiler: [
    { word: 'broil', word_class: 'N' },
    { word: 'broil', word_class: 'V' },
    { word: 'broiler', word_class: 'N' },
    { word: 'broiled', word_class: 'AJ' },
    { word: 'broiling', word_class: 'N' }
  ],
  butchery: [
    { word: 'butcher', word_class: 'N' },
    { word: 'butcher', word_class: 'V' },
    { word: 'butchery', word_class: 'N' },
    { word: 'butchering', word_class: 'N' }
  ],
  agonistical: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  soundman: [
    { word: 'sound', word_class: 'N' },
    { word: 'sound', word_class: 'V' },
    { word: 'sound', word_class: 'AJ' },
    { word: 'sound', word_class: 'AV' },
    { word: 'sounded', word_class: 'AJ' },
    { word: 'sounding', word_class: 'N' },
    { word: 'soundman', word_class: 'N' },
    { word: 'sounding', word_class: 'AJ' },
    { word: 'soundness', word_class: 'N' }
  ],
  procurable: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  molarity: [
    { word: 'molar', word_class: 'N' },
    { word: 'molar', word_class: 'AJ' },
    { word: 'molarity', word_class: 'N' }
  ],
  restorative: [
    { word: 'restore', word_class: 'N' },
    { word: 'restore', word_class: 'V' },
    { word: 'restorer', word_class: 'N' },
    { word: 'restored', word_class: 'AJ' },
    { word: 'restoration', word_class: 'N' },
    { word: 'restorative', word_class: 'N' },
    { word: 'restorative', word_class: 'AJ' }
  ],
  mutualize: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  embarrass: [
    { word: 'embarrass', word_class: 'V' },
    { word: 'embarrassed', word_class: 'AJ' },
    { word: 'embarrassing', word_class: 'AJ' },
    { word: 'embarrassment', word_class: 'N' }
  ],
  approve: [
    { word: 'approve', word_class: 'V' },
    { word: 'approval', word_class: 'N' },
    { word: 'approved', word_class: 'AJ' },
    { word: 'approving', word_class: 'N' },
    { word: 'approving', word_class: 'AJ' }
  ],
  unload: [
    { word: 'unload', word_class: 'V' },
    { word: 'unloaded', word_class: 'AJ' },
    { word: 'unloading', word_class: 'N' },
    { word: 'unloading', word_class: 'AJ' }
  ],
  sneaky: [
    { word: 'sneak', word_class: 'N' },
    { word: 'sneak', word_class: 'V' },
    { word: 'sneak', word_class: 'AJ' },
    { word: 'sneaker', word_class: 'N' },
    { word: 'sneaky', word_class: 'AJ' },
    { word: 'sneaking', word_class: 'AJ' },
    { word: 'sneakiness', word_class: 'N' }
  ],
  venerability: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  bitter: [
    { word: 'bitter', word_class: 'N' },
    { word: 'bitter', word_class: 'V' },
    { word: 'bitter', word_class: 'AJ' },
    { word: 'bitter', word_class: 'AV' },
    { word: 'bitters', word_class: 'N' },
    { word: 'bitterness', word_class: 'N' }
  ],
  commonplaceness: [
    { word: 'commonplace', word_class: 'N' },
    { word: 'commonplace', word_class: 'AJ' },
    { word: 'commonplaceness', word_class: 'N' }
  ],
  voluminousness: [
    { word: 'volume', word_class: 'N' },
    { word: 'voluminous', word_class: 'AJ' },
    { word: 'voluminousness', word_class: 'N' }
  ],
  fleece: [
    { word: 'fleece', word_class: 'N' },
    { word: 'fleece', word_class: 'V' },
    { word: 'fleecy', word_class: 'AJ' }
  ],
  vesication: [
    { word: 'vesical', word_class: 'AJ' },
    { word: 'vesicant', word_class: 'N' },
    { word: 'vesicate', word_class: 'V' },
    { word: 'vesicant', word_class: 'AJ' },
    { word: 'vesication', word_class: 'N' }
  ],
  discolor: [
    { word: 'discolor', word_class: 'V' },
    { word: 'discolored', word_class: 'AJ' },
    { word: 'discolorize', word_class: 'V' },
    { word: 'discoloration', word_class: 'N' }
  ],
  insensitive: [
    { word: 'insensitive', word_class: 'AJ' },
    { word: 'insensitivity', word_class: 'N' },
    { word: 'insensitively', word_class: 'AV' },
    { word: 'insensitiveness', word_class: 'N' }
  ],
  literalness: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  cultured: [
    { word: 'culture', word_class: 'N' },
    { word: 'culture', word_class: 'V' },
    { word: 'cultural', word_class: 'AJ' },
    { word: 'cultured', word_class: 'AJ' },
    { word: 'culturally', word_class: 'AV' }
  ],
  respond: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  grooving: [
    { word: 'groove', word_class: 'N' },
    { word: 'groove', word_class: 'V' },
    { word: 'groover', word_class: 'N' },
    { word: 'grooved', word_class: 'AJ' },
    { word: 'grooving', word_class: 'N' }
  ],
  presence: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  assimilate: [
    { word: 'assimilate', word_class: 'V' },
    { word: 'assimilable', word_class: 'AJ' },
    { word: 'assimilation', word_class: 'N' },
    { word: 'assimilating', word_class: 'AJ' },
    { word: 'assimilative', word_class: 'AJ' }
  ],
  cyclic: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  lessen: [
    { word: 'lessen', word_class: 'V' },
    { word: 'lessened', word_class: 'AJ' },
    { word: 'lessening', word_class: 'N' },
    { word: 'lessening', word_class: 'AJ' }
  ],
  straggle: [
    { word: 'straggle', word_class: 'N' },
    { word: 'straggle', word_class: 'V' },
    { word: 'straggler', word_class: 'N' },
    { word: 'straggling', word_class: 'AJ' }
  ],
  deputize: [
    { word: 'depute', word_class: 'V' },
    { word: 'deputy', word_class: 'N' },
    { word: 'deputy', word_class: 'AJ' },
    { word: 'deputize', word_class: 'V' },
    { word: 'deputation', word_class: 'N' },
    { word: 'deputization', word_class: 'N' }
  ],
  important: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  adultery: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  elite: [
    { word: 'elite', word_class: 'N' },
    { word: 'elite', word_class: 'AJ' },
    { word: 'elitism', word_class: 'N' }
  ],
  fucker: [
    { word: 'fuck', word_class: 'N' },
    { word: 'fuck', word_class: 'V' },
    { word: 'fucker', word_class: 'N' },
    { word: 'fucking', word_class: 'N' },
    { word: 'fucking', word_class: 'AJ' },
    { word: 'fucking', word_class: 'AV' }
  ],
  accessory: [
    { word: 'accessory', word_class: 'N' },
    { word: 'accessory', word_class: 'AJ' },
    { word: 'accessorial', word_class: 'AJ' }
  ],
  strangely: [
    { word: 'strange', word_class: 'AJ' },
    { word: 'strangely', word_class: 'AV' },
    { word: 'strangeness', word_class: 'N' }
  ],
  misused: [
    { word: 'misuse', word_class: 'N' },
    { word: 'misuse', word_class: 'V' },
    { word: 'misused', word_class: 'AJ' }
  ],
  accentual: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  transaction: [
    { word: 'transact', word_class: 'N' },
    { word: 'transact', word_class: 'V' },
    { word: 'transaction', word_class: 'N' },
    { word: 'transactions', word_class: 'N' }
  ],
  anticoagulation: [
    { word: 'anticoagulant', word_class: 'N' },
    { word: 'anticoagulation', word_class: 'N' },
    { word: 'anticoagulative', word_class: 'AJ' }
  ],
  bomber: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  rig: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  whelped: [
    { word: 'whelp', word_class: 'N' },
    { word: 'whelp', word_class: 'V' },
    { word: 'whelped', word_class: 'AJ' }
  ],
  ingraft: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  distraint: [
    { word: 'distrain', word_class: 'V' },
    { word: 'distraint', word_class: 'N' },
    { word: 'distrainment', word_class: 'N' }
  ],
  inoculating: [
    { word: 'inoculate', word_class: 'V' },
    { word: 'inoculating', word_class: 'N' },
    { word: 'inoculation', word_class: 'N' }
  ],
  catcher: [
    { word: 'catch', word_class: 'N' },
    { word: 'catch', word_class: 'V' },
    { word: 'catcher', word_class: 'N' },
    { word: 'catching', word_class: 'N' },
    { word: 'catching', word_class: 'AJ' },
    { word: 'catchment', word_class: 'N' }
  ],
  minuteness: [
    { word: 'minute', word_class: 'N' },
    { word: 'minute', word_class: 'V' },
    { word: 'minute', word_class: 'AJ' },
    { word: 'minutes', word_class: 'N' },
    { word: 'minutely', word_class: 'AV' },
    { word: 'minuteman', word_class: 'N' },
    { word: 'minuteness', word_class: 'N' }
  ],
  resentful: [
    { word: 'resent', word_class: 'V' },
    { word: 'resentful', word_class: 'AJ' },
    { word: 'resentment', word_class: 'N' }
  ],
  gullibly: [
    { word: 'gullible', word_class: 'AJ' },
    { word: 'gullibly', word_class: 'AV' },
    { word: 'gullibility', word_class: 'N' }
  ],
  cackler: [
    { word: 'cackle', word_class: 'N' },
    { word: 'cackle', word_class: 'V' },
    { word: 'cackler', word_class: 'N' }
  ],
  circumscribed: [
    { word: 'circumscribe', word_class: 'N' },
    { word: 'circumscribe', word_class: 'V' },
    { word: 'circumscribed', word_class: 'AJ' },
    { word: 'circumscription', word_class: 'N' }
  ],
  blood: [
    { word: 'blood', word_class: 'N' },
    { word: 'blood', word_class: 'V' },
    { word: 'bloody', word_class: 'V' },
    { word: 'bloody', word_class: 'AJ' },
    { word: 'bloody', word_class: 'AV' },
    { word: 'blooded', word_class: 'AJ' },
    { word: 'bloodied', word_class: 'AJ' }
  ],
  hysterics: [
    { word: 'hysteric', word_class: 'AJ' },
    { word: 'hysterics', word_class: 'N' },
    { word: 'hysterical', word_class: 'AJ' },
    { word: 'hysterically', word_class: 'AV' }
  ],
  foreordination: [
    { word: 'foreordain', word_class: 'V' },
    { word: 'foreordained', word_class: 'AJ' },
    { word: 'foreordainment', word_class: 'N' },
    { word: 'foreordination', word_class: 'N' }
  ],
  enthral: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  unsociable: [
    { word: 'unsocial', word_class: 'AJ' },
    { word: 'unsociable', word_class: 'AJ' },
    { word: 'unsociably', word_class: 'AV' },
    { word: 'unsociability', word_class: 'N' },
    { word: 'unsociableness', word_class: 'N' }
  ],
  punctilious: [
    { word: 'punctilious', word_class: 'AJ' },
    { word: 'punctiliously', word_class: 'AV' },
    { word: 'punctiliousness', word_class: 'N' }
  ],
  easterly: [
    { word: 'easterly', word_class: 'N' },
    { word: 'easterly', word_class: 'AJ' },
    { word: 'easterly', word_class: 'AV' }
  ],
  eloign: [
    { word: 'eloin', word_class: 'V' },
    { word: 'eloign', word_class: 'V' },
    { word: 'eloinment', word_class: 'N' },
    { word: 'eloignment', word_class: 'N' }
  ],
  bugginess: [
    { word: 'buggy', word_class: 'N' },
    { word: 'buggy', word_class: 'AJ' },
    { word: 'bugginess', word_class: 'N' }
  ],
  jest: [
    { word: 'jest', word_class: 'N' },
    { word: 'jest', word_class: 'V' },
    { word: 'jester', word_class: 'N' },
    { word: 'jesting', word_class: 'AJ' }
  ],
  appraise: [
    { word: 'appraise', word_class: 'V' },
    { word: 'appraisal', word_class: 'N' },
    { word: 'appraiser', word_class: 'N' },
    { word: 'appraising', word_class: 'AJ' }
  ],
  impermissibility: [
    { word: 'impermissible', word_class: 'AJ' },
    { word: 'impermissibly', word_class: 'AV' },
    { word: 'impermissibility', word_class: 'N' }
  ],
  vivacity: [
    { word: 'vivace', word_class: 'AJ' },
    { word: 'vivace', word_class: 'AV' },
    { word: 'vivacity', word_class: 'N' }
  ],
  satisfying: [
    { word: 'satisfy', word_class: 'V' },
    { word: 'satisfied', word_class: 'AJ' },
    { word: 'satisfying', word_class: 'N' },
    { word: 'satisfying', word_class: 'AJ' },
    { word: 'satisfaction', word_class: 'N' },
    { word: 'satisfiable', word_class: 'AJ' }
  ],
  detox: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  tumbler: [
    { word: 'tumble', word_class: 'N' },
    { word: 'tumble', word_class: 'V' },
    { word: 'tumbler', word_class: 'N' },
    { word: 'tumbling', word_class: 'N' },
    { word: 'tumbling', word_class: 'AJ' }
  ],
  dock: [
    { word: 'dock', word_class: 'N' },
    { word: 'dock', word_class: 'V' },
    { word: 'docker', word_class: 'N' },
    { word: 'dockage', word_class: 'N' },
    { word: 'docked', word_class: 'AJ' },
    { word: 'docking', word_class: 'N' }
  ],
  district: [
    { word: 'district', word_class: 'N' },
    { word: 'district', word_class: 'V' },
    { word: 'district', word_class: 'AJ' }
  ],
  actress: [
    { word: 'act', word_class: 'N' },
    { word: 'act', word_class: 'V' },
    { word: 'acts', word_class: 'N' },
    { word: 'actor', word_class: 'N' },
    { word: 'acting', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'actress', word_class: 'N' }
  ],
  production: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  abate: [
    { word: 'abate', word_class: 'V' },
    { word: 'abatic', word_class: 'AJ' },
    { word: 'abating', word_class: 'AJ' },
    { word: 'abatement', word_class: 'N' }
  ],
  bulk: [{ word: 'bulk', word_class: 'N' }, { word: 'bulk', word_class: 'V' }, { word: 'bulk', word_class: 'AJ' }],
  fulfil: [
    { word: 'fulfil', word_class: 'V' },
    { word: 'fulfill', word_class: 'V' },
    { word: 'fulfilled', word_class: 'AJ' },
    { word: 'fulfilment', word_class: 'N' },
    { word: 'fulfillment', word_class: 'N' }
  ],
  torpidity: [
    { word: 'torpid', word_class: 'AJ' },
    { word: 'torpidity', word_class: 'N' },
    { word: 'torpidness', word_class: 'N' }
  ],
  subjection: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  disenthrallment: [
    { word: 'disenthral', word_class: 'V' },
    { word: 'disenthrall', word_class: 'V' },
    { word: 'disenthralment', word_class: 'N' },
    { word: 'disenthrallment', word_class: 'N' }
  ],
  fallen: [
    { word: 'fall', word_class: 'N' },
    { word: 'fall', word_class: 'V' },
    { word: 'falls', word_class: 'N' },
    { word: 'fallal', word_class: 'N' },
    { word: 'fallen', word_class: 'AJ' },
    { word: 'falling', word_class: 'N' },
    { word: 'falling', word_class: 'AJ' }
  ],
  soaked: [
    { word: 'soak', word_class: 'N' },
    { word: 'soak', word_class: 'V' },
    { word: 'soaker', word_class: 'N' },
    { word: 'soaked', word_class: 'AJ' },
    { word: 'soaking', word_class: 'N' },
    { word: 'soaking', word_class: 'AJ' },
    { word: 'soaking', word_class: 'AV' }
  ],
  oriental: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  providential: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  courageousness: [
    { word: 'courage', word_class: 'N' },
    { word: 'courageous', word_class: 'AJ' },
    { word: 'courageously', word_class: 'AV' },
    { word: 'courageousness', word_class: 'N' }
  ],
  mean: [
    { word: 'mean', word_class: 'N' },
    { word: 'mean', word_class: 'V' },
    { word: 'mean', word_class: 'AJ' },
    { word: 'means', word_class: 'N' },
    { word: 'meaning', word_class: 'N' },
    { word: 'meaning', word_class: 'AJ' },
    { word: 'meanness', word_class: 'N' }
  ],
  collusion: [
    { word: 'collude', word_class: 'V' },
    { word: 'collusion', word_class: 'N' },
    { word: 'collusive', word_class: 'AJ' }
  ],
  conciliator: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  eloquent: [
    { word: 'eloquence', word_class: 'N' },
    { word: 'eloquent', word_class: 'AJ' },
    { word: 'eloquently', word_class: 'AV' }
  ],
  thumbed: [
    { word: 'thumb', word_class: 'N' },
    { word: 'thumb', word_class: 'V' },
    { word: 'thumbed', word_class: 'AJ' }
  ],
  wind: [
    { word: 'wind', word_class: 'N' },
    { word: 'wind', word_class: 'V' },
    { word: 'winder', word_class: 'N' },
    { word: 'winded', word_class: 'AJ' },
    { word: 'winding', word_class: 'N' },
    { word: 'winding', word_class: 'AJ' }
  ],
  alphabetic: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  dissatisfied: [
    { word: 'dissatisfy', word_class: 'V' },
    { word: 'dissatisfied', word_class: 'AJ' },
    { word: 'dissatisfaction', word_class: 'N' }
  ],
  weal: [
    { word: 'wee', word_class: 'N' },
    { word: 'wee', word_class: 'V' },
    { word: 'weal', word_class: 'N' },
    { word: 'west', word_class: 'N' },
    { word: 'wee', word_class: 'AJ' },
    { word: 'west', word_class: 'AJ' },
    { word: 'west', word_class: 'AV' },
    { word: 'weeness', word_class: 'N' }
  ],
  attentiveness: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  devest: [
    { word: 'devest', word_class: 'V' },
    { word: 'divest', word_class: 'N' },
    { word: 'divest', word_class: 'V' },
    { word: 'divesture', word_class: 'N' },
    { word: 'divestment', word_class: 'N' },
    { word: 'divestiture', word_class: 'N' }
  ],
  ironical: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  convergency: [
    { word: 'converge', word_class: 'V' },
    { word: 'converging', word_class: 'N' },
    { word: 'convergence', word_class: 'N' },
    { word: 'convergency', word_class: 'N' },
    { word: 'convergent', word_class: 'AJ' },
    { word: 'converging', word_class: 'AJ' }
  ],
  hesitancy: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  conjurer: [
    { word: 'conjure', word_class: 'V' },
    { word: 'conjurer', word_class: 'N' },
    { word: 'conjuror', word_class: 'N' },
    { word: 'conjuring', word_class: 'N' },
    { word: 'conjuration', word_class: 'N' }
  ],
  victimise: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  cosmological: [
    { word: 'cosmology', word_class: 'N' },
    { word: 'cosmologic', word_class: 'AJ' },
    { word: 'cosmological', word_class: 'AJ' }
  ],
  fruition: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  glamor: [
    { word: 'glamor', word_class: 'N' },
    { word: 'glamorize', word_class: 'V' },
    { word: 'glamorous', word_class: 'AJ' },
    { word: 'glamorisation', word_class: 'N' },
    { word: 'glamorization', word_class: 'N' }
  ],
  basketeer: [
    { word: 'basket', word_class: 'N' },
    { word: 'basketeer', word_class: 'N' },
    { word: 'basketful', word_class: 'N' },
    { word: 'basketful', word_class: 'AJ' }
  ],
  readiness: [
    { word: 'ready', word_class: 'N' },
    { word: 'ready', word_class: 'V' },
    { word: 'ready', word_class: 'AJ' },
    { word: 'readying', word_class: 'N' },
    { word: 'readiness', word_class: 'N' }
  ],
  wriggle: [
    { word: 'wriggle', word_class: 'N' },
    { word: 'wriggle', word_class: 'V' },
    { word: 'wriggler', word_class: 'N' },
    { word: 'wriggly', word_class: 'AJ' },
    { word: 'wriggling', word_class: 'AJ' }
  ],
  sealing: [
    { word: 'seal', word_class: 'N' },
    { word: 'seal', word_class: 'V' },
    { word: 'sealer', word_class: 'N' },
    { word: 'sealant', word_class: 'N' },
    { word: 'sealed', word_class: 'AJ' },
    { word: 'sealing', word_class: 'N' }
  ],
  demythologisation: [
    { word: 'demythologise', word_class: 'V' },
    { word: 'demythologize', word_class: 'V' },
    { word: 'demythologized', word_class: 'AJ' },
    { word: 'demythologisation', word_class: 'N' },
    { word: 'demythologization', word_class: 'N' }
  ],
  burring: [
    { word: 'bur', word_class: 'N' },
    { word: 'bur', word_class: 'V' },
    { word: 'burr', word_class: 'N' },
    { word: 'burr', word_class: 'V' },
    { word: 'burred', word_class: 'AJ' },
    { word: 'burring', word_class: 'N' }
  ],
  colonist: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  ebonization: [
    { word: 'ebon', word_class: 'AJ' },
    { word: 'ebonics', word_class: 'N' },
    { word: 'ebonize', word_class: 'V' },
    { word: 'ebonization', word_class: 'N' }
  ],
  signed: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  athlete: [
    { word: 'athlete', word_class: 'N' },
    { word: 'athletic', word_class: 'AJ' },
    { word: 'athletics', word_class: 'N' }
  ],
  clipping: [
    { word: 'clip', word_class: 'N' },
    { word: 'clip', word_class: 'V' },
    { word: 'clipped', word_class: 'AJ' },
    { word: 'clipping', word_class: 'N' }
  ],
  weaken: [
    { word: 'weaken', word_class: 'V' },
    { word: 'weakened', word_class: 'AJ' },
    { word: 'weakening', word_class: 'N' },
    { word: 'weakening', word_class: 'AJ' }
  ],
  botched: [
    { word: 'botch', word_class: 'N' },
    { word: 'botch', word_class: 'V' },
    { word: 'botcher', word_class: 'N' },
    { word: 'botched', word_class: 'AJ' }
  ],
  legislation: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  spaceship: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  riveter: [
    { word: 'rivet', word_class: 'N' },
    { word: 'rivet', word_class: 'V' },
    { word: 'riveter', word_class: 'N' },
    { word: 'riveting', word_class: 'AJ' }
  ],
  specifiable: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  planar: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  spying: [{ word: 'spy', word_class: 'N' }, { word: 'spy', word_class: 'V' }, { word: 'spying', word_class: 'N' }],
  superposition: [
    { word: 'superpose', word_class: 'N' },
    { word: 'superpose', word_class: 'V' },
    { word: 'superposable', word_class: 'AJ' },
    { word: 'superposition', word_class: 'N' }
  ],
  discontinuance: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  scuff: [{ word: 'scuff', word_class: 'N' }, { word: 'scuff', word_class: 'V' }, { word: 'scuffs', word_class: 'N' }],
  deliquescent: [
    { word: 'deliquesce', word_class: 'V' },
    { word: 'deliquescence', word_class: 'N' },
    { word: 'deliquescent', word_class: 'AJ' }
  ],
  chronicler: [
    { word: 'chronicle', word_class: 'N' },
    { word: 'chronicle', word_class: 'V' },
    { word: 'chronicler', word_class: 'N' }
  ],
  stupify: [
    { word: 'stupefy', word_class: 'V' },
    { word: 'stupify', word_class: 'V' },
    { word: 'stupefied', word_class: 'AJ' },
    { word: 'stupefying', word_class: 'AJ' },
    { word: 'stupefaction', word_class: 'N' }
  ],
  privates: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  befuddlement: [
    { word: 'befuddle', word_class: 'V' },
    { word: 'befuddled', word_class: 'AJ' },
    { word: 'befuddlement', word_class: 'N' }
  ],
  paralysis: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  inhabitable: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  quell: [
    { word: 'quell', word_class: 'V' },
    { word: 'quelled', word_class: 'AJ' },
    { word: 'quelling', word_class: 'N' }
  ],
  retrieve: [
    { word: 'retrieve', word_class: 'N' },
    { word: 'retrieve', word_class: 'V' },
    { word: 'retrieval', word_class: 'N' },
    { word: 'retriever', word_class: 'N' },
    { word: 'retrievable', word_class: 'AJ' }
  ],
  farness: [
    { word: 'fare', word_class: 'V' },
    { word: 'far', word_class: 'AJ' },
    { word: 'far', word_class: 'AV' },
    { word: 'fare', word_class: 'N' },
    { word: 'farness', word_class: 'N' }
  ],
  day: [
    { word: 'day', word_class: 'N' },
    { word: 'dais', word_class: 'N' },
    { word: 'days', word_class: 'N' },
    { word: 'daily', word_class: 'N' },
    { word: 'daily', word_class: 'AJ' },
    { word: 'daily', word_class: 'AV' }
  ],
  parvenu: [
    { word: 'parvenu', word_class: 'N' },
    { word: 'parvenu', word_class: 'AJ' },
    { word: 'parvenue', word_class: 'AJ' }
  ],
  sounded: [
    { word: 'sound', word_class: 'N' },
    { word: 'sound', word_class: 'V' },
    { word: 'sound', word_class: 'AJ' },
    { word: 'sound', word_class: 'AV' },
    { word: 'sounded', word_class: 'AJ' },
    { word: 'sounding', word_class: 'N' },
    { word: 'soundman', word_class: 'N' },
    { word: 'sounding', word_class: 'AJ' },
    { word: 'soundness', word_class: 'N' }
  ],
  urinary: [
    { word: 'urine', word_class: 'N' },
    { word: 'urinal', word_class: 'N' },
    { word: 'urinal', word_class: 'AJ' },
    { word: 'urinate', word_class: 'V' },
    { word: 'urinary', word_class: 'AJ' },
    { word: 'urination', word_class: 'N' }
  ],
  transient: [
    { word: 'transient', word_class: 'N' },
    { word: 'transience', word_class: 'N' },
    { word: 'transiency', word_class: 'N' },
    { word: 'transient', word_class: 'AJ' },
    { word: 'transiently', word_class: 'AV' }
  ],
  genetics: [
    { word: 'genet', word_class: 'N' },
    { word: 'genetic', word_class: 'AJ' },
    { word: 'genetics', word_class: 'N' },
    { word: 'genetical', word_class: 'AJ' },
    { word: 'genetically', word_class: 'AV' }
  ],
  supersede: [
    { word: 'supersede', word_class: 'V' },
    { word: 'supersedure', word_class: 'N' },
    { word: 'supersession', word_class: 'N' }
  ],
  galling: [
    { word: 'gall', word_class: 'V' },
    { word: 'gall', word_class: 'N' },
    { word: 'gallant', word_class: 'N' },
    { word: 'gallant', word_class: 'V' },
    { word: 'galled', word_class: 'AJ' },
    { word: 'gallant', word_class: 'AJ' },
    { word: 'galling', word_class: 'AJ' },
    { word: 'gallantry', word_class: 'N' }
  ],
  spiteful: [
    { word: 'spite', word_class: 'N' },
    { word: 'spite', word_class: 'V' },
    { word: 'spiteful', word_class: 'AJ' },
    { word: 'spitefulness', word_class: 'N' }
  ],
  analyze: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  implicative: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  excide: [
    { word: 'excide', word_class: 'V' },
    { word: 'excise', word_class: 'N' },
    { word: 'excise', word_class: 'V' },
    { word: 'excision', word_class: 'N' },
    { word: 'exciseman', word_class: 'N' }
  ],
  limiting: [
    { word: 'limit', word_class: 'N' },
    { word: 'limit', word_class: 'V' },
    { word: 'limiter', word_class: 'N' },
    { word: 'limited', word_class: 'AJ' },
    { word: 'limiting', word_class: 'N' },
    { word: 'limiting', word_class: 'AJ' },
    { word: 'limitation', word_class: 'N' }
  ],
  insincerely: [
    { word: 'insincere', word_class: 'AJ' },
    { word: 'insincerity', word_class: 'N' },
    { word: 'insincerely', word_class: 'AV' }
  ],
  protrusive: [
    { word: 'protrude', word_class: 'V' },
    { word: 'protrusion', word_class: 'N' },
    { word: 'protruding', word_class: 'AJ' },
    { word: 'protrusive', word_class: 'AJ' },
    { word: 'protrusible', word_class: 'AJ' }
  ],
  opsonisation: [
    { word: 'opsonize', word_class: 'V' },
    { word: 'opsonisation', word_class: 'N' },
    { word: 'opsonization', word_class: 'N' },
    { word: 'opsonification', word_class: 'N' }
  ],
  sometimes: [
    { word: 'sometime', word_class: 'AJ' },
    { word: 'sometime', word_class: 'AV' },
    { word: 'sometimes', word_class: 'AV' }
  ],
  vent: [
    { word: 'vent', word_class: 'N' },
    { word: 'vent', word_class: 'V' },
    { word: 'venter', word_class: 'N' },
    { word: 'vented', word_class: 'AJ' },
    { word: 'venting', word_class: 'N' }
  ],
  glamorisation: [
    { word: 'glamor', word_class: 'N' },
    { word: 'glamorize', word_class: 'V' },
    { word: 'glamorous', word_class: 'AJ' },
    { word: 'glamorisation', word_class: 'N' },
    { word: 'glamorization', word_class: 'N' }
  ],
  metalworking: [
    { word: 'metalwork', word_class: 'N' },
    { word: 'metalworks', word_class: 'N' },
    { word: 'metalworker', word_class: 'N' },
    { word: 'metalworking', word_class: 'N' }
  ],
  containerized: [
    { word: 'containerise', word_class: 'V' },
    { word: 'containerize', word_class: 'V' },
    { word: 'containerized', word_class: 'AJ' },
    { word: 'containerisation', word_class: 'N' },
    { word: 'containerization', word_class: 'N' }
  ],
  asteroid: [
    { word: 'asteroid', word_class: 'N' },
    { word: 'asteroid', word_class: 'AJ' },
    { word: 'asteroidal', word_class: 'AJ' }
  ],
  panting: [
    { word: 'pant', word_class: 'N' },
    { word: 'pant', word_class: 'V' },
    { word: 'pants', word_class: 'N' },
    { word: 'panting', word_class: 'N' },
    { word: 'panting', word_class: 'AJ' }
  ],
  reformable: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  politely: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  rehabilitative: [
    { word: 'rehabilitate', word_class: 'V' },
    { word: 'rehabilitated', word_class: 'AJ' },
    { word: 'rehabilitation', word_class: 'N' },
    { word: 'rehabilitative', word_class: 'AJ' }
  ],
  congelation: [
    { word: 'congeal', word_class: 'V' },
    { word: 'congealed', word_class: 'AJ' },
    { word: 'congealment', word_class: 'N' },
    { word: 'congelation', word_class: 'N' }
  ],
  rust: [
    { word: 'rust', word_class: 'N' },
    { word: 'rust', word_class: 'V' },
    { word: 'rust', word_class: 'AJ' },
    { word: 'rusted', word_class: 'AJ' },
    { word: 'rusting', word_class: 'N' }
  ],
  concealment: [
    { word: 'conceal', word_class: 'V' },
    { word: 'concealed', word_class: 'AJ' },
    { word: 'concealing', word_class: 'N' },
    { word: 'concealing', word_class: 'AJ' },
    { word: 'concealment', word_class: 'N' }
  ],
  personalization: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  ancestress: [
    { word: 'ancestor', word_class: 'N' },
    { word: 'ancestral', word_class: 'AJ' },
    { word: 'ancestress', word_class: 'N' }
  ],
  discernable: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  crackle: [
    { word: 'crackle', word_class: 'N' },
    { word: 'crackle', word_class: 'V' },
    { word: 'crackle', word_class: 'AJ' },
    { word: 'crackling', word_class: 'N' },
    { word: 'crackled', word_class: 'AJ' },
    { word: 'crackling', word_class: 'AJ' },
    { word: 'cracklings', word_class: 'N' }
  ],
  acrid: [
    { word: 'acrid', word_class: 'AJ' },
    { word: 'acridity', word_class: 'N' },
    { word: 'acridness', word_class: 'N' }
  ],
  heaves: [
    { word: 'heave', word_class: 'N' },
    { word: 'heave', word_class: 'V' },
    { word: 'heaver', word_class: 'N' },
    { word: 'heaves', word_class: 'N' },
    { word: 'heaving', word_class: 'N' },
    { word: 'heaving', word_class: 'AJ' }
  ],
  tastiness: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  sensitivity: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  sinker: [
    { word: 'sink', word_class: 'N' },
    { word: 'sink', word_class: 'V' },
    { word: 'sinker', word_class: 'N' },
    { word: 'sinkage', word_class: 'N' },
    { word: 'sinking', word_class: 'N' },
    { word: 'sunken', word_class: 'AJ' }
  ],
  containerisation: [
    { word: 'containerise', word_class: 'V' },
    { word: 'containerize', word_class: 'V' },
    { word: 'containerized', word_class: 'AJ' },
    { word: 'containerisation', word_class: 'N' },
    { word: 'containerization', word_class: 'N' }
  ],
  intellectual: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  unlikely: [
    { word: 'unlike', word_class: 'N' },
    { word: 'unlike', word_class: 'V' },
    { word: 'unlike', word_class: 'AJ' },
    { word: 'unlikely', word_class: 'AJ' },
    { word: 'unlikable', word_class: 'AJ' },
    { word: 'unlikeness', word_class: 'N' },
    { word: 'unlikeable', word_class: 'AJ' }
  ],
  inhaling: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  cogitable: [
    { word: 'cogitate', word_class: 'V' },
    { word: 'cogitable', word_class: 'AJ' },
    { word: 'cogitation', word_class: 'N' },
    { word: 'cogitative', word_class: 'AJ' }
  ],
  overturn: [
    { word: 'overturn', word_class: 'N' },
    { word: 'overturn', word_class: 'V' },
    { word: 'overturned', word_class: 'AJ' },
    { word: 'overturning', word_class: 'N' }
  ],
  southwest: [
    { word: 'southwest', word_class: 'N' },
    { word: 'southwest', word_class: 'AJ' },
    { word: 'southwest', word_class: 'AV' },
    { word: 'southwester', word_class: 'N' }
  ],
  superordination: [
    { word: 'superordinate', word_class: 'N' },
    { word: 'superordinate', word_class: 'AJ' },
    { word: 'superordination', word_class: 'N' }
  ],
  mining: [
    { word: 'mine', word_class: 'N' },
    { word: 'mine', word_class: 'V' },
    { word: 'miner', word_class: 'N' },
    { word: 'mined', word_class: 'AJ' },
    { word: 'mining', word_class: 'N' },
    { word: 'minion', word_class: 'N' },
    { word: 'minor', word_class: 'N' }
  ],
  wavering: [
    { word: 'waver', word_class: 'N' },
    { word: 'waver', word_class: 'V' },
    { word: 'waverer', word_class: 'N' },
    { word: 'wavering', word_class: 'N' },
    { word: 'wavering', word_class: 'AJ' }
  ],
  hammer: [
    { word: 'hammer', word_class: 'N' },
    { word: 'hammer', word_class: 'V' },
    { word: 'hammered', word_class: 'AJ' },
    { word: 'hammering', word_class: 'N' }
  ],
  lush: [{ word: 'lush', word_class: 'N' }, { word: 'lush', word_class: 'AJ' }, { word: 'lushness', word_class: 'N' }],
  impulsively: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  concern: [
    { word: 'concrn', word_class: 'V' },
    { word: 'concern', word_class: 'N' },
    { word: 'concern', word_class: 'V' },
    { word: 'concerned', word_class: 'AJ' },
    { word: 'concerning', word_class: 'AJ' }
  ],
  mineralize: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  hunch: [
    { word: 'hunch', word_class: 'N' },
    { word: 'hunch', word_class: 'V' },
    { word: 'hunched', word_class: 'AJ' }
  ],
  gushing: [
    { word: 'gush', word_class: 'N' },
    { word: 'gush', word_class: 'V' },
    { word: 'gusher', word_class: 'N' },
    { word: 'gushing', word_class: 'AJ' }
  ],
  geographical: [
    { word: 'geographer', word_class: 'N' },
    { word: 'geographic', word_class: 'AJ' },
    { word: 'geographics', word_class: 'N' },
    { word: 'geographical', word_class: 'AJ' },
    { word: 'geographically', word_class: 'AV' }
  ],
  pliancy: [
    { word: 'ply', word_class: 'N' },
    { word: 'ply', word_class: 'V' },
    { word: 'pliant', word_class: 'N' },
    { word: 'pliancy', word_class: 'N' },
    { word: 'pliant', word_class: 'AJ' },
    { word: 'pliantness', word_class: 'N' }
  ],
  underhanded: [
    { word: 'underhand', word_class: 'AJ' },
    { word: 'underhand', word_class: 'AV' },
    { word: 'underhanded', word_class: 'AJ' }
  ],
  digestibility: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  solarize: [
    { word: 'solar', word_class: 'AJ' },
    { word: 'solarise', word_class: 'V' },
    { word: 'solarize', word_class: 'V' },
    { word: 'solarisation', word_class: 'N' },
    { word: 'solarization', word_class: 'N' }
  ],
  tithing: [
    { word: 'tithe', word_class: 'N' },
    { word: 'tithe', word_class: 'V' },
    { word: 'tithing', word_class: 'N' }
  ],
  badgerer: [
    { word: 'badger', word_class: 'N' },
    { word: 'badger', word_class: 'V' },
    { word: 'badgerer', word_class: 'N' },
    { word: 'badgering', word_class: 'N' }
  ],
  naiveness: [
    { word: 'naive', word_class: 'AJ' },
    { word: 'naivety', word_class: 'N' },
    { word: 'naively', word_class: 'AV' },
    { word: 'naiveness', word_class: 'N' }
  ],
  jaded: [
    { word: 'jade', word_class: 'N' },
    { word: 'jade', word_class: 'V' },
    { word: 'jade', word_class: 'AJ' },
    { word: 'jaded', word_class: 'AJ' }
  ],
  faithfulness: [
    { word: 'faith', word_class: 'N' },
    { word: 'faithful', word_class: 'N' },
    { word: 'faithful', word_class: 'AJ' },
    { word: 'faithfulness', word_class: 'N' }
  ],
  inshrine: [
    { word: 'enshrine', word_class: 'V' },
    { word: 'inshrine', word_class: 'V' },
    { word: 'enshrinement', word_class: 'N' }
  ],
  refractiveness: [
    { word: 'refract', word_class: 'V' },
    { word: 'refraction', word_class: 'N' },
    { word: 'refractive', word_class: 'AJ' },
    { word: 'refractivity', word_class: 'N' },
    { word: 'refractiveness', word_class: 'N' }
  ],
  bootlicking: [
    { word: 'bootlick', word_class: 'V' },
    { word: 'bootlicker', word_class: 'N' },
    { word: 'bootlicking', word_class: 'AJ' }
  ],
  combat: [
    { word: 'combat', word_class: 'N' },
    { word: 'combat', word_class: 'V' },
    { word: 'combatant', word_class: 'N' },
    { word: 'combatant', word_class: 'AJ' }
  ],
  satanic: [
    { word: 'satan', word_class: 'N' },
    { word: 'satanic', word_class: 'AJ' },
    { word: 'satanism', word_class: 'N' }
  ],
  levanter: [
    { word: 'levant', word_class: 'N' },
    { word: 'levant', word_class: 'V' },
    { word: 'levanter', word_class: 'N' }
  ],
  paternalism: [
    { word: 'paternal', word_class: 'AJ' },
    { word: 'paternity', word_class: 'N' },
    { word: 'paternalism', word_class: 'N' },
    { word: 'paternally', word_class: 'AV' }
  ],
  execrable: [
    { word: 'execrate', word_class: 'V' },
    { word: 'execrable', word_class: 'AJ' },
    { word: 'execration', word_class: 'N' }
  ],
  unattainableness: [
    { word: 'unattainable', word_class: 'AJ' },
    { word: 'unattainably', word_class: 'AV' },
    { word: 'unattainableness', word_class: 'N' }
  ],
  abortive: [
    { word: 'abort', word_class: 'V' },
    { word: 'abortion', word_class: 'N' },
    { word: 'abortive', word_class: 'AJ' },
    { word: 'abortively', word_class: 'AV' }
  ],
  coming: [
    { word: 'come', word_class: 'V' },
    { word: 'come', word_class: 'AJ' },
    { word: 'comer', word_class: 'N' },
    { word: 'coming', word_class: 'N' },
    { word: 'comely', word_class: 'AJ' },
    { word: 'coming', word_class: 'AJ' }
  ],
  transistorize: [
    { word: 'transistor', word_class: 'N' },
    { word: 'transistorise', word_class: 'V' },
    { word: 'transistorize', word_class: 'V' },
    { word: 'transistorized', word_class: 'AJ' },
    { word: 'transistorization', word_class: 'N' }
  ],
  caesarism: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  cheesy: [
    { word: 'cheese', word_class: 'N' },
    { word: 'cheese', word_class: 'V' },
    { word: 'cheesy', word_class: 'AJ' }
  ],
  adornment: [
    { word: 'adorn', word_class: 'V' },
    { word: 'adorned', word_class: 'AJ' },
    { word: 'adornment', word_class: 'N' }
  ],
  humanoid: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  specialism: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  surprisal: [
    { word: 'surprise', word_class: 'N' },
    { word: 'surprise', word_class: 'V' },
    { word: 'surprisal', word_class: 'N' },
    { word: 'surprised', word_class: 'AJ' },
    { word: 'surprising', word_class: 'N' },
    { word: 'surprising', word_class: 'AJ' }
  ],
  remembrance: [
    { word: 'remember', word_class: 'N' },
    { word: 'remember', word_class: 'V' },
    { word: 'remembered', word_class: 'AJ' },
    { word: 'remembering', word_class: 'N' },
    { word: 'remembrance', word_class: 'N' }
  ],
  unusefulness: [
    { word: 'unused', word_class: 'AJ' },
    { word: 'unusable', word_class: 'AJ' },
    { word: 'unuseable', word_class: 'AJ' },
    { word: 'unusefulness', word_class: 'N' }
  ],
  sucker: [
    { word: 'suck', word_class: 'N' },
    { word: 'suck', word_class: 'V' },
    { word: 'sucker', word_class: 'N' },
    { word: 'sucking', word_class: 'N' },
    { word: 'suction', word_class: 'N' },
    { word: 'suction', word_class: 'V' }
  ],
  tantalizer: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  satiation: [
    { word: 'satiate', word_class: 'V' },
    { word: 'satiate', word_class: 'AJ' },
    { word: 'satiated', word_class: 'AJ' },
    { word: 'satiation', word_class: 'N' }
  ],
  scathe: [
    { word: 'scathe', word_class: 'N' },
    { word: 'scathe', word_class: 'V' },
    { word: 'scathing', word_class: 'AJ' }
  ],
  uprising: [
    { word: 'uprise', word_class: 'V' },
    { word: 'uprisen', word_class: 'AJ' },
    { word: 'uprising', word_class: 'N' }
  ],
  ebb: [{ word: 'ebb', word_class: 'N' }, { word: 'ebb', word_class: 'V' }, { word: 'ebb', word_class: 'AJ' }],
  jilted: [{ word: 'jilt', word_class: 'N' }, { word: 'jilt', word_class: 'V' }, { word: 'jilted', word_class: 'AJ' }],
  morning: [
    { word: 'morn', word_class: 'N' },
    { word: 'morn', word_class: 'V' },
    { word: 'morning', word_class: 'N' },
    { word: 'morning', word_class: 'AJ' }
  ],
  amend: [
    { word: 'amend', word_class: 'V' },
    { word: 'amends', word_class: 'N' },
    { word: 'amended', word_class: 'AJ' },
    { word: 'amendment', word_class: 'N' },
    { word: 'amendable', word_class: 'AJ' }
  ],
  intriguing: [
    { word: 'intrigue', word_class: 'N' },
    { word: 'intrigue', word_class: 'V' },
    { word: 'intriguing', word_class: 'AJ' }
  ],
  alteration: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  equalisation: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  evaporation: [
    { word: 'evaporate', word_class: 'V' },
    { word: 'evaporable', word_class: 'AJ' },
    { word: 'evaporated', word_class: 'AJ' },
    { word: 'evaporation', word_class: 'N' },
    { word: 'evaporative', word_class: 'AJ' }
  ],
  summerize: [
    { word: 'summer', word_class: 'N' },
    { word: 'summer', word_class: 'V' },
    { word: 'summer', word_class: 'AJ' },
    { word: 'summerize', word_class: 'V' }
  ],
  communalise: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  litigator: [
    { word: 'litigant', word_class: 'N' },
    { word: 'litigate', word_class: 'V' },
    { word: 'litigator', word_class: 'N' },
    { word: 'litigation', word_class: 'N' }
  ],
  foliated: [
    { word: 'foliate', word_class: 'V' },
    { word: 'foliate', word_class: 'AJ' },
    { word: 'foliated', word_class: 'AJ' },
    { word: 'foliation', word_class: 'N' }
  ],
  opportunism: [
    { word: 'opportune', word_class: 'AJ' },
    { word: 'opportunism', word_class: 'N' },
    { word: 'opportunity', word_class: 'N' },
    { word: 'opportunely', word_class: 'AV' },
    { word: 'opportuneness', word_class: 'N' }
  ],
  tenderization: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  molting: [{ word: 'molt', word_class: 'N' }, { word: 'molt', word_class: 'V' }, { word: 'molting', word_class: 'N' }],
  ringed: [
    { word: 'ring', word_class: 'N' },
    { word: 'ring', word_class: 'V' },
    { word: 'rung', word_class: 'N' },
    { word: 'rung', word_class: 'AJ' },
    { word: 'ringer', word_class: 'N' },
    { word: 'ringed', word_class: 'AJ' },
    { word: 'ringing', word_class: 'N' },
    { word: 'ringing', word_class: 'AJ' }
  ],
  absolutely: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  gluey: [{ word: 'glue', word_class: 'N' }, { word: 'glue', word_class: 'V' }, { word: 'gluey', word_class: 'AJ' }],
  impudent: [
    { word: 'impudence', word_class: 'N' },
    { word: 'impudent', word_class: 'AJ' },
    { word: 'impudently', word_class: 'AV' }
  ],
  intermittently: [
    { word: 'intermittence', word_class: 'N' },
    { word: 'intermittency', word_class: 'N' },
    { word: 'intermittent', word_class: 'AJ' },
    { word: 'intermittently', word_class: 'AV' }
  ],
  uniformity: [
    { word: 'uniform', word_class: 'N' },
    { word: 'uniform', word_class: 'V' },
    { word: 'uniform', word_class: 'AJ' },
    { word: 'uniformed', word_class: 'AJ' },
    { word: 'uniformity', word_class: 'N' },
    { word: 'uniformize', word_class: 'V' },
    { word: 'uniformness', word_class: 'N' }
  ],
  infirmary: [
    { word: 'infirm', word_class: 'N' },
    { word: 'infirm', word_class: 'V' },
    { word: 'infirm', word_class: 'AJ' },
    { word: 'infirmary', word_class: 'N' },
    { word: 'infirmity', word_class: 'N' }
  ],
  suspect: [
    { word: 'suspect', word_class: 'N' },
    { word: 'suspect', word_class: 'V' },
    { word: 'suspect', word_class: 'AJ' },
    { word: 'suspicion', word_class: 'N' },
    { word: 'suspected', word_class: 'AJ' }
  ],
  instill: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  bromination: [
    { word: 'bromine', word_class: 'N' },
    { word: 'brominate', word_class: 'V' },
    { word: 'bromination', word_class: 'N' }
  ],
  design: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  stalemate: [
    { word: 'stalemate', word_class: 'N' },
    { word: 'stalemate', word_class: 'V' },
    { word: 'stalemated', word_class: 'AJ' }
  ],
  raring: [
    { word: 'rare', word_class: 'AJ' },
    { word: 'rarely', word_class: 'AV' },
    { word: 'raring', word_class: 'AJ' },
    { word: 'rareness', word_class: 'N' }
  ],
  allow: [
    { word: 'allow', word_class: 'V' },
    { word: 'allowance', word_class: 'N' },
    { word: 'allowance', word_class: 'V' },
    { word: 'allowable', word_class: 'AJ' },
    { word: 'allowably', word_class: 'AV' }
  ],
  means: [
    { word: 'mean', word_class: 'N' },
    { word: 'mean', word_class: 'V' },
    { word: 'mean', word_class: 'AJ' },
    { word: 'means', word_class: 'N' },
    { word: 'meaning', word_class: 'N' },
    { word: 'meaning', word_class: 'AJ' },
    { word: 'meanness', word_class: 'N' }
  ],
  inflame: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  belong: [
    { word: 'belong', word_class: 'V' },
    { word: 'belonging', word_class: 'N' },
    { word: 'belongings', word_class: 'N' }
  ],
  found: [
    { word: 'found', word_class: 'N' },
    { word: 'found', word_class: 'V' },
    { word: 'found', word_class: 'AJ' },
    { word: 'founder', word_class: 'N' },
    { word: 'founder', word_class: 'V' },
    { word: 'founded', word_class: 'AJ' },
    { word: 'founding', word_class: 'N' },
    { word: 'foundation', word_class: 'N' },
    { word: 'foundering', word_class: 'N' }
  ],
  blocking: [
    { word: 'block', word_class: 'N' },
    { word: 'block', word_class: 'V' },
    { word: 'blocker', word_class: 'N' },
    { word: 'blockage', word_class: 'N' },
    { word: 'blocked', word_class: 'AJ' },
    { word: 'blocking', word_class: 'N' }
  ],
  insufficient: [
    { word: 'insufficiency', word_class: 'N' },
    { word: 'insufficient', word_class: 'AJ' },
    { word: 'insufficiently', word_class: 'AV' }
  ],
  supplicant: [
    { word: 'supplicant', word_class: 'N' },
    { word: 'supplicate', word_class: 'V' },
    { word: 'supplicant', word_class: 'AJ' },
    { word: 'supplication', word_class: 'N' }
  ],
  gynandromorphic: [
    { word: 'gynandromorph', word_class: 'N' },
    { word: 'gynandromorphic', word_class: 'AJ' },
    { word: 'gynandromorphous', word_class: 'AJ' }
  ],
  fabricate: [
    { word: 'fabric', word_class: 'N' },
    { word: 'fabricate', word_class: 'V' },
    { word: 'fabricator', word_class: 'N' },
    { word: 'fabricated', word_class: 'AJ' },
    { word: 'fabrication', word_class: 'N' }
  ],
  christianity: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  continuant: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  compatibly: [
    { word: 'compatible', word_class: 'N' },
    { word: 'compatible', word_class: 'AJ' },
    { word: 'compatibly', word_class: 'AV' },
    { word: 'compatibility', word_class: 'N' }
  ],
  untie: [
    { word: 'untie', word_class: 'V' },
    { word: 'untied', word_class: 'AJ' },
    { word: 'untying', word_class: 'N' }
  ],
  futuristic: [
    { word: 'futurist', word_class: 'N' },
    { word: 'futurist', word_class: 'AJ' },
    { word: 'futuristic', word_class: 'AJ' }
  ],
  disobligation: [
    { word: 'disoblige', word_class: 'V' },
    { word: 'disobliging', word_class: 'AJ' },
    { word: 'disobligation', word_class: 'N' }
  ],
  skill: [
    { word: 'skill', word_class: 'N' },
    { word: 'skilful', word_class: 'AJ' },
    { word: 'skilled', word_class: 'AJ' },
    { word: 'skillful', word_class: 'AJ' },
    { word: 'skillfulness', word_class: 'N' }
  ],
  penetrating: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  dishearten: [
    { word: 'dishearten', word_class: 'V' },
    { word: 'disheartened', word_class: 'AJ' },
    { word: 'disheartening', word_class: 'AJ' },
    { word: 'disheartenment', word_class: 'N' }
  ],
  divulge: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  deputation: [
    { word: 'depute', word_class: 'V' },
    { word: 'deputy', word_class: 'N' },
    { word: 'deputy', word_class: 'AJ' },
    { word: 'deputize', word_class: 'V' },
    { word: 'deputation', word_class: 'N' },
    { word: 'deputization', word_class: 'N' }
  ],
  ellipsoidal: [
    { word: 'ellipsoid', word_class: 'N' },
    { word: 'ellipsoid', word_class: 'AJ' },
    { word: 'ellipsoidal', word_class: 'AJ' }
  ],
  pensiveness: [
    { word: 'pensive', word_class: 'AJ' },
    { word: 'pensively', word_class: 'AV' },
    { word: 'pensiveness', word_class: 'N' }
  ],
  tattle: [
    { word: 'tattle', word_class: 'N' },
    { word: 'tattle', word_class: 'V' },
    { word: 'tattler', word_class: 'N' },
    { word: 'tattling', word_class: 'AJ' }
  ],
  populating: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  send: [{ word: 'send', word_class: 'V' }, { word: 'sender', word_class: 'N' }, { word: 'sending', word_class: 'N' }],
  break: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  saving: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  underdevelopment: [
    { word: 'underdevelop', word_class: 'V' },
    { word: 'underdeveloped', word_class: 'AJ' },
    { word: 'underdevelopment', word_class: 'N' }
  ],
  coalman: [{ word: 'coal', word_class: 'N' }, { word: 'coal', word_class: 'V' }, { word: 'coalman', word_class: 'N' }],
  inoculation: [
    { word: 'inoculate', word_class: 'V' },
    { word: 'inoculating', word_class: 'N' },
    { word: 'inoculation', word_class: 'N' }
  ],
  steelman: [
    { word: 'steel', word_class: 'N' },
    { word: 'steel', word_class: 'V' },
    { word: 'steelman', word_class: 'N' }
  ],
  factualness: [
    { word: 'factual', word_class: 'AJ' },
    { word: 'factuality', word_class: 'N' },
    { word: 'factually', word_class: 'AV' },
    { word: 'factualness', word_class: 'N' }
  ],
  pauperize: [
    { word: 'pauper', word_class: 'N' },
    { word: 'pauper', word_class: 'V' },
    { word: 'pauperism', word_class: 'N' },
    { word: 'pauperize', word_class: 'V' },
    { word: 'pauperization', word_class: 'N' }
  ],
  chronology: [
    { word: 'chronology', word_class: 'N' },
    { word: 'chronologize', word_class: 'V' },
    { word: 'chronological', word_class: 'AJ' },
    { word: 'chronologically', word_class: 'AV' }
  ],
  negative: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  fixings: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  perfection: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  irreverently: [
    { word: 'irreverence', word_class: 'N' },
    { word: 'irreverent', word_class: 'AJ' },
    { word: 'irreverently', word_class: 'AV' }
  ],
  sufficiency: [
    { word: 'suffice', word_class: 'V' },
    { word: 'sufficiency', word_class: 'N' },
    { word: 'sufficient', word_class: 'AJ' },
    { word: 'sufficiently', word_class: 'AV' }
  ],
  impotent: [
    { word: 'impotence', word_class: 'N' },
    { word: 'impotency', word_class: 'N' },
    { word: 'impotent', word_class: 'AJ' },
    { word: 'impotently', word_class: 'AV' }
  ],
  enfeeble: [
    { word: 'enfeeble', word_class: 'V' },
    { word: 'enfeebled', word_class: 'AJ' },
    { word: 'enfeebling', word_class: 'AJ' },
    { word: 'enfeeblement', word_class: 'N' }
  ],
  convenience: [
    { word: 'convenience', word_class: 'N' },
    { word: 'convenience', word_class: 'V' },
    { word: 'convenient', word_class: 'AJ' },
    { word: 'conveniences', word_class: 'N' },
    { word: 'conveniently', word_class: 'AV' }
  ],
  striving: [
    { word: 'strife', word_class: 'N' },
    { word: 'strive', word_class: 'V' },
    { word: 'striver', word_class: 'N' },
    { word: 'striven', word_class: 'AJ' },
    { word: 'striving', word_class: 'N' }
  ],
  lock: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  disgustful: [
    { word: 'disgust', word_class: 'N' },
    { word: 'disgust', word_class: 'V' },
    { word: 'disgusted', word_class: 'AJ' },
    { word: 'disgustful', word_class: 'AJ' },
    { word: 'disgusting', word_class: 'AJ' }
  ],
  hawker: [
    { word: 'hawk', word_class: 'N' },
    { word: 'hawk', word_class: 'V' },
    { word: 'hawker', word_class: 'N' },
    { word: 'hawking', word_class: 'N' }
  ],
  roast: [
    { word: 'roast', word_class: 'N' },
    { word: 'roast', word_class: 'V' },
    { word: 'roast', word_class: 'AJ' },
    { word: 'roaster', word_class: 'N' },
    { word: 'roasted', word_class: 'AJ' },
    { word: 'roasting', word_class: 'N' }
  ],
  gilder: [
    { word: 'gild', word_class: 'N' },
    { word: 'gild', word_class: 'V' },
    { word: 'gilder', word_class: 'N' },
    { word: 'gilded', word_class: 'AJ' },
    { word: 'gilding', word_class: 'N' }
  ],
  jejunely: [
    { word: 'jejune', word_class: 'AJ' },
    { word: 'jejunity', word_class: 'N' },
    { word: 'jejunely', word_class: 'AV' },
    { word: 'jejunitis', word_class: 'N' },
    { word: 'jejuneness', word_class: 'N' }
  ],
  diffident: [
    { word: 'diffidence', word_class: 'N' },
    { word: 'diffident', word_class: 'AJ' },
    { word: 'diffidently', word_class: 'AV' }
  ],
  tinned: [
    { word: 'tin', word_class: 'N' },
    { word: 'tin', word_class: 'V' },
    { word: 'tinful', word_class: 'N' },
    { word: 'tinful', word_class: 'AJ' },
    { word: 'tinned', word_class: 'AJ' },
    { word: 'tinning', word_class: 'N' }
  ],
  incredible: [
    { word: 'incredible', word_class: 'AJ' },
    { word: 'incredibly', word_class: 'AV' },
    { word: 'incredibility', word_class: 'N' },
    { word: 'incredibleness', word_class: 'N' }
  ],
  weepy: [
    { word: 'weep', word_class: 'V' },
    { word: 'weepy', word_class: 'AJ' },
    { word: 'weeper', word_class: 'N' },
    { word: 'weeping', word_class: 'N' },
    { word: 'weeping', word_class: 'AJ' },
    { word: 'weepiness', word_class: 'N' }
  ],
  ransacked: [
    { word: 'ransack', word_class: 'V' },
    { word: 'ransacked', word_class: 'AJ' },
    { word: 'ransacking', word_class: 'N' }
  ],
  loud: [{ word: 'loud', word_class: 'AJ' }, { word: 'loud', word_class: 'AV' }, { word: 'loudness', word_class: 'N' }],
  parasitical: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  measurably: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  snoopy: [
    { word: 'snoop', word_class: 'N' },
    { word: 'snoop', word_class: 'V' },
    { word: 'snooper', word_class: 'N' },
    { word: 'snoopy', word_class: 'AJ' },
    { word: 'snoopiness', word_class: 'N' }
  ],
  footing: [
    { word: 'foot', word_class: 'N' },
    { word: 'foot', word_class: 'V' },
    { word: 'footer', word_class: 'N' },
    { word: 'footed', word_class: 'AJ' },
    { word: 'footing', word_class: 'N' },
    { word: 'footman', word_class: 'N' }
  ],
  conferment: [
    { word: 'confer', word_class: 'V' },
    { word: 'confer', word_class: 'AV' },
    { word: 'conferee', word_class: 'N' },
    { word: 'conferral', word_class: 'N' },
    { word: 'conference', word_class: 'N' },
    { word: 'conferment', word_class: 'N' },
    { word: 'conferred', word_class: 'AJ' }
  ],
  sedulous: [
    { word: 'sedulity', word_class: 'N' },
    { word: 'sedulous', word_class: 'AJ' },
    { word: 'sedulously', word_class: 'AV' },
    { word: 'sedulousness', word_class: 'N' }
  ],
  tersely: [
    { word: 'terse', word_class: 'AJ' },
    { word: 'tersely', word_class: 'AV' },
    { word: 'terseness', word_class: 'N' }
  ],
  whiz: [
    { word: 'whiz', word_class: 'N' },
    { word: 'whiz', word_class: 'V' },
    { word: 'whizz', word_class: 'N' },
    { word: 'whizz', word_class: 'V' },
    { word: 'whizzed', word_class: 'AJ' },
    { word: 'whizzing', word_class: 'AJ' }
  ],
  conjugate: [
    { word: 'conjugal', word_class: 'AJ' },
    { word: 'conjugate', word_class: 'N' },
    { word: 'conjugate', word_class: 'V' },
    { word: 'conjugate', word_class: 'AJ' },
    { word: 'conjugally', word_class: 'AV' },
    { word: 'conjugated', word_class: 'AJ' },
    { word: 'conjugation', word_class: 'N' }
  ],
  positively: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  traumatize: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  fog: [{ word: 'fog', word_class: 'N' }, { word: 'fog', word_class: 'V' }, { word: 'fogged', word_class: 'AJ' }],
  sprinkler: [
    { word: 'sprinkle', word_class: 'N' },
    { word: 'sprinkle', word_class: 'V' },
    { word: 'sprinkler', word_class: 'N' },
    { word: 'sprinkles', word_class: 'N' },
    { word: 'sprinkled', word_class: 'AJ' },
    { word: 'sprinkling', word_class: 'N' }
  ],
  begin: [
    { word: 'begin', word_class: 'V' },
    { word: 'begun', word_class: 'AJ' },
    { word: 'beginning', word_class: 'N' },
    { word: 'beginning', word_class: 'AJ' }
  ],
  epicyclic: [
    { word: 'epicycle', word_class: 'N' },
    { word: 'epicyclic', word_class: 'AJ' },
    { word: 'epicyclical', word_class: 'AJ' }
  ],
  stealing: [
    { word: 'steal', word_class: 'N' },
    { word: 'steal', word_class: 'V' },
    { word: 'stolen', word_class: 'AJ' },
    { word: 'stealing', word_class: 'N' }
  ],
  cuspidated: [
    { word: 'cuspid', word_class: 'N' },
    { word: 'cuspidal', word_class: 'AJ' },
    { word: 'cuspidate', word_class: 'AJ' },
    { word: 'cuspidated', word_class: 'AJ' },
    { word: 'cuspidation', word_class: 'N' }
  ],
  swashbuckler: [
    { word: 'swashbuckle', word_class: 'V' },
    { word: 'swashbuckler', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'AJ' }
  ],
  browning: [
    { word: 'brown', word_class: 'N' },
    { word: 'brown', word_class: 'V' },
    { word: 'brown', word_class: 'AJ' },
    { word: 'browned', word_class: 'AJ' },
    { word: 'browning', word_class: 'N' },
    { word: 'brownness', word_class: 'N' }
  ],
  presumed: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  haploid: [
    { word: 'haploid', word_class: 'N' },
    { word: 'haploid', word_class: 'AJ' },
    { word: 'haploidic', word_class: 'AJ' }
  ],
  visitor: [
    { word: 'visit', word_class: 'N' },
    { word: 'visit', word_class: 'V' },
    { word: 'visitor', word_class: 'N' },
    { word: 'visitant', word_class: 'N' },
    { word: 'visiting', word_class: 'N' },
    { word: 'visiting', word_class: 'AJ' },
    { word: 'visitation', word_class: 'N' }
  ],
  oiler: [
    { word: 'oil', word_class: 'N' },
    { word: 'oil', word_class: 'V' },
    { word: 'oiler', word_class: 'N' },
    { word: 'oiled', word_class: 'AJ' },
    { word: 'oilman', word_class: 'N' }
  ],
  vociferation: [
    { word: 'vociferate', word_class: 'V' },
    { word: 'vociferous', word_class: 'AJ' },
    { word: 'vociferation', word_class: 'N' },
    { word: 'vociferously', word_class: 'AV' }
  ],
  'double-crossing': [
    { word: 'double-cross', word_class: 'N' },
    { word: 'double-cross', word_class: 'V' },
    { word: 'double-crosser', word_class: 'N' },
    { word: 'double-crossing', word_class: 'N' }
  ],
  descendent: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  rising: [
    { word: 'rise', word_class: 'N' },
    { word: 'rise', word_class: 'V' },
    { word: 'riser', word_class: 'N' },
    { word: 'risen', word_class: 'AJ' },
    { word: 'rising', word_class: 'N' },
    { word: 'rising', word_class: 'AJ' }
  ],
  ascription: [
    { word: 'ascribe', word_class: 'V' },
    { word: 'ascription', word_class: 'N' },
    { word: 'ascribable', word_class: 'AJ' }
  ],
  venomously: [
    { word: 'venom', word_class: 'N' },
    { word: 'venomed', word_class: 'AJ' },
    { word: 'venomous', word_class: 'AJ' },
    { word: 'venomously', word_class: 'AV' }
  ],
  mercerisation: [
    { word: 'mercer', word_class: 'N' },
    { word: 'mercerise', word_class: 'V' },
    { word: 'mercerize', word_class: 'V' },
    { word: 'mercerisation', word_class: 'N' },
    { word: 'mercerization', word_class: 'N' }
  ],
  stenographer: [
    { word: 'stenographer', word_class: 'N' },
    { word: 'stenographic', word_class: 'AJ' },
    { word: 'stenographical', word_class: 'AJ' }
  ],
  necking: [
    { word: 'neck', word_class: 'N' },
    { word: 'neck', word_class: 'V' },
    { word: 'necked', word_class: 'AJ' },
    { word: 'necking', word_class: 'N' }
  ],
  puddler: [
    { word: 'puddle', word_class: 'N' },
    { word: 'puddle', word_class: 'V' },
    { word: 'puddler', word_class: 'N' },
    { word: 'puddly', word_class: 'AJ' }
  ],
  economically: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  imbued: [
    { word: 'imbue', word_class: 'V' },
    { word: 'imbued', word_class: 'AJ' },
    { word: 'imbuement', word_class: 'N' }
  ],
  thrill: [
    { word: 'thrill', word_class: 'N' },
    { word: 'thrill', word_class: 'V' },
    { word: 'thriller', word_class: 'N' },
    { word: 'thrilled', word_class: 'AJ' },
    { word: 'thrillful', word_class: 'AJ' },
    { word: 'thrilling', word_class: 'AJ' }
  ],
  comparison: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  orchestrate: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  internationalization: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  bushwhack: [
    { word: 'bushwhack', word_class: 'V' },
    { word: 'bushwhacker', word_class: 'N' },
    { word: 'bushwhacking', word_class: 'AJ' }
  ],
  bereavement: [
    { word: 'bereave', word_class: 'V' },
    { word: 'bereaved', word_class: 'AJ' },
    { word: 'bereavement', word_class: 'N' }
  ],
  unselfconsciousness: [
    { word: 'unselfconscious', word_class: 'AJ' },
    { word: 'unselfconsciously', word_class: 'AV' },
    { word: 'unselfconsciousness', word_class: 'N' }
  ],
  urbanely: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  adjust: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  wringer: [
    { word: 'wring', word_class: 'N' },
    { word: 'wring', word_class: 'V' },
    { word: 'wringer', word_class: 'N' }
  ],
  intervention: [
    { word: 'intervene', word_class: 'V' },
    { word: 'intervening', word_class: 'AJ' },
    { word: 'intervention', word_class: 'N' }
  ],
  adipose: [
    { word: 'adipose', word_class: 'AJ' },
    { word: 'adiposity', word_class: 'N' },
    { word: 'adiposeness', word_class: 'N' }
  ],
  petal: [
    { word: 'petal', word_class: 'N' },
    { word: 'petaled', word_class: 'AJ' },
    { word: 'petalled', word_class: 'AJ' },
    { word: 'petalous', word_class: 'AJ' }
  ],
  commercial: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  stalked: [
    { word: 'stalk', word_class: 'N' },
    { word: 'stalk', word_class: 'V' },
    { word: 'stalker', word_class: 'N' },
    { word: 'stalked', word_class: 'AJ' },
    { word: 'stalking', word_class: 'N' },
    { word: 'stalking', word_class: 'AJ' }
  ],
  rampageous: [
    { word: 'rampage', word_class: 'N' },
    { word: 'rampage', word_class: 'V' },
    { word: 'rampageous', word_class: 'AJ' }
  ],
  cloistered: [
    { word: 'cloister', word_class: 'N' },
    { word: 'cloister', word_class: 'V' },
    { word: 'cloistered', word_class: 'AJ' }
  ],
  vigil: [
    { word: 'vigil', word_class: 'N' },
    { word: 'vigilance', word_class: 'N' },
    { word: 'vigilant', word_class: 'AJ' }
  ],
  unlike: [
    { word: 'unlike', word_class: 'N' },
    { word: 'unlike', word_class: 'V' },
    { word: 'unlike', word_class: 'AJ' },
    { word: 'unlikely', word_class: 'AJ' },
    { word: 'unlikable', word_class: 'AJ' },
    { word: 'unlikeness', word_class: 'N' },
    { word: 'unlikeable', word_class: 'AJ' }
  ],
  copying: [
    { word: 'copy', word_class: 'N' },
    { word: 'copy', word_class: 'V' },
    { word: 'copier', word_class: 'N' },
    { word: 'copied', word_class: 'AJ' },
    { word: 'copying', word_class: 'N' },
    { word: 'copyist', word_class: 'N' }
  ],
  art: [
    { word: 'art', word_class: 'N' },
    { word: 'arts', word_class: 'N' },
    { word: 'artful', word_class: 'AJ' },
    { word: 'artistry', word_class: 'N' },
    { word: 'artfulness', word_class: 'N' }
  ],
  crosshatching: [
    { word: 'crosshatch', word_class: 'N' },
    { word: 'crosshatch', word_class: 'V' },
    { word: 'crosshatched', word_class: 'AJ' },
    { word: 'crosshatching', word_class: 'N' }
  ],
  unequivocal: [
    { word: 'unequivocal', word_class: 'AJ' },
    { word: 'unequivocally', word_class: 'AV' },
    { word: 'unequivocalness', word_class: 'N' }
  ],
  preconize: [
    { word: 'preconise', word_class: 'V' },
    { word: 'preconize', word_class: 'V' },
    { word: 'preconisation', word_class: 'N' },
    { word: 'preconization', word_class: 'N' }
  ],
  insistence: [
    { word: 'insist', word_class: 'V' },
    { word: 'insisting', word_class: 'N' },
    { word: 'insistence', word_class: 'N' },
    { word: 'insistency', word_class: 'N' },
    { word: 'insistent', word_class: 'AJ' },
    { word: 'insistently', word_class: 'AV' }
  ],
  mountaineer: [
    { word: 'mountain', word_class: 'N' },
    { word: 'mountain', word_class: 'AJ' },
    { word: 'mountaineer', word_class: 'N' },
    { word: 'mountaineer', word_class: 'V' },
    { word: 'mountainous', word_class: 'AJ' },
    { word: 'mountaineering', word_class: 'N' }
  ],
  majority: [
    { word: 'major', word_class: 'N' },
    { word: 'major', word_class: 'V' },
    { word: 'major', word_class: 'AJ' },
    { word: 'majors', word_class: 'N' },
    { word: 'majority', word_class: 'N' }
  ],
  sleeper: [
    { word: 'sleep', word_class: 'N' },
    { word: 'sleep', word_class: 'V' },
    { word: 'sleeper', word_class: 'N' },
    { word: 'sleeping', word_class: 'N' },
    { word: 'sleeping', word_class: 'AJ' }
  ],
  convivial: [
    { word: 'convivial', word_class: 'AJ' },
    { word: 'conviviality', word_class: 'N' },
    { word: 'convivially', word_class: 'AV' }
  ],
  felicity: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  upward: [
    { word: 'upward', word_class: 'AJ' },
    { word: 'upward', word_class: 'AV' },
    { word: 'upwards', word_class: 'AV' }
  ],
  deflation: [
    { word: 'deflate', word_class: 'V' },
    { word: 'deflator', word_class: 'N' },
    { word: 'deflated', word_class: 'AJ' },
    { word: 'deflation', word_class: 'N' },
    { word: 'deflationary', word_class: 'AJ' }
  ],
  demureness: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  solemnize: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  gallic: [
    { word: 'gallic', word_class: 'AJ' },
    { word: 'gallicism', word_class: 'N' },
    { word: 'gallicize', word_class: 'V' },
    { word: 'gallicization', word_class: 'N' }
  ],
  homogeneously: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  reconnoitre: [
    { word: 'reconnoiter', word_class: 'V' },
    { word: 'reconnoitre', word_class: 'V' },
    { word: 'reconnaissance', word_class: 'N' },
    { word: 'reconnoitering', word_class: 'N' }
  ],
  fantastical: [
    { word: 'fantast', word_class: 'N' },
    { word: 'fantastic', word_class: 'AJ' },
    { word: 'fantastical', word_class: 'AJ' },
    { word: 'fantastically', word_class: 'AV' }
  ],
  revaluate: [
    { word: 'revalue', word_class: 'V' },
    { word: 'revaluate', word_class: 'V' },
    { word: 'revaluation', word_class: 'N' }
  ],
  corset: [
    { word: 'corset', word_class: 'N' },
    { word: 'corset', word_class: 'V' },
    { word: 'corsetry', word_class: 'N' }
  ],
  rotating: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  sending: [
    { word: 'send', word_class: 'V' },
    { word: 'sender', word_class: 'N' },
    { word: 'sending', word_class: 'N' }
  ],
  slaughterous: [
    { word: 'slaughter', word_class: 'N' },
    { word: 'slaughter', word_class: 'V' },
    { word: 'slaughterer', word_class: 'N' },
    { word: 'slaughterous', word_class: 'AJ' }
  ],
  extinguishment: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  tapped: [
    { word: 'tap', word_class: 'N' },
    { word: 'tap', word_class: 'V' },
    { word: 'taps', word_class: 'N' },
    { word: 'tapped', word_class: 'AJ' },
    { word: 'tapping', word_class: 'N' }
  ],
  disciplined: [
    { word: 'discipline', word_class: 'N' },
    { word: 'discipline', word_class: 'V' },
    { word: 'disciplinal', word_class: 'AJ' },
    { word: 'disciplined', word_class: 'AJ' },
    { word: 'disciplinary', word_class: 'AJ' }
  ],
  typing: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  containerful: [
    { word: 'contain', word_class: 'V' },
    { word: 'container', word_class: 'N' },
    { word: 'contained', word_class: 'AJ' },
    { word: 'containment', word_class: 'N' },
    { word: 'containerful', word_class: 'N' }
  ],
  worsened: [
    { word: 'worsen', word_class: 'V' },
    { word: 'worsened', word_class: 'AJ' },
    { word: 'worsening', word_class: 'N' },
    { word: 'worsening', word_class: 'AJ' }
  ],
  obsequiousness: [
    { word: 'obsequy', word_class: 'N' },
    { word: 'obsequious', word_class: 'AJ' },
    { word: 'obsequiously', word_class: 'AV' },
    { word: 'obsequiousness', word_class: 'N' }
  ],
  stenographical: [
    { word: 'stenographer', word_class: 'N' },
    { word: 'stenographic', word_class: 'AJ' },
    { word: 'stenographical', word_class: 'AJ' }
  ],
  caulked: [
    { word: 'caulk', word_class: 'N' },
    { word: 'caulk', word_class: 'V' },
    { word: 'caulked', word_class: 'AJ' },
    { word: 'caulking', word_class: 'N' }
  ],
  episode: [
    { word: 'episode', word_class: 'N' },
    { word: 'episodic', word_class: 'AJ' },
    { word: 'episodically', word_class: 'AV' }
  ],
  unsatisfied: [
    { word: 'unsatisfied', word_class: 'AJ' },
    { word: 'unsatisfying', word_class: 'AJ' },
    { word: 'unsatisfiable', word_class: 'AJ' }
  ],
  removal: [
    { word: 'remove', word_class: 'N' },
    { word: 'remove', word_class: 'V' },
    { word: 'removal', word_class: 'N' },
    { word: 'remover', word_class: 'N' },
    { word: 'removed', word_class: 'AJ' },
    { word: 'removable', word_class: 'AJ' }
  ],
  indenture: [
    { word: 'indent', word_class: 'N' },
    { word: 'indent', word_class: 'V' },
    { word: 'indenture', word_class: 'N' },
    { word: 'indenture', word_class: 'V' },
    { word: 'indented', word_class: 'AJ' },
    { word: 'indentation', word_class: 'N' },
    { word: 'indentured', word_class: 'AJ' }
  ],
  issuant: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  stymie: [
    { word: 'stymy', word_class: 'V' },
    { word: 'stymie', word_class: 'N' },
    { word: 'stymie', word_class: 'V' }
  ],
  unionized: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  determinant: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  squatting: [
    { word: 'squat', word_class: 'N' },
    { word: 'squat', word_class: 'V' },
    { word: 'squat', word_class: 'AJ' },
    { word: 'squatness', word_class: 'N' },
    { word: 'squatting', word_class: 'N' }
  ],
  fueling: [
    { word: 'fuel', word_class: 'N' },
    { word: 'fuel', word_class: 'V' },
    { word: 'fueled', word_class: 'AJ' },
    { word: 'fueling', word_class: 'N' }
  ],
  territorialization: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  matureness: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  conducted: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  historied: [
    { word: 'history', word_class: 'N' },
    { word: 'historic', word_class: 'AJ' },
    { word: 'historied', word_class: 'AJ' },
    { word: 'historical', word_class: 'AJ' },
    { word: 'historically', word_class: 'AV' }
  ],
  ceremony: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  crisp: [
    { word: 'crisp', word_class: 'N' },
    { word: 'crisp', word_class: 'V' },
    { word: 'crisp', word_class: 'AJ' },
    { word: 'crispness', word_class: 'N' }
  ],
  misguide: [
    { word: 'misguide', word_class: 'V' },
    { word: 'misguided', word_class: 'AJ' },
    { word: 'misguidance', word_class: 'N' }
  ],
  conservatively: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  provident: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  grudging: [
    { word: 'grudge', word_class: 'N' },
    { word: 'grudge', word_class: 'V' },
    { word: 'grudging', word_class: 'AJ' }
  ],
  piles: [
    { word: 'pile', word_class: 'N' },
    { word: 'pile', word_class: 'V' },
    { word: 'piles', word_class: 'N' },
    { word: 'piled', word_class: 'AJ' },
    { word: 'piling', word_class: 'N' }
  ],
  equilibration: [
    { word: 'equilibrate', word_class: 'V' },
    { word: 'equilibrize', word_class: 'V' },
    { word: 'equilibration', word_class: 'N' }
  ],
  drudging: [
    { word: 'drudge', word_class: 'N' },
    { word: 'drudge', word_class: 'V' },
    { word: 'drudgery', word_class: 'N' },
    { word: 'drudging', word_class: 'AJ' }
  ],
  ordinary: [
    { word: 'ordinary', word_class: 'N' },
    { word: 'ordinary', word_class: 'AJ' },
    { word: 'ordinariness', word_class: 'N' }
  ],
  urbanite: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  famishment: [
    { word: 'famish', word_class: 'V' },
    { word: 'famish', word_class: 'AJ' },
    { word: 'famished', word_class: 'AJ' },
    { word: 'famishment', word_class: 'N' }
  ],
  impulsiveness: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  rapidity: [
    { word: 'rapid', word_class: 'N' },
    { word: 'rapid', word_class: 'AJ' },
    { word: 'rapids', word_class: 'N' },
    { word: 'rapidity', word_class: 'N' }
  ],
  albinic: [
    { word: 'albinal', word_class: 'AJ' },
    { word: 'albinic', word_class: 'AJ' },
    { word: 'albinism', word_class: 'N' }
  ],
  remembering: [
    { word: 'remember', word_class: 'N' },
    { word: 'remember', word_class: 'V' },
    { word: 'remembered', word_class: 'AJ' },
    { word: 'remembering', word_class: 'N' },
    { word: 'remembrance', word_class: 'N' }
  ],
  complimentary: [
    { word: 'compliment', word_class: 'N' },
    { word: 'compliment', word_class: 'V' },
    { word: 'compliments', word_class: 'N' },
    { word: 'complimentary', word_class: 'AJ' }
  ],
  belligerency: [
    { word: 'belligerent', word_class: 'N' },
    { word: 'belligerence', word_class: 'N' },
    { word: 'belligerency', word_class: 'N' },
    { word: 'belligerent', word_class: 'AJ' },
    { word: 'belligerently', word_class: 'AV' }
  ],
  unattractiveness: [
    { word: 'unattractive', word_class: 'AJ' },
    { word: 'unattractively', word_class: 'AV' },
    { word: 'unattractiveness', word_class: 'N' }
  ],
  disproval: [
    { word: 'disproof', word_class: 'N' },
    { word: 'disprove', word_class: 'V' },
    { word: 'disproval', word_class: 'N' },
    { word: 'disproving', word_class: 'N' }
  ],
  cloister: [
    { word: 'cloister', word_class: 'N' },
    { word: 'cloister', word_class: 'V' },
    { word: 'cloistered', word_class: 'AJ' }
  ],
  book: [
    { word: 'book', word_class: 'N' },
    { word: 'book', word_class: 'V' },
    { word: 'booker', word_class: 'N' },
    { word: 'booked', word_class: 'AJ' },
    { word: 'booking', word_class: 'N' }
  ],
  'double-dealing': [
    { word: 'double-date', word_class: 'V' },
    { word: 'double-dealing', word_class: 'N' },
    { word: 'double-dealing', word_class: 'AJ' }
  ],
  metrical: [
    { word: 'metric', word_class: 'N' },
    { word: 'metric', word_class: 'AJ' },
    { word: 'metrical', word_class: 'AJ' },
    { word: 'metricize', word_class: 'V' },
    { word: 'metrically', word_class: 'AV' },
    { word: 'metrication', word_class: 'N' }
  ],
  mummy: [
    { word: 'mummy', word_class: 'N' },
    { word: 'mummify', word_class: 'V' },
    { word: 'mummification', word_class: 'N' }
  ],
  insulant: [
    { word: 'insulant', word_class: 'N' },
    { word: 'insulate', word_class: 'V' },
    { word: 'insulator', word_class: 'N' },
    { word: 'insulation', word_class: 'N' }
  ],
  clangoring: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  bow: [
    { word: 'bow', word_class: 'N' },
    { word: 'bow', word_class: 'V' },
    { word: 'bow', word_class: 'AJ' },
    { word: 'bower', word_class: 'N' },
    { word: 'bower', word_class: 'V' },
    { word: 'bowed', word_class: 'AJ' },
    { word: 'bowman', word_class: 'N' },
    { word: 'bowing', word_class: 'N' },
    { word: 'bowing', word_class: 'AJ' }
  ],
  misjudge: [
    { word: 'misjudge', word_class: 'V' },
    { word: 'misjudgment', word_class: 'N' },
    { word: 'misjudgement', word_class: 'N' }
  ],
  baffled: [
    { word: 'baffle', word_class: 'N' },
    { word: 'baffle', word_class: 'V' },
    { word: 'baffled', word_class: 'N' },
    { word: 'baffled', word_class: 'AJ' },
    { word: 'baffling', word_class: 'AJ' },
    { word: 'bafflement', word_class: 'N' }
  ],
  argus: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  lisp: [{ word: 'lisp', word_class: 'N' }, { word: 'lisp', word_class: 'V' }, { word: 'lisper', word_class: 'N' }],
  tutorship: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  virginity: [
    { word: 'virgin', word_class: 'N' },
    { word: 'virgin', word_class: 'AJ' },
    { word: 'virginal', word_class: 'N' },
    { word: 'virginal', word_class: 'AJ' },
    { word: 'virginity', word_class: 'N' }
  ],
  circular: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  bellows: [
    { word: 'bellow', word_class: 'N' },
    { word: 'bellow', word_class: 'V' },
    { word: 'bellows', word_class: 'N' },
    { word: 'bellowing', word_class: 'N' }
  ],
  mixture: [
    { word: 'mix', word_class: 'N' },
    { word: 'mix', word_class: 'V' },
    { word: 'mixer', word_class: 'N' },
    { word: 'mixed', word_class: 'AJ' },
    { word: 'mixing', word_class: 'N' },
    { word: 'mixture', word_class: 'N' }
  ],
  home: [
    { word: 'home', word_class: 'N' },
    { word: 'home', word_class: 'V' },
    { word: 'homy', word_class: 'AJ' },
    { word: 'homer', word_class: 'N' },
    { word: 'home', word_class: 'AV' },
    { word: 'home', word_class: 'AJ' },
    { word: 'homer', word_class: 'V' },
    { word: 'homely', word_class: 'AJ' },
    { word: 'homing', word_class: 'AJ' },
    { word: 'homeric', word_class: 'AJ' }
  ],
  pigeonholing: [
    { word: 'pigeonhole', word_class: 'N' },
    { word: 'pigeonhole', word_class: 'V' },
    { word: 'pigeonholing', word_class: 'N' }
  ],
  squirm: [
    { word: 'squirm', word_class: 'N' },
    { word: 'squirm', word_class: 'V' },
    { word: 'squirmy', word_class: 'AJ' },
    { word: 'squirming', word_class: 'AJ' }
  ],
  congeal: [
    { word: 'congeal', word_class: 'V' },
    { word: 'congealed', word_class: 'AJ' },
    { word: 'congealment', word_class: 'N' },
    { word: 'congelation', word_class: 'N' }
  ],
  replicate: [
    { word: 'replicate', word_class: 'V' },
    { word: 'replicable', word_class: 'AJ' },
    { word: 'replication', word_class: 'N' }
  ],
  enterprise: [
    { word: 'enterprise', word_class: 'N' },
    { word: 'enterpriser', word_class: 'N' },
    { word: 'enterprising', word_class: 'AJ' }
  ],
  darning: [
    { word: 'darn', word_class: 'N' },
    { word: 'darn', word_class: 'V' },
    { word: 'darned', word_class: 'AJ' },
    { word: 'darning', word_class: 'N' }
  ],
  systematization: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  belittling: [
    { word: 'belittle', word_class: 'V' },
    { word: 'belittled', word_class: 'AJ' },
    { word: 'belittling', word_class: 'N' },
    { word: 'belittling', word_class: 'AJ' },
    { word: 'belittlement', word_class: 'N' }
  ],
  extremely: [
    { word: 'extreme', word_class: 'N' },
    { word: 'extreme', word_class: 'AJ' },
    { word: 'extremism', word_class: 'N' },
    { word: 'extremity', word_class: 'N' },
    { word: 'extremely', word_class: 'AV' }
  ],
  despised: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  epidemiology: [
    { word: 'epidemiology', word_class: 'N' },
    { word: 'epidemiologic', word_class: 'AJ' },
    { word: 'epidemiological', word_class: 'AJ' }
  ],
  arterialization: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  approximation: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  subjunctive: [
    { word: 'subjoin', word_class: 'V' },
    { word: 'subjoining', word_class: 'N' },
    { word: 'subjunction', word_class: 'N' },
    { word: 'subjunctive', word_class: 'N' },
    { word: 'subjunctive', word_class: 'AJ' }
  ],
  empiric: [
    { word: 'empire', word_class: 'N' },
    { word: 'empiric', word_class: 'AJ' },
    { word: 'empirical', word_class: 'AJ' },
    { word: 'empirically', word_class: 'AV' }
  ],
  pillaging: [
    { word: 'pillage', word_class: 'N' },
    { word: 'pillage', word_class: 'V' },
    { word: 'pillager', word_class: 'N' },
    { word: 'pillaged', word_class: 'AJ' },
    { word: 'pillaging', word_class: 'N' }
  ],
  preeminence: [
    { word: 'preeminence', word_class: 'N' },
    { word: 'preeminent', word_class: 'AJ' },
    { word: 'preeminently', word_class: 'AV' }
  ],
  exalting: [
    { word: 'exalt', word_class: 'V' },
    { word: 'exalted', word_class: 'AJ' },
    { word: 'exalting', word_class: 'AJ' },
    { word: 'exaltation', word_class: 'N' }
  ],
  facetiousness: [
    { word: 'facetious', word_class: 'AJ' },
    { word: 'facetiously', word_class: 'AV' },
    { word: 'facetiousness', word_class: 'N' }
  ],
  chronological: [
    { word: 'chronology', word_class: 'N' },
    { word: 'chronologize', word_class: 'V' },
    { word: 'chronological', word_class: 'AJ' },
    { word: 'chronologically', word_class: 'AV' }
  ],
  extirpate: [
    { word: 'extirpate', word_class: 'V' },
    { word: 'extirpable', word_class: 'AJ' },
    { word: 'extirpation', word_class: 'N' }
  ],
  insurer: [
    { word: 'insure', word_class: 'V' },
    { word: 'insure', word_class: 'AJ' },
    { word: 'insurer', word_class: 'N' },
    { word: 'insured', word_class: 'AJ' },
    { word: 'insurance', word_class: 'N' }
  ],
  pitying: [
    { word: 'pity', word_class: 'N' },
    { word: 'pity', word_class: 'V' },
    { word: 'piteous', word_class: 'AJ' },
    { word: 'pitiful', word_class: 'AJ' },
    { word: 'pitying', word_class: 'AJ' }
  ],
  swashbuckle: [
    { word: 'swashbuckle', word_class: 'V' },
    { word: 'swashbuckler', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'N' },
    { word: 'swashbuckling', word_class: 'AJ' }
  ],
  fearfulness: [
    { word: 'fear', word_class: 'N' },
    { word: 'fear', word_class: 'V' },
    { word: 'fearful', word_class: 'AJ' },
    { word: 'fearsome', word_class: 'AJ' },
    { word: 'fearfulness', word_class: 'N' },
    { word: 'fearsomely', word_class: 'AV' }
  ],
  dispersal: [
    { word: 'disperse', word_class: 'V' },
    { word: 'dispersal', word_class: 'N' },
    { word: 'dispersed', word_class: 'AJ' },
    { word: 'dispersion', word_class: 'N' },
    { word: 'dispersive', word_class: 'AJ' }
  ],
  administratively: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  stay: [
    { word: 'stay', word_class: 'N' },
    { word: 'stay', word_class: 'V' },
    { word: 'stays', word_class: 'N' },
    { word: 'stayer', word_class: 'N' },
    { word: 'stayman', word_class: 'N' }
  ],
  theatrical: [
    { word: 'theater', word_class: 'N' },
    { word: 'theatric', word_class: 'AJ' },
    { word: 'theatrical', word_class: 'N' },
    { word: 'theatrical', word_class: 'AJ' },
    { word: 'theatricality', word_class: 'N' },
    { word: 'theatrically', word_class: 'AV' }
  ],
  studded: [
    { word: 'stud', word_class: 'N' },
    { word: 'stud', word_class: 'V' },
    { word: 'student', word_class: 'N' },
    { word: 'studded', word_class: 'AJ' }
  ],
  granulated: [
    { word: 'granule', word_class: 'N' },
    { word: 'granulate', word_class: 'V' },
    { word: 'granulated', word_class: 'AJ' },
    { word: 'granulation', word_class: 'N' }
  ],
  parochialism: [
    { word: 'parish', word_class: 'N' },
    { word: 'parochial', word_class: 'AJ' },
    { word: 'parochialism', word_class: 'N' },
    { word: 'parochially', word_class: 'AV' }
  ],
  coziness: [
    { word: 'cozy', word_class: 'N' },
    { word: 'cozie', word_class: 'N' },
    { word: 'cozy', word_class: 'AJ' },
    { word: 'coziness', word_class: 'N' }
  ],
  precarious: [
    { word: 'precarious', word_class: 'AJ' },
    { word: 'precariously', word_class: 'AV' },
    { word: 'precariousness', word_class: 'N' }
  ],
  pondering: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  curled: [
    { word: 'curl', word_class: 'N' },
    { word: 'curl', word_class: 'V' },
    { word: 'curler', word_class: 'N' },
    { word: 'curled', word_class: 'AJ' },
    { word: 'curling', word_class: 'N' },
    { word: 'curling', word_class: 'AJ' }
  ],
  skimmed: [
    { word: 'skim', word_class: 'N' },
    { word: 'skim', word_class: 'V' },
    { word: 'skim', word_class: 'AJ' },
    { word: 'skimmed', word_class: 'AJ' },
    { word: 'skimming', word_class: 'N' }
  ],
  traversal: [
    { word: 'traverse', word_class: 'N' },
    { word: 'traverse', word_class: 'V' },
    { word: 'traversal', word_class: 'N' },
    { word: 'traversable', word_class: 'AJ' }
  ],
  pressing: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  charming: [
    { word: 'charm', word_class: 'N' },
    { word: 'charm', word_class: 'V' },
    { word: 'charmer', word_class: 'N' },
    { word: 'charmed', word_class: 'AJ' },
    { word: 'charming', word_class: 'AJ' }
  ],
  badger: [
    { word: 'badger', word_class: 'N' },
    { word: 'badger', word_class: 'V' },
    { word: 'badgerer', word_class: 'N' },
    { word: 'badgering', word_class: 'N' }
  ],
  rating: [{ word: 'rate', word_class: 'N' }, { word: 'rate', word_class: 'V' }, { word: 'rating', word_class: 'N' }],
  canal: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  water: [
    { word: 'water', word_class: 'N' },
    { word: 'water', word_class: 'V' },
    { word: 'waters', word_class: 'N' },
    { word: 'watered', word_class: 'AJ' },
    { word: 'watering', word_class: 'N' },
    { word: 'waterman', word_class: 'N' }
  ],
  locked: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  jocular: [
    { word: 'jocular', word_class: 'AJ' },
    { word: 'jocular', word_class: 'AV' },
    { word: 'jocularity', word_class: 'N' }
  ],
  jotting: [{ word: 'jot', word_class: 'N' }, { word: 'jot', word_class: 'V' }, { word: 'jotting', word_class: 'N' }],
  culpability: [
    { word: 'culpable', word_class: 'AJ' },
    { word: 'culpably', word_class: 'AV' },
    { word: 'culpability', word_class: 'N' },
    { word: 'culpableness', word_class: 'N' }
  ],
  cryptical: [
    { word: 'cryptic', word_class: 'AJ' },
    { word: 'cryptical', word_class: 'AJ' },
    { word: 'cryptically', word_class: 'AV' }
  ],
  semiotical: [
    { word: 'semiotic', word_class: 'AJ' },
    { word: 'semiotics', word_class: 'N' },
    { word: 'semiotical', word_class: 'AJ' }
  ],
  antithetically: [
    { word: 'antithetic', word_class: 'AJ' },
    { word: 'antithetical', word_class: 'AJ' },
    { word: 'antithetically', word_class: 'AV' }
  ],
  picnicker: [
    { word: 'picnic', word_class: 'N' },
    { word: 'picnic', word_class: 'V' },
    { word: 'picnicker', word_class: 'N' }
  ],
  mediateness: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  canalization: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  axiom: [
    { word: 'axiom', word_class: 'N' },
    { word: 'axiomatic', word_class: 'AJ' },
    { word: 'axiomatical', word_class: 'AJ' },
    { word: 'axiomatically', word_class: 'AV' }
  ],
  agreed: [
    { word: 'agree', word_class: 'V' },
    { word: 'agreed', word_class: 'AJ' },
    { word: 'agreeing', word_class: 'AJ' },
    { word: 'agreement', word_class: 'N' }
  ],
  overcast: [
    { word: 'overcast', word_class: 'N' },
    { word: 'overcast', word_class: 'V' },
    { word: 'overcast', word_class: 'AJ' },
    { word: 'overcasting', word_class: 'N' }
  ],
  sewer: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  busying: [
    { word: 'busy', word_class: 'V' },
    { word: 'busy', word_class: 'AJ' },
    { word: 'busying', word_class: 'N' },
    { word: 'business', word_class: 'N' },
    { word: 'busyness', word_class: 'N' },
    { word: 'business', word_class: 'AJ' }
  ],
  trichinisation: [
    { word: 'trichinise', word_class: 'V' },
    { word: 'trichinize', word_class: 'V' },
    { word: 'trichinisation', word_class: 'N' },
    { word: 'trichinization', word_class: 'N' }
  ],
  maintained: [
    { word: 'maintain', word_class: 'V' },
    { word: 'maintained', word_class: 'AJ' },
    { word: 'maintenance', word_class: 'N' },
    { word: 'maintainable', word_class: 'AJ' }
  ],
  therapist: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  'right-hander': [
    { word: 'right-hand', word_class: 'AJ' },
    { word: 'right-hander', word_class: 'N' },
    { word: 'right-handed', word_class: 'AJ' }
  ],
  structurally: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  perm: [
    { word: 'perm', word_class: 'N' },
    { word: 'perm', word_class: 'V' },
    { word: 'permed', word_class: 'AJ' },
    { word: 'permeative', word_class: 'AJ' },
    { word: 'perlocutionary', word_class: 'N' }
  ],
  subsidisation: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  engrossed: [
    { word: 'engross', word_class: 'V' },
    { word: 'engrossed', word_class: 'AJ' },
    { word: 'engrossing', word_class: 'AJ' },
    { word: 'engrossment', word_class: 'N' }
  ],
  ethyl: [
    { word: 'ethyl', word_class: 'N' },
    { word: 'ethylate', word_class: 'V' },
    { word: 'ethylation', word_class: 'N' }
  ],
  clench: [
    { word: 'clench', word_class: 'N' },
    { word: 'clench', word_class: 'V' },
    { word: 'clenched', word_class: 'AJ' }
  ],
  retreat: [
    { word: 'retreat', word_class: 'N' },
    { word: 'retreat', word_class: 'V' },
    { word: 'retreated', word_class: 'N' },
    { word: 'retreatant', word_class: 'N' },
    { word: 'retreating', word_class: 'AJ' }
  ],
  grudge: [
    { word: 'grudge', word_class: 'N' },
    { word: 'grudge', word_class: 'V' },
    { word: 'grudging', word_class: 'AJ' }
  ],
  compartmentalization: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  dense: [
    { word: 'dense', word_class: 'AJ' },
    { word: 'densely', word_class: 'AV' },
    { word: 'denseness', word_class: 'N' }
  ],
  burdensome: [
    { word: 'burden', word_class: 'N' },
    { word: 'burden', word_class: 'V' },
    { word: 'burdened', word_class: 'AJ' },
    { word: 'burdensome', word_class: 'AJ' },
    { word: 'burdensomeness', word_class: 'N' }
  ],
  corroding: [
    { word: 'corrode', word_class: 'V' },
    { word: 'corroded', word_class: 'AJ' },
    { word: 'corroding', word_class: 'N' },
    { word: 'corrosion', word_class: 'N' },
    { word: 'corrosive', word_class: 'N' },
    { word: 'corrosive', word_class: 'AJ' }
  ],
  adulterate: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  eulogize: [
    { word: 'eulogy', word_class: 'N' },
    { word: 'eulogist', word_class: 'N' },
    { word: 'eulogize', word_class: 'V' },
    { word: 'eulogistic', word_class: 'AJ' },
    { word: 'eulogization', word_class: 'N' }
  ],
  plumaged: [
    { word: 'plume', word_class: 'N' },
    { word: 'plume', word_class: 'V' },
    { word: 'plumage', word_class: 'N' },
    { word: 'plumed', word_class: 'AJ' },
    { word: 'plumaged', word_class: 'AJ' }
  ],
  monotonous: [
    { word: 'monotone', word_class: 'N' },
    { word: 'monotone', word_class: 'AJ' },
    { word: 'monotonic', word_class: 'AJ' },
    { word: 'monotonous', word_class: 'AJ' },
    { word: 'monotonously', word_class: 'AV' }
  ],
  luxuriant: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  crack: [
    { word: 'crack', word_class: 'N' },
    { word: 'crack', word_class: 'V' },
    { word: 'crack', word_class: 'AJ' },
    { word: 'cracker', word_class: 'N' },
    { word: 'cracked', word_class: 'AJ' },
    { word: 'cracking', word_class: 'N' },
    { word: 'crackers', word_class: 'AJ' },
    { word: 'cracking', word_class: 'AJ' }
  ],
  garrulous: [
    { word: 'garrulity', word_class: 'N' },
    { word: 'garrulous', word_class: 'AJ' },
    { word: 'garrulously', word_class: 'AV' },
    { word: 'garrulousness', word_class: 'N' }
  ],
  splicer: [
    { word: 'splice', word_class: 'N' },
    { word: 'splice', word_class: 'V' },
    { word: 'splicer', word_class: 'N' },
    { word: 'splicing', word_class: 'N' }
  ],
  doze: [
    { word: 'doze', word_class: 'N' },
    { word: 'doze', word_class: 'V' },
    { word: 'dozer', word_class: 'N' },
    { word: 'dozing', word_class: 'AJ' }
  ],
  allotropic: [
    { word: 'allotrope', word_class: 'N' },
    { word: 'allotropic', word_class: 'AJ' },
    { word: 'allotropism', word_class: 'N' },
    { word: 'allotropical', word_class: 'AJ' }
  ],
  carter: [
    { word: 'cart', word_class: 'N' },
    { word: 'cart', word_class: 'V' },
    { word: 'carte', word_class: 'N' },
    { word: 'carter', word_class: 'N' },
    { word: 'carting', word_class: 'N' }
  ],
  modify: [
    { word: 'modify', word_class: 'V' },
    { word: 'modifier', word_class: 'N' },
    { word: 'modified', word_class: 'AJ' },
    { word: 'modifiable', word_class: 'AJ' },
    { word: 'modification', word_class: 'N' }
  ],
  wee: [
    { word: 'wee', word_class: 'N' },
    { word: 'wee', word_class: 'V' },
    { word: 'weal', word_class: 'N' },
    { word: 'west', word_class: 'N' },
    { word: 'wee', word_class: 'AJ' },
    { word: 'west', word_class: 'AJ' },
    { word: 'west', word_class: 'AV' },
    { word: 'weeness', word_class: 'N' }
  ],
  tranparent: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  deliquesce: [
    { word: 'deliquesce', word_class: 'V' },
    { word: 'deliquescence', word_class: 'N' },
    { word: 'deliquescent', word_class: 'AJ' }
  ],
  contemporaneousness: [
    { word: 'contemporize', word_class: 'V' },
    { word: 'contemporaneity', word_class: 'N' },
    { word: 'contemporaneous', word_class: 'AJ' },
    { word: 'contemporaneously', word_class: 'AV' },
    { word: 'contemporaneousness', word_class: 'N' }
  ],
  blemishment: [
    { word: 'blemish', word_class: 'N' },
    { word: 'blemish', word_class: 'V' },
    { word: 'blemished', word_class: 'AJ' },
    { word: 'blemishment', word_class: 'N' }
  ],
  lunate: [
    { word: 'lunate', word_class: 'AJ' },
    { word: 'lunatic', word_class: 'N' },
    { word: 'lunatic', word_class: 'AJ' },
    { word: 'lunation', word_class: 'N' }
  ],
  adverbial: [
    { word: 'adverbial', word_class: 'N' },
    { word: 'adverbial', word_class: 'AJ' },
    { word: 'adverbially', word_class: 'AV' }
  ],
  ordered: [
    { word: 'order', word_class: 'N' },
    { word: 'order', word_class: 'V' },
    { word: 'ordered', word_class: 'AJ' },
    { word: 'ordering', word_class: 'N' }
  ],
  airing: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  opal: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  episcopalian: [
    { word: 'episcopalian', word_class: 'N' },
    { word: 'episcopalian', word_class: 'AJ' },
    { word: 'episcopalianism', word_class: 'N' }
  ],
  grinding: [
    { word: 'grind', word_class: 'N' },
    { word: 'grind', word_class: 'V' },
    { word: 'grinder', word_class: 'N' },
    { word: 'grinding', word_class: 'N' }
  ],
  wainscot: [
    { word: 'wainscot', word_class: 'N' },
    { word: 'wainscot', word_class: 'V' },
    { word: 'wainscoted', word_class: 'AJ' },
    { word: 'wainscoting', word_class: 'N' },
    { word: 'wainscotting', word_class: 'N' }
  ],
  delightful: [
    { word: 'delight', word_class: 'N' },
    { word: 'delight', word_class: 'V' },
    { word: 'delighted', word_class: 'AJ' },
    { word: 'delightful', word_class: 'AJ' }
  ],
  polychromize: [
    { word: 'polychrome', word_class: 'V' },
    { word: 'polychrome', word_class: 'AJ' },
    { word: 'polychromic', word_class: 'AJ' },
    { word: 'polychromize', word_class: 'V' }
  ],
  contend: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  undependably: [
    { word: 'undependable', word_class: 'AJ' },
    { word: 'undependably', word_class: 'AV' },
    { word: 'undependability', word_class: 'N' },
    { word: 'undependableness', word_class: 'N' }
  ],
  edifying: [
    { word: 'edify', word_class: 'V' },
    { word: 'edified', word_class: 'AJ' },
    { word: 'edifying', word_class: 'AJ' },
    { word: 'edification', word_class: 'N' }
  ],
  mutant: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  abasement: [
    { word: 'abase', word_class: 'V' },
    { word: 'abasic', word_class: 'AJ' },
    { word: 'abasement', word_class: 'N' }
  ],
  dangling: [
    { word: 'dangle', word_class: 'N' },
    { word: 'dangle', word_class: 'V' },
    { word: 'dangling', word_class: 'N' },
    { word: 'dangling', word_class: 'AJ' }
  ],
  ignored: [
    { word: 'ignore', word_class: 'V' },
    { word: 'ignorant', word_class: 'N' },
    { word: 'ignored', word_class: 'AJ' },
    { word: 'ignorance', word_class: 'N' },
    { word: 'ignorant', word_class: 'AJ' },
    { word: 'ignorantness', word_class: 'N' }
  ],
  shit: [{ word: 'shit', word_class: 'N' }, { word: 'shit', word_class: 'V' }, { word: 'shitting', word_class: 'N' }],
  cloaked: [
    { word: 'cloak', word_class: 'N' },
    { word: 'cloak', word_class: 'V' },
    { word: 'cloaked', word_class: 'AJ' }
  ],
  shutting: [
    { word: 'shut', word_class: 'V' },
    { word: 'shut', word_class: 'AJ' },
    { word: 'shutting', word_class: 'N' }
  ],
  lesbianism: [
    { word: 'lesbian', word_class: 'N' },
    { word: 'lesbian', word_class: 'AJ' },
    { word: 'lesbianism', word_class: 'N' }
  ],
  ousting: [
    { word: 'oust', word_class: 'V' },
    { word: 'ouster', word_class: 'N' },
    { word: 'ousting', word_class: 'N' }
  ],
  guest: [{ word: 'guest', word_class: 'N' }, { word: 'guest', word_class: 'V' }, { word: 'guest', word_class: 'AJ' }],
  ebulliently: [
    { word: 'ebullience', word_class: 'N' },
    { word: 'ebullient', word_class: 'AJ' },
    { word: 'ebulliently', word_class: 'AV' }
  ],
  lexicographic: [
    { word: 'lexicographer', word_class: 'N' },
    { word: 'lexicographic', word_class: 'AJ' },
    { word: 'lexicographical', word_class: 'AJ' }
  ],
  propitiously: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  buttoned: [
    { word: 'button', word_class: 'N' },
    { word: 'button', word_class: 'V' },
    { word: 'buttoned', word_class: 'AJ' }
  ],
  libidinal: [
    { word: 'libido', word_class: 'N' },
    { word: 'libidinal', word_class: 'AJ' },
    { word: 'libidinous', word_class: 'AJ' }
  ],
  impotence: [
    { word: 'impotence', word_class: 'N' },
    { word: 'impotency', word_class: 'N' },
    { word: 'impotent', word_class: 'AJ' },
    { word: 'impotently', word_class: 'AV' }
  ],
  colorate: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  harmful: [
    { word: 'harm', word_class: 'N' },
    { word: 'harm', word_class: 'V' },
    { word: 'harmed', word_class: 'AJ' },
    { word: 'harmful', word_class: 'AJ' },
    { word: 'harmfulness', word_class: 'N' }
  ],
  variolate: [
    { word: 'variolate', word_class: 'V' },
    { word: 'variolation', word_class: 'N' },
    { word: 'variolisation', word_class: 'N' },
    { word: 'variolization', word_class: 'N' }
  ],
  forthrightness: [
    { word: 'forthright', word_class: 'AJ' },
    { word: 'forthright', word_class: 'AV' },
    { word: 'forthrightness', word_class: 'N' }
  ],
  old: [
    { word: 'old', word_class: 'N' },
    { word: 'old', word_class: 'AJ' },
    { word: 'oldy', word_class: 'N' },
    { word: 'oldness', word_class: 'N' }
  ],
  memorizer: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  clogging: [
    { word: 'clog', word_class: 'N' },
    { word: 'clog', word_class: 'V' },
    { word: 'clogged', word_class: 'AJ' },
    { word: 'clogging', word_class: 'AJ' }
  ],
  diminishment: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  transportation: [
    { word: 'transport', word_class: 'N' },
    { word: 'transport', word_class: 'V' },
    { word: 'transporter', word_class: 'N' },
    { word: 'transportable', word_class: 'AJ' },
    { word: 'transportation', word_class: 'N' }
  ],
  balking: [
    { word: 'balk', word_class: 'N' },
    { word: 'balk', word_class: 'V' },
    { word: 'balked', word_class: 'AJ' },
    { word: 'balking', word_class: 'AJ' }
  ],
  irons: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  paralyzation: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  aesthete: [
    { word: 'aesthete', word_class: 'N' },
    { word: 'aesthetic', word_class: 'N' },
    { word: 'aesthetic', word_class: 'AJ' },
    { word: 'aesthetics', word_class: 'N' },
    { word: 'aesthetical', word_class: 'AJ' },
    { word: 'aesthetically', word_class: 'AV' }
  ],
  stooping: [
    { word: 'stoop', word_class: 'N' },
    { word: 'stoop', word_class: 'V' },
    { word: 'stooped', word_class: 'AJ' },
    { word: 'stooping', word_class: 'AJ' }
  ],
  aviator: [
    { word: 'aviate', word_class: 'V' },
    { word: 'aviator', word_class: 'N' },
    { word: 'aviation', word_class: 'N' }
  ],
  nefariousness: [
    { word: 'nefarious', word_class: 'AJ' },
    { word: 'nefariously', word_class: 'AV' },
    { word: 'nefariousness', word_class: 'N' }
  ],
  mangle: [
    { word: 'mangle', word_class: 'N' },
    { word: 'mangle', word_class: 'V' },
    { word: 'mangled', word_class: 'AJ' }
  ],
  highness: [
    { word: 'high', word_class: 'N' },
    { word: 'high', word_class: 'AJ' },
    { word: 'high', word_class: 'AV' },
    { word: 'heighten', word_class: 'V' },
    { word: 'highness', word_class: 'N' },
    { word: 'heightening', word_class: 'AJ' }
  ],
  affine: [
    { word: 'affine', word_class: 'N' },
    { word: 'affine', word_class: 'AJ' },
    { word: 'affinal', word_class: 'AJ' },
    { word: 'affined', word_class: 'AJ' },
    { word: 'affinity', word_class: 'N' }
  ],
  downstair: [
    { word: 'downstair', word_class: 'AJ' },
    { word: 'downstairs', word_class: 'N' },
    { word: 'downstairs', word_class: 'AJ' },
    { word: 'downstairs', word_class: 'AV' }
  ],
  lignified: [
    { word: 'lignify', word_class: 'V' },
    { word: 'lignified', word_class: 'AJ' },
    { word: 'lignification', word_class: 'N' }
  ],
  intuitive: [
    { word: 'intuit', word_class: 'V' },
    { word: 'intuition', word_class: 'N' },
    { word: 'intuitive', word_class: 'AJ' },
    { word: 'intuitively', word_class: 'AV' }
  ],
  followers: [
    { word: 'follow', word_class: 'V' },
    { word: 'follower', word_class: 'N' },
    { word: 'followers', word_class: 'N' },
    { word: 'following', word_class: 'N' },
    { word: 'following', word_class: 'AJ' }
  ],
  harrow: [
    { word: 'harrow', word_class: 'N' },
    { word: 'harrow', word_class: 'V' },
    { word: 'harrowing', word_class: 'AJ' },
    { word: 'harrowment', word_class: 'N' }
  ],
  despondency: [
    { word: 'despond', word_class: 'V' },
    { word: 'despondence', word_class: 'N' },
    { word: 'despondency', word_class: 'N' },
    { word: 'despondent', word_class: 'AJ' },
    { word: 'despondently', word_class: 'AV' }
  ],
  amusement: [
    { word: 'amuse', word_class: 'V' },
    { word: 'amused', word_class: 'AJ' },
    { word: 'amusing', word_class: 'AJ' },
    { word: 'amusive', word_class: 'AJ' },
    { word: 'amusement', word_class: 'N' }
  ],
  unalterably: [
    { word: 'unalterable', word_class: 'AJ' },
    { word: 'unalterably', word_class: 'AV' },
    { word: 'unalterability', word_class: 'N' }
  ],
  powderize: [
    { word: 'powder', word_class: 'N' },
    { word: 'powder', word_class: 'V' },
    { word: 'powdered', word_class: 'AJ' },
    { word: 'powderize', word_class: 'V' }
  ],
  democratize: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  promisor: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  sponge: [
    { word: 'sponge', word_class: 'N' },
    { word: 'sponge', word_class: 'V' },
    { word: 'sponger', word_class: 'N' },
    { word: 'spongy', word_class: 'AJ' },
    { word: 'sponginess', word_class: 'N' }
  ],
  transduce: [
    { word: 'transduce', word_class: 'V' },
    { word: 'transducer', word_class: 'N' },
    { word: 'transduction', word_class: 'N' }
  ],
  scarce: [
    { word: 'scarce', word_class: 'AJ' },
    { word: 'scarce', word_class: 'AV' },
    { word: 'scarcely', word_class: 'AV' },
    { word: 'scarceness', word_class: 'N' }
  ],
  lurch: [
    { word: 'lurch', word_class: 'N' },
    { word: 'lurch', word_class: 'V' },
    { word: 'lurcher', word_class: 'N' },
    { word: 'lurching', word_class: 'AJ' }
  ],
  tope: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  forfeit: [
    { word: 'forfeit', word_class: 'N' },
    { word: 'forfeit', word_class: 'V' },
    { word: 'forfeit', word_class: 'AJ' },
    { word: 'forfeited', word_class: 'AJ' },
    { word: 'forfeiture', word_class: 'N' }
  ],
  hennaed: [
    { word: 'henna', word_class: 'N' },
    { word: 'henna', word_class: 'V' },
    { word: 'hennaed', word_class: 'AJ' }
  ],
  demolition: [
    { word: 'demolish', word_class: 'V' },
    { word: 'demolition', word_class: 'N' },
    { word: 'demolished', word_class: 'AJ' },
    { word: 'demolishing', word_class: 'N' },
    { word: 'demolishment', word_class: 'N' }
  ],
  improver: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  accepted: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  unusually: [
    { word: 'unusual', word_class: 'AJ' },
    { word: 'unusually', word_class: 'AV' },
    { word: 'unusualness', word_class: 'N' }
  ],
  trembles: [
    { word: 'tremble', word_class: 'N' },
    { word: 'tremble', word_class: 'V' },
    { word: 'trembles', word_class: 'N' },
    { word: 'trembly', word_class: 'AJ' },
    { word: 'trembling', word_class: 'N' },
    { word: 'trembling', word_class: 'AJ' }
  ],
  showiness: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  translucency: [
    { word: 'translucence', word_class: 'N' },
    { word: 'translucency', word_class: 'N' },
    { word: 'translucent', word_class: 'AJ' }
  ],
  helpfulness: [
    { word: 'help', word_class: 'N' },
    { word: 'help', word_class: 'V' },
    { word: 'helper', word_class: 'N' },
    { word: 'helping', word_class: 'N' },
    { word: 'helpful', word_class: 'AJ' },
    { word: 'helpfulness', word_class: 'N' }
  ],
  clipped: [
    { word: 'clip', word_class: 'N' },
    { word: 'clip', word_class: 'V' },
    { word: 'clipped', word_class: 'AJ' },
    { word: 'clipping', word_class: 'N' }
  ],
  supercharger: [
    { word: 'supercharge', word_class: 'V' },
    { word: 'supercharger', word_class: 'N' },
    { word: 'supercharged', word_class: 'AJ' }
  ],
  chart: [
    { word: 'chart', word_class: 'N' },
    { word: 'chart', word_class: 'V' },
    { word: 'chartism', word_class: 'N' },
    { word: 'chartist', word_class: 'N' }
  ],
  dominating: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  entangle: [
    { word: 'entangle', word_class: 'V' },
    { word: 'entangled', word_class: 'AJ' },
    { word: 'entanglement', word_class: 'N' }
  ],
  exegetical: [
    { word: 'exegete', word_class: 'N' },
    { word: 'exegetic', word_class: 'AJ' },
    { word: 'exegetical', word_class: 'AJ' }
  ],
  cutinisation: [
    { word: 'cutinise', word_class: 'V' },
    { word: 'cutinize', word_class: 'V' },
    { word: 'cutinisation', word_class: 'N' },
    { word: 'cutinization', word_class: 'N' }
  ],
  uninstructive: [
    { word: 'uninstructed', word_class: 'AJ' },
    { word: 'uninstructive', word_class: 'AJ' },
    { word: 'uninstructively', word_class: 'AV' }
  ],
  transhipment: [
    { word: 'tranship', word_class: 'V' },
    { word: 'transship', word_class: 'N' },
    { word: 'transship', word_class: 'V' },
    { word: 'transhipment', word_class: 'N' },
    { word: 'transshipment', word_class: 'N' }
  ],
  bicuspidate: [
    { word: 'bicuspid', word_class: 'N' },
    { word: 'bicuspid', word_class: 'AJ' },
    { word: 'bicuspidate', word_class: 'AJ' }
  ],
  plagued: [
    { word: 'plague', word_class: 'N' },
    { word: 'plague', word_class: 'V' },
    { word: 'plagued', word_class: 'AJ' }
  ],
  regularisation: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  saw: [
    { word: 'saw', word_class: 'N' },
    { word: 'saw', word_class: 'V' },
    { word: 'sawn', word_class: 'AJ' },
    { word: 'sawyer', word_class: 'N' }
  ],
  chivalric: [
    { word: 'chivalry', word_class: 'N' },
    { word: 'chivalric', word_class: 'AJ' },
    { word: 'chivalrous', word_class: 'AJ' },
    { word: 'chivalrously', word_class: 'AV' }
  ],
  immoderation: [
    { word: 'immoderate', word_class: 'AJ' },
    { word: 'immoderation', word_class: 'N' },
    { word: 'immoderately', word_class: 'AV' },
    { word: 'immoderateness', word_class: 'N' }
  ],
  despondent: [
    { word: 'despond', word_class: 'V' },
    { word: 'despondence', word_class: 'N' },
    { word: 'despondency', word_class: 'N' },
    { word: 'despondent', word_class: 'AJ' },
    { word: 'despondently', word_class: 'AV' }
  ],
  pour: [
    { word: 'pour', word_class: 'V' },
    { word: 'pouring', word_class: 'N' },
    { word: 'pouring', word_class: 'AJ' }
  ],
  groom: [
    { word: 'groom', word_class: 'N' },
    { word: 'groom', word_class: 'V' },
    { word: 'groomed', word_class: 'AJ' },
    { word: 'grooming', word_class: 'N' }
  ],
  wont: [
    { word: 'wont', word_class: 'N' },
    { word: 'wont', word_class: 'V' },
    { word: 'wont', word_class: 'AJ' },
    { word: 'wonted', word_class: 'AJ' }
  ],
  arsenic: [
    { word: 'arsenal', word_class: 'N' },
    { word: 'arsenic', word_class: 'N' },
    { word: 'arsenate', word_class: 'N' },
    { word: 'arsenical', word_class: 'AJ' }
  ],
  addictive: [
    { word: 'addict', word_class: 'N' },
    { word: 'addict', word_class: 'V' },
    { word: 'addicted', word_class: 'AJ' },
    { word: 'addiction', word_class: 'N' },
    { word: 'addictive', word_class: 'AJ' }
  ],
  feminize: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  bullfighting: [
    { word: 'bullfight', word_class: 'N' },
    { word: 'bullfighter', word_class: 'N' },
    { word: 'bullfighting', word_class: 'N' }
  ],
  digitizer: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  tiresomeness: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  drawling: [
    { word: 'drawl', word_class: 'N' },
    { word: 'drawl', word_class: 'V' },
    { word: 'drawler', word_class: 'N' },
    { word: 'drawling', word_class: 'AJ' }
  ],
  wool: [
    { word: 'wool', word_class: 'N' },
    { word: 'woolen', word_class: 'N' },
    { word: 'woolen', word_class: 'AJ' },
    { word: 'woollen', word_class: 'N' },
    { word: 'woollen', word_class: 'AJ' }
  ],
  fork: [
    { word: 'fork', word_class: 'N' },
    { word: 'fork', word_class: 'V' },
    { word: 'forked', word_class: 'AJ' },
    { word: 'forkful', word_class: 'N' },
    { word: 'forking', word_class: 'N' },
    { word: 'forkful', word_class: 'AJ' }
  ],
  transplanting: [
    { word: 'transplant', word_class: 'N' },
    { word: 'transplant', word_class: 'V' },
    { word: 'transplanting', word_class: 'N' },
    { word: 'transplantable', word_class: 'AJ' },
    { word: 'transplantation', word_class: 'N' }
  ],
  abnormality: [
    { word: 'abnormal', word_class: 'AJ' },
    { word: 'abnormality', word_class: 'N' },
    { word: 'abnormally', word_class: 'AV' }
  ],
  soaking: [
    { word: 'soak', word_class: 'N' },
    { word: 'soak', word_class: 'V' },
    { word: 'soaker', word_class: 'N' },
    { word: 'soaked', word_class: 'AJ' },
    { word: 'soaking', word_class: 'N' },
    { word: 'soaking', word_class: 'AJ' },
    { word: 'soaking', word_class: 'AV' }
  ],
  turbidness: [
    { word: 'turbid', word_class: 'AJ' },
    { word: 'turbidity', word_class: 'N' },
    { word: 'turbidness', word_class: 'N' }
  ],
  unappreciatively: [
    { word: 'unappreciated', word_class: 'AJ' },
    { word: 'unappreciative', word_class: 'AJ' },
    { word: 'unappreciatively', word_class: 'AV' }
  ],
  angular: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  beatific: [
    { word: 'beatify', word_class: 'V' },
    { word: 'beatific', word_class: 'AJ' },
    { word: 'beatified', word_class: 'AJ' },
    { word: 'beatification', word_class: 'N' }
  ],
  inanely: [
    { word: 'inane', word_class: 'AJ' },
    { word: 'inanity', word_class: 'N' },
    { word: 'inanely', word_class: 'AV' }
  ],
  wizardry: [
    { word: 'wizard', word_class: 'N' },
    { word: 'wizard', word_class: 'AJ' },
    { word: 'wizardry', word_class: 'N' }
  ],
  constable: [
    { word: 'constable', word_class: 'N' },
    { word: 'constabulary', word_class: 'N' },
    { word: 'constabulary', word_class: 'AJ' }
  ],
  impregnated: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  trilobated: [
    { word: 'trilobed', word_class: 'AJ' },
    { word: 'trilobate', word_class: 'AJ' },
    { word: 'trilobated', word_class: 'AJ' }
  ],
  thunder: [
    { word: 'thunder', word_class: 'N' },
    { word: 'thunder', word_class: 'V' },
    { word: 'thunderer', word_class: 'N' },
    { word: 'thundering', word_class: 'AJ' },
    { word: 'thunderous', word_class: 'AJ' }
  ],
  illiberally: [
    { word: 'illiberal', word_class: 'AJ' },
    { word: 'illiberality', word_class: 'N' },
    { word: 'illiberally', word_class: 'AV' }
  ],
  blastoporic: [
    { word: 'blastopore', word_class: 'N' },
    { word: 'blastoporal', word_class: 'AJ' },
    { word: 'blastoporic', word_class: 'AJ' }
  ],
  carpospore: [
    { word: 'carpospore', word_class: 'N' },
    { word: 'carposporic', word_class: 'AJ' },
    { word: 'carposporous', word_class: 'AJ' }
  ],
  extractable: [
    { word: 'extract', word_class: 'N' },
    { word: 'extract', word_class: 'V' },
    { word: 'extractor', word_class: 'N' },
    { word: 'extraction', word_class: 'N' },
    { word: 'extractable', word_class: 'AJ' },
    { word: 'extractible', word_class: 'AJ' }
  ],
  horrification: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  thinker: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  piled: [
    { word: 'pile', word_class: 'N' },
    { word: 'pile', word_class: 'V' },
    { word: 'piles', word_class: 'N' },
    { word: 'piled', word_class: 'AJ' },
    { word: 'piling', word_class: 'N' }
  ],
  geld: [{ word: 'geld', word_class: 'V' }, { word: 'gelded', word_class: 'AJ' }, { word: 'gelding', word_class: 'N' }],
  fundamentalism: [
    { word: 'fundament', word_class: 'N' },
    { word: 'fundamental', word_class: 'N' },
    { word: 'fundamental', word_class: 'AJ' },
    { word: 'fundamentals', word_class: 'N' },
    { word: 'fundamentalism', word_class: 'N' },
    { word: 'fundamentally', word_class: 'AV' }
  ],
  technicality: [
    { word: 'technical', word_class: 'AJ' },
    { word: 'technicality', word_class: 'N' },
    { word: 'technically', word_class: 'AV' }
  ],
  anterior: [
    { word: 'anterior', word_class: 'N' },
    { word: 'anterior', word_class: 'AJ' },
    { word: 'anteriority', word_class: 'N' }
  ],
  sympathy: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  biographer: [
    { word: 'biographer', word_class: 'N' },
    { word: 'biographic', word_class: 'AJ' },
    { word: 'biographical', word_class: 'AJ' }
  ],
  dreaded: [
    { word: 'dread', word_class: 'N' },
    { word: 'dread', word_class: 'V' },
    { word: 'dread', word_class: 'AJ' },
    { word: 'dreaded', word_class: 'AJ' },
    { word: 'dreadful', word_class: 'AJ' },
    { word: 'dreadfulness', word_class: 'N' }
  ],
  crookbacked: [
    { word: 'crookback', word_class: 'N' },
    { word: 'crookback', word_class: 'AJ' },
    { word: 'crookbacked', word_class: 'AJ' }
  ],
  metalworks: [
    { word: 'metalwork', word_class: 'N' },
    { word: 'metalworks', word_class: 'N' },
    { word: 'metalworker', word_class: 'N' },
    { word: 'metalworking', word_class: 'N' }
  ],
  unitary: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  edify: [
    { word: 'edify', word_class: 'V' },
    { word: 'edified', word_class: 'AJ' },
    { word: 'edifying', word_class: 'AJ' },
    { word: 'edification', word_class: 'N' }
  ],
  choosy: [
    { word: 'choice', word_class: 'N' },
    { word: 'choose', word_class: 'V' },
    { word: 'chosen', word_class: 'N' },
    { word: 'choosy', word_class: 'AJ' },
    { word: 'choice', word_class: 'AJ' },
    { word: 'chosen', word_class: 'AJ' },
    { word: 'choiceness', word_class: 'N' }
  ],
  trivial: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  calumniation: [
    { word: 'calumny', word_class: 'N' },
    { word: 'calumniate', word_class: 'V' },
    { word: 'calumnious', word_class: 'AJ' },
    { word: 'calumniation', word_class: 'N' }
  ],
  fadeless: [
    { word: 'fade', word_class: 'N' },
    { word: 'fade', word_class: 'V' },
    { word: 'faded', word_class: 'AJ' },
    { word: 'fading', word_class: 'N' },
    { word: 'fading', word_class: 'AJ' },
    { word: 'fadeless', word_class: 'AJ' }
  ],
  tartness: [
    { word: 'tart', word_class: 'N' },
    { word: 'tart', word_class: 'AJ' },
    { word: 'tartness', word_class: 'N' }
  ],
  mortify: [
    { word: 'mortify', word_class: 'V' },
    { word: 'mortified', word_class: 'AJ' },
    { word: 'mortifying', word_class: 'AJ' },
    { word: 'mortification', word_class: 'N' }
  ],
  worried: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  cabalistic: [
    { word: 'cabal', word_class: 'N' },
    { word: 'cabal', word_class: 'V' },
    { word: 'cabalism', word_class: 'N' },
    { word: 'cabalist', word_class: 'N' },
    { word: 'cabalistic', word_class: 'AJ' }
  ],
  irritated: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  invigilate: [
    { word: 'invigilate', word_class: 'V' },
    { word: 'invigilator', word_class: 'N' },
    { word: 'invigilation', word_class: 'N' }
  ],
  crashing: [
    { word: 'crash', word_class: 'N' },
    { word: 'crash', word_class: 'V' },
    { word: 'crash', word_class: 'AJ' },
    { word: 'crasher', word_class: 'N' },
    { word: 'crashing', word_class: 'AJ' }
  ],
  bash: [
    { word: 'bash', word_class: 'N' },
    { word: 'bash', word_class: 'V' },
    { word: 'bashful', word_class: 'AJ' },
    { word: 'bashfulness', word_class: 'N' }
  ],
  inconclusive: [
    { word: 'inconclusive', word_class: 'AJ' },
    { word: 'inconclusively', word_class: 'AV' },
    { word: 'inconclusiveness', word_class: 'N' }
  ],
  adenoidal: [
    { word: 'adenoid', word_class: 'N' },
    { word: 'adenoid', word_class: 'AJ' },
    { word: 'adenoidal', word_class: 'AJ' }
  ],
  possess: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  accommodational: [
    { word: 'accommodate', word_class: 'V' },
    { word: 'accommodation', word_class: 'N' },
    { word: 'accommodating', word_class: 'AJ' },
    { word: 'accommodative', word_class: 'AJ' },
    { word: 'accommodational', word_class: 'AJ' }
  ],
  journal: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  dynasty: [
    { word: 'dynast', word_class: 'N' },
    { word: 'dynasty', word_class: 'N' },
    { word: 'dynasty', word_class: 'AJ' },
    { word: 'dynastic', word_class: 'AJ' }
  ],
  placeman: [
    { word: 'place', word_class: 'N' },
    { word: 'place', word_class: 'V' },
    { word: 'placer', word_class: 'N' },
    { word: 'placed', word_class: 'AJ' },
    { word: 'placeman', word_class: 'N' },
    { word: 'placement', word_class: 'N' }
  ],
  absolution: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  implant: [
    { word: 'implant', word_class: 'N' },
    { word: 'implant', word_class: 'V' },
    { word: 'implanted', word_class: 'AJ' },
    { word: 'implantation', word_class: 'N' }
  ],
  demilitarize: [
    { word: 'demilitarise', word_class: 'V' },
    { word: 'demilitarize', word_class: 'V' },
    { word: 'demilitarisation', word_class: 'N' },
    { word: 'demilitarization', word_class: 'N' }
  ],
  truculent: [
    { word: 'truculence', word_class: 'N' },
    { word: 'truculency', word_class: 'N' },
    { word: 'truculent', word_class: 'AJ' },
    { word: 'truculently', word_class: 'AV' }
  ],
  perceptivity: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  oftener: [
    { word: 'often', word_class: 'AV' },
    { word: 'oftener', word_class: 'AV' },
    { word: 'oftenness', word_class: 'N' }
  ],
  crease: [
    { word: 'crease', word_class: 'N' },
    { word: 'crease', word_class: 'V' },
    { word: 'creased', word_class: 'AJ' }
  ],
  painfulness: [
    { word: 'pain', word_class: 'N' },
    { word: 'pain', word_class: 'V' },
    { word: 'pains', word_class: 'N' },
    { word: 'pained', word_class: 'AJ' },
    { word: 'painful', word_class: 'AJ' },
    { word: 'painfulness', word_class: 'N' }
  ],
  balked: [
    { word: 'balk', word_class: 'N' },
    { word: 'balk', word_class: 'V' },
    { word: 'balked', word_class: 'AJ' },
    { word: 'balking', word_class: 'AJ' }
  ],
  treasured: [
    { word: 'treasure', word_class: 'N' },
    { word: 'treasure', word_class: 'V' },
    { word: 'treasurer', word_class: 'N' },
    { word: 'treasured', word_class: 'AJ' }
  ],
  slowness: [
    { word: 'slow', word_class: 'V' },
    { word: 'slow', word_class: 'AJ' },
    { word: 'slow', word_class: 'AV' },
    { word: 'slowing', word_class: 'N' },
    { word: 'slowness', word_class: 'N' }
  ],
  dominoes: [
    { word: 'domino', word_class: 'N' },
    { word: 'dominos', word_class: 'N' },
    { word: 'dominoes', word_class: 'N' }
  ],
  homosexuality: [
    { word: 'homosexual', word_class: 'N' },
    { word: 'homosexual', word_class: 'AJ' },
    { word: 'homosexualism', word_class: 'N' },
    { word: 'homosexuality', word_class: 'N' }
  ],
  saying: [{ word: 'say', word_class: 'N' }, { word: 'say', word_class: 'V' }, { word: 'saying', word_class: 'N' }],
  motorize: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  delimited: [
    { word: 'delimit', word_class: 'N' },
    { word: 'delimit', word_class: 'V' },
    { word: 'delimitate', word_class: 'V' },
    { word: 'delimited', word_class: 'AJ' },
    { word: 'delimitation', word_class: 'N' }
  ],
  hypochondria: [
    { word: 'hypochondria', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'AJ' },
    { word: 'hypochondriacal', word_class: 'AJ' }
  ],
  spattered: [
    { word: 'spatter', word_class: 'N' },
    { word: 'spatter', word_class: 'V' },
    { word: 'spattered', word_class: 'AJ' },
    { word: 'spattering', word_class: 'N' }
  ],
  totalisation: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  offensiveness: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  culpableness: [
    { word: 'culpable', word_class: 'AJ' },
    { word: 'culpably', word_class: 'AV' },
    { word: 'culpability', word_class: 'N' },
    { word: 'culpableness', word_class: 'N' }
  ],
  crosse: [
    { word: 'cross', word_class: 'N' },
    { word: 'cross', word_class: 'V' },
    { word: 'cross', word_class: 'AJ' },
    { word: 'crosse', word_class: 'N' },
    { word: 'crossed', word_class: 'AJ' },
    { word: 'crossing', word_class: 'N' },
    { word: 'crossing', word_class: 'AJ' },
    { word: 'crossness', word_class: 'N' }
  ],
  pridefulness: [
    { word: 'pride', word_class: 'N' },
    { word: 'pride', word_class: 'V' },
    { word: 'prideful', word_class: 'AJ' },
    { word: 'pridefulness', word_class: 'N' }
  ],
  taken: [
    { word: 'take', word_class: 'N' },
    { word: 'take', word_class: 'V' },
    { word: 'taker', word_class: 'N' },
    { word: 'taken', word_class: 'AJ' },
    { word: 'taking', word_class: 'N' },
    { word: 'taking', word_class: 'AJ' },
    { word: 'takings', word_class: 'N' }
  ],
  dappled: [
    { word: 'dapple', word_class: 'N' },
    { word: 'dapple', word_class: 'V' },
    { word: 'dappled', word_class: 'AJ' }
  ],
  subsidise: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  abash: [
    { word: 'abash', word_class: 'V' },
    { word: 'abashed', word_class: 'AJ' },
    { word: 'abashment', word_class: 'N' }
  ],
  engage: [
    { word: 'engage', word_class: 'V' },
    { word: 'engaged', word_class: 'AJ' },
    { word: 'engaging', word_class: 'AJ' },
    { word: 'engagement', word_class: 'N' }
  ],
  wander: [
    { word: 'wander', word_class: 'N' },
    { word: 'wander', word_class: 'V' },
    { word: 'wanderer', word_class: 'N' },
    { word: 'wandering', word_class: 'N' },
    { word: 'wandering', word_class: 'AJ' }
  ],
  consitution: [
    { word: 'constitute', word_class: 'V' },
    { word: 'consitution', word_class: 'N' },
    { word: 'constitution', word_class: 'N' },
    { word: 'constitutive', word_class: 'AJ' },
    { word: 'constitutional', word_class: 'N' },
    { word: 'constitutional', word_class: 'AJ' }
  ],
  tinseled: [
    { word: 'tinsel', word_class: 'N' },
    { word: 'tinsel', word_class: 'V' },
    { word: 'tinsel', word_class: 'AJ' },
    { word: 'tinseled', word_class: 'AJ' }
  ],
  investing: [
    { word: 'invest', word_class: 'N' },
    { word: 'invest', word_class: 'V' },
    { word: 'investor', word_class: 'N' },
    { word: 'invested', word_class: 'AJ' },
    { word: 'investing', word_class: 'N' },
    { word: 'investment', word_class: 'N' }
  ],
  hissing: [
    { word: 'hiss', word_class: 'N' },
    { word: 'hiss', word_class: 'V' },
    { word: 'hissing', word_class: 'N' },
    { word: 'hissing', word_class: 'AJ' }
  ],
  bacchant: [
    { word: 'bacchant', word_class: 'N' },
    { word: 'bacchante', word_class: 'N' },
    { word: 'bacchantic', word_class: 'AJ' }
  ],
  scrape: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  associable: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  apprehensive: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  disagreeably: [
    { word: 'disagree', word_class: 'V' },
    { word: 'disagreement', word_class: 'N' },
    { word: 'disagreeable', word_class: 'AJ' },
    { word: 'disagreeably', word_class: 'AV' },
    { word: 'disagreeableness', word_class: 'N' }
  ],
  preferability: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  intern: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  brachiate: [
    { word: 'brachiate', word_class: 'V' },
    { word: 'brachiate', word_class: 'AJ' },
    { word: 'brachiation', word_class: 'N' }
  ],
  unprofitable: [
    { word: 'unprofitable', word_class: 'AJ' },
    { word: 'unprofitably', word_class: 'AV' },
    { word: 'unprofitability', word_class: 'N' },
    { word: 'unprofitableness', word_class: 'N' }
  ],
  restricted: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  callus: [
    { word: 'callus', word_class: 'N' },
    { word: 'callus', word_class: 'V' },
    { word: 'callused', word_class: 'AJ' }
  ],
  reciprocally: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  sulphurization: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  undulant: [
    { word: 'undulate', word_class: 'V' },
    { word: 'undulant', word_class: 'AJ' },
    { word: 'undulate', word_class: 'AJ' },
    { word: 'undulation', word_class: 'N' }
  ],
  disuse: [
    { word: 'disuse', word_class: 'N' },
    { word: 'disuse', word_class: 'V' },
    { word: 'disused', word_class: 'AJ' }
  ],
  ascendable: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  put: [
    { word: 'put', word_class: 'N' },
    { word: 'put', word_class: 'V' },
    { word: 'putting', word_class: 'N' },
    { word: 'putative', word_class: 'AJ' }
  ],
  shaven: [
    { word: 'shave', word_class: 'N' },
    { word: 'shave', word_class: 'V' },
    { word: 'shaver', word_class: 'N' },
    { word: 'shaved', word_class: 'AJ' },
    { word: 'shaven', word_class: 'AJ' },
    { word: 'shaving', word_class: 'N' }
  ],
  hemispherical: [
    { word: 'hemisphere', word_class: 'N' },
    { word: 'hemispheric', word_class: 'AJ' },
    { word: 'hemispherical', word_class: 'AJ' }
  ],
  fawn: [{ word: 'fawn', word_class: 'N' }, { word: 'fawn', word_class: 'V' }, { word: 'fawning', word_class: 'AJ' }],
  wheeler: [
    { word: 'wheel', word_class: 'N' },
    { word: 'wheel', word_class: 'V' },
    { word: 'wheels', word_class: 'N' },
    { word: 'wheeler', word_class: 'N' },
    { word: 'wheeled', word_class: 'AJ' },
    { word: 'wheeling', word_class: 'N' },
    { word: 'wheeling', word_class: 'AJ' }
  ],
  toiler: [
    { word: 'toil', word_class: 'N' },
    { word: 'toil', word_class: 'V' },
    { word: 'toiler', word_class: 'N' },
    { word: 'toiling', word_class: 'AJ' },
    { word: 'toilsome', word_class: 'AJ' },
    { word: 'toilsomeness', word_class: 'N' }
  ],
  monument: [
    { word: 'monument', word_class: 'N' },
    { word: 'monumental', word_class: 'AJ' },
    { word: 'monumentalize', word_class: 'V' }
  ],
  enfranchise: [
    { word: 'enfranchise', word_class: 'V' },
    { word: 'enfranchised', word_class: 'AJ' },
    { word: 'enfranchisement', word_class: 'N' }
  ],
  humanitarian: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  unimaginably: [
    { word: 'unimagined', word_class: 'AJ' },
    { word: 'unimaginable', word_class: 'AJ' },
    { word: 'unimaginably', word_class: 'AV' },
    { word: 'unimaginative', word_class: 'AJ' },
    { word: 'unimaginatively', word_class: 'AV' }
  ],
  bidden: [
    { word: 'bid', word_class: 'N' },
    { word: 'bid', word_class: 'V' },
    { word: 'bidden', word_class: 'AJ' },
    { word: 'bidding', word_class: 'N' }
  ],
  abstruse: [
    { word: 'abstruse', word_class: 'AJ' },
    { word: 'abstrusity', word_class: 'N' },
    { word: 'abstrusely', word_class: 'AV' },
    { word: 'abstruseness', word_class: 'N' }
  ],
  strategy: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  dominate: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  subnormal: [
    { word: 'subnormal', word_class: 'N' },
    { word: 'subnormal', word_class: 'AJ' },
    { word: 'subnormality', word_class: 'N' }
  ],
  sneaking: [
    { word: 'sneak', word_class: 'N' },
    { word: 'sneak', word_class: 'V' },
    { word: 'sneak', word_class: 'AJ' },
    { word: 'sneaker', word_class: 'N' },
    { word: 'sneaky', word_class: 'AJ' },
    { word: 'sneaking', word_class: 'AJ' },
    { word: 'sneakiness', word_class: 'N' }
  ],
  postural: [
    { word: 'posture', word_class: 'N' },
    { word: 'posture', word_class: 'V' },
    { word: 'postural', word_class: 'AJ' },
    { word: 'posturing', word_class: 'N' }
  ],
  environs: [
    { word: 'environ', word_class: 'N' },
    { word: 'environ', word_class: 'V' },
    { word: 'environs', word_class: 'N' },
    { word: 'environment', word_class: 'N' }
  ],
  hoot: [{ word: 'hoot', word_class: 'N' }, { word: 'hoot', word_class: 'V' }, { word: 'hooter', word_class: 'N' }],
  pursuance: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  muckraking: [
    { word: 'muckrake', word_class: 'V' },
    { word: 'muckraker', word_class: 'N' },
    { word: 'muckraking', word_class: 'N' }
  ],
  sober: [
    { word: 'sober', word_class: 'V' },
    { word: 'sober', word_class: 'AJ' },
    { word: 'sobriety', word_class: 'N' },
    { word: 'sobering', word_class: 'AJ' },
    { word: 'soberness', word_class: 'N' }
  ],
  project: [
    { word: 'project', word_class: 'N' },
    { word: 'project', word_class: 'V' },
    { word: 'projector', word_class: 'N' },
    { word: 'projected', word_class: 'AJ' },
    { word: 'projection', word_class: 'N' },
    { word: 'projecting', word_class: 'AJ' }
  ],
  encased: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  grave: [
    { word: 'grave', word_class: 'N' },
    { word: 'grave', word_class: 'V' },
    { word: 'grave', word_class: 'AJ' },
    { word: 'gravely', word_class: 'AV' },
    { word: 'graveness', word_class: 'N' }
  ],
  requital: [
    { word: 'requite', word_class: 'V' },
    { word: 'requital', word_class: 'N' },
    { word: 'requited', word_class: 'AJ' },
    { word: 'requitement', word_class: 'N' }
  ],
  binding: [
    { word: 'bind', word_class: 'N' },
    { word: 'bind', word_class: 'V' },
    { word: 'binder', word_class: 'N' },
    { word: 'bindery', word_class: 'N' },
    { word: 'binding', word_class: 'N' },
    { word: 'bindery', word_class: 'AJ' },
    { word: 'binding', word_class: 'AJ' }
  ],
  cash: [{ word: 'cash', word_class: 'N' }, { word: 'cash', word_class: 'V' }, { word: 'cashed', word_class: 'AJ' }],
  gaffer: [
    { word: 'gaff', word_class: 'N' },
    { word: 'gaff', word_class: 'V' },
    { word: 'gaffe', word_class: 'N' },
    { word: 'gaffer', word_class: 'N' }
  ],
  indispensability: [
    { word: 'indispensable', word_class: 'AJ' },
    { word: 'indispensability', word_class: 'N' },
    { word: 'indispensableness', word_class: 'N' }
  ],
  covenant: [
    { word: 'coven', word_class: 'N' },
    { word: 'covenant', word_class: 'N' },
    { word: 'covenant', word_class: 'V' }
  ],
  winter: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  sniffling: [
    { word: 'sniffle', word_class: 'N' },
    { word: 'sniffle', word_class: 'V' },
    { word: 'sniffling', word_class: 'AJ' }
  ],
  scandalise: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  housebroken: [
    { word: 'housebreak', word_class: 'V' },
    { word: 'housebreaker', word_class: 'N' },
    { word: 'housebroken', word_class: 'AJ' },
    { word: 'housebreaking', word_class: 'N' }
  ],
  illogically: [
    { word: 'illogic', word_class: 'N' },
    { word: 'illogical', word_class: 'AJ' },
    { word: 'illogicality', word_class: 'N' },
    { word: 'illogically', word_class: 'AV' }
  ],
  fulguration: [
    { word: 'fulgurate', word_class: 'V' },
    { word: 'fulgurant', word_class: 'AJ' },
    { word: 'fulgurous', word_class: 'AJ' },
    { word: 'fulguration', word_class: 'N' },
    { word: 'fulgurating', word_class: 'AJ' }
  ],
  hybridize: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  continency: [
    { word: 'continent', word_class: 'N' },
    { word: 'continence', word_class: 'N' },
    { word: 'continency', word_class: 'N' },
    { word: 'continent', word_class: 'AJ' }
  ],
  grope: [
    { word: 'grope', word_class: 'N' },
    { word: 'grope', word_class: 'V' },
    { word: 'groping', word_class: 'AJ' }
  ],
  scuffs: [{ word: 'scuff', word_class: 'N' }, { word: 'scuff', word_class: 'V' }, { word: 'scuffs', word_class: 'N' }],
  commutation: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  analytic: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  eyes: [
    { word: 'eye', word_class: 'N' },
    { word: 'eye', word_class: 'V' },
    { word: 'eyes', word_class: 'N' },
    { word: 'eyed', word_class: 'AJ' },
    { word: 'eyeful', word_class: 'N' },
    { word: 'eyeful', word_class: 'AJ' }
  ],
  astringence: [
    { word: 'astringent', word_class: 'N' },
    { word: 'astringence', word_class: 'N' },
    { word: 'astringency', word_class: 'N' },
    { word: 'astringent', word_class: 'AJ' }
  ],
  dejected: [
    { word: 'deject', word_class: 'V' },
    { word: 'dejected', word_class: 'AJ' },
    { word: 'dejection', word_class: 'N' }
  ],
  sorrow: [
    { word: 'sorrow', word_class: 'N' },
    { word: 'sorrow', word_class: 'V' },
    { word: 'sorrowful', word_class: 'AJ' },
    { word: 'sorrowing', word_class: 'AJ' },
    { word: 'sorrowfulness', word_class: 'N' }
  ],
  imparting: [
    { word: 'impart', word_class: 'N' },
    { word: 'impart', word_class: 'V' },
    { word: 'imparting', word_class: 'N' },
    { word: 'impartment', word_class: 'N' },
    { word: 'impartation', word_class: 'N' }
  ],
  orderliness: [
    { word: 'orderly', word_class: 'N' },
    { word: 'orderly', word_class: 'AJ' },
    { word: 'orderliness', word_class: 'N' }
  ],
  legitimization: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  applicable: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  ware: [{ word: 'ware', word_class: 'N' }, { word: 'ware', word_class: 'V' }, { word: 'wares', word_class: 'N' }],
  darken: [
    { word: 'darken', word_class: 'V' },
    { word: 'darkened', word_class: 'AJ' },
    { word: 'darkening', word_class: 'N' },
    { word: 'darkening', word_class: 'AJ' }
  ],
  viscidity: [
    { word: 'viscid', word_class: 'AJ' },
    { word: 'viscidity', word_class: 'N' },
    { word: 'viscidness', word_class: 'N' }
  ],
  disproportionment: [
    { word: 'disproportion', word_class: 'N' },
    { word: 'disproportion', word_class: 'V' },
    { word: 'disproportional', word_class: 'AJ' },
    { word: 'disproportionate', word_class: 'AJ' },
    { word: 'disproportionment', word_class: 'N' },
    { word: 'disproportionately', word_class: 'AV' }
  ],
  pathogenic: [
    { word: 'pathogen', word_class: 'N' },
    { word: 'pathogenic', word_class: 'AJ' },
    { word: 'pathogenically', word_class: 'AV' }
  ],
  oaken: [{ word: 'oak', word_class: 'N' }, { word: 'oak', word_class: 'AJ' }, { word: 'oaken', word_class: 'AJ' }],
  wrecking: [
    { word: 'wreck', word_class: 'N' },
    { word: 'wreck', word_class: 'V' },
    { word: 'wrecker', word_class: 'N' },
    { word: 'wrecked', word_class: 'AJ' },
    { word: 'wrecking', word_class: 'N' }
  ],
  dichotomization: [
    { word: 'dichotomize', word_class: 'V' },
    { word: 'dichotomous', word_class: 'AJ' },
    { word: 'dichotomously', word_class: 'AV' },
    { word: 'dichotomization', word_class: 'N' }
  ],
  cooper: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  imploration: [
    { word: 'implore', word_class: 'V' },
    { word: 'imploring', word_class: 'AJ' },
    { word: 'imploration', word_class: 'N' }
  ],
  disdainfulness: [
    { word: 'disdain', word_class: 'N' },
    { word: 'disdain', word_class: 'V' },
    { word: 'disdainful', word_class: 'AJ' },
    { word: 'disdainment', word_class: 'N' },
    { word: 'disdainfulness', word_class: 'N' }
  ],
  cocknification: [
    { word: 'cocknify', word_class: 'V' },
    { word: 'cockneyfy', word_class: 'V' },
    { word: 'cocknification', word_class: 'N' },
    { word: 'cockneyfication', word_class: 'N' }
  ],
  calipers: [
    { word: 'caliper', word_class: 'N' },
    { word: 'caliper', word_class: 'V' },
    { word: 'calipers', word_class: 'N' }
  ],
  reorganization: [
    { word: 'reorganize', word_class: 'V' },
    { word: 'reorganized', word_class: 'AJ' },
    { word: 'reorganization', word_class: 'N' }
  ],
  interlining: [
    { word: 'interline', word_class: 'V' },
    { word: 'interlining', word_class: 'N' },
    { word: 'interlineal', word_class: 'AJ' },
    { word: 'interlineate', word_class: 'V' },
    { word: 'interlineation', word_class: 'N' }
  ],
  optimization: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  quarrel: [
    { word: 'quarrel', word_class: 'N' },
    { word: 'quarrel', word_class: 'V' },
    { word: 'quarrelsome', word_class: 'AJ' },
    { word: 'quarrelsomeness', word_class: 'N' }
  ],
  caribou: [
    { word: 'carib', word_class: 'N' },
    { word: 'caribe', word_class: 'N' },
    { word: 'caribou', word_class: 'N' }
  ],
  gusher: [
    { word: 'gush', word_class: 'N' },
    { word: 'gush', word_class: 'V' },
    { word: 'gusher', word_class: 'N' },
    { word: 'gushing', word_class: 'AJ' }
  ],
  railroad: [
    { word: 'railroad', word_class: 'N' },
    { word: 'railroad', word_class: 'V' },
    { word: 'railroader', word_class: 'N' }
  ],
  medically: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  certifiable: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  paperbacked: [
    { word: 'paperback', word_class: 'N' },
    { word: 'paperback', word_class: 'AJ' },
    { word: 'paperbacked', word_class: 'AJ' }
  ],
  condensate: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  tendrilled: [
    { word: 'tendril', word_class: 'N' },
    { word: 'tendriled', word_class: 'AJ' },
    { word: 'tendrilled', word_class: 'AJ' }
  ],
  decipherment: [
    { word: 'decipher', word_class: 'V' },
    { word: 'deciphered', word_class: 'AJ' },
    { word: 'decipherment', word_class: 'N' },
    { word: 'decipherable', word_class: 'AJ' },
    { word: 'decipherably', word_class: 'AV' }
  ],
  accountability: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  dirtying: [
    { word: 'dirty', word_class: 'V' },
    { word: 'dirty', word_class: 'AJ' },
    { word: 'dirtying', word_class: 'N' },
    { word: 'dirtiness', word_class: 'N' }
  ],
  derivational: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  tallyman: [
    { word: 'tally', word_class: 'N' },
    { word: 'tally', word_class: 'V' },
    { word: 'tallyman', word_class: 'N' }
  ],
  inaudibly: [
    { word: 'inaudible', word_class: 'AJ' },
    { word: 'inaudibly', word_class: 'AV' },
    { word: 'inaudibility', word_class: 'N' },
    { word: 'inaudibleness', word_class: 'N' }
  ],
  taps: [
    { word: 'tap', word_class: 'N' },
    { word: 'tap', word_class: 'V' },
    { word: 'taps', word_class: 'N' },
    { word: 'tapped', word_class: 'AJ' },
    { word: 'tapping', word_class: 'N' }
  ],
  acceptor: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  aluminous: [
    { word: 'aluminize', word_class: 'V' },
    { word: 'aluminous', word_class: 'AJ' },
    { word: 'aluminization', word_class: 'N' }
  ],
  accomplish: [
    { word: 'accomplish', word_class: 'V' },
    { word: 'accomplished', word_class: 'AJ' },
    { word: 'accomplishment', word_class: 'N' },
    { word: 'accomplishable', word_class: 'AJ' }
  ],
  confounding: [
    { word: 'confound', word_class: 'V' },
    { word: 'confounded', word_class: 'AJ' },
    { word: 'confounding', word_class: 'N' },
    { word: 'confounding', word_class: 'AJ' }
  ],
  amused: [
    { word: 'amuse', word_class: 'V' },
    { word: 'amused', word_class: 'AJ' },
    { word: 'amusing', word_class: 'AJ' },
    { word: 'amusive', word_class: 'AJ' },
    { word: 'amusement', word_class: 'N' }
  ],
  untied: [
    { word: 'untie', word_class: 'V' },
    { word: 'untied', word_class: 'AJ' },
    { word: 'untying', word_class: 'N' }
  ],
  immediately: [
    { word: 'immediacy', word_class: 'N' },
    { word: 'immediate', word_class: 'V' },
    { word: 'immediate', word_class: 'AJ' },
    { word: 'immediately', word_class: 'AV' },
    { word: 'immediateness', word_class: 'N' }
  ],
  documented: [
    { word: 'document', word_class: 'N' },
    { word: 'document', word_class: 'V' },
    { word: 'documental', word_class: 'AJ' },
    { word: 'documented', word_class: 'AJ' },
    { word: 'documentation', word_class: 'N' }
  ],
  nibbling: [
    { word: 'nibble', word_class: 'N' },
    { word: 'nibble', word_class: 'V' },
    { word: 'nibbling', word_class: 'N' }
  ],
  fitfulness: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  alienate: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  reassuring: [
    { word: 'reassure', word_class: 'V' },
    { word: 'reassured', word_class: 'AJ' },
    { word: 'reassurance', word_class: 'N' },
    { word: 'reassuring', word_class: 'AJ' }
  ],
  bleached: [
    { word: 'bleach', word_class: 'N' },
    { word: 'bleach', word_class: 'V' },
    { word: 'bleached', word_class: 'AJ' }
  ],
  inauguration: [
    { word: 'inaugural', word_class: 'N' },
    { word: 'inaugural', word_class: 'AJ' },
    { word: 'inaugurate', word_class: 'V' },
    { word: 'inaugurally', word_class: 'AV' },
    { word: 'inauguration', word_class: 'N' }
  ],
  sample: [
    { word: 'sample', word_class: 'N' },
    { word: 'sample', word_class: 'V' },
    { word: 'sampler', word_class: 'N' },
    { word: 'sampling', word_class: 'N' }
  ],
  plea: [
    { word: 'plea', word_class: 'N' },
    { word: 'plead', word_class: 'V' },
    { word: 'pleading', word_class: 'N' },
    { word: 'pleading', word_class: 'AJ' }
  ],
  singsong: [
    { word: 'singsong', word_class: 'N' },
    { word: 'singsong', word_class: 'V' },
    { word: 'singsong', word_class: 'AJ' }
  ],
  spirit: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  concomitance: [
    { word: 'concomitant', word_class: 'N' },
    { word: 'concomitance', word_class: 'N' },
    { word: 'concomitant', word_class: 'AJ' }
  ],
  expurgated: [
    { word: 'expurgate', word_class: 'V' },
    { word: 'expurgated', word_class: 'AJ' },
    { word: 'expurgation', word_class: 'N' }
  ],
  focal: [
    { word: 'focal', word_class: 'AJ' },
    { word: 'focalize', word_class: 'V' },
    { word: 'focally', word_class: 'AV' },
    { word: 'focalisation', word_class: 'N' },
    { word: 'focalization', word_class: 'N' }
  ],
  console: [
    { word: 'console', word_class: 'N' },
    { word: 'console', word_class: 'V' },
    { word: 'consoling', word_class: 'AJ' },
    { word: 'consolable', word_class: 'AJ' },
    { word: 'consolation', word_class: 'N' },
    { word: 'consolement', word_class: 'N' }
  ],
  baptist: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  pattern: [
    { word: 'pattern', word_class: 'N' },
    { word: 'pattern', word_class: 'V' },
    { word: 'patterned', word_class: 'AJ' }
  ],
  retroaction: [
    { word: 'retroact', word_class: 'V' },
    { word: 'retroaction', word_class: 'N' },
    { word: 'retroactive', word_class: 'AJ' },
    { word: 'retroactively', word_class: 'AV' }
  ],
  hieratical: [
    { word: 'hieratic', word_class: 'N' },
    { word: 'hieratic', word_class: 'AJ' },
    { word: 'hieratical', word_class: 'AJ' }
  ],
  regarding: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  agitate: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  cancellous: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  pectize: [
    { word: 'pectise', word_class: 'V' },
    { word: 'pectize', word_class: 'V' },
    { word: 'pectisation', word_class: 'N' },
    { word: 'pectization', word_class: 'N' }
  ],
  globularness: [
    { word: 'globe', word_class: 'N' },
    { word: 'globular', word_class: 'AJ' },
    { word: 'globularness', word_class: 'N' }
  ],
  depressive: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  'ill-use': [
    { word: 'ill-use', word_class: 'N' },
    { word: 'ill-use', word_class: 'V' },
    { word: 'ill-used', word_class: 'AJ' }
  ],
  confusable: [
    { word: 'confuse', word_class: 'V' },
    { word: 'confused', word_class: 'AJ' },
    { word: 'confusion', word_class: 'N' },
    { word: 'confusing', word_class: 'AJ' },
    { word: 'confusable', word_class: 'AJ' }
  ],
  fledged: [
    { word: 'fledge', word_class: 'V' },
    { word: 'fledged', word_class: 'AJ' },
    { word: 'fledgling', word_class: 'N' },
    { word: 'fledgling', word_class: 'AJ' }
  ],
  aired: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  poacher: [
    { word: 'poach', word_class: 'V' },
    { word: 'poacher', word_class: 'N' },
    { word: 'poached', word_class: 'AJ' },
    { word: 'poaching', word_class: 'N' }
  ],
  unsatiated: [
    { word: 'unsatiable', word_class: 'AJ' },
    { word: 'unsatiably', word_class: 'AV' },
    { word: 'unsatiated', word_class: 'AJ' }
  ],
  relegation: [
    { word: 'relegate', word_class: 'V' },
    { word: 'relegating', word_class: 'N' },
    { word: 'relegation', word_class: 'N' }
  ],
  textured: [
    { word: 'texture', word_class: 'N' },
    { word: 'texture', word_class: 'V' },
    { word: 'textured', word_class: 'AJ' }
  ],
  denigration: [
    { word: 'denigrate', word_class: 'V' },
    { word: 'denigration', word_class: 'N' },
    { word: 'denigrating', word_class: 'AJ' },
    { word: 'denigrative', word_class: 'AJ' }
  ],
  doubtful: [
    { word: 'doubt', word_class: 'N' },
    { word: 'doubt', word_class: 'V' },
    { word: 'doubter', word_class: 'N' },
    { word: 'doubtful', word_class: 'AJ' },
    { word: 'doubting', word_class: 'AJ' },
    { word: 'doubtfulness', word_class: 'N' }
  ],
  convoluted: [
    { word: 'convolve', word_class: 'V' },
    { word: 'convolute', word_class: 'V' },
    { word: 'convolute', word_class: 'AJ' },
    { word: 'convoluted', word_class: 'AJ' },
    { word: 'convolution', word_class: 'N' }
  ],
  plumage: [
    { word: 'plume', word_class: 'N' },
    { word: 'plume', word_class: 'V' },
    { word: 'plumage', word_class: 'N' },
    { word: 'plumed', word_class: 'AJ' },
    { word: 'plumaged', word_class: 'AJ' }
  ],
  expansible: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  disturb: [
    { word: 'disturb', word_class: 'V' },
    { word: 'disturbed', word_class: 'AJ' },
    { word: 'disturbance', word_class: 'N' },
    { word: 'disturbing', word_class: 'AJ' }
  ],
  actuated: [
    { word: 'actuate', word_class: 'V' },
    { word: 'actuator', word_class: 'N' },
    { word: 'actuated', word_class: 'AJ' },
    { word: 'actuation', word_class: 'N' },
    { word: 'actuating', word_class: 'AJ' }
  ],
  anagrammatical: [
    { word: 'anagrammatic', word_class: 'AJ' },
    { word: 'anagrammatize', word_class: 'V' },
    { word: 'anagrammatical', word_class: 'AJ' },
    { word: 'anagrammatization', word_class: 'N' }
  ],
  plumbism: [
    { word: 'plumb', word_class: 'N' },
    { word: 'plumb', word_class: 'V' },
    { word: 'plumb', word_class: 'AJ' },
    { word: 'plumb', word_class: 'AV' },
    { word: 'plumber', word_class: 'N' },
    { word: 'plumbed', word_class: 'AJ' },
    { word: 'plumbing', word_class: 'N' },
    { word: 'plumbism', word_class: 'N' }
  ],
  uncomfortable: [
    { word: 'uncomfortable', word_class: 'AJ' },
    { word: 'uncomfortably', word_class: 'AV' },
    { word: 'uncomfortableness', word_class: 'N' }
  ],
  humidity: [
    { word: 'humid', word_class: 'AJ' },
    { word: 'humidity', word_class: 'N' },
    { word: 'humidness', word_class: 'N' }
  ],
  surging: [
    { word: 'surge', word_class: 'N' },
    { word: 'surge', word_class: 'V' },
    { word: 'surging', word_class: 'AJ' }
  ],
  weakling: [
    { word: 'weak', word_class: 'AJ' },
    { word: 'weakling', word_class: 'N' },
    { word: 'weakness', word_class: 'N' }
  ],
  majestic: [
    { word: 'majesty', word_class: 'N' },
    { word: 'majestic', word_class: 'AJ' },
    { word: 'majestically', word_class: 'AV' }
  ],
  palpitating: [
    { word: 'palpitate', word_class: 'V' },
    { word: 'palpitant', word_class: 'AJ' },
    { word: 'palpitation', word_class: 'N' },
    { word: 'palpitating', word_class: 'AJ' }
  ],
  detonative: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  remonstrant: [
    { word: 'remonstrant', word_class: 'N' },
    { word: 'remonstrate', word_class: 'V' },
    { word: 'remonstrance', word_class: 'N' },
    { word: 'remonstrant', word_class: 'AJ' },
    { word: 'remonstration', word_class: 'N' }
  ],
  sanguine: [
    { word: 'sanguine', word_class: 'AJ' },
    { word: 'sanguinity', word_class: 'N' },
    { word: 'sanguineness', word_class: 'N' },
    { word: 'sanguineous', word_class: 'AJ' }
  ],
  accelerando: [
    { word: 'accelerando', word_class: 'N' },
    { word: 'accelerando', word_class: 'AJ' },
    { word: 'accelerando', word_class: 'AV' }
  ],
  mongolian: [
    { word: 'mongolian', word_class: 'N' },
    { word: 'mongolian', word_class: 'AJ' },
    { word: 'mongolianism', word_class: 'N' }
  ],
  containerize: [
    { word: 'containerise', word_class: 'V' },
    { word: 'containerize', word_class: 'V' },
    { word: 'containerized', word_class: 'AJ' },
    { word: 'containerisation', word_class: 'N' },
    { word: 'containerization', word_class: 'N' }
  ],
  bus: [
    { word: 'bus', word_class: 'N' },
    { word: 'bus', word_class: 'V' },
    { word: 'buss', word_class: 'N' },
    { word: 'buss', word_class: 'V' },
    { word: 'busful', word_class: 'N' },
    { word: 'busman', word_class: 'N' },
    { word: 'busful', word_class: 'AJ' },
    { word: 'bussing', word_class: 'N' }
  ],
  parallelism: [
    { word: 'parallel', word_class: 'N' },
    { word: 'parallel', word_class: 'V' },
    { word: 'parallel', word_class: 'AJ' },
    { word: 'parallelism', word_class: 'N' },
    { word: 'parallelize', word_class: 'V' }
  ],
  coarse: [
    { word: 'coarse', word_class: 'AJ' },
    { word: 'coarsely', word_class: 'AV' },
    { word: 'coarseness', word_class: 'N' }
  ],
  tunic: [
    { word: 'tunic', word_class: 'N' },
    { word: 'tunic', word_class: 'AJ' },
    { word: 'tunicate', word_class: 'N' }
  ],
  keel: [{ word: 'keel', word_class: 'N' }, { word: 'keel', word_class: 'V' }, { word: 'keeled', word_class: 'AJ' }],
  weighing: [
    { word: 'weigh', word_class: 'V' },
    { word: 'weight', word_class: 'N' },
    { word: 'weight', word_class: 'V' },
    { word: 'weighing', word_class: 'N' },
    { word: 'weighted', word_class: 'AJ' },
    { word: 'weighting', word_class: 'N' }
  ],
  minutely: [
    { word: 'minute', word_class: 'N' },
    { word: 'minute', word_class: 'V' },
    { word: 'minute', word_class: 'AJ' },
    { word: 'minutes', word_class: 'N' },
    { word: 'minutely', word_class: 'AV' },
    { word: 'minuteman', word_class: 'N' },
    { word: 'minuteness', word_class: 'N' }
  ],
  clang: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  recline: [
    { word: 'recline', word_class: 'N' },
    { word: 'recline', word_class: 'V' },
    { word: 'recliner', word_class: 'N' },
    { word: 'reclining', word_class: 'N' },
    { word: 'reclining', word_class: 'AJ' },
    { word: 'reclination', word_class: 'N' }
  ],
  sliced: [
    { word: 'slice', word_class: 'N' },
    { word: 'slice', word_class: 'V' },
    { word: 'slicer', word_class: 'N' },
    { word: 'sliced', word_class: 'AJ' },
    { word: 'slicing', word_class: 'N' }
  ],
  hippie: [
    { word: 'hippy', word_class: 'N' },
    { word: 'hippie', word_class: 'N' },
    { word: 'hippy', word_class: 'AJ' }
  ],
  constabulary: [
    { word: 'constable', word_class: 'N' },
    { word: 'constabulary', word_class: 'N' },
    { word: 'constabulary', word_class: 'AJ' }
  ],
  quantifiability: [
    { word: 'quantify', word_class: 'V' },
    { word: 'quantifier', word_class: 'N' },
    { word: 'quantifiable', word_class: 'AJ' },
    { word: 'quantification', word_class: 'N' },
    { word: 'quantifiability', word_class: 'N' }
  ],
  enveloping: [
    { word: 'envelop', word_class: 'V' },
    { word: 'envelope', word_class: 'N' },
    { word: 'envelope', word_class: 'V' },
    { word: 'enveloped', word_class: 'AJ' },
    { word: 'enveloping', word_class: 'N' },
    { word: 'enveloping', word_class: 'AJ' },
    { word: 'envelopment', word_class: 'N' }
  ],
  prudently: [
    { word: 'prudence', word_class: 'N' },
    { word: 'prudent', word_class: 'AJ' },
    { word: 'prudently', word_class: 'AV' }
  ],
  inquirer: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  indecently: [
    { word: 'indecency', word_class: 'N' },
    { word: 'indecent', word_class: 'AJ' },
    { word: 'indecently', word_class: 'AV' }
  ],
  assumed: [
    { word: 'assume', word_class: 'V' },
    { word: 'assumed', word_class: 'AJ' },
    { word: 'assuming', word_class: 'AJ' },
    { word: 'assumption', word_class: 'N' },
    { word: 'assumptive', word_class: 'AJ' }
  ],
  circumstantially: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  obediently: [
    { word: 'obey', word_class: 'V' },
    { word: 'obedience', word_class: 'N' },
    { word: 'obedient', word_class: 'AJ' },
    { word: 'obediently', word_class: 'AV' }
  ],
  resurge: [
    { word: 'resurge', word_class: 'N' },
    { word: 'resurge', word_class: 'V' },
    { word: 'resurgence', word_class: 'N' },
    { word: 'resurgent', word_class: 'AJ' }
  ],
  plumpness: [
    { word: 'plump', word_class: 'N' },
    { word: 'plump', word_class: 'V' },
    { word: 'plump', word_class: 'AJ' },
    { word: 'plump', word_class: 'AV' },
    { word: 'plumping', word_class: 'AJ' },
    { word: 'plumpness', word_class: 'N' }
  ],
  itchiness: [
    { word: 'itch', word_class: 'N' },
    { word: 'itch', word_class: 'V' },
    { word: 'itchy', word_class: 'AJ' },
    { word: 'itching', word_class: 'N' },
    { word: 'itchiness', word_class: 'N' }
  ],
  enurement: [
    { word: 'enure', word_class: 'V' },
    { word: 'enured', word_class: 'AJ' },
    { word: 'enurement', word_class: 'N' }
  ],
  subscription: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  suppurate: [
    { word: 'suppurate', word_class: 'V' },
    { word: 'suppuration', word_class: 'N' },
    { word: 'suppurative', word_class: 'AJ' }
  ],
  feeder: [
    { word: 'feed', word_class: 'N' },
    { word: 'feed', word_class: 'V' },
    { word: 'feeder', word_class: 'N' },
    { word: 'feeding', word_class: 'N' }
  ],
  involution: [
    { word: 'involute', word_class: 'V' },
    { word: 'involute', word_class: 'AJ' },
    { word: 'involution', word_class: 'N' }
  ],
  countersign: [
    { word: 'countersign', word_class: 'N' },
    { word: 'countersign', word_class: 'V' },
    { word: 'countersignature', word_class: 'N' }
  ],
  glaze: [
    { word: 'glaze', word_class: 'N' },
    { word: 'glaze', word_class: 'V' },
    { word: 'glazer', word_class: 'N' },
    { word: 'glazed', word_class: 'AJ' },
    { word: 'glazing', word_class: 'N' }
  ],
  applicant: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  absolve: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  favorite: [
    { word: 'favorite', word_class: 'N' },
    { word: 'favorite', word_class: 'AJ' },
    { word: 'favoritism', word_class: 'N' }
  ],
  onward: [
    { word: 'onward', word_class: 'AJ' },
    { word: 'onward', word_class: 'AV' },
    { word: 'onwards', word_class: 'AV' }
  ],
  vitalize: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  scrawl: [
    { word: 'scrawl', word_class: 'N' },
    { word: 'scrawl', word_class: 'V' },
    { word: 'scrawled', word_class: 'AJ' }
  ],
  motorist: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  zero: [{ word: 'zero', word_class: 'N' }, { word: 'zero', word_class: 'V' }, { word: 'zero', word_class: 'AJ' }],
  repayment: [
    { word: 'repay', word_class: 'V' },
    { word: 'repayment', word_class: 'N' },
    { word: 'repayable', word_class: 'AJ' }
  ],
  comfort: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  valuableness: [
    { word: 'valuable', word_class: 'N' },
    { word: 'valuable', word_class: 'AJ' },
    { word: 'valuables', word_class: 'N' },
    { word: 'valuableness', word_class: 'N' }
  ],
  valorization: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  bowman: [
    { word: 'bow', word_class: 'N' },
    { word: 'bow', word_class: 'V' },
    { word: 'bow', word_class: 'AJ' },
    { word: 'bower', word_class: 'N' },
    { word: 'bower', word_class: 'V' },
    { word: 'bowed', word_class: 'AJ' },
    { word: 'bowman', word_class: 'N' },
    { word: 'bowing', word_class: 'N' },
    { word: 'bowing', word_class: 'AJ' }
  ],
  rioting: [
    { word: 'riot', word_class: 'N' },
    { word: 'riot', word_class: 'V' },
    { word: 'rioter', word_class: 'N' },
    { word: 'rioting', word_class: 'N' }
  ],
  terminated: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  potentiate: [
    { word: 'potential', word_class: 'N' },
    { word: 'potential', word_class: 'AJ' },
    { word: 'potentiate', word_class: 'V' },
    { word: 'potentiality', word_class: 'N' },
    { word: 'potentially', word_class: 'AV' },
    { word: 'potentiation', word_class: 'N' }
  ],
  unclear: [
    { word: 'unclear', word_class: 'AJ' },
    { word: 'uncleared', word_class: 'AJ' },
    { word: 'unclearness', word_class: 'N' }
  ],
  disentanglement: [
    { word: 'disentangle', word_class: 'V' },
    { word: 'disentangled', word_class: 'AJ' },
    { word: 'disentanglement', word_class: 'N' }
  ],
  merited: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  policeman: [
    { word: 'police', word_class: 'N' },
    { word: 'police', word_class: 'V' },
    { word: 'policeman', word_class: 'N' }
  ],
  liberating: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  parasitically: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  bacteriologic: [
    { word: 'bacteriology', word_class: 'N' },
    { word: 'bacteriologic', word_class: 'AJ' },
    { word: 'bacteriological', word_class: 'AJ' }
  ],
  congested: [
    { word: 'congest', word_class: 'V' },
    { word: 'congested', word_class: 'AJ' },
    { word: 'congestion', word_class: 'N' },
    { word: 'congestive', word_class: 'AJ' }
  ],
  vermiculation: [
    { word: 'vermiculate', word_class: 'V' },
    { word: 'vermiculate', word_class: 'AJ' },
    { word: 'vermiculated', word_class: 'AJ' },
    { word: 'vermiculation', word_class: 'N' }
  ],
  ascertainable: [
    { word: 'ascertain', word_class: 'V' },
    { word: 'ascertained', word_class: 'AJ' },
    { word: 'ascertainment', word_class: 'N' },
    { word: 'ascertainable', word_class: 'AJ' }
  ],
  togs: [{ word: 'tog', word_class: 'V' }, { word: 'togs', word_class: 'N' }, { word: 'togged', word_class: 'AJ' }],
  centrical: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  equinox: [
    { word: 'equinox', word_class: 'N' },
    { word: 'equinoctial', word_class: 'N' },
    { word: 'equinoctial', word_class: 'AJ' }
  ],
  staple: [
    { word: 'staple', word_class: 'N' },
    { word: 'staple', word_class: 'V' },
    { word: 'staple', word_class: 'AJ' },
    { word: 'stapler', word_class: 'N' },
    { word: 'stapled', word_class: 'AJ' }
  ],
  allegory: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  islamization: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  exuviation: [
    { word: 'exuvial', word_class: 'AJ' },
    { word: 'exuviate', word_class: 'V' },
    { word: 'exuviation', word_class: 'N' }
  ],
  gnaw: [{ word: 'gnaw', word_class: 'N' }, { word: 'gnaw', word_class: 'V' }, { word: 'gnawer', word_class: 'N' }],
  fille: [
    { word: 'fill', word_class: 'N' },
    { word: 'fill', word_class: 'V' },
    { word: 'fille', word_class: 'N' },
    { word: 'filler', word_class: 'N' },
    { word: 'filled', word_class: 'AJ' },
    { word: 'filling', word_class: 'N' }
  ],
  allotrope: [
    { word: 'allotrope', word_class: 'N' },
    { word: 'allotropic', word_class: 'AJ' },
    { word: 'allotropism', word_class: 'N' },
    { word: 'allotropical', word_class: 'AJ' }
  ],
  deferential: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  coronation: [
    { word: 'coronal', word_class: 'N' },
    { word: 'coroner', word_class: 'N' },
    { word: 'coronate', word_class: 'V' },
    { word: 'coronation', word_class: 'N' }
  ],
  upstair: [
    { word: 'upstair', word_class: 'AJ' },
    { word: 'upstairs', word_class: 'N' },
    { word: 'upstairs', word_class: 'AJ' },
    { word: 'upstairs', word_class: 'AV' }
  ],
  later: [
    { word: 'later', word_class: 'AJ' },
    { word: 'later', word_class: 'AV' },
    { word: 'lateral', word_class: 'N' },
    { word: 'lateral', word_class: 'AJ' },
    { word: 'laterality', word_class: 'N' },
    { word: 'laterally', word_class: 'AV' }
  ],
  sleepwalk: [
    { word: 'sleepwalk', word_class: 'V' },
    { word: 'sleepwalker', word_class: 'N' },
    { word: 'sleepwalking', word_class: 'N' }
  ],
  shrouding: [
    { word: 'shroud', word_class: 'N' },
    { word: 'shroud', word_class: 'V' },
    { word: 'shrouding', word_class: 'AJ' }
  ],
  monopolisation: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  damned: [
    { word: 'damn', word_class: 'N' },
    { word: 'damn', word_class: 'V' },
    { word: 'damn', word_class: 'AJ' },
    { word: 'damn', word_class: 'AV' },
    { word: 'damned', word_class: 'N' },
    { word: 'damned', word_class: 'AJ' },
    { word: 'damned', word_class: 'AV' },
    { word: 'damning', word_class: 'AJ' },
    { word: 'damnation', word_class: 'N' }
  ],
  submittal: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  evaporated: [
    { word: 'evaporate', word_class: 'V' },
    { word: 'evaporable', word_class: 'AJ' },
    { word: 'evaporated', word_class: 'AJ' },
    { word: 'evaporation', word_class: 'N' },
    { word: 'evaporative', word_class: 'AJ' }
  ],
  solidify: [
    { word: 'solidify', word_class: 'V' },
    { word: 'solidified', word_class: 'AJ' },
    { word: 'solidifying', word_class: 'N' },
    { word: 'solidification', word_class: 'N' }
  ],
  sailor: [
    { word: 'sail', word_class: 'N' },
    { word: 'sail', word_class: 'V' },
    { word: 'sailor', word_class: 'N' },
    { word: 'sailing', word_class: 'N' },
    { word: 'sailing', word_class: 'AJ' }
  ],
  imperialistic: [
    { word: 'imperialist', word_class: 'N' },
    { word: 'imperialist', word_class: 'AJ' },
    { word: 'imperialistic', word_class: 'AJ' }
  ],
  consolidated: [
    { word: 'consolidate', word_class: 'V' },
    { word: 'consolidated', word_class: 'AJ' },
    { word: 'consolidation', word_class: 'N' },
    { word: 'consolidative', word_class: 'AJ' }
  ],
  petrifaction: [
    { word: 'petrify', word_class: 'V' },
    { word: 'petrified', word_class: 'AJ' },
    { word: 'petrifying', word_class: 'AJ' },
    { word: 'petrifaction', word_class: 'N' },
    { word: 'petrification', word_class: 'N' }
  ],
  perishables: [
    { word: 'perish', word_class: 'V' },
    { word: 'perisher', word_class: 'N' },
    { word: 'perishable', word_class: 'AJ' },
    { word: 'perishables', word_class: 'N' }
  ],
  shoveler: [
    { word: 'shovel', word_class: 'N' },
    { word: 'shovel', word_class: 'V' },
    { word: 'shoveler', word_class: 'N' },
    { word: 'shovelful', word_class: 'N' },
    { word: 'shovelful', word_class: 'AJ' }
  ],
  detoxicate: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  concur: [
    { word: 'concur', word_class: 'V' },
    { word: 'concurrence', word_class: 'N' },
    { word: 'concurrent', word_class: 'AJ' },
    { word: 'concurring', word_class: 'AJ' },
    { word: 'concurrently', word_class: 'AV' }
  ],
  broken: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  predator: [
    { word: 'prey', word_class: 'N' },
    { word: 'prey', word_class: 'V' },
    { word: 'predate', word_class: 'V' },
    { word: 'preying', word_class: 'N' },
    { word: 'predator', word_class: 'N' },
    { word: 'predation', word_class: 'N' }
  ],
  localise: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  filled: [
    { word: 'fill', word_class: 'N' },
    { word: 'fill', word_class: 'V' },
    { word: 'fille', word_class: 'N' },
    { word: 'filler', word_class: 'N' },
    { word: 'filled', word_class: 'AJ' },
    { word: 'filling', word_class: 'N' }
  ],
  hieratic: [
    { word: 'hieratic', word_class: 'N' },
    { word: 'hieratic', word_class: 'AJ' },
    { word: 'hieratical', word_class: 'AJ' }
  ],
  rambling: [
    { word: 'ramble', word_class: 'N' },
    { word: 'ramble', word_class: 'V' },
    { word: 'rambler', word_class: 'N' },
    { word: 'rambling', word_class: 'AJ' }
  ],
  seeded: [
    { word: 'seed', word_class: 'N' },
    { word: 'seed', word_class: 'V' },
    { word: 'seeder', word_class: 'N' },
    { word: 'seeded', word_class: 'AJ' },
    { word: 'seedman', word_class: 'N' }
  ],
  atlantes: [
    { word: 'atlantes', word_class: 'N' },
    { word: 'atlantic', word_class: 'N' },
    { word: 'atlantic', word_class: 'AJ' }
  ],
  bait: [{ word: 'bait', word_class: 'N' }, { word: 'bait', word_class: 'V' }, { word: 'baiting', word_class: 'N' }],
  cockneyfy: [
    { word: 'cocknify', word_class: 'V' },
    { word: 'cockneyfy', word_class: 'V' },
    { word: 'cocknification', word_class: 'N' },
    { word: 'cockneyfication', word_class: 'N' }
  ],
  entering: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  dislocation: [
    { word: 'dislocate', word_class: 'V' },
    { word: 'dislocated', word_class: 'AJ' },
    { word: 'dislocation', word_class: 'N' }
  ],
  condolement: [
    { word: 'condole', word_class: 'V' },
    { word: 'condolence', word_class: 'N' },
    { word: 'condolement', word_class: 'N' }
  ],
  bionomical: [
    { word: 'bionomic', word_class: 'AJ' },
    { word: 'bionomics', word_class: 'N' },
    { word: 'bionomical', word_class: 'AJ' }
  ],
  burbling: [
    { word: 'burble', word_class: 'V' },
    { word: 'burbly', word_class: 'AJ' },
    { word: 'burbling', word_class: 'AJ' }
  ],
  hellenism: [
    { word: 'hellene', word_class: 'N' },
    { word: 'hellenic', word_class: 'N' },
    { word: 'hellenic', word_class: 'AJ' },
    { word: 'hellenism', word_class: 'N' },
    { word: 'hellenize', word_class: 'V' },
    { word: 'hellenization', word_class: 'N' }
  ],
  colloquialism: [
    { word: 'colloquy', word_class: 'N' },
    { word: 'colloquial', word_class: 'AJ' },
    { word: 'colloquialism', word_class: 'N' },
    { word: 'colloquially', word_class: 'AV' }
  ],
  al: [{ word: 'al', word_class: 'N' }, { word: 'ale', word_class: 'N' }, { word: 'als', word_class: 'N' }],
  cleaned: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  contiguous: [
    { word: 'contiguity', word_class: 'N' },
    { word: 'contiguous', word_class: 'AJ' },
    { word: 'contiguousness', word_class: 'N' }
  ],
  goaded: [
    { word: 'goad', word_class: 'N' },
    { word: 'goad', word_class: 'V' },
    { word: 'goaded', word_class: 'AJ' },
    { word: 'goading', word_class: 'N' }
  ],
  restrained: [
    { word: 'restrain', word_class: 'N' },
    { word: 'restrain', word_class: 'V' },
    { word: 'restraint', word_class: 'N' },
    { word: 'restrained', word_class: 'AJ' }
  ],
  title: [
    { word: 'title', word_class: 'N' },
    { word: 'title', word_class: 'V' },
    { word: 'titled', word_class: 'AJ' },
    { word: 'titular', word_class: 'AJ' }
  ],
  enhance: [
    { word: 'enhance', word_class: 'V' },
    { word: 'enhanced', word_class: 'AJ' },
    { word: 'enhancive', word_class: 'AJ' },
    { word: 'enhancement', word_class: 'N' }
  ],
  westernize: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  interlock: [
    { word: 'interlock', word_class: 'N' },
    { word: 'interlock', word_class: 'V' },
    { word: 'interlocking', word_class: 'N' },
    { word: 'interlocking', word_class: 'AJ' }
  ],
  transiency: [
    { word: 'transient', word_class: 'N' },
    { word: 'transience', word_class: 'N' },
    { word: 'transiency', word_class: 'N' },
    { word: 'transient', word_class: 'AJ' },
    { word: 'transiently', word_class: 'AV' }
  ],
  prosecutor: [
    { word: 'prosecute', word_class: 'V' },
    { word: 'prosecutor', word_class: 'N' },
    { word: 'prosecution', word_class: 'N' }
  ],
  estimate: [
    { word: 'estimate', word_class: 'N' },
    { word: 'estimate', word_class: 'V' },
    { word: 'estimator', word_class: 'N' },
    { word: 'estimable', word_class: 'AJ' },
    { word: 'estimated', word_class: 'AJ' },
    { word: 'estimation', word_class: 'N' }
  ],
  correction: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  uniqueness: [
    { word: 'unique', word_class: 'AJ' },
    { word: 'uniquely', word_class: 'AV' },
    { word: 'uniqueness', word_class: 'N' }
  ],
  disregard: [
    { word: 'disregard', word_class: 'N' },
    { word: 'disregard', word_class: 'V' },
    { word: 'disregarded', word_class: 'AJ' },
    { word: 'disregarding', word_class: 'AV' }
  ],
  serology: [
    { word: 'serology', word_class: 'N' },
    { word: 'serologic', word_class: 'AJ' },
    { word: 'serological', word_class: 'AJ' }
  ],
  determination: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  trail: [
    { word: 'trail', word_class: 'N' },
    { word: 'trail', word_class: 'V' },
    { word: 'trailer', word_class: 'N' },
    { word: 'trailing', word_class: 'N' },
    { word: 'trailing', word_class: 'AJ' }
  ],
  departmentally: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  braced: [
    { word: 'brace', word_class: 'N' },
    { word: 'brace', word_class: 'V' },
    { word: 'bracer', word_class: 'N' },
    { word: 'braces', word_class: 'N' },
    { word: 'braced', word_class: 'AJ' },
    { word: 'bracing', word_class: 'N' },
    { word: 'bracing', word_class: 'AJ' }
  ],
  fevered: [
    { word: 'fever', word_class: 'N' },
    { word: 'fever', word_class: 'V' },
    { word: 'fevered', word_class: 'AJ' },
    { word: 'feverous', word_class: 'AJ' }
  ],
  chop: [{ word: 'chop', word_class: 'N' }, { word: 'chop', word_class: 'V' }, { word: 'chopped', word_class: 'AJ' }],
  daubery: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  gleam: [
    { word: 'gleam', word_class: 'N' },
    { word: 'gleam', word_class: 'V' },
    { word: 'gleaming', word_class: 'N' },
    { word: 'gleaming', word_class: 'AJ' }
  ],
  shutter: [
    { word: 'shutter', word_class: 'N' },
    { word: 'shutter', word_class: 'V' },
    { word: 'shuttered', word_class: 'AJ' }
  ],
  quit: [
    { word: 'quit', word_class: 'V' },
    { word: 'quit', word_class: 'AJ' },
    { word: 'quite', word_class: 'AV' },
    { word: 'quits', word_class: 'AJ' }
  ],
  duelist: [
    { word: 'duel', word_class: 'N' },
    { word: 'duel', word_class: 'V' },
    { word: 'dueler', word_class: 'N' },
    { word: 'duello', word_class: 'N' },
    { word: 'duelist', word_class: 'N' }
  ],
  distribute: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  etymological: [
    { word: 'etymology', word_class: 'N' },
    { word: 'etymologize', word_class: 'V' },
    { word: 'etymological', word_class: 'AJ' },
    { word: 'etymologizing', word_class: 'N' },
    { word: 'etymologization', word_class: 'N' }
  ],
  ministration: [
    { word: 'minister', word_class: 'V' },
    { word: 'minister', word_class: 'N' },
    { word: 'ministry', word_class: 'N' },
    { word: 'ministrant', word_class: 'N' },
    { word: 'ministrant', word_class: 'AJ' },
    { word: 'ministering', word_class: 'AJ' },
    { word: 'ministration', word_class: 'N' }
  ],
  deadly: [
    { word: 'dead', word_class: 'N' },
    { word: 'dead', word_class: 'AJ' },
    { word: 'dead', word_class: 'AV' },
    { word: 'deadly', word_class: 'AJ' },
    { word: 'deadly', word_class: 'AV' },
    { word: 'deadness', word_class: 'N' },
    { word: 'deadliness', word_class: 'N' }
  ],
  musculature: [
    { word: 'muscle', word_class: 'N' },
    { word: 'muscle', word_class: 'V' },
    { word: 'muscled', word_class: 'AJ' },
    { word: 'muscular', word_class: 'AJ' },
    { word: 'musculature', word_class: 'N' }
  ],
  leveraging: [
    { word: 'lever', word_class: 'N' },
    { word: 'lever', word_class: 'V' },
    { word: 'leverage', word_class: 'N' },
    { word: 'leverage', word_class: 'V' },
    { word: 'leveraging', word_class: 'N' }
  ],
  athletics: [
    { word: 'athlete', word_class: 'N' },
    { word: 'athletic', word_class: 'AJ' },
    { word: 'athletics', word_class: 'N' }
  ],
  supinate: [
    { word: 'supine', word_class: 'AJ' },
    { word: 'supinate', word_class: 'V' },
    { word: 'supinely', word_class: 'AV' },
    { word: 'supination', word_class: 'N' }
  ],
  writhed: [
    { word: 'writhe', word_class: 'N' },
    { word: 'writhe', word_class: 'V' },
    { word: 'writhed', word_class: 'AJ' },
    { word: 'writhing', word_class: 'AJ' }
  ],
  suggestibility: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  lodge: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  caprice: [
    { word: 'caprice', word_class: 'N' },
    { word: 'capricious', word_class: 'AJ' },
    { word: 'capriciously', word_class: 'AV' },
    { word: 'capriciousness', word_class: 'N' }
  ],
  advice: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  degrading: [
    { word: 'degrade', word_class: 'N' },
    { word: 'degrade', word_class: 'V' },
    { word: 'degraded', word_class: 'AJ' },
    { word: 'degrading', word_class: 'AJ' },
    { word: 'degradation', word_class: 'N' }
  ],
  flier: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  vowelise: [
    { word: 'vowel', word_class: 'N' },
    { word: 'vowelise', word_class: 'V' },
    { word: 'vowelize', word_class: 'V' },
    { word: 'vowelisation', word_class: 'N' },
    { word: 'vowelization', word_class: 'N' }
  ],
  continuity: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  actinal: [
    { word: 'actin', word_class: 'N' },
    { word: 'actinal', word_class: 'AJ' },
    { word: 'actinic', word_class: 'AJ' },
    { word: 'actinism', word_class: 'N' }
  ],
  therapeutical: [
    { word: 'therapy', word_class: 'N' },
    { word: 'therapist', word_class: 'N' },
    { word: 'therapeutic', word_class: 'AJ' },
    { word: 'therapeutics', word_class: 'N' },
    { word: 'therapeutical', word_class: 'AJ' },
    { word: 'therapeutically', word_class: 'AV' }
  ],
  denationalisation: [
    { word: 'denationalise', word_class: 'V' },
    { word: 'denationalize', word_class: 'V' },
    { word: 'denationalisation', word_class: 'N' },
    { word: 'denationalization', word_class: 'N' }
  ],
  faultfinder: [
    { word: 'faultfinder', word_class: 'N' },
    { word: 'faultfinding', word_class: 'N' },
    { word: 'faultfinding', word_class: 'AJ' }
  ],
  elasticate: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  formalities: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  totalitarian: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  perseverence: [
    { word: 'persevere', word_class: 'V' },
    { word: 'perseverance', word_class: 'N' },
    { word: 'perseverence', word_class: 'N' },
    { word: 'perseveration', word_class: 'N' }
  ],
  vacillate: [
    { word: 'vacillate', word_class: 'V' },
    { word: 'vacillant', word_class: 'AJ' },
    { word: 'vacillator', word_class: 'N' },
    { word: 'vacillation', word_class: 'N' },
    { word: 'vacillating', word_class: 'AJ' }
  ],
  tranquillization: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  murmur: [
    { word: 'murmur', word_class: 'N' },
    { word: 'murmur', word_class: 'V' },
    { word: 'murmuring', word_class: 'N' },
    { word: 'murmuring', word_class: 'AJ' },
    { word: 'murmurous', word_class: 'AJ' },
    { word: 'murmuration', word_class: 'N' }
  ],
  ameliorating: [
    { word: 'ameliorate', word_class: 'V' },
    { word: 'amelioration', word_class: 'N' },
    { word: 'ameliorating', word_class: 'AJ' },
    { word: 'ameliorative', word_class: 'AJ' }
  ],
  absorber: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  courting: [
    { word: 'court', word_class: 'N' },
    { word: 'court', word_class: 'V' },
    { word: 'courting', word_class: 'N' },
    { word: 'courtship', word_class: 'N' }
  ],
  sinning: [
    { word: 'sin', word_class: 'N' },
    { word: 'sin', word_class: 'V' },
    { word: 'sinful', word_class: 'AJ' },
    { word: 'sinning', word_class: 'N' },
    { word: 'sinning', word_class: 'AJ' },
    { word: 'sinfulness', word_class: 'N' }
  ],
  excessiveness: [
    { word: 'exceed', word_class: 'V' },
    { word: 'excess', word_class: 'N' },
    { word: 'excess', word_class: 'AJ' },
    { word: 'excessive', word_class: 'AJ' },
    { word: 'excessively', word_class: 'AV' },
    { word: 'excessiveness', word_class: 'N' }
  ],
  accordance: [
    { word: 'accord', word_class: 'N' },
    { word: 'accord', word_class: 'V' },
    { word: 'accordion', word_class: 'N' },
    { word: 'accordance', word_class: 'N' },
    { word: 'accordant', word_class: 'AJ' },
    { word: 'according', word_class: 'AJ' },
    { word: 'accordion', word_class: 'AJ' }
  ],
  deteriorate: [
    { word: 'deteriorate', word_class: 'V' },
    { word: 'deterioration', word_class: 'N' },
    { word: 'deteriorating', word_class: 'AJ' }
  ],
  diagrammatically: [
    { word: 'diagram', word_class: 'N' },
    { word: 'diagram', word_class: 'V' },
    { word: 'diagramming', word_class: 'N' },
    { word: 'diagrammatic', word_class: 'AJ' },
    { word: 'diagrammatical', word_class: 'AJ' },
    { word: 'diagrammatically', word_class: 'AV' }
  ],
  oxidizer: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  envelopment: [
    { word: 'envelop', word_class: 'V' },
    { word: 'envelope', word_class: 'N' },
    { word: 'envelope', word_class: 'V' },
    { word: 'enveloped', word_class: 'AJ' },
    { word: 'enveloping', word_class: 'N' },
    { word: 'enveloping', word_class: 'AJ' },
    { word: 'envelopment', word_class: 'N' }
  ],
  ridiculousness: [
    { word: 'ridicule', word_class: 'N' },
    { word: 'ridicule', word_class: 'V' },
    { word: 'ridiculous', word_class: 'AJ' },
    { word: 'ridiculously', word_class: 'AV' },
    { word: 'ridiculousness', word_class: 'N' }
  ],
  apery: [
    { word: 'apery', word_class: 'N' },
    { word: 'aperient', word_class: 'N' },
    { word: 'aperient', word_class: 'AJ' }
  ],
  cuddle: [
    { word: 'cuddle', word_class: 'N' },
    { word: 'cuddle', word_class: 'V' },
    { word: 'cuddly', word_class: 'AJ' },
    { word: 'cuddling', word_class: 'N' }
  ],
  precaution: [
    { word: 'precaution', word_class: 'N' },
    { word: 'precaution', word_class: 'V' },
    { word: 'precautional', word_class: 'AJ' }
  ],
  whacker: [
    { word: 'whack', word_class: 'N' },
    { word: 'whack', word_class: 'V' },
    { word: 'whacker', word_class: 'N' },
    { word: 'whacked', word_class: 'AJ' },
    { word: 'whacking', word_class: 'N' },
    { word: 'whacking', word_class: 'AJ' },
    { word: 'whacking', word_class: 'AV' }
  ],
  retaliative: [
    { word: 'retaliate', word_class: 'V' },
    { word: 'retaliator', word_class: 'N' },
    { word: 'retaliation', word_class: 'N' },
    { word: 'retaliative', word_class: 'AJ' }
  ],
  glamorous: [
    { word: 'glamor', word_class: 'N' },
    { word: 'glamorize', word_class: 'V' },
    { word: 'glamorous', word_class: 'AJ' },
    { word: 'glamorisation', word_class: 'N' },
    { word: 'glamorization', word_class: 'N' }
  ],
  heterogeneousness: [
    { word: 'heterogeneity', word_class: 'N' },
    { word: 'heterogenous', word_class: 'AJ' },
    { word: 'heterogeneous', word_class: 'AJ' },
    { word: 'heterogeneousness', word_class: 'N' }
  ],
  editorial: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  apartment: [
    { word: 'apart', word_class: 'AJ' },
    { word: 'apart', word_class: 'AV' },
    { word: 'apartment', word_class: 'N' }
  ],
  meningitis: [
    { word: 'meninges', word_class: 'N' },
    { word: 'meningism', word_class: 'N' },
    { word: 'meningeal', word_class: 'AJ' },
    { word: 'meningitis', word_class: 'N' }
  ],
  unobserved: [
    { word: 'unobserved', word_class: 'AJ' },
    { word: 'unobservant', word_class: 'AJ' },
    { word: 'unobservable', word_class: 'AJ' }
  ],
  pontific: [
    { word: 'pontific', word_class: 'AJ' },
    { word: 'pontifical', word_class: 'AJ' },
    { word: 'pontificate', word_class: 'N' },
    { word: 'pontificate', word_class: 'V' },
    { word: 'pontification', word_class: 'N' }
  ],
  misprision: [
    { word: 'misprise', word_class: 'V' },
    { word: 'misprize', word_class: 'V' },
    { word: 'misprision', word_class: 'N' }
  ],
  enigmatically: [
    { word: 'enigma', word_class: 'N' },
    { word: 'enigmatic', word_class: 'AJ' },
    { word: 'enigmatical', word_class: 'AJ' },
    { word: 'enigmatically', word_class: 'AV' }
  ],
  resultant: [
    { word: 'result', word_class: 'N' },
    { word: 'result', word_class: 'V' },
    { word: 'resultant', word_class: 'N' },
    { word: 'resultant', word_class: 'AJ' },
    { word: 'resulting', word_class: 'AJ' }
  ],
  amplify: [
    { word: 'amplify', word_class: 'V' },
    { word: 'amplifier', word_class: 'N' },
    { word: 'amplification', word_class: 'N' }
  ],
  vandalise: [
    { word: 'vandal', word_class: 'N' },
    { word: 'vandalise', word_class: 'V' },
    { word: 'vandalism', word_class: 'N' },
    { word: 'vandalize', word_class: 'V' },
    { word: 'vandalization', word_class: 'N' }
  ],
  ticking: [
    { word: 'tick', word_class: 'N' },
    { word: 'tick', word_class: 'V' },
    { word: 'ticker', word_class: 'N' },
    { word: 'ticking', word_class: 'N' }
  ],
  cinema: [
    { word: 'cinema', word_class: 'N' },
    { word: 'cinematic', word_class: 'AJ' },
    { word: 'cinematize', word_class: 'V' }
  ],
  ample: [
    { word: 'ample', word_class: 'AJ' },
    { word: 'amply', word_class: 'AJ' },
    { word: 'amply', word_class: 'AV' },
    { word: 'ampleness', word_class: 'N' }
  ],
  brewage: [
    { word: 'brew', word_class: 'N' },
    { word: 'brew', word_class: 'V' },
    { word: 'brewer', word_class: 'N' },
    { word: 'brewage', word_class: 'N' },
    { word: 'brewery', word_class: 'N' },
    { word: 'brewing', word_class: 'N' },
    { word: 'brewery', word_class: 'AJ' }
  ],
  unobservable: [
    { word: 'unobserved', word_class: 'AJ' },
    { word: 'unobservant', word_class: 'AJ' },
    { word: 'unobservable', word_class: 'AJ' }
  ],
  mutuality: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  ligation: [
    { word: 'ligate', word_class: 'V' },
    { word: 'ligation', word_class: 'N' },
    { word: 'ligature', word_class: 'N' }
  ],
  extradition: [
    { word: 'extradite', word_class: 'N' },
    { word: 'extradite', word_class: 'V' },
    { word: 'extradition', word_class: 'N' }
  ],
  cannulate: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  dissolvable: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  regularize: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  corrective: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  jailer: [
    { word: 'jail', word_class: 'N' },
    { word: 'jail', word_class: 'V' },
    { word: 'jailer', word_class: 'N' },
    { word: 'jailed', word_class: 'AJ' },
    { word: 'jailing', word_class: 'N' }
  ],
  shelled: [
    { word: 'shell', word_class: 'N' },
    { word: 'shell', word_class: 'V' },
    { word: 'shell', word_class: 'AJ' },
    { word: 'shelled', word_class: 'AJ' },
    { word: 'shelling', word_class: 'N' }
  ],
  universally: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  fireman: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  palatinate: [
    { word: 'palatine', word_class: 'N' },
    { word: 'palatine', word_class: 'AJ' },
    { word: 'palatinate', word_class: 'N' }
  ],
  repeater: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  psychical: [
    { word: 'psychic', word_class: 'N' },
    { word: 'psychic', word_class: 'AJ' },
    { word: 'psychical', word_class: 'AJ' },
    { word: 'psychically', word_class: 'AV' }
  ],
  exhalation: [
    { word: 'exhale', word_class: 'V' },
    { word: 'exhale', word_class: 'AJ' },
    { word: 'exhaled', word_class: 'AJ' },
    { word: 'exhaling', word_class: 'AJ' },
    { word: 'exhalation', word_class: 'N' }
  ],
  garnish: [
    { word: 'garnish', word_class: 'N' },
    { word: 'garnish', word_class: 'V' },
    { word: 'garnished', word_class: 'AJ' },
    { word: 'garnishment', word_class: 'N' }
  ],
  isomerise: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  pursued: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  cocknify: [
    { word: 'cocknify', word_class: 'V' },
    { word: 'cockneyfy', word_class: 'V' },
    { word: 'cocknification', word_class: 'N' },
    { word: 'cockneyfication', word_class: 'N' }
  ],
  ostensibly: [
    { word: 'ostensive', word_class: 'AJ' },
    { word: 'ostensible', word_class: 'AJ' },
    { word: 'ostensibly', word_class: 'AV' }
  ],
  judiciousness: [
    { word: 'judicial', word_class: 'AJ' },
    { word: 'judicious', word_class: 'AJ' },
    { word: 'judicially', word_class: 'AV' },
    { word: 'judiciously', word_class: 'AV' },
    { word: 'judiciousness', word_class: 'N' }
  ],
  lenient: [
    { word: 'lenience', word_class: 'N' },
    { word: 'leniency', word_class: 'N' },
    { word: 'lenient', word_class: 'AJ' },
    { word: 'leniently', word_class: 'AV' }
  ],
  tights: [
    { word: 'tight', word_class: 'AJ' },
    { word: 'tight', word_class: 'AV' },
    { word: 'tights', word_class: 'N' },
    { word: 'tightness', word_class: 'N' }
  ],
  variance: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  graded: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  radially: [
    { word: 'radial', word_class: 'N' },
    { word: 'radial', word_class: 'AJ' },
    { word: 'radially', word_class: 'AV' }
  ],
  despiteful: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  abolition: [
    { word: 'abolish', word_class: 'V' },
    { word: 'abolition', word_class: 'N' },
    { word: 'abolishment', word_class: 'N' },
    { word: 'abolishable', word_class: 'AJ' }
  ],
  aloof: [
    { word: 'aloof', word_class: 'AJ' },
    { word: 'aloof', word_class: 'AV' },
    { word: 'aloofness', word_class: 'N' }
  ],
  bicyclist: [
    { word: 'bicycle', word_class: 'N' },
    { word: 'bicycle', word_class: 'V' },
    { word: 'bicyclic', word_class: 'AJ' },
    { word: 'bicycling', word_class: 'N' },
    { word: 'bicyclist', word_class: 'N' }
  ],
  ponder: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  consummation: [
    { word: 'consummate', word_class: 'V' },
    { word: 'consummate', word_class: 'AJ' },
    { word: 'consummated', word_class: 'AJ' },
    { word: 'consummation', word_class: 'N' }
  ],
  dripping: [
    { word: 'drip', word_class: 'N' },
    { word: 'drip', word_class: 'V' },
    { word: 'dripping', word_class: 'N' },
    { word: 'dripping', word_class: 'AJ' },
    { word: 'dripping', word_class: 'AV' },
    { word: 'drippings', word_class: 'N' }
  ],
  animize: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  enablement: [
    { word: 'enable', word_class: 'V' },
    { word: 'enabling', word_class: 'AJ' },
    { word: 'enablement', word_class: 'N' }
  ],
  waiting: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  crossed: [
    { word: 'cross', word_class: 'N' },
    { word: 'cross', word_class: 'V' },
    { word: 'cross', word_class: 'AJ' },
    { word: 'crosse', word_class: 'N' },
    { word: 'crossed', word_class: 'AJ' },
    { word: 'crossing', word_class: 'N' },
    { word: 'crossing', word_class: 'AJ' },
    { word: 'crossness', word_class: 'N' }
  ],
  verboseness: [
    { word: 'verbose', word_class: 'AJ' },
    { word: 'verbosity', word_class: 'N' },
    { word: 'verbosely', word_class: 'AV' },
    { word: 'verboseness', word_class: 'N' }
  ],
  sulfate: [
    { word: 'sulfate', word_class: 'N' },
    { word: 'sulfate', word_class: 'V' },
    { word: 'sulfation', word_class: 'N' }
  ],
  libertarianism: [
    { word: 'liberty', word_class: 'N' },
    { word: 'libertarian', word_class: 'N' },
    { word: 'libertarian', word_class: 'AJ' },
    { word: 'libertarianism', word_class: 'N' }
  ],
  define: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  consumption: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  adulteration: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  statute: [
    { word: 'statute', word_class: 'N' },
    { word: 'statute', word_class: 'AJ' },
    { word: 'statutory', word_class: 'AJ' }
  ],
  exalt: [
    { word: 'exalt', word_class: 'V' },
    { word: 'exalted', word_class: 'AJ' },
    { word: 'exalting', word_class: 'AJ' },
    { word: 'exaltation', word_class: 'N' }
  ],
  regionalize: [
    { word: 'region', word_class: 'N' },
    { word: 'regional', word_class: 'AJ' },
    { word: 'regionalize', word_class: 'V' },
    { word: 'regionally', word_class: 'AV' }
  ],
  transparency: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  pretender: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  pointing: [
    { word: 'point', word_class: 'N' },
    { word: 'point', word_class: 'V' },
    { word: 'pointer', word_class: 'N' },
    { word: 'pointed', word_class: 'AJ' },
    { word: 'pointing', word_class: 'N' },
    { word: 'pointing', word_class: 'AJ' }
  ],
  raspy: [
    { word: 'rasp', word_class: 'N' },
    { word: 'rasp', word_class: 'V' },
    { word: 'raspy', word_class: 'AJ' },
    { word: 'rasping', word_class: 'N' },
    { word: 'rasping', word_class: 'AJ' }
  ],
  jejuneness: [
    { word: 'jejune', word_class: 'AJ' },
    { word: 'jejunity', word_class: 'N' },
    { word: 'jejunely', word_class: 'AV' },
    { word: 'jejunitis', word_class: 'N' },
    { word: 'jejuneness', word_class: 'N' }
  ],
  alienable: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  perusing: [
    { word: 'peruse', word_class: 'V' },
    { word: 'perusal', word_class: 'N' },
    { word: 'perusing', word_class: 'N' }
  ],
  marketing: [
    { word: 'market', word_class: 'N' },
    { word: 'market', word_class: 'V' },
    { word: 'marketing', word_class: 'N' },
    { word: 'marketable', word_class: 'AJ' }
  ],
  burl: [{ word: 'burl', word_class: 'N' }, { word: 'burl', word_class: 'V' }, { word: 'burled', word_class: 'AJ' }],
  patriotism: [
    { word: 'patriot', word_class: 'N' },
    { word: 'patriotic', word_class: 'AJ' },
    { word: 'patriotism', word_class: 'N' },
    { word: 'patriotically', word_class: 'AV' }
  ],
  pathologically: [
    { word: 'pathology', word_class: 'N' },
    { word: 'pathologic', word_class: 'AJ' },
    { word: 'pathological', word_class: 'AJ' },
    { word: 'pathologically', word_class: 'AV' }
  ],
  requitement: [
    { word: 'requite', word_class: 'V' },
    { word: 'requital', word_class: 'N' },
    { word: 'requited', word_class: 'AJ' },
    { word: 'requitement', word_class: 'N' }
  ],
  decipherable: [
    { word: 'decipher', word_class: 'V' },
    { word: 'deciphered', word_class: 'AJ' },
    { word: 'decipherment', word_class: 'N' },
    { word: 'decipherable', word_class: 'AJ' },
    { word: 'decipherably', word_class: 'AV' }
  ],
  unilateral: [
    { word: 'unilateral', word_class: 'AJ' },
    { word: 'unilateralism', word_class: 'N' },
    { word: 'unilaterally', word_class: 'AV' }
  ],
  pretending: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  animateness: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  apportionable: [
    { word: 'apportion', word_class: 'V' },
    { word: 'apportioned', word_class: 'AJ' },
    { word: 'apportioning', word_class: 'N' },
    { word: 'apportionment', word_class: 'N' },
    { word: 'apportionable', word_class: 'AJ' }
  ],
  boxed: [
    { word: 'box', word_class: 'N' },
    { word: 'box', word_class: 'V' },
    { word: 'boxer', word_class: 'N' },
    { word: 'boxed', word_class: 'AJ' },
    { word: 'boxful', word_class: 'N' },
    { word: 'boxing', word_class: 'N' },
    { word: 'boxful', word_class: 'AJ' }
  ],
  saturate: [
    { word: 'saturate', word_class: 'V' },
    { word: 'saturable', word_class: 'AJ' },
    { word: 'saturated', word_class: 'AJ' },
    { word: 'saturation', word_class: 'N' }
  ],
  scalar: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  daydreamer: [
    { word: 'daydream', word_class: 'N' },
    { word: 'daydream', word_class: 'V' },
    { word: 'daydreamer', word_class: 'N' }
  ],
  touch: [
    { word: 'touch', word_class: 'N' },
    { word: 'touch', word_class: 'V' },
    { word: 'touched', word_class: 'AJ' },
    { word: 'touching', word_class: 'N' },
    { word: 'touching', word_class: 'AJ' }
  ],
  emancipate: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  mannered: [
    { word: 'manner', word_class: 'N' },
    { word: 'manners', word_class: 'N' },
    { word: 'mannered', word_class: 'AJ' },
    { word: 'mannerism', word_class: 'N' }
  ],
  miniaturisation: [
    { word: 'miniature', word_class: 'N' },
    { word: 'miniature', word_class: 'AJ' },
    { word: 'miniaturise', word_class: 'V' },
    { word: 'miniaturize', word_class: 'V' },
    { word: 'miniaturisation', word_class: 'N' },
    { word: 'miniaturization', word_class: 'N' }
  ],
  abhor: [
    { word: 'abhor', word_class: 'V' },
    { word: 'abhorrer', word_class: 'N' },
    { word: 'abhorrence', word_class: 'N' },
    { word: 'abhorrent', word_class: 'AJ' }
  ],
  vacate: [
    { word: 'vacate', word_class: 'V' },
    { word: 'vacation', word_class: 'N' },
    { word: 'vacation', word_class: 'V' },
    { word: 'vacationer', word_class: 'N' },
    { word: 'vacationing', word_class: 'N' },
    { word: 'vacationist', word_class: 'N' }
  ],
  sectionalize: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  decayed: [
    { word: 'decay', word_class: 'N' },
    { word: 'decay', word_class: 'V' },
    { word: 'decayed', word_class: 'AJ' }
  ],
  instantiate: [
    { word: 'instance', word_class: 'N' },
    { word: 'instance', word_class: 'V' },
    { word: 'instancy', word_class: 'N' },
    { word: 'instantiate', word_class: 'V' },
    { word: 'instantiation', word_class: 'N' }
  ],
  spreading: [
    { word: 'spread', word_class: 'N' },
    { word: 'spread', word_class: 'V' },
    { word: 'spread', word_class: 'AJ' },
    { word: 'spreader', word_class: 'N' },
    { word: 'spreading', word_class: 'N' },
    { word: 'spreading', word_class: 'AJ' }
  ],
  acknowledgeable: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  acerbic: [
    { word: 'acerb', word_class: 'AJ' },
    { word: 'acerbate', word_class: 'V' },
    { word: 'acerbic', word_class: 'AJ' },
    { word: 'acerbity', word_class: 'N' },
    { word: 'acerbation', word_class: 'N' }
  ],
  entirety: [
    { word: 'entire', word_class: 'N' },
    { word: 'entire', word_class: 'AJ' },
    { word: 'entirety', word_class: 'N' },
    { word: 'entirely', word_class: 'AV' },
    { word: 'entireness', word_class: 'N' }
  ],
  spend: [
    { word: 'spend', word_class: 'V' },
    { word: 'spender', word_class: 'N' },
    { word: 'spending', word_class: 'N' }
  ],
  sweating: [
    { word: 'sweat', word_class: 'N' },
    { word: 'sweat', word_class: 'V' },
    { word: 'sweats', word_class: 'N' },
    { word: 'sweater', word_class: 'N' },
    { word: 'sweating', word_class: 'N' },
    { word: 'sweating', word_class: 'AJ' }
  ],
  brief: [
    { word: 'brief', word_class: 'N' },
    { word: 'brief', word_class: 'V' },
    { word: 'brief', word_class: 'AJ' },
    { word: 'briefs', word_class: 'N' },
    { word: 'brevity', word_class: 'N' },
    { word: 'briefing', word_class: 'N' },
    { word: 'briefness', word_class: 'N' }
  ],
  inevitableness: [
    { word: 'inevitable', word_class: 'N' },
    { word: 'inevitable', word_class: 'AJ' },
    { word: 'inevitably', word_class: 'AV' },
    { word: 'inevitability', word_class: 'N' },
    { word: 'inevitableness', word_class: 'N' }
  ],
  divergence: [
    { word: 'diverge', word_class: 'N' },
    { word: 'diverge', word_class: 'V' },
    { word: 'divergence', word_class: 'N' },
    { word: 'divergency', word_class: 'N' },
    { word: 'divergent', word_class: 'AJ' },
    { word: 'diverging', word_class: 'AJ' }
  ],
  pleat: [
    { word: 'pleat', word_class: 'N' },
    { word: 'pleat', word_class: 'V' },
    { word: 'pleated', word_class: 'AJ' },
    { word: 'pleating', word_class: 'N' }
  ],
  brachiation: [
    { word: 'brachiate', word_class: 'V' },
    { word: 'brachiate', word_class: 'AJ' },
    { word: 'brachiation', word_class: 'N' }
  ],
  rewriting: [
    { word: 'rewrite', word_class: 'N' },
    { word: 'rewrite', word_class: 'V' },
    { word: 'rewriter', word_class: 'N' },
    { word: 'rewriting', word_class: 'N' }
  ],
  anaesthetization: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  absurdness: [
    { word: 'absurd', word_class: 'AJ' },
    { word: 'absurdity', word_class: 'N' },
    { word: 'absurdness', word_class: 'N' }
  ],
  existence: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  prophetic: [
    { word: 'prophet', word_class: 'N' },
    { word: 'prophets', word_class: 'N' },
    { word: 'prophetic', word_class: 'AJ' },
    { word: 'prophetical', word_class: 'AJ' },
    { word: 'prophetically', word_class: 'AV' }
  ],
  syllogize: [
    { word: 'syllogise', word_class: 'V' },
    { word: 'syllogism', word_class: 'N' },
    { word: 'syllogize', word_class: 'V' },
    { word: 'syllogisation', word_class: 'N' },
    { word: 'syllogization', word_class: 'N' }
  ],
  slick: [
    { word: 'slick', word_class: 'N' },
    { word: 'slick', word_class: 'V' },
    { word: 'slick', word_class: 'AJ' },
    { word: 'slicked', word_class: 'AJ' },
    { word: 'slickness', word_class: 'N' }
  ],
  desiccated: [
    { word: 'desiccant', word_class: 'N' },
    { word: 'desiccate', word_class: 'V' },
    { word: 'desiccate', word_class: 'AJ' },
    { word: 'desiccated', word_class: 'AJ' },
    { word: 'desiccation', word_class: 'N' }
  ],
  woodworking: [
    { word: 'woodwork', word_class: 'N' },
    { word: 'woodworker', word_class: 'N' },
    { word: 'woodworking', word_class: 'N' }
  ],
  banquet: [
    { word: 'banquet', word_class: 'N' },
    { word: 'banquet', word_class: 'V' },
    { word: 'banqueting', word_class: 'N' }
  ],
  formed: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  health: [
    { word: 'health', word_class: 'N' },
    { word: 'healthful', word_class: 'AJ' },
    { word: 'healthfulness', word_class: 'N' }
  ],
  floored: [
    { word: 'floor', word_class: 'N' },
    { word: 'floor', word_class: 'V' },
    { word: 'floored', word_class: 'AJ' },
    { word: 'flooring', word_class: 'N' }
  ],
  fanaticism: [
    { word: 'fanaticism', word_class: 'N' },
    { word: 'fanaticize', word_class: 'V' },
    { word: 'fanaticization', word_class: 'N' }
  ],
  indecency: [
    { word: 'indecency', word_class: 'N' },
    { word: 'indecent', word_class: 'AJ' },
    { word: 'indecently', word_class: 'AV' }
  ],
  serialise: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  enslavement: [
    { word: 'enslave', word_class: 'V' },
    { word: 'enslaved', word_class: 'AJ' },
    { word: 'enslaving', word_class: 'N' },
    { word: 'enslavement', word_class: 'N' }
  ],
  syncretization: [
    { word: 'syncretise', word_class: 'V' },
    { word: 'syncretize', word_class: 'V' },
    { word: 'syncretisation', word_class: 'N' },
    { word: 'syncretization', word_class: 'N' }
  ],
  interment: [
    { word: 'inter', word_class: 'V' },
    { word: 'inter', word_class: 'AJ' },
    { word: 'interment', word_class: 'N' },
    { word: 'interred', word_class: 'AJ' }
  ],
  abduct: [
    { word: 'abduct', word_class: 'V' },
    { word: 'abductor', word_class: 'N' },
    { word: 'abducted', word_class: 'AJ' },
    { word: 'abduction', word_class: 'N' },
    { word: 'abducting', word_class: 'AJ' }
  ],
  servicing: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  gracefulness: [
    { word: 'grace', word_class: 'N' },
    { word: 'grace', word_class: 'V' },
    { word: 'graces', word_class: 'N' },
    { word: 'gracious', word_class: 'AJ' },
    { word: 'graceful', word_class: 'AJ' },
    { word: 'gracefulness', word_class: 'N' }
  ],
  convolve: [
    { word: 'convolve', word_class: 'V' },
    { word: 'convolute', word_class: 'V' },
    { word: 'convolute', word_class: 'AJ' },
    { word: 'convoluted', word_class: 'AJ' },
    { word: 'convolution', word_class: 'N' }
  ],
  reprimand: [
    { word: 'reprimand', word_class: 'N' },
    { word: 'reprimand', word_class: 'V' },
    { word: 'reprimanded', word_class: 'AJ' }
  ],
  vitals: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  perfumer: [
    { word: 'perfume', word_class: 'N' },
    { word: 'perfume', word_class: 'V' },
    { word: 'perfumer', word_class: 'N' },
    { word: 'perfumed', word_class: 'AJ' },
    { word: 'perfumery', word_class: 'N' },
    { word: 'perfumery', word_class: 'AJ' }
  ],
  frightening: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  golf: [
    { word: 'golf', word_class: 'N' },
    { word: 'golf', word_class: 'V' },
    { word: 'golfer', word_class: 'N' },
    { word: 'golfing', word_class: 'N' }
  ],
  desulphurize: [
    { word: 'desulphurise', word_class: 'V' },
    { word: 'desulphurize', word_class: 'V' },
    { word: 'desulphurisation', word_class: 'N' },
    { word: 'desulphurization', word_class: 'N' }
  ],
  mint: [
    { word: 'mint', word_class: 'N' },
    { word: 'mint', word_class: 'V' },
    { word: 'mint', word_class: 'AJ' },
    { word: 'mintage', word_class: 'N' }
  ],
  unlovely: [
    { word: 'unloved', word_class: 'AJ' },
    { word: 'unlovely', word_class: 'AJ' },
    { word: 'unloving', word_class: 'AJ' },
    { word: 'unlovable', word_class: 'AJ' }
  ],
  guzzling: [
    { word: 'guzzle', word_class: 'V' },
    { word: 'guzzler', word_class: 'N' },
    { word: 'guzzling', word_class: 'N' }
  ],
  abbreviation: [
    { word: 'abbreviate', word_class: 'V' },
    { word: 'abbreviated', word_class: 'AJ' },
    { word: 'abbreviation', word_class: 'N' }
  ],
  quarters: [
    { word: 'quarter', word_class: 'N' },
    { word: 'quarter', word_class: 'V' },
    { word: 'quarter', word_class: 'AJ' },
    { word: 'quarters', word_class: 'N' },
    { word: 'quartering', word_class: 'N' }
  ],
  alchemize: [
    { word: 'alchemic', word_class: 'AJ' },
    { word: 'alchemize', word_class: 'V' },
    { word: 'alchemical', word_class: 'AJ' },
    { word: 'alchemization', word_class: 'N' }
  ],
  often: [
    { word: 'often', word_class: 'AV' },
    { word: 'oftener', word_class: 'AV' },
    { word: 'oftenness', word_class: 'N' }
  ],
  gold: [
    { word: 'gold', word_class: 'N' },
    { word: 'gold', word_class: 'V' },
    { word: 'gold', word_class: 'AJ' },
    { word: 'golden', word_class: 'V' },
    { word: 'golden', word_class: 'AJ' },
    { word: 'gold-mining', word_class: 'N' }
  ],
  participate: [
    { word: 'participant', word_class: 'N' },
    { word: 'participate', word_class: 'V' },
    { word: 'participance', word_class: 'N' },
    { word: 'participation', word_class: 'N' },
    { word: 'participating', word_class: 'AJ' }
  ],
  deplumate: [
    { word: 'deplume', word_class: 'V' },
    { word: 'deplumate', word_class: 'V' },
    { word: 'deplumation', word_class: 'N' }
  ],
  overpowered: [
    { word: 'overpower', word_class: 'V' },
    { word: 'overpowered', word_class: 'AJ' },
    { word: 'overpowering', word_class: 'AJ' }
  ],
  roofed: [
    { word: 'roof', word_class: 'N' },
    { word: 'roof', word_class: 'V' },
    { word: 'roofer', word_class: 'N' },
    { word: 'roofed', word_class: 'AJ' },
    { word: 'roofing', word_class: 'N' }
  ],
  unimaginative: [
    { word: 'unimagined', word_class: 'AJ' },
    { word: 'unimaginable', word_class: 'AJ' },
    { word: 'unimaginably', word_class: 'AV' },
    { word: 'unimaginative', word_class: 'AJ' },
    { word: 'unimaginatively', word_class: 'AV' }
  ],
  sideway: [
    { word: 'sideway', word_class: 'AJ' },
    { word: 'sideway', word_class: 'AV' },
    { word: 'sideways', word_class: 'AJ' },
    { word: 'sideways', word_class: 'AV' }
  ],
  vertically: [
    { word: 'vertical', word_class: 'N' },
    { word: 'vertical', word_class: 'AJ' },
    { word: 'verticality', word_class: 'N' },
    { word: 'vertically', word_class: 'AV' },
    { word: 'verticalness', word_class: 'N' }
  ],
  compiling: [
    { word: 'compile', word_class: 'V' },
    { word: 'compiler', word_class: 'N' },
    { word: 'compiling', word_class: 'N' },
    { word: 'compilation', word_class: 'N' }
  ],
  lissomeness: [
    { word: 'lissom', word_class: 'AJ' },
    { word: 'lissome', word_class: 'AJ' },
    { word: 'lissomeness', word_class: 'N' }
  ],
  planting: [
    { word: 'plant', word_class: 'N' },
    { word: 'plant', word_class: 'V' },
    { word: 'planter', word_class: 'N' },
    { word: 'planted', word_class: 'AJ' },
    { word: 'planting', word_class: 'N' },
    { word: 'plantation', word_class: 'N' }
  ],
  archangel: [
    { word: 'archangel', word_class: 'N' },
    { word: 'archangelic', word_class: 'AJ' },
    { word: 'archangelical', word_class: 'AJ' }
  ],
  abnegate: [
    { word: 'abnegate', word_class: 'V' },
    { word: 'abnegator', word_class: 'N' },
    { word: 'abnegation', word_class: 'N' }
  ],
  receiving: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  pen: [
    { word: 'pen', word_class: 'V' },
    { word: 'pen', word_class: 'N' },
    { word: 'penman', word_class: 'N' },
    { word: 'penance', word_class: 'N' },
    { word: 'penance', word_class: 'V' },
    { word: 'penned', word_class: 'AJ' },
    { word: 'penning', word_class: 'N' }
  ],
  annoyer: [
    { word: 'annoy', word_class: 'V' },
    { word: 'annoyer', word_class: 'N' },
    { word: 'annoyed', word_class: 'AJ' },
    { word: 'annoying', word_class: 'N' },
    { word: 'annoyance', word_class: 'N' },
    { word: 'annoying', word_class: 'AJ' }
  ],
  intelligibility: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  ravaged: [
    { word: 'ravage', word_class: 'N' },
    { word: 'ravage', word_class: 'V' },
    { word: 'ravaged', word_class: 'AJ' },
    { word: 'ravaging', word_class: 'N' },
    { word: 'ravaging', word_class: 'AJ' },
    { word: 'ravagement', word_class: 'N' }
  ],
  endangerment: [
    { word: 'endanger', word_class: 'V' },
    { word: 'endangered', word_class: 'AJ' },
    { word: 'endangerment', word_class: 'N' }
  ],
  communisation: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  municipalization: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  white: [
    { word: 'white', word_class: 'N' },
    { word: 'white', word_class: 'V' },
    { word: 'white', word_class: 'AJ' },
    { word: 'whiting', word_class: 'N' },
    { word: 'whiteness', word_class: 'N' }
  ],
  simply: [
    { word: 'simple', word_class: 'N' },
    { word: 'simple', word_class: 'AJ' },
    { word: 'simply', word_class: 'AV' },
    { word: 'simpleness', word_class: 'N' }
  ],
  bowdlerism: [
    { word: 'bowdlerise', word_class: 'V' },
    { word: 'bowdlerism', word_class: 'N' },
    { word: 'bowdlerize', word_class: 'V' },
    { word: 'bowdlerisation', word_class: 'N' },
    { word: 'bowdlerization', word_class: 'N' }
  ],
  gospeler: [
    { word: 'gospel', word_class: 'N' },
    { word: 'gospels', word_class: 'N' },
    { word: 'gospeler', word_class: 'N' },
    { word: 'gospeller', word_class: 'N' }
  ],
  whir: [
    { word: 'whir', word_class: 'N' },
    { word: 'whir', word_class: 'V' },
    { word: 'whirring', word_class: 'N' },
    { word: 'whirring', word_class: 'AJ' }
  ],
  repeated: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  felonious: [
    { word: 'felony', word_class: 'N' },
    { word: 'felony', word_class: 'AJ' },
    { word: 'felonious', word_class: 'AJ' }
  ],
  penetrative: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  ancestral: [
    { word: 'ancestor', word_class: 'N' },
    { word: 'ancestral', word_class: 'AJ' },
    { word: 'ancestress', word_class: 'N' }
  ],
  fugal: [
    { word: 'fugu', word_class: 'N' },
    { word: 'fugue', word_class: 'N' },
    { word: 'fugal', word_class: 'AJ' },
    { word: 'fugally', word_class: 'AV' }
  ],
  ravishment: [
    { word: 'ravish', word_class: 'V' },
    { word: 'ravisher', word_class: 'N' },
    { word: 'ravishing', word_class: 'AJ' },
    { word: 'ravishment', word_class: 'N' }
  ],
  chilly: [
    { word: 'chilli', word_class: 'N' },
    { word: 'chilly', word_class: 'N' },
    { word: 'chilly', word_class: 'AJ' },
    { word: 'chilliness', word_class: 'N' }
  ],
  exchangeability: [
    { word: 'exchange', word_class: 'N' },
    { word: 'exchange', word_class: 'V' },
    { word: 'exchanger', word_class: 'N' },
    { word: 'exchanged', word_class: 'AJ' },
    { word: 'exchangeable', word_class: 'AJ' },
    { word: 'exchangeability', word_class: 'N' }
  ],
  occultation: [
    { word: 'occult', word_class: 'N' },
    { word: 'occult', word_class: 'V' },
    { word: 'occult', word_class: 'AJ' },
    { word: 'occultation', word_class: 'N' }
  ],
  movability: [
    { word: 'movable', word_class: 'AJ' },
    { word: 'movability', word_class: 'N' },
    { word: 'movableness', word_class: 'N' }
  ],
  rejuvenesce: [
    { word: 'rejuvenate', word_class: 'V' },
    { word: 'rejuvenesce', word_class: 'V' },
    { word: 'rejuvenation', word_class: 'N' }
  ],
  toughness: [
    { word: 'tough', word_class: 'N' },
    { word: 'tough', word_class: 'AJ' },
    { word: 'toughness', word_class: 'N' }
  ],
  singularise: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  vitriol: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  mapping: [{ word: 'map', word_class: 'N' }, { word: 'map', word_class: 'V' }, { word: 'mapping', word_class: 'N' }],
  contumaciously: [
    { word: 'contumacy', word_class: 'N' },
    { word: 'contumacious', word_class: 'AJ' },
    { word: 'contumaciously', word_class: 'AV' }
  ],
  unsuitably: [
    { word: 'unsuited', word_class: 'AJ' },
    { word: 'unsuitable', word_class: 'AJ' },
    { word: 'unsuitably', word_class: 'AV' },
    { word: 'unsuitability', word_class: 'N' },
    { word: 'unsuitableness', word_class: 'N' }
  ],
  ly: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  billed: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  malediction: [
    { word: 'maledict', word_class: 'N' },
    { word: 'maledict', word_class: 'V' },
    { word: 'maledict', word_class: 'AJ' },
    { word: 'malediction', word_class: 'N' }
  ],
  ageism: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  superscript: [
    { word: 'superscribe', word_class: 'V' },
    { word: 'superscript', word_class: 'N' },
    { word: 'superscript', word_class: 'AJ' },
    { word: 'superscription', word_class: 'N' }
  ],
  produce: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  jargon: [
    { word: 'jargon', word_class: 'N' },
    { word: 'jargon', word_class: 'V' },
    { word: 'jargonise', word_class: 'V' },
    { word: 'jargonize', word_class: 'V' },
    { word: 'jargonisation', word_class: 'N' },
    { word: 'jargonization', word_class: 'N' }
  ],
  yankee: [
    { word: 'yank', word_class: 'N' },
    { word: 'yank', word_class: 'V' },
    { word: 'yankee', word_class: 'N' },
    { word: 'yankee', word_class: 'AJ' }
  ],
  dispossession: [
    { word: 'dispossess', word_class: 'V' },
    { word: 'dispossessed', word_class: 'AJ' },
    { word: 'dispossession', word_class: 'N' }
  ],
  marvelment: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  suffocative: [
    { word: 'suffocate', word_class: 'V' },
    { word: 'suffocation', word_class: 'N' },
    { word: 'suffocating', word_class: 'AJ' },
    { word: 'suffocative', word_class: 'AJ' }
  ],
  expectant: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  smoked: [
    { word: 'smoke', word_class: 'N' },
    { word: 'smoke', word_class: 'V' },
    { word: 'smoker', word_class: 'N' },
    { word: 'smoky', word_class: 'AJ' },
    { word: 'smoked', word_class: 'AJ' },
    { word: 'smoking', word_class: 'N' },
    { word: 'smoking', word_class: 'AJ' }
  ],
  fencing: [
    { word: 'fence', word_class: 'N' },
    { word: 'fence', word_class: 'V' },
    { word: 'fencer', word_class: 'N' },
    { word: 'fencing', word_class: 'N' }
  ],
  fluorescence: [
    { word: 'fluoresce', word_class: 'V' },
    { word: 'fluorescence', word_class: 'N' },
    { word: 'fluorescent', word_class: 'AJ' }
  ],
  cicatrise: [
    { word: 'cicatrise', word_class: 'V' },
    { word: 'cicatrize', word_class: 'V' },
    { word: 'cicatrisation', word_class: 'N' },
    { word: 'cicatrization', word_class: 'N' }
  ],
  monied: [
    { word: 'money', word_class: 'N' },
    { word: 'monied', word_class: 'AJ' },
    { word: 'moneyed', word_class: 'AJ' }
  ],
  photograph: [
    { word: 'photograph', word_class: 'N' },
    { word: 'photograph', word_class: 'V' },
    { word: 'photographer', word_class: 'N' },
    { word: 'photographic', word_class: 'AJ' },
    { word: 'photographically', word_class: 'AV' }
  ],
  attunement: [
    { word: 'attune', word_class: 'V' },
    { word: 'attuned', word_class: 'AJ' },
    { word: 'attunement', word_class: 'N' }
  ],
  hesitate: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  periodical: [
    { word: 'period', word_class: 'N' },
    { word: 'periodic', word_class: 'AJ' },
    { word: 'periodical', word_class: 'N' },
    { word: 'periodical', word_class: 'AJ' },
    { word: 'periodicity', word_class: 'N' },
    { word: 'periodically', word_class: 'AV' }
  ],
  muslimism: [
    { word: 'muslim', word_class: 'N' },
    { word: 'muslim', word_class: 'AJ' },
    { word: 'muslimism', word_class: 'N' }
  ],
  hoydenism: [
    { word: 'hoyden', word_class: 'N' },
    { word: 'hoyden', word_class: 'AJ' },
    { word: 'hoydenism', word_class: 'N' }
  ],
  disputative: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  gratification: [
    { word: 'gratify', word_class: 'V' },
    { word: 'gratified', word_class: 'AJ' },
    { word: 'gratifying', word_class: 'AJ' },
    { word: 'gratification', word_class: 'N' }
  ],
  recently: [
    { word: 'recent', word_class: 'N' },
    { word: 'recent', word_class: 'AJ' },
    { word: 'recently', word_class: 'AV' },
    { word: 'recentness', word_class: 'N' }
  ],
  interpreter: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  ammoniation: [
    { word: 'ammoniate', word_class: 'V' },
    { word: 'ammoniated', word_class: 'AJ' },
    { word: 'ammoniation', word_class: 'N' }
  ],
  forestalment: [
    { word: 'forestall', word_class: 'V' },
    { word: 'forestalling', word_class: 'N' },
    { word: 'forestalment', word_class: 'N' },
    { word: 'forestallment', word_class: 'N' }
  ],
  bulge: [
    { word: 'bulge', word_class: 'N' },
    { word: 'bulge', word_class: 'V' },
    { word: 'bulging', word_class: 'AJ' }
  ],
  neighbor: [
    { word: 'neighbor', word_class: 'N' },
    { word: 'neighbor', word_class: 'V' },
    { word: 'neighbor', word_class: 'AJ' },
    { word: 'neighboring', word_class: 'AJ' }
  ],
  instrument: [
    { word: 'instrument', word_class: 'N' },
    { word: 'instrument', word_class: 'V' },
    { word: 'instrumental', word_class: 'N' },
    { word: 'instrumental', word_class: 'AJ' },
    { word: 'instrumentate', word_class: 'V' },
    { word: 'instrumentality', word_class: 'N' },
    { word: 'instrumentation', word_class: 'N' }
  ],
  deep: [
    { word: 'deep', word_class: 'N' },
    { word: 'deep', word_class: 'AJ' },
    { word: 'deep', word_class: 'AV' },
    { word: 'deepness', word_class: 'N' }
  ],
  mingle: [
    { word: 'mingle', word_class: 'V' },
    { word: 'mingling', word_class: 'N' },
    { word: 'mingling', word_class: 'AJ' }
  ],
  guiding: [
    { word: 'guide', word_class: 'N' },
    { word: 'guide', word_class: 'V' },
    { word: 'guided', word_class: 'AJ' },
    { word: 'guiding', word_class: 'N' },
    { word: 'guidance', word_class: 'N' },
    { word: 'guiding', word_class: 'AJ' }
  ],
  auctioneer: [
    { word: 'auction', word_class: 'N' },
    { word: 'auction', word_class: 'V' },
    { word: 'auctioneer', word_class: 'N' },
    { word: 'auctioneer', word_class: 'V' }
  ],
  magnetism: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  unawareness: [
    { word: 'unaware', word_class: 'AJ' },
    { word: 'unawares', word_class: 'AV' },
    { word: 'unawareness', word_class: 'N' }
  ],
  missed: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  academic: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  loudness: [
    { word: 'loud', word_class: 'AJ' },
    { word: 'loud', word_class: 'AV' },
    { word: 'loudness', word_class: 'N' }
  ],
  overwork: [
    { word: 'overwork', word_class: 'N' },
    { word: 'overwork', word_class: 'V' },
    { word: 'overworking', word_class: 'N' }
  ],
  gilded: [
    { word: 'gild', word_class: 'N' },
    { word: 'gild', word_class: 'V' },
    { word: 'gilder', word_class: 'N' },
    { word: 'gilded', word_class: 'AJ' },
    { word: 'gilding', word_class: 'N' }
  ],
  chipping: [
    { word: 'chip', word_class: 'N' },
    { word: 'chip', word_class: 'V' },
    { word: 'chipped', word_class: 'AJ' },
    { word: 'chipping', word_class: 'N' }
  ],
  aerosol: [
    { word: 'aerosol', word_class: 'N' },
    { word: 'aerosolize', word_class: 'V' },
    { word: 'aerosolized', word_class: 'AJ' }
  ],
  interaction: [
    { word: 'interact', word_class: 'V' },
    { word: 'interaction', word_class: 'N' },
    { word: 'interactive', word_class: 'AJ' },
    { word: 'interactional', word_class: 'AJ' }
  ],
  observe: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  arbitrement: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  turner: [
    { word: 'turn', word_class: 'N' },
    { word: 'turn', word_class: 'V' },
    { word: 'turner', word_class: 'N' },
    { word: 'turned', word_class: 'AJ' },
    { word: 'turnery', word_class: 'N' },
    { word: 'turning', word_class: 'N' },
    { word: 'turnery', word_class: 'AJ' },
    { word: 'turning', word_class: 'AJ' }
  ],
  developing: [
    { word: 'develop', word_class: 'V' },
    { word: 'developer', word_class: 'N' },
    { word: 'developed', word_class: 'AJ' },
    { word: 'developing', word_class: 'N' },
    { word: 'developing', word_class: 'AJ' },
    { word: 'development', word_class: 'N' }
  ],
  emancipated: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  explode: [
    { word: 'explode', word_class: 'V' },
    { word: 'explosion', word_class: 'N' },
    { word: 'explosive', word_class: 'N' },
    { word: 'exploding', word_class: 'AJ' },
    { word: 'explosive', word_class: 'AJ' },
    { word: 'explosively', word_class: 'AV' }
  ],
  burst: [
    { word: 'burst', word_class: 'N' },
    { word: 'burst', word_class: 'V' },
    { word: 'burst', word_class: 'AJ' },
    { word: 'burster', word_class: 'N' },
    { word: 'bursting', word_class: 'AJ' }
  ],
  synonymy: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  amerind: [
    { word: 'amerind', word_class: 'N' },
    { word: 'amerind', word_class: 'AJ' },
    { word: 'amerindic', word_class: 'AJ' }
  ],
  cursively: [
    { word: 'cursive', word_class: 'N' },
    { word: 'cursive', word_class: 'AJ' },
    { word: 'cursively', word_class: 'AV' }
  ],
  peacefulness: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  manor: [
    { word: 'man', word_class: 'V' },
    { word: 'man', word_class: 'N' },
    { word: 'manor', word_class: 'N' },
    { word: 'manned', word_class: 'AJ' },
    { word: 'manful', word_class: 'AJ' },
    { word: 'manfulness', word_class: 'N' }
  ],
  centralize: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  externalize: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  reciprocity: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  philhellenic: [
    { word: 'philhellene', word_class: 'N' },
    { word: 'philhellene', word_class: 'AJ' },
    { word: 'philhellenic', word_class: 'AJ' }
  ],
  prussian: [
    { word: 'prussian', word_class: 'N' },
    { word: 'prussian', word_class: 'AJ' },
    { word: 'prussianise', word_class: 'V' },
    { word: 'prussianize', word_class: 'V' },
    { word: 'prussianisation', word_class: 'N' },
    { word: 'prussianization', word_class: 'N' }
  ],
  indelicate: [
    { word: 'indelible', word_class: 'AJ' },
    { word: 'indelibly', word_class: 'AV' },
    { word: 'indelicate', word_class: 'AJ' }
  ],
  platitudinization: [
    { word: 'platitude', word_class: 'N' },
    { word: 'platitudinal', word_class: 'AJ' },
    { word: 'platitudinize', word_class: 'V' },
    { word: 'platitudinous', word_class: 'AJ' },
    { word: 'platitudinization', word_class: 'N' }
  ],
  publisher: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  supination: [
    { word: 'supine', word_class: 'AJ' },
    { word: 'supinate', word_class: 'V' },
    { word: 'supinely', word_class: 'AV' },
    { word: 'supination', word_class: 'N' }
  ],
  equipment: [
    { word: 'equip', word_class: 'V' },
    { word: 'equipment', word_class: 'N' },
    { word: 'equipped', word_class: 'AJ' },
    { word: 'equipping', word_class: 'N' }
  ],
  energize: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  programming: [
    { word: 'program', word_class: 'N' },
    { word: 'program', word_class: 'V' },
    { word: 'programming', word_class: 'N' }
  ],
  compulsivity: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  horrifying: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  purport: [
    { word: 'purport', word_class: 'N' },
    { word: 'purport', word_class: 'V' },
    { word: 'purported', word_class: 'AJ' }
  ],
  benefaction: [
    { word: 'benefit', word_class: 'N' },
    { word: 'benefit', word_class: 'V' },
    { word: 'benefaction', word_class: 'N' }
  ],
  anarchical: [
    { word: 'anarch', word_class: 'N' },
    { word: 'anarchic', word_class: 'AJ' },
    { word: 'anarchism', word_class: 'N' },
    { word: 'anarchical', word_class: 'AJ' },
    { word: 'anarchically', word_class: 'AV' }
  ],
  pigmentation: [
    { word: 'pig', word_class: 'N' },
    { word: 'pig', word_class: 'V' },
    { word: 'pigman', word_class: 'N' },
    { word: 'piggery', word_class: 'N' },
    { word: 'pigment', word_class: 'N' },
    { word: 'pigment', word_class: 'V' },
    { word: 'pigmentation', word_class: 'N' }
  ],
  fertility: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  linger: [
    { word: 'linger', word_class: 'V' },
    { word: 'lingerer', word_class: 'N' },
    { word: 'lingering', word_class: 'N' },
    { word: 'lingering', word_class: 'AJ' }
  ],
  unsoundness: [
    { word: 'unsound', word_class: 'AJ' },
    { word: 'unsounded', word_class: 'AJ' },
    { word: 'unsoundness', word_class: 'N' },
    { word: 'unsoundable', word_class: 'AJ' }
  ],
  penultimate: [
    { word: 'penultima', word_class: 'N' },
    { word: 'penultimate', word_class: 'N' },
    { word: 'penultimate', word_class: 'AJ' }
  ],
  desynchronizing: [
    { word: 'desynchronize', word_class: 'V' },
    { word: 'desynchronizing', word_class: 'N' },
    { word: 'desynchronization', word_class: 'N' }
  ],
  maladjustive: [
    { word: 'maladjusted', word_class: 'AJ' },
    { word: 'maladjustive', word_class: 'AJ' },
    { word: 'maladjustment', word_class: 'N' }
  ],
  mintage: [
    { word: 'mint', word_class: 'N' },
    { word: 'mint', word_class: 'V' },
    { word: 'mint', word_class: 'AJ' },
    { word: 'mintage', word_class: 'N' }
  ],
  scruples: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  retired: [
    { word: 'retire', word_class: 'N' },
    { word: 'retire', word_class: 'V' },
    { word: 'retiree', word_class: 'N' },
    { word: 'retired', word_class: 'AJ' },
    { word: 'retiring', word_class: 'AJ' },
    { word: 'retirement', word_class: 'N' }
  ],
  baldness: [
    { word: 'bald', word_class: 'V' },
    { word: 'bald', word_class: 'AJ' },
    { word: 'baldy', word_class: 'N' },
    { word: 'balding', word_class: 'AJ' },
    { word: 'baldness', word_class: 'N' }
  ],
  intone: [
    { word: 'intone', word_class: 'N' },
    { word: 'intone', word_class: 'V' },
    { word: 'intonate', word_class: 'V' },
    { word: 'intoned', word_class: 'AJ' },
    { word: 'intonation', word_class: 'N' }
  ],
  affectioned: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  diluting: [
    { word: 'dilute', word_class: 'N' },
    { word: 'dilute', word_class: 'V' },
    { word: 'dilute', word_class: 'AJ' },
    { word: 'dilutant', word_class: 'N' },
    { word: 'diluted', word_class: 'AJ' },
    { word: 'diluting', word_class: 'N' },
    { word: 'dilution', word_class: 'N' }
  ],
  frugal: [
    { word: 'frugal', word_class: 'AJ' },
    { word: 'frugality', word_class: 'N' },
    { word: 'frugally', word_class: 'AV' },
    { word: 'frugalness', word_class: 'N' }
  ],
  atonicity: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  fanatical: [
    { word: 'fanatic', word_class: 'N' },
    { word: 'fanatic', word_class: 'AJ' },
    { word: 'fanatical', word_class: 'AJ' },
    { word: 'fanatically', word_class: 'AV' }
  ],
  ranch: [
    { word: 'ranch', word_class: 'N' },
    { word: 'ranch', word_class: 'V' },
    { word: 'ranching', word_class: 'N' }
  ],
  expiry: [
    { word: 'expire', word_class: 'V' },
    { word: 'expiry', word_class: 'N' },
    { word: 'expired', word_class: 'AJ' },
    { word: 'expiring', word_class: 'AJ' },
    { word: 'expiration', word_class: 'N' }
  ],
  objective: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  bless: [
    { word: 'bless', word_class: 'V' },
    { word: 'blessed', word_class: 'AJ' },
    { word: 'blessing', word_class: 'N' }
  ],
  penetratively: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  albinism: [
    { word: 'albinal', word_class: 'AJ' },
    { word: 'albinic', word_class: 'AJ' },
    { word: 'albinism', word_class: 'N' }
  ],
  adept: [
    { word: 'adept', word_class: 'N' },
    { word: 'adept', word_class: 'AJ' },
    { word: 'adeptness', word_class: 'N' }
  ],
  superfluous: [
    { word: 'superfluity', word_class: 'N' },
    { word: 'superfluous', word_class: 'AJ' },
    { word: 'superfluously', word_class: 'AV' }
  ],
  dialect: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  sociobiological: [
    { word: 'sociobiology', word_class: 'N' },
    { word: 'sociobiologic', word_class: 'AJ' },
    { word: 'sociobiological', word_class: 'AJ' },
    { word: 'sociobiologically', word_class: 'AV' }
  ],
  reliable: [
    { word: 'reliable', word_class: 'AJ' },
    { word: 'reliably', word_class: 'AV' },
    { word: 'reliability', word_class: 'N' },
    { word: 'reliableness', word_class: 'N' }
  ],
  backwoods: [
    { word: 'backwood', word_class: 'N' },
    { word: 'backwoods', word_class: 'N' },
    { word: 'backwoods', word_class: 'AJ' }
  ],
  anatomize: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  guttering: [
    { word: 'gutter', word_class: 'N' },
    { word: 'gutter', word_class: 'V' },
    { word: 'guttering', word_class: 'AJ' }
  ],
  explosive: [
    { word: 'explode', word_class: 'V' },
    { word: 'explosion', word_class: 'N' },
    { word: 'explosive', word_class: 'N' },
    { word: 'exploding', word_class: 'AJ' },
    { word: 'explosive', word_class: 'AJ' },
    { word: 'explosively', word_class: 'AV' }
  ],
  pargeting: [
    { word: 'parget', word_class: 'N' },
    { word: 'parget', word_class: 'V' },
    { word: 'pargeting', word_class: 'N' }
  ],
  drudge: [
    { word: 'drudge', word_class: 'N' },
    { word: 'drudge', word_class: 'V' },
    { word: 'drudgery', word_class: 'N' },
    { word: 'drudging', word_class: 'AJ' }
  ],
  conceptive: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  trichinise: [
    { word: 'trichinise', word_class: 'V' },
    { word: 'trichinize', word_class: 'V' },
    { word: 'trichinisation', word_class: 'N' },
    { word: 'trichinization', word_class: 'N' }
  ],
  mortgagor: [
    { word: 'mortgage', word_class: 'N' },
    { word: 'mortgage', word_class: 'V' },
    { word: 'mortgagee', word_class: 'N' },
    { word: 'mortgagor', word_class: 'N' },
    { word: 'mortgaged', word_class: 'AJ' }
  ],
  bar: [
    { word: 'bar', word_class: 'N' },
    { word: 'bar', word_class: 'V' },
    { word: 'bars', word_class: 'N' },
    { word: 'barman', word_class: 'N' },
    { word: 'barred', word_class: 'AJ' },
    { word: 'barring', word_class: 'N' }
  ],
  acetylize: [
    { word: 'acetyl', word_class: 'N' },
    { word: 'acetylate', word_class: 'V' },
    { word: 'acetylic', word_class: 'AJ' },
    { word: 'acetylize', word_class: 'V' },
    { word: 'acetylation', word_class: 'N' }
  ],
  alluring: [
    { word: 'allure', word_class: 'N' },
    { word: 'allure', word_class: 'V' },
    { word: 'alluring', word_class: 'AJ' },
    { word: 'allurement', word_class: 'N' }
  ],
  ideographic: [
    { word: 'ideograph', word_class: 'N' },
    { word: 'ideographic', word_class: 'AJ' },
    { word: 'ideographically', word_class: 'AV' }
  ],
  rend: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  upwards: [
    { word: 'upward', word_class: 'AJ' },
    { word: 'upward', word_class: 'AV' },
    { word: 'upwards', word_class: 'AV' }
  ],
  drizzling: [
    { word: 'drizzle', word_class: 'N' },
    { word: 'drizzle', word_class: 'V' },
    { word: 'drizzly', word_class: 'AJ' },
    { word: 'drizzling', word_class: 'AJ' }
  ],
  porose: [
    { word: 'pore', word_class: 'N' },
    { word: 'pore', word_class: 'V' },
    { word: 'porose', word_class: 'AJ' },
    { word: 'porosity', word_class: 'N' }
  ],
  defamatory: [
    { word: 'defame', word_class: 'V' },
    { word: 'defamer', word_class: 'N' },
    { word: 'defamation', word_class: 'N' },
    { word: 'defamatory', word_class: 'AJ' }
  ],
  picking: [
    { word: 'pick', word_class: 'N' },
    { word: 'pick', word_class: 'V' },
    { word: 'picker', word_class: 'N' },
    { word: 'picking', word_class: 'N' }
  ],
  patronizing: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  wean: [{ word: 'wean', word_class: 'V' }, { word: 'weaned', word_class: 'AJ' }, { word: 'weaning', word_class: 'N' }],
  standardize: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  draining: [
    { word: 'drain', word_class: 'N' },
    { word: 'drain', word_class: 'V' },
    { word: 'drainage', word_class: 'N' },
    { word: 'drained', word_class: 'AJ' },
    { word: 'draining', word_class: 'AJ' }
  ],
  preserver: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  awaken: [
    { word: 'awaken', word_class: 'V' },
    { word: 'awakened', word_class: 'AJ' },
    { word: 'awakening', word_class: 'N' }
  ],
  quadrupling: [
    { word: 'quadruple', word_class: 'N' },
    { word: 'quadruple', word_class: 'V' },
    { word: 'quadruple', word_class: 'AJ' },
    { word: 'quadrupling', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'V' },
    { word: 'quadruplicate', word_class: 'AJ' },
    { word: 'quadruplication', word_class: 'N' }
  ],
  haunt: [
    { word: 'haunt', word_class: 'N' },
    { word: 'haunt', word_class: 'V' },
    { word: 'haunted', word_class: 'AJ' },
    { word: 'haunting', word_class: 'AJ' }
  ],
  suspicion: [
    { word: 'suspect', word_class: 'N' },
    { word: 'suspect', word_class: 'V' },
    { word: 'suspect', word_class: 'AJ' },
    { word: 'suspicion', word_class: 'N' },
    { word: 'suspected', word_class: 'AJ' }
  ],
  abstemious: [
    { word: 'abstemious', word_class: 'AJ' },
    { word: 'abstemiously', word_class: 'AV' },
    { word: 'abstemiousness', word_class: 'N' }
  ],
  catholicity: [
    { word: 'catholic', word_class: 'N' },
    { word: 'catholic', word_class: 'AJ' },
    { word: 'catholicity', word_class: 'N' }
  ],
  mercury: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  baptists: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  precis: [
    { word: 'precis', word_class: 'V' },
    { word: 'precis', word_class: 'N' },
    { word: 'precise', word_class: 'AJ' },
    { word: 'precision', word_class: 'N' },
    { word: 'precisely', word_class: 'AV' },
    { word: 'preciseness', word_class: 'N' }
  ],
  grandiose: [
    { word: 'grandiose', word_class: 'AJ' },
    { word: 'grandiosity', word_class: 'N' },
    { word: 'grandiosely', word_class: 'AV' }
  ],
  peptonization: [
    { word: 'peptone', word_class: 'N' },
    { word: 'peptonise', word_class: 'V' },
    { word: 'peptonize', word_class: 'V' },
    { word: 'peptonisation', word_class: 'N' },
    { word: 'peptonization', word_class: 'N' }
  ],
  explosively: [
    { word: 'explode', word_class: 'V' },
    { word: 'explosion', word_class: 'N' },
    { word: 'explosive', word_class: 'N' },
    { word: 'exploding', word_class: 'AJ' },
    { word: 'explosive', word_class: 'AJ' },
    { word: 'explosively', word_class: 'AV' }
  ],
  crinkle: [
    { word: 'crinkle', word_class: 'N' },
    { word: 'crinkle', word_class: 'V' },
    { word: 'crinkled', word_class: 'AJ' }
  ],
  scary: [
    { word: 'scare', word_class: 'N' },
    { word: 'scare', word_class: 'V' },
    { word: 'scary', word_class: 'AJ' },
    { word: 'scared', word_class: 'AJ' }
  ],
  decreasing: [
    { word: 'decrease', word_class: 'N' },
    { word: 'decrease', word_class: 'V' },
    { word: 'decreased', word_class: 'AJ' },
    { word: 'decreasing', word_class: 'AJ' }
  ],
  instancy: [
    { word: 'instance', word_class: 'N' },
    { word: 'instance', word_class: 'V' },
    { word: 'instancy', word_class: 'N' },
    { word: 'instantiate', word_class: 'V' },
    { word: 'instantiation', word_class: 'N' }
  ],
  genitive: [
    { word: 'genital', word_class: 'N' },
    { word: 'genital', word_class: 'AJ' },
    { word: 'genitals', word_class: 'N' },
    { word: 'genitive', word_class: 'N' },
    { word: 'genitive', word_class: 'AJ' }
  ],
  sane: [
    { word: 'sane', word_class: 'AJ' },
    { word: 'sanely', word_class: 'AV' },
    { word: 'saneness', word_class: 'N' }
  ],
  nationally: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  sextuple: [
    { word: 'sextuple', word_class: 'N' },
    { word: 'sextuple', word_class: 'AJ' },
    { word: 'sextuplicate', word_class: 'V' },
    { word: 'sextuplication', word_class: 'N' }
  ],
  venerable: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  mythologization: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  algebra: [
    { word: 'algebra', word_class: 'N' },
    { word: 'algebraic', word_class: 'AJ' },
    { word: 'algebraical', word_class: 'AJ' },
    { word: 'algebraically', word_class: 'AV' }
  ],
  inhibitor: [
    { word: 'inhibit', word_class: 'V' },
    { word: 'inhibitor', word_class: 'N' },
    { word: 'inhibited', word_class: 'AJ' },
    { word: 'inhibition', word_class: 'N' },
    { word: 'inhibiting', word_class: 'AJ' }
  ],
  circumstances: [
    { word: 'circumstance', word_class: 'N' },
    { word: 'circumstances', word_class: 'N' },
    { word: 'circumstantial', word_class: 'AJ' },
    { word: 'circumstantiate', word_class: 'V' },
    { word: 'circumstantially', word_class: 'AV' },
    { word: 'circumstantiation', word_class: 'N' }
  ],
  tinge: [
    { word: 'ting', word_class: 'N' },
    { word: 'ting', word_class: 'V' },
    { word: 'tinge', word_class: 'N' },
    { word: 'tinge', word_class: 'V' },
    { word: 'tinged', word_class: 'AJ' }
  ],
  bull: [
    { word: 'bull', word_class: 'N' },
    { word: 'bull', word_class: 'V' },
    { word: 'bull', word_class: 'AJ' },
    { word: 'bullion', word_class: 'N' }
  ],
  magnified: [
    { word: 'magnify', word_class: 'V' },
    { word: 'magnifier', word_class: 'N' },
    { word: 'magnified', word_class: 'AJ' },
    { word: 'magnification', word_class: 'N' }
  ],
  sanction: [
    { word: 'sanction', word_class: 'N' },
    { word: 'sanction', word_class: 'V' },
    { word: 'sanctioned', word_class: 'AJ' },
    { word: 'sanctioning', word_class: 'AJ' },
    { word: 'sanctionative', word_class: 'AJ' }
  ],
  boozy: [
    { word: 'booze', word_class: 'N' },
    { word: 'booze', word_class: 'V' },
    { word: 'boozer', word_class: 'N' },
    { word: 'boozy', word_class: 'AJ' },
    { word: 'boozing', word_class: 'N' }
  ],
  embellished: [
    { word: 'embellish', word_class: 'V' },
    { word: 'embellished', word_class: 'AJ' },
    { word: 'embellishment', word_class: 'N' }
  ],
  giver: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  phosphatisation: [
    { word: 'phosphate', word_class: 'N' },
    { word: 'phosphatise', word_class: 'V' },
    { word: 'phosphatize', word_class: 'V' },
    { word: 'phosphatisation', word_class: 'N' },
    { word: 'phosphatization', word_class: 'N' }
  ],
  quarantine: [
    { word: 'quarantine', word_class: 'N' },
    { word: 'quarantine', word_class: 'V' },
    { word: 'quarantined', word_class: 'AJ' }
  ],
  placement: [
    { word: 'place', word_class: 'N' },
    { word: 'place', word_class: 'V' },
    { word: 'placer', word_class: 'N' },
    { word: 'placed', word_class: 'AJ' },
    { word: 'placeman', word_class: 'N' },
    { word: 'placement', word_class: 'N' }
  ],
  razed: [
    { word: 'raze', word_class: 'V' },
    { word: 'razor', word_class: 'N' },
    { word: 'razor', word_class: 'V' },
    { word: 'razed', word_class: 'AJ' },
    { word: 'razing', word_class: 'N' }
  ],
  heighten: [
    { word: 'high', word_class: 'N' },
    { word: 'high', word_class: 'AJ' },
    { word: 'high', word_class: 'AV' },
    { word: 'heighten', word_class: 'V' },
    { word: 'highness', word_class: 'N' },
    { word: 'heightening', word_class: 'AJ' }
  ],
  meagre: [
    { word: 'meagre', word_class: 'AJ' },
    { word: 'meagrely', word_class: 'AV' },
    { word: 'meagreness', word_class: 'N' }
  ],
  assertively: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  woolgather: [
    { word: 'woolgather', word_class: 'V' },
    { word: 'woolgatherer', word_class: 'N' },
    { word: 'woolgathering', word_class: 'N' },
    { word: 'woolgathering', word_class: 'AJ' }
  ],
  bituminous: [
    { word: 'bituminise', word_class: 'V' },
    { word: 'bituminize', word_class: 'V' },
    { word: 'bituminous', word_class: 'AJ' },
    { word: 'bituminisation', word_class: 'N' },
    { word: 'bituminization', word_class: 'N' }
  ],
  save: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  tendency: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  sapid: [
    { word: 'sapid', word_class: 'AJ' },
    { word: 'sapidity', word_class: 'N' },
    { word: 'sapidness', word_class: 'N' }
  ],
  gowned: [{ word: 'gown', word_class: 'N' }, { word: 'gown', word_class: 'V' }, { word: 'gowned', word_class: 'AJ' }],
  amenities: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  egoistical: [
    { word: 'egoist', word_class: 'N' },
    { word: 'egoistic', word_class: 'AJ' },
    { word: 'egoistical', word_class: 'AJ' }
  ],
  regularized: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  horrified: [
    { word: 'horror', word_class: 'N' },
    { word: 'horrify', word_class: 'V' },
    { word: 'horrific', word_class: 'AJ' },
    { word: 'horrified', word_class: 'AJ' },
    { word: 'horrifying', word_class: 'AJ' },
    { word: 'horrification', word_class: 'N' }
  ],
  limiter: [
    { word: 'limit', word_class: 'N' },
    { word: 'limit', word_class: 'V' },
    { word: 'limiter', word_class: 'N' },
    { word: 'limited', word_class: 'AJ' },
    { word: 'limiting', word_class: 'N' },
    { word: 'limiting', word_class: 'AJ' },
    { word: 'limitation', word_class: 'N' }
  ],
  forborne: [
    { word: 'forbear', word_class: 'N' },
    { word: 'forbear', word_class: 'V' },
    { word: 'forborne', word_class: 'AJ' },
    { word: 'forbearance', word_class: 'N' },
    { word: 'forbearing', word_class: 'AJ' }
  ],
  cater: [
    { word: 'cater', word_class: 'V' },
    { word: 'caterer', word_class: 'N' },
    { word: 'catering', word_class: 'N' }
  ],
  enchain: [
    { word: 'enchain', word_class: 'V' },
    { word: 'enchained', word_class: 'AJ' },
    { word: 'enchainment', word_class: 'N' }
  ],
  rationed: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  cenobitic: [
    { word: 'cenobite', word_class: 'N' },
    { word: 'cenobitic', word_class: 'AJ' },
    { word: 'cenobitical', word_class: 'AJ' }
  ],
  authoritarian: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  deaminate: [
    { word: 'deaminate', word_class: 'V' },
    { word: 'deaminize', word_class: 'V' },
    { word: 'deamination', word_class: 'N' },
    { word: 'deaminisation', word_class: 'N' },
    { word: 'deaminization', word_class: 'N' }
  ],
  humanise: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  shyness: [
    { word: 'shy', word_class: 'N' },
    { word: 'shy', word_class: 'V' },
    { word: 'shy', word_class: 'AJ' },
    { word: 'shiite', word_class: 'N' },
    { word: 'shyness', word_class: 'N' }
  ],
  shamanize: [
    { word: 'shaman', word_class: 'N' },
    { word: 'shaman', word_class: 'AJ' },
    { word: 'shamanism', word_class: 'N' },
    { word: 'shamanize', word_class: 'V' }
  ],
  desperately: [
    { word: 'despair', word_class: 'N' },
    { word: 'despair', word_class: 'V' },
    { word: 'desperate', word_class: 'N' },
    { word: 'desperate', word_class: 'AJ' },
    { word: 'despairing', word_class: 'AJ' },
    { word: 'desperation', word_class: 'N' },
    { word: 'desperately', word_class: 'AV' }
  ],
  allegation: [
    { word: 'allege', word_class: 'V' },
    { word: 'alleged', word_class: 'AJ' },
    { word: 'allegation', word_class: 'N' },
    { word: 'allegement', word_class: 'N' },
    { word: 'allegeable', word_class: 'AJ' }
  ],
  strid: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  excellently: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  villain: [
    { word: 'villain', word_class: 'N' },
    { word: 'villainous', word_class: 'AJ' },
    { word: 'villainousness', word_class: 'N' }
  ],
  hybridizing: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  segregation: [
    { word: 'segregant', word_class: 'N' },
    { word: 'segregate', word_class: 'V' },
    { word: 'segregated', word_class: 'AJ' },
    { word: 'segregation', word_class: 'N' }
  ],
  contemporaneity: [
    { word: 'contemporize', word_class: 'V' },
    { word: 'contemporaneity', word_class: 'N' },
    { word: 'contemporaneous', word_class: 'AJ' },
    { word: 'contemporaneously', word_class: 'AV' },
    { word: 'contemporaneousness', word_class: 'N' }
  ],
  palatalise: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  vitriolise: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  destiny: [
    { word: 'destine', word_class: 'V' },
    { word: 'destiny', word_class: 'N' },
    { word: 'destined', word_class: 'AJ' },
    { word: 'destination', word_class: 'N' }
  ],
  redistribute: [
    { word: 'redistribute', word_class: 'V' },
    { word: 'redistributed', word_class: 'AJ' },
    { word: 'redistribution', word_class: 'N' }
  ],
  elicitation: [
    { word: 'elicit', word_class: 'V' },
    { word: 'elicited', word_class: 'AJ' },
    { word: 'elicitation', word_class: 'N' }
  ],
  apposition: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  enfranchisement: [
    { word: 'enfranchise', word_class: 'V' },
    { word: 'enfranchised', word_class: 'AJ' },
    { word: 'enfranchisement', word_class: 'N' }
  ],
  memorisation: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  deprecating: [
    { word: 'deprecate', word_class: 'V' },
    { word: 'deprecation', word_class: 'N' },
    { word: 'deprecating', word_class: 'AJ' },
    { word: 'deprecative', word_class: 'AJ' }
  ],
  'gold-mining': [
    { word: 'gold', word_class: 'N' },
    { word: 'gold', word_class: 'V' },
    { word: 'gold', word_class: 'AJ' },
    { word: 'golden', word_class: 'V' },
    { word: 'golden', word_class: 'AJ' },
    { word: 'gold-mining', word_class: 'N' }
  ],
  monetisation: [
    { word: 'monetise', word_class: 'V' },
    { word: 'monetize', word_class: 'V' },
    { word: 'monetisation', word_class: 'N' },
    { word: 'monetization', word_class: 'N' }
  ],
  elliptic: [
    { word: 'elliptic', word_class: 'AJ' },
    { word: 'elliptical', word_class: 'AJ' },
    { word: 'ellipticity', word_class: 'N' }
  ],
  approximable: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  demilitarisation: [
    { word: 'demilitarise', word_class: 'V' },
    { word: 'demilitarize', word_class: 'V' },
    { word: 'demilitarisation', word_class: 'N' },
    { word: 'demilitarization', word_class: 'N' }
  ],
  imperious: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  pailful: [
    { word: 'pail', word_class: 'N' },
    { word: 'pailful', word_class: 'N' },
    { word: 'pailful', word_class: 'AJ' }
  ],
  luxury: [
    { word: 'luxury', word_class: 'N' },
    { word: 'luxury', word_class: 'AJ' },
    { word: 'luxuriate', word_class: 'V' },
    { word: 'luxuriance', word_class: 'N' },
    { word: 'luxuriant', word_class: 'AJ' },
    { word: 'luxurious', word_class: 'AJ' },
    { word: 'luxuriation', word_class: 'N' },
    { word: 'luxuriously', word_class: 'AV' },
    { word: 'luxuriousness', word_class: 'N' }
  ],
  falling: [
    { word: 'fall', word_class: 'N' },
    { word: 'fall', word_class: 'V' },
    { word: 'falls', word_class: 'N' },
    { word: 'fallal', word_class: 'N' },
    { word: 'fallen', word_class: 'AJ' },
    { word: 'falling', word_class: 'N' },
    { word: 'falling', word_class: 'AJ' }
  ],
  mercerization: [
    { word: 'mercer', word_class: 'N' },
    { word: 'mercerise', word_class: 'V' },
    { word: 'mercerize', word_class: 'V' },
    { word: 'mercerisation', word_class: 'N' },
    { word: 'mercerization', word_class: 'N' }
  ],
  angling: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  inventively: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  torture: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  kidnap: [
    { word: 'kidnap', word_class: 'V' },
    { word: 'kidnaper', word_class: 'N' },
    { word: 'kidnaped', word_class: 'AJ' },
    { word: 'kidnapped', word_class: 'AJ' },
    { word: 'kidnapping', word_class: 'N' }
  ],
  inexpediency: [
    { word: 'inexpedience', word_class: 'N' },
    { word: 'inexpediency', word_class: 'N' },
    { word: 'inexpedient', word_class: 'AJ' },
    { word: 'inexpediently', word_class: 'AV' }
  ],
  wintry: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  polygonal: [
    { word: 'polygon', word_class: 'N' },
    { word: 'polygonal', word_class: 'AJ' },
    { word: 'polygonally', word_class: 'AV' }
  ],
  partitioned: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  depilate: [
    { word: 'depilate', word_class: 'V' },
    { word: 'depilous', word_class: 'AJ' },
    { word: 'depilation', word_class: 'N' }
  ],
  wrong: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  grazing: [
    { word: 'graze', word_class: 'N' },
    { word: 'graze', word_class: 'V' },
    { word: 'grazed', word_class: 'AJ' },
    { word: 'grazing', word_class: 'N' }
  ],
  incorporate: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  seraph: [
    { word: 'seraph', word_class: 'N' },
    { word: 'seraphic', word_class: 'AJ' },
    { word: 'seraphical', word_class: 'AJ' }
  ],
  crosshatch: [
    { word: 'crosshatch', word_class: 'N' },
    { word: 'crosshatch', word_class: 'V' },
    { word: 'crosshatched', word_class: 'AJ' },
    { word: 'crosshatching', word_class: 'N' }
  ],
  reinforcement: [
    { word: 'reinforce', word_class: 'V' },
    { word: 'reinforcer', word_class: 'N' },
    { word: 'reinforced', word_class: 'AJ' },
    { word: 'reinforcement', word_class: 'N' }
  ],
  thermal: [
    { word: 'thermal', word_class: 'N' },
    { word: 'thermal', word_class: 'AJ' },
    { word: 'thermally', word_class: 'AV' }
  ],
  emit: [
    { word: 'emit', word_class: 'V' },
    { word: 'emission', word_class: 'N' },
    { word: 'emitting', word_class: 'AJ' }
  ],
  predestinate: [
    { word: 'predestine', word_class: 'V' },
    { word: 'predestinate', word_class: 'V' },
    { word: 'predestined', word_class: 'AJ' },
    { word: 'predestinate', word_class: 'AJ' },
    { word: 'predestination', word_class: 'N' }
  ],
  ragged: [
    { word: 'rag', word_class: 'N' },
    { word: 'rag', word_class: 'V' },
    { word: 'ragged', word_class: 'AJ' },
    { word: 'raggedy', word_class: 'AJ' }
  ],
  etiological: [
    { word: 'etiology', word_class: 'N' },
    { word: 'etiologic', word_class: 'AJ' },
    { word: 'etiological', word_class: 'AJ' }
  ],
  enthusiastically: [
    { word: 'enthusiast', word_class: 'N' },
    { word: 'enthusiastic', word_class: 'AJ' },
    { word: 'enthusiastically', word_class: 'AV' }
  ],
  unobtrusiveness: [
    { word: 'unobtrusive', word_class: 'AJ' },
    { word: 'unobtrusively', word_class: 'AV' },
    { word: 'unobtrusiveness', word_class: 'N' }
  ],
  amendable: [
    { word: 'amend', word_class: 'V' },
    { word: 'amends', word_class: 'N' },
    { word: 'amended', word_class: 'AJ' },
    { word: 'amendment', word_class: 'N' },
    { word: 'amendable', word_class: 'AJ' }
  ],
  hatter: [
    { word: 'hat', word_class: 'N' },
    { word: 'hat', word_class: 'V' },
    { word: 'hatful', word_class: 'N' },
    { word: 'hatter', word_class: 'N' },
    { word: 'hatful', word_class: 'AJ' },
    { word: 'hatted', word_class: 'AJ' }
  ],
  vented: [
    { word: 'vent', word_class: 'N' },
    { word: 'vent', word_class: 'V' },
    { word: 'venter', word_class: 'N' },
    { word: 'vented', word_class: 'AJ' },
    { word: 'venting', word_class: 'N' }
  ],
  counterpoise: [
    { word: 'counterpoise', word_class: 'N' },
    { word: 'counterpoise', word_class: 'V' },
    { word: 'counterpoised', word_class: 'AJ' }
  ],
  immobilise: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  auspices: [
    { word: 'auspice', word_class: 'N' },
    { word: 'auspices', word_class: 'N' },
    { word: 'auspicate', word_class: 'V' }
  ],
  etymologize: [
    { word: 'etymology', word_class: 'N' },
    { word: 'etymologize', word_class: 'V' },
    { word: 'etymological', word_class: 'AJ' },
    { word: 'etymologizing', word_class: 'N' },
    { word: 'etymologization', word_class: 'N' }
  ],
  grill: [
    { word: 'grill', word_class: 'N' },
    { word: 'grill', word_class: 'V' },
    { word: 'grille', word_class: 'N' },
    { word: 'grilled', word_class: 'AJ' },
    { word: 'grilling', word_class: 'N' }
  ],
  natured: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  robed: [{ word: 'robe', word_class: 'N' }, { word: 'robe', word_class: 'V' }, { word: 'robed', word_class: 'AJ' }],
  luxemburger: [
    { word: 'luxemburg', word_class: 'N' },
    { word: 'luxemburger', word_class: 'N' },
    { word: 'luxemburger', word_class: 'AJ' }
  ],
  causality: [
    { word: 'causal', word_class: 'AJ' },
    { word: 'causality', word_class: 'N' },
    { word: 'causally', word_class: 'AV' }
  ],
  latinize: [
    { word: 'latin', word_class: 'N' },
    { word: 'latin', word_class: 'AJ' },
    { word: 'latinize', word_class: 'V' },
    { word: 'latinist', word_class: 'N' },
    { word: 'latinate', word_class: 'AJ' },
    { word: 'latinization', word_class: 'N' }
  ],
  auscultation: [
    { word: 'auscultate', word_class: 'V' },
    { word: 'ausultation', word_class: 'N' },
    { word: 'auscultation', word_class: 'N' }
  ],
  tipped: [
    { word: 'tip', word_class: 'N' },
    { word: 'tip', word_class: 'V' },
    { word: 'tippy', word_class: 'AJ' },
    { word: 'tipped', word_class: 'AJ' }
  ],
  comment: [
    { word: 'comment', word_class: 'N' },
    { word: 'comment', word_class: 'V' },
    { word: 'commentary', word_class: 'N' },
    { word: 'commentate', word_class: 'V' },
    { word: 'commentator', word_class: 'N' },
    { word: 'commentation', word_class: 'N' }
  ],
  erotic: [
    { word: 'erotic', word_class: 'AJ' },
    { word: 'erotism', word_class: 'N' },
    { word: 'erotically', word_class: 'AV' }
  ],
  euphonization: [
    { word: 'euphonic', word_class: 'AJ' },
    { word: 'euphonize', word_class: 'V' },
    { word: 'euphonous', word_class: 'AJ' },
    { word: 'euphonical', word_class: 'AJ' },
    { word: 'euphonization', word_class: 'N' }
  ],
  unscrupulous: [
    { word: 'unscrupulous', word_class: 'AJ' },
    { word: 'unscrupulously', word_class: 'AV' },
    { word: 'unscrupulousness', word_class: 'N' }
  ],
  disarmer: [
    { word: 'disarm', word_class: 'V' },
    { word: 'disarmer', word_class: 'N' },
    { word: 'disarming', word_class: 'N' },
    { word: 'disarming', word_class: 'AJ' },
    { word: 'disarmament', word_class: 'N' }
  ],
  pair: [
    { word: 'pair', word_class: 'N' },
    { word: 'pair', word_class: 'V' },
    { word: 'paired', word_class: 'AJ' },
    { word: 'pairing', word_class: 'N' }
  ],
  slinger: [
    { word: 'sling', word_class: 'N' },
    { word: 'sling', word_class: 'V' },
    { word: 'slinger', word_class: 'N' },
    { word: 'slinging', word_class: 'N' }
  ],
  nominating: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  androgenic: [
    { word: 'androgen', word_class: 'N' },
    { word: 'androgenic', word_class: 'AJ' },
    { word: 'androgenous', word_class: 'AJ' }
  ],
  sparing: [
    { word: 'spare', word_class: 'N' },
    { word: 'spare', word_class: 'V' },
    { word: 'spare', word_class: 'AJ' },
    { word: 'sparely', word_class: 'AV' },
    { word: 'sparing', word_class: 'AJ' },
    { word: 'spareness', word_class: 'N' }
  ],
  disarm: [
    { word: 'disarm', word_class: 'V' },
    { word: 'disarmer', word_class: 'N' },
    { word: 'disarming', word_class: 'N' },
    { word: 'disarming', word_class: 'AJ' },
    { word: 'disarmament', word_class: 'N' }
  ],
  dodger: [
    { word: 'dodge', word_class: 'N' },
    { word: 'dodge', word_class: 'V' },
    { word: 'dodger', word_class: 'N' },
    { word: 'dodging', word_class: 'N' }
  ],
  suppress: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  formation: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  bluffer: [
    { word: 'bluff', word_class: 'N' },
    { word: 'bluff', word_class: 'V' },
    { word: 'bluff', word_class: 'AJ' },
    { word: 'bluffer', word_class: 'N' },
    { word: 'bluffness', word_class: 'N' }
  ],
  achromatize: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  axiomatical: [
    { word: 'axiom', word_class: 'N' },
    { word: 'axiomatic', word_class: 'AJ' },
    { word: 'axiomatical', word_class: 'AJ' },
    { word: 'axiomatically', word_class: 'AV' }
  ],
  inconsistency: [
    { word: 'inconsistency', word_class: 'N' },
    { word: 'inconsistent', word_class: 'AJ' },
    { word: 'inconsistently', word_class: 'AV' }
  ],
  fruitfulness: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  unitarian: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  unclearness: [
    { word: 'unclear', word_class: 'AJ' },
    { word: 'uncleared', word_class: 'AJ' },
    { word: 'unclearness', word_class: 'N' }
  ],
  adduction: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  reaction: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  distressing: [
    { word: 'distress', word_class: 'N' },
    { word: 'distress', word_class: 'V' },
    { word: 'distressed', word_class: 'AJ' },
    { word: 'distressful', word_class: 'AJ' },
    { word: 'distressing', word_class: 'AJ' },
    { word: 'distressfulness', word_class: 'N' }
  ],
  activeness: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  sass: [{ word: 'sass', word_class: 'N' }, { word: 'sass', word_class: 'V' }, { word: 'sassing', word_class: 'N' }],
  condenser: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  caricature: [
    { word: 'caricature', word_class: 'N' },
    { word: 'caricature', word_class: 'V' },
    { word: 'caricaturist', word_class: 'N' }
  ],
  whirl: [
    { word: 'whirl', word_class: 'N' },
    { word: 'whirl', word_class: 'V' },
    { word: 'whirling', word_class: 'N' },
    { word: 'whirling', word_class: 'AJ' }
  ],
  tactful: [
    { word: 'tact', word_class: 'N' },
    { word: 'tactful', word_class: 'AJ' },
    { word: 'tactfulness', word_class: 'N' }
  ],
  romany: [
    { word: 'romany', word_class: 'N' },
    { word: 'romani', word_class: 'AJ' },
    { word: 'romany', word_class: 'AJ' }
  ],
  revocation: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  struggle: [
    { word: 'struggle', word_class: 'N' },
    { word: 'struggle', word_class: 'V' },
    { word: 'struggling', word_class: 'AJ' }
  ],
  transfigure: [
    { word: 'transfigure', word_class: 'N' },
    { word: 'transfigure', word_class: 'V' },
    { word: 'transfiguration', word_class: 'N' },
    { word: 'transfigurement', word_class: 'N' }
  ],
  contriteness: [
    { word: 'contrite', word_class: 'AJ' },
    { word: 'contrition', word_class: 'N' },
    { word: 'contritely', word_class: 'AV' },
    { word: 'contriteness', word_class: 'N' }
  ],
  rookie: [
    { word: 'rooky', word_class: 'N' },
    { word: 'rookie', word_class: 'N' },
    { word: 'rooky', word_class: 'AJ' }
  ],
  frolicked: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  trying: [
    { word: 'try', word_class: 'N' },
    { word: 'try', word_class: 'V' },
    { word: 'trial', word_class: 'N' },
    { word: 'trier', word_class: 'N' },
    { word: 'tryst', word_class: 'N' },
    { word: 'trial', word_class: 'AJ' },
    { word: 'trying', word_class: 'AJ' }
  ],
  basidiomycetes: [
    { word: 'basidiomycete', word_class: 'N' },
    { word: 'basidiomycetes', word_class: 'N' },
    { word: 'basidiomycetous', word_class: 'AJ' }
  ],
  secrete: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  horticultural: [
    { word: 'horticulture', word_class: 'N' },
    { word: 'horticultural', word_class: 'AJ' },
    { word: 'horticulturally', word_class: 'AV' }
  ],
  evocation: [
    { word: 'evoke', word_class: 'V' },
    { word: 'evoked', word_class: 'AJ' },
    { word: 'evocable', word_class: 'AJ' },
    { word: 'evocation', word_class: 'N' },
    { word: 'evocative', word_class: 'AJ' }
  ],
  tumidity: [
    { word: 'tumid', word_class: 'AJ' },
    { word: 'tumidity', word_class: 'N' },
    { word: 'tumidness', word_class: 'N' }
  ],
  bumbler: [
    { word: 'bumble', word_class: 'V' },
    { word: 'bumbler', word_class: 'N' },
    { word: 'bumbling', word_class: 'AJ' }
  ],
  agglutinative: [
    { word: 'agglutinate', word_class: 'V' },
    { word: 'agglutinate', word_class: 'AJ' },
    { word: 'agglutination', word_class: 'N' },
    { word: 'agglutinative', word_class: 'AJ' }
  ],
  permissibly: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  scrap: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  priced: [
    { word: 'price', word_class: 'N' },
    { word: 'price', word_class: 'V' },
    { word: 'pricy', word_class: 'AJ' },
    { word: 'priced', word_class: 'AJ' },
    { word: 'pricing', word_class: 'N' }
  ],
  universalise: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  romanticize: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  chagrined: [
    { word: 'chagrin', word_class: 'N' },
    { word: 'chagrin', word_class: 'V' },
    { word: 'chagrined', word_class: 'AJ' }
  ],
  sole: [
    { word: 'sole', word_class: 'N' },
    { word: 'sole', word_class: 'V' },
    { word: 'sole', word_class: 'AJ' },
    { word: 'soled', word_class: 'AJ' },
    { word: 'solely', word_class: 'AV' }
  ],
  dread: [
    { word: 'dread', word_class: 'N' },
    { word: 'dread', word_class: 'V' },
    { word: 'dread', word_class: 'AJ' },
    { word: 'dreaded', word_class: 'AJ' },
    { word: 'dreadful', word_class: 'AJ' },
    { word: 'dreadfulness', word_class: 'N' }
  ],
  recording: [
    { word: 'record', word_class: 'N' },
    { word: 'record', word_class: 'V' },
    { word: 'record', word_class: 'AJ' },
    { word: 'recorder', word_class: 'N' },
    { word: 'recording', word_class: 'N' },
    { word: 'recorded', word_class: 'AJ' },
    { word: 'recordation', word_class: 'N' }
  ],
  ironist: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  capable: [
    { word: 'cabable', word_class: 'AJ' },
    { word: 'capable', word_class: 'AJ' },
    { word: 'capably', word_class: 'AV' },
    { word: 'capability', word_class: 'N' },
    { word: 'capableness', word_class: 'N' }
  ],
  dig: [
    { word: 'dig', word_class: 'N' },
    { word: 'dig', word_class: 'V' },
    { word: 'digs', word_class: 'N' },
    { word: 'digging', word_class: 'N' },
    { word: 'diggings', word_class: 'N' }
  ],
  phlegmatically: [
    { word: 'phlegmatic', word_class: 'AJ' },
    { word: 'phlegmatical', word_class: 'AJ' },
    { word: 'phlegmatically', word_class: 'AV' }
  ],
  meditativeness: [
    { word: 'meditate', word_class: 'V' },
    { word: 'meditation', word_class: 'N' },
    { word: 'meditative', word_class: 'AJ' },
    { word: 'meditatively', word_class: 'AV' },
    { word: 'meditativeness', word_class: 'N' }
  ],
  return: [
    { word: 'return', word_class: 'N' },
    { word: 'return', word_class: 'V' },
    { word: 'returning', word_class: 'AJ' },
    { word: 'returnable', word_class: 'AJ' }
  ],
  user: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  enterpriser: [
    { word: 'enterprise', word_class: 'N' },
    { word: 'enterpriser', word_class: 'N' },
    { word: 'enterprising', word_class: 'AJ' }
  ],
  metabolic: [
    { word: 'metabolic', word_class: 'AJ' },
    { word: 'metabolism', word_class: 'N' },
    { word: 'metabolize', word_class: 'V' },
    { word: 'metabolous', word_class: 'AJ' },
    { word: 'metabolization', word_class: 'N' }
  ],
  grandiloquence: [
    { word: 'grandiloquence', word_class: 'N' },
    { word: 'grandiloquent', word_class: 'AJ' },
    { word: 'grandiloquently', word_class: 'AV' }
  ],
  marriage: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  archival: [
    { word: 'archive', word_class: 'N' },
    { word: 'archive', word_class: 'V' },
    { word: 'archive', word_class: 'AJ' },
    { word: 'archives', word_class: 'N' },
    { word: 'archival', word_class: 'AJ' }
  ],
  gravel: [
    { word: 'gravel', word_class: 'N' },
    { word: 'gravel', word_class: 'V' },
    { word: 'gravel', word_class: 'AJ' },
    { word: 'graveled', word_class: 'AJ' }
  ],
  marred: [
    { word: 'mar', word_class: 'N' },
    { word: 'mar', word_class: 'V' },
    { word: 'mars', word_class: 'N' },
    { word: 'marred', word_class: 'AJ' }
  ],
  earner: [
    { word: 'earn', word_class: 'V' },
    { word: 'earner', word_class: 'N' },
    { word: 'earned', word_class: 'AJ' },
    { word: 'earning', word_class: 'N' },
    { word: 'earnings', word_class: 'N' }
  ],
  cloud: [
    { word: 'cloud', word_class: 'N' },
    { word: 'cloud', word_class: 'V' },
    { word: 'clouded', word_class: 'V' },
    { word: 'clouded', word_class: 'AJ' },
    { word: 'clouding', word_class: 'N' }
  ],
  mug: [
    { word: 'mug', word_class: 'N' },
    { word: 'mug', word_class: 'V' },
    { word: 'mugful', word_class: 'N' },
    { word: 'mugging', word_class: 'N' }
  ],
  nature: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  flammability: [
    { word: 'flame', word_class: 'N' },
    { word: 'flame', word_class: 'V' },
    { word: 'flaming', word_class: 'N' },
    { word: 'flaming', word_class: 'AJ' },
    { word: 'flammable', word_class: 'AJ' },
    { word: 'flammability', word_class: 'N' },
    { word: 'flammability', word_class: 'AJ' }
  ],
  diplomatic: [
    { word: 'diplomat', word_class: 'N' },
    { word: 'diplomatic', word_class: 'AJ' },
    { word: 'diplomatical', word_class: 'AJ' },
    { word: 'diplomatically', word_class: 'AV' }
  ],
  spats: [{ word: 'spat', word_class: 'N' }, { word: 'spat', word_class: 'V' }, { word: 'spats', word_class: 'N' }],
  shirk: [
    { word: 'shirk', word_class: 'V' },
    { word: 'shirker', word_class: 'N' },
    { word: 'shirking', word_class: 'N' }
  ],
  deify: [
    { word: 'deify', word_class: 'V' },
    { word: 'deific', word_class: 'AJ' },
    { word: 'deification', word_class: 'N' }
  ],
  screeching: [
    { word: 'screech', word_class: 'N' },
    { word: 'screech', word_class: 'V' },
    { word: 'screeching', word_class: 'N' },
    { word: 'screeching', word_class: 'AJ' }
  ],
  unionisation: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  permanency: [
    { word: 'permanent', word_class: 'N' },
    { word: 'permanence', word_class: 'N' },
    { word: 'permanency', word_class: 'N' },
    { word: 'permanent', word_class: 'AJ' },
    { word: 'permanently', word_class: 'AV' }
  ],
  maximation: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  judicious: [
    { word: 'judicial', word_class: 'AJ' },
    { word: 'judicious', word_class: 'AJ' },
    { word: 'judicially', word_class: 'AV' },
    { word: 'judiciously', word_class: 'AV' },
    { word: 'judiciousness', word_class: 'N' }
  ],
  conciliate: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  confused: [
    { word: 'confuse', word_class: 'V' },
    { word: 'confused', word_class: 'AJ' },
    { word: 'confusion', word_class: 'N' },
    { word: 'confusing', word_class: 'AJ' },
    { word: 'confusable', word_class: 'AJ' }
  ],
  sensualism: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  haggling: [
    { word: 'haggle', word_class: 'N' },
    { word: 'haggle', word_class: 'V' },
    { word: 'haggling', word_class: 'N' }
  ],
  titillated: [
    { word: 'titillate', word_class: 'V' },
    { word: 'titillated', word_class: 'AJ' },
    { word: 'titillation', word_class: 'N' },
    { word: 'titillating', word_class: 'AJ' }
  ],
  conclusively: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  deduction: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  publishable: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  forjudge: [
    { word: 'forjudge', word_class: 'V' },
    { word: 'forejudge', word_class: 'V' },
    { word: 'forjudgment', word_class: 'N' },
    { word: 'forejudgment', word_class: 'N' },
    { word: 'forejudgement', word_class: 'N' }
  ],
  renovated: [
    { word: 'renovate', word_class: 'V' },
    { word: 'renovator', word_class: 'N' },
    { word: 'renovated', word_class: 'AJ' },
    { word: 'renovation', word_class: 'N' }
  ],
  perilously: [
    { word: 'peril', word_class: 'N' },
    { word: 'peril', word_class: 'V' },
    { word: 'perilous', word_class: 'AJ' },
    { word: 'perilously', word_class: 'AV' },
    { word: 'perilousness', word_class: 'N' }
  ],
  passiveness: [
    { word: 'passive', word_class: 'N' },
    { word: 'passive', word_class: 'AJ' },
    { word: 'passivism', word_class: 'N' },
    { word: 'passivity', word_class: 'N' },
    { word: 'passively', word_class: 'AV' },
    { word: 'passiveness', word_class: 'N' }
  ],
  preoccupied: [
    { word: 'preoccupy', word_class: 'V' },
    { word: 'preoccupancy', word_class: 'N' },
    { word: 'preoccupied', word_class: 'AJ' },
    { word: 'preoccupation', word_class: 'N' }
  ],
  ruler: [
    { word: 'rule', word_class: 'N' },
    { word: 'rule', word_class: 'V' },
    { word: 'ruler', word_class: 'N' },
    { word: 'ruled', word_class: 'AJ' },
    { word: 'ruling', word_class: 'N' },
    { word: 'ruling', word_class: 'AJ' }
  ],
  proximate: [
    { word: 'proximal', word_class: 'AJ' },
    { word: 'proximity', word_class: 'N' },
    { word: 'proximate', word_class: 'AJ' }
  ],
  impolitic: [
    { word: 'impolite', word_class: 'AJ' },
    { word: 'impolitic', word_class: 'AJ' },
    { word: 'impolitely', word_class: 'AV' },
    { word: 'impoliteness', word_class: 'N' }
  ],
  rente: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  paling: [
    { word: 'pale', word_class: 'N' },
    { word: 'pale', word_class: 'V' },
    { word: 'pale', word_class: 'AJ' },
    { word: 'paling', word_class: 'N' },
    { word: 'palely', word_class: 'AV' },
    { word: 'paling', word_class: 'AJ' },
    { word: 'paleness', word_class: 'N' }
  ],
  bellow: [
    { word: 'bellow', word_class: 'N' },
    { word: 'bellow', word_class: 'V' },
    { word: 'bellows', word_class: 'N' },
    { word: 'bellowing', word_class: 'N' }
  ],
  extravagate: [
    { word: 'extravagate', word_class: 'V' },
    { word: 'extravagance', word_class: 'N' },
    { word: 'extravagant', word_class: 'AJ' },
    { word: 'extravagation', word_class: 'N' }
  ],
  solar: [
    { word: 'solar', word_class: 'AJ' },
    { word: 'solarise', word_class: 'V' },
    { word: 'solarize', word_class: 'V' },
    { word: 'solarisation', word_class: 'N' },
    { word: 'solarization', word_class: 'N' }
  ],
  belonging: [
    { word: 'belong', word_class: 'V' },
    { word: 'belonging', word_class: 'N' },
    { word: 'belongings', word_class: 'N' }
  ],
  majors: [
    { word: 'major', word_class: 'N' },
    { word: 'major', word_class: 'V' },
    { word: 'major', word_class: 'AJ' },
    { word: 'majors', word_class: 'N' },
    { word: 'majority', word_class: 'N' }
  ],
  rectified: [
    { word: 'rectify', word_class: 'V' },
    { word: 'rectifier', word_class: 'N' },
    { word: 'rectified', word_class: 'AJ' },
    { word: 'rectifiable', word_class: 'AJ' },
    { word: 'rectification', word_class: 'N' }
  ],
  bowleg: [
    { word: 'bowleg', word_class: 'N' },
    { word: 'bowleg', word_class: 'AJ' },
    { word: 'bowlegged', word_class: 'AJ' }
  ],
  pale: [
    { word: 'pale', word_class: 'N' },
    { word: 'pale', word_class: 'V' },
    { word: 'pale', word_class: 'AJ' },
    { word: 'paling', word_class: 'N' },
    { word: 'palely', word_class: 'AV' },
    { word: 'paling', word_class: 'AJ' },
    { word: 'paleness', word_class: 'N' }
  ],
  buzzer: [
    { word: 'buzz', word_class: 'N' },
    { word: 'buzz', word_class: 'V' },
    { word: 'buzzer', word_class: 'N' },
    { word: 'buzzing', word_class: 'AJ' }
  ],
  subtlety: [
    { word: 'subtle', word_class: 'AJ' },
    { word: 'subtly', word_class: 'AV' },
    { word: 'subtlety', word_class: 'N' }
  ],
  crabs: [
    { word: 'crab', word_class: 'N' },
    { word: 'crab', word_class: 'V' },
    { word: 'crabs', word_class: 'N' },
    { word: 'crabbed', word_class: 'AJ' }
  ],
  identifiable: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  intelligibly: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  deepened: [
    { word: 'deepen', word_class: 'V' },
    { word: 'deepened', word_class: 'AJ' },
    { word: 'deepening', word_class: 'N' },
    { word: 'deepening', word_class: 'AJ' }
  ],
  recessionary: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  contortion: [
    { word: 'contort', word_class: 'V' },
    { word: 'contorsion', word_class: 'N' },
    { word: 'contorted', word_class: 'AJ' },
    { word: 'contortion', word_class: 'N' }
  ],
  timely: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  rooster: [
    { word: 'roost', word_class: 'N' },
    { word: 'roost', word_class: 'V' },
    { word: 'rooster', word_class: 'N' }
  ],
  totaliser: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  evidently: [
    { word: 'evidence', word_class: 'N' },
    { word: 'evidence', word_class: 'V' },
    { word: 'evident', word_class: 'AJ' },
    { word: 'evidently', word_class: 'AV' },
    { word: 'evidential', word_class: 'AJ' }
  ],
  channelize: [
    { word: 'channel', word_class: 'N' },
    { word: 'channel', word_class: 'V' },
    { word: 'channels', word_class: 'N' },
    { word: 'channelize', word_class: 'V' },
    { word: 'channelization', word_class: 'N' }
  ],
  seasoner: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  hearer: [
    { word: 'hear', word_class: 'V' },
    { word: 'hearer', word_class: 'N' },
    { word: 'hearing', word_class: 'N' },
    { word: 'hearing', word_class: 'AJ' }
  ],
  garnishment: [
    { word: 'garnish', word_class: 'N' },
    { word: 'garnish', word_class: 'V' },
    { word: 'garnished', word_class: 'AJ' },
    { word: 'garnishment', word_class: 'N' }
  ],
  captainship: [
    { word: 'captain', word_class: 'N' },
    { word: 'captain', word_class: 'V' },
    { word: 'captaincy', word_class: 'N' },
    { word: 'captainship', word_class: 'N' }
  ],
  spiritualize: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  rule: [
    { word: 'rule', word_class: 'N' },
    { word: 'rule', word_class: 'V' },
    { word: 'ruler', word_class: 'N' },
    { word: 'ruled', word_class: 'AJ' },
    { word: 'ruling', word_class: 'N' },
    { word: 'ruling', word_class: 'AJ' }
  ],
  individualist: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  preventative: [
    { word: 'prevent', word_class: 'N' },
    { word: 'prevent', word_class: 'V' },
    { word: 'prevention', word_class: 'N' },
    { word: 'preventive', word_class: 'N' },
    { word: 'preventive', word_class: 'AJ' },
    { word: 'preventable', word_class: 'AJ' },
    { word: 'preventative', word_class: 'N' },
    { word: 'preventative', word_class: 'AJ' }
  ],
  saccharine: [
    { word: 'saccharin', word_class: 'N' },
    { word: 'saccharine', word_class: 'AJ' },
    { word: 'saccharinity', word_class: 'N' }
  ],
  thievery: [
    { word: 'thieve', word_class: 'V' },
    { word: 'thievery', word_class: 'N' },
    { word: 'thieving', word_class: 'N' },
    { word: 'thievery', word_class: 'AJ' },
    { word: 'thieving', word_class: 'AJ' }
  ],
  enthrall: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  administrator: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  convex: [
    { word: 'convex', word_class: 'AJ' },
    { word: 'convexity', word_class: 'N' },
    { word: 'convexness', word_class: 'N' }
  ],
  just: [{ word: 'just', word_class: 'AJ' }, { word: 'just', word_class: 'AV' }, { word: 'justness', word_class: 'N' }],
  emission: [
    { word: 'emit', word_class: 'V' },
    { word: 'emission', word_class: 'N' },
    { word: 'emitting', word_class: 'AJ' }
  ],
  composure: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  silliness: [
    { word: 'silly', word_class: 'N' },
    { word: 'silly', word_class: 'AJ' },
    { word: 'silliness', word_class: 'N' }
  ],
  adulterant: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  seer: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  nice: [
    { word: 'nice', word_class: 'N' },
    { word: 'nice', word_class: 'AJ' },
    { word: 'nicety', word_class: 'N' },
    { word: 'nicely', word_class: 'AV' },
    { word: 'niceness', word_class: 'N' }
  ],
  croak: [
    { word: 'croak', word_class: 'N' },
    { word: 'croak', word_class: 'V' },
    { word: 'croaker', word_class: 'N' },
    { word: 'croaking', word_class: 'N' },
    { word: 'croaking', word_class: 'AJ' }
  ],
  opinion: [
    { word: 'opine', word_class: 'V' },
    { word: 'opinion', word_class: 'N' },
    { word: 'opinionated', word_class: 'AJ' },
    { word: 'opinionative', word_class: 'AJ' }
  ],
  sonnet: [
    { word: 'sonnet', word_class: 'N' },
    { word: 'sonnet', word_class: 'V' },
    { word: 'sonneteer', word_class: 'N' }
  ],
  maturely: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  pulverize: [
    { word: 'pulverise', word_class: 'V' },
    { word: 'pulverize', word_class: 'V' },
    { word: 'pulverized', word_class: 'AJ' },
    { word: 'pulverisation', word_class: 'N' },
    { word: 'pulverization', word_class: 'N' }
  ],
  autocratic: [
    { word: 'autocrat', word_class: 'N' },
    { word: 'autocracy', word_class: 'N' },
    { word: 'autocratic', word_class: 'AJ' },
    { word: 'autocratical', word_class: 'AJ' },
    { word: 'autocratically', word_class: 'AV' }
  ],
  animistic: [
    { word: 'animist', word_class: 'N' },
    { word: 'animist', word_class: 'AJ' },
    { word: 'animistic', word_class: 'AJ' }
  ],
  barbecue: [
    { word: 'barbecue', word_class: 'N' },
    { word: 'barbecue', word_class: 'V' },
    { word: 'barbecued', word_class: 'AJ' },
    { word: 'barbecuing', word_class: 'N' }
  ],
  epicureanism: [
    { word: 'epicurean', word_class: 'N' },
    { word: 'epicurean', word_class: 'AJ' },
    { word: 'epicureanism', word_class: 'N' }
  ],
  bend: [
    { word: 'bend', word_class: 'N' },
    { word: 'bend', word_class: 'V' },
    { word: 'bends', word_class: 'N' },
    { word: 'bender', word_class: 'N' },
    { word: 'bended', word_class: 'AJ' },
    { word: 'bending', word_class: 'N' },
    { word: 'bending', word_class: 'AJ' }
  ],
  plaster: [
    { word: 'plaster', word_class: 'N' },
    { word: 'plaster', word_class: 'V' },
    { word: 'plasterer', word_class: 'N' },
    { word: 'plastered', word_class: 'AJ' },
    { word: 'plastering', word_class: 'N' }
  ],
  syndication: [
    { word: 'syndic', word_class: 'N' },
    { word: 'syndicate', word_class: 'N' },
    { word: 'syndicate', word_class: 'V' },
    { word: 'syndicalism', word_class: 'N' },
    { word: 'syndication', word_class: 'N' }
  ],
  unknowable: [
    { word: 'unknowing', word_class: 'N' },
    { word: 'unknowing', word_class: 'AJ' },
    { word: 'unknowable', word_class: 'AJ' }
  ],
  please: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  mullion: [
    { word: 'mull', word_class: 'N' },
    { word: 'mull', word_class: 'V' },
    { word: 'mullion', word_class: 'N' },
    { word: 'mullioned', word_class: 'AJ' }
  ],
  shade: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  interrelationship: [
    { word: 'interrelate', word_class: 'V' },
    { word: 'interrelated', word_class: 'AJ' },
    { word: 'interrelation', word_class: 'N' },
    { word: 'interrelationship', word_class: 'N' }
  ],
  embroiderer: [
    { word: 'embroider', word_class: 'V' },
    { word: 'embroidery', word_class: 'N' },
    { word: 'embroiderer', word_class: 'N' },
    { word: 'embroidered', word_class: 'AJ' }
  ],
  inaccessibly: [
    { word: 'inaccessible', word_class: 'AJ' },
    { word: 'inaccessibly', word_class: 'AV' },
    { word: 'inaccessibility', word_class: 'N' }
  ],
  chaperon: [
    { word: 'chaperon', word_class: 'N' },
    { word: 'chaperon', word_class: 'V' },
    { word: 'chaperone', word_class: 'N' },
    { word: 'chaperone', word_class: 'V' },
    { word: 'chaperonage', word_class: 'N' }
  ],
  engineering: [
    { word: 'engine', word_class: 'N' },
    { word: 'engineer', word_class: 'N' },
    { word: 'engineer', word_class: 'V' },
    { word: 'engineering', word_class: 'N' }
  ],
  anagrams: [
    { word: 'anagram', word_class: 'N' },
    { word: 'anagram', word_class: 'V' },
    { word: 'anagrams', word_class: 'N' }
  ],
  glint: [
    { word: 'glint', word_class: 'N' },
    { word: 'glint', word_class: 'V' },
    { word: 'glinting', word_class: 'AJ' }
  ],
  distaste: [
    { word: 'distaste', word_class: 'N' },
    { word: 'distasteful', word_class: 'AJ' },
    { word: 'distastefulness', word_class: 'N' }
  ],
  news: [
    { word: 'new', word_class: 'AJ' },
    { word: 'new', word_class: 'AV' },
    { word: 'news', word_class: 'N' },
    { word: 'newness', word_class: 'N' }
  ],
  flagging: [
    { word: 'flag', word_class: 'N' },
    { word: 'flag', word_class: 'V' },
    { word: 'flagship', word_class: 'N' },
    { word: 'flagging', word_class: 'AJ' }
  ],
  behind: [
    { word: 'behind', word_class: 'N' },
    { word: 'behind', word_class: 'AJ' },
    { word: 'behind', word_class: 'AV' }
  ],
  vandalism: [
    { word: 'vandal', word_class: 'N' },
    { word: 'vandalise', word_class: 'V' },
    { word: 'vandalism', word_class: 'N' },
    { word: 'vandalize', word_class: 'V' },
    { word: 'vandalization', word_class: 'N' }
  ],
  wriggler: [
    { word: 'wriggle', word_class: 'N' },
    { word: 'wriggle', word_class: 'V' },
    { word: 'wriggler', word_class: 'N' },
    { word: 'wriggly', word_class: 'AJ' },
    { word: 'wriggling', word_class: 'AJ' }
  ],
  lesbian: [
    { word: 'lesbian', word_class: 'N' },
    { word: 'lesbian', word_class: 'AJ' },
    { word: 'lesbianism', word_class: 'N' }
  ],
  actuality: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  tautology: [
    { word: 'tautology', word_class: 'N' },
    { word: 'tautologic', word_class: 'AJ' },
    { word: 'tautological', word_class: 'AJ' }
  ],
  incurment: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  visible: [
    { word: 'visible', word_class: 'AJ' },
    { word: 'visibly', word_class: 'AV' },
    { word: 'visibility', word_class: 'N' },
    { word: 'visibleness', word_class: 'N' }
  ],
  convulsion: [
    { word: 'convulse', word_class: 'V' },
    { word: 'convulsion', word_class: 'N' },
    { word: 'convulsive', word_class: 'AJ' },
    { word: 'convulsively', word_class: 'AV' }
  ],
  southerner: [
    { word: 'southern', word_class: 'AJ' },
    { word: 'southerner', word_class: 'N' },
    { word: 'southernism', word_class: 'N' },
    { word: 'southernness', word_class: 'N' }
  ],
  animism: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  sociability: [
    { word: 'sociable', word_class: 'N' },
    { word: 'sociable', word_class: 'AJ' },
    { word: 'sociably', word_class: 'AV' },
    { word: 'sociability', word_class: 'N' },
    { word: 'sociableness', word_class: 'N' }
  ],
  portion: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  incrustation: [
    { word: 'encrust', word_class: 'V' },
    { word: 'incrust', word_class: 'V' },
    { word: 'encrusted', word_class: 'AJ' },
    { word: 'encrustation', word_class: 'N' },
    { word: 'incrustation', word_class: 'N' }
  ],
  humid: [
    { word: 'humid', word_class: 'AJ' },
    { word: 'humidity', word_class: 'N' },
    { word: 'humidness', word_class: 'N' }
  ],
  avowed: [
    { word: 'avow', word_class: 'V' },
    { word: 'avowal', word_class: 'N' },
    { word: 'avowed', word_class: 'AJ' }
  ],
  prostatic: [
    { word: 'prostate', word_class: 'N' },
    { word: 'prostate', word_class: 'V' },
    { word: 'prostate', word_class: 'AJ' },
    { word: 'prostatic', word_class: 'AJ' },
    { word: 'prostatitis', word_class: 'N' }
  ],
  mellowness: [
    { word: 'mellow', word_class: 'V' },
    { word: 'mellow', word_class: 'AJ' },
    { word: 'mellow', word_class: 'AV' },
    { word: 'mellowed', word_class: 'AJ' },
    { word: 'mellowing', word_class: 'N' },
    { word: 'mellowing', word_class: 'AJ' },
    { word: 'mellowness', word_class: 'N' }
  ],
  drowse: [
    { word: 'drowse', word_class: 'N' },
    { word: 'drowse', word_class: 'V' },
    { word: 'drowsy', word_class: 'AJ' },
    { word: 'drowsing', word_class: 'AJ' },
    { word: 'drowsiness', word_class: 'N' }
  ],
  harvest: [
    { word: 'harvest', word_class: 'N' },
    { word: 'harvest', word_class: 'V' },
    { word: 'harvester', word_class: 'N' },
    { word: 'harvesting', word_class: 'N' },
    { word: 'harvestman', word_class: 'N' }
  ],
  crimson: [
    { word: 'crimson', word_class: 'N' },
    { word: 'crimson', word_class: 'V' },
    { word: 'crimson', word_class: 'AJ' }
  ],
  yielding: [
    { word: 'yield', word_class: 'N' },
    { word: 'yield', word_class: 'V' },
    { word: 'yielding', word_class: 'N' },
    { word: 'yielding', word_class: 'AJ' }
  ],
  praise: [
    { word: 'praise', word_class: 'N' },
    { word: 'praise', word_class: 'V' },
    { word: 'praising', word_class: 'AJ' },
    { word: 'praiseful', word_class: 'AJ' }
  ],
  hyperbolically: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  alinement: [
    { word: 'aline', word_class: 'V' },
    { word: 'alined', word_class: 'AJ' },
    { word: 'alinement', word_class: 'N' }
  ],
  excel: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  clinched: [
    { word: 'clinch', word_class: 'N' },
    { word: 'clinch', word_class: 'V' },
    { word: 'clincher', word_class: 'N' },
    { word: 'clinched', word_class: 'AJ' }
  ],
  lecherous: [
    { word: 'lech', word_class: 'V' },
    { word: 'lecher', word_class: 'N' },
    { word: 'lecherous', word_class: 'AJ' },
    { word: 'lecherousness', word_class: 'N' }
  ],
  carting: [
    { word: 'cart', word_class: 'N' },
    { word: 'cart', word_class: 'V' },
    { word: 'carte', word_class: 'N' },
    { word: 'carter', word_class: 'N' },
    { word: 'carting', word_class: 'N' }
  ],
  deaden: [
    { word: 'deaden', word_class: 'V' },
    { word: 'deadened', word_class: 'AJ' },
    { word: 'deadening', word_class: 'N' },
    { word: 'deadening', word_class: 'AJ' }
  ],
  commend: [
    { word: 'commend', word_class: 'V' },
    { word: 'commendable', word_class: 'AJ' },
    { word: 'commendable', word_class: 'AV' },
    { word: 'commendation', word_class: 'N' },
    { word: 'commendatory', word_class: 'AJ' }
  ],
  inconsiderate: [
    { word: 'inconsiderate', word_class: 'AJ' },
    { word: 'inconsiderable', word_class: 'AJ' },
    { word: 'inconsideration', word_class: 'N' },
    { word: 'inconsiderately', word_class: 'AV' },
    { word: 'inconsiderateness', word_class: 'N' }
  ],
  bichromated: [
    { word: 'bichrome', word_class: 'AJ' },
    { word: 'bichromate', word_class: 'N' },
    { word: 'bichromated', word_class: 'AJ' }
  ],
  captain: [
    { word: 'captain', word_class: 'N' },
    { word: 'captain', word_class: 'V' },
    { word: 'captaincy', word_class: 'N' },
    { word: 'captainship', word_class: 'N' }
  ],
  combustion: [
    { word: 'combust', word_class: 'V' },
    { word: 'combustion', word_class: 'N' },
    { word: 'combustible', word_class: 'N' },
    { word: 'combustive', word_class: 'AJ' },
    { word: 'combustible', word_class: 'AJ' }
  ],
  emblematize: [
    { word: 'emblematic', word_class: 'AJ' },
    { word: 'emblematize', word_class: 'V' },
    { word: 'emblematical', word_class: 'AJ' },
    { word: 'emblematization', word_class: 'N' }
  ],
  hobnailed: [
    { word: 'hobnail', word_class: 'N' },
    { word: 'hobnail', word_class: 'V' },
    { word: 'hobnail', word_class: 'AJ' },
    { word: 'hobnailed', word_class: 'AJ' }
  ],
  stertor: [
    { word: 'stertor', word_class: 'N' },
    { word: 'stertorous', word_class: 'AJ' },
    { word: 'stertorously', word_class: 'AV' }
  ],
  hex: [
    { word: 'hex', word_class: 'N' },
    { word: 'hex', word_class: 'V' },
    { word: 'hex', word_class: 'AJ' },
    { word: 'hexed', word_class: 'AJ' }
  ],
  agrobiology: [
    { word: 'agrobiology', word_class: 'N' },
    { word: 'agrobiologic', word_class: 'AJ' },
    { word: 'agrobiological', word_class: 'AJ' }
  ],
  intoxicant: [
    { word: 'intoxicant', word_class: 'N' },
    { word: 'intoxicate', word_class: 'V' },
    { word: 'intoxicant', word_class: 'AJ' },
    { word: 'intoxicated', word_class: 'AJ' },
    { word: 'intoxication', word_class: 'N' },
    { word: 'intoxicating', word_class: 'AJ' }
  ],
  pater: [
    { word: 'pat', word_class: 'N' },
    { word: 'pat', word_class: 'V' },
    { word: 'pat', word_class: 'AJ' },
    { word: 'pat', word_class: 'AV' },
    { word: 'pater', word_class: 'N' },
    { word: 'patness', word_class: 'N' }
  ],
  prolongation: [
    { word: 'prolong', word_class: 'V' },
    { word: 'prolong', word_class: 'AJ' },
    { word: 'prolongate', word_class: 'V' },
    { word: 'prolonged', word_class: 'AJ' },
    { word: 'prolongment', word_class: 'N' },
    { word: 'prolongation', word_class: 'N' }
  ],
  atrociously: [
    { word: 'atrocious', word_class: 'AJ' },
    { word: 'atrociously', word_class: 'AV' },
    { word: 'atrociousness', word_class: 'N' }
  ],
  secretive: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  detoxification: [
    { word: 'detox', word_class: 'N' },
    { word: 'detoxify', word_class: 'V' },
    { word: 'detoxicate', word_class: 'V' },
    { word: 'detoxified', word_class: 'AJ' },
    { word: 'detoxication', word_class: 'N' },
    { word: 'detoxification', word_class: 'N' }
  ],
  ineffective: [
    { word: 'ineffective', word_class: 'AJ' },
    { word: 'ineffectively', word_class: 'AV' },
    { word: 'ineffectiveness', word_class: 'N' }
  ],
  checker: [
    { word: 'check', word_class: 'N' },
    { word: 'check', word_class: 'V' },
    { word: 'checker', word_class: 'N' },
    { word: 'checker', word_class: 'V' },
    { word: 'checked', word_class: 'AJ' },
    { word: 'checkers', word_class: 'N' },
    { word: 'checkered', word_class: 'AJ' }
  ],
  midway: [
    { word: 'midway', word_class: 'N' },
    { word: 'midway', word_class: 'AJ' },
    { word: 'midway', word_class: 'AV' }
  ],
  deplume: [
    { word: 'deplume', word_class: 'V' },
    { word: 'deplumate', word_class: 'V' },
    { word: 'deplumation', word_class: 'N' }
  ],
  sulfation: [
    { word: 'sulfate', word_class: 'N' },
    { word: 'sulfate', word_class: 'V' },
    { word: 'sulfation', word_class: 'N' }
  ],
  candidate: [
    { word: 'candid', word_class: 'AJ' },
    { word: 'candidacy', word_class: 'N' },
    { word: 'candidate', word_class: 'N' },
    { word: 'candidness', word_class: 'N' }
  ],
  victualer: [
    { word: 'victual', word_class: 'N' },
    { word: 'victual', word_class: 'V' },
    { word: 'victuals', word_class: 'N' },
    { word: 'victualer', word_class: 'N' },
    { word: 'victualler', word_class: 'N' }
  ],
  stockholder: [
    { word: 'stockholder', word_class: 'N' },
    { word: 'stockholding', word_class: 'N' },
    { word: 'stockholdings', word_class: 'N' }
  ],
  inefficacious: [
    { word: 'inefficacy', word_class: 'N' },
    { word: 'inefficacious', word_class: 'AJ' },
    { word: 'inefficaciously', word_class: 'AV' },
    { word: 'inefficaciousness', word_class: 'N' }
  ],
  covet: [
    { word: 'covet', word_class: 'N' },
    { word: 'covet', word_class: 'V' },
    { word: 'coveted', word_class: 'AJ' },
    { word: 'covetous', word_class: 'AJ' },
    { word: 'covetously', word_class: 'AV' },
    { word: 'covetousness', word_class: 'N' }
  ],
  tubulate: [
    { word: 'tubule', word_class: 'N' },
    { word: 'tubulate', word_class: 'V' },
    { word: 'tubulation', word_class: 'N' }
  ],
  astutely: [
    { word: 'astute', word_class: 'AJ' },
    { word: 'astutely', word_class: 'AV' },
    { word: 'astuteness', word_class: 'N' }
  ],
  crystalize: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  perish: [
    { word: 'perish', word_class: 'V' },
    { word: 'perisher', word_class: 'N' },
    { word: 'perishable', word_class: 'AJ' },
    { word: 'perishables', word_class: 'N' }
  ],
  graphite: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  folks: [
    { word: 'folk', word_class: 'N' },
    { word: 'folk', word_class: 'AJ' },
    { word: 'folks', word_class: 'N' },
    { word: 'folksy', word_class: 'AJ' }
  ],
  deferent: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  excitement: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  respects: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  responsibleness: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  plausibility: [
    { word: 'plausible', word_class: 'AJ' },
    { word: 'plausibly', word_class: 'AV' },
    { word: 'plausibility', word_class: 'N' },
    { word: 'plausibleness', word_class: 'N' }
  ],
  southwesterner: [
    { word: 'southwestern', word_class: 'N' },
    { word: 'southwestern', word_class: 'AJ' },
    { word: 'southwesterner', word_class: 'N' }
  ],
  dishonored: [
    { word: 'dishonor', word_class: 'N' },
    { word: 'dishonor', word_class: 'V' },
    { word: 'dishonored', word_class: 'AJ' },
    { word: 'dishonorable', word_class: 'AJ' },
    { word: 'dishonorably', word_class: 'AV' },
    { word: 'dishonorableness', word_class: 'N' }
  ],
  ascendancy: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  cracker: [
    { word: 'crack', word_class: 'N' },
    { word: 'crack', word_class: 'V' },
    { word: 'crack', word_class: 'AJ' },
    { word: 'cracker', word_class: 'N' },
    { word: 'cracked', word_class: 'AJ' },
    { word: 'cracking', word_class: 'N' },
    { word: 'crackers', word_class: 'AJ' },
    { word: 'cracking', word_class: 'AJ' }
  ],
  protective: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  track: [
    { word: 'track', word_class: 'N' },
    { word: 'track', word_class: 'V' },
    { word: 'tracker', word_class: 'N' },
    { word: 'tracked', word_class: 'AJ' },
    { word: 'tracking', word_class: 'N' }
  ],
  homophonic: [
    { word: 'homophone', word_class: 'N' },
    { word: 'homophonic', word_class: 'AJ' },
    { word: 'homophonous', word_class: 'AJ' }
  ],
  indeterminateness: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  derisively: [
    { word: 'deride', word_class: 'V' },
    { word: 'derision', word_class: 'N' },
    { word: 'derisive', word_class: 'AJ' },
    { word: 'derisively', word_class: 'AV' }
  ],
  accentuate: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  abranchial: [
    { word: 'abranchial', word_class: 'AJ' },
    { word: 'abranchiate', word_class: 'AJ' },
    { word: 'abranchious', word_class: 'AJ' }
  ],
  singular: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  exacting: [
    { word: 'exact', word_class: 'N' },
    { word: 'exact', word_class: 'V' },
    { word: 'exact', word_class: 'AJ' },
    { word: 'exaction', word_class: 'N' },
    { word: 'exacting', word_class: 'AJ' },
    { word: 'exactness', word_class: 'N' },
    { word: 'exactitude', word_class: 'N' }
  ],
  creativeness: [
    { word: 'creative', word_class: 'AJ' },
    { word: 'creativity', word_class: 'N' },
    { word: 'creatively', word_class: 'AV' },
    { word: 'creativeness', word_class: 'N' }
  ],
  quantization: [
    { word: 'quantise', word_class: 'V' },
    { word: 'quantize', word_class: 'V' },
    { word: 'quantisation', word_class: 'N' },
    { word: 'quantization', word_class: 'N' }
  ],
  experiential: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  guzzle: [
    { word: 'guzzle', word_class: 'V' },
    { word: 'guzzler', word_class: 'N' },
    { word: 'guzzling', word_class: 'N' }
  ],
  dispatcher: [
    { word: 'dispatch', word_class: 'N' },
    { word: 'dispatch', word_class: 'V' },
    { word: 'dispatcher', word_class: 'N' },
    { word: 'dispatched', word_class: 'AJ' }
  ],
  wreck: [
    { word: 'wreck', word_class: 'N' },
    { word: 'wreck', word_class: 'V' },
    { word: 'wrecker', word_class: 'N' },
    { word: 'wrecked', word_class: 'AJ' },
    { word: 'wrecking', word_class: 'N' }
  ],
  clink: [
    { word: 'clink', word_class: 'N' },
    { word: 'clink', word_class: 'V' },
    { word: 'clinker', word_class: 'N' },
    { word: 'clinker', word_class: 'V' },
    { word: 'clinking', word_class: 'AJ' }
  ],
  sedimentary: [
    { word: 'sediment', word_class: 'N' },
    { word: 'sediment', word_class: 'V' },
    { word: 'sedimentary', word_class: 'AJ' },
    { word: 'sedimentation', word_class: 'N' }
  ],
  materialistic: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  equivalency: [
    { word: 'equivalent', word_class: 'N' },
    { word: 'equivalence', word_class: 'N' },
    { word: 'equivalency', word_class: 'N' },
    { word: 'equivalent', word_class: 'AJ' }
  ],
  deciding: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  tenaciousness: [
    { word: 'tenacious', word_class: 'AJ' },
    { word: 'tenaciously', word_class: 'AV' },
    { word: 'tenaciousness', word_class: 'N' }
  ],
  hysterectomise: [
    { word: 'hysterectomy', word_class: 'N' },
    { word: 'hysterectomise', word_class: 'V' },
    { word: 'hysterectomize', word_class: 'V' }
  ],
  hottempered: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  puttee: [
    { word: 'putt', word_class: 'N' },
    { word: 'putt', word_class: 'V' },
    { word: 'puttee', word_class: 'N' },
    { word: 'putter', word_class: 'N' },
    { word: 'putter', word_class: 'V' },
    { word: 'puttees', word_class: 'N' },
    { word: 'putterer', word_class: 'N' }
  ],
  desalinate: [
    { word: 'desalinate', word_class: 'V' },
    { word: 'desalinize', word_class: 'V' },
    { word: 'desalinated', word_class: 'AJ' },
    { word: 'desalination', word_class: 'N' },
    { word: 'desalinization', word_class: 'N' }
  ],
  mendicant: [
    { word: 'mendicant', word_class: 'N' },
    { word: 'mendicancy', word_class: 'N' },
    { word: 'mendicant', word_class: 'AJ' }
  ],
  bloody: [
    { word: 'blood', word_class: 'N' },
    { word: 'blood', word_class: 'V' },
    { word: 'bloody', word_class: 'V' },
    { word: 'bloody', word_class: 'AJ' },
    { word: 'bloody', word_class: 'AV' },
    { word: 'blooded', word_class: 'AJ' },
    { word: 'bloodied', word_class: 'AJ' }
  ],
  equalised: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  booking: [
    { word: 'book', word_class: 'N' },
    { word: 'book', word_class: 'V' },
    { word: 'booker', word_class: 'N' },
    { word: 'booked', word_class: 'AJ' },
    { word: 'booking', word_class: 'N' }
  ],
  cozy: [
    { word: 'cozy', word_class: 'N' },
    { word: 'cozie', word_class: 'N' },
    { word: 'cozy', word_class: 'AJ' },
    { word: 'coziness', word_class: 'N' }
  ],
  islam: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  dishful: [
    { word: 'dish', word_class: 'N' },
    { word: 'dish', word_class: 'V' },
    { word: 'dished', word_class: 'AJ' },
    { word: 'dishful', word_class: 'N' },
    { word: 'dishful', word_class: 'AJ' }
  ],
  occupant: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  euphonous: [
    { word: 'euphonic', word_class: 'AJ' },
    { word: 'euphonize', word_class: 'V' },
    { word: 'euphonous', word_class: 'AJ' },
    { word: 'euphonical', word_class: 'AJ' },
    { word: 'euphonization', word_class: 'N' }
  ],
  underestimation: [
    { word: 'underestimate', word_class: 'N' },
    { word: 'underestimate', word_class: 'V' },
    { word: 'underestimation', word_class: 'N' }
  ],
  front: [
    { word: 'front', word_class: 'N' },
    { word: 'front', word_class: 'V' },
    { word: 'front', word_class: 'AJ' },
    { word: 'frontal', word_class: 'N' },
    { word: 'frontage', word_class: 'N' },
    { word: 'frontal', word_class: 'AJ' },
    { word: 'frontally', word_class: 'AV' }
  ],
  reasonable: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  intractableness: [
    { word: 'intractable', word_class: 'AJ' },
    { word: 'intractability', word_class: 'N' },
    { word: 'intractableness', word_class: 'N' }
  ],
  muffle: [
    { word: 'muffle', word_class: 'N' },
    { word: 'muffle', word_class: 'V' },
    { word: 'muffler', word_class: 'N' },
    { word: 'muffled', word_class: 'AJ' }
  ],
  methodical: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  persecution: [
    { word: 'persecute', word_class: 'V' },
    { word: 'persecutor', word_class: 'N' },
    { word: 'persecuted', word_class: 'AJ' },
    { word: 'persecution', word_class: 'N' }
  ],
  discreet: [
    { word: 'discreet', word_class: 'AJ' },
    { word: 'discreetness', word_class: 'N' },
    { word: 'discretionary', word_class: 'AJ' }
  ],
  indigestion: [
    { word: 'indigestion', word_class: 'N' },
    { word: 'indigestible', word_class: 'AJ' },
    { word: 'indigestibility', word_class: 'N' },
    { word: 'indigestibleness', word_class: 'N' }
  ],
  smile: [
    { word: 'smile', word_class: 'N' },
    { word: 'smile', word_class: 'V' },
    { word: 'smiler', word_class: 'N' },
    { word: 'smiling', word_class: 'N' },
    { word: 'smiling', word_class: 'AJ' }
  ],
  equivalence: [
    { word: 'equivalent', word_class: 'N' },
    { word: 'equivalence', word_class: 'N' },
    { word: 'equivalency', word_class: 'N' },
    { word: 'equivalent', word_class: 'AJ' }
  ],
  escapement: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  contriver: [
    { word: 'contrive', word_class: 'V' },
    { word: 'contriver', word_class: 'N' },
    { word: 'contrived', word_class: 'AJ' },
    { word: 'contrivance', word_class: 'N' }
  ],
  undress: [
    { word: 'undress', word_class: 'N' },
    { word: 'undress', word_class: 'V' },
    { word: 'undressed', word_class: 'AJ' }
  ],
  forbid: [
    { word: 'forbid', word_class: 'V' },
    { word: 'forbid', word_class: 'AJ' },
    { word: 'forbidden', word_class: 'AJ' },
    { word: 'forbidding', word_class: 'N' },
    { word: 'forbiddance', word_class: 'N' },
    { word: 'forbidding', word_class: 'AJ' }
  ],
  floor: [
    { word: 'floor', word_class: 'N' },
    { word: 'floor', word_class: 'V' },
    { word: 'floored', word_class: 'AJ' },
    { word: 'flooring', word_class: 'N' }
  ],
  curvaceousness: [
    { word: 'curvaceous', word_class: 'AJ' },
    { word: 'curvaceously', word_class: 'AV' },
    { word: 'curvaceousness', word_class: 'N' }
  ],
  promiscuousness: [
    { word: 'promiscuity', word_class: 'N' },
    { word: 'promiscuous', word_class: 'AJ' },
    { word: 'promiscuously', word_class: 'AV' },
    { word: 'promiscuousness', word_class: 'N' }
  ],
  actuator: [
    { word: 'actuate', word_class: 'V' },
    { word: 'actuator', word_class: 'N' },
    { word: 'actuated', word_class: 'AJ' },
    { word: 'actuation', word_class: 'N' },
    { word: 'actuating', word_class: 'AJ' }
  ],
  dogma: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  cheese: [
    { word: 'cheese', word_class: 'N' },
    { word: 'cheese', word_class: 'V' },
    { word: 'cheesy', word_class: 'AJ' }
  ],
  directed: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  'penny-pinching': [
    { word: 'penny-pinch', word_class: 'V' },
    { word: 'penny-pinching', word_class: 'N' },
    { word: 'penny-pinching', word_class: 'AJ' }
  ],
  lament: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  excessive: [
    { word: 'exceed', word_class: 'V' },
    { word: 'excess', word_class: 'N' },
    { word: 'excess', word_class: 'AJ' },
    { word: 'excessive', word_class: 'AJ' },
    { word: 'excessively', word_class: 'AV' },
    { word: 'excessiveness', word_class: 'N' }
  ],
  craziness: [
    { word: 'crazy', word_class: 'N' },
    { word: 'crazy', word_class: 'AJ' },
    { word: 'craziness', word_class: 'N' }
  ],
  blending: [
    { word: 'blend', word_class: 'N' },
    { word: 'blend', word_class: 'V' },
    { word: 'blende', word_class: 'N' },
    { word: 'blender', word_class: 'N' },
    { word: 'blended', word_class: 'AJ' },
    { word: 'blending', word_class: 'N' },
    { word: 'blending', word_class: 'AJ' }
  ],
  meltage: [
    { word: 'melt', word_class: 'N' },
    { word: 'melt', word_class: 'V' },
    { word: 'molten', word_class: 'AJ' },
    { word: 'meltage', word_class: 'N' },
    { word: 'melted', word_class: 'AJ' },
    { word: 'melting', word_class: 'N' },
    { word: 'melting', word_class: 'AJ' }
  ],
  federalism: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  daubing: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  bounds: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  buying: [
    { word: 'buy', word_class: 'N' },
    { word: 'buy', word_class: 'V' },
    { word: 'buyer', word_class: 'N' },
    { word: 'buying', word_class: 'N' }
  ],
  depersonalisation: [
    { word: 'depersonalise', word_class: 'V' },
    { word: 'depersonalize', word_class: 'V' },
    { word: 'depersonalisation', word_class: 'N' },
    { word: 'depersonalization', word_class: 'N' }
  ],
  'fire-resisting': [
    { word: 'fire-resistant', word_class: 'AJ' },
    { word: 'fire-resisting', word_class: 'AJ' },
    { word: 'fire-resistive', word_class: 'AJ' }
  ],
  appeasing: [
    { word: 'appease', word_class: 'V' },
    { word: 'appeaser', word_class: 'N' },
    { word: 'appeasing', word_class: 'AJ' },
    { word: 'appeasable', word_class: 'AJ' },
    { word: 'appeasement', word_class: 'N' }
  ],
  chance: [
    { word: 'chance', word_class: 'N' },
    { word: 'chance', word_class: 'V' },
    { word: 'chance', word_class: 'AJ' },
    { word: 'chancy', word_class: 'AJ' },
    { word: 'chanceful', word_class: 'AJ' }
  ],
  keen: [
    { word: 'keen', word_class: 'N' },
    { word: 'keen', word_class: 'V' },
    { word: 'keen', word_class: 'AJ' },
    { word: 'keenness', word_class: 'N' }
  ],
  resplendent: [
    { word: 'resplendence', word_class: 'N' },
    { word: 'resplendency', word_class: 'N' },
    { word: 'resplendent', word_class: 'AJ' },
    { word: 'resplendently', word_class: 'AV' }
  ],
  tuned: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  florist: [
    { word: 'flower', word_class: 'N' },
    { word: 'flower', word_class: 'V' },
    { word: 'floral', word_class: 'AJ' },
    { word: 'florist', word_class: 'N' },
    { word: 'flowered', word_class: 'AJ' },
    { word: 'flowering', word_class: 'N' },
    { word: 'flowering', word_class: 'AJ' }
  ],
  ventilation: [
    { word: 'ventilate', word_class: 'V' },
    { word: 'ventilator', word_class: 'N' },
    { word: 'ventilated', word_class: 'AJ' },
    { word: 'ventilation', word_class: 'N' }
  ],
  nominate: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  inspiration: [
    { word: 'inspire', word_class: 'N' },
    { word: 'inspire', word_class: 'V' },
    { word: 'inspired', word_class: 'AJ' },
    { word: 'inspiring', word_class: 'AJ' },
    { word: 'inspiration', word_class: 'N' },
    { word: 'inspirational', word_class: 'AJ' }
  ],
  taster: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  correspond: [
    { word: 'correspond', word_class: 'V' },
    { word: 'correspondent', word_class: 'N' },
    { word: 'correspondence', word_class: 'N' },
    { word: 'correspondent', word_class: 'AJ' },
    { word: 'corresponding', word_class: 'AJ' }
  ],
  jabberer: [
    { word: 'jabber', word_class: 'N' },
    { word: 'jabber', word_class: 'V' },
    { word: 'jabberer', word_class: 'N' },
    { word: 'jabbering', word_class: 'N' },
    { word: 'jabbering', word_class: 'AJ' }
  ],
  hybridization: [
    { word: 'hybrid', word_class: 'N' },
    { word: 'hybrid', word_class: 'AJ' },
    { word: 'hybridise', word_class: 'V' },
    { word: 'hybridize', word_class: 'V' },
    { word: 'hybridizing', word_class: 'N' },
    { word: 'hybridisation', word_class: 'N' },
    { word: 'hybridization', word_class: 'N' }
  ],
  refreshed: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  noise: [
    { word: 'noise', word_class: 'N' },
    { word: 'noise', word_class: 'V' },
    { word: 'noisy', word_class: 'AJ' },
    { word: 'noisiness', word_class: 'N' }
  ],
  generous: [
    { word: 'generous', word_class: 'AJ' },
    { word: 'generosity', word_class: 'N' },
    { word: 'generously', word_class: 'AV' },
    { word: 'generousness', word_class: 'N' }
  ],
  cooling: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  congenially: [
    { word: 'congenial', word_class: 'AJ' },
    { word: 'congeniality', word_class: 'N' },
    { word: 'congenially', word_class: 'AV' },
    { word: 'congenialness', word_class: 'N' }
  ],
  disbeliever: [
    { word: 'disbelief', word_class: 'N' },
    { word: 'disbelieve', word_class: 'V' },
    { word: 'disbeliever', word_class: 'N' },
    { word: 'disbelieving', word_class: 'AJ' }
  ],
  ghostwritten: [
    { word: 'ghostwrite', word_class: 'V' },
    { word: 'ghostwriter', word_class: 'N' },
    { word: 'ghostwritten', word_class: 'AJ' }
  ],
  discursiveness: [
    { word: 'discursive', word_class: 'AJ' },
    { word: 'discursively', word_class: 'AV' },
    { word: 'discursiveness', word_class: 'N' }
  ],
  deprive: [
    { word: 'deprive', word_class: 'V' },
    { word: 'deprived', word_class: 'AJ' },
    { word: 'deprivation', word_class: 'N' }
  ],
  harvester: [
    { word: 'harvest', word_class: 'N' },
    { word: 'harvest', word_class: 'V' },
    { word: 'harvester', word_class: 'N' },
    { word: 'harvesting', word_class: 'N' },
    { word: 'harvestman', word_class: 'N' }
  ],
  bestowal: [
    { word: 'bestow', word_class: 'V' },
    { word: 'bestowal', word_class: 'N' },
    { word: 'bestowed', word_class: 'AJ' },
    { word: 'bestowment', word_class: 'N' }
  ],
  riddled: [
    { word: 'riddle', word_class: 'N' },
    { word: 'riddle', word_class: 'V' },
    { word: 'riddled', word_class: 'AJ' }
  ],
  bastard: [
    { word: 'bastard', word_class: 'N' },
    { word: 'bastard', word_class: 'AJ' },
    { word: 'bastardize', word_class: 'V' },
    { word: 'bastardized', word_class: 'AJ' },
    { word: 'bastardization', word_class: 'N' }
  ],
  decking: [
    { word: 'deck', word_class: 'N' },
    { word: 'deck', word_class: 'V' },
    { word: 'decked', word_class: 'AJ' },
    { word: 'decking', word_class: 'N' }
  ],
  alcoholisation: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  provide: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  blazonment: [
    { word: 'blazon', word_class: 'N' },
    { word: 'blazon', word_class: 'V' },
    { word: 'blazoning', word_class: 'N' },
    { word: 'blazonment', word_class: 'N' }
  ],
  skeleton: [
    { word: 'skeleton', word_class: 'N' },
    { word: 'skeletal', word_class: 'AJ' },
    { word: 'skeletonise', word_class: 'V' },
    { word: 'skeletonize', word_class: 'V' },
    { word: 'skeletonization', word_class: 'N' }
  ],
  synchronising: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  contain: [
    { word: 'contain', word_class: 'V' },
    { word: 'container', word_class: 'N' },
    { word: 'contained', word_class: 'AJ' },
    { word: 'containment', word_class: 'N' },
    { word: 'containerful', word_class: 'N' }
  ],
  evaluable: [
    { word: 'evaluate', word_class: 'V' },
    { word: 'evaluator', word_class: 'N' },
    { word: 'evaluable', word_class: 'AJ' },
    { word: 'evaluation', word_class: 'N' },
    { word: 'evaluative', word_class: 'AJ' }
  ],
  fulfill: [
    { word: 'fulfil', word_class: 'V' },
    { word: 'fulfill', word_class: 'V' },
    { word: 'fulfilled', word_class: 'AJ' },
    { word: 'fulfilment', word_class: 'N' },
    { word: 'fulfillment', word_class: 'N' }
  ],
  lettered: [
    { word: 'letter', word_class: 'N' },
    { word: 'letter', word_class: 'V' },
    { word: 'letters', word_class: 'N' },
    { word: 'lettered', word_class: 'AJ' },
    { word: 'lettering', word_class: 'N' },
    { word: 'letterman', word_class: 'N' }
  ],
  panelist: [
    { word: 'panel', word_class: 'N' },
    { word: 'panel', word_class: 'V' },
    { word: 'paneling', word_class: 'N' },
    { word: 'panelist', word_class: 'N' },
    { word: 'paneled', word_class: 'AJ' },
    { word: 'panelling', word_class: 'N' }
  ],
  tubule: [
    { word: 'tubule', word_class: 'N' },
    { word: 'tubulate', word_class: 'V' },
    { word: 'tubulation', word_class: 'N' }
  ],
  baked: [
    { word: 'bake', word_class: 'N' },
    { word: 'bake', word_class: 'V' },
    { word: 'baker', word_class: 'N' },
    { word: 'baked', word_class: 'AJ' },
    { word: 'bakery', word_class: 'N' },
    { word: 'baking', word_class: 'N' },
    { word: 'bakery', word_class: 'AJ' },
    { word: 'baking', word_class: 'AJ' }
  ],
  myelin: [
    { word: 'myelin', word_class: 'N' },
    { word: 'myeline', word_class: 'N' },
    { word: 'myelinic', word_class: 'AJ' },
    { word: 'myelinated', word_class: 'AJ' }
  ],
  instantaneous: [
    { word: 'instantaneous', word_class: 'AJ' },
    { word: 'instantaneously', word_class: 'AV' },
    { word: 'instantaneousness', word_class: 'N' }
  ],
  impersonator: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  cave: [{ word: 'cave', word_class: 'N' }, { word: 'cave', word_class: 'V' }, { word: 'caving', word_class: 'N' }],
  wad: [
    { word: 'wad', word_class: 'N' },
    { word: 'wad', word_class: 'V' },
    { word: 'wads', word_class: 'N' },
    { word: 'wadding', word_class: 'N' }
  ],
  entangled: [
    { word: 'entangle', word_class: 'V' },
    { word: 'entangled', word_class: 'AJ' },
    { word: 'entanglement', word_class: 'N' }
  ],
  connive: [
    { word: 'connive', word_class: 'V' },
    { word: 'connivance', word_class: 'N' },
    { word: 'conniving', word_class: 'AJ' }
  ],
  avenging: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  retained: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  sombre: [
    { word: 'sombre', word_class: 'AJ' },
    { word: 'sombrely', word_class: 'AV' },
    { word: 'sombreness', word_class: 'N' }
  ],
  tyranni: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  crooning: [
    { word: 'croon', word_class: 'N' },
    { word: 'croon', word_class: 'V' },
    { word: 'crooner', word_class: 'N' },
    { word: 'crooning', word_class: 'N' }
  ],
  unveiled: [
    { word: 'unveil', word_class: 'V' },
    { word: 'unveiled', word_class: 'AJ' },
    { word: 'unveiling', word_class: 'N' }
  ],
  disciplinal: [
    { word: 'discipline', word_class: 'N' },
    { word: 'discipline', word_class: 'V' },
    { word: 'disciplinal', word_class: 'AJ' },
    { word: 'disciplined', word_class: 'AJ' },
    { word: 'disciplinary', word_class: 'AJ' }
  ],
  stubbly: [
    { word: 'stubble', word_class: 'N' },
    { word: 'stubbly', word_class: 'AJ' },
    { word: 'stubbled', word_class: 'AJ' }
  ],
  anoint: [
    { word: 'anoint', word_class: 'V' },
    { word: 'anointing', word_class: 'N' },
    { word: 'inunction', word_class: 'N' },
    { word: 'anointment', word_class: 'N' }
  ],
  tendriled: [
    { word: 'tendril', word_class: 'N' },
    { word: 'tendriled', word_class: 'AJ' },
    { word: 'tendrilled', word_class: 'AJ' }
  ],
  changeable: [
    { word: 'changeable', word_class: 'AJ' },
    { word: 'changeability', word_class: 'N' },
    { word: 'changeableness', word_class: 'N' }
  ],
  digitately: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  linguistics: [
    { word: 'linguist', word_class: 'N' },
    { word: 'linguistic', word_class: 'AJ' },
    { word: 'linguistics', word_class: 'N' },
    { word: 'linguistically', word_class: 'AV' }
  ],
  accreditation: [
    { word: 'accredit', word_class: 'V' },
    { word: 'accredited', word_class: 'AJ' },
    { word: 'accreditation', word_class: 'N' }
  ],
  compensate: [
    { word: 'compensate', word_class: 'V' },
    { word: 'compensable', word_class: 'AJ' },
    { word: 'compensated', word_class: 'AJ' },
    { word: 'compensation', word_class: 'N' },
    { word: 'compensative', word_class: 'AJ' }
  ],
  fluoridation: [
    { word: 'fluoride', word_class: 'N' },
    { word: 'fluoridate', word_class: 'V' },
    { word: 'fluoridize', word_class: 'V' },
    { word: 'fluoridation', word_class: 'N' },
    { word: 'fluoridization', word_class: 'N' }
  ],
  incapacitating: [
    { word: 'incapacity', word_class: 'N' },
    { word: 'incapacitate', word_class: 'V' },
    { word: 'incapacitated', word_class: 'AJ' },
    { word: 'incapacitation', word_class: 'N' },
    { word: 'incapacitating', word_class: 'AJ' }
  ],
  palatability: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  curry: [{ word: 'curry', word_class: 'N' }, { word: 'curry', word_class: 'V' }, { word: 'curry', word_class: 'AJ' }],
  orangeness: [
    { word: 'orang', word_class: 'N' },
    { word: 'orange', word_class: 'N' },
    { word: 'orange', word_class: 'AJ' },
    { word: 'orangy', word_class: 'AJ' },
    { word: 'orangeness', word_class: 'N' }
  ],
  nestling: [
    { word: 'nestle', word_class: 'N' },
    { word: 'nestle', word_class: 'V' },
    { word: 'nestled', word_class: 'AJ' },
    { word: 'nestling', word_class: 'N' }
  ],
  revitalise: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  closed: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  ab: [{ word: 'ab', word_class: 'N' }, { word: 'abed', word_class: 'AJ' }, { word: 'abed', word_class: 'AV' }],
  absolutistic: [
    { word: 'absolutist', word_class: 'N' },
    { word: 'absolutist', word_class: 'AJ' },
    { word: 'absolutistic', word_class: 'AJ' }
  ],
  dreadful: [
    { word: 'dread', word_class: 'N' },
    { word: 'dread', word_class: 'V' },
    { word: 'dread', word_class: 'AJ' },
    { word: 'dreaded', word_class: 'AJ' },
    { word: 'dreadful', word_class: 'AJ' },
    { word: 'dreadfulness', word_class: 'N' }
  ],
  gauntlet: [
    { word: 'gauntlet', word_class: 'N' },
    { word: 'gauntlets', word_class: 'N' },
    { word: 'gauntleted', word_class: 'AJ' }
  ],
  vaguely: [
    { word: 'vagus', word_class: 'N' },
    { word: 'vague', word_class: 'AJ' },
    { word: 'vaguely', word_class: 'AV' },
    { word: 'vagueness', word_class: 'N' }
  ],
  enlist: [
    { word: 'enlist', word_class: 'V' },
    { word: 'enlistee', word_class: 'N' },
    { word: 'enlisted', word_class: 'AJ' },
    { word: 'enlisting', word_class: 'N' },
    { word: 'enlistment', word_class: 'N' }
  ],
  kinesthetically: [
    { word: 'kinesthetic', word_class: 'AJ' },
    { word: 'kinesthetics', word_class: 'N' },
    { word: 'kinesthetically', word_class: 'AV' }
  ],
  cart: [
    { word: 'cart', word_class: 'N' },
    { word: 'cart', word_class: 'V' },
    { word: 'carte', word_class: 'N' },
    { word: 'carter', word_class: 'N' },
    { word: 'carting', word_class: 'N' }
  ],
  procedural: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  powerful: [
    { word: 'power', word_class: 'N' },
    { word: 'power', word_class: 'V' },
    { word: 'power', word_class: 'AJ' },
    { word: 'powered', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AV' },
    { word: 'powerfulness', word_class: 'N' }
  ],
  elliptical: [
    { word: 'elliptic', word_class: 'AJ' },
    { word: 'elliptical', word_class: 'AJ' },
    { word: 'ellipticity', word_class: 'N' }
  ],
  moving: [
    { word: 'move', word_class: 'N' },
    { word: 'move', word_class: 'V' },
    { word: 'mover', word_class: 'N' },
    { word: 'moved', word_class: 'AJ' },
    { word: 'moving', word_class: 'N' },
    { word: 'moving', word_class: 'AJ' },
    { word: 'movement', word_class: 'N' }
  ],
  thrombosed: [
    { word: 'thrombose', word_class: 'V' },
    { word: 'thrombosis', word_class: 'N' },
    { word: 'thrombosed', word_class: 'AJ' }
  ],
  shovel: [
    { word: 'shovel', word_class: 'N' },
    { word: 'shovel', word_class: 'V' },
    { word: 'shoveler', word_class: 'N' },
    { word: 'shovelful', word_class: 'N' },
    { word: 'shovelful', word_class: 'AJ' }
  ],
  stickiness: [
    { word: 'stick', word_class: 'N' },
    { word: 'stick', word_class: 'V' },
    { word: 'sticker', word_class: 'N' },
    { word: 'sticky', word_class: 'AJ' },
    { word: 'sticking', word_class: 'AJ' },
    { word: 'stickiness', word_class: 'N' }
  ],
  disintegrable: [
    { word: 'disintegrate', word_class: 'V' },
    { word: 'disintegrable', word_class: 'AJ' },
    { word: 'disintegration', word_class: 'N' },
    { word: 'disintegrative', word_class: 'AJ' }
  ],
  extortion: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  unfasten: [
    { word: 'unfasten', word_class: 'V' },
    { word: 'unfastened', word_class: 'AJ' },
    { word: 'unfastening', word_class: 'N' }
  ],
  adaptation: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  exposed: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  discoloration: [
    { word: 'discolor', word_class: 'V' },
    { word: 'discolored', word_class: 'AJ' },
    { word: 'discolorize', word_class: 'V' },
    { word: 'discoloration', word_class: 'N' }
  ],
  wagerer: [
    { word: 'wager', word_class: 'N' },
    { word: 'wager', word_class: 'V' },
    { word: 'wagerer', word_class: 'N' }
  ],
  snip: [
    { word: 'snip', word_class: 'N' },
    { word: 'snip', word_class: 'V' },
    { word: 'snips', word_class: 'N' },
    { word: 'snipping', word_class: 'N' }
  ],
  limning: [
    { word: 'limn', word_class: 'V' },
    { word: 'limner', word_class: 'N' },
    { word: 'limning', word_class: 'N' }
  ],
  rejuvenation: [
    { word: 'rejuvenate', word_class: 'V' },
    { word: 'rejuvenesce', word_class: 'V' },
    { word: 'rejuvenation', word_class: 'N' }
  ],
  insert: [
    { word: 'insert', word_class: 'N' },
    { word: 'insert', word_class: 'V' },
    { word: 'insertion', word_class: 'N' }
  ],
  staunchness: [
    { word: 'staunch', word_class: 'V' },
    { word: 'staunch', word_class: 'AJ' },
    { word: 'staunchness', word_class: 'N' }
  ],
  ravelling: [
    { word: 'ravel', word_class: 'N' },
    { word: 'ravel', word_class: 'V' },
    { word: 'raveling', word_class: 'N' },
    { word: 'ravelling', word_class: 'N' }
  ],
  pharisee: [
    { word: 'pharisee', word_class: 'N' },
    { word: 'pharisaic', word_class: 'AJ' },
    { word: 'pharisaical', word_class: 'AJ' }
  ],
  seconder: [
    { word: 'second', word_class: 'N' },
    { word: 'second', word_class: 'V' },
    { word: 'second', word_class: 'AJ' },
    { word: 'second', word_class: 'AV' },
    { word: 'seconder', word_class: 'N' },
    { word: 'secondary', word_class: 'N' },
    { word: 'secondary', word_class: 'AJ' },
    { word: 'secondment', word_class: 'N' }
  ],
  navigate: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  introspection: [
    { word: 'introspect', word_class: 'V' },
    { word: 'introspection', word_class: 'N' },
    { word: 'introspective', word_class: 'AJ' },
    { word: 'introspectiveness', word_class: 'N' }
  ],
  judgeship: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  content: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  futurism: [
    { word: 'future', word_class: 'N' },
    { word: 'future', word_class: 'AJ' },
    { word: 'futurism', word_class: 'N' },
    { word: 'futurity', word_class: 'N' }
  ],
  vivification: [
    { word: 'vivify', word_class: 'V' },
    { word: 'vivifying', word_class: 'N' },
    { word: 'vivification', word_class: 'N' }
  ],
  roving: [
    { word: 'rove', word_class: 'N' },
    { word: 'rove', word_class: 'V' },
    { word: 'rover', word_class: 'N' },
    { word: 'roving', word_class: 'N' },
    { word: 'roving', word_class: 'AJ' }
  ],
  punter: [
    { word: 'punt', word_class: 'N' },
    { word: 'punt', word_class: 'V' },
    { word: 'punter', word_class: 'N' },
    { word: 'punting', word_class: 'N' }
  ],
  chromatographical: [
    { word: 'chromatographic', word_class: 'AJ' },
    { word: 'chromatographical', word_class: 'AJ' },
    { word: 'chromatographically', word_class: 'AV' }
  ],
  transferable: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  avenger: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  intensity: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  discoverable: [
    { word: 'discover', word_class: 'N' },
    { word: 'discover', word_class: 'V' },
    { word: 'discovery', word_class: 'N' },
    { word: 'discoverer', word_class: 'N' },
    { word: 'discovery', word_class: 'AJ' },
    { word: 'discovered', word_class: 'AJ' },
    { word: 'discoverable', word_class: 'AJ' }
  ],
  gameness: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  squeezer: [
    { word: 'squeeze', word_class: 'N' },
    { word: 'squeeze', word_class: 'V' },
    { word: 'squeezer', word_class: 'N' }
  ],
  nursing: [
    { word: 'nurse', word_class: 'N' },
    { word: 'nurse', word_class: 'V' },
    { word: 'nursed', word_class: 'AJ' },
    { word: 'nursing', word_class: 'N' }
  ],
  cleaver: [
    { word: 'cleave', word_class: 'V' },
    { word: 'cleaver', word_class: 'N' },
    { word: 'cloven', word_class: 'AJ' },
    { word: 'cleavers', word_class: 'N' }
  ],
  electrifying: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  urbane: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  inseminate: [
    { word: 'inseminate', word_class: 'V' },
    { word: 'inseminated', word_class: 'AJ' },
    { word: 'insemination', word_class: 'N' }
  ],
  amicability: [
    { word: 'amicable', word_class: 'AJ' },
    { word: 'amicably', word_class: 'AV' },
    { word: 'amicability', word_class: 'N' },
    { word: 'amicableness', word_class: 'N' }
  ],
  elucidate: [
    { word: 'elucidate', word_class: 'V' },
    { word: 'elucidation', word_class: 'N' },
    { word: 'elucidative', word_class: 'AJ' }
  ],
  distrustful: [
    { word: 'distrust', word_class: 'N' },
    { word: 'distrust', word_class: 'V' },
    { word: 'distrustful', word_class: 'AJ' },
    { word: 'distrustfulness', word_class: 'N' }
  ],
  accounting: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  thrower: [
    { word: 'throw', word_class: 'N' },
    { word: 'throw', word_class: 'V' },
    { word: 'thrower', word_class: 'N' },
    { word: 'thrown', word_class: 'AJ' }
  ],
  devaluation: [
    { word: 'devalue', word_class: 'V' },
    { word: 'devaluate', word_class: 'V' },
    { word: 'devalued', word_class: 'AJ' },
    { word: 'devaluation', word_class: 'N' }
  ],
  gush: [
    { word: 'gush', word_class: 'N' },
    { word: 'gush', word_class: 'V' },
    { word: 'gusher', word_class: 'N' },
    { word: 'gushing', word_class: 'AJ' }
  ],
  cocainise: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  repugn: [
    { word: 'repugn', word_class: 'V' },
    { word: 'repugnance', word_class: 'N' },
    { word: 'repugnancy', word_class: 'N' },
    { word: 'repugnant', word_class: 'AJ' }
  ],
  pms: [{ word: 'pm', word_class: 'N' }, { word: 'pm', word_class: 'AJ' }, { word: 'pms', word_class: 'N' }],
  overmuch: [
    { word: 'overmuch', word_class: 'N' },
    { word: 'overmuch', word_class: 'AJ' },
    { word: 'overmuch', word_class: 'AV' },
    { word: 'overmuchness', word_class: 'N' }
  ],
  box: [
    { word: 'box', word_class: 'N' },
    { word: 'box', word_class: 'V' },
    { word: 'boxer', word_class: 'N' },
    { word: 'boxed', word_class: 'AJ' },
    { word: 'boxful', word_class: 'N' },
    { word: 'boxing', word_class: 'N' },
    { word: 'boxful', word_class: 'AJ' }
  ],
  submerged: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  gradualness: [
    { word: 'gradual', word_class: 'N' },
    { word: 'gradual', word_class: 'AJ' },
    { word: 'graduality', word_class: 'N' },
    { word: 'gradually', word_class: 'AV' },
    { word: 'gradualness', word_class: 'N' }
  ],
  formalize: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  glare: [
    { word: 'glare', word_class: 'N' },
    { word: 'glare', word_class: 'V' },
    { word: 'glaring', word_class: 'AJ' }
  ],
  soreness: [
    { word: 'sore', word_class: 'N' },
    { word: 'sore', word_class: 'AJ' },
    { word: 'sorely', word_class: 'AV' },
    { word: 'soreness', word_class: 'N' }
  ],
  hunchbacked: [
    { word: 'hunchback', word_class: 'N' },
    { word: 'hunchback', word_class: 'AJ' },
    { word: 'hunchbacked', word_class: 'AJ' }
  ],
  algebraical: [
    { word: 'algebra', word_class: 'N' },
    { word: 'algebraic', word_class: 'AJ' },
    { word: 'algebraical', word_class: 'AJ' },
    { word: 'algebraically', word_class: 'AV' }
  ],
  baggage: [
    { word: 'bag', word_class: 'N' },
    { word: 'bag', word_class: 'V' },
    { word: 'bagful', word_class: 'N' },
    { word: 'bagman', word_class: 'N' },
    { word: 'bagful', word_class: 'AJ' },
    { word: 'baggage', word_class: 'N' },
    { word: 'bagging', word_class: 'N' }
  ],
  stomachic: [
    { word: 'stomach', word_class: 'N' },
    { word: 'stomach', word_class: 'V' },
    { word: 'stomacher', word_class: 'N' },
    { word: 'stomachal', word_class: 'AJ' },
    { word: 'stomachic', word_class: 'AJ' }
  ],
  relevant: [
    { word: 'relevance', word_class: 'N' },
    { word: 'relevancy', word_class: 'N' },
    { word: 'relevant', word_class: 'AJ' }
  ],
  oxygenate: [
    { word: 'oxygen', word_class: 'N' },
    { word: 'oxygenate', word_class: 'V' },
    { word: 'oxygenize', word_class: 'V' },
    { word: 'oxygenated', word_class: 'AJ' },
    { word: 'oxygenation', word_class: 'N' }
  ],
  fragrance: [
    { word: 'fragrance', word_class: 'N' },
    { word: 'fragrancy', word_class: 'N' },
    { word: 'fragrant', word_class: 'AJ' }
  ],
  deductible: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  imperceptibly: [
    { word: 'imperceptible', word_class: 'AJ' },
    { word: 'imperceptibly', word_class: 'AV' },
    { word: 'imperceptibility', word_class: 'N' }
  ],
  irritant: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  excavation: [
    { word: 'excavate', word_class: 'V' },
    { word: 'excavator', word_class: 'N' },
    { word: 'excavation', word_class: 'N' }
  ],
  scepter: [
    { word: 'scepter', word_class: 'N' },
    { word: 'scepter', word_class: 'V' },
    { word: 'sceptered', word_class: 'AJ' }
  ],
  narrator: [
    { word: 'narrate', word_class: 'V' },
    { word: 'narrator', word_class: 'N' },
    { word: 'narration', word_class: 'N' },
    { word: 'narrative', word_class: 'N' },
    { word: 'narrative', word_class: 'AJ' }
  ],
  glistening: [
    { word: 'glisten', word_class: 'N' },
    { word: 'glisten', word_class: 'V' },
    { word: 'glistening', word_class: 'AJ' }
  ],
  fulfilled: [
    { word: 'fulfil', word_class: 'V' },
    { word: 'fulfill', word_class: 'V' },
    { word: 'fulfilled', word_class: 'AJ' },
    { word: 'fulfilment', word_class: 'N' },
    { word: 'fulfillment', word_class: 'N' }
  ],
  prescient: [
    { word: 'prescience', word_class: 'N' },
    { word: 'prescient', word_class: 'AJ' },
    { word: 'presciently', word_class: 'AV' }
  ],
  fecundate: [
    { word: 'fecund', word_class: 'AJ' },
    { word: 'fecundate', word_class: 'V' },
    { word: 'fecundity', word_class: 'N' },
    { word: 'fecundation', word_class: 'N' }
  ],
  fascinate: [
    { word: 'fascinate', word_class: 'V' },
    { word: 'fascinated', word_class: 'AJ' },
    { word: 'fascination', word_class: 'N' },
    { word: 'fascinating', word_class: 'AJ' }
  ],
  gratify: [
    { word: 'gratify', word_class: 'V' },
    { word: 'gratified', word_class: 'AJ' },
    { word: 'gratifying', word_class: 'AJ' },
    { word: 'gratification', word_class: 'N' }
  ],
  apposite: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  incrimination: [
    { word: 'incriminate', word_class: 'V' },
    { word: 'incrimination', word_class: 'N' },
    { word: 'incriminating', word_class: 'AJ' }
  ],
  grotesque: [
    { word: 'grotesque', word_class: 'N' },
    { word: 'grotesque', word_class: 'AJ' },
    { word: 'grotesquely', word_class: 'AV' },
    { word: 'grotesqueness', word_class: 'N' }
  ],
  enlarger: [
    { word: 'enlarge', word_class: 'V' },
    { word: 'enlarger', word_class: 'N' },
    { word: 'enlarged', word_class: 'AJ' },
    { word: 'enlargement', word_class: 'N' }
  ],
  utterance: [
    { word: 'utter', word_class: 'V' },
    { word: 'utter', word_class: 'AJ' },
    { word: 'uttered', word_class: 'AJ' },
    { word: 'utterance', word_class: 'N' },
    { word: 'utterable', word_class: 'AJ' }
  ],
  substance: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  remonetization: [
    { word: 'remonetise', word_class: 'V' },
    { word: 'remonetize', word_class: 'V' },
    { word: 'remonetisation', word_class: 'N' },
    { word: 'remonetization', word_class: 'N' }
  ],
  veneration: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  romper: [{ word: 'romp', word_class: 'N' }, { word: 'romp', word_class: 'V' }, { word: 'romper', word_class: 'N' }],
  trampled: [
    { word: 'trample', word_class: 'N' },
    { word: 'trample', word_class: 'V' },
    { word: 'trampled', word_class: 'AJ' },
    { word: 'trampling', word_class: 'N' },
    { word: 'trampling', word_class: 'AJ' }
  ],
  denotative: [
    { word: 'denote', word_class: 'N' },
    { word: 'denote', word_class: 'V' },
    { word: 'denotive', word_class: 'AJ' },
    { word: 'denotation', word_class: 'N' },
    { word: 'denotement', word_class: 'N' },
    { word: 'denotative', word_class: 'AJ' }
  ],
  suckle: [
    { word: 'suckle', word_class: 'V' },
    { word: 'suckled', word_class: 'AJ' },
    { word: 'suckling', word_class: 'N' }
  ],
  bagging: [
    { word: 'bag', word_class: 'N' },
    { word: 'bag', word_class: 'V' },
    { word: 'bagful', word_class: 'N' },
    { word: 'bagman', word_class: 'N' },
    { word: 'bagful', word_class: 'AJ' },
    { word: 'baggage', word_class: 'N' },
    { word: 'bagging', word_class: 'N' }
  ],
  actuating: [
    { word: 'actuate', word_class: 'V' },
    { word: 'actuator', word_class: 'N' },
    { word: 'actuated', word_class: 'AJ' },
    { word: 'actuation', word_class: 'N' },
    { word: 'actuating', word_class: 'AJ' }
  ],
  whirring: [
    { word: 'whir', word_class: 'N' },
    { word: 'whir', word_class: 'V' },
    { word: 'whirring', word_class: 'N' },
    { word: 'whirring', word_class: 'AJ' }
  ],
  entry: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  bunk: [
    { word: 'bunk', word_class: 'N' },
    { word: 'bunk', word_class: 'V' },
    { word: 'bunker', word_class: 'N' },
    { word: 'bunker', word_class: 'V' }
  ],
  lackadaisic: [
    { word: 'lackadaisic', word_class: 'AJ' },
    { word: 'lackadaisical', word_class: 'AJ' },
    { word: 'lackadaisically', word_class: 'AV' }
  ],
  invasion: [
    { word: 'invade', word_class: 'V' },
    { word: 'invader', word_class: 'N' },
    { word: 'invasion', word_class: 'N' },
    { word: 'invading', word_class: 'AJ' },
    { word: 'invasive', word_class: 'AJ' }
  ],
  scattering: [
    { word: 'scatter', word_class: 'N' },
    { word: 'scatter', word_class: 'V' },
    { word: 'scattered', word_class: 'AJ' },
    { word: 'scattering', word_class: 'N' },
    { word: 'scattering', word_class: 'AJ' }
  ],
  hug: [{ word: 'hug', word_class: 'N' }, { word: 'hug', word_class: 'V' }, { word: 'hugging', word_class: 'N' }],
  vegetational: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  speak: [
    { word: 'speak', word_class: 'V' },
    { word: 'speech', word_class: 'N' },
    { word: 'speaker', word_class: 'N' },
    { word: 'spoken', word_class: 'AJ' },
    { word: 'speaking', word_class: 'N' },
    { word: 'speaking', word_class: 'AJ' }
  ],
  disconnection: [
    { word: 'disconnect', word_class: 'V' },
    { word: 'disconnexion', word_class: 'N' },
    { word: 'disconnected', word_class: 'AJ' },
    { word: 'disconnection', word_class: 'N' }
  ],
  continuation: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  dwell: [
    { word: 'dwell', word_class: 'V' },
    { word: 'dweller', word_class: 'N' },
    { word: 'dwelling', word_class: 'N' }
  ],
  digestion: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  stain: [
    { word: 'stain', word_class: 'N' },
    { word: 'stain', word_class: 'V' },
    { word: 'stained', word_class: 'AJ' },
    { word: 'staining', word_class: 'N' }
  ],
  sneering: [
    { word: 'sneer', word_class: 'N' },
    { word: 'sneer', word_class: 'V' },
    { word: 'sneering', word_class: 'N' },
    { word: 'sneering', word_class: 'AJ' }
  ],
  deference: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  dramatist: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  digression: [
    { word: 'digress', word_class: 'V' },
    { word: 'digression', word_class: 'N' },
    { word: 'digressive', word_class: 'AJ' }
  ],
  hyphen: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  ransom: [
    { word: 'ransom', word_class: 'N' },
    { word: 'ransom', word_class: 'V' },
    { word: 'ransomed', word_class: 'AJ' }
  ],
  forked: [
    { word: 'fork', word_class: 'N' },
    { word: 'fork', word_class: 'V' },
    { word: 'forked', word_class: 'AJ' },
    { word: 'forkful', word_class: 'N' },
    { word: 'forking', word_class: 'N' },
    { word: 'forkful', word_class: 'AJ' }
  ],
  shuttered: [
    { word: 'shutter', word_class: 'N' },
    { word: 'shutter', word_class: 'V' },
    { word: 'shuttered', word_class: 'AJ' }
  ],
  frost: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  subversion: [
    { word: 'subvert', word_class: 'V' },
    { word: 'subverter', word_class: 'N' },
    { word: 'subversion', word_class: 'N' },
    { word: 'subversive', word_class: 'N' },
    { word: 'subversive', word_class: 'AJ' },
    { word: 'subversiveness', word_class: 'N' }
  ],
  rastafarianism: [
    { word: 'rastafarian', word_class: 'N' },
    { word: 'rastafarian', word_class: 'AJ' },
    { word: 'rastafarianism', word_class: 'N' }
  ],
  extendable: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  'free-liver': [
    { word: 'free-liver', word_class: 'N' },
    { word: 'free-living', word_class: 'N' },
    { word: 'free-living', word_class: 'AJ' }
  ],
  conviviality: [
    { word: 'convivial', word_class: 'AJ' },
    { word: 'conviviality', word_class: 'N' },
    { word: 'convivially', word_class: 'AV' }
  ],
  idolatrously: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  verticillated: [
    { word: 'verticil', word_class: 'N' },
    { word: 'verticillate', word_class: 'AJ' },
    { word: 'verticillated', word_class: 'AJ' }
  ],
  nationalist: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  nictate: [
    { word: 'nictate', word_class: 'V' },
    { word: 'nictation', word_class: 'N' },
    { word: 'nictitate', word_class: 'V' },
    { word: 'nictitation', word_class: 'N' }
  ],
  motley: [
    { word: 'motley', word_class: 'N' },
    { word: 'motley', word_class: 'V' },
    { word: 'motley', word_class: 'AJ' }
  ],
  placidness: [
    { word: 'placid', word_class: 'AJ' },
    { word: 'placidity', word_class: 'N' },
    { word: 'placidness', word_class: 'N' }
  ],
  indirectness: [
    { word: 'indirect', word_class: 'AJ' },
    { word: 'indirection', word_class: 'N' },
    { word: 'indirectness', word_class: 'N' }
  ],
  like: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  expectoration: [
    { word: 'expectorant', word_class: 'N' },
    { word: 'expectorate', word_class: 'V' },
    { word: 'expectoration', word_class: 'N' }
  ],
  observation: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  bombing: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  mutilation: [
    { word: 'mutilate', word_class: 'V' },
    { word: 'mutilated', word_class: 'AJ' },
    { word: 'mutilation', word_class: 'N' }
  ],
  luminous: [
    { word: 'luminal', word_class: 'N' },
    { word: 'luminance', word_class: 'N' },
    { word: 'luminous', word_class: 'AJ' },
    { word: 'luminousness', word_class: 'N' }
  ],
  larder: [{ word: 'lard', word_class: 'N' }, { word: 'lard', word_class: 'V' }, { word: 'larder', word_class: 'N' }],
  birling: [
    { word: 'birl', word_class: 'V' },
    { word: 'birle', word_class: 'V' },
    { word: 'birling', word_class: 'N' }
  ],
  slouched: [
    { word: 'slouch', word_class: 'N' },
    { word: 'slouch', word_class: 'V' },
    { word: 'slouched', word_class: 'AJ' },
    { word: 'slouching', word_class: 'AJ' }
  ],
  adjusted: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  solicit: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  alphabetically: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  confiscated: [
    { word: 'confiscate', word_class: 'V' },
    { word: 'confiscate', word_class: 'AJ' },
    { word: 'confiscable', word_class: 'AJ' },
    { word: 'confiscated', word_class: 'AJ' },
    { word: 'confiscation', word_class: 'N' }
  ],
  denigrating: [
    { word: 'denigrate', word_class: 'V' },
    { word: 'denigration', word_class: 'N' },
    { word: 'denigrating', word_class: 'AJ' },
    { word: 'denigrative', word_class: 'AJ' }
  ],
  immature: [
    { word: 'immature', word_class: 'AJ' },
    { word: 'immaturity', word_class: 'N' },
    { word: 'immaturely', word_class: 'AV' },
    { word: 'immatureness', word_class: 'N' }
  ],
  acrobatics: [
    { word: 'acrobat', word_class: 'N' },
    { word: 'acrobatic', word_class: 'AJ' },
    { word: 'acrobatics', word_class: 'N' }
  ],
  vaporisation: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  esthetics: [
    { word: 'esthete', word_class: 'N' },
    { word: 'esthetic', word_class: 'N' },
    { word: 'esthetic', word_class: 'AJ' },
    { word: 'esthetics', word_class: 'N' },
    { word: 'esthetical', word_class: 'AJ' },
    { word: 'esthetically', word_class: 'AV' }
  ],
  converted: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  pest: [
    { word: 'pee', word_class: 'N' },
    { word: 'pee', word_class: 'V' },
    { word: 'pest', word_class: 'N' },
    { word: 'peeing', word_class: 'N' }
  ],
  malevolently: [
    { word: 'malevolence', word_class: 'N' },
    { word: 'malevolency', word_class: 'N' },
    { word: 'malevolent', word_class: 'AJ' },
    { word: 'malevolently', word_class: 'AV' }
  ],
  subsume: [
    { word: 'subsume', word_class: 'V' },
    { word: 'subsuming', word_class: 'N' },
    { word: 'subsumption', word_class: 'N' }
  ],
  warning: [
    { word: 'warn', word_class: 'V' },
    { word: 'warning', word_class: 'N' },
    { word: 'warning', word_class: 'AJ' }
  ],
  spoken: [
    { word: 'speak', word_class: 'V' },
    { word: 'speech', word_class: 'N' },
    { word: 'speaker', word_class: 'N' },
    { word: 'spoken', word_class: 'AJ' },
    { word: 'speaking', word_class: 'N' },
    { word: 'speaking', word_class: 'AJ' }
  ],
  agism: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  fade: [
    { word: 'fade', word_class: 'N' },
    { word: 'fade', word_class: 'V' },
    { word: 'faded', word_class: 'AJ' },
    { word: 'fading', word_class: 'N' },
    { word: 'fading', word_class: 'AJ' },
    { word: 'fadeless', word_class: 'AJ' }
  ],
  allis: [
    { word: 'ally', word_class: 'N' },
    { word: 'ally', word_class: 'V' },
    { word: 'allis', word_class: 'N' },
    { word: 'allies', word_class: 'N' },
    { word: 'allied', word_class: 'AJ' },
    { word: 'alliance', word_class: 'N' }
  ],
  crowned: [
    { word: 'crown', word_class: 'N' },
    { word: 'crown', word_class: 'V' },
    { word: 'crowned', word_class: 'AJ' },
    { word: 'crowning', word_class: 'AJ' }
  ],
  magnetics: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  dapple: [
    { word: 'dapple', word_class: 'N' },
    { word: 'dapple', word_class: 'V' },
    { word: 'dappled', word_class: 'AJ' }
  ],
  sectarian: [
    { word: 'sectarian', word_class: 'N' },
    { word: 'sectarian', word_class: 'AJ' },
    { word: 'sectarianism', word_class: 'N' }
  ],
  petrifying: [
    { word: 'petrify', word_class: 'V' },
    { word: 'petrified', word_class: 'AJ' },
    { word: 'petrifying', word_class: 'AJ' },
    { word: 'petrifaction', word_class: 'N' },
    { word: 'petrification', word_class: 'N' }
  ],
  acetate: [
    { word: 'acetal', word_class: 'N' },
    { word: 'acetic', word_class: 'N' },
    { word: 'acetate', word_class: 'N' },
    { word: 'acetic', word_class: 'AJ' },
    { word: 'acetous', word_class: 'AJ' }
  ],
  adversely: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  support: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  phenomena: [
    { word: 'phenomena', word_class: 'N' },
    { word: 'phenomenal', word_class: 'AJ' },
    { word: 'phenomenally', word_class: 'AV' }
  ],
  visualise: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  periodontal: [
    { word: 'periodontal', word_class: 'AJ' },
    { word: 'periodontic', word_class: 'AJ' },
    { word: 'periodontics', word_class: 'N' },
    { word: 'periodontitis', word_class: 'N' }
  ],
  days: [
    { word: 'day', word_class: 'N' },
    { word: 'dais', word_class: 'N' },
    { word: 'days', word_class: 'N' },
    { word: 'daily', word_class: 'N' },
    { word: 'daily', word_class: 'AJ' },
    { word: 'daily', word_class: 'AV' }
  ],
  liquidize: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  plentifulness: [
    { word: 'plenty', word_class: 'N' },
    { word: 'plenty', word_class: 'AJ' },
    { word: 'plenty', word_class: 'AV' },
    { word: 'plenteous', word_class: 'AJ' },
    { word: 'plentiful', word_class: 'AJ' },
    { word: 'plentifulness', word_class: 'N' }
  ],
  hibernation: [
    { word: 'hibernal', word_class: 'AJ' },
    { word: 'hibernate', word_class: 'V' },
    { word: 'hibernation', word_class: 'N' },
    { word: 'hibernating', word_class: 'AJ' }
  ],
  gregariously: [
    { word: 'gregarious', word_class: 'AJ' },
    { word: 'gregariously', word_class: 'AV' },
    { word: 'gregariousness', word_class: 'N' }
  ],
  sternutator: [
    { word: 'sternutator', word_class: 'N' },
    { word: 'sternutation', word_class: 'N' },
    { word: 'sternutative', word_class: 'AJ' }
  ],
  susurrant: [
    { word: 'susurrate', word_class: 'V' },
    { word: 'susurrant', word_class: 'AJ' },
    { word: 'susurrous', word_class: 'AJ' },
    { word: 'susurration', word_class: 'N' }
  ],
  vanquish: [
    { word: 'vanquish', word_class: 'V' },
    { word: 'vanquisher', word_class: 'N' },
    { word: 'vanquished', word_class: 'AJ' },
    { word: 'vanquishment', word_class: 'N' },
    { word: 'vanquishable', word_class: 'AJ' }
  ],
  infuriation: [
    { word: 'infuriate', word_class: 'V' },
    { word: 'infuriated', word_class: 'AJ' },
    { word: 'infuriation', word_class: 'N' },
    { word: 'infuriating', word_class: 'AJ' }
  ],
  crucial: [
    { word: 'crucial', word_class: 'AJ' },
    { word: 'cruciality', word_class: 'N' },
    { word: 'crucially', word_class: 'AV' }
  ],
  shaped: [
    { word: 'shape', word_class: 'N' },
    { word: 'shape', word_class: 'V' },
    { word: 'shaper', word_class: 'N' },
    { word: 'shaped', word_class: 'AJ' },
    { word: 'shaping', word_class: 'N' },
    { word: 'shapely', word_class: 'AJ' },
    { word: 'shaping', word_class: 'AJ' }
  ],
  resister: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  differentiation: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  lurking: [
    { word: 'lurk', word_class: 'V' },
    { word: 'lurker', word_class: 'N' },
    { word: 'lurking', word_class: 'AJ' }
  ],
  solicited: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  cryogenics: [
    { word: 'cryogen', word_class: 'N' },
    { word: 'cryogenic', word_class: 'AJ' },
    { word: 'cryogenics', word_class: 'N' }
  ],
  excusable: [
    { word: 'excuse', word_class: 'N' },
    { word: 'excuse', word_class: 'V' },
    { word: 'excused', word_class: 'AJ' },
    { word: 'excusable', word_class: 'AJ' },
    { word: 'excusably', word_class: 'AV' }
  ],
  continuousness: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  lightsome: [
    { word: 'lightsome', word_class: 'AJ' },
    { word: 'lightsomely', word_class: 'AV' },
    { word: 'lightsomeness', word_class: 'N' }
  ],
  interdependent: [
    { word: 'interdepend', word_class: 'V' },
    { word: 'interdependence', word_class: 'N' },
    { word: 'interdependency', word_class: 'N' },
    { word: 'interdependent', word_class: 'AJ' }
  ],
  eternally: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  scrabbly: [
    { word: 'scrabble', word_class: 'N' },
    { word: 'scrabble', word_class: 'V' },
    { word: 'scrabbly', word_class: 'AJ' }
  ],
  considerate: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  lutheranism: [
    { word: 'lutheran', word_class: 'N' },
    { word: 'lutheran', word_class: 'AJ' },
    { word: 'lutheranism', word_class: 'N' }
  ],
  corned: [{ word: 'corn', word_class: 'N' }, { word: 'corn', word_class: 'V' }, { word: 'corned', word_class: 'AJ' }],
  pertinency: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  vacillation: [
    { word: 'vacillate', word_class: 'V' },
    { word: 'vacillant', word_class: 'AJ' },
    { word: 'vacillator', word_class: 'N' },
    { word: 'vacillation', word_class: 'N' },
    { word: 'vacillating', word_class: 'AJ' }
  ],
  tabular: [
    { word: 'table', word_class: 'N' },
    { word: 'table', word_class: 'V' },
    { word: 'tableful', word_class: 'N' },
    { word: 'tabular', word_class: 'AJ' },
    { word: 'tableful', word_class: 'AJ' },
    { word: 'tabularize', word_class: 'V' }
  ],
  beater: [
    { word: 'beat', word_class: 'N' },
    { word: 'beat', word_class: 'V' },
    { word: 'beat', word_class: 'AJ' },
    { word: 'beater', word_class: 'N' },
    { word: 'beaten', word_class: 'AJ' },
    { word: 'beating', word_class: 'N' },
    { word: 'beating', word_class: 'AJ' }
  ],
  alarmism: [
    { word: 'alarm', word_class: 'N' },
    { word: 'alarm', word_class: 'V' },
    { word: 'alarmism', word_class: 'N' },
    { word: 'alarmist', word_class: 'N' },
    { word: 'alarmed', word_class: 'AJ' },
    { word: 'alarming', word_class: 'AJ' }
  ],
  diazotise: [
    { word: 'diazotise', word_class: 'V' },
    { word: 'diazotize', word_class: 'V' },
    { word: 'diazotisation', word_class: 'N' },
    { word: 'diazotization', word_class: 'N' }
  ],
  impracticably: [
    { word: 'impracticable', word_class: 'AJ' },
    { word: 'impracticably', word_class: 'AV' },
    { word: 'impracticability', word_class: 'N' },
    { word: 'impracticableness', word_class: 'N' }
  ],
  tie: [
    { word: 'tie', word_class: 'N' },
    { word: 'tie', word_class: 'V' },
    { word: 'tier', word_class: 'N' },
    { word: 'tying', word_class: 'N' },
    { word: 'tying', word_class: 'V' }
  ],
  exceed: [
    { word: 'exceed', word_class: 'V' },
    { word: 'excess', word_class: 'N' },
    { word: 'excess', word_class: 'AJ' },
    { word: 'excessive', word_class: 'AJ' },
    { word: 'excessively', word_class: 'AV' },
    { word: 'excessiveness', word_class: 'N' }
  ],
  recommit: [
    { word: 'recommit', word_class: 'V' },
    { word: 'recommittal', word_class: 'N' },
    { word: 'recommitment', word_class: 'N' }
  ],
  strapping: [
    { word: 'strap', word_class: 'N' },
    { word: 'strap', word_class: 'V' },
    { word: 'strapping', word_class: 'AJ' }
  ],
  returning: [
    { word: 'return', word_class: 'N' },
    { word: 'return', word_class: 'V' },
    { word: 'returning', word_class: 'AJ' },
    { word: 'returnable', word_class: 'AJ' }
  ],
  credulously: [
    { word: 'credulity', word_class: 'N' },
    { word: 'credulous', word_class: 'AJ' },
    { word: 'credulously', word_class: 'AV' },
    { word: 'credulousness', word_class: 'N' }
  ],
  stigmatism: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  polyphonic: [
    { word: 'polyphone', word_class: 'N' },
    { word: 'polyphonic', word_class: 'AJ' },
    { word: 'polyphonous', word_class: 'AJ' },
    { word: 'polyphonically', word_class: 'AV' }
  ],
  dialectics: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  an: [{ word: 'an', word_class: 'N' }, { word: 'ans', word_class: 'N' }, { word: 'ane', word_class: 'AJ' }],
  menstruate: [
    { word: 'menstruate', word_class: 'V' },
    { word: 'menstruation', word_class: 'N' },
    { word: 'menstruating', word_class: 'AJ' }
  ],
  corrigible: [
    { word: 'corrigible', word_class: 'AJ' },
    { word: 'corrigibly', word_class: 'AV' },
    { word: 'corrigibility', word_class: 'N' }
  ],
  categoric: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  truthfulness: [
    { word: 'true', word_class: 'N' },
    { word: 'true', word_class: 'V' },
    { word: 'true', word_class: 'AJ' },
    { word: 'truth', word_class: 'N' },
    { word: 'true', word_class: 'AV' },
    { word: 'truly', word_class: 'AV' },
    { word: 'truthful', word_class: 'AJ' },
    { word: 'truthfulness', word_class: 'N' }
  ],
  anthropoid: [
    { word: 'anthropoid', word_class: 'N' },
    { word: 'anthropoid', word_class: 'AJ' },
    { word: 'anthropoidal', word_class: 'AJ' }
  ],
  spilth: [
    { word: 'spill', word_class: 'N' },
    { word: 'spill', word_class: 'V' },
    { word: 'spilth', word_class: 'N' },
    { word: 'spillage', word_class: 'N' }
  ],
  transmittance: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  retardment: [
    { word: 'retard', word_class: 'N' },
    { word: 'retard', word_class: 'V' },
    { word: 'retarded', word_class: 'AJ' },
    { word: 'retardment', word_class: 'N' },
    { word: 'retardation', word_class: 'N' }
  ],
  handed: [
    { word: 'hand', word_class: 'N' },
    { word: 'hand', word_class: 'V' },
    { word: 'hands', word_class: 'N' },
    { word: 'handed', word_class: 'AJ' },
    { word: 'handful', word_class: 'N' },
    { word: 'handful', word_class: 'AJ' }
  ],
  chilblains: [
    { word: 'chilblain', word_class: 'N' },
    { word: 'chilblains', word_class: 'N' },
    { word: 'chilblained', word_class: 'AJ' }
  ],
  winking: [
    { word: 'wink', word_class: 'N' },
    { word: 'wink', word_class: 'V' },
    { word: 'winker', word_class: 'N' },
    { word: 'winking', word_class: 'AJ' }
  ],
  patriarchic: [
    { word: 'patriarch', word_class: 'N' },
    { word: 'patriarchs', word_class: 'N' },
    { word: 'patriarchal', word_class: 'AJ' },
    { word: 'patriarchate', word_class: 'N' },
    { word: 'patriarchic', word_class: 'AJ' }
  ],
  coinciding: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  breakable: [
    { word: 'breakable', word_class: 'N' },
    { word: 'breakable', word_class: 'AJ' },
    { word: 'breakability', word_class: 'N' },
    { word: 'breakableness', word_class: 'N' }
  ],
  disinherited: [
    { word: 'disinherit', word_class: 'V' },
    { word: 'disinherited', word_class: 'AJ' },
    { word: 'disinheritance', word_class: 'N' }
  ],
  gastronome: [
    { word: 'gastronome', word_class: 'N' },
    { word: 'gastronomic', word_class: 'AJ' },
    { word: 'gastronomical', word_class: 'AJ' }
  ],
  trespassing: [
    { word: 'trespass', word_class: 'N' },
    { word: 'trespass', word_class: 'V' },
    { word: 'trespasser', word_class: 'N' },
    { word: 'trespassing', word_class: 'AJ' }
  ],
  recognizably: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  edged: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  wounded: [
    { word: 'wound', word_class: 'N' },
    { word: 'wound', word_class: 'V' },
    { word: 'wound', word_class: 'AJ' },
    { word: 'wounded', word_class: 'N' },
    { word: 'wounded', word_class: 'AJ' },
    { word: 'wounding', word_class: 'N' },
    { word: 'wounding', word_class: 'AJ' }
  ],
  scrupulous: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  darting: [
    { word: 'dart', word_class: 'N' },
    { word: 'dart', word_class: 'V' },
    { word: 'darts', word_class: 'N' },
    { word: 'darter', word_class: 'N' },
    { word: 'darting', word_class: 'AJ' }
  ],
  immobile: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  unlivable: [
    { word: 'unlive', word_class: 'V' },
    { word: 'unlivable', word_class: 'AJ' },
    { word: 'unliveable', word_class: 'AJ' }
  ],
  reconcilable: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  abrasion: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  audition: [
    { word: 'audit', word_class: 'N' },
    { word: 'audit', word_class: 'V' },
    { word: 'auditor', word_class: 'N' },
    { word: 'audition', word_class: 'N' },
    { word: 'audition', word_class: 'V' },
    { word: 'auditive', word_class: 'AJ' }
  ],
  jam: [
    { word: 'jam', word_class: 'N' },
    { word: 'jam', word_class: 'V' },
    { word: 'jammed', word_class: 'AJ' },
    { word: 'jamming', word_class: 'N' }
  ],
  plague: [
    { word: 'plague', word_class: 'N' },
    { word: 'plague', word_class: 'V' },
    { word: 'plagued', word_class: 'AJ' }
  ],
  unfavourableness: [
    { word: 'unfavourable', word_class: 'AJ' },
    { word: 'unfavourably', word_class: 'AV' },
    { word: 'unfavourableness', word_class: 'N' }
  ],
  minded: [
    { word: 'mind', word_class: 'N' },
    { word: 'mind', word_class: 'V' },
    { word: 'minder', word_class: 'N' },
    { word: 'minded', word_class: 'AJ' },
    { word: 'mindful', word_class: 'AJ' },
    { word: 'mindfulness', word_class: 'N' }
  ],
  projection: [
    { word: 'project', word_class: 'N' },
    { word: 'project', word_class: 'V' },
    { word: 'projector', word_class: 'N' },
    { word: 'projected', word_class: 'AJ' },
    { word: 'projection', word_class: 'N' },
    { word: 'projecting', word_class: 'AJ' }
  ],
  disable: [
    { word: 'disable', word_class: 'N' },
    { word: 'disable', word_class: 'V' },
    { word: 'disabled', word_class: 'N' },
    { word: 'disable', word_class: 'AJ' },
    { word: 'disabled', word_class: 'AJ' },
    { word: 'disability', word_class: 'N' },
    { word: 'disabling', word_class: 'AJ' },
    { word: 'disablement', word_class: 'N' }
  ],
  suggestible: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  wasting: [
    { word: 'waste', word_class: 'N' },
    { word: 'waste', word_class: 'V' },
    { word: 'waste', word_class: 'AJ' },
    { word: 'waster', word_class: 'N' },
    { word: 'wasted', word_class: 'AJ' },
    { word: 'wasting', word_class: 'N' },
    { word: 'wasteful', word_class: 'AJ' },
    { word: 'wastefulness', word_class: 'N' }
  ],
  lectureship: [
    { word: 'lecture', word_class: 'N' },
    { word: 'lecture', word_class: 'V' },
    { word: 'lecturer', word_class: 'N' },
    { word: 'lecturing', word_class: 'N' },
    { word: 'lectureship', word_class: 'N' }
  ],
  wesleyanism: [
    { word: 'wesleyan', word_class: 'N' },
    { word: 'wesleyan', word_class: 'AJ' },
    { word: 'wesleyanism', word_class: 'N' }
  ],
  poise: [{ word: 'poise', word_class: 'N' }, { word: 'poise', word_class: 'V' }, { word: 'poised', word_class: 'AJ' }],
  unfavorably: [
    { word: 'unfavorable', word_class: 'AJ' },
    { word: 'unfavorably', word_class: 'AV' },
    { word: 'unfavorableness', word_class: 'N' }
  ],
  dissidence: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  abounding: [
    { word: 'abound', word_class: 'V' },
    { word: 'abundance', word_class: 'N' },
    { word: 'abundant', word_class: 'AJ' },
    { word: 'abounding', word_class: 'AJ' },
    { word: 'abundantly', word_class: 'AV' }
  ],
  hysterically: [
    { word: 'hysteric', word_class: 'AJ' },
    { word: 'hysterics', word_class: 'N' },
    { word: 'hysterical', word_class: 'AJ' },
    { word: 'hysterically', word_class: 'AV' }
  ],
  lecture: [
    { word: 'lecture', word_class: 'N' },
    { word: 'lecture', word_class: 'V' },
    { word: 'lecturer', word_class: 'N' },
    { word: 'lecturing', word_class: 'N' },
    { word: 'lectureship', word_class: 'N' }
  ],
  ignoble: [
    { word: 'ignoble', word_class: 'AJ' },
    { word: 'ignobly', word_class: 'AV' },
    { word: 'ignobleness', word_class: 'N' }
  ],
  capsulation: [
    { word: 'capsule', word_class: 'N' },
    { word: 'capsulate', word_class: 'V' },
    { word: 'capsulate', word_class: 'AJ' },
    { word: 'capsulated', word_class: 'AJ' },
    { word: 'capsulation', word_class: 'N' }
  ],
  posterity: [
    { word: 'post', word_class: 'N' },
    { word: 'post', word_class: 'V' },
    { word: 'post', word_class: 'AJ' },
    { word: 'poster', word_class: 'N' },
    { word: 'poster', word_class: 'V' },
    { word: 'posting', word_class: 'N' },
    { word: 'postman', word_class: 'N' },
    { word: 'posterity', word_class: 'N' }
  ],
  permutableness: [
    { word: 'permute', word_class: 'V' },
    { word: 'permutation', word_class: 'N' },
    { word: 'permutability', word_class: 'N' },
    { word: 'permutableness', word_class: 'N' }
  ],
  aire: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  ripened: [
    { word: 'ripen', word_class: 'V' },
    { word: 'ripened', word_class: 'AJ' },
    { word: 'ripening', word_class: 'N' }
  ],
  statistic: [
    { word: 'statistic', word_class: 'N' },
    { word: 'statistic', word_class: 'AJ' },
    { word: 'statistics', word_class: 'N' },
    { word: 'statistical', word_class: 'AJ' },
    { word: 'statistically', word_class: 'AV' }
  ],
  sonny: [{ word: 'son', word_class: 'N' }, { word: 'sonny', word_class: 'N' }, { word: 'sonny', word_class: 'AJ' }],
  bacteroid: [
    { word: 'bacteroid', word_class: 'N' },
    { word: 'bacteroid', word_class: 'AJ' },
    { word: 'bacteroides', word_class: 'N' },
    { word: 'bacteroidal', word_class: 'AJ' }
  ],
  cognizable: [
    { word: 'cognize', word_class: 'V' },
    { word: 'cognizance', word_class: 'N' },
    { word: 'cognizant', word_class: 'AJ' },
    { word: 'cognizable', word_class: 'AJ' }
  ],
  doddering: [
    { word: 'dodder', word_class: 'N' },
    { word: 'dodder', word_class: 'V' },
    { word: 'dodderer', word_class: 'N' },
    { word: 'doddering', word_class: 'AJ' }
  ],
  enchant: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  subservient: [
    { word: 'subserve', word_class: 'V' },
    { word: 'subservience', word_class: 'N' },
    { word: 'subservient', word_class: 'AJ' },
    { word: 'subserviently', word_class: 'AV' },
    { word: 'subservientness', word_class: 'N' }
  ],
  acquaintance: [
    { word: 'acquaint', word_class: 'V' },
    { word: 'acquainted', word_class: 'AJ' },
    { word: 'acquaintance', word_class: 'N' }
  ],
  power: [
    { word: 'power', word_class: 'N' },
    { word: 'power', word_class: 'V' },
    { word: 'power', word_class: 'AJ' },
    { word: 'powered', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AV' },
    { word: 'powerfulness', word_class: 'N' }
  ],
  emboldenment: [
    { word: 'embold', word_class: 'V' },
    { word: 'embolden', word_class: 'V' },
    { word: 'embolden', word_class: 'AJ' },
    { word: 'emboldened', word_class: 'AJ' },
    { word: 'emboldenment', word_class: 'N' }
  ],
  fewness: [{ word: 'few', word_class: 'N' }, { word: 'few', word_class: 'AJ' }, { word: 'fewness', word_class: 'N' }],
  patentee: [
    { word: 'patent', word_class: 'N' },
    { word: 'patent', word_class: 'V' },
    { word: 'patent', word_class: 'AJ' },
    { word: 'patentee', word_class: 'N' },
    { word: 'patented', word_class: 'AJ' },
    { word: 'patently', word_class: 'AV' }
  ],
  meliorism: [
    { word: 'meliorate', word_class: 'V' },
    { word: 'meliorism', word_class: 'N' },
    { word: 'melioration', word_class: 'N' },
    { word: 'meliorative', word_class: 'AJ' }
  ],
  exclusion: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  etymologization: [
    { word: 'etymology', word_class: 'N' },
    { word: 'etymologize', word_class: 'V' },
    { word: 'etymological', word_class: 'AJ' },
    { word: 'etymologizing', word_class: 'N' },
    { word: 'etymologization', word_class: 'N' }
  ],
  illiberality: [
    { word: 'illiberal', word_class: 'AJ' },
    { word: 'illiberality', word_class: 'N' },
    { word: 'illiberally', word_class: 'AV' }
  ],
  remiss: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  indolently: [
    { word: 'indolence', word_class: 'N' },
    { word: 'indolent', word_class: 'AJ' },
    { word: 'indolently', word_class: 'AV' }
  ],
  audibly: [
    { word: 'audible', word_class: 'AJ' },
    { word: 'audibly', word_class: 'AV' },
    { word: 'audibility', word_class: 'N' },
    { word: 'audibleness', word_class: 'N' }
  ],
  modulated: [
    { word: 'module', word_class: 'N' },
    { word: 'modular', word_class: 'AJ' },
    { word: 'modulate', word_class: 'V' },
    { word: 'modulated', word_class: 'AJ' },
    { word: 'modulation', word_class: 'N' }
  ],
  camouflage: [
    { word: 'camouflage', word_class: 'N' },
    { word: 'camouflage', word_class: 'V' },
    { word: 'camouflaged', word_class: 'AJ' }
  ],
  enchanter: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  communicative: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  laboured: [
    { word: 'labour', word_class: 'N' },
    { word: 'labour', word_class: 'V' },
    { word: 'labourer', word_class: 'N' },
    { word: 'laboured', word_class: 'AJ' },
    { word: 'labourite', word_class: 'N' },
    { word: 'labouring', word_class: 'AJ' }
  ],
  defensibly: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  perceivable: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  envision: [
    { word: 'envision', word_class: 'V' },
    { word: 'envisioned', word_class: 'AJ' },
    { word: 'envisioning', word_class: 'N' }
  ],
  expositive: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  denotation: [
    { word: 'denote', word_class: 'N' },
    { word: 'denote', word_class: 'V' },
    { word: 'denotive', word_class: 'AJ' },
    { word: 'denotation', word_class: 'N' },
    { word: 'denotement', word_class: 'N' },
    { word: 'denotative', word_class: 'AJ' }
  ],
  recursive: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  soloist: [{ word: 'solo', word_class: 'N' }, { word: 'solo', word_class: 'V' }, { word: 'soloist', word_class: 'N' }],
  nucleation: [
    { word: 'nucleus', word_class: 'N' },
    { word: 'nucleate', word_class: 'V' },
    { word: 'nucleation', word_class: 'N' }
  ],
  famed: [{ word: 'fame', word_class: 'N' }, { word: 'fame', word_class: 'V' }, { word: 'famed', word_class: 'AJ' }],
  incredibly: [
    { word: 'incredible', word_class: 'AJ' },
    { word: 'incredibly', word_class: 'AV' },
    { word: 'incredibility', word_class: 'N' },
    { word: 'incredibleness', word_class: 'N' }
  ],
  stands: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  bilaterally: [
    { word: 'bilateral', word_class: 'AJ' },
    { word: 'bilateralism', word_class: 'N' },
    { word: 'bilaterality', word_class: 'N' },
    { word: 'bilaterally', word_class: 'AV' }
  ],
  mimic: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  deformity: [
    { word: 'deform', word_class: 'V' },
    { word: 'deformed', word_class: 'AJ' },
    { word: 'deformity', word_class: 'N' },
    { word: 'deformation', word_class: 'N' },
    { word: 'deformational', word_class: 'AJ' }
  ],
  stroke: [
    { word: 'stroke', word_class: 'N' },
    { word: 'stroke', word_class: 'V' },
    { word: 'stroking', word_class: 'N' }
  ],
  adolescence: [
    { word: 'adolescent', word_class: 'N' },
    { word: 'adolescence', word_class: 'N' },
    { word: 'adolescent', word_class: 'AJ' }
  ],
  here: [
    { word: 'here', word_class: 'N' },
    { word: 'here', word_class: 'AJ' },
    { word: 'here', word_class: 'AV' },
    { word: 'hereness', word_class: 'N' }
  ],
  crop: [{ word: 'crop', word_class: 'N' }, { word: 'crop', word_class: 'V' }, { word: 'cropped', word_class: 'AJ' }],
  convey: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  tapering: [
    { word: 'taper', word_class: 'N' },
    { word: 'taper', word_class: 'V' },
    { word: 'tapered', word_class: 'AJ' },
    { word: 'tapering', word_class: 'N' },
    { word: 'tapering', word_class: 'AJ' }
  ],
  disoblige: [
    { word: 'disoblige', word_class: 'V' },
    { word: 'disobliging', word_class: 'AJ' },
    { word: 'disobligation', word_class: 'N' }
  ],
  greasy: [
    { word: 'grease', word_class: 'N' },
    { word: 'grease', word_class: 'V' },
    { word: 'greaser', word_class: 'N' },
    { word: 'greasy', word_class: 'AJ' },
    { word: 'greased', word_class: 'AJ' },
    { word: 'greasiness', word_class: 'N' }
  ],
  impenetrability: [
    { word: 'impenetrable', word_class: 'AJ' },
    { word: 'impenetrability', word_class: 'N' },
    { word: 'impenetrableness', word_class: 'N' }
  ],
  bolted: [
    { word: 'bolt', word_class: 'N' },
    { word: 'bolt', word_class: 'V' },
    { word: 'bolt', word_class: 'AV' },
    { word: 'bolted', word_class: 'AJ' }
  ],
  republishing: [
    { word: 'republic', word_class: 'N' },
    { word: 'republic', word_class: 'AJ' },
    { word: 'republish', word_class: 'V' },
    { word: 'republishing', word_class: 'N' },
    { word: 'republication', word_class: 'N' }
  ],
  economise: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  immanent: [
    { word: 'immanence', word_class: 'N' },
    { word: 'immanency', word_class: 'N' },
    { word: 'immanent', word_class: 'AJ' }
  ],
  dishonourableness: [
    { word: 'dishonour', word_class: 'N' },
    { word: 'dishonour', word_class: 'V' },
    { word: 'dishonourable', word_class: 'AJ' },
    { word: 'dishonourably', word_class: 'AV' },
    { word: 'dishonourableness', word_class: 'N' }
  ],
  motorized: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  suppresser: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  palpability: [
    { word: 'palpable', word_class: 'AJ' },
    { word: 'palpably', word_class: 'AV' },
    { word: 'palpability', word_class: 'N' }
  ],
  sinuate: [
    { word: 'sinuate', word_class: 'V' },
    { word: 'sinuate', word_class: 'AJ' },
    { word: 'sinuation', word_class: 'N' }
  ],
  ripening: [
    { word: 'ripen', word_class: 'V' },
    { word: 'ripened', word_class: 'AJ' },
    { word: 'ripening', word_class: 'N' }
  ],
  eager: [
    { word: 'eager', word_class: 'N' },
    { word: 'eager', word_class: 'AJ' },
    { word: 'eagerness', word_class: 'N' }
  ],
  motility: [
    { word: 'motile', word_class: 'N' },
    { word: 'motile', word_class: 'AJ' },
    { word: 'motility', word_class: 'N' }
  ],
  reversed: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  pneumatic: [
    { word: 'pneumatic', word_class: 'AJ' },
    { word: 'pneumatics', word_class: 'N' },
    { word: 'pneumatically', word_class: 'AV' }
  ],
  metricize: [
    { word: 'metric', word_class: 'N' },
    { word: 'metric', word_class: 'AJ' },
    { word: 'metrical', word_class: 'AJ' },
    { word: 'metricize', word_class: 'V' },
    { word: 'metrically', word_class: 'AV' },
    { word: 'metrication', word_class: 'N' }
  ],
  ferned: [
    { word: 'fern', word_class: 'N' },
    { word: 'ferned', word_class: 'AJ' },
    { word: 'fernery', word_class: 'N' }
  ],
  confines: [
    { word: 'confine', word_class: 'V' },
    { word: 'confines', word_class: 'N' },
    { word: 'confined', word_class: 'AJ' },
    { word: 'confining', word_class: 'AJ' },
    { word: 'confinement', word_class: 'N' }
  ],
  sojourner: [
    { word: 'sojourn', word_class: 'N' },
    { word: 'sojourn', word_class: 'V' },
    { word: 'sojourner', word_class: 'N' }
  ],
  incorruptness: [
    { word: 'incorrupt', word_class: 'AJ' },
    { word: 'incorruption', word_class: 'N' },
    { word: 'incorruptness', word_class: 'N' },
    { word: 'incorruptible', word_class: 'AJ' },
    { word: 'incorruptibility', word_class: 'N' }
  ],
  idyll: [
    { word: 'idyll', word_class: 'N' },
    { word: 'idyllic', word_class: 'AJ' },
    { word: 'idyllically', word_class: 'AV' }
  ],
  protect: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  incite: [
    { word: 'incite', word_class: 'V' },
    { word: 'inciter', word_class: 'N' },
    { word: 'incitive', word_class: 'AJ' },
    { word: 'incitation', word_class: 'N' },
    { word: 'incitement', word_class: 'N' }
  ],
  dowsing: [
    { word: 'dowse', word_class: 'V' },
    { word: 'dowser', word_class: 'N' },
    { word: 'dowsing', word_class: 'N' }
  ],
  expertness: [
    { word: 'expert', word_class: 'N' },
    { word: 'expert', word_class: 'AJ' },
    { word: 'expertize', word_class: 'V' },
    { word: 'expertness', word_class: 'N' }
  ],
  dismissive: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  metalization: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  inhospitable: [
    { word: 'inhospitable', word_class: 'AJ' },
    { word: 'inhospitably', word_class: 'AV' },
    { word: 'inhospitableness', word_class: 'N' }
  ],
  sit: [{ word: 'sit', word_class: 'V' }, { word: 'sitting', word_class: 'N' }, { word: 'sitting', word_class: 'AJ' }],
  eyeful: [
    { word: 'eye', word_class: 'N' },
    { word: 'eye', word_class: 'V' },
    { word: 'eyes', word_class: 'N' },
    { word: 'eyed', word_class: 'AJ' },
    { word: 'eyeful', word_class: 'N' },
    { word: 'eyeful', word_class: 'AJ' }
  ],
  equalize: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  protract: [
    { word: 'protract', word_class: 'N' },
    { word: 'protract', word_class: 'V' },
    { word: 'protractor', word_class: 'N' },
    { word: 'protracted', word_class: 'AJ' },
    { word: 'protraction', word_class: 'N' },
    { word: 'protractible', word_class: 'AJ' }
  ],
  fastening: [
    { word: 'fasten', word_class: 'V' },
    { word: 'fastener', word_class: 'N' },
    { word: 'fastened', word_class: 'AJ' },
    { word: 'fastening', word_class: 'N' }
  ],
  dithering: [
    { word: 'dither', word_class: 'N' },
    { word: 'dither', word_class: 'V' },
    { word: 'dithering', word_class: 'N' }
  ],
  impugnable: [
    { word: 'impugn', word_class: 'V' },
    { word: 'impugnment', word_class: 'N' },
    { word: 'impugnable', word_class: 'AJ' },
    { word: 'impugnation', word_class: 'N' }
  ],
  tortured: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  routinization: [
    { word: 'routine', word_class: 'N' },
    { word: 'routine', word_class: 'AJ' },
    { word: 'routinize', word_class: 'V' },
    { word: 'routinely', word_class: 'AV' },
    { word: 'routinization', word_class: 'N' }
  ],
  mottling: [
    { word: 'mottle', word_class: 'N' },
    { word: 'mottle', word_class: 'V' },
    { word: 'mottled', word_class: 'AJ' },
    { word: 'mottling', word_class: 'N' }
  ],
  tartar: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  clashing: [
    { word: 'clash', word_class: 'N' },
    { word: 'clash', word_class: 'V' },
    { word: 'clashing', word_class: 'AJ' }
  ],
  flexibleness: [
    { word: 'flexible', word_class: 'AJ' },
    { word: 'flexibly', word_class: 'AV' },
    { word: 'flexibility', word_class: 'N' },
    { word: 'flexibleness', word_class: 'N' }
  ],
  requisiteness: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  segmentation: [
    { word: 'segment', word_class: 'N' },
    { word: 'segment', word_class: 'V' },
    { word: 'segmental', word_class: 'AJ' },
    { word: 'segmented', word_class: 'AJ' },
    { word: 'segmentation', word_class: 'N' }
  ],
  masochist: [
    { word: 'masochist', word_class: 'N' },
    { word: 'masochistic', word_class: 'AJ' },
    { word: 'masochistically', word_class: 'AV' }
  ],
  cocker: [
    { word: 'cock', word_class: 'N' },
    { word: 'cock', word_class: 'V' },
    { word: 'cocker', word_class: 'N' },
    { word: 'cocker', word_class: 'V' }
  ],
  liner: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  dichotomous: [
    { word: 'dichotomize', word_class: 'V' },
    { word: 'dichotomous', word_class: 'AJ' },
    { word: 'dichotomously', word_class: 'AV' },
    { word: 'dichotomization', word_class: 'N' }
  ],
  advocation: [
    { word: 'advocacy', word_class: 'N' },
    { word: 'advocate', word_class: 'N' },
    { word: 'advocate', word_class: 'V' },
    { word: 'advocation', word_class: 'N' }
  ],
  expire: [
    { word: 'expire', word_class: 'V' },
    { word: 'expiry', word_class: 'N' },
    { word: 'expired', word_class: 'AJ' },
    { word: 'expiring', word_class: 'AJ' },
    { word: 'expiration', word_class: 'N' }
  ],
  relentless: [
    { word: 'relent', word_class: 'N' },
    { word: 'relent', word_class: 'V' },
    { word: 'relentless', word_class: 'AJ' },
    { word: 'relentlessness', word_class: 'N' }
  ],
  repair: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  handful: [
    { word: 'hand', word_class: 'N' },
    { word: 'hand', word_class: 'V' },
    { word: 'hands', word_class: 'N' },
    { word: 'handed', word_class: 'AJ' },
    { word: 'handful', word_class: 'N' },
    { word: 'handful', word_class: 'AJ' }
  ],
  demander: [
    { word: 'demand', word_class: 'N' },
    { word: 'demand', word_class: 'V' },
    { word: 'demander', word_class: 'N' },
    { word: 'demanding', word_class: 'AJ' }
  ],
  cowed: [
    { word: 'cow', word_class: 'N' },
    { word: 'cow', word_class: 'V' },
    { word: 'cows', word_class: 'N' },
    { word: 'cowed', word_class: 'AJ' },
    { word: 'cowman', word_class: 'N' }
  ],
  abruption: [
    { word: 'abrupt', word_class: 'AJ' },
    { word: 'abruption', word_class: 'N' },
    { word: 'abruptness', word_class: 'N' }
  ],
  enmesh: [
    { word: 'enmesh', word_class: 'V' },
    { word: 'enmeshed', word_class: 'AJ' },
    { word: 'enmeshment', word_class: 'N' }
  ],
  legging: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  southwester: [
    { word: 'southwest', word_class: 'N' },
    { word: 'southwest', word_class: 'AJ' },
    { word: 'southwest', word_class: 'AV' },
    { word: 'southwester', word_class: 'N' }
  ],
  decimalisation: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  firm: [
    { word: 'firm', word_class: 'N' },
    { word: 'firm', word_class: 'V' },
    { word: 'firm', word_class: 'AJ' },
    { word: 'firm', word_class: 'AV' },
    { word: 'firmness', word_class: 'N' }
  ],
  class: [
    { word: 'class', word_class: 'N' },
    { word: 'class', word_class: 'V' },
    { word: 'classificatory', word_class: 'AJ' }
  ],
  appeal: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  paper: [
    { word: 'paper', word_class: 'N' },
    { word: 'paper', word_class: 'V' },
    { word: 'papers', word_class: 'N' },
    { word: 'paperer', word_class: 'N' },
    { word: 'papering', word_class: 'N' }
  ],
  formic: [
    { word: 'formic', word_class: 'AJ' },
    { word: 'formicate', word_class: 'V' },
    { word: 'formication', word_class: 'N' }
  ],
  demilitarise: [
    { word: 'demilitarise', word_class: 'V' },
    { word: 'demilitarize', word_class: 'V' },
    { word: 'demilitarisation', word_class: 'N' },
    { word: 'demilitarization', word_class: 'N' }
  ],
  cubist: [
    { word: 'cube', word_class: 'N' },
    { word: 'cube', word_class: 'V' },
    { word: 'cubism', word_class: 'N' },
    { word: 'cubist', word_class: 'N' },
    { word: 'cubist', word_class: 'AJ' },
    { word: 'cubistic', word_class: 'AJ' }
  ],
  documentation: [
    { word: 'document', word_class: 'N' },
    { word: 'document', word_class: 'V' },
    { word: 'documental', word_class: 'AJ' },
    { word: 'documented', word_class: 'AJ' },
    { word: 'documentation', word_class: 'N' }
  ],
  melted: [
    { word: 'melt', word_class: 'N' },
    { word: 'melt', word_class: 'V' },
    { word: 'molten', word_class: 'AJ' },
    { word: 'meltage', word_class: 'N' },
    { word: 'melted', word_class: 'AJ' },
    { word: 'melting', word_class: 'N' },
    { word: 'melting', word_class: 'AJ' }
  ],
  coinage: [
    { word: 'coin', word_class: 'N' },
    { word: 'coin', word_class: 'V' },
    { word: 'coiner', word_class: 'N' },
    { word: 'coinage', word_class: 'N' }
  ],
  initialisation: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  snowman: [{ word: 'snow', word_class: 'N' }, { word: 'snow', word_class: 'V' }, { word: 'snowman', word_class: 'N' }],
  podzolize: [
    { word: 'podzol', word_class: 'N' },
    { word: 'podzolize', word_class: 'V' },
    { word: 'podzolization', word_class: 'N' }
  ],
  versed: [
    { word: 'verse', word_class: 'N' },
    { word: 'verse', word_class: 'V' },
    { word: 'versed', word_class: 'AJ' }
  ],
  gummed: [
    { word: 'gum', word_class: 'N' },
    { word: 'gum', word_class: 'V' },
    { word: 'gummed', word_class: 'AJ' },
    { word: 'gumming', word_class: 'N' }
  ],
  civil: [
    { word: 'civil', word_class: 'AJ' },
    { word: 'civility', word_class: 'N' },
    { word: 'civilize', word_class: 'V' },
    { word: 'civilized', word_class: 'AJ' },
    { word: 'civilization', word_class: 'N' }
  ],
  lasting: [
    { word: 'last', word_class: 'N' },
    { word: 'last', word_class: 'V' },
    { word: 'last', word_class: 'AJ' },
    { word: 'last', word_class: 'AV' },
    { word: 'lasting', word_class: 'AJ' }
  ],
  extremity: [
    { word: 'extreme', word_class: 'N' },
    { word: 'extreme', word_class: 'AJ' },
    { word: 'extremism', word_class: 'N' },
    { word: 'extremity', word_class: 'N' },
    { word: 'extremely', word_class: 'AV' }
  ],
  catalogued: [
    { word: 'catalogue', word_class: 'N' },
    { word: 'catalogue', word_class: 'V' },
    { word: 'cataloguer', word_class: 'N' },
    { word: 'catalogued', word_class: 'AJ' }
  ],
  serene: [
    { word: 'serene', word_class: 'AJ' },
    { word: 'serenity', word_class: 'N' },
    { word: 'serenely', word_class: 'AV' }
  ],
  lauder: [
    { word: 'laud', word_class: 'V' },
    { word: 'lauder', word_class: 'N' },
    { word: 'laudator', word_class: 'N' },
    { word: 'laudation', word_class: 'N' },
    { word: 'laudatory', word_class: 'AJ' }
  ],
  sinusoidally: [
    { word: 'sinusoid', word_class: 'N' },
    { word: 'sinusoidal', word_class: 'AJ' },
    { word: 'sinusoidally', word_class: 'AV' }
  ],
  gossiper: [
    { word: 'gossip', word_class: 'N' },
    { word: 'gossip', word_class: 'V' },
    { word: 'gossiper', word_class: 'N' },
    { word: 'gossiping', word_class: 'N' }
  ],
  taunting: [
    { word: 'taunt', word_class: 'N' },
    { word: 'taunt', word_class: 'V' },
    { word: 'taunting', word_class: 'N' },
    { word: 'taunting', word_class: 'AJ' }
  ],
  humiliated: [
    { word: 'humiliate', word_class: 'V' },
    { word: 'humiliated', word_class: 'AJ' },
    { word: 'humiliation', word_class: 'N' },
    { word: 'humiliating', word_class: 'AJ' }
  ],
  conform: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  technically: [
    { word: 'technical', word_class: 'AJ' },
    { word: 'technicality', word_class: 'N' },
    { word: 'technically', word_class: 'AV' }
  ],
  compactness: [
    { word: 'compact', word_class: 'N' },
    { word: 'compact', word_class: 'V' },
    { word: 'compact', word_class: 'AJ' },
    { word: 'compaction', word_class: 'N' },
    { word: 'compactness', word_class: 'N' }
  ],
  avoidable: [
    { word: 'avoid', word_class: 'V' },
    { word: 'avoidance', word_class: 'N' },
    { word: 'avoidable', word_class: 'AJ' }
  ],
  lumper: [{ word: 'lump', word_class: 'N' }, { word: 'lump', word_class: 'V' }, { word: 'lumper', word_class: 'N' }],
  time: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  detest: [
    { word: 'detest', word_class: 'V' },
    { word: 'detested', word_class: 'AJ' },
    { word: 'detestable', word_class: 'AJ' },
    { word: 'detestably', word_class: 'AV' },
    { word: 'detestation', word_class: 'N' }
  ],
  diabetes: [
    { word: 'diabetes', word_class: 'N' },
    { word: 'diabetic', word_class: 'N' },
    { word: 'diabetic', word_class: 'AJ' }
  ],
  engraft: [
    { word: 'engraft', word_class: 'V' },
    { word: 'ingraft', word_class: 'V' },
    { word: 'engraftment', word_class: 'N' },
    { word: 'ingraftment', word_class: 'N' },
    { word: 'engraftation', word_class: 'N' },
    { word: 'ingraftation', word_class: 'N' }
  ],
  resulting: [
    { word: 'result', word_class: 'N' },
    { word: 'result', word_class: 'V' },
    { word: 'resultant', word_class: 'N' },
    { word: 'resultant', word_class: 'AJ' },
    { word: 'resulting', word_class: 'AJ' }
  ],
  republicanise: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  surroundings: [
    { word: 'surround', word_class: 'N' },
    { word: 'surround', word_class: 'V' },
    { word: 'surround', word_class: 'AJ' },
    { word: 'surrounded', word_class: 'AJ' },
    { word: 'surrounding', word_class: 'N' },
    { word: 'surrounding', word_class: 'AJ' },
    { word: 'surroundings', word_class: 'N' }
  ],
  embow: [
    { word: 'embow', word_class: 'V' },
    { word: 'embower', word_class: 'V' },
    { word: 'embowment', word_class: 'N' },
    { word: 'embowered', word_class: 'AJ' }
  ],
  lignify: [
    { word: 'lignify', word_class: 'V' },
    { word: 'lignified', word_class: 'AJ' },
    { word: 'lignification', word_class: 'N' }
  ],
  egression: [
    { word: 'egress', word_class: 'N' },
    { word: 'egress', word_class: 'V' },
    { word: 'egression', word_class: 'N' }
  ],
  vocalise: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  simpleness: [
    { word: 'simple', word_class: 'N' },
    { word: 'simple', word_class: 'AJ' },
    { word: 'simply', word_class: 'AV' },
    { word: 'simpleness', word_class: 'N' }
  ],
  amply: [
    { word: 'ample', word_class: 'AJ' },
    { word: 'amply', word_class: 'AJ' },
    { word: 'amply', word_class: 'AV' },
    { word: 'ampleness', word_class: 'N' }
  ],
  terror: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  genuflect: [
    { word: 'genuflect', word_class: 'V' },
    { word: 'genuflexion', word_class: 'N' },
    { word: 'genuflection', word_class: 'N' }
  ],
  instillator: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  expounding: [
    { word: 'expound', word_class: 'N' },
    { word: 'expound', word_class: 'V' },
    { word: 'expounding', word_class: 'N' }
  ],
  trichinize: [
    { word: 'trichinise', word_class: 'V' },
    { word: 'trichinize', word_class: 'V' },
    { word: 'trichinisation', word_class: 'N' },
    { word: 'trichinization', word_class: 'N' }
  ],
  blusher: [
    { word: 'blush', word_class: 'N' },
    { word: 'blush', word_class: 'V' },
    { word: 'blusher', word_class: 'N' },
    { word: 'blushful', word_class: 'AJ' },
    { word: 'blushing', word_class: 'AJ' }
  ],
  requisition: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  leap: [
    { word: 'leap', word_class: 'N' },
    { word: 'leap', word_class: 'V' },
    { word: 'leaping', word_class: 'N' },
    { word: 'leaping', word_class: 'AJ' }
  ],
  derogative: [
    { word: 'derogate', word_class: 'V' },
    { word: 'derogation', word_class: 'N' },
    { word: 'derogative', word_class: 'AJ' }
  ],
  astounding: [
    { word: 'astound', word_class: 'V' },
    { word: 'astounded', word_class: 'AJ' },
    { word: 'astounding', word_class: 'AJ' }
  ],
  brittle: [
    { word: 'brittle', word_class: 'N' },
    { word: 'brittle', word_class: 'AJ' },
    { word: 'brittleness', word_class: 'N' }
  ],
  renting: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  rationalisation: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  lain: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  botanist: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  cooperatively: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  costume: [
    { word: 'costume', word_class: 'N' },
    { word: 'costume', word_class: 'V' },
    { word: 'costume', word_class: 'AJ' },
    { word: 'costumed', word_class: 'AJ' }
  ],
  impermeableness: [
    { word: 'impermeable', word_class: 'AJ' },
    { word: 'impermeability', word_class: 'N' },
    { word: 'impermeableness', word_class: 'N' }
  ],
  fusee: [
    { word: 'fuse', word_class: 'N' },
    { word: 'fuse', word_class: 'V' },
    { word: 'fusee', word_class: 'N' },
    { word: 'fused', word_class: 'AJ' },
    { word: 'fusion', word_class: 'N' }
  ],
  reconcilement: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  tarry: [
    { word: 'tarry', word_class: 'V' },
    { word: 'tarry', word_class: 'AJ' },
    { word: 'tarrying', word_class: 'N' },
    { word: 'tarriance', word_class: 'N' }
  ],
  tranship: [
    { word: 'tranship', word_class: 'V' },
    { word: 'transship', word_class: 'N' },
    { word: 'transship', word_class: 'V' },
    { word: 'transhipment', word_class: 'N' },
    { word: 'transshipment', word_class: 'N' }
  ],
  tenable: [
    { word: 'tenable', word_class: 'AJ' },
    { word: 'tenably', word_class: 'AV' },
    { word: 'tenability', word_class: 'N' },
    { word: 'tenableness', word_class: 'N' }
  ],
  corrugated: [
    { word: 'corrugate', word_class: 'V' },
    { word: 'corrugated', word_class: 'AJ' },
    { word: 'corrugation', word_class: 'N' }
  ],
  segmented: [
    { word: 'segment', word_class: 'N' },
    { word: 'segment', word_class: 'V' },
    { word: 'segmental', word_class: 'AJ' },
    { word: 'segmented', word_class: 'AJ' },
    { word: 'segmentation', word_class: 'N' }
  ],
  digitisation: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  acrylate: [
    { word: 'acrylic', word_class: 'N' },
    { word: 'acrylate', word_class: 'N' },
    { word: 'acrylic', word_class: 'AJ' }
  ],
  impersonalize: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  evangelism: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  ask: [
    { word: 'ask', word_class: 'V' },
    { word: 'asker', word_class: 'N' },
    { word: 'asking', word_class: 'N' },
    { word: 'asking', word_class: 'AJ' }
  ],
  anticipated: [
    { word: 'anticipate', word_class: 'V' },
    { word: 'anticipant', word_class: 'AJ' },
    { word: 'anticipated', word_class: 'AJ' },
    { word: 'anticipation', word_class: 'N' },
    { word: 'anticipative', word_class: 'AJ' }
  ],
  slashing: [
    { word: 'slash', word_class: 'N' },
    { word: 'slash', word_class: 'V' },
    { word: 'slashed', word_class: 'AJ' },
    { word: 'slashing', word_class: 'N' },
    { word: 'slashing', word_class: 'AJ' }
  ],
  clangorous: [
    { word: 'clang', word_class: 'N' },
    { word: 'clang', word_class: 'V' },
    { word: 'clanger', word_class: 'N' },
    { word: 'clangor', word_class: 'N' },
    { word: 'clangor', word_class: 'V' },
    { word: 'clanging', word_class: 'AJ' },
    { word: 'clangoring', word_class: 'N' },
    { word: 'clangorous', word_class: 'AJ' }
  ],
  cost: [
    { word: 'cost', word_class: 'N' },
    { word: 'cost', word_class: 'V' },
    { word: 'costs', word_class: 'N' },
    { word: 'costing', word_class: 'N' }
  ],
  venge: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  contemporaneous: [
    { word: 'contemporize', word_class: 'V' },
    { word: 'contemporaneity', word_class: 'N' },
    { word: 'contemporaneous', word_class: 'AJ' },
    { word: 'contemporaneously', word_class: 'AV' },
    { word: 'contemporaneousness', word_class: 'N' }
  ],
  abominate: [
    { word: 'abominate', word_class: 'V' },
    { word: 'abominator', word_class: 'N' },
    { word: 'abominable', word_class: 'AJ' },
    { word: 'abominably', word_class: 'AV' },
    { word: 'abomination', word_class: 'N' }
  ],
  mortgagee: [
    { word: 'mortgage', word_class: 'N' },
    { word: 'mortgage', word_class: 'V' },
    { word: 'mortgagee', word_class: 'N' },
    { word: 'mortgagor', word_class: 'N' },
    { word: 'mortgaged', word_class: 'AJ' }
  ],
  diabolism: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  effector: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  rattling: [
    { word: 'rattle', word_class: 'N' },
    { word: 'rattle', word_class: 'V' },
    { word: 'rattly', word_class: 'AJ' },
    { word: 'rattler', word_class: 'N' },
    { word: 'rattled', word_class: 'AJ' },
    { word: 'rattling', word_class: 'N' },
    { word: 'rattling', word_class: 'AJ' },
    { word: 'rattling', word_class: 'AV' }
  ],
  favourableness: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  proposal: [
    { word: 'propose', word_class: 'N' },
    { word: 'propose', word_class: 'V' },
    { word: 'proposal', word_class: 'N' },
    { word: 'proposer', word_class: 'N' },
    { word: 'proposed', word_class: 'AJ' }
  ],
  holographical: [
    { word: 'holograph', word_class: 'N' },
    { word: 'holograph', word_class: 'AJ' },
    { word: 'holographic', word_class: 'AJ' },
    { word: 'holographical', word_class: 'AJ' }
  ],
  absolutism: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  inversely: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  demonism: [
    { word: 'demon', word_class: 'N' },
    { word: 'demonic', word_class: 'AJ' },
    { word: 'demonism', word_class: 'N' },
    { word: 'demonize', word_class: 'V' },
    { word: 'demonization', word_class: 'N' }
  ],
  roentgenisation: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  texture: [
    { word: 'texture', word_class: 'N' },
    { word: 'texture', word_class: 'V' },
    { word: 'textured', word_class: 'AJ' }
  ],
  confederate: [
    { word: 'confederate', word_class: 'N' },
    { word: 'confederate', word_class: 'V' },
    { word: 'confederate', word_class: 'AJ' },
    { word: 'confederation', word_class: 'N' }
  ],
  correlation: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  preposition: [
    { word: 'preposition', word_class: 'N' },
    { word: 'preposition', word_class: 'V' },
    { word: 'prepositional', word_class: 'AJ' }
  ],
  variant: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  anglicisation: [
    { word: 'anglicise', word_class: 'V' },
    { word: 'anglicism', word_class: 'N' },
    { word: 'anglicize', word_class: 'V' },
    { word: 'anglicisation', word_class: 'N' },
    { word: 'anglicization', word_class: 'N' }
  ],
  landholder: [
    { word: 'landholder', word_class: 'N' },
    { word: 'landholding', word_class: 'N' },
    { word: 'landholding', word_class: 'AJ' }
  ],
  warped: [
    { word: 'warp', word_class: 'N' },
    { word: 'warp', word_class: 'V' },
    { word: 'warped', word_class: 'AJ' },
    { word: 'warping', word_class: 'N' }
  ],
  grievous: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  curarisation: [
    { word: 'curare', word_class: 'N' },
    { word: 'curarise', word_class: 'V' },
    { word: 'curarize', word_class: 'V' },
    { word: 'curarisation', word_class: 'N' },
    { word: 'curarization', word_class: 'N' }
  ],
  asternal: [
    { word: 'astern', word_class: 'AJ' },
    { word: 'astern', word_class: 'AV' },
    { word: 'asternal', word_class: 'AJ' }
  ],
  winder: [
    { word: 'wind', word_class: 'N' },
    { word: 'wind', word_class: 'V' },
    { word: 'winder', word_class: 'N' },
    { word: 'winded', word_class: 'AJ' },
    { word: 'winding', word_class: 'N' },
    { word: 'winding', word_class: 'AJ' }
  ],
  embedment: [
    { word: 'embed', word_class: 'V' },
    { word: 'imbed', word_class: 'N' },
    { word: 'imbed', word_class: 'V' },
    { word: 'embedded', word_class: 'AJ' },
    { word: 'embedment', word_class: 'N' },
    { word: 'imbedment', word_class: 'N' }
  ],
  haphazard: [
    { word: 'haphazard', word_class: 'AJ' },
    { word: 'haphazard', word_class: 'AV' },
    { word: 'haphazardness', word_class: 'N' }
  ],
  charmed: [
    { word: 'charm', word_class: 'N' },
    { word: 'charm', word_class: 'V' },
    { word: 'charmer', word_class: 'N' },
    { word: 'charmed', word_class: 'AJ' },
    { word: 'charming', word_class: 'AJ' }
  ],
  script: [
    { word: 'script', word_class: 'N' },
    { word: 'script', word_class: 'V' },
    { word: 'scripted', word_class: 'AJ' }
  ],
  scouring: [
    { word: 'scour', word_class: 'N' },
    { word: 'scour', word_class: 'V' },
    { word: 'scours', word_class: 'N' },
    { word: 'scourer', word_class: 'N' },
    { word: 'scoured', word_class: 'AJ' },
    { word: 'scouring', word_class: 'N' }
  ],
  soaker: [
    { word: 'soak', word_class: 'N' },
    { word: 'soak', word_class: 'V' },
    { word: 'soaker', word_class: 'N' },
    { word: 'soaked', word_class: 'AJ' },
    { word: 'soaking', word_class: 'N' },
    { word: 'soaking', word_class: 'AJ' },
    { word: 'soaking', word_class: 'AV' }
  ],
  subserviently: [
    { word: 'subserve', word_class: 'V' },
    { word: 'subservience', word_class: 'N' },
    { word: 'subservient', word_class: 'AJ' },
    { word: 'subserviently', word_class: 'AV' },
    { word: 'subservientness', word_class: 'N' }
  ],
  appreciably: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  sermonization: [
    { word: 'sermon', word_class: 'N' },
    { word: 'sermonize', word_class: 'V' },
    { word: 'sermonizer', word_class: 'N' },
    { word: 'sermonization', word_class: 'N' }
  ],
  conveyed: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  hoofing: [
    { word: 'hoof', word_class: 'N' },
    { word: 'hoof', word_class: 'V' },
    { word: 'hoofed', word_class: 'AJ' },
    { word: 'hoofing', word_class: 'N' }
  ],
  spiritualism: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  vagueness: [
    { word: 'vagus', word_class: 'N' },
    { word: 'vague', word_class: 'AJ' },
    { word: 'vaguely', word_class: 'AV' },
    { word: 'vagueness', word_class: 'N' }
  ],
  cumulation: [
    { word: 'cumulate', word_class: 'V' },
    { word: 'cumulation', word_class: 'N' },
    { word: 'cumulative', word_class: 'AJ' },
    { word: 'cumulatively', word_class: 'AV' }
  ],
  skateboarding: [
    { word: 'skateboard', word_class: 'N' },
    { word: 'skateboard', word_class: 'V' },
    { word: 'skateboarder', word_class: 'N' },
    { word: 'skateboarding', word_class: 'N' }
  ],
  competing: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  distrainment: [
    { word: 'distrain', word_class: 'V' },
    { word: 'distraint', word_class: 'N' },
    { word: 'distrainment', word_class: 'N' }
  ],
  sherardise: [
    { word: 'sherardise', word_class: 'V' },
    { word: 'sherardize', word_class: 'V' },
    { word: 'sherardisation', word_class: 'N' },
    { word: 'sherardization', word_class: 'N' }
  ],
  spontaneously: [
    { word: 'spontaneity', word_class: 'N' },
    { word: 'spontaneous', word_class: 'AJ' },
    { word: 'spontaneously', word_class: 'AV' },
    { word: 'spontaneousness', word_class: 'N' }
  ],
  arrival: [
    { word: 'arrive', word_class: 'V' },
    { word: 'arrival', word_class: 'N' },
    { word: 'arriver', word_class: 'N' },
    { word: 'arriving', word_class: 'AJ' }
  ],
  envelope: [
    { word: 'envelop', word_class: 'V' },
    { word: 'envelope', word_class: 'N' },
    { word: 'envelope', word_class: 'V' },
    { word: 'enveloped', word_class: 'AJ' },
    { word: 'enveloping', word_class: 'N' },
    { word: 'enveloping', word_class: 'AJ' },
    { word: 'envelopment', word_class: 'N' }
  ],
  zipper: [
    { word: 'zipper', word_class: 'N' },
    { word: 'zipper', word_class: 'V' },
    { word: 'zippered', word_class: 'AJ' }
  ],
  survival: [
    { word: 'survive', word_class: 'V' },
    { word: 'survival', word_class: 'N' },
    { word: 'survivor', word_class: 'N' },
    { word: 'surviving', word_class: 'AJ' }
  ],
  nullified: [
    { word: 'nullify', word_class: 'V' },
    { word: 'nullified', word_class: 'AJ' },
    { word: 'nullification', word_class: 'N' }
  ],
  antecedence: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  flared: [
    { word: 'flare', word_class: 'N' },
    { word: 'flare', word_class: 'V' },
    { word: 'flared', word_class: 'AJ' },
    { word: 'flaring', word_class: 'AJ' }
  ],
  responsiveness: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  praising: [
    { word: 'praise', word_class: 'N' },
    { word: 'praise', word_class: 'V' },
    { word: 'praising', word_class: 'AJ' },
    { word: 'praiseful', word_class: 'AJ' }
  ],
  endear: [
    { word: 'endear', word_class: 'V' },
    { word: 'endearing', word_class: 'AJ' },
    { word: 'endearment', word_class: 'N' }
  ],
  putrescent: [
    { word: 'putrescence', word_class: 'N' },
    { word: 'putrescent', word_class: 'AJ' },
    { word: 'putrescible', word_class: 'AJ' }
  ],
  graveness: [
    { word: 'grave', word_class: 'N' },
    { word: 'grave', word_class: 'V' },
    { word: 'grave', word_class: 'AJ' },
    { word: 'gravely', word_class: 'AV' },
    { word: 'graveness', word_class: 'N' }
  ],
  exemplification: [
    { word: 'exemplify', word_class: 'V' },
    { word: 'exemplifying', word_class: 'AJ' },
    { word: 'exemplification', word_class: 'N' }
  ],
  barefooted: [
    { word: 'barefoot', word_class: 'AJ' },
    { word: 'barefoot', word_class: 'AV' },
    { word: 'barefooted', word_class: 'AJ' },
    { word: 'barefooted', word_class: 'AV' }
  ],
  recognition: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  pageant: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  perniciously: [
    { word: 'pernicious', word_class: 'AJ' },
    { word: 'perniciously', word_class: 'AV' },
    { word: 'perniciousness', word_class: 'N' }
  ],
  monopolize: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  inflexibility: [
    { word: 'inflexible', word_class: 'AJ' },
    { word: 'inflexibly', word_class: 'AV' },
    { word: 'inflexibility', word_class: 'N' },
    { word: 'inflexibleness', word_class: 'N' }
  ],
  fundamentally: [
    { word: 'fundament', word_class: 'N' },
    { word: 'fundamental', word_class: 'N' },
    { word: 'fundamental', word_class: 'AJ' },
    { word: 'fundamentals', word_class: 'N' },
    { word: 'fundamentalism', word_class: 'N' },
    { word: 'fundamentally', word_class: 'AV' }
  ],
  modernizing: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  aspiration: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  gainful: [
    { word: 'gain', word_class: 'N' },
    { word: 'gain', word_class: 'V' },
    { word: 'gainer', word_class: 'N' },
    { word: 'gainful', word_class: 'AJ' }
  ],
  alien: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  spitefulness: [
    { word: 'spite', word_class: 'N' },
    { word: 'spite', word_class: 'V' },
    { word: 'spiteful', word_class: 'AJ' },
    { word: 'spitefulness', word_class: 'N' }
  ],
  intrenchment: [
    { word: 'intrench', word_class: 'N' },
    { word: 'intrench', word_class: 'V' },
    { word: 'intrenchment', word_class: 'N' }
  ],
  unadapted: [
    { word: 'unadapted', word_class: 'AJ' },
    { word: 'unadaptable', word_class: 'AJ' },
    { word: 'unadaptability', word_class: 'N' }
  ],
  disheveled: [
    { word: 'dishevel', word_class: 'V' },
    { word: 'disheveled', word_class: 'AJ' },
    { word: 'dishevelled', word_class: 'AJ' },
    { word: 'dishevelment', word_class: 'N' }
  ],
  avow: [{ word: 'avow', word_class: 'V' }, { word: 'avowal', word_class: 'N' }, { word: 'avowed', word_class: 'AJ' }],
  pressure: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  refrigerant: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  junction: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  jumble: [
    { word: 'jumble', word_class: 'N' },
    { word: 'jumble', word_class: 'V' },
    { word: 'jumbled', word_class: 'AJ' }
  ],
  blister: [
    { word: 'blister', word_class: 'N' },
    { word: 'blister', word_class: 'V' },
    { word: 'blistered', word_class: 'AJ' },
    { word: 'blistering', word_class: 'AJ' }
  ],
  malingering: [
    { word: 'malinger', word_class: 'V' },
    { word: 'malingerer', word_class: 'N' },
    { word: 'malingering', word_class: 'N' }
  ],
  flutist: [
    { word: 'flute', word_class: 'N' },
    { word: 'flute', word_class: 'V' },
    { word: 'fluting', word_class: 'N' },
    { word: 'flutist', word_class: 'N' },
    { word: 'flautist', word_class: 'N' }
  ],
  spherical: [
    { word: 'spheric', word_class: 'AJ' },
    { word: 'spherical', word_class: 'AJ' },
    { word: 'sphericity', word_class: 'N' },
    { word: 'spherically', word_class: 'AV' },
    { word: 'sphericalness', word_class: 'N' }
  ],
  dawdler: [
    { word: 'dawdle', word_class: 'V' },
    { word: 'dawdler', word_class: 'N' },
    { word: 'dawdling', word_class: 'N' },
    { word: 'dawdling', word_class: 'AJ' }
  ],
  climber: [
    { word: 'climb', word_class: 'N' },
    { word: 'climb', word_class: 'V' },
    { word: 'climber', word_class: 'N' },
    { word: 'climbing', word_class: 'N' },
    { word: 'climbing', word_class: 'AJ' }
  ],
  suffuse: [
    { word: 'suffuse', word_class: 'V' },
    { word: 'suffused', word_class: 'AJ' },
    { word: 'suffusion', word_class: 'N' },
    { word: 'suffusive', word_class: 'AJ' }
  ],
  bind: [
    { word: 'bind', word_class: 'N' },
    { word: 'bind', word_class: 'V' },
    { word: 'binder', word_class: 'N' },
    { word: 'bindery', word_class: 'N' },
    { word: 'binding', word_class: 'N' },
    { word: 'bindery', word_class: 'AJ' },
    { word: 'binding', word_class: 'AJ' }
  ],
  rime: [
    { word: 'rime', word_class: 'N' },
    { word: 'rime', word_class: 'V' },
    { word: 'rimy', word_class: 'AJ' },
    { word: 'rimed', word_class: 'AJ' },
    { word: 'riming', word_class: 'AJ' }
  ],
  scrubs: [
    { word: 'scrub', word_class: 'N' },
    { word: 'scrub', word_class: 'V' },
    { word: 'scrub', word_class: 'AJ' },
    { word: 'scrubs', word_class: 'N' },
    { word: 'scrubbed', word_class: 'AJ' },
    { word: 'scrubbing', word_class: 'N' }
  ],
  wetness: [
    { word: 'wet', word_class: 'N' },
    { word: 'wet', word_class: 'V' },
    { word: 'wet', word_class: 'AJ' },
    { word: 'wetness', word_class: 'N' },
    { word: 'wetting', word_class: 'N' }
  ],
  weeping: [
    { word: 'weep', word_class: 'V' },
    { word: 'weepy', word_class: 'AJ' },
    { word: 'weeper', word_class: 'N' },
    { word: 'weeping', word_class: 'N' },
    { word: 'weeping', word_class: 'AJ' },
    { word: 'weepiness', word_class: 'N' }
  ],
  darter: [
    { word: 'dart', word_class: 'N' },
    { word: 'dart', word_class: 'V' },
    { word: 'darts', word_class: 'N' },
    { word: 'darter', word_class: 'N' },
    { word: 'darting', word_class: 'AJ' }
  ],
  lexical: [
    { word: 'lexical', word_class: 'AJ' },
    { word: 'lexicalize', word_class: 'V' },
    { word: 'lexically', word_class: 'AV' }
  ],
  conjoin: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  outfitted: [
    { word: 'outfit', word_class: 'N' },
    { word: 'outfit', word_class: 'V' },
    { word: 'outfitted', word_class: 'AJ' },
    { word: 'outfitting', word_class: 'N' }
  ],
  smooching: [
    { word: 'smooch', word_class: 'N' },
    { word: 'smooch', word_class: 'V' },
    { word: 'smooching', word_class: 'N' }
  ],
  tamer: [
    { word: 'tame', word_class: 'V' },
    { word: 'tame', word_class: 'AJ' },
    { word: 'tamer', word_class: 'N' },
    { word: 'tamed', word_class: 'AJ' },
    { word: 'tamely', word_class: 'AV' },
    { word: 'tameness', word_class: 'N' }
  ],
  fore: [{ word: 'fore', word_class: 'N' }, { word: 'fore', word_class: 'AJ' }, { word: 'fore', word_class: 'AV' }],
  quantize: [
    { word: 'quantise', word_class: 'V' },
    { word: 'quantize', word_class: 'V' },
    { word: 'quantisation', word_class: 'N' },
    { word: 'quantization', word_class: 'N' }
  ],
  disembodiment: [
    { word: 'disembody', word_class: 'V' },
    { word: 'disembodied', word_class: 'AJ' },
    { word: 'disembodiment', word_class: 'N' }
  ],
  contemptuously: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  passionate: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  perambulator: [
    { word: 'perambulate', word_class: 'V' },
    { word: 'perambulator', word_class: 'N' },
    { word: 'perambulation', word_class: 'N' },
    { word: 'perambulating', word_class: 'AJ' }
  ],
  deadliness: [
    { word: 'dead', word_class: 'N' },
    { word: 'dead', word_class: 'AJ' },
    { word: 'dead', word_class: 'AV' },
    { word: 'deadly', word_class: 'AJ' },
    { word: 'deadly', word_class: 'AV' },
    { word: 'deadness', word_class: 'N' },
    { word: 'deadliness', word_class: 'N' }
  ],
  imperativeness: [
    { word: 'imperative', word_class: 'N' },
    { word: 'imperative', word_class: 'AJ' },
    { word: 'imperatively', word_class: 'AV' },
    { word: 'imperativeness', word_class: 'N' }
  ],
  ailment: [
    { word: 'ail', word_class: 'N' },
    { word: 'ail', word_class: 'V' },
    { word: 'ailing', word_class: 'AJ' },
    { word: 'ailment', word_class: 'N' }
  ],
  place: [
    { word: 'place', word_class: 'N' },
    { word: 'place', word_class: 'V' },
    { word: 'placer', word_class: 'N' },
    { word: 'placed', word_class: 'AJ' },
    { word: 'placeman', word_class: 'N' },
    { word: 'placement', word_class: 'N' }
  ],
  thoughtfulness: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  transmissible: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  slews: [{ word: 'slew', word_class: 'N' }, { word: 'slew', word_class: 'V' }, { word: 'slews', word_class: 'N' }],
  emotionally: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  voluntary: [
    { word: 'voluntary', word_class: 'N' },
    { word: 'voluntary', word_class: 'AJ' },
    { word: 'voluntarism', word_class: 'N' }
  ],
  antique: [
    { word: 'antique', word_class: 'N' },
    { word: 'antique', word_class: 'V' },
    { word: 'antique', word_class: 'AJ' },
    { word: 'antiquate', word_class: 'V' },
    { word: 'antiquity', word_class: 'N' },
    { word: 'antiquated', word_class: 'AJ' },
    { word: 'antiquation', word_class: 'N' }
  ],
  field: [
    { word: 'field', word_class: 'N' },
    { word: 'field', word_class: 'V' },
    { word: 'fielder', word_class: 'N' },
    { word: 'fielding', word_class: 'N' }
  ],
  fit: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  swing: [
    { word: 'swing', word_class: 'N' },
    { word: 'swing', word_class: 'V' },
    { word: 'swinge', word_class: 'V' },
    { word: 'swinger', word_class: 'N' },
    { word: 'swinging', word_class: 'N' },
    { word: 'swinging', word_class: 'AJ' },
    { word: 'swingeing', word_class: 'AJ' }
  ],
  penetration: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  christianize: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  treatment: [
    { word: 'treat', word_class: 'N' },
    { word: 'treat', word_class: 'V' },
    { word: 'treated', word_class: 'AJ' },
    { word: 'treatment', word_class: 'N' }
  ],
  ribbed: [
    { word: 'rib', word_class: 'N' },
    { word: 'rib', word_class: 'V' },
    { word: 'ribbed', word_class: 'AJ' },
    { word: 'ribbing', word_class: 'N' }
  ],
  remittal: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  emancipating: [
    { word: 'emancipate', word_class: 'V' },
    { word: 'emancipator', word_class: 'N' },
    { word: 'emancipation', word_class: 'N' },
    { word: 'emancipated', word_class: 'AJ' },
    { word: 'emancipating', word_class: 'AJ' },
    { word: 'emancipative', word_class: 'AJ' }
  ],
  transparently: [
    { word: 'tranparent', word_class: 'AJ' },
    { word: 'transparent', word_class: 'N' },
    { word: 'transparent', word_class: 'V' },
    { word: 'transparence', word_class: 'N' },
    { word: 'transparency', word_class: 'N' },
    { word: 'transparent', word_class: 'AJ' },
    { word: 'transparently', word_class: 'AV' },
    { word: 'transparentness', word_class: 'N' }
  ],
  devil: [
    { word: 'devil', word_class: 'N' },
    { word: 'devil', word_class: 'V' },
    { word: 'devilize', word_class: 'V' },
    { word: 'devilment', word_class: 'N' }
  ],
  dissociate: [
    { word: 'dissociate', word_class: 'V' },
    { word: 'disassociate', word_class: 'V' },
    { word: 'dissociable', word_class: 'AJ' },
    { word: 'dissociation', word_class: 'N' },
    { word: 'dissociated', word_class: 'AJ' },
    { word: 'dissociative', word_class: 'AJ' },
    { word: 'disassociation', word_class: 'N' }
  ],
  captaincy: [
    { word: 'captain', word_class: 'N' },
    { word: 'captain', word_class: 'V' },
    { word: 'captaincy', word_class: 'N' },
    { word: 'captainship', word_class: 'N' }
  ],
  choked: [
    { word: 'choke', word_class: 'N' },
    { word: 'choke', word_class: 'V' },
    { word: 'choker', word_class: 'N' },
    { word: 'choked', word_class: 'AJ' },
    { word: 'choking', word_class: 'N' },
    { word: 'choking', word_class: 'AJ' }
  ],
  constricting: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  promising: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  fashions: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  cynicism: [
    { word: 'cynic', word_class: 'N' },
    { word: 'cynical', word_class: 'AJ' },
    { word: 'cynicism', word_class: 'N' },
    { word: 'cynically', word_class: 'AV' }
  ],
  yowling: [{ word: 'yowl', word_class: 'N' }, { word: 'yowl', word_class: 'V' }, { word: 'yowling', word_class: 'N' }],
  silly: [
    { word: 'silly', word_class: 'N' },
    { word: 'silly', word_class: 'AJ' },
    { word: 'silliness', word_class: 'N' }
  ],
  insincerity: [
    { word: 'insincere', word_class: 'AJ' },
    { word: 'insincerity', word_class: 'N' },
    { word: 'insincerely', word_class: 'AV' }
  ],
  jovially: [
    { word: 'jovial', word_class: 'AJ' },
    { word: 'joviality', word_class: 'N' },
    { word: 'jovially', word_class: 'AV' }
  ],
  supernaturalness: [
    { word: 'supernatural', word_class: 'N' },
    { word: 'supernatural', word_class: 'AJ' },
    { word: 'supernaturalism', word_class: 'N' },
    { word: 'supernaturally', word_class: 'AV' },
    { word: 'supernaturalness', word_class: 'N' }
  ],
  bargee: [
    { word: 'barge', word_class: 'N' },
    { word: 'barge', word_class: 'V' },
    { word: 'bargee', word_class: 'N' },
    { word: 'bargeman', word_class: 'N' }
  ],
  jilt: [{ word: 'jilt', word_class: 'N' }, { word: 'jilt', word_class: 'V' }, { word: 'jilted', word_class: 'AJ' }],
  feathering: [
    { word: 'feather', word_class: 'N' },
    { word: 'feather', word_class: 'V' },
    { word: 'feathered', word_class: 'AJ' },
    { word: 'feathering', word_class: 'N' }
  ],
  magnetized: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  permission: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  finding: [
    { word: 'find', word_class: 'N' },
    { word: 'find', word_class: 'V' },
    { word: 'finder', word_class: 'N' },
    { word: 'finding', word_class: 'N' }
  ],
  theory: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  deposed: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  compulsive: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  extortioner: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  accompaniment: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  vermilion: [
    { word: 'vermilion', word_class: 'N' },
    { word: 'vermilion', word_class: 'V' },
    { word: 'vermilion', word_class: 'AJ' }
  ],
  taboo: [{ word: 'taboo', word_class: 'N' }, { word: 'taboo', word_class: 'V' }, { word: 'taboo', word_class: 'AJ' }],
  beneficent: [
    { word: 'benefice', word_class: 'N' },
    { word: 'benefice', word_class: 'V' },
    { word: 'beneficence', word_class: 'N' },
    { word: 'beneficent', word_class: 'AJ' },
    { word: 'beneficiary', word_class: 'N' },
    { word: 'beneficiary', word_class: 'AJ' }
  ],
  yaws: [{ word: 'yaw', word_class: 'N' }, { word: 'yaw', word_class: 'V' }, { word: 'yaws', word_class: 'N' }],
  spectroscope: [
    { word: 'spectroscope', word_class: 'N' },
    { word: 'spectroscopic', word_class: 'AJ' },
    { word: 'spectroscopical', word_class: 'AJ' }
  ],
  enthrallment: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  antiquity: [
    { word: 'antique', word_class: 'N' },
    { word: 'antique', word_class: 'V' },
    { word: 'antique', word_class: 'AJ' },
    { word: 'antiquate', word_class: 'V' },
    { word: 'antiquity', word_class: 'N' },
    { word: 'antiquated', word_class: 'AJ' },
    { word: 'antiquation', word_class: 'N' }
  ],
  abduce: [
    { word: 'abduce', word_class: 'V' },
    { word: 'abducent', word_class: 'N' },
    { word: 'abducent', word_class: 'AJ' }
  ],
  disadvantageous: [
    { word: 'disadvantage', word_class: 'N' },
    { word: 'disadvantage', word_class: 'V' },
    { word: 'disadvantaged', word_class: 'AJ' },
    { word: 'disadvantageous', word_class: 'AJ' },
    { word: 'disadvantageously', word_class: 'AV' }
  ],
  amalgamation: [
    { word: 'amalgam', word_class: 'N' },
    { word: 'amalgamate', word_class: 'V' },
    { word: 'amalgamate', word_class: 'AJ' },
    { word: 'amalgamated', word_class: 'AJ' },
    { word: 'amalgamation', word_class: 'N' },
    { word: 'amalgamative', word_class: 'AJ' }
  ],
  parochially: [
    { word: 'parish', word_class: 'N' },
    { word: 'parochial', word_class: 'AJ' },
    { word: 'parochialism', word_class: 'N' },
    { word: 'parochially', word_class: 'AV' }
  ],
  mindful: [
    { word: 'mind', word_class: 'N' },
    { word: 'mind', word_class: 'V' },
    { word: 'minder', word_class: 'N' },
    { word: 'minded', word_class: 'AJ' },
    { word: 'mindful', word_class: 'AJ' },
    { word: 'mindfulness', word_class: 'N' }
  ],
  aliment: [
    { word: 'aliment', word_class: 'N' },
    { word: 'aliment', word_class: 'V' },
    { word: 'alimentation', word_class: 'N' },
    { word: 'alimentative', word_class: 'AJ' }
  ],
  optimum: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  compass: [
    { word: 'compass', word_class: 'N' },
    { word: 'compass', word_class: 'V' },
    { word: 'compassion', word_class: 'N' },
    { word: 'compassionate', word_class: 'V' },
    { word: 'compassionate', word_class: 'AJ' },
    { word: 'compassionately', word_class: 'AV' },
    { word: 'compassionateness', word_class: 'N' }
  ],
  depolarise: [
    { word: 'depolarise', word_class: 'V' },
    { word: 'depolarize', word_class: 'V' },
    { word: 'depolarisation', word_class: 'N' },
    { word: 'depolarization', word_class: 'N' }
  ],
  press: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  evocative: [
    { word: 'evoke', word_class: 'V' },
    { word: 'evoked', word_class: 'AJ' },
    { word: 'evocable', word_class: 'AJ' },
    { word: 'evocation', word_class: 'N' },
    { word: 'evocative', word_class: 'AJ' }
  ],
  talkativeness: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  defectiveness: [
    { word: 'defect', word_class: 'N' },
    { word: 'defect', word_class: 'V' },
    { word: 'defector', word_class: 'N' },
    { word: 'defection', word_class: 'N' },
    { word: 'defective', word_class: 'AJ' },
    { word: 'defectively', word_class: 'AV' },
    { word: 'defectiveness', word_class: 'N' }
  ],
  embroidery: [
    { word: 'embroider', word_class: 'V' },
    { word: 'embroidery', word_class: 'N' },
    { word: 'embroiderer', word_class: 'N' },
    { word: 'embroidered', word_class: 'AJ' }
  ],
  produced: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  cyclone: [
    { word: 'cyclone', word_class: 'N' },
    { word: 'cyclonal', word_class: 'AJ' },
    { word: 'cyclonic', word_class: 'AJ' },
    { word: 'cyclonical', word_class: 'AJ' }
  ],
  glass: [
    { word: 'glass', word_class: 'N' },
    { word: 'glass', word_class: 'V' },
    { word: 'glasses', word_class: 'N' },
    { word: 'glassed', word_class: 'AJ' },
    { word: 'glassful', word_class: 'N' },
    { word: 'glassful', word_class: 'AJ' }
  ],
  pry: [
    { word: 'pry', word_class: 'N' },
    { word: 'pry', word_class: 'V' },
    { word: 'prior', word_class: 'N' },
    { word: 'prior', word_class: 'AJ' },
    { word: 'prying', word_class: 'N' },
    { word: 'prying', word_class: 'AJ' }
  ],
  illustrated: [
    { word: 'illustrate', word_class: 'V' },
    { word: 'illustrator', word_class: 'N' },
    { word: 'illustrated', word_class: 'AJ' },
    { word: 'illustration', word_class: 'N' },
    { word: 'illustrative', word_class: 'AJ' }
  ],
  mongrelise: [
    { word: 'mongrel', word_class: 'N' },
    { word: 'mongrel', word_class: 'AJ' },
    { word: 'mongrelise', word_class: 'V' },
    { word: 'mongrelize', word_class: 'V' },
    { word: 'mongrelisation', word_class: 'N' },
    { word: 'mongrelization', word_class: 'N' }
  ],
  totalize: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  soothe: [
    { word: 'sooth', word_class: 'N' },
    { word: 'soothe', word_class: 'V' },
    { word: 'soothing', word_class: 'AJ' }
  ],
  americanization: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  defensibility: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  monarchal: [
    { word: 'monarch', word_class: 'N' },
    { word: 'monarchal', word_class: 'AJ' },
    { word: 'monarchic', word_class: 'AJ' },
    { word: 'monarchism', word_class: 'N' },
    { word: 'monarchical', word_class: 'AJ' }
  ],
  nettled: [
    { word: 'nettle', word_class: 'N' },
    { word: 'nettle', word_class: 'V' },
    { word: 'nettled', word_class: 'AJ' },
    { word: 'nettlesome', word_class: 'AJ' }
  ],
  disarming: [
    { word: 'disarm', word_class: 'V' },
    { word: 'disarmer', word_class: 'N' },
    { word: 'disarming', word_class: 'N' },
    { word: 'disarming', word_class: 'AJ' },
    { word: 'disarmament', word_class: 'N' }
  ],
  antiphonal: [
    { word: 'antiphon', word_class: 'N' },
    { word: 'antiphonal', word_class: 'N' },
    { word: 'antiphonal', word_class: 'AJ' }
  ],
  residency: [
    { word: 'reside', word_class: 'V' },
    { word: 'resident', word_class: 'N' },
    { word: 'residence', word_class: 'N' },
    { word: 'residency', word_class: 'N' },
    { word: 'resident', word_class: 'AJ' }
  ],
  fetish: [
    { word: 'fetish', word_class: 'N' },
    { word: 'fetish', word_class: 'AJ' },
    { word: 'fetishism', word_class: 'N' }
  ],
  finisher: [
    { word: 'finish', word_class: 'N' },
    { word: 'finish', word_class: 'V' },
    { word: 'finish', word_class: 'AJ' },
    { word: 'finisher', word_class: 'N' },
    { word: 'finished', word_class: 'AJ' },
    { word: 'finishing', word_class: 'N' }
  ],
  menstruating: [
    { word: 'menstruate', word_class: 'V' },
    { word: 'menstruation', word_class: 'N' },
    { word: 'menstruating', word_class: 'AJ' }
  ],
  mitigation: [
    { word: 'mitigate', word_class: 'V' },
    { word: 'mitigable', word_class: 'AJ' },
    { word: 'mitigated', word_class: 'AJ' },
    { word: 'mitigation', word_class: 'N' },
    { word: 'mitigative', word_class: 'AJ' }
  ],
  repress: [
    { word: 'repress', word_class: 'N' },
    { word: 'repress', word_class: 'V' },
    { word: 'repressed', word_class: 'AJ' },
    { word: 'repression', word_class: 'N' },
    { word: 'repressing', word_class: 'AJ' },
    { word: 'repressive', word_class: 'AJ' }
  ],
  grater: [
    { word: 'grate', word_class: 'N' },
    { word: 'grate', word_class: 'V' },
    { word: 'grater', word_class: 'N' },
    { word: 'grating', word_class: 'N' },
    { word: 'grating', word_class: 'AJ' },
    { word: 'grateful', word_class: 'AJ' },
    { word: 'gratefulness', word_class: 'N' }
  ],
  falls: [
    { word: 'fall', word_class: 'N' },
    { word: 'fall', word_class: 'V' },
    { word: 'falls', word_class: 'N' },
    { word: 'fallal', word_class: 'N' },
    { word: 'fallen', word_class: 'AJ' },
    { word: 'falling', word_class: 'N' },
    { word: 'falling', word_class: 'AJ' }
  ],
  block: [
    { word: 'block', word_class: 'N' },
    { word: 'block', word_class: 'V' },
    { word: 'blocker', word_class: 'N' },
    { word: 'blockage', word_class: 'N' },
    { word: 'blocked', word_class: 'AJ' },
    { word: 'blocking', word_class: 'N' }
  ],
  nonsense: [
    { word: 'nonsense', word_class: 'N' },
    { word: 'nonsense', word_class: 'AJ' },
    { word: 'nonsensical', word_class: 'AJ' },
    { word: 'nonsensicality', word_class: 'N' }
  ],
  heated: [
    { word: 'heat', word_class: 'N' },
    { word: 'heat', word_class: 'V' },
    { word: 'heater', word_class: 'N' },
    { word: 'heated', word_class: 'AJ' },
    { word: 'heating', word_class: 'N' },
    { word: 'heating', word_class: 'AJ' }
  ],
  dabble: [
    { word: 'dabble', word_class: 'V' },
    { word: 'dabbler', word_class: 'N' },
    { word: 'dabbled', word_class: 'AJ' }
  ],
  unperceivable: [
    { word: 'unperceived', word_class: 'AJ' },
    { word: 'unperceiving', word_class: 'AJ' },
    { word: 'unperceivable', word_class: 'AJ' }
  ],
  strangulate: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  homeless: [
    { word: 'homeless', word_class: 'N' },
    { word: 'homeless', word_class: 'AJ' },
    { word: 'homelessness', word_class: 'N' }
  ],
  blazing: [
    { word: 'blaze', word_class: 'N' },
    { word: 'blaze', word_class: 'V' },
    { word: 'blazer', word_class: 'N' },
    { word: 'blazing', word_class: 'N' },
    { word: 'blazing', word_class: 'AJ' }
  ],
  wriggling: [
    { word: 'wriggle', word_class: 'N' },
    { word: 'wriggle', word_class: 'V' },
    { word: 'wriggler', word_class: 'N' },
    { word: 'wriggly', word_class: 'AJ' },
    { word: 'wriggling', word_class: 'AJ' }
  ],
  constrictive: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  effectivity: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  chafe: [
    { word: 'chafe', word_class: 'N' },
    { word: 'chafe', word_class: 'V' },
    { word: 'chafed', word_class: 'AJ' },
    { word: 'chafing', word_class: 'N' }
  ],
  unreason: [
    { word: 'unreason', word_class: 'N' },
    { word: 'unreasoning', word_class: 'AJ' },
    { word: 'unreasonable', word_class: 'AJ' },
    { word: 'unreasonably', word_class: 'AV' }
  ],
  vicarial: [
    { word: 'vicarial', word_class: 'AJ' },
    { word: 'vicariate', word_class: 'N' },
    { word: 'vicarious', word_class: 'AJ' },
    { word: 'vicariously', word_class: 'AV' }
  ],
  one: [
    { word: 'on', word_class: 'AV' },
    { word: 'one', word_class: 'N' },
    { word: 'on', word_class: 'AJ' },
    { word: 'one', word_class: 'AJ' },
    { word: 'oneness', word_class: 'N' }
  ],
  booze: [
    { word: 'booze', word_class: 'N' },
    { word: 'booze', word_class: 'V' },
    { word: 'boozer', word_class: 'N' },
    { word: 'boozy', word_class: 'AJ' },
    { word: 'boozing', word_class: 'N' }
  ],
  validation: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  tranquillize: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  chastened: [
    { word: 'chasten', word_class: 'V' },
    { word: 'chastise', word_class: 'V' },
    { word: 'chastened', word_class: 'AJ' },
    { word: 'chastening', word_class: 'N' },
    { word: 'chastised', word_class: 'AJ' },
    { word: 'chastisement', word_class: 'N' }
  ],
  boosters: [
    { word: 'boost', word_class: 'N' },
    { word: 'boost', word_class: 'V' },
    { word: 'booster', word_class: 'N' },
    { word: 'boosters', word_class: 'N' }
  ],
  purgatory: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  romanticism: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  corsetry: [
    { word: 'corset', word_class: 'N' },
    { word: 'corset', word_class: 'V' },
    { word: 'corsetry', word_class: 'N' }
  ],
  communicational: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  unweary: [
    { word: 'unweary', word_class: 'AJ' },
    { word: 'unwearied', word_class: 'AJ' },
    { word: 'unwearying', word_class: 'AJ' }
  ],
  game: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  obfuscate: [
    { word: 'obfuscate', word_class: 'V' },
    { word: 'obfuscation', word_class: 'N' },
    { word: 'obfucscation', word_class: 'N' }
  ],
  dumped: [
    { word: 'dump', word_class: 'N' },
    { word: 'dump', word_class: 'V' },
    { word: 'dumps', word_class: 'N' },
    { word: 'dumper', word_class: 'N' },
    { word: 'dumped', word_class: 'AJ' },
    { word: 'dumping', word_class: 'N' }
  ],
  trivialize: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  aggrievement: [
    { word: 'aggrieve', word_class: 'V' },
    { word: 'aggrieved', word_class: 'AJ' },
    { word: 'aggrievement', word_class: 'N' }
  ],
  telegraphist: [
    { word: 'telegraph', word_class: 'N' },
    { word: 'telegraph', word_class: 'V' },
    { word: 'telegrapher', word_class: 'N' },
    { word: 'telegraphic', word_class: 'AJ' },
    { word: 'telegraphist', word_class: 'N' },
    { word: 'telegraphically', word_class: 'AV' }
  ],
  fright: [
    { word: 'fright', word_class: 'N' },
    { word: 'fright', word_class: 'V' },
    { word: 'frighten', word_class: 'V' },
    { word: 'frightful', word_class: 'AJ' },
    { word: 'frightened', word_class: 'AJ' },
    { word: 'frightening', word_class: 'N' },
    { word: 'frightening', word_class: 'AJ' },
    { word: 'frightfulness', word_class: 'N' }
  ],
  bismuthic: [
    { word: 'bismuth', word_class: 'N' },
    { word: 'bismuthal', word_class: 'AJ' },
    { word: 'bismuthic', word_class: 'AJ' }
  ],
  inertness: [
    { word: 'inert', word_class: 'AJ' },
    { word: 'inertance', word_class: 'N' },
    { word: 'inertness', word_class: 'N' }
  ],
  geometric: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  legibly: [
    { word: 'legible', word_class: 'AJ' },
    { word: 'legibly', word_class: 'AV' },
    { word: 'legibility', word_class: 'N' }
  ],
  charcoal: [
    { word: 'charcoal', word_class: 'N' },
    { word: 'charcoal', word_class: 'V' },
    { word: 'charcoal', word_class: 'AJ' }
  ],
  awing: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  faerie: [
    { word: 'faery', word_class: 'N' },
    { word: 'faerie', word_class: 'N' },
    { word: 'faery', word_class: 'AJ' }
  ],
  inflection: [
    { word: 'inflect', word_class: 'V' },
    { word: 'inflected', word_class: 'AJ' },
    { word: 'inflection', word_class: 'N' },
    { word: 'inflectional', word_class: 'AJ' }
  ],
  unceremonious: [
    { word: 'unceremonial', word_class: 'AJ' },
    { word: 'unceremonious', word_class: 'AJ' },
    { word: 'unceremoniously', word_class: 'AV' },
    { word: 'unceremoniousness', word_class: 'N' }
  ],
  remission: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  beholding: [
    { word: 'behold', word_class: 'V' },
    { word: 'beholder', word_class: 'N' },
    { word: 'beholding', word_class: 'N' }
  ],
  joint: [
    { word: 'joint', word_class: 'N' },
    { word: 'joint', word_class: 'V' },
    { word: 'joint', word_class: 'AJ' },
    { word: 'jointer', word_class: 'N' },
    { word: 'jointed', word_class: 'AJ' }
  ],
  calisthenic: [
    { word: 'calisthenic', word_class: 'N' },
    { word: 'calisthenic', word_class: 'AJ' },
    { word: 'calisthenics', word_class: 'N' }
  ],
  fantast: [
    { word: 'fantast', word_class: 'N' },
    { word: 'fantastic', word_class: 'AJ' },
    { word: 'fantastical', word_class: 'AJ' },
    { word: 'fantastically', word_class: 'AV' }
  ],
  unthought: [
    { word: 'unthought', word_class: 'AJ' },
    { word: 'unthoughtful', word_class: 'AJ' },
    { word: 'unthoughtfulness', word_class: 'N' }
  ],
  depopulation: [
    { word: 'depopulate', word_class: 'V' },
    { word: 'depopulated', word_class: 'AJ' },
    { word: 'depopulation', word_class: 'N' }
  ],
  ultimate: [
    { word: 'ultimate', word_class: 'AJ' },
    { word: 'ultimately', word_class: 'AV' },
    { word: 'ultimateness', word_class: 'N' }
  ],
  partnership: [
    { word: 'partner', word_class: 'N' },
    { word: 'partner', word_class: 'V' },
    { word: 'partnership', word_class: 'N' }
  ],
  onomatopoeical: [
    { word: 'onomatopoeia', word_class: 'N' },
    { word: 'onomatopoeic', word_class: 'AJ' },
    { word: 'onomatopoetic', word_class: 'AJ' },
    { word: 'onomatopoeical', word_class: 'AJ' }
  ],
  mastoiditis: [
    { word: 'mastoid', word_class: 'N' },
    { word: 'mastoid', word_class: 'AJ' },
    { word: 'mastoidal', word_class: 'AJ' },
    { word: 'mastoiditis', word_class: 'N' }
  ],
  artificial: [
    { word: 'artificial', word_class: 'AJ' },
    { word: 'artificiality', word_class: 'N' },
    { word: 'artificially', word_class: 'AV' }
  ],
  imbue: [
    { word: 'imbue', word_class: 'V' },
    { word: 'imbued', word_class: 'AJ' },
    { word: 'imbuement', word_class: 'N' }
  ],
  tablespoonful: [
    { word: 'tablespoon', word_class: 'N' },
    { word: 'tablespoonful', word_class: 'N' },
    { word: 'tablespoonful', word_class: 'AJ' }
  ],
  ticklish: [
    { word: 'tickle', word_class: 'N' },
    { word: 'tickle', word_class: 'V' },
    { word: 'tickler', word_class: 'N' },
    { word: 'tickling', word_class: 'N' },
    { word: 'tickling', word_class: 'AJ' },
    { word: 'ticklish', word_class: 'AJ' }
  ],
  salivation: [
    { word: 'saliva', word_class: 'N' },
    { word: 'salivate', word_class: 'V' },
    { word: 'salivary', word_class: 'AJ' },
    { word: 'salivation', word_class: 'N' }
  ],
  flagellant: [
    { word: 'flagellant', word_class: 'N' },
    { word: 'flagellate', word_class: 'N' },
    { word: 'flagellate', word_class: 'V' },
    { word: 'flagellate', word_class: 'AJ' },
    { word: 'flagellated', word_class: 'AJ' },
    { word: 'flagellation', word_class: 'N' }
  ],
  civilization: [
    { word: 'civil', word_class: 'AJ' },
    { word: 'civility', word_class: 'N' },
    { word: 'civilize', word_class: 'V' },
    { word: 'civilized', word_class: 'AJ' },
    { word: 'civilization', word_class: 'N' }
  ],
  landed: [
    { word: 'land', word_class: 'N' },
    { word: 'land', word_class: 'V' },
    { word: 'land', word_class: 'AJ' },
    { word: 'landed', word_class: 'AJ' },
    { word: 'landing', word_class: 'N' },
    { word: 'landman', word_class: 'N' }
  ],
  mirrored: [
    { word: 'mirror', word_class: 'N' },
    { word: 'mirror', word_class: 'V' },
    { word: 'mirrored', word_class: 'AJ' }
  ],
  eating: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  humiliation: [
    { word: 'humiliate', word_class: 'V' },
    { word: 'humiliated', word_class: 'AJ' },
    { word: 'humiliation', word_class: 'N' },
    { word: 'humiliating', word_class: 'AJ' }
  ],
  boiled: [
    { word: 'boil', word_class: 'N' },
    { word: 'boil', word_class: 'V' },
    { word: 'boiler', word_class: 'N' },
    { word: 'boiled', word_class: 'AJ' },
    { word: 'boiling', word_class: 'N' },
    { word: 'boiling', word_class: 'AJ' },
    { word: 'boiling', word_class: 'AV' }
  ],
  disgust: [
    { word: 'disgust', word_class: 'N' },
    { word: 'disgust', word_class: 'V' },
    { word: 'disgusted', word_class: 'AJ' },
    { word: 'disgustful', word_class: 'AJ' },
    { word: 'disgusting', word_class: 'AJ' }
  ],
  rarefication: [
    { word: 'rarefy', word_class: 'V' },
    { word: 'rarefied', word_class: 'AJ' },
    { word: 'rarefication', word_class: 'N' }
  ],
  incompatible: [
    { word: 'incompatible', word_class: 'AJ' },
    { word: 'incompatibly', word_class: 'AV' },
    { word: 'incompatibility', word_class: 'N' }
  ],
  assimilative: [
    { word: 'assimilate', word_class: 'V' },
    { word: 'assimilable', word_class: 'AJ' },
    { word: 'assimilation', word_class: 'N' },
    { word: 'assimilating', word_class: 'AJ' },
    { word: 'assimilative', word_class: 'AJ' }
  ],
  terribly: [
    { word: 'terrible', word_class: 'AJ' },
    { word: 'terribly', word_class: 'AV' },
    { word: 'terribleness', word_class: 'N' }
  ],
  distraction: [
    { word: 'distract', word_class: 'N' },
    { word: 'distract', word_class: 'V' },
    { word: 'distracted', word_class: 'AJ' },
    { word: 'distraction', word_class: 'N' }
  ],
  palpitation: [
    { word: 'palpitate', word_class: 'V' },
    { word: 'palpitant', word_class: 'AJ' },
    { word: 'palpitation', word_class: 'N' },
    { word: 'palpitating', word_class: 'AJ' }
  ],
  eminent: [
    { word: 'eminence', word_class: 'N' },
    { word: 'eminent', word_class: 'AJ' },
    { word: 'eminently', word_class: 'AV' }
  ],
  numerousness: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  intermediator: [
    { word: 'intermediate', word_class: 'N' },
    { word: 'intermediate', word_class: 'V' },
    { word: 'intermediate', word_class: 'AJ' },
    { word: 'intermediator', word_class: 'N' },
    { word: 'intermediation', word_class: 'N' },
    { word: 'intermediately', word_class: 'AV' }
  ],
  consignation: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  apperceive: [
    { word: 'apperceive', word_class: 'V' },
    { word: 'apperception', word_class: 'N' },
    { word: 'apperceptive', word_class: 'AJ' }
  ],
  fossil: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  supplement: [
    { word: 'supplement', word_class: 'N' },
    { word: 'supplement', word_class: 'V' },
    { word: 'supplemental', word_class: 'AJ' },
    { word: 'supplementary', word_class: 'AJ' },
    { word: 'supplementation', word_class: 'N' }
  ],
  exercitation: [
    { word: 'exercise', word_class: 'N' },
    { word: 'exercise', word_class: 'V' },
    { word: 'exerciser', word_class: 'N' },
    { word: 'exercising', word_class: 'N' },
    { word: 'exercitation', word_class: 'N' }
  ],
  eloquence: [
    { word: 'eloquence', word_class: 'N' },
    { word: 'eloquent', word_class: 'AJ' },
    { word: 'eloquently', word_class: 'AV' }
  ],
  pollination: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  moonshine: [
    { word: 'moonshine', word_class: 'N' },
    { word: 'moonshine', word_class: 'V' },
    { word: 'moonshiner', word_class: 'N' }
  ],
  enjoyableness: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  clock: [
    { word: 'clock', word_class: 'N' },
    { word: 'clock', word_class: 'V' },
    { word: 'clocks', word_class: 'N' },
    { word: 'clocking', word_class: 'N' }
  ],
  visualize: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  false: [
    { word: 'false', word_class: 'AJ' },
    { word: 'false', word_class: 'AV' },
    { word: 'falsely', word_class: 'AV' },
    { word: 'falsehood', word_class: 'N' },
    { word: 'falseness', word_class: 'N' }
  ],
  delimitate: [
    { word: 'delimit', word_class: 'N' },
    { word: 'delimit', word_class: 'V' },
    { word: 'delimitate', word_class: 'V' },
    { word: 'delimited', word_class: 'AJ' },
    { word: 'delimitation', word_class: 'N' }
  ],
  hyphenated: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  plume: [
    { word: 'plume', word_class: 'N' },
    { word: 'plume', word_class: 'V' },
    { word: 'plumage', word_class: 'N' },
    { word: 'plumed', word_class: 'AJ' },
    { word: 'plumaged', word_class: 'AJ' }
  ],
  contaminant: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  percolation: [
    { word: 'percolate', word_class: 'V' },
    { word: 'percolator', word_class: 'N' },
    { word: 'percolation', word_class: 'N' }
  ],
  wigged: [
    { word: 'wig', word_class: 'N' },
    { word: 'wig', word_class: 'V' },
    { word: 'wigged', word_class: 'AJ' },
    { word: 'wigging', word_class: 'N' }
  ],
  substantiating: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  distill: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  suppuration: [
    { word: 'suppurate', word_class: 'V' },
    { word: 'suppuration', word_class: 'N' },
    { word: 'suppurative', word_class: 'AJ' }
  ],
  acrobatic: [
    { word: 'acrobat', word_class: 'N' },
    { word: 'acrobatic', word_class: 'AJ' },
    { word: 'acrobatics', word_class: 'N' }
  ],
  sandwich: [
    { word: 'sandwich', word_class: 'N' },
    { word: 'sandwich', word_class: 'V' },
    { word: 'sandwichman', word_class: 'N' }
  ],
  cord: [
    { word: 'cord', word_class: 'N' },
    { word: 'cord', word_class: 'V' },
    { word: 'cords', word_class: 'N' },
    { word: 'corded', word_class: 'AJ' },
    { word: 'cording', word_class: 'N' },
    { word: 'cordite', word_class: 'N' }
  ],
  inflexibly: [
    { word: 'inflexible', word_class: 'AJ' },
    { word: 'inflexibly', word_class: 'AV' },
    { word: 'inflexibility', word_class: 'N' },
    { word: 'inflexibleness', word_class: 'N' }
  ],
  partner: [
    { word: 'partner', word_class: 'N' },
    { word: 'partner', word_class: 'V' },
    { word: 'partnership', word_class: 'N' }
  ],
  beaded: [
    { word: 'bead', word_class: 'N' },
    { word: 'bead', word_class: 'V' },
    { word: 'beads', word_class: 'N' },
    { word: 'beaded', word_class: 'AJ' },
    { word: 'beading', word_class: 'N' }
  ],
  mastoid: [
    { word: 'mastoid', word_class: 'N' },
    { word: 'mastoid', word_class: 'AJ' },
    { word: 'mastoidal', word_class: 'AJ' },
    { word: 'mastoiditis', word_class: 'N' }
  ],
  expending: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  drain: [
    { word: 'drain', word_class: 'N' },
    { word: 'drain', word_class: 'V' },
    { word: 'drainage', word_class: 'N' },
    { word: 'drained', word_class: 'AJ' },
    { word: 'draining', word_class: 'AJ' }
  ],
  symmetrisation: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  vaginal: [
    { word: 'vagina', word_class: 'N' },
    { word: 'vaginal', word_class: 'AJ' },
    { word: 'vaginitis', word_class: 'N' }
  ],
  weighting: [
    { word: 'weigh', word_class: 'V' },
    { word: 'weight', word_class: 'N' },
    { word: 'weight', word_class: 'V' },
    { word: 'weighing', word_class: 'N' },
    { word: 'weighted', word_class: 'AJ' },
    { word: 'weighting', word_class: 'N' }
  ],
  tightening: [
    { word: 'tighten', word_class: 'V' },
    { word: 'tightened', word_class: 'AJ' },
    { word: 'tightening', word_class: 'N' }
  ],
  inattentiveness: [
    { word: 'inattention', word_class: 'N' },
    { word: 'inattentive', word_class: 'AJ' },
    { word: 'inattentively', word_class: 'AV' },
    { word: 'inattentiveness', word_class: 'N' }
  ],
  reactive: [
    { word: 'reactive', word_class: 'AJ' },
    { word: 'reactivate', word_class: 'V' },
    { word: 'reactivity', word_class: 'N' },
    { word: 'reactivation', word_class: 'N' }
  ],
  tickler: [
    { word: 'tickle', word_class: 'N' },
    { word: 'tickle', word_class: 'V' },
    { word: 'tickler', word_class: 'N' },
    { word: 'tickling', word_class: 'N' },
    { word: 'tickling', word_class: 'AJ' },
    { word: 'ticklish', word_class: 'AJ' }
  ],
  inquisitively: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  despising: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  adumbration: [
    { word: 'adumbrate', word_class: 'V' },
    { word: 'adumbration', word_class: 'N' },
    { word: 'adumbrative', word_class: 'AJ' }
  ],
  starboard: [
    { word: 'starboard', word_class: 'N' },
    { word: 'starboard', word_class: 'V' },
    { word: 'starboard', word_class: 'AJ' }
  ],
  electrically: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  career: [
    { word: 'career', word_class: 'N' },
    { word: 'career', word_class: 'V' },
    { word: 'careerism', word_class: 'N' },
    { word: 'careerist', word_class: 'N' }
  ],
  exhaust: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  flavor: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  prefer: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  trader: [
    { word: 'trade', word_class: 'N' },
    { word: 'trade', word_class: 'V' },
    { word: 'trade', word_class: 'AJ' },
    { word: 'trader', word_class: 'N' },
    { word: 'trading', word_class: 'N' },
    { word: 'traded', word_class: 'AJ' }
  ],
  mutterer: [
    { word: 'mutter', word_class: 'N' },
    { word: 'mutter', word_class: 'V' },
    { word: 'mutterer', word_class: 'N' },
    { word: 'muttering', word_class: 'N' },
    { word: 'muttering', word_class: 'AJ' }
  ],
  haste: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  constitutionalism: [
    { word: 'constitutionalism', word_class: 'N' },
    { word: 'constitutionalize', word_class: 'V' },
    { word: 'constitutionally', word_class: 'AV' }
  ],
  unpalatability: [
    { word: 'unpalatable', word_class: 'AJ' },
    { word: 'unpalatably', word_class: 'AV' },
    { word: 'unpalatability', word_class: 'N' },
    { word: 'unpalatableness', word_class: 'N' }
  ],
  scheduling: [
    { word: 'schedule', word_class: 'N' },
    { word: 'schedule', word_class: 'V' },
    { word: 'scheduled', word_class: 'AJ' },
    { word: 'scheduling', word_class: 'N' }
  ],
  enfranchised: [
    { word: 'enfranchise', word_class: 'V' },
    { word: 'enfranchised', word_class: 'AJ' },
    { word: 'enfranchisement', word_class: 'N' }
  ],
  pressurise: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  violent: [
    { word: 'violence', word_class: 'N' },
    { word: 'violent', word_class: 'AJ' },
    { word: 'violently', word_class: 'AV' }
  ],
  plagiarist: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  listed: [
    { word: 'list', word_class: 'N' },
    { word: 'list', word_class: 'V' },
    { word: 'lister', word_class: 'N' },
    { word: 'listed', word_class: 'AJ' },
    { word: 'listing', word_class: 'N' }
  ],
  tattered: [
    { word: 'tatter', word_class: 'N' },
    { word: 'tatter', word_class: 'V' },
    { word: 'tattered', word_class: 'AJ' }
  ],
  orchestration: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  radiographic: [
    { word: 'radiograph', word_class: 'N' },
    { word: 'radiographer', word_class: 'N' },
    { word: 'radiographic', word_class: 'AJ' }
  ],
  guy: [{ word: 'gui', word_class: 'N' }, { word: 'guy', word_class: 'N' }, { word: 'guy', word_class: 'V' }],
  graceful: [
    { word: 'grace', word_class: 'N' },
    { word: 'grace', word_class: 'V' },
    { word: 'graces', word_class: 'N' },
    { word: 'gracious', word_class: 'AJ' },
    { word: 'graceful', word_class: 'AJ' },
    { word: 'gracefulness', word_class: 'N' }
  ],
  athletic: [
    { word: 'athlete', word_class: 'N' },
    { word: 'athletic', word_class: 'AJ' },
    { word: 'athletics', word_class: 'N' }
  ],
  illogic: [
    { word: 'illogic', word_class: 'N' },
    { word: 'illogical', word_class: 'AJ' },
    { word: 'illogicality', word_class: 'N' },
    { word: 'illogically', word_class: 'AV' }
  ],
  bramble: [
    { word: 'bramble', word_class: 'N' },
    { word: 'brambly', word_class: 'AJ' },
    { word: 'brambling', word_class: 'N' }
  ],
  incident: [
    { word: 'incident', word_class: 'N' },
    { word: 'incidence', word_class: 'N' },
    { word: 'incident', word_class: 'AJ' }
  ],
  dividing: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  semantic: [
    { word: 'semantic', word_class: 'AJ' },
    { word: 'semantics', word_class: 'N' },
    { word: 'semantically', word_class: 'AV' }
  ],
  blossoming: [
    { word: 'blossom', word_class: 'N' },
    { word: 'blossom', word_class: 'V' },
    { word: 'blossoming', word_class: 'N' }
  ],
  squash: [
    { word: 'squash', word_class: 'N' },
    { word: 'squash', word_class: 'V' },
    { word: 'squashed', word_class: 'AJ' }
  ],
  profitableness: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  pander: [
    { word: 'pander', word_class: 'N' },
    { word: 'pander', word_class: 'V' },
    { word: 'panderer', word_class: 'N' }
  ],
  nominally: [
    { word: 'nominal', word_class: 'N' },
    { word: 'nominal', word_class: 'AJ' },
    { word: 'nominate', word_class: 'V' },
    { word: 'nomination', word_class: 'N' },
    { word: 'nominally', word_class: 'AV' },
    { word: 'nominalism', word_class: 'N' },
    { word: 'nominated', word_class: 'AJ' },
    { word: 'nominating', word_class: 'N' },
    { word: 'nominative', word_class: 'N' },
    { word: 'nominative', word_class: 'AJ' }
  ],
  embossed: [
    { word: 'emboss', word_class: 'V' },
    { word: 'embossed', word_class: 'AJ' },
    { word: 'embossment', word_class: 'N' }
  ],
  activating: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  astounded: [
    { word: 'astound', word_class: 'V' },
    { word: 'astounded', word_class: 'AJ' },
    { word: 'astounding', word_class: 'AJ' }
  ],
  extravert: [
    { word: 'extravert', word_class: 'N' },
    { word: 'extravert', word_class: 'AJ' },
    { word: 'extraverted', word_class: 'AJ' },
    { word: 'extravertive', word_class: 'AJ' }
  ],
  simple: [
    { word: 'simple', word_class: 'N' },
    { word: 'simple', word_class: 'AJ' },
    { word: 'simply', word_class: 'AV' },
    { word: 'simpleness', word_class: 'N' }
  ],
  winsomeness: [
    { word: 'win', word_class: 'N' },
    { word: 'win', word_class: 'V' },
    { word: 'winning', word_class: 'N' },
    { word: 'winsome', word_class: 'AJ' },
    { word: 'winning', word_class: 'AJ' },
    { word: 'winnings', word_class: 'N' },
    { word: 'winsomely', word_class: 'AV' },
    { word: 'winsomeness', word_class: 'N' }
  ],
  redeeming: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  propulsion: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  stout: [
    { word: 'stout', word_class: 'N' },
    { word: 'stout', word_class: 'AJ' },
    { word: 'stoutness', word_class: 'N' }
  ],
  lining: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  dexter: [
    { word: 'dexter', word_class: 'AJ' },
    { word: 'dexterity', word_class: 'N' },
    { word: 'dexterous', word_class: 'AJ' },
    { word: 'dexterously', word_class: 'AV' }
  ],
  incursion: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  geniality: [
    { word: 'genial', word_class: 'AJ' },
    { word: 'geniality', word_class: 'N' },
    { word: 'genially', word_class: 'AV' }
  ],
  readying: [
    { word: 'ready', word_class: 'N' },
    { word: 'ready', word_class: 'V' },
    { word: 'ready', word_class: 'AJ' },
    { word: 'readying', word_class: 'N' },
    { word: 'readiness', word_class: 'N' }
  ],
  basinal: [
    { word: 'basin', word_class: 'N' },
    { word: 'basinal', word_class: 'AJ' },
    { word: 'basined', word_class: 'AJ' },
    { word: 'basinful', word_class: 'N' }
  ],
  canulation: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  humanness: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  pellucidity: [
    { word: 'pellucid', word_class: 'AJ' },
    { word: 'pellucidity', word_class: 'N' },
    { word: 'pellucidness', word_class: 'N' }
  ],
  anion: [
    { word: 'anion', word_class: 'N' },
    { word: 'anionic', word_class: 'N' },
    { word: 'anionic', word_class: 'AJ' }
  ],
  destroyed: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  profiteering: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  welcomed: [
    { word: 'welcome', word_class: 'N' },
    { word: 'welcome', word_class: 'V' },
    { word: 'welcome', word_class: 'AJ' },
    { word: 'welcomed', word_class: 'AJ' },
    { word: 'welcoming', word_class: 'N' },
    { word: 'welcoming', word_class: 'AJ' }
  ],
  ruled: [
    { word: 'rule', word_class: 'N' },
    { word: 'rule', word_class: 'V' },
    { word: 'ruler', word_class: 'N' },
    { word: 'ruled', word_class: 'AJ' },
    { word: 'ruling', word_class: 'N' },
    { word: 'ruling', word_class: 'AJ' }
  ],
  insecure: [
    { word: 'insecure', word_class: 'AJ' },
    { word: 'insecurity', word_class: 'N' },
    { word: 'insecurely', word_class: 'AV' },
    { word: 'insecureness', word_class: 'N' }
  ],
  recessional: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  premise: [
    { word: 'premise', word_class: 'N' },
    { word: 'premise', word_class: 'V' },
    { word: 'premises', word_class: 'N' }
  ],
  conglomerate: [
    { word: 'conglomerate', word_class: 'N' },
    { word: 'conglomerate', word_class: 'V' },
    { word: 'conglomerate', word_class: 'AJ' },
    { word: 'conglomeration', word_class: 'N' }
  ],
  subtly: [
    { word: 'subtle', word_class: 'AJ' },
    { word: 'subtly', word_class: 'AV' },
    { word: 'subtlety', word_class: 'N' }
  ],
  impreciseness: [
    { word: 'imprecise', word_class: 'AJ' },
    { word: 'imprecision', word_class: 'N' },
    { word: 'imprecisely', word_class: 'AV' },
    { word: 'impreciseness', word_class: 'N' }
  ],
  acrogenous: [
    { word: 'acrogen', word_class: 'N' },
    { word: 'acrogenic', word_class: 'AJ' },
    { word: 'acrogenous', word_class: 'AJ' }
  ],
  temporally: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  suffusion: [
    { word: 'suffuse', word_class: 'V' },
    { word: 'suffused', word_class: 'AJ' },
    { word: 'suffusion', word_class: 'N' },
    { word: 'suffusive', word_class: 'AJ' }
  ],
  demanding: [
    { word: 'demand', word_class: 'N' },
    { word: 'demand', word_class: 'V' },
    { word: 'demander', word_class: 'N' },
    { word: 'demanding', word_class: 'AJ' }
  ],
  tempestuousness: [
    { word: 'tempest', word_class: 'N' },
    { word: 'tempestuous', word_class: 'AJ' },
    { word: 'tempestuousness', word_class: 'N' }
  ],
  semitropic: [
    { word: 'semitropic', word_class: 'AJ' },
    { word: 'semitropics', word_class: 'N' },
    { word: 'semitropical', word_class: 'AJ' }
  ],
  arrogated: [
    { word: 'arrogate', word_class: 'V' },
    { word: 'arrogance', word_class: 'N' },
    { word: 'arrogant', word_class: 'AJ' },
    { word: 'arrogated', word_class: 'AJ' },
    { word: 'arrogation', word_class: 'N' }
  ],
  imperfection: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  addressee: [
    { word: 'address', word_class: 'N' },
    { word: 'address', word_class: 'V' },
    { word: 'addressee', word_class: 'N' },
    { word: 'addressed', word_class: 'AJ' },
    { word: 'addressable', word_class: 'AJ' }
  ],
  reduced: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  fame: [{ word: 'fame', word_class: 'N' }, { word: 'fame', word_class: 'V' }, { word: 'famed', word_class: 'AJ' }],
  ruralise: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  forehand: [
    { word: 'forehand', word_class: 'N' },
    { word: 'forehand', word_class: 'AJ' },
    { word: 'forehanded', word_class: 'AJ' }
  ],
  conversion: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  affair: [
    { word: 'affair', word_class: 'N' },
    { word: 'affaire', word_class: 'N' },
    { word: 'affairs', word_class: 'N' }
  ],
  conceptualise: [
    { word: 'conceptus', word_class: 'N' },
    { word: 'conceptual', word_class: 'AJ' },
    { word: 'conceptualise', word_class: 'V' },
    { word: 'conceptualize', word_class: 'V' },
    { word: 'conceptuality', word_class: 'N' },
    { word: 'conceptualism', word_class: 'N' },
    { word: 'conceptually', word_class: 'AV' },
    { word: 'conceptualisation', word_class: 'N' },
    { word: 'conceptualization', word_class: 'N' }
  ],
  squirming: [
    { word: 'squirm', word_class: 'N' },
    { word: 'squirm', word_class: 'V' },
    { word: 'squirmy', word_class: 'AJ' },
    { word: 'squirming', word_class: 'AJ' }
  ],
  dowser: [
    { word: 'dowse', word_class: 'V' },
    { word: 'dowser', word_class: 'N' },
    { word: 'dowsing', word_class: 'N' }
  ],
  marauder: [
    { word: 'maraud', word_class: 'N' },
    { word: 'maraud', word_class: 'V' },
    { word: 'marauder', word_class: 'N' },
    { word: 'marauding', word_class: 'N' },
    { word: 'marauding', word_class: 'AJ' }
  ],
  poundal: [
    { word: 'pound', word_class: 'N' },
    { word: 'pound', word_class: 'V' },
    { word: 'poundal', word_class: 'N' },
    { word: 'pounder', word_class: 'N' },
    { word: 'pounding', word_class: 'N' }
  ],
  hiss: [
    { word: 'hiss', word_class: 'N' },
    { word: 'hiss', word_class: 'V' },
    { word: 'hissing', word_class: 'N' },
    { word: 'hissing', word_class: 'AJ' }
  ],
  inexorable: [
    { word: 'inexorable', word_class: 'AJ' },
    { word: 'inexorably', word_class: 'AV' },
    { word: 'inexorability', word_class: 'N' },
    { word: 'inexorableness', word_class: 'N' }
  ],
  actuation: [
    { word: 'actuate', word_class: 'V' },
    { word: 'actuator', word_class: 'N' },
    { word: 'actuated', word_class: 'AJ' },
    { word: 'actuation', word_class: 'N' },
    { word: 'actuating', word_class: 'AJ' }
  ],
  horsy: [{ word: 'horse', word_class: 'N' }, { word: 'horse', word_class: 'V' }, { word: 'horsy', word_class: 'AJ' }],
  ambitiously: [
    { word: 'ambitious', word_class: 'AJ' },
    { word: 'ambitiously', word_class: 'AV' },
    { word: 'ambitiousness', word_class: 'N' }
  ],
  bullied: [
    { word: 'bully', word_class: 'N' },
    { word: 'bully', word_class: 'V' },
    { word: 'bully', word_class: 'AJ' },
    { word: 'bullied', word_class: 'AJ' },
    { word: 'bullying', word_class: 'N' },
    { word: 'bullying', word_class: 'AJ' }
  ],
  inoculate: [
    { word: 'inoculate', word_class: 'V' },
    { word: 'inoculating', word_class: 'N' },
    { word: 'inoculation', word_class: 'N' }
  ],
  improvisation: [
    { word: 'improvise', word_class: 'V' },
    { word: 'improvised', word_class: 'AJ' },
    { word: 'improvisation', word_class: 'N' }
  ],
  appropriable: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  grown: [
    { word: 'grow', word_class: 'V' },
    { word: 'grower', word_class: 'N' },
    { word: 'grown', word_class: 'AJ' },
    { word: 'growth', word_class: 'N' },
    { word: 'growing', word_class: 'N' },
    { word: 'growing', word_class: 'AJ' }
  ],
  homogenate: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  flowered: [
    { word: 'flower', word_class: 'N' },
    { word: 'flower', word_class: 'V' },
    { word: 'floral', word_class: 'AJ' },
    { word: 'florist', word_class: 'N' },
    { word: 'flowered', word_class: 'AJ' },
    { word: 'flowering', word_class: 'N' },
    { word: 'flowering', word_class: 'AJ' }
  ],
  devotement: [
    { word: 'devote', word_class: 'V' },
    { word: 'devoted', word_class: 'AJ' },
    { word: 'devotion', word_class: 'N' },
    { word: 'devotement', word_class: 'N' },
    { word: 'devotional', word_class: 'AJ' }
  ],
  husbandman: [
    { word: 'husband', word_class: 'N' },
    { word: 'husband', word_class: 'V' },
    { word: 'husbandry', word_class: 'N' },
    { word: 'husbandman', word_class: 'N' }
  ],
  jelled: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  catholicise: [
    { word: 'catholicise', word_class: 'V' },
    { word: 'catholicism', word_class: 'N' },
    { word: 'catholicize', word_class: 'V' },
    { word: 'catholicisation', word_class: 'N' },
    { word: 'catholicization', word_class: 'N' }
  ],
  connecting: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  sorely: [
    { word: 'sore', word_class: 'N' },
    { word: 'sore', word_class: 'AJ' },
    { word: 'sorely', word_class: 'AV' },
    { word: 'soreness', word_class: 'N' }
  ],
  hieroglyphically: [
    { word: 'hieroglyph', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'AJ' },
    { word: 'hieroglyphical', word_class: 'AJ' },
    { word: 'hieroglyphically', word_class: 'AV' }
  ],
  frustration: [
    { word: 'frustrate', word_class: 'V' },
    { word: 'frustrated', word_class: 'AJ' },
    { word: 'frustrating', word_class: 'N' },
    { word: 'frustration', word_class: 'N' },
    { word: 'frustrating', word_class: 'AJ' }
  ],
  quizzically: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  canvass: [
    { word: 'canvass', word_class: 'N' },
    { word: 'canvass', word_class: 'V' },
    { word: 'canvasser', word_class: 'N' },
    { word: 'canvassing', word_class: 'N' }
  ],
  narrate: [
    { word: 'narrate', word_class: 'V' },
    { word: 'narrator', word_class: 'N' },
    { word: 'narration', word_class: 'N' },
    { word: 'narrative', word_class: 'N' },
    { word: 'narrative', word_class: 'AJ' }
  ],
  relations: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  divulgence: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  formalise: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  dodging: [
    { word: 'dodge', word_class: 'N' },
    { word: 'dodge', word_class: 'V' },
    { word: 'dodger', word_class: 'N' },
    { word: 'dodging', word_class: 'N' }
  ],
  gabble: [
    { word: 'gabble', word_class: 'N' },
    { word: 'gabble', word_class: 'V' },
    { word: 'gabbling', word_class: 'AJ' }
  ],
  lugubriousness: [
    { word: 'lugubrious', word_class: 'AJ' },
    { word: 'lugubriously', word_class: 'AV' },
    { word: 'lugubriousness', word_class: 'N' }
  ],
  adjudicative: [
    { word: 'adjudicate', word_class: 'V' },
    { word: 'adjudicator', word_class: 'N' },
    { word: 'adjudication', word_class: 'N' },
    { word: 'adjudicative', word_class: 'AJ' }
  ],
  dunked: [
    { word: 'dunk', word_class: 'N' },
    { word: 'dunk', word_class: 'V' },
    { word: 'dunker', word_class: 'N' },
    { word: 'dunked', word_class: 'AJ' },
    { word: 'dunkers', word_class: 'N' }
  ],
  amicably: [
    { word: 'amicable', word_class: 'AJ' },
    { word: 'amicably', word_class: 'AV' },
    { word: 'amicability', word_class: 'N' },
    { word: 'amicableness', word_class: 'N' }
  ],
  battlement: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  costumed: [
    { word: 'costume', word_class: 'N' },
    { word: 'costume', word_class: 'V' },
    { word: 'costume', word_class: 'AJ' },
    { word: 'costumed', word_class: 'AJ' }
  ],
  despond: [
    { word: 'despond', word_class: 'V' },
    { word: 'despondence', word_class: 'N' },
    { word: 'despondency', word_class: 'N' },
    { word: 'despondent', word_class: 'AJ' },
    { word: 'despondently', word_class: 'AV' }
  ],
  subsiding: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  powerfulness: [
    { word: 'power', word_class: 'N' },
    { word: 'power', word_class: 'V' },
    { word: 'power', word_class: 'AJ' },
    { word: 'powered', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AJ' },
    { word: 'powerful', word_class: 'AV' },
    { word: 'powerfulness', word_class: 'N' }
  ],
  congregational: [
    { word: 'congregate', word_class: 'V' },
    { word: 'congregate', word_class: 'AJ' },
    { word: 'congregating', word_class: 'N' },
    { word: 'congregation', word_class: 'N' },
    { word: 'congregational', word_class: 'AJ' }
  ],
  bushed: [
    { word: 'bush', word_class: 'N' },
    { word: 'bush', word_class: 'V' },
    { word: 'bushed', word_class: 'AJ' },
    { word: 'bushing', word_class: 'N' }
  ],
  tannage: [
    { word: 'tan', word_class: 'N' },
    { word: 'tan', word_class: 'V' },
    { word: 'tan', word_class: 'AJ' },
    { word: 'tannage', word_class: 'N' },
    { word: 'tanned', word_class: 'AJ' }
  ],
  typewritten: [
    { word: 'typewrite', word_class: 'V' },
    { word: 'typewriter', word_class: 'N' },
    { word: 'typewriting', word_class: 'N' },
    { word: 'typewritten', word_class: 'AJ' }
  ],
  topically: [
    { word: 'topic', word_class: 'N' },
    { word: 'topical', word_class: 'AJ' },
    { word: 'topically', word_class: 'AV' }
  ],
  irreversibility: [
    { word: 'irreversible', word_class: 'AJ' },
    { word: 'irreversibly', word_class: 'AV' },
    { word: 'irreversibility', word_class: 'N' }
  ],
  crusade: [
    { word: 'crusade', word_class: 'N' },
    { word: 'crusade', word_class: 'V' },
    { word: 'crusader', word_class: 'N' }
  ],
  smelt: [{ word: 'smelt', word_class: 'N' }, { word: 'smelt', word_class: 'V' }, { word: 'smelter', word_class: 'N' }],
  italicise: [
    { word: 'italicise', word_class: 'V' },
    { word: 'italicize', word_class: 'V' },
    { word: 'italicisation', word_class: 'N' },
    { word: 'italicization', word_class: 'N' }
  ],
  penance: [
    { word: 'pen', word_class: 'V' },
    { word: 'pen', word_class: 'N' },
    { word: 'penman', word_class: 'N' },
    { word: 'penance', word_class: 'N' },
    { word: 'penance', word_class: 'V' },
    { word: 'penned', word_class: 'AJ' },
    { word: 'penning', word_class: 'N' }
  ],
  exulting: [
    { word: 'exult', word_class: 'V' },
    { word: 'exultant', word_class: 'N' },
    { word: 'exultance', word_class: 'N' },
    { word: 'exultant', word_class: 'AJ' },
    { word: 'exulting', word_class: 'AJ' },
    { word: 'exultation', word_class: 'N' }
  ],
  interact: [
    { word: 'interact', word_class: 'V' },
    { word: 'interaction', word_class: 'N' },
    { word: 'interactive', word_class: 'AJ' },
    { word: 'interactional', word_class: 'AJ' }
  ],
  foulness: [
    { word: 'foul', word_class: 'N' },
    { word: 'foul', word_class: 'V' },
    { word: 'foul', word_class: 'AJ' },
    { word: 'fouled', word_class: 'AJ' },
    { word: 'foulness', word_class: 'N' }
  ],
  greenness: [
    { word: 'green', word_class: 'N' },
    { word: 'green', word_class: 'V' },
    { word: 'green', word_class: 'AJ' },
    { word: 'greens', word_class: 'N' },
    { word: 'greenery', word_class: 'N' },
    { word: 'greening', word_class: 'N' },
    { word: 'greening', word_class: 'AJ' },
    { word: 'greenness', word_class: 'N' }
  ],
  infold: [
    { word: 'infold', word_class: 'V' },
    { word: 'infolding', word_class: 'N' },
    { word: 'infoldment', word_class: 'N' }
  ],
  sincerely: [
    { word: 'sincere', word_class: 'AJ' },
    { word: 'sincerity', word_class: 'N' },
    { word: 'sincerely', word_class: 'AV' }
  ],
  optimism: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  demurrer: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  decease: [
    { word: 'decease', word_class: 'N' },
    { word: 'decease', word_class: 'V' },
    { word: 'deceased', word_class: 'N' },
    { word: 'deceased', word_class: 'AJ' }
  ],
  royally: [
    { word: 'royal', word_class: 'N' },
    { word: 'royal', word_class: 'AJ' },
    { word: 'royalty', word_class: 'N' },
    { word: 'royally', word_class: 'AV' }
  ],
  vehement: [
    { word: 'vehemence', word_class: 'N' },
    { word: 'vehemency', word_class: 'N' },
    { word: 'vehement', word_class: 'AJ' },
    { word: 'vehemently', word_class: 'AV' }
  ],
  castled: [
    { word: 'castle', word_class: 'N' },
    { word: 'castle', word_class: 'V' },
    { word: 'castled', word_class: 'AJ' },
    { word: 'castling', word_class: 'N' }
  ],
  varnished: [
    { word: 'varnish', word_class: 'N' },
    { word: 'varnish', word_class: 'V' },
    { word: 'varnished', word_class: 'AJ' }
  ],
  expectorant: [
    { word: 'expectorant', word_class: 'N' },
    { word: 'expectorate', word_class: 'V' },
    { word: 'expectoration', word_class: 'N' }
  ],
  herald: [
    { word: 'herald', word_class: 'N' },
    { word: 'herald', word_class: 'V' },
    { word: 'heralded', word_class: 'AJ' },
    { word: 'heraldic', word_class: 'AJ' }
  ],
  shielded: [
    { word: 'shield', word_class: 'N' },
    { word: 'shield', word_class: 'V' },
    { word: 'shielded', word_class: 'AJ' },
    { word: 'shielding', word_class: 'N' },
    { word: 'shielding', word_class: 'AJ' }
  ],
  ireful: [
    { word: 'ir', word_class: 'N' },
    { word: 'ire', word_class: 'N' },
    { word: 'irs', word_class: 'N' },
    { word: 'ireful', word_class: 'AJ' }
  ],
  tinged: [
    { word: 'ting', word_class: 'N' },
    { word: 'ting', word_class: 'V' },
    { word: 'tinge', word_class: 'N' },
    { word: 'tinge', word_class: 'V' },
    { word: 'tinged', word_class: 'AJ' }
  ],
  resurgent: [
    { word: 'resurge', word_class: 'N' },
    { word: 'resurge', word_class: 'V' },
    { word: 'resurgence', word_class: 'N' },
    { word: 'resurgent', word_class: 'AJ' }
  ],
  rashness: [
    { word: 'rash', word_class: 'N' },
    { word: 'rash', word_class: 'AJ' },
    { word: 'rashness', word_class: 'N' }
  ],
  twinkly: [
    { word: 'twinkle', word_class: 'N' },
    { word: 'twinkle', word_class: 'V' },
    { word: 'twinkly', word_class: 'AJ' },
    { word: 'twinkling', word_class: 'N' },
    { word: 'twinkling', word_class: 'AJ' }
  ],
  putrefiable: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  believable: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  prognosticate: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  cobbler: [
    { word: 'cobble', word_class: 'N' },
    { word: 'cobble', word_class: 'V' },
    { word: 'cobbler', word_class: 'N' },
    { word: 'cobbling', word_class: 'N' }
  ],
  reverberance: [
    { word: 'reverberate', word_class: 'V' },
    { word: 'reverberance', word_class: 'N' },
    { word: 'reverberant', word_class: 'AJ' },
    { word: 'reverberation', word_class: 'N' },
    { word: 'reverberating', word_class: 'AJ' }
  ],
  visibly: [
    { word: 'visible', word_class: 'AJ' },
    { word: 'visibly', word_class: 'AV' },
    { word: 'visibility', word_class: 'N' },
    { word: 'visibleness', word_class: 'N' }
  ],
  mormon: [
    { word: 'mormon', word_class: 'N' },
    { word: 'mormon', word_class: 'AJ' },
    { word: 'mormons', word_class: 'N' },
    { word: 'mormonism', word_class: 'N' }
  ],
  veil: [
    { word: 'veil', word_class: 'N' },
    { word: 'veil', word_class: 'V' },
    { word: 'veiled', word_class: 'AJ' },
    { word: 'veiling', word_class: 'N' }
  ],
  selectivity: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  virile: [
    { word: 'virile', word_class: 'AJ' },
    { word: 'virility', word_class: 'N' },
    { word: 'virilization', word_class: 'N' }
  ],
  chicanery: [
    { word: 'chicane', word_class: 'N' },
    { word: 'chicane', word_class: 'V' },
    { word: 'chicanery', word_class: 'N' }
  ],
  winy: [
    { word: 'wine', word_class: 'N' },
    { word: 'wine', word_class: 'V' },
    { word: 'winy', word_class: 'AJ' },
    { word: 'winery', word_class: 'N' },
    { word: 'winemaker', word_class: 'N' },
    { word: 'winemaking', word_class: 'N' }
  ],
  then: [{ word: 'then', word_class: 'N' }, { word: 'then', word_class: 'AJ' }, { word: 'then', word_class: 'AV' }],
  indicator: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  backer: [
    { word: 'back', word_class: 'N' },
    { word: 'back', word_class: 'V' },
    { word: 'back', word_class: 'AJ' },
    { word: 'back', word_class: 'AV' },
    { word: 'backer', word_class: 'N' },
    { word: 'backed', word_class: 'AJ' },
    { word: 'backing', word_class: 'N' }
  ],
  inviolable: [
    { word: 'inviolate', word_class: 'V' },
    { word: 'inviolate', word_class: 'AJ' },
    { word: 'inviolable', word_class: 'AJ' }
  ],
  basophile: [
    { word: 'basophil', word_class: 'N' },
    { word: 'basophile', word_class: 'N' },
    { word: 'basophilic', word_class: 'AJ' }
  ],
  gentleness: [
    { word: 'gentle', word_class: 'V' },
    { word: 'gentle', word_class: 'AJ' },
    { word: 'gentleness', word_class: 'N' }
  ],
  attractable: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  meticulously: [
    { word: 'meticulous', word_class: 'AJ' },
    { word: 'meticulously', word_class: 'AV' },
    { word: 'meticulousness', word_class: 'N' }
  ],
  leavening: [
    { word: 'leaven', word_class: 'N' },
    { word: 'leaven', word_class: 'V' },
    { word: 'leavened', word_class: 'AJ' },
    { word: 'leavening', word_class: 'N' }
  ],
  dissolubility: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  hone: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  fraudulently: [
    { word: 'fraud', word_class: 'N' },
    { word: 'fraudulence', word_class: 'N' },
    { word: 'fraudulent', word_class: 'AJ' },
    { word: 'fraudulently', word_class: 'AV' }
  ],
  deepen: [
    { word: 'deepen', word_class: 'V' },
    { word: 'deepened', word_class: 'AJ' },
    { word: 'deepening', word_class: 'N' },
    { word: 'deepening', word_class: 'AJ' }
  ],
  protectorate: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  unchanging: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  bandaged: [
    { word: 'bandage', word_class: 'N' },
    { word: 'bandage', word_class: 'V' },
    { word: 'bandaged', word_class: 'AJ' },
    { word: 'bandaging', word_class: 'N' }
  ],
  organ: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  sanctimonious: [
    { word: 'sanctimony', word_class: 'N' },
    { word: 'sanctimonious', word_class: 'AJ' },
    { word: 'sanctimoniously', word_class: 'AV' },
    { word: 'sanctimoniousness', word_class: 'N' }
  ],
  sagaciousness: [
    { word: 'sagacious', word_class: 'AJ' },
    { word: 'sagaciously', word_class: 'AV' },
    { word: 'sagaciousness', word_class: 'N' }
  ],
  fantasize: [
    { word: 'fantasy', word_class: 'N' },
    { word: 'fantasia', word_class: 'N' },
    { word: 'fantasist', word_class: 'N' },
    { word: 'fantasize', word_class: 'V' }
  ],
  institute: [
    { word: 'institute', word_class: 'N' },
    { word: 'institute', word_class: 'V' },
    { word: 'institution', word_class: 'N' },
    { word: 'institutional', word_class: 'AJ' }
  ],
  yaw: [{ word: 'yaw', word_class: 'N' }, { word: 'yaw', word_class: 'V' }, { word: 'yaws', word_class: 'N' }],
  exterminator: [
    { word: 'exterminate', word_class: 'V' },
    { word: 'exterminator', word_class: 'N' },
    { word: 'exterminable', word_class: 'AJ' },
    { word: 'exterminated', word_class: 'AJ' },
    { word: 'extermination', word_class: 'N' }
  ],
  titillation: [
    { word: 'titillate', word_class: 'V' },
    { word: 'titillated', word_class: 'AJ' },
    { word: 'titillation', word_class: 'N' },
    { word: 'titillating', word_class: 'AJ' }
  ],
  laboriousness: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  atonal: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  signer: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  denigrate: [
    { word: 'denigrate', word_class: 'V' },
    { word: 'denigration', word_class: 'N' },
    { word: 'denigrating', word_class: 'AJ' },
    { word: 'denigrative', word_class: 'AJ' }
  ],
  entire: [
    { word: 'entire', word_class: 'N' },
    { word: 'entire', word_class: 'AJ' },
    { word: 'entirety', word_class: 'N' },
    { word: 'entirely', word_class: 'AV' },
    { word: 'entireness', word_class: 'N' }
  ],
  autogenic: [
    { word: 'autogenic', word_class: 'AJ' },
    { word: 'autogenics', word_class: 'N' },
    { word: 'autogenous', word_class: 'AJ' }
  ],
  acridity: [
    { word: 'acrid', word_class: 'AJ' },
    { word: 'acridity', word_class: 'N' },
    { word: 'acridness', word_class: 'N' }
  ],
  unilateralism: [
    { word: 'unilateral', word_class: 'AJ' },
    { word: 'unilateralism', word_class: 'N' },
    { word: 'unilaterally', word_class: 'AV' }
  ],
  surfing: [
    { word: 'surf', word_class: 'N' },
    { word: 'surf', word_class: 'V' },
    { word: 'surfer', word_class: 'N' },
    { word: 'surfing', word_class: 'N' }
  ],
  clinking: [
    { word: 'clink', word_class: 'N' },
    { word: 'clink', word_class: 'V' },
    { word: 'clinker', word_class: 'N' },
    { word: 'clinker', word_class: 'V' },
    { word: 'clinking', word_class: 'AJ' }
  ],
  potterer: [
    { word: 'potter', word_class: 'N' },
    { word: 'potter', word_class: 'V' },
    { word: 'potterer', word_class: 'N' }
  ],
  slumber: [
    { word: 'slumber', word_class: 'N' },
    { word: 'slumber', word_class: 'V' },
    { word: 'slumberer', word_class: 'N' },
    { word: 'slumbering', word_class: 'AJ' },
    { word: 'slumberous', word_class: 'AJ' }
  ],
  hypostatise: [
    { word: 'hypostasis', word_class: 'N' },
    { word: 'hypostatise', word_class: 'V' },
    { word: 'hypostatize', word_class: 'V' },
    { word: 'hypostatisation', word_class: 'N' },
    { word: 'hypostatization', word_class: 'N' }
  ],
  hoaxer: [{ word: 'hoax', word_class: 'N' }, { word: 'hoax', word_class: 'V' }, { word: 'hoaxer', word_class: 'N' }],
  distil: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  recurrence: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  solidity: [
    { word: 'solid', word_class: 'N' },
    { word: 'solid', word_class: 'AJ' },
    { word: 'solidity', word_class: 'N' },
    { word: 'solidness', word_class: 'N' }
  ],
  monster: [
    { word: 'monster', word_class: 'N' },
    { word: 'monstrous', word_class: 'AJ' },
    { word: 'monstrosity', word_class: 'N' }
  ],
  intoned: [
    { word: 'intone', word_class: 'N' },
    { word: 'intone', word_class: 'V' },
    { word: 'intonate', word_class: 'V' },
    { word: 'intoned', word_class: 'AJ' },
    { word: 'intonation', word_class: 'N' }
  ],
  'dry-cleaning': [
    { word: 'dry-clean', word_class: 'V' },
    { word: 'dry-cleaned', word_class: 'AJ' },
    { word: 'dry-cleaning', word_class: 'N' }
  ],
  gynecologic: [
    { word: 'gynecology', word_class: 'N' },
    { word: 'gynecologic', word_class: 'AJ' },
    { word: 'gynecological', word_class: 'AJ' }
  ],
  wadding: [
    { word: 'wad', word_class: 'N' },
    { word: 'wad', word_class: 'V' },
    { word: 'wads', word_class: 'N' },
    { word: 'wadding', word_class: 'N' }
  ],
  episcopalianism: [
    { word: 'episcopalian', word_class: 'N' },
    { word: 'episcopalian', word_class: 'AJ' },
    { word: 'episcopalianism', word_class: 'N' }
  ],
  tolerable: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  idolise: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  lunge: [
    { word: 'lung', word_class: 'N' },
    { word: 'lunge', word_class: 'N' },
    { word: 'lunge', word_class: 'V' },
    { word: 'lunger', word_class: 'N' }
  ],
  diametrically: [
    { word: 'diameter', word_class: 'N' },
    { word: 'diametral', word_class: 'AJ' },
    { word: 'diametric', word_class: 'AJ' },
    { word: 'diametrical', word_class: 'AJ' },
    { word: 'diametrically', word_class: 'AV' }
  ],
  mighty: [
    { word: 'mighty', word_class: 'AJ' },
    { word: 'mighty', word_class: 'AV' },
    { word: 'mightiness', word_class: 'N' }
  ],
  pollinate: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  tiling: [
    { word: 'tile', word_class: 'N' },
    { word: 'tile', word_class: 'V' },
    { word: 'tiled', word_class: 'AJ' },
    { word: 'tiling', word_class: 'N' }
  ],
  offsides: [
    { word: 'offside', word_class: 'AJ' },
    { word: 'offside', word_class: 'AV' },
    { word: 'offsides', word_class: 'AJ' }
  ],
  glorify: [
    { word: 'glory', word_class: 'N' },
    { word: 'glory', word_class: 'V' },
    { word: 'glorify', word_class: 'V' },
    { word: 'glorified', word_class: 'AJ' },
    { word: 'glorification', word_class: 'N' }
  ],
  desalinization: [
    { word: 'desalinate', word_class: 'V' },
    { word: 'desalinize', word_class: 'V' },
    { word: 'desalinated', word_class: 'AJ' },
    { word: 'desalination', word_class: 'N' },
    { word: 'desalinization', word_class: 'N' }
  ],
  vileness: [
    { word: 'vile', word_class: 'AJ' },
    { word: 'vilely', word_class: 'AV' },
    { word: 'vileness', word_class: 'N' }
  ],
  blusterous: [
    { word: 'bluster', word_class: 'N' },
    { word: 'bluster', word_class: 'V' },
    { word: 'blusterer', word_class: 'N' },
    { word: 'blustering', word_class: 'AJ' },
    { word: 'blusterous', word_class: 'AJ' }
  ],
  brutally: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  monitoring: [
    { word: 'monitor', word_class: 'N' },
    { word: 'monitor', word_class: 'V' },
    { word: 'monitoring', word_class: 'N' }
  ],
  conceivability: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  imperfect: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  sensed: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  blitheness: [
    { word: 'blithe', word_class: 'AJ' },
    { word: 'blithely', word_class: 'AV' },
    { word: 'blitheness', word_class: 'N' }
  ],
  executant: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  wildness: [
    { word: 'wild', word_class: 'N' },
    { word: 'wild', word_class: 'AJ' },
    { word: 'wild', word_class: 'AV' },
    { word: 'wildness', word_class: 'N' }
  ],
  papistic: [
    { word: 'papist', word_class: 'N' },
    { word: 'papist', word_class: 'AJ' },
    { word: 'papistic', word_class: 'AJ' },
    { word: 'papistical', word_class: 'AJ' }
  ],
  swingeing: [
    { word: 'swing', word_class: 'N' },
    { word: 'swing', word_class: 'V' },
    { word: 'swinge', word_class: 'V' },
    { word: 'swinger', word_class: 'N' },
    { word: 'swinging', word_class: 'N' },
    { word: 'swinging', word_class: 'AJ' },
    { word: 'swingeing', word_class: 'AJ' }
  ],
  poaching: [
    { word: 'poach', word_class: 'V' },
    { word: 'poacher', word_class: 'N' },
    { word: 'poached', word_class: 'AJ' },
    { word: 'poaching', word_class: 'N' }
  ],
  shameful: [
    { word: 'shame', word_class: 'N' },
    { word: 'shame', word_class: 'V' },
    { word: 'shamed', word_class: 'AJ' },
    { word: 'shameful', word_class: 'AJ' },
    { word: 'shamefulness', word_class: 'N' }
  ],
  considerateness: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  cumulatively: [
    { word: 'cumulate', word_class: 'V' },
    { word: 'cumulation', word_class: 'N' },
    { word: 'cumulative', word_class: 'AJ' },
    { word: 'cumulatively', word_class: 'AV' }
  ],
  discontinued: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  electrodeposition: [
    { word: 'electrodeposit', word_class: 'V' },
    { word: 'elecrodeposition', word_class: 'N' },
    { word: 'electrodeposition', word_class: 'N' }
  ],
  noticeability: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  impregnably: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  prevalence: [
    { word: 'prevail', word_class: 'V' },
    { word: 'prevalence', word_class: 'N' },
    { word: 'prevalent', word_class: 'AJ' },
    { word: 'prevailing', word_class: 'AJ' }
  ],
  stoutness: [
    { word: 'stout', word_class: 'N' },
    { word: 'stout', word_class: 'AJ' },
    { word: 'stoutness', word_class: 'N' }
  ],
  discombobulated: [
    { word: 'discombobulate', word_class: 'V' },
    { word: 'discombobulated', word_class: 'AJ' },
    { word: 'discombobulation', word_class: 'N' }
  ],
  deaminization: [
    { word: 'deaminate', word_class: 'V' },
    { word: 'deaminize', word_class: 'V' },
    { word: 'deamination', word_class: 'N' },
    { word: 'deaminisation', word_class: 'N' },
    { word: 'deaminization', word_class: 'N' }
  ],
  expansive: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  lingering: [
    { word: 'linger', word_class: 'V' },
    { word: 'lingerer', word_class: 'N' },
    { word: 'lingering', word_class: 'N' },
    { word: 'lingering', word_class: 'AJ' }
  ],
  install: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  amorously: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  inconspicuous: [
    { word: 'inconspicuous', word_class: 'AJ' },
    { word: 'inconspicuously', word_class: 'AV' },
    { word: 'inconspicuousness', word_class: 'N' }
  ],
  carefulness: [
    { word: 'care', word_class: 'N' },
    { word: 'care', word_class: 'V' },
    { word: 'caring', word_class: 'N' },
    { word: 'caring', word_class: 'AJ' },
    { word: 'careful', word_class: 'AJ' },
    { word: 'carefulness', word_class: 'N' }
  ],
  tearfulness: [
    { word: 'tear', word_class: 'N' },
    { word: 'tear', word_class: 'V' },
    { word: 'tears', word_class: 'N' },
    { word: 'torn', word_class: 'AJ' },
    { word: 'tearing', word_class: 'N' },
    { word: 'tearful', word_class: 'AJ' },
    { word: 'tearing', word_class: 'AJ' },
    { word: 'tearfulness', word_class: 'N' }
  ],
  optimist: [
    { word: 'optimist', word_class: 'N' },
    { word: 'optimistic', word_class: 'AJ' },
    { word: 'optimistically', word_class: 'AV' }
  ],
  exegete: [
    { word: 'exegete', word_class: 'N' },
    { word: 'exegetic', word_class: 'AJ' },
    { word: 'exegetical', word_class: 'AJ' }
  ],
  crust: [
    { word: 'crust', word_class: 'N' },
    { word: 'crust', word_class: 'V' },
    { word: 'crusted', word_class: 'AJ' }
  ],
  amortize: [
    { word: 'amort', word_class: 'AJ' },
    { word: 'amortize', word_class: 'V' },
    { word: 'amortization', word_class: 'N' }
  ],
  rough: [
    { word: 'rough', word_class: 'N' },
    { word: 'rough', word_class: 'V' },
    { word: 'rough', word_class: 'AJ' },
    { word: 'rough', word_class: 'AV' },
    { word: 'roughage', word_class: 'N' },
    { word: 'roughness', word_class: 'N' }
  ],
  wings: [
    { word: 'wing', word_class: 'N' },
    { word: 'wing', word_class: 'V' },
    { word: 'wings', word_class: 'N' },
    { word: 'winger', word_class: 'N' },
    { word: 'winged', word_class: 'AJ' },
    { word: 'wingman', word_class: 'N' }
  ],
  auntie: [{ word: 'aunt', word_class: 'N' }, { word: 'aunty', word_class: 'N' }, { word: 'auntie', word_class: 'N' }],
  invalidness: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  adaptive: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  pollute: [
    { word: 'pollute', word_class: 'V' },
    { word: 'pollutant', word_class: 'N' },
    { word: 'polluted', word_class: 'AJ' },
    { word: 'pollution', word_class: 'N' }
  ],
  axial: [
    { word: 'axis', word_class: 'N' },
    { word: 'axial', word_class: 'AJ' },
    { word: 'axially', word_class: 'AV' }
  ],
  privateness: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  amphitheatre: [
    { word: 'amphitheatre', word_class: 'N' },
    { word: 'amphitheatric', word_class: 'AJ' },
    { word: 'amphitheatrical', word_class: 'AJ' }
  ],
  bagman: [
    { word: 'bag', word_class: 'N' },
    { word: 'bag', word_class: 'V' },
    { word: 'bagful', word_class: 'N' },
    { word: 'bagman', word_class: 'N' },
    { word: 'bagful', word_class: 'AJ' },
    { word: 'baggage', word_class: 'N' },
    { word: 'bagging', word_class: 'N' }
  ],
  humanities: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  intellection: [
    { word: 'intellect', word_class: 'N' },
    { word: 'intellection', word_class: 'N' },
    { word: 'intellectual', word_class: 'N' },
    { word: 'intellectual', word_class: 'AJ' },
    { word: 'intellectualise', word_class: 'V' },
    { word: 'intellectualize', word_class: 'V' },
    { word: 'intellectually', word_class: 'AV' },
    { word: 'intellectualisation', word_class: 'N' },
    { word: 'intellectualization', word_class: 'N' }
  ],
  garlic: [
    { word: 'garlic', word_class: 'N' },
    { word: 'garlicky', word_class: 'AJ' },
    { word: 'garlicked', word_class: 'AJ' }
  ],
  sampler: [
    { word: 'sample', word_class: 'N' },
    { word: 'sample', word_class: 'V' },
    { word: 'sampler', word_class: 'N' },
    { word: 'sampling', word_class: 'N' }
  ],
  carburization: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  traffic: [
    { word: 'traffic', word_class: 'N' },
    { word: 'traffic', word_class: 'V' },
    { word: 'trafficker', word_class: 'N' },
    { word: 'trafficator', word_class: 'N' },
    { word: 'trafficking', word_class: 'AJ' }
  ],
  conglobe: [
    { word: 'conglobe', word_class: 'V' },
    { word: 'conglobate', word_class: 'V' },
    { word: 'conglobation', word_class: 'N' }
  ],
  devastate: [
    { word: 'devastate', word_class: 'V' },
    { word: 'devastated', word_class: 'AJ' },
    { word: 'devastation', word_class: 'N' },
    { word: 'devastating', word_class: 'AJ' }
  ],
  steamship: [
    { word: 'steam', word_class: 'N' },
    { word: 'steam', word_class: 'V' },
    { word: 'steamer', word_class: 'N' },
    { word: 'steamed', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AV' },
    { word: 'steamship', word_class: 'N' }
  ],
  chapped: [
    { word: 'chap', word_class: 'N' },
    { word: 'chap', word_class: 'V' },
    { word: 'chapman', word_class: 'N' },
    { word: 'chapped', word_class: 'AJ' }
  ],
  describable: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  implantation: [
    { word: 'implant', word_class: 'N' },
    { word: 'implant', word_class: 'V' },
    { word: 'implanted', word_class: 'AJ' },
    { word: 'implantation', word_class: 'N' }
  ],
  effusively: [
    { word: 'effuse', word_class: 'V' },
    { word: 'effusion', word_class: 'N' },
    { word: 'effusive', word_class: 'AJ' },
    { word: 'effusively', word_class: 'AV' },
    { word: 'effusiveness', word_class: 'N' }
  ],
  superpose: [
    { word: 'superpose', word_class: 'N' },
    { word: 'superpose', word_class: 'V' },
    { word: 'superposable', word_class: 'AJ' },
    { word: 'superposition', word_class: 'N' }
  ],
  detonable: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  procural: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  conspirator: [
    { word: 'conspire', word_class: 'V' },
    { word: 'conspiracy', word_class: 'N' },
    { word: 'conspirator', word_class: 'N' },
    { word: 'conspirative', word_class: 'AJ' }
  ],
  inheritor: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  piggery: [
    { word: 'pig', word_class: 'N' },
    { word: 'pig', word_class: 'V' },
    { word: 'pigman', word_class: 'N' },
    { word: 'piggery', word_class: 'N' },
    { word: 'pigment', word_class: 'N' },
    { word: 'pigment', word_class: 'V' },
    { word: 'pigmentation', word_class: 'N' }
  ],
  vocalist: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  severally: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  uncovering: [
    { word: 'uncover', word_class: 'V' },
    { word: 'uncovered', word_class: 'AJ' },
    { word: 'uncovering', word_class: 'N' }
  ],
  'deaf-muteness': [
    { word: 'deaf-mute', word_class: 'N' },
    { word: 'deaf-mute', word_class: 'AJ' },
    { word: 'deaf-mutism', word_class: 'N' },
    { word: 'deaf-muteness', word_class: 'N' }
  ],
  implied: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  serialization: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  dribbling: [
    { word: 'dribble', word_class: 'N' },
    { word: 'dribble', word_class: 'V' },
    { word: 'dribbler', word_class: 'N' },
    { word: 'dribbling', word_class: 'N' }
  ],
  secretiveness: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  harry: [
    { word: 'harry', word_class: 'V' },
    { word: 'harrier', word_class: 'N' },
    { word: 'harried', word_class: 'AJ' }
  ],
  kyanize: [
    { word: 'kyanise', word_class: 'V' },
    { word: 'kyanize', word_class: 'V' },
    { word: 'kyanisation', word_class: 'N' },
    { word: 'kyanization', word_class: 'N' }
  ],
  outgoing: [
    { word: 'outgo', word_class: 'N' },
    { word: 'outgo', word_class: 'V' },
    { word: 'outgoing', word_class: 'AJ' }
  ],
  decarburise: [
    { word: 'decarburise', word_class: 'V' },
    { word: 'decarburize', word_class: 'V' },
    { word: 'decarburation', word_class: 'N' },
    { word: 'decarburisation', word_class: 'N' },
    { word: 'decarburization', word_class: 'N' }
  ],
  spluttering: [
    { word: 'splutter', word_class: 'N' },
    { word: 'splutter', word_class: 'V' },
    { word: 'spluttering', word_class: 'AJ' }
  ],
  nicely: [
    { word: 'nice', word_class: 'N' },
    { word: 'nice', word_class: 'AJ' },
    { word: 'nicety', word_class: 'N' },
    { word: 'nicely', word_class: 'AV' },
    { word: 'niceness', word_class: 'N' }
  ],
  manicure: [
    { word: 'manicure', word_class: 'N' },
    { word: 'manicure', word_class: 'V' },
    { word: 'manicurist', word_class: 'N' }
  ],
  suffix: [
    { word: 'suffix', word_class: 'N' },
    { word: 'suffix', word_class: 'V' },
    { word: 'suffixion', word_class: 'N' },
    { word: 'suffixation', word_class: 'N' }
  ],
  defend: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  percolate: [
    { word: 'percolate', word_class: 'V' },
    { word: 'percolator', word_class: 'N' },
    { word: 'percolation', word_class: 'N' }
  ],
  dogging: [
    { word: 'dog', word_class: 'N' },
    { word: 'dog', word_class: 'V' },
    { word: 'dogged', word_class: 'AJ' },
    { word: 'dogging', word_class: 'AJ' }
  ],
  tickle: [
    { word: 'tickle', word_class: 'N' },
    { word: 'tickle', word_class: 'V' },
    { word: 'tickler', word_class: 'N' },
    { word: 'tickling', word_class: 'N' },
    { word: 'tickling', word_class: 'AJ' },
    { word: 'ticklish', word_class: 'AJ' }
  ],
  incorporation: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  leak: [
    { word: 'leak', word_class: 'N' },
    { word: 'leak', word_class: 'V' },
    { word: 'leakage', word_class: 'N' },
    { word: 'leaking', word_class: 'AJ' }
  ],
  melodic: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  tatter: [
    { word: 'tatter', word_class: 'N' },
    { word: 'tatter', word_class: 'V' },
    { word: 'tattered', word_class: 'AJ' }
  ],
  curse: [
    { word: 'curse', word_class: 'N' },
    { word: 'curse', word_class: 'V' },
    { word: 'cursor', word_class: 'N' },
    { word: 'cursed', word_class: 'AJ' },
    { word: 'cursing', word_class: 'AJ' }
  ],
  adherence: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  individualisation: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  remonstrate: [
    { word: 'remonstrant', word_class: 'N' },
    { word: 'remonstrate', word_class: 'V' },
    { word: 'remonstrance', word_class: 'N' },
    { word: 'remonstrant', word_class: 'AJ' },
    { word: 'remonstration', word_class: 'N' }
  ],
  heterological: [
    { word: 'heterologic', word_class: 'AJ' },
    { word: 'heterologous', word_class: 'AJ' },
    { word: 'heterological', word_class: 'AJ' }
  ],
  centrally: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  morals: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  detent: [
    { word: 'detain', word_class: 'V' },
    { word: 'detent', word_class: 'N' },
    { word: 'detente', word_class: 'N' },
    { word: 'detainee', word_class: 'N' },
    { word: 'detention', word_class: 'N' }
  ],
  mouse: [
    { word: 'mouse', word_class: 'N' },
    { word: 'mouse', word_class: 'V' },
    { word: 'mousy', word_class: 'AJ' },
    { word: 'mousing', word_class: 'N' }
  ],
  inopportuneness: [
    { word: 'inopportune', word_class: 'AJ' },
    { word: 'inopportunely', word_class: 'AV' },
    { word: 'inopportuneness', word_class: 'N' }
  ],
  evangelicalism: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  abandoned: [
    { word: 'abandon', word_class: 'N' },
    { word: 'abandon', word_class: 'V' },
    { word: 'abandoned', word_class: 'AJ' },
    { word: 'abandonment', word_class: 'N' }
  ],
  canopy: [
    { word: 'canopy', word_class: 'N' },
    { word: 'canopy', word_class: 'V' },
    { word: 'canopied', word_class: 'AJ' }
  ],
  meritoriousness: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  profuseness: [
    { word: 'profuse', word_class: 'N' },
    { word: 'profuse', word_class: 'V' },
    { word: 'profuse', word_class: 'AJ' },
    { word: 'profusion', word_class: 'N' },
    { word: 'profusely', word_class: 'AV' },
    { word: 'profuseness', word_class: 'N' }
  ],
  intuition: [
    { word: 'intuit', word_class: 'V' },
    { word: 'intuition', word_class: 'N' },
    { word: 'intuitive', word_class: 'AJ' },
    { word: 'intuitively', word_class: 'AV' }
  ],
  alternatively: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  impermanency: [
    { word: 'impermanence', word_class: 'N' },
    { word: 'impermanency', word_class: 'N' },
    { word: 'impermanent', word_class: 'AJ' }
  ],
  bracing: [
    { word: 'brace', word_class: 'N' },
    { word: 'brace', word_class: 'V' },
    { word: 'bracer', word_class: 'N' },
    { word: 'braces', word_class: 'N' },
    { word: 'braced', word_class: 'AJ' },
    { word: 'bracing', word_class: 'N' },
    { word: 'bracing', word_class: 'AJ' }
  ],
  barbarize: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  positiveness: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  snoopiness: [
    { word: 'snoop', word_class: 'N' },
    { word: 'snoop', word_class: 'V' },
    { word: 'snooper', word_class: 'N' },
    { word: 'snoopy', word_class: 'AJ' },
    { word: 'snoopiness', word_class: 'N' }
  ],
  efficacy: [
    { word: 'efficacy', word_class: 'N' },
    { word: 'efficacious', word_class: 'AJ' },
    { word: 'efficaciously', word_class: 'AV' },
    { word: 'efficaciousness', word_class: 'N' }
  ],
  pulsate: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  acclimatization: [
    { word: 'acclimatise', word_class: 'V' },
    { word: 'acclimatize', word_class: 'V' },
    { word: 'acclimatisation', word_class: 'N' },
    { word: 'acclimatization', word_class: 'N' }
  ],
  staunch: [
    { word: 'staunch', word_class: 'V' },
    { word: 'staunch', word_class: 'AJ' },
    { word: 'staunchness', word_class: 'N' }
  ],
  chilblained: [
    { word: 'chilblain', word_class: 'N' },
    { word: 'chilblains', word_class: 'N' },
    { word: 'chilblained', word_class: 'AJ' }
  ],
  shouldered: [
    { word: 'shoulder', word_class: 'N' },
    { word: 'shoulder', word_class: 'V' },
    { word: 'shouldered', word_class: 'AJ' }
  ],
  enrapturement: [
    { word: 'enrapture', word_class: 'V' },
    { word: 'enraptured', word_class: 'AJ' },
    { word: 'enrapturement', word_class: 'N' }
  ],
  disdainful: [
    { word: 'disdain', word_class: 'N' },
    { word: 'disdain', word_class: 'V' },
    { word: 'disdainful', word_class: 'AJ' },
    { word: 'disdainment', word_class: 'N' },
    { word: 'disdainfulness', word_class: 'N' }
  ],
  hog: [{ word: 'hog', word_class: 'N' }, { word: 'hog', word_class: 'V' }, { word: 'hogged', word_class: 'AJ' }],
  thermostatics: [
    { word: 'thermostat', word_class: 'N' },
    { word: 'thermostat', word_class: 'V' },
    { word: 'thermostatic', word_class: 'AJ' },
    { word: 'thermostatics', word_class: 'N' },
    { word: 'thermostatically', word_class: 'AV' }
  ],
  actualize: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  encouragement: [
    { word: 'encourage', word_class: 'V' },
    { word: 'encouraged', word_class: 'AJ' },
    { word: 'encouraging', word_class: 'AJ' },
    { word: 'encouragement', word_class: 'N' }
  ],
  stooped: [
    { word: 'stoop', word_class: 'N' },
    { word: 'stoop', word_class: 'V' },
    { word: 'stooped', word_class: 'AJ' },
    { word: 'stooping', word_class: 'AJ' }
  ],
  christian: [
    { word: 'christian', word_class: 'N' },
    { word: 'christian', word_class: 'AJ' },
    { word: 'christianise', word_class: 'V' },
    { word: 'christianity', word_class: 'N' },
    { word: 'christianize', word_class: 'V' },
    { word: 'christianisation', word_class: 'N' },
    { word: 'christianization', word_class: 'N' }
  ],
  vapidity: [
    { word: 'vapid', word_class: 'AJ' },
    { word: 'vapidity', word_class: 'N' },
    { word: 'vapidness', word_class: 'N' }
  ],
  comforting: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  lowness: [
    { word: 'low', word_class: 'N' },
    { word: 'low', word_class: 'V' },
    { word: 'low', word_class: 'AJ' },
    { word: 'low', word_class: 'AV' },
    { word: 'lowly', word_class: 'AJ' },
    { word: 'lowly', word_class: 'AV' },
    { word: 'lowness', word_class: 'N' }
  ],
  hesitance: [
    { word: 'hesitate', word_class: 'V' },
    { word: 'hesitance', word_class: 'N' },
    { word: 'hesitant', word_class: 'AJ' },
    { word: 'hesitancy', word_class: 'N' },
    { word: 'hesitater', word_class: 'N' },
    { word: 'hesitator', word_class: 'N' },
    { word: 'hesitation', word_class: 'N' },
    { word: 'hesitating', word_class: 'AJ' }
  ],
  immediateness: [
    { word: 'immediacy', word_class: 'N' },
    { word: 'immediate', word_class: 'V' },
    { word: 'immediate', word_class: 'AJ' },
    { word: 'immediately', word_class: 'AV' },
    { word: 'immediateness', word_class: 'N' }
  ],
  abdicate: [
    { word: 'abdicate', word_class: 'V' },
    { word: 'abdicator', word_class: 'N' },
    { word: 'abdicable', word_class: 'AJ' },
    { word: 'abdication', word_class: 'N' }
  ],
  restrict: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  stack: [
    { word: 'stack', word_class: 'N' },
    { word: 'stack', word_class: 'V' },
    { word: 'stacks', word_class: 'N' },
    { word: 'stacked', word_class: 'AJ' }
  ],
  exorcise: [
    { word: 'exorcise', word_class: 'V' },
    { word: 'exorcism', word_class: 'N' },
    { word: 'exorcist', word_class: 'N' },
    { word: 'exorcize', word_class: 'V' },
    { word: 'exorciser', word_class: 'N' }
  ],
  malevolence: [
    { word: 'malevolence', word_class: 'N' },
    { word: 'malevolency', word_class: 'N' },
    { word: 'malevolent', word_class: 'AJ' },
    { word: 'malevolently', word_class: 'AV' }
  ],
  trousered: [
    { word: 'trouser', word_class: 'N' },
    { word: 'trousers', word_class: 'N' },
    { word: 'trousered', word_class: 'AJ' },
    { word: 'trousering', word_class: 'N' }
  ],
  dozing: [
    { word: 'doze', word_class: 'N' },
    { word: 'doze', word_class: 'V' },
    { word: 'dozer', word_class: 'N' },
    { word: 'dozing', word_class: 'AJ' }
  ],
  attention: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  weeds: [
    { word: 'weed', word_class: 'N' },
    { word: 'weed', word_class: 'V' },
    { word: 'weeds', word_class: 'N' },
    { word: 'weeded', word_class: 'AJ' }
  ],
  pulverise: [
    { word: 'pulverise', word_class: 'V' },
    { word: 'pulverize', word_class: 'V' },
    { word: 'pulverized', word_class: 'AJ' },
    { word: 'pulverisation', word_class: 'N' },
    { word: 'pulverization', word_class: 'N' }
  ],
  impending: [
    { word: 'impend', word_class: 'V' },
    { word: 'impendence', word_class: 'N' },
    { word: 'impendency', word_class: 'N' },
    { word: 'impending', word_class: 'AJ' }
  ],
  manufacturer: [
    { word: 'manufacture', word_class: 'N' },
    { word: 'manufacture', word_class: 'V' },
    { word: 'manufacturer', word_class: 'N' },
    { word: 'manufactured', word_class: 'AJ' },
    { word: 'manufacturing', word_class: 'N' }
  ],
  arthrosporic: [
    { word: 'arthrospore', word_class: 'N' },
    { word: 'arthrosporic', word_class: 'AJ' },
    { word: 'arthrosporous', word_class: 'AJ' }
  ],
  embowment: [
    { word: 'embow', word_class: 'V' },
    { word: 'embower', word_class: 'V' },
    { word: 'embowment', word_class: 'N' },
    { word: 'embowered', word_class: 'AJ' }
  ],
  commercialisation: [
    { word: 'commercial', word_class: 'N' },
    { word: 'commercial', word_class: 'AJ' },
    { word: 'commercialise', word_class: 'V' },
    { word: 'commercialism', word_class: 'N' },
    { word: 'commercialize', word_class: 'V' },
    { word: 'commercially', word_class: 'AV' },
    { word: 'commercialized', word_class: 'AJ' },
    { word: 'commercialisation', word_class: 'N' },
    { word: 'commercialization', word_class: 'N' }
  ],
  iniquity: [
    { word: 'iniquity', word_class: 'N' },
    { word: 'iniquitous', word_class: 'AJ' },
    { word: 'iniquitously', word_class: 'AV' }
  ],
  paving: [
    { word: 'pave', word_class: 'V' },
    { word: 'paved', word_class: 'AJ' },
    { word: 'paving', word_class: 'N' },
    { word: 'pavement', word_class: 'N' }
  ],
  decimalise: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  whistle: [
    { word: 'whistle', word_class: 'N' },
    { word: 'whistle', word_class: 'V' },
    { word: 'whistler', word_class: 'N' },
    { word: 'whistling', word_class: 'N' }
  ],
  li: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  commutator: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  polyphonically: [
    { word: 'polyphone', word_class: 'N' },
    { word: 'polyphonic', word_class: 'AJ' },
    { word: 'polyphonous', word_class: 'AJ' },
    { word: 'polyphonically', word_class: 'AV' }
  ],
  cuckoldry: [
    { word: 'cuckold', word_class: 'N' },
    { word: 'cuckold', word_class: 'V' },
    { word: 'cuckoldry', word_class: 'N' }
  ],
  contestant: [
    { word: 'contest', word_class: 'N' },
    { word: 'contest', word_class: 'V' },
    { word: 'contestant', word_class: 'N' },
    { word: 'contestable', word_class: 'AJ' },
    { word: 'contestation', word_class: 'N' }
  ],
  exportation: [
    { word: 'export', word_class: 'N' },
    { word: 'export', word_class: 'V' },
    { word: 'exporter', word_class: 'N' },
    { word: 'exporting', word_class: 'N' },
    { word: 'exportable', word_class: 'AJ' },
    { word: 'exportation', word_class: 'N' }
  ],
  mountain: [
    { word: 'mountain', word_class: 'N' },
    { word: 'mountain', word_class: 'AJ' },
    { word: 'mountaineer', word_class: 'N' },
    { word: 'mountaineer', word_class: 'V' },
    { word: 'mountainous', word_class: 'AJ' },
    { word: 'mountaineering', word_class: 'N' }
  ],
  vocal: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  cytological: [
    { word: 'cytology', word_class: 'N' },
    { word: 'cytologic', word_class: 'AJ' },
    { word: 'cytological', word_class: 'AJ' }
  ],
  poisonous: [
    { word: 'poison', word_class: 'N' },
    { word: 'poison', word_class: 'V' },
    { word: 'poisoner', word_class: 'N' },
    { word: 'poisoning', word_class: 'N' },
    { word: 'poisonous', word_class: 'AJ' },
    { word: 'poisonously', word_class: 'AV' }
  ],
  dupery: [{ word: 'dupe', word_class: 'N' }, { word: 'dupe', word_class: 'V' }, { word: 'dupery', word_class: 'N' }],
  scooter: [
    { word: 'scoot', word_class: 'N' },
    { word: 'scoot', word_class: 'V' },
    { word: 'scooter', word_class: 'N' }
  ],
  swerve: [
    { word: 'swerve', word_class: 'N' },
    { word: 'swerve', word_class: 'V' },
    { word: 'swerving', word_class: 'N' }
  ],
  dietetics: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  alphanumerical: [
    { word: 'alphanumeric', word_class: 'AJ' },
    { word: 'alphanumerics', word_class: 'N' },
    { word: 'alphanumerical', word_class: 'AJ' }
  ],
  rimmed: [{ word: 'rim', word_class: 'N' }, { word: 'rim', word_class: 'V' }, { word: 'rimmed', word_class: 'AJ' }],
  resonant: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  assure: [
    { word: 'assur', word_class: 'N' },
    { word: 'assure', word_class: 'V' },
    { word: 'assured', word_class: 'AJ' },
    { word: 'assurance', word_class: 'N' },
    { word: 'assuring', word_class: 'AJ' }
  ],
  bordering: [
    { word: 'border', word_class: 'N' },
    { word: 'border', word_class: 'V' },
    { word: 'borderer', word_class: 'N' },
    { word: 'bordered', word_class: 'AJ' },
    { word: 'bordering', word_class: 'AJ' }
  ],
  injurious: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  redeemed: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  didactically: [
    { word: 'didactic', word_class: 'AJ' },
    { word: 'didactical', word_class: 'AJ' },
    { word: 'didactically', word_class: 'AV' }
  ],
  normal: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  patently: [
    { word: 'patent', word_class: 'N' },
    { word: 'patent', word_class: 'V' },
    { word: 'patent', word_class: 'AJ' },
    { word: 'patentee', word_class: 'N' },
    { word: 'patented', word_class: 'AJ' },
    { word: 'patently', word_class: 'AV' }
  ],
  fecund: [
    { word: 'fecund', word_class: 'AJ' },
    { word: 'fecundate', word_class: 'V' },
    { word: 'fecundity', word_class: 'N' },
    { word: 'fecundation', word_class: 'N' }
  ],
  detention: [
    { word: 'detain', word_class: 'V' },
    { word: 'detent', word_class: 'N' },
    { word: 'detente', word_class: 'N' },
    { word: 'detainee', word_class: 'N' },
    { word: 'detention', word_class: 'N' }
  ],
  loquacious: [
    { word: 'loquacious', word_class: 'AJ' },
    { word: 'loquaciously', word_class: 'AV' },
    { word: 'loquaciousness', word_class: 'N' }
  ],
  resemblant: [
    { word: 'resemble', word_class: 'V' },
    { word: 'resemblant', word_class: 'N' },
    { word: 'resemblance', word_class: 'N' },
    { word: 'resemblant', word_class: 'AJ' }
  ],
  sneakiness: [
    { word: 'sneak', word_class: 'N' },
    { word: 'sneak', word_class: 'V' },
    { word: 'sneak', word_class: 'AJ' },
    { word: 'sneaker', word_class: 'N' },
    { word: 'sneaky', word_class: 'AJ' },
    { word: 'sneaking', word_class: 'AJ' },
    { word: 'sneakiness', word_class: 'N' }
  ],
  honours: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  conk: [{ word: 'conk', word_class: 'N' }, { word: 'conk', word_class: 'V' }, { word: 'conker', word_class: 'N' }],
  wear: [
    { word: 'wear', word_class: 'N' },
    { word: 'wear', word_class: 'V' },
    { word: 'worn', word_class: 'AJ' },
    { word: 'wearer', word_class: 'N' },
    { word: 'wearing', word_class: 'N' },
    { word: 'wearing', word_class: 'AJ' }
  ],
  tippy: [
    { word: 'tip', word_class: 'N' },
    { word: 'tip', word_class: 'V' },
    { word: 'tippy', word_class: 'AJ' },
    { word: 'tipped', word_class: 'AJ' }
  ],
  disapproved: [
    { word: 'disapprove', word_class: 'V' },
    { word: 'disapproval', word_class: 'N' },
    { word: 'disapproved', word_class: 'AJ' },
    { word: 'disapproving', word_class: 'AJ' }
  ],
  recusation: [
    { word: 'recuse', word_class: 'V' },
    { word: 'recusal', word_class: 'N' },
    { word: 'recusant', word_class: 'N' },
    { word: 'recusancy', word_class: 'N' },
    { word: 'recusant', word_class: 'AJ' },
    { word: 'recusation', word_class: 'N' }
  ],
  helminth: [
    { word: 'helminth', word_class: 'N' },
    { word: 'helminthic', word_class: 'N' },
    { word: 'helminthic', word_class: 'AJ' }
  ],
  umpirage: [
    { word: 'umpire', word_class: 'N' },
    { word: 'umpire', word_class: 'V' },
    { word: 'umpirage', word_class: 'N' },
    { word: 'umpiring', word_class: 'N' },
    { word: 'umpireship', word_class: 'N' }
  ],
  provably: [
    { word: 'provable', word_class: 'AJ' },
    { word: 'provably', word_class: 'AV' },
    { word: 'provability', word_class: 'N' }
  ],
  sadness: [{ word: 'sad', word_class: 'V' }, { word: 'sad', word_class: 'AJ' }, { word: 'sadness', word_class: 'N' }],
  incest: [
    { word: 'incest', word_class: 'N' },
    { word: 'incestuous', word_class: 'AJ' },
    { word: 'incestuously', word_class: 'AV' }
  ],
  denominator: [
    { word: 'denominate', word_class: 'V' },
    { word: 'denominator', word_class: 'N' },
    { word: 'denomination', word_class: 'N' },
    { word: 'denominational', word_class: 'AJ' }
  ],
  unimpressive: [
    { word: 'unimpressed', word_class: 'AJ' },
    { word: 'unimpressive', word_class: 'AJ' },
    { word: 'unimpressively', word_class: 'AV' }
  ],
  band: [
    { word: 'band', word_class: 'N' },
    { word: 'band', word_class: 'V' },
    { word: 'banded', word_class: 'AJ' },
    { word: 'banding', word_class: 'N' }
  ],
  appurtenant: [
    { word: 'appurtenance', word_class: 'N' },
    { word: 'appurtenant', word_class: 'AJ' },
    { word: 'appurtenances', word_class: 'N' }
  ],
  incarnate: [
    { word: 'incarnate', word_class: 'V' },
    { word: 'incarnate', word_class: 'AJ' },
    { word: 'incarnation', word_class: 'N' }
  ],
  steadiness: [
    { word: 'steady', word_class: 'N' },
    { word: 'steady', word_class: 'V' },
    { word: 'steady', word_class: 'AJ' },
    { word: 'steady', word_class: 'AV' },
    { word: 'steadied', word_class: 'AJ' },
    { word: 'steadiness', word_class: 'N' },
    { word: 'steadying', word_class: 'AJ' }
  ],
  contemplative: [
    { word: 'contemplate', word_class: 'V' },
    { word: 'contemplation', word_class: 'N' },
    { word: 'contemplative', word_class: 'AJ' },
    { word: 'contemplativeness', word_class: 'N' }
  ],
  mistake: [
    { word: 'mistake', word_class: 'N' },
    { word: 'mistake', word_class: 'V' },
    { word: 'mistaking', word_class: 'N' }
  ],
  unperceived: [
    { word: 'unperceived', word_class: 'AJ' },
    { word: 'unperceiving', word_class: 'AJ' },
    { word: 'unperceivable', word_class: 'AJ' }
  ],
  maltreatment: [
    { word: 'maltreat', word_class: 'V' },
    { word: 'maltreated', word_class: 'AJ' },
    { word: 'maltreatment', word_class: 'N' }
  ],
  contusioned: [
    { word: 'contuse', word_class: 'V' },
    { word: 'contused', word_class: 'AJ' },
    { word: 'contusion', word_class: 'N' },
    { word: 'contusioned', word_class: 'AJ' }
  ],
  burglarise: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  crinkled: [
    { word: 'crinkle', word_class: 'N' },
    { word: 'crinkle', word_class: 'V' },
    { word: 'crinkled', word_class: 'AJ' }
  ],
  fencer: [
    { word: 'fence', word_class: 'N' },
    { word: 'fence', word_class: 'V' },
    { word: 'fencer', word_class: 'N' },
    { word: 'fencing', word_class: 'N' }
  ],
  bounty: [
    { word: 'bounty', word_class: 'N' },
    { word: 'bountied', word_class: 'AJ' },
    { word: 'bounteous', word_class: 'AJ' },
    { word: 'bountiful', word_class: 'AJ' },
    { word: 'bountifulness', word_class: 'N' }
  ],
  'double-spaced': [
    { word: 'double-space', word_class: 'V' },
    { word: 'double-spaced', word_class: 'AJ' },
    { word: 'double-spacing', word_class: 'N' }
  ],
  supinely: [
    { word: 'supine', word_class: 'AJ' },
    { word: 'supinate', word_class: 'V' },
    { word: 'supinely', word_class: 'AV' },
    { word: 'supination', word_class: 'N' }
  ],
  chronologically: [
    { word: 'chronology', word_class: 'N' },
    { word: 'chronologize', word_class: 'V' },
    { word: 'chronological', word_class: 'AJ' },
    { word: 'chronologically', word_class: 'AV' }
  ],
  cosmology: [
    { word: 'cosmology', word_class: 'N' },
    { word: 'cosmologic', word_class: 'AJ' },
    { word: 'cosmological', word_class: 'AJ' }
  ],
  gluttonously: [
    { word: 'glutton', word_class: 'N' },
    { word: 'gluttonize', word_class: 'V' },
    { word: 'gluttonous', word_class: 'AJ' },
    { word: 'gluttonously', word_class: 'AV' }
  ],
  leavened: [
    { word: 'leaven', word_class: 'N' },
    { word: 'leaven', word_class: 'V' },
    { word: 'leavened', word_class: 'AJ' },
    { word: 'leavening', word_class: 'N' }
  ],
  leaseholder: [
    { word: 'leasehold', word_class: 'N' },
    { word: 'leaseholder', word_class: 'N' },
    { word: 'leaseholding', word_class: 'N' }
  ],
  curvaceously: [
    { word: 'curvaceous', word_class: 'AJ' },
    { word: 'curvaceously', word_class: 'AV' },
    { word: 'curvaceousness', word_class: 'N' }
  ],
  vigilant: [
    { word: 'vigil', word_class: 'N' },
    { word: 'vigilance', word_class: 'N' },
    { word: 'vigilant', word_class: 'AJ' }
  ],
  reclaimable: [
    { word: 'reclaim', word_class: 'V' },
    { word: 'reclaimed', word_class: 'AJ' },
    { word: 'reclamation', word_class: 'N' },
    { word: 'reclaimable', word_class: 'AJ' }
  ],
  hazy: [
    { word: 'haze', word_class: 'N' },
    { word: 'haze', word_class: 'V' },
    { word: 'hazy', word_class: 'AJ' },
    { word: 'haziness', word_class: 'N' }
  ],
  rescuer: [
    { word: 'rescue', word_class: 'N' },
    { word: 'rescue', word_class: 'V' },
    { word: 'rescuer', word_class: 'N' },
    { word: 'rescued', word_class: 'AJ' }
  ],
  defray: [
    { word: 'defray', word_class: 'V' },
    { word: 'defrayal', word_class: 'N' },
    { word: 'defrayment', word_class: 'N' }
  ],
  presumptively: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  dichotomously: [
    { word: 'dichotomize', word_class: 'V' },
    { word: 'dichotomous', word_class: 'AJ' },
    { word: 'dichotomously', word_class: 'AV' },
    { word: 'dichotomization', word_class: 'N' }
  ],
  maturing: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  idealistic: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  originalism: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  chested: [
    { word: 'chest', word_class: 'N' },
    { word: 'chested', word_class: 'AJ' },
    { word: 'chestful', word_class: 'N' },
    { word: 'chestful', word_class: 'AJ' }
  ],
  hurry: [
    { word: 'hurry', word_class: 'N' },
    { word: 'hurry', word_class: 'V' },
    { word: 'hurried', word_class: 'AJ' },
    { word: 'hurrying', word_class: 'N' },
    { word: 'hurrying', word_class: 'AJ' }
  ],
  marriageable: [
    { word: 'marri', word_class: 'N' },
    { word: 'marry', word_class: 'V' },
    { word: 'marriage', word_class: 'N' },
    { word: 'married', word_class: 'AJ' },
    { word: 'marriageable', word_class: 'AJ' },
    { word: 'marriageability', word_class: 'N' }
  ],
  curettement: [
    { word: 'curet', word_class: 'N' },
    { word: 'curet', word_class: 'V' },
    { word: 'curette', word_class: 'N' },
    { word: 'curettement', word_class: 'N' }
  ],
  hued: [{ word: 'hue', word_class: 'N' }, { word: 'hue', word_class: 'V' }, { word: 'hued', word_class: 'AJ' }],
  suspiciousness: [
    { word: 'suspicious', word_class: 'AJ' },
    { word: 'suspiciously', word_class: 'AV' },
    { word: 'suspiciousness', word_class: 'N' }
  ],
  gobbler: [
    { word: 'gobble', word_class: 'N' },
    { word: 'gobble', word_class: 'V' },
    { word: 'gobbler', word_class: 'N' }
  ],
  seeping: [
    { word: 'seep', word_class: 'V' },
    { word: 'seepage', word_class: 'N' },
    { word: 'seeping', word_class: 'AJ' }
  ],
  sherardize: [
    { word: 'sherardise', word_class: 'V' },
    { word: 'sherardize', word_class: 'V' },
    { word: 'sherardisation', word_class: 'N' },
    { word: 'sherardization', word_class: 'N' }
  ],
  radical: [
    { word: 'radical', word_class: 'N' },
    { word: 'radical', word_class: 'AJ' },
    { word: 'radicalism', word_class: 'N' },
    { word: 'radically', word_class: 'AV' }
  ],
  doctrine: [
    { word: 'doctrine', word_class: 'N' },
    { word: 'doctrinal', word_class: 'AJ' },
    { word: 'doctrinally', word_class: 'AV' }
  ],
  randomisation: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  skeptic: [
    { word: 'skeptic', word_class: 'N' },
    { word: 'skeptical', word_class: 'AJ' },
    { word: 'skepticism', word_class: 'N' },
    { word: 'skeptically', word_class: 'AV' }
  ],
  devoted: [
    { word: 'devote', word_class: 'V' },
    { word: 'devoted', word_class: 'AJ' },
    { word: 'devotion', word_class: 'N' },
    { word: 'devotement', word_class: 'N' },
    { word: 'devotional', word_class: 'AJ' }
  ],
  demonstration: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  denunciation: [
    { word: 'denounce', word_class: 'V' },
    { word: 'denunciate', word_class: 'V' },
    { word: 'denouncement', word_class: 'N' },
    { word: 'denunciation', word_class: 'N' },
    { word: 'denunciative', word_class: 'AJ' }
  ],
  barreled: [
    { word: 'barrel', word_class: 'N' },
    { word: 'barrel', word_class: 'V' },
    { word: 'barrels', word_class: 'N' },
    { word: 'barreled', word_class: 'AJ' },
    { word: 'barrelful', word_class: 'N' },
    { word: 'barrelled', word_class: 'AJ' }
  ],
  prim: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  granger: [
    { word: 'granger', word_class: 'N' },
    { word: 'grangerism', word_class: 'N' },
    { word: 'grangerize', word_class: 'V' },
    { word: 'grangerization', word_class: 'N' }
  ],
  dismay: [
    { word: 'dismay', word_class: 'N' },
    { word: 'dismay', word_class: 'V' },
    { word: 'dismayed', word_class: 'AJ' },
    { word: 'dismaying', word_class: 'AJ' }
  ],
  famished: [
    { word: 'famish', word_class: 'V' },
    { word: 'famish', word_class: 'AJ' },
    { word: 'famished', word_class: 'AJ' },
    { word: 'famishment', word_class: 'N' }
  ],
  transformable: [
    { word: 'transform', word_class: 'N' },
    { word: 'transform', word_class: 'V' },
    { word: 'transformer', word_class: 'N' },
    { word: 'transformed', word_class: 'AJ' },
    { word: 'transformable', word_class: 'AJ' },
    { word: 'transformation', word_class: 'N' }
  ],
  interconnection: [
    { word: 'interconnect', word_class: 'V' },
    { word: 'interconnected', word_class: 'AJ' },
    { word: 'interconnection', word_class: 'N' }
  ],
  borne: [
    { word: 'bear', word_class: 'N' },
    { word: 'bear', word_class: 'V' },
    { word: 'born', word_class: 'AJ' },
    { word: 'bearer', word_class: 'N' },
    { word: 'borne', word_class: 'AJ' },
    { word: 'bearing', word_class: 'N' },
    { word: 'bearing', word_class: 'AJ' }
  ],
  stale: [
    { word: 'stale', word_class: 'V' },
    { word: 'stale', word_class: 'AJ' },
    { word: 'staleness', word_class: 'N' }
  ],
  sag: [{ word: 'sag', word_class: 'N' }, { word: 'sag', word_class: 'V' }, { word: 'sagging', word_class: 'AJ' }],
  fluoride: [
    { word: 'fluoride', word_class: 'N' },
    { word: 'fluoridate', word_class: 'V' },
    { word: 'fluoridize', word_class: 'V' },
    { word: 'fluoridation', word_class: 'N' },
    { word: 'fluoridization', word_class: 'N' }
  ],
  slain: [
    { word: 'slay', word_class: 'V' },
    { word: 'slain', word_class: 'N' },
    { word: 'slain', word_class: 'AJ' },
    { word: 'slayer', word_class: 'N' },
    { word: 'slaying', word_class: 'N' }
  ],
  bookseller: [
    { word: 'booksell', word_class: 'V' },
    { word: 'bookseller', word_class: 'N' },
    { word: 'bookselling', word_class: 'N' }
  ],
  caravan: [
    { word: 'caravan', word_class: 'N' },
    { word: 'caravan', word_class: 'V' },
    { word: 'caravanning', word_class: 'N' }
  ],
  corn: [{ word: 'corn', word_class: 'N' }, { word: 'corn', word_class: 'V' }, { word: 'corned', word_class: 'AJ' }],
  adorably: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  tinted: [
    { word: 'tint', word_class: 'N' },
    { word: 'tint', word_class: 'V' },
    { word: 'tinted', word_class: 'AJ' },
    { word: 'tinting', word_class: 'N' }
  ],
  incredulously: [
    { word: 'incredulity', word_class: 'N' },
    { word: 'incredulous', word_class: 'AJ' },
    { word: 'incredulously', word_class: 'AV' }
  ],
  serrated: [
    { word: 'serrate', word_class: 'V' },
    { word: 'serrate', word_class: 'AJ' },
    { word: 'serrated', word_class: 'AJ' },
    { word: 'serration', word_class: 'N' }
  ],
  lodger: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  genuine: [
    { word: 'genuine', word_class: 'AJ' },
    { word: 'genuinely', word_class: 'AV' },
    { word: 'genuineness', word_class: 'N' }
  ],
  digitalise: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  wonderfulness: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  thermionics: [
    { word: 'thermion', word_class: 'N' },
    { word: 'thermionic', word_class: 'AJ' },
    { word: 'thermionics', word_class: 'N' }
  ],
  contemplate: [
    { word: 'contemplate', word_class: 'V' },
    { word: 'contemplation', word_class: 'N' },
    { word: 'contemplative', word_class: 'AJ' },
    { word: 'contemplativeness', word_class: 'N' }
  ],
  disfranchised: [
    { word: 'disfranchise', word_class: 'V' },
    { word: 'disfranchised', word_class: 'AJ' },
    { word: 'disfranchisement', word_class: 'N' }
  ],
  fictionalization: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  immutability: [
    { word: 'immutable', word_class: 'AJ' },
    { word: 'immutably', word_class: 'AV' },
    { word: 'immutability', word_class: 'N' },
    { word: 'immutableness', word_class: 'N' }
  ],
  ram: [
    { word: 'ram', word_class: 'N' },
    { word: 'ram', word_class: 'V' },
    { word: 'ramee', word_class: 'N' },
    { word: 'ramman', word_class: 'N' }
  ],
  boating: [
    { word: 'boat', word_class: 'N' },
    { word: 'boat', word_class: 'V' },
    { word: 'boater', word_class: 'N' },
    { word: 'boating', word_class: 'N' },
    { word: 'boatman', word_class: 'N' }
  ],
  turbidity: [
    { word: 'turbid', word_class: 'AJ' },
    { word: 'turbidity', word_class: 'N' },
    { word: 'turbidness', word_class: 'N' }
  ],
  shelf: [
    { word: 'shelf', word_class: 'N' },
    { word: 'shelve', word_class: 'V' },
    { word: 'shelfful', word_class: 'N' },
    { word: 'shelving', word_class: 'N' },
    { word: 'shelfful', word_class: 'AJ' }
  ],
  injury: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  communicate: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  store: [
    { word: 'store', word_class: 'N' },
    { word: 'store', word_class: 'V' },
    { word: 'store', word_class: 'AJ' },
    { word: 'stores', word_class: 'N' },
    { word: 'storage', word_class: 'N' },
    { word: 'storing', word_class: 'N' }
  ],
  publicizer: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  coherence: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  crunched: [
    { word: 'crunch', word_class: 'N' },
    { word: 'crunch', word_class: 'V' },
    { word: 'crunched', word_class: 'AJ' }
  ],
  coagulable: [
    { word: 'coagulate', word_class: 'V' },
    { word: 'coagulate', word_class: 'AJ' },
    { word: 'coagulable', word_class: 'AJ' },
    { word: 'coagulated', word_class: 'AJ' },
    { word: 'coagulation', word_class: 'N' }
  ],
  nebulization: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  detached: [
    { word: 'detach', word_class: 'V' },
    { word: 'detached', word_class: 'AJ' },
    { word: 'detachment', word_class: 'N' },
    { word: 'detachable', word_class: 'AJ' }
  ],
  divinely: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  atomic: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  set: [
    { word: 'set', word_class: 'N' },
    { word: 'set', word_class: 'V' },
    { word: 'set', word_class: 'AJ' },
    { word: 'setting', word_class: 'N' },
    { word: 'setting', word_class: 'AJ' }
  ],
  jouncing: [
    { word: 'jounce', word_class: 'N' },
    { word: 'jounce', word_class: 'V' },
    { word: 'jouncing', word_class: 'AJ' }
  ],
  sip: [{ word: 'sip', word_class: 'N' }, { word: 'sip', word_class: 'V' }, { word: 'sipping', word_class: 'N' }],
  heterosexualism: [
    { word: 'heterosexual', word_class: 'N' },
    { word: 'heterosexual', word_class: 'AJ' },
    { word: 'heterosexualism', word_class: 'N' },
    { word: 'heterosexuality', word_class: 'N' }
  ],
  armourer: [
    { word: 'armour', word_class: 'N' },
    { word: 'armour', word_class: 'V' },
    { word: 'armourer', word_class: 'N' },
    { word: 'armoured', word_class: 'AJ' }
  ],
  orchestral: [
    { word: 'orchestra', word_class: 'N' },
    { word: 'orchestral', word_class: 'AJ' },
    { word: 'orchestrate', word_class: 'V' },
    { word: 'orchestrator', word_class: 'N' },
    { word: 'orchestrated', word_class: 'AJ' },
    { word: 'orchestration', word_class: 'N' }
  ],
  segregant: [
    { word: 'segregant', word_class: 'N' },
    { word: 'segregate', word_class: 'V' },
    { word: 'segregated', word_class: 'AJ' },
    { word: 'segregation', word_class: 'N' }
  ],
  unusual: [
    { word: 'unusual', word_class: 'AJ' },
    { word: 'unusually', word_class: 'AV' },
    { word: 'unusualness', word_class: 'N' }
  ],
  enthusiastic: [
    { word: 'enthusiast', word_class: 'N' },
    { word: 'enthusiastic', word_class: 'AJ' },
    { word: 'enthusiastically', word_class: 'AV' }
  ],
  parachuter: [
    { word: 'parachute', word_class: 'N' },
    { word: 'parachute', word_class: 'V' },
    { word: 'parachuter', word_class: 'N' },
    { word: 'parachuting', word_class: 'N' },
    { word: 'parachutist', word_class: 'N' }
  ],
  despicability: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  imperiously: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  dependably: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  glister: [
    { word: 'glister', word_class: 'N' },
    { word: 'glister', word_class: 'V' },
    { word: 'glistering', word_class: 'AJ' }
  ],
  debase: [
    { word: 'debase', word_class: 'V' },
    { word: 'debased', word_class: 'AJ' },
    { word: 'debasing', word_class: 'AJ' },
    { word: 'debasement', word_class: 'N' }
  ],
  concept: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  modeller: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  poisoner: [
    { word: 'poison', word_class: 'N' },
    { word: 'poison', word_class: 'V' },
    { word: 'poisoner', word_class: 'N' },
    { word: 'poisoning', word_class: 'N' },
    { word: 'poisonous', word_class: 'AJ' },
    { word: 'poisonously', word_class: 'AV' }
  ],
  gentility: [
    { word: 'gentile', word_class: 'N' },
    { word: 'gentile', word_class: 'AJ' },
    { word: 'gentility', word_class: 'N' }
  ],
  normalisation: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  anaglyphic: [
    { word: 'anaglyph', word_class: 'N' },
    { word: 'anaglyphic', word_class: 'AJ' },
    { word: 'anaglyphical', word_class: 'AJ' }
  ],
  externalization: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  playing: [
    { word: 'play', word_class: 'N' },
    { word: 'play', word_class: 'V' },
    { word: 'play', word_class: 'AJ' },
    { word: 'player', word_class: 'N' },
    { word: 'played', word_class: 'AJ' },
    { word: 'playing', word_class: 'N' },
    { word: 'playing', word_class: 'AJ' }
  ],
  assertable: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  liver: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  ineffectiveness: [
    { word: 'ineffective', word_class: 'AJ' },
    { word: 'ineffectively', word_class: 'AV' },
    { word: 'ineffectiveness', word_class: 'N' }
  ],
  decimalize: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  usurped: [
    { word: 'usurp', word_class: 'V' },
    { word: 'usurper', word_class: 'N' },
    { word: 'usurped', word_class: 'AJ' },
    { word: 'usurpation', word_class: 'N' }
  ],
  eventually: [
    { word: 'eventual', word_class: 'AJ' },
    { word: 'eventuate', word_class: 'V' },
    { word: 'eventuality', word_class: 'N' },
    { word: 'eventually', word_class: 'AV' },
    { word: 'eventuation', word_class: 'N' }
  ],
  communion: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  graphics: [
    { word: 'graphic', word_class: 'N' },
    { word: 'graphic', word_class: 'AJ' },
    { word: 'graphics', word_class: 'N' },
    { word: 'graphical', word_class: 'AJ' },
    { word: 'graphically', word_class: 'AV' }
  ],
  sparseness: [
    { word: 'sparse', word_class: 'AJ' },
    { word: 'sparsely', word_class: 'AV' },
    { word: 'sparseness', word_class: 'N' }
  ],
  ignite: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  disarmament: [
    { word: 'disarm', word_class: 'V' },
    { word: 'disarmer', word_class: 'N' },
    { word: 'disarming', word_class: 'N' },
    { word: 'disarming', word_class: 'AJ' },
    { word: 'disarmament', word_class: 'N' }
  ],
  insensibly: [
    { word: 'insensate', word_class: 'AJ' },
    { word: 'insensible', word_class: 'AJ' },
    { word: 'insensibly', word_class: 'AV' },
    { word: 'insensibility', word_class: 'N' }
  ],
  denotive: [
    { word: 'denote', word_class: 'N' },
    { word: 'denote', word_class: 'V' },
    { word: 'denotive', word_class: 'AJ' },
    { word: 'denotation', word_class: 'N' },
    { word: 'denotement', word_class: 'N' },
    { word: 'denotative', word_class: 'AJ' }
  ],
  stuttering: [
    { word: 'stutter', word_class: 'N' },
    { word: 'stutter', word_class: 'V' },
    { word: 'stutterer', word_class: 'N' },
    { word: 'stuttering', word_class: 'N' },
    { word: 'stuttering', word_class: 'AJ' }
  ],
  chidden: [
    { word: 'chide', word_class: 'V' },
    { word: 'chiding', word_class: 'N' },
    { word: 'chidden', word_class: 'AJ' }
  ],
  deflector: [
    { word: 'deflect', word_class: 'V' },
    { word: 'deflector', word_class: 'N' },
    { word: 'deflection', word_class: 'N' },
    { word: 'deflective', word_class: 'AJ' }
  ],
  interrelation: [
    { word: 'interrelate', word_class: 'V' },
    { word: 'interrelated', word_class: 'AJ' },
    { word: 'interrelation', word_class: 'N' },
    { word: 'interrelationship', word_class: 'N' }
  ],
  narrative: [
    { word: 'narrate', word_class: 'V' },
    { word: 'narrator', word_class: 'N' },
    { word: 'narration', word_class: 'N' },
    { word: 'narrative', word_class: 'N' },
    { word: 'narrative', word_class: 'AJ' }
  ],
  catechesis: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  carousing: [
    { word: 'carouse', word_class: 'N' },
    { word: 'carouse', word_class: 'V' },
    { word: 'carousal', word_class: 'N' },
    { word: 'carouser', word_class: 'N' },
    { word: 'carousing', word_class: 'AJ' }
  ],
  tasteful: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  emasculated: [
    { word: 'emasculate', word_class: 'V' },
    { word: 'emasculate', word_class: 'AJ' },
    { word: 'emasculated', word_class: 'AJ' },
    { word: 'emasculation', word_class: 'N' }
  ],
  carouser: [
    { word: 'carouse', word_class: 'N' },
    { word: 'carouse', word_class: 'V' },
    { word: 'carousal', word_class: 'N' },
    { word: 'carouser', word_class: 'N' },
    { word: 'carousing', word_class: 'AJ' }
  ],
  hollander: [
    { word: 'holland', word_class: 'N' },
    { word: 'hollands', word_class: 'N' },
    { word: 'hollander', word_class: 'N' }
  ],
  antiphon: [
    { word: 'antiphon', word_class: 'N' },
    { word: 'antiphonal', word_class: 'N' },
    { word: 'antiphonal', word_class: 'AJ' }
  ],
  commensurateness: [
    { word: 'commensurate', word_class: 'AJ' },
    { word: 'commensurable', word_class: 'AJ' },
    { word: 'commensurateness', word_class: 'N' }
  ],
  petition: [
    { word: 'petition', word_class: 'N' },
    { word: 'petition', word_class: 'V' },
    { word: 'petitioner', word_class: 'N' }
  ],
  dotted: [{ word: 'dot', word_class: 'N' }, { word: 'dot', word_class: 'V' }, { word: 'dotted', word_class: 'AJ' }],
  dependableness: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  jet: [
    { word: 'jet', word_class: 'N' },
    { word: 'jet', word_class: 'V' },
    { word: 'jet', word_class: 'AJ' },
    { word: 'jets', word_class: 'N' },
    { word: 'jetting', word_class: 'AJ' }
  ],
  mythicization: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  negate: [
    { word: 'negate', word_class: 'V' },
    { word: 'negating', word_class: 'N' },
    { word: 'negation', word_class: 'N' }
  ],
  sunniness: [
    { word: 'sunni', word_class: 'N' },
    { word: 'sunny', word_class: 'AJ' },
    { word: 'sunniness', word_class: 'N' }
  ],
  tentatively: [
    { word: 'tent', word_class: 'N' },
    { word: 'tent', word_class: 'V' },
    { word: 'tenting', word_class: 'N' },
    { word: 'tentative', word_class: 'AJ' },
    { word: 'tentatively', word_class: 'AV' }
  ],
  teutonisation: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  expose: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  arrester: [
    { word: 'arrest', word_class: 'N' },
    { word: 'arrest', word_class: 'V' },
    { word: 'arrester', word_class: 'N' },
    { word: 'arrested', word_class: 'AJ' },
    { word: 'arresting', word_class: 'AJ' }
  ],
  clenched: [
    { word: 'clench', word_class: 'N' },
    { word: 'clench', word_class: 'V' },
    { word: 'clenched', word_class: 'AJ' }
  ],
  drifter: [
    { word: 'drift', word_class: 'N' },
    { word: 'drift', word_class: 'V' },
    { word: 'drifter', word_class: 'N' },
    { word: 'drifting', word_class: 'N' },
    { word: 'drifting', word_class: 'AJ' }
  ],
  ignominiously: [
    { word: 'ignominy', word_class: 'N' },
    { word: 'ignominious', word_class: 'AJ' },
    { word: 'ignominiously', word_class: 'AV' },
    { word: 'ignominiousness', word_class: 'N' }
  ],
  encase: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  establishment: [
    { word: 'establish', word_class: 'V' },
    { word: 'established', word_class: 'AJ' },
    { word: 'establishment', word_class: 'N' }
  ],
  disturbed: [
    { word: 'disturb', word_class: 'V' },
    { word: 'disturbed', word_class: 'AJ' },
    { word: 'disturbance', word_class: 'N' },
    { word: 'disturbing', word_class: 'AJ' }
  ],
  ably: [
    { word: 'abls', word_class: 'N' },
    { word: 'able', word_class: 'AJ' },
    { word: 'ably', word_class: 'AV' },
    { word: 'ability', word_class: 'N' },
    { word: 'ablative', word_class: 'N' },
    { word: 'ablative', word_class: 'AJ' }
  ],
  highjack: [
    { word: 'highjack', word_class: 'N' },
    { word: 'highjack', word_class: 'V' },
    { word: 'highjacker', word_class: 'N' },
    { word: 'highjacking', word_class: 'N' }
  ],
  distract: [
    { word: 'distract', word_class: 'N' },
    { word: 'distract', word_class: 'V' },
    { word: 'distracted', word_class: 'AJ' },
    { word: 'distraction', word_class: 'N' }
  ],
  predisposition: [
    { word: 'predispose', word_class: 'V' },
    { word: 'predisposal', word_class: 'N' },
    { word: 'predisposed', word_class: 'AJ' },
    { word: 'predisposition', word_class: 'N' }
  ],
  mongrel: [
    { word: 'mongrel', word_class: 'N' },
    { word: 'mongrel', word_class: 'AJ' },
    { word: 'mongrelise', word_class: 'V' },
    { word: 'mongrelize', word_class: 'V' },
    { word: 'mongrelisation', word_class: 'N' },
    { word: 'mongrelization', word_class: 'N' }
  ],
  joined: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  encircling: [
    { word: 'encircle', word_class: 'V' },
    { word: 'encircled', word_class: 'AJ' },
    { word: 'encircling', word_class: 'AJ' },
    { word: 'encirclement', word_class: 'N' }
  ],
  corker: [
    { word: 'cork', word_class: 'N' },
    { word: 'cork', word_class: 'V' },
    { word: 'corker', word_class: 'N' },
    { word: 'corked', word_class: 'AJ' },
    { word: 'corking', word_class: 'AJ' }
  ],
  caponize: [
    { word: 'capon', word_class: 'N' },
    { word: 'caponize', word_class: 'V' },
    { word: 'caponization', word_class: 'N' }
  ],
  pavement: [
    { word: 'pave', word_class: 'V' },
    { word: 'paved', word_class: 'AJ' },
    { word: 'paving', word_class: 'N' },
    { word: 'pavement', word_class: 'N' }
  ],
  cure: [{ word: 'cure', word_class: 'N' }, { word: 'cure', word_class: 'V' }, { word: 'cured', word_class: 'AJ' }],
  pitching: [
    { word: 'pitch', word_class: 'N' },
    { word: 'pitch', word_class: 'V' },
    { word: 'pitcher', word_class: 'N' },
    { word: 'pitched', word_class: 'AJ' },
    { word: 'pitching', word_class: 'N' },
    { word: 'pitcherful', word_class: 'N' }
  ],
  logicism: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  exudate: [
    { word: 'exude', word_class: 'V' },
    { word: 'exudate', word_class: 'N' },
    { word: 'exudate', word_class: 'V' },
    { word: 'exudation', word_class: 'N' }
  ],
  equivocation: [
    { word: 'equivocal', word_class: 'AJ' },
    { word: 'equivocate', word_class: 'V' },
    { word: 'equivocally', word_class: 'AV' },
    { word: 'equivocation', word_class: 'N' },
    { word: 'equivocalness', word_class: 'N' }
  ],
  pearler: [
    { word: 'pearl', word_class: 'N' },
    { word: 'pearl', word_class: 'V' },
    { word: 'pearl', word_class: 'AJ' },
    { word: 'pearler', word_class: 'N' },
    { word: 'pearlite', word_class: 'N' }
  ],
  mutineer: [
    { word: 'mutiny', word_class: 'N' },
    { word: 'mutiny', word_class: 'V' },
    { word: 'mutineer', word_class: 'N' },
    { word: 'mutinous', word_class: 'AJ' }
  ],
  inadequateness: [
    { word: 'inadequate', word_class: 'AJ' },
    { word: 'inadequately', word_class: 'AV' },
    { word: 'inadequateness', word_class: 'N' }
  ],
  resonance: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  propagandize: [
    { word: 'propaganda', word_class: 'N' },
    { word: 'propagandist', word_class: 'N' },
    { word: 'propagandize', word_class: 'V' },
    { word: 'propagandist', word_class: 'AJ' },
    { word: 'propagandistic', word_class: 'AJ' },
    { word: 'propagandization', word_class: 'N' }
  ],
  iodisation: [
    { word: 'iodise', word_class: 'V' },
    { word: 'iodize', word_class: 'V' },
    { word: 'iodized', word_class: 'AJ' },
    { word: 'iodisation', word_class: 'N' },
    { word: 'iodization', word_class: 'N' }
  ],
  bargain: [
    { word: 'bargain', word_class: 'N' },
    { word: 'bargain', word_class: 'V' },
    { word: 'bargain', word_class: 'AJ' },
    { word: 'bargainer', word_class: 'N' },
    { word: 'bargaining', word_class: 'N' }
  ],
  invariance: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  discordance: [
    { word: 'discord', word_class: 'N' },
    { word: 'discord', word_class: 'V' },
    { word: 'discordant', word_class: 'N' },
    { word: 'discordance', word_class: 'N' },
    { word: 'discordant', word_class: 'AJ' }
  ],
  spraying: [
    { word: 'spray', word_class: 'N' },
    { word: 'spray', word_class: 'V' },
    { word: 'sprayer', word_class: 'N' },
    { word: 'spraying', word_class: 'N' }
  ],
  inordinately: [
    { word: 'inordinate', word_class: 'N' },
    { word: 'inordinate', word_class: 'AJ' },
    { word: 'inordinately', word_class: 'AV' },
    { word: 'inordinateness', word_class: 'N' }
  ],
  inconsequence: [
    { word: 'inconsequence', word_class: 'N' },
    { word: 'inconsequent', word_class: 'AJ' },
    { word: 'inconsequently', word_class: 'AV' }
  ],
  aerobics: [
    { word: 'aerobe', word_class: 'N' },
    { word: 'aerobic', word_class: 'AJ' },
    { word: 'aerobics', word_class: 'N' }
  ],
  hyperbolise: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  junk: [
    { word: 'junk', word_class: 'N' },
    { word: 'junk', word_class: 'V' },
    { word: 'junker', word_class: 'N' },
    { word: 'junked', word_class: 'AJ' }
  ],
  prizefight: [
    { word: 'prizefight', word_class: 'N' },
    { word: 'prizefight', word_class: 'V' },
    { word: 'prizefighter', word_class: 'N' }
  ],
  regard: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  eternalization: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  abatement: [
    { word: 'abate', word_class: 'V' },
    { word: 'abatic', word_class: 'AJ' },
    { word: 'abating', word_class: 'AJ' },
    { word: 'abatement', word_class: 'N' }
  ],
  shaper: [
    { word: 'shape', word_class: 'N' },
    { word: 'shape', word_class: 'V' },
    { word: 'shaper', word_class: 'N' },
    { word: 'shaped', word_class: 'AJ' },
    { word: 'shaping', word_class: 'N' },
    { word: 'shapely', word_class: 'AJ' },
    { word: 'shaping', word_class: 'AJ' }
  ],
  corbel: [
    { word: 'corbel', word_class: 'N' },
    { word: 'corbel', word_class: 'V' },
    { word: 'corbelled', word_class: 'AJ' }
  ],
  locator: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  kindling: [
    { word: 'kindle', word_class: 'V' },
    { word: 'kindled', word_class: 'AJ' },
    { word: 'kindling', word_class: 'N' }
  ],
  baby: [{ word: 'baby', word_class: 'N' }, { word: 'baby', word_class: 'V' }, { word: 'baby', word_class: 'AJ' }],
  fining: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  unveiling: [
    { word: 'unveil', word_class: 'V' },
    { word: 'unveiled', word_class: 'AJ' },
    { word: 'unveiling', word_class: 'N' }
  ],
  anaglyph: [
    { word: 'anaglyph', word_class: 'N' },
    { word: 'anaglyphic', word_class: 'AJ' },
    { word: 'anaglyphical', word_class: 'AJ' }
  ],
  extrapolated: [
    { word: 'extrapolate', word_class: 'V' },
    { word: 'extrapolated', word_class: 'AJ' },
    { word: 'extrapolation', word_class: 'N' }
  ],
  weight: [
    { word: 'weigh', word_class: 'V' },
    { word: 'weight', word_class: 'N' },
    { word: 'weight', word_class: 'V' },
    { word: 'weighing', word_class: 'N' },
    { word: 'weighted', word_class: 'AJ' },
    { word: 'weighting', word_class: 'N' }
  ],
  bag: [
    { word: 'bag', word_class: 'N' },
    { word: 'bag', word_class: 'V' },
    { word: 'bagful', word_class: 'N' },
    { word: 'bagman', word_class: 'N' },
    { word: 'bagful', word_class: 'AJ' },
    { word: 'baggage', word_class: 'N' },
    { word: 'bagging', word_class: 'N' }
  ],
  despondence: [
    { word: 'despond', word_class: 'V' },
    { word: 'despondence', word_class: 'N' },
    { word: 'despondency', word_class: 'N' },
    { word: 'despondent', word_class: 'AJ' },
    { word: 'despondently', word_class: 'AV' }
  ],
  accountancy: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  causative: [
    { word: 'cause', word_class: 'N' },
    { word: 'cause', word_class: 'V' },
    { word: 'causing', word_class: 'N' },
    { word: 'causation', word_class: 'N' },
    { word: 'causative', word_class: 'AJ' }
  ],
  adsorptive: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  obstinate: [
    { word: 'obstinacy', word_class: 'N' },
    { word: 'obstinance', word_class: 'N' },
    { word: 'obstinate', word_class: 'AJ' },
    { word: 'obstinately', word_class: 'AV' }
  ],
  traducement: [
    { word: 'traduce', word_class: 'V' },
    { word: 'traducer', word_class: 'N' },
    { word: 'traduction', word_class: 'N' },
    { word: 'traducement', word_class: 'N' }
  ],
  geographic: [
    { word: 'geographer', word_class: 'N' },
    { word: 'geographic', word_class: 'AJ' },
    { word: 'geographics', word_class: 'N' },
    { word: 'geographical', word_class: 'AJ' },
    { word: 'geographically', word_class: 'AV' }
  ],
  unproductive: [
    { word: 'unproductive', word_class: 'AJ' },
    { word: 'unproductively', word_class: 'AV' },
    { word: 'unproductiveness', word_class: 'N' }
  ],
  coincidentally: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  satire: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  burbly: [
    { word: 'burble', word_class: 'V' },
    { word: 'burbly', word_class: 'AJ' },
    { word: 'burbling', word_class: 'AJ' }
  ],
  occidentalization: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  remake: [
    { word: 'remake', word_class: 'N' },
    { word: 'remake', word_class: 'V' },
    { word: 'remaking', word_class: 'N' }
  ],
  discontinuous: [
    { word: 'discontinue', word_class: 'V' },
    { word: 'discontinued', word_class: 'AJ' },
    { word: 'discontinuity', word_class: 'N' },
    { word: 'discontinuance', word_class: 'N' },
    { word: 'discontinuous', word_class: 'AJ' },
    { word: 'discontinuation', word_class: 'N' }
  ],
  fractionate: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  baker: [
    { word: 'bake', word_class: 'N' },
    { word: 'bake', word_class: 'V' },
    { word: 'baker', word_class: 'N' },
    { word: 'baked', word_class: 'AJ' },
    { word: 'bakery', word_class: 'N' },
    { word: 'baking', word_class: 'N' },
    { word: 'bakery', word_class: 'AJ' },
    { word: 'baking', word_class: 'AJ' }
  ],
  described: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  tripping: [
    { word: 'trip', word_class: 'N' },
    { word: 'trip', word_class: 'V' },
    { word: 'tripping', word_class: 'AJ' }
  ],
  characterized: [
    { word: 'character', word_class: 'N' },
    { word: 'character', word_class: 'V' },
    { word: 'characterize', word_class: 'V' },
    { word: 'characterized', word_class: 'AJ' },
    { word: 'characterization', word_class: 'N' }
  ],
  underdevelop: [
    { word: 'underdevelop', word_class: 'V' },
    { word: 'underdeveloped', word_class: 'AJ' },
    { word: 'underdevelopment', word_class: 'N' }
  ],
  unimagined: [
    { word: 'unimagined', word_class: 'AJ' },
    { word: 'unimaginable', word_class: 'AJ' },
    { word: 'unimaginably', word_class: 'AV' },
    { word: 'unimaginative', word_class: 'AJ' },
    { word: 'unimaginatively', word_class: 'AV' }
  ],
  trisection: [
    { word: 'trisect', word_class: 'N' },
    { word: 'trisect', word_class: 'V' },
    { word: 'trisection', word_class: 'N' }
  ],
  immunized: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  theorize: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  scale: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  supervisor: [
    { word: 'supervise', word_class: 'N' },
    { word: 'supervise', word_class: 'V' },
    { word: 'supervisor', word_class: 'N' },
    { word: 'supervised', word_class: 'AJ' },
    { word: 'supervising', word_class: 'N' },
    { word: 'supervision', word_class: 'N' }
  ],
  usuriously: [
    { word: 'usury', word_class: 'N' },
    { word: 'usurious', word_class: 'AJ' },
    { word: 'usuriously', word_class: 'AV' }
  ],
  allied: [
    { word: 'ally', word_class: 'N' },
    { word: 'ally', word_class: 'V' },
    { word: 'allis', word_class: 'N' },
    { word: 'allies', word_class: 'N' },
    { word: 'allied', word_class: 'AJ' },
    { word: 'alliance', word_class: 'N' }
  ],
  unequaled: [
    { word: 'unequal', word_class: 'AJ' },
    { word: 'unequaled', word_class: 'AJ' },
    { word: 'unequally', word_class: 'AV' },
    { word: 'unequalized', word_class: 'AJ' }
  ],
  evening: [
    { word: 'even', word_class: 'V' },
    { word: 'even', word_class: 'AJ' },
    { word: 'even', word_class: 'AV' },
    { word: 'evening', word_class: 'N' },
    { word: 'evenness', word_class: 'N' }
  ],
  unperceiving: [
    { word: 'unperceived', word_class: 'AJ' },
    { word: 'unperceiving', word_class: 'AJ' },
    { word: 'unperceivable', word_class: 'AJ' }
  ],
  pickle: [
    { word: 'pickle', word_class: 'N' },
    { word: 'pickle', word_class: 'V' },
    { word: 'pickled', word_class: 'AJ' }
  ],
  legitimate: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  forejudgement: [
    { word: 'forjudge', word_class: 'V' },
    { word: 'forejudge', word_class: 'V' },
    { word: 'forjudgment', word_class: 'N' },
    { word: 'forejudgment', word_class: 'N' },
    { word: 'forejudgement', word_class: 'N' }
  ],
  chilblain: [
    { word: 'chilblain', word_class: 'N' },
    { word: 'chilblains', word_class: 'N' },
    { word: 'chilblained', word_class: 'AJ' }
  ],
  purgation: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  reconditeness: [
    { word: 'recondite', word_class: 'AJ' },
    { word: 'recondition', word_class: 'V' },
    { word: 'reconditeness', word_class: 'N' },
    { word: 'reconditioned', word_class: 'AJ' }
  ],
  deodorisation: [
    { word: 'deodorant', word_class: 'N' },
    { word: 'deodorise', word_class: 'V' },
    { word: 'deodorize', word_class: 'V' },
    { word: 'deodorisation', word_class: 'N' },
    { word: 'deodorization', word_class: 'N' }
  ],
  whooper: [
    { word: 'whoop', word_class: 'N' },
    { word: 'whoop', word_class: 'V' },
    { word: 'whoopee', word_class: 'N' },
    { word: 'whooper', word_class: 'N' }
  ],
  arrogate: [
    { word: 'arrogate', word_class: 'V' },
    { word: 'arrogance', word_class: 'N' },
    { word: 'arrogant', word_class: 'AJ' },
    { word: 'arrogated', word_class: 'AJ' },
    { word: 'arrogation', word_class: 'N' }
  ],
  centered: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  cursed: [
    { word: 'curse', word_class: 'N' },
    { word: 'curse', word_class: 'V' },
    { word: 'cursor', word_class: 'N' },
    { word: 'cursed', word_class: 'AJ' },
    { word: 'cursing', word_class: 'AJ' }
  ],
  protest: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  simultaneousness: [
    { word: 'simultaneity', word_class: 'N' },
    { word: 'simultaneous', word_class: 'AJ' },
    { word: 'simultaneously', word_class: 'AV' },
    { word: 'simultaneousness', word_class: 'N' }
  ],
  reduction: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  taximan: [
    { word: 'taxi', word_class: 'N' },
    { word: 'taxi', word_class: 'V' },
    { word: 'taxis', word_class: 'N' },
    { word: 'taximan', word_class: 'N' }
  ],
  honorably: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  glooming: [
    { word: 'gloom', word_class: 'N' },
    { word: 'gloomful', word_class: 'AJ' },
    { word: 'glooming', word_class: 'AJ' }
  ],
  alcoholization: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  philistinism: [
    { word: 'philistine', word_class: 'N' },
    { word: 'philistine', word_class: 'AJ' },
    { word: 'philistinism', word_class: 'N' }
  ],
  utilized: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  roentgenize: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  castration: [
    { word: 'castrate', word_class: 'N' },
    { word: 'castrate', word_class: 'V' },
    { word: 'castrated', word_class: 'AJ' },
    { word: 'castration', word_class: 'N' }
  ],
  subversive: [
    { word: 'subvert', word_class: 'V' },
    { word: 'subverter', word_class: 'N' },
    { word: 'subversion', word_class: 'N' },
    { word: 'subversive', word_class: 'N' },
    { word: 'subversive', word_class: 'AJ' },
    { word: 'subversiveness', word_class: 'N' }
  ],
  humourous: [
    { word: 'humour', word_class: 'N' },
    { word: 'humour', word_class: 'V' },
    { word: 'humourist', word_class: 'N' },
    { word: 'humourous', word_class: 'AJ' },
    { word: 'humourously', word_class: 'AV' }
  ],
  vocalize: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  microscopically: [
    { word: 'microscope', word_class: 'N' },
    { word: 'microscopic', word_class: 'AJ' },
    { word: 'microscopical', word_class: 'AJ' },
    { word: 'microscopically', word_class: 'AV' }
  ],
  covalence: [
    { word: 'covalence', word_class: 'N' },
    { word: 'covalency', word_class: 'N' },
    { word: 'covalent', word_class: 'AJ' }
  ],
  forgotten: [
    { word: 'forget', word_class: 'V' },
    { word: 'forgetful', word_class: 'AJ' },
    { word: 'forgotten', word_class: 'AJ' },
    { word: 'forgetfulness', word_class: 'N' }
  ],
  sopping: [
    { word: 'sop', word_class: 'N' },
    { word: 'sop', word_class: 'V' },
    { word: 'sops', word_class: 'N' },
    { word: 'sopping', word_class: 'AJ' },
    { word: 'sopping', word_class: 'AV' }
  ],
  nettle: [
    { word: 'nettle', word_class: 'N' },
    { word: 'nettle', word_class: 'V' },
    { word: 'nettled', word_class: 'AJ' },
    { word: 'nettlesome', word_class: 'AJ' }
  ],
  comprehensibility: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  caribe: [
    { word: 'carib', word_class: 'N' },
    { word: 'caribe', word_class: 'N' },
    { word: 'caribou', word_class: 'N' }
  ],
  feigning: [
    { word: 'feign', word_class: 'V' },
    { word: 'feigned', word_class: 'AJ' },
    { word: 'feigning', word_class: 'N' }
  ],
  separation: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  retaining: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  disabled: [
    { word: 'disable', word_class: 'N' },
    { word: 'disable', word_class: 'V' },
    { word: 'disabled', word_class: 'N' },
    { word: 'disable', word_class: 'AJ' },
    { word: 'disabled', word_class: 'AJ' },
    { word: 'disability', word_class: 'N' },
    { word: 'disabling', word_class: 'AJ' },
    { word: 'disablement', word_class: 'N' }
  ],
  definite: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  bootees: [
    { word: 'boot', word_class: 'N' },
    { word: 'boot', word_class: 'V' },
    { word: 'bootee', word_class: 'N' },
    { word: 'bootes', word_class: 'N' },
    { word: 'booted', word_class: 'AJ' },
    { word: 'bootees', word_class: 'N' }
  ],
  repute: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  hellenization: [
    { word: 'hellene', word_class: 'N' },
    { word: 'hellenic', word_class: 'N' },
    { word: 'hellenic', word_class: 'AJ' },
    { word: 'hellenism', word_class: 'N' },
    { word: 'hellenize', word_class: 'V' },
    { word: 'hellenization', word_class: 'N' }
  ],
  unseasonably: [
    { word: 'unseasoned', word_class: 'AJ' },
    { word: 'unseasonable', word_class: 'AJ' },
    { word: 'unseasonably', word_class: 'AV' },
    { word: 'unseasonableness', word_class: 'N' }
  ],
  upstairs: [
    { word: 'upstair', word_class: 'AJ' },
    { word: 'upstairs', word_class: 'N' },
    { word: 'upstairs', word_class: 'AJ' },
    { word: 'upstairs', word_class: 'AV' }
  ],
  drafting: [
    { word: 'draft', word_class: 'N' },
    { word: 'draft', word_class: 'V' },
    { word: 'draftee', word_class: 'N' },
    { word: 'drafter', word_class: 'N' },
    { word: 'drafting', word_class: 'N' }
  ],
  territorially: [
    { word: 'territory', word_class: 'N' },
    { word: 'territorial', word_class: 'N' },
    { word: 'territorial', word_class: 'AJ' },
    { word: 'territorialise', word_class: 'V' },
    { word: 'territorialize', word_class: 'V' },
    { word: 'territoriality', word_class: 'N' },
    { word: 'territorially', word_class: 'AV' },
    { word: 'territorialisation', word_class: 'N' },
    { word: 'territorialization', word_class: 'N' }
  ],
  temporality: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  salaciousness: [
    { word: 'salacious', word_class: 'AJ' },
    { word: 'salaciously', word_class: 'AV' },
    { word: 'salaciousness', word_class: 'N' }
  ],
  uprightness: [
    { word: 'upright', word_class: 'N' },
    { word: 'upright', word_class: 'V' },
    { word: 'upright', word_class: 'AJ' },
    { word: 'uprightness', word_class: 'N' }
  ],
  disenthralment: [
    { word: 'disenthral', word_class: 'V' },
    { word: 'disenthrall', word_class: 'V' },
    { word: 'disenthralment', word_class: 'N' },
    { word: 'disenthrallment', word_class: 'N' }
  ],
  ammoniated: [
    { word: 'ammoniate', word_class: 'V' },
    { word: 'ammoniated', word_class: 'AJ' },
    { word: 'ammoniation', word_class: 'N' }
  ],
  reducer: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  loot: [
    { word: 'loot', word_class: 'N' },
    { word: 'loot', word_class: 'V' },
    { word: 'looter', word_class: 'N' },
    { word: 'looted', word_class: 'AJ' },
    { word: 'looting', word_class: 'N' }
  ],
  alert: [
    { word: 'alert', word_class: 'N' },
    { word: 'alert', word_class: 'V' },
    { word: 'alert', word_class: 'AJ' },
    { word: 'alerting', word_class: 'N' },
    { word: 'alertness', word_class: 'N' }
  ],
  force: [
    { word: 'force', word_class: 'N' },
    { word: 'force', word_class: 'V' },
    { word: 'forced', word_class: 'AJ' },
    { word: 'forceful', word_class: 'AJ' },
    { word: 'forcefulness', word_class: 'N' }
  ],
  opine: [
    { word: 'opine', word_class: 'V' },
    { word: 'opinion', word_class: 'N' },
    { word: 'opinionated', word_class: 'AJ' },
    { word: 'opinionative', word_class: 'AJ' }
  ],
  moralise: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  approximately: [
    { word: 'approximant', word_class: 'N' },
    { word: 'approximate', word_class: 'V' },
    { word: 'approximate', word_class: 'AJ' },
    { word: 'approximable', word_class: 'AJ' },
    { word: 'approximation', word_class: 'N' },
    { word: 'approximately', word_class: 'AV' },
    { word: 'approximative', word_class: 'AJ' }
  ],
  reverting: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  hushed: [{ word: 'hush', word_class: 'N' }, { word: 'hush', word_class: 'V' }, { word: 'hushed', word_class: 'AJ' }],
  expressively: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  modernist: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  wrapping: [
    { word: 'wrap', word_class: 'N' },
    { word: 'wrap', word_class: 'V' },
    { word: 'wrapped', word_class: 'AJ' },
    { word: 'wrapping', word_class: 'N' }
  ],
  exultant: [
    { word: 'exult', word_class: 'V' },
    { word: 'exultant', word_class: 'N' },
    { word: 'exultance', word_class: 'N' },
    { word: 'exultant', word_class: 'AJ' },
    { word: 'exulting', word_class: 'AJ' },
    { word: 'exultation', word_class: 'N' }
  ],
  formalized: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  sousing: [
    { word: 'souse', word_class: 'N' },
    { word: 'souse', word_class: 'V' },
    { word: 'soused', word_class: 'AJ' },
    { word: 'sousing', word_class: 'N' }
  ],
  academy: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  fermenting: [
    { word: 'ferment', word_class: 'N' },
    { word: 'ferment', word_class: 'V' },
    { word: 'fermented', word_class: 'AJ' },
    { word: 'fermenting', word_class: 'N' },
    { word: 'fermentation', word_class: 'N' }
  ],
  enmeshment: [
    { word: 'enmesh', word_class: 'V' },
    { word: 'enmeshed', word_class: 'AJ' },
    { word: 'enmeshment', word_class: 'N' }
  ],
  exportable: [
    { word: 'export', word_class: 'N' },
    { word: 'export', word_class: 'V' },
    { word: 'exporter', word_class: 'N' },
    { word: 'exporting', word_class: 'N' },
    { word: 'exportable', word_class: 'AJ' },
    { word: 'exportation', word_class: 'N' }
  ],
  disproportionate: [
    { word: 'disproportion', word_class: 'N' },
    { word: 'disproportion', word_class: 'V' },
    { word: 'disproportional', word_class: 'AJ' },
    { word: 'disproportionate', word_class: 'AJ' },
    { word: 'disproportionment', word_class: 'N' },
    { word: 'disproportionately', word_class: 'AV' }
  ],
  respectfulness: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  forgoing: [
    { word: 'forgo', word_class: 'V' },
    { word: 'forgoing', word_class: 'N' },
    { word: 'forgone', word_class: 'AJ' },
    { word: 'forgoing', word_class: 'AJ' }
  ],
  stockist: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  exasperate: [
    { word: 'exasperate', word_class: 'V' },
    { word: 'exasperated', word_class: 'AJ' },
    { word: 'exasperation', word_class: 'N' },
    { word: 'exasperating', word_class: 'AJ' }
  ],
  critic: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  captivate: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  benefit: [
    { word: 'benefit', word_class: 'N' },
    { word: 'benefit', word_class: 'V' },
    { word: 'benefaction', word_class: 'N' }
  ],
  suitableness: [
    { word: 'suitable', word_class: 'AJ' },
    { word: 'suitably', word_class: 'AV' },
    { word: 'suitability', word_class: 'N' },
    { word: 'suitableness', word_class: 'N' }
  ],
  insubstantiality: [
    { word: 'insubstantial', word_class: 'AJ' },
    { word: 'insubstantiality', word_class: 'N' },
    { word: 'insubstantially', word_class: 'AV' }
  ],
  expiration: [
    { word: 'expire', word_class: 'V' },
    { word: 'expiry', word_class: 'N' },
    { word: 'expired', word_class: 'AJ' },
    { word: 'expiring', word_class: 'AJ' },
    { word: 'expiration', word_class: 'N' }
  ],
  beveled: [
    { word: 'bevel', word_class: 'N' },
    { word: 'bevel', word_class: 'V' },
    { word: 'bevel', word_class: 'AJ' },
    { word: 'beveled', word_class: 'AJ' }
  ],
  college: [
    { word: 'college', word_class: 'N' },
    { word: 'collegial', word_class: 'AJ' },
    { word: 'collegiate', word_class: 'AJ' }
  ],
  eternization: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  accommodating: [
    { word: 'accommodate', word_class: 'V' },
    { word: 'accommodation', word_class: 'N' },
    { word: 'accommodating', word_class: 'AJ' },
    { word: 'accommodative', word_class: 'AJ' },
    { word: 'accommodational', word_class: 'AJ' }
  ],
  jesuitism: [
    { word: 'jesuit', word_class: 'N' },
    { word: 'jesuit', word_class: 'AJ' },
    { word: 'jesuitic', word_class: 'AJ' },
    { word: 'jesuitism', word_class: 'N' },
    { word: 'jesuitical', word_class: 'AJ' }
  ],
  destructiveness: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  unifying: [
    { word: 'unify', word_class: 'V' },
    { word: 'unified', word_class: 'AJ' },
    { word: 'unifying', word_class: 'AJ' },
    { word: 'unification', word_class: 'N' }
  ],
  readably: [
    { word: 'readable', word_class: 'AJ' },
    { word: 'readably', word_class: 'AV' },
    { word: 'readability', word_class: 'N' }
  ],
  demodulation: [
    { word: 'demodulate', word_class: 'V' },
    { word: 'demodulator', word_class: 'N' },
    { word: 'demodulation', word_class: 'N' }
  ],
  trajectory: [
    { word: 'traject', word_class: 'V' },
    { word: 'trajection', word_class: 'N' },
    { word: 'trajectory', word_class: 'N' },
    { word: 'trajectory', word_class: 'AJ' }
  ],
  vowelisation: [
    { word: 'vowel', word_class: 'N' },
    { word: 'vowelise', word_class: 'V' },
    { word: 'vowelize', word_class: 'V' },
    { word: 'vowelisation', word_class: 'N' },
    { word: 'vowelization', word_class: 'N' }
  ],
  allegretto: [
    { word: 'allegretto', word_class: 'N' },
    { word: 'allegretto', word_class: 'AJ' },
    { word: 'allegretto', word_class: 'AV' }
  ],
  devitalisation: [
    { word: 'devitalise', word_class: 'V' },
    { word: 'devitalize', word_class: 'V' },
    { word: 'devitalisation', word_class: 'N' },
    { word: 'devitalization', word_class: 'N' }
  ],
  combination: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  bawling: [
    { word: 'bawl', word_class: 'V' },
    { word: 'bawling', word_class: 'N' },
    { word: 'bawling', word_class: 'AJ' }
  ],
  forever: [
    { word: 'forever', word_class: 'N' },
    { word: 'forever', word_class: 'AJ' },
    { word: 'forever', word_class: 'AV' }
  ],
  placid: [
    { word: 'placid', word_class: 'AJ' },
    { word: 'placidity', word_class: 'N' },
    { word: 'placidness', word_class: 'N' }
  ],
  unquestionability: [
    { word: 'unquestionable', word_class: 'AJ' },
    { word: 'unquestionably', word_class: 'AV' },
    { word: 'unquestionability', word_class: 'N' },
    { word: 'unquestionableness', word_class: 'N' }
  ],
  ablated: [
    { word: 'ablate', word_class: 'V' },
    { word: 'ablated', word_class: 'AJ' },
    { word: 'ablation', word_class: 'N' }
  ],
  fuse: [
    { word: 'fuse', word_class: 'N' },
    { word: 'fuse', word_class: 'V' },
    { word: 'fusee', word_class: 'N' },
    { word: 'fused', word_class: 'AJ' },
    { word: 'fusion', word_class: 'N' }
  ],
  marked: [
    { word: 'mark', word_class: 'N' },
    { word: 'mark', word_class: 'V' },
    { word: 'marker', word_class: 'N' },
    { word: 'marked', word_class: 'AJ' },
    { word: 'marking', word_class: 'N' }
  ],
  abstruseness: [
    { word: 'abstruse', word_class: 'AJ' },
    { word: 'abstrusity', word_class: 'N' },
    { word: 'abstrusely', word_class: 'AV' },
    { word: 'abstruseness', word_class: 'N' }
  ],
  howl: [
    { word: 'howl', word_class: 'N' },
    { word: 'howl', word_class: 'V' },
    { word: 'howler', word_class: 'N' },
    { word: 'howling', word_class: 'N' },
    { word: 'howling', word_class: 'AJ' }
  ],
  crudely: [
    { word: 'crude', word_class: 'N' },
    { word: 'crude', word_class: 'AJ' },
    { word: 'crudely', word_class: 'AV' },
    { word: 'crudeness', word_class: 'N' }
  ],
  spectroscopic: [
    { word: 'spectroscope', word_class: 'N' },
    { word: 'spectroscopic', word_class: 'AJ' },
    { word: 'spectroscopical', word_class: 'AJ' }
  ],
  phonological: [
    { word: 'phonology', word_class: 'N' },
    { word: 'phonologic', word_class: 'AJ' },
    { word: 'phonological', word_class: 'AJ' }
  ],
  privilege: [
    { word: 'privilege', word_class: 'N' },
    { word: 'privilege', word_class: 'V' },
    { word: 'privileged', word_class: 'AJ' }
  ],
  overlapping: [
    { word: 'overlap', word_class: 'N' },
    { word: 'overlap', word_class: 'V' },
    { word: 'overlapping', word_class: 'N' },
    { word: 'overlapping', word_class: 'AJ' }
  ],
  masses: [
    { word: 'mass', word_class: 'N' },
    { word: 'mass', word_class: 'V' },
    { word: 'mass', word_class: 'AJ' },
    { word: 'masse', word_class: 'N' },
    { word: 'masses', word_class: 'N' },
    { word: 'massed', word_class: 'AJ' }
  ],
  divergency: [
    { word: 'diverge', word_class: 'N' },
    { word: 'diverge', word_class: 'V' },
    { word: 'divergence', word_class: 'N' },
    { word: 'divergency', word_class: 'N' },
    { word: 'divergent', word_class: 'AJ' },
    { word: 'diverging', word_class: 'AJ' }
  ],
  bellyache: [
    { word: 'bellyache', word_class: 'N' },
    { word: 'bellyache', word_class: 'V' },
    { word: 'bellyacher', word_class: 'N' }
  ],
  top: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  draughts: [
    { word: 'draught', word_class: 'N' },
    { word: 'draught', word_class: 'V' },
    { word: 'draughts', word_class: 'N' }
  ],
  anecdote: [
    { word: 'anecdote', word_class: 'N' },
    { word: 'anecdotal', word_class: 'AJ' },
    { word: 'anecdotic', word_class: 'AJ' },
    { word: 'anecdotical', word_class: 'AJ' }
  ],
  cannery: [
    { word: 'can', word_class: 'N' },
    { word: 'can', word_class: 'V' },
    { word: 'canful', word_class: 'N' },
    { word: 'canful', word_class: 'AJ' },
    { word: 'canned', word_class: 'AJ' },
    { word: 'cannery', word_class: 'N' },
    { word: 'canning', word_class: 'N' },
    { word: 'cannery', word_class: 'AJ' }
  ],
  worrisome: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  beautician: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  unpropitiousness: [
    { word: 'unpropitious', word_class: 'AJ' },
    { word: 'unpropitiously', word_class: 'AV' },
    { word: 'unpropitiousness', word_class: 'N' }
  ],
  receipt: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  particular: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  childbearing: [
    { word: 'child', word_class: 'N' },
    { word: 'childbearing', word_class: 'N' },
    { word: 'childbearing', word_class: 'AJ' }
  ],
  unassertively: [
    { word: 'unassertive', word_class: 'AJ' },
    { word: 'unassertively', word_class: 'AV' },
    { word: 'unassertiveness', word_class: 'N' }
  ],
  granular: [
    { word: 'grain', word_class: 'N' },
    { word: 'grain', word_class: 'V' },
    { word: 'grained', word_class: 'AJ' },
    { word: 'graining', word_class: 'N' },
    { word: 'granular', word_class: 'AJ' },
    { word: 'granularity', word_class: 'N' }
  ],
  idiotically: [
    { word: 'idiot', word_class: 'N' },
    { word: 'idiocy', word_class: 'N' },
    { word: 'idiotic', word_class: 'AJ' },
    { word: 'idiotically', word_class: 'AV' }
  ],
  dreamer: [
    { word: 'dream', word_class: 'N' },
    { word: 'dream', word_class: 'V' },
    { word: 'dreamer', word_class: 'N' },
    { word: 'dreamed', word_class: 'AJ' },
    { word: 'dreaming', word_class: 'N' }
  ],
  scalloped: [
    { word: 'scallop', word_class: 'N' },
    { word: 'scallop', word_class: 'V' },
    { word: 'scalloped', word_class: 'AJ' }
  ],
  whip: [
    { word: 'whip', word_class: 'N' },
    { word: 'whip', word_class: 'V' },
    { word: 'whipping', word_class: 'N' },
    { word: 'whipping', word_class: 'AJ' }
  ],
  hew: [{ word: 'hew', word_class: 'V' }, { word: 'hewer', word_class: 'N' }, { word: 'hewn', word_class: 'AJ' }],
  bewitching: [
    { word: 'bewitch', word_class: 'V' },
    { word: 'bewitched', word_class: 'AJ' },
    { word: 'bewitching', word_class: 'AJ' },
    { word: 'bewitchment', word_class: 'N' }
  ],
  ethnology: [
    { word: 'ethnology', word_class: 'N' },
    { word: 'ethnologic', word_class: 'AJ' },
    { word: 'ethnological', word_class: 'AJ' }
  ],
  decolor: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  tube: [
    { word: 'tube', word_class: 'N' },
    { word: 'tube', word_class: 'V' },
    { word: 'tubed', word_class: 'AJ' },
    { word: 'tubing', word_class: 'N' },
    { word: 'tubular', word_class: 'AJ' }
  ],
  regulate: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  may: [
    { word: 'may', word_class: 'N' },
    { word: 'may', word_class: 'V' },
    { word: 'mayor', word_class: 'N' },
    { word: 'mayoral', word_class: 'AJ' }
  ],
  impenitently: [
    { word: 'impenitence', word_class: 'N' },
    { word: 'impenitency', word_class: 'N' },
    { word: 'impenitent', word_class: 'AJ' },
    { word: 'impenitently', word_class: 'AV' }
  ],
  edibility: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  embranchment: [
    { word: 'branch', word_class: 'N' },
    { word: 'branch', word_class: 'V' },
    { word: 'branched', word_class: 'AJ' },
    { word: 'branching', word_class: 'N' },
    { word: 'branching', word_class: 'AJ' },
    { word: 'embranchment', word_class: 'N' }
  ],
  quiescence: [
    { word: 'quiescence', word_class: 'N' },
    { word: 'quiescency', word_class: 'N' },
    { word: 'quiescent', word_class: 'AJ' }
  ],
  dramatic: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  contest: [
    { word: 'contest', word_class: 'N' },
    { word: 'contest', word_class: 'V' },
    { word: 'contestant', word_class: 'N' },
    { word: 'contestable', word_class: 'AJ' },
    { word: 'contestation', word_class: 'N' }
  ],
  stir: [
    { word: 'stir', word_class: 'N' },
    { word: 'stir', word_class: 'V' },
    { word: 'stirred', word_class: 'AJ' },
    { word: 'stirring', word_class: 'N' },
    { word: 'stirring', word_class: 'AJ' }
  ],
  contrarious: [
    { word: 'contrary', word_class: 'N' },
    { word: 'contrary', word_class: 'AJ' },
    { word: 'contrariness', word_class: 'N' },
    { word: 'contrarious', word_class: 'AJ' },
    { word: 'contrariwise', word_class: 'AV' }
  ],
  austereness: [
    { word: 'austere', word_class: 'AJ' },
    { word: 'austerity', word_class: 'N' },
    { word: 'austerely', word_class: 'AV' },
    { word: 'austereness', word_class: 'N' }
  ],
  sound: [
    { word: 'sound', word_class: 'N' },
    { word: 'sound', word_class: 'V' },
    { word: 'sound', word_class: 'AJ' },
    { word: 'sound', word_class: 'AV' },
    { word: 'sounded', word_class: 'AJ' },
    { word: 'sounding', word_class: 'N' },
    { word: 'soundman', word_class: 'N' },
    { word: 'sounding', word_class: 'AJ' },
    { word: 'soundness', word_class: 'N' }
  ],
  crippled: [
    { word: 'cripple', word_class: 'N' },
    { word: 'cripple', word_class: 'V' },
    { word: 'crippled', word_class: 'AJ' },
    { word: 'crippling', word_class: 'AJ' }
  ],
  sanguinity: [
    { word: 'sanguine', word_class: 'AJ' },
    { word: 'sanguinity', word_class: 'N' },
    { word: 'sanguineness', word_class: 'N' },
    { word: 'sanguineous', word_class: 'AJ' }
  ],
  ding: [{ word: 'ding', word_class: 'N' }, { word: 'ding', word_class: 'V' }, { word: 'dinge', word_class: 'V' }],
  embattlement: [
    { word: 'embattle', word_class: 'V' },
    { word: 'embattled', word_class: 'AJ' },
    { word: 'embattlement', word_class: 'N' }
  ],
  regrets: [
    { word: 'regret', word_class: 'N' },
    { word: 'regret', word_class: 'V' },
    { word: 'regrets', word_class: 'N' },
    { word: 'regretful', word_class: 'AJ' }
  ],
  perpetrator: [
    { word: 'perpetrate', word_class: 'V' },
    { word: 'perpetrator', word_class: 'N' },
    { word: 'perpetration', word_class: 'N' }
  ],
  oscine: [
    { word: 'oscine', word_class: 'N' },
    { word: 'oscine', word_class: 'AJ' },
    { word: 'oscines', word_class: 'N' }
  ],
  fossilization: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  overwhelm: [
    { word: 'overwhelm', word_class: 'V' },
    { word: 'overwhelmed', word_class: 'AJ' },
    { word: 'overwhelming', word_class: 'AJ' }
  ],
  embalmer: [
    { word: 'embalm', word_class: 'V' },
    { word: 'embalmer', word_class: 'N' },
    { word: 'embalmment', word_class: 'N' }
  ],
  navigable: [
    { word: 'navigate', word_class: 'V' },
    { word: 'navigator', word_class: 'N' },
    { word: 'navigable', word_class: 'AJ' },
    { word: 'navigation', word_class: 'N' },
    { word: 'navigability', word_class: 'N' },
    { word: 'navigational', word_class: 'AJ' }
  ],
  rival: [
    { word: 'rival', word_class: 'N' },
    { word: 'rival', word_class: 'V' },
    { word: 'rival', word_class: 'AJ' },
    { word: 'rivalry', word_class: 'N' }
  ],
  colourful: [
    { word: 'colour', word_class: 'N' },
    { word: 'colour', word_class: 'V' },
    { word: 'colour', word_class: 'AJ' },
    { word: 'colours', word_class: 'N' },
    { word: 'coloured', word_class: 'AJ' },
    { word: 'colouring', word_class: 'N' },
    { word: 'colourful', word_class: 'AJ' }
  ],
  retriever: [
    { word: 'retrieve', word_class: 'N' },
    { word: 'retrieve', word_class: 'V' },
    { word: 'retrieval', word_class: 'N' },
    { word: 'retriever', word_class: 'N' },
    { word: 'retrievable', word_class: 'AJ' }
  ],
  seismology: [
    { word: 'seismology', word_class: 'N' },
    { word: 'seismologic', word_class: 'AJ' },
    { word: 'seismological', word_class: 'AJ' }
  ],
  aerobe: [
    { word: 'aerobe', word_class: 'N' },
    { word: 'aerobic', word_class: 'AJ' },
    { word: 'aerobics', word_class: 'N' }
  ],
  folk: [
    { word: 'folk', word_class: 'N' },
    { word: 'folk', word_class: 'AJ' },
    { word: 'folks', word_class: 'N' },
    { word: 'folksy', word_class: 'AJ' }
  ],
  knocker: [
    { word: 'knock', word_class: 'N' },
    { word: 'knock', word_class: 'V' },
    { word: 'knocker', word_class: 'N' },
    { word: 'knocking', word_class: 'N' }
  ],
  malfunctioning: [
    { word: 'malfunction', word_class: 'N' },
    { word: 'malfunction', word_class: 'V' },
    { word: 'malfunctioning', word_class: 'AJ' }
  ],
  credentialed: [
    { word: 'credential', word_class: 'N' },
    { word: 'credentials', word_class: 'N' },
    { word: 'credentialed', word_class: 'AJ' }
  ],
  expertize: [
    { word: 'expert', word_class: 'N' },
    { word: 'expert', word_class: 'AJ' },
    { word: 'expertize', word_class: 'V' },
    { word: 'expertness', word_class: 'N' }
  ],
  coarsened: [
    { word: 'coarsen', word_class: 'V' },
    { word: 'coarsened', word_class: 'AJ' },
    { word: 'coarsening', word_class: 'N' }
  ],
  carousal: [
    { word: 'carouse', word_class: 'N' },
    { word: 'carouse', word_class: 'V' },
    { word: 'carousal', word_class: 'N' },
    { word: 'carouser', word_class: 'N' },
    { word: 'carousing', word_class: 'AJ' }
  ],
  fastidiously: [
    { word: 'fastidious', word_class: 'AJ' },
    { word: 'fastidiously', word_class: 'AV' },
    { word: 'fastidiousness', word_class: 'N' }
  ],
  drenching: [
    { word: 'drench', word_class: 'V' },
    { word: 'drenched', word_class: 'AJ' },
    { word: 'drenching', word_class: 'N' }
  ],
  ultracentrifuge: [
    { word: 'ultracentrifuge', word_class: 'N' },
    { word: 'ultracentrifuge', word_class: 'V' },
    { word: 'ultracentrifugation', word_class: 'N' }
  ],
  faltering: [
    { word: 'falter', word_class: 'N' },
    { word: 'falter', word_class: 'V' },
    { word: 'faltering', word_class: 'N' },
    { word: 'faltering', word_class: 'AJ' }
  ],
  whispering: [
    { word: 'whisper', word_class: 'N' },
    { word: 'whisper', word_class: 'V' },
    { word: 'whisperer', word_class: 'N' },
    { word: 'whispered', word_class: 'AJ' },
    { word: 'whispering', word_class: 'N' },
    { word: 'whispering', word_class: 'AJ' }
  ],
  tenancy: [
    { word: 'tenant', word_class: 'N' },
    { word: 'tenant', word_class: 'V' },
    { word: 'tenancy', word_class: 'N' },
    { word: 'tenanted', word_class: 'AJ' }
  ],
  abranchiate: [
    { word: 'abranchial', word_class: 'AJ' },
    { word: 'abranchiate', word_class: 'AJ' },
    { word: 'abranchious', word_class: 'AJ' }
  ],
  striped: [
    { word: 'stripe', word_class: 'N' },
    { word: 'stripe', word_class: 'V' },
    { word: 'stripes', word_class: 'N' },
    { word: 'stripy', word_class: 'AJ' },
    { word: 'striped', word_class: 'AJ' },
    { word: 'striping', word_class: 'N' }
  ],
  compositional: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  ranker: [
    { word: 'rank', word_class: 'N' },
    { word: 'rank', word_class: 'V' },
    { word: 'rank', word_class: 'AJ' },
    { word: 'ranker', word_class: 'N' },
    { word: 'ranked', word_class: 'AJ' },
    { word: 'ranking', word_class: 'N' },
    { word: 'ranking', word_class: 'AJ' },
    { word: 'rankness', word_class: 'N' }
  ],
  spellbinding: [
    { word: 'spellbind', word_class: 'V' },
    { word: 'spellbinder', word_class: 'N' },
    { word: 'spellbinding', word_class: 'AJ' }
  ],
  sponsor: [
    { word: 'sponsor', word_class: 'N' },
    { word: 'sponsor', word_class: 'V' },
    { word: 'sponsorship', word_class: 'N' }
  ],
  orientalize: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  psychopathologic: [
    { word: 'psychopathology', word_class: 'N' },
    { word: 'psychopathologic', word_class: 'AJ' },
    { word: 'psychopathological', word_class: 'AJ' }
  ],
  putrefy: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  acclimatise: [
    { word: 'acclimatise', word_class: 'V' },
    { word: 'acclimatize', word_class: 'V' },
    { word: 'acclimatisation', word_class: 'N' },
    { word: 'acclimatization', word_class: 'N' }
  ],
  depositor: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  jocosely: [
    { word: 'jocose', word_class: 'AJ' },
    { word: 'jocosity', word_class: 'N' },
    { word: 'jocosely', word_class: 'AV' },
    { word: 'jocoseness', word_class: 'N' }
  ],
  erose: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  machinery: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  novelize: [
    { word: 'novel', word_class: 'N' },
    { word: 'novel', word_class: 'AJ' },
    { word: 'novelty', word_class: 'N' },
    { word: 'novelize', word_class: 'V' },
    { word: 'novelties', word_class: 'N' },
    { word: 'novelization', word_class: 'N' }
  ],
  behaviorist: [
    { word: 'behaviorist', word_class: 'N' },
    { word: 'behaviorist', word_class: 'AJ' },
    { word: 'behavioristic', word_class: 'AJ' }
  ],
  pm: [{ word: 'pm', word_class: 'N' }, { word: 'pm', word_class: 'AJ' }, { word: 'pms', word_class: 'N' }],
  correlativity: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  recursion: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  laugher: [
    { word: 'laugh', word_class: 'N' },
    { word: 'laugh', word_class: 'V' },
    { word: 'laugher', word_class: 'N' },
    { word: 'laughter', word_class: 'N' },
    { word: 'laughing', word_class: 'AJ' }
  ],
  biographical: [
    { word: 'biographer', word_class: 'N' },
    { word: 'biographic', word_class: 'AJ' },
    { word: 'biographical', word_class: 'AJ' }
  ],
  concreteness: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  shellacking: [
    { word: 'shellac', word_class: 'N' },
    { word: 'shellac', word_class: 'V' },
    { word: 'shellacking', word_class: 'AJ' }
  ],
  invigorating: [
    { word: 'invigorate', word_class: 'V' },
    { word: 'invigorated', word_class: 'AJ' },
    { word: 'invigoration', word_class: 'N' },
    { word: 'invigorating', word_class: 'AJ' }
  ],
  mincing: [
    { word: 'mince', word_class: 'N' },
    { word: 'mince', word_class: 'V' },
    { word: 'mincer', word_class: 'N' },
    { word: 'mincing', word_class: 'AJ' }
  ],
  dickie: [
    { word: 'dicky', word_class: 'N' },
    { word: 'dickie', word_class: 'N' },
    { word: 'dicky', word_class: 'AJ' }
  ],
  shooting: [
    { word: 'shot', word_class: 'N' },
    { word: 'shoot', word_class: 'N' },
    { word: 'shoot', word_class: 'V' },
    { word: 'shot', word_class: 'AJ' },
    { word: 'shooter', word_class: 'N' },
    { word: 'shooting', word_class: 'N' }
  ],
  destroy: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  printing: [
    { word: 'print', word_class: 'N' },
    { word: 'print', word_class: 'V' },
    { word: 'print', word_class: 'AJ' },
    { word: 'printer', word_class: 'N' },
    { word: 'printed', word_class: 'AJ' },
    { word: 'printing', word_class: 'N' }
  ],
  soldiership: [
    { word: 'soldier', word_class: 'N' },
    { word: 'soldier', word_class: 'V' },
    { word: 'soldiery', word_class: 'N' },
    { word: 'soldiery', word_class: 'AJ' },
    { word: 'soldiering', word_class: 'N' },
    { word: 'soldiership', word_class: 'N' }
  ],
  enthralling: [
    { word: 'enthral', word_class: 'V' },
    { word: 'enthrall', word_class: 'V' },
    { word: 'enthralled', word_class: 'AJ' },
    { word: 'enthralment', word_class: 'N' },
    { word: 'enthralling', word_class: 'AJ' },
    { word: 'enthrallment', word_class: 'N' }
  ],
  anteriority: [
    { word: 'anterior', word_class: 'N' },
    { word: 'anterior', word_class: 'AJ' },
    { word: 'anteriority', word_class: 'N' }
  ],
  nonviolence: [
    { word: 'nonviolence', word_class: 'N' },
    { word: 'nonviolent', word_class: 'AJ' },
    { word: 'nonviolently', word_class: 'AV' }
  ],
  marking: [
    { word: 'mark', word_class: 'N' },
    { word: 'mark', word_class: 'V' },
    { word: 'marker', word_class: 'N' },
    { word: 'marked', word_class: 'AJ' },
    { word: 'marking', word_class: 'N' }
  ],
  attainable: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  formula: [
    { word: 'formula', word_class: 'N' },
    { word: 'formulaic', word_class: 'AJ' },
    { word: 'formulaize', word_class: 'V' }
  ],
  worthful: [
    { word: 'worth', word_class: 'N' },
    { word: 'worth', word_class: 'AJ' },
    { word: 'worthful', word_class: 'AJ' }
  ],
  triplicate: [
    { word: 'triplicate', word_class: 'N' },
    { word: 'triplicate', word_class: 'V' },
    { word: 'triplicate', word_class: 'AJ' },
    { word: 'triplication', word_class: 'N' }
  ],
  negligence: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  numeric: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  ceremoniously: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  laicisation: [
    { word: 'laicise', word_class: 'V' },
    { word: 'laicize', word_class: 'V' },
    { word: 'laicisation', word_class: 'N' },
    { word: 'laicization', word_class: 'N' }
  ],
  frail: [
    { word: 'frail', word_class: 'N' },
    { word: 'frail', word_class: 'AJ' },
    { word: 'frailty', word_class: 'N' },
    { word: 'frailness', word_class: 'N' }
  ],
  inflammatory: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  degeneration: [
    { word: 'degenerate', word_class: 'N' },
    { word: 'degenerate', word_class: 'V' },
    { word: 'degenerate', word_class: 'AJ' },
    { word: 'degeneration', word_class: 'N' }
  ],
  reverence: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  convincement: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  jolting: [
    { word: 'jolt', word_class: 'N' },
    { word: 'jolt', word_class: 'V' },
    { word: 'jolted', word_class: 'AJ' },
    { word: 'jolting', word_class: 'AJ' }
  ],
  sloped: [
    { word: 'slope', word_class: 'N' },
    { word: 'slope', word_class: 'V' },
    { word: 'sloped', word_class: 'AJ' },
    { word: 'sloping', word_class: 'AJ' }
  ],
  hypnosis: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  convince: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  fertile: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  demythologize: [
    { word: 'demythologise', word_class: 'V' },
    { word: 'demythologize', word_class: 'V' },
    { word: 'demythologized', word_class: 'AJ' },
    { word: 'demythologisation', word_class: 'N' },
    { word: 'demythologization', word_class: 'N' }
  ],
  scandalmongering: [
    { word: 'scandalmonger', word_class: 'N' },
    { word: 'scandalmongering', word_class: 'N' },
    { word: 'scandalmongering', word_class: 'AJ' }
  ],
  adaptability: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  idolatry: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  insightfulness: [
    { word: 'insight', word_class: 'N' },
    { word: 'insight', word_class: 'V' },
    { word: 'insightful', word_class: 'AJ' },
    { word: 'insightfulness', word_class: 'N' }
  ],
  dazzled: [
    { word: 'dazzle', word_class: 'N' },
    { word: 'dazzle', word_class: 'V' },
    { word: 'dazzled', word_class: 'AJ' },
    { word: 'dazzling', word_class: 'AJ' }
  ],
  transcendence: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  wearing: [
    { word: 'wear', word_class: 'N' },
    { word: 'wear', word_class: 'V' },
    { word: 'worn', word_class: 'AJ' },
    { word: 'wearer', word_class: 'N' },
    { word: 'wearing', word_class: 'N' },
    { word: 'wearing', word_class: 'AJ' }
  ],
  incitation: [
    { word: 'incite', word_class: 'V' },
    { word: 'inciter', word_class: 'N' },
    { word: 'incitive', word_class: 'AJ' },
    { word: 'incitation', word_class: 'N' },
    { word: 'incitement', word_class: 'N' }
  ],
  starter: [
    { word: 'start', word_class: 'N' },
    { word: 'start', word_class: 'V' },
    { word: 'starter', word_class: 'N' },
    { word: 'starting', word_class: 'N' },
    { word: 'starting', word_class: 'AJ' }
  ],
  brutalise: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  lacking: [
    { word: 'lack', word_class: 'N' },
    { word: 'lack', word_class: 'V' },
    { word: 'lacking', word_class: 'AJ' }
  ],
  jointer: [
    { word: 'joint', word_class: 'N' },
    { word: 'joint', word_class: 'V' },
    { word: 'joint', word_class: 'AJ' },
    { word: 'jointer', word_class: 'N' },
    { word: 'jointed', word_class: 'AJ' }
  ],
  catalog: [
    { word: 'catalog', word_class: 'N' },
    { word: 'catalog', word_class: 'V' },
    { word: 'cataloger', word_class: 'N' }
  ],
  walk: [
    { word: 'walk', word_class: 'N' },
    { word: 'walk', word_class: 'V' },
    { word: 'walker', word_class: 'N' },
    { word: 'walking', word_class: 'N' },
    { word: 'walking', word_class: 'AJ' }
  ],
  elucidation: [
    { word: 'elucidate', word_class: 'V' },
    { word: 'elucidation', word_class: 'N' },
    { word: 'elucidative', word_class: 'AJ' }
  ],
  feature: [
    { word: 'feature', word_class: 'N' },
    { word: 'feature', word_class: 'V' },
    { word: 'featured', word_class: 'AJ' }
  ],
  galvanization: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  informatively: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  rescission: [
    { word: 'rescind', word_class: 'V' },
    { word: 'rescinding', word_class: 'N' },
    { word: 'rescission', word_class: 'N' },
    { word: 'rescindment', word_class: 'N' },
    { word: 'rescindable', word_class: 'AJ' }
  ],
  engrossment: [
    { word: 'engross', word_class: 'V' },
    { word: 'engrossed', word_class: 'AJ' },
    { word: 'engrossing', word_class: 'AJ' },
    { word: 'engrossment', word_class: 'N' }
  ],
  emerge: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  impenitent: [
    { word: 'impenitence', word_class: 'N' },
    { word: 'impenitency', word_class: 'N' },
    { word: 'impenitent', word_class: 'AJ' },
    { word: 'impenitently', word_class: 'AV' }
  ],
  bush: [
    { word: 'bush', word_class: 'N' },
    { word: 'bush', word_class: 'V' },
    { word: 'bushed', word_class: 'AJ' },
    { word: 'bushing', word_class: 'N' }
  ],
  marbles: [
    { word: 'marble', word_class: 'N' },
    { word: 'marble', word_class: 'V' },
    { word: 'marbles', word_class: 'N' },
    { word: 'marbled', word_class: 'AJ' },
    { word: 'marbling', word_class: 'N' }
  ],
  yachting: [
    { word: 'yacht', word_class: 'N' },
    { word: 'yacht', word_class: 'V' },
    { word: 'yachting', word_class: 'N' }
  ],
  proficiency: [
    { word: 'proficiency', word_class: 'N' },
    { word: 'proficient', word_class: 'AJ' },
    { word: 'proficiently', word_class: 'AV' }
  ],
  luggage: [
    { word: 'lug', word_class: 'N' },
    { word: 'lug', word_class: 'V' },
    { word: 'luger', word_class: 'N' },
    { word: 'luggage', word_class: 'N' }
  ],
  squinched: [
    { word: 'squinch', word_class: 'N' },
    { word: 'squinch', word_class: 'V' },
    { word: 'squinched', word_class: 'AJ' }
  ],
  rid: [{ word: 'rid', word_class: 'V' }, { word: 'rid', word_class: 'AJ' }, { word: 'riddance', word_class: 'N' }],
  hebdomadal: [
    { word: 'hebdomad', word_class: 'N' },
    { word: 'hebdomadal', word_class: 'AJ' },
    { word: 'hebdomadally', word_class: 'AV' }
  ],
  dumbfound: [
    { word: 'dumbfound', word_class: 'V' },
    { word: 'dumbfounded', word_class: 'AJ' },
    { word: 'dumbfounding', word_class: 'AJ' }
  ],
  bellowing: [
    { word: 'bellow', word_class: 'N' },
    { word: 'bellow', word_class: 'V' },
    { word: 'bellows', word_class: 'N' },
    { word: 'bellowing', word_class: 'N' }
  ],
  macerate: [
    { word: 'macer', word_class: 'N' },
    { word: 'macerate', word_class: 'V' },
    { word: 'maceration', word_class: 'N' },
    { word: 'macerative', word_class: 'AJ' }
  ],
  aggravating: [
    { word: 'aggravate', word_class: 'V' },
    { word: 'aggravated', word_class: 'AJ' },
    { word: 'aggravation', word_class: 'N' },
    { word: 'aggravating', word_class: 'AJ' }
  ],
  granulate: [
    { word: 'granule', word_class: 'N' },
    { word: 'granulate', word_class: 'V' },
    { word: 'granulated', word_class: 'AJ' },
    { word: 'granulation', word_class: 'N' }
  ],
  sleek: [
    { word: 'sleek', word_class: 'V' },
    { word: 'sleek', word_class: 'AJ' },
    { word: 'sleekness', word_class: 'N' }
  ],
  intransigent: [
    { word: 'intransigence', word_class: 'N' },
    { word: 'intransigency', word_class: 'N' },
    { word: 'intransigent', word_class: 'AJ' }
  ],
  renovator: [
    { word: 'renovate', word_class: 'V' },
    { word: 'renovator', word_class: 'N' },
    { word: 'renovated', word_class: 'AJ' },
    { word: 'renovation', word_class: 'N' }
  ],
  countrified: [
    { word: 'country', word_class: 'N' },
    { word: 'country', word_class: 'V' },
    { word: 'country', word_class: 'AJ' },
    { word: 'countrify', word_class: 'V' },
    { word: 'countrified', word_class: 'AJ' }
  ],
  marvelous: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  onerous: [
    { word: 'onerous', word_class: 'AJ' },
    { word: 'onerously', word_class: 'AV' },
    { word: 'onerousness', word_class: 'N' }
  ],
  understatement: [
    { word: 'understate', word_class: 'V' },
    { word: 'understated', word_class: 'AJ' },
    { word: 'understatement', word_class: 'N' }
  ],
  stylize: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  digitation: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  affableness: [
    { word: 'affable', word_class: 'AJ' },
    { word: 'affably', word_class: 'AV' },
    { word: 'affability', word_class: 'N' },
    { word: 'affableness', word_class: 'N' }
  ],
  flying: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  loyal: [
    { word: 'loyal', word_class: 'AJ' },
    { word: 'loyalty', word_class: 'N' },
    { word: 'loyally', word_class: 'AV' }
  ],
  nest: [
    { word: 'nest', word_class: 'N' },
    { word: 'nest', word_class: 'V' },
    { word: 'nester', word_class: 'N' },
    { word: 'nestor', word_class: 'N' }
  ],
  authorisation: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  carpenter: [
    { word: 'carpent', word_class: 'AJ' },
    { word: 'carpenter', word_class: 'N' },
    { word: 'carpenter', word_class: 'V' },
    { word: 'carpentry', word_class: 'N' }
  ],
  speculativeness: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  exhaustively: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  turbanned: [
    { word: 'turban', word_class: 'N' },
    { word: 'turbaned', word_class: 'AJ' },
    { word: 'turbanned', word_class: 'AJ' }
  ],
  assembly: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  conditional: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  capability: [
    { word: 'cabable', word_class: 'AJ' },
    { word: 'capable', word_class: 'AJ' },
    { word: 'capably', word_class: 'AV' },
    { word: 'capability', word_class: 'N' },
    { word: 'capableness', word_class: 'N' }
  ],
  revealed: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  fluoridize: [
    { word: 'fluoride', word_class: 'N' },
    { word: 'fluoridate', word_class: 'V' },
    { word: 'fluoridize', word_class: 'V' },
    { word: 'fluoridation', word_class: 'N' },
    { word: 'fluoridization', word_class: 'N' }
  ],
  decipher: [
    { word: 'decipher', word_class: 'V' },
    { word: 'deciphered', word_class: 'AJ' },
    { word: 'decipherment', word_class: 'N' },
    { word: 'decipherable', word_class: 'AJ' },
    { word: 'decipherably', word_class: 'AV' }
  ],
  hexed: [
    { word: 'hex', word_class: 'N' },
    { word: 'hex', word_class: 'V' },
    { word: 'hex', word_class: 'AJ' },
    { word: 'hexed', word_class: 'AJ' }
  ],
  quarrelsomeness: [
    { word: 'quarrel', word_class: 'N' },
    { word: 'quarrel', word_class: 'V' },
    { word: 'quarrelsome', word_class: 'AJ' },
    { word: 'quarrelsomeness', word_class: 'N' }
  ],
  locative: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  wheeziness: [
    { word: 'wheeze', word_class: 'N' },
    { word: 'wheeze', word_class: 'V' },
    { word: 'wheezy', word_class: 'AJ' },
    { word: 'wheezing', word_class: 'AJ' },
    { word: 'wheeziness', word_class: 'N' }
  ],
  dyed: [
    { word: 'dy', word_class: 'N' },
    { word: 'die', word_class: 'N' },
    { word: 'die', word_class: 'V' },
    { word: 'death', word_class: 'N' },
    { word: 'dyed', word_class: 'AJ' },
    { word: 'dying', word_class: 'V' },
    { word: 'dying', word_class: 'N' },
    { word: 'dying', word_class: 'AJ' }
  ],
  volcanism: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  impetuousness: [
    { word: 'impetus', word_class: 'N' },
    { word: 'impetuous', word_class: 'AJ' },
    { word: 'impetuously', word_class: 'AV' },
    { word: 'impetuousness', word_class: 'N' }
  ],
  irreverence: [
    { word: 'irreverence', word_class: 'N' },
    { word: 'irreverent', word_class: 'AJ' },
    { word: 'irreverently', word_class: 'AV' }
  ],
  insidious: [
    { word: 'insidious', word_class: 'AJ' },
    { word: 'insidiously', word_class: 'AV' },
    { word: 'insidiousness', word_class: 'N' }
  ],
  cannulization: [
    { word: 'canulate', word_class: 'V' },
    { word: 'cannulate', word_class: 'V' },
    { word: 'canulation', word_class: 'N' },
    { word: 'cannulation', word_class: 'N' },
    { word: 'canulization', word_class: 'N' },
    { word: 'cannulization', word_class: 'N' }
  ],
  aper: [{ word: 'ape', word_class: 'N' }, { word: 'ape', word_class: 'V' }, { word: 'aper', word_class: 'N' }],
  madness: [
    { word: 'mad', word_class: 'AJ' },
    { word: 'madness', word_class: 'N' },
    { word: 'bemadden', word_class: 'V' }
  ],
  vocalization: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  remittance: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  sacked: [
    { word: 'sack', word_class: 'N' },
    { word: 'sack', word_class: 'V' },
    { word: 'sacked', word_class: 'AJ' },
    { word: 'sackful', word_class: 'N' },
    { word: 'sacking', word_class: 'N' },
    { word: 'sackful', word_class: 'AJ' }
  ],
  butt: [{ word: 'butt', word_class: 'N' }, { word: 'butt', word_class: 'V' }, { word: 'butte', word_class: 'N' }],
  formularization: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  appose: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  concurrence: [
    { word: 'concur', word_class: 'V' },
    { word: 'concurrence', word_class: 'N' },
    { word: 'concurrent', word_class: 'AJ' },
    { word: 'concurring', word_class: 'AJ' },
    { word: 'concurrently', word_class: 'AV' }
  ],
  position: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  blighted: [
    { word: 'blight', word_class: 'N' },
    { word: 'blight', word_class: 'V' },
    { word: 'blighter', word_class: 'N' },
    { word: 'blighted', word_class: 'AJ' }
  ],
  insurgent: [
    { word: 'insurgent', word_class: 'N' },
    { word: 'insurgency', word_class: 'N' },
    { word: 'insurgent', word_class: 'AJ' }
  ],
  mobilize: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  restfulness: [
    { word: 'rest', word_class: 'N' },
    { word: 'rest', word_class: 'V' },
    { word: 'rested', word_class: 'AJ' },
    { word: 'restful', word_class: 'AJ' },
    { word: 'restfulness', word_class: 'N' }
  ],
  touring: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  armoured: [
    { word: 'armour', word_class: 'N' },
    { word: 'armour', word_class: 'V' },
    { word: 'armourer', word_class: 'N' },
    { word: 'armoured', word_class: 'AJ' }
  ],
  aggravation: [
    { word: 'aggravate', word_class: 'V' },
    { word: 'aggravated', word_class: 'AJ' },
    { word: 'aggravation', word_class: 'N' },
    { word: 'aggravating', word_class: 'AJ' }
  ],
  teutonise: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  barrels: [
    { word: 'barrel', word_class: 'N' },
    { word: 'barrel', word_class: 'V' },
    { word: 'barrels', word_class: 'N' },
    { word: 'barreled', word_class: 'AJ' },
    { word: 'barrelful', word_class: 'N' },
    { word: 'barrelled', word_class: 'AJ' }
  ],
  unreality: [
    { word: 'unreal', word_class: 'AJ' },
    { word: 'unrealism', word_class: 'N' },
    { word: 'unreality', word_class: 'N' },
    { word: 'unrealized', word_class: 'AJ' }
  ],
  scrupulously: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  forget: [
    { word: 'forget', word_class: 'V' },
    { word: 'forgetful', word_class: 'AJ' },
    { word: 'forgotten', word_class: 'AJ' },
    { word: 'forgetfulness', word_class: 'N' }
  ],
  lateness: [
    { word: 'late', word_class: 'AJ' },
    { word: 'late', word_class: 'AV' },
    { word: 'lately', word_class: 'AV' },
    { word: 'lateness', word_class: 'N' }
  ],
  shrink: [
    { word: 'shrink', word_class: 'N' },
    { word: 'shrink', word_class: 'V' },
    { word: 'shrunk', word_class: 'AJ' },
    { word: 'shrinkage', word_class: 'N' },
    { word: 'shrinking', word_class: 'N' },
    { word: 'shrunken', word_class: 'AJ' }
  ],
  shock: [
    { word: 'shock', word_class: 'N' },
    { word: 'shock', word_class: 'V' },
    { word: 'shocker', word_class: 'N' },
    { word: 'shocked', word_class: 'AJ' },
    { word: 'shocking', word_class: 'AJ' }
  ],
  esteem: [
    { word: 'esteem', word_class: 'N' },
    { word: 'esteem', word_class: 'V' },
    { word: 'esteemed', word_class: 'AJ' }
  ],
  hyposensitization: [
    { word: 'hyposensitise', word_class: 'V' },
    { word: 'hyposensitize', word_class: 'V' },
    { word: 'hyposensitisation', word_class: 'N' },
    { word: 'hyposensitization', word_class: 'N' }
  ],
  acreage: [
    { word: 'acre', word_class: 'N' },
    { word: 'acres', word_class: 'N' },
    { word: 'acreage', word_class: 'N' }
  ],
  stimulate: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  alkalinization: [
    { word: 'alkali', word_class: 'N' },
    { word: 'alkaline', word_class: 'AJ' },
    { word: 'alkalinity', word_class: 'N' },
    { word: 'alkalinize', word_class: 'V' },
    { word: 'alkalinization', word_class: 'N' }
  ],
  officialize: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  respective: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  graphitization: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  artificiality: [
    { word: 'artificial', word_class: 'AJ' },
    { word: 'artificiality', word_class: 'N' },
    { word: 'artificially', word_class: 'AV' }
  ],
  warrantee: [
    { word: 'warrant', word_class: 'N' },
    { word: 'warrant', word_class: 'V' },
    { word: 'warrantee', word_class: 'N' },
    { word: 'warrantor', word_class: 'N' },
    { word: 'warranted', word_class: 'AJ' }
  ],
  broadside: [
    { word: 'broadside', word_class: 'N' },
    { word: 'broadside', word_class: 'V' },
    { word: 'broadside', word_class: 'AJ' },
    { word: 'broadside', word_class: 'AV' }
  ],
  discussant: [
    { word: 'discuss', word_class: 'V' },
    { word: 'discussant', word_class: 'N' },
    { word: 'discussion', word_class: 'N' }
  ],
  unfolded: [
    { word: 'unfold', word_class: 'V' },
    { word: 'unfolded', word_class: 'AJ' },
    { word: 'unfolding', word_class: 'N' }
  ],
  possession: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  homogeneousness: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  weaponry: [
    { word: 'weapon', word_class: 'N' },
    { word: 'weaponry', word_class: 'N' },
    { word: 'weaponed', word_class: 'AJ' }
  ],
  discernible: [
    { word: 'discern', word_class: 'V' },
    { word: 'discerning', word_class: 'AJ' },
    { word: 'discernment', word_class: 'N' },
    { word: 'discernable', word_class: 'AJ' },
    { word: 'discernible', word_class: 'AJ' },
    { word: 'discernability', word_class: 'N' }
  ],
  planeness: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  parted: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  bested: [
    { word: 'best', word_class: 'N' },
    { word: 'best', word_class: 'V' },
    { word: 'best', word_class: 'AJ' },
    { word: 'best', word_class: 'AV' },
    { word: 'bested', word_class: 'AJ' }
  ],
  estrange: [
    { word: 'estrange', word_class: 'V' },
    { word: 'estranged', word_class: 'AJ' },
    { word: 'estranging', word_class: 'AJ' },
    { word: 'estrangement', word_class: 'N' }
  ],
  censure: [
    { word: 'censure', word_class: 'N' },
    { word: 'censure', word_class: 'V' },
    { word: 'censured', word_class: 'AJ' },
    { word: 'censurable', word_class: 'AJ' }
  ],
  notary: [
    { word: 'notary', word_class: 'N' },
    { word: 'notarize', word_class: 'V' },
    { word: 'notarization', word_class: 'N' }
  ],
  hypodermic: [
    { word: 'hypodermic', word_class: 'N' },
    { word: 'hypodermal', word_class: 'AJ' },
    { word: 'hypodermic', word_class: 'AJ' }
  ],
  hyphenation: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  lionization: [
    { word: 'lionise', word_class: 'V' },
    { word: 'lionize', word_class: 'V' },
    { word: 'lionisation', word_class: 'N' },
    { word: 'lionization', word_class: 'N' }
  ],
  pernicious: [
    { word: 'pernicious', word_class: 'AJ' },
    { word: 'perniciously', word_class: 'AV' },
    { word: 'perniciousness', word_class: 'N' }
  ],
  booster: [
    { word: 'boost', word_class: 'N' },
    { word: 'boost', word_class: 'V' },
    { word: 'booster', word_class: 'N' },
    { word: 'boosters', word_class: 'N' }
  ],
  purely: [
    { word: 'puree', word_class: 'V' },
    { word: 'puree', word_class: 'N' },
    { word: 'pure', word_class: 'AJ' },
    { word: 'purely', word_class: 'AV' },
    { word: 'pureness', word_class: 'N' }
  ],
  trembling: [
    { word: 'tremble', word_class: 'N' },
    { word: 'tremble', word_class: 'V' },
    { word: 'trembles', word_class: 'N' },
    { word: 'trembly', word_class: 'AJ' },
    { word: 'trembling', word_class: 'N' },
    { word: 'trembling', word_class: 'AJ' }
  ],
  tracker: [
    { word: 'track', word_class: 'N' },
    { word: 'track', word_class: 'V' },
    { word: 'tracker', word_class: 'N' },
    { word: 'tracked', word_class: 'AJ' },
    { word: 'tracking', word_class: 'N' }
  ],
  bacteroidal: [
    { word: 'bacteroid', word_class: 'N' },
    { word: 'bacteroid', word_class: 'AJ' },
    { word: 'bacteroides', word_class: 'N' },
    { word: 'bacteroidal', word_class: 'AJ' }
  ],
  visualized: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  cheating: [
    { word: 'cheat', word_class: 'N' },
    { word: 'cheat', word_class: 'V' },
    { word: 'cheater', word_class: 'N' },
    { word: 'cheating', word_class: 'N' },
    { word: 'cheating', word_class: 'AJ' }
  ],
  reticent: [
    { word: 'reticence', word_class: 'N' },
    { word: 'reticent', word_class: 'AJ' },
    { word: 'reticently', word_class: 'AV' }
  ],
  appreciated: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  audaciousness: [
    { word: 'audacious', word_class: 'AJ' },
    { word: 'audaciously', word_class: 'AV' },
    { word: 'audaciousness', word_class: 'N' }
  ],
  wiggly: [
    { word: 'wiggle', word_class: 'N' },
    { word: 'wiggle', word_class: 'V' },
    { word: 'wiggler', word_class: 'N' },
    { word: 'wiggly', word_class: 'AJ' },
    { word: 'wiggling', word_class: 'AJ' },
    { word: 'wiggliness', word_class: 'N' }
  ],
  unanimated: [
    { word: 'unanimity', word_class: 'N' },
    { word: 'unanimous', word_class: 'AJ' },
    { word: 'unanimated', word_class: 'AJ' },
    { word: 'unanimously', word_class: 'AV' }
  ],
  donate: [
    { word: 'donate', word_class: 'V' },
    { word: 'donated', word_class: 'AJ' },
    { word: 'donation', word_class: 'N' }
  ],
  prohibitively: [
    { word: 'prohibit', word_class: 'V' },
    { word: 'prohibition', word_class: 'N' },
    { word: 'prohibited', word_class: 'AJ' },
    { word: 'prohibitive', word_class: 'AJ' },
    { word: 'prohibitively', word_class: 'AV' }
  ],
  presage: [
    { word: 'presage', word_class: 'N' },
    { word: 'presage', word_class: 'V' },
    { word: 'presage', word_class: 'AJ' }
  ],
  wilt: [
    { word: 'wilt', word_class: 'N' },
    { word: 'wilt', word_class: 'V' },
    { word: 'wilted', word_class: 'AJ' },
    { word: 'wilting', word_class: 'N' }
  ],
  anatomical: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  independency: [
    { word: 'independent', word_class: 'N' },
    { word: 'independence', word_class: 'N' },
    { word: 'independent', word_class: 'AJ' },
    { word: 'independency', word_class: 'N' },
    { word: 'independently', word_class: 'AV' }
  ],
  contiguity: [
    { word: 'contiguity', word_class: 'N' },
    { word: 'contiguous', word_class: 'AJ' },
    { word: 'contiguousness', word_class: 'N' }
  ],
  inhabitant: [
    { word: 'inhabit', word_class: 'N' },
    { word: 'inhabit', word_class: 'V' },
    { word: 'inhabitant', word_class: 'N' },
    { word: 'inhabited', word_class: 'AJ' },
    { word: 'inhabitancy', word_class: 'N' },
    { word: 'inhabitable', word_class: 'AJ' },
    { word: 'inhabitation', word_class: 'N' }
  ],
  individuation: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  tusk: [{ word: 'tusk', word_class: 'N' }, { word: 'tusk', word_class: 'V' }, { word: 'tusked', word_class: 'AJ' }],
  permissibility: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  picker: [
    { word: 'pick', word_class: 'N' },
    { word: 'pick', word_class: 'V' },
    { word: 'picker', word_class: 'N' },
    { word: 'picking', word_class: 'N' }
  ],
  pottery: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  serialism: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  definitive: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  unsnarl: [
    { word: 'unsnarl', word_class: 'V' },
    { word: 'unsnarled', word_class: 'AJ' },
    { word: 'unsnarling', word_class: 'N' }
  ],
  strangle: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  diverseness: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  allergist: [
    { word: 'allergy', word_class: 'N' },
    { word: 'allergic', word_class: 'AJ' },
    { word: 'allergist', word_class: 'N' }
  ],
  accretive: [
    { word: 'accrete', word_class: 'V' },
    { word: 'accretion', word_class: 'N' },
    { word: 'accretive', word_class: 'AJ' }
  ],
  frill: [
    { word: 'frill', word_class: 'N' },
    { word: 'frills', word_class: 'N' },
    { word: 'frilled', word_class: 'AJ' }
  ],
  tabularize: [
    { word: 'table', word_class: 'N' },
    { word: 'table', word_class: 'V' },
    { word: 'tableful', word_class: 'N' },
    { word: 'tabular', word_class: 'AJ' },
    { word: 'tableful', word_class: 'AJ' },
    { word: 'tabularize', word_class: 'V' }
  ],
  embossment: [
    { word: 'emboss', word_class: 'V' },
    { word: 'embossed', word_class: 'AJ' },
    { word: 'embossment', word_class: 'N' }
  ],
  depravation: [
    { word: 'deprave', word_class: 'V' },
    { word: 'depraved', word_class: 'AJ' },
    { word: 'depravity', word_class: 'N' },
    { word: 'depravation', word_class: 'N' }
  ],
  missing: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  catabolic: [
    { word: 'catabolic', word_class: 'AJ' },
    { word: 'catabolism', word_class: 'N' },
    { word: 'catabolize', word_class: 'V' }
  ],
  bicker: [
    { word: 'bicker', word_class: 'N' },
    { word: 'bicker', word_class: 'V' },
    { word: 'bickering', word_class: 'N' }
  ],
  cognition: [
    { word: 'cognition', word_class: 'N' },
    { word: 'cognitive', word_class: 'AJ' },
    { word: 'cognitively', word_class: 'AV' }
  ],
  beggar: [
    { word: 'beg', word_class: 'V' },
    { word: 'beggar', word_class: 'N' },
    { word: 'beggar', word_class: 'V' },
    { word: 'beggary', word_class: 'N' },
    { word: 'begging', word_class: 'N' },
    { word: 'beggary', word_class: 'AJ' },
    { word: 'beggarman', word_class: 'N' }
  ],
  pinioned: [
    { word: 'pinion', word_class: 'N' },
    { word: 'pinion', word_class: 'V' },
    { word: 'pinioned', word_class: 'AJ' }
  ],
  prevention: [
    { word: 'prevent', word_class: 'N' },
    { word: 'prevent', word_class: 'V' },
    { word: 'prevention', word_class: 'N' },
    { word: 'preventive', word_class: 'N' },
    { word: 'preventive', word_class: 'AJ' },
    { word: 'preventable', word_class: 'AJ' },
    { word: 'preventative', word_class: 'N' },
    { word: 'preventative', word_class: 'AJ' }
  ],
  lineation: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  gay: [
    { word: 'gay', word_class: 'N' },
    { word: 'gay', word_class: 'AJ' },
    { word: 'gaiety', word_class: 'N' },
    { word: 'gaily', word_class: 'AV' }
  ],
  consummated: [
    { word: 'consummate', word_class: 'V' },
    { word: 'consummate', word_class: 'AJ' },
    { word: 'consummated', word_class: 'AJ' },
    { word: 'consummation', word_class: 'N' }
  ],
  shocked: [
    { word: 'shock', word_class: 'N' },
    { word: 'shock', word_class: 'V' },
    { word: 'shocker', word_class: 'N' },
    { word: 'shocked', word_class: 'AJ' },
    { word: 'shocking', word_class: 'AJ' }
  ],
  militaristic: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  abstention: [
    { word: 'abstain', word_class: 'V' },
    { word: 'abstainer', word_class: 'N' },
    { word: 'abstinent', word_class: 'N' },
    { word: 'abstention', word_class: 'N' },
    { word: 'abstinence', word_class: 'N' },
    { word: 'abstinent', word_class: 'AJ' }
  ],
  solid: [
    { word: 'solid', word_class: 'N' },
    { word: 'solid', word_class: 'AJ' },
    { word: 'solidity', word_class: 'N' },
    { word: 'solidness', word_class: 'N' }
  ],
  paperback: [
    { word: 'paperback', word_class: 'N' },
    { word: 'paperback', word_class: 'AJ' },
    { word: 'paperbacked', word_class: 'AJ' }
  ],
  flecked: [
    { word: 'fleck', word_class: 'N' },
    { word: 'fleck', word_class: 'V' },
    { word: 'flecked', word_class: 'AJ' }
  ],
  tensional: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  purging: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  exuberate: [
    { word: 'exuberate', word_class: 'V' },
    { word: 'exuberance', word_class: 'N' },
    { word: 'exuberant', word_class: 'AJ' },
    { word: 'exuberance', word_class: 'AJ' },
    { word: 'exuberation', word_class: 'N' }
  ],
  sceptic: [
    { word: 'sceptic', word_class: 'N' },
    { word: 'sceptical', word_class: 'AJ' },
    { word: 'scepticism', word_class: 'N' },
    { word: 'sceptically', word_class: 'AV' }
  ],
  motorman: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  inconclusively: [
    { word: 'inconclusive', word_class: 'AJ' },
    { word: 'inconclusively', word_class: 'AV' },
    { word: 'inconclusiveness', word_class: 'N' }
  ],
  calumnious: [
    { word: 'calumny', word_class: 'N' },
    { word: 'calumniate', word_class: 'V' },
    { word: 'calumnious', word_class: 'AJ' },
    { word: 'calumniation', word_class: 'N' }
  ],
  contuse: [
    { word: 'contuse', word_class: 'V' },
    { word: 'contused', word_class: 'AJ' },
    { word: 'contusion', word_class: 'N' },
    { word: 'contusioned', word_class: 'AJ' }
  ],
  sail: [
    { word: 'sail', word_class: 'N' },
    { word: 'sail', word_class: 'V' },
    { word: 'sailor', word_class: 'N' },
    { word: 'sailing', word_class: 'N' },
    { word: 'sailing', word_class: 'AJ' }
  ],
  swindler: [
    { word: 'swindle', word_class: 'N' },
    { word: 'swindle', word_class: 'V' },
    { word: 'swindler', word_class: 'N' }
  ],
  accomplishment: [
    { word: 'accomplish', word_class: 'V' },
    { word: 'accomplished', word_class: 'AJ' },
    { word: 'accomplishment', word_class: 'N' },
    { word: 'accomplishable', word_class: 'AJ' }
  ],
  bomb: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  uninitiate: [
    { word: 'uninitiate', word_class: 'N' },
    { word: 'uninitiate', word_class: 'AJ' },
    { word: 'uninitiated', word_class: 'AJ' }
  ],
  sequence: [
    { word: 'sequence', word_class: 'N' },
    { word: 'sequence', word_class: 'V' },
    { word: 'sequential', word_class: 'AJ' },
    { word: 'sequentially', word_class: 'AV' }
  ],
  necked: [
    { word: 'neck', word_class: 'N' },
    { word: 'neck', word_class: 'V' },
    { word: 'necked', word_class: 'AJ' },
    { word: 'necking', word_class: 'N' }
  ],
  filamentous: [
    { word: 'filament', word_class: 'N' },
    { word: 'filamentary', word_class: 'AJ' },
    { word: 'filamentous', word_class: 'AJ' }
  ],
  servilely: [
    { word: 'servile', word_class: 'AJ' },
    { word: 'servility', word_class: 'N' },
    { word: 'servilely', word_class: 'AV' }
  ],
  warmed: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  impulsion: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  matching: [
    { word: 'match', word_class: 'N' },
    { word: 'match', word_class: 'V' },
    { word: 'matched', word_class: 'AJ' },
    { word: 'matching', word_class: 'AJ' }
  ],
  undesiring: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  convolution: [
    { word: 'convolve', word_class: 'V' },
    { word: 'convolute', word_class: 'V' },
    { word: 'convolute', word_class: 'AJ' },
    { word: 'convoluted', word_class: 'AJ' },
    { word: 'convolution', word_class: 'N' }
  ],
  psychically: [
    { word: 'psychic', word_class: 'N' },
    { word: 'psychic', word_class: 'AJ' },
    { word: 'psychical', word_class: 'AJ' },
    { word: 'psychically', word_class: 'AV' }
  ],
  incurved: [
    { word: 'incurvate', word_class: 'V' },
    { word: 'incurved', word_class: 'AJ' },
    { word: 'incurvate', word_class: 'AJ' },
    { word: 'incurvation', word_class: 'N' },
    { word: 'incurvature', word_class: 'N' }
  ],
  reverberant: [
    { word: 'reverberate', word_class: 'V' },
    { word: 'reverberance', word_class: 'N' },
    { word: 'reverberant', word_class: 'AJ' },
    { word: 'reverberation', word_class: 'N' },
    { word: 'reverberating', word_class: 'AJ' }
  ],
  digester: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  polymorphism: [
    { word: 'polymorph', word_class: 'N' },
    { word: 'polymorphic', word_class: 'AJ' },
    { word: 'polymorphism', word_class: 'N' },
    { word: 'polymorphous', word_class: 'AJ' }
  ],
  traverse: [
    { word: 'traverse', word_class: 'N' },
    { word: 'traverse', word_class: 'V' },
    { word: 'traversal', word_class: 'N' },
    { word: 'traversable', word_class: 'AJ' }
  ],
  lushness: [
    { word: 'lush', word_class: 'N' },
    { word: 'lush', word_class: 'AJ' },
    { word: 'lushness', word_class: 'N' }
  ],
  goal: [
    { word: 'go', word_class: 'N' },
    { word: 'go', word_class: 'V' },
    { word: 'go', word_class: 'AJ' },
    { word: 'goal', word_class: 'N' },
    { word: 'goer', word_class: 'N' },
    { word: 'going', word_class: 'N' },
    { word: 'gone', word_class: 'AJ' },
    { word: 'going', word_class: 'AJ' }
  ],
  craft: [{ word: 'craft', word_class: 'N' }, { word: 'craft', word_class: 'V' }, { word: 'crafter', word_class: 'N' }],
  computerization: [
    { word: 'computerise', word_class: 'V' },
    { word: 'computerize', word_class: 'V' },
    { word: 'computerized', word_class: 'AJ' },
    { word: 'computerisation', word_class: 'N' },
    { word: 'computerization', word_class: 'N' }
  ],
  homelessness: [
    { word: 'homeless', word_class: 'N' },
    { word: 'homeless', word_class: 'AJ' },
    { word: 'homelessness', word_class: 'N' }
  ],
  wane: [
    { word: 'wan', word_class: 'N' },
    { word: 'wan', word_class: 'V' },
    { word: 'wane', word_class: 'N' },
    { word: 'wane', word_class: 'V' },
    { word: 'wan', word_class: 'AJ' },
    { word: 'waning', word_class: 'N' },
    { word: 'waning', word_class: 'AJ' },
    { word: 'wanness', word_class: 'N' }
  ],
  tax: [
    { word: 'tax', word_class: 'N' },
    { word: 'tax', word_class: 'V' },
    { word: 'taxman', word_class: 'N' },
    { word: 'taxing', word_class: 'AJ' },
    { word: 'taxation', word_class: 'N' }
  ],
  legitimisation: [
    { word: 'legitimatise', word_class: 'V' },
    { word: 'legitimatize', word_class: 'V' },
    { word: 'legitimisation', word_class: 'N' },
    { word: 'legitimatisation', word_class: 'N' },
    { word: 'legitimatization', word_class: 'N' }
  ],
  digging: [
    { word: 'dig', word_class: 'N' },
    { word: 'dig', word_class: 'V' },
    { word: 'digs', word_class: 'N' },
    { word: 'digging', word_class: 'N' },
    { word: 'diggings', word_class: 'N' }
  ],
  splintering: [
    { word: 'splinter', word_class: 'N' },
    { word: 'splinter', word_class: 'V' },
    { word: 'splinters', word_class: 'N' },
    { word: 'splintered', word_class: 'AJ' },
    { word: 'splintering', word_class: 'N' }
  ],
  positivistic: [
    { word: 'positivist', word_class: 'N' },
    { word: 'positivist', word_class: 'AJ' },
    { word: 'positivistic', word_class: 'AJ' }
  ],
  provision: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  tile: [
    { word: 'tile', word_class: 'N' },
    { word: 'tile', word_class: 'V' },
    { word: 'tiled', word_class: 'AJ' },
    { word: 'tiling', word_class: 'N' }
  ],
  desperate: [
    { word: 'despair', word_class: 'N' },
    { word: 'despair', word_class: 'V' },
    { word: 'desperate', word_class: 'N' },
    { word: 'desperate', word_class: 'AJ' },
    { word: 'despairing', word_class: 'AJ' },
    { word: 'desperation', word_class: 'N' },
    { word: 'desperately', word_class: 'AV' }
  ],
  tweeter: [
    { word: 'tweet', word_class: 'N' },
    { word: 'tweet', word_class: 'V' },
    { word: 'tweeter', word_class: 'N' }
  ],
  helpful: [
    { word: 'help', word_class: 'N' },
    { word: 'help', word_class: 'V' },
    { word: 'helper', word_class: 'N' },
    { word: 'helping', word_class: 'N' },
    { word: 'helpful', word_class: 'AJ' },
    { word: 'helpfulness', word_class: 'N' }
  ],
  deface: [
    { word: 'deface', word_class: 'V' },
    { word: 'defaced', word_class: 'AJ' },
    { word: 'defacement', word_class: 'N' }
  ],
  preterite: [
    { word: 'preterit', word_class: 'N' },
    { word: 'preterit', word_class: 'AJ' },
    { word: 'preterite', word_class: 'N' },
    { word: 'preterite', word_class: 'AJ' },
    { word: 'preterition', word_class: 'N' }
  ],
  hangman: [
    { word: 'hang', word_class: 'N' },
    { word: 'hang', word_class: 'V' },
    { word: 'hanger', word_class: 'N' },
    { word: 'hanging', word_class: 'N' },
    { word: 'hanging', word_class: 'V' },
    { word: 'hangman', word_class: 'N' },
    { word: 'hanging', word_class: 'AJ' }
  ],
  twirler: [
    { word: 'twirl', word_class: 'N' },
    { word: 'twirl', word_class: 'V' },
    { word: 'twirler', word_class: 'N' }
  ],
  pocketful: [
    { word: 'pocket', word_class: 'N' },
    { word: 'pocket', word_class: 'V' },
    { word: 'pocketful', word_class: 'N' },
    { word: 'pocketful', word_class: 'AJ' }
  ],
  imperially: [
    { word: 'imperial', word_class: 'N' },
    { word: 'imperial', word_class: 'AJ' },
    { word: 'imperious', word_class: 'AJ' },
    { word: 'imperialism', word_class: 'N' },
    { word: 'imperially', word_class: 'AV' },
    { word: 'imperiously', word_class: 'AV' },
    { word: 'imperiousness', word_class: 'N' }
  ],
  perturb: [
    { word: 'perturb', word_class: 'V' },
    { word: 'perturbate', word_class: 'V' },
    { word: 'perturbed', word_class: 'AJ' },
    { word: 'perturbing', word_class: 'AJ' },
    { word: 'perturbation', word_class: 'N' }
  ],
  furnished: [
    { word: 'furnish', word_class: 'V' },
    { word: 'furnished', word_class: 'AJ' },
    { word: 'furnishing', word_class: 'N' },
    { word: 'furnishings', word_class: 'N' }
  ],
  hammering: [
    { word: 'hammer', word_class: 'N' },
    { word: 'hammer', word_class: 'V' },
    { word: 'hammered', word_class: 'AJ' },
    { word: 'hammering', word_class: 'N' }
  ],
  undependable: [
    { word: 'undependable', word_class: 'AJ' },
    { word: 'undependably', word_class: 'AV' },
    { word: 'undependability', word_class: 'N' },
    { word: 'undependableness', word_class: 'N' }
  ],
  moralization: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  cycloidal: [
    { word: 'cycloid', word_class: 'N' },
    { word: 'cycloid', word_class: 'AJ' },
    { word: 'cycloidal', word_class: 'AJ' }
  ],
  calligraphic: [
    { word: 'calligraph', word_class: 'N' },
    { word: 'calligrapher', word_class: 'N' },
    { word: 'calligraphic', word_class: 'AJ' },
    { word: 'calligraphical', word_class: 'AJ' }
  ],
  disproportion: [
    { word: 'disproportion', word_class: 'N' },
    { word: 'disproportion', word_class: 'V' },
    { word: 'disproportional', word_class: 'AJ' },
    { word: 'disproportionate', word_class: 'AJ' },
    { word: 'disproportionment', word_class: 'N' },
    { word: 'disproportionately', word_class: 'AV' }
  ],
  sociality: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  achievability: [
    { word: 'achieve', word_class: 'V' },
    { word: 'achiever', word_class: 'N' },
    { word: 'achievable', word_class: 'AJ' },
    { word: 'achievement', word_class: 'N' },
    { word: 'achievability', word_class: 'N' }
  ],
  cavaly: [
    { word: 'cavaly', word_class: 'AJ' },
    { word: 'cavalier', word_class: 'N' },
    { word: 'cavalier', word_class: 'AJ' }
  ],
  recognize: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  cortical: [
    { word: 'cortical', word_class: 'AJ' },
    { word: 'corticate', word_class: 'V' },
    { word: 'cortically', word_class: 'AV' },
    { word: 'cortication', word_class: 'N' }
  ],
  adjuster: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  vague: [
    { word: 'vagus', word_class: 'N' },
    { word: 'vague', word_class: 'AJ' },
    { word: 'vaguely', word_class: 'AV' },
    { word: 'vagueness', word_class: 'N' }
  ],
  aspire: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  expended: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  crosscut: [
    { word: 'crosscut', word_class: 'N' },
    { word: 'crosscut', word_class: 'V' },
    { word: 'crosscut', word_class: 'AJ' }
  ],
  authenticated: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  frilled: [
    { word: 'frill', word_class: 'N' },
    { word: 'frills', word_class: 'N' },
    { word: 'frilled', word_class: 'AJ' }
  ],
  scrubbed: [
    { word: 'scrub', word_class: 'N' },
    { word: 'scrub', word_class: 'V' },
    { word: 'scrub', word_class: 'AJ' },
    { word: 'scrubs', word_class: 'N' },
    { word: 'scrubbed', word_class: 'AJ' },
    { word: 'scrubbing', word_class: 'N' }
  ],
  abyssal: [
    { word: 'abysm', word_class: 'N' },
    { word: 'abyss', word_class: 'N' },
    { word: 'abysmal', word_class: 'AJ' },
    { word: 'abyssal', word_class: 'AJ' },
    { word: 'abysmally', word_class: 'AV' }
  ],
  theologizer: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  manual: [
    { word: 'manual', word_class: 'N' },
    { word: 'manual', word_class: 'AJ' },
    { word: 'manually', word_class: 'AV' }
  ],
  carpet: [
    { word: 'carpet', word_class: 'N' },
    { word: 'carpet', word_class: 'V' },
    { word: 'carpeted', word_class: 'AJ' },
    { word: 'carpeting', word_class: 'N' }
  ],
  internationalise: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  adjudicate: [
    { word: 'adjudicate', word_class: 'V' },
    { word: 'adjudicator', word_class: 'N' },
    { word: 'adjudication', word_class: 'N' },
    { word: 'adjudicative', word_class: 'AJ' }
  ],
  sift: [{ word: 'sift', word_class: 'V' }, { word: 'sifter', word_class: 'N' }, { word: 'sifting', word_class: 'N' }],
  kaleidoscopic: [
    { word: 'kaleidoscope', word_class: 'N' },
    { word: 'kaleidoscopic', word_class: 'AJ' },
    { word: 'kaleidoscopical', word_class: 'AJ' }
  ],
  ruined: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  disregarded: [
    { word: 'disregard', word_class: 'N' },
    { word: 'disregard', word_class: 'V' },
    { word: 'disregarded', word_class: 'AJ' },
    { word: 'disregarding', word_class: 'AV' }
  ],
  slur: [{ word: 'slur', word_class: 'N' }, { word: 'slur', word_class: 'V' }, { word: 'slurred', word_class: 'AJ' }],
  mercurial: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  carboxylate: [
    { word: 'carboxyl', word_class: 'N' },
    { word: 'carboxyl', word_class: 'AJ' },
    { word: 'carboxylate', word_class: 'V' },
    { word: 'carboxylic', word_class: 'AJ' },
    { word: 'carboxylation', word_class: 'N' }
  ],
  branding: [
    { word: 'brand', word_class: 'N' },
    { word: 'brand', word_class: 'V' },
    { word: 'branded', word_class: 'AJ' },
    { word: 'branding', word_class: 'N' }
  ],
  purported: [
    { word: 'purport', word_class: 'N' },
    { word: 'purport', word_class: 'V' },
    { word: 'purported', word_class: 'AJ' }
  ],
  immutable: [
    { word: 'immutable', word_class: 'AJ' },
    { word: 'immutably', word_class: 'AV' },
    { word: 'immutability', word_class: 'N' },
    { word: 'immutableness', word_class: 'N' }
  ],
  disappointed: [
    { word: 'disappoint', word_class: 'V' },
    { word: 'disappointed', word_class: 'AJ' },
    { word: 'disappointing', word_class: 'N' },
    { word: 'disappointing', word_class: 'AJ' },
    { word: 'disappointment', word_class: 'N' }
  ],
  mousing: [
    { word: 'mouse', word_class: 'N' },
    { word: 'mouse', word_class: 'V' },
    { word: 'mousy', word_class: 'AJ' },
    { word: 'mousing', word_class: 'N' }
  ],
  ulcerated: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  changing: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  fractionize: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  uplifting: [
    { word: 'uplift', word_class: 'N' },
    { word: 'uplift', word_class: 'V' },
    { word: 'uplifted', word_class: 'AJ' },
    { word: 'uplifting', word_class: 'N' },
    { word: 'upliftment', word_class: 'N' }
  ],
  enquirer: [
    { word: 'enquire', word_class: 'V' },
    { word: 'enquiry', word_class: 'N' },
    { word: 'enquirer', word_class: 'N' }
  ],
  transmittable: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  awakened: [
    { word: 'awaken', word_class: 'V' },
    { word: 'awakened', word_class: 'AJ' },
    { word: 'awakening', word_class: 'N' }
  ],
  pursuant: [
    { word: 'pursue', word_class: 'V' },
    { word: 'pursued', word_class: 'N' },
    { word: 'pursuer', word_class: 'N' },
    { word: 'pursuit', word_class: 'N' },
    { word: 'pursuant', word_class: 'N' },
    { word: 'pursued', word_class: 'AJ' },
    { word: 'pursuance', word_class: 'N' },
    { word: 'pursuant', word_class: 'AJ' },
    { word: 'pursuing', word_class: 'AJ' }
  ],
  rurally: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  voiding: [
    { word: 'void', word_class: 'N' },
    { word: 'void', word_class: 'V' },
    { word: 'void', word_class: 'AJ' },
    { word: 'voiding', word_class: 'N' },
    { word: 'voidance', word_class: 'N' }
  ],
  attributable: [
    { word: 'attribute', word_class: 'N' },
    { word: 'attribute', word_class: 'V' },
    { word: 'attribution', word_class: 'N' },
    { word: 'attributive', word_class: 'AJ' },
    { word: 'attributable', word_class: 'AJ' },
    { word: 'attributively', word_class: 'AV' }
  ],
  collectivization: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  despotical: [
    { word: 'despot', word_class: 'N' },
    { word: 'despot', word_class: 'V' },
    { word: 'despotic', word_class: 'AJ' },
    { word: 'despotism', word_class: 'N' },
    { word: 'despotical', word_class: 'AJ' }
  ],
  congregate: [
    { word: 'congregate', word_class: 'V' },
    { word: 'congregate', word_class: 'AJ' },
    { word: 'congregating', word_class: 'N' },
    { word: 'congregation', word_class: 'N' },
    { word: 'congregational', word_class: 'AJ' }
  ],
  reprieve: [
    { word: 'reprieve', word_class: 'N' },
    { word: 'reprieve', word_class: 'V' },
    { word: 'reprievement', word_class: 'N' }
  ],
  thanks: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  trapping: [
    { word: 'trap', word_class: 'N' },
    { word: 'trap', word_class: 'V' },
    { word: 'trapped', word_class: 'AJ' },
    { word: 'trapping', word_class: 'N' },
    { word: 'trappings', word_class: 'N' }
  ],
  refusal: [
    { word: 'refuse', word_class: 'N' },
    { word: 'refuse', word_class: 'V' },
    { word: 'refusal', word_class: 'N' }
  ],
  interpreting: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  conniving: [
    { word: 'connive', word_class: 'V' },
    { word: 'connivance', word_class: 'N' },
    { word: 'conniving', word_class: 'AJ' }
  ],
  chiseller: [
    { word: 'chisel', word_class: 'N' },
    { word: 'chisel', word_class: 'V' },
    { word: 'chiseler', word_class: 'N' },
    { word: 'chiseled', word_class: 'AJ' },
    { word: 'chiseller', word_class: 'N' }
  ],
  pinning: [
    { word: 'pin', word_class: 'N' },
    { word: 'pin', word_class: 'V' },
    { word: 'pinned', word_class: 'AJ' },
    { word: 'pinning', word_class: 'N' }
  ],
  delight: [
    { word: 'delight', word_class: 'N' },
    { word: 'delight', word_class: 'V' },
    { word: 'delighted', word_class: 'AJ' },
    { word: 'delightful', word_class: 'AJ' }
  ],
  plunderage: [
    { word: 'plunder', word_class: 'N' },
    { word: 'plunder', word_class: 'V' },
    { word: 'plunderer', word_class: 'N' },
    { word: 'plunderage', word_class: 'N' },
    { word: 'plundered', word_class: 'AJ' },
    { word: 'plundering', word_class: 'N' },
    { word: 'plundering', word_class: 'AJ' }
  ],
  abducted: [
    { word: 'abduct', word_class: 'V' },
    { word: 'abductor', word_class: 'N' },
    { word: 'abducted', word_class: 'AJ' },
    { word: 'abduction', word_class: 'N' },
    { word: 'abducting', word_class: 'AJ' }
  ],
  planet: [
    { word: 'planet', word_class: 'N' },
    { word: 'planetal', word_class: 'AJ' },
    { word: 'planetoid', word_class: 'N' },
    { word: 'planetary', word_class: 'AJ' }
  ],
  contract: [
    { word: 'contract', word_class: 'N' },
    { word: 'contract', word_class: 'V' },
    { word: 'contractor', word_class: 'N' },
    { word: 'contraction', word_class: 'N' },
    { word: 'contracting', word_class: 'N' },
    { word: 'contracted', word_class: 'AJ' },
    { word: 'contractable', word_class: 'AJ' }
  ],
  acidulousness: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  undesirable: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  doleful: [
    { word: 'dole', word_class: 'N' },
    { word: 'dole', word_class: 'V' },
    { word: 'doleful', word_class: 'AJ' },
    { word: 'dolefulness', word_class: 'N' }
  ],
  antecessor: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  opulent: [
    { word: 'opulence', word_class: 'N' },
    { word: 'opulent', word_class: 'AJ' },
    { word: 'opulently', word_class: 'AV' }
  ],
  linguist: [
    { word: 'linguist', word_class: 'N' },
    { word: 'linguistic', word_class: 'AJ' },
    { word: 'linguistics', word_class: 'N' },
    { word: 'linguistically', word_class: 'AV' }
  ],
  taint: [
    { word: 'taint', word_class: 'N' },
    { word: 'taint', word_class: 'V' },
    { word: 'tainted', word_class: 'AJ' }
  ],
  acculturation: [
    { word: 'acculturate', word_class: 'V' },
    { word: 'acculturation', word_class: 'N' },
    { word: 'acculturative', word_class: 'AJ' },
    { word: 'acculturational', word_class: 'AJ' }
  ],
  acidity: [
    { word: 'acid', word_class: 'N' },
    { word: 'acid', word_class: 'AJ' },
    { word: 'acidic', word_class: 'AJ' },
    { word: 'acidity', word_class: 'N' }
  ],
  aids: [
    { word: 'aid', word_class: 'N' },
    { word: 'aid', word_class: 'V' },
    { word: 'aide', word_class: 'N' },
    { word: 'aids', word_class: 'N' },
    { word: 'aides', word_class: 'N' },
    { word: 'aided', word_class: 'AJ' }
  ],
  ephemerality: [
    { word: 'ephemeral', word_class: 'AJ' },
    { word: 'ephemerality', word_class: 'N' },
    { word: 'ephemeralness', word_class: 'N' }
  ],
  inclining: [
    { word: 'incline', word_class: 'N' },
    { word: 'incline', word_class: 'V' },
    { word: 'inclined', word_class: 'AJ' },
    { word: 'inclining', word_class: 'N' },
    { word: 'inclination', word_class: 'N' }
  ],
  certification: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  canted: [
    { word: 'cant', word_class: 'N' },
    { word: 'cant', word_class: 'V' },
    { word: 'cant', word_class: 'AJ' },
    { word: 'cantor', word_class: 'N' },
    { word: 'canted', word_class: 'AJ' }
  ],
  embezzler: [
    { word: 'embezzle', word_class: 'V' },
    { word: 'embezzler', word_class: 'N' },
    { word: 'embezzled', word_class: 'AJ' },
    { word: 'embezzlement', word_class: 'N' }
  ],
  spayed: [
    { word: 'spay', word_class: 'V' },
    { word: 'spay', word_class: 'AJ' },
    { word: 'spayed', word_class: 'AJ' },
    { word: 'spaying', word_class: 'N' }
  ],
  unknowing: [
    { word: 'unknowing', word_class: 'N' },
    { word: 'unknowing', word_class: 'AJ' },
    { word: 'unknowable', word_class: 'AJ' }
  ],
  baldy: [
    { word: 'bald', word_class: 'V' },
    { word: 'bald', word_class: 'AJ' },
    { word: 'baldy', word_class: 'N' },
    { word: 'balding', word_class: 'AJ' },
    { word: 'baldness', word_class: 'N' }
  ],
  acoustically: [
    { word: 'acoustic', word_class: 'N' },
    { word: 'acoustic', word_class: 'AJ' },
    { word: 'acoustics', word_class: 'N' },
    { word: 'acoustical', word_class: 'AJ' },
    { word: 'acoustically', word_class: 'AV' }
  ],
  argentous: [
    { word: 'argent', word_class: 'N' },
    { word: 'argent', word_class: 'AJ' },
    { word: 'argentic', word_class: 'AJ' },
    { word: 'argentous', word_class: 'AJ' }
  ],
  entwinement: [
    { word: 'entwine', word_class: 'V' },
    { word: 'entwinement', word_class: 'N' },
    { word: 'entwintwine', word_class: 'V' },
    { word: 'entwintwinement', word_class: 'N' }
  ],
  rapaciousness: [
    { word: 'rapacious', word_class: 'AJ' },
    { word: 'rapaciously', word_class: 'AV' },
    { word: 'rapaciousness', word_class: 'N' }
  ],
  jabber: [
    { word: 'jabber', word_class: 'N' },
    { word: 'jabber', word_class: 'V' },
    { word: 'jabberer', word_class: 'N' },
    { word: 'jabbering', word_class: 'N' },
    { word: 'jabbering', word_class: 'AJ' }
  ],
  striated: [
    { word: 'striate', word_class: 'V' },
    { word: 'striate', word_class: 'AJ' },
    { word: 'striated', word_class: 'AJ' },
    { word: 'striation', word_class: 'N' }
  ],
  vulgarisation: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  forbidding: [
    { word: 'forbid', word_class: 'V' },
    { word: 'forbid', word_class: 'AJ' },
    { word: 'forbidden', word_class: 'AJ' },
    { word: 'forbidding', word_class: 'N' },
    { word: 'forbiddance', word_class: 'N' },
    { word: 'forbidding', word_class: 'AJ' }
  ],
  realize: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  infatuation: [
    { word: 'infatuate', word_class: 'V' },
    { word: 'infatuated', word_class: 'AJ' },
    { word: 'infatuation', word_class: 'N' }
  ],
  sportiveness: [
    { word: 'sportive', word_class: 'AJ' },
    { word: 'sportively', word_class: 'AV' },
    { word: 'sportiveness', word_class: 'N' }
  ],
  blockade: [
    { word: 'blockade', word_class: 'N' },
    { word: 'blockade', word_class: 'V' },
    { word: 'blockaded', word_class: 'AJ' },
    { word: 'blockading', word_class: 'AJ' }
  ],
  amativeness: [
    { word: 'am', word_class: 'N' },
    { word: 'am', word_class: 'AJ' },
    { word: 'amative', word_class: 'AJ' },
    { word: 'amativeness', word_class: 'N' }
  ],
  abrupt: [
    { word: 'abrupt', word_class: 'AJ' },
    { word: 'abruption', word_class: 'N' },
    { word: 'abruptness', word_class: 'N' }
  ],
  line: [
    { word: 'line', word_class: 'N' },
    { word: 'line', word_class: 'V' },
    { word: 'liner', word_class: 'N' },
    { word: 'lined', word_class: 'AJ' },
    { word: 'lining', word_class: 'N' },
    { word: 'lineman', word_class: 'N' },
    { word: 'lineation', word_class: 'N' }
  ],
  discretion: [
    { word: 'discrete', word_class: 'N' },
    { word: 'discrete', word_class: 'AJ' },
    { word: 'discretion', word_class: 'N' },
    { word: 'discreteness', word_class: 'N' },
    { word: 'discretional', word_class: 'AJ' }
  ],
  module: [
    { word: 'module', word_class: 'N' },
    { word: 'modular', word_class: 'AJ' },
    { word: 'modulate', word_class: 'V' },
    { word: 'modulated', word_class: 'AJ' },
    { word: 'modulation', word_class: 'N' }
  ],
  querulous: [
    { word: 'querulous', word_class: 'AJ' },
    { word: 'querulously', word_class: 'AV' },
    { word: 'querulousness', word_class: 'N' }
  ],
  idolize: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  cuff: [{ word: 'cuff', word_class: 'N' }, { word: 'cuff', word_class: 'V' }, { word: 'cuffs', word_class: 'N' }],
  enslaved: [
    { word: 'enslave', word_class: 'V' },
    { word: 'enslaved', word_class: 'AJ' },
    { word: 'enslaving', word_class: 'N' },
    { word: 'enslavement', word_class: 'N' }
  ],
  nurse: [
    { word: 'nurse', word_class: 'N' },
    { word: 'nurse', word_class: 'V' },
    { word: 'nursed', word_class: 'AJ' },
    { word: 'nursing', word_class: 'N' }
  ],
  scrabble: [
    { word: 'scrabble', word_class: 'N' },
    { word: 'scrabble', word_class: 'V' },
    { word: 'scrabbly', word_class: 'AJ' }
  ],
  pneumatically: [
    { word: 'pneumatic', word_class: 'AJ' },
    { word: 'pneumatics', word_class: 'N' },
    { word: 'pneumatically', word_class: 'AV' }
  ],
  inevitably: [
    { word: 'inevitable', word_class: 'N' },
    { word: 'inevitable', word_class: 'AJ' },
    { word: 'inevitably', word_class: 'AV' },
    { word: 'inevitability', word_class: 'N' },
    { word: 'inevitableness', word_class: 'N' }
  ],
  react: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  mortgage: [
    { word: 'mortgage', word_class: 'N' },
    { word: 'mortgage', word_class: 'V' },
    { word: 'mortgagee', word_class: 'N' },
    { word: 'mortgagor', word_class: 'N' },
    { word: 'mortgaged', word_class: 'AJ' }
  ],
  considerately: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  eloignment: [
    { word: 'eloin', word_class: 'V' },
    { word: 'eloign', word_class: 'V' },
    { word: 'eloinment', word_class: 'N' },
    { word: 'eloignment', word_class: 'N' }
  ],
  propulsive: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  closeness: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  derivable: [
    { word: 'derive', word_class: 'V' },
    { word: 'derivate', word_class: 'N' },
    { word: 'derived', word_class: 'AJ' },
    { word: 'deriving', word_class: 'N' },
    { word: 'derivation', word_class: 'N' },
    { word: 'derivable', word_class: 'AJ' },
    { word: 'derivative', word_class: 'N' },
    { word: 'derivative', word_class: 'AJ' },
    { word: 'derivational', word_class: 'AJ' }
  ],
  pressurization: [
    { word: 'press', word_class: 'N' },
    { word: 'press', word_class: 'V' },
    { word: 'pressure', word_class: 'N' },
    { word: 'pressman', word_class: 'N' },
    { word: 'pressed', word_class: 'AJ' },
    { word: 'pressure', word_class: 'V' },
    { word: 'pressing', word_class: 'N' },
    { word: 'pressing', word_class: 'AJ' },
    { word: 'pressurise', word_class: 'V' },
    { word: 'pressurize', word_class: 'V' },
    { word: 'pressurized', word_class: 'AJ' },
    { word: 'pressurisation', word_class: 'N' },
    { word: 'pressurization', word_class: 'N' }
  ],
  mars: [
    { word: 'mar', word_class: 'N' },
    { word: 'mar', word_class: 'V' },
    { word: 'mars', word_class: 'N' },
    { word: 'marred', word_class: 'AJ' }
  ],
  advert: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  bewitch: [
    { word: 'bewitch', word_class: 'V' },
    { word: 'bewitched', word_class: 'AJ' },
    { word: 'bewitching', word_class: 'AJ' },
    { word: 'bewitchment', word_class: 'N' }
  ],
  exonerate: [
    { word: 'exonerate', word_class: 'V' },
    { word: 'exonerated', word_class: 'AJ' },
    { word: 'exoneration', word_class: 'N' }
  ],
  figural: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  isolationistic: [
    { word: 'isolationist', word_class: 'N' },
    { word: 'isolationist', word_class: 'AJ' },
    { word: 'isolationistic', word_class: 'AJ' }
  ],
  incessancy: [
    { word: 'incessancy', word_class: 'N' },
    { word: 'incessant', word_class: 'AJ' },
    { word: 'incessantness', word_class: 'N' }
  ],
  perfume: [
    { word: 'perfume', word_class: 'N' },
    { word: 'perfume', word_class: 'V' },
    { word: 'perfumer', word_class: 'N' },
    { word: 'perfumed', word_class: 'AJ' },
    { word: 'perfumery', word_class: 'N' },
    { word: 'perfumery', word_class: 'AJ' }
  ],
  magniloquently: [
    { word: 'magniloquence', word_class: 'N' },
    { word: 'magniloquent', word_class: 'AJ' },
    { word: 'magniloquently', word_class: 'AV' }
  ],
  inviolate: [
    { word: 'inviolate', word_class: 'V' },
    { word: 'inviolate', word_class: 'AJ' },
    { word: 'inviolable', word_class: 'AJ' }
  ],
  handsomeness: [
    { word: 'handsome', word_class: 'AJ' },
    { word: 'handsomely', word_class: 'AV' },
    { word: 'handsomeness', word_class: 'N' }
  ],
  blondness: [
    { word: 'blond', word_class: 'N' },
    { word: 'blond', word_class: 'AJ' },
    { word: 'blonde', word_class: 'N' },
    { word: 'blonde', word_class: 'AJ' },
    { word: 'blondness', word_class: 'N' }
  ],
  submit: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  affiliated: [
    { word: 'affiliate', word_class: 'N' },
    { word: 'affiliate', word_class: 'V' },
    { word: 'affiliated', word_class: 'AJ' },
    { word: 'affiliation', word_class: 'N' }
  ],
  wronged: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  segmental: [
    { word: 'segment', word_class: 'N' },
    { word: 'segment', word_class: 'V' },
    { word: 'segmental', word_class: 'AJ' },
    { word: 'segmented', word_class: 'AJ' },
    { word: 'segmentation', word_class: 'N' }
  ],
  industrial: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  hollowed: [
    { word: 'hollow', word_class: 'N' },
    { word: 'hollow', word_class: 'V' },
    { word: 'hollow', word_class: 'AJ' },
    { word: 'hollowed', word_class: 'AJ' },
    { word: 'hollowness', word_class: 'N' }
  ],
  immunisation: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  flooded: [
    { word: 'flood', word_class: 'N' },
    { word: 'flood', word_class: 'V' },
    { word: 'flood', word_class: 'AJ' },
    { word: 'flooded', word_class: 'AJ' },
    { word: 'flooding', word_class: 'N' },
    { word: 'flooding', word_class: 'AJ' }
  ],
  reciprocative: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  hebraisation: [
    { word: 'hebraise', word_class: 'V' },
    { word: 'hebraize', word_class: 'V' },
    { word: 'hebraisation', word_class: 'N' },
    { word: 'hebraization', word_class: 'N' }
  ],
  file: [
    { word: 'file', word_class: 'N' },
    { word: 'file', word_class: 'V' },
    { word: 'filer', word_class: 'N' },
    { word: 'filing', word_class: 'N' }
  ],
  specialise: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  lister: [
    { word: 'list', word_class: 'N' },
    { word: 'list', word_class: 'V' },
    { word: 'lister', word_class: 'N' },
    { word: 'listed', word_class: 'AJ' },
    { word: 'listing', word_class: 'N' }
  ],
  contrition: [
    { word: 'contrite', word_class: 'AJ' },
    { word: 'contrition', word_class: 'N' },
    { word: 'contritely', word_class: 'AV' },
    { word: 'contriteness', word_class: 'N' }
  ],
  randomization: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  cadenced: [
    { word: 'cadence', word_class: 'N' },
    { word: 'cadency', word_class: 'N' },
    { word: 'cadenced', word_class: 'AJ' }
  ],
  hurtful: [
    { word: 'hurt', word_class: 'N' },
    { word: 'hurt', word_class: 'V' },
    { word: 'hurt', word_class: 'AJ' },
    { word: 'hurting', word_class: 'N' },
    { word: 'hurtful', word_class: 'AJ' }
  ],
  vernalisation: [
    { word: 'vernal', word_class: 'AJ' },
    { word: 'vernalise', word_class: 'V' },
    { word: 'vernalize', word_class: 'V' },
    { word: 'vernalisation', word_class: 'N' },
    { word: 'vernalization', word_class: 'N' }
  ],
  journalistically: [
    { word: 'journalist', word_class: 'N' },
    { word: 'journalistic', word_class: 'AJ' },
    { word: 'journalistically', word_class: 'AV' }
  ],
  intuitively: [
    { word: 'intuit', word_class: 'V' },
    { word: 'intuition', word_class: 'N' },
    { word: 'intuitive', word_class: 'AJ' },
    { word: 'intuitively', word_class: 'AV' }
  ],
  bead: [
    { word: 'bead', word_class: 'N' },
    { word: 'bead', word_class: 'V' },
    { word: 'beads', word_class: 'N' },
    { word: 'beaded', word_class: 'AJ' },
    { word: 'beading', word_class: 'N' }
  ],
  fur: [
    { word: 'fur', word_class: 'N' },
    { word: 'fur', word_class: 'V' },
    { word: 'furor', word_class: 'N' },
    { word: 'furore', word_class: 'N' },
    { word: 'furred', word_class: 'AJ' },
    { word: 'furring', word_class: 'N' }
  ],
  uplift: [
    { word: 'uplift', word_class: 'N' },
    { word: 'uplift', word_class: 'V' },
    { word: 'uplifted', word_class: 'AJ' },
    { word: 'uplifting', word_class: 'N' },
    { word: 'upliftment', word_class: 'N' }
  ],
  corroborant: [
    { word: 'corroborate', word_class: 'V' },
    { word: 'corroborant', word_class: 'AJ' },
    { word: 'corroborated', word_class: 'AJ' },
    { word: 'corroboration', word_class: 'N' },
    { word: 'corroborative', word_class: 'AJ' }
  ],
  shallow: [
    { word: 'shallow', word_class: 'N' },
    { word: 'shallow', word_class: 'V' },
    { word: 'shallow', word_class: 'AJ' },
    { word: 'shallowness', word_class: 'N' }
  ],
  indigestibility: [
    { word: 'indigestion', word_class: 'N' },
    { word: 'indigestible', word_class: 'AJ' },
    { word: 'indigestibility', word_class: 'N' },
    { word: 'indigestibleness', word_class: 'N' }
  ],
  respondent: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  anathematisation: [
    { word: 'anathematise', word_class: 'V' },
    { word: 'anathematize', word_class: 'V' },
    { word: 'anathematisation', word_class: 'N' },
    { word: 'anathematization', word_class: 'N' }
  ],
  coed: [{ word: 'co', word_class: 'N' }, { word: 'cos', word_class: 'N' }, { word: 'coed', word_class: 'N' }],
  delusionary: [
    { word: 'delude', word_class: 'V' },
    { word: 'delusion', word_class: 'N' },
    { word: 'delusive', word_class: 'AJ' },
    { word: 'delusively', word_class: 'AV' },
    { word: 'delusionary', word_class: 'AJ' }
  ],
  palsied: [
    { word: 'palsy', word_class: 'N' },
    { word: 'palsy', word_class: 'V' },
    { word: 'palsied', word_class: 'AJ' }
  ],
  film: [
    { word: 'film', word_class: 'N' },
    { word: 'film', word_class: 'V' },
    { word: 'filmed', word_class: 'AJ' },
    { word: 'filming', word_class: 'N' },
    { word: 'filmmaker', word_class: 'N' },
    { word: 'filmmaking', word_class: 'N' }
  ],
  chopine: [
    { word: 'chopin', word_class: 'N' },
    { word: 'chopine', word_class: 'N' },
    { word: 'chopines', word_class: 'N' }
  ],
  budget: [
    { word: 'budget', word_class: 'N' },
    { word: 'budget', word_class: 'V' },
    { word: 'budgetary', word_class: 'AJ' }
  ],
  overactive: [
    { word: 'overact', word_class: 'V' },
    { word: 'overacting', word_class: 'N' },
    { word: 'overactive', word_class: 'AJ' },
    { word: 'overactivity', word_class: 'N' }
  ],
  minors: [
    { word: 'minor', word_class: 'AJ' },
    { word: 'minors', word_class: 'N' },
    { word: 'minority', word_class: 'N' }
  ],
  swoon: [
    { word: 'swoon', word_class: 'N' },
    { word: 'swoon', word_class: 'V' },
    { word: 'swooning', word_class: 'AJ' }
  ],
  rafts: [
    { word: 'raft', word_class: 'N' },
    { word: 'raft', word_class: 'V' },
    { word: 'rafts', word_class: 'N' },
    { word: 'rafter', word_class: 'N' },
    { word: 'rafter', word_class: 'V' },
    { word: 'raftman', word_class: 'N' },
    { word: 'raftered', word_class: 'AJ' }
  ],
  rent: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  underclothing: [
    { word: 'underclothes', word_class: 'N' },
    { word: 'underclothed', word_class: 'AJ' },
    { word: 'underclothing', word_class: 'N' }
  ],
  liberated: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  bowdlerise: [
    { word: 'bowdlerise', word_class: 'V' },
    { word: 'bowdlerism', word_class: 'N' },
    { word: 'bowdlerize', word_class: 'V' },
    { word: 'bowdlerisation', word_class: 'N' },
    { word: 'bowdlerization', word_class: 'N' }
  ],
  size: [
    { word: 'size', word_class: 'N' },
    { word: 'size', word_class: 'V' },
    { word: 'size', word_class: 'AJ' },
    { word: 'sized', word_class: 'AJ' },
    { word: 'sizing', word_class: 'N' }
  ],
  negligent: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  subcontract: [
    { word: 'subcontract', word_class: 'N' },
    { word: 'subcontract', word_class: 'V' },
    { word: 'subcontractor', word_class: 'N' }
  ],
  estimator: [
    { word: 'estimate', word_class: 'N' },
    { word: 'estimate', word_class: 'V' },
    { word: 'estimator', word_class: 'N' },
    { word: 'estimable', word_class: 'AJ' },
    { word: 'estimated', word_class: 'AJ' },
    { word: 'estimation', word_class: 'N' }
  ],
  satirist: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  filtrate: [
    { word: 'filter', word_class: 'N' },
    { word: 'filter', word_class: 'V' },
    { word: 'filtrate', word_class: 'N' },
    { word: 'filtrate', word_class: 'V' },
    { word: 'filtration', word_class: 'N' }
  ],
  labeled: [
    { word: 'label', word_class: 'N' },
    { word: 'label', word_class: 'V' },
    { word: 'labeled', word_class: 'AJ' },
    { word: 'labelled', word_class: 'AJ' }
  ],
  obsessiveness: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  incisor: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  depopulate: [
    { word: 'depopulate', word_class: 'V' },
    { word: 'depopulated', word_class: 'AJ' },
    { word: 'depopulation', word_class: 'N' }
  ],
  gloating: [
    { word: 'gloat', word_class: 'N' },
    { word: 'gloat', word_class: 'V' },
    { word: 'gloating', word_class: 'N' }
  ],
  expel: [
    { word: 'expel', word_class: 'V' },
    { word: 'expell', word_class: 'V' },
    { word: 'expelling', word_class: 'N' },
    { word: 'expulsion', word_class: 'N' }
  ],
  seducer: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  isometrics: [
    { word: 'isometric', word_class: 'AJ' },
    { word: 'isometrics', word_class: 'N' },
    { word: 'isometrical', word_class: 'AJ' }
  ],
  atom: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  grovel: [
    { word: 'grovel', word_class: 'V' },
    { word: 'groveller', word_class: 'N' },
    { word: 'groveling', word_class: 'AJ' },
    { word: 'grovelling', word_class: 'AJ' }
  ],
  child: [
    { word: 'child', word_class: 'N' },
    { word: 'childbearing', word_class: 'N' },
    { word: 'childbearing', word_class: 'AJ' }
  ],
  nonsexual: [
    { word: 'asexual', word_class: 'AJ' },
    { word: 'asexuality', word_class: 'N' },
    { word: 'asexually', word_class: 'AV' },
    { word: 'nonsexual', word_class: 'AJ' }
  ],
  conservation: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  prefigure: [
    { word: 'prefigure', word_class: 'V' },
    { word: 'prefiguration', word_class: 'N' },
    { word: 'prefigurement', word_class: 'N' },
    { word: 'prefigurative', word_class: 'AJ' }
  ],
  officially: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  help: [
    { word: 'help', word_class: 'N' },
    { word: 'help', word_class: 'V' },
    { word: 'helper', word_class: 'N' },
    { word: 'helping', word_class: 'N' },
    { word: 'helpful', word_class: 'AJ' },
    { word: 'helpfulness', word_class: 'N' }
  ],
  litigant: [
    { word: 'litigant', word_class: 'N' },
    { word: 'litigate', word_class: 'V' },
    { word: 'litigator', word_class: 'N' },
    { word: 'litigation', word_class: 'N' }
  ],
  british: [
    { word: 'british', word_class: 'N' },
    { word: 'british', word_class: 'AJ' },
    { word: 'britisher', word_class: 'N' },
    { word: 'britishism', word_class: 'N' }
  ],
  vault: [
    { word: 'vault', word_class: 'N' },
    { word: 'vault', word_class: 'V' },
    { word: 'vaulter', word_class: 'N' },
    { word: 'vaulted', word_class: 'AJ' },
    { word: 'vaulting', word_class: 'N' },
    { word: 'vaulting', word_class: 'AJ' }
  ],
  relishing: [
    { word: 'relish', word_class: 'N' },
    { word: 'relish', word_class: 'V' },
    { word: 'relishing', word_class: 'N' }
  ],
  multiplied: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  lessened: [
    { word: 'lessen', word_class: 'V' },
    { word: 'lessened', word_class: 'AJ' },
    { word: 'lessening', word_class: 'N' },
    { word: 'lessening', word_class: 'AJ' }
  ],
  rescindable: [
    { word: 'rescind', word_class: 'V' },
    { word: 'rescinding', word_class: 'N' },
    { word: 'rescission', word_class: 'N' },
    { word: 'rescindment', word_class: 'N' },
    { word: 'rescindable', word_class: 'AJ' }
  ],
  misanthrope: [
    { word: 'misanthrope', word_class: 'N' },
    { word: 'misanthropic', word_class: 'AJ' },
    { word: 'misanthropical', word_class: 'AJ' }
  ],
  chaser: [
    { word: 'chase', word_class: 'N' },
    { word: 'chase', word_class: 'V' },
    { word: 'chased', word_class: 'N' },
    { word: 'chaser', word_class: 'N' }
  ],
  gadget: [
    { word: 'gadget', word_class: 'N' },
    { word: 'gadgetry', word_class: 'N' },
    { word: 'gadgeteer', word_class: 'N' }
  ],
  reversely: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  cervicitis: [
    { word: 'cervix', word_class: 'N' },
    { word: 'cervical', word_class: 'AJ' },
    { word: 'cervicitis', word_class: 'N' }
  ],
  excitative: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  tropical: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  truckle: [
    { word: 'truckle', word_class: 'N' },
    { word: 'truckle', word_class: 'V' },
    { word: 'truckling', word_class: 'N' }
  ],
  webbing: [
    { word: 'web', word_class: 'N' },
    { word: 'web', word_class: 'V' },
    { word: 'webbed', word_class: 'AJ' },
    { word: 'webbing', word_class: 'N' }
  ],
  extenuation: [
    { word: 'extenuate', word_class: 'V' },
    { word: 'extenuation', word_class: 'N' },
    { word: 'extenuating', word_class: 'AJ' }
  ],
  category: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  meninges: [
    { word: 'meninges', word_class: 'N' },
    { word: 'meningism', word_class: 'N' },
    { word: 'meningeal', word_class: 'AJ' },
    { word: 'meningitis', word_class: 'N' }
  ],
  songfulness: [
    { word: 'song', word_class: 'N' },
    { word: 'songful', word_class: 'AJ' },
    { word: 'songfulness', word_class: 'N' }
  ],
  betrayal: [
    { word: 'betray', word_class: 'V' },
    { word: 'betrayal', word_class: 'N' },
    { word: 'betrayer', word_class: 'N' },
    { word: 'betraying', word_class: 'AJ' }
  ],
  effusion: [
    { word: 'effuse', word_class: 'V' },
    { word: 'effusion', word_class: 'N' },
    { word: 'effusive', word_class: 'AJ' },
    { word: 'effusively', word_class: 'AV' },
    { word: 'effusiveness', word_class: 'N' }
  ],
  aggrandizement: [
    { word: 'aggrandise', word_class: 'V' },
    { word: 'aggrandize', word_class: 'V' },
    { word: 'aggrandisement', word_class: 'N' },
    { word: 'aggrandizement', word_class: 'N' }
  ],
  hive: [{ word: 'hive', word_class: 'N' }, { word: 'hive', word_class: 'V' }, { word: 'hives', word_class: 'N' }],
  dynast: [
    { word: 'dynast', word_class: 'N' },
    { word: 'dynasty', word_class: 'N' },
    { word: 'dynasty', word_class: 'AJ' },
    { word: 'dynastic', word_class: 'AJ' }
  ],
  embattle: [
    { word: 'embattle', word_class: 'V' },
    { word: 'embattled', word_class: 'AJ' },
    { word: 'embattlement', word_class: 'N' }
  ],
  repayable: [
    { word: 'repay', word_class: 'V' },
    { word: 'repayment', word_class: 'N' },
    { word: 'repayable', word_class: 'AJ' }
  ],
  respectively: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  summoning: [
    { word: 'summon', word_class: 'V' },
    { word: 'summons', word_class: 'N' },
    { word: 'summons', word_class: 'V' },
    { word: 'summoning', word_class: 'N' }
  ],
  calculated: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  asymptote: [
    { word: 'asymptote', word_class: 'N' },
    { word: 'asymptotic', word_class: 'AJ' },
    { word: 'asymptotically', word_class: 'AV' }
  ],
  considerably: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  forgiveness: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  punkie: [
    { word: 'punky', word_class: 'N' },
    { word: 'punkie', word_class: 'N' },
    { word: 'punky', word_class: 'AJ' }
  ],
  belittled: [
    { word: 'belittle', word_class: 'V' },
    { word: 'belittled', word_class: 'AJ' },
    { word: 'belittling', word_class: 'N' },
    { word: 'belittling', word_class: 'AJ' },
    { word: 'belittlement', word_class: 'N' }
  ],
  effect: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  artificially: [
    { word: 'artificial', word_class: 'AJ' },
    { word: 'artificiality', word_class: 'N' },
    { word: 'artificially', word_class: 'AV' }
  ],
  favored: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  revisal: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  deserter: [
    { word: 'desert', word_class: 'N' },
    { word: 'desert', word_class: 'V' },
    { word: 'desert', word_class: 'AJ' },
    { word: 'deserter', word_class: 'N' },
    { word: 'deserted', word_class: 'AJ' },
    { word: 'desertion', word_class: 'N' }
  ],
  springer: [
    { word: 'spring', word_class: 'N' },
    { word: 'spring', word_class: 'V' },
    { word: 'spring', word_class: 'AJ' },
    { word: 'sprung', word_class: 'AJ' },
    { word: 'springer', word_class: 'N' },
    { word: 'springing', word_class: 'AJ' }
  ],
  stake: [
    { word: 'stake', word_class: 'N' },
    { word: 'stake', word_class: 'V' },
    { word: 'stakes', word_class: 'N' },
    { word: 'staking', word_class: 'N' }
  ],
  sensitizer: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  rapacious: [
    { word: 'rapacious', word_class: 'AJ' },
    { word: 'rapaciously', word_class: 'AV' },
    { word: 'rapaciousness', word_class: 'N' }
  ],
  intimal: [
    { word: 'intimacy', word_class: 'N' },
    { word: 'intimate', word_class: 'N' },
    { word: 'intimal', word_class: 'AJ' },
    { word: 'intimate', word_class: 'V' },
    { word: 'intimate', word_class: 'AJ' },
    { word: 'intimation', word_class: 'N' },
    { word: 'intimately', word_class: 'AV' }
  ],
  immediate: [
    { word: 'immediacy', word_class: 'N' },
    { word: 'immediate', word_class: 'V' },
    { word: 'immediate', word_class: 'AJ' },
    { word: 'immediately', word_class: 'AV' },
    { word: 'immediateness', word_class: 'N' }
  ],
  shingling: [
    { word: 'shingle', word_class: 'N' },
    { word: 'shingle', word_class: 'V' },
    { word: 'shingles', word_class: 'N' },
    { word: 'shingling', word_class: 'N' }
  ],
  hurt: [
    { word: 'hurt', word_class: 'N' },
    { word: 'hurt', word_class: 'V' },
    { word: 'hurt', word_class: 'AJ' },
    { word: 'hurting', word_class: 'N' },
    { word: 'hurtful', word_class: 'AJ' }
  ],
  inhospitableness: [
    { word: 'inhospitable', word_class: 'AJ' },
    { word: 'inhospitably', word_class: 'AV' },
    { word: 'inhospitableness', word_class: 'N' }
  ],
  mercifulness: [
    { word: 'mercy', word_class: 'N' },
    { word: 'merciful', word_class: 'AJ' },
    { word: 'mercifulness', word_class: 'N' }
  ],
  corrigibly: [
    { word: 'corrigible', word_class: 'AJ' },
    { word: 'corrigibly', word_class: 'AV' },
    { word: 'corrigibility', word_class: 'N' }
  ],
  frown: [
    { word: 'frown', word_class: 'N' },
    { word: 'frown', word_class: 'V' },
    { word: 'frowning', word_class: 'AJ' }
  ],
  briskness: [
    { word: 'brisk', word_class: 'V' },
    { word: 'brisk', word_class: 'AJ' },
    { word: 'briskness', word_class: 'N' }
  ],
  endearing: [
    { word: 'endear', word_class: 'V' },
    { word: 'endearing', word_class: 'AJ' },
    { word: 'endearment', word_class: 'N' }
  ],
  blue: [
    { word: 'blue', word_class: 'N' },
    { word: 'blue', word_class: 'V' },
    { word: 'blue', word_class: 'AJ' },
    { word: 'blues', word_class: 'N' },
    { word: 'blueing', word_class: 'N' },
    { word: 'blueness', word_class: 'N' }
  ],
  syncopated: [
    { word: 'syncope', word_class: 'N' },
    { word: 'syncopate', word_class: 'V' },
    { word: 'syncopated', word_class: 'AJ' },
    { word: 'syncopation', word_class: 'N' }
  ],
  phenotypic: [
    { word: 'phenotype', word_class: 'N' },
    { word: 'phenotypic', word_class: 'AJ' },
    { word: 'phenotypical', word_class: 'AJ' }
  ],
  incomprehensible: [
    { word: 'incomprehension', word_class: 'N' },
    { word: 'incomprehensive', word_class: 'AJ' },
    { word: 'incomprehensible', word_class: 'AJ' },
    { word: 'incomprehensibility', word_class: 'N' }
  ],
  heartiness: [
    { word: 'hearty', word_class: 'N' },
    { word: 'hearty', word_class: 'AJ' },
    { word: 'heartiness', word_class: 'N' }
  ],
  habit: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  emigrate: [
    { word: 'emigre', word_class: 'N' },
    { word: 'emigrant', word_class: 'N' },
    { word: 'emigrate', word_class: 'V' },
    { word: 'emigration', word_class: 'N' }
  ],
  marble: [
    { word: 'marble', word_class: 'N' },
    { word: 'marble', word_class: 'V' },
    { word: 'marbles', word_class: 'N' },
    { word: 'marbled', word_class: 'AJ' },
    { word: 'marbling', word_class: 'N' }
  ],
  effectualness: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  holland: [
    { word: 'holland', word_class: 'N' },
    { word: 'hollands', word_class: 'N' },
    { word: 'hollander', word_class: 'N' }
  ],
  toper: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  floodlighted: [
    { word: 'floodlight', word_class: 'N' },
    { word: 'floodlight', word_class: 'V' },
    { word: 'floodlighted', word_class: 'AJ' }
  ],
  sorcerous: [
    { word: 'sorcery', word_class: 'N' },
    { word: 'sorcerer', word_class: 'N' },
    { word: 'sorceress', word_class: 'N' },
    { word: 'sorcerize', word_class: 'V' },
    { word: 'sorcerous', word_class: 'AJ' }
  ],
  babblement: [
    { word: 'babble', word_class: 'N' },
    { word: 'babble', word_class: 'V' },
    { word: 'babbler', word_class: 'N' },
    { word: 'babbling', word_class: 'N' },
    { word: 'babbling', word_class: 'AJ' },
    { word: 'babblement', word_class: 'N' }
  ],
  linked: [
    { word: 'link', word_class: 'N' },
    { word: 'link', word_class: 'V' },
    { word: 'links', word_class: 'N' },
    { word: 'linkage', word_class: 'N' },
    { word: 'linkman', word_class: 'N' },
    { word: 'linked', word_class: 'AJ' }
  ],
  integrity: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  ovule: [
    { word: 'ovule', word_class: 'N' },
    { word: 'ovulate', word_class: 'V' },
    { word: 'ovulation', word_class: 'N' }
  ],
  inerrancy: [
    { word: 'inerrancy', word_class: 'N' },
    { word: 'inerrant', word_class: 'AJ' },
    { word: 'inerrable', word_class: 'AJ' }
  ],
  domesticity: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  convicted: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  gardener: [
    { word: 'garden', word_class: 'N' },
    { word: 'garden', word_class: 'V' },
    { word: 'gardener', word_class: 'N' },
    { word: 'gardening', word_class: 'N' }
  ],
  cosy: [{ word: 'cosy', word_class: 'N' }, { word: 'cosy', word_class: 'AJ' }, { word: 'cosiness', word_class: 'N' }],
  hypnotization: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  jellify: [
    { word: 'jell', word_class: 'V' },
    { word: 'jelly', word_class: 'V' },
    { word: 'jelly', word_class: 'N' },
    { word: 'jelled', word_class: 'AJ' },
    { word: 'jellify', word_class: 'V' },
    { word: 'jellied', word_class: 'AJ' },
    { word: 'jellification', word_class: 'N' }
  ],
  democratise: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  roundness: [
    { word: 'round', word_class: 'N' },
    { word: 'round', word_class: 'V' },
    { word: 'round', word_class: 'AJ' },
    { word: 'round', word_class: 'AV' },
    { word: 'rounded', word_class: 'AJ' },
    { word: 'roundness', word_class: 'N' }
  ],
  trumping: [
    { word: 'trump', word_class: 'N' },
    { word: 'trump', word_class: 'V' },
    { word: 'trumping', word_class: 'N' }
  ],
  disdain: [
    { word: 'disdain', word_class: 'N' },
    { word: 'disdain', word_class: 'V' },
    { word: 'disdainful', word_class: 'AJ' },
    { word: 'disdainment', word_class: 'N' },
    { word: 'disdainfulness', word_class: 'N' }
  ],
  narcotized: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  engraved: [
    { word: 'engrave', word_class: 'V' },
    { word: 'engraver', word_class: 'N' },
    { word: 'engraved', word_class: 'AJ' },
    { word: 'engraving', word_class: 'N' }
  ],
  sensitize: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  arming: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  theologise: [
    { word: 'theologise', word_class: 'V' },
    { word: 'theologize', word_class: 'V' },
    { word: 'theologizer', word_class: 'N' },
    { word: 'theological', word_class: 'AJ' },
    { word: 'theologically', word_class: 'AV' },
    { word: 'theologisation', word_class: 'N' },
    { word: 'theologization', word_class: 'N' }
  ],
  entwine: [
    { word: 'entwine', word_class: 'V' },
    { word: 'entwinement', word_class: 'N' },
    { word: 'entwintwine', word_class: 'V' },
    { word: 'entwintwinement', word_class: 'N' }
  ],
  specialisation: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  warmth: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  scald: [
    { word: 'scald', word_class: 'N' },
    { word: 'scald', word_class: 'V' },
    { word: 'scalding', word_class: 'AJ' }
  ],
  empower: [
    { word: 'empower', word_class: 'V' },
    { word: 'empowered', word_class: 'AJ' },
    { word: 'empowerment', word_class: 'N' }
  ],
  reclaim: [
    { word: 'reclaim', word_class: 'V' },
    { word: 'reclaimed', word_class: 'AJ' },
    { word: 'reclamation', word_class: 'N' },
    { word: 'reclaimable', word_class: 'AJ' }
  ],
  candy: [
    { word: 'candy', word_class: 'N' },
    { word: 'candy', word_class: 'V' },
    { word: 'candied', word_class: 'AJ' }
  ],
  convenient: [
    { word: 'convenience', word_class: 'N' },
    { word: 'convenience', word_class: 'V' },
    { word: 'convenient', word_class: 'AJ' },
    { word: 'conveniences', word_class: 'N' },
    { word: 'conveniently', word_class: 'AV' }
  ],
  debasement: [
    { word: 'debase', word_class: 'V' },
    { word: 'debased', word_class: 'AJ' },
    { word: 'debasing', word_class: 'AJ' },
    { word: 'debasement', word_class: 'N' }
  ],
  imperviousness: [
    { word: 'impervious', word_class: 'AJ' },
    { word: 'imperviable', word_class: 'AJ' },
    { word: 'imperviousness', word_class: 'N' }
  ],
  code: [{ word: 'code', word_class: 'N' }, { word: 'code', word_class: 'V' }, { word: 'coding', word_class: 'N' }],
  split: [
    { word: 'split', word_class: 'N' },
    { word: 'split', word_class: 'V' },
    { word: 'split', word_class: 'AJ' },
    { word: 'splitting', word_class: 'AJ' }
  ],
  regulative: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  spherically: [
    { word: 'spheric', word_class: 'AJ' },
    { word: 'spherical', word_class: 'AJ' },
    { word: 'sphericity', word_class: 'N' },
    { word: 'spherically', word_class: 'AV' },
    { word: 'sphericalness', word_class: 'N' }
  ],
  codling: [
    { word: 'coddle', word_class: 'V' },
    { word: 'codling', word_class: 'N' },
    { word: 'coddled', word_class: 'AJ' }
  ],
  refulgent: [
    { word: 'refulgence', word_class: 'N' },
    { word: 'refulgency', word_class: 'N' },
    { word: 'refulgent', word_class: 'AJ' }
  ],
  assailability: [
    { word: 'assail', word_class: 'V' },
    { word: 'assailant', word_class: 'N' },
    { word: 'assailment', word_class: 'N' },
    { word: 'assailable', word_class: 'AJ' },
    { word: 'assailability', word_class: 'N' }
  ],
  debauched: [
    { word: 'debauch', word_class: 'N' },
    { word: 'debauch', word_class: 'V' },
    { word: 'debauchee', word_class: 'N' },
    { word: 'debaucher', word_class: 'N' },
    { word: 'debauched', word_class: 'AJ' },
    { word: 'debauchery', word_class: 'N' },
    { word: 'debauchery', word_class: 'AJ' }
  ],
  fifty: [
    { word: 'fifty', word_class: 'N' },
    { word: 'fifty', word_class: 'AJ' },
    { word: 'fifties', word_class: 'N' }
  ],
  effectuate: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  legitimatize: [
    { word: 'legitimatise', word_class: 'V' },
    { word: 'legitimatize', word_class: 'V' },
    { word: 'legitimisation', word_class: 'N' },
    { word: 'legitimatisation', word_class: 'N' },
    { word: 'legitimatization', word_class: 'N' }
  ],
  wizen: [
    { word: 'wizen', word_class: 'V' },
    { word: 'wizen', word_class: 'AJ' },
    { word: 'wizened', word_class: 'AJ' }
  ],
  vanquishment: [
    { word: 'vanquish', word_class: 'V' },
    { word: 'vanquisher', word_class: 'N' },
    { word: 'vanquished', word_class: 'AJ' },
    { word: 'vanquishment', word_class: 'N' },
    { word: 'vanquishable', word_class: 'AJ' }
  ],
  admirability: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  sovereign: [
    { word: 'sovereign', word_class: 'N' },
    { word: 'sovereign', word_class: 'AJ' },
    { word: 'sovereignty', word_class: 'N' }
  ],
  questionably: [
    { word: 'question', word_class: 'N' },
    { word: 'question', word_class: 'V' },
    { word: 'questioner', word_class: 'N' },
    { word: 'questioning', word_class: 'N' },
    { word: 'questioning', word_class: 'AJ' },
    { word: 'questionable', word_class: 'AJ' },
    { word: 'questionably', word_class: 'AV' }
  ],
  loyalty: [
    { word: 'loyal', word_class: 'AJ' },
    { word: 'loyalty', word_class: 'N' },
    { word: 'loyally', word_class: 'AV' }
  ],
  categorise: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  suffice: [
    { word: 'suffice', word_class: 'V' },
    { word: 'sufficiency', word_class: 'N' },
    { word: 'sufficient', word_class: 'AJ' },
    { word: 'sufficiently', word_class: 'AV' }
  ],
  access: [
    { word: 'access', word_class: 'N' },
    { word: 'access', word_class: 'V' },
    { word: 'accession', word_class: 'N' },
    { word: 'accession', word_class: 'V' },
    { word: 'accessible', word_class: 'AJ' },
    { word: 'accessibility', word_class: 'N' }
  ],
  calcify: [
    { word: 'calcify', word_class: 'V' },
    { word: 'calcific', word_class: 'AJ' },
    { word: 'calcification', word_class: 'N' }
  ],
  saponify: [
    { word: 'saponify', word_class: 'V' },
    { word: 'saponified', word_class: 'AJ' },
    { word: 'saponification', word_class: 'N' }
  ],
  anecdotical: [
    { word: 'anecdote', word_class: 'N' },
    { word: 'anecdotal', word_class: 'AJ' },
    { word: 'anecdotic', word_class: 'AJ' },
    { word: 'anecdotical', word_class: 'AJ' }
  ],
  pertinently: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  europeanize: [
    { word: 'european', word_class: 'N' },
    { word: 'european', word_class: 'AJ' },
    { word: 'europeanize', word_class: 'V' },
    { word: 'europeanization', word_class: 'N' }
  ],
  courageously: [
    { word: 'courage', word_class: 'N' },
    { word: 'courageous', word_class: 'AJ' },
    { word: 'courageously', word_class: 'AV' },
    { word: 'courageousness', word_class: 'N' }
  ],
  evoked: [
    { word: 'evoke', word_class: 'V' },
    { word: 'evoked', word_class: 'AJ' },
    { word: 'evocable', word_class: 'AJ' },
    { word: 'evocation', word_class: 'N' },
    { word: 'evocative', word_class: 'AJ' }
  ],
  maiming: [
    { word: 'maim', word_class: 'V' },
    { word: 'maimed', word_class: 'N' },
    { word: 'maimed', word_class: 'AJ' },
    { word: 'maiming', word_class: 'N' }
  ],
  totally: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  appropriation: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  skim: [
    { word: 'skim', word_class: 'N' },
    { word: 'skim', word_class: 'V' },
    { word: 'skim', word_class: 'AJ' },
    { word: 'skimmed', word_class: 'AJ' },
    { word: 'skimming', word_class: 'N' }
  ],
  aridity: [
    { word: 'arid', word_class: 'AJ' },
    { word: 'aridity', word_class: 'N' },
    { word: 'aridness', word_class: 'N' }
  ],
  monochromic: [
    { word: 'monochrome', word_class: 'N' },
    { word: 'monochrome', word_class: 'AJ' },
    { word: 'monochromic', word_class: 'AJ' },
    { word: 'monochromous', word_class: 'AJ' }
  ],
  grunting: [
    { word: 'grunt', word_class: 'N' },
    { word: 'grunt', word_class: 'V' },
    { word: 'grunting', word_class: 'AJ' }
  ],
  commutative: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  centrifuge: [
    { word: 'centrifuge', word_class: 'N' },
    { word: 'centrifuge', word_class: 'V' },
    { word: 'centrifugal', word_class: 'AJ' },
    { word: 'centrifugate', word_class: 'V' },
    { word: 'centrifugation', word_class: 'N' }
  ],
  preeminent: [
    { word: 'preeminence', word_class: 'N' },
    { word: 'preeminent', word_class: 'AJ' },
    { word: 'preeminently', word_class: 'AV' }
  ],
  bunt: [{ word: 'bunt', word_class: 'N' }, { word: 'bunt', word_class: 'V' }, { word: 'bunting', word_class: 'N' }],
  widely: [
    { word: 'wide', word_class: 'AJ' },
    { word: 'wide', word_class: 'AV' },
    { word: 'width', word_class: 'N' },
    { word: 'widely', word_class: 'AV' },
    { word: 'wideness', word_class: 'N' }
  ],
  approving: [
    { word: 'approve', word_class: 'V' },
    { word: 'approval', word_class: 'N' },
    { word: 'approved', word_class: 'AJ' },
    { word: 'approving', word_class: 'N' },
    { word: 'approving', word_class: 'AJ' }
  ],
  zestfulness: [
    { word: 'zest', word_class: 'N' },
    { word: 'zest', word_class: 'V' },
    { word: 'zestful', word_class: 'AJ' },
    { word: 'zestfulness', word_class: 'N' }
  ],
  lexically: [
    { word: 'lexical', word_class: 'AJ' },
    { word: 'lexicalize', word_class: 'V' },
    { word: 'lexically', word_class: 'AV' }
  ],
  beautification: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  unsystematically: [
    { word: 'unsystematic', word_class: 'AJ' },
    { word: 'unsystematized', word_class: 'AJ' },
    { word: 'unsystematically', word_class: 'AV' }
  ],
  weekender: [
    { word: 'weekend', word_class: 'N' },
    { word: 'weekend', word_class: 'V' },
    { word: 'weekender', word_class: 'N' }
  ],
  barrelful: [
    { word: 'barrel', word_class: 'N' },
    { word: 'barrel', word_class: 'V' },
    { word: 'barrels', word_class: 'N' },
    { word: 'barreled', word_class: 'AJ' },
    { word: 'barrelful', word_class: 'N' },
    { word: 'barrelled', word_class: 'AJ' }
  ],
  stigmatization: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  coveted: [
    { word: 'covet', word_class: 'N' },
    { word: 'covet', word_class: 'V' },
    { word: 'coveted', word_class: 'AJ' },
    { word: 'covetous', word_class: 'AJ' },
    { word: 'covetously', word_class: 'AV' },
    { word: 'covetousness', word_class: 'N' }
  ],
  brewing: [
    { word: 'brew', word_class: 'N' },
    { word: 'brew', word_class: 'V' },
    { word: 'brewer', word_class: 'N' },
    { word: 'brewage', word_class: 'N' },
    { word: 'brewery', word_class: 'N' },
    { word: 'brewing', word_class: 'N' },
    { word: 'brewery', word_class: 'AJ' }
  ],
  vaccination: [
    { word: 'vaccine', word_class: 'N' },
    { word: 'vaccinate', word_class: 'V' },
    { word: 'vaccinated', word_class: 'AJ' },
    { word: 'vaccinating', word_class: 'N' },
    { word: 'vaccination', word_class: 'N' }
  ],
  undesirably: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  serviceman: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  seclude: [
    { word: 'seclude', word_class: 'V' },
    { word: 'secluded', word_class: 'AJ' },
    { word: 'seclusion', word_class: 'N' }
  ],
  ornamented: [
    { word: 'ornament', word_class: 'N' },
    { word: 'ornament', word_class: 'V' },
    { word: 'ornamental', word_class: 'N' },
    { word: 'ornamental', word_class: 'AJ' },
    { word: 'ornamented', word_class: 'AJ' },
    { word: 'ornamentation', word_class: 'N' }
  ],
  personableness: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  wanted: [
    { word: 'want', word_class: 'N' },
    { word: 'want', word_class: 'V' },
    { word: 'wanted', word_class: 'AJ' },
    { word: 'wanting', word_class: 'AJ' }
  ],
  pusillanimousness: [
    { word: 'pusillanimity', word_class: 'N' },
    { word: 'pusillanimous', word_class: 'AJ' },
    { word: 'pusillanimously', word_class: 'AV' },
    { word: 'pusillanimousness', word_class: 'N' }
  ],
  weaning: [
    { word: 'wean', word_class: 'V' },
    { word: 'weaned', word_class: 'AJ' },
    { word: 'weaning', word_class: 'N' }
  ],
  jubilancy: [
    { word: 'jubilate', word_class: 'V' },
    { word: 'jubilance', word_class: 'N' },
    { word: 'jubilancy', word_class: 'N' },
    { word: 'jubilant', word_class: 'AJ' },
    { word: 'jubilation', word_class: 'N' }
  ],
  fitting: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  carbonyl: [
    { word: 'carbonyl', word_class: 'N' },
    { word: 'carbonyl', word_class: 'AJ' },
    { word: 'carbonylic', word_class: 'AJ' }
  ],
  homestead: [
    { word: 'homestead', word_class: 'N' },
    { word: 'homestead', word_class: 'V' },
    { word: 'homesteader', word_class: 'N' }
  ],
  internationalist: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  indigenousness: [
    { word: 'indigene', word_class: 'N' },
    { word: 'indigenous', word_class: 'AJ' },
    { word: 'indigenousness', word_class: 'N' }
  ],
  naturalise: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  lavishness: [
    { word: 'lavish', word_class: 'V' },
    { word: 'lavish', word_class: 'AJ' },
    { word: 'lavishment', word_class: 'N' },
    { word: 'lavishness', word_class: 'N' }
  ],
  federalise: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  grease: [
    { word: 'grease', word_class: 'N' },
    { word: 'grease', word_class: 'V' },
    { word: 'greaser', word_class: 'N' },
    { word: 'greasy', word_class: 'AJ' },
    { word: 'greased', word_class: 'AJ' },
    { word: 'greasiness', word_class: 'N' }
  ],
  decay: [
    { word: 'decay', word_class: 'N' },
    { word: 'decay', word_class: 'V' },
    { word: 'decayed', word_class: 'AJ' }
  ],
  vivify: [
    { word: 'vivify', word_class: 'V' },
    { word: 'vivifying', word_class: 'N' },
    { word: 'vivification', word_class: 'N' }
  ],
  investor: [
    { word: 'invest', word_class: 'N' },
    { word: 'invest', word_class: 'V' },
    { word: 'investor', word_class: 'N' },
    { word: 'invested', word_class: 'AJ' },
    { word: 'investing', word_class: 'N' },
    { word: 'investment', word_class: 'N' }
  ],
  curler: [
    { word: 'curl', word_class: 'N' },
    { word: 'curl', word_class: 'V' },
    { word: 'curler', word_class: 'N' },
    { word: 'curled', word_class: 'AJ' },
    { word: 'curling', word_class: 'N' },
    { word: 'curling', word_class: 'AJ' }
  ],
  breezy: [
    { word: 'breeze', word_class: 'N' },
    { word: 'breeze', word_class: 'V' },
    { word: 'breezy', word_class: 'AJ' },
    { word: 'breeziness', word_class: 'N' }
  ],
  associational: [
    { word: 'associate', word_class: 'N' },
    { word: 'associate', word_class: 'V' },
    { word: 'associate', word_class: 'AJ' },
    { word: 'associable', word_class: 'AJ' },
    { word: 'associated', word_class: 'AJ' },
    { word: 'association', word_class: 'N' },
    { word: 'associative', word_class: 'AJ' },
    { word: 'associability', word_class: 'N' },
    { word: 'associational', word_class: 'AJ' },
    { word: 'associableness', word_class: 'N' }
  ],
  specification: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  overestimate: [
    { word: 'overestimate', word_class: 'N' },
    { word: 'overestimate', word_class: 'V' },
    { word: 'overestimation', word_class: 'N' }
  ],
  steep: [
    { word: 'steep', word_class: 'N' },
    { word: 'steep', word_class: 'V' },
    { word: 'steep', word_class: 'AJ' },
    { word: 'steepness', word_class: 'N' }
  ],
  conductive: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  commander: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  menially: [
    { word: 'menial', word_class: 'N' },
    { word: 'menial', word_class: 'AJ' },
    { word: 'menially', word_class: 'AV' }
  ],
  legalize: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  breach: [
    { word: 'breach', word_class: 'N' },
    { word: 'breach', word_class: 'V' },
    { word: 'broach', word_class: 'N' },
    { word: 'broach', word_class: 'V' },
    { word: 'breached', word_class: 'AJ' },
    { word: 'broached', word_class: 'AJ' }
  ],
  blunted: [
    { word: 'blunt', word_class: 'V' },
    { word: 'blunt', word_class: 'AJ' },
    { word: 'blunted', word_class: 'AJ' },
    { word: 'bluntness', word_class: 'N' }
  ],
  despot: [
    { word: 'despot', word_class: 'N' },
    { word: 'despot', word_class: 'V' },
    { word: 'despotic', word_class: 'AJ' },
    { word: 'despotism', word_class: 'N' },
    { word: 'despotical', word_class: 'AJ' }
  ],
  communize: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  polarization: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  planetal: [
    { word: 'planet', word_class: 'N' },
    { word: 'planetal', word_class: 'AJ' },
    { word: 'planetoid', word_class: 'N' },
    { word: 'planetary', word_class: 'AJ' }
  ],
  hindrance: [
    { word: 'hinder', word_class: 'V' },
    { word: 'hinder', word_class: 'AJ' },
    { word: 'hindrance', word_class: 'N' },
    { word: 'hindering', word_class: 'AJ' }
  ],
  institutionally: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  am: [
    { word: 'am', word_class: 'N' },
    { word: 'am', word_class: 'AJ' },
    { word: 'amative', word_class: 'AJ' },
    { word: 'amativeness', word_class: 'N' }
  ],
  divulgate: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  effeminate: [
    { word: 'effeminacy', word_class: 'N' },
    { word: 'effeminate', word_class: 'V' },
    { word: 'effeminize', word_class: 'V' },
    { word: 'effeminate', word_class: 'AJ' },
    { word: 'effeminateness', word_class: 'N' }
  ],
  grouper: [
    { word: 'group', word_class: 'N' },
    { word: 'group', word_class: 'V' },
    { word: 'group', word_class: 'AJ' },
    { word: 'grouper', word_class: 'N' },
    { word: 'grouped', word_class: 'AJ' },
    { word: 'grouping', word_class: 'N' }
  ],
  realistic: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  schismatic: [
    { word: 'schismatic', word_class: 'AJ' },
    { word: 'schismatical', word_class: 'AJ' },
    { word: 'schismatically', word_class: 'AV' }
  ],
  terrible: [
    { word: 'terrible', word_class: 'AJ' },
    { word: 'terribly', word_class: 'AV' },
    { word: 'terribleness', word_class: 'N' }
  ],
  nude: [{ word: 'nude', word_class: 'N' }, { word: 'nude', word_class: 'AJ' }, { word: 'nudeness', word_class: 'N' }],
  streamlined: [
    { word: 'streamline', word_class: 'N' },
    { word: 'streamline', word_class: 'V' },
    { word: 'streamliner', word_class: 'N' },
    { word: 'streamlined', word_class: 'AJ' }
  ],
  reverberating: [
    { word: 'reverberate', word_class: 'V' },
    { word: 'reverberance', word_class: 'N' },
    { word: 'reverberant', word_class: 'AJ' },
    { word: 'reverberation', word_class: 'N' },
    { word: 'reverberating', word_class: 'AJ' }
  ],
  locating: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  parented: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  salable: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  rapids: [
    { word: 'rapid', word_class: 'N' },
    { word: 'rapid', word_class: 'AJ' },
    { word: 'rapids', word_class: 'N' },
    { word: 'rapidity', word_class: 'N' }
  ],
  negativity: [
    { word: 'negative', word_class: 'N' },
    { word: 'negative', word_class: 'V' },
    { word: 'negative', word_class: 'AJ' },
    { word: 'negativism', word_class: 'N' },
    { word: 'negativist', word_class: 'N' },
    { word: 'negativity', word_class: 'N' },
    { word: 'negatively', word_class: 'AV' },
    { word: 'negativeness', word_class: 'N' }
  ],
  daub: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  schooling: [
    { word: 'school', word_class: 'N' },
    { word: 'school', word_class: 'V' },
    { word: 'schooling', word_class: 'N' },
    { word: 'schooled', word_class: 'AJ' },
    { word: 'schoolman', word_class: 'N' }
  ],
  imbibition: [
    { word: 'imbibe', word_class: 'V' },
    { word: 'imbiber', word_class: 'N' },
    { word: 'imbibing', word_class: 'N' },
    { word: 'imbibition', word_class: 'N' }
  ],
  unionization: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  perspicuously: [
    { word: 'perspicuity', word_class: 'N' },
    { word: 'perspicuous', word_class: 'AJ' },
    { word: 'perspicuously', word_class: 'AV' },
    { word: 'perspicuousness', word_class: 'N' }
  ],
  quarryman: [
    { word: 'quarry', word_class: 'N' },
    { word: 'quarry', word_class: 'V' },
    { word: 'quarrier', word_class: 'N' },
    { word: 'quarrying', word_class: 'N' },
    { word: 'quarryman', word_class: 'N' }
  ],
  blockage: [
    { word: 'block', word_class: 'N' },
    { word: 'block', word_class: 'V' },
    { word: 'blocker', word_class: 'N' },
    { word: 'blockage', word_class: 'N' },
    { word: 'blocked', word_class: 'AJ' },
    { word: 'blocking', word_class: 'N' }
  ],
  hirsute: [
    { word: 'hirsute', word_class: 'AJ' },
    { word: 'hirsutism', word_class: 'N' },
    { word: 'hirsuteness', word_class: 'N' }
  ],
  betterment: [
    { word: 'better', word_class: 'N' },
    { word: 'better', word_class: 'V' },
    { word: 'better', word_class: 'AJ' },
    { word: 'better', word_class: 'AV' },
    { word: 'bettering', word_class: 'AJ' },
    { word: 'betterment', word_class: 'N' }
  ],
  doing: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  jaundice: [
    { word: 'jaundice', word_class: 'N' },
    { word: 'jaundice', word_class: 'V' },
    { word: 'jaundiced', word_class: 'AJ' }
  ],
  bubbler: [
    { word: 'bubble', word_class: 'N' },
    { word: 'bubble', word_class: 'V' },
    { word: 'bubbly', word_class: 'N' },
    { word: 'bubbler', word_class: 'N' },
    { word: 'bubbly', word_class: 'AJ' },
    { word: 'bubbling', word_class: 'AJ' }
  ],
  pagan: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  underpin: [
    { word: 'underpin', word_class: 'N' },
    { word: 'underpin', word_class: 'V' },
    { word: 'underpinning', word_class: 'N' }
  ],
  fire: [
    { word: 'fire', word_class: 'N' },
    { word: 'fire', word_class: 'V' },
    { word: 'first', word_class: 'N' },
    { word: 'fiery', word_class: 'AJ' },
    { word: 'firing', word_class: 'N' },
    { word: 'fired', word_class: 'AJ' },
    { word: 'first', word_class: 'AJ' },
    { word: 'first', word_class: 'AV' },
    { word: 'fireman', word_class: 'N' },
    { word: 'fieriness', word_class: 'N' }
  ],
  charioteer: [
    { word: 'chariot', word_class: 'N' },
    { word: 'chariot', word_class: 'V' },
    { word: 'charioteer', word_class: 'N' }
  ],
  vengefulness: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  fluoridization: [
    { word: 'fluoride', word_class: 'N' },
    { word: 'fluoridate', word_class: 'V' },
    { word: 'fluoridize', word_class: 'V' },
    { word: 'fluoridation', word_class: 'N' },
    { word: 'fluoridization', word_class: 'N' }
  ],
  synthesise: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  russianise: [
    { word: 'russian', word_class: 'N' },
    { word: 'russian', word_class: 'AJ' },
    { word: 'russianise', word_class: 'V' },
    { word: 'russianize', word_class: 'V' },
    { word: 'russianisation', word_class: 'N' },
    { word: 'russianization', word_class: 'N' }
  ],
  volunteer: [
    { word: 'volunteer', word_class: 'N' },
    { word: 'volunteer', word_class: 'V' },
    { word: 'volunteer', word_class: 'AJ' }
  ],
  variably: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  publicity: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  memory: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  launcher: [
    { word: 'launch', word_class: 'N' },
    { word: 'launch', word_class: 'V' },
    { word: 'launcher', word_class: 'N' },
    { word: 'launching', word_class: 'N' }
  ],
  steeplechase: [
    { word: 'steeplechase', word_class: 'N' },
    { word: 'steeplechase', word_class: 'V' },
    { word: 'steeplechaser', word_class: 'N' },
    { word: 'steeplechasing', word_class: 'N' }
  ],
  polemize: [
    { word: 'polemic', word_class: 'N' },
    { word: 'polemic', word_class: 'AJ' },
    { word: 'polemize', word_class: 'V' },
    { word: 'polemical', word_class: 'AJ' },
    { word: 'polemically', word_class: 'AV' }
  ],
  renewal: [
    { word: 'renew', word_class: 'N' },
    { word: 'renew', word_class: 'V' },
    { word: 'renew', word_class: 'AJ' },
    { word: 'renewal', word_class: 'N' },
    { word: 'renewal', word_class: 'V' },
    { word: 'renewed', word_class: 'AJ' },
    { word: 'renewing', word_class: 'AJ' },
    { word: 'renewable', word_class: 'AJ' }
  ],
  grammatical: [
    { word: 'grammatic', word_class: 'AJ' },
    { word: 'grammatical', word_class: 'AJ' },
    { word: 'grammatically', word_class: 'AV' }
  ],
  bidding: [
    { word: 'bid', word_class: 'N' },
    { word: 'bid', word_class: 'V' },
    { word: 'bidden', word_class: 'AJ' },
    { word: 'bidding', word_class: 'N' }
  ],
  emend: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  savings: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  etherization: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  influentially: [
    { word: 'influence', word_class: 'N' },
    { word: 'influence', word_class: 'V' },
    { word: 'influential', word_class: 'AJ' },
    { word: 'influentially', word_class: 'AV' }
  ],
  walker: [
    { word: 'walk', word_class: 'N' },
    { word: 'walk', word_class: 'V' },
    { word: 'walker', word_class: 'N' },
    { word: 'walking', word_class: 'N' },
    { word: 'walking', word_class: 'AJ' }
  ],
  perpetuate: [
    { word: 'perpetual', word_class: 'AJ' },
    { word: 'perpetuate', word_class: 'V' },
    { word: 'perpetuity', word_class: 'N' },
    { word: 'perpetually', word_class: 'AV' },
    { word: 'perpetuation', word_class: 'N' }
  ],
  gravidity: [
    { word: 'gravid', word_class: 'AJ' },
    { word: 'gravidity', word_class: 'N' },
    { word: 'gravidness', word_class: 'N' }
  ],
  dissenter: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  carbonize: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  antithetic: [
    { word: 'antithetic', word_class: 'AJ' },
    { word: 'antithetical', word_class: 'AJ' },
    { word: 'antithetically', word_class: 'AV' }
  ],
  vulgarity: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  asymptotic: [
    { word: 'asymptote', word_class: 'N' },
    { word: 'asymptotic', word_class: 'AJ' },
    { word: 'asymptotically', word_class: 'AV' }
  ],
  deject: [
    { word: 'deject', word_class: 'V' },
    { word: 'dejected', word_class: 'AJ' },
    { word: 'dejection', word_class: 'N' }
  ],
  extradite: [
    { word: 'extradite', word_class: 'N' },
    { word: 'extradite', word_class: 'V' },
    { word: 'extradition', word_class: 'N' }
  ],
  alternant: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  expectorate: [
    { word: 'expectorant', word_class: 'N' },
    { word: 'expectorate', word_class: 'V' },
    { word: 'expectoration', word_class: 'N' }
  ],
  householder: [
    { word: 'household', word_class: 'N' },
    { word: 'household', word_class: 'AJ' },
    { word: 'householder', word_class: 'N' }
  ],
  inconsistently: [
    { word: 'inconsistency', word_class: 'N' },
    { word: 'inconsistent', word_class: 'AJ' },
    { word: 'inconsistently', word_class: 'AV' }
  ],
  beautify: [
    { word: 'beauty', word_class: 'N' },
    { word: 'beautify', word_class: 'V' },
    { word: 'beauteous', word_class: 'AJ' },
    { word: 'beautician', word_class: 'N' },
    { word: 'beautiful', word_class: 'AJ' },
    { word: 'beautification', word_class: 'N' }
  ],
  bellyacher: [
    { word: 'bellyache', word_class: 'N' },
    { word: 'bellyache', word_class: 'V' },
    { word: 'bellyacher', word_class: 'N' }
  ],
  tarnished: [
    { word: 'tarnish', word_class: 'N' },
    { word: 'tarnish', word_class: 'V' },
    { word: 'tarnished', word_class: 'AJ' }
  ],
  unsuitable: [
    { word: 'unsuited', word_class: 'AJ' },
    { word: 'unsuitable', word_class: 'AJ' },
    { word: 'unsuitably', word_class: 'AV' },
    { word: 'unsuitability', word_class: 'N' },
    { word: 'unsuitableness', word_class: 'N' }
  ],
  obligate: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  elucidative: [
    { word: 'elucidate', word_class: 'V' },
    { word: 'elucidation', word_class: 'N' },
    { word: 'elucidative', word_class: 'AJ' }
  ],
  aborigine: [
    { word: 'aborigine', word_class: 'N' },
    { word: 'aboriginal', word_class: 'N' },
    { word: 'aboriginal', word_class: 'AJ' }
  ],
  papist: [
    { word: 'papist', word_class: 'N' },
    { word: 'papist', word_class: 'AJ' },
    { word: 'papistic', word_class: 'AJ' },
    { word: 'papistical', word_class: 'AJ' }
  ],
  liquified: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  wobbling: [
    { word: 'wobble', word_class: 'N' },
    { word: 'wobble', word_class: 'V' },
    { word: 'wobbler', word_class: 'N' },
    { word: 'wobbly', word_class: 'AJ' },
    { word: 'wobbling', word_class: 'AJ' }
  ],
  lyrical: [
    { word: 'lyric', word_class: 'N' },
    { word: 'lyric', word_class: 'V' },
    { word: 'lyric', word_class: 'AJ' },
    { word: 'lyrical', word_class: 'AJ' },
    { word: 'lyricism', word_class: 'N' },
    { word: 'lyricality', word_class: 'N' },
    { word: 'lyrically', word_class: 'AV' }
  ],
  ruggedness: [
    { word: 'ruggedize', word_class: 'V' },
    { word: 'ruggedness', word_class: 'N' },
    { word: 'ruggedization', word_class: 'N' }
  ],
  oncologic: [
    { word: 'oncology', word_class: 'N' },
    { word: 'oncologic', word_class: 'AJ' },
    { word: 'oncological', word_class: 'AJ' }
  ],
  circulation: [
    { word: 'circulant', word_class: 'N' },
    { word: 'circulate', word_class: 'V' },
    { word: 'circulation', word_class: 'N' },
    { word: 'circulating', word_class: 'AJ' },
    { word: 'circulative', word_class: 'AJ' }
  ],
  sulfurized: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  euphonic: [
    { word: 'euphonic', word_class: 'AJ' },
    { word: 'euphonize', word_class: 'V' },
    { word: 'euphonous', word_class: 'AJ' },
    { word: 'euphonical', word_class: 'AJ' },
    { word: 'euphonization', word_class: 'N' }
  ],
  laurelled: [
    { word: 'laurel', word_class: 'N' },
    { word: 'laurels', word_class: 'N' },
    { word: 'laureled', word_class: 'AJ' },
    { word: 'laurelled', word_class: 'AJ' }
  ],
  inexorableness: [
    { word: 'inexorable', word_class: 'AJ' },
    { word: 'inexorably', word_class: 'AV' },
    { word: 'inexorability', word_class: 'N' },
    { word: 'inexorableness', word_class: 'N' }
  ],
  torpid: [
    { word: 'torpid', word_class: 'AJ' },
    { word: 'torpidity', word_class: 'N' },
    { word: 'torpidness', word_class: 'N' }
  ],
  excused: [
    { word: 'excuse', word_class: 'N' },
    { word: 'excuse', word_class: 'V' },
    { word: 'excused', word_class: 'AJ' },
    { word: 'excusable', word_class: 'AJ' },
    { word: 'excusably', word_class: 'AV' }
  ],
  devitalize: [
    { word: 'devitalise', word_class: 'V' },
    { word: 'devitalize', word_class: 'V' },
    { word: 'devitalisation', word_class: 'N' },
    { word: 'devitalization', word_class: 'N' }
  ],
  extort: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  amenability: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  fancy: [
    { word: 'fancy', word_class: 'N' },
    { word: 'fancy', word_class: 'V' },
    { word: 'fancy', word_class: 'AJ' },
    { word: 'fancier', word_class: 'N' },
    { word: 'fancied', word_class: 'AJ' },
    { word: 'fanciful', word_class: 'AJ' }
  ],
  assertive: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  son: [{ word: 'son', word_class: 'N' }, { word: 'sonny', word_class: 'N' }, { word: 'sonny', word_class: 'AJ' }],
  incoherency: [
    { word: 'incoherence', word_class: 'N' },
    { word: 'incoherency', word_class: 'N' },
    { word: 'incoherent', word_class: 'AJ' },
    { word: 'incoherently', word_class: 'AV' }
  ],
  symbolize: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  vulnerability: [
    { word: 'vulnerable', word_class: 'AJ' },
    { word: 'vulnerably', word_class: 'AV' },
    { word: 'vulnerability', word_class: 'N' }
  ],
  gamely: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  decentralize: [
    { word: 'decentralize', word_class: 'V' },
    { word: 'decentralized', word_class: 'AJ' },
    { word: 'decentralizing', word_class: 'AJ' },
    { word: 'decentralization', word_class: 'N' }
  ],
  poler: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  yield: [
    { word: 'yield', word_class: 'N' },
    { word: 'yield', word_class: 'V' },
    { word: 'yielding', word_class: 'N' },
    { word: 'yielding', word_class: 'AJ' }
  ],
  fascistic: [
    { word: 'fascist', word_class: 'N' },
    { word: 'fascist', word_class: 'AJ' },
    { word: 'fascistic', word_class: 'AJ' }
  ],
  drawer: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  valor: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  grandiloquent: [
    { word: 'grandiloquence', word_class: 'N' },
    { word: 'grandiloquent', word_class: 'AJ' },
    { word: 'grandiloquently', word_class: 'AV' }
  ],
  thundering: [
    { word: 'thunder', word_class: 'N' },
    { word: 'thunder', word_class: 'V' },
    { word: 'thunderer', word_class: 'N' },
    { word: 'thundering', word_class: 'AJ' },
    { word: 'thunderous', word_class: 'AJ' }
  ],
  quavery: [
    { word: 'quaver', word_class: 'N' },
    { word: 'quaver', word_class: 'V' },
    { word: 'quavery', word_class: 'AJ' },
    { word: 'quavering', word_class: 'AJ' }
  ],
  conqueror: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  nodulated: [
    { word: 'nodule', word_class: 'N' },
    { word: 'nodular', word_class: 'AJ' },
    { word: 'noduled', word_class: 'AJ' },
    { word: 'nodulated', word_class: 'AJ' }
  ],
  unrighteously: [
    { word: 'unrighteous', word_class: 'AJ' },
    { word: 'unrighteously', word_class: 'AV' },
    { word: 'unrighteousness', word_class: 'N' }
  ],
  discredit: [
    { word: 'discredit', word_class: 'N' },
    { word: 'discredit', word_class: 'V' },
    { word: 'discredited', word_class: 'AJ' },
    { word: 'discreditable', word_class: 'AJ' },
    { word: 'discreditably', word_class: 'AV' }
  ],
  exoticness: [
    { word: 'exotic', word_class: 'AJ' },
    { word: 'exotism', word_class: 'N' },
    { word: 'exoticness', word_class: 'N' }
  ],
  carping: [
    { word: 'carp', word_class: 'N' },
    { word: 'carp', word_class: 'V' },
    { word: 'carpal', word_class: 'N' },
    { word: 'carper', word_class: 'N' },
    { word: 'carping', word_class: 'N' },
    { word: 'carpal', word_class: 'AJ' },
    { word: 'carping', word_class: 'AJ' }
  ],
  troops: [
    { word: 'troop', word_class: 'N' },
    { word: 'troop', word_class: 'V' },
    { word: 'troops', word_class: 'N' },
    { word: 'trooper', word_class: 'N' },
    { word: 'troopship', word_class: 'N' }
  ],
  gratifying: [
    { word: 'gratify', word_class: 'V' },
    { word: 'gratified', word_class: 'AJ' },
    { word: 'gratifying', word_class: 'AJ' },
    { word: 'gratification', word_class: 'N' }
  ],
  slurred: [
    { word: 'slur', word_class: 'N' },
    { word: 'slur', word_class: 'V' },
    { word: 'slurred', word_class: 'AJ' }
  ],
  picnic: [
    { word: 'picnic', word_class: 'N' },
    { word: 'picnic', word_class: 'V' },
    { word: 'picnicker', word_class: 'N' }
  ],
  laugh: [
    { word: 'laugh', word_class: 'N' },
    { word: 'laugh', word_class: 'V' },
    { word: 'laugher', word_class: 'N' },
    { word: 'laughter', word_class: 'N' },
    { word: 'laughing', word_class: 'AJ' }
  ],
  jurisprudentially: [
    { word: 'jurisprudent', word_class: 'N' },
    { word: 'jurisprudence', word_class: 'N' },
    { word: 'jurisprudential', word_class: 'AJ' },
    { word: 'jurisprudentially', word_class: 'AV' }
  ],
  embellish: [
    { word: 'embellish', word_class: 'V' },
    { word: 'embellished', word_class: 'AJ' },
    { word: 'embellishment', word_class: 'N' }
  ],
  disputable: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  comply: [
    { word: 'comply', word_class: 'V' },
    { word: 'compliance', word_class: 'N' },
    { word: 'compliancy', word_class: 'N' },
    { word: 'complying', word_class: 'AJ' }
  ],
  allies: [
    { word: 'ally', word_class: 'N' },
    { word: 'ally', word_class: 'V' },
    { word: 'allis', word_class: 'N' },
    { word: 'allies', word_class: 'N' },
    { word: 'allied', word_class: 'AJ' },
    { word: 'alliance', word_class: 'N' }
  ],
  obliquity: [
    { word: 'oblique', word_class: 'N' },
    { word: 'oblique', word_class: 'AJ' },
    { word: 'obliquity', word_class: 'N' },
    { word: 'obliquely', word_class: 'AV' },
    { word: 'obliqueness', word_class: 'N' }
  ],
  banned: [
    { word: 'ban', word_class: 'N' },
    { word: 'ban', word_class: 'V' },
    { word: 'banned', word_class: 'AJ' },
    { word: 'banning', word_class: 'N' }
  ],
  alterable: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  bender: [
    { word: 'bend', word_class: 'N' },
    { word: 'bend', word_class: 'V' },
    { word: 'bends', word_class: 'N' },
    { word: 'bender', word_class: 'N' },
    { word: 'bended', word_class: 'AJ' },
    { word: 'bending', word_class: 'N' },
    { word: 'bending', word_class: 'AJ' }
  ],
  deformational: [
    { word: 'deform', word_class: 'V' },
    { word: 'deformed', word_class: 'AJ' },
    { word: 'deformity', word_class: 'N' },
    { word: 'deformation', word_class: 'N' },
    { word: 'deformational', word_class: 'AJ' }
  ],
  wisdom: [
    { word: 'wise', word_class: 'N' },
    { word: 'wise', word_class: 'AJ' },
    { word: 'wisdom', word_class: 'N' },
    { word: 'wisely', word_class: 'AV' },
    { word: 'wiseness', word_class: 'N' }
  ],
  calculous: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  fumigant: [
    { word: 'fumigant', word_class: 'N' },
    { word: 'fumigate', word_class: 'V' },
    { word: 'fumigation', word_class: 'N' }
  ],
  analyse: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  adoption: [
    { word: 'adopt', word_class: 'V' },
    { word: 'adopted', word_class: 'AJ' },
    { word: 'adoption', word_class: 'N' },
    { word: 'adoptive', word_class: 'AJ' },
    { word: 'adoptable', word_class: 'AJ' }
  ],
  offhanded: [
    { word: 'offhand', word_class: 'AJ' },
    { word: 'offhand', word_class: 'AV' },
    { word: 'offhanded', word_class: 'AJ' },
    { word: 'offhanded', word_class: 'AV' }
  ],
  despotic: [
    { word: 'despot', word_class: 'N' },
    { word: 'despot', word_class: 'V' },
    { word: 'despotic', word_class: 'AJ' },
    { word: 'despotism', word_class: 'N' },
    { word: 'despotical', word_class: 'AJ' }
  ],
  doubles: [
    { word: 'double', word_class: 'N' },
    { word: 'double', word_class: 'V' },
    { word: 'double', word_class: 'AJ' },
    { word: 'double', word_class: 'AV' },
    { word: 'doubles', word_class: 'N' },
    { word: 'doubly', word_class: 'AV' },
    { word: 'doubled', word_class: 'AJ' },
    { word: 'doubling', word_class: 'N' }
  ],
  warbler: [
    { word: 'warble', word_class: 'N' },
    { word: 'warble', word_class: 'V' },
    { word: 'warbler', word_class: 'N' }
  ],
  whizzing: [
    { word: 'whiz', word_class: 'N' },
    { word: 'whiz', word_class: 'V' },
    { word: 'whizz', word_class: 'N' },
    { word: 'whizz', word_class: 'V' },
    { word: 'whizzed', word_class: 'AJ' },
    { word: 'whizzing', word_class: 'AJ' }
  ],
  determined: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  intermittent: [
    { word: 'intermittence', word_class: 'N' },
    { word: 'intermittency', word_class: 'N' },
    { word: 'intermittent', word_class: 'AJ' },
    { word: 'intermittently', word_class: 'AV' }
  ],
  solicitousness: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  fumigate: [
    { word: 'fumigant', word_class: 'N' },
    { word: 'fumigate', word_class: 'V' },
    { word: 'fumigation', word_class: 'N' }
  ],
  okay: [
    { word: 'okay', word_class: 'N' },
    { word: 'okay', word_class: 'V' },
    { word: 'okay', word_class: 'AJ' },
    { word: 'okay', word_class: 'AV' }
  ],
  complexion: [
    { word: 'complexion', word_class: 'N' },
    { word: 'complexion', word_class: 'V' },
    { word: 'complexioned', word_class: 'AJ' }
  ],
  divestiture: [
    { word: 'devest', word_class: 'V' },
    { word: 'divest', word_class: 'N' },
    { word: 'divest', word_class: 'V' },
    { word: 'divesture', word_class: 'N' },
    { word: 'divestment', word_class: 'N' },
    { word: 'divestiture', word_class: 'N' }
  ],
  rural: [
    { word: 'rural', word_class: 'AJ' },
    { word: 'ruralise', word_class: 'V' },
    { word: 'ruralize', word_class: 'V' },
    { word: 'rurally', word_class: 'AV' },
    { word: 'ruralisation', word_class: 'N' },
    { word: 'ruralization', word_class: 'N' }
  ],
  remedy: [
    { word: 'remedy', word_class: 'N' },
    { word: 'remedy', word_class: 'V' },
    { word: 'remedial', word_class: 'AJ' },
    { word: 'remediable', word_class: 'AJ' },
    { word: 'remediation', word_class: 'N' }
  ],
  erecting: [
    { word: 'erect', word_class: 'V' },
    { word: 'erect', word_class: 'AJ' },
    { word: 'erecting', word_class: 'N' },
    { word: 'erection', word_class: 'N' },
    { word: 'erectness', word_class: 'N' }
  ],
  saccharin: [
    { word: 'saccharin', word_class: 'N' },
    { word: 'saccharine', word_class: 'AJ' },
    { word: 'saccharinity', word_class: 'N' }
  ],
  electromagnet: [
    { word: 'electromagnet', word_class: 'N' },
    { word: 'electromagnetic', word_class: 'AJ' },
    { word: 'electromagnetics', word_class: 'N' },
    { word: 'electromagnetism', word_class: 'N' }
  ],
  finance: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  puritanic: [
    { word: 'puritan', word_class: 'N' },
    { word: 'puritan', word_class: 'AJ' },
    { word: 'puritanic', word_class: 'AJ' },
    { word: 'puritanism', word_class: 'N' },
    { word: 'puritanical', word_class: 'AJ' },
    { word: 'puritanically', word_class: 'AV' }
  ],
  wriggly: [
    { word: 'wriggle', word_class: 'N' },
    { word: 'wriggle', word_class: 'V' },
    { word: 'wriggler', word_class: 'N' },
    { word: 'wriggly', word_class: 'AJ' },
    { word: 'wriggling', word_class: 'AJ' }
  ],
  spareness: [
    { word: 'spare', word_class: 'N' },
    { word: 'spare', word_class: 'V' },
    { word: 'spare', word_class: 'AJ' },
    { word: 'sparely', word_class: 'AV' },
    { word: 'sparing', word_class: 'AJ' },
    { word: 'spareness', word_class: 'N' }
  ],
  worshipful: [
    { word: 'worship', word_class: 'N' },
    { word: 'worship', word_class: 'V' },
    { word: 'worshiper', word_class: 'N' },
    { word: 'worshipful', word_class: 'AJ' },
    { word: 'worshiping', word_class: 'AJ' },
    { word: 'worshipped', word_class: 'AJ' },
    { word: 'worshipping', word_class: 'AJ' }
  ],
  slew: [{ word: 'slew', word_class: 'N' }, { word: 'slew', word_class: 'V' }, { word: 'slews', word_class: 'N' }],
  snuggling: [
    { word: 'snuggle', word_class: 'N' },
    { word: 'snuggle', word_class: 'V' },
    { word: 'snuggly', word_class: 'AJ' },
    { word: 'snuggled', word_class: 'AJ' },
    { word: 'snuggling', word_class: 'N' }
  ],
  kneeling: [
    { word: 'kneel', word_class: 'N' },
    { word: 'kneel', word_class: 'V' },
    { word: 'kneeler', word_class: 'N' },
    { word: 'kneeling', word_class: 'N' },
    { word: 'kneeling', word_class: 'AJ' }
  ],
  fashioned: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  revilement: [
    { word: 'revile', word_class: 'V' },
    { word: 'revile', word_class: 'AJ' },
    { word: 'reviled', word_class: 'AJ' },
    { word: 'revilement', word_class: 'N' }
  ],
  imbibing: [
    { word: 'imbibe', word_class: 'V' },
    { word: 'imbiber', word_class: 'N' },
    { word: 'imbibing', word_class: 'N' },
    { word: 'imbibition', word_class: 'N' }
  ],
  indulgent: [
    { word: 'indulge', word_class: 'V' },
    { word: 'indulging', word_class: 'N' },
    { word: 'indulgence', word_class: 'N' },
    { word: 'indulgent', word_class: 'AJ' },
    { word: 'indulgently', word_class: 'AV' }
  ],
  connote: [
    { word: 'connote', word_class: 'V' },
    { word: 'connotation', word_class: 'N' },
    { word: 'connotative', word_class: 'AJ' },
    { word: 'connotational', word_class: 'AJ' }
  ],
  combustive: [
    { word: 'combust', word_class: 'V' },
    { word: 'combustion', word_class: 'N' },
    { word: 'combustible', word_class: 'N' },
    { word: 'combustive', word_class: 'AJ' },
    { word: 'combustible', word_class: 'AJ' }
  ],
  visiting: [
    { word: 'visit', word_class: 'N' },
    { word: 'visit', word_class: 'V' },
    { word: 'visitor', word_class: 'N' },
    { word: 'visitant', word_class: 'N' },
    { word: 'visiting', word_class: 'N' },
    { word: 'visiting', word_class: 'AJ' },
    { word: 'visitation', word_class: 'N' }
  ],
  complacence: [
    { word: 'complacence', word_class: 'N' },
    { word: 'complacency', word_class: 'N' },
    { word: 'complacent', word_class: 'AJ' },
    { word: 'complacently', word_class: 'AV' }
  ],
  auspiciously: [
    { word: 'auspicious', word_class: 'AJ' },
    { word: 'auspiciously', word_class: 'AV' },
    { word: 'auspiciousness', word_class: 'N' }
  ],
  falsely: [
    { word: 'false', word_class: 'AJ' },
    { word: 'false', word_class: 'AV' },
    { word: 'falsely', word_class: 'AV' },
    { word: 'falsehood', word_class: 'N' },
    { word: 'falseness', word_class: 'N' }
  ],
  snorkel: [
    { word: 'snorkel', word_class: 'N' },
    { word: 'snorkel', word_class: 'V' },
    { word: 'snorkeling', word_class: 'N' }
  ],
  deliverer: [
    { word: 'deliver', word_class: 'N' },
    { word: 'deliver', word_class: 'V' },
    { word: 'delivery', word_class: 'N' },
    { word: 'deliverer', word_class: 'N' },
    { word: 'delivery', word_class: 'AJ' },
    { word: 'deliverance', word_class: 'N' }
  ],
  dissection: [
    { word: 'dissect', word_class: 'N' },
    { word: 'dissect', word_class: 'V' },
    { word: 'dissected', word_class: 'AJ' },
    { word: 'dissection', word_class: 'N' }
  ],
  practicably: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  earn: [
    { word: 'earn', word_class: 'V' },
    { word: 'earner', word_class: 'N' },
    { word: 'earned', word_class: 'AJ' },
    { word: 'earning', word_class: 'N' },
    { word: 'earnings', word_class: 'N' }
  ],
  daydream: [
    { word: 'daydream', word_class: 'N' },
    { word: 'daydream', word_class: 'V' },
    { word: 'daydreamer', word_class: 'N' }
  ],
  mental: [
    { word: 'mental', word_class: 'AJ' },
    { word: 'mentalism', word_class: 'N' },
    { word: 'mentality', word_class: 'N' },
    { word: 'mentally', word_class: 'AV' }
  ],
  edit: [
    { word: 'edit', word_class: 'V' },
    { word: 'editor', word_class: 'N' },
    { word: 'edited', word_class: 'AJ' },
    { word: 'editing', word_class: 'N' },
    { word: 'edition', word_class: 'N' }
  ],
  awning: [{ word: 'awn', word_class: 'N' }, { word: 'awned', word_class: 'AJ' }, { word: 'awning', word_class: 'N' }],
  funds: [
    { word: 'fund', word_class: 'N' },
    { word: 'fund', word_class: 'V' },
    { word: 'funds', word_class: 'N' },
    { word: 'funded', word_class: 'AJ' },
    { word: 'funding', word_class: 'N' }
  ],
  stomatal: [
    { word: 'stomatal', word_class: 'AJ' },
    { word: 'stomatitis', word_class: 'N' },
    { word: 'stomatous', word_class: 'AJ' }
  ],
  helper: [
    { word: 'help', word_class: 'N' },
    { word: 'help', word_class: 'V' },
    { word: 'helper', word_class: 'N' },
    { word: 'helping', word_class: 'N' },
    { word: 'helpful', word_class: 'AJ' },
    { word: 'helpfulness', word_class: 'N' }
  ],
  contraband: [
    { word: 'contraband', word_class: 'N' },
    { word: 'contraband', word_class: 'V' },
    { word: 'contraband', word_class: 'AJ' }
  ],
  axone: [{ word: 'axon', word_class: 'N' }, { word: 'axone', word_class: 'N' }, { word: 'axonal', word_class: 'AJ' }],
  muhammadan: [
    { word: 'muhammadan', word_class: 'N' },
    { word: 'muhammadan', word_class: 'AJ' },
    { word: 'muhammadanism', word_class: 'N' }
  ],
  cowled: [
    { word: 'cowl', word_class: 'N' },
    { word: 'cowl', word_class: 'V' },
    { word: 'cowled', word_class: 'AJ' },
    { word: 'cowling', word_class: 'N' }
  ],
  sideways: [
    { word: 'sideway', word_class: 'AJ' },
    { word: 'sideway', word_class: 'AV' },
    { word: 'sideways', word_class: 'AJ' },
    { word: 'sideways', word_class: 'AV' }
  ],
  pester: [
    { word: 'pester', word_class: 'V' },
    { word: 'pesterer', word_class: 'N' },
    { word: 'pestered', word_class: 'AJ' },
    { word: 'pestering', word_class: 'AJ' }
  ],
  denominational: [
    { word: 'denominate', word_class: 'V' },
    { word: 'denominator', word_class: 'N' },
    { word: 'denomination', word_class: 'N' },
    { word: 'denominational', word_class: 'AJ' }
  ],
  toller: [
    { word: 'toll', word_class: 'N' },
    { word: 'toll', word_class: 'V' },
    { word: 'toller', word_class: 'N' },
    { word: 'tollman', word_class: 'N' }
  ],
  radiographer: [
    { word: 'radiograph', word_class: 'N' },
    { word: 'radiographer', word_class: 'N' },
    { word: 'radiographic', word_class: 'AJ' }
  ],
  liberal: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  husband: [
    { word: 'husband', word_class: 'N' },
    { word: 'husband', word_class: 'V' },
    { word: 'husbandry', word_class: 'N' },
    { word: 'husbandman', word_class: 'N' }
  ],
  discrimination: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  hospitalization: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  gruesomely: [
    { word: 'gruesome', word_class: 'AJ' },
    { word: 'gruesomely', word_class: 'AV' },
    { word: 'gruesomeness', word_class: 'N' }
  ],
  darts: [
    { word: 'dart', word_class: 'N' },
    { word: 'dart', word_class: 'V' },
    { word: 'darts', word_class: 'N' },
    { word: 'darter', word_class: 'N' },
    { word: 'darting', word_class: 'AJ' }
  ],
  thrillful: [
    { word: 'thrill', word_class: 'N' },
    { word: 'thrill', word_class: 'V' },
    { word: 'thriller', word_class: 'N' },
    { word: 'thrilled', word_class: 'AJ' },
    { word: 'thrillful', word_class: 'AJ' },
    { word: 'thrilling', word_class: 'AJ' }
  ],
  indefatigation: [
    { word: 'indefatigable', word_class: 'AJ' },
    { word: 'indefatigably', word_class: 'AV' },
    { word: 'indefatigation', word_class: 'N' },
    { word: 'indefatigability', word_class: 'N' },
    { word: 'indefatigableness', word_class: 'N' }
  ],
  discursive: [
    { word: 'discursive', word_class: 'AJ' },
    { word: 'discursively', word_class: 'AV' },
    { word: 'discursiveness', word_class: 'N' }
  ],
  twitching: [
    { word: 'twitch', word_class: 'N' },
    { word: 'twitch', word_class: 'V' },
    { word: 'twitching', word_class: 'N' }
  ],
  analogical: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  declamation: [
    { word: 'declaim', word_class: 'N' },
    { word: 'declaim', word_class: 'V' },
    { word: 'declamation', word_class: 'N' },
    { word: 'declamatory', word_class: 'AJ' }
  ],
  discreetness: [
    { word: 'discreet', word_class: 'AJ' },
    { word: 'discreetness', word_class: 'N' },
    { word: 'discretionary', word_class: 'AJ' }
  ],
  bereaved: [
    { word: 'bereave', word_class: 'V' },
    { word: 'bereaved', word_class: 'AJ' },
    { word: 'bereavement', word_class: 'N' }
  ],
  appurtenances: [
    { word: 'appurtenance', word_class: 'N' },
    { word: 'appurtenant', word_class: 'AJ' },
    { word: 'appurtenances', word_class: 'N' }
  ],
  merchandiser: [
    { word: 'merchandise', word_class: 'N' },
    { word: 'merchandise', word_class: 'V' },
    { word: 'merchandiser', word_class: 'N' },
    { word: 'merchandising', word_class: 'N' },
    { word: 'merchandisement', word_class: 'N' }
  ],
  panelling: [
    { word: 'panel', word_class: 'N' },
    { word: 'panel', word_class: 'V' },
    { word: 'paneling', word_class: 'N' },
    { word: 'panelist', word_class: 'N' },
    { word: 'paneled', word_class: 'AJ' },
    { word: 'panelling', word_class: 'N' }
  ],
  mounting: [
    { word: 'mount', word_class: 'N' },
    { word: 'mount', word_class: 'V' },
    { word: 'mounted', word_class: 'AJ' },
    { word: 'mounting', word_class: 'N' }
  ],
  punting: [
    { word: 'punt', word_class: 'N' },
    { word: 'punt', word_class: 'V' },
    { word: 'punter', word_class: 'N' },
    { word: 'punting', word_class: 'N' }
  ],
  colloquial: [
    { word: 'colloquy', word_class: 'N' },
    { word: 'colloquial', word_class: 'AJ' },
    { word: 'colloquialism', word_class: 'N' },
    { word: 'colloquially', word_class: 'AV' }
  ],
  escapee: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  treasure: [
    { word: 'treasure', word_class: 'N' },
    { word: 'treasure', word_class: 'V' },
    { word: 'treasurer', word_class: 'N' },
    { word: 'treasured', word_class: 'AJ' }
  ],
  skirmisher: [
    { word: 'skirmish', word_class: 'N' },
    { word: 'skirmish', word_class: 'V' },
    { word: 'skirmisher', word_class: 'N' }
  ],
  ordainment: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  integrative: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  notate: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  typically: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  blinking: [
    { word: 'blink', word_class: 'N' },
    { word: 'blink', word_class: 'V' },
    { word: 'blinks', word_class: 'N' },
    { word: 'blinker', word_class: 'N' },
    { word: 'blinker', word_class: 'V' },
    { word: 'blinking', word_class: 'AJ' }
  ],
  methodists: [
    { word: 'methodist', word_class: 'N' },
    { word: 'methodist', word_class: 'AJ' },
    { word: 'methodists', word_class: 'N' }
  ],
  anthropocentrism: [
    { word: 'anthropocentric', word_class: 'AJ' },
    { word: 'anthropocentrism', word_class: 'N' },
    { word: 'anthropocentricity', word_class: 'N' }
  ],
  blooming: [
    { word: 'bloom', word_class: 'N' },
    { word: 'bloom', word_class: 'V' },
    { word: 'blooming', word_class: 'N' },
    { word: 'blooming', word_class: 'AJ' }
  ],
  sustained: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  ready: [
    { word: 'ready', word_class: 'N' },
    { word: 'ready', word_class: 'V' },
    { word: 'ready', word_class: 'AJ' },
    { word: 'readying', word_class: 'N' },
    { word: 'readiness', word_class: 'N' }
  ],
  demographic: [
    { word: 'demographer', word_class: 'N' },
    { word: 'demographic', word_class: 'N' },
    { word: 'demographic', word_class: 'AJ' }
  ],
  guarantee: [
    { word: 'guarantee', word_class: 'N' },
    { word: 'guarantee', word_class: 'V' },
    { word: 'guaranteed', word_class: 'AJ' }
  ],
  backpack: [
    { word: 'backpack', word_class: 'N' },
    { word: 'backpack', word_class: 'V' },
    { word: 'backpacking', word_class: 'N' }
  ],
  incapacitate: [
    { word: 'incapacity', word_class: 'N' },
    { word: 'incapacitate', word_class: 'V' },
    { word: 'incapacitated', word_class: 'AJ' },
    { word: 'incapacitation', word_class: 'N' },
    { word: 'incapacitating', word_class: 'AJ' }
  ],
  unobtrusively: [
    { word: 'unobtrusive', word_class: 'AJ' },
    { word: 'unobtrusively', word_class: 'AV' },
    { word: 'unobtrusiveness', word_class: 'N' }
  ],
  willful: [
    { word: 'will', word_class: 'N' },
    { word: 'will', word_class: 'V' },
    { word: 'willing', word_class: 'N' },
    { word: 'willful', word_class: 'AJ' },
    { word: 'willing', word_class: 'AJ' },
    { word: 'willfulness', word_class: 'N' }
  ],
  lately: [
    { word: 'late', word_class: 'AJ' },
    { word: 'late', word_class: 'AV' },
    { word: 'lately', word_class: 'AV' },
    { word: 'lateness', word_class: 'N' }
  ],
  denigrative: [
    { word: 'denigrate', word_class: 'V' },
    { word: 'denigration', word_class: 'N' },
    { word: 'denigrating', word_class: 'AJ' },
    { word: 'denigrative', word_class: 'AJ' }
  ],
  mentality: [
    { word: 'mental', word_class: 'AJ' },
    { word: 'mentalism', word_class: 'N' },
    { word: 'mentality', word_class: 'N' },
    { word: 'mentally', word_class: 'AV' }
  ],
  muslim: [
    { word: 'muslim', word_class: 'N' },
    { word: 'muslim', word_class: 'AJ' },
    { word: 'muslimism', word_class: 'N' }
  ],
  comprehensibly: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  calendric: [
    { word: 'calendar', word_class: 'N' },
    { word: 'calendar', word_class: 'V' },
    { word: 'calendric', word_class: 'AJ' },
    { word: 'calendrical', word_class: 'AJ' }
  ],
  sown: [
    { word: 'sow', word_class: 'N' },
    { word: 'sow', word_class: 'V' },
    { word: 'sower', word_class: 'N' },
    { word: 'sown', word_class: 'AJ' }
  ],
  highjacker: [
    { word: 'highjack', word_class: 'N' },
    { word: 'highjack', word_class: 'V' },
    { word: 'highjacker', word_class: 'N' },
    { word: 'highjacking', word_class: 'N' }
  ],
  fleeting: [
    { word: 'fleet', word_class: 'N' },
    { word: 'fleet', word_class: 'V' },
    { word: 'fleet', word_class: 'AJ' },
    { word: 'fleeting', word_class: 'AJ' },
    { word: 'fleetness', word_class: 'N' }
  ],
  bond: [
    { word: 'bond', word_class: 'N' },
    { word: 'bond', word_class: 'V' },
    { word: 'bond', word_class: 'AJ' },
    { word: 'bonded', word_class: 'AJ' },
    { word: 'bonding', word_class: 'N' },
    { word: 'bondman', word_class: 'N' }
  ],
  leering: [
    { word: 'leer', word_class: 'N' },
    { word: 'leer', word_class: 'V' },
    { word: 'leering', word_class: 'AJ' }
  ],
  belie: [
    { word: 'belie', word_class: 'N' },
    { word: 'belie', word_class: 'V' },
    { word: 'belying', word_class: 'AJ' }
  ],
  fostered: [
    { word: 'foster', word_class: 'V' },
    { word: 'foster', word_class: 'AJ' },
    { word: 'fosterage', word_class: 'N' },
    { word: 'fostered', word_class: 'AJ' },
    { word: 'fostering', word_class: 'N' }
  ],
  equalized: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  plunge: [
    { word: 'plunge', word_class: 'N' },
    { word: 'plunge', word_class: 'V' },
    { word: 'plunger', word_class: 'N' }
  ],
  sifter: [
    { word: 'sift', word_class: 'V' },
    { word: 'sifter', word_class: 'N' },
    { word: 'sifting', word_class: 'N' }
  ],
  insulation: [
    { word: 'insulant', word_class: 'N' },
    { word: 'insulate', word_class: 'V' },
    { word: 'insulator', word_class: 'N' },
    { word: 'insulation', word_class: 'N' }
  ],
  looker: [
    { word: 'look', word_class: 'N' },
    { word: 'look', word_class: 'V' },
    { word: 'looker', word_class: 'N' },
    { word: 'looking', word_class: 'N' },
    { word: 'looking', word_class: 'AJ' }
  ],
  routed: [
    { word: 'rout', word_class: 'N' },
    { word: 'rout', word_class: 'V' },
    { word: 'route', word_class: 'V' },
    { word: 'route', word_class: 'N' },
    { word: 'routed', word_class: 'AJ' }
  ],
  abominably: [
    { word: 'abominate', word_class: 'V' },
    { word: 'abominator', word_class: 'N' },
    { word: 'abominable', word_class: 'AJ' },
    { word: 'abominably', word_class: 'AV' },
    { word: 'abomination', word_class: 'N' }
  ],
  emblematical: [
    { word: 'emblematic', word_class: 'AJ' },
    { word: 'emblematize', word_class: 'V' },
    { word: 'emblematical', word_class: 'AJ' },
    { word: 'emblematization', word_class: 'N' }
  ],
  optionally: [
    { word: 'ope', word_class: 'V' },
    { word: 'opal', word_class: 'N' },
    { word: 'opt', word_class: 'V' },
    { word: 'option', word_class: 'N' },
    { word: 'optative', word_class: 'N' },
    { word: 'optative', word_class: 'AJ' },
    { word: 'optional', word_class: 'AJ' },
    { word: 'optionally', word_class: 'AV' }
  ],
  egotistic: [
    { word: 'egotist', word_class: 'N' },
    { word: 'egotistic', word_class: 'AJ' },
    { word: 'egotistical', word_class: 'AJ' },
    { word: 'egotistically', word_class: 'AV' }
  ],
  immorality: [
    { word: 'immoral', word_class: 'AJ' },
    { word: 'immorality', word_class: 'N' },
    { word: 'immorally', word_class: 'AV' }
  ],
  corrupting: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  copy: [
    { word: 'copy', word_class: 'N' },
    { word: 'copy', word_class: 'V' },
    { word: 'copier', word_class: 'N' },
    { word: 'copied', word_class: 'AJ' },
    { word: 'copying', word_class: 'N' },
    { word: 'copyist', word_class: 'N' }
  ],
  engrave: [
    { word: 'engrave', word_class: 'V' },
    { word: 'engraver', word_class: 'N' },
    { word: 'engraved', word_class: 'AJ' },
    { word: 'engraving', word_class: 'N' }
  ],
  bottle: [
    { word: 'bottle', word_class: 'N' },
    { word: 'bottle', word_class: 'V' },
    { word: 'bottleful', word_class: 'N' },
    { word: 'bottleful', word_class: 'AJ' }
  ],
  tuck: [
    { word: 'tuck', word_class: 'N' },
    { word: 'tuck', word_class: 'V' },
    { word: 'tucker', word_class: 'N' },
    { word: 'tucker', word_class: 'V' },
    { word: 'tucked', word_class: 'AJ' }
  ],
  interlineate: [
    { word: 'interline', word_class: 'V' },
    { word: 'interlining', word_class: 'N' },
    { word: 'interlineal', word_class: 'AJ' },
    { word: 'interlineate', word_class: 'V' },
    { word: 'interlineation', word_class: 'N' }
  ],
  computation: [
    { word: 'compute', word_class: 'V' },
    { word: 'computer', word_class: 'N' },
    { word: 'computable', word_class: 'AJ' },
    { word: 'computation', word_class: 'N' },
    { word: 'computational', word_class: 'AJ' }
  ],
  farad: [
    { word: 'farad', word_class: 'N' },
    { word: 'faradise', word_class: 'V' },
    { word: 'faradize', word_class: 'V' },
    { word: 'faradisation', word_class: 'N' },
    { word: 'faradization', word_class: 'N' }
  ],
  compressor: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  alphabetisation: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  sublimination: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  repulsive: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  disputatiously: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  skilled: [
    { word: 'skill', word_class: 'N' },
    { word: 'skilful', word_class: 'AJ' },
    { word: 'skilled', word_class: 'AJ' },
    { word: 'skillful', word_class: 'AJ' },
    { word: 'skillfulness', word_class: 'N' }
  ],
  authoritatively: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  sales: [
    { word: 'sale', word_class: 'N' },
    { word: 'sell', word_class: 'N' },
    { word: 'sell', word_class: 'V' },
    { word: 'sales', word_class: 'N' },
    { word: 'seller', word_class: 'N' },
    { word: 'selling', word_class: 'N' },
    { word: 'salable', word_class: 'AJ' },
    { word: 'saleable', word_class: 'AJ' },
    { word: 'salability', word_class: 'N' }
  ],
  offsetting: [
    { word: 'offset', word_class: 'N' },
    { word: 'offset', word_class: 'V' },
    { word: 'offsetting', word_class: 'AJ' }
  ],
  consubstantial: [
    { word: 'consubstantial', word_class: 'AJ' },
    { word: 'consubstantiate', word_class: 'V' },
    { word: 'consubstantiation', word_class: 'N' }
  ],
  panderer: [
    { word: 'pander', word_class: 'N' },
    { word: 'pander', word_class: 'V' },
    { word: 'panderer', word_class: 'N' }
  ],
  settler: [
    { word: 'settle', word_class: 'N' },
    { word: 'settle', word_class: 'V' },
    { word: 'settler', word_class: 'N' },
    { word: 'settled', word_class: 'AJ' },
    { word: 'settling', word_class: 'N' },
    { word: 'settlement', word_class: 'N' }
  ],
  triple: [
    { word: 'triple', word_class: 'N' },
    { word: 'triple', word_class: 'V' },
    { word: 'triple', word_class: 'AJ' },
    { word: 'tripling', word_class: 'N' }
  ],
  precipitately: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  detachable: [
    { word: 'detach', word_class: 'V' },
    { word: 'detached', word_class: 'AJ' },
    { word: 'detachment', word_class: 'N' },
    { word: 'detachable', word_class: 'AJ' }
  ],
  calligrapher: [
    { word: 'calligraph', word_class: 'N' },
    { word: 'calligrapher', word_class: 'N' },
    { word: 'calligraphic', word_class: 'AJ' },
    { word: 'calligraphical', word_class: 'AJ' }
  ],
  pruning: [
    { word: 'prune', word_class: 'N' },
    { word: 'prune', word_class: 'V' },
    { word: 'pruner', word_class: 'N' },
    { word: 'pruned', word_class: 'AJ' },
    { word: 'pruning', word_class: 'N' }
  ],
  asterisk: [
    { word: 'asterisk', word_class: 'N' },
    { word: 'asterisk', word_class: 'V' },
    { word: 'asterisked', word_class: 'AJ' }
  ],
  skeletonization: [
    { word: 'skeleton', word_class: 'N' },
    { word: 'skeletal', word_class: 'AJ' },
    { word: 'skeletonise', word_class: 'V' },
    { word: 'skeletonize', word_class: 'V' },
    { word: 'skeletonization', word_class: 'N' }
  ],
  plagiarization: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  execution: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  discretional: [
    { word: 'discrete', word_class: 'N' },
    { word: 'discrete', word_class: 'AJ' },
    { word: 'discretion', word_class: 'N' },
    { word: 'discreteness', word_class: 'N' },
    { word: 'discretional', word_class: 'AJ' }
  ],
  modeler: [
    { word: 'model', word_class: 'N' },
    { word: 'model', word_class: 'V' },
    { word: 'model', word_class: 'AJ' },
    { word: 'modeler', word_class: 'N' },
    { word: 'modeled', word_class: 'AJ' },
    { word: 'modeling', word_class: 'N' },
    { word: 'modeller', word_class: 'N' },
    { word: 'modelling', word_class: 'N' }
  ],
  desynchronize: [
    { word: 'desynchronize', word_class: 'V' },
    { word: 'desynchronizing', word_class: 'N' },
    { word: 'desynchronization', word_class: 'N' }
  ],
  completion: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  atypic: [
    { word: 'atypic', word_class: 'AJ' },
    { word: 'atypical', word_class: 'AJ' },
    { word: 'atypicality', word_class: 'N' },
    { word: 'atypically', word_class: 'AV' }
  ],
  ignominy: [
    { word: 'ignominy', word_class: 'N' },
    { word: 'ignominious', word_class: 'AJ' },
    { word: 'ignominiously', word_class: 'AV' },
    { word: 'ignominiousness', word_class: 'N' }
  ],
  inventorying: [
    { word: 'inventory', word_class: 'N' },
    { word: 'inventory', word_class: 'V' },
    { word: 'inventory', word_class: 'AJ' },
    { word: 'inventorying', word_class: 'N' }
  ],
  compress: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  unwearying: [
    { word: 'unweary', word_class: 'AJ' },
    { word: 'unwearied', word_class: 'AJ' },
    { word: 'unwearying', word_class: 'AJ' }
  ],
  rejoicing: [
    { word: 'rejoice', word_class: 'V' },
    { word: 'rejoicing', word_class: 'N' },
    { word: 'rejoicing', word_class: 'AJ' }
  ],
  catastrophe: [
    { word: 'catastrophe', word_class: 'N' },
    { word: 'catastrophic', word_class: 'AJ' },
    { word: 'catastrophically', word_class: 'AV' }
  ],
  homologize: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  optic: [
    { word: 'optic', word_class: 'N' },
    { word: 'optic', word_class: 'AJ' },
    { word: 'optics', word_class: 'N' },
    { word: 'optical', word_class: 'AJ' },
    { word: 'optically', word_class: 'AV' }
  ],
  creditably: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  booksell: [
    { word: 'booksell', word_class: 'V' },
    { word: 'bookseller', word_class: 'N' },
    { word: 'bookselling', word_class: 'N' }
  ],
  tingling: [
    { word: 'tingle', word_class: 'N' },
    { word: 'tingle', word_class: 'V' },
    { word: 'tingly', word_class: 'AJ' },
    { word: 'tingling', word_class: 'N' },
    { word: 'tingling', word_class: 'AJ' }
  ],
  ale: [{ word: 'al', word_class: 'N' }, { word: 'ale', word_class: 'N' }, { word: 'als', word_class: 'N' }],
  vulcanisation: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  versifier: [
    { word: 'versify', word_class: 'V' },
    { word: 'versifier', word_class: 'N' },
    { word: 'versification', word_class: 'N' }
  ],
  retention: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  wheeled: [
    { word: 'wheel', word_class: 'N' },
    { word: 'wheel', word_class: 'V' },
    { word: 'wheels', word_class: 'N' },
    { word: 'wheeler', word_class: 'N' },
    { word: 'wheeled', word_class: 'AJ' },
    { word: 'wheeling', word_class: 'N' },
    { word: 'wheeling', word_class: 'AJ' }
  ],
  dispensability: [
    { word: 'dispense', word_class: 'V' },
    { word: 'dispenser', word_class: 'N' },
    { word: 'dispensed', word_class: 'AJ' },
    { word: 'dispensing', word_class: 'N' },
    { word: 'dispensable', word_class: 'AJ' },
    { word: 'dispensation', word_class: 'N' },
    { word: 'dispensability', word_class: 'N' },
    { word: 'dispensableness', word_class: 'N' }
  ],
  severalize: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  retaliator: [
    { word: 'retaliate', word_class: 'V' },
    { word: 'retaliator', word_class: 'N' },
    { word: 'retaliation', word_class: 'N' },
    { word: 'retaliative', word_class: 'AJ' }
  ],
  sundry: [
    { word: 'sundry', word_class: 'N' },
    { word: 'sundry', word_class: 'AJ' },
    { word: 'sundries', word_class: 'N' }
  ],
  perplex: [
    { word: 'perplex', word_class: 'V' },
    { word: 'perplexed', word_class: 'AJ' },
    { word: 'perplexity', word_class: 'N' },
    { word: 'perplexing', word_class: 'AJ' }
  ],
  tripling: [
    { word: 'triple', word_class: 'N' },
    { word: 'triple', word_class: 'V' },
    { word: 'triple', word_class: 'AJ' },
    { word: 'tripling', word_class: 'N' }
  ],
  impovempoverish: [
    { word: 'impoverish', word_class: 'V' },
    { word: 'impoverished', word_class: 'AJ' },
    { word: 'impoverishment', word_class: 'N' },
    { word: 'impovempoverish', word_class: 'V' },
    { word: 'impovempoverishment', word_class: 'N' }
  ],
  suffocating: [
    { word: 'suffocate', word_class: 'V' },
    { word: 'suffocation', word_class: 'N' },
    { word: 'suffocating', word_class: 'AJ' },
    { word: 'suffocative', word_class: 'AJ' }
  ],
  cycling: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  ennoblement: [
    { word: 'ennoble', word_class: 'V' },
    { word: 'ennobling', word_class: 'AJ' },
    { word: 'ennoblement', word_class: 'N' }
  ],
  commemoration: [
    { word: 'commemorate', word_class: 'V' },
    { word: 'commemoration', word_class: 'N' },
    { word: 'commemorating', word_class: 'AJ' },
    { word: 'commemorative', word_class: 'AJ' }
  ],
  beading: [
    { word: 'bead', word_class: 'N' },
    { word: 'bead', word_class: 'V' },
    { word: 'beads', word_class: 'N' },
    { word: 'beaded', word_class: 'AJ' },
    { word: 'beading', word_class: 'N' }
  ],
  wilting: [
    { word: 'wilt', word_class: 'N' },
    { word: 'wilt', word_class: 'V' },
    { word: 'wilted', word_class: 'AJ' },
    { word: 'wilting', word_class: 'N' }
  ],
  felted: [
    { word: 'felt', word_class: 'N' },
    { word: 'felt', word_class: 'V' },
    { word: 'felt', word_class: 'AJ' },
    { word: 'felted', word_class: 'AJ' }
  ],
  temporisation: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  fashionable: [
    { word: 'fashion', word_class: 'N' },
    { word: 'fashion', word_class: 'V' },
    { word: 'fashions', word_class: 'N' },
    { word: 'fashioned', word_class: 'AJ' },
    { word: 'fashioning', word_class: 'N' },
    { word: 'fashionable', word_class: 'AJ' },
    { word: 'fashionably', word_class: 'AV' }
  ],
  inexpensive: [
    { word: 'inexpensive', word_class: 'AJ' },
    { word: 'inexpensively', word_class: 'AV' },
    { word: 'inexpensiveness', word_class: 'N' }
  ],
  inductance: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  glut: [{ word: 'glut', word_class: 'N' }, { word: 'glut', word_class: 'V' }, { word: 'glutted', word_class: 'AJ' }],
  cellularity: [
    { word: 'cell', word_class: 'N' },
    { word: 'cellular', word_class: 'AJ' },
    { word: 'cellularity', word_class: 'N' }
  ],
  amorally: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  tiding: [
    { word: 'tide', word_class: 'N' },
    { word: 'tide', word_class: 'V' },
    { word: 'tiding', word_class: 'N' },
    { word: 'tidings', word_class: 'N' }
  ],
  stalwartness: [
    { word: 'stalwart', word_class: 'N' },
    { word: 'stalwart', word_class: 'AJ' },
    { word: 'stalwartness', word_class: 'N' }
  ],
  victimized: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  awed: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  mailing: [
    { word: 'mail', word_class: 'N' },
    { word: 'mail', word_class: 'V' },
    { word: 'mailer', word_class: 'N' },
    { word: 'mailman', word_class: 'N' },
    { word: 'mailing', word_class: 'N' },
    { word: 'mailed', word_class: 'AJ' }
  ],
  dissension: [
    { word: 'dissent', word_class: 'N' },
    { word: 'dissent', word_class: 'V' },
    { word: 'dissenter', word_class: 'N' },
    { word: 'dissident', word_class: 'N' },
    { word: 'dissension', word_class: 'N' },
    { word: 'dissidence', word_class: 'N' },
    { word: 'dissident', word_class: 'AJ' },
    { word: 'dissenting', word_class: 'AJ' }
  ],
  aetiology: [
    { word: 'aetiology', word_class: 'N' },
    { word: 'aetiologic', word_class: 'AJ' },
    { word: 'aetiological', word_class: 'AJ' }
  ],
  disclose: [
    { word: 'disclose', word_class: 'V' },
    { word: 'disclosed', word_class: 'AJ' },
    { word: 'disclosure', word_class: 'N' },
    { word: 'disclosing', word_class: 'AJ' }
  ],
  inquiring: [
    { word: 'inquire', word_class: 'V' },
    { word: 'inquiry', word_class: 'N' },
    { word: 'inquirer', word_class: 'N' },
    { word: 'inquiring', word_class: 'N' },
    { word: 'inquiring', word_class: 'AJ' },
    { word: 'inquisition', word_class: 'N' },
    { word: 'inquisitive', word_class: 'AJ' },
    { word: 'inquisitively', word_class: 'AV' },
    { word: 'inquisitiveness', word_class: 'N' }
  ],
  maintainable: [
    { word: 'maintain', word_class: 'V' },
    { word: 'maintained', word_class: 'AJ' },
    { word: 'maintenance', word_class: 'N' },
    { word: 'maintainable', word_class: 'AJ' }
  ],
  accompanyist: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  phonemics: [
    { word: 'phoneme', word_class: 'N' },
    { word: 'phonemic', word_class: 'AJ' },
    { word: 'phonemic', word_class: 'AV' },
    { word: 'phonemics', word_class: 'N' }
  ],
  battered: [
    { word: 'batter', word_class: 'N' },
    { word: 'batter', word_class: 'V' },
    { word: 'battery', word_class: 'N' },
    { word: 'battery', word_class: 'AJ' },
    { word: 'battered', word_class: 'AJ' },
    { word: 'battering', word_class: 'N' }
  ],
  stertorous: [
    { word: 'stertor', word_class: 'N' },
    { word: 'stertorous', word_class: 'AJ' },
    { word: 'stertorously', word_class: 'AV' }
  ],
  hay: [{ word: 'hay', word_class: 'N' }, { word: 'hay', word_class: 'V' }, { word: 'haying', word_class: 'N' }],
  sobriety: [
    { word: 'sober', word_class: 'V' },
    { word: 'sober', word_class: 'AJ' },
    { word: 'sobriety', word_class: 'N' },
    { word: 'sobering', word_class: 'AJ' },
    { word: 'soberness', word_class: 'N' }
  ],
  downright: [
    { word: 'downright', word_class: 'AJ' },
    { word: 'downright', word_class: 'AV' },
    { word: 'downrightness', word_class: 'N' }
  ],
  analytical: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  aroused: [
    { word: 'arouse', word_class: 'V' },
    { word: 'arousal', word_class: 'N' },
    { word: 'aroused', word_class: 'AJ' }
  ],
  subordination: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  nodule: [
    { word: 'nodule', word_class: 'N' },
    { word: 'nodular', word_class: 'AJ' },
    { word: 'noduled', word_class: 'AJ' },
    { word: 'nodulated', word_class: 'AJ' }
  ],
  dieting: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  salvor: [
    { word: 'salve', word_class: 'N' },
    { word: 'salve', word_class: 'V' },
    { word: 'salver', word_class: 'N' },
    { word: 'salvor', word_class: 'N' },
    { word: 'salving', word_class: 'AJ' },
    { word: 'salvation', word_class: 'N' }
  ],
  acts: [
    { word: 'act', word_class: 'N' },
    { word: 'act', word_class: 'V' },
    { word: 'acts', word_class: 'N' },
    { word: 'actor', word_class: 'N' },
    { word: 'acting', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'actress', word_class: 'N' }
  ],
  lyophilized: [
    { word: 'lyophilize', word_class: 'V' },
    { word: 'lyophilized', word_class: 'AJ' },
    { word: 'lyophilization', word_class: 'N' }
  ],
  moderacy: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  busman: [
    { word: 'bus', word_class: 'N' },
    { word: 'bus', word_class: 'V' },
    { word: 'buss', word_class: 'N' },
    { word: 'buss', word_class: 'V' },
    { word: 'busful', word_class: 'N' },
    { word: 'busman', word_class: 'N' },
    { word: 'busful', word_class: 'AJ' },
    { word: 'bussing', word_class: 'N' }
  ],
  periodontic: [
    { word: 'periodontal', word_class: 'AJ' },
    { word: 'periodontic', word_class: 'AJ' },
    { word: 'periodontics', word_class: 'N' },
    { word: 'periodontitis', word_class: 'N' }
  ],
  rasp: [
    { word: 'rasp', word_class: 'N' },
    { word: 'rasp', word_class: 'V' },
    { word: 'raspy', word_class: 'AJ' },
    { word: 'rasping', word_class: 'N' },
    { word: 'rasping', word_class: 'AJ' }
  ],
  anger: [
    { word: 'anger', word_class: 'N' },
    { word: 'anger', word_class: 'V' },
    { word: 'angry', word_class: 'AJ' },
    { word: 'angered', word_class: 'AJ' },
    { word: 'angriness', word_class: 'N' }
  ],
  skier: [
    { word: 'ski', word_class: 'N' },
    { word: 'ski', word_class: 'V' },
    { word: 'skis', word_class: 'N' },
    { word: 'skier', word_class: 'N' },
    { word: 'skiing', word_class: 'N' }
  ],
  'well-wishing': [
    { word: 'well-wisher', word_class: 'N' },
    { word: 'well-wishing', word_class: 'N' },
    { word: 'well-wishing', word_class: 'AJ' }
  ],
  serpent: [
    { word: 'serpent', word_class: 'N' },
    { word: 'serpentes', word_class: 'N' },
    { word: 'serpentine', word_class: 'AJ' }
  ],
  roasted: [
    { word: 'roast', word_class: 'N' },
    { word: 'roast', word_class: 'V' },
    { word: 'roast', word_class: 'AJ' },
    { word: 'roaster', word_class: 'N' },
    { word: 'roasted', word_class: 'AJ' },
    { word: 'roasting', word_class: 'N' }
  ],
  promulgate: [
    { word: 'promulgate', word_class: 'V' },
    { word: 'promulgator', word_class: 'N' },
    { word: 'promulgated', word_class: 'AJ' },
    { word: 'promulgation', word_class: 'N' }
  ],
  warehouse: [
    { word: 'warehouse', word_class: 'N' },
    { word: 'warehouse', word_class: 'V' },
    { word: 'warehouser', word_class: 'N' }
  ],
  fastidiousness: [
    { word: 'fastidious', word_class: 'AJ' },
    { word: 'fastidiously', word_class: 'AV' },
    { word: 'fastidiousness', word_class: 'N' }
  ],
  precedency: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  gallicization: [
    { word: 'gallic', word_class: 'AJ' },
    { word: 'gallicism', word_class: 'N' },
    { word: 'gallicize', word_class: 'V' },
    { word: 'gallicization', word_class: 'N' }
  ],
  immortalisation: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  feathered: [
    { word: 'feather', word_class: 'N' },
    { word: 'feather', word_class: 'V' },
    { word: 'feathered', word_class: 'AJ' },
    { word: 'feathering', word_class: 'N' }
  ],
  punctiliousness: [
    { word: 'punctilious', word_class: 'AJ' },
    { word: 'punctiliously', word_class: 'AV' },
    { word: 'punctiliousness', word_class: 'N' }
  ],
  holy: [{ word: 'holy', word_class: 'N' }, { word: 'holy', word_class: 'AJ' }, { word: 'holiness', word_class: 'N' }],
  occlusion: [
    { word: 'occlude', word_class: 'V' },
    { word: 'occluded', word_class: 'AJ' },
    { word: 'occlusion', word_class: 'N' },
    { word: 'occlusive', word_class: 'N' },
    { word: 'occlusive', word_class: 'AJ' }
  ],
  urination: [
    { word: 'urine', word_class: 'N' },
    { word: 'urinal', word_class: 'N' },
    { word: 'urinal', word_class: 'AJ' },
    { word: 'urinate', word_class: 'V' },
    { word: 'urinary', word_class: 'AJ' },
    { word: 'urination', word_class: 'N' }
  ],
  folding: [
    { word: 'fold', word_class: 'N' },
    { word: 'fold', word_class: 'V' },
    { word: 'fold', word_class: 'AJ' },
    { word: 'folder', word_class: 'N' },
    { word: 'folded', word_class: 'AJ' },
    { word: 'folding', word_class: 'N' },
    { word: 'folding', word_class: 'AJ' }
  ],
  orderly: [
    { word: 'orderly', word_class: 'N' },
    { word: 'orderly', word_class: 'AJ' },
    { word: 'orderliness', word_class: 'N' }
  ],
  recap: [
    { word: 'recap', word_class: 'N' },
    { word: 'recap', word_class: 'V' },
    { word: 'recapitulate', word_class: 'V' },
    { word: 'recapitulation', word_class: 'N' }
  ],
  acumination: [
    { word: 'acuminate', word_class: 'V' },
    { word: 'acuminate', word_class: 'AJ' },
    { word: 'acumination', word_class: 'N' }
  ],
  manful: [
    { word: 'man', word_class: 'V' },
    { word: 'man', word_class: 'N' },
    { word: 'manor', word_class: 'N' },
    { word: 'manned', word_class: 'AJ' },
    { word: 'manful', word_class: 'AJ' },
    { word: 'manfulness', word_class: 'N' }
  ],
  resuscitated: [
    { word: 'resuscitate', word_class: 'V' },
    { word: 'resuscitated', word_class: 'AJ' },
    { word: 'resuscitation', word_class: 'N' }
  ],
  damages: [
    { word: 'damage', word_class: 'N' },
    { word: 'damage', word_class: 'V' },
    { word: 'damages', word_class: 'N' },
    { word: 'damaged', word_class: 'AJ' },
    { word: 'damaging', word_class: 'AJ' }
  ],
  allocate: [
    { word: 'allocate', word_class: 'V' },
    { word: 'allocable', word_class: 'AJ' },
    { word: 'allocation', word_class: 'N' }
  ],
  gravitative: [
    { word: 'gravitate', word_class: 'V' },
    { word: 'gravitation', word_class: 'N' },
    { word: 'gravitative', word_class: 'AJ' },
    { word: 'gravitational', word_class: 'AJ' }
  ],
  licentious: [
    { word: 'licentiate', word_class: 'N' },
    { word: 'licentious', word_class: 'AJ' },
    { word: 'licentiously', word_class: 'AV' },
    { word: 'licentiousness', word_class: 'N' }
  ],
  forage: [
    { word: 'forage', word_class: 'N' },
    { word: 'forage', word_class: 'V' },
    { word: 'foraging', word_class: 'N' }
  ],
  prearrange: [
    { word: 'prearrange', word_class: 'V' },
    { word: 'prearranged', word_class: 'AJ' },
    { word: 'prearrangement', word_class: 'N' }
  ],
  flavoured: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  thermostatic: [
    { word: 'thermostat', word_class: 'N' },
    { word: 'thermostat', word_class: 'V' },
    { word: 'thermostatic', word_class: 'AJ' },
    { word: 'thermostatics', word_class: 'N' },
    { word: 'thermostatically', word_class: 'AV' }
  ],
  submergence: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  nauseate: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  pebbled: [
    { word: 'pebble', word_class: 'N' },
    { word: 'pebbly', word_class: 'AJ' },
    { word: 'pebbled', word_class: 'AJ' }
  ],
  churning: [
    { word: 'churn', word_class: 'N' },
    { word: 'churn', word_class: 'V' },
    { word: 'churning', word_class: 'AJ' }
  ],
  earful: [
    { word: 'ear', word_class: 'N' },
    { word: 'eared', word_class: 'AJ' },
    { word: 'earful', word_class: 'N' },
    { word: 'earful', word_class: 'AJ' },
    { word: 'earring', word_class: 'N' }
  ],
  concord: [
    { word: 'concord', word_class: 'N' },
    { word: 'concord', word_class: 'V' },
    { word: 'concordant', word_class: 'N' },
    { word: 'concordance', word_class: 'N' },
    { word: 'concordant', word_class: 'AJ' }
  ],
  legitimately: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  frolic: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  mathematically: [
    { word: 'mathematic', word_class: 'AJ' },
    { word: 'mathematics', word_class: 'N' },
    { word: 'mathematical', word_class: 'AJ' },
    { word: 'mathematically', word_class: 'AV' }
  ],
  dully: [
    { word: 'dull', word_class: 'V' },
    { word: 'dull', word_class: 'AJ' },
    { word: 'dully', word_class: 'AV' },
    { word: 'dulled', word_class: 'AJ' },
    { word: 'dullness', word_class: 'N' }
  ],
  miniaturise: [
    { word: 'miniature', word_class: 'N' },
    { word: 'miniature', word_class: 'AJ' },
    { word: 'miniaturise', word_class: 'V' },
    { word: 'miniaturize', word_class: 'V' },
    { word: 'miniaturisation', word_class: 'N' },
    { word: 'miniaturization', word_class: 'N' }
  ],
  vacationing: [
    { word: 'vacate', word_class: 'V' },
    { word: 'vacation', word_class: 'N' },
    { word: 'vacation', word_class: 'V' },
    { word: 'vacationer', word_class: 'N' },
    { word: 'vacationing', word_class: 'N' },
    { word: 'vacationist', word_class: 'N' }
  ],
  coated: [
    { word: 'coat', word_class: 'N' },
    { word: 'coat', word_class: 'V' },
    { word: 'coatee', word_class: 'N' },
    { word: 'coated', word_class: 'AJ' },
    { word: 'coating', word_class: 'N' }
  ],
  simmer: [
    { word: 'simmer', word_class: 'N' },
    { word: 'simmer', word_class: 'V' },
    { word: 'simmering', word_class: 'N' }
  ],
  comfortably: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  update: [
    { word: 'update', word_class: 'N' },
    { word: 'update', word_class: 'V' },
    { word: 'updating', word_class: 'N' }
  ],
  depute: [
    { word: 'depute', word_class: 'V' },
    { word: 'deputy', word_class: 'N' },
    { word: 'deputy', word_class: 'AJ' },
    { word: 'deputize', word_class: 'V' },
    { word: 'deputation', word_class: 'N' },
    { word: 'deputization', word_class: 'N' }
  ],
  instal: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  reproachful: [
    { word: 'reproach', word_class: 'N' },
    { word: 'reproach', word_class: 'V' },
    { word: 'reproachful', word_class: 'AJ' },
    { word: 'reproachment', word_class: 'N' }
  ],
  predictable: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  sheathed: [
    { word: 'sheath', word_class: 'N' },
    { word: 'sheathe', word_class: 'V' },
    { word: 'sheathed', word_class: 'AJ' },
    { word: 'sheathing', word_class: 'N' }
  ],
  reveller: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  foraging: [
    { word: 'forage', word_class: 'N' },
    { word: 'forage', word_class: 'V' },
    { word: 'foraging', word_class: 'N' }
  ],
  garnished: [
    { word: 'garnish', word_class: 'N' },
    { word: 'garnish', word_class: 'V' },
    { word: 'garnished', word_class: 'AJ' },
    { word: 'garnishment', word_class: 'N' }
  ],
  hyalinization: [
    { word: 'hyalin', word_class: 'N' },
    { word: 'hyaline', word_class: 'N' },
    { word: 'hyaline', word_class: 'AJ' },
    { word: 'hyalinization', word_class: 'N' }
  ],
  hematologic: [
    { word: 'hematology', word_class: 'N' },
    { word: 'hematologic', word_class: 'AJ' },
    { word: 'hematological', word_class: 'AJ' }
  ],
  expropriate: [
    { word: 'expropriate', word_class: 'V' },
    { word: 'expropriated', word_class: 'AJ' },
    { word: 'expropriation', word_class: 'N' }
  ],
  ethereal: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  frolicker: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  secret: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  lighted: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  acquiescence: [
    { word: 'acquiesce', word_class: 'V' },
    { word: 'acquiescence', word_class: 'N' },
    { word: 'acquiescent', word_class: 'AJ' }
  ],
  adduct: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  flatness: [
    { word: 'flat', word_class: 'N' },
    { word: 'flat', word_class: 'AJ' },
    { word: 'flat', word_class: 'AV' },
    { word: 'flats', word_class: 'N' },
    { word: 'flatness', word_class: 'N' }
  ],
  leased: [
    { word: 'lease', word_class: 'N' },
    { word: 'lease', word_class: 'V' },
    { word: 'lessee', word_class: 'N' },
    { word: 'lessor', word_class: 'N' },
    { word: 'leased', word_class: 'AJ' }
  ],
  migration: [
    { word: 'migrant', word_class: 'N' },
    { word: 'migrate', word_class: 'V' },
    { word: 'migrant', word_class: 'AJ' },
    { word: 'migration', word_class: 'N' }
  ],
  flight: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  sphericalness: [
    { word: 'spheric', word_class: 'AJ' },
    { word: 'spherical', word_class: 'AJ' },
    { word: 'sphericity', word_class: 'N' },
    { word: 'spherically', word_class: 'AV' },
    { word: 'sphericalness', word_class: 'N' }
  ],
  specified: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  brace: [
    { word: 'brace', word_class: 'N' },
    { word: 'brace', word_class: 'V' },
    { word: 'bracer', word_class: 'N' },
    { word: 'braces', word_class: 'N' },
    { word: 'braced', word_class: 'AJ' },
    { word: 'bracing', word_class: 'N' },
    { word: 'bracing', word_class: 'AJ' }
  ],
  religious: [
    { word: 'religious', word_class: 'N' },
    { word: 'religious', word_class: 'AJ' },
    { word: 'religiously', word_class: 'AV' },
    { word: 'religiousness', word_class: 'N' }
  ],
  jesuitical: [
    { word: 'jesuit', word_class: 'N' },
    { word: 'jesuit', word_class: 'AJ' },
    { word: 'jesuitic', word_class: 'AJ' },
    { word: 'jesuitism', word_class: 'N' },
    { word: 'jesuitical', word_class: 'AJ' }
  ],
  resistible: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  snivel: [
    { word: 'snivel', word_class: 'N' },
    { word: 'snivel', word_class: 'V' },
    { word: 'sniveler', word_class: 'N' },
    { word: 'sniveling', word_class: 'N' }
  ],
  regal: [
    { word: 'regal', word_class: 'AJ' },
    { word: 'regale', word_class: 'N' },
    { word: 'regale', word_class: 'V' },
    { word: 'regally', word_class: 'AV' },
    { word: 'regalement', word_class: 'N' }
  ],
  macer: [
    { word: 'macer', word_class: 'N' },
    { word: 'macerate', word_class: 'V' },
    { word: 'maceration', word_class: 'N' },
    { word: 'macerative', word_class: 'AJ' }
  ],
  reflectance: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  drumming: [
    { word: 'drum', word_class: 'N' },
    { word: 'drum', word_class: 'V' },
    { word: 'drumming', word_class: 'N' }
  ],
  fantastically: [
    { word: 'fantast', word_class: 'N' },
    { word: 'fantastic', word_class: 'AJ' },
    { word: 'fantastical', word_class: 'AJ' },
    { word: 'fantastically', word_class: 'AV' }
  ],
  centred: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  anaglyphical: [
    { word: 'anaglyph', word_class: 'N' },
    { word: 'anaglyphic', word_class: 'AJ' },
    { word: 'anaglyphical', word_class: 'AJ' }
  ],
  entitlement: [
    { word: 'entitle', word_class: 'V' },
    { word: 'entitled', word_class: 'AJ' },
    { word: 'entitlement', word_class: 'N' }
  ],
  desiccant: [
    { word: 'desiccant', word_class: 'N' },
    { word: 'desiccate', word_class: 'V' },
    { word: 'desiccate', word_class: 'AJ' },
    { word: 'desiccated', word_class: 'AJ' },
    { word: 'desiccation', word_class: 'N' }
  ],
  taunt: [
    { word: 'taunt', word_class: 'N' },
    { word: 'taunt', word_class: 'V' },
    { word: 'taunting', word_class: 'N' },
    { word: 'taunting', word_class: 'AJ' }
  ],
  velarisation: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  affront: [
    { word: 'affront', word_class: 'N' },
    { word: 'affront', word_class: 'V' },
    { word: 'affronted', word_class: 'AJ' }
  ],
  pass: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  distortable: [
    { word: 'distort', word_class: 'N' },
    { word: 'distort', word_class: 'V' },
    { word: 'distorted', word_class: 'AJ' },
    { word: 'distortion', word_class: 'N' },
    { word: 'distortable', word_class: 'AJ' }
  ],
  frontage: [
    { word: 'front', word_class: 'N' },
    { word: 'front', word_class: 'V' },
    { word: 'front', word_class: 'AJ' },
    { word: 'frontal', word_class: 'N' },
    { word: 'frontage', word_class: 'N' },
    { word: 'frontal', word_class: 'AJ' },
    { word: 'frontally', word_class: 'AV' }
  ],
  wrangle: [
    { word: 'wrangle', word_class: 'N' },
    { word: 'wrangle', word_class: 'V' },
    { word: 'wrangler', word_class: 'N' },
    { word: 'wrangling', word_class: 'N' }
  ],
  slipperiness: [
    { word: 'slip', word_class: 'N' },
    { word: 'slip', word_class: 'V' },
    { word: 'slippage', word_class: 'N' },
    { word: 'slippery', word_class: 'AJ' },
    { word: 'slipping', word_class: 'AJ' },
    { word: 'slipperiness', word_class: 'N' }
  ],
  acquired: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  bushwhacker: [
    { word: 'bushwhack', word_class: 'V' },
    { word: 'bushwhacker', word_class: 'N' },
    { word: 'bushwhacking', word_class: 'AJ' }
  ],
  flickering: [
    { word: 'flick', word_class: 'N' },
    { word: 'flick', word_class: 'V' },
    { word: 'flicker', word_class: 'N' },
    { word: 'flicker', word_class: 'V' },
    { word: 'flickering', word_class: 'AJ' }
  ],
  follow: [
    { word: 'follow', word_class: 'V' },
    { word: 'follower', word_class: 'N' },
    { word: 'followers', word_class: 'N' },
    { word: 'following', word_class: 'N' },
    { word: 'following', word_class: 'AJ' }
  ],
  disenchanting: [
    { word: 'disenchant', word_class: 'V' },
    { word: 'disenchanted', word_class: 'AJ' },
    { word: 'disenchanting', word_class: 'AJ' },
    { word: 'disenchantment', word_class: 'N' }
  ],
  interlacing: [
    { word: 'interlace', word_class: 'V' },
    { word: 'interlaced', word_class: 'AJ' },
    { word: 'interlacing', word_class: 'AJ' },
    { word: 'interlacement', word_class: 'N' }
  ],
  trawler: [
    { word: 'trawl', word_class: 'N' },
    { word: 'trawl', word_class: 'V' },
    { word: 'trawler', word_class: 'N' }
  ],
  compell: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  repairman: [
    { word: 'repair', word_class: 'N' },
    { word: 'repair', word_class: 'V' },
    { word: 'repairer', word_class: 'N' },
    { word: 'repaired', word_class: 'AJ' },
    { word: 'repairman', word_class: 'N' },
    { word: 'reparable', word_class: 'N' },
    { word: 'reparable', word_class: 'AJ' },
    { word: 'reparation', word_class: 'N' },
    { word: 'repairable', word_class: 'AJ' }
  ],
  rigorously: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  hardbacked: [
    { word: 'hardback', word_class: 'N' },
    { word: 'hardback', word_class: 'AJ' },
    { word: 'hardbacked', word_class: 'AJ' }
  ],
  comprehensible: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  drag: [
    { word: 'drag', word_class: 'N' },
    { word: 'drag', word_class: 'V' },
    { word: 'dragee', word_class: 'N' },
    { word: 'dragging', word_class: 'AJ' }
  ],
  swimming: [
    { word: 'swim', word_class: 'N' },
    { word: 'swim', word_class: 'V' },
    { word: 'swimming', word_class: 'N' },
    { word: 'swimming', word_class: 'AJ' }
  ],
  optimistic: [
    { word: 'optimist', word_class: 'N' },
    { word: 'optimistic', word_class: 'AJ' },
    { word: 'optimistically', word_class: 'AV' }
  ],
  daunt: [
    { word: 'daunt', word_class: 'V' },
    { word: 'daunted', word_class: 'AJ' },
    { word: 'daunting', word_class: 'AJ' },
    { word: 'dauntless', word_class: 'AJ' },
    { word: 'dauntlessness', word_class: 'N' }
  ],
  backstage: [
    { word: 'backstage', word_class: 'N' },
    { word: 'backstage', word_class: 'AJ' },
    { word: 'backstage', word_class: 'AV' }
  ],
  alkalic: [
    { word: 'alkalic', word_class: 'AJ' },
    { word: 'alkalize', word_class: 'V' },
    { word: 'alkalizer', word_class: 'N' }
  ],
  bantering: [
    { word: 'banter', word_class: 'N' },
    { word: 'banter', word_class: 'V' },
    { word: 'bantering', word_class: 'AJ' }
  ],
  bucketful: [
    { word: 'bucket', word_class: 'N' },
    { word: 'bucket', word_class: 'V' },
    { word: 'bucketful', word_class: 'N' },
    { word: 'bucketful', word_class: 'AJ' }
  ],
  zoning: [{ word: 'zone', word_class: 'N' }, { word: 'zone', word_class: 'V' }, { word: 'zoning', word_class: 'N' }],
  theatrically: [
    { word: 'theater', word_class: 'N' },
    { word: 'theatric', word_class: 'AJ' },
    { word: 'theatrical', word_class: 'N' },
    { word: 'theatrical', word_class: 'AJ' },
    { word: 'theatricality', word_class: 'N' },
    { word: 'theatrically', word_class: 'AV' }
  ],
  discomycete: [
    { word: 'discomycete', word_class: 'N' },
    { word: 'discomycetes', word_class: 'N' },
    { word: 'discomycetous', word_class: 'AJ' }
  ],
  cosiness: [
    { word: 'cosy', word_class: 'N' },
    { word: 'cosy', word_class: 'AJ' },
    { word: 'cosiness', word_class: 'N' }
  ],
  engulfment: [
    { word: 'engulf', word_class: 'V' },
    { word: 'engulfed', word_class: 'AJ' },
    { word: 'engulfment', word_class: 'N' }
  ],
  swerving: [
    { word: 'swerve', word_class: 'N' },
    { word: 'swerve', word_class: 'V' },
    { word: 'swerving', word_class: 'N' }
  ],
  transport: [
    { word: 'transport', word_class: 'N' },
    { word: 'transport', word_class: 'V' },
    { word: 'transporter', word_class: 'N' },
    { word: 'transportable', word_class: 'AJ' },
    { word: 'transportation', word_class: 'N' }
  ],
  refining: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  tongue: [
    { word: 'tongue', word_class: 'N' },
    { word: 'tongue', word_class: 'V' },
    { word: 'tongued', word_class: 'AJ' }
  ],
  fantasy: [
    { word: 'fantasy', word_class: 'N' },
    { word: 'fantasia', word_class: 'N' },
    { word: 'fantasist', word_class: 'N' },
    { word: 'fantasize', word_class: 'V' }
  ],
  relish: [
    { word: 'relish', word_class: 'N' },
    { word: 'relish', word_class: 'V' },
    { word: 'relishing', word_class: 'N' }
  ],
  sack: [
    { word: 'sack', word_class: 'N' },
    { word: 'sack', word_class: 'V' },
    { word: 'sacked', word_class: 'AJ' },
    { word: 'sackful', word_class: 'N' },
    { word: 'sacking', word_class: 'N' },
    { word: 'sackful', word_class: 'AJ' }
  ],
  hazardously: [
    { word: 'hazard', word_class: 'N' },
    { word: 'hazard', word_class: 'V' },
    { word: 'hazarding', word_class: 'N' },
    { word: 'hazardous', word_class: 'AJ' },
    { word: 'hazardously', word_class: 'AV' },
    { word: 'hazardousness', word_class: 'N' }
  ],
  capitalize: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  chased: [
    { word: 'chase', word_class: 'N' },
    { word: 'chase', word_class: 'V' },
    { word: 'chased', word_class: 'N' },
    { word: 'chaser', word_class: 'N' }
  ],
  spiritualist: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  idolisation: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  distortion: [
    { word: 'distort', word_class: 'N' },
    { word: 'distort', word_class: 'V' },
    { word: 'distorted', word_class: 'AJ' },
    { word: 'distortion', word_class: 'N' },
    { word: 'distortable', word_class: 'AJ' }
  ],
  kyanization: [
    { word: 'kyanise', word_class: 'V' },
    { word: 'kyanize', word_class: 'V' },
    { word: 'kyanisation', word_class: 'N' },
    { word: 'kyanization', word_class: 'N' }
  ],
  socially: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  invisibility: [
    { word: 'invisible', word_class: 'AJ' },
    { word: 'invisibly', word_class: 'AV' },
    { word: 'invisibility', word_class: 'N' },
    { word: 'invisibleness', word_class: 'N' }
  ],
  summon: [
    { word: 'summon', word_class: 'V' },
    { word: 'summons', word_class: 'N' },
    { word: 'summons', word_class: 'V' },
    { word: 'summoning', word_class: 'N' }
  ],
  ad: [{ word: 'ad', word_class: 'N' }, { word: 'ade', word_class: 'N' }, { word: 'added', word_class: 'AJ' }],
  trapped: [
    { word: 'trap', word_class: 'N' },
    { word: 'trap', word_class: 'V' },
    { word: 'trapped', word_class: 'AJ' },
    { word: 'trapping', word_class: 'N' },
    { word: 'trappings', word_class: 'N' }
  ],
  badgering: [
    { word: 'badger', word_class: 'N' },
    { word: 'badger', word_class: 'V' },
    { word: 'badgerer', word_class: 'N' },
    { word: 'badgering', word_class: 'N' }
  ],
  enclosure: [
    { word: 'enclose', word_class: 'V' },
    { word: 'enclosed', word_class: 'AJ' },
    { word: 'enclosing', word_class: 'N' },
    { word: 'enclosure', word_class: 'N' }
  ],
  rankness: [
    { word: 'rank', word_class: 'N' },
    { word: 'rank', word_class: 'V' },
    { word: 'rank', word_class: 'AJ' },
    { word: 'ranker', word_class: 'N' },
    { word: 'ranked', word_class: 'AJ' },
    { word: 'ranking', word_class: 'N' },
    { word: 'ranking', word_class: 'AJ' },
    { word: 'rankness', word_class: 'N' }
  ],
  stuff: [
    { word: 'stuff', word_class: 'N' },
    { word: 'stuff', word_class: 'V' },
    { word: 'stuffed', word_class: 'AJ' },
    { word: 'stuffing', word_class: 'N' }
  ],
  martyrization: [
    { word: 'martyr', word_class: 'N' },
    { word: 'martyr', word_class: 'V' },
    { word: 'martyrize', word_class: 'V' },
    { word: 'martyrisation', word_class: 'N' },
    { word: 'martyrization', word_class: 'N' }
  ],
  provisions: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  anatomisation: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  actinic: [
    { word: 'actin', word_class: 'N' },
    { word: 'actinal', word_class: 'AJ' },
    { word: 'actinic', word_class: 'AJ' },
    { word: 'actinism', word_class: 'N' }
  ],
  conceivably: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  lunation: [
    { word: 'lunate', word_class: 'AJ' },
    { word: 'lunatic', word_class: 'N' },
    { word: 'lunatic', word_class: 'AJ' },
    { word: 'lunation', word_class: 'N' }
  ],
  defeat: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  breathed: [
    { word: 'breath', word_class: 'N' },
    { word: 'breathe', word_class: 'V' },
    { word: 'breather', word_class: 'N' },
    { word: 'breathed', word_class: 'AJ' },
    { word: 'breathing', word_class: 'N' },
    { word: 'breathing', word_class: 'AJ' }
  ],
  deceitfulness: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  ossified: [
    { word: 'ossify', word_class: 'V' },
    { word: 'ossified', word_class: 'AJ' },
    { word: 'ossification', word_class: 'N' }
  ],
  pound: [
    { word: 'pound', word_class: 'N' },
    { word: 'pound', word_class: 'V' },
    { word: 'poundal', word_class: 'N' },
    { word: 'pounder', word_class: 'N' },
    { word: 'pounding', word_class: 'N' }
  ],
  impermeable: [
    { word: 'impermeable', word_class: 'AJ' },
    { word: 'impermeability', word_class: 'N' },
    { word: 'impermeableness', word_class: 'N' }
  ],
  preacher: [
    { word: 'preach', word_class: 'V' },
    { word: 'preacher', word_class: 'N' },
    { word: 'preachy', word_class: 'AJ' },
    { word: 'preaching', word_class: 'N' },
    { word: 'preachment', word_class: 'N' }
  ],
  amiableness: [
    { word: 'amiable', word_class: 'AJ' },
    { word: 'amiably', word_class: 'AV' },
    { word: 'amiability', word_class: 'N' },
    { word: 'amiableness', word_class: 'N' }
  ],
  reshuffle: [
    { word: 'reshuffle', word_class: 'N' },
    { word: 'reshuffle', word_class: 'V' },
    { word: 'reshuffling', word_class: 'N' }
  ],
  bushing: [
    { word: 'bush', word_class: 'N' },
    { word: 'bush', word_class: 'V' },
    { word: 'bushed', word_class: 'AJ' },
    { word: 'bushing', word_class: 'N' }
  ],
  jewelry: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  incompletion: [
    { word: 'incomplete', word_class: 'AJ' },
    { word: 'incompletion', word_class: 'N' },
    { word: 'incompletely', word_class: 'AV' },
    { word: 'incompleteness', word_class: 'N' }
  ],
  causally: [
    { word: 'causal', word_class: 'AJ' },
    { word: 'causality', word_class: 'N' },
    { word: 'causally', word_class: 'AV' }
  ],
  combatively: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  shiite: [
    { word: 'shy', word_class: 'N' },
    { word: 'shy', word_class: 'V' },
    { word: 'shy', word_class: 'AJ' },
    { word: 'shiite', word_class: 'N' },
    { word: 'shyness', word_class: 'N' }
  ],
  shallowness: [
    { word: 'shallow', word_class: 'N' },
    { word: 'shallow', word_class: 'V' },
    { word: 'shallow', word_class: 'AJ' },
    { word: 'shallowness', word_class: 'N' }
  ],
  matriarchal: [
    { word: 'matriarch', word_class: 'N' },
    { word: 'matriarchal', word_class: 'AJ' },
    { word: 'matriarchate', word_class: 'N' },
    { word: 'matriarchic', word_class: 'AJ' }
  ],
  infirm: [
    { word: 'infirm', word_class: 'N' },
    { word: 'infirm', word_class: 'V' },
    { word: 'infirm', word_class: 'AJ' },
    { word: 'infirmary', word_class: 'N' },
    { word: 'infirmity', word_class: 'N' }
  ],
  approach: [
    { word: 'approach', word_class: 'N' },
    { word: 'approach', word_class: 'V' },
    { word: 'approaching', word_class: 'N' },
    { word: 'approaching', word_class: 'AJ' },
    { word: 'approachable', word_class: 'AJ' },
    { word: 'approachability', word_class: 'N' }
  ],
  sulphonation: [
    { word: 'sulfonate', word_class: 'N' },
    { word: 'sulfonate', word_class: 'V' },
    { word: 'sulphonate', word_class: 'V' },
    { word: 'sulfonation', word_class: 'N' },
    { word: 'sulphonation', word_class: 'N' }
  ],
  exhibitionistic: [
    { word: 'exhibitionist', word_class: 'N' },
    { word: 'exhibitionist', word_class: 'AJ' },
    { word: 'exhibitionistic', word_class: 'AJ' }
  ],
  abrasiveness: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  misrepresentation: [
    { word: 'misrepresent', word_class: 'V' },
    { word: 'misrepresented', word_class: 'AJ' },
    { word: 'misrepresentation', word_class: 'N' }
  ],
  seasoning: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  typifying: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  discriminator: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  vehemently: [
    { word: 'vehemence', word_class: 'N' },
    { word: 'vehemency', word_class: 'N' },
    { word: 'vehement', word_class: 'AJ' },
    { word: 'vehemently', word_class: 'AV' }
  ],
  interactional: [
    { word: 'interact', word_class: 'V' },
    { word: 'interaction', word_class: 'N' },
    { word: 'interactive', word_class: 'AJ' },
    { word: 'interactional', word_class: 'AJ' }
  ],
  stinker: [
    { word: 'stink', word_class: 'N' },
    { word: 'stink', word_class: 'V' },
    { word: 'stinker', word_class: 'N' },
    { word: 'stinking', word_class: 'AJ' }
  ],
  confound: [
    { word: 'confound', word_class: 'V' },
    { word: 'confounded', word_class: 'AJ' },
    { word: 'confounding', word_class: 'N' },
    { word: 'confounding', word_class: 'AJ' }
  ],
  clanking: [
    { word: 'clank', word_class: 'N' },
    { word: 'clank', word_class: 'V' },
    { word: 'clanking', word_class: 'AJ' }
  ],
  schismatical: [
    { word: 'schismatic', word_class: 'AJ' },
    { word: 'schismatical', word_class: 'AJ' },
    { word: 'schismatically', word_class: 'AV' }
  ],
  dilution: [
    { word: 'dilute', word_class: 'N' },
    { word: 'dilute', word_class: 'V' },
    { word: 'dilute', word_class: 'AJ' },
    { word: 'dilutant', word_class: 'N' },
    { word: 'diluted', word_class: 'AJ' },
    { word: 'diluting', word_class: 'N' },
    { word: 'dilution', word_class: 'N' }
  ],
  unequal: [
    { word: 'unequal', word_class: 'AJ' },
    { word: 'unequaled', word_class: 'AJ' },
    { word: 'unequally', word_class: 'AV' },
    { word: 'unequalized', word_class: 'AJ' }
  ],
  coldness: [
    { word: 'cold', word_class: 'N' },
    { word: 'cold', word_class: 'AJ' },
    { word: 'coldness', word_class: 'N' }
  ],
  executive: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  damn: [
    { word: 'damn', word_class: 'N' },
    { word: 'damn', word_class: 'V' },
    { word: 'damn', word_class: 'AJ' },
    { word: 'damn', word_class: 'AV' },
    { word: 'damned', word_class: 'N' },
    { word: 'damned', word_class: 'AJ' },
    { word: 'damned', word_class: 'AV' },
    { word: 'damning', word_class: 'AJ' },
    { word: 'damnation', word_class: 'N' }
  ],
  pall: [{ word: 'pall', word_class: 'N' }, { word: 'pall', word_class: 'V' }, { word: 'pallor', word_class: 'N' }],
  tyrannic: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  conditionality: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  paternity: [
    { word: 'paternal', word_class: 'AJ' },
    { word: 'paternity', word_class: 'N' },
    { word: 'paternalism', word_class: 'N' },
    { word: 'paternally', word_class: 'AV' }
  ],
  ferocious: [
    { word: 'ferocious', word_class: 'AJ' },
    { word: 'ferociously', word_class: 'AV' },
    { word: 'ferociousness', word_class: 'N' }
  ],
  swathe: [
    { word: 'swath', word_class: 'N' },
    { word: 'swathe', word_class: 'N' },
    { word: 'swathe', word_class: 'V' },
    { word: 'swathing', word_class: 'N' }
  ],
  redemption: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  airship: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  metallic: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  syllabicity: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  militarist: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  infatuated: [
    { word: 'infatuate', word_class: 'V' },
    { word: 'infatuated', word_class: 'AJ' },
    { word: 'infatuation', word_class: 'N' }
  ],
  are: [{ word: 'ar', word_class: 'N' }, { word: 'are', word_class: 'N' }, { word: 'ares', word_class: 'N' }],
  dissuade: [
    { word: 'dissuade', word_class: 'V' },
    { word: 'dissuasion', word_class: 'N' },
    { word: 'dissuasive', word_class: 'AJ' }
  ],
  ostensive: [
    { word: 'ostensive', word_class: 'AJ' },
    { word: 'ostensible', word_class: 'AJ' },
    { word: 'ostensibly', word_class: 'AV' }
  ],
  log: [
    { word: 'log', word_class: 'N' },
    { word: 'log', word_class: 'V' },
    { word: 'log', word_class: 'AJ' },
    { word: 'logging', word_class: 'N' }
  ],
  blended: [
    { word: 'blend', word_class: 'N' },
    { word: 'blend', word_class: 'V' },
    { word: 'blende', word_class: 'N' },
    { word: 'blender', word_class: 'N' },
    { word: 'blended', word_class: 'AJ' },
    { word: 'blending', word_class: 'N' },
    { word: 'blending', word_class: 'AJ' }
  ],
  reverse: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  dished: [
    { word: 'dish', word_class: 'N' },
    { word: 'dish', word_class: 'V' },
    { word: 'dished', word_class: 'AJ' },
    { word: 'dishful', word_class: 'N' },
    { word: 'dishful', word_class: 'AJ' }
  ],
  forsaking: [
    { word: 'forsake', word_class: 'V' },
    { word: 'forsaken', word_class: 'AJ' },
    { word: 'forsaking', word_class: 'N' }
  ],
  futile: [
    { word: 'futile', word_class: 'AJ' },
    { word: 'futility', word_class: 'N' },
    { word: 'futilely', word_class: 'AV' }
  ],
  cords: [
    { word: 'cord', word_class: 'N' },
    { word: 'cord', word_class: 'V' },
    { word: 'cords', word_class: 'N' },
    { word: 'corded', word_class: 'AJ' },
    { word: 'cording', word_class: 'N' },
    { word: 'cordite', word_class: 'N' }
  ],
  grieving: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  rudiment: [
    { word: 'rudiment', word_class: 'N' },
    { word: 'rudiments', word_class: 'N' },
    { word: 'rudimentary', word_class: 'AJ' }
  ],
  instrumentation: [
    { word: 'instrument', word_class: 'N' },
    { word: 'instrument', word_class: 'V' },
    { word: 'instrumental', word_class: 'N' },
    { word: 'instrumental', word_class: 'AJ' },
    { word: 'instrumentate', word_class: 'V' },
    { word: 'instrumentality', word_class: 'N' },
    { word: 'instrumentation', word_class: 'N' }
  ],
  protestation: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  impeller: [
    { word: 'impel', word_class: 'V' },
    { word: 'impulse', word_class: 'N' },
    { word: 'impulse', word_class: 'V' },
    { word: 'impeller', word_class: 'N' },
    { word: 'impelled', word_class: 'AJ' },
    { word: 'impulsion', word_class: 'N' },
    { word: 'impellent', word_class: 'AJ' },
    { word: 'impelling', word_class: 'AJ' },
    { word: 'impulsive', word_class: 'AJ' },
    { word: 'impulsively', word_class: 'AV' },
    { word: 'impulsiveness', word_class: 'N' }
  ],
  outsider: [
    { word: 'outside', word_class: 'N' },
    { word: 'outside', word_class: 'AJ' },
    { word: 'outside', word_class: 'AV' },
    { word: 'outsider', word_class: 'N' }
  ],
  squat: [
    { word: 'squat', word_class: 'N' },
    { word: 'squat', word_class: 'V' },
    { word: 'squat', word_class: 'AJ' },
    { word: 'squatness', word_class: 'N' },
    { word: 'squatting', word_class: 'N' }
  ],
  laceration: [
    { word: 'lacerate', word_class: 'V' },
    { word: 'lacerate', word_class: 'AJ' },
    { word: 'lacerated', word_class: 'AJ' },
    { word: 'laceration', word_class: 'N' }
  ],
  recess: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  conducting: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  unification: [
    { word: 'unify', word_class: 'V' },
    { word: 'unified', word_class: 'AJ' },
    { word: 'unifying', word_class: 'AJ' },
    { word: 'unification', word_class: 'N' }
  ],
  mow: [
    { word: 'mow', word_class: 'N' },
    { word: 'mow', word_class: 'V' },
    { word: 'mower', word_class: 'N' },
    { word: 'mown', word_class: 'AJ' }
  ],
  rhymed: [
    { word: 'rhyme', word_class: 'N' },
    { word: 'rhyme', word_class: 'V' },
    { word: 'rhymed', word_class: 'AJ' },
    { word: 'rhyming', word_class: 'AJ' }
  ],
  fossilist: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  format: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  waning: [
    { word: 'wan', word_class: 'N' },
    { word: 'wan', word_class: 'V' },
    { word: 'wane', word_class: 'N' },
    { word: 'wane', word_class: 'V' },
    { word: 'wan', word_class: 'AJ' },
    { word: 'waning', word_class: 'N' },
    { word: 'waning', word_class: 'AJ' },
    { word: 'wanness', word_class: 'N' }
  ],
  seniority: [
    { word: 'senior', word_class: 'N' },
    { word: 'senior', word_class: 'AJ' },
    { word: 'seniority', word_class: 'N' }
  ],
  unequivocally: [
    { word: 'unequivocal', word_class: 'AJ' },
    { word: 'unequivocally', word_class: 'AV' },
    { word: 'unequivocalness', word_class: 'N' }
  ],
  transcendental: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  'cross-examiner': [
    { word: 'cross-examine', word_class: 'V' },
    { word: 'cross-examiner', word_class: 'N' },
    { word: 'cross-examination', word_class: 'N' }
  ],
  impolitely: [
    { word: 'impolite', word_class: 'AJ' },
    { word: 'impolitic', word_class: 'AJ' },
    { word: 'impolitely', word_class: 'AV' },
    { word: 'impoliteness', word_class: 'N' }
  ],
  bastardization: [
    { word: 'bastard', word_class: 'N' },
    { word: 'bastard', word_class: 'AJ' },
    { word: 'bastardize', word_class: 'V' },
    { word: 'bastardized', word_class: 'AJ' },
    { word: 'bastardization', word_class: 'N' }
  ],
  dancing: [
    { word: 'dance', word_class: 'N' },
    { word: 'dance', word_class: 'V' },
    { word: 'dancer', word_class: 'N' },
    { word: 'dancing', word_class: 'N' },
    { word: 'dancing', word_class: 'AJ' }
  ],
  nationalise: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  disagreeable: [
    { word: 'disagree', word_class: 'V' },
    { word: 'disagreement', word_class: 'N' },
    { word: 'disagreeable', word_class: 'AJ' },
    { word: 'disagreeably', word_class: 'AV' },
    { word: 'disagreeableness', word_class: 'N' }
  ],
  sectioned: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  annul: [
    { word: 'annul', word_class: 'V' },
    { word: 'annulate', word_class: 'AJ' },
    { word: 'annulment', word_class: 'N' },
    { word: 'annulated', word_class: 'AJ' }
  ],
  agglomerated: [
    { word: 'agglomerate', word_class: 'V' },
    { word: 'agglomerate', word_class: 'AJ' },
    { word: 'agglomerated', word_class: 'AJ' },
    { word: 'agglomeration', word_class: 'N' }
  ],
  deodorize: [
    { word: 'deodorant', word_class: 'N' },
    { word: 'deodorise', word_class: 'V' },
    { word: 'deodorize', word_class: 'V' },
    { word: 'deodorisation', word_class: 'N' },
    { word: 'deodorization', word_class: 'N' }
  ],
  colour: [
    { word: 'colour', word_class: 'N' },
    { word: 'colour', word_class: 'V' },
    { word: 'colour', word_class: 'AJ' },
    { word: 'colours', word_class: 'N' },
    { word: 'coloured', word_class: 'AJ' },
    { word: 'colouring', word_class: 'N' },
    { word: 'colourful', word_class: 'AJ' }
  ],
  insidiously: [
    { word: 'insidious', word_class: 'AJ' },
    { word: 'insidiously', word_class: 'AV' },
    { word: 'insidiousness', word_class: 'N' }
  ],
  formally: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  flute: [
    { word: 'flute', word_class: 'N' },
    { word: 'flute', word_class: 'V' },
    { word: 'fluting', word_class: 'N' },
    { word: 'flutist', word_class: 'N' },
    { word: 'flautist', word_class: 'N' }
  ],
  duster: [
    { word: 'dust', word_class: 'N' },
    { word: 'dust', word_class: 'V' },
    { word: 'duster', word_class: 'N' },
    { word: 'dustman', word_class: 'N' }
  ],
  relapse: [
    { word: 'relapse', word_class: 'N' },
    { word: 'relapse', word_class: 'V' },
    { word: 'relapsing', word_class: 'N' }
  ],
  inurement: [
    { word: 'inure', word_class: 'V' },
    { word: 'inured', word_class: 'AJ' },
    { word: 'inurement', word_class: 'N' }
  ],
  antagonization: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  inaudible: [
    { word: 'inaudible', word_class: 'AJ' },
    { word: 'inaudibly', word_class: 'AV' },
    { word: 'inaudibility', word_class: 'N' },
    { word: 'inaudibleness', word_class: 'N' }
  ],
  cross: [
    { word: 'cross', word_class: 'N' },
    { word: 'cross', word_class: 'V' },
    { word: 'cross', word_class: 'AJ' },
    { word: 'crosse', word_class: 'N' },
    { word: 'crossed', word_class: 'AJ' },
    { word: 'crossing', word_class: 'N' },
    { word: 'crossing', word_class: 'AJ' },
    { word: 'crossness', word_class: 'N' }
  ],
  carbonylic: [
    { word: 'carbonyl', word_class: 'N' },
    { word: 'carbonyl', word_class: 'AJ' },
    { word: 'carbonylic', word_class: 'AJ' }
  ],
  vengeance: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  trespasser: [
    { word: 'trespass', word_class: 'N' },
    { word: 'trespass', word_class: 'V' },
    { word: 'trespasser', word_class: 'N' },
    { word: 'trespassing', word_class: 'AJ' }
  ],
  ruffle: [
    { word: 'ruffle', word_class: 'N' },
    { word: 'ruffle', word_class: 'V' },
    { word: 'ruffled', word_class: 'AJ' }
  ],
  handsomely: [
    { word: 'handsome', word_class: 'AJ' },
    { word: 'handsomely', word_class: 'AV' },
    { word: 'handsomeness', word_class: 'N' }
  ],
  skater: [
    { word: 'skate', word_class: 'N' },
    { word: 'skate', word_class: 'V' },
    { word: 'skater', word_class: 'N' },
    { word: 'skates', word_class: 'N' },
    { word: 'skating', word_class: 'N' }
  ],
  mediated: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  abating: [
    { word: 'abate', word_class: 'V' },
    { word: 'abatic', word_class: 'AJ' },
    { word: 'abating', word_class: 'AJ' },
    { word: 'abatement', word_class: 'N' }
  ],
  sententiously: [
    { word: 'sentence', word_class: 'N' },
    { word: 'sentence', word_class: 'V' },
    { word: 'sentential', word_class: 'AJ' },
    { word: 'sententious', word_class: 'AJ' },
    { word: 'sententiously', word_class: 'AV' }
  ],
  unproductively: [
    { word: 'unproductive', word_class: 'AJ' },
    { word: 'unproductively', word_class: 'AV' },
    { word: 'unproductiveness', word_class: 'N' }
  ],
  throw: [
    { word: 'throw', word_class: 'N' },
    { word: 'throw', word_class: 'V' },
    { word: 'thrower', word_class: 'N' },
    { word: 'thrown', word_class: 'AJ' }
  ],
  leisured: [
    { word: 'leisure', word_class: 'N' },
    { word: 'leisure', word_class: 'AJ' },
    { word: 'leisured', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AV' }
  ],
  growling: [
    { word: 'growl', word_class: 'N' },
    { word: 'growl', word_class: 'V' },
    { word: 'growler', word_class: 'N' },
    { word: 'growling', word_class: 'N' }
  ],
  ionized: [
    { word: 'ionize', word_class: 'V' },
    { word: 'ionized', word_class: 'AJ' },
    { word: 'ionization', word_class: 'N' }
  ],
  misogynous: [
    { word: 'misogynic', word_class: 'AJ' },
    { word: 'misogynism', word_class: 'N' },
    { word: 'misogynous', word_class: 'AJ' }
  ],
  unmanliness: [
    { word: 'unmanly', word_class: 'AJ' },
    { word: 'unmanly', word_class: 'AV' },
    { word: 'unmanliness', word_class: 'N' }
  ],
  annulment: [
    { word: 'annul', word_class: 'V' },
    { word: 'annulate', word_class: 'AJ' },
    { word: 'annulment', word_class: 'N' },
    { word: 'annulated', word_class: 'AJ' }
  ],
  slip: [
    { word: 'slip', word_class: 'N' },
    { word: 'slip', word_class: 'V' },
    { word: 'slippage', word_class: 'N' },
    { word: 'slippery', word_class: 'AJ' },
    { word: 'slipping', word_class: 'AJ' },
    { word: 'slipperiness', word_class: 'N' }
  ],
  pedagogically: [
    { word: 'pedagogic', word_class: 'AJ' },
    { word: 'pedagogical', word_class: 'AJ' },
    { word: 'pedagogically', word_class: 'AV' }
  ],
  productivity: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  squirt: [
    { word: 'squirt', word_class: 'N' },
    { word: 'squirt', word_class: 'V' },
    { word: 'squirting', word_class: 'AJ' }
  ],
  specially: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  proclamation: [
    { word: 'proclaim', word_class: 'N' },
    { word: 'proclaim', word_class: 'V' },
    { word: 'proclaimed', word_class: 'AJ' },
    { word: 'proclamation', word_class: 'N' }
  ],
  cognisant: [
    { word: 'cognise', word_class: 'V' },
    { word: 'cognisance', word_class: 'N' },
    { word: 'cognisant', word_class: 'AJ' },
    { word: 'cognisable', word_class: 'AJ' }
  ],
  bestowment: [
    { word: 'bestow', word_class: 'V' },
    { word: 'bestowal', word_class: 'N' },
    { word: 'bestowed', word_class: 'AJ' },
    { word: 'bestowment', word_class: 'N' }
  ],
  unawares: [
    { word: 'unaware', word_class: 'AJ' },
    { word: 'unawares', word_class: 'AV' },
    { word: 'unawareness', word_class: 'N' }
  ],
  barbecued: [
    { word: 'barbecue', word_class: 'N' },
    { word: 'barbecue', word_class: 'V' },
    { word: 'barbecued', word_class: 'AJ' },
    { word: 'barbecuing', word_class: 'N' }
  ],
  angulation: [
    { word: 'angulate', word_class: 'V' },
    { word: 'angulate', word_class: 'AJ' },
    { word: 'angulation', word_class: 'N' }
  ],
  bobbysocker: [
    { word: 'bobbysock', word_class: 'N' },
    { word: 'bobbysocks', word_class: 'N' },
    { word: 'bobbysocker', word_class: 'N' }
  ],
  extended: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  achromatization: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  incapsulation: [
    { word: 'encapsulate', word_class: 'V' },
    { word: 'incapsulate', word_class: 'V' },
    { word: 'encapsulation', word_class: 'N' },
    { word: 'incapsulation', word_class: 'N' }
  ],
  ripple: [
    { word: 'ripple', word_class: 'N' },
    { word: 'ripple', word_class: 'V' },
    { word: 'ripply', word_class: 'AJ' },
    { word: 'rippled', word_class: 'AJ' },
    { word: 'rippling', word_class: 'N' }
  ],
  theorization: [
    { word: 'theory', word_class: 'N' },
    { word: 'theorise', word_class: 'V' },
    { word: 'theorist', word_class: 'N' },
    { word: 'theorize', word_class: 'V' },
    { word: 'theoretic', word_class: 'AJ' },
    { word: 'theorization', word_class: 'N' },
    { word: 'theoretical', word_class: 'AJ' },
    { word: 'theorisation', word_class: 'N' },
    { word: 'theoretically', word_class: 'AV' }
  ],
  merchandisement: [
    { word: 'merchandise', word_class: 'N' },
    { word: 'merchandise', word_class: 'V' },
    { word: 'merchandiser', word_class: 'N' },
    { word: 'merchandising', word_class: 'N' },
    { word: 'merchandisement', word_class: 'N' }
  ],
  persistent: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  desexualisation: [
    { word: 'desexualise', word_class: 'V' },
    { word: 'desexualize', word_class: 'V' },
    { word: 'desexualisation', word_class: 'N' },
    { word: 'desexualization', word_class: 'N' }
  ],
  hypochondriacal: [
    { word: 'hypochondria', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'N' },
    { word: 'hypochondriac', word_class: 'AJ' },
    { word: 'hypochondriacal', word_class: 'AJ' }
  ],
  gas: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  wooing: [{ word: 'woo', word_class: 'V' }, { word: 'wooer', word_class: 'N' }, { word: 'wooing', word_class: 'N' }],
  vitally: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  publicist: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  outgo: [
    { word: 'outgo', word_class: 'N' },
    { word: 'outgo', word_class: 'V' },
    { word: 'outgoing', word_class: 'AJ' }
  ],
  honeycombed: [
    { word: 'honeycomb', word_class: 'N' },
    { word: 'honeycomb', word_class: 'V' },
    { word: 'honeycombed', word_class: 'AJ' }
  ],
  fatigued: [
    { word: 'fatigue', word_class: 'N' },
    { word: 'fatigue', word_class: 'V' },
    { word: 'fatigues', word_class: 'N' },
    { word: 'fatigued', word_class: 'AJ' }
  ],
  downstairs: [
    { word: 'downstair', word_class: 'AJ' },
    { word: 'downstairs', word_class: 'N' },
    { word: 'downstairs', word_class: 'AJ' },
    { word: 'downstairs', word_class: 'AV' }
  ],
  magnify: [
    { word: 'magnify', word_class: 'V' },
    { word: 'magnifier', word_class: 'N' },
    { word: 'magnified', word_class: 'AJ' },
    { word: 'magnification', word_class: 'N' }
  ],
  inspect: [
    { word: 'inspect', word_class: 'V' },
    { word: 'inspector', word_class: 'N' },
    { word: 'inspection', word_class: 'N' },
    { word: 'inspectorate', word_class: 'N' }
  ],
  potency: [
    { word: 'potence', word_class: 'N' },
    { word: 'potency', word_class: 'N' },
    { word: 'potent', word_class: 'AJ' },
    { word: 'potentate', word_class: 'N' },
    { word: 'potently', word_class: 'AV' }
  ],
  diabolical: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  framer: [
    { word: 'frame', word_class: 'N' },
    { word: 'frame', word_class: 'V' },
    { word: 'framer', word_class: 'N' },
    { word: 'frames', word_class: 'V' },
    { word: 'framed', word_class: 'AJ' },
    { word: 'framing', word_class: 'N' }
  ],
  fertilisation: [
    { word: 'fertilise', word_class: 'V' },
    { word: 'fertilised', word_class: 'AJ' },
    { word: 'fertilisation', word_class: 'N' }
  ],
  extraction: [
    { word: 'extract', word_class: 'N' },
    { word: 'extract', word_class: 'V' },
    { word: 'extractor', word_class: 'N' },
    { word: 'extraction', word_class: 'N' },
    { word: 'extractable', word_class: 'AJ' },
    { word: 'extractible', word_class: 'AJ' }
  ],
  disaffirmance: [
    { word: 'disaffirm', word_class: 'V' },
    { word: 'disaffirmance', word_class: 'N' },
    { word: 'disaffirmation', word_class: 'N' }
  ],
  male: [{ word: 'male', word_class: 'N' }, { word: 'male', word_class: 'AJ' }, { word: 'maleness', word_class: 'N' }],
  vial: [{ word: 'vie', word_class: 'V' }, { word: 'vial', word_class: 'N' }, { word: 'vying', word_class: 'V' }],
  modernisation: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  enrolment: [
    { word: 'enrol', word_class: 'V' },
    { word: 'enroll', word_class: 'V' },
    { word: 'enrolled', word_class: 'AJ' },
    { word: 'enrolment', word_class: 'N' },
    { word: 'enrollment', word_class: 'N' }
  ],
  annually: [
    { word: 'annual', word_class: 'N' },
    { word: 'annual', word_class: 'AJ' },
    { word: 'annually', word_class: 'AV' }
  ],
  breakability: [
    { word: 'breakable', word_class: 'N' },
    { word: 'breakable', word_class: 'AJ' },
    { word: 'breakability', word_class: 'N' },
    { word: 'breakableness', word_class: 'N' }
  ],
  witch: [
    { word: 'witch', word_class: 'N' },
    { word: 'witch', word_class: 'V' },
    { word: 'witchery', word_class: 'N' },
    { word: 'witching', word_class: 'AJ' }
  ],
  unit: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  lessening: [
    { word: 'lessen', word_class: 'V' },
    { word: 'lessened', word_class: 'AJ' },
    { word: 'lessening', word_class: 'N' },
    { word: 'lessening', word_class: 'AJ' }
  ],
  added: [{ word: 'ad', word_class: 'N' }, { word: 'ade', word_class: 'N' }, { word: 'added', word_class: 'AJ' }],
  petrification: [
    { word: 'petrify', word_class: 'V' },
    { word: 'petrified', word_class: 'AJ' },
    { word: 'petrifying', word_class: 'AJ' },
    { word: 'petrifaction', word_class: 'N' },
    { word: 'petrification', word_class: 'N' }
  ],
  meagrely: [
    { word: 'meagre', word_class: 'AJ' },
    { word: 'meagrely', word_class: 'AV' },
    { word: 'meagreness', word_class: 'N' }
  ],
  resistive: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  gestational: [
    { word: 'gestate', word_class: 'V' },
    { word: 'gestation', word_class: 'N' },
    { word: 'gestational', word_class: 'AJ' }
  ],
  killing: [
    { word: 'kill', word_class: 'N' },
    { word: 'kill', word_class: 'V' },
    { word: 'killer', word_class: 'N' },
    { word: 'killing', word_class: 'N' },
    { word: 'killing', word_class: 'AJ' }
  ],
  governed: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  hoof: [
    { word: 'hoof', word_class: 'N' },
    { word: 'hoof', word_class: 'V' },
    { word: 'hoofed', word_class: 'AJ' },
    { word: 'hoofing', word_class: 'N' }
  ],
  snorting: [
    { word: 'snort', word_class: 'N' },
    { word: 'snort', word_class: 'V' },
    { word: 'snorter', word_class: 'N' },
    { word: 'snorting', word_class: 'N' },
    { word: 'snorting', word_class: 'AJ' }
  ],
  insulate: [
    { word: 'insulant', word_class: 'N' },
    { word: 'insulate', word_class: 'V' },
    { word: 'insulator', word_class: 'N' },
    { word: 'insulation', word_class: 'N' }
  ],
  obliterate: [
    { word: 'obliterate', word_class: 'V' },
    { word: 'obliterate', word_class: 'AJ' },
    { word: 'obliterable', word_class: 'AJ' },
    { word: 'obliterated', word_class: 'AJ' },
    { word: 'obliteration', word_class: 'N' },
    { word: 'obliterating', word_class: 'AJ' }
  ],
  coaster: [
    { word: 'coast', word_class: 'N' },
    { word: 'coast', word_class: 'V' },
    { word: 'coaster', word_class: 'N' }
  ],
  abolishable: [
    { word: 'abolish', word_class: 'V' },
    { word: 'abolition', word_class: 'N' },
    { word: 'abolishment', word_class: 'N' },
    { word: 'abolishable', word_class: 'AJ' }
  ],
  decompressing: [
    { word: 'decompress', word_class: 'V' },
    { word: 'decompressing', word_class: 'N' },
    { word: 'decompression', word_class: 'N' }
  ],
  steamed: [
    { word: 'steam', word_class: 'N' },
    { word: 'steam', word_class: 'V' },
    { word: 'steamer', word_class: 'N' },
    { word: 'steamed', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AV' },
    { word: 'steamship', word_class: 'N' }
  ],
  signaller: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  exclamatory: [
    { word: 'exclaim', word_class: 'N' },
    { word: 'exclaim', word_class: 'V' },
    { word: 'exclaiming', word_class: 'N' },
    { word: 'exclamation', word_class: 'N' },
    { word: 'exclamatory', word_class: 'AJ' }
  ],
  desirable: [
    { word: 'desire', word_class: 'N' },
    { word: 'desire', word_class: 'V' },
    { word: 'desired', word_class: 'AJ' },
    { word: 'desirous', word_class: 'AJ' },
    { word: 'desirable', word_class: 'AJ' },
    { word: 'desirability', word_class: 'N' },
    { word: 'desirableness', word_class: 'N' }
  ],
  placenta: [
    { word: 'placenta', word_class: 'N' },
    { word: 'placental', word_class: 'N' },
    { word: 'placental', word_class: 'AJ' },
    { word: 'placentation', word_class: 'N' }
  ],
  gag: [{ word: 'gag', word_class: 'N' }, { word: 'gag', word_class: 'V' }, { word: 'gagman', word_class: 'N' }],
  retinitis: [
    { word: 'retina', word_class: 'N' },
    { word: 'retinal', word_class: 'N' },
    { word: 'retinal', word_class: 'AJ' },
    { word: 'retinitis', word_class: 'N' }
  ],
  comprehension: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  abstemiously: [
    { word: 'abstemious', word_class: 'AJ' },
    { word: 'abstemiously', word_class: 'AV' },
    { word: 'abstemiousness', word_class: 'N' }
  ],
  question: [
    { word: 'question', word_class: 'N' },
    { word: 'question', word_class: 'V' },
    { word: 'questioner', word_class: 'N' },
    { word: 'questioning', word_class: 'N' },
    { word: 'questioning', word_class: 'AJ' },
    { word: 'questionable', word_class: 'AJ' },
    { word: 'questionably', word_class: 'AV' }
  ],
  agrological: [
    { word: 'agrology', word_class: 'N' },
    { word: 'agrologic', word_class: 'AJ' },
    { word: 'agrological', word_class: 'AJ' }
  ],
  consumptive: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  insolently: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  deplore: [
    { word: 'deplore', word_class: 'V' },
    { word: 'deplorable', word_class: 'AJ' },
    { word: 'deplorably', word_class: 'AV' }
  ],
  secern: [
    { word: 'secern', word_class: 'V' },
    { word: 'secernate', word_class: 'V' },
    { word: 'secernment', word_class: 'N' }
  ],
  enclosing: [
    { word: 'enclose', word_class: 'V' },
    { word: 'enclosed', word_class: 'AJ' },
    { word: 'enclosing', word_class: 'N' },
    { word: 'enclosure', word_class: 'N' }
  ],
  easing: [
    { word: 'ease', word_class: 'N' },
    { word: 'ease', word_class: 'V' },
    { word: 'eased', word_class: 'AJ' },
    { word: 'easing', word_class: 'N' },
    { word: 'easement', word_class: 'N' }
  ],
  collegiate: [
    { word: 'college', word_class: 'N' },
    { word: 'collegial', word_class: 'AJ' },
    { word: 'collegiate', word_class: 'AJ' }
  ],
  fluorine: [
    { word: 'fluorine', word_class: 'N' },
    { word: 'fluorinate', word_class: 'V' },
    { word: 'fluorination', word_class: 'N' }
  ],
  humanism: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  pruriency: [
    { word: 'prurience', word_class: 'N' },
    { word: 'pruriency', word_class: 'N' },
    { word: 'prurient', word_class: 'AJ' },
    { word: 'pruriently', word_class: 'AV' }
  ],
  rooms: [
    { word: 'room', word_class: 'N' },
    { word: 'room', word_class: 'V' },
    { word: 'rooms', word_class: 'N' },
    { word: 'roomer', word_class: 'N' },
    { word: 'roomful', word_class: 'N' },
    { word: 'roomful', word_class: 'AJ' }
  ],
  striping: [
    { word: 'stripe', word_class: 'N' },
    { word: 'stripe', word_class: 'V' },
    { word: 'stripes', word_class: 'N' },
    { word: 'stripy', word_class: 'AJ' },
    { word: 'striped', word_class: 'AJ' },
    { word: 'striping', word_class: 'N' }
  ],
  exasperation: [
    { word: 'exasperate', word_class: 'V' },
    { word: 'exasperated', word_class: 'AJ' },
    { word: 'exasperation', word_class: 'N' },
    { word: 'exasperating', word_class: 'AJ' }
  ],
  travel: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  forested: [
    { word: 'forest', word_class: 'N' },
    { word: 'forest', word_class: 'V' },
    { word: 'forester', word_class: 'N' },
    { word: 'forestry', word_class: 'N' },
    { word: 'forested', word_class: 'AJ' }
  ],
  collimator: [
    { word: 'collimate', word_class: 'V' },
    { word: 'collimator', word_class: 'N' },
    { word: 'collimation', word_class: 'N' }
  ],
  accoutrement: [
    { word: 'accoutre', word_class: 'V' },
    { word: 'accoutred', word_class: 'AJ' },
    { word: 'accoutrement', word_class: 'N' }
  ],
  academia: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  canonise: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  alphabet: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  duello: [
    { word: 'duel', word_class: 'N' },
    { word: 'duel', word_class: 'V' },
    { word: 'dueler', word_class: 'N' },
    { word: 'duello', word_class: 'N' },
    { word: 'duelist', word_class: 'N' }
  ],
  companionableness: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  photoconductive: [
    { word: 'photoconduction', word_class: 'N' },
    { word: 'photoconductive', word_class: 'AJ' },
    { word: 'photoconductivity', word_class: 'N' }
  ],
  reviewer: [
    { word: 'review', word_class: 'N' },
    { word: 'review', word_class: 'V' },
    { word: 'review', word_class: 'AJ' },
    { word: 'reviewer', word_class: 'N' }
  ],
  pasty: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  inopportunely: [
    { word: 'inopportune', word_class: 'AJ' },
    { word: 'inopportunely', word_class: 'AV' },
    { word: 'inopportuneness', word_class: 'N' }
  ],
  ambulance: [
    { word: 'ambulate', word_class: 'V' },
    { word: 'ambulance', word_class: 'N' },
    { word: 'ambulant', word_class: 'AJ' },
    { word: 'ambulation', word_class: 'N' }
  ],
  strewn: [
    { word: 'strew', word_class: 'V' },
    { word: 'strewn', word_class: 'AJ' },
    { word: 'strewing', word_class: 'N' }
  ],
  bacteriophagic: [
    { word: 'bacteriophage', word_class: 'N' },
    { word: 'bacteriophagic', word_class: 'AJ' },
    { word: 'bacteriophagous', word_class: 'AJ' }
  ],
  urbanize: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  incandesce: [
    { word: 'incandesce', word_class: 'V' },
    { word: 'incandescence', word_class: 'N' },
    { word: 'incandescent', word_class: 'AJ' }
  ],
  acquirable: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  bestride: [
    { word: 'bestrid', word_class: 'AJ' },
    { word: 'bestride', word_class: 'V' },
    { word: 'bestridden', word_class: 'AJ' }
  ],
  accrued: [
    { word: 'accrue', word_class: 'V' },
    { word: 'accrual', word_class: 'N' },
    { word: 'accrued', word_class: 'AJ' },
    { word: 'accrument', word_class: 'N' },
    { word: 'accruement', word_class: 'N' }
  ],
  provocative: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  parings: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  virility: [
    { word: 'virile', word_class: 'AJ' },
    { word: 'virility', word_class: 'N' },
    { word: 'virilization', word_class: 'N' }
  ],
  dehiscent: [
    { word: 'dehisce', word_class: 'V' },
    { word: 'dehiscence', word_class: 'N' },
    { word: 'dehiscent', word_class: 'AJ' }
  ],
  practised: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  procedure: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  postponed: [
    { word: 'postpone', word_class: 'V' },
    { word: 'postponer', word_class: 'N' },
    { word: 'postponed', word_class: 'AJ' },
    { word: 'postponement', word_class: 'N' }
  ],
  ingenuousness: [
    { word: 'ingenue', word_class: 'N' },
    { word: 'ingenuity', word_class: 'N' },
    { word: 'ingenuous', word_class: 'AJ' },
    { word: 'ingenuously', word_class: 'AV' },
    { word: 'ingenuousness', word_class: 'N' }
  ],
  anchorman: [
    { word: 'anchor', word_class: 'N' },
    { word: 'anchor', word_class: 'V' },
    { word: 'anchorage', word_class: 'N' },
    { word: 'anchorite', word_class: 'N' },
    { word: 'anchorman', word_class: 'N' },
    { word: 'anchoritic', word_class: 'AJ' }
  ],
  bungled: [
    { word: 'bungle', word_class: 'N' },
    { word: 'bungle', word_class: 'V' },
    { word: 'bungler', word_class: 'N' },
    { word: 'bungled', word_class: 'AJ' },
    { word: 'bungling', word_class: 'N' },
    { word: 'bungling', word_class: 'AJ' }
  ],
  disfigure: [
    { word: 'disfigure', word_class: 'V' },
    { word: 'disfigured', word_class: 'AJ' },
    { word: 'disfiguration', word_class: 'N' },
    { word: 'disfigurement', word_class: 'N' }
  ],
  harmonic: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  diphthongisation: [
    { word: 'diphthong', word_class: 'N' },
    { word: 'diphthongise', word_class: 'V' },
    { word: 'diphthongize', word_class: 'V' },
    { word: 'diphthongisation', word_class: 'N' },
    { word: 'diphthongization', word_class: 'N' }
  ],
  screw: [
    { word: 'screw', word_class: 'N' },
    { word: 'screw', word_class: 'V' },
    { word: 'screwing', word_class: 'N' }
  ],
  detecting: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  proportional: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  'air-conditioned': [
    { word: 'air-condition', word_class: 'V' },
    { word: 'air-conditioned', word_class: 'AJ' },
    { word: 'air-conditioning', word_class: 'N' }
  ],
  compulsiveness: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  pig: [
    { word: 'pig', word_class: 'N' },
    { word: 'pig', word_class: 'V' },
    { word: 'pigman', word_class: 'N' },
    { word: 'piggery', word_class: 'N' },
    { word: 'pigment', word_class: 'N' },
    { word: 'pigment', word_class: 'V' },
    { word: 'pigmentation', word_class: 'N' }
  ],
  inflaming: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  helping: [
    { word: 'help', word_class: 'N' },
    { word: 'help', word_class: 'V' },
    { word: 'helper', word_class: 'N' },
    { word: 'helping', word_class: 'N' },
    { word: 'helpful', word_class: 'AJ' },
    { word: 'helpfulness', word_class: 'N' }
  ],
  quietness: [
    { word: 'quiet', word_class: 'N' },
    { word: 'quiet', word_class: 'V' },
    { word: 'quiet', word_class: 'AJ' },
    { word: 'quiet', word_class: 'AV' },
    { word: 'quietism', word_class: 'N' },
    { word: 'quietist', word_class: 'N' },
    { word: 'quietness', word_class: 'N' }
  ],
  narcotize: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  labelled: [
    { word: 'label', word_class: 'N' },
    { word: 'label', word_class: 'V' },
    { word: 'labeled', word_class: 'AJ' },
    { word: 'labelled', word_class: 'AJ' }
  ],
  adagio: [
    { word: 'adagio', word_class: 'N' },
    { word: 'adagio', word_class: 'AJ' },
    { word: 'adagio', word_class: 'AV' }
  ],
  apomictical: [
    { word: 'apomict', word_class: 'N' },
    { word: 'apomictic', word_class: 'AJ' },
    { word: 'apomictical', word_class: 'AJ' }
  ],
  republication: [
    { word: 'republic', word_class: 'N' },
    { word: 'republic', word_class: 'AJ' },
    { word: 'republish', word_class: 'V' },
    { word: 'republishing', word_class: 'N' },
    { word: 'republication', word_class: 'N' }
  ],
  originate: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  imputation: [
    { word: 'impute', word_class: 'V' },
    { word: 'imputable', word_class: 'AJ' },
    { word: 'imputation', word_class: 'N' }
  ],
  importer: [
    { word: 'import', word_class: 'N' },
    { word: 'import', word_class: 'V' },
    { word: 'importer', word_class: 'N' },
    { word: 'imported', word_class: 'AJ' },
    { word: 'importing', word_class: 'N' },
    { word: 'importance', word_class: 'N' },
    { word: 'important', word_class: 'AJ' },
    { word: 'importation', word_class: 'N' }
  ],
  dragoon: [
    { word: 'dragoon', word_class: 'N' },
    { word: 'dragoon', word_class: 'V' },
    { word: 'dragoonage', word_class: 'N' }
  ],
  encode: [
    { word: 'encode', word_class: 'V' },
    { word: 'encoding', word_class: 'N' },
    { word: 'encodement', word_class: 'N' }
  ],
  attainableness: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  lampooner: [
    { word: 'lampoon', word_class: 'N' },
    { word: 'lampoon', word_class: 'V' },
    { word: 'lampooner', word_class: 'N' }
  ],
  reiterate: [
    { word: 'reiterate', word_class: 'V' },
    { word: 'reiteration', word_class: 'N' },
    { word: 'reiterative', word_class: 'AJ' }
  ],
  kindliness: [
    { word: 'kind', word_class: 'N' },
    { word: 'kind', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AV' },
    { word: 'kindness', word_class: 'N' },
    { word: 'kindliness', word_class: 'N' }
  ],
  iodinated: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  interiorize: [
    { word: 'interior', word_class: 'N' },
    { word: 'interior', word_class: 'AJ' },
    { word: 'interiorize', word_class: 'V' },
    { word: 'interiorization', word_class: 'N' }
  ],
  ectodermal: [
    { word: 'ectoderm', word_class: 'N' },
    { word: 'ectodermal', word_class: 'AJ' },
    { word: 'ectodermic', word_class: 'AJ' }
  ],
  cheerfulness: [
    { word: 'cheer', word_class: 'N' },
    { word: 'cheer', word_class: 'V' },
    { word: 'cheering', word_class: 'N' },
    { word: 'cheerful', word_class: 'AJ' },
    { word: 'cheering', word_class: 'AJ' },
    { word: 'cheerfulness', word_class: 'N' }
  ],
  confinement: [
    { word: 'confine', word_class: 'V' },
    { word: 'confines', word_class: 'N' },
    { word: 'confined', word_class: 'AJ' },
    { word: 'confining', word_class: 'AJ' },
    { word: 'confinement', word_class: 'N' }
  ],
  perambulating: [
    { word: 'perambulate', word_class: 'V' },
    { word: 'perambulator', word_class: 'N' },
    { word: 'perambulation', word_class: 'N' },
    { word: 'perambulating', word_class: 'AJ' }
  ],
  wideness: [
    { word: 'wide', word_class: 'AJ' },
    { word: 'wide', word_class: 'AV' },
    { word: 'width', word_class: 'N' },
    { word: 'widely', word_class: 'AV' },
    { word: 'wideness', word_class: 'N' }
  ],
  mature: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  commemorative: [
    { word: 'commemorate', word_class: 'V' },
    { word: 'commemoration', word_class: 'N' },
    { word: 'commemorating', word_class: 'AJ' },
    { word: 'commemorative', word_class: 'AJ' }
  ],
  soviet: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  slanderous: [
    { word: 'slander', word_class: 'N' },
    { word: 'slander', word_class: 'V' },
    { word: 'slanderer', word_class: 'N' },
    { word: 'slanderous', word_class: 'AJ' }
  ],
  pucker: [
    { word: 'pucker', word_class: 'N' },
    { word: 'pucker', word_class: 'V' },
    { word: 'puckered', word_class: 'AJ' }
  ],
  dwarfism: [
    { word: 'dwarf', word_class: 'N' },
    { word: 'dwarf', word_class: 'V' },
    { word: 'dwarf', word_class: 'AJ' },
    { word: 'dwarfism', word_class: 'N' }
  ],
  baseness: [
    { word: 'base', word_class: 'N' },
    { word: 'base', word_class: 'V' },
    { word: 'base', word_class: 'AJ' },
    { word: 'based', word_class: 'AJ' },
    { word: 'basely', word_class: 'AV' },
    { word: 'baseness', word_class: 'N' }
  ],
  probe: [
    { word: 'probe', word_class: 'N' },
    { word: 'probe', word_class: 'V' },
    { word: 'probing', word_class: 'N' },
    { word: 'probing', word_class: 'AJ' }
  ],
  admired: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  trucking: [
    { word: 'truck', word_class: 'N' },
    { word: 'truck', word_class: 'V' },
    { word: 'truckful', word_class: 'N' },
    { word: 'trucking', word_class: 'N' },
    { word: 'truckful', word_class: 'AJ' }
  ],
  initially: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  variate: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  powdered: [
    { word: 'powder', word_class: 'N' },
    { word: 'powder', word_class: 'V' },
    { word: 'powdered', word_class: 'AJ' },
    { word: 'powderize', word_class: 'V' }
  ],
  releasing: [
    { word: 'release', word_class: 'N' },
    { word: 'release', word_class: 'V' },
    { word: 'released', word_class: 'AJ' },
    { word: 'releasing', word_class: 'AJ' }
  ],
  leather: [
    { word: 'leather', word_class: 'N' },
    { word: 'leather', word_class: 'V' },
    { word: 'leathered', word_class: 'AJ' }
  ],
  safety: [
    { word: 'safe', word_class: 'N' },
    { word: 'safe', word_class: 'AJ' },
    { word: 'safety', word_class: 'N' },
    { word: 'safely', word_class: 'AV' },
    { word: 'safeness', word_class: 'N' }
  ],
  dication: [
    { word: 'dictate', word_class: 'N' },
    { word: 'dictate', word_class: 'V' },
    { word: 'dication', word_class: 'N' },
    { word: 'dictator', word_class: 'N' },
    { word: 'dictated', word_class: 'AJ' },
    { word: 'dictation', word_class: 'N' }
  ],
  bootee: [
    { word: 'boot', word_class: 'N' },
    { word: 'boot', word_class: 'V' },
    { word: 'bootee', word_class: 'N' },
    { word: 'bootes', word_class: 'N' },
    { word: 'booted', word_class: 'AJ' },
    { word: 'bootees', word_class: 'N' }
  ],
  raveling: [
    { word: 'ravel', word_class: 'N' },
    { word: 'ravel', word_class: 'V' },
    { word: 'raveling', word_class: 'N' },
    { word: 'ravelling', word_class: 'N' }
  ],
  stamped: [
    { word: 'stamp', word_class: 'N' },
    { word: 'stamp', word_class: 'V' },
    { word: 'stamped', word_class: 'AJ' }
  ],
  maturate: [
    { word: 'mature', word_class: 'V' },
    { word: 'mature', word_class: 'AJ' },
    { word: 'maturate', word_class: 'V' },
    { word: 'matured', word_class: 'AJ' },
    { word: 'maturity', word_class: 'N' },
    { word: 'maturing', word_class: 'N' },
    { word: 'maturely', word_class: 'AV' },
    { word: 'maturation', word_class: 'N' },
    { word: 'maturement', word_class: 'N' },
    { word: 'matureness', word_class: 'N' },
    { word: 'maturational', word_class: 'AJ' }
  ],
  thicken: [
    { word: 'thicken', word_class: 'V' },
    { word: 'thickener', word_class: 'N' },
    { word: 'thickened', word_class: 'AJ' },
    { word: 'thickening', word_class: 'N' },
    { word: 'thickening', word_class: 'AJ' }
  ],
  branch: [
    { word: 'branch', word_class: 'N' },
    { word: 'branch', word_class: 'V' },
    { word: 'branched', word_class: 'AJ' },
    { word: 'branching', word_class: 'N' },
    { word: 'branching', word_class: 'AJ' },
    { word: 'embranchment', word_class: 'N' }
  ],
  pulverization: [
    { word: 'pulverise', word_class: 'V' },
    { word: 'pulverize', word_class: 'V' },
    { word: 'pulverized', word_class: 'AJ' },
    { word: 'pulverisation', word_class: 'N' },
    { word: 'pulverization', word_class: 'N' }
  ],
  cryogenic: [
    { word: 'cryogen', word_class: 'N' },
    { word: 'cryogenic', word_class: 'AJ' },
    { word: 'cryogenics', word_class: 'N' }
  ],
  carburise: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  'double-cross': [
    { word: 'double-cross', word_class: 'N' },
    { word: 'double-cross', word_class: 'V' },
    { word: 'double-crosser', word_class: 'N' },
    { word: 'double-crossing', word_class: 'N' }
  ],
  enchantment: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  interspersion: [
    { word: 'intersperse', word_class: 'V' },
    { word: 'interspersal', word_class: 'N' },
    { word: 'interspersion', word_class: 'N' }
  ],
  constipation: [
    { word: 'constipate', word_class: 'V' },
    { word: 'constipated', word_class: 'AJ' },
    { word: 'constipation', word_class: 'N' },
    { word: 'constipating', word_class: 'AJ' }
  ],
  penultima: [
    { word: 'penultima', word_class: 'N' },
    { word: 'penultimate', word_class: 'N' },
    { word: 'penultimate', word_class: 'AJ' }
  ],
  substitutability: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  upended: [
    { word: 'upend', word_class: 'V' },
    { word: 'upended', word_class: 'AJ' },
    { word: 'upending', word_class: 'N' }
  ],
  joker: [
    { word: 'joke', word_class: 'N' },
    { word: 'joke', word_class: 'V' },
    { word: 'joker', word_class: 'N' },
    { word: 'joking', word_class: 'AJ' }
  ],
  scatology: [
    { word: 'scatology', word_class: 'N' },
    { word: 'scatologic', word_class: 'AJ' },
    { word: 'scatological', word_class: 'AJ' }
  ],
  intangibleness: [
    { word: 'intangible', word_class: 'N' },
    { word: 'intangible', word_class: 'AJ' },
    { word: 'intangibility', word_class: 'N' },
    { word: 'intangibleness', word_class: 'N' }
  ],
  van: [{ word: 'van', word_class: 'N' }, { word: 'vanful', word_class: 'N' }, { word: 'vanful', word_class: 'AJ' }],
  authenticate: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  lifelike: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  bureaucratism: [
    { word: 'bureaucrat', word_class: 'N' },
    { word: 'bureaucracy', word_class: 'N' },
    { word: 'bureaucratic', word_class: 'AJ' },
    { word: 'bureaucratism', word_class: 'N' },
    { word: 'bureaucratically', word_class: 'AV' }
  ],
  stays: [
    { word: 'stay', word_class: 'N' },
    { word: 'stay', word_class: 'V' },
    { word: 'stays', word_class: 'N' },
    { word: 'stayer', word_class: 'N' },
    { word: 'stayman', word_class: 'N' }
  ],
  recovering: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  precariously: [
    { word: 'precarious', word_class: 'AJ' },
    { word: 'precariously', word_class: 'AV' },
    { word: 'precariousness', word_class: 'N' }
  ],
  jurisprudence: [
    { word: 'jurisprudent', word_class: 'N' },
    { word: 'jurisprudence', word_class: 'N' },
    { word: 'jurisprudential', word_class: 'AJ' },
    { word: 'jurisprudentially', word_class: 'AV' }
  ],
  investigating: [
    { word: 'investigate', word_class: 'V' },
    { word: 'investigator', word_class: 'N' },
    { word: 'investigating', word_class: 'N' },
    { word: 'investigation', word_class: 'N' },
    { word: 'investigative', word_class: 'AJ' }
  ],
  disenchant: [
    { word: 'disenchant', word_class: 'V' },
    { word: 'disenchanted', word_class: 'AJ' },
    { word: 'disenchanting', word_class: 'AJ' },
    { word: 'disenchantment', word_class: 'N' }
  ],
  thermostatically: [
    { word: 'thermostat', word_class: 'N' },
    { word: 'thermostat', word_class: 'V' },
    { word: 'thermostatic', word_class: 'AJ' },
    { word: 'thermostatics', word_class: 'N' },
    { word: 'thermostatically', word_class: 'AV' }
  ],
  consistency: [
    { word: 'consist', word_class: 'V' },
    { word: 'consistence', word_class: 'N' },
    { word: 'consistency', word_class: 'N' },
    { word: 'consistent', word_class: 'AJ' },
    { word: 'consistently', word_class: 'AV' }
  ],
  retard: [
    { word: 'retard', word_class: 'N' },
    { word: 'retard', word_class: 'V' },
    { word: 'retarded', word_class: 'AJ' },
    { word: 'retardment', word_class: 'N' },
    { word: 'retardation', word_class: 'N' }
  ],
  gasification: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  harvesting: [
    { word: 'harvest', word_class: 'N' },
    { word: 'harvest', word_class: 'V' },
    { word: 'harvester', word_class: 'N' },
    { word: 'harvesting', word_class: 'N' },
    { word: 'harvestman', word_class: 'N' }
  ],
  whitewash: [
    { word: 'whitewash', word_class: 'N' },
    { word: 'whitewash', word_class: 'V' },
    { word: 'whitewashed', word_class: 'AJ' }
  ],
  routinely: [
    { word: 'routine', word_class: 'N' },
    { word: 'routine', word_class: 'AJ' },
    { word: 'routinize', word_class: 'V' },
    { word: 'routinely', word_class: 'AV' },
    { word: 'routinization', word_class: 'N' }
  ],
  overflowing: [
    { word: 'overflow', word_class: 'N' },
    { word: 'overflow', word_class: 'V' },
    { word: 'overflowing', word_class: 'N' },
    { word: 'overflowing', word_class: 'AJ' }
  ],
  frolicsomeness: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  telescoped: [
    { word: 'telescope', word_class: 'N' },
    { word: 'telescope', word_class: 'V' },
    { word: 'telescoped', word_class: 'AJ' },
    { word: 'telescopic', word_class: 'AJ' },
    { word: 'telescopically', word_class: 'AV' }
  ],
  vagabond: [
    { word: 'vagabond', word_class: 'N' },
    { word: 'vagabond', word_class: 'V' },
    { word: 'vagabond', word_class: 'AJ' }
  ],
  recreation: [
    { word: 'recreate', word_class: 'V' },
    { word: 'recreation', word_class: 'N' },
    { word: 'recreational', word_class: 'AJ' }
  ],
  fecundation: [
    { word: 'fecund', word_class: 'AJ' },
    { word: 'fecundate', word_class: 'V' },
    { word: 'fecundity', word_class: 'N' },
    { word: 'fecundation', word_class: 'N' }
  ],
  idolatrise: [
    { word: 'idol', word_class: 'N' },
    { word: 'idolise', word_class: 'V' },
    { word: 'idolize', word_class: 'V' },
    { word: 'idolator', word_class: 'N' },
    { word: 'idolatry', word_class: 'N' },
    { word: 'idolized', word_class: 'AJ' },
    { word: 'idolatrise', word_class: 'V' },
    { word: 'idolatrize', word_class: 'V' },
    { word: 'idolatrous', word_class: 'AJ' },
    { word: 'idolisation', word_class: 'N' },
    { word: 'idolization', word_class: 'N' },
    { word: 'idolatrously', word_class: 'AV' }
  ],
  good: [
    { word: 'good', word_class: 'N' },
    { word: 'good', word_class: 'AJ' },
    { word: 'good', word_class: 'AV' },
    { word: 'goods', word_class: 'N' },
    { word: 'goodness', word_class: 'N' }
  ],
  'snow-blinded': [
    { word: 'snow-blind', word_class: 'V' },
    { word: 'snow-blind', word_class: 'AJ' },
    { word: 'snow-blinded', word_class: 'AJ' }
  ],
  conclusion: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  wake: [
    { word: 'wake', word_class: 'N' },
    { word: 'wake', word_class: 'V' },
    { word: 'waking', word_class: 'N' },
    { word: 'woken', word_class: 'AJ' },
    { word: 'waking', word_class: 'AJ' },
    { word: 'wakeful', word_class: 'AJ' },
    { word: 'wakefulness', word_class: 'N' }
  ],
  eared: [
    { word: 'ear', word_class: 'N' },
    { word: 'eared', word_class: 'AJ' },
    { word: 'earful', word_class: 'N' },
    { word: 'earful', word_class: 'AJ' },
    { word: 'earring', word_class: 'N' }
  ],
  photosensitivity: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  rubricate: [
    { word: 'rubric', word_class: 'N' },
    { word: 'rubric', word_class: 'V' },
    { word: 'rubricate', word_class: 'V' },
    { word: 'rubrication', word_class: 'N' }
  ],
  wherewithal: [
    { word: 'wherewith', word_class: 'AV' },
    { word: 'wherewithal', word_class: 'N' },
    { word: 'wherewithal', word_class: 'AV' }
  ],
  repulsively: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  alleviation: [
    { word: 'alleviate', word_class: 'V' },
    { word: 'alleviated', word_class: 'AJ' },
    { word: 'alleviation', word_class: 'N' },
    { word: 'alleviative', word_class: 'AJ' }
  ],
  graphical: [
    { word: 'graphic', word_class: 'N' },
    { word: 'graphic', word_class: 'AJ' },
    { word: 'graphics', word_class: 'N' },
    { word: 'graphical', word_class: 'AJ' },
    { word: 'graphically', word_class: 'AV' }
  ],
  abasic: [
    { word: 'abase', word_class: 'V' },
    { word: 'abasic', word_class: 'AJ' },
    { word: 'abasement', word_class: 'N' }
  ],
  flamboyance: [
    { word: 'flamboyant', word_class: 'N' },
    { word: 'flamboyance', word_class: 'N' },
    { word: 'flamboyancy', word_class: 'N' },
    { word: 'flamboyant', word_class: 'AJ' }
  ],
  canoeist: [
    { word: 'canoe', word_class: 'N' },
    { word: 'canoe', word_class: 'V' },
    { word: 'canoeist', word_class: 'N' }
  ],
  casuistic: [
    { word: 'casuist', word_class: 'N' },
    { word: 'casuistic', word_class: 'AJ' },
    { word: 'casuistical', word_class: 'AJ' }
  ],
  cooperation: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  perspiring: [
    { word: 'perspire', word_class: 'V' },
    { word: 'perspiring', word_class: 'N' },
    { word: 'perspiring', word_class: 'AJ' },
    { word: 'perspiration', word_class: 'N' }
  ],
  racket: [
    { word: 'racket', word_class: 'N' },
    { word: 'racket', word_class: 'V' },
    { word: 'racketeer', word_class: 'N' },
    { word: 'racketeer', word_class: 'V' },
    { word: 'racketeering', word_class: 'N' }
  ],
  materialization: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  parchment: [
    { word: 'parch', word_class: 'V' },
    { word: 'parched', word_class: 'AJ' },
    { word: 'parchment', word_class: 'N' }
  ],
  nationalism: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  duplicating: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  geographically: [
    { word: 'geographer', word_class: 'N' },
    { word: 'geographic', word_class: 'AJ' },
    { word: 'geographics', word_class: 'N' },
    { word: 'geographical', word_class: 'AJ' },
    { word: 'geographically', word_class: 'AV' }
  ],
  washed: [
    { word: 'wash', word_class: 'N' },
    { word: 'wash', word_class: 'V' },
    { word: 'washer', word_class: 'N' },
    { word: 'washed', word_class: 'AJ' },
    { word: 'washing', word_class: 'N' }
  ],
  verse: [{ word: 'verse', word_class: 'N' }, { word: 'verse', word_class: 'V' }, { word: 'versed', word_class: 'AJ' }],
  generously: [
    { word: 'generous', word_class: 'AJ' },
    { word: 'generosity', word_class: 'N' },
    { word: 'generously', word_class: 'AV' },
    { word: 'generousness', word_class: 'N' }
  ],
  obscurant: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  ferris: [
    { word: 'ferry', word_class: 'N' },
    { word: 'ferry', word_class: 'V' },
    { word: 'ferris', word_class: 'N' },
    { word: 'ferrying', word_class: 'N' },
    { word: 'ferryman', word_class: 'N' }
  ],
  intolerably: [
    { word: 'intolerance', word_class: 'N' },
    { word: 'intolerant', word_class: 'AJ' },
    { word: 'intolerable', word_class: 'AJ' },
    { word: 'intolerably', word_class: 'AV' }
  ],
  circularization: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  arterialisation: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  demonization: [
    { word: 'demon', word_class: 'N' },
    { word: 'demonic', word_class: 'AJ' },
    { word: 'demonism', word_class: 'N' },
    { word: 'demonize', word_class: 'V' },
    { word: 'demonization', word_class: 'N' }
  ],
  clamorously: [
    { word: 'clam', word_class: 'N' },
    { word: 'clam', word_class: 'V' },
    { word: 'clamor', word_class: 'N' },
    { word: 'clamor', word_class: 'V' },
    { word: 'clamoring', word_class: 'N' },
    { word: 'clamorous', word_class: 'AJ' },
    { word: 'clamorously', word_class: 'AV' }
  ],
  addicted: [
    { word: 'addict', word_class: 'N' },
    { word: 'addict', word_class: 'V' },
    { word: 'addicted', word_class: 'AJ' },
    { word: 'addiction', word_class: 'N' },
    { word: 'addictive', word_class: 'AJ' }
  ],
  bounding: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  bayer: [
    { word: 'bai', word_class: 'N' },
    { word: 'bay', word_class: 'N' },
    { word: 'bay', word_class: 'V' },
    { word: 'bay', word_class: 'AJ' },
    { word: 'bayer', word_class: 'N' }
  ],
  houseman: [
    { word: 'house', word_class: 'N' },
    { word: 'house', word_class: 'V' },
    { word: 'housing', word_class: 'N' },
    { word: 'houseful', word_class: 'N' },
    { word: 'houseman', word_class: 'N' },
    { word: 'housings', word_class: 'N' },
    { word: 'houseful', word_class: 'AJ' }
  ],
  precipitable: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  cluster: [
    { word: 'cluster', word_class: 'N' },
    { word: 'cluster', word_class: 'V' },
    { word: 'clustered', word_class: 'AJ' }
  ],
  tender: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  styling: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  invigoration: [
    { word: 'invigorate', word_class: 'V' },
    { word: 'invigorated', word_class: 'AJ' },
    { word: 'invigoration', word_class: 'N' },
    { word: 'invigorating', word_class: 'AJ' }
  ],
  mutualness: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  counteractive: [
    { word: 'counteract', word_class: 'V' },
    { word: 'counteraction', word_class: 'N' },
    { word: 'counteractive', word_class: 'AJ' }
  ],
  coextensive: [
    { word: 'coextend', word_class: 'V' },
    { word: 'coextension', word_class: 'N' },
    { word: 'coextensive', word_class: 'AJ' }
  ],
  archives: [
    { word: 'archive', word_class: 'N' },
    { word: 'archive', word_class: 'V' },
    { word: 'archive', word_class: 'AJ' },
    { word: 'archives', word_class: 'N' },
    { word: 'archival', word_class: 'AJ' }
  ],
  call: [
    { word: 'call', word_class: 'N' },
    { word: 'call', word_class: 'V' },
    { word: 'caller', word_class: 'N' },
    { word: 'called', word_class: 'AJ' },
    { word: 'caller', word_class: 'AJ' },
    { word: 'calling', word_class: 'N' }
  ],
  galled: [
    { word: 'gall', word_class: 'V' },
    { word: 'gall', word_class: 'N' },
    { word: 'gallant', word_class: 'N' },
    { word: 'gallant', word_class: 'V' },
    { word: 'galled', word_class: 'AJ' },
    { word: 'gallant', word_class: 'AJ' },
    { word: 'galling', word_class: 'AJ' },
    { word: 'gallantry', word_class: 'N' }
  ],
  unused: [
    { word: 'unused', word_class: 'AJ' },
    { word: 'unusable', word_class: 'AJ' },
    { word: 'unuseable', word_class: 'AJ' },
    { word: 'unusefulness', word_class: 'N' }
  ],
  slump: [
    { word: 'slump', word_class: 'N' },
    { word: 'slump', word_class: 'V' },
    { word: 'slumped', word_class: 'AJ' }
  ],
  sanctioning: [
    { word: 'sanction', word_class: 'N' },
    { word: 'sanction', word_class: 'V' },
    { word: 'sanctioned', word_class: 'AJ' },
    { word: 'sanctioning', word_class: 'AJ' },
    { word: 'sanctionative', word_class: 'AJ' }
  ],
  quiet: [
    { word: 'quiet', word_class: 'N' },
    { word: 'quiet', word_class: 'V' },
    { word: 'quiet', word_class: 'AJ' },
    { word: 'quiet', word_class: 'AV' },
    { word: 'quietism', word_class: 'N' },
    { word: 'quietist', word_class: 'N' },
    { word: 'quietness', word_class: 'N' }
  ],
  coo: [{ word: 'coo', word_class: 'N' }, { word: 'coo', word_class: 'V' }, { word: 'cooing', word_class: 'AJ' }],
  disappearance: [
    { word: 'disappear', word_class: 'V' },
    { word: 'disappearing', word_class: 'N' },
    { word: 'disappearance', word_class: 'N' },
    { word: 'disappearing', word_class: 'AJ' }
  ],
  sniper: [{ word: 'snipe', word_class: 'N' }, { word: 'snipe', word_class: 'V' }, { word: 'sniper', word_class: 'N' }],
  divulgation: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  archeological: [
    { word: 'archeology', word_class: 'N' },
    { word: 'archeologic', word_class: 'AJ' },
    { word: 'archeological', word_class: 'AJ' }
  ],
  placer: [
    { word: 'place', word_class: 'N' },
    { word: 'place', word_class: 'V' },
    { word: 'placer', word_class: 'N' },
    { word: 'placed', word_class: 'AJ' },
    { word: 'placeman', word_class: 'N' },
    { word: 'placement', word_class: 'N' }
  ],
  antiquate: [
    { word: 'antique', word_class: 'N' },
    { word: 'antique', word_class: 'V' },
    { word: 'antique', word_class: 'AJ' },
    { word: 'antiquate', word_class: 'V' },
    { word: 'antiquity', word_class: 'N' },
    { word: 'antiquated', word_class: 'AJ' },
    { word: 'antiquation', word_class: 'N' }
  ],
  astrologer: [
    { word: 'astrology', word_class: 'N' },
    { word: 'astrologer', word_class: 'N' },
    { word: 'astrological', word_class: 'AJ' }
  ],
  rupture: [
    { word: 'rupture', word_class: 'N' },
    { word: 'rupture', word_class: 'V' },
    { word: 'ruptured', word_class: 'AJ' }
  ],
  apologize: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  cleanness: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  aristocratically: [
    { word: 'aristocrat', word_class: 'N' },
    { word: 'aristocracy', word_class: 'N' },
    { word: 'aristocratic', word_class: 'AJ' },
    { word: 'aristocratical', word_class: 'AJ' },
    { word: 'aristocratically', word_class: 'AV' }
  ],
  indicative: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  mayor: [
    { word: 'may', word_class: 'N' },
    { word: 'may', word_class: 'V' },
    { word: 'mayor', word_class: 'N' },
    { word: 'mayoral', word_class: 'AJ' }
  ],
  submariner: [
    { word: 'submarine', word_class: 'N' },
    { word: 'submarine', word_class: 'AJ' },
    { word: 'submariner', word_class: 'N' }
  ],
  innumerate: [
    { word: 'innumerate', word_class: 'AJ' },
    { word: 'innumerous', word_class: 'AJ' },
    { word: 'innumerable', word_class: 'AJ' },
    { word: 'innumerableness', word_class: 'N' }
  ],
  inadvertence: [
    { word: 'inadvertence', word_class: 'N' },
    { word: 'inadvertency', word_class: 'N' },
    { word: 'inadvertent', word_class: 'AJ' },
    { word: 'inadvertently', word_class: 'AV' }
  ],
  provoke: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  transcribe: [
    { word: 'transcribe', word_class: 'V' },
    { word: 'transcript', word_class: 'N' },
    { word: 'transcribed', word_class: 'AJ' },
    { word: 'transcription', word_class: 'N' }
  ],
  castle: [
    { word: 'castle', word_class: 'N' },
    { word: 'castle', word_class: 'V' },
    { word: 'castled', word_class: 'AJ' },
    { word: 'castling', word_class: 'N' }
  ],
  allusiveness: [
    { word: 'allude', word_class: 'V' },
    { word: 'allusion', word_class: 'N' },
    { word: 'allusive', word_class: 'AJ' },
    { word: 'allusiveness', word_class: 'N' }
  ],
  augmenting: [
    { word: 'augment', word_class: 'V' },
    { word: 'augmented', word_class: 'AJ' },
    { word: 'augmenting', word_class: 'AJ' },
    { word: 'augmentation', word_class: 'N' },
    { word: 'augmentative', word_class: 'AJ' }
  ],
  nitrogenization: [
    { word: 'nitrogen', word_class: 'N' },
    { word: 'nitrogenize', word_class: 'V' },
    { word: 'nitrogenous', word_class: 'AJ' },
    { word: 'nitrogenization', word_class: 'N' }
  ],
  postpone: [
    { word: 'postpone', word_class: 'V' },
    { word: 'postponer', word_class: 'N' },
    { word: 'postponed', word_class: 'AJ' },
    { word: 'postponement', word_class: 'N' }
  ],
  prideful: [
    { word: 'pride', word_class: 'N' },
    { word: 'pride', word_class: 'V' },
    { word: 'prideful', word_class: 'AJ' },
    { word: 'pridefulness', word_class: 'N' }
  ],
  arsenal: [
    { word: 'arsenal', word_class: 'N' },
    { word: 'arsenic', word_class: 'N' },
    { word: 'arsenate', word_class: 'N' },
    { word: 'arsenical', word_class: 'AJ' }
  ],
  season: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  fluidization: [
    { word: 'fluidize', word_class: 'V' },
    { word: 'fluidisation', word_class: 'N' },
    { word: 'fluidization', word_class: 'N' }
  ],
  anthropomorphous: [
    { word: 'anthropomorphic', word_class: 'AJ' },
    { word: 'anthropomorphism', word_class: 'N' },
    { word: 'anthropomorphize', word_class: 'V' },
    { word: 'anthropomorphous', word_class: 'AJ' },
    { word: 'anthropomorphization', word_class: 'N' }
  ],
  locke: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  quixotism: [
    { word: 'quixotic', word_class: 'AJ' },
    { word: 'quixotism', word_class: 'N' },
    { word: 'quixotically', word_class: 'AV' }
  ],
  atypical: [
    { word: 'atypic', word_class: 'AJ' },
    { word: 'atypical', word_class: 'AJ' },
    { word: 'atypicality', word_class: 'N' },
    { word: 'atypically', word_class: 'AV' }
  ],
  trawl: [{ word: 'trawl', word_class: 'N' }, { word: 'trawl', word_class: 'V' }, { word: 'trawler', word_class: 'N' }],
  testacy: [
    { word: 'testacy', word_class: 'N' },
    { word: 'testate', word_class: 'N' },
    { word: 'testate', word_class: 'AJ' },
    { word: 'testator', word_class: 'N' }
  ],
  devolve: [
    { word: 'devolve', word_class: 'V' },
    { word: 'devolution', word_class: 'N' },
    { word: 'devolvement', word_class: 'N' },
    { word: 'devolutionary', word_class: 'AJ' }
  ],
  recur: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  humidness: [
    { word: 'humid', word_class: 'AJ' },
    { word: 'humidity', word_class: 'N' },
    { word: 'humidness', word_class: 'N' }
  ],
  ethnographer: [
    { word: 'ethnographer', word_class: 'N' },
    { word: 'ethnographic', word_class: 'AJ' },
    { word: 'ethnographical', word_class: 'AJ' }
  ],
  pace: [
    { word: 'pace', word_class: 'N' },
    { word: 'pace', word_class: 'V' },
    { word: 'pacer', word_class: 'N' },
    { word: 'pacing', word_class: 'N' }
  ],
  pedantically: [
    { word: 'pedant', word_class: 'N' },
    { word: 'pedantry', word_class: 'N' },
    { word: 'pedantic', word_class: 'AJ' },
    { word: 'pedantically', word_class: 'AV' }
  ],
  theatric: [
    { word: 'theater', word_class: 'N' },
    { word: 'theatric', word_class: 'AJ' },
    { word: 'theatrical', word_class: 'N' },
    { word: 'theatrical', word_class: 'AJ' },
    { word: 'theatricality', word_class: 'N' },
    { word: 'theatrically', word_class: 'AV' }
  ],
  wailing: [
    { word: 'wail', word_class: 'N' },
    { word: 'wail', word_class: 'V' },
    { word: 'wailing', word_class: 'N' },
    { word: 'wailful', word_class: 'AJ' },
    { word: 'wailing', word_class: 'AJ' }
  ],
  despite: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  participation: [
    { word: 'participant', word_class: 'N' },
    { word: 'participate', word_class: 'V' },
    { word: 'participance', word_class: 'N' },
    { word: 'participation', word_class: 'N' },
    { word: 'participating', word_class: 'AJ' }
  ],
  combinator: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  vulgarism: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  seeing: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  translatable: [
    { word: 'translate', word_class: 'V' },
    { word: 'translate', word_class: 'AJ' },
    { word: 'translator', word_class: 'N' },
    { word: 'translation', word_class: 'N' },
    { word: 'translatable', word_class: 'AJ' },
    { word: 'translational', word_class: 'AJ' }
  ],
  prefix: [
    { word: 'prefix', word_class: 'N' },
    { word: 'prefix', word_class: 'V' },
    { word: 'prefixture', word_class: 'N' }
  ],
  bargainer: [
    { word: 'bargain', word_class: 'N' },
    { word: 'bargain', word_class: 'V' },
    { word: 'bargain', word_class: 'AJ' },
    { word: 'bargainer', word_class: 'N' },
    { word: 'bargaining', word_class: 'N' }
  ],
  dogmatically: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  palatalization: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  tidiness: [
    { word: 'tidy', word_class: 'N' },
    { word: 'tidy', word_class: 'V' },
    { word: 'tidy', word_class: 'AJ' },
    { word: 'tidiness', word_class: 'N' }
  ],
  intrinsic: [
    { word: 'intrinsic', word_class: 'AJ' },
    { word: 'intrinsical', word_class: 'AJ' },
    { word: 'intrinsically', word_class: 'AV' }
  ],
  revitalized: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  spacious: [
    { word: 'space', word_class: 'N' },
    { word: 'space', word_class: 'V' },
    { word: 'spaced', word_class: 'AJ' },
    { word: 'spacing', word_class: 'N' },
    { word: 'spaceman', word_class: 'N' },
    { word: 'spatial', word_class: 'AJ' },
    { word: 'spaceship', word_class: 'N' },
    { word: 'spacious', word_class: 'AJ' },
    { word: 'spatiality', word_class: 'N' },
    { word: 'spatially', word_class: 'AV' }
  ],
  enumerable: [
    { word: 'enumerate', word_class: 'V' },
    { word: 'enumerable', word_class: 'AJ' },
    { word: 'enumeration', word_class: 'N' }
  ],
  magnificence: [
    { word: 'magnificence', word_class: 'N' },
    { word: 'magnificent', word_class: 'AJ' },
    { word: 'magnificently', word_class: 'AV' }
  ],
  obstreperousness: [
    { word: 'obstreperous', word_class: 'AJ' },
    { word: 'obstreperously', word_class: 'AV' },
    { word: 'obstreperousness', word_class: 'N' }
  ],
  widowed: [
    { word: 'widow', word_class: 'N' },
    { word: 'widow', word_class: 'V' },
    { word: 'widower', word_class: 'N' },
    { word: 'widowed', word_class: 'AJ' }
  ],
  composition: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  brahminical: [
    { word: 'brahmin', word_class: 'N' },
    { word: 'brahminic', word_class: 'AJ' },
    { word: 'brahminism', word_class: 'N' },
    { word: 'brahminical', word_class: 'AJ' }
  ],
  odor: [
    { word: 'odor', word_class: 'N' },
    { word: 'odorize', word_class: 'V' },
    { word: 'odorous', word_class: 'AJ' },
    { word: 'odoriferous', word_class: 'AJ' }
  ],
  primitive: [
    { word: 'primitive', word_class: 'N' },
    { word: 'primitive', word_class: 'AJ' },
    { word: 'primitively', word_class: 'AV' },
    { word: 'primitiveness', word_class: 'N' }
  ],
  spattering: [
    { word: 'spatter', word_class: 'N' },
    { word: 'spatter', word_class: 'V' },
    { word: 'spattered', word_class: 'AJ' },
    { word: 'spattering', word_class: 'N' }
  ],
  elicited: [
    { word: 'elicit', word_class: 'V' },
    { word: 'elicited', word_class: 'AJ' },
    { word: 'elicitation', word_class: 'N' }
  ],
  suggestively: [
    { word: 'suggest', word_class: 'V' },
    { word: 'suggested', word_class: 'AJ' },
    { word: 'suggestion', word_class: 'N' },
    { word: 'suggestive', word_class: 'AJ' },
    { word: 'suggestible', word_class: 'AJ' },
    { word: 'suggestively', word_class: 'AV' },
    { word: 'suggestibility', word_class: 'N' }
  ],
  matriculate: [
    { word: 'matriculate', word_class: 'N' },
    { word: 'matriculate', word_class: 'V' },
    { word: 'matriculation', word_class: 'N' }
  ],
  insipidity: [
    { word: 'insipid', word_class: 'AJ' },
    { word: 'insipidity', word_class: 'N' },
    { word: 'insipidness', word_class: 'N' }
  ],
  transformation: [
    { word: 'transform', word_class: 'N' },
    { word: 'transform', word_class: 'V' },
    { word: 'transformer', word_class: 'N' },
    { word: 'transformed', word_class: 'AJ' },
    { word: 'transformable', word_class: 'AJ' },
    { word: 'transformation', word_class: 'N' }
  ],
  cur: [
    { word: 'cur', word_class: 'N' },
    { word: 'cur', word_class: 'AJ' },
    { word: 'curative', word_class: 'N' },
    { word: 'curative', word_class: 'AJ' }
  ],
  sensuous: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  tessellated: [
    { word: 'tesselate', word_class: 'V' },
    { word: 'tessellate', word_class: 'V' },
    { word: 'tessellated', word_class: 'AJ' },
    { word: 'tessellation', word_class: 'N' }
  ],
  formularisation: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  injudiciously: [
    { word: 'injudicious', word_class: 'AJ' },
    { word: 'injudiciously', word_class: 'AV' },
    { word: 'injudiciousness', word_class: 'N' }
  ],
  chartered: [
    { word: 'charter', word_class: 'N' },
    { word: 'charter', word_class: 'V' },
    { word: 'charterage', word_class: 'N' },
    { word: 'chartered', word_class: 'AJ' }
  ],
  jesuitic: [
    { word: 'jesuit', word_class: 'N' },
    { word: 'jesuit', word_class: 'AJ' },
    { word: 'jesuitic', word_class: 'AJ' },
    { word: 'jesuitism', word_class: 'N' },
    { word: 'jesuitical', word_class: 'AJ' }
  ],
  galloping: [
    { word: 'gallop', word_class: 'N' },
    { word: 'gallop', word_class: 'V' },
    { word: 'galloping', word_class: 'AJ' }
  ],
  rag: [
    { word: 'rag', word_class: 'N' },
    { word: 'rag', word_class: 'V' },
    { word: 'ragged', word_class: 'AJ' },
    { word: 'raggedy', word_class: 'AJ' }
  ],
  dilapidated: [
    { word: 'dilapidate', word_class: 'V' },
    { word: 'dilapidated', word_class: 'AJ' },
    { word: 'dilapidation', word_class: 'N' }
  ],
  talismanic: [
    { word: 'talisman', word_class: 'N' },
    { word: 'talisman', word_class: 'AJ' },
    { word: 'talismanic', word_class: 'AJ' }
  ],
  compart: [
    { word: 'compart', word_class: 'V' },
    { word: 'compartment', word_class: 'N' },
    { word: 'compartmented', word_class: 'AJ' }
  ],
  aggrandise: [
    { word: 'aggrandise', word_class: 'V' },
    { word: 'aggrandize', word_class: 'V' },
    { word: 'aggrandisement', word_class: 'N' },
    { word: 'aggrandizement', word_class: 'N' }
  ],
  domesticate: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  electoral: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  corrode: [
    { word: 'corrode', word_class: 'V' },
    { word: 'corroded', word_class: 'AJ' },
    { word: 'corroding', word_class: 'N' },
    { word: 'corrosion', word_class: 'N' },
    { word: 'corrosive', word_class: 'N' },
    { word: 'corrosive', word_class: 'AJ' }
  ],
  renunciative: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  supervised: [
    { word: 'supervise', word_class: 'N' },
    { word: 'supervise', word_class: 'V' },
    { word: 'supervisor', word_class: 'N' },
    { word: 'supervised', word_class: 'AJ' },
    { word: 'supervising', word_class: 'N' },
    { word: 'supervision', word_class: 'N' }
  ],
  emendable: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  region: [
    { word: 'region', word_class: 'N' },
    { word: 'regional', word_class: 'AJ' },
    { word: 'regionalize', word_class: 'V' },
    { word: 'regionally', word_class: 'AV' }
  ],
  unpleasing: [
    { word: 'unpleasant', word_class: 'AJ' },
    { word: 'unpleasing', word_class: 'AJ' },
    { word: 'unpleasantness', word_class: 'N' }
  ],
  aloneness: [
    { word: 'alone', word_class: 'AJ' },
    { word: 'alone', word_class: 'AV' },
    { word: 'aloneness', word_class: 'N' }
  ],
  advertize: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  bones: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  unskilled: [
    { word: 'unskilled', word_class: 'AJ' },
    { word: 'unskillful', word_class: 'AJ' },
    { word: 'unskillfulness', word_class: 'N' }
  ],
  unnoticeable: [
    { word: 'unnoticeable', word_class: 'AJ' },
    { word: 'unnoticeably', word_class: 'AV' },
    { word: 'unnoticeableness', word_class: 'N' }
  ],
  wilder: [
    { word: 'wilder', word_class: 'V' },
    { word: 'wilderment', word_class: 'N' },
    { word: 'wilderness', word_class: 'N' }
  ],
  wilderment: [
    { word: 'wilder', word_class: 'V' },
    { word: 'wilderment', word_class: 'N' },
    { word: 'wilderness', word_class: 'N' }
  ],
  crystallize: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  immateriality: [
    { word: 'immaterial', word_class: 'AJ' },
    { word: 'immateriality', word_class: 'N' },
    { word: 'immaterialize', word_class: 'V' },
    { word: 'immaterialization', word_class: 'N' }
  ],
  sum: [
    { word: 'sum', word_class: 'N' },
    { word: 'sum', word_class: 'V' },
    { word: 'summate', word_class: 'V' },
    { word: 'summation', word_class: 'N' },
    { word: 'summational', word_class: 'AJ' }
  ],
  forging: [
    { word: 'forge', word_class: 'N' },
    { word: 'forge', word_class: 'V' },
    { word: 'forger', word_class: 'N' },
    { word: 'forged', word_class: 'AJ' },
    { word: 'forgery', word_class: 'N' },
    { word: 'forging', word_class: 'N' },
    { word: 'forgery', word_class: 'AJ' }
  ],
  controversion: [
    { word: 'controvert', word_class: 'V' },
    { word: 'controversy', word_class: 'N' },
    { word: 'controversion', word_class: 'N' },
    { word: 'controversial', word_class: 'AJ' },
    { word: 'controversially', word_class: 'AV' }
  ],
  cryptic: [
    { word: 'cryptic', word_class: 'AJ' },
    { word: 'cryptical', word_class: 'AJ' },
    { word: 'cryptically', word_class: 'AV' }
  ],
  malignance: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  cording: [
    { word: 'cord', word_class: 'N' },
    { word: 'cord', word_class: 'V' },
    { word: 'cords', word_class: 'N' },
    { word: 'corded', word_class: 'AJ' },
    { word: 'cording', word_class: 'N' },
    { word: 'cordite', word_class: 'N' }
  ],
  traitorous: [
    { word: 'traitor', word_class: 'N' },
    { word: 'traitorous', word_class: 'AJ' },
    { word: 'traitorously', word_class: 'AV' },
    { word: 'traitorousness', word_class: 'N' }
  ],
  vernalization: [
    { word: 'vernal', word_class: 'AJ' },
    { word: 'vernalise', word_class: 'V' },
    { word: 'vernalize', word_class: 'V' },
    { word: 'vernalisation', word_class: 'N' },
    { word: 'vernalization', word_class: 'N' }
  ],
  uglification: [
    { word: 'ugli', word_class: 'N' },
    { word: 'ugly', word_class: 'AJ' },
    { word: 'uglify', word_class: 'V' },
    { word: 'ugliness', word_class: 'N' },
    { word: 'uglification', word_class: 'N' }
  ],
  bastioned: [
    { word: 'bast', word_class: 'N' },
    { word: 'baste', word_class: 'V' },
    { word: 'baste', word_class: 'N' },
    { word: 'basting', word_class: 'N' },
    { word: 'bastion', word_class: 'N' },
    { word: 'bastioned', word_class: 'AJ' }
  ],
  ministry: [
    { word: 'minister', word_class: 'V' },
    { word: 'minister', word_class: 'N' },
    { word: 'ministry', word_class: 'N' },
    { word: 'ministrant', word_class: 'N' },
    { word: 'ministrant', word_class: 'AJ' },
    { word: 'ministering', word_class: 'AJ' },
    { word: 'ministration', word_class: 'N' }
  ],
  inversion: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  together: [
    { word: 'together', word_class: 'AJ' },
    { word: 'together', word_class: 'AV' },
    { word: 'togetherness', word_class: 'N' }
  ],
  insecureness: [
    { word: 'insecure', word_class: 'AJ' },
    { word: 'insecurity', word_class: 'N' },
    { word: 'insecurely', word_class: 'AV' },
    { word: 'insecureness', word_class: 'N' }
  ],
  generalist: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  jacobinic: [
    { word: 'jacobin', word_class: 'N' },
    { word: 'jacobinic', word_class: 'AJ' },
    { word: 'jacobinism', word_class: 'N' },
    { word: 'jacobinical', word_class: 'AJ' }
  ],
  hedged: [
    { word: 'hedge', word_class: 'N' },
    { word: 'hedge', word_class: 'V' },
    { word: 'hedged', word_class: 'AJ' },
    { word: 'hedging', word_class: 'N' }
  ],
  blamed: [
    { word: 'blame', word_class: 'N' },
    { word: 'blame', word_class: 'V' },
    { word: 'blame', word_class: 'AJ' },
    { word: 'blamed', word_class: 'AJ' },
    { word: 'blameful', word_class: 'AJ' }
  ],
  chicness: [
    { word: 'chic', word_class: 'N' },
    { word: 'chic', word_class: 'AJ' },
    { word: 'chicness', word_class: 'N' }
  ],
  dermatologic: [
    { word: 'dermatology', word_class: 'N' },
    { word: 'dermatologic', word_class: 'AJ' },
    { word: 'dermatological', word_class: 'AJ' }
  ],
  overlap: [
    { word: 'overlap', word_class: 'N' },
    { word: 'overlap', word_class: 'V' },
    { word: 'overlapping', word_class: 'N' },
    { word: 'overlapping', word_class: 'AJ' }
  ],
  presciently: [
    { word: 'prescience', word_class: 'N' },
    { word: 'prescient', word_class: 'AJ' },
    { word: 'presciently', word_class: 'AV' }
  ],
  hypersensitivity: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  tactics: [
    { word: 'tactic', word_class: 'N' },
    { word: 'tactic', word_class: 'AJ' },
    { word: 'tactics', word_class: 'N' },
    { word: 'tactical', word_class: 'AJ' },
    { word: 'tactically', word_class: 'AV' }
  ],
  garb: [{ word: 'garb', word_class: 'N' }, { word: 'garb', word_class: 'V' }, { word: 'garbed', word_class: 'AJ' }],
  dreamed: [
    { word: 'dream', word_class: 'N' },
    { word: 'dream', word_class: 'V' },
    { word: 'dreamer', word_class: 'N' },
    { word: 'dreamed', word_class: 'AJ' },
    { word: 'dreaming', word_class: 'N' }
  ],
  annoyed: [
    { word: 'annoy', word_class: 'V' },
    { word: 'annoyer', word_class: 'N' },
    { word: 'annoyed', word_class: 'AJ' },
    { word: 'annoying', word_class: 'N' },
    { word: 'annoyance', word_class: 'N' },
    { word: 'annoying', word_class: 'AJ' }
  ],
  inadvisably: [
    { word: 'inadvisable', word_class: 'AJ' },
    { word: 'inadvisably', word_class: 'AV' },
    { word: 'inadvisability', word_class: 'N' }
  ],
  unsound: [
    { word: 'unsound', word_class: 'AJ' },
    { word: 'unsounded', word_class: 'AJ' },
    { word: 'unsoundness', word_class: 'N' },
    { word: 'unsoundable', word_class: 'AJ' }
  ],
  decomposable: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  sweetened: [
    { word: 'sweeten', word_class: 'V' },
    { word: 'sweetener', word_class: 'N' },
    { word: 'sweetened', word_class: 'AJ' },
    { word: 'sweetening', word_class: 'N' }
  ],
  ripe: [
    { word: 'ripe', word_class: 'AJ' },
    { word: 'ripely', word_class: 'AV' },
    { word: 'ripeness', word_class: 'N' }
  ],
  resonation: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  indetermination: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  sour: [
    { word: 'sour', word_class: 'N' },
    { word: 'sour', word_class: 'V' },
    { word: 'sour', word_class: 'AJ' },
    { word: 'soured', word_class: 'AJ' },
    { word: 'souring', word_class: 'N' },
    { word: 'sourness', word_class: 'N' }
  ],
  vitiation: [
    { word: 'vitiate', word_class: 'V' },
    { word: 'vitiated', word_class: 'AJ' },
    { word: 'vitiation', word_class: 'N' }
  ],
  accelerated: [
    { word: 'accelerate', word_class: 'V' },
    { word: 'accelerator', word_class: 'N' },
    { word: 'accelerated', word_class: 'AJ' },
    { word: 'acceleration', word_class: 'N' },
    { word: 'accelerating', word_class: 'AJ' }
  ],
  grangerize: [
    { word: 'granger', word_class: 'N' },
    { word: 'grangerism', word_class: 'N' },
    { word: 'grangerize', word_class: 'V' },
    { word: 'grangerization', word_class: 'N' }
  ],
  pluralism: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  threatened: [
    { word: 'threat', word_class: 'N' },
    { word: 'threaten', word_class: 'V' },
    { word: 'threatened', word_class: 'AJ' },
    { word: 'threatening', word_class: 'AJ' }
  ],
  destroyer: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  daunting: [
    { word: 'daunt', word_class: 'V' },
    { word: 'daunted', word_class: 'AJ' },
    { word: 'daunting', word_class: 'AJ' },
    { word: 'dauntless', word_class: 'AJ' },
    { word: 'dauntlessness', word_class: 'N' }
  ],
  prolongate: [
    { word: 'prolong', word_class: 'V' },
    { word: 'prolong', word_class: 'AJ' },
    { word: 'prolongate', word_class: 'V' },
    { word: 'prolonged', word_class: 'AJ' },
    { word: 'prolongment', word_class: 'N' },
    { word: 'prolongation', word_class: 'N' }
  ],
  agglomerate: [
    { word: 'agglomerate', word_class: 'V' },
    { word: 'agglomerate', word_class: 'AJ' },
    { word: 'agglomerated', word_class: 'AJ' },
    { word: 'agglomeration', word_class: 'N' }
  ],
  lodgings: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  fantasia: [
    { word: 'fantasy', word_class: 'N' },
    { word: 'fantasia', word_class: 'N' },
    { word: 'fantasist', word_class: 'N' },
    { word: 'fantasize', word_class: 'V' }
  ],
  neurological: [
    { word: 'neurology', word_class: 'N' },
    { word: 'neurologic', word_class: 'AJ' },
    { word: 'neurological', word_class: 'AJ' }
  ],
  age: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  demoralise: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  witching: [
    { word: 'witch', word_class: 'N' },
    { word: 'witch', word_class: 'V' },
    { word: 'witchery', word_class: 'N' },
    { word: 'witching', word_class: 'AJ' }
  ],
  patriarchs: [
    { word: 'patriarch', word_class: 'N' },
    { word: 'patriarchs', word_class: 'N' },
    { word: 'patriarchal', word_class: 'AJ' },
    { word: 'patriarchate', word_class: 'N' },
    { word: 'patriarchic', word_class: 'AJ' }
  ],
  speculative: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  luminal: [
    { word: 'luminal', word_class: 'N' },
    { word: 'luminance', word_class: 'N' },
    { word: 'luminous', word_class: 'AJ' },
    { word: 'luminousness', word_class: 'N' }
  ],
  classics: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  insensibility: [
    { word: 'insensate', word_class: 'AJ' },
    { word: 'insensible', word_class: 'AJ' },
    { word: 'insensibly', word_class: 'AV' },
    { word: 'insensibility', word_class: 'N' }
  ],
  contribution: [
    { word: 'contribute', word_class: 'V' },
    { word: 'contributor', word_class: 'N' },
    { word: 'contribution', word_class: 'N' },
    { word: 'contributing', word_class: 'AJ' },
    { word: 'contributive', word_class: 'AJ' }
  ],
  pretence: [
    { word: 'pretend', word_class: 'V' },
    { word: 'pretence', word_class: 'N' },
    { word: 'pretend', word_class: 'AJ' },
    { word: 'pretense', word_class: 'N' },
    { word: 'pretense', word_class: 'V' },
    { word: 'pretender', word_class: 'N' },
    { word: 'pretense', word_class: 'AJ' },
    { word: 'pretended', word_class: 'AJ' },
    { word: 'pretending', word_class: 'N' },
    { word: 'pretension', word_class: 'N' }
  ],
  primer: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  titular: [
    { word: 'title', word_class: 'N' },
    { word: 'title', word_class: 'V' },
    { word: 'titled', word_class: 'AJ' },
    { word: 'titular', word_class: 'AJ' }
  ],
  quaver: [
    { word: 'quaver', word_class: 'N' },
    { word: 'quaver', word_class: 'V' },
    { word: 'quavery', word_class: 'AJ' },
    { word: 'quavering', word_class: 'AJ' }
  ],
  honoring: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  account: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  don: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  provocation: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  subject: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  copolymerise: [
    { word: 'copolymerise', word_class: 'V' },
    { word: 'copolymerize', word_class: 'V' },
    { word: 'copolymerisation', word_class: 'N' },
    { word: 'copolymerization', word_class: 'N' }
  ],
  subserve: [
    { word: 'subserve', word_class: 'V' },
    { word: 'subservience', word_class: 'N' },
    { word: 'subservient', word_class: 'AJ' },
    { word: 'subserviently', word_class: 'AV' },
    { word: 'subservientness', word_class: 'N' }
  ],
  endurable: [
    { word: 'endure', word_class: 'V' },
    { word: 'endurance', word_class: 'N' },
    { word: 'enduring', word_class: 'AJ' },
    { word: 'endurable', word_class: 'AJ' }
  ],
  sneak: [
    { word: 'sneak', word_class: 'N' },
    { word: 'sneak', word_class: 'V' },
    { word: 'sneak', word_class: 'AJ' },
    { word: 'sneaker', word_class: 'N' },
    { word: 'sneaky', word_class: 'AJ' },
    { word: 'sneaking', word_class: 'AJ' },
    { word: 'sneakiness', word_class: 'N' }
  ],
  immune: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  moderation: [
    { word: 'moderacy', word_class: 'N' },
    { word: 'moderate', word_class: 'V' },
    { word: 'moderate', word_class: 'N' },
    { word: 'moderate', word_class: 'AJ' },
    { word: 'moderator', word_class: 'N' },
    { word: 'moderation', word_class: 'N' },
    { word: 'moderatism', word_class: 'N' },
    { word: 'moderated', word_class: 'AJ' },
    { word: 'moderately', word_class: 'AV' },
    { word: 'moderating', word_class: 'AJ' },
    { word: 'moderateness', word_class: 'N' }
  ],
  lutheran: [
    { word: 'lutheran', word_class: 'N' },
    { word: 'lutheran', word_class: 'AJ' },
    { word: 'lutheranism', word_class: 'N' }
  ],
  moralistic: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  fiercely: [
    { word: 'fierce', word_class: 'AJ' },
    { word: 'fiercely', word_class: 'AV' },
    { word: 'fierceness', word_class: 'N' }
  ],
  corpulent: [
    { word: 'corpulence', word_class: 'N' },
    { word: 'corpulency', word_class: 'N' },
    { word: 'corpulent', word_class: 'AJ' }
  ],
  ascent: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  whacked: [
    { word: 'whack', word_class: 'N' },
    { word: 'whack', word_class: 'V' },
    { word: 'whacker', word_class: 'N' },
    { word: 'whacked', word_class: 'AJ' },
    { word: 'whacking', word_class: 'N' },
    { word: 'whacking', word_class: 'AJ' },
    { word: 'whacking', word_class: 'AV' }
  ],
  threshing: [
    { word: 'thresh', word_class: 'V' },
    { word: 'thresher', word_class: 'N' },
    { word: 'threshing', word_class: 'N' }
  ],
  fined: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  extirpable: [
    { word: 'extirpate', word_class: 'V' },
    { word: 'extirpable', word_class: 'AJ' },
    { word: 'extirpation', word_class: 'N' }
  ],
  vitalization: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  conventionally: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  stitch: [
    { word: 'stitch', word_class: 'N' },
    { word: 'stitch', word_class: 'V' },
    { word: 'stitcher', word_class: 'N' },
    { word: 'stitched', word_class: 'AJ' },
    { word: 'stitching', word_class: 'N' }
  ],
  dehydrogenate: [
    { word: 'dehydrogenate', word_class: 'V' },
    { word: 'dehydrogenation', word_class: 'N' },
    { word: 'dehydrogenisation', word_class: 'N' },
    { word: 'dehydrogenization', word_class: 'N' }
  ],
  clank: [
    { word: 'clank', word_class: 'N' },
    { word: 'clank', word_class: 'V' },
    { word: 'clanking', word_class: 'AJ' }
  ],
  tattling: [
    { word: 'tattle', word_class: 'N' },
    { word: 'tattle', word_class: 'V' },
    { word: 'tattler', word_class: 'N' },
    { word: 'tattling', word_class: 'AJ' }
  ],
  quaternity: [
    { word: 'quatern', word_class: 'N' },
    { word: 'quaternity', word_class: 'N' },
    { word: 'quaternate', word_class: 'AJ' }
  ],
  morphologically: [
    { word: 'morphology', word_class: 'N' },
    { word: 'morphologic', word_class: 'AJ' },
    { word: 'morphological', word_class: 'AJ' },
    { word: 'morphologically', word_class: 'AV' }
  ],
  ideal: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  variational: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  wearying: [
    { word: 'weary', word_class: 'V' },
    { word: 'weary', word_class: 'AJ' },
    { word: 'wearied', word_class: 'AJ' },
    { word: 'weariness', word_class: 'N' },
    { word: 'wearying', word_class: 'AJ' }
  ],
  falseness: [
    { word: 'false', word_class: 'AJ' },
    { word: 'false', word_class: 'AV' },
    { word: 'falsely', word_class: 'AV' },
    { word: 'falsehood', word_class: 'N' },
    { word: 'falseness', word_class: 'N' }
  ],
  whereabout: [
    { word: 'whereabout', word_class: 'AV' },
    { word: 'whereabouts', word_class: 'N' },
    { word: 'whereabouts', word_class: 'AV' }
  ],
  probation: [
    { word: 'probate', word_class: 'N' },
    { word: 'probate', word_class: 'V' },
    { word: 'probation', word_class: 'N' },
    { word: 'probationary', word_class: 'AJ' }
  ],
  anu: [{ word: 'anu', word_class: 'N' }, { word: 'anus', word_class: 'N' }, { word: 'anal', word_class: 'AJ' }],
  syntax: [
    { word: 'syntax', word_class: 'N' },
    { word: 'syntactic', word_class: 'AJ' },
    { word: 'syntactical', word_class: 'AJ' },
    { word: 'syntactically', word_class: 'AV' }
  ],
  burial: [
    { word: 'bury', word_class: 'V' },
    { word: 'burial', word_class: 'N' },
    { word: 'burier', word_class: 'N' },
    { word: 'buried', word_class: 'AJ' },
    { word: 'burying', word_class: 'N' }
  ],
  cataloger: [
    { word: 'catalog', word_class: 'N' },
    { word: 'catalog', word_class: 'V' },
    { word: 'cataloger', word_class: 'N' }
  ],
  evidence: [
    { word: 'evidence', word_class: 'N' },
    { word: 'evidence', word_class: 'V' },
    { word: 'evident', word_class: 'AJ' },
    { word: 'evidently', word_class: 'AV' },
    { word: 'evidential', word_class: 'AJ' }
  ],
  mobilise: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  compassionate: [
    { word: 'compass', word_class: 'N' },
    { word: 'compass', word_class: 'V' },
    { word: 'compassion', word_class: 'N' },
    { word: 'compassionate', word_class: 'V' },
    { word: 'compassionate', word_class: 'AJ' },
    { word: 'compassionately', word_class: 'AV' },
    { word: 'compassionateness', word_class: 'N' }
  ],
  judgmental: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  confirmatory: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  paradize: [
    { word: 'parade', word_class: 'N' },
    { word: 'parade', word_class: 'V' },
    { word: 'parader', word_class: 'N' },
    { word: 'paradize', word_class: 'V' }
  ],
  romanise: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  complement: [
    { word: 'complement', word_class: 'N' },
    { word: 'complement', word_class: 'V' },
    { word: 'complemental', word_class: 'AJ' },
    { word: 'complementary', word_class: 'AJ' },
    { word: 'complementation', word_class: 'N' }
  ],
  devisal: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  grantor: [
    { word: 'grant', word_class: 'N' },
    { word: 'grant', word_class: 'V' },
    { word: 'grantee', word_class: 'N' },
    { word: 'grantor', word_class: 'N' },
    { word: 'granted', word_class: 'AJ' }
  ],
  consideration: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  unsymmetric: [
    { word: 'unsymmetric', word_class: 'AJ' },
    { word: 'unsymmetrical', word_class: 'AJ' },
    { word: 'unsymmetrically', word_class: 'AV' }
  ],
  queuing: [
    { word: 'queue', word_class: 'N' },
    { word: 'queue', word_class: 'V' },
    { word: 'queuing', word_class: 'AJ' }
  ],
  voyeuristic: [
    { word: 'voyeuristic', word_class: 'AJ' },
    { word: 'voyeuristical', word_class: 'AJ' },
    { word: 'voyeuristically', word_class: 'AV' }
  ],
  transliteration: [
    { word: 'transliterate', word_class: 'V' },
    { word: 'transliterate', word_class: 'AJ' },
    { word: 'transliteration', word_class: 'N' }
  ],
  hatched: [
    { word: 'hatch', word_class: 'N' },
    { word: 'hatch', word_class: 'V' },
    { word: 'hatched', word_class: 'AJ' },
    { word: 'hatching', word_class: 'N' },
    { word: 'hatching', word_class: 'AJ' }
  ],
  cauterization: [
    { word: 'cauterise', word_class: 'V' },
    { word: 'cauterize', word_class: 'V' },
    { word: 'cauterisation', word_class: 'N' },
    { word: 'cauterization', word_class: 'N' }
  ],
  lick: [
    { word: 'lick', word_class: 'N' },
    { word: 'lick', word_class: 'V' },
    { word: 'licked', word_class: 'AJ' },
    { word: 'licking', word_class: 'N' }
  ],
  romanticise: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  egg: [{ word: 'egg', word_class: 'N' }, { word: 'egg', word_class: 'V' }, { word: 'eggs', word_class: 'N' }],
  workman: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  brownness: [
    { word: 'brown', word_class: 'N' },
    { word: 'brown', word_class: 'V' },
    { word: 'brown', word_class: 'AJ' },
    { word: 'browned', word_class: 'AJ' },
    { word: 'browning', word_class: 'N' },
    { word: 'brownness', word_class: 'N' }
  ],
  reliableness: [
    { word: 'reliable', word_class: 'AJ' },
    { word: 'reliably', word_class: 'AV' },
    { word: 'reliability', word_class: 'N' },
    { word: 'reliableness', word_class: 'N' }
  ],
  engaged: [
    { word: 'engage', word_class: 'V' },
    { word: 'engaged', word_class: 'AJ' },
    { word: 'engaging', word_class: 'AJ' },
    { word: 'engagement', word_class: 'N' }
  ],
  trivialization: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  mimicked: [
    { word: 'mimic', word_class: 'N' },
    { word: 'mimic', word_class: 'V' },
    { word: 'mimic', word_class: 'AJ' },
    { word: 'mimicry', word_class: 'N' },
    { word: 'mimicker', word_class: 'V' },
    { word: 'mimicker', word_class: 'N' },
    { word: 'mimickry', word_class: 'N' },
    { word: 'mimicked', word_class: 'AJ' },
    { word: 'mimicking', word_class: 'AJ' }
  ],
  itchy: [
    { word: 'itch', word_class: 'N' },
    { word: 'itch', word_class: 'V' },
    { word: 'itchy', word_class: 'AJ' },
    { word: 'itching', word_class: 'N' },
    { word: 'itchiness', word_class: 'N' }
  ],
  burble: [
    { word: 'burble', word_class: 'V' },
    { word: 'burbly', word_class: 'AJ' },
    { word: 'burbling', word_class: 'AJ' }
  ],
  peculator: [
    { word: 'peculate', word_class: 'V' },
    { word: 'peculator', word_class: 'N' },
    { word: 'peculation', word_class: 'N' }
  ],
  vaunter: [
    { word: 'vaunt', word_class: 'N' },
    { word: 'vaunt', word_class: 'V' },
    { word: 'vaunter', word_class: 'N' }
  ],
  baste: [
    { word: 'bast', word_class: 'N' },
    { word: 'baste', word_class: 'V' },
    { word: 'baste', word_class: 'N' },
    { word: 'basting', word_class: 'N' },
    { word: 'bastion', word_class: 'N' },
    { word: 'bastioned', word_class: 'AJ' }
  ],
  adorned: [
    { word: 'adorn', word_class: 'V' },
    { word: 'adorned', word_class: 'AJ' },
    { word: 'adornment', word_class: 'N' }
  ],
  represent: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  scold: [
    { word: 'scold', word_class: 'N' },
    { word: 'scold', word_class: 'V' },
    { word: 'scolder', word_class: 'N' },
    { word: 'scolding', word_class: 'N' },
    { word: 'scolding', word_class: 'AJ' }
  ],
  lift: [
    { word: 'lift', word_class: 'N' },
    { word: 'lift', word_class: 'V' },
    { word: 'lifter', word_class: 'N' },
    { word: 'lifted', word_class: 'AJ' },
    { word: 'liftman', word_class: 'N' }
  ],
  reform: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  strategically: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  parsee: [{ word: 'parse', word_class: 'N' }, { word: 'parse', word_class: 'V' }, { word: 'parsee', word_class: 'N' }],
  dressed: [
    { word: 'dress', word_class: 'N' },
    { word: 'dress', word_class: 'V' },
    { word: 'dress', word_class: 'AJ' },
    { word: 'dresser', word_class: 'N' },
    { word: 'dressed', word_class: 'AJ' },
    { word: 'dressing', word_class: 'N' }
  ],
  dandify: [
    { word: 'dandify', word_class: 'V' },
    { word: 'dandified', word_class: 'AJ' },
    { word: 'dandification', word_class: 'N' }
  ],
  appendicitis: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  officiously: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  ban: [
    { word: 'ban', word_class: 'N' },
    { word: 'ban', word_class: 'V' },
    { word: 'banned', word_class: 'AJ' },
    { word: 'banning', word_class: 'N' }
  ],
  contort: [
    { word: 'contort', word_class: 'V' },
    { word: 'contorsion', word_class: 'N' },
    { word: 'contorted', word_class: 'AJ' },
    { word: 'contortion', word_class: 'N' }
  ],
  ingrain: [
    { word: 'ingrain', word_class: 'V' },
    { word: 'ingrained', word_class: 'AJ' },
    { word: 'ingraining', word_class: 'N' }
  ],
  sublimity: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  quotation: [
    { word: 'quote', word_class: 'N' },
    { word: 'quote', word_class: 'V' },
    { word: 'quotation', word_class: 'N' }
  ],
  rescind: [
    { word: 'rescind', word_class: 'V' },
    { word: 'rescinding', word_class: 'N' },
    { word: 'rescission', word_class: 'N' },
    { word: 'rescindment', word_class: 'N' },
    { word: 'rescindable', word_class: 'AJ' }
  ],
  morbid: [
    { word: 'morbid', word_class: 'AJ' },
    { word: 'morbidity', word_class: 'N' },
    { word: 'morbidness', word_class: 'N' }
  ],
  normanise: [
    { word: 'norman', word_class: 'N' },
    { word: 'norman', word_class: 'AJ' },
    { word: 'normanise', word_class: 'V' },
    { word: 'normanize', word_class: 'V' },
    { word: 'normanisation', word_class: 'N' },
    { word: 'normanization', word_class: 'N' }
  ],
  perversion: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  discourage: [
    { word: 'discourage', word_class: 'N' },
    { word: 'discourage', word_class: 'V' },
    { word: 'discouraged', word_class: 'AJ' },
    { word: 'discouraging', word_class: 'AJ' },
    { word: 'discouragement', word_class: 'N' }
  ],
  recommittal: [
    { word: 'recommit', word_class: 'V' },
    { word: 'recommittal', word_class: 'N' },
    { word: 'recommitment', word_class: 'N' }
  ],
  affirmation: [
    { word: 'affirm', word_class: 'V' },
    { word: 'affirmation', word_class: 'N' },
    { word: 'affirmable', word_class: 'AJ' },
    { word: 'affirmative', word_class: 'N' },
    { word: 'affirmative', word_class: 'AJ' },
    { word: 'affirmativeness', word_class: 'N' }
  ],
  imprudence: [
    { word: 'imprudence', word_class: 'N' },
    { word: 'imprudent', word_class: 'AJ' },
    { word: 'imprudently', word_class: 'AV' }
  ],
  hop: [{ word: 'hop', word_class: 'N' }, { word: 'hop', word_class: 'V' }, { word: 'hops', word_class: 'N' }],
  shrill: [
    { word: 'shrill', word_class: 'V' },
    { word: 'shrill', word_class: 'AJ' },
    { word: 'shrilly', word_class: 'AV' },
    { word: 'shrilling', word_class: 'N' },
    { word: 'shrilling', word_class: 'AJ' },
    { word: 'shrillness', word_class: 'N' }
  ],
  circuit: [
    { word: 'circuit', word_class: 'N' },
    { word: 'circuit', word_class: 'V' },
    { word: 'circuitous', word_class: 'AJ' }
  ],
  thinking: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  bore: [
    { word: 'bore', word_class: 'N' },
    { word: 'bore', word_class: 'V' },
    { word: 'borer', word_class: 'N' },
    { word: 'bored', word_class: 'AJ' },
    { word: 'boring', word_class: 'N' },
    { word: 'boredom', word_class: 'N' },
    { word: 'boring', word_class: 'AJ' }
  ],
  hypostasis: [
    { word: 'hypostasis', word_class: 'N' },
    { word: 'hypostatise', word_class: 'V' },
    { word: 'hypostatize', word_class: 'V' },
    { word: 'hypostatisation', word_class: 'N' },
    { word: 'hypostatization', word_class: 'N' }
  ],
  shackle: [
    { word: 'shackle', word_class: 'N' },
    { word: 'shackle', word_class: 'V' },
    { word: 'shackled', word_class: 'AJ' }
  ],
  impressiveness: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  supportable: [
    { word: 'support', word_class: 'N' },
    { word: 'support', word_class: 'V' },
    { word: 'supporter', word_class: 'N' },
    { word: 'supported', word_class: 'AJ' },
    { word: 'supporting', word_class: 'N' },
    { word: 'supporting', word_class: 'AJ' },
    { word: 'supportive', word_class: 'AJ' },
    { word: 'supportable', word_class: 'AJ' }
  ],
  concerning: [
    { word: 'concrn', word_class: 'V' },
    { word: 'concern', word_class: 'N' },
    { word: 'concern', word_class: 'V' },
    { word: 'concerned', word_class: 'AJ' },
    { word: 'concerning', word_class: 'AJ' }
  ],
  hibernating: [
    { word: 'hibernal', word_class: 'AJ' },
    { word: 'hibernate', word_class: 'V' },
    { word: 'hibernation', word_class: 'N' },
    { word: 'hibernating', word_class: 'AJ' }
  ],
  teutonize: [
    { word: 'teuton', word_class: 'N' },
    { word: 'teutonise', word_class: 'V' },
    { word: 'teutonic', word_class: 'AJ' },
    { word: 'teutonize', word_class: 'V' },
    { word: 'teutonisation', word_class: 'N' },
    { word: 'teutonization', word_class: 'N' }
  ],
  disembodied: [
    { word: 'disembody', word_class: 'V' },
    { word: 'disembodied', word_class: 'AJ' },
    { word: 'disembodiment', word_class: 'N' }
  ],
  overlooking: [
    { word: 'overlook', word_class: 'N' },
    { word: 'overlook', word_class: 'V' },
    { word: 'overlooked', word_class: 'AJ' },
    { word: 'overlooking', word_class: 'AJ' }
  ],
  monetarize: [
    { word: 'monetary', word_class: 'N' },
    { word: 'monetary', word_class: 'AJ' },
    { word: 'monetarism', word_class: 'N' },
    { word: 'monetarize', word_class: 'V' },
    { word: 'monetarily', word_class: 'AJ' }
  ],
  fryer: [
    { word: 'fry', word_class: 'N' },
    { word: 'fry', word_class: 'V' },
    { word: 'frier', word_class: 'N' },
    { word: 'fryer', word_class: 'N' },
    { word: 'frying', word_class: 'N' }
  ],
  revise: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  breadth: [
    { word: 'broad', word_class: 'N' },
    { word: 'broad', word_class: 'AJ' },
    { word: 'breadth', word_class: 'N' },
    { word: 'broadness', word_class: 'N' }
  ],
  disfiguration: [
    { word: 'disfigure', word_class: 'V' },
    { word: 'disfigured', word_class: 'AJ' },
    { word: 'disfiguration', word_class: 'N' },
    { word: 'disfigurement', word_class: 'N' }
  ],
  disoriented: [
    { word: 'disorient', word_class: 'V' },
    { word: 'disorientate', word_class: 'V' },
    { word: 'disoriented', word_class: 'AJ' },
    { word: 'disorienting', word_class: 'AJ' },
    { word: 'disorientation', word_class: 'N' }
  ],
  puncher: [
    { word: 'punch', word_class: 'N' },
    { word: 'punch', word_class: 'V' },
    { word: 'puncher', word_class: 'N' }
  ],
  decolorization: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  ardently: [
    { word: 'ardency', word_class: 'N' },
    { word: 'ardent', word_class: 'AJ' },
    { word: 'ardently', word_class: 'AV' }
  ],
  digitalisation: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  poser: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  cast: [
    { word: 'cast', word_class: 'N' },
    { word: 'cast', word_class: 'V' },
    { word: 'cast', word_class: 'AJ' },
    { word: 'caste', word_class: 'N' },
    { word: 'caster', word_class: 'N' },
    { word: 'castor', word_class: 'N' },
    { word: 'casting', word_class: 'N' }
  ],
  birthing: [
    { word: 'birth', word_class: 'N' },
    { word: 'birth', word_class: 'V' },
    { word: 'birthe', word_class: 'V' },
    { word: 'birthing', word_class: 'N' },
    { word: 'birthing', word_class: 'AJ' }
  ],
  possessively: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  agitator: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  blaspheme: [
    { word: 'blaspheme', word_class: 'V' },
    { word: 'blasphemy', word_class: 'N' },
    { word: 'blasphemer', word_class: 'N' },
    { word: 'blasphemous', word_class: 'AJ' },
    { word: 'blasphemously', word_class: 'AV' }
  ],
  swabbing: [
    { word: 'swab', word_class: 'N' },
    { word: 'swab', word_class: 'V' },
    { word: 'swabbing', word_class: 'N' }
  ],
  trumpets: [
    { word: 'trumpet', word_class: 'N' },
    { word: 'trumpet', word_class: 'V' },
    { word: 'trumpets', word_class: 'N' },
    { word: 'trumpeter', word_class: 'N' }
  ],
  halogenate: [
    { word: 'halogen', word_class: 'N' },
    { word: 'halogenate', word_class: 'V' },
    { word: 'halogenation', word_class: 'N' }
  ],
  naturism: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  enclosed: [
    { word: 'enclose', word_class: 'V' },
    { word: 'enclosed', word_class: 'AJ' },
    { word: 'enclosing', word_class: 'N' },
    { word: 'enclosure', word_class: 'N' }
  ],
  antimonial: [
    { word: 'antimony', word_class: 'N' },
    { word: 'antimonial', word_class: 'AJ' },
    { word: 'antimonious', word_class: 'AJ' }
  ],
  northward: [
    { word: 'northward', word_class: 'AJ' },
    { word: 'northward', word_class: 'AV' },
    { word: 'northwards', word_class: 'AV' }
  ],
  perfect: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  pathogen: [
    { word: 'pathogen', word_class: 'N' },
    { word: 'pathogenic', word_class: 'AJ' },
    { word: 'pathogenically', word_class: 'AV' }
  ],
  cycloid: [
    { word: 'cycloid', word_class: 'N' },
    { word: 'cycloid', word_class: 'AJ' },
    { word: 'cycloidal', word_class: 'AJ' }
  ],
  signally: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  assorted: [
    { word: 'assort', word_class: 'V' },
    { word: 'assorted', word_class: 'AJ' },
    { word: 'assortment', word_class: 'N' }
  ],
  immunologic: [
    { word: 'immunology', word_class: 'N' },
    { word: 'immunologic', word_class: 'AJ' },
    { word: 'immunological', word_class: 'AJ' }
  ],
  supervention: [
    { word: 'supervene', word_class: 'V' },
    { word: 'supervention', word_class: 'N' },
    { word: 'supervenience', word_class: 'N' }
  ],
  reclamation: [
    { word: 'reclaim', word_class: 'V' },
    { word: 'reclaimed', word_class: 'AJ' },
    { word: 'reclamation', word_class: 'N' },
    { word: 'reclaimable', word_class: 'AJ' }
  ],
  impassiveness: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  loan: [{ word: 'loan', word_class: 'N' }, { word: 'loan', word_class: 'V' }, { word: 'loaner', word_class: 'N' }],
  conically: [
    { word: 'conic', word_class: 'N' },
    { word: 'conic', word_class: 'AJ' },
    { word: 'conical', word_class: 'AJ' },
    { word: 'conically', word_class: 'AV' }
  ],
  crouching: [
    { word: 'crouch', word_class: 'N' },
    { word: 'crouch', word_class: 'V' },
    { word: 'crouched', word_class: 'AJ' },
    { word: 'crouching', word_class: 'AJ' }
  ],
  tethered: [
    { word: 'tether', word_class: 'N' },
    { word: 'tether', word_class: 'V' },
    { word: 'tethered', word_class: 'AJ' }
  ],
  commensurable: [
    { word: 'commensurate', word_class: 'AJ' },
    { word: 'commensurable', word_class: 'AJ' },
    { word: 'commensurateness', word_class: 'N' }
  ],
  detergency: [
    { word: 'detergent', word_class: 'N' },
    { word: 'detergence', word_class: 'N' },
    { word: 'detergency', word_class: 'N' },
    { word: 'detergent', word_class: 'AJ' }
  ],
  jog: [{ word: 'jog', word_class: 'N' }, { word: 'jog', word_class: 'V' }, { word: 'jogging', word_class: 'N' }],
  nitrate: [
    { word: 'nitrate', word_class: 'N' },
    { word: 'nitrate', word_class: 'V' },
    { word: 'nitration', word_class: 'N' }
  ],
  impersonalise: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  spiritualisation: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  arborous: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  factuality: [
    { word: 'factual', word_class: 'AJ' },
    { word: 'factuality', word_class: 'N' },
    { word: 'factually', word_class: 'AV' },
    { word: 'factualness', word_class: 'N' }
  ],
  syllabification: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  rocket: [
    { word: 'rocket', word_class: 'N' },
    { word: 'rocket', word_class: 'V' },
    { word: 'rocketry', word_class: 'N' }
  ],
  figuratively: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  marches: [
    { word: 'march', word_class: 'N' },
    { word: 'march', word_class: 'V' },
    { word: 'marche', word_class: 'N' },
    { word: 'marcher', word_class: 'N' },
    { word: 'marches', word_class: 'N' },
    { word: 'marching', word_class: 'N' },
    { word: 'marching', word_class: 'AJ' }
  ],
  actualization: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  mass: [
    { word: 'mass', word_class: 'N' },
    { word: 'mass', word_class: 'V' },
    { word: 'mass', word_class: 'AJ' },
    { word: 'masse', word_class: 'N' },
    { word: 'masses', word_class: 'N' },
    { word: 'massed', word_class: 'AJ' }
  ],
  statistical: [
    { word: 'statistic', word_class: 'N' },
    { word: 'statistic', word_class: 'AJ' },
    { word: 'statistics', word_class: 'N' },
    { word: 'statistical', word_class: 'AJ' },
    { word: 'statistically', word_class: 'AV' }
  ],
  constitutionalize: [
    { word: 'constitutionalism', word_class: 'N' },
    { word: 'constitutionalize', word_class: 'V' },
    { word: 'constitutionally', word_class: 'AV' }
  ],
  adducting: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  scheduled: [
    { word: 'schedule', word_class: 'N' },
    { word: 'schedule', word_class: 'V' },
    { word: 'scheduled', word_class: 'AJ' },
    { word: 'scheduling', word_class: 'N' }
  ],
  canonic: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  tartarization: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  tack: [{ word: 'tack', word_class: 'N' }, { word: 'tack', word_class: 'V' }, { word: 'tacking', word_class: 'N' }],
  mutualise: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  thoughtful: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  rutted: [{ word: 'rut', word_class: 'N' }, { word: 'rut', word_class: 'V' }, { word: 'rutted', word_class: 'AJ' }],
  frames: [
    { word: 'frame', word_class: 'N' },
    { word: 'frame', word_class: 'V' },
    { word: 'framer', word_class: 'N' },
    { word: 'frames', word_class: 'V' },
    { word: 'framed', word_class: 'AJ' },
    { word: 'framing', word_class: 'N' }
  ],
  gynandromorph: [
    { word: 'gynandromorph', word_class: 'N' },
    { word: 'gynandromorphic', word_class: 'AJ' },
    { word: 'gynandromorphous', word_class: 'AJ' }
  ],
  nasalisation: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  tolerably: [
    { word: 'tolerate', word_class: 'V' },
    { word: 'tolerance', word_class: 'N' },
    { word: 'tolerant', word_class: 'AJ' },
    { word: 'tolerable', word_class: 'AJ' },
    { word: 'tolerably', word_class: 'AV' },
    { word: 'toleration', word_class: 'N' },
    { word: 'tolerability', word_class: 'N' }
  ],
  exposure: [
    { word: 'expose', word_class: 'N' },
    { word: 'expose', word_class: 'V' },
    { word: 'exposit', word_class: 'V' },
    { word: 'exposed', word_class: 'AJ' },
    { word: 'exposure', word_class: 'N' },
    { word: 'exposition', word_class: 'N' },
    { word: 'expositive', word_class: 'AJ' }
  ],
  dispassionateness: [
    { word: 'dispassionate', word_class: 'AJ' },
    { word: 'dispassionately', word_class: 'AV' },
    { word: 'dispassionateness', word_class: 'N' }
  ],
  comprisal: [
    { word: 'comprise', word_class: 'V' },
    { word: 'comprisal', word_class: 'N' },
    { word: 'comprising', word_class: 'N' }
  ],
  coherently: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  sterileness: [
    { word: 'sterile', word_class: 'AJ' },
    { word: 'sterilise', word_class: 'V' },
    { word: 'sterility', word_class: 'N' },
    { word: 'sterilize', word_class: 'V' },
    { word: 'sterileness', word_class: 'N' },
    { word: 'sterilized', word_class: 'AJ' },
    { word: 'sterilisation', word_class: 'N' },
    { word: 'sterilization', word_class: 'N' }
  ],
  tip: [
    { word: 'tip', word_class: 'N' },
    { word: 'tip', word_class: 'V' },
    { word: 'tippy', word_class: 'AJ' },
    { word: 'tipped', word_class: 'AJ' }
  ],
  affined: [
    { word: 'affine', word_class: 'N' },
    { word: 'affine', word_class: 'AJ' },
    { word: 'affinal', word_class: 'AJ' },
    { word: 'affined', word_class: 'AJ' },
    { word: 'affinity', word_class: 'N' }
  ],
  interchangeable: [
    { word: 'interchange', word_class: 'N' },
    { word: 'interchange', word_class: 'V' },
    { word: 'interchangeable', word_class: 'AJ' },
    { word: 'interchangeably', word_class: 'AV' },
    { word: 'interchangeability', word_class: 'N' },
    { word: 'interchangeableness', word_class: 'N' }
  ],
  dismiss: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  auspicious: [
    { word: 'auspicious', word_class: 'AJ' },
    { word: 'auspiciously', word_class: 'AV' },
    { word: 'auspiciousness', word_class: 'N' }
  ],
  pluralization: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  dally: [
    { word: 'dally', word_class: 'V' },
    { word: 'dallier', word_class: 'N' },
    { word: 'dalliance', word_class: 'N' }
  ],
  distillment: [
    { word: 'distil', word_class: 'V' },
    { word: 'distill', word_class: 'N' },
    { word: 'distill', word_class: 'V' },
    { word: 'distill', word_class: 'AJ' },
    { word: 'distill', word_class: 'AV' },
    { word: 'distiller', word_class: 'N' },
    { word: 'distillate', word_class: 'N' },
    { word: 'distillment', word_class: 'N' },
    { word: 'distillation', word_class: 'N' }
  ],
  sensorial: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  caroling: [
    { word: 'carol', word_class: 'N' },
    { word: 'carol', word_class: 'V' },
    { word: 'caroler', word_class: 'N' },
    { word: 'caroling', word_class: 'N' },
    { word: 'caroller', word_class: 'N' }
  ],
  border: [
    { word: 'border', word_class: 'N' },
    { word: 'border', word_class: 'V' },
    { word: 'borderer', word_class: 'N' },
    { word: 'bordered', word_class: 'AJ' },
    { word: 'bordering', word_class: 'AJ' }
  ],
  demagog: [
    { word: 'demagog', word_class: 'N' },
    { word: 'demagogic', word_class: 'AJ' },
    { word: 'demagogical', word_class: 'AJ' }
  ],
  outlaw: [
    { word: 'outlaw', word_class: 'N' },
    { word: 'outlaw', word_class: 'V' },
    { word: 'outlaw', word_class: 'AJ' },
    { word: 'outlawed', word_class: 'AJ' }
  ],
  planking: [
    { word: 'plank', word_class: 'N' },
    { word: 'plank', word_class: 'V' },
    { word: 'planking', word_class: 'N' }
  ],
  authority: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  gutturalization: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  raftman: [
    { word: 'raft', word_class: 'N' },
    { word: 'raft', word_class: 'V' },
    { word: 'rafts', word_class: 'N' },
    { word: 'rafter', word_class: 'N' },
    { word: 'rafter', word_class: 'V' },
    { word: 'raftman', word_class: 'N' },
    { word: 'raftered', word_class: 'AJ' }
  ],
  accommodate: [
    { word: 'accommodate', word_class: 'V' },
    { word: 'accommodation', word_class: 'N' },
    { word: 'accommodating', word_class: 'AJ' },
    { word: 'accommodative', word_class: 'AJ' },
    { word: 'accommodational', word_class: 'AJ' }
  ],
  pyramid: [
    { word: 'pyramid', word_class: 'N' },
    { word: 'pyramid', word_class: 'V' },
    { word: 'pyramidal', word_class: 'AJ' }
  ],
  harmonically: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  convict: [
    { word: 'convict', word_class: 'N' },
    { word: 'convict', word_class: 'V' },
    { word: 'convince', word_class: 'V' },
    { word: 'conviction', word_class: 'N' },
    { word: 'convinced', word_class: 'AJ' },
    { word: 'convicted', word_class: 'AJ' },
    { word: 'convincing', word_class: 'N' },
    { word: 'convincing', word_class: 'AJ' },
    { word: 'convincement', word_class: 'N' },
    { word: 'convincible', word_class: 'AJ' }
  ],
  veneering: [
    { word: 'veneer', word_class: 'N' },
    { word: 'veneer', word_class: 'V' },
    { word: 'veneering', word_class: 'N' }
  ],
  aquatics: [
    { word: 'aquatic', word_class: 'N' },
    { word: 'aquatic', word_class: 'AJ' },
    { word: 'aquatics', word_class: 'N' }
  ],
  phonologic: [
    { word: 'phonology', word_class: 'N' },
    { word: 'phonologic', word_class: 'AJ' },
    { word: 'phonological', word_class: 'AJ' }
  ],
  extrovertive: [
    { word: 'extrovert', word_class: 'N' },
    { word: 'extrovert', word_class: 'AJ' },
    { word: 'extroversion', word_class: 'N' },
    { word: 'extroverted', word_class: 'AJ' },
    { word: 'extroversive', word_class: 'AJ' },
    { word: 'extrovertive', word_class: 'AJ' }
  ],
  urgency: [
    { word: 'urge', word_class: 'N' },
    { word: 'urge', word_class: 'V' },
    { word: 'urging', word_class: 'N' },
    { word: 'urgency', word_class: 'N' }
  ],
  excited: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  demobilise: [
    { word: 'demobilise', word_class: 'V' },
    { word: 'demobilize', word_class: 'V' },
    { word: 'demobilisation', word_class: 'N' },
    { word: 'demobilization', word_class: 'N' }
  ],
  obliterated: [
    { word: 'obliterate', word_class: 'V' },
    { word: 'obliterate', word_class: 'AJ' },
    { word: 'obliterable', word_class: 'AJ' },
    { word: 'obliterated', word_class: 'AJ' },
    { word: 'obliteration', word_class: 'N' },
    { word: 'obliterating', word_class: 'AJ' }
  ],
  discolour: [
    { word: 'discolour', word_class: 'V' },
    { word: 'discoloured', word_class: 'AJ' },
    { word: 'discolouration', word_class: 'N' }
  ],
  debarment: [
    { word: 'debar', word_class: 'V' },
    { word: 'debarment', word_class: 'N' },
    { word: 'debarring', word_class: 'N' }
  ],
  contaminate: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  pricing: [
    { word: 'price', word_class: 'N' },
    { word: 'price', word_class: 'V' },
    { word: 'pricy', word_class: 'AJ' },
    { word: 'priced', word_class: 'AJ' },
    { word: 'pricing', word_class: 'N' }
  ],
  patent: [
    { word: 'patent', word_class: 'N' },
    { word: 'patent', word_class: 'V' },
    { word: 'patent', word_class: 'AJ' },
    { word: 'patentee', word_class: 'N' },
    { word: 'patented', word_class: 'AJ' },
    { word: 'patently', word_class: 'AV' }
  ],
  sewerage: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  toaster: [
    { word: 'toast', word_class: 'N' },
    { word: 'toast', word_class: 'V' },
    { word: 'toaster', word_class: 'N' },
    { word: 'toasted', word_class: 'AJ' },
    { word: 'toasting', word_class: 'N' }
  ],
  computable: [
    { word: 'compute', word_class: 'V' },
    { word: 'computer', word_class: 'N' },
    { word: 'computable', word_class: 'AJ' },
    { word: 'computation', word_class: 'N' },
    { word: 'computational', word_class: 'AJ' }
  ],
  screaming: [
    { word: 'scream', word_class: 'N' },
    { word: 'scream', word_class: 'V' },
    { word: 'screamer', word_class: 'N' },
    { word: 'screaming', word_class: 'N' },
    { word: 'screaming', word_class: 'AJ' }
  ],
  judgship: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  style: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  scant: [
    { word: 'scant', word_class: 'V' },
    { word: 'scant', word_class: 'AJ' },
    { word: 'scantness', word_class: 'N' }
  ],
  parse: [{ word: 'parse', word_class: 'N' }, { word: 'parse', word_class: 'V' }, { word: 'parsee', word_class: 'N' }],
  vacationist: [
    { word: 'vacate', word_class: 'V' },
    { word: 'vacation', word_class: 'N' },
    { word: 'vacation', word_class: 'V' },
    { word: 'vacationer', word_class: 'N' },
    { word: 'vacationing', word_class: 'N' },
    { word: 'vacationist', word_class: 'N' }
  ],
  zestful: [
    { word: 'zest', word_class: 'N' },
    { word: 'zest', word_class: 'V' },
    { word: 'zestful', word_class: 'AJ' },
    { word: 'zestfulness', word_class: 'N' }
  ],
  overawed: [
    { word: 'overawe', word_class: 'N' },
    { word: 'overawe', word_class: 'V' },
    { word: 'overawed', word_class: 'AJ' }
  ],
  suburban: [
    { word: 'suburban', word_class: 'AJ' },
    { word: 'suburbanize', word_class: 'V' },
    { word: 'suburbanized', word_class: 'AJ' }
  ],
  transcribed: [
    { word: 'transcribe', word_class: 'V' },
    { word: 'transcript', word_class: 'N' },
    { word: 'transcribed', word_class: 'AJ' },
    { word: 'transcription', word_class: 'N' }
  ],
  obstruction: [
    { word: 'obstruct', word_class: 'V' },
    { word: 'obstructed', word_class: 'AJ' },
    { word: 'obstruction', word_class: 'N' },
    { word: 'obstructive', word_class: 'AJ' },
    { word: 'obstructively', word_class: 'AV' }
  ],
  lodgement: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  chilliness: [
    { word: 'chilli', word_class: 'N' },
    { word: 'chilly', word_class: 'N' },
    { word: 'chilly', word_class: 'AJ' },
    { word: 'chilliness', word_class: 'N' }
  ],
  unthoughtful: [
    { word: 'unthought', word_class: 'AJ' },
    { word: 'unthoughtful', word_class: 'AJ' },
    { word: 'unthoughtfulness', word_class: 'N' }
  ],
  specifically: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  interconnected: [
    { word: 'interconnect', word_class: 'V' },
    { word: 'interconnected', word_class: 'AJ' },
    { word: 'interconnection', word_class: 'N' }
  ],
  serving: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  erotically: [
    { word: 'erotic', word_class: 'AJ' },
    { word: 'erotism', word_class: 'N' },
    { word: 'erotically', word_class: 'AV' }
  ],
  forged: [
    { word: 'forge', word_class: 'N' },
    { word: 'forge', word_class: 'V' },
    { word: 'forger', word_class: 'N' },
    { word: 'forged', word_class: 'AJ' },
    { word: 'forgery', word_class: 'N' },
    { word: 'forging', word_class: 'N' },
    { word: 'forgery', word_class: 'AJ' }
  ],
  misjudgment: [
    { word: 'misjudge', word_class: 'V' },
    { word: 'misjudgment', word_class: 'N' },
    { word: 'misjudgement', word_class: 'N' }
  ],
  desalinated: [
    { word: 'desalinate', word_class: 'V' },
    { word: 'desalinize', word_class: 'V' },
    { word: 'desalinated', word_class: 'AJ' },
    { word: 'desalination', word_class: 'N' },
    { word: 'desalinization', word_class: 'N' }
  ],
  skiing: [
    { word: 'ski', word_class: 'N' },
    { word: 'ski', word_class: 'V' },
    { word: 'skis', word_class: 'N' },
    { word: 'skier', word_class: 'N' },
    { word: 'skiing', word_class: 'N' }
  ],
  inning: [
    { word: 'in', word_class: 'N' },
    { word: 'in', word_class: 'AJ' },
    { word: 'in', word_class: 'AV' },
    { word: 'inning', word_class: 'N' },
    { word: 'innings', word_class: 'N' }
  ],
  predate: [
    { word: 'prey', word_class: 'N' },
    { word: 'prey', word_class: 'V' },
    { word: 'predate', word_class: 'V' },
    { word: 'preying', word_class: 'N' },
    { word: 'predator', word_class: 'N' },
    { word: 'predation', word_class: 'N' }
  ],
  gathered: [
    { word: 'gather', word_class: 'N' },
    { word: 'gather', word_class: 'V' },
    { word: 'gatherer', word_class: 'N' },
    { word: 'gathering', word_class: 'N' },
    { word: 'gathered', word_class: 'AJ' },
    { word: 'gathering', word_class: 'AJ' }
  ],
  inactiveness: [
    { word: 'inaction', word_class: 'N' },
    { word: 'inactive', word_class: 'AJ' },
    { word: 'inactivity', word_class: 'N' },
    { word: 'inactiveness', word_class: 'N' }
  ],
  precociously: [
    { word: 'precocial', word_class: 'AJ' },
    { word: 'precocious', word_class: 'AJ' },
    { word: 'precociously', word_class: 'AV' },
    { word: 'precociousness', word_class: 'N' }
  ],
  venomed: [
    { word: 'venom', word_class: 'N' },
    { word: 'venomed', word_class: 'AJ' },
    { word: 'venomous', word_class: 'AJ' },
    { word: 'venomously', word_class: 'AV' }
  ],
  emotionalise: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  institutional: [
    { word: 'institute', word_class: 'N' },
    { word: 'institute', word_class: 'V' },
    { word: 'institution', word_class: 'N' },
    { word: 'institutional', word_class: 'AJ' }
  ],
  degrade: [
    { word: 'degrade', word_class: 'N' },
    { word: 'degrade', word_class: 'V' },
    { word: 'degraded', word_class: 'AJ' },
    { word: 'degrading', word_class: 'AJ' },
    { word: 'degradation', word_class: 'N' }
  ],
  flood: [
    { word: 'flood', word_class: 'N' },
    { word: 'flood', word_class: 'V' },
    { word: 'flood', word_class: 'AJ' },
    { word: 'flooded', word_class: 'AJ' },
    { word: 'flooding', word_class: 'N' },
    { word: 'flooding', word_class: 'AJ' }
  ],
  distinguished: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  halogenation: [
    { word: 'halogen', word_class: 'N' },
    { word: 'halogenate', word_class: 'V' },
    { word: 'halogenation', word_class: 'N' }
  ],
  coordinately: [
    { word: 'coordinate', word_class: 'N' },
    { word: 'coordinate', word_class: 'V' },
    { word: 'co-ordinate', word_class: 'V' },
    { word: 'coordinate', word_class: 'AJ' },
    { word: 'coordinator', word_class: 'N' },
    { word: 'coordination', word_class: 'N' },
    { word: 'coordinated', word_class: 'AJ' },
    { word: 'coordinately', word_class: 'AV' },
    { word: 'coordinating', word_class: 'AJ' },
    { word: 'coordinative', word_class: 'AJ' }
  ],
  animal: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  pulled: [
    { word: 'pull', word_class: 'N' },
    { word: 'pull', word_class: 'V' },
    { word: 'pullman', word_class: 'N' },
    { word: 'pulled', word_class: 'AJ' },
    { word: 'pulling', word_class: 'N' }
  ],
  referable: [
    { word: 'refer', word_class: 'V' },
    { word: 'referent', word_class: 'N' },
    { word: 'referral', word_class: 'N' },
    { word: 'reference', word_class: 'N' },
    { word: 'reference', word_class: 'V' },
    { word: 'referable', word_class: 'AJ' }
  ],
  sensibility: [
    { word: 'sensible', word_class: 'AJ' },
    { word: 'sensibly', word_class: 'AV' },
    { word: 'sensibility', word_class: 'N' },
    { word: 'sensibleness', word_class: 'N' }
  ],
  copyist: [
    { word: 'copy', word_class: 'N' },
    { word: 'copy', word_class: 'V' },
    { word: 'copier', word_class: 'N' },
    { word: 'copied', word_class: 'AJ' },
    { word: 'copying', word_class: 'N' },
    { word: 'copyist', word_class: 'N' }
  ],
  abatic: [
    { word: 'abate', word_class: 'V' },
    { word: 'abatic', word_class: 'AJ' },
    { word: 'abating', word_class: 'AJ' },
    { word: 'abatement', word_class: 'N' }
  ],
  predicatively: [
    { word: 'predicate', word_class: 'N' },
    { word: 'predicate', word_class: 'V' },
    { word: 'predicate', word_class: 'AJ' },
    { word: 'predicator', word_class: 'N' },
    { word: 'predication', word_class: 'N' },
    { word: 'predicative', word_class: 'AJ' },
    { word: 'predicatively', word_class: 'AV' }
  ],
  bookbindery: [
    { word: 'bookbind', word_class: 'V' },
    { word: 'bookbinder', word_class: 'N' },
    { word: 'bookbindery', word_class: 'N' },
    { word: 'bookbinding', word_class: 'N' },
    { word: 'bookbindery', word_class: 'AJ' }
  ],
  mechanist: [
    { word: 'mechanist', word_class: 'N' },
    { word: 'mechanistic', word_class: 'AJ' },
    { word: 'mechanistically', word_class: 'AV' }
  ],
  validate: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  desexualization: [
    { word: 'desexualise', word_class: 'V' },
    { word: 'desexualize', word_class: 'V' },
    { word: 'desexualisation', word_class: 'N' },
    { word: 'desexualization', word_class: 'N' }
  ],
  fermentation: [
    { word: 'ferment', word_class: 'N' },
    { word: 'ferment', word_class: 'V' },
    { word: 'fermented', word_class: 'AJ' },
    { word: 'fermenting', word_class: 'N' },
    { word: 'fermentation', word_class: 'N' }
  ],
  wrench: [
    { word: 'wrench', word_class: 'N' },
    { word: 'wrench', word_class: 'V' },
    { word: 'wrenching', word_class: 'AJ' }
  ],
  topic: [
    { word: 'topic', word_class: 'N' },
    { word: 'topical', word_class: 'AJ' },
    { word: 'topically', word_class: 'AV' }
  ],
  advocate: [
    { word: 'advocacy', word_class: 'N' },
    { word: 'advocate', word_class: 'N' },
    { word: 'advocate', word_class: 'V' },
    { word: 'advocation', word_class: 'N' }
  ],
  inspiriting: [
    { word: 'inspirit', word_class: 'V' },
    { word: 'inspiriting', word_class: 'AJ' },
    { word: 'inspiritment', word_class: 'N' }
  ],
  adducing: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  fumigation: [
    { word: 'fumigant', word_class: 'N' },
    { word: 'fumigate', word_class: 'V' },
    { word: 'fumigation', word_class: 'N' }
  ],
  slating: [
    { word: 'slate', word_class: 'N' },
    { word: 'slate', word_class: 'V' },
    { word: 'slater', word_class: 'N' },
    { word: 'slating', word_class: 'N' }
  ],
  warping: [
    { word: 'warp', word_class: 'N' },
    { word: 'warp', word_class: 'V' },
    { word: 'warped', word_class: 'AJ' },
    { word: 'warping', word_class: 'N' }
  ],
  puppeteer: [
    { word: 'puppet', word_class: 'N' },
    { word: 'puppetry', word_class: 'N' },
    { word: 'puppeteer', word_class: 'N' }
  ],
  blindfold: [
    { word: 'blindfold', word_class: 'N' },
    { word: 'blindfold', word_class: 'V' },
    { word: 'blindfold', word_class: 'AJ' },
    { word: 'blindfolded', word_class: 'AJ' }
  ],
  transgress: [
    { word: 'transgress', word_class: 'V' },
    { word: 'transgressor', word_class: 'N' },
    { word: 'transgression', word_class: 'N' }
  ],
  scrambled: [
    { word: 'scramble', word_class: 'N' },
    { word: 'scramble', word_class: 'V' },
    { word: 'scrambler', word_class: 'N' },
    { word: 'scrambled', word_class: 'AJ' }
  ],
  erase: [
    { word: 'erase', word_class: 'V' },
    { word: 'eraser', word_class: 'N' },
    { word: 'erasure', word_class: 'N' },
    { word: 'erasable', word_class: 'AJ' }
  ],
  innovate: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  sovietisation: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  urinate: [
    { word: 'urine', word_class: 'N' },
    { word: 'urinal', word_class: 'N' },
    { word: 'urinal', word_class: 'AJ' },
    { word: 'urinate', word_class: 'V' },
    { word: 'urinary', word_class: 'AJ' },
    { word: 'urination', word_class: 'N' }
  ],
  abridge: [
    { word: 'abridge', word_class: 'V' },
    { word: 'abridger', word_class: 'N' },
    { word: 'abridged', word_class: 'AJ' },
    { word: 'abridgment', word_class: 'N' },
    { word: 'abridgement', word_class: 'N' }
  ],
  washer: [
    { word: 'wash', word_class: 'N' },
    { word: 'wash', word_class: 'V' },
    { word: 'washer', word_class: 'N' },
    { word: 'washed', word_class: 'AJ' },
    { word: 'washing', word_class: 'N' }
  ],
  concavity: [
    { word: 'concave', word_class: 'AJ' },
    { word: 'concavity', word_class: 'N' },
    { word: 'concavely', word_class: 'AV' },
    { word: 'concaveness', word_class: 'N' }
  ],
  revision: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  animist: [
    { word: 'animist', word_class: 'N' },
    { word: 'animist', word_class: 'AJ' },
    { word: 'animistic', word_class: 'AJ' }
  ],
  decoration: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  electromagnetics: [
    { word: 'electromagnet', word_class: 'N' },
    { word: 'electromagnetic', word_class: 'AJ' },
    { word: 'electromagnetics', word_class: 'N' },
    { word: 'electromagnetism', word_class: 'N' }
  ],
  recuse: [
    { word: 'recuse', word_class: 'V' },
    { word: 'recusal', word_class: 'N' },
    { word: 'recusant', word_class: 'N' },
    { word: 'recusancy', word_class: 'N' },
    { word: 'recusant', word_class: 'AJ' },
    { word: 'recusation', word_class: 'N' }
  ],
  snug: [{ word: 'snug', word_class: 'N' }, { word: 'snug', word_class: 'AJ' }, { word: 'snugness', word_class: 'N' }],
  hebdomad: [
    { word: 'hebdomad', word_class: 'N' },
    { word: 'hebdomadal', word_class: 'AJ' },
    { word: 'hebdomadally', word_class: 'AV' }
  ],
  garrotte: [
    { word: 'garrotte', word_class: 'N' },
    { word: 'garrotte', word_class: 'V' },
    { word: 'garrotter', word_class: 'N' }
  ],
  justifier: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  'double-crosser': [
    { word: 'double-cross', word_class: 'N' },
    { word: 'double-cross', word_class: 'V' },
    { word: 'double-crosser', word_class: 'N' },
    { word: 'double-crossing', word_class: 'N' }
  ],
  intended: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  humanely: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  neoclassicist: [
    { word: 'neoclassicist', word_class: 'N' },
    { word: 'neoclassicist', word_class: 'AJ' },
    { word: 'neoclassicistic', word_class: 'AJ' }
  ],
  pole: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  jeer: [
    { word: 'jeer', word_class: 'N' },
    { word: 'jeer', word_class: 'V' },
    { word: 'jeering', word_class: 'N' },
    { word: 'jeering', word_class: 'AJ' }
  ],
  unassertive: [
    { word: 'unassertive', word_class: 'AJ' },
    { word: 'unassertively', word_class: 'AV' },
    { word: 'unassertiveness', word_class: 'N' }
  ],
  sensing: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  soil: [
    { word: 'soil', word_class: 'N' },
    { word: 'soil', word_class: 'V' },
    { word: 'soilage', word_class: 'N' },
    { word: 'soiled', word_class: 'AJ' },
    { word: 'soilure', word_class: 'N' },
    { word: 'soiling', word_class: 'N' }
  ],
  'right-handed': [
    { word: 'right-hand', word_class: 'AJ' },
    { word: 'right-hander', word_class: 'N' },
    { word: 'right-handed', word_class: 'AJ' }
  ],
  nonconforming: [
    { word: 'nonconformity', word_class: 'N' },
    { word: 'nonconformance', word_class: 'N' },
    { word: 'nonconforming', word_class: 'AJ' }
  ],
  exponential: [
    { word: 'exponent', word_class: 'N' },
    { word: 'exponential', word_class: 'N' },
    { word: 'exponential', word_class: 'AJ' },
    { word: 'exponentiate', word_class: 'V' },
    { word: 'exponentially', word_class: 'AV' },
    { word: 'exponentiation', word_class: 'N' }
  ],
  gruesome: [
    { word: 'gruesome', word_class: 'AJ' },
    { word: 'gruesomely', word_class: 'AV' },
    { word: 'gruesomeness', word_class: 'N' }
  ],
  symbolically: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  grotesqueness: [
    { word: 'grotesque', word_class: 'N' },
    { word: 'grotesque', word_class: 'AJ' },
    { word: 'grotesquely', word_class: 'AV' },
    { word: 'grotesqueness', word_class: 'N' }
  ],
  obstruct: [
    { word: 'obstruct', word_class: 'V' },
    { word: 'obstructed', word_class: 'AJ' },
    { word: 'obstruction', word_class: 'N' },
    { word: 'obstructive', word_class: 'AJ' },
    { word: 'obstructively', word_class: 'AV' }
  ],
  latitudinal: [
    { word: 'latitude', word_class: 'N' },
    { word: 'latitudinal', word_class: 'AJ' },
    { word: 'latitudinary', word_class: 'AJ' }
  ],
  liberalize: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  'cross-examine': [
    { word: 'cross-examine', word_class: 'V' },
    { word: 'cross-examiner', word_class: 'N' },
    { word: 'cross-examination', word_class: 'N' }
  ],
  least: [{ word: 'least', word_class: 'N' }, { word: 'least', word_class: 'AJ' }, { word: 'least', word_class: 'AV' }],
  scathing: [
    { word: 'scathe', word_class: 'N' },
    { word: 'scathe', word_class: 'V' },
    { word: 'scathing', word_class: 'AJ' }
  ],
  rhomboid: [
    { word: 'rhomboid', word_class: 'N' },
    { word: 'rhomboid', word_class: 'AJ' },
    { word: 'rhomboidal', word_class: 'AJ' }
  ],
  digest: [
    { word: 'digest', word_class: 'N' },
    { word: 'digest', word_class: 'V' },
    { word: 'digester', word_class: 'N' },
    { word: 'digested', word_class: 'AJ' },
    { word: 'digestion', word_class: 'N' },
    { word: 'digestive', word_class: 'AJ' },
    { word: 'digestible', word_class: 'AJ' },
    { word: 'digestibility', word_class: 'N' },
    { word: 'digestibleness', word_class: 'N' }
  ],
  husky: [
    { word: 'husky', word_class: 'N' },
    { word: 'husky', word_class: 'AJ' },
    { word: 'huskiness', word_class: 'N' }
  ],
  taxing: [
    { word: 'tax', word_class: 'N' },
    { word: 'tax', word_class: 'V' },
    { word: 'taxman', word_class: 'N' },
    { word: 'taxing', word_class: 'AJ' },
    { word: 'taxation', word_class: 'N' }
  ],
  cock: [
    { word: 'cock', word_class: 'N' },
    { word: 'cock', word_class: 'V' },
    { word: 'cocker', word_class: 'N' },
    { word: 'cocker', word_class: 'V' }
  ],
  motorcycling: [
    { word: 'motorcycle', word_class: 'N' },
    { word: 'motorcycle', word_class: 'V' },
    { word: 'motorcycling', word_class: 'N' }
  ],
  bugged: [{ word: 'bug', word_class: 'N' }, { word: 'bug', word_class: 'V' }, { word: 'bugged', word_class: 'AJ' }],
  smiling: [
    { word: 'smile', word_class: 'N' },
    { word: 'smile', word_class: 'V' },
    { word: 'smiler', word_class: 'N' },
    { word: 'smiling', word_class: 'N' },
    { word: 'smiling', word_class: 'AJ' }
  ],
  gray: [
    { word: 'gray', word_class: 'N' },
    { word: 'gray', word_class: 'V' },
    { word: 'gray', word_class: 'AJ' },
    { word: 'grayed', word_class: 'AJ' },
    { word: 'grayness', word_class: 'N' }
  ],
  invitational: [
    { word: 'invite', word_class: 'N' },
    { word: 'invite', word_class: 'V' },
    { word: 'invitee', word_class: 'N' },
    { word: 'invited', word_class: 'AJ' },
    { word: 'inviting', word_class: 'AJ' },
    { word: 'invitation', word_class: 'N' },
    { word: 'invitation', word_class: 'AJ' },
    { word: 'invitational', word_class: 'AJ' }
  ],
  tempter: [
    { word: 'tempt', word_class: 'V' },
    { word: 'tempter', word_class: 'N' },
    { word: 'tempting', word_class: 'AJ' },
    { word: 'temptress', word_class: 'N' },
    { word: 'temptation', word_class: 'N' }
  ],
  desecration: [
    { word: 'desecrate', word_class: 'V' },
    { word: 'desecrated', word_class: 'AJ' },
    { word: 'desecration', word_class: 'N' }
  ],
  curet: [
    { word: 'curet', word_class: 'N' },
    { word: 'curet', word_class: 'V' },
    { word: 'curette', word_class: 'N' },
    { word: 'curettement', word_class: 'N' }
  ],
  modernism: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  abetter: [
    { word: 'abet', word_class: 'V' },
    { word: 'abettal', word_class: 'N' },
    { word: 'abetter', word_class: 'N' },
    { word: 'abetment', word_class: 'N' }
  ],
  detected: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  acquiescent: [
    { word: 'acquiesce', word_class: 'V' },
    { word: 'acquiescence', word_class: 'N' },
    { word: 'acquiescent', word_class: 'AJ' }
  ],
  trencher: [
    { word: 'trench', word_class: 'N' },
    { word: 'trench', word_class: 'V' },
    { word: 'trencher', word_class: 'N' }
  ],
  bumper: [
    { word: 'bump', word_class: 'N' },
    { word: 'bump', word_class: 'V' },
    { word: 'bumper', word_class: 'N' },
    { word: 'bumper', word_class: 'AJ' }
  ],
  dicotyledones: [
    { word: 'dicotyledon', word_class: 'N' },
    { word: 'dicotyledones', word_class: 'N' },
    { word: 'dicotyledonous', word_class: 'AJ' }
  ],
  dizzy: [
    { word: 'dizzy', word_class: 'V' },
    { word: 'dizzy', word_class: 'AJ' },
    { word: 'dizziness', word_class: 'N' }
  ],
  dehypnotisation: [
    { word: 'dehypnotise', word_class: 'V' },
    { word: 'dehypnotize', word_class: 'V' },
    { word: 'dehypnotisation', word_class: 'N' },
    { word: 'dehypnotization', word_class: 'N' }
  ],
  anime: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  provoked: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  emphasizing: [
    { word: 'emphasis', word_class: 'N' },
    { word: 'emphasize', word_class: 'V' },
    { word: 'emphasized', word_class: 'AJ' },
    { word: 'emphasizing', word_class: 'N' }
  ],
  garrotter: [
    { word: 'garrotte', word_class: 'N' },
    { word: 'garrotte', word_class: 'V' },
    { word: 'garrotter', word_class: 'N' }
  ],
  absorbate: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  notice: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  hyposensitisation: [
    { word: 'hyposensitise', word_class: 'V' },
    { word: 'hyposensitize', word_class: 'V' },
    { word: 'hyposensitisation', word_class: 'N' },
    { word: 'hyposensitization', word_class: 'N' }
  ],
  conspicuously: [
    { word: 'conspicuous', word_class: 'AJ' },
    { word: 'conspicuously', word_class: 'AV' },
    { word: 'conspicuousness', word_class: 'N' }
  ],
  laborious: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  blues: [
    { word: 'blue', word_class: 'N' },
    { word: 'blue', word_class: 'V' },
    { word: 'blue', word_class: 'AJ' },
    { word: 'blues', word_class: 'N' },
    { word: 'blueing', word_class: 'N' },
    { word: 'blueness', word_class: 'N' }
  ],
  preserving: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  delectability: [
    { word: 'delectate', word_class: 'AJ' },
    { word: 'delectable', word_class: 'AJ' },
    { word: 'delectation', word_class: 'N' },
    { word: 'delectability', word_class: 'N' }
  ],
  expansiveness: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  honourable: [
    { word: 'honour', word_class: 'N' },
    { word: 'honour', word_class: 'V' },
    { word: 'honours', word_class: 'N' },
    { word: 'honoured', word_class: 'AJ' },
    { word: 'honourable', word_class: 'AJ' },
    { word: 'honourably', word_class: 'AV' },
    { word: 'honourableness', word_class: 'N' }
  ],
  bare: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  forced: [
    { word: 'force', word_class: 'N' },
    { word: 'force', word_class: 'V' },
    { word: 'forced', word_class: 'AJ' },
    { word: 'forceful', word_class: 'AJ' },
    { word: 'forcefulness', word_class: 'N' }
  ],
  naturalist: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  fortunate: [
    { word: 'fortune', word_class: 'N' },
    { word: 'fortunate', word_class: 'N' },
    { word: 'fortunate', word_class: 'AJ' },
    { word: 'fortunately', word_class: 'AV' }
  ],
  stomatous: [
    { word: 'stomatal', word_class: 'AJ' },
    { word: 'stomatitis', word_class: 'N' },
    { word: 'stomatous', word_class: 'AJ' }
  ],
  primed: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  adventive: [
    { word: 'advent', word_class: 'N' },
    { word: 'adventism', word_class: 'N' },
    { word: 'adventive', word_class: 'AJ' }
  ],
  anatomise: [
    { word: 'anatomic', word_class: 'AJ' },
    { word: 'anatomise', word_class: 'V' },
    { word: 'anatomize', word_class: 'V' },
    { word: 'anatomical', word_class: 'N' },
    { word: 'anatomical', word_class: 'AJ' },
    { word: 'anatomically', word_class: 'AV' },
    { word: 'anatomisation', word_class: 'N' },
    { word: 'anatomization', word_class: 'N' }
  ],
  departmentalisation: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  hygienic: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  caution: [
    { word: 'caution', word_class: 'N' },
    { word: 'caution', word_class: 'V' },
    { word: 'cautionary', word_class: 'AJ' }
  ],
  sixties: [
    { word: 'sixty', word_class: 'N' },
    { word: 'sixty', word_class: 'AJ' },
    { word: 'sixties', word_class: 'N' }
  ],
  looper: [
    { word: 'loop', word_class: 'N' },
    { word: 'loop', word_class: 'V' },
    { word: 'looper', word_class: 'N' },
    { word: 'looped', word_class: 'AJ' }
  ],
  captivation: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  puzzler: [
    { word: 'puzzle', word_class: 'N' },
    { word: 'puzzle', word_class: 'V' },
    { word: 'puzzler', word_class: 'N' },
    { word: 'puzzled', word_class: 'AJ' },
    { word: 'puzzling', word_class: 'AJ' },
    { word: 'puzzlement', word_class: 'N' }
  ],
  axiomatic: [
    { word: 'axiom', word_class: 'N' },
    { word: 'axiomatic', word_class: 'AJ' },
    { word: 'axiomatical', word_class: 'AJ' },
    { word: 'axiomatically', word_class: 'AV' }
  ],
  stumping: [
    { word: 'stump', word_class: 'N' },
    { word: 'stump', word_class: 'V' },
    { word: 'stumper', word_class: 'N' },
    { word: 'stumping', word_class: 'N' }
  ],
  crafter: [
    { word: 'craft', word_class: 'N' },
    { word: 'craft', word_class: 'V' },
    { word: 'crafter', word_class: 'N' }
  ],
  frosting: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  guided: [
    { word: 'guide', word_class: 'N' },
    { word: 'guide', word_class: 'V' },
    { word: 'guided', word_class: 'AJ' },
    { word: 'guiding', word_class: 'N' },
    { word: 'guidance', word_class: 'N' },
    { word: 'guiding', word_class: 'AJ' }
  ],
  scholastically: [
    { word: 'scholastic', word_class: 'N' },
    { word: 'scholastic', word_class: 'AJ' },
    { word: 'scholastically', word_class: 'AV' }
  ],
  scintillation: [
    { word: 'scintillate', word_class: 'V' },
    { word: 'scintillant', word_class: 'AJ' },
    { word: 'scintillation', word_class: 'N' },
    { word: 'scintillating', word_class: 'AJ' }
  ],
  pampered: [
    { word: 'pamper', word_class: 'V' },
    { word: 'pampered', word_class: 'AJ' },
    { word: 'pampering', word_class: 'N' },
    { word: 'pampering', word_class: 'AJ' }
  ],
  indefinitely: [
    { word: 'indefinite', word_class: 'AJ' },
    { word: 'indefinitely', word_class: 'AV' },
    { word: 'indefiniteness', word_class: 'N' }
  ],
  tiring: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  voiced: [
    { word: 'voice', word_class: 'N' },
    { word: 'voice', word_class: 'V' },
    { word: 'voiced', word_class: 'AJ' }
  ],
  dexterously: [
    { word: 'dexter', word_class: 'AJ' },
    { word: 'dexterity', word_class: 'N' },
    { word: 'dexterous', word_class: 'AJ' },
    { word: 'dexterously', word_class: 'AV' }
  ],
  streaming: [
    { word: 'stream', word_class: 'N' },
    { word: 'stream', word_class: 'V' },
    { word: 'streamer', word_class: 'N' },
    { word: 'streaming', word_class: 'N' },
    { word: 'streaming', word_class: 'AJ' }
  ],
  adopted: [
    { word: 'adopt', word_class: 'V' },
    { word: 'adopted', word_class: 'AJ' },
    { word: 'adoption', word_class: 'N' },
    { word: 'adoptive', word_class: 'AJ' },
    { word: 'adoptable', word_class: 'AJ' }
  ],
  anthropomorphize: [
    { word: 'anthropomorphic', word_class: 'AJ' },
    { word: 'anthropomorphism', word_class: 'N' },
    { word: 'anthropomorphize', word_class: 'V' },
    { word: 'anthropomorphous', word_class: 'AJ' },
    { word: 'anthropomorphization', word_class: 'N' }
  ],
  consigner: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  initial: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  projecting: [
    { word: 'project', word_class: 'N' },
    { word: 'project', word_class: 'V' },
    { word: 'projector', word_class: 'N' },
    { word: 'projected', word_class: 'AJ' },
    { word: 'projection', word_class: 'N' },
    { word: 'projecting', word_class: 'AJ' }
  ],
  immaculateness: [
    { word: 'immaculate', word_class: 'AJ' },
    { word: 'immaculately', word_class: 'AV' },
    { word: 'immaculateness', word_class: 'N' }
  ],
  crapulous: [
    { word: 'crapulence', word_class: 'N' },
    { word: 'crapulent', word_class: 'AJ' },
    { word: 'crapulous', word_class: 'AJ' }
  ],
  retort: [
    { word: 'retort', word_class: 'N' },
    { word: 'retort', word_class: 'V' },
    { word: 'retorsion', word_class: 'N' },
    { word: 'retortion', word_class: 'N' }
  ],
  reveler: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  cramp: [
    { word: 'cramp', word_class: 'N' },
    { word: 'cramp', word_class: 'V' },
    { word: 'cramped', word_class: 'AJ' }
  ],
  crash: [
    { word: 'crash', word_class: 'N' },
    { word: 'crash', word_class: 'V' },
    { word: 'crash', word_class: 'AJ' },
    { word: 'crasher', word_class: 'N' },
    { word: 'crashing', word_class: 'AJ' }
  ],
  mutiny: [
    { word: 'mutiny', word_class: 'N' },
    { word: 'mutiny', word_class: 'V' },
    { word: 'mutineer', word_class: 'N' },
    { word: 'mutinous', word_class: 'AJ' }
  ],
  teetotaling: [
    { word: 'teetotal', word_class: 'AJ' },
    { word: 'teetotaling', word_class: 'N' },
    { word: 'teetotalism', word_class: 'N' }
  ],
  remaking: [
    { word: 'remake', word_class: 'N' },
    { word: 'remake', word_class: 'V' },
    { word: 'remaking', word_class: 'N' }
  ],
  catalogue: [
    { word: 'catalogue', word_class: 'N' },
    { word: 'catalogue', word_class: 'V' },
    { word: 'cataloguer', word_class: 'N' },
    { word: 'catalogued', word_class: 'AJ' }
  ],
  mute: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  misuse: [
    { word: 'misuse', word_class: 'N' },
    { word: 'misuse', word_class: 'V' },
    { word: 'misused', word_class: 'AJ' }
  ],
  meditate: [
    { word: 'meditate', word_class: 'V' },
    { word: 'meditation', word_class: 'N' },
    { word: 'meditative', word_class: 'AJ' },
    { word: 'meditatively', word_class: 'AV' },
    { word: 'meditativeness', word_class: 'N' }
  ],
  bounded: [
    { word: 'bound', word_class: 'N' },
    { word: 'bound', word_class: 'V' },
    { word: 'bound', word_class: 'AJ' },
    { word: 'bounds', word_class: 'N' },
    { word: 'bounder', word_class: 'N' },
    { word: 'boundary', word_class: 'N' },
    { word: 'bounded', word_class: 'AJ' },
    { word: 'bounding', word_class: 'AJ' }
  ],
  catastrophically: [
    { word: 'catastrophe', word_class: 'N' },
    { word: 'catastrophic', word_class: 'AJ' },
    { word: 'catastrophically', word_class: 'AV' }
  ],
  absorption: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  babbler: [
    { word: 'babble', word_class: 'N' },
    { word: 'babble', word_class: 'V' },
    { word: 'babbler', word_class: 'N' },
    { word: 'babbling', word_class: 'N' },
    { word: 'babbling', word_class: 'AJ' },
    { word: 'babblement', word_class: 'N' }
  ],
  bisection: [
    { word: 'bisect', word_class: 'N' },
    { word: 'bisect', word_class: 'V' },
    { word: 'bisection', word_class: 'N' },
    { word: 'bisectional', word_class: 'AJ' }
  ],
  servility: [
    { word: 'servile', word_class: 'AJ' },
    { word: 'servility', word_class: 'N' },
    { word: 'servilely', word_class: 'AV' }
  ],
  concretization: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  objectionably: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  ugliness: [
    { word: 'ugli', word_class: 'N' },
    { word: 'ugly', word_class: 'AJ' },
    { word: 'uglify', word_class: 'V' },
    { word: 'ugliness', word_class: 'N' },
    { word: 'uglification', word_class: 'N' }
  ],
  reconnaissance: [
    { word: 'reconnoiter', word_class: 'V' },
    { word: 'reconnoitre', word_class: 'V' },
    { word: 'reconnaissance', word_class: 'N' },
    { word: 'reconnoitering', word_class: 'N' }
  ],
  ethically: [
    { word: 'ethic', word_class: 'N' },
    { word: 'ethics', word_class: 'N' },
    { word: 'ethical', word_class: 'AJ' },
    { word: 'ethically', word_class: 'AV' }
  ],
  formidability: [
    { word: 'formidable', word_class: 'AJ' },
    { word: 'formidably', word_class: 'AV' },
    { word: 'formidability', word_class: 'N' }
  ],
  jurisprudent: [
    { word: 'jurisprudent', word_class: 'N' },
    { word: 'jurisprudence', word_class: 'N' },
    { word: 'jurisprudential', word_class: 'AJ' },
    { word: 'jurisprudentially', word_class: 'AV' }
  ],
  electrician: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  laxative: [
    { word: 'lax', word_class: 'AJ' },
    { word: 'laxness', word_class: 'N' },
    { word: 'laxative', word_class: 'N' },
    { word: 'laxative', word_class: 'AJ' }
  ],
  trimmed: [
    { word: 'trim', word_class: 'N' },
    { word: 'trim', word_class: 'V' },
    { word: 'trim', word_class: 'AJ' },
    { word: 'trimer', word_class: 'N' },
    { word: 'trimmed', word_class: 'AJ' },
    { word: 'trimming', word_class: 'N' },
    { word: 'trimness', word_class: 'N' }
  ],
  parochial: [
    { word: 'parish', word_class: 'N' },
    { word: 'parochial', word_class: 'AJ' },
    { word: 'parochialism', word_class: 'N' },
    { word: 'parochially', word_class: 'AV' }
  ],
  partiality: [
    { word: 'partial', word_class: 'AJ' },
    { word: 'partiality', word_class: 'N' },
    { word: 'partially', word_class: 'AV' },
    { word: 'partialness', word_class: 'N' }
  ],
  nictitation: [
    { word: 'nictate', word_class: 'V' },
    { word: 'nictation', word_class: 'N' },
    { word: 'nictitate', word_class: 'V' },
    { word: 'nictitation', word_class: 'N' }
  ],
  arillate: [
    { word: 'aril', word_class: 'N' },
    { word: 'ariled', word_class: 'AJ' },
    { word: 'arillate', word_class: 'AJ' }
  ],
  weeness: [
    { word: 'wee', word_class: 'N' },
    { word: 'wee', word_class: 'V' },
    { word: 'weal', word_class: 'N' },
    { word: 'west', word_class: 'N' },
    { word: 'wee', word_class: 'AJ' },
    { word: 'west', word_class: 'AJ' },
    { word: 'west', word_class: 'AV' },
    { word: 'weeness', word_class: 'N' }
  ],
  celebrated: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  copal: [
    { word: 'cope', word_class: 'N' },
    { word: 'cope', word_class: 'V' },
    { word: 'copal', word_class: 'N' },
    { word: 'coping', word_class: 'N' }
  ],
  illegally: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  demonstratively: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  cenobitical: [
    { word: 'cenobite', word_class: 'N' },
    { word: 'cenobitic', word_class: 'AJ' },
    { word: 'cenobitical', word_class: 'AJ' }
  ],
  informal: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  repetitious: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  incredibility: [
    { word: 'incredible', word_class: 'AJ' },
    { word: 'incredibly', word_class: 'AV' },
    { word: 'incredibility', word_class: 'N' },
    { word: 'incredibleness', word_class: 'N' }
  ],
  truncate: [
    { word: 'truncate', word_class: 'V' },
    { word: 'truncate', word_class: 'AJ' },
    { word: 'truncated', word_class: 'AJ' },
    { word: 'truncation', word_class: 'N' }
  ],
  annotating: [
    { word: 'annotate', word_class: 'V' },
    { word: 'annotating', word_class: 'N' },
    { word: 'annotation', word_class: 'N' }
  ],
  retrieval: [
    { word: 'retrieve', word_class: 'N' },
    { word: 'retrieve', word_class: 'V' },
    { word: 'retrieval', word_class: 'N' },
    { word: 'retriever', word_class: 'N' },
    { word: 'retrievable', word_class: 'AJ' }
  ],
  prominent: [
    { word: 'prominence', word_class: 'N' },
    { word: 'prominent', word_class: 'AJ' },
    { word: 'prominently', word_class: 'AV' }
  ],
  knitted: [
    { word: 'knit', word_class: 'N' },
    { word: 'knit', word_class: 'V' },
    { word: 'knitted', word_class: 'AJ' },
    { word: 'knitting', word_class: 'N' }
  ],
  pedagogic: [
    { word: 'pedagogic', word_class: 'AJ' },
    { word: 'pedagogical', word_class: 'AJ' },
    { word: 'pedagogically', word_class: 'AV' }
  ],
  terrorist: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  competitiveness: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  entitle: [
    { word: 'entitle', word_class: 'V' },
    { word: 'entitled', word_class: 'AJ' },
    { word: 'entitlement', word_class: 'N' }
  ],
  depilation: [
    { word: 'depilate', word_class: 'V' },
    { word: 'depilous', word_class: 'AJ' },
    { word: 'depilation', word_class: 'N' }
  ],
  preserves: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  crusader: [
    { word: 'crusade', word_class: 'N' },
    { word: 'crusade', word_class: 'V' },
    { word: 'crusader', word_class: 'N' }
  ],
  infuriating: [
    { word: 'infuriate', word_class: 'V' },
    { word: 'infuriated', word_class: 'AJ' },
    { word: 'infuriation', word_class: 'N' },
    { word: 'infuriating', word_class: 'AJ' }
  ],
  gasify: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  resigned: [
    { word: 'resign', word_class: 'N' },
    { word: 'resign', word_class: 'V' },
    { word: 'resigned', word_class: 'AJ' },
    { word: 'resignation', word_class: 'N' }
  ],
  smart: [
    { word: 'smart', word_class: 'N' },
    { word: 'smart', word_class: 'V' },
    { word: 'smart', word_class: 'AJ' },
    { word: 'smarting', word_class: 'N' },
    { word: 'smarting', word_class: 'AJ' },
    { word: 'smartness', word_class: 'N' }
  ],
  emulsifier: [
    { word: 'emulsify', word_class: 'V' },
    { word: 'emulsifier', word_class: 'N' },
    { word: 'emulsified', word_class: 'AJ' },
    { word: 'emulsification', word_class: 'N' }
  ],
  republican: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  exorcize: [
    { word: 'exorcise', word_class: 'V' },
    { word: 'exorcism', word_class: 'N' },
    { word: 'exorcist', word_class: 'N' },
    { word: 'exorcize', word_class: 'V' },
    { word: 'exorciser', word_class: 'N' }
  ],
  impregnation: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  metaphor: [
    { word: 'metaphor', word_class: 'N' },
    { word: 'metaphoric', word_class: 'AJ' },
    { word: 'metaphorical', word_class: 'AJ' },
    { word: 'metaphorically', word_class: 'AV' }
  ],
  alkalizer: [
    { word: 'alkalic', word_class: 'AJ' },
    { word: 'alkalize', word_class: 'V' },
    { word: 'alkalizer', word_class: 'N' }
  ],
  prattler: [
    { word: 'prattle', word_class: 'N' },
    { word: 'prattle', word_class: 'V' },
    { word: 'prattler', word_class: 'N' },
    { word: 'prattling', word_class: 'AJ' }
  ],
  meticulousness: [
    { word: 'meticulous', word_class: 'AJ' },
    { word: 'meticulously', word_class: 'AV' },
    { word: 'meticulousness', word_class: 'N' }
  ],
  transitivity: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  jointed: [
    { word: 'joint', word_class: 'N' },
    { word: 'joint', word_class: 'V' },
    { word: 'joint', word_class: 'AJ' },
    { word: 'jointer', word_class: 'N' },
    { word: 'jointed', word_class: 'AJ' }
  ],
  currently: [
    { word: 'current', word_class: 'N' },
    { word: 'current', word_class: 'AJ' },
    { word: 'currently', word_class: 'AV' },
    { word: 'currentness', word_class: 'N' }
  ],
  overprotective: [
    { word: 'overprotect', word_class: 'V' },
    { word: 'overprotection', word_class: 'N' },
    { word: 'overprotective', word_class: 'AJ' }
  ],
  pricker: [
    { word: 'prick', word_class: 'N' },
    { word: 'prick', word_class: 'V' },
    { word: 'pricker', word_class: 'N' },
    { word: 'pricking', word_class: 'N' }
  ],
  decorate: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  scream: [
    { word: 'scream', word_class: 'N' },
    { word: 'scream', word_class: 'V' },
    { word: 'screamer', word_class: 'N' },
    { word: 'screaming', word_class: 'N' },
    { word: 'screaming', word_class: 'AJ' }
  ],
  casting: [
    { word: 'cast', word_class: 'N' },
    { word: 'cast', word_class: 'V' },
    { word: 'cast', word_class: 'AJ' },
    { word: 'caste', word_class: 'N' },
    { word: 'caster', word_class: 'N' },
    { word: 'castor', word_class: 'N' },
    { word: 'casting', word_class: 'N' }
  ],
  ratables: [
    { word: 'ratable', word_class: 'AJ' },
    { word: 'ratables', word_class: 'N' },
    { word: 'ratability', word_class: 'N' }
  ],
  named: [
    { word: 'name', word_class: 'N' },
    { word: 'name', word_class: 'V' },
    { word: 'names', word_class: 'N' },
    { word: 'named', word_class: 'AJ' },
    { word: 'naming', word_class: 'N' },
    { word: 'namely', word_class: 'AV' },
    { word: 'naming', word_class: 'AJ' }
  ],
  falsehood: [
    { word: 'false', word_class: 'AJ' },
    { word: 'false', word_class: 'AV' },
    { word: 'falsely', word_class: 'AV' },
    { word: 'falsehood', word_class: 'N' },
    { word: 'falseness', word_class: 'N' }
  ],
  destructibility: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  treasurer: [
    { word: 'treasure', word_class: 'N' },
    { word: 'treasure', word_class: 'V' },
    { word: 'treasurer', word_class: 'N' },
    { word: 'treasured', word_class: 'AJ' }
  ],
  cleaning: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  hematological: [
    { word: 'hematology', word_class: 'N' },
    { word: 'hematologic', word_class: 'AJ' },
    { word: 'hematological', word_class: 'AJ' }
  ],
  grizzled: [
    { word: 'grizzle', word_class: 'N' },
    { word: 'grizzle', word_class: 'V' },
    { word: 'grizzled', word_class: 'AJ' }
  ],
  suffocation: [
    { word: 'suffocate', word_class: 'V' },
    { word: 'suffocation', word_class: 'N' },
    { word: 'suffocating', word_class: 'AJ' },
    { word: 'suffocative', word_class: 'AJ' }
  ],
  say: [{ word: 'say', word_class: 'N' }, { word: 'say', word_class: 'V' }, { word: 'saying', word_class: 'N' }],
  pictorially: [
    { word: 'pictorial', word_class: 'N' },
    { word: 'pictorial', word_class: 'AJ' },
    { word: 'pictorially', word_class: 'AV' }
  ],
  obnoxiousness: [
    { word: 'obnoxious', word_class: 'AJ' },
    { word: 'obnoxiously', word_class: 'AV' },
    { word: 'obnoxiousness', word_class: 'N' }
  ],
  anesthetized: [
    { word: 'anesthetic', word_class: 'N' },
    { word: 'anesthetic', word_class: 'AJ' },
    { word: 'anesthetize', word_class: 'V' },
    { word: 'anesthetized', word_class: 'AJ' },
    { word: 'anesthetization', word_class: 'N' }
  ],
  entrenchment: [
    { word: 'entrench', word_class: 'V' },
    { word: 'entrenched', word_class: 'AJ' },
    { word: 'entrenchment', word_class: 'N' }
  ],
  gelatinousness: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  jarful: [
    { word: 'jar', word_class: 'N' },
    { word: 'jar', word_class: 'V' },
    { word: 'jarful', word_class: 'N' },
    { word: 'jarful', word_class: 'AJ' },
    { word: 'jarring', word_class: 'AJ' }
  ],
  abuttal: [
    { word: 'abut', word_class: 'V' },
    { word: 'abuttal', word_class: 'N' },
    { word: 'abutter', word_class: 'N' },
    { word: 'abutment', word_class: 'N' },
    { word: 'abutting', word_class: 'AJ' }
  ],
  diverge: [
    { word: 'diverge', word_class: 'N' },
    { word: 'diverge', word_class: 'V' },
    { word: 'divergence', word_class: 'N' },
    { word: 'divergency', word_class: 'N' },
    { word: 'divergent', word_class: 'AJ' },
    { word: 'diverging', word_class: 'AJ' }
  ],
  expectancy: [
    { word: 'expect', word_class: 'V' },
    { word: 'expected', word_class: 'AJ' },
    { word: 'expectancy', word_class: 'N' },
    { word: 'expectant', word_class: 'AJ' },
    { word: 'expectable', word_class: 'AJ' },
    { word: 'expectation', word_class: 'N' }
  ],
  classified: [
    { word: 'classify', word_class: 'V' },
    { word: 'classifier', word_class: 'N' },
    { word: 'classified', word_class: 'AJ' },
    { word: 'classifiable', word_class: 'AJ' },
    { word: 'classification', word_class: 'N' }
  ],
  obtrusiveness: [
    { word: 'obtrude', word_class: 'V' },
    { word: 'obtrusion', word_class: 'N' },
    { word: 'obtrusive', word_class: 'AJ' },
    { word: 'obtrusively', word_class: 'AV' },
    { word: 'obtrusiveness', word_class: 'N' }
  ],
  manoeuvrable: [
    { word: 'manoeuvre', word_class: 'N' },
    { word: 'manoeuvre', word_class: 'V' },
    { word: 'manoeuvrable', word_class: 'AJ' }
  ],
  soak: [
    { word: 'soak', word_class: 'N' },
    { word: 'soak', word_class: 'V' },
    { word: 'soaker', word_class: 'N' },
    { word: 'soaked', word_class: 'AJ' },
    { word: 'soaking', word_class: 'N' },
    { word: 'soaking', word_class: 'AJ' },
    { word: 'soaking', word_class: 'AV' }
  ],
  mohammedanism: [
    { word: 'mohammedan', word_class: 'N' },
    { word: 'mohammedan', word_class: 'AJ' },
    { word: 'mohammedanism', word_class: 'N' }
  ],
  pained: [
    { word: 'pain', word_class: 'N' },
    { word: 'pain', word_class: 'V' },
    { word: 'pains', word_class: 'N' },
    { word: 'pained', word_class: 'AJ' },
    { word: 'painful', word_class: 'AJ' },
    { word: 'painfulness', word_class: 'N' }
  ],
  acquit: [
    { word: 'acquit', word_class: 'V' },
    { word: 'acquittal', word_class: 'N' },
    { word: 'acquitted', word_class: 'AJ' },
    { word: 'acquittance', word_class: 'N' }
  ],
  affirmative: [
    { word: 'affirm', word_class: 'V' },
    { word: 'affirmation', word_class: 'N' },
    { word: 'affirmable', word_class: 'AJ' },
    { word: 'affirmative', word_class: 'N' },
    { word: 'affirmative', word_class: 'AJ' },
    { word: 'affirmativeness', word_class: 'N' }
  ],
  tending: [
    { word: 'tend', word_class: 'V' },
    { word: 'tend', word_class: 'AJ' },
    { word: 'tending', word_class: 'N' },
    { word: 'tendency', word_class: 'N' },
    { word: 'tendance', word_class: 'N' },
    { word: 'tending', word_class: 'AJ' },
    { word: 'tendencious', word_class: 'AJ' },
    { word: 'tendentious', word_class: 'AJ' },
    { word: 'tendentiously', word_class: 'AV' },
    { word: 'tendentiousness', word_class: 'N' }
  ],
  dehumanized: [
    { word: 'dehumanise', word_class: 'V' },
    { word: 'dehumanize', word_class: 'V' },
    { word: 'dehumanized', word_class: 'AJ' },
    { word: 'dehumanisation', word_class: 'N' },
    { word: 'dehumanization', word_class: 'N' }
  ],
  decarbonate: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  utile: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  polymorph: [
    { word: 'polymorph', word_class: 'N' },
    { word: 'polymorphic', word_class: 'AJ' },
    { word: 'polymorphism', word_class: 'N' },
    { word: 'polymorphous', word_class: 'AJ' }
  ],
  generalized: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  earthing: [
    { word: 'earth', word_class: 'N' },
    { word: 'earth', word_class: 'V' },
    { word: 'earthen', word_class: 'AJ' },
    { word: 'earthing', word_class: 'N' },
    { word: 'earthman', word_class: 'N' }
  ],
  persuasion: [
    { word: 'persuade', word_class: 'V' },
    { word: 'persuader', word_class: 'N' },
    { word: 'persuasion', word_class: 'N' },
    { word: 'persuasive', word_class: 'AJ' },
    { word: 'persuadable', word_class: 'AJ' },
    { word: 'persuasible', word_class: 'AJ' },
    { word: 'persuasively', word_class: 'AV' },
    { word: 'persuasiveness', word_class: 'N' }
  ],
  organs: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  decipherably: [
    { word: 'decipher', word_class: 'V' },
    { word: 'deciphered', word_class: 'AJ' },
    { word: 'decipherment', word_class: 'N' },
    { word: 'decipherable', word_class: 'AJ' },
    { word: 'decipherably', word_class: 'AV' }
  ],
  portrayed: [
    { word: 'portray', word_class: 'V' },
    { word: 'portrayal', word_class: 'N' },
    { word: 'portrayed', word_class: 'AJ' },
    { word: 'portraying', word_class: 'N' }
  ],
  hoarsely: [
    { word: 'hoarse', word_class: 'AJ' },
    { word: 'hoarsely', word_class: 'AV' },
    { word: 'hoarseness', word_class: 'N' }
  ],
  advertisement: [
    { word: 'advertise', word_class: 'V' },
    { word: 'advertiser', word_class: 'N' },
    { word: 'advertised', word_class: 'AJ' },
    { word: 'advertising', word_class: 'N' },
    { word: 'advertisement', word_class: 'N' }
  ],
  impudence: [
    { word: 'impudence', word_class: 'N' },
    { word: 'impudent', word_class: 'AJ' },
    { word: 'impudently', word_class: 'AV' }
  ],
  lease: [
    { word: 'lease', word_class: 'N' },
    { word: 'lease', word_class: 'V' },
    { word: 'lessee', word_class: 'N' },
    { word: 'lessor', word_class: 'N' },
    { word: 'leased', word_class: 'AJ' }
  ],
  trillions: [
    { word: 'trill', word_class: 'N' },
    { word: 'trill', word_class: 'V' },
    { word: 'trilled', word_class: 'AJ' },
    { word: 'trillion', word_class: 'N' },
    { word: 'trillion', word_class: 'AJ' },
    { word: 'trillions', word_class: 'N' }
  ],
  rotated: [
    { word: 'rotate', word_class: 'V' },
    { word: 'rotated', word_class: 'AJ' },
    { word: 'rotation', word_class: 'N' },
    { word: 'rotating', word_class: 'AJ' },
    { word: 'rotatable', word_class: 'AJ' },
    { word: 'rotational', word_class: 'AJ' }
  ],
  conciliation: [
    { word: 'conciliate', word_class: 'V' },
    { word: 'conciliator', word_class: 'N' },
    { word: 'conciliable', word_class: 'AJ' },
    { word: 'conciliation', word_class: 'N' },
    { word: 'conciliating', word_class: 'AJ' },
    { word: 'conciliative', word_class: 'AJ' }
  ],
  diagnostics: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  citify: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  distinguishing: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  clustered: [
    { word: 'cluster', word_class: 'N' },
    { word: 'cluster', word_class: 'V' },
    { word: 'clustered', word_class: 'AJ' }
  ],
  pity: [
    { word: 'pity', word_class: 'N' },
    { word: 'pity', word_class: 'V' },
    { word: 'piteous', word_class: 'AJ' },
    { word: 'pitiful', word_class: 'AJ' },
    { word: 'pitying', word_class: 'AJ' }
  ],
  afrikanerisation: [
    { word: 'afrikanerise', word_class: 'V' },
    { word: 'afrikanerize', word_class: 'V' },
    { word: 'afrikanerisation', word_class: 'N' },
    { word: 'afrikanerization', word_class: 'N' }
  ],
  acquisitive: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  arthropod: [
    { word: 'arthropod', word_class: 'N' },
    { word: 'arthropodal', word_class: 'AJ' },
    { word: 'arthropodous', word_class: 'AJ' }
  ],
  unintelligently: [
    { word: 'unintelligent', word_class: 'AJ' },
    { word: 'unintelligible', word_class: 'AJ' },
    { word: 'unintelligibly', word_class: 'AV' },
    { word: 'unintelligently', word_class: 'AV' },
    { word: 'unintelligibility', word_class: 'N' }
  ],
  dependable: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  peptisation: [
    { word: 'peptise', word_class: 'V' },
    { word: 'peptize', word_class: 'V' },
    { word: 'peptizing', word_class: 'N' },
    { word: 'peptizing', word_class: 'AJ' },
    { word: 'peptisation', word_class: 'N' },
    { word: 'peptization', word_class: 'N' }
  ],
  pageantry: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  hinder: [
    { word: 'hinder', word_class: 'V' },
    { word: 'hinder', word_class: 'AJ' },
    { word: 'hindrance', word_class: 'N' },
    { word: 'hindering', word_class: 'AJ' }
  ],
  cutinization: [
    { word: 'cutinise', word_class: 'V' },
    { word: 'cutinize', word_class: 'V' },
    { word: 'cutinisation', word_class: 'N' },
    { word: 'cutinization', word_class: 'N' }
  ],
  republic: [
    { word: 'republic', word_class: 'N' },
    { word: 'republic', word_class: 'AJ' },
    { word: 'republish', word_class: 'V' },
    { word: 'republishing', word_class: 'N' },
    { word: 'republication', word_class: 'N' }
  ],
  bookkeeper: [
    { word: 'bookkeep', word_class: 'V' },
    { word: 'bookkeeper', word_class: 'N' },
    { word: 'bookkeeping', word_class: 'N' }
  ],
  ascospore: [
    { word: 'ascospore', word_class: 'N' },
    { word: 'ascosporic', word_class: 'AJ' },
    { word: 'ascosporous', word_class: 'AJ' }
  ],
  defoliate: [
    { word: 'defoliant', word_class: 'N' },
    { word: 'defoliate', word_class: 'V' },
    { word: 'defoliate', word_class: 'AJ' },
    { word: 'defoliator', word_class: 'N' },
    { word: 'defoliated', word_class: 'AJ' },
    { word: 'defoliation', word_class: 'N' }
  ],
  sportive: [
    { word: 'sportive', word_class: 'AJ' },
    { word: 'sportively', word_class: 'AV' },
    { word: 'sportiveness', word_class: 'N' }
  ],
  advise: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  plain: [
    { word: 'plain', word_class: 'N' },
    { word: 'plain', word_class: 'V' },
    { word: 'plain', word_class: 'AJ' },
    { word: 'plain', word_class: 'AV' },
    { word: 'plainness', word_class: 'N' }
  ],
  banking: [
    { word: 'bank', word_class: 'N' },
    { word: 'bank', word_class: 'V' },
    { word: 'banker', word_class: 'N' },
    { word: 'banking', word_class: 'N' }
  ],
  corroborative: [
    { word: 'corroborate', word_class: 'V' },
    { word: 'corroborant', word_class: 'AJ' },
    { word: 'corroborated', word_class: 'AJ' },
    { word: 'corroboration', word_class: 'N' },
    { word: 'corroborative', word_class: 'AJ' }
  ],
  timid: [
    { word: 'timid', word_class: 'N' },
    { word: 'timid', word_class: 'AJ' },
    { word: 'timidity', word_class: 'N' },
    { word: 'timidness', word_class: 'N' }
  ],
  northern: [
    { word: 'northern', word_class: 'N' },
    { word: 'northern', word_class: 'AJ' },
    { word: 'northerner', word_class: 'N' },
    { word: 'northernness', word_class: 'N' }
  ],
  wailful: [
    { word: 'wail', word_class: 'N' },
    { word: 'wail', word_class: 'V' },
    { word: 'wailing', word_class: 'N' },
    { word: 'wailful', word_class: 'AJ' },
    { word: 'wailing', word_class: 'AJ' }
  ],
  stabilise: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  dehumanisation: [
    { word: 'dehumanise', word_class: 'V' },
    { word: 'dehumanize', word_class: 'V' },
    { word: 'dehumanized', word_class: 'AJ' },
    { word: 'dehumanisation', word_class: 'N' },
    { word: 'dehumanization', word_class: 'N' }
  ],
  exuberant: [
    { word: 'exuberate', word_class: 'V' },
    { word: 'exuberance', word_class: 'N' },
    { word: 'exuberant', word_class: 'AJ' },
    { word: 'exuberance', word_class: 'AJ' },
    { word: 'exuberation', word_class: 'N' }
  ],
  caloric: [
    { word: 'calory', word_class: 'N' },
    { word: 'calorie', word_class: 'N' },
    { word: 'caloric', word_class: 'AJ' }
  ],
  shopping: [
    { word: 'shop', word_class: 'N' },
    { word: 'shop', word_class: 'V' },
    { word: 'shopping', word_class: 'N' }
  ],
  spaying: [
    { word: 'spay', word_class: 'V' },
    { word: 'spay', word_class: 'AJ' },
    { word: 'spayed', word_class: 'AJ' },
    { word: 'spaying', word_class: 'N' }
  ],
  interdict: [
    { word: 'interdict', word_class: 'N' },
    { word: 'interdict', word_class: 'V' },
    { word: 'interdiction', word_class: 'N' }
  ],
  sharer: [
    { word: 'share', word_class: 'N' },
    { word: 'share', word_class: 'V' },
    { word: 'sharer', word_class: 'N' },
    { word: 'shared', word_class: 'AJ' },
    { word: 'sharing', word_class: 'N' },
    { word: 'sharing', word_class: 'AJ' }
  ],
  legitimation: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  elevator: [
    { word: 'elevate', word_class: 'V' },
    { word: 'elevator', word_class: 'N' },
    { word: 'elevated', word_class: 'AJ' },
    { word: 'elevation', word_class: 'N' }
  ],
  compressibility: [
    { word: 'compress', word_class: 'N' },
    { word: 'compress', word_class: 'V' },
    { word: 'compressor', word_class: 'N' },
    { word: 'compressed', word_class: 'AJ' },
    { word: 'compressing', word_class: 'N' },
    { word: 'compression', word_class: 'N' },
    { word: 'compressible', word_class: 'AJ' },
    { word: 'compressibility', word_class: 'N' }
  ],
  appreciable: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  scraper: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  glinting: [
    { word: 'glint', word_class: 'N' },
    { word: 'glint', word_class: 'V' },
    { word: 'glinting', word_class: 'AJ' }
  ],
  pining: [{ word: 'pine', word_class: 'N' }, { word: 'pine', word_class: 'V' }, { word: 'pining', word_class: 'N' }],
  speciality: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  elation: [
    { word: 'elate', word_class: 'V' },
    { word: 'elater', word_class: 'N' },
    { word: 'elated', word_class: 'AJ' },
    { word: 'elation', word_class: 'N' },
    { word: 'elating', word_class: 'AJ' }
  ],
  nurture: [
    { word: 'nurture', word_class: 'N' },
    { word: 'nurture', word_class: 'V' },
    { word: 'nurtural', word_class: 'AJ' },
    { word: 'nurturing', word_class: 'N' }
  ],
  deprecative: [
    { word: 'deprecate', word_class: 'V' },
    { word: 'deprecation', word_class: 'N' },
    { word: 'deprecating', word_class: 'AJ' },
    { word: 'deprecative', word_class: 'AJ' }
  ],
  saunterer: [
    { word: 'saunter', word_class: 'N' },
    { word: 'saunter', word_class: 'V' },
    { word: 'saunterer', word_class: 'N' }
  ],
  decapitation: [
    { word: 'decapitate', word_class: 'V' },
    { word: 'decapitated', word_class: 'AJ' },
    { word: 'decapitation', word_class: 'N' }
  ],
  titillating: [
    { word: 'titillate', word_class: 'V' },
    { word: 'titillated', word_class: 'AJ' },
    { word: 'titillation', word_class: 'N' },
    { word: 'titillating', word_class: 'AJ' }
  ],
  profaned: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  cased: [
    { word: 'case', word_class: 'N' },
    { word: 'case', word_class: 'V' },
    { word: 'cased', word_class: 'AJ' },
    { word: 'casing', word_class: 'N' },
    { word: 'caseful', word_class: 'N' },
    { word: 'casement', word_class: 'N' }
  ],
  obscene: [
    { word: 'obscene', word_class: 'AJ' },
    { word: 'obscenity', word_class: 'N' },
    { word: 'obscenely', word_class: 'AV' }
  ],
  fixing: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  eternize: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  gluttonous: [
    { word: 'glutton', word_class: 'N' },
    { word: 'gluttonize', word_class: 'V' },
    { word: 'gluttonous', word_class: 'AJ' },
    { word: 'gluttonously', word_class: 'AV' }
  ],
  capably: [
    { word: 'cabable', word_class: 'AJ' },
    { word: 'capable', word_class: 'AJ' },
    { word: 'capably', word_class: 'AV' },
    { word: 'capability', word_class: 'N' },
    { word: 'capableness', word_class: 'N' }
  ],
  occupation: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  harm: [
    { word: 'harm', word_class: 'N' },
    { word: 'harm', word_class: 'V' },
    { word: 'harmed', word_class: 'AJ' },
    { word: 'harmful', word_class: 'AJ' },
    { word: 'harmfulness', word_class: 'N' }
  ],
  prefigurative: [
    { word: 'prefigure', word_class: 'V' },
    { word: 'prefiguration', word_class: 'N' },
    { word: 'prefigurement', word_class: 'N' },
    { word: 'prefigurative', word_class: 'AJ' }
  ],
  walled: [{ word: 'wall', word_class: 'N' }, { word: 'wall', word_class: 'V' }, { word: 'walled', word_class: 'AJ' }],
  definition: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  moralist: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  fetter: [
    { word: 'fetter', word_class: 'N' },
    { word: 'fetter', word_class: 'V' },
    { word: 'fettered', word_class: 'AJ' }
  ],
  torrify: [
    { word: 'torrefy', word_class: 'V' },
    { word: 'torrify', word_class: 'V' },
    { word: 'torrefaction', word_class: 'N' },
    { word: 'torrifaction', word_class: 'N' }
  ],
  incontrovertibly: [
    { word: 'incontrovertible', word_class: 'AJ' },
    { word: 'incontrovertibly', word_class: 'AV' },
    { word: 'incontrovertibility', word_class: 'N' },
    { word: 'incontrovertibleness', word_class: 'N' }
  ],
  developer: [
    { word: 'develop', word_class: 'V' },
    { word: 'developer', word_class: 'N' },
    { word: 'developed', word_class: 'AJ' },
    { word: 'developing', word_class: 'N' },
    { word: 'developing', word_class: 'AJ' },
    { word: 'development', word_class: 'N' }
  ],
  immunosuppression: [
    { word: 'immunosuppressant', word_class: 'N' },
    { word: 'immunosuppression', word_class: 'N' },
    { word: 'immunosuppressive', word_class: 'AJ' }
  ],
  vitality: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  prostitution: [
    { word: 'prostitute', word_class: 'N' },
    { word: 'prostitute', word_class: 'V' },
    { word: 'prostitution', word_class: 'N' }
  ],
  grandiosely: [
    { word: 'grandiose', word_class: 'AJ' },
    { word: 'grandiosity', word_class: 'N' },
    { word: 'grandiosely', word_class: 'AV' }
  ],
  lao: [{ word: 'lao', word_class: 'N' }, { word: 'lao', word_class: 'AJ' }, { word: 'laos', word_class: 'N' }],
  glisten: [
    { word: 'glisten', word_class: 'N' },
    { word: 'glisten', word_class: 'V' },
    { word: 'glistening', word_class: 'AJ' }
  ],
  fundamentalist: [
    { word: 'fundamentalist', word_class: 'N' },
    { word: 'fundamentalist', word_class: 'AJ' },
    { word: 'fundamentalistic', word_class: 'AJ' }
  ],
  tingly: [
    { word: 'tingle', word_class: 'N' },
    { word: 'tingle', word_class: 'V' },
    { word: 'tingly', word_class: 'AJ' },
    { word: 'tingling', word_class: 'N' },
    { word: 'tingling', word_class: 'AJ' }
  ],
  dealer: [
    { word: 'deal', word_class: 'N' },
    { word: 'deal', word_class: 'V' },
    { word: 'deal', word_class: 'AJ' },
    { word: 'dealer', word_class: 'N' },
    { word: 'dealing', word_class: 'N' },
    { word: 'dealings', word_class: 'N' }
  ],
  schematically: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  alimentation: [
    { word: 'aliment', word_class: 'N' },
    { word: 'aliment', word_class: 'V' },
    { word: 'alimentation', word_class: 'N' },
    { word: 'alimentative', word_class: 'AJ' }
  ],
  maternity: [
    { word: 'maternal', word_class: 'AJ' },
    { word: 'maternity', word_class: 'N' },
    { word: 'maternally', word_class: 'AV' }
  ],
  rob: [{ word: 'rob', word_class: 'V' }, { word: 'robbery', word_class: 'N' }, { word: 'robbery', word_class: 'AJ' }],
  nauseation: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  bussing: [
    { word: 'bus', word_class: 'N' },
    { word: 'bus', word_class: 'V' },
    { word: 'buss', word_class: 'N' },
    { word: 'buss', word_class: 'V' },
    { word: 'busful', word_class: 'N' },
    { word: 'busman', word_class: 'N' },
    { word: 'busful', word_class: 'AJ' },
    { word: 'bussing', word_class: 'N' }
  ],
  gradually: [
    { word: 'gradual', word_class: 'N' },
    { word: 'gradual', word_class: 'AJ' },
    { word: 'graduality', word_class: 'N' },
    { word: 'gradually', word_class: 'AV' },
    { word: 'gradualness', word_class: 'N' }
  ],
  intradermal: [
    { word: 'intradermal', word_class: 'AJ' },
    { word: 'intradermic', word_class: 'AJ' },
    { word: 'intradermally', word_class: 'AV' }
  ],
  purulent: [
    { word: 'purulence', word_class: 'N' },
    { word: 'purulency', word_class: 'N' },
    { word: 'purulent', word_class: 'AJ' }
  ],
  shown: [
    { word: 'show', word_class: 'N' },
    { word: 'show', word_class: 'V' },
    { word: 'shown', word_class: 'AJ' },
    { word: 'showy', word_class: 'AJ' },
    { word: 'showing', word_class: 'N' },
    { word: 'showman', word_class: 'N' },
    { word: 'showiness', word_class: 'N' }
  ],
  juxtaposition: [
    { word: 'juxtapose', word_class: 'V' },
    { word: 'juxtaposed', word_class: 'AJ' },
    { word: 'juxtaposition', word_class: 'N' }
  ],
  incoherently: [
    { word: 'incoherence', word_class: 'N' },
    { word: 'incoherency', word_class: 'N' },
    { word: 'incoherent', word_class: 'AJ' },
    { word: 'incoherently', word_class: 'AV' }
  ],
  gumming: [
    { word: 'gum', word_class: 'N' },
    { word: 'gum', word_class: 'V' },
    { word: 'gummed', word_class: 'AJ' },
    { word: 'gumming', word_class: 'N' }
  ],
  goldbricking: [
    { word: 'goldbrick', word_class: 'N' },
    { word: 'goldbricking', word_class: 'N' },
    { word: 'goldbricking', word_class: 'AJ' }
  ],
  humour: [
    { word: 'humour', word_class: 'N' },
    { word: 'humour', word_class: 'V' },
    { word: 'humourist', word_class: 'N' },
    { word: 'humourous', word_class: 'AJ' },
    { word: 'humourously', word_class: 'AV' }
  ],
  'self-conscious': [
    { word: 'self-conscious', word_class: 'AJ' },
    { word: 'self-consciously', word_class: 'AV' },
    { word: 'self-consciousness', word_class: 'N' }
  ],
  furring: [
    { word: 'fur', word_class: 'N' },
    { word: 'fur', word_class: 'V' },
    { word: 'furor', word_class: 'N' },
    { word: 'furore', word_class: 'N' },
    { word: 'furred', word_class: 'AJ' },
    { word: 'furring', word_class: 'N' }
  ],
  release: [
    { word: 'release', word_class: 'N' },
    { word: 'release', word_class: 'V' },
    { word: 'released', word_class: 'AJ' },
    { word: 'releasing', word_class: 'AJ' }
  ],
  stitched: [
    { word: 'stitch', word_class: 'N' },
    { word: 'stitch', word_class: 'V' },
    { word: 'stitcher', word_class: 'N' },
    { word: 'stitched', word_class: 'AJ' },
    { word: 'stitching', word_class: 'N' }
  ],
  observant: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  emended: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  flicker: [
    { word: 'flick', word_class: 'N' },
    { word: 'flick', word_class: 'V' },
    { word: 'flicker', word_class: 'N' },
    { word: 'flicker', word_class: 'V' },
    { word: 'flickering', word_class: 'AJ' }
  ],
  caparison: [
    { word: 'caparison', word_class: 'N' },
    { word: 'caparison', word_class: 'V' },
    { word: 'caparisoned', word_class: 'AJ' }
  ],
  oceanic: [
    { word: 'ocean', word_class: 'N' },
    { word: 'oceanic', word_class: 'N' },
    { word: 'oceanic', word_class: 'AJ' }
  ],
  harassed: [
    { word: 'harass', word_class: 'V' },
    { word: 'harassed', word_class: 'AJ' },
    { word: 'harassment', word_class: 'N' }
  ],
  column: [
    { word: 'column', word_class: 'N' },
    { word: 'columnar', word_class: 'AJ' },
    { word: 'columned', word_class: 'AJ' }
  ],
  notarize: [
    { word: 'notary', word_class: 'N' },
    { word: 'notarize', word_class: 'V' },
    { word: 'notarization', word_class: 'N' }
  ],
  venerated: [
    { word: 'venerate', word_class: 'V' },
    { word: 'venereal', word_class: 'AJ' },
    { word: 'venerable', word_class: 'AJ' },
    { word: 'venerably', word_class: 'AV' },
    { word: 'venerated', word_class: 'AJ' },
    { word: 'veneration', word_class: 'N' },
    { word: 'venerating', word_class: 'AJ' },
    { word: 'venerability', word_class: 'N' },
    { word: 'venerability', word_class: 'AJ' }
  ],
  impassivity: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  grubbiness: [
    { word: 'grubby', word_class: 'N' },
    { word: 'grubby', word_class: 'AJ' },
    { word: 'grubbiness', word_class: 'N' }
  ],
  sense: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  hardship: [
    { word: 'hard', word_class: 'AJ' },
    { word: 'hard', word_class: 'AV' },
    { word: 'harding', word_class: 'N' },
    { word: 'hardness', word_class: 'N' },
    { word: 'hardship', word_class: 'N' }
  ],
  pleased: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  translation: [
    { word: 'translate', word_class: 'V' },
    { word: 'translate', word_class: 'AJ' },
    { word: 'translator', word_class: 'N' },
    { word: 'translation', word_class: 'N' },
    { word: 'translatable', word_class: 'AJ' },
    { word: 'translational', word_class: 'AJ' }
  ],
  figure: [
    { word: 'figure', word_class: 'N' },
    { word: 'figure', word_class: 'V' },
    { word: 'figurer', word_class: 'N' },
    { word: 'figural', word_class: 'AJ' },
    { word: 'figured', word_class: 'AJ' },
    { word: 'figuring', word_class: 'N' },
    { word: 'figuration', word_class: 'N' },
    { word: 'figurative', word_class: 'AJ' },
    { word: 'figuratively', word_class: 'AV' }
  ],
  ejector: [
    { word: 'eject', word_class: 'V' },
    { word: 'ejector', word_class: 'N' },
    { word: 'ejection', word_class: 'N' }
  ],
  fathomable: [
    { word: 'fathom', word_class: 'N' },
    { word: 'fathom', word_class: 'V' },
    { word: 'fathomable', word_class: 'AJ' }
  ],
  objector: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  sheathe: [
    { word: 'sheath', word_class: 'N' },
    { word: 'sheathe', word_class: 'V' },
    { word: 'sheathed', word_class: 'AJ' },
    { word: 'sheathing', word_class: 'N' }
  ],
  purgatorial: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  knowledgeably: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  sacrilegious: [
    { word: 'sacrilege', word_class: 'N' },
    { word: 'sacrilegious', word_class: 'AJ' },
    { word: 'sacrilegiously', word_class: 'AV' },
    { word: 'sacrilegiousness', word_class: 'N' }
  ],
  shovelful: [
    { word: 'shovel', word_class: 'N' },
    { word: 'shovel', word_class: 'V' },
    { word: 'shoveler', word_class: 'N' },
    { word: 'shovelful', word_class: 'N' },
    { word: 'shovelful', word_class: 'AJ' }
  ],
  pelecypodous: [
    { word: 'pelecypod', word_class: 'N' },
    { word: 'pelecypod', word_class: 'AJ' },
    { word: 'pelecypodous', word_class: 'AJ' }
  ],
  unprofitableness: [
    { word: 'unprofitable', word_class: 'AJ' },
    { word: 'unprofitably', word_class: 'AV' },
    { word: 'unprofitability', word_class: 'N' },
    { word: 'unprofitableness', word_class: 'N' }
  ],
  coven: [
    { word: 'coven', word_class: 'N' },
    { word: 'covenant', word_class: 'N' },
    { word: 'covenant', word_class: 'V' }
  ],
  obdurate: [
    { word: 'obduracy', word_class: 'N' },
    { word: 'obdurate', word_class: 'AJ' },
    { word: 'obdurately', word_class: 'AV' }
  ],
  selection: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  internationalize: [
    { word: 'international', word_class: 'N' },
    { word: 'international', word_class: 'V' },
    { word: 'international', word_class: 'AJ' },
    { word: 'internationalise', word_class: 'V' },
    { word: 'internationalize', word_class: 'V' },
    { word: 'internationality', word_class: 'N' },
    { word: 'internationalism', word_class: 'N' },
    { word: 'internationalist', word_class: 'N' },
    { word: 'internationalist', word_class: 'AJ' },
    { word: 'internationally', word_class: 'AV' },
    { word: 'internationalistic', word_class: 'AJ' },
    { word: 'internationalisation', word_class: 'N' },
    { word: 'internationalization', word_class: 'N' }
  ],
  disavowable: [
    { word: 'disavow', word_class: 'V' },
    { word: 'disavowal', word_class: 'N' },
    { word: 'disavowable', word_class: 'AJ' }
  ],
  caste: [
    { word: 'cast', word_class: 'N' },
    { word: 'cast', word_class: 'V' },
    { word: 'cast', word_class: 'AJ' },
    { word: 'caste', word_class: 'N' },
    { word: 'caster', word_class: 'N' },
    { word: 'castor', word_class: 'N' },
    { word: 'casting', word_class: 'N' }
  ],
  incise: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  condition: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  jabbering: [
    { word: 'jabber', word_class: 'N' },
    { word: 'jabber', word_class: 'V' },
    { word: 'jabberer', word_class: 'N' },
    { word: 'jabbering', word_class: 'N' },
    { word: 'jabbering', word_class: 'AJ' }
  ],
  flooring: [
    { word: 'floor', word_class: 'N' },
    { word: 'floor', word_class: 'V' },
    { word: 'floored', word_class: 'AJ' },
    { word: 'flooring', word_class: 'N' }
  ],
  exporting: [
    { word: 'export', word_class: 'N' },
    { word: 'export', word_class: 'V' },
    { word: 'exporter', word_class: 'N' },
    { word: 'exporting', word_class: 'N' },
    { word: 'exportable', word_class: 'AJ' },
    { word: 'exportation', word_class: 'N' }
  ],
  unrespectability: [
    { word: 'unrespected', word_class: 'AJ' },
    { word: 'unrespectable', word_class: 'AJ' },
    { word: 'unrespectability', word_class: 'N' }
  ],
  restricting: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  glower: [
    { word: 'glower', word_class: 'N' },
    { word: 'glower', word_class: 'V' },
    { word: 'glowering', word_class: 'AJ' }
  ],
  enlivened: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  slider: [
    { word: 'slide', word_class: 'N' },
    { word: 'slide', word_class: 'V' },
    { word: 'slider', word_class: 'N' },
    { word: 'sliding', word_class: 'AJ' }
  ],
  sop: [
    { word: 'sop', word_class: 'N' },
    { word: 'sop', word_class: 'V' },
    { word: 'sops', word_class: 'N' },
    { word: 'sopping', word_class: 'AJ' },
    { word: 'sopping', word_class: 'AV' }
  ],
  lowering: [
    { word: 'lower', word_class: 'N' },
    { word: 'lower', word_class: 'V' },
    { word: 'lower', word_class: 'AJ' },
    { word: 'lowered', word_class: 'AJ' },
    { word: 'lowering', word_class: 'N' },
    { word: 'lowering', word_class: 'AJ' }
  ],
  rationally: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  diminution: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  depolarisation: [
    { word: 'depolarise', word_class: 'V' },
    { word: 'depolarize', word_class: 'V' },
    { word: 'depolarisation', word_class: 'N' },
    { word: 'depolarization', word_class: 'N' }
  ],
  carmine: [
    { word: 'carmine', word_class: 'N' },
    { word: 'carmine', word_class: 'V' },
    { word: 'carmine', word_class: 'AJ' },
    { word: 'carminative', word_class: 'N' },
    { word: 'carminative', word_class: 'AJ' }
  ],
  devastation: [
    { word: 'devastate', word_class: 'V' },
    { word: 'devastated', word_class: 'AJ' },
    { word: 'devastation', word_class: 'N' },
    { word: 'devastating', word_class: 'AJ' }
  ],
  standardization: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  palliative: [
    { word: 'pally', word_class: 'AJ' },
    { word: 'palliative', word_class: 'N' },
    { word: 'palliative', word_class: 'AJ' }
  ],
  mechanistic: [
    { word: 'mechanist', word_class: 'N' },
    { word: 'mechanistic', word_class: 'AJ' },
    { word: 'mechanistically', word_class: 'AV' }
  ],
  disownment: [
    { word: 'disown', word_class: 'V' },
    { word: 'disowned', word_class: 'AJ' },
    { word: 'disowning', word_class: 'N' },
    { word: 'disownment', word_class: 'N' }
  ],
  retrocession: [
    { word: 'retrocede', word_class: 'V' },
    { word: 'retrocedence', word_class: 'N' },
    { word: 'retrocession', word_class: 'N' }
  ],
  plow: [
    { word: 'plow', word_class: 'N' },
    { word: 'plow', word_class: 'V' },
    { word: 'plowed', word_class: 'AJ' },
    { word: 'plowing', word_class: 'N' }
  ],
  confederation: [
    { word: 'confederate', word_class: 'N' },
    { word: 'confederate', word_class: 'V' },
    { word: 'confederate', word_class: 'AJ' },
    { word: 'confederation', word_class: 'N' }
  ],
  consistent: [
    { word: 'consist', word_class: 'V' },
    { word: 'consistence', word_class: 'N' },
    { word: 'consistency', word_class: 'N' },
    { word: 'consistent', word_class: 'AJ' },
    { word: 'consistently', word_class: 'AV' }
  ],
  collectivisation: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  overstated: [
    { word: 'overstate', word_class: 'V' },
    { word: 'overstated', word_class: 'AJ' },
    { word: 'overstatement', word_class: 'N' }
  ],
  captivated: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  fluctuating: [
    { word: 'fluctuate', word_class: 'V' },
    { word: 'fluctuation', word_class: 'N' },
    { word: 'fluctuating', word_class: 'AJ' }
  ],
  coloration: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  bankrupt: [
    { word: 'bankrupt', word_class: 'N' },
    { word: 'bankrupt', word_class: 'V' },
    { word: 'bankrupt', word_class: 'AJ' },
    { word: 'bankruptcy', word_class: 'N' }
  ],
  monetization: [
    { word: 'monetise', word_class: 'V' },
    { word: 'monetize', word_class: 'V' },
    { word: 'monetisation', word_class: 'N' },
    { word: 'monetization', word_class: 'N' }
  ],
  macadamized: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  coloured: [
    { word: 'colour', word_class: 'N' },
    { word: 'colour', word_class: 'V' },
    { word: 'colour', word_class: 'AJ' },
    { word: 'colours', word_class: 'N' },
    { word: 'coloured', word_class: 'AJ' },
    { word: 'colouring', word_class: 'N' },
    { word: 'colourful', word_class: 'AJ' }
  ],
  muse: [
    { word: 'muse', word_class: 'N' },
    { word: 'muse', word_class: 'V' },
    { word: 'musing', word_class: 'N' },
    { word: 'musing', word_class: 'AJ' }
  ],
  sort: [
    { word: 'sort', word_class: 'N' },
    { word: 'sort', word_class: 'V' },
    { word: 'sorter', word_class: 'N' },
    { word: 'sorted', word_class: 'AJ' },
    { word: 'sorting', word_class: 'N' }
  ],
  laughing: [
    { word: 'laugh', word_class: 'N' },
    { word: 'laugh', word_class: 'V' },
    { word: 'laugher', word_class: 'N' },
    { word: 'laughter', word_class: 'N' },
    { word: 'laughing', word_class: 'AJ' }
  ],
  annihilated: [
    { word: 'annihilate', word_class: 'V' },
    { word: 'annihilated', word_class: 'AJ' },
    { word: 'annihilation', word_class: 'N' },
    { word: 'annihilating', word_class: 'AJ' },
    { word: 'annihilative', word_class: 'AJ' }
  ],
  remover: [
    { word: 'remove', word_class: 'N' },
    { word: 'remove', word_class: 'V' },
    { word: 'removal', word_class: 'N' },
    { word: 'remover', word_class: 'N' },
    { word: 'removed', word_class: 'AJ' },
    { word: 'removable', word_class: 'AJ' }
  ],
  conductivity: [
    { word: 'conduct', word_class: 'N' },
    { word: 'conduct', word_class: 'V' },
    { word: 'conductor', word_class: 'N' },
    { word: 'conducted', word_class: 'AJ' },
    { word: 'conduction', word_class: 'N' },
    { word: 'conducting', word_class: 'N' },
    { word: 'conductance', word_class: 'N' },
    { word: 'conductive', word_class: 'AJ' },
    { word: 'conductivity', word_class: 'N' }
  ],
  direction: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  unbelieving: [
    { word: 'unbeliever', word_class: 'N' },
    { word: 'unbelieving', word_class: 'AJ' },
    { word: 'unbelievable', word_class: 'AJ' },
    { word: 'unbelievably', word_class: 'AV' }
  ],
  exponentially: [
    { word: 'exponent', word_class: 'N' },
    { word: 'exponential', word_class: 'N' },
    { word: 'exponential', word_class: 'AJ' },
    { word: 'exponentiate', word_class: 'V' },
    { word: 'exponentially', word_class: 'AV' },
    { word: 'exponentiation', word_class: 'N' }
  ],
  gladfulness: [
    { word: 'glad', word_class: 'AJ' },
    { word: 'gladness', word_class: 'N' },
    { word: 'gladfulness', word_class: 'N' }
  ],
  rousing: [
    { word: 'rouse', word_class: 'N' },
    { word: 'rouse', word_class: 'V' },
    { word: 'rousing', word_class: 'N' },
    { word: 'rousing', word_class: 'AJ' }
  ],
  wherefor: [
    { word: 'wherefor', word_class: 'AV' },
    { word: 'wherefore', word_class: 'N' },
    { word: 'wherefore', word_class: 'AV' }
  ],
  calligraphical: [
    { word: 'calligraph', word_class: 'N' },
    { word: 'calligrapher', word_class: 'N' },
    { word: 'calligraphic', word_class: 'AJ' },
    { word: 'calligraphical', word_class: 'AJ' }
  ],
  disparagement: [
    { word: 'disparage', word_class: 'N' },
    { word: 'disparage', word_class: 'V' },
    { word: 'disparager', word_class: 'N' },
    { word: 'disparaging', word_class: 'AJ' },
    { word: 'disparagement', word_class: 'N' }
  ],
  adoring: [
    { word: 'adore', word_class: 'V' },
    { word: 'adorer', word_class: 'N' },
    { word: 'adored', word_class: 'AJ' },
    { word: 'adoring', word_class: 'AJ' },
    { word: 'adorable', word_class: 'AJ' },
    { word: 'adorably', word_class: 'AV' },
    { word: 'adoration', word_class: 'N' },
    { word: 'adorability', word_class: 'N' },
    { word: 'adorableness', word_class: 'N' }
  ],
  perisher: [
    { word: 'perish', word_class: 'V' },
    { word: 'perisher', word_class: 'N' },
    { word: 'perishable', word_class: 'AJ' },
    { word: 'perishables', word_class: 'N' }
  ],
  neutered: [
    { word: 'neuter', word_class: 'N' },
    { word: 'neuter', word_class: 'V' },
    { word: 'neuter', word_class: 'AJ' },
    { word: 'neutered', word_class: 'AJ' },
    { word: 'neutering', word_class: 'N' }
  ],
  'deaf-mutism': [
    { word: 'deaf-mute', word_class: 'N' },
    { word: 'deaf-mute', word_class: 'AJ' },
    { word: 'deaf-mutism', word_class: 'N' },
    { word: 'deaf-muteness', word_class: 'N' }
  ],
  ferrying: [
    { word: 'ferry', word_class: 'N' },
    { word: 'ferry', word_class: 'V' },
    { word: 'ferris', word_class: 'N' },
    { word: 'ferrying', word_class: 'N' },
    { word: 'ferryman', word_class: 'N' }
  ],
  inculpable: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  swearer: [
    { word: 'swear', word_class: 'V' },
    { word: 'sworn', word_class: 'AJ' },
    { word: 'swearer', word_class: 'N' },
    { word: 'swearing', word_class: 'N' }
  ],
  gynandromorphous: [
    { word: 'gynandromorph', word_class: 'N' },
    { word: 'gynandromorphic', word_class: 'AJ' },
    { word: 'gynandromorphous', word_class: 'AJ' }
  ],
  gross: [
    { word: 'gross', word_class: 'N' },
    { word: 'gross', word_class: 'V' },
    { word: 'gross', word_class: 'AJ' },
    { word: 'grossness', word_class: 'N' }
  ],
  roentgen: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  manually: [
    { word: 'manual', word_class: 'N' },
    { word: 'manual', word_class: 'AJ' },
    { word: 'manually', word_class: 'AV' }
  ],
  baa: [{ word: 'baa', word_class: 'N' }, { word: 'baa', word_class: 'V' }, { word: 'baas', word_class: 'N' }],
  barkeeper: [
    { word: 'barkeep', word_class: 'N' },
    { word: 'barkeep', word_class: 'V' },
    { word: 'barkeeper', word_class: 'N' }
  ],
  deliberateness: [
    { word: 'deliberate', word_class: 'V' },
    { word: 'deliberate', word_class: 'AJ' },
    { word: 'deliberation', word_class: 'N' },
    { word: 'deliberately', word_class: 'AV' },
    { word: 'deliberateness', word_class: 'N' }
  ],
  synthetism: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  perked: [{ word: 'perk', word_class: 'N' }, { word: 'perk', word_class: 'V' }, { word: 'perked', word_class: 'AJ' }],
  rewrite: [
    { word: 'rewrite', word_class: 'N' },
    { word: 'rewrite', word_class: 'V' },
    { word: 'rewriter', word_class: 'N' },
    { word: 'rewriting', word_class: 'N' }
  ],
  contradictory: [
    { word: 'contradictory', word_class: 'N' },
    { word: 'contradictory', word_class: 'AJ' },
    { word: 'contradictoriness', word_class: 'N' }
  ],
  laicise: [
    { word: 'laicise', word_class: 'V' },
    { word: 'laicize', word_class: 'V' },
    { word: 'laicisation', word_class: 'N' },
    { word: 'laicization', word_class: 'N' }
  ],
  accelerate: [
    { word: 'accelerate', word_class: 'V' },
    { word: 'accelerator', word_class: 'N' },
    { word: 'accelerated', word_class: 'AJ' },
    { word: 'acceleration', word_class: 'N' },
    { word: 'accelerating', word_class: 'AJ' }
  ],
  cognizance: [
    { word: 'cognize', word_class: 'V' },
    { word: 'cognizance', word_class: 'N' },
    { word: 'cognizant', word_class: 'AJ' },
    { word: 'cognizable', word_class: 'AJ' }
  ],
  mormons: [
    { word: 'mormon', word_class: 'N' },
    { word: 'mormon', word_class: 'AJ' },
    { word: 'mormons', word_class: 'N' },
    { word: 'mormonism', word_class: 'N' }
  ],
  decarbonation: [
    { word: 'decarbonate', word_class: 'V' },
    { word: 'decarbonise', word_class: 'V' },
    { word: 'decarbonize', word_class: 'V' },
    { word: 'decarbonation', word_class: 'N' },
    { word: 'decarbonisation', word_class: 'N' },
    { word: 'decarbonization', word_class: 'N' }
  ],
  interruption: [
    { word: 'interrupt', word_class: 'V' },
    { word: 'interrupter', word_class: 'N' },
    { word: 'interrupted', word_class: 'AJ' },
    { word: 'interruption', word_class: 'N' }
  ],
  serenely: [
    { word: 'serene', word_class: 'AJ' },
    { word: 'serenity', word_class: 'N' },
    { word: 'serenely', word_class: 'AV' }
  ],
  discolored: [
    { word: 'discolor', word_class: 'V' },
    { word: 'discolored', word_class: 'AJ' },
    { word: 'discolorize', word_class: 'V' },
    { word: 'discoloration', word_class: 'N' }
  ],
  niceness: [
    { word: 'nice', word_class: 'N' },
    { word: 'nice', word_class: 'AJ' },
    { word: 'nicety', word_class: 'N' },
    { word: 'nicely', word_class: 'AV' },
    { word: 'niceness', word_class: 'N' }
  ],
  courage: [
    { word: 'courage', word_class: 'N' },
    { word: 'courageous', word_class: 'AJ' },
    { word: 'courageously', word_class: 'AV' },
    { word: 'courageousness', word_class: 'N' }
  ],
  elasticise: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  trim: [
    { word: 'trim', word_class: 'N' },
    { word: 'trim', word_class: 'V' },
    { word: 'trim', word_class: 'AJ' },
    { word: 'trimer', word_class: 'N' },
    { word: 'trimmed', word_class: 'AJ' },
    { word: 'trimming', word_class: 'N' },
    { word: 'trimness', word_class: 'N' }
  ],
  severable: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  surrendered: [
    { word: 'surrender', word_class: 'N' },
    { word: 'surrender', word_class: 'V' },
    { word: 'surrendered', word_class: 'AJ' }
  ],
  adhesion: [
    { word: 'adhere', word_class: 'V' },
    { word: 'adherent', word_class: 'N' },
    { word: 'adhesion', word_class: 'N' },
    { word: 'adhesive', word_class: 'N' },
    { word: 'adherence', word_class: 'N' },
    { word: 'adherent', word_class: 'AJ' },
    { word: 'adhesive', word_class: 'AJ' },
    { word: 'adhesiveness', word_class: 'N' }
  ],
  harmonics: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  precipitancy: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  convalescent: [
    { word: 'convalesce', word_class: 'V' },
    { word: 'convalescent', word_class: 'N' },
    { word: 'convalescence', word_class: 'N' },
    { word: 'convalescent', word_class: 'AJ' }
  ],
  exoneration: [
    { word: 'exonerate', word_class: 'V' },
    { word: 'exonerated', word_class: 'AJ' },
    { word: 'exoneration', word_class: 'N' }
  ],
  democrat: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  observer: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  aggregative: [
    { word: 'aggregate', word_class: 'N' },
    { word: 'aggregate', word_class: 'V' },
    { word: 'aggregate', word_class: 'AJ' },
    { word: 'aggregation', word_class: 'N' },
    { word: 'aggregative', word_class: 'AJ' }
  ],
  persistently: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  professing: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  blithely: [
    { word: 'blithe', word_class: 'AJ' },
    { word: 'blithely', word_class: 'AV' },
    { word: 'blitheness', word_class: 'N' }
  ],
  nativistic: [
    { word: 'nativist', word_class: 'N' },
    { word: 'nativist', word_class: 'AJ' },
    { word: 'nativistic', word_class: 'AJ' }
  ],
  denier: [{ word: 'deny', word_class: 'V' }, { word: 'denial', word_class: 'N' }, { word: 'denier', word_class: 'N' }],
  turned: [
    { word: 'turn', word_class: 'N' },
    { word: 'turn', word_class: 'V' },
    { word: 'turner', word_class: 'N' },
    { word: 'turned', word_class: 'AJ' },
    { word: 'turnery', word_class: 'N' },
    { word: 'turning', word_class: 'N' },
    { word: 'turnery', word_class: 'AJ' },
    { word: 'turning', word_class: 'AJ' }
  ],
  durably: [
    { word: 'durable', word_class: 'AJ' },
    { word: 'durables', word_class: 'N' },
    { word: 'durably', word_class: 'AV' },
    { word: 'durability', word_class: 'N' }
  ],
  gyps: [{ word: 'gyp', word_class: 'N' }, { word: 'gyp', word_class: 'V' }, { word: 'gyps', word_class: 'N' }],
  editorialization: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  fictional: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  depressor: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  ferment: [
    { word: 'ferment', word_class: 'N' },
    { word: 'ferment', word_class: 'V' },
    { word: 'fermented', word_class: 'AJ' },
    { word: 'fermenting', word_class: 'N' },
    { word: 'fermentation', word_class: 'N' }
  ],
  suiting: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  affability: [
    { word: 'affable', word_class: 'AJ' },
    { word: 'affably', word_class: 'AV' },
    { word: 'affability', word_class: 'N' },
    { word: 'affableness', word_class: 'N' }
  ],
  counter: [
    { word: 'count', word_class: 'N' },
    { word: 'count', word_class: 'V' },
    { word: 'counter', word_class: 'N' },
    { word: 'counter', word_class: 'V' },
    { word: 'counter', word_class: 'AV' },
    { word: 'counter', word_class: 'AJ' },
    { word: 'counting', word_class: 'N' },
    { word: 'counterman', word_class: 'N' }
  ],
  hemstitching: [
    { word: 'hemstitch', word_class: 'N' },
    { word: 'hemstitch', word_class: 'V' },
    { word: 'hemstitching', word_class: 'N' }
  ],
  rectify: [
    { word: 'rectify', word_class: 'V' },
    { word: 'rectifier', word_class: 'N' },
    { word: 'rectified', word_class: 'AJ' },
    { word: 'rectifiable', word_class: 'AJ' },
    { word: 'rectification', word_class: 'N' }
  ],
  overactivity: [
    { word: 'overact', word_class: 'V' },
    { word: 'overacting', word_class: 'N' },
    { word: 'overactive', word_class: 'AJ' },
    { word: 'overactivity', word_class: 'N' }
  ],
  license: [
    { word: 'license', word_class: 'N' },
    { word: 'license', word_class: 'V' },
    { word: 'licensee', word_class: 'N' },
    { word: 'licensed', word_class: 'AJ' }
  ],
  miscellany: [
    { word: 'miscellany', word_class: 'N' },
    { word: 'miscellany', word_class: 'AJ' },
    { word: 'miscellaneous', word_class: 'AJ' }
  ],
  blur: [{ word: 'blur', word_class: 'N' }, { word: 'blur', word_class: 'V' }, { word: 'blurred', word_class: 'AJ' }],
  venally: [
    { word: 'venal', word_class: 'AJ' },
    { word: 'venality', word_class: 'N' },
    { word: 'venally', word_class: 'AV' }
  ],
  plumed: [
    { word: 'plume', word_class: 'N' },
    { word: 'plume', word_class: 'V' },
    { word: 'plumage', word_class: 'N' },
    { word: 'plumed', word_class: 'AJ' },
    { word: 'plumaged', word_class: 'AJ' }
  ],
  able: [
    { word: 'abls', word_class: 'N' },
    { word: 'able', word_class: 'AJ' },
    { word: 'ably', word_class: 'AV' },
    { word: 'ability', word_class: 'N' },
    { word: 'ablative', word_class: 'N' },
    { word: 'ablative', word_class: 'AJ' }
  ],
  bilobate: [
    { word: 'bilobed', word_class: 'AJ' },
    { word: 'bilobate', word_class: 'AJ' },
    { word: 'bilobated', word_class: 'AJ' }
  ],
  superinduction: [
    { word: 'superinduce', word_class: 'V' },
    { word: 'superinduction', word_class: 'N' },
    { word: 'superinducement', word_class: 'N' }
  ],
  declassification: [
    { word: 'declassify', word_class: 'V' },
    { word: 'declassified', word_class: 'AJ' },
    { word: 'declassification', word_class: 'N' }
  ],
  stoned: [
    { word: 'stone', word_class: 'N' },
    { word: 'stone', word_class: 'V' },
    { word: 'stony', word_class: 'AJ' },
    { word: 'stone', word_class: 'AJ' },
    { word: 'stoned', word_class: 'AJ' }
  ],
  photographic: [
    { word: 'photograph', word_class: 'N' },
    { word: 'photograph', word_class: 'V' },
    { word: 'photographer', word_class: 'N' },
    { word: 'photographic', word_class: 'AJ' },
    { word: 'photographically', word_class: 'AV' }
  ],
  encouraging: [
    { word: 'encourage', word_class: 'V' },
    { word: 'encouraged', word_class: 'AJ' },
    { word: 'encouraging', word_class: 'AJ' },
    { word: 'encouragement', word_class: 'N' }
  ],
  living: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  calm: [
    { word: 'calm', word_class: 'N' },
    { word: 'calm', word_class: 'V' },
    { word: 'calm', word_class: 'AJ' },
    { word: 'calming', word_class: 'N' },
    { word: 'calming', word_class: 'AJ' },
    { word: 'calmness', word_class: 'N' }
  ],
  endowment: [
    { word: 'endow', word_class: 'V' },
    { word: 'endowed', word_class: 'AJ' },
    { word: 'endowment', word_class: 'N' }
  ],
  agglomeration: [
    { word: 'agglomerate', word_class: 'V' },
    { word: 'agglomerate', word_class: 'AJ' },
    { word: 'agglomerated', word_class: 'AJ' },
    { word: 'agglomeration', word_class: 'N' }
  ],
  vandalize: [
    { word: 'vandal', word_class: 'N' },
    { word: 'vandalise', word_class: 'V' },
    { word: 'vandalism', word_class: 'N' },
    { word: 'vandalize', word_class: 'V' },
    { word: 'vandalization', word_class: 'N' }
  ],
  alterative: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  unintelligent: [
    { word: 'unintelligent', word_class: 'AJ' },
    { word: 'unintelligible', word_class: 'AJ' },
    { word: 'unintelligibly', word_class: 'AV' },
    { word: 'unintelligently', word_class: 'AV' },
    { word: 'unintelligibility', word_class: 'N' }
  ],
  toilsome: [
    { word: 'toil', word_class: 'N' },
    { word: 'toil', word_class: 'V' },
    { word: 'toiler', word_class: 'N' },
    { word: 'toiling', word_class: 'AJ' },
    { word: 'toilsome', word_class: 'AJ' },
    { word: 'toilsomeness', word_class: 'N' }
  ],
  dulled: [
    { word: 'dull', word_class: 'V' },
    { word: 'dull', word_class: 'AJ' },
    { word: 'dully', word_class: 'AV' },
    { word: 'dulled', word_class: 'AJ' },
    { word: 'dullness', word_class: 'N' }
  ],
  boasting: [
    { word: 'boast', word_class: 'N' },
    { word: 'boast', word_class: 'V' },
    { word: 'boaster', word_class: 'N' },
    { word: 'boasting', word_class: 'N' },
    { word: 'boastful', word_class: 'AJ' },
    { word: 'boastfulness', word_class: 'N' }
  ],
  retrospective: [
    { word: 'retrospect', word_class: 'N' },
    { word: 'retrospect', word_class: 'V' },
    { word: 'retrospection', word_class: 'N' },
    { word: 'retrospective', word_class: 'N' },
    { word: 'retrospective', word_class: 'AJ' },
    { word: 'retrospectively', word_class: 'AV' }
  ],
  resettled: [
    { word: 'resettle', word_class: 'V' },
    { word: 'resettled', word_class: 'AJ' },
    { word: 'resettlement', word_class: 'N' }
  ],
  buckle: [
    { word: 'buckle', word_class: 'N' },
    { word: 'buckle', word_class: 'V' },
    { word: 'buckler', word_class: 'N' }
  ],
  marshall: [
    { word: 'marshal', word_class: 'N' },
    { word: 'marshal', word_class: 'V' },
    { word: 'marshal', word_class: 'AJ' },
    { word: 'marshall', word_class: 'N' },
    { word: 'marshaling', word_class: 'N' },
    { word: 'marshaling', word_class: 'V' },
    { word: 'marshalship', word_class: 'N' }
  ],
  befoul: [
    { word: 'befoul', word_class: 'V' },
    { word: 'befouled', word_class: 'AJ' },
    { word: 'befoulment', word_class: 'N' }
  ],
  nearness: [
    { word: 'near', word_class: 'V' },
    { word: 'near', word_class: 'AJ' },
    { word: 'near', word_class: 'AV' },
    { word: 'nearness', word_class: 'N' }
  ],
  meretricious: [
    { word: 'meretricious', word_class: 'AJ' },
    { word: 'meretriciously', word_class: 'AV' },
    { word: 'meretriciousness', word_class: 'N' }
  ],
  externally: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  creaking: [
    { word: 'creak', word_class: 'N' },
    { word: 'creak', word_class: 'V' },
    { word: 'creaking', word_class: 'N' },
    { word: 'creaking', word_class: 'AJ' }
  ],
  disavowal: [
    { word: 'disavow', word_class: 'V' },
    { word: 'disavowal', word_class: 'N' },
    { word: 'disavowable', word_class: 'AJ' }
  ],
  allegorization: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  canonist: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  catheterization: [
    { word: 'catheterise', word_class: 'V' },
    { word: 'catheterize', word_class: 'V' },
    { word: 'catheterisation', word_class: 'N' },
    { word: 'catheterization', word_class: 'N' }
  ],
  justness: [
    { word: 'just', word_class: 'AJ' },
    { word: 'just', word_class: 'AV' },
    { word: 'justness', word_class: 'N' }
  ],
  desexualize: [
    { word: 'desexualise', word_class: 'V' },
    { word: 'desexualize', word_class: 'V' },
    { word: 'desexualisation', word_class: 'N' },
    { word: 'desexualization', word_class: 'N' }
  ],
  embarrassment: [
    { word: 'embarrass', word_class: 'V' },
    { word: 'embarrassed', word_class: 'AJ' },
    { word: 'embarrassing', word_class: 'AJ' },
    { word: 'embarrassment', word_class: 'N' }
  ],
  odorize: [
    { word: 'odor', word_class: 'N' },
    { word: 'odorize', word_class: 'V' },
    { word: 'odorous', word_class: 'AJ' },
    { word: 'odoriferous', word_class: 'AJ' }
  ],
  sweater: [
    { word: 'sweat', word_class: 'N' },
    { word: 'sweat', word_class: 'V' },
    { word: 'sweats', word_class: 'N' },
    { word: 'sweater', word_class: 'N' },
    { word: 'sweating', word_class: 'N' },
    { word: 'sweating', word_class: 'AJ' }
  ],
  tailing: [
    { word: 'tail', word_class: 'N' },
    { word: 'tail', word_class: 'V' },
    { word: 'tail', word_class: 'AJ' },
    { word: 'tails', word_class: 'N' },
    { word: 'tailed', word_class: 'AJ' },
    { word: 'tailing', word_class: 'N' },
    { word: 'tailing', word_class: 'AJ' }
  ],
  hacker: [
    { word: 'hack', word_class: 'N' },
    { word: 'hack', word_class: 'V' },
    { word: 'hackee', word_class: 'N' },
    { word: 'hacker', word_class: 'N' }
  ],
  debar: [
    { word: 'debar', word_class: 'V' },
    { word: 'debarment', word_class: 'N' },
    { word: 'debarring', word_class: 'N' }
  ],
  laminate: [
    { word: 'laminate', word_class: 'N' },
    { word: 'laminate', word_class: 'V' },
    { word: 'laminitis', word_class: 'N' },
    { word: 'lamination', word_class: 'N' }
  ],
  nebulise: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  detractive: [
    { word: 'detract', word_class: 'V' },
    { word: 'detraction', word_class: 'N' },
    { word: 'detractive', word_class: 'AJ' }
  ],
  skimming: [
    { word: 'skim', word_class: 'N' },
    { word: 'skim', word_class: 'V' },
    { word: 'skim', word_class: 'AJ' },
    { word: 'skimmed', word_class: 'AJ' },
    { word: 'skimming', word_class: 'N' }
  ],
  mopping: [{ word: 'mop', word_class: 'N' }, { word: 'mop', word_class: 'V' }, { word: 'mopping', word_class: 'N' }],
  executed: [
    { word: 'execute', word_class: 'V' },
    { word: 'executor', word_class: 'N' },
    { word: 'executant', word_class: 'N' },
    { word: 'execution', word_class: 'N' },
    { word: 'executed', word_class: 'AJ' },
    { word: 'executing', word_class: 'N' },
    { word: 'executive', word_class: 'N' },
    { word: 'executive', word_class: 'AJ' },
    { word: 'executioner', word_class: 'N' }
  ],
  rudeness: [
    { word: 'rude', word_class: 'AJ' },
    { word: 'rudely', word_class: 'AV' },
    { word: 'rudeness', word_class: 'N' }
  ],
  menial: [
    { word: 'menial', word_class: 'N' },
    { word: 'menial', word_class: 'AJ' },
    { word: 'menially', word_class: 'AV' }
  ],
  bowlful: [
    { word: 'bowl', word_class: 'N' },
    { word: 'bowl', word_class: 'V' },
    { word: 'bowls', word_class: 'N' },
    { word: 'bowler', word_class: 'N' },
    { word: 'bowlful', word_class: 'N' },
    { word: 'bowling', word_class: 'N' },
    { word: 'bowlful', word_class: 'AJ' }
  ],
  literal: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  coax: [
    { word: 'coax', word_class: 'N' },
    { word: 'coax', word_class: 'V' },
    { word: 'coaxing', word_class: 'N' },
    { word: 'coaxing', word_class: 'AJ' }
  ],
  chelicerate: [
    { word: 'cheliceral', word_class: 'AJ' },
    { word: 'chelicerate', word_class: 'AJ' },
    { word: 'chelicerous', word_class: 'AJ' }
  ],
  tasting: [
    { word: 'taste', word_class: 'N' },
    { word: 'taste', word_class: 'V' },
    { word: 'tasty', word_class: 'AJ' },
    { word: 'taster', word_class: 'N' },
    { word: 'tasting', word_class: 'N' },
    { word: 'tasteful', word_class: 'AJ' },
    { word: 'tastiness', word_class: 'N' },
    { word: 'tastefulness', word_class: 'N' }
  ],
  guesstimation: [
    { word: 'guesstimate', word_class: 'N' },
    { word: 'guesstimate', word_class: 'V' },
    { word: 'guesstimation', word_class: 'N' }
  ],
  lavishment: [
    { word: 'lavish', word_class: 'V' },
    { word: 'lavish', word_class: 'AJ' },
    { word: 'lavishment', word_class: 'N' },
    { word: 'lavishness', word_class: 'N' }
  ],
  supernatural: [
    { word: 'supernatural', word_class: 'N' },
    { word: 'supernatural', word_class: 'AJ' },
    { word: 'supernaturalism', word_class: 'N' },
    { word: 'supernaturally', word_class: 'AV' },
    { word: 'supernaturalness', word_class: 'N' }
  ],
  selective: [
    { word: 'select', word_class: 'V' },
    { word: 'select', word_class: 'AJ' },
    { word: 'selector', word_class: 'N' },
    { word: 'selected', word_class: 'AJ' },
    { word: 'selection', word_class: 'N' },
    { word: 'selectman', word_class: 'N' },
    { word: 'selective', word_class: 'AJ' },
    { word: 'selectivity', word_class: 'N' },
    { word: 'selectively', word_class: 'AV' }
  ],
  unmanageably: [
    { word: 'unmanageable', word_class: 'AJ' },
    { word: 'unmanageably', word_class: 'AV' },
    { word: 'unmanageableness', word_class: 'N' }
  ],
  waters: [
    { word: 'water', word_class: 'N' },
    { word: 'water', word_class: 'V' },
    { word: 'waters', word_class: 'N' },
    { word: 'watered', word_class: 'AJ' },
    { word: 'watering', word_class: 'N' },
    { word: 'waterman', word_class: 'N' }
  ],
  writhing: [
    { word: 'writhe', word_class: 'N' },
    { word: 'writhe', word_class: 'V' },
    { word: 'writhed', word_class: 'AJ' },
    { word: 'writhing', word_class: 'AJ' }
  ],
  crowding: [
    { word: 'crowd', word_class: 'N' },
    { word: 'crowd', word_class: 'V' },
    { word: 'crowded', word_class: 'AJ' },
    { word: 'crowding', word_class: 'N' }
  ],
  recorder: [
    { word: 'record', word_class: 'N' },
    { word: 'record', word_class: 'V' },
    { word: 'record', word_class: 'AJ' },
    { word: 'recorder', word_class: 'N' },
    { word: 'recording', word_class: 'N' },
    { word: 'recorded', word_class: 'AJ' },
    { word: 'recordation', word_class: 'N' }
  ],
  profit: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  prize: [{ word: 'prize', word_class: 'N' }, { word: 'prize', word_class: 'V' }, { word: 'prize', word_class: 'AJ' }],
  nobility: [
    { word: 'noble', word_class: 'N' },
    { word: 'noble', word_class: 'AJ' },
    { word: 'nobility', word_class: 'N' }
  ],
  singer: [
    { word: 'sing', word_class: 'N' },
    { word: 'sing', word_class: 'V' },
    { word: 'singe', word_class: 'N' },
    { word: 'singe', word_class: 'V' },
    { word: 'singer', word_class: 'N' },
    { word: 'singing', word_class: 'N' },
    { word: 'singing', word_class: 'AJ' }
  ],
  pilfer: [
    { word: 'pilfer', word_class: 'V' },
    { word: 'pilferer', word_class: 'N' },
    { word: 'pilferage', word_class: 'N' }
  ],
  enjoyer: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  biter: [
    { word: 'bite', word_class: 'N' },
    { word: 'bite', word_class: 'V' },
    { word: 'biter', word_class: 'N' },
    { word: 'biting', word_class: 'AJ' },
    { word: 'bitten', word_class: 'AJ' }
  ],
  mugful: [
    { word: 'mug', word_class: 'N' },
    { word: 'mug', word_class: 'V' },
    { word: 'mugful', word_class: 'N' },
    { word: 'mugging', word_class: 'N' }
  ],
  trainee: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  sight: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  backhand: [
    { word: 'backhand', word_class: 'N' },
    { word: 'backhand', word_class: 'V' },
    { word: 'backhand', word_class: 'AJ' },
    { word: 'backhanded', word_class: 'AJ' }
  ],
  numerically: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  faced: [
    { word: 'face', word_class: 'N' },
    { word: 'face', word_class: 'V' },
    { word: 'facer', word_class: 'N' },
    { word: 'faced', word_class: 'AJ' },
    { word: 'facing', word_class: 'N' },
    { word: 'faction', word_class: 'N' }
  ],
  degustate: [
    { word: 'degust', word_class: 'V' },
    { word: 'degustate', word_class: 'V' },
    { word: 'degustation', word_class: 'N' }
  ],
  campion: [
    { word: 'camp', word_class: 'N' },
    { word: 'camp', word_class: 'V' },
    { word: 'camp', word_class: 'AJ' },
    { word: 'camper', word_class: 'N' },
    { word: 'camping', word_class: 'N' },
    { word: 'campion', word_class: 'N' }
  ],
  divider: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  darraign: [
    { word: 'deraign', word_class: 'V' },
    { word: 'darraign', word_class: 'V' },
    { word: 'deraignment', word_class: 'N' },
    { word: 'darraignment', word_class: 'N' }
  ],
  actualise: [
    { word: 'actual', word_class: 'AJ' },
    { word: 'actualise', word_class: 'V' },
    { word: 'actuality', word_class: 'N' },
    { word: 'actualize', word_class: 'V' },
    { word: 'actually', word_class: 'AV' },
    { word: 'actualized', word_class: 'AJ' },
    { word: 'actualisation', word_class: 'N' },
    { word: 'actualization', word_class: 'N' }
  ],
  confounded: [
    { word: 'confound', word_class: 'V' },
    { word: 'confounded', word_class: 'AJ' },
    { word: 'confounding', word_class: 'N' },
    { word: 'confounding', word_class: 'AJ' }
  ],
  confuting: [
    { word: 'confute', word_class: 'V' },
    { word: 'confuting', word_class: 'N' },
    { word: 'confutation', word_class: 'N' }
  ],
  canalise: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  grasping: [
    { word: 'grasp', word_class: 'N' },
    { word: 'grasp', word_class: 'V' },
    { word: 'grasping', word_class: 'N' },
    { word: 'grasping', word_class: 'AJ' }
  ],
  canker: [
    { word: 'canker', word_class: 'N' },
    { word: 'canker', word_class: 'V' },
    { word: 'cankerous', word_class: 'AJ' }
  ],
  ac: [
    { word: 'ac', word_class: 'N' },
    { word: 'ace', word_class: 'N' },
    { word: 'ace', word_class: 'V' },
    { word: 'ace', word_class: 'AJ' }
  ],
  germinate: [
    { word: 'germ', word_class: 'N' },
    { word: 'germinal', word_class: 'N' },
    { word: 'germinal', word_class: 'AJ' },
    { word: 'germinate', word_class: 'V' },
    { word: 'germination', word_class: 'N' }
  ],
  outcropping: [
    { word: 'outcrop', word_class: 'N' },
    { word: 'outcrop', word_class: 'V' },
    { word: 'outcropping', word_class: 'N' }
  ],
  suburbanized: [
    { word: 'suburban', word_class: 'AJ' },
    { word: 'suburbanize', word_class: 'V' },
    { word: 'suburbanized', word_class: 'AJ' }
  ],
  lubricant: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  synthetization: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  revocably: [
    { word: 'revoke', word_class: 'N' },
    { word: 'revoke', word_class: 'V' },
    { word: 'revocable', word_class: 'N' },
    { word: 'revocable', word_class: 'AJ' },
    { word: 'revocably', word_class: 'AV' },
    { word: 'revocation', word_class: 'N' },
    { word: 'revokable', word_class: 'AJ' },
    { word: 'revocation', word_class: 'AJ' },
    { word: 'revocability', word_class: 'N' },
    { word: 'revocability', word_class: 'AJ' }
  ],
  alchemization: [
    { word: 'alchemic', word_class: 'AJ' },
    { word: 'alchemize', word_class: 'V' },
    { word: 'alchemical', word_class: 'AJ' },
    { word: 'alchemization', word_class: 'N' }
  ],
  padding: [
    { word: 'pad', word_class: 'N' },
    { word: 'pad', word_class: 'V' },
    { word: 'padded', word_class: 'AJ' },
    { word: 'padding', word_class: 'N' }
  ],
  descend: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  aggress: [
    { word: 'aggress', word_class: 'V' },
    { word: 'aggression', word_class: 'N' },
    { word: 'aggressive', word_class: 'AJ' },
    { word: 'aggressively', word_class: 'AV' },
    { word: 'aggressiveness', word_class: 'N' }
  ],
  preachy: [
    { word: 'preach', word_class: 'V' },
    { word: 'preacher', word_class: 'N' },
    { word: 'preachy', word_class: 'AJ' },
    { word: 'preaching', word_class: 'N' },
    { word: 'preachment', word_class: 'N' }
  ],
  recusant: [
    { word: 'recuse', word_class: 'V' },
    { word: 'recusal', word_class: 'N' },
    { word: 'recusant', word_class: 'N' },
    { word: 'recusancy', word_class: 'N' },
    { word: 'recusant', word_class: 'AJ' },
    { word: 'recusation', word_class: 'N' }
  ],
  melody: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  atypicality: [
    { word: 'atypic', word_class: 'AJ' },
    { word: 'atypical', word_class: 'AJ' },
    { word: 'atypicality', word_class: 'N' },
    { word: 'atypically', word_class: 'AV' }
  ],
  racist: [
    { word: 'race', word_class: 'N' },
    { word: 'race', word_class: 'V' },
    { word: 'racer', word_class: 'N' },
    { word: 'racism', word_class: 'N' },
    { word: 'racing', word_class: 'N' },
    { word: 'racist', word_class: 'N' },
    { word: 'racist', word_class: 'AJ' }
  ],
  impalempale: [
    { word: 'impale', word_class: 'V' },
    { word: 'impale', word_class: 'AJ' },
    { word: 'impalement', word_class: 'N' },
    { word: 'impalempale', word_class: 'V' },
    { word: 'impalempalement', word_class: 'N' }
  ],
  tankage: [
    { word: 'tank', word_class: 'N' },
    { word: 'tank', word_class: 'V' },
    { word: 'tankage', word_class: 'N' },
    { word: 'tankful', word_class: 'N' },
    { word: 'tankful', word_class: 'AJ' }
  ],
  imbricate: [
    { word: 'imbricate', word_class: 'V' },
    { word: 'imbricate', word_class: 'AJ' },
    { word: 'imbricated', word_class: 'AJ' },
    { word: 'imbrication', word_class: 'N' }
  ],
  watered: [
    { word: 'water', word_class: 'N' },
    { word: 'water', word_class: 'V' },
    { word: 'waters', word_class: 'N' },
    { word: 'watered', word_class: 'AJ' },
    { word: 'watering', word_class: 'N' },
    { word: 'waterman', word_class: 'N' }
  ],
  shortage: [
    { word: 'short', word_class: 'N' },
    { word: 'short', word_class: 'V' },
    { word: 'short', word_class: 'AJ' },
    { word: 'short', word_class: 'AV' },
    { word: 'shorts', word_class: 'N' },
    { word: 'shortage', word_class: 'N' },
    { word: 'shortness', word_class: 'N' }
  ],
  aged: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  breadwinner: [
    { word: 'breadwin', word_class: 'V' },
    { word: 'breadwinner', word_class: 'N' },
    { word: 'breadwinning', word_class: 'N' }
  ],
  typographic: [
    { word: 'typographer', word_class: 'N' },
    { word: 'typographic', word_class: 'AJ' },
    { word: 'typographical', word_class: 'AJ' },
    { word: 'typographically', word_class: 'AV' }
  ],
  incurably: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  stroll: [
    { word: 'stroll', word_class: 'N' },
    { word: 'stroll', word_class: 'V' },
    { word: 'stroller', word_class: 'N' }
  ],
  indecorous: [
    { word: 'indecorous', word_class: 'AJ' },
    { word: 'indecorously', word_class: 'AV' },
    { word: 'indecorousness', word_class: 'N' }
  ],
  knitting: [
    { word: 'knit', word_class: 'N' },
    { word: 'knit', word_class: 'V' },
    { word: 'knitted', word_class: 'AJ' },
    { word: 'knitting', word_class: 'N' }
  ],
  stunting: [
    { word: 'stunt', word_class: 'N' },
    { word: 'stunt', word_class: 'V' },
    { word: 'stunted', word_class: 'AJ' },
    { word: 'stunting', word_class: 'N' }
  ],
  imperfectness: [
    { word: 'imperfect', word_class: 'N' },
    { word: 'imperfect', word_class: 'AJ' },
    { word: 'imperfection', word_class: 'N' },
    { word: 'imperfective', word_class: 'N' },
    { word: 'imperfectness', word_class: 'N' },
    { word: 'imperfectability', word_class: 'N' },
    { word: 'imperfectibility', word_class: 'N' }
  ],
  syllogise: [
    { word: 'syllogise', word_class: 'V' },
    { word: 'syllogism', word_class: 'N' },
    { word: 'syllogize', word_class: 'V' },
    { word: 'syllogisation', word_class: 'N' },
    { word: 'syllogization', word_class: 'N' }
  ],
  intelligently: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  impure: [
    { word: 'impure', word_class: 'AJ' },
    { word: 'impurity', word_class: 'N' },
    { word: 'impureness', word_class: 'N' }
  ],
  adductive: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  coddled: [
    { word: 'coddle', word_class: 'V' },
    { word: 'codling', word_class: 'N' },
    { word: 'coddled', word_class: 'AJ' }
  ],
  harvestman: [
    { word: 'harvest', word_class: 'N' },
    { word: 'harvest', word_class: 'V' },
    { word: 'harvester', word_class: 'N' },
    { word: 'harvesting', word_class: 'N' },
    { word: 'harvestman', word_class: 'N' }
  ],
  inauspiciously: [
    { word: 'inauspicious', word_class: 'AJ' },
    { word: 'inauspiciously', word_class: 'AV' },
    { word: 'inauspiciousness', word_class: 'N' }
  ],
  inopportune: [
    { word: 'inopportune', word_class: 'AJ' },
    { word: 'inopportunely', word_class: 'AV' },
    { word: 'inopportuneness', word_class: 'N' }
  ],
  cynically: [
    { word: 'cynic', word_class: 'N' },
    { word: 'cynical', word_class: 'AJ' },
    { word: 'cynicism', word_class: 'N' },
    { word: 'cynically', word_class: 'AV' }
  ],
  amalgam: [
    { word: 'amalgam', word_class: 'N' },
    { word: 'amalgamate', word_class: 'V' },
    { word: 'amalgamate', word_class: 'AJ' },
    { word: 'amalgamated', word_class: 'AJ' },
    { word: 'amalgamation', word_class: 'N' },
    { word: 'amalgamative', word_class: 'AJ' }
  ],
  failing: [
    { word: 'fail', word_class: 'V' },
    { word: 'failed', word_class: 'AJ' },
    { word: 'failing', word_class: 'N' },
    { word: 'failure', word_class: 'N' },
    { word: 'failing', word_class: 'AJ' }
  ],
  engineer: [
    { word: 'engine', word_class: 'N' },
    { word: 'engineer', word_class: 'N' },
    { word: 'engineer', word_class: 'V' },
    { word: 'engineering', word_class: 'N' }
  ],
  clean: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  antagonism: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  trustfulness: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  licensed: [
    { word: 'license', word_class: 'N' },
    { word: 'license', word_class: 'V' },
    { word: 'licensee', word_class: 'N' },
    { word: 'licensed', word_class: 'AJ' }
  ],
  eulogization: [
    { word: 'eulogy', word_class: 'N' },
    { word: 'eulogist', word_class: 'N' },
    { word: 'eulogize', word_class: 'V' },
    { word: 'eulogistic', word_class: 'AJ' },
    { word: 'eulogization', word_class: 'N' }
  ],
  inject: [
    { word: 'inject', word_class: 'V' },
    { word: 'injectant', word_class: 'N' },
    { word: 'injection', word_class: 'N' },
    { word: 'injectable', word_class: 'AJ' }
  ],
  distinctively: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  trainman: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  liquidization: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  broached: [
    { word: 'breach', word_class: 'N' },
    { word: 'breach', word_class: 'V' },
    { word: 'broach', word_class: 'N' },
    { word: 'broach', word_class: 'V' },
    { word: 'breached', word_class: 'AJ' },
    { word: 'broached', word_class: 'AJ' }
  ],
  misanthropical: [
    { word: 'misanthrope', word_class: 'N' },
    { word: 'misanthropic', word_class: 'AJ' },
    { word: 'misanthropical', word_class: 'AJ' }
  ],
  seance: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  cup: [
    { word: 'cup', word_class: 'N' },
    { word: 'cup', word_class: 'V' },
    { word: 'cupful', word_class: 'N' },
    { word: 'cupful', word_class: 'AJ' },
    { word: 'cupping', word_class: 'N' }
  ],
  operate: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  repellant: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  camphor: [
    { word: 'camphor', word_class: 'N' },
    { word: 'camphorate', word_class: 'V' },
    { word: 'camphoric', word_class: 'AJ' },
    { word: 'camphorated', word_class: 'AJ' },
    { word: 'camphoration', word_class: 'N' }
  ],
  immunological: [
    { word: 'immunology', word_class: 'N' },
    { word: 'immunologic', word_class: 'AJ' },
    { word: 'immunological', word_class: 'AJ' }
  ],
  personate: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  blooded: [
    { word: 'blood', word_class: 'N' },
    { word: 'blood', word_class: 'V' },
    { word: 'bloody', word_class: 'V' },
    { word: 'bloody', word_class: 'AJ' },
    { word: 'bloody', word_class: 'AV' },
    { word: 'blooded', word_class: 'AJ' },
    { word: 'bloodied', word_class: 'AJ' }
  ],
  passivity: [
    { word: 'passive', word_class: 'N' },
    { word: 'passive', word_class: 'AJ' },
    { word: 'passivism', word_class: 'N' },
    { word: 'passivity', word_class: 'N' },
    { word: 'passively', word_class: 'AV' },
    { word: 'passiveness', word_class: 'N' }
  ],
  clap: [{ word: 'clap', word_class: 'N' }, { word: 'clap', word_class: 'V' }, { word: 'clapping', word_class: 'N' }],
  embrace: [
    { word: 'embrace', word_class: 'N' },
    { word: 'embrace', word_class: 'V' },
    { word: 'embracing', word_class: 'N' },
    { word: 'embracement', word_class: 'N' }
  ],
  permute: [
    { word: 'permute', word_class: 'V' },
    { word: 'permutation', word_class: 'N' },
    { word: 'permutability', word_class: 'N' },
    { word: 'permutableness', word_class: 'N' }
  ],
  frustrated: [
    { word: 'frustrate', word_class: 'V' },
    { word: 'frustrated', word_class: 'AJ' },
    { word: 'frustrating', word_class: 'N' },
    { word: 'frustration', word_class: 'N' },
    { word: 'frustrating', word_class: 'AJ' }
  ],
  pureblood: [
    { word: 'pureblood', word_class: 'N' },
    { word: 'pureblood', word_class: 'AJ' },
    { word: 'pureblooded', word_class: 'AJ' }
  ],
  mormonism: [
    { word: 'mormon', word_class: 'N' },
    { word: 'mormon', word_class: 'AJ' },
    { word: 'mormons', word_class: 'N' },
    { word: 'mormonism', word_class: 'N' }
  ],
  coextend: [
    { word: 'coextend', word_class: 'V' },
    { word: 'coextension', word_class: 'N' },
    { word: 'coextensive', word_class: 'AJ' }
  ],
  spectrographic: [
    { word: 'spectrograph', word_class: 'N' },
    { word: 'spectrographic', word_class: 'AJ' },
    { word: 'spectrographically', word_class: 'AV' }
  ],
  favourable: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  medication: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  embed: [
    { word: 'embed', word_class: 'V' },
    { word: 'imbed', word_class: 'N' },
    { word: 'imbed', word_class: 'V' },
    { word: 'embedded', word_class: 'AJ' },
    { word: 'embedment', word_class: 'N' },
    { word: 'imbedment', word_class: 'N' }
  ],
  canonical: [
    { word: 'canon', word_class: 'N' },
    { word: 'canonise', word_class: 'V' },
    { word: 'canonic', word_class: 'AJ' },
    { word: 'canonize', word_class: 'V' },
    { word: 'canonist', word_class: 'N' },
    { word: 'canonist', word_class: 'AJ' },
    { word: 'canonical', word_class: 'AJ' },
    { word: 'canonized', word_class: 'AJ' },
    { word: 'canonisation', word_class: 'N' },
    { word: 'canonization', word_class: 'N' }
  ],
  neoliberalism: [
    { word: 'neoliberal', word_class: 'N' },
    { word: 'neoliberal', word_class: 'AJ' },
    { word: 'neoliberalism', word_class: 'N' }
  ],
  intimately: [
    { word: 'intimacy', word_class: 'N' },
    { word: 'intimate', word_class: 'N' },
    { word: 'intimal', word_class: 'AJ' },
    { word: 'intimate', word_class: 'V' },
    { word: 'intimate', word_class: 'AJ' },
    { word: 'intimation', word_class: 'N' },
    { word: 'intimately', word_class: 'AV' }
  ],
  decreased: [
    { word: 'decrease', word_class: 'N' },
    { word: 'decrease', word_class: 'V' },
    { word: 'decreased', word_class: 'AJ' },
    { word: 'decreasing', word_class: 'AJ' }
  ],
  gosling: [
    { word: 'goose', word_class: 'N' },
    { word: 'goose', word_class: 'V' },
    { word: 'gosling', word_class: 'N' }
  ],
  recliner: [
    { word: 'recline', word_class: 'N' },
    { word: 'recline', word_class: 'V' },
    { word: 'recliner', word_class: 'N' },
    { word: 'reclining', word_class: 'N' },
    { word: 'reclining', word_class: 'AJ' },
    { word: 'reclination', word_class: 'N' }
  ],
  steady: [
    { word: 'steady', word_class: 'N' },
    { word: 'steady', word_class: 'V' },
    { word: 'steady', word_class: 'AJ' },
    { word: 'steady', word_class: 'AV' },
    { word: 'steadied', word_class: 'AJ' },
    { word: 'steadiness', word_class: 'N' },
    { word: 'steadying', word_class: 'AJ' }
  ],
  patient: [
    { word: 'patient', word_class: 'N' },
    { word: 'patience', word_class: 'N' },
    { word: 'patient', word_class: 'AJ' },
    { word: 'patiently', word_class: 'AV' }
  ],
  flail: [
    { word: 'flail', word_class: 'N' },
    { word: 'flail', word_class: 'V' },
    { word: 'flailing', word_class: 'AJ' }
  ],
  symbolist: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  disadvantageously: [
    { word: 'disadvantage', word_class: 'N' },
    { word: 'disadvantage', word_class: 'V' },
    { word: 'disadvantaged', word_class: 'AJ' },
    { word: 'disadvantageous', word_class: 'AJ' },
    { word: 'disadvantageously', word_class: 'AV' }
  ],
  twilled: [
    { word: 'twill', word_class: 'N' },
    { word: 'twill', word_class: 'V' },
    { word: 'twill', word_class: 'AJ' },
    { word: 'twilled', word_class: 'AJ' }
  ],
  physics: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  capableness: [
    { word: 'cabable', word_class: 'AJ' },
    { word: 'capable', word_class: 'AJ' },
    { word: 'capably', word_class: 'AV' },
    { word: 'capability', word_class: 'N' },
    { word: 'capableness', word_class: 'N' }
  ],
  ascertained: [
    { word: 'ascertain', word_class: 'V' },
    { word: 'ascertained', word_class: 'AJ' },
    { word: 'ascertainment', word_class: 'N' },
    { word: 'ascertainable', word_class: 'AJ' }
  ],
  exhibit: [
    { word: 'exhibit', word_class: 'N' },
    { word: 'exhibit', word_class: 'V' },
    { word: 'exhibitor', word_class: 'N' },
    { word: 'exhibition', word_class: 'N' }
  ],
  crimp: [{ word: 'crimp', word_class: 'N' }, { word: 'crimp', word_class: 'V' }, { word: 'crimper', word_class: 'N' }],
  soused: [
    { word: 'souse', word_class: 'N' },
    { word: 'souse', word_class: 'V' },
    { word: 'soused', word_class: 'AJ' },
    { word: 'sousing', word_class: 'N' }
  ],
  monopolise: [
    { word: 'monoply', word_class: 'N' },
    { word: 'monopoly', word_class: 'N' },
    { word: 'monopolise', word_class: 'V' },
    { word: 'monopolist', word_class: 'N' },
    { word: 'monopolize', word_class: 'V' },
    { word: 'monopolizer', word_class: 'N' },
    { word: 'monopolistic', word_class: 'AJ' },
    { word: 'monopolisation', word_class: 'N' },
    { word: 'monopolization', word_class: 'N' }
  ],
  disillusioned: [
    { word: 'disillusion', word_class: 'N' },
    { word: 'disillusion', word_class: 'V' },
    { word: 'disillusioned', word_class: 'AJ' },
    { word: 'disillusioning', word_class: 'AJ' },
    { word: 'disillusionment', word_class: 'N' }
  ],
  heat: [
    { word: 'heat', word_class: 'N' },
    { word: 'heat', word_class: 'V' },
    { word: 'heater', word_class: 'N' },
    { word: 'heated', word_class: 'AJ' },
    { word: 'heating', word_class: 'N' },
    { word: 'heating', word_class: 'AJ' }
  ],
  perfidious: [
    { word: 'perfidy', word_class: 'N' },
    { word: 'perfidious', word_class: 'AJ' },
    { word: 'perfidiously', word_class: 'AV' },
    { word: 'perfidiousness', word_class: 'N' }
  ],
  swish: [
    { word: 'swish', word_class: 'N' },
    { word: 'swish', word_class: 'V' },
    { word: 'swish', word_class: 'AJ' },
    { word: 'swishy', word_class: 'AJ' },
    { word: 'swishing', word_class: 'AJ' }
  ],
  fringed: [
    { word: 'fringe', word_class: 'N' },
    { word: 'fringe', word_class: 'V' },
    { word: 'fringe', word_class: 'AJ' },
    { word: 'fringed', word_class: 'AJ' }
  ],
  topical: [
    { word: 'topic', word_class: 'N' },
    { word: 'topical', word_class: 'AJ' },
    { word: 'topically', word_class: 'AV' }
  ],
  splicing: [
    { word: 'splice', word_class: 'N' },
    { word: 'splice', word_class: 'V' },
    { word: 'splicer', word_class: 'N' },
    { word: 'splicing', word_class: 'N' }
  ],
  darned: [
    { word: 'darn', word_class: 'N' },
    { word: 'darn', word_class: 'V' },
    { word: 'darned', word_class: 'AJ' },
    { word: 'darning', word_class: 'N' }
  ],
  hypersensitive: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  immaturity: [
    { word: 'immature', word_class: 'AJ' },
    { word: 'immaturity', word_class: 'N' },
    { word: 'immaturely', word_class: 'AV' },
    { word: 'immatureness', word_class: 'N' }
  ],
  incredulity: [
    { word: 'incredulity', word_class: 'N' },
    { word: 'incredulous', word_class: 'AJ' },
    { word: 'incredulously', word_class: 'AV' }
  ],
  arousal: [
    { word: 'arouse', word_class: 'V' },
    { word: 'arousal', word_class: 'N' },
    { word: 'aroused', word_class: 'AJ' }
  ],
  rhetorically: [
    { word: 'rhetoric', word_class: 'N' },
    { word: 'rhetorical', word_class: 'AJ' },
    { word: 'rhetorically', word_class: 'AV' }
  ],
  reasoning: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  immorally: [
    { word: 'immoral', word_class: 'AJ' },
    { word: 'immorality', word_class: 'N' },
    { word: 'immorally', word_class: 'AV' }
  ],
  feminisation: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  depersonalise: [
    { word: 'depersonalise', word_class: 'V' },
    { word: 'depersonalize', word_class: 'V' },
    { word: 'depersonalisation', word_class: 'N' },
    { word: 'depersonalization', word_class: 'N' }
  ],
  take: [
    { word: 'take', word_class: 'N' },
    { word: 'take', word_class: 'V' },
    { word: 'taker', word_class: 'N' },
    { word: 'taken', word_class: 'AJ' },
    { word: 'taking', word_class: 'N' },
    { word: 'taking', word_class: 'AJ' },
    { word: 'takings', word_class: 'N' }
  ],
  atmospherics: [
    { word: 'atmosphere', word_class: 'N' },
    { word: 'atmospheric', word_class: 'AJ' },
    { word: 'atmospherics', word_class: 'N' },
    { word: 'atmospherical', word_class: 'AJ' }
  ],
  entertainer: [
    { word: 'entertain', word_class: 'V' },
    { word: 'entertainer', word_class: 'N' },
    { word: 'entertained', word_class: 'AJ' },
    { word: 'entertaining', word_class: 'AJ' },
    { word: 'entertainment', word_class: 'N' }
  ],
  bacteria: [
    { word: 'bacteria', word_class: 'N' },
    { word: 'bacterial', word_class: 'AJ' },
    { word: 'bacterially', word_class: 'AV' }
  ],
  transverse: [
    { word: 'transverse', word_class: 'N' },
    { word: 'transverse', word_class: 'V' },
    { word: 'transversal', word_class: 'N' },
    { word: 'transverse', word_class: 'AJ' },
    { word: 'transversal', word_class: 'AJ' },
    { word: 'transversely', word_class: 'AV' },
    { word: 'transversally', word_class: 'AV' }
  ],
  times: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  elevated: [
    { word: 'elevate', word_class: 'V' },
    { word: 'elevator', word_class: 'N' },
    { word: 'elevated', word_class: 'AJ' },
    { word: 'elevation', word_class: 'N' }
  ],
  luminesce: [
    { word: 'luminesce', word_class: 'V' },
    { word: 'luminescence', word_class: 'N' },
    { word: 'luminescent', word_class: 'AJ' }
  ],
  conservative: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  sweeper: [
    { word: 'sweep', word_class: 'N' },
    { word: 'sweep', word_class: 'V' },
    { word: 'sweeper', word_class: 'N' },
    { word: 'sweeping', word_class: 'N' },
    { word: 'sweeping', word_class: 'AJ' }
  ],
  assume: [
    { word: 'assume', word_class: 'V' },
    { word: 'assumed', word_class: 'AJ' },
    { word: 'assuming', word_class: 'AJ' },
    { word: 'assumption', word_class: 'N' },
    { word: 'assumptive', word_class: 'AJ' }
  ],
  cauterize: [
    { word: 'cauterise', word_class: 'V' },
    { word: 'cauterize', word_class: 'V' },
    { word: 'cauterisation', word_class: 'N' },
    { word: 'cauterization', word_class: 'N' }
  ],
  inflator: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  prorogue: [
    { word: 'prorogue', word_class: 'N' },
    { word: 'prorogue', word_class: 'V' },
    { word: 'prorogation', word_class: 'N' }
  ],
  dignification: [
    { word: 'dignify', word_class: 'V' },
    { word: 'dignified', word_class: 'AJ' },
    { word: 'dignifying', word_class: 'AJ' },
    { word: 'dignification', word_class: 'N' }
  ],
  harass: [
    { word: 'harass', word_class: 'V' },
    { word: 'harassed', word_class: 'AJ' },
    { word: 'harassment', word_class: 'N' }
  ],
  turban: [
    { word: 'turban', word_class: 'N' },
    { word: 'turbaned', word_class: 'AJ' },
    { word: 'turbanned', word_class: 'AJ' }
  ],
  lie: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  grating: [
    { word: 'grate', word_class: 'N' },
    { word: 'grate', word_class: 'V' },
    { word: 'grater', word_class: 'N' },
    { word: 'grating', word_class: 'N' },
    { word: 'grating', word_class: 'AJ' },
    { word: 'grateful', word_class: 'AJ' },
    { word: 'gratefulness', word_class: 'N' }
  ],
  little: [
    { word: 'little', word_class: 'N' },
    { word: 'little', word_class: 'AJ' },
    { word: 'little', word_class: 'AV' },
    { word: 'littleness', word_class: 'N' }
  ],
  squalling: [
    { word: 'squall', word_class: 'N' },
    { word: 'squall', word_class: 'V' },
    { word: 'squalling', word_class: 'AJ' }
  ],
  inflate: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  timidity: [
    { word: 'timid', word_class: 'N' },
    { word: 'timid', word_class: 'AJ' },
    { word: 'timidity', word_class: 'N' },
    { word: 'timidness', word_class: 'N' }
  ],
  eponymous: [
    { word: 'eponym', word_class: 'N' },
    { word: 'eponymic', word_class: 'AJ' },
    { word: 'eponymous', word_class: 'AJ' }
  ],
  extravagant: [
    { word: 'extravagate', word_class: 'V' },
    { word: 'extravagance', word_class: 'N' },
    { word: 'extravagant', word_class: 'AJ' },
    { word: 'extravagation', word_class: 'N' }
  ],
  caesarean: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  crusted: [
    { word: 'crust', word_class: 'N' },
    { word: 'crust', word_class: 'V' },
    { word: 'crusted', word_class: 'AJ' }
  ],
  distinguish: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  tarmac: [
    { word: 'tarmac', word_class: 'N' },
    { word: 'tarmac', word_class: 'V' },
    { word: 'tarmac', word_class: 'AJ' }
  ],
  dreadfulness: [
    { word: 'dread', word_class: 'N' },
    { word: 'dread', word_class: 'V' },
    { word: 'dread', word_class: 'AJ' },
    { word: 'dreaded', word_class: 'AJ' },
    { word: 'dreadful', word_class: 'AJ' },
    { word: 'dreadfulness', word_class: 'N' }
  ],
  treasonous: [
    { word: 'treason', word_class: 'N' },
    { word: 'treasonous', word_class: 'AJ' },
    { word: 'treasonable', word_class: 'AJ' },
    { word: 'treasonably', word_class: 'AV' }
  ],
  diffused: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  demonstrable: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  etherealize: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  table: [
    { word: 'table', word_class: 'N' },
    { word: 'table', word_class: 'V' },
    { word: 'tableful', word_class: 'N' },
    { word: 'tabular', word_class: 'AJ' },
    { word: 'tableful', word_class: 'AJ' },
    { word: 'tabularize', word_class: 'V' }
  ],
  burred: [
    { word: 'bur', word_class: 'N' },
    { word: 'bur', word_class: 'V' },
    { word: 'burr', word_class: 'N' },
    { word: 'burr', word_class: 'V' },
    { word: 'burred', word_class: 'AJ' },
    { word: 'burring', word_class: 'N' }
  ],
  educator: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  anthologization: [
    { word: 'anthology', word_class: 'N' },
    { word: 'anthologize', word_class: 'V' },
    { word: 'anthologization', word_class: 'N' }
  ],
  provencal: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  finite: [
    { word: 'finite', word_class: 'N' },
    { word: 'finite', word_class: 'AJ' },
    { word: 'finitely', word_class: 'AV' },
    { word: 'finiteness', word_class: 'N' }
  ],
  gloom: [
    { word: 'gloom', word_class: 'N' },
    { word: 'gloomful', word_class: 'AJ' },
    { word: 'glooming', word_class: 'AJ' }
  ],
  milker: [
    { word: 'milk', word_class: 'N' },
    { word: 'milk', word_class: 'V' },
    { word: 'milk', word_class: 'AJ' },
    { word: 'milker', word_class: 'N' },
    { word: 'milkman', word_class: 'N' }
  ],
  bivalve: [
    { word: 'bivalve', word_class: 'N' },
    { word: 'bivalve', word_class: 'AJ' },
    { word: 'bivalved', word_class: 'AJ' }
  ],
  undecomposable: [
    { word: 'undecomposed', word_class: 'AJ' },
    { word: 'undecomposable', word_class: 'AJ' },
    { word: 'undecomposability', word_class: 'N' }
  ],
  belching: [
    { word: 'belch', word_class: 'N' },
    { word: 'belch', word_class: 'V' },
    { word: 'belching', word_class: 'N' }
  ],
  mutely: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  salvageable: [
    { word: 'salvage', word_class: 'N' },
    { word: 'salvage', word_class: 'V' },
    { word: 'salvager', word_class: 'N' },
    { word: 'salvageable', word_class: 'AJ' }
  ],
  recrystallisation: [
    { word: 'recrystallise', word_class: 'V' },
    { word: 'recrystallize', word_class: 'V' },
    { word: 'recrystallisation', word_class: 'N' },
    { word: 'recrystallization', word_class: 'N' }
  ],
  locally: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  clocking: [
    { word: 'clock', word_class: 'N' },
    { word: 'clock', word_class: 'V' },
    { word: 'clocks', word_class: 'N' },
    { word: 'clocking', word_class: 'N' }
  ],
  cosmetics: [
    { word: 'cosmetic', word_class: 'N' },
    { word: 'cosmetic', word_class: 'AJ' },
    { word: 'cosmetics', word_class: 'N' },
    { word: 'cosmetically', word_class: 'AV' }
  ],
  reach: [
    { word: 'reach', word_class: 'N' },
    { word: 'reach', word_class: 'V' },
    { word: 'reaching', word_class: 'N' }
  ],
  approbative: [
    { word: 'approbate', word_class: 'V' },
    { word: 'approbation', word_class: 'N' },
    { word: 'approbative', word_class: 'AJ' }
  ],
  orthopedical: [
    { word: 'orthopedic', word_class: 'AJ' },
    { word: 'orthopedics', word_class: 'N' },
    { word: 'orthopedical', word_class: 'AJ' }
  ],
  polyphonous: [
    { word: 'polyphone', word_class: 'N' },
    { word: 'polyphonic', word_class: 'AJ' },
    { word: 'polyphonous', word_class: 'AJ' },
    { word: 'polyphonically', word_class: 'AV' }
  ],
  finished: [
    { word: 'finish', word_class: 'N' },
    { word: 'finish', word_class: 'V' },
    { word: 'finish', word_class: 'AJ' },
    { word: 'finisher', word_class: 'N' },
    { word: 'finished', word_class: 'AJ' },
    { word: 'finishing', word_class: 'N' }
  ],
  demean: [
    { word: 'demean', word_class: 'V' },
    { word: 'demeanor', word_class: 'N' },
    { word: 'demeaning', word_class: 'AJ' }
  ],
  felicitously: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  morphophoneme: [
    { word: 'morphophoneme', word_class: 'N' },
    { word: 'morphophonemic', word_class: 'AJ' },
    { word: 'morphophonemics', word_class: 'N' }
  ],
  arrayal: [
    { word: 'array', word_class: 'N' },
    { word: 'array', word_class: 'V' },
    { word: 'arrayal', word_class: 'N' },
    { word: 'arrayed', word_class: 'AJ' }
  ],
  explanatory: [
    { word: 'explain', word_class: 'N' },
    { word: 'explain', word_class: 'V' },
    { word: 'explain', word_class: 'AJ' },
    { word: 'explanation', word_class: 'N' },
    { word: 'explainable', word_class: 'AJ' },
    { word: 'explanatory', word_class: 'AJ' }
  ],
  impersonate: [
    { word: 'impersonal', word_class: 'AJ' },
    { word: 'impersonate', word_class: 'V' },
    { word: 'impersonator', word_class: 'N' },
    { word: 'impersonalise', word_class: 'V' },
    { word: 'impersonalize', word_class: 'V' },
    { word: 'impersonation', word_class: 'N' },
    { word: 'impersonally', word_class: 'AV' },
    { word: 'impersonalisation', word_class: 'N' },
    { word: 'impersonalization', word_class: 'N' }
  ],
  cesarian: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  stowing: [
    { word: 'stow', word_class: 'V' },
    { word: 'stowage', word_class: 'N' },
    { word: 'stowing', word_class: 'N' }
  ],
  effeminateness: [
    { word: 'effeminacy', word_class: 'N' },
    { word: 'effeminate', word_class: 'V' },
    { word: 'effeminize', word_class: 'V' },
    { word: 'effeminate', word_class: 'AJ' },
    { word: 'effeminateness', word_class: 'N' }
  ],
  poker: [
    { word: 'poke', word_class: 'N' },
    { word: 'poke', word_class: 'V' },
    { word: 'poker', word_class: 'N' },
    { word: 'poking', word_class: 'N' }
  ],
  sandal: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  vie: [{ word: 'vie', word_class: 'V' }, { word: 'vial', word_class: 'N' }, { word: 'vying', word_class: 'V' }],
  spiral: [
    { word: 'spiral', word_class: 'N' },
    { word: 'spiral', word_class: 'V' },
    { word: 'spiral', word_class: 'AJ' },
    { word: 'spirally', word_class: 'AV' },
    { word: 'spiraling', word_class: 'AJ' }
  ],
  grant: [
    { word: 'grant', word_class: 'N' },
    { word: 'grant', word_class: 'V' },
    { word: 'grantee', word_class: 'N' },
    { word: 'grantor', word_class: 'N' },
    { word: 'granted', word_class: 'AJ' }
  ],
  perversely: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  scolder: [
    { word: 'scold', word_class: 'N' },
    { word: 'scold', word_class: 'V' },
    { word: 'scolder', word_class: 'N' },
    { word: 'scolding', word_class: 'N' },
    { word: 'scolding', word_class: 'AJ' }
  ],
  infernal: [
    { word: 'infernal', word_class: 'N' },
    { word: 'infernal', word_class: 'AJ' },
    { word: 'infernally', word_class: 'AV' }
  ],
  orange: [
    { word: 'orang', word_class: 'N' },
    { word: 'orange', word_class: 'N' },
    { word: 'orange', word_class: 'AJ' },
    { word: 'orangy', word_class: 'AJ' },
    { word: 'orangeness', word_class: 'N' }
  ],
  acerb: [
    { word: 'acerb', word_class: 'AJ' },
    { word: 'acerbate', word_class: 'V' },
    { word: 'acerbic', word_class: 'AJ' },
    { word: 'acerbity', word_class: 'N' },
    { word: 'acerbation', word_class: 'N' }
  ],
  merchandise: [
    { word: 'merchandise', word_class: 'N' },
    { word: 'merchandise', word_class: 'V' },
    { word: 'merchandiser', word_class: 'N' },
    { word: 'merchandising', word_class: 'N' },
    { word: 'merchandisement', word_class: 'N' }
  ],
  primacy: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  mergence: [
    { word: 'merge', word_class: 'V' },
    { word: 'merger', word_class: 'N' },
    { word: 'merged', word_class: 'AJ' },
    { word: 'merging', word_class: 'N' },
    { word: 'mergence', word_class: 'N' },
    { word: 'merging', word_class: 'AJ' }
  ],
  disputant: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  wooden: [
    { word: 'wood', word_class: 'N' },
    { word: 'wood', word_class: 'AJ' },
    { word: 'woods', word_class: 'N' },
    { word: 'woods', word_class: 'AJ' },
    { word: 'wooded', word_class: 'AJ' },
    { word: 'wooden', word_class: 'AJ' },
    { word: 'woodsy', word_class: 'AJ' }
  ],
  unitize: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  'vis-a-vis': [
    { word: 'vis-a-vis', word_class: 'N' },
    { word: 'vis-a-vis', word_class: 'AJ' },
    { word: 'vis-a-vis', word_class: 'AV' }
  ],
  terminate: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  yellowness: [
    { word: 'yellow', word_class: 'N' },
    { word: 'yellow', word_class: 'V' },
    { word: 'yellow', word_class: 'AJ' },
    { word: 'yellowed', word_class: 'AJ' },
    { word: 'yellowness', word_class: 'N' }
  ],
  eruditely: [
    { word: 'erudite', word_class: 'AJ' },
    { word: 'erudition', word_class: 'N' },
    { word: 'eruditely', word_class: 'AV' },
    { word: 'eruditeness', word_class: 'N' }
  ],
  thermodynamic: [
    { word: 'thermodynamic', word_class: 'AJ' },
    { word: 'thermodynamics', word_class: 'N' },
    { word: 'thermodynamical', word_class: 'AJ' },
    { word: 'thermodynamically', word_class: 'AV' }
  ],
  corroboration: [
    { word: 'corroborate', word_class: 'V' },
    { word: 'corroborant', word_class: 'AJ' },
    { word: 'corroborated', word_class: 'AJ' },
    { word: 'corroboration', word_class: 'N' },
    { word: 'corroborative', word_class: 'AJ' }
  ],
  bristle: [
    { word: 'bristle', word_class: 'N' },
    { word: 'bristle', word_class: 'V' },
    { word: 'bristled', word_class: 'AJ' }
  ],
  metalworker: [
    { word: 'metalwork', word_class: 'N' },
    { word: 'metalworks', word_class: 'N' },
    { word: 'metalworker', word_class: 'N' },
    { word: 'metalworking', word_class: 'N' }
  ],
  exclude: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  sand: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  unintelligibility: [
    { word: 'unintelligent', word_class: 'AJ' },
    { word: 'unintelligible', word_class: 'AJ' },
    { word: 'unintelligibly', word_class: 'AV' },
    { word: 'unintelligently', word_class: 'AV' },
    { word: 'unintelligibility', word_class: 'N' }
  ],
  objectively: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  snooper: [
    { word: 'snoop', word_class: 'N' },
    { word: 'snoop', word_class: 'V' },
    { word: 'snooper', word_class: 'N' },
    { word: 'snoopy', word_class: 'AJ' },
    { word: 'snoopiness', word_class: 'N' }
  ],
  impoverishment: [
    { word: 'impoverish', word_class: 'V' },
    { word: 'impoverished', word_class: 'AJ' },
    { word: 'impoverishment', word_class: 'N' },
    { word: 'impovempoverish', word_class: 'V' },
    { word: 'impovempoverishment', word_class: 'N' }
  ],
  edibleness: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  repugnancy: [
    { word: 'repugn', word_class: 'V' },
    { word: 'repugnance', word_class: 'N' },
    { word: 'repugnancy', word_class: 'N' },
    { word: 'repugnant', word_class: 'AJ' }
  ],
  academically: [
    { word: 'academe', word_class: 'N' },
    { word: 'academy', word_class: 'N' },
    { word: 'academia', word_class: 'N' },
    { word: 'academic', word_class: 'N' },
    { word: 'academic', word_class: 'AJ' },
    { word: 'academism', word_class: 'N' },
    { word: 'academically', word_class: 'AV' }
  ],
  valorously: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  separatist: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  brake: [
    { word: 'brake', word_class: 'N' },
    { word: 'brake', word_class: 'V' },
    { word: 'brakes', word_class: 'N' },
    { word: 'brakeman', word_class: 'N' }
  ],
  conception: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  suspense: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  mutable: [
    { word: 'mutable', word_class: 'AJ' },
    { word: 'mutably', word_class: 'AV' },
    { word: 'mutability', word_class: 'N' },
    { word: 'mutableness', word_class: 'N' }
  ],
  nurturing: [
    { word: 'nurture', word_class: 'N' },
    { word: 'nurture', word_class: 'V' },
    { word: 'nurtural', word_class: 'AJ' },
    { word: 'nurturing', word_class: 'N' }
  ],
  immortalise: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  locomotion: [
    { word: 'locomote', word_class: 'V' },
    { word: 'locomotion', word_class: 'N' },
    { word: 'locomotive', word_class: 'N' },
    { word: 'locomotive', word_class: 'AJ' }
  ],
  boisterousness: [
    { word: 'boisterous', word_class: 'AJ' },
    { word: 'boisterously', word_class: 'AV' },
    { word: 'boisterousness', word_class: 'N' }
  ],
  denial: [{ word: 'deny', word_class: 'V' }, { word: 'denial', word_class: 'N' }, { word: 'denier', word_class: 'N' }],
  pusillanimity: [
    { word: 'pusillanimity', word_class: 'N' },
    { word: 'pusillanimous', word_class: 'AJ' },
    { word: 'pusillanimously', word_class: 'AV' },
    { word: 'pusillanimousness', word_class: 'N' }
  ],
  sainted: [
    { word: 'saint', word_class: 'N' },
    { word: 'saint', word_class: 'V' },
    { word: 'sainted', word_class: 'AJ' }
  ],
  depressant: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  defile: [
    { word: 'defile', word_class: 'N' },
    { word: 'defile', word_class: 'V' },
    { word: 'defiled', word_class: 'AJ' },
    { word: 'defilement', word_class: 'N' }
  ],
  respectably: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  adaptative: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  eroding: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  naivety: [
    { word: 'naive', word_class: 'AJ' },
    { word: 'naivety', word_class: 'N' },
    { word: 'naively', word_class: 'AV' },
    { word: 'naiveness', word_class: 'N' }
  ],
  dispirited: [
    { word: 'dispirit', word_class: 'V' },
    { word: 'dispirited', word_class: 'AJ' },
    { word: 'dispiriting', word_class: 'AJ' }
  ],
  conglobate: [
    { word: 'conglobe', word_class: 'V' },
    { word: 'conglobate', word_class: 'V' },
    { word: 'conglobation', word_class: 'N' }
  ],
  epigrammatization: [
    { word: 'epigrammatic', word_class: 'AJ' },
    { word: 'epigrammatize', word_class: 'V' },
    { word: 'epigrammatization', word_class: 'N' }
  ],
  naturalistic: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  housebreaking: [
    { word: 'housebreak', word_class: 'V' },
    { word: 'housebreaker', word_class: 'N' },
    { word: 'housebroken', word_class: 'AJ' },
    { word: 'housebreaking', word_class: 'N' }
  ],
  solubly: [
    { word: 'soluble', word_class: 'AJ' },
    { word: 'solubly', word_class: 'AV' },
    { word: 'solubility', word_class: 'N' },
    { word: 'solubleness', word_class: 'N' }
  ],
  tenseness: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  isomorphous: [
    { word: 'isomorphic', word_class: 'AJ' },
    { word: 'isomorphism', word_class: 'N' },
    { word: 'isomorphous', word_class: 'AJ' }
  ],
  anaesthetized: [
    { word: 'anaesthetic', word_class: 'N' },
    { word: 'anaesthetize', word_class: 'V' },
    { word: 'anaesthetic', word_class: 'AJ' },
    { word: 'anaesthetist', word_class: 'N' },
    { word: 'anaesthetized', word_class: 'AJ' },
    { word: 'anaesthetisation', word_class: 'N' },
    { word: 'anaesthetization', word_class: 'N' }
  ],
  continual: [
    { word: 'continue', word_class: 'V' },
    { word: 'continual', word_class: 'AJ' },
    { word: 'continuant', word_class: 'N' },
    { word: 'continued', word_class: 'AJ' },
    { word: 'continuity', word_class: 'N' },
    { word: 'continuance', word_class: 'N' },
    { word: 'continuous', word_class: 'AJ' },
    { word: 'continuing', word_class: 'AJ' },
    { word: 'continually', word_class: 'AV' },
    { word: 'continuation', word_class: 'N' },
    { word: 'continuously', word_class: 'AV' },
    { word: 'continuousness', word_class: 'N' }
  ],
  retrospect: [
    { word: 'retrospect', word_class: 'N' },
    { word: 'retrospect', word_class: 'V' },
    { word: 'retrospection', word_class: 'N' },
    { word: 'retrospective', word_class: 'N' },
    { word: 'retrospective', word_class: 'AJ' },
    { word: 'retrospectively', word_class: 'AV' }
  ],
  negation: [
    { word: 'negate', word_class: 'V' },
    { word: 'negating', word_class: 'N' },
    { word: 'negation', word_class: 'N' }
  ],
  marginally: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  public: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  participating: [
    { word: 'participant', word_class: 'N' },
    { word: 'participate', word_class: 'V' },
    { word: 'participance', word_class: 'N' },
    { word: 'participation', word_class: 'N' },
    { word: 'participating', word_class: 'AJ' }
  ],
  addressed: [
    { word: 'address', word_class: 'N' },
    { word: 'address', word_class: 'V' },
    { word: 'addressee', word_class: 'N' },
    { word: 'addressed', word_class: 'AJ' },
    { word: 'addressable', word_class: 'AJ' }
  ],
  majestically: [
    { word: 'majesty', word_class: 'N' },
    { word: 'majestic', word_class: 'AJ' },
    { word: 'majestically', word_class: 'AV' }
  ],
  barb: [{ word: 'barb', word_class: 'N' }, { word: 'barb', word_class: 'V' }, { word: 'barbed', word_class: 'AJ' }],
  sculptural: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  barrel: [
    { word: 'barrel', word_class: 'N' },
    { word: 'barrel', word_class: 'V' },
    { word: 'barrels', word_class: 'N' },
    { word: 'barreled', word_class: 'AJ' },
    { word: 'barrelful', word_class: 'N' },
    { word: 'barrelled', word_class: 'AJ' }
  ],
  slanted: [
    { word: 'slant', word_class: 'N' },
    { word: 'slant', word_class: 'V' },
    { word: 'slanted', word_class: 'AJ' },
    { word: 'slanting', word_class: 'AJ' }
  ],
  morose: [
    { word: 'morose', word_class: 'AJ' },
    { word: 'morosely', word_class: 'AV' },
    { word: 'moroseness', word_class: 'N' }
  ],
  profound: [
    { word: 'profound', word_class: 'V' },
    { word: 'profound', word_class: 'AJ' },
    { word: 'profundity', word_class: 'N' },
    { word: 'profoundness', word_class: 'N' }
  ],
  behaviour: [
    { word: 'behaviour', word_class: 'N' },
    { word: 'behavioural', word_class: 'AJ' },
    { word: 'behaviourism', word_class: 'N' }
  ],
  compartmentalize: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  dowdiness: [
    { word: 'dowdy', word_class: 'N' },
    { word: 'dowdy', word_class: 'AJ' },
    { word: 'dowdiness', word_class: 'N' }
  ],
  acknowledgement: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  remit: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  desist: [
    { word: 'desist', word_class: 'V' },
    { word: 'desistance', word_class: 'N' },
    { word: 'desistence', word_class: 'N' }
  ],
  deamination: [
    { word: 'deaminate', word_class: 'V' },
    { word: 'deaminize', word_class: 'V' },
    { word: 'deamination', word_class: 'N' },
    { word: 'deaminisation', word_class: 'N' },
    { word: 'deaminization', word_class: 'N' }
  ],
  confirmation: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  mechanise: [
    { word: 'mechanic', word_class: 'N' },
    { word: 'mechanic', word_class: 'AJ' },
    { word: 'mechanics', word_class: 'N' },
    { word: 'mechanise', word_class: 'V' },
    { word: 'mechanism', word_class: 'N' },
    { word: 'mechanize', word_class: 'V' },
    { word: 'mechanical', word_class: 'AJ' },
    { word: 'mechanized', word_class: 'AJ' },
    { word: 'mechanically', word_class: 'AV' },
    { word: 'mechanisation', word_class: 'N' },
    { word: 'mechanization', word_class: 'N' }
  ],
  disappointing: [
    { word: 'disappoint', word_class: 'V' },
    { word: 'disappointed', word_class: 'AJ' },
    { word: 'disappointing', word_class: 'N' },
    { word: 'disappointing', word_class: 'AJ' },
    { word: 'disappointment', word_class: 'N' }
  ],
  disseminative: [
    { word: 'disseminate', word_class: 'V' },
    { word: 'disseminator', word_class: 'N' },
    { word: 'dissemination', word_class: 'N' },
    { word: 'disseminating', word_class: 'AJ' },
    { word: 'disseminative', word_class: 'AJ' }
  ],
  hyalin: [
    { word: 'hyalin', word_class: 'N' },
    { word: 'hyaline', word_class: 'N' },
    { word: 'hyaline', word_class: 'AJ' },
    { word: 'hyalinization', word_class: 'N' }
  ],
  adamant: [
    { word: 'adam', word_class: 'N' },
    { word: 'adams', word_class: 'N' },
    { word: 'adamant', word_class: 'N' },
    { word: 'adamance', word_class: 'N' },
    { word: 'adamant', word_class: 'AJ' }
  ],
  disfranchise: [
    { word: 'disfranchise', word_class: 'V' },
    { word: 'disfranchised', word_class: 'AJ' },
    { word: 'disfranchisement', word_class: 'N' }
  ],
  fabianism: [
    { word: 'fabian', word_class: 'N' },
    { word: 'fabian', word_class: 'AJ' },
    { word: 'fabianism', word_class: 'N' }
  ],
  processing: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  reference: [
    { word: 'refer', word_class: 'V' },
    { word: 'referent', word_class: 'N' },
    { word: 'referral', word_class: 'N' },
    { word: 'reference', word_class: 'N' },
    { word: 'reference', word_class: 'V' },
    { word: 'referable', word_class: 'AJ' }
  ],
  impregnability: [
    { word: 'impregnant', word_class: 'N' },
    { word: 'impregnate', word_class: 'V' },
    { word: 'impregnable', word_class: 'AJ' },
    { word: 'impregnably', word_class: 'AV' },
    { word: 'impregnated', word_class: 'AJ' },
    { word: 'impregnation', word_class: 'N' },
    { word: 'impregnability', word_class: 'N' }
  ],
  stabilization: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  defined: [
    { word: 'define', word_class: 'V' },
    { word: 'defined', word_class: 'AJ' },
    { word: 'defining', word_class: 'N' },
    { word: 'definite', word_class: 'AJ' },
    { word: 'definable', word_class: 'AJ' },
    { word: 'definition', word_class: 'N' },
    { word: 'definitely', word_class: 'AV' },
    { word: 'definitive', word_class: 'AJ' },
    { word: 'definiteness', word_class: 'N' }
  ],
  sabbat: [
    { word: 'sabbat', word_class: 'N' },
    { word: 'sabbatical', word_class: 'N' },
    { word: 'sabbatical', word_class: 'AJ' }
  ],
  undertake: [
    { word: 'undertake', word_class: 'V' },
    { word: 'undertaker', word_class: 'N' },
    { word: 'undertaking', word_class: 'N' }
  ],
  shipwrecked: [
    { word: 'shipwreck', word_class: 'N' },
    { word: 'shipwreck', word_class: 'V' },
    { word: 'shipwrecked', word_class: 'AJ' }
  ],
  consolement: [
    { word: 'console', word_class: 'N' },
    { word: 'console', word_class: 'V' },
    { word: 'consoling', word_class: 'AJ' },
    { word: 'consolable', word_class: 'AJ' },
    { word: 'consolation', word_class: 'N' },
    { word: 'consolement', word_class: 'N' }
  ],
  gloat: [
    { word: 'gloat', word_class: 'N' },
    { word: 'gloat', word_class: 'V' },
    { word: 'gloating', word_class: 'N' }
  ],
  legged: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  columned: [
    { word: 'column', word_class: 'N' },
    { word: 'columnar', word_class: 'AJ' },
    { word: 'columned', word_class: 'AJ' }
  ],
  externalisation: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  agnostic: [
    { word: 'agnostic', word_class: 'N' },
    { word: 'agnostic', word_class: 'AJ' },
    { word: 'agnostical', word_class: 'AJ' }
  ],
  visually: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  subscriber: [
    { word: 'subscribe', word_class: 'N' },
    { word: 'subscribe', word_class: 'V' },
    { word: 'subscript', word_class: 'N' },
    { word: 'subscriber', word_class: 'N' },
    { word: 'subscript', word_class: 'AJ' },
    { word: 'subcription', word_class: 'N' },
    { word: 'subscribed', word_class: 'AJ' },
    { word: 'subscription', word_class: 'N' }
  ],
  creationism: [
    { word: 'create', word_class: 'V' },
    { word: 'creator', word_class: 'N' },
    { word: 'creation', word_class: 'N' },
    { word: 'creationism', word_class: 'N' }
  ],
  commonsensible: [
    { word: 'commonsense', word_class: 'AJ' },
    { word: 'commonsensible', word_class: 'AJ' },
    { word: 'commonsensical', word_class: 'AJ' }
  ],
  foresight: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  treasonably: [
    { word: 'treason', word_class: 'N' },
    { word: 'treasonous', word_class: 'AJ' },
    { word: 'treasonable', word_class: 'AJ' },
    { word: 'treasonably', word_class: 'AV' }
  ],
  actinomycetous: [
    { word: 'actinomycete', word_class: 'N' },
    { word: 'actinomycetal', word_class: 'AJ' },
    { word: 'actinomycetous', word_class: 'AJ' }
  ],
  imputable: [
    { word: 'impute', word_class: 'V' },
    { word: 'imputable', word_class: 'AJ' },
    { word: 'imputation', word_class: 'N' }
  ],
  physicalness: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  remark: [
    { word: 'remark', word_class: 'N' },
    { word: 'remark', word_class: 'V' },
    { word: 'remarkable', word_class: 'AJ' },
    { word: 'remarkably', word_class: 'AV' }
  ],
  grangerism: [
    { word: 'granger', word_class: 'N' },
    { word: 'grangerism', word_class: 'N' },
    { word: 'grangerize', word_class: 'V' },
    { word: 'grangerization', word_class: 'N' }
  ],
  knot: [{ word: 'knot', word_class: 'N' }, { word: 'knot', word_class: 'V' }, { word: 'knotted', word_class: 'AJ' }],
  topographical: [
    { word: 'topographic', word_class: 'AJ' },
    { word: 'topographical', word_class: 'AJ' },
    { word: 'topographically', word_class: 'AV' }
  ],
  fortifying: [
    { word: 'fortify', word_class: 'V' },
    { word: 'fortified', word_class: 'AJ' },
    { word: 'fortifying', word_class: 'AJ' },
    { word: 'fortification', word_class: 'N' }
  ],
  adjectively: [
    { word: 'adjective', word_class: 'N' },
    { word: 'adjective', word_class: 'AJ' },
    { word: 'adjectively', word_class: 'AV' }
  ],
  prodigiously: [
    { word: 'prodigy', word_class: 'N' },
    { word: 'prodigious', word_class: 'AJ' },
    { word: 'prodigiously', word_class: 'AV' }
  ],
  laboriously: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  vacuolization: [
    { word: 'vacuole', word_class: 'N' },
    { word: 'vacuolate', word_class: 'AJ' },
    { word: 'vacuolated', word_class: 'AJ' },
    { word: 'vacuolation', word_class: 'N' },
    { word: 'vacuolization', word_class: 'N' }
  ],
  malinger: [
    { word: 'malinger', word_class: 'V' },
    { word: 'malingerer', word_class: 'N' },
    { word: 'malingering', word_class: 'N' }
  ],
  operative: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  depravity: [
    { word: 'deprave', word_class: 'V' },
    { word: 'depraved', word_class: 'AJ' },
    { word: 'depravity', word_class: 'N' },
    { word: 'depravation', word_class: 'N' }
  ],
  consignment: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  benignancy: [
    { word: 'benign', word_class: 'AJ' },
    { word: 'benignity', word_class: 'N' },
    { word: 'benignancy', word_class: 'N' },
    { word: 'benignant', word_class: 'AJ' }
  ],
  hydrogenation: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  barkeep: [
    { word: 'barkeep', word_class: 'N' },
    { word: 'barkeep', word_class: 'V' },
    { word: 'barkeeper', word_class: 'N' }
  ],
  clearance: [
    { word: 'clear', word_class: 'N' },
    { word: 'clear', word_class: 'V' },
    { word: 'clear', word_class: 'AJ' },
    { word: 'clear', word_class: 'AV' },
    { word: 'cleared', word_class: 'AJ' },
    { word: 'clearing', word_class: 'N' },
    { word: 'clearance', word_class: 'N' },
    { word: 'clearness', word_class: 'N' }
  ],
  pellucidness: [
    { word: 'pellucid', word_class: 'AJ' },
    { word: 'pellucidity', word_class: 'N' },
    { word: 'pellucidness', word_class: 'N' }
  ],
  mutter: [
    { word: 'mutter', word_class: 'N' },
    { word: 'mutter', word_class: 'V' },
    { word: 'mutterer', word_class: 'N' },
    { word: 'muttering', word_class: 'N' },
    { word: 'muttering', word_class: 'AJ' }
  ],
  preparative: [
    { word: 'prepare', word_class: 'V' },
    { word: 'prepared', word_class: 'AJ' },
    { word: 'preparation', word_class: 'N' },
    { word: 'preparative', word_class: 'N' },
    { word: 'preparative', word_class: 'AJ' }
  ],
  macroscopical: [
    { word: 'macroscopic', word_class: 'AJ' },
    { word: 'macroscopical', word_class: 'AJ' },
    { word: 'macroscopically', word_class: 'AV' }
  ],
  sentence: [
    { word: 'sentence', word_class: 'N' },
    { word: 'sentence', word_class: 'V' },
    { word: 'sentential', word_class: 'AJ' },
    { word: 'sententious', word_class: 'AJ' },
    { word: 'sententiously', word_class: 'AV' }
  ],
  swagger: [
    { word: 'swagger', word_class: 'N' },
    { word: 'swagger', word_class: 'V' },
    { word: 'swagger', word_class: 'AJ' },
    { word: 'swaggerer', word_class: 'N' },
    { word: 'swaggering', word_class: 'AJ' }
  ],
  absorbable: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  righthand: [
    { word: 'righthand', word_class: 'AJ' },
    { word: 'righthanded', word_class: 'AJ' },
    { word: 'righthandedness', word_class: 'N' }
  ],
  ignition: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  lutanist: [
    { word: 'lute', word_class: 'N' },
    { word: 'lute', word_class: 'V' },
    { word: 'luting', word_class: 'N' },
    { word: 'lutist', word_class: 'N' },
    { word: 'lutanist', word_class: 'N' }
  ],
  unavailing: [
    { word: 'unavailing', word_class: 'AJ' },
    { word: 'unavailable', word_class: 'AJ' },
    { word: 'unavailability', word_class: 'N' }
  ],
  ceremonially: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  diagnose: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  commendable: [
    { word: 'commend', word_class: 'V' },
    { word: 'commendable', word_class: 'AJ' },
    { word: 'commendable', word_class: 'AV' },
    { word: 'commendation', word_class: 'N' },
    { word: 'commendatory', word_class: 'AJ' }
  ],
  secretarial: [
    { word: 'secretary', word_class: 'N' },
    { word: 'secretarial', word_class: 'AJ' },
    { word: 'secretariate', word_class: 'N' }
  ],
  immoveable: [
    { word: 'immovable', word_class: 'AJ' },
    { word: 'immovably', word_class: 'AV' },
    { word: 'immoveable', word_class: 'AJ' },
    { word: 'immovability', word_class: 'N' },
    { word: 'immovableness', word_class: 'N' }
  ],
  putty: [{ word: 'putty', word_class: 'N' }, { word: 'putty', word_class: 'V' }, { word: 'putty', word_class: 'AJ' }],
  notably: [
    { word: 'notable', word_class: 'N' },
    { word: 'notable', word_class: 'AJ' },
    { word: 'notably', word_class: 'AV' },
    { word: 'notability', word_class: 'N' }
  ],
  plead: [
    { word: 'plea', word_class: 'N' },
    { word: 'plead', word_class: 'V' },
    { word: 'pleading', word_class: 'N' },
    { word: 'pleading', word_class: 'AJ' }
  ],
  teacupful: [
    { word: 'teacup', word_class: 'N' },
    { word: 'teacupful', word_class: 'N' },
    { word: 'teacupful', word_class: 'AJ' }
  ],
  duplication: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  ritualize: [
    { word: 'ritual', word_class: 'N' },
    { word: 'ritual', word_class: 'AJ' },
    { word: 'ritualism', word_class: 'N' },
    { word: 'ritualize', word_class: 'V' },
    { word: 'ritually', word_class: 'AV' },
    { word: 'ritualization', word_class: 'N' }
  ],
  camouflaged: [
    { word: 'camouflage', word_class: 'N' },
    { word: 'camouflage', word_class: 'V' },
    { word: 'camouflaged', word_class: 'AJ' }
  ],
  journalist: [
    { word: 'journalist', word_class: 'N' },
    { word: 'journalistic', word_class: 'AJ' },
    { word: 'journalistically', word_class: 'AV' }
  ],
  greening: [
    { word: 'green', word_class: 'N' },
    { word: 'green', word_class: 'V' },
    { word: 'green', word_class: 'AJ' },
    { word: 'greens', word_class: 'N' },
    { word: 'greenery', word_class: 'N' },
    { word: 'greening', word_class: 'N' },
    { word: 'greening', word_class: 'AJ' },
    { word: 'greenness', word_class: 'N' }
  ],
  doom: [
    { word: 'doom', word_class: 'N' },
    { word: 'doom', word_class: 'V' },
    { word: 'doomed', word_class: 'N' },
    { word: 'doomed', word_class: 'AJ' }
  ],
  classicism: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  contumacy: [
    { word: 'contumacy', word_class: 'N' },
    { word: 'contumacious', word_class: 'AJ' },
    { word: 'contumaciously', word_class: 'AV' }
  ],
  vitamin: [
    { word: 'vitamin', word_class: 'N' },
    { word: 'vitamine', word_class: 'N' },
    { word: 'vitaminize', word_class: 'V' }
  ],
  occasional: [
    { word: 'occasion', word_class: 'N' },
    { word: 'occasion', word_class: 'V' },
    { word: 'occasions', word_class: 'N' },
    { word: 'occasional', word_class: 'AJ' },
    { word: 'occasionment', word_class: 'N' },
    { word: 'occasionally', word_class: 'AV' }
  ],
  fins: [{ word: 'fin', word_class: 'N' }, { word: 'fin', word_class: 'V' }, { word: 'fins', word_class: 'N' }],
  reiterative: [
    { word: 'reiterate', word_class: 'V' },
    { word: 'reiteration', word_class: 'N' },
    { word: 'reiterative', word_class: 'AJ' }
  ],
  convention: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  floatation: [
    { word: 'float', word_class: 'N' },
    { word: 'float', word_class: 'V' },
    { word: 'floater', word_class: 'N' },
    { word: 'floating', word_class: 'AJ' },
    { word: 'flotation', word_class: 'N' },
    { word: 'floatation', word_class: 'N' }
  ],
  dissolved: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  buff: [{ word: 'buff', word_class: 'N' }, { word: 'buff', word_class: 'V' }, { word: 'buff', word_class: 'AJ' }],
  clownish: [
    { word: 'clown', word_class: 'N' },
    { word: 'clown', word_class: 'V' },
    { word: 'clownery', word_class: 'N' },
    { word: 'clowning', word_class: 'N' },
    { word: 'clownish', word_class: 'AJ' }
  ],
  americanism: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  feudalisation: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  straining: [
    { word: 'strain', word_class: 'N' },
    { word: 'strain', word_class: 'V' },
    { word: 'strainer', word_class: 'N' },
    { word: 'strained', word_class: 'AJ' },
    { word: 'straining', word_class: 'N' },
    { word: 'straining', word_class: 'AJ' }
  ],
  useful: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  swift: [
    { word: 'swift', word_class: 'N' },
    { word: 'swift', word_class: 'AJ' },
    { word: 'swiftness', word_class: 'N' }
  ],
  consecrate: [
    { word: 'consecrate', word_class: 'V' },
    { word: 'consecrate', word_class: 'AJ' },
    { word: 'consecrated', word_class: 'AJ' },
    { word: 'consecration', word_class: 'N' }
  ],
  sophisticated: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  slough: [
    { word: 'slough', word_class: 'N' },
    { word: 'slough', word_class: 'V' },
    { word: 'sloughing', word_class: 'N' }
  ],
  astound: [
    { word: 'astound', word_class: 'V' },
    { word: 'astounded', word_class: 'AJ' },
    { word: 'astounding', word_class: 'AJ' }
  ],
  starve: [
    { word: 'starve', word_class: 'V' },
    { word: 'starved', word_class: 'AJ' },
    { word: 'starving', word_class: 'N' },
    { word: 'starving', word_class: 'AJ' },
    { word: 'starvation', word_class: 'N' }
  ],
  mission: [
    { word: 'miss', word_class: 'N' },
    { word: 'miss', word_class: 'V' },
    { word: 'missal', word_class: 'N' },
    { word: 'mission', word_class: 'N' },
    { word: 'missed', word_class: 'AJ' },
    { word: 'missing', word_class: 'AJ' },
    { word: 'missioner', word_class: 'N' },
    { word: 'missionary', word_class: 'N' }
  ],
  acetylic: [
    { word: 'acetyl', word_class: 'N' },
    { word: 'acetylate', word_class: 'V' },
    { word: 'acetylic', word_class: 'AJ' },
    { word: 'acetylize', word_class: 'V' },
    { word: 'acetylation', word_class: 'N' }
  ],
  pruriently: [
    { word: 'prurience', word_class: 'N' },
    { word: 'pruriency', word_class: 'N' },
    { word: 'prurient', word_class: 'AJ' },
    { word: 'pruriently', word_class: 'AV' }
  ],
  retentive: [
    { word: 'retain', word_class: 'V' },
    { word: 'retainer', word_class: 'N' },
    { word: 'retention', word_class: 'N' },
    { word: 'retained', word_class: 'AJ' },
    { word: 'retaining', word_class: 'AJ' },
    { word: 'retentive', word_class: 'AJ' },
    { word: 'retentivity', word_class: 'N' },
    { word: 'retentively', word_class: 'AV' },
    { word: 'retentiveness', word_class: 'N' }
  ],
  undernourish: [
    { word: 'undernourish', word_class: 'V' },
    { word: 'undernourished', word_class: 'AJ' },
    { word: 'undernourishment', word_class: 'N' }
  ],
  heaving: [
    { word: 'heave', word_class: 'N' },
    { word: 'heave', word_class: 'V' },
    { word: 'heaver', word_class: 'N' },
    { word: 'heaves', word_class: 'N' },
    { word: 'heaving', word_class: 'N' },
    { word: 'heaving', word_class: 'AJ' }
  ],
  caesar: [
    { word: 'caesar', word_class: 'N' },
    { word: 'cesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'N' },
    { word: 'cesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'N' },
    { word: 'caesarean', word_class: 'N' },
    { word: 'cesarian', word_class: 'AJ' },
    { word: 'caesarism', word_class: 'N' },
    { word: 'caesarean', word_class: 'AJ' },
    { word: 'caesarian', word_class: 'AJ' }
  ],
  advisability: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  antiquation: [
    { word: 'antique', word_class: 'N' },
    { word: 'antique', word_class: 'V' },
    { word: 'antique', word_class: 'AJ' },
    { word: 'antiquate', word_class: 'V' },
    { word: 'antiquity', word_class: 'N' },
    { word: 'antiquated', word_class: 'AJ' },
    { word: 'antiquation', word_class: 'N' }
  ],
  slayer: [
    { word: 'slay', word_class: 'V' },
    { word: 'slain', word_class: 'N' },
    { word: 'slain', word_class: 'AJ' },
    { word: 'slayer', word_class: 'N' },
    { word: 'slaying', word_class: 'N' }
  ],
  appetency: [
    { word: 'appetence', word_class: 'N' },
    { word: 'appetency', word_class: 'N' },
    { word: 'appetent', word_class: 'AJ' },
    { word: 'appetizer', word_class: 'N' },
    { word: 'appetizing', word_class: 'AJ' }
  ],
  devise: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  reactivity: [
    { word: 'reactive', word_class: 'AJ' },
    { word: 'reactivate', word_class: 'V' },
    { word: 'reactivity', word_class: 'N' },
    { word: 'reactivation', word_class: 'N' }
  ],
  philippines: [
    { word: 'philippine', word_class: 'N' },
    { word: 'philippine', word_class: 'AJ' },
    { word: 'philippines', word_class: 'N' }
  ],
  sonneteer: [
    { word: 'sonnet', word_class: 'N' },
    { word: 'sonnet', word_class: 'V' },
    { word: 'sonneteer', word_class: 'N' }
  ],
  indication: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  slow: [
    { word: 'slow', word_class: 'V' },
    { word: 'slow', word_class: 'AJ' },
    { word: 'slow', word_class: 'AV' },
    { word: 'slowing', word_class: 'N' },
    { word: 'slowness', word_class: 'N' }
  ],
  shoddy: [
    { word: 'shoddy', word_class: 'N' },
    { word: 'shoddy', word_class: 'AJ' },
    { word: 'shoddiness', word_class: 'N' }
  ],
  subminiaturization: [
    { word: 'subminiaturise', word_class: 'V' },
    { word: 'subminiaturize', word_class: 'V' },
    { word: 'subminiaturisation', word_class: 'N' },
    { word: 'subminiaturization', word_class: 'N' }
  ],
  localize: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  tell: [
    { word: 'tell', word_class: 'N' },
    { word: 'tell', word_class: 'V' },
    { word: 'teller', word_class: 'N' },
    { word: 'telling', word_class: 'N' },
    { word: 'telling', word_class: 'AJ' }
  ],
  bureaucratic: [
    { word: 'bureaucrat', word_class: 'N' },
    { word: 'bureaucracy', word_class: 'N' },
    { word: 'bureaucratic', word_class: 'AJ' },
    { word: 'bureaucratism', word_class: 'N' },
    { word: 'bureaucratically', word_class: 'AV' }
  ],
  dazzle: [
    { word: 'dazzle', word_class: 'N' },
    { word: 'dazzle', word_class: 'V' },
    { word: 'dazzled', word_class: 'AJ' },
    { word: 'dazzling', word_class: 'AJ' }
  ],
  meditatively: [
    { word: 'meditate', word_class: 'V' },
    { word: 'meditation', word_class: 'N' },
    { word: 'meditative', word_class: 'AJ' },
    { word: 'meditatively', word_class: 'AV' },
    { word: 'meditativeness', word_class: 'N' }
  ],
  booming: [
    { word: 'boom', word_class: 'N' },
    { word: 'boom', word_class: 'V' },
    { word: 'boomer', word_class: 'N' },
    { word: 'booming', word_class: 'AJ' }
  ],
  corruption: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  nudeness: [
    { word: 'nude', word_class: 'N' },
    { word: 'nude', word_class: 'AJ' },
    { word: 'nudeness', word_class: 'N' }
  ],
  catching: [
    { word: 'catch', word_class: 'N' },
    { word: 'catch', word_class: 'V' },
    { word: 'catcher', word_class: 'N' },
    { word: 'catching', word_class: 'N' },
    { word: 'catching', word_class: 'AJ' },
    { word: 'catchment', word_class: 'N' }
  ],
  possibly: [
    { word: 'possible', word_class: 'N' },
    { word: 'possible', word_class: 'AJ' },
    { word: 'possibly', word_class: 'AV' },
    { word: 'possibility', word_class: 'N' },
    { word: 'possibleness', word_class: 'N' }
  ],
  calisthenics: [
    { word: 'calisthenic', word_class: 'N' },
    { word: 'calisthenic', word_class: 'AJ' },
    { word: 'calisthenics', word_class: 'N' }
  ],
  concretisation: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  cooked: [
    { word: 'cook', word_class: 'N' },
    { word: 'cook', word_class: 'V' },
    { word: 'cook', word_class: 'AJ' },
    { word: 'cooker', word_class: 'N' },
    { word: 'cookery', word_class: 'N' },
    { word: 'cooking', word_class: 'N' },
    { word: 'cooked', word_class: 'AJ' },
    { word: 'cookery', word_class: 'AJ' }
  ],
  entice: [
    { word: 'entice', word_class: 'V' },
    { word: 'enticing', word_class: 'AJ' },
    { word: 'enticement', word_class: 'N' }
  ],
  tagged: [{ word: 'tag', word_class: 'N' }, { word: 'tag', word_class: 'V' }, { word: 'tagged', word_class: 'AJ' }],
  winterize: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  pinche: [
    { word: 'pinch', word_class: 'N' },
    { word: 'pinch', word_class: 'V' },
    { word: 'pinche', word_class: 'N' },
    { word: 'pinched', word_class: 'AJ' }
  ],
  dissimulation: [
    { word: 'dissimulate', word_class: 'V' },
    { word: 'dissimulation', word_class: 'N' },
    { word: 'dissimulating', word_class: 'AJ' },
    { word: 'dissimulative', word_class: 'AJ' }
  ],
  pestered: [
    { word: 'pester', word_class: 'V' },
    { word: 'pesterer', word_class: 'N' },
    { word: 'pestered', word_class: 'AJ' },
    { word: 'pestering', word_class: 'AJ' }
  ],
  prickly: [
    { word: 'prickle', word_class: 'N' },
    { word: 'prickle', word_class: 'V' },
    { word: 'prickly', word_class: 'AJ' },
    { word: 'prickling', word_class: 'N' },
    { word: 'prickling', word_class: 'AJ' }
  ],
  generalization: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  chill: [
    { word: 'chill', word_class: 'N' },
    { word: 'chill', word_class: 'V' },
    { word: 'chill', word_class: 'AJ' },
    { word: 'chilled', word_class: 'AJ' },
    { word: 'chilling', word_class: 'N' },
    { word: 'chilling', word_class: 'AJ' }
  ],
  neutralise: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  'anti-semite': [
    { word: 'anti-semite', word_class: 'N' },
    { word: 'anti-semite', word_class: 'AJ' },
    { word: 'anti-semitic', word_class: 'AJ' },
    { word: 'anti-semitism', word_class: 'N' }
  ],
  baroque: [
    { word: 'baroque', word_class: 'N' },
    { word: 'baroque', word_class: 'AJ' },
    { word: 'baroqueness', word_class: 'N' }
  ],
  quixotically: [
    { word: 'quixotic', word_class: 'AJ' },
    { word: 'quixotism', word_class: 'N' },
    { word: 'quixotically', word_class: 'AV' }
  ],
  buffeted: [
    { word: 'buffet', word_class: 'N' },
    { word: 'buffet', word_class: 'V' },
    { word: 'buffeted', word_class: 'AJ' },
    { word: 'buffeting', word_class: 'N' }
  ],
  captivating: [
    { word: 'captive', word_class: 'N' },
    { word: 'captive', word_class: 'AJ' },
    { word: 'captivate', word_class: 'V' },
    { word: 'captivity', word_class: 'N' },
    { word: 'captivated', word_class: 'AJ' },
    { word: 'captivation', word_class: 'N' },
    { word: 'captivating', word_class: 'AJ' }
  ],
  sophistry: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  distributively: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  neoclassicistic: [
    { word: 'neoclassicist', word_class: 'N' },
    { word: 'neoclassicist', word_class: 'AJ' },
    { word: 'neoclassicistic', word_class: 'AJ' }
  ],
  dignify: [
    { word: 'dignify', word_class: 'V' },
    { word: 'dignified', word_class: 'AJ' },
    { word: 'dignifying', word_class: 'AJ' },
    { word: 'dignification', word_class: 'N' }
  ],
  sulphurate: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  mongrelize: [
    { word: 'mongrel', word_class: 'N' },
    { word: 'mongrel', word_class: 'AJ' },
    { word: 'mongrelise', word_class: 'V' },
    { word: 'mongrelize', word_class: 'V' },
    { word: 'mongrelisation', word_class: 'N' },
    { word: 'mongrelization', word_class: 'N' }
  ],
  authorities: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  metric: [
    { word: 'metric', word_class: 'N' },
    { word: 'metric', word_class: 'AJ' },
    { word: 'metrical', word_class: 'AJ' },
    { word: 'metricize', word_class: 'V' },
    { word: 'metrically', word_class: 'AV' },
    { word: 'metrication', word_class: 'N' }
  ],
  incasement: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  desulphurization: [
    { word: 'desulphurise', word_class: 'V' },
    { word: 'desulphurize', word_class: 'V' },
    { word: 'desulphurisation', word_class: 'N' },
    { word: 'desulphurization', word_class: 'N' }
  ],
  supposed: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  fitment: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  enumeration: [
    { word: 'enumerate', word_class: 'V' },
    { word: 'enumerable', word_class: 'AJ' },
    { word: 'enumeration', word_class: 'N' }
  ],
  intrustment: [
    { word: 'entrust', word_class: 'V' },
    { word: 'intrust', word_class: 'V' },
    { word: 'entrustment', word_class: 'N' },
    { word: 'intrustment', word_class: 'N' }
  ],
  suffocate: [
    { word: 'suffocate', word_class: 'V' },
    { word: 'suffocation', word_class: 'N' },
    { word: 'suffocating', word_class: 'AJ' },
    { word: 'suffocative', word_class: 'AJ' }
  ],
  insularity: [
    { word: 'insular', word_class: 'AJ' },
    { word: 'insularism', word_class: 'N' },
    { word: 'insularity', word_class: 'N' }
  ],
  copulate: [
    { word: 'copulate', word_class: 'V' },
    { word: 'copulation', word_class: 'N' },
    { word: 'copulative', word_class: 'N' },
    { word: 'copulative', word_class: 'AJ' }
  ],
  footed: [
    { word: 'foot', word_class: 'N' },
    { word: 'foot', word_class: 'V' },
    { word: 'footer', word_class: 'N' },
    { word: 'footed', word_class: 'AJ' },
    { word: 'footing', word_class: 'N' },
    { word: 'footman', word_class: 'N' }
  ],
  abuser: [
    { word: 'abuse', word_class: 'N' },
    { word: 'abuse', word_class: 'V' },
    { word: 'abuser', word_class: 'N' },
    { word: 'abused', word_class: 'AJ' },
    { word: 'abusive', word_class: 'AJ' },
    { word: 'abusively', word_class: 'AV' }
  ],
  congealed: [
    { word: 'congeal', word_class: 'V' },
    { word: 'congealed', word_class: 'AJ' },
    { word: 'congealment', word_class: 'N' },
    { word: 'congelation', word_class: 'N' }
  ],
  progressive: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  judicially: [
    { word: 'judicial', word_class: 'AJ' },
    { word: 'judicious', word_class: 'AJ' },
    { word: 'judicially', word_class: 'AV' },
    { word: 'judiciously', word_class: 'AV' },
    { word: 'judiciousness', word_class: 'N' }
  ],
  patronize: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  logicality: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  shrieked: [
    { word: 'shriek', word_class: 'N' },
    { word: 'shriek', word_class: 'V' },
    { word: 'shrieked', word_class: 'AJ' },
    { word: 'shrieking', word_class: 'N' },
    { word: 'shrieking', word_class: 'AJ' }
  ],
  perplexed: [
    { word: 'perplex', word_class: 'V' },
    { word: 'perplexed', word_class: 'AJ' },
    { word: 'perplexity', word_class: 'N' },
    { word: 'perplexing', word_class: 'AJ' }
  ],
  sincere: [
    { word: 'sincere', word_class: 'AJ' },
    { word: 'sincerity', word_class: 'N' },
    { word: 'sincerely', word_class: 'AV' }
  ],
  positionable: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  advertency: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  unintelligibly: [
    { word: 'unintelligent', word_class: 'AJ' },
    { word: 'unintelligible', word_class: 'AJ' },
    { word: 'unintelligibly', word_class: 'AV' },
    { word: 'unintelligently', word_class: 'AV' },
    { word: 'unintelligibility', word_class: 'N' }
  ],
  shouter: [
    { word: 'shout', word_class: 'N' },
    { word: 'shout', word_class: 'V' },
    { word: 'shouter', word_class: 'N' },
    { word: 'shouted', word_class: 'AJ' },
    { word: 'shouting', word_class: 'N' },
    { word: 'shouting', word_class: 'AJ' }
  ],
  ethnological: [
    { word: 'ethnology', word_class: 'N' },
    { word: 'ethnologic', word_class: 'AJ' },
    { word: 'ethnological', word_class: 'AJ' }
  ],
  grasp: [
    { word: 'grasp', word_class: 'N' },
    { word: 'grasp', word_class: 'V' },
    { word: 'grasping', word_class: 'N' },
    { word: 'grasping', word_class: 'AJ' }
  ],
  organization: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  slant: [
    { word: 'slant', word_class: 'N' },
    { word: 'slant', word_class: 'V' },
    { word: 'slanted', word_class: 'AJ' },
    { word: 'slanting', word_class: 'AJ' }
  ],
  bruiser: [
    { word: 'bruise', word_class: 'N' },
    { word: 'bruise', word_class: 'V' },
    { word: 'bruiser', word_class: 'N' },
    { word: 'bruised', word_class: 'AJ' },
    { word: 'bruising', word_class: 'AJ' }
  ],
  triteness: [
    { word: 'trite', word_class: 'AJ' },
    { word: 'tritely', word_class: 'AV' },
    { word: 'triteness', word_class: 'N' }
  ],
  suburbanize: [
    { word: 'suburban', word_class: 'AJ' },
    { word: 'suburbanize', word_class: 'V' },
    { word: 'suburbanized', word_class: 'AJ' }
  ],
  orang: [
    { word: 'orang', word_class: 'N' },
    { word: 'orange', word_class: 'N' },
    { word: 'orange', word_class: 'AJ' },
    { word: 'orangy', word_class: 'AJ' },
    { word: 'orangeness', word_class: 'N' }
  ],
  booker: [
    { word: 'book', word_class: 'N' },
    { word: 'book', word_class: 'V' },
    { word: 'booker', word_class: 'N' },
    { word: 'booked', word_class: 'AJ' },
    { word: 'booking', word_class: 'N' }
  ],
  left: [{ word: 'left', word_class: 'N' }, { word: 'left', word_class: 'AJ' }, { word: 'left', word_class: 'AV' }],
  equipoise: [
    { word: 'equipoise', word_class: 'N' },
    { word: 'equipoise', word_class: 'V' },
    { word: 'equipoised', word_class: 'AJ' }
  ],
  contention: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  tenanted: [
    { word: 'tenant', word_class: 'N' },
    { word: 'tenant', word_class: 'V' },
    { word: 'tenancy', word_class: 'N' },
    { word: 'tenanted', word_class: 'AJ' }
  ],
  wage: [{ word: 'wage', word_class: 'N' }, { word: 'wage', word_class: 'V' }, { word: 'wages', word_class: 'N' }],
  beguile: [
    { word: 'beguile', word_class: 'V' },
    { word: 'beguiled', word_class: 'AJ' },
    { word: 'beguiling', word_class: 'AJ' },
    { word: 'beguilement', word_class: 'N' }
  ],
  mediator: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  thawing: [
    { word: 'thaw', word_class: 'N' },
    { word: 'thaw', word_class: 'V' },
    { word: 'thawed', word_class: 'AJ' },
    { word: 'thawing', word_class: 'N' }
  ],
  cane: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  respirator: [
    { word: 'respire', word_class: 'N' },
    { word: 'respire', word_class: 'V' },
    { word: 'respirate', word_class: 'V' },
    { word: 'respirator', word_class: 'N' },
    { word: 'respiration', word_class: 'N' }
  ],
  universalism: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  sustain: [
    { word: 'sustain', word_class: 'V' },
    { word: 'sustenance', word_class: 'N' },
    { word: 'sustained', word_class: 'AJ' },
    { word: 'sustinence', word_class: 'N' },
    { word: 'sustainment', word_class: 'N' },
    { word: 'sustainable', word_class: 'AJ' },
    { word: 'sustainability', word_class: 'N' }
  ],
  equate: [
    { word: 'equate', word_class: 'V' },
    { word: 'equator', word_class: 'N' },
    { word: 'equating', word_class: 'N' },
    { word: 'equation', word_class: 'N' },
    { word: 'equatability', word_class: 'N' }
  ],
  differently: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  cashed: [{ word: 'cash', word_class: 'N' }, { word: 'cash', word_class: 'V' }, { word: 'cashed', word_class: 'AJ' }],
  voyeuristically: [
    { word: 'voyeuristic', word_class: 'AJ' },
    { word: 'voyeuristical', word_class: 'AJ' },
    { word: 'voyeuristically', word_class: 'AV' }
  ],
  mastering: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  mongol: [
    { word: 'mongol', word_class: 'N' },
    { word: 'mongol', word_class: 'AJ' },
    { word: 'mongolic', word_class: 'N' },
    { word: 'mongolism', word_class: 'N' }
  ],
  afflict: [
    { word: 'afflict', word_class: 'V' },
    { word: 'afflicted', word_class: 'AJ' },
    { word: 'affliction', word_class: 'N' },
    { word: 'afflictive', word_class: 'AJ' }
  ],
  wigging: [
    { word: 'wig', word_class: 'N' },
    { word: 'wig', word_class: 'V' },
    { word: 'wigged', word_class: 'AJ' },
    { word: 'wigging', word_class: 'N' }
  ],
  tumbling: [
    { word: 'tumble', word_class: 'N' },
    { word: 'tumble', word_class: 'V' },
    { word: 'tumbler', word_class: 'N' },
    { word: 'tumbling', word_class: 'N' },
    { word: 'tumbling', word_class: 'AJ' }
  ],
  assai: [
    { word: 'assay', word_class: 'N' },
    { word: 'assay', word_class: 'V' },
    { word: 'assai', word_class: 'AV' },
    { word: 'assayal', word_class: 'N' }
  ],
  diabetic: [
    { word: 'diabetes', word_class: 'N' },
    { word: 'diabetic', word_class: 'N' },
    { word: 'diabetic', word_class: 'AJ' }
  ],
  avenge: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  saliva: [
    { word: 'saliva', word_class: 'N' },
    { word: 'salivate', word_class: 'V' },
    { word: 'salivary', word_class: 'AJ' },
    { word: 'salivation', word_class: 'N' }
  ],
  compulsion: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  farm: [
    { word: 'farm', word_class: 'N' },
    { word: 'farm', word_class: 'V' },
    { word: 'farm', word_class: 'AJ' },
    { word: 'farmer', word_class: 'N' },
    { word: 'farming', word_class: 'N' },
    { word: 'farming', word_class: 'AJ' }
  ],
  sternutation: [
    { word: 'sternutator', word_class: 'N' },
    { word: 'sternutation', word_class: 'N' },
    { word: 'sternutative', word_class: 'AJ' }
  ],
  utilize: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  pedagogical: [
    { word: 'pedagogic', word_class: 'AJ' },
    { word: 'pedagogical', word_class: 'AJ' },
    { word: 'pedagogically', word_class: 'AV' }
  ],
  telecast: [
    { word: 'telecast', word_class: 'N' },
    { word: 'telecast', word_class: 'V' },
    { word: 'telecasting', word_class: 'N' }
  ],
  contentious: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  luncher: [
    { word: 'lunch', word_class: 'N' },
    { word: 'lunch', word_class: 'V' },
    { word: 'luncher', word_class: 'N' },
    { word: 'lunching', word_class: 'N' }
  ],
  consequent: [
    { word: 'consequent', word_class: 'N' },
    { word: 'consequence', word_class: 'N' },
    { word: 'consequent', word_class: 'AJ' },
    { word: 'consequently', word_class: 'AV' }
  ],
  exteriorise: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  denuded: [
    { word: 'denude', word_class: 'V' },
    { word: 'denudate', word_class: 'V' },
    { word: 'denuded', word_class: 'AJ' },
    { word: 'denudate', word_class: 'AJ' },
    { word: 'denudation', word_class: 'N' }
  ],
  vanquisher: [
    { word: 'vanquish', word_class: 'V' },
    { word: 'vanquisher', word_class: 'N' },
    { word: 'vanquished', word_class: 'AJ' },
    { word: 'vanquishment', word_class: 'N' },
    { word: 'vanquishable', word_class: 'AJ' }
  ],
  amyloid: [
    { word: 'amyloid', word_class: 'N' },
    { word: 'amyloid', word_class: 'AJ' },
    { word: 'amyloidal', word_class: 'AJ' }
  ],
  tour: [
    { word: 'tour', word_class: 'N' },
    { word: 'tour', word_class: 'V' },
    { word: 'tourer', word_class: 'N' },
    { word: 'tourism', word_class: 'N' },
    { word: 'touring', word_class: 'N' },
    { word: 'tourist', word_class: 'N' },
    { word: 'touring', word_class: 'AJ' },
    { word: 'touristed', word_class: 'AJ' }
  ],
  clamourous: [
    { word: 'clamour', word_class: 'N' },
    { word: 'clamour', word_class: 'V' },
    { word: 'clamouring', word_class: 'N' },
    { word: 'clamourous', word_class: 'AJ' }
  ],
  panegyrical: [
    { word: 'panegyric', word_class: 'N' },
    { word: 'panegyric', word_class: 'AJ' },
    { word: 'panegyrical', word_class: 'AJ' }
  ],
  anarch: [
    { word: 'anarch', word_class: 'N' },
    { word: 'anarchic', word_class: 'AJ' },
    { word: 'anarchism', word_class: 'N' },
    { word: 'anarchical', word_class: 'AJ' },
    { word: 'anarchically', word_class: 'AV' }
  ],
  glittering: [
    { word: 'glitter', word_class: 'N' },
    { word: 'glitter', word_class: 'V' },
    { word: 'glittering', word_class: 'AJ' }
  ],
  knuckles: [
    { word: 'knuckle', word_class: 'N' },
    { word: 'knuckle', word_class: 'V' },
    { word: 'knuckles', word_class: 'N' }
  ],
  spade: [
    { word: 'spade', word_class: 'N' },
    { word: 'spade', word_class: 'V' },
    { word: 'spadeful', word_class: 'N' },
    { word: 'spadeful', word_class: 'AJ' }
  ],
  tranquillisation: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  financy: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  disabling: [
    { word: 'disable', word_class: 'N' },
    { word: 'disable', word_class: 'V' },
    { word: 'disabled', word_class: 'N' },
    { word: 'disable', word_class: 'AJ' },
    { word: 'disabled', word_class: 'AJ' },
    { word: 'disability', word_class: 'N' },
    { word: 'disabling', word_class: 'AJ' },
    { word: 'disablement', word_class: 'N' }
  ],
  articulate: [
    { word: 'articulate', word_class: 'V' },
    { word: 'articulate', word_class: 'AJ' },
    { word: 'articulation', word_class: 'N' },
    { word: 'articulated', word_class: 'AJ' },
    { word: 'articulately', word_class: 'AV' },
    { word: 'articulative', word_class: 'AJ' },
    { word: 'articulateness', word_class: 'N' }
  ],
  edentate: [
    { word: 'edental', word_class: 'AJ' },
    { word: 'edentate', word_class: 'N' },
    { word: 'edentate', word_class: 'AJ' }
  ],
  mischievously: [
    { word: 'mischief', word_class: 'N' },
    { word: 'mischievous', word_class: 'AJ' },
    { word: 'mischievously', word_class: 'AV' },
    { word: 'mischievousness', word_class: 'N' }
  ],
  universalization: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  demilitarization: [
    { word: 'demilitarise', word_class: 'V' },
    { word: 'demilitarize', word_class: 'V' },
    { word: 'demilitarisation', word_class: 'N' },
    { word: 'demilitarization', word_class: 'N' }
  ],
  reprisal: [
    { word: 'reprise', word_class: 'N' },
    { word: 'reprise', word_class: 'V' },
    { word: 'reprisal', word_class: 'N' }
  ],
  sever: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  disadvantage: [
    { word: 'disadvantage', word_class: 'N' },
    { word: 'disadvantage', word_class: 'V' },
    { word: 'disadvantaged', word_class: 'AJ' },
    { word: 'disadvantageous', word_class: 'AJ' },
    { word: 'disadvantageously', word_class: 'AV' }
  ],
  valuator: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  give: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  breadwin: [
    { word: 'breadwin', word_class: 'V' },
    { word: 'breadwinner', word_class: 'N' },
    { word: 'breadwinning', word_class: 'N' }
  ],
  highbrow: [
    { word: 'highbrow', word_class: 'N' },
    { word: 'highbrow', word_class: 'AJ' },
    { word: 'highbrowed', word_class: 'AJ' }
  ],
  ineffectually: [
    { word: 'ineffectual', word_class: 'AJ' },
    { word: 'ineffectuality', word_class: 'N' },
    { word: 'ineffectually', word_class: 'AV' },
    { word: 'ineffectualness', word_class: 'N' }
  ],
  adulterously: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  immoderately: [
    { word: 'immoderate', word_class: 'AJ' },
    { word: 'immoderation', word_class: 'N' },
    { word: 'immoderately', word_class: 'AV' },
    { word: 'immoderateness', word_class: 'N' }
  ],
  disposal: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  appositive: [
    { word: 'appose', word_class: 'V' },
    { word: 'apposite', word_class: 'AJ' },
    { word: 'apposition', word_class: 'N' },
    { word: 'apposable', word_class: 'AJ' },
    { word: 'appositive', word_class: 'AJ' },
    { word: 'appositeness', word_class: 'N' },
    { word: 'appositional', word_class: 'AJ' },
    { word: 'appositively', word_class: 'AV' }
  ],
  perfidy: [
    { word: 'perfidy', word_class: 'N' },
    { word: 'perfidious', word_class: 'AJ' },
    { word: 'perfidiously', word_class: 'AV' },
    { word: 'perfidiousness', word_class: 'N' }
  ],
  artiste: [
    { word: 'artist', word_class: 'N' },
    { word: 'artiste', word_class: 'N' },
    { word: 'artistic', word_class: 'AJ' },
    { word: 'artistically', word_class: 'AV' }
  ],
  litterer: [
    { word: 'litter', word_class: 'N' },
    { word: 'litter', word_class: 'V' },
    { word: 'litterer', word_class: 'N' },
    { word: 'littered', word_class: 'AJ' }
  ],
  channelization: [
    { word: 'channel', word_class: 'N' },
    { word: 'channel', word_class: 'V' },
    { word: 'channels', word_class: 'N' },
    { word: 'channelize', word_class: 'V' },
    { word: 'channelization', word_class: 'N' }
  ],
  discomfit: [
    { word: 'discomfit', word_class: 'N' },
    { word: 'discomfit', word_class: 'V' },
    { word: 'discomfited', word_class: 'N' },
    { word: 'discomfited', word_class: 'AJ' },
    { word: 'discomfiture', word_class: 'N' }
  ],
  acuminate: [
    { word: 'acuminate', word_class: 'V' },
    { word: 'acuminate', word_class: 'AJ' },
    { word: 'acumination', word_class: 'N' }
  ],
  institution: [
    { word: 'institute', word_class: 'N' },
    { word: 'institute', word_class: 'V' },
    { word: 'institution', word_class: 'N' },
    { word: 'institutional', word_class: 'AJ' }
  ],
  income: [
    { word: 'income', word_class: 'N' },
    { word: 'income', word_class: 'V' },
    { word: 'incoming', word_class: 'N' },
    { word: 'incoming', word_class: 'AJ' }
  ],
  staking: [
    { word: 'stake', word_class: 'N' },
    { word: 'stake', word_class: 'V' },
    { word: 'stakes', word_class: 'N' },
    { word: 'staking', word_class: 'N' }
  ],
  renegade: [
    { word: 'renegade', word_class: 'N' },
    { word: 'renegade', word_class: 'V' },
    { word: 'renegade', word_class: 'AJ' }
  ],
  programme: [
    { word: 'programme', word_class: 'N' },
    { word: 'programme', word_class: 'V' },
    { word: 'programmer', word_class: 'N' }
  ],
  iniquitous: [
    { word: 'iniquity', word_class: 'N' },
    { word: 'iniquitous', word_class: 'AJ' },
    { word: 'iniquitously', word_class: 'AV' }
  ],
  expunction: [
    { word: 'expunge', word_class: 'V' },
    { word: 'expunging', word_class: 'N' },
    { word: 'expunction', word_class: 'N' }
  ],
  suppurative: [
    { word: 'suppurate', word_class: 'V' },
    { word: 'suppuration', word_class: 'N' },
    { word: 'suppurative', word_class: 'AJ' }
  ],
  twinning: [
    { word: 'twin', word_class: 'N' },
    { word: 'twin', word_class: 'V' },
    { word: 'twin', word_class: 'AJ' },
    { word: 'twinned', word_class: 'AJ' },
    { word: 'twinning', word_class: 'AJ' }
  ],
  agonist: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  chiseled: [
    { word: 'chisel', word_class: 'N' },
    { word: 'chisel', word_class: 'V' },
    { word: 'chiseler', word_class: 'N' },
    { word: 'chiseled', word_class: 'AJ' },
    { word: 'chiseller', word_class: 'N' }
  ],
  acute: [
    { word: 'acute', word_class: 'N' },
    { word: 'acute', word_class: 'AJ' },
    { word: 'acutely', word_class: 'AV' },
    { word: 'acuteness', word_class: 'N' }
  ],
  peptize: [
    { word: 'peptise', word_class: 'V' },
    { word: 'peptize', word_class: 'V' },
    { word: 'peptizing', word_class: 'N' },
    { word: 'peptizing', word_class: 'AJ' },
    { word: 'peptisation', word_class: 'N' },
    { word: 'peptization', word_class: 'N' }
  ],
  eat: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  dipped: [{ word: 'dip', word_class: 'N' }, { word: 'dip', word_class: 'V' }, { word: 'dipped', word_class: 'AJ' }],
  refreshment: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  rebuttal: [
    { word: 'rebut', word_class: 'V' },
    { word: 'rebuttal', word_class: 'N' },
    { word: 'rebutter', word_class: 'N' }
  ],
  repositing: [
    { word: 'reposit', word_class: 'V' },
    { word: 'repositing', word_class: 'N' },
    { word: 'reposition', word_class: 'N' },
    { word: 'reposition', word_class: 'V' },
    { word: 'repositioning', word_class: 'N' }
  ],
  multiplex: [
    { word: 'multiplex', word_class: 'N' },
    { word: 'multiplex', word_class: 'V' },
    { word: 'multiplex', word_class: 'AJ' },
    { word: 'multiplexer', word_class: 'N' }
  ],
  methodology: [
    { word: 'methodology', word_class: 'N' },
    { word: 'methodological', word_class: 'AJ' },
    { word: 'methodologically', word_class: 'AV' }
  ],
  facilitative: [
    { word: 'facilitate', word_class: 'V' },
    { word: 'facilitator', word_class: 'N' },
    { word: 'facilitated', word_class: 'AJ' },
    { word: 'facilitation', word_class: 'N' },
    { word: 'facilitative', word_class: 'AJ' }
  ],
  bellyful: [
    { word: 'belly', word_class: 'N' },
    { word: 'belly', word_class: 'V' },
    { word: 'belly', word_class: 'AJ' },
    { word: 'bellis', word_class: 'N' },
    { word: 'bellied', word_class: 'AJ' },
    { word: 'bellyful', word_class: 'N' },
    { word: 'belliful', word_class: 'AJ' },
    { word: 'bellying', word_class: 'AJ' }
  ],
  adversity: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  waverer: [
    { word: 'waver', word_class: 'N' },
    { word: 'waver', word_class: 'V' },
    { word: 'waverer', word_class: 'N' },
    { word: 'wavering', word_class: 'N' },
    { word: 'wavering', word_class: 'AJ' }
  ],
  trumpet: [
    { word: 'trumpet', word_class: 'N' },
    { word: 'trumpet', word_class: 'V' },
    { word: 'trumpets', word_class: 'N' },
    { word: 'trumpeter', word_class: 'N' }
  ],
  scandalous: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  allegro: [
    { word: 'allegro', word_class: 'N' },
    { word: 'allegro', word_class: 'AJ' },
    { word: 'allegro', word_class: 'AV' }
  ],
  resistivity: [
    { word: 'resist', word_class: 'V' },
    { word: 'resister', word_class: 'N' },
    { word: 'resistor', word_class: 'N' },
    { word: 'resistant', word_class: 'N' },
    { word: 'resistance', word_class: 'N' },
    { word: 'resistant', word_class: 'AJ' },
    { word: 'resistive', word_class: 'AJ' },
    { word: 'resistible', word_class: 'AJ' },
    { word: 'resistivity', word_class: 'N' }
  ],
  questioning: [
    { word: 'question', word_class: 'N' },
    { word: 'question', word_class: 'V' },
    { word: 'questioner', word_class: 'N' },
    { word: 'questioning', word_class: 'N' },
    { word: 'questioning', word_class: 'AJ' },
    { word: 'questionable', word_class: 'AJ' },
    { word: 'questionably', word_class: 'AV' }
  ],
  heater: [
    { word: 'heat', word_class: 'N' },
    { word: 'heat', word_class: 'V' },
    { word: 'heater', word_class: 'N' },
    { word: 'heated', word_class: 'AJ' },
    { word: 'heating', word_class: 'N' },
    { word: 'heating', word_class: 'AJ' }
  ],
  positioner: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  bricklaying: [
    { word: 'bricklay', word_class: 'V' },
    { word: 'bricklayer', word_class: 'N' },
    { word: 'bricklaying', word_class: 'N' }
  ],
  scandalously: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  rarefy: [
    { word: 'rarefy', word_class: 'V' },
    { word: 'rarefied', word_class: 'AJ' },
    { word: 'rarefication', word_class: 'N' }
  ],
  staleness: [
    { word: 'stale', word_class: 'V' },
    { word: 'stale', word_class: 'AJ' },
    { word: 'staleness', word_class: 'N' }
  ],
  mirror: [
    { word: 'mirror', word_class: 'N' },
    { word: 'mirror', word_class: 'V' },
    { word: 'mirrored', word_class: 'AJ' }
  ],
  aggrieve: [
    { word: 'aggrieve', word_class: 'V' },
    { word: 'aggrieved', word_class: 'AJ' },
    { word: 'aggrievement', word_class: 'N' }
  ],
  abstractive: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  expediency: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  furore: [
    { word: 'fur', word_class: 'N' },
    { word: 'fur', word_class: 'V' },
    { word: 'furor', word_class: 'N' },
    { word: 'furore', word_class: 'N' },
    { word: 'furred', word_class: 'AJ' },
    { word: 'furring', word_class: 'N' }
  ],
  disowning: [
    { word: 'disown', word_class: 'V' },
    { word: 'disowned', word_class: 'AJ' },
    { word: 'disowning', word_class: 'N' },
    { word: 'disownment', word_class: 'N' }
  ],
  avariciousness: [
    { word: 'avarice', word_class: 'N' },
    { word: 'avaricious', word_class: 'AJ' },
    { word: 'avariciously', word_class: 'AV' },
    { word: 'avariciousness', word_class: 'N' }
  ],
  blend: [
    { word: 'blend', word_class: 'N' },
    { word: 'blend', word_class: 'V' },
    { word: 'blende', word_class: 'N' },
    { word: 'blender', word_class: 'N' },
    { word: 'blended', word_class: 'AJ' },
    { word: 'blending', word_class: 'N' },
    { word: 'blending', word_class: 'AJ' }
  ],
  inholding: [
    { word: 'inhold', word_class: 'V' },
    { word: 'inholding', word_class: 'N' },
    { word: 'inholding', word_class: 'AJ' }
  ],
  noticeableness: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  nucleate: [
    { word: 'nucleus', word_class: 'N' },
    { word: 'nucleate', word_class: 'V' },
    { word: 'nucleation', word_class: 'N' }
  ],
  elementary: [
    { word: 'element', word_class: 'N' },
    { word: 'elements', word_class: 'N' },
    { word: 'elemental', word_class: 'AJ' },
    { word: 'elementary', word_class: 'AJ' }
  ],
  manginess: [
    { word: 'mange', word_class: 'N' },
    { word: 'mangy', word_class: 'AJ' },
    { word: 'manginess', word_class: 'N' }
  ],
  termination: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  thronged: [
    { word: 'throng', word_class: 'N' },
    { word: 'throng', word_class: 'V' },
    { word: 'thronged', word_class: 'AJ' }
  ],
  collectivistic: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  aviation: [
    { word: 'aviate', word_class: 'V' },
    { word: 'aviator', word_class: 'N' },
    { word: 'aviation', word_class: 'N' }
  ],
  recounting: [
    { word: 'recount', word_class: 'N' },
    { word: 'recount', word_class: 'V' },
    { word: 'recountal', word_class: 'N' },
    { word: 'recounting', word_class: 'N' }
  ],
  ejaculation: [
    { word: 'ejaculate', word_class: 'N' },
    { word: 'ejaculate', word_class: 'V' },
    { word: 'ejaculator', word_class: 'N' },
    { word: 'ejaculation', word_class: 'N' }
  ],
  verified: [
    { word: 'verify', word_class: 'V' },
    { word: 'verified', word_class: 'AJ' },
    { word: 'verifying', word_class: 'AJ' },
    { word: 'verifiable', word_class: 'AJ' },
    { word: 'verification', word_class: 'N' }
  ],
  furnish: [
    { word: 'furnish', word_class: 'V' },
    { word: 'furnished', word_class: 'AJ' },
    { word: 'furnishing', word_class: 'N' },
    { word: 'furnishings', word_class: 'N' }
  ],
  indecorousness: [
    { word: 'indecorous', word_class: 'AJ' },
    { word: 'indecorously', word_class: 'AV' },
    { word: 'indecorousness', word_class: 'N' }
  ],
  acridness: [
    { word: 'acrid', word_class: 'AJ' },
    { word: 'acridity', word_class: 'N' },
    { word: 'acridness', word_class: 'N' }
  ],
  perfidiously: [
    { word: 'perfidy', word_class: 'N' },
    { word: 'perfidious', word_class: 'AJ' },
    { word: 'perfidiously', word_class: 'AV' },
    { word: 'perfidiousness', word_class: 'N' }
  ],
  enlistee: [
    { word: 'enlist', word_class: 'V' },
    { word: 'enlistee', word_class: 'N' },
    { word: 'enlisted', word_class: 'AJ' },
    { word: 'enlisting', word_class: 'N' },
    { word: 'enlistment', word_class: 'N' }
  ],
  efficient: [
    { word: 'efficiency', word_class: 'N' },
    { word: 'efficient', word_class: 'AJ' },
    { word: 'efficiently', word_class: 'AV' }
  ],
  unrealized: [
    { word: 'unreal', word_class: 'AJ' },
    { word: 'unrealism', word_class: 'N' },
    { word: 'unreality', word_class: 'N' },
    { word: 'unrealized', word_class: 'AJ' }
  ],
  irritate: [
    { word: 'irritant', word_class: 'N' },
    { word: 'irritate', word_class: 'V' },
    { word: 'irritable', word_class: 'AJ' },
    { word: 'irritably', word_class: 'AV' },
    { word: 'irritated', word_class: 'AJ' },
    { word: 'irritation', word_class: 'N' },
    { word: 'irritating', word_class: 'AJ' },
    { word: 'irritative', word_class: 'AJ' },
    { word: 'irritability', word_class: 'N' }
  ],
  leger: [
    { word: 'leg', word_class: 'N' },
    { word: 'leg', word_class: 'V' },
    { word: 'leger', word_class: 'N' },
    { word: 'legacy', word_class: 'N' },
    { word: 'legate', word_class: 'N' },
    { word: 'legate', word_class: 'V' },
    { word: 'legion', word_class: 'N' },
    { word: 'legged', word_class: 'AJ' },
    { word: 'legging', word_class: 'N' },
    { word: 'legation', word_class: 'N' },
    { word: 'legerity', word_class: 'N' }
  ],
  blastopore: [
    { word: 'blastopore', word_class: 'N' },
    { word: 'blastoporal', word_class: 'AJ' },
    { word: 'blastoporic', word_class: 'AJ' }
  ],
  inclusion: [
    { word: 'include', word_class: 'V' },
    { word: 'included', word_class: 'AJ' },
    { word: 'inclusion', word_class: 'N' },
    { word: 'inclusive', word_class: 'AJ' }
  ],
  catoptrics: [
    { word: 'catoptric', word_class: 'AJ' },
    { word: 'catoptrics', word_class: 'N' },
    { word: 'catoptrical', word_class: 'AJ' }
  ],
  pearlite: [
    { word: 'pearl', word_class: 'N' },
    { word: 'pearl', word_class: 'V' },
    { word: 'pearl', word_class: 'AJ' },
    { word: 'pearler', word_class: 'N' },
    { word: 'pearlite', word_class: 'N' }
  ],
  function: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  editorialise: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  corner: [
    { word: 'corner', word_class: 'N' },
    { word: 'corner', word_class: 'V' },
    { word: 'cornered', word_class: 'AJ' }
  ],
  anagrammatization: [
    { word: 'anagrammatic', word_class: 'AJ' },
    { word: 'anagrammatize', word_class: 'V' },
    { word: 'anagrammatical', word_class: 'AJ' },
    { word: 'anagrammatization', word_class: 'N' }
  ],
  abls: [
    { word: 'abls', word_class: 'N' },
    { word: 'able', word_class: 'AJ' },
    { word: 'ably', word_class: 'AV' },
    { word: 'ability', word_class: 'N' },
    { word: 'ablative', word_class: 'N' },
    { word: 'ablative', word_class: 'AJ' }
  ],
  decompose: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  impracticable: [
    { word: 'impracticable', word_class: 'AJ' },
    { word: 'impracticably', word_class: 'AV' },
    { word: 'impracticability', word_class: 'N' },
    { word: 'impracticableness', word_class: 'N' }
  ],
  devitalise: [
    { word: 'devitalise', word_class: 'V' },
    { word: 'devitalize', word_class: 'V' },
    { word: 'devitalisation', word_class: 'N' },
    { word: 'devitalization', word_class: 'N' }
  ],
  negotiant: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  investment: [
    { word: 'invest', word_class: 'N' },
    { word: 'invest', word_class: 'V' },
    { word: 'investor', word_class: 'N' },
    { word: 'invested', word_class: 'AJ' },
    { word: 'investing', word_class: 'N' },
    { word: 'investment', word_class: 'N' }
  ],
  eudemonism: [
    { word: 'eudemon', word_class: 'N' },
    { word: 'eudemonic', word_class: 'AJ' },
    { word: 'eudemonism', word_class: 'N' }
  ],
  trickery: [
    { word: 'trick', word_class: 'N' },
    { word: 'trick', word_class: 'V' },
    { word: 'trickery', word_class: 'N' },
    { word: 'trickery', word_class: 'AJ' }
  ],
  saver: [
    { word: 'save', word_class: 'N' },
    { word: 'save', word_class: 'V' },
    { word: 'savor', word_class: 'N' },
    { word: 'saver', word_class: 'N' },
    { word: 'savor', word_class: 'V' },
    { word: 'savant', word_class: 'N' },
    { word: 'saving', word_class: 'N' },
    { word: 'saved', word_class: 'AJ' },
    { word: 'saving', word_class: 'AJ' },
    { word: 'savings', word_class: 'N' },
    { word: 'savoring', word_class: 'N' }
  ],
  maneuverable: [
    { word: 'maneuverer', word_class: 'N' },
    { word: 'maneuverable', word_class: 'AJ' },
    { word: 'maneuverability', word_class: 'N' }
  ],
  impertinently: [
    { word: 'impertinence', word_class: 'N' },
    { word: 'impertinent', word_class: 'AJ' },
    { word: 'impertinently', word_class: 'AV' }
  ],
  vertebral: [
    { word: 'vertebral', word_class: 'AJ' },
    { word: 'vertebrate', word_class: 'N' },
    { word: 'vertebrate', word_class: 'AJ' }
  ],
  locomote: [
    { word: 'locomote', word_class: 'V' },
    { word: 'locomotion', word_class: 'N' },
    { word: 'locomotive', word_class: 'N' },
    { word: 'locomotive', word_class: 'AJ' }
  ],
  volatilized: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  limpness: [
    { word: 'limp', word_class: 'N' },
    { word: 'limp', word_class: 'V' },
    { word: 'limp', word_class: 'AJ' },
    { word: 'limping', word_class: 'N' },
    { word: 'limpness', word_class: 'N' }
  ],
  usual: [
    { word: 'usual', word_class: 'AJ' },
    { word: 'usually', word_class: 'AV' },
    { word: 'usualness', word_class: 'N' }
  ],
  arrangement: [
    { word: 'arrange', word_class: 'V' },
    { word: 'arranger', word_class: 'N' },
    { word: 'arranged', word_class: 'AJ' },
    { word: 'arranging', word_class: 'N' },
    { word: 'arrangement', word_class: 'N' }
  ],
  socialized: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  affronted: [
    { word: 'affront', word_class: 'N' },
    { word: 'affront', word_class: 'V' },
    { word: 'affronted', word_class: 'AJ' }
  ],
  consanguinity: [
    { word: 'consanguine', word_class: 'AJ' },
    { word: 'consanguinity', word_class: 'N' },
    { word: 'consanguineous', word_class: 'AJ' }
  ],
  fulsome: [
    { word: 'fulsome', word_class: 'AJ' },
    { word: 'fulsomely', word_class: 'AV' },
    { word: 'fulsomeness', word_class: 'N' }
  ],
  laureation: [
    { word: 'laureate', word_class: 'N' },
    { word: 'laureate', word_class: 'V' },
    { word: 'laureate', word_class: 'AJ' },
    { word: 'laureation', word_class: 'N' }
  ],
  justle: [
    { word: 'jostle', word_class: 'N' },
    { word: 'jostle', word_class: 'V' },
    { word: 'justle', word_class: 'V' },
    { word: 'jostling', word_class: 'N' },
    { word: 'jostlement', word_class: 'N' },
    { word: 'justlement', word_class: 'N' }
  ],
  bowlegged: [
    { word: 'bowleg', word_class: 'N' },
    { word: 'bowleg', word_class: 'AJ' },
    { word: 'bowlegged', word_class: 'AJ' }
  ],
  hoyden: [
    { word: 'hoyden', word_class: 'N' },
    { word: 'hoyden', word_class: 'AJ' },
    { word: 'hoydenism', word_class: 'N' }
  ],
  subcontractor: [
    { word: 'subcontract', word_class: 'N' },
    { word: 'subcontract', word_class: 'V' },
    { word: 'subcontractor', word_class: 'N' }
  ],
  impossibleness: [
    { word: 'impossible', word_class: 'N' },
    { word: 'impossible', word_class: 'AJ' },
    { word: 'impossibly', word_class: 'AV' },
    { word: 'impossibility', word_class: 'N' },
    { word: 'impossibleness', word_class: 'N' }
  ],
  impureness: [
    { word: 'impure', word_class: 'AJ' },
    { word: 'impurity', word_class: 'N' },
    { word: 'impureness', word_class: 'N' }
  ],
  bragging: [
    { word: 'brag', word_class: 'N' },
    { word: 'brag', word_class: 'V' },
    { word: 'brag', word_class: 'AJ' },
    { word: 'bragging', word_class: 'N' },
    { word: 'bragging', word_class: 'AJ' },
    { word: 'braggadocio', word_class: 'N' }
  ],
  autotomize: [
    { word: 'autotomic', word_class: 'AJ' },
    { word: 'autotomize', word_class: 'V' },
    { word: 'autotomization', word_class: 'N' }
  ],
  sleepwalking: [
    { word: 'sleepwalk', word_class: 'V' },
    { word: 'sleepwalker', word_class: 'N' },
    { word: 'sleepwalking', word_class: 'N' }
  ],
  pensionable: [
    { word: 'pension', word_class: 'N' },
    { word: 'pension', word_class: 'V' },
    { word: 'pensioner', word_class: 'N' },
    { word: 'pensionable', word_class: 'AJ' }
  ],
  mar: [
    { word: 'mar', word_class: 'N' },
    { word: 'mar', word_class: 'V' },
    { word: 'mars', word_class: 'N' },
    { word: 'marred', word_class: 'AJ' }
  ],
  weak: [
    { word: 'weak', word_class: 'AJ' },
    { word: 'weakling', word_class: 'N' },
    { word: 'weakness', word_class: 'N' }
  ],
  complacent: [
    { word: 'complacence', word_class: 'N' },
    { word: 'complacency', word_class: 'N' },
    { word: 'complacent', word_class: 'AJ' },
    { word: 'complacently', word_class: 'AV' }
  ],
  stunning: [
    { word: 'stun', word_class: 'V' },
    { word: 'stunned', word_class: 'AJ' },
    { word: 'stunning', word_class: 'AJ' }
  ],
  dilation: [
    { word: 'dilate', word_class: 'V' },
    { word: 'dilate', word_class: 'AJ' },
    { word: 'dilated', word_class: 'AJ' },
    { word: 'dilation', word_class: 'N' },
    { word: 'dilatation', word_class: 'N' }
  ],
  translucent: [
    { word: 'translucence', word_class: 'N' },
    { word: 'translucency', word_class: 'N' },
    { word: 'translucent', word_class: 'AJ' }
  ],
  ammoniacal: [
    { word: 'ammoniac', word_class: 'N' },
    { word: 'ammoniac', word_class: 'AJ' },
    { word: 'ammoniacal', word_class: 'AJ' }
  ],
  legitimacy: [
    { word: 'legitimacy', word_class: 'N' },
    { word: 'legitimate', word_class: 'V' },
    { word: 'legitimize', word_class: 'V' },
    { word: 'legitimate', word_class: 'AJ' },
    { word: 'legitimation', word_class: 'N' },
    { word: 'legitimately', word_class: 'AV' },
    { word: 'legitimization', word_class: 'N' }
  ],
  maltreated: [
    { word: 'maltreat', word_class: 'V' },
    { word: 'maltreated', word_class: 'AJ' },
    { word: 'maltreatment', word_class: 'N' }
  ],
  hush: [{ word: 'hush', word_class: 'N' }, { word: 'hush', word_class: 'V' }, { word: 'hushed', word_class: 'AJ' }],
  lilting: [
    { word: 'lilt', word_class: 'N' },
    { word: 'lilt', word_class: 'V' },
    { word: 'lilting', word_class: 'AJ' }
  ],
  derisive: [
    { word: 'deride', word_class: 'V' },
    { word: 'derision', word_class: 'N' },
    { word: 'derisive', word_class: 'AJ' },
    { word: 'derisively', word_class: 'AV' }
  ],
  cavalier: [
    { word: 'cavaly', word_class: 'AJ' },
    { word: 'cavalier', word_class: 'N' },
    { word: 'cavalier', word_class: 'AJ' }
  ],
  unitarianism: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  incompleteness: [
    { word: 'incomplete', word_class: 'AJ' },
    { word: 'incompletion', word_class: 'N' },
    { word: 'incompletely', word_class: 'AV' },
    { word: 'incompleteness', word_class: 'N' }
  ],
  conflict: [
    { word: 'conflict', word_class: 'N' },
    { word: 'conflict', word_class: 'V' },
    { word: 'confliction', word_class: 'N' },
    { word: 'conflicting', word_class: 'AJ' }
  ],
  phonetics: [
    { word: 'phonetic', word_class: 'AJ' },
    { word: 'phonetics', word_class: 'N' },
    { word: 'phonetically', word_class: 'AV' }
  ],
  roomful: [
    { word: 'room', word_class: 'N' },
    { word: 'room', word_class: 'V' },
    { word: 'rooms', word_class: 'N' },
    { word: 'roomer', word_class: 'N' },
    { word: 'roomful', word_class: 'N' },
    { word: 'roomful', word_class: 'AJ' }
  ],
  sprint: [
    { word: 'sprint', word_class: 'N' },
    { word: 'sprint', word_class: 'V' },
    { word: 'sprinter', word_class: 'N' }
  ],
  gimbals: [
    { word: 'gimbal', word_class: 'N' },
    { word: 'gimbal', word_class: 'V' },
    { word: 'gimbals', word_class: 'N' },
    { word: 'gimbaled', word_class: 'AJ' }
  ],
  nipping: [{ word: 'nip', word_class: 'N' }, { word: 'nip', word_class: 'V' }, { word: 'nipping', word_class: 'AJ' }],
  regardless: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  mumble: [
    { word: 'mumble', word_class: 'V' },
    { word: 'mumbler', word_class: 'N' },
    { word: 'mumbling', word_class: 'N' },
    { word: 'mumbling', word_class: 'AJ' }
  ],
  sanitise: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  bungler: [
    { word: 'bungle', word_class: 'N' },
    { word: 'bungle', word_class: 'V' },
    { word: 'bungler', word_class: 'N' },
    { word: 'bungled', word_class: 'AJ' },
    { word: 'bungling', word_class: 'N' },
    { word: 'bungling', word_class: 'AJ' }
  ],
  notify: [
    { word: 'notify', word_class: 'V' },
    { word: 'notifying', word_class: 'N' },
    { word: 'notifiable', word_class: 'AJ' },
    { word: 'notification', word_class: 'N' }
  ],
  hungry: [
    { word: 'hunger', word_class: 'N' },
    { word: 'hunger', word_class: 'V' },
    { word: 'hungry', word_class: 'AJ' },
    { word: 'hungriness', word_class: 'N' }
  ],
  ennobling: [
    { word: 'ennoble', word_class: 'V' },
    { word: 'ennobling', word_class: 'AJ' },
    { word: 'ennoblement', word_class: 'N' }
  ],
  basidiomycetous: [
    { word: 'basidiomycete', word_class: 'N' },
    { word: 'basidiomycetes', word_class: 'N' },
    { word: 'basidiomycetous', word_class: 'AJ' }
  ],
  pearl: [
    { word: 'pearl', word_class: 'N' },
    { word: 'pearl', word_class: 'V' },
    { word: 'pearl', word_class: 'AJ' },
    { word: 'pearler', word_class: 'N' },
    { word: 'pearlite', word_class: 'N' }
  ],
  occident: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  bicorned: [
    { word: 'bicorn', word_class: 'N' },
    { word: 'bicorn', word_class: 'AJ' },
    { word: 'bicorne', word_class: 'N' },
    { word: 'bicorned', word_class: 'AJ' },
    { word: 'bicornate', word_class: 'AJ' }
  ],
  domestic: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  marine: [
    { word: 'marine', word_class: 'N' },
    { word: 'marine', word_class: 'AJ' },
    { word: 'mariner', word_class: 'N' },
    { word: 'marinade', word_class: 'N' },
    { word: 'marinade', word_class: 'V' },
    { word: 'marinate', word_class: 'V' },
    { word: 'marination', word_class: 'N' }
  ],
  fatally: [
    { word: 'fatal', word_class: 'AJ' },
    { word: 'fatalism', word_class: 'N' },
    { word: 'fatality', word_class: 'N' },
    { word: 'fatally', word_class: 'AV' }
  ],
  ancient: [
    { word: 'ancient', word_class: 'N' },
    { word: 'ancient', word_class: 'AJ' },
    { word: 'anciently', word_class: 'AV' },
    { word: 'ancientness', word_class: 'N' }
  ],
  concretism: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  communicable: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  main: [{ word: 'main', word_class: 'N' }, { word: 'main', word_class: 'AJ' }, { word: 'maine', word_class: 'N' }],
  levant: [
    { word: 'levant', word_class: 'N' },
    { word: 'levant', word_class: 'V' },
    { word: 'levanter', word_class: 'N' }
  ],
  hobnail: [
    { word: 'hobnail', word_class: 'N' },
    { word: 'hobnail', word_class: 'V' },
    { word: 'hobnail', word_class: 'AJ' },
    { word: 'hobnailed', word_class: 'AJ' }
  ],
  atrophic: [
    { word: 'atrophy', word_class: 'N' },
    { word: 'atrophy', word_class: 'V' },
    { word: 'atrophic', word_class: 'AJ' },
    { word: 'atrophied', word_class: 'AJ' }
  ],
  adulate: [
    { word: 'adulate', word_class: 'V' },
    { word: 'adulator', word_class: 'N' },
    { word: 'adulation', word_class: 'N' }
  ],
  assimilating: [
    { word: 'assimilate', word_class: 'V' },
    { word: 'assimilable', word_class: 'AJ' },
    { word: 'assimilation', word_class: 'N' },
    { word: 'assimilating', word_class: 'AJ' },
    { word: 'assimilative', word_class: 'AJ' }
  ],
  stated: [
    { word: 'state', word_class: 'N' },
    { word: 'state', word_class: 'V' },
    { word: 'state', word_class: 'AJ' },
    { word: 'stator', word_class: 'N' },
    { word: 'stated', word_class: 'AJ' },
    { word: 'stately', word_class: 'AJ' },
    { word: 'statement', word_class: 'N' }
  ],
  partaker: [
    { word: 'partake', word_class: 'V' },
    { word: 'partaker', word_class: 'N' },
    { word: 'partaken', word_class: 'AJ' }
  ],
  seam: [
    { word: 'seam', word_class: 'N' },
    { word: 'seam', word_class: 'V' },
    { word: 'seamed', word_class: 'AJ' },
    { word: 'seamstress', word_class: 'N' }
  ],
  acidophile: [
    { word: 'acidophil', word_class: 'N' },
    { word: 'acidophile', word_class: 'N' },
    { word: 'acidophilic', word_class: 'AJ' },
    { word: 'acidophilous', word_class: 'AJ' }
  ],
  disaffirmation: [
    { word: 'disaffirm', word_class: 'V' },
    { word: 'disaffirmance', word_class: 'N' },
    { word: 'disaffirmation', word_class: 'N' }
  ],
  chew: [{ word: 'chew', word_class: 'N' }, { word: 'chew', word_class: 'V' }, { word: 'chewing', word_class: 'N' }],
  pardonable: [
    { word: 'pardon', word_class: 'N' },
    { word: 'pardon', word_class: 'V' },
    { word: 'pardoner', word_class: 'N' },
    { word: 'pardonable', word_class: 'AJ' },
    { word: 'pardonably', word_class: 'AV' }
  ],
  traditionality: [
    { word: 'traditionalism', word_class: 'N' },
    { word: 'traditionality', word_class: 'N' },
    { word: 'traditionally', word_class: 'AV' }
  ],
  deepfrozen: [
    { word: 'deepfreeze', word_class: 'N' },
    { word: 'deepfreeze', word_class: 'V' },
    { word: 'deepfrozen', word_class: 'AJ' }
  ],
  genially: [
    { word: 'genial', word_class: 'AJ' },
    { word: 'geniality', word_class: 'N' },
    { word: 'genially', word_class: 'AV' }
  ],
  suppliant: [
    { word: 'supply', word_class: 'N' },
    { word: 'supply', word_class: 'V' },
    { word: 'supplier', word_class: 'N' },
    { word: 'supplies', word_class: 'N' },
    { word: 'suppliant', word_class: 'N' },
    { word: 'supplying', word_class: 'N' },
    { word: 'suppliant', word_class: 'AJ' }
  ],
  prospering: [
    { word: 'prosper', word_class: 'V' },
    { word: 'prosperity', word_class: 'N' },
    { word: 'prospering', word_class: 'AJ' },
    { word: 'prosperous', word_class: 'AJ' },
    { word: 'prosperously', word_class: 'AV' }
  ],
  memorialization: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  commentate: [
    { word: 'comment', word_class: 'N' },
    { word: 'comment', word_class: 'V' },
    { word: 'commentary', word_class: 'N' },
    { word: 'commentate', word_class: 'V' },
    { word: 'commentator', word_class: 'N' },
    { word: 'commentation', word_class: 'N' }
  ],
  stoop: [
    { word: 'stoop', word_class: 'N' },
    { word: 'stoop', word_class: 'V' },
    { word: 'stooped', word_class: 'AJ' },
    { word: 'stooping', word_class: 'AJ' }
  ],
  waterproofed: [
    { word: 'waterproof', word_class: 'N' },
    { word: 'waterproof', word_class: 'V' },
    { word: 'waterproof', word_class: 'AJ' },
    { word: 'waterproofed', word_class: 'AJ' },
    { word: 'waterproofing', word_class: 'N' }
  ],
  so: [
    { word: 'so', word_class: 'N' },
    { word: 'so', word_class: 'AJ' },
    { word: 'so', word_class: 'AV' },
    { word: 'sos', word_class: 'N' }
  ],
  autotomic: [
    { word: 'autotomic', word_class: 'AJ' },
    { word: 'autotomize', word_class: 'V' },
    { word: 'autotomization', word_class: 'N' }
  ],
  prepare: [
    { word: 'prepare', word_class: 'V' },
    { word: 'prepared', word_class: 'AJ' },
    { word: 'preparation', word_class: 'N' },
    { word: 'preparative', word_class: 'N' },
    { word: 'preparative', word_class: 'AJ' }
  ],
  heartbreaker: [
    { word: 'heartbreak', word_class: 'N' },
    { word: 'heartbreaker', word_class: 'N' },
    { word: 'heartbreaking', word_class: 'AJ' }
  ],
  refrigerated: [
    { word: 'refrigerant', word_class: 'N' },
    { word: 'refrigerate', word_class: 'V' },
    { word: 'refrigerant', word_class: 'AJ' },
    { word: 'refrigerator', word_class: 'N' },
    { word: 'refrigerated', word_class: 'AJ' },
    { word: 'refrigeration', word_class: 'N' },
    { word: 'refrigerating', word_class: 'AJ' }
  ],
  apostrophic: [
    { word: 'apostrophe', word_class: 'N' },
    { word: 'apostrophic', word_class: 'AJ' },
    { word: 'apostrophize', word_class: 'V' },
    { word: 'apostrophization', word_class: 'N' }
  ],
  terrified: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  nettlesome: [
    { word: 'nettle', word_class: 'N' },
    { word: 'nettle', word_class: 'V' },
    { word: 'nettled', word_class: 'AJ' },
    { word: 'nettlesome', word_class: 'AJ' }
  ],
  corrugation: [
    { word: 'corrugate', word_class: 'V' },
    { word: 'corrugated', word_class: 'AJ' },
    { word: 'corrugation', word_class: 'N' }
  ],
  stressful: [
    { word: 'stress', word_class: 'N' },
    { word: 'stress', word_class: 'V' },
    { word: 'stressed', word_class: 'AJ' },
    { word: 'stressful', word_class: 'AJ' }
  ],
  calculability: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  snuffly: [
    { word: 'snuffle', word_class: 'N' },
    { word: 'snuffle', word_class: 'V' },
    { word: 'snuffly', word_class: 'AJ' },
    { word: 'snuffling', word_class: 'AJ' }
  ],
  annotation: [
    { word: 'annotate', word_class: 'V' },
    { word: 'annotating', word_class: 'N' },
    { word: 'annotation', word_class: 'N' }
  ],
  murmurous: [
    { word: 'murmur', word_class: 'N' },
    { word: 'murmur', word_class: 'V' },
    { word: 'murmuring', word_class: 'N' },
    { word: 'murmuring', word_class: 'AJ' },
    { word: 'murmurous', word_class: 'AJ' },
    { word: 'murmuration', word_class: 'N' }
  ],
  sweeping: [
    { word: 'sweep', word_class: 'N' },
    { word: 'sweep', word_class: 'V' },
    { word: 'sweeper', word_class: 'N' },
    { word: 'sweeping', word_class: 'N' },
    { word: 'sweeping', word_class: 'AJ' }
  ],
  abnormal: [
    { word: 'abnormal', word_class: 'AJ' },
    { word: 'abnormality', word_class: 'N' },
    { word: 'abnormally', word_class: 'AV' }
  ],
  thermodynamics: [
    { word: 'thermodynamic', word_class: 'AJ' },
    { word: 'thermodynamics', word_class: 'N' },
    { word: 'thermodynamical', word_class: 'AJ' },
    { word: 'thermodynamically', word_class: 'AV' }
  ],
  ionize: [
    { word: 'ionize', word_class: 'V' },
    { word: 'ionized', word_class: 'AJ' },
    { word: 'ionization', word_class: 'N' }
  ],
  osmotically: [
    { word: 'osmose', word_class: 'V' },
    { word: 'osmosis', word_class: 'N' },
    { word: 'osmotic', word_class: 'AJ' },
    { word: 'osmotically', word_class: 'AV' }
  ],
  expunging: [
    { word: 'expunge', word_class: 'V' },
    { word: 'expunging', word_class: 'N' },
    { word: 'expunction', word_class: 'N' }
  ],
  illustrate: [
    { word: 'illustrate', word_class: 'V' },
    { word: 'illustrator', word_class: 'N' },
    { word: 'illustrated', word_class: 'AJ' },
    { word: 'illustration', word_class: 'N' },
    { word: 'illustrative', word_class: 'AJ' }
  ],
  planning: [
    { word: 'plan', word_class: 'N' },
    { word: 'plan', word_class: 'V' },
    { word: 'planned', word_class: 'AJ' },
    { word: 'planning', word_class: 'N' }
  ],
  sands: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  eradicable: [
    { word: 'eradicate', word_class: 'V' },
    { word: 'eradicator', word_class: 'N' },
    { word: 'eradicable', word_class: 'AJ' },
    { word: 'eradication', word_class: 'N' }
  ],
  memorably: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  jeweler: [
    { word: 'jewel', word_class: 'N' },
    { word: 'jewel', word_class: 'V' },
    { word: 'jeweler', word_class: 'N' },
    { word: 'jewelry', word_class: 'N' },
    { word: 'jeweled', word_class: 'AJ' },
    { word: 'jeweller', word_class: 'N' },
    { word: 'jewelled', word_class: 'AJ' }
  ],
  assigned: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  operational: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  indubitability: [
    { word: 'indubitable', word_class: 'AJ' },
    { word: 'indubitably', word_class: 'AV' },
    { word: 'indubitability', word_class: 'N' }
  ],
  fascist: [
    { word: 'fascist', word_class: 'N' },
    { word: 'fascist', word_class: 'AJ' },
    { word: 'fascistic', word_class: 'AJ' }
  ],
  refractivity: [
    { word: 'refract', word_class: 'V' },
    { word: 'refraction', word_class: 'N' },
    { word: 'refractive', word_class: 'AJ' },
    { word: 'refractivity', word_class: 'N' },
    { word: 'refractiveness', word_class: 'N' }
  ],
  benediction: [
    { word: 'benedict', word_class: 'N' },
    { word: 'benediction', word_class: 'N' },
    { word: 'benedictive', word_class: 'AJ' }
  ],
  broad: [
    { word: 'broad', word_class: 'N' },
    { word: 'broad', word_class: 'AJ' },
    { word: 'breadth', word_class: 'N' },
    { word: 'broadness', word_class: 'N' }
  ],
  facilitated: [
    { word: 'facilitate', word_class: 'V' },
    { word: 'facilitator', word_class: 'N' },
    { word: 'facilitated', word_class: 'AJ' },
    { word: 'facilitation', word_class: 'N' },
    { word: 'facilitative', word_class: 'AJ' }
  ],
  disunion: [
    { word: 'disunion', word_class: 'N' },
    { word: 'disunite', word_class: 'V' },
    { word: 'disunited', word_class: 'AJ' }
  ],
  born: [
    { word: 'bear', word_class: 'N' },
    { word: 'bear', word_class: 'V' },
    { word: 'born', word_class: 'AJ' },
    { word: 'bearer', word_class: 'N' },
    { word: 'borne', word_class: 'AJ' },
    { word: 'bearing', word_class: 'N' },
    { word: 'bearing', word_class: 'AJ' }
  ],
  subtilize: [
    { word: 'subtilise', word_class: 'V' },
    { word: 'subtilize', word_class: 'V' },
    { word: 'subtilisation', word_class: 'N' },
    { word: 'subtilization', word_class: 'N' }
  ],
  etcher: [
    { word: 'etch', word_class: 'N' },
    { word: 'etch', word_class: 'V' },
    { word: 'etcher', word_class: 'N' },
    { word: 'etched', word_class: 'AJ' },
    { word: 'etching', word_class: 'N' }
  ],
  deserve: [
    { word: 'deserve', word_class: 'V' },
    { word: 'deserved', word_class: 'AJ' },
    { word: 'deserving', word_class: 'AJ' }
  ],
  consequently: [
    { word: 'consequent', word_class: 'N' },
    { word: 'consequence', word_class: 'N' },
    { word: 'consequent', word_class: 'AJ' },
    { word: 'consequently', word_class: 'AV' }
  ],
  petalled: [
    { word: 'petal', word_class: 'N' },
    { word: 'petaled', word_class: 'AJ' },
    { word: 'petalled', word_class: 'AJ' },
    { word: 'petalous', word_class: 'AJ' }
  ],
  pervading: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  devilment: [
    { word: 'devil', word_class: 'N' },
    { word: 'devil', word_class: 'V' },
    { word: 'devilize', word_class: 'V' },
    { word: 'devilment', word_class: 'N' }
  ],
  hypersensitise: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  contaminating: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  innocence: [
    { word: 'innocent', word_class: 'N' },
    { word: 'innocence', word_class: 'N' },
    { word: 'innocency', word_class: 'N' },
    { word: 'innocent', word_class: 'AJ' },
    { word: 'innocently', word_class: 'AV' }
  ],
  disobey: [
    { word: 'disobey', word_class: 'V' },
    { word: 'disobedience', word_class: 'N' },
    { word: 'disobedient', word_class: 'AJ' },
    { word: 'disobediently', word_class: 'AV' }
  ],
  supposal: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  seats: [
    { word: 'seat', word_class: 'N' },
    { word: 'seat', word_class: 'V' },
    { word: 'seats', word_class: 'N' },
    { word: 'seated', word_class: 'AJ' },
    { word: 'seating', word_class: 'N' }
  ],
  tyranny: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  smack: [
    { word: 'smack', word_class: 'N' },
    { word: 'smack', word_class: 'V' },
    { word: 'smack', word_class: 'AV' },
    { word: 'smacker', word_class: 'N' },
    { word: 'smacking', word_class: 'N' }
  ],
  manned: [
    { word: 'man', word_class: 'V' },
    { word: 'man', word_class: 'N' },
    { word: 'manor', word_class: 'N' },
    { word: 'manned', word_class: 'AJ' },
    { word: 'manful', word_class: 'AJ' },
    { word: 'manfulness', word_class: 'N' }
  ],
  outrageous: [
    { word: 'outrage', word_class: 'N' },
    { word: 'outrage', word_class: 'V' },
    { word: 'outraged', word_class: 'AJ' },
    { word: 'outrageous', word_class: 'AJ' },
    { word: 'outrageously', word_class: 'AV' },
    { word: 'outrageousness', word_class: 'N' }
  ],
  attractive: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  eerie: [
    { word: 'eery', word_class: 'AJ' },
    { word: 'eerie', word_class: 'AJ' },
    { word: 'eeriness', word_class: 'N' }
  ],
  bobbysock: [
    { word: 'bobbysock', word_class: 'N' },
    { word: 'bobbysocks', word_class: 'N' },
    { word: 'bobbysocker', word_class: 'N' }
  ],
  stuffing: [
    { word: 'stuff', word_class: 'N' },
    { word: 'stuff', word_class: 'V' },
    { word: 'stuffed', word_class: 'AJ' },
    { word: 'stuffing', word_class: 'N' }
  ],
  vitriolization: [
    { word: 'vitriol', word_class: 'N' },
    { word: 'vitriolise', word_class: 'V' },
    { word: 'vitriolic', word_class: 'AJ' },
    { word: 'vitriolize', word_class: 'V' },
    { word: 'vitriolically', word_class: 'AV' },
    { word: 'vitriolisation', word_class: 'N' },
    { word: 'vitriolization', word_class: 'N' }
  ],
  evangelistic: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  telephonist: [
    { word: 'telephone', word_class: 'N' },
    { word: 'telephone', word_class: 'V' },
    { word: 'telephoner', word_class: 'N' },
    { word: 'telephonic', word_class: 'AJ' },
    { word: 'telephonist', word_class: 'N' }
  ],
  predigest: [
    { word: 'predigest', word_class: 'V' },
    { word: 'predigested', word_class: 'AJ' },
    { word: 'predigestion', word_class: 'N' }
  ],
  splashing: [
    { word: 'splash', word_class: 'N' },
    { word: 'splash', word_class: 'V' },
    { word: 'splashed', word_class: 'AJ' },
    { word: 'splashing', word_class: 'N' }
  ],
  vital: [
    { word: 'vital', word_class: 'AJ' },
    { word: 'vitals', word_class: 'N' },
    { word: 'vitalise', word_class: 'V' },
    { word: 'vitalism', word_class: 'N' },
    { word: 'vitalize', word_class: 'V' },
    { word: 'vitalist', word_class: 'N' },
    { word: 'vitality', word_class: 'N' },
    { word: 'vitally', word_class: 'AV' },
    { word: 'vitalness', word_class: 'N' },
    { word: 'vitalizing', word_class: 'AJ' },
    { word: 'vitalisation', word_class: 'N' },
    { word: 'vitalization', word_class: 'N' }
  ],
  personally: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  eeriness: [
    { word: 'eery', word_class: 'AJ' },
    { word: 'eerie', word_class: 'AJ' },
    { word: 'eeriness', word_class: 'N' }
  ],
  mettlesome: [
    { word: 'mettle', word_class: 'N' },
    { word: 'mettlesome', word_class: 'AJ' },
    { word: 'mettlesomeness', word_class: 'N' }
  ],
  fossilized: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  acculturate: [
    { word: 'acculturate', word_class: 'V' },
    { word: 'acculturation', word_class: 'N' },
    { word: 'acculturative', word_class: 'AJ' },
    { word: 'acculturational', word_class: 'AJ' }
  ],
  new: [
    { word: 'new', word_class: 'AJ' },
    { word: 'new', word_class: 'AV' },
    { word: 'news', word_class: 'N' },
    { word: 'newness', word_class: 'N' }
  ],
  sepulchral: [
    { word: 'sepulcher', word_class: 'N' },
    { word: 'sepulchre', word_class: 'N' },
    { word: 'sepulchral', word_class: 'AJ' }
  ],
  speculator: [
    { word: 'speculate', word_class: 'V' },
    { word: 'speculator', word_class: 'N' },
    { word: 'speculation', word_class: 'N' },
    { word: 'speculative', word_class: 'AJ' },
    { word: 'speculatively', word_class: 'AV' },
    { word: 'speculativeness', word_class: 'N' }
  ],
  metallize: [
    { word: 'metal', word_class: 'V' },
    { word: 'metal', word_class: 'N' },
    { word: 'metal', word_class: 'AJ' },
    { word: 'metallic', word_class: 'AJ' },
    { word: 'metallize', word_class: 'V' },
    { word: 'metalization', word_class: 'N' },
    { word: 'metallisation', word_class: 'N' },
    { word: 'metallization', word_class: 'N' }
  ],
  sulkiness: [
    { word: 'sulky', word_class: 'N' },
    { word: 'sulky', word_class: 'AJ' },
    { word: 'sulkiness', word_class: 'N' }
  ],
  disguised: [
    { word: 'disguise', word_class: 'N' },
    { word: 'disguise', word_class: 'V' },
    { word: 'disguised', word_class: 'AJ' }
  ],
  literacy: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  discombobulation: [
    { word: 'discombobulate', word_class: 'V' },
    { word: 'discombobulated', word_class: 'AJ' },
    { word: 'discombobulation', word_class: 'N' }
  ],
  kindled: [
    { word: 'kindle', word_class: 'V' },
    { word: 'kindled', word_class: 'AJ' },
    { word: 'kindling', word_class: 'N' }
  ],
  monetise: [
    { word: 'monetise', word_class: 'V' },
    { word: 'monetize', word_class: 'V' },
    { word: 'monetisation', word_class: 'N' },
    { word: 'monetization', word_class: 'N' }
  ],
  dynamite: [
    { word: 'dynamite', word_class: 'N' },
    { word: 'dynamite', word_class: 'V' },
    { word: 'dynamiter', word_class: 'N' },
    { word: 'dynamitist', word_class: 'N' }
  ],
  penally: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  vandalization: [
    { word: 'vandal', word_class: 'N' },
    { word: 'vandalise', word_class: 'V' },
    { word: 'vandalism', word_class: 'N' },
    { word: 'vandalize', word_class: 'V' },
    { word: 'vandalization', word_class: 'N' }
  ],
  maimed: [
    { word: 'maim', word_class: 'V' },
    { word: 'maimed', word_class: 'N' },
    { word: 'maimed', word_class: 'AJ' },
    { word: 'maiming', word_class: 'N' }
  ],
  inclined: [
    { word: 'incline', word_class: 'N' },
    { word: 'incline', word_class: 'V' },
    { word: 'inclined', word_class: 'AJ' },
    { word: 'inclining', word_class: 'N' },
    { word: 'inclination', word_class: 'N' }
  ],
  sulfonate: [
    { word: 'sulfonate', word_class: 'N' },
    { word: 'sulfonate', word_class: 'V' },
    { word: 'sulphonate', word_class: 'V' },
    { word: 'sulfonation', word_class: 'N' },
    { word: 'sulphonation', word_class: 'N' }
  ],
  felicitation: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  jagged: [{ word: 'jag', word_class: 'N' }, { word: 'jag', word_class: 'V' }, { word: 'jagged', word_class: 'AJ' }],
  signification: [
    { word: 'signify', word_class: 'V' },
    { word: 'signification', word_class: 'N' },
    { word: 'significative', word_class: 'AJ' }
  ],
  differentially: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  rusted: [
    { word: 'rust', word_class: 'N' },
    { word: 'rust', word_class: 'V' },
    { word: 'rust', word_class: 'AJ' },
    { word: 'rusted', word_class: 'AJ' },
    { word: 'rusting', word_class: 'N' }
  ],
  renounce: [
    { word: 'renounce', word_class: 'V' },
    { word: 'renunciate', word_class: 'V' },
    { word: 'renunciant', word_class: 'AJ' },
    { word: 'renouncement', word_class: 'N' },
    { word: 'renunciation', word_class: 'N' },
    { word: 'renunciative', word_class: 'AJ' }
  ],
  faery: [{ word: 'faery', word_class: 'N' }, { word: 'faerie', word_class: 'N' }, { word: 'faery', word_class: 'AJ' }],
  landscaped: [
    { word: 'landscape', word_class: 'N' },
    { word: 'landscape', word_class: 'V' },
    { word: 'landscaped', word_class: 'AJ' },
    { word: 'landscaping', word_class: 'N' },
    { word: 'landscapist', word_class: 'N' }
  ],
  paganization: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  stricken: [
    { word: 'strike', word_class: 'N' },
    { word: 'strike', word_class: 'V' },
    { word: 'striker', word_class: 'N' },
    { word: 'striking', word_class: 'N' },
    { word: 'stricken', word_class: 'AJ' },
    { word: 'striking', word_class: 'AJ' }
  ],
  brush: [
    { word: 'brush', word_class: 'N' },
    { word: 'brush', word_class: 'V' },
    { word: 'brushed', word_class: 'AJ' },
    { word: 'brushing', word_class: 'N' }
  ],
  innovativeness: [
    { word: 'innovate', word_class: 'V' },
    { word: 'innovator', word_class: 'N' },
    { word: 'innovation', word_class: 'N' },
    { word: 'innovative', word_class: 'AJ' },
    { word: 'innovational', word_class: 'AJ' },
    { word: 'innovativeness', word_class: 'N' }
  ],
  communicating: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  recitative: [
    { word: 'recite', word_class: 'V' },
    { word: 'recital', word_class: 'N' },
    { word: 'recitation', word_class: 'N' },
    { word: 'recitative', word_class: 'N' }
  ],
  reins: [{ word: 'rein', word_class: 'N' }, { word: 'rein', word_class: 'V' }, { word: 'reins', word_class: 'N' }],
  specialize: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  reveal: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  uplifted: [
    { word: 'uplift', word_class: 'N' },
    { word: 'uplift', word_class: 'V' },
    { word: 'uplifted', word_class: 'AJ' },
    { word: 'uplifting', word_class: 'N' },
    { word: 'upliftment', word_class: 'N' }
  ],
  bootlick: [
    { word: 'bootlick', word_class: 'V' },
    { word: 'bootlicker', word_class: 'N' },
    { word: 'bootlicking', word_class: 'AJ' }
  ],
  chivalry: [
    { word: 'chivalry', word_class: 'N' },
    { word: 'chivalric', word_class: 'AJ' },
    { word: 'chivalrous', word_class: 'AJ' },
    { word: 'chivalrously', word_class: 'AV' }
  ],
  metamorphous: [
    { word: 'metamorphic', word_class: 'AJ' },
    { word: 'metamorphism', word_class: 'N' },
    { word: 'metamorphous', word_class: 'AJ' }
  ],
  congratulate: [
    { word: 'congratulate', word_class: 'V' },
    { word: 'congratulation', word_class: 'N' },
    { word: 'congratulations', word_class: 'N' }
  ],
  equipping: [
    { word: 'equip', word_class: 'V' },
    { word: 'equipment', word_class: 'N' },
    { word: 'equipped', word_class: 'AJ' },
    { word: 'equipping', word_class: 'N' }
  ],
  tread: [
    { word: 'tread', word_class: 'N' },
    { word: 'tread', word_class: 'V' },
    { word: 'trodden', word_class: 'AJ' }
  ],
  enervate: [
    { word: 'enervate', word_class: 'V' },
    { word: 'enervated', word_class: 'AJ' },
    { word: 'enervation', word_class: 'N' },
    { word: 'enervating', word_class: 'AJ' }
  ],
  stalemated: [
    { word: 'stalemate', word_class: 'N' },
    { word: 'stalemate', word_class: 'V' },
    { word: 'stalemated', word_class: 'AJ' }
  ],
  see: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  arbitral: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  stableness: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  prefiguration: [
    { word: 'prefigure', word_class: 'V' },
    { word: 'prefiguration', word_class: 'N' },
    { word: 'prefigurement', word_class: 'N' },
    { word: 'prefigurative', word_class: 'AJ' }
  ],
  engagement: [
    { word: 'engage', word_class: 'V' },
    { word: 'engaged', word_class: 'AJ' },
    { word: 'engaging', word_class: 'AJ' },
    { word: 'engagement', word_class: 'N' }
  ],
  opportunity: [
    { word: 'opportune', word_class: 'AJ' },
    { word: 'opportunism', word_class: 'N' },
    { word: 'opportunity', word_class: 'N' },
    { word: 'opportunely', word_class: 'AV' },
    { word: 'opportuneness', word_class: 'N' }
  ],
  inconsiderateness: [
    { word: 'inconsiderate', word_class: 'AJ' },
    { word: 'inconsiderable', word_class: 'AJ' },
    { word: 'inconsideration', word_class: 'N' },
    { word: 'inconsiderately', word_class: 'AV' },
    { word: 'inconsiderateness', word_class: 'N' }
  ],
  net: [
    { word: 'net', word_class: 'N' },
    { word: 'net', word_class: 'V' },
    { word: 'net', word_class: 'AJ' },
    { word: 'netted', word_class: 'AJ' },
    { word: 'netting', word_class: 'N' }
  ],
  conventionalisation: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  hygiene: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  loafing: [
    { word: 'loaf', word_class: 'N' },
    { word: 'loaf', word_class: 'V' },
    { word: 'loafer', word_class: 'N' },
    { word: 'loafing', word_class: 'N' }
  ],
  wail: [
    { word: 'wail', word_class: 'N' },
    { word: 'wail', word_class: 'V' },
    { word: 'wailing', word_class: 'N' },
    { word: 'wailful', word_class: 'AJ' },
    { word: 'wailing', word_class: 'AJ' }
  ],
  subordinator: [
    { word: 'subordinate', word_class: 'N' },
    { word: 'subordinate', word_class: 'V' },
    { word: 'subordinate', word_class: 'AJ' },
    { word: 'subordination', word_class: 'N' },
    { word: 'subordinator', word_class: 'AJ' },
    { word: 'subordinating', word_class: 'AJ' },
    { word: 'subordinative', word_class: 'AJ' },
    { word: 'subordinateness', word_class: 'N' }
  ],
  fragrancy: [
    { word: 'fragrance', word_class: 'N' },
    { word: 'fragrancy', word_class: 'N' },
    { word: 'fragrant', word_class: 'AJ' }
  ],
  sagacious: [
    { word: 'sagacious', word_class: 'AJ' },
    { word: 'sagaciously', word_class: 'AV' },
    { word: 'sagaciousness', word_class: 'N' }
  ],
  balancer: [
    { word: 'balance', word_class: 'N' },
    { word: 'balance', word_class: 'V' },
    { word: 'balancer', word_class: 'N' },
    { word: 'balanced', word_class: 'AJ' },
    { word: 'balancing', word_class: 'N' }
  ],
  prepared: [
    { word: 'prepare', word_class: 'V' },
    { word: 'prepared', word_class: 'AJ' },
    { word: 'preparation', word_class: 'N' },
    { word: 'preparative', word_class: 'N' },
    { word: 'preparative', word_class: 'AJ' }
  ],
  falconer: [
    { word: 'falcon', word_class: 'N' },
    { word: 'falcon', word_class: 'V' },
    { word: 'falconer', word_class: 'N' },
    { word: 'falconry', word_class: 'N' }
  ],
  polychromic: [
    { word: 'polychrome', word_class: 'V' },
    { word: 'polychrome', word_class: 'AJ' },
    { word: 'polychromic', word_class: 'AJ' },
    { word: 'polychromize', word_class: 'V' }
  ],
  compose: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  unreadable: [
    { word: 'unread', word_class: 'AJ' },
    { word: 'unreadable', word_class: 'AJ' },
    { word: 'unreadably', word_class: 'AV' }
  ],
  warrantor: [
    { word: 'warrant', word_class: 'N' },
    { word: 'warrant', word_class: 'V' },
    { word: 'warrantee', word_class: 'N' },
    { word: 'warrantor', word_class: 'N' },
    { word: 'warranted', word_class: 'AJ' }
  ],
  vigilance: [
    { word: 'vigil', word_class: 'N' },
    { word: 'vigilance', word_class: 'N' },
    { word: 'vigilant', word_class: 'AJ' }
  ],
  notable: [
    { word: 'notable', word_class: 'N' },
    { word: 'notable', word_class: 'AJ' },
    { word: 'notably', word_class: 'AV' },
    { word: 'notability', word_class: 'N' }
  ],
  chopines: [
    { word: 'chopin', word_class: 'N' },
    { word: 'chopine', word_class: 'N' },
    { word: 'chopines', word_class: 'N' }
  ],
  swooning: [
    { word: 'swoon', word_class: 'N' },
    { word: 'swoon', word_class: 'V' },
    { word: 'swooning', word_class: 'AJ' }
  ],
  debilitate: [
    { word: 'debilitate', word_class: 'V' },
    { word: 'debilitated', word_class: 'AJ' },
    { word: 'debilitation', word_class: 'N' },
    { word: 'debilitating', word_class: 'AJ' },
    { word: 'debilitative', word_class: 'AJ' }
  ],
  allocable: [
    { word: 'allocate', word_class: 'V' },
    { word: 'allocable', word_class: 'AJ' },
    { word: 'allocation', word_class: 'N' }
  ],
  gastronomic: [
    { word: 'gastronome', word_class: 'N' },
    { word: 'gastronomic', word_class: 'AJ' },
    { word: 'gastronomical', word_class: 'AJ' }
  ],
  dogmatical: [
    { word: 'dogma', word_class: 'N' },
    { word: 'dogmatic', word_class: 'AJ' },
    { word: 'dogmatise', word_class: 'V' },
    { word: 'dogmatism', word_class: 'N' },
    { word: 'dogmatize', word_class: 'V' },
    { word: 'dogmatical', word_class: 'AJ' },
    { word: 'dogmatisation', word_class: 'N' },
    { word: 'dogmatically', word_class: 'AV' },
    { word: 'dogmatization', word_class: 'N' }
  ],
  salivate: [
    { word: 'saliva', word_class: 'N' },
    { word: 'salivate', word_class: 'V' },
    { word: 'salivary', word_class: 'AJ' },
    { word: 'salivation', word_class: 'N' }
  ],
  pocked: [{ word: 'pock', word_class: 'N' }, { word: 'pock', word_class: 'V' }, { word: 'pocked', word_class: 'AJ' }],
  woolgatherer: [
    { word: 'woolgather', word_class: 'V' },
    { word: 'woolgatherer', word_class: 'N' },
    { word: 'woolgathering', word_class: 'N' },
    { word: 'woolgathering', word_class: 'AJ' }
  ],
  lecturing: [
    { word: 'lecture', word_class: 'N' },
    { word: 'lecture', word_class: 'V' },
    { word: 'lecturer', word_class: 'N' },
    { word: 'lecturing', word_class: 'N' },
    { word: 'lectureship', word_class: 'N' }
  ],
  stare: [
    { word: 'stare', word_class: 'N' },
    { word: 'stare', word_class: 'V' },
    { word: 'staring', word_class: 'AJ' },
    { word: 'staring', word_class: 'AV' }
  ],
  vat: [{ word: 'vat', word_class: 'N' }, { word: 'vatful', word_class: 'N' }, { word: 'vatful', word_class: 'AJ' }],
  trip: [{ word: 'trip', word_class: 'N' }, { word: 'trip', word_class: 'V' }, { word: 'tripping', word_class: 'AJ' }],
  physiological: [
    { word: 'physiology', word_class: 'N' },
    { word: 'physiologic', word_class: 'AJ' },
    { word: 'physiological', word_class: 'AJ' },
    { word: 'physiologically', word_class: 'AV' }
  ],
  overflow: [
    { word: 'overflow', word_class: 'N' },
    { word: 'overflow', word_class: 'V' },
    { word: 'overflowing', word_class: 'N' },
    { word: 'overflowing', word_class: 'AJ' }
  ],
  medicinally: [
    { word: 'medicine', word_class: 'N' },
    { word: 'medicine', word_class: 'V' },
    { word: 'medicinal', word_class: 'AJ' },
    { word: 'medicinally', word_class: 'AV' }
  ],
  felicitous: [
    { word: 'felicity', word_class: 'N' },
    { word: 'felicitate', word_class: 'V' },
    { word: 'felicitous', word_class: 'AJ' },
    { word: 'felicitation', word_class: 'N' },
    { word: 'felicitously', word_class: 'AV' },
    { word: 'felicitousness', word_class: 'N' }
  ],
  comprehended: [
    { word: 'comprehend', word_class: 'V' },
    { word: 'comprehended', word_class: 'AJ' },
    { word: 'comprehension', word_class: 'N' },
    { word: 'comprehensive', word_class: 'AJ' },
    { word: 'comprehendible', word_class: 'AJ' },
    { word: 'comprehensible', word_class: 'AJ' },
    { word: 'comprehensibly', word_class: 'AV' },
    { word: 'comprehensively', word_class: 'AV' },
    { word: 'comprehensibility', word_class: 'N' },
    { word: 'comprehensiveness', word_class: 'N' }
  ],
  everting: [
    { word: 'evert', word_class: 'V' },
    { word: 'eversion', word_class: 'N' },
    { word: 'everting', word_class: 'N' }
  ],
  sevens: [
    { word: 'seven', word_class: 'N' },
    { word: 'seven', word_class: 'AJ' },
    { word: 'sevens', word_class: 'N' },
    { word: 'sevener', word_class: 'N' }
  ],
  perseveration: [
    { word: 'persevere', word_class: 'V' },
    { word: 'perseverance', word_class: 'N' },
    { word: 'perseverence', word_class: 'N' },
    { word: 'perseveration', word_class: 'N' }
  ],
  junketing: [
    { word: 'junket', word_class: 'N' },
    { word: 'junket', word_class: 'V' },
    { word: 'junketing', word_class: 'N' }
  ],
  foment: [
    { word: 'foment', word_class: 'V' },
    { word: 'fomenter', word_class: 'N' },
    { word: 'fomentation', word_class: 'N' }
  ],
  chastely: [
    { word: 'chaste', word_class: 'AJ' },
    { word: 'chastely', word_class: 'AV' },
    { word: 'chasteness', word_class: 'N' }
  ],
  footman: [
    { word: 'foot', word_class: 'N' },
    { word: 'foot', word_class: 'V' },
    { word: 'footer', word_class: 'N' },
    { word: 'footed', word_class: 'AJ' },
    { word: 'footing', word_class: 'N' },
    { word: 'footman', word_class: 'N' }
  ],
  initiated: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  energetic: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  fornicator: [
    { word: 'fornicate', word_class: 'V' },
    { word: 'fornicator', word_class: 'N' },
    { word: 'fornication', word_class: 'N' }
  ],
  besieged: [
    { word: 'besiege', word_class: 'V' },
    { word: 'besieger', word_class: 'N' },
    { word: 'besieged', word_class: 'AJ' },
    { word: 'besieging', word_class: 'N' }
  ],
  jot: [{ word: 'jot', word_class: 'N' }, { word: 'jot', word_class: 'V' }, { word: 'jotting', word_class: 'N' }],
  overlayer: [
    { word: 'overlay', word_class: 'N' },
    { word: 'overlay', word_class: 'V' },
    { word: 'overlayer', word_class: 'N' }
  ],
  purloin: [
    { word: 'purloin', word_class: 'V' },
    { word: 'purloined', word_class: 'AJ' },
    { word: 'purloinment', word_class: 'N' }
  ],
  glazed: [
    { word: 'glaze', word_class: 'N' },
    { word: 'glaze', word_class: 'V' },
    { word: 'glazer', word_class: 'N' },
    { word: 'glazed', word_class: 'AJ' },
    { word: 'glazing', word_class: 'N' }
  ],
  office: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  limited: [
    { word: 'limit', word_class: 'N' },
    { word: 'limit', word_class: 'V' },
    { word: 'limiter', word_class: 'N' },
    { word: 'limited', word_class: 'AJ' },
    { word: 'limiting', word_class: 'N' },
    { word: 'limiting', word_class: 'AJ' },
    { word: 'limitation', word_class: 'N' }
  ],
  fulgurant: [
    { word: 'fulgurate', word_class: 'V' },
    { word: 'fulgurant', word_class: 'AJ' },
    { word: 'fulgurous', word_class: 'AJ' },
    { word: 'fulguration', word_class: 'N' },
    { word: 'fulgurating', word_class: 'AJ' }
  ],
  penitentially: [
    { word: 'penitent', word_class: 'N' },
    { word: 'penitence', word_class: 'N' },
    { word: 'penitent', word_class: 'AJ' },
    { word: 'penitently', word_class: 'AV' },
    { word: 'penitential', word_class: 'AJ' },
    { word: 'penitentially', word_class: 'AV' }
  ],
  accent: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  contextualize: [
    { word: 'contextual', word_class: 'AJ' },
    { word: 'contextualize', word_class: 'V' },
    { word: 'contextually', word_class: 'AV' }
  ],
  ocean: [
    { word: 'ocean', word_class: 'N' },
    { word: 'oceanic', word_class: 'N' },
    { word: 'oceanic', word_class: 'AJ' }
  ],
  merging: [
    { word: 'merge', word_class: 'V' },
    { word: 'merger', word_class: 'N' },
    { word: 'merged', word_class: 'AJ' },
    { word: 'merging', word_class: 'N' },
    { word: 'mergence', word_class: 'N' },
    { word: 'merging', word_class: 'AJ' }
  ],
  asphyxiate: [
    { word: 'asphyxiate', word_class: 'V' },
    { word: 'asphyxiated', word_class: 'AJ' },
    { word: 'asphyxiation', word_class: 'N' },
    { word: 'asphyxiating', word_class: 'AJ' }
  ],
  intersectant: [
    { word: 'intersect', word_class: 'N' },
    { word: 'intersect', word_class: 'V' },
    { word: 'intersection', word_class: 'N' },
    { word: 'intersectant', word_class: 'AJ' },
    { word: 'intersecting', word_class: 'AJ' }
  ],
  betrothal: [
    { word: 'betroth', word_class: 'V' },
    { word: 'betrothal', word_class: 'N' },
    { word: 'betrothed', word_class: 'N' },
    { word: 'betrothed', word_class: 'AJ' }
  ],
  deduct: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  letterman: [
    { word: 'letter', word_class: 'N' },
    { word: 'letter', word_class: 'V' },
    { word: 'letters', word_class: 'N' },
    { word: 'lettered', word_class: 'AJ' },
    { word: 'lettering', word_class: 'N' },
    { word: 'letterman', word_class: 'N' }
  ],
  duplicate: [
    { word: 'duplicate', word_class: 'N' },
    { word: 'duplicate', word_class: 'V' },
    { word: 'duplicity', word_class: 'N' },
    { word: 'duplicator', word_class: 'N' },
    { word: 'duplicate', word_class: 'AJ' },
    { word: 'duplicable', word_class: 'AJ' },
    { word: 'duplicating', word_class: 'N' },
    { word: 'duplication', word_class: 'N' },
    { word: 'duplicability', word_class: 'N' }
  ],
  trayful: [
    { word: 'tray', word_class: 'N' },
    { word: 'trayful', word_class: 'N' },
    { word: 'trayful', word_class: 'AJ' }
  ],
  avidness: [
    { word: 'avid', word_class: 'AJ' },
    { word: 'avidity', word_class: 'N' },
    { word: 'avidness', word_class: 'N' }
  ],
  cleared: [
    { word: 'clear', word_class: 'N' },
    { word: 'clear', word_class: 'V' },
    { word: 'clear', word_class: 'AJ' },
    { word: 'clear', word_class: 'AV' },
    { word: 'cleared', word_class: 'AJ' },
    { word: 'clearing', word_class: 'N' },
    { word: 'clearance', word_class: 'N' },
    { word: 'clearness', word_class: 'N' }
  ],
  fertilized: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  subjunction: [
    { word: 'subjoin', word_class: 'V' },
    { word: 'subjoining', word_class: 'N' },
    { word: 'subjunction', word_class: 'N' },
    { word: 'subjunctive', word_class: 'N' },
    { word: 'subjunctive', word_class: 'AJ' }
  ],
  desensitize: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  debarring: [
    { word: 'debar', word_class: 'V' },
    { word: 'debarment', word_class: 'N' },
    { word: 'debarring', word_class: 'N' }
  ],
  tailoring: [
    { word: 'tailor', word_class: 'N' },
    { word: 'tailor', word_class: 'V' },
    { word: 'tailored', word_class: 'AJ' },
    { word: 'tailoring', word_class: 'N' }
  ],
  analyticity: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  tautological: [
    { word: 'tautology', word_class: 'N' },
    { word: 'tautologic', word_class: 'AJ' },
    { word: 'tautological', word_class: 'AJ' }
  ],
  strap: [
    { word: 'strap', word_class: 'N' },
    { word: 'strap', word_class: 'V' },
    { word: 'strapping', word_class: 'AJ' }
  ],
  occidentalisation: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  cochin: [
    { word: 'cochin', word_class: 'N' },
    { word: 'cochineal', word_class: 'N' },
    { word: 'cochineal', word_class: 'AJ' }
  ],
  idealisation: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  faradize: [
    { word: 'farad', word_class: 'N' },
    { word: 'faradise', word_class: 'V' },
    { word: 'faradize', word_class: 'V' },
    { word: 'faradisation', word_class: 'N' },
    { word: 'faradization', word_class: 'N' }
  ],
  illegibly: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  decomposed: [
    { word: 'decompose', word_class: 'V' },
    { word: 'decomposed', word_class: 'AJ' },
    { word: 'decomposing', word_class: 'AJ' },
    { word: 'decomposable', word_class: 'AJ' },
    { word: 'decomposition', word_class: 'N' },
    { word: 'decompositional', word_class: 'AJ' }
  ],
  daubed: [
    { word: 'daub', word_class: 'N' },
    { word: 'daub', word_class: 'V' },
    { word: 'daubry', word_class: 'N' },
    { word: 'dauber', word_class: 'N' },
    { word: 'daubed', word_class: 'AJ' },
    { word: 'daubery', word_class: 'N' },
    { word: 'daubing', word_class: 'N' }
  ],
  indexical: [
    { word: 'index', word_class: 'N' },
    { word: 'index', word_class: 'V' },
    { word: 'indexer', word_class: 'N' },
    { word: 'indexing', word_class: 'N' },
    { word: 'indexation', word_class: 'N' },
    { word: 'indexical', word_class: 'AJ' }
  ],
  spiciness: [
    { word: 'spice', word_class: 'N' },
    { word: 'spice', word_class: 'V' },
    { word: 'spicy', word_class: 'AJ' },
    { word: 'spiciness', word_class: 'N' }
  ],
  itinerate: [
    { word: 'itinerant', word_class: 'N' },
    { word: 'itinerate', word_class: 'V' },
    { word: 'itinerant', word_class: 'AJ' },
    { word: 'itineration', word_class: 'N' }
  ],
  complemental: [
    { word: 'complement', word_class: 'N' },
    { word: 'complement', word_class: 'V' },
    { word: 'complemental', word_class: 'AJ' },
    { word: 'complementary', word_class: 'AJ' },
    { word: 'complementation', word_class: 'N' }
  ],
  beneficence: [
    { word: 'benefice', word_class: 'N' },
    { word: 'benefice', word_class: 'V' },
    { word: 'beneficence', word_class: 'N' },
    { word: 'beneficent', word_class: 'AJ' },
    { word: 'beneficiary', word_class: 'N' },
    { word: 'beneficiary', word_class: 'AJ' }
  ],
  keratinize: [
    { word: 'keratin', word_class: 'N' },
    { word: 'keratinise', word_class: 'V' },
    { word: 'keratinize', word_class: 'V' },
    { word: 'keratinisation', word_class: 'N' },
    { word: 'keratinization', word_class: 'N' }
  ],
  wonted: [
    { word: 'wont', word_class: 'N' },
    { word: 'wont', word_class: 'V' },
    { word: 'wont', word_class: 'AJ' },
    { word: 'wonted', word_class: 'AJ' }
  ],
  funding: [
    { word: 'fund', word_class: 'N' },
    { word: 'fund', word_class: 'V' },
    { word: 'funds', word_class: 'N' },
    { word: 'funded', word_class: 'AJ' },
    { word: 'funding', word_class: 'N' }
  ],
  dehumanization: [
    { word: 'dehumanise', word_class: 'V' },
    { word: 'dehumanize', word_class: 'V' },
    { word: 'dehumanized', word_class: 'AJ' },
    { word: 'dehumanisation', word_class: 'N' },
    { word: 'dehumanization', word_class: 'N' }
  ],
  ripeness: [
    { word: 'ripe', word_class: 'AJ' },
    { word: 'ripely', word_class: 'AV' },
    { word: 'ripeness', word_class: 'N' }
  ],
  crossness: [
    { word: 'cross', word_class: 'N' },
    { word: 'cross', word_class: 'V' },
    { word: 'cross', word_class: 'AJ' },
    { word: 'crosse', word_class: 'N' },
    { word: 'crossed', word_class: 'AJ' },
    { word: 'crossing', word_class: 'N' },
    { word: 'crossing', word_class: 'AJ' },
    { word: 'crossness', word_class: 'N' }
  ],
  fiction: [
    { word: 'fiction', word_class: 'N' },
    { word: 'fictional', word_class: 'AJ' },
    { word: 'fictionalise', word_class: 'V' },
    { word: 'fictionalize', word_class: 'V' },
    { word: 'fictionalisation', word_class: 'N' },
    { word: 'fictionalization', word_class: 'N' }
  ],
  parabolize: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  opaqueness: [
    { word: 'opacity', word_class: 'N' },
    { word: 'opaque', word_class: 'AJ' },
    { word: 'opaquely', word_class: 'AV' },
    { word: 'opaqueness', word_class: 'N' }
  ],
  cicatrize: [
    { word: 'cicatrise', word_class: 'V' },
    { word: 'cicatrize', word_class: 'V' },
    { word: 'cicatrisation', word_class: 'N' },
    { word: 'cicatrization', word_class: 'N' }
  ],
  notarization: [
    { word: 'notary', word_class: 'N' },
    { word: 'notarize', word_class: 'V' },
    { word: 'notarization', word_class: 'N' }
  ],
  obsequiously: [
    { word: 'obsequy', word_class: 'N' },
    { word: 'obsequious', word_class: 'AJ' },
    { word: 'obsequiously', word_class: 'AV' },
    { word: 'obsequiousness', word_class: 'N' }
  ],
  detector: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  howler: [
    { word: 'howl', word_class: 'N' },
    { word: 'howl', word_class: 'V' },
    { word: 'howler', word_class: 'N' },
    { word: 'howling', word_class: 'N' },
    { word: 'howling', word_class: 'AJ' }
  ],
  climb: [
    { word: 'climb', word_class: 'N' },
    { word: 'climb', word_class: 'V' },
    { word: 'climber', word_class: 'N' },
    { word: 'climbing', word_class: 'N' },
    { word: 'climbing', word_class: 'AJ' }
  ],
  scrupulosity: [
    { word: 'scruple', word_class: 'N' },
    { word: 'scruple', word_class: 'V' },
    { word: 'scruples', word_class: 'N' },
    { word: 'scrupulous', word_class: 'AJ' },
    { word: 'scrupulosity', word_class: 'N' },
    { word: 'scrupulously', word_class: 'AV' },
    { word: 'scrupulousness', word_class: 'N' }
  ],
  unhorse: [
    { word: 'unhorse', word_class: 'N' },
    { word: 'unhorse', word_class: 'V' },
    { word: 'unhorsed', word_class: 'AJ' }
  ],
  flop: [
    { word: 'flop', word_class: 'N' },
    { word: 'flop', word_class: 'V' },
    { word: 'flop', word_class: 'AV' },
    { word: 'flopping', word_class: 'N' }
  ],
  sociological: [
    { word: 'sociology', word_class: 'N' },
    { word: 'sociological', word_class: 'AJ' },
    { word: 'sociologically', word_class: 'AV' }
  ],
  cannibalisation: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  phosphatization: [
    { word: 'phosphate', word_class: 'N' },
    { word: 'phosphatise', word_class: 'V' },
    { word: 'phosphatize', word_class: 'V' },
    { word: 'phosphatisation', word_class: 'N' },
    { word: 'phosphatization', word_class: 'N' }
  ],
  neutral: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  ostracize: [
    { word: 'ostracism', word_class: 'N' },
    { word: 'ostracize', word_class: 'V' },
    { word: 'ostracization', word_class: 'N' }
  ],
  colonization: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  gasified: [
    { word: 'ga', word_class: 'N' },
    { word: 'gas', word_class: 'N' },
    { word: 'gas', word_class: 'V' },
    { word: 'gasify', word_class: 'V' },
    { word: 'gasman', word_class: 'N' },
    { word: 'gasified', word_class: 'AJ' },
    { word: 'gasification', word_class: 'N' }
  ],
  conceal: [
    { word: 'conceal', word_class: 'V' },
    { word: 'concealed', word_class: 'AJ' },
    { word: 'concealing', word_class: 'N' },
    { word: 'concealing', word_class: 'AJ' },
    { word: 'concealment', word_class: 'N' }
  ],
  lovely: [
    { word: 'love', word_class: 'N' },
    { word: 'love', word_class: 'V' },
    { word: 'lover', word_class: 'N' },
    { word: 'loved', word_class: 'AJ' },
    { word: 'lovely', word_class: 'N' },
    { word: 'lovely', word_class: 'AJ' },
    { word: 'loving', word_class: 'AJ' }
  ],
  aluminize: [
    { word: 'aluminize', word_class: 'V' },
    { word: 'aluminous', word_class: 'AJ' },
    { word: 'aluminization', word_class: 'N' }
  ],
  persecute: [
    { word: 'persecute', word_class: 'V' },
    { word: 'persecutor', word_class: 'N' },
    { word: 'persecuted', word_class: 'AJ' },
    { word: 'persecution', word_class: 'N' }
  ],
  stretched: [
    { word: 'stretch', word_class: 'N' },
    { word: 'stretch', word_class: 'V' },
    { word: 'stretch', word_class: 'AJ' },
    { word: 'stretcher', word_class: 'N' },
    { word: 'stretched', word_class: 'AJ' },
    { word: 'stretching', word_class: 'N' },
    { word: 'stretching', word_class: 'AJ' }
  ],
  greaser: [
    { word: 'grease', word_class: 'N' },
    { word: 'grease', word_class: 'V' },
    { word: 'greaser', word_class: 'N' },
    { word: 'greasy', word_class: 'AJ' },
    { word: 'greased', word_class: 'AJ' },
    { word: 'greasiness', word_class: 'N' }
  ],
  teller: [
    { word: 'tell', word_class: 'N' },
    { word: 'tell', word_class: 'V' },
    { word: 'teller', word_class: 'N' },
    { word: 'telling', word_class: 'N' },
    { word: 'telling', word_class: 'AJ' }
  ],
  immunise: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  rewarding: [
    { word: 'reward', word_class: 'N' },
    { word: 'reward', word_class: 'V' },
    { word: 'rewardful', word_class: 'AJ' },
    { word: 'rewarding', word_class: 'AJ' },
    { word: 'rewardable', word_class: 'AJ' }
  ],
  arrested: [
    { word: 'arrest', word_class: 'N' },
    { word: 'arrest', word_class: 'V' },
    { word: 'arrester', word_class: 'N' },
    { word: 'arrested', word_class: 'AJ' },
    { word: 'arresting', word_class: 'AJ' }
  ],
  flighted: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  exotism: [
    { word: 'exotic', word_class: 'AJ' },
    { word: 'exotism', word_class: 'N' },
    { word: 'exoticness', word_class: 'N' }
  ],
  credibleness: [
    { word: 'credible', word_class: 'AJ' },
    { word: 'credibly', word_class: 'AV' },
    { word: 'credibility', word_class: 'N' },
    { word: 'credibleness', word_class: 'N' }
  ],
  primness: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  effervescent: [
    { word: 'effervesce', word_class: 'V' },
    { word: 'effervescence', word_class: 'N' },
    { word: 'effervescent', word_class: 'AJ' },
    { word: 'effervescing', word_class: 'AJ' }
  ],
  enfolding: [
    { word: 'enfold', word_class: 'V' },
    { word: 'enfolding', word_class: 'N' },
    { word: 'enfoldment', word_class: 'N' }
  ],
  syllogisation: [
    { word: 'syllogise', word_class: 'V' },
    { word: 'syllogism', word_class: 'N' },
    { word: 'syllogize', word_class: 'V' },
    { word: 'syllogisation', word_class: 'N' },
    { word: 'syllogization', word_class: 'N' }
  ],
  brambling: [
    { word: 'bramble', word_class: 'N' },
    { word: 'brambly', word_class: 'AJ' },
    { word: 'brambling', word_class: 'N' }
  ],
  flyer: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  corruptness: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  depressing: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  caponization: [
    { word: 'capon', word_class: 'N' },
    { word: 'caponize', word_class: 'V' },
    { word: 'caponization', word_class: 'N' }
  ],
  shadowed: [
    { word: 'shadow', word_class: 'N' },
    { word: 'shadow', word_class: 'V' },
    { word: 'shadowed', word_class: 'AJ' },
    { word: 'shadowing', word_class: 'N' },
    { word: 'shadowing', word_class: 'AJ' }
  ],
  paganize: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  arsenical: [
    { word: 'arsenal', word_class: 'N' },
    { word: 'arsenic', word_class: 'N' },
    { word: 'arsenate', word_class: 'N' },
    { word: 'arsenical', word_class: 'AJ' }
  ],
  silent: [
    { word: 'silence', word_class: 'N' },
    { word: 'silence', word_class: 'V' },
    { word: 'silent', word_class: 'AJ' },
    { word: 'silencer', word_class: 'N' },
    { word: 'silenced', word_class: 'AJ' },
    { word: 'silently', word_class: 'AV' }
  ],
  quizzing: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  deduce: [
    { word: 'deduce', word_class: 'V' },
    { word: 'deduct', word_class: 'V' },
    { word: 'deducted', word_class: 'AJ' },
    { word: 'deduction', word_class: 'N' },
    { word: 'deducible', word_class: 'AJ' },
    { word: 'deductive', word_class: 'AJ' },
    { word: 'deductible', word_class: 'N' },
    { word: 'deductible', word_class: 'AJ' }
  ],
  cabal: [
    { word: 'cabal', word_class: 'N' },
    { word: 'cabal', word_class: 'V' },
    { word: 'cabalism', word_class: 'N' },
    { word: 'cabalist', word_class: 'N' },
    { word: 'cabalistic', word_class: 'AJ' }
  ],
  gelatine: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  emitting: [
    { word: 'emit', word_class: 'V' },
    { word: 'emission', word_class: 'N' },
    { word: 'emitting', word_class: 'AJ' }
  ],
  learn: [
    { word: 'learn', word_class: 'V' },
    { word: 'learner', word_class: 'N' },
    { word: 'learned', word_class: 'AJ' },
    { word: 'learning', word_class: 'N' }
  ],
  chimeral: [
    { word: 'chimeral', word_class: 'AJ' },
    { word: 'chimeric', word_class: 'AJ' },
    { word: 'chimerical', word_class: 'AJ' }
  ],
  invariableness: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  intimidation: [
    { word: 'intimidate', word_class: 'V' },
    { word: 'intimidated', word_class: 'AJ' },
    { word: 'intimidation', word_class: 'N' },
    { word: 'intimidating', word_class: 'AJ' }
  ],
  melodize: [
    { word: 'melody', word_class: 'N' },
    { word: 'melodic', word_class: 'AJ' },
    { word: 'melodize', word_class: 'V' },
    { word: 'melodious', word_class: 'AJ' },
    { word: 'melodically', word_class: 'AV' },
    { word: 'melodization', word_class: 'N' },
    { word: 'melodiously', word_class: 'AV' },
    { word: 'melodiousness', word_class: 'N' }
  ],
  dominance: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  dawdle: [
    { word: 'dawdle', word_class: 'V' },
    { word: 'dawdler', word_class: 'N' },
    { word: 'dawdling', word_class: 'N' },
    { word: 'dawdling', word_class: 'AJ' }
  ],
  hidden: [
    { word: 'hide', word_class: 'N' },
    { word: 'hide', word_class: 'V' },
    { word: 'hiding', word_class: 'N' },
    { word: 'hidden', word_class: 'AJ' }
  ],
  uninformed: [
    { word: 'uninformed', word_class: 'AJ' },
    { word: 'uninformative', word_class: 'AJ' },
    { word: 'uninformatively', word_class: 'AV' }
  ],
  worry: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  antimony: [
    { word: 'antimony', word_class: 'N' },
    { word: 'antimonial', word_class: 'AJ' },
    { word: 'antimonious', word_class: 'AJ' }
  ],
  woo: [{ word: 'woo', word_class: 'V' }, { word: 'wooer', word_class: 'N' }, { word: 'wooing', word_class: 'N' }],
  placate: [
    { word: 'placate', word_class: 'V' },
    { word: 'placation', word_class: 'N' },
    { word: 'placating', word_class: 'AJ' }
  ],
  interdependence: [
    { word: 'interdepend', word_class: 'V' },
    { word: 'interdependence', word_class: 'N' },
    { word: 'interdependency', word_class: 'N' },
    { word: 'interdependent', word_class: 'AJ' }
  ],
  detective: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  absoluteness: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  susurrate: [
    { word: 'susurrate', word_class: 'V' },
    { word: 'susurrant', word_class: 'AJ' },
    { word: 'susurrous', word_class: 'AJ' },
    { word: 'susurration', word_class: 'N' }
  ],
  stymy: [{ word: 'stymy', word_class: 'V' }, { word: 'stymie', word_class: 'N' }, { word: 'stymie', word_class: 'V' }],
  doubly: [
    { word: 'double', word_class: 'N' },
    { word: 'double', word_class: 'V' },
    { word: 'double', word_class: 'AJ' },
    { word: 'double', word_class: 'AV' },
    { word: 'doubles', word_class: 'N' },
    { word: 'doubly', word_class: 'AV' },
    { word: 'doubled', word_class: 'AJ' },
    { word: 'doubling', word_class: 'N' }
  ],
  refit: [
    { word: 'refit', word_class: 'N' },
    { word: 'refit', word_class: 'V' },
    { word: 'refit', word_class: 'AJ' },
    { word: 'refitment', word_class: 'N' }
  ],
  pop: [
    { word: 'pop', word_class: 'N' },
    { word: 'pop', word_class: 'V' },
    { word: 'pop', word_class: 'AJ' },
    { word: 'pop', word_class: 'AV' },
    { word: 'popping', word_class: 'N' }
  ],
  wizard: [
    { word: 'wizard', word_class: 'N' },
    { word: 'wizard', word_class: 'AJ' },
    { word: 'wizardry', word_class: 'N' }
  ],
  adulterous: [
    { word: 'adultery', word_class: 'N' },
    { word: 'adulterer', word_class: 'N' },
    { word: 'adulterant', word_class: 'N' },
    { word: 'adulterate', word_class: 'V' },
    { word: 'adulteress', word_class: 'N' },
    { word: 'adulterant', word_class: 'AJ' },
    { word: 'adulterous', word_class: 'AJ' },
    { word: 'adulterate', word_class: 'AJ' },
    { word: 'adulterated', word_class: 'AJ' },
    { word: 'adulteration', word_class: 'N' },
    { word: 'adulterating', word_class: 'AJ' },
    { word: 'adulterously', word_class: 'AV' }
  ],
  cataclysmal: [
    { word: 'cataclysm', word_class: 'N' },
    { word: 'cataclysmal', word_class: 'AJ' },
    { word: 'cataclysmic', word_class: 'AJ' }
  ],
  presentable: [
    { word: 'present', word_class: 'N' },
    { word: 'present', word_class: 'V' },
    { word: 'presence', word_class: 'N' },
    { word: 'present', word_class: 'AJ' },
    { word: 'presenter', word_class: 'N' },
    { word: 'presented', word_class: 'AJ' },
    { word: 'presently', word_class: 'AV' },
    { word: 'presentment', word_class: 'N' },
    { word: 'presentness', word_class: 'N' },
    { word: 'presentable', word_class: 'AJ' },
    { word: 'presentably', word_class: 'AV' },
    { word: 'presentation', word_class: 'N' },
    { word: 'presentational', word_class: 'AJ' }
  ],
  conscientiously: [
    { word: 'conscience', word_class: 'N' },
    { word: 'conscientious', word_class: 'AJ' },
    { word: 'conscientiously', word_class: 'AV' },
    { word: 'conscientiousness', word_class: 'N' }
  ],
  hypodermal: [
    { word: 'hypodermic', word_class: 'N' },
    { word: 'hypodermal', word_class: 'AJ' },
    { word: 'hypodermic', word_class: 'AJ' }
  ],
  tweet: [{ word: 'tweet', word_class: 'N' }, { word: 'tweet', word_class: 'V' }, { word: 'tweeter', word_class: 'N' }],
  hygienical: [
    { word: 'hygiene', word_class: 'N' },
    { word: 'hygienic', word_class: 'AJ' },
    { word: 'hygienics', word_class: 'N' },
    { word: 'hygienize', word_class: 'V' },
    { word: 'hygienical', word_class: 'AJ' },
    { word: 'hygienically', word_class: 'AV' }
  ],
  synergistic: [
    { word: 'synergy', word_class: 'N' },
    { word: 'synergism', word_class: 'N' },
    { word: 'synergist', word_class: 'N' },
    { word: 'synergistic', word_class: 'AJ' }
  ],
  oscines: [
    { word: 'oscine', word_class: 'N' },
    { word: 'oscine', word_class: 'AJ' },
    { word: 'oscines', word_class: 'N' }
  ],
  pull: [
    { word: 'pull', word_class: 'N' },
    { word: 'pull', word_class: 'V' },
    { word: 'pullman', word_class: 'N' },
    { word: 'pulled', word_class: 'AJ' },
    { word: 'pulling', word_class: 'N' }
  ],
  batman: [
    { word: 'bat', word_class: 'N' },
    { word: 'bat', word_class: 'V' },
    { word: 'bats', word_class: 'AJ' },
    { word: 'batman', word_class: 'N' },
    { word: 'batting', word_class: 'N' }
  ],
  severality: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  landscaping: [
    { word: 'landscape', word_class: 'N' },
    { word: 'landscape', word_class: 'V' },
    { word: 'landscaped', word_class: 'AJ' },
    { word: 'landscaping', word_class: 'N' },
    { word: 'landscapist', word_class: 'N' }
  ],
  assembling: [
    { word: 'assemble', word_class: 'V' },
    { word: 'assembly', word_class: 'N' },
    { word: 'assembler', word_class: 'N' },
    { word: 'assemblage', word_class: 'N' },
    { word: 'assembled', word_class: 'AJ' },
    { word: 'assembled', word_class: 'AV' },
    { word: 'assembling', word_class: 'N' }
  ],
  eroded: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  regale: [
    { word: 'regal', word_class: 'AJ' },
    { word: 'regale', word_class: 'N' },
    { word: 'regale', word_class: 'V' },
    { word: 'regally', word_class: 'AV' },
    { word: 'regalement', word_class: 'N' }
  ],
  suppressed: [
    { word: 'suppress', word_class: 'V' },
    { word: 'suppresser', word_class: 'N' },
    { word: 'suppressor', word_class: 'N' },
    { word: 'suppressed', word_class: 'AJ' },
    { word: 'suppression', word_class: 'N' },
    { word: 'suppressive', word_class: 'AJ' }
  ],
  recapitulate: [
    { word: 'recap', word_class: 'N' },
    { word: 'recap', word_class: 'V' },
    { word: 'recapitulate', word_class: 'V' },
    { word: 'recapitulation', word_class: 'N' }
  ],
  caravanning: [
    { word: 'caravan', word_class: 'N' },
    { word: 'caravan', word_class: 'V' },
    { word: 'caravanning', word_class: 'N' }
  ],
  solvation: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  vagrant: [
    { word: 'vagrant', word_class: 'N' },
    { word: 'vagrancy', word_class: 'N' },
    { word: 'vagrant', word_class: 'AJ' }
  ],
  calvinistic: [
    { word: 'calvinist', word_class: 'N' },
    { word: 'calvinist', word_class: 'AJ' },
    { word: 'calvinistic', word_class: 'AJ' },
    { word: 'calvinistical', word_class: 'AJ' }
  ],
  conceivableness: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  enforce: [
    { word: 'enforce', word_class: 'V' },
    { word: 'enforcer', word_class: 'N' },
    { word: 'enforced', word_class: 'AJ' },
    { word: 'enforcement', word_class: 'N' },
    { word: 'enforceable', word_class: 'AJ' }
  ],
  documental: [
    { word: 'document', word_class: 'N' },
    { word: 'document', word_class: 'V' },
    { word: 'documental', word_class: 'AJ' },
    { word: 'documented', word_class: 'AJ' },
    { word: 'documentation', word_class: 'N' }
  ],
  precondition: [
    { word: 'precondition', word_class: 'N' },
    { word: 'precondition', word_class: 'V' },
    { word: 'preconditioned', word_class: 'AJ' }
  ],
  lugubrious: [
    { word: 'lugubrious', word_class: 'AJ' },
    { word: 'lugubriously', word_class: 'AV' },
    { word: 'lugubriousness', word_class: 'N' }
  ],
  romanize: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  torment: [
    { word: 'torment', word_class: 'N' },
    { word: 'torment', word_class: 'V' },
    { word: 'tormenter', word_class: 'N' },
    { word: 'tormentor', word_class: 'N' },
    { word: 'tormented', word_class: 'AJ' }
  ],
  preface: [
    { word: 'preface', word_class: 'N' },
    { word: 'preface', word_class: 'V' },
    { word: 'prefatory', word_class: 'AJ' },
    { word: 'prefatorial', word_class: 'AJ' }
  ],
  licking: [
    { word: 'lick', word_class: 'N' },
    { word: 'lick', word_class: 'V' },
    { word: 'licked', word_class: 'AJ' },
    { word: 'licking', word_class: 'N' }
  ],
  menstruation: [
    { word: 'menstruate', word_class: 'V' },
    { word: 'menstruation', word_class: 'N' },
    { word: 'menstruating', word_class: 'AJ' }
  ],
  domesticated: [
    { word: 'domestic', word_class: 'N' },
    { word: 'domestic', word_class: 'AJ' },
    { word: 'domesticate', word_class: 'V' },
    { word: 'domesticize', word_class: 'V' },
    { word: 'domesticity', word_class: 'N' },
    { word: 'domestically', word_class: 'AV' },
    { word: 'domesticated', word_class: 'AJ' },
    { word: 'domestication', word_class: 'N' }
  ],
  indicate: [
    { word: 'indic', word_class: 'N' },
    { word: 'indicant', word_class: 'N' },
    { word: 'indicate', word_class: 'V' },
    { word: 'indicator', word_class: 'N' },
    { word: 'indicating', word_class: 'N' },
    { word: 'indication', word_class: 'N' },
    { word: 'indicative', word_class: 'N' },
    { word: 'indicative', word_class: 'AJ' }
  ],
  look: [
    { word: 'look', word_class: 'N' },
    { word: 'look', word_class: 'V' },
    { word: 'looker', word_class: 'N' },
    { word: 'looking', word_class: 'N' },
    { word: 'looking', word_class: 'AJ' }
  ],
  dowse: [
    { word: 'dowse', word_class: 'V' },
    { word: 'dowser', word_class: 'N' },
    { word: 'dowsing', word_class: 'N' }
  ],
  decentralization: [
    { word: 'decentralize', word_class: 'V' },
    { word: 'decentralized', word_class: 'AJ' },
    { word: 'decentralizing', word_class: 'AJ' },
    { word: 'decentralization', word_class: 'N' }
  ],
  inductor: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  dodderer: [
    { word: 'dodder', word_class: 'N' },
    { word: 'dodder', word_class: 'V' },
    { word: 'dodderer', word_class: 'N' },
    { word: 'doddering', word_class: 'AJ' }
  ],
  puckered: [
    { word: 'pucker', word_class: 'N' },
    { word: 'pucker', word_class: 'V' },
    { word: 'puckered', word_class: 'AJ' }
  ],
  blanket: [
    { word: 'blanket', word_class: 'N' },
    { word: 'blanket', word_class: 'V' },
    { word: 'blanket', word_class: 'AJ' }
  ],
  extenuating: [
    { word: 'extenuate', word_class: 'V' },
    { word: 'extenuation', word_class: 'N' },
    { word: 'extenuating', word_class: 'AJ' }
  ],
  comical: [
    { word: 'comic', word_class: 'N' },
    { word: 'comic', word_class: 'AJ' },
    { word: 'comical', word_class: 'AJ' },
    { word: 'comically', word_class: 'AV' }
  ],
  underclothes: [
    { word: 'underclothes', word_class: 'N' },
    { word: 'underclothed', word_class: 'AJ' },
    { word: 'underclothing', word_class: 'N' }
  ],
  deliver: [
    { word: 'deliver', word_class: 'N' },
    { word: 'deliver', word_class: 'V' },
    { word: 'delivery', word_class: 'N' },
    { word: 'deliverer', word_class: 'N' },
    { word: 'delivery', word_class: 'AJ' },
    { word: 'deliverance', word_class: 'N' }
  ],
  tramp: [
    { word: 'tramp', word_class: 'N' },
    { word: 'tramp', word_class: 'V' },
    { word: 'tramping', word_class: 'AJ' }
  ],
  reasonably: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  retrocede: [
    { word: 'retrocede', word_class: 'V' },
    { word: 'retrocedence', word_class: 'N' },
    { word: 'retrocession', word_class: 'N' }
  ],
  marbleization: [
    { word: 'marbleize', word_class: 'V' },
    { word: 'marbleized', word_class: 'AJ' },
    { word: 'marbleizing', word_class: 'N' },
    { word: 'marbleization', word_class: 'N' }
  ],
  fervent: [
    { word: 'fervency', word_class: 'N' },
    { word: 'fervent', word_class: 'AJ' },
    { word: 'fervently', word_class: 'AV' }
  ],
  transvestitism: [
    { word: 'transvestite', word_class: 'N' },
    { word: 'transvestite', word_class: 'AJ' },
    { word: 'transvestitism', word_class: 'N' }
  ],
  owing: [{ word: 'owe', word_class: 'V' }, { word: 'owed', word_class: 'AJ' }, { word: 'owing', word_class: 'AJ' }],
  consonant: [
    { word: 'consonant', word_class: 'N' },
    { word: 'consonance', word_class: 'N' },
    { word: 'consonant', word_class: 'AJ' }
  ],
  kidnapped: [
    { word: 'kidnap', word_class: 'V' },
    { word: 'kidnaper', word_class: 'N' },
    { word: 'kidnaped', word_class: 'AJ' },
    { word: 'kidnapped', word_class: 'AJ' },
    { word: 'kidnapping', word_class: 'N' }
  ],
  antipathy: [
    { word: 'antipathy', word_class: 'N' },
    { word: 'antipathy', word_class: 'AJ' },
    { word: 'antipathetic', word_class: 'AJ' },
    { word: 'antipathetical', word_class: 'AJ' }
  ],
  skin: [
    { word: 'skin', word_class: 'N' },
    { word: 'skin', word_class: 'V' },
    { word: 'skinful', word_class: 'N' },
    { word: 'skinful', word_class: 'AJ' },
    { word: 'skinned', word_class: 'AJ' }
  ],
  braiding: [
    { word: 'braid', word_class: 'N' },
    { word: 'braid', word_class: 'V' },
    { word: 'braided', word_class: 'AJ' },
    { word: 'braiding', word_class: 'N' }
  ],
  interviewer: [
    { word: 'interview', word_class: 'N' },
    { word: 'interview', word_class: 'V' },
    { word: 'interviewee', word_class: 'N' },
    { word: 'interviewer', word_class: 'N' }
  ],
  lying: [
    { word: 'li', word_class: 'N' },
    { word: 'lie', word_class: 'V' },
    { word: 'lie', word_class: 'N' },
    { word: 'ly', word_class: 'AJ' },
    { word: 'liar', word_class: 'N' },
    { word: 'lied', word_class: 'N' },
    { word: 'lain', word_class: 'AJ' },
    { word: 'lying', word_class: 'N' },
    { word: 'lying', word_class: 'AJ' }
  ],
  door: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  askance: [
    { word: 'askance', word_class: 'N' },
    { word: 'askance', word_class: 'AJ' },
    { word: 'askance', word_class: 'AV' }
  ],
  asymmetrical: [
    { word: 'asymmetric', word_class: 'AJ' },
    { word: 'asymmetrical', word_class: 'N' },
    { word: 'asymmetrical', word_class: 'AJ' },
    { word: 'asymmetrically', word_class: 'AV' }
  ],
  botanization: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  abducent: [
    { word: 'abduce', word_class: 'V' },
    { word: 'abducent', word_class: 'N' },
    { word: 'abducent', word_class: 'AJ' }
  ],
  determiner: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  cloak: [
    { word: 'cloak', word_class: 'N' },
    { word: 'cloak', word_class: 'V' },
    { word: 'cloaked', word_class: 'AJ' }
  ],
  conversance: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  mercurate: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  reversal: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  cylindricality: [
    { word: 'cylinder', word_class: 'N' },
    { word: 'cylindric', word_class: 'AJ' },
    { word: 'cylindrical', word_class: 'AJ' },
    { word: 'cylindricality', word_class: 'N' }
  ],
  transgressor: [
    { word: 'transgress', word_class: 'V' },
    { word: 'transgressor', word_class: 'N' },
    { word: 'transgression', word_class: 'N' }
  ],
  thunderous: [
    { word: 'thunder', word_class: 'N' },
    { word: 'thunder', word_class: 'V' },
    { word: 'thunderer', word_class: 'N' },
    { word: 'thundering', word_class: 'AJ' },
    { word: 'thunderous', word_class: 'AJ' }
  ],
  narrow: [
    { word: 'narrow', word_class: 'N' },
    { word: 'narrow', word_class: 'V' },
    { word: 'narrow', word_class: 'AJ' },
    { word: 'narrowed', word_class: 'AJ' },
    { word: 'narrowing', word_class: 'N' },
    { word: 'narrowing', word_class: 'AJ' },
    { word: 'narrowness', word_class: 'N' }
  ],
  iodized: [
    { word: 'iodise', word_class: 'V' },
    { word: 'iodize', word_class: 'V' },
    { word: 'iodized', word_class: 'AJ' },
    { word: 'iodisation', word_class: 'N' },
    { word: 'iodization', word_class: 'N' }
  ],
  adsorption: [
    { word: 'adsorb', word_class: 'V' },
    { word: 'adsorbate', word_class: 'N' },
    { word: 'adsorbed', word_class: 'AJ' },
    { word: 'adsorbent', word_class: 'N' },
    { word: 'adsorbate', word_class: 'AJ' },
    { word: 'adsorption', word_class: 'N' },
    { word: 'adsorbent', word_class: 'AJ' },
    { word: 'adsorbable', word_class: 'AJ' },
    { word: 'adsorptive', word_class: 'AJ' }
  ],
  muchness: [
    { word: 'much', word_class: 'N' },
    { word: 'much', word_class: 'AJ' },
    { word: 'much', word_class: 'AV' },
    { word: 'muchness', word_class: 'N' }
  ],
  no: [{ word: 'no', word_class: 'N' }, { word: 'no', word_class: 'AJ' }, { word: 'no', word_class: 'AV' }],
  graduate: [
    { word: 'graduate', word_class: 'N' },
    { word: 'graduate', word_class: 'V' },
    { word: 'graduate', word_class: 'AJ' },
    { word: 'graduated', word_class: 'AJ' },
    { word: 'graduation', word_class: 'N' }
  ],
  political: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  talk: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  recapitulation: [
    { word: 'recap', word_class: 'N' },
    { word: 'recap', word_class: 'V' },
    { word: 'recapitulate', word_class: 'V' },
    { word: 'recapitulation', word_class: 'N' }
  ],
  unnatural: [
    { word: 'unnatural', word_class: 'AJ' },
    { word: 'unnaturally', word_class: 'AV' },
    { word: 'unnaturalness', word_class: 'N' },
    { word: 'unnaturalized', word_class: 'AJ' }
  ],
  gaily: [
    { word: 'gay', word_class: 'N' },
    { word: 'gay', word_class: 'AJ' },
    { word: 'gaiety', word_class: 'N' },
    { word: 'gaily', word_class: 'AV' }
  ],
  approval: [
    { word: 'approve', word_class: 'V' },
    { word: 'approval', word_class: 'N' },
    { word: 'approved', word_class: 'AJ' },
    { word: 'approving', word_class: 'N' },
    { word: 'approving', word_class: 'AJ' }
  ],
  clairvoyance: [
    { word: 'clairvoyant', word_class: 'N' },
    { word: 'clairvoyance', word_class: 'N' },
    { word: 'clairvoyant', word_class: 'AJ' }
  ],
  harding: [
    { word: 'hard', word_class: 'AJ' },
    { word: 'hard', word_class: 'AV' },
    { word: 'harding', word_class: 'N' },
    { word: 'hardness', word_class: 'N' },
    { word: 'hardship', word_class: 'N' }
  ],
  kenning: [{ word: 'ken', word_class: 'N' }, { word: 'ken', word_class: 'V' }, { word: 'kenning', word_class: 'N' }],
  competent: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  pediatric: [
    { word: 'pediatric', word_class: 'AJ' },
    { word: 'pediatrics', word_class: 'N' },
    { word: 'pediatrician', word_class: 'N' }
  ],
  defeatist: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  precision: [
    { word: 'precis', word_class: 'V' },
    { word: 'precis', word_class: 'N' },
    { word: 'precise', word_class: 'AJ' },
    { word: 'precision', word_class: 'N' },
    { word: 'precisely', word_class: 'AV' },
    { word: 'preciseness', word_class: 'N' }
  ],
  bake: [
    { word: 'bake', word_class: 'N' },
    { word: 'bake', word_class: 'V' },
    { word: 'baker', word_class: 'N' },
    { word: 'baked', word_class: 'AJ' },
    { word: 'bakery', word_class: 'N' },
    { word: 'baking', word_class: 'N' },
    { word: 'bakery', word_class: 'AJ' },
    { word: 'baking', word_class: 'AJ' }
  ],
  introversion: [
    { word: 'introvert', word_class: 'N' },
    { word: 'introvert', word_class: 'V' },
    { word: 'introvert', word_class: 'AJ' },
    { word: 'introversion', word_class: 'N' },
    { word: 'introverted', word_class: 'AJ' },
    { word: 'introversive', word_class: 'AJ' },
    { word: 'introvertive', word_class: 'AJ' }
  ],
  ameliorate: [
    { word: 'ameliorate', word_class: 'V' },
    { word: 'amelioration', word_class: 'N' },
    { word: 'ameliorating', word_class: 'AJ' },
    { word: 'ameliorative', word_class: 'AJ' }
  ],
  suturing: [
    { word: 'suture', word_class: 'N' },
    { word: 'suture', word_class: 'V' },
    { word: 'suturing', word_class: 'N' }
  ],
  demolishment: [
    { word: 'demolish', word_class: 'V' },
    { word: 'demolition', word_class: 'N' },
    { word: 'demolished', word_class: 'AJ' },
    { word: 'demolishing', word_class: 'N' },
    { word: 'demolishment', word_class: 'N' }
  ],
  arab: [{ word: 'arab', word_class: 'N' }, { word: 'arabic', word_class: 'N' }, { word: 'arabic', word_class: 'AJ' }],
  prolixity: [
    { word: 'prolix', word_class: 'AJ' },
    { word: 'prolixity', word_class: 'N' },
    { word: 'prolixness', word_class: 'N' }
  ],
  raging: [
    { word: 'rage', word_class: 'N' },
    { word: 'rage', word_class: 'V' },
    { word: 'ragee', word_class: 'N' },
    { word: 'raging', word_class: 'AJ' }
  ],
  cynical: [
    { word: 'cynic', word_class: 'N' },
    { word: 'cynical', word_class: 'AJ' },
    { word: 'cynicism', word_class: 'N' },
    { word: 'cynically', word_class: 'AV' }
  ],
  unexpressive: [
    { word: 'unexpressed', word_class: 'AJ' },
    { word: 'unexpressive', word_class: 'AJ' },
    { word: 'unexpressible', word_class: 'AJ' }
  ],
  individualise: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  opportunistic: [
    { word: 'opportunist', word_class: 'N' },
    { word: 'opportunist', word_class: 'AJ' },
    { word: 'opportunistic', word_class: 'AJ' }
  ],
  napping: [
    { word: 'nap', word_class: 'N' },
    { word: 'nap', word_class: 'V' },
    { word: 'napped', word_class: 'AJ' },
    { word: 'napping', word_class: 'AJ' }
  ],
  summons: [
    { word: 'summon', word_class: 'V' },
    { word: 'summons', word_class: 'N' },
    { word: 'summons', word_class: 'V' },
    { word: 'summoning', word_class: 'N' }
  ],
  fingered: [
    { word: 'finger', word_class: 'N' },
    { word: 'finger', word_class: 'V' },
    { word: 'fingered', word_class: 'AJ' },
    { word: 'fingering', word_class: 'N' }
  ],
  enchantress: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  inventiveness: [
    { word: 'invent', word_class: 'N' },
    { word: 'invent', word_class: 'V' },
    { word: 'inventor', word_class: 'N' },
    { word: 'invented', word_class: 'AJ' },
    { word: 'invention', word_class: 'N' },
    { word: 'inventive', word_class: 'AJ' },
    { word: 'inventively', word_class: 'AV' },
    { word: 'inventiveness', word_class: 'N' }
  ],
  disintegration: [
    { word: 'disintegrate', word_class: 'V' },
    { word: 'disintegrable', word_class: 'AJ' },
    { word: 'disintegration', word_class: 'N' },
    { word: 'disintegrative', word_class: 'AJ' }
  ],
  satirically: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  wanting: [
    { word: 'want', word_class: 'N' },
    { word: 'want', word_class: 'V' },
    { word: 'wanted', word_class: 'AJ' },
    { word: 'wanting', word_class: 'AJ' }
  ],
  cancellation: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  curarize: [
    { word: 'curare', word_class: 'N' },
    { word: 'curarise', word_class: 'V' },
    { word: 'curarize', word_class: 'V' },
    { word: 'curarisation', word_class: 'N' },
    { word: 'curarization', word_class: 'N' }
  ],
  futilely: [
    { word: 'futile', word_class: 'AJ' },
    { word: 'futility', word_class: 'N' },
    { word: 'futilely', word_class: 'AV' }
  ],
  resume: [
    { word: 'resume', word_class: 'N' },
    { word: 'resume', word_class: 'V' },
    { word: 'resumption', word_class: 'N' }
  ],
  ethical: [
    { word: 'ethic', word_class: 'N' },
    { word: 'ethics', word_class: 'N' },
    { word: 'ethical', word_class: 'AJ' },
    { word: 'ethically', word_class: 'AV' }
  ],
  aeronautics: [
    { word: 'aeronaut', word_class: 'N' },
    { word: 'aeronautic', word_class: 'AJ' },
    { word: 'aeronautics', word_class: 'N' },
    { word: 'aeronautical', word_class: 'AJ' }
  ],
  digitize: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  hydrolysate: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  defraudation: [
    { word: 'defraud', word_class: 'V' },
    { word: 'defraudment', word_class: 'N' },
    { word: 'defraudation', word_class: 'N' }
  ],
  medical: [
    { word: 'medic', word_class: 'N' },
    { word: 'medical', word_class: 'N' },
    { word: 'medical', word_class: 'AJ' },
    { word: 'medicate', word_class: 'V' },
    { word: 'medically', word_class: 'AV' },
    { word: 'medication', word_class: 'N' },
    { word: 'medicative', word_class: 'AJ' }
  ],
  indulging: [
    { word: 'indulge', word_class: 'V' },
    { word: 'indulging', word_class: 'N' },
    { word: 'indulgence', word_class: 'N' },
    { word: 'indulgent', word_class: 'AJ' },
    { word: 'indulgently', word_class: 'AV' }
  ],
  delocalization: [
    { word: 'delocalise', word_class: 'V' },
    { word: 'delocalize', word_class: 'V' },
    { word: 'delocalisation', word_class: 'N' },
    { word: 'delocalization', word_class: 'N' }
  ],
  regularise: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  analyzer: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  timing: [
    { word: 'time', word_class: 'N' },
    { word: 'time', word_class: 'V' },
    { word: 'timer', word_class: 'N' },
    { word: 'times', word_class: 'N' },
    { word: 'timed', word_class: 'AJ' },
    { word: 'timing', word_class: 'N' },
    { word: 'timely', word_class: 'AJ' },
    { word: 'timely', word_class: 'AV' }
  ],
  lamenter: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  purifier: [
    { word: 'purify', word_class: 'V' },
    { word: 'purifier', word_class: 'N' },
    { word: 'purified', word_class: 'AJ' },
    { word: 'purifying', word_class: 'AJ' },
    { word: 'purification', word_class: 'N' }
  ],
  pretentious: [
    { word: 'pretentious', word_class: 'AJ' },
    { word: 'pretentiously', word_class: 'AV' },
    { word: 'pretentiousness', word_class: 'N' }
  ],
  anticipate: [
    { word: 'anticipate', word_class: 'V' },
    { word: 'anticipant', word_class: 'AJ' },
    { word: 'anticipated', word_class: 'AJ' },
    { word: 'anticipation', word_class: 'N' },
    { word: 'anticipative', word_class: 'AJ' }
  ],
  celebrator: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  elasticity: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  amuse: [
    { word: 'amuse', word_class: 'V' },
    { word: 'amused', word_class: 'AJ' },
    { word: 'amusing', word_class: 'AJ' },
    { word: 'amusive', word_class: 'AJ' },
    { word: 'amusement', word_class: 'N' }
  ],
  shore: [
    { word: 'shore', word_class: 'N' },
    { word: 'shore', word_class: 'V' },
    { word: 'shore', word_class: 'AJ' },
    { word: 'shoring', word_class: 'N' }
  ],
  lightsomely: [
    { word: 'lightsome', word_class: 'AJ' },
    { word: 'lightsomely', word_class: 'AV' },
    { word: 'lightsomeness', word_class: 'N' }
  ],
  aggregate: [
    { word: 'aggregate', word_class: 'N' },
    { word: 'aggregate', word_class: 'V' },
    { word: 'aggregate', word_class: 'AJ' },
    { word: 'aggregation', word_class: 'N' },
    { word: 'aggregative', word_class: 'AJ' }
  ],
  paperer: [
    { word: 'paper', word_class: 'N' },
    { word: 'paper', word_class: 'V' },
    { word: 'papers', word_class: 'N' },
    { word: 'paperer', word_class: 'N' },
    { word: 'papering', word_class: 'N' }
  ],
  amortization: [
    { word: 'amort', word_class: 'AJ' },
    { word: 'amortize', word_class: 'V' },
    { word: 'amortization', word_class: 'N' }
  ],
  myeline: [
    { word: 'myelin', word_class: 'N' },
    { word: 'myeline', word_class: 'N' },
    { word: 'myelinic', word_class: 'AJ' },
    { word: 'myelinated', word_class: 'AJ' }
  ],
  meteoritical: [
    { word: 'meteorite', word_class: 'N' },
    { word: 'meteoritic', word_class: 'AJ' },
    { word: 'meteoritical', word_class: 'AJ' }
  ],
  instinctively: [
    { word: 'instinct', word_class: 'N' },
    { word: 'instinct', word_class: 'AJ' },
    { word: 'instinctive', word_class: 'AJ' },
    { word: 'instinctively', word_class: 'AV' }
  ],
  modernistic: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  chilled: [
    { word: 'chill', word_class: 'N' },
    { word: 'chill', word_class: 'V' },
    { word: 'chill', word_class: 'AJ' },
    { word: 'chilled', word_class: 'AJ' },
    { word: 'chilling', word_class: 'N' },
    { word: 'chilling', word_class: 'AJ' }
  ],
  throng: [
    { word: 'throng', word_class: 'N' },
    { word: 'throng', word_class: 'V' },
    { word: 'thronged', word_class: 'AJ' }
  ],
  effectively: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  meliorate: [
    { word: 'meliorate', word_class: 'V' },
    { word: 'meliorism', word_class: 'N' },
    { word: 'melioration', word_class: 'N' },
    { word: 'meliorative', word_class: 'AJ' }
  ],
  disenthral: [
    { word: 'disenthral', word_class: 'V' },
    { word: 'disenthrall', word_class: 'V' },
    { word: 'disenthralment', word_class: 'N' },
    { word: 'disenthrallment', word_class: 'N' }
  ],
  sprout: [
    { word: 'sprout', word_class: 'N' },
    { word: 'sprout', word_class: 'V' },
    { word: 'sprouted', word_class: 'AJ' },
    { word: 'sprouting', word_class: 'N' }
  ],
  overrun: [
    { word: 'overrun', word_class: 'N' },
    { word: 'overrun', word_class: 'V' },
    { word: 'overrun', word_class: 'AJ' }
  ],
  sociology: [
    { word: 'sociology', word_class: 'N' },
    { word: 'sociological', word_class: 'AJ' },
    { word: 'sociologically', word_class: 'AV' }
  ],
  rending: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  toddle: [
    { word: 'toddle', word_class: 'N' },
    { word: 'toddle', word_class: 'V' },
    { word: 'toddler', word_class: 'N' }
  ],
  smudge: [
    { word: 'smudge', word_class: 'N' },
    { word: 'smudge', word_class: 'V' },
    { word: 'smudgy', word_class: 'AJ' },
    { word: 'smudged', word_class: 'AJ' }
  ],
  collimate: [
    { word: 'collimate', word_class: 'V' },
    { word: 'collimator', word_class: 'N' },
    { word: 'collimation', word_class: 'N' }
  ],
  largeness: [
    { word: 'large', word_class: 'N' },
    { word: 'large', word_class: 'AJ' },
    { word: 'large', word_class: 'AV' },
    { word: 'largely', word_class: 'AV' },
    { word: 'largeness', word_class: 'N' }
  ],
  effectually: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  immediacy: [
    { word: 'immediacy', word_class: 'N' },
    { word: 'immediate', word_class: 'V' },
    { word: 'immediate', word_class: 'AJ' },
    { word: 'immediately', word_class: 'AV' },
    { word: 'immediateness', word_class: 'N' }
  ],
  willing: [
    { word: 'will', word_class: 'N' },
    { word: 'will', word_class: 'V' },
    { word: 'willing', word_class: 'N' },
    { word: 'willful', word_class: 'AJ' },
    { word: 'willing', word_class: 'AJ' },
    { word: 'willfulness', word_class: 'N' }
  ],
  there: [
    { word: 'there', word_class: 'N' },
    { word: 'there', word_class: 'AV' },
    { word: 'thereness', word_class: 'N' }
  ],
  carib: [
    { word: 'carib', word_class: 'N' },
    { word: 'caribe', word_class: 'N' },
    { word: 'caribou', word_class: 'N' }
  ],
  consider: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  overjoy: [
    { word: 'overjoy', word_class: 'N' },
    { word: 'overjoy', word_class: 'V' },
    { word: 'overjoyed', word_class: 'AJ' }
  ],
  monarch: [
    { word: 'monarch', word_class: 'N' },
    { word: 'monarchal', word_class: 'AJ' },
    { word: 'monarchic', word_class: 'AJ' },
    { word: 'monarchism', word_class: 'N' },
    { word: 'monarchical', word_class: 'AJ' }
  ],
  rebelliousness: [
    { word: 'rebellious', word_class: 'AJ' },
    { word: 'rebelliously', word_class: 'AV' },
    { word: 'rebelliousness', word_class: 'N' }
  ],
  tamely: [
    { word: 'tame', word_class: 'V' },
    { word: 'tame', word_class: 'AJ' },
    { word: 'tamer', word_class: 'N' },
    { word: 'tamed', word_class: 'AJ' },
    { word: 'tamely', word_class: 'AV' },
    { word: 'tameness', word_class: 'N' }
  ],
  inclusive: [
    { word: 'include', word_class: 'V' },
    { word: 'included', word_class: 'AJ' },
    { word: 'inclusion', word_class: 'N' },
    { word: 'inclusive', word_class: 'AJ' }
  ],
  gape: [{ word: 'gape', word_class: 'N' }, { word: 'gape', word_class: 'V' }, { word: 'gaping', word_class: 'AJ' }],
  argument: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  nonconformity: [
    { word: 'nonconformity', word_class: 'N' },
    { word: 'nonconformance', word_class: 'N' },
    { word: 'nonconforming', word_class: 'AJ' }
  ],
  slanderer: [
    { word: 'slander', word_class: 'N' },
    { word: 'slander', word_class: 'V' },
    { word: 'slanderer', word_class: 'N' },
    { word: 'slanderous', word_class: 'AJ' }
  ],
  botanical: [
    { word: 'botany', word_class: 'N' },
    { word: 'botanic', word_class: 'AJ' },
    { word: 'botanist', word_class: 'N' },
    { word: 'botanize', word_class: 'V' },
    { word: 'botanical', word_class: 'AJ' },
    { word: 'botanization', word_class: 'N' }
  ],
  exorcism: [
    { word: 'exorcise', word_class: 'V' },
    { word: 'exorcism', word_class: 'N' },
    { word: 'exorcist', word_class: 'N' },
    { word: 'exorcize', word_class: 'V' },
    { word: 'exorciser', word_class: 'N' }
  ],
  snoop: [
    { word: 'snoop', word_class: 'N' },
    { word: 'snoop', word_class: 'V' },
    { word: 'snooper', word_class: 'N' },
    { word: 'snoopy', word_class: 'AJ' },
    { word: 'snoopiness', word_class: 'N' }
  ],
  furtherance: [
    { word: 'further', word_class: 'V' },
    { word: 'further', word_class: 'AJ' },
    { word: 'further', word_class: 'AV' },
    { word: 'furtherance', word_class: 'N' }
  ],
  individually: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  tacking: [{ word: 'tack', word_class: 'N' }, { word: 'tack', word_class: 'V' }, { word: 'tacking', word_class: 'N' }],
  royalty: [
    { word: 'royal', word_class: 'N' },
    { word: 'royal', word_class: 'AJ' },
    { word: 'royalty', word_class: 'N' },
    { word: 'royally', word_class: 'AV' }
  ],
  ceremonious: [
    { word: 'ceremony', word_class: 'N' },
    { word: 'ceremonial', word_class: 'N' },
    { word: 'ceremonial', word_class: 'AJ' },
    { word: 'ceremonious', word_class: 'AJ' },
    { word: 'ceremonially', word_class: 'AV' },
    { word: 'ceremoniously', word_class: 'AV' },
    { word: 'ceremoniousness', word_class: 'N' }
  ],
  inflected: [
    { word: 'inflect', word_class: 'V' },
    { word: 'inflected', word_class: 'AJ' },
    { word: 'inflection', word_class: 'N' },
    { word: 'inflectional', word_class: 'AJ' }
  ],
  refract: [
    { word: 'refract', word_class: 'V' },
    { word: 'refraction', word_class: 'N' },
    { word: 'refractive', word_class: 'AJ' },
    { word: 'refractivity', word_class: 'N' },
    { word: 'refractiveness', word_class: 'N' }
  ],
  extravagance: [
    { word: 'extravagate', word_class: 'V' },
    { word: 'extravagance', word_class: 'N' },
    { word: 'extravagant', word_class: 'AJ' },
    { word: 'extravagation', word_class: 'N' }
  ],
  crooked: [
    { word: 'crook', word_class: 'N' },
    { word: 'crook', word_class: 'V' },
    { word: 'crooked', word_class: 'AJ' }
  ],
  penetrate: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  dialysis: [
    { word: 'dialyze', word_class: 'V' },
    { word: 'dialysis', word_class: 'N' },
    { word: 'dialyzer', word_class: 'N' }
  ],
  edgeways: [
    { word: 'edge', word_class: 'N' },
    { word: 'edge', word_class: 'V' },
    { word: 'edger', word_class: 'N' },
    { word: 'edge', word_class: 'AJ' },
    { word: 'edgy', word_class: 'AJ' },
    { word: 'edged', word_class: 'AJ' },
    { word: 'edging', word_class: 'N' },
    { word: 'edginess', word_class: 'N' },
    { word: 'edgeways', word_class: 'AV' }
  ],
  pushful: [
    { word: 'push', word_class: 'N' },
    { word: 'push', word_class: 'V' },
    { word: 'pusher', word_class: 'N' },
    { word: 'pushing', word_class: 'N' },
    { word: 'pushful', word_class: 'AJ' },
    { word: 'pushing', word_class: 'AJ' }
  ],
  demoralisation: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  pontificate: [
    { word: 'pontific', word_class: 'AJ' },
    { word: 'pontifical', word_class: 'AJ' },
    { word: 'pontificate', word_class: 'N' },
    { word: 'pontificate', word_class: 'V' },
    { word: 'pontification', word_class: 'N' }
  ],
  fester: [
    { word: 'fester', word_class: 'N' },
    { word: 'fester', word_class: 'V' },
    { word: 'festering', word_class: 'N' },
    { word: 'festering', word_class: 'AJ' }
  ],
  hospital: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  aerobic: [
    { word: 'aerobe', word_class: 'N' },
    { word: 'aerobic', word_class: 'AJ' },
    { word: 'aerobics', word_class: 'N' }
  ],
  mourning: [
    { word: 'mourn', word_class: 'V' },
    { word: 'mourner', word_class: 'N' },
    { word: 'mourning', word_class: 'N' },
    { word: 'mournful', word_class: 'AJ' },
    { word: 'mourning', word_class: 'AJ' },
    { word: 'mournfulness', word_class: 'N' }
  ],
  levi: [
    { word: 'levi', word_class: 'N' },
    { word: 'levy', word_class: 'N' },
    { word: 'levy', word_class: 'V' },
    { word: 'levis', word_class: 'N' }
  ],
  understate: [
    { word: 'understate', word_class: 'V' },
    { word: 'understated', word_class: 'AJ' },
    { word: 'understatement', word_class: 'N' }
  ],
  gamesome: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  personably: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  antecedently: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  hazarding: [
    { word: 'hazard', word_class: 'N' },
    { word: 'hazard', word_class: 'V' },
    { word: 'hazarding', word_class: 'N' },
    { word: 'hazardous', word_class: 'AJ' },
    { word: 'hazardously', word_class: 'AV' },
    { word: 'hazardousness', word_class: 'N' }
  ],
  ferociously: [
    { word: 'ferocious', word_class: 'AJ' },
    { word: 'ferociously', word_class: 'AV' },
    { word: 'ferociousness', word_class: 'N' }
  ],
  condensation: [
    { word: 'condense', word_class: 'V' },
    { word: 'condenser', word_class: 'N' },
    { word: 'condensate', word_class: 'N' },
    { word: 'condensed', word_class: 'AJ' },
    { word: 'condensate', word_class: 'V' },
    { word: 'condensing', word_class: 'N' },
    { word: 'condensation', word_class: 'N' }
  ],
  tune: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  revival: [
    { word: 'revive', word_class: 'V' },
    { word: 'revival', word_class: 'N' },
    { word: 'revive', word_class: 'AJ' },
    { word: 'revived', word_class: 'AJ' },
    { word: 'reviving', word_class: 'AJ' },
    { word: 'revivalism', word_class: 'N' }
  ],
  macadamization: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  slight: [
    { word: 'slight', word_class: 'N' },
    { word: 'slight', word_class: 'V' },
    { word: 'slight', word_class: 'AJ' },
    { word: 'slighting', word_class: 'AJ' },
    { word: 'slightness', word_class: 'N' }
  ],
  beak: [{ word: 'beak', word_class: 'N' }, { word: 'beak', word_class: 'V' }, { word: 'beaked', word_class: 'AJ' }],
  amazing: [
    { word: 'amaze', word_class: 'V' },
    { word: 'amazed', word_class: 'AJ' },
    { word: 'amazing', word_class: 'AJ' },
    { word: 'amazement', word_class: 'N' }
  ],
  pollenation: [
    { word: 'pollen', word_class: 'N' },
    { word: 'pollenate', word_class: 'V' },
    { word: 'pollinate', word_class: 'V' },
    { word: 'pollinator', word_class: 'N' },
    { word: 'pollenation', word_class: 'N' },
    { word: 'pollination', word_class: 'N' }
  ],
  shorn: [
    { word: 'shear', word_class: 'N' },
    { word: 'shear', word_class: 'V' },
    { word: 'shears', word_class: 'N' },
    { word: 'shorn', word_class: 'AJ' },
    { word: 'sheared', word_class: 'AJ' },
    { word: 'shearing', word_class: 'N' }
  ],
  fractional: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  mythicise: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  preoccupancy: [
    { word: 'preoccupy', word_class: 'V' },
    { word: 'preoccupancy', word_class: 'N' },
    { word: 'preoccupied', word_class: 'AJ' },
    { word: 'preoccupation', word_class: 'N' }
  ],
  increased: [
    { word: 'increase', word_class: 'N' },
    { word: 'increase', word_class: 'V' },
    { word: 'increased', word_class: 'AJ' },
    { word: 'increasing', word_class: 'AJ' }
  ],
  incipient: [
    { word: 'incipience', word_class: 'N' },
    { word: 'incipiency', word_class: 'N' },
    { word: 'incipient', word_class: 'AJ' }
  ],
  offside: [
    { word: 'offside', word_class: 'AJ' },
    { word: 'offside', word_class: 'AV' },
    { word: 'offsides', word_class: 'AJ' }
  ],
  jolt: [
    { word: 'jolt', word_class: 'N' },
    { word: 'jolt', word_class: 'V' },
    { word: 'jolted', word_class: 'AJ' },
    { word: 'jolting', word_class: 'AJ' }
  ],
  prospector: [
    { word: 'prospect', word_class: 'N' },
    { word: 'prospect', word_class: 'V' },
    { word: 'prospector', word_class: 'N' },
    { word: 'prospective', word_class: 'AJ' }
  ],
  inflatable: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  bountifulness: [
    { word: 'bounty', word_class: 'N' },
    { word: 'bountied', word_class: 'AJ' },
    { word: 'bounteous', word_class: 'AJ' },
    { word: 'bountiful', word_class: 'AJ' },
    { word: 'bountifulness', word_class: 'N' }
  ],
  prolongment: [
    { word: 'prolong', word_class: 'V' },
    { word: 'prolong', word_class: 'AJ' },
    { word: 'prolongate', word_class: 'V' },
    { word: 'prolonged', word_class: 'AJ' },
    { word: 'prolongment', word_class: 'N' },
    { word: 'prolongation', word_class: 'N' }
  ],
  halted: [
    { word: 'halt', word_class: 'N' },
    { word: 'halt', word_class: 'V' },
    { word: 'halt', word_class: 'AJ' },
    { word: 'halter', word_class: 'N' },
    { word: 'halter', word_class: 'V' },
    { word: 'halted', word_class: 'AJ' },
    { word: 'haltere', word_class: 'N' },
    { word: 'halting', word_class: 'AJ' }
  ],
  introvert: [
    { word: 'introvert', word_class: 'N' },
    { word: 'introvert', word_class: 'V' },
    { word: 'introvert', word_class: 'AJ' },
    { word: 'introversion', word_class: 'N' },
    { word: 'introverted', word_class: 'AJ' },
    { word: 'introversive', word_class: 'AJ' },
    { word: 'introvertive', word_class: 'AJ' }
  ],
  spoilation: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  worsening: [
    { word: 'worsen', word_class: 'V' },
    { word: 'worsened', word_class: 'AJ' },
    { word: 'worsening', word_class: 'N' },
    { word: 'worsening', word_class: 'AJ' }
  ],
  womaniser: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  predominant: [
    { word: 'predominate', word_class: 'V' },
    { word: 'predominance', word_class: 'N' },
    { word: 'predominant', word_class: 'AJ' },
    { word: 'predominate', word_class: 'AJ' },
    { word: 'predomination', word_class: 'N' }
  ],
  ascetic: [
    { word: 'ascetic', word_class: 'N' },
    { word: 'ascetic', word_class: 'AJ' },
    { word: 'ascetical', word_class: 'AJ' },
    { word: 'ascetically', word_class: 'AV' }
  ],
  vigorously: [
    { word: 'vigor', word_class: 'N' },
    { word: 'vigorous', word_class: 'AJ' },
    { word: 'vigorously', word_class: 'AV' }
  ],
  bilabial: [
    { word: 'bilabial', word_class: 'N' },
    { word: 'bilabial', word_class: 'AJ' },
    { word: 'bilabiate', word_class: 'AJ' }
  ],
  demeanor: [
    { word: 'demean', word_class: 'V' },
    { word: 'demeanor', word_class: 'N' },
    { word: 'demeaning', word_class: 'AJ' }
  ],
  allotted: [
    { word: 'allot', word_class: 'V' },
    { word: 'allotment', word_class: 'N' },
    { word: 'allotted', word_class: 'AJ' }
  ],
  superfluity: [
    { word: 'superfluity', word_class: 'N' },
    { word: 'superfluous', word_class: 'AJ' },
    { word: 'superfluously', word_class: 'AV' }
  ],
  pet: [
    { word: 'pet', word_class: 'N' },
    { word: 'pet', word_class: 'V' },
    { word: 'pet', word_class: 'AJ' },
    { word: 'petting', word_class: 'N' }
  ],
  aromatization: [
    { word: 'aroma', word_class: 'N' },
    { word: 'aromatic', word_class: 'AJ' },
    { word: 'aromatise', word_class: 'V' },
    { word: 'aromatize', word_class: 'V' },
    { word: 'aromatisation', word_class: 'N' },
    { word: 'aromatization', word_class: 'N' }
  ],
  rawboned: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  incompetency: [
    { word: 'incompetent', word_class: 'N' },
    { word: 'incompetence', word_class: 'N' },
    { word: 'incompetency', word_class: 'N' },
    { word: 'incompetent', word_class: 'AJ' },
    { word: 'incompetently', word_class: 'AV' }
  ],
  preconisation: [
    { word: 'preconise', word_class: 'V' },
    { word: 'preconize', word_class: 'V' },
    { word: 'preconisation', word_class: 'N' },
    { word: 'preconization', word_class: 'N' }
  ],
  haunting: [
    { word: 'haunt', word_class: 'N' },
    { word: 'haunt', word_class: 'V' },
    { word: 'haunted', word_class: 'AJ' },
    { word: 'haunting', word_class: 'AJ' }
  ],
  squeaker: [
    { word: 'squeak', word_class: 'N' },
    { word: 'squeak', word_class: 'V' },
    { word: 'squeaker', word_class: 'N' },
    { word: 'squeaking', word_class: 'AJ' }
  ],
  catch: [
    { word: 'catch', word_class: 'N' },
    { word: 'catch', word_class: 'V' },
    { word: 'catcher', word_class: 'N' },
    { word: 'catching', word_class: 'N' },
    { word: 'catching', word_class: 'AJ' },
    { word: 'catchment', word_class: 'N' }
  ],
  cannibalization: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  dispute: [
    { word: 'dispute', word_class: 'N' },
    { word: 'dispute', word_class: 'V' },
    { word: 'disputant', word_class: 'N' },
    { word: 'disputed', word_class: 'AJ' },
    { word: 'disputable', word_class: 'AJ' },
    { word: 'disputation', word_class: 'N' },
    { word: 'disputative', word_class: 'AJ' },
    { word: 'disputatious', word_class: 'AJ' },
    { word: 'disputatiously', word_class: 'AV' }
  ],
  semitropics: [
    { word: 'semitropic', word_class: 'AJ' },
    { word: 'semitropics', word_class: 'N' },
    { word: 'semitropical', word_class: 'AJ' }
  ],
  recrystallize: [
    { word: 'recrystallise', word_class: 'V' },
    { word: 'recrystallize', word_class: 'V' },
    { word: 'recrystallisation', word_class: 'N' },
    { word: 'recrystallization', word_class: 'N' }
  ],
  polar: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  avail: [
    { word: 'avail', word_class: 'N' },
    { word: 'avail', word_class: 'V' },
    { word: 'available', word_class: 'AJ' },
    { word: 'availability', word_class: 'N' },
    { word: 'availableness', word_class: 'N' }
  ],
  apostolical: [
    { word: 'apostle', word_class: 'N' },
    { word: 'apostolic', word_class: 'AJ' },
    { word: 'apostolical', word_class: 'AJ' }
  ],
  revising: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  heightening: [
    { word: 'high', word_class: 'N' },
    { word: 'high', word_class: 'AJ' },
    { word: 'high', word_class: 'AV' },
    { word: 'heighten', word_class: 'V' },
    { word: 'highness', word_class: 'N' },
    { word: 'heightening', word_class: 'AJ' }
  ],
  ascosporic: [
    { word: 'ascospore', word_class: 'N' },
    { word: 'ascosporic', word_class: 'AJ' },
    { word: 'ascosporous', word_class: 'AJ' }
  ],
  sequinned: [
    { word: 'sequin', word_class: 'N' },
    { word: 'sequin', word_class: 'V' },
    { word: 'sequined', word_class: 'AJ' },
    { word: 'sequinned', word_class: 'AJ' }
  ],
  spanking: [
    { word: 'spank', word_class: 'N' },
    { word: 'spank', word_class: 'V' },
    { word: 'spanker', word_class: 'N' },
    { word: 'spanking', word_class: 'N' },
    { word: 'spanking', word_class: 'AJ' }
  ],
  republicanism: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  discoverer: [
    { word: 'discover', word_class: 'N' },
    { word: 'discover', word_class: 'V' },
    { word: 'discovery', word_class: 'N' },
    { word: 'discoverer', word_class: 'N' },
    { word: 'discovery', word_class: 'AJ' },
    { word: 'discovered', word_class: 'AJ' },
    { word: 'discoverable', word_class: 'AJ' }
  ],
  exteriorize: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  prostration: [
    { word: 'prostrate', word_class: 'V' },
    { word: 'prostrate', word_class: 'AJ' },
    { word: 'prostration', word_class: 'N' }
  ],
  insight: [
    { word: 'insight', word_class: 'N' },
    { word: 'insight', word_class: 'V' },
    { word: 'insightful', word_class: 'AJ' },
    { word: 'insightfulness', word_class: 'N' }
  ],
  indefatigable: [
    { word: 'indefatigable', word_class: 'AJ' },
    { word: 'indefatigably', word_class: 'AV' },
    { word: 'indefatigation', word_class: 'N' },
    { word: 'indefatigability', word_class: 'N' },
    { word: 'indefatigableness', word_class: 'N' }
  ],
  changer: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  identity: [
    { word: 'identity', word_class: 'N' },
    { word: 'identical', word_class: 'AJ' },
    { word: 'identically', word_class: 'AV' }
  ],
  effervescence: [
    { word: 'effervesce', word_class: 'V' },
    { word: 'effervescence', word_class: 'N' },
    { word: 'effervescent', word_class: 'AJ' },
    { word: 'effervescing', word_class: 'AJ' }
  ],
  oscillating: [
    { word: 'oscillate', word_class: 'V' },
    { word: 'oscillator', word_class: 'N' },
    { word: 'oscillation', word_class: 'N' },
    { word: 'oscillating', word_class: 'AJ' }
  ],
  victualler: [
    { word: 'victual', word_class: 'N' },
    { word: 'victual', word_class: 'V' },
    { word: 'victuals', word_class: 'N' },
    { word: 'victualer', word_class: 'N' },
    { word: 'victualler', word_class: 'N' }
  ],
  lull: [{ word: 'lull', word_class: 'N' }, { word: 'lull', word_class: 'V' }, { word: 'lulling', word_class: 'AJ' }],
  peeled: [
    { word: 'peel', word_class: 'N' },
    { word: 'peel', word_class: 'V' },
    { word: 'peeler', word_class: 'N' },
    { word: 'peeled', word_class: 'AJ' },
    { word: 'peeling', word_class: 'AJ' }
  ],
  barbarousness: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  fraud: [
    { word: 'fraud', word_class: 'N' },
    { word: 'fraudulence', word_class: 'N' },
    { word: 'fraudulent', word_class: 'AJ' },
    { word: 'fraudulently', word_class: 'AV' }
  ],
  reviving: [
    { word: 'revive', word_class: 'V' },
    { word: 'revival', word_class: 'N' },
    { word: 'revive', word_class: 'AJ' },
    { word: 'revived', word_class: 'AJ' },
    { word: 'reviving', word_class: 'AJ' },
    { word: 'revivalism', word_class: 'N' }
  ],
  chaos: [
    { word: 'chaos', word_class: 'N' },
    { word: 'chaotic', word_class: 'AJ' },
    { word: 'chaotically', word_class: 'AV' }
  ],
  jaundiced: [
    { word: 'jaundice', word_class: 'N' },
    { word: 'jaundice', word_class: 'V' },
    { word: 'jaundiced', word_class: 'AJ' }
  ],
  glutted: [
    { word: 'glut', word_class: 'N' },
    { word: 'glut', word_class: 'V' },
    { word: 'glutted', word_class: 'AJ' }
  ],
  crapulent: [
    { word: 'crapulence', word_class: 'N' },
    { word: 'crapulent', word_class: 'AJ' },
    { word: 'crapulous', word_class: 'AJ' }
  ],
  cellular: [
    { word: 'cell', word_class: 'N' },
    { word: 'cellular', word_class: 'AJ' },
    { word: 'cellularity', word_class: 'N' }
  ],
  abysm: [
    { word: 'abysm', word_class: 'N' },
    { word: 'abyss', word_class: 'N' },
    { word: 'abysmal', word_class: 'AJ' },
    { word: 'abyssal', word_class: 'AJ' },
    { word: 'abysmally', word_class: 'AV' }
  ],
  description: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  frolicsome: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  erasable: [
    { word: 'erase', word_class: 'V' },
    { word: 'eraser', word_class: 'N' },
    { word: 'erasure', word_class: 'N' },
    { word: 'erasable', word_class: 'AJ' }
  ],
  clarified: [
    { word: 'clarify', word_class: 'V' },
    { word: 'clarified', word_class: 'AJ' },
    { word: 'clarifying', word_class: 'AJ' },
    { word: 'clarification', word_class: 'N' }
  ],
  machinate: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  liberate: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  fighting: [
    { word: 'fight', word_class: 'N' },
    { word: 'fight', word_class: 'V' },
    { word: 'fighter', word_class: 'N' },
    { word: 'fighting', word_class: 'N' },
    { word: 'fighting', word_class: 'AJ' }
  ],
  gradate: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  equally: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  interlineation: [
    { word: 'interline', word_class: 'V' },
    { word: 'interlining', word_class: 'N' },
    { word: 'interlineal', word_class: 'AJ' },
    { word: 'interlineate', word_class: 'V' },
    { word: 'interlineation', word_class: 'N' }
  ],
  incorporeality: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  disused: [
    { word: 'disuse', word_class: 'N' },
    { word: 'disuse', word_class: 'V' },
    { word: 'disused', word_class: 'AJ' }
  ],
  sluicing: [
    { word: 'sluice', word_class: 'N' },
    { word: 'sluice', word_class: 'V' },
    { word: 'sluicing', word_class: 'AJ' }
  ],
  quintuple: [
    { word: 'quintuple', word_class: 'V' },
    { word: 'quintuple', word_class: 'AJ' },
    { word: 'quintupling', word_class: 'N' },
    { word: 'quintuplicate', word_class: 'V' },
    { word: 'quintuplication', word_class: 'N' }
  ],
  layer: [
    { word: 'lay', word_class: 'N' },
    { word: 'lay', word_class: 'V' },
    { word: 'lay', word_class: 'AJ' },
    { word: 'layer', word_class: 'N' },
    { word: 'layer', word_class: 'V' },
    { word: 'laying', word_class: 'N' },
    { word: 'layman', word_class: 'N' },
    { word: 'layered', word_class: 'AJ' }
  ],
  rise: [
    { word: 'rise', word_class: 'N' },
    { word: 'rise', word_class: 'V' },
    { word: 'riser', word_class: 'N' },
    { word: 'risen', word_class: 'AJ' },
    { word: 'rising', word_class: 'N' },
    { word: 'rising', word_class: 'AJ' }
  ],
  principal: [
    { word: 'principe', word_class: 'N' },
    { word: 'principal', word_class: 'N' },
    { word: 'principal', word_class: 'AJ' },
    { word: 'principality', word_class: 'N' },
    { word: 'principally', word_class: 'AV' }
  ],
  curtained: [
    { word: 'curtain', word_class: 'N' },
    { word: 'curtain', word_class: 'V' },
    { word: 'curtained', word_class: 'AJ' }
  ],
  mineralisation: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  wholesaler: [
    { word: 'wholesale', word_class: 'N' },
    { word: 'wholesale', word_class: 'V' },
    { word: 'wholesale', word_class: 'AJ' },
    { word: 'wholesale', word_class: 'AV' },
    { word: 'wholesaler', word_class: 'N' }
  ],
  hibernate: [
    { word: 'hibernal', word_class: 'AJ' },
    { word: 'hibernate', word_class: 'V' },
    { word: 'hibernation', word_class: 'N' },
    { word: 'hibernating', word_class: 'AJ' }
  ],
  dribbler: [
    { word: 'dribble', word_class: 'N' },
    { word: 'dribble', word_class: 'V' },
    { word: 'dribbler', word_class: 'N' },
    { word: 'dribbling', word_class: 'N' }
  ],
  laborer: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  genuflexion: [
    { word: 'genuflect', word_class: 'V' },
    { word: 'genuflexion', word_class: 'N' },
    { word: 'genuflection', word_class: 'N' }
  ],
  permeation: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  briefs: [
    { word: 'brief', word_class: 'N' },
    { word: 'brief', word_class: 'V' },
    { word: 'brief', word_class: 'AJ' },
    { word: 'briefs', word_class: 'N' },
    { word: 'brevity', word_class: 'N' },
    { word: 'briefing', word_class: 'N' },
    { word: 'briefness', word_class: 'N' }
  ],
  bathing: [
    { word: 'bath', word_class: 'N' },
    { word: 'bath', word_class: 'V' },
    { word: 'bathe', word_class: 'N' },
    { word: 'bathe', word_class: 'V' },
    { word: 'bather', word_class: 'N' },
    { word: 'bathing', word_class: 'N' }
  ],
  realistically: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  cooperative: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  enlightened: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  soldering: [
    { word: 'solder', word_class: 'N' },
    { word: 'solder', word_class: 'V' },
    { word: 'soldering', word_class: 'N' }
  ],
  fear: [
    { word: 'fear', word_class: 'N' },
    { word: 'fear', word_class: 'V' },
    { word: 'fearful', word_class: 'AJ' },
    { word: 'fearsome', word_class: 'AJ' },
    { word: 'fearfulness', word_class: 'N' },
    { word: 'fearsomely', word_class: 'AV' }
  ],
  jug: [
    { word: 'jug', word_class: 'N' },
    { word: 'jug', word_class: 'V' },
    { word: 'jugful', word_class: 'N' },
    { word: 'jugful', word_class: 'AJ' }
  ],
  incestuous: [
    { word: 'incest', word_class: 'N' },
    { word: 'incestuous', word_class: 'AJ' },
    { word: 'incestuously', word_class: 'AV' }
  ],
  municipalise: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  bounteous: [
    { word: 'bounty', word_class: 'N' },
    { word: 'bountied', word_class: 'AJ' },
    { word: 'bounteous', word_class: 'AJ' },
    { word: 'bountiful', word_class: 'AJ' },
    { word: 'bountifulness', word_class: 'N' }
  ],
  bright: [
    { word: 'bright', word_class: 'AJ' },
    { word: 'bright', word_class: 'AV' },
    { word: 'brightness', word_class: 'N' }
  ],
  humans: [
    { word: 'human', word_class: 'N' },
    { word: 'human', word_class: 'AJ' },
    { word: 'humans', word_class: 'N' },
    { word: 'humane', word_class: 'AJ' },
    { word: 'humanise', word_class: 'V' },
    { word: 'humanist', word_class: 'N' },
    { word: 'humanoid', word_class: 'N' },
    { word: 'humanity', word_class: 'N' },
    { word: 'humanism', word_class: 'N' },
    { word: 'humanize', word_class: 'V' },
    { word: 'humanely', word_class: 'AV' },
    { word: 'humanist', word_class: 'AJ' },
    { word: 'humanness', word_class: 'N' },
    { word: 'humanoid', word_class: 'AJ' },
    { word: 'humaneness', word_class: 'N' },
    { word: 'humanities', word_class: 'N' },
    { word: 'humanistic', word_class: 'AJ' },
    { word: 'humanisation', word_class: 'N' },
    { word: 'humanitarian', word_class: 'N' },
    { word: 'humanization', word_class: 'N' },
    { word: 'humanitarian', word_class: 'AJ' },
    { word: 'humanitarianism', word_class: 'N' }
  ],
  iridescent: [
    { word: 'iridesce', word_class: 'V' },
    { word: 'iridescence', word_class: 'N' },
    { word: 'iridescent', word_class: 'AJ' }
  ],
  basic: [
    { word: 'basic', word_class: 'N' },
    { word: 'basic', word_class: 'AJ' },
    { word: 'basics', word_class: 'N' },
    { word: 'basically', word_class: 'AV' }
  ],
  possibleness: [
    { word: 'possible', word_class: 'N' },
    { word: 'possible', word_class: 'AJ' },
    { word: 'possibly', word_class: 'AV' },
    { word: 'possibility', word_class: 'N' },
    { word: 'possibleness', word_class: 'N' }
  ],
  infectious: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  obstructive: [
    { word: 'obstruct', word_class: 'V' },
    { word: 'obstructed', word_class: 'AJ' },
    { word: 'obstruction', word_class: 'N' },
    { word: 'obstructive', word_class: 'AJ' },
    { word: 'obstructively', word_class: 'AV' }
  ],
  transience: [
    { word: 'transient', word_class: 'N' },
    { word: 'transience', word_class: 'N' },
    { word: 'transiency', word_class: 'N' },
    { word: 'transient', word_class: 'AJ' },
    { word: 'transiently', word_class: 'AV' }
  ],
  ossify: [
    { word: 'ossify', word_class: 'V' },
    { word: 'ossified', word_class: 'AJ' },
    { word: 'ossification', word_class: 'N' }
  ],
  frisk: [
    { word: 'frisk', word_class: 'N' },
    { word: 'frisk', word_class: 'V' },
    { word: 'frisking', word_class: 'N' }
  ],
  clowning: [
    { word: 'clown', word_class: 'N' },
    { word: 'clown', word_class: 'V' },
    { word: 'clownery', word_class: 'N' },
    { word: 'clowning', word_class: 'N' },
    { word: 'clownish', word_class: 'AJ' }
  ],
  laconism: [
    { word: 'laconic', word_class: 'AJ' },
    { word: 'laconism', word_class: 'N' },
    { word: 'laconically', word_class: 'AV' }
  ],
  embold: [
    { word: 'embold', word_class: 'V' },
    { word: 'embolden', word_class: 'V' },
    { word: 'embolden', word_class: 'AJ' },
    { word: 'emboldened', word_class: 'AJ' },
    { word: 'emboldenment', word_class: 'N' }
  ],
  wrapped: [
    { word: 'wrap', word_class: 'N' },
    { word: 'wrap', word_class: 'V' },
    { word: 'wrapped', word_class: 'AJ' },
    { word: 'wrapping', word_class: 'N' }
  ],
  vulture: [
    { word: 'vultur', word_class: 'N' },
    { word: 'vulture', word_class: 'N' },
    { word: 'vulturous', word_class: 'AJ' }
  ],
  collision: [
    { word: 'collide', word_class: 'V' },
    { word: 'collider', word_class: 'N' },
    { word: 'collision', word_class: 'N' }
  ],
  picturesqueness: [
    { word: 'picturesque', word_class: 'AJ' },
    { word: 'picturesquely', word_class: 'AV' },
    { word: 'picturesqueness', word_class: 'N' }
  ],
  capped: [
    { word: 'cap', word_class: 'N' },
    { word: 'cap', word_class: 'V' },
    { word: 'capful', word_class: 'N' },
    { word: 'caption', word_class: 'N' },
    { word: 'capful', word_class: 'AJ' },
    { word: 'capped', word_class: 'AJ' },
    { word: 'caption', word_class: 'V' }
  ],
  interrupt: [
    { word: 'interrupt', word_class: 'V' },
    { word: 'interrupter', word_class: 'N' },
    { word: 'interrupted', word_class: 'AJ' },
    { word: 'interruption', word_class: 'N' }
  ],
  regular: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  savageness: [
    { word: 'savage', word_class: 'V' },
    { word: 'savage', word_class: 'N' },
    { word: 'savage', word_class: 'AJ' },
    { word: 'savagery', word_class: 'N' },
    { word: 'savagely', word_class: 'AV' },
    { word: 'savagery', word_class: 'AJ' },
    { word: 'savageness', word_class: 'N' }
  ],
  bestrid: [
    { word: 'bestrid', word_class: 'AJ' },
    { word: 'bestride', word_class: 'V' },
    { word: 'bestridden', word_class: 'AJ' }
  ],
  coughing: [
    { word: 'cough', word_class: 'N' },
    { word: 'cough', word_class: 'V' },
    { word: 'coughing', word_class: 'N' }
  ],
  bewitched: [
    { word: 'bewitch', word_class: 'V' },
    { word: 'bewitched', word_class: 'AJ' },
    { word: 'bewitching', word_class: 'AJ' },
    { word: 'bewitchment', word_class: 'N' }
  ],
  orientation: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  aestivation: [
    { word: 'aestival', word_class: 'AJ' },
    { word: 'aestivate', word_class: 'V' },
    { word: 'aestivation', word_class: 'N' }
  ],
  ambulate: [
    { word: 'ambulate', word_class: 'V' },
    { word: 'ambulance', word_class: 'N' },
    { word: 'ambulant', word_class: 'AJ' },
    { word: 'ambulation', word_class: 'N' }
  ],
  constrict: [
    { word: 'constrict', word_class: 'V' },
    { word: 'constrictor', word_class: 'N' },
    { word: 'constricted', word_class: 'AJ' },
    { word: 'constriction', word_class: 'N' },
    { word: 'constricting', word_class: 'AJ' },
    { word: 'constrictive', word_class: 'AJ' }
  ],
  established: [
    { word: 'establish', word_class: 'V' },
    { word: 'established', word_class: 'AJ' },
    { word: 'establishment', word_class: 'N' }
  ],
  trichinization: [
    { word: 'trichinise', word_class: 'V' },
    { word: 'trichinize', word_class: 'V' },
    { word: 'trichinisation', word_class: 'N' },
    { word: 'trichinization', word_class: 'N' }
  ],
  interested: [
    { word: 'interest', word_class: 'N' },
    { word: 'interest', word_class: 'V' },
    { word: 'interested', word_class: 'AJ' },
    { word: 'interesting', word_class: 'AJ' }
  ],
  frustrate: [
    { word: 'frustrate', word_class: 'V' },
    { word: 'frustrated', word_class: 'AJ' },
    { word: 'frustrating', word_class: 'N' },
    { word: 'frustration', word_class: 'N' },
    { word: 'frustrating', word_class: 'AJ' }
  ],
  victuals: [
    { word: 'victual', word_class: 'N' },
    { word: 'victual', word_class: 'V' },
    { word: 'victuals', word_class: 'N' },
    { word: 'victualer', word_class: 'N' },
    { word: 'victualler', word_class: 'N' }
  ],
  lumbering: [
    { word: 'lumber', word_class: 'N' },
    { word: 'lumber', word_class: 'V' },
    { word: 'lumberman', word_class: 'N' },
    { word: 'lumbering', word_class: 'AJ' }
  ],
  interne: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  pitted: [
    { word: 'pit', word_class: 'N' },
    { word: 'pit', word_class: 'V' },
    { word: 'pitman', word_class: 'N' },
    { word: 'pitted', word_class: 'AJ' },
    { word: 'pitting', word_class: 'N' }
  ],
  maternally: [
    { word: 'maternal', word_class: 'AJ' },
    { word: 'maternity', word_class: 'N' },
    { word: 'maternally', word_class: 'AV' }
  ],
  sociologically: [
    { word: 'sociology', word_class: 'N' },
    { word: 'sociological', word_class: 'AJ' },
    { word: 'sociologically', word_class: 'AV' }
  ],
  unpalatable: [
    { word: 'unpalatable', word_class: 'AJ' },
    { word: 'unpalatably', word_class: 'AV' },
    { word: 'unpalatability', word_class: 'N' },
    { word: 'unpalatableness', word_class: 'N' }
  ],
  double: [
    { word: 'double', word_class: 'N' },
    { word: 'double', word_class: 'V' },
    { word: 'double', word_class: 'AJ' },
    { word: 'double', word_class: 'AV' },
    { word: 'doubles', word_class: 'N' },
    { word: 'doubly', word_class: 'AV' },
    { word: 'doubled', word_class: 'AJ' },
    { word: 'doubling', word_class: 'N' }
  ],
  frozen: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  receipts: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  extortionate: [
    { word: 'extort', word_class: 'N' },
    { word: 'extort', word_class: 'V' },
    { word: 'extorsion', word_class: 'N' },
    { word: 'extortion', word_class: 'N' },
    { word: 'extortioner', word_class: 'N' },
    { word: 'extortionate', word_class: 'AJ' },
    { word: 'extortionately', word_class: 'AV' }
  ],
  awarding: [
    { word: 'award', word_class: 'N' },
    { word: 'award', word_class: 'V' },
    { word: 'awarding', word_class: 'N' }
  ],
  poison: [
    { word: 'poison', word_class: 'N' },
    { word: 'poison', word_class: 'V' },
    { word: 'poisoner', word_class: 'N' },
    { word: 'poisoning', word_class: 'N' },
    { word: 'poisonous', word_class: 'AJ' },
    { word: 'poisonously', word_class: 'AV' }
  ],
  familiarize: [
    { word: 'familiar', word_class: 'N' },
    { word: 'familiar', word_class: 'AJ' },
    { word: 'familiarise', word_class: 'V' },
    { word: 'familiarity', word_class: 'N' },
    { word: 'familiarize', word_class: 'V' },
    { word: 'familiarized', word_class: 'AJ' },
    { word: 'familiarizing', word_class: 'AJ' },
    { word: 'familiarisation', word_class: 'N' },
    { word: 'familiarization', word_class: 'N' }
  ],
  rampage: [
    { word: 'rampage', word_class: 'N' },
    { word: 'rampage', word_class: 'V' },
    { word: 'rampageous', word_class: 'AJ' }
  ],
  ruffianism: [
    { word: 'ruffian', word_class: 'N' },
    { word: 'ruffian', word_class: 'AJ' },
    { word: 'ruffianism', word_class: 'N' }
  ],
  cervical: [
    { word: 'cervix', word_class: 'N' },
    { word: 'cervical', word_class: 'AJ' },
    { word: 'cervicitis', word_class: 'N' }
  ],
  opener: [
    { word: 'open', word_class: 'N' },
    { word: 'open', word_class: 'V' },
    { word: 'open', word_class: 'AJ' },
    { word: 'opener', word_class: 'N' },
    { word: 'opening', word_class: 'N' },
    { word: 'opened', word_class: 'AJ' },
    { word: 'opening', word_class: 'AJ' },
    { word: 'openness', word_class: 'N' }
  ],
  dark: [{ word: 'dark', word_class: 'N' }, { word: 'dark', word_class: 'AJ' }, { word: 'darkness', word_class: 'N' }],
  orientalisation: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  vary: [
    { word: 'vary', word_class: 'V' },
    { word: 'variant', word_class: 'N' },
    { word: 'varied', word_class: 'AJ' },
    { word: 'variate', word_class: 'V' },
    { word: 'variate', word_class: 'N' },
    { word: 'variable', word_class: 'N' },
    { word: 'variance', word_class: 'N' },
    { word: 'variant', word_class: 'AJ' },
    { word: 'various', word_class: 'AJ' },
    { word: 'varying', word_class: 'AJ' },
    { word: 'variable', word_class: 'AJ' },
    { word: 'variation', word_class: 'N' },
    { word: 'variably', word_class: 'AV' },
    { word: 'variability', word_class: 'N' },
    { word: 'variational', word_class: 'N' },
    { word: 'variableness', word_class: 'N' }
  ],
  stretching: [
    { word: 'stretch', word_class: 'N' },
    { word: 'stretch', word_class: 'V' },
    { word: 'stretch', word_class: 'AJ' },
    { word: 'stretcher', word_class: 'N' },
    { word: 'stretched', word_class: 'AJ' },
    { word: 'stretching', word_class: 'N' },
    { word: 'stretching', word_class: 'AJ' }
  ],
  mournful: [
    { word: 'mourn', word_class: 'V' },
    { word: 'mourner', word_class: 'N' },
    { word: 'mourning', word_class: 'N' },
    { word: 'mournful', word_class: 'AJ' },
    { word: 'mourning', word_class: 'AJ' },
    { word: 'mournfulness', word_class: 'N' }
  ],
  spatter: [
    { word: 'spatter', word_class: 'N' },
    { word: 'spatter', word_class: 'V' },
    { word: 'spattered', word_class: 'AJ' },
    { word: 'spattering', word_class: 'N' }
  ],
  sonority: [
    { word: 'sonorant', word_class: 'N' },
    { word: 'sonority', word_class: 'N' },
    { word: 'sonorant', word_class: 'AJ' },
    { word: 'sonorous', word_class: 'AJ' },
    { word: 'sonorously', word_class: 'AV' },
    { word: 'sonorousness', word_class: 'N' }
  ],
  opportunist: [
    { word: 'opportunist', word_class: 'N' },
    { word: 'opportunist', word_class: 'AJ' },
    { word: 'opportunistic', word_class: 'AJ' }
  ],
  fried: [{ word: 'fri', word_class: 'N' }, { word: 'fries', word_class: 'N' }, { word: 'fried', word_class: 'AJ' }],
  depreciating: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  taker: [
    { word: 'take', word_class: 'N' },
    { word: 'take', word_class: 'V' },
    { word: 'taker', word_class: 'N' },
    { word: 'taken', word_class: 'AJ' },
    { word: 'taking', word_class: 'N' },
    { word: 'taking', word_class: 'AJ' },
    { word: 'takings', word_class: 'N' }
  ],
  africanize: [
    { word: 'african', word_class: 'N' },
    { word: 'african', word_class: 'AJ' },
    { word: 'africanise', word_class: 'V' },
    { word: 'africanize', word_class: 'V' },
    { word: 'africanisation', word_class: 'N' },
    { word: 'africanization', word_class: 'N' }
  ],
  tranquillity: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  spy: [{ word: 'spy', word_class: 'N' }, { word: 'spy', word_class: 'V' }, { word: 'spying', word_class: 'N' }],
  merciful: [
    { word: 'mercy', word_class: 'N' },
    { word: 'merciful', word_class: 'AJ' },
    { word: 'mercifulness', word_class: 'N' }
  ],
  indexer: [
    { word: 'index', word_class: 'N' },
    { word: 'index', word_class: 'V' },
    { word: 'indexer', word_class: 'N' },
    { word: 'indexing', word_class: 'N' },
    { word: 'indexation', word_class: 'N' },
    { word: 'indexical', word_class: 'AJ' }
  ],
  factual: [
    { word: 'factual', word_class: 'AJ' },
    { word: 'factuality', word_class: 'N' },
    { word: 'factually', word_class: 'AV' },
    { word: 'factualness', word_class: 'N' }
  ],
  crossing: [
    { word: 'cross', word_class: 'N' },
    { word: 'cross', word_class: 'V' },
    { word: 'cross', word_class: 'AJ' },
    { word: 'crosse', word_class: 'N' },
    { word: 'crossed', word_class: 'AJ' },
    { word: 'crossing', word_class: 'N' },
    { word: 'crossing', word_class: 'AJ' },
    { word: 'crossness', word_class: 'N' }
  ],
  honeymoon: [
    { word: 'honeymoon', word_class: 'N' },
    { word: 'honeymoon', word_class: 'V' },
    { word: 'honeymooner', word_class: 'N' }
  ],
  pine: [{ word: 'pine', word_class: 'N' }, { word: 'pine', word_class: 'V' }, { word: 'pining', word_class: 'N' }],
  skis: [
    { word: 'ski', word_class: 'N' },
    { word: 'ski', word_class: 'V' },
    { word: 'skis', word_class: 'N' },
    { word: 'skier', word_class: 'N' },
    { word: 'skiing', word_class: 'N' }
  ],
  globe: [
    { word: 'globe', word_class: 'N' },
    { word: 'globular', word_class: 'AJ' },
    { word: 'globularness', word_class: 'N' }
  ],
  knackery: [
    { word: 'knacker', word_class: 'N' },
    { word: 'knacker', word_class: 'V' },
    { word: 'knackery', word_class: 'N' }
  ],
  penal: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  youthful: [
    { word: 'youth', word_class: 'N' },
    { word: 'youthful', word_class: 'AJ' },
    { word: 'youthfulness', word_class: 'N' }
  ],
  imbed: [
    { word: 'embed', word_class: 'V' },
    { word: 'imbed', word_class: 'N' },
    { word: 'imbed', word_class: 'V' },
    { word: 'embedded', word_class: 'AJ' },
    { word: 'embedment', word_class: 'N' },
    { word: 'imbedment', word_class: 'N' }
  ],
  susurration: [
    { word: 'susurrate', word_class: 'V' },
    { word: 'susurrant', word_class: 'AJ' },
    { word: 'susurrous', word_class: 'AJ' },
    { word: 'susurration', word_class: 'N' }
  ],
  portentous: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  intonation: [
    { word: 'intone', word_class: 'N' },
    { word: 'intone', word_class: 'V' },
    { word: 'intonate', word_class: 'V' },
    { word: 'intoned', word_class: 'AJ' },
    { word: 'intonation', word_class: 'N' }
  ],
  logarithmic: [
    { word: 'logarithm', word_class: 'N' },
    { word: 'logarithmic', word_class: 'AJ' },
    { word: 'logarithmically', word_class: 'AV' }
  ],
  cultivator: [
    { word: 'cultivate', word_class: 'V' },
    { word: 'cultivator', word_class: 'N' },
    { word: 'cultivable', word_class: 'AJ' },
    { word: 'cultivated', word_class: 'AJ' },
    { word: 'cultivation', word_class: 'N' }
  ],
  noticeably: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  ardent: [
    { word: 'ardency', word_class: 'N' },
    { word: 'ardent', word_class: 'AJ' },
    { word: 'ardently', word_class: 'AV' }
  ],
  osmotic: [
    { word: 'osmose', word_class: 'V' },
    { word: 'osmosis', word_class: 'N' },
    { word: 'osmotic', word_class: 'AJ' },
    { word: 'osmotically', word_class: 'AV' }
  ],
  healer: [
    { word: 'heal', word_class: 'V' },
    { word: 'healer', word_class: 'N' },
    { word: 'healed', word_class: 'AJ' },
    { word: 'healing', word_class: 'N' },
    { word: 'healing', word_class: 'AJ' }
  ],
  anomalous: [
    { word: 'anomaly', word_class: 'N' },
    { word: 'anomalous', word_class: 'AJ' },
    { word: 'anomalously', word_class: 'AV' },
    { word: 'anomalousness', word_class: 'N' }
  ],
  meteorite: [
    { word: 'meteorite', word_class: 'N' },
    { word: 'meteoritic', word_class: 'AJ' },
    { word: 'meteoritical', word_class: 'AJ' }
  ],
  gaminess: [
    { word: 'game', word_class: 'N' },
    { word: 'game', word_class: 'V' },
    { word: 'game', word_class: 'AJ' },
    { word: 'gamy', word_class: 'AJ' },
    { word: 'gaming', word_class: 'N' },
    { word: 'gamely', word_class: 'AV' },
    { word: 'gameness', word_class: 'N' },
    { word: 'gaminess', word_class: 'N' },
    { word: 'gamesome', word_class: 'AJ' }
  ],
  skewness: [
    { word: 'skew', word_class: 'V' },
    { word: 'skew', word_class: 'AJ' },
    { word: 'skewed', word_class: 'AJ' },
    { word: 'skewness', word_class: 'N' }
  ],
  companionship: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  extremism: [
    { word: 'extreme', word_class: 'N' },
    { word: 'extreme', word_class: 'AJ' },
    { word: 'extremism', word_class: 'N' },
    { word: 'extremity', word_class: 'N' },
    { word: 'extremely', word_class: 'AV' }
  ],
  patriot: [
    { word: 'patriot', word_class: 'N' },
    { word: 'patriotic', word_class: 'AJ' },
    { word: 'patriotism', word_class: 'N' },
    { word: 'patriotically', word_class: 'AV' }
  ],
  cozen: [
    { word: 'cozen', word_class: 'N' },
    { word: 'cozen', word_class: 'V' },
    { word: 'cozenage', word_class: 'N' }
  ],
  offset: [
    { word: 'offset', word_class: 'N' },
    { word: 'offset', word_class: 'V' },
    { word: 'offsetting', word_class: 'AJ' }
  ],
  overstatement: [
    { word: 'overstate', word_class: 'V' },
    { word: 'overstated', word_class: 'AJ' },
    { word: 'overstatement', word_class: 'N' }
  ],
  hunched: [
    { word: 'hunch', word_class: 'N' },
    { word: 'hunch', word_class: 'V' },
    { word: 'hunched', word_class: 'AJ' }
  ],
  affection: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  immobilizing: [
    { word: 'immobile', word_class: 'AJ' },
    { word: 'immobilise', word_class: 'V' },
    { word: 'immobilize', word_class: 'V' },
    { word: 'immobilizing', word_class: 'N' },
    { word: 'immobilisation', word_class: 'N' },
    { word: 'immobilization', word_class: 'N' }
  ],
  ravishing: [
    { word: 'ravish', word_class: 'V' },
    { word: 'ravisher', word_class: 'N' },
    { word: 'ravishing', word_class: 'AJ' },
    { word: 'ravishment', word_class: 'N' }
  ],
  winger: [
    { word: 'wing', word_class: 'N' },
    { word: 'wing', word_class: 'V' },
    { word: 'wings', word_class: 'N' },
    { word: 'winger', word_class: 'N' },
    { word: 'winged', word_class: 'AJ' },
    { word: 'wingman', word_class: 'N' }
  ],
  allege: [
    { word: 'allege', word_class: 'V' },
    { word: 'alleged', word_class: 'AJ' },
    { word: 'allegation', word_class: 'N' },
    { word: 'allegement', word_class: 'N' },
    { word: 'allegeable', word_class: 'AJ' }
  ],
  profits: [
    { word: 'profit', word_class: 'N' },
    { word: 'profit', word_class: 'V' },
    { word: 'profit', word_class: 'AJ' },
    { word: 'profits', word_class: 'N' },
    { word: 'profiteer', word_class: 'N' },
    { word: 'profiteer', word_class: 'V' },
    { word: 'profitable', word_class: 'AJ' },
    { word: 'profitably', word_class: 'AV' },
    { word: 'profiteering', word_class: 'N' },
    { word: 'profitability', word_class: 'N' },
    { word: 'profitableness', word_class: 'N' }
  ],
  dimmed: [
    { word: 'dim', word_class: 'V' },
    { word: 'dim', word_class: 'AJ' },
    { word: 'dimer', word_class: 'N' },
    { word: 'dimmed', word_class: 'AJ' },
    { word: 'dimness', word_class: 'N' }
  ],
  perennially: [
    { word: 'perennial', word_class: 'N' },
    { word: 'perennial', word_class: 'AJ' },
    { word: 'perennially', word_class: 'AV' }
  ],
  inciter: [
    { word: 'incite', word_class: 'V' },
    { word: 'inciter', word_class: 'N' },
    { word: 'incitive', word_class: 'AJ' },
    { word: 'incitation', word_class: 'N' },
    { word: 'incitement', word_class: 'N' }
  ],
  porosity: [
    { word: 'pore', word_class: 'N' },
    { word: 'pore', word_class: 'V' },
    { word: 'porose', word_class: 'AJ' },
    { word: 'porosity', word_class: 'N' }
  ],
  autotomization: [
    { word: 'autotomic', word_class: 'AJ' },
    { word: 'autotomize', word_class: 'V' },
    { word: 'autotomization', word_class: 'N' }
  ],
  masher: [{ word: 'mash', word_class: 'N' }, { word: 'mash', word_class: 'V' }, { word: 'masher', word_class: 'N' }],
  capacitive: [
    { word: 'capacitate', word_class: 'V' },
    { word: 'capacitance', word_class: 'N' },
    { word: 'capacitive', word_class: 'AJ' },
    { word: 'capacitation', word_class: 'N' }
  ],
  cuspid: [
    { word: 'cuspid', word_class: 'N' },
    { word: 'cuspidal', word_class: 'AJ' },
    { word: 'cuspidate', word_class: 'AJ' },
    { word: 'cuspidated', word_class: 'AJ' },
    { word: 'cuspidation', word_class: 'N' }
  ],
  expanse: [
    { word: 'expand', word_class: 'V' },
    { word: 'expanse', word_class: 'N' },
    { word: 'expanded', word_class: 'AJ' },
    { word: 'expansion', word_class: 'N' },
    { word: 'expanding', word_class: 'AJ' },
    { word: 'expansive', word_class: 'AJ' },
    { word: 'expansible', word_class: 'AJ' },
    { word: 'expandable', word_class: 'AJ' },
    { word: 'expandible', word_class: 'AJ' },
    { word: 'expansivity', word_class: 'N' },
    { word: 'expansively', word_class: 'AV' },
    { word: 'expansiveness', word_class: 'N' }
  ],
  fishing: [
    { word: 'fish', word_class: 'N' },
    { word: 'fish', word_class: 'V' },
    { word: 'fisher', word_class: 'N' },
    { word: 'fishery', word_class: 'N' },
    { word: 'fishing', word_class: 'N' },
    { word: 'fishery', word_class: 'AJ' }
  ],
  exemption: [
    { word: 'exempt', word_class: 'V' },
    { word: 'exempt', word_class: 'AJ' },
    { word: 'exemption', word_class: 'N' }
  ],
  slavery: [
    { word: 'slave', word_class: 'N' },
    { word: 'slave', word_class: 'V' },
    { word: 'slave', word_class: 'AJ' },
    { word: 'slavery', word_class: 'N' },
    { word: 'slavery', word_class: 'AJ' }
  ],
  entreaty: [
    { word: 'entreat', word_class: 'V' },
    { word: 'intreat', word_class: 'V' },
    { word: 'entreaty', word_class: 'N' },
    { word: 'entreatment', word_class: 'N' },
    { word: 'intreatment', word_class: 'N' }
  ],
  unwearied: [
    { word: 'unweary', word_class: 'AJ' },
    { word: 'unwearied', word_class: 'AJ' },
    { word: 'unwearying', word_class: 'AJ' }
  ],
  summarization: [
    { word: 'summary', word_class: 'N' },
    { word: 'summary', word_class: 'AJ' },
    { word: 'summarise', word_class: 'V' },
    { word: 'summarize', word_class: 'V' },
    { word: 'summarisation', word_class: 'N' },
    { word: 'summarization', word_class: 'N' }
  ],
  stump: [
    { word: 'stump', word_class: 'N' },
    { word: 'stump', word_class: 'V' },
    { word: 'stumper', word_class: 'N' },
    { word: 'stumping', word_class: 'N' }
  ],
  fairness: [
    { word: 'fair', word_class: 'N' },
    { word: 'fair', word_class: 'V' },
    { word: 'fair', word_class: 'AJ' },
    { word: 'fair', word_class: 'AV' },
    { word: 'fairness', word_class: 'N' }
  ],
  patriarchate: [
    { word: 'patriarch', word_class: 'N' },
    { word: 'patriarchs', word_class: 'N' },
    { word: 'patriarchal', word_class: 'AJ' },
    { word: 'patriarchate', word_class: 'N' },
    { word: 'patriarchic', word_class: 'AJ' }
  ],
  capitalistic: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  resolving: [
    { word: 'resolve', word_class: 'N' },
    { word: 'resolve', word_class: 'V' },
    { word: 'resolute', word_class: 'N' },
    { word: 'resolute', word_class: 'AJ' },
    { word: 'resolvent', word_class: 'N' },
    { word: 'resolved', word_class: 'AJ' },
    { word: 'resolving', word_class: 'N' },
    { word: 'resolution', word_class: 'N' },
    { word: 'resolutely', word_class: 'AV' },
    { word: 'resolvable', word_class: 'AJ' },
    { word: 'resoluteness', word_class: 'N' }
  ],
  financial: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  spirituous: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  carouse: [
    { word: 'carouse', word_class: 'N' },
    { word: 'carouse', word_class: 'V' },
    { word: 'carousal', word_class: 'N' },
    { word: 'carouser', word_class: 'N' },
    { word: 'carousing', word_class: 'AJ' }
  ],
  answer: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  indeterminancy: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  monstrous: [
    { word: 'monster', word_class: 'N' },
    { word: 'monstrous', word_class: 'AJ' },
    { word: 'monstrosity', word_class: 'N' }
  ],
  intentness: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  humor: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  thermodynamical: [
    { word: 'thermodynamic', word_class: 'AJ' },
    { word: 'thermodynamics', word_class: 'N' },
    { word: 'thermodynamical', word_class: 'AJ' },
    { word: 'thermodynamically', word_class: 'AV' }
  ],
  remunerated: [
    { word: 'remunerate', word_class: 'V' },
    { word: 'remunerator', word_class: 'N' },
    { word: 'remunerated', word_class: 'AJ' },
    { word: 'remuneration', word_class: 'N' }
  ],
  buoyancy: [
    { word: 'buoy', word_class: 'N' },
    { word: 'buoy', word_class: 'V' },
    { word: 'buoyant', word_class: 'N' },
    { word: 'buoyancy', word_class: 'N' },
    { word: 'buoyant', word_class: 'AJ' }
  ],
  aphrodisiac: [
    { word: 'aphrodisiac', word_class: 'N' },
    { word: 'aphrodisiac', word_class: 'AJ' },
    { word: 'aphrodisiacal', word_class: 'AJ' }
  ],
  skeptical: [
    { word: 'skeptic', word_class: 'N' },
    { word: 'skeptical', word_class: 'AJ' },
    { word: 'skepticism', word_class: 'N' },
    { word: 'skeptically', word_class: 'AV' }
  ],
  draught: [
    { word: 'draught', word_class: 'N' },
    { word: 'draught', word_class: 'V' },
    { word: 'draughts', word_class: 'N' }
  ],
  incinerator: [
    { word: 'incinerate', word_class: 'V' },
    { word: 'incinerator', word_class: 'N' },
    { word: 'incineration', word_class: 'N' }
  ],
  forfeited: [
    { word: 'forfeit', word_class: 'N' },
    { word: 'forfeit', word_class: 'V' },
    { word: 'forfeit', word_class: 'AJ' },
    { word: 'forfeited', word_class: 'AJ' },
    { word: 'forfeiture', word_class: 'N' }
  ],
  disenfranchise: [
    { word: 'disenfranchise', word_class: 'V' },
    { word: 'disenfranchised', word_class: 'AJ' },
    { word: 'disenfranchisement', word_class: 'N' }
  ],
  shattered: [
    { word: 'shatter', word_class: 'V' },
    { word: 'shattered', word_class: 'AJ' },
    { word: 'shattering', word_class: 'N' },
    { word: 'shattering', word_class: 'AJ' }
  ],
  divers: [
    { word: 'dive', word_class: 'N' },
    { word: 'dive', word_class: 'V' },
    { word: 'diver', word_class: 'N' },
    { word: 'diving', word_class: 'N' },
    { word: 'divers', word_class: 'AJ' }
  ],
  organic: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  arithmetically: [
    { word: 'arithmetic', word_class: 'N' },
    { word: 'arithmetic', word_class: 'AJ' },
    { word: 'arithmetical', word_class: 'AJ' },
    { word: 'arithmetically', word_class: 'AV' }
  ],
  naturalness: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  torturing: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  permissively: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  shininess: [
    { word: 'shine', word_class: 'N' },
    { word: 'shine', word_class: 'V' },
    { word: 'shiny', word_class: 'AJ' },
    { word: 'shiner', word_class: 'N' },
    { word: 'shining', word_class: 'N' },
    { word: 'shining', word_class: 'AJ' },
    { word: 'shininess', word_class: 'N' }
  ],
  polygon: [
    { word: 'polygon', word_class: 'N' },
    { word: 'polygonal', word_class: 'AJ' },
    { word: 'polygonally', word_class: 'AV' }
  ],
  pitched: [
    { word: 'pitch', word_class: 'N' },
    { word: 'pitch', word_class: 'V' },
    { word: 'pitcher', word_class: 'N' },
    { word: 'pitched', word_class: 'AJ' },
    { word: 'pitching', word_class: 'N' },
    { word: 'pitcherful', word_class: 'N' }
  ],
  censorious: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  untangling: [
    { word: 'untangle', word_class: 'V' },
    { word: 'untangled', word_class: 'AJ' },
    { word: 'untangling', word_class: 'N' }
  ],
  departmentalization: [
    { word: 'departmental', word_class: 'AJ' },
    { word: 'departmentalise', word_class: 'V' },
    { word: 'departmentalize', word_class: 'V' },
    { word: 'departmentally', word_class: 'AV' },
    { word: 'departmentalisation', word_class: 'N' },
    { word: 'departmentalization', word_class: 'N' }
  ],
  serially: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  practicable: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  urbanized: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  fugu: [
    { word: 'fugu', word_class: 'N' },
    { word: 'fugue', word_class: 'N' },
    { word: 'fugal', word_class: 'AJ' },
    { word: 'fugally', word_class: 'AV' }
  ],
  catechization: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  unequally: [
    { word: 'unequal', word_class: 'AJ' },
    { word: 'unequaled', word_class: 'AJ' },
    { word: 'unequally', word_class: 'AV' },
    { word: 'unequalized', word_class: 'AJ' }
  ],
  focalization: [
    { word: 'focal', word_class: 'AJ' },
    { word: 'focalize', word_class: 'V' },
    { word: 'focally', word_class: 'AV' },
    { word: 'focalisation', word_class: 'N' },
    { word: 'focalization', word_class: 'N' }
  ],
  incontrovertible: [
    { word: 'incontrovertible', word_class: 'AJ' },
    { word: 'incontrovertibly', word_class: 'AV' },
    { word: 'incontrovertibility', word_class: 'N' },
    { word: 'incontrovertibleness', word_class: 'N' }
  ],
  cubicity: [
    { word: 'cubic', word_class: 'AJ' },
    { word: 'cubical', word_class: 'AJ' },
    { word: 'cubicity', word_class: 'N' }
  ],
  redheader: [
    { word: 'redhead', word_class: 'N' },
    { word: 'redheader', word_class: 'N' },
    { word: 'redheaded', word_class: 'AJ' }
  ],
  naturalization: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  pile: [
    { word: 'pile', word_class: 'N' },
    { word: 'pile', word_class: 'V' },
    { word: 'piles', word_class: 'N' },
    { word: 'piled', word_class: 'AJ' },
    { word: 'piling', word_class: 'N' }
  ],
  interlace: [
    { word: 'interlace', word_class: 'V' },
    { word: 'interlaced', word_class: 'AJ' },
    { word: 'interlacing', word_class: 'AJ' },
    { word: 'interlacement', word_class: 'N' }
  ],
  esthete: [
    { word: 'esthete', word_class: 'N' },
    { word: 'esthetic', word_class: 'N' },
    { word: 'esthetic', word_class: 'AJ' },
    { word: 'esthetics', word_class: 'N' },
    { word: 'esthetical', word_class: 'AJ' },
    { word: 'esthetically', word_class: 'AV' }
  ],
  polymerisation: [
    { word: 'polymeric', word_class: 'AJ' },
    { word: 'polymerise', word_class: 'V' },
    { word: 'polymerize', word_class: 'V' },
    { word: 'polymerisation', word_class: 'N' },
    { word: 'polymerization', word_class: 'N' }
  ],
  hyperbolization: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  registrar: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  mistrustful: [
    { word: 'mistrust', word_class: 'N' },
    { word: 'mistrust', word_class: 'V' },
    { word: 'mistrustful', word_class: 'AJ' }
  ],
  cracked: [
    { word: 'crack', word_class: 'N' },
    { word: 'crack', word_class: 'V' },
    { word: 'crack', word_class: 'AJ' },
    { word: 'cracker', word_class: 'N' },
    { word: 'cracked', word_class: 'AJ' },
    { word: 'cracking', word_class: 'N' },
    { word: 'crackers', word_class: 'AJ' },
    { word: 'cracking', word_class: 'AJ' }
  ],
  fixed: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  gripe: [
    { word: 'grip', word_class: 'N' },
    { word: 'grip', word_class: 'V' },
    { word: 'gripe', word_class: 'N' },
    { word: 'gripe', word_class: 'V' },
    { word: 'gripping', word_class: 'AJ' }
  ],
  sensational: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  piping: [
    { word: 'pipe', word_class: 'N' },
    { word: 'pipe', word_class: 'V' },
    { word: 'piper', word_class: 'N' },
    { word: 'pipes', word_class: 'N' },
    { word: 'piping', word_class: 'N' },
    { word: 'pipeful', word_class: 'N' },
    { word: 'piping', word_class: 'AJ' },
    { word: 'piping', word_class: 'AV' },
    { word: 'pipeful', word_class: 'AJ' }
  ],
  punish: [
    { word: 'punish', word_class: 'V' },
    { word: 'punished', word_class: 'AJ' },
    { word: 'punishing', word_class: 'AJ' },
    { word: 'punishment', word_class: 'N' },
    { word: 'punishable', word_class: 'AJ' }
  ],
  fermented: [
    { word: 'ferment', word_class: 'N' },
    { word: 'ferment', word_class: 'V' },
    { word: 'fermented', word_class: 'AJ' },
    { word: 'fermenting', word_class: 'N' },
    { word: 'fermentation', word_class: 'N' }
  ],
  viscid: [
    { word: 'viscid', word_class: 'AJ' },
    { word: 'viscidity', word_class: 'N' },
    { word: 'viscidness', word_class: 'N' }
  ],
  sympathizer: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  rabbi: [
    { word: 'rabbi', word_class: 'N' },
    { word: 'rabbinate', word_class: 'N' },
    { word: 'rabbinic', word_class: 'AJ' },
    { word: 'rabbinical', word_class: 'AJ' }
  ],
  crock: [
    { word: 'crock', word_class: 'N' },
    { word: 'crock', word_class: 'V' },
    { word: 'crocked', word_class: 'AJ' }
  ],
  conveniences: [
    { word: 'convenience', word_class: 'N' },
    { word: 'convenience', word_class: 'V' },
    { word: 'convenient', word_class: 'AJ' },
    { word: 'conveniences', word_class: 'N' },
    { word: 'conveniently', word_class: 'AV' }
  ],
  cuddly: [
    { word: 'cuddle', word_class: 'N' },
    { word: 'cuddle', word_class: 'V' },
    { word: 'cuddly', word_class: 'AJ' },
    { word: 'cuddling', word_class: 'N' }
  ],
  pillage: [
    { word: 'pillage', word_class: 'N' },
    { word: 'pillage', word_class: 'V' },
    { word: 'pillager', word_class: 'N' },
    { word: 'pillaged', word_class: 'AJ' },
    { word: 'pillaging', word_class: 'N' }
  ],
  typify: [
    { word: 'type', word_class: 'N' },
    { word: 'type', word_class: 'V' },
    { word: 'typed', word_class: 'AJ' },
    { word: 'typic', word_class: 'AJ' },
    { word: 'typify', word_class: 'V' },
    { word: 'typing', word_class: 'N' },
    { word: 'typist', word_class: 'N' },
    { word: 'typing', word_class: 'AJ' },
    { word: 'typical', word_class: 'AJ' },
    { word: 'typifying', word_class: 'N' },
    { word: 'typicality', word_class: 'N' },
    { word: 'typically', word_class: 'AV' },
    { word: 'typification', word_class: 'N' }
  ],
  expressible: [
    { word: 'express', word_class: 'N' },
    { word: 'express', word_class: 'V' },
    { word: 'express', word_class: 'AJ' },
    { word: 'express', word_class: 'AV' },
    { word: 'expressed', word_class: 'AJ' },
    { word: 'expression', word_class: 'N' },
    { word: 'expressive', word_class: 'AJ' },
    { word: 'expressible', word_class: 'AJ' },
    { word: 'expressively', word_class: 'AV' },
    { word: 'expressiveness', word_class: 'N' }
  ],
  horse: [{ word: 'horse', word_class: 'N' }, { word: 'horse', word_class: 'V' }, { word: 'horsy', word_class: 'AJ' }],
  employed: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  recurrently: [
    { word: 'recur', word_class: 'N' },
    { word: 'recur', word_class: 'V' },
    { word: 'recurse', word_class: 'N' },
    { word: 'recurse', word_class: 'V' },
    { word: 'recursion', word_class: 'N' },
    { word: 'recurrence', word_class: 'N' },
    { word: 'recurrent', word_class: 'AJ' },
    { word: 'recursive', word_class: 'AJ' },
    { word: 'recurrently', word_class: 'AV' }
  ],
  flatter: [
    { word: 'flatter', word_class: 'V' },
    { word: 'flattery', word_class: 'N' },
    { word: 'flatterer', word_class: 'N' },
    { word: 'flattering', word_class: 'AJ' }
  ],
  bet: [{ word: 'bet', word_class: 'N' }, { word: 'bet', word_class: 'V' }, { word: 'betting', word_class: 'AJ' }],
  scowl: [
    { word: 'scowl', word_class: 'N' },
    { word: 'scowl', word_class: 'V' },
    { word: 'scowling', word_class: 'AJ' }
  ],
  enjoyably: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  propell: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  shamanism: [
    { word: 'shaman', word_class: 'N' },
    { word: 'shaman', word_class: 'AJ' },
    { word: 'shamanism', word_class: 'N' },
    { word: 'shamanize', word_class: 'V' }
  ],
  personalise: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  bast: [
    { word: 'bast', word_class: 'N' },
    { word: 'baste', word_class: 'V' },
    { word: 'baste', word_class: 'N' },
    { word: 'basting', word_class: 'N' },
    { word: 'bastion', word_class: 'N' },
    { word: 'bastioned', word_class: 'AJ' }
  ],
  battlemented: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  'cross-question': [
    { word: 'cross-question', word_class: 'N' },
    { word: 'cross-question', word_class: 'V' },
    { word: 'cross-questioning', word_class: 'N' }
  ],
  isolated: [
    { word: 'isolate', word_class: 'V' },
    { word: 'isolable', word_class: 'AJ' },
    { word: 'isolated', word_class: 'AJ' },
    { word: 'isolation', word_class: 'N' },
    { word: 'isolating', word_class: 'AJ' }
  ],
  habited: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  exude: [
    { word: 'exude', word_class: 'V' },
    { word: 'exudate', word_class: 'N' },
    { word: 'exudate', word_class: 'V' },
    { word: 'exudation', word_class: 'N' }
  ],
  right: [
    { word: 'right', word_class: 'V' },
    { word: 'right', word_class: 'N' },
    { word: 'right', word_class: 'AJ' },
    { word: 'right', word_class: 'AV' },
    { word: 'rightist', word_class: 'N' },
    { word: 'rightful', word_class: 'AJ' },
    { word: 'rightist', word_class: 'AJ' },
    { word: 'rightness', word_class: 'N' },
    { word: 'rightfulness', word_class: 'N' }
  ],
  synchronisation: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  mutation: [
    { word: 'mute', word_class: 'N' },
    { word: 'mute', word_class: 'V' },
    { word: 'mute', word_class: 'AJ' },
    { word: 'mutant', word_class: 'N' },
    { word: 'mutate', word_class: 'V' },
    { word: 'muted', word_class: 'AJ' },
    { word: 'mutant', word_class: 'AJ' },
    { word: 'mutely', word_class: 'AV' },
    { word: 'mutation', word_class: 'N' },
    { word: 'muteness', word_class: 'N' },
    { word: 'mutational', word_class: 'AJ' }
  ],
  yearn: [
    { word: 'yearn', word_class: 'V' },
    { word: 'yearning', word_class: 'N' },
    { word: 'yearning', word_class: 'AJ' }
  ],
  shell: [
    { word: 'shell', word_class: 'N' },
    { word: 'shell', word_class: 'V' },
    { word: 'shell', word_class: 'AJ' },
    { word: 'shelled', word_class: 'AJ' },
    { word: 'shelling', word_class: 'N' }
  ],
  electricity: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  insolate: [
    { word: 'insole', word_class: 'N' },
    { word: 'insolate', word_class: 'V' },
    { word: 'insolence', word_class: 'N' },
    { word: 'insolent', word_class: 'AJ' },
    { word: 'insolation', word_class: 'N' },
    { word: 'insolently', word_class: 'AV' }
  ],
  camping: [
    { word: 'camp', word_class: 'N' },
    { word: 'camp', word_class: 'V' },
    { word: 'camp', word_class: 'AJ' },
    { word: 'camper', word_class: 'N' },
    { word: 'camping', word_class: 'N' },
    { word: 'campion', word_class: 'N' }
  ],
  acidophilous: [
    { word: 'acidophil', word_class: 'N' },
    { word: 'acidophile', word_class: 'N' },
    { word: 'acidophilic', word_class: 'AJ' },
    { word: 'acidophilous', word_class: 'AJ' }
  ],
  balloonist: [
    { word: 'balloon', word_class: 'N' },
    { word: 'balloon', word_class: 'V' },
    { word: 'ballooning', word_class: 'N' },
    { word: 'balloonist', word_class: 'N' },
    { word: 'ballooning', word_class: 'AJ' }
  ],
  vocalic: [
    { word: 'vocal', word_class: 'N' },
    { word: 'vocal', word_class: 'AJ' },
    { word: 'vocalise', word_class: 'V' },
    { word: 'vocalize', word_class: 'V' },
    { word: 'vocalic', word_class: 'AJ' },
    { word: 'vocalist', word_class: 'N' },
    { word: 'vocally', word_class: 'AV' },
    { word: 'vocalizing', word_class: 'N' },
    { word: 'vocalisation', word_class: 'N' },
    { word: 'vocalization', word_class: 'N' }
  ],
  ossification: [
    { word: 'ossify', word_class: 'V' },
    { word: 'ossified', word_class: 'AJ' },
    { word: 'ossification', word_class: 'N' }
  ],
  swilling: [
    { word: 'swill', word_class: 'N' },
    { word: 'swill', word_class: 'V' },
    { word: 'swilling', word_class: 'N' }
  ],
  purpleness: [
    { word: 'purple', word_class: 'N' },
    { word: 'purple', word_class: 'V' },
    { word: 'purple', word_class: 'AJ' },
    { word: 'purpleness', word_class: 'N' }
  ],
  contemplation: [
    { word: 'contemplate', word_class: 'V' },
    { word: 'contemplation', word_class: 'N' },
    { word: 'contemplative', word_class: 'AJ' },
    { word: 'contemplativeness', word_class: 'N' }
  ],
  feasting: [
    { word: 'feast', word_class: 'N' },
    { word: 'feast', word_class: 'V' },
    { word: 'feasting', word_class: 'N' }
  ],
  detailed: [
    { word: 'detail', word_class: 'N' },
    { word: 'detail', word_class: 'V' },
    { word: 'details', word_class: 'N' },
    { word: 'detailed', word_class: 'AJ' },
    { word: 'detailing', word_class: 'N' }
  ],
  mobility: [
    { word: 'mobile', word_class: 'N' },
    { word: 'mobile', word_class: 'AJ' },
    { word: 'mobilise', word_class: 'V' },
    { word: 'mobilize', word_class: 'V' },
    { word: 'mobility', word_class: 'N' },
    { word: 'mobilisation', word_class: 'N' },
    { word: 'mobilization', word_class: 'N' }
  ],
  visualizer: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  bleach: [
    { word: 'bleach', word_class: 'N' },
    { word: 'bleach', word_class: 'V' },
    { word: 'bleached', word_class: 'AJ' }
  ],
  effeminize: [
    { word: 'effeminacy', word_class: 'N' },
    { word: 'effeminate', word_class: 'V' },
    { word: 'effeminize', word_class: 'V' },
    { word: 'effeminate', word_class: 'AJ' },
    { word: 'effeminateness', word_class: 'N' }
  ],
  incorporated: [
    { word: 'incorporate', word_class: 'V' },
    { word: 'incorporate', word_class: 'AJ' },
    { word: 'incorporeal', word_class: 'AJ' },
    { word: 'incorporable', word_class: 'AJ' },
    { word: 'incorporated', word_class: 'AJ' },
    { word: 'incorporation', word_class: 'N' },
    { word: 'incorporative', word_class: 'AJ' },
    { word: 'incorporeality', word_class: 'N' }
  ],
  perforation: [
    { word: 'perforate', word_class: 'V' },
    { word: 'perforate', word_class: 'AJ' },
    { word: 'perforated', word_class: 'AJ' },
    { word: 'perforation', word_class: 'N' }
  ],
  corroborate: [
    { word: 'corroborate', word_class: 'V' },
    { word: 'corroborant', word_class: 'AJ' },
    { word: 'corroborated', word_class: 'AJ' },
    { word: 'corroboration', word_class: 'N' },
    { word: 'corroborative', word_class: 'AJ' }
  ],
  evasion: [
    { word: 'evade', word_class: 'V' },
    { word: 'evasion', word_class: 'N' },
    { word: 'evasive', word_class: 'AJ' },
    { word: 'evasively', word_class: 'AV' },
    { word: 'evasiveness', word_class: 'N' }
  ],
  cowry: [{ word: 'cowry', word_class: 'N' }, { word: 'cowrie', word_class: 'N' }, { word: 'cowry', word_class: 'AJ' }],
  obtrusively: [
    { word: 'obtrude', word_class: 'V' },
    { word: 'obtrusion', word_class: 'N' },
    { word: 'obtrusive', word_class: 'AJ' },
    { word: 'obtrusively', word_class: 'AV' },
    { word: 'obtrusiveness', word_class: 'N' }
  ],
  trading: [
    { word: 'trade', word_class: 'N' },
    { word: 'trade', word_class: 'V' },
    { word: 'trade', word_class: 'AJ' },
    { word: 'trader', word_class: 'N' },
    { word: 'trading', word_class: 'N' },
    { word: 'traded', word_class: 'AJ' }
  ],
  outwards: [
    { word: 'outward', word_class: 'V' },
    { word: 'outward', word_class: 'AJ' },
    { word: 'outward', word_class: 'AV' },
    { word: 'outwards', word_class: 'AV' },
    { word: 'outwardness', word_class: 'N' }
  ],
  spell: [
    { word: 'spell', word_class: 'N' },
    { word: 'spell', word_class: 'V' },
    { word: 'speller', word_class: 'N' },
    { word: 'spelling', word_class: 'N' }
  ],
  agilely: [
    { word: 'agile', word_class: 'AJ' },
    { word: 'agility', word_class: 'N' },
    { word: 'agilely', word_class: 'AV' }
  ],
  parabolic: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  stylish: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  rest: [
    { word: 'rest', word_class: 'N' },
    { word: 'rest', word_class: 'V' },
    { word: 'rested', word_class: 'AJ' },
    { word: 'restful', word_class: 'AJ' },
    { word: 'restfulness', word_class: 'N' }
  ],
  sinfulness: [
    { word: 'sin', word_class: 'N' },
    { word: 'sin', word_class: 'V' },
    { word: 'sinful', word_class: 'AJ' },
    { word: 'sinning', word_class: 'N' },
    { word: 'sinning', word_class: 'AJ' },
    { word: 'sinfulness', word_class: 'N' }
  ],
  iteration: [
    { word: 'iterate', word_class: 'V' },
    { word: 'iterance', word_class: 'N' },
    { word: 'iteration', word_class: 'N' },
    { word: 'iterative', word_class: 'AJ' }
  ],
  excitableness: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  wither: [
    { word: 'wither', word_class: 'V' },
    { word: 'withers', word_class: 'N' },
    { word: 'withered', word_class: 'AJ' },
    { word: 'withering', word_class: 'N' },
    { word: 'withering', word_class: 'AJ' }
  ],
  battle: [
    { word: 'battle', word_class: 'N' },
    { word: 'battle', word_class: 'V' },
    { word: 'battler', word_class: 'N' },
    { word: 'battleful', word_class: 'AJ' },
    { word: 'battlement', word_class: 'N' },
    { word: 'battleship', word_class: 'N' },
    { word: 'battlemented', word_class: 'AJ' }
  ],
  bichrome: [
    { word: 'bichrome', word_class: 'AJ' },
    { word: 'bichromate', word_class: 'N' },
    { word: 'bichromated', word_class: 'AJ' }
  ],
  outcrop: [
    { word: 'outcrop', word_class: 'N' },
    { word: 'outcrop', word_class: 'V' },
    { word: 'outcropping', word_class: 'N' }
  ],
  peculate: [
    { word: 'peculate', word_class: 'V' },
    { word: 'peculator', word_class: 'N' },
    { word: 'peculation', word_class: 'N' }
  ],
  plasticize: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  prestigious: [
    { word: 'prestige', word_class: 'N' },
    { word: 'prestigious', word_class: 'AJ' },
    { word: 'prestigiousness', word_class: 'N' }
  ],
  impress: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  trousers: [
    { word: 'trouser', word_class: 'N' },
    { word: 'trousers', word_class: 'N' },
    { word: 'trousered', word_class: 'AJ' },
    { word: 'trousering', word_class: 'N' }
  ],
  addition: [
    { word: 'add', word_class: 'V' },
    { word: 'adder', word_class: 'N' },
    { word: 'addition', word_class: 'N' },
    { word: 'additive', word_class: 'N' },
    { word: 'additive', word_class: 'AJ' },
    { word: 'additional', word_class: 'AJ' }
  ],
  fall: [
    { word: 'fall', word_class: 'N' },
    { word: 'fall', word_class: 'V' },
    { word: 'falls', word_class: 'N' },
    { word: 'fallal', word_class: 'N' },
    { word: 'fallen', word_class: 'AJ' },
    { word: 'falling', word_class: 'N' },
    { word: 'falling', word_class: 'AJ' }
  ],
  scramble: [
    { word: 'scramble', word_class: 'N' },
    { word: 'scramble', word_class: 'V' },
    { word: 'scrambler', word_class: 'N' },
    { word: 'scrambled', word_class: 'AJ' }
  ],
  tame: [
    { word: 'tame', word_class: 'V' },
    { word: 'tame', word_class: 'AJ' },
    { word: 'tamer', word_class: 'N' },
    { word: 'tamed', word_class: 'AJ' },
    { word: 'tamely', word_class: 'AV' },
    { word: 'tameness', word_class: 'N' }
  ],
  amassment: [
    { word: 'amass', word_class: 'V' },
    { word: 'amassed', word_class: 'AJ' },
    { word: 'amassment', word_class: 'N' }
  ],
  journalism: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  vesiculate: [
    { word: 'vesiculate', word_class: 'V' },
    { word: 'vesiculitis', word_class: 'N' },
    { word: 'vesiculation', word_class: 'N' }
  ],
  mythologisation: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  rails: [
    { word: 'rail', word_class: 'N' },
    { word: 'rail', word_class: 'V' },
    { word: 'rails', word_class: 'N' },
    { word: 'railing', word_class: 'N' }
  ],
  qualifying: [
    { word: 'qualify', word_class: 'V' },
    { word: 'qualifier', word_class: 'N' },
    { word: 'qualified', word_class: 'AJ' },
    { word: 'qualifying', word_class: 'N' },
    { word: 'qualifying', word_class: 'AJ' },
    { word: 'qualification', word_class: 'N' }
  ],
  dehumanise: [
    { word: 'dehumanise', word_class: 'V' },
    { word: 'dehumanize', word_class: 'V' },
    { word: 'dehumanized', word_class: 'AJ' },
    { word: 'dehumanisation', word_class: 'N' },
    { word: 'dehumanization', word_class: 'N' }
  ],
  momentary: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  popularity: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  worrier: [
    { word: 'worry', word_class: 'N' },
    { word: 'worry', word_class: 'V' },
    { word: 'worrier', word_class: 'N' },
    { word: 'worried', word_class: 'AJ' },
    { word: 'worrying', word_class: 'N' },
    { word: 'worriment', word_class: 'N' },
    { word: 'worrying', word_class: 'AJ' },
    { word: 'worrisome', word_class: 'AJ' }
  ],
  flamboyancy: [
    { word: 'flamboyant', word_class: 'N' },
    { word: 'flamboyance', word_class: 'N' },
    { word: 'flamboyancy', word_class: 'N' },
    { word: 'flamboyant', word_class: 'AJ' }
  ],
  administer: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  consolable: [
    { word: 'console', word_class: 'N' },
    { word: 'console', word_class: 'V' },
    { word: 'consoling', word_class: 'AJ' },
    { word: 'consolable', word_class: 'AJ' },
    { word: 'consolation', word_class: 'N' },
    { word: 'consolement', word_class: 'N' }
  ],
  garden: [
    { word: 'garden', word_class: 'N' },
    { word: 'garden', word_class: 'V' },
    { word: 'gardener', word_class: 'N' },
    { word: 'gardening', word_class: 'N' }
  ],
  marker: [
    { word: 'mark', word_class: 'N' },
    { word: 'mark', word_class: 'V' },
    { word: 'marker', word_class: 'N' },
    { word: 'marked', word_class: 'AJ' },
    { word: 'marking', word_class: 'N' }
  ],
  oppressor: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  bin: [{ word: 'bin', word_class: 'N' }, { word: 'bin', word_class: 'V' }, { word: 'binful', word_class: 'N' }],
  feminineness: [
    { word: 'feminism', word_class: 'N' },
    { word: 'feminist', word_class: 'N' },
    { word: 'feminine', word_class: 'N' },
    { word: 'feminize', word_class: 'V' },
    { word: 'feminise', word_class: 'V' },
    { word: 'feminine', word_class: 'AJ' },
    { word: 'feminist', word_class: 'AJ' },
    { word: 'femininity', word_class: 'N' },
    { word: 'feminisation', word_class: 'N' },
    { word: 'feminineness', word_class: 'N' },
    { word: 'feminization', word_class: 'N' }
  ],
  disrepute: [
    { word: 'disrepute', word_class: 'N' },
    { word: 'disreputable', word_class: 'AJ' },
    { word: 'disreputably', word_class: 'AV' },
    { word: 'disreputability', word_class: 'N' },
    { word: 'disreputableness', word_class: 'N' }
  ],
  guaranteed: [
    { word: 'guarantee', word_class: 'N' },
    { word: 'guarantee', word_class: 'V' },
    { word: 'guaranteed', word_class: 'AJ' }
  ],
  speeding: [
    { word: 'speed', word_class: 'N' },
    { word: 'speed', word_class: 'V' },
    { word: 'speeder', word_class: 'N' },
    { word: 'speeding', word_class: 'N' },
    { word: 'speeding', word_class: 'AJ' }
  ],
  ares: [{ word: 'ar', word_class: 'N' }, { word: 'are', word_class: 'N' }, { word: 'ares', word_class: 'N' }],
  huskiness: [
    { word: 'husky', word_class: 'N' },
    { word: 'husky', word_class: 'AJ' },
    { word: 'huskiness', word_class: 'N' }
  ],
  protraction: [
    { word: 'protract', word_class: 'N' },
    { word: 'protract', word_class: 'V' },
    { word: 'protractor', word_class: 'N' },
    { word: 'protracted', word_class: 'AJ' },
    { word: 'protraction', word_class: 'N' },
    { word: 'protractible', word_class: 'AJ' }
  ],
  ballooning: [
    { word: 'balloon', word_class: 'N' },
    { word: 'balloon', word_class: 'V' },
    { word: 'ballooning', word_class: 'N' },
    { word: 'balloonist', word_class: 'N' },
    { word: 'ballooning', word_class: 'AJ' }
  ],
  scribe: [
    { word: 'scribe', word_class: 'N' },
    { word: 'scribe', word_class: 'V' },
    { word: 'scriber', word_class: 'N' }
  ],
  chomping: [
    { word: 'chomp', word_class: 'N' },
    { word: 'chomp', word_class: 'V' },
    { word: 'chomping', word_class: 'N' }
  ],
  begun: [
    { word: 'begin', word_class: 'V' },
    { word: 'begun', word_class: 'AJ' },
    { word: 'beginning', word_class: 'N' },
    { word: 'beginning', word_class: 'AJ' }
  ],
  unfastened: [
    { word: 'unfasten', word_class: 'V' },
    { word: 'unfastened', word_class: 'AJ' },
    { word: 'unfastening', word_class: 'N' }
  ],
  wholesale: [
    { word: 'wholesale', word_class: 'N' },
    { word: 'wholesale', word_class: 'V' },
    { word: 'wholesale', word_class: 'AJ' },
    { word: 'wholesale', word_class: 'AV' },
    { word: 'wholesaler', word_class: 'N' }
  ],
  interceptor: [
    { word: 'intercept', word_class: 'N' },
    { word: 'intercept', word_class: 'V' },
    { word: 'interceptor', word_class: 'N' },
    { word: 'interception', word_class: 'N' }
  ],
  careen: [
    { word: 'careen', word_class: 'N' },
    { word: 'careen', word_class: 'V' },
    { word: 'careenage', word_class: 'N' }
  ],
  pocket: [
    { word: 'pocket', word_class: 'N' },
    { word: 'pocket', word_class: 'V' },
    { word: 'pocketful', word_class: 'N' },
    { word: 'pocketful', word_class: 'AJ' }
  ],
  elopement: [
    { word: 'elope', word_class: 'V' },
    { word: 'elops', word_class: 'N' },
    { word: 'elopement', word_class: 'N' }
  ],
  homogenize: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  probability: [
    { word: 'probable', word_class: 'N' },
    { word: 'probable', word_class: 'AJ' },
    { word: 'probably', word_class: 'AV' },
    { word: 'probability', word_class: 'N' }
  ],
  allot: [
    { word: 'allot', word_class: 'V' },
    { word: 'allotment', word_class: 'N' },
    { word: 'allotted', word_class: 'AJ' }
  ],
  styled: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  evasive: [
    { word: 'evade', word_class: 'V' },
    { word: 'evasion', word_class: 'N' },
    { word: 'evasive', word_class: 'AJ' },
    { word: 'evasively', word_class: 'AV' },
    { word: 'evasiveness', word_class: 'N' }
  ],
  steering: [
    { word: 'steer', word_class: 'N' },
    { word: 'steer', word_class: 'V' },
    { word: 'steering', word_class: 'N' }
  ],
  companionate: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  industrialization: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  horsewhip: [
    { word: 'horsewhip', word_class: 'N' },
    { word: 'horsewhip', word_class: 'V' },
    { word: 'horsewhipping', word_class: 'N' }
  ],
  authentic: [
    { word: 'authentic', word_class: 'AJ' },
    { word: 'authenticate', word_class: 'V' },
    { word: 'authenticity', word_class: 'N' },
    { word: 'authentically', word_class: 'AV' },
    { word: 'authenticated', word_class: 'AJ' },
    { word: 'authentication', word_class: 'N' }
  ],
  seasonableness: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  voluptuous: [
    { word: 'voluptuous', word_class: 'AJ' },
    { word: 'voluptuously', word_class: 'AV' },
    { word: 'voluptuousness', word_class: 'N' }
  ],
  heterosexual: [
    { word: 'heterosexual', word_class: 'N' },
    { word: 'heterosexual', word_class: 'AJ' },
    { word: 'heterosexualism', word_class: 'N' },
    { word: 'heterosexuality', word_class: 'N' }
  ],
  cheat: [
    { word: 'cheat', word_class: 'N' },
    { word: 'cheat', word_class: 'V' },
    { word: 'cheater', word_class: 'N' },
    { word: 'cheating', word_class: 'N' },
    { word: 'cheating', word_class: 'AJ' }
  ],
  democratization: [
    { word: 'democrat', word_class: 'N' },
    { word: 'democratise', word_class: 'V' },
    { word: 'democratize', word_class: 'V' },
    { word: 'democratic', word_class: 'AJ' },
    { word: 'democratically', word_class: 'AV' },
    { word: 'democratisation', word_class: 'N' },
    { word: 'democratization', word_class: 'N' }
  ],
  snatch: [
    { word: 'snatch', word_class: 'N' },
    { word: 'snatch', word_class: 'V' },
    { word: 'snatcher', word_class: 'N' }
  ],
  shamble: [
    { word: 'shamble', word_class: 'N' },
    { word: 'shamble', word_class: 'V' },
    { word: 'shambles', word_class: 'N' },
    { word: 'shambling', word_class: 'N' }
  ],
  pruned: [
    { word: 'prune', word_class: 'N' },
    { word: 'prune', word_class: 'V' },
    { word: 'pruner', word_class: 'N' },
    { word: 'pruned', word_class: 'AJ' },
    { word: 'pruning', word_class: 'N' }
  ],
  million: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  discover: [
    { word: 'discover', word_class: 'N' },
    { word: 'discover', word_class: 'V' },
    { word: 'discovery', word_class: 'N' },
    { word: 'discoverer', word_class: 'N' },
    { word: 'discovery', word_class: 'AJ' },
    { word: 'discovered', word_class: 'AJ' },
    { word: 'discoverable', word_class: 'AJ' }
  ],
  macadam: [
    { word: 'macadam', word_class: 'N' },
    { word: 'macadam', word_class: 'AJ' },
    { word: 'macadamise', word_class: 'V' },
    { word: 'macadamize', word_class: 'V' },
    { word: 'macadamized', word_class: 'AJ' },
    { word: 'macadamisation', word_class: 'N' },
    { word: 'macadamization', word_class: 'N' }
  ],
  implicant: [
    { word: 'imply', word_class: 'V' },
    { word: 'implied', word_class: 'AJ' },
    { word: 'implicant', word_class: 'N' },
    { word: 'implicate', word_class: 'V' },
    { word: 'implicated', word_class: 'AJ' },
    { word: 'implicating', word_class: 'N' },
    { word: 'implication', word_class: 'N' },
    { word: 'implicative', word_class: 'AJ' },
    { word: 'implicational', word_class: 'AJ' }
  ],
  deflate: [
    { word: 'deflate', word_class: 'V' },
    { word: 'deflator', word_class: 'N' },
    { word: 'deflated', word_class: 'AJ' },
    { word: 'deflation', word_class: 'N' },
    { word: 'deflationary', word_class: 'AJ' }
  ],
  impermissibly: [
    { word: 'impermissible', word_class: 'AJ' },
    { word: 'impermissibly', word_class: 'AV' },
    { word: 'impermissibility', word_class: 'N' }
  ],
  heating: [
    { word: 'heat', word_class: 'N' },
    { word: 'heat', word_class: 'V' },
    { word: 'heater', word_class: 'N' },
    { word: 'heated', word_class: 'AJ' },
    { word: 'heating', word_class: 'N' },
    { word: 'heating', word_class: 'AJ' }
  ],
  casuist: [
    { word: 'casuist', word_class: 'N' },
    { word: 'casuistic', word_class: 'AJ' },
    { word: 'casuistical', word_class: 'AJ' }
  ],
  submission: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  'penny-pinch': [
    { word: 'penny-pinch', word_class: 'V' },
    { word: 'penny-pinching', word_class: 'N' },
    { word: 'penny-pinching', word_class: 'AJ' }
  ],
  erosible: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  wiper: [{ word: 'wipe', word_class: 'N' }, { word: 'wipe', word_class: 'V' }, { word: 'wiper', word_class: 'N' }],
  colonizer: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  researcher: [
    { word: 'research', word_class: 'N' },
    { word: 'research', word_class: 'V' },
    { word: 'researcher', word_class: 'N' },
    { word: 'researchable', word_class: 'AJ' }
  ],
  sophistication: [
    { word: 'sophist', word_class: 'N' },
    { word: 'sophistry', word_class: 'N' },
    { word: 'sophistic', word_class: 'AJ' },
    { word: 'sophisticate', word_class: 'V' },
    { word: 'sophisticate', word_class: 'N' },
    { word: 'sophistical', word_class: 'AJ' },
    { word: 'sophisticated', word_class: 'AJ' },
    { word: 'sophistication', word_class: 'N' }
  ],
  appal: [
    { word: 'appal', word_class: 'V' },
    { word: 'appall', word_class: 'V' },
    { word: 'appalled', word_class: 'AJ' },
    { word: 'appalling', word_class: 'N' },
    { word: 'appalling', word_class: 'AJ' }
  ],
  declamatory: [
    { word: 'declaim', word_class: 'N' },
    { word: 'declaim', word_class: 'V' },
    { word: 'declamation', word_class: 'N' },
    { word: 'declamatory', word_class: 'AJ' }
  ],
  animalise: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  hatching: [
    { word: 'hatch', word_class: 'N' },
    { word: 'hatch', word_class: 'V' },
    { word: 'hatched', word_class: 'AJ' },
    { word: 'hatching', word_class: 'N' },
    { word: 'hatching', word_class: 'AJ' }
  ],
  super: [
    { word: 'sup', word_class: 'N' },
    { word: 'sup', word_class: 'V' },
    { word: 'super', word_class: 'N' },
    { word: 'super', word_class: 'AJ' },
    { word: 'super', word_class: 'AV' },
    { word: 'supping', word_class: 'N' },
    { word: 'superable', word_class: 'AJ' }
  ],
  conceivable: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  ventilated: [
    { word: 'ventilate', word_class: 'V' },
    { word: 'ventilator', word_class: 'N' },
    { word: 'ventilated', word_class: 'AJ' },
    { word: 'ventilation', word_class: 'N' }
  ],
  regularity: [
    { word: 'regular', word_class: 'N' },
    { word: 'regular', word_class: 'AJ' },
    { word: 'regularise', word_class: 'V' },
    { word: 'regularize', word_class: 'V' },
    { word: 'regularity', word_class: 'N' },
    { word: 'regularized', word_class: 'AJ' },
    { word: 'regularisation', word_class: 'N' },
    { word: 'regularization', word_class: 'N' }
  ],
  frugality: [
    { word: 'frugal', word_class: 'AJ' },
    { word: 'frugality', word_class: 'N' },
    { word: 'frugally', word_class: 'AV' },
    { word: 'frugalness', word_class: 'N' }
  ],
  inculpation: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  generator: [
    { word: 'generate', word_class: 'V' },
    { word: 'generator', word_class: 'N' },
    { word: 'generation', word_class: 'N' },
    { word: 'generative', word_class: 'AJ' }
  ],
  attitudinize: [
    { word: 'attitude', word_class: 'N' },
    { word: 'attitudinal', word_class: 'AJ' },
    { word: 'attitudinize', word_class: 'V' },
    { word: 'attitudinization', word_class: 'N' }
  ],
  profanely: [
    { word: 'profane', word_class: 'V' },
    { word: 'profane', word_class: 'AJ' },
    { word: 'profaned', word_class: 'AJ' },
    { word: 'profanity', word_class: 'N' },
    { word: 'profanely', word_class: 'AV' },
    { word: 'profanation', word_class: 'N' },
    { word: 'profaneness', word_class: 'N' }
  ],
  gratefulness: [
    { word: 'grate', word_class: 'N' },
    { word: 'grate', word_class: 'V' },
    { word: 'grater', word_class: 'N' },
    { word: 'grating', word_class: 'N' },
    { word: 'grating', word_class: 'AJ' },
    { word: 'grateful', word_class: 'AJ' },
    { word: 'gratefulness', word_class: 'N' }
  ],
  leaved: [
    { word: 'leave', word_class: 'N' },
    { word: 'leave', word_class: 'V' },
    { word: 'leaver', word_class: 'N' },
    { word: 'leaved', word_class: 'AJ' },
    { word: 'leaving', word_class: 'N' }
  ],
  caged: [
    { word: 'cage', word_class: 'N' },
    { word: 'cage', word_class: 'V' },
    { word: 'cager', word_class: 'N' },
    { word: 'caged', word_class: 'AJ' }
  ],
  tyrannous: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  repositioning: [
    { word: 'reposit', word_class: 'V' },
    { word: 'repositing', word_class: 'N' },
    { word: 'reposition', word_class: 'N' },
    { word: 'reposition', word_class: 'V' },
    { word: 'repositioning', word_class: 'N' }
  ],
  mill: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  desalinize: [
    { word: 'desalinate', word_class: 'V' },
    { word: 'desalinize', word_class: 'V' },
    { word: 'desalinated', word_class: 'AJ' },
    { word: 'desalination', word_class: 'N' },
    { word: 'desalinization', word_class: 'N' }
  ],
  'place-kick': [
    { word: 'place-kick', word_class: 'V' },
    { word: 'place-kicker', word_class: 'N' },
    { word: 'place-kicking', word_class: 'N' }
  ],
  aetiological: [
    { word: 'aetiology', word_class: 'N' },
    { word: 'aetiologic', word_class: 'AJ' },
    { word: 'aetiological', word_class: 'AJ' }
  ],
  methylated: [
    { word: 'methyl', word_class: 'N' },
    { word: 'methylate', word_class: 'V' },
    { word: 'methylated', word_class: 'AJ' },
    { word: 'methylation', word_class: 'N' }
  ],
  foliation: [
    { word: 'foliate', word_class: 'V' },
    { word: 'foliate', word_class: 'AJ' },
    { word: 'foliated', word_class: 'AJ' },
    { word: 'foliation', word_class: 'N' }
  ],
  roamer: [{ word: 'roam', word_class: 'N' }, { word: 'roam', word_class: 'V' }, { word: 'roamer', word_class: 'N' }],
  percussive: [
    { word: 'percuss', word_class: 'V' },
    { word: 'percussion', word_class: 'N' },
    { word: 'percussive', word_class: 'AJ' }
  ],
  poignance: [
    { word: 'poignance', word_class: 'N' },
    { word: 'poignancy', word_class: 'N' },
    { word: 'poignant', word_class: 'AJ' }
  ],
  yank: [
    { word: 'yank', word_class: 'N' },
    { word: 'yank', word_class: 'V' },
    { word: 'yankee', word_class: 'N' },
    { word: 'yankee', word_class: 'AJ' }
  ],
  outside: [
    { word: 'outside', word_class: 'N' },
    { word: 'outside', word_class: 'AJ' },
    { word: 'outside', word_class: 'AV' },
    { word: 'outsider', word_class: 'N' }
  ],
  iron: [
    { word: 'iron', word_class: 'N' },
    { word: 'iron', word_class: 'V' },
    { word: 'iron', word_class: 'AJ' },
    { word: 'irons', word_class: 'N' },
    { word: 'irony', word_class: 'N' },
    { word: 'irony', word_class: 'AJ' },
    { word: 'ironed', word_class: 'AJ' },
    { word: 'ironic', word_class: 'AJ' },
    { word: 'ironing', word_class: 'N' },
    { word: 'ironist', word_class: 'N' },
    { word: 'ironical', word_class: 'AJ' },
    { word: 'ironically', word_class: 'AV' }
  ],
  deafened: [
    { word: 'deafen', word_class: 'V' },
    { word: 'deafened', word_class: 'AJ' },
    { word: 'deafening', word_class: 'AJ' }
  ],
  beleaguerment: [
    { word: 'beleaguer', word_class: 'V' },
    { word: 'beleaguering', word_class: 'N' },
    { word: 'beleaguerment', word_class: 'N' }
  ],
  jailing: [
    { word: 'jail', word_class: 'N' },
    { word: 'jail', word_class: 'V' },
    { word: 'jailer', word_class: 'N' },
    { word: 'jailed', word_class: 'AJ' },
    { word: 'jailing', word_class: 'N' }
  ],
  moulting: [
    { word: 'mould', word_class: 'N' },
    { word: 'mould', word_class: 'V' },
    { word: 'moult', word_class: 'N' },
    { word: 'moult', word_class: 'V' },
    { word: 'moulding', word_class: 'N' },
    { word: 'moulting', word_class: 'N' }
  ],
  simultaneity: [
    { word: 'simultaneity', word_class: 'N' },
    { word: 'simultaneous', word_class: 'AJ' },
    { word: 'simultaneously', word_class: 'AV' },
    { word: 'simultaneousness', word_class: 'N' }
  ],
  pensive: [
    { word: 'pensive', word_class: 'AJ' },
    { word: 'pensively', word_class: 'AV' },
    { word: 'pensiveness', word_class: 'N' }
  ],
  germinal: [
    { word: 'germ', word_class: 'N' },
    { word: 'germinal', word_class: 'N' },
    { word: 'germinal', word_class: 'AJ' },
    { word: 'germinate', word_class: 'V' },
    { word: 'germination', word_class: 'N' }
  ],
  squatness: [
    { word: 'squat', word_class: 'N' },
    { word: 'squat', word_class: 'V' },
    { word: 'squat', word_class: 'AJ' },
    { word: 'squatness', word_class: 'N' },
    { word: 'squatting', word_class: 'N' }
  ],
  need: [
    { word: 'need', word_class: 'N' },
    { word: 'need', word_class: 'V' },
    { word: 'needs', word_class: 'AV' },
    { word: 'needed', word_class: 'AJ' },
    { word: 'needful', word_class: 'AJ' }
  ],
  parade: [
    { word: 'parade', word_class: 'N' },
    { word: 'parade', word_class: 'V' },
    { word: 'parader', word_class: 'N' },
    { word: 'paradize', word_class: 'V' }
  ],
  paramagnetic: [
    { word: 'paramagnet', word_class: 'N' },
    { word: 'paramagnetic', word_class: 'AJ' },
    { word: 'paramagnetism', word_class: 'N' }
  ],
  carbon: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  platinise: [
    { word: 'platinise', word_class: 'V' },
    { word: 'platinize', word_class: 'V' },
    { word: 'platinisation', word_class: 'N' },
    { word: 'platinization', word_class: 'N' }
  ],
  doubting: [
    { word: 'doubt', word_class: 'N' },
    { word: 'doubt', word_class: 'V' },
    { word: 'doubter', word_class: 'N' },
    { word: 'doubtful', word_class: 'AJ' },
    { word: 'doubting', word_class: 'AJ' },
    { word: 'doubtfulness', word_class: 'N' }
  ],
  cruciality: [
    { word: 'crucial', word_class: 'AJ' },
    { word: 'cruciality', word_class: 'N' },
    { word: 'crucially', word_class: 'AV' }
  ],
  worthiness: [
    { word: 'worthy', word_class: 'N' },
    { word: 'worthy', word_class: 'AJ' },
    { word: 'worthiness', word_class: 'N' }
  ],
  lunch: [
    { word: 'lunch', word_class: 'N' },
    { word: 'lunch', word_class: 'V' },
    { word: 'luncher', word_class: 'N' },
    { word: 'lunching', word_class: 'N' }
  ],
  separately: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  reminiscence: [
    { word: 'reminisce', word_class: 'V' },
    { word: 'reminiscence', word_class: 'N' },
    { word: 'reminiscent', word_class: 'AJ' },
    { word: 'reminiscently', word_class: 'AV' }
  ],
  passeres: [
    { word: 'pass', word_class: 'N' },
    { word: 'pass', word_class: 'V' },
    { word: 'pass', word_class: 'AJ' },
    { word: 'passe', word_class: 'AJ' },
    { word: 'passer', word_class: 'N' },
    { word: 'passion', word_class: 'N' },
    { word: 'passing', word_class: 'N' },
    { word: 'passing', word_class: 'AJ' },
    { word: 'passeres', word_class: 'N' },
    { word: 'passing', word_class: 'AV' },
    { word: 'passionate', word_class: 'AJ' },
    { word: 'passionately', word_class: 'AV' },
    { word: 'passionateness', word_class: 'N' }
  ],
  plentiful: [
    { word: 'plenty', word_class: 'N' },
    { word: 'plenty', word_class: 'AJ' },
    { word: 'plenty', word_class: 'AV' },
    { word: 'plenteous', word_class: 'AJ' },
    { word: 'plentiful', word_class: 'AJ' },
    { word: 'plentifulness', word_class: 'N' }
  ],
  rescued: [
    { word: 'rescue', word_class: 'N' },
    { word: 'rescue', word_class: 'V' },
    { word: 'rescuer', word_class: 'N' },
    { word: 'rescued', word_class: 'AJ' }
  ],
  fastidious: [
    { word: 'fastidious', word_class: 'AJ' },
    { word: 'fastidiously', word_class: 'AV' },
    { word: 'fastidiousness', word_class: 'N' }
  ],
  disposure: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  advocacy: [
    { word: 'advocacy', word_class: 'N' },
    { word: 'advocate', word_class: 'N' },
    { word: 'advocate', word_class: 'V' },
    { word: 'advocation', word_class: 'N' }
  ],
  synonymous: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  adoptive: [
    { word: 'adopt', word_class: 'V' },
    { word: 'adopted', word_class: 'AJ' },
    { word: 'adoption', word_class: 'N' },
    { word: 'adoptive', word_class: 'AJ' },
    { word: 'adoptable', word_class: 'AJ' }
  ],
  lower: [
    { word: 'lower', word_class: 'N' },
    { word: 'lower', word_class: 'V' },
    { word: 'lower', word_class: 'AJ' },
    { word: 'lowered', word_class: 'AJ' },
    { word: 'lowering', word_class: 'N' },
    { word: 'lowering', word_class: 'AJ' }
  ],
  maintenance: [
    { word: 'maintain', word_class: 'V' },
    { word: 'maintained', word_class: 'AJ' },
    { word: 'maintenance', word_class: 'N' },
    { word: 'maintainable', word_class: 'AJ' }
  ],
  foray: [{ word: 'foray', word_class: 'N' }, { word: 'foray', word_class: 'V' }, { word: 'foray', word_class: 'AV' }],
  roll: [
    { word: 'roll', word_class: 'N' },
    { word: 'roll', word_class: 'V' },
    { word: 'roller', word_class: 'N' },
    { word: 'rolled', word_class: 'AJ' },
    { word: 'rolling', word_class: 'N' },
    { word: 'rolling', word_class: 'AJ' }
  ],
  imbruembruement: [
    { word: 'imbrue', word_class: 'V' },
    { word: 'imbruement', word_class: 'N' },
    { word: 'imbruembrue', word_class: 'V' },
    { word: 'imbruembruement', word_class: 'N' }
  ],
  pustulate: [
    { word: 'pustule', word_class: 'N' },
    { word: 'pustulate', word_class: 'V' },
    { word: 'pustulate', word_class: 'AJ' },
    { word: 'pustulation', word_class: 'N' }
  ],
  apparel: [
    { word: 'apparel', word_class: 'N' },
    { word: 'apparel', word_class: 'V' },
    { word: 'appareled', word_class: 'AJ' }
  ],
  arid: [
    { word: 'arid', word_class: 'AJ' },
    { word: 'aridity', word_class: 'N' },
    { word: 'aridness', word_class: 'N' }
  ],
  atomiser: [
    { word: 'atom', word_class: 'N' },
    { word: 'atomic', word_class: 'AJ' },
    { word: 'atomise', word_class: 'V' },
    { word: 'atomize', word_class: 'V' },
    { word: 'atomizer', word_class: 'N' },
    { word: 'atomiser', word_class: 'N' },
    { word: 'atomisation', word_class: 'N' },
    { word: 'atomization', word_class: 'N' }
  ],
  landscape: [
    { word: 'landscape', word_class: 'N' },
    { word: 'landscape', word_class: 'V' },
    { word: 'landscaped', word_class: 'AJ' },
    { word: 'landscaping', word_class: 'N' },
    { word: 'landscapist', word_class: 'N' }
  ],
  conjunct: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  puddly: [
    { word: 'puddle', word_class: 'N' },
    { word: 'puddle', word_class: 'V' },
    { word: 'puddler', word_class: 'N' },
    { word: 'puddly', word_class: 'AJ' }
  ],
  chlorophyllous: [
    { word: 'chlorophyl', word_class: 'N' },
    { word: 'chlorophyll', word_class: 'N' },
    { word: 'chlorophyllous', word_class: 'AJ' }
  ],
  blazon: [
    { word: 'blazon', word_class: 'N' },
    { word: 'blazon', word_class: 'V' },
    { word: 'blazoning', word_class: 'N' },
    { word: 'blazonment', word_class: 'N' }
  ],
  catechist: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  sparring: [
    { word: 'spar', word_class: 'N' },
    { word: 'spar', word_class: 'V' },
    { word: 'sparring', word_class: 'N' }
  ],
  formularizing: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  surmisable: [
    { word: 'surmise', word_class: 'N' },
    { word: 'surmise', word_class: 'V' },
    { word: 'surmisable', word_class: 'AJ' }
  ],
  rib: [
    { word: 'rib', word_class: 'N' },
    { word: 'rib', word_class: 'V' },
    { word: 'ribbed', word_class: 'AJ' },
    { word: 'ribbing', word_class: 'N' }
  ],
  atrophied: [
    { word: 'atrophy', word_class: 'N' },
    { word: 'atrophy', word_class: 'V' },
    { word: 'atrophic', word_class: 'AJ' },
    { word: 'atrophied', word_class: 'AJ' }
  ],
  shaping: [
    { word: 'shape', word_class: 'N' },
    { word: 'shape', word_class: 'V' },
    { word: 'shaper', word_class: 'N' },
    { word: 'shaped', word_class: 'AJ' },
    { word: 'shaping', word_class: 'N' },
    { word: 'shapely', word_class: 'AJ' },
    { word: 'shaping', word_class: 'AJ' }
  ],
  tinkling: [
    { word: 'tinkle', word_class: 'N' },
    { word: 'tinkle', word_class: 'V' },
    { word: 'tinkling', word_class: 'AJ' }
  ],
  dramatization: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  superfluously: [
    { word: 'superfluity', word_class: 'N' },
    { word: 'superfluous', word_class: 'AJ' },
    { word: 'superfluously', word_class: 'AV' }
  ],
  elasticize: [
    { word: 'elastic', word_class: 'N' },
    { word: 'elastic', word_class: 'AJ' },
    { word: 'elastance', word_class: 'N' },
    { word: 'elasticate', word_class: 'V' },
    { word: 'elasticise', word_class: 'V' },
    { word: 'elasticity', word_class: 'N' },
    { word: 'elasticize', word_class: 'V' },
    { word: 'elastication', word_class: 'N' },
    { word: 'elasticized', word_class: 'AJ' }
  ],
  thresher: [
    { word: 'thresh', word_class: 'V' },
    { word: 'thresher', word_class: 'N' },
    { word: 'threshing', word_class: 'N' }
  ],
  shame: [
    { word: 'shame', word_class: 'N' },
    { word: 'shame', word_class: 'V' },
    { word: 'shamed', word_class: 'AJ' },
    { word: 'shameful', word_class: 'AJ' },
    { word: 'shamefulness', word_class: 'N' }
  ],
  subtilization: [
    { word: 'subtilise', word_class: 'V' },
    { word: 'subtilize', word_class: 'V' },
    { word: 'subtilisation', word_class: 'N' },
    { word: 'subtilization', word_class: 'N' }
  ],
  cuspidation: [
    { word: 'cuspid', word_class: 'N' },
    { word: 'cuspidal', word_class: 'AJ' },
    { word: 'cuspidate', word_class: 'AJ' },
    { word: 'cuspidated', word_class: 'AJ' },
    { word: 'cuspidation', word_class: 'N' }
  ],
  preponderance: [
    { word: 'preponderant', word_class: 'N' },
    { word: 'preponderate', word_class: 'V' },
    { word: 'preponderance', word_class: 'N' },
    { word: 'preponderant', word_class: 'AJ' },
    { word: 'preponderation', word_class: 'N' },
    { word: 'preponderating', word_class: 'AJ' }
  ],
  sentimentalization: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  pontification: [
    { word: 'pontific', word_class: 'AJ' },
    { word: 'pontifical', word_class: 'AJ' },
    { word: 'pontificate', word_class: 'N' },
    { word: 'pontificate', word_class: 'V' },
    { word: 'pontification', word_class: 'N' }
  ],
  nationalization: [
    { word: 'nation', word_class: 'N' },
    { word: 'national', word_class: 'N' },
    { word: 'national', word_class: 'AJ' },
    { word: 'nationalise', word_class: 'V' },
    { word: 'nationalist', word_class: 'N' },
    { word: 'nationalism', word_class: 'N' },
    { word: 'nationalize', word_class: 'V' },
    { word: 'nationality', word_class: 'N' },
    { word: 'nationally', word_class: 'AV' },
    { word: 'nationalist', word_class: 'AJ' },
    { word: 'nationalistic', word_class: 'AJ' },
    { word: 'nationalisation', word_class: 'N' },
    { word: 'nationalization', word_class: 'N' }
  ],
  raggedy: [
    { word: 'rag', word_class: 'N' },
    { word: 'rag', word_class: 'V' },
    { word: 'ragged', word_class: 'AJ' },
    { word: 'raggedy', word_class: 'AJ' }
  ],
  acceptance: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  anonym: [
    { word: 'anonym', word_class: 'N' },
    { word: 'anonymity', word_class: 'N' },
    { word: 'anonymous', word_class: 'AJ' },
    { word: 'anonymously', word_class: 'AV' }
  ],
  africanization: [
    { word: 'african', word_class: 'N' },
    { word: 'african', word_class: 'AJ' },
    { word: 'africanise', word_class: 'V' },
    { word: 'africanize', word_class: 'V' },
    { word: 'africanisation', word_class: 'N' },
    { word: 'africanization', word_class: 'N' }
  ],
  reviser: [
    { word: 'revise', word_class: 'N' },
    { word: 'revise', word_class: 'V' },
    { word: 'revisal', word_class: 'N' },
    { word: 'reviser', word_class: 'N' },
    { word: 'revised', word_class: 'AJ' },
    { word: 'revising', word_class: 'N' },
    { word: 'revision', word_class: 'N' }
  ],
  pervasion: [
    { word: 'pervade', word_class: 'V' },
    { word: 'pervasion', word_class: 'N' },
    { word: 'pervading', word_class: 'AJ' },
    { word: 'pervasive', word_class: 'AJ' },
    { word: 'pervasively', word_class: 'AV' },
    { word: 'pervasiveness', word_class: 'N' }
  ],
  reversibility: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  exalted: [
    { word: 'exalt', word_class: 'V' },
    { word: 'exalted', word_class: 'AJ' },
    { word: 'exalting', word_class: 'AJ' },
    { word: 'exaltation', word_class: 'N' }
  ],
  annoyance: [
    { word: 'annoy', word_class: 'V' },
    { word: 'annoyer', word_class: 'N' },
    { word: 'annoyed', word_class: 'AJ' },
    { word: 'annoying', word_class: 'N' },
    { word: 'annoyance', word_class: 'N' },
    { word: 'annoying', word_class: 'AJ' }
  ],
  grafting: [
    { word: 'graft', word_class: 'N' },
    { word: 'graft', word_class: 'V' },
    { word: 'grafting', word_class: 'N' }
  ],
  filamentary: [
    { word: 'filament', word_class: 'N' },
    { word: 'filamentary', word_class: 'AJ' },
    { word: 'filamentous', word_class: 'AJ' }
  ],
  tangibly: [
    { word: 'tangible', word_class: 'AJ' },
    { word: 'tangibly', word_class: 'AV' },
    { word: 'tangibility', word_class: 'N' },
    { word: 'tangibleness', word_class: 'N' }
  ],
  correlate: [
    { word: 'correlate', word_class: 'N' },
    { word: 'correlate', word_class: 'V' },
    { word: 'correlate', word_class: 'AJ' },
    { word: 'correlated', word_class: 'AJ' },
    { word: 'correlation', word_class: 'N' },
    { word: 'correlative', word_class: 'N' },
    { word: 'correlative', word_class: 'AJ' },
    { word: 'correlativity', word_class: 'N' },
    { word: 'correlational', word_class: 'AJ' }
  ],
  nullify: [
    { word: 'nullify', word_class: 'V' },
    { word: 'nullified', word_class: 'AJ' },
    { word: 'nullification', word_class: 'N' }
  ],
  debauchee: [
    { word: 'debauch', word_class: 'N' },
    { word: 'debauch', word_class: 'V' },
    { word: 'debauchee', word_class: 'N' },
    { word: 'debaucher', word_class: 'N' },
    { word: 'debauched', word_class: 'AJ' },
    { word: 'debauchery', word_class: 'N' },
    { word: 'debauchery', word_class: 'AJ' }
  ],
  eclipse: [
    { word: 'eclipse', word_class: 'N' },
    { word: 'eclipse', word_class: 'V' },
    { word: 'ecliptic', word_class: 'N' },
    { word: 'ecliptic', word_class: 'AJ' }
  ],
  inconsideration: [
    { word: 'inconsiderate', word_class: 'AJ' },
    { word: 'inconsiderable', word_class: 'AJ' },
    { word: 'inconsideration', word_class: 'N' },
    { word: 'inconsiderately', word_class: 'AV' },
    { word: 'inconsiderateness', word_class: 'N' }
  ],
  monetize: [
    { word: 'monetise', word_class: 'V' },
    { word: 'monetize', word_class: 'V' },
    { word: 'monetisation', word_class: 'N' },
    { word: 'monetization', word_class: 'N' }
  ],
  handler: [
    { word: 'handle', word_class: 'N' },
    { word: 'handle', word_class: 'V' },
    { word: 'handler', word_class: 'N' },
    { word: 'handled', word_class: 'AJ' },
    { word: 'handling', word_class: 'N' }
  ],
  mineralization: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  packaged: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  jocosity: [
    { word: 'jocose', word_class: 'AJ' },
    { word: 'jocosity', word_class: 'N' },
    { word: 'jocosely', word_class: 'AV' },
    { word: 'jocoseness', word_class: 'N' }
  ],
  juxtapose: [
    { word: 'juxtapose', word_class: 'V' },
    { word: 'juxtaposed', word_class: 'AJ' },
    { word: 'juxtaposition', word_class: 'N' }
  ],
  exciseman: [
    { word: 'excide', word_class: 'V' },
    { word: 'excise', word_class: 'N' },
    { word: 'excise', word_class: 'V' },
    { word: 'excision', word_class: 'N' },
    { word: 'exciseman', word_class: 'N' }
  ],
  deplorably: [
    { word: 'deplore', word_class: 'V' },
    { word: 'deplorable', word_class: 'AJ' },
    { word: 'deplorably', word_class: 'AV' }
  ],
  illustrator: [
    { word: 'illustrate', word_class: 'V' },
    { word: 'illustrator', word_class: 'N' },
    { word: 'illustrated', word_class: 'AJ' },
    { word: 'illustration', word_class: 'N' },
    { word: 'illustrative', word_class: 'AJ' }
  ],
  polemical: [
    { word: 'polemic', word_class: 'N' },
    { word: 'polemic', word_class: 'AJ' },
    { word: 'polemize', word_class: 'V' },
    { word: 'polemical', word_class: 'AJ' },
    { word: 'polemically', word_class: 'AV' }
  ],
  predigestion: [
    { word: 'predigest', word_class: 'V' },
    { word: 'predigested', word_class: 'AJ' },
    { word: 'predigestion', word_class: 'N' }
  ],
  promulgator: [
    { word: 'promulgate', word_class: 'V' },
    { word: 'promulgator', word_class: 'N' },
    { word: 'promulgated', word_class: 'AJ' },
    { word: 'promulgation', word_class: 'N' }
  ],
  vomitive: [
    { word: 'vomit', word_class: 'N' },
    { word: 'vomit', word_class: 'V' },
    { word: 'vomiting', word_class: 'N' },
    { word: 'vomitive', word_class: 'N' },
    { word: 'vomiturition', word_class: 'N' }
  ],
  cladding: [
    { word: 'clad', word_class: 'V' },
    { word: 'clad', word_class: 'AJ' },
    { word: 'cladding', word_class: 'N' }
  ],
  nodding: [{ word: 'nod', word_class: 'N' }, { word: 'nod', word_class: 'V' }, { word: 'nodding', word_class: 'AJ' }],
  tantalize: [
    { word: 'tantalise', word_class: 'V' },
    { word: 'tantalize', word_class: 'V' },
    { word: 'tantalizer', word_class: 'N' },
    { word: 'tantalizing', word_class: 'AJ' },
    { word: 'tantalisation', word_class: 'N' },
    { word: 'tantalization', word_class: 'N' }
  ],
  jolly: [
    { word: 'jolly', word_class: 'N' },
    { word: 'jolly', word_class: 'V' },
    { word: 'jolly', word_class: 'AJ' },
    { word: 'jolly', word_class: 'AV' },
    { word: 'jollity', word_class: 'N' }
  ],
  hear: [
    { word: 'hear', word_class: 'V' },
    { word: 'hearer', word_class: 'N' },
    { word: 'hearing', word_class: 'N' },
    { word: 'hearing', word_class: 'AJ' }
  ],
  customization: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  increasing: [
    { word: 'increase', word_class: 'N' },
    { word: 'increase', word_class: 'V' },
    { word: 'increased', word_class: 'AJ' },
    { word: 'increasing', word_class: 'AJ' }
  ],
  westerner: [
    { word: 'western', word_class: 'N' },
    { word: 'western', word_class: 'AJ' },
    { word: 'westerner', word_class: 'N' },
    { word: 'westernize', word_class: 'V' },
    { word: 'westernise', word_class: 'V' },
    { word: 'westernisation', word_class: 'N' },
    { word: 'westernization', word_class: 'N' }
  ],
  overturning: [
    { word: 'overturn', word_class: 'N' },
    { word: 'overturn', word_class: 'V' },
    { word: 'overturned', word_class: 'AJ' },
    { word: 'overturning', word_class: 'N' }
  ],
  hypnotize: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  crosshatched: [
    { word: 'crosshatch', word_class: 'N' },
    { word: 'crosshatch', word_class: 'V' },
    { word: 'crosshatched', word_class: 'AJ' },
    { word: 'crosshatching', word_class: 'N' }
  ],
  wonderment: [
    { word: 'wonder', word_class: 'N' },
    { word: 'wonder', word_class: 'V' },
    { word: 'wondrous', word_class: 'AJ' },
    { word: 'wondrous', word_class: 'AV' },
    { word: 'wonderment', word_class: 'N' },
    { word: 'wondering', word_class: 'AJ' },
    { word: 'wonderful', word_class: 'AJ' },
    { word: 'wonderfulness', word_class: 'N' }
  ],
  belligerent: [
    { word: 'belligerent', word_class: 'N' },
    { word: 'belligerence', word_class: 'N' },
    { word: 'belligerency', word_class: 'N' },
    { word: 'belligerent', word_class: 'AJ' },
    { word: 'belligerently', word_class: 'AV' }
  ],
  oscillate: [
    { word: 'oscillate', word_class: 'V' },
    { word: 'oscillator', word_class: 'N' },
    { word: 'oscillation', word_class: 'N' },
    { word: 'oscillating', word_class: 'AJ' }
  ],
  prestidigitate: [
    { word: 'prestidigitate', word_class: 'V' },
    { word: 'prestidigitator', word_class: 'N' },
    { word: 'prestidigitation', word_class: 'N' }
  ],
  etiolated: [
    { word: 'etiolate', word_class: 'V' },
    { word: 'etiolate', word_class: 'AJ' },
    { word: 'etiolated', word_class: 'AJ' },
    { word: 'etiolation', word_class: 'N' }
  ],
  internee: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  adulator: [
    { word: 'adulate', word_class: 'V' },
    { word: 'adulator', word_class: 'N' },
    { word: 'adulation', word_class: 'N' }
  ],
  raiding: [
    { word: 'raid', word_class: 'N' },
    { word: 'raid', word_class: 'V' },
    { word: 'raider', word_class: 'N' },
    { word: 'raiding', word_class: 'AJ' }
  ],
  iniquitously: [
    { word: 'iniquity', word_class: 'N' },
    { word: 'iniquitous', word_class: 'AJ' },
    { word: 'iniquitously', word_class: 'AV' }
  ],
  hasten: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  telegraphically: [
    { word: 'telegraph', word_class: 'N' },
    { word: 'telegraph', word_class: 'V' },
    { word: 'telegrapher', word_class: 'N' },
    { word: 'telegraphic', word_class: 'AJ' },
    { word: 'telegraphist', word_class: 'N' },
    { word: 'telegraphically', word_class: 'AV' }
  ],
  bonding: [
    { word: 'bond', word_class: 'N' },
    { word: 'bond', word_class: 'V' },
    { word: 'bond', word_class: 'AJ' },
    { word: 'bonded', word_class: 'AJ' },
    { word: 'bonding', word_class: 'N' },
    { word: 'bondman', word_class: 'N' }
  ],
  pencil: [
    { word: 'pencil', word_class: 'N' },
    { word: 'pencil', word_class: 'V' },
    { word: 'penciled', word_class: 'AJ' },
    { word: 'pencilled', word_class: 'AJ' }
  ],
  flee: [
    { word: 'fly', word_class: 'N' },
    { word: 'fly', word_class: 'V' },
    { word: 'flee', word_class: 'V' },
    { word: 'fly', word_class: 'AJ' },
    { word: 'flier', word_class: 'N' },
    { word: 'flyer', word_class: 'N' },
    { word: 'flight', word_class: 'N' },
    { word: 'flight', word_class: 'V' },
    { word: 'flown', word_class: 'AJ' },
    { word: 'flying', word_class: 'N' },
    { word: 'flying', word_class: 'AJ' },
    { word: 'fleeing', word_class: 'AJ' },
    { word: 'flighted', word_class: 'AJ' }
  ],
  plagiarize: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  intolerant: [
    { word: 'intolerance', word_class: 'N' },
    { word: 'intolerant', word_class: 'AJ' },
    { word: 'intolerable', word_class: 'AJ' },
    { word: 'intolerably', word_class: 'AV' }
  ],
  basophil: [
    { word: 'basophil', word_class: 'N' },
    { word: 'basophile', word_class: 'N' },
    { word: 'basophilic', word_class: 'AJ' }
  ],
  plagiaristic: [
    { word: 'plagiarise', word_class: 'V' },
    { word: 'plagiarism', word_class: 'N' },
    { word: 'plagiarize', word_class: 'V' },
    { word: 'plagiarist', word_class: 'N' },
    { word: 'plagiarized', word_class: 'AJ' },
    { word: 'plagiaristic', word_class: 'AJ' },
    { word: 'plagiarisation', word_class: 'N' },
    { word: 'plagiarization', word_class: 'N' }
  ],
  contemptibly: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  coincide: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  dismast: [
    { word: 'dismast', word_class: 'N' },
    { word: 'dismast', word_class: 'V' },
    { word: 'dismastment', word_class: 'N' }
  ],
  effervesce: [
    { word: 'effervesce', word_class: 'V' },
    { word: 'effervescence', word_class: 'N' },
    { word: 'effervescent', word_class: 'AJ' },
    { word: 'effervescing', word_class: 'AJ' }
  ],
  treble: [
    { word: 'treble', word_class: 'N' },
    { word: 'treble', word_class: 'V' },
    { word: 'treble', word_class: 'AJ' },
    { word: 'trebly', word_class: 'AV' }
  ],
  flatulency: [
    { word: 'flatulence', word_class: 'N' },
    { word: 'flatulency', word_class: 'N' },
    { word: 'flatulent', word_class: 'AJ' }
  ],
  unmanned: [
    { word: 'unman', word_class: 'V' },
    { word: 'unmanful', word_class: 'AJ' },
    { word: 'unmanned', word_class: 'AJ' }
  ],
  adjective: [
    { word: 'adjective', word_class: 'N' },
    { word: 'adjective', word_class: 'AJ' },
    { word: 'adjectively', word_class: 'AV' }
  ],
  venality: [
    { word: 'venal', word_class: 'AJ' },
    { word: 'venality', word_class: 'N' },
    { word: 'venally', word_class: 'AV' }
  ],
  titivation: [
    { word: 'titivate', word_class: 'V' },
    { word: 'tittivate', word_class: 'V' },
    { word: 'titivation', word_class: 'N' },
    { word: 'tittivation', word_class: 'N' }
  ],
  renter: [
    { word: 'rend', word_class: 'V' },
    { word: 'rent', word_class: 'N' },
    { word: 'rent', word_class: 'V' },
    { word: 'rent', word_class: 'AJ' },
    { word: 'rente', word_class: 'N' },
    { word: 'rental', word_class: 'N' },
    { word: 'renter', word_class: 'N' },
    { word: 'rental', word_class: 'AJ' },
    { word: 'renting', word_class: 'N' },
    { word: 'rending', word_class: 'AJ' }
  ],
  traveller: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  costs: [
    { word: 'cost', word_class: 'N' },
    { word: 'cost', word_class: 'V' },
    { word: 'costs', word_class: 'N' },
    { word: 'costing', word_class: 'N' }
  ],
  unsettle: [
    { word: 'unsettle', word_class: 'V' },
    { word: 'unsettled', word_class: 'AJ' },
    { word: 'unsettlement', word_class: 'N' }
  ],
  desiccation: [
    { word: 'desiccant', word_class: 'N' },
    { word: 'desiccate', word_class: 'V' },
    { word: 'desiccate', word_class: 'AJ' },
    { word: 'desiccated', word_class: 'AJ' },
    { word: 'desiccation', word_class: 'N' }
  ],
  capsule: [
    { word: 'capsule', word_class: 'N' },
    { word: 'capsulate', word_class: 'V' },
    { word: 'capsulate', word_class: 'AJ' },
    { word: 'capsulated', word_class: 'AJ' },
    { word: 'capsulation', word_class: 'N' }
  ],
  dynastic: [
    { word: 'dynast', word_class: 'N' },
    { word: 'dynasty', word_class: 'N' },
    { word: 'dynasty', word_class: 'AJ' },
    { word: 'dynastic', word_class: 'AJ' }
  ],
  demagnetisation: [
    { word: 'demagnetise', word_class: 'V' },
    { word: 'demagnetize', word_class: 'V' },
    { word: 'demagnetisation', word_class: 'N' },
    { word: 'demagnetization', word_class: 'N' }
  ],
  counterfeiter: [
    { word: 'counterfeit', word_class: 'N' },
    { word: 'counterfeit', word_class: 'V' },
    { word: 'counterfeit', word_class: 'AJ' },
    { word: 'counterfeiter', word_class: 'N' }
  ],
  demagogic: [
    { word: 'demagog', word_class: 'N' },
    { word: 'demagogic', word_class: 'AJ' },
    { word: 'demagogical', word_class: 'AJ' }
  ],
  distrust: [
    { word: 'distrust', word_class: 'N' },
    { word: 'distrust', word_class: 'V' },
    { word: 'distrustful', word_class: 'AJ' },
    { word: 'distrustfulness', word_class: 'N' }
  ],
  wildcat: [
    { word: 'wildcat', word_class: 'N' },
    { word: 'wildcat', word_class: 'V' },
    { word: 'wildcat', word_class: 'AJ' },
    { word: 'wildcatting', word_class: 'N' }
  ],
  luminescence: [
    { word: 'luminesce', word_class: 'V' },
    { word: 'luminescence', word_class: 'N' },
    { word: 'luminescent', word_class: 'AJ' }
  ],
  suitability: [
    { word: 'suitable', word_class: 'AJ' },
    { word: 'suitably', word_class: 'AV' },
    { word: 'suitability', word_class: 'N' },
    { word: 'suitableness', word_class: 'N' }
  ],
  hypnotise: [
    { word: 'hypnotise', word_class: 'V' },
    { word: 'hypnotised', word_class: 'AJ' },
    { word: 'hypnotisation', word_class: 'N' }
  ],
  vengeful: [
    { word: 'venge', word_class: 'V' },
    { word: 'avenge', word_class: 'V' },
    { word: 'avenger', word_class: 'N' },
    { word: 'avenging', word_class: 'AJ' },
    { word: 'vengeance', word_class: 'N' },
    { word: 'vengeful', word_class: 'AJ' },
    { word: 'vengefulness', word_class: 'N' }
  ],
  appropriately: [
    { word: 'appropriate', word_class: 'V' },
    { word: 'appropriate', word_class: 'AJ' },
    { word: 'appropriable', word_class: 'AJ' },
    { word: 'appropriated', word_class: 'AJ' },
    { word: 'appropriation', word_class: 'N' },
    { word: 'appropriately', word_class: 'AV' },
    { word: 'appropriative', word_class: 'AJ' },
    { word: 'appropriateness', word_class: 'N' }
  ],
  interdepend: [
    { word: 'interdepend', word_class: 'V' },
    { word: 'interdependence', word_class: 'N' },
    { word: 'interdependency', word_class: 'N' },
    { word: 'interdependent', word_class: 'AJ' }
  ],
  delocalize: [
    { word: 'delocalise', word_class: 'V' },
    { word: 'delocalize', word_class: 'V' },
    { word: 'delocalisation', word_class: 'N' },
    { word: 'delocalization', word_class: 'N' }
  ],
  indignant: [
    { word: 'indignity', word_class: 'N' },
    { word: 'indignant', word_class: 'AJ' },
    { word: 'indignation', word_class: 'N' }
  ],
  sequentially: [
    { word: 'sequence', word_class: 'N' },
    { word: 'sequence', word_class: 'V' },
    { word: 'sequential', word_class: 'AJ' },
    { word: 'sequentially', word_class: 'AV' }
  ],
  trustee: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  cloture: [
    { word: 'close', word_class: 'N' },
    { word: 'close', word_class: 'V' },
    { word: 'close', word_class: 'AJ' },
    { word: 'close', word_class: 'AV' },
    { word: 'closed', word_class: 'AJ' },
    { word: 'closing', word_class: 'N' },
    { word: 'closure', word_class: 'N' },
    { word: 'closure', word_class: 'V' },
    { word: 'cloture', word_class: 'N' },
    { word: 'cloture', word_class: 'V' },
    { word: 'closely', word_class: 'AV' },
    { word: 'closing', word_class: 'AJ' },
    { word: 'closeness', word_class: 'N' }
  ],
  italianisation: [
    { word: 'italian', word_class: 'N' },
    { word: 'italian', word_class: 'AJ' },
    { word: 'italianise', word_class: 'V' },
    { word: 'italianize', word_class: 'V' },
    { word: 'italianisation', word_class: 'N' },
    { word: 'italianization', word_class: 'N' }
  ],
  abide: [
    { word: 'abide', word_class: 'V' },
    { word: 'abidance', word_class: 'N' },
    { word: 'abiding', word_class: 'AJ' }
  ],
  embezzlement: [
    { word: 'embezzle', word_class: 'V' },
    { word: 'embezzler', word_class: 'N' },
    { word: 'embezzled', word_class: 'AJ' },
    { word: 'embezzlement', word_class: 'N' }
  ],
  sanitation: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  premature: [
    { word: 'premature', word_class: 'AJ' },
    { word: 'prematurity', word_class: 'N' },
    { word: 'prematurely', word_class: 'AV' },
    { word: 'prematureness', word_class: 'N' }
  ],
  schemer: [
    { word: 'scheme', word_class: 'N' },
    { word: 'scheme', word_class: 'V' },
    { word: 'schemer', word_class: 'N' },
    { word: 'scheming', word_class: 'AJ' }
  ],
  bad: [
    { word: 'bad', word_class: 'N' },
    { word: 'bad', word_class: 'AJ' },
    { word: 'bad', word_class: 'AV' },
    { word: 'badness', word_class: 'N' }
  ],
  lolling: [
    { word: 'loll', word_class: 'N' },
    { word: 'loll', word_class: 'V' },
    { word: 'lolling', word_class: 'AJ' }
  ],
  amalgamative: [
    { word: 'amalgam', word_class: 'N' },
    { word: 'amalgamate', word_class: 'V' },
    { word: 'amalgamate', word_class: 'AJ' },
    { word: 'amalgamated', word_class: 'AJ' },
    { word: 'amalgamation', word_class: 'N' },
    { word: 'amalgamative', word_class: 'AJ' }
  ],
  proffer: [
    { word: 'proffer', word_class: 'N' },
    { word: 'proffer', word_class: 'V' },
    { word: 'profferment', word_class: 'N' }
  ],
  insufficiently: [
    { word: 'insufficiency', word_class: 'N' },
    { word: 'insufficient', word_class: 'AJ' },
    { word: 'insufficiently', word_class: 'AV' }
  ],
  commonality: [
    { word: 'common', word_class: 'N' },
    { word: 'common', word_class: 'AJ' },
    { word: 'commons', word_class: 'N' },
    { word: 'commoner', word_class: 'N' },
    { word: 'commonness', word_class: 'N' },
    { word: 'commonality', word_class: 'N' }
  ],
  feudalism: [
    { word: 'feudal', word_class: 'N' },
    { word: 'feudal', word_class: 'AJ' },
    { word: 'feudalise', word_class: 'V' },
    { word: 'feudalize', word_class: 'V' },
    { word: 'feudalism', word_class: 'N' },
    { word: 'feudally', word_class: 'AV' },
    { word: 'feudalisation', word_class: 'N' },
    { word: 'feudalization', word_class: 'N' }
  ],
  impressionist: [
    { word: 'impressionist', word_class: 'N' },
    { word: 'impressionist', word_class: 'AJ' },
    { word: 'impressionistic', word_class: 'AJ' }
  ],
  superintendence: [
    { word: 'superintend', word_class: 'V' },
    { word: 'superintendent', word_class: 'N' },
    { word: 'superintendence', word_class: 'N' },
    { word: 'superintendent', word_class: 'AJ' }
  ],
  redheaded: [
    { word: 'redhead', word_class: 'N' },
    { word: 'redheader', word_class: 'N' },
    { word: 'redheaded', word_class: 'AJ' }
  ],
  baffle: [
    { word: 'baffle', word_class: 'N' },
    { word: 'baffle', word_class: 'V' },
    { word: 'baffled', word_class: 'N' },
    { word: 'baffled', word_class: 'AJ' },
    { word: 'baffling', word_class: 'AJ' },
    { word: 'bafflement', word_class: 'N' }
  ],
  grace: [
    { word: 'grace', word_class: 'N' },
    { word: 'grace', word_class: 'V' },
    { word: 'graces', word_class: 'N' },
    { word: 'gracious', word_class: 'AJ' },
    { word: 'graceful', word_class: 'AJ' },
    { word: 'gracefulness', word_class: 'N' }
  ],
  mongolism: [
    { word: 'mongol', word_class: 'N' },
    { word: 'mongol', word_class: 'AJ' },
    { word: 'mongolic', word_class: 'N' },
    { word: 'mongolism', word_class: 'N' }
  ],
  uglify: [
    { word: 'ugli', word_class: 'N' },
    { word: 'ugly', word_class: 'AJ' },
    { word: 'uglify', word_class: 'V' },
    { word: 'ugliness', word_class: 'N' },
    { word: 'uglification', word_class: 'N' }
  ],
  epic: [{ word: 'epic', word_class: 'N' }, { word: 'epic', word_class: 'AJ' }, { word: 'epical', word_class: 'AJ' }],
  truck: [
    { word: 'truck', word_class: 'N' },
    { word: 'truck', word_class: 'V' },
    { word: 'truckful', word_class: 'N' },
    { word: 'trucking', word_class: 'N' },
    { word: 'truckful', word_class: 'AJ' }
  ],
  redoubled: [
    { word: 'redouble', word_class: 'N' },
    { word: 'redouble', word_class: 'V' },
    { word: 'redoubled', word_class: 'AJ' }
  ],
  translate: [
    { word: 'translate', word_class: 'V' },
    { word: 'translate', word_class: 'AJ' },
    { word: 'translator', word_class: 'N' },
    { word: 'translation', word_class: 'N' },
    { word: 'translatable', word_class: 'AJ' },
    { word: 'translational', word_class: 'AJ' }
  ],
  currentness: [
    { word: 'current', word_class: 'N' },
    { word: 'current', word_class: 'AJ' },
    { word: 'currently', word_class: 'AV' },
    { word: 'currentness', word_class: 'N' }
  ],
  puffing: [
    { word: 'puffed', word_class: 'AJ' },
    { word: 'puffing', word_class: 'N' },
    { word: 'puffing', word_class: 'AJ' }
  ],
  clinker: [
    { word: 'clink', word_class: 'N' },
    { word: 'clink', word_class: 'V' },
    { word: 'clinker', word_class: 'N' },
    { word: 'clinker', word_class: 'V' },
    { word: 'clinking', word_class: 'AJ' }
  ],
  platitudinous: [
    { word: 'platitude', word_class: 'N' },
    { word: 'platitudinal', word_class: 'AJ' },
    { word: 'platitudinize', word_class: 'V' },
    { word: 'platitudinous', word_class: 'AJ' },
    { word: 'platitudinization', word_class: 'N' }
  ],
  indifferent: [
    { word: 'indifference', word_class: 'N' },
    { word: 'indifferent', word_class: 'AJ' },
    { word: 'indifferently', word_class: 'AV' }
  ],
  exclaim: [
    { word: 'exclaim', word_class: 'N' },
    { word: 'exclaim', word_class: 'V' },
    { word: 'exclaiming', word_class: 'N' },
    { word: 'exclamation', word_class: 'N' },
    { word: 'exclamatory', word_class: 'AJ' }
  ],
  tangibility: [
    { word: 'tangible', word_class: 'AJ' },
    { word: 'tangibly', word_class: 'AV' },
    { word: 'tangibility', word_class: 'N' },
    { word: 'tangibleness', word_class: 'N' }
  ],
  dismantled: [
    { word: 'dismantle', word_class: 'N' },
    { word: 'dismantle', word_class: 'V' },
    { word: 'dismantled', word_class: 'AJ' },
    { word: 'dismantling', word_class: 'N' },
    { word: 'dismantlement', word_class: 'N' }
  ],
  horseshoer: [
    { word: 'horseshoe', word_class: 'N' },
    { word: 'horseshoer', word_class: 'N' },
    { word: 'horseshoes', word_class: 'N' }
  ],
  distrustfulness: [
    { word: 'distrust', word_class: 'N' },
    { word: 'distrust', word_class: 'V' },
    { word: 'distrustful', word_class: 'AJ' },
    { word: 'distrustfulness', word_class: 'N' }
  ],
  admission: [
    { word: 'admit', word_class: 'V' },
    { word: 'admission', word_class: 'N' },
    { word: 'admitted', word_class: 'AJ' },
    { word: 'admissive', word_class: 'AJ' },
    { word: 'admittance', word_class: 'N' },
    { word: 'admissible', word_class: 'AJ' },
    { word: 'admittable', word_class: 'AJ' },
    { word: 'admittible', word_class: 'AJ' },
    { word: 'admissibility', word_class: 'N' }
  ],
  binful: [{ word: 'bin', word_class: 'N' }, { word: 'bin', word_class: 'V' }, { word: 'binful', word_class: 'N' }],
  colonize: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  gesticulation: [
    { word: 'gesticulate', word_class: 'V' },
    { word: 'gesticulation', word_class: 'N' },
    { word: 'gesticulating', word_class: 'AJ' }
  ],
  forgone: [
    { word: 'forgo', word_class: 'V' },
    { word: 'forgoing', word_class: 'N' },
    { word: 'forgone', word_class: 'AJ' },
    { word: 'forgoing', word_class: 'AJ' }
  ],
  smacker: [
    { word: 'smack', word_class: 'N' },
    { word: 'smack', word_class: 'V' },
    { word: 'smack', word_class: 'AV' },
    { word: 'smacker', word_class: 'N' },
    { word: 'smacking', word_class: 'N' }
  ],
  indecorously: [
    { word: 'indecorous', word_class: 'AJ' },
    { word: 'indecorously', word_class: 'AV' },
    { word: 'indecorousness', word_class: 'N' }
  ],
  emigrant: [
    { word: 'emigre', word_class: 'N' },
    { word: 'emigrant', word_class: 'N' },
    { word: 'emigrate', word_class: 'V' },
    { word: 'emigration', word_class: 'N' }
  ],
  trandscendence: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  petitioner: [
    { word: 'petition', word_class: 'N' },
    { word: 'petition', word_class: 'V' },
    { word: 'petitioner', word_class: 'N' }
  ],
  interlineal: [
    { word: 'interline', word_class: 'V' },
    { word: 'interlining', word_class: 'N' },
    { word: 'interlineal', word_class: 'AJ' },
    { word: 'interlineate', word_class: 'V' },
    { word: 'interlineation', word_class: 'N' }
  ],
  scratch: [
    { word: 'scratch', word_class: 'N' },
    { word: 'scratch', word_class: 'V' },
    { word: 'scratched', word_class: 'AJ' },
    { word: 'scratching', word_class: 'N' },
    { word: 'scratching', word_class: 'AJ' }
  ],
  submissively: [
    { word: 'submit', word_class: 'V' },
    { word: 'submittal', word_class: 'N' },
    { word: 'submission', word_class: 'N' },
    { word: 'submissive', word_class: 'N' },
    { word: 'submissive', word_class: 'AJ' },
    { word: 'submissively', word_class: 'AV' },
    { word: 'submissiveness', word_class: 'N' }
  ],
  portal: [
    { word: 'port', word_class: 'N' },
    { word: 'port', word_class: 'V' },
    { word: 'port', word_class: 'AJ' },
    { word: 'portal', word_class: 'N' },
    { word: 'porter', word_class: 'N' },
    { word: 'portal', word_class: 'AJ' },
    { word: 'portent', word_class: 'N' },
    { word: 'portion', word_class: 'N' },
    { word: 'portion', word_class: 'V' },
    { word: 'portend', word_class: 'V' },
    { word: 'portentous', word_class: 'AJ' },
    { word: 'portentously', word_class: 'AV' }
  ],
  literalism: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  hackney: [
    { word: 'hackney', word_class: 'N' },
    { word: 'hackney', word_class: 'V' },
    { word: 'hackneyed', word_class: 'AJ' }
  ],
  indecisiveness: [
    { word: 'indecision', word_class: 'N' },
    { word: 'indecisive', word_class: 'AJ' },
    { word: 'indecisively', word_class: 'AV' },
    { word: 'indecisiveness', word_class: 'N' }
  ],
  imprison: [
    { word: 'imprison', word_class: 'N' },
    { word: 'imprison', word_class: 'V' },
    { word: 'imprisoned', word_class: 'AJ' },
    { word: 'imprisonment', word_class: 'N' }
  ],
  loathsome: [
    { word: 'loath', word_class: 'V' },
    { word: 'loath', word_class: 'AJ' },
    { word: 'loathe', word_class: 'V' },
    { word: 'loathing', word_class: 'N' },
    { word: 'loathsome', word_class: 'AJ' },
    { word: 'loathsomeness', word_class: 'N' }
  ],
  lamentation: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  borderer: [
    { word: 'border', word_class: 'N' },
    { word: 'border', word_class: 'V' },
    { word: 'borderer', word_class: 'N' },
    { word: 'bordered', word_class: 'AJ' },
    { word: 'bordering', word_class: 'AJ' }
  ],
  brained: [
    { word: 'brain', word_class: 'N' },
    { word: 'brain', word_class: 'V' },
    { word: 'brained', word_class: 'AJ' }
  ],
  applauder: [
    { word: 'applaud', word_class: 'V' },
    { word: 'applause', word_class: 'N' },
    { word: 'applauder', word_class: 'N' },
    { word: 'applaudable', word_class: 'AJ' }
  ],
  governable: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  stony: [
    { word: 'stone', word_class: 'N' },
    { word: 'stone', word_class: 'V' },
    { word: 'stony', word_class: 'AJ' },
    { word: 'stone', word_class: 'AJ' },
    { word: 'stoned', word_class: 'AJ' }
  ],
  clogged: [
    { word: 'clog', word_class: 'N' },
    { word: 'clog', word_class: 'V' },
    { word: 'clogged', word_class: 'AJ' },
    { word: 'clogging', word_class: 'AJ' }
  ],
  counterpoised: [
    { word: 'counterpoise', word_class: 'N' },
    { word: 'counterpoise', word_class: 'V' },
    { word: 'counterpoised', word_class: 'AJ' }
  ],
  puncturable: [
    { word: 'puncture', word_class: 'N' },
    { word: 'puncture', word_class: 'V' },
    { word: 'punctured', word_class: 'AJ' },
    { word: 'puncturing', word_class: 'N' },
    { word: 'puncturable', word_class: 'AJ' }
  ],
  acknowledge: [
    { word: 'acknowledge', word_class: 'V' },
    { word: 'acknowledged', word_class: 'AJ' },
    { word: 'acknowldgement', word_class: 'N' },
    { word: 'acknowledgment', word_class: 'N' },
    { word: 'acknowledgement', word_class: 'N' },
    { word: 'acknowledgeable', word_class: 'AJ' }
  ],
  repudiation: [
    { word: 'repudiate', word_class: 'V' },
    { word: 'repudiated', word_class: 'AJ' },
    { word: 'repudiation', word_class: 'N' },
    { word: 'repudiative', word_class: 'AJ' }
  ],
  grumbler: [
    { word: 'grumble', word_class: 'N' },
    { word: 'grumble', word_class: 'V' },
    { word: 'grumbler', word_class: 'N' },
    { word: 'grumbling', word_class: 'N' },
    { word: 'grumbling', word_class: 'AJ' }
  ],
  nap: [
    { word: 'nap', word_class: 'N' },
    { word: 'nap', word_class: 'V' },
    { word: 'napped', word_class: 'AJ' },
    { word: 'napping', word_class: 'AJ' }
  ],
  suffused: [
    { word: 'suffuse', word_class: 'V' },
    { word: 'suffused', word_class: 'AJ' },
    { word: 'suffusion', word_class: 'N' },
    { word: 'suffusive', word_class: 'AJ' }
  ],
  capital: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  impartment: [
    { word: 'impart', word_class: 'N' },
    { word: 'impart', word_class: 'V' },
    { word: 'imparting', word_class: 'N' },
    { word: 'impartment', word_class: 'N' },
    { word: 'impartation', word_class: 'N' }
  ],
  dishonor: [
    { word: 'dishonor', word_class: 'N' },
    { word: 'dishonor', word_class: 'V' },
    { word: 'dishonored', word_class: 'AJ' },
    { word: 'dishonorable', word_class: 'AJ' },
    { word: 'dishonorably', word_class: 'AV' },
    { word: 'dishonorableness', word_class: 'N' }
  ],
  talker: [
    { word: 'talk', word_class: 'N' },
    { word: 'talk', word_class: 'V' },
    { word: 'talks', word_class: 'N' },
    { word: 'talker', word_class: 'N' },
    { word: 'talking', word_class: 'N' },
    { word: 'talking', word_class: 'AJ' },
    { word: 'talkative', word_class: 'AJ' },
    { word: 'talkatively', word_class: 'AV' },
    { word: 'talkativeness', word_class: 'N' }
  ],
  'ill-treatment': [
    { word: 'ill-treat', word_class: 'V' },
    { word: 'ill-treated', word_class: 'AJ' },
    { word: 'ill-treatment', word_class: 'N' }
  ],
  seriation: [
    { word: 'seriate', word_class: 'V' },
    { word: 'seriate', word_class: 'AJ' },
    { word: 'seriation', word_class: 'N' }
  ],
  bicornate: [
    { word: 'bicorn', word_class: 'N' },
    { word: 'bicorn', word_class: 'AJ' },
    { word: 'bicorne', word_class: 'N' },
    { word: 'bicorned', word_class: 'AJ' },
    { word: 'bicornate', word_class: 'AJ' }
  ],
  habitually: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  recyclable: [
    { word: 'recycle', word_class: 'V' },
    { word: 'recycling', word_class: 'N' },
    { word: 'recyclable', word_class: 'AJ' }
  ],
  cooker: [
    { word: 'cook', word_class: 'N' },
    { word: 'cook', word_class: 'V' },
    { word: 'cook', word_class: 'AJ' },
    { word: 'cooker', word_class: 'N' },
    { word: 'cookery', word_class: 'N' },
    { word: 'cooking', word_class: 'N' },
    { word: 'cooked', word_class: 'AJ' },
    { word: 'cookery', word_class: 'AJ' }
  ],
  transferral: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  compulsory: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  soulfulness: [
    { word: 'soul', word_class: 'N' },
    { word: 'soulful', word_class: 'AJ' },
    { word: 'soulfulness', word_class: 'N' }
  ],
  convalesce: [
    { word: 'convalesce', word_class: 'V' },
    { word: 'convalescent', word_class: 'N' },
    { word: 'convalescence', word_class: 'N' },
    { word: 'convalescent', word_class: 'AJ' }
  ],
  cos: [{ word: 'co', word_class: 'N' }, { word: 'cos', word_class: 'N' }, { word: 'coed', word_class: 'N' }],
  analogization: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  curare: [
    { word: 'curare', word_class: 'N' },
    { word: 'curarise', word_class: 'V' },
    { word: 'curarize', word_class: 'V' },
    { word: 'curarisation', word_class: 'N' },
    { word: 'curarization', word_class: 'N' }
  ],
  rested: [
    { word: 'rest', word_class: 'N' },
    { word: 'rest', word_class: 'V' },
    { word: 'rested', word_class: 'AJ' },
    { word: 'restful', word_class: 'AJ' },
    { word: 'restfulness', word_class: 'N' }
  ],
  replaceability: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  salaciously: [
    { word: 'salacious', word_class: 'AJ' },
    { word: 'salaciously', word_class: 'AV' },
    { word: 'salaciousness', word_class: 'N' }
  ],
  differing: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  deodorant: [
    { word: 'deodorant', word_class: 'N' },
    { word: 'deodorise', word_class: 'V' },
    { word: 'deodorize', word_class: 'V' },
    { word: 'deodorisation', word_class: 'N' },
    { word: 'deodorization', word_class: 'N' }
  ],
  phosphorus: [
    { word: 'phosphor', word_class: 'N' },
    { word: 'phosphorus', word_class: 'N' },
    { word: 'phosphoric', word_class: 'AJ' },
    { word: 'phosphorous', word_class: 'AJ' }
  ],
  judicial: [
    { word: 'judicial', word_class: 'AJ' },
    { word: 'judicious', word_class: 'AJ' },
    { word: 'judicially', word_class: 'AV' },
    { word: 'judiciously', word_class: 'AV' },
    { word: 'judiciousness', word_class: 'N' }
  ],
  malthusian: [
    { word: 'malthusian', word_class: 'N' },
    { word: 'malthusian', word_class: 'AJ' },
    { word: 'malthusianism', word_class: 'N' }
  ],
  prune: [
    { word: 'prune', word_class: 'N' },
    { word: 'prune', word_class: 'V' },
    { word: 'pruner', word_class: 'N' },
    { word: 'pruned', word_class: 'AJ' },
    { word: 'pruning', word_class: 'N' }
  ],
  obscuration: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  estranged: [
    { word: 'estrange', word_class: 'V' },
    { word: 'estranged', word_class: 'AJ' },
    { word: 'estranging', word_class: 'AJ' },
    { word: 'estrangement', word_class: 'N' }
  ],
  emergence: [
    { word: 'emerge', word_class: 'V' },
    { word: 'emersion', word_class: 'N' },
    { word: 'emergence', word_class: 'N' },
    { word: 'emergency', word_class: 'N' },
    { word: 'emergent', word_class: 'AJ' },
    { word: 'emerging', word_class: 'AJ' },
    { word: 'emergency', word_class: 'AJ' }
  ],
  puncture: [
    { word: 'puncture', word_class: 'N' },
    { word: 'puncture', word_class: 'V' },
    { word: 'punctured', word_class: 'AJ' },
    { word: 'puncturing', word_class: 'N' },
    { word: 'puncturable', word_class: 'AJ' }
  ],
  dresser: [
    { word: 'dress', word_class: 'N' },
    { word: 'dress', word_class: 'V' },
    { word: 'dress', word_class: 'AJ' },
    { word: 'dresser', word_class: 'N' },
    { word: 'dressed', word_class: 'AJ' },
    { word: 'dressing', word_class: 'N' }
  ],
  focalize: [
    { word: 'focal', word_class: 'AJ' },
    { word: 'focalize', word_class: 'V' },
    { word: 'focally', word_class: 'AV' },
    { word: 'focalisation', word_class: 'N' },
    { word: 'focalization', word_class: 'N' }
  ],
  trussed: [
    { word: 'truss', word_class: 'N' },
    { word: 'truss', word_class: 'V' },
    { word: 'trussed', word_class: 'AJ' }
  ],
  packing: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  shimmy: [
    { word: 'shimmy', word_class: 'N' },
    { word: 'shimmy', word_class: 'V' },
    { word: 'shimmy', word_class: 'AJ' }
  ],
  enumerate: [
    { word: 'enumerate', word_class: 'V' },
    { word: 'enumerable', word_class: 'AJ' },
    { word: 'enumeration', word_class: 'N' }
  ],
  immovableness: [
    { word: 'immovable', word_class: 'AJ' },
    { word: 'immovably', word_class: 'AV' },
    { word: 'immoveable', word_class: 'AJ' },
    { word: 'immovability', word_class: 'N' },
    { word: 'immovableness', word_class: 'N' }
  ],
  disembody: [
    { word: 'disembody', word_class: 'V' },
    { word: 'disembodied', word_class: 'AJ' },
    { word: 'disembodiment', word_class: 'N' }
  ],
  propelling: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  penetrably: [
    { word: 'penetrate', word_class: 'V' },
    { word: 'penetrable', word_class: 'AJ' },
    { word: 'penetrably', word_class: 'AV' },
    { word: 'penetration', word_class: 'N' },
    { word: 'penetrating', word_class: 'AJ' },
    { word: 'penetrative', word_class: 'AJ' },
    { word: 'penetrability', word_class: 'N' },
    { word: 'penetratively', word_class: 'AV' }
  ],
  mob: [{ word: 'mob', word_class: 'N' }, { word: 'mob', word_class: 'V' }, { word: 'mob', word_class: 'AJ' }],
  tractableness: [
    { word: 'tractable', word_class: 'AJ' },
    { word: 'tractably', word_class: 'AV' },
    { word: 'tractability', word_class: 'N' },
    { word: 'tractableness', word_class: 'N' }
  ],
  technology: [
    { word: 'technology', word_class: 'N' },
    { word: 'technological', word_class: 'AJ' },
    { word: 'technologically', word_class: 'AV' }
  ],
  demonetise: [
    { word: 'demonetise', word_class: 'V' },
    { word: 'demonetize', word_class: 'V' },
    { word: 'demonetisation', word_class: 'N' },
    { word: 'demonetization', word_class: 'N' }
  ],
  decisively: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  sailplane: [
    { word: 'sailplane', word_class: 'N' },
    { word: 'sailplane', word_class: 'V' },
    { word: 'sailplaning', word_class: 'N' }
  ],
  rub: [{ word: 'rub', word_class: 'N' }, { word: 'rub', word_class: 'V' }, { word: 'rubbing', word_class: 'N' }],
  parenthetically: [
    { word: 'parenthetic', word_class: 'AJ' },
    { word: 'parenthetical', word_class: 'AJ' },
    { word: 'parenthetically', word_class: 'AV' }
  ],
  rubbing: [{ word: 'rub', word_class: 'N' }, { word: 'rub', word_class: 'V' }, { word: 'rubbing', word_class: 'N' }],
  tittering: [
    { word: 'titter', word_class: 'N' },
    { word: 'titter', word_class: 'V' },
    { word: 'tittering', word_class: 'AJ' }
  ],
  smudgy: [
    { word: 'smudge', word_class: 'N' },
    { word: 'smudge', word_class: 'V' },
    { word: 'smudgy', word_class: 'AJ' },
    { word: 'smudged', word_class: 'AJ' }
  ],
  antagonist: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  utility: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  prey: [
    { word: 'prey', word_class: 'N' },
    { word: 'prey', word_class: 'V' },
    { word: 'predate', word_class: 'V' },
    { word: 'preying', word_class: 'N' },
    { word: 'predator', word_class: 'N' },
    { word: 'predation', word_class: 'N' }
  ],
  assessment: [
    { word: 'assess', word_class: 'N' },
    { word: 'assess', word_class: 'V' },
    { word: 'assessor', word_class: 'N' },
    { word: 'assessment', word_class: 'N' },
    { word: 'assessable', word_class: 'AJ' }
  ],
  scanty: [
    { word: 'scanty', word_class: 'N' },
    { word: 'scanty', word_class: 'AJ' },
    { word: 'scantiness', word_class: 'N' }
  ],
  tippler: [
    { word: 'tipple', word_class: 'N' },
    { word: 'tipple', word_class: 'V' },
    { word: 'tippler', word_class: 'N' }
  ],
  sharpness: [
    { word: 'sharp', word_class: 'N' },
    { word: 'sharp', word_class: 'AJ' },
    { word: 'sharp', word_class: 'AV' },
    { word: 'sharpness', word_class: 'N' }
  ],
  probabilistically: [
    { word: 'probabilist', word_class: 'N' },
    { word: 'probabilistic', word_class: 'AJ' },
    { word: 'probabilistically', word_class: 'AV' }
  ],
  plod: [
    { word: 'plod', word_class: 'N' },
    { word: 'plod', word_class: 'V' },
    { word: 'plodding', word_class: 'N' },
    { word: 'plodding', word_class: 'AJ' }
  ],
  let: [{ word: 'let', word_class: 'N' }, { word: 'let', word_class: 'V' }, { word: 'letting', word_class: 'N' }],
  germanize: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  saucer: [{ word: 'sauce', word_class: 'N' }, { word: 'sauce', word_class: 'V' }, { word: 'saucer', word_class: 'N' }],
  potholed: [
    { word: 'pothole', word_class: 'N' },
    { word: 'potholer', word_class: 'N' },
    { word: 'potholed', word_class: 'AJ' }
  ],
  extroversion: [
    { word: 'extrovert', word_class: 'N' },
    { word: 'extrovert', word_class: 'AJ' },
    { word: 'extroversion', word_class: 'N' },
    { word: 'extroverted', word_class: 'AJ' },
    { word: 'extroversive', word_class: 'AJ' },
    { word: 'extrovertive', word_class: 'AJ' }
  ],
  teacher: [
    { word: 'teach', word_class: 'N' },
    { word: 'teach', word_class: 'V' },
    { word: 'teacher', word_class: 'N' },
    { word: 'teaching', word_class: 'N' }
  ],
  alcoholize: [
    { word: 'alcohol', word_class: 'N' },
    { word: 'alcoholic', word_class: 'N' },
    { word: 'alcoholize', word_class: 'V' },
    { word: 'alcoholise', word_class: 'V' },
    { word: 'alcoholic', word_class: 'AJ' },
    { word: 'alcoholism', word_class: 'N' },
    { word: 'alcoholisation', word_class: 'N' },
    { word: 'alcoholization', word_class: 'N' }
  ],
  basined: [
    { word: 'basin', word_class: 'N' },
    { word: 'basinal', word_class: 'AJ' },
    { word: 'basined', word_class: 'AJ' },
    { word: 'basinful', word_class: 'N' }
  ],
  transformed: [
    { word: 'transform', word_class: 'N' },
    { word: 'transform', word_class: 'V' },
    { word: 'transformer', word_class: 'N' },
    { word: 'transformed', word_class: 'AJ' },
    { word: 'transformable', word_class: 'AJ' },
    { word: 'transformation', word_class: 'N' }
  ],
  anciently: [
    { word: 'ancient', word_class: 'N' },
    { word: 'ancient', word_class: 'AJ' },
    { word: 'anciently', word_class: 'AV' },
    { word: 'ancientness', word_class: 'N' }
  ],
  fossilize: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  dumbfounding: [
    { word: 'dumbfound', word_class: 'V' },
    { word: 'dumbfounded', word_class: 'AJ' },
    { word: 'dumbfounding', word_class: 'AJ' }
  ],
  abstractor: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  angiocarpous: [
    { word: 'angiocarp', word_class: 'N' },
    { word: 'angiocarpic', word_class: 'AJ' },
    { word: 'angiocarpous', word_class: 'AJ' }
  ],
  composing: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  foreshadowing: [
    { word: 'foreshadow', word_class: 'V' },
    { word: 'foreshadowing', word_class: 'N' },
    { word: 'foreshadowing', word_class: 'AJ' }
  ],
  eponymic: [
    { word: 'eponym', word_class: 'N' },
    { word: 'eponymic', word_class: 'AJ' },
    { word: 'eponymous', word_class: 'AJ' }
  ],
  propellant: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  evacuated: [
    { word: 'evacuee', word_class: 'N' },
    { word: 'evacuate', word_class: 'V' },
    { word: 'evacuant', word_class: 'AJ' },
    { word: 'evacuated', word_class: 'AJ' },
    { word: 'evacuation', word_class: 'N' }
  ],
  unpretentiously: [
    { word: 'unpretentious', word_class: 'AJ' },
    { word: 'unpretentiously', word_class: 'AV' },
    { word: 'unpretentiousness', word_class: 'N' }
  ],
  mocker: [
    { word: 'mock', word_class: 'N' },
    { word: 'mock', word_class: 'V' },
    { word: 'mock', word_class: 'AJ' },
    { word: 'mocker', word_class: 'N' },
    { word: 'mockery', word_class: 'N' },
    { word: 'mockery', word_class: 'AJ' },
    { word: 'mocking', word_class: 'AJ' }
  ],
  sensuality: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  appearing: [
    { word: 'appear', word_class: 'V' },
    { word: 'appearing', word_class: 'N' },
    { word: 'appearance', word_class: 'N' }
  ],
  entrenched: [
    { word: 'entrench', word_class: 'V' },
    { word: 'entrenched', word_class: 'AJ' },
    { word: 'entrenchment', word_class: 'N' }
  ],
  pamper: [
    { word: 'pamper', word_class: 'V' },
    { word: 'pampered', word_class: 'AJ' },
    { word: 'pampering', word_class: 'N' },
    { word: 'pampering', word_class: 'AJ' }
  ],
  angiocarpic: [
    { word: 'angiocarp', word_class: 'N' },
    { word: 'angiocarpic', word_class: 'AJ' },
    { word: 'angiocarpous', word_class: 'AJ' }
  ],
  generative: [
    { word: 'generate', word_class: 'V' },
    { word: 'generator', word_class: 'N' },
    { word: 'generation', word_class: 'N' },
    { word: 'generative', word_class: 'AJ' }
  ],
  open: [
    { word: 'open', word_class: 'N' },
    { word: 'open', word_class: 'V' },
    { word: 'open', word_class: 'AJ' },
    { word: 'opener', word_class: 'N' },
    { word: 'opening', word_class: 'N' },
    { word: 'opened', word_class: 'AJ' },
    { word: 'opening', word_class: 'AJ' },
    { word: 'openness', word_class: 'N' }
  ],
  nebulize: [
    { word: 'nebulise', word_class: 'V' },
    { word: 'nebulize', word_class: 'V' },
    { word: 'nebulous', word_class: 'AJ' },
    { word: 'nebulously', word_class: 'AV' },
    { word: 'nebulisation', word_class: 'N' },
    { word: 'nebulization', word_class: 'N' }
  ],
  arsenate: [
    { word: 'arsenal', word_class: 'N' },
    { word: 'arsenic', word_class: 'N' },
    { word: 'arsenate', word_class: 'N' },
    { word: 'arsenical', word_class: 'AJ' }
  ],
  qualifier: [
    { word: 'qualify', word_class: 'V' },
    { word: 'qualifier', word_class: 'N' },
    { word: 'qualified', word_class: 'AJ' },
    { word: 'qualifying', word_class: 'N' },
    { word: 'qualifying', word_class: 'AJ' },
    { word: 'qualification', word_class: 'N' }
  ],
  conscientious: [
    { word: 'conscience', word_class: 'N' },
    { word: 'conscientious', word_class: 'AJ' },
    { word: 'conscientiously', word_class: 'AV' },
    { word: 'conscientiousness', word_class: 'N' }
  ],
  connotational: [
    { word: 'connote', word_class: 'V' },
    { word: 'connotation', word_class: 'N' },
    { word: 'connotative', word_class: 'AJ' },
    { word: 'connotational', word_class: 'AJ' }
  ],
  totalism: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  refreshful: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  screening: [
    { word: 'screen', word_class: 'N' },
    { word: 'screen', word_class: 'V' },
    { word: 'screening', word_class: 'N' }
  ],
  detested: [
    { word: 'detest', word_class: 'V' },
    { word: 'detested', word_class: 'AJ' },
    { word: 'detestable', word_class: 'AJ' },
    { word: 'detestably', word_class: 'AV' },
    { word: 'detestation', word_class: 'N' }
  ],
  illegal: [
    { word: 'illegal', word_class: 'AJ' },
    { word: 'illegalise', word_class: 'V' },
    { word: 'illegalize', word_class: 'V' },
    { word: 'illegality', word_class: 'N' },
    { word: 'illegally', word_class: 'AV' },
    { word: 'illegible', word_class: 'AJ' },
    { word: 'illegibly', word_class: 'AV' },
    { word: 'illegibility', word_class: 'N' },
    { word: 'illegalisation', word_class: 'N' },
    { word: 'illegalization', word_class: 'N' }
  ],
  acceleration: [
    { word: 'accelerate', word_class: 'V' },
    { word: 'accelerator', word_class: 'N' },
    { word: 'accelerated', word_class: 'AJ' },
    { word: 'acceleration', word_class: 'N' },
    { word: 'accelerating', word_class: 'AJ' }
  ],
  truss: [
    { word: 'truss', word_class: 'N' },
    { word: 'truss', word_class: 'V' },
    { word: 'trussed', word_class: 'AJ' }
  ],
  unionist: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  unpalatableness: [
    { word: 'unpalatable', word_class: 'AJ' },
    { word: 'unpalatably', word_class: 'AV' },
    { word: 'unpalatability', word_class: 'N' },
    { word: 'unpalatableness', word_class: 'N' }
  ],
  invariable: [
    { word: 'invariant', word_class: 'N' },
    { word: 'invariant', word_class: 'AJ' },
    { word: 'invariance', word_class: 'N' },
    { word: 'invariable', word_class: 'AJ' },
    { word: 'invariably', word_class: 'AV' },
    { word: 'invariability', word_class: 'N' },
    { word: 'invariableness', word_class: 'N' }
  ],
  resentment: [
    { word: 'resent', word_class: 'V' },
    { word: 'resentful', word_class: 'AJ' },
    { word: 'resentment', word_class: 'N' }
  ],
  stinking: [
    { word: 'stink', word_class: 'N' },
    { word: 'stink', word_class: 'V' },
    { word: 'stinker', word_class: 'N' },
    { word: 'stinking', word_class: 'AJ' }
  ],
  repressive: [
    { word: 'repress', word_class: 'N' },
    { word: 'repress', word_class: 'V' },
    { word: 'repressed', word_class: 'AJ' },
    { word: 'repression', word_class: 'N' },
    { word: 'repressing', word_class: 'AJ' },
    { word: 'repressive', word_class: 'AJ' }
  ],
  structuralism: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  estivation: [
    { word: 'estival', word_class: 'AJ' },
    { word: 'estivate', word_class: 'V' },
    { word: 'estivation', word_class: 'N' }
  ],
  tithe: [{ word: 'tithe', word_class: 'N' }, { word: 'tithe', word_class: 'V' }, { word: 'tithing', word_class: 'N' }],
  thankful: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  plasticise: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  boone: [{ word: 'boon', word_class: 'N' }, { word: 'boon', word_class: 'AJ' }, { word: 'boone', word_class: 'N' }],
  supervision: [
    { word: 'supervise', word_class: 'N' },
    { word: 'supervise', word_class: 'V' },
    { word: 'supervisor', word_class: 'N' },
    { word: 'supervised', word_class: 'AJ' },
    { word: 'supervising', word_class: 'N' },
    { word: 'supervision', word_class: 'N' }
  ],
  operatively: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  cadency: [
    { word: 'cadence', word_class: 'N' },
    { word: 'cadency', word_class: 'N' },
    { word: 'cadenced', word_class: 'AJ' }
  ],
  slander: [
    { word: 'slander', word_class: 'N' },
    { word: 'slander', word_class: 'V' },
    { word: 'slanderer', word_class: 'N' },
    { word: 'slanderous', word_class: 'AJ' }
  ],
  toothed: [
    { word: 'tooth', word_class: 'N' },
    { word: 'toothed', word_class: 'AJ' },
    { word: 'toothsome', word_class: 'AJ' },
    { word: 'toothsomeness', word_class: 'N' }
  ],
  blown: [
    { word: 'blow', word_class: 'N' },
    { word: 'blow', word_class: 'V' },
    { word: 'blower', word_class: 'N' },
    { word: 'blown', word_class: 'AJ' },
    { word: 'blowing', word_class: 'N' }
  ],
  ambulation: [
    { word: 'ambulate', word_class: 'V' },
    { word: 'ambulance', word_class: 'N' },
    { word: 'ambulant', word_class: 'AJ' },
    { word: 'ambulation', word_class: 'N' }
  ],
  loads: [
    { word: 'load', word_class: 'N' },
    { word: 'load', word_class: 'V' },
    { word: 'loads', word_class: 'N' },
    { word: 'loader', word_class: 'N' },
    { word: 'loaded', word_class: 'AJ' },
    { word: 'loading', word_class: 'N' },
    { word: 'loading', word_class: 'AJ' }
  ],
  precisely: [
    { word: 'precis', word_class: 'V' },
    { word: 'precis', word_class: 'N' },
    { word: 'precise', word_class: 'AJ' },
    { word: 'precision', word_class: 'N' },
    { word: 'precisely', word_class: 'AV' },
    { word: 'preciseness', word_class: 'N' }
  ],
  chartism: [
    { word: 'chart', word_class: 'N' },
    { word: 'chart', word_class: 'V' },
    { word: 'chartism', word_class: 'N' },
    { word: 'chartist', word_class: 'N' }
  ],
  eosinophil: [
    { word: 'eosinophil', word_class: 'N' },
    { word: 'eosinophile', word_class: 'N' },
    { word: 'eosinophilic', word_class: 'AJ' }
  ],
  arbitrational: [
    { word: 'arbitral', word_class: 'AJ' },
    { word: 'arbitrate', word_class: 'V' },
    { word: 'arbitrator', word_class: 'N' },
    { word: 'arbitrable', word_class: 'AJ' },
    { word: 'arbitrament', word_class: 'N' },
    { word: 'arbitration', word_class: 'N' },
    { word: 'arbitrement', word_class: 'N' },
    { word: 'arbitrative', word_class: 'AJ' },
    { word: 'arbitrational', word_class: 'AJ' }
  ],
  agreeing: [
    { word: 'agree', word_class: 'V' },
    { word: 'agreed', word_class: 'AJ' },
    { word: 'agreeing', word_class: 'AJ' },
    { word: 'agreement', word_class: 'N' }
  ],
  gallicism: [
    { word: 'gallic', word_class: 'AJ' },
    { word: 'gallicism', word_class: 'N' },
    { word: 'gallicize', word_class: 'V' },
    { word: 'gallicization', word_class: 'N' }
  ],
  persisting: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  enfeebling: [
    { word: 'enfeeble', word_class: 'V' },
    { word: 'enfeebled', word_class: 'AJ' },
    { word: 'enfeebling', word_class: 'AJ' },
    { word: 'enfeeblement', word_class: 'N' }
  ],
  transmutability: [
    { word: 'transmute', word_class: 'V' },
    { word: 'transmute', word_class: 'AJ' },
    { word: 'transmutable', word_class: 'AJ' },
    { word: 'transmutation', word_class: 'N' },
    { word: 'transmutability', word_class: 'N' }
  ],
  millenarian: [
    { word: 'millenarian', word_class: 'N' },
    { word: 'millenarian', word_class: 'AJ' },
    { word: 'millenarianism', word_class: 'N' }
  ],
  suntanned: [
    { word: 'suntan', word_class: 'N' },
    { word: 'suntan', word_class: 'V' },
    { word: 'suntanned', word_class: 'AJ' }
  ],
  cheekful: [
    { word: 'cheek', word_class: 'N' },
    { word: 'cheek', word_class: 'V' },
    { word: 'cheek', word_class: 'AJ' },
    { word: 'cheeked', word_class: 'AJ' },
    { word: 'cheekful', word_class: 'N' },
    { word: 'cheekful', word_class: 'AJ' }
  ],
  visitant: [
    { word: 'visit', word_class: 'N' },
    { word: 'visit', word_class: 'V' },
    { word: 'visitor', word_class: 'N' },
    { word: 'visitant', word_class: 'N' },
    { word: 'visiting', word_class: 'N' },
    { word: 'visiting', word_class: 'AJ' },
    { word: 'visitation', word_class: 'N' }
  ],
  iodinate: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  wanderer: [
    { word: 'wander', word_class: 'N' },
    { word: 'wander', word_class: 'V' },
    { word: 'wanderer', word_class: 'N' },
    { word: 'wandering', word_class: 'N' },
    { word: 'wandering', word_class: 'AJ' }
  ],
  editorialize: [
    { word: 'editorial', word_class: 'N' },
    { word: 'editorial', word_class: 'AJ' },
    { word: 'editorialise', word_class: 'V' },
    { word: 'editorialist', word_class: 'N' },
    { word: 'editorialize', word_class: 'V' },
    { word: 'editorially', word_class: 'AV' },
    { word: 'editorialisation', word_class: 'N' },
    { word: 'editorialization', word_class: 'N' }
  ],
  crispness: [
    { word: 'crisp', word_class: 'N' },
    { word: 'crisp', word_class: 'V' },
    { word: 'crisp', word_class: 'AJ' },
    { word: 'crispness', word_class: 'N' }
  ],
  convert: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  rational: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  vibrant: [
    { word: 'vibrant', word_class: 'N' },
    { word: 'vibrate', word_class: 'V' },
    { word: 'vibrance', word_class: 'N' },
    { word: 'vibrancy', word_class: 'N' },
    { word: 'vibrant', word_class: 'AJ' },
    { word: 'vibrator', word_class: 'N' },
    { word: 'vibration', word_class: 'N' },
    { word: 'vibrating', word_class: 'AJ' },
    { word: 'vibrations', word_class: 'N' },
    { word: 'vibrational', word_class: 'AJ' }
  ],
  arch: [
    { word: 'arch', word_class: 'N' },
    { word: 'arch', word_class: 'V' },
    { word: 'arch', word_class: 'AJ' },
    { word: 'archer', word_class: 'N' },
    { word: 'arched', word_class: 'AJ' },
    { word: 'arching', word_class: 'AJ' },
    { word: 'archness', word_class: 'N' }
  ],
  africanise: [
    { word: 'african', word_class: 'N' },
    { word: 'african', word_class: 'AJ' },
    { word: 'africanise', word_class: 'V' },
    { word: 'africanize', word_class: 'V' },
    { word: 'africanisation', word_class: 'N' },
    { word: 'africanization', word_class: 'N' }
  ],
  stifle: [
    { word: 'stifle', word_class: 'N' },
    { word: 'stifle', word_class: 'V' },
    { word: 'stifled', word_class: 'AJ' },
    { word: 'stifling', word_class: 'N' },
    { word: 'stifling', word_class: 'AJ' }
  ],
  alignment: [
    { word: 'align', word_class: 'V' },
    { word: 'aligned', word_class: 'AJ' },
    { word: 'aligning', word_class: 'AJ' },
    { word: 'alignment', word_class: 'N' }
  ],
  unsnarling: [
    { word: 'unsnarl', word_class: 'V' },
    { word: 'unsnarled', word_class: 'AJ' },
    { word: 'unsnarling', word_class: 'N' }
  ],
  recombine: [
    { word: 'recombine', word_class: 'V' },
    { word: 'recombinant', word_class: 'N' },
    { word: 'recombinant', word_class: 'AJ' }
  ],
  europeanization: [
    { word: 'european', word_class: 'N' },
    { word: 'european', word_class: 'AJ' },
    { word: 'europeanize', word_class: 'V' },
    { word: 'europeanization', word_class: 'N' }
  ],
  spoiler: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  chilling: [
    { word: 'chill', word_class: 'N' },
    { word: 'chill', word_class: 'V' },
    { word: 'chill', word_class: 'AJ' },
    { word: 'chilled', word_class: 'AJ' },
    { word: 'chilling', word_class: 'N' },
    { word: 'chilling', word_class: 'AJ' }
  ],
  employer: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  totemism: [
    { word: 'totem', word_class: 'N' },
    { word: 'totemic', word_class: 'AJ' },
    { word: 'totemism', word_class: 'N' }
  ],
  rumination: [
    { word: 'ruminant', word_class: 'N' },
    { word: 'ruminate', word_class: 'V' },
    { word: 'ruminant', word_class: 'AJ' },
    { word: 'rumination', word_class: 'N' },
    { word: 'ruminative', word_class: 'AJ' }
  ],
  homological: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  steamer: [
    { word: 'steam', word_class: 'N' },
    { word: 'steam', word_class: 'V' },
    { word: 'steamer', word_class: 'N' },
    { word: 'steamed', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AV' },
    { word: 'steamship', word_class: 'N' }
  ],
  granularity: [
    { word: 'grain', word_class: 'N' },
    { word: 'grain', word_class: 'V' },
    { word: 'grained', word_class: 'AJ' },
    { word: 'graining', word_class: 'N' },
    { word: 'granular', word_class: 'AJ' },
    { word: 'granularity', word_class: 'N' }
  ],
  provocatively: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  mistreated: [
    { word: 'mistreat', word_class: 'V' },
    { word: 'mistreated', word_class: 'AJ' },
    { word: 'mistreatment', word_class: 'N' }
  ],
  honored: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  bobtailed: [
    { word: 'bobtail', word_class: 'N' },
    { word: 'bobtail', word_class: 'AJ' },
    { word: 'bobtailed', word_class: 'AJ' }
  ],
  inexorably: [
    { word: 'inexorable', word_class: 'AJ' },
    { word: 'inexorably', word_class: 'AV' },
    { word: 'inexorability', word_class: 'N' },
    { word: 'inexorableness', word_class: 'N' }
  ],
  obscurity: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  subservience: [
    { word: 'subserve', word_class: 'V' },
    { word: 'subservience', word_class: 'N' },
    { word: 'subservient', word_class: 'AJ' },
    { word: 'subserviently', word_class: 'AV' },
    { word: 'subservientness', word_class: 'N' }
  ],
  resonate: [
    { word: 'resonant', word_class: 'N' },
    { word: 'resonate', word_class: 'V' },
    { word: 'resonance', word_class: 'N' },
    { word: 'resonant', word_class: 'AJ' },
    { word: 'resonator', word_class: 'N' },
    { word: 'resonation', word_class: 'N' },
    { word: 'resonating', word_class: 'AJ' }
  ],
  clothes: [
    { word: 'cloth', word_class: 'N' },
    { word: 'clothe', word_class: 'V' },
    { word: 'clothes', word_class: 'N' },
    { word: 'clothed', word_class: 'AJ' },
    { word: 'clothing', word_class: 'N' }
  ],
  urbanization: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  meretriciousness: [
    { word: 'meretricious', word_class: 'AJ' },
    { word: 'meretriciously', word_class: 'AV' },
    { word: 'meretriciousness', word_class: 'N' }
  ],
  determinism: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  prognostic: [
    { word: 'prognostic', word_class: 'N' },
    { word: 'prognostic', word_class: 'AJ' },
    { word: 'prognosticate', word_class: 'V' },
    { word: 'prognosticator', word_class: 'N' },
    { word: 'prognositcation', word_class: 'N' },
    { word: 'prognostication', word_class: 'N' },
    { word: 'prognosticative', word_class: 'AJ' }
  ],
  anticipation: [
    { word: 'anticipate', word_class: 'V' },
    { word: 'anticipant', word_class: 'AJ' },
    { word: 'anticipated', word_class: 'AJ' },
    { word: 'anticipation', word_class: 'N' },
    { word: 'anticipative', word_class: 'AJ' }
  ],
  sickness: [
    { word: 'sick', word_class: 'N' },
    { word: 'sick', word_class: 'V' },
    { word: 'sick', word_class: 'AJ' },
    { word: 'sickness', word_class: 'N' }
  ],
  pantomime: [
    { word: 'pantomime', word_class: 'N' },
    { word: 'pantomime', word_class: 'V' },
    { word: 'pantomimer', word_class: 'N' }
  ],
  hedging: [
    { word: 'hedge', word_class: 'N' },
    { word: 'hedge', word_class: 'V' },
    { word: 'hedged', word_class: 'AJ' },
    { word: 'hedging', word_class: 'N' }
  ],
  brandish: [
    { word: 'brandish', word_class: 'N' },
    { word: 'brandish', word_class: 'V' },
    { word: 'brandish', word_class: 'AJ' },
    { word: 'brandishment', word_class: 'N' }
  ],
  stabbing: [
    { word: 'stab', word_class: 'N' },
    { word: 'stab', word_class: 'V' },
    { word: 'stabbed', word_class: 'AJ' },
    { word: 'stabbing', word_class: 'AJ' }
  ],
  individual: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  instilment: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  mystification: [
    { word: 'mystify', word_class: 'V' },
    { word: 'mystified', word_class: 'AJ' },
    { word: 'mystifying', word_class: 'AJ' },
    { word: 'mystification', word_class: 'N' }
  ],
  carbonic: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  squawker: [
    { word: 'squawk', word_class: 'N' },
    { word: 'squawk', word_class: 'V' },
    { word: 'squawker', word_class: 'N' }
  ],
  outwardness: [
    { word: 'outward', word_class: 'V' },
    { word: 'outward', word_class: 'AJ' },
    { word: 'outward', word_class: 'AV' },
    { word: 'outwards', word_class: 'AV' },
    { word: 'outwardness', word_class: 'N' }
  ],
  adulation: [
    { word: 'adulate', word_class: 'V' },
    { word: 'adulator', word_class: 'N' },
    { word: 'adulation', word_class: 'N' }
  ],
  evaluative: [
    { word: 'evaluate', word_class: 'V' },
    { word: 'evaluator', word_class: 'N' },
    { word: 'evaluable', word_class: 'AJ' },
    { word: 'evaluation', word_class: 'N' },
    { word: 'evaluative', word_class: 'AJ' }
  ],
  leaving: [
    { word: 'leave', word_class: 'N' },
    { word: 'leave', word_class: 'V' },
    { word: 'leaver', word_class: 'N' },
    { word: 'leaved', word_class: 'AJ' },
    { word: 'leaving', word_class: 'N' }
  ],
  maximisation: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  incredulous: [
    { word: 'incredulity', word_class: 'N' },
    { word: 'incredulous', word_class: 'AJ' },
    { word: 'incredulously', word_class: 'AV' }
  ],
  fresh: [
    { word: 'fresh', word_class: 'AJ' },
    { word: 'fresh', word_class: 'AV' },
    { word: 'freshness', word_class: 'N' }
  ],
  cataclysm: [
    { word: 'cataclysm', word_class: 'N' },
    { word: 'cataclysmal', word_class: 'AJ' },
    { word: 'cataclysmic', word_class: 'AJ' }
  ],
  journalise: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  durable: [
    { word: 'durable', word_class: 'AJ' },
    { word: 'durables', word_class: 'N' },
    { word: 'durably', word_class: 'AV' },
    { word: 'durability', word_class: 'N' }
  ],
  prodigally: [
    { word: 'prodigal', word_class: 'N' },
    { word: 'prodigal', word_class: 'AJ' },
    { word: 'prodigality', word_class: 'N' },
    { word: 'prodigally', word_class: 'AV' }
  ],
  surrounded: [
    { word: 'surround', word_class: 'N' },
    { word: 'surround', word_class: 'V' },
    { word: 'surround', word_class: 'AJ' },
    { word: 'surrounded', word_class: 'AJ' },
    { word: 'surrounding', word_class: 'N' },
    { word: 'surrounding', word_class: 'AJ' },
    { word: 'surroundings', word_class: 'N' }
  ],
  archetypal: [
    { word: 'archetype', word_class: 'N' },
    { word: 'archetypal', word_class: 'AJ' },
    { word: 'archetypic', word_class: 'AJ' },
    { word: 'archetypical', word_class: 'AJ' }
  ],
  creeps: [
    { word: 'creep', word_class: 'N' },
    { word: 'creep', word_class: 'V' },
    { word: 'creeps', word_class: 'N' },
    { word: 'creeper', word_class: 'N' },
    { word: 'creeping', word_class: 'N' },
    { word: 'creeping', word_class: 'AJ' }
  ],
  capitate: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  involute: [
    { word: 'involute', word_class: 'V' },
    { word: 'involute', word_class: 'AJ' },
    { word: 'involution', word_class: 'N' }
  ],
  loosened: [
    { word: 'loosen', word_class: 'V' },
    { word: 'loosened', word_class: 'AJ' },
    { word: 'loosening', word_class: 'N' }
  ],
  temporal: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  cyclical: [
    { word: 'cycle', word_class: 'N' },
    { word: 'cycle', word_class: 'V' },
    { word: 'cyclic', word_class: 'AJ' },
    { word: 'cycling', word_class: 'N' },
    { word: 'cyclist', word_class: 'N' },
    { word: 'cycling', word_class: 'AJ' },
    { word: 'cyclable', word_class: 'AJ' },
    { word: 'cyclical', word_class: 'AJ' },
    { word: 'cyclicity', word_class: 'N' }
  ],
  asphaltic: [
    { word: 'asphalt', word_class: 'N' },
    { word: 'asphalt', word_class: 'V' },
    { word: 'asphalt', word_class: 'AJ' },
    { word: 'asphaltic', word_class: 'AJ' }
  ],
  compare: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  persistence: [
    { word: 'persist', word_class: 'V' },
    { word: 'persistence', word_class: 'N' },
    { word: 'persistent', word_class: 'AJ' },
    { word: 'persistency', word_class: 'N' },
    { word: 'persisting', word_class: 'AJ' },
    { word: 'persistently', word_class: 'AV' }
  ],
  flaw: [{ word: 'flaw', word_class: 'N' }, { word: 'flaw', word_class: 'V' }, { word: 'flawed', word_class: 'AJ' }],
  disjunct: [
    { word: 'disjoin', word_class: 'V' },
    { word: 'disjunct', word_class: 'N' },
    { word: 'disjunct', word_class: 'AJ' },
    { word: 'disjoined', word_class: 'AJ' },
    { word: 'disjunction', word_class: 'N' },
    { word: 'disjunctive', word_class: 'AJ' }
  ],
  publicization: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  behavior: [
    { word: 'behave', word_class: 'V' },
    { word: 'behavior', word_class: 'N' },
    { word: 'behavioral', word_class: 'AJ' },
    { word: 'behaviorism', word_class: 'N' }
  ],
  pulsating: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  satisfaction: [
    { word: 'satisfy', word_class: 'V' },
    { word: 'satisfied', word_class: 'AJ' },
    { word: 'satisfying', word_class: 'N' },
    { word: 'satisfying', word_class: 'AJ' },
    { word: 'satisfaction', word_class: 'N' },
    { word: 'satisfiable', word_class: 'AJ' }
  ],
  commandeer: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  benevolent: [
    { word: 'benevolence', word_class: 'N' },
    { word: 'benevolent', word_class: 'AJ' },
    { word: 'benevolently', word_class: 'AV' }
  ],
  faithful: [
    { word: 'faith', word_class: 'N' },
    { word: 'faithful', word_class: 'N' },
    { word: 'faithful', word_class: 'AJ' },
    { word: 'faithfulness', word_class: 'N' }
  ],
  liquidate: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  bloat: [
    { word: 'bloat', word_class: 'N' },
    { word: 'bloat', word_class: 'V' },
    { word: 'bloated', word_class: 'AJ' }
  ],
  walloping: [
    { word: 'wallop', word_class: 'N' },
    { word: 'wallop', word_class: 'V' },
    { word: 'walloping', word_class: 'N' },
    { word: 'walloping', word_class: 'AJ' }
  ],
  unenlightenment: [
    { word: 'unenlightened', word_class: 'AJ' },
    { word: 'unenlightening', word_class: 'AJ' },
    { word: 'unenlightenment', word_class: 'N' }
  ],
  repressing: [
    { word: 'repress', word_class: 'N' },
    { word: 'repress', word_class: 'V' },
    { word: 'repressed', word_class: 'AJ' },
    { word: 'repression', word_class: 'N' },
    { word: 'repressing', word_class: 'AJ' },
    { word: 'repressive', word_class: 'AJ' }
  ],
  earliness: [
    { word: 'early', word_class: 'AJ' },
    { word: 'early', word_class: 'AV' },
    { word: 'earliness', word_class: 'N' }
  ],
  appetizer: [
    { word: 'appetence', word_class: 'N' },
    { word: 'appetency', word_class: 'N' },
    { word: 'appetent', word_class: 'AJ' },
    { word: 'appetizer', word_class: 'N' },
    { word: 'appetizing', word_class: 'AJ' }
  ],
  imposing: [
    { word: 'impose', word_class: 'N' },
    { word: 'impose', word_class: 'V' },
    { word: 'imposed', word_class: 'AJ' },
    { word: 'imposing', word_class: 'AJ' },
    { word: 'imposition', word_class: 'N' }
  ],
  canvassing: [
    { word: 'canvass', word_class: 'N' },
    { word: 'canvass', word_class: 'V' },
    { word: 'canvasser', word_class: 'N' },
    { word: 'canvassing', word_class: 'N' }
  ],
  integral: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  redeemer: [
    { word: 'redeem', word_class: 'V' },
    { word: 'redeemer', word_class: 'N' },
    { word: 'redeemed', word_class: 'AJ' },
    { word: 'redeeming', word_class: 'AJ' },
    { word: 'redemption', word_class: 'N' },
    { word: 'redeemable', word_class: 'AJ' },
    { word: 'redemptive', word_class: 'AJ' },
    { word: 'redemptional', word_class: 'AJ' }
  ],
  inadequately: [
    { word: 'inadequate', word_class: 'AJ' },
    { word: 'inadequately', word_class: 'AV' },
    { word: 'inadequateness', word_class: 'N' }
  ],
  scull: [
    { word: 'scull', word_class: 'N' },
    { word: 'scull', word_class: 'V' },
    { word: 'sculler', word_class: 'N' },
    { word: 'sculling', word_class: 'N' },
    { word: 'scullion', word_class: 'N' }
  ],
  applied: [
    { word: 'apply', word_class: 'V' },
    { word: 'applier', word_class: 'N' },
    { word: 'applied', word_class: 'AJ' },
    { word: 'applicant', word_class: 'N' },
    { word: 'appliance', word_class: 'N' },
    { word: 'applicator', word_class: 'N' },
    { word: 'applicable', word_class: 'AJ' },
    { word: 'application', word_class: 'N' },
    { word: 'applicative', word_class: 'AJ' },
    { word: 'applicability', word_class: 'N' }
  ],
  contiguousness: [
    { word: 'contiguity', word_class: 'N' },
    { word: 'contiguous', word_class: 'AJ' },
    { word: 'contiguousness', word_class: 'N' }
  ],
  craps: [{ word: 'crap', word_class: 'N' }, { word: 'crap', word_class: 'V' }, { word: 'craps', word_class: 'N' }],
  craven: [
    { word: 'craven', word_class: 'N' },
    { word: 'craven', word_class: 'AJ' },
    { word: 'cravenness', word_class: 'N' }
  ],
  laundering: [
    { word: 'launder', word_class: 'V' },
    { word: 'laundry', word_class: 'N' },
    { word: 'laundering', word_class: 'N' }
  ],
  gauntlets: [
    { word: 'gauntlet', word_class: 'N' },
    { word: 'gauntlets', word_class: 'N' },
    { word: 'gauntleted', word_class: 'AJ' }
  ],
  transvestite: [
    { word: 'transvestite', word_class: 'N' },
    { word: 'transvestite', word_class: 'AJ' },
    { word: 'transvestitism', word_class: 'N' }
  ],
  unfitness: [
    { word: 'unfit', word_class: 'V' },
    { word: 'unfit', word_class: 'AJ' },
    { word: 'unfitness', word_class: 'N' },
    { word: 'unfitting', word_class: 'AJ' }
  ],
  fathom: [
    { word: 'fathom', word_class: 'N' },
    { word: 'fathom', word_class: 'V' },
    { word: 'fathomable', word_class: 'AJ' }
  ],
  dicotyledonous: [
    { word: 'dicotyledon', word_class: 'N' },
    { word: 'dicotyledones', word_class: 'N' },
    { word: 'dicotyledonous', word_class: 'AJ' }
  ],
  abundantly: [
    { word: 'abound', word_class: 'V' },
    { word: 'abundance', word_class: 'N' },
    { word: 'abundant', word_class: 'AJ' },
    { word: 'abounding', word_class: 'AJ' },
    { word: 'abundantly', word_class: 'AV' }
  ],
  vomit: [
    { word: 'vomit', word_class: 'N' },
    { word: 'vomit', word_class: 'V' },
    { word: 'vomiting', word_class: 'N' },
    { word: 'vomitive', word_class: 'N' },
    { word: 'vomiturition', word_class: 'N' }
  ],
  anglican: [
    { word: 'anglican', word_class: 'N' },
    { word: 'anglican', word_class: 'AJ' },
    { word: 'anglicanism', word_class: 'N' }
  ],
  lamentably: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  breaking: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  bafflement: [
    { word: 'baffle', word_class: 'N' },
    { word: 'baffle', word_class: 'V' },
    { word: 'baffled', word_class: 'N' },
    { word: 'baffled', word_class: 'AJ' },
    { word: 'baffling', word_class: 'AJ' },
    { word: 'bafflement', word_class: 'N' }
  ],
  fingering: [
    { word: 'finger', word_class: 'N' },
    { word: 'finger', word_class: 'V' },
    { word: 'fingered', word_class: 'AJ' },
    { word: 'fingering', word_class: 'N' }
  ],
  meander: [
    { word: 'meander', word_class: 'N' },
    { word: 'meander', word_class: 'V' },
    { word: 'meandering', word_class: 'AJ' }
  ],
  northwards: [
    { word: 'northward', word_class: 'AJ' },
    { word: 'northward', word_class: 'AV' },
    { word: 'northwards', word_class: 'AV' }
  ],
  situation: [
    { word: 'situate', word_class: 'V' },
    { word: 'situated', word_class: 'AJ' },
    { word: 'situating', word_class: 'N' },
    { word: 'situation', word_class: 'N' }
  ],
  synonymously: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  deglutition: [
    { word: 'swallow', word_class: 'N' },
    { word: 'swallow', word_class: 'V' },
    { word: 'swallowed', word_class: 'AJ' },
    { word: 'deglutition', word_class: 'N' }
  ],
  adaptor: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  pastoral: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  genotypic: [
    { word: 'genotype', word_class: 'N' },
    { word: 'genotypic', word_class: 'AJ' },
    { word: 'genotypical', word_class: 'AJ' }
  ],
  caramelize: [
    { word: 'caramel', word_class: 'N' },
    { word: 'caramel', word_class: 'AJ' },
    { word: 'caramelize', word_class: 'V' },
    { word: 'caramelization', word_class: 'N' }
  ],
  asynchronism: [
    { word: 'asynchrony', word_class: 'N' },
    { word: 'asynchronism', word_class: 'N' },
    { word: 'asynchronous', word_class: 'AJ' }
  ],
  harmfulness: [
    { word: 'harm', word_class: 'N' },
    { word: 'harm', word_class: 'V' },
    { word: 'harmed', word_class: 'AJ' },
    { word: 'harmful', word_class: 'AJ' },
    { word: 'harmfulness', word_class: 'N' }
  ],
  greasiness: [
    { word: 'grease', word_class: 'N' },
    { word: 'grease', word_class: 'V' },
    { word: 'greaser', word_class: 'N' },
    { word: 'greasy', word_class: 'AJ' },
    { word: 'greased', word_class: 'AJ' },
    { word: 'greasiness', word_class: 'N' }
  ],
  clutch: [
    { word: 'clutch', word_class: 'N' },
    { word: 'clutch', word_class: 'V' },
    { word: 'clutches', word_class: 'N' }
  ],
  cordite: [
    { word: 'cord', word_class: 'N' },
    { word: 'cord', word_class: 'V' },
    { word: 'cords', word_class: 'N' },
    { word: 'corded', word_class: 'AJ' },
    { word: 'cording', word_class: 'N' },
    { word: 'cordite', word_class: 'N' }
  ],
  commune: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  etiolate: [
    { word: 'etiolate', word_class: 'V' },
    { word: 'etiolate', word_class: 'AJ' },
    { word: 'etiolated', word_class: 'AJ' },
    { word: 'etiolation', word_class: 'N' }
  ],
  obscurely: [
    { word: 'obscure', word_class: 'V' },
    { word: 'obscure', word_class: 'AJ' },
    { word: 'obscurity', word_class: 'N' },
    { word: 'obscurant', word_class: 'AJ' },
    { word: 'obscurely', word_class: 'AV' },
    { word: 'obscuration', word_class: 'N' },
    { word: 'obscureness', word_class: 'N' }
  ],
  cozenage: [
    { word: 'cozen', word_class: 'N' },
    { word: 'cozen', word_class: 'V' },
    { word: 'cozenage', word_class: 'N' }
  ],
  emendate: [
    { word: 'emend', word_class: 'V' },
    { word: 'emendate', word_class: 'V' },
    { word: 'emended', word_class: 'AJ' },
    { word: 'emendment', word_class: 'N' },
    { word: 'emendable', word_class: 'AJ' },
    { word: 'emendation', word_class: 'N' }
  ],
  tasseled: [
    { word: 'tassel', word_class: 'N' },
    { word: 'tassel', word_class: 'V' },
    { word: 'tasseled', word_class: 'AJ' },
    { word: 'tasselled', word_class: 'AJ' }
  ],
  inherit: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  competition: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  instructed: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  oblation: [
    { word: 'oblate', word_class: 'N' },
    { word: 'oblate', word_class: 'AJ' },
    { word: 'oblation', word_class: 'N' },
    { word: 'oblateness', word_class: 'N' }
  ],
  incrust: [
    { word: 'encrust', word_class: 'V' },
    { word: 'incrust', word_class: 'V' },
    { word: 'encrusted', word_class: 'AJ' },
    { word: 'encrustation', word_class: 'N' },
    { word: 'incrustation', word_class: 'N' }
  ],
  contusion: [
    { word: 'contuse', word_class: 'V' },
    { word: 'contused', word_class: 'AJ' },
    { word: 'contusion', word_class: 'N' },
    { word: 'contusioned', word_class: 'AJ' }
  ],
  reconciliation: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  instinctive: [
    { word: 'instinct', word_class: 'N' },
    { word: 'instinct', word_class: 'AJ' },
    { word: 'instinctive', word_class: 'AJ' },
    { word: 'instinctively', word_class: 'AV' }
  ],
  ballad: [
    { word: 'ballad', word_class: 'N' },
    { word: 'ballade', word_class: 'N' },
    { word: 'balladeer', word_class: 'N' }
  ],
  seaward: [
    { word: 'seaward', word_class: 'AJ' },
    { word: 'seaward', word_class: 'AV' },
    { word: 'seawards', word_class: 'AV' }
  ],
  comically: [
    { word: 'comic', word_class: 'N' },
    { word: 'comic', word_class: 'AJ' },
    { word: 'comical', word_class: 'AJ' },
    { word: 'comically', word_class: 'AV' }
  ],
  demagnetize: [
    { word: 'demagnetise', word_class: 'V' },
    { word: 'demagnetize', word_class: 'V' },
    { word: 'demagnetisation', word_class: 'N' },
    { word: 'demagnetization', word_class: 'N' }
  ],
  memorise: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  manfulness: [
    { word: 'man', word_class: 'V' },
    { word: 'man', word_class: 'N' },
    { word: 'manor', word_class: 'N' },
    { word: 'manned', word_class: 'AJ' },
    { word: 'manful', word_class: 'AJ' },
    { word: 'manfulness', word_class: 'N' }
  ],
  zigzag: [
    { word: 'zigzag', word_class: 'N' },
    { word: 'zigzag', word_class: 'V' },
    { word: 'zigzag', word_class: 'AJ' },
    { word: 'zigzag', word_class: 'AV' }
  ],
  autographic: [
    { word: 'autograph', word_class: 'N' },
    { word: 'autograph', word_class: 'V' },
    { word: 'autographed', word_class: 'AJ' },
    { word: 'autographic', word_class: 'AJ' }
  ],
  dislodgment: [
    { word: 'dislodge', word_class: 'V' },
    { word: 'dislodgment', word_class: 'N' },
    { word: 'dislodgement', word_class: 'N' }
  ],
  accompanying: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  implosion: [
    { word: 'implode', word_class: 'V' },
    { word: 'implosion', word_class: 'N' },
    { word: 'implosive', word_class: 'AJ' }
  ],
  slave: [
    { word: 'slave', word_class: 'N' },
    { word: 'slave', word_class: 'V' },
    { word: 'slave', word_class: 'AJ' },
    { word: 'slavery', word_class: 'N' },
    { word: 'slavery', word_class: 'AJ' }
  ],
  ministering: [
    { word: 'minister', word_class: 'V' },
    { word: 'minister', word_class: 'N' },
    { word: 'ministry', word_class: 'N' },
    { word: 'ministrant', word_class: 'N' },
    { word: 'ministrant', word_class: 'AJ' },
    { word: 'ministering', word_class: 'AJ' },
    { word: 'ministration', word_class: 'N' }
  ],
  asymptotically: [
    { word: 'asymptote', word_class: 'N' },
    { word: 'asymptotic', word_class: 'AJ' },
    { word: 'asymptotically', word_class: 'AV' }
  ],
  methylate: [
    { word: 'methyl', word_class: 'N' },
    { word: 'methylate', word_class: 'V' },
    { word: 'methylated', word_class: 'AJ' },
    { word: 'methylation', word_class: 'N' }
  ],
  flats: [
    { word: 'flat', word_class: 'N' },
    { word: 'flat', word_class: 'AJ' },
    { word: 'flat', word_class: 'AV' },
    { word: 'flats', word_class: 'N' },
    { word: 'flatness', word_class: 'N' }
  ],
  mesmerised: [
    { word: 'mesmerise', word_class: 'V' },
    { word: 'mesmerist', word_class: 'N' },
    { word: 'mesmerize', word_class: 'V' },
    { word: 'mesmeric', word_class: 'AJ' },
    { word: 'mesmerism', word_class: 'N' },
    { word: 'mesmerised', word_class: 'AJ' },
    { word: 'mesmerized', word_class: 'AJ' },
    { word: 'mesmerizing', word_class: 'AJ' },
    { word: 'mesmerisation', word_class: 'N' },
    { word: 'mesmerization', word_class: 'N' }
  ],
  discomycetous: [
    { word: 'discomycete', word_class: 'N' },
    { word: 'discomycetes', word_class: 'N' },
    { word: 'discomycetous', word_class: 'AJ' }
  ],
  criticism: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  energy: [
    { word: 'energy', word_class: 'N' },
    { word: 'energize', word_class: 'V' },
    { word: 'energetic', word_class: 'AJ' },
    { word: 'energizing', word_class: 'N' },
    { word: 'energizing', word_class: 'AJ' },
    { word: 'energization', word_class: 'N' },
    { word: 'energetically', word_class: 'AV' }
  ],
  sweats: [
    { word: 'sweat', word_class: 'N' },
    { word: 'sweat', word_class: 'V' },
    { word: 'sweats', word_class: 'N' },
    { word: 'sweater', word_class: 'N' },
    { word: 'sweating', word_class: 'N' },
    { word: 'sweating', word_class: 'AJ' }
  ],
  disbelief: [
    { word: 'disbelief', word_class: 'N' },
    { word: 'disbelieve', word_class: 'V' },
    { word: 'disbeliever', word_class: 'N' },
    { word: 'disbelieving', word_class: 'AJ' }
  ],
  epicycle: [
    { word: 'epicycle', word_class: 'N' },
    { word: 'epicyclic', word_class: 'AJ' },
    { word: 'epicyclical', word_class: 'AJ' }
  ],
  appendage: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  reconditioned: [
    { word: 'recondite', word_class: 'AJ' },
    { word: 'recondition', word_class: 'V' },
    { word: 'reconditeness', word_class: 'N' },
    { word: 'reconditioned', word_class: 'AJ' }
  ],
  fossilisation: [
    { word: 'fossil', word_class: 'N' },
    { word: 'fossilise', word_class: 'V' },
    { word: 'fossilize', word_class: 'V' },
    { word: 'fossilist', word_class: 'N' },
    { word: 'fossilized', word_class: 'AJ' },
    { word: 'fossilisation', word_class: 'N' },
    { word: 'fossilization', word_class: 'N' }
  ],
  ablate: [
    { word: 'ablate', word_class: 'V' },
    { word: 'ablated', word_class: 'AJ' },
    { word: 'ablation', word_class: 'N' }
  ],
  specked: [
    { word: 'speck', word_class: 'N' },
    { word: 'speck', word_class: 'V' },
    { word: 'specked', word_class: 'AJ' }
  ],
  evaluate: [
    { word: 'evaluate', word_class: 'V' },
    { word: 'evaluator', word_class: 'N' },
    { word: 'evaluable', word_class: 'AJ' },
    { word: 'evaluation', word_class: 'N' },
    { word: 'evaluative', word_class: 'AJ' }
  ],
  circulant: [
    { word: 'circulant', word_class: 'N' },
    { word: 'circulate', word_class: 'V' },
    { word: 'circulation', word_class: 'N' },
    { word: 'circulating', word_class: 'AJ' },
    { word: 'circulative', word_class: 'AJ' }
  ],
  queen: [{ word: 'queen', word_class: 'N' }, { word: 'queen', word_class: 'V' }, { word: 'queens', word_class: 'N' }],
  bubbling: [
    { word: 'bubble', word_class: 'N' },
    { word: 'bubble', word_class: 'V' },
    { word: 'bubbly', word_class: 'N' },
    { word: 'bubbler', word_class: 'N' },
    { word: 'bubbly', word_class: 'AJ' },
    { word: 'bubbling', word_class: 'AJ' }
  ],
  implanted: [
    { word: 'implant', word_class: 'N' },
    { word: 'implant', word_class: 'V' },
    { word: 'implanted', word_class: 'AJ' },
    { word: 'implantation', word_class: 'N' }
  ],
  breather: [
    { word: 'breath', word_class: 'N' },
    { word: 'breathe', word_class: 'V' },
    { word: 'breather', word_class: 'N' },
    { word: 'breathed', word_class: 'AJ' },
    { word: 'breathing', word_class: 'N' },
    { word: 'breathing', word_class: 'AJ' }
  ],
  immortality: [
    { word: 'immortal', word_class: 'N' },
    { word: 'immortal', word_class: 'AJ' },
    { word: 'immortalise', word_class: 'V' },
    { word: 'immortalize', word_class: 'V' },
    { word: 'immortality', word_class: 'N' },
    { word: 'immortalisation', word_class: 'N' },
    { word: 'immortalization', word_class: 'N' }
  ],
  draftee: [
    { word: 'draft', word_class: 'N' },
    { word: 'draft', word_class: 'V' },
    { word: 'draftee', word_class: 'N' },
    { word: 'drafter', word_class: 'N' },
    { word: 'drafting', word_class: 'N' }
  ],
  adduce: [
    { word: 'adduce', word_class: 'V' },
    { word: 'adduct', word_class: 'V' },
    { word: 'adducing', word_class: 'N' },
    { word: 'adducent', word_class: 'AJ' },
    { word: 'adduction', word_class: 'N' },
    { word: 'adducting', word_class: 'AJ' },
    { word: 'adductive', word_class: 'AJ' }
  ],
  arboreal: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  plucked: [
    { word: 'pluck', word_class: 'N' },
    { word: 'pluck', word_class: 'V' },
    { word: 'plucked', word_class: 'AJ' }
  ],
  ignorant: [
    { word: 'ignore', word_class: 'V' },
    { word: 'ignorant', word_class: 'N' },
    { word: 'ignored', word_class: 'AJ' },
    { word: 'ignorance', word_class: 'N' },
    { word: 'ignorant', word_class: 'AJ' },
    { word: 'ignorantness', word_class: 'N' }
  ],
  wheel: [
    { word: 'wheel', word_class: 'N' },
    { word: 'wheel', word_class: 'V' },
    { word: 'wheels', word_class: 'N' },
    { word: 'wheeler', word_class: 'N' },
    { word: 'wheeled', word_class: 'AJ' },
    { word: 'wheeling', word_class: 'N' },
    { word: 'wheeling', word_class: 'AJ' }
  ],
  ax: [
    { word: 'ax', word_class: 'N' },
    { word: 'ax', word_class: 'V' },
    { word: 'axe', word_class: 'N' },
    { word: 'axe', word_class: 'V' }
  ],
  hyposensitise: [
    { word: 'hyposensitise', word_class: 'V' },
    { word: 'hyposensitize', word_class: 'V' },
    { word: 'hyposensitisation', word_class: 'N' },
    { word: 'hyposensitization', word_class: 'N' }
  ],
  existentialism: [
    { word: 'exist', word_class: 'V' },
    { word: 'existing', word_class: 'V' },
    { word: 'existence', word_class: 'N' },
    { word: 'existent', word_class: 'AJ' },
    { word: 'existing', word_class: 'AJ' },
    { word: 'existential', word_class: 'AJ' },
    { word: 'existentialism', word_class: 'N' }
  ],
  bemusement: [
    { word: 'bemuse', word_class: 'V' },
    { word: 'bemused', word_class: 'AJ' },
    { word: 'bemusement', word_class: 'N' }
  ],
  equipoised: [
    { word: 'equipoise', word_class: 'N' },
    { word: 'equipoise', word_class: 'V' },
    { word: 'equipoised', word_class: 'AJ' }
  ],
  shirting: [
    { word: 'shirt', word_class: 'N' },
    { word: 'shirt', word_class: 'V' },
    { word: 'shirting', word_class: 'N' }
  ],
  discredited: [
    { word: 'discredit', word_class: 'N' },
    { word: 'discredit', word_class: 'V' },
    { word: 'discredited', word_class: 'AJ' },
    { word: 'discreditable', word_class: 'AJ' },
    { word: 'discreditably', word_class: 'AV' }
  ],
  slum: [{ word: 'slum', word_class: 'N' }, { word: 'slum', word_class: 'V' }, { word: 'slum', word_class: 'AJ' }],
  unsupportive: [
    { word: 'unsupported', word_class: 'AJ' },
    { word: 'unsupportive', word_class: 'AJ' },
    { word: 'unsupportable', word_class: 'AJ' }
  ],
  vindictiveness: [
    { word: 'vindictive', word_class: 'AJ' },
    { word: 'vindictively', word_class: 'AV' },
    { word: 'vindictiveness', word_class: 'N' }
  ],
  strategical: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  lockman: [
    { word: 'lock', word_class: 'N' },
    { word: 'lock', word_class: 'V' },
    { word: 'locke', word_class: 'N' },
    { word: 'locker', word_class: 'N' },
    { word: 'locked', word_class: 'AJ' },
    { word: 'locking', word_class: 'N' },
    { word: 'lockman', word_class: 'N' }
  ],
  preferentially: [
    { word: 'prefer', word_class: 'V' },
    { word: 'preference', word_class: 'N' },
    { word: 'preferment', word_class: 'N' },
    { word: 'preferred', word_class: 'AJ' },
    { word: 'preferable', word_class: 'AJ' },
    { word: 'preferably', word_class: 'AV' },
    { word: 'preferability', word_class: 'N' },
    { word: 'preferential', word_class: 'AJ' },
    { word: 'preferability', word_class: 'AJ' },
    { word: 'preferentially', word_class: 'AV' }
  ],
  destruction: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  part: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  secularism: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  annihilative: [
    { word: 'annihilate', word_class: 'V' },
    { word: 'annihilated', word_class: 'AJ' },
    { word: 'annihilation', word_class: 'N' },
    { word: 'annihilating', word_class: 'AJ' },
    { word: 'annihilative', word_class: 'AJ' }
  ],
  vamper: [{ word: 'vamp', word_class: 'N' }, { word: 'vamp', word_class: 'V' }, { word: 'vamper', word_class: 'N' }],
  procurance: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  blueing: [
    { word: 'blue', word_class: 'N' },
    { word: 'blue', word_class: 'V' },
    { word: 'blue', word_class: 'AJ' },
    { word: 'blues', word_class: 'N' },
    { word: 'blueing', word_class: 'N' },
    { word: 'blueness', word_class: 'N' }
  ],
  permanent: [
    { word: 'permanent', word_class: 'N' },
    { word: 'permanence', word_class: 'N' },
    { word: 'permanency', word_class: 'N' },
    { word: 'permanent', word_class: 'AJ' },
    { word: 'permanently', word_class: 'AV' }
  ],
  parachutist: [
    { word: 'parachute', word_class: 'N' },
    { word: 'parachute', word_class: 'V' },
    { word: 'parachuter', word_class: 'N' },
    { word: 'parachuting', word_class: 'N' },
    { word: 'parachutist', word_class: 'N' }
  ],
  country: [
    { word: 'country', word_class: 'N' },
    { word: 'country', word_class: 'V' },
    { word: 'country', word_class: 'AJ' },
    { word: 'countrify', word_class: 'V' },
    { word: 'countrified', word_class: 'AJ' }
  ],
  address: [
    { word: 'address', word_class: 'N' },
    { word: 'address', word_class: 'V' },
    { word: 'addressee', word_class: 'N' },
    { word: 'addressed', word_class: 'AJ' },
    { word: 'addressable', word_class: 'AJ' }
  ],
  transcendency: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  sovietism: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  shredded: [
    { word: 'shred', word_class: 'N' },
    { word: 'shred', word_class: 'V' },
    { word: 'shredded', word_class: 'AJ' }
  ],
  retaliate: [
    { word: 'retaliate', word_class: 'V' },
    { word: 'retaliator', word_class: 'N' },
    { word: 'retaliation', word_class: 'N' },
    { word: 'retaliative', word_class: 'AJ' }
  ],
  neglect: [
    { word: 'neglect', word_class: 'N' },
    { word: 'neglect', word_class: 'V' },
    { word: 'neglected', word_class: 'AJ' },
    { word: 'neglectful', word_class: 'AJ' },
    { word: 'neglectfulness', word_class: 'N' }
  ],
  parabolise: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  pronominalization: [
    { word: 'pronominal', word_class: 'AJ' },
    { word: 'pronominalise', word_class: 'V' },
    { word: 'pronominalize', word_class: 'V' },
    { word: 'pronominalisation', word_class: 'N' },
    { word: 'pronominalization', word_class: 'N' }
  ],
  titter: [
    { word: 'titter', word_class: 'N' },
    { word: 'titter', word_class: 'V' },
    { word: 'tittering', word_class: 'AJ' }
  ],
  opalescence: [
    { word: 'opalesce', word_class: 'V' },
    { word: 'opalescence', word_class: 'N' },
    { word: 'opalescent', word_class: 'AJ' }
  ],
  peptonize: [
    { word: 'peptone', word_class: 'N' },
    { word: 'peptonise', word_class: 'V' },
    { word: 'peptonize', word_class: 'V' },
    { word: 'peptonisation', word_class: 'N' },
    { word: 'peptonization', word_class: 'N' }
  ],
  excrescence: [
    { word: 'excrescent', word_class: 'N' },
    { word: 'excrescence', word_class: 'N' },
    { word: 'excrescent', word_class: 'AJ' }
  ],
  fumbler: [
    { word: 'fumble', word_class: 'N' },
    { word: 'fumble', word_class: 'V' },
    { word: 'fumbler', word_class: 'N' },
    { word: 'fumbling', word_class: 'AJ' }
  ],
  bombast: [
    { word: 'bomb', word_class: 'N' },
    { word: 'bomb', word_class: 'V' },
    { word: 'bomber', word_class: 'N' },
    { word: 'bombast', word_class: 'N' },
    { word: 'bombing', word_class: 'N' },
    { word: 'bombastic', word_class: 'AJ' },
    { word: 'bombastically', word_class: 'AV' }
  ],
  wireless: [
    { word: 'wireless', word_class: 'N' },
    { word: 'wireless', word_class: 'V' },
    { word: 'wireless', word_class: 'AJ' }
  ],
  symbolisation: [
    { word: 'symbol', word_class: 'N' },
    { word: 'symbolic', word_class: 'AJ' },
    { word: 'symbolise', word_class: 'V' },
    { word: 'symbolism', word_class: 'N' },
    { word: 'symbolize', word_class: 'V' },
    { word: 'symbolist', word_class: 'N' },
    { word: 'symbolical', word_class: 'AJ' },
    { word: 'symbolizing', word_class: 'N' },
    { word: 'symbolically', word_class: 'AV' },
    { word: 'symbolisation', word_class: 'N' },
    { word: 'symbolization', word_class: 'N' }
  ],
  even: [
    { word: 'even', word_class: 'V' },
    { word: 'even', word_class: 'AJ' },
    { word: 'even', word_class: 'AV' },
    { word: 'evening', word_class: 'N' },
    { word: 'evenness', word_class: 'N' }
  ],
  dupe: [{ word: 'dupe', word_class: 'N' }, { word: 'dupe', word_class: 'V' }, { word: 'dupery', word_class: 'N' }],
  voluntarism: [
    { word: 'voluntary', word_class: 'N' },
    { word: 'voluntary', word_class: 'AJ' },
    { word: 'voluntarism', word_class: 'N' }
  ],
  interposal: [
    { word: 'interpose', word_class: 'V' },
    { word: 'interposal', word_class: 'N' },
    { word: 'interposition', word_class: 'N' }
  ],
  connotation: [
    { word: 'connote', word_class: 'V' },
    { word: 'connotation', word_class: 'N' },
    { word: 'connotative', word_class: 'AJ' },
    { word: 'connotational', word_class: 'AJ' }
  ],
  liberalism: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  inflammable: [
    { word: 'inflame', word_class: 'N' },
    { word: 'inflame', word_class: 'V' },
    { word: 'inflamed', word_class: 'AJ' },
    { word: 'inflaming', word_class: 'N' },
    { word: 'inflammable', word_class: 'AJ' },
    { word: 'inflammation', word_class: 'N' },
    { word: 'inflammatory', word_class: 'AJ' }
  ],
  gulp: [{ word: 'gulp', word_class: 'N' }, { word: 'gulp', word_class: 'V' }, { word: 'gulping', word_class: 'N' }],
  amen: [
    { word: 'amen', word_class: 'N' },
    { word: 'amenity', word_class: 'N' },
    { word: 'amenable', word_class: 'AJ' },
    { word: 'amenities', word_class: 'N' },
    { word: 'amenability', word_class: 'N' },
    { word: 'amenableness', word_class: 'N' }
  ],
  individualistically: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  scrutinise: [
    { word: 'scrutiny', word_class: 'N' },
    { word: 'scrutinise', word_class: 'V' },
    { word: 'scrutinize', word_class: 'V' },
    { word: 'scrutineer', word_class: 'N' },
    { word: 'scrutinise', word_class: 'AJ' },
    { word: 'scrutinize', word_class: 'AJ' }
  ],
  commensalism: [
    { word: 'commensal', word_class: 'N' },
    { word: 'commensal', word_class: 'AJ' },
    { word: 'commensalism', word_class: 'N' },
    { word: 'commensally', word_class: 'AV' }
  ],
  synchronal: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  seafaring: [
    { word: 'seafarer', word_class: 'N' },
    { word: 'seafaring', word_class: 'N' },
    { word: 'seafaring', word_class: 'AJ' }
  ],
  electric: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  synthetisation: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  withered: [
    { word: 'wither', word_class: 'V' },
    { word: 'withers', word_class: 'N' },
    { word: 'withered', word_class: 'AJ' },
    { word: 'withering', word_class: 'N' },
    { word: 'withering', word_class: 'AJ' }
  ],
  gobble: [
    { word: 'gobble', word_class: 'N' },
    { word: 'gobble', word_class: 'V' },
    { word: 'gobbler', word_class: 'N' }
  ],
  recognise: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  delicious: [
    { word: 'delicious', word_class: 'N' },
    { word: 'delicious', word_class: 'AJ' },
    { word: 'deliciously', word_class: 'AV' },
    { word: 'deliciousness', word_class: 'N' }
  ],
  expender: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  weapon: [
    { word: 'weapon', word_class: 'N' },
    { word: 'weaponry', word_class: 'N' },
    { word: 'weaponed', word_class: 'AJ' }
  ],
  calming: [
    { word: 'calm', word_class: 'N' },
    { word: 'calm', word_class: 'V' },
    { word: 'calm', word_class: 'AJ' },
    { word: 'calming', word_class: 'N' },
    { word: 'calming', word_class: 'AJ' },
    { word: 'calmness', word_class: 'N' }
  ],
  merger: [
    { word: 'merge', word_class: 'V' },
    { word: 'merger', word_class: 'N' },
    { word: 'merged', word_class: 'AJ' },
    { word: 'merging', word_class: 'N' },
    { word: 'mergence', word_class: 'N' },
    { word: 'merging', word_class: 'AJ' }
  ],
  movement: [
    { word: 'move', word_class: 'N' },
    { word: 'move', word_class: 'V' },
    { word: 'mover', word_class: 'N' },
    { word: 'moved', word_class: 'AJ' },
    { word: 'moving', word_class: 'N' },
    { word: 'moving', word_class: 'AJ' },
    { word: 'movement', word_class: 'N' }
  ],
  lustrous: [
    { word: 'luster', word_class: 'N' },
    { word: 'luster', word_class: 'V' },
    { word: 'lustrous', word_class: 'AJ' }
  ],
  vendee: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  outdoors: [
    { word: 'outdoor', word_class: 'AJ' },
    { word: 'outdoors', word_class: 'N' },
    { word: 'outdoors', word_class: 'AJ' },
    { word: 'outdoors', word_class: 'AV' }
  ],
  holographic: [
    { word: 'holograph', word_class: 'N' },
    { word: 'holograph', word_class: 'AJ' },
    { word: 'holographic', word_class: 'AJ' },
    { word: 'holographical', word_class: 'AJ' }
  ],
  malignity: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  down: [
    { word: 'down', word_class: 'N' },
    { word: 'down', word_class: 'V' },
    { word: 'down', word_class: 'AJ' },
    { word: 'down', word_class: 'AV' },
    { word: 'downer', word_class: 'N' },
    { word: 'downed', word_class: 'AJ' }
  ],
  deliverance: [
    { word: 'deliver', word_class: 'N' },
    { word: 'deliver', word_class: 'V' },
    { word: 'delivery', word_class: 'N' },
    { word: 'deliverer', word_class: 'N' },
    { word: 'delivery', word_class: 'AJ' },
    { word: 'deliverance', word_class: 'N' }
  ],
  imbibe: [
    { word: 'imbibe', word_class: 'V' },
    { word: 'imbiber', word_class: 'N' },
    { word: 'imbibing', word_class: 'N' },
    { word: 'imbibition', word_class: 'N' }
  ],
  assessor: [
    { word: 'assess', word_class: 'N' },
    { word: 'assess', word_class: 'V' },
    { word: 'assessor', word_class: 'N' },
    { word: 'assessment', word_class: 'N' },
    { word: 'assessable', word_class: 'AJ' }
  ],
  palate: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  sprouted: [
    { word: 'sprout', word_class: 'N' },
    { word: 'sprout', word_class: 'V' },
    { word: 'sprouted', word_class: 'AJ' },
    { word: 'sprouting', word_class: 'N' }
  ],
  embedded: [
    { word: 'embed', word_class: 'V' },
    { word: 'imbed', word_class: 'N' },
    { word: 'imbed', word_class: 'V' },
    { word: 'embedded', word_class: 'AJ' },
    { word: 'embedment', word_class: 'N' },
    { word: 'imbedment', word_class: 'N' }
  ],
  transcend: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  compatible: [
    { word: 'compatible', word_class: 'N' },
    { word: 'compatible', word_class: 'AJ' },
    { word: 'compatibly', word_class: 'AV' },
    { word: 'compatibility', word_class: 'N' }
  ],
  err: [{ word: 'err', word_class: 'V' }, { word: 'error', word_class: 'N' }, { word: 'erratum', word_class: 'N' }],
  distrain: [
    { word: 'distrain', word_class: 'V' },
    { word: 'distraint', word_class: 'N' },
    { word: 'distrainment', word_class: 'N' }
  ],
  manageableness: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  indulgently: [
    { word: 'indulge', word_class: 'V' },
    { word: 'indulging', word_class: 'N' },
    { word: 'indulgence', word_class: 'N' },
    { word: 'indulgent', word_class: 'AJ' },
    { word: 'indulgently', word_class: 'AV' }
  ],
  changed: [
    { word: 'chang', word_class: 'N' },
    { word: 'change', word_class: 'N' },
    { word: 'change', word_class: 'V' },
    { word: 'changer', word_class: 'N' },
    { word: 'changed', word_class: 'AJ' },
    { word: 'changing', word_class: 'AJ' },
    { word: 'changeful', word_class: 'AJ' },
    { word: 'changefulness', word_class: 'N' }
  ],
  hurtle: [
    { word: 'hurtle', word_class: 'N' },
    { word: 'hurtle', word_class: 'V' },
    { word: 'hurtling', word_class: 'AJ' }
  ],
  names: [
    { word: 'name', word_class: 'N' },
    { word: 'name', word_class: 'V' },
    { word: 'names', word_class: 'N' },
    { word: 'named', word_class: 'AJ' },
    { word: 'naming', word_class: 'N' },
    { word: 'namely', word_class: 'AV' },
    { word: 'naming', word_class: 'AJ' }
  ],
  ingraining: [
    { word: 'ingrain', word_class: 'V' },
    { word: 'ingrained', word_class: 'AJ' },
    { word: 'ingraining', word_class: 'N' }
  ],
  tempestuous: [
    { word: 'tempest', word_class: 'N' },
    { word: 'tempestuous', word_class: 'AJ' },
    { word: 'tempestuousness', word_class: 'N' }
  ],
  unintelligible: [
    { word: 'unintelligent', word_class: 'AJ' },
    { word: 'unintelligible', word_class: 'AJ' },
    { word: 'unintelligibly', word_class: 'AV' },
    { word: 'unintelligently', word_class: 'AV' },
    { word: 'unintelligibility', word_class: 'N' }
  ],
  protestant: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  prodigious: [
    { word: 'prodigy', word_class: 'N' },
    { word: 'prodigious', word_class: 'AJ' },
    { word: 'prodigiously', word_class: 'AV' }
  ],
  pacifism: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  economize: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  bushwhacking: [
    { word: 'bushwhack', word_class: 'V' },
    { word: 'bushwhacker', word_class: 'N' },
    { word: 'bushwhacking', word_class: 'AJ' }
  ],
  intend: [
    { word: 'intend', word_class: 'V' },
    { word: 'intent', word_class: 'N' },
    { word: 'intent', word_class: 'V' },
    { word: 'intent', word_class: 'AJ' },
    { word: 'intention', word_class: 'N' },
    { word: 'intended', word_class: 'AJ' },
    { word: 'intently', word_class: 'AV' },
    { word: 'intentness', word_class: 'N' },
    { word: 'intentional', word_class: 'AJ' }
  ],
  frequence: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  justifiable: [
    { word: 'justify', word_class: 'V' },
    { word: 'justifier', word_class: 'N' },
    { word: 'justified', word_class: 'AJ' },
    { word: 'justifiable', word_class: 'AJ' },
    { word: 'justifiably', word_class: 'AV' },
    { word: 'justification', word_class: 'N' },
    { word: 'justificative', word_class: 'AJ' }
  ],
  embody: [
    { word: 'embody', word_class: 'V' },
    { word: 'embodied', word_class: 'AJ' },
    { word: 'embodiment', word_class: 'N' }
  ],
  available: [
    { word: 'avail', word_class: 'N' },
    { word: 'avail', word_class: 'V' },
    { word: 'available', word_class: 'AJ' },
    { word: 'availability', word_class: 'N' },
    { word: 'availableness', word_class: 'N' }
  ],
  mask: [
    { word: 'mask', word_class: 'N' },
    { word: 'mask', word_class: 'V' },
    { word: 'masked', word_class: 'AJ' },
    { word: 'masking', word_class: 'N' },
    { word: 'masking', word_class: 'AJ' }
  ],
  scowling: [
    { word: 'scowl', word_class: 'N' },
    { word: 'scowl', word_class: 'V' },
    { word: 'scowling', word_class: 'AJ' }
  ],
  introspectiveness: [
    { word: 'introspect', word_class: 'V' },
    { word: 'introspection', word_class: 'N' },
    { word: 'introspective', word_class: 'AJ' },
    { word: 'introspectiveness', word_class: 'N' }
  ],
  smoulder: [
    { word: 'smoulder', word_class: 'N' },
    { word: 'smoulder', word_class: 'V' },
    { word: 'smouldering', word_class: 'AJ' }
  ],
  procurator: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  bargaining: [
    { word: 'bargain', word_class: 'N' },
    { word: 'bargain', word_class: 'V' },
    { word: 'bargain', word_class: 'AJ' },
    { word: 'bargainer', word_class: 'N' },
    { word: 'bargaining', word_class: 'N' }
  ],
  adverbially: [
    { word: 'adverbial', word_class: 'N' },
    { word: 'adverbial', word_class: 'AJ' },
    { word: 'adverbially', word_class: 'AV' }
  ],
  infielder: [
    { word: 'infield', word_class: 'N' },
    { word: 'infield', word_class: 'V' },
    { word: 'infielder', word_class: 'N' }
  ],
  misappropriated: [
    { word: 'misappropriate', word_class: 'V' },
    { word: 'misappropriated', word_class: 'AJ' },
    { word: 'misappropriation', word_class: 'N' }
  ],
  opened: [
    { word: 'open', word_class: 'N' },
    { word: 'open', word_class: 'V' },
    { word: 'open', word_class: 'AJ' },
    { word: 'opener', word_class: 'N' },
    { word: 'opening', word_class: 'N' },
    { word: 'opened', word_class: 'AJ' },
    { word: 'opening', word_class: 'AJ' },
    { word: 'openness', word_class: 'N' }
  ],
  thumping: [
    { word: 'thump', word_class: 'N' },
    { word: 'thump', word_class: 'V' },
    { word: 'thumping', word_class: 'N' },
    { word: 'thumping', word_class: 'AJ' }
  ],
  unbendable: [
    { word: 'unbend', word_class: 'V' },
    { word: 'unbending', word_class: 'AJ' },
    { word: 'unbendable', word_class: 'AJ' }
  ],
  grievance: [
    { word: 'grief', word_class: 'N' },
    { word: 'grieve', word_class: 'V' },
    { word: 'griever', word_class: 'N' },
    { word: 'grievance', word_class: 'N' },
    { word: 'grieving', word_class: 'AJ' },
    { word: 'grievous', word_class: 'AJ' }
  ],
  wrangling: [
    { word: 'wrangle', word_class: 'N' },
    { word: 'wrangle', word_class: 'V' },
    { word: 'wrangler', word_class: 'N' },
    { word: 'wrangling', word_class: 'N' }
  ],
  dilated: [
    { word: 'dilate', word_class: 'V' },
    { word: 'dilate', word_class: 'AJ' },
    { word: 'dilated', word_class: 'AJ' },
    { word: 'dilation', word_class: 'N' },
    { word: 'dilatation', word_class: 'N' }
  ],
  stores: [
    { word: 'store', word_class: 'N' },
    { word: 'store', word_class: 'V' },
    { word: 'store', word_class: 'AJ' },
    { word: 'stores', word_class: 'N' },
    { word: 'storage', word_class: 'N' },
    { word: 'storing', word_class: 'N' }
  ],
  patrolman: [
    { word: 'patrol', word_class: 'N' },
    { word: 'patrol', word_class: 'V' },
    { word: 'patroller', word_class: 'N' },
    { word: 'patrolman', word_class: 'N' },
    { word: 'patrolled', word_class: 'AJ' },
    { word: 'patrolling', word_class: 'N' },
    { word: 'patrolling', word_class: 'AJ' }
  ],
  periodontics: [
    { word: 'periodontal', word_class: 'AJ' },
    { word: 'periodontic', word_class: 'AJ' },
    { word: 'periodontics', word_class: 'N' },
    { word: 'periodontitis', word_class: 'N' }
  ],
  weakness: [
    { word: 'weak', word_class: 'AJ' },
    { word: 'weakling', word_class: 'N' },
    { word: 'weakness', word_class: 'N' }
  ],
  dehydrogenization: [
    { word: 'dehydrogenate', word_class: 'V' },
    { word: 'dehydrogenation', word_class: 'N' },
    { word: 'dehydrogenisation', word_class: 'N' },
    { word: 'dehydrogenization', word_class: 'N' }
  ],
  analytically: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  alliance: [
    { word: 'ally', word_class: 'N' },
    { word: 'ally', word_class: 'V' },
    { word: 'allis', word_class: 'N' },
    { word: 'allies', word_class: 'N' },
    { word: 'allied', word_class: 'AJ' },
    { word: 'alliance', word_class: 'N' }
  ],
  eloinment: [
    { word: 'eloin', word_class: 'V' },
    { word: 'eloign', word_class: 'V' },
    { word: 'eloinment', word_class: 'N' },
    { word: 'eloignment', word_class: 'N' }
  ],
  trouser: [
    { word: 'trouser', word_class: 'N' },
    { word: 'trousers', word_class: 'N' },
    { word: 'trousered', word_class: 'AJ' },
    { word: 'trousering', word_class: 'N' }
  ],
  pumps: [{ word: 'pump', word_class: 'N' }, { word: 'pump', word_class: 'V' }, { word: 'pumps', word_class: 'N' }],
  mutual: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  thermally: [
    { word: 'thermal', word_class: 'N' },
    { word: 'thermal', word_class: 'AJ' },
    { word: 'thermally', word_class: 'AV' }
  ],
  inferential: [
    { word: 'infer', word_class: 'V' },
    { word: 'inferral', word_class: 'N' },
    { word: 'inference', word_class: 'N' },
    { word: 'inferable', word_class: 'AJ' },
    { word: 'inferential', word_class: 'AJ' }
  ],
  mistreatment: [
    { word: 'mistreat', word_class: 'V' },
    { word: 'mistreated', word_class: 'AJ' },
    { word: 'mistreatment', word_class: 'N' }
  ],
  cobble: [
    { word: 'cobble', word_class: 'N' },
    { word: 'cobble', word_class: 'V' },
    { word: 'cobbler', word_class: 'N' },
    { word: 'cobbling', word_class: 'N' }
  ],
  manageability: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  fallal: [
    { word: 'fall', word_class: 'N' },
    { word: 'fall', word_class: 'V' },
    { word: 'falls', word_class: 'N' },
    { word: 'fallal', word_class: 'N' },
    { word: 'fallen', word_class: 'AJ' },
    { word: 'falling', word_class: 'N' },
    { word: 'falling', word_class: 'AJ' }
  ],
  consubstantiate: [
    { word: 'consubstantial', word_class: 'AJ' },
    { word: 'consubstantiate', word_class: 'V' },
    { word: 'consubstantiation', word_class: 'N' }
  ],
  rinsing: [
    { word: 'rinse', word_class: 'N' },
    { word: 'rinse', word_class: 'V' },
    { word: 'rinsing', word_class: 'N' }
  ],
  impossibility: [
    { word: 'impossible', word_class: 'N' },
    { word: 'impossible', word_class: 'AJ' },
    { word: 'impossibly', word_class: 'AV' },
    { word: 'impossibility', word_class: 'N' },
    { word: 'impossibleness', word_class: 'N' }
  ],
  antecedency: [
    { word: 'antecede', word_class: 'V' },
    { word: 'antecedent', word_class: 'N' },
    { word: 'antecessor', word_class: 'N' },
    { word: 'antecedence', word_class: 'N' },
    { word: 'antecedency', word_class: 'N' },
    { word: 'antecedent', word_class: 'AJ' },
    { word: 'antecedently', word_class: 'AV' }
  ],
  positive: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  ovulate: [
    { word: 'ovule', word_class: 'N' },
    { word: 'ovulate', word_class: 'V' },
    { word: 'ovulation', word_class: 'N' }
  ],
  calibrate: [
    { word: 'caliber', word_class: 'N' },
    { word: 'calibre', word_class: 'N' },
    { word: 'calibrate', word_class: 'V' },
    { word: 'calibration', word_class: 'N' }
  ],
  riser: [
    { word: 'rise', word_class: 'N' },
    { word: 'rise', word_class: 'V' },
    { word: 'riser', word_class: 'N' },
    { word: 'risen', word_class: 'AJ' },
    { word: 'rising', word_class: 'N' },
    { word: 'rising', word_class: 'AJ' }
  ],
  functioning: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  disenthrall: [
    { word: 'disenthral', word_class: 'V' },
    { word: 'disenthrall', word_class: 'V' },
    { word: 'disenthralment', word_class: 'N' },
    { word: 'disenthrallment', word_class: 'N' }
  ],
  leaseholding: [
    { word: 'leasehold', word_class: 'N' },
    { word: 'leaseholder', word_class: 'N' },
    { word: 'leaseholding', word_class: 'N' }
  ],
  deranged: [
    { word: 'derange', word_class: 'V' },
    { word: 'deranged', word_class: 'AJ' },
    { word: 'derangement', word_class: 'N' }
  ],
  cornhusk: [
    { word: 'cornhusk', word_class: 'N' },
    { word: 'cornhusker', word_class: 'N' },
    { word: 'cornhusking', word_class: 'N' }
  ],
  yearning: [
    { word: 'yearn', word_class: 'V' },
    { word: 'yearning', word_class: 'N' },
    { word: 'yearning', word_class: 'AJ' }
  ],
  worst: [
    { word: 'worst', word_class: 'N' },
    { word: 'worst', word_class: 'V' },
    { word: 'worst', word_class: 'AJ' },
    { word: 'worst', word_class: 'AV' },
    { word: 'worsted', word_class: 'N' }
  ],
  volatilization: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  disconsolately: [
    { word: 'disconsole', word_class: 'V' },
    { word: 'disconsolate', word_class: 'AJ' },
    { word: 'disconsolately', word_class: 'AV' },
    { word: 'disconsolateness', word_class: 'N' }
  ],
  primitively: [
    { word: 'primitive', word_class: 'N' },
    { word: 'primitive', word_class: 'AJ' },
    { word: 'primitively', word_class: 'AV' },
    { word: 'primitiveness', word_class: 'N' }
  ],
  electrification: [
    { word: 'electric', word_class: 'N' },
    { word: 'electric', word_class: 'AJ' },
    { word: 'electrify', word_class: 'V' },
    { word: 'electrician', word_class: 'N' },
    { word: 'electrical', word_class: 'AJ' },
    { word: 'electricity', word_class: 'N' },
    { word: 'electrically', word_class: 'AV' },
    { word: 'electrifying', word_class: 'AJ' },
    { word: 'electrification', word_class: 'N' }
  ],
  nasalize: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  humorousness: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  meritorious: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  effective: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  phonology: [
    { word: 'phonology', word_class: 'N' },
    { word: 'phonologic', word_class: 'AJ' },
    { word: 'phonological', word_class: 'AJ' }
  ],
  pleasure: [
    { word: 'please', word_class: 'V' },
    { word: 'pleasant', word_class: 'N' },
    { word: 'pleased', word_class: 'AJ' },
    { word: 'pleasure', word_class: 'N' },
    { word: 'pleasing', word_class: 'N' },
    { word: 'pleasure', word_class: 'V' },
    { word: 'pleasance', word_class: 'N' },
    { word: 'pleasant', word_class: 'AJ' },
    { word: 'pleasing', word_class: 'AJ' },
    { word: 'pleasantry', word_class: 'N' },
    { word: 'pleasantness', word_class: 'N' },
    { word: 'pleasurable', word_class: 'AJ' },
    { word: 'pleasurably', word_class: 'AV' },
    { word: 'pleasureful', word_class: 'AJ' }
  ],
  ostentatiously: [
    { word: 'ostentatious', word_class: 'AJ' },
    { word: 'ostentatiously', word_class: 'AV' },
    { word: 'ostentatiousness', word_class: 'N' }
  ],
  prayer: [
    { word: 'pray', word_class: 'V' },
    { word: 'prayer', word_class: 'N' },
    { word: 'prayerful', word_class: 'AJ' }
  ],
  scholastic: [
    { word: 'scholastic', word_class: 'N' },
    { word: 'scholastic', word_class: 'AJ' },
    { word: 'scholastically', word_class: 'AV' }
  ],
  metaphorically: [
    { word: 'metaphor', word_class: 'N' },
    { word: 'metaphoric', word_class: 'AJ' },
    { word: 'metaphorical', word_class: 'AJ' },
    { word: 'metaphorically', word_class: 'AV' }
  ],
  chlorine: [
    { word: 'chlorine', word_class: 'N' },
    { word: 'chlorinate', word_class: 'V' },
    { word: 'chlorination', word_class: 'N' }
  ],
  infirmity: [
    { word: 'infirm', word_class: 'N' },
    { word: 'infirm', word_class: 'V' },
    { word: 'infirm', word_class: 'AJ' },
    { word: 'infirmary', word_class: 'N' },
    { word: 'infirmity', word_class: 'N' }
  ],
  analyzable: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  synonymousness: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  graphitisation: [
    { word: 'graph', word_class: 'N' },
    { word: 'graph', word_class: 'V' },
    { word: 'graphite', word_class: 'N' },
    { word: 'graphitise', word_class: 'V' },
    { word: 'graphitize', word_class: 'V' },
    { word: 'graphitisation', word_class: 'N' },
    { word: 'graphitization', word_class: 'N' }
  ],
  metabolism: [
    { word: 'metabolic', word_class: 'AJ' },
    { word: 'metabolism', word_class: 'N' },
    { word: 'metabolize', word_class: 'V' },
    { word: 'metabolous', word_class: 'AJ' },
    { word: 'metabolization', word_class: 'N' }
  ],
  brahminic: [
    { word: 'brahmin', word_class: 'N' },
    { word: 'brahminic', word_class: 'AJ' },
    { word: 'brahminism', word_class: 'N' },
    { word: 'brahminical', word_class: 'AJ' }
  ],
  implementation: [
    { word: 'implement', word_class: 'N' },
    { word: 'implement', word_class: 'V' },
    { word: 'implemental', word_class: 'AJ' },
    { word: 'implemented', word_class: 'AJ' },
    { word: 'implementation', word_class: 'N' }
  ],
  dwindling: [
    { word: 'dwindle', word_class: 'V' },
    { word: 'dwindling', word_class: 'N' },
    { word: 'dwindling', word_class: 'AJ' }
  ],
  engrossing: [
    { word: 'engross', word_class: 'V' },
    { word: 'engrossed', word_class: 'AJ' },
    { word: 'engrossing', word_class: 'AJ' },
    { word: 'engrossment', word_class: 'N' }
  ],
  beat: [
    { word: 'beat', word_class: 'N' },
    { word: 'beat', word_class: 'V' },
    { word: 'beat', word_class: 'AJ' },
    { word: 'beater', word_class: 'N' },
    { word: 'beaten', word_class: 'AJ' },
    { word: 'beating', word_class: 'N' },
    { word: 'beating', word_class: 'AJ' }
  ],
  brown: [
    { word: 'brown', word_class: 'N' },
    { word: 'brown', word_class: 'V' },
    { word: 'brown', word_class: 'AJ' },
    { word: 'browned', word_class: 'AJ' },
    { word: 'browning', word_class: 'N' },
    { word: 'brownness', word_class: 'N' }
  ],
  coexistent: [
    { word: 'coexist', word_class: 'V' },
    { word: 'coexistence', word_class: 'N' },
    { word: 'coexistent', word_class: 'AJ' },
    { word: 'coexisting', word_class: 'AJ' }
  ],
  affliction: [
    { word: 'afflict', word_class: 'V' },
    { word: 'afflicted', word_class: 'AJ' },
    { word: 'affliction', word_class: 'N' },
    { word: 'afflictive', word_class: 'AJ' }
  ],
  adviser: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  fluctuate: [
    { word: 'fluctuate', word_class: 'V' },
    { word: 'fluctuation', word_class: 'N' },
    { word: 'fluctuating', word_class: 'AJ' }
  ],
  heave: [
    { word: 'heave', word_class: 'N' },
    { word: 'heave', word_class: 'V' },
    { word: 'heaver', word_class: 'N' },
    { word: 'heaves', word_class: 'N' },
    { word: 'heaving', word_class: 'N' },
    { word: 'heaving', word_class: 'AJ' }
  ],
  carburisation: [
    { word: 'carburet', word_class: 'V' },
    { word: 'carburise', word_class: 'V' },
    { word: 'carburize', word_class: 'V' },
    { word: 'carburation', word_class: 'N' },
    { word: 'carburisation', word_class: 'N' },
    { word: 'carburization', word_class: 'N' }
  ],
  inspired: [
    { word: 'inspire', word_class: 'N' },
    { word: 'inspire', word_class: 'V' },
    { word: 'inspired', word_class: 'AJ' },
    { word: 'inspiring', word_class: 'AJ' },
    { word: 'inspiration', word_class: 'N' },
    { word: 'inspirational', word_class: 'AJ' }
  ],
  ignitible: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  plausibleness: [
    { word: 'plausible', word_class: 'AJ' },
    { word: 'plausibly', word_class: 'AV' },
    { word: 'plausibility', word_class: 'N' },
    { word: 'plausibleness', word_class: 'N' }
  ],
  synthesize: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  gesture: [
    { word: 'gesture', word_class: 'N' },
    { word: 'gesture', word_class: 'V' },
    { word: 'gestural', word_class: 'AJ' }
  ],
  simultaneous: [
    { word: 'simultaneity', word_class: 'N' },
    { word: 'simultaneous', word_class: 'AJ' },
    { word: 'simultaneously', word_class: 'AV' },
    { word: 'simultaneousness', word_class: 'N' }
  ],
  consistence: [
    { word: 'consist', word_class: 'V' },
    { word: 'consistence', word_class: 'N' },
    { word: 'consistency', word_class: 'N' },
    { word: 'consistent', word_class: 'AJ' },
    { word: 'consistently', word_class: 'AV' }
  ],
  know: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  collaborator: [
    { word: 'collaborate', word_class: 'V' },
    { word: 'collaborator', word_class: 'N' },
    { word: 'collaboration', word_class: 'N' }
  ],
  solarisation: [
    { word: 'solar', word_class: 'AJ' },
    { word: 'solarise', word_class: 'V' },
    { word: 'solarize', word_class: 'V' },
    { word: 'solarisation', word_class: 'N' },
    { word: 'solarization', word_class: 'N' }
  ],
  sensitisation: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  recant: [
    { word: 'recant', word_class: 'N' },
    { word: 'recant', word_class: 'V' },
    { word: 'recantation', word_class: 'N' }
  ],
  squarely: [
    { word: 'square', word_class: 'N' },
    { word: 'square', word_class: 'V' },
    { word: 'square', word_class: 'AJ' },
    { word: 'square', word_class: 'AV' },
    { word: 'squared', word_class: 'AJ' },
    { word: 'squarely', word_class: 'AV' },
    { word: 'squareness', word_class: 'N' }
  ],
  plasticization: [
    { word: 'plastic', word_class: 'N' },
    { word: 'plastic', word_class: 'AJ' },
    { word: 'plasticise', word_class: 'V' },
    { word: 'plasticize', word_class: 'V' },
    { word: 'plasticity', word_class: 'N' },
    { word: 'plasticizer', word_class: 'N' },
    { word: 'plastically', word_class: 'AV' },
    { word: 'plasticisation', word_class: 'N' },
    { word: 'plasticization', word_class: 'N' }
  ],
  corruptive: [
    { word: 'corrupt', word_class: 'V' },
    { word: 'corrupt', word_class: 'AJ' },
    { word: 'corruption', word_class: 'N' },
    { word: 'corrupted', word_class: 'AJ' },
    { word: 'corrupting', word_class: 'AJ' },
    { word: 'corruptive', word_class: 'AJ' },
    { word: 'corruptness', word_class: 'N' },
    { word: 'corruptible', word_class: 'AJ' },
    { word: 'corruptibility', word_class: 'N' }
  ],
  complain: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  mulcting: [
    { word: 'mulct', word_class: 'N' },
    { word: 'mulct', word_class: 'V' },
    { word: 'mulcting', word_class: 'N' }
  ],
  absentee: [
    { word: 'absent', word_class: 'V' },
    { word: 'absence', word_class: 'N' },
    { word: 'absent', word_class: 'AJ' },
    { word: 'absentee', word_class: 'N' },
    { word: 'absently', word_class: 'AV' },
    { word: 'absenteeism', word_class: 'N' }
  ],
  fruitful: [
    { word: 'fruit', word_class: 'N' },
    { word: 'fruit', word_class: 'V' },
    { word: 'fruited', word_class: 'AJ' },
    { word: 'fruition', word_class: 'N' },
    { word: 'fruitful', word_class: 'AJ' },
    { word: 'fruiting', word_class: 'AJ' },
    { word: 'fruitfulness', word_class: 'N' }
  ],
  autumn: [
    { word: 'autumn', word_class: 'N' },
    { word: 'autumn', word_class: 'AJ' },
    { word: 'autumnal', word_class: 'AJ' }
  ],
  measurable: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  think: [
    { word: 'think', word_class: 'N' },
    { word: 'think', word_class: 'V' },
    { word: 'thought', word_class: 'N' },
    { word: 'thinker', word_class: 'N' },
    { word: 'thinking', word_class: 'N' },
    { word: 'thinking', word_class: 'AJ' },
    { word: 'thoughtful', word_class: 'AJ' },
    { word: 'thoughtfulness', word_class: 'N' }
  ],
  inhumation: [
    { word: 'inhume', word_class: 'V' },
    { word: 'inhumed', word_class: 'AJ' },
    { word: 'inhumation', word_class: 'N' }
  ],
  rhapsodize: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  coin: [
    { word: 'coin', word_class: 'N' },
    { word: 'coin', word_class: 'V' },
    { word: 'coiner', word_class: 'N' },
    { word: 'coinage', word_class: 'N' }
  ],
  virilization: [
    { word: 'virile', word_class: 'AJ' },
    { word: 'virility', word_class: 'N' },
    { word: 'virilization', word_class: 'N' }
  ],
  faradisation: [
    { word: 'farad', word_class: 'N' },
    { word: 'faradise', word_class: 'V' },
    { word: 'faradize', word_class: 'V' },
    { word: 'faradisation', word_class: 'N' },
    { word: 'faradization', word_class: 'N' }
  ],
  local: [
    { word: 'local', word_class: 'N' },
    { word: 'local', word_class: 'AJ' },
    { word: 'locale', word_class: 'N' },
    { word: 'localise', word_class: 'V' },
    { word: 'localism', word_class: 'N' },
    { word: 'localize', word_class: 'V' },
    { word: 'locality', word_class: 'N' },
    { word: 'locally', word_class: 'AV' },
    { word: 'localized', word_class: 'AJ' },
    { word: 'localisation', word_class: 'N' },
    { word: 'localization', word_class: 'N' }
  ],
  count: [
    { word: 'count', word_class: 'N' },
    { word: 'count', word_class: 'V' },
    { word: 'counter', word_class: 'N' },
    { word: 'counter', word_class: 'V' },
    { word: 'counter', word_class: 'AV' },
    { word: 'counter', word_class: 'AJ' },
    { word: 'counting', word_class: 'N' },
    { word: 'counterman', word_class: 'N' }
  ],
  disposed: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  embalm: [
    { word: 'embalm', word_class: 'V' },
    { word: 'embalmer', word_class: 'N' },
    { word: 'embalmment', word_class: 'N' }
  ],
  histrionic: [
    { word: 'histrion', word_class: 'N' },
    { word: 'histrionic', word_class: 'AJ' },
    { word: 'histrionics', word_class: 'N' }
  ],
  blasphemously: [
    { word: 'blaspheme', word_class: 'V' },
    { word: 'blasphemy', word_class: 'N' },
    { word: 'blasphemer', word_class: 'N' },
    { word: 'blasphemous', word_class: 'AJ' },
    { word: 'blasphemously', word_class: 'AV' }
  ],
  rationality: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  prominently: [
    { word: 'prominence', word_class: 'N' },
    { word: 'prominent', word_class: 'AJ' },
    { word: 'prominently', word_class: 'AV' }
  ],
  sheltered: [
    { word: 'shelter', word_class: 'N' },
    { word: 'shelter', word_class: 'V' },
    { word: 'sheltered', word_class: 'AJ' }
  ],
  weeper: [
    { word: 'weep', word_class: 'V' },
    { word: 'weepy', word_class: 'AJ' },
    { word: 'weeper', word_class: 'N' },
    { word: 'weeping', word_class: 'N' },
    { word: 'weeping', word_class: 'AJ' },
    { word: 'weepiness', word_class: 'N' }
  ],
  nonsensicality: [
    { word: 'nonsense', word_class: 'N' },
    { word: 'nonsense', word_class: 'AJ' },
    { word: 'nonsensical', word_class: 'AJ' },
    { word: 'nonsensicality', word_class: 'N' }
  ],
  footer: [
    { word: 'foot', word_class: 'N' },
    { word: 'foot', word_class: 'V' },
    { word: 'footer', word_class: 'N' },
    { word: 'footed', word_class: 'AJ' },
    { word: 'footing', word_class: 'N' },
    { word: 'footman', word_class: 'N' }
  ],
  disappoint: [
    { word: 'disappoint', word_class: 'V' },
    { word: 'disappointed', word_class: 'AJ' },
    { word: 'disappointing', word_class: 'N' },
    { word: 'disappointing', word_class: 'AJ' },
    { word: 'disappointment', word_class: 'N' }
  ],
  noted: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  transmute: [
    { word: 'transmute', word_class: 'V' },
    { word: 'transmute', word_class: 'AJ' },
    { word: 'transmutable', word_class: 'AJ' },
    { word: 'transmutation', word_class: 'N' },
    { word: 'transmutability', word_class: 'N' }
  ],
  fanaticize: [
    { word: 'fanaticism', word_class: 'N' },
    { word: 'fanaticize', word_class: 'V' },
    { word: 'fanaticization', word_class: 'N' }
  ],
  murmuration: [
    { word: 'murmur', word_class: 'N' },
    { word: 'murmur', word_class: 'V' },
    { word: 'murmuring', word_class: 'N' },
    { word: 'murmuring', word_class: 'AJ' },
    { word: 'murmurous', word_class: 'AJ' },
    { word: 'murmuration', word_class: 'N' }
  ],
  declaratory: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  exterior: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  thriller: [
    { word: 'thrill', word_class: 'N' },
    { word: 'thrill', word_class: 'V' },
    { word: 'thriller', word_class: 'N' },
    { word: 'thrilled', word_class: 'AJ' },
    { word: 'thrillful', word_class: 'AJ' },
    { word: 'thrilling', word_class: 'AJ' }
  ],
  judgement: [
    { word: 'judge', word_class: 'N' },
    { word: 'judge', word_class: 'V' },
    { word: 'judges', word_class: 'N' },
    { word: 'judging', word_class: 'N' },
    { word: 'judgment', word_class: 'N' },
    { word: 'judgship', word_class: 'N' },
    { word: 'judgement', word_class: 'N' },
    { word: 'judgeship', word_class: 'N' },
    { word: 'judgmental', word_class: 'AJ' }
  ],
  unprofitability: [
    { word: 'unprofitable', word_class: 'AJ' },
    { word: 'unprofitably', word_class: 'AV' },
    { word: 'unprofitability', word_class: 'N' },
    { word: 'unprofitableness', word_class: 'N' }
  ],
  indigene: [
    { word: 'indigene', word_class: 'N' },
    { word: 'indigenous', word_class: 'AJ' },
    { word: 'indigenousness', word_class: 'N' }
  ],
  prolixness: [
    { word: 'prolix', word_class: 'AJ' },
    { word: 'prolixity', word_class: 'N' },
    { word: 'prolixness', word_class: 'N' }
  ],
  agitating: [
    { word: 'agitate', word_class: 'V' },
    { word: 'agitator', word_class: 'N' },
    { word: 'agitated', word_class: 'AJ' },
    { word: 'agitation', word_class: 'N' },
    { word: 'agitating', word_class: 'AJ' },
    { word: 'agitative', word_class: 'AJ' }
  ],
  obliterable: [
    { word: 'obliterate', word_class: 'V' },
    { word: 'obliterate', word_class: 'AJ' },
    { word: 'obliterable', word_class: 'AJ' },
    { word: 'obliterated', word_class: 'AJ' },
    { word: 'obliteration', word_class: 'N' },
    { word: 'obliterating', word_class: 'AJ' }
  ],
  disposable: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  oriented: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  chancy: [
    { word: 'chance', word_class: 'N' },
    { word: 'chance', word_class: 'V' },
    { word: 'chance', word_class: 'AJ' },
    { word: 'chancy', word_class: 'AJ' },
    { word: 'chanceful', word_class: 'AJ' }
  ],
  unselfconscious: [
    { word: 'unselfconscious', word_class: 'AJ' },
    { word: 'unselfconsciously', word_class: 'AV' },
    { word: 'unselfconsciousness', word_class: 'N' }
  ],
  alter: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  apprentice: [
    { word: 'apprentice', word_class: 'N' },
    { word: 'apprentice', word_class: 'V' },
    { word: 'apprentice', word_class: 'AJ' },
    { word: 'apprenticeship', word_class: 'N' }
  ],
  profusion: [
    { word: 'profuse', word_class: 'N' },
    { word: 'profuse', word_class: 'V' },
    { word: 'profuse', word_class: 'AJ' },
    { word: 'profusion', word_class: 'N' },
    { word: 'profusely', word_class: 'AV' },
    { word: 'profuseness', word_class: 'N' }
  ],
  awesome: [
    { word: 'awe', word_class: 'N' },
    { word: 'awe', word_class: 'V' },
    { word: 'awed', word_class: 'AJ' },
    { word: 'awful', word_class: 'AJ' },
    { word: 'awful', word_class: 'AV' },
    { word: 'awing', word_class: 'AJ' },
    { word: 'awesome', word_class: 'AJ' },
    { word: 'awfulness', word_class: 'N' }
  ],
  ladle: [
    { word: 'ladle', word_class: 'N' },
    { word: 'ladle', word_class: 'V' },
    { word: 'ladleful', word_class: 'N' },
    { word: 'ladleful', word_class: 'AJ' }
  ],
  telegraph: [
    { word: 'telegraph', word_class: 'N' },
    { word: 'telegraph', word_class: 'V' },
    { word: 'telegrapher', word_class: 'N' },
    { word: 'telegraphic', word_class: 'AJ' },
    { word: 'telegraphist', word_class: 'N' },
    { word: 'telegraphically', word_class: 'AV' }
  ],
  netted: [
    { word: 'net', word_class: 'N' },
    { word: 'net', word_class: 'V' },
    { word: 'net', word_class: 'AJ' },
    { word: 'netted', word_class: 'AJ' },
    { word: 'netting', word_class: 'N' }
  ],
  debut: [
    { word: 'debut', word_class: 'N' },
    { word: 'debut', word_class: 'V' },
    { word: 'debuted', word_class: 'AJ' },
    { word: 'debuting', word_class: 'AJ' }
  ],
  opaquely: [
    { word: 'opacity', word_class: 'N' },
    { word: 'opaque', word_class: 'AJ' },
    { word: 'opaquely', word_class: 'AV' },
    { word: 'opaqueness', word_class: 'N' }
  ],
  graduality: [
    { word: 'gradual', word_class: 'N' },
    { word: 'gradual', word_class: 'AJ' },
    { word: 'graduality', word_class: 'N' },
    { word: 'gradually', word_class: 'AV' },
    { word: 'gradualness', word_class: 'N' }
  ],
  complicating: [
    { word: 'complicate', word_class: 'V' },
    { word: 'complicity', word_class: 'N' },
    { word: 'complicated', word_class: 'AJ' },
    { word: 'complicating', word_class: 'N' },
    { word: 'complication', word_class: 'N' }
  ],
  ride: [
    { word: 'ride', word_class: 'N' },
    { word: 'ride', word_class: 'V' },
    { word: 'rider', word_class: 'N' },
    { word: 'riding', word_class: 'N' },
    { word: 'ridden', word_class: 'AJ' },
    { word: 'riding', word_class: 'AJ' }
  ],
  quakerism: [
    { word: 'quake', word_class: 'N' },
    { word: 'quake', word_class: 'V' },
    { word: 'quaker', word_class: 'N' },
    { word: 'quakers', word_class: 'N' },
    { word: 'quaking', word_class: 'AJ' },
    { word: 'quakerism', word_class: 'N' }
  ],
  instructions: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  stunned: [
    { word: 'stun', word_class: 'V' },
    { word: 'stunned', word_class: 'AJ' },
    { word: 'stunning', word_class: 'AJ' }
  ],
  commonness: [
    { word: 'common', word_class: 'N' },
    { word: 'common', word_class: 'AJ' },
    { word: 'commons', word_class: 'N' },
    { word: 'commoner', word_class: 'N' },
    { word: 'commonness', word_class: 'N' },
    { word: 'commonality', word_class: 'N' }
  ],
  trap: [
    { word: 'trap', word_class: 'N' },
    { word: 'trap', word_class: 'V' },
    { word: 'trapped', word_class: 'AJ' },
    { word: 'trapping', word_class: 'N' },
    { word: 'trappings', word_class: 'N' }
  ],
  conjectural: [
    { word: 'conjecture', word_class: 'N' },
    { word: 'conjecture', word_class: 'V' },
    { word: 'conjectural', word_class: 'AJ' }
  ],
  abdicator: [
    { word: 'abdicate', word_class: 'V' },
    { word: 'abdicator', word_class: 'N' },
    { word: 'abdicable', word_class: 'AJ' },
    { word: 'abdication', word_class: 'N' }
  ],
  flooding: [
    { word: 'flood', word_class: 'N' },
    { word: 'flood', word_class: 'V' },
    { word: 'flood', word_class: 'AJ' },
    { word: 'flooded', word_class: 'AJ' },
    { word: 'flooding', word_class: 'N' },
    { word: 'flooding', word_class: 'AJ' }
  ],
  postulate: [
    { word: 'postulant', word_class: 'N' },
    { word: 'postulate', word_class: 'N' },
    { word: 'postulate', word_class: 'V' },
    { word: 'postulation', word_class: 'N' },
    { word: 'postulational', word_class: 'AJ' }
  ],
  protectively: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  increment: [
    { word: 'increment', word_class: 'N' },
    { word: 'increment', word_class: 'V' },
    { word: 'incremental', word_class: 'AJ' }
  ],
  determine: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  barbell: [
    { word: 'barbel', word_class: 'N' },
    { word: 'barbell', word_class: 'N' },
    { word: 'barbellate', word_class: 'AJ' }
  ],
  sooth: [
    { word: 'sooth', word_class: 'N' },
    { word: 'soothe', word_class: 'V' },
    { word: 'soothing', word_class: 'AJ' }
  ],
  thrombosis: [
    { word: 'thrombose', word_class: 'V' },
    { word: 'thrombosis', word_class: 'N' },
    { word: 'thrombosed', word_class: 'AJ' }
  ],
  rhapsodization: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  ground: [
    { word: 'ground', word_class: 'N' },
    { word: 'ground', word_class: 'V' },
    { word: 'ground', word_class: 'AJ' },
    { word: 'grounds', word_class: 'N' },
    { word: 'grounder', word_class: 'N' },
    { word: 'grounding', word_class: 'N' }
  ],
  posture: [
    { word: 'posture', word_class: 'N' },
    { word: 'posture', word_class: 'V' },
    { word: 'postural', word_class: 'AJ' },
    { word: 'posturing', word_class: 'N' }
  ],
  hoodoo: [
    { word: 'hoodoo', word_class: 'N' },
    { word: 'hoodoo', word_class: 'V' },
    { word: 'hoodooism', word_class: 'N' }
  ],
  diffuser: [
    { word: 'diffuse', word_class: 'V' },
    { word: 'diffusor', word_class: 'N' },
    { word: 'diffuse', word_class: 'AJ' },
    { word: 'diffuser', word_class: 'N' },
    { word: 'diffused', word_class: 'AJ' },
    { word: 'diffusion', word_class: 'N' },
    { word: 'diffusely', word_class: 'AV' },
    { word: 'diffusing', word_class: 'AJ' },
    { word: 'diffusive', word_class: 'AJ' },
    { word: 'diffuseness', word_class: 'N' }
  ],
  disembarkation: [
    { word: 'disembark', word_class: 'V' },
    { word: 'disembarkment', word_class: 'N' },
    { word: 'disembarkation', word_class: 'N' }
  ],
  obedience: [
    { word: 'obey', word_class: 'V' },
    { word: 'obedience', word_class: 'N' },
    { word: 'obedient', word_class: 'AJ' },
    { word: 'obediently', word_class: 'AV' }
  ],
  displease: [
    { word: 'displease', word_class: 'V' },
    { word: 'displeased', word_class: 'AJ' },
    { word: 'displeasure', word_class: 'N' },
    { word: 'displeasing', word_class: 'AJ' }
  ],
  defending: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  carboxylic: [
    { word: 'carboxyl', word_class: 'N' },
    { word: 'carboxyl', word_class: 'AJ' },
    { word: 'carboxylate', word_class: 'V' },
    { word: 'carboxylic', word_class: 'AJ' },
    { word: 'carboxylation', word_class: 'N' }
  ],
  tilled: [
    { word: 'till', word_class: 'V' },
    { word: 'till', word_class: 'N' },
    { word: 'tiller', word_class: 'N' },
    { word: 'tiller', word_class: 'V' },
    { word: 'tillage', word_class: 'N' },
    { word: 'tilled', word_class: 'AJ' },
    { word: 'tilling', word_class: 'N' }
  ],
  abdominous: [
    { word: 'abdomen', word_class: 'N' },
    { word: 'abdominal', word_class: 'AJ' },
    { word: 'abdominous', word_class: 'AJ' },
    { word: 'abdominousness', word_class: 'N' }
  ],
  disruptively: [
    { word: 'disrupt', word_class: 'V' },
    { word: 'disrupted', word_class: 'AJ' },
    { word: 'disruption', word_class: 'N' },
    { word: 'disruptive', word_class: 'AJ' },
    { word: 'disruptively', word_class: 'AV' }
  ],
  pullman: [
    { word: 'pull', word_class: 'N' },
    { word: 'pull', word_class: 'V' },
    { word: 'pullman', word_class: 'N' },
    { word: 'pulled', word_class: 'AJ' },
    { word: 'pulling', word_class: 'N' }
  ],
  homesteader: [
    { word: 'homestead', word_class: 'N' },
    { word: 'homestead', word_class: 'V' },
    { word: 'homesteader', word_class: 'N' }
  ],
  immunize: [
    { word: 'immune', word_class: 'N' },
    { word: 'immune', word_class: 'AJ' },
    { word: 'immunise', word_class: 'V' },
    { word: 'immunity', word_class: 'N' },
    { word: 'immunize', word_class: 'V' },
    { word: 'immunized', word_class: 'AJ' },
    { word: 'immunisation', word_class: 'N' },
    { word: 'immunization', word_class: 'N' }
  ],
  whiten: [
    { word: 'whiten', word_class: 'V' },
    { word: 'whitener', word_class: 'N' },
    { word: 'whitened', word_class: 'AJ' },
    { word: 'whitening', word_class: 'N' }
  ],
  concretely: [
    { word: 'concrete', word_class: 'N' },
    { word: 'concrete', word_class: 'V' },
    { word: 'concrete', word_class: 'AJ' },
    { word: 'concretion', word_class: 'N' },
    { word: 'concretism', word_class: 'N' },
    { word: 'concretize', word_class: 'V' },
    { word: 'concretely', word_class: 'AV' },
    { word: 'concreteness', word_class: 'N' },
    { word: 'concretisation', word_class: 'N' },
    { word: 'concretization', word_class: 'N' }
  ],
  caning: [
    { word: 'cane', word_class: 'N' },
    { word: 'cane', word_class: 'V' },
    { word: 'canal', word_class: 'N' },
    { word: 'canal', word_class: 'V' },
    { word: 'canal', word_class: 'AJ' },
    { word: 'caning', word_class: 'N' },
    { word: 'canalise', word_class: 'V' },
    { word: 'canalize', word_class: 'V' },
    { word: 'canalisation', word_class: 'N' },
    { word: 'canalization', word_class: 'N' }
  ],
  pusher: [
    { word: 'push', word_class: 'N' },
    { word: 'push', word_class: 'V' },
    { word: 'pusher', word_class: 'N' },
    { word: 'pushing', word_class: 'N' },
    { word: 'pushful', word_class: 'AJ' },
    { word: 'pushing', word_class: 'AJ' }
  ],
  disparager: [
    { word: 'disparage', word_class: 'N' },
    { word: 'disparage', word_class: 'V' },
    { word: 'disparager', word_class: 'N' },
    { word: 'disparaging', word_class: 'AJ' },
    { word: 'disparagement', word_class: 'N' }
  ],
  hypnotist: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  materialize: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  calling: [
    { word: 'call', word_class: 'N' },
    { word: 'call', word_class: 'V' },
    { word: 'caller', word_class: 'N' },
    { word: 'called', word_class: 'AJ' },
    { word: 'caller', word_class: 'AJ' },
    { word: 'calling', word_class: 'N' }
  ],
  capitalization: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  ultracentrifugation: [
    { word: 'ultracentrifuge', word_class: 'N' },
    { word: 'ultracentrifuge', word_class: 'V' },
    { word: 'ultracentrifugation', word_class: 'N' }
  ],
  adventurer: [
    { word: 'adventure', word_class: 'N' },
    { word: 'adventure', word_class: 'V' },
    { word: 'adventurer', word_class: 'N' },
    { word: 'adventurism', word_class: 'N' },
    { word: 'adventurous', word_class: 'AJ' },
    { word: 'adventuresome', word_class: 'AJ' },
    { word: 'adventurousness', word_class: 'N' }
  ],
  envisioned: [
    { word: 'envision', word_class: 'V' },
    { word: 'envisioned', word_class: 'AJ' },
    { word: 'envisioning', word_class: 'N' }
  ],
  improbably: [
    { word: 'improbable', word_class: 'AJ' },
    { word: 'improbably', word_class: 'AV' },
    { word: 'improbability', word_class: 'N' },
    { word: 'improbableness', word_class: 'N' }
  ],
  bejewelled: [
    { word: 'bejewel', word_class: 'V' },
    { word: 'bejeweled', word_class: 'AJ' },
    { word: 'bejewelled', word_class: 'AJ' }
  ],
  fingerprinting: [
    { word: 'fingerprint', word_class: 'N' },
    { word: 'fingerprint', word_class: 'V' },
    { word: 'fingerprinting', word_class: 'N' }
  ],
  quarry: [
    { word: 'quarry', word_class: 'N' },
    { word: 'quarry', word_class: 'V' },
    { word: 'quarrier', word_class: 'N' },
    { word: 'quarrying', word_class: 'N' },
    { word: 'quarryman', word_class: 'N' }
  ],
  centennially: [
    { word: 'centennial', word_class: 'N' },
    { word: 'centennial', word_class: 'AJ' },
    { word: 'centennially', word_class: 'AV' }
  ],
  torpidness: [
    { word: 'torpid', word_class: 'AJ' },
    { word: 'torpidity', word_class: 'N' },
    { word: 'torpidness', word_class: 'N' }
  ],
  reprehensible: [
    { word: 'reprehend', word_class: 'V' },
    { word: 'reprehension', word_class: 'N' },
    { word: 'reprehensible', word_class: 'AJ' },
    { word: 'reprehensibly', word_class: 'AV' },
    { word: 'reprehensively', word_class: 'AV' },
    { word: 'reprehensibility', word_class: 'N' }
  ],
  admirableness: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  reigning: [
    { word: 'reign', word_class: 'N' },
    { word: 'reign', word_class: 'V' },
    { word: 'reigning', word_class: 'AJ' }
  ],
  improvident: [
    { word: 'improvidence', word_class: 'N' },
    { word: 'improvident', word_class: 'AJ' },
    { word: 'improvidently', word_class: 'AV' }
  ],
  extemporization: [
    { word: 'extempore', word_class: 'AJ' },
    { word: 'extempore', word_class: 'AV' },
    { word: 'extemporise', word_class: 'V' },
    { word: 'extemporize', word_class: 'V' },
    { word: 'extemporate', word_class: 'V' },
    { word: 'extemporisation', word_class: 'N' },
    { word: 'extemporization', word_class: 'N' }
  ],
  representational: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  prickle: [
    { word: 'prickle', word_class: 'N' },
    { word: 'prickle', word_class: 'V' },
    { word: 'prickly', word_class: 'AJ' },
    { word: 'prickling', word_class: 'N' },
    { word: 'prickling', word_class: 'AJ' }
  ],
  axe: [
    { word: 'ax', word_class: 'N' },
    { word: 'ax', word_class: 'V' },
    { word: 'axe', word_class: 'N' },
    { word: 'axe', word_class: 'V' }
  ],
  bullfighter: [
    { word: 'bullfight', word_class: 'N' },
    { word: 'bullfighter', word_class: 'N' },
    { word: 'bullfighting', word_class: 'N' }
  ],
  unchangeably: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  burglary: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  divorcee: [
    { word: 'divorce', word_class: 'N' },
    { word: 'divorce', word_class: 'V' },
    { word: 'divorcee', word_class: 'N' },
    { word: 'divorced', word_class: 'AJ' },
    { word: 'divorcement', word_class: 'N' }
  ],
  liberation: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  rudiments: [
    { word: 'rudiment', word_class: 'N' },
    { word: 'rudiments', word_class: 'N' },
    { word: 'rudimentary', word_class: 'AJ' }
  ],
  unambiguously: [
    { word: 'unambiguity', word_class: 'N' },
    { word: 'unambiguous', word_class: 'AJ' },
    { word: 'unambiguously', word_class: 'AV' }
  ],
  solacement: [
    { word: 'solace', word_class: 'N' },
    { word: 'solace', word_class: 'V' },
    { word: 'solacement', word_class: 'N' }
  ],
  staff: [{ word: 'staff', word_class: 'N' }, { word: 'staff', word_class: 'V' }, { word: 'staffer', word_class: 'N' }],
  affiliation: [
    { word: 'affiliate', word_class: 'N' },
    { word: 'affiliate', word_class: 'V' },
    { word: 'affiliated', word_class: 'AJ' },
    { word: 'affiliation', word_class: 'N' }
  ],
  trumpeter: [
    { word: 'trumpet', word_class: 'N' },
    { word: 'trumpet', word_class: 'V' },
    { word: 'trumpets', word_class: 'N' },
    { word: 'trumpeter', word_class: 'N' }
  ],
  aestival: [
    { word: 'aestival', word_class: 'AJ' },
    { word: 'aestivate', word_class: 'V' },
    { word: 'aestivation', word_class: 'N' }
  ],
  hated: [
    { word: 'hate', word_class: 'N' },
    { word: 'hate', word_class: 'V' },
    { word: 'hater', word_class: 'N' },
    { word: 'hated', word_class: 'AJ' },
    { word: 'hateful', word_class: 'AJ' },
    { word: 'hatefulness', word_class: 'N' }
  ],
  schismatically: [
    { word: 'schismatic', word_class: 'AJ' },
    { word: 'schismatical', word_class: 'AJ' },
    { word: 'schismatically', word_class: 'AV' }
  ],
  negligible: [
    { word: 'neglige', word_class: 'N' },
    { word: 'negligence', word_class: 'N' },
    { word: 'negligent', word_class: 'AJ' },
    { word: 'negligible', word_class: 'AV' },
    { word: 'negligible', word_class: 'AJ' },
    { word: 'negligibly', word_class: 'AV' },
    { word: 'negligently', word_class: 'AV' }
  ],
  swath: [
    { word: 'swath', word_class: 'N' },
    { word: 'swathe', word_class: 'N' },
    { word: 'swathe', word_class: 'V' },
    { word: 'swathing', word_class: 'N' }
  ],
  substantiated: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  angled: [
    { word: 'angle', word_class: 'N' },
    { word: 'angle', word_class: 'V' },
    { word: 'angler', word_class: 'N' },
    { word: 'angled', word_class: 'AJ' },
    { word: 'angling', word_class: 'N' },
    { word: 'angular', word_class: 'AJ' },
    { word: 'angularity', word_class: 'N' }
  ],
  annunciation: [
    { word: 'annunciate', word_class: 'V' },
    { word: 'annunciator', word_class: 'N' },
    { word: 'annunciation', word_class: 'N' }
  ],
  lug: [
    { word: 'lug', word_class: 'N' },
    { word: 'lug', word_class: 'V' },
    { word: 'luger', word_class: 'N' },
    { word: 'luggage', word_class: 'N' }
  ],
  revalorise: [
    { word: 'revalorise', word_class: 'V' },
    { word: 'revalorize', word_class: 'V' },
    { word: 'revalorization', word_class: 'N' }
  ],
  railroader: [
    { word: 'railroad', word_class: 'N' },
    { word: 'railroad', word_class: 'V' },
    { word: 'railroader', word_class: 'N' }
  ],
  fluorinate: [
    { word: 'fluorine', word_class: 'N' },
    { word: 'fluorinate', word_class: 'V' },
    { word: 'fluorination', word_class: 'N' }
  ],
  givenness: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  snow: [{ word: 'snow', word_class: 'N' }, { word: 'snow', word_class: 'V' }, { word: 'snowman', word_class: 'N' }],
  embarkment: [
    { word: 'embark', word_class: 'V' },
    { word: 'embarkment', word_class: 'N' },
    { word: 'embarkation', word_class: 'N' }
  ],
  asphyxiated: [
    { word: 'asphyxiate', word_class: 'V' },
    { word: 'asphyxiated', word_class: 'AJ' },
    { word: 'asphyxiation', word_class: 'N' },
    { word: 'asphyxiating', word_class: 'AJ' }
  ],
  refractive: [
    { word: 'refract', word_class: 'V' },
    { word: 'refraction', word_class: 'N' },
    { word: 'refractive', word_class: 'AJ' },
    { word: 'refractivity', word_class: 'N' },
    { word: 'refractiveness', word_class: 'N' }
  ],
  pairing: [
    { word: 'pair', word_class: 'N' },
    { word: 'pair', word_class: 'V' },
    { word: 'paired', word_class: 'AJ' },
    { word: 'pairing', word_class: 'N' }
  ],
  agnostical: [
    { word: 'agnostic', word_class: 'N' },
    { word: 'agnostic', word_class: 'AJ' },
    { word: 'agnostical', word_class: 'AJ' }
  ],
  connector: [
    { word: 'connect', word_class: 'V' },
    { word: 'connecter', word_class: 'N' },
    { word: 'connector', word_class: 'N' },
    { word: 'connection', word_class: 'N' },
    { word: 'connected', word_class: 'AJ' },
    { word: 'connecting', word_class: 'N' },
    { word: 'connective', word_class: 'N' },
    { word: 'connecting', word_class: 'AJ' },
    { word: 'connective', word_class: 'AJ' }
  ],
  ostentatious: [
    { word: 'ostentatious', word_class: 'AJ' },
    { word: 'ostentatiously', word_class: 'AV' },
    { word: 'ostentatiousness', word_class: 'N' }
  ],
  maliciousness: [
    { word: 'malice', word_class: 'N' },
    { word: 'malice', word_class: 'V' },
    { word: 'malicious', word_class: 'AJ' },
    { word: 'maliciously', word_class: 'AV' },
    { word: 'maliciousness', word_class: 'N' }
  ],
  nasally: [
    { word: 'nasal', word_class: 'N' },
    { word: 'nasal', word_class: 'AJ' },
    { word: 'nasale', word_class: 'V' },
    { word: 'nasalise', word_class: 'V' },
    { word: 'nasality', word_class: 'N' },
    { word: 'nasalize', word_class: 'V' },
    { word: 'nasally', word_class: 'AV' },
    { word: 'nasalisation', word_class: 'N' },
    { word: 'nasalization', word_class: 'N' }
  ],
  remarkably: [
    { word: 'remark', word_class: 'N' },
    { word: 'remark', word_class: 'V' },
    { word: 'remarkable', word_class: 'AJ' },
    { word: 'remarkably', word_class: 'AV' }
  ],
  carful: [{ word: 'car', word_class: 'N' }, { word: 'carful', word_class: 'N' }, { word: 'carful', word_class: 'AJ' }],
  impermeability: [
    { word: 'impermeable', word_class: 'AJ' },
    { word: 'impermeability', word_class: 'N' },
    { word: 'impermeableness', word_class: 'N' }
  ],
  invader: [
    { word: 'invade', word_class: 'V' },
    { word: 'invader', word_class: 'N' },
    { word: 'invasion', word_class: 'N' },
    { word: 'invading', word_class: 'AJ' },
    { word: 'invasive', word_class: 'AJ' }
  ],
  incurvate: [
    { word: 'incurvate', word_class: 'V' },
    { word: 'incurved', word_class: 'AJ' },
    { word: 'incurvate', word_class: 'AJ' },
    { word: 'incurvation', word_class: 'N' },
    { word: 'incurvature', word_class: 'N' }
  ],
  improvidently: [
    { word: 'improvidence', word_class: 'N' },
    { word: 'improvident', word_class: 'AJ' },
    { word: 'improvidently', word_class: 'AV' }
  ],
  introverted: [
    { word: 'introvert', word_class: 'N' },
    { word: 'introvert', word_class: 'V' },
    { word: 'introvert', word_class: 'AJ' },
    { word: 'introversion', word_class: 'N' },
    { word: 'introverted', word_class: 'AJ' },
    { word: 'introversive', word_class: 'AJ' },
    { word: 'introvertive', word_class: 'AJ' }
  ],
  erection: [
    { word: 'erect', word_class: 'V' },
    { word: 'erect', word_class: 'AJ' },
    { word: 'erecting', word_class: 'N' },
    { word: 'erection', word_class: 'N' },
    { word: 'erectness', word_class: 'N' }
  ],
  quizzical: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  cooler: [
    { word: 'cool', word_class: 'N' },
    { word: 'cool', word_class: 'V' },
    { word: 'cool', word_class: 'AJ' },
    { word: 'cooler', word_class: 'N' },
    { word: 'coolant', word_class: 'N' },
    { word: 'cooled', word_class: 'AJ' },
    { word: 'cooling', word_class: 'N' },
    { word: 'cooling', word_class: 'AJ' },
    { word: 'coolness', word_class: 'N' }
  ],
  factorisation: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  amusive: [
    { word: 'amuse', word_class: 'V' },
    { word: 'amused', word_class: 'AJ' },
    { word: 'amusing', word_class: 'AJ' },
    { word: 'amusive', word_class: 'AJ' },
    { word: 'amusement', word_class: 'N' }
  ],
  alternating: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  transliterate: [
    { word: 'transliterate', word_class: 'V' },
    { word: 'transliterate', word_class: 'AJ' },
    { word: 'transliteration', word_class: 'N' }
  ],
  sulfurization: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  constraining: [
    { word: 'constrain', word_class: 'V' },
    { word: 'constraint', word_class: 'N' },
    { word: 'constrained', word_class: 'AJ' },
    { word: 'constraining', word_class: 'AJ' }
  ],
  songful: [
    { word: 'song', word_class: 'N' },
    { word: 'songful', word_class: 'AJ' },
    { word: 'songfulness', word_class: 'N' }
  ],
  earthman: [
    { word: 'earth', word_class: 'N' },
    { word: 'earth', word_class: 'V' },
    { word: 'earthen', word_class: 'AJ' },
    { word: 'earthing', word_class: 'N' },
    { word: 'earthman', word_class: 'N' }
  ],
  netting: [
    { word: 'net', word_class: 'N' },
    { word: 'net', word_class: 'V' },
    { word: 'net', word_class: 'AJ' },
    { word: 'netted', word_class: 'AJ' },
    { word: 'netting', word_class: 'N' }
  ],
  synonymity: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  italianise: [
    { word: 'italian', word_class: 'N' },
    { word: 'italian', word_class: 'AJ' },
    { word: 'italianise', word_class: 'V' },
    { word: 'italianize', word_class: 'V' },
    { word: 'italianisation', word_class: 'N' },
    { word: 'italianization', word_class: 'N' }
  ],
  incompetent: [
    { word: 'incompetent', word_class: 'N' },
    { word: 'incompetence', word_class: 'N' },
    { word: 'incompetency', word_class: 'N' },
    { word: 'incompetent', word_class: 'AJ' },
    { word: 'incompetently', word_class: 'AV' }
  ],
  coupe: [
    { word: 'coup', word_class: 'N' },
    { word: 'coup', word_class: 'V' },
    { word: 'coup', word_class: 'AJ' },
    { word: 'coupe', word_class: 'N' }
  ],
  diabolization: [
    { word: 'diabolise', word_class: 'V' },
    { word: 'diabolic', word_class: 'AJ' },
    { word: 'diabolize', word_class: 'V' },
    { word: 'diabolism', word_class: 'N' },
    { word: 'diabolical', word_class: 'AJ' },
    { word: 'diabolically', word_class: 'AV' },
    { word: 'diabolization', word_class: 'N' }
  ],
  overmuchness: [
    { word: 'overmuch', word_class: 'N' },
    { word: 'overmuch', word_class: 'AJ' },
    { word: 'overmuch', word_class: 'AV' },
    { word: 'overmuchness', word_class: 'N' }
  ],
  reckon: [
    { word: 'reckon', word_class: 'V' },
    { word: 'reckoner', word_class: 'N' },
    { word: 'reckoning', word_class: 'N' }
  ],
  sovietization: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  reminder: [
    { word: 'remind', word_class: 'N' },
    { word: 'remind', word_class: 'V' },
    { word: 'reminder', word_class: 'N' },
    { word: 'remindful', word_class: 'AJ' }
  ],
  tenderize: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  dislikable: [
    { word: 'dislike', word_class: 'N' },
    { word: 'dislike', word_class: 'V' },
    { word: 'disliked', word_class: 'AJ' },
    { word: 'dislikable', word_class: 'AJ' }
  ],
  methyl: [
    { word: 'methyl', word_class: 'N' },
    { word: 'methylate', word_class: 'V' },
    { word: 'methylated', word_class: 'AJ' },
    { word: 'methylation', word_class: 'N' }
  ],
  coachman: [
    { word: 'coach', word_class: 'N' },
    { word: 'coach', word_class: 'V' },
    { word: 'coaching', word_class: 'N' },
    { word: 'coachman', word_class: 'N' }
  ],
  refectory: [
    { word: 'refect', word_class: 'V' },
    { word: 'refection', word_class: 'N' },
    { word: 'refectory', word_class: 'N' },
    { word: 'refectory', word_class: 'AJ' }
  ],
  scared: [
    { word: 'scare', word_class: 'N' },
    { word: 'scare', word_class: 'V' },
    { word: 'scary', word_class: 'AJ' },
    { word: 'scared', word_class: 'AJ' }
  ],
  intreat: [
    { word: 'entreat', word_class: 'V' },
    { word: 'intreat', word_class: 'V' },
    { word: 'entreaty', word_class: 'N' },
    { word: 'entreatment', word_class: 'N' },
    { word: 'intreatment', word_class: 'N' }
  ],
  valid: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  glassed: [
    { word: 'glass', word_class: 'N' },
    { word: 'glass', word_class: 'V' },
    { word: 'glasses', word_class: 'N' },
    { word: 'glassed', word_class: 'AJ' },
    { word: 'glassful', word_class: 'N' },
    { word: 'glassful', word_class: 'AJ' }
  ],
  mercy: [
    { word: 'mercy', word_class: 'N' },
    { word: 'merciful', word_class: 'AJ' },
    { word: 'mercifulness', word_class: 'N' }
  ],
  confessor: [
    { word: 'confess', word_class: 'V' },
    { word: 'confessor', word_class: 'N' },
    { word: 'confessed', word_class: 'AJ' },
    { word: 'confession', word_class: 'N' }
  ],
  rut: [{ word: 'rut', word_class: 'N' }, { word: 'rut', word_class: 'V' }, { word: 'rutted', word_class: 'AJ' }],
  disgraceful: [
    { word: 'disgrace', word_class: 'N' },
    { word: 'disgrace', word_class: 'V' },
    { word: 'disgraced', word_class: 'AJ' },
    { word: 'disgraceful', word_class: 'AJ' },
    { word: 'disgracefulness', word_class: 'N' }
  ],
  cube: [
    { word: 'cube', word_class: 'N' },
    { word: 'cube', word_class: 'V' },
    { word: 'cubism', word_class: 'N' },
    { word: 'cubist', word_class: 'N' },
    { word: 'cubist', word_class: 'AJ' },
    { word: 'cubistic', word_class: 'AJ' }
  ],
  inadvertent: [
    { word: 'inadvertence', word_class: 'N' },
    { word: 'inadvertency', word_class: 'N' },
    { word: 'inadvertent', word_class: 'AJ' },
    { word: 'inadvertently', word_class: 'AV' }
  ],
  streaked: [
    { word: 'streak', word_class: 'N' },
    { word: 'streak', word_class: 'V' },
    { word: 'streaker', word_class: 'N' },
    { word: 'streaked', word_class: 'AJ' }
  ],
  european: [
    { word: 'european', word_class: 'N' },
    { word: 'european', word_class: 'AJ' },
    { word: 'europeanize', word_class: 'V' },
    { word: 'europeanization', word_class: 'N' }
  ],
  belove: [
    { word: 'belove', word_class: 'V' },
    { word: 'beloved', word_class: 'N' },
    { word: 'beloved', word_class: 'AJ' }
  ],
  erupting: [
    { word: 'erupt', word_class: 'V' },
    { word: 'eruption', word_class: 'N' },
    { word: 'erupting', word_class: 'AJ' },
    { word: 'eruptive', word_class: 'AJ' }
  ],
  hierarch: [
    { word: 'hierarch', word_class: 'N' },
    { word: 'hierarchal', word_class: 'AJ' },
    { word: 'hierarchic', word_class: 'AJ' },
    { word: 'hierarchical', word_class: 'AJ' },
    { word: 'hierarchically', word_class: 'AV' }
  ],
  unanimously: [
    { word: 'unanimity', word_class: 'N' },
    { word: 'unanimous', word_class: 'AJ' },
    { word: 'unanimated', word_class: 'AJ' },
    { word: 'unanimously', word_class: 'AV' }
  ],
  burglarize: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  militant: [
    { word: 'militant', word_class: 'N' },
    { word: 'militate', word_class: 'V' },
    { word: 'militance', word_class: 'N' },
    { word: 'militancy', word_class: 'N' },
    { word: 'militant', word_class: 'AJ' },
    { word: 'militation', word_class: 'N' }
  ],
  kneeler: [
    { word: 'kneel', word_class: 'N' },
    { word: 'kneel', word_class: 'V' },
    { word: 'kneeler', word_class: 'N' },
    { word: 'kneeling', word_class: 'N' },
    { word: 'kneeling', word_class: 'AJ' }
  ],
  insulator: [
    { word: 'insulant', word_class: 'N' },
    { word: 'insulate', word_class: 'V' },
    { word: 'insulator', word_class: 'N' },
    { word: 'insulation', word_class: 'N' }
  ],
  interspersal: [
    { word: 'intersperse', word_class: 'V' },
    { word: 'interspersal', word_class: 'N' },
    { word: 'interspersion', word_class: 'N' }
  ],
  practice: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  jostling: [
    { word: 'jostle', word_class: 'N' },
    { word: 'jostle', word_class: 'V' },
    { word: 'justle', word_class: 'V' },
    { word: 'jostling', word_class: 'N' },
    { word: 'jostlement', word_class: 'N' },
    { word: 'justlement', word_class: 'N' }
  ],
  weigh: [
    { word: 'weigh', word_class: 'V' },
    { word: 'weight', word_class: 'N' },
    { word: 'weight', word_class: 'V' },
    { word: 'weighing', word_class: 'N' },
    { word: 'weighted', word_class: 'AJ' },
    { word: 'weighting', word_class: 'N' }
  ],
  disappear: [
    { word: 'disappear', word_class: 'V' },
    { word: 'disappearing', word_class: 'N' },
    { word: 'disappearance', word_class: 'N' },
    { word: 'disappearing', word_class: 'AJ' }
  ],
  polymerization: [
    { word: 'polymeric', word_class: 'AJ' },
    { word: 'polymerise', word_class: 'V' },
    { word: 'polymerize', word_class: 'V' },
    { word: 'polymerisation', word_class: 'N' },
    { word: 'polymerization', word_class: 'N' }
  ],
  invigorate: [
    { word: 'invigorate', word_class: 'V' },
    { word: 'invigorated', word_class: 'AJ' },
    { word: 'invigoration', word_class: 'N' },
    { word: 'invigorating', word_class: 'AJ' }
  ],
  griminess: [
    { word: 'grime', word_class: 'N' },
    { word: 'grim', word_class: 'AJ' },
    { word: 'grime', word_class: 'V' },
    { word: 'grimy', word_class: 'AJ' },
    { word: 'grimness', word_class: 'N' },
    { word: 'griminess', word_class: 'N' }
  ],
  administrative: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  cheering: [
    { word: 'cheer', word_class: 'N' },
    { word: 'cheer', word_class: 'V' },
    { word: 'cheering', word_class: 'N' },
    { word: 'cheerful', word_class: 'AJ' },
    { word: 'cheering', word_class: 'AJ' },
    { word: 'cheerfulness', word_class: 'N' }
  ],
  reserve: [
    { word: 'reserve', word_class: 'N' },
    { word: 'reserve', word_class: 'V' },
    { word: 'reserve', word_class: 'AJ' },
    { word: 'reserves', word_class: 'N' },
    { word: 'reserved', word_class: 'AJ' },
    { word: 'reservist', word_class: 'N' },
    { word: 'reservation', word_class: 'N' }
  ],
  prussianise: [
    { word: 'prussian', word_class: 'N' },
    { word: 'prussian', word_class: 'AJ' },
    { word: 'prussianise', word_class: 'V' },
    { word: 'prussianize', word_class: 'V' },
    { word: 'prussianisation', word_class: 'N' },
    { word: 'prussianization', word_class: 'N' }
  ],
  hurdler: [
    { word: 'hurdle', word_class: 'N' },
    { word: 'hurdle', word_class: 'V' },
    { word: 'hurdler', word_class: 'N' },
    { word: 'hurdles', word_class: 'N' },
    { word: 'hurdling', word_class: 'N' }
  ],
  mercuration: [
    { word: 'mercury', word_class: 'N' },
    { word: 'mercurate', word_class: 'V' },
    { word: 'mercuric', word_class: 'AJ' },
    { word: 'mercurial', word_class: 'AJ' },
    { word: 'mercuration', word_class: 'N' },
    { word: 'mercurialize', word_class: 'V' },
    { word: 'mercurialise', word_class: 'V' },
    { word: 'mercurialisation', word_class: 'N' },
    { word: 'mercurialization', word_class: 'N' }
  ],
  dismastment: [
    { word: 'dismast', word_class: 'N' },
    { word: 'dismast', word_class: 'V' },
    { word: 'dismastment', word_class: 'N' }
  ],
  limp: [
    { word: 'limp', word_class: 'N' },
    { word: 'limp', word_class: 'V' },
    { word: 'limp', word_class: 'AJ' },
    { word: 'limping', word_class: 'N' },
    { word: 'limpness', word_class: 'N' }
  ],
  ameliorative: [
    { word: 'ameliorate', word_class: 'V' },
    { word: 'amelioration', word_class: 'N' },
    { word: 'ameliorating', word_class: 'AJ' },
    { word: 'ameliorative', word_class: 'AJ' }
  ],
  disorientation: [
    { word: 'disorient', word_class: 'V' },
    { word: 'disorientate', word_class: 'V' },
    { word: 'disoriented', word_class: 'AJ' },
    { word: 'disorienting', word_class: 'AJ' },
    { word: 'disorientation', word_class: 'N' }
  ],
  lecturer: [
    { word: 'lecture', word_class: 'N' },
    { word: 'lecture', word_class: 'V' },
    { word: 'lecturer', word_class: 'N' },
    { word: 'lecturing', word_class: 'N' },
    { word: 'lectureship', word_class: 'N' }
  ],
  ambitiousness: [
    { word: 'ambitious', word_class: 'AJ' },
    { word: 'ambitiously', word_class: 'AV' },
    { word: 'ambitiousness', word_class: 'N' }
  ],
  inerrable: [
    { word: 'inerrancy', word_class: 'N' },
    { word: 'inerrant', word_class: 'AJ' },
    { word: 'inerrable', word_class: 'AJ' }
  ],
  touching: [
    { word: 'touch', word_class: 'N' },
    { word: 'touch', word_class: 'V' },
    { word: 'touched', word_class: 'AJ' },
    { word: 'touching', word_class: 'N' },
    { word: 'touching', word_class: 'AJ' }
  ],
  schematisation: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  disobedience: [
    { word: 'disobey', word_class: 'V' },
    { word: 'disobedience', word_class: 'N' },
    { word: 'disobedient', word_class: 'AJ' },
    { word: 'disobediently', word_class: 'AV' }
  ],
  dismission: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  contemptible: [
    { word: 'contemp', word_class: 'N' },
    { word: 'contempt', word_class: 'N' },
    { word: 'contempt', word_class: 'V' },
    { word: 'contemptible', word_class: 'AJ' },
    { word: 'contemptibly', word_class: 'AV' },
    { word: 'contemptuous', word_class: 'AJ' },
    { word: 'contemptibility', word_class: 'N' },
    { word: 'contemptuously', word_class: 'AV' }
  ],
  hereness: [
    { word: 'here', word_class: 'N' },
    { word: 'here', word_class: 'AJ' },
    { word: 'here', word_class: 'AV' },
    { word: 'hereness', word_class: 'N' }
  ],
  crest: [
    { word: 'crest', word_class: 'N' },
    { word: 'crest', word_class: 'V' },
    { word: 'crested', word_class: 'AJ' }
  ],
  minion: [
    { word: 'mine', word_class: 'N' },
    { word: 'mine', word_class: 'V' },
    { word: 'miner', word_class: 'N' },
    { word: 'mined', word_class: 'AJ' },
    { word: 'mining', word_class: 'N' },
    { word: 'minion', word_class: 'N' },
    { word: 'minor', word_class: 'N' }
  ],
  subjugated: [
    { word: 'subjugate', word_class: 'V' },
    { word: 'subjugate', word_class: 'AJ' },
    { word: 'subjugable', word_class: 'AJ' },
    { word: 'subjugated', word_class: 'AJ' },
    { word: 'subjugation', word_class: 'N' }
  ],
  fluttering: [
    { word: 'flutter', word_class: 'N' },
    { word: 'flutter', word_class: 'V' },
    { word: 'fluttering', word_class: 'N' },
    { word: 'fluttering', word_class: 'AJ' }
  ],
  shiner: [
    { word: 'shine', word_class: 'N' },
    { word: 'shine', word_class: 'V' },
    { word: 'shiny', word_class: 'AJ' },
    { word: 'shiner', word_class: 'N' },
    { word: 'shining', word_class: 'N' },
    { word: 'shining', word_class: 'AJ' },
    { word: 'shininess', word_class: 'N' }
  ],
  partitioning: [
    { word: 'part', word_class: 'N' },
    { word: 'part', word_class: 'V' },
    { word: 'parts', word_class: 'N' },
    { word: 'part', word_class: 'AV' },
    { word: 'part', word_class: 'AJ' },
    { word: 'parted', word_class: 'AJ' },
    { word: 'parting', word_class: 'N' },
    { word: 'parting', word_class: 'AJ' },
    { word: 'partition', word_class: 'N' },
    { word: 'partition', word_class: 'V' },
    { word: 'partitive', word_class: 'N' },
    { word: 'partitive', word_class: 'AJ' },
    { word: 'partitionist', word_class: 'N' },
    { word: 'partitioned', word_class: 'AJ' },
    { word: 'partitioning', word_class: 'N' }
  ],
  choker: [
    { word: 'choke', word_class: 'N' },
    { word: 'choke', word_class: 'V' },
    { word: 'choker', word_class: 'N' },
    { word: 'choked', word_class: 'AJ' },
    { word: 'choking', word_class: 'N' },
    { word: 'choking', word_class: 'AJ' }
  ],
  interpretive: [
    { word: 'interpret', word_class: 'V' },
    { word: 'interpreter', word_class: 'N' },
    { word: 'interpreted', word_class: 'AJ' },
    { word: 'interpreting', word_class: 'N' },
    { word: 'interpretive', word_class: 'AJ' },
    { word: 'interpretable', word_class: 'AJ' },
    { word: 'interpretation', word_class: 'N' },
    { word: 'interpretative', word_class: 'AJ' }
  ],
  religiousness: [
    { word: 'religious', word_class: 'N' },
    { word: 'religious', word_class: 'AJ' },
    { word: 'religiously', word_class: 'AV' },
    { word: 'religiousness', word_class: 'N' }
  ],
  manipulable: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  governor: [
    { word: 'govern', word_class: 'V' },
    { word: 'governed', word_class: 'N' },
    { word: 'governor', word_class: 'N' },
    { word: 'governess', word_class: 'N' },
    { word: 'governing', word_class: 'N' },
    { word: 'governance', word_class: 'N' },
    { word: 'governing', word_class: 'AJ' },
    { word: 'government', word_class: 'N' },
    { word: 'governable', word_class: 'AJ' }
  ],
  dolefulness: [
    { word: 'dole', word_class: 'N' },
    { word: 'dole', word_class: 'V' },
    { word: 'doleful', word_class: 'AJ' },
    { word: 'dolefulness', word_class: 'N' }
  ],
  foreordain: [
    { word: 'foreordain', word_class: 'V' },
    { word: 'foreordained', word_class: 'AJ' },
    { word: 'foreordainment', word_class: 'N' },
    { word: 'foreordination', word_class: 'N' }
  ],
  carpeted: [
    { word: 'carpet', word_class: 'N' },
    { word: 'carpet', word_class: 'V' },
    { word: 'carpeted', word_class: 'AJ' },
    { word: 'carpeting', word_class: 'N' }
  ],
  gloves: [
    { word: 'glove', word_class: 'N' },
    { word: 'glove', word_class: 'V' },
    { word: 'gloves', word_class: 'N' },
    { word: 'gloved', word_class: 'AJ' }
  ],
  atonic: [
    { word: 'atone', word_class: 'V' },
    { word: 'atonal', word_class: 'N' },
    { word: 'atonal', word_class: 'AJ' },
    { word: 'atonic', word_class: 'AJ' },
    { word: 'atonality', word_class: 'N' },
    { word: 'atonally', word_class: 'AV' },
    { word: 'atonement', word_class: 'N' },
    { word: 'atonalism', word_class: 'N' },
    { word: 'atonicity', word_class: 'N' }
  ],
  numerability: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  eased: [
    { word: 'ease', word_class: 'N' },
    { word: 'ease', word_class: 'V' },
    { word: 'eased', word_class: 'AJ' },
    { word: 'easing', word_class: 'N' },
    { word: 'easement', word_class: 'N' }
  ],
  dispossessed: [
    { word: 'dispossess', word_class: 'V' },
    { word: 'dispossessed', word_class: 'AJ' },
    { word: 'dispossession', word_class: 'N' }
  ],
  illuminate: [
    { word: 'illume', word_class: 'V' },
    { word: 'illumine', word_class: 'V' },
    { word: 'illuminant', word_class: 'N' },
    { word: 'illuminate', word_class: 'V' },
    { word: 'illuminance', word_class: 'N' },
    { word: 'illuminated', word_class: 'AJ' },
    { word: 'illumination', word_class: 'N' },
    { word: 'illuminating', word_class: 'AJ' }
  ],
  width: [
    { word: 'wide', word_class: 'AJ' },
    { word: 'wide', word_class: 'AV' },
    { word: 'width', word_class: 'N' },
    { word: 'widely', word_class: 'AV' },
    { word: 'wideness', word_class: 'N' }
  ],
  trivialise: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  reposit: [
    { word: 'reposit', word_class: 'V' },
    { word: 'repositing', word_class: 'N' },
    { word: 'reposition', word_class: 'N' },
    { word: 'reposition', word_class: 'V' },
    { word: 'repositioning', word_class: 'N' }
  ],
  shimmering: [
    { word: 'shimmer', word_class: 'N' },
    { word: 'shimmer', word_class: 'V' },
    { word: 'shimmering', word_class: 'AJ' }
  ],
  phoner: [{ word: 'phone', word_class: 'N' }, { word: 'phone', word_class: 'V' }, { word: 'phoner', word_class: 'N' }],
  scudding: [
    { word: 'scud', word_class: 'N' },
    { word: 'scud', word_class: 'V' },
    { word: 'scudding', word_class: 'N' }
  ],
  depicting: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  mountaineering: [
    { word: 'mountain', word_class: 'N' },
    { word: 'mountain', word_class: 'AJ' },
    { word: 'mountaineer', word_class: 'N' },
    { word: 'mountaineer', word_class: 'V' },
    { word: 'mountainous', word_class: 'AJ' },
    { word: 'mountaineering', word_class: 'N' }
  ],
  defense: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  unread: [
    { word: 'unread', word_class: 'AJ' },
    { word: 'unreadable', word_class: 'AJ' },
    { word: 'unreadably', word_class: 'AV' }
  ],
  quizzic: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  stonewaller: [
    { word: 'stonewall', word_class: 'V' },
    { word: 'stonewaller', word_class: 'N' },
    { word: 'stonewalling', word_class: 'N' }
  ],
  sorb: [
    { word: 'sorb', word_class: 'N' },
    { word: 'sorb', word_class: 'V' },
    { word: 'sorbed', word_class: 'AJ' },
    { word: 'sorption', word_class: 'N' }
  ],
  efflorescence: [
    { word: 'effloresce', word_class: 'V' },
    { word: 'efflorescence', word_class: 'N' },
    { word: 'efflorescent', word_class: 'AJ' }
  ],
  plate: [
    { word: 'plate', word_class: 'N' },
    { word: 'plate', word_class: 'V' },
    { word: 'plating', word_class: 'N' },
    { word: 'plateful', word_class: 'N' },
    { word: 'plateful', word_class: 'AJ' }
  ],
  aligned: [
    { word: 'align', word_class: 'V' },
    { word: 'aligned', word_class: 'AJ' },
    { word: 'aligning', word_class: 'AJ' },
    { word: 'alignment', word_class: 'N' }
  ],
  combing: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  emboldened: [
    { word: 'embold', word_class: 'V' },
    { word: 'embolden', word_class: 'V' },
    { word: 'embolden', word_class: 'AJ' },
    { word: 'emboldened', word_class: 'AJ' },
    { word: 'emboldenment', word_class: 'N' }
  ],
  impressionism: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  carpentry: [
    { word: 'carpent', word_class: 'AJ' },
    { word: 'carpenter', word_class: 'N' },
    { word: 'carpenter', word_class: 'V' },
    { word: 'carpentry', word_class: 'N' }
  ],
  condemn: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  finalization: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  propitiousness: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  synonymize: [
    { word: 'synonym', word_class: 'N' },
    { word: 'synonymy', word_class: 'N' },
    { word: 'synonymy', word_class: 'AJ' },
    { word: 'synonymise', word_class: 'V' },
    { word: 'synonymity', word_class: 'N' },
    { word: 'synonymize', word_class: 'V' },
    { word: 'synonymous', word_class: 'AJ' },
    { word: 'synonymously', word_class: 'AV' },
    { word: 'synonymization', word_class: 'N' },
    { word: 'synonymousness', word_class: 'N' }
  ],
  prussianize: [
    { word: 'prussian', word_class: 'N' },
    { word: 'prussian', word_class: 'AJ' },
    { word: 'prussianise', word_class: 'V' },
    { word: 'prussianize', word_class: 'V' },
    { word: 'prussianisation', word_class: 'N' },
    { word: 'prussianization', word_class: 'N' }
  ],
  worse: [{ word: 'worse', word_class: 'N' }, { word: 'worse', word_class: 'AJ' }, { word: 'worse', word_class: 'AV' }],
  macroscopic: [
    { word: 'macroscopic', word_class: 'AJ' },
    { word: 'macroscopical', word_class: 'AJ' },
    { word: 'macroscopically', word_class: 'AV' }
  ],
  feast: [
    { word: 'feast', word_class: 'N' },
    { word: 'feast', word_class: 'V' },
    { word: 'feasting', word_class: 'N' }
  ],
  poetically: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  harpoon: [
    { word: 'harpoon', word_class: 'N' },
    { word: 'harpoon', word_class: 'V' },
    { word: 'harpooner', word_class: 'N' },
    { word: 'harpooneer', word_class: 'N' }
  ],
  benign: [
    { word: 'benign', word_class: 'AJ' },
    { word: 'benignity', word_class: 'N' },
    { word: 'benignancy', word_class: 'N' },
    { word: 'benignant', word_class: 'AJ' }
  ],
  skateboarder: [
    { word: 'skateboard', word_class: 'N' },
    { word: 'skateboard', word_class: 'V' },
    { word: 'skateboarder', word_class: 'N' },
    { word: 'skateboarding', word_class: 'N' }
  ],
  reciprocation: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  layered: [
    { word: 'lay', word_class: 'N' },
    { word: 'lay', word_class: 'V' },
    { word: 'lay', word_class: 'AJ' },
    { word: 'layer', word_class: 'N' },
    { word: 'layer', word_class: 'V' },
    { word: 'laying', word_class: 'N' },
    { word: 'layman', word_class: 'N' },
    { word: 'layered', word_class: 'AJ' }
  ],
  entanglement: [
    { word: 'entangle', word_class: 'V' },
    { word: 'entangled', word_class: 'AJ' },
    { word: 'entanglement', word_class: 'N' }
  ],
  stolidity: [
    { word: 'stolid', word_class: 'AJ' },
    { word: 'stolidity', word_class: 'N' },
    { word: 'stolidness', word_class: 'N' }
  ],
  inflationary: [
    { word: 'inflate', word_class: 'V' },
    { word: 'inflater', word_class: 'N' },
    { word: 'inflator', word_class: 'N' },
    { word: 'inflated', word_class: 'AJ' },
    { word: 'inflation', word_class: 'N' },
    { word: 'inflatable', word_class: 'AJ' },
    { word: 'inflationary', word_class: 'AJ' }
  ],
  blind: [
    { word: 'blind', word_class: 'N' },
    { word: 'blind', word_class: 'V' },
    { word: 'blind', word_class: 'AJ' },
    { word: 'blinder', word_class: 'N' },
    { word: 'blinded', word_class: 'AJ' },
    { word: 'blinding', word_class: 'AJ' },
    { word: 'blindness', word_class: 'N' }
  ],
  sermonizer: [
    { word: 'sermon', word_class: 'N' },
    { word: 'sermonize', word_class: 'V' },
    { word: 'sermonizer', word_class: 'N' },
    { word: 'sermonization', word_class: 'N' }
  ],
  gadgetry: [
    { word: 'gadget', word_class: 'N' },
    { word: 'gadgetry', word_class: 'N' },
    { word: 'gadgeteer', word_class: 'N' }
  ],
  drowsing: [
    { word: 'drowse', word_class: 'N' },
    { word: 'drowse', word_class: 'V' },
    { word: 'drowsy', word_class: 'AJ' },
    { word: 'drowsing', word_class: 'AJ' },
    { word: 'drowsiness', word_class: 'N' }
  ],
  certified: [
    { word: 'certify', word_class: 'V' },
    { word: 'certified', word_class: 'AJ' },
    { word: 'certificate', word_class: 'N' },
    { word: 'certificate', word_class: 'V' },
    { word: 'certifiable', word_class: 'AJ' },
    { word: 'certificated', word_class: 'AJ' },
    { word: 'certification', word_class: 'N' }
  ],
  devolvement: [
    { word: 'devolve', word_class: 'V' },
    { word: 'devolution', word_class: 'N' },
    { word: 'devolvement', word_class: 'N' },
    { word: 'devolutionary', word_class: 'AJ' }
  ],
  autobiographic: [
    { word: 'autobiographer', word_class: 'N' },
    { word: 'autobiographic', word_class: 'AJ' },
    { word: 'autobiographical', word_class: 'AJ' }
  ],
  inefficaciousness: [
    { word: 'inefficacy', word_class: 'N' },
    { word: 'inefficacious', word_class: 'AJ' },
    { word: 'inefficaciously', word_class: 'AV' },
    { word: 'inefficaciousness', word_class: 'N' }
  ],
  disingenuously: [
    { word: 'disingenuous', word_class: 'AJ' },
    { word: 'disingenuously', word_class: 'AV' },
    { word: 'disingenuousness', word_class: 'N' }
  ],
  bowdlerisation: [
    { word: 'bowdlerise', word_class: 'V' },
    { word: 'bowdlerism', word_class: 'N' },
    { word: 'bowdlerize', word_class: 'V' },
    { word: 'bowdlerisation', word_class: 'N' },
    { word: 'bowdlerization', word_class: 'N' }
  ],
  truculency: [
    { word: 'truculence', word_class: 'N' },
    { word: 'truculency', word_class: 'N' },
    { word: 'truculent', word_class: 'AJ' },
    { word: 'truculently', word_class: 'AV' }
  ],
  remainder: [
    { word: 'remain', word_class: 'V' },
    { word: 'remain', word_class: 'AJ' },
    { word: 'remains', word_class: 'N' },
    { word: 'remainder', word_class: 'N' },
    { word: 'remanence', word_class: 'N' },
    { word: 'remainder', word_class: 'V' },
    { word: 'remaining', word_class: 'AJ' }
  ],
  depression: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  internalisation: [
    { word: 'internal', word_class: 'N' },
    { word: 'internal', word_class: 'AJ' },
    { word: 'internally', word_class: 'AV' },
    { word: 'internalise', word_class: 'V' },
    { word: 'internalize', word_class: 'V' },
    { word: 'internalisation', word_class: 'N' },
    { word: 'internalization', word_class: 'N' }
  ],
  bowl: [
    { word: 'bowl', word_class: 'N' },
    { word: 'bowl', word_class: 'V' },
    { word: 'bowls', word_class: 'N' },
    { word: 'bowler', word_class: 'N' },
    { word: 'bowlful', word_class: 'N' },
    { word: 'bowling', word_class: 'N' },
    { word: 'bowlful', word_class: 'AJ' }
  ],
  lividity: [
    { word: 'livid', word_class: 'AJ' },
    { word: 'lividity', word_class: 'N' },
    { word: 'lividness', word_class: 'N' }
  ],
  designate: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  iodization: [
    { word: 'iodise', word_class: 'V' },
    { word: 'iodize', word_class: 'V' },
    { word: 'iodized', word_class: 'AJ' },
    { word: 'iodisation', word_class: 'N' },
    { word: 'iodization', word_class: 'N' }
  ],
  humbly: [
    { word: 'humble', word_class: 'V' },
    { word: 'humble', word_class: 'AJ' },
    { word: 'humbly', word_class: 'AV' },
    { word: 'humbled', word_class: 'AJ' },
    { word: 'humbling', word_class: 'N' },
    { word: 'humbling', word_class: 'AJ' },
    { word: 'humbleness', word_class: 'N' }
  ],
  legitimatization: [
    { word: 'legitimatise', word_class: 'V' },
    { word: 'legitimatize', word_class: 'V' },
    { word: 'legitimisation', word_class: 'N' },
    { word: 'legitimatisation', word_class: 'N' },
    { word: 'legitimatization', word_class: 'N' }
  ],
  exhilarating: [
    { word: 'exhilarate', word_class: 'V' },
    { word: 'exhilarated', word_class: 'AJ' },
    { word: 'exhilaration', word_class: 'N' },
    { word: 'exhilarating', word_class: 'AJ' }
  ],
  ingeniousness: [
    { word: 'ingenious', word_class: 'AJ' },
    { word: 'ingeniously', word_class: 'AV' },
    { word: 'ingeniousness', word_class: 'N' }
  ],
  morally: [
    { word: 'moral', word_class: 'N' },
    { word: 'moral', word_class: 'AJ' },
    { word: 'morale', word_class: 'N' },
    { word: 'morals', word_class: 'N' },
    { word: 'moralise', word_class: 'V' },
    { word: 'moralist', word_class: 'N' },
    { word: 'moralize', word_class: 'V' },
    { word: 'morality', word_class: 'N' },
    { word: 'morally', word_class: 'AV' },
    { word: 'moralizing', word_class: 'N' },
    { word: 'moralistic', word_class: 'AJ' },
    { word: 'moralisation', word_class: 'N' },
    { word: 'moralization', word_class: 'N' }
  ],
  masticate: [
    { word: 'mastic', word_class: 'N' },
    { word: 'masticate', word_class: 'V' },
    { word: 'mastication', word_class: 'N' }
  ],
  mythological: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  suitably: [
    { word: 'suitable', word_class: 'AJ' },
    { word: 'suitably', word_class: 'AV' },
    { word: 'suitability', word_class: 'N' },
    { word: 'suitableness', word_class: 'N' }
  ],
  murderously: [
    { word: 'murder', word_class: 'N' },
    { word: 'murder', word_class: 'V' },
    { word: 'murderer', word_class: 'N' },
    { word: 'murdered', word_class: 'AJ' },
    { word: 'murderous', word_class: 'AJ' },
    { word: 'murderously', word_class: 'AV' },
    { word: 'murderousness', word_class: 'N' }
  ],
  incapacity: [
    { word: 'incapacity', word_class: 'N' },
    { word: 'incapacitate', word_class: 'V' },
    { word: 'incapacitated', word_class: 'AJ' },
    { word: 'incapacitation', word_class: 'N' },
    { word: 'incapacitating', word_class: 'AJ' }
  ],
  slack: [
    { word: 'slack', word_class: 'N' },
    { word: 'slack', word_class: 'V' },
    { word: 'slack', word_class: 'AJ' },
    { word: 'slacks', word_class: 'N' },
    { word: 'slacker', word_class: 'N' },
    { word: 'slacking', word_class: 'N' },
    { word: 'slackness', word_class: 'N' }
  ],
  agonize: [
    { word: 'agone', word_class: 'AJ' },
    { word: 'agonal', word_class: 'AJ' },
    { word: 'agonist', word_class: 'N' },
    { word: 'agonize', word_class: 'V' },
    { word: 'agonized', word_class: 'AJ' },
    { word: 'agonistic', word_class: 'AJ' },
    { word: 'agonizing', word_class: 'AJ' },
    { word: 'agonization', word_class: 'N' },
    { word: 'agonistical', word_class: 'AJ' }
  ],
  communalization: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  proselytise: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  highlighting: [
    { word: 'highlight', word_class: 'N' },
    { word: 'highlight', word_class: 'V' },
    { word: 'highlighting', word_class: 'N' }
  ],
  involve: [
    { word: 'involve', word_class: 'V' },
    { word: 'involved', word_class: 'AJ' },
    { word: 'involvement', word_class: 'N' }
  ],
  match: [
    { word: 'match', word_class: 'N' },
    { word: 'match', word_class: 'V' },
    { word: 'matched', word_class: 'AJ' },
    { word: 'matching', word_class: 'AJ' }
  ],
  deprived: [
    { word: 'deprive', word_class: 'V' },
    { word: 'deprived', word_class: 'AJ' },
    { word: 'deprivation', word_class: 'N' }
  ],
  understandably: [
    { word: 'understand', word_class: 'N' },
    { word: 'understand', word_class: 'V' },
    { word: 'understanding', word_class: 'N' },
    { word: 'understanding', word_class: 'AJ' },
    { word: 'understandable', word_class: 'AJ' },
    { word: 'understandably', word_class: 'AV' },
    { word: 'understandability', word_class: 'N' }
  ],
  fatality: [
    { word: 'fatal', word_class: 'AJ' },
    { word: 'fatalism', word_class: 'N' },
    { word: 'fatality', word_class: 'N' },
    { word: 'fatally', word_class: 'AV' }
  ],
  horizontal: [
    { word: 'horizontal', word_class: 'N' },
    { word: 'horizontal', word_class: 'AJ' },
    { word: 'horizontality', word_class: 'N' },
    { word: 'horizontally', word_class: 'AV' }
  ],
  peddler: [
    { word: 'peddle', word_class: 'V' },
    { word: 'peddler', word_class: 'N' },
    { word: 'peddling', word_class: 'N' }
  ],
  advisory: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  jingle: [
    { word: 'jingle', word_class: 'N' },
    { word: 'jingle', word_class: 'V' },
    { word: 'jingling', word_class: 'AJ' }
  ],
  universality: [
    { word: 'universe', word_class: 'N' },
    { word: 'universe', word_class: 'V' },
    { word: 'universal', word_class: 'N' },
    { word: 'universal', word_class: 'AJ' },
    { word: 'university', word_class: 'N' },
    { word: 'universalise', word_class: 'V' },
    { word: 'universalize', word_class: 'V' },
    { word: 'universalism', word_class: 'N' },
    { word: 'universality', word_class: 'N' },
    { word: 'universally', word_class: 'AV' },
    { word: 'universalisation', word_class: 'N' },
    { word: 'universalization', word_class: 'N' }
  ],
  procurement: [
    { word: 'procure', word_class: 'N' },
    { word: 'procure', word_class: 'V' },
    { word: 'procural', word_class: 'N' },
    { word: 'procurer', word_class: 'N' },
    { word: 'procurance', word_class: 'N' },
    { word: 'procurator', word_class: 'N' },
    { word: 'procurable', word_class: 'AJ' },
    { word: 'procurement', word_class: 'N' }
  ],
  amplifier: [
    { word: 'amplify', word_class: 'V' },
    { word: 'amplifier', word_class: 'N' },
    { word: 'amplification', word_class: 'N' }
  ],
  defensively: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  bulging: [
    { word: 'bulge', word_class: 'N' },
    { word: 'bulge', word_class: 'V' },
    { word: 'bulging', word_class: 'AJ' }
  ],
  contender: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  echoing: [
    { word: 'echo', word_class: 'N' },
    { word: 'echo', word_class: 'V' },
    { word: 'echoing', word_class: 'AJ' }
  ],
  deoxidise: [
    { word: 'deoxidise', word_class: 'V' },
    { word: 'deoxidize', word_class: 'V' },
    { word: 'deoxidisation', word_class: 'N' },
    { word: 'deoxidization', word_class: 'N' }
  ],
  relocated: [
    { word: 'relocate', word_class: 'V' },
    { word: 'relocated', word_class: 'AJ' },
    { word: 'relocation', word_class: 'N' }
  ],
  corporate: [
    { word: 'corporal', word_class: 'N' },
    { word: 'corporal', word_class: 'AJ' },
    { word: 'corporate', word_class: 'AJ' },
    { word: 'corporeal', word_class: 'AJ' },
    { word: 'corporation', word_class: 'N' },
    { word: 'corporeality', word_class: 'N' }
  ],
  incipience: [
    { word: 'incipience', word_class: 'N' },
    { word: 'incipiency', word_class: 'N' },
    { word: 'incipient', word_class: 'AJ' }
  ],
  knacker: [
    { word: 'knacker', word_class: 'N' },
    { word: 'knacker', word_class: 'V' },
    { word: 'knackery', word_class: 'N' }
  ],
  overburden: [
    { word: 'overburden', word_class: 'N' },
    { word: 'overburden', word_class: 'V' },
    { word: 'overburdened', word_class: 'AJ' }
  ],
  torturer: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  deportation: [
    { word: 'deport', word_class: 'V' },
    { word: 'deportee', word_class: 'N' },
    { word: 'deportment', word_class: 'N' },
    { word: 'deportation', word_class: 'N' }
  ],
  patronized: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  offer: [
    { word: 'offer', word_class: 'N' },
    { word: 'offer', word_class: 'V' },
    { word: 'offering', word_class: 'N' }
  ],
  pensioner: [
    { word: 'pension', word_class: 'N' },
    { word: 'pension', word_class: 'V' },
    { word: 'pensioner', word_class: 'N' },
    { word: 'pensionable', word_class: 'AJ' }
  ],
  pastorate: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  hospitable: [
    { word: 'hospital', word_class: 'N' },
    { word: 'hospitable', word_class: 'AJ' },
    { word: 'hospitably', word_class: 'AV' },
    { word: 'hospitalize', word_class: 'V' },
    { word: 'hospitality', word_class: 'N' },
    { word: 'hospitableness', word_class: 'N' },
    { word: 'hospitalization', word_class: 'N' }
  ],
  caseful: [
    { word: 'case', word_class: 'N' },
    { word: 'case', word_class: 'V' },
    { word: 'cased', word_class: 'AJ' },
    { word: 'casing', word_class: 'N' },
    { word: 'caseful', word_class: 'N' },
    { word: 'casement', word_class: 'N' }
  ],
  refulgency: [
    { word: 'refulgence', word_class: 'N' },
    { word: 'refulgency', word_class: 'N' },
    { word: 'refulgent', word_class: 'AJ' }
  ],
  reprievement: [
    { word: 'reprieve', word_class: 'N' },
    { word: 'reprieve', word_class: 'V' },
    { word: 'reprievement', word_class: 'N' }
  ],
  calculate: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  uninformatively: [
    { word: 'uninformed', word_class: 'AJ' },
    { word: 'uninformative', word_class: 'AJ' },
    { word: 'uninformatively', word_class: 'AV' }
  ],
  nauseating: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  thatcher: [
    { word: 'thatch', word_class: 'N' },
    { word: 'thatch', word_class: 'V' },
    { word: 'thatcher', word_class: 'N' }
  ],
  disfigured: [
    { word: 'disfigure', word_class: 'V' },
    { word: 'disfigured', word_class: 'AJ' },
    { word: 'disfiguration', word_class: 'N' },
    { word: 'disfigurement', word_class: 'N' }
  ],
  repudiative: [
    { word: 'repudiate', word_class: 'V' },
    { word: 'repudiated', word_class: 'AJ' },
    { word: 'repudiation', word_class: 'N' },
    { word: 'repudiative', word_class: 'AJ' }
  ],
  immoral: [
    { word: 'immoral', word_class: 'AJ' },
    { word: 'immorality', word_class: 'N' },
    { word: 'immorally', word_class: 'AV' }
  ],
  cutinize: [
    { word: 'cutinise', word_class: 'V' },
    { word: 'cutinize', word_class: 'V' },
    { word: 'cutinisation', word_class: 'N' },
    { word: 'cutinization', word_class: 'N' }
  ],
  hearts: [
    { word: 'heart', word_class: 'N' },
    { word: 'hearts', word_class: 'N' },
    { word: 'hearted', word_class: 'AJ' }
  ],
  journalize: [
    { word: 'journal', word_class: 'N' },
    { word: 'journalese', word_class: 'N' },
    { word: 'journalize', word_class: 'V' },
    { word: 'journalise', word_class: 'V' },
    { word: 'journalism', word_class: 'N' },
    { word: 'journalisation', word_class: 'N' },
    { word: 'journalization', word_class: 'N' }
  ],
  implausibly: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  mystified: [
    { word: 'mystify', word_class: 'V' },
    { word: 'mystified', word_class: 'AJ' },
    { word: 'mystifying', word_class: 'AJ' },
    { word: 'mystification', word_class: 'N' }
  ],
  analyser: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  completing: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  acceptableness: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  regulating: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  atrophy: [
    { word: 'atrophy', word_class: 'N' },
    { word: 'atrophy', word_class: 'V' },
    { word: 'atrophic', word_class: 'AJ' },
    { word: 'atrophied', word_class: 'AJ' }
  ],
  debilitative: [
    { word: 'debilitate', word_class: 'V' },
    { word: 'debilitated', word_class: 'AJ' },
    { word: 'debilitation', word_class: 'N' },
    { word: 'debilitating', word_class: 'AJ' },
    { word: 'debilitative', word_class: 'AJ' }
  ],
  opsonization: [
    { word: 'opsonize', word_class: 'V' },
    { word: 'opsonisation', word_class: 'N' },
    { word: 'opsonization', word_class: 'N' },
    { word: 'opsonification', word_class: 'N' }
  ],
  unmanageable: [
    { word: 'unmanageable', word_class: 'AJ' },
    { word: 'unmanageably', word_class: 'AV' },
    { word: 'unmanageableness', word_class: 'N' }
  ],
  ascetically: [
    { word: 'ascetic', word_class: 'N' },
    { word: 'ascetic', word_class: 'AJ' },
    { word: 'ascetical', word_class: 'AJ' },
    { word: 'ascetically', word_class: 'AV' }
  ],
  remanence: [
    { word: 'remain', word_class: 'V' },
    { word: 'remain', word_class: 'AJ' },
    { word: 'remains', word_class: 'N' },
    { word: 'remainder', word_class: 'N' },
    { word: 'remanence', word_class: 'N' },
    { word: 'remainder', word_class: 'V' },
    { word: 'remaining', word_class: 'AJ' }
  ],
  coopt: [
    { word: 'coopt', word_class: 'V' },
    { word: 'co-opt', word_class: 'V' },
    { word: 'co-option', word_class: 'N' },
    { word: 'co-optation', word_class: 'N' }
  ],
  pertinence: [
    { word: 'pertain', word_class: 'V' },
    { word: 'pertinence', word_class: 'N' },
    { word: 'pertinency', word_class: 'N' },
    { word: 'pertinent', word_class: 'AJ' },
    { word: 'pertinacity', word_class: 'N' },
    { word: 'pertinently', word_class: 'AV' }
  ],
  gracious: [
    { word: 'grace', word_class: 'N' },
    { word: 'grace', word_class: 'V' },
    { word: 'graces', word_class: 'N' },
    { word: 'gracious', word_class: 'AJ' },
    { word: 'graceful', word_class: 'AJ' },
    { word: 'gracefulness', word_class: 'N' }
  ],
  jingling: [
    { word: 'jingle', word_class: 'N' },
    { word: 'jingle', word_class: 'V' },
    { word: 'jingling', word_class: 'AJ' }
  ],
  imprisonment: [
    { word: 'imprison', word_class: 'N' },
    { word: 'imprison', word_class: 'V' },
    { word: 'imprisoned', word_class: 'AJ' },
    { word: 'imprisonment', word_class: 'N' }
  ],
  freewheeler: [
    { word: 'freewheel', word_class: 'V' },
    { word: 'freewheeler', word_class: 'N' },
    { word: 'freewheeling', word_class: 'AJ' }
  ],
  hebraise: [
    { word: 'hebraise', word_class: 'V' },
    { word: 'hebraize', word_class: 'V' },
    { word: 'hebraisation', word_class: 'N' },
    { word: 'hebraization', word_class: 'N' }
  ],
  attenuation: [
    { word: 'attenuate', word_class: 'V' },
    { word: 'attenuate', word_class: 'AJ' },
    { word: 'attenuated', word_class: 'AJ' },
    { word: 'attenuation', word_class: 'N' }
  ],
  roman: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  aspirate: [
    { word: 'aspire', word_class: 'V' },
    { word: 'aspirant', word_class: 'N' },
    { word: 'aspirate', word_class: 'V' },
    { word: 'aspirate', word_class: 'N' },
    { word: 'aspirant', word_class: 'AJ' },
    { word: 'aspirator', word_class: 'N' },
    { word: 'aspiring', word_class: 'AJ' },
    { word: 'aspiration', word_class: 'N' }
  ],
  testee: [
    { word: 'test', word_class: 'N' },
    { word: 'test', word_class: 'V' },
    { word: 'test', word_class: 'AJ' },
    { word: 'testee', word_class: 'N' },
    { word: 'tester', word_class: 'N' },
    { word: 'tested', word_class: 'AJ' },
    { word: 'testing', word_class: 'N' }
  ],
  potentiality: [
    { word: 'potential', word_class: 'N' },
    { word: 'potential', word_class: 'AJ' },
    { word: 'potentiate', word_class: 'V' },
    { word: 'potentiality', word_class: 'N' },
    { word: 'potentially', word_class: 'AV' },
    { word: 'potentiation', word_class: 'N' }
  ],
  infect: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  hydrogen: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  disagreement: [
    { word: 'disagree', word_class: 'V' },
    { word: 'disagreement', word_class: 'N' },
    { word: 'disagreeable', word_class: 'AJ' },
    { word: 'disagreeably', word_class: 'AV' },
    { word: 'disagreeableness', word_class: 'N' }
  ],
  foresightful: [
    { word: 'foresee', word_class: 'V' },
    { word: 'foreseen', word_class: 'AJ' },
    { word: 'foresight', word_class: 'N' },
    { word: 'foreseeable', word_class: 'AJ' },
    { word: 'foresighted', word_class: 'AJ' },
    { word: 'foresightful', word_class: 'AJ' },
    { word: 'foresightfulness', word_class: 'N' }
  ],
  splatter: [
    { word: 'splatter', word_class: 'N' },
    { word: 'splatter', word_class: 'V' },
    { word: 'splattered', word_class: 'AJ' },
    { word: 'splattering', word_class: 'N' }
  ],
  numbering: [
    { word: 'number', word_class: 'N' },
    { word: 'number', word_class: 'V' },
    { word: 'numbers', word_class: 'N' },
    { word: 'numbering', word_class: 'N' }
  ],
  specialty: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  tutorially: [
    { word: 'tutor', word_class: 'N' },
    { word: 'tutor', word_class: 'V' },
    { word: 'tutelage', word_class: 'N' },
    { word: 'tutorial', word_class: 'N' },
    { word: 'tutoring', word_class: 'N' },
    { word: 'tutored', word_class: 'AJ' },
    { word: 'tutorial', word_class: 'AJ' },
    { word: 'tutorship', word_class: 'N' },
    { word: 'tutorially', word_class: 'AV' }
  ],
  suspected: [
    { word: 'suspect', word_class: 'N' },
    { word: 'suspect', word_class: 'V' },
    { word: 'suspect', word_class: 'AJ' },
    { word: 'suspicion', word_class: 'N' },
    { word: 'suspected', word_class: 'AJ' }
  ],
  imbecilic: [
    { word: 'imbecile', word_class: 'N' },
    { word: 'imbecile', word_class: 'AJ' },
    { word: 'imbecilic', word_class: 'AJ' },
    { word: 'imbecility', word_class: 'N' }
  ],
  hemisphere: [
    { word: 'hemisphere', word_class: 'N' },
    { word: 'hemispheric', word_class: 'AJ' },
    { word: 'hemispherical', word_class: 'AJ' }
  ],
  adder: [
    { word: 'add', word_class: 'V' },
    { word: 'adder', word_class: 'N' },
    { word: 'addition', word_class: 'N' },
    { word: 'additive', word_class: 'N' },
    { word: 'additive', word_class: 'AJ' },
    { word: 'additional', word_class: 'AJ' }
  ],
  practicability: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  axis: [{ word: 'axis', word_class: 'N' }, { word: 'axial', word_class: 'AJ' }, { word: 'axially', word_class: 'AV' }],
  refilling: [
    { word: 'refill', word_class: 'N' },
    { word: 'refill', word_class: 'V' },
    { word: 'refilling', word_class: 'N' }
  ],
  philosopher: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  caller: [
    { word: 'call', word_class: 'N' },
    { word: 'call', word_class: 'V' },
    { word: 'caller', word_class: 'N' },
    { word: 'called', word_class: 'AJ' },
    { word: 'caller', word_class: 'AJ' },
    { word: 'calling', word_class: 'N' }
  ],
  decolorisation: [
    { word: 'decolor', word_class: 'V' },
    { word: 'decolour', word_class: 'V' },
    { word: 'decolorize', word_class: 'V' },
    { word: 'decoloration', word_class: 'N' },
    { word: 'decolorisation', word_class: 'N' },
    { word: 'decolorization', word_class: 'N' }
  ],
  inconsequent: [
    { word: 'inconsequence', word_class: 'N' },
    { word: 'inconsequent', word_class: 'AJ' },
    { word: 'inconsequently', word_class: 'AV' }
  ],
  slice: [
    { word: 'slice', word_class: 'N' },
    { word: 'slice', word_class: 'V' },
    { word: 'slicer', word_class: 'N' },
    { word: 'sliced', word_class: 'AJ' },
    { word: 'slicing', word_class: 'N' }
  ],
  object: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  obstinately: [
    { word: 'obstinacy', word_class: 'N' },
    { word: 'obstinance', word_class: 'N' },
    { word: 'obstinate', word_class: 'AJ' },
    { word: 'obstinately', word_class: 'AV' }
  ],
  butcher: [
    { word: 'butcher', word_class: 'N' },
    { word: 'butcher', word_class: 'V' },
    { word: 'butchery', word_class: 'N' },
    { word: 'butchering', word_class: 'N' }
  ],
  assassinate: [
    { word: 'assassin', word_class: 'N' },
    { word: 'assassinate', word_class: 'V' },
    { word: 'assassinated', word_class: 'AJ' },
    { word: 'assassination', word_class: 'N' }
  ],
  pyrotechnics: [
    { word: 'pyrotechnic', word_class: 'AJ' },
    { word: 'pyrotechnics', word_class: 'N' },
    { word: 'pyrotechnical', word_class: 'AJ' }
  ],
  chest: [
    { word: 'chest', word_class: 'N' },
    { word: 'chested', word_class: 'AJ' },
    { word: 'chestful', word_class: 'N' },
    { word: 'chestful', word_class: 'AJ' }
  ],
  destruct: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  flex: [
    { word: 'flex', word_class: 'N' },
    { word: 'flex', word_class: 'V' },
    { word: 'flexor', word_class: 'N' },
    { word: 'flexion', word_class: 'N' },
    { word: 'flection', word_class: 'N' }
  ],
  wishing: [
    { word: 'wish', word_class: 'N' },
    { word: 'wish', word_class: 'V' },
    { word: 'wishing', word_class: 'N' },
    { word: 'wishful', word_class: 'AJ' },
    { word: 'wishfulness', word_class: 'N' }
  ],
  accommodation: [
    { word: 'accommodate', word_class: 'V' },
    { word: 'accommodation', word_class: 'N' },
    { word: 'accommodating', word_class: 'AJ' },
    { word: 'accommodative', word_class: 'AJ' },
    { word: 'accommodational', word_class: 'AJ' }
  ],
  insular: [
    { word: 'insular', word_class: 'AJ' },
    { word: 'insularism', word_class: 'N' },
    { word: 'insularity', word_class: 'N' }
  ],
  caudate: [
    { word: 'caudate', word_class: 'N' },
    { word: 'caudate', word_class: 'AJ' },
    { word: 'caudated', word_class: 'AJ' }
  ],
  indecent: [
    { word: 'indecency', word_class: 'N' },
    { word: 'indecent', word_class: 'AJ' },
    { word: 'indecently', word_class: 'AV' }
  ],
  socialite: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  'cross-questioning': [
    { word: 'cross-question', word_class: 'N' },
    { word: 'cross-question', word_class: 'V' },
    { word: 'cross-questioning', word_class: 'N' }
  ],
  perennial: [
    { word: 'perennial', word_class: 'N' },
    { word: 'perennial', word_class: 'AJ' },
    { word: 'perennially', word_class: 'AV' }
  ],
  filtration: [
    { word: 'filter', word_class: 'N' },
    { word: 'filter', word_class: 'V' },
    { word: 'filtrate', word_class: 'N' },
    { word: 'filtrate', word_class: 'V' },
    { word: 'filtration', word_class: 'N' }
  ],
  assort: [
    { word: 'assort', word_class: 'V' },
    { word: 'assorted', word_class: 'AJ' },
    { word: 'assortment', word_class: 'N' }
  ],
  residue: [
    { word: 'residue', word_class: 'N' },
    { word: 'residual', word_class: 'N' },
    { word: 'residual', word_class: 'AJ' },
    { word: 'residuary', word_class: 'AJ' }
  ],
  clatter: [
    { word: 'clatter', word_class: 'N' },
    { word: 'clatter', word_class: 'V' },
    { word: 'clattering', word_class: 'AJ' }
  ],
  condemnation: [
    { word: 'condemn', word_class: 'V' },
    { word: 'condemned', word_class: 'AJ' },
    { word: 'condemning', word_class: 'AJ' },
    { word: 'condemnable', word_class: 'AJ' },
    { word: 'condemnation', word_class: 'N' },
    { word: 'condemnatory', word_class: 'AJ' }
  ],
  emotionalization: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  narration: [
    { word: 'narrate', word_class: 'V' },
    { word: 'narrator', word_class: 'N' },
    { word: 'narration', word_class: 'N' },
    { word: 'narrative', word_class: 'N' },
    { word: 'narrative', word_class: 'AJ' }
  ],
  emotionality: [
    { word: 'emotionalise', word_class: 'V' },
    { word: 'emotionalism', word_class: 'N' },
    { word: 'emotionalize', word_class: 'V' },
    { word: 'emotionality', word_class: 'N' },
    { word: 'emotionally', word_class: 'AV' },
    { word: 'emotionalisation', word_class: 'N' },
    { word: 'emotionalization', word_class: 'N' }
  ],
  boss: [
    { word: 'boss', word_class: 'N' },
    { word: 'boss', word_class: 'V' },
    { word: 'boss', word_class: 'AJ' },
    { word: 'bossism', word_class: 'N' }
  ],
  cohere: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  secretively: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  laughter: [
    { word: 'laugh', word_class: 'N' },
    { word: 'laugh', word_class: 'V' },
    { word: 'laugher', word_class: 'N' },
    { word: 'laughter', word_class: 'N' },
    { word: 'laughing', word_class: 'AJ' }
  ],
  cubic: [
    { word: 'cubic', word_class: 'AJ' },
    { word: 'cubical', word_class: 'AJ' },
    { word: 'cubicity', word_class: 'N' }
  ],
  indefatigably: [
    { word: 'indefatigable', word_class: 'AJ' },
    { word: 'indefatigably', word_class: 'AV' },
    { word: 'indefatigation', word_class: 'N' },
    { word: 'indefatigability', word_class: 'N' },
    { word: 'indefatigableness', word_class: 'N' }
  ],
  snuffle: [
    { word: 'snuffle', word_class: 'N' },
    { word: 'snuffle', word_class: 'V' },
    { word: 'snuffly', word_class: 'AJ' },
    { word: 'snuffling', word_class: 'AJ' }
  ],
  imminence: [
    { word: 'imminence', word_class: 'N' },
    { word: 'imminency', word_class: 'N' },
    { word: 'imminent', word_class: 'AJ' },
    { word: 'imminently', word_class: 'AV' }
  ],
  asking: [
    { word: 'ask', word_class: 'V' },
    { word: 'asker', word_class: 'N' },
    { word: 'asking', word_class: 'N' },
    { word: 'asking', word_class: 'AJ' }
  ],
  licentiousness: [
    { word: 'licentiate', word_class: 'N' },
    { word: 'licentious', word_class: 'AJ' },
    { word: 'licentiously', word_class: 'AV' },
    { word: 'licentiousness', word_class: 'N' }
  ],
  citified: [
    { word: 'city', word_class: 'N' },
    { word: 'citify', word_class: 'V' },
    { word: 'cityfy', word_class: 'V' },
    { word: 'citified', word_class: 'AJ' },
    { word: 'cityfied', word_class: 'AJ' },
    { word: 'citification', word_class: 'N' },
    { word: 'cityfication', word_class: 'N' }
  ],
  mincer: [
    { word: 'mince', word_class: 'N' },
    { word: 'mince', word_class: 'V' },
    { word: 'mincer', word_class: 'N' },
    { word: 'mincing', word_class: 'AJ' }
  ],
  flexibly: [
    { word: 'flexible', word_class: 'AJ' },
    { word: 'flexibly', word_class: 'AV' },
    { word: 'flexibility', word_class: 'N' },
    { word: 'flexibleness', word_class: 'N' }
  ],
  succession: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  imminently: [
    { word: 'imminence', word_class: 'N' },
    { word: 'imminency', word_class: 'N' },
    { word: 'imminent', word_class: 'AJ' },
    { word: 'imminently', word_class: 'AV' }
  ],
  abrade: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  active: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  imaginable: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  sprawled: [
    { word: 'sprawl', word_class: 'N' },
    { word: 'sprawl', word_class: 'V' },
    { word: 'sprawled', word_class: 'AJ' },
    { word: 'sprawling', word_class: 'N' },
    { word: 'sprawling', word_class: 'AJ' }
  ],
  tollman: [
    { word: 'toll', word_class: 'N' },
    { word: 'toll', word_class: 'V' },
    { word: 'toller', word_class: 'N' },
    { word: 'tollman', word_class: 'N' }
  ],
  snub: [{ word: 'snub', word_class: 'N' }, { word: 'snub', word_class: 'V' }, { word: 'snub', word_class: 'AJ' }],
  apocope: [
    { word: 'apocope', word_class: 'N' },
    { word: 'apocopate', word_class: 'V' },
    { word: 'apocopation', word_class: 'N' }
  ],
  impartially: [
    { word: 'impartial', word_class: 'AJ' },
    { word: 'impartiality', word_class: 'N' },
    { word: 'impartially', word_class: 'AV' }
  ],
  crate: [
    { word: 'crate', word_class: 'N' },
    { word: 'crate', word_class: 'V' },
    { word: 'crater', word_class: 'N' },
    { word: 'crater', word_class: 'V' },
    { word: 'crateful', word_class: 'N' }
  ],
  lateral: [
    { word: 'later', word_class: 'AJ' },
    { word: 'later', word_class: 'AV' },
    { word: 'lateral', word_class: 'N' },
    { word: 'lateral', word_class: 'AJ' },
    { word: 'laterality', word_class: 'N' },
    { word: 'laterally', word_class: 'AV' }
  ],
  servile: [
    { word: 'servile', word_class: 'AJ' },
    { word: 'servility', word_class: 'N' },
    { word: 'servilely', word_class: 'AV' }
  ],
  sunny: [
    { word: 'sunni', word_class: 'N' },
    { word: 'sunny', word_class: 'AJ' },
    { word: 'sunniness', word_class: 'N' }
  ],
  fare: [
    { word: 'fare', word_class: 'V' },
    { word: 'far', word_class: 'AJ' },
    { word: 'far', word_class: 'AV' },
    { word: 'fare', word_class: 'N' },
    { word: 'farness', word_class: 'N' }
  ],
  conceived: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  disqualified: [
    { word: 'disqualify', word_class: 'V' },
    { word: 'disqualified', word_class: 'AJ' },
    { word: 'disqualifying', word_class: 'N' },
    { word: 'disqualifying', word_class: 'AJ' },
    { word: 'disqualification', word_class: 'N' }
  ],
  consensual: [
    { word: 'consent', word_class: 'N' },
    { word: 'consent', word_class: 'V' },
    { word: 'consensus', word_class: 'N' },
    { word: 'consensual', word_class: 'AJ' },
    { word: 'consenting', word_class: 'AJ' }
  ],
  substantialness: [
    { word: 'substance', word_class: 'N' },
    { word: 'substantial', word_class: 'AJ' },
    { word: 'substantiate', word_class: 'V' },
    { word: 'substantiation', word_class: 'N' },
    { word: 'substantially', word_class: 'AV' },
    { word: 'substantiality', word_class: 'N' },
    { word: 'substantiated', word_class: 'AJ' },
    { word: 'substantialness', word_class: 'N' },
    { word: 'substantiating', word_class: 'AJ' },
    { word: 'substantiative', word_class: 'AJ' }
  ],
  tartarisation: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  toxicological: [
    { word: 'toxicology', word_class: 'N' },
    { word: 'toxicologic', word_class: 'AJ' },
    { word: 'toxicological', word_class: 'AJ' }
  ],
  bouncing: [
    { word: 'bounce', word_class: 'N' },
    { word: 'bounce', word_class: 'V' },
    { word: 'bouncy', word_class: 'AJ' },
    { word: 'bouncer', word_class: 'N' },
    { word: 'bouncing', word_class: 'N' },
    { word: 'bouncing', word_class: 'AJ' },
    { word: 'bounciness', word_class: 'N' }
  ],
  divided: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  militance: [
    { word: 'militant', word_class: 'N' },
    { word: 'militate', word_class: 'V' },
    { word: 'militance', word_class: 'N' },
    { word: 'militancy', word_class: 'N' },
    { word: 'militant', word_class: 'AJ' },
    { word: 'militation', word_class: 'N' }
  ],
  alone: [
    { word: 'alone', word_class: 'AJ' },
    { word: 'alone', word_class: 'AV' },
    { word: 'aloneness', word_class: 'N' }
  ],
  masterful: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  obligational: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  exponentiation: [
    { word: 'exponent', word_class: 'N' },
    { word: 'exponential', word_class: 'N' },
    { word: 'exponential', word_class: 'AJ' },
    { word: 'exponentiate', word_class: 'V' },
    { word: 'exponentially', word_class: 'AV' },
    { word: 'exponentiation', word_class: 'N' }
  ],
  arguer: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  prematurity: [
    { word: 'premature', word_class: 'AJ' },
    { word: 'prematurity', word_class: 'N' },
    { word: 'prematurely', word_class: 'AV' },
    { word: 'prematureness', word_class: 'N' }
  ],
  sectarianism: [
    { word: 'sectarian', word_class: 'N' },
    { word: 'sectarian', word_class: 'AJ' },
    { word: 'sectarianism', word_class: 'N' }
  ],
  homiletical: [
    { word: 'homiletic', word_class: 'AJ' },
    { word: 'homiletics', word_class: 'N' },
    { word: 'homiletical', word_class: 'AJ' }
  ],
  isomerize: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  shucks: [{ word: 'shuck', word_class: 'N' }, { word: 'shuck', word_class: 'V' }, { word: 'shucks', word_class: 'N' }],
  brooder: [
    { word: 'brood', word_class: 'N' },
    { word: 'brood', word_class: 'V' },
    { word: 'brood', word_class: 'AJ' },
    { word: 'brooder', word_class: 'N' },
    { word: 'brooding', word_class: 'N' },
    { word: 'brooding', word_class: 'AJ' }
  ],
  torrefaction: [
    { word: 'torrefy', word_class: 'V' },
    { word: 'torrify', word_class: 'V' },
    { word: 'torrefaction', word_class: 'N' },
    { word: 'torrifaction', word_class: 'N' }
  ],
  militarized: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  anagogic: [
    { word: 'anagoge', word_class: 'N' },
    { word: 'anagogic', word_class: 'AJ' },
    { word: 'anagogical', word_class: 'AJ' }
  ],
  jostlement: [
    { word: 'jostle', word_class: 'N' },
    { word: 'jostle', word_class: 'V' },
    { word: 'justle', word_class: 'V' },
    { word: 'jostling', word_class: 'N' },
    { word: 'jostlement', word_class: 'N' },
    { word: 'justlement', word_class: 'N' }
  ],
  dexterity: [
    { word: 'dexter', word_class: 'AJ' },
    { word: 'dexterity', word_class: 'N' },
    { word: 'dexterous', word_class: 'AJ' },
    { word: 'dexterously', word_class: 'AV' }
  ],
  syllabically: [
    { word: 'syllabic', word_class: 'N' },
    { word: 'syllable', word_class: 'N' },
    { word: 'syllabic', word_class: 'AJ' },
    { word: 'syllabify', word_class: 'V' },
    { word: 'syllabize', word_class: 'V' },
    { word: 'syllabled', word_class: 'AJ' },
    { word: 'syllabicate', word_class: 'V' },
    { word: 'syllabicity', word_class: 'N' },
    { word: 'syllabically', word_class: 'AV' },
    { word: 'syllabication', word_class: 'N' },
    { word: 'syllabification', word_class: 'N' },
    { word: 'syllabification', word_class: 'V' }
  ],
  jape: [{ word: 'jape', word_class: 'N' }, { word: 'jape', word_class: 'V' }, { word: 'japery', word_class: 'N' }],
  delay: [
    { word: 'delay', word_class: 'N' },
    { word: 'delay', word_class: 'V' },
    { word: 'delayed', word_class: 'AJ' }
  ],
  importunate: [
    { word: 'importune', word_class: 'V' },
    { word: 'importunacy', word_class: 'N' },
    { word: 'importunity', word_class: 'N' },
    { word: 'importunate', word_class: 'AJ' },
    { word: 'importunately', word_class: 'AV' }
  ],
  regressing: [
    { word: 'regress', word_class: 'N' },
    { word: 'regress', word_class: 'V' },
    { word: 'regression', word_class: 'N' },
    { word: 'regressing', word_class: 'AJ' },
    { word: 'regressive', word_class: 'AJ' }
  ],
  brawl: [
    { word: 'brawl', word_class: 'N' },
    { word: 'brawl', word_class: 'V' },
    { word: 'brawler', word_class: 'N' },
    { word: 'brawling', word_class: 'AJ' }
  ],
  stigmatist: [
    { word: 'stigmatic', word_class: 'N' },
    { word: 'stigmatise', word_class: 'V' },
    { word: 'stigmatize', word_class: 'V' },
    { word: 'stigmatist', word_class: 'N' },
    { word: 'stigmatic', word_class: 'AJ' },
    { word: 'stigmatism', word_class: 'N' },
    { word: 'stigmatisation', word_class: 'N' },
    { word: 'stigmatization', word_class: 'N' }
  ],
  breaker: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  up: [
    { word: 'up', word_class: 'V' },
    { word: 'up', word_class: 'AJ' },
    { word: 'up', word_class: 'AV' },
    { word: 'ups', word_class: 'V' }
  ],
  rapturous: [
    { word: 'rapture', word_class: 'N' },
    { word: 'rapture', word_class: 'V' },
    { word: 'rapturous', word_class: 'AJ' },
    { word: 'rapturously', word_class: 'AV' }
  ],
  revelation: [
    { word: 'revel', word_class: 'N' },
    { word: 'revel', word_class: 'V' },
    { word: 'reveal', word_class: 'N' },
    { word: 'reveal', word_class: 'V' },
    { word: 'reveler', word_class: 'N' },
    { word: 'revelry', word_class: 'N' },
    { word: 'reveller', word_class: 'N' },
    { word: 'revealed', word_class: 'AJ' },
    { word: 'revelment', word_class: 'N' },
    { word: 'revealing', word_class: 'N' },
    { word: 'revealing', word_class: 'AJ' },
    { word: 'revelation', word_class: 'N' },
    { word: 'revelatory', word_class: 'AJ' }
  ],
  openness: [
    { word: 'open', word_class: 'N' },
    { word: 'open', word_class: 'V' },
    { word: 'open', word_class: 'AJ' },
    { word: 'opener', word_class: 'N' },
    { word: 'opening', word_class: 'N' },
    { word: 'opened', word_class: 'AJ' },
    { word: 'opening', word_class: 'AJ' },
    { word: 'openness', word_class: 'N' }
  ],
  onerousness: [
    { word: 'onerous', word_class: 'AJ' },
    { word: 'onerously', word_class: 'AV' },
    { word: 'onerousness', word_class: 'N' }
  ],
  metaphysical: [
    { word: 'metaphysics', word_class: 'N' },
    { word: 'metaphysical', word_class: 'AJ' },
    { word: 'metaphysically', word_class: 'AV' }
  ],
  displeasure: [
    { word: 'displease', word_class: 'V' },
    { word: 'displeased', word_class: 'AJ' },
    { word: 'displeasure', word_class: 'N' },
    { word: 'displeasing', word_class: 'AJ' }
  ],
  remonetisation: [
    { word: 'remonetise', word_class: 'V' },
    { word: 'remonetize', word_class: 'V' },
    { word: 'remonetisation', word_class: 'N' },
    { word: 'remonetization', word_class: 'N' }
  ],
  comic: [
    { word: 'comic', word_class: 'N' },
    { word: 'comic', word_class: 'AJ' },
    { word: 'comical', word_class: 'AJ' },
    { word: 'comically', word_class: 'AV' }
  ],
  bilingually: [
    { word: 'bilingual', word_class: 'N' },
    { word: 'bilingual', word_class: 'AJ' },
    { word: 'bilingually', word_class: 'AV' }
  ],
  romantically: [
    { word: 'romance', word_class: 'N' },
    { word: 'romance', word_class: 'V' },
    { word: 'romance', word_class: 'AJ' },
    { word: 'romantic', word_class: 'N' },
    { word: 'romantic', word_class: 'AJ' },
    { word: 'romantically', word_class: 'AV' }
  ],
  temperament: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  traditionalist: [
    { word: 'traditionalist', word_class: 'N' },
    { word: 'traditionalist', word_class: 'AJ' },
    { word: 'traditionalistic', word_class: 'AJ' }
  ],
  unpalatably: [
    { word: 'unpalatable', word_class: 'AJ' },
    { word: 'unpalatably', word_class: 'AV' },
    { word: 'unpalatability', word_class: 'N' },
    { word: 'unpalatableness', word_class: 'N' }
  ],
  halter: [
    { word: 'halt', word_class: 'N' },
    { word: 'halt', word_class: 'V' },
    { word: 'halt', word_class: 'AJ' },
    { word: 'halter', word_class: 'N' },
    { word: 'halter', word_class: 'V' },
    { word: 'halted', word_class: 'AJ' },
    { word: 'haltere', word_class: 'N' },
    { word: 'halting', word_class: 'AJ' }
  ],
  philosophization: [
    { word: 'philosopher', word_class: 'N' },
    { word: 'philosophise', word_class: 'V' },
    { word: 'philosophic', word_class: 'AJ' },
    { word: 'philosophize', word_class: 'V' },
    { word: 'philosophical', word_class: 'AJ' },
    { word: 'philosophizing', word_class: 'N' },
    { word: 'philosophically', word_class: 'AV' },
    { word: 'philosophisation', word_class: 'N' },
    { word: 'philosophization', word_class: 'N' }
  ],
  depolarization: [
    { word: 'depolarise', word_class: 'V' },
    { word: 'depolarize', word_class: 'V' },
    { word: 'depolarisation', word_class: 'N' },
    { word: 'depolarization', word_class: 'N' }
  ],
  revolution: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  ethics: [
    { word: 'ethic', word_class: 'N' },
    { word: 'ethics', word_class: 'N' },
    { word: 'ethical', word_class: 'AJ' },
    { word: 'ethically', word_class: 'AV' }
  ],
  somnolence: [
    { word: 'somnolence', word_class: 'N' },
    { word: 'somnolent', word_class: 'AJ' },
    { word: 'somnolently', word_class: 'AV' }
  ],
  reprise: [
    { word: 'reprise', word_class: 'N' },
    { word: 'reprise', word_class: 'V' },
    { word: 'reprisal', word_class: 'N' }
  ],
  determent: [
    { word: 'deter', word_class: 'V' },
    { word: 'determent', word_class: 'N' },
    { word: 'deterrent', word_class: 'N' },
    { word: 'deterrence', word_class: 'N' },
    { word: 'deterrent', word_class: 'AJ' }
  ],
  rescue: [
    { word: 'rescue', word_class: 'N' },
    { word: 'rescue', word_class: 'V' },
    { word: 'rescuer', word_class: 'N' },
    { word: 'rescued', word_class: 'AJ' }
  ],
  erroneousness: [
    { word: 'erroneous', word_class: 'AJ' },
    { word: 'erroneously', word_class: 'AV' },
    { word: 'erroneousness', word_class: 'N' }
  ],
  pedigreed: [
    { word: 'pedigree', word_class: 'N' },
    { word: 'pedigree', word_class: 'AJ' },
    { word: 'pedigreed', word_class: 'AJ' }
  ],
  amazed: [
    { word: 'amaze', word_class: 'V' },
    { word: 'amazed', word_class: 'AJ' },
    { word: 'amazing', word_class: 'AJ' },
    { word: 'amazement', word_class: 'N' }
  ],
  cinchonization: [
    { word: 'cinchonise', word_class: 'V' },
    { word: 'cinchonize', word_class: 'V' },
    { word: 'cinchonisation', word_class: 'N' },
    { word: 'cinchonization', word_class: 'N' }
  ],
  memorize: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  champion: [
    { word: 'champion', word_class: 'N' },
    { word: 'champion', word_class: 'V' },
    { word: 'champion', word_class: 'AJ' },
    { word: 'championship', word_class: 'N' }
  ],
  engulfed: [
    { word: 'engulf', word_class: 'V' },
    { word: 'engulfed', word_class: 'AJ' },
    { word: 'engulfment', word_class: 'N' }
  ],
  chomp: [
    { word: 'chomp', word_class: 'N' },
    { word: 'chomp', word_class: 'V' },
    { word: 'chomping', word_class: 'N' }
  ],
  fastener: [
    { word: 'fasten', word_class: 'V' },
    { word: 'fastener', word_class: 'N' },
    { word: 'fastened', word_class: 'AJ' },
    { word: 'fastening', word_class: 'N' }
  ],
  bounciness: [
    { word: 'bounce', word_class: 'N' },
    { word: 'bounce', word_class: 'V' },
    { word: 'bouncy', word_class: 'AJ' },
    { word: 'bouncer', word_class: 'N' },
    { word: 'bouncing', word_class: 'N' },
    { word: 'bouncing', word_class: 'AJ' },
    { word: 'bounciness', word_class: 'N' }
  ],
  honest: [
    { word: 'honest', word_class: 'AJ' },
    { word: 'honesty', word_class: 'N' },
    { word: 'honestness', word_class: 'N' }
  ],
  rubefy: [
    { word: 'rubefy', word_class: 'V' },
    { word: 'rubifcation', word_class: 'N' },
    { word: 'rubification', word_class: 'N' }
  ],
  coherency: [
    { word: 'cohere', word_class: 'V' },
    { word: 'cohere', word_class: 'AV' },
    { word: 'cohesion', word_class: 'N' },
    { word: 'coherence', word_class: 'N' },
    { word: 'coherent', word_class: 'AJ' },
    { word: 'cohesive', word_class: 'AJ' },
    { word: 'coherency', word_class: 'N' },
    { word: 'coherently', word_class: 'AV' },
    { word: 'cohesiveness', word_class: 'N' }
  ],
  psychologize: [
    { word: 'psychology', word_class: 'N' },
    { word: 'psychologize', word_class: 'V' },
    { word: 'psychological', word_class: 'AJ' },
    { word: 'psychologically', word_class: 'AV' },
    { word: 'psychologization', word_class: 'N' }
  ],
  tackling: [
    { word: 'tackle', word_class: 'N' },
    { word: 'tackle', word_class: 'V' },
    { word: 'tackler', word_class: 'N' },
    { word: 'tackling', word_class: 'N' }
  ],
  disproportional: [
    { word: 'disproportion', word_class: 'N' },
    { word: 'disproportion', word_class: 'V' },
    { word: 'disproportional', word_class: 'AJ' },
    { word: 'disproportionate', word_class: 'AJ' },
    { word: 'disproportionment', word_class: 'N' },
    { word: 'disproportionately', word_class: 'AV' }
  ],
  besieging: [
    { word: 'besiege', word_class: 'V' },
    { word: 'besieger', word_class: 'N' },
    { word: 'besieged', word_class: 'AJ' },
    { word: 'besieging', word_class: 'N' }
  ],
  biographic: [
    { word: 'biographer', word_class: 'N' },
    { word: 'biographic', word_class: 'AJ' },
    { word: 'biographical', word_class: 'AJ' }
  ],
  sensually: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  parer: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  station: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  onomatopoetic: [
    { word: 'onomatopoeia', word_class: 'N' },
    { word: 'onomatopoeic', word_class: 'AJ' },
    { word: 'onomatopoetic', word_class: 'AJ' },
    { word: 'onomatopoeical', word_class: 'AJ' }
  ],
  disconcerting: [
    { word: 'disconcert', word_class: 'N' },
    { word: 'disconcert', word_class: 'V' },
    { word: 'disconcerted', word_class: 'AJ' },
    { word: 'disconcertion', word_class: 'N' },
    { word: 'disconcerting', word_class: 'AJ' },
    { word: 'disconcertment', word_class: 'N' }
  ],
  potholer: [
    { word: 'pothole', word_class: 'N' },
    { word: 'potholer', word_class: 'N' },
    { word: 'potholed', word_class: 'AJ' }
  ],
  discouraging: [
    { word: 'discourage', word_class: 'N' },
    { word: 'discourage', word_class: 'V' },
    { word: 'discouraged', word_class: 'AJ' },
    { word: 'discouraging', word_class: 'AJ' },
    { word: 'discouragement', word_class: 'N' }
  ],
  fumes: [
    { word: 'fume', word_class: 'N' },
    { word: 'fume', word_class: 'V' },
    { word: 'fumes', word_class: 'N' },
    { word: 'fumed', word_class: 'AJ' }
  ],
  stylised: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  achromatise: [
    { word: 'achromatise', word_class: 'V' },
    { word: 'achromatic', word_class: 'AJ' },
    { word: 'achromatize', word_class: 'V' },
    { word: 'achromatism', word_class: 'N' },
    { word: 'achromatous', word_class: 'AJ' },
    { word: 'achromatisation', word_class: 'N' },
    { word: 'achromatization', word_class: 'N' }
  ],
  woods: [
    { word: 'wood', word_class: 'N' },
    { word: 'wood', word_class: 'AJ' },
    { word: 'woods', word_class: 'N' },
    { word: 'woods', word_class: 'AJ' },
    { word: 'wooded', word_class: 'AJ' },
    { word: 'wooden', word_class: 'AJ' },
    { word: 'woodsy', word_class: 'AJ' }
  ],
  assaultive: [
    { word: 'assault', word_class: 'N' },
    { word: 'assault', word_class: 'V' },
    { word: 'assault', word_class: 'AJ' },
    { word: 'assaulted', word_class: 'AJ' },
    { word: 'assaultive', word_class: 'AJ' }
  ],
  known: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  unrespected: [
    { word: 'unrespected', word_class: 'AJ' },
    { word: 'unrespectable', word_class: 'AJ' },
    { word: 'unrespectability', word_class: 'N' }
  ],
  reclaimed: [
    { word: 'reclaim', word_class: 'V' },
    { word: 'reclaimed', word_class: 'AJ' },
    { word: 'reclamation', word_class: 'N' },
    { word: 'reclaimable', word_class: 'AJ' }
  ],
  labialize: [
    { word: 'labial', word_class: 'N' },
    { word: 'labial', word_class: 'AJ' },
    { word: 'labialise', word_class: 'V' },
    { word: 'labialize', word_class: 'V' },
    { word: 'labialisation', word_class: 'N' },
    { word: 'labialization', word_class: 'N' }
  ],
  quote: [
    { word: 'quote', word_class: 'N' },
    { word: 'quote', word_class: 'V' },
    { word: 'quotation', word_class: 'N' }
  ],
  whistling: [
    { word: 'whistle', word_class: 'N' },
    { word: 'whistle', word_class: 'V' },
    { word: 'whistler', word_class: 'N' },
    { word: 'whistling', word_class: 'N' }
  ],
  canary: [
    { word: 'canary', word_class: 'N' },
    { word: 'canary', word_class: 'AJ' },
    { word: 'canaries', word_class: 'N' }
  ],
  fantastic: [
    { word: 'fantast', word_class: 'N' },
    { word: 'fantastic', word_class: 'AJ' },
    { word: 'fantastical', word_class: 'AJ' },
    { word: 'fantastically', word_class: 'AV' }
  ],
  transmutation: [
    { word: 'transmute', word_class: 'V' },
    { word: 'transmute', word_class: 'AJ' },
    { word: 'transmutable', word_class: 'AJ' },
    { word: 'transmutation', word_class: 'N' },
    { word: 'transmutability', word_class: 'N' }
  ],
  formidable: [
    { word: 'formidable', word_class: 'AJ' },
    { word: 'formidably', word_class: 'AV' },
    { word: 'formidability', word_class: 'N' }
  ],
  forking: [
    { word: 'fork', word_class: 'N' },
    { word: 'fork', word_class: 'V' },
    { word: 'forked', word_class: 'AJ' },
    { word: 'forkful', word_class: 'N' },
    { word: 'forking', word_class: 'N' },
    { word: 'forkful', word_class: 'AJ' }
  ],
  ruining: [
    { word: 'ruin', word_class: 'N' },
    { word: 'ruin', word_class: 'V' },
    { word: 'ruiner', word_class: 'N' },
    { word: 'ruinate', word_class: 'V' },
    { word: 'ruined', word_class: 'AJ' },
    { word: 'ruining', word_class: 'N' },
    { word: 'ruination', word_class: 'N' }
  ],
  wholesomely: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  unappreciated: [
    { word: 'unappreciated', word_class: 'AJ' },
    { word: 'unappreciative', word_class: 'AJ' },
    { word: 'unappreciatively', word_class: 'AV' }
  ],
  adversary: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  tangle: [
    { word: 'tangle', word_class: 'N' },
    { word: 'tangle', word_class: 'V' },
    { word: 'tangled', word_class: 'AJ' },
    { word: 'tanglement', word_class: 'N' }
  ],
  sprinkles: [
    { word: 'sprinkle', word_class: 'N' },
    { word: 'sprinkle', word_class: 'V' },
    { word: 'sprinkler', word_class: 'N' },
    { word: 'sprinkles', word_class: 'N' },
    { word: 'sprinkled', word_class: 'AJ' },
    { word: 'sprinkling', word_class: 'N' }
  ],
  lubricity: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  wantonness: [
    { word: 'wanton', word_class: 'N' },
    { word: 'wanton', word_class: 'V' },
    { word: 'wanton', word_class: 'AJ' },
    { word: 'wantonness', word_class: 'N' }
  ],
  predispose: [
    { word: 'predispose', word_class: 'V' },
    { word: 'predisposal', word_class: 'N' },
    { word: 'predisposed', word_class: 'AJ' },
    { word: 'predisposition', word_class: 'N' }
  ],
  phosphatize: [
    { word: 'phosphate', word_class: 'N' },
    { word: 'phosphatise', word_class: 'V' },
    { word: 'phosphatize', word_class: 'V' },
    { word: 'phosphatisation', word_class: 'N' },
    { word: 'phosphatization', word_class: 'N' }
  ],
  patronization: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  ideologically: [
    { word: 'ideology', word_class: 'N' },
    { word: 'ideologic', word_class: 'AJ' },
    { word: 'ideological', word_class: 'AJ' },
    { word: 'ideologically', word_class: 'AV' }
  ],
  insatiate: [
    { word: 'insatiate', word_class: 'AJ' },
    { word: 'insatiable', word_class: 'AJ' },
    { word: 'insatiably', word_class: 'AV' }
  ],
  lubrication: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  lump: [{ word: 'lump', word_class: 'N' }, { word: 'lump', word_class: 'V' }, { word: 'lumper', word_class: 'N' }],
  ruptured: [
    { word: 'rupture', word_class: 'N' },
    { word: 'rupture', word_class: 'V' },
    { word: 'ruptured', word_class: 'AJ' }
  ],
  productiveness: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  factoring: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  backwood: [
    { word: 'backwood', word_class: 'N' },
    { word: 'backwoods', word_class: 'N' },
    { word: 'backwoods', word_class: 'AJ' }
  ],
  centennial: [
    { word: 'centennial', word_class: 'N' },
    { word: 'centennial', word_class: 'AJ' },
    { word: 'centennially', word_class: 'AV' }
  ],
  digitalization: [
    { word: 'digit', word_class: 'N' },
    { word: 'digitate', word_class: 'V' },
    { word: 'digital', word_class: 'AJ' },
    { word: 'digitize', word_class: 'V' },
    { word: 'digitise', word_class: 'V' },
    { word: 'digitate', word_class: 'AJ' },
    { word: 'digitizer', word_class: 'N' },
    { word: 'digitalise', word_class: 'V' },
    { word: 'digitalize', word_class: 'V' },
    { word: 'digitally', word_class: 'AV' },
    { word: 'digitation', word_class: 'N' },
    { word: 'digitately', word_class: 'AV' },
    { word: 'digitization', word_class: 'N' },
    { word: 'digitisation', word_class: 'N' },
    { word: 'digitalisation', word_class: 'N' },
    { word: 'digitalization', word_class: 'N' }
  ],
  fragmentize: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  spiritous: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  relie: [
    { word: 'rely', word_class: 'V' },
    { word: 'relie', word_class: 'V' },
    { word: 'reliant', word_class: 'N' },
    { word: 'reliance', word_class: 'N' },
    { word: 'reliant', word_class: 'AJ' }
  ],
  pauper: [
    { word: 'pauper', word_class: 'N' },
    { word: 'pauper', word_class: 'V' },
    { word: 'pauperism', word_class: 'N' },
    { word: 'pauperize', word_class: 'V' },
    { word: 'pauperization', word_class: 'N' }
  ],
  jerky: [
    { word: 'jerk', word_class: 'N' },
    { word: 'jerk', word_class: 'V' },
    { word: 'jerky', word_class: 'N' },
    { word: 'jerky', word_class: 'AJ' },
    { word: 'jerking', word_class: 'N' },
    { word: 'jerking', word_class: 'AJ' },
    { word: 'jerkiness', word_class: 'N' }
  ],
  pedigree: [
    { word: 'pedigree', word_class: 'N' },
    { word: 'pedigree', word_class: 'AJ' },
    { word: 'pedigreed', word_class: 'AJ' }
  ],
  numbers: [
    { word: 'number', word_class: 'N' },
    { word: 'number', word_class: 'V' },
    { word: 'numbers', word_class: 'N' },
    { word: 'numbering', word_class: 'N' }
  ],
  recondition: [
    { word: 'recondite', word_class: 'AJ' },
    { word: 'recondition', word_class: 'V' },
    { word: 'reconditeness', word_class: 'N' },
    { word: 'reconditioned', word_class: 'AJ' }
  ],
  pat: [
    { word: 'pat', word_class: 'N' },
    { word: 'pat', word_class: 'V' },
    { word: 'pat', word_class: 'AJ' },
    { word: 'pat', word_class: 'AV' },
    { word: 'pater', word_class: 'N' },
    { word: 'patness', word_class: 'N' }
  ],
  dosed: [{ word: 'dose', word_class: 'N' }, { word: 'dose', word_class: 'V' }, { word: 'dosed', word_class: 'AJ' }],
  anagogical: [
    { word: 'anagoge', word_class: 'N' },
    { word: 'anagogic', word_class: 'AJ' },
    { word: 'anagogical', word_class: 'AJ' }
  ],
  undernourishment: [
    { word: 'undernourish', word_class: 'V' },
    { word: 'undernourished', word_class: 'AJ' },
    { word: 'undernourishment', word_class: 'N' }
  ],
  verticalness: [
    { word: 'vertical', word_class: 'N' },
    { word: 'vertical', word_class: 'AJ' },
    { word: 'verticality', word_class: 'N' },
    { word: 'vertically', word_class: 'AV' },
    { word: 'verticalness', word_class: 'N' }
  ],
  song: [
    { word: 'song', word_class: 'N' },
    { word: 'songful', word_class: 'AJ' },
    { word: 'songfulness', word_class: 'N' }
  ],
  laureled: [
    { word: 'laurel', word_class: 'N' },
    { word: 'laurels', word_class: 'N' },
    { word: 'laureled', word_class: 'AJ' },
    { word: 'laurelled', word_class: 'AJ' }
  ],
  unbending: [
    { word: 'unbend', word_class: 'V' },
    { word: 'unbending', word_class: 'AJ' },
    { word: 'unbendable', word_class: 'AJ' }
  ],
  onwards: [
    { word: 'onward', word_class: 'AJ' },
    { word: 'onward', word_class: 'AV' },
    { word: 'onwards', word_class: 'AV' }
  ],
  toxicology: [
    { word: 'toxicology', word_class: 'N' },
    { word: 'toxicologic', word_class: 'AJ' },
    { word: 'toxicological', word_class: 'AJ' }
  ],
  populism: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  stand: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  grinder: [
    { word: 'grind', word_class: 'N' },
    { word: 'grind', word_class: 'V' },
    { word: 'grinder', word_class: 'N' },
    { word: 'grinding', word_class: 'N' }
  ],
  admirably: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  wig: [
    { word: 'wig', word_class: 'N' },
    { word: 'wig', word_class: 'V' },
    { word: 'wigged', word_class: 'AJ' },
    { word: 'wigging', word_class: 'N' }
  ],
  specify: [
    { word: 'specify', word_class: 'V' },
    { word: 'specific', word_class: 'N' },
    { word: 'specific', word_class: 'AJ' },
    { word: 'specified', word_class: 'AJ' },
    { word: 'specifying', word_class: 'N' },
    { word: 'specificity', word_class: 'N' },
    { word: 'specifiable', word_class: 'AJ' },
    { word: 'specifically', word_class: 'AV' },
    { word: 'specification', word_class: 'N' }
  ],
  subconsciousness: [
    { word: 'subconscious', word_class: 'N' },
    { word: 'subconscious', word_class: 'AJ' },
    { word: 'subconsciously', word_class: 'AV' },
    { word: 'subconsciousness', word_class: 'N' }
  ],
  coenobitic: [
    { word: 'coenobite', word_class: 'N' },
    { word: 'coenobitic', word_class: 'AJ' },
    { word: 'coenobitical', word_class: 'AJ' }
  ],
  headliner: [
    { word: 'headline', word_class: 'N' },
    { word: 'headline', word_class: 'V' },
    { word: 'headliner', word_class: 'N' }
  ],
  implausibleness: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  fulgurate: [
    { word: 'fulgurate', word_class: 'V' },
    { word: 'fulgurant', word_class: 'AJ' },
    { word: 'fulgurous', word_class: 'AJ' },
    { word: 'fulguration', word_class: 'N' },
    { word: 'fulgurating', word_class: 'AJ' }
  ],
  completed: [
    { word: 'complete', word_class: 'V' },
    { word: 'complete', word_class: 'AJ' },
    { word: 'completion', word_class: 'N' },
    { word: 'completed', word_class: 'AJ' },
    { word: 'completely', word_class: 'AV' },
    { word: 'completing', word_class: 'AJ' },
    { word: 'completeness', word_class: 'N' }
  ],
  sensibly: [
    { word: 'sensible', word_class: 'AJ' },
    { word: 'sensibly', word_class: 'AV' },
    { word: 'sensibility', word_class: 'N' },
    { word: 'sensibleness', word_class: 'N' }
  ],
  ravel: [
    { word: 'ravel', word_class: 'N' },
    { word: 'ravel', word_class: 'V' },
    { word: 'raveling', word_class: 'N' },
    { word: 'ravelling', word_class: 'N' }
  ],
  amor: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  engraver: [
    { word: 'engrave', word_class: 'V' },
    { word: 'engraver', word_class: 'N' },
    { word: 'engraved', word_class: 'AJ' },
    { word: 'engraving', word_class: 'N' }
  ],
  patriarch: [
    { word: 'patriarch', word_class: 'N' },
    { word: 'patriarchs', word_class: 'N' },
    { word: 'patriarchal', word_class: 'AJ' },
    { word: 'patriarchate', word_class: 'N' },
    { word: 'patriarchic', word_class: 'AJ' }
  ],
  persevere: [
    { word: 'persevere', word_class: 'V' },
    { word: 'perseverance', word_class: 'N' },
    { word: 'perseverence', word_class: 'N' },
    { word: 'perseveration', word_class: 'N' }
  ],
  dead: [
    { word: 'dead', word_class: 'N' },
    { word: 'dead', word_class: 'AJ' },
    { word: 'dead', word_class: 'AV' },
    { word: 'deadly', word_class: 'AJ' },
    { word: 'deadly', word_class: 'AV' },
    { word: 'deadness', word_class: 'N' },
    { word: 'deadliness', word_class: 'N' }
  ],
  incessantness: [
    { word: 'incessancy', word_class: 'N' },
    { word: 'incessant', word_class: 'AJ' },
    { word: 'incessantness', word_class: 'N' }
  ],
  carving: [
    { word: 'carve', word_class: 'V' },
    { word: 'carver', word_class: 'N' },
    { word: 'carved', word_class: 'AJ' },
    { word: 'carving', word_class: 'N' }
  ],
  repetitively: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  based: [
    { word: 'base', word_class: 'N' },
    { word: 'base', word_class: 'V' },
    { word: 'base', word_class: 'AJ' },
    { word: 'based', word_class: 'AJ' },
    { word: 'basely', word_class: 'AV' },
    { word: 'baseness', word_class: 'N' }
  ],
  posterior: [
    { word: 'posterior', word_class: 'N' },
    { word: 'posterior', word_class: 'AJ' },
    { word: 'posteriority', word_class: 'N' }
  ],
  dictate: [
    { word: 'dictate', word_class: 'N' },
    { word: 'dictate', word_class: 'V' },
    { word: 'dication', word_class: 'N' },
    { word: 'dictator', word_class: 'N' },
    { word: 'dictated', word_class: 'AJ' },
    { word: 'dictation', word_class: 'N' }
  ],
  parachuting: [
    { word: 'parachute', word_class: 'N' },
    { word: 'parachute', word_class: 'V' },
    { word: 'parachuter', word_class: 'N' },
    { word: 'parachuting', word_class: 'N' },
    { word: 'parachutist', word_class: 'N' }
  ],
  trimming: [
    { word: 'trim', word_class: 'N' },
    { word: 'trim', word_class: 'V' },
    { word: 'trim', word_class: 'AJ' },
    { word: 'trimer', word_class: 'N' },
    { word: 'trimmed', word_class: 'AJ' },
    { word: 'trimming', word_class: 'N' },
    { word: 'trimness', word_class: 'N' }
  ],
  eosinophilic: [
    { word: 'eosinophil', word_class: 'N' },
    { word: 'eosinophile', word_class: 'N' },
    { word: 'eosinophilic', word_class: 'AJ' }
  ],
  mired: [{ word: 'mire', word_class: 'N' }, { word: 'mire', word_class: 'V' }, { word: 'mired', word_class: 'AJ' }],
  anglicize: [
    { word: 'anglicise', word_class: 'V' },
    { word: 'anglicism', word_class: 'N' },
    { word: 'anglicize', word_class: 'V' },
    { word: 'anglicisation', word_class: 'N' },
    { word: 'anglicization', word_class: 'N' }
  ],
  row: [
    { word: 'row', word_class: 'N' },
    { word: 'row', word_class: 'V' },
    { word: 'rower', word_class: 'N' },
    { word: 'rowing', word_class: 'N' }
  ],
  avowal: [
    { word: 'avow', word_class: 'V' },
    { word: 'avowal', word_class: 'N' },
    { word: 'avowed', word_class: 'AJ' }
  ],
  spurring: [
    { word: 'spur', word_class: 'N' },
    { word: 'spur', word_class: 'V' },
    { word: 'spurring', word_class: 'N' }
  ],
  abyss: [
    { word: 'abysm', word_class: 'N' },
    { word: 'abyss', word_class: 'N' },
    { word: 'abysmal', word_class: 'AJ' },
    { word: 'abyssal', word_class: 'AJ' },
    { word: 'abysmally', word_class: 'AV' }
  ],
  complementary: [
    { word: 'complement', word_class: 'N' },
    { word: 'complement', word_class: 'V' },
    { word: 'complemental', word_class: 'AJ' },
    { word: 'complementary', word_class: 'AJ' },
    { word: 'complementation', word_class: 'N' }
  ],
  landscapist: [
    { word: 'landscape', word_class: 'N' },
    { word: 'landscape', word_class: 'V' },
    { word: 'landscaped', word_class: 'AJ' },
    { word: 'landscaping', word_class: 'N' },
    { word: 'landscapist', word_class: 'N' }
  ],
  insensitiveness: [
    { word: 'insensitive', word_class: 'AJ' },
    { word: 'insensitivity', word_class: 'N' },
    { word: 'insensitively', word_class: 'AV' },
    { word: 'insensitiveness', word_class: 'N' }
  ],
  flavourer: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  labialise: [
    { word: 'labial', word_class: 'N' },
    { word: 'labial', word_class: 'AJ' },
    { word: 'labialise', word_class: 'V' },
    { word: 'labialize', word_class: 'V' },
    { word: 'labialisation', word_class: 'N' },
    { word: 'labialization', word_class: 'N' }
  ],
  elated: [
    { word: 'elate', word_class: 'V' },
    { word: 'elater', word_class: 'N' },
    { word: 'elated', word_class: 'AJ' },
    { word: 'elation', word_class: 'N' },
    { word: 'elating', word_class: 'AJ' }
  ],
  hedge: [
    { word: 'hedge', word_class: 'N' },
    { word: 'hedge', word_class: 'V' },
    { word: 'hedged', word_class: 'AJ' },
    { word: 'hedging', word_class: 'N' }
  ],
  transshipment: [
    { word: 'tranship', word_class: 'V' },
    { word: 'transship', word_class: 'N' },
    { word: 'transship', word_class: 'V' },
    { word: 'transhipment', word_class: 'N' },
    { word: 'transshipment', word_class: 'N' }
  ],
  remoteness: [
    { word: 'remote', word_class: 'N' },
    { word: 'remote', word_class: 'AJ' },
    { word: 'remotion', word_class: 'N' },
    { word: 'remotely', word_class: 'AV' },
    { word: 'remoteness', word_class: 'N' }
  ],
  latitudinary: [
    { word: 'latitude', word_class: 'N' },
    { word: 'latitudinal', word_class: 'AJ' },
    { word: 'latitudinary', word_class: 'AJ' }
  ],
  proficiently: [
    { word: 'proficiency', word_class: 'N' },
    { word: 'proficient', word_class: 'AJ' },
    { word: 'proficiently', word_class: 'AV' }
  ],
  collection: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  withdraw: [
    { word: 'withdraw', word_class: 'V' },
    { word: 'withdrawal', word_class: 'N' },
    { word: 'withdrawn', word_class: 'AJ' },
    { word: 'withdrawing', word_class: 'AJ' },
    { word: 'withdrawnness', word_class: 'N' }
  ],
  result: [
    { word: 'result', word_class: 'N' },
    { word: 'result', word_class: 'V' },
    { word: 'resultant', word_class: 'N' },
    { word: 'resultant', word_class: 'AJ' },
    { word: 'resulting', word_class: 'AJ' }
  ],
  jocose: [
    { word: 'jocose', word_class: 'AJ' },
    { word: 'jocosity', word_class: 'N' },
    { word: 'jocosely', word_class: 'AV' },
    { word: 'jocoseness', word_class: 'N' }
  ],
  rev: [
    { word: 'rev', word_class: 'N' },
    { word: 'rev', word_class: 'V' },
    { word: 'revved', word_class: 'AJ' },
    { word: 'revving', word_class: 'AJ' }
  ],
  accountable: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  bruised: [
    { word: 'bruise', word_class: 'N' },
    { word: 'bruise', word_class: 'V' },
    { word: 'bruiser', word_class: 'N' },
    { word: 'bruised', word_class: 'AJ' },
    { word: 'bruising', word_class: 'AJ' }
  ],
  yawn: [{ word: 'yawn', word_class: 'N' }, { word: 'yawn', word_class: 'V' }, { word: 'yawning', word_class: 'AJ' }],
  superficies: [
    { word: 'superficies', word_class: 'N' },
    { word: 'superficial', word_class: 'AJ' },
    { word: 'superficiality', word_class: 'N' },
    { word: 'superficially', word_class: 'AV' }
  ],
  cinematic: [
    { word: 'cinema', word_class: 'N' },
    { word: 'cinematic', word_class: 'AJ' },
    { word: 'cinematize', word_class: 'V' }
  ],
  romanticistic: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  enchanted: [
    { word: 'enchant', word_class: 'V' },
    { word: 'enchanter', word_class: 'N' },
    { word: 'enchanted', word_class: 'AJ' },
    { word: 'enchanting', word_class: 'AJ' },
    { word: 'enchantment', word_class: 'N' },
    { word: 'enchantress', word_class: 'N' }
  ],
  peril: [
    { word: 'peril', word_class: 'N' },
    { word: 'peril', word_class: 'V' },
    { word: 'perilous', word_class: 'AJ' },
    { word: 'perilously', word_class: 'AV' },
    { word: 'perilousness', word_class: 'N' }
  ],
  curdle: [
    { word: 'curdle', word_class: 'V' },
    { word: 'curdled', word_class: 'AJ' },
    { word: 'curdling', word_class: 'N' },
    { word: 'curdling', word_class: 'AJ' }
  ],
  oligarch: [
    { word: 'oligarch', word_class: 'N' },
    { word: 'oligarchic', word_class: 'AJ' },
    { word: 'oligarchical', word_class: 'AJ' }
  ],
  novelty: [
    { word: 'novel', word_class: 'N' },
    { word: 'novel', word_class: 'AJ' },
    { word: 'novelty', word_class: 'N' },
    { word: 'novelize', word_class: 'V' },
    { word: 'novelties', word_class: 'N' },
    { word: 'novelization', word_class: 'N' }
  ],
  keyed: [
    { word: 'key', word_class: 'N' },
    { word: 'key', word_class: 'V' },
    { word: 'key', word_class: 'AJ' },
    { word: 'keyed', word_class: 'AJ' }
  ],
  buttressing: [
    { word: 'buttress', word_class: 'N' },
    { word: 'buttress', word_class: 'V' },
    { word: 'buttressed', word_class: 'AJ' },
    { word: 'buttressing', word_class: 'N' }
  ],
  transitively: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  capricious: [
    { word: 'caprice', word_class: 'N' },
    { word: 'capricious', word_class: 'AJ' },
    { word: 'capriciously', word_class: 'AV' },
    { word: 'capriciousness', word_class: 'N' }
  ],
  harmed: [
    { word: 'harm', word_class: 'N' },
    { word: 'harm', word_class: 'V' },
    { word: 'harmed', word_class: 'AJ' },
    { word: 'harmful', word_class: 'AJ' },
    { word: 'harmfulness', word_class: 'N' }
  ],
  outlined: [
    { word: 'outline', word_class: 'N' },
    { word: 'outline', word_class: 'V' },
    { word: 'outlined', word_class: 'AJ' }
  ],
  bichromate: [
    { word: 'bichrome', word_class: 'AJ' },
    { word: 'bichromate', word_class: 'N' },
    { word: 'bichromated', word_class: 'AJ' }
  ],
  susceptibility: [
    { word: 'susceptible', word_class: 'AJ' },
    { word: 'susceptibly', word_class: 'AV' },
    { word: 'susceptibility', word_class: 'N' },
    { word: 'susceptibleness', word_class: 'N' }
  ],
  striver: [
    { word: 'strife', word_class: 'N' },
    { word: 'strive', word_class: 'V' },
    { word: 'striver', word_class: 'N' },
    { word: 'striven', word_class: 'AJ' },
    { word: 'striving', word_class: 'N' }
  ],
  deregulating: [
    { word: 'deregulate', word_class: 'V' },
    { word: 'deregulating', word_class: 'N' },
    { word: 'deregulation', word_class: 'N' }
  ],
  substantivise: [
    { word: 'substantival', word_class: 'AJ' },
    { word: 'substantivise', word_class: 'V' },
    { word: 'substantivize', word_class: 'V' },
    { word: 'substantivisation', word_class: 'N' },
    { word: 'substantivization', word_class: 'N' }
  ],
  lignification: [
    { word: 'lignify', word_class: 'V' },
    { word: 'lignified', word_class: 'AJ' },
    { word: 'lignification', word_class: 'N' }
  ],
  orphaned: [
    { word: 'orphan', word_class: 'N' },
    { word: 'orphan', word_class: 'V' },
    { word: 'orphan', word_class: 'AJ' },
    { word: 'orphanage', word_class: 'N' },
    { word: 'orphaned', word_class: 'AJ' }
  ],
  emblematic: [
    { word: 'emblematic', word_class: 'AJ' },
    { word: 'emblematize', word_class: 'V' },
    { word: 'emblematical', word_class: 'AJ' },
    { word: 'emblematization', word_class: 'N' }
  ],
  schematize: [
    { word: 'schema', word_class: 'N' },
    { word: 'schematic', word_class: 'N' },
    { word: 'schematic', word_class: 'AJ' },
    { word: 'schematize', word_class: 'V' },
    { word: 'schematise', word_class: 'V' },
    { word: 'schematically', word_class: 'AV' },
    { word: 'schematisation', word_class: 'N' },
    { word: 'schematization', word_class: 'N' }
  ],
  stabilizer: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  reconciling: [
    { word: 'reconcile', word_class: 'V' },
    { word: 'reconciler', word_class: 'N' },
    { word: 'reconciled', word_class: 'AJ' },
    { word: 'reconciling', word_class: 'AJ' },
    { word: 'reconcilement', word_class: 'N' },
    { word: 'reconcilable', word_class: 'AJ' },
    { word: 'reconciliation', word_class: 'N' }
  ],
  blink: [
    { word: 'blink', word_class: 'N' },
    { word: 'blink', word_class: 'V' },
    { word: 'blinks', word_class: 'N' },
    { word: 'blinker', word_class: 'N' },
    { word: 'blinker', word_class: 'V' },
    { word: 'blinking', word_class: 'AJ' }
  ],
  humoring: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  isomorphic: [
    { word: 'isomorphic', word_class: 'AJ' },
    { word: 'isomorphism', word_class: 'N' },
    { word: 'isomorphous', word_class: 'AJ' }
  ],
  devour: [
    { word: 'devour', word_class: 'V' },
    { word: 'devourer', word_class: 'N' },
    { word: 'devoured', word_class: 'AJ' },
    { word: 'devouring', word_class: 'AJ' }
  ],
  stocker: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  budding: [
    { word: 'bud', word_class: 'N' },
    { word: 'bud', word_class: 'V' },
    { word: 'budding', word_class: 'N' },
    { word: 'budding', word_class: 'AJ' }
  ],
  conjuror: [
    { word: 'conjure', word_class: 'V' },
    { word: 'conjurer', word_class: 'N' },
    { word: 'conjuror', word_class: 'N' },
    { word: 'conjuring', word_class: 'N' },
    { word: 'conjuration', word_class: 'N' }
  ],
  hackneyed: [
    { word: 'hackney', word_class: 'N' },
    { word: 'hackney', word_class: 'V' },
    { word: 'hackneyed', word_class: 'AJ' }
  ],
  armful: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  dehydrated: [
    { word: 'dehydrate', word_class: 'V' },
    { word: 'dehydrated', word_class: 'AJ' },
    { word: 'dehydration', word_class: 'N' }
  ],
  respectable: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  incurableness: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  concerted: [
    { word: 'concert', word_class: 'N' },
    { word: 'concert', word_class: 'V' },
    { word: 'concerted', word_class: 'AJ' },
    { word: 'concertize', word_class: 'V' }
  ],
  transpiration: [
    { word: 'transpire', word_class: 'V' },
    { word: 'transpirate', word_class: 'V' },
    { word: 'transpiring', word_class: 'AJ' },
    { word: 'transpiration', word_class: 'N' }
  ],
  directivity: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  'cross-examination': [
    { word: 'cross-examine', word_class: 'V' },
    { word: 'cross-examiner', word_class: 'N' },
    { word: 'cross-examination', word_class: 'N' }
  ],
  flatterer: [
    { word: 'flatter', word_class: 'V' },
    { word: 'flattery', word_class: 'N' },
    { word: 'flatterer', word_class: 'N' },
    { word: 'flattering', word_class: 'AJ' }
  ],
  flourish: [
    { word: 'flourish', word_class: 'N' },
    { word: 'flourish', word_class: 'V' },
    { word: 'flourish', word_class: 'AJ' },
    { word: 'flourishing', word_class: 'AJ' }
  ],
  abranchious: [
    { word: 'abranchial', word_class: 'AJ' },
    { word: 'abranchiate', word_class: 'AJ' },
    { word: 'abranchious', word_class: 'AJ' }
  ],
  emboss: [
    { word: 'emboss', word_class: 'V' },
    { word: 'embossed', word_class: 'AJ' },
    { word: 'embossment', word_class: 'N' }
  ],
  projector: [
    { word: 'project', word_class: 'N' },
    { word: 'project', word_class: 'V' },
    { word: 'projector', word_class: 'N' },
    { word: 'projected', word_class: 'AJ' },
    { word: 'projection', word_class: 'N' },
    { word: 'projecting', word_class: 'AJ' }
  ],
  scandalization: [
    { word: 'scandal', word_class: 'N' },
    { word: 'scandalise', word_class: 'V' },
    { word: 'scandalize', word_class: 'V' },
    { word: 'scandalous', word_class: 'AJ' },
    { word: 'scandalously', word_class: 'AV' },
    { word: 'scandalisation', word_class: 'N' },
    { word: 'scandalization', word_class: 'N' },
    { word: 'scandalousness', word_class: 'N' }
  ],
  imperviable: [
    { word: 'impervious', word_class: 'AJ' },
    { word: 'imperviable', word_class: 'AJ' },
    { word: 'imperviousness', word_class: 'N' }
  ],
  activist: [
    { word: 'activist', word_class: 'N' },
    { word: 'activist', word_class: 'AJ' },
    { word: 'activistic', word_class: 'AJ' }
  ],
  prolix: [
    { word: 'prolix', word_class: 'AJ' },
    { word: 'prolixity', word_class: 'N' },
    { word: 'prolixness', word_class: 'N' }
  ],
  're-create': [
    { word: 're-create', word_class: 'V' },
    { word: 're-created', word_class: 'AJ' },
    { word: 're-creation', word_class: 'N' }
  ],
  postman: [
    { word: 'post', word_class: 'N' },
    { word: 'post', word_class: 'V' },
    { word: 'post', word_class: 'AJ' },
    { word: 'poster', word_class: 'N' },
    { word: 'poster', word_class: 'V' },
    { word: 'posting', word_class: 'N' },
    { word: 'postman', word_class: 'N' },
    { word: 'posterity', word_class: 'N' }
  ],
  fries: [{ word: 'fri', word_class: 'N' }, { word: 'fries', word_class: 'N' }, { word: 'fried', word_class: 'AJ' }],
  shortening: [
    { word: 'shorten', word_class: 'V' },
    { word: 'shortened', word_class: 'AJ' },
    { word: 'shortening', word_class: 'N' }
  ],
  scraped: [
    { word: 'scrap', word_class: 'N' },
    { word: 'scrap', word_class: 'V' },
    { word: 'scrape', word_class: 'N' },
    { word: 'scrape', word_class: 'V' },
    { word: 'scrap', word_class: 'AJ' },
    { word: 'scraps', word_class: 'N' },
    { word: 'scraper', word_class: 'N' },
    { word: 'scraped', word_class: 'AJ' },
    { word: 'scraping', word_class: 'N' }
  ],
  intransitively: [
    { word: 'intransitive', word_class: 'AJ' },
    { word: 'intransitivity', word_class: 'N' },
    { word: 'intransitively', word_class: 'AV' },
    { word: 'intransitiveness', word_class: 'N' }
  ],
  lawful: [
    { word: 'law', word_class: 'N' },
    { word: 'laws', word_class: 'N' },
    { word: 'lawful', word_class: 'AJ' },
    { word: 'lawfulness', word_class: 'N' }
  ],
  ebonize: [
    { word: 'ebon', word_class: 'AJ' },
    { word: 'ebonics', word_class: 'N' },
    { word: 'ebonize', word_class: 'V' },
    { word: 'ebonization', word_class: 'N' }
  ],
  colours: [
    { word: 'colour', word_class: 'N' },
    { word: 'colour', word_class: 'V' },
    { word: 'colour', word_class: 'AJ' },
    { word: 'colours', word_class: 'N' },
    { word: 'coloured', word_class: 'AJ' },
    { word: 'colouring', word_class: 'N' },
    { word: 'colourful', word_class: 'AJ' }
  ],
  unskillful: [
    { word: 'unskilled', word_class: 'AJ' },
    { word: 'unskillful', word_class: 'AJ' },
    { word: 'unskillfulness', word_class: 'N' }
  ],
  clincher: [
    { word: 'clinch', word_class: 'N' },
    { word: 'clinch', word_class: 'V' },
    { word: 'clincher', word_class: 'N' },
    { word: 'clinched', word_class: 'AJ' }
  ],
  bibliographer: [
    { word: 'bibliographer', word_class: 'N' },
    { word: 'bibliographic', word_class: 'AJ' },
    { word: 'bibliographical', word_class: 'AJ' }
  ],
  guzzler: [
    { word: 'guzzle', word_class: 'V' },
    { word: 'guzzler', word_class: 'N' },
    { word: 'guzzling', word_class: 'N' }
  ],
  epigrammatic: [
    { word: 'epigrammatic', word_class: 'AJ' },
    { word: 'epigrammatize', word_class: 'V' },
    { word: 'epigrammatization', word_class: 'N' }
  ],
  deception: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  utilitarian: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  capitalism: [
    { word: 'capital', word_class: 'N' },
    { word: 'capital', word_class: 'AJ' },
    { word: 'capitate', word_class: 'N' },
    { word: 'capitate', word_class: 'AJ' },
    { word: 'capitalism', word_class: 'N' },
    { word: 'capitalist', word_class: 'N' },
    { word: 'capitalize', word_class: 'V' },
    { word: 'capitation', word_class: 'N' },
    { word: 'capitalist', word_class: 'AJ' },
    { word: 'capitalistic', word_class: 'AJ' },
    { word: 'capitalization', word_class: 'N' }
  ],
  authorization: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  inland: [
    { word: 'inland', word_class: 'N' },
    { word: 'inland', word_class: 'V' },
    { word: 'inland', word_class: 'AJ' },
    { word: 'inland', word_class: 'AV' }
  ],
  lasciviously: [
    { word: 'lascivious', word_class: 'AJ' },
    { word: 'lasciviously', word_class: 'AV' },
    { word: 'lasciviousness', word_class: 'N' }
  ],
  illusion: [
    { word: 'illude', word_class: 'V' },
    { word: 'illusion', word_class: 'N' },
    { word: 'illusive', word_class: 'AJ' },
    { word: 'illusionary', word_class: 'AJ' }
  ],
  abradant: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  vendition: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  improbable: [
    { word: 'improbable', word_class: 'AJ' },
    { word: 'improbably', word_class: 'AV' },
    { word: 'improbability', word_class: 'N' },
    { word: 'improbableness', word_class: 'N' }
  ],
  dereliction: [
    { word: 'derelict', word_class: 'N' },
    { word: 'derelict', word_class: 'AJ' },
    { word: 'dereliction', word_class: 'N' }
  ],
  recognizable: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  biologically: [
    { word: 'biologic', word_class: 'AJ' },
    { word: 'biologism', word_class: 'N' },
    { word: 'biological', word_class: 'AJ' },
    { word: 'biologically', word_class: 'AV' }
  ],
  tanglement: [
    { word: 'tangle', word_class: 'N' },
    { word: 'tangle', word_class: 'V' },
    { word: 'tangled', word_class: 'AJ' },
    { word: 'tanglement', word_class: 'N' }
  ],
  humming: [
    { word: 'hum', word_class: 'N' },
    { word: 'hum', word_class: 'V' },
    { word: 'humor', word_class: 'N' },
    { word: 'humor', word_class: 'V' },
    { word: 'humming', word_class: 'N' },
    { word: 'humoral', word_class: 'AJ' },
    { word: 'humoring', word_class: 'N' },
    { word: 'humorous', word_class: 'AJ' },
    { word: 'humorously', word_class: 'AV' },
    { word: 'humorousness', word_class: 'N' }
  ],
  handsome: [
    { word: 'handsome', word_class: 'AJ' },
    { word: 'handsomely', word_class: 'AV' },
    { word: 'handsomeness', word_class: 'N' }
  ],
  chosen: [
    { word: 'choice', word_class: 'N' },
    { word: 'choose', word_class: 'V' },
    { word: 'chosen', word_class: 'N' },
    { word: 'choosy', word_class: 'AJ' },
    { word: 'choice', word_class: 'AJ' },
    { word: 'chosen', word_class: 'AJ' },
    { word: 'choiceness', word_class: 'N' }
  ],
  sherardization: [
    { word: 'sherardise', word_class: 'V' },
    { word: 'sherardize', word_class: 'V' },
    { word: 'sherardisation', word_class: 'N' },
    { word: 'sherardization', word_class: 'N' }
  ],
  smoothness: [
    { word: 'smooth', word_class: 'N' },
    { word: 'smooth', word_class: 'V' },
    { word: 'smooth', word_class: 'AJ' },
    { word: 'smoother', word_class: 'N' },
    { word: 'smoothed', word_class: 'AJ' },
    { word: 'smoothness', word_class: 'N' }
  ],
  honorary: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  northwest: [
    { word: 'northwest', word_class: 'N' },
    { word: 'northwest', word_class: 'AJ' },
    { word: 'northwest', word_class: 'AV' },
    { word: 'northwester', word_class: 'N' }
  ],
  transiently: [
    { word: 'transient', word_class: 'N' },
    { word: 'transience', word_class: 'N' },
    { word: 'transiency', word_class: 'N' },
    { word: 'transient', word_class: 'AJ' },
    { word: 'transiently', word_class: 'AV' }
  ],
  roam: [{ word: 'roam', word_class: 'N' }, { word: 'roam', word_class: 'V' }, { word: 'roamer', word_class: 'N' }],
  eruct: [
    { word: 'eruct', word_class: 'V' },
    { word: 'eructate', word_class: 'V' },
    { word: 'eructation', word_class: 'N' }
  ],
  intoxicated: [
    { word: 'intoxicant', word_class: 'N' },
    { word: 'intoxicate', word_class: 'V' },
    { word: 'intoxicant', word_class: 'AJ' },
    { word: 'intoxicated', word_class: 'AJ' },
    { word: 'intoxication', word_class: 'N' },
    { word: 'intoxicating', word_class: 'AJ' }
  ],
  decapitate: [
    { word: 'decapitate', word_class: 'V' },
    { word: 'decapitated', word_class: 'AJ' },
    { word: 'decapitation', word_class: 'N' }
  ],
  herd: [
    { word: 'herd', word_class: 'N' },
    { word: 'herd', word_class: 'V' },
    { word: 'herder', word_class: 'N' },
    { word: 'herding', word_class: 'AJ' }
  ],
  sincerity: [
    { word: 'sincere', word_class: 'AJ' },
    { word: 'sincerity', word_class: 'N' },
    { word: 'sincerely', word_class: 'AV' }
  ],
  alternately: [
    { word: 'alternant', word_class: 'N' },
    { word: 'alternate', word_class: 'N' },
    { word: 'alternate', word_class: 'V' },
    { word: 'alternate', word_class: 'AJ' },
    { word: 'alternator', word_class: 'N' },
    { word: 'alternation', word_class: 'N' },
    { word: 'alternative', word_class: 'N' },
    { word: 'alternately', word_class: 'AV' },
    { word: 'alternating', word_class: 'AJ' },
    { word: 'alternative', word_class: 'AJ' },
    { word: 'alternatively', word_class: 'AV' }
  ],
  desensitise: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  pilgrim: [
    { word: 'pilgrim', word_class: 'N' },
    { word: 'pilgrimage', word_class: 'N' },
    { word: 'pilgrimage', word_class: 'V' }
  ],
  growl: [
    { word: 'growl', word_class: 'N' },
    { word: 'growl', word_class: 'V' },
    { word: 'growler', word_class: 'N' },
    { word: 'growling', word_class: 'N' }
  ],
  misappropriate: [
    { word: 'misappropriate', word_class: 'V' },
    { word: 'misappropriated', word_class: 'AJ' },
    { word: 'misappropriation', word_class: 'N' }
  ],
  examine: [
    { word: 'examine', word_class: 'N' },
    { word: 'examine', word_class: 'V' },
    { word: 'examinee', word_class: 'N' },
    { word: 'examiner', word_class: 'N' },
    { word: 'examination', word_class: 'N' }
  ],
  obliterating: [
    { word: 'obliterate', word_class: 'V' },
    { word: 'obliterate', word_class: 'AJ' },
    { word: 'obliterable', word_class: 'AJ' },
    { word: 'obliterated', word_class: 'AJ' },
    { word: 'obliteration', word_class: 'N' },
    { word: 'obliterating', word_class: 'AJ' }
  ],
  conspicuous: [
    { word: 'conspicuous', word_class: 'AJ' },
    { word: 'conspicuously', word_class: 'AV' },
    { word: 'conspicuousness', word_class: 'N' }
  ],
  photoconduction: [
    { word: 'photoconduction', word_class: 'N' },
    { word: 'photoconductive', word_class: 'AJ' },
    { word: 'photoconductivity', word_class: 'N' }
  ],
  canned: [
    { word: 'can', word_class: 'N' },
    { word: 'can', word_class: 'V' },
    { word: 'canful', word_class: 'N' },
    { word: 'canful', word_class: 'AJ' },
    { word: 'canned', word_class: 'AJ' },
    { word: 'cannery', word_class: 'N' },
    { word: 'canning', word_class: 'N' },
    { word: 'cannery', word_class: 'AJ' }
  ],
  transcription: [
    { word: 'transcribe', word_class: 'V' },
    { word: 'transcript', word_class: 'N' },
    { word: 'transcribed', word_class: 'AJ' },
    { word: 'transcription', word_class: 'N' }
  ],
  respectability: [
    { word: 'respect', word_class: 'N' },
    { word: 'respect', word_class: 'V' },
    { word: 'respects', word_class: 'N' },
    { word: 'respecter', word_class: 'N' },
    { word: 'respected', word_class: 'AJ' },
    { word: 'respectful', word_class: 'AJ' },
    { word: 'respective', word_class: 'AJ' },
    { word: 'respectable', word_class: 'AJ' },
    { word: 'respectably', word_class: 'AV' },
    { word: 'respectively', word_class: 'AV' },
    { word: 'respectability', word_class: 'N' },
    { word: 'respectfulness', word_class: 'N' }
  ],
  elevate: [
    { word: 'elevate', word_class: 'V' },
    { word: 'elevator', word_class: 'N' },
    { word: 'elevated', word_class: 'AJ' },
    { word: 'elevation', word_class: 'N' }
  ],
  machine: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  signor: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  aboriginal: [
    { word: 'aborigine', word_class: 'N' },
    { word: 'aboriginal', word_class: 'N' },
    { word: 'aboriginal', word_class: 'AJ' }
  ],
  destructively: [
    { word: 'destroy', word_class: 'V' },
    { word: 'destruct', word_class: 'V' },
    { word: 'destroyer', word_class: 'N' },
    { word: 'destroyed', word_class: 'AJ' },
    { word: 'destruction', word_class: 'N' },
    { word: 'destroyable', word_class: 'AJ' },
    { word: 'destructive', word_class: 'AJ' },
    { word: 'destructible', word_class: 'AJ' },
    { word: 'destructively', word_class: 'AV' },
    { word: 'destructibility', word_class: 'N' },
    { word: 'destructiveness', word_class: 'N' }
  ],
  lithograph: [
    { word: 'lithograph', word_class: 'N' },
    { word: 'lithograph', word_class: 'V' },
    { word: 'lithography', word_class: 'N' },
    { word: 'lithographer', word_class: 'N' },
    { word: 'lithography', word_class: 'AJ' },
    { word: 'lithographic', word_class: 'AJ' }
  ],
  peeing: [
    { word: 'pee', word_class: 'N' },
    { word: 'pee', word_class: 'V' },
    { word: 'pest', word_class: 'N' },
    { word: 'peeing', word_class: 'N' }
  ],
  verticillate: [
    { word: 'verticil', word_class: 'N' },
    { word: 'verticillate', word_class: 'AJ' },
    { word: 'verticillated', word_class: 'AJ' }
  ],
  legally: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  accuse: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  economics: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  dissemblance: [
    { word: 'dissemble', word_class: 'V' },
    { word: 'dissembler', word_class: 'N' },
    { word: 'dissembling', word_class: 'N' },
    { word: 'dissemblance', word_class: 'N' },
    { word: 'dissembling', word_class: 'AJ' }
  ],
  impasse: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  smirched: [
    { word: 'smirch', word_class: 'N' },
    { word: 'smirch', word_class: 'V' },
    { word: 'smirched', word_class: 'AJ' }
  ],
  ratiocinate: [
    { word: 'ratiocinate', word_class: 'V' },
    { word: 'ratiocinator', word_class: 'N' },
    { word: 'ratiocination', word_class: 'N' },
    { word: 'ratiocinative', word_class: 'AJ' }
  ],
  sphericity: [
    { word: 'spheric', word_class: 'AJ' },
    { word: 'spherical', word_class: 'AJ' },
    { word: 'sphericity', word_class: 'N' },
    { word: 'spherically', word_class: 'AV' },
    { word: 'sphericalness', word_class: 'N' }
  ],
  comparing: [
    { word: 'compare', word_class: 'N' },
    { word: 'compare', word_class: 'V' },
    { word: 'comparing', word_class: 'N' },
    { word: 'comparator', word_class: 'N' },
    { word: 'comparison', word_class: 'N' },
    { word: 'comparable', word_class: 'AJ' },
    { word: 'comparably', word_class: 'AV' },
    { word: 'comparative', word_class: 'N' },
    { word: 'comparative', word_class: 'AJ' },
    { word: 'comparability', word_class: 'N' },
    { word: 'comparatively', word_class: 'AV' }
  ],
  compiler: [
    { word: 'compile', word_class: 'V' },
    { word: 'compiler', word_class: 'N' },
    { word: 'compiling', word_class: 'N' },
    { word: 'compilation', word_class: 'N' }
  ],
  emotive: [
    { word: 'emote', word_class: 'V' },
    { word: 'emotion', word_class: 'N' },
    { word: 'emotive', word_class: 'AJ' },
    { word: 'emotional', word_class: 'AJ' }
  ],
  adapted: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  vapor: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  flection: [
    { word: 'flex', word_class: 'N' },
    { word: 'flex', word_class: 'V' },
    { word: 'flexor', word_class: 'N' },
    { word: 'flexion', word_class: 'N' },
    { word: 'flection', word_class: 'N' }
  ],
  bubbly: [
    { word: 'bubble', word_class: 'N' },
    { word: 'bubble', word_class: 'V' },
    { word: 'bubbly', word_class: 'N' },
    { word: 'bubbler', word_class: 'N' },
    { word: 'bubbly', word_class: 'AJ' },
    { word: 'bubbling', word_class: 'AJ' }
  ],
  operating: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  contemporary: [
    { word: 'contemporary', word_class: 'N' },
    { word: 'contemporary', word_class: 'AJ' },
    { word: 'contemporaries', word_class: 'N' }
  ],
  american: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  reverently: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  dominical: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  voluptuousness: [
    { word: 'voluptuous', word_class: 'AJ' },
    { word: 'voluptuously', word_class: 'AV' },
    { word: 'voluptuousness', word_class: 'N' }
  ],
  tatting: [
    { word: 'tat', word_class: 'N' },
    { word: 'tat', word_class: 'V' },
    { word: 'tatt', word_class: 'V' },
    { word: 'tater', word_class: 'N' },
    { word: 'tatting', word_class: 'N' }
  ],
  prime: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  edified: [
    { word: 'edify', word_class: 'V' },
    { word: 'edified', word_class: 'AJ' },
    { word: 'edifying', word_class: 'AJ' },
    { word: 'edification', word_class: 'N' }
  ],
  creative: [
    { word: 'creative', word_class: 'AJ' },
    { word: 'creativity', word_class: 'N' },
    { word: 'creatively', word_class: 'AV' },
    { word: 'creativeness', word_class: 'N' }
  ],
  mend: [
    { word: 'mend', word_class: 'N' },
    { word: 'mend', word_class: 'V' },
    { word: 'mender', word_class: 'N' },
    { word: 'mending', word_class: 'N' }
  ],
  evacuation: [
    { word: 'evacuee', word_class: 'N' },
    { word: 'evacuate', word_class: 'V' },
    { word: 'evacuant', word_class: 'AJ' },
    { word: 'evacuated', word_class: 'AJ' },
    { word: 'evacuation', word_class: 'N' }
  ],
  whiteness: [
    { word: 'white', word_class: 'N' },
    { word: 'white', word_class: 'V' },
    { word: 'white', word_class: 'AJ' },
    { word: 'whiting', word_class: 'N' },
    { word: 'whiteness', word_class: 'N' }
  ],
  rinse: [{ word: 'rinse', word_class: 'N' }, { word: 'rinse', word_class: 'V' }, { word: 'rinsing', word_class: 'N' }],
  implement: [
    { word: 'implement', word_class: 'N' },
    { word: 'implement', word_class: 'V' },
    { word: 'implemental', word_class: 'AJ' },
    { word: 'implemented', word_class: 'AJ' },
    { word: 'implementation', word_class: 'N' }
  ],
  back: [
    { word: 'back', word_class: 'N' },
    { word: 'back', word_class: 'V' },
    { word: 'back', word_class: 'AJ' },
    { word: 'back', word_class: 'AV' },
    { word: 'backer', word_class: 'N' },
    { word: 'backed', word_class: 'AJ' },
    { word: 'backing', word_class: 'N' }
  ],
  discrete: [
    { word: 'discrete', word_class: 'N' },
    { word: 'discrete', word_class: 'AJ' },
    { word: 'discretion', word_class: 'N' },
    { word: 'discreteness', word_class: 'N' },
    { word: 'discretional', word_class: 'AJ' }
  ],
  keeper: [
    { word: 'keep', word_class: 'N' },
    { word: 'keep', word_class: 'V' },
    { word: 'keeper', word_class: 'N' },
    { word: 'keeping', word_class: 'N' }
  ],
  devastated: [
    { word: 'devastate', word_class: 'V' },
    { word: 'devastated', word_class: 'AJ' },
    { word: 'devastation', word_class: 'N' },
    { word: 'devastating', word_class: 'AJ' }
  ],
  stagger: [
    { word: 'stagger', word_class: 'N' },
    { word: 'stagger', word_class: 'V' },
    { word: 'staggerer', word_class: 'N' },
    { word: 'staggering', word_class: 'AJ' }
  ],
  broiling: [
    { word: 'broil', word_class: 'N' },
    { word: 'broil', word_class: 'V' },
    { word: 'broiler', word_class: 'N' },
    { word: 'broiled', word_class: 'AJ' },
    { word: 'broiling', word_class: 'N' }
  ],
  efficiently: [
    { word: 'efficiency', word_class: 'N' },
    { word: 'efficient', word_class: 'AJ' },
    { word: 'efficiently', word_class: 'AV' }
  ],
  bell: [
    { word: 'bell', word_class: 'N' },
    { word: 'bell', word_class: 'V' },
    { word: 'belle', word_class: 'N' },
    { word: 'belling', word_class: 'N' },
    { word: 'bellman', word_class: 'N' }
  ],
  doubling: [
    { word: 'double', word_class: 'N' },
    { word: 'double', word_class: 'V' },
    { word: 'double', word_class: 'AJ' },
    { word: 'double', word_class: 'AV' },
    { word: 'doubles', word_class: 'N' },
    { word: 'doubly', word_class: 'AV' },
    { word: 'doubled', word_class: 'AJ' },
    { word: 'doubling', word_class: 'N' }
  ],
  decarburize: [
    { word: 'decarburise', word_class: 'V' },
    { word: 'decarburize', word_class: 'V' },
    { word: 'decarburation', word_class: 'N' },
    { word: 'decarburisation', word_class: 'N' },
    { word: 'decarburization', word_class: 'N' }
  ],
  simultaneously: [
    { word: 'simultaneity', word_class: 'N' },
    { word: 'simultaneous', word_class: 'AJ' },
    { word: 'simultaneously', word_class: 'AV' },
    { word: 'simultaneousness', word_class: 'N' }
  ],
  compulsively: [
    { word: 'compel', word_class: 'V' },
    { word: 'compell', word_class: 'V' },
    { word: 'compelled', word_class: 'AJ' },
    { word: 'compulsion', word_class: 'N' },
    { word: 'compulsive', word_class: 'N' },
    { word: 'compulsive', word_class: 'AJ' },
    { word: 'compulsory', word_class: 'AJ' },
    { word: 'compelling', word_class: 'AJ' },
    { word: 'compulsivity', word_class: 'N' },
    { word: 'compulsively', word_class: 'AV' },
    { word: 'compulsiveness', word_class: 'N' }
  ],
  viability: [
    { word: 'viable', word_class: 'AJ' },
    { word: 'viably', word_class: 'AV' },
    { word: 'viability', word_class: 'N' }
  ],
  flasher: [
    { word: 'flash', word_class: 'N' },
    { word: 'flash', word_class: 'V' },
    { word: 'flash', word_class: 'AJ' },
    { word: 'flasher', word_class: 'N' },
    { word: 'flashing', word_class: 'N' },
    { word: 'flashing', word_class: 'AJ' }
  ],
  exporter: [
    { word: 'export', word_class: 'N' },
    { word: 'export', word_class: 'V' },
    { word: 'exporter', word_class: 'N' },
    { word: 'exporting', word_class: 'N' },
    { word: 'exportable', word_class: 'AJ' },
    { word: 'exportation', word_class: 'N' }
  ],
  circularize: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  laconically: [
    { word: 'laconic', word_class: 'AJ' },
    { word: 'laconism', word_class: 'N' },
    { word: 'laconically', word_class: 'AV' }
  ],
  extra: [{ word: 'extra', word_class: 'N' }, { word: 'extra', word_class: 'AJ' }, { word: 'extra', word_class: 'AV' }],
  north: [{ word: 'north', word_class: 'N' }, { word: 'north', word_class: 'AJ' }, { word: 'north', word_class: 'AV' }],
  stabilizing: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  disinterested: [
    { word: 'disinterest', word_class: 'N' },
    { word: 'disinterest', word_class: 'V' },
    { word: 'disinterested', word_class: 'AJ' }
  ],
  pantheistic: [
    { word: 'pantheist', word_class: 'N' },
    { word: 'pantheist', word_class: 'AJ' },
    { word: 'pantheistic', word_class: 'AJ' }
  ],
  shadowing: [
    { word: 'shadow', word_class: 'N' },
    { word: 'shadow', word_class: 'V' },
    { word: 'shadowed', word_class: 'AJ' },
    { word: 'shadowing', word_class: 'N' },
    { word: 'shadowing', word_class: 'AJ' }
  ],
  loss: [
    { word: 'lose', word_class: 'V' },
    { word: 'loss', word_class: 'N' },
    { word: 'loser', word_class: 'N' },
    { word: 'losses', word_class: 'N' },
    { word: 'losing', word_class: 'AJ' },
    { word: 'losings', word_class: 'N' }
  ],
  mathematical: [
    { word: 'mathematic', word_class: 'AJ' },
    { word: 'mathematics', word_class: 'N' },
    { word: 'mathematical', word_class: 'AJ' },
    { word: 'mathematically', word_class: 'AV' }
  ],
  observational: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  expend: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  transfer: [
    { word: 'transfer', word_class: 'N' },
    { word: 'transfer', word_class: 'V' },
    { word: 'transferral', word_class: 'N' },
    { word: 'transference', word_class: 'N' },
    { word: 'transferable', word_class: 'AJ' },
    { word: 'transferrable', word_class: 'AJ' },
    { word: 'transferability', word_class: 'N' }
  ],
  handle: [
    { word: 'handle', word_class: 'N' },
    { word: 'handle', word_class: 'V' },
    { word: 'handler', word_class: 'N' },
    { word: 'handled', word_class: 'AJ' },
    { word: 'handling', word_class: 'N' }
  ],
  stock: [
    { word: 'stock', word_class: 'N' },
    { word: 'stock', word_class: 'V' },
    { word: 'stock', word_class: 'AJ' },
    { word: 'stocks', word_class: 'N' },
    { word: 'stocker', word_class: 'N' },
    { word: 'stockist', word_class: 'N' },
    { word: 'stocked', word_class: 'AJ' },
    { word: 'stockman', word_class: 'N' },
    { word: 'stocking', word_class: 'N' },
    { word: 'stocking', word_class: 'AJ' }
  ],
  diminished: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  transitive: [
    { word: 'transit', word_class: 'N' },
    { word: 'transit', word_class: 'V' },
    { word: 'transition', word_class: 'N' },
    { word: 'transitive', word_class: 'AJ' },
    { word: 'transitivity', word_class: 'N' },
    { word: 'transitional', word_class: 'AJ' },
    { word: 'transitively', word_class: 'AV' },
    { word: 'transitiveness', word_class: 'N' }
  ],
  woodwork: [
    { word: 'woodwork', word_class: 'N' },
    { word: 'woodworker', word_class: 'N' },
    { word: 'woodworking', word_class: 'N' }
  ],
  substituting: [
    { word: 'substitute', word_class: 'N' },
    { word: 'substitute', word_class: 'V' },
    { word: 'substitute', word_class: 'AJ' },
    { word: 'substituting', word_class: 'N' },
    { word: 'substitution', word_class: 'N' },
    { word: 'substitutable', word_class: 'AJ' },
    { word: 'substitutability', word_class: 'N' },
    { word: 'substitutionary', word_class: 'AJ' }
  ],
  nibble: [
    { word: 'nibble', word_class: 'N' },
    { word: 'nibble', word_class: 'V' },
    { word: 'nibbling', word_class: 'N' }
  ],
  commandant: [
    { word: 'command', word_class: 'N' },
    { word: 'command', word_class: 'V' },
    { word: 'commander', word_class: 'N' },
    { word: 'commandant', word_class: 'N' },
    { word: 'commandeer', word_class: 'V' },
    { word: 'commandeer', word_class: 'N' },
    { word: 'commanding', word_class: 'AJ' },
    { word: 'commandment', word_class: 'N' }
  ],
  regimentals: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  improved: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  splayfoot: [
    { word: 'splayfoot', word_class: 'N' },
    { word: 'splayfoot', word_class: 'AJ' },
    { word: 'splayfooted', word_class: 'AJ' }
  ],
  retirement: [
    { word: 'retire', word_class: 'N' },
    { word: 'retire', word_class: 'V' },
    { word: 'retiree', word_class: 'N' },
    { word: 'retired', word_class: 'AJ' },
    { word: 'retiring', word_class: 'AJ' },
    { word: 'retirement', word_class: 'N' }
  ],
  economiser: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  housebreaker: [
    { word: 'housebreak', word_class: 'V' },
    { word: 'housebreaker', word_class: 'N' },
    { word: 'housebroken', word_class: 'AJ' },
    { word: 'housebreaking', word_class: 'N' }
  ],
  bungle: [
    { word: 'bungle', word_class: 'N' },
    { word: 'bungle', word_class: 'V' },
    { word: 'bungler', word_class: 'N' },
    { word: 'bungled', word_class: 'AJ' },
    { word: 'bungling', word_class: 'N' },
    { word: 'bungling', word_class: 'AJ' }
  ],
  hyperbolic: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  anonymity: [
    { word: 'anonym', word_class: 'N' },
    { word: 'anonymity', word_class: 'N' },
    { word: 'anonymous', word_class: 'AJ' },
    { word: 'anonymously', word_class: 'AV' }
  ],
  southeast: [
    { word: 'southeast', word_class: 'N' },
    { word: 'southeast', word_class: 'AJ' },
    { word: 'southeast', word_class: 'AV' },
    { word: 'southeaster', word_class: 'N' }
  ],
  tubulation: [
    { word: 'tubule', word_class: 'N' },
    { word: 'tubulate', word_class: 'V' },
    { word: 'tubulation', word_class: 'N' }
  ],
  nauseated: [
    { word: 'nausea', word_class: 'N' },
    { word: 'nauseate', word_class: 'V' },
    { word: 'nauseous', word_class: 'AJ' },
    { word: 'nauseated', word_class: 'AJ' },
    { word: 'nauseation', word_class: 'N' },
    { word: 'nauseating', word_class: 'AJ' }
  ],
  divulsion: [
    { word: 'divulge', word_class: 'V' },
    { word: 'divulgate', word_class: 'V' },
    { word: 'divulsion', word_class: 'N' },
    { word: 'divulgence', word_class: 'N' },
    { word: 'divulgation', word_class: 'N' },
    { word: 'divulgement', word_class: 'N' }
  ],
  allegorisation: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  ecologic: [
    { word: 'ecology', word_class: 'N' },
    { word: 'ecologic', word_class: 'AJ' },
    { word: 'ecological', word_class: 'AJ' },
    { word: 'ecologically', word_class: 'AV' }
  ],
  structure: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  stakes: [
    { word: 'stake', word_class: 'N' },
    { word: 'stake', word_class: 'V' },
    { word: 'stakes', word_class: 'N' },
    { word: 'staking', word_class: 'N' }
  ],
  disbelieving: [
    { word: 'disbelief', word_class: 'N' },
    { word: 'disbelieve', word_class: 'V' },
    { word: 'disbeliever', word_class: 'N' },
    { word: 'disbelieving', word_class: 'AJ' }
  ],
  unreliable: [
    { word: 'unreliable', word_class: 'AJ' },
    { word: 'unreliably', word_class: 'AV' },
    { word: 'unreliability', word_class: 'N' },
    { word: 'unreliableness', word_class: 'N' }
  ],
  wainscotting: [
    { word: 'wainscot', word_class: 'N' },
    { word: 'wainscot', word_class: 'V' },
    { word: 'wainscoted', word_class: 'AJ' },
    { word: 'wainscoting', word_class: 'N' },
    { word: 'wainscotting', word_class: 'N' }
  ],
  seism: [
    { word: 'see', word_class: 'N' },
    { word: 'see', word_class: 'V' },
    { word: 'seer', word_class: 'N' },
    { word: 'see', word_class: 'AV' },
    { word: 'seen', word_class: 'AJ' },
    { word: 'seism', word_class: 'N' },
    { word: 'sight', word_class: 'N' },
    { word: 'sight', word_class: 'V' },
    { word: 'seance', word_class: 'N' },
    { word: 'seeing', word_class: 'N' },
    { word: 'seeing', word_class: 'AJ' },
    { word: 'sighted', word_class: 'AJ' },
    { word: 'sighting', word_class: 'N' }
  ],
  hipped: [{ word: 'hip', word_class: 'N' }, { word: 'hip', word_class: 'AJ' }, { word: 'hipped', word_class: 'AJ' }],
  scud: [{ word: 'scud', word_class: 'N' }, { word: 'scud', word_class: 'V' }, { word: 'scudding', word_class: 'N' }],
  discontented: [
    { word: 'discontent', word_class: 'N' },
    { word: 'discontent', word_class: 'V' },
    { word: 'discontent', word_class: 'AJ' },
    { word: 'discontented', word_class: 'AJ' },
    { word: 'discontentment', word_class: 'N' }
  ],
  legalism: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  boisterous: [
    { word: 'boisterous', word_class: 'AJ' },
    { word: 'boisterously', word_class: 'AV' },
    { word: 'boisterousness', word_class: 'N' }
  ],
  humourist: [
    { word: 'humour', word_class: 'N' },
    { word: 'humour', word_class: 'V' },
    { word: 'humourist', word_class: 'N' },
    { word: 'humourous', word_class: 'AJ' },
    { word: 'humourously', word_class: 'AV' }
  ],
  centralisation: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  disenfranchisement: [
    { word: 'disenfranchise', word_class: 'V' },
    { word: 'disenfranchised', word_class: 'AJ' },
    { word: 'disenfranchisement', word_class: 'N' }
  ],
  dominos: [
    { word: 'domino', word_class: 'N' },
    { word: 'dominos', word_class: 'N' },
    { word: 'dominoes', word_class: 'N' }
  ],
  tuner: [
    { word: 'tune', word_class: 'N' },
    { word: 'tune', word_class: 'V' },
    { word: 'tuner', word_class: 'N' },
    { word: 'tuned', word_class: 'AJ' },
    { word: 'tuning', word_class: 'N' },
    { word: 'tuneful', word_class: 'AJ' },
    { word: 'tunefulness', word_class: 'N' }
  ],
  acidulation: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  discordant: [
    { word: 'discord', word_class: 'N' },
    { word: 'discord', word_class: 'V' },
    { word: 'discordant', word_class: 'N' },
    { word: 'discordance', word_class: 'N' },
    { word: 'discordant', word_class: 'AJ' }
  ],
  liquefaction: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  confirmed: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  painful: [
    { word: 'pain', word_class: 'N' },
    { word: 'pain', word_class: 'V' },
    { word: 'pains', word_class: 'N' },
    { word: 'pained', word_class: 'AJ' },
    { word: 'painful', word_class: 'AJ' },
    { word: 'painfulness', word_class: 'N' }
  ],
  argumentative: [
    { word: 'argue', word_class: 'V' },
    { word: 'argus', word_class: 'N' },
    { word: 'arguer', word_class: 'N' },
    { word: 'arguing', word_class: 'N' },
    { word: 'arguing', word_class: 'AJ' },
    { word: 'argument', word_class: 'N' },
    { word: 'argumentation', word_class: 'N' },
    { word: 'argumentative', word_class: 'AJ' },
    { word: 'argumentatively', word_class: 'AV' }
  ],
  inconspicuousness: [
    { word: 'inconspicuous', word_class: 'AJ' },
    { word: 'inconspicuously', word_class: 'AV' },
    { word: 'inconspicuousness', word_class: 'N' }
  ],
  heal: [
    { word: 'heal', word_class: 'V' },
    { word: 'healer', word_class: 'N' },
    { word: 'healed', word_class: 'AJ' },
    { word: 'healing', word_class: 'N' },
    { word: 'healing', word_class: 'AJ' }
  ],
  discriminative: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  hanger: [
    { word: 'hang', word_class: 'N' },
    { word: 'hang', word_class: 'V' },
    { word: 'hanger', word_class: 'N' },
    { word: 'hanging', word_class: 'N' },
    { word: 'hanging', word_class: 'V' },
    { word: 'hangman', word_class: 'N' },
    { word: 'hanging', word_class: 'AJ' }
  ],
  depose: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  geometrize: [
    { word: 'geometrize', word_class: 'V' },
    { word: 'geometrise', word_class: 'V' },
    { word: 'geometric', word_class: 'AJ' },
    { word: 'geometrical', word_class: 'AJ' },
    { word: 'geometrically', word_class: 'AV' },
    { word: 'geometrization', word_class: 'N' }
  ],
  baring: [
    { word: 'bare', word_class: 'V' },
    { word: 'bare', word_class: 'AJ' },
    { word: 'bared', word_class: 'AJ' },
    { word: 'barite', word_class: 'N' },
    { word: 'baring', word_class: 'N' },
    { word: 'barely', word_class: 'AV' },
    { word: 'bareness', word_class: 'N' }
  ],
  spiritual: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  finishing: [
    { word: 'finish', word_class: 'N' },
    { word: 'finish', word_class: 'V' },
    { word: 'finish', word_class: 'AJ' },
    { word: 'finisher', word_class: 'N' },
    { word: 'finished', word_class: 'AJ' },
    { word: 'finishing', word_class: 'N' }
  ],
  slothfulness: [
    { word: 'sloth', word_class: 'N' },
    { word: 'slothful', word_class: 'AJ' },
    { word: 'slothfulness', word_class: 'N' }
  ],
  gunnery: [
    { word: 'gun', word_class: 'N' },
    { word: 'gun', word_class: 'V' },
    { word: 'gunnery', word_class: 'N' },
    { word: 'gunnery', word_class: 'AJ' }
  ],
  overwhelmed: [
    { word: 'overwhelm', word_class: 'V' },
    { word: 'overwhelmed', word_class: 'AJ' },
    { word: 'overwhelming', word_class: 'AJ' }
  ],
  illative: [
    { word: 'ill', word_class: 'N' },
    { word: 'ill', word_class: 'AJ' },
    { word: 'ill', word_class: 'AV' },
    { word: 'illness', word_class: 'N' },
    { word: 'illative', word_class: 'AJ' }
  ],
  insecurity: [
    { word: 'insecure', word_class: 'AJ' },
    { word: 'insecurity', word_class: 'N' },
    { word: 'insecurely', word_class: 'AV' },
    { word: 'insecureness', word_class: 'N' }
  ],
  proclaimed: [
    { word: 'proclaim', word_class: 'N' },
    { word: 'proclaim', word_class: 'V' },
    { word: 'proclaimed', word_class: 'AJ' },
    { word: 'proclamation', word_class: 'N' }
  ],
  bully: [
    { word: 'bully', word_class: 'N' },
    { word: 'bully', word_class: 'V' },
    { word: 'bully', word_class: 'AJ' },
    { word: 'bullied', word_class: 'AJ' },
    { word: 'bullying', word_class: 'N' },
    { word: 'bullying', word_class: 'AJ' }
  ],
  sensualization: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  beating: [
    { word: 'beat', word_class: 'N' },
    { word: 'beat', word_class: 'V' },
    { word: 'beat', word_class: 'AJ' },
    { word: 'beater', word_class: 'N' },
    { word: 'beaten', word_class: 'AJ' },
    { word: 'beating', word_class: 'N' },
    { word: 'beating', word_class: 'AJ' }
  ],
  machinator: [
    { word: 'machine', word_class: 'N' },
    { word: 'machine', word_class: 'V' },
    { word: 'machinery', word_class: 'N' },
    { word: 'machinate', word_class: 'V' },
    { word: 'machinist', word_class: 'N' },
    { word: 'machinator', word_class: 'N' },
    { word: 'machination', word_class: 'N' }
  ],
  imposed: [
    { word: 'impose', word_class: 'N' },
    { word: 'impose', word_class: 'V' },
    { word: 'imposed', word_class: 'AJ' },
    { word: 'imposing', word_class: 'AJ' },
    { word: 'imposition', word_class: 'N' }
  ],
  leveling: [
    { word: 'level', word_class: 'N' },
    { word: 'level', word_class: 'V' },
    { word: 'level', word_class: 'AJ' },
    { word: 'leveler', word_class: 'N' },
    { word: 'leveling', word_class: 'N' },
    { word: 'leveller', word_class: 'N' }
  ],
  expeditious: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  aggressive: [
    { word: 'aggress', word_class: 'V' },
    { word: 'aggression', word_class: 'N' },
    { word: 'aggressive', word_class: 'AJ' },
    { word: 'aggressively', word_class: 'AV' },
    { word: 'aggressiveness', word_class: 'N' }
  ],
  multiplier: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  coenobitical: [
    { word: 'coenobite', word_class: 'N' },
    { word: 'coenobitic', word_class: 'AJ' },
    { word: 'coenobitical', word_class: 'AJ' }
  ],
  pedal: [
    { word: 'pedal', word_class: 'N' },
    { word: 'pedal', word_class: 'V' },
    { word: 'pedal', word_class: 'AJ' },
    { word: 'pedaler', word_class: 'N' },
    { word: 'pedaller', word_class: 'N' }
  ],
  superimposed: [
    { word: 'superimpose', word_class: 'V' },
    { word: 'superimposed', word_class: 'AJ' },
    { word: 'superimposition', word_class: 'N' }
  ],
  lighter: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  overworking: [
    { word: 'overwork', word_class: 'N' },
    { word: 'overwork', word_class: 'V' },
    { word: 'overworking', word_class: 'N' }
  ],
  gulping: [{ word: 'gulp', word_class: 'N' }, { word: 'gulp', word_class: 'V' }, { word: 'gulping', word_class: 'N' }],
  immigration: [
    { word: 'immigrant', word_class: 'N' },
    { word: 'immigrate', word_class: 'V' },
    { word: 'immigration', word_class: 'N' }
  ],
  pungency: [
    { word: 'pungency', word_class: 'N' },
    { word: 'pungent', word_class: 'AJ' },
    { word: 'pungently', word_class: 'AV' }
  ],
  enrapture: [
    { word: 'enrapture', word_class: 'V' },
    { word: 'enraptured', word_class: 'AJ' },
    { word: 'enrapturement', word_class: 'N' }
  ],
  pilot: [
    { word: 'pilot', word_class: 'N' },
    { word: 'pilot', word_class: 'V' },
    { word: 'pilot', word_class: 'AJ' },
    { word: 'pilotage', word_class: 'N' },
    { word: 'piloting', word_class: 'N' }
  ],
  mysteriously: [
    { word: 'mystery', word_class: 'N' },
    { word: 'mysterious', word_class: 'AJ' },
    { word: 'mysteriously', word_class: 'AV' }
  ],
  particularism: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  included: [
    { word: 'include', word_class: 'V' },
    { word: 'included', word_class: 'AJ' },
    { word: 'inclusion', word_class: 'N' },
    { word: 'inclusive', word_class: 'AJ' }
  ],
  populated: [
    { word: 'populate', word_class: 'V' },
    { word: 'populism', word_class: 'N' },
    { word: 'populous', word_class: 'AJ' },
    { word: 'populated', word_class: 'AJ' },
    { word: 'populating', word_class: 'N' },
    { word: 'population', word_class: 'N' }
  ],
  scarred: [
    { word: 'scar', word_class: 'N' },
    { word: 'scar', word_class: 'V' },
    { word: 'scarred', word_class: 'AJ' }
  ],
  accusing: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  eternalize: [
    { word: 'eternal', word_class: 'AJ' },
    { word: 'eternity', word_class: 'N' },
    { word: 'eternize', word_class: 'V' },
    { word: 'eternalize', word_class: 'V' },
    { word: 'eternally', word_class: 'AV' },
    { word: 'eternisation', word_class: 'N' },
    { word: 'eternization', word_class: 'N' },
    { word: 'eternalisation', word_class: 'N' },
    { word: 'eternalization', word_class: 'N' }
  ],
  sensitive: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  initiator: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  intelligence: [
    { word: 'intelligence', word_class: 'N' },
    { word: 'intelligent', word_class: 'AJ' },
    { word: 'intelligible', word_class: 'AJ' },
    { word: 'intelligibly', word_class: 'AV' },
    { word: 'intelligently', word_class: 'AV' },
    { word: 'intelligibility', word_class: 'N' }
  ],
  distensible: [
    { word: 'distend', word_class: 'V' },
    { word: 'distended', word_class: 'AJ' },
    { word: 'distension', word_class: 'N' },
    { word: 'distention', word_class: 'N' },
    { word: 'distensible', word_class: 'AJ' }
  ],
  wrongdoing: [
    { word: 'wrong', word_class: 'N' },
    { word: 'wrong', word_class: 'V' },
    { word: 'wrong', word_class: 'AJ' },
    { word: 'wrong', word_class: 'AV' },
    { word: 'wronged', word_class: 'AJ' },
    { word: 'wrongdoer', word_class: 'N' },
    { word: 'wrongful', word_class: 'AJ' },
    { word: 'wrongness', word_class: 'N' },
    { word: 'wrongdoing', word_class: 'N' },
    { word: 'wrongfulness', word_class: 'N' }
  ],
  demolishing: [
    { word: 'demolish', word_class: 'V' },
    { word: 'demolition', word_class: 'N' },
    { word: 'demolished', word_class: 'AJ' },
    { word: 'demolishing', word_class: 'N' },
    { word: 'demolishment', word_class: 'N' }
  ],
  cultivation: [
    { word: 'cultivate', word_class: 'V' },
    { word: 'cultivator', word_class: 'N' },
    { word: 'cultivable', word_class: 'AJ' },
    { word: 'cultivated', word_class: 'AJ' },
    { word: 'cultivation', word_class: 'N' }
  ],
  dissipate: [
    { word: 'dissipate', word_class: 'V' },
    { word: 'dissipated', word_class: 'AJ' },
    { word: 'dissipation', word_class: 'N' }
  ],
  bawdy: [
    { word: 'bawdy', word_class: 'N' },
    { word: 'bawdy', word_class: 'AJ' },
    { word: 'bawdiness', word_class: 'N' }
  ],
  harmony: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  insanely: [
    { word: 'insane', word_class: 'AJ' },
    { word: 'insanity', word_class: 'N' },
    { word: 'insanely', word_class: 'AV' },
    { word: 'insaneness', word_class: 'N' }
  ],
  retailing: [
    { word: 'retail', word_class: 'N' },
    { word: 'retail', word_class: 'V' },
    { word: 'retail', word_class: 'AJ' },
    { word: 'retail', word_class: 'AV' },
    { word: 'retailer', word_class: 'N' },
    { word: 'retailing', word_class: 'N' }
  ],
  alike: [
    { word: 'alike', word_class: 'AJ' },
    { word: 'alike', word_class: 'AV' },
    { word: 'alikeness', word_class: 'N' }
  ],
  sonorous: [
    { word: 'sonorant', word_class: 'N' },
    { word: 'sonority', word_class: 'N' },
    { word: 'sonorant', word_class: 'AJ' },
    { word: 'sonorous', word_class: 'AJ' },
    { word: 'sonorously', word_class: 'AV' },
    { word: 'sonorousness', word_class: 'N' }
  ],
  appellate: [
    { word: 'appeal', word_class: 'N' },
    { word: 'appeal', word_class: 'V' },
    { word: 'appellant', word_class: 'N' },
    { word: 'appellant', word_class: 'AJ' },
    { word: 'appellate', word_class: 'AJ' },
    { word: 'appealing', word_class: 'AJ' },
    { word: 'appealable', word_class: 'AJ' },
    { word: 'appellation', word_class: 'N' },
    { word: 'appellative', word_class: 'N' },
    { word: 'appellative', word_class: 'AJ' }
  ],
  averse: [
    { word: 'avert', word_class: 'V' },
    { word: 'averse', word_class: 'AJ' },
    { word: 'aversion', word_class: 'N' },
    { word: 'averting', word_class: 'N' },
    { word: 'aversive', word_class: 'AJ' }
  ],
  housekeeper: [
    { word: 'housekeep', word_class: 'V' },
    { word: 'housekeeper', word_class: 'N' },
    { word: 'housekeeping', word_class: 'N' }
  ],
  susceptibleness: [
    { word: 'susceptible', word_class: 'AJ' },
    { word: 'susceptibly', word_class: 'AV' },
    { word: 'susceptibility', word_class: 'N' },
    { word: 'susceptibleness', word_class: 'N' }
  ],
  incurring: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  comprise: [
    { word: 'comprise', word_class: 'V' },
    { word: 'comprisal', word_class: 'N' },
    { word: 'comprising', word_class: 'N' }
  ],
  expeditiously: [
    { word: 'expedite', word_class: 'V' },
    { word: 'expedient', word_class: 'N' },
    { word: 'expedient', word_class: 'V' },
    { word: 'expedient', word_class: 'AJ' },
    { word: 'expediency', word_class: 'N' },
    { word: 'expedience', word_class: 'N' },
    { word: 'expedition', word_class: 'N' },
    { word: 'expedited', word_class: 'AJ' },
    { word: 'expediently', word_class: 'AV' },
    { word: 'expeditious', word_class: 'AJ' },
    { word: 'expeditiously', word_class: 'AV' },
    { word: 'expeditiousness', word_class: 'N' }
  ],
  pharisaical: [
    { word: 'pharisee', word_class: 'N' },
    { word: 'pharisaic', word_class: 'AJ' },
    { word: 'pharisaical', word_class: 'AJ' }
  ],
  thermion: [
    { word: 'thermion', word_class: 'N' },
    { word: 'thermionic', word_class: 'AJ' },
    { word: 'thermionics', word_class: 'N' }
  ],
  perpetration: [
    { word: 'perpetrate', word_class: 'V' },
    { word: 'perpetrator', word_class: 'N' },
    { word: 'perpetration', word_class: 'N' }
  ],
  skateboard: [
    { word: 'skateboard', word_class: 'N' },
    { word: 'skateboard', word_class: 'V' },
    { word: 'skateboarder', word_class: 'N' },
    { word: 'skateboarding', word_class: 'N' }
  ],
  gymnastic: [
    { word: 'gymnast', word_class: 'N' },
    { word: 'gymnastic', word_class: 'AJ' },
    { word: 'gymnastics', word_class: 'N' }
  ],
  accenting: [
    { word: 'accent', word_class: 'N' },
    { word: 'accent', word_class: 'V' },
    { word: 'accentor', word_class: 'N' },
    { word: 'accented', word_class: 'AJ' },
    { word: 'accenting', word_class: 'N' },
    { word: 'accentual', word_class: 'AJ' },
    { word: 'accentuate', word_class: 'V' },
    { word: 'accentuation', word_class: 'N' }
  ],
  encroachment: [
    { word: 'encroach', word_class: 'V' },
    { word: 'encroacher', word_class: 'N' },
    { word: 'encroaching', word_class: 'AJ' },
    { word: 'encroachment', word_class: 'N' }
  ],
  fearsomely: [
    { word: 'fear', word_class: 'N' },
    { word: 'fear', word_class: 'V' },
    { word: 'fearful', word_class: 'AJ' },
    { word: 'fearsome', word_class: 'AJ' },
    { word: 'fearfulness', word_class: 'N' },
    { word: 'fearsomely', word_class: 'AV' }
  ],
  marshalship: [
    { word: 'marshal', word_class: 'N' },
    { word: 'marshal', word_class: 'V' },
    { word: 'marshal', word_class: 'AJ' },
    { word: 'marshall', word_class: 'N' },
    { word: 'marshaling', word_class: 'N' },
    { word: 'marshaling', word_class: 'V' },
    { word: 'marshalship', word_class: 'N' }
  ],
  acoustics: [
    { word: 'acoustic', word_class: 'N' },
    { word: 'acoustic', word_class: 'AJ' },
    { word: 'acoustics', word_class: 'N' },
    { word: 'acoustical', word_class: 'AJ' },
    { word: 'acoustically', word_class: 'AV' }
  ],
  invest: [
    { word: 'invest', word_class: 'N' },
    { word: 'invest', word_class: 'V' },
    { word: 'investor', word_class: 'N' },
    { word: 'invested', word_class: 'AJ' },
    { word: 'investing', word_class: 'N' },
    { word: 'investment', word_class: 'N' }
  ],
  adjustor: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  vindictively: [
    { word: 'vindictive', word_class: 'AJ' },
    { word: 'vindictively', word_class: 'AV' },
    { word: 'vindictiveness', word_class: 'N' }
  ],
  viable: [
    { word: 'viable', word_class: 'AJ' },
    { word: 'viably', word_class: 'AV' },
    { word: 'viability', word_class: 'N' }
  ],
  unsuitability: [
    { word: 'unsuited', word_class: 'AJ' },
    { word: 'unsuitable', word_class: 'AJ' },
    { word: 'unsuitably', word_class: 'AV' },
    { word: 'unsuitability', word_class: 'N' },
    { word: 'unsuitableness', word_class: 'N' }
  ],
  amalgamated: [
    { word: 'amalgam', word_class: 'N' },
    { word: 'amalgamate', word_class: 'V' },
    { word: 'amalgamate', word_class: 'AJ' },
    { word: 'amalgamated', word_class: 'AJ' },
    { word: 'amalgamation', word_class: 'N' },
    { word: 'amalgamative', word_class: 'AJ' }
  ],
  junker: [
    { word: 'junk', word_class: 'N' },
    { word: 'junk', word_class: 'V' },
    { word: 'junker', word_class: 'N' },
    { word: 'junked', word_class: 'AJ' }
  ],
  retrospection: [
    { word: 'retrospect', word_class: 'N' },
    { word: 'retrospect', word_class: 'V' },
    { word: 'retrospection', word_class: 'N' },
    { word: 'retrospective', word_class: 'N' },
    { word: 'retrospective', word_class: 'AJ' },
    { word: 'retrospectively', word_class: 'AV' }
  ],
  revetment: [
    { word: 'revet', word_class: 'V' },
    { word: 'revetment', word_class: 'N' },
    { word: 'revetement', word_class: 'N' }
  ],
  sorceress: [
    { word: 'sorcery', word_class: 'N' },
    { word: 'sorcerer', word_class: 'N' },
    { word: 'sorceress', word_class: 'N' },
    { word: 'sorcerize', word_class: 'V' },
    { word: 'sorcerous', word_class: 'AJ' }
  ],
  rave: [
    { word: 'rave', word_class: 'N' },
    { word: 'rave', word_class: 'V' },
    { word: 'raver', word_class: 'N' },
    { word: 'raving', word_class: 'N' },
    { word: 'raving', word_class: 'AJ' },
    { word: 'raving', word_class: 'AV' }
  ],
  belted: [
    { word: 'belt', word_class: 'N' },
    { word: 'belt', word_class: 'V' },
    { word: 'belted', word_class: 'AJ' },
    { word: 'belting', word_class: 'N' }
  ],
  honorableness: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  ghostwrite: [
    { word: 'ghostwrite', word_class: 'V' },
    { word: 'ghostwriter', word_class: 'N' },
    { word: 'ghostwritten', word_class: 'AJ' }
  ],
  socialism: [
    { word: 'social', word_class: 'N' },
    { word: 'social', word_class: 'AJ' },
    { word: 'socialise', word_class: 'V' },
    { word: 'socialite', word_class: 'N' },
    { word: 'socialism', word_class: 'N' },
    { word: 'socialize', word_class: 'V' },
    { word: 'socialist', word_class: 'N' },
    { word: 'sociality', word_class: 'N' },
    { word: 'socially', word_class: 'AV' },
    { word: 'socialist', word_class: 'AJ' },
    { word: 'socialized', word_class: 'AJ' },
    { word: 'socializing', word_class: 'N' },
    { word: 'socialistic', word_class: 'AJ' },
    { word: 'socialisation', word_class: 'N' },
    { word: 'socialization', word_class: 'N' }
  ],
  embower: [
    { word: 'embow', word_class: 'V' },
    { word: 'embower', word_class: 'V' },
    { word: 'embowment', word_class: 'N' },
    { word: 'embowered', word_class: 'AJ' }
  ],
  interest: [
    { word: 'interest', word_class: 'N' },
    { word: 'interest', word_class: 'V' },
    { word: 'interested', word_class: 'AJ' },
    { word: 'interesting', word_class: 'AJ' }
  ],
  frolicking: [
    { word: 'frolic', word_class: 'N' },
    { word: 'frolic', word_class: 'V' },
    { word: 'frolicker', word_class: 'N' },
    { word: 'frolicky', word_class: 'AJ' },
    { word: 'frolicked', word_class: 'AJ' },
    { word: 'frolicking', word_class: 'AJ' },
    { word: 'frolicsome', word_class: 'AJ' },
    { word: 'frolicsomeness', word_class: 'N' }
  ],
  deposal: [
    { word: 'depose', word_class: 'N' },
    { word: 'depose', word_class: 'V' },
    { word: 'deposal', word_class: 'N' },
    { word: 'deposit', word_class: 'N' },
    { word: 'deposit', word_class: 'V' },
    { word: 'deposed', word_class: 'AJ' },
    { word: 'depositor', word_class: 'N' },
    { word: 'deposition', word_class: 'N' }
  ],
  scalding: [
    { word: 'scald', word_class: 'N' },
    { word: 'scald', word_class: 'V' },
    { word: 'scalding', word_class: 'AJ' }
  ],
  lessor: [
    { word: 'lease', word_class: 'N' },
    { word: 'lease', word_class: 'V' },
    { word: 'lessee', word_class: 'N' },
    { word: 'lessor', word_class: 'N' },
    { word: 'leased', word_class: 'AJ' }
  ],
  jesuit: [
    { word: 'jesuit', word_class: 'N' },
    { word: 'jesuit', word_class: 'AJ' },
    { word: 'jesuitic', word_class: 'AJ' },
    { word: 'jesuitism', word_class: 'N' },
    { word: 'jesuitical', word_class: 'AJ' }
  ],
  angriness: [
    { word: 'anger', word_class: 'N' },
    { word: 'anger', word_class: 'V' },
    { word: 'angry', word_class: 'AJ' },
    { word: 'angered', word_class: 'AJ' },
    { word: 'angriness', word_class: 'N' }
  ],
  constraint: [
    { word: 'constrain', word_class: 'V' },
    { word: 'constraint', word_class: 'N' },
    { word: 'constrained', word_class: 'AJ' },
    { word: 'constraining', word_class: 'AJ' }
  ],
  breed: [
    { word: 'breed', word_class: 'N' },
    { word: 'breed', word_class: 'V' },
    { word: 'breeder', word_class: 'N' },
    { word: 'breeding', word_class: 'N' },
    { word: 'breeding', word_class: 'AJ' }
  ],
  ant: [
    { word: 'ant', word_class: 'N' },
    { word: 'ant', word_class: 'AJ' },
    { word: 'ante', word_class: 'N' },
    { word: 'ante', word_class: 'V' },
    { word: 'ante', word_class: 'AJ' }
  ],
  remittence: [
    { word: 'remit', word_class: 'V' },
    { word: 'remiss', word_class: 'AJ' },
    { word: 'remittal', word_class: 'N' },
    { word: 'remission', word_class: 'N' },
    { word: 'remittance', word_class: 'N' },
    { word: 'remissness', word_class: 'N' },
    { word: 'remittence', word_class: 'N' },
    { word: 'remittent', word_class: 'AJ' }
  ],
  dazzling: [
    { word: 'dazzle', word_class: 'N' },
    { word: 'dazzle', word_class: 'V' },
    { word: 'dazzled', word_class: 'AJ' },
    { word: 'dazzling', word_class: 'AJ' }
  ],
  scoffer: [
    { word: 'scoff', word_class: 'N' },
    { word: 'scoff', word_class: 'V' },
    { word: 'scoffer', word_class: 'N' }
  ],
  unsnarled: [
    { word: 'unsnarl', word_class: 'V' },
    { word: 'unsnarled', word_class: 'AJ' },
    { word: 'unsnarling', word_class: 'N' }
  ],
  allude: [
    { word: 'allude', word_class: 'V' },
    { word: 'allusion', word_class: 'N' },
    { word: 'allusive', word_class: 'AJ' },
    { word: 'allusiveness', word_class: 'N' }
  ],
  decimate: [
    { word: 'decimal', word_class: 'N' },
    { word: 'decimal', word_class: 'AJ' },
    { word: 'decimate', word_class: 'N' },
    { word: 'decimate', word_class: 'V' },
    { word: 'decimalise', word_class: 'V' },
    { word: 'decimalize', word_class: 'V' },
    { word: 'decimation', word_class: 'N' },
    { word: 'decimalisation', word_class: 'N' },
    { word: 'decimalization', word_class: 'N' }
  ],
  threatening: [
    { word: 'threat', word_class: 'N' },
    { word: 'threaten', word_class: 'V' },
    { word: 'threatened', word_class: 'AJ' },
    { word: 'threatening', word_class: 'AJ' }
  ],
  degust: [
    { word: 'degust', word_class: 'V' },
    { word: 'degustate', word_class: 'V' },
    { word: 'degustation', word_class: 'N' }
  ],
  hardened: [
    { word: 'harden', word_class: 'V' },
    { word: 'hardened', word_class: 'AJ' },
    { word: 'hardening', word_class: 'N' }
  ],
  phosphorescence: [
    { word: 'phosphoresce', word_class: 'V' },
    { word: 'phosphorescence', word_class: 'N' },
    { word: 'phosphorescent', word_class: 'AJ' }
  ],
  spike: [
    { word: 'spike', word_class: 'N' },
    { word: 'spike', word_class: 'V' },
    { word: 'spiky', word_class: 'AJ' },
    { word: 'spiked', word_class: 'AJ' }
  ],
  raze: [
    { word: 'raze', word_class: 'V' },
    { word: 'razor', word_class: 'N' },
    { word: 'razor', word_class: 'V' },
    { word: 'razed', word_class: 'AJ' },
    { word: 'razing', word_class: 'N' }
  ],
  base: [
    { word: 'base', word_class: 'N' },
    { word: 'base', word_class: 'V' },
    { word: 'base', word_class: 'AJ' },
    { word: 'based', word_class: 'AJ' },
    { word: 'basely', word_class: 'AV' },
    { word: 'baseness', word_class: 'N' }
  ],
  ideograph: [
    { word: 'ideograph', word_class: 'N' },
    { word: 'ideographic', word_class: 'AJ' },
    { word: 'ideographically', word_class: 'AV' }
  ],
  habitability: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  unaware: [
    { word: 'unaware', word_class: 'AJ' },
    { word: 'unawares', word_class: 'AV' },
    { word: 'unawareness', word_class: 'N' }
  ],
  emptiness: [
    { word: 'empty', word_class: 'N' },
    { word: 'empty', word_class: 'V' },
    { word: 'empty', word_class: 'AJ' },
    { word: 'emptied', word_class: 'AJ' },
    { word: 'emptying', word_class: 'N' },
    { word: 'emptiness', word_class: 'N' }
  ],
  colloquially: [
    { word: 'colloquy', word_class: 'N' },
    { word: 'colloquial', word_class: 'AJ' },
    { word: 'colloquialism', word_class: 'N' },
    { word: 'colloquially', word_class: 'AV' }
  ],
  isomerisation: [
    { word: 'isomeric', word_class: 'AJ' },
    { word: 'isomerise', word_class: 'V' },
    { word: 'isomerism', word_class: 'N' },
    { word: 'isomerize', word_class: 'V' },
    { word: 'isomerisation', word_class: 'N' },
    { word: 'isomerization', word_class: 'N' }
  ],
  sprinkle: [
    { word: 'sprinkle', word_class: 'N' },
    { word: 'sprinkle', word_class: 'V' },
    { word: 'sprinkler', word_class: 'N' },
    { word: 'sprinkles', word_class: 'N' },
    { word: 'sprinkled', word_class: 'AJ' },
    { word: 'sprinkling', word_class: 'N' }
  ],
  casual: [
    { word: 'casual', word_class: 'AJ' },
    { word: 'casually', word_class: 'AV' },
    { word: 'casualness', word_class: 'N' }
  ],
  tenant: [
    { word: 'tenant', word_class: 'N' },
    { word: 'tenant', word_class: 'V' },
    { word: 'tenancy', word_class: 'N' },
    { word: 'tenanted', word_class: 'AJ' }
  ],
  chair: [
    { word: 'chair', word_class: 'N' },
    { word: 'chair', word_class: 'V' },
    { word: 'chairman', word_class: 'N' },
    { word: 'chairman', word_class: 'V' }
  ],
  spontaneous: [
    { word: 'spontaneity', word_class: 'N' },
    { word: 'spontaneous', word_class: 'AJ' },
    { word: 'spontaneously', word_class: 'AV' },
    { word: 'spontaneousness', word_class: 'N' }
  ],
  alienism: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  anagoge: [
    { word: 'anagoge', word_class: 'N' },
    { word: 'anagogic', word_class: 'AJ' },
    { word: 'anagogical', word_class: 'AJ' }
  ],
  genital: [
    { word: 'genital', word_class: 'N' },
    { word: 'genital', word_class: 'AJ' },
    { word: 'genitals', word_class: 'N' },
    { word: 'genitive', word_class: 'N' },
    { word: 'genitive', word_class: 'AJ' }
  ],
  popularize: [
    { word: 'popular', word_class: 'AJ' },
    { word: 'popularise', word_class: 'V' },
    { word: 'popularize', word_class: 'V' },
    { word: 'popularism', word_class: 'N' },
    { word: 'popularity', word_class: 'N' },
    { word: 'popularisation', word_class: 'N' },
    { word: 'popularization', word_class: 'N' }
  ],
  causing: [
    { word: 'cause', word_class: 'N' },
    { word: 'cause', word_class: 'V' },
    { word: 'causing', word_class: 'N' },
    { word: 'causation', word_class: 'N' },
    { word: 'causative', word_class: 'AJ' }
  ],
  effectiveness: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  stripped: [
    { word: 'strip', word_class: 'N' },
    { word: 'strip', word_class: 'V' },
    { word: 'strip', word_class: 'AJ' },
    { word: 'striper', word_class: 'N' },
    { word: 'stripped', word_class: 'AJ' },
    { word: 'stripping', word_class: 'N' }
  ],
  presumable: [
    { word: 'presume', word_class: 'V' },
    { word: 'presumed', word_class: 'AJ' },
    { word: 'presumption', word_class: 'N' },
    { word: 'presumable', word_class: 'AJ' },
    { word: 'presumably', word_class: 'AV' },
    { word: 'presumptive', word_class: 'AJ' },
    { word: 'presumptuous', word_class: 'AJ' },
    { word: 'presumptively', word_class: 'AV' },
    { word: 'presumptuously', word_class: 'AV' },
    { word: 'presumptuousness', word_class: 'N' }
  ],
  gaudy: [
    { word: 'gaudy', word_class: 'N' },
    { word: 'gaudy', word_class: 'AJ' },
    { word: 'gaudiness', word_class: 'N' }
  ],
  anunciation: [
    { word: 'announce', word_class: 'V' },
    { word: 'announcer', word_class: 'N' },
    { word: 'announced', word_class: 'AJ' },
    { word: 'anunciation', word_class: 'N' },
    { word: 'announcement', word_class: 'N' }
  ],
  composer: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  retinal: [
    { word: 'retina', word_class: 'N' },
    { word: 'retinal', word_class: 'N' },
    { word: 'retinal', word_class: 'AJ' },
    { word: 'retinitis', word_class: 'N' }
  ],
  shaking: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  'self-assertiveness': [
    { word: 'self-assertion', word_class: 'N' },
    { word: 'self-asserting', word_class: 'AJ' },
    { word: 'self-assertive', word_class: 'AJ' },
    { word: 'self-assertiveness', word_class: 'N' }
  ],
  slicing: [
    { word: 'slice', word_class: 'N' },
    { word: 'slice', word_class: 'V' },
    { word: 'slicer', word_class: 'N' },
    { word: 'sliced', word_class: 'AJ' },
    { word: 'slicing', word_class: 'N' }
  ],
  islamize: [
    { word: 'islam', word_class: 'N' },
    { word: 'islamise', word_class: 'V' },
    { word: 'islamic', word_class: 'AJ' },
    { word: 'islamize', word_class: 'V' },
    { word: 'islamism', word_class: 'N' },
    { word: 'islamisation', word_class: 'N' },
    { word: 'islamization', word_class: 'N' }
  ],
  leaching: [
    { word: 'leach', word_class: 'N' },
    { word: 'leach', word_class: 'V' },
    { word: 'leaching', word_class: 'N' }
  ],
  opposing: [
    { word: 'oppose', word_class: 'V' },
    { word: 'opposed', word_class: 'AJ' },
    { word: 'opposite', word_class: 'N' },
    { word: 'opposing', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AJ' },
    { word: 'opposite', word_class: 'AV' },
    { word: 'opposition', word_class: 'N' },
    { word: 'opposable', word_class: 'AJ' },
    { word: 'oppositely', word_class: 'AV' },
    { word: 'oppositive', word_class: 'AJ' },
    { word: 'oppositeness', word_class: 'N' }
  ],
  assailment: [
    { word: 'assail', word_class: 'V' },
    { word: 'assailant', word_class: 'N' },
    { word: 'assailment', word_class: 'N' },
    { word: 'assailable', word_class: 'AJ' },
    { word: 'assailability', word_class: 'N' }
  ],
  romantic: [
    { word: 'romance', word_class: 'N' },
    { word: 'romance', word_class: 'V' },
    { word: 'romance', word_class: 'AJ' },
    { word: 'romantic', word_class: 'N' },
    { word: 'romantic', word_class: 'AJ' },
    { word: 'romantically', word_class: 'AV' }
  ],
  privileged: [
    { word: 'privilege', word_class: 'N' },
    { word: 'privilege', word_class: 'V' },
    { word: 'privileged', word_class: 'AJ' }
  ],
  toppling: [
    { word: 'topple', word_class: 'N' },
    { word: 'topple', word_class: 'V' },
    { word: 'toppling', word_class: 'AJ' }
  ],
  strife: [
    { word: 'strife', word_class: 'N' },
    { word: 'strive', word_class: 'V' },
    { word: 'striver', word_class: 'N' },
    { word: 'striven', word_class: 'AJ' },
    { word: 'striving', word_class: 'N' }
  ],
  ascendant: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  floodlight: [
    { word: 'floodlight', word_class: 'N' },
    { word: 'floodlight', word_class: 'V' },
    { word: 'floodlighted', word_class: 'AJ' }
  ],
  forestalling: [
    { word: 'forestall', word_class: 'V' },
    { word: 'forestalling', word_class: 'N' },
    { word: 'forestalment', word_class: 'N' },
    { word: 'forestallment', word_class: 'N' }
  ],
  roentgenization: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  repetition: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  regards: [
    { word: 'regard', word_class: 'N' },
    { word: 'regard', word_class: 'V' },
    { word: 'regards', word_class: 'N' },
    { word: 'regardant', word_class: 'AJ' },
    { word: 'regardful', word_class: 'AJ' },
    { word: 'regarding', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AJ' },
    { word: 'regardless', word_class: 'AV' }
  ],
  photosensitive: [
    { word: 'photosensitise', word_class: 'V' },
    { word: 'photosensitize', word_class: 'V' },
    { word: 'photosensitive', word_class: 'AJ' },
    { word: 'photosensitivity', word_class: 'N' },
    { word: 'photosensitisation', word_class: 'N' },
    { word: 'photosensitization', word_class: 'N' }
  ],
  trouble: [
    { word: 'trouble', word_class: 'N' },
    { word: 'trouble', word_class: 'V' },
    { word: 'troubled', word_class: 'AJ' },
    { word: 'troubling', word_class: 'N' },
    { word: 'troubling', word_class: 'AJ' },
    { word: 'troublesome', word_class: 'AJ' },
    { word: 'troublesomeness', word_class: 'N' }
  ],
  feebleness: [
    { word: 'feeble', word_class: 'AJ' },
    { word: 'feebly', word_class: 'AV' },
    { word: 'feebleness', word_class: 'N' }
  ],
  sacrificeable: [
    { word: 'sacrifice', word_class: 'N' },
    { word: 'sacrifice', word_class: 'V' },
    { word: 'sacrificeable', word_class: 'AJ' }
  ],
  odorous: [
    { word: 'odor', word_class: 'N' },
    { word: 'odorize', word_class: 'V' },
    { word: 'odorous', word_class: 'AJ' },
    { word: 'odoriferous', word_class: 'AJ' }
  ],
  pencilled: [
    { word: 'pencil', word_class: 'N' },
    { word: 'pencil', word_class: 'V' },
    { word: 'penciled', word_class: 'AJ' },
    { word: 'pencilled', word_class: 'AJ' }
  ],
  discriminating: [
    { word: 'discriminant', word_class: 'N' },
    { word: 'discriminate', word_class: 'V' },
    { word: 'discriminator', word_class: 'N' },
    { word: 'discriminate', word_class: 'AJ' },
    { word: 'discriminable', word_class: 'AJ' },
    { word: 'discrimination', word_class: 'N' },
    { word: 'discriminately', word_class: 'AV' },
    { word: 'discriminating', word_class: 'AJ' },
    { word: 'discriminative', word_class: 'AJ' }
  ],
  unreliableness: [
    { word: 'unreliable', word_class: 'AJ' },
    { word: 'unreliably', word_class: 'AV' },
    { word: 'unreliability', word_class: 'N' },
    { word: 'unreliableness', word_class: 'N' }
  ],
  monarchy: [
    { word: 'monarchy', word_class: 'N' },
    { word: 'monarchy', word_class: 'AJ' },
    { word: 'monarchial', word_class: 'AJ' }
  ],
  whimpering: [
    { word: 'whimper', word_class: 'N' },
    { word: 'whimper', word_class: 'V' },
    { word: 'whimpering', word_class: 'N' }
  ],
  nurtural: [
    { word: 'nurture', word_class: 'N' },
    { word: 'nurture', word_class: 'V' },
    { word: 'nurtural', word_class: 'AJ' },
    { word: 'nurturing', word_class: 'N' }
  ],
  sulfuric: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  brakes: [
    { word: 'brake', word_class: 'N' },
    { word: 'brake', word_class: 'V' },
    { word: 'brakes', word_class: 'N' },
    { word: 'brakeman', word_class: 'N' }
  ],
  developed: [
    { word: 'develop', word_class: 'V' },
    { word: 'developer', word_class: 'N' },
    { word: 'developed', word_class: 'AJ' },
    { word: 'developing', word_class: 'N' },
    { word: 'developing', word_class: 'AJ' },
    { word: 'development', word_class: 'N' }
  ],
  audacious: [
    { word: 'audacious', word_class: 'AJ' },
    { word: 'audaciously', word_class: 'AV' },
    { word: 'audaciousness', word_class: 'N' }
  ],
  anodization: [
    { word: 'anode', word_class: 'N' },
    { word: 'anodal', word_class: 'AJ' },
    { word: 'anodic', word_class: 'AJ' },
    { word: 'anodize', word_class: 'V' },
    { word: 'anodization', word_class: 'N' }
  ],
  extol: [
    { word: 'extol', word_class: 'V' },
    { word: 'extoll', word_class: 'V' },
    { word: 'extoller', word_class: 'N' },
    { word: 'extolment', word_class: 'N' }
  ],
  amass: [
    { word: 'amass', word_class: 'V' },
    { word: 'amassed', word_class: 'AJ' },
    { word: 'amassment', word_class: 'N' }
  ],
  wiggling: [
    { word: 'wiggle', word_class: 'N' },
    { word: 'wiggle', word_class: 'V' },
    { word: 'wiggler', word_class: 'N' },
    { word: 'wiggly', word_class: 'AJ' },
    { word: 'wiggling', word_class: 'AJ' },
    { word: 'wiggliness', word_class: 'N' }
  ],
  testifier: [
    { word: 'testify', word_class: 'V' },
    { word: 'testifier', word_class: 'N' },
    { word: 'testification', word_class: 'N' }
  ],
  suppositional: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  marshaling: [
    { word: 'marshal', word_class: 'N' },
    { word: 'marshal', word_class: 'V' },
    { word: 'marshal', word_class: 'AJ' },
    { word: 'marshall', word_class: 'N' },
    { word: 'marshaling', word_class: 'N' },
    { word: 'marshaling', word_class: 'V' },
    { word: 'marshalship', word_class: 'N' }
  ],
  draper: [
    { word: 'drape', word_class: 'N' },
    { word: 'drape', word_class: 'V' },
    { word: 'draper', word_class: 'N' },
    { word: 'draped', word_class: 'AJ' },
    { word: 'drapery', word_class: 'N' },
    { word: 'drapery', word_class: 'AJ' }
  ],
  waft: [
    { word: 'waft', word_class: 'N' },
    { word: 'waft', word_class: 'V' },
    { word: 'waftage', word_class: 'N' },
    { word: 'wafture', word_class: 'N' }
  ],
  incorruptibility: [
    { word: 'incorrupt', word_class: 'AJ' },
    { word: 'incorruption', word_class: 'N' },
    { word: 'incorruptness', word_class: 'N' },
    { word: 'incorruptible', word_class: 'AJ' },
    { word: 'incorruptibility', word_class: 'N' }
  ],
  circularisation: [
    { word: 'circle', word_class: 'N' },
    { word: 'circle', word_class: 'V' },
    { word: 'circular', word_class: 'N' },
    { word: 'circling', word_class: 'AJ' },
    { word: 'circular', word_class: 'AJ' },
    { word: 'circularize', word_class: 'V' },
    { word: 'circularise', word_class: 'V' },
    { word: 'circularity', word_class: 'N' },
    { word: 'circularisation', word_class: 'N' },
    { word: 'circularization', word_class: 'N' }
  ],
  dribble: [
    { word: 'dribble', word_class: 'N' },
    { word: 'dribble', word_class: 'V' },
    { word: 'dribbler', word_class: 'N' },
    { word: 'dribbling', word_class: 'N' }
  ],
  staggering: [
    { word: 'stagger', word_class: 'N' },
    { word: 'stagger', word_class: 'V' },
    { word: 'staggerer', word_class: 'N' },
    { word: 'staggering', word_class: 'AJ' }
  ],
  immaturely: [
    { word: 'immature', word_class: 'AJ' },
    { word: 'immaturity', word_class: 'N' },
    { word: 'immaturely', word_class: 'AV' },
    { word: 'immatureness', word_class: 'N' }
  ],
  wish: [
    { word: 'wish', word_class: 'N' },
    { word: 'wish', word_class: 'V' },
    { word: 'wishing', word_class: 'N' },
    { word: 'wishful', word_class: 'AJ' },
    { word: 'wishfulness', word_class: 'N' }
  ],
  reproducibly: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  italianization: [
    { word: 'italian', word_class: 'N' },
    { word: 'italian', word_class: 'AJ' },
    { word: 'italianise', word_class: 'V' },
    { word: 'italianize', word_class: 'V' },
    { word: 'italianisation', word_class: 'N' },
    { word: 'italianization', word_class: 'N' }
  ],
  occlude: [
    { word: 'occlude', word_class: 'V' },
    { word: 'occluded', word_class: 'AJ' },
    { word: 'occlusion', word_class: 'N' },
    { word: 'occlusive', word_class: 'N' },
    { word: 'occlusive', word_class: 'AJ' }
  ],
  dissoluteness: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  rip: [
    { word: 'rip', word_class: 'N' },
    { word: 'rip', word_class: 'V' },
    { word: 'ripped', word_class: 'AJ' },
    { word: 'ripping', word_class: 'AJ' }
  ],
  variegate: [
    { word: 'variegate', word_class: 'V' },
    { word: 'variegated', word_class: 'AJ' },
    { word: 'variegation', word_class: 'N' }
  ],
  attainment: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  temperately: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  potentiation: [
    { word: 'potential', word_class: 'N' },
    { word: 'potential', word_class: 'AJ' },
    { word: 'potentiate', word_class: 'V' },
    { word: 'potentiality', word_class: 'N' },
    { word: 'potentially', word_class: 'AV' },
    { word: 'potentiation', word_class: 'N' }
  ],
  orbital: [
    { word: 'orbit', word_class: 'N' },
    { word: 'orbit', word_class: 'V' },
    { word: 'orbital', word_class: 'AJ' }
  ],
  desert: [
    { word: 'desert', word_class: 'N' },
    { word: 'desert', word_class: 'V' },
    { word: 'desert', word_class: 'AJ' },
    { word: 'deserter', word_class: 'N' },
    { word: 'deserted', word_class: 'AJ' },
    { word: 'desertion', word_class: 'N' }
  ],
  deification: [
    { word: 'deify', word_class: 'V' },
    { word: 'deific', word_class: 'AJ' },
    { word: 'deification', word_class: 'N' }
  ],
  superficially: [
    { word: 'superficies', word_class: 'N' },
    { word: 'superficial', word_class: 'AJ' },
    { word: 'superficiality', word_class: 'N' },
    { word: 'superficially', word_class: 'AV' }
  ],
  actinism: [
    { word: 'actin', word_class: 'N' },
    { word: 'actinal', word_class: 'AJ' },
    { word: 'actinic', word_class: 'AJ' },
    { word: 'actinism', word_class: 'N' }
  ],
  sublimate: [
    { word: 'sublime', word_class: 'N' },
    { word: 'sublime', word_class: 'V' },
    { word: 'sublime', word_class: 'AJ' },
    { word: 'sublimate', word_class: 'N' },
    { word: 'sublimate', word_class: 'V' },
    { word: 'sublimed', word_class: 'AJ' },
    { word: 'sublimity', word_class: 'N' },
    { word: 'sublimate', word_class: 'AJ' },
    { word: 'sublimely', word_class: 'AV' },
    { word: 'sublimated', word_class: 'AJ' },
    { word: 'sublimation', word_class: 'N' },
    { word: 'subliminal', word_class: 'AJ' },
    { word: 'sublimination', word_class: 'N' }
  ],
  sequestered: [
    { word: 'sequester', word_class: 'V' },
    { word: 'sequestrate', word_class: 'V' },
    { word: 'sequestered', word_class: 'AJ' },
    { word: 'sequestration', word_class: 'N' }
  ],
  rosy: [
    { word: 'rose', word_class: 'N' },
    { word: 'rosy', word_class: 'AJ' },
    { word: 'rose', word_class: 'AJ' },
    { word: 'rosiness', word_class: 'N' }
  ],
  correcting: [
    { word: 'correct', word_class: 'V' },
    { word: 'correct', word_class: 'AJ' },
    { word: 'corrected', word_class: 'AJ' },
    { word: 'correcting', word_class: 'N' },
    { word: 'correction', word_class: 'N' },
    { word: 'corrective', word_class: 'N' },
    { word: 'corrective', word_class: 'AJ' },
    { word: 'correctness', word_class: 'N' },
    { word: 'correctable', word_class: 'AJ' },
    { word: 'correctitude', word_class: 'N' },
    { word: 'correctional', word_class: 'AJ' }
  ],
  magnetise: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  ridge: [{ word: 'ridge', word_class: 'N' }, { word: 'ridge', word_class: 'V' }, { word: 'ridged', word_class: 'AJ' }],
  scurrility: [
    { word: 'scurrility', word_class: 'N' },
    { word: 'scurrilous', word_class: 'AJ' },
    { word: 'scurrilously', word_class: 'AV' }
  ],
  alienated: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  exerciser: [
    { word: 'exercise', word_class: 'N' },
    { word: 'exercise', word_class: 'V' },
    { word: 'exerciser', word_class: 'N' },
    { word: 'exercising', word_class: 'N' },
    { word: 'exercitation', word_class: 'N' }
  ],
  provoker: [
    { word: 'provoke', word_class: 'V' },
    { word: 'provoker', word_class: 'N' },
    { word: 'provoked', word_class: 'AJ' },
    { word: 'provoking', word_class: 'AJ' },
    { word: 'provocation', word_class: 'N' },
    { word: 'provocative', word_class: 'N' },
    { word: 'provocative', word_class: 'AJ' },
    { word: 'provocatively', word_class: 'AV' }
  ],
  headship: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  ungraciousness: [
    { word: 'ungracious', word_class: 'AJ' },
    { word: 'ungraciously', word_class: 'AV' },
    { word: 'ungraciousness', word_class: 'N' }
  ],
  deraignment: [
    { word: 'deraign', word_class: 'V' },
    { word: 'darraign', word_class: 'V' },
    { word: 'deraignment', word_class: 'N' },
    { word: 'darraignment', word_class: 'N' }
  ],
  oversee: [
    { word: 'oversee', word_class: 'V' },
    { word: 'overseer', word_class: 'N' },
    { word: 'oversize', word_class: 'AJ' },
    { word: 'oversized', word_class: 'AJ' }
  ],
  channel: [
    { word: 'channel', word_class: 'N' },
    { word: 'channel', word_class: 'V' },
    { word: 'channels', word_class: 'N' },
    { word: 'channelize', word_class: 'V' },
    { word: 'channelization', word_class: 'N' }
  ],
  fitness: [
    { word: 'fit', word_class: 'N' },
    { word: 'fit', word_class: 'V' },
    { word: 'fit', word_class: 'AJ' },
    { word: 'fitment', word_class: 'N' },
    { word: 'fitness', word_class: 'N' },
    { word: 'fitful', word_class: 'AJ' },
    { word: 'fitting', word_class: 'N' },
    { word: 'fitting', word_class: 'AJ' },
    { word: 'fitfulness', word_class: 'N' }
  ],
  disparage: [
    { word: 'disparage', word_class: 'N' },
    { word: 'disparage', word_class: 'V' },
    { word: 'disparager', word_class: 'N' },
    { word: 'disparaging', word_class: 'AJ' },
    { word: 'disparagement', word_class: 'N' }
  ],
  steel: [
    { word: 'steel', word_class: 'N' },
    { word: 'steel', word_class: 'V' },
    { word: 'steelman', word_class: 'N' }
  ],
  loathsomeness: [
    { word: 'loath', word_class: 'V' },
    { word: 'loath', word_class: 'AJ' },
    { word: 'loathe', word_class: 'V' },
    { word: 'loathing', word_class: 'N' },
    { word: 'loathsome', word_class: 'AJ' },
    { word: 'loathsomeness', word_class: 'N' }
  ],
  observing: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  label: [
    { word: 'label', word_class: 'N' },
    { word: 'label', word_class: 'V' },
    { word: 'labeled', word_class: 'AJ' },
    { word: 'labelled', word_class: 'AJ' }
  ],
  reflectively: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  get: [{ word: 'get', word_class: 'V' }, { word: 'getting', word_class: 'N' }, { word: 'gotten', word_class: 'AJ' }],
  vigor: [
    { word: 'vigor', word_class: 'N' },
    { word: 'vigorous', word_class: 'AJ' },
    { word: 'vigorously', word_class: 'AV' }
  ],
  obsessively: [
    { word: 'obsess', word_class: 'V' },
    { word: 'obsession', word_class: 'N' },
    { word: 'obsessed', word_class: 'AJ' },
    { word: 'obsessive', word_class: 'AJ' },
    { word: 'obsessivity', word_class: 'N' },
    { word: 'obsessively', word_class: 'AV' },
    { word: 'obsessiveness', word_class: 'N' }
  ],
  conventionalism: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  psychologically: [
    { word: 'psychology', word_class: 'N' },
    { word: 'psychologize', word_class: 'V' },
    { word: 'psychological', word_class: 'AJ' },
    { word: 'psychologically', word_class: 'AV' },
    { word: 'psychologization', word_class: 'N' }
  ],
  matriarch: [
    { word: 'matriarch', word_class: 'N' },
    { word: 'matriarchal', word_class: 'AJ' },
    { word: 'matriarchate', word_class: 'N' },
    { word: 'matriarchic', word_class: 'AJ' }
  ],
  ghostwriter: [
    { word: 'ghostwrite', word_class: 'V' },
    { word: 'ghostwriter', word_class: 'N' },
    { word: 'ghostwritten', word_class: 'AJ' }
  ],
  ploughed: [
    { word: 'plough', word_class: 'N' },
    { word: 'plough', word_class: 'V' },
    { word: 'ploughed', word_class: 'AJ' },
    { word: 'ploughing', word_class: 'N' },
    { word: 'ploughman', word_class: 'N' }
  ],
  hydrolyse: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  demolish: [
    { word: 'demolish', word_class: 'V' },
    { word: 'demolition', word_class: 'N' },
    { word: 'demolished', word_class: 'AJ' },
    { word: 'demolishing', word_class: 'N' },
    { word: 'demolishment', word_class: 'N' }
  ],
  hives: [{ word: 'hive', word_class: 'N' }, { word: 'hive', word_class: 'V' }, { word: 'hives', word_class: 'N' }],
  generality: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  invalidism: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  systemisation: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  advisor: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  crew: [{ word: 'crew', word_class: 'N' }, { word: 'crew', word_class: 'V' }, { word: 'crewman', word_class: 'N' }],
  diction: [{ word: 'dice', word_class: 'N' }, { word: 'dice', word_class: 'V' }, { word: 'diction', word_class: 'N' }],
  substantivization: [
    { word: 'substantival', word_class: 'AJ' },
    { word: 'substantivise', word_class: 'V' },
    { word: 'substantivize', word_class: 'V' },
    { word: 'substantivisation', word_class: 'N' },
    { word: 'substantivization', word_class: 'N' }
  ],
  masturbation: [
    { word: 'masturbate', word_class: 'V' },
    { word: 'masturbator', word_class: 'N' },
    { word: 'masturbation', word_class: 'N' }
  ],
  reciprocating: [
    { word: 'reciprocal', word_class: 'N' },
    { word: 'reciprocal', word_class: 'AJ' },
    { word: 'reciprocate', word_class: 'V' },
    { word: 'reciprocity', word_class: 'N' },
    { word: 'reciprocality', word_class: 'N' },
    { word: 'reciprocally', word_class: 'AV' },
    { word: 'reciprocation', word_class: 'N' },
    { word: 'reciprocating', word_class: 'AJ' },
    { word: 'reciprocative', word_class: 'AJ' }
  ],
  injudicious: [
    { word: 'injudicious', word_class: 'AJ' },
    { word: 'injudiciously', word_class: 'AV' },
    { word: 'injudiciousness', word_class: 'N' }
  ],
  laudation: [
    { word: 'laud', word_class: 'V' },
    { word: 'lauder', word_class: 'N' },
    { word: 'laudator', word_class: 'N' },
    { word: 'laudation', word_class: 'N' },
    { word: 'laudatory', word_class: 'AJ' }
  ],
  rejuvenate: [
    { word: 'rejuvenate', word_class: 'V' },
    { word: 'rejuvenesce', word_class: 'V' },
    { word: 'rejuvenation', word_class: 'N' }
  ],
  patience: [
    { word: 'patient', word_class: 'N' },
    { word: 'patience', word_class: 'N' },
    { word: 'patient', word_class: 'AJ' },
    { word: 'patiently', word_class: 'AV' }
  ],
  deceptive: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  curdling: [
    { word: 'curdle', word_class: 'V' },
    { word: 'curdled', word_class: 'AJ' },
    { word: 'curdling', word_class: 'N' },
    { word: 'curdling', word_class: 'AJ' }
  ],
  snorer: [
    { word: 'snore', word_class: 'N' },
    { word: 'snore', word_class: 'V' },
    { word: 'snorer', word_class: 'N' },
    { word: 'snoring', word_class: 'N' },
    { word: 'snoring', word_class: 'AJ' }
  ],
  logging: [
    { word: 'log', word_class: 'N' },
    { word: 'log', word_class: 'V' },
    { word: 'log', word_class: 'AJ' },
    { word: 'logging', word_class: 'N' }
  ],
  fishery: [
    { word: 'fish', word_class: 'N' },
    { word: 'fish', word_class: 'V' },
    { word: 'fisher', word_class: 'N' },
    { word: 'fishery', word_class: 'N' },
    { word: 'fishing', word_class: 'N' },
    { word: 'fishery', word_class: 'AJ' }
  ],
  twinned: [
    { word: 'twin', word_class: 'N' },
    { word: 'twin', word_class: 'V' },
    { word: 'twin', word_class: 'AJ' },
    { word: 'twinned', word_class: 'AJ' },
    { word: 'twinning', word_class: 'AJ' }
  ],
  alarm: [
    { word: 'alarm', word_class: 'N' },
    { word: 'alarm', word_class: 'V' },
    { word: 'alarmism', word_class: 'N' },
    { word: 'alarmist', word_class: 'N' },
    { word: 'alarmed', word_class: 'AJ' },
    { word: 'alarming', word_class: 'AJ' }
  ],
  attended: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  pedant: [
    { word: 'pedant', word_class: 'N' },
    { word: 'pedantry', word_class: 'N' },
    { word: 'pedantic', word_class: 'AJ' },
    { word: 'pedantically', word_class: 'AV' }
  ],
  economisation: [
    { word: 'economise', word_class: 'V' },
    { word: 'economist', word_class: 'N' },
    { word: 'economize', word_class: 'V' },
    { word: 'economic', word_class: 'AJ' },
    { word: 'economics', word_class: 'N' },
    { word: 'economiser', word_class: 'N' },
    { word: 'economizer', word_class: 'N' },
    { word: 'economical', word_class: 'AJ' },
    { word: 'economically', word_class: 'AV' },
    { word: 'economisation', word_class: 'N' },
    { word: 'economization', word_class: 'N' }
  ],
  pneumatics: [
    { word: 'pneumatic', word_class: 'AJ' },
    { word: 'pneumatics', word_class: 'N' },
    { word: 'pneumatically', word_class: 'AV' }
  ],
  drop: [
    { word: 'drop', word_class: 'N' },
    { word: 'drop', word_class: 'V' },
    { word: 'dropped', word_class: 'AJ' },
    { word: 'dropping', word_class: 'N' },
    { word: 'dropping', word_class: 'AJ' },
    { word: 'droppings', word_class: 'N' }
  ],
  third: [{ word: 'third', word_class: 'N' }, { word: 'third', word_class: 'AJ' }, { word: 'third', word_class: 'AV' }],
  devouring: [
    { word: 'devour', word_class: 'V' },
    { word: 'devourer', word_class: 'N' },
    { word: 'devoured', word_class: 'AJ' },
    { word: 'devouring', word_class: 'AJ' }
  ],
  detachment: [
    { word: 'detach', word_class: 'V' },
    { word: 'detached', word_class: 'AJ' },
    { word: 'detachment', word_class: 'N' },
    { word: 'detachable', word_class: 'AJ' }
  ],
  growing: [
    { word: 'grow', word_class: 'V' },
    { word: 'grower', word_class: 'N' },
    { word: 'grown', word_class: 'AJ' },
    { word: 'growth', word_class: 'N' },
    { word: 'growing', word_class: 'N' },
    { word: 'growing', word_class: 'AJ' }
  ],
  scratched: [
    { word: 'scratch', word_class: 'N' },
    { word: 'scratch', word_class: 'V' },
    { word: 'scratched', word_class: 'AJ' },
    { word: 'scratching', word_class: 'N' },
    { word: 'scratching', word_class: 'AJ' }
  ],
  glimmering: [
    { word: 'glimmer', word_class: 'N' },
    { word: 'glimmer', word_class: 'V' },
    { word: 'glimmering', word_class: 'N' },
    { word: 'glimmering', word_class: 'AJ' }
  ],
  arresting: [
    { word: 'arrest', word_class: 'N' },
    { word: 'arrest', word_class: 'V' },
    { word: 'arrester', word_class: 'N' },
    { word: 'arrested', word_class: 'AJ' },
    { word: 'arresting', word_class: 'AJ' }
  ],
  safeness: [
    { word: 'safe', word_class: 'N' },
    { word: 'safe', word_class: 'AJ' },
    { word: 'safety', word_class: 'N' },
    { word: 'safely', word_class: 'AV' },
    { word: 'safeness', word_class: 'N' }
  ],
  pentecostalism: [
    { word: 'pentecost', word_class: 'N' },
    { word: 'pentecostal', word_class: 'N' },
    { word: 'pentecostal', word_class: 'AJ' },
    { word: 'pentecostalism', word_class: 'N' }
  ],
  typewriter: [
    { word: 'typewrite', word_class: 'V' },
    { word: 'typewriter', word_class: 'N' },
    { word: 'typewriting', word_class: 'N' },
    { word: 'typewritten', word_class: 'AJ' }
  ],
  arrogance: [
    { word: 'arrogate', word_class: 'V' },
    { word: 'arrogance', word_class: 'N' },
    { word: 'arrogant', word_class: 'AJ' },
    { word: 'arrogated', word_class: 'AJ' },
    { word: 'arrogation', word_class: 'N' }
  ],
  pack: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  northeast: [
    { word: 'northeast', word_class: 'N' },
    { word: 'northeast', word_class: 'AJ' },
    { word: 'northeast', word_class: 'AV' },
    { word: 'northeaster', word_class: 'N' }
  ],
  elemental: [
    { word: 'element', word_class: 'N' },
    { word: 'elements', word_class: 'N' },
    { word: 'elemental', word_class: 'AJ' },
    { word: 'elementary', word_class: 'AJ' }
  ],
  sparkling: [
    { word: 'sparkle', word_class: 'N' },
    { word: 'sparkle', word_class: 'V' },
    { word: 'sparkler', word_class: 'N' },
    { word: 'sparkling', word_class: 'N' },
    { word: 'sparkling', word_class: 'AJ' }
  ],
  fluorescent: [
    { word: 'fluoresce', word_class: 'V' },
    { word: 'fluorescence', word_class: 'N' },
    { word: 'fluorescent', word_class: 'AJ' }
  ],
  few: [{ word: 'few', word_class: 'N' }, { word: 'few', word_class: 'AJ' }, { word: 'fewness', word_class: 'N' }],
  pierce: [
    { word: 'pierce', word_class: 'N' },
    { word: 'pierce', word_class: 'V' },
    { word: 'pierced', word_class: 'AJ' },
    { word: 'piercing', word_class: 'AJ' }
  ],
  knock: [
    { word: 'knock', word_class: 'N' },
    { word: 'knock', word_class: 'V' },
    { word: 'knocker', word_class: 'N' },
    { word: 'knocking', word_class: 'N' }
  ],
  addressable: [
    { word: 'address', word_class: 'N' },
    { word: 'address', word_class: 'V' },
    { word: 'addressee', word_class: 'N' },
    { word: 'addressed', word_class: 'AJ' },
    { word: 'addressable', word_class: 'AJ' }
  ],
  classification: [
    { word: 'classify', word_class: 'V' },
    { word: 'classifier', word_class: 'N' },
    { word: 'classified', word_class: 'AJ' },
    { word: 'classifiable', word_class: 'AJ' },
    { word: 'classification', word_class: 'N' }
  ],
  stirring: [
    { word: 'stir', word_class: 'N' },
    { word: 'stir', word_class: 'V' },
    { word: 'stirred', word_class: 'AJ' },
    { word: 'stirring', word_class: 'N' },
    { word: 'stirring', word_class: 'AJ' }
  ],
  stunted: [
    { word: 'stunt', word_class: 'N' },
    { word: 'stunt', word_class: 'V' },
    { word: 'stunted', word_class: 'AJ' },
    { word: 'stunting', word_class: 'N' }
  ],
  leaning: [
    { word: 'lean', word_class: 'N' },
    { word: 'lean', word_class: 'V' },
    { word: 'lean', word_class: 'AJ' },
    { word: 'leaning', word_class: 'N' },
    { word: 'leaning', word_class: 'AJ' },
    { word: 'leanness', word_class: 'N' }
  ],
  experiment: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  prevalent: [
    { word: 'prevail', word_class: 'V' },
    { word: 'prevalence', word_class: 'N' },
    { word: 'prevalent', word_class: 'AJ' },
    { word: 'prevailing', word_class: 'AJ' }
  ],
  recentness: [
    { word: 'recent', word_class: 'N' },
    { word: 'recent', word_class: 'AJ' },
    { word: 'recently', word_class: 'AV' },
    { word: 'recentness', word_class: 'N' }
  ],
  innocent: [
    { word: 'innocent', word_class: 'N' },
    { word: 'innocence', word_class: 'N' },
    { word: 'innocency', word_class: 'N' },
    { word: 'innocent', word_class: 'AJ' },
    { word: 'innocently', word_class: 'AV' }
  ],
  tumidness: [
    { word: 'tumid', word_class: 'AJ' },
    { word: 'tumidity', word_class: 'N' },
    { word: 'tumidness', word_class: 'N' }
  ],
  sports: [
    { word: 'sport', word_class: 'N' },
    { word: 'sport', word_class: 'V' },
    { word: 'sport', word_class: 'AJ' },
    { word: 'sports', word_class: 'N' },
    { word: 'sporting', word_class: 'AJ' }
  ],
  ingenuous: [
    { word: 'ingenue', word_class: 'N' },
    { word: 'ingenuity', word_class: 'N' },
    { word: 'ingenuous', word_class: 'AJ' },
    { word: 'ingenuously', word_class: 'AV' },
    { word: 'ingenuousness', word_class: 'N' }
  ],
  unambiguity: [
    { word: 'unambiguity', word_class: 'N' },
    { word: 'unambiguous', word_class: 'AJ' },
    { word: 'unambiguously', word_class: 'AV' }
  ],
  confining: [
    { word: 'confine', word_class: 'V' },
    { word: 'confines', word_class: 'N' },
    { word: 'confined', word_class: 'AJ' },
    { word: 'confining', word_class: 'AJ' },
    { word: 'confinement', word_class: 'N' }
  ],
  practicing: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  propagator: [
    { word: 'propagate', word_class: 'V' },
    { word: 'propagator', word_class: 'N' },
    { word: 'propagation', word_class: 'N' }
  ],
  egotistically: [
    { word: 'egotist', word_class: 'N' },
    { word: 'egotistic', word_class: 'AJ' },
    { word: 'egotistical', word_class: 'AJ' },
    { word: 'egotistically', word_class: 'AV' }
  ],
  piddle: [
    { word: 'piddle', word_class: 'N' },
    { word: 'piddle', word_class: 'V' },
    { word: 'piddling', word_class: 'AJ' }
  ],
  prescript: [
    { word: 'prescribe', word_class: 'N' },
    { word: 'prescribe', word_class: 'V' },
    { word: 'prescript', word_class: 'N' },
    { word: 'prescript', word_class: 'V' },
    { word: 'prescript', word_class: 'AJ' },
    { word: 'prescribed', word_class: 'AJ' },
    { word: 'prescription', word_class: 'N' },
    { word: 'prescription', word_class: 'AJ' },
    { word: 'prescriptive', word_class: 'AJ' }
  ],
  feeler: [
    { word: 'feel', word_class: 'N' },
    { word: 'feel', word_class: 'V' },
    { word: 'feeler', word_class: 'N' },
    { word: 'feeling', word_class: 'N' },
    { word: 'feelings', word_class: 'N' }
  ],
  crawling: [
    { word: 'crawl', word_class: 'N' },
    { word: 'crawl', word_class: 'V' },
    { word: 'crawler', word_class: 'N' },
    { word: 'crawling', word_class: 'N' },
    { word: 'crawling', word_class: 'AJ' }
  ],
  secure: [
    { word: 'secure', word_class: 'V' },
    { word: 'secure', word_class: 'AJ' },
    { word: 'security', word_class: 'N' },
    { word: 'secured', word_class: 'AJ' },
    { word: 'securely', word_class: 'AV' },
    { word: 'securement', word_class: 'N' },
    { word: 'secureness', word_class: 'N' }
  ],
  exacerbation: [
    { word: 'exacerbate', word_class: 'V' },
    { word: 'exacerbation', word_class: 'N' },
    { word: 'exacerbating', word_class: 'AJ' }
  ],
  five: [{ word: 'five', word_class: 'N' }, { word: 'five', word_class: 'AJ' }, { word: 'fives', word_class: 'N' }],
  spin: [
    { word: 'spin', word_class: 'N' },
    { word: 'spin', word_class: 'V' },
    { word: 'spinal', word_class: 'N' },
    { word: 'spinal', word_class: 'AJ' },
    { word: 'spinning', word_class: 'N' },
    { word: 'spinally', word_class: 'AV' },
    { word: 'spinning', word_class: 'AJ' }
  ],
  unappreciative: [
    { word: 'unappreciated', word_class: 'AJ' },
    { word: 'unappreciative', word_class: 'AJ' },
    { word: 'unappreciatively', word_class: 'AV' }
  ],
  collector: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  identified: [
    { word: 'identify', word_class: 'V' },
    { word: 'identified', word_class: 'AJ' },
    { word: 'identifying', word_class: 'AJ' },
    { word: 'identifiable', word_class: 'AJ' },
    { word: 'identifiably', word_class: 'AV' },
    { word: 'identification', word_class: 'N' }
  ],
  mauler: [{ word: 'maul', word_class: 'N' }, { word: 'maul', word_class: 'V' }, { word: 'mauler', word_class: 'N' }],
  necessitation: [
    { word: 'necessity', word_class: 'N' },
    { word: 'necessitate', word_class: 'V' },
    { word: 'necessitous', word_class: 'AJ' },
    { word: 'necessitation', word_class: 'N' }
  ],
  publicise: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  infix: [
    { word: 'infix', word_class: 'N' },
    { word: 'infix', word_class: 'V' },
    { word: 'infixion', word_class: 'N' },
    { word: 'infixation', word_class: 'N' }
  ],
  compilation: [
    { word: 'compile', word_class: 'V' },
    { word: 'compiler', word_class: 'N' },
    { word: 'compiling', word_class: 'N' },
    { word: 'compilation', word_class: 'N' }
  ],
  proceeds: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  dissuasive: [
    { word: 'dissuade', word_class: 'V' },
    { word: 'dissuasion', word_class: 'N' },
    { word: 'dissuasive', word_class: 'AJ' }
  ],
  asterisked: [
    { word: 'asterisk', word_class: 'N' },
    { word: 'asterisk', word_class: 'V' },
    { word: 'asterisked', word_class: 'AJ' }
  ],
  grin: [{ word: 'grin', word_class: 'N' }, { word: 'grin', word_class: 'V' }, { word: 'grinning', word_class: 'N' }],
  beaming: [
    { word: 'beam', word_class: 'N' },
    { word: 'beam', word_class: 'V' },
    { word: 'beaming', word_class: 'AJ' }
  ],
  hypostatisation: [
    { word: 'hypostasis', word_class: 'N' },
    { word: 'hypostatise', word_class: 'V' },
    { word: 'hypostatize', word_class: 'V' },
    { word: 'hypostatisation', word_class: 'N' },
    { word: 'hypostatization', word_class: 'N' }
  ],
  communicativeness: [
    { word: 'communicate', word_class: 'V' },
    { word: 'communicant', word_class: 'N' },
    { word: 'communicator', word_class: 'N' },
    { word: 'communicable', word_class: 'AJ' },
    { word: 'communication', word_class: 'N' },
    { word: 'communicating', word_class: 'N' },
    { word: 'communicative', word_class: 'AJ' },
    { word: 'communicational', word_class: 'AJ' },
    { word: 'communicativeness', word_class: 'N' }
  ],
  endorse: [
    { word: 'endorse', word_class: 'V' },
    { word: 'endorser', word_class: 'N' },
    { word: 'endorsed', word_class: 'AJ' },
    { word: 'endorsement', word_class: 'N' }
  ],
  garbled: [
    { word: 'garble', word_class: 'N' },
    { word: 'garble', word_class: 'V' },
    { word: 'garbled', word_class: 'AJ' }
  ],
  advantageous: [
    { word: 'advantage', word_class: 'N' },
    { word: 'advantage', word_class: 'V' },
    { word: 'advantaged', word_class: 'AJ' },
    { word: 'advantageous', word_class: 'AJ' },
    { word: 'advantageously', word_class: 'AV' },
    { word: 'advantageousness', word_class: 'N' }
  ],
  suited: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  ozone: [
    { word: 'ozone', word_class: 'N' },
    { word: 'ozonise', word_class: 'V' },
    { word: 'ozonize', word_class: 'V' },
    { word: 'ozonisation', word_class: 'N' },
    { word: 'ozonization', word_class: 'N' }
  ],
  unchangeableness: [
    { word: 'unchanged', word_class: 'AJ' },
    { word: 'unchanging', word_class: 'AJ' },
    { word: 'unchangeable', word_class: 'AJ' },
    { word: 'unchangeably', word_class: 'AV' },
    { word: 'unchangeability', word_class: 'N' },
    { word: 'unchangeableness', word_class: 'N' }
  ],
  furor: [
    { word: 'fur', word_class: 'N' },
    { word: 'fur', word_class: 'V' },
    { word: 'furor', word_class: 'N' },
    { word: 'furore', word_class: 'N' },
    { word: 'furred', word_class: 'AJ' },
    { word: 'furring', word_class: 'N' }
  ],
  jut: [
    { word: 'jut', word_class: 'N' },
    { word: 'jut', word_class: 'V' },
    { word: 'jutting', word_class: 'N' },
    { word: 'jutting', word_class: 'AJ' }
  ],
  cooperage: [
    { word: 'cooper', word_class: 'N' },
    { word: 'cooper', word_class: 'V' },
    { word: 'cooperage', word_class: 'N' },
    { word: 'cooperate', word_class: 'V' },
    { word: 'co-operate', word_class: 'V' },
    { word: 'cooperator', word_class: 'N' },
    { word: 'cooperation', word_class: 'N' },
    { word: 'cooperative', word_class: 'N' },
    { word: 'cooperative', word_class: 'AJ' },
    { word: 'cooperatively', word_class: 'AV' },
    { word: 'cooperativeness', word_class: 'N' }
  ],
  disjunction: [
    { word: 'disjoin', word_class: 'V' },
    { word: 'disjunct', word_class: 'N' },
    { word: 'disjunct', word_class: 'AJ' },
    { word: 'disjoined', word_class: 'AJ' },
    { word: 'disjunction', word_class: 'N' },
    { word: 'disjunctive', word_class: 'AJ' }
  ],
  encompassment: [
    { word: 'encompass', word_class: 'V' },
    { word: 'encompassing', word_class: 'AJ' },
    { word: 'encompassment', word_class: 'N' }
  ],
  clinic: [
    { word: 'clinic', word_class: 'N' },
    { word: 'clinic', word_class: 'AJ' },
    { word: 'clinical', word_class: 'AJ' },
    { word: 'clinically', word_class: 'AV' }
  ],
  solicitor: [
    { word: 'solicit', word_class: 'V' },
    { word: 'solicitor', word_class: 'N' },
    { word: 'solicited', word_class: 'AJ' },
    { word: 'solicitous', word_class: 'AJ' },
    { word: 'solicitation', word_class: 'N' },
    { word: 'solicitously', word_class: 'AV' },
    { word: 'solicitousness', word_class: 'N' }
  ],
  concessive: [
    { word: 'concede', word_class: 'V' },
    { word: 'conceded', word_class: 'AJ' },
    { word: 'conceding', word_class: 'N' },
    { word: 'conceding', word_class: 'AJ' },
    { word: 'concession', word_class: 'N' },
    { word: 'concessive', word_class: 'AJ' }
  ],
  fertilization: [
    { word: 'fertile', word_class: 'AJ' },
    { word: 'fertility', word_class: 'N' },
    { word: 'fertilize', word_class: 'V' },
    { word: 'fertilizer', word_class: 'N' },
    { word: 'fertilized', word_class: 'AJ' },
    { word: 'fertilization', word_class: 'N' }
  ],
  revive: [
    { word: 'revive', word_class: 'V' },
    { word: 'revival', word_class: 'N' },
    { word: 'revive', word_class: 'AJ' },
    { word: 'revived', word_class: 'AJ' },
    { word: 'reviving', word_class: 'AJ' },
    { word: 'revivalism', word_class: 'N' }
  ],
  incipiency: [
    { word: 'incipience', word_class: 'N' },
    { word: 'incipiency', word_class: 'N' },
    { word: 'incipient', word_class: 'AJ' }
  ],
  establish: [
    { word: 'establish', word_class: 'V' },
    { word: 'established', word_class: 'AJ' },
    { word: 'establishment', word_class: 'N' }
  ],
  iodination: [
    { word: 'iodin', word_class: 'N' },
    { word: 'iodine', word_class: 'N' },
    { word: 'iodinate', word_class: 'V' },
    { word: 'iodinated', word_class: 'AJ' },
    { word: 'iodination', word_class: 'N' },
    { word: 'iodinating', word_class: 'AJ' }
  ],
  prophetically: [
    { word: 'prophet', word_class: 'N' },
    { word: 'prophets', word_class: 'N' },
    { word: 'prophetic', word_class: 'AJ' },
    { word: 'prophetical', word_class: 'AJ' },
    { word: 'prophetically', word_class: 'AV' }
  ],
  jinx: [{ word: 'jinx', word_class: 'N' }, { word: 'jinx', word_class: 'V' }, { word: 'jinxed', word_class: 'AJ' }],
  unified: [
    { word: 'unify', word_class: 'V' },
    { word: 'unified', word_class: 'AJ' },
    { word: 'unifying', word_class: 'AJ' },
    { word: 'unification', word_class: 'N' }
  ],
  unimaginatively: [
    { word: 'unimagined', word_class: 'AJ' },
    { word: 'unimaginable', word_class: 'AJ' },
    { word: 'unimaginably', word_class: 'AV' },
    { word: 'unimaginative', word_class: 'AJ' },
    { word: 'unimaginatively', word_class: 'AV' }
  ],
  transform: [
    { word: 'transform', word_class: 'N' },
    { word: 'transform', word_class: 'V' },
    { word: 'transformer', word_class: 'N' },
    { word: 'transformed', word_class: 'AJ' },
    { word: 'transformable', word_class: 'AJ' },
    { word: 'transformation', word_class: 'N' }
  ],
  itemization: [
    { word: 'item', word_class: 'N' },
    { word: 'item', word_class: 'AV' },
    { word: 'itemise', word_class: 'V' },
    { word: 'itemize', word_class: 'V' },
    { word: 'itemisation', word_class: 'N' },
    { word: 'itemization', word_class: 'N' }
  ],
  reeking: [
    { word: 'reek', word_class: 'N' },
    { word: 'reek', word_class: 'V' },
    { word: 'reeking', word_class: 'AJ' }
  ],
  promulgated: [
    { word: 'promulgate', word_class: 'V' },
    { word: 'promulgator', word_class: 'N' },
    { word: 'promulgated', word_class: 'AJ' },
    { word: 'promulgation', word_class: 'N' }
  ],
  forswear: [
    { word: 'forswear', word_class: 'V' },
    { word: 'forsworn', word_class: 'AJ' },
    { word: 'forswearing', word_class: 'N' }
  ],
  downer: [
    { word: 'down', word_class: 'N' },
    { word: 'down', word_class: 'V' },
    { word: 'down', word_class: 'AJ' },
    { word: 'down', word_class: 'AV' },
    { word: 'downer', word_class: 'N' },
    { word: 'downed', word_class: 'AJ' }
  ],
  boatman: [
    { word: 'boat', word_class: 'N' },
    { word: 'boat', word_class: 'V' },
    { word: 'boater', word_class: 'N' },
    { word: 'boating', word_class: 'N' },
    { word: 'boatman', word_class: 'N' }
  ],
  essayist: [
    { word: 'essay', word_class: 'N' },
    { word: 'essay', word_class: 'V' },
    { word: 'essayist', word_class: 'N' }
  ],
  court: [
    { word: 'court', word_class: 'N' },
    { word: 'court', word_class: 'V' },
    { word: 'courting', word_class: 'N' },
    { word: 'courtship', word_class: 'N' }
  ],
  regional: [
    { word: 'region', word_class: 'N' },
    { word: 'regional', word_class: 'AJ' },
    { word: 'regionalize', word_class: 'V' },
    { word: 'regionally', word_class: 'AV' }
  ],
  volumetric: [
    { word: 'volumetric', word_class: 'AJ' },
    { word: 'volumetrical', word_class: 'AJ' },
    { word: 'volumetrically', word_class: 'AV' }
  ],
  occasion: [
    { word: 'occasion', word_class: 'N' },
    { word: 'occasion', word_class: 'V' },
    { word: 'occasions', word_class: 'N' },
    { word: 'occasional', word_class: 'AJ' },
    { word: 'occasionment', word_class: 'N' },
    { word: 'occasionally', word_class: 'AV' }
  ],
  frayed: [{ word: 'fray', word_class: 'N' }, { word: 'fray', word_class: 'V' }, { word: 'frayed', word_class: 'AJ' }],
  trace: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  maddened: [
    { word: 'madden', word_class: 'V' },
    { word: 'maddened', word_class: 'AJ' },
    { word: 'maddening', word_class: 'AJ' }
  ],
  corduroy: [
    { word: 'corduroy', word_class: 'N' },
    { word: 'corduroy', word_class: 'V' },
    { word: 'corduroy', word_class: 'AJ' },
    { word: 'corduroys', word_class: 'N' }
  ],
  sensualize: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  inductive: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  acquisition: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  censured: [
    { word: 'censure', word_class: 'N' },
    { word: 'censure', word_class: 'V' },
    { word: 'censured', word_class: 'AJ' },
    { word: 'censurable', word_class: 'AJ' }
  ],
  scorn: [
    { word: 'scorn', word_class: 'N' },
    { word: 'scorn', word_class: 'V' },
    { word: 'scorned', word_class: 'AJ' },
    { word: 'scornful', word_class: 'AJ' }
  ],
  marvel: [
    { word: 'marvel', word_class: 'N' },
    { word: 'marvel', word_class: 'V' },
    { word: 'marvelment', word_class: 'N' },
    { word: 'marvelous', word_class: 'AJ' },
    { word: 'marvellous', word_class: 'AJ' },
    { word: 'marvelously', word_class: 'AV' },
    { word: 'marvellously', word_class: 'AV' }
  ],
  disquiet: [
    { word: 'disquiet', word_class: 'N' },
    { word: 'disquiet', word_class: 'V' },
    { word: 'disquieted', word_class: 'AJ' },
    { word: 'disquieting', word_class: 'AJ' }
  ],
  desolately: [
    { word: 'desolate', word_class: 'V' },
    { word: 'desolate', word_class: 'AJ' },
    { word: 'desolated', word_class: 'AJ' },
    { word: 'desolation', word_class: 'N' },
    { word: 'desolately', word_class: 'AV' }
  ],
  reproduce: [
    { word: 'reproduce', word_class: 'V' },
    { word: 'reproducer', word_class: 'N' },
    { word: 'reproduction', word_class: 'N' },
    { word: 'reproducible', word_class: 'AJ' },
    { word: 'reproducibly', word_class: 'AV' },
    { word: 'reproductive', word_class: 'AJ' },
    { word: 'reproducibility', word_class: 'N' }
  ],
  individualism: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  quizzer: [
    { word: 'quiz', word_class: 'N' },
    { word: 'quiz', word_class: 'V' },
    { word: 'quizzer', word_class: 'N' },
    { word: 'quizzes', word_class: 'V' },
    { word: 'quizzic', word_class: 'AJ' },
    { word: 'quizzing', word_class: 'AJ' },
    { word: 'quizzical', word_class: 'AJ' },
    { word: 'quizzically', word_class: 'AV' }
  ],
  martyr: [
    { word: 'martyr', word_class: 'N' },
    { word: 'martyr', word_class: 'V' },
    { word: 'martyrize', word_class: 'V' },
    { word: 'martyrisation', word_class: 'N' },
    { word: 'martyrization', word_class: 'N' }
  ],
  arbitrage: [
    { word: 'arbitrage', word_class: 'N' },
    { word: 'arbitrage', word_class: 'V' },
    { word: 'arbitrager', word_class: 'N' }
  ],
  likeness: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  indictable: [
    { word: 'indict', word_class: 'V' },
    { word: 'indiction', word_class: 'N' },
    { word: 'indictment', word_class: 'N' },
    { word: 'indictable', word_class: 'AJ' },
    { word: 'indictability', word_class: 'N' }
  ],
  kid: [
    { word: 'kid', word_class: 'N' },
    { word: 'kid', word_class: 'V' },
    { word: 'kiddy', word_class: 'N' },
    { word: 'kiddie', word_class: 'N' }
  ],
  errancy: [
    { word: 'errant', word_class: 'N' },
    { word: 'errancy', word_class: 'N' },
    { word: 'errant', word_class: 'AJ' }
  ],
  festivity: [
    { word: 'festival', word_class: 'N' },
    { word: 'festive', word_class: 'AJ' },
    { word: 'festivity', word_class: 'N' }
  ],
  prejudge: [
    { word: 'prejudge', word_class: 'V' },
    { word: 'prejudgment', word_class: 'N' },
    { word: 'prejudgement', word_class: 'N' }
  ],
  syncopation: [
    { word: 'syncope', word_class: 'N' },
    { word: 'syncopate', word_class: 'V' },
    { word: 'syncopated', word_class: 'AJ' },
    { word: 'syncopation', word_class: 'N' }
  ],
  thunderer: [
    { word: 'thunder', word_class: 'N' },
    { word: 'thunder', word_class: 'V' },
    { word: 'thunderer', word_class: 'N' },
    { word: 'thundering', word_class: 'AJ' },
    { word: 'thunderous', word_class: 'AJ' }
  ],
  dos: [
    { word: 'do', word_class: 'N' },
    { word: 'do', word_class: 'V' },
    { word: 'don', word_class: 'N' },
    { word: 'don', word_class: 'V' },
    { word: 'dos', word_class: 'N' },
    { word: 'doer', word_class: 'N' },
    { word: 'door', word_class: 'N' },
    { word: 'doing', word_class: 'N' },
    { word: 'done', word_class: 'AJ' },
    { word: 'donee', word_class: 'N' },
    { word: 'doable', word_class: 'AJ' }
  ],
  except: [
    { word: 'except', word_class: 'V' },
    { word: 'exception', word_class: 'N' },
    { word: 'exceptional', word_class: 'AJ' },
    { word: 'exceptionable', word_class: 'AJ' },
    { word: 'exceptionally', word_class: 'AV' }
  ],
  trinitarian: [
    { word: 'trinity', word_class: 'N' },
    { word: 'trinitarian', word_class: 'N' },
    { word: 'trinitarian', word_class: 'AJ' },
    { word: 'trinitarianism', word_class: 'N' }
  ],
  implausable: [
    { word: 'implausable', word_class: 'AJ' },
    { word: 'implausably', word_class: 'AV' },
    { word: 'implausible', word_class: 'AJ' },
    { word: 'implausibly', word_class: 'AV' },
    { word: 'implausibility', word_class: 'N' },
    { word: 'implausibleness', word_class: 'N' }
  ],
  strategic: [
    { word: 'strategy', word_class: 'N' },
    { word: 'strategic', word_class: 'AJ' },
    { word: 'strategist', word_class: 'N' },
    { word: 'strategics', word_class: 'N' },
    { word: 'strategical', word_class: 'AJ' },
    { word: 'strategically', word_class: 'AV' }
  ],
  redouble: [
    { word: 'redouble', word_class: 'N' },
    { word: 'redouble', word_class: 'V' },
    { word: 'redoubled', word_class: 'AJ' }
  ],
  giving: [
    { word: 'gift', word_class: 'N' },
    { word: 'gift', word_class: 'V' },
    { word: 'give', word_class: 'N' },
    { word: 'give', word_class: 'V' },
    { word: 'given', word_class: 'N' },
    { word: 'giver', word_class: 'N' },
    { word: 'given', word_class: 'AJ' },
    { word: 'giving', word_class: 'N' },
    { word: 'gifted', word_class: 'AJ' },
    { word: 'giving', word_class: 'AJ' },
    { word: 'givenness', word_class: 'N' }
  ],
  inconceivable: [
    { word: 'inconceivable', word_class: 'AJ' },
    { word: 'inconceivably', word_class: 'AV' },
    { word: 'inconceivability', word_class: 'N' },
    { word: 'inconceivableness', word_class: 'N' }
  ],
  affixed: [
    { word: 'affix', word_class: 'N' },
    { word: 'affix', word_class: 'V' },
    { word: 'affixal', word_class: 'AJ' },
    { word: 'affixed', word_class: 'AJ' },
    { word: 'affixation', word_class: 'N' }
  ],
  geologization: [
    { word: 'geology', word_class: 'N' },
    { word: 'geologise', word_class: 'V' },
    { word: 'geologic', word_class: 'AJ' },
    { word: 'geologize', word_class: 'V' },
    { word: 'geological', word_class: 'AJ' },
    { word: 'geologically', word_class: 'AV' },
    { word: 'geologization', word_class: 'N' }
  ],
  dismantle: [
    { word: 'dismantle', word_class: 'N' },
    { word: 'dismantle', word_class: 'V' },
    { word: 'dismantled', word_class: 'AJ' },
    { word: 'dismantling', word_class: 'N' },
    { word: 'dismantlement', word_class: 'N' }
  ],
  linkage: [
    { word: 'link', word_class: 'N' },
    { word: 'link', word_class: 'V' },
    { word: 'links', word_class: 'N' },
    { word: 'linkage', word_class: 'N' },
    { word: 'linkman', word_class: 'N' },
    { word: 'linked', word_class: 'AJ' }
  ],
  convertible: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  hoarder: [
    { word: 'hoard', word_class: 'N' },
    { word: 'hoard', word_class: 'V' },
    { word: 'hoarder', word_class: 'N' },
    { word: 'hoarding', word_class: 'N' }
  ],
  revolving: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  impeccable: [
    { word: 'impeccant', word_class: 'AJ' },
    { word: 'impeccable', word_class: 'AJ' },
    { word: 'impeccably', word_class: 'AV' }
  ],
  canoe: [
    { word: 'canoe', word_class: 'N' },
    { word: 'canoe', word_class: 'V' },
    { word: 'canoeist', word_class: 'N' }
  ],
  confirmable: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  barbaric: [
    { word: 'barbaric', word_class: 'AJ' },
    { word: 'barbarism', word_class: 'N' },
    { word: 'barbarize', word_class: 'V' },
    { word: 'barbarity', word_class: 'N' },
    { word: 'barbarous', word_class: 'AJ' },
    { word: 'barbarously', word_class: 'AV' },
    { word: 'barbarization', word_class: 'N' },
    { word: 'barbarousness', word_class: 'N' }
  ],
  alloy: [
    { word: 'alloy', word_class: 'N' },
    { word: 'alloy', word_class: 'V' },
    { word: 'alloyed', word_class: 'AJ' }
  ],
  prickling: [
    { word: 'prickle', word_class: 'N' },
    { word: 'prickle', word_class: 'V' },
    { word: 'prickly', word_class: 'AJ' },
    { word: 'prickling', word_class: 'N' },
    { word: 'prickling', word_class: 'AJ' }
  ],
  verbal: [
    { word: 'verbal', word_class: 'N' },
    { word: 'verbal', word_class: 'AJ' },
    { word: 'verbalise', word_class: 'V' },
    { word: 'verbalize', word_class: 'V' },
    { word: 'verbally', word_class: 'AV' },
    { word: 'verbalized', word_class: 'AJ' },
    { word: 'verbalisation', word_class: 'N' },
    { word: 'verbalization', word_class: 'N' }
  ],
  issuing: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  anathematization: [
    { word: 'anathematise', word_class: 'V' },
    { word: 'anathematize', word_class: 'V' },
    { word: 'anathematisation', word_class: 'N' },
    { word: 'anathematization', word_class: 'N' }
  ],
  aphasic: [
    { word: 'aphasia', word_class: 'N' },
    { word: 'aphasic', word_class: 'N' },
    { word: 'aphasic', word_class: 'AJ' }
  ],
  outrageousness: [
    { word: 'outrage', word_class: 'N' },
    { word: 'outrage', word_class: 'V' },
    { word: 'outraged', word_class: 'AJ' },
    { word: 'outrageous', word_class: 'AJ' },
    { word: 'outrageously', word_class: 'AV' },
    { word: 'outrageousness', word_class: 'N' }
  ],
  vapid: [
    { word: 'vapid', word_class: 'AJ' },
    { word: 'vapidity', word_class: 'N' },
    { word: 'vapidness', word_class: 'N' }
  ],
  regally: [
    { word: 'regal', word_class: 'AJ' },
    { word: 'regale', word_class: 'N' },
    { word: 'regale', word_class: 'V' },
    { word: 'regally', word_class: 'AV' },
    { word: 'regalement', word_class: 'N' }
  ],
  'free-living': [
    { word: 'free-liver', word_class: 'N' },
    { word: 'free-living', word_class: 'N' },
    { word: 'free-living', word_class: 'AJ' }
  ],
  gravid: [
    { word: 'gravid', word_class: 'AJ' },
    { word: 'gravidity', word_class: 'N' },
    { word: 'gravidness', word_class: 'N' }
  ],
  reversion: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  absent: [
    { word: 'absent', word_class: 'V' },
    { word: 'absence', word_class: 'N' },
    { word: 'absent', word_class: 'AJ' },
    { word: 'absentee', word_class: 'N' },
    { word: 'absently', word_class: 'AV' },
    { word: 'absenteeism', word_class: 'N' }
  ],
  overlooked: [
    { word: 'overlook', word_class: 'N' },
    { word: 'overlook', word_class: 'V' },
    { word: 'overlooked', word_class: 'AJ' },
    { word: 'overlooking', word_class: 'AJ' }
  ],
  cerebrate: [
    { word: 'cerebral', word_class: 'AJ' },
    { word: 'cerebrate', word_class: 'V' },
    { word: 'cerebrally', word_class: 'AV' },
    { word: 'cerebration', word_class: 'N' }
  ],
  concordant: [
    { word: 'concord', word_class: 'N' },
    { word: 'concord', word_class: 'V' },
    { word: 'concordant', word_class: 'N' },
    { word: 'concordance', word_class: 'N' },
    { word: 'concordant', word_class: 'AJ' }
  ],
  smirch: [
    { word: 'smirch', word_class: 'N' },
    { word: 'smirch', word_class: 'V' },
    { word: 'smirched', word_class: 'AJ' }
  ],
  shaving: [
    { word: 'shave', word_class: 'N' },
    { word: 'shave', word_class: 'V' },
    { word: 'shaver', word_class: 'N' },
    { word: 'shaved', word_class: 'AJ' },
    { word: 'shaven', word_class: 'AJ' },
    { word: 'shaving', word_class: 'N' }
  ],
  epitomize: [
    { word: 'epitome', word_class: 'N' },
    { word: 'epitomize', word_class: 'V' },
    { word: 'epitomization', word_class: 'N' }
  ],
  applaud: [
    { word: 'applaud', word_class: 'V' },
    { word: 'applause', word_class: 'N' },
    { word: 'applauder', word_class: 'N' },
    { word: 'applaudable', word_class: 'AJ' }
  ],
  foal: [{ word: 'foal', word_class: 'N' }, { word: 'foal', word_class: 'V' }, { word: 'foaled', word_class: 'AJ' }],
  docking: [
    { word: 'dock', word_class: 'N' },
    { word: 'dock', word_class: 'V' },
    { word: 'docker', word_class: 'N' },
    { word: 'dockage', word_class: 'N' },
    { word: 'docked', word_class: 'AJ' },
    { word: 'docking', word_class: 'N' }
  ],
  interchangeableness: [
    { word: 'interchange', word_class: 'N' },
    { word: 'interchange', word_class: 'V' },
    { word: 'interchangeable', word_class: 'AJ' },
    { word: 'interchangeably', word_class: 'AV' },
    { word: 'interchangeability', word_class: 'N' },
    { word: 'interchangeableness', word_class: 'N' }
  ],
  parceling: [
    { word: 'parcel', word_class: 'N' },
    { word: 'parcel', word_class: 'V' },
    { word: 'parceling', word_class: 'N' },
    { word: 'parcelling', word_class: 'N' }
  ],
  hold: [
    { word: 'hold', word_class: 'N' },
    { word: 'hold', word_class: 'V' },
    { word: 'holder', word_class: 'N' },
    { word: 'holding', word_class: 'N' },
    { word: 'holding', word_class: 'AJ' }
  ],
  elaboration: [
    { word: 'elaborate', word_class: 'V' },
    { word: 'elaborate', word_class: 'AJ' },
    { word: 'elaborated', word_class: 'AJ' },
    { word: 'elaboration', word_class: 'N' },
    { word: 'elaborately', word_class: 'AV' },
    { word: 'elaborateness', word_class: 'N' }
  ],
  brambly: [
    { word: 'bramble', word_class: 'N' },
    { word: 'brambly', word_class: 'AJ' },
    { word: 'brambling', word_class: 'N' }
  ],
  acidulate: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  oak: [{ word: 'oak', word_class: 'N' }, { word: 'oak', word_class: 'AJ' }, { word: 'oaken', word_class: 'AJ' }],
  gutturalisation: [
    { word: 'guttural', word_class: 'N' },
    { word: 'guttural', word_class: 'AJ' },
    { word: 'gutturalise', word_class: 'V' },
    { word: 'gutturalize', word_class: 'V' },
    { word: 'gutturally', word_class: 'AV' },
    { word: 'gutturalisation', word_class: 'N' },
    { word: 'gutturalization', word_class: 'N' }
  ],
  realization: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  rover: [
    { word: 'rove', word_class: 'N' },
    { word: 'rove', word_class: 'V' },
    { word: 'rover', word_class: 'N' },
    { word: 'roving', word_class: 'N' },
    { word: 'roving', word_class: 'AJ' }
  ],
  value: [
    { word: 'value', word_class: 'N' },
    { word: 'value', word_class: 'V' },
    { word: 'value', word_class: 'AJ' },
    { word: 'valuer', word_class: 'N' },
    { word: 'values', word_class: 'N' },
    { word: 'valuate', word_class: 'V' },
    { word: 'valued', word_class: 'AJ' },
    { word: 'valuator', word_class: 'N' },
    { word: 'valuation', word_class: 'N' }
  ],
  bower: [
    { word: 'bow', word_class: 'N' },
    { word: 'bow', word_class: 'V' },
    { word: 'bow', word_class: 'AJ' },
    { word: 'bower', word_class: 'N' },
    { word: 'bower', word_class: 'V' },
    { word: 'bowed', word_class: 'AJ' },
    { word: 'bowman', word_class: 'N' },
    { word: 'bowing', word_class: 'N' },
    { word: 'bowing', word_class: 'AJ' }
  ],
  velum: [
    { word: 'velar', word_class: 'N' },
    { word: 'velum', word_class: 'N' },
    { word: 'velar', word_class: 'AJ' },
    { word: 'velarize', word_class: 'V' },
    { word: 'velarise', word_class: 'V' },
    { word: 'velarisation', word_class: 'N' },
    { word: 'velarization', word_class: 'N' }
  ],
  precipitation: [
    { word: 'precipitant', word_class: 'N' },
    { word: 'precipitate', word_class: 'N' },
    { word: 'precipitate', word_class: 'V' },
    { word: 'precipitance', word_class: 'N' },
    { word: 'precipitancy', word_class: 'N' },
    { word: 'precipitant', word_class: 'AJ' },
    { word: 'precipitate', word_class: 'AJ' },
    { word: 'precipitator', word_class: 'N' },
    { word: 'precipitous', word_class: 'AJ' },
    { word: 'precipitable', word_class: 'AJ' },
    { word: 'precipitation', word_class: 'N' },
    { word: 'precipitating', word_class: 'AJ' },
    { word: 'precipitately', word_class: 'AV' },
    { word: 'precipitously', word_class: 'AV' },
    { word: 'precipitateness', word_class: 'N' },
    { word: 'precipitousness', word_class: 'N' }
  ],
  movable: [
    { word: 'movable', word_class: 'AJ' },
    { word: 'movability', word_class: 'N' },
    { word: 'movableness', word_class: 'N' }
  ],
  tarnish: [
    { word: 'tarnish', word_class: 'N' },
    { word: 'tarnish', word_class: 'V' },
    { word: 'tarnished', word_class: 'AJ' }
  ],
  putridness: [
    { word: 'putrefy', word_class: 'V' },
    { word: 'putrid', word_class: 'AJ' },
    { word: 'putridity', word_class: 'N' },
    { word: 'putrefied', word_class: 'AJ' },
    { word: 'putridness', word_class: 'N' },
    { word: 'putrefaction', word_class: 'N' },
    { word: 'putrefiable', word_class: 'AJ' },
    { word: 'putrefactive', word_class: 'AJ' }
  ],
  surface: [
    { word: 'surface', word_class: 'N' },
    { word: 'surface', word_class: 'V' },
    { word: 'surface', word_class: 'AJ' },
    { word: 'surfacing', word_class: 'N' }
  ],
  shimmer: [
    { word: 'shimmer', word_class: 'N' },
    { word: 'shimmer', word_class: 'V' },
    { word: 'shimmering', word_class: 'AJ' }
  ],
  stretcher: [
    { word: 'stretch', word_class: 'N' },
    { word: 'stretch', word_class: 'V' },
    { word: 'stretch', word_class: 'AJ' },
    { word: 'stretcher', word_class: 'N' },
    { word: 'stretched', word_class: 'AJ' },
    { word: 'stretching', word_class: 'N' },
    { word: 'stretching', word_class: 'AJ' }
  ],
  lugubriously: [
    { word: 'lugubrious', word_class: 'AJ' },
    { word: 'lugubriously', word_class: 'AV' },
    { word: 'lugubriousness', word_class: 'N' }
  ],
  puke: [{ word: 'puke', word_class: 'N' }, { word: 'puke', word_class: 'V' }, { word: 'puking', word_class: 'N' }],
  compliments: [
    { word: 'compliment', word_class: 'N' },
    { word: 'compliment', word_class: 'V' },
    { word: 'compliments', word_class: 'N' },
    { word: 'complimentary', word_class: 'AJ' }
  ],
  annexal: [
    { word: 'annex', word_class: 'N' },
    { word: 'annex', word_class: 'V' },
    { word: 'annexe', word_class: 'N' },
    { word: 'annexal', word_class: 'AJ' },
    { word: 'annexation', word_class: 'N' },
    { word: 'annexational', word_class: 'AJ' }
  ],
  evangelical: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  subjugation: [
    { word: 'subjugate', word_class: 'V' },
    { word: 'subjugate', word_class: 'AJ' },
    { word: 'subjugable', word_class: 'AJ' },
    { word: 'subjugated', word_class: 'AJ' },
    { word: 'subjugation', word_class: 'N' }
  ],
  exception: [
    { word: 'except', word_class: 'V' },
    { word: 'exception', word_class: 'N' },
    { word: 'exceptional', word_class: 'AJ' },
    { word: 'exceptionable', word_class: 'AJ' },
    { word: 'exceptionally', word_class: 'AV' }
  ],
  anecdotic: [
    { word: 'anecdote', word_class: 'N' },
    { word: 'anecdotal', word_class: 'AJ' },
    { word: 'anecdotic', word_class: 'AJ' },
    { word: 'anecdotical', word_class: 'AJ' }
  ],
  granulation: [
    { word: 'granule', word_class: 'N' },
    { word: 'granulate', word_class: 'V' },
    { word: 'granulated', word_class: 'AJ' },
    { word: 'granulation', word_class: 'N' }
  ],
  meanness: [
    { word: 'mean', word_class: 'N' },
    { word: 'mean', word_class: 'V' },
    { word: 'mean', word_class: 'AJ' },
    { word: 'means', word_class: 'N' },
    { word: 'meaning', word_class: 'N' },
    { word: 'meaning', word_class: 'AJ' },
    { word: 'meanness', word_class: 'N' }
  ],
  diagonal: [
    { word: 'diagonal', word_class: 'N' },
    { word: 'diagonal', word_class: 'AJ' },
    { word: 'diagonalize', word_class: 'V' },
    { word: 'diagonally', word_class: 'AV' },
    { word: 'diagonalization', word_class: 'N' }
  ],
  haying: [{ word: 'hay', word_class: 'N' }, { word: 'hay', word_class: 'V' }, { word: 'haying', word_class: 'N' }],
  protrusible: [
    { word: 'protrude', word_class: 'V' },
    { word: 'protrusion', word_class: 'N' },
    { word: 'protruding', word_class: 'AJ' },
    { word: 'protrusive', word_class: 'AJ' },
    { word: 'protrusible', word_class: 'AJ' }
  ],
  measurability: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  bombard: [
    { word: 'bombard', word_class: 'N' },
    { word: 'bombard', word_class: 'V' },
    { word: 'bombardment', word_class: 'N' }
  ],
  modesty: [
    { word: 'modest', word_class: 'AJ' },
    { word: 'modesty', word_class: 'N' },
    { word: 'modestness', word_class: 'N' }
  ],
  complainant: [
    { word: 'complain', word_class: 'V' },
    { word: 'complaint', word_class: 'N' },
    { word: 'complainer', word_class: 'N' },
    { word: 'complainant', word_class: 'N' },
    { word: 'complaining', word_class: 'AJ' },
    { word: 'complaintive', word_class: 'AJ' }
  ],
  warrant: [
    { word: 'warrant', word_class: 'N' },
    { word: 'warrant', word_class: 'V' },
    { word: 'warrantee', word_class: 'N' },
    { word: 'warrantor', word_class: 'N' },
    { word: 'warranted', word_class: 'AJ' }
  ],
  arts: [
    { word: 'art', word_class: 'N' },
    { word: 'arts', word_class: 'N' },
    { word: 'artful', word_class: 'AJ' },
    { word: 'artistry', word_class: 'N' },
    { word: 'artfulness', word_class: 'N' }
  ],
  congenialness: [
    { word: 'congenial', word_class: 'AJ' },
    { word: 'congeniality', word_class: 'N' },
    { word: 'congenially', word_class: 'AV' },
    { word: 'congenialness', word_class: 'N' }
  ],
  jade: [
    { word: 'jade', word_class: 'N' },
    { word: 'jade', word_class: 'V' },
    { word: 'jade', word_class: 'AJ' },
    { word: 'jaded', word_class: 'AJ' }
  ],
  deceptively: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  physical: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  improve: [
    { word: 'improve', word_class: 'V' },
    { word: 'improver', word_class: 'N' },
    { word: 'improved', word_class: 'AJ' },
    { word: 'improving', word_class: 'AJ' },
    { word: 'improvable', word_class: 'AJ' },
    { word: 'improvement', word_class: 'N' }
  ],
  dictator: [
    { word: 'dictate', word_class: 'N' },
    { word: 'dictate', word_class: 'V' },
    { word: 'dication', word_class: 'N' },
    { word: 'dictator', word_class: 'N' },
    { word: 'dictated', word_class: 'AJ' },
    { word: 'dictation', word_class: 'N' }
  ],
  sheath: [
    { word: 'sheath', word_class: 'N' },
    { word: 'sheathe', word_class: 'V' },
    { word: 'sheathed', word_class: 'AJ' },
    { word: 'sheathing', word_class: 'N' }
  ],
  disaffect: [
    { word: 'disaffect', word_class: 'V' },
    { word: 'disaffected', word_class: 'AJ' },
    { word: 'disaffection', word_class: 'N' }
  ],
  glasses: [
    { word: 'glass', word_class: 'N' },
    { word: 'glass', word_class: 'V' },
    { word: 'glasses', word_class: 'N' },
    { word: 'glassed', word_class: 'AJ' },
    { word: 'glassful', word_class: 'N' },
    { word: 'glassful', word_class: 'AJ' }
  ],
  knowing: [
    { word: 'know', word_class: 'V' },
    { word: 'known', word_class: 'AJ' },
    { word: 'knowing', word_class: 'N' },
    { word: 'knowing', word_class: 'AJ' },
    { word: 'knowledge', word_class: 'N' },
    { word: 'knowledgeable', word_class: 'AJ' },
    { word: 'knowledgeably', word_class: 'AV' }
  ],
  maximal: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  reported: [
    { word: 'report', word_class: 'N' },
    { word: 'report', word_class: 'V' },
    { word: 'reporter', word_class: 'N' },
    { word: 'reportage', word_class: 'N' },
    { word: 'reported', word_class: 'AJ' },
    { word: 'reporting', word_class: 'N' },
    { word: 'reportable', word_class: 'AJ' },
    { word: 'reportorial', word_class: 'AJ' }
  ],
  worth: [
    { word: 'worth', word_class: 'N' },
    { word: 'worth', word_class: 'AJ' },
    { word: 'worthful', word_class: 'AJ' }
  ],
  inference: [
    { word: 'infer', word_class: 'V' },
    { word: 'inferral', word_class: 'N' },
    { word: 'inference', word_class: 'N' },
    { word: 'inferable', word_class: 'AJ' },
    { word: 'inferential', word_class: 'AJ' }
  ],
  absorptivity: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  conker: [{ word: 'conk', word_class: 'N' }, { word: 'conk', word_class: 'V' }, { word: 'conker', word_class: 'N' }],
  writing: [
    { word: 'write', word_class: 'V' },
    { word: 'writer', word_class: 'N' },
    { word: 'writing', word_class: 'N' },
    { word: 'writings', word_class: 'N' },
    { word: 'written', word_class: 'AJ' }
  ],
  torrefy: [
    { word: 'torrefy', word_class: 'V' },
    { word: 'torrify', word_class: 'V' },
    { word: 'torrefaction', word_class: 'N' },
    { word: 'torrifaction', word_class: 'N' }
  ],
  mischief: [
    { word: 'mischief', word_class: 'N' },
    { word: 'mischievous', word_class: 'AJ' },
    { word: 'mischievously', word_class: 'AV' },
    { word: 'mischievousness', word_class: 'N' }
  ],
  bilingual: [
    { word: 'bilingual', word_class: 'N' },
    { word: 'bilingual', word_class: 'AJ' },
    { word: 'bilingually', word_class: 'AV' }
  ],
  intruder: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  severing: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  pricy: [
    { word: 'price', word_class: 'N' },
    { word: 'price', word_class: 'V' },
    { word: 'pricy', word_class: 'AJ' },
    { word: 'priced', word_class: 'AJ' },
    { word: 'pricing', word_class: 'N' }
  ],
  mazed: [
    { word: 'maze', word_class: 'N' },
    { word: 'maze', word_class: 'V' },
    { word: 'mazed', word_class: 'AJ' },
    { word: 'mazement', word_class: 'N' }
  ],
  control: [
    { word: 'control', word_class: 'N' },
    { word: 'control', word_class: 'V' },
    { word: 'controller', word_class: 'N' },
    { word: 'controlled', word_class: 'AJ' },
    { word: 'controlling', word_class: 'N' },
    { word: 'controlling', word_class: 'AJ' },
    { word: 'controllable', word_class: 'AJ' }
  ],
  invasive: [
    { word: 'invade', word_class: 'V' },
    { word: 'invader', word_class: 'N' },
    { word: 'invasion', word_class: 'N' },
    { word: 'invading', word_class: 'AJ' },
    { word: 'invasive', word_class: 'AJ' }
  ],
  awakening: [
    { word: 'awaken', word_class: 'V' },
    { word: 'awakened', word_class: 'AJ' },
    { word: 'awakening', word_class: 'N' }
  ],
  rippled: [
    { word: 'ripple', word_class: 'N' },
    { word: 'ripple', word_class: 'V' },
    { word: 'ripply', word_class: 'AJ' },
    { word: 'rippled', word_class: 'AJ' },
    { word: 'rippling', word_class: 'N' }
  ],
  virulent: [
    { word: 'virulence', word_class: 'N' },
    { word: 'virulency', word_class: 'N' },
    { word: 'virulent', word_class: 'AJ' },
    { word: 'virulently', word_class: 'AV' }
  ],
  conquering: [
    { word: 'conquer', word_class: 'V' },
    { word: 'conquest', word_class: 'N' },
    { word: 'conqueror', word_class: 'N' },
    { word: 'conquered', word_class: 'AJ' },
    { word: 'conquering', word_class: 'N' },
    { word: 'conquering', word_class: 'AJ' },
    { word: 'conquerable', word_class: 'AJ' }
  ],
  stylist: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  magnification: [
    { word: 'magnify', word_class: 'V' },
    { word: 'magnifier', word_class: 'N' },
    { word: 'magnified', word_class: 'AJ' },
    { word: 'magnification', word_class: 'N' }
  ],
  desensitisation: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  jargonisation: [
    { word: 'jargon', word_class: 'N' },
    { word: 'jargon', word_class: 'V' },
    { word: 'jargonise', word_class: 'V' },
    { word: 'jargonize', word_class: 'V' },
    { word: 'jargonisation', word_class: 'N' },
    { word: 'jargonization', word_class: 'N' }
  ],
  ponderously: [
    { word: 'ponder', word_class: 'V' },
    { word: 'pondering', word_class: 'AJ' },
    { word: 'ponderous', word_class: 'AJ' },
    { word: 'ponderable', word_class: 'AJ' },
    { word: 'ponderously', word_class: 'AV' },
    { word: 'ponderousness', word_class: 'N' }
  ],
  dispassionately: [
    { word: 'dispassionate', word_class: 'AJ' },
    { word: 'dispassionately', word_class: 'AV' },
    { word: 'dispassionateness', word_class: 'N' }
  ],
  diversification: [
    { word: 'diversify', word_class: 'V' },
    { word: 'diversified', word_class: 'AJ' },
    { word: 'diversification', word_class: 'N' }
  ],
  dialectical: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  pulling: [
    { word: 'pull', word_class: 'N' },
    { word: 'pull', word_class: 'V' },
    { word: 'pullman', word_class: 'N' },
    { word: 'pulled', word_class: 'AJ' },
    { word: 'pulling', word_class: 'N' }
  ],
  coalescence: [
    { word: 'coalesce', word_class: 'V' },
    { word: 'coalesced', word_class: 'AJ' },
    { word: 'coalescence', word_class: 'N' },
    { word: 'coalescency', word_class: 'N' }
  ],
  unionism: [
    { word: 'unit', word_class: 'N' },
    { word: 'union', word_class: 'N' },
    { word: 'unite', word_class: 'V' },
    { word: 'unity', word_class: 'N' },
    { word: 'union', word_class: 'AJ' },
    { word: 'unity', word_class: 'AJ' },
    { word: 'united', word_class: 'AJ' },
    { word: 'uniting', word_class: 'N' },
    { word: 'unitize', word_class: 'V' },
    { word: 'unionise', word_class: 'V' },
    { word: 'unionism', word_class: 'N' },
    { word: 'unitary', word_class: 'AJ' },
    { word: 'unionize', word_class: 'V' },
    { word: 'unionist', word_class: 'N' },
    { word: 'unitarian', word_class: 'N' },
    { word: 'unionized', word_class: 'AJ' },
    { word: 'unitarian', word_class: 'AJ' },
    { word: 'unionisation', word_class: 'N' },
    { word: 'unionization', word_class: 'N' },
    { word: 'unitarianism', word_class: 'N' }
  ],
  adversative: [
    { word: 'advert', word_class: 'N' },
    { word: 'advert', word_class: 'V' },
    { word: 'adverse', word_class: 'AJ' },
    { word: 'adversary', word_class: 'N' },
    { word: 'adversion', word_class: 'N' },
    { word: 'adversity', word_class: 'N' },
    { word: 'advertize', word_class: 'V' },
    { word: 'adversely', word_class: 'AV' },
    { word: 'advertence', word_class: 'N' },
    { word: 'advertent', word_class: 'AJ' },
    { word: 'advertency', word_class: 'N' },
    { word: 'advertizing', word_class: 'N' },
    { word: 'adversative', word_class: 'AJ' },
    { word: 'advertently', word_class: 'AV' }
  ],
  sociobiologically: [
    { word: 'sociobiology', word_class: 'N' },
    { word: 'sociobiologic', word_class: 'AJ' },
    { word: 'sociobiological', word_class: 'AJ' },
    { word: 'sociobiologically', word_class: 'AV' }
  ],
  interchange: [
    { word: 'interchange', word_class: 'N' },
    { word: 'interchange', word_class: 'V' },
    { word: 'interchangeable', word_class: 'AJ' },
    { word: 'interchangeably', word_class: 'AV' },
    { word: 'interchangeability', word_class: 'N' },
    { word: 'interchangeableness', word_class: 'N' }
  ],
  homology: [
    { word: 'homology', word_class: 'N' },
    { word: 'homologate', word_class: 'V' },
    { word: 'homologic', word_class: 'AJ' },
    { word: 'homologize', word_class: 'V' },
    { word: 'homologous', word_class: 'AJ' },
    { word: 'homologation', word_class: 'N' },
    { word: 'homological', word_class: 'AJ' },
    { word: 'homologization', word_class: 'N' }
  ],
  tracing: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  logistics: [
    { word: 'logistic', word_class: 'AJ' },
    { word: 'logistics', word_class: 'N' },
    { word: 'logistical', word_class: 'AJ' }
  ],
  defenseless: [
    { word: 'defenseless', word_class: 'AJ' },
    { word: 'defenseless', word_class: 'AV' },
    { word: 'defenselessness', word_class: 'N' }
  ],
  unrealism: [
    { word: 'unreal', word_class: 'AJ' },
    { word: 'unrealism', word_class: 'N' },
    { word: 'unreality', word_class: 'N' },
    { word: 'unrealized', word_class: 'AJ' }
  ],
  confidential: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  preclude: [
    { word: 'preclude', word_class: 'V' },
    { word: 'preclusion', word_class: 'N' },
    { word: 'preclusive', word_class: 'AJ' }
  ],
  mounted: [
    { word: 'mount', word_class: 'N' },
    { word: 'mount', word_class: 'V' },
    { word: 'mounted', word_class: 'AJ' },
    { word: 'mounting', word_class: 'N' }
  ],
  abandonment: [
    { word: 'abandon', word_class: 'N' },
    { word: 'abandon', word_class: 'V' },
    { word: 'abandoned', word_class: 'AJ' },
    { word: 'abandonment', word_class: 'N' }
  ],
  transfigurement: [
    { word: 'transfigure', word_class: 'N' },
    { word: 'transfigure', word_class: 'V' },
    { word: 'transfiguration', word_class: 'N' },
    { word: 'transfigurement', word_class: 'N' }
  ],
  snuggle: [
    { word: 'snuggle', word_class: 'N' },
    { word: 'snuggle', word_class: 'V' },
    { word: 'snuggly', word_class: 'AJ' },
    { word: 'snuggled', word_class: 'AJ' },
    { word: 'snuggling', word_class: 'N' }
  ],
  installment: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  insensitively: [
    { word: 'insensitive', word_class: 'AJ' },
    { word: 'insensitivity', word_class: 'N' },
    { word: 'insensitively', word_class: 'AV' },
    { word: 'insensitiveness', word_class: 'N' }
  ],
  scourer: [
    { word: 'scour', word_class: 'N' },
    { word: 'scour', word_class: 'V' },
    { word: 'scours', word_class: 'N' },
    { word: 'scourer', word_class: 'N' },
    { word: 'scoured', word_class: 'AJ' },
    { word: 'scouring', word_class: 'N' }
  ],
  pebble: [
    { word: 'pebble', word_class: 'N' },
    { word: 'pebbly', word_class: 'AJ' },
    { word: 'pebbled', word_class: 'AJ' }
  ],
  doctoral: [
    { word: 'doctor', word_class: 'N' },
    { word: 'doctor', word_class: 'V' },
    { word: 'doctoral', word_class: 'AJ' },
    { word: 'doctorate', word_class: 'N' }
  ],
  soluble: [
    { word: 'soluble', word_class: 'AJ' },
    { word: 'solubly', word_class: 'AV' },
    { word: 'solubility', word_class: 'N' },
    { word: 'solubleness', word_class: 'N' }
  ],
  libelous: [
    { word: 'libel', word_class: 'N' },
    { word: 'libel', word_class: 'V' },
    { word: 'libeler', word_class: 'N' },
    { word: 'libelous', word_class: 'AJ' },
    { word: 'libellous', word_class: 'AJ' }
  ],
  collectively: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  seasonable: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  on: [
    { word: 'on', word_class: 'AV' },
    { word: 'one', word_class: 'N' },
    { word: 'on', word_class: 'AJ' },
    { word: 'one', word_class: 'AJ' },
    { word: 'oneness', word_class: 'N' }
  ],
  rationalization: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  owned: [
    { word: 'own', word_class: 'V' },
    { word: 'own', word_class: 'AJ' },
    { word: 'owner', word_class: 'N' },
    { word: 'owned', word_class: 'AJ' }
  ],
  urban: [
    { word: 'urban', word_class: 'AJ' },
    { word: 'urbane', word_class: 'AJ' },
    { word: 'urbanise', word_class: 'V' },
    { word: 'urbanite', word_class: 'N' },
    { word: 'urbanize', word_class: 'V' },
    { word: 'urbanity', word_class: 'N' },
    { word: 'urbanely', word_class: 'AV' },
    { word: 'urbanized', word_class: 'AJ' },
    { word: 'urbanization', word_class: 'N' }
  ],
  incumber: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  hijack: [
    { word: 'hijack', word_class: 'N' },
    { word: 'hijack', word_class: 'V' },
    { word: 'hijacker', word_class: 'N' },
    { word: 'hijacking', word_class: 'N' },
    { word: 'hijacking', word_class: 'AJ' }
  ],
  omnibus: [
    { word: 'omnibus', word_class: 'N' },
    { word: 'omnibus', word_class: 'V' },
    { word: 'omnibus', word_class: 'AJ' }
  ],
  swishy: [
    { word: 'swish', word_class: 'N' },
    { word: 'swish', word_class: 'V' },
    { word: 'swish', word_class: 'AJ' },
    { word: 'swishy', word_class: 'AJ' },
    { word: 'swishing', word_class: 'AJ' }
  ],
  current: [
    { word: 'current', word_class: 'N' },
    { word: 'current', word_class: 'AJ' },
    { word: 'currently', word_class: 'AV' },
    { word: 'currentness', word_class: 'N' }
  ],
  swinge: [
    { word: 'swing', word_class: 'N' },
    { word: 'swing', word_class: 'V' },
    { word: 'swinge', word_class: 'V' },
    { word: 'swinger', word_class: 'N' },
    { word: 'swinging', word_class: 'N' },
    { word: 'swinging', word_class: 'AJ' },
    { word: 'swingeing', word_class: 'AJ' }
  ],
  proportionate: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  disowned: [
    { word: 'disown', word_class: 'V' },
    { word: 'disowned', word_class: 'AJ' },
    { word: 'disowning', word_class: 'N' },
    { word: 'disownment', word_class: 'N' }
  ],
  resplendence: [
    { word: 'resplendence', word_class: 'N' },
    { word: 'resplendency', word_class: 'N' },
    { word: 'resplendent', word_class: 'AJ' },
    { word: 'resplendently', word_class: 'AV' }
  ],
  receivable: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  describe: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  joking: [
    { word: 'joke', word_class: 'N' },
    { word: 'joke', word_class: 'V' },
    { word: 'joker', word_class: 'N' },
    { word: 'joking', word_class: 'AJ' }
  ],
  solemnise: [
    { word: 'solemn', word_class: 'AJ' },
    { word: 'solemnise', word_class: 'V' },
    { word: 'solemnity', word_class: 'N' },
    { word: 'solemnize', word_class: 'V' },
    { word: 'solemnisation', word_class: 'N' },
    { word: 'solemnization', word_class: 'N' }
  ],
  complexness: [
    { word: 'complex', word_class: 'N' },
    { word: 'complex', word_class: 'AJ' },
    { word: 'complexity', word_class: 'N' },
    { word: 'complexness', word_class: 'N' }
  ],
  epicurean: [
    { word: 'epicurean', word_class: 'N' },
    { word: 'epicurean', word_class: 'AJ' },
    { word: 'epicureanism', word_class: 'N' }
  ],
  reflexive: [
    { word: 'reflex', word_class: 'N' },
    { word: 'reflex', word_class: 'V' },
    { word: 'reflex', word_class: 'AJ' },
    { word: 'reflexive', word_class: 'N' },
    { word: 'reflexive', word_class: 'AJ' },
    { word: 'reflexivity', word_class: 'N' },
    { word: 'reflexiveness', word_class: 'N' }
  ],
  perpetually: [
    { word: 'perpetual', word_class: 'AJ' },
    { word: 'perpetuate', word_class: 'V' },
    { word: 'perpetuity', word_class: 'N' },
    { word: 'perpetually', word_class: 'AV' },
    { word: 'perpetuation', word_class: 'N' }
  ],
  amerindic: [
    { word: 'amerind', word_class: 'N' },
    { word: 'amerind', word_class: 'AJ' },
    { word: 'amerindic', word_class: 'AJ' }
  ],
  groaning: [
    { word: 'groan', word_class: 'N' },
    { word: 'groan', word_class: 'V' },
    { word: 'groaning', word_class: 'AJ' }
  ],
  operably: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  imagery: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  deputy: [
    { word: 'depute', word_class: 'V' },
    { word: 'deputy', word_class: 'N' },
    { word: 'deputy', word_class: 'AJ' },
    { word: 'deputize', word_class: 'V' },
    { word: 'deputation', word_class: 'N' },
    { word: 'deputization', word_class: 'N' }
  ],
  proceed: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  dialectically: [
    { word: 'dialect', word_class: 'N' },
    { word: 'dialectic', word_class: 'N' },
    { word: 'dialectal', word_class: 'AJ' },
    { word: 'dialectic', word_class: 'AJ' },
    { word: 'dialectics', word_class: 'N' },
    { word: 'dialectical', word_class: 'AJ' },
    { word: 'dialectically', word_class: 'AV' }
  ],
  demote: [
    { word: 'demote', word_class: 'V' },
    { word: 'demotic', word_class: 'N' },
    { word: 'demotic', word_class: 'AJ' },
    { word: 'demotion', word_class: 'N' }
  ],
  waterproof: [
    { word: 'waterproof', word_class: 'N' },
    { word: 'waterproof', word_class: 'V' },
    { word: 'waterproof', word_class: 'AJ' },
    { word: 'waterproofed', word_class: 'AJ' },
    { word: 'waterproofing', word_class: 'N' }
  ],
  appetent: [
    { word: 'appetence', word_class: 'N' },
    { word: 'appetency', word_class: 'N' },
    { word: 'appetent', word_class: 'AJ' },
    { word: 'appetizer', word_class: 'N' },
    { word: 'appetizing', word_class: 'AJ' }
  ],
  bode: [{ word: 'bode', word_class: 'V' }, { word: 'boding', word_class: 'N' }, { word: 'bodement', word_class: 'N' }],
  moment: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  bosom: [
    { word: 'bosom', word_class: 'N' },
    { word: 'bosom', word_class: 'V' },
    { word: 'bosom', word_class: 'AJ' },
    { word: 'bosomed', word_class: 'AJ' }
  ],
  torturous: [
    { word: 'torture', word_class: 'N' },
    { word: 'torture', word_class: 'V' },
    { word: 'torturer', word_class: 'N' },
    { word: 'tortuous', word_class: 'AJ' },
    { word: 'tortured', word_class: 'AJ' },
    { word: 'torturing', word_class: 'N' },
    { word: 'torturing', word_class: 'AJ' },
    { word: 'torturous', word_class: 'AJ' },
    { word: 'torturously', word_class: 'AV' }
  ],
  escaped: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  satisfied: [
    { word: 'satisfy', word_class: 'V' },
    { word: 'satisfied', word_class: 'AJ' },
    { word: 'satisfying', word_class: 'N' },
    { word: 'satisfying', word_class: 'AJ' },
    { word: 'satisfaction', word_class: 'N' },
    { word: 'satisfiable', word_class: 'AJ' }
  ],
  orientated: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  taping: [
    { word: 'tape', word_class: 'N' },
    { word: 'tape', word_class: 'V' },
    { word: 'taped', word_class: 'AJ' },
    { word: 'taping', word_class: 'N' }
  ],
  tenderized: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  analyzed: [
    { word: 'analyse', word_class: 'V' },
    { word: 'analyze', word_class: 'V' },
    { word: 'analyser', word_class: 'N' },
    { word: 'analysis', word_class: 'N' },
    { word: 'analyzer', word_class: 'N' },
    { word: 'analytic', word_class: 'AJ' },
    { word: 'analyzed', word_class: 'AJ' },
    { word: 'analytical', word_class: 'AJ' },
    { word: 'analyticity', word_class: 'N' },
    { word: 'analyzable', word_class: 'AJ' },
    { word: 'analyzation', word_class: 'N' },
    { word: 'analytically', word_class: 'AV' }
  ],
  drinking: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  unalterable: [
    { word: 'unalterable', word_class: 'AJ' },
    { word: 'unalterably', word_class: 'AV' },
    { word: 'unalterability', word_class: 'N' }
  ],
  desolated: [
    { word: 'desolate', word_class: 'V' },
    { word: 'desolate', word_class: 'AJ' },
    { word: 'desolated', word_class: 'AJ' },
    { word: 'desolation', word_class: 'N' },
    { word: 'desolately', word_class: 'AV' }
  ],
  quibbler: [
    { word: 'quibble', word_class: 'N' },
    { word: 'quibble', word_class: 'V' },
    { word: 'quibbler', word_class: 'N' },
    { word: 'quibbling', word_class: 'AJ' }
  ],
  combinational: [
    { word: 'combine', word_class: 'N' },
    { word: 'combine', word_class: 'V' },
    { word: 'combined', word_class: 'AJ' },
    { word: 'combining', word_class: 'N' },
    { word: 'combinator', word_class: 'N' },
    { word: 'combining', word_class: 'AJ' },
    { word: 'combinable', word_class: 'AJ' },
    { word: 'combination', word_class: 'N' },
    { word: 'combinative', word_class: 'AJ' },
    { word: 'combinational', word_class: 'AJ' }
  ],
  separability: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  volcanic: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  headful: [
    { word: 'head', word_class: 'N' },
    { word: 'head', word_class: 'V' },
    { word: 'head', word_class: 'AJ' },
    { word: 'header', word_class: 'N' },
    { word: 'headed', word_class: 'AJ' },
    { word: 'heading', word_class: 'N' },
    { word: 'headman', word_class: 'N' },
    { word: 'headful', word_class: 'N' },
    { word: 'headship', word_class: 'N' }
  ],
  trauma: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  professionally: [
    { word: 'profess', word_class: 'V' },
    { word: 'professor', word_class: 'N' },
    { word: 'profession', word_class: 'N' },
    { word: 'professed', word_class: 'AJ' },
    { word: 'professing', word_class: 'N' },
    { word: 'professional', word_class: 'N' },
    { word: 'professional', word_class: 'AJ' },
    { word: 'professionalism', word_class: 'N' },
    { word: 'professionally', word_class: 'AV' }
  ],
  whop: [
    { word: 'whop', word_class: 'N' },
    { word: 'whop', word_class: 'V' },
    { word: 'whopping', word_class: 'AJ' },
    { word: 'whopping', word_class: 'AV' }
  ],
  appoint: [
    { word: 'appoint', word_class: 'V' },
    { word: 'appointee', word_class: 'N' },
    { word: 'appointed', word_class: 'AJ' },
    { word: 'appiontment', word_class: 'N' },
    { word: 'appointive', word_class: 'AJ' },
    { word: 'appointment', word_class: 'N' }
  ],
  prestige: [
    { word: 'prestige', word_class: 'N' },
    { word: 'prestigious', word_class: 'AJ' },
    { word: 'prestigiousness', word_class: 'N' }
  ],
  moisturize: [
    { word: 'moist', word_class: 'AJ' },
    { word: 'moisture', word_class: 'N' },
    { word: 'moistness', word_class: 'N' },
    { word: 'moisturize', word_class: 'V' }
  ],
  pasteurize: [
    { word: 'pasteur', word_class: 'N' },
    { word: 'pasteurize', word_class: 'V' },
    { word: 'pasteurized', word_class: 'AJ' },
    { word: 'pasteurization', word_class: 'N' }
  ],
  groveller: [
    { word: 'grovel', word_class: 'V' },
    { word: 'groveller', word_class: 'N' },
    { word: 'groveling', word_class: 'AJ' },
    { word: 'grovelling', word_class: 'AJ' }
  ],
  prussianisation: [
    { word: 'prussian', word_class: 'N' },
    { word: 'prussian', word_class: 'AJ' },
    { word: 'prussianise', word_class: 'V' },
    { word: 'prussianize', word_class: 'V' },
    { word: 'prussianisation', word_class: 'N' },
    { word: 'prussianization', word_class: 'N' }
  ],
  amphitheatrical: [
    { word: 'amphitheatre', word_class: 'N' },
    { word: 'amphitheatric', word_class: 'AJ' },
    { word: 'amphitheatrical', word_class: 'AJ' }
  ],
  howling: [
    { word: 'howl', word_class: 'N' },
    { word: 'howl', word_class: 'V' },
    { word: 'howler', word_class: 'N' },
    { word: 'howling', word_class: 'N' },
    { word: 'howling', word_class: 'AJ' }
  ],
  demoralize: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  fakeer: [
    { word: 'fake', word_class: 'N' },
    { word: 'fake', word_class: 'V' },
    { word: 'fake', word_class: 'AJ' },
    { word: 'faker', word_class: 'N' },
    { word: 'fakeer', word_class: 'N' }
  ],
  pardon: [
    { word: 'pardon', word_class: 'N' },
    { word: 'pardon', word_class: 'V' },
    { word: 'pardoner', word_class: 'N' },
    { word: 'pardonable', word_class: 'AJ' },
    { word: 'pardonably', word_class: 'AV' }
  ],
  catheterize: [
    { word: 'catheterise', word_class: 'V' },
    { word: 'catheterize', word_class: 'V' },
    { word: 'catheterisation', word_class: 'N' },
    { word: 'catheterization', word_class: 'N' }
  ],
  simulate: [
    { word: 'simulate', word_class: 'V' },
    { word: 'simulator', word_class: 'N' },
    { word: 'simulated', word_class: 'AJ' },
    { word: 'simulation', word_class: 'N' }
  ],
  quenched: [
    { word: 'quench', word_class: 'V' },
    { word: 'quenched', word_class: 'AJ' },
    { word: 'quenching', word_class: 'N' }
  ],
  warehouser: [
    { word: 'warehouse', word_class: 'N' },
    { word: 'warehouse', word_class: 'V' },
    { word: 'warehouser', word_class: 'N' }
  ],
  fake: [
    { word: 'fake', word_class: 'N' },
    { word: 'fake', word_class: 'V' },
    { word: 'fake', word_class: 'AJ' },
    { word: 'faker', word_class: 'N' },
    { word: 'fakeer', word_class: 'N' }
  ],
  vaccinate: [
    { word: 'vaccine', word_class: 'N' },
    { word: 'vaccinate', word_class: 'V' },
    { word: 'vaccinated', word_class: 'AJ' },
    { word: 'vaccinating', word_class: 'N' },
    { word: 'vaccination', word_class: 'N' }
  ],
  ethnicity: [
    { word: 'ethnic', word_class: 'AJ' },
    { word: 'ethnical', word_class: 'AJ' },
    { word: 'ethnicity', word_class: 'N' },
    { word: 'ethnically', word_class: 'AV' }
  ],
  south: [{ word: 'south', word_class: 'N' }, { word: 'south', word_class: 'AJ' }, { word: 'south', word_class: 'AV' }],
  towering: [
    { word: 'tower', word_class: 'N' },
    { word: 'tower', word_class: 'V' },
    { word: 'towering', word_class: 'AJ' }
  ],
  ratify: [
    { word: 'ratify', word_class: 'V' },
    { word: 'ratifier', word_class: 'N' },
    { word: 'ratified', word_class: 'AJ' },
    { word: 'ratification', word_class: 'N' }
  ],
  dividable: [
    { word: 'divide', word_class: 'N' },
    { word: 'divide', word_class: 'V' },
    { word: 'divider', word_class: 'N' },
    { word: 'divided', word_class: 'AJ' },
    { word: 'dividers', word_class: 'N' },
    { word: 'division', word_class: 'N' },
    { word: 'dividing', word_class: 'AJ' },
    { word: 'divisive', word_class: 'AJ' },
    { word: 'dividable', word_class: 'AJ' },
    { word: 'divisible', word_class: 'AJ' },
    { word: 'divisibility', word_class: 'N' }
  ],
  tempt: [
    { word: 'tempt', word_class: 'V' },
    { word: 'tempter', word_class: 'N' },
    { word: 'tempting', word_class: 'AJ' },
    { word: 'temptress', word_class: 'N' },
    { word: 'temptation', word_class: 'N' }
  ],
  bitterness: [
    { word: 'bitter', word_class: 'N' },
    { word: 'bitter', word_class: 'V' },
    { word: 'bitter', word_class: 'AJ' },
    { word: 'bitter', word_class: 'AV' },
    { word: 'bitters', word_class: 'N' },
    { word: 'bitterness', word_class: 'N' }
  ],
  anthropomorphism: [
    { word: 'anthropomorphic', word_class: 'AJ' },
    { word: 'anthropomorphism', word_class: 'N' },
    { word: 'anthropomorphize', word_class: 'V' },
    { word: 'anthropomorphous', word_class: 'AJ' },
    { word: 'anthropomorphization', word_class: 'N' }
  ],
  ascomycetous: [
    { word: 'ascomycete', word_class: 'N' },
    { word: 'ascomycetes', word_class: 'N' },
    { word: 'ascomycetous', word_class: 'AJ' }
  ],
  brawling: [
    { word: 'brawl', word_class: 'N' },
    { word: 'brawl', word_class: 'V' },
    { word: 'brawler', word_class: 'N' },
    { word: 'brawling', word_class: 'AJ' }
  ],
  loaf: [
    { word: 'loaf', word_class: 'N' },
    { word: 'loaf', word_class: 'V' },
    { word: 'loafer', word_class: 'N' },
    { word: 'loafing', word_class: 'N' }
  ],
  cogitate: [
    { word: 'cogitate', word_class: 'V' },
    { word: 'cogitable', word_class: 'AJ' },
    { word: 'cogitation', word_class: 'N' },
    { word: 'cogitative', word_class: 'AJ' }
  ],
  disposition: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  package: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  caress: [
    { word: 'caress', word_class: 'N' },
    { word: 'caress', word_class: 'V' },
    { word: 'caressing', word_class: 'N' },
    { word: 'caressing', word_class: 'AJ' },
    { word: 'caressive', word_class: 'AJ' }
  ],
  coal: [{ word: 'coal', word_class: 'N' }, { word: 'coal', word_class: 'V' }, { word: 'coalman', word_class: 'N' }],
  strangled: [
    { word: 'strangle', word_class: 'V' },
    { word: 'strangler', word_class: 'N' },
    { word: 'strangles', word_class: 'N' },
    { word: 'strangled', word_class: 'AJ' },
    { word: 'strangling', word_class: 'N' },
    { word: 'strangulate', word_class: 'V' },
    { word: 'strangulation', word_class: 'N' }
  ],
  stableman: [
    { word: 'stable', word_class: 'N' },
    { word: 'stable', word_class: 'V' },
    { word: 'stable', word_class: 'AJ' },
    { word: 'stabile', word_class: 'N' },
    { word: 'stabile', word_class: 'AJ' },
    { word: 'stabling', word_class: 'N' },
    { word: 'stabilise', word_class: 'V' },
    { word: 'stabilize', word_class: 'V' },
    { word: 'stableman', word_class: 'N' },
    { word: 'stability', word_class: 'N' },
    { word: 'stabilizer', word_class: 'N' },
    { word: 'stableness', word_class: 'N' },
    { word: 'stabilized', word_class: 'AJ' },
    { word: 'stabilizing', word_class: 'AJ' },
    { word: 'stabilisation', word_class: 'N' },
    { word: 'stabilization', word_class: 'N' }
  ],
  exhaustive: [
    { word: 'exhaust', word_class: 'N' },
    { word: 'exhaust', word_class: 'V' },
    { word: 'exhausted', word_class: 'AJ' },
    { word: 'exhaustion', word_class: 'N' },
    { word: 'exhaustive', word_class: 'AJ' },
    { word: 'exhausting', word_class: 'AJ' },
    { word: 'exhaustible', word_class: 'AJ' },
    { word: 'exhaustively', word_class: 'AV' }
  ],
  pestering: [
    { word: 'pester', word_class: 'V' },
    { word: 'pesterer', word_class: 'N' },
    { word: 'pestered', word_class: 'AJ' },
    { word: 'pestering', word_class: 'AJ' }
  ],
  inconvenient: [
    { word: 'inconvenience', word_class: 'N' },
    { word: 'inconvenience', word_class: 'V' },
    { word: 'inconvenient', word_class: 'AJ' },
    { word: 'inconveniently', word_class: 'AV' }
  ],
  conceded: [
    { word: 'concede', word_class: 'V' },
    { word: 'conceded', word_class: 'AJ' },
    { word: 'conceding', word_class: 'N' },
    { word: 'conceding', word_class: 'AJ' },
    { word: 'concession', word_class: 'N' },
    { word: 'concessive', word_class: 'AJ' }
  ],
  curvaceous: [
    { word: 'curvaceous', word_class: 'AJ' },
    { word: 'curvaceously', word_class: 'AV' },
    { word: 'curvaceousness', word_class: 'N' }
  ],
  pronominalise: [
    { word: 'pronominal', word_class: 'AJ' },
    { word: 'pronominalise', word_class: 'V' },
    { word: 'pronominalize', word_class: 'V' },
    { word: 'pronominalisation', word_class: 'N' },
    { word: 'pronominalization', word_class: 'N' }
  ],
  quadruple: [
    { word: 'quadruple', word_class: 'N' },
    { word: 'quadruple', word_class: 'V' },
    { word: 'quadruple', word_class: 'AJ' },
    { word: 'quadrupling', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'V' },
    { word: 'quadruplicate', word_class: 'AJ' },
    { word: 'quadruplication', word_class: 'N' }
  ],
  recent: [
    { word: 'recent', word_class: 'N' },
    { word: 'recent', word_class: 'AJ' },
    { word: 'recently', word_class: 'AV' },
    { word: 'recentness', word_class: 'N' }
  ],
  intimacy: [
    { word: 'intimacy', word_class: 'N' },
    { word: 'intimate', word_class: 'N' },
    { word: 'intimal', word_class: 'AJ' },
    { word: 'intimate', word_class: 'V' },
    { word: 'intimate', word_class: 'AJ' },
    { word: 'intimation', word_class: 'N' },
    { word: 'intimately', word_class: 'AV' }
  ],
  customs: [
    { word: 'custom', word_class: 'N' },
    { word: 'custom', word_class: 'AJ' },
    { word: 'customs', word_class: 'N' },
    { word: 'customer', word_class: 'N' },
    { word: 'customize', word_class: 'V' },
    { word: 'customary', word_class: 'AJ' },
    { word: 'customization', word_class: 'N' }
  ],
  wangling: [
    { word: 'wangle', word_class: 'N' },
    { word: 'wangle', word_class: 'V' },
    { word: 'wangling', word_class: 'N' }
  ],
  tattler: [
    { word: 'tattle', word_class: 'N' },
    { word: 'tattle', word_class: 'V' },
    { word: 'tattler', word_class: 'N' },
    { word: 'tattling', word_class: 'AJ' }
  ],
  ascendent: [
    { word: 'ascend', word_class: 'V' },
    { word: 'ascent', word_class: 'N' },
    { word: 'ascendant', word_class: 'N' },
    { word: 'ascension', word_class: 'N' },
    { word: 'ascendent', word_class: 'N' },
    { word: 'ascending', word_class: 'N' },
    { word: 'ascendance', word_class: 'N' },
    { word: 'ascendancy', word_class: 'N' },
    { word: 'ascendant', word_class: 'AJ' },
    { word: 'ascendence', word_class: 'N' },
    { word: 'ascending', word_class: 'AJ' },
    { word: 'ascendent', word_class: 'AJ' },
    { word: 'ascendency', word_class: 'N' },
    { word: 'ascensive', word_class: 'AJ' },
    { word: 'ascendable', word_class: 'AJ' },
    { word: 'ascendible', word_class: 'AJ' }
  ],
  caster: [
    { word: 'cast', word_class: 'N' },
    { word: 'cast', word_class: 'V' },
    { word: 'cast', word_class: 'AJ' },
    { word: 'caste', word_class: 'N' },
    { word: 'caster', word_class: 'N' },
    { word: 'castor', word_class: 'N' },
    { word: 'casting', word_class: 'N' }
  ],
  impoverish: [
    { word: 'impoverish', word_class: 'V' },
    { word: 'impoverished', word_class: 'AJ' },
    { word: 'impoverishment', word_class: 'N' },
    { word: 'impovempoverish', word_class: 'V' },
    { word: 'impovempoverishment', word_class: 'N' }
  ],
  maine: [{ word: 'main', word_class: 'N' }, { word: 'main', word_class: 'AJ' }, { word: 'maine', word_class: 'N' }],
  molder: [
    { word: 'molder', word_class: 'V' },
    { word: 'moldered', word_class: 'AJ' },
    { word: 'moldering', word_class: 'AJ' }
  ],
  emigration: [
    { word: 'emigre', word_class: 'N' },
    { word: 'emigrant', word_class: 'N' },
    { word: 'emigrate', word_class: 'V' },
    { word: 'emigration', word_class: 'N' }
  ],
  blackening: [
    { word: 'blacken', word_class: 'V' },
    { word: 'blackened', word_class: 'AJ' },
    { word: 'blackening', word_class: 'N' }
  ],
  infantile: [
    { word: 'infant', word_class: 'N' },
    { word: 'infancy', word_class: 'N' },
    { word: 'infancy', word_class: 'V' },
    { word: 'infant', word_class: 'AJ' },
    { word: 'infancy', word_class: 'AJ' },
    { word: 'infantile', word_class: 'AJ' },
    { word: 'infantilism', word_class: 'N' }
  ],
  trainful: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  annihilating: [
    { word: 'annihilate', word_class: 'V' },
    { word: 'annihilated', word_class: 'AJ' },
    { word: 'annihilation', word_class: 'N' },
    { word: 'annihilating', word_class: 'AJ' },
    { word: 'annihilative', word_class: 'AJ' }
  ],
  carry: [
    { word: 'carry', word_class: 'N' },
    { word: 'carry', word_class: 'V' },
    { word: 'carry', word_class: 'AJ' },
    { word: 'carrier', word_class: 'N' },
    { word: 'carriage', word_class: 'N' }
  ],
  formicate: [
    { word: 'formic', word_class: 'AJ' },
    { word: 'formicate', word_class: 'V' },
    { word: 'formication', word_class: 'N' }
  ],
  round: [
    { word: 'round', word_class: 'N' },
    { word: 'round', word_class: 'V' },
    { word: 'round', word_class: 'AJ' },
    { word: 'round', word_class: 'AV' },
    { word: 'rounded', word_class: 'AJ' },
    { word: 'roundness', word_class: 'N' }
  ],
  aberrate: [
    { word: 'aberrant', word_class: 'N' },
    { word: 'aberrate', word_class: 'V' },
    { word: 'aberrance', word_class: 'N' },
    { word: 'aberrancy', word_class: 'N' },
    { word: 'aberrant', word_class: 'AJ' },
    { word: 'aberration', word_class: 'N' }
  ],
  demonstrator: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  unthinking: [
    { word: 'unthinking', word_class: 'AJ' },
    { word: 'unthinking', word_class: 'AV' },
    { word: 'unthinkable', word_class: 'AJ' },
    { word: 'unthinkably', word_class: 'AV' }
  ],
  startle: [
    { word: 'startle', word_class: 'N' },
    { word: 'startle', word_class: 'V' },
    { word: 'startled', word_class: 'AJ' },
    { word: 'startling', word_class: 'AJ' }
  ],
  eats: [
    { word: 'eat', word_class: 'V' },
    { word: 'eats', word_class: 'N' },
    { word: 'eater', word_class: 'N' },
    { word: 'eaten', word_class: 'AJ' },
    { word: 'eating', word_class: 'N' },
    { word: 'edible', word_class: 'N' },
    { word: 'edible', word_class: 'AJ' },
    { word: 'edibility', word_class: 'N' },
    { word: 'edibleness', word_class: 'N' }
  ],
  paginate: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  forsaken: [
    { word: 'forsake', word_class: 'V' },
    { word: 'forsaken', word_class: 'AJ' },
    { word: 'forsaking', word_class: 'N' }
  ],
  shrinkage: [
    { word: 'shrink', word_class: 'N' },
    { word: 'shrink', word_class: 'V' },
    { word: 'shrunk', word_class: 'AJ' },
    { word: 'shrinkage', word_class: 'N' },
    { word: 'shrinking', word_class: 'N' },
    { word: 'shrunken', word_class: 'AJ' }
  ],
  editor: [
    { word: 'edit', word_class: 'V' },
    { word: 'editor', word_class: 'N' },
    { word: 'edited', word_class: 'AJ' },
    { word: 'editing', word_class: 'N' },
    { word: 'edition', word_class: 'N' }
  ],
  admonish: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  lascivious: [
    { word: 'lascivious', word_class: 'AJ' },
    { word: 'lasciviously', word_class: 'AV' },
    { word: 'lasciviousness', word_class: 'N' }
  ],
  flourishing: [
    { word: 'flourish', word_class: 'N' },
    { word: 'flourish', word_class: 'V' },
    { word: 'flourish', word_class: 'AJ' },
    { word: 'flourishing', word_class: 'AJ' }
  ],
  meritable: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  step: [{ word: 'step', word_class: 'N' }, { word: 'step', word_class: 'V' }, { word: 'steps', word_class: 'N' }],
  appeasable: [
    { word: 'appease', word_class: 'V' },
    { word: 'appeaser', word_class: 'N' },
    { word: 'appeasing', word_class: 'AJ' },
    { word: 'appeasable', word_class: 'AJ' },
    { word: 'appeasement', word_class: 'N' }
  ],
  gill: [{ word: 'gill', word_class: 'N' }, { word: 'gill', word_class: 'V' }, { word: 'gilled', word_class: 'AJ' }],
  devalued: [
    { word: 'devalue', word_class: 'V' },
    { word: 'devaluate', word_class: 'V' },
    { word: 'devalued', word_class: 'AJ' },
    { word: 'devaluation', word_class: 'N' }
  ],
  blower: [
    { word: 'blow', word_class: 'N' },
    { word: 'blow', word_class: 'V' },
    { word: 'blower', word_class: 'N' },
    { word: 'blown', word_class: 'AJ' },
    { word: 'blowing', word_class: 'N' }
  ],
  heterologic: [
    { word: 'heterologic', word_class: 'AJ' },
    { word: 'heterologous', word_class: 'AJ' },
    { word: 'heterological', word_class: 'AJ' }
  ],
  delegate: [
    { word: 'delegate', word_class: 'N' },
    { word: 'delegate', word_class: 'V' },
    { word: 'delegable', word_class: 'AJ' },
    { word: 'delegating', word_class: 'N' },
    { word: 'delegation', word_class: 'N' }
  ],
  mad: [{ word: 'mad', word_class: 'AJ' }, { word: 'madness', word_class: 'N' }, { word: 'bemadden', word_class: 'V' }],
  enmeshed: [
    { word: 'enmesh', word_class: 'V' },
    { word: 'enmeshed', word_class: 'AJ' },
    { word: 'enmeshment', word_class: 'N' }
  ],
  consignee: [
    { word: 'consign', word_class: 'V' },
    { word: 'consignee', word_class: 'N' },
    { word: 'consigner', word_class: 'N' },
    { word: 'consignor', word_class: 'N' },
    { word: 'consignment', word_class: 'N' },
    { word: 'consignation', word_class: 'N' }
  ],
  rowdy: [
    { word: 'rowdy', word_class: 'N' },
    { word: 'rowdy', word_class: 'AJ' },
    { word: 'rowdiness', word_class: 'N' }
  ],
  ignitable: [
    { word: 'ignite', word_class: 'V' },
    { word: 'igniter', word_class: 'N' },
    { word: 'ignitor', word_class: 'N' },
    { word: 'ignited', word_class: 'AJ' },
    { word: 'ignition', word_class: 'N' },
    { word: 'ignitable', word_class: 'AJ' },
    { word: 'ignitible', word_class: 'AJ' }
  ],
  baptize: [
    { word: 'baptise', word_class: 'V' },
    { word: 'baptism', word_class: 'N' },
    { word: 'baptist', word_class: 'N' },
    { word: 'baptize', word_class: 'V' },
    { word: 'baptist', word_class: 'AJ' },
    { word: 'baptists', word_class: 'N' },
    { word: 'baptismal', word_class: 'AJ' }
  ],
  lard: [{ word: 'lard', word_class: 'N' }, { word: 'lard', word_class: 'V' }, { word: 'larder', word_class: 'N' }],
  receptively: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  chromatographically: [
    { word: 'chromatographic', word_class: 'AJ' },
    { word: 'chromatographical', word_class: 'AJ' },
    { word: 'chromatographically', word_class: 'AV' }
  ],
  bleeder: [
    { word: 'bleed', word_class: 'V' },
    { word: 'bleeder', word_class: 'N' },
    { word: 'bleeding', word_class: 'N' },
    { word: 'bleeding', word_class: 'AJ' }
  ],
  wassail: [
    { word: 'wassail', word_class: 'N' },
    { word: 'wassail', word_class: 'V' },
    { word: 'wassailer', word_class: 'N' }
  ],
  palatableness: [
    { word: 'palate', word_class: 'N' },
    { word: 'palatal', word_class: 'N' },
    { word: 'palatal', word_class: 'AJ' },
    { word: 'palatable', word_class: 'AJ' },
    { word: 'palatably', word_class: 'AV' },
    { word: 'palatalise', word_class: 'V' },
    { word: 'palatalize', word_class: 'V' },
    { word: 'palatability', word_class: 'N' },
    { word: 'palatalized', word_class: 'AJ' },
    { word: 'palatableness', word_class: 'N' },
    { word: 'palatalisation', word_class: 'N' },
    { word: 'palatalization', word_class: 'N' }
  ],
  antagonizing: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  secularize: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  auspicate: [
    { word: 'auspice', word_class: 'N' },
    { word: 'auspices', word_class: 'N' },
    { word: 'auspicate', word_class: 'V' }
  ],
  secularist: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  reversibly: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  leathered: [
    { word: 'leather', word_class: 'N' },
    { word: 'leather', word_class: 'V' },
    { word: 'leathered', word_class: 'AJ' }
  ],
  ecological: [
    { word: 'ecology', word_class: 'N' },
    { word: 'ecologic', word_class: 'AJ' },
    { word: 'ecological', word_class: 'AJ' },
    { word: 'ecologically', word_class: 'AV' }
  ],
  ideologic: [
    { word: 'ideology', word_class: 'N' },
    { word: 'ideologic', word_class: 'AJ' },
    { word: 'ideological', word_class: 'AJ' },
    { word: 'ideologically', word_class: 'AV' }
  ],
  soliloquy: [
    { word: 'soliloquy', word_class: 'N' },
    { word: 'soliloquize', word_class: 'V' },
    { word: 'soliloquization', word_class: 'N' }
  ],
  score: [
    { word: 'score', word_class: 'N' },
    { word: 'score', word_class: 'V' },
    { word: 'score', word_class: 'AJ' },
    { word: 'scorer', word_class: 'N' },
    { word: 'scores', word_class: 'N' }
  ],
  wait: [
    { word: 'wait', word_class: 'N' },
    { word: 'wait', word_class: 'V' },
    { word: 'await', word_class: 'V' },
    { word: 'waiter', word_class: 'N' },
    { word: 'waiting', word_class: 'N' },
    { word: 'awaited', word_class: 'AJ' },
    { word: 'waiting', word_class: 'AJ' },
    { word: 'waitress', word_class: 'N' }
  ],
  action: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  poleaxe: [
    { word: 'poleax', word_class: 'N' },
    { word: 'poleax', word_class: 'V' },
    { word: 'poleaxe', word_class: 'N' },
    { word: 'poleaxe', word_class: 'V' }
  ],
  refractory: [
    { word: 'refractory', word_class: 'N' },
    { word: 'refractory', word_class: 'AJ' },
    { word: 'refractoriness', word_class: 'N' }
  ],
  prefixture: [
    { word: 'prefix', word_class: 'N' },
    { word: 'prefix', word_class: 'V' },
    { word: 'prefixture', word_class: 'N' }
  ],
  watchful: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  crude: [
    { word: 'crude', word_class: 'N' },
    { word: 'crude', word_class: 'AJ' },
    { word: 'crudely', word_class: 'AV' },
    { word: 'crudeness', word_class: 'N' }
  ],
  coward: [
    { word: 'coward', word_class: 'N' },
    { word: 'coward', word_class: 'V' },
    { word: 'coward', word_class: 'AJ' }
  ],
  slumberous: [
    { word: 'slumber', word_class: 'N' },
    { word: 'slumber', word_class: 'V' },
    { word: 'slumberer', word_class: 'N' },
    { word: 'slumbering', word_class: 'AJ' },
    { word: 'slumberous', word_class: 'AJ' }
  ],
  conventionalise: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  negating: [
    { word: 'negate', word_class: 'V' },
    { word: 'negating', word_class: 'N' },
    { word: 'negation', word_class: 'N' }
  ],
  potful: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  warming: [
    { word: 'warm', word_class: 'N' },
    { word: 'warm', word_class: 'V' },
    { word: 'warm', word_class: 'AJ' },
    { word: 'warmth', word_class: 'N' },
    { word: 'warmer', word_class: 'N' },
    { word: 'warmed', word_class: 'AJ' },
    { word: 'warming', word_class: 'N' },
    { word: 'warming', word_class: 'AJ' },
    { word: 'warmness', word_class: 'N' }
  ],
  efficiency: [
    { word: 'efficiency', word_class: 'N' },
    { word: 'efficient', word_class: 'AJ' },
    { word: 'efficiently', word_class: 'AV' }
  ],
  betrayer: [
    { word: 'betray', word_class: 'V' },
    { word: 'betrayal', word_class: 'N' },
    { word: 'betrayer', word_class: 'N' },
    { word: 'betraying', word_class: 'AJ' }
  ],
  disclaim: [
    { word: 'disclaim', word_class: 'V' },
    { word: 'disclaimer', word_class: 'N' },
    { word: 'disclamation', word_class: 'N' }
  ],
  impeach: [
    { word: 'impeach', word_class: 'N' },
    { word: 'impeach', word_class: 'V' },
    { word: 'impeachment', word_class: 'N' },
    { word: 'impeachability', word_class: 'N' }
  ],
  uncleared: [
    { word: 'unclear', word_class: 'AJ' },
    { word: 'uncleared', word_class: 'AJ' },
    { word: 'unclearness', word_class: 'N' }
  ],
  usurpation: [
    { word: 'usurp', word_class: 'V' },
    { word: 'usurper', word_class: 'N' },
    { word: 'usurped', word_class: 'AJ' },
    { word: 'usurpation', word_class: 'N' }
  ],
  subtropic: [
    { word: 'subtropic', word_class: 'AJ' },
    { word: 'subtropics', word_class: 'N' },
    { word: 'subtropical', word_class: 'AJ' }
  ],
  desulphurise: [
    { word: 'desulphurise', word_class: 'V' },
    { word: 'desulphurize', word_class: 'V' },
    { word: 'desulphurisation', word_class: 'N' },
    { word: 'desulphurization', word_class: 'N' }
  ],
  statistically: [
    { word: 'statistic', word_class: 'N' },
    { word: 'statistic', word_class: 'AJ' },
    { word: 'statistics', word_class: 'N' },
    { word: 'statistical', word_class: 'AJ' },
    { word: 'statistically', word_class: 'AV' }
  ],
  heavenward: [
    { word: 'heavenward', word_class: 'AJ' },
    { word: 'heavenward', word_class: 'AV' },
    { word: 'heavenwards', word_class: 'AV' }
  ],
  equal: [
    { word: 'equal', word_class: 'N' },
    { word: 'equal', word_class: 'V' },
    { word: 'equal', word_class: 'AJ' },
    { word: 'equalise', word_class: 'V' },
    { word: 'equality', word_class: 'N' },
    { word: 'equalize', word_class: 'V' },
    { word: 'equally', word_class: 'AV' },
    { word: 'equaliser', word_class: 'N' },
    { word: 'equalizer', word_class: 'N' },
    { word: 'equalised', word_class: 'AJ' },
    { word: 'equalized', word_class: 'AJ' },
    { word: 'equalisation', word_class: 'N' },
    { word: 'equalization', word_class: 'N' }
  ],
  natural: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  boxer: [
    { word: 'box', word_class: 'N' },
    { word: 'box', word_class: 'V' },
    { word: 'boxer', word_class: 'N' },
    { word: 'boxed', word_class: 'AJ' },
    { word: 'boxful', word_class: 'N' },
    { word: 'boxing', word_class: 'N' },
    { word: 'boxful', word_class: 'AJ' }
  ],
  vomiturition: [
    { word: 'vomit', word_class: 'N' },
    { word: 'vomit', word_class: 'V' },
    { word: 'vomiting', word_class: 'N' },
    { word: 'vomitive', word_class: 'N' },
    { word: 'vomiturition', word_class: 'N' }
  ],
  banish: [
    { word: 'banish', word_class: 'V' },
    { word: 'banish', word_class: 'AJ' },
    { word: 'banishment', word_class: 'N' }
  ],
  incised: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  carnalize: [
    { word: 'carnal', word_class: 'AJ' },
    { word: 'carnality', word_class: 'N' },
    { word: 'carnalize', word_class: 'V' },
    { word: 'carnally', word_class: 'AV' }
  ],
  deflated: [
    { word: 'deflate', word_class: 'V' },
    { word: 'deflator', word_class: 'N' },
    { word: 'deflated', word_class: 'AJ' },
    { word: 'deflation', word_class: 'N' },
    { word: 'deflationary', word_class: 'AJ' }
  ],
  subside: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  laden: [
    { word: 'lade', word_class: 'V' },
    { word: 'laden', word_class: 'V' },
    { word: 'laden', word_class: 'AJ' },
    { word: 'lading', word_class: 'N' },
    { word: 'ladened', word_class: 'AJ' }
  ],
  administrable: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  investigative: [
    { word: 'investigate', word_class: 'V' },
    { word: 'investigator', word_class: 'N' },
    { word: 'investigating', word_class: 'N' },
    { word: 'investigation', word_class: 'N' },
    { word: 'investigative', word_class: 'AJ' }
  ],
  instructional: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  signal: [
    { word: 'sign', word_class: 'N' },
    { word: 'sign', word_class: 'V' },
    { word: 'sign', word_class: 'AJ' },
    { word: 'signal', word_class: 'N' },
    { word: 'signal', word_class: 'V' },
    { word: 'signer', word_class: 'N' },
    { word: 'signor', word_class: 'N' },
    { word: 'signal', word_class: 'AJ' },
    { word: 'signed', word_class: 'AJ' },
    { word: 'signing', word_class: 'N' },
    { word: 'signaler', word_class: 'N' },
    { word: 'signalize', word_class: 'V' },
    { word: 'signaling', word_class: 'N' },
    { word: 'signature', word_class: 'N' },
    { word: 'signalman', word_class: 'N' },
    { word: 'signaller', word_class: 'N' },
    { word: 'signally', word_class: 'AV' },
    { word: 'signalization', word_class: 'N' }
  ],
  roar: [
    { word: 'roar', word_class: 'N' },
    { word: 'roar', word_class: 'V' },
    { word: 'roarer', word_class: 'N' },
    { word: 'roaring', word_class: 'N' },
    { word: 'roaring', word_class: 'AJ' },
    { word: 'roaring', word_class: 'AV' }
  ],
  debaucher: [
    { word: 'debauch', word_class: 'N' },
    { word: 'debauch', word_class: 'V' },
    { word: 'debauchee', word_class: 'N' },
    { word: 'debaucher', word_class: 'N' },
    { word: 'debauched', word_class: 'AJ' },
    { word: 'debauchery', word_class: 'N' },
    { word: 'debauchery', word_class: 'AJ' }
  ],
  interrogative: [
    { word: 'interrogate', word_class: 'V' },
    { word: 'interrogator', word_class: 'N' },
    { word: 'interrogation', word_class: 'N' },
    { word: 'interrogative', word_class: 'N' },
    { word: 'interrogative', word_class: 'AJ' },
    { word: 'interrogatively', word_class: 'AV' }
  ],
  yellow: [
    { word: 'yellow', word_class: 'N' },
    { word: 'yellow', word_class: 'V' },
    { word: 'yellow', word_class: 'AJ' },
    { word: 'yellowed', word_class: 'AJ' },
    { word: 'yellowness', word_class: 'N' }
  ],
  unrighteous: [
    { word: 'unrighteous', word_class: 'AJ' },
    { word: 'unrighteously', word_class: 'AV' },
    { word: 'unrighteousness', word_class: 'N' }
  ],
  demonetisation: [
    { word: 'demonetise', word_class: 'V' },
    { word: 'demonetize', word_class: 'V' },
    { word: 'demonetisation', word_class: 'N' },
    { word: 'demonetization', word_class: 'N' }
  ],
  revenge: [
    { word: 'revenge', word_class: 'N' },
    { word: 'revenge', word_class: 'V' },
    { word: 'revengeful', word_class: 'AJ' }
  ],
  tinful: [
    { word: 'tin', word_class: 'N' },
    { word: 'tin', word_class: 'V' },
    { word: 'tinful', word_class: 'N' },
    { word: 'tinful', word_class: 'AJ' },
    { word: 'tinned', word_class: 'AJ' },
    { word: 'tinning', word_class: 'N' }
  ],
  inhumane: [
    { word: 'inhuman', word_class: 'AJ' },
    { word: 'inhumane', word_class: 'AJ' },
    { word: 'inhumanity', word_class: 'N' },
    { word: 'inhumanely', word_class: 'AV' },
    { word: 'inhumaneness', word_class: 'N' }
  ],
  dislodge: [
    { word: 'dislodge', word_class: 'V' },
    { word: 'dislodgment', word_class: 'N' },
    { word: 'dislodgement', word_class: 'N' }
  ],
  tyrannical: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  fulsomeness: [
    { word: 'fulsome', word_class: 'AJ' },
    { word: 'fulsomely', word_class: 'AV' },
    { word: 'fulsomeness', word_class: 'N' }
  ],
  parcelling: [
    { word: 'parcel', word_class: 'N' },
    { word: 'parcel', word_class: 'V' },
    { word: 'parceling', word_class: 'N' },
    { word: 'parcelling', word_class: 'N' }
  ],
  rontgenisation: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  tops: [
    { word: 'top', word_class: 'N' },
    { word: 'top', word_class: 'V' },
    { word: 'top', word_class: 'AJ' },
    { word: 'tope', word_class: 'V' },
    { word: 'topee', word_class: 'N' },
    { word: 'toper', word_class: 'N' },
    { word: 'tops', word_class: 'AJ' },
    { word: 'topped', word_class: 'AJ' },
    { word: 'topping', word_class: 'N' },
    { word: 'topping', word_class: 'AJ' }
  ],
  convent: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  womanizer: [
    { word: 'woman', word_class: 'N' },
    { word: 'womanise', word_class: 'V' },
    { word: 'womanize', word_class: 'V' },
    { word: 'womaniser', word_class: 'N' },
    { word: 'womanizer', word_class: 'N' },
    { word: 'womanization', word_class: 'N' }
  ],
  icelander: [
    { word: 'iceland', word_class: 'N' },
    { word: 'icelandic', word_class: 'N' },
    { word: 'icelander', word_class: 'N' },
    { word: 'icelandic', word_class: 'AJ' }
  ],
  sized: [
    { word: 'size', word_class: 'N' },
    { word: 'size', word_class: 'V' },
    { word: 'size', word_class: 'AJ' },
    { word: 'sized', word_class: 'AJ' },
    { word: 'sizing', word_class: 'N' }
  ],
  plunger: [
    { word: 'plunge', word_class: 'N' },
    { word: 'plunge', word_class: 'V' },
    { word: 'plunger', word_class: 'N' }
  ],
  exhibition: [
    { word: 'exhibit', word_class: 'N' },
    { word: 'exhibit', word_class: 'V' },
    { word: 'exhibitor', word_class: 'N' },
    { word: 'exhibition', word_class: 'N' }
  ],
  accommodative: [
    { word: 'accommodate', word_class: 'V' },
    { word: 'accommodation', word_class: 'N' },
    { word: 'accommodating', word_class: 'AJ' },
    { word: 'accommodative', word_class: 'AJ' },
    { word: 'accommodational', word_class: 'AJ' }
  ],
  clash: [
    { word: 'clash', word_class: 'N' },
    { word: 'clash', word_class: 'V' },
    { word: 'clashing', word_class: 'AJ' }
  ],
  skivvies: [
    { word: 'skivvy', word_class: 'N' },
    { word: 'skivvy', word_class: 'V' },
    { word: 'skivvies', word_class: 'N' }
  ],
  subdivision: [
    { word: 'subdivide', word_class: 'V' },
    { word: 'subdivided', word_class: 'AJ' },
    { word: 'subdivision', word_class: 'N' }
  ],
  retrenchment: [
    { word: 'retrench', word_class: 'N' },
    { word: 'retrench', word_class: 'V' },
    { word: 'retrenchment', word_class: 'N' }
  ],
  malodorousness: [
    { word: 'malodor', word_class: 'N' },
    { word: 'malodorous', word_class: 'AJ' },
    { word: 'malodorousness', word_class: 'N' }
  ],
  radically: [
    { word: 'radical', word_class: 'N' },
    { word: 'radical', word_class: 'AJ' },
    { word: 'radicalism', word_class: 'N' },
    { word: 'radically', word_class: 'AV' }
  ],
  troublesome: [
    { word: 'trouble', word_class: 'N' },
    { word: 'trouble', word_class: 'V' },
    { word: 'troubled', word_class: 'AJ' },
    { word: 'troubling', word_class: 'N' },
    { word: 'troubling', word_class: 'AJ' },
    { word: 'troublesome', word_class: 'AJ' },
    { word: 'troublesomeness', word_class: 'N' }
  ],
  synchronize: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  consumable: [
    { word: 'consume', word_class: 'V' },
    { word: 'consumer', word_class: 'N' },
    { word: 'consumed', word_class: 'AJ' },
    { word: 'consuming', word_class: 'AJ' },
    { word: 'consumable', word_class: 'AJ' },
    { word: 'consumption', word_class: 'N' },
    { word: 'consumptive', word_class: 'N' },
    { word: 'consumptive', word_class: 'AJ' }
  ],
  keeping: [
    { word: 'keep', word_class: 'N' },
    { word: 'keep', word_class: 'V' },
    { word: 'keeper', word_class: 'N' },
    { word: 'keeping', word_class: 'N' }
  ],
  diplomat: [
    { word: 'diplomat', word_class: 'N' },
    { word: 'diplomatic', word_class: 'AJ' },
    { word: 'diplomatical', word_class: 'AJ' },
    { word: 'diplomatically', word_class: 'AV' }
  ],
  composite: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  educe: [
    { word: 'educe', word_class: 'V' },
    { word: 'educate', word_class: 'V' },
    { word: 'educatee', word_class: 'N' },
    { word: 'eduction', word_class: 'N' },
    { word: 'educator', word_class: 'N' },
    { word: 'educable', word_class: 'AJ' },
    { word: 'educated', word_class: 'AJ' },
    { word: 'education', word_class: 'N' },
    { word: 'educative', word_class: 'AJ' },
    { word: 'educational', word_class: 'AJ' }
  ],
  odds: [{ word: 'odd', word_class: 'AJ' }, { word: 'odds', word_class: 'N' }, { word: 'oddness', word_class: 'N' }],
  literalize: [
    { word: 'liter', word_class: 'N' },
    { word: 'literal', word_class: 'N' },
    { word: 'literacy', word_class: 'N' },
    { word: 'literal', word_class: 'AJ' },
    { word: 'literate', word_class: 'N' },
    { word: 'literate', word_class: 'AJ' },
    { word: 'literalism', word_class: 'N' },
    { word: 'literalize', word_class: 'V' },
    { word: 'literally', word_class: 'AV' },
    { word: 'literalness', word_class: 'N' }
  ],
  diminutiveness: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  cogitative: [
    { word: 'cogitate', word_class: 'V' },
    { word: 'cogitable', word_class: 'AJ' },
    { word: 'cogitation', word_class: 'N' },
    { word: 'cogitative', word_class: 'AJ' }
  ],
  collapsed: [
    { word: 'collapse', word_class: 'N' },
    { word: 'collapse', word_class: 'V' },
    { word: 'collapsed', word_class: 'AJ' },
    { word: 'collapsable', word_class: 'AJ' },
    { word: 'collapsible', word_class: 'AJ' }
  ],
  glorification: [
    { word: 'glory', word_class: 'N' },
    { word: 'glory', word_class: 'V' },
    { word: 'glorify', word_class: 'V' },
    { word: 'glorified', word_class: 'AJ' },
    { word: 'glorification', word_class: 'N' }
  ],
  barbel: [
    { word: 'barbel', word_class: 'N' },
    { word: 'barbell', word_class: 'N' },
    { word: 'barbellate', word_class: 'AJ' }
  ],
  recessive: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  shrivelled: [
    { word: 'shrivel', word_class: 'V' },
    { word: 'shriveled', word_class: 'AJ' },
    { word: 'shrivelled', word_class: 'AJ' }
  ],
  massively: [
    { word: 'massive', word_class: 'AJ' },
    { word: 'massively', word_class: 'AV' },
    { word: 'massiveness', word_class: 'N' }
  ],
  disillusionment: [
    { word: 'disillusion', word_class: 'N' },
    { word: 'disillusion', word_class: 'V' },
    { word: 'disillusioned', word_class: 'AJ' },
    { word: 'disillusioning', word_class: 'AJ' },
    { word: 'disillusionment', word_class: 'N' }
  ],
  alimentative: [
    { word: 'aliment', word_class: 'N' },
    { word: 'aliment', word_class: 'V' },
    { word: 'alimentation', word_class: 'N' },
    { word: 'alimentative', word_class: 'AJ' }
  ],
  muscled: [
    { word: 'muscle', word_class: 'N' },
    { word: 'muscle', word_class: 'V' },
    { word: 'muscled', word_class: 'AJ' },
    { word: 'muscular', word_class: 'AJ' },
    { word: 'musculature', word_class: 'N' }
  ],
  private: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  abnegation: [
    { word: 'abnegate', word_class: 'V' },
    { word: 'abnegator', word_class: 'N' },
    { word: 'abnegation', word_class: 'N' }
  ],
  prevaricator: [
    { word: 'prevaricate', word_class: 'V' },
    { word: 'prevaricator', word_class: 'N' },
    { word: 'prevarication', word_class: 'N' }
  ],
  wader: [{ word: 'wade', word_class: 'V' }, { word: 'wader', word_class: 'N' }, { word: 'wading', word_class: 'N' }],
  philistine: [
    { word: 'philistine', word_class: 'N' },
    { word: 'philistine', word_class: 'AJ' },
    { word: 'philistinism', word_class: 'N' }
  ],
  romanism: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  several: [
    { word: 'sever', word_class: 'V' },
    { word: 'several', word_class: 'N' },
    { word: 'severe', word_class: 'AJ' },
    { word: 'several', word_class: 'AJ' },
    { word: 'severed', word_class: 'AJ' },
    { word: 'severing', word_class: 'N' },
    { word: 'severity', word_class: 'N' },
    { word: 'severance', word_class: 'N' },
    { word: 'severely', word_class: 'AV' },
    { word: 'severable', word_class: 'AJ' },
    { word: 'severally', word_class: 'AV' },
    { word: 'severality', word_class: 'N' },
    { word: 'severalize', word_class: 'V' },
    { word: 'severeness', word_class: 'N' }
  ],
  worsen: [
    { word: 'worsen', word_class: 'V' },
    { word: 'worsened', word_class: 'AJ' },
    { word: 'worsening', word_class: 'N' },
    { word: 'worsening', word_class: 'AJ' }
  ],
  absorbing: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  secondment: [
    { word: 'second', word_class: 'N' },
    { word: 'second', word_class: 'V' },
    { word: 'second', word_class: 'AJ' },
    { word: 'second', word_class: 'AV' },
    { word: 'seconder', word_class: 'N' },
    { word: 'secondary', word_class: 'N' },
    { word: 'secondary', word_class: 'AJ' },
    { word: 'secondment', word_class: 'N' }
  ],
  quartering: [
    { word: 'quarter', word_class: 'N' },
    { word: 'quarter', word_class: 'V' },
    { word: 'quarter', word_class: 'AJ' },
    { word: 'quarters', word_class: 'N' },
    { word: 'quartering', word_class: 'N' }
  ],
  paralyse: [
    { word: 'paralyse', word_class: 'V' },
    { word: 'paralyze', word_class: 'V' },
    { word: 'paralysis', word_class: 'N' },
    { word: 'paralyzed', word_class: 'AJ' },
    { word: 'paralysation', word_class: 'N' },
    { word: 'paralyzation', word_class: 'N' }
  ],
  dissemble: [
    { word: 'dissemble', word_class: 'V' },
    { word: 'dissembler', word_class: 'N' },
    { word: 'dissembling', word_class: 'N' },
    { word: 'dissemblance', word_class: 'N' },
    { word: 'dissembling', word_class: 'AJ' }
  ],
  opinionated: [
    { word: 'opine', word_class: 'V' },
    { word: 'opinion', word_class: 'N' },
    { word: 'opinionated', word_class: 'AJ' },
    { word: 'opinionative', word_class: 'AJ' }
  ],
  afflictive: [
    { word: 'afflict', word_class: 'V' },
    { word: 'afflicted', word_class: 'AJ' },
    { word: 'affliction', word_class: 'N' },
    { word: 'afflictive', word_class: 'AJ' }
  ],
  flexible: [
    { word: 'flexible', word_class: 'AJ' },
    { word: 'flexibly', word_class: 'AV' },
    { word: 'flexibility', word_class: 'N' },
    { word: 'flexibleness', word_class: 'N' }
  ],
  swell: [
    { word: 'swell', word_class: 'N' },
    { word: 'swell', word_class: 'V' },
    { word: 'swell', word_class: 'AJ' },
    { word: 'swelled', word_class: 'AJ' },
    { word: 'swelling', word_class: 'N' },
    { word: 'swollen', word_class: 'AJ' },
    { word: 'swelling', word_class: 'AJ' }
  ],
  droppings: [
    { word: 'drop', word_class: 'N' },
    { word: 'drop', word_class: 'V' },
    { word: 'dropped', word_class: 'AJ' },
    { word: 'dropping', word_class: 'N' },
    { word: 'dropping', word_class: 'AJ' },
    { word: 'droppings', word_class: 'N' }
  ],
  ellipticity: [
    { word: 'elliptic', word_class: 'AJ' },
    { word: 'elliptical', word_class: 'AJ' },
    { word: 'ellipticity', word_class: 'N' }
  ],
  'self-assertion': [
    { word: 'self-assertion', word_class: 'N' },
    { word: 'self-asserting', word_class: 'AJ' },
    { word: 'self-assertive', word_class: 'AJ' },
    { word: 'self-assertiveness', word_class: 'N' }
  ],
  paganise: [
    { word: 'pagan', word_class: 'N' },
    { word: 'pagan', word_class: 'AJ' },
    { word: 'paganise', word_class: 'V' },
    { word: 'paganize', word_class: 'V' },
    { word: 'paganism', word_class: 'N' },
    { word: 'paganisation', word_class: 'N' },
    { word: 'paganization', word_class: 'N' }
  ],
  peculation: [
    { word: 'peculate', word_class: 'V' },
    { word: 'peculator', word_class: 'N' },
    { word: 'peculation', word_class: 'N' }
  ],
  puddle: [
    { word: 'puddle', word_class: 'N' },
    { word: 'puddle', word_class: 'V' },
    { word: 'puddler', word_class: 'N' },
    { word: 'puddly', word_class: 'AJ' }
  ],
  crystal: [
    { word: 'crystal', word_class: 'N' },
    { word: 'crystalize', word_class: 'V' },
    { word: 'crystallize', word_class: 'V' },
    { word: 'crystalized', word_class: 'AJ' },
    { word: 'crystalline', word_class: 'AJ' },
    { word: 'crystallized', word_class: 'AJ' },
    { word: 'crystalization', word_class: 'N' },
    { word: 'crystallization', word_class: 'N' }
  ],
  colonic: [
    { word: 'colon', word_class: 'N' },
    { word: 'colonic', word_class: 'N' },
    { word: 'colonize', word_class: 'V' },
    { word: 'colonist', word_class: 'N' },
    { word: 'colonic', word_class: 'AJ' },
    { word: 'colonizer', word_class: 'N' },
    { word: 'colonized', word_class: 'AJ' },
    { word: 'colonization', word_class: 'N' }
  ],
  rationalistic: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  bicyclic: [
    { word: 'bicycle', word_class: 'N' },
    { word: 'bicycle', word_class: 'V' },
    { word: 'bicyclic', word_class: 'AJ' },
    { word: 'bicycling', word_class: 'N' },
    { word: 'bicyclist', word_class: 'N' }
  ],
  prospective: [
    { word: 'prospect', word_class: 'N' },
    { word: 'prospect', word_class: 'V' },
    { word: 'prospector', word_class: 'N' },
    { word: 'prospective', word_class: 'AJ' }
  ],
  cementation: [
    { word: 'cement', word_class: 'N' },
    { word: 'cement', word_class: 'V' },
    { word: 'cementite', word_class: 'N' },
    { word: 'cementation', word_class: 'N' }
  ],
  authoritarianism: [
    { word: 'author', word_class: 'N' },
    { word: 'author', word_class: 'V' },
    { word: 'authorise', word_class: 'V' },
    { word: 'authority', word_class: 'N' },
    { word: 'authorize', word_class: 'V' },
    { word: 'authorities', word_class: 'N' },
    { word: 'authorized', word_class: 'AJ' },
    { word: 'authoritarian', word_class: 'N' },
    { word: 'authorisation', word_class: 'N' },
    { word: 'authorization', word_class: 'N' },
    { word: 'authoritarian', word_class: 'AJ' },
    { word: 'authoritative', word_class: 'AJ' },
    { word: 'authoritarianism', word_class: 'N' },
    { word: 'authoritatively', word_class: 'AV' }
  ],
  descendants: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  accountant: [
    { word: 'account', word_class: 'N' },
    { word: 'account', word_class: 'V' },
    { word: 'accounting', word_class: 'N' },
    { word: 'accountant', word_class: 'N' },
    { word: 'accountancy', word_class: 'N' },
    { word: 'accountable', word_class: 'AJ' },
    { word: 'accountability', word_class: 'N' }
  ],
  sulfonation: [
    { word: 'sulfonate', word_class: 'N' },
    { word: 'sulfonate', word_class: 'V' },
    { word: 'sulphonate', word_class: 'V' },
    { word: 'sulfonation', word_class: 'N' },
    { word: 'sulphonation', word_class: 'N' }
  ],
  catabolism: [
    { word: 'catabolic', word_class: 'AJ' },
    { word: 'catabolism', word_class: 'N' },
    { word: 'catabolize', word_class: 'V' }
  ],
  paramagnet: [
    { word: 'paramagnet', word_class: 'N' },
    { word: 'paramagnetic', word_class: 'AJ' },
    { word: 'paramagnetism', word_class: 'N' }
  ],
  seise: [
    { word: 'seise', word_class: 'V' },
    { word: 'seize', word_class: 'V' },
    { word: 'seizer', word_class: 'N' },
    { word: 'seized', word_class: 'AJ' },
    { word: 'seizing', word_class: 'N' },
    { word: 'seizure', word_class: 'N' }
  ],
  stupid: [
    { word: 'stupid', word_class: 'N' },
    { word: 'stupid', word_class: 'AJ' },
    { word: 'stupidity', word_class: 'N' }
  ],
  imaging: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  smelling: [
    { word: 'smell', word_class: 'N' },
    { word: 'smell', word_class: 'V' },
    { word: 'smelling', word_class: 'N' },
    { word: 'smelling', word_class: 'AJ' }
  ],
  publication: [
    { word: 'public', word_class: 'N' },
    { word: 'public', word_class: 'AJ' },
    { word: 'publish', word_class: 'V' },
    { word: 'publicise', word_class: 'V' },
    { word: 'publicist', word_class: 'N' },
    { word: 'publicize', word_class: 'V' },
    { word: 'publicity', word_class: 'N' },
    { word: 'publisher', word_class: 'N' },
    { word: 'publicizer', word_class: 'N' },
    { word: 'published', word_class: 'AJ' },
    { word: 'publishing', word_class: 'N' },
    { word: 'publically', word_class: 'AV' },
    { word: 'publication', word_class: 'N' },
    { word: 'publicized', word_class: 'AJ' },
    { word: 'publicizing', word_class: 'N' },
    { word: 'publishable', word_class: 'AJ' },
    { word: 'publicization', word_class: 'N' }
  ],
  obverse: [
    { word: 'obvert', word_class: 'V' },
    { word: 'obverse', word_class: 'N' },
    { word: 'obverse', word_class: 'AJ' },
    { word: 'obversion', word_class: 'N' }
  ],
  better: [
    { word: 'better', word_class: 'N' },
    { word: 'better', word_class: 'V' },
    { word: 'better', word_class: 'AJ' },
    { word: 'better', word_class: 'AV' },
    { word: 'bettering', word_class: 'AJ' },
    { word: 'betterment', word_class: 'N' }
  ],
  submersible: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  eructation: [
    { word: 'eruct', word_class: 'V' },
    { word: 'eructate', word_class: 'V' },
    { word: 'eructation', word_class: 'N' }
  ],
  onerously: [
    { word: 'onerous', word_class: 'AJ' },
    { word: 'onerously', word_class: 'AV' },
    { word: 'onerousness', word_class: 'N' }
  ],
  resilement: [
    { word: 'resile', word_class: 'V' },
    { word: 'resilement', word_class: 'N' },
    { word: 'resilience', word_class: 'N' },
    { word: 'resiliency', word_class: 'N' },
    { word: 'resilient', word_class: 'AJ' }
  ],
  neurobiology: [
    { word: 'neurobiology', word_class: 'N' },
    { word: 'neurobiological', word_class: 'AJ' },
    { word: 'neurobiological', word_class: 'AV' }
  ],
  twinkling: [
    { word: 'twinkle', word_class: 'N' },
    { word: 'twinkle', word_class: 'V' },
    { word: 'twinkly', word_class: 'AJ' },
    { word: 'twinkling', word_class: 'N' },
    { word: 'twinkling', word_class: 'AJ' }
  ],
  shoplifter: [
    { word: 'shoplift', word_class: 'V' },
    { word: 'shoplifter', word_class: 'N' },
    { word: 'shoplifting', word_class: 'N' }
  ],
  highland: [
    { word: 'highland', word_class: 'N' },
    { word: 'highland', word_class: 'AJ' },
    { word: 'highlands', word_class: 'N' },
    { word: 'highlander', word_class: 'N' }
  ],
  alphabetise: [
    { word: 'alphabet', word_class: 'N' },
    { word: 'alphabetise', word_class: 'V' },
    { word: 'alphabetic', word_class: 'AJ' },
    { word: 'alphabetize', word_class: 'V' },
    { word: 'alphabetical', word_class: 'AJ' },
    { word: 'alphabetized', word_class: 'AJ' },
    { word: 'alphabetically', word_class: 'AV' },
    { word: 'alphabetisation', word_class: 'N' },
    { word: 'alphabetization', word_class: 'N' }
  ],
  axially: [
    { word: 'axis', word_class: 'N' },
    { word: 'axial', word_class: 'AJ' },
    { word: 'axially', word_class: 'AV' }
  ],
  arms: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  materialism: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  creditor: [
    { word: 'credit', word_class: 'N' },
    { word: 'credit', word_class: 'V' },
    { word: 'creditor', word_class: 'N' },
    { word: 'credited', word_class: 'AJ' },
    { word: 'crediting', word_class: 'N' },
    { word: 'creditable', word_class: 'AJ' },
    { word: 'creditably', word_class: 'AV' }
  ],
  'fire-resistive': [
    { word: 'fire-resistant', word_class: 'AJ' },
    { word: 'fire-resisting', word_class: 'AJ' },
    { word: 'fire-resistive', word_class: 'AJ' }
  ],
  blazoning: [
    { word: 'blazon', word_class: 'N' },
    { word: 'blazon', word_class: 'V' },
    { word: 'blazoning', word_class: 'N' },
    { word: 'blazonment', word_class: 'N' }
  ],
  plating: [
    { word: 'plate', word_class: 'N' },
    { word: 'plate', word_class: 'V' },
    { word: 'plating', word_class: 'N' },
    { word: 'plateful', word_class: 'N' },
    { word: 'plateful', word_class: 'AJ' }
  ],
  procrastinator: [
    { word: 'procrastinate', word_class: 'V' },
    { word: 'procrastinator', word_class: 'N' },
    { word: 'procrastination', word_class: 'N' }
  ],
  labor: [
    { word: 'labor', word_class: 'N' },
    { word: 'labor', word_class: 'V' },
    { word: 'laborer', word_class: 'N' },
    { word: 'labored', word_class: 'AJ' },
    { word: 'laboring', word_class: 'AJ' },
    { word: 'laborious', word_class: 'AJ' },
    { word: 'laboriously', word_class: 'AV' },
    { word: 'laboriousness', word_class: 'N' }
  ],
  election: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  invert: [
    { word: 'invert', word_class: 'V' },
    { word: 'inverse', word_class: 'N' },
    { word: 'inverse', word_class: 'V' },
    { word: 'inverse', word_class: 'AJ' },
    { word: 'inverter', word_class: 'N' },
    { word: 'inversion', word_class: 'N' },
    { word: 'inverted', word_class: 'AJ' },
    { word: 'inversely', word_class: 'AV' }
  ],
  sobbing: [{ word: 'sob', word_class: 'N' }, { word: 'sob', word_class: 'V' }, { word: 'sobbing', word_class: 'N' }],
  vanquished: [
    { word: 'vanquish', word_class: 'V' },
    { word: 'vanquisher', word_class: 'N' },
    { word: 'vanquished', word_class: 'AJ' },
    { word: 'vanquishment', word_class: 'N' },
    { word: 'vanquishable', word_class: 'AJ' }
  ],
  callousness: [
    { word: 'calloused', word_class: 'AJ' },
    { word: 'callously', word_class: 'AV' },
    { word: 'callousness', word_class: 'N' }
  ],
  recommended: [
    { word: 'recommend', word_class: 'V' },
    { word: 'recommended', word_class: 'AJ' },
    { word: 'recommendation', word_class: 'N' }
  ],
  refiner: [
    { word: 'refine', word_class: 'N' },
    { word: 'refine', word_class: 'V' },
    { word: 'refine', word_class: 'AJ' },
    { word: 'refiner', word_class: 'N' },
    { word: 'refined', word_class: 'AJ' },
    { word: 'refinery', word_class: 'N' },
    { word: 'refining', word_class: 'N' },
    { word: 'refinance', word_class: 'V' },
    { word: 'refinery', word_class: 'AJ' },
    { word: 'refinement', word_class: 'N' }
  ],
  sculptured: [
    { word: 'sculpt', word_class: 'V' },
    { word: 'sculpted', word_class: 'AJ' },
    { word: 'sculpture', word_class: 'N' },
    { word: 'sculpture', word_class: 'V' },
    { word: 'sculpturer', word_class: 'N' },
    { word: 'sculptural', word_class: 'AJ' },
    { word: 'sculptured', word_class: 'AJ' }
  ],
  ablative: [
    { word: 'abls', word_class: 'N' },
    { word: 'able', word_class: 'AJ' },
    { word: 'ably', word_class: 'AV' },
    { word: 'ability', word_class: 'N' },
    { word: 'ablative', word_class: 'N' },
    { word: 'ablative', word_class: 'AJ' }
  ],
  furtive: [
    { word: 'furtive', word_class: 'AJ' },
    { word: 'furtively', word_class: 'AV' },
    { word: 'furtiveness', word_class: 'N' }
  ],
  cleave: [
    { word: 'cleave', word_class: 'V' },
    { word: 'cleaver', word_class: 'N' },
    { word: 'cloven', word_class: 'AJ' },
    { word: 'cleavers', word_class: 'N' }
  ],
  sponger: [
    { word: 'sponge', word_class: 'N' },
    { word: 'sponge', word_class: 'V' },
    { word: 'sponger', word_class: 'N' },
    { word: 'spongy', word_class: 'AJ' },
    { word: 'sponginess', word_class: 'N' }
  ],
  rebut: [
    { word: 'rebut', word_class: 'V' },
    { word: 'rebuttal', word_class: 'N' },
    { word: 'rebutter', word_class: 'N' }
  ],
  depressed: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  employee: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  distributor: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  confucianism: [
    { word: 'confucian', word_class: 'N' },
    { word: 'confucian', word_class: 'AJ' },
    { word: 'confucianism', word_class: 'N' }
  ],
  solving: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  hyperbole: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  disconcertion: [
    { word: 'disconcert', word_class: 'N' },
    { word: 'disconcert', word_class: 'V' },
    { word: 'disconcerted', word_class: 'AJ' },
    { word: 'disconcertion', word_class: 'N' },
    { word: 'disconcerting', word_class: 'AJ' },
    { word: 'disconcertment', word_class: 'N' }
  ],
  subsidy: [
    { word: 'subside', word_class: 'V' },
    { word: 'subsidy', word_class: 'N' },
    { word: 'subsidize', word_class: 'V' },
    { word: 'subsiding', word_class: 'N' },
    { word: 'subsidise', word_class: 'V' },
    { word: 'subsidence', word_class: 'N' },
    { word: 'subsiding', word_class: 'AJ' },
    { word: 'subsidized', word_class: 'AJ' },
    { word: 'subsidisation', word_class: 'N' },
    { word: 'subsidization', word_class: 'N' }
  ],
  dent: [
    { word: 'dent', word_class: 'N' },
    { word: 'dent', word_class: 'V' },
    { word: 'dented', word_class: 'AJ' },
    { word: 'dentist', word_class: 'N' }
  ],
  disprove: [
    { word: 'disproof', word_class: 'N' },
    { word: 'disprove', word_class: 'V' },
    { word: 'disproval', word_class: 'N' },
    { word: 'disproving', word_class: 'N' }
  ],
  quarrier: [
    { word: 'quarry', word_class: 'N' },
    { word: 'quarry', word_class: 'V' },
    { word: 'quarrier', word_class: 'N' },
    { word: 'quarrying', word_class: 'N' },
    { word: 'quarryman', word_class: 'N' }
  ],
  arborical: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  plumb: [
    { word: 'plumb', word_class: 'N' },
    { word: 'plumb', word_class: 'V' },
    { word: 'plumb', word_class: 'AJ' },
    { word: 'plumb', word_class: 'AV' },
    { word: 'plumber', word_class: 'N' },
    { word: 'plumbed', word_class: 'AJ' },
    { word: 'plumbing', word_class: 'N' },
    { word: 'plumbism', word_class: 'N' }
  ],
  disruption: [
    { word: 'disrupt', word_class: 'V' },
    { word: 'disrupted', word_class: 'AJ' },
    { word: 'disruption', word_class: 'N' },
    { word: 'disruptive', word_class: 'AJ' },
    { word: 'disruptively', word_class: 'AV' }
  ],
  rehabilitation: [
    { word: 'rehabilitate', word_class: 'V' },
    { word: 'rehabilitated', word_class: 'AJ' },
    { word: 'rehabilitation', word_class: 'N' },
    { word: 'rehabilitative', word_class: 'AJ' }
  ],
  beached: [
    { word: 'beach', word_class: 'N' },
    { word: 'beach', word_class: 'V' },
    { word: 'beached', word_class: 'AJ' }
  ],
  mason: [
    { word: 'mason', word_class: 'N' },
    { word: 'masonry', word_class: 'N' },
    { word: 'masonic', word_class: 'AJ' }
  ],
  intangible: [
    { word: 'intangible', word_class: 'N' },
    { word: 'intangible', word_class: 'AJ' },
    { word: 'intangibility', word_class: 'N' },
    { word: 'intangibleness', word_class: 'N' }
  ],
  tease: [
    { word: 'tease', word_class: 'N' },
    { word: 'tease', word_class: 'V' },
    { word: 'teaser', word_class: 'N' },
    { word: 'teased', word_class: 'AJ' },
    { word: 'teasing', word_class: 'N' },
    { word: 'teasing', word_class: 'AJ' }
  ],
  opalescent: [
    { word: 'opalesce', word_class: 'V' },
    { word: 'opalescence', word_class: 'N' },
    { word: 'opalescent', word_class: 'AJ' }
  ],
  rasping: [
    { word: 'rasp', word_class: 'N' },
    { word: 'rasp', word_class: 'V' },
    { word: 'raspy', word_class: 'AJ' },
    { word: 'rasping', word_class: 'N' },
    { word: 'rasping', word_class: 'AJ' }
  ],
  word: [
    { word: 'word', word_class: 'N' },
    { word: 'word', word_class: 'V' },
    { word: 'words', word_class: 'N' },
    { word: 'wording', word_class: 'N' }
  ],
  acuteness: [
    { word: 'acute', word_class: 'N' },
    { word: 'acute', word_class: 'AJ' },
    { word: 'acutely', word_class: 'AV' },
    { word: 'acuteness', word_class: 'N' }
  ],
  temperance: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  premeditated: [
    { word: 'premeditate', word_class: 'V' },
    { word: 'premeditated', word_class: 'AJ' },
    { word: 'premeditation', word_class: 'N' }
  ],
  sapidity: [
    { word: 'sapid', word_class: 'AJ' },
    { word: 'sapidity', word_class: 'N' },
    { word: 'sapidness', word_class: 'N' }
  ],
  iridesce: [
    { word: 'iridesce', word_class: 'V' },
    { word: 'iridescence', word_class: 'N' },
    { word: 'iridescent', word_class: 'AJ' }
  ],
  supplementation: [
    { word: 'supplement', word_class: 'N' },
    { word: 'supplement', word_class: 'V' },
    { word: 'supplemental', word_class: 'AJ' },
    { word: 'supplementary', word_class: 'AJ' },
    { word: 'supplementation', word_class: 'N' }
  ],
  vaginitis: [
    { word: 'vagina', word_class: 'N' },
    { word: 'vaginal', word_class: 'AJ' },
    { word: 'vaginitis', word_class: 'N' }
  ],
  polishing: [
    { word: 'polish', word_class: 'N' },
    { word: 'polish', word_class: 'V' },
    { word: 'polish', word_class: 'AJ' },
    { word: 'polisher', word_class: 'N' },
    { word: 'polished', word_class: 'AJ' },
    { word: 'polishing', word_class: 'N' }
  ],
  apportioned: [
    { word: 'apportion', word_class: 'V' },
    { word: 'apportioned', word_class: 'AJ' },
    { word: 'apportioning', word_class: 'N' },
    { word: 'apportionment', word_class: 'N' },
    { word: 'apportionable', word_class: 'AJ' }
  ],
  fledge: [
    { word: 'fledge', word_class: 'V' },
    { word: 'fledged', word_class: 'AJ' },
    { word: 'fledgling', word_class: 'N' },
    { word: 'fledgling', word_class: 'AJ' }
  ],
  perusal: [
    { word: 'peruse', word_class: 'V' },
    { word: 'perusal', word_class: 'N' },
    { word: 'perusing', word_class: 'N' }
  ],
  parabolisation: [
    { word: 'parabola', word_class: 'N' },
    { word: 'parabolic', word_class: 'AJ' },
    { word: 'parabolize', word_class: 'V' },
    { word: 'parabolise', word_class: 'V' },
    { word: 'parabolical', word_class: 'AJ' },
    { word: 'parabolisation', word_class: 'N' },
    { word: 'parabolization', word_class: 'N' }
  ],
  reputably: [
    { word: 'repute', word_class: 'N' },
    { word: 'repute', word_class: 'V' },
    { word: 'reputed', word_class: 'AJ' },
    { word: 'reputable', word_class: 'AJ' },
    { word: 'reputably', word_class: 'AV' },
    { word: 'reputation', word_class: 'N' },
    { word: 'reputability', word_class: 'N' }
  ],
  reposeful: [
    { word: 'repose', word_class: 'N' },
    { word: 'repose', word_class: 'V' },
    { word: 'reposal', word_class: 'N' },
    { word: 'reposeful', word_class: 'AJ' }
  ],
  gloomful: [
    { word: 'gloom', word_class: 'N' },
    { word: 'gloomful', word_class: 'AJ' },
    { word: 'glooming', word_class: 'AJ' }
  ],
  poach: [
    { word: 'poach', word_class: 'V' },
    { word: 'poacher', word_class: 'N' },
    { word: 'poached', word_class: 'AJ' },
    { word: 'poaching', word_class: 'N' }
  ],
  congealment: [
    { word: 'congeal', word_class: 'V' },
    { word: 'congealed', word_class: 'AJ' },
    { word: 'congealment', word_class: 'N' },
    { word: 'congelation', word_class: 'N' }
  ],
  anglophilic: [
    { word: 'anglophil', word_class: 'N' },
    { word: 'anglophile', word_class: 'N' },
    { word: 'anglophilic', word_class: 'AJ' }
  ],
  evocable: [
    { word: 'evoke', word_class: 'V' },
    { word: 'evoked', word_class: 'AJ' },
    { word: 'evocable', word_class: 'AJ' },
    { word: 'evocation', word_class: 'N' },
    { word: 'evocative', word_class: 'AJ' }
  ],
  inundation: [
    { word: 'inundate', word_class: 'V' },
    { word: 'inundated', word_class: 'AJ' },
    { word: 'inundation', word_class: 'N' }
  ],
  ingenue: [
    { word: 'ingenue', word_class: 'N' },
    { word: 'ingenuity', word_class: 'N' },
    { word: 'ingenuous', word_class: 'AJ' },
    { word: 'ingenuously', word_class: 'AV' },
    { word: 'ingenuousness', word_class: 'N' }
  ],
  norman: [
    { word: 'norman', word_class: 'N' },
    { word: 'norman', word_class: 'AJ' },
    { word: 'normanise', word_class: 'V' },
    { word: 'normanize', word_class: 'V' },
    { word: 'normanisation', word_class: 'N' },
    { word: 'normanization', word_class: 'N' }
  ],
  germ: [
    { word: 'germ', word_class: 'N' },
    { word: 'germinal', word_class: 'N' },
    { word: 'germinal', word_class: 'AJ' },
    { word: 'germinate', word_class: 'V' },
    { word: 'germination', word_class: 'N' }
  ],
  electromagnetic: [
    { word: 'electromagnet', word_class: 'N' },
    { word: 'electromagnetic', word_class: 'AJ' },
    { word: 'electromagnetics', word_class: 'N' },
    { word: 'electromagnetism', word_class: 'N' }
  ],
  gynecology: [
    { word: 'gynecology', word_class: 'N' },
    { word: 'gynecologic', word_class: 'AJ' },
    { word: 'gynecological', word_class: 'AJ' }
  ],
  proration: [
    { word: 'prorate', word_class: 'N' },
    { word: 'prorate', word_class: 'V' },
    { word: 'proration', word_class: 'N' }
  ],
  abrase: [
    { word: 'abrade', word_class: 'V' },
    { word: 'abrase', word_class: 'V' },
    { word: 'abrader', word_class: 'N' },
    { word: 'abrasion', word_class: 'N' },
    { word: 'abradant', word_class: 'N' },
    { word: 'abraded', word_class: 'AJ' },
    { word: 'abrasive', word_class: 'N' },
    { word: 'abrasive', word_class: 'AJ' },
    { word: 'abrasiveness', word_class: 'N' }
  ],
  bareback: [
    { word: 'bareback', word_class: 'AJ' },
    { word: 'bareback', word_class: 'AV' },
    { word: 'barebacked', word_class: 'AJ' },
    { word: 'barebacked', word_class: 'AV' }
  ],
  grader: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  uptown: [
    { word: 'uptown', word_class: 'N' },
    { word: 'uptown', word_class: 'AJ' },
    { word: 'uptown', word_class: 'AV' }
  ],
  origination: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  phoneme: [
    { word: 'phoneme', word_class: 'N' },
    { word: 'phonemic', word_class: 'AJ' },
    { word: 'phonemic', word_class: 'AV' },
    { word: 'phonemics', word_class: 'N' }
  ],
  prefatorial: [
    { word: 'preface', word_class: 'N' },
    { word: 'preface', word_class: 'V' },
    { word: 'prefatory', word_class: 'AJ' },
    { word: 'prefatorial', word_class: 'AJ' }
  ],
  heaviness: [
    { word: 'heavy', word_class: 'N' },
    { word: 'heavy', word_class: 'AJ' },
    { word: 'heavy', word_class: 'AV' },
    { word: 'heaviness', word_class: 'N' }
  ],
  penalization: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  accused: [
    { word: 'accuse', word_class: 'V' },
    { word: 'accusal', word_class: 'N' },
    { word: 'accused', word_class: 'N' },
    { word: 'accuser', word_class: 'N' },
    { word: 'accusing', word_class: 'AJ' },
    { word: 'accusive', word_class: 'AJ' },
    { word: 'accusation', word_class: 'N' },
    { word: 'accusative', word_class: 'N' },
    { word: 'accusative', word_class: 'AJ' }
  ],
  bunched: [
    { word: 'bunch', word_class: 'N' },
    { word: 'bunch', word_class: 'V' },
    { word: 'bunched', word_class: 'AJ' }
  ],
  mantle: [
    { word: 'mantle', word_class: 'N' },
    { word: 'mantle', word_class: 'V' },
    { word: 'mantled', word_class: 'AJ' }
  ],
  favourably: [
    { word: 'favour', word_class: 'N' },
    { word: 'favour', word_class: 'V' },
    { word: 'favourite', word_class: 'N' },
    { word: 'favourite', word_class: 'AJ' },
    { word: 'favourable', word_class: 'AJ' },
    { word: 'favourably', word_class: 'AV' },
    { word: 'favouritism', word_class: 'N' },
    { word: 'favourableness', word_class: 'N' }
  ],
  wed: [
    { word: 'wed', word_class: 'N' },
    { word: 'wed', word_class: 'V' },
    { word: 'wed', word_class: 'AJ' },
    { word: 'wedding', word_class: 'N' },
    { word: 'wedded', word_class: 'AJ' }
  ],
  ganges: [{ word: 'gang', word_class: 'N' }, { word: 'gang', word_class: 'V' }, { word: 'ganges', word_class: 'N' }],
  clog: [
    { word: 'clog', word_class: 'N' },
    { word: 'clog', word_class: 'V' },
    { word: 'clogged', word_class: 'AJ' },
    { word: 'clogging', word_class: 'AJ' }
  ],
  malevolent: [
    { word: 'malevolence', word_class: 'N' },
    { word: 'malevolency', word_class: 'N' },
    { word: 'malevolent', word_class: 'AJ' },
    { word: 'malevolently', word_class: 'AV' }
  ],
  reconnoitering: [
    { word: 'reconnoiter', word_class: 'V' },
    { word: 'reconnoitre', word_class: 'V' },
    { word: 'reconnaissance', word_class: 'N' },
    { word: 'reconnoitering', word_class: 'N' }
  ],
  infinitive: [
    { word: 'infinite', word_class: 'AJ' },
    { word: 'infinitive', word_class: 'N' },
    { word: 'infinitely', word_class: 'AV' },
    { word: 'infinitive', word_class: 'AJ' },
    { word: 'infiniteness', word_class: 'N' }
  ],
  stringer: [
    { word: 'string', word_class: 'N' },
    { word: 'string', word_class: 'V' },
    { word: 'stringer', word_class: 'N' }
  ],
  debate: [
    { word: 'debate', word_class: 'N' },
    { word: 'debate', word_class: 'V' },
    { word: 'debater', word_class: 'N' },
    { word: 'debatable', word_class: 'AJ' }
  ],
  structured: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  narcotise: [
    { word: 'narcotic', word_class: 'N' },
    { word: 'narcotise', word_class: 'V' },
    { word: 'narcotic', word_class: 'AJ' },
    { word: 'narcotize', word_class: 'V' },
    { word: 'narcotized', word_class: 'AJ' },
    { word: 'narcotizing', word_class: 'AJ' },
    { word: 'narcotisation', word_class: 'N' },
    { word: 'narcotization', word_class: 'N' }
  ],
  predation: [
    { word: 'prey', word_class: 'N' },
    { word: 'prey', word_class: 'V' },
    { word: 'predate', word_class: 'V' },
    { word: 'preying', word_class: 'N' },
    { word: 'predator', word_class: 'N' },
    { word: 'predation', word_class: 'N' }
  ],
  administrate: [
    { word: 'administer', word_class: 'V' },
    { word: 'administrate', word_class: 'V' },
    { word: 'administrator', word_class: 'N' },
    { word: 'administrable', word_class: 'AJ' },
    { word: 'administration', word_class: 'N' },
    { word: 'administrative', word_class: 'AJ' },
    { word: 'administratively', word_class: 'AV' }
  ],
  resinated: [
    { word: 'resin', word_class: 'N' },
    { word: 'resin', word_class: 'V' },
    { word: 'resinate', word_class: 'V' },
    { word: 'resinous', word_class: 'AJ' },
    { word: 'resinated', word_class: 'AJ' },
    { word: 'resination', word_class: 'N' }
  ],
  freelance: [
    { word: 'freelance', word_class: 'N' },
    { word: 'freelance', word_class: 'V' },
    { word: 'freelance', word_class: 'AJ' }
  ],
  'de-iodinate': [
    { word: 'de-iodinate', word_class: 'V' },
    { word: 'de-iodination', word_class: 'N' },
    { word: 'de-iodinating', word_class: 'AJ' }
  ],
  hieroglyphical: [
    { word: 'hieroglyph', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'N' },
    { word: 'hieroglyphic', word_class: 'AJ' },
    { word: 'hieroglyphical', word_class: 'AJ' },
    { word: 'hieroglyphically', word_class: 'AV' }
  ],
  quietism: [
    { word: 'quiet', word_class: 'N' },
    { word: 'quiet', word_class: 'V' },
    { word: 'quiet', word_class: 'AJ' },
    { word: 'quiet', word_class: 'AV' },
    { word: 'quietism', word_class: 'N' },
    { word: 'quietist', word_class: 'N' },
    { word: 'quietness', word_class: 'N' }
  ],
  remembered: [
    { word: 'remember', word_class: 'N' },
    { word: 'remember', word_class: 'V' },
    { word: 'remembered', word_class: 'AJ' },
    { word: 'remembering', word_class: 'N' },
    { word: 'remembrance', word_class: 'N' }
  ],
  slumberer: [
    { word: 'slumber', word_class: 'N' },
    { word: 'slumber', word_class: 'V' },
    { word: 'slumberer', word_class: 'N' },
    { word: 'slumbering', word_class: 'AJ' },
    { word: 'slumberous', word_class: 'AJ' }
  ],
  ecology: [
    { word: 'ecology', word_class: 'N' },
    { word: 'ecologic', word_class: 'AJ' },
    { word: 'ecological', word_class: 'AJ' },
    { word: 'ecologically', word_class: 'AV' }
  ],
  statice: [
    { word: 'static', word_class: 'N' },
    { word: 'static', word_class: 'AJ' },
    { word: 'statice', word_class: 'N' },
    { word: 'statics', word_class: 'N' },
    { word: 'statically', word_class: 'AV' }
  ],
  impressed: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  rotting: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  best: [
    { word: 'best', word_class: 'N' },
    { word: 'best', word_class: 'V' },
    { word: 'best', word_class: 'AJ' },
    { word: 'best', word_class: 'AV' },
    { word: 'bested', word_class: 'AJ' }
  ],
  arm: [
    { word: 'arm', word_class: 'N' },
    { word: 'arm', word_class: 'V' },
    { word: 'arms', word_class: 'N' },
    { word: 'armor', word_class: 'N' },
    { word: 'armor', word_class: 'V' },
    { word: 'armful', word_class: 'N' },
    { word: 'armed', word_class: 'AJ' },
    { word: 'arming', word_class: 'N' },
    { word: 'armful', word_class: 'AJ' },
    { word: 'armorer', word_class: 'N' },
    { word: 'armament', word_class: 'N' },
    { word: 'armored', word_class: 'AJ' }
  ],
  stage: [
    { word: 'stage', word_class: 'N' },
    { word: 'stage', word_class: 'V' },
    { word: 'stager', word_class: 'N' },
    { word: 'staged', word_class: 'AJ' },
    { word: 'staging', word_class: 'N' }
  ],
  shadow: [
    { word: 'shadow', word_class: 'N' },
    { word: 'shadow', word_class: 'V' },
    { word: 'shadowed', word_class: 'AJ' },
    { word: 'shadowing', word_class: 'N' },
    { word: 'shadowing', word_class: 'AJ' }
  ],
  apostle: [
    { word: 'apostle', word_class: 'N' },
    { word: 'apostolic', word_class: 'AJ' },
    { word: 'apostolical', word_class: 'AJ' }
  ],
  prettiness: [
    { word: 'pretty', word_class: 'AJ' },
    { word: 'pretty', word_class: 'AV' },
    { word: 'prettiness', word_class: 'N' }
  ],
  dishonorably: [
    { word: 'dishonor', word_class: 'N' },
    { word: 'dishonor', word_class: 'V' },
    { word: 'dishonored', word_class: 'AJ' },
    { word: 'dishonorable', word_class: 'AJ' },
    { word: 'dishonorably', word_class: 'AV' },
    { word: 'dishonorableness', word_class: 'N' }
  ],
  jesting: [
    { word: 'jest', word_class: 'N' },
    { word: 'jest', word_class: 'V' },
    { word: 'jester', word_class: 'N' },
    { word: 'jesting', word_class: 'AJ' }
  ],
  vaccinated: [
    { word: 'vaccine', word_class: 'N' },
    { word: 'vaccinate', word_class: 'V' },
    { word: 'vaccinated', word_class: 'AJ' },
    { word: 'vaccinating', word_class: 'N' },
    { word: 'vaccination', word_class: 'N' }
  ],
  condescend: [
    { word: 'condescend', word_class: 'V' },
    { word: 'condescension', word_class: 'N' },
    { word: 'condescendence', word_class: 'N' },
    { word: 'condescending', word_class: 'AJ' }
  ],
  slanting: [
    { word: 'slant', word_class: 'N' },
    { word: 'slant', word_class: 'V' },
    { word: 'slanted', word_class: 'AJ' },
    { word: 'slanting', word_class: 'AJ' }
  ],
  forgiving: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  pictural: [
    { word: 'picture', word_class: 'N' },
    { word: 'picture', word_class: 'V' },
    { word: 'pictural', word_class: 'AJ' },
    { word: 'pictured', word_class: 'AJ' },
    { word: 'picturing', word_class: 'N' }
  ],
  spectacled: [
    { word: 'spectacle', word_class: 'N' },
    { word: 'spectacles', word_class: 'N' },
    { word: 'spectacled', word_class: 'AJ' },
    { word: 'bespectacled', word_class: 'AJ' }
  ],
  tranquilizing: [
    { word: 'tranquil', word_class: 'AJ' },
    { word: 'tranquility', word_class: 'N' },
    { word: 'tranquilize', word_class: 'V' },
    { word: 'tranquilizer', word_class: 'N' },
    { word: 'tranquillity', word_class: 'N' },
    { word: 'tranquillize', word_class: 'V' },
    { word: 'tranquillizer', word_class: 'N' },
    { word: 'tranquilizing', word_class: 'AJ' },
    { word: 'tranquilization', word_class: 'N' },
    { word: 'tranquillisation', word_class: 'N' },
    { word: 'tranquillization', word_class: 'N' }
  ],
  individualized: [
    { word: 'individual', word_class: 'N' },
    { word: 'individual', word_class: 'AJ' },
    { word: 'individuate', word_class: 'V' },
    { word: 'individualise', word_class: 'V' },
    { word: 'individuality', word_class: 'N' },
    { word: 'individualize', word_class: 'V' },
    { word: 'individualism', word_class: 'N' },
    { word: 'individuation', word_class: 'N' },
    { word: 'individualist', word_class: 'N' },
    { word: 'individually', word_class: 'AV' },
    { word: 'individualist', word_class: 'AJ' },
    { word: 'individualized', word_class: 'AJ' },
    { word: 'individualistic', word_class: 'AJ' },
    { word: 'individualisation', word_class: 'N' },
    { word: 'individualization', word_class: 'N' },
    { word: 'individualistically', word_class: 'AV' }
  ],
  combustible: [
    { word: 'combust', word_class: 'V' },
    { word: 'combustion', word_class: 'N' },
    { word: 'combustible', word_class: 'N' },
    { word: 'combustive', word_class: 'AJ' },
    { word: 'combustible', word_class: 'AJ' }
  ],
  possible: [
    { word: 'possible', word_class: 'N' },
    { word: 'possible', word_class: 'AJ' },
    { word: 'possibly', word_class: 'AV' },
    { word: 'possibility', word_class: 'N' },
    { word: 'possibleness', word_class: 'N' }
  ],
  greenery: [
    { word: 'green', word_class: 'N' },
    { word: 'green', word_class: 'V' },
    { word: 'green', word_class: 'AJ' },
    { word: 'greens', word_class: 'N' },
    { word: 'greenery', word_class: 'N' },
    { word: 'greening', word_class: 'N' },
    { word: 'greening', word_class: 'AJ' },
    { word: 'greenness', word_class: 'N' }
  ],
  piffling: [
    { word: 'piffle', word_class: 'N' },
    { word: 'piffle', word_class: 'V' },
    { word: 'piffling', word_class: 'AJ' }
  ],
  impermanence: [
    { word: 'impermanence', word_class: 'N' },
    { word: 'impermanency', word_class: 'N' },
    { word: 'impermanent', word_class: 'AJ' }
  ],
  constructively: [
    { word: 'construct', word_class: 'N' },
    { word: 'construct', word_class: 'V' },
    { word: 'structure', word_class: 'N' },
    { word: 'structure', word_class: 'V' },
    { word: 'structural', word_class: 'AJ' },
    { word: 'constructor', word_class: 'N' },
    { word: 'structured', word_class: 'AJ' },
    { word: 'construction', word_class: 'N' },
    { word: 'structuralism', word_class: 'N' },
    { word: 'structuralize', word_class: 'V' },
    { word: 'constructive', word_class: 'AJ' },
    { word: 'structurally', word_class: 'AV' },
    { word: 'constructively', word_class: 'AV' },
    { word: 'constructiveness', word_class: 'N' },
    { word: 'structuralization', word_class: 'N' }
  ],
  symphonic: [
    { word: 'symphony', word_class: 'N' },
    { word: 'symphonic', word_class: 'AJ' },
    { word: 'symphonize', word_class: 'V' },
    { word: 'symphonious', word_class: 'AJ' }
  ],
  colored: [
    { word: 'color', word_class: 'N' },
    { word: 'color', word_class: 'V' },
    { word: 'color', word_class: 'AJ' },
    { word: 'colors', word_class: 'N' },
    { word: 'colored', word_class: 'N' },
    { word: 'colorate', word_class: 'V' },
    { word: 'colored', word_class: 'AJ' },
    { word: 'coloring', word_class: 'N' },
    { word: 'colorful', word_class: 'AJ' },
    { word: 'coloration', word_class: 'N' }
  ],
  shoal: [
    { word: 'shoe', word_class: 'N' },
    { word: 'shoe', word_class: 'V' },
    { word: 'shoal', word_class: 'N' },
    { word: 'shoal', word_class: 'V' },
    { word: 'shoes', word_class: 'N' },
    { word: 'shoal', word_class: 'AJ' }
  ],
  notability: [
    { word: 'notable', word_class: 'N' },
    { word: 'notable', word_class: 'AJ' },
    { word: 'notably', word_class: 'AV' },
    { word: 'notability', word_class: 'N' }
  ],
  smarting: [
    { word: 'smart', word_class: 'N' },
    { word: 'smart', word_class: 'V' },
    { word: 'smart', word_class: 'AJ' },
    { word: 'smarting', word_class: 'N' },
    { word: 'smarting', word_class: 'AJ' },
    { word: 'smartness', word_class: 'N' }
  ],
  prudent: [
    { word: 'prudence', word_class: 'N' },
    { word: 'prudent', word_class: 'AJ' },
    { word: 'prudently', word_class: 'AV' }
  ],
  synchronicity: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  highlander: [
    { word: 'highland', word_class: 'N' },
    { word: 'highland', word_class: 'AJ' },
    { word: 'highlands', word_class: 'N' },
    { word: 'highlander', word_class: 'N' }
  ],
  injuriousness: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  patch: [
    { word: 'patch', word_class: 'N' },
    { word: 'patch', word_class: 'V' },
    { word: 'patched', word_class: 'AJ' },
    { word: 'patching', word_class: 'N' }
  ],
  anode: [
    { word: 'anode', word_class: 'N' },
    { word: 'anodal', word_class: 'AJ' },
    { word: 'anodic', word_class: 'AJ' },
    { word: 'anodize', word_class: 'V' },
    { word: 'anodization', word_class: 'N' }
  ],
  powder: [
    { word: 'powder', word_class: 'N' },
    { word: 'powder', word_class: 'V' },
    { word: 'powdered', word_class: 'AJ' },
    { word: 'powderize', word_class: 'V' }
  ],
  jogging: [{ word: 'jog', word_class: 'N' }, { word: 'jog', word_class: 'V' }, { word: 'jogging', word_class: 'N' }],
  understanding: [
    { word: 'understand', word_class: 'N' },
    { word: 'understand', word_class: 'V' },
    { word: 'understanding', word_class: 'N' },
    { word: 'understanding', word_class: 'AJ' },
    { word: 'understandable', word_class: 'AJ' },
    { word: 'understandably', word_class: 'AV' },
    { word: 'understandability', word_class: 'N' }
  ],
  'self-respect': [
    { word: 'self-respect', word_class: 'N' },
    { word: 'self-respectful', word_class: 'AJ' },
    { word: 'self-respecting', word_class: 'AJ' }
  ],
  reservist: [
    { word: 'reserve', word_class: 'N' },
    { word: 'reserve', word_class: 'V' },
    { word: 'reserve', word_class: 'AJ' },
    { word: 'reserves', word_class: 'N' },
    { word: 'reserved', word_class: 'AJ' },
    { word: 'reservist', word_class: 'N' },
    { word: 'reservation', word_class: 'N' }
  ],
  root: [
    { word: 'root', word_class: 'N' },
    { word: 'root', word_class: 'V' },
    { word: 'root', word_class: 'AJ' },
    { word: 'rooted', word_class: 'AJ' },
    { word: 'rooting', word_class: 'N' }
  ],
  finally: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  counseling: [
    { word: 'counsel', word_class: 'N' },
    { word: 'counsel', word_class: 'V' },
    { word: 'counselor', word_class: 'N' },
    { word: 'counseling', word_class: 'N' }
  ],
  rightful: [
    { word: 'right', word_class: 'V' },
    { word: 'right', word_class: 'N' },
    { word: 'right', word_class: 'AJ' },
    { word: 'right', word_class: 'AV' },
    { word: 'rightist', word_class: 'N' },
    { word: 'rightful', word_class: 'AJ' },
    { word: 'rightist', word_class: 'AJ' },
    { word: 'rightness', word_class: 'N' },
    { word: 'rightfulness', word_class: 'N' }
  ],
  reactionism: [
    { word: 'react', word_class: 'N' },
    { word: 'react', word_class: 'V' },
    { word: 'reactor', word_class: 'N' },
    { word: 'reactant', word_class: 'N' },
    { word: 'reaction', word_class: 'N' },
    { word: 'reactance', word_class: 'N' },
    { word: 'reactionism', word_class: 'N' }
  ],
  genetic: [
    { word: 'genet', word_class: 'N' },
    { word: 'genetic', word_class: 'AJ' },
    { word: 'genetics', word_class: 'N' },
    { word: 'genetical', word_class: 'AJ' },
    { word: 'genetically', word_class: 'AV' }
  ],
  optical: [
    { word: 'optic', word_class: 'N' },
    { word: 'optic', word_class: 'AJ' },
    { word: 'optics', word_class: 'N' },
    { word: 'optical', word_class: 'AJ' },
    { word: 'optically', word_class: 'AV' }
  ],
  foreigner: [
    { word: 'foreign', word_class: 'AJ' },
    { word: 'foreigner', word_class: 'N' },
    { word: 'foreignness', word_class: 'N' }
  ],
  shocking: [
    { word: 'shock', word_class: 'N' },
    { word: 'shock', word_class: 'V' },
    { word: 'shocker', word_class: 'N' },
    { word: 'shocked', word_class: 'AJ' },
    { word: 'shocking', word_class: 'AJ' }
  ],
  precociousness: [
    { word: 'precocial', word_class: 'AJ' },
    { word: 'precocious', word_class: 'AJ' },
    { word: 'precociously', word_class: 'AV' },
    { word: 'precociousness', word_class: 'N' }
  ],
  volatile: [
    { word: 'volatile', word_class: 'N' },
    { word: 'volatile', word_class: 'AJ' },
    { word: 'volatilise', word_class: 'V' },
    { word: 'volatilize', word_class: 'V' },
    { word: 'volatility', word_class: 'N' },
    { word: 'volatilized', word_class: 'AJ' },
    { word: 'volatilisation', word_class: 'N' },
    { word: 'volatilization', word_class: 'N' }
  ],
  buttress: [
    { word: 'buttress', word_class: 'N' },
    { word: 'buttress', word_class: 'V' },
    { word: 'buttressed', word_class: 'AJ' },
    { word: 'buttressing', word_class: 'N' }
  ],
  interrupted: [
    { word: 'interrupt', word_class: 'V' },
    { word: 'interrupter', word_class: 'N' },
    { word: 'interrupted', word_class: 'AJ' },
    { word: 'interruption', word_class: 'N' }
  ],
  faradise: [
    { word: 'farad', word_class: 'N' },
    { word: 'faradise', word_class: 'V' },
    { word: 'faradize', word_class: 'V' },
    { word: 'faradisation', word_class: 'N' },
    { word: 'faradization', word_class: 'N' }
  ],
  evenness: [
    { word: 'even', word_class: 'V' },
    { word: 'even', word_class: 'AJ' },
    { word: 'even', word_class: 'AV' },
    { word: 'evening', word_class: 'N' },
    { word: 'evenness', word_class: 'N' }
  ],
  antimonious: [
    { word: 'antimony', word_class: 'N' },
    { word: 'antimonial', word_class: 'AJ' },
    { word: 'antimonious', word_class: 'AJ' }
  ],
  intumescency: [
    { word: 'intumesce', word_class: 'V' },
    { word: 'intumescence', word_class: 'N' },
    { word: 'intumescency', word_class: 'N' }
  ],
  kiddie: [
    { word: 'kid', word_class: 'N' },
    { word: 'kid', word_class: 'V' },
    { word: 'kiddy', word_class: 'N' },
    { word: 'kiddie', word_class: 'N' }
  ],
  propel: [
    { word: 'propel', word_class: 'V' },
    { word: 'propell', word_class: 'V' },
    { word: 'propeller', word_class: 'N' },
    { word: 'propellant', word_class: 'N' },
    { word: 'propellent', word_class: 'N' },
    { word: 'propulsion', word_class: 'N' },
    { word: 'propellant', word_class: 'AJ' },
    { word: 'propellent', word_class: 'AJ' },
    { word: 'propelling', word_class: 'AJ' },
    { word: 'propulsive', word_class: 'AJ' }
  ],
  reiteration: [
    { word: 'reiterate', word_class: 'V' },
    { word: 'reiteration', word_class: 'N' },
    { word: 'reiterative', word_class: 'AJ' }
  ],
  spay: [
    { word: 'spay', word_class: 'V' },
    { word: 'spay', word_class: 'AJ' },
    { word: 'spayed', word_class: 'AJ' },
    { word: 'spaying', word_class: 'N' }
  ],
  spelling: [
    { word: 'spell', word_class: 'N' },
    { word: 'spell', word_class: 'V' },
    { word: 'speller', word_class: 'N' },
    { word: 'spelling', word_class: 'N' }
  ],
  ag: [
    { word: 'ag', word_class: 'N' },
    { word: 'age', word_class: 'N' },
    { word: 'age', word_class: 'V' },
    { word: 'aged', word_class: 'N' },
    { word: 'agent', word_class: 'N' },
    { word: 'agism', word_class: 'N' },
    { word: 'aged', word_class: 'AJ' },
    { word: 'aging', word_class: 'N' },
    { word: 'ageism', word_class: 'N' },
    { word: 'aging', word_class: 'AJ' },
    { word: 'ageing', word_class: 'N' },
    { word: 'agent', word_class: 'AJ' },
    { word: 'ageing', word_class: 'AJ' }
  ],
  crackling: [
    { word: 'crackle', word_class: 'N' },
    { word: 'crackle', word_class: 'V' },
    { word: 'crackle', word_class: 'AJ' },
    { word: 'crackling', word_class: 'N' },
    { word: 'crackled', word_class: 'AJ' },
    { word: 'crackling', word_class: 'AJ' },
    { word: 'cracklings', word_class: 'N' }
  ],
  vaporous: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  crenelate: [
    { word: 'crenel', word_class: 'V' },
    { word: 'crenelate', word_class: 'V' },
    { word: 'crenelate', word_class: 'AJ' },
    { word: 'crenellate', word_class: 'V' },
    { word: 'crenelated', word_class: 'AJ' },
    { word: 'crenelation', word_class: 'N' },
    { word: 'crenellate', word_class: 'AJ' },
    { word: 'crenellated', word_class: 'AJ' },
    { word: 'crenellation', word_class: 'N' }
  ],
  freight: [
    { word: 'freight', word_class: 'N' },
    { word: 'freight', word_class: 'V' },
    { word: 'freighter', word_class: 'N' }
  ],
  briefness: [
    { word: 'brief', word_class: 'N' },
    { word: 'brief', word_class: 'V' },
    { word: 'brief', word_class: 'AJ' },
    { word: 'briefs', word_class: 'N' },
    { word: 'brevity', word_class: 'N' },
    { word: 'briefing', word_class: 'N' },
    { word: 'briefness', word_class: 'N' }
  ],
  inattentively: [
    { word: 'inattention', word_class: 'N' },
    { word: 'inattentive', word_class: 'AJ' },
    { word: 'inattentively', word_class: 'AV' },
    { word: 'inattentiveness', word_class: 'N' }
  ],
  inherited: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  affectionateness: [
    { word: 'affect', word_class: 'N' },
    { word: 'affect', word_class: 'V' },
    { word: 'affection', word_class: 'N' },
    { word: 'affected', word_class: 'AJ' },
    { word: 'affecting', word_class: 'AJ' },
    { word: 'affective', word_class: 'AJ' },
    { word: 'affectation', word_class: 'N' },
    { word: 'affectional', word_class: 'AJ' },
    { word: 'affectioned', word_class: 'AJ' },
    { word: 'affectionate', word_class: 'AJ' },
    { word: 'affectionately', word_class: 'AV' },
    { word: 'affectionateness', word_class: 'N' }
  ],
  versification: [
    { word: 'versify', word_class: 'V' },
    { word: 'versifier', word_class: 'N' },
    { word: 'versification', word_class: 'N' }
  ],
  inferior: [
    { word: 'inferior', word_class: 'N' },
    { word: 'inferior', word_class: 'AJ' },
    { word: 'inferiority', word_class: 'N' }
  ],
  rigidness: [
    { word: 'rigid', word_class: 'AJ' },
    { word: 'rigidity', word_class: 'N' },
    { word: 'rigidness', word_class: 'N' }
  ],
  multiply: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  disseminating: [
    { word: 'disseminate', word_class: 'V' },
    { word: 'disseminator', word_class: 'N' },
    { word: 'dissemination', word_class: 'N' },
    { word: 'disseminating', word_class: 'AJ' },
    { word: 'disseminative', word_class: 'AJ' }
  ],
  diplomatically: [
    { word: 'diplomat', word_class: 'N' },
    { word: 'diplomatic', word_class: 'AJ' },
    { word: 'diplomatical', word_class: 'AJ' },
    { word: 'diplomatically', word_class: 'AV' }
  ],
  tensely: [
    { word: 'tense', word_class: 'N' },
    { word: 'tense', word_class: 'V' },
    { word: 'tensor', word_class: 'N' },
    { word: 'tense', word_class: 'AJ' },
    { word: 'tensed', word_class: 'AJ' },
    { word: 'tension', word_class: 'N' },
    { word: 'tensely', word_class: 'AV' },
    { word: 'tenseness', word_class: 'N' },
    { word: 'tensional', word_class: 'AJ' }
  ],
  hazardous: [
    { word: 'hazard', word_class: 'N' },
    { word: 'hazard', word_class: 'V' },
    { word: 'hazarding', word_class: 'N' },
    { word: 'hazardous', word_class: 'AJ' },
    { word: 'hazardously', word_class: 'AV' },
    { word: 'hazardousness', word_class: 'N' }
  ],
  reluct: [
    { word: 'reluct', word_class: 'V' },
    { word: 'reluctance', word_class: 'N' },
    { word: 'reluctancy', word_class: 'N' },
    { word: 'reluctant', word_class: 'AJ' },
    { word: 'reluctivity', word_class: 'N' }
  ],
  worker: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  placed: [
    { word: 'place', word_class: 'N' },
    { word: 'place', word_class: 'V' },
    { word: 'placer', word_class: 'N' },
    { word: 'placed', word_class: 'AJ' },
    { word: 'placeman', word_class: 'N' },
    { word: 'placement', word_class: 'N' }
  ],
  ratable: [
    { word: 'ratable', word_class: 'AJ' },
    { word: 'ratables', word_class: 'N' },
    { word: 'ratability', word_class: 'N' }
  ],
  peptization: [
    { word: 'peptise', word_class: 'V' },
    { word: 'peptize', word_class: 'V' },
    { word: 'peptizing', word_class: 'N' },
    { word: 'peptizing', word_class: 'AJ' },
    { word: 'peptisation', word_class: 'N' },
    { word: 'peptization', word_class: 'N' }
  ],
  federalization: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  irresolutely: [
    { word: 'irresolute', word_class: 'AJ' },
    { word: 'irresolution', word_class: 'N' },
    { word: 'irresolutely', word_class: 'AV' },
    { word: 'irresoluteness', word_class: 'N' }
  ],
  doctrinal: [
    { word: 'doctrine', word_class: 'N' },
    { word: 'doctrinal', word_class: 'AJ' },
    { word: 'doctrinally', word_class: 'AV' }
  ],
  dramatize: [
    { word: 'drama', word_class: 'N' },
    { word: 'dramatic', word_class: 'AJ' },
    { word: 'dramatize', word_class: 'V' },
    { word: 'dramatist', word_class: 'N' },
    { word: 'dramatics', word_class: 'N' },
    { word: 'dramatically', word_class: 'AV' },
    { word: 'dramatization', word_class: 'N' }
  ],
  lameness: [
    { word: 'lame', word_class: 'N' },
    { word: 'lame', word_class: 'V' },
    { word: 'lame', word_class: 'AJ' },
    { word: 'lamely', word_class: 'AV' },
    { word: 'lameness', word_class: 'N' }
  ],
  dissolutely: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  ingrained: [
    { word: 'ingrain', word_class: 'V' },
    { word: 'ingrained', word_class: 'AJ' },
    { word: 'ingraining', word_class: 'N' }
  ],
  most: [{ word: 'most', word_class: 'N' }, { word: 'most', word_class: 'AJ' }, { word: 'most', word_class: 'AV' }],
  divinization: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  induce: [
    { word: 'induce', word_class: 'V' },
    { word: 'induct', word_class: 'N' },
    { word: 'induct', word_class: 'V' },
    { word: 'inductor', word_class: 'N' },
    { word: 'induced', word_class: 'AJ' },
    { word: 'inducing', word_class: 'N' },
    { word: 'inducive', word_class: 'AJ' },
    { word: 'induction', word_class: 'N' },
    { word: 'inducement', word_class: 'N' },
    { word: 'inductance', word_class: 'N' },
    { word: 'inductive', word_class: 'AJ' }
  ],
  defer: [
    { word: 'defer', word_class: 'V' },
    { word: 'deferent', word_class: 'AJ' },
    { word: 'deference', word_class: 'N' },
    { word: 'deferment', word_class: 'N' },
    { word: 'deferred', word_class: 'AJ' },
    { word: 'deferential', word_class: 'AJ' },
    { word: 'deferentially', word_class: 'AV' }
  ],
  embodied: [
    { word: 'embody', word_class: 'V' },
    { word: 'embodied', word_class: 'AJ' },
    { word: 'embodiment', word_class: 'N' }
  ],
  automobile: [
    { word: 'automobile', word_class: 'N' },
    { word: 'automobile', word_class: 'V' },
    { word: 'automobile', word_class: 'AJ' }
  ],
  churn: [
    { word: 'churn', word_class: 'N' },
    { word: 'churn', word_class: 'V' },
    { word: 'churning', word_class: 'AJ' }
  ],
  integrating: [
    { word: 'integral', word_class: 'N' },
    { word: 'integral', word_class: 'AJ' },
    { word: 'integrate', word_class: 'V' },
    { word: 'integrity', word_class: 'N' },
    { word: 'integrator', word_class: 'N' },
    { word: 'integrable', word_class: 'AJ' },
    { word: 'integrated', word_class: 'AJ' },
    { word: 'integration', word_class: 'N' },
    { word: 'integrally', word_class: 'AV' },
    { word: 'integrating', word_class: 'N' },
    { word: 'integrative', word_class: 'AJ' }
  ],
  behavioral: [
    { word: 'behave', word_class: 'V' },
    { word: 'behavior', word_class: 'N' },
    { word: 'behavioral', word_class: 'AJ' },
    { word: 'behaviorism', word_class: 'N' }
  ],
  enraptured: [
    { word: 'enrapture', word_class: 'V' },
    { word: 'enraptured', word_class: 'AJ' },
    { word: 'enrapturement', word_class: 'N' }
  ],
  defoliant: [
    { word: 'defoliant', word_class: 'N' },
    { word: 'defoliate', word_class: 'V' },
    { word: 'defoliate', word_class: 'AJ' },
    { word: 'defoliator', word_class: 'N' },
    { word: 'defoliated', word_class: 'AJ' },
    { word: 'defoliation', word_class: 'N' }
  ],
  mortifying: [
    { word: 'mortify', word_class: 'V' },
    { word: 'mortified', word_class: 'AJ' },
    { word: 'mortifying', word_class: 'AJ' },
    { word: 'mortification', word_class: 'N' }
  ],
  euphemize: [
    { word: 'euphemism', word_class: 'N' },
    { word: 'euphemize', word_class: 'V' },
    { word: 'euphemization', word_class: 'N' }
  ],
  cow: [
    { word: 'cow', word_class: 'N' },
    { word: 'cow', word_class: 'V' },
    { word: 'cows', word_class: 'N' },
    { word: 'cowed', word_class: 'AJ' },
    { word: 'cowman', word_class: 'N' }
  ],
  kind: [
    { word: 'kind', word_class: 'N' },
    { word: 'kind', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AJ' },
    { word: 'kindly', word_class: 'AV' },
    { word: 'kindness', word_class: 'N' },
    { word: 'kindliness', word_class: 'N' }
  ],
  stumper: [
    { word: 'stump', word_class: 'N' },
    { word: 'stump', word_class: 'V' },
    { word: 'stumper', word_class: 'N' },
    { word: 'stumping', word_class: 'N' }
  ],
  livery: [
    { word: 'livery', word_class: 'N' },
    { word: 'livery', word_class: 'AJ' },
    { word: 'liveried', word_class: 'AJ' }
  ],
  bitten: [
    { word: 'bite', word_class: 'N' },
    { word: 'bite', word_class: 'V' },
    { word: 'biter', word_class: 'N' },
    { word: 'biting', word_class: 'AJ' },
    { word: 'bitten', word_class: 'AJ' }
  ],
  multiplicative: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  superlatively: [
    { word: 'superlative', word_class: 'N' },
    { word: 'superlative', word_class: 'AJ' },
    { word: 'superlatively', word_class: 'AV' }
  ],
  provisional: [
    { word: 'provide', word_class: 'V' },
    { word: 'provider', word_class: 'N' },
    { word: 'provision', word_class: 'N' },
    { word: 'provision', word_class: 'V' },
    { word: 'providing', word_class: 'N' },
    { word: 'providence', word_class: 'N' },
    { word: 'provident', word_class: 'AJ' },
    { word: 'provisions', word_class: 'N' },
    { word: 'provisioner', word_class: 'N' },
    { word: 'providently', word_class: 'AV' },
    { word: 'provisional', word_class: 'AJ' },
    { word: 'providential', word_class: 'AJ' },
    { word: 'provisionally', word_class: 'AV' },
    { word: 'providentially', word_class: 'AV' }
  ],
  decisiveness: [
    { word: 'decide', word_class: 'V' },
    { word: 'decided', word_class: 'AJ' },
    { word: 'deciding', word_class: 'N' },
    { word: 'decision', word_class: 'N' },
    { word: 'deciding', word_class: 'AJ' },
    { word: 'decisive', word_class: 'AJ' },
    { word: 'decisively', word_class: 'AV' },
    { word: 'decisiveness', word_class: 'N' }
  ],
  absconder: [
    { word: 'abscond', word_class: 'V' },
    { word: 'absconder', word_class: 'N' },
    { word: 'abscondment', word_class: 'N' }
  ],
  payer: [
    { word: 'pay', word_class: 'N' },
    { word: 'pay', word_class: 'V' },
    { word: 'payee', word_class: 'N' },
    { word: 'payer', word_class: 'N' },
    { word: 'paying', word_class: 'N' },
    { word: 'paying', word_class: 'AJ' },
    { word: 'payment', word_class: 'N' }
  ],
  brimming: [
    { word: 'brim', word_class: 'N' },
    { word: 'brim', word_class: 'V' },
    { word: 'brimful', word_class: 'AJ' },
    { word: 'brimming', word_class: 'AJ' }
  ],
  acceptable: [
    { word: 'accept', word_class: 'V' },
    { word: 'acceptor', word_class: 'N' },
    { word: 'accepted', word_class: 'AJ' },
    { word: 'acceptance', word_class: 'N' },
    { word: 'acceptant', word_class: 'AJ' },
    { word: 'accepting', word_class: 'AJ' },
    { word: 'acceptive', word_class: 'AJ' },
    { word: 'acceptable', word_class: 'AJ' },
    { word: 'acceptably', word_class: 'AV' },
    { word: 'acceptation', word_class: 'N' },
    { word: 'acceptability', word_class: 'N' },
    { word: 'acceptableness', word_class: 'N' }
  ],
  sepulchre: [
    { word: 'sepulcher', word_class: 'N' },
    { word: 'sepulchre', word_class: 'N' },
    { word: 'sepulchral', word_class: 'AJ' }
  ],
  catechism: [
    { word: 'catechism', word_class: 'N' },
    { word: 'catechist', word_class: 'N' },
    { word: 'catechize', word_class: 'V' },
    { word: 'catechesis', word_class: 'N' },
    { word: 'catechistic', word_class: 'AJ' },
    { word: 'catechization', word_class: 'N' }
  ],
  admiring: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  atypically: [
    { word: 'atypic', word_class: 'AJ' },
    { word: 'atypical', word_class: 'AJ' },
    { word: 'atypicality', word_class: 'N' },
    { word: 'atypically', word_class: 'AV' }
  ],
  visualization: [
    { word: 'visual', word_class: 'V' },
    { word: 'visual', word_class: 'AJ' },
    { word: 'visualise', word_class: 'V' },
    { word: 'visualize', word_class: 'V' },
    { word: 'visually', word_class: 'AV' },
    { word: 'visualizer', word_class: 'N' },
    { word: 'visualized', word_class: 'AJ' },
    { word: 'visualisation', word_class: 'N' },
    { word: 'visualization', word_class: 'N' }
  ],
  degraded: [
    { word: 'degrade', word_class: 'N' },
    { word: 'degrade', word_class: 'V' },
    { word: 'degraded', word_class: 'AJ' },
    { word: 'degrading', word_class: 'AJ' },
    { word: 'degradation', word_class: 'N' }
  ],
  republish: [
    { word: 'republic', word_class: 'N' },
    { word: 'republic', word_class: 'AJ' },
    { word: 'republish', word_class: 'V' },
    { word: 'republishing', word_class: 'N' },
    { word: 'republication', word_class: 'N' }
  ],
  lowlander: [
    { word: 'lowland', word_class: 'N' },
    { word: 'lowland', word_class: 'AJ' },
    { word: 'lowlander', word_class: 'N' }
  ],
  settlement: [
    { word: 'settle', word_class: 'N' },
    { word: 'settle', word_class: 'V' },
    { word: 'settler', word_class: 'N' },
    { word: 'settled', word_class: 'AJ' },
    { word: 'settling', word_class: 'N' },
    { word: 'settlement', word_class: 'N' }
  ],
  hypnotized: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  deflection: [
    { word: 'deflect', word_class: 'V' },
    { word: 'deflector', word_class: 'N' },
    { word: 'deflection', word_class: 'N' },
    { word: 'deflective', word_class: 'AJ' }
  ],
  occupied: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  dissipated: [
    { word: 'dissipate', word_class: 'V' },
    { word: 'dissipated', word_class: 'AJ' },
    { word: 'dissipation', word_class: 'N' }
  ],
  germanise: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  ingress: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  tameness: [
    { word: 'tame', word_class: 'V' },
    { word: 'tame', word_class: 'AJ' },
    { word: 'tamer', word_class: 'N' },
    { word: 'tamed', word_class: 'AJ' },
    { word: 'tamely', word_class: 'AV' },
    { word: 'tameness', word_class: 'N' }
  ],
  vehemency: [
    { word: 'vehemence', word_class: 'N' },
    { word: 'vehemency', word_class: 'N' },
    { word: 'vehement', word_class: 'AJ' },
    { word: 'vehemently', word_class: 'AV' }
  ],
  acrogenic: [
    { word: 'acrogen', word_class: 'N' },
    { word: 'acrogenic', word_class: 'AJ' },
    { word: 'acrogenous', word_class: 'AJ' }
  ],
  abetment: [
    { word: 'abet', word_class: 'V' },
    { word: 'abettal', word_class: 'N' },
    { word: 'abetter', word_class: 'N' },
    { word: 'abetment', word_class: 'N' }
  ],
  confiding: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  furnishing: [
    { word: 'furnish', word_class: 'V' },
    { word: 'furnished', word_class: 'AJ' },
    { word: 'furnishing', word_class: 'N' },
    { word: 'furnishings', word_class: 'N' }
  ],
  layman: [
    { word: 'lay', word_class: 'N' },
    { word: 'lay', word_class: 'V' },
    { word: 'lay', word_class: 'AJ' },
    { word: 'layer', word_class: 'N' },
    { word: 'layer', word_class: 'V' },
    { word: 'laying', word_class: 'N' },
    { word: 'layman', word_class: 'N' },
    { word: 'layered', word_class: 'AJ' }
  ],
  absorptance: [
    { word: 'absorb', word_class: 'V' },
    { word: 'absorber', word_class: 'N' },
    { word: 'absorbent', word_class: 'N' },
    { word: 'absorbate', word_class: 'N' },
    { word: 'absorbed', word_class: 'AJ' },
    { word: 'absorbency', word_class: 'N' },
    { word: 'absorbent', word_class: 'AJ' },
    { word: 'absorbing', word_class: 'AJ' },
    { word: 'absorption', word_class: 'N' },
    { word: 'absorbable', word_class: 'AJ' },
    { word: 'absorptive', word_class: 'AJ' },
    { word: 'absorptance', word_class: 'N' },
    { word: 'absorptivity', word_class: 'N' }
  ],
  importunity: [
    { word: 'importune', word_class: 'V' },
    { word: 'importunacy', word_class: 'N' },
    { word: 'importunity', word_class: 'N' },
    { word: 'importunate', word_class: 'AJ' },
    { word: 'importunately', word_class: 'AV' }
  ],
  supplant: [
    { word: 'supplant', word_class: 'V' },
    { word: 'supplanter', word_class: 'N' },
    { word: 'supplanting', word_class: 'N' },
    { word: 'supplantation', word_class: 'N' }
  ],
  offensive: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  animalization: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  deflect: [
    { word: 'deflect', word_class: 'V' },
    { word: 'deflector', word_class: 'N' },
    { word: 'deflection', word_class: 'N' },
    { word: 'deflective', word_class: 'AJ' }
  ],
  skinny: [
    { word: 'skinny', word_class: 'N' },
    { word: 'skinny', word_class: 'AJ' },
    { word: 'skinniness', word_class: 'N' }
  ],
  credential: [
    { word: 'credential', word_class: 'N' },
    { word: 'credentials', word_class: 'N' },
    { word: 'credentialed', word_class: 'AJ' }
  ],
  earth: [
    { word: 'earth', word_class: 'N' },
    { word: 'earth', word_class: 'V' },
    { word: 'earthen', word_class: 'AJ' },
    { word: 'earthing', word_class: 'N' },
    { word: 'earthman', word_class: 'N' }
  ],
  conformism: [
    { word: 'conform', word_class: 'V' },
    { word: 'conformism', word_class: 'N' },
    { word: 'conformist', word_class: 'N' },
    { word: 'conformity', word_class: 'N' },
    { word: 'conformance', word_class: 'N' },
    { word: 'conforming', word_class: 'AJ' },
    { word: 'conformist', word_class: 'AJ' },
    { word: 'conformable', word_class: 'AJ' },
    { word: 'conformation', word_class: 'N' }
  ],
  chock: [
    { word: 'chock', word_class: 'N' },
    { word: 'chock', word_class: 'V' },
    { word: 'chock', word_class: 'AV' },
    { word: 'chockful', word_class: 'AJ' }
  ],
  endow: [
    { word: 'endow', word_class: 'V' },
    { word: 'endowed', word_class: 'AJ' },
    { word: 'endowment', word_class: 'N' }
  ],
  moaning: [
    { word: 'moan', word_class: 'N' },
    { word: 'moan', word_class: 'V' },
    { word: 'moaner', word_class: 'N' },
    { word: 'moaning', word_class: 'AJ' }
  ],
  detonation: [
    { word: 'detonate', word_class: 'V' },
    { word: 'detonator', word_class: 'N' },
    { word: 'detonable', word_class: 'AJ' },
    { word: 'detonation', word_class: 'N' },
    { word: 'detonating', word_class: 'AJ' },
    { word: 'detonative', word_class: 'AJ' }
  ],
  casualness: [
    { word: 'casual', word_class: 'AJ' },
    { word: 'casually', word_class: 'AV' },
    { word: 'casualness', word_class: 'N' }
  ],
  proof: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  annulate: [
    { word: 'annul', word_class: 'V' },
    { word: 'annulate', word_class: 'AJ' },
    { word: 'annulment', word_class: 'N' },
    { word: 'annulated', word_class: 'AJ' }
  ],
  micturition: [
    { word: 'micturate', word_class: 'V' },
    { word: 'micturation', word_class: 'N' },
    { word: 'micturition', word_class: 'N' }
  ],
  slops: [
    { word: 'slop', word_class: 'N' },
    { word: 'slop', word_class: 'V' },
    { word: 'slops', word_class: 'N' },
    { word: 'slopped', word_class: 'AJ' }
  ],
  incased: [
    { word: 'encase', word_class: 'V' },
    { word: 'incase', word_class: 'V' },
    { word: 'encased', word_class: 'AJ' },
    { word: 'incased', word_class: 'AJ' },
    { word: 'encasement', word_class: 'N' },
    { word: 'incasement', word_class: 'N' }
  ],
  badness: [
    { word: 'bad', word_class: 'N' },
    { word: 'bad', word_class: 'AJ' },
    { word: 'bad', word_class: 'AV' },
    { word: 'badness', word_class: 'N' }
  ],
  inured: [
    { word: 'inure', word_class: 'V' },
    { word: 'inured', word_class: 'AJ' },
    { word: 'inurement', word_class: 'N' }
  ],
  balanced: [
    { word: 'balance', word_class: 'N' },
    { word: 'balance', word_class: 'V' },
    { word: 'balancer', word_class: 'N' },
    { word: 'balanced', word_class: 'AJ' },
    { word: 'balancing', word_class: 'N' }
  ],
  escalator: [
    { word: 'escalate', word_class: 'V' },
    { word: 'escalator', word_class: 'N' },
    { word: 'escalation', word_class: 'N' }
  ],
  unattractively: [
    { word: 'unattractive', word_class: 'AJ' },
    { word: 'unattractively', word_class: 'AV' },
    { word: 'unattractiveness', word_class: 'N' }
  ],
  ulcerate: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  americanise: [
    { word: 'american', word_class: 'N' },
    { word: 'american', word_class: 'AJ' },
    { word: 'americanise', word_class: 'V' },
    { word: 'americanize', word_class: 'V' },
    { word: 'americanism', word_class: 'N' },
    { word: 'americanisation', word_class: 'N' },
    { word: 'americanization', word_class: 'N' }
  ],
  contorted: [
    { word: 'contort', word_class: 'V' },
    { word: 'contorsion', word_class: 'N' },
    { word: 'contorted', word_class: 'AJ' },
    { word: 'contortion', word_class: 'N' }
  ],
  wangle: [
    { word: 'wangle', word_class: 'N' },
    { word: 'wangle', word_class: 'V' },
    { word: 'wangling', word_class: 'N' }
  ],
  surrounding: [
    { word: 'surround', word_class: 'N' },
    { word: 'surround', word_class: 'V' },
    { word: 'surround', word_class: 'AJ' },
    { word: 'surrounded', word_class: 'AJ' },
    { word: 'surrounding', word_class: 'N' },
    { word: 'surrounding', word_class: 'AJ' },
    { word: 'surroundings', word_class: 'N' }
  ],
  stew: [
    { word: 'stew', word_class: 'N' },
    { word: 'stew', word_class: 'V' },
    { word: 'stewed', word_class: 'AJ' },
    { word: 'stewing', word_class: 'N' }
  ],
  honey: [
    { word: 'honey', word_class: 'N' },
    { word: 'honey', word_class: 'V' },
    { word: 'honey', word_class: 'AJ' },
    { word: 'honeyed', word_class: 'AJ' }
  ],
  sanitate: [
    { word: 'sanitate', word_class: 'V' },
    { word: 'sanitise', word_class: 'V' },
    { word: 'sanitize', word_class: 'V' },
    { word: 'sanitation', word_class: 'N' },
    { word: 'sanitized', word_class: 'AJ' },
    { word: 'sanitizing', word_class: 'N' },
    { word: 'sanitisation', word_class: 'N' },
    { word: 'sanitization', word_class: 'N' }
  ],
  reformer: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  diversity: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  forgetful: [
    { word: 'forget', word_class: 'V' },
    { word: 'forgetful', word_class: 'AJ' },
    { word: 'forgotten', word_class: 'AJ' },
    { word: 'forgetfulness', word_class: 'N' }
  ],
  histrionics: [
    { word: 'histrion', word_class: 'N' },
    { word: 'histrionic', word_class: 'AJ' },
    { word: 'histrionics', word_class: 'N' }
  ],
  tactic: [
    { word: 'tactic', word_class: 'N' },
    { word: 'tactic', word_class: 'AJ' },
    { word: 'tactics', word_class: 'N' },
    { word: 'tactical', word_class: 'AJ' },
    { word: 'tactically', word_class: 'AV' }
  ],
  fri: [{ word: 'fri', word_class: 'N' }, { word: 'fries', word_class: 'N' }, { word: 'fried', word_class: 'AJ' }],
  read: [
    { word: 'read', word_class: 'N' },
    { word: 'read', word_class: 'V' },
    { word: 'read', word_class: 'AJ' },
    { word: 'reader', word_class: 'N' },
    { word: 'reading', word_class: 'N' }
  ],
  lasher: [
    { word: 'lash', word_class: 'N' },
    { word: 'lash', word_class: 'V' },
    { word: 'lasher', word_class: 'N' },
    { word: 'lashing', word_class: 'N' },
    { word: 'lashing', word_class: 'AJ' },
    { word: 'lashings', word_class: 'N' }
  ],
  elaborateness: [
    { word: 'elaborate', word_class: 'V' },
    { word: 'elaborate', word_class: 'AJ' },
    { word: 'elaborated', word_class: 'AJ' },
    { word: 'elaboration', word_class: 'N' },
    { word: 'elaborately', word_class: 'AV' },
    { word: 'elaborateness', word_class: 'N' }
  ],
  flirt: [
    { word: 'flirt', word_class: 'N' },
    { word: 'flirt', word_class: 'V' },
    { word: 'flirting', word_class: 'N' },
    { word: 'flirtation', word_class: 'N' },
    { word: 'flirtatious', word_class: 'AJ' },
    { word: 'flirtatiously', word_class: 'AV' }
  ],
  fat: [
    { word: 'fat', word_class: 'N' },
    { word: 'fat', word_class: 'V' },
    { word: 'fat', word_class: 'AJ' },
    { word: 'fatness', word_class: 'N' }
  ],
  referee: [
    { word: 'referee', word_class: 'N' },
    { word: 'referee', word_class: 'V' },
    { word: 'refereeing', word_class: 'N' }
  ],
  abutment: [
    { word: 'abut', word_class: 'V' },
    { word: 'abuttal', word_class: 'N' },
    { word: 'abutter', word_class: 'N' },
    { word: 'abutment', word_class: 'N' },
    { word: 'abutting', word_class: 'AJ' }
  ],
  indoors: [
    { word: 'indoor', word_class: 'N' },
    { word: 'indoor', word_class: 'AJ' },
    { word: 'indoors', word_class: 'AV' }
  ],
  notion: [
    { word: 'note', word_class: 'N' },
    { word: 'note', word_class: 'V' },
    { word: 'notate', word_class: 'V' },
    { word: 'notion', word_class: 'N' },
    { word: 'noted', word_class: 'AJ' },
    { word: 'notions', word_class: 'N' },
    { word: 'notation', word_class: 'N' },
    { word: 'notional', word_class: 'AJ' }
  ],
  cured: [{ word: 'cure', word_class: 'N' }, { word: 'cure', word_class: 'V' }, { word: 'cured', word_class: 'AJ' }],
  anus: [{ word: 'anu', word_class: 'N' }, { word: 'anus', word_class: 'N' }, { word: 'anal', word_class: 'AJ' }],
  revved: [
    { word: 'rev', word_class: 'N' },
    { word: 'rev', word_class: 'V' },
    { word: 'revved', word_class: 'AJ' },
    { word: 'revving', word_class: 'AJ' }
  ],
  experimenter: [
    { word: 'experience', word_class: 'N' },
    { word: 'experience', word_class: 'V' },
    { word: 'experiment', word_class: 'N' },
    { word: 'experiment', word_class: 'V' },
    { word: 'experimenter', word_class: 'N' },
    { word: 'experiential', word_class: 'AJ' },
    { word: 'experimental', word_class: 'AJ' },
    { word: 'experimentalism', word_class: 'N' },
    { word: 'experimentally', word_class: 'AV' },
    { word: 'experimentation', word_class: 'N' }
  ],
  fueled: [
    { word: 'fuel', word_class: 'N' },
    { word: 'fuel', word_class: 'V' },
    { word: 'fueled', word_class: 'AJ' },
    { word: 'fueling', word_class: 'N' }
  ],
  disablement: [
    { word: 'disable', word_class: 'N' },
    { word: 'disable', word_class: 'V' },
    { word: 'disabled', word_class: 'N' },
    { word: 'disable', word_class: 'AJ' },
    { word: 'disabled', word_class: 'AJ' },
    { word: 'disability', word_class: 'N' },
    { word: 'disabling', word_class: 'AJ' },
    { word: 'disablement', word_class: 'N' }
  ],
  repeat: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  budgetary: [
    { word: 'budget', word_class: 'N' },
    { word: 'budget', word_class: 'V' },
    { word: 'budgetary', word_class: 'AJ' }
  ],
  smell: [
    { word: 'smell', word_class: 'N' },
    { word: 'smell', word_class: 'V' },
    { word: 'smelling', word_class: 'N' },
    { word: 'smelling', word_class: 'AJ' }
  ],
  gown: [{ word: 'gown', word_class: 'N' }, { word: 'gown', word_class: 'V' }, { word: 'gowned', word_class: 'AJ' }],
  insipidness: [
    { word: 'insipid', word_class: 'AJ' },
    { word: 'insipidity', word_class: 'N' },
    { word: 'insipidness', word_class: 'N' }
  ],
  independently: [
    { word: 'independent', word_class: 'N' },
    { word: 'independence', word_class: 'N' },
    { word: 'independent', word_class: 'AJ' },
    { word: 'independency', word_class: 'N' },
    { word: 'independently', word_class: 'AV' }
  ],
  affixation: [
    { word: 'affix', word_class: 'N' },
    { word: 'affix', word_class: 'V' },
    { word: 'affixal', word_class: 'AJ' },
    { word: 'affixed', word_class: 'AJ' },
    { word: 'affixation', word_class: 'N' }
  ],
  permeate: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  engorge: [
    { word: 'engorge', word_class: 'V' },
    { word: 'engorged', word_class: 'AJ' },
    { word: 'engorgement', word_class: 'N' }
  ],
  fulminate: [
    { word: 'fulminate', word_class: 'N' },
    { word: 'fulminate', word_class: 'V' },
    { word: 'fulmination', word_class: 'N' }
  ],
  entwintwinement: [
    { word: 'entwine', word_class: 'V' },
    { word: 'entwinement', word_class: 'N' },
    { word: 'entwintwine', word_class: 'V' },
    { word: 'entwintwinement', word_class: 'N' }
  ],
  rotted: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  mocking: [
    { word: 'mock', word_class: 'N' },
    { word: 'mock', word_class: 'V' },
    { word: 'mock', word_class: 'AJ' },
    { word: 'mocker', word_class: 'N' },
    { word: 'mockery', word_class: 'N' },
    { word: 'mockery', word_class: 'AJ' },
    { word: 'mocking', word_class: 'AJ' }
  ],
  defoliation: [
    { word: 'defoliant', word_class: 'N' },
    { word: 'defoliate', word_class: 'V' },
    { word: 'defoliate', word_class: 'AJ' },
    { word: 'defoliator', word_class: 'N' },
    { word: 'defoliated', word_class: 'AJ' },
    { word: 'defoliation', word_class: 'N' }
  ],
  traumatization: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  ulcer: [
    { word: 'ulcer', word_class: 'N' },
    { word: 'ulcerate', word_class: 'V' },
    { word: 'ulcerous', word_class: 'AJ' },
    { word: 'ulcerated', word_class: 'AJ' },
    { word: 'ulceration', word_class: 'N' },
    { word: 'ulcerative', word_class: 'AJ' }
  ],
  unexcitable: [
    { word: 'unexcited', word_class: 'AJ' },
    { word: 'unexciting', word_class: 'AJ' },
    { word: 'unexcitable', word_class: 'AJ' }
  ],
  puritan: [
    { word: 'puritan', word_class: 'N' },
    { word: 'puritan', word_class: 'AJ' },
    { word: 'puritanic', word_class: 'AJ' },
    { word: 'puritanism', word_class: 'N' },
    { word: 'puritanical', word_class: 'AJ' },
    { word: 'puritanically', word_class: 'AV' }
  ],
  pluralize: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  scaled: [
    { word: 'scale', word_class: 'N' },
    { word: 'scale', word_class: 'V' },
    { word: 'scalar', word_class: 'N' },
    { word: 'scaly', word_class: 'AJ' },
    { word: 'scales', word_class: 'N' },
    { word: 'scalar', word_class: 'AJ' },
    { word: 'scaled', word_class: 'AJ' },
    { word: 'scaling', word_class: 'N' }
  ],
  scrutiny: [
    { word: 'scrutiny', word_class: 'N' },
    { word: 'scrutinise', word_class: 'V' },
    { word: 'scrutinize', word_class: 'V' },
    { word: 'scrutineer', word_class: 'N' },
    { word: 'scrutinise', word_class: 'AJ' },
    { word: 'scrutinize', word_class: 'AJ' }
  ],
  ups: [
    { word: 'up', word_class: 'V' },
    { word: 'up', word_class: 'AJ' },
    { word: 'up', word_class: 'AV' },
    { word: 'ups', word_class: 'V' }
  ],
  manipulative: [
    { word: 'manipulate', word_class: 'V' },
    { word: 'manipulator', word_class: 'N' },
    { word: 'manipulable', word_class: 'AJ' },
    { word: 'manipulation', word_class: 'N' },
    { word: 'manipulative', word_class: 'AJ' },
    { word: 'manipulability', word_class: 'N' }
  ],
  obsolescent: [
    { word: 'obsolesce', word_class: 'V' },
    { word: 'obsolescence', word_class: 'N' },
    { word: 'obsolescent', word_class: 'AJ' }
  ],
  unsatiable: [
    { word: 'unsatiable', word_class: 'AJ' },
    { word: 'unsatiably', word_class: 'AV' },
    { word: 'unsatiated', word_class: 'AJ' }
  ],
  clownery: [
    { word: 'clown', word_class: 'N' },
    { word: 'clown', word_class: 'V' },
    { word: 'clownery', word_class: 'N' },
    { word: 'clowning', word_class: 'N' },
    { word: 'clownish', word_class: 'AJ' }
  ],
  activity: [
    { word: 'action', word_class: 'N' },
    { word: 'active', word_class: 'N' },
    { word: 'acting', word_class: 'AJ' },
    { word: 'active', word_class: 'AJ' },
    { word: 'activate', word_class: 'V' },
    { word: 'activity', word_class: 'N' },
    { word: 'activator', word_class: 'N' },
    { word: 'actively', word_class: 'AV' },
    { word: 'activated', word_class: 'AJ' },
    { word: 'activating', word_class: 'N' },
    { word: 'activation', word_class: 'N' },
    { word: 'activeness', word_class: 'N' },
    { word: 'actionable', word_class: 'AJ' },
    { word: 'activating', word_class: 'AJ' }
  ],
  martyrize: [
    { word: 'martyr', word_class: 'N' },
    { word: 'martyr', word_class: 'V' },
    { word: 'martyrize', word_class: 'V' },
    { word: 'martyrisation', word_class: 'N' },
    { word: 'martyrization', word_class: 'N' }
  ],
  jejunity: [
    { word: 'jejune', word_class: 'AJ' },
    { word: 'jejunity', word_class: 'N' },
    { word: 'jejunely', word_class: 'AV' },
    { word: 'jejunitis', word_class: 'N' },
    { word: 'jejuneness', word_class: 'N' }
  ],
  articled: [
    { word: 'article', word_class: 'N' },
    { word: 'article', word_class: 'V' },
    { word: 'articled', word_class: 'AJ' }
  ],
  sticking: [
    { word: 'stick', word_class: 'N' },
    { word: 'stick', word_class: 'V' },
    { word: 'sticker', word_class: 'N' },
    { word: 'sticky', word_class: 'AJ' },
    { word: 'sticking', word_class: 'AJ' },
    { word: 'stickiness', word_class: 'N' }
  ],
  hyaline: [
    { word: 'hyalin', word_class: 'N' },
    { word: 'hyaline', word_class: 'N' },
    { word: 'hyaline', word_class: 'AJ' },
    { word: 'hyalinization', word_class: 'N' }
  ],
  pluralise: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  companionability: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  contamination: [
    { word: 'contaminant', word_class: 'N' },
    { word: 'contaminate', word_class: 'V' },
    { word: 'contaminated', word_class: 'AJ' },
    { word: 'contamination', word_class: 'N' },
    { word: 'contaminating', word_class: 'AJ' },
    { word: 'contaminative', word_class: 'AJ' }
  ],
  arthrosporous: [
    { word: 'arthrospore', word_class: 'N' },
    { word: 'arthrosporic', word_class: 'AJ' },
    { word: 'arthrosporous', word_class: 'AJ' }
  ],
  creation: [
    { word: 'create', word_class: 'V' },
    { word: 'creator', word_class: 'N' },
    { word: 'creation', word_class: 'N' },
    { word: 'creationism', word_class: 'N' }
  ],
  undulation: [
    { word: 'undulate', word_class: 'V' },
    { word: 'undulant', word_class: 'AJ' },
    { word: 'undulate', word_class: 'AJ' },
    { word: 'undulation', word_class: 'N' }
  ],
  restive: [
    { word: 'restive', word_class: 'AJ' },
    { word: 'restively', word_class: 'AV' },
    { word: 'restiveness', word_class: 'N' }
  ],
  advance: [
    { word: 'advance', word_class: 'N' },
    { word: 'advance', word_class: 'V' },
    { word: 'advance', word_class: 'AJ' },
    { word: 'advanced', word_class: 'AJ' },
    { word: 'advancing', word_class: 'AJ' },
    { word: 'advancement', word_class: 'N' }
  ],
  practise: [
    { word: 'practice', word_class: 'N' },
    { word: 'practice', word_class: 'V' },
    { word: 'practise', word_class: 'N' },
    { word: 'practise', word_class: 'V' },
    { word: 'practical', word_class: 'AJ' },
    { word: 'practiced', word_class: 'AJ' },
    { word: 'practised', word_class: 'AJ' },
    { word: 'practicing', word_class: 'AJ' },
    { word: 'practicable', word_class: 'AJ' },
    { word: 'practicably', word_class: 'AV' },
    { word: 'practicality', word_class: 'N' },
    { word: 'practically', word_class: 'AV' },
    { word: 'practicability', word_class: 'N' },
    { word: 'practicableness', word_class: 'N' }
  ],
  shortened: [
    { word: 'shorten', word_class: 'V' },
    { word: 'shortened', word_class: 'AJ' },
    { word: 'shortening', word_class: 'N' }
  ],
  plane: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  vesiculitis: [
    { word: 'vesiculate', word_class: 'V' },
    { word: 'vesiculitis', word_class: 'N' },
    { word: 'vesiculation', word_class: 'N' }
  ],
  vulnerably: [
    { word: 'vulnerable', word_class: 'AJ' },
    { word: 'vulnerably', word_class: 'AV' },
    { word: 'vulnerability', word_class: 'N' }
  ],
  eery: [
    { word: 'eery', word_class: 'AJ' },
    { word: 'eerie', word_class: 'AJ' },
    { word: 'eeriness', word_class: 'N' }
  ],
  validity: [
    { word: 'valid', word_class: 'AJ' },
    { word: 'validate', word_class: 'V' },
    { word: 'validity', word_class: 'N' },
    { word: 'validness', word_class: 'N' },
    { word: 'validated', word_class: 'AJ' },
    { word: 'validation', word_class: 'N' },
    { word: 'validating', word_class: 'N' },
    { word: 'validating', word_class: 'AJ' }
  ],
  rigor: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  feoff: [
    { word: 'feoff', word_class: 'N' },
    { word: 'feoff', word_class: 'V' },
    { word: 'feoffment', word_class: 'N' }
  ],
  fail: [
    { word: 'fail', word_class: 'V' },
    { word: 'failed', word_class: 'AJ' },
    { word: 'failing', word_class: 'N' },
    { word: 'failure', word_class: 'N' },
    { word: 'failing', word_class: 'AJ' }
  ],
  ecclesiastically: [
    { word: 'ecclesiastic', word_class: 'N' },
    { word: 'ecclesiastic', word_class: 'AJ' },
    { word: 'ecclesiastical', word_class: 'AJ' },
    { word: 'ecclesiastically', word_class: 'AV' }
  ],
  unloading: [
    { word: 'unload', word_class: 'V' },
    { word: 'unloaded', word_class: 'AJ' },
    { word: 'unloading', word_class: 'N' },
    { word: 'unloading', word_class: 'AJ' }
  ],
  slimy: [
    { word: 'slime', word_class: 'N' },
    { word: 'slime', word_class: 'V' },
    { word: 'slimy', word_class: 'AJ' },
    { word: 'slimed', word_class: 'AJ' },
    { word: 'sliminess', word_class: 'N' }
  ],
  transducer: [
    { word: 'transduce', word_class: 'V' },
    { word: 'transducer', word_class: 'N' },
    { word: 'transduction', word_class: 'N' }
  ],
  harnessed: [
    { word: 'harness', word_class: 'N' },
    { word: 'harness', word_class: 'V' },
    { word: 'harnessed', word_class: 'AJ' }
  ],
  dehydrogenisation: [
    { word: 'dehydrogenate', word_class: 'V' },
    { word: 'dehydrogenation', word_class: 'N' },
    { word: 'dehydrogenisation', word_class: 'N' },
    { word: 'dehydrogenization', word_class: 'N' }
  ],
  noduled: [
    { word: 'nodule', word_class: 'N' },
    { word: 'nodular', word_class: 'AJ' },
    { word: 'noduled', word_class: 'AJ' },
    { word: 'nodulated', word_class: 'AJ' }
  ],
  stitching: [
    { word: 'stitch', word_class: 'N' },
    { word: 'stitch', word_class: 'V' },
    { word: 'stitcher', word_class: 'N' },
    { word: 'stitched', word_class: 'AJ' },
    { word: 'stitching', word_class: 'N' }
  ],
  inefficaciously: [
    { word: 'inefficacy', word_class: 'N' },
    { word: 'inefficacious', word_class: 'AJ' },
    { word: 'inefficaciously', word_class: 'AV' },
    { word: 'inefficaciousness', word_class: 'N' }
  ],
  perfective: [
    { word: 'perfect', word_class: 'N' },
    { word: 'perfect', word_class: 'V' },
    { word: 'perfect', word_class: 'AJ' },
    { word: 'perfection', word_class: 'N' },
    { word: 'perfected', word_class: 'AJ' },
    { word: 'perfective', word_class: 'N' },
    { word: 'perfectible', word_class: 'AJ' },
    { word: 'perfectability', word_class: 'N' },
    { word: 'perfectibility', word_class: 'N' }
  ],
  crowded: [
    { word: 'crowd', word_class: 'N' },
    { word: 'crowd', word_class: 'V' },
    { word: 'crowded', word_class: 'AJ' },
    { word: 'crowding', word_class: 'N' }
  ],
  retardation: [
    { word: 'retard', word_class: 'N' },
    { word: 'retard', word_class: 'V' },
    { word: 'retarded', word_class: 'AJ' },
    { word: 'retardment', word_class: 'N' },
    { word: 'retardation', word_class: 'N' }
  ],
  unnoticeably: [
    { word: 'unnoticeable', word_class: 'AJ' },
    { word: 'unnoticeably', word_class: 'AV' },
    { word: 'unnoticeableness', word_class: 'N' }
  ],
  unfavorable: [
    { word: 'unfavorable', word_class: 'AJ' },
    { word: 'unfavorably', word_class: 'AV' },
    { word: 'unfavorableness', word_class: 'N' }
  ],
  cabalist: [
    { word: 'cabal', word_class: 'N' },
    { word: 'cabal', word_class: 'V' },
    { word: 'cabalism', word_class: 'N' },
    { word: 'cabalist', word_class: 'N' },
    { word: 'cabalistic', word_class: 'AJ' }
  ],
  start: [
    { word: 'start', word_class: 'N' },
    { word: 'start', word_class: 'V' },
    { word: 'starter', word_class: 'N' },
    { word: 'starting', word_class: 'N' },
    { word: 'starting', word_class: 'AJ' }
  ],
  instrumentate: [
    { word: 'instrument', word_class: 'N' },
    { word: 'instrument', word_class: 'V' },
    { word: 'instrumental', word_class: 'N' },
    { word: 'instrumental', word_class: 'AJ' },
    { word: 'instrumentate', word_class: 'V' },
    { word: 'instrumentality', word_class: 'N' },
    { word: 'instrumentation', word_class: 'N' }
  ],
  disagreeableness: [
    { word: 'disagree', word_class: 'V' },
    { word: 'disagreement', word_class: 'N' },
    { word: 'disagreeable', word_class: 'AJ' },
    { word: 'disagreeably', word_class: 'AV' },
    { word: 'disagreeableness', word_class: 'N' }
  ],
  neutralist: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  fray: [{ word: 'fray', word_class: 'N' }, { word: 'fray', word_class: 'V' }, { word: 'frayed', word_class: 'AJ' }],
  amends: [
    { word: 'amend', word_class: 'V' },
    { word: 'amends', word_class: 'N' },
    { word: 'amended', word_class: 'AJ' },
    { word: 'amendment', word_class: 'N' },
    { word: 'amendable', word_class: 'AJ' }
  ],
  ectodermic: [
    { word: 'ectoderm', word_class: 'N' },
    { word: 'ectodermal', word_class: 'AJ' },
    { word: 'ectodermic', word_class: 'AJ' }
  ],
  fundamental: [
    { word: 'fundament', word_class: 'N' },
    { word: 'fundamental', word_class: 'N' },
    { word: 'fundamental', word_class: 'AJ' },
    { word: 'fundamentals', word_class: 'N' },
    { word: 'fundamentalism', word_class: 'N' },
    { word: 'fundamentally', word_class: 'AV' }
  ],
  defoliator: [
    { word: 'defoliant', word_class: 'N' },
    { word: 'defoliate', word_class: 'V' },
    { word: 'defoliate', word_class: 'AJ' },
    { word: 'defoliator', word_class: 'N' },
    { word: 'defoliated', word_class: 'AJ' },
    { word: 'defoliation', word_class: 'N' }
  ],
  scandalmonger: [
    { word: 'scandalmonger', word_class: 'N' },
    { word: 'scandalmongering', word_class: 'N' },
    { word: 'scandalmongering', word_class: 'AJ' }
  ],
  big: [{ word: 'big', word_class: 'AJ' }, { word: 'big', word_class: 'AV' }, { word: 'bigness', word_class: 'N' }],
  ideology: [
    { word: 'ideology', word_class: 'N' },
    { word: 'ideologic', word_class: 'AJ' },
    { word: 'ideological', word_class: 'AJ' },
    { word: 'ideologically', word_class: 'AV' }
  ],
  enclose: [
    { word: 'enclose', word_class: 'V' },
    { word: 'enclosed', word_class: 'AJ' },
    { word: 'enclosing', word_class: 'N' },
    { word: 'enclosure', word_class: 'N' }
  ],
  particularised: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  sinking: [
    { word: 'sink', word_class: 'N' },
    { word: 'sink', word_class: 'V' },
    { word: 'sinker', word_class: 'N' },
    { word: 'sinkage', word_class: 'N' },
    { word: 'sinking', word_class: 'N' },
    { word: 'sunken', word_class: 'AJ' }
  ],
  enjoyable: [
    { word: 'enjoy', word_class: 'V' },
    { word: 'enjoyer', word_class: 'N' },
    { word: 'enjoyment', word_class: 'N' },
    { word: 'enjoyable', word_class: 'AJ' },
    { word: 'enjoyably', word_class: 'AV' },
    { word: 'enjoyableness', word_class: 'N' }
  ],
  crimper: [
    { word: 'crimp', word_class: 'N' },
    { word: 'crimp', word_class: 'V' },
    { word: 'crimper', word_class: 'N' }
  ],
  dispersion: [
    { word: 'disperse', word_class: 'V' },
    { word: 'dispersal', word_class: 'N' },
    { word: 'dispersed', word_class: 'AJ' },
    { word: 'dispersion', word_class: 'N' },
    { word: 'dispersive', word_class: 'AJ' }
  ],
  oversubscribe: [
    { word: 'oversubscribe', word_class: 'V' },
    { word: 'oversubscribed', word_class: 'AJ' },
    { word: 'oversubscription', word_class: 'N' }
  ],
  terribleness: [
    { word: 'terrible', word_class: 'AJ' },
    { word: 'terribly', word_class: 'AV' },
    { word: 'terribleness', word_class: 'N' }
  ],
  externalise: [
    { word: 'extern', word_class: 'N' },
    { word: 'exterior', word_class: 'N' },
    { word: 'external', word_class: 'N' },
    { word: 'exterior', word_class: 'AJ' },
    { word: 'external', word_class: 'AJ' },
    { word: 'exteriorise', word_class: 'V' },
    { word: 'exteriorize', word_class: 'V' },
    { word: 'externalise', word_class: 'V' },
    { word: 'externality', word_class: 'N' },
    { word: 'externalize', word_class: 'V' },
    { word: 'externally', word_class: 'AV' },
    { word: 'exteriorisation', word_class: 'N' },
    { word: 'externalisation', word_class: 'N' },
    { word: 'externalization', word_class: 'N' },
    { word: 'exteriorization', word_class: 'N' }
  ],
  fellate: [
    { word: 'fell', word_class: 'N' },
    { word: 'fell', word_class: 'V' },
    { word: 'fell', word_class: 'AJ' },
    { word: 'fellate', word_class: 'V' },
    { word: 'felled', word_class: 'AJ' },
    { word: 'fellation', word_class: 'N' }
  ],
  dependability: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  favorably: [
    { word: 'favor', word_class: 'N' },
    { word: 'favor', word_class: 'V' },
    { word: 'favored', word_class: 'AJ' },
    { word: 'favoring', word_class: 'AJ' },
    { word: 'favorable', word_class: 'AJ' },
    { word: 'favorably', word_class: 'AV' },
    { word: 'favorableness', word_class: 'N' }
  ],
  abridger: [
    { word: 'abridge', word_class: 'V' },
    { word: 'abridger', word_class: 'N' },
    { word: 'abridged', word_class: 'AJ' },
    { word: 'abridgment', word_class: 'N' },
    { word: 'abridgement', word_class: 'N' }
  ],
  whiny: [
    { word: 'whine', word_class: 'N' },
    { word: 'whine', word_class: 'V' },
    { word: 'whiner', word_class: 'N' },
    { word: 'whiny', word_class: 'AJ' },
    { word: 'whining', word_class: 'AJ' }
  ],
  grazed: [
    { word: 'graze', word_class: 'N' },
    { word: 'graze', word_class: 'V' },
    { word: 'grazed', word_class: 'AJ' },
    { word: 'grazing', word_class: 'N' }
  ],
  temperate: [
    { word: 'temper', word_class: 'N' },
    { word: 'temper', word_class: 'V' },
    { word: 'tempera', word_class: 'N' },
    { word: 'tempered', word_class: 'AJ' },
    { word: 'tempering', word_class: 'N' },
    { word: 'temperance', word_class: 'N' },
    { word: 'temperate', word_class: 'AJ' },
    { word: 'tempering', word_class: 'AJ' },
    { word: 'temperament', word_class: 'N' },
    { word: 'hottempered', word_class: 'AJ' },
    { word: 'temperately', word_class: 'AV' },
    { word: 'temperateness', word_class: 'N' }
  ],
  suitor: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  blaring: [
    { word: 'blare', word_class: 'N' },
    { word: 'blare', word_class: 'V' },
    { word: 'blaring', word_class: 'N' },
    { word: 'blaring', word_class: 'AJ' }
  ],
  apostolic: [
    { word: 'apostle', word_class: 'N' },
    { word: 'apostolic', word_class: 'AJ' },
    { word: 'apostolical', word_class: 'AJ' }
  ],
  animating: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  confucian: [
    { word: 'confucian', word_class: 'N' },
    { word: 'confucian', word_class: 'AJ' },
    { word: 'confucianism', word_class: 'N' }
  ],
  conglobation: [
    { word: 'conglobe', word_class: 'V' },
    { word: 'conglobate', word_class: 'V' },
    { word: 'conglobation', word_class: 'N' }
  ],
  lapsing: [
    { word: 'lapse', word_class: 'N' },
    { word: 'lapse', word_class: 'V' },
    { word: 'lapsed', word_class: 'AJ' },
    { word: 'lapsing', word_class: 'N' }
  ],
  exactitude: [
    { word: 'exact', word_class: 'N' },
    { word: 'exact', word_class: 'V' },
    { word: 'exact', word_class: 'AJ' },
    { word: 'exaction', word_class: 'N' },
    { word: 'exacting', word_class: 'AJ' },
    { word: 'exactness', word_class: 'N' },
    { word: 'exactitude', word_class: 'N' }
  ],
  criticality: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  sprawling: [
    { word: 'sprawl', word_class: 'N' },
    { word: 'sprawl', word_class: 'V' },
    { word: 'sprawled', word_class: 'AJ' },
    { word: 'sprawling', word_class: 'N' },
    { word: 'sprawling', word_class: 'AJ' }
  ],
  hebraization: [
    { word: 'hebraise', word_class: 'V' },
    { word: 'hebraize', word_class: 'V' },
    { word: 'hebraisation', word_class: 'N' },
    { word: 'hebraization', word_class: 'N' }
  ],
  dehypnotization: [
    { word: 'dehypnotise', word_class: 'V' },
    { word: 'dehypnotize', word_class: 'V' },
    { word: 'dehypnotisation', word_class: 'N' },
    { word: 'dehypnotization', word_class: 'N' }
  ],
  dissolution: [
    { word: 'dissolve', word_class: 'N' },
    { word: 'dissolve', word_class: 'V' },
    { word: 'dissolver', word_class: 'N' },
    { word: 'dissolute', word_class: 'AJ' },
    { word: 'dissolvent', word_class: 'N' },
    { word: 'dissolved', word_class: 'AJ' },
    { word: 'dissoluble', word_class: 'AJ' },
    { word: 'dissolution', word_class: 'N' },
    { word: 'dissolutely', word_class: 'AV' },
    { word: 'dissolvable', word_class: 'AJ' },
    { word: 'dissolubility', word_class: 'N' },
    { word: 'dissoluteness', word_class: 'N' }
  ],
  unguiculated: [
    { word: 'unguiculate', word_class: 'N' },
    { word: 'unguiculate', word_class: 'AJ' },
    { word: 'unguiculated', word_class: 'AJ' }
  ],
  subtilisation: [
    { word: 'subtilise', word_class: 'V' },
    { word: 'subtilize', word_class: 'V' },
    { word: 'subtilisation', word_class: 'N' },
    { word: 'subtilization', word_class: 'N' }
  ],
  low: [
    { word: 'low', word_class: 'N' },
    { word: 'low', word_class: 'V' },
    { word: 'low', word_class: 'AJ' },
    { word: 'low', word_class: 'AV' },
    { word: 'lowly', word_class: 'AJ' },
    { word: 'lowly', word_class: 'AV' },
    { word: 'lowness', word_class: 'N' }
  ],
  enigmatical: [
    { word: 'enigma', word_class: 'N' },
    { word: 'enigmatic', word_class: 'AJ' },
    { word: 'enigmatical', word_class: 'AJ' },
    { word: 'enigmatically', word_class: 'AV' }
  ],
  epidemiologic: [
    { word: 'epidemiology', word_class: 'N' },
    { word: 'epidemiologic', word_class: 'AJ' },
    { word: 'epidemiological', word_class: 'AJ' }
  ],
  mythicize: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  aesthetical: [
    { word: 'aesthete', word_class: 'N' },
    { word: 'aesthetic', word_class: 'N' },
    { word: 'aesthetic', word_class: 'AJ' },
    { word: 'aesthetics', word_class: 'N' },
    { word: 'aesthetical', word_class: 'AJ' },
    { word: 'aesthetically', word_class: 'AV' }
  ],
  saccharinity: [
    { word: 'saccharin', word_class: 'N' },
    { word: 'saccharine', word_class: 'AJ' },
    { word: 'saccharinity', word_class: 'N' }
  ],
  loaded: [
    { word: 'load', word_class: 'N' },
    { word: 'load', word_class: 'V' },
    { word: 'loads', word_class: 'N' },
    { word: 'loader', word_class: 'N' },
    { word: 'loaded', word_class: 'AJ' },
    { word: 'loading', word_class: 'N' },
    { word: 'loading', word_class: 'AJ' }
  ],
  meddler: [
    { word: 'meddle', word_class: 'V' },
    { word: 'meddler', word_class: 'N' },
    { word: 'meddling', word_class: 'N' },
    { word: 'meddling', word_class: 'AJ' },
    { word: 'meddlesome', word_class: 'AJ' },
    { word: 'meddlesomeness', word_class: 'N' }
  ],
  tackler: [
    { word: 'tackle', word_class: 'N' },
    { word: 'tackle', word_class: 'V' },
    { word: 'tackler', word_class: 'N' },
    { word: 'tackling', word_class: 'N' }
  ],
  startled: [
    { word: 'startle', word_class: 'N' },
    { word: 'startle', word_class: 'V' },
    { word: 'startled', word_class: 'AJ' },
    { word: 'startling', word_class: 'AJ' }
  ],
  exuvial: [
    { word: 'exuvial', word_class: 'AJ' },
    { word: 'exuviate', word_class: 'V' },
    { word: 'exuviation', word_class: 'N' }
  ],
  caffeinic: [
    { word: 'caffein', word_class: 'N' },
    { word: 'caffeine', word_class: 'N' },
    { word: 'caffeinic', word_class: 'AJ' },
    { word: 'caffeinism', word_class: 'N' }
  ],
  stint: [
    { word: 'stint', word_class: 'N' },
    { word: 'stint', word_class: 'V' },
    { word: 'stinting', word_class: 'AJ' }
  ],
  coincident: [
    { word: 'coincide', word_class: 'V' },
    { word: 'coincidence', word_class: 'N' },
    { word: 'coincident', word_class: 'AJ' },
    { word: 'coinciding', word_class: 'AJ' },
    { word: 'coincidental', word_class: 'AJ' },
    { word: 'coincidently', word_class: 'AV' },
    { word: 'coincidentally', word_class: 'AV' }
  ],
  move: [
    { word: 'move', word_class: 'N' },
    { word: 'move', word_class: 'V' },
    { word: 'mover', word_class: 'N' },
    { word: 'moved', word_class: 'AJ' },
    { word: 'moving', word_class: 'N' },
    { word: 'moving', word_class: 'AJ' },
    { word: 'movement', word_class: 'N' }
  ],
  sinusoid: [
    { word: 'sinusoid', word_class: 'N' },
    { word: 'sinusoidal', word_class: 'AJ' },
    { word: 'sinusoidally', word_class: 'AV' }
  ],
  cognise: [
    { word: 'cognise', word_class: 'V' },
    { word: 'cognisance', word_class: 'N' },
    { word: 'cognisant', word_class: 'AJ' },
    { word: 'cognisable', word_class: 'AJ' }
  ],
  lupin: [
    { word: 'lupin', word_class: 'N' },
    { word: 'lupine', word_class: 'N' },
    { word: 'lupine', word_class: 'AJ' }
  ],
  punishing: [
    { word: 'punish', word_class: 'V' },
    { word: 'punished', word_class: 'AJ' },
    { word: 'punishing', word_class: 'AJ' },
    { word: 'punishment', word_class: 'N' },
    { word: 'punishable', word_class: 'AJ' }
  ],
  improbability: [
    { word: 'improbable', word_class: 'AJ' },
    { word: 'improbably', word_class: 'AV' },
    { word: 'improbability', word_class: 'N' },
    { word: 'improbableness', word_class: 'N' }
  ],
  beneficial: [
    { word: 'beneficial', word_class: 'AJ' },
    { word: 'beneficially', word_class: 'AV' },
    { word: 'beneficiation', word_class: 'N' }
  ],
  roiling: [
    { word: 'roil', word_class: 'V' },
    { word: 'roiled', word_class: 'AJ' },
    { word: 'roiling', word_class: 'AJ' }
  ],
  magnanimously: [
    { word: 'magnanimity', word_class: 'N' },
    { word: 'magnanimous', word_class: 'AJ' },
    { word: 'magnanimously', word_class: 'AV' },
    { word: 'magnanimousness', word_class: 'N' }
  ],
  anointment: [
    { word: 'anoint', word_class: 'V' },
    { word: 'anointing', word_class: 'N' },
    { word: 'inunction', word_class: 'N' },
    { word: 'anointment', word_class: 'N' }
  ],
  syncretize: [
    { word: 'syncretise', word_class: 'V' },
    { word: 'syncretize', word_class: 'V' },
    { word: 'syncretisation', word_class: 'N' },
    { word: 'syncretization', word_class: 'N' }
  ],
  dehypnotise: [
    { word: 'dehypnotise', word_class: 'V' },
    { word: 'dehypnotize', word_class: 'V' },
    { word: 'dehypnotisation', word_class: 'N' },
    { word: 'dehypnotization', word_class: 'N' }
  ],
  forestallment: [
    { word: 'forestall', word_class: 'V' },
    { word: 'forestalling', word_class: 'N' },
    { word: 'forestalment', word_class: 'N' },
    { word: 'forestallment', word_class: 'N' }
  ],
  thump: [
    { word: 'thump', word_class: 'N' },
    { word: 'thump', word_class: 'V' },
    { word: 'thumping', word_class: 'N' },
    { word: 'thumping', word_class: 'AJ' }
  ],
  anglicise: [
    { word: 'anglicise', word_class: 'V' },
    { word: 'anglicism', word_class: 'N' },
    { word: 'anglicize', word_class: 'V' },
    { word: 'anglicisation', word_class: 'N' },
    { word: 'anglicization', word_class: 'N' }
  ],
  retraction: [
    { word: 'retract', word_class: 'N' },
    { word: 'retract', word_class: 'V' },
    { word: 'retractor', word_class: 'N' },
    { word: 'retracted', word_class: 'AJ' },
    { word: 'retraction', word_class: 'N' },
    { word: 'retractable', word_class: 'AJ' }
  ],
  rate: [{ word: 'rate', word_class: 'N' }, { word: 'rate', word_class: 'V' }, { word: 'rating', word_class: 'N' }],
  measure: [
    { word: 'measure', word_class: 'N' },
    { word: 'measure', word_class: 'V' },
    { word: 'measured', word_class: 'AJ' },
    { word: 'measuring', word_class: 'N' },
    { word: 'measurement', word_class: 'N' },
    { word: 'measurable', word_class: 'AJ' },
    { word: 'measurably', word_class: 'AV' },
    { word: 'measurability', word_class: 'N' }
  ],
  inhaler: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  involved: [
    { word: 'involve', word_class: 'V' },
    { word: 'involved', word_class: 'AJ' },
    { word: 'involvement', word_class: 'N' }
  ],
  credulous: [
    { word: 'credulity', word_class: 'N' },
    { word: 'credulous', word_class: 'AJ' },
    { word: 'credulously', word_class: 'AV' },
    { word: 'credulousness', word_class: 'N' }
  ],
  entitled: [
    { word: 'entitle', word_class: 'V' },
    { word: 'entitled', word_class: 'AJ' },
    { word: 'entitlement', word_class: 'N' }
  ],
  terrify: [
    { word: 'terror', word_class: 'N' },
    { word: 'terrify', word_class: 'V' },
    { word: 'terrorise', word_class: 'V' },
    { word: 'terrorize', word_class: 'V' },
    { word: 'terrorist', word_class: 'N' },
    { word: 'terrorism', word_class: 'N' },
    { word: 'terrified', word_class: 'AJ' },
    { word: 'terrorist', word_class: 'AJ' },
    { word: 'terrifying', word_class: 'AJ' },
    { word: 'terrorisation', word_class: 'N' },
    { word: 'terrorization', word_class: 'N' }
  ],
  effeminacy: [
    { word: 'effeminacy', word_class: 'N' },
    { word: 'effeminate', word_class: 'V' },
    { word: 'effeminize', word_class: 'V' },
    { word: 'effeminate', word_class: 'AJ' },
    { word: 'effeminateness', word_class: 'N' }
  ],
  explorer: [
    { word: 'explore', word_class: 'V' },
    { word: 'explorer', word_class: 'N' },
    { word: 'exploration', word_class: 'N' },
    { word: 'explorative', word_class: 'AJ' },
    { word: 'exploratory', word_class: 'AJ' }
  ],
  seismologic: [
    { word: 'seismology', word_class: 'N' },
    { word: 'seismologic', word_class: 'AJ' },
    { word: 'seismological', word_class: 'AJ' }
  ],
  butte: [{ word: 'butt', word_class: 'N' }, { word: 'butt', word_class: 'V' }, { word: 'butte', word_class: 'N' }],
  itinerant: [
    { word: 'itinerant', word_class: 'N' },
    { word: 'itinerate', word_class: 'V' },
    { word: 'itinerant', word_class: 'AJ' },
    { word: 'itineration', word_class: 'N' }
  ],
  censorial: [
    { word: 'censor', word_class: 'N' },
    { word: 'censor', word_class: 'V' },
    { word: 'censored', word_class: 'AJ' },
    { word: 'censoring', word_class: 'N' },
    { word: 'censorial', word_class: 'AJ' },
    { word: 'censorship', word_class: 'N' },
    { word: 'censorious', word_class: 'AJ' }
  ],
  rhapsodically: [
    { word: 'rhapsody', word_class: 'N' },
    { word: 'rhapsodic', word_class: 'AJ' },
    { word: 'rhapsodise', word_class: 'V' },
    { word: 'rhapsodize', word_class: 'V' },
    { word: 'rhapsodically', word_class: 'AV' },
    { word: 'rhapsodization', word_class: 'N' }
  ],
  purulency: [
    { word: 'purulence', word_class: 'N' },
    { word: 'purulency', word_class: 'N' },
    { word: 'purulent', word_class: 'AJ' }
  ],
  conventionalize: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  insurgency: [
    { word: 'insurgent', word_class: 'N' },
    { word: 'insurgency', word_class: 'N' },
    { word: 'insurgent', word_class: 'AJ' }
  ],
  kick: [
    { word: 'kick', word_class: 'N' },
    { word: 'kick', word_class: 'V' },
    { word: 'kicker', word_class: 'N' },
    { word: 'kicking', word_class: 'N' }
  ],
  vegetarian: [
    { word: 'vegetarian', word_class: 'N' },
    { word: 'vegetarian', word_class: 'AJ' },
    { word: 'vegetarianism', word_class: 'N' }
  ],
  oration: [
    { word: 'orate', word_class: 'V' },
    { word: 'orator', word_class: 'N' },
    { word: 'oration', word_class: 'N' }
  ],
  attritional: [
    { word: 'attrited', word_class: 'AJ' },
    { word: 'attrition', word_class: 'N' },
    { word: 'attritional', word_class: 'AJ' }
  ],
  cancelled: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  sugared: [
    { word: 'sugar', word_class: 'N' },
    { word: 'sugar', word_class: 'V' },
    { word: 'sugared', word_class: 'AJ' }
  ],
  train: [
    { word: 'train', word_class: 'N' },
    { word: 'train', word_class: 'V' },
    { word: 'trainee', word_class: 'N' },
    { word: 'trainer', word_class: 'N' },
    { word: 'trained', word_class: 'AJ' },
    { word: 'trainful', word_class: 'N' },
    { word: 'training', word_class: 'N' },
    { word: 'trainman', word_class: 'N' },
    { word: 'trainful', word_class: 'AJ' }
  ],
  conjunction: [
    { word: 'conjoin', word_class: 'V' },
    { word: 'conjunct', word_class: 'N' },
    { word: 'conjunct', word_class: 'AJ' },
    { word: 'conjoined', word_class: 'AJ' },
    { word: 'conjunction', word_class: 'N' },
    { word: 'conjunctive', word_class: 'N' },
    { word: 'conjunctive', word_class: 'AJ' },
    { word: 'conjunctivitis', word_class: 'N' }
  ],
  surmounted: [
    { word: 'surmount', word_class: 'V' },
    { word: 'surmounted', word_class: 'AJ' },
    { word: 'surmountable', word_class: 'AJ' }
  ],
  diazotize: [
    { word: 'diazotise', word_class: 'V' },
    { word: 'diazotize', word_class: 'V' },
    { word: 'diazotisation', word_class: 'N' },
    { word: 'diazotization', word_class: 'N' }
  ],
  syllogization: [
    { word: 'syllogise', word_class: 'V' },
    { word: 'syllogism', word_class: 'N' },
    { word: 'syllogize', word_class: 'V' },
    { word: 'syllogisation', word_class: 'N' },
    { word: 'syllogization', word_class: 'N' }
  ],
  engine: [
    { word: 'engine', word_class: 'N' },
    { word: 'engineer', word_class: 'N' },
    { word: 'engineer', word_class: 'V' },
    { word: 'engineering', word_class: 'N' }
  ],
  sandblaster: [
    { word: 'sandblast', word_class: 'N' },
    { word: 'sandblast', word_class: 'V' },
    { word: 'sandblaster', word_class: 'N' }
  ],
  dope: [{ word: 'dope', word_class: 'N' }, { word: 'dope', word_class: 'V' }, { word: 'doped', word_class: 'AJ' }],
  anarchically: [
    { word: 'anarch', word_class: 'N' },
    { word: 'anarchic', word_class: 'AJ' },
    { word: 'anarchism', word_class: 'N' },
    { word: 'anarchical', word_class: 'AJ' },
    { word: 'anarchically', word_class: 'AV' }
  ],
  vexatious: [
    { word: 'vex', word_class: 'V' },
    { word: 'vexed', word_class: 'AJ' },
    { word: 'vexing', word_class: 'AJ' },
    { word: 'vexation', word_class: 'N' },
    { word: 'vexatious', word_class: 'AJ' }
  ],
  episodically: [
    { word: 'episode', word_class: 'N' },
    { word: 'episodic', word_class: 'AJ' },
    { word: 'episodically', word_class: 'AV' }
  ],
  garlicked: [
    { word: 'garlic', word_class: 'N' },
    { word: 'garlicky', word_class: 'AJ' },
    { word: 'garlicked', word_class: 'AJ' }
  ],
  mould: [
    { word: 'mould', word_class: 'N' },
    { word: 'mould', word_class: 'V' },
    { word: 'moult', word_class: 'N' },
    { word: 'moult', word_class: 'V' },
    { word: 'moulding', word_class: 'N' },
    { word: 'moulting', word_class: 'N' }
  ],
  compartmentalisation: [
    { word: 'compartmental', word_class: 'AJ' },
    { word: 'compartmentalise', word_class: 'V' },
    { word: 'compartmentalize', word_class: 'V' },
    { word: 'compartmentalized', word_class: 'AJ' },
    { word: 'compartmentalisation', word_class: 'N' },
    { word: 'compartmentalization', word_class: 'N' }
  ],
  formalisation: [
    { word: 'formal', word_class: 'N' },
    { word: 'formal', word_class: 'AJ' },
    { word: 'formalise', word_class: 'V' },
    { word: 'formalism', word_class: 'N' },
    { word: 'formalize', word_class: 'V' },
    { word: 'formality', word_class: 'N' },
    { word: 'formally', word_class: 'AV' },
    { word: 'formalness', word_class: 'N' },
    { word: 'formalized', word_class: 'AJ' },
    { word: 'formalities', word_class: 'N' },
    { word: 'formalisation', word_class: 'N' },
    { word: 'formalization', word_class: 'N' }
  ],
  determining: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  manufactured: [
    { word: 'manufacture', word_class: 'N' },
    { word: 'manufacture', word_class: 'V' },
    { word: 'manufacturer', word_class: 'N' },
    { word: 'manufactured', word_class: 'AJ' },
    { word: 'manufacturing', word_class: 'N' }
  ],
  enlarge: [
    { word: 'enlarge', word_class: 'V' },
    { word: 'enlarger', word_class: 'N' },
    { word: 'enlarged', word_class: 'AJ' },
    { word: 'enlargement', word_class: 'N' }
  ],
  ans: [{ word: 'an', word_class: 'N' }, { word: 'ans', word_class: 'N' }, { word: 'ane', word_class: 'AJ' }],
  welding: [
    { word: 'weld', word_class: 'N' },
    { word: 'weld', word_class: 'V' },
    { word: 'welder', word_class: 'N' },
    { word: 'welding', word_class: 'N' },
    { word: 'weldment', word_class: 'N' }
  ],
  immigrant: [
    { word: 'immigrant', word_class: 'N' },
    { word: 'immigrate', word_class: 'V' },
    { word: 'immigration', word_class: 'N' }
  ],
  astrology: [
    { word: 'astrology', word_class: 'N' },
    { word: 'astrologer', word_class: 'N' },
    { word: 'astrological', word_class: 'AJ' }
  ],
  objection: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  welsh: [
    { word: 'welsh', word_class: 'N' },
    { word: 'welsh', word_class: 'V' },
    { word: 'welsh', word_class: 'AJ' },
    { word: 'welsher', word_class: 'N' },
    { word: 'welshman', word_class: 'N' }
  ],
  turgid: [
    { word: 'turgid', word_class: 'AJ' },
    { word: 'turgidity', word_class: 'N' },
    { word: 'turgidness', word_class: 'N' }
  ],
  wholesomeness: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  satirize: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  fervency: [
    { word: 'fervency', word_class: 'N' },
    { word: 'fervent', word_class: 'AJ' },
    { word: 'fervently', word_class: 'AV' }
  ],
  teens: [{ word: 'teen', word_class: 'N' }, { word: 'teen', word_class: 'AJ' }, { word: 'teens', word_class: 'N' }],
  motorisation: [
    { word: 'motor', word_class: 'N' },
    { word: 'motor', word_class: 'V' },
    { word: 'motor', word_class: 'AJ' },
    { word: 'motorise', word_class: 'V' },
    { word: 'motorize', word_class: 'V' },
    { word: 'motorist', word_class: 'N' },
    { word: 'motored', word_class: 'AJ' },
    { word: 'motoring', word_class: 'N' },
    { word: 'motorman', word_class: 'N' },
    { word: 'motorized', word_class: 'AJ' },
    { word: 'motorisation', word_class: 'N' },
    { word: 'motorization', word_class: 'N' }
  ],
  launch: [
    { word: 'launch', word_class: 'N' },
    { word: 'launch', word_class: 'V' },
    { word: 'launcher', word_class: 'N' },
    { word: 'launching', word_class: 'N' }
  ],
  peaceably: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  magnetic: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  contained: [
    { word: 'contain', word_class: 'V' },
    { word: 'container', word_class: 'N' },
    { word: 'contained', word_class: 'AJ' },
    { word: 'containment', word_class: 'N' },
    { word: 'containerful', word_class: 'N' }
  ],
  installing: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  tubing: [
    { word: 'tube', word_class: 'N' },
    { word: 'tube', word_class: 'V' },
    { word: 'tubed', word_class: 'AJ' },
    { word: 'tubing', word_class: 'N' },
    { word: 'tubular', word_class: 'AJ' }
  ],
  flocculate: [
    { word: 'floccule', word_class: 'N' },
    { word: 'flocculate', word_class: 'V' },
    { word: 'flocculent', word_class: 'AJ' },
    { word: 'flocculation', word_class: 'N' }
  ],
  multiplexer: [
    { word: 'multiplex', word_class: 'N' },
    { word: 'multiplex', word_class: 'V' },
    { word: 'multiplex', word_class: 'AJ' },
    { word: 'multiplexer', word_class: 'N' }
  ],
  antinomian: [
    { word: 'antinomian', word_class: 'N' },
    { word: 'antinomian', word_class: 'AJ' },
    { word: 'antinomianism', word_class: 'N' }
  ],
  use: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  urinal: [
    { word: 'urine', word_class: 'N' },
    { word: 'urinal', word_class: 'N' },
    { word: 'urinal', word_class: 'AJ' },
    { word: 'urinate', word_class: 'V' },
    { word: 'urinary', word_class: 'AJ' },
    { word: 'urination', word_class: 'N' }
  ],
  disconcertment: [
    { word: 'disconcert', word_class: 'N' },
    { word: 'disconcert', word_class: 'V' },
    { word: 'disconcerted', word_class: 'AJ' },
    { word: 'disconcertion', word_class: 'N' },
    { word: 'disconcerting', word_class: 'AJ' },
    { word: 'disconcertment', word_class: 'N' }
  ],
  prompt: [
    { word: 'prompt', word_class: 'N' },
    { word: 'prompt', word_class: 'V' },
    { word: 'prompt', word_class: 'AJ' },
    { word: 'prompter', word_class: 'N' },
    { word: 'prompting', word_class: 'N' },
    { word: 'promptness', word_class: 'N' }
  ],
  flattery: [
    { word: 'flatter', word_class: 'V' },
    { word: 'flattery', word_class: 'N' },
    { word: 'flatterer', word_class: 'N' },
    { word: 'flattering', word_class: 'AJ' }
  ],
  appreciative: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  amoralism: [
    { word: 'amor', word_class: 'N' },
    { word: 'amoral', word_class: 'AJ' },
    { word: 'amorous', word_class: 'AJ' },
    { word: 'amoralism', word_class: 'N' },
    { word: 'amorally', word_class: 'AV' },
    { word: 'amorously', word_class: 'AV' },
    { word: 'amorousness', word_class: 'N' }
  ],
  putterer: [
    { word: 'putt', word_class: 'N' },
    { word: 'putt', word_class: 'V' },
    { word: 'puttee', word_class: 'N' },
    { word: 'putter', word_class: 'N' },
    { word: 'putter', word_class: 'V' },
    { word: 'puttees', word_class: 'N' },
    { word: 'putterer', word_class: 'N' }
  ],
  vaccine: [
    { word: 'vaccine', word_class: 'N' },
    { word: 'vaccinate', word_class: 'V' },
    { word: 'vaccinated', word_class: 'AJ' },
    { word: 'vaccinating', word_class: 'N' },
    { word: 'vaccination', word_class: 'N' }
  ],
  intoxicate: [
    { word: 'intoxicant', word_class: 'N' },
    { word: 'intoxicate', word_class: 'V' },
    { word: 'intoxicant', word_class: 'AJ' },
    { word: 'intoxicated', word_class: 'AJ' },
    { word: 'intoxication', word_class: 'N' },
    { word: 'intoxicating', word_class: 'AJ' }
  ],
  impalempalement: [
    { word: 'impale', word_class: 'V' },
    { word: 'impale', word_class: 'AJ' },
    { word: 'impalement', word_class: 'N' },
    { word: 'impalempale', word_class: 'V' },
    { word: 'impalempalement', word_class: 'N' }
  ],
  oust: [{ word: 'oust', word_class: 'V' }, { word: 'ouster', word_class: 'N' }, { word: 'ousting', word_class: 'N' }],
  scorcher: [
    { word: 'scorch', word_class: 'N' },
    { word: 'scorch', word_class: 'V' },
    { word: 'scorcher', word_class: 'N' },
    { word: 'scorched', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AJ' },
    { word: 'scorching', word_class: 'AV' }
  ],
  sociableness: [
    { word: 'sociable', word_class: 'N' },
    { word: 'sociable', word_class: 'AJ' },
    { word: 'sociably', word_class: 'AV' },
    { word: 'sociability', word_class: 'N' },
    { word: 'sociableness', word_class: 'N' }
  ],
  itemisation: [
    { word: 'item', word_class: 'N' },
    { word: 'item', word_class: 'AV' },
    { word: 'itemise', word_class: 'V' },
    { word: 'itemize', word_class: 'V' },
    { word: 'itemisation', word_class: 'N' },
    { word: 'itemization', word_class: 'N' }
  ],
  gimbaled: [
    { word: 'gimbal', word_class: 'N' },
    { word: 'gimbal', word_class: 'V' },
    { word: 'gimbals', word_class: 'N' },
    { word: 'gimbaled', word_class: 'AJ' }
  ],
  swelled: [
    { word: 'swell', word_class: 'N' },
    { word: 'swell', word_class: 'V' },
    { word: 'swell', word_class: 'AJ' },
    { word: 'swelled', word_class: 'AJ' },
    { word: 'swelling', word_class: 'N' },
    { word: 'swollen', word_class: 'AJ' },
    { word: 'swelling', word_class: 'AJ' }
  ],
  grunt: [
    { word: 'grunt', word_class: 'N' },
    { word: 'grunt', word_class: 'V' },
    { word: 'grunting', word_class: 'AJ' }
  ],
  osmose: [
    { word: 'osmose', word_class: 'V' },
    { word: 'osmosis', word_class: 'N' },
    { word: 'osmotic', word_class: 'AJ' },
    { word: 'osmotically', word_class: 'AV' }
  ],
  sworn: [
    { word: 'swear', word_class: 'V' },
    { word: 'sworn', word_class: 'AJ' },
    { word: 'swearer', word_class: 'N' },
    { word: 'swearing', word_class: 'N' }
  ],
  ir: [
    { word: 'ir', word_class: 'N' },
    { word: 'ire', word_class: 'N' },
    { word: 'irs', word_class: 'N' },
    { word: 'ireful', word_class: 'AJ' }
  ],
  straggler: [
    { word: 'straggle', word_class: 'N' },
    { word: 'straggle', word_class: 'V' },
    { word: 'straggler', word_class: 'N' },
    { word: 'straggling', word_class: 'AJ' }
  ],
  gelatinisation: [
    { word: 'gelatin', word_class: 'N' },
    { word: 'gelatine', word_class: 'N' },
    { word: 'gelatinise', word_class: 'V' },
    { word: 'gelatinize', word_class: 'V' },
    { word: 'gelatinous', word_class: 'AJ' },
    { word: 'gelatinisation', word_class: 'N' },
    { word: 'gelatinization', word_class: 'N' },
    { word: 'gelatinousness', word_class: 'N' }
  ],
  still: [
    { word: 'still', word_class: 'N' },
    { word: 'still', word_class: 'V' },
    { word: 'still', word_class: 'AJ' },
    { word: 'still', word_class: 'AV' },
    { word: 'stillness', word_class: 'N' }
  ],
  vicariously: [
    { word: 'vicarial', word_class: 'AJ' },
    { word: 'vicariate', word_class: 'N' },
    { word: 'vicarious', word_class: 'AJ' },
    { word: 'vicariously', word_class: 'AV' }
  ],
  luxemburg: [
    { word: 'luxemburg', word_class: 'N' },
    { word: 'luxemburger', word_class: 'N' },
    { word: 'luxemburger', word_class: 'AJ' }
  ],
  attender: [
    { word: 'attend', word_class: 'V' },
    { word: 'attender', word_class: 'N' },
    { word: 'attention', word_class: 'N' },
    { word: 'attendant', word_class: 'N' },
    { word: 'attended', word_class: 'AJ' },
    { word: 'attending', word_class: 'N' },
    { word: 'attendance', word_class: 'N' },
    { word: 'attentive', word_class: 'AJ' },
    { word: 'attending', word_class: 'AJ' },
    { word: 'attendant', word_class: 'AJ' },
    { word: 'attentional', word_class: 'AJ' },
    { word: 'attentively', word_class: 'AV' },
    { word: 'attentiveness', word_class: 'N' }
  ],
  letting: [{ word: 'let', word_class: 'N' }, { word: 'let', word_class: 'V' }, { word: 'letting', word_class: 'N' }],
  enlightening: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  accumulation: [
    { word: 'accumulate', word_class: 'V' },
    { word: 'accumulator', word_class: 'N' },
    { word: 'accumulated', word_class: 'AJ' },
    { word: 'accumulation', word_class: 'N' },
    { word: 'accumulative', word_class: 'AJ' }
  ],
  tropicalisation: [
    { word: 'tropic', word_class: 'N' },
    { word: 'tropic', word_class: 'AJ' },
    { word: 'tropics', word_class: 'N' },
    { word: 'tropical', word_class: 'AJ' },
    { word: 'tropicalise', word_class: 'V' },
    { word: 'tropicalize', word_class: 'V' },
    { word: 'tropically', word_class: 'AV' },
    { word: 'tropicalisation', word_class: 'N' },
    { word: 'tropicalization', word_class: 'N' }
  ],
  crank: [{ word: 'crank', word_class: 'N' }, { word: 'crank', word_class: 'V' }, { word: 'crank', word_class: 'AJ' }],
  sacrifice: [
    { word: 'sacrifice', word_class: 'N' },
    { word: 'sacrifice', word_class: 'V' },
    { word: 'sacrificeable', word_class: 'AJ' }
  ],
  ladened: [
    { word: 'lade', word_class: 'V' },
    { word: 'laden', word_class: 'V' },
    { word: 'laden', word_class: 'AJ' },
    { word: 'lading', word_class: 'N' },
    { word: 'ladened', word_class: 'AJ' }
  ],
  byzantinism: [
    { word: 'byzantine', word_class: 'N' },
    { word: 'byzantine', word_class: 'AJ' },
    { word: 'byzantinism', word_class: 'N' }
  ],
  nativity: [
    { word: 'native', word_class: 'N' },
    { word: 'native', word_class: 'AJ' },
    { word: 'nativism', word_class: 'N' },
    { word: 'nativity', word_class: 'N' },
    { word: 'nativeness', word_class: 'N' }
  ],
  objectionableness: [
    { word: 'object', word_class: 'N' },
    { word: 'object', word_class: 'V' },
    { word: 'objector', word_class: 'N' },
    { word: 'objection', word_class: 'N' },
    { word: 'objective', word_class: 'N' },
    { word: 'objective', word_class: 'AJ' },
    { word: 'objectivity', word_class: 'N' },
    { word: 'objectively', word_class: 'AV' },
    { word: 'objectiveness', word_class: 'N' },
    { word: 'objectionable', word_class: 'AJ' },
    { word: 'objectionably', word_class: 'AV' },
    { word: 'objectionability', word_class: 'N' },
    { word: 'objectionability', word_class: 'AJ' },
    { word: 'objectionableness', word_class: 'N' }
  ],
  cinematographer: [
    { word: 'cinematograph', word_class: 'V' },
    { word: 'cinematography', word_class: 'N' },
    { word: 'cinematographer', word_class: 'N' }
  ],
  fretful: [
    { word: 'fret', word_class: 'N' },
    { word: 'fret', word_class: 'V' },
    { word: 'fretful', word_class: 'AJ' },
    { word: 'fretted', word_class: 'AJ' },
    { word: 'fretfulness', word_class: 'N' }
  ],
  brittleness: [
    { word: 'brittle', word_class: 'N' },
    { word: 'brittle', word_class: 'AJ' },
    { word: 'brittleness', word_class: 'N' }
  ],
  kissing: [
    { word: 'kiss', word_class: 'N' },
    { word: 'kiss', word_class: 'V' },
    { word: 'kisser', word_class: 'N' },
    { word: 'kissing', word_class: 'N' }
  ],
  returnable: [
    { word: 'return', word_class: 'N' },
    { word: 'return', word_class: 'V' },
    { word: 'returning', word_class: 'AJ' },
    { word: 'returnable', word_class: 'AJ' }
  ],
  apocalyptic: [
    { word: 'apocalypse', word_class: 'N' },
    { word: 'apocalyptic', word_class: 'AJ' },
    { word: 'apocalyptical', word_class: 'AJ' }
  ],
  benignity: [
    { word: 'benign', word_class: 'AJ' },
    { word: 'benignity', word_class: 'N' },
    { word: 'benignancy', word_class: 'N' },
    { word: 'benignant', word_class: 'AJ' }
  ],
  solvate: [
    { word: 'solve', word_class: 'V' },
    { word: 'solute', word_class: 'N' },
    { word: 'solvate', word_class: 'V' },
    { word: 'solvate', word_class: 'N' },
    { word: 'solvent', word_class: 'N' },
    { word: 'solved', word_class: 'AJ' },
    { word: 'solving', word_class: 'N' },
    { word: 'solution', word_class: 'N' },
    { word: 'solvency', word_class: 'N' },
    { word: 'solvent', word_class: 'AJ' },
    { word: 'solvation', word_class: 'N' }
  ],
  commonplace: [
    { word: 'commonplace', word_class: 'N' },
    { word: 'commonplace', word_class: 'AJ' },
    { word: 'commonplaceness', word_class: 'N' }
  ],
  fabrication: [
    { word: 'fabric', word_class: 'N' },
    { word: 'fabricate', word_class: 'V' },
    { word: 'fabricator', word_class: 'N' },
    { word: 'fabricated', word_class: 'AJ' },
    { word: 'fabrication', word_class: 'N' }
  ],
  chronologize: [
    { word: 'chronology', word_class: 'N' },
    { word: 'chronologize', word_class: 'V' },
    { word: 'chronological', word_class: 'AJ' },
    { word: 'chronologically', word_class: 'AV' }
  ],
  spirited: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  modernness: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  itch: [
    { word: 'itch', word_class: 'N' },
    { word: 'itch', word_class: 'V' },
    { word: 'itchy', word_class: 'AJ' },
    { word: 'itching', word_class: 'N' },
    { word: 'itchiness', word_class: 'N' }
  ],
  prescience: [
    { word: 'prescience', word_class: 'N' },
    { word: 'prescient', word_class: 'AJ' },
    { word: 'presciently', word_class: 'AV' }
  ],
  potman: [
    { word: 'pot', word_class: 'N' },
    { word: 'pot', word_class: 'V' },
    { word: 'potful', word_class: 'N' },
    { word: 'potion', word_class: 'N' },
    { word: 'potman', word_class: 'N' },
    { word: 'potful', word_class: 'AJ' },
    { word: 'potted', word_class: 'AJ' },
    { word: 'pottery', word_class: 'N' },
    { word: 'potation', word_class: 'N' },
    { word: 'pottery', word_class: 'AJ' }
  ],
  clarification: [
    { word: 'clarify', word_class: 'V' },
    { word: 'clarified', word_class: 'AJ' },
    { word: 'clarifying', word_class: 'AJ' },
    { word: 'clarification', word_class: 'N' }
  ],
  conspire: [
    { word: 'conspire', word_class: 'V' },
    { word: 'conspiracy', word_class: 'N' },
    { word: 'conspirator', word_class: 'N' },
    { word: 'conspirative', word_class: 'AJ' }
  ],
  subsequent: [
    { word: 'subsequence', word_class: 'N' },
    { word: 'subsequent', word_class: 'AJ' },
    { word: 'subsequently', word_class: 'AV' },
    { word: 'subsequentness', word_class: 'N' }
  ],
  wrecked: [
    { word: 'wreck', word_class: 'N' },
    { word: 'wreck', word_class: 'V' },
    { word: 'wrecker', word_class: 'N' },
    { word: 'wrecked', word_class: 'AJ' },
    { word: 'wrecking', word_class: 'N' }
  ],
  encrustation: [
    { word: 'encrust', word_class: 'V' },
    { word: 'incrust', word_class: 'V' },
    { word: 'encrusted', word_class: 'AJ' },
    { word: 'encrustation', word_class: 'N' },
    { word: 'incrustation', word_class: 'N' }
  ],
  deterrence: [
    { word: 'deter', word_class: 'V' },
    { word: 'determent', word_class: 'N' },
    { word: 'deterrent', word_class: 'N' },
    { word: 'deterrence', word_class: 'N' },
    { word: 'deterrent', word_class: 'AJ' }
  ],
  stupefaction: [
    { word: 'stupefy', word_class: 'V' },
    { word: 'stupify', word_class: 'V' },
    { word: 'stupefied', word_class: 'AJ' },
    { word: 'stupefying', word_class: 'AJ' },
    { word: 'stupefaction', word_class: 'N' }
  ],
  highbrowed: [
    { word: 'highbrow', word_class: 'N' },
    { word: 'highbrow', word_class: 'AJ' },
    { word: 'highbrowed', word_class: 'AJ' }
  ],
  orientalise: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  reproving: [
    { word: 'reprove', word_class: 'V' },
    { word: 'reproval', word_class: 'N' },
    { word: 'reproved', word_class: 'AJ' },
    { word: 'reproving', word_class: 'AJ' }
  ],
  obtrusion: [
    { word: 'obtrude', word_class: 'V' },
    { word: 'obtrusion', word_class: 'N' },
    { word: 'obtrusive', word_class: 'AJ' },
    { word: 'obtrusively', word_class: 'AV' },
    { word: 'obtrusiveness', word_class: 'N' }
  ],
  descent: [
    { word: 'descend', word_class: 'V' },
    { word: 'descent', word_class: 'N' },
    { word: 'descendant', word_class: 'N' },
    { word: 'descendent', word_class: 'N' },
    { word: 'descendant', word_class: 'AJ' },
    { word: 'descendants', word_class: 'N' },
    { word: 'descendent', word_class: 'AJ' },
    { word: 'descending', word_class: 'AJ' }
  ],
  rude: [
    { word: 'rude', word_class: 'AJ' },
    { word: 'rudely', word_class: 'AV' },
    { word: 'rudeness', word_class: 'N' }
  ],
  grovelling: [
    { word: 'grovel', word_class: 'V' },
    { word: 'groveller', word_class: 'N' },
    { word: 'groveling', word_class: 'AJ' },
    { word: 'grovelling', word_class: 'AJ' }
  ],
  disparaging: [
    { word: 'disparage', word_class: 'N' },
    { word: 'disparage', word_class: 'V' },
    { word: 'disparager', word_class: 'N' },
    { word: 'disparaging', word_class: 'AJ' },
    { word: 'disparagement', word_class: 'N' }
  ],
  befuddle: [
    { word: 'befuddle', word_class: 'V' },
    { word: 'befuddled', word_class: 'AJ' },
    { word: 'befuddlement', word_class: 'N' }
  ],
  replacement: [
    { word: 'replace', word_class: 'V' },
    { word: 'replacing', word_class: 'N' },
    { word: 'eplacement', word_class: 'N' },
    { word: 'replacement', word_class: 'N' },
    { word: 'replaceable', word_class: 'AJ' },
    { word: 'replaceability', word_class: 'N' }
  ],
  sanctification: [
    { word: 'sanctify', word_class: 'V' },
    { word: 'sanctified', word_class: 'AJ' },
    { word: 'sanctification', word_class: 'N' }
  ],
  decorousness: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  statically: [
    { word: 'static', word_class: 'N' },
    { word: 'static', word_class: 'AJ' },
    { word: 'statice', word_class: 'N' },
    { word: 'statics', word_class: 'N' },
    { word: 'statically', word_class: 'AV' }
  ],
  apologetics: [
    { word: 'apology', word_class: 'N' },
    { word: 'apologist', word_class: 'N' },
    { word: 'apologise', word_class: 'V' },
    { word: 'apologize', word_class: 'V' },
    { word: 'apologetic', word_class: 'AJ' },
    { word: 'apologetics', word_class: 'N' },
    { word: 'apologetically', word_class: 'AV' }
  ],
  luminance: [
    { word: 'luminal', word_class: 'N' },
    { word: 'luminance', word_class: 'N' },
    { word: 'luminous', word_class: 'AJ' },
    { word: 'luminousness', word_class: 'N' }
  ],
  trier: [
    { word: 'try', word_class: 'N' },
    { word: 'try', word_class: 'V' },
    { word: 'trial', word_class: 'N' },
    { word: 'trier', word_class: 'N' },
    { word: 'tryst', word_class: 'N' },
    { word: 'trial', word_class: 'AJ' },
    { word: 'trying', word_class: 'AJ' }
  ],
  streamer: [
    { word: 'stream', word_class: 'N' },
    { word: 'stream', word_class: 'V' },
    { word: 'streamer', word_class: 'N' },
    { word: 'streaming', word_class: 'N' },
    { word: 'streaming', word_class: 'AJ' }
  ],
  platinisation: [
    { word: 'platinise', word_class: 'V' },
    { word: 'platinize', word_class: 'V' },
    { word: 'platinisation', word_class: 'N' },
    { word: 'platinization', word_class: 'N' }
  ],
  shaken: [
    { word: 'shake', word_class: 'N' },
    { word: 'shake', word_class: 'V' },
    { word: 'shaky', word_class: 'AJ' },
    { word: 'shaker', word_class: 'N' },
    { word: 'shaken', word_class: 'AJ' },
    { word: 'shaking', word_class: 'N' },
    { word: 'shaking', word_class: 'AJ' },
    { word: 'shakiness', word_class: 'N' }
  ],
  breeziness: [
    { word: 'breeze', word_class: 'N' },
    { word: 'breeze', word_class: 'V' },
    { word: 'breezy', word_class: 'AJ' },
    { word: 'breeziness', word_class: 'N' }
  ],
  varnish: [
    { word: 'varnish', word_class: 'N' },
    { word: 'varnish', word_class: 'V' },
    { word: 'varnished', word_class: 'AJ' }
  ],
  answerableness: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  woodworker: [
    { word: 'woodwork', word_class: 'N' },
    { word: 'woodworker', word_class: 'N' },
    { word: 'woodworking', word_class: 'N' }
  ],
  remandment: [
    { word: 'remand', word_class: 'N' },
    { word: 'remand', word_class: 'V' },
    { word: 'remandment', word_class: 'N' }
  ],
  arterialize: [
    { word: 'artery', word_class: 'N' },
    { word: 'arterial', word_class: 'AJ' },
    { word: 'arterialise', word_class: 'V' },
    { word: 'arterialize', word_class: 'V' },
    { word: 'arterialisation', word_class: 'N' },
    { word: 'arterialization', word_class: 'N' }
  ],
  gazette: [
    { word: 'gazette', word_class: 'N' },
    { word: 'gazette', word_class: 'V' },
    { word: 'gazetteer', word_class: 'N' }
  ],
  criticalness: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  luting: [
    { word: 'lute', word_class: 'N' },
    { word: 'lute', word_class: 'V' },
    { word: 'luting', word_class: 'N' },
    { word: 'lutist', word_class: 'N' },
    { word: 'lutanist', word_class: 'N' }
  ],
  separate: [
    { word: 'separate', word_class: 'N' },
    { word: 'separate', word_class: 'V' },
    { word: 'separate', word_class: 'AJ' },
    { word: 'separator', word_class: 'N' },
    { word: 'separable', word_class: 'AJ' },
    { word: 'separably', word_class: 'AV' },
    { word: 'separation', word_class: 'N' },
    { word: 'separatist', word_class: 'N' },
    { word: 'separated', word_class: 'AJ' },
    { word: 'separately', word_class: 'AV' },
    { word: 'separatist', word_class: 'AJ' },
    { word: 'separative', word_class: 'AJ' },
    { word: 'separability', word_class: 'N' },
    { word: 'separateness', word_class: 'N' },
    { word: 'separability', word_class: 'AJ' }
  ],
  versify: [
    { word: 'versify', word_class: 'V' },
    { word: 'versifier', word_class: 'N' },
    { word: 'versification', word_class: 'N' }
  ],
  differential: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  difference: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  banded: [
    { word: 'band', word_class: 'N' },
    { word: 'band', word_class: 'V' },
    { word: 'banded', word_class: 'AJ' },
    { word: 'banding', word_class: 'N' }
  ],
  considered: [
    { word: 'consider', word_class: 'V' },
    { word: 'considered', word_class: 'AJ' },
    { word: 'considerate', word_class: 'AJ' },
    { word: 'considerable', word_class: 'AJ' },
    { word: 'consideration', word_class: 'N' },
    { word: 'considerably', word_class: 'AV' },
    { word: 'considerately', word_class: 'AV' },
    { word: 'considerateness', word_class: 'N' }
  ],
  galvanic: [
    { word: 'galvanise', word_class: 'V' },
    { word: 'galvanic', word_class: 'AJ' },
    { word: 'galvanize', word_class: 'V' },
    { word: 'galvanism', word_class: 'N' },
    { word: 'galvanizing', word_class: 'N' },
    { word: 'galvanizing', word_class: 'AJ' },
    { word: 'galvanisation', word_class: 'N' },
    { word: 'galvanization', word_class: 'N' }
  ],
  mat: [
    { word: 'mat', word_class: 'N' },
    { word: 'mat', word_class: 'V' },
    { word: 'matt', word_class: 'N' },
    { word: 'matt', word_class: 'V' },
    { word: 'mat', word_class: 'AJ' },
    { word: 'matt', word_class: 'AJ' },
    { word: 'matte', word_class: 'N' },
    { word: 'matman', word_class: 'N' },
    { word: 'matte', word_class: 'AJ' },
    { word: 'matted', word_class: 'AJ' },
    { word: 'matting', word_class: 'N' }
  ],
  remuneration: [
    { word: 'remunerate', word_class: 'V' },
    { word: 'remunerator', word_class: 'N' },
    { word: 'remunerated', word_class: 'AJ' },
    { word: 'remuneration', word_class: 'N' }
  ],
  sixty: [
    { word: 'sixty', word_class: 'N' },
    { word: 'sixty', word_class: 'AJ' },
    { word: 'sixties', word_class: 'N' }
  ],
  tenderise: [
    { word: 'tender', word_class: 'N' },
    { word: 'tender', word_class: 'V' },
    { word: 'tender', word_class: 'AJ' },
    { word: 'tenderise', word_class: 'V' },
    { word: 'tenderize', word_class: 'V' },
    { word: 'tenderizer', word_class: 'N' },
    { word: 'tenderness', word_class: 'N' },
    { word: 'tenderized', word_class: 'AJ' },
    { word: 'tenderisation', word_class: 'N' },
    { word: 'tenderization', word_class: 'N' }
  ],
  possessiveness: [
    { word: 'possess', word_class: 'N' },
    { word: 'possess', word_class: 'V' },
    { word: 'possessor', word_class: 'N' },
    { word: 'possessed', word_class: 'AJ' },
    { word: 'possession', word_class: 'N' },
    { word: 'possessive', word_class: 'N' },
    { word: 'possessive', word_class: 'AJ' },
    { word: 'possessively', word_class: 'AV' },
    { word: 'possessiveness', word_class: 'N' }
  ],
  pigman: [
    { word: 'pig', word_class: 'N' },
    { word: 'pig', word_class: 'V' },
    { word: 'pigman', word_class: 'N' },
    { word: 'piggery', word_class: 'N' },
    { word: 'pigment', word_class: 'N' },
    { word: 'pigment', word_class: 'V' },
    { word: 'pigmentation', word_class: 'N' }
  ],
  acrogen: [
    { word: 'acrogen', word_class: 'N' },
    { word: 'acrogenic', word_class: 'AJ' },
    { word: 'acrogenous', word_class: 'AJ' }
  ],
  metonym: [
    { word: 'metonym', word_class: 'N' },
    { word: 'metonymic', word_class: 'AJ' },
    { word: 'metonymical', word_class: 'AJ' },
    { word: 'metonymically', word_class: 'AV' }
  ],
  brutalisation: [
    { word: 'brutal', word_class: 'AJ' },
    { word: 'brutalise', word_class: 'V' },
    { word: 'brutality', word_class: 'N' },
    { word: 'brutalize', word_class: 'V' },
    { word: 'brutally', word_class: 'AV' },
    { word: 'brutalisation', word_class: 'N' },
    { word: 'brutalization', word_class: 'N' }
  ],
  bars: [
    { word: 'bar', word_class: 'N' },
    { word: 'bar', word_class: 'V' },
    { word: 'bars', word_class: 'N' },
    { word: 'barman', word_class: 'N' },
    { word: 'barred', word_class: 'AJ' },
    { word: 'barring', word_class: 'N' }
  ],
  recommendation: [
    { word: 'recommend', word_class: 'V' },
    { word: 'recommended', word_class: 'AJ' },
    { word: 'recommendation', word_class: 'N' }
  ],
  clear: [
    { word: 'clear', word_class: 'N' },
    { word: 'clear', word_class: 'V' },
    { word: 'clear', word_class: 'AJ' },
    { word: 'clear', word_class: 'AV' },
    { word: 'cleared', word_class: 'AJ' },
    { word: 'clearing', word_class: 'N' },
    { word: 'clearance', word_class: 'N' },
    { word: 'clearness', word_class: 'N' }
  ],
  apprehensiveness: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  cold: [{ word: 'cold', word_class: 'N' }, { word: 'cold', word_class: 'AJ' }, { word: 'coldness', word_class: 'N' }],
  pectise: [
    { word: 'pectise', word_class: 'V' },
    { word: 'pectize', word_class: 'V' },
    { word: 'pectisation', word_class: 'N' },
    { word: 'pectization', word_class: 'N' }
  ],
  shelling: [
    { word: 'shell', word_class: 'N' },
    { word: 'shell', word_class: 'V' },
    { word: 'shell', word_class: 'AJ' },
    { word: 'shelled', word_class: 'AJ' },
    { word: 'shelling', word_class: 'N' }
  ],
  logical: [
    { word: 'logic', word_class: 'N' },
    { word: 'logical', word_class: 'AJ' },
    { word: 'logicism', word_class: 'N' },
    { word: 'logicality', word_class: 'N' },
    { word: 'logically', word_class: 'AV' },
    { word: 'logicalness', word_class: 'N' }
  ],
  card: [{ word: 'card', word_class: 'N' }, { word: 'card', word_class: 'V' }, { word: 'cards', word_class: 'N' }],
  inexpressible: [
    { word: 'inexpressive', word_class: 'AJ' },
    { word: 'inexpressible', word_class: 'AJ' },
    { word: 'inexpressively', word_class: 'AV' }
  ],
  coral: [
    { word: 'core', word_class: 'N' },
    { word: 'core', word_class: 'V' },
    { word: 'coral', word_class: 'N' },
    { word: 'coral', word_class: 'AJ' }
  ],
  delusion: [
    { word: 'delude', word_class: 'V' },
    { word: 'delusion', word_class: 'N' },
    { word: 'delusive', word_class: 'AJ' },
    { word: 'delusively', word_class: 'AV' },
    { word: 'delusionary', word_class: 'AJ' }
  ],
  rectification: [
    { word: 'rectify', word_class: 'V' },
    { word: 'rectifier', word_class: 'N' },
    { word: 'rectified', word_class: 'AJ' },
    { word: 'rectifiable', word_class: 'AJ' },
    { word: 'rectification', word_class: 'N' }
  ],
  familial: [
    { word: 'family', word_class: 'N' },
    { word: 'family', word_class: 'AV' },
    { word: 'familial', word_class: 'AJ' }
  ],
  gears: [
    { word: 'gear', word_class: 'N' },
    { word: 'gear', word_class: 'V' },
    { word: 'gears', word_class: 'N' },
    { word: 'geared', word_class: 'AJ' },
    { word: 'gearing', word_class: 'N' }
  ],
  controlled: [
    { word: 'control', word_class: 'N' },
    { word: 'control', word_class: 'V' },
    { word: 'controller', word_class: 'N' },
    { word: 'controlled', word_class: 'AJ' },
    { word: 'controlling', word_class: 'N' },
    { word: 'controlling', word_class: 'AJ' },
    { word: 'controllable', word_class: 'AJ' }
  ],
  rowdiness: [
    { word: 'rowdy', word_class: 'N' },
    { word: 'rowdy', word_class: 'AJ' },
    { word: 'rowdiness', word_class: 'N' }
  ],
  transmit: [
    { word: 'transmit', word_class: 'V' },
    { word: 'transmittal', word_class: 'N' },
    { word: 'transmitter', word_class: 'N' },
    { word: 'transmission', word_class: 'N' },
    { word: 'transmitted', word_class: 'AJ' },
    { word: 'transmitting', word_class: 'N' },
    { word: 'transmittance', word_class: 'N' },
    { word: 'transmissible', word_class: 'AJ' },
    { word: 'transmittable', word_class: 'AJ' }
  ],
  phone: [{ word: 'phone', word_class: 'N' }, { word: 'phone', word_class: 'V' }, { word: 'phoner', word_class: 'N' }],
  honorific: [
    { word: 'hone', word_class: 'N' },
    { word: 'hone', word_class: 'V' },
    { word: 'honor', word_class: 'N' },
    { word: 'honor', word_class: 'V' },
    { word: 'honored', word_class: 'AJ' },
    { word: 'honoring', word_class: 'N' },
    { word: 'honorary', word_class: 'AJ' },
    { word: 'honorific', word_class: 'N' },
    { word: 'honorific', word_class: 'AJ' },
    { word: 'honorable', word_class: 'AJ' },
    { word: 'honorably', word_class: 'AV' },
    { word: 'honorableness', word_class: 'N' }
  ],
  masturbate: [
    { word: 'masturbate', word_class: 'V' },
    { word: 'masturbator', word_class: 'N' },
    { word: 'masturbation', word_class: 'N' }
  ],
  manifestation: [
    { word: 'manifest', word_class: 'N' },
    { word: 'manifest', word_class: 'V' },
    { word: 'manifest', word_class: 'AJ' },
    { word: 'manifestation', word_class: 'N' }
  ],
  dismember: [
    { word: 'dismember', word_class: 'N' },
    { word: 'dismember', word_class: 'V' },
    { word: 'dismemberment', word_class: 'N' }
  ],
  fully: [
    { word: 'full', word_class: 'V' },
    { word: 'full', word_class: 'AJ' },
    { word: 'full', word_class: 'AV' },
    { word: 'fully', word_class: 'AV' },
    { word: 'fulness', word_class: 'N' },
    { word: 'fullness', word_class: 'N' }
  ],
  sulphurise: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  margination: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  bellman: [
    { word: 'bell', word_class: 'N' },
    { word: 'bell', word_class: 'V' },
    { word: 'belle', word_class: 'N' },
    { word: 'belling', word_class: 'N' },
    { word: 'bellman', word_class: 'N' }
  ],
  miser: [
    { word: 'miser', word_class: 'N' },
    { word: 'miserable', word_class: 'AJ' },
    { word: 'miserably', word_class: 'AV' }
  ],
  sweetening: [
    { word: 'sweeten', word_class: 'V' },
    { word: 'sweetener', word_class: 'N' },
    { word: 'sweetened', word_class: 'AJ' },
    { word: 'sweetening', word_class: 'N' }
  ],
  ambivalency: [
    { word: 'ambivalence', word_class: 'N' },
    { word: 'ambivalency', word_class: 'N' },
    { word: 'ambivalent', word_class: 'AJ' }
  ],
  demurely: [
    { word: 'demur', word_class: 'N' },
    { word: 'demur', word_class: 'V' },
    { word: 'demural', word_class: 'N' },
    { word: 'demure', word_class: 'AJ' },
    { word: 'demurral', word_class: 'N' },
    { word: 'demurrer', word_class: 'N' },
    { word: 'demurely', word_class: 'AV' },
    { word: 'demureness', word_class: 'N' }
  ],
  ting: [
    { word: 'ting', word_class: 'N' },
    { word: 'ting', word_class: 'V' },
    { word: 'tinge', word_class: 'N' },
    { word: 'tinge', word_class: 'V' },
    { word: 'tinged', word_class: 'AJ' }
  ],
  forgivable: [
    { word: 'forgive', word_class: 'V' },
    { word: 'forgive', word_class: 'AJ' },
    { word: 'forgiven', word_class: 'V' },
    { word: 'forgiven', word_class: 'AJ' },
    { word: 'forgiving', word_class: 'AJ' },
    { word: 'forgivable', word_class: 'AJ' },
    { word: 'forgivably', word_class: 'AV' },
    { word: 'forgiveness', word_class: 'N' }
  ],
  hydrogenise: [
    { word: 'hydrogen', word_class: 'N' },
    { word: 'hydrogenate', word_class: 'V' },
    { word: 'hydrogenise', word_class: 'V' },
    { word: 'hydrogenize', word_class: 'V' },
    { word: 'hydrogenation', word_class: 'N' },
    { word: 'hydrogenisation', word_class: 'N' },
    { word: 'hydrogenization', word_class: 'N' }
  ],
  freethinker: [
    { word: 'freethinker', word_class: 'N' },
    { word: 'freethinking', word_class: 'N' },
    { word: 'freethinking', word_class: 'AJ' }
  ],
  parry: [{ word: 'parry', word_class: 'N' }, { word: 'parry', word_class: 'V' }, { word: 'parry', word_class: 'AJ' }],
  disproportionately: [
    { word: 'disproportion', word_class: 'N' },
    { word: 'disproportion', word_class: 'V' },
    { word: 'disproportional', word_class: 'AJ' },
    { word: 'disproportionate', word_class: 'AJ' },
    { word: 'disproportionment', word_class: 'N' },
    { word: 'disproportionately', word_class: 'AV' }
  ],
  curling: [
    { word: 'curl', word_class: 'N' },
    { word: 'curl', word_class: 'V' },
    { word: 'curler', word_class: 'N' },
    { word: 'curled', word_class: 'AJ' },
    { word: 'curling', word_class: 'N' },
    { word: 'curling', word_class: 'AJ' }
  ],
  toxicity: [
    { word: 'toxic', word_class: 'AJ' },
    { word: 'toxicity', word_class: 'N' },
    { word: 'toxicant', word_class: 'AJ' }
  ],
  pipe: [
    { word: 'pipe', word_class: 'N' },
    { word: 'pipe', word_class: 'V' },
    { word: 'piper', word_class: 'N' },
    { word: 'pipes', word_class: 'N' },
    { word: 'piping', word_class: 'N' },
    { word: 'pipeful', word_class: 'N' },
    { word: 'piping', word_class: 'AJ' },
    { word: 'piping', word_class: 'AV' },
    { word: 'pipeful', word_class: 'AJ' }
  ],
  wave: [
    { word: 'wave', word_class: 'N' },
    { word: 'wave', word_class: 'V' },
    { word: 'wavy', word_class: 'AJ' },
    { word: 'waving', word_class: 'N' },
    { word: 'waving', word_class: 'AJ' }
  ],
  receivables: [
    { word: 'receipt', word_class: 'N' },
    { word: 'receipt', word_class: 'V' },
    { word: 'receive', word_class: 'V' },
    { word: 'receipts', word_class: 'N' },
    { word: 'receiver', word_class: 'N' },
    { word: 'receptor', word_class: 'N' },
    { word: 'reception', word_class: 'N' },
    { word: 'recipient', word_class: 'N' },
    { word: 'received', word_class: 'AJ' },
    { word: 'receiving', word_class: 'N' },
    { word: 'receptive', word_class: 'AJ' },
    { word: 'receivable', word_class: 'AJ' },
    { word: 'receivables', word_class: 'N' },
    { word: 'receptivity', word_class: 'N' },
    { word: 'receptively', word_class: 'AV' },
    { word: 'receptiveness', word_class: 'N' }
  ],
  particularized: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  privation: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  automatize: [
    { word: 'automat', word_class: 'N' },
    { word: 'automate', word_class: 'N' },
    { word: 'automate', word_class: 'V' },
    { word: 'automatic', word_class: 'N' },
    { word: 'automation', word_class: 'N' },
    { word: 'automatic', word_class: 'AJ' },
    { word: 'automatism', word_class: 'N' },
    { word: 'automated', word_class: 'AJ' },
    { word: 'automatize', word_class: 'V' },
    { word: 'automatically', word_class: 'AV' },
    { word: 'automatization', word_class: 'N' }
  ],
  untruthfulness: [
    { word: 'untruth', word_class: 'N' },
    { word: 'untruthful', word_class: 'AJ' },
    { word: 'untruthfulness', word_class: 'N' }
  ],
  cleanser: [
    { word: 'cleanse', word_class: 'V' },
    { word: 'cleanser', word_class: 'N' },
    { word: 'cleansed', word_class: 'AJ' },
    { word: 'cleansing', word_class: 'N' },
    { word: 'cleansing', word_class: 'AJ' }
  ],
  etherisation: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  employment: [
    { word: 'employ', word_class: 'N' },
    { word: 'employ', word_class: 'V' },
    { word: 'employee', word_class: 'N' },
    { word: 'employer', word_class: 'N' },
    { word: 'employed', word_class: 'AJ' },
    { word: 'employment', word_class: 'N' },
    { word: 'employable', word_class: 'AJ' }
  ],
  unify: [
    { word: 'unify', word_class: 'V' },
    { word: 'unified', word_class: 'AJ' },
    { word: 'unifying', word_class: 'AJ' },
    { word: 'unification', word_class: 'N' }
  ],
  pharmaceutic: [
    { word: 'pharmaceutic', word_class: 'AJ' },
    { word: 'pharmaceutics', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'N' },
    { word: 'pharmaceutical', word_class: 'AJ' }
  ],
  egocentric: [
    { word: 'egocentric', word_class: 'N' },
    { word: 'egocentric', word_class: 'AJ' },
    { word: 'egocentrism', word_class: 'N' }
  ],
  drone: [
    { word: 'drone', word_class: 'N' },
    { word: 'drone', word_class: 'V' },
    { word: 'droning', word_class: 'N' },
    { word: 'droning', word_class: 'AJ' }
  ],
  admiration: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  inebriant: [
    { word: 'inebriant', word_class: 'N' },
    { word: 'inebriate', word_class: 'N' },
    { word: 'inebriate', word_class: 'V' },
    { word: 'inebriated', word_class: 'AJ' },
    { word: 'inebriation', word_class: 'N' }
  ],
  receding: [
    { word: 'recede', word_class: 'V' },
    { word: 'recess', word_class: 'V' },
    { word: 'recess', word_class: 'N' },
    { word: 'receding', word_class: 'N' },
    { word: 'receding', word_class: 'AJ' },
    { word: 'recession', word_class: 'N' },
    { word: 'recessed', word_class: 'AJ' },
    { word: 'recessive', word_class: 'AJ' },
    { word: 'recessional', word_class: 'N' },
    { word: 'recessional', word_class: 'AJ' },
    { word: 'recessionary', word_class: 'AJ' }
  ],
  belongings: [
    { word: 'belong', word_class: 'V' },
    { word: 'belonging', word_class: 'N' },
    { word: 'belongings', word_class: 'N' }
  ],
  ante: [
    { word: 'ant', word_class: 'N' },
    { word: 'ant', word_class: 'AJ' },
    { word: 'ante', word_class: 'N' },
    { word: 'ante', word_class: 'V' },
    { word: 'ante', word_class: 'AJ' }
  ],
  pediatrics: [
    { word: 'pediatric', word_class: 'AJ' },
    { word: 'pediatrics', word_class: 'N' },
    { word: 'pediatrician', word_class: 'N' }
  ],
  formularize: [
    { word: 'formularise', word_class: 'V' },
    { word: 'formularize', word_class: 'V' },
    { word: 'formularising', word_class: 'N' },
    { word: 'formularizing', word_class: 'N' },
    { word: 'formularisation', word_class: 'N' },
    { word: 'formularization', word_class: 'N' }
  ],
  settled: [
    { word: 'settle', word_class: 'N' },
    { word: 'settle', word_class: 'V' },
    { word: 'settler', word_class: 'N' },
    { word: 'settled', word_class: 'AJ' },
    { word: 'settling', word_class: 'N' },
    { word: 'settlement', word_class: 'N' }
  ],
  allusive: [
    { word: 'allude', word_class: 'V' },
    { word: 'allusion', word_class: 'N' },
    { word: 'allusive', word_class: 'AJ' },
    { word: 'allusiveness', word_class: 'N' }
  ],
  tailed: [
    { word: 'tail', word_class: 'N' },
    { word: 'tail', word_class: 'V' },
    { word: 'tail', word_class: 'AJ' },
    { word: 'tails', word_class: 'N' },
    { word: 'tailed', word_class: 'AJ' },
    { word: 'tailing', word_class: 'N' },
    { word: 'tailing', word_class: 'AJ' }
  ],
  rustler: [
    { word: 'rustle', word_class: 'N' },
    { word: 'rustle', word_class: 'V' },
    { word: 'rustler', word_class: 'N' },
    { word: 'rustling', word_class: 'N' },
    { word: 'rustling', word_class: 'AJ' }
  ],
  strengthened: [
    { word: 'strong', word_class: 'AJ' },
    { word: 'strengthen', word_class: 'V' },
    { word: 'strengthener', word_class: 'N' },
    { word: 'strengthened', word_class: 'AJ' },
    { word: 'strengthening', word_class: 'N' },
    { word: 'strengthening', word_class: 'AJ' }
  ],
  absolutist: [
    { word: 'absolutist', word_class: 'N' },
    { word: 'absolutist', word_class: 'AJ' },
    { word: 'absolutistic', word_class: 'AJ' }
  ],
  grouped: [
    { word: 'group', word_class: 'N' },
    { word: 'group', word_class: 'V' },
    { word: 'group', word_class: 'AJ' },
    { word: 'grouper', word_class: 'N' },
    { word: 'grouped', word_class: 'AJ' },
    { word: 'grouping', word_class: 'N' }
  ],
  ferry: [
    { word: 'ferry', word_class: 'N' },
    { word: 'ferry', word_class: 'V' },
    { word: 'ferris', word_class: 'N' },
    { word: 'ferrying', word_class: 'N' },
    { word: 'ferryman', word_class: 'N' }
  ],
  neurologic: [
    { word: 'neurology', word_class: 'N' },
    { word: 'neurologic', word_class: 'AJ' },
    { word: 'neurological', word_class: 'AJ' }
  ],
  incitement: [
    { word: 'incite', word_class: 'V' },
    { word: 'inciter', word_class: 'N' },
    { word: 'incitive', word_class: 'AJ' },
    { word: 'incitation', word_class: 'N' },
    { word: 'incitement', word_class: 'N' }
  ],
  variegation: [
    { word: 'variegate', word_class: 'V' },
    { word: 'variegated', word_class: 'AJ' },
    { word: 'variegation', word_class: 'N' }
  ],
  overlie: [
    { word: 'overlie', word_class: 'V' },
    { word: 'overly', word_class: 'AV' },
    { word: 'overlying', word_class: 'AJ' }
  ],
  squealing: [
    { word: 'squeal', word_class: 'N' },
    { word: 'squeal', word_class: 'V' },
    { word: 'squealer', word_class: 'N' },
    { word: 'squealing', word_class: 'AJ' }
  ],
  declarative: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  oxidise: [
    { word: 'oxide', word_class: 'N' },
    { word: 'oxidant', word_class: 'N' },
    { word: 'oxidate', word_class: 'V' },
    { word: 'oxidise', word_class: 'V' },
    { word: 'oxidize', word_class: 'V' },
    { word: 'oxidizer', word_class: 'N' },
    { word: 'oxidation', word_class: 'N' },
    { word: 'oxidised', word_class: 'AJ' },
    { word: 'oxidized', word_class: 'AJ' },
    { word: 'oxidative', word_class: 'AJ' },
    { word: 'oxidisation', word_class: 'N' },
    { word: 'oxidization', word_class: 'N' }
  ],
  reclining: [
    { word: 'recline', word_class: 'N' },
    { word: 'recline', word_class: 'V' },
    { word: 'recliner', word_class: 'N' },
    { word: 'reclining', word_class: 'N' },
    { word: 'reclining', word_class: 'AJ' },
    { word: 'reclination', word_class: 'N' }
  ],
  inertance: [
    { word: 'inert', word_class: 'AJ' },
    { word: 'inertance', word_class: 'N' },
    { word: 'inertness', word_class: 'N' }
  ],
  dissimulative: [
    { word: 'dissimulate', word_class: 'V' },
    { word: 'dissimulation', word_class: 'N' },
    { word: 'dissimulating', word_class: 'AJ' },
    { word: 'dissimulative', word_class: 'AJ' }
  ],
  neuronal: [
    { word: 'neuron', word_class: 'N' },
    { word: 'neuronal', word_class: 'AJ' },
    { word: 'neuronic', word_class: 'AJ' }
  ],
  horn: [{ word: 'horn', word_class: 'N' }, { word: 'horn', word_class: 'V' }, { word: 'horned', word_class: 'AJ' }],
  offending: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  inhere: [
    { word: 'inhere', word_class: 'V' },
    { word: 'inhere', word_class: 'AV' },
    { word: 'inherence', word_class: 'N' },
    { word: 'inherent', word_class: 'AJ' },
    { word: 'inherently', word_class: 'AV' }
  ],
  scatologic: [
    { word: 'scatology', word_class: 'N' },
    { word: 'scatologic', word_class: 'AJ' },
    { word: 'scatological', word_class: 'AJ' }
  ],
  apostrophize: [
    { word: 'apostrophe', word_class: 'N' },
    { word: 'apostrophic', word_class: 'AJ' },
    { word: 'apostrophize', word_class: 'V' },
    { word: 'apostrophization', word_class: 'N' }
  ],
  service: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  near: [
    { word: 'near', word_class: 'V' },
    { word: 'near', word_class: 'AJ' },
    { word: 'near', word_class: 'AV' },
    { word: 'nearness', word_class: 'N' }
  ],
  dyer: [
    { word: 'dye', word_class: 'N' },
    { word: 'dye', word_class: 'V' },
    { word: 'dyer', word_class: 'N' },
    { word: 'dyeing', word_class: 'N' }
  ],
  ionization: [
    { word: 'ionize', word_class: 'V' },
    { word: 'ionized', word_class: 'AJ' },
    { word: 'ionization', word_class: 'N' }
  ],
  leader: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  bowler: [
    { word: 'bowl', word_class: 'N' },
    { word: 'bowl', word_class: 'V' },
    { word: 'bowls', word_class: 'N' },
    { word: 'bowler', word_class: 'N' },
    { word: 'bowlful', word_class: 'N' },
    { word: 'bowling', word_class: 'N' },
    { word: 'bowlful', word_class: 'AJ' }
  ],
  spirally: [
    { word: 'spiral', word_class: 'N' },
    { word: 'spiral', word_class: 'V' },
    { word: 'spiral', word_class: 'AJ' },
    { word: 'spirally', word_class: 'AV' },
    { word: 'spiraling', word_class: 'AJ' }
  ],
  arbor: [
    { word: 'arbor', word_class: 'N' },
    { word: 'arborize', word_class: 'V' },
    { word: 'arboreal', word_class: 'AJ' },
    { word: 'arborous', word_class: 'AJ' },
    { word: 'arboreous', word_class: 'AJ' },
    { word: 'arborical', word_class: 'AJ' }
  ],
  attune: [
    { word: 'attune', word_class: 'V' },
    { word: 'attuned', word_class: 'AJ' },
    { word: 'attunement', word_class: 'N' }
  ],
  browned: [
    { word: 'brown', word_class: 'N' },
    { word: 'brown', word_class: 'V' },
    { word: 'brown', word_class: 'AJ' },
    { word: 'browned', word_class: 'AJ' },
    { word: 'browning', word_class: 'N' },
    { word: 'brownness', word_class: 'N' }
  ],
  belying: [
    { word: 'belie', word_class: 'N' },
    { word: 'belie', word_class: 'V' },
    { word: 'belying', word_class: 'AJ' }
  ],
  standard: [
    { word: 'standard', word_class: 'N' },
    { word: 'standard', word_class: 'AJ' },
    { word: 'standardise', word_class: 'V' },
    { word: 'standardize', word_class: 'V' },
    { word: 'standardized', word_class: 'AJ' },
    { word: 'standardisation', word_class: 'N' },
    { word: 'standardization', word_class: 'N' }
  ],
  evaluator: [
    { word: 'evaluate', word_class: 'V' },
    { word: 'evaluator', word_class: 'N' },
    { word: 'evaluable', word_class: 'AJ' },
    { word: 'evaluation', word_class: 'N' },
    { word: 'evaluative', word_class: 'AJ' }
  ],
  dynamism: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  optimally: [
    { word: 'optimum', word_class: 'N' },
    { word: 'optimal', word_class: 'AJ' },
    { word: 'optimise', word_class: 'V' },
    { word: 'optimize', word_class: 'V' },
    { word: 'optimism', word_class: 'N' },
    { word: 'optimum', word_class: 'AJ' },
    { word: 'optimally', word_class: 'AV' },
    { word: 'optimisation', word_class: 'N' },
    { word: 'optimization', word_class: 'N' }
  ],
  acre: [{ word: 'acre', word_class: 'N' }, { word: 'acres', word_class: 'N' }, { word: 'acreage', word_class: 'N' }],
  manufacturing: [
    { word: 'manufacture', word_class: 'N' },
    { word: 'manufacture', word_class: 'V' },
    { word: 'manufacturer', word_class: 'N' },
    { word: 'manufactured', word_class: 'AJ' },
    { word: 'manufacturing', word_class: 'N' }
  ],
  clamor: [
    { word: 'clam', word_class: 'N' },
    { word: 'clam', word_class: 'V' },
    { word: 'clamor', word_class: 'N' },
    { word: 'clamor', word_class: 'V' },
    { word: 'clamoring', word_class: 'N' },
    { word: 'clamorous', word_class: 'AJ' },
    { word: 'clamorously', word_class: 'AV' }
  ],
  feeding: [
    { word: 'feed', word_class: 'N' },
    { word: 'feed', word_class: 'V' },
    { word: 'feeder', word_class: 'N' },
    { word: 'feeding', word_class: 'N' }
  ],
  incomprehensive: [
    { word: 'incomprehension', word_class: 'N' },
    { word: 'incomprehensive', word_class: 'AJ' },
    { word: 'incomprehensible', word_class: 'AJ' },
    { word: 'incomprehensibility', word_class: 'N' }
  ],
  encrusted: [
    { word: 'encrust', word_class: 'V' },
    { word: 'incrust', word_class: 'V' },
    { word: 'encrusted', word_class: 'AJ' },
    { word: 'encrustation', word_class: 'N' },
    { word: 'incrustation', word_class: 'N' }
  ],
  tonic: [
    { word: 'tonic', word_class: 'N' },
    { word: 'tonic', word_class: 'AJ' },
    { word: 'tonicity', word_class: 'N' }
  ],
  relative: [
    { word: 'relate', word_class: 'V' },
    { word: 'relate', word_class: 'AJ' },
    { word: 'related', word_class: 'AJ' },
    { word: 'relation', word_class: 'N' },
    { word: 'relative', word_class: 'N' },
    { word: 'relative', word_class: 'AJ' },
    { word: 'relations', word_class: 'N' },
    { word: 'relativity', word_class: 'N' },
    { word: 'relational', word_class: 'AJ' },
    { word: 'relatively', word_class: 'AV' }
  ],
  overhanging: [
    { word: 'overhang', word_class: 'N' },
    { word: 'overhang', word_class: 'V' },
    { word: 'overhanging', word_class: 'AJ' }
  ],
  sampling: [
    { word: 'sample', word_class: 'N' },
    { word: 'sample', word_class: 'V' },
    { word: 'sampler', word_class: 'N' },
    { word: 'sampling', word_class: 'N' }
  ],
  dissembler: [
    { word: 'dissemble', word_class: 'V' },
    { word: 'dissembler', word_class: 'N' },
    { word: 'dissembling', word_class: 'N' },
    { word: 'dissemblance', word_class: 'N' },
    { word: 'dissembling', word_class: 'AJ' }
  ],
  thank: [
    { word: 'thank', word_class: 'V' },
    { word: 'thanks', word_class: 'N' },
    { word: 'thanking', word_class: 'N' },
    { word: 'thankful', word_class: 'AJ' },
    { word: 'thankless', word_class: 'AJ' },
    { word: 'thankfulness', word_class: 'N' }
  ],
  sue: [
    { word: 'sue', word_class: 'V' },
    { word: 'suit', word_class: 'V' },
    { word: 'suit', word_class: 'N' },
    { word: 'suite', word_class: 'N' },
    { word: 'suitor', word_class: 'N' },
    { word: 'suited', word_class: 'AJ' },
    { word: 'suiting', word_class: 'N' }
  ],
  phosphate: [
    { word: 'phosphate', word_class: 'N' },
    { word: 'phosphatise', word_class: 'V' },
    { word: 'phosphatize', word_class: 'V' },
    { word: 'phosphatisation', word_class: 'N' },
    { word: 'phosphatization', word_class: 'N' }
  ],
  sailing: [
    { word: 'sail', word_class: 'N' },
    { word: 'sail', word_class: 'V' },
    { word: 'sailor', word_class: 'N' },
    { word: 'sailing', word_class: 'N' },
    { word: 'sailing', word_class: 'AJ' }
  ],
  capacitation: [
    { word: 'capacitate', word_class: 'V' },
    { word: 'capacitance', word_class: 'N' },
    { word: 'capacitive', word_class: 'AJ' },
    { word: 'capacitation', word_class: 'N' }
  ],
  joiner: [
    { word: 'join', word_class: 'N' },
    { word: 'join', word_class: 'V' },
    { word: 'joiner', word_class: 'N' },
    { word: 'joinder', word_class: 'N' },
    { word: 'joined', word_class: 'AJ' },
    { word: 'joining', word_class: 'N' },
    { word: 'jointure', word_class: 'N' },
    { word: 'junction', word_class: 'N' }
  ],
  chafed: [
    { word: 'chafe', word_class: 'N' },
    { word: 'chafe', word_class: 'V' },
    { word: 'chafed', word_class: 'AJ' },
    { word: 'chafing', word_class: 'N' }
  ],
  jacobin: [
    { word: 'jacobin', word_class: 'N' },
    { word: 'jacobinic', word_class: 'AJ' },
    { word: 'jacobinism', word_class: 'N' },
    { word: 'jacobinical', word_class: 'AJ' }
  ],
  reminiscent: [
    { word: 'reminisce', word_class: 'V' },
    { word: 'reminiscence', word_class: 'N' },
    { word: 'reminiscent', word_class: 'AJ' },
    { word: 'reminiscently', word_class: 'AV' }
  ],
  commutable: [
    { word: 'commute', word_class: 'V' },
    { word: 'commuter', word_class: 'N' },
    { word: 'commutate', word_class: 'V' },
    { word: 'commuting', word_class: 'N' },
    { word: 'commutator', word_class: 'N' },
    { word: 'commutable', word_class: 'AJ' },
    { word: 'commutation', word_class: 'N' },
    { word: 'commutative', word_class: 'AJ' },
    { word: 'commutability', word_class: 'N' }
  ],
  scrutineer: [
    { word: 'scrutiny', word_class: 'N' },
    { word: 'scrutinise', word_class: 'V' },
    { word: 'scrutinize', word_class: 'V' },
    { word: 'scrutineer', word_class: 'N' },
    { word: 'scrutinise', word_class: 'AJ' },
    { word: 'scrutinize', word_class: 'AJ' }
  ],
  abortion: [
    { word: 'abort', word_class: 'V' },
    { word: 'abortion', word_class: 'N' },
    { word: 'abortive', word_class: 'AJ' },
    { word: 'abortively', word_class: 'AV' }
  ],
  boolean: [
    { word: 'boole', word_class: 'N' },
    { word: 'boole', word_class: 'AJ' },
    { word: 'boolean', word_class: 'N' },
    { word: 'boolean', word_class: 'AJ' }
  ],
  disobedient: [
    { word: 'disobey', word_class: 'V' },
    { word: 'disobedience', word_class: 'N' },
    { word: 'disobedient', word_class: 'AJ' },
    { word: 'disobediently', word_class: 'AV' }
  ],
  invalidating: [
    { word: 'invalid', word_class: 'N' },
    { word: 'invalid', word_class: 'V' },
    { word: 'invalid', word_class: 'AJ' },
    { word: 'invalidate', word_class: 'V' },
    { word: 'invalidism', word_class: 'N' },
    { word: 'invalidity', word_class: 'N' },
    { word: 'invalidness', word_class: 'N' },
    { word: 'invalidated', word_class: 'AJ' },
    { word: 'invalidation', word_class: 'N' },
    { word: 'invalidating', word_class: 'AJ' }
  ],
  sup: [
    { word: 'sup', word_class: 'N' },
    { word: 'sup', word_class: 'V' },
    { word: 'super', word_class: 'N' },
    { word: 'super', word_class: 'AJ' },
    { word: 'super', word_class: 'AV' },
    { word: 'supping', word_class: 'N' },
    { word: 'superable', word_class: 'AJ' }
  ],
  rifle: [
    { word: 'rifle', word_class: 'N' },
    { word: 'rifle', word_class: 'V' },
    { word: 'rifled', word_class: 'AJ' },
    { word: 'rifling', word_class: 'N' },
    { word: 'rifleman', word_class: 'N' }
  ],
  orienting: [
    { word: 'orient', word_class: 'N' },
    { word: 'orient', word_class: 'V' },
    { word: 'orient', word_class: 'AJ' },
    { word: 'oriental', word_class: 'N' },
    { word: 'oriental', word_class: 'AJ' },
    { word: 'orientate', word_class: 'V' },
    { word: 'oriented', word_class: 'AJ' },
    { word: 'orienting', word_class: 'AJ' },
    { word: 'orientalise', word_class: 'V' },
    { word: 'orientalize', word_class: 'V' },
    { word: 'orientated', word_class: 'AJ' },
    { word: 'orientation', word_class: 'N' },
    { word: 'orientating', word_class: 'AJ' },
    { word: 'orientalisation', word_class: 'N' },
    { word: 'orientalization', word_class: 'N' }
  ],
  buddhist: [
    { word: 'buddhist', word_class: 'N' },
    { word: 'buddhist', word_class: 'AJ' },
    { word: 'buddhistic', word_class: 'AJ' }
  ],
  homogenized: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  hypnotic: [
    { word: 'hypnosis', word_class: 'N' },
    { word: 'hypnotic', word_class: 'N' },
    { word: 'hypnotic', word_class: 'AJ' },
    { word: 'hypnotism', word_class: 'N' },
    { word: 'hypnotist', word_class: 'N' },
    { word: 'hypnotize', word_class: 'V' },
    { word: 'hypnotizer', word_class: 'N' },
    { word: 'hypnotized', word_class: 'AJ' },
    { word: 'hypnotization', word_class: 'N' }
  ],
  tails: [
    { word: 'tail', word_class: 'N' },
    { word: 'tail', word_class: 'V' },
    { word: 'tail', word_class: 'AJ' },
    { word: 'tails', word_class: 'N' },
    { word: 'tailed', word_class: 'AJ' },
    { word: 'tailing', word_class: 'N' },
    { word: 'tailing', word_class: 'AJ' }
  ],
  bluff: [
    { word: 'bluff', word_class: 'N' },
    { word: 'bluff', word_class: 'V' },
    { word: 'bluff', word_class: 'AJ' },
    { word: 'bluffer', word_class: 'N' },
    { word: 'bluffness', word_class: 'N' }
  ],
  bloated: [
    { word: 'bloat', word_class: 'N' },
    { word: 'bloat', word_class: 'V' },
    { word: 'bloated', word_class: 'AJ' }
  ],
  vacillator: [
    { word: 'vacillate', word_class: 'V' },
    { word: 'vacillant', word_class: 'AJ' },
    { word: 'vacillator', word_class: 'N' },
    { word: 'vacillation', word_class: 'N' },
    { word: 'vacillating', word_class: 'AJ' }
  ],
  future: [
    { word: 'future', word_class: 'N' },
    { word: 'future', word_class: 'AJ' },
    { word: 'futurism', word_class: 'N' },
    { word: 'futurity', word_class: 'N' }
  ],
  puttees: [
    { word: 'putt', word_class: 'N' },
    { word: 'putt', word_class: 'V' },
    { word: 'puttee', word_class: 'N' },
    { word: 'putter', word_class: 'N' },
    { word: 'putter', word_class: 'V' },
    { word: 'puttees', word_class: 'N' },
    { word: 'putterer', word_class: 'N' }
  ],
  endorsement: [
    { word: 'endorse', word_class: 'V' },
    { word: 'endorser', word_class: 'N' },
    { word: 'endorsed', word_class: 'AJ' },
    { word: 'endorsement', word_class: 'N' }
  ],
  copolymerization: [
    { word: 'copolymerise', word_class: 'V' },
    { word: 'copolymerize', word_class: 'V' },
    { word: 'copolymerisation', word_class: 'N' },
    { word: 'copolymerization', word_class: 'N' }
  ],
  truckful: [
    { word: 'truck', word_class: 'N' },
    { word: 'truck', word_class: 'V' },
    { word: 'truckful', word_class: 'N' },
    { word: 'trucking', word_class: 'N' },
    { word: 'truckful', word_class: 'AJ' }
  ],
  fluid: [
    { word: 'fluid', word_class: 'N' },
    { word: 'fluid', word_class: 'AJ' },
    { word: 'fluidness', word_class: 'N' }
  ],
  fluidness: [
    { word: 'fluid', word_class: 'N' },
    { word: 'fluid', word_class: 'AJ' },
    { word: 'fluidness', word_class: 'N' }
  ],
  rationalism: [
    { word: 'ration', word_class: 'N' },
    { word: 'ration', word_class: 'V' },
    { word: 'rational', word_class: 'AJ' },
    { word: 'rationed', word_class: 'AJ' },
    { word: 'rationing', word_class: 'N' },
    { word: 'rationalise', word_class: 'V' },
    { word: 'rationality', word_class: 'N' },
    { word: 'rationalist', word_class: 'N' },
    { word: 'rationalism', word_class: 'N' },
    { word: 'rationally', word_class: 'AV' },
    { word: 'rationalize', word_class: 'V' },
    { word: 'rationalist', word_class: 'AJ' },
    { word: 'rationalness', word_class: 'N' },
    { word: 'rationalistic', word_class: 'AJ' },
    { word: 'rationalisation', word_class: 'N' },
    { word: 'rationalization', word_class: 'N' }
  ],
  squinch: [
    { word: 'squinch', word_class: 'N' },
    { word: 'squinch', word_class: 'V' },
    { word: 'squinched', word_class: 'AJ' }
  ],
  productively: [
    { word: 'produce', word_class: 'N' },
    { word: 'produce', word_class: 'V' },
    { word: 'product', word_class: 'N' },
    { word: 'producer', word_class: 'N' },
    { word: 'produced', word_class: 'AJ' },
    { word: 'production', word_class: 'N' },
    { word: 'productive', word_class: 'AJ' },
    { word: 'productivity', word_class: 'N' },
    { word: 'productively', word_class: 'AV' },
    { word: 'productiveness', word_class: 'N' }
  ],
  potence: [
    { word: 'potence', word_class: 'N' },
    { word: 'potency', word_class: 'N' },
    { word: 'potent', word_class: 'AJ' },
    { word: 'potentate', word_class: 'N' },
    { word: 'potently', word_class: 'AV' }
  ],
  handwriting: [
    { word: 'handwrite', word_class: 'V' },
    { word: 'handwriting', word_class: 'N' },
    { word: 'handwritten', word_class: 'AJ' }
  ],
  victoriously: [
    { word: 'victory', word_class: 'N' },
    { word: 'victory', word_class: 'AJ' },
    { word: 'victorious', word_class: 'AJ' },
    { word: 'victoriously', word_class: 'AV' }
  ],
  goading: [
    { word: 'goad', word_class: 'N' },
    { word: 'goad', word_class: 'V' },
    { word: 'goaded', word_class: 'AJ' },
    { word: 'goading', word_class: 'N' }
  ],
  readability: [
    { word: 'readable', word_class: 'AJ' },
    { word: 'readably', word_class: 'AV' },
    { word: 'readability', word_class: 'N' }
  ],
  mitigable: [
    { word: 'mitigate', word_class: 'V' },
    { word: 'mitigable', word_class: 'AJ' },
    { word: 'mitigated', word_class: 'AJ' },
    { word: 'mitigation', word_class: 'N' },
    { word: 'mitigative', word_class: 'AJ' }
  ],
  disqualify: [
    { word: 'disqualify', word_class: 'V' },
    { word: 'disqualified', word_class: 'AJ' },
    { word: 'disqualifying', word_class: 'N' },
    { word: 'disqualifying', word_class: 'AJ' },
    { word: 'disqualification', word_class: 'N' }
  ],
  woolgathering: [
    { word: 'woolgather', word_class: 'V' },
    { word: 'woolgatherer', word_class: 'N' },
    { word: 'woolgathering', word_class: 'N' },
    { word: 'woolgathering', word_class: 'AJ' }
  ],
  southernism: [
    { word: 'southern', word_class: 'AJ' },
    { word: 'southerner', word_class: 'N' },
    { word: 'southernism', word_class: 'N' },
    { word: 'southernness', word_class: 'N' }
  ],
  burning: [
    { word: 'burn', word_class: 'N' },
    { word: 'burn', word_class: 'V' },
    { word: 'burner', word_class: 'N' },
    { word: 'burned', word_class: 'AJ' },
    { word: 'burning', word_class: 'N' },
    { word: 'burning', word_class: 'AJ' }
  ],
  introvertive: [
    { word: 'introvert', word_class: 'N' },
    { word: 'introvert', word_class: 'V' },
    { word: 'introvert', word_class: 'AJ' },
    { word: 'introversion', word_class: 'N' },
    { word: 'introverted', word_class: 'AJ' },
    { word: 'introversive', word_class: 'AJ' },
    { word: 'introvertive', word_class: 'AJ' }
  ],
  denounce: [
    { word: 'denounce', word_class: 'V' },
    { word: 'denunciate', word_class: 'V' },
    { word: 'denouncement', word_class: 'N' },
    { word: 'denunciation', word_class: 'N' },
    { word: 'denunciative', word_class: 'AJ' }
  ],
  compassionately: [
    { word: 'compass', word_class: 'N' },
    { word: 'compass', word_class: 'V' },
    { word: 'compassion', word_class: 'N' },
    { word: 'compassionate', word_class: 'V' },
    { word: 'compassionate', word_class: 'AJ' },
    { word: 'compassionately', word_class: 'AV' },
    { word: 'compassionateness', word_class: 'N' }
  ],
  privacy: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  pacifier: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  delighted: [
    { word: 'delight', word_class: 'N' },
    { word: 'delight', word_class: 'V' },
    { word: 'delighted', word_class: 'AJ' },
    { word: 'delightful', word_class: 'AJ' }
  ],
  commendatory: [
    { word: 'commend', word_class: 'V' },
    { word: 'commendable', word_class: 'AJ' },
    { word: 'commendable', word_class: 'AV' },
    { word: 'commendation', word_class: 'N' },
    { word: 'commendatory', word_class: 'AJ' }
  ],
  romance: [
    { word: 'romance', word_class: 'N' },
    { word: 'romance', word_class: 'V' },
    { word: 'romance', word_class: 'AJ' },
    { word: 'romantic', word_class: 'N' },
    { word: 'romantic', word_class: 'AJ' },
    { word: 'romantically', word_class: 'AV' }
  ],
  podzolization: [
    { word: 'podzol', word_class: 'N' },
    { word: 'podzolize', word_class: 'V' },
    { word: 'podzolization', word_class: 'N' }
  ],
  bum: [{ word: 'bum', word_class: 'N' }, { word: 'bum', word_class: 'V' }, { word: 'bum', word_class: 'AJ' }],
  mythicisation: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  militarise: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  glowing: [
    { word: 'glow', word_class: 'N' },
    { word: 'glow', word_class: 'V' },
    { word: 'glowing', word_class: 'N' },
    { word: 'glowing', word_class: 'AJ' }
  ],
  inaugurate: [
    { word: 'inaugural', word_class: 'N' },
    { word: 'inaugural', word_class: 'AJ' },
    { word: 'inaugurate', word_class: 'V' },
    { word: 'inaugurally', word_class: 'AV' },
    { word: 'inauguration', word_class: 'N' }
  ],
  meliorative: [
    { word: 'meliorate', word_class: 'V' },
    { word: 'meliorism', word_class: 'N' },
    { word: 'melioration', word_class: 'N' },
    { word: 'meliorative', word_class: 'AJ' }
  ],
  mailer: [
    { word: 'mail', word_class: 'N' },
    { word: 'mail', word_class: 'V' },
    { word: 'mailer', word_class: 'N' },
    { word: 'mailman', word_class: 'N' },
    { word: 'mailing', word_class: 'N' },
    { word: 'mailed', word_class: 'AJ' }
  ],
  lissome: [
    { word: 'lissom', word_class: 'AJ' },
    { word: 'lissome', word_class: 'AJ' },
    { word: 'lissomeness', word_class: 'N' }
  ],
  lust: [
    { word: 'lust', word_class: 'N' },
    { word: 'lust', word_class: 'V' },
    { word: 'lustful', word_class: 'AJ' },
    { word: 'lustfulness', word_class: 'N' }
  ],
  predetermination: [
    { word: 'predetermine', word_class: 'V' },
    { word: 'predetermined', word_class: 'AJ' },
    { word: 'predetermination', word_class: 'N' }
  ],
  mist: [{ word: 'mist', word_class: 'N' }, { word: 'mist', word_class: 'V' }, { word: 'mister', word_class: 'N' }],
  hindering: [
    { word: 'hinder', word_class: 'V' },
    { word: 'hinder', word_class: 'AJ' },
    { word: 'hindrance', word_class: 'N' },
    { word: 'hindering', word_class: 'AJ' }
  ],
  teaspoon: [
    { word: 'teaspoon', word_class: 'N' },
    { word: 'teaspoonful', word_class: 'N' },
    { word: 'teaspoonful', word_class: 'AJ' }
  ],
  demographer: [
    { word: 'demographer', word_class: 'N' },
    { word: 'demographic', word_class: 'N' },
    { word: 'demographic', word_class: 'AJ' }
  ],
  branchiopod: [
    { word: 'branchiopod', word_class: 'N' },
    { word: 'branchiopod', word_class: 'AJ' },
    { word: 'branchiopodous', word_class: 'AJ' }
  ],
  modernization: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  mastership: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  heterologous: [
    { word: 'heterologic', word_class: 'AJ' },
    { word: 'heterologous', word_class: 'AJ' },
    { word: 'heterological', word_class: 'AJ' }
  ],
  coding: [{ word: 'code', word_class: 'N' }, { word: 'code', word_class: 'V' }, { word: 'coding', word_class: 'N' }],
  contributor: [
    { word: 'contribute', word_class: 'V' },
    { word: 'contributor', word_class: 'N' },
    { word: 'contribution', word_class: 'N' },
    { word: 'contributing', word_class: 'AJ' },
    { word: 'contributive', word_class: 'AJ' }
  ],
  muffler: [
    { word: 'muffle', word_class: 'N' },
    { word: 'muffle', word_class: 'V' },
    { word: 'muffler', word_class: 'N' },
    { word: 'muffled', word_class: 'AJ' }
  ],
  point: [
    { word: 'point', word_class: 'N' },
    { word: 'point', word_class: 'V' },
    { word: 'pointer', word_class: 'N' },
    { word: 'pointed', word_class: 'AJ' },
    { word: 'pointing', word_class: 'N' },
    { word: 'pointing', word_class: 'AJ' }
  ],
  sensation: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  scoop: [
    { word: 'scoop', word_class: 'N' },
    { word: 'scoop', word_class: 'V' },
    { word: 'scoopful', word_class: 'N' },
    { word: 'scooping', word_class: 'N' },
    { word: 'scoopful', word_class: 'AJ' }
  ],
  immingle: [
    { word: 'immix', word_class: 'V' },
    { word: 'immingle', word_class: 'V' },
    { word: 'immixture', word_class: 'N' }
  ],
  incisive: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  second: [
    { word: 'second', word_class: 'N' },
    { word: 'second', word_class: 'V' },
    { word: 'second', word_class: 'AJ' },
    { word: 'second', word_class: 'AV' },
    { word: 'seconder', word_class: 'N' },
    { word: 'secondary', word_class: 'N' },
    { word: 'secondary', word_class: 'AJ' },
    { word: 'secondment', word_class: 'N' }
  ],
  installation: [
    { word: 'instal', word_class: 'V' },
    { word: 'install', word_class: 'N' },
    { word: 'install', word_class: 'V' },
    { word: 'installing', word_class: 'N' },
    { word: 'instalment', word_class: 'N' },
    { word: 'installment', word_class: 'N' },
    { word: 'installation', word_class: 'N' }
  ],
  preserve: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  poll: [{ word: 'poll', word_class: 'N' }, { word: 'poll', word_class: 'V' }, { word: 'polls', word_class: 'N' }],
  perlocutionary: [
    { word: 'perm', word_class: 'N' },
    { word: 'perm', word_class: 'V' },
    { word: 'permed', word_class: 'AJ' },
    { word: 'permeative', word_class: 'AJ' },
    { word: 'perlocutionary', word_class: 'N' }
  ],
  cryogen: [
    { word: 'cryogen', word_class: 'N' },
    { word: 'cryogenic', word_class: 'AJ' },
    { word: 'cryogenics', word_class: 'N' }
  ],
  ordaining: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  reef: [{ word: 'reef', word_class: 'N' }, { word: 'reef', word_class: 'V' }, { word: 'reefer', word_class: 'N' }],
  morphophonemic: [
    { word: 'morphophoneme', word_class: 'N' },
    { word: 'morphophonemic', word_class: 'AJ' },
    { word: 'morphophonemics', word_class: 'N' }
  ],
  emotional: [
    { word: 'emote', word_class: 'V' },
    { word: 'emotion', word_class: 'N' },
    { word: 'emotive', word_class: 'AJ' },
    { word: 'emotional', word_class: 'AJ' }
  ],
  institutionalise: [
    { word: 'institutionalise', word_class: 'V' },
    { word: 'institutionalize', word_class: 'V' },
    { word: 'institutionally', word_class: 'AV' },
    { word: 'institutionalized', word_class: 'AJ' },
    { word: 'institutionalisation', word_class: 'N' },
    { word: 'institutionalization', word_class: 'N' }
  ],
  nodular: [
    { word: 'nodule', word_class: 'N' },
    { word: 'nodular', word_class: 'AJ' },
    { word: 'noduled', word_class: 'AJ' },
    { word: 'nodulated', word_class: 'AJ' }
  ],
  conventional: [
    { word: 'convene', word_class: 'V' },
    { word: 'convent', word_class: 'N' },
    { word: 'convener', word_class: 'N' },
    { word: 'convening', word_class: 'N' },
    { word: 'convention', word_class: 'N' },
    { word: 'conventional', word_class: 'AJ' }
  ],
  negotiator: [
    { word: 'negotiant', word_class: 'N' },
    { word: 'negotiate', word_class: 'V' },
    { word: 'negotiator', word_class: 'N' },
    { word: 'negotiable', word_class: 'AJ' },
    { word: 'negotiation', word_class: 'N' },
    { word: 'negotiability', word_class: 'N' }
  ],
  board: [
    { word: 'board', word_class: 'N' },
    { word: 'board', word_class: 'V' },
    { word: 'boards', word_class: 'N' },
    { word: 'boarder', word_class: 'N' },
    { word: 'boarding', word_class: 'N' }
  ],
  liechtenstein: [
    { word: 'liechtenstein', word_class: 'N' },
    { word: 'liechtensteiner', word_class: 'N' },
    { word: 'liechtensteiner', word_class: 'AJ' }
  ],
  trephination: [
    { word: 'trephine', word_class: 'N' },
    { word: 'trephine', word_class: 'V' },
    { word: 'trephination', word_class: 'N' }
  ],
  foil: [
    { word: 'foil', word_class: 'N' },
    { word: 'foil', word_class: 'V' },
    { word: 'foiled', word_class: 'AJ' },
    { word: 'foiling', word_class: 'N' }
  ],
  sassing: [{ word: 'sass', word_class: 'N' }, { word: 'sass', word_class: 'V' }, { word: 'sassing', word_class: 'N' }],
  lilt: [{ word: 'lilt', word_class: 'N' }, { word: 'lilt', word_class: 'V' }, { word: 'lilting', word_class: 'AJ' }],
  anodal: [
    { word: 'anode', word_class: 'N' },
    { word: 'anodal', word_class: 'AJ' },
    { word: 'anodic', word_class: 'AJ' },
    { word: 'anodize', word_class: 'V' },
    { word: 'anodization', word_class: 'N' }
  ],
  stylization: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  hopefulness: [
    { word: 'hope', word_class: 'N' },
    { word: 'hope', word_class: 'V' },
    { word: 'hopeful', word_class: 'N' },
    { word: 'hopeful', word_class: 'AJ' },
    { word: 'hopefulness', word_class: 'N' }
  ],
  shanghaier: [
    { word: 'shanghai', word_class: 'N' },
    { word: 'shanghai', word_class: 'V' },
    { word: 'shanghaier', word_class: 'N' }
  ],
  racketeer: [
    { word: 'racket', word_class: 'N' },
    { word: 'racket', word_class: 'V' },
    { word: 'racketeer', word_class: 'N' },
    { word: 'racketeer', word_class: 'V' },
    { word: 'racketeering', word_class: 'N' }
  ],
  shear: [
    { word: 'shear', word_class: 'N' },
    { word: 'shear', word_class: 'V' },
    { word: 'shears', word_class: 'N' },
    { word: 'shorn', word_class: 'AJ' },
    { word: 'sheared', word_class: 'AJ' },
    { word: 'shearing', word_class: 'N' }
  ],
  highlands: [
    { word: 'highland', word_class: 'N' },
    { word: 'highland', word_class: 'AJ' },
    { word: 'highlands', word_class: 'N' },
    { word: 'highlander', word_class: 'N' }
  ],
  intertwined: [
    { word: 'intertwine', word_class: 'N' },
    { word: 'intertwine', word_class: 'V' },
    { word: 'intertwined', word_class: 'AJ' },
    { word: 'intertwinement', word_class: 'N' }
  ],
  rehearse: [
    { word: 'rehearse', word_class: 'N' },
    { word: 'rehearse', word_class: 'V' },
    { word: 'rehearsal', word_class: 'N' }
  ],
  advised: [
    { word: 'advice', word_class: 'N' },
    { word: 'advise', word_class: 'V' },
    { word: 'advisee', word_class: 'N' },
    { word: 'adviser', word_class: 'N' },
    { word: 'advisor', word_class: 'N' },
    { word: 'advised', word_class: 'AJ' },
    { word: 'advisory', word_class: 'N' },
    { word: 'advisory', word_class: 'AJ' },
    { word: 'advisable', word_class: 'AJ' },
    { word: 'advisement', word_class: 'N' },
    { word: 'advisability', word_class: 'N' }
  ],
  criticize: [
    { word: 'critic', word_class: 'N' },
    { word: 'critique', word_class: 'N' },
    { word: 'critique', word_class: 'V' },
    { word: 'criticism', word_class: 'N' },
    { word: 'criticise', word_class: 'V' },
    { word: 'critical', word_class: 'AJ' },
    { word: 'criticize', word_class: 'V' },
    { word: 'criticality', word_class: 'N' },
    { word: 'critically', word_class: 'AV' },
    { word: 'criticalness', word_class: 'N' }
  ],
  managing: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  scallop: [
    { word: 'scallop', word_class: 'N' },
    { word: 'scallop', word_class: 'V' },
    { word: 'scalloped', word_class: 'AJ' }
  ],
  terminally: [
    { word: 'terminal', word_class: 'N' },
    { word: 'terminal', word_class: 'AJ' },
    { word: 'terminate', word_class: 'V' },
    { word: 'terminator', word_class: 'N' },
    { word: 'terminable', word_class: 'AJ' },
    { word: 'terminably', word_class: 'AV' },
    { word: 'terminally', word_class: 'AV' },
    { word: 'terminated', word_class: 'AJ' },
    { word: 'termination', word_class: 'N' },
    { word: 'terminative', word_class: 'AJ' }
  ],
  manageable: [
    { word: 'manage', word_class: 'V' },
    { word: 'manager', word_class: 'N' },
    { word: 'managing', word_class: 'N' },
    { word: 'management', word_class: 'N' },
    { word: 'manageable', word_class: 'AJ' },
    { word: 'manageably', word_class: 'AV' },
    { word: 'manageability', word_class: 'N' },
    { word: 'manageableness', word_class: 'N' }
  ],
  grubby: [
    { word: 'grubby', word_class: 'N' },
    { word: 'grubby', word_class: 'AJ' },
    { word: 'grubbiness', word_class: 'N' }
  ],
  volcanize: [
    { word: 'volcano', word_class: 'N' },
    { word: 'volcanic', word_class: 'AJ' },
    { word: 'volcanism', word_class: 'N' },
    { word: 'volcanize', word_class: 'V' },
    { word: 'volcanise', word_class: 'V' },
    { word: 'volcanically', word_class: 'AV' },
    { word: 'volcanisation', word_class: 'N' },
    { word: 'volcanization', word_class: 'N' }
  ],
  healthfulness: [
    { word: 'health', word_class: 'N' },
    { word: 'healthful', word_class: 'AJ' },
    { word: 'healthfulness', word_class: 'N' }
  ],
  disapproving: [
    { word: 'disapprove', word_class: 'V' },
    { word: 'disapproval', word_class: 'N' },
    { word: 'disapproved', word_class: 'AJ' },
    { word: 'disapproving', word_class: 'AJ' }
  ],
  vitiate: [
    { word: 'vitiate', word_class: 'V' },
    { word: 'vitiated', word_class: 'AJ' },
    { word: 'vitiation', word_class: 'N' }
  ],
  bobbysocks: [
    { word: 'bobbysock', word_class: 'N' },
    { word: 'bobbysocks', word_class: 'N' },
    { word: 'bobbysocker', word_class: 'N' }
  ],
  cryptically: [
    { word: 'cryptic', word_class: 'AJ' },
    { word: 'cryptical', word_class: 'AJ' },
    { word: 'cryptically', word_class: 'AV' }
  ],
  constrain: [
    { word: 'constrain', word_class: 'V' },
    { word: 'constraint', word_class: 'N' },
    { word: 'constrained', word_class: 'AJ' },
    { word: 'constraining', word_class: 'AJ' }
  ],
  whoopee: [
    { word: 'whoop', word_class: 'N' },
    { word: 'whoop', word_class: 'V' },
    { word: 'whoopee', word_class: 'N' },
    { word: 'whooper', word_class: 'N' }
  ],
  demoralizing: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  watching: [
    { word: 'watch', word_class: 'N' },
    { word: 'watch', word_class: 'V' },
    { word: 'watcher', word_class: 'N' },
    { word: 'watchman', word_class: 'N' },
    { word: 'watching', word_class: 'N' },
    { word: 'watchful', word_class: 'AJ' },
    { word: 'watchfulness', word_class: 'N' }
  ],
  tired: [
    { word: 'tire', word_class: 'N' },
    { word: 'tire', word_class: 'V' },
    { word: 'tired', word_class: 'AJ' },
    { word: 'tiring', word_class: 'AJ' },
    { word: 'tiresome', word_class: 'AJ' },
    { word: 'tiresomely', word_class: 'AV' },
    { word: 'tiresomeness', word_class: 'N' }
  ],
  analogous: [
    { word: 'analog', word_class: 'N' },
    { word: 'analog', word_class: 'AJ' },
    { word: 'analogy', word_class: 'N' },
    { word: 'analogy', word_class: 'AJ' },
    { word: 'analogist', word_class: 'N' },
    { word: 'analogize', word_class: 'V' },
    { word: 'analogous', word_class: 'AJ' },
    { word: 'analogical', word_class: 'AJ' },
    { word: 'analogously', word_class: 'AV' },
    { word: 'analogization', word_class: 'N' }
  ],
  physically: [
    { word: 'physic', word_class: 'N' },
    { word: 'physic', word_class: 'V' },
    { word: 'physics', word_class: 'N' },
    { word: 'physical', word_class: 'N' },
    { word: 'physical', word_class: 'AJ' },
    { word: 'physicist', word_class: 'N' },
    { word: 'physicalism', word_class: 'N' },
    { word: 'physicality', word_class: 'N' },
    { word: 'physically', word_class: 'AV' },
    { word: 'physicalness', word_class: 'N' }
  ],
  jangle: [
    { word: 'jangle', word_class: 'N' },
    { word: 'jangle', word_class: 'V' },
    { word: 'jangling', word_class: 'AJ' }
  ],
  undesired: [
    { word: 'undesired', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'N' },
    { word: 'undesiring', word_class: 'AJ' },
    { word: 'undesirous', word_class: 'AJ' },
    { word: 'undesirable', word_class: 'AJ' },
    { word: 'undesirably', word_class: 'AV' },
    { word: 'undesirability', word_class: 'N' }
  ],
  stratified: [
    { word: 'stratify', word_class: 'V' },
    { word: 'stratified', word_class: 'AJ' },
    { word: 'stratification', word_class: 'N' }
  ],
  immix: [
    { word: 'immix', word_class: 'V' },
    { word: 'immingle', word_class: 'V' },
    { word: 'immixture', word_class: 'N' }
  ],
  soilure: [
    { word: 'soil', word_class: 'N' },
    { word: 'soil', word_class: 'V' },
    { word: 'soilage', word_class: 'N' },
    { word: 'soiled', word_class: 'AJ' },
    { word: 'soilure', word_class: 'N' },
    { word: 'soiling', word_class: 'N' }
  ],
  trust: [
    { word: 'trust', word_class: 'N' },
    { word: 'trust', word_class: 'V' },
    { word: 'trustee', word_class: 'N' },
    { word: 'trusted', word_class: 'AJ' },
    { word: 'trustful', word_class: 'AJ' },
    { word: 'trusting', word_class: 'AJ' },
    { word: 'trustfulness', word_class: 'N' }
  ],
  surfeited: [
    { word: 'surfeit', word_class: 'N' },
    { word: 'surfeit', word_class: 'V' },
    { word: 'surfeited', word_class: 'AJ' }
  ],
  demoniacally: [
    { word: 'demoniac', word_class: 'N' },
    { word: 'demoniac', word_class: 'AJ' },
    { word: 'demoniacal', word_class: 'AJ' },
    { word: 'demoniacally', word_class: 'AV' }
  ],
  harpooneer: [
    { word: 'harpoon', word_class: 'N' },
    { word: 'harpoon', word_class: 'V' },
    { word: 'harpooner', word_class: 'N' },
    { word: 'harpooneer', word_class: 'N' }
  ],
  winterise: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  accessibility: [
    { word: 'access', word_class: 'N' },
    { word: 'access', word_class: 'V' },
    { word: 'accession', word_class: 'N' },
    { word: 'accession', word_class: 'V' },
    { word: 'accessible', word_class: 'AJ' },
    { word: 'accessibility', word_class: 'N' }
  ],
  roofing: [
    { word: 'roof', word_class: 'N' },
    { word: 'roof', word_class: 'V' },
    { word: 'roofer', word_class: 'N' },
    { word: 'roofed', word_class: 'AJ' },
    { word: 'roofing', word_class: 'N' }
  ],
  geodesical: [
    { word: 'geodesic', word_class: 'N' },
    { word: 'geodesic', word_class: 'AJ' },
    { word: 'geodesical', word_class: 'AJ' }
  ],
  labial: [
    { word: 'labial', word_class: 'N' },
    { word: 'labial', word_class: 'AJ' },
    { word: 'labialise', word_class: 'V' },
    { word: 'labialize', word_class: 'V' },
    { word: 'labialisation', word_class: 'N' },
    { word: 'labialization', word_class: 'N' }
  ],
  moronity: [
    { word: 'moron', word_class: 'N' },
    { word: 'morone', word_class: 'N' },
    { word: 'moronic', word_class: 'AJ' },
    { word: 'moronity', word_class: 'N' }
  ],
  section: [
    { word: 'section', word_class: 'V' },
    { word: 'section', word_class: 'N' },
    { word: 'sectional', word_class: 'AJ' },
    { word: 'sectioned', word_class: 'AJ' },
    { word: 'sectionalise', word_class: 'V' },
    { word: 'sectionalism', word_class: 'N' },
    { word: 'sectionalize', word_class: 'V' },
    { word: 'sectionalisation', word_class: 'N' },
    { word: 'sectionalization', word_class: 'N' }
  ],
  preying: [
    { word: 'prey', word_class: 'N' },
    { word: 'prey', word_class: 'V' },
    { word: 'predate', word_class: 'V' },
    { word: 'preying', word_class: 'N' },
    { word: 'predator', word_class: 'N' },
    { word: 'predation', word_class: 'N' }
  ],
  cheliceral: [
    { word: 'cheliceral', word_class: 'AJ' },
    { word: 'chelicerate', word_class: 'AJ' },
    { word: 'chelicerous', word_class: 'AJ' }
  ],
  exploratory: [
    { word: 'explore', word_class: 'V' },
    { word: 'explorer', word_class: 'N' },
    { word: 'exploration', word_class: 'N' },
    { word: 'explorative', word_class: 'AJ' },
    { word: 'exploratory', word_class: 'AJ' }
  ],
  inessentiality: [
    { word: 'inessential', word_class: 'N' },
    { word: 'inessential', word_class: 'AJ' },
    { word: 'inessentiality', word_class: 'N' }
  ],
  remunerator: [
    { word: 'remunerate', word_class: 'V' },
    { word: 'remunerator', word_class: 'N' },
    { word: 'remunerated', word_class: 'AJ' },
    { word: 'remuneration', word_class: 'N' }
  ],
  predestine: [
    { word: 'predestine', word_class: 'V' },
    { word: 'predestinate', word_class: 'V' },
    { word: 'predestined', word_class: 'AJ' },
    { word: 'predestinate', word_class: 'AJ' },
    { word: 'predestination', word_class: 'N' }
  ],
  regenerating: [
    { word: 'regenerate', word_class: 'V' },
    { word: 'regenerate', word_class: 'AJ' },
    { word: 'regeneration', word_class: 'N' },
    { word: 'regenerating', word_class: 'AJ' }
  ],
  fortuitousness: [
    { word: 'fortuitous', word_class: 'AJ' },
    { word: 'fortuitously', word_class: 'AV' },
    { word: 'fortuitousness', word_class: 'N' }
  ],
  real: [
    { word: 'real', word_class: 'N' },
    { word: 'real', word_class: 'AJ' },
    { word: 'real', word_class: 'AV' },
    { word: 'realness', word_class: 'N' }
  ],
  northerner: [
    { word: 'northern', word_class: 'N' },
    { word: 'northern', word_class: 'AJ' },
    { word: 'northerner', word_class: 'N' },
    { word: 'northernness', word_class: 'N' }
  ],
  predetermine: [
    { word: 'predetermine', word_class: 'V' },
    { word: 'predetermined', word_class: 'AJ' },
    { word: 'predetermination', word_class: 'N' }
  ],
  obfucscation: [
    { word: 'obfuscate', word_class: 'V' },
    { word: 'obfuscation', word_class: 'N' },
    { word: 'obfucscation', word_class: 'N' }
  ],
  antipathetic: [
    { word: 'antipathy', word_class: 'N' },
    { word: 'antipathy', word_class: 'AJ' },
    { word: 'antipathetic', word_class: 'AJ' },
    { word: 'antipathetical', word_class: 'AJ' }
  ],
  isolate: [
    { word: 'isolate', word_class: 'V' },
    { word: 'isolable', word_class: 'AJ' },
    { word: 'isolated', word_class: 'AJ' },
    { word: 'isolation', word_class: 'N' },
    { word: 'isolating', word_class: 'AJ' }
  ],
  vulgarization: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  recognized: [
    { word: 'recognise', word_class: 'V' },
    { word: 'recognize', word_class: 'V' },
    { word: 'recognition', word_class: 'N' },
    { word: 'recognized', word_class: 'AJ' },
    { word: 'recognisance', word_class: 'N' },
    { word: 'recognizance', word_class: 'N' },
    { word: 'recognisable', word_class: 'AJ' },
    { word: 'recognizable', word_class: 'AJ' },
    { word: 'recognizably', word_class: 'AV' }
  ],
  wavy: [
    { word: 'wave', word_class: 'N' },
    { word: 'wave', word_class: 'V' },
    { word: 'wavy', word_class: 'AJ' },
    { word: 'waving', word_class: 'N' },
    { word: 'waving', word_class: 'AJ' }
  ],
  ransomed: [
    { word: 'ransom', word_class: 'N' },
    { word: 'ransom', word_class: 'V' },
    { word: 'ransomed', word_class: 'AJ' }
  ],
  eighties: [
    { word: 'eighty', word_class: 'N' },
    { word: 'eighty', word_class: 'AJ' },
    { word: 'eighties', word_class: 'N' }
  ],
  hasty: [
    { word: 'haste', word_class: 'N' },
    { word: 'hasten', word_class: 'V' },
    { word: 'hasty', word_class: 'AJ' },
    { word: 'hastings', word_class: 'N' },
    { word: 'hastening', word_class: 'N' },
    { word: 'hastiness', word_class: 'N' },
    { word: 'hastening', word_class: 'AJ' }
  ],
  magnetisation: [
    { word: 'magnet', word_class: 'N' },
    { word: 'magnetise', word_class: 'V' },
    { word: 'magnetic', word_class: 'AJ' },
    { word: 'magnetize', word_class: 'V' },
    { word: 'magnetics', word_class: 'N' },
    { word: 'magnetism', word_class: 'N' },
    { word: 'magnetized', word_class: 'AJ' },
    { word: 'magnetically', word_class: 'AV' },
    { word: 'magnetisation', word_class: 'N' },
    { word: 'magnetization', word_class: 'N' }
  ],
  revving: [
    { word: 'rev', word_class: 'N' },
    { word: 'rev', word_class: 'V' },
    { word: 'revved', word_class: 'AJ' },
    { word: 'revving', word_class: 'AJ' }
  ],
  successfulness: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  room: [
    { word: 'room', word_class: 'N' },
    { word: 'room', word_class: 'V' },
    { word: 'rooms', word_class: 'N' },
    { word: 'roomer', word_class: 'N' },
    { word: 'roomful', word_class: 'N' },
    { word: 'roomful', word_class: 'AJ' }
  ],
  injured: [
    { word: 'injure', word_class: 'V' },
    { word: 'injury', word_class: 'N' },
    { word: 'injured', word_class: 'AJ' },
    { word: 'injurious', word_class: 'AJ' },
    { word: 'injuriously', word_class: 'AV' },
    { word: 'injuriousness', word_class: 'N' }
  ],
  breasted: [
    { word: 'breast', word_class: 'N' },
    { word: 'breast', word_class: 'V' },
    { word: 'breasted', word_class: 'AJ' }
  ],
  trisect: [
    { word: 'trisect', word_class: 'N' },
    { word: 'trisect', word_class: 'V' },
    { word: 'trisection', word_class: 'N' }
  ],
  golfing: [
    { word: 'golf', word_class: 'N' },
    { word: 'golf', word_class: 'V' },
    { word: 'golfer', word_class: 'N' },
    { word: 'golfing', word_class: 'N' }
  ],
  undyed: [
    { word: 'undies', word_class: 'N' },
    { word: 'undyed', word_class: 'AJ' },
    { word: 'undying', word_class: 'AJ' }
  ],
  shady: [
    { word: 'shade', word_class: 'N' },
    { word: 'shade', word_class: 'V' },
    { word: 'shades', word_class: 'N' },
    { word: 'shady', word_class: 'AJ' },
    { word: 'shaded', word_class: 'AJ' },
    { word: 'shading', word_class: 'N' },
    { word: 'shadiness', word_class: 'N' }
  ],
  sliding: [
    { word: 'slide', word_class: 'N' },
    { word: 'slide', word_class: 'V' },
    { word: 'slider', word_class: 'N' },
    { word: 'sliding', word_class: 'AJ' }
  ],
  braving: [
    { word: 'brave', word_class: 'V' },
    { word: 'brave', word_class: 'N' },
    { word: 'brave', word_class: 'AJ' },
    { word: 'bravery', word_class: 'N' },
    { word: 'braving', word_class: 'N' },
    { word: 'bravely', word_class: 'AV' },
    { word: 'bravery', word_class: 'AJ' }
  ],
  courtship: [
    { word: 'court', word_class: 'N' },
    { word: 'court', word_class: 'V' },
    { word: 'courting', word_class: 'N' },
    { word: 'courtship', word_class: 'N' }
  ],
  opinionative: [
    { word: 'opine', word_class: 'V' },
    { word: 'opinion', word_class: 'N' },
    { word: 'opinionated', word_class: 'AJ' },
    { word: 'opinionative', word_class: 'AJ' }
  ],
  uninstructively: [
    { word: 'uninstructed', word_class: 'AJ' },
    { word: 'uninstructive', word_class: 'AJ' },
    { word: 'uninstructively', word_class: 'AV' }
  ],
  distinction: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  subjectively: [
    { word: 'subject', word_class: 'N' },
    { word: 'subject', word_class: 'V' },
    { word: 'subject', word_class: 'AJ' },
    { word: 'subjection', word_class: 'N' },
    { word: 'subjective', word_class: 'AJ' },
    { word: 'subjectivity', word_class: 'N' },
    { word: 'subjectively', word_class: 'AV' },
    { word: 'subjectiveness', word_class: 'N' }
  ],
  rheuma: [
    { word: 'rheuma', word_class: 'N' },
    { word: 'rheumatic', word_class: 'N' },
    { word: 'rheumatic', word_class: 'AJ' },
    { word: 'rheumatism', word_class: 'N' }
  ],
  lousy: [
    { word: 'louse', word_class: 'N' },
    { word: 'louse', word_class: 'V' },
    { word: 'lousy', word_class: 'AJ' },
    { word: 'lousiness', word_class: 'N' }
  ],
  dedication: [
    { word: 'dedicate', word_class: 'V' },
    { word: 'dedicated', word_class: 'AJ' },
    { word: 'dedication', word_class: 'N' }
  ],
  parched: [
    { word: 'parch', word_class: 'V' },
    { word: 'parched', word_class: 'AJ' },
    { word: 'parchment', word_class: 'N' }
  ],
  obstetrical: [
    { word: 'obstetric', word_class: 'AJ' },
    { word: 'obstetrics', word_class: 'N' },
    { word: 'obstetrical', word_class: 'AJ' }
  ],
  cleanly: [
    { word: 'clean', word_class: 'N' },
    { word: 'clean', word_class: 'V' },
    { word: 'clean', word_class: 'AJ' },
    { word: 'clean', word_class: 'AV' },
    { word: 'cleaner', word_class: 'N' },
    { word: 'cleaning', word_class: 'N' },
    { word: 'cleanly', word_class: 'AJ' },
    { word: 'cleanly', word_class: 'AV' },
    { word: 'cleaned', word_class: 'AJ' },
    { word: 'cleaners', word_class: 'N' },
    { word: 'cleanness', word_class: 'N' },
    { word: 'cleanliness', word_class: 'N' }
  ],
  obedient: [
    { word: 'obey', word_class: 'V' },
    { word: 'obedience', word_class: 'N' },
    { word: 'obedient', word_class: 'AJ' },
    { word: 'obediently', word_class: 'AV' }
  ],
  parcel: [
    { word: 'parcel', word_class: 'N' },
    { word: 'parcel', word_class: 'V' },
    { word: 'parceling', word_class: 'N' },
    { word: 'parcelling', word_class: 'N' }
  ],
  als: [{ word: 'al', word_class: 'N' }, { word: 'ale', word_class: 'N' }, { word: 'als', word_class: 'N' }],
  alligatored: [
    { word: 'alligator', word_class: 'N' },
    { word: 'alligator', word_class: 'V' },
    { word: 'alligatored', word_class: 'AJ' }
  ],
  billowing: [
    { word: 'billow', word_class: 'N' },
    { word: 'billow', word_class: 'V' },
    { word: 'billowing', word_class: 'AJ' }
  ],
  desolate: [
    { word: 'desolate', word_class: 'V' },
    { word: 'desolate', word_class: 'AJ' },
    { word: 'desolated', word_class: 'AJ' },
    { word: 'desolation', word_class: 'N' },
    { word: 'desolately', word_class: 'AV' }
  ],
  pant: [
    { word: 'pant', word_class: 'N' },
    { word: 'pant', word_class: 'V' },
    { word: 'pants', word_class: 'N' },
    { word: 'panting', word_class: 'N' },
    { word: 'panting', word_class: 'AJ' }
  ],
  disaffirm: [
    { word: 'disaffirm', word_class: 'V' },
    { word: 'disaffirmance', word_class: 'N' },
    { word: 'disaffirmation', word_class: 'N' }
  ],
  arithmetical: [
    { word: 'arithmetic', word_class: 'N' },
    { word: 'arithmetic', word_class: 'AJ' },
    { word: 'arithmetical', word_class: 'AJ' },
    { word: 'arithmetically', word_class: 'AV' }
  ],
  entertaining: [
    { word: 'entertain', word_class: 'V' },
    { word: 'entertainer', word_class: 'N' },
    { word: 'entertained', word_class: 'AJ' },
    { word: 'entertaining', word_class: 'AJ' },
    { word: 'entertainment', word_class: 'N' }
  ],
  trifling: [
    { word: 'trifle', word_class: 'N' },
    { word: 'trifle', word_class: 'V' },
    { word: 'trifling', word_class: 'N' },
    { word: 'trifling', word_class: 'AJ' }
  ],
  finale: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  encompassing: [
    { word: 'encompass', word_class: 'V' },
    { word: 'encompassing', word_class: 'AJ' },
    { word: 'encompassment', word_class: 'N' }
  ],
  incur: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  hack: [
    { word: 'hack', word_class: 'N' },
    { word: 'hack', word_class: 'V' },
    { word: 'hackee', word_class: 'N' },
    { word: 'hacker', word_class: 'N' }
  ],
  ruffled: [
    { word: 'ruffle', word_class: 'N' },
    { word: 'ruffle', word_class: 'V' },
    { word: 'ruffled', word_class: 'AJ' }
  ],
  enlisting: [
    { word: 'enlist', word_class: 'V' },
    { word: 'enlistee', word_class: 'N' },
    { word: 'enlisted', word_class: 'AJ' },
    { word: 'enlisting', word_class: 'N' },
    { word: 'enlistment', word_class: 'N' }
  ],
  deceit: [
    { word: 'deceit', word_class: 'N' },
    { word: 'deceive', word_class: 'V' },
    { word: 'deceiver', word_class: 'N' },
    { word: 'deception', word_class: 'N' },
    { word: 'deceitful', word_class: 'AJ' },
    { word: 'deceptive', word_class: 'AJ' },
    { word: 'deceptively', word_class: 'AV' },
    { word: 'deceitfulness', word_class: 'N' },
    { word: 'deceptiveness', word_class: 'N' }
  ],
  naturalized: [
    { word: 'nature', word_class: 'N' },
    { word: 'natural', word_class: 'N' },
    { word: 'natured', word_class: 'AJ' },
    { word: 'natural', word_class: 'AJ' },
    { word: 'naturism', word_class: 'N' },
    { word: 'naturalise', word_class: 'V' },
    { word: 'naturalize', word_class: 'V' },
    { word: 'naturalism', word_class: 'N' },
    { word: 'naturalist', word_class: 'N' },
    { word: 'naturally', word_class: 'AV' },
    { word: 'naturalness', word_class: 'N' },
    { word: 'naturalized', word_class: 'AJ' },
    { word: 'naturalistic', word_class: 'AJ' },
    { word: 'naturalisation', word_class: 'N' },
    { word: 'naturalization', word_class: 'N' }
  ],
  birding: [{ word: 'bird', word_class: 'N' }, { word: 'bird', word_class: 'V' }, { word: 'birding', word_class: 'N' }],
  hymnal: [
    { word: 'hymn', word_class: 'N' },
    { word: 'hymn', word_class: 'V' },
    { word: 'hymnal', word_class: 'N' },
    { word: 'hymnal', word_class: 'AJ' }
  ],
  nonconformance: [
    { word: 'nonconformity', word_class: 'N' },
    { word: 'nonconformance', word_class: 'N' },
    { word: 'nonconforming', word_class: 'AJ' }
  ],
  inculpableness: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  dull: [
    { word: 'dull', word_class: 'V' },
    { word: 'dull', word_class: 'AJ' },
    { word: 'dully', word_class: 'AV' },
    { word: 'dulled', word_class: 'AJ' },
    { word: 'dullness', word_class: 'N' }
  ],
  fulgurous: [
    { word: 'fulgurate', word_class: 'V' },
    { word: 'fulgurant', word_class: 'AJ' },
    { word: 'fulgurous', word_class: 'AJ' },
    { word: 'fulguration', word_class: 'N' },
    { word: 'fulgurating', word_class: 'AJ' }
  ],
  marbled: [
    { word: 'marble', word_class: 'N' },
    { word: 'marble', word_class: 'V' },
    { word: 'marbles', word_class: 'N' },
    { word: 'marbled', word_class: 'AJ' },
    { word: 'marbling', word_class: 'N' }
  ],
  autocratical: [
    { word: 'autocrat', word_class: 'N' },
    { word: 'autocracy', word_class: 'N' },
    { word: 'autocratic', word_class: 'AJ' },
    { word: 'autocratical', word_class: 'AJ' },
    { word: 'autocratically', word_class: 'AV' }
  ],
  herding: [
    { word: 'herd', word_class: 'N' },
    { word: 'herd', word_class: 'V' },
    { word: 'herder', word_class: 'N' },
    { word: 'herding', word_class: 'AJ' }
  ],
  collapsible: [
    { word: 'collapse', word_class: 'N' },
    { word: 'collapse', word_class: 'V' },
    { word: 'collapsed', word_class: 'AJ' },
    { word: 'collapsable', word_class: 'AJ' },
    { word: 'collapsible', word_class: 'AJ' }
  ],
  furl: [{ word: 'furl', word_class: 'N' }, { word: 'furl', word_class: 'V' }, { word: 'furled', word_class: 'AJ' }],
  vociferously: [
    { word: 'vociferate', word_class: 'V' },
    { word: 'vociferous', word_class: 'AJ' },
    { word: 'vociferation', word_class: 'N' },
    { word: 'vociferously', word_class: 'AV' }
  ],
  decked: [
    { word: 'deck', word_class: 'N' },
    { word: 'deck', word_class: 'V' },
    { word: 'decked', word_class: 'AJ' },
    { word: 'decking', word_class: 'N' }
  ],
  chattering: [
    { word: 'chatter', word_class: 'N' },
    { word: 'chatter', word_class: 'V' },
    { word: 'chatterer', word_class: 'N' },
    { word: 'chattering', word_class: 'N' },
    { word: 'chattering', word_class: 'AJ' }
  ],
  inefficiency: [
    { word: 'inefficiency', word_class: 'N' },
    { word: 'inefficient', word_class: 'AJ' },
    { word: 'inefficiently', word_class: 'AV' }
  ],
  scripted: [
    { word: 'script', word_class: 'N' },
    { word: 'script', word_class: 'V' },
    { word: 'scripted', word_class: 'AJ' }
  ],
  decadence: [
    { word: 'decade', word_class: 'N' },
    { word: 'decadent', word_class: 'N' },
    { word: 'decadence', word_class: 'N' },
    { word: 'decadent', word_class: 'AJ' }
  ],
  cluttered: [
    { word: 'clutter', word_class: 'N' },
    { word: 'clutter', word_class: 'V' },
    { word: 'cluttered', word_class: 'AJ' }
  ],
  admonishing: [
    { word: 'admonish', word_class: 'V' },
    { word: 'admonisher', word_class: 'N' },
    { word: 'admonition', word_class: 'N' },
    { word: 'admonitory', word_class: 'AJ' },
    { word: 'admonished', word_class: 'AJ' },
    { word: 'admonishing', word_class: 'AJ' },
    { word: 'admonishment', word_class: 'N' }
  ],
  rateable: [
    { word: 'rateable', word_class: 'AJ' },
    { word: 'rateables', word_class: 'N' },
    { word: 'rateability', word_class: 'N' }
  ],
  nativism: [
    { word: 'native', word_class: 'N' },
    { word: 'native', word_class: 'AJ' },
    { word: 'nativism', word_class: 'N' },
    { word: 'nativity', word_class: 'N' },
    { word: 'nativeness', word_class: 'N' }
  ],
  steward: [
    { word: 'steward', word_class: 'N' },
    { word: 'steward', word_class: 'V' },
    { word: 'stewardship', word_class: 'N' }
  ],
  parody: [
    { word: 'parody', word_class: 'N' },
    { word: 'parody', word_class: 'V' },
    { word: 'parodist', word_class: 'N' }
  ],
  squawk: [
    { word: 'squawk', word_class: 'N' },
    { word: 'squawk', word_class: 'V' },
    { word: 'squawker', word_class: 'N' }
  ],
  transactions: [
    { word: 'transact', word_class: 'N' },
    { word: 'transact', word_class: 'V' },
    { word: 'transaction', word_class: 'N' },
    { word: 'transactions', word_class: 'N' }
  ],
  slay: [
    { word: 'slay', word_class: 'V' },
    { word: 'slain', word_class: 'N' },
    { word: 'slain', word_class: 'AJ' },
    { word: 'slayer', word_class: 'N' },
    { word: 'slaying', word_class: 'N' }
  ],
  crewman: [{ word: 'crew', word_class: 'N' }, { word: 'crew', word_class: 'V' }, { word: 'crewman', word_class: 'N' }],
  stalking: [
    { word: 'stalk', word_class: 'N' },
    { word: 'stalk', word_class: 'V' },
    { word: 'stalker', word_class: 'N' },
    { word: 'stalked', word_class: 'AJ' },
    { word: 'stalking', word_class: 'N' },
    { word: 'stalking', word_class: 'AJ' }
  ],
  cute: [
    { word: 'cute', word_class: 'AJ' },
    { word: 'cutely', word_class: 'AV' },
    { word: 'cuteness', word_class: 'N' }
  ],
  breakers: [
    { word: 'break', word_class: 'N' },
    { word: 'break', word_class: 'V' },
    { word: 'broken', word_class: 'AJ' },
    { word: 'breaker', word_class: 'N' },
    { word: 'breakage', word_class: 'N' },
    { word: 'breakers', word_class: 'N' },
    { word: 'breaking', word_class: 'N' },
    { word: 'breaking', word_class: 'AJ' }
  ],
  retrospectively: [
    { word: 'retrospect', word_class: 'N' },
    { word: 'retrospect', word_class: 'V' },
    { word: 'retrospection', word_class: 'N' },
    { word: 'retrospective', word_class: 'N' },
    { word: 'retrospective', word_class: 'AJ' },
    { word: 'retrospectively', word_class: 'AV' }
  ],
  capsulate: [
    { word: 'capsule', word_class: 'N' },
    { word: 'capsulate', word_class: 'V' },
    { word: 'capsulate', word_class: 'AJ' },
    { word: 'capsulated', word_class: 'AJ' },
    { word: 'capsulation', word_class: 'N' }
  ],
  harmonized: [
    { word: 'harmony', word_class: 'N' },
    { word: 'harmonic', word_class: 'N' },
    { word: 'harmonic', word_class: 'AJ' },
    { word: 'harmonize', word_class: 'V' },
    { word: 'harmonics', word_class: 'N' },
    { word: 'harmonise', word_class: 'V' },
    { word: 'harmonious', word_class: 'AJ' },
    { word: 'harmonical', word_class: 'AJ' },
    { word: 'harmonized', word_class: 'AJ' },
    { word: 'harmonically', word_class: 'AV' },
    { word: 'harmoniously', word_class: 'AV' },
    { word: 'harmonisation', word_class: 'N' },
    { word: 'harmonization', word_class: 'N' },
    { word: 'harmoniousness', word_class: 'N' }
  ],
  quivering: [
    { word: 'quiver', word_class: 'N' },
    { word: 'quiver', word_class: 'V' },
    { word: 'quivering', word_class: 'N' },
    { word: 'quivering', word_class: 'AJ' }
  ],
  infarct: [
    { word: 'infarct', word_class: 'N' },
    { word: 'infarct', word_class: 'V' },
    { word: 'infarction', word_class: 'N' }
  ],
  roil: [
    { word: 'roil', word_class: 'V' },
    { word: 'roiled', word_class: 'AJ' },
    { word: 'roiling', word_class: 'AJ' }
  ],
  indecisive: [
    { word: 'indecision', word_class: 'N' },
    { word: 'indecisive', word_class: 'AJ' },
    { word: 'indecisively', word_class: 'AV' },
    { word: 'indecisiveness', word_class: 'N' }
  ],
  meningism: [
    { word: 'meninges', word_class: 'N' },
    { word: 'meningism', word_class: 'N' },
    { word: 'meningeal', word_class: 'AJ' },
    { word: 'meningitis', word_class: 'N' }
  ],
  calender: [
    { word: 'calender', word_class: 'N' },
    { word: 'calender', word_class: 'V' },
    { word: 'calendered', word_class: 'AJ' }
  ],
  deride: [
    { word: 'deride', word_class: 'V' },
    { word: 'derision', word_class: 'N' },
    { word: 'derisive', word_class: 'AJ' },
    { word: 'derisively', word_class: 'AV' }
  ],
  dispose: [
    { word: 'dispose', word_class: 'N' },
    { word: 'dispose', word_class: 'V' },
    { word: 'disposal', word_class: 'N' },
    { word: 'disposure', word_class: 'N' },
    { word: 'disposed', word_class: 'AJ' },
    { word: 'disposable', word_class: 'AJ' },
    { word: 'disposition', word_class: 'N' }
  ],
  idealise: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  consequence: [
    { word: 'consequent', word_class: 'N' },
    { word: 'consequence', word_class: 'N' },
    { word: 'consequent', word_class: 'AJ' },
    { word: 'consequently', word_class: 'AV' }
  ],
  namely: [
    { word: 'name', word_class: 'N' },
    { word: 'name', word_class: 'V' },
    { word: 'names', word_class: 'N' },
    { word: 'named', word_class: 'AJ' },
    { word: 'naming', word_class: 'N' },
    { word: 'namely', word_class: 'AV' },
    { word: 'naming', word_class: 'AJ' }
  ],
  propitiative: [
    { word: 'propitiate', word_class: 'V' },
    { word: 'propitious', word_class: 'AJ' },
    { word: 'propitiation', word_class: 'N' },
    { word: 'propitiative', word_class: 'AJ' },
    { word: 'propitiously', word_class: 'AV' },
    { word: 'propitiousness', word_class: 'N' }
  ],
  hebraize: [
    { word: 'hebraise', word_class: 'V' },
    { word: 'hebraize', word_class: 'V' },
    { word: 'hebraisation', word_class: 'N' },
    { word: 'hebraization', word_class: 'N' }
  ],
  clattering: [
    { word: 'clatter', word_class: 'N' },
    { word: 'clatter', word_class: 'V' },
    { word: 'clattering', word_class: 'AJ' }
  ],
  demodulate: [
    { word: 'demodulate', word_class: 'V' },
    { word: 'demodulator', word_class: 'N' },
    { word: 'demodulation', word_class: 'N' }
  ],
  unique: [
    { word: 'unique', word_class: 'AJ' },
    { word: 'uniquely', word_class: 'AV' },
    { word: 'uniqueness', word_class: 'N' }
  ],
  paragraph: [
    { word: 'paragraph', word_class: 'N' },
    { word: 'paragraph', word_class: 'V' },
    { word: 'paragrapher', word_class: 'N' }
  ],
  legislatively: [
    { word: 'legislate', word_class: 'V' },
    { word: 'legislator', word_class: 'N' },
    { word: 'legislation', word_class: 'N' },
    { word: 'legislature', word_class: 'N' },
    { word: 'legislating', word_class: 'N' },
    { word: 'legislative', word_class: 'AJ' },
    { word: 'legislatively', word_class: 'AV' }
  ],
  pulsation: [
    { word: 'pulse', word_class: 'V' },
    { word: 'pulse', word_class: 'N' },
    { word: 'pulsate', word_class: 'V' },
    { word: 'pulsed', word_class: 'AJ' },
    { word: 'pulsing', word_class: 'N' },
    { word: 'pulsing', word_class: 'AJ' },
    { word: 'pulsation', word_class: 'N' },
    { word: 'pulsating', word_class: 'AJ' }
  ],
  mortality: [
    { word: 'mortal', word_class: 'N' },
    { word: 'mortal', word_class: 'AJ' },
    { word: 'mortality', word_class: 'N' },
    { word: 'mortally', word_class: 'AV' }
  ],
  camphorate: [
    { word: 'camphor', word_class: 'N' },
    { word: 'camphorate', word_class: 'V' },
    { word: 'camphoric', word_class: 'AJ' },
    { word: 'camphorated', word_class: 'AJ' },
    { word: 'camphoration', word_class: 'N' }
  ],
  uninstructed: [
    { word: 'uninstructed', word_class: 'AJ' },
    { word: 'uninstructive', word_class: 'AJ' },
    { word: 'uninstructively', word_class: 'AV' }
  ],
  whispered: [
    { word: 'whisper', word_class: 'N' },
    { word: 'whisper', word_class: 'V' },
    { word: 'whisperer', word_class: 'N' },
    { word: 'whispered', word_class: 'AJ' },
    { word: 'whispering', word_class: 'N' },
    { word: 'whispering', word_class: 'AJ' }
  ],
  winnow: [
    { word: 'winnow', word_class: 'N' },
    { word: 'winnow', word_class: 'V' },
    { word: 'winnowing', word_class: 'N' }
  ],
  whining: [
    { word: 'whine', word_class: 'N' },
    { word: 'whine', word_class: 'V' },
    { word: 'whiner', word_class: 'N' },
    { word: 'whiny', word_class: 'AJ' },
    { word: 'whining', word_class: 'AJ' }
  ],
  victim: [
    { word: 'victim', word_class: 'N' },
    { word: 'victimise', word_class: 'V' },
    { word: 'victimize', word_class: 'V' },
    { word: 'victimized', word_class: 'AJ' },
    { word: 'victimisation', word_class: 'N' },
    { word: 'victimization', word_class: 'N' }
  ],
  hypersensitize: [
    { word: 'hypersensitise', word_class: 'V' },
    { word: 'hypersensitize', word_class: 'V' },
    { word: 'hypersensitive', word_class: 'AJ' },
    { word: 'hypersensitivity', word_class: 'N' },
    { word: 'hypersensitized', word_class: 'AJ' },
    { word: 'hypersensitisation', word_class: 'N' },
    { word: 'hypersensitization', word_class: 'N' }
  ],
  regalement: [
    { word: 'regal', word_class: 'AJ' },
    { word: 'regale', word_class: 'N' },
    { word: 'regale', word_class: 'V' },
    { word: 'regally', word_class: 'AV' },
    { word: 'regalement', word_class: 'N' }
  ],
  dumps: [
    { word: 'dump', word_class: 'N' },
    { word: 'dump', word_class: 'V' },
    { word: 'dumps', word_class: 'N' },
    { word: 'dumper', word_class: 'N' },
    { word: 'dumped', word_class: 'AJ' },
    { word: 'dumping', word_class: 'N' }
  ],
  prohibited: [
    { word: 'prohibit', word_class: 'V' },
    { word: 'prohibition', word_class: 'N' },
    { word: 'prohibited', word_class: 'AJ' },
    { word: 'prohibitive', word_class: 'AJ' },
    { word: 'prohibitively', word_class: 'AV' }
  ],
  sizing: [
    { word: 'size', word_class: 'N' },
    { word: 'size', word_class: 'V' },
    { word: 'size', word_class: 'AJ' },
    { word: 'sized', word_class: 'AJ' },
    { word: 'sizing', word_class: 'N' }
  ],
  molt: [{ word: 'molt', word_class: 'N' }, { word: 'molt', word_class: 'V' }, { word: 'molting', word_class: 'N' }],
  aerie: [{ word: 'aery', word_class: 'N' }, { word: 'aerie', word_class: 'N' }, { word: 'aery', word_class: 'AJ' }],
  likely: [
    { word: 'like', word_class: 'N' },
    { word: 'like', word_class: 'V' },
    { word: 'like', word_class: 'AJ' },
    { word: 'like', word_class: 'AV' },
    { word: 'liked', word_class: 'AJ' },
    { word: 'liking', word_class: 'N' },
    { word: 'likely', word_class: 'AJ' },
    { word: 'likely', word_class: 'AV' },
    { word: 'likeness', word_class: 'N' },
    { word: 'likelihood', word_class: 'N' }
  ],
  dismissible: [
    { word: 'dismiss', word_class: 'N' },
    { word: 'dismiss', word_class: 'V' },
    { word: 'dismissal', word_class: 'N' },
    { word: 'dismission', word_class: 'N' },
    { word: 'dismissed', word_class: 'AJ' },
    { word: 'dismissive', word_class: 'AJ' },
    { word: 'dismissible', word_class: 'AJ' }
  ],
  extendible: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  enigmatic: [
    { word: 'enigma', word_class: 'N' },
    { word: 'enigmatic', word_class: 'AJ' },
    { word: 'enigmatical', word_class: 'AJ' },
    { word: 'enigmatically', word_class: 'AV' }
  ],
  consultation: [
    { word: 'consult', word_class: 'V' },
    { word: 'consultant', word_class: 'N' },
    { word: 'consultive', word_class: 'AJ' },
    { word: 'consultation', word_class: 'N' },
    { word: 'consultative', word_class: 'AJ' }
  ],
  rotor: [
    { word: 'rot', word_class: 'N' },
    { word: 'rot', word_class: 'V' },
    { word: 'rotor', word_class: 'N' },
    { word: 'rotted', word_class: 'AJ' },
    { word: 'rotten', word_class: 'AJ' },
    { word: 'rotting', word_class: 'N' },
    { word: 'rotting', word_class: 'AJ' },
    { word: 'rottenness', word_class: 'N' }
  ],
  instigant: [
    { word: 'instigant', word_class: 'N' },
    { word: 'instigate', word_class: 'V' },
    { word: 'instigator', word_class: 'N' },
    { word: 'instigation', word_class: 'N' },
    { word: 'instigative', word_class: 'AJ' }
  ],
  ventriloquize: [
    { word: 'ventriloquise', word_class: 'V' },
    { word: 'ventriloquism', word_class: 'N' },
    { word: 'ventriloquize', word_class: 'V' },
    { word: 'ventriloquization', word_class: 'N' }
  ],
  roarer: [
    { word: 'roar', word_class: 'N' },
    { word: 'roar', word_class: 'V' },
    { word: 'roarer', word_class: 'N' },
    { word: 'roaring', word_class: 'N' },
    { word: 'roaring', word_class: 'AJ' },
    { word: 'roaring', word_class: 'AV' }
  ],
  domination: [
    { word: 'domine', word_class: 'N' },
    { word: 'dominate', word_class: 'V' },
    { word: 'domineer', word_class: 'V' },
    { word: 'dominant', word_class: 'N' },
    { word: 'dominance', word_class: 'N' },
    { word: 'dominant', word_class: 'AJ' },
    { word: 'dominated', word_class: 'AJ' },
    { word: 'domination', word_class: 'N' },
    { word: 'dominical', word_class: 'AJ' },
    { word: 'dominating', word_class: 'AJ' },
    { word: 'domineering', word_class: 'AJ' }
  ],
  occlusive: [
    { word: 'occlude', word_class: 'V' },
    { word: 'occluded', word_class: 'AJ' },
    { word: 'occlusion', word_class: 'N' },
    { word: 'occlusive', word_class: 'N' },
    { word: 'occlusive', word_class: 'AJ' }
  ],
  retire: [
    { word: 'retire', word_class: 'N' },
    { word: 'retire', word_class: 'V' },
    { word: 'retiree', word_class: 'N' },
    { word: 'retired', word_class: 'AJ' },
    { word: 'retiring', word_class: 'AJ' },
    { word: 'retirement', word_class: 'N' }
  ],
  umpireship: [
    { word: 'umpire', word_class: 'N' },
    { word: 'umpire', word_class: 'V' },
    { word: 'umpirage', word_class: 'N' },
    { word: 'umpiring', word_class: 'N' },
    { word: 'umpireship', word_class: 'N' }
  ],
  apotheosize: [
    { word: 'apotheose', word_class: 'V' },
    { word: 'apotheosize', word_class: 'V' },
    { word: 'apotheosization', word_class: 'N' }
  ],
  discombobulate: [
    { word: 'discombobulate', word_class: 'V' },
    { word: 'discombobulated', word_class: 'AJ' },
    { word: 'discombobulation', word_class: 'N' }
  ],
  covered: [
    { word: 'cover', word_class: 'N' },
    { word: 'cover', word_class: 'V' },
    { word: 'covered', word_class: 'AJ' },
    { word: 'covering', word_class: 'N' },
    { word: 'covering', word_class: 'AJ' }
  ],
  believe: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  senior: [
    { word: 'senior', word_class: 'N' },
    { word: 'senior', word_class: 'AJ' },
    { word: 'seniority', word_class: 'N' }
  ],
  spasticity: [
    { word: 'spastic', word_class: 'N' },
    { word: 'spastic', word_class: 'AJ' },
    { word: 'spasticity', word_class: 'N' }
  ],
  meshed: [
    { word: 'mesh', word_class: 'N' },
    { word: 'mesh', word_class: 'V' },
    { word: 'meshed', word_class: 'N' },
    { word: 'meshed', word_class: 'AJ' },
    { word: 'meshing', word_class: 'N' }
  ],
  din: [
    { word: 'din', word_class: 'N' },
    { word: 'din', word_class: 'V' },
    { word: 'dine', word_class: 'V' },
    { word: 'diner', word_class: 'N' },
    { word: 'dining', word_class: 'N' }
  ],
  follower: [
    { word: 'follow', word_class: 'V' },
    { word: 'follower', word_class: 'N' },
    { word: 'followers', word_class: 'N' },
    { word: 'following', word_class: 'N' },
    { word: 'following', word_class: 'AJ' }
  ],
  preceding: [
    { word: 'precede', word_class: 'V' },
    { word: 'precedent', word_class: 'N' },
    { word: 'precedence', word_class: 'N' },
    { word: 'precedent', word_class: 'AJ' },
    { word: 'precedency', word_class: 'N' },
    { word: 'preceding', word_class: 'AJ' },
    { word: 'precedented', word_class: 'AJ' }
  ],
  jinxed: [{ word: 'jinx', word_class: 'N' }, { word: 'jinx', word_class: 'V' }, { word: 'jinxed', word_class: 'AJ' }],
  skyward: [
    { word: 'skyward', word_class: 'AJ' },
    { word: 'skyward', word_class: 'AV' },
    { word: 'skywards', word_class: 'AV' }
  ],
  protecting: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  phlegmatical: [
    { word: 'phlegmatic', word_class: 'AJ' },
    { word: 'phlegmatical', word_class: 'AJ' },
    { word: 'phlegmatically', word_class: 'AV' }
  ],
  cannibal: [
    { word: 'cannibal', word_class: 'N' },
    { word: 'cannibalise', word_class: 'V' },
    { word: 'cannibalism', word_class: 'N' },
    { word: 'cannibalize', word_class: 'V' },
    { word: 'cannibalisation', word_class: 'N' },
    { word: 'cannibalization', word_class: 'N' }
  ],
  imperative: [
    { word: 'imperative', word_class: 'N' },
    { word: 'imperative', word_class: 'AJ' },
    { word: 'imperatively', word_class: 'AV' },
    { word: 'imperativeness', word_class: 'N' }
  ],
  bracer: [
    { word: 'brace', word_class: 'N' },
    { word: 'brace', word_class: 'V' },
    { word: 'bracer', word_class: 'N' },
    { word: 'braces', word_class: 'N' },
    { word: 'braced', word_class: 'AJ' },
    { word: 'bracing', word_class: 'N' },
    { word: 'bracing', word_class: 'AJ' }
  ],
  carnality: [
    { word: 'carnal', word_class: 'AJ' },
    { word: 'carnality', word_class: 'N' },
    { word: 'carnalize', word_class: 'V' },
    { word: 'carnally', word_class: 'AV' }
  ],
  gospeller: [
    { word: 'gospel', word_class: 'N' },
    { word: 'gospels', word_class: 'N' },
    { word: 'gospeler', word_class: 'N' },
    { word: 'gospeller', word_class: 'N' }
  ],
  winded: [
    { word: 'wind', word_class: 'N' },
    { word: 'wind', word_class: 'V' },
    { word: 'winder', word_class: 'N' },
    { word: 'winded', word_class: 'AJ' },
    { word: 'winding', word_class: 'N' },
    { word: 'winding', word_class: 'AJ' }
  ],
  vote: [
    { word: 'vote', word_class: 'N' },
    { word: 'vote', word_class: 'V' },
    { word: 'voter', word_class: 'N' },
    { word: 'voting', word_class: 'N' }
  ],
  indeterminate: [
    { word: 'indeterminancy', word_class: 'N' },
    { word: 'indeterminate', word_class: 'AJ' },
    { word: 'indeterminable', word_class: 'AJ' },
    { word: 'indeterminably', word_class: 'AV' },
    { word: 'indetermination', word_class: 'N' },
    { word: 'indeterminateness', word_class: 'N' }
  ],
  trespass: [
    { word: 'trespass', word_class: 'N' },
    { word: 'trespass', word_class: 'V' },
    { word: 'trespasser', word_class: 'N' },
    { word: 'trespassing', word_class: 'AJ' }
  ],
  salinity: [
    { word: 'saline', word_class: 'N' },
    { word: 'saline', word_class: 'AJ' },
    { word: 'salinate', word_class: 'V' },
    { word: 'salinity', word_class: 'N' }
  ],
  diligently: [
    { word: 'diligence', word_class: 'N' },
    { word: 'diligent', word_class: 'AJ' },
    { word: 'diligently', word_class: 'AV' }
  ],
  behavioristic: [
    { word: 'behaviorist', word_class: 'N' },
    { word: 'behaviorist', word_class: 'AJ' },
    { word: 'behavioristic', word_class: 'AJ' }
  ],
  wheezing: [
    { word: 'wheeze', word_class: 'N' },
    { word: 'wheeze', word_class: 'V' },
    { word: 'wheezy', word_class: 'AJ' },
    { word: 'wheezing', word_class: 'AJ' },
    { word: 'wheeziness', word_class: 'N' }
  ],
  inane: [
    { word: 'inane', word_class: 'AJ' },
    { word: 'inanity', word_class: 'N' },
    { word: 'inanely', word_class: 'AV' }
  ],
  aerosolized: [
    { word: 'aerosol', word_class: 'N' },
    { word: 'aerosolize', word_class: 'V' },
    { word: 'aerosolized', word_class: 'AJ' }
  ],
  partake: [
    { word: 'partake', word_class: 'V' },
    { word: 'partaker', word_class: 'N' },
    { word: 'partaken', word_class: 'AJ' }
  ],
  reserved: [
    { word: 'reserve', word_class: 'N' },
    { word: 'reserve', word_class: 'V' },
    { word: 'reserve', word_class: 'AJ' },
    { word: 'reserves', word_class: 'N' },
    { word: 'reserved', word_class: 'AJ' },
    { word: 'reservist', word_class: 'N' },
    { word: 'reservation', word_class: 'N' }
  ],
  half: [{ word: 'half', word_class: 'N' }, { word: 'half', word_class: 'AJ' }, { word: 'half', word_class: 'AV' }],
  perceptibility: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  surfboard: [
    { word: 'surfboard', word_class: 'N' },
    { word: 'surfboarder', word_class: 'N' },
    { word: 'surfboarding', word_class: 'N' }
  ],
  bandage: [
    { word: 'bandage', word_class: 'N' },
    { word: 'bandage', word_class: 'V' },
    { word: 'bandaged', word_class: 'AJ' },
    { word: 'bandaging', word_class: 'N' }
  ],
  antagonise: [
    { word: 'antagonise', word_class: 'V' },
    { word: 'antagonist', word_class: 'N' },
    { word: 'antagonize', word_class: 'V' },
    { word: 'antagonism', word_class: 'N' },
    { word: 'antagonistic', word_class: 'AJ' },
    { word: 'antagonizing', word_class: 'AJ' },
    { word: 'antagonisation', word_class: 'N' },
    { word: 'antagonization', word_class: 'N' },
    { word: 'antagonistically', word_class: 'AV' }
  ],
  black: [
    { word: 'black', word_class: 'V' },
    { word: 'black', word_class: 'N' },
    { word: 'black', word_class: 'AJ' },
    { word: 'blacking', word_class: 'N' },
    { word: 'blackness', word_class: 'N' }
  ],
  pally: [
    { word: 'pally', word_class: 'AJ' },
    { word: 'palliative', word_class: 'N' },
    { word: 'palliative', word_class: 'AJ' }
  ],
  variegated: [
    { word: 'variegate', word_class: 'V' },
    { word: 'variegated', word_class: 'AJ' },
    { word: 'variegation', word_class: 'N' }
  ],
  enigma: [
    { word: 'enigma', word_class: 'N' },
    { word: 'enigmatic', word_class: 'AJ' },
    { word: 'enigmatical', word_class: 'AJ' },
    { word: 'enigmatically', word_class: 'AV' }
  ],
  tucker: [
    { word: 'tuck', word_class: 'N' },
    { word: 'tuck', word_class: 'V' },
    { word: 'tucker', word_class: 'N' },
    { word: 'tucker', word_class: 'V' },
    { word: 'tucked', word_class: 'AJ' }
  ],
  imminent: [
    { word: 'imminence', word_class: 'N' },
    { word: 'imminency', word_class: 'N' },
    { word: 'imminent', word_class: 'AJ' },
    { word: 'imminently', word_class: 'AV' }
  ],
  detectable: [
    { word: 'detect', word_class: 'V' },
    { word: 'detector', word_class: 'N' },
    { word: 'detecting', word_class: 'N' },
    { word: 'detection', word_class: 'N' },
    { word: 'detective', word_class: 'N' },
    { word: 'detected', word_class: 'AJ' },
    { word: 'detective', word_class: 'AJ' },
    { word: 'detectable', word_class: 'AJ' }
  ],
  prescriptive: [
    { word: 'prescribe', word_class: 'N' },
    { word: 'prescribe', word_class: 'V' },
    { word: 'prescript', word_class: 'N' },
    { word: 'prescript', word_class: 'V' },
    { word: 'prescript', word_class: 'AJ' },
    { word: 'prescribed', word_class: 'AJ' },
    { word: 'prescription', word_class: 'N' },
    { word: 'prescription', word_class: 'AJ' },
    { word: 'prescriptive', word_class: 'AJ' }
  ],
  radial: [
    { word: 'radial', word_class: 'N' },
    { word: 'radial', word_class: 'AJ' },
    { word: 'radially', word_class: 'AV' }
  ],
  vend: [
    { word: 'vend', word_class: 'V' },
    { word: 'vendee', word_class: 'N' },
    { word: 'vender', word_class: 'N' },
    { word: 'vendor', word_class: 'N' },
    { word: 'vending', word_class: 'N' },
    { word: 'vendition', word_class: 'N' }
  ],
  packaging: [
    { word: 'pack', word_class: 'N' },
    { word: 'pack', word_class: 'V' },
    { word: 'packer', word_class: 'N' },
    { word: 'package', word_class: 'N' },
    { word: 'package', word_class: 'V' },
    { word: 'packing', word_class: 'N' },
    { word: 'packed', word_class: 'AJ' },
    { word: 'packaged', word_class: 'AJ' },
    { word: 'packaging', word_class: 'N' }
  ],
  apperceptive: [
    { word: 'apperceive', word_class: 'V' },
    { word: 'apperception', word_class: 'N' },
    { word: 'apperceptive', word_class: 'AJ' }
  ],
  advertiser: [
    { word: 'advertise', word_class: 'V' },
    { word: 'advertiser', word_class: 'N' },
    { word: 'advertised', word_class: 'AJ' },
    { word: 'advertising', word_class: 'N' },
    { word: 'advertisement', word_class: 'N' }
  ],
  suspenders: [
    { word: 'suspend', word_class: 'V' },
    { word: 'suspense', word_class: 'N' },
    { word: 'suspender', word_class: 'N' },
    { word: 'suspended', word_class: 'AJ' },
    { word: 'suspension', word_class: 'N' },
    { word: 'suspenders', word_class: 'N' },
    { word: 'suspensive', word_class: 'AJ' },
    { word: 'suspenseful', word_class: 'AJ' }
  ],
  annulated: [
    { word: 'annul', word_class: 'V' },
    { word: 'annulate', word_class: 'AJ' },
    { word: 'annulment', word_class: 'N' },
    { word: 'annulated', word_class: 'AJ' }
  ],
  overstate: [
    { word: 'overstate', word_class: 'V' },
    { word: 'overstated', word_class: 'AJ' },
    { word: 'overstatement', word_class: 'N' }
  ],
  exasperating: [
    { word: 'exasperate', word_class: 'V' },
    { word: 'exasperated', word_class: 'AJ' },
    { word: 'exasperation', word_class: 'N' },
    { word: 'exasperating', word_class: 'AJ' }
  ],
  equitable: [
    { word: 'equitable', word_class: 'AJ' },
    { word: 'equitably', word_class: 'AV' },
    { word: 'equitation', word_class: 'N' }
  ],
  subminiaturisation: [
    { word: 'subminiaturise', word_class: 'V' },
    { word: 'subminiaturize', word_class: 'V' },
    { word: 'subminiaturisation', word_class: 'N' },
    { word: 'subminiaturization', word_class: 'N' }
  ],
  traveling: [
    { word: 'travel', word_class: 'N' },
    { word: 'travel', word_class: 'V' },
    { word: 'traveler', word_class: 'N' },
    { word: 'traveled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'N' },
    { word: 'traveller', word_class: 'N' },
    { word: 'travelled', word_class: 'AJ' },
    { word: 'traveling', word_class: 'AJ' },
    { word: 'travelling', word_class: 'N' },
    { word: 'travelable', word_class: 'AJ' }
  ],
  carpeting: [
    { word: 'carpet', word_class: 'N' },
    { word: 'carpet', word_class: 'V' },
    { word: 'carpeted', word_class: 'AJ' },
    { word: 'carpeting', word_class: 'N' }
  ],
  silence: [
    { word: 'silence', word_class: 'N' },
    { word: 'silence', word_class: 'V' },
    { word: 'silent', word_class: 'AJ' },
    { word: 'silencer', word_class: 'N' },
    { word: 'silenced', word_class: 'AJ' },
    { word: 'silently', word_class: 'AV' }
  ],
  reactivate: [
    { word: 'reactive', word_class: 'AJ' },
    { word: 'reactivate', word_class: 'V' },
    { word: 'reactivity', word_class: 'N' },
    { word: 'reactivation', word_class: 'N' }
  ],
  starved: [
    { word: 'starve', word_class: 'V' },
    { word: 'starved', word_class: 'AJ' },
    { word: 'starving', word_class: 'N' },
    { word: 'starving', word_class: 'AJ' },
    { word: 'starvation', word_class: 'N' }
  ],
  indeed: [
    { word: 'indeed', word_class: 'N' },
    { word: 'indeed', word_class: 'V' },
    { word: 'indeed', word_class: 'AV' }
  ],
  viewers: [
    { word: 'view', word_class: 'N' },
    { word: 'view', word_class: 'V' },
    { word: 'viewer', word_class: 'N' },
    { word: 'viewers', word_class: 'N' },
    { word: 'viewing', word_class: 'N' }
  ],
  perfumery: [
    { word: 'perfume', word_class: 'N' },
    { word: 'perfume', word_class: 'V' },
    { word: 'perfumer', word_class: 'N' },
    { word: 'perfumed', word_class: 'AJ' },
    { word: 'perfumery', word_class: 'N' },
    { word: 'perfumery', word_class: 'AJ' }
  ],
  sensitively: [
    { word: 'sense', word_class: 'N' },
    { word: 'sense', word_class: 'V' },
    { word: 'sensor', word_class: 'N' },
    { word: 'sensed', word_class: 'AJ' },
    { word: 'sensing', word_class: 'N' },
    { word: 'sensate', word_class: 'AJ' },
    { word: 'sensory', word_class: 'AJ' },
    { word: 'sensuous', word_class: 'AJ' },
    { word: 'sensation', word_class: 'N' },
    { word: 'sensitise', word_class: 'V' },
    { word: 'sensitize', word_class: 'V' },
    { word: 'sensitive', word_class: 'AJ' },
    { word: 'sensitizer', word_class: 'N' },
    { word: 'sensorial', word_class: 'AJ' },
    { word: 'sensitivity', word_class: 'N' },
    { word: 'sensitized', word_class: 'AJ' },
    { word: 'sensitizing', word_class: 'N' },
    { word: 'sensational', word_class: 'AJ' },
    { word: 'sensitively', word_class: 'AV' },
    { word: 'sensitizing', word_class: 'AJ' },
    { word: 'sensitisation', word_class: 'N' },
    { word: 'sensitiveness', word_class: 'N' },
    { word: 'sensitization', word_class: 'N' }
  ],
  unsoundable: [
    { word: 'unsound', word_class: 'AJ' },
    { word: 'unsounded', word_class: 'AJ' },
    { word: 'unsoundness', word_class: 'N' },
    { word: 'unsoundable', word_class: 'AJ' }
  ],
  picturing: [
    { word: 'picture', word_class: 'N' },
    { word: 'picture', word_class: 'V' },
    { word: 'pictural', word_class: 'AJ' },
    { word: 'pictured', word_class: 'AJ' },
    { word: 'picturing', word_class: 'N' }
  ],
  milling: [
    { word: 'mill', word_class: 'N' },
    { word: 'mill', word_class: 'V' },
    { word: 'miller', word_class: 'N' },
    { word: 'milling', word_class: 'N' },
    { word: 'milled', word_class: 'AJ' },
    { word: 'million', word_class: 'N' },
    { word: 'milling', word_class: 'AJ' },
    { word: 'million', word_class: 'AJ' },
    { word: 'millions', word_class: 'N' }
  ],
  bilabiate: [
    { word: 'bilabial', word_class: 'N' },
    { word: 'bilabial', word_class: 'AJ' },
    { word: 'bilabiate', word_class: 'AJ' }
  ],
  pitting: [
    { word: 'pit', word_class: 'N' },
    { word: 'pit', word_class: 'V' },
    { word: 'pitman', word_class: 'N' },
    { word: 'pitted', word_class: 'AJ' },
    { word: 'pitting', word_class: 'N' }
  ],
  beatify: [
    { word: 'beatify', word_class: 'V' },
    { word: 'beatific', word_class: 'AJ' },
    { word: 'beatified', word_class: 'AJ' },
    { word: 'beatification', word_class: 'N' }
  ],
  efface: [
    { word: 'efface', word_class: 'V' },
    { word: 'effacement', word_class: 'N' },
    { word: 'effaceable', word_class: 'AJ' }
  ],
  inflow: [
    { word: 'inflow', word_class: 'N' },
    { word: 'inflow', word_class: 'V' },
    { word: 'inflowing', word_class: 'AJ' }
  ],
  smooch: [
    { word: 'smooch', word_class: 'N' },
    { word: 'smooch', word_class: 'V' },
    { word: 'smooching', word_class: 'N' }
  ],
  reconstructed: [
    { word: 'reconstruct', word_class: 'V' },
    { word: 'reconstructed', word_class: 'AJ' },
    { word: 'reconstruction', word_class: 'N' },
    { word: 'reconstructive', word_class: 'AJ' }
  ],
  secrecy: [
    { word: 'secret', word_class: 'N' },
    { word: 'secrecy', word_class: 'N' },
    { word: 'secret', word_class: 'AJ' },
    { word: 'secrete', word_class: 'V' },
    { word: 'secreting', word_class: 'N' },
    { word: 'secretion', word_class: 'N' },
    { word: 'secretive', word_class: 'AJ' },
    { word: 'secretively', word_class: 'AV' },
    { word: 'secretiveness', word_class: 'N' }
  ],
  affirm: [
    { word: 'affirm', word_class: 'V' },
    { word: 'affirmation', word_class: 'N' },
    { word: 'affirmable', word_class: 'AJ' },
    { word: 'affirmative', word_class: 'N' },
    { word: 'affirmative', word_class: 'AJ' },
    { word: 'affirmativeness', word_class: 'N' }
  ],
  rower: [
    { word: 'row', word_class: 'N' },
    { word: 'row', word_class: 'V' },
    { word: 'rower', word_class: 'N' },
    { word: 'rowing', word_class: 'N' }
  ],
  demoralization: [
    { word: 'demoralise', word_class: 'V' },
    { word: 'demoralize', word_class: 'V' },
    { word: 'demoralized', word_class: 'AJ' },
    { word: 'demoralising', word_class: 'AJ' },
    { word: 'demoralizing', word_class: 'AJ' },
    { word: 'demoralisation', word_class: 'N' },
    { word: 'demoralization', word_class: 'N' }
  ],
  provincial: [
    { word: 'provincial', word_class: 'N' },
    { word: 'provincial', word_class: 'AJ' },
    { word: 'provincialism', word_class: 'N' },
    { word: 'provincially', word_class: 'AV' }
  ],
  recovery: [
    { word: 'recover', word_class: 'N' },
    { word: 'recover', word_class: 'V' },
    { word: 'recovery', word_class: 'N' },
    { word: 'recoverer', word_class: 'N' },
    { word: 'recovery', word_class: 'AJ' },
    { word: 'recovered', word_class: 'AJ' },
    { word: 'recovering', word_class: 'AJ' },
    { word: 'recoverable', word_class: 'AJ' }
  ],
  genetically: [
    { word: 'genet', word_class: 'N' },
    { word: 'genetic', word_class: 'AJ' },
    { word: 'genetics', word_class: 'N' },
    { word: 'genetical', word_class: 'AJ' },
    { word: 'genetically', word_class: 'AV' }
  ],
  gradational: [
    { word: 'grade', word_class: 'N' },
    { word: 'grade', word_class: 'V' },
    { word: 'grader', word_class: 'N' },
    { word: 'grade', word_class: 'AJ' },
    { word: 'gradate', word_class: 'V' },
    { word: 'graded', word_class: 'AJ' },
    { word: 'grading', word_class: 'N' },
    { word: 'gradation', word_class: 'N' },
    { word: 'gradational', word_class: 'AJ' }
  ],
  reflexivity: [
    { word: 'reflex', word_class: 'N' },
    { word: 'reflex', word_class: 'V' },
    { word: 'reflex', word_class: 'AJ' },
    { word: 'reflexive', word_class: 'N' },
    { word: 'reflexive', word_class: 'AJ' },
    { word: 'reflexivity', word_class: 'N' },
    { word: 'reflexiveness', word_class: 'N' }
  ],
  stubbled: [
    { word: 'stubble', word_class: 'N' },
    { word: 'stubbly', word_class: 'AJ' },
    { word: 'stubbled', word_class: 'AJ' }
  ],
  malignancy: [
    { word: 'malign', word_class: 'V' },
    { word: 'malign', word_class: 'AJ' },
    { word: 'maligner', word_class: 'N' },
    { word: 'maligned', word_class: 'AJ' },
    { word: 'malignity', word_class: 'N' },
    { word: 'malignant', word_class: 'AJ' },
    { word: 'malignancy', word_class: 'N' },
    { word: 'malignance', word_class: 'N' },
    { word: 'malignment', word_class: 'N' }
  ],
  pelt: [{ word: 'pelt', word_class: 'N' }, { word: 'pelt', word_class: 'V' }, { word: 'pelting', word_class: 'N' }],
  diversionary: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  abutting: [
    { word: 'abut', word_class: 'V' },
    { word: 'abuttal', word_class: 'N' },
    { word: 'abutter', word_class: 'N' },
    { word: 'abutment', word_class: 'N' },
    { word: 'abutting', word_class: 'AJ' }
  ],
  paying: [
    { word: 'pay', word_class: 'N' },
    { word: 'pay', word_class: 'V' },
    { word: 'payee', word_class: 'N' },
    { word: 'payer', word_class: 'N' },
    { word: 'paying', word_class: 'N' },
    { word: 'paying', word_class: 'AJ' },
    { word: 'payment', word_class: 'N' }
  ],
  swollen: [
    { word: 'swell', word_class: 'N' },
    { word: 'swell', word_class: 'V' },
    { word: 'swell', word_class: 'AJ' },
    { word: 'swelled', word_class: 'AJ' },
    { word: 'swelling', word_class: 'N' },
    { word: 'swollen', word_class: 'AJ' },
    { word: 'swelling', word_class: 'AJ' }
  ],
  cannon: [
    { word: 'cannon', word_class: 'N' },
    { word: 'cannon', word_class: 'V' },
    { word: 'cannoneer', word_class: 'N' }
  ],
  confined: [
    { word: 'confine', word_class: 'V' },
    { word: 'confines', word_class: 'N' },
    { word: 'confined', word_class: 'AJ' },
    { word: 'confining', word_class: 'AJ' },
    { word: 'confinement', word_class: 'N' }
  ],
  maximise: [
    { word: 'maxim', word_class: 'N' },
    { word: 'maximise', word_class: 'V' },
    { word: 'maximize', word_class: 'V' },
    { word: 'maximal', word_class: 'AJ' },
    { word: 'maximally', word_class: 'AV' },
    { word: 'maximation', word_class: 'N' },
    { word: 'maximizing', word_class: 'AJ' },
    { word: 'maximisation', word_class: 'N' },
    { word: 'maximization', word_class: 'N' }
  ],
  successiveness: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  astern: [
    { word: 'astern', word_class: 'AJ' },
    { word: 'astern', word_class: 'AV' },
    { word: 'asternal', word_class: 'AJ' }
  ],
  incompletely: [
    { word: 'incomplete', word_class: 'AJ' },
    { word: 'incompletion', word_class: 'N' },
    { word: 'incompletely', word_class: 'AV' },
    { word: 'incompleteness', word_class: 'N' }
  ],
  reflect: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  handcuff: [
    { word: 'handcuff', word_class: 'N' },
    { word: 'handcuff', word_class: 'V' },
    { word: 'handcuffs', word_class: 'N' }
  ],
  undependableness: [
    { word: 'undependable', word_class: 'AJ' },
    { word: 'undependably', word_class: 'AV' },
    { word: 'undependability', word_class: 'N' },
    { word: 'undependableness', word_class: 'N' }
  ],
  unwiseness: [
    { word: 'unwise', word_class: 'AJ' },
    { word: 'unwisely', word_class: 'AV' },
    { word: 'unwiseness', word_class: 'N' }
  ],
  gnosticism: [
    { word: 'gnostic', word_class: 'N' },
    { word: 'gnostic', word_class: 'AJ' },
    { word: 'gnosticism', word_class: 'N' }
  ],
  apprehension: [
    { word: 'apprehend', word_class: 'V' },
    { word: 'apprehension', word_class: 'N' },
    { word: 'apprehensive', word_class: 'AJ' },
    { word: 'apprehensible', word_class: 'AJ' },
    { word: 'apprehensively', word_class: 'AV' },
    { word: 'apprehensiveness', word_class: 'N' }
  ],
  scrawled: [
    { word: 'scrawl', word_class: 'N' },
    { word: 'scrawl', word_class: 'V' },
    { word: 'scrawled', word_class: 'AJ' }
  ],
  priming: [
    { word: 'prim', word_class: 'V' },
    { word: 'prim', word_class: 'AJ' },
    { word: 'prime', word_class: 'V' },
    { word: 'prime', word_class: 'N' },
    { word: 'prime', word_class: 'AJ' },
    { word: 'primer', word_class: 'N' },
    { word: 'primacy', word_class: 'N' },
    { word: 'primed', word_class: 'AJ' },
    { word: 'priming', word_class: 'N' },
    { word: 'primness', word_class: 'N' }
  ],
  earned: [
    { word: 'earn', word_class: 'V' },
    { word: 'earner', word_class: 'N' },
    { word: 'earned', word_class: 'AJ' },
    { word: 'earning', word_class: 'N' },
    { word: 'earnings', word_class: 'N' }
  ],
  melting: [
    { word: 'melt', word_class: 'N' },
    { word: 'melt', word_class: 'V' },
    { word: 'molten', word_class: 'AJ' },
    { word: 'meltage', word_class: 'N' },
    { word: 'melted', word_class: 'AJ' },
    { word: 'melting', word_class: 'N' },
    { word: 'melting', word_class: 'AJ' }
  ],
  crying: [
    { word: 'cry', word_class: 'N' },
    { word: 'cry', word_class: 'V' },
    { word: 'crier', word_class: 'N' },
    { word: 'crying', word_class: 'N' },
    { word: 'crying', word_class: 'AJ' }
  ],
  successively: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  marketable: [
    { word: 'market', word_class: 'N' },
    { word: 'market', word_class: 'V' },
    { word: 'marketing', word_class: 'N' },
    { word: 'marketable', word_class: 'AJ' }
  ],
  superlative: [
    { word: 'superlative', word_class: 'N' },
    { word: 'superlative', word_class: 'AJ' },
    { word: 'superlatively', word_class: 'AV' }
  ],
  itching: [
    { word: 'itch', word_class: 'N' },
    { word: 'itch', word_class: 'V' },
    { word: 'itchy', word_class: 'AJ' },
    { word: 'itching', word_class: 'N' },
    { word: 'itchiness', word_class: 'N' }
  ],
  amended: [
    { word: 'amend', word_class: 'V' },
    { word: 'amends', word_class: 'N' },
    { word: 'amended', word_class: 'AJ' },
    { word: 'amendment', word_class: 'N' },
    { word: 'amendable', word_class: 'AJ' }
  ],
  pink: [{ word: 'pink', word_class: 'N' }, { word: 'pink', word_class: 'V' }, { word: 'pink', word_class: 'AJ' }],
  inculpate: [
    { word: 'inculpate', word_class: 'V' },
    { word: 'inculpable', word_class: 'AJ' },
    { word: 'inculpation', word_class: 'N' },
    { word: 'inculpative', word_class: 'AJ' },
    { word: 'inculpability', word_class: 'N' },
    { word: 'inculpableness', word_class: 'N' }
  ],
  carposporic: [
    { word: 'carpospore', word_class: 'N' },
    { word: 'carposporic', word_class: 'AJ' },
    { word: 'carposporous', word_class: 'AJ' }
  ],
  visionary: [
    { word: 'vision', word_class: 'N' },
    { word: 'visionary', word_class: 'N' },
    { word: 'visionary', word_class: 'AJ' }
  ],
  stargazer: [
    { word: 'stargaze', word_class: 'V' },
    { word: 'stargazer', word_class: 'N' },
    { word: 'stargazing', word_class: 'N' }
  ],
  push: [
    { word: 'push', word_class: 'N' },
    { word: 'push', word_class: 'V' },
    { word: 'pusher', word_class: 'N' },
    { word: 'pushing', word_class: 'N' },
    { word: 'pushful', word_class: 'AJ' },
    { word: 'pushing', word_class: 'AJ' }
  ],
  remember: [
    { word: 'remember', word_class: 'N' },
    { word: 'remember', word_class: 'V' },
    { word: 'remembered', word_class: 'AJ' },
    { word: 'remembering', word_class: 'N' },
    { word: 'remembrance', word_class: 'N' }
  ],
  monocotyledon: [
    { word: 'monocotyledon', word_class: 'N' },
    { word: 'monocotyledones', word_class: 'N' },
    { word: 'monocotyledonous', word_class: 'AJ' }
  ],
  contact: [
    { word: 'contact', word_class: 'N' },
    { word: 'contact', word_class: 'V' },
    { word: 'contacting', word_class: 'N' }
  ],
  furnishings: [
    { word: 'furnish', word_class: 'V' },
    { word: 'furnished', word_class: 'AJ' },
    { word: 'furnishing', word_class: 'N' },
    { word: 'furnishings', word_class: 'N' }
  ],
  computerisation: [
    { word: 'computerise', word_class: 'V' },
    { word: 'computerize', word_class: 'V' },
    { word: 'computerized', word_class: 'AJ' },
    { word: 'computerisation', word_class: 'N' },
    { word: 'computerization', word_class: 'N' }
  ],
  calorie: [
    { word: 'calory', word_class: 'N' },
    { word: 'calorie', word_class: 'N' },
    { word: 'caloric', word_class: 'AJ' }
  ],
  credible: [
    { word: 'credible', word_class: 'AJ' },
    { word: 'credibly', word_class: 'AV' },
    { word: 'credibility', word_class: 'N' },
    { word: 'credibleness', word_class: 'N' }
  ],
  laxness: [
    { word: 'lax', word_class: 'AJ' },
    { word: 'laxness', word_class: 'N' },
    { word: 'laxative', word_class: 'N' },
    { word: 'laxative', word_class: 'AJ' }
  ],
  preexistent: [
    { word: 'preexist', word_class: 'V' },
    { word: 'preexistence', word_class: 'N' },
    { word: 'preexistent', word_class: 'AJ' }
  ],
  defamation: [
    { word: 'defame', word_class: 'V' },
    { word: 'defamer', word_class: 'N' },
    { word: 'defamation', word_class: 'N' },
    { word: 'defamatory', word_class: 'AJ' }
  ],
  till: [
    { word: 'till', word_class: 'V' },
    { word: 'till', word_class: 'N' },
    { word: 'tiller', word_class: 'N' },
    { word: 'tiller', word_class: 'V' },
    { word: 'tillage', word_class: 'N' },
    { word: 'tilled', word_class: 'AJ' },
    { word: 'tilling', word_class: 'N' }
  ],
  random: [
    { word: 'random', word_class: 'AJ' },
    { word: 'randomise', word_class: 'V' },
    { word: 'randomize', word_class: 'V' },
    { word: 'randomness', word_class: 'N' },
    { word: 'randomized', word_class: 'AJ' },
    { word: 'randomisation', word_class: 'N' },
    { word: 'randomization', word_class: 'N' }
  ],
  convergent: [
    { word: 'converge', word_class: 'V' },
    { word: 'converging', word_class: 'N' },
    { word: 'convergence', word_class: 'N' },
    { word: 'convergency', word_class: 'N' },
    { word: 'convergent', word_class: 'AJ' },
    { word: 'converging', word_class: 'AJ' }
  ],
  using: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  unnoticeableness: [
    { word: 'unnoticeable', word_class: 'AJ' },
    { word: 'unnoticeably', word_class: 'AV' },
    { word: 'unnoticeableness', word_class: 'N' }
  ],
  mastery: [
    { word: 'master', word_class: 'N' },
    { word: 'master', word_class: 'V' },
    { word: 'mastery', word_class: 'N' },
    { word: 'master', word_class: 'AJ' },
    { word: 'mastery', word_class: 'AJ' },
    { word: 'mastered', word_class: 'AJ' },
    { word: 'mastering', word_class: 'N' },
    { word: 'masterful', word_class: 'AJ' },
    { word: 'mastership', word_class: 'N' }
  ],
  traumatisation: [
    { word: 'trauma', word_class: 'N' },
    { word: 'traumatic', word_class: 'AJ' },
    { word: 'traumatise', word_class: 'V' },
    { word: 'traumatize', word_class: 'V' },
    { word: 'traumatisation', word_class: 'N' },
    { word: 'traumatization', word_class: 'N' }
  ],
  aristocratic: [
    { word: 'aristocrat', word_class: 'N' },
    { word: 'aristocracy', word_class: 'N' },
    { word: 'aristocratic', word_class: 'AJ' },
    { word: 'aristocratical', word_class: 'AJ' },
    { word: 'aristocratically', word_class: 'AV' }
  ],
  reinforced: [
    { word: 'reinforce', word_class: 'V' },
    { word: 'reinforcer', word_class: 'N' },
    { word: 'reinforced', word_class: 'AJ' },
    { word: 'reinforcement', word_class: 'N' }
  ],
  live: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  riming: [
    { word: 'rime', word_class: 'N' },
    { word: 'rime', word_class: 'V' },
    { word: 'rimy', word_class: 'AJ' },
    { word: 'rimed', word_class: 'AJ' },
    { word: 'riming', word_class: 'AJ' }
  ],
  desensitized: [
    { word: 'desensitise', word_class: 'V' },
    { word: 'desensitize', word_class: 'V' },
    { word: 'desensitized', word_class: 'AJ' },
    { word: 'desensitizing', word_class: 'AJ' },
    { word: 'desensitisation', word_class: 'N' },
    { word: 'desensitization', word_class: 'N' }
  ],
  serviceability: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  supine: [
    { word: 'supine', word_class: 'AJ' },
    { word: 'supinate', word_class: 'V' },
    { word: 'supinely', word_class: 'AV' },
    { word: 'supination', word_class: 'N' }
  ],
  industriousness: [
    { word: 'industry', word_class: 'N' },
    { word: 'industrial', word_class: 'AJ' },
    { word: 'industrious', word_class: 'AJ' },
    { word: 'industrialise', word_class: 'V' },
    { word: 'industrialize', word_class: 'V' },
    { word: 'industrialist', word_class: 'N' },
    { word: 'industrialism', word_class: 'N' },
    { word: 'industrially', word_class: 'AV' },
    { word: 'industriously', word_class: 'AV' },
    { word: 'industrialized', word_class: 'AJ' },
    { word: 'industriousness', word_class: 'N' },
    { word: 'industrialisation', word_class: 'N' },
    { word: 'industrialization', word_class: 'N' }
  ],
  legitimatise: [
    { word: 'legitimatise', word_class: 'V' },
    { word: 'legitimatize', word_class: 'V' },
    { word: 'legitimisation', word_class: 'N' },
    { word: 'legitimatisation', word_class: 'N' },
    { word: 'legitimatization', word_class: 'N' }
  ],
  obtainment: [
    { word: 'obtain', word_class: 'V' },
    { word: 'obtainment', word_class: 'N' },
    { word: 'obtainable', word_class: 'AJ' }
  ],
  lavish: [
    { word: 'lavish', word_class: 'V' },
    { word: 'lavish', word_class: 'AJ' },
    { word: 'lavishment', word_class: 'N' },
    { word: 'lavishness', word_class: 'N' }
  ],
  intrusion: [
    { word: 'intrude', word_class: 'V' },
    { word: 'intruder', word_class: 'N' },
    { word: 'intrusion', word_class: 'N' },
    { word: 'intruding', word_class: 'AJ' },
    { word: 'intrusive', word_class: 'AJ' },
    { word: 'intrusiveness', word_class: 'N' }
  ],
  occupier: [
    { word: 'occupy', word_class: 'V' },
    { word: 'occupant', word_class: 'N' },
    { word: 'occupier', word_class: 'N' },
    { word: 'occupancy', word_class: 'N' },
    { word: 'occupied', word_class: 'AJ' },
    { word: 'occupying', word_class: 'N' },
    { word: 'occupation', word_class: 'N' },
    { word: 'occupational', word_class: 'AJ' }
  ],
  lethargically: [
    { word: 'lethargy', word_class: 'N' },
    { word: 'lethargic', word_class: 'AJ' },
    { word: 'lethargically', word_class: 'AV' }
  ],
  punctiliously: [
    { word: 'punctilious', word_class: 'AJ' },
    { word: 'punctiliously', word_class: 'AV' },
    { word: 'punctiliousness', word_class: 'N' }
  ],
  infection: [
    { word: 'infect', word_class: 'V' },
    { word: 'infected', word_class: 'AJ' },
    { word: 'infection', word_class: 'N' },
    { word: 'infective', word_class: 'AJ' },
    { word: 'infectious', word_class: 'AJ' },
    { word: 'infectiously', word_class: 'AV' }
  ],
  abridgement: [
    { word: 'abridge', word_class: 'V' },
    { word: 'abridger', word_class: 'N' },
    { word: 'abridged', word_class: 'AJ' },
    { word: 'abridgment', word_class: 'N' },
    { word: 'abridgement', word_class: 'N' }
  ],
  sanctimoniously: [
    { word: 'sanctimony', word_class: 'N' },
    { word: 'sanctimonious', word_class: 'AJ' },
    { word: 'sanctimoniously', word_class: 'AV' },
    { word: 'sanctimoniousness', word_class: 'N' }
  ],
  cancellated: [
    { word: 'cancel', word_class: 'N' },
    { word: 'cancel', word_class: 'V' },
    { word: 'cancelled', word_class: 'AJ' },
    { word: 'cancellate', word_class: 'AJ' },
    { word: 'cancellous', word_class: 'AJ' },
    { word: 'cancellated', word_class: 'AJ' },
    { word: 'cancellation', word_class: 'N' }
  ],
  distributed: [
    { word: 'distribute', word_class: 'N' },
    { word: 'distribute', word_class: 'V' },
    { word: 'distributer', word_class: 'N' },
    { word: 'distributor', word_class: 'N' },
    { word: 'distribution', word_class: 'N' },
    { word: 'distributed', word_class: 'AJ' },
    { word: 'distributive', word_class: 'AJ' },
    { word: 'distributional', word_class: 'AJ' },
    { word: 'distributively', word_class: 'AV' }
  ],
  parasitism: [
    { word: 'parasite', word_class: 'N' },
    { word: 'parasitic', word_class: 'AJ' },
    { word: 'parasitize', word_class: 'V' },
    { word: 'parasitism', word_class: 'N' },
    { word: 'parasitical', word_class: 'AJ' },
    { word: 'parasitically', word_class: 'AV' },
    { word: 'parasitization', word_class: 'N' }
  ],
  outing: [
    { word: 'out', word_class: 'N' },
    { word: 'out', word_class: 'V' },
    { word: 'out', word_class: 'AJ' },
    { word: 'out', word_class: 'AV' },
    { word: 'outing', word_class: 'N' }
  ],
  hiker: [{ word: 'hike', word_class: 'N' }, { word: 'hike', word_class: 'V' }, { word: 'hiker', word_class: 'N' }],
  realist: [
    { word: 'realise', word_class: 'V' },
    { word: 'realist', word_class: 'N' },
    { word: 'realize', word_class: 'V' },
    { word: 'realized', word_class: 'AJ' },
    { word: 'realistic', word_class: 'AJ' },
    { word: 'realisation', word_class: 'N' },
    { word: 'realizable', word_class: 'AJ' },
    { word: 'realization', word_class: 'N' },
    { word: 'realistically', word_class: 'AV' }
  ],
  frequentation: [
    { word: 'frequent', word_class: 'V' },
    { word: 'frequence', word_class: 'N' },
    { word: 'frequency', word_class: 'N' },
    { word: 'frequent', word_class: 'AJ' },
    { word: 'frequenter', word_class: 'N' },
    { word: 'frequently', word_class: 'AV' },
    { word: 'frequentation', word_class: 'N' }
  ],
  handcuffs: [
    { word: 'handcuff', word_class: 'N' },
    { word: 'handcuff', word_class: 'V' },
    { word: 'handcuffs', word_class: 'N' }
  ],
  westerly: [
    { word: 'westerly', word_class: 'N' },
    { word: 'westerly', word_class: 'AJ' },
    { word: 'westerly', word_class: 'AV' }
  ],
  descriptive: [
    { word: 'describe', word_class: 'V' },
    { word: 'described', word_class: 'AJ' },
    { word: 'description', word_class: 'N' },
    { word: 'describable', word_class: 'AJ' },
    { word: 'descriptive', word_class: 'AJ' },
    { word: 'descriptively', word_class: 'AV' }
  ],
  gang: [{ word: 'gang', word_class: 'N' }, { word: 'gang', word_class: 'V' }, { word: 'ganges', word_class: 'N' }],
  ation: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  proofed: [
    { word: 'proof', word_class: 'N' },
    { word: 'proof', word_class: 'V' },
    { word: 'prove', word_class: 'V' },
    { word: 'proof', word_class: 'AJ' },
    { word: 'proven', word_class: 'AJ' },
    { word: 'proved', word_class: 'AJ' },
    { word: 'proving', word_class: 'N' },
    { word: 'proofed', word_class: 'AJ' },
    { word: 'provence', word_class: 'N' },
    { word: 'provencal', word_class: 'N' },
    { word: 'provenance', word_class: 'N' }
  ],
  posing: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  inconsiderately: [
    { word: 'inconsiderate', word_class: 'AJ' },
    { word: 'inconsiderable', word_class: 'AJ' },
    { word: 'inconsideration', word_class: 'N' },
    { word: 'inconsiderately', word_class: 'AV' },
    { word: 'inconsiderateness', word_class: 'N' }
  ],
  frugally: [
    { word: 'frugal', word_class: 'AJ' },
    { word: 'frugality', word_class: 'N' },
    { word: 'frugally', word_class: 'AV' },
    { word: 'frugalness', word_class: 'N' }
  ],
  ransacking: [
    { word: 'ransack', word_class: 'V' },
    { word: 'ransacked', word_class: 'AJ' },
    { word: 'ransacking', word_class: 'N' }
  ],
  originative: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  quadruplication: [
    { word: 'quadruple', word_class: 'N' },
    { word: 'quadruple', word_class: 'V' },
    { word: 'quadruple', word_class: 'AJ' },
    { word: 'quadrupling', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'N' },
    { word: 'quadruplicate', word_class: 'V' },
    { word: 'quadruplicate', word_class: 'AJ' },
    { word: 'quadruplication', word_class: 'N' }
  ],
  ideational: [
    { word: 'idea', word_class: 'N' },
    { word: 'ideate', word_class: 'V' },
    { word: 'ideation', word_class: 'N' },
    { word: 'ideational', word_class: 'AJ' }
  ],
  evil: [{ word: 'evil', word_class: 'N' }, { word: 'evil', word_class: 'AJ' }, { word: 'evilness', word_class: 'N' }],
  seasonally: [
    { word: 'season', word_class: 'N' },
    { word: 'season', word_class: 'V' },
    { word: 'seasoner', word_class: 'N' },
    { word: 'seasoning', word_class: 'N' },
    { word: 'seasonal', word_class: 'AJ' },
    { word: 'seasoned', word_class: 'AJ' },
    { word: 'seasonable', word_class: 'AJ' },
    { word: 'seasonably', word_class: 'AV' },
    { word: 'seasonally', word_class: 'AV' },
    { word: 'seasonableness', word_class: 'N' }
  ],
  contingency: [
    { word: 'contingent', word_class: 'N' },
    { word: 'contingency', word_class: 'N' },
    { word: 'contingent', word_class: 'AJ' }
  ],
  boole: [
    { word: 'boole', word_class: 'N' },
    { word: 'boole', word_class: 'AJ' },
    { word: 'boolean', word_class: 'N' },
    { word: 'boolean', word_class: 'AJ' }
  ],
  ravage: [
    { word: 'ravage', word_class: 'N' },
    { word: 'ravage', word_class: 'V' },
    { word: 'ravaged', word_class: 'AJ' },
    { word: 'ravaging', word_class: 'N' },
    { word: 'ravaging', word_class: 'AJ' },
    { word: 'ravagement', word_class: 'N' }
  ],
  assuming: [
    { word: 'assume', word_class: 'V' },
    { word: 'assumed', word_class: 'AJ' },
    { word: 'assuming', word_class: 'AJ' },
    { word: 'assumption', word_class: 'N' },
    { word: 'assumptive', word_class: 'AJ' }
  ],
  winterization: [
    { word: 'winter', word_class: 'N' },
    { word: 'winter', word_class: 'V' },
    { word: 'winter', word_class: 'AJ' },
    { word: 'wintry', word_class: 'AJ' },
    { word: 'winterize', word_class: 'V' },
    { word: 'winterise', word_class: 'V' },
    { word: 'winterisation', word_class: 'N' },
    { word: 'winterization', word_class: 'N' }
  ],
  marbleize: [
    { word: 'marbleize', word_class: 'V' },
    { word: 'marbleized', word_class: 'AJ' },
    { word: 'marbleizing', word_class: 'N' },
    { word: 'marbleization', word_class: 'N' }
  ],
  bilaterality: [
    { word: 'bilateral', word_class: 'AJ' },
    { word: 'bilateralism', word_class: 'N' },
    { word: 'bilaterality', word_class: 'N' },
    { word: 'bilaterally', word_class: 'AV' }
  ],
  portably: [
    { word: 'portable', word_class: 'N' },
    { word: 'portable', word_class: 'AJ' },
    { word: 'portably', word_class: 'AV' },
    { word: 'portability', word_class: 'N' }
  ],
  singularity: [
    { word: 'singular', word_class: 'N' },
    { word: 'singular', word_class: 'AJ' },
    { word: 'singularise', word_class: 'V' },
    { word: 'singularize', word_class: 'V' },
    { word: 'singularity', word_class: 'N' },
    { word: 'singularisation', word_class: 'N' },
    { word: 'singularization', word_class: 'N' }
  ],
  unanimous: [
    { word: 'unanimity', word_class: 'N' },
    { word: 'unanimous', word_class: 'AJ' },
    { word: 'unanimated', word_class: 'AJ' },
    { word: 'unanimously', word_class: 'AV' }
  ],
  disappointment: [
    { word: 'disappoint', word_class: 'V' },
    { word: 'disappointed', word_class: 'AJ' },
    { word: 'disappointing', word_class: 'N' },
    { word: 'disappointing', word_class: 'AJ' },
    { word: 'disappointment', word_class: 'N' }
  ],
  life: [
    { word: 'live', word_class: 'V' },
    { word: 'life', word_class: 'N' },
    { word: 'live', word_class: 'AJ' },
    { word: 'live', word_class: 'AV' },
    { word: 'liver', word_class: 'N' },
    { word: 'living', word_class: 'N' },
    { word: 'enliven', word_class: 'V' },
    { word: 'lively', word_class: 'AJ' },
    { word: 'lively', word_class: 'AV' },
    { word: 'living', word_class: 'AJ' },
    { word: 'liveness', word_class: 'N' },
    { word: 'lifelike', word_class: 'AJ' },
    { word: 'enlivened', word_class: 'AJ' },
    { word: 'enlivening', word_class: 'AJ' },
    { word: 'enlivenment', word_class: 'N' }
  ],
  detailing: [
    { word: 'detail', word_class: 'N' },
    { word: 'detail', word_class: 'V' },
    { word: 'details', word_class: 'N' },
    { word: 'detailed', word_class: 'AJ' },
    { word: 'detailing', word_class: 'N' }
  ],
  mutually: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  dishevelled: [
    { word: 'dishevel', word_class: 'V' },
    { word: 'disheveled', word_class: 'AJ' },
    { word: 'dishevelled', word_class: 'AJ' },
    { word: 'dishevelment', word_class: 'N' }
  ],
  blighter: [
    { word: 'blight', word_class: 'N' },
    { word: 'blight', word_class: 'V' },
    { word: 'blighter', word_class: 'N' },
    { word: 'blighted', word_class: 'AJ' }
  ],
  felled: [
    { word: 'fell', word_class: 'N' },
    { word: 'fell', word_class: 'V' },
    { word: 'fell', word_class: 'AJ' },
    { word: 'fellate', word_class: 'V' },
    { word: 'felled', word_class: 'AJ' },
    { word: 'fellation', word_class: 'N' }
  ],
  mannerism: [
    { word: 'manner', word_class: 'N' },
    { word: 'manners', word_class: 'N' },
    { word: 'mannered', word_class: 'AJ' },
    { word: 'mannerism', word_class: 'N' }
  ],
  arbitrager: [
    { word: 'arbitrage', word_class: 'N' },
    { word: 'arbitrage', word_class: 'V' },
    { word: 'arbitrager', word_class: 'N' }
  ],
  centre: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  recruitment: [
    { word: 'recruit', word_class: 'N' },
    { word: 'recruit', word_class: 'V' },
    { word: 'recruiter', word_class: 'N' },
    { word: 'recruitment', word_class: 'N' }
  ],
  wax: [
    { word: 'wax', word_class: 'N' },
    { word: 'wax', word_class: 'V' },
    { word: 'waxed', word_class: 'AJ' },
    { word: 'waxen', word_class: 'AJ' },
    { word: 'waxing', word_class: 'N' },
    { word: 'waxing', word_class: 'AJ' }
  ],
  accurately: [
    { word: 'accuracy', word_class: 'N' },
    { word: 'accurate', word_class: 'AJ' },
    { word: 'accurately', word_class: 'AV' }
  ],
  expendable: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  outpour: [
    { word: 'outpour', word_class: 'N' },
    { word: 'outpour', word_class: 'V' },
    { word: 'outpouring', word_class: 'N' }
  ],
  record: [
    { word: 'record', word_class: 'N' },
    { word: 'record', word_class: 'V' },
    { word: 'record', word_class: 'AJ' },
    { word: 'recorder', word_class: 'N' },
    { word: 'recording', word_class: 'N' },
    { word: 'recorded', word_class: 'AJ' },
    { word: 'recordation', word_class: 'N' }
  ],
  trinitarianism: [
    { word: 'trinity', word_class: 'N' },
    { word: 'trinitarian', word_class: 'N' },
    { word: 'trinitarian', word_class: 'AJ' },
    { word: 'trinitarianism', word_class: 'N' }
  ],
  intimation: [
    { word: 'intimacy', word_class: 'N' },
    { word: 'intimate', word_class: 'N' },
    { word: 'intimal', word_class: 'AJ' },
    { word: 'intimate', word_class: 'V' },
    { word: 'intimate', word_class: 'AJ' },
    { word: 'intimation', word_class: 'N' },
    { word: 'intimately', word_class: 'AV' }
  ],
  licentiously: [
    { word: 'licentiate', word_class: 'N' },
    { word: 'licentious', word_class: 'AJ' },
    { word: 'licentiously', word_class: 'AV' },
    { word: 'licentiousness', word_class: 'N' }
  ],
  teenage: [
    { word: 'teenage', word_class: 'AJ' },
    { word: 'teenager', word_class: 'N' },
    { word: 'teenaged', word_class: 'AJ' }
  ],
  reverential: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  libertarian: [
    { word: 'liberty', word_class: 'N' },
    { word: 'libertarian', word_class: 'N' },
    { word: 'libertarian', word_class: 'AJ' },
    { word: 'libertarianism', word_class: 'N' }
  ],
  rush: [
    { word: 'rush', word_class: 'N' },
    { word: 'rush', word_class: 'V' },
    { word: 'rush', word_class: 'AJ' },
    { word: 'rushed', word_class: 'AJ' },
    { word: 'rushing', word_class: 'N' },
    { word: 'rushing', word_class: 'AJ' }
  ],
  locate: [
    { word: 'locate', word_class: 'V' },
    { word: 'locator', word_class: 'N' },
    { word: 'located', word_class: 'AJ' },
    { word: 'locating', word_class: 'N' },
    { word: 'location', word_class: 'N' },
    { word: 'locative', word_class: 'N' },
    { word: 'locative', word_class: 'AJ' }
  ],
  traction: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  pacifically: [
    { word: 'pacify', word_class: 'V' },
    { word: 'pacific', word_class: 'N' },
    { word: 'pacific', word_class: 'AJ' },
    { word: 'pacifier', word_class: 'N' },
    { word: 'pacifist', word_class: 'N' },
    { word: 'pacifism', word_class: 'N' },
    { word: 'pacifist', word_class: 'AJ' },
    { word: 'pacifying', word_class: 'AJ' },
    { word: 'pacifistic', word_class: 'AJ' },
    { word: 'pacifically', word_class: 'AV' },
    { word: 'pacification', word_class: 'N' },
    { word: 'pacifistically', word_class: 'AV' }
  ],
  inhume: [
    { word: 'inhume', word_class: 'V' },
    { word: 'inhumed', word_class: 'AJ' },
    { word: 'inhumation', word_class: 'N' }
  ],
  finances: [
    { word: 'finance', word_class: 'N' },
    { word: 'finance', word_class: 'V' },
    { word: 'financy', word_class: 'AJ' },
    { word: 'finances', word_class: 'N' },
    { word: 'financed', word_class: 'AJ' },
    { word: 'financier', word_class: 'N' },
    { word: 'financing', word_class: 'N' },
    { word: 'financier', word_class: 'V' },
    { word: 'financial', word_class: 'AJ' },
    { word: 'financially', word_class: 'AV' }
  ],
  ejaculator: [
    { word: 'ejaculate', word_class: 'N' },
    { word: 'ejaculate', word_class: 'V' },
    { word: 'ejaculator', word_class: 'N' },
    { word: 'ejaculation', word_class: 'N' }
  ],
  imitation: [
    { word: 'imitate', word_class: 'V' },
    { word: 'imitator', word_class: 'N' },
    { word: 'imitable', word_class: 'AJ' },
    { word: 'imitation', word_class: 'N' },
    { word: 'imitation', word_class: 'AJ' },
    { word: 'imitative', word_class: 'AJ' }
  ],
  sander: [
    { word: 'sand', word_class: 'N' },
    { word: 'sand', word_class: 'V' },
    { word: 'sands', word_class: 'N' },
    { word: 'sandal', word_class: 'N' },
    { word: 'sander', word_class: 'N' },
    { word: 'sandal', word_class: 'AJ' },
    { word: 'sandaled', word_class: 'AJ' },
    { word: 'sandalled', word_class: 'AJ' }
  ],
  infusion: [
    { word: 'infuse', word_class: 'N' },
    { word: 'infuse', word_class: 'V' },
    { word: 'infusion', word_class: 'N' }
  ],
  indignation: [
    { word: 'indignity', word_class: 'N' },
    { word: 'indignant', word_class: 'AJ' },
    { word: 'indignation', word_class: 'N' }
  ],
  permit: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  abstrusely: [
    { word: 'abstruse', word_class: 'AJ' },
    { word: 'abstrusity', word_class: 'N' },
    { word: 'abstrusely', word_class: 'AV' },
    { word: 'abstruseness', word_class: 'N' }
  ],
  admirer: [
    { word: 'admire', word_class: 'V' },
    { word: 'admiral', word_class: 'N' },
    { word: 'admirer', word_class: 'N' },
    { word: 'admired', word_class: 'AJ' },
    { word: 'admiring', word_class: 'AJ' },
    { word: 'admirable', word_class: 'AJ' },
    { word: 'admirably', word_class: 'AV' },
    { word: 'admiration', word_class: 'N' },
    { word: 'admirability', word_class: 'N' },
    { word: 'admirableness', word_class: 'N' }
  ],
  informed: [
    { word: 'inform', word_class: 'N' },
    { word: 'inform', word_class: 'V' },
    { word: 'informer', word_class: 'N' },
    { word: 'informant', word_class: 'N' },
    { word: 'informal', word_class: 'AJ' },
    { word: 'informed', word_class: 'AJ' },
    { word: 'informing', word_class: 'N' },
    { word: 'informality', word_class: 'N' },
    { word: 'informally', word_class: 'AV' },
    { word: 'information', word_class: 'N' },
    { word: 'informative', word_class: 'AJ' },
    { word: 'informatively', word_class: 'AV' }
  ],
  abhorrent: [
    { word: 'abhor', word_class: 'V' },
    { word: 'abhorrer', word_class: 'N' },
    { word: 'abhorrence', word_class: 'N' },
    { word: 'abhorrent', word_class: 'AJ' }
  ],
  'co-optation': [
    { word: 'coopt', word_class: 'V' },
    { word: 'co-opt', word_class: 'V' },
    { word: 'co-option', word_class: 'N' },
    { word: 'co-optation', word_class: 'N' }
  ],
  contingent: [
    { word: 'contingent', word_class: 'N' },
    { word: 'contingency', word_class: 'N' },
    { word: 'contingent', word_class: 'AJ' }
  ],
  stolen: [
    { word: 'steal', word_class: 'N' },
    { word: 'steal', word_class: 'V' },
    { word: 'stolen', word_class: 'AJ' },
    { word: 'stealing', word_class: 'N' }
  ],
  prurience: [
    { word: 'prurience', word_class: 'N' },
    { word: 'pruriency', word_class: 'N' },
    { word: 'prurient', word_class: 'AJ' },
    { word: 'pruriently', word_class: 'AV' }
  ],
  chanceful: [
    { word: 'chance', word_class: 'N' },
    { word: 'chance', word_class: 'V' },
    { word: 'chance', word_class: 'AJ' },
    { word: 'chancy', word_class: 'AJ' },
    { word: 'chanceful', word_class: 'AJ' }
  ],
  vaporization: [
    { word: 'vapor', word_class: 'N' },
    { word: 'vapor', word_class: 'V' },
    { word: 'vaporing', word_class: 'N' },
    { word: 'vaporise', word_class: 'V' },
    { word: 'vaporize', word_class: 'V' },
    { word: 'vaporous', word_class: 'AJ' },
    { word: 'vaporized', word_class: 'AJ' },
    { word: 'vaporisation', word_class: 'N' },
    { word: 'vaporization', word_class: 'N' }
  ],
  pilgrimage: [
    { word: 'pilgrim', word_class: 'N' },
    { word: 'pilgrimage', word_class: 'N' },
    { word: 'pilgrimage', word_class: 'V' }
  ],
  ease: [
    { word: 'ease', word_class: 'N' },
    { word: 'ease', word_class: 'V' },
    { word: 'eased', word_class: 'AJ' },
    { word: 'easing', word_class: 'N' },
    { word: 'easement', word_class: 'N' }
  ],
  perpetual: [
    { word: 'perpetual', word_class: 'AJ' },
    { word: 'perpetuate', word_class: 'V' },
    { word: 'perpetuity', word_class: 'N' },
    { word: 'perpetually', word_class: 'AV' },
    { word: 'perpetuation', word_class: 'N' }
  ],
  pinnate: [
    { word: 'pinnate', word_class: 'AJ' },
    { word: 'pinnated', word_class: 'AJ' },
    { word: 'pinnately', word_class: 'AV' }
  ],
  numbness: [
    { word: 'numb', word_class: 'V' },
    { word: 'numb', word_class: 'AJ' },
    { word: 'numbing', word_class: 'AJ' },
    { word: 'numbness', word_class: 'N' }
  ],
  assuring: [
    { word: 'assur', word_class: 'N' },
    { word: 'assure', word_class: 'V' },
    { word: 'assured', word_class: 'AJ' },
    { word: 'assurance', word_class: 'N' },
    { word: 'assuring', word_class: 'AJ' }
  ],
  occidentalise: [
    { word: 'occident', word_class: 'N' },
    { word: 'occidental', word_class: 'N' },
    { word: 'occidental', word_class: 'AJ' },
    { word: 'occidentalise', word_class: 'V' },
    { word: 'occidentalize', word_class: 'V' },
    { word: 'occidentalisation', word_class: 'N' },
    { word: 'occidentalization', word_class: 'N' }
  ],
  cognitive: [
    { word: 'cognition', word_class: 'N' },
    { word: 'cognitive', word_class: 'AJ' },
    { word: 'cognitively', word_class: 'AV' }
  ],
  enlighten: [
    { word: 'light', word_class: 'N' },
    { word: 'light', word_class: 'V' },
    { word: 'light', word_class: 'AV' },
    { word: 'light', word_class: 'AJ' },
    { word: 'lighter', word_class: 'N' },
    { word: 'lighter', word_class: 'V' },
    { word: 'lighted', word_class: 'AJ' },
    { word: 'lighting', word_class: 'N' },
    { word: 'enlighten', word_class: 'V' },
    { word: 'lightness', word_class: 'N' },
    { word: 'lightship', word_class: 'N' },
    { word: 'enlightened', word_class: 'N' },
    { word: 'enlightened', word_class: 'AJ' },
    { word: 'enlightening', word_class: 'AJ' },
    { word: 'enlightenment', word_class: 'N' }
  ],
  audaciously: [
    { word: 'audacious', word_class: 'AJ' },
    { word: 'audaciously', word_class: 'AV' },
    { word: 'audaciousness', word_class: 'N' }
  ],
  moulding: [
    { word: 'mould', word_class: 'N' },
    { word: 'mould', word_class: 'V' },
    { word: 'moult', word_class: 'N' },
    { word: 'moult', word_class: 'V' },
    { word: 'moulding', word_class: 'N' },
    { word: 'moulting', word_class: 'N' }
  ],
  ruffian: [
    { word: 'ruffian', word_class: 'N' },
    { word: 'ruffian', word_class: 'AJ' },
    { word: 'ruffianism', word_class: 'N' }
  ],
  heroics: [
    { word: 'heroic', word_class: 'AJ' },
    { word: 'heroics', word_class: 'N' },
    { word: 'heroical', word_class: 'AJ' },
    { word: 'heroically', word_class: 'AV' }
  ],
  diminutive: [
    { word: 'diminish', word_class: 'V' },
    { word: 'diminish', word_class: 'AJ' },
    { word: 'diminution', word_class: 'N' },
    { word: 'diminutive', word_class: 'N' },
    { word: 'diminutive', word_class: 'AJ' },
    { word: 'diminished', word_class: 'AJ' },
    { word: 'diminishing', word_class: 'AJ' },
    { word: 'diminishment', word_class: 'N' },
    { word: 'diminutiveness', word_class: 'N' }
  ],
  overlay: [
    { word: 'overlay', word_class: 'N' },
    { word: 'overlay', word_class: 'V' },
    { word: 'overlayer', word_class: 'N' }
  ],
  confident: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  satirise: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  sweetener: [
    { word: 'sweeten', word_class: 'V' },
    { word: 'sweetener', word_class: 'N' },
    { word: 'sweetened', word_class: 'AJ' },
    { word: 'sweetening', word_class: 'N' }
  ],
  immaculately: [
    { word: 'immaculate', word_class: 'AJ' },
    { word: 'immaculately', word_class: 'AV' },
    { word: 'immaculateness', word_class: 'N' }
  ],
  liquefied: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  accompanist: [
    { word: 'accompany', word_class: 'V' },
    { word: 'accompanist', word_class: 'N' },
    { word: 'accompanied', word_class: 'AJ' },
    { word: 'accompanyist', word_class: 'N' },
    { word: 'accompaniment', word_class: 'N' },
    { word: 'accompanying', word_class: 'AJ' }
  ],
  contemplativeness: [
    { word: 'contemplate', word_class: 'V' },
    { word: 'contemplation', word_class: 'N' },
    { word: 'contemplative', word_class: 'AJ' },
    { word: 'contemplativeness', word_class: 'N' }
  ],
  undertaking: [
    { word: 'undertake', word_class: 'V' },
    { word: 'undertaker', word_class: 'N' },
    { word: 'undertaking', word_class: 'N' }
  ],
  former: [
    { word: 'form', word_class: 'N' },
    { word: 'form', word_class: 'V' },
    { word: 'format', word_class: 'N' },
    { word: 'former', word_class: 'N' },
    { word: 'format', word_class: 'V' },
    { word: 'formed', word_class: 'AJ' },
    { word: 'former', word_class: 'AJ' },
    { word: 'formation', word_class: 'N' },
    { word: 'formative', word_class: 'N' },
    { word: 'formative', word_class: 'AJ' },
    { word: 'formatting', word_class: 'N' }
  ],
  meritoriously: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  detainee: [
    { word: 'detain', word_class: 'V' },
    { word: 'detent', word_class: 'N' },
    { word: 'detente', word_class: 'N' },
    { word: 'detainee', word_class: 'N' },
    { word: 'detention', word_class: 'N' }
  ],
  artful: [
    { word: 'art', word_class: 'N' },
    { word: 'arts', word_class: 'N' },
    { word: 'artful', word_class: 'AJ' },
    { word: 'artistry', word_class: 'N' },
    { word: 'artfulness', word_class: 'N' }
  ],
  habitable: [
    { word: 'habit', word_class: 'N' },
    { word: 'habit', word_class: 'V' },
    { word: 'habitue', word_class: 'N' },
    { word: 'habited', word_class: 'AJ' },
    { word: 'habituate', word_class: 'V' },
    { word: 'habitual', word_class: 'AJ' },
    { word: 'habitable', word_class: 'AJ' },
    { word: 'habitably', word_class: 'AV' },
    { word: 'habitation', word_class: 'N' },
    { word: 'habitually', word_class: 'AV' },
    { word: 'habituation', word_class: 'N' },
    { word: 'habitability', word_class: 'N' }
  ],
  goad: [
    { word: 'goad', word_class: 'N' },
    { word: 'goad', word_class: 'V' },
    { word: 'goaded', word_class: 'AJ' },
    { word: 'goading', word_class: 'N' }
  ],
  effectuation: [
    { word: 'effectual', word_class: 'AJ' },
    { word: 'effectuate', word_class: 'V' },
    { word: 'effectuality', word_class: 'N' },
    { word: 'effectually', word_class: 'AV' },
    { word: 'effectuation', word_class: 'N' },
    { word: 'effectualness', word_class: 'N' }
  ],
  synchronism: [
    { word: 'synchrony', word_class: 'N' },
    { word: 'synchronal', word_class: 'AJ' },
    { word: 'synchronic', word_class: 'AJ' },
    { word: 'synchronise', word_class: 'V' },
    { word: 'synchronism', word_class: 'N' },
    { word: 'synchronize', word_class: 'V' },
    { word: 'synchronous', word_class: 'AJ' },
    { word: 'synchroneity', word_class: 'N' },
    { word: 'synchronized', word_class: 'AJ' },
    { word: 'synchronicity', word_class: 'N' },
    { word: 'synchronising', word_class: 'N' },
    { word: 'synchronizing', word_class: 'N' },
    { word: 'synchronously', word_class: 'AV' },
    { word: 'synchronisation', word_class: 'N' },
    { word: 'synchronization', word_class: 'N' }
  ],
  imperceptibility: [
    { word: 'imperceptible', word_class: 'AJ' },
    { word: 'imperceptibly', word_class: 'AV' },
    { word: 'imperceptibility', word_class: 'N' }
  ],
  thermionic: [
    { word: 'thermion', word_class: 'N' },
    { word: 'thermionic', word_class: 'AJ' },
    { word: 'thermionics', word_class: 'N' }
  ],
  sapidness: [
    { word: 'sapid', word_class: 'AJ' },
    { word: 'sapidity', word_class: 'N' },
    { word: 'sapidness', word_class: 'N' }
  ],
  bended: [
    { word: 'bend', word_class: 'N' },
    { word: 'bend', word_class: 'V' },
    { word: 'bends', word_class: 'N' },
    { word: 'bender', word_class: 'N' },
    { word: 'bended', word_class: 'AJ' },
    { word: 'bending', word_class: 'N' },
    { word: 'bending', word_class: 'AJ' }
  ],
  competency: [
    { word: 'compete', word_class: 'V' },
    { word: 'competence', word_class: 'N' },
    { word: 'competency', word_class: 'N' },
    { word: 'competent', word_class: 'AJ' },
    { word: 'competing', word_class: 'AJ' },
    { word: 'competition', word_class: 'N' },
    { word: 'competently', word_class: 'AV' },
    { word: 'competitive', word_class: 'AJ' },
    { word: 'competitively', word_class: 'AV' },
    { word: 'competitiveness', word_class: 'N' }
  ],
  lumber: [
    { word: 'lumber', word_class: 'N' },
    { word: 'lumber', word_class: 'V' },
    { word: 'lumberman', word_class: 'N' },
    { word: 'lumbering', word_class: 'AJ' }
  ],
  rallying: [
    { word: 'rally', word_class: 'N' },
    { word: 'rally', word_class: 'V' },
    { word: 'rallying', word_class: 'N' },
    { word: 'rallying', word_class: 'AJ' }
  ],
  tyrannise: [
    { word: 'tyranni', word_class: 'N' },
    { word: 'tyranny', word_class: 'N' },
    { word: 'tyrannic', word_class: 'AJ' },
    { word: 'tyrannise', word_class: 'V' },
    { word: 'tyrannize', word_class: 'V' },
    { word: 'tyrannous', word_class: 'AJ' },
    { word: 'tyrannical', word_class: 'AJ' }
  ],
  federated: [
    { word: 'federal', word_class: 'N' },
    { word: 'federal', word_class: 'AJ' },
    { word: 'federate', word_class: 'V' },
    { word: 'federate', word_class: 'AJ' },
    { word: 'federalise', word_class: 'V' },
    { word: 'federalize', word_class: 'V' },
    { word: 'federalism', word_class: 'N' },
    { word: 'federation', word_class: 'N' },
    { word: 'federated', word_class: 'AJ' },
    { word: 'federally', word_class: 'AV' },
    { word: 'federalisation', word_class: 'N' },
    { word: 'federalization', word_class: 'N' }
  ],
  mineralized: [
    { word: 'mineral', word_class: 'N' },
    { word: 'mineral', word_class: 'AJ' },
    { word: 'mineralise', word_class: 'V' },
    { word: 'mineralize', word_class: 'V' },
    { word: 'mineralized', word_class: 'AJ' },
    { word: 'mineralisation', word_class: 'N' },
    { word: 'mineralization', word_class: 'N' }
  ],
  visibility: [
    { word: 'visible', word_class: 'AJ' },
    { word: 'visibly', word_class: 'AV' },
    { word: 'visibility', word_class: 'N' },
    { word: 'visibleness', word_class: 'N' }
  ],
  inactive: [
    { word: 'inaction', word_class: 'N' },
    { word: 'inactive', word_class: 'AJ' },
    { word: 'inactivity', word_class: 'N' },
    { word: 'inactiveness', word_class: 'N' }
  ],
  conservator: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  opaque: [
    { word: 'opacity', word_class: 'N' },
    { word: 'opaque', word_class: 'AJ' },
    { word: 'opaquely', word_class: 'AV' },
    { word: 'opaqueness', word_class: 'N' }
  ],
  implacate: [
    { word: 'implacate', word_class: 'V' },
    { word: 'implacable', word_class: 'AJ' },
    { word: 'implacably', word_class: 'AV' }
  ],
  temporization: [
    { word: 'temporise', word_class: 'V' },
    { word: 'temporize', word_class: 'V' },
    { word: 'temporal', word_class: 'AJ' },
    { word: 'temporality', word_class: 'N' },
    { word: 'temporally', word_class: 'AV' },
    { word: 'temporisation', word_class: 'N' },
    { word: 'temporization', word_class: 'N' }
  ],
  usurper: [
    { word: 'usurp', word_class: 'V' },
    { word: 'usurper', word_class: 'N' },
    { word: 'usurped', word_class: 'AJ' },
    { word: 'usurpation', word_class: 'N' }
  ],
  declarator: [
    { word: 'declare', word_class: 'V' },
    { word: 'declared', word_class: 'AJ' },
    { word: 'declarator', word_class: 'N' },
    { word: 'declarable', word_class: 'AJ' },
    { word: 'declaration', word_class: 'N' },
    { word: 'declarative', word_class: 'N' },
    { word: 'declarative', word_class: 'AJ' },
    { word: 'declaratory', word_class: 'AJ' }
  ],
  interactive: [
    { word: 'interact', word_class: 'V' },
    { word: 'interaction', word_class: 'N' },
    { word: 'interactive', word_class: 'AJ' },
    { word: 'interactional', word_class: 'AJ' }
  ],
  demonstrated: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  vulcan: [
    { word: 'vulcan', word_class: 'N' },
    { word: 'vulcanise', word_class: 'V' },
    { word: 'vulcanize', word_class: 'V' },
    { word: 'vulcanized', word_class: 'AJ' },
    { word: 'vulcanisation', word_class: 'N' },
    { word: 'vulcanization', word_class: 'N' }
  ],
  flashing: [
    { word: 'flash', word_class: 'N' },
    { word: 'flash', word_class: 'V' },
    { word: 'flash', word_class: 'AJ' },
    { word: 'flasher', word_class: 'N' },
    { word: 'flashing', word_class: 'N' },
    { word: 'flashing', word_class: 'AJ' }
  ],
  materially: [
    { word: 'material', word_class: 'N' },
    { word: 'material', word_class: 'AJ' },
    { word: 'materialism', word_class: 'N' },
    { word: 'materialize', word_class: 'V' },
    { word: 'materialist', word_class: 'N' },
    { word: 'materiality', word_class: 'N' },
    { word: 'materially', word_class: 'AV' },
    { word: 'materialistic', word_class: 'AJ' },
    { word: 'materialization', word_class: 'N' },
    { word: 'materialistically', word_class: 'AV' }
  ],
  bump: [
    { word: 'bump', word_class: 'N' },
    { word: 'bump', word_class: 'V' },
    { word: 'bumper', word_class: 'N' },
    { word: 'bumper', word_class: 'AJ' }
  ],
  heart: [
    { word: 'heart', word_class: 'N' },
    { word: 'hearts', word_class: 'N' },
    { word: 'hearted', word_class: 'AJ' }
  ],
  occasionally: [
    { word: 'occasion', word_class: 'N' },
    { word: 'occasion', word_class: 'V' },
    { word: 'occasions', word_class: 'N' },
    { word: 'occasional', word_class: 'AJ' },
    { word: 'occasionment', word_class: 'N' },
    { word: 'occasionally', word_class: 'AV' }
  ],
  idealism: [
    { word: 'ideal', word_class: 'N' },
    { word: 'ideal', word_class: 'AJ' },
    { word: 'idealise', word_class: 'V' },
    { word: 'idealist', word_class: 'N' },
    { word: 'idealize', word_class: 'V' },
    { word: 'idealism', word_class: 'N' },
    { word: 'ideally', word_class: 'AV' },
    { word: 'idealized', word_class: 'AJ' },
    { word: 'idealistic', word_class: 'AJ' },
    { word: 'idealisation', word_class: 'N' },
    { word: 'idealization', word_class: 'N' }
  ],
  depress: [
    { word: 'depress', word_class: 'N' },
    { word: 'depress', word_class: 'V' },
    { word: 'depressor', word_class: 'N' },
    { word: 'depressant', word_class: 'N' },
    { word: 'depressed', word_class: 'AJ' },
    { word: 'depression', word_class: 'N' },
    { word: 'depressive', word_class: 'N' },
    { word: 'depressant', word_class: 'AJ' },
    { word: 'depressing', word_class: 'AJ' },
    { word: 'depressive', word_class: 'AJ' }
  ],
  duds: [{ word: 'dud', word_class: 'N' }, { word: 'dud', word_class: 'AJ' }, { word: 'duds', word_class: 'N' }],
  inheritable: [
    { word: 'inherit', word_class: 'V' },
    { word: 'inheritor', word_class: 'N' },
    { word: 'inherited', word_class: 'AJ' },
    { word: 'inheritance', word_class: 'N' },
    { word: 'inheriting', word_class: 'AJ' },
    { word: 'inheritable', word_class: 'AJ' }
  ],
  heterosexuality: [
    { word: 'heterosexual', word_class: 'N' },
    { word: 'heterosexual', word_class: 'AJ' },
    { word: 'heterosexualism', word_class: 'N' },
    { word: 'heterosexuality', word_class: 'N' }
  ],
  fatalist: [
    { word: 'fatalist', word_class: 'N' },
    { word: 'fatalist', word_class: 'AJ' },
    { word: 'fatalistic', word_class: 'AJ' }
  ],
  minimized: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  teased: [
    { word: 'tease', word_class: 'N' },
    { word: 'tease', word_class: 'V' },
    { word: 'teaser', word_class: 'N' },
    { word: 'teased', word_class: 'AJ' },
    { word: 'teasing', word_class: 'N' },
    { word: 'teasing', word_class: 'AJ' }
  ],
  retrogressive: [
    { word: 'retrogress', word_class: 'V' },
    { word: 'retrogression', word_class: 'N' },
    { word: 'retrogressive', word_class: 'AJ' }
  ],
  decor: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  algae: [{ word: 'alga', word_class: 'N' }, { word: 'algae', word_class: 'N' }, { word: 'algal', word_class: 'AJ' }],
  carbonous: [
    { word: 'carbon', word_class: 'N' },
    { word: 'carbonate', word_class: 'V' },
    { word: 'carbonic', word_class: 'AJ' },
    { word: 'carbonate', word_class: 'N' },
    { word: 'carbonize', word_class: 'V' },
    { word: 'carbonous', word_class: 'AJ' },
    { word: 'carbonated', word_class: 'AJ' },
    { word: 'carbonation', word_class: 'N' },
    { word: 'carbonization', word_class: 'N' }
  ],
  expell: [
    { word: 'expel', word_class: 'V' },
    { word: 'expell', word_class: 'V' },
    { word: 'expelling', word_class: 'N' },
    { word: 'expulsion', word_class: 'N' }
  ],
  delineation: [
    { word: 'delineate', word_class: 'V' },
    { word: 'delineate', word_class: 'AJ' },
    { word: 'delineated', word_class: 'AJ' },
    { word: 'delineation', word_class: 'N' },
    { word: 'delineative', word_class: 'AJ' }
  ],
  profligate: [
    { word: 'profligacy', word_class: 'N' },
    { word: 'profligate', word_class: 'N' },
    { word: 'profligate', word_class: 'AJ' },
    { word: 'profligately', word_class: 'AV' }
  ],
  fan: [
    { word: 'fan', word_class: 'N' },
    { word: 'fan', word_class: 'V' },
    { word: 'fanion', word_class: 'N' },
    { word: 'fanned', word_class: 'AJ' }
  ],
  bogeyman: [
    { word: 'bogey', word_class: 'N' },
    { word: 'bogey', word_class: 'V' },
    { word: 'bogeyman', word_class: 'N' }
  ],
  unthinkably: [
    { word: 'unthinking', word_class: 'AJ' },
    { word: 'unthinking', word_class: 'AV' },
    { word: 'unthinkable', word_class: 'AJ' },
    { word: 'unthinkably', word_class: 'AV' }
  ],
  muttering: [
    { word: 'mutter', word_class: 'N' },
    { word: 'mutter', word_class: 'V' },
    { word: 'mutterer', word_class: 'N' },
    { word: 'muttering', word_class: 'N' },
    { word: 'muttering', word_class: 'AJ' }
  ],
  plant: [
    { word: 'plant', word_class: 'N' },
    { word: 'plant', word_class: 'V' },
    { word: 'planter', word_class: 'N' },
    { word: 'planted', word_class: 'AJ' },
    { word: 'planting', word_class: 'N' },
    { word: 'plantation', word_class: 'N' }
  ],
  sprucely: [
    { word: 'spruce', word_class: 'N' },
    { word: 'spruce', word_class: 'V' },
    { word: 'spruce', word_class: 'AJ' },
    { word: 'sprucely', word_class: 'AV' },
    { word: 'spruceness', word_class: 'N' }
  ],
  satisfy: [
    { word: 'satisfy', word_class: 'V' },
    { word: 'satisfied', word_class: 'AJ' },
    { word: 'satisfying', word_class: 'N' },
    { word: 'satisfying', word_class: 'AJ' },
    { word: 'satisfaction', word_class: 'N' },
    { word: 'satisfiable', word_class: 'AJ' }
  ],
  sagely: [{ word: 'sage', word_class: 'N' }, { word: 'sage', word_class: 'AJ' }, { word: 'sagely', word_class: 'AV' }],
  operability: [
    { word: 'operate', word_class: 'V' },
    { word: 'operant', word_class: 'AJ' },
    { word: 'operator', word_class: 'N' },
    { word: 'operable', word_class: 'AJ' },
    { word: 'operably', word_class: 'AV' },
    { word: 'operation', word_class: 'N' },
    { word: 'operative', word_class: 'N' },
    { word: 'operating', word_class: 'AJ' },
    { word: 'operations', word_class: 'N' },
    { word: 'operative', word_class: 'AJ' },
    { word: 'operability', word_class: 'N' },
    { word: 'operability', word_class: 'AJ' },
    { word: 'operational', word_class: 'AJ' },
    { word: 'operatively', word_class: 'AV' }
  ],
  premeditation: [
    { word: 'premeditate', word_class: 'V' },
    { word: 'premeditated', word_class: 'AJ' },
    { word: 'premeditation', word_class: 'N' }
  ],
  heckler: [
    { word: 'heckle', word_class: 'V' },
    { word: 'heckler', word_class: 'N' },
    { word: 'heckling', word_class: 'N' }
  ],
  viewer: [
    { word: 'view', word_class: 'N' },
    { word: 'view', word_class: 'V' },
    { word: 'viewer', word_class: 'N' },
    { word: 'viewers', word_class: 'N' },
    { word: 'viewing', word_class: 'N' }
  ],
  alterability: [
    { word: 'alter', word_class: 'V' },
    { word: 'altered', word_class: 'AJ' },
    { word: 'altering', word_class: 'N' },
    { word: 'alterable', word_class: 'AJ' },
    { word: 'alteration', word_class: 'N' },
    { word: 'alterative', word_class: 'AJ' },
    { word: 'alterability', word_class: 'N' }
  ],
  shag: [
    { word: 'shag', word_class: 'N' },
    { word: 'shag', word_class: 'V' },
    { word: 'shagged', word_class: 'AJ' },
    { word: 'shagging', word_class: 'N' }
  ],
  parenthetical: [
    { word: 'parenthetic', word_class: 'AJ' },
    { word: 'parenthetical', word_class: 'AJ' },
    { word: 'parenthetically', word_class: 'AV' }
  ],
  vociferate: [
    { word: 'vociferate', word_class: 'V' },
    { word: 'vociferous', word_class: 'AJ' },
    { word: 'vociferation', word_class: 'N' },
    { word: 'vociferously', word_class: 'AV' }
  ],
  deflator: [
    { word: 'deflate', word_class: 'V' },
    { word: 'deflator', word_class: 'N' },
    { word: 'deflated', word_class: 'AJ' },
    { word: 'deflation', word_class: 'N' },
    { word: 'deflationary', word_class: 'AJ' }
  ],
  reproval: [
    { word: 'reprove', word_class: 'V' },
    { word: 'reproval', word_class: 'N' },
    { word: 'reproved', word_class: 'AJ' },
    { word: 'reproving', word_class: 'AJ' }
  ],
  surfboarding: [
    { word: 'surfboard', word_class: 'N' },
    { word: 'surfboarder', word_class: 'N' },
    { word: 'surfboarding', word_class: 'N' }
  ],
  particularize: [
    { word: 'particular', word_class: 'N' },
    { word: 'particular', word_class: 'AJ' },
    { word: 'particulars', word_class: 'N' },
    { word: 'particularise', word_class: 'V' },
    { word: 'particularize', word_class: 'V' },
    { word: 'particularity', word_class: 'N' },
    { word: 'particularism', word_class: 'N' },
    { word: 'particularised', word_class: 'AJ' },
    { word: 'particularized', word_class: 'AJ' },
    { word: 'particularisation', word_class: 'N' },
    { word: 'particularization', word_class: 'N' }
  ],
  successful: [
    { word: 'succeed', word_class: 'V' },
    { word: 'success', word_class: 'N' },
    { word: 'successor', word_class: 'N' },
    { word: 'succession', word_class: 'N' },
    { word: 'successive', word_class: 'AJ' },
    { word: 'successful', word_class: 'AJ' },
    { word: 'successively', word_class: 'AV' },
    { word: 'successfulness', word_class: 'N' },
    { word: 'successiveness', word_class: 'N' }
  ],
  distressed: [
    { word: 'distress', word_class: 'N' },
    { word: 'distress', word_class: 'V' },
    { word: 'distressed', word_class: 'AJ' },
    { word: 'distressful', word_class: 'AJ' },
    { word: 'distressing', word_class: 'AJ' },
    { word: 'distressfulness', word_class: 'N' }
  ],
  spouting: [
    { word: 'spout', word_class: 'N' },
    { word: 'spout', word_class: 'V' },
    { word: 'spouting', word_class: 'AJ' }
  ],
  alchemistical: [
    { word: 'alchemist', word_class: 'N' },
    { word: 'alchemistic', word_class: 'AJ' },
    { word: 'alchemistical', word_class: 'AJ' }
  ],
  digress: [
    { word: 'digress', word_class: 'V' },
    { word: 'digression', word_class: 'N' },
    { word: 'digressive', word_class: 'AJ' }
  ],
  suckled: [
    { word: 'suckle', word_class: 'V' },
    { word: 'suckled', word_class: 'AJ' },
    { word: 'suckling', word_class: 'N' }
  ],
  mountebankery: [
    { word: 'mountebank', word_class: 'N' },
    { word: 'mountebank', word_class: 'V' },
    { word: 'mountebankery', word_class: 'N' }
  ],
  misplaced: [
    { word: 'misplace', word_class: 'V' },
    { word: 'misplaced', word_class: 'AJ' },
    { word: 'misplacement', word_class: 'N' }
  ],
  emigre: [
    { word: 'emigre', word_class: 'N' },
    { word: 'emigrant', word_class: 'N' },
    { word: 'emigrate', word_class: 'V' },
    { word: 'emigration', word_class: 'N' }
  ],
  refitment: [
    { word: 'refit', word_class: 'N' },
    { word: 'refit', word_class: 'V' },
    { word: 'refit', word_class: 'AJ' },
    { word: 'refitment', word_class: 'N' }
  ],
  obtusely: [
    { word: 'obtuse', word_class: 'AJ' },
    { word: 'obtusely', word_class: 'AV' },
    { word: 'obtuseness', word_class: 'N' }
  ],
  conjugated: [
    { word: 'conjugal', word_class: 'AJ' },
    { word: 'conjugate', word_class: 'N' },
    { word: 'conjugate', word_class: 'V' },
    { word: 'conjugate', word_class: 'AJ' },
    { word: 'conjugally', word_class: 'AV' },
    { word: 'conjugated', word_class: 'AJ' },
    { word: 'conjugation', word_class: 'N' }
  ],
  licensee: [
    { word: 'license', word_class: 'N' },
    { word: 'license', word_class: 'V' },
    { word: 'licensee', word_class: 'N' },
    { word: 'licensed', word_class: 'AJ' }
  ],
  dilapidate: [
    { word: 'dilapidate', word_class: 'V' },
    { word: 'dilapidated', word_class: 'AJ' },
    { word: 'dilapidation', word_class: 'N' }
  ],
  inherence: [
    { word: 'inhere', word_class: 'V' },
    { word: 'inhere', word_class: 'AV' },
    { word: 'inherence', word_class: 'N' },
    { word: 'inherent', word_class: 'AJ' },
    { word: 'inherently', word_class: 'AV' }
  ],
  lowbrowed: [
    { word: 'lowbrow', word_class: 'N' },
    { word: 'lowbrow', word_class: 'AJ' },
    { word: 'lowbrowed', word_class: 'AJ' }
  ],
  interception: [
    { word: 'intercept', word_class: 'N' },
    { word: 'intercept', word_class: 'V' },
    { word: 'interceptor', word_class: 'N' },
    { word: 'interception', word_class: 'N' }
  ],
  unlive: [
    { word: 'unlive', word_class: 'V' },
    { word: 'unlivable', word_class: 'AJ' },
    { word: 'unliveable', word_class: 'AJ' }
  ],
  britishism: [
    { word: 'british', word_class: 'N' },
    { word: 'british', word_class: 'AJ' },
    { word: 'britisher', word_class: 'N' },
    { word: 'britishism', word_class: 'N' }
  ],
  conditionally: [
    { word: 'condition', word_class: 'N' },
    { word: 'condition', word_class: 'V' },
    { word: 'conditioner', word_class: 'N' },
    { word: 'conditional', word_class: 'AJ' },
    { word: 'conditioned', word_class: 'AJ' },
    { word: 'conditioning', word_class: 'N' },
    { word: 'conditionality', word_class: 'N' },
    { word: 'conditionally', word_class: 'AV' }
  ],
  poetic: [
    { word: 'poetic', word_class: 'AJ' },
    { word: 'poetics', word_class: 'N' },
    { word: 'poetical', word_class: 'AJ' },
    { word: 'poeticize', word_class: 'V' },
    { word: 'poetically', word_class: 'AV' },
    { word: 'poeticization', word_class: 'N' }
  ],
  totaled: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  fixate: [
    { word: 'fix', word_class: 'N' },
    { word: 'fix', word_class: 'V' },
    { word: 'fixer', word_class: 'N' },
    { word: 'fixate', word_class: 'V' },
    { word: 'fixed', word_class: 'AJ' },
    { word: 'fixing', word_class: 'N' },
    { word: 'fixings', word_class: 'N' },
    { word: 'fixture', word_class: 'N' },
    { word: 'fixation', word_class: 'N' },
    { word: 'fixative', word_class: 'N' }
  ],
  deterioration: [
    { word: 'deteriorate', word_class: 'V' },
    { word: 'deterioration', word_class: 'N' },
    { word: 'deteriorating', word_class: 'AJ' }
  ],
  fleet: [
    { word: 'fleet', word_class: 'N' },
    { word: 'fleet', word_class: 'V' },
    { word: 'fleet', word_class: 'AJ' },
    { word: 'fleeting', word_class: 'AJ' },
    { word: 'fleetness', word_class: 'N' }
  ],
  arrange: [
    { word: 'arrange', word_class: 'V' },
    { word: 'arranger', word_class: 'N' },
    { word: 'arranged', word_class: 'AJ' },
    { word: 'arranging', word_class: 'N' },
    { word: 'arrangement', word_class: 'N' }
  ],
  depicted: [
    { word: 'depict', word_class: 'V' },
    { word: 'depicted', word_class: 'AJ' },
    { word: 'depicting', word_class: 'N' },
    { word: 'depiction', word_class: 'N' },
    { word: 'depicture', word_class: 'V' },
    { word: 'depictive', word_class: 'AJ' }
  ],
  revalorize: [
    { word: 'revalorise', word_class: 'V' },
    { word: 'revalorize', word_class: 'V' },
    { word: 'revalorization', word_class: 'N' }
  ],
  smuggling: [
    { word: 'smuggle', word_class: 'V' },
    { word: 'smuggler', word_class: 'N' },
    { word: 'smuggled', word_class: 'AJ' },
    { word: 'smuggling', word_class: 'N' }
  ],
  enchainment: [
    { word: 'enchain', word_class: 'V' },
    { word: 'enchained', word_class: 'AJ' },
    { word: 'enchainment', word_class: 'N' }
  ],
  mate: [
    { word: 'mate', word_class: 'V' },
    { word: 'mate', word_class: 'N' },
    { word: 'mater', word_class: 'N' },
    { word: 'mates', word_class: 'N' },
    { word: 'mated', word_class: 'AJ' },
    { word: 'mating', word_class: 'N' }
  ],
  widower: [
    { word: 'widow', word_class: 'N' },
    { word: 'widow', word_class: 'V' },
    { word: 'widower', word_class: 'N' },
    { word: 'widowed', word_class: 'AJ' }
  ],
  trivialisation: [
    { word: 'trivial', word_class: 'N' },
    { word: 'trivial', word_class: 'AJ' },
    { word: 'trivialise', word_class: 'V' },
    { word: 'trivialize', word_class: 'V' },
    { word: 'triviality', word_class: 'N' },
    { word: 'trivially', word_class: 'AV' },
    { word: 'trivialisation', word_class: 'N' },
    { word: 'trivialization', word_class: 'N' }
  ],
  evanescence: [
    { word: 'evanesce', word_class: 'V' },
    { word: 'evanescence', word_class: 'N' },
    { word: 'evanescent', word_class: 'AJ' }
  ],
  enteric: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  militarism: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  inferiority: [
    { word: 'inferior', word_class: 'N' },
    { word: 'inferior', word_class: 'AJ' },
    { word: 'inferiority', word_class: 'N' }
  ],
  transposability: [
    { word: 'transpose', word_class: 'N' },
    { word: 'transpose', word_class: 'V' },
    { word: 'transposed', word_class: 'AJ' },
    { word: 'transposition', word_class: 'N' },
    { word: 'transposability', word_class: 'N' }
  ],
  restrictively: [
    { word: 'restrict', word_class: 'V' },
    { word: 'restrict', word_class: 'AJ' },
    { word: 'restricted', word_class: 'AJ' },
    { word: 'restriction', word_class: 'N' },
    { word: 'restricting', word_class: 'AJ' },
    { word: 'restrictive', word_class: 'AJ' },
    { word: 'restrictively', word_class: 'AV' },
    { word: 'restrictiveness', word_class: 'N' }
  ],
  prestidigitator: [
    { word: 'prestidigitate', word_class: 'V' },
    { word: 'prestidigitator', word_class: 'N' },
    { word: 'prestidigitation', word_class: 'N' }
  ],
  hypostasize: [
    { word: 'hypostasize', word_class: 'V' },
    { word: 'hypostasisation', word_class: 'N' },
    { word: 'hypostasization', word_class: 'N' }
  ],
  dredger: [
    { word: 'dredge', word_class: 'N' },
    { word: 'dredge', word_class: 'V' },
    { word: 'dredger', word_class: 'N' }
  ],
  soviets: [
    { word: 'soviet', word_class: 'N' },
    { word: 'soviet', word_class: 'AJ' },
    { word: 'soviets', word_class: 'N' },
    { word: 'sovietise', word_class: 'V' },
    { word: 'sovietism', word_class: 'N' },
    { word: 'sovietize', word_class: 'V' },
    { word: 'sovietisation', word_class: 'N' },
    { word: 'sovietization', word_class: 'N' }
  ],
  process: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  scoopful: [
    { word: 'scoop', word_class: 'N' },
    { word: 'scoop', word_class: 'V' },
    { word: 'scoopful', word_class: 'N' },
    { word: 'scooping', word_class: 'N' },
    { word: 'scoopful', word_class: 'AJ' }
  ],
  weekly: [
    { word: 'weekly', word_class: 'N' },
    { word: 'weekly', word_class: 'AJ' },
    { word: 'weekly', word_class: 'AV' }
  ],
  scratching: [
    { word: 'scratch', word_class: 'N' },
    { word: 'scratch', word_class: 'V' },
    { word: 'scratched', word_class: 'AJ' },
    { word: 'scratching', word_class: 'N' },
    { word: 'scratching', word_class: 'AJ' }
  ],
  polarity: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  sulfurous: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  aerial: [
    { word: 'aerial', word_class: 'N' },
    { word: 'aerial', word_class: 'AJ' },
    { word: 'aerially', word_class: 'AV' }
  ],
  postulant: [
    { word: 'postulant', word_class: 'N' },
    { word: 'postulate', word_class: 'N' },
    { word: 'postulate', word_class: 'V' },
    { word: 'postulation', word_class: 'N' },
    { word: 'postulational', word_class: 'AJ' }
  ],
  litter: [
    { word: 'litter', word_class: 'N' },
    { word: 'litter', word_class: 'V' },
    { word: 'litterer', word_class: 'N' },
    { word: 'littered', word_class: 'AJ' }
  ],
  wholesome: [
    { word: 'whole', word_class: 'N' },
    { word: 'whole', word_class: 'AJ' },
    { word: 'whole', word_class: 'AV' },
    { word: 'wholly', word_class: 'AJ' },
    { word: 'wholly', word_class: 'AV' },
    { word: 'wholeness', word_class: 'N' },
    { word: 'wholesome', word_class: 'AJ' },
    { word: 'wholesomely', word_class: 'AV' },
    { word: 'wholesomeness', word_class: 'N' }
  ],
  indent: [
    { word: 'indent', word_class: 'N' },
    { word: 'indent', word_class: 'V' },
    { word: 'indenture', word_class: 'N' },
    { word: 'indenture', word_class: 'V' },
    { word: 'indented', word_class: 'AJ' },
    { word: 'indentation', word_class: 'N' },
    { word: 'indentured', word_class: 'AJ' }
  ],
  represented: [
    { word: 'represent', word_class: 'N' },
    { word: 'represent', word_class: 'V' },
    { word: 'represent', word_class: 'AJ' },
    { word: 'represented', word_class: 'AJ' },
    { word: 'representable', word_class: 'AJ' },
    { word: 'representation', word_class: 'N' },
    { word: 'representative', word_class: 'N' },
    { word: 'representative', word_class: 'AJ' },
    { word: 'representational', word_class: 'AJ' }
  ],
  timorous: [
    { word: 'timorous', word_class: 'AJ' },
    { word: 'timorously', word_class: 'AV' },
    { word: 'timorousness', word_class: 'N' }
  ],
  distressful: [
    { word: 'distress', word_class: 'N' },
    { word: 'distress', word_class: 'V' },
    { word: 'distressed', word_class: 'AJ' },
    { word: 'distressful', word_class: 'AJ' },
    { word: 'distressing', word_class: 'AJ' },
    { word: 'distressfulness', word_class: 'N' }
  ],
  pecker: [{ word: 'peck', word_class: 'N' }, { word: 'peck', word_class: 'V' }, { word: 'pecker', word_class: 'N' }],
  permeative: [
    { word: 'perm', word_class: 'N' },
    { word: 'perm', word_class: 'V' },
    { word: 'permed', word_class: 'AJ' },
    { word: 'permeative', word_class: 'AJ' },
    { word: 'perlocutionary', word_class: 'N' }
  ],
  teaser: [
    { word: 'tease', word_class: 'N' },
    { word: 'tease', word_class: 'V' },
    { word: 'teaser', word_class: 'N' },
    { word: 'teased', word_class: 'AJ' },
    { word: 'teasing', word_class: 'N' },
    { word: 'teasing', word_class: 'AJ' }
  ],
  search: [
    { word: 'search', word_class: 'N' },
    { word: 'search', word_class: 'V' },
    { word: 'searcher', word_class: 'N' },
    { word: 'searching', word_class: 'N' },
    { word: 'searching', word_class: 'AJ' }
  ],
  award: [
    { word: 'award', word_class: 'N' },
    { word: 'award', word_class: 'V' },
    { word: 'awarding', word_class: 'N' }
  ],
  prompter: [
    { word: 'prompt', word_class: 'N' },
    { word: 'prompt', word_class: 'V' },
    { word: 'prompt', word_class: 'AJ' },
    { word: 'prompter', word_class: 'N' },
    { word: 'prompting', word_class: 'N' },
    { word: 'promptness', word_class: 'N' }
  ],
  stone: [
    { word: 'stone', word_class: 'N' },
    { word: 'stone', word_class: 'V' },
    { word: 'stony', word_class: 'AJ' },
    { word: 'stone', word_class: 'AJ' },
    { word: 'stoned', word_class: 'AJ' }
  ],
  bewildered: [
    { word: 'bewilder', word_class: 'V' },
    { word: 'bewildered', word_class: 'AJ' },
    { word: 'bewildering', word_class: 'AJ' },
    { word: 'bewilderment', word_class: 'N' }
  ],
  crapulence: [
    { word: 'crapulence', word_class: 'N' },
    { word: 'crapulent', word_class: 'AJ' },
    { word: 'crapulous', word_class: 'AJ' }
  ],
  permeableness: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  disfigurement: [
    { word: 'disfigure', word_class: 'V' },
    { word: 'disfigured', word_class: 'AJ' },
    { word: 'disfiguration', word_class: 'N' },
    { word: 'disfigurement', word_class: 'N' }
  ],
  attrition: [
    { word: 'attrited', word_class: 'AJ' },
    { word: 'attrition', word_class: 'N' },
    { word: 'attritional', word_class: 'AJ' }
  ],
  supercharge: [
    { word: 'supercharge', word_class: 'V' },
    { word: 'supercharger', word_class: 'N' },
    { word: 'supercharged', word_class: 'AJ' }
  ],
  inconclusiveness: [
    { word: 'inconclusive', word_class: 'AJ' },
    { word: 'inconclusively', word_class: 'AV' },
    { word: 'inconclusiveness', word_class: 'N' }
  ],
  careerism: [
    { word: 'career', word_class: 'N' },
    { word: 'career', word_class: 'V' },
    { word: 'careerism', word_class: 'N' },
    { word: 'careerist', word_class: 'N' }
  ],
  paradoxically: [
    { word: 'paradox', word_class: 'N' },
    { word: 'paradoxical', word_class: 'AJ' },
    { word: 'paradoxically', word_class: 'AV' }
  ],
  muddiness: [
    { word: 'mud', word_class: 'N' },
    { word: 'mud', word_class: 'V' },
    { word: 'muddy', word_class: 'V' },
    { word: 'muddy', word_class: 'AJ' },
    { word: 'muddied', word_class: 'AJ' },
    { word: 'muddiness', word_class: 'N' }
  ],
  genet: [
    { word: 'genet', word_class: 'N' },
    { word: 'genetic', word_class: 'AJ' },
    { word: 'genetics', word_class: 'N' },
    { word: 'genetical', word_class: 'AJ' },
    { word: 'genetically', word_class: 'AV' }
  ],
  alienation: [
    { word: 'alien', word_class: 'N' },
    { word: 'alien', word_class: 'V' },
    { word: 'alien', word_class: 'AJ' },
    { word: 'alienate', word_class: 'V' },
    { word: 'alienism', word_class: 'N' },
    { word: 'alienable', word_class: 'AJ' },
    { word: 'alienated', word_class: 'AJ' },
    { word: 'alienation', word_class: 'N' },
    { word: 'alienating', word_class: 'AJ' }
  ],
  sage: [{ word: 'sage', word_class: 'N' }, { word: 'sage', word_class: 'AJ' }, { word: 'sagely', word_class: 'AV' }],
  innerve: [
    { word: 'innerve', word_class: 'V' },
    { word: 'innervate', word_class: 'V' },
    { word: 'innervation', word_class: 'N' }
  ],
  illegitimately: [
    { word: 'illegitimate', word_class: 'N' },
    { word: 'illegitimate', word_class: 'AJ' },
    { word: 'illegitimately', word_class: 'AV' }
  ],
  glider: [
    { word: 'glide', word_class: 'N' },
    { word: 'glide', word_class: 'V' },
    { word: 'glider', word_class: 'N' },
    { word: 'gliding', word_class: 'N' }
  ],
  smoother: [
    { word: 'smooth', word_class: 'N' },
    { word: 'smooth', word_class: 'V' },
    { word: 'smooth', word_class: 'AJ' },
    { word: 'smoother', word_class: 'N' },
    { word: 'smoothed', word_class: 'AJ' },
    { word: 'smoothness', word_class: 'N' }
  ],
  rehabilitated: [
    { word: 'rehabilitate', word_class: 'V' },
    { word: 'rehabilitated', word_class: 'AJ' },
    { word: 'rehabilitation', word_class: 'N' },
    { word: 'rehabilitative', word_class: 'AJ' }
  ],
  preeminently: [
    { word: 'preeminence', word_class: 'N' },
    { word: 'preeminent', word_class: 'AJ' },
    { word: 'preeminently', word_class: 'AV' }
  ],
  accredit: [
    { word: 'accredit', word_class: 'V' },
    { word: 'accredited', word_class: 'AJ' },
    { word: 'accreditation', word_class: 'N' }
  ],
  flexion: [
    { word: 'flex', word_class: 'N' },
    { word: 'flex', word_class: 'V' },
    { word: 'flexor', word_class: 'N' },
    { word: 'flexion', word_class: 'N' },
    { word: 'flection', word_class: 'N' }
  ],
  exclusively: [
    { word: 'exclude', word_class: 'V' },
    { word: 'exclusion', word_class: 'N' },
    { word: 'exclusive', word_class: 'N' },
    { word: 'exclusive', word_class: 'AJ' },
    { word: 'exclusively', word_class: 'AV' },
    { word: 'exclusionary', word_class: 'AJ' },
    { word: 'exclusiveness', word_class: 'N' }
  ],
  aid: [
    { word: 'aid', word_class: 'N' },
    { word: 'aid', word_class: 'V' },
    { word: 'aide', word_class: 'N' },
    { word: 'aids', word_class: 'N' },
    { word: 'aides', word_class: 'N' },
    { word: 'aided', word_class: 'AJ' }
  ],
  embalmment: [
    { word: 'embalm', word_class: 'V' },
    { word: 'embalmer', word_class: 'N' },
    { word: 'embalmment', word_class: 'N' }
  ],
  sexism: [
    { word: 'sex', word_class: 'N' },
    { word: 'sex', word_class: 'V' },
    { word: 'sexed', word_class: 'AJ' },
    { word: 'sexism', word_class: 'N' },
    { word: 'sexist', word_class: 'N' },
    { word: 'sexist', word_class: 'AJ' }
  ],
  syndic: [
    { word: 'syndic', word_class: 'N' },
    { word: 'syndicate', word_class: 'N' },
    { word: 'syndicate', word_class: 'V' },
    { word: 'syndicalism', word_class: 'N' },
    { word: 'syndication', word_class: 'N' }
  ],
  rally: [
    { word: 'rally', word_class: 'N' },
    { word: 'rally', word_class: 'V' },
    { word: 'rallying', word_class: 'N' },
    { word: 'rallying', word_class: 'AJ' }
  ],
  erectness: [
    { word: 'erect', word_class: 'V' },
    { word: 'erect', word_class: 'AJ' },
    { word: 'erecting', word_class: 'N' },
    { word: 'erection', word_class: 'N' },
    { word: 'erectness', word_class: 'N' }
  ],
  renowned: [
    { word: 'renown', word_class: 'N' },
    { word: 'renown', word_class: 'V' },
    { word: 'renowned', word_class: 'AJ' }
  ],
  subconsciously: [
    { word: 'subconscious', word_class: 'N' },
    { word: 'subconscious', word_class: 'AJ' },
    { word: 'subconsciously', word_class: 'AV' },
    { word: 'subconsciousness', word_class: 'N' }
  ],
  lamenting: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  liquidator: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  animated: [
    { word: 'anime', word_class: 'N' },
    { word: 'animal', word_class: 'N' },
    { word: 'animal', word_class: 'AJ' },
    { word: 'animate', word_class: 'V' },
    { word: 'animism', word_class: 'N' },
    { word: 'animize', word_class: 'V' },
    { word: 'animate', word_class: 'AJ' },
    { word: 'animalise', word_class: 'V' },
    { word: 'animalize', word_class: 'V' },
    { word: 'animated', word_class: 'AJ' },
    { word: 'animalism', word_class: 'N' },
    { word: 'animatism', word_class: 'N' },
    { word: 'animality', word_class: 'N' },
    { word: 'animation', word_class: 'N' },
    { word: 'animating', word_class: 'AJ' },
    { word: 'animateness', word_class: 'N' },
    { word: 'animalisation', word_class: 'N' },
    { word: 'animalization', word_class: 'N' }
  ],
  bootie: [
    { word: 'booty', word_class: 'N' },
    { word: 'bootie', word_class: 'N' },
    { word: 'booty', word_class: 'AJ' },
    { word: 'booties', word_class: 'N' }
  ],
  reversive: [
    { word: 'revert', word_class: 'V' },
    { word: 'reverse', word_class: 'N' },
    { word: 'reverse', word_class: 'V' },
    { word: 'reversal', word_class: 'N' },
    { word: 'reverse', word_class: 'AJ' },
    { word: 'reversed', word_class: 'AJ' },
    { word: 'reversion', word_class: 'N' },
    { word: 'reverting', word_class: 'N' },
    { word: 'reversely', word_class: 'AV' },
    { word: 'reversible', word_class: 'N' },
    { word: 'reversive', word_class: 'AJ' },
    { word: 'reverting', word_class: 'AJ' },
    { word: 'reversible', word_class: 'AJ' },
    { word: 'reversibly', word_class: 'AV' },
    { word: 'revertible', word_class: 'AJ' },
    { word: 'reversibility', word_class: 'N' }
  ],
  detestably: [
    { word: 'detest', word_class: 'V' },
    { word: 'detested', word_class: 'AJ' },
    { word: 'detestable', word_class: 'AJ' },
    { word: 'detestably', word_class: 'AV' },
    { word: 'detestation', word_class: 'N' }
  ],
  utilitarianism: [
    { word: 'utile', word_class: 'AJ' },
    { word: 'utilise', word_class: 'V' },
    { word: 'utility', word_class: 'N' },
    { word: 'utilize', word_class: 'V' },
    { word: 'utility', word_class: 'AJ' },
    { word: 'utilized', word_class: 'AJ' },
    { word: 'utilitarian', word_class: 'N' },
    { word: 'utilisation', word_class: 'N' },
    { word: 'utilization', word_class: 'N' },
    { word: 'utilitarian', word_class: 'AJ' },
    { word: 'utilitarianism', word_class: 'N' }
  ],
  fragrant: [
    { word: 'fragrance', word_class: 'N' },
    { word: 'fragrancy', word_class: 'N' },
    { word: 'fragrant', word_class: 'AJ' }
  ],
  drawn: [
    { word: 'draw', word_class: 'N' },
    { word: 'draw', word_class: 'V' },
    { word: 'drawn', word_class: 'AJ' },
    { word: 'drawee', word_class: 'N' },
    { word: 'drawer', word_class: 'N' },
    { word: 'drawers', word_class: 'N' },
    { word: 'drawing', word_class: 'N' }
  ],
  more: [
    { word: 'more', word_class: 'N' },
    { word: 'more', word_class: 'AJ' },
    { word: 'more', word_class: 'AV' },
    { word: 'mores', word_class: 'N' }
  ],
  normalization: [
    { word: 'normal', word_class: 'N' },
    { word: 'normal', word_class: 'AJ' },
    { word: 'normalise', word_class: 'V' },
    { word: 'normalize', word_class: 'V' },
    { word: 'normality', word_class: 'N' },
    { word: 'normally', word_class: 'AV' },
    { word: 'normalisation', word_class: 'N' },
    { word: 'normalization', word_class: 'N' }
  ],
  balkanization: [
    { word: 'balkans', word_class: 'N' },
    { word: 'balkanise', word_class: 'V' },
    { word: 'balkanize', word_class: 'V' },
    { word: 'balkanisation', word_class: 'N' },
    { word: 'balkanization', word_class: 'N' }
  ],
  hackee: [
    { word: 'hack', word_class: 'N' },
    { word: 'hack', word_class: 'V' },
    { word: 'hackee', word_class: 'N' },
    { word: 'hacker', word_class: 'N' }
  ],
  blasting: [
    { word: 'blast', word_class: 'N' },
    { word: 'blast', word_class: 'V' },
    { word: 'blasted', word_class: 'AJ' },
    { word: 'blasting', word_class: 'AJ' }
  ],
  idea: [
    { word: 'idea', word_class: 'N' },
    { word: 'ideate', word_class: 'V' },
    { word: 'ideation', word_class: 'N' },
    { word: 'ideational', word_class: 'AJ' }
  ],
  engorgement: [
    { word: 'engorge', word_class: 'V' },
    { word: 'engorged', word_class: 'AJ' },
    { word: 'engorgement', word_class: 'N' }
  ],
  resuscitate: [
    { word: 'resuscitate', word_class: 'V' },
    { word: 'resuscitated', word_class: 'AJ' },
    { word: 'resuscitation', word_class: 'N' }
  ],
  arthropodous: [
    { word: 'arthropod', word_class: 'N' },
    { word: 'arthropodal', word_class: 'AJ' },
    { word: 'arthropodous', word_class: 'AJ' }
  ],
  assess: [
    { word: 'assess', word_class: 'N' },
    { word: 'assess', word_class: 'V' },
    { word: 'assessor', word_class: 'N' },
    { word: 'assessment', word_class: 'N' },
    { word: 'assessable', word_class: 'AJ' }
  ],
  besiege: [
    { word: 'besiege', word_class: 'V' },
    { word: 'besieger', word_class: 'N' },
    { word: 'besieged', word_class: 'AJ' },
    { word: 'besieging', word_class: 'N' }
  ],
  flocculent: [
    { word: 'floccule', word_class: 'N' },
    { word: 'flocculate', word_class: 'V' },
    { word: 'flocculent', word_class: 'AJ' },
    { word: 'flocculation', word_class: 'N' }
  ],
  bailment: [
    { word: 'bail', word_class: 'N' },
    { word: 'bail', word_class: 'V' },
    { word: 'bailment', word_class: 'N' }
  ],
  mutably: [
    { word: 'mutable', word_class: 'AJ' },
    { word: 'mutably', word_class: 'AV' },
    { word: 'mutability', word_class: 'N' },
    { word: 'mutableness', word_class: 'N' }
  ],
  dietetical: [
    { word: 'diet', word_class: 'N' },
    { word: 'diet', word_class: 'V' },
    { word: 'diet', word_class: 'AJ' },
    { word: 'dietary', word_class: 'N' },
    { word: 'dieting', word_class: 'N' },
    { word: 'dietary', word_class: 'AJ' },
    { word: 'dietetic', word_class: 'AJ' },
    { word: 'dietetics', word_class: 'N' },
    { word: 'dietetical', word_class: 'AJ' }
  ],
  encysted: [
    { word: 'encyst', word_class: 'V' },
    { word: 'encysted', word_class: 'AJ' },
    { word: 'encystment', word_class: 'N' },
    { word: 'encystation', word_class: 'N' }
  ],
  stretch: [
    { word: 'stretch', word_class: 'N' },
    { word: 'stretch', word_class: 'V' },
    { word: 'stretch', word_class: 'AJ' },
    { word: 'stretcher', word_class: 'N' },
    { word: 'stretched', word_class: 'AJ' },
    { word: 'stretching', word_class: 'N' },
    { word: 'stretching', word_class: 'AJ' }
  ],
  prostitute: [
    { word: 'prostitute', word_class: 'N' },
    { word: 'prostitute', word_class: 'V' },
    { word: 'prostitution', word_class: 'N' }
  ],
  coevals: [
    { word: 'coeval', word_class: 'N' },
    { word: 'coeval', word_class: 'AJ' },
    { word: 'coevals', word_class: 'N' }
  ],
  availableness: [
    { word: 'avail', word_class: 'N' },
    { word: 'avail', word_class: 'V' },
    { word: 'available', word_class: 'AJ' },
    { word: 'availability', word_class: 'N' },
    { word: 'availableness', word_class: 'N' }
  ],
  contacting: [
    { word: 'contact', word_class: 'N' },
    { word: 'contact', word_class: 'V' },
    { word: 'contacting', word_class: 'N' }
  ],
  mangled: [
    { word: 'mangle', word_class: 'N' },
    { word: 'mangle', word_class: 'V' },
    { word: 'mangled', word_class: 'AJ' }
  ],
  syndicate: [
    { word: 'syndic', word_class: 'N' },
    { word: 'syndicate', word_class: 'N' },
    { word: 'syndicate', word_class: 'V' },
    { word: 'syndicalism', word_class: 'N' },
    { word: 'syndication', word_class: 'N' }
  ],
  insulted: [
    { word: 'insult', word_class: 'N' },
    { word: 'insult', word_class: 'V' },
    { word: 'insulted', word_class: 'AJ' },
    { word: 'insulting', word_class: 'AJ' }
  ],
  superimpose: [
    { word: 'superimpose', word_class: 'V' },
    { word: 'superimposed', word_class: 'AJ' },
    { word: 'superimposition', word_class: 'N' }
  ],
  trilled: [
    { word: 'trill', word_class: 'N' },
    { word: 'trill', word_class: 'V' },
    { word: 'trilled', word_class: 'AJ' },
    { word: 'trillion', word_class: 'N' },
    { word: 'trillion', word_class: 'AJ' },
    { word: 'trillions', word_class: 'N' }
  ],
  polarise: [
    { word: 'pole', word_class: 'N' },
    { word: 'pole', word_class: 'V' },
    { word: 'poler', word_class: 'N' },
    { word: 'polar', word_class: 'AJ' },
    { word: 'polarise', word_class: 'V' },
    { word: 'polarity', word_class: 'N' },
    { word: 'polarize', word_class: 'V' },
    { word: 'polarisation', word_class: 'N' },
    { word: 'polarization', word_class: 'N' }
  ],
  skew: [
    { word: 'skew', word_class: 'V' },
    { word: 'skew', word_class: 'AJ' },
    { word: 'skewed', word_class: 'AJ' },
    { word: 'skewness', word_class: 'N' }
  ],
  being: [{ word: 'be', word_class: 'N' }, { word: 'be', word_class: 'V' }, { word: 'being', word_class: 'N' }],
  maladjustment: [
    { word: 'maladjusted', word_class: 'AJ' },
    { word: 'maladjustive', word_class: 'AJ' },
    { word: 'maladjustment', word_class: 'N' }
  ],
  potential: [
    { word: 'potential', word_class: 'N' },
    { word: 'potential', word_class: 'AJ' },
    { word: 'potentiate', word_class: 'V' },
    { word: 'potentiality', word_class: 'N' },
    { word: 'potentially', word_class: 'AV' },
    { word: 'potentiation', word_class: 'N' }
  ],
  vesiculation: [
    { word: 'vesiculate', word_class: 'V' },
    { word: 'vesiculitis', word_class: 'N' },
    { word: 'vesiculation', word_class: 'N' }
  ],
  concluding: [
    { word: 'conclude', word_class: 'V' },
    { word: 'concluded', word_class: 'AJ' },
    { word: 'conclusion', word_class: 'N' },
    { word: 'concluding', word_class: 'AJ' },
    { word: 'conclusive', word_class: 'AJ' },
    { word: 'conclusively', word_class: 'AV' },
    { word: 'conclusiveness', word_class: 'N' }
  ],
  quadratic: [
    { word: 'quadrate', word_class: 'N' },
    { word: 'quadrate', word_class: 'AJ' },
    { word: 'quadratic', word_class: 'N' },
    { word: 'quadratic', word_class: 'AJ' },
    { word: 'quadratics', word_class: 'N' }
  ],
  rose: [
    { word: 'rose', word_class: 'N' },
    { word: 'rosy', word_class: 'AJ' },
    { word: 'rose', word_class: 'AJ' },
    { word: 'rosiness', word_class: 'N' }
  ],
  mystery: [
    { word: 'mystery', word_class: 'N' },
    { word: 'mysterious', word_class: 'AJ' },
    { word: 'mysteriously', word_class: 'AV' }
  ],
  elaborate: [
    { word: 'elaborate', word_class: 'V' },
    { word: 'elaborate', word_class: 'AJ' },
    { word: 'elaborated', word_class: 'AJ' },
    { word: 'elaboration', word_class: 'N' },
    { word: 'elaborately', word_class: 'AV' },
    { word: 'elaborateness', word_class: 'N' }
  ],
  devisee: [
    { word: 'device', word_class: 'N' },
    { word: 'devise', word_class: 'N' },
    { word: 'devise', word_class: 'V' },
    { word: 'devisal', word_class: 'N' },
    { word: 'devices', word_class: 'N' },
    { word: 'devisee', word_class: 'N' },
    { word: 'deviser', word_class: 'N' },
    { word: 'devising', word_class: 'N' }
  ],
  positional: [
    { word: 'pose', word_class: 'N' },
    { word: 'pose', word_class: 'V' },
    { word: 'poser', word_class: 'N' },
    { word: 'posit', word_class: 'V' },
    { word: 'posing', word_class: 'N' },
    { word: 'position', word_class: 'N' },
    { word: 'position', word_class: 'V' },
    { word: 'positive', word_class: 'N' },
    { word: 'positive', word_class: 'AJ' },
    { word: 'positioner', word_class: 'N' },
    { word: 'positivity', word_class: 'N' },
    { word: 'positional', word_class: 'AJ' },
    { word: 'positioning', word_class: 'N' },
    { word: 'positively', word_class: 'AV' },
    { word: 'positioning', word_class: 'AJ' },
    { word: 'positiveness', word_class: 'N' },
    { word: 'positionable', word_class: 'AJ' }
  ],
  reformative: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  dying: [
    { word: 'dy', word_class: 'N' },
    { word: 'die', word_class: 'N' },
    { word: 'die', word_class: 'V' },
    { word: 'death', word_class: 'N' },
    { word: 'dyed', word_class: 'AJ' },
    { word: 'dying', word_class: 'V' },
    { word: 'dying', word_class: 'N' },
    { word: 'dying', word_class: 'AJ' }
  ],
  segregated: [
    { word: 'segregant', word_class: 'N' },
    { word: 'segregate', word_class: 'V' },
    { word: 'segregated', word_class: 'AJ' },
    { word: 'segregation', word_class: 'N' }
  ],
  annex: [
    { word: 'annex', word_class: 'N' },
    { word: 'annex', word_class: 'V' },
    { word: 'annexe', word_class: 'N' },
    { word: 'annexal', word_class: 'AJ' },
    { word: 'annexation', word_class: 'N' },
    { word: 'annexational', word_class: 'AJ' }
  ],
  numerably: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  adjunctive: [
    { word: 'adjunct', word_class: 'N' },
    { word: 'adjunct', word_class: 'AJ' },
    { word: 'adjunction', word_class: 'N' },
    { word: 'adjunctive', word_class: 'AJ' }
  ],
  unquestionably: [
    { word: 'unquestionable', word_class: 'AJ' },
    { word: 'unquestionably', word_class: 'AV' },
    { word: 'unquestionability', word_class: 'N' },
    { word: 'unquestionableness', word_class: 'N' }
  ],
  ethnologic: [
    { word: 'ethnology', word_class: 'N' },
    { word: 'ethnologic', word_class: 'AJ' },
    { word: 'ethnological', word_class: 'AJ' }
  ],
  skittle: [
    { word: 'skittle', word_class: 'N' },
    { word: 'skittle', word_class: 'V' },
    { word: 'skittles', word_class: 'N' }
  ],
  birl: [{ word: 'birl', word_class: 'V' }, { word: 'birle', word_class: 'V' }, { word: 'birling', word_class: 'N' }],
  explainable: [
    { word: 'explain', word_class: 'N' },
    { word: 'explain', word_class: 'V' },
    { word: 'explain', word_class: 'AJ' },
    { word: 'explanation', word_class: 'N' },
    { word: 'explainable', word_class: 'AJ' },
    { word: 'explanatory', word_class: 'AJ' }
  ],
  bottom: [
    { word: 'bottom', word_class: 'N' },
    { word: 'bottom', word_class: 'V' },
    { word: 'bottom', word_class: 'AJ' },
    { word: 'bottomed', word_class: 'AJ' }
  ],
  laos: [{ word: 'lao', word_class: 'N' }, { word: 'lao', word_class: 'AJ' }, { word: 'laos', word_class: 'N' }],
  squandering: [
    { word: 'squander', word_class: 'V' },
    { word: 'squanderer', word_class: 'N' },
    { word: 'squandered', word_class: 'AJ' },
    { word: 'squandering', word_class: 'N' }
  ],
  classic: [
    { word: 'classic', word_class: 'N' },
    { word: 'classic', word_class: 'AJ' },
    { word: 'classics', word_class: 'N' },
    { word: 'classical', word_class: 'AJ' },
    { word: 'classicism', word_class: 'N' },
    { word: 'classicize', word_class: 'V' },
    { word: 'classically', word_class: 'AV' }
  ],
  stance: [
    { word: 'stand', word_class: 'N' },
    { word: 'stand', word_class: 'V' },
    { word: 'stance', word_class: 'N' },
    { word: 'stands', word_class: 'N' },
    { word: 'station', word_class: 'N' },
    { word: 'station', word_class: 'V' },
    { word: 'standing', word_class: 'N' },
    { word: 'standing', word_class: 'AJ' },
    { word: 'stationer', word_class: 'N' }
  ],
  impermissible: [
    { word: 'impermissible', word_class: 'AJ' },
    { word: 'impermissibly', word_class: 'AV' },
    { word: 'impermissibility', word_class: 'N' }
  ],
  ravenously: [
    { word: 'raven', word_class: 'N' },
    { word: 'raven', word_class: 'V' },
    { word: 'ravening', word_class: 'AJ' },
    { word: 'ravenous', word_class: 'AJ' },
    { word: 'ravenously', word_class: 'AV' },
    { word: 'ravenousness', word_class: 'N' }
  ],
  decorous: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  tenured: [
    { word: 'tenure', word_class: 'N' },
    { word: 'tenure', word_class: 'V' },
    { word: 'tenured', word_class: 'AJ' }
  ],
  credibly: [
    { word: 'credible', word_class: 'AJ' },
    { word: 'credibly', word_class: 'AV' },
    { word: 'credibility', word_class: 'N' },
    { word: 'credibleness', word_class: 'N' }
  ],
  carboxylation: [
    { word: 'carboxyl', word_class: 'N' },
    { word: 'carboxyl', word_class: 'AJ' },
    { word: 'carboxylate', word_class: 'V' },
    { word: 'carboxylic', word_class: 'AJ' },
    { word: 'carboxylation', word_class: 'N' }
  ],
  swear: [
    { word: 'swear', word_class: 'V' },
    { word: 'sworn', word_class: 'AJ' },
    { word: 'swearer', word_class: 'N' },
    { word: 'swearing', word_class: 'N' }
  ],
  unmanful: [
    { word: 'unman', word_class: 'V' },
    { word: 'unmanful', word_class: 'AJ' },
    { word: 'unmanned', word_class: 'AJ' }
  ],
  moroseness: [
    { word: 'morose', word_class: 'AJ' },
    { word: 'morosely', word_class: 'AV' },
    { word: 'moroseness', word_class: 'N' }
  ],
  warn: [
    { word: 'warn', word_class: 'V' },
    { word: 'warning', word_class: 'N' },
    { word: 'warning', word_class: 'AJ' }
  ],
  dissembling: [
    { word: 'dissemble', word_class: 'V' },
    { word: 'dissembler', word_class: 'N' },
    { word: 'dissembling', word_class: 'N' },
    { word: 'dissemblance', word_class: 'N' },
    { word: 'dissembling', word_class: 'AJ' }
  ],
  peopled: [
    { word: 'people', word_class: 'N' },
    { word: 'people', word_class: 'V' },
    { word: 'peopled', word_class: 'AJ' }
  ],
  contentiousness: [
    { word: 'contend', word_class: 'V' },
    { word: 'content', word_class: 'N' },
    { word: 'content', word_class: 'V' },
    { word: 'content', word_class: 'AJ' },
    { word: 'contents', word_class: 'N' },
    { word: 'contender', word_class: 'N' },
    { word: 'contention', word_class: 'N' },
    { word: 'contented', word_class: 'AJ' },
    { word: 'contending', word_class: 'AJ' },
    { word: 'contentment', word_class: 'N' },
    { word: 'contentious', word_class: 'AJ' },
    { word: 'contentiousness', word_class: 'N' }
  ],
  munificence: [
    { word: 'munificence', word_class: 'N' },
    { word: 'munificent', word_class: 'AJ' },
    { word: 'munificently', word_class: 'AV' }
  ],
  sundries: [
    { word: 'sundry', word_class: 'N' },
    { word: 'sundry', word_class: 'AJ' },
    { word: 'sundries', word_class: 'N' }
  ],
  satirisation: [
    { word: 'satire', word_class: 'N' },
    { word: 'satirise', word_class: 'V' },
    { word: 'satiric', word_class: 'AJ' },
    { word: 'satirize', word_class: 'V' },
    { word: 'satirist', word_class: 'N' },
    { word: 'satirical', word_class: 'AJ' },
    { word: 'satirically', word_class: 'AV' },
    { word: 'satirisation', word_class: 'N' },
    { word: 'satirization', word_class: 'N' }
  ],
  unattractive: [
    { word: 'unattractive', word_class: 'AJ' },
    { word: 'unattractively', word_class: 'AV' },
    { word: 'unattractiveness', word_class: 'N' }
  ],
  superficiality: [
    { word: 'superficies', word_class: 'N' },
    { word: 'superficial', word_class: 'AJ' },
    { word: 'superficiality', word_class: 'N' },
    { word: 'superficially', word_class: 'AV' }
  ],
  people: [
    { word: 'people', word_class: 'N' },
    { word: 'people', word_class: 'V' },
    { word: 'peopled', word_class: 'AJ' }
  ],
  masculine: [
    { word: 'masculine', word_class: 'N' },
    { word: 'masculine', word_class: 'AJ' },
    { word: 'masculinity', word_class: 'N' },
    { word: 'masculinization', word_class: 'N' }
  ],
  liveried: [
    { word: 'livery', word_class: 'N' },
    { word: 'livery', word_class: 'AJ' },
    { word: 'liveried', word_class: 'AJ' }
  ],
  pathology: [
    { word: 'pathology', word_class: 'N' },
    { word: 'pathologic', word_class: 'AJ' },
    { word: 'pathological', word_class: 'AJ' },
    { word: 'pathologically', word_class: 'AV' }
  ],
  algebraic: [
    { word: 'algebra', word_class: 'N' },
    { word: 'algebraic', word_class: 'AJ' },
    { word: 'algebraical', word_class: 'AJ' },
    { word: 'algebraically', word_class: 'AV' }
  ],
  determinateness: [
    { word: 'determine', word_class: 'V' },
    { word: 'determiner', word_class: 'N' },
    { word: 'determinant', word_class: 'N' },
    { word: 'determinism', word_class: 'N' },
    { word: 'determinate', word_class: 'V' },
    { word: 'determinist', word_class: 'N' },
    { word: 'determined', word_class: 'AJ' },
    { word: 'determinant', word_class: 'AJ' },
    { word: 'determinate', word_class: 'AJ' },
    { word: 'determining', word_class: 'AJ' },
    { word: 'determination', word_class: 'N' },
    { word: 'determinable', word_class: 'AJ' },
    { word: 'determinative', word_class: 'N' },
    { word: 'determinative', word_class: 'AJ' },
    { word: 'determinateness', word_class: 'N' }
  ],
  modernized: [
    { word: 'modern', word_class: 'N' },
    { word: 'modern', word_class: 'AJ' },
    { word: 'moderne', word_class: 'AJ' },
    { word: 'modernise', word_class: 'V' },
    { word: 'modernize', word_class: 'V' },
    { word: 'modernist', word_class: 'N' },
    { word: 'modernism', word_class: 'N' },
    { word: 'modernity', word_class: 'N' },
    { word: 'modernist', word_class: 'AJ' },
    { word: 'modernness', word_class: 'N' },
    { word: 'modernizing', word_class: 'N' },
    { word: 'modernized', word_class: 'AJ' },
    { word: 'modernistic', word_class: 'AJ' },
    { word: 'modernisation', word_class: 'N' },
    { word: 'modernization', word_class: 'N' }
  ],
  convulse: [
    { word: 'convulse', word_class: 'V' },
    { word: 'convulsion', word_class: 'N' },
    { word: 'convulsive', word_class: 'AJ' },
    { word: 'convulsively', word_class: 'AV' }
  ],
  leaders: [
    { word: 'lead', word_class: 'N' },
    { word: 'lead', word_class: 'V' },
    { word: 'leader', word_class: 'N' },
    { word: 'leaden', word_class: 'AJ' },
    { word: 'leaded', word_class: 'AJ' },
    { word: 'leaders', word_class: 'N' },
    { word: 'leading', word_class: 'N' },
    { word: 'leading', word_class: 'AJ' }
  ],
  initiation: [
    { word: 'initial', word_class: 'N' },
    { word: 'initial', word_class: 'V' },
    { word: 'initiate', word_class: 'V' },
    { word: 'initiate', word_class: 'N' },
    { word: 'initial', word_class: 'AJ' },
    { word: 'initiate', word_class: 'AJ' },
    { word: 'initiator', word_class: 'N' },
    { word: 'initialise', word_class: 'V' },
    { word: 'initialize', word_class: 'V' },
    { word: 'initiation', word_class: 'N' },
    { word: 'initially', word_class: 'AV' },
    { word: 'initiative', word_class: 'N' },
    { word: 'initiated', word_class: 'AJ' },
    { word: 'initiative', word_class: 'AJ' },
    { word: 'initialisation', word_class: 'N' },
    { word: 'initialization', word_class: 'N' }
  ],
  thudding: [
    { word: 'thud', word_class: 'N' },
    { word: 'thud', word_class: 'V' },
    { word: 'thudding', word_class: 'AJ' }
  ],
  immigrate: [
    { word: 'immigrant', word_class: 'N' },
    { word: 'immigrate', word_class: 'V' },
    { word: 'immigration', word_class: 'N' }
  ],
  unman: [
    { word: 'unman', word_class: 'V' },
    { word: 'unmanful', word_class: 'AJ' },
    { word: 'unmanned', word_class: 'AJ' }
  ],
  triangulation: [
    { word: 'triangulate', word_class: 'V' },
    { word: 'triangulate', word_class: 'AJ' },
    { word: 'triangulation', word_class: 'N' }
  ],
  burgle: [
    { word: 'burgle', word_class: 'V' },
    { word: 'burglar', word_class: 'N' },
    { word: 'burglary', word_class: 'N' },
    { word: 'burglary', word_class: 'AJ' },
    { word: 'burglarise', word_class: 'V' },
    { word: 'burglarize', word_class: 'V' },
    { word: 'burglarious', word_class: 'AJ' },
    { word: 'burglarization', word_class: 'N' }
  ],
  calvinist: [
    { word: 'calvinist', word_class: 'N' },
    { word: 'calvinist', word_class: 'AJ' },
    { word: 'calvinistic', word_class: 'AJ' },
    { word: 'calvinistical', word_class: 'AJ' }
  ],
  meteorology: [
    { word: 'meteorology', word_class: 'N' },
    { word: 'meteorologic', word_class: 'AJ' },
    { word: 'meteorological', word_class: 'AJ' },
    { word: 'meteorologically', word_class: 'AV' }
  ],
  susceptible: [
    { word: 'susceptible', word_class: 'AJ' },
    { word: 'susceptibly', word_class: 'AV' },
    { word: 'susceptibility', word_class: 'N' },
    { word: 'susceptibleness', word_class: 'N' }
  ],
  bigotry: [
    { word: 'bigot', word_class: 'N' },
    { word: 'bigotry', word_class: 'N' },
    { word: 'bigoted', word_class: 'AJ' }
  ],
  conversely: [
    { word: 'converse', word_class: 'N' },
    { word: 'converse', word_class: 'V' },
    { word: 'converse', word_class: 'AJ' },
    { word: 'conversance', word_class: 'N' },
    { word: 'conversant', word_class: 'AJ' },
    { word: 'conversancy', word_class: 'N' },
    { word: 'conversely', word_class: 'AV' },
    { word: 'conversation', word_class: 'N' },
    { word: 'conversational', word_class: 'AJ' }
  ],
  rebel: [
    { word: 'rebel', word_class: 'N' },
    { word: 'rebel', word_class: 'V' },
    { word: 'rebel', word_class: 'AJ' },
    { word: 'rebellion', word_class: 'N' },
    { word: 'rebelling', word_class: 'AJ' }
  ],
  issus: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  granule: [
    { word: 'granule', word_class: 'N' },
    { word: 'granulate', word_class: 'V' },
    { word: 'granulated', word_class: 'AJ' },
    { word: 'granulation', word_class: 'N' }
  ],
  reclusiveness: [
    { word: 'recluse', word_class: 'N' },
    { word: 'recluse', word_class: 'AJ' },
    { word: 'reclusive', word_class: 'AJ' },
    { word: 'reclusiveness', word_class: 'N' }
  ],
  fractionise: [
    { word: 'fraction', word_class: 'N' },
    { word: 'fraction', word_class: 'V' },
    { word: 'fractionate', word_class: 'V' },
    { word: 'fractionize', word_class: 'V' },
    { word: 'fractionise', word_class: 'V' },
    { word: 'fractional', word_class: 'AJ' },
    { word: 'fractionation', word_class: 'N' },
    { word: 'fractionisation', word_class: 'N' },
    { word: 'fractionization', word_class: 'N' }
  ],
  plumber: [
    { word: 'plumb', word_class: 'N' },
    { word: 'plumb', word_class: 'V' },
    { word: 'plumb', word_class: 'AJ' },
    { word: 'plumb', word_class: 'AV' },
    { word: 'plumber', word_class: 'N' },
    { word: 'plumbed', word_class: 'AJ' },
    { word: 'plumbing', word_class: 'N' },
    { word: 'plumbism', word_class: 'N' }
  ],
  whoop: [
    { word: 'whoop', word_class: 'N' },
    { word: 'whoop', word_class: 'V' },
    { word: 'whoopee', word_class: 'N' },
    { word: 'whooper', word_class: 'N' }
  ],
  coupled: [
    { word: 'couple', word_class: 'N' },
    { word: 'couple', word_class: 'V' },
    { word: 'couple', word_class: 'AJ' },
    { word: 'coupler', word_class: 'N' },
    { word: 'coupled', word_class: 'AJ' },
    { word: 'coupling', word_class: 'N' }
  ],
  sentiment: [
    { word: 'sentiment', word_class: 'N' },
    { word: 'sentimental', word_class: 'AJ' },
    { word: 'sentimentalise', word_class: 'V' },
    { word: 'sentimentalize', word_class: 'V' },
    { word: 'sentimentalist', word_class: 'N' },
    { word: 'sentimentality', word_class: 'N' },
    { word: 'sentimentally', word_class: 'AV' },
    { word: 'sentimentalisation', word_class: 'N' },
    { word: 'sentimentalization', word_class: 'N' }
  ],
  effects: [
    { word: 'effect', word_class: 'N' },
    { word: 'effect', word_class: 'V' },
    { word: 'effects', word_class: 'N' },
    { word: 'effector', word_class: 'N' },
    { word: 'effected', word_class: 'AJ' },
    { word: 'effective', word_class: 'AJ' },
    { word: 'effectivity', word_class: 'N' },
    { word: 'effectively', word_class: 'AV' },
    { word: 'effectiveness', word_class: 'N' }
  ],
  glowering: [
    { word: 'glower', word_class: 'N' },
    { word: 'glower', word_class: 'V' },
    { word: 'glowering', word_class: 'AJ' }
  ],
  shouted: [
    { word: 'shout', word_class: 'N' },
    { word: 'shout', word_class: 'V' },
    { word: 'shouter', word_class: 'N' },
    { word: 'shouted', word_class: 'AJ' },
    { word: 'shouting', word_class: 'N' },
    { word: 'shouting', word_class: 'AJ' }
  ],
  stalk: [
    { word: 'stalk', word_class: 'N' },
    { word: 'stalk', word_class: 'V' },
    { word: 'stalker', word_class: 'N' },
    { word: 'stalked', word_class: 'AJ' },
    { word: 'stalking', word_class: 'N' },
    { word: 'stalking', word_class: 'AJ' }
  ],
  dignifying: [
    { word: 'dignify', word_class: 'V' },
    { word: 'dignified', word_class: 'AJ' },
    { word: 'dignifying', word_class: 'AJ' },
    { word: 'dignification', word_class: 'N' }
  ],
  convertor: [
    { word: 'convert', word_class: 'N' },
    { word: 'convert', word_class: 'V' },
    { word: 'converter', word_class: 'N' },
    { word: 'convertor', word_class: 'N' },
    { word: 'conversion', word_class: 'N' },
    { word: 'converted', word_class: 'AJ' },
    { word: 'converting', word_class: 'N' },
    { word: 'convertible', word_class: 'N' },
    { word: 'convertible', word_class: 'AJ' },
    { word: 'convertibility', word_class: 'N' }
  ],
  pauperization: [
    { word: 'pauper', word_class: 'N' },
    { word: 'pauper', word_class: 'V' },
    { word: 'pauperism', word_class: 'N' },
    { word: 'pauperize', word_class: 'V' },
    { word: 'pauperization', word_class: 'N' }
  ],
  organically: [
    { word: 'organ', word_class: 'N' },
    { word: 'organs', word_class: 'N' },
    { word: 'organic', word_class: 'N' },
    { word: 'organize', word_class: 'V' },
    { word: 'organist', word_class: 'N' },
    { word: 'organic', word_class: 'AJ' },
    { word: 'organism', word_class: 'N' },
    { word: 'organizer', word_class: 'N' },
    { word: 'organized', word_class: 'AJ' },
    { word: 'organically', word_class: 'AV' },
    { word: 'organization', word_class: 'N' }
  ],
  brusque: [
    { word: 'brusque', word_class: 'AJ' },
    { word: 'brusquely', word_class: 'AV' },
    { word: 'brusqueness', word_class: 'N' }
  ],
  vulgarise: [
    { word: 'vulgar', word_class: 'AJ' },
    { word: 'vulgarise', word_class: 'V' },
    { word: 'vulgarism', word_class: 'N' },
    { word: 'vulgarize', word_class: 'V' },
    { word: 'vulgarity', word_class: 'N' },
    { word: 'vulgarisation', word_class: 'N' },
    { word: 'vulgarization', word_class: 'N' }
  ],
  parental: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  written: [
    { word: 'write', word_class: 'V' },
    { word: 'writer', word_class: 'N' },
    { word: 'writing', word_class: 'N' },
    { word: 'writings', word_class: 'N' },
    { word: 'written', word_class: 'AJ' }
  ],
  tartarize: [
    { word: 'tartar', word_class: 'N' },
    { word: 'tartarise', word_class: 'V' },
    { word: 'tartaric', word_class: 'AJ' },
    { word: 'tartarize', word_class: 'V' },
    { word: 'tartarisation', word_class: 'N' },
    { word: 'tartarization', word_class: 'N' }
  ],
  intermittency: [
    { word: 'intermittence', word_class: 'N' },
    { word: 'intermittency', word_class: 'N' },
    { word: 'intermittent', word_class: 'AJ' },
    { word: 'intermittently', word_class: 'AV' }
  ],
  infer: [
    { word: 'infer', word_class: 'V' },
    { word: 'inferral', word_class: 'N' },
    { word: 'inference', word_class: 'N' },
    { word: 'inferable', word_class: 'AJ' },
    { word: 'inferential', word_class: 'AJ' }
  ],
  letter: [
    { word: 'letter', word_class: 'N' },
    { word: 'letter', word_class: 'V' },
    { word: 'letters', word_class: 'N' },
    { word: 'lettered', word_class: 'AJ' },
    { word: 'lettering', word_class: 'N' },
    { word: 'letterman', word_class: 'N' }
  ],
  goddamned: [
    { word: 'goddamn', word_class: 'AJ' },
    { word: 'goddamn', word_class: 'AV' },
    { word: 'goddamned', word_class: 'AJ' },
    { word: 'goddamned', word_class: 'AV' }
  ],
  gangrenous: [
    { word: 'gangrene', word_class: 'N' },
    { word: 'gangrene', word_class: 'V' },
    { word: 'gangrenous', word_class: 'AJ' }
  ],
  illude: [
    { word: 'illude', word_class: 'V' },
    { word: 'illusion', word_class: 'N' },
    { word: 'illusive', word_class: 'AJ' },
    { word: 'illusionary', word_class: 'AJ' }
  ],
  inward: [
    { word: 'inward', word_class: 'N' },
    { word: 'inward', word_class: 'V' },
    { word: 'inward', word_class: 'AJ' },
    { word: 'inward', word_class: 'AV' },
    { word: 'inwards', word_class: 'AV' },
    { word: 'inwardness', word_class: 'N' }
  ],
  solely: [
    { word: 'sole', word_class: 'N' },
    { word: 'sole', word_class: 'V' },
    { word: 'sole', word_class: 'AJ' },
    { word: 'soled', word_class: 'AJ' },
    { word: 'solely', word_class: 'AV' }
  ],
  sermon: [
    { word: 'sermon', word_class: 'N' },
    { word: 'sermonize', word_class: 'V' },
    { word: 'sermonizer', word_class: 'N' },
    { word: 'sermonization', word_class: 'N' }
  ],
  splattered: [
    { word: 'splatter', word_class: 'N' },
    { word: 'splatter', word_class: 'V' },
    { word: 'splattered', word_class: 'AJ' },
    { word: 'splattering', word_class: 'N' }
  ],
  forestry: [
    { word: 'forest', word_class: 'N' },
    { word: 'forest', word_class: 'V' },
    { word: 'forester', word_class: 'N' },
    { word: 'forestry', word_class: 'N' },
    { word: 'forested', word_class: 'AJ' }
  ],
  chromatism: [
    { word: 'chromate', word_class: 'N' },
    { word: 'chromatic', word_class: 'AJ' },
    { word: 'chromatism', word_class: 'N' },
    { word: 'chromaticity', word_class: 'N' },
    { word: 'chromatically', word_class: 'AV' }
  ],
  assistant: [
    { word: 'assist', word_class: 'N' },
    { word: 'assist', word_class: 'V' },
    { word: 'assistant', word_class: 'N' },
    { word: 'assisted', word_class: 'AJ' },
    { word: 'assistance', word_class: 'N' },
    { word: 'assistant', word_class: 'AJ' },
    { word: 'assistive', word_class: 'AJ' }
  ],
  absence: [
    { word: 'absent', word_class: 'V' },
    { word: 'absence', word_class: 'N' },
    { word: 'absent', word_class: 'AJ' },
    { word: 'absentee', word_class: 'N' },
    { word: 'absently', word_class: 'AV' },
    { word: 'absenteeism', word_class: 'N' }
  ],
  insure: [
    { word: 'insure', word_class: 'V' },
    { word: 'insure', word_class: 'AJ' },
    { word: 'insurer', word_class: 'N' },
    { word: 'insured', word_class: 'AJ' },
    { word: 'insurance', word_class: 'N' }
  ],
  permutability: [
    { word: 'permute', word_class: 'V' },
    { word: 'permutation', word_class: 'N' },
    { word: 'permutability', word_class: 'N' },
    { word: 'permutableness', word_class: 'N' }
  ],
  dynamics: [
    { word: 'dynamic', word_class: 'N' },
    { word: 'dynamic', word_class: 'AJ' },
    { word: 'dynamics', word_class: 'N' },
    { word: 'dynamism', word_class: 'N' },
    { word: 'dynamize', word_class: 'V' },
    { word: 'dynamical', word_class: 'AJ' },
    { word: 'dynamically', word_class: 'AV' }
  ],
  induration: [
    { word: 'indurate', word_class: 'V' },
    { word: 'indurate', word_class: 'AJ' },
    { word: 'induration', word_class: 'N' }
  ],
  challenge: [
    { word: 'challenge', word_class: 'N' },
    { word: 'challenge', word_class: 'V' },
    { word: 'challenger', word_class: 'N' },
    { word: 'challenging', word_class: 'AJ' },
    { word: 'challengeable', word_class: 'AJ' }
  ],
  disown: [
    { word: 'disown', word_class: 'V' },
    { word: 'disowned', word_class: 'AJ' },
    { word: 'disowning', word_class: 'N' },
    { word: 'disownment', word_class: 'N' }
  ],
  obliging: [
    { word: 'oblige', word_class: 'V' },
    { word: 'obligate', word_class: 'V' },
    { word: 'obliged', word_class: 'AJ' },
    { word: 'obligate', word_class: 'AJ' },
    { word: 'obliging', word_class: 'AJ' },
    { word: 'obligated', word_class: 'AJ' },
    { word: 'obligation', word_class: 'N' },
    { word: 'obligational', word_class: 'AJ' }
  ],
  stiffness: [
    { word: 'stiff', word_class: 'N' },
    { word: 'stiff', word_class: 'AJ' },
    { word: 'stiff', word_class: 'AV' },
    { word: 'stiffness', word_class: 'N' }
  ],
  municipalisation: [
    { word: 'municipal', word_class: 'AJ' },
    { word: 'municipalise', word_class: 'V' },
    { word: 'municipalize', word_class: 'V' },
    { word: 'municipality', word_class: 'N' },
    { word: 'municipally', word_class: 'AV' },
    { word: 'municipalisation', word_class: 'N' },
    { word: 'municipalization', word_class: 'N' }
  ],
  satiate: [
    { word: 'satiate', word_class: 'V' },
    { word: 'satiate', word_class: 'AJ' },
    { word: 'satiated', word_class: 'AJ' },
    { word: 'satiation', word_class: 'N' }
  ],
  proportionment: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  crunch: [
    { word: 'crunch', word_class: 'N' },
    { word: 'crunch', word_class: 'V' },
    { word: 'crunched', word_class: 'AJ' }
  ],
  highjacking: [
    { word: 'highjack', word_class: 'N' },
    { word: 'highjack', word_class: 'V' },
    { word: 'highjacker', word_class: 'N' },
    { word: 'highjacking', word_class: 'N' }
  ],
  stimulating: [
    { word: 'stimulant', word_class: 'N' },
    { word: 'stimulate', word_class: 'V' },
    { word: 'stimulant', word_class: 'AJ' },
    { word: 'stimulated', word_class: 'AJ' },
    { word: 'stimulation', word_class: 'N' },
    { word: 'stimulating', word_class: 'AJ' },
    { word: 'stimulative', word_class: 'AJ' }
  ],
  conventionality: [
    { word: 'conventioneer', word_class: 'N' },
    { word: 'conventionalise', word_class: 'V' },
    { word: 'conventionality', word_class: 'N' },
    { word: 'conventionalize', word_class: 'V' },
    { word: 'conventionally', word_class: 'AV' },
    { word: 'conventionalism', word_class: 'N' },
    { word: 'conventionalized', word_class: 'AJ' },
    { word: 'conventionalisation', word_class: 'N' },
    { word: 'conventionalization', word_class: 'N' }
  ],
  cabalism: [
    { word: 'cabal', word_class: 'N' },
    { word: 'cabal', word_class: 'V' },
    { word: 'cabalism', word_class: 'N' },
    { word: 'cabalist', word_class: 'N' },
    { word: 'cabalistic', word_class: 'AJ' }
  ],
  preservation: [
    { word: 'preserve', word_class: 'N' },
    { word: 'preserve', word_class: 'V' },
    { word: 'preserver', word_class: 'N' },
    { word: 'preserves', word_class: 'N' },
    { word: 'preserved', word_class: 'AJ' },
    { word: 'preserving', word_class: 'N' },
    { word: 'preserving', word_class: 'AJ' },
    { word: 'preservable', word_class: 'AJ' },
    { word: 'preservation', word_class: 'N' },
    { word: 'preservative', word_class: 'N' },
    { word: 'preservative', word_class: 'AJ' }
  ],
  ostracism: [
    { word: 'ostracism', word_class: 'N' },
    { word: 'ostracize', word_class: 'V' },
    { word: 'ostracization', word_class: 'N' }
  ],
  norm: [
    { word: 'norm', word_class: 'N' },
    { word: 'normalcy', word_class: 'N' },
    { word: 'normative', word_class: 'AJ' }
  ],
  puree: [
    { word: 'puree', word_class: 'V' },
    { word: 'puree', word_class: 'N' },
    { word: 'pure', word_class: 'AJ' },
    { word: 'purely', word_class: 'AV' },
    { word: 'pureness', word_class: 'N' }
  ],
  indiscriminately: [
    { word: 'indiscriminate', word_class: 'AJ' },
    { word: 'indiscriminately', word_class: 'AV' },
    { word: 'indiscriminating', word_class: 'AJ' }
  ],
  steam: [
    { word: 'steam', word_class: 'N' },
    { word: 'steam', word_class: 'V' },
    { word: 'steamer', word_class: 'N' },
    { word: 'steamed', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AJ' },
    { word: 'steaming', word_class: 'AV' },
    { word: 'steamship', word_class: 'N' }
  ],
  lending: [
    { word: 'lend', word_class: 'V' },
    { word: 'lender', word_class: 'N' },
    { word: 'lending', word_class: 'N' }
  ],
  calculation: [
    { word: 'calculate', word_class: 'V' },
    { word: 'calculator', word_class: 'N' },
    { word: 'calculous', word_class: 'AJ' },
    { word: 'calculable', word_class: 'AJ' },
    { word: 'calculably', word_class: 'AV' },
    { word: 'calculated', word_class: 'AJ' },
    { word: 'calculation', word_class: 'N' },
    { word: 'calculating', word_class: 'AJ' },
    { word: 'calculative', word_class: 'AJ' },
    { word: 'calculability', word_class: 'N' },
    { word: 'calculability', word_class: 'AJ' }
  ],
  concavely: [
    { word: 'concave', word_class: 'AJ' },
    { word: 'concavity', word_class: 'N' },
    { word: 'concavely', word_class: 'AV' },
    { word: 'concaveness', word_class: 'N' }
  ],
  snuggly: [
    { word: 'snuggle', word_class: 'N' },
    { word: 'snuggle', word_class: 'V' },
    { word: 'snuggly', word_class: 'AJ' },
    { word: 'snuggled', word_class: 'AJ' },
    { word: 'snuggling', word_class: 'N' }
  ],
  irreversibly: [
    { word: 'irreversible', word_class: 'AJ' },
    { word: 'irreversibly', word_class: 'AV' },
    { word: 'irreversibility', word_class: 'N' }
  ],
  sulfurize: [
    { word: 'sulfur', word_class: 'N' },
    { word: 'sulfuric', word_class: 'AJ' },
    { word: 'sulfurize', word_class: 'V' },
    { word: 'sulfurous', word_class: 'AJ' },
    { word: 'sulfurized', word_class: 'AJ' },
    { word: 'sulfurization', word_class: 'N' }
  ],
  marbling: [
    { word: 'marble', word_class: 'N' },
    { word: 'marble', word_class: 'V' },
    { word: 'marbles', word_class: 'N' },
    { word: 'marbled', word_class: 'AJ' },
    { word: 'marbling', word_class: 'N' }
  ],
  republicanization: [
    { word: 'republican', word_class: 'N' },
    { word: 'republican', word_class: 'AJ' },
    { word: 'republicanise', word_class: 'V' },
    { word: 'republicanism', word_class: 'N' },
    { word: 'republicanize', word_class: 'V' },
    { word: 'republicanisation', word_class: 'N' },
    { word: 'republicanization', word_class: 'N' }
  ],
  amateurism: [
    { word: 'amateur', word_class: 'N' },
    { word: 'amateur', word_class: 'AJ' },
    { word: 'amateurism', word_class: 'N' }
  ],
  whitening: [
    { word: 'whiten', word_class: 'V' },
    { word: 'whitener', word_class: 'N' },
    { word: 'whitened', word_class: 'AJ' },
    { word: 'whitening', word_class: 'N' }
  ],
  wording: [
    { word: 'word', word_class: 'N' },
    { word: 'word', word_class: 'V' },
    { word: 'words', word_class: 'N' },
    { word: 'wording', word_class: 'N' }
  ],
  pleading: [
    { word: 'plea', word_class: 'N' },
    { word: 'plead', word_class: 'V' },
    { word: 'pleading', word_class: 'N' },
    { word: 'pleading', word_class: 'AJ' }
  ],
  impassion: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  incompetently: [
    { word: 'incompetent', word_class: 'N' },
    { word: 'incompetence', word_class: 'N' },
    { word: 'incompetency', word_class: 'N' },
    { word: 'incompetent', word_class: 'AJ' },
    { word: 'incompetently', word_class: 'AV' }
  ],
  acquire: [
    { word: 'acquire', word_class: 'V' },
    { word: 'acquirer', word_class: 'N' },
    { word: 'acquired', word_class: 'AJ' },
    { word: 'acquiring', word_class: 'N' },
    { word: 'acquirement', word_class: 'N' },
    { word: 'acquirable', word_class: 'AJ' },
    { word: 'acquisition', word_class: 'N' },
    { word: 'acquisitive', word_class: 'AJ' },
    { word: 'acquisitiveness', word_class: 'N' }
  ],
  daily: [
    { word: 'day', word_class: 'N' },
    { word: 'dais', word_class: 'N' },
    { word: 'days', word_class: 'N' },
    { word: 'daily', word_class: 'N' },
    { word: 'daily', word_class: 'AJ' },
    { word: 'daily', word_class: 'AV' }
  ],
  penitence: [
    { word: 'penitent', word_class: 'N' },
    { word: 'penitence', word_class: 'N' },
    { word: 'penitent', word_class: 'AJ' },
    { word: 'penitently', word_class: 'AV' },
    { word: 'penitential', word_class: 'AJ' },
    { word: 'penitentially', word_class: 'AV' }
  ],
  disembark: [
    { word: 'disembark', word_class: 'V' },
    { word: 'disembarkment', word_class: 'N' },
    { word: 'disembarkation', word_class: 'N' }
  ],
  discord: [
    { word: 'discord', word_class: 'N' },
    { word: 'discord', word_class: 'V' },
    { word: 'discordant', word_class: 'N' },
    { word: 'discordance', word_class: 'N' },
    { word: 'discordant', word_class: 'AJ' }
  ],
  disjointed: [
    { word: 'disjoint', word_class: 'N' },
    { word: 'disjoint', word_class: 'V' },
    { word: 'disjoint', word_class: 'AJ' },
    { word: 'disjointed', word_class: 'AJ' }
  ],
  factorization: [
    { word: 'factor', word_class: 'N' },
    { word: 'factor', word_class: 'V' },
    { word: 'factorise', word_class: 'V' },
    { word: 'factorize', word_class: 'V' },
    { word: 'factoring', word_class: 'N' },
    { word: 'factorisation', word_class: 'N' },
    { word: 'factorization', word_class: 'N' }
  ],
  regulator: [
    { word: 'regulate', word_class: 'V' },
    { word: 'regulator', word_class: 'N' },
    { word: 'regulation', word_class: 'N' },
    { word: 'regulated', word_class: 'AJ' },
    { word: 'regulating', word_class: 'N' },
    { word: 'regulating', word_class: 'AJ' },
    { word: 'regulation', word_class: 'AJ' },
    { word: 'regulative', word_class: 'AJ' }
  ],
  caressing: [
    { word: 'caress', word_class: 'N' },
    { word: 'caress', word_class: 'V' },
    { word: 'caressing', word_class: 'N' },
    { word: 'caressing', word_class: 'AJ' },
    { word: 'caressive', word_class: 'AJ' }
  ],
  fornicate: [
    { word: 'fornicate', word_class: 'V' },
    { word: 'fornicator', word_class: 'N' },
    { word: 'fornication', word_class: 'N' }
  ],
  require: [
    { word: 'require', word_class: 'V' },
    { word: 'required', word_class: 'AJ' },
    { word: 'requisite', word_class: 'N' },
    { word: 'requisite', word_class: 'AJ' },
    { word: 'requirement', word_class: 'N' },
    { word: 'requisition', word_class: 'N' },
    { word: 'requisition', word_class: 'V' },
    { word: 'requisiteness', word_class: 'N' }
  ],
  softhearted: [
    { word: 'softhearted', word_class: 'AJ' },
    { word: 'softheartedness', word_class: 'N' },
    { word: 'softheartedness', word_class: 'AJ' }
  ],
  patron: [
    { word: 'patron', word_class: 'N' },
    { word: 'patronage', word_class: 'N' },
    { word: 'patronage', word_class: 'V' },
    { word: 'patronise', word_class: 'V' },
    { word: 'patronize', word_class: 'V' },
    { word: 'patronized', word_class: 'AJ' },
    { word: 'patronising', word_class: 'AJ' },
    { word: 'patronizing', word_class: 'AJ' },
    { word: 'patronization', word_class: 'N' }
  ],
  vindicated: [
    { word: 'vindicate', word_class: 'V' },
    { word: 'vindicator', word_class: 'N' },
    { word: 'vindicable', word_class: 'AJ' },
    { word: 'vindicated', word_class: 'AJ' },
    { word: 'vindication', word_class: 'N' }
  ],
  squinting: [
    { word: 'squint', word_class: 'N' },
    { word: 'squint', word_class: 'V' },
    { word: 'squint', word_class: 'AJ' },
    { word: 'squinty', word_class: 'AJ' },
    { word: 'squinting', word_class: 'AJ' }
  ],
  motherly: [
    { word: 'motherly', word_class: 'AJ' },
    { word: 'motherly', word_class: 'AV' },
    { word: 'motherliness', word_class: 'N' }
  ],
  load: [
    { word: 'load', word_class: 'N' },
    { word: 'load', word_class: 'V' },
    { word: 'loads', word_class: 'N' },
    { word: 'loader', word_class: 'N' },
    { word: 'loaded', word_class: 'AJ' },
    { word: 'loading', word_class: 'N' },
    { word: 'loading', word_class: 'AJ' }
  ],
  hypothetically: [
    { word: 'hypothetic', word_class: 'AJ' },
    { word: 'hypothetical', word_class: 'AJ' },
    { word: 'hypothetically', word_class: 'AV' }
  ],
  insulting: [
    { word: 'insult', word_class: 'N' },
    { word: 'insult', word_class: 'V' },
    { word: 'insulted', word_class: 'AJ' },
    { word: 'insulting', word_class: 'AJ' }
  ],
  mythic: [
    { word: 'mythic', word_class: 'AJ' },
    { word: 'mythical', word_class: 'AJ' },
    { word: 'mythicise', word_class: 'V' },
    { word: 'mythicize', word_class: 'V' },
    { word: 'mythicisation', word_class: 'N' },
    { word: 'mythicization', word_class: 'N' }
  ],
  reverentially: [
    { word: 'revere', word_class: 'N' },
    { word: 'revere', word_class: 'V' },
    { word: 'revers', word_class: 'N' },
    { word: 'revered', word_class: 'AJ' },
    { word: 'reverence', word_class: 'N' },
    { word: 'reverence', word_class: 'V' },
    { word: 'reverent', word_class: 'AJ' },
    { word: 'reverently', word_class: 'AV' },
    { word: 'reverential', word_class: 'AJ' },
    { word: 'reverentially', word_class: 'AV' }
  ],
  unwise: [
    { word: 'unwise', word_class: 'AJ' },
    { word: 'unwisely', word_class: 'AV' },
    { word: 'unwiseness', word_class: 'N' }
  ],
  incurable: [
    { word: 'incur', word_class: 'N' },
    { word: 'incur', word_class: 'V' },
    { word: 'incurable', word_class: 'N' },
    { word: 'incurment', word_class: 'N' },
    { word: 'incurring', word_class: 'N' },
    { word: 'incursion', word_class: 'N' },
    { word: 'incurrence', word_class: 'N' },
    { word: 'incurable', word_class: 'AJ' },
    { word: 'incurably', word_class: 'AV' },
    { word: 'incursive', word_class: 'AJ' },
    { word: 'incurability', word_class: 'N' },
    { word: 'incurableness', word_class: 'N' }
  ],
  sunburned: [
    { word: 'sunburn', word_class: 'N' },
    { word: 'sunburn', word_class: 'V' },
    { word: 'sunburned', word_class: 'AJ' }
  ],
  mailed: [
    { word: 'mail', word_class: 'N' },
    { word: 'mail', word_class: 'V' },
    { word: 'mailer', word_class: 'N' },
    { word: 'mailman', word_class: 'N' },
    { word: 'mailing', word_class: 'N' },
    { word: 'mailed', word_class: 'AJ' }
  ],
  malleably: [
    { word: 'malleable', word_class: 'AJ' },
    { word: 'malleably', word_class: 'AV' },
    { word: 'malleability', word_class: 'N' }
  ],
  speckle: [
    { word: 'speckle', word_class: 'N' },
    { word: 'speckle', word_class: 'V' },
    { word: 'speckled', word_class: 'AJ' }
  ],
  plumbed: [
    { word: 'plumb', word_class: 'N' },
    { word: 'plumb', word_class: 'V' },
    { word: 'plumb', word_class: 'AJ' },
    { word: 'plumb', word_class: 'AV' },
    { word: 'plumber', word_class: 'N' },
    { word: 'plumbed', word_class: 'AJ' },
    { word: 'plumbing', word_class: 'N' },
    { word: 'plumbism', word_class: 'N' }
  ],
  penalize: [
    { word: 'penal', word_class: 'AJ' },
    { word: 'penalise', word_class: 'V' },
    { word: 'penalize', word_class: 'V' },
    { word: 'penally', word_class: 'AV' },
    { word: 'penalized', word_class: 'AJ' },
    { word: 'penalisation', word_class: 'N' },
    { word: 'penalization', word_class: 'N' }
  ],
  hyphenization: [
    { word: 'hyphen', word_class: 'N' },
    { word: 'hyphen', word_class: 'V' },
    { word: 'hyphenate', word_class: 'V' },
    { word: 'hyphenize', word_class: 'V' },
    { word: 'hyphenated', word_class: 'AJ' },
    { word: 'hyphenation', word_class: 'N' },
    { word: 'hyphenization', word_class: 'N' }
  ],
  chained: [
    { word: 'chain', word_class: 'N' },
    { word: 'chain', word_class: 'V' },
    { word: 'chains', word_class: 'N' },
    { word: 'chained', word_class: 'AJ' }
  ],
  intertwinement: [
    { word: 'intertwine', word_class: 'N' },
    { word: 'intertwine', word_class: 'V' },
    { word: 'intertwined', word_class: 'AJ' },
    { word: 'intertwinement', word_class: 'N' }
  ],
  splice: [
    { word: 'splice', word_class: 'N' },
    { word: 'splice', word_class: 'V' },
    { word: 'splicer', word_class: 'N' },
    { word: 'splicing', word_class: 'N' }
  ],
  silenced: [
    { word: 'silence', word_class: 'N' },
    { word: 'silence', word_class: 'V' },
    { word: 'silent', word_class: 'AJ' },
    { word: 'silencer', word_class: 'N' },
    { word: 'silenced', word_class: 'AJ' },
    { word: 'silently', word_class: 'AV' }
  ],
  molested: [
    { word: 'molest', word_class: 'V' },
    { word: 'molester', word_class: 'N' },
    { word: 'molested', word_class: 'AJ' },
    { word: 'molestation', word_class: 'N' }
  ],
  kicker: [
    { word: 'kick', word_class: 'N' },
    { word: 'kick', word_class: 'V' },
    { word: 'kicker', word_class: 'N' },
    { word: 'kicking', word_class: 'N' }
  ],
  infixion: [
    { word: 'infix', word_class: 'N' },
    { word: 'infix', word_class: 'V' },
    { word: 'infixion', word_class: 'N' },
    { word: 'infixation', word_class: 'N' }
  ],
  bondman: [
    { word: 'bond', word_class: 'N' },
    { word: 'bond', word_class: 'V' },
    { word: 'bond', word_class: 'AJ' },
    { word: 'bonded', word_class: 'AJ' },
    { word: 'bonding', word_class: 'N' },
    { word: 'bondman', word_class: 'N' }
  ],
  illegitimate: [
    { word: 'illegitimate', word_class: 'N' },
    { word: 'illegitimate', word_class: 'AJ' },
    { word: 'illegitimately', word_class: 'AV' }
  ],
  stinger: [
    { word: 'sting', word_class: 'N' },
    { word: 'sting', word_class: 'V' },
    { word: 'stinger', word_class: 'N' },
    { word: 'stinging', word_class: 'N' },
    { word: 'stinging', word_class: 'AJ' }
  ],
  barrack: [
    { word: 'barrack', word_class: 'N' },
    { word: 'barrack', word_class: 'V' },
    { word: 'barracks', word_class: 'N' },
    { word: 'barracking', word_class: 'N' }
  ],
  censurable: [
    { word: 'censure', word_class: 'N' },
    { word: 'censure', word_class: 'V' },
    { word: 'censured', word_class: 'AJ' },
    { word: 'censurable', word_class: 'AJ' }
  ],
  tightness: [
    { word: 'tight', word_class: 'AJ' },
    { word: 'tight', word_class: 'AV' },
    { word: 'tights', word_class: 'N' },
    { word: 'tightness', word_class: 'N' }
  ],
  defeatism: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  impasseable: [
    { word: 'impasse', word_class: 'N' },
    { word: 'impasse', word_class: 'AJ' },
    { word: 'impassion', word_class: 'N' },
    { word: 'impassion', word_class: 'V' },
    { word: 'impassive', word_class: 'AJ' },
    { word: 'impassable', word_class: 'AJ' },
    { word: 'impassivity', word_class: 'N' },
    { word: 'impasseable', word_class: 'AJ' },
    { word: 'impassioned', word_class: 'AJ' },
    { word: 'impassively', word_class: 'AV' },
    { word: 'impassiveness', word_class: 'N' }
  ],
  partialness: [
    { word: 'partial', word_class: 'AJ' },
    { word: 'partiality', word_class: 'N' },
    { word: 'partially', word_class: 'AV' },
    { word: 'partialness', word_class: 'N' }
  ],
  equatability: [
    { word: 'equate', word_class: 'V' },
    { word: 'equator', word_class: 'N' },
    { word: 'equating', word_class: 'N' },
    { word: 'equation', word_class: 'N' },
    { word: 'equatability', word_class: 'N' }
  ],
  geographer: [
    { word: 'geographer', word_class: 'N' },
    { word: 'geographic', word_class: 'AJ' },
    { word: 'geographics', word_class: 'N' },
    { word: 'geographical', word_class: 'AJ' },
    { word: 'geographically', word_class: 'AV' }
  ],
  despisal: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  disapprove: [
    { word: 'disapprove', word_class: 'V' },
    { word: 'disapproval', word_class: 'N' },
    { word: 'disapproved', word_class: 'AJ' },
    { word: 'disapproving', word_class: 'AJ' }
  ],
  horsewhipping: [
    { word: 'horsewhip', word_class: 'N' },
    { word: 'horsewhip', word_class: 'V' },
    { word: 'horsewhipping', word_class: 'N' }
  ],
  cajole: [
    { word: 'cajole', word_class: 'V' },
    { word: 'cajolery', word_class: 'N' },
    { word: 'cajolement', word_class: 'N' }
  ],
  diversely: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  moaner: [
    { word: 'moan', word_class: 'N' },
    { word: 'moan', word_class: 'V' },
    { word: 'moaner', word_class: 'N' },
    { word: 'moaning', word_class: 'AJ' }
  ],
  screen: [
    { word: 'screen', word_class: 'N' },
    { word: 'screen', word_class: 'V' },
    { word: 'screening', word_class: 'N' }
  ],
  bliss: [
    { word: 'bliss', word_class: 'N' },
    { word: 'blissful', word_class: 'AJ' },
    { word: 'blissfulness', word_class: 'N' }
  ],
  reflecting: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  intensive: [
    { word: 'intense', word_class: 'N' },
    { word: 'intense', word_class: 'V' },
    { word: 'intense', word_class: 'AJ' },
    { word: 'intension', word_class: 'N' },
    { word: 'intensity', word_class: 'N' },
    { word: 'intensive', word_class: 'N' },
    { word: 'intensely', word_class: 'AV' },
    { word: 'intensive', word_class: 'AJ' },
    { word: 'intensively', word_class: 'AV' },
    { word: 'intensiveness', word_class: 'N' }
  ],
  choice: [
    { word: 'choice', word_class: 'N' },
    { word: 'choose', word_class: 'V' },
    { word: 'chosen', word_class: 'N' },
    { word: 'choosy', word_class: 'AJ' },
    { word: 'choice', word_class: 'AJ' },
    { word: 'chosen', word_class: 'AJ' },
    { word: 'choiceness', word_class: 'N' }
  ],
  gesticulate: [
    { word: 'gesticulate', word_class: 'V' },
    { word: 'gesticulation', word_class: 'N' },
    { word: 'gesticulating', word_class: 'AJ' }
  ],
  pustulation: [
    { word: 'pustule', word_class: 'N' },
    { word: 'pustulate', word_class: 'V' },
    { word: 'pustulate', word_class: 'AJ' },
    { word: 'pustulation', word_class: 'N' }
  ],
  monocotyledones: [
    { word: 'monocotyledon', word_class: 'N' },
    { word: 'monocotyledones', word_class: 'N' },
    { word: 'monocotyledonous', word_class: 'AJ' }
  ],
  escalate: [
    { word: 'escalate', word_class: 'V' },
    { word: 'escalator', word_class: 'N' },
    { word: 'escalation', word_class: 'N' }
  ],
  uprisen: [
    { word: 'uprise', word_class: 'V' },
    { word: 'uprisen', word_class: 'AJ' },
    { word: 'uprising', word_class: 'N' }
  ],
  restively: [
    { word: 'restive', word_class: 'AJ' },
    { word: 'restively', word_class: 'AV' },
    { word: 'restiveness', word_class: 'N' }
  ],
  nourished: [
    { word: 'nourish', word_class: 'V' },
    { word: 'nourished', word_class: 'AJ' },
    { word: 'nourishing', word_class: 'N' },
    { word: 'nourishing', word_class: 'AJ' },
    { word: 'nourishment', word_class: 'N' }
  ],
  prefabricate: [
    { word: 'prefabricate', word_class: 'V' },
    { word: 'prefabricated', word_class: 'AJ' },
    { word: 'prefabrication', word_class: 'N' }
  ],
  transistorise: [
    { word: 'transistor', word_class: 'N' },
    { word: 'transistorise', word_class: 'V' },
    { word: 'transistorize', word_class: 'V' },
    { word: 'transistorized', word_class: 'AJ' },
    { word: 'transistorization', word_class: 'N' }
  ],
  leisure: [
    { word: 'leisure', word_class: 'N' },
    { word: 'leisure', word_class: 'AJ' },
    { word: 'leisured', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AJ' },
    { word: 'leisurely', word_class: 'AV' }
  ],
  volubility: [
    { word: 'voluble', word_class: 'AJ' },
    { word: 'volubly', word_class: 'AV' },
    { word: 'volubility', word_class: 'N' }
  ],
  sport: [
    { word: 'sport', word_class: 'N' },
    { word: 'sport', word_class: 'V' },
    { word: 'sport', word_class: 'AJ' },
    { word: 'sports', word_class: 'N' },
    { word: 'sporting', word_class: 'AJ' }
  ],
  empire: [
    { word: 'empire', word_class: 'N' },
    { word: 'empiric', word_class: 'AJ' },
    { word: 'empirical', word_class: 'AJ' },
    { word: 'empirically', word_class: 'AV' }
  ],
  barman: [
    { word: 'bar', word_class: 'N' },
    { word: 'bar', word_class: 'V' },
    { word: 'bars', word_class: 'N' },
    { word: 'barman', word_class: 'N' },
    { word: 'barred', word_class: 'AJ' },
    { word: 'barring', word_class: 'N' }
  ],
  refulgence: [
    { word: 'refulgence', word_class: 'N' },
    { word: 'refulgency', word_class: 'N' },
    { word: 'refulgent', word_class: 'AJ' }
  ],
  homogeneity: [
    { word: 'homogenate', word_class: 'N' },
    { word: 'homogenise', word_class: 'V' },
    { word: 'homogenize', word_class: 'V' },
    { word: 'homogeneity', word_class: 'N' },
    { word: 'homogeneous', word_class: 'AJ' },
    { word: 'homogenized', word_class: 'AJ' },
    { word: 'homogeneously', word_class: 'AV' },
    { word: 'homogenisation', word_class: 'N' },
    { word: 'homogenization', word_class: 'N' },
    { word: 'homogeneousness', word_class: 'N' }
  ],
  tetanization: [
    { word: 'tetanise', word_class: 'V' },
    { word: 'tetanize', word_class: 'V' },
    { word: 'tetanisation', word_class: 'N' },
    { word: 'tetanization', word_class: 'N' }
  ],
  decapitated: [
    { word: 'decapitate', word_class: 'V' },
    { word: 'decapitated', word_class: 'AJ' },
    { word: 'decapitation', word_class: 'N' }
  ],
  contorsion: [
    { word: 'contort', word_class: 'V' },
    { word: 'contorsion', word_class: 'N' },
    { word: 'contorted', word_class: 'AJ' },
    { word: 'contortion', word_class: 'N' }
  ],
  inconsistent: [
    { word: 'inconsistency', word_class: 'N' },
    { word: 'inconsistent', word_class: 'AJ' },
    { word: 'inconsistently', word_class: 'AV' }
  ],
  variolization: [
    { word: 'variolate', word_class: 'V' },
    { word: 'variolation', word_class: 'N' },
    { word: 'variolisation', word_class: 'N' },
    { word: 'variolization', word_class: 'N' }
  ],
  isomorphism: [
    { word: 'isomorphic', word_class: 'AJ' },
    { word: 'isomorphism', word_class: 'N' },
    { word: 'isomorphous', word_class: 'AJ' }
  ],
  hunting: [
    { word: 'hunt', word_class: 'N' },
    { word: 'hunt', word_class: 'V' },
    { word: 'hunter', word_class: 'N' },
    { word: 'hunted', word_class: 'AJ' },
    { word: 'hunting', word_class: 'N' }
  ],
  overweight: [
    { word: 'overweight', word_class: 'N' },
    { word: 'overweight', word_class: 'V' },
    { word: 'overweight', word_class: 'AJ' }
  ],
  paste: [
    { word: 'past', word_class: 'N' },
    { word: 'past', word_class: 'AJ' },
    { word: 'past', word_class: 'AV' },
    { word: 'paste', word_class: 'V' },
    { word: 'paste', word_class: 'N' },
    { word: 'pasty', word_class: 'N' },
    { word: 'pastis', word_class: 'N' },
    { word: 'pastor', word_class: 'N' },
    { word: 'pasty', word_class: 'AJ' },
    { word: 'pasted', word_class: 'AJ' },
    { word: 'pasties', word_class: 'N' },
    { word: 'pastness', word_class: 'N' },
    { word: 'pastoral', word_class: 'N' },
    { word: 'pastoral', word_class: 'AJ' },
    { word: 'pastorate', word_class: 'N' }
  ],
  perceive: [
    { word: 'percept', word_class: 'N' },
    { word: 'perceive', word_class: 'V' },
    { word: 'perceiver', word_class: 'N' },
    { word: 'perception', word_class: 'N' },
    { word: 'perceived', word_class: 'AJ' },
    { word: 'perceiving', word_class: 'N' },
    { word: 'perceptive', word_class: 'AJ' },
    { word: 'perceptible', word_class: 'AJ' },
    { word: 'perceptibly', word_class: 'AV' },
    { word: 'perceivable', word_class: 'AJ' },
    { word: 'perceptivity', word_class: 'N' },
    { word: 'perceptively', word_class: 'AV' },
    { word: 'perceptibility', word_class: 'N' },
    { word: 'perceptiveness', word_class: 'N' }
  ],
  frankness: [
    { word: 'frank', word_class: 'N' },
    { word: 'frank', word_class: 'V' },
    { word: 'frank', word_class: 'AJ' },
    { word: 'frankness', word_class: 'N' }
  ],
  promotive: [
    { word: 'promote', word_class: 'N' },
    { word: 'promote', word_class: 'V' },
    { word: 'promoter', word_class: 'N' },
    { word: 'promotion', word_class: 'N' },
    { word: 'promotive', word_class: 'AJ' },
    { word: 'promotional', word_class: 'AJ' }
  ],
  delusive: [
    { word: 'delude', word_class: 'V' },
    { word: 'delusion', word_class: 'N' },
    { word: 'delusive', word_class: 'AJ' },
    { word: 'delusively', word_class: 'AV' },
    { word: 'delusionary', word_class: 'AJ' }
  ],
  officiating: [
    { word: 'office', word_class: 'N' },
    { word: 'officer', word_class: 'N' },
    { word: 'officer', word_class: 'V' },
    { word: 'official', word_class: 'N' },
    { word: 'officiate', word_class: 'V' },
    { word: 'official', word_class: 'AJ' },
    { word: 'officious', word_class: 'AJ' },
    { word: 'officially', word_class: 'AV' },
    { word: 'officiating', word_class: 'N' },
    { word: 'officiation', word_class: 'N' },
    { word: 'officialize', word_class: 'V' },
    { word: 'officiously', word_class: 'AV' },
    { word: 'officiousness', word_class: 'N' }
  ],
  permeable: [
    { word: 'permeate', word_class: 'V' },
    { word: 'permeable', word_class: 'AJ' },
    { word: 'permeated', word_class: 'AJ' },
    { word: 'permeation', word_class: 'N' },
    { word: 'permeating', word_class: 'AJ' },
    { word: 'permeability', word_class: 'N' },
    { word: 'permeableness', word_class: 'N' }
  ],
  attractively: [
    { word: 'attract', word_class: 'V' },
    { word: 'attraction', word_class: 'N' },
    { word: 'attractive', word_class: 'AJ' },
    { word: 'attractable', word_class: 'AJ' },
    { word: 'attractively', word_class: 'AV' },
    { word: 'attractiveness', word_class: 'N' }
  ],
  fragmentary: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  vindicator: [
    { word: 'vindicate', word_class: 'V' },
    { word: 'vindicator', word_class: 'N' },
    { word: 'vindicable', word_class: 'AJ' },
    { word: 'vindicated', word_class: 'AJ' },
    { word: 'vindication', word_class: 'N' }
  ],
  responsible: [
    { word: 'respond', word_class: 'V' },
    { word: 'response', word_class: 'N' },
    { word: 'responder', word_class: 'N' },
    { word: 'respondent', word_class: 'N' },
    { word: 'respondent', word_class: 'AJ' },
    { word: 'responsive', word_class: 'AJ' },
    { word: 'responsible', word_class: 'AJ' },
    { word: 'responsibly', word_class: 'AV' },
    { word: 'responsibility', word_class: 'N' },
    { word: 'responsiveness', word_class: 'N' },
    { word: 'responsibleness', word_class: 'N' }
  ],
  deterrent: [
    { word: 'deter', word_class: 'V' },
    { word: 'determent', word_class: 'N' },
    { word: 'deterrent', word_class: 'N' },
    { word: 'deterrence', word_class: 'N' },
    { word: 'deterrent', word_class: 'AJ' }
  ],
  repose: [
    { word: 'repose', word_class: 'N' },
    { word: 'repose', word_class: 'V' },
    { word: 'reposal', word_class: 'N' },
    { word: 'reposeful', word_class: 'AJ' }
  ],
  matched: [
    { word: 'match', word_class: 'N' },
    { word: 'match', word_class: 'V' },
    { word: 'matched', word_class: 'AJ' },
    { word: 'matching', word_class: 'AJ' }
  ],
  accrual: [
    { word: 'accrue', word_class: 'V' },
    { word: 'accrual', word_class: 'N' },
    { word: 'accrued', word_class: 'AJ' },
    { word: 'accrument', word_class: 'N' },
    { word: 'accruement', word_class: 'N' }
  ],
  liberalization: [
    { word: 'liberal', word_class: 'N' },
    { word: 'liberal', word_class: 'AJ' },
    { word: 'liberate', word_class: 'V' },
    { word: 'liberator', word_class: 'N' },
    { word: 'liberalise', word_class: 'V' },
    { word: 'liberality', word_class: 'N' },
    { word: 'liberalize', word_class: 'V' },
    { word: 'liberalism', word_class: 'N' },
    { word: 'liberation', word_class: 'N' },
    { word: 'liberally', word_class: 'AV' },
    { word: 'liberated', word_class: 'AJ' },
    { word: 'liberalness', word_class: 'N' },
    { word: 'liberating', word_class: 'AJ' },
    { word: 'liberalisation', word_class: 'N' },
    { word: 'liberalization', word_class: 'N' }
  ],
  rapist: [
    { word: 'rape', word_class: 'N' },
    { word: 'rape', word_class: 'V' },
    { word: 'raper', word_class: 'N' },
    { word: 'raped', word_class: 'AJ' },
    { word: 'rapist', word_class: 'N' }
  ],
  opsonize: [
    { word: 'opsonize', word_class: 'V' },
    { word: 'opsonisation', word_class: 'N' },
    { word: 'opsonization', word_class: 'N' },
    { word: 'opsonification', word_class: 'N' }
  ],
  behead: [
    { word: 'behead', word_class: 'V' },
    { word: 'beheaded', word_class: 'AJ' },
    { word: 'beheading', word_class: 'N' }
  ],
  cocainization: [
    { word: 'cocain', word_class: 'N' },
    { word: 'cocaine', word_class: 'N' },
    { word: 'cocainise', word_class: 'V' },
    { word: 'cocainize', word_class: 'V' },
    { word: 'cocainisation', word_class: 'N' },
    { word: 'cocainization', word_class: 'N' }
  ],
  image: [
    { word: 'image', word_class: 'N' },
    { word: 'image', word_class: 'V' },
    { word: 'imagery', word_class: 'N' },
    { word: 'imagism', word_class: 'N' },
    { word: 'imagine', word_class: 'V' },
    { word: 'imaging', word_class: 'N' },
    { word: 'imagery', word_class: 'AJ' },
    { word: 'imagined', word_class: 'AJ' },
    { word: 'imaginary', word_class: 'AJ' },
    { word: 'imagination', word_class: 'N' },
    { word: 'imaginable', word_class: 'AJ' },
    { word: 'imaginative', word_class: 'AJ' },
    { word: 'imaginatively', word_class: 'AV' },
    { word: 'imaginativeness', word_class: 'N' }
  ],
  gather: [
    { word: 'gather', word_class: 'N' },
    { word: 'gather', word_class: 'V' },
    { word: 'gatherer', word_class: 'N' },
    { word: 'gathering', word_class: 'N' },
    { word: 'gathered', word_class: 'AJ' },
    { word: 'gathering', word_class: 'AJ' }
  ],
  invisible: [
    { word: 'invisible', word_class: 'AJ' },
    { word: 'invisibly', word_class: 'AV' },
    { word: 'invisibility', word_class: 'N' },
    { word: 'invisibleness', word_class: 'N' }
  ],
  bankruptcy: [
    { word: 'bankrupt', word_class: 'N' },
    { word: 'bankrupt', word_class: 'V' },
    { word: 'bankrupt', word_class: 'AJ' },
    { word: 'bankruptcy', word_class: 'N' }
  ],
  distaff: [
    { word: 'distaff', word_class: 'N' },
    { word: 'distaff', word_class: 'V' },
    { word: 'distaff', word_class: 'AJ' }
  ],
  coexist: [
    { word: 'coexist', word_class: 'V' },
    { word: 'coexistence', word_class: 'N' },
    { word: 'coexistent', word_class: 'AJ' },
    { word: 'coexisting', word_class: 'AJ' }
  ],
  disentangle: [
    { word: 'disentangle', word_class: 'V' },
    { word: 'disentangled', word_class: 'AJ' },
    { word: 'disentanglement', word_class: 'N' }
  ],
  combed: [
    { word: 'comb', word_class: 'N' },
    { word: 'comb', word_class: 'V' },
    { word: 'combed', word_class: 'AJ' },
    { word: 'combing', word_class: 'N' },
    { word: 'combative', word_class: 'AJ' },
    { word: 'combatively', word_class: 'AV' },
    { word: 'combativeness', word_class: 'N' }
  ],
  valorous: [
    { word: 'valor', word_class: 'N' },
    { word: 'valorise', word_class: 'V' },
    { word: 'valorize', word_class: 'V' },
    { word: 'valorous', word_class: 'AJ' },
    { word: 'valorously', word_class: 'AV' },
    { word: 'valorization', word_class: 'N' },
    { word: 'valorousness', word_class: 'N' }
  ],
  subjoin: [
    { word: 'subjoin', word_class: 'V' },
    { word: 'subjoining', word_class: 'N' },
    { word: 'subjunction', word_class: 'N' },
    { word: 'subjunctive', word_class: 'N' },
    { word: 'subjunctive', word_class: 'AJ' }
  ],
  upending: [
    { word: 'upend', word_class: 'V' },
    { word: 'upended', word_class: 'AJ' },
    { word: 'upending', word_class: 'N' }
  ],
  putative: [
    { word: 'put', word_class: 'N' },
    { word: 'put', word_class: 'V' },
    { word: 'putting', word_class: 'N' },
    { word: 'putative', word_class: 'AJ' }
  ],
  'anti-semitic': [
    { word: 'anti-semite', word_class: 'N' },
    { word: 'anti-semite', word_class: 'AJ' },
    { word: 'anti-semitic', word_class: 'AJ' },
    { word: 'anti-semitism', word_class: 'N' }
  ],
  thinning: [
    { word: 'thin', word_class: 'V' },
    { word: 'thin', word_class: 'AJ' },
    { word: 'thin', word_class: 'AV' },
    { word: 'thinned', word_class: 'AJ' },
    { word: 'thinness', word_class: 'N' },
    { word: 'thinning', word_class: 'N' }
  ],
  malcontent: [
    { word: 'malcontent', word_class: 'N' },
    { word: 'malcontent', word_class: 'V' },
    { word: 'malcontent', word_class: 'AJ' }
  ],
  slither: [
    { word: 'slither', word_class: 'V' },
    { word: 'slithery', word_class: 'AJ' },
    { word: 'slithering', word_class: 'AJ' }
  ],
  unsettlement: [
    { word: 'unsettle', word_class: 'V' },
    { word: 'unsettled', word_class: 'AJ' },
    { word: 'unsettlement', word_class: 'N' }
  ],
  distort: [
    { word: 'distort', word_class: 'N' },
    { word: 'distort', word_class: 'V' },
    { word: 'distorted', word_class: 'AJ' },
    { word: 'distortion', word_class: 'N' },
    { word: 'distortable', word_class: 'AJ' }
  ],
  yacht: [
    { word: 'yacht', word_class: 'N' },
    { word: 'yacht', word_class: 'V' },
    { word: 'yachting', word_class: 'N' }
  ],
  copolymerisation: [
    { word: 'copolymerise', word_class: 'V' },
    { word: 'copolymerize', word_class: 'V' },
    { word: 'copolymerisation', word_class: 'N' },
    { word: 'copolymerization', word_class: 'N' }
  ],
  elusiveness: [
    { word: 'elude', word_class: 'V' },
    { word: 'eluding', word_class: 'N' },
    { word: 'elusion', word_class: 'N' },
    { word: 'elusive', word_class: 'AJ' },
    { word: 'elusiveness', word_class: 'N' }
  ],
  protected: [
    { word: 'protect', word_class: 'V' },
    { word: 'protector', word_class: 'N' },
    { word: 'protected', word_class: 'AJ' },
    { word: 'protecting', word_class: 'N' },
    { word: 'protection', word_class: 'N' },
    { word: 'protecting', word_class: 'AJ' },
    { word: 'protective', word_class: 'AJ' },
    { word: 'protectorate', word_class: 'N' },
    { word: 'protectively', word_class: 'AV' },
    { word: 'protectiveness', word_class: 'N' }
  ],
  weaver: [
    { word: 'weave', word_class: 'N' },
    { word: 'weave', word_class: 'V' },
    { word: 'woven', word_class: 'AJ' },
    { word: 'weaver', word_class: 'N' },
    { word: 'weaving', word_class: 'N' },
    { word: 'weaving', word_class: 'AJ' }
  ],
  rigorousness: [
    { word: 'rig', word_class: 'N' },
    { word: 'rig', word_class: 'V' },
    { word: 'rigor', word_class: 'N' },
    { word: 'rigged', word_class: 'AJ' },
    { word: 'rigging', word_class: 'N' },
    { word: 'rigorous', word_class: 'AJ' },
    { word: 'rigorously', word_class: 'AV' },
    { word: 'rigorousness', word_class: 'N' }
  ],
  disproving: [
    { word: 'disproof', word_class: 'N' },
    { word: 'disprove', word_class: 'V' },
    { word: 'disproval', word_class: 'N' },
    { word: 'disproving', word_class: 'N' }
  ],
  transfixion: [
    { word: 'transfix', word_class: 'N' },
    { word: 'transfix', word_class: 'V' },
    { word: 'transfixed', word_class: 'AJ' },
    { word: 'transfixion', word_class: 'N' }
  ],
  stall: [
    { word: 'stall', word_class: 'N' },
    { word: 'stall', word_class: 'V' },
    { word: 'stalls', word_class: 'N' },
    { word: 'stalling', word_class: 'N' },
    { word: 'stallion', word_class: 'N' }
  ],
  inhibition: [
    { word: 'inhibit', word_class: 'V' },
    { word: 'inhibitor', word_class: 'N' },
    { word: 'inhibited', word_class: 'AJ' },
    { word: 'inhibition', word_class: 'N' },
    { word: 'inhibiting', word_class: 'AJ' }
  ],
  inter: [
    { word: 'inter', word_class: 'V' },
    { word: 'inter', word_class: 'AJ' },
    { word: 'interment', word_class: 'N' },
    { word: 'interred', word_class: 'AJ' }
  ],
  punky: [{ word: 'punky', word_class: 'N' }, { word: 'punkie', word_class: 'N' }, { word: 'punky', word_class: 'AJ' }],
  caustic: [
    { word: 'caustic', word_class: 'N' },
    { word: 'caustic', word_class: 'AJ' },
    { word: 'caustically', word_class: 'AV' }
  ],
  snafu: [{ word: 'snafu', word_class: 'N' }, { word: 'snafu', word_class: 'V' }, { word: 'snafu', word_class: 'AJ' }],
  revitalisation: [
    { word: 'revitalise', word_class: 'V' },
    { word: 'revitalize', word_class: 'V' },
    { word: 'revitalized', word_class: 'AJ' },
    { word: 'revitalizing', word_class: 'AJ' },
    { word: 'revitalisation', word_class: 'N' },
    { word: 'revitalization', word_class: 'N' }
  ],
  probing: [
    { word: 'probe', word_class: 'N' },
    { word: 'probe', word_class: 'V' },
    { word: 'probing', word_class: 'N' },
    { word: 'probing', word_class: 'AJ' }
  ],
  yelling: [
    { word: 'yell', word_class: 'N' },
    { word: 'yell', word_class: 'V' },
    { word: 'yeller', word_class: 'N' },
    { word: 'yelled', word_class: 'AJ' },
    { word: 'yelling', word_class: 'N' },
    { word: 'yelling', word_class: 'AJ' }
  ],
  stewardship: [
    { word: 'steward', word_class: 'N' },
    { word: 'steward', word_class: 'V' },
    { word: 'stewardship', word_class: 'N' }
  ],
  fulsomely: [
    { word: 'fulsome', word_class: 'AJ' },
    { word: 'fulsomely', word_class: 'AV' },
    { word: 'fulsomeness', word_class: 'N' }
  ],
  turn: [
    { word: 'turn', word_class: 'N' },
    { word: 'turn', word_class: 'V' },
    { word: 'turner', word_class: 'N' },
    { word: 'turned', word_class: 'AJ' },
    { word: 'turnery', word_class: 'N' },
    { word: 'turning', word_class: 'N' },
    { word: 'turnery', word_class: 'AJ' },
    { word: 'turning', word_class: 'AJ' }
  ],
  'left-hander': [
    { word: 'left-hand', word_class: 'AJ' },
    { word: 'left-hander', word_class: 'N' },
    { word: 'left-handed', word_class: 'AJ' }
  ],
  illiberal: [
    { word: 'illiberal', word_class: 'AJ' },
    { word: 'illiberality', word_class: 'N' },
    { word: 'illiberally', word_class: 'AV' }
  ],
  supposition: [
    { word: 'suppose', word_class: 'V' },
    { word: 'supposal', word_class: 'N' },
    { word: 'supposed', word_class: 'AJ' },
    { word: 'supposable', word_class: 'AJ' },
    { word: 'supposition', word_class: 'N' },
    { word: 'suppositional', word_class: 'AJ' }
  ],
  hydration: [
    { word: 'hydrate', word_class: 'N' },
    { word: 'hydrate', word_class: 'V' },
    { word: 'hydration', word_class: 'N' }
  ],
  regeneration: [
    { word: 'regenerate', word_class: 'V' },
    { word: 'regenerate', word_class: 'AJ' },
    { word: 'regeneration', word_class: 'N' },
    { word: 'regenerating', word_class: 'AJ' }
  ],
  toned: [{ word: 'tone', word_class: 'N' }, { word: 'tone', word_class: 'V' }, { word: 'toned', word_class: 'AJ' }],
  comforter: [
    { word: 'comfort', word_class: 'N' },
    { word: 'comfort', word_class: 'V' },
    { word: 'comforts', word_class: 'N' },
    { word: 'comforter', word_class: 'N' },
    { word: 'comforted', word_class: 'AJ' },
    { word: 'comforting', word_class: 'AJ' },
    { word: 'comfortable', word_class: 'AJ' },
    { word: 'comfortably', word_class: 'AV' },
    { word: 'comfortableness', word_class: 'N' }
  ],
  extension: [
    { word: 'extend', word_class: 'V' },
    { word: 'extension', word_class: 'N' },
    { word: 'extended', word_class: 'AJ' },
    { word: 'extensive', word_class: 'AJ' },
    { word: 'extendable', word_class: 'AJ' },
    { word: 'extensible', word_class: 'AJ' },
    { word: 'extendible', word_class: 'AJ' },
    { word: 'extensively', word_class: 'AV' },
    { word: 'extensibility', word_class: 'N' }
  ],
  curability: [
    { word: 'curable', word_class: 'AJ' },
    { word: 'curability', word_class: 'N' },
    { word: 'curableness', word_class: 'N' }
  ],
  wainscoting: [
    { word: 'wainscot', word_class: 'N' },
    { word: 'wainscot', word_class: 'V' },
    { word: 'wainscoted', word_class: 'AJ' },
    { word: 'wainscoting', word_class: 'N' },
    { word: 'wainscotting', word_class: 'N' }
  ],
  laicize: [
    { word: 'laicise', word_class: 'V' },
    { word: 'laicize', word_class: 'V' },
    { word: 'laicisation', word_class: 'N' },
    { word: 'laicization', word_class: 'N' }
  ],
  run: [
    { word: 'run', word_class: 'N' },
    { word: 'run', word_class: 'V' },
    { word: 'run', word_class: 'AJ' },
    { word: 'runny', word_class: 'AJ' },
    { word: 'running', word_class: 'N' },
    { word: 'running', word_class: 'AJ' },
    { word: 'runniness', word_class: 'N' }
  ],
  testify: [
    { word: 'testify', word_class: 'V' },
    { word: 'testifier', word_class: 'N' },
    { word: 'testification', word_class: 'N' }
  ],
  ascosporous: [
    { word: 'ascospore', word_class: 'N' },
    { word: 'ascosporic', word_class: 'AJ' },
    { word: 'ascosporous', word_class: 'AJ' }
  ],
  recriminate: [
    { word: 'recriminate', word_class: 'V' },
    { word: 'recrimination', word_class: 'N' },
    { word: 'recriminative', word_class: 'AJ' }
  ],
  intersect: [
    { word: 'intersect', word_class: 'N' },
    { word: 'intersect', word_class: 'V' },
    { word: 'intersection', word_class: 'N' },
    { word: 'intersectant', word_class: 'AJ' },
    { word: 'intersecting', word_class: 'AJ' }
  ],
  conceptional: [
    { word: 'concept', word_class: 'N' },
    { word: 'conceive', word_class: 'V' },
    { word: 'conceiver', word_class: 'N' },
    { word: 'conception', word_class: 'N' },
    { word: 'conceived', word_class: 'AJ' },
    { word: 'conceptive', word_class: 'AJ' },
    { word: 'conceivable', word_class: 'AJ' },
    { word: 'conceivably', word_class: 'AV' },
    { word: 'conceptional', word_class: 'AJ' },
    { word: 'conceivability', word_class: 'N' },
    { word: 'conceivableness', word_class: 'N' }
  ],
  reflective: [
    { word: 'reflect', word_class: 'V' },
    { word: 'reflector', word_class: 'N' },
    { word: 'reflected', word_class: 'AJ' },
    { word: 'reflection', word_class: 'N' },
    { word: 'reflectance', word_class: 'N' },
    { word: 'reflecting', word_class: 'AJ' },
    { word: 'reflective', word_class: 'AJ' },
    { word: 'reflectivity', word_class: 'N' },
    { word: 'reflectively', word_class: 'AV' }
  ],
  instillation: [
    { word: 'instil', word_class: 'V' },
    { word: 'instill', word_class: 'N' },
    { word: 'instill', word_class: 'V' },
    { word: 'instill', word_class: 'AJ' },
    { word: 'instill', word_class: 'AV' },
    { word: 'instilling', word_class: 'N' },
    { word: 'instilment', word_class: 'N' },
    { word: 'instillator', word_class: 'N' },
    { word: 'instillment', word_class: 'N' },
    { word: 'instillation', word_class: 'N' }
  ],
  deter: [
    { word: 'deter', word_class: 'V' },
    { word: 'determent', word_class: 'N' },
    { word: 'deterrent', word_class: 'N' },
    { word: 'deterrence', word_class: 'N' },
    { word: 'deterrent', word_class: 'AJ' }
  ],
  glitter: [
    { word: 'glitter', word_class: 'N' },
    { word: 'glitter', word_class: 'V' },
    { word: 'glittering', word_class: 'AJ' }
  ],
  romani: [
    { word: 'romany', word_class: 'N' },
    { word: 'romani', word_class: 'AJ' },
    { word: 'romany', word_class: 'AJ' }
  ],
  appreciativeness: [
    { word: 'appreciate', word_class: 'V' },
    { word: 'appreciable', word_class: 'AJ' },
    { word: 'appreciably', word_class: 'AV' },
    { word: 'appreciation', word_class: 'N' },
    { word: 'appreciated', word_class: 'AJ' },
    { word: 'appreciative', word_class: 'AJ' },
    { word: 'appreciatively', word_class: 'AV' },
    { word: 'appreciativeness', word_class: 'N' }
  ],
  overlook: [
    { word: 'overlook', word_class: 'N' },
    { word: 'overlook', word_class: 'V' },
    { word: 'overlooked', word_class: 'AJ' },
    { word: 'overlooking', word_class: 'AJ' }
  ],
  annihilation: [
    { word: 'annihilate', word_class: 'V' },
    { word: 'annihilated', word_class: 'AJ' },
    { word: 'annihilation', word_class: 'N' },
    { word: 'annihilating', word_class: 'AJ' },
    { word: 'annihilative', word_class: 'AJ' }
  ],
  equilibrize: [
    { word: 'equilibrate', word_class: 'V' },
    { word: 'equilibrize', word_class: 'V' },
    { word: 'equilibration', word_class: 'N' }
  ],
  enterics: [
    { word: 'enter', word_class: 'V' },
    { word: 'entry', word_class: 'N' },
    { word: 'entrant', word_class: 'N' },
    { word: 'ingress', word_class: 'N' },
    { word: 'ingress', word_class: 'V' },
    { word: 'entrance', word_class: 'N' },
    { word: 'enteral', word_class: 'AJ' },
    { word: 'entrance', word_class: 'V' },
    { word: 'enteric', word_class: 'AJ' },
    { word: 'enterics', word_class: 'N' },
    { word: 'entering', word_class: 'N' },
    { word: 'entering', word_class: 'AJ' },
    { word: 'enteritis', word_class: 'N' },
    { word: 'ingression', word_class: 'N' },
    { word: 'entranced', word_class: 'AJ' },
    { word: 'entrancing', word_class: 'AJ' },
    { word: 'entrancement', word_class: 'N' }
  ],
  lubricator: [
    { word: 'lubricant', word_class: 'N' },
    { word: 'lubricate', word_class: 'V' },
    { word: 'lubricity', word_class: 'N' },
    { word: 'lubricator', word_class: 'N' },
    { word: 'lubricated', word_class: 'AJ' },
    { word: 'lubrication', word_class: 'N' }
  ],
  specialness: [
    { word: 'special', word_class: 'N' },
    { word: 'special', word_class: 'AJ' },
    { word: 'specialty', word_class: 'N' },
    { word: 'specialise', word_class: 'V' },
    { word: 'specialize', word_class: 'V' },
    { word: 'speciality', word_class: 'N' },
    { word: 'specialism', word_class: 'N' },
    { word: 'specialist', word_class: 'N' },
    { word: 'specially', word_class: 'AV' },
    { word: 'specializer', word_class: 'N' },
    { word: 'specialist', word_class: 'AJ' },
    { word: 'specialness', word_class: 'N' },
    { word: 'specialized', word_class: 'AJ' },
    { word: 'specialistic', word_class: 'AJ' },
    { word: 'specialisation', word_class: 'N' },
    { word: 'specialization', word_class: 'N' }
  ],
  vegetive: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  inconceivability: [
    { word: 'inconceivable', word_class: 'AJ' },
    { word: 'inconceivably', word_class: 'AV' },
    { word: 'inconceivability', word_class: 'N' },
    { word: 'inconceivableness', word_class: 'N' }
  ],
  winnowing: [
    { word: 'winnow', word_class: 'N' },
    { word: 'winnow', word_class: 'V' },
    { word: 'winnowing', word_class: 'N' }
  ],
  complacency: [
    { word: 'complacence', word_class: 'N' },
    { word: 'complacency', word_class: 'N' },
    { word: 'complacent', word_class: 'AJ' },
    { word: 'complacently', word_class: 'AV' }
  ],
  clearness: [
    { word: 'clear', word_class: 'N' },
    { word: 'clear', word_class: 'V' },
    { word: 'clear', word_class: 'AJ' },
    { word: 'clear', word_class: 'AV' },
    { word: 'cleared', word_class: 'AJ' },
    { word: 'clearing', word_class: 'N' },
    { word: 'clearance', word_class: 'N' },
    { word: 'clearness', word_class: 'N' }
  ],
  multiple: [
    { word: 'multiple', word_class: 'N' },
    { word: 'multiply', word_class: 'V' },
    { word: 'multiple', word_class: 'AJ' },
    { word: 'multiply', word_class: 'AV' },
    { word: 'multiplier', word_class: 'N' },
    { word: 'multiplied', word_class: 'AJ' },
    { word: 'multiplicity', word_class: 'N' },
    { word: 'multiplication', word_class: 'N' },
    { word: 'multiplicative', word_class: 'AJ' }
  ],
  broil: [
    { word: 'broil', word_class: 'N' },
    { word: 'broil', word_class: 'V' },
    { word: 'broiler', word_class: 'N' },
    { word: 'broiled', word_class: 'AJ' },
    { word: 'broiling', word_class: 'N' }
  ],
  quarter: [
    { word: 'quarter', word_class: 'N' },
    { word: 'quarter', word_class: 'V' },
    { word: 'quarter', word_class: 'AJ' },
    { word: 'quarters', word_class: 'N' },
    { word: 'quartering', word_class: 'N' }
  ],
  swamped: [
    { word: 'swamp', word_class: 'N' },
    { word: 'swamp', word_class: 'V' },
    { word: 'swamped', word_class: 'AJ' }
  ],
  jar: [
    { word: 'jar', word_class: 'N' },
    { word: 'jar', word_class: 'V' },
    { word: 'jarful', word_class: 'N' },
    { word: 'jarful', word_class: 'AJ' },
    { word: 'jarring', word_class: 'AJ' }
  ],
  suberize: [
    { word: 'suberise', word_class: 'V' },
    { word: 'suberize', word_class: 'V' },
    { word: 'suberisation', word_class: 'N' },
    { word: 'suberization', word_class: 'N' }
  ],
  baas: [{ word: 'baa', word_class: 'N' }, { word: 'baa', word_class: 'V' }, { word: 'baas', word_class: 'N' }],
  stridency: [
    { word: 'strid', word_class: 'AJ' },
    { word: 'stride', word_class: 'N' },
    { word: 'stride', word_class: 'V' },
    { word: 'stridor', word_class: 'N' },
    { word: 'stridden', word_class: 'AJ' },
    { word: 'stridence', word_class: 'N' },
    { word: 'stridency', word_class: 'N' }
  ],
  homosexual: [
    { word: 'homosexual', word_class: 'N' },
    { word: 'homosexual', word_class: 'AJ' },
    { word: 'homosexualism', word_class: 'N' },
    { word: 'homosexuality', word_class: 'N' }
  ],
  elector: [
    { word: 'elect', word_class: 'N' },
    { word: 'elect', word_class: 'V' },
    { word: 'elect', word_class: 'AJ' },
    { word: 'elector', word_class: 'N' },
    { word: 'elected', word_class: 'AJ' },
    { word: 'election', word_class: 'N' },
    { word: 'electress', word_class: 'N' },
    { word: 'elective', word_class: 'AJ' },
    { word: 'electoral', word_class: 'AJ' },
    { word: 'electorate', word_class: 'N' }
  ],
  dehydrate: [
    { word: 'dehydrate', word_class: 'V' },
    { word: 'dehydrated', word_class: 'AJ' },
    { word: 'dehydration', word_class: 'N' }
  ],
  boring: [
    { word: 'bore', word_class: 'N' },
    { word: 'bore', word_class: 'V' },
    { word: 'borer', word_class: 'N' },
    { word: 'bored', word_class: 'AJ' },
    { word: 'boring', word_class: 'N' },
    { word: 'boredom', word_class: 'N' },
    { word: 'boring', word_class: 'AJ' }
  ],
  untangled: [
    { word: 'untangle', word_class: 'V' },
    { word: 'untangled', word_class: 'AJ' },
    { word: 'untangling', word_class: 'N' }
  ],
  'de-iodination': [
    { word: 'de-iodinate', word_class: 'V' },
    { word: 'de-iodination', word_class: 'N' },
    { word: 'de-iodinating', word_class: 'AJ' }
  ],
  scurry: [
    { word: 'scurry', word_class: 'N' },
    { word: 'scurry', word_class: 'V' },
    { word: 'scurrying', word_class: 'AJ' }
  ],
  distend: [
    { word: 'distend', word_class: 'V' },
    { word: 'distended', word_class: 'AJ' },
    { word: 'distension', word_class: 'N' },
    { word: 'distention', word_class: 'N' },
    { word: 'distensible', word_class: 'AJ' }
  ],
  quarrelsome: [
    { word: 'quarrel', word_class: 'N' },
    { word: 'quarrel', word_class: 'V' },
    { word: 'quarrelsome', word_class: 'AJ' },
    { word: 'quarrelsomeness', word_class: 'N' }
  ],
  dispiriting: [
    { word: 'dispirit', word_class: 'V' },
    { word: 'dispirited', word_class: 'AJ' },
    { word: 'dispiriting', word_class: 'AJ' }
  ],
  morphology: [
    { word: 'morphology', word_class: 'N' },
    { word: 'morphologic', word_class: 'AJ' },
    { word: 'morphological', word_class: 'AJ' },
    { word: 'morphologically', word_class: 'AV' }
  ],
  hardness: [
    { word: 'hard', word_class: 'AJ' },
    { word: 'hard', word_class: 'AV' },
    { word: 'harding', word_class: 'N' },
    { word: 'hardness', word_class: 'N' },
    { word: 'hardship', word_class: 'N' }
  ],
  surrogate: [
    { word: 'surrogate', word_class: 'N' },
    { word: 'surrogate', word_class: 'V' },
    { word: 'surrogate', word_class: 'AJ' },
    { word: 'surrogation', word_class: 'N' }
  ],
  generically: [
    { word: 'general', word_class: 'N' },
    { word: 'general', word_class: 'V' },
    { word: 'general', word_class: 'AJ' },
    { word: 'generic', word_class: 'AJ' },
    { word: 'generality', word_class: 'N' },
    { word: 'generalist', word_class: 'N' },
    { word: 'generalize', word_class: 'V' },
    { word: 'generally', word_class: 'AV' },
    { word: 'generalized', word_class: 'AJ' },
    { word: 'generically', word_class: 'AV' },
    { word: 'generalization', word_class: 'N' }
  ],
  annoying: [
    { word: 'annoy', word_class: 'V' },
    { word: 'annoyer', word_class: 'N' },
    { word: 'annoyed', word_class: 'AJ' },
    { word: 'annoying', word_class: 'N' },
    { word: 'annoyance', word_class: 'N' },
    { word: 'annoying', word_class: 'AJ' }
  ],
  imbiber: [
    { word: 'imbibe', word_class: 'V' },
    { word: 'imbiber', word_class: 'N' },
    { word: 'imbibing', word_class: 'N' },
    { word: 'imbibition', word_class: 'N' }
  ],
  marginality: [
    { word: 'margin', word_class: 'N' },
    { word: 'marginal', word_class: 'AJ' },
    { word: 'marginate', word_class: 'V' },
    { word: 'marginality', word_class: 'N' },
    { word: 'marginally', word_class: 'AV' },
    { word: 'margination', word_class: 'N' }
  ],
  inpouring: [
    { word: 'inpour', word_class: 'N' },
    { word: 'inpouring', word_class: 'N' },
    { word: 'inpouring', word_class: 'AJ' }
  ],
  thick: [
    { word: 'thick', word_class: 'N' },
    { word: 'thick', word_class: 'AJ' },
    { word: 'thick', word_class: 'AV' },
    { word: 'thickness', word_class: 'N' }
  ],
  anthropomorphic: [
    { word: 'anthropomorphic', word_class: 'AJ' },
    { word: 'anthropomorphism', word_class: 'N' },
    { word: 'anthropomorphize', word_class: 'V' },
    { word: 'anthropomorphous', word_class: 'AJ' },
    { word: 'anthropomorphization', word_class: 'N' }
  ],
  destination: [
    { word: 'destine', word_class: 'V' },
    { word: 'destiny', word_class: 'N' },
    { word: 'destined', word_class: 'AJ' },
    { word: 'destination', word_class: 'N' }
  ],
  progressively: [
    { word: 'progress', word_class: 'N' },
    { word: 'progress', word_class: 'V' },
    { word: 'progression', word_class: 'N' },
    { word: 'progressive', word_class: 'N' },
    { word: 'progressive', word_class: 'AJ' },
    { word: 'progressivity', word_class: 'N' },
    { word: 'progressively', word_class: 'AV' },
    { word: 'progressiveness', word_class: 'N' }
  ],
  approachable: [
    { word: 'approach', word_class: 'N' },
    { word: 'approach', word_class: 'V' },
    { word: 'approaching', word_class: 'N' },
    { word: 'approaching', word_class: 'AJ' },
    { word: 'approachable', word_class: 'AJ' },
    { word: 'approachability', word_class: 'N' }
  ],
  durables: [
    { word: 'durable', word_class: 'AJ' },
    { word: 'durables', word_class: 'N' },
    { word: 'durably', word_class: 'AV' },
    { word: 'durability', word_class: 'N' }
  ],
  unsuitableness: [
    { word: 'unsuited', word_class: 'AJ' },
    { word: 'unsuitable', word_class: 'AJ' },
    { word: 'unsuitably', word_class: 'AV' },
    { word: 'unsuitability', word_class: 'N' },
    { word: 'unsuitableness', word_class: 'N' }
  ],
  sensualness: [
    { word: 'sensual', word_class: 'AJ' },
    { word: 'sensualism', word_class: 'N' },
    { word: 'sensualize', word_class: 'V' },
    { word: 'sensuality', word_class: 'N' },
    { word: 'sensually', word_class: 'AV' },
    { word: 'sensualness', word_class: 'N' },
    { word: 'sensualization', word_class: 'N' }
  ],
  secular: [
    { word: 'secular', word_class: 'AJ' },
    { word: 'secularise', word_class: 'V' },
    { word: 'secularize', word_class: 'V' },
    { word: 'secularist', word_class: 'N' },
    { word: 'secularism', word_class: 'N' },
    { word: 'secularized', word_class: 'AJ' },
    { word: 'secularisation', word_class: 'N' },
    { word: 'secularization', word_class: 'N' }
  ],
  cognisable: [
    { word: 'cognise', word_class: 'V' },
    { word: 'cognisance', word_class: 'N' },
    { word: 'cognisant', word_class: 'AJ' },
    { word: 'cognisable', word_class: 'AJ' }
  ],
  predicted: [
    { word: 'predict', word_class: 'V' },
    { word: 'predictor', word_class: 'N' },
    { word: 'predicted', word_class: 'AJ' },
    { word: 'prediction', word_class: 'N' },
    { word: 'predictive', word_class: 'AJ' },
    { word: 'predictable', word_class: 'AJ' },
    { word: 'predictably', word_class: 'AV' },
    { word: 'predictability', word_class: 'N' }
  ],
  orthopedics: [
    { word: 'orthopedic', word_class: 'AJ' },
    { word: 'orthopedics', word_class: 'N' },
    { word: 'orthopedical', word_class: 'AJ' }
  ],
  antitype: [
    { word: 'antitype', word_class: 'N' },
    { word: 'antitypic', word_class: 'AJ' },
    { word: 'antitypical', word_class: 'AJ' }
  ],
  decrial: [
    { word: 'decry', word_class: 'N' },
    { word: 'decry', word_class: 'V' },
    { word: 'decrial', word_class: 'N' }
  ],
  cubism: [
    { word: 'cube', word_class: 'N' },
    { word: 'cube', word_class: 'V' },
    { word: 'cubism', word_class: 'N' },
    { word: 'cubist', word_class: 'N' },
    { word: 'cubist', word_class: 'AJ' },
    { word: 'cubistic', word_class: 'AJ' }
  ],
  cannoneer: [
    { word: 'cannon', word_class: 'N' },
    { word: 'cannon', word_class: 'V' },
    { word: 'cannoneer', word_class: 'N' }
  ],
  snipe: [{ word: 'snipe', word_class: 'N' }, { word: 'snipe', word_class: 'V' }, { word: 'sniper', word_class: 'N' }],
  relegate: [
    { word: 'relegate', word_class: 'V' },
    { word: 'relegating', word_class: 'N' },
    { word: 'relegation', word_class: 'N' }
  ],
  panic: [
    { word: 'panic', word_class: 'N' },
    { word: 'panic', word_class: 'V' },
    { word: 'panic', word_class: 'AJ' },
    { word: 'panicky', word_class: 'AJ' }
  ],
  tremble: [
    { word: 'tremble', word_class: 'N' },
    { word: 'tremble', word_class: 'V' },
    { word: 'trembles', word_class: 'N' },
    { word: 'trembly', word_class: 'AJ' },
    { word: 'trembling', word_class: 'N' },
    { word: 'trembling', word_class: 'AJ' }
  ],
  lacerate: [
    { word: 'lacerate', word_class: 'V' },
    { word: 'lacerate', word_class: 'AJ' },
    { word: 'lacerated', word_class: 'AJ' },
    { word: 'laceration', word_class: 'N' }
  ],
  brand: [
    { word: 'brand', word_class: 'N' },
    { word: 'brand', word_class: 'V' },
    { word: 'branded', word_class: 'AJ' },
    { word: 'branding', word_class: 'N' }
  ],
  ruminate: [
    { word: 'ruminant', word_class: 'N' },
    { word: 'ruminate', word_class: 'V' },
    { word: 'ruminant', word_class: 'AJ' },
    { word: 'rumination', word_class: 'N' },
    { word: 'ruminative', word_class: 'AJ' }
  ],
  answerability: [
    { word: 'answer', word_class: 'N' },
    { word: 'answer', word_class: 'V' },
    { word: 'answerer', word_class: 'N' },
    { word: 'answering', word_class: 'AJ' },
    { word: 'answerable', word_class: 'AJ' },
    { word: 'answerability', word_class: 'N' },
    { word: 'answerableness', word_class: 'N' }
  ],
  celebrity: [
    { word: 'celebrant', word_class: 'N' },
    { word: 'celebrate', word_class: 'V' },
    { word: 'celebrity', word_class: 'N' },
    { word: 'celebrater', word_class: 'N' },
    { word: 'celebrator', word_class: 'N' },
    { word: 'celebrated', word_class: 'AJ' },
    { word: 'celebration', word_class: 'N' }
  ],
  flavorer: [
    { word: 'flavor', word_class: 'N' },
    { word: 'flavor', word_class: 'V' },
    { word: 'flavour', word_class: 'N' },
    { word: 'flavour', word_class: 'V' },
    { word: 'flavorer', word_class: 'N' },
    { word: 'flavoring', word_class: 'N' },
    { word: 'flavourer', word_class: 'N' },
    { word: 'flavored', word_class: 'AJ' },
    { word: 'flavorful', word_class: 'AJ' },
    { word: 'flavorous', word_class: 'AJ' },
    { word: 'flavoured', word_class: 'AJ' },
    { word: 'flavouring', word_class: 'N' },
    { word: 'flavourful', word_class: 'AJ' },
    { word: 'flavourous', word_class: 'AJ' }
  ],
  complicate: [
    { word: 'complicate', word_class: 'V' },
    { word: 'complicity', word_class: 'N' },
    { word: 'complicated', word_class: 'AJ' },
    { word: 'complicating', word_class: 'N' },
    { word: 'complication', word_class: 'N' }
  ],
  notification: [
    { word: 'notify', word_class: 'V' },
    { word: 'notifying', word_class: 'N' },
    { word: 'notifiable', word_class: 'AJ' },
    { word: 'notification', word_class: 'N' }
  ],
  residuary: [
    { word: 'residue', word_class: 'N' },
    { word: 'residual', word_class: 'N' },
    { word: 'residual', word_class: 'AJ' },
    { word: 'residuary', word_class: 'AJ' }
  ],
  expensive: [
    { word: 'expend', word_class: 'V' },
    { word: 'expense', word_class: 'N' },
    { word: 'expender', word_class: 'N' },
    { word: 'expended', word_class: 'AJ' },
    { word: 'expending', word_class: 'N' },
    { word: 'expensive', word_class: 'AJ' },
    { word: 'expendable', word_class: 'AJ' },
    { word: 'expenditure', word_class: 'N' },
    { word: 'expensively', word_class: 'AV' },
    { word: 'expensiveness', word_class: 'N' }
  ],
  hebrews: [
    { word: 'hebrew', word_class: 'N' },
    { word: 'hebrew', word_class: 'AJ' },
    { word: 'hebrews', word_class: 'N' }
  ],
  pontifical: [
    { word: 'pontific', word_class: 'AJ' },
    { word: 'pontifical', word_class: 'AJ' },
    { word: 'pontificate', word_class: 'N' },
    { word: 'pontificate', word_class: 'V' },
    { word: 'pontification', word_class: 'N' }
  ],
  fatigues: [
    { word: 'fatigue', word_class: 'N' },
    { word: 'fatigue', word_class: 'V' },
    { word: 'fatigues', word_class: 'N' },
    { word: 'fatigued', word_class: 'AJ' }
  ],
  promotion: [
    { word: 'promote', word_class: 'N' },
    { word: 'promote', word_class: 'V' },
    { word: 'promoter', word_class: 'N' },
    { word: 'promotion', word_class: 'N' },
    { word: 'promotive', word_class: 'AJ' },
    { word: 'promotional', word_class: 'AJ' }
  ],
  hyperbolize: [
    { word: 'hyperbole', word_class: 'N' },
    { word: 'hyperbolic', word_class: 'AJ' },
    { word: 'hyperbolise', word_class: 'V' },
    { word: 'hyperbolize', word_class: 'V' },
    { word: 'hyperbolically', word_class: 'AV' },
    { word: 'hyperbolization', word_class: 'N' }
  ],
  adaptational: [
    { word: 'adapt', word_class: 'V' },
    { word: 'adapter', word_class: 'N' },
    { word: 'adaptor', word_class: 'N' },
    { word: 'adapted', word_class: 'AJ' },
    { word: 'adaptive', word_class: 'AJ' },
    { word: 'adaptable', word_class: 'AJ' },
    { word: 'adaptation', word_class: 'N' },
    { word: 'adaptative', word_class: 'AJ' },
    { word: 'adaptability', word_class: 'N' },
    { word: 'adaptational', word_class: 'AJ' }
  ],
  adeptness: [
    { word: 'adept', word_class: 'N' },
    { word: 'adept', word_class: 'AJ' },
    { word: 'adeptness', word_class: 'N' }
  ],
  improvise: [
    { word: 'improvise', word_class: 'V' },
    { word: 'improvised', word_class: 'AJ' },
    { word: 'improvisation', word_class: 'N' }
  ],
  capful: [
    { word: 'cap', word_class: 'N' },
    { word: 'cap', word_class: 'V' },
    { word: 'capful', word_class: 'N' },
    { word: 'caption', word_class: 'N' },
    { word: 'capful', word_class: 'AJ' },
    { word: 'capped', word_class: 'AJ' },
    { word: 'caption', word_class: 'V' }
  ],
  bereave: [
    { word: 'bereave', word_class: 'V' },
    { word: 'bereaved', word_class: 'AJ' },
    { word: 'bereavement', word_class: 'N' }
  ],
  impose: [
    { word: 'impose', word_class: 'N' },
    { word: 'impose', word_class: 'V' },
    { word: 'imposed', word_class: 'AJ' },
    { word: 'imposing', word_class: 'AJ' },
    { word: 'imposition', word_class: 'N' }
  ],
  systematics: [
    { word: 'system', word_class: 'N' },
    { word: 'systemic', word_class: 'AJ' },
    { word: 'systemize', word_class: 'V' },
    { word: 'systematize', word_class: 'V' },
    { word: 'systematics', word_class: 'N' },
    { word: 'systematic', word_class: 'AJ' },
    { word: 'systemisation', word_class: 'N' },
    { word: 'systemization', word_class: 'N' },
    { word: 'systematized', word_class: 'AJ' },
    { word: 'systematically', word_class: 'AV' },
    { word: 'systematisation', word_class: 'N' },
    { word: 'systematization', word_class: 'N' }
  ],
  maneuver: [
    { word: 'maneuver', word_class: 'N' },
    { word: 'maneuver', word_class: 'V' },
    { word: 'maneuvering', word_class: 'N' }
  ],
  minority: [
    { word: 'minor', word_class: 'AJ' },
    { word: 'minors', word_class: 'N' },
    { word: 'minority', word_class: 'N' }
  ],
  traduce: [
    { word: 'traduce', word_class: 'V' },
    { word: 'traducer', word_class: 'N' },
    { word: 'traduction', word_class: 'N' },
    { word: 'traducement', word_class: 'N' }
  ],
  omnipresence: [
    { word: 'omnipresent', word_class: 'N' },
    { word: 'omnipresent', word_class: 'V' },
    { word: 'omnipresence', word_class: 'N' },
    { word: 'omnipresent', word_class: 'AJ' }
  ],
  die: [
    { word: 'dy', word_class: 'N' },
    { word: 'die', word_class: 'N' },
    { word: 'die', word_class: 'V' },
    { word: 'death', word_class: 'N' },
    { word: 'dyed', word_class: 'AJ' },
    { word: 'dying', word_class: 'V' },
    { word: 'dying', word_class: 'N' },
    { word: 'dying', word_class: 'AJ' }
  ],
  inhalator: [
    { word: 'inhale', word_class: 'V' },
    { word: 'inhaler', word_class: 'N' },
    { word: 'inhale', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'N' },
    { word: 'inhaled', word_class: 'AJ' },
    { word: 'inhalant', word_class: 'AJ' },
    { word: 'inhalator', word_class: 'N' },
    { word: 'inhaling', word_class: 'AJ' },
    { word: 'inhalation', word_class: 'N' }
  ],
  eminence: [
    { word: 'eminence', word_class: 'N' },
    { word: 'eminent', word_class: 'AJ' },
    { word: 'eminently', word_class: 'AV' }
  ],
  deprecate: [
    { word: 'deprecate', word_class: 'V' },
    { word: 'deprecation', word_class: 'N' },
    { word: 'deprecating', word_class: 'AJ' },
    { word: 'deprecative', word_class: 'AJ' }
  ],
  consultive: [
    { word: 'consult', word_class: 'V' },
    { word: 'consultant', word_class: 'N' },
    { word: 'consultive', word_class: 'AJ' },
    { word: 'consultation', word_class: 'N' },
    { word: 'consultative', word_class: 'AJ' }
  ],
  nimble: [
    { word: 'nimble', word_class: 'AJ' },
    { word: 'nimbly', word_class: 'AV' },
    { word: 'nimbleness', word_class: 'N' }
  ],
  sputtering: [
    { word: 'sputter', word_class: 'N' },
    { word: 'sputter', word_class: 'V' },
    { word: 'sputtering', word_class: 'N' },
    { word: 'sputtering', word_class: 'AJ' }
  ],
  symmetrically: [
    { word: 'symmetry', word_class: 'N' },
    { word: 'symmetric', word_class: 'AJ' },
    { word: 'symmetrize', word_class: 'V' },
    { word: 'symmetrise', word_class: 'V' },
    { word: 'symmetrical', word_class: 'AJ' },
    { word: 'symmetrically', word_class: 'AV' },
    { word: 'symmetrisation', word_class: 'N' },
    { word: 'symmetrization', word_class: 'N' }
  ],
  ratification: [
    { word: 'ratify', word_class: 'V' },
    { word: 'ratifier', word_class: 'N' },
    { word: 'ratified', word_class: 'AJ' },
    { word: 'ratification', word_class: 'N' }
  ],
  narrowness: [
    { word: 'narrow', word_class: 'N' },
    { word: 'narrow', word_class: 'V' },
    { word: 'narrow', word_class: 'AJ' },
    { word: 'narrowed', word_class: 'AJ' },
    { word: 'narrowing', word_class: 'N' },
    { word: 'narrowing', word_class: 'AJ' },
    { word: 'narrowness', word_class: 'N' }
  ],
  overwhelming: [
    { word: 'overwhelm', word_class: 'V' },
    { word: 'overwhelmed', word_class: 'AJ' },
    { word: 'overwhelming', word_class: 'AJ' }
  ],
  promise: [
    { word: 'promise', word_class: 'N' },
    { word: 'promise', word_class: 'V' },
    { word: 'promisee', word_class: 'N' },
    { word: 'promiser', word_class: 'N' },
    { word: 'promisor', word_class: 'N' },
    { word: 'promised', word_class: 'AJ' },
    { word: 'promising', word_class: 'AJ' }
  ],
  original: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  brushing: [
    { word: 'brush', word_class: 'N' },
    { word: 'brush', word_class: 'V' },
    { word: 'brushed', word_class: 'AJ' },
    { word: 'brushing', word_class: 'N' }
  ],
  rehabilitate: [
    { word: 'rehabilitate', word_class: 'V' },
    { word: 'rehabilitated', word_class: 'AJ' },
    { word: 'rehabilitation', word_class: 'N' },
    { word: 'rehabilitative', word_class: 'AJ' }
  ],
  regiment: [
    { word: 'regiment', word_class: 'N' },
    { word: 'regiment', word_class: 'V' },
    { word: 'regimental', word_class: 'AJ' },
    { word: 'regimentals', word_class: 'N' },
    { word: 'regimented', word_class: 'AJ' },
    { word: 'regimentally', word_class: 'AV' },
    { word: 'regimentation', word_class: 'N' }
  ],
  seafarer: [
    { word: 'seafarer', word_class: 'N' },
    { word: 'seafaring', word_class: 'N' },
    { word: 'seafaring', word_class: 'AJ' }
  ],
  incessant: [
    { word: 'incessancy', word_class: 'N' },
    { word: 'incessant', word_class: 'AJ' },
    { word: 'incessantness', word_class: 'N' }
  ],
  peaceful: [
    { word: 'peace', word_class: 'N' },
    { word: 'peaceful', word_class: 'AJ' },
    { word: 'peaceable', word_class: 'AJ' },
    { word: 'peaceably', word_class: 'AV' },
    { word: 'peacefulness', word_class: 'N' },
    { word: 'peaceableness', word_class: 'N' }
  ],
  tumultuously: [
    { word: 'tumult', word_class: 'N' },
    { word: 'tumultuous', word_class: 'AJ' },
    { word: 'tumultuously', word_class: 'AV' },
    { word: 'tumultuousness', word_class: 'N' }
  ],
  communization: [
    { word: 'communist', word_class: 'N' },
    { word: 'communism', word_class: 'N' },
    { word: 'communist', word_class: 'AJ' },
    { word: 'communistic', word_class: 'AJ' },
    { word: 'communize', word_class: 'V' },
    { word: 'communisation', word_class: 'N' },
    { word: 'communization', word_class: 'N' }
  ],
  privatize: [
    { word: 'privacy', word_class: 'N' },
    { word: 'private', word_class: 'N' },
    { word: 'private', word_class: 'AJ' },
    { word: 'privates', word_class: 'N' },
    { word: 'privateer', word_class: 'N' },
    { word: 'privation', word_class: 'N' },
    { word: 'privatize', word_class: 'V' },
    { word: 'privately', word_class: 'AV' },
    { word: 'privateness', word_class: 'N' }
  ],
  honk: [{ word: 'honk', word_class: 'N' }, { word: 'honk', word_class: 'V' }, { word: 'honker', word_class: 'N' }],
  flammable: [
    { word: 'flame', word_class: 'N' },
    { word: 'flame', word_class: 'V' },
    { word: 'flaming', word_class: 'N' },
    { word: 'flaming', word_class: 'AJ' },
    { word: 'flammable', word_class: 'AJ' },
    { word: 'flammability', word_class: 'N' },
    { word: 'flammability', word_class: 'AJ' }
  ],
  lackadaisical: [
    { word: 'lackadaisic', word_class: 'AJ' },
    { word: 'lackadaisical', word_class: 'AJ' },
    { word: 'lackadaisically', word_class: 'AV' }
  ],
  medicine: [
    { word: 'medicine', word_class: 'N' },
    { word: 'medicine', word_class: 'V' },
    { word: 'medicinal', word_class: 'AJ' },
    { word: 'medicinally', word_class: 'AV' }
  ],
  designing: [
    { word: 'design', word_class: 'N' },
    { word: 'design', word_class: 'V' },
    { word: 'designer', word_class: 'N' },
    { word: 'designate', word_class: 'V' },
    { word: 'designate', word_class: 'N' },
    { word: 'designed', word_class: 'AJ' },
    { word: 'designer', word_class: 'AJ' },
    { word: 'designing', word_class: 'N' },
    { word: 'designate', word_class: 'AJ' },
    { word: 'designing', word_class: 'AJ' },
    { word: 'designated', word_class: 'AJ' },
    { word: 'designation', word_class: 'N' },
    { word: 'designative', word_class: 'AJ' }
  ],
  default: [
    { word: 'default', word_class: 'N' },
    { word: 'default', word_class: 'V' },
    { word: 'default', word_class: 'AJ' },
    { word: 'defaulter', word_class: 'N' }
  ],
  patriarchal: [
    { word: 'patriarch', word_class: 'N' },
    { word: 'patriarchs', word_class: 'N' },
    { word: 'patriarchal', word_class: 'AJ' },
    { word: 'patriarchate', word_class: 'N' },
    { word: 'patriarchic', word_class: 'AJ' }
  ],
  attempt: [
    { word: 'attempt', word_class: 'N' },
    { word: 'attempt', word_class: 'V' },
    { word: 'attempted', word_class: 'AJ' },
    { word: 'attemptable', word_class: 'AJ' }
  ],
  ethnic: [
    { word: 'ethnic', word_class: 'AJ' },
    { word: 'ethnical', word_class: 'AJ' },
    { word: 'ethnicity', word_class: 'N' },
    { word: 'ethnically', word_class: 'AV' }
  ],
  roping: [{ word: 'rope', word_class: 'N' }, { word: 'rope', word_class: 'V' }, { word: 'roping', word_class: 'N' }],
  distinctiveness: [
    { word: 'distinct', word_class: 'AJ' },
    { word: 'distinction', word_class: 'N' },
    { word: 'distinguish', word_class: 'V' },
    { word: 'distinctive', word_class: 'AJ' },
    { word: 'distinctness', word_class: 'N' },
    { word: 'distinctively', word_class: 'AV' },
    { word: 'distinguished', word_class: 'AJ' },
    { word: 'distinctiveness', word_class: 'N' },
    { word: 'distinguishing', word_class: 'AJ' },
    { word: 'distinguishable', word_class: 'AJ' }
  ],
  madden: [
    { word: 'madden', word_class: 'V' },
    { word: 'maddened', word_class: 'AJ' },
    { word: 'maddening', word_class: 'AJ' }
  ],
  permissible: [
    { word: 'permit', word_class: 'N' },
    { word: 'permit', word_class: 'V' },
    { word: 'permission', word_class: 'N' },
    { word: 'permitted', word_class: 'AJ' },
    { word: 'permissive', word_class: 'AJ' },
    { word: 'permissible', word_class: 'AJ' },
    { word: 'permissibly', word_class: 'AV' },
    { word: 'permissively', word_class: 'AV' },
    { word: 'permissibility', word_class: 'N' },
    { word: 'permissiveness', word_class: 'N' }
  ],
  transistorization: [
    { word: 'transistor', word_class: 'N' },
    { word: 'transistorise', word_class: 'V' },
    { word: 'transistorize', word_class: 'V' },
    { word: 'transistorized', word_class: 'AJ' },
    { word: 'transistorization', word_class: 'N' }
  ],
  acoustical: [
    { word: 'acoustic', word_class: 'N' },
    { word: 'acoustic', word_class: 'AJ' },
    { word: 'acoustics', word_class: 'N' },
    { word: 'acoustical', word_class: 'AJ' },
    { word: 'acoustically', word_class: 'AV' }
  ],
  sketcher: [
    { word: 'sketch', word_class: 'N' },
    { word: 'sketch', word_class: 'V' },
    { word: 'sketcher', word_class: 'N' }
  ],
  challenger: [
    { word: 'challenge', word_class: 'N' },
    { word: 'challenge', word_class: 'V' },
    { word: 'challenger', word_class: 'N' },
    { word: 'challenging', word_class: 'AJ' },
    { word: 'challengeable', word_class: 'AJ' }
  ],
  subtropics: [
    { word: 'subtropic', word_class: 'AJ' },
    { word: 'subtropics', word_class: 'N' },
    { word: 'subtropical', word_class: 'AJ' }
  ],
  decorator: [
    { word: 'decor', word_class: 'N' },
    { word: 'decorate', word_class: 'V' },
    { word: 'decorator', word_class: 'N' },
    { word: 'decorous', word_class: 'AJ' },
    { word: 'decorated', word_class: 'AJ' },
    { word: 'decoration', word_class: 'N' },
    { word: 'decorative', word_class: 'AJ' },
    { word: 'decorative', word_class: 'AV' },
    { word: 'decorously', word_class: 'AV' },
    { word: 'decorousness', word_class: 'N' },
    { word: 'decorativeness', word_class: 'N' }
  ],
  reticulate: [
    { word: 'reticule', word_class: 'N' },
    { word: 'reticulate', word_class: 'V' },
    { word: 'reticulate', word_class: 'AJ' },
    { word: 'reticulated', word_class: 'AJ' },
    { word: 'reticulation', word_class: 'N' }
  ],
  bastardize: [
    { word: 'bastard', word_class: 'N' },
    { word: 'bastard', word_class: 'AJ' },
    { word: 'bastardize', word_class: 'V' },
    { word: 'bastardized', word_class: 'AJ' },
    { word: 'bastardization', word_class: 'N' }
  ],
  irrelevancy: [
    { word: 'irrelevance', word_class: 'N' },
    { word: 'irrelevancy', word_class: 'N' },
    { word: 'irrelevant', word_class: 'AJ' }
  ],
  refrainment: [
    { word: 'refrain', word_class: 'N' },
    { word: 'refrain', word_class: 'V' },
    { word: 'refrainment', word_class: 'N' }
  ],
  brominate: [
    { word: 'bromine', word_class: 'N' },
    { word: 'brominate', word_class: 'V' },
    { word: 'bromination', word_class: 'N' }
  ],
  stomatitis: [
    { word: 'stomatal', word_class: 'AJ' },
    { word: 'stomatitis', word_class: 'N' },
    { word: 'stomatous', word_class: 'AJ' }
  ],
  riches: [
    { word: 'rich', word_class: 'AJ' },
    { word: 'riches', word_class: 'N' },
    { word: 'richness', word_class: 'N' }
  ],
  instructive: [
    { word: 'instruct', word_class: 'V' },
    { word: 'instructor', word_class: 'N' },
    { word: 'instructed', word_class: 'AJ' },
    { word: 'instruction', word_class: 'N' },
    { word: 'instructive', word_class: 'AJ' },
    { word: 'instructions', word_class: 'N' },
    { word: 'instructional', word_class: 'AJ' },
    { word: 'instructively', word_class: 'AV' }
  ],
  memorable: [
    { word: 'memory', word_class: 'N' },
    { word: 'memorial', word_class: 'N' },
    { word: 'memorise', word_class: 'V' },
    { word: 'memorize', word_class: 'V' },
    { word: 'memorial', word_class: 'AJ' },
    { word: 'memorizer', word_class: 'N' },
    { word: 'memorable', word_class: 'AJ' },
    { word: 'memorably', word_class: 'AV' },
    { word: 'memorialise', word_class: 'V' },
    { word: 'memorialize', word_class: 'V' },
    { word: 'memorisation', word_class: 'N' },
    { word: 'memorization', word_class: 'N' },
    { word: 'memorialisation', word_class: 'N' },
    { word: 'memorialization', word_class: 'N' }
  ],
  neutralism: [
    { word: 'neutral', word_class: 'N' },
    { word: 'neutral', word_class: 'AJ' },
    { word: 'neutralise', word_class: 'V' },
    { word: 'neutralist', word_class: 'N' },
    { word: 'neutralize', word_class: 'V' },
    { word: 'neutralism', word_class: 'N' },
    { word: 'neutrality', word_class: 'N' },
    { word: 'neutralized', word_class: 'AJ' },
    { word: 'neutralisation', word_class: 'N' },
    { word: 'neutralization', word_class: 'N' }
  ],
  agglutination: [
    { word: 'agglutinate', word_class: 'V' },
    { word: 'agglutinate', word_class: 'AJ' },
    { word: 'agglutination', word_class: 'N' },
    { word: 'agglutinative', word_class: 'AJ' }
  ],
  saliency: [
    { word: 'salient', word_class: 'N' },
    { word: 'salience', word_class: 'N' },
    { word: 'saliency', word_class: 'N' },
    { word: 'salient', word_class: 'AJ' }
  ],
  thinned: [
    { word: 'thin', word_class: 'V' },
    { word: 'thin', word_class: 'AJ' },
    { word: 'thin', word_class: 'AV' },
    { word: 'thinned', word_class: 'AJ' },
    { word: 'thinness', word_class: 'N' },
    { word: 'thinning', word_class: 'N' }
  ],
  defeated: [
    { word: 'defeat', word_class: 'N' },
    { word: 'defeat', word_class: 'V' },
    { word: 'defeated', word_class: 'N' },
    { word: 'defeated', word_class: 'AJ' },
    { word: 'defeature', word_class: 'N' },
    { word: 'defeatism', word_class: 'N' },
    { word: 'defeatist', word_class: 'N' },
    { word: 'defeasance', word_class: 'N' },
    { word: 'defeasible', word_class: 'AJ' }
  ],
  fine: [
    { word: 'fine', word_class: 'N' },
    { word: 'fine', word_class: 'V' },
    { word: 'final', word_class: 'N' },
    { word: 'fine', word_class: 'AJ' },
    { word: 'fine', word_class: 'AV' },
    { word: 'final', word_class: 'AJ' },
    { word: 'finale', word_class: 'N' },
    { word: 'fined', word_class: 'AJ' },
    { word: 'fining', word_class: 'N' },
    { word: 'finely', word_class: 'AV' },
    { word: 'finality', word_class: 'N' },
    { word: 'finalist', word_class: 'N' },
    { word: 'fineness', word_class: 'N' },
    { word: 'finally', word_class: 'AV' },
    { word: 'finalize', word_class: 'V' },
    { word: 'finalization', word_class: 'N' }
  ],
  radiating: [
    { word: 'radiate', word_class: 'V' },
    { word: 'radiator', word_class: 'N' },
    { word: 'radiation', word_class: 'N' },
    { word: 'radiating', word_class: 'AJ' }
  ],
  hardheadness: [
    { word: 'hardheads', word_class: 'N' },
    { word: 'hardheaded', word_class: 'AJ' },
    { word: 'hardheadness', word_class: 'N' }
  ],
  cervix: [
    { word: 'cervix', word_class: 'N' },
    { word: 'cervical', word_class: 'AJ' },
    { word: 'cervicitis', word_class: 'N' }
  ],
  tons: [{ word: 'ton', word_class: 'N' }, { word: 'tons', word_class: 'N' }, { word: 'tonnage', word_class: 'N' }],
  legality: [
    { word: 'legal', word_class: 'AJ' },
    { word: 'legalise', word_class: 'V' },
    { word: 'legalism', word_class: 'N' },
    { word: 'legalize', word_class: 'V' },
    { word: 'legality', word_class: 'N' },
    { word: 'legally', word_class: 'AV' },
    { word: 'legalisation', word_class: 'N' },
    { word: 'legalization', word_class: 'N' }
  ],
  meticulous: [
    { word: 'meticulous', word_class: 'AJ' },
    { word: 'meticulously', word_class: 'AV' },
    { word: 'meticulousness', word_class: 'N' }
  ],
  proselytisation: [
    { word: 'proselyte', word_class: 'N' },
    { word: 'proselyte', word_class: 'V' },
    { word: 'proselytise', word_class: 'V' },
    { word: 'proselytize', word_class: 'V' },
    { word: 'proselytism', word_class: 'N' },
    { word: 'proselytisation', word_class: 'N' },
    { word: 'proselytization', word_class: 'N' }
  ],
  seek: [
    { word: 'seek', word_class: 'N' },
    { word: 'seek', word_class: 'V' },
    { word: 'seeker', word_class: 'N' },
    { word: 'seeking', word_class: 'N' },
    { word: 'seeking', word_class: 'AJ' }
  ],
  worthy: [
    { word: 'worthy', word_class: 'N' },
    { word: 'worthy', word_class: 'AJ' },
    { word: 'worthiness', word_class: 'N' }
  ],
  aversive: [
    { word: 'avert', word_class: 'V' },
    { word: 'averse', word_class: 'AJ' },
    { word: 'aversion', word_class: 'N' },
    { word: 'averting', word_class: 'N' },
    { word: 'aversive', word_class: 'AJ' }
  ],
  ranching: [
    { word: 'ranch', word_class: 'N' },
    { word: 'ranch', word_class: 'V' },
    { word: 'ranching', word_class: 'N' }
  ],
  unjustified: [
    { word: 'unjustified', word_class: 'AJ' },
    { word: 'unjustifiable', word_class: 'AJ' },
    { word: 'unjustifiably', word_class: 'AV' }
  ],
  ravenous: [
    { word: 'raven', word_class: 'N' },
    { word: 'raven', word_class: 'V' },
    { word: 'ravening', word_class: 'AJ' },
    { word: 'ravenous', word_class: 'AJ' },
    { word: 'ravenously', word_class: 'AV' },
    { word: 'ravenousness', word_class: 'N' }
  ],
  prevarication: [
    { word: 'prevaricate', word_class: 'V' },
    { word: 'prevaricator', word_class: 'N' },
    { word: 'prevarication', word_class: 'N' }
  ],
  forward: [
    { word: 'forward', word_class: 'N' },
    { word: 'forward', word_class: 'V' },
    { word: 'forward', word_class: 'AJ' },
    { word: 'forward', word_class: 'AV' },
    { word: 'forwards', word_class: 'AV' },
    { word: 'forwarding', word_class: 'N' },
    { word: 'forwardness', word_class: 'N' }
  ],
  overturned: [
    { word: 'overturn', word_class: 'N' },
    { word: 'overturn', word_class: 'V' },
    { word: 'overturned', word_class: 'AJ' },
    { word: 'overturning', word_class: 'N' }
  ],
  planate: [
    { word: 'plane', word_class: 'N' },
    { word: 'plane', word_class: 'V' },
    { word: 'plane', word_class: 'AJ' },
    { word: 'planer', word_class: 'N' },
    { word: 'planar', word_class: 'AJ' },
    { word: 'planate', word_class: 'AJ' },
    { word: 'planation', word_class: 'N' },
    { word: 'planeness', word_class: 'N' }
  ],
  allegorize: [
    { word: 'allegory', word_class: 'N' },
    { word: 'allegoric', word_class: 'AJ' },
    { word: 'allegorize', word_class: 'V' },
    { word: 'allegorise', word_class: 'V' },
    { word: 'allegorical', word_class: 'AJ' },
    { word: 'allegorically', word_class: 'AV' },
    { word: 'allegorisation', word_class: 'N' },
    { word: 'allegorization', word_class: 'N' }
  ],
  looking: [
    { word: 'look', word_class: 'N' },
    { word: 'look', word_class: 'V' },
    { word: 'looker', word_class: 'N' },
    { word: 'looking', word_class: 'N' },
    { word: 'looking', word_class: 'AJ' }
  ],
  uxorious: [
    { word: 'uxorial', word_class: 'AJ' },
    { word: 'uxorious', word_class: 'AJ' },
    { word: 'uxoriously', word_class: 'AV' },
    { word: 'uxoriousness', word_class: 'N' }
  ],
  evolution: [
    { word: 'evolve', word_class: 'V' },
    { word: 'evolution', word_class: 'N' },
    { word: 'evolvement', word_class: 'N' },
    { word: 'evolutionary', word_class: 'AJ' }
  ],
  detrimentally: [
    { word: 'detriment', word_class: 'N' },
    { word: 'detrimental', word_class: 'AJ' },
    { word: 'detrimentally', word_class: 'AV' }
  ],
  rhyming: [
    { word: 'rhyme', word_class: 'N' },
    { word: 'rhyme', word_class: 'V' },
    { word: 'rhymed', word_class: 'AJ' },
    { word: 'rhyming', word_class: 'AJ' }
  ],
  surfeit: [
    { word: 'surfeit', word_class: 'N' },
    { word: 'surfeit', word_class: 'V' },
    { word: 'surfeited', word_class: 'AJ' }
  ],
  swinging: [
    { word: 'swing', word_class: 'N' },
    { word: 'swing', word_class: 'V' },
    { word: 'swinge', word_class: 'V' },
    { word: 'swinger', word_class: 'N' },
    { word: 'swinging', word_class: 'N' },
    { word: 'swinging', word_class: 'AJ' },
    { word: 'swingeing', word_class: 'AJ' }
  ],
  banker: [
    { word: 'bank', word_class: 'N' },
    { word: 'bank', word_class: 'V' },
    { word: 'banker', word_class: 'N' },
    { word: 'banking', word_class: 'N' }
  ],
  bibliographical: [
    { word: 'bibliographer', word_class: 'N' },
    { word: 'bibliographic', word_class: 'AJ' },
    { word: 'bibliographical', word_class: 'AJ' }
  ],
  looting: [
    { word: 'loot', word_class: 'N' },
    { word: 'loot', word_class: 'V' },
    { word: 'looter', word_class: 'N' },
    { word: 'looted', word_class: 'AJ' },
    { word: 'looting', word_class: 'N' }
  ],
  directional: [
    { word: 'direct', word_class: 'V' },
    { word: 'direct', word_class: 'AJ' },
    { word: 'direct', word_class: 'AV' },
    { word: 'director', word_class: 'N' },
    { word: 'directed', word_class: 'AJ' },
    { word: 'direction', word_class: 'N' },
    { word: 'directive', word_class: 'N' },
    { word: 'directing', word_class: 'AJ' },
    { word: 'directive', word_class: 'AJ' },
    { word: 'directness', word_class: 'N' },
    { word: 'directivity', word_class: 'N' },
    { word: 'directorate', word_class: 'N' },
    { word: 'directional', word_class: 'AJ' }
  ],
  billing: [
    { word: 'bill', word_class: 'N' },
    { word: 'bill', word_class: 'V' },
    { word: 'billing', word_class: 'N' },
    { word: 'billed', word_class: 'AJ' },
    { word: 'billion', word_class: 'N' },
    { word: 'billings', word_class: 'N' },
    { word: 'billion', word_class: 'AJ' },
    { word: 'billions', word_class: 'N' }
  ],
  misguided: [
    { word: 'misguide', word_class: 'V' },
    { word: 'misguided', word_class: 'AJ' },
    { word: 'misguidance', word_class: 'N' }
  ],
  hypocritically: [
    { word: 'hypocrite', word_class: 'N' },
    { word: 'hypocritical', word_class: 'AJ' },
    { word: 'hypocritically', word_class: 'AV' }
  ],
  server: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  conveyance: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  savagery: [
    { word: 'savage', word_class: 'V' },
    { word: 'savage', word_class: 'N' },
    { word: 'savage', word_class: 'AJ' },
    { word: 'savagery', word_class: 'N' },
    { word: 'savagely', word_class: 'AV' },
    { word: 'savagery', word_class: 'AJ' },
    { word: 'savageness', word_class: 'N' }
  ],
  enlarged: [
    { word: 'enlarge', word_class: 'V' },
    { word: 'enlarger', word_class: 'N' },
    { word: 'enlarged', word_class: 'AJ' },
    { word: 'enlargement', word_class: 'N' }
  ],
  mediatisation: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  emote: [
    { word: 'emote', word_class: 'V' },
    { word: 'emotion', word_class: 'N' },
    { word: 'emotive', word_class: 'AJ' },
    { word: 'emotional', word_class: 'AJ' }
  ],
  insularism: [
    { word: 'insular', word_class: 'AJ' },
    { word: 'insularism', word_class: 'N' },
    { word: 'insularity', word_class: 'N' }
  ],
  dandified: [
    { word: 'dandify', word_class: 'V' },
    { word: 'dandified', word_class: 'AJ' },
    { word: 'dandification', word_class: 'N' }
  ],
  fluoridate: [
    { word: 'fluoride', word_class: 'N' },
    { word: 'fluoridate', word_class: 'V' },
    { word: 'fluoridize', word_class: 'V' },
    { word: 'fluoridation', word_class: 'N' },
    { word: 'fluoridization', word_class: 'N' }
  ],
  speckled: [
    { word: 'speckle', word_class: 'N' },
    { word: 'speckle', word_class: 'V' },
    { word: 'speckled', word_class: 'AJ' }
  ],
  fib: [
    { word: 'fib', word_class: 'N' },
    { word: 'fib', word_class: 'V' },
    { word: 'fiber', word_class: 'N' },
    { word: 'fibbing', word_class: 'N' },
    { word: 'fibrous', word_class: 'AJ' }
  ],
  sextuplicate: [
    { word: 'sextuple', word_class: 'N' },
    { word: 'sextuple', word_class: 'AJ' },
    { word: 'sextuplicate', word_class: 'V' },
    { word: 'sextuplication', word_class: 'N' }
  ],
  extinguishable: [
    { word: 'extinguish', word_class: 'V' },
    { word: 'extinguisher', word_class: 'N' },
    { word: 'extinguished', word_class: 'AJ' },
    { word: 'extinguishing', word_class: 'N' },
    { word: 'extinguishment', word_class: 'N' },
    { word: 'extinguishable', word_class: 'AJ' }
  ],
  slide: [
    { word: 'slide', word_class: 'N' },
    { word: 'slide', word_class: 'V' },
    { word: 'slider', word_class: 'N' },
    { word: 'sliding', word_class: 'AJ' }
  ],
  mediate: [
    { word: 'mediate', word_class: 'V' },
    { word: 'mediate', word_class: 'AJ' },
    { word: 'mediator', word_class: 'N' },
    { word: 'mediation', word_class: 'N' },
    { word: 'mediatize', word_class: 'V' },
    { word: 'mediatise', word_class: 'V' },
    { word: 'mediated', word_class: 'AJ' },
    { word: 'mediateness', word_class: 'N' },
    { word: 'mediatisation', word_class: 'N' },
    { word: 'mediatization', word_class: 'N' }
  ],
  demobilization: [
    { word: 'demobilise', word_class: 'V' },
    { word: 'demobilize', word_class: 'V' },
    { word: 'demobilisation', word_class: 'N' },
    { word: 'demobilization', word_class: 'N' }
  ],
  euphemization: [
    { word: 'euphemism', word_class: 'N' },
    { word: 'euphemize', word_class: 'V' },
    { word: 'euphemization', word_class: 'N' }
  ],
  meddlesome: [
    { word: 'meddle', word_class: 'V' },
    { word: 'meddler', word_class: 'N' },
    { word: 'meddling', word_class: 'N' },
    { word: 'meddling', word_class: 'AJ' },
    { word: 'meddlesome', word_class: 'AJ' },
    { word: 'meddlesomeness', word_class: 'N' }
  ],
  pit: [
    { word: 'pit', word_class: 'N' },
    { word: 'pit', word_class: 'V' },
    { word: 'pitman', word_class: 'N' },
    { word: 'pitted', word_class: 'AJ' },
    { word: 'pitting', word_class: 'N' }
  ],
  lowly: [
    { word: 'low', word_class: 'N' },
    { word: 'low', word_class: 'V' },
    { word: 'low', word_class: 'AJ' },
    { word: 'low', word_class: 'AV' },
    { word: 'lowly', word_class: 'AJ' },
    { word: 'lowly', word_class: 'AV' },
    { word: 'lowness', word_class: 'N' }
  ],
  disarrayed: [
    { word: 'disarray', word_class: 'N' },
    { word: 'disarray', word_class: 'V' },
    { word: 'disarrayed', word_class: 'AJ' }
  ],
  morosely: [
    { word: 'morose', word_class: 'AJ' },
    { word: 'morosely', word_class: 'AV' },
    { word: 'moroseness', word_class: 'N' }
  ],
  heroic: [
    { word: 'heroic', word_class: 'AJ' },
    { word: 'heroics', word_class: 'N' },
    { word: 'heroical', word_class: 'AJ' },
    { word: 'heroically', word_class: 'AV' }
  ],
  fragmental: [
    { word: 'fragment', word_class: 'N' },
    { word: 'fragment', word_class: 'V' },
    { word: 'fragmental', word_class: 'AJ' },
    { word: 'fragmented', word_class: 'AJ' },
    { word: 'fragmentize', word_class: 'V' },
    { word: 'fragmentary', word_class: 'AJ' },
    { word: 'fragmentation', word_class: 'N' }
  ],
  perversity: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  polite: [
    { word: 'polite', word_class: 'N' },
    { word: 'polite', word_class: 'AJ' },
    { word: 'politic', word_class: 'AJ' },
    { word: 'politics', word_class: 'N' },
    { word: 'politely', word_class: 'AV' },
    { word: 'politeness', word_class: 'N' },
    { word: 'political', word_class: 'AJ' },
    { word: 'politician', word_class: 'N' },
    { word: 'politically', word_class: 'AV' }
  ],
  muscle: [
    { word: 'muscle', word_class: 'N' },
    { word: 'muscle', word_class: 'V' },
    { word: 'muscled', word_class: 'AJ' },
    { word: 'muscular', word_class: 'AJ' },
    { word: 'musculature', word_class: 'N' }
  ],
  opening: [
    { word: 'open', word_class: 'N' },
    { word: 'open', word_class: 'V' },
    { word: 'open', word_class: 'AJ' },
    { word: 'opener', word_class: 'N' },
    { word: 'opening', word_class: 'N' },
    { word: 'opened', word_class: 'AJ' },
    { word: 'opening', word_class: 'AJ' },
    { word: 'openness', word_class: 'N' }
  ],
  relocation: [
    { word: 'relocate', word_class: 'V' },
    { word: 'relocated', word_class: 'AJ' },
    { word: 'relocation', word_class: 'N' }
  ],
  antipode: [
    { word: 'antipode', word_class: 'N' },
    { word: 'antipodal', word_class: 'N' },
    { word: 'antipodes', word_class: 'N' },
    { word: 'antipodal', word_class: 'AJ' }
  ],
  polymorphous: [
    { word: 'polymorph', word_class: 'N' },
    { word: 'polymorphic', word_class: 'AJ' },
    { word: 'polymorphism', word_class: 'N' },
    { word: 'polymorphous', word_class: 'AJ' }
  ],
  'self-asserting': [
    { word: 'self-assertion', word_class: 'N' },
    { word: 'self-asserting', word_class: 'AJ' },
    { word: 'self-assertive', word_class: 'AJ' },
    { word: 'self-assertiveness', word_class: 'N' }
  ],
  unlikable: [
    { word: 'unlike', word_class: 'N' },
    { word: 'unlike', word_class: 'V' },
    { word: 'unlike', word_class: 'AJ' },
    { word: 'unlikely', word_class: 'AJ' },
    { word: 'unlikable', word_class: 'AJ' },
    { word: 'unlikeness', word_class: 'N' },
    { word: 'unlikeable', word_class: 'AJ' }
  ],
  wrap: [
    { word: 'wrap', word_class: 'N' },
    { word: 'wrap', word_class: 'V' },
    { word: 'wrapped', word_class: 'AJ' },
    { word: 'wrapping', word_class: 'N' }
  ],
  syndicalism: [
    { word: 'syndic', word_class: 'N' },
    { word: 'syndicate', word_class: 'N' },
    { word: 'syndicate', word_class: 'V' },
    { word: 'syndicalism', word_class: 'N' },
    { word: 'syndication', word_class: 'N' }
  ],
  bumbling: [
    { word: 'bumble', word_class: 'V' },
    { word: 'bumbler', word_class: 'N' },
    { word: 'bumbling', word_class: 'AJ' }
  ],
  pivotal: [
    { word: 'pivot', word_class: 'N' },
    { word: 'pivot', word_class: 'V' },
    { word: 'pivotal', word_class: 'AJ' }
  ],
  repellently: [
    { word: 'repel', word_class: 'V' },
    { word: 'repulse', word_class: 'N' },
    { word: 'repulse', word_class: 'V' },
    { word: 'repellant', word_class: 'N' },
    { word: 'repellent', word_class: 'N' },
    { word: 'repelling', word_class: 'N' },
    { word: 'repulsion', word_class: 'N' },
    { word: 'repulsive', word_class: 'AJ' },
    { word: 'repellant', word_class: 'AJ' },
    { word: 'repellent', word_class: 'AJ' },
    { word: 'repellently', word_class: 'AV' },
    { word: 'repulsively', word_class: 'AV' },
    { word: 'repulsiveness', word_class: 'N' }
  ],
  plundered: [
    { word: 'plunder', word_class: 'N' },
    { word: 'plunder', word_class: 'V' },
    { word: 'plunderer', word_class: 'N' },
    { word: 'plunderage', word_class: 'N' },
    { word: 'plundered', word_class: 'AJ' },
    { word: 'plundering', word_class: 'N' },
    { word: 'plundering', word_class: 'AJ' }
  ],
  twill: [
    { word: 'twill', word_class: 'N' },
    { word: 'twill', word_class: 'V' },
    { word: 'twill', word_class: 'AJ' },
    { word: 'twilled', word_class: 'AJ' }
  ],
  militarisation: [
    { word: 'military', word_class: 'N' },
    { word: 'military', word_class: 'AJ' },
    { word: 'militarism', word_class: 'N' },
    { word: 'militarist', word_class: 'N' },
    { word: 'militarise', word_class: 'V' },
    { word: 'militarize', word_class: 'V' },
    { word: 'militarily', word_class: 'AJ' },
    { word: 'militarily', word_class: 'AV' },
    { word: 'militarized', word_class: 'AJ' },
    { word: 'militaristic', word_class: 'AJ' },
    { word: 'militarisation', word_class: 'N' },
    { word: 'militarization', word_class: 'N' }
  ],
  evangelise: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  denatured: [
    { word: 'denature', word_class: 'V' },
    { word: 'denaturant', word_class: 'N' },
    { word: 'denatured', word_class: 'AJ' },
    { word: 'denaturalise', word_class: 'V' },
    { word: 'denaturalize', word_class: 'V' },
    { word: 'denaturation', word_class: 'N' },
    { word: 'denaturized', word_class: 'AJ' },
    { word: 'denaturalisation', word_class: 'N' },
    { word: 'denaturalization', word_class: 'N' }
  ],
  president: [
    { word: 'preside', word_class: 'N' },
    { word: 'preside', word_class: 'V' },
    { word: 'president', word_class: 'N' },
    { word: 'presidency', word_class: 'N' },
    { word: 'president', word_class: 'AJ' }
  ],
  impertinent: [
    { word: 'impertinence', word_class: 'N' },
    { word: 'impertinent', word_class: 'AJ' },
    { word: 'impertinently', word_class: 'AV' }
  ],
  minuteman: [
    { word: 'minute', word_class: 'N' },
    { word: 'minute', word_class: 'V' },
    { word: 'minute', word_class: 'AJ' },
    { word: 'minutes', word_class: 'N' },
    { word: 'minutely', word_class: 'AV' },
    { word: 'minuteman', word_class: 'N' },
    { word: 'minuteness', word_class: 'N' }
  ],
  repeating: [
    { word: 'repeat', word_class: 'N' },
    { word: 'repeat', word_class: 'V' },
    { word: 'repeater', word_class: 'N' },
    { word: 'repeated', word_class: 'AJ' },
    { word: 'repeating', word_class: 'N' },
    { word: 'repetition', word_class: 'N' },
    { word: 'repetition', word_class: 'V' },
    { word: 'repetitive', word_class: 'AJ' },
    { word: 'repeatable', word_class: 'AJ' },
    { word: 'repetitious', word_class: 'AJ' },
    { word: 'repetitively', word_class: 'AV' },
    { word: 'repetitiveness', word_class: 'N' },
    { word: 'repetitiousness', word_class: 'N' }
  ],
  composed: [
    { word: 'compose', word_class: 'V' },
    { word: 'composer', word_class: 'N' },
    { word: 'composed', word_class: 'AJ' },
    { word: 'composure', word_class: 'N' },
    { word: 'composing', word_class: 'N' },
    { word: 'composite', word_class: 'N' },
    { word: 'composite', word_class: 'AJ' },
    { word: 'composition', word_class: 'N' },
    { word: 'compositional', word_class: 'AJ' }
  ],
  neglectful: [
    { word: 'neglect', word_class: 'N' },
    { word: 'neglect', word_class: 'V' },
    { word: 'neglected', word_class: 'AJ' },
    { word: 'neglectful', word_class: 'AJ' },
    { word: 'neglectfulness', word_class: 'N' }
  ],
  soldiering: [
    { word: 'soldier', word_class: 'N' },
    { word: 'soldier', word_class: 'V' },
    { word: 'soldiery', word_class: 'N' },
    { word: 'soldiery', word_class: 'AJ' },
    { word: 'soldiering', word_class: 'N' },
    { word: 'soldiership', word_class: 'N' }
  ],
  splinters: [
    { word: 'splinter', word_class: 'N' },
    { word: 'splinter', word_class: 'V' },
    { word: 'splinters', word_class: 'N' },
    { word: 'splintered', word_class: 'AJ' },
    { word: 'splintering', word_class: 'N' }
  ],
  incisiveness: [
    { word: 'incise', word_class: 'V' },
    { word: 'incisor', word_class: 'N' },
    { word: 'incised', word_class: 'AJ' },
    { word: 'incision', word_class: 'N' },
    { word: 'incisive', word_class: 'AJ' },
    { word: 'incisively', word_class: 'AV' },
    { word: 'incisiveness', word_class: 'N' }
  ],
  sulphuration: [
    { word: 'sulphur', word_class: 'N' },
    { word: 'sulphur', word_class: 'V' },
    { word: 'sulphurise', word_class: 'V' },
    { word: 'sulphurize', word_class: 'V' },
    { word: 'sulphurate', word_class: 'V' },
    { word: 'sulphuric', word_class: 'AJ' },
    { word: 'sulphurous', word_class: 'AJ' },
    { word: 'sulphuration', word_class: 'N' },
    { word: 'sulfurisation', word_class: 'N' },
    { word: 'sulphurization', word_class: 'N' }
  ],
  dependent: [
    { word: 'depend', word_class: 'V' },
    { word: 'dependant', word_class: 'N' },
    { word: 'dependent', word_class: 'N' },
    { word: 'dependance', word_class: 'N' },
    { word: 'dependence', word_class: 'N' },
    { word: 'dependency', word_class: 'N' },
    { word: 'dependent', word_class: 'AJ' },
    { word: 'dependant', word_class: 'AJ' },
    { word: 'dependable', word_class: 'AJ' },
    { word: 'dependably', word_class: 'AV' },
    { word: 'dependability', word_class: 'N' },
    { word: 'dependableness', word_class: 'N' }
  ],
  suberise: [
    { word: 'suberise', word_class: 'V' },
    { word: 'suberize', word_class: 'V' },
    { word: 'suberisation', word_class: 'N' },
    { word: 'suberization', word_class: 'N' }
  ],
  brininess: [
    { word: 'brine', word_class: 'N' },
    { word: 'brine', word_class: 'V' },
    { word: 'briny', word_class: 'N' },
    { word: 'briny', word_class: 'AJ' },
    { word: 'brininess', word_class: 'N' }
  ],
  cropped: [
    { word: 'crop', word_class: 'N' },
    { word: 'crop', word_class: 'V' },
    { word: 'cropped', word_class: 'AJ' }
  ],
  transposed: [
    { word: 'transpose', word_class: 'N' },
    { word: 'transpose', word_class: 'V' },
    { word: 'transposed', word_class: 'AJ' },
    { word: 'transposition', word_class: 'N' },
    { word: 'transposability', word_class: 'N' }
  ],
  drunkenness: [
    { word: 'drink', word_class: 'N' },
    { word: 'drink', word_class: 'V' },
    { word: 'drunk', word_class: 'N' },
    { word: 'drunk', word_class: 'AJ' },
    { word: 'drinker', word_class: 'N' },
    { word: 'drunken', word_class: 'V' },
    { word: 'drinking', word_class: 'N' },
    { word: 'drunken', word_class: 'AJ' },
    { word: 'drunkenness', word_class: 'N' }
  ],
  invigilator: [
    { word: 'invigilate', word_class: 'V' },
    { word: 'invigilator', word_class: 'N' },
    { word: 'invigilation', word_class: 'N' }
  ],
  hunger: [
    { word: 'hunger', word_class: 'N' },
    { word: 'hunger', word_class: 'V' },
    { word: 'hungry', word_class: 'AJ' },
    { word: 'hungriness', word_class: 'N' }
  ],
  quickness: [
    { word: 'quick', word_class: 'N' },
    { word: 'quick', word_class: 'AJ' },
    { word: 'quick', word_class: 'AV' },
    { word: 'quickness', word_class: 'N' }
  ],
  purgative: [
    { word: 'purge', word_class: 'N' },
    { word: 'purge', word_class: 'V' },
    { word: 'purging', word_class: 'N' },
    { word: 'purging', word_class: 'AJ' },
    { word: 'purgation', word_class: 'N' },
    { word: 'purgative', word_class: 'N' },
    { word: 'purgatory', word_class: 'N' },
    { word: 'purgative', word_class: 'AJ' },
    { word: 'purgatorial', word_class: 'AJ' }
  ],
  manoeuvre: [
    { word: 'manoeuvre', word_class: 'N' },
    { word: 'manoeuvre', word_class: 'V' },
    { word: 'manoeuvrable', word_class: 'AJ' }
  ],
  steeplechaser: [
    { word: 'steeplechase', word_class: 'N' },
    { word: 'steeplechase', word_class: 'V' },
    { word: 'steeplechaser', word_class: 'N' },
    { word: 'steeplechasing', word_class: 'N' }
  ],
  decarburisation: [
    { word: 'decarburise', word_class: 'V' },
    { word: 'decarburize', word_class: 'V' },
    { word: 'decarburation', word_class: 'N' },
    { word: 'decarburisation', word_class: 'N' },
    { word: 'decarburization', word_class: 'N' }
  ],
  campaigner: [
    { word: 'campaign', word_class: 'N' },
    { word: 'campaign', word_class: 'V' },
    { word: 'campaigner', word_class: 'N' },
    { word: 'campaigning', word_class: 'N' }
  ],
  helminthic: [
    { word: 'helminth', word_class: 'N' },
    { word: 'helminthic', word_class: 'N' },
    { word: 'helminthic', word_class: 'AJ' }
  ],
  plural: [
    { word: 'plural', word_class: 'N' },
    { word: 'plural', word_class: 'AJ' },
    { word: 'pluralise', word_class: 'V' },
    { word: 'pluralism', word_class: 'N' },
    { word: 'pluralize', word_class: 'V' },
    { word: 'plurality', word_class: 'N' },
    { word: 'pluralisation', word_class: 'N' },
    { word: 'pluralization', word_class: 'N' }
  ],
  face: [
    { word: 'face', word_class: 'N' },
    { word: 'face', word_class: 'V' },
    { word: 'facer', word_class: 'N' },
    { word: 'faced', word_class: 'AJ' },
    { word: 'facing', word_class: 'N' },
    { word: 'faction', word_class: 'N' }
  ],
  differentiator: [
    { word: 'differ', word_class: 'V' },
    { word: 'different', word_class: 'AJ' },
    { word: 'difference', word_class: 'N' },
    { word: 'differing', word_class: 'AJ' },
    { word: 'differential', word_class: 'N' },
    { word: 'differently', word_class: 'AV' },
    { word: 'differential', word_class: 'AJ' },
    { word: 'differentiate', word_class: 'V' },
    { word: 'differentiator', word_class: 'N' },
    { word: 'differentiable', word_class: 'AJ' },
    { word: 'differentiation', word_class: 'N' },
    { word: 'differentiated', word_class: 'AJ' },
    { word: 'differentially', word_class: 'AV' }
  ],
  excellence: [
    { word: 'excel', word_class: 'V' },
    { word: 'excell', word_class: 'V' },
    { word: 'excellency', word_class: 'N' },
    { word: 'excellence', word_class: 'N' },
    { word: 'excellent', word_class: 'AJ' },
    { word: 'excellently', word_class: 'AV' }
  ],
  encompass: [
    { word: 'encompass', word_class: 'V' },
    { word: 'encompassing', word_class: 'AJ' },
    { word: 'encompassment', word_class: 'N' }
  ],
  promiscuously: [
    { word: 'promiscuity', word_class: 'N' },
    { word: 'promiscuous', word_class: 'AJ' },
    { word: 'promiscuously', word_class: 'AV' },
    { word: 'promiscuousness', word_class: 'N' }
  ],
  possibility: [
    { word: 'possible', word_class: 'N' },
    { word: 'possible', word_class: 'AJ' },
    { word: 'possibly', word_class: 'AV' },
    { word: 'possibility', word_class: 'N' },
    { word: 'possibleness', word_class: 'N' }
  ],
  libidinous: [
    { word: 'libido', word_class: 'N' },
    { word: 'libidinal', word_class: 'AJ' },
    { word: 'libidinous', word_class: 'AJ' }
  ],
  abet: [
    { word: 'abet', word_class: 'V' },
    { word: 'abettal', word_class: 'N' },
    { word: 'abetter', word_class: 'N' },
    { word: 'abetment', word_class: 'N' }
  ],
  rocketry: [
    { word: 'rocket', word_class: 'N' },
    { word: 'rocket', word_class: 'V' },
    { word: 'rocketry', word_class: 'N' }
  ],
  remedial: [
    { word: 'remedy', word_class: 'N' },
    { word: 'remedy', word_class: 'V' },
    { word: 'remedial', word_class: 'AJ' },
    { word: 'remediable', word_class: 'AJ' },
    { word: 'remediation', word_class: 'N' }
  ],
  recruit: [
    { word: 'recruit', word_class: 'N' },
    { word: 'recruit', word_class: 'V' },
    { word: 'recruiter', word_class: 'N' },
    { word: 'recruitment', word_class: 'N' }
  ],
  dedicate: [
    { word: 'dedicate', word_class: 'V' },
    { word: 'dedicated', word_class: 'AJ' },
    { word: 'dedication', word_class: 'N' }
  ],
  merit: [
    { word: 'merit', word_class: 'N' },
    { word: 'merit', word_class: 'V' },
    { word: 'merited', word_class: 'AJ' },
    { word: 'meriting', word_class: 'AJ' },
    { word: 'meritable', word_class: 'AJ' },
    { word: 'meritorious', word_class: 'AJ' },
    { word: 'meritoriously', word_class: 'AV' },
    { word: 'meritoriousness', word_class: 'N' }
  ],
  numerator: [
    { word: 'numeral', word_class: 'N' },
    { word: 'numeric', word_class: 'AJ' },
    { word: 'numerate', word_class: 'V' },
    { word: 'numeral', word_class: 'AJ' },
    { word: 'numerate', word_class: 'AJ' },
    { word: 'numerator', word_class: 'N' },
    { word: 'numerous', word_class: 'AJ' },
    { word: 'numerable', word_class: 'AJ' },
    { word: 'numerably', word_class: 'AV' },
    { word: 'numeration', word_class: 'N' },
    { word: 'numerical', word_class: 'AJ' },
    { word: 'numerability', word_class: 'N' },
    { word: 'numerically', word_class: 'AV' },
    { word: 'numerousness', word_class: 'N' }
  ],
  recantation: [
    { word: 'recant', word_class: 'N' },
    { word: 'recant', word_class: 'V' },
    { word: 'recantation', word_class: 'N' }
  ],
  blindfolded: [
    { word: 'blindfold', word_class: 'N' },
    { word: 'blindfold', word_class: 'V' },
    { word: 'blindfold', word_class: 'AJ' },
    { word: 'blindfolded', word_class: 'AJ' }
  ],
  perspicuous: [
    { word: 'perspicuity', word_class: 'N' },
    { word: 'perspicuous', word_class: 'AJ' },
    { word: 'perspicuously', word_class: 'AV' },
    { word: 'perspicuousness', word_class: 'N' }
  ],
  exciting: [
    { word: 'excite', word_class: 'V' },
    { word: 'excited', word_class: 'AJ' },
    { word: 'excitant', word_class: 'AJ' },
    { word: 'exciting', word_class: 'AJ' },
    { word: 'excitation', word_class: 'N' },
    { word: 'excitable', word_class: 'AJ' },
    { word: 'excitement', word_class: 'N' },
    { word: 'excitative', word_class: 'AJ' },
    { word: 'excitatory', word_class: 'AJ' },
    { word: 'excitability', word_class: 'N' },
    { word: 'excitableness', word_class: 'N' }
  ],
  prodding: [
    { word: 'prod', word_class: 'N' },
    { word: 'prod', word_class: 'V' },
    { word: 'prodding', word_class: 'N' }
  ],
  germanism: [
    { word: 'german', word_class: 'N' },
    { word: 'german', word_class: 'AJ' },
    { word: 'germane', word_class: 'AJ' },
    { word: 'germanic', word_class: 'N' },
    { word: 'germanic', word_class: 'AJ' },
    { word: 'germanise', word_class: 'V' },
    { word: 'germanize', word_class: 'V' },
    { word: 'germanism', word_class: 'N' },
    { word: 'germaneness', word_class: 'N' },
    { word: 'germanisation', word_class: 'N' },
    { word: 'germanization', word_class: 'N' }
  ],
  reducible: [
    { word: 'reduce', word_class: 'V' },
    { word: 'reducer', word_class: 'N' },
    { word: 'reduced', word_class: 'AJ' },
    { word: 'reducing', word_class: 'N' },
    { word: 'reduction', word_class: 'N' },
    { word: 'reducible', word_class: 'AJ' },
    { word: 'reductive', word_class: 'AJ' }
  ],
  abdication: [
    { word: 'abdicate', word_class: 'V' },
    { word: 'abdicator', word_class: 'N' },
    { word: 'abdicable', word_class: 'AJ' },
    { word: 'abdication', word_class: 'N' }
  ],
  conserving: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  beetle: [
    { word: 'beetle', word_class: 'N' },
    { word: 'beetle', word_class: 'V' },
    { word: 'beetle', word_class: 'AJ' },
    { word: 'beetling', word_class: 'AJ' }
  ],
  elegance: [
    { word: 'elegize', word_class: 'V' },
    { word: 'elegance', word_class: 'N' },
    { word: 'elegant', word_class: 'AJ' },
    { word: 'elegization', word_class: 'N' }
  ],
  methodize: [
    { word: 'method', word_class: 'N' },
    { word: 'methodise', word_class: 'V' },
    { word: 'methodism', word_class: 'N' },
    { word: 'methodize', word_class: 'V' },
    { word: 'methodical', word_class: 'AJ' },
    { word: 'methodically', word_class: 'AV' },
    { word: 'methodisation', word_class: 'N' },
    { word: 'methodization', word_class: 'N' }
  ],
  stiffened: [
    { word: 'stiffen', word_class: 'V' },
    { word: 'stiffener', word_class: 'N' },
    { word: 'stiffened', word_class: 'AJ' },
    { word: 'stiffening', word_class: 'N' }
  ],
  bloodied: [
    { word: 'blood', word_class: 'N' },
    { word: 'blood', word_class: 'V' },
    { word: 'bloody', word_class: 'V' },
    { word: 'bloody', word_class: 'AJ' },
    { word: 'bloody', word_class: 'AV' },
    { word: 'blooded', word_class: 'AJ' },
    { word: 'bloodied', word_class: 'AJ' }
  ],
  binoculars: [
    { word: 'binocular', word_class: 'N' },
    { word: 'binocular', word_class: 'AJ' },
    { word: 'binoculars', word_class: 'N' }
  ],
  discolouration: [
    { word: 'discolour', word_class: 'V' },
    { word: 'discoloured', word_class: 'AJ' },
    { word: 'discolouration', word_class: 'N' }
  ],
  repudiated: [
    { word: 'repudiate', word_class: 'V' },
    { word: 'repudiated', word_class: 'AJ' },
    { word: 'repudiation', word_class: 'N' },
    { word: 'repudiative', word_class: 'AJ' }
  ],
  discomycetes: [
    { word: 'discomycete', word_class: 'N' },
    { word: 'discomycetes', word_class: 'N' },
    { word: 'discomycetous', word_class: 'AJ' }
  ],
  airs: [
    { word: 'air', word_class: 'N' },
    { word: 'air', word_class: 'V' },
    { word: 'air', word_class: 'AJ' },
    { word: 'aire', word_class: 'N' },
    { word: 'airs', word_class: 'N' },
    { word: 'aired', word_class: 'AJ' },
    { word: 'airing', word_class: 'N' },
    { word: 'airman', word_class: 'N' },
    { word: 'airship', word_class: 'N' }
  ],
  romanisation: [
    { word: 'roman', word_class: 'N' },
    { word: 'roman', word_class: 'AJ' },
    { word: 'romanal', word_class: 'N' },
    { word: 'romanise', word_class: 'V' },
    { word: 'romanism', word_class: 'N' },
    { word: 'romanize', word_class: 'V' },
    { word: 'romanisation', word_class: 'N' },
    { word: 'romanization', word_class: 'N' }
  ],
  acculturative: [
    { word: 'acculturate', word_class: 'V' },
    { word: 'acculturation', word_class: 'N' },
    { word: 'acculturative', word_class: 'AJ' },
    { word: 'acculturational', word_class: 'AJ' }
  ],
  fight: [
    { word: 'fight', word_class: 'N' },
    { word: 'fight', word_class: 'V' },
    { word: 'fighter', word_class: 'N' },
    { word: 'fighting', word_class: 'N' },
    { word: 'fighting', word_class: 'AJ' }
  ],
  alchemist: [
    { word: 'alchemist', word_class: 'N' },
    { word: 'alchemistic', word_class: 'AJ' },
    { word: 'alchemistical', word_class: 'AJ' }
  ],
  ire: [
    { word: 'ir', word_class: 'N' },
    { word: 'ire', word_class: 'N' },
    { word: 'irs', word_class: 'N' },
    { word: 'ireful', word_class: 'AJ' }
  ],
  razor: [
    { word: 'raze', word_class: 'V' },
    { word: 'razor', word_class: 'N' },
    { word: 'razor', word_class: 'V' },
    { word: 'razed', word_class: 'AJ' },
    { word: 'razing', word_class: 'N' }
  ],
  introjection: [
    { word: 'introject', word_class: 'N' },
    { word: 'introject', word_class: 'V' },
    { word: 'introjected', word_class: 'AJ' },
    { word: 'introjection', word_class: 'N' }
  ],
  agglutinate: [
    { word: 'agglutinate', word_class: 'V' },
    { word: 'agglutinate', word_class: 'AJ' },
    { word: 'agglutination', word_class: 'N' },
    { word: 'agglutinative', word_class: 'AJ' }
  ],
  inflowing: [
    { word: 'inflow', word_class: 'N' },
    { word: 'inflow', word_class: 'V' },
    { word: 'inflowing', word_class: 'AJ' }
  ],
  regretful: [
    { word: 'regret', word_class: 'N' },
    { word: 'regret', word_class: 'V' },
    { word: 'regrets', word_class: 'N' },
    { word: 'regretful', word_class: 'AJ' }
  ],
  transcendentally: [
    { word: 'transcend', word_class: 'V' },
    { word: 'transcendence', word_class: 'N' },
    { word: 'transcendency', word_class: 'N' },
    { word: 'transcendent', word_class: 'AJ' },
    { word: 'trandscendence', word_class: 'N' },
    { word: 'transcendental', word_class: 'AJ' },
    { word: 'transcendentalism', word_class: 'N' },
    { word: 'transcendentally', word_class: 'AV' }
  ],
  asexuality: [
    { word: 'asexual', word_class: 'AJ' },
    { word: 'asexuality', word_class: 'N' },
    { word: 'asexually', word_class: 'AV' },
    { word: 'nonsexual', word_class: 'AJ' }
  ],
  escape: [
    { word: 'escape', word_class: 'N' },
    { word: 'escape', word_class: 'V' },
    { word: 'escapee', word_class: 'N' },
    { word: 'escapade', word_class: 'N' },
    { word: 'escapism', word_class: 'N' },
    { word: 'escaped', word_class: 'AJ' },
    { word: 'escapist', word_class: 'N' },
    { word: 'escapement', word_class: 'N' }
  ],
  pitiful: [
    { word: 'pity', word_class: 'N' },
    { word: 'pity', word_class: 'V' },
    { word: 'piteous', word_class: 'AJ' },
    { word: 'pitiful', word_class: 'AJ' },
    { word: 'pitying', word_class: 'AJ' }
  ],
  proscribe: [
    { word: 'proscribe', word_class: 'N' },
    { word: 'proscribe', word_class: 'V' },
    { word: 'proscribed', word_class: 'AJ' },
    { word: 'proscription', word_class: 'N' },
    { word: 'proscriptive', word_class: 'AJ' }
  ],
  reasoner: [
    { word: 'reason', word_class: 'N' },
    { word: 'reason', word_class: 'V' },
    { word: 'reasoner', word_class: 'N' },
    { word: 'reasoned', word_class: 'AJ' },
    { word: 'reasoning', word_class: 'N' },
    { word: 'reasoning', word_class: 'AJ' },
    { word: 'reasonable', word_class: 'AJ' },
    { word: 'reasonably', word_class: 'AV' },
    { word: 'reasonableness', word_class: 'N' }
  ],
  nuts: [
    { word: 'nut', word_class: 'N' },
    { word: 'nut', word_class: 'V' },
    { word: 'nuts', word_class: 'AJ' },
    { word: 'nutate', word_class: 'V' },
    { word: 'nutation', word_class: 'N' }
  ],
  spiritualization: [
    { word: 'spirit', word_class: 'N' },
    { word: 'spirit', word_class: 'V' },
    { word: 'spirits', word_class: 'N' },
    { word: 'spirited', word_class: 'AJ' },
    { word: 'spiritize', word_class: 'V' },
    { word: 'spiritual', word_class: 'N' },
    { word: 'spiritual', word_class: 'AJ' },
    { word: 'spiritous', word_class: 'AJ' },
    { word: 'spirituous', word_class: 'AJ' },
    { word: 'spiritualist', word_class: 'N' },
    { word: 'spiritualize', word_class: 'V' },
    { word: 'spirituality', word_class: 'N' },
    { word: 'spiritually', word_class: 'AV' },
    { word: 'spiritualism', word_class: 'N' },
    { word: 'spiritualise', word_class: 'V' },
    { word: 'spiritualist', word_class: 'AJ' },
    { word: 'spiritualistic', word_class: 'AJ' },
    { word: 'spiritualisation', word_class: 'N' },
    { word: 'spiritualization', word_class: 'N' }
  ],
  group: [
    { word: 'group', word_class: 'N' },
    { word: 'group', word_class: 'V' },
    { word: 'group', word_class: 'AJ' },
    { word: 'grouper', word_class: 'N' },
    { word: 'grouped', word_class: 'AJ' },
    { word: 'grouping', word_class: 'N' }
  ],
  ending: [
    { word: 'end', word_class: 'N' },
    { word: 'end', word_class: 'V' },
    { word: 'ended', word_class: 'AJ' },
    { word: 'ending', word_class: 'N' }
  ],
  sympathise: [
    { word: 'sympathy', word_class: 'N' },
    { word: 'sympathise', word_class: 'V' },
    { word: 'sympathize', word_class: 'V' },
    { word: 'sympathizer', word_class: 'N' },
    { word: 'sympathetic', word_class: 'AJ' },
    { word: 'sympathetically', word_class: 'AV' }
  ],
  creatively: [
    { word: 'creative', word_class: 'AJ' },
    { word: 'creativity', word_class: 'N' },
    { word: 'creatively', word_class: 'AV' },
    { word: 'creativeness', word_class: 'N' }
  ],
  communal: [
    { word: 'commune', word_class: 'N' },
    { word: 'commune', word_class: 'V' },
    { word: 'communion', word_class: 'N' },
    { word: 'communal', word_class: 'AJ' },
    { word: 'community', word_class: 'N' },
    { word: 'community', word_class: 'AJ' },
    { word: 'communalise', word_class: 'V' },
    { word: 'communalize', word_class: 'V' },
    { word: 'communally', word_class: 'AV' },
    { word: 'communalisation', word_class: 'N' },
    { word: 'communalization', word_class: 'N' }
  ],
  gesticulating: [
    { word: 'gesticulate', word_class: 'V' },
    { word: 'gesticulation', word_class: 'N' },
    { word: 'gesticulating', word_class: 'AJ' }
  ],
  truancy: [
    { word: 'truant', word_class: 'N' },
    { word: 'truant', word_class: 'V' },
    { word: 'truancy', word_class: 'N' },
    { word: 'truant', word_class: 'AJ' }
  ],
  rhythmical: [
    { word: 'rhythmic', word_class: 'AJ' },
    { word: 'rhythmical', word_class: 'AJ' },
    { word: 'rhythmicity', word_class: 'N' },
    { word: 'rhythmically', word_class: 'AV' }
  ],
  minimisation: [
    { word: 'minim', word_class: 'N' },
    { word: 'minimise', word_class: 'V' },
    { word: 'minimize', word_class: 'V' },
    { word: 'minimal', word_class: 'AJ' },
    { word: 'minimally', word_class: 'AV' },
    { word: 'minimized', word_class: 'AJ' },
    { word: 'minimisation', word_class: 'N' },
    { word: 'minimization', word_class: 'N' }
  ],
  shred: [
    { word: 'shred', word_class: 'N' },
    { word: 'shred', word_class: 'V' },
    { word: 'shredded', word_class: 'AJ' }
  ],
  merchandising: [
    { word: 'merchandise', word_class: 'N' },
    { word: 'merchandise', word_class: 'V' },
    { word: 'merchandiser', word_class: 'N' },
    { word: 'merchandising', word_class: 'N' },
    { word: 'merchandisement', word_class: 'N' }
  ],
  bone: [
    { word: 'bone', word_class: 'N' },
    { word: 'bone', word_class: 'V' },
    { word: 'bony', word_class: 'AJ' },
    { word: 'bone', word_class: 'AJ' },
    { word: 'boner', word_class: 'N' },
    { word: 'bones', word_class: 'N' },
    { word: 'boned', word_class: 'AJ' },
    { word: 'boniness', word_class: 'N' },
    { word: 'rawboned', word_class: 'AJ' }
  ],
  intensifier: [
    { word: 'intensify', word_class: 'V' },
    { word: 'intensifier', word_class: 'N' },
    { word: 'intensified', word_class: 'AJ' },
    { word: 'intensifying', word_class: 'AJ' },
    { word: 'intensification', word_class: 'N' },
    { word: 'intensificatiion', word_class: 'N' }
  ],
  billow: [
    { word: 'billow', word_class: 'N' },
    { word: 'billow', word_class: 'V' },
    { word: 'billowing', word_class: 'AJ' }
  ],
  lyrically: [
    { word: 'lyric', word_class: 'N' },
    { word: 'lyric', word_class: 'V' },
    { word: 'lyric', word_class: 'AJ' },
    { word: 'lyrical', word_class: 'AJ' },
    { word: 'lyricism', word_class: 'N' },
    { word: 'lyricality', word_class: 'N' },
    { word: 'lyrically', word_class: 'AV' }
  ],
  apart: [
    { word: 'apart', word_class: 'AJ' },
    { word: 'apart', word_class: 'AV' },
    { word: 'apartment', word_class: 'N' }
  ],
  tiled: [
    { word: 'tile', word_class: 'N' },
    { word: 'tile', word_class: 'V' },
    { word: 'tiled', word_class: 'AJ' },
    { word: 'tiling', word_class: 'N' }
  ],
  companionable: [
    { word: 'companion', word_class: 'N' },
    { word: 'companion', word_class: 'V' },
    { word: 'companionate', word_class: 'AJ' },
    { word: 'companionship', word_class: 'N' },
    { word: 'companionable', word_class: 'AJ' },
    { word: 'companionably', word_class: 'AV' },
    { word: 'companionability', word_class: 'N' },
    { word: 'companionableness', word_class: 'N' }
  ],
  shackled: [
    { word: 'shackle', word_class: 'N' },
    { word: 'shackle', word_class: 'V' },
    { word: 'shackled', word_class: 'AJ' }
  ],
  independence: [
    { word: 'independent', word_class: 'N' },
    { word: 'independence', word_class: 'N' },
    { word: 'independent', word_class: 'AJ' },
    { word: 'independency', word_class: 'N' },
    { word: 'independently', word_class: 'AV' }
  ],
  perverted: [
    { word: 'pervert', word_class: 'N' },
    { word: 'pervert', word_class: 'V' },
    { word: 'perverse', word_class: 'AJ' },
    { word: 'perversion', word_class: 'N' },
    { word: 'perverted', word_class: 'AJ' },
    { word: 'perversity', word_class: 'N' },
    { word: 'perversely', word_class: 'AV' },
    { word: 'perversive', word_class: 'AJ' },
    { word: 'perverseness', word_class: 'N' }
  ],
  reformist: [
    { word: 'reform', word_class: 'N' },
    { word: 'reform', word_class: 'V' },
    { word: 'reformer', word_class: 'N' },
    { word: 'reformist', word_class: 'N' },
    { word: 'reformed', word_class: 'AJ' },
    { word: 'reformist', word_class: 'AJ' },
    { word: 'reformable', word_class: 'AJ' },
    { word: 'reformation', word_class: 'N' },
    { word: 'reformatory', word_class: 'N' },
    { word: 'reformatory', word_class: 'AJ' },
    { word: 'reformative', word_class: 'AJ' }
  ],
  scheme: [
    { word: 'scheme', word_class: 'N' },
    { word: 'scheme', word_class: 'V' },
    { word: 'schemer', word_class: 'N' },
    { word: 'scheming', word_class: 'AJ' }
  ],
  flash: [
    { word: 'flash', word_class: 'N' },
    { word: 'flash', word_class: 'V' },
    { word: 'flash', word_class: 'AJ' },
    { word: 'flasher', word_class: 'N' },
    { word: 'flashing', word_class: 'N' },
    { word: 'flashing', word_class: 'AJ' }
  ],
  incongruousness: [
    { word: 'incongruity', word_class: 'N' },
    { word: 'incongruent', word_class: 'AJ' },
    { word: 'incongruous', word_class: 'AJ' },
    { word: 'incongruously', word_class: 'AV' },
    { word: 'incongruousness', word_class: 'N' }
  ],
  oppressiveness: [
    { word: 'oppress', word_class: 'V' },
    { word: 'oppressor', word_class: 'N' },
    { word: 'oppression', word_class: 'N' },
    { word: 'oppressed', word_class: 'AJ' },
    { word: 'oppressive', word_class: 'AJ' },
    { word: 'oppressively', word_class: 'AV' },
    { word: 'oppressiveness', word_class: 'N' }
  ],
  relevancy: [
    { word: 'relevance', word_class: 'N' },
    { word: 'relevancy', word_class: 'N' },
    { word: 'relevant', word_class: 'AJ' }
  ],
  participance: [
    { word: 'participant', word_class: 'N' },
    { word: 'participate', word_class: 'V' },
    { word: 'participance', word_class: 'N' },
    { word: 'participation', word_class: 'N' },
    { word: 'participating', word_class: 'AJ' }
  ],
  thimbleful: [
    { word: 'thimble', word_class: 'N' },
    { word: 'thimbleful', word_class: 'N' },
    { word: 'thimbleful', word_class: 'AJ' }
  ],
  depreciator: [
    { word: 'depreciate', word_class: 'V' },
    { word: 'depreciator', word_class: 'N' },
    { word: 'depreciable', word_class: 'AJ' },
    { word: 'depreciation', word_class: 'N' },
    { word: 'depreciating', word_class: 'AJ' },
    { word: 'depreciative', word_class: 'AJ' }
  ],
  licenced: [
    { word: 'licence', word_class: 'N' },
    { word: 'licence', word_class: 'V' },
    { word: 'licenced', word_class: 'AJ' }
  ],
  philhellene: [
    { word: 'philhellene', word_class: 'N' },
    { word: 'philhellene', word_class: 'AJ' },
    { word: 'philhellenic', word_class: 'AJ' }
  ],
  charged: [
    { word: 'charge', word_class: 'N' },
    { word: 'charge', word_class: 'V' },
    { word: 'charger', word_class: 'N' },
    { word: 'charged', word_class: 'AJ' }
  ],
  absolute: [
    { word: 'absolve', word_class: 'V' },
    { word: 'absolute', word_class: 'N' },
    { word: 'absolver', word_class: 'N' },
    { word: 'absolute', word_class: 'AJ' },
    { word: 'absolved', word_class: 'AJ' },
    { word: 'absolution', word_class: 'N' },
    { word: 'absolutism', word_class: 'N' },
    { word: 'absolutely', word_class: 'AV' },
    { word: 'absoluteness', word_class: 'N' }
  ],
  debite: [{ word: 'debit', word_class: 'N' }, { word: 'debit', word_class: 'V' }, { word: 'debite', word_class: 'V' }],
  buck: [
    { word: 'buck', word_class: 'N' },
    { word: 'buck', word_class: 'V' },
    { word: 'buck', word_class: 'AJ' },
    { word: 'bucked', word_class: 'AJ' }
  ],
  woken: [
    { word: 'wake', word_class: 'N' },
    { word: 'wake', word_class: 'V' },
    { word: 'waking', word_class: 'N' },
    { word: 'woken', word_class: 'AJ' },
    { word: 'waking', word_class: 'AJ' },
    { word: 'wakeful', word_class: 'AJ' },
    { word: 'wakefulness', word_class: 'N' }
  ],
  radio: [{ word: 'radio', word_class: 'N' }, { word: 'radio', word_class: 'V' }, { word: 'radio', word_class: 'AJ' }],
  entailment: [
    { word: 'entail', word_class: 'N' },
    { word: 'entail', word_class: 'V' },
    { word: 'entailment', word_class: 'N' }
  ],
  much: [
    { word: 'much', word_class: 'N' },
    { word: 'much', word_class: 'AJ' },
    { word: 'much', word_class: 'AV' },
    { word: 'muchness', word_class: 'N' }
  ],
  sops: [
    { word: 'sop', word_class: 'N' },
    { word: 'sop', word_class: 'V' },
    { word: 'sops', word_class: 'N' },
    { word: 'sopping', word_class: 'AJ' },
    { word: 'sopping', word_class: 'AV' }
  ],
  momentous: [
    { word: 'moment', word_class: 'N' },
    { word: 'momently', word_class: 'AV' },
    { word: 'momentary', word_class: 'AJ' },
    { word: 'momentous', word_class: 'AJ' },
    { word: 'momentously', word_class: 'AV' },
    { word: 'momentousness', word_class: 'N' }
  ],
  comer: [
    { word: 'come', word_class: 'V' },
    { word: 'come', word_class: 'AJ' },
    { word: 'comer', word_class: 'N' },
    { word: 'coming', word_class: 'N' },
    { word: 'comely', word_class: 'AJ' },
    { word: 'coming', word_class: 'AJ' }
  ],
  disclosed: [
    { word: 'disclose', word_class: 'V' },
    { word: 'disclosed', word_class: 'AJ' },
    { word: 'disclosure', word_class: 'N' },
    { word: 'disclosing', word_class: 'AJ' }
  ],
  leer: [{ word: 'leer', word_class: 'N' }, { word: 'leer', word_class: 'V' }, { word: 'leering', word_class: 'AJ' }],
  protester: [
    { word: 'ation', word_class: 'N' },
    { word: 'protest', word_class: 'N' },
    { word: 'protest', word_class: 'V' },
    { word: 'protester', word_class: 'N' },
    { word: 'protestant', word_class: 'N' },
    { word: 'protestant', word_class: 'AJ' },
    { word: 'protesting', word_class: 'AJ' },
    { word: 'protestation', word_class: 'N' }
  ],
  foaming: [
    { word: 'foam', word_class: 'N' },
    { word: 'foam', word_class: 'V' },
    { word: 'foaming', word_class: 'AJ' }
  ],
  diverse: [
    { word: 'divert', word_class: 'V' },
    { word: 'diverse', word_class: 'N' },
    { word: 'diverse', word_class: 'V' },
    { word: 'diverse', word_class: 'AJ' },
    { word: 'diversion', word_class: 'N' },
    { word: 'diversity', word_class: 'N' },
    { word: 'diverted', word_class: 'AJ' },
    { word: 'diversely', word_class: 'AV' },
    { word: 'diverting', word_class: 'AJ' },
    { word: 'diverseness', word_class: 'N' },
    { word: 'diversionary', word_class: 'AJ' }
  ],
  ovulation: [
    { word: 'ovule', word_class: 'N' },
    { word: 'ovulate', word_class: 'V' },
    { word: 'ovulation', word_class: 'N' }
  ],
  zoroastrian: [
    { word: 'zoroastrian', word_class: 'N' },
    { word: 'zoroastrian', word_class: 'AJ' },
    { word: 'zoroastrianism', word_class: 'N' }
  ],
  movableness: [
    { word: 'movable', word_class: 'AJ' },
    { word: 'movability', word_class: 'N' },
    { word: 'movableness', word_class: 'N' }
  ],
  acidulent: [
    { word: 'acidulate', word_class: 'V' },
    { word: 'acidulent', word_class: 'AJ' },
    { word: 'acidulous', word_class: 'AJ' },
    { word: 'acidulated', word_class: 'AJ' },
    { word: 'acidulation', word_class: 'N' },
    { word: 'acidulousness', word_class: 'N' }
  ],
  despicableness: [
    { word: 'despise', word_class: 'V' },
    { word: 'despite', word_class: 'N' },
    { word: 'despisal', word_class: 'N' },
    { word: 'despised', word_class: 'AJ' },
    { word: 'despising', word_class: 'N' },
    { word: 'despicable', word_class: 'AJ' },
    { word: 'despicably', word_class: 'AV' },
    { word: 'despiteful', word_class: 'AJ' },
    { word: 'despicability', word_class: 'N' },
    { word: 'despicableness', word_class: 'N' }
  ],
  depersonalize: [
    { word: 'depersonalise', word_class: 'V' },
    { word: 'depersonalize', word_class: 'V' },
    { word: 'depersonalisation', word_class: 'N' },
    { word: 'depersonalization', word_class: 'N' }
  ],
  scrub: [
    { word: 'scrub', word_class: 'N' },
    { word: 'scrub', word_class: 'V' },
    { word: 'scrub', word_class: 'AJ' },
    { word: 'scrubs', word_class: 'N' },
    { word: 'scrubbed', word_class: 'AJ' },
    { word: 'scrubbing', word_class: 'N' }
  ],
  crimination: [
    { word: 'crime', word_class: 'N' },
    { word: 'criminal', word_class: 'N' },
    { word: 'criminal', word_class: 'AJ' },
    { word: 'criminate', word_class: 'V' },
    { word: 'crimination', word_class: 'N' },
    { word: 'criminality', word_class: 'N' },
    { word: 'criminalism', word_class: 'N' },
    { word: 'criminalize', word_class: 'V' },
    { word: 'criminally', word_class: 'AV' },
    { word: 'criminalness', word_class: 'N' },
    { word: 'criminative', word_class: 'AJ' }
  ],
  asserted: [
    { word: 'assert', word_class: 'V' },
    { word: 'asserted', word_class: 'AJ' },
    { word: 'assertion', word_class: 'N' },
    { word: 'asserting', word_class: 'AJ' },
    { word: 'assertive', word_class: 'AJ' },
    { word: 'asserttion', word_class: 'N' },
    { word: 'assertable', word_class: 'AJ' },
    { word: 'assertively', word_class: 'AV' },
    { word: 'assertiveness', word_class: 'N' }
  ],
  collected: [
    { word: 'collect', word_class: 'N' },
    { word: 'collect', word_class: 'V' },
    { word: 'collect', word_class: 'AJ' },
    { word: 'collect', word_class: 'AV' },
    { word: 'collector', word_class: 'N' },
    { word: 'collected', word_class: 'AJ' },
    { word: 'collecting', word_class: 'N' },
    { word: 'collection', word_class: 'N' },
    { word: 'collective', word_class: 'N' },
    { word: 'collective', word_class: 'AJ' },
    { word: 'collectable', word_class: 'AJ' },
    { word: 'collectible', word_class: 'AJ' },
    { word: 'collectively', word_class: 'AV' }
  ],
  venturesome: [
    { word: 'venture', word_class: 'N' },
    { word: 'venture', word_class: 'V' },
    { word: 'venturous', word_class: 'AJ' },
    { word: 'venturesome', word_class: 'AJ' },
    { word: 'venturesomeness', word_class: 'N' }
  ],
  unexpressible: [
    { word: 'unexpressed', word_class: 'AJ' },
    { word: 'unexpressive', word_class: 'AJ' },
    { word: 'unexpressible', word_class: 'AJ' }
  ],
  anathematise: [
    { word: 'anathematise', word_class: 'V' },
    { word: 'anathematize', word_class: 'V' },
    { word: 'anathematisation', word_class: 'N' },
    { word: 'anathematization', word_class: 'N' }
  ],
  sorted: [
    { word: 'sort', word_class: 'N' },
    { word: 'sort', word_class: 'V' },
    { word: 'sorter', word_class: 'N' },
    { word: 'sorted', word_class: 'AJ' },
    { word: 'sorting', word_class: 'N' }
  ],
  tossing: [
    { word: 'toss', word_class: 'N' },
    { word: 'toss', word_class: 'V' },
    { word: 'tossing', word_class: 'AJ' }
  ],
  attained: [
    { word: 'attain', word_class: 'V' },
    { word: 'attained', word_class: 'AJ' },
    { word: 'attainment', word_class: 'N' },
    { word: 'attainable', word_class: 'AJ' },
    { word: 'attainability', word_class: 'N' },
    { word: 'attainableness', word_class: 'N' }
  ],
  launder: [
    { word: 'launder', word_class: 'V' },
    { word: 'laundry', word_class: 'N' },
    { word: 'laundering', word_class: 'N' }
  ],
  grilling: [
    { word: 'grill', word_class: 'N' },
    { word: 'grill', word_class: 'V' },
    { word: 'grille', word_class: 'N' },
    { word: 'grilled', word_class: 'AJ' },
    { word: 'grilling', word_class: 'N' }
  ],
  subtilise: [
    { word: 'subtilise', word_class: 'V' },
    { word: 'subtilize', word_class: 'V' },
    { word: 'subtilisation', word_class: 'N' },
    { word: 'subtilization', word_class: 'N' }
  ],
  rubberized: [
    { word: 'rubber', word_class: 'N' },
    { word: 'rubber', word_class: 'V' },
    { word: 'rubber', word_class: 'AJ' },
    { word: 'rubberize', word_class: 'V' },
    { word: 'rubberized', word_class: 'AJ' },
    { word: 'rubberization', word_class: 'N' }
  ],
  cynic: [
    { word: 'cynic', word_class: 'N' },
    { word: 'cynical', word_class: 'AJ' },
    { word: 'cynicism', word_class: 'N' },
    { word: 'cynically', word_class: 'AV' }
  ],
  squirting: [
    { word: 'squirt', word_class: 'N' },
    { word: 'squirt', word_class: 'V' },
    { word: 'squirting', word_class: 'AJ' }
  ],
  buckler: [
    { word: 'buckle', word_class: 'N' },
    { word: 'buckle', word_class: 'V' },
    { word: 'buckler', word_class: 'N' }
  ],
  fraternal: [
    { word: 'fraternal', word_class: 'AJ' },
    { word: 'fraternity', word_class: 'N' },
    { word: 'fraternize', word_class: 'V' },
    { word: 'fraternally', word_class: 'AV' },
    { word: 'fraternization', word_class: 'N' }
  ],
  playaction: [
    { word: 'playact', word_class: 'V' },
    { word: 'playacting', word_class: 'N' },
    { word: 'playaction', word_class: 'N' }
  ],
  categorized: [
    { word: 'category', word_class: 'N' },
    { word: 'category', word_class: 'V' },
    { word: 'categoric', word_class: 'AJ' },
    { word: 'categorize', word_class: 'V' },
    { word: 'categorise', word_class: 'V' },
    { word: 'categorial', word_class: 'AJ' },
    { word: 'categorical', word_class: 'AJ' },
    { word: 'categorized', word_class: 'AJ' },
    { word: 'categorisation', word_class: 'N' },
    { word: 'categorically', word_class: 'AV' },
    { word: 'categorization', word_class: 'N' }
  ],
  noticeable: [
    { word: 'notice', word_class: 'N' },
    { word: 'notice', word_class: 'V' },
    { word: 'noticed', word_class: 'AJ' },
    { word: 'noticeable', word_class: 'AJ' },
    { word: 'noticeably', word_class: 'AV' },
    { word: 'noticeability', word_class: 'N' },
    { word: 'noticeableness', word_class: 'N' }
  ],
  meddle: [
    { word: 'meddle', word_class: 'V' },
    { word: 'meddler', word_class: 'N' },
    { word: 'meddling', word_class: 'N' },
    { word: 'meddling', word_class: 'AJ' },
    { word: 'meddlesome', word_class: 'AJ' },
    { word: 'meddlesomeness', word_class: 'N' }
  ],
  provability: [
    { word: 'provable', word_class: 'AJ' },
    { word: 'provably', word_class: 'AV' },
    { word: 'provability', word_class: 'N' }
  ],
  effloresce: [
    { word: 'effloresce', word_class: 'V' },
    { word: 'efflorescence', word_class: 'N' },
    { word: 'efflorescent', word_class: 'AJ' }
  ],
  burying: [
    { word: 'bury', word_class: 'V' },
    { word: 'burial', word_class: 'N' },
    { word: 'burier', word_class: 'N' },
    { word: 'buried', word_class: 'AJ' },
    { word: 'burying', word_class: 'N' }
  ],
  planned: [
    { word: 'plan', word_class: 'N' },
    { word: 'plan', word_class: 'V' },
    { word: 'planned', word_class: 'AJ' },
    { word: 'planning', word_class: 'N' }
  ],
  deteriorating: [
    { word: 'deteriorate', word_class: 'V' },
    { word: 'deterioration', word_class: 'N' },
    { word: 'deteriorating', word_class: 'AJ' }
  ],
  dart: [
    { word: 'dart', word_class: 'N' },
    { word: 'dart', word_class: 'V' },
    { word: 'darts', word_class: 'N' },
    { word: 'darter', word_class: 'N' },
    { word: 'darting', word_class: 'AJ' }
  ],
  erosion: [
    { word: 'erode', word_class: 'V' },
    { word: 'erose', word_class: 'AJ' },
    { word: 'eroding', word_class: 'N' },
    { word: 'eroded', word_class: 'AJ' },
    { word: 'erosion', word_class: 'N' },
    { word: 'erosive', word_class: 'AJ' },
    { word: 'erosible', word_class: 'AJ' }
  ],
  relaxed: [
    { word: 'relax', word_class: 'V' },
    { word: 'relax', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'N' },
    { word: 'relaxed', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'AJ' },
    { word: 'relaxing', word_class: 'AJ' },
    { word: 'relaxation', word_class: 'N' }
  ],
  shrieking: [
    { word: 'shriek', word_class: 'N' },
    { word: 'shriek', word_class: 'V' },
    { word: 'shrieked', word_class: 'AJ' },
    { word: 'shrieking', word_class: 'N' },
    { word: 'shrieking', word_class: 'AJ' }
  ],
  confidentiality: [
    { word: 'confide', word_class: 'V' },
    { word: 'confidant', word_class: 'N' },
    { word: 'confidence', word_class: 'N' },
    { word: 'confident', word_class: 'AJ' },
    { word: 'confiding', word_class: 'AJ' },
    { word: 'confidently', word_class: 'AV' },
    { word: 'confidential', word_class: 'AJ' },
    { word: 'confidentiality', word_class: 'N' },
    { word: 'confidentially', word_class: 'AV' }
  ],
  prodigality: [
    { word: 'prodigal', word_class: 'N' },
    { word: 'prodigal', word_class: 'AJ' },
    { word: 'prodigality', word_class: 'N' },
    { word: 'prodigally', word_class: 'AV' }
  ],
  lactate: [
    { word: 'lactate', word_class: 'V' },
    { word: 'lactation', word_class: 'N' },
    { word: 'lactating', word_class: 'AJ' }
  ],
  recruiter: [
    { word: 'recruit', word_class: 'N' },
    { word: 'recruit', word_class: 'V' },
    { word: 'recruiter', word_class: 'N' },
    { word: 'recruitment', word_class: 'N' }
  ],
  whopping: [
    { word: 'whop', word_class: 'N' },
    { word: 'whop', word_class: 'V' },
    { word: 'whopping', word_class: 'AJ' },
    { word: 'whopping', word_class: 'AV' }
  ],
  disincline: [
    { word: 'disincline', word_class: 'V' },
    { word: 'disinclined', word_class: 'AJ' },
    { word: 'disinclination', word_class: 'N' }
  ],
  duck: [{ word: 'duck', word_class: 'N' }, { word: 'duck', word_class: 'V' }, { word: 'ducking', word_class: 'N' }],
  rontgenization: [
    { word: 'roentgen', word_class: 'N' },
    { word: 'roentgenise', word_class: 'V' },
    { word: 'roentgenize', word_class: 'V' },
    { word: 'rontgenisation', word_class: 'N' },
    { word: 'rontgenization', word_class: 'N' },
    { word: 'roentgenisation', word_class: 'N' },
    { word: 'roentgenization', word_class: 'N' }
  ],
  paint: [
    { word: 'paint', word_class: 'N' },
    { word: 'paint', word_class: 'V' },
    { word: 'painter', word_class: 'N' },
    { word: 'painted', word_class: 'AJ' },
    { word: 'painting', word_class: 'N' }
  ],
  rectifiable: [
    { word: 'rectify', word_class: 'V' },
    { word: 'rectifier', word_class: 'N' },
    { word: 'rectified', word_class: 'AJ' },
    { word: 'rectifiable', word_class: 'AJ' },
    { word: 'rectification', word_class: 'N' }
  ],
  camp: [
    { word: 'camp', word_class: 'N' },
    { word: 'camp', word_class: 'V' },
    { word: 'camp', word_class: 'AJ' },
    { word: 'camper', word_class: 'N' },
    { word: 'camping', word_class: 'N' },
    { word: 'campion', word_class: 'N' }
  ],
  charmer: [
    { word: 'charm', word_class: 'N' },
    { word: 'charm', word_class: 'V' },
    { word: 'charmer', word_class: 'N' },
    { word: 'charmed', word_class: 'AJ' },
    { word: 'charming', word_class: 'AJ' }
  ],
  trick: [
    { word: 'trick', word_class: 'N' },
    { word: 'trick', word_class: 'V' },
    { word: 'trickery', word_class: 'N' },
    { word: 'trickery', word_class: 'AJ' }
  ],
  rhythmic: [
    { word: 'rhythmic', word_class: 'AJ' },
    { word: 'rhythmical', word_class: 'AJ' },
    { word: 'rhythmicity', word_class: 'N' },
    { word: 'rhythmically', word_class: 'AV' }
  ],
  cleavers: [
    { word: 'cleave', word_class: 'V' },
    { word: 'cleaver', word_class: 'N' },
    { word: 'cloven', word_class: 'AJ' },
    { word: 'cleavers', word_class: 'N' }
  ],
  attuned: [
    { word: 'attune', word_class: 'V' },
    { word: 'attuned', word_class: 'AJ' },
    { word: 'attunement', word_class: 'N' }
  ],
  liquesce: [
    { word: 'liquesce', word_class: 'V' },
    { word: 'liquescence', word_class: 'N' },
    { word: 'liquescent', word_class: 'AJ' }
  ],
  wanton: [
    { word: 'wanton', word_class: 'N' },
    { word: 'wanton', word_class: 'V' },
    { word: 'wanton', word_class: 'AJ' },
    { word: 'wantonness', word_class: 'N' }
  ],
  scorned: [
    { word: 'scorn', word_class: 'N' },
    { word: 'scorn', word_class: 'V' },
    { word: 'scorned', word_class: 'AJ' },
    { word: 'scornful', word_class: 'AJ' }
  ],
  cretinous: [
    { word: 'cretin', word_class: 'N' },
    { word: 'cretinism', word_class: 'N' },
    { word: 'cretinous', word_class: 'AJ' }
  ],
  equator: [
    { word: 'equate', word_class: 'V' },
    { word: 'equator', word_class: 'N' },
    { word: 'equating', word_class: 'N' },
    { word: 'equation', word_class: 'N' },
    { word: 'equatability', word_class: 'N' }
  ],
  refresher: [
    { word: 'refresh', word_class: 'V' },
    { word: 'refresh', word_class: 'AJ' },
    { word: 'refresher', word_class: 'N' },
    { word: 'refreshed', word_class: 'AJ' },
    { word: 'refreshful', word_class: 'AJ' },
    { word: 'refreshing', word_class: 'AJ' },
    { word: 'refreshment', word_class: 'N' }
  ],
  filler: [
    { word: 'fill', word_class: 'N' },
    { word: 'fill', word_class: 'V' },
    { word: 'fille', word_class: 'N' },
    { word: 'filler', word_class: 'N' },
    { word: 'filled', word_class: 'AJ' },
    { word: 'filling', word_class: 'N' }
  ],
  devastating: [
    { word: 'devastate', word_class: 'V' },
    { word: 'devastated', word_class: 'AJ' },
    { word: 'devastation', word_class: 'N' },
    { word: 'devastating', word_class: 'AJ' }
  ],
  lodgment: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  assignment: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  rant: [
    { word: 'rant', word_class: 'N' },
    { word: 'rant', word_class: 'V' },
    { word: 'ranter', word_class: 'N' },
    { word: 'ranting', word_class: 'N' }
  ],
  preponderant: [
    { word: 'preponderant', word_class: 'N' },
    { word: 'preponderate', word_class: 'V' },
    { word: 'preponderance', word_class: 'N' },
    { word: 'preponderant', word_class: 'AJ' },
    { word: 'preponderation', word_class: 'N' },
    { word: 'preponderating', word_class: 'AJ' }
  ],
  southwestern: [
    { word: 'southwestern', word_class: 'N' },
    { word: 'southwestern', word_class: 'AJ' },
    { word: 'southwesterner', word_class: 'N' }
  ],
  acid: [
    { word: 'acid', word_class: 'N' },
    { word: 'acid', word_class: 'AJ' },
    { word: 'acidic', word_class: 'AJ' },
    { word: 'acidity', word_class: 'N' }
  ],
  appendix: [
    { word: 'append', word_class: 'V' },
    { word: 'appendix', word_class: 'N' },
    { word: 'appendage', word_class: 'N' },
    { word: 'appendant', word_class: 'AJ' },
    { word: 'appendaged', word_class: 'AJ' },
    { word: 'appendicitis', word_class: 'N' }
  ],
  astronautical: [
    { word: 'astronaut', word_class: 'N' },
    { word: 'astronautic', word_class: 'AJ' },
    { word: 'astronautics', word_class: 'N' },
    { word: 'astronautical', word_class: 'AJ' }
  ],
  indentured: [
    { word: 'indent', word_class: 'N' },
    { word: 'indent', word_class: 'V' },
    { word: 'indenture', word_class: 'N' },
    { word: 'indenture', word_class: 'V' },
    { word: 'indented', word_class: 'AJ' },
    { word: 'indentation', word_class: 'N' },
    { word: 'indentured', word_class: 'AJ' }
  ],
  roof: [
    { word: 'roof', word_class: 'N' },
    { word: 'roof', word_class: 'V' },
    { word: 'roofer', word_class: 'N' },
    { word: 'roofed', word_class: 'AJ' },
    { word: 'roofing', word_class: 'N' }
  ],
  frontally: [
    { word: 'front', word_class: 'N' },
    { word: 'front', word_class: 'V' },
    { word: 'front', word_class: 'AJ' },
    { word: 'frontal', word_class: 'N' },
    { word: 'frontage', word_class: 'N' },
    { word: 'frontal', word_class: 'AJ' },
    { word: 'frontally', word_class: 'AV' }
  ],
  'double-space': [
    { word: 'double-space', word_class: 'V' },
    { word: 'double-spaced', word_class: 'AJ' },
    { word: 'double-spacing', word_class: 'N' }
  ],
  shy: [
    { word: 'shy', word_class: 'N' },
    { word: 'shy', word_class: 'V' },
    { word: 'shy', word_class: 'AJ' },
    { word: 'shiite', word_class: 'N' },
    { word: 'shyness', word_class: 'N' }
  ],
  satanism: [
    { word: 'satan', word_class: 'N' },
    { word: 'satanic', word_class: 'AJ' },
    { word: 'satanism', word_class: 'N' }
  ],
  demolished: [
    { word: 'demolish', word_class: 'V' },
    { word: 'demolition', word_class: 'N' },
    { word: 'demolished', word_class: 'AJ' },
    { word: 'demolishing', word_class: 'N' },
    { word: 'demolishment', word_class: 'N' }
  ],
  forwards: [
    { word: 'forward', word_class: 'N' },
    { word: 'forward', word_class: 'V' },
    { word: 'forward', word_class: 'AJ' },
    { word: 'forward', word_class: 'AV' },
    { word: 'forwards', word_class: 'AV' },
    { word: 'forwarding', word_class: 'N' },
    { word: 'forwardness', word_class: 'N' }
  ],
  reminisce: [
    { word: 'reminisce', word_class: 'V' },
    { word: 'reminiscence', word_class: 'N' },
    { word: 'reminiscent', word_class: 'AJ' },
    { word: 'reminiscently', word_class: 'AV' }
  ],
  originator: [
    { word: 'origin', word_class: 'N' },
    { word: 'original', word_class: 'N' },
    { word: 'original', word_class: 'AJ' },
    { word: 'originate', word_class: 'V' },
    { word: 'originator', word_class: 'N' },
    { word: 'originalism', word_class: 'N' },
    { word: 'originality', word_class: 'N' },
    { word: 'originally', word_class: 'AV' },
    { word: 'origination', word_class: 'N' },
    { word: 'originative', word_class: 'AJ' }
  ],
  warp: [
    { word: 'warp', word_class: 'N' },
    { word: 'warp', word_class: 'V' },
    { word: 'warped', word_class: 'AJ' },
    { word: 'warping', word_class: 'N' }
  ],
  transpiring: [
    { word: 'transpire', word_class: 'V' },
    { word: 'transpirate', word_class: 'V' },
    { word: 'transpiring', word_class: 'AJ' },
    { word: 'transpiration', word_class: 'N' }
  ],
  spotting: [
    { word: 'spot', word_class: 'N' },
    { word: 'spot', word_class: 'V' },
    { word: 'spotted', word_class: 'AJ' },
    { word: 'spotting', word_class: 'N' }
  ],
  farming: [
    { word: 'farm', word_class: 'N' },
    { word: 'farm', word_class: 'V' },
    { word: 'farm', word_class: 'AJ' },
    { word: 'farmer', word_class: 'N' },
    { word: 'farming', word_class: 'N' },
    { word: 'farming', word_class: 'AJ' }
  ],
  divinity: [
    { word: 'divine', word_class: 'N' },
    { word: 'divine', word_class: 'V' },
    { word: 'divine', word_class: 'AJ' },
    { word: 'diviner', word_class: 'N' },
    { word: 'divinise', word_class: 'V' },
    { word: 'divinize', word_class: 'V' },
    { word: 'divinity', word_class: 'N' },
    { word: 'divinely', word_class: 'AV' },
    { word: 'divination', word_class: 'N' },
    { word: 'divinisation', word_class: 'N' },
    { word: 'divinization', word_class: 'N' }
  ],
  deadening: [
    { word: 'deaden', word_class: 'V' },
    { word: 'deadened', word_class: 'AJ' },
    { word: 'deadening', word_class: 'N' },
    { word: 'deadening', word_class: 'AJ' }
  ],
  ergotism: [
    { word: 'ergot', word_class: 'N' },
    { word: 'ergotic', word_class: 'AJ' },
    { word: 'ergotism', word_class: 'N' }
  ],
  morbidness: [
    { word: 'morbid', word_class: 'AJ' },
    { word: 'morbidity', word_class: 'N' },
    { word: 'morbidness', word_class: 'N' }
  ],
  spoiled: [
    { word: 'spoil', word_class: 'N' },
    { word: 'spoil', word_class: 'V' },
    { word: 'spoiler', word_class: 'N' },
    { word: 'spoilage', word_class: 'N' },
    { word: 'spoiled', word_class: 'AJ' },
    { word: 'spoiling', word_class: 'N' },
    { word: 'spoilation', word_class: 'N' }
  ],
  alleviative: [
    { word: 'alleviate', word_class: 'V' },
    { word: 'alleviated', word_class: 'AJ' },
    { word: 'alleviation', word_class: 'N' },
    { word: 'alleviative', word_class: 'AJ' }
  ],
  crochet: [
    { word: 'crochet', word_class: 'N' },
    { word: 'crochet', word_class: 'V' },
    { word: 'crocheting', word_class: 'N' }
  ],
  forsake: [
    { word: 'forsake', word_class: 'V' },
    { word: 'forsaken', word_class: 'AJ' },
    { word: 'forsaking', word_class: 'N' }
  ],
  shaver: [
    { word: 'shave', word_class: 'N' },
    { word: 'shave', word_class: 'V' },
    { word: 'shaver', word_class: 'N' },
    { word: 'shaved', word_class: 'AJ' },
    { word: 'shaven', word_class: 'AJ' },
    { word: 'shaving', word_class: 'N' }
  ],
  shelve: [
    { word: 'shelf', word_class: 'N' },
    { word: 'shelve', word_class: 'V' },
    { word: 'shelfful', word_class: 'N' },
    { word: 'shelving', word_class: 'N' },
    { word: 'shelfful', word_class: 'AJ' }
  ],
  abstracter: [
    { word: 'abstract', word_class: 'N' },
    { word: 'abstract', word_class: 'V' },
    { word: 'abstract', word_class: 'AJ' },
    { word: 'abstracter', word_class: 'N' },
    { word: 'abstractor', word_class: 'N' },
    { word: 'abstracted', word_class: 'AJ' },
    { word: 'abstraction', word_class: 'N' },
    { word: 'abstractive', word_class: 'AJ' },
    { word: 'abstractness', word_class: 'N' }
  ],
  motivity: [
    { word: 'motive', word_class: 'N' },
    { word: 'motive', word_class: 'V' },
    { word: 'motive', word_class: 'AJ' },
    { word: 'motivate', word_class: 'V' },
    { word: 'motivity', word_class: 'N' },
    { word: 'motivation', word_class: 'N' },
    { word: 'motivated', word_class: 'AJ' },
    { word: 'motivating', word_class: 'AJ' },
    { word: 'motivative', word_class: 'AJ' },
    { word: 'motivational', word_class: 'AJ' }
  ],
  chlorophyll: [
    { word: 'chlorophyl', word_class: 'N' },
    { word: 'chlorophyll', word_class: 'N' },
    { word: 'chlorophyllous', word_class: 'AJ' }
  ],
  submergible: [
    { word: 'submerge', word_class: 'V' },
    { word: 'submerse', word_class: 'V' },
    { word: 'submerged', word_class: 'AJ' },
    { word: 'submerging', word_class: 'N' },
    { word: 'submersed', word_class: 'AJ' },
    { word: 'submersion', word_class: 'N' },
    { word: 'submergence', word_class: 'N' },
    { word: 'submersible', word_class: 'N' },
    { word: 'submergible', word_class: 'AJ' },
    { word: 'submersible', word_class: 'AJ' }
  ],
  hydrolyzation: [
    { word: 'hydrolyse', word_class: 'V' },
    { word: 'hydrolyze', word_class: 'V' },
    { word: 'hydrolysis', word_class: 'N' },
    { word: 'hydrolysate', word_class: 'N' },
    { word: 'hydrolyzable', word_class: 'AJ' },
    { word: 'hydrolyzation', word_class: 'N' }
  ],
  evangel: [
    { word: 'evangel', word_class: 'N' },
    { word: 'evangelise', word_class: 'V' },
    { word: 'evangelist', word_class: 'N' },
    { word: 'evangelize', word_class: 'V' },
    { word: 'evangelism', word_class: 'N' },
    { word: 'evangelical', word_class: 'N' },
    { word: 'evangelical', word_class: 'AJ' },
    { word: 'evangelistic', word_class: 'AJ' },
    { word: 'evangelisation', word_class: 'N' },
    { word: 'evangelicalism', word_class: 'N' },
    { word: 'evangelization', word_class: 'N' }
  ],
  malted: [
    { word: 'malt', word_class: 'N' },
    { word: 'malt', word_class: 'V' },
    { word: 'malted', word_class: 'N' },
    { word: 'maltman', word_class: 'N' }
  ],
  truckling: [
    { word: 'truckle', word_class: 'N' },
    { word: 'truckle', word_class: 'V' },
    { word: 'truckling', word_class: 'N' }
  ],
  graecophilic: [
    { word: 'graecophile', word_class: 'N' },
    { word: 'graecophile', word_class: 'AJ' },
    { word: 'graecophilic', word_class: 'AJ' }
  ],
  seductively: [
    { word: 'seduce', word_class: 'V' },
    { word: 'seducer', word_class: 'N' },
    { word: 'seduced', word_class: 'AJ' },
    { word: 'seduction', word_class: 'N' },
    { word: 'seductive', word_class: 'AJ' },
    { word: 'seductively', word_class: 'AV' }
  ],
  indiction: [
    { word: 'indict', word_class: 'V' },
    { word: 'indiction', word_class: 'N' },
    { word: 'indictment', word_class: 'N' },
    { word: 'indictable', word_class: 'AJ' },
    { word: 'indictability', word_class: 'N' }
  ],
  issue: [
    { word: 'issue', word_class: 'N' },
    { word: 'issue', word_class: 'V' },
    { word: 'issus', word_class: 'N' },
    { word: 'issuer', word_class: 'N' },
    { word: 'issuant', word_class: 'N' },
    { word: 'issuing', word_class: 'N' },
    { word: 'issuance', word_class: 'N' },
    { word: 'issuant', word_class: 'AJ' }
  ],
  flirtatious: [
    { word: 'flirt', word_class: 'N' },
    { word: 'flirt', word_class: 'V' },
    { word: 'flirting', word_class: 'N' },
    { word: 'flirtation', word_class: 'N' },
    { word: 'flirtatious', word_class: 'AJ' },
    { word: 'flirtatiously', word_class: 'AV' }
  ],
  conveyer: [
    { word: 'convey', word_class: 'V' },
    { word: 'conveyer', word_class: 'N' },
    { word: 'conveyor', word_class: 'N' },
    { word: 'conveyed', word_class: 'AJ' },
    { word: 'conveying', word_class: 'N' },
    { word: 'conveyance', word_class: 'N' },
    { word: 'conveyable', word_class: 'AJ' }
  ],
  salve: [
    { word: 'salve', word_class: 'N' },
    { word: 'salve', word_class: 'V' },
    { word: 'salver', word_class: 'N' },
    { word: 'salvor', word_class: 'N' },
    { word: 'salving', word_class: 'AJ' },
    { word: 'salvation', word_class: 'N' }
  ],
  sewn: [
    { word: 'sew', word_class: 'V' },
    { word: 'sewer', word_class: 'N' },
    { word: 'sewn', word_class: 'AJ' },
    { word: 'sewed', word_class: 'AJ' },
    { word: 'sewing', word_class: 'N' },
    { word: 'sewerage', word_class: 'N' }
  ],
  backed: [
    { word: 'back', word_class: 'N' },
    { word: 'back', word_class: 'V' },
    { word: 'back', word_class: 'AJ' },
    { word: 'back', word_class: 'AV' },
    { word: 'backer', word_class: 'N' },
    { word: 'backed', word_class: 'AJ' },
    { word: 'backing', word_class: 'N' }
  ],
  susceptibly: [
    { word: 'susceptible', word_class: 'AJ' },
    { word: 'susceptibly', word_class: 'AV' },
    { word: 'susceptibility', word_class: 'N' },
    { word: 'susceptibleness', word_class: 'N' }
  ],
  decree: [
    { word: 'decree', word_class: 'N' },
    { word: 'decree', word_class: 'V' },
    { word: 'decreed', word_class: 'AJ' },
    { word: 'decrement', word_class: 'N' },
    { word: 'decrement', word_class: 'V' }
  ],
  whoredom: [
    { word: 'whore', word_class: 'N' },
    { word: 'whore', word_class: 'V' },
    { word: 'whoredom', word_class: 'N' }
  ],
  decoding: [
    { word: 'decode', word_class: 'V' },
    { word: 'decoder', word_class: 'N' },
    { word: 'decoding', word_class: 'N' }
  ],
  unforgiving: [
    { word: 'unforgiving', word_class: 'AJ' },
    { word: 'unforgivable', word_class: 'AJ' },
    { word: 'unforgivably', word_class: 'AV' }
  ],
  centralized: [
    { word: 'center', word_class: 'N' },
    { word: 'center', word_class: 'V' },
    { word: 'centre', word_class: 'N' },
    { word: 'centre', word_class: 'V' },
    { word: 'central', word_class: 'N' },
    { word: 'center', word_class: 'AJ' },
    { word: 'centred', word_class: 'N' },
    { word: 'central', word_class: 'AJ' },
    { word: 'centric', word_class: 'AJ' },
    { word: 'centrist', word_class: 'N' },
    { word: 'centroid', word_class: 'N' },
    { word: 'centred', word_class: 'AJ' },
    { word: 'centered', word_class: 'AJ' },
    { word: 'centering', word_class: 'N' },
    { word: 'centrist', word_class: 'AJ' },
    { word: 'centrality', word_class: 'N' },
    { word: 'centralize', word_class: 'V' },
    { word: 'centrally', word_class: 'AV' },
    { word: 'centrical', word_class: 'AJ' },
    { word: 'centroidal', word_class: 'AJ' },
    { word: 'centralized', word_class: 'AJ' },
    { word: 'centralizing', word_class: 'AJ' },
    { word: 'centralisation', word_class: 'N' },
    { word: 'centralization', word_class: 'N' }
  ],
  owed: [{ word: 'owe', word_class: 'V' }, { word: 'owed', word_class: 'AJ' }, { word: 'owing', word_class: 'AJ' }],
  anomalousness: [
    { word: 'anomaly', word_class: 'N' },
    { word: 'anomalous', word_class: 'AJ' },
    { word: 'anomalously', word_class: 'AV' },
    { word: 'anomalousness', word_class: 'N' }
  ],
  nuptial: [
    { word: 'nuptial', word_class: 'N' },
    { word: 'nuptial', word_class: 'AJ' },
    { word: 'nuptials', word_class: 'N' }
  ],
  impaction: [
    { word: 'impact', word_class: 'N' },
    { word: 'impact', word_class: 'V' },
    { word: 'impacted', word_class: 'AJ' },
    { word: 'impaction', word_class: 'N' }
  ],
  aberrance: [
    { word: 'aberrant', word_class: 'N' },
    { word: 'aberrate', word_class: 'V' },
    { word: 'aberrance', word_class: 'N' },
    { word: 'aberrancy', word_class: 'N' },
    { word: 'aberrant', word_class: 'AJ' },
    { word: 'aberration', word_class: 'N' }
  ],
  intrigue: [
    { word: 'intrigue', word_class: 'N' },
    { word: 'intrigue', word_class: 'V' },
    { word: 'intriguing', word_class: 'AJ' }
  ],
  mythologise: [
    { word: 'mythology', word_class: 'N' },
    { word: 'mythologic', word_class: 'AJ' },
    { word: 'mythologise', word_class: 'V' },
    { word: 'mythologize', word_class: 'V' },
    { word: 'mythological', word_class: 'AJ' },
    { word: 'mythologisation', word_class: 'N' },
    { word: 'mythologization', word_class: 'N' }
  ],
  trial: [
    { word: 'try', word_class: 'N' },
    { word: 'try', word_class: 'V' },
    { word: 'trial', word_class: 'N' },
    { word: 'trier', word_class: 'N' },
    { word: 'tryst', word_class: 'N' },
    { word: 'trial', word_class: 'AJ' },
    { word: 'trying', word_class: 'AJ' }
  ],
  foreground: [
    { word: 'foreground', word_class: 'N' },
    { word: 'foreground', word_class: 'V' },
    { word: 'foregrounding', word_class: 'N' }
  ],
  ticker: [
    { word: 'tick', word_class: 'N' },
    { word: 'tick', word_class: 'V' },
    { word: 'ticker', word_class: 'N' },
    { word: 'ticking', word_class: 'N' }
  ],
  shuck: [{ word: 'shuck', word_class: 'N' }, { word: 'shuck', word_class: 'V' }, { word: 'shucks', word_class: 'N' }],
  malice: [
    { word: 'malice', word_class: 'N' },
    { word: 'malice', word_class: 'V' },
    { word: 'malicious', word_class: 'AJ' },
    { word: 'maliciously', word_class: 'AV' },
    { word: 'maliciousness', word_class: 'N' }
  ],
  tapered: [
    { word: 'taper', word_class: 'N' },
    { word: 'taper', word_class: 'V' },
    { word: 'tapered', word_class: 'AJ' },
    { word: 'tapering', word_class: 'N' },
    { word: 'tapering', word_class: 'AJ' }
  ],
  impugnment: [
    { word: 'impugn', word_class: 'V' },
    { word: 'impugnment', word_class: 'N' },
    { word: 'impugnable', word_class: 'AJ' },
    { word: 'impugnation', word_class: 'N' }
  ],
  impeded: [
    { word: 'impede', word_class: 'V' },
    { word: 'impeded', word_class: 'AJ' },
    { word: 'impedance', word_class: 'N' },
    { word: 'impedence', word_class: 'N' },
    { word: 'impeding', word_class: 'AJ' },
    { word: 'impediment', word_class: 'N' }
  ],
  browser: [
    { word: 'browse', word_class: 'N' },
    { word: 'browse', word_class: 'V' },
    { word: 'browser', word_class: 'N' },
    { word: 'browsing', word_class: 'N' }
  ],
  inexorability: [
    { word: 'inexorable', word_class: 'AJ' },
    { word: 'inexorably', word_class: 'AV' },
    { word: 'inexorability', word_class: 'N' },
    { word: 'inexorableness', word_class: 'N' }
  ],
  disquieted: [
    { word: 'disquiet', word_class: 'N' },
    { word: 'disquiet', word_class: 'V' },
    { word: 'disquieted', word_class: 'AJ' },
    { word: 'disquieting', word_class: 'AJ' }
  ],
  vegetative: [
    { word: 'vegetal', word_class: 'AJ' },
    { word: 'vegetate', word_class: 'V' },
    { word: 'vegetable', word_class: 'N' },
    { word: 'vegetive', word_class: 'AJ' },
    { word: 'vegetable', word_class: 'AJ' },
    { word: 'vegetation', word_class: 'N' },
    { word: 'vegetative', word_class: 'AJ' },
    { word: 'vegetational', word_class: 'AJ' }
  ],
  undersize: [
    { word: 'underseal', word_class: 'N' },
    { word: 'undersize', word_class: 'AJ' },
    { word: 'undersized', word_class: 'AJ' },
    { word: 'undersealed', word_class: 'AJ' }
  ],
  pagination: [
    { word: 'page', word_class: 'N' },
    { word: 'page', word_class: 'V' },
    { word: 'paging', word_class: 'N' },
    { word: 'pageant', word_class: 'N' },
    { word: 'paginate', word_class: 'V' },
    { word: 'pageantry', word_class: 'N' },
    { word: 'pagination', word_class: 'N' }
  ],
  ridged: [
    { word: 'ridge', word_class: 'N' },
    { word: 'ridge', word_class: 'V' },
    { word: 'ridged', word_class: 'AJ' }
  ],
  works: [
    { word: 'work', word_class: 'N' },
    { word: 'work', word_class: 'V' },
    { word: 'works', word_class: 'N' },
    { word: 'worker', word_class: 'N' },
    { word: 'workman', word_class: 'N' },
    { word: 'working', word_class: 'N' },
    { word: 'working', word_class: 'AJ' },
    { word: 'workings', word_class: 'N' }
  ],
  wrecker: [
    { word: 'wreck', word_class: 'N' },
    { word: 'wreck', word_class: 'V' },
    { word: 'wrecker', word_class: 'N' },
    { word: 'wrecked', word_class: 'AJ' },
    { word: 'wrecking', word_class: 'N' }
  ],
  revolver: [
    { word: 'revolt', word_class: 'N' },
    { word: 'revolt', word_class: 'V' },
    { word: 'revolve', word_class: 'V' },
    { word: 'revolver', word_class: 'N' },
    { word: 'revolved', word_class: 'AJ' },
    { word: 'revolution', word_class: 'N' },
    { word: 'revolting', word_class: 'AJ' },
    { word: 'revolving', word_class: 'AJ' },
    { word: 'revolutionary', word_class: 'N' },
    { word: 'revolutionary', word_class: 'AJ' }
  ],
  wearer: [
    { word: 'wear', word_class: 'N' },
    { word: 'wear', word_class: 'V' },
    { word: 'worn', word_class: 'AJ' },
    { word: 'wearer', word_class: 'N' },
    { word: 'wearing', word_class: 'N' },
    { word: 'wearing', word_class: 'AJ' }
  ],
  mash: [{ word: 'mash', word_class: 'N' }, { word: 'mash', word_class: 'V' }, { word: 'masher', word_class: 'N' }],
  proportionally: [
    { word: 'proportion', word_class: 'N' },
    { word: 'proportion', word_class: 'V' },
    { word: 'proportional', word_class: 'N' },
    { word: 'proportional', word_class: 'AJ' },
    { word: 'proportionate', word_class: 'V' },
    { word: 'proportionate', word_class: 'AJ' },
    { word: 'proportionment', word_class: 'N' },
    { word: 'proportionable', word_class: 'AJ' },
    { word: 'proportionality', word_class: 'N' },
    { word: 'proportionally', word_class: 'AV' },
    { word: 'proportionation', word_class: 'N' },
    { word: 'proportionately', word_class: 'AV' },
    { word: 'proportionateness', word_class: 'N' }
  ],
  contextual: [
    { word: 'contextual', word_class: 'AJ' },
    { word: 'contextualize', word_class: 'V' },
    { word: 'contextually', word_class: 'AV' }
  ],
  collude: [
    { word: 'collude', word_class: 'V' },
    { word: 'collusion', word_class: 'N' },
    { word: 'collusive', word_class: 'AJ' }
  ],
  shearing: [
    { word: 'shear', word_class: 'N' },
    { word: 'shear', word_class: 'V' },
    { word: 'shears', word_class: 'N' },
    { word: 'shorn', word_class: 'AJ' },
    { word: 'sheared', word_class: 'AJ' },
    { word: 'shearing', word_class: 'N' }
  ],
  believably: [
    { word: 'belief', word_class: 'N' },
    { word: 'believe', word_class: 'V' },
    { word: 'believer', word_class: 'N' },
    { word: 'believing', word_class: 'N' },
    { word: 'believable', word_class: 'AJ' },
    { word: 'believably', word_class: 'AV' },
    { word: 'believability', word_class: 'N' }
  ],
  arrayed: [
    { word: 'array', word_class: 'N' },
    { word: 'array', word_class: 'V' },
    { word: 'arrayal', word_class: 'N' },
    { word: 'arrayed', word_class: 'AJ' }
  ],
  conservancy: [
    { word: 'conserve', word_class: 'N' },
    { word: 'conserve', word_class: 'V' },
    { word: 'conserves', word_class: 'N' },
    { word: 'conserved', word_class: 'AJ' },
    { word: 'conserving', word_class: 'N' },
    { word: 'conservancy', word_class: 'N' },
    { word: 'conservator', word_class: 'N' },
    { word: 'conserving', word_class: 'AJ' },
    { word: 'conservation', word_class: 'N' },
    { word: 'conservative', word_class: 'N' },
    { word: 'conservative', word_class: 'AJ' },
    { word: 'conservatively', word_class: 'AV' }
  ],
  functionalism: [
    { word: 'function', word_class: 'N' },
    { word: 'function', word_class: 'V' },
    { word: 'functionary', word_class: 'N' },
    { word: 'functional', word_class: 'AJ' },
    { word: 'functioning', word_class: 'N' },
    { word: 'functioning', word_class: 'AJ' },
    { word: 'functionalism', word_class: 'N' },
    { word: 'functionally', word_class: 'AV' }
  ],
  freezer: [
    { word: 'frost', word_class: 'N' },
    { word: 'frost', word_class: 'V' },
    { word: 'freeze', word_class: 'N' },
    { word: 'freeze', word_class: 'V' },
    { word: 'freezer', word_class: 'N' },
    { word: 'frozen', word_class: 'AJ' },
    { word: 'freezing', word_class: 'N' },
    { word: 'freezing', word_class: 'V' },
    { word: 'frosted', word_class: 'AJ' },
    { word: 'frosting', word_class: 'N' },
    { word: 'freezing', word_class: 'AJ' }
  ],
  swiftness: [
    { word: 'swift', word_class: 'N' },
    { word: 'swift', word_class: 'AJ' },
    { word: 'swiftness', word_class: 'N' }
  ],
  glove: [
    { word: 'glove', word_class: 'N' },
    { word: 'glove', word_class: 'V' },
    { word: 'gloves', word_class: 'N' },
    { word: 'gloved', word_class: 'AJ' }
  ],
  smacking: [
    { word: 'smack', word_class: 'N' },
    { word: 'smack', word_class: 'V' },
    { word: 'smack', word_class: 'AV' },
    { word: 'smacker', word_class: 'N' },
    { word: 'smacking', word_class: 'N' }
  ],
  sauteing: [
    { word: 'saute', word_class: 'V' },
    { word: 'saute', word_class: 'AJ' },
    { word: 'sauteing', word_class: 'N' }
  ],
  sallowness: [
    { word: 'sallow', word_class: 'N' },
    { word: 'sallow', word_class: 'V' },
    { word: 'sallow', word_class: 'AJ' },
    { word: 'sallowness', word_class: 'N' }
  ],
  perpetuity: [
    { word: 'perpetual', word_class: 'AJ' },
    { word: 'perpetuate', word_class: 'V' },
    { word: 'perpetuity', word_class: 'N' },
    { word: 'perpetually', word_class: 'AV' },
    { word: 'perpetuation', word_class: 'N' }
  ],
  adjustment: [
    { word: 'adjust', word_class: 'V' },
    { word: 'adjuster', word_class: 'N' },
    { word: 'adjustor', word_class: 'N' },
    { word: 'adjusted', word_class: 'AJ' },
    { word: 'adjustive', word_class: 'AJ' },
    { word: 'adjustment', word_class: 'N' },
    { word: 'adjustable', word_class: 'AJ' }
  ],
  ligate: [
    { word: 'ligate', word_class: 'V' },
    { word: 'ligation', word_class: 'N' },
    { word: 'ligature', word_class: 'N' }
  ],
  impression: [
    { word: 'impress', word_class: 'N' },
    { word: 'impress', word_class: 'V' },
    { word: 'impression', word_class: 'N' },
    { word: 'impressed', word_class: 'AJ' },
    { word: 'impressure', word_class: 'N' },
    { word: 'impressive', word_class: 'AJ' },
    { word: 'impressment', word_class: 'N' },
    { word: 'impressionism', word_class: 'N' },
    { word: 'impressively', word_class: 'AV' },
    { word: 'impressiveness', word_class: 'N' },
    { word: 'impressionable', word_class: 'AJ' }
  ],
  tepid: [
    { word: 'tepid', word_class: 'AJ' },
    { word: 'tepidity', word_class: 'N' },
    { word: 'tepidness', word_class: 'N' }
  ],
  intricacy: [
    { word: 'intricacy', word_class: 'N' },
    { word: 'intricate', word_class: 'AJ' },
    { word: 'intricately', word_class: 'AV' }
  ],
  stylistically: [
    { word: 'style', word_class: 'N' },
    { word: 'style', word_class: 'V' },
    { word: 'stylist', word_class: 'N' },
    { word: 'stylite', word_class: 'N' },
    { word: 'styled', word_class: 'AJ' },
    { word: 'stylise', word_class: 'V' },
    { word: 'stylize', word_class: 'V' },
    { word: 'styling', word_class: 'AJ' },
    { word: 'stylish', word_class: 'AJ' },
    { word: 'stylised', word_class: 'AJ' },
    { word: 'stylized', word_class: 'AJ' },
    { word: 'stylistic', word_class: 'AJ' },
    { word: 'stylisation', word_class: 'N' },
    { word: 'stylishness', word_class: 'N' },
    { word: 'stylization', word_class: 'N' },
    { word: 'stylistically', word_class: 'AV' }
  ],
  shingles: [
    { word: 'shingle', word_class: 'N' },
    { word: 'shingle', word_class: 'V' },
    { word: 'shingles', word_class: 'N' },
    { word: 'shingling', word_class: 'N' }
  ],
  inwardness: [
    { word: 'inward', word_class: 'N' },
    { word: 'inward', word_class: 'V' },
    { word: 'inward', word_class: 'AJ' },
    { word: 'inward', word_class: 'AV' },
    { word: 'inwards', word_class: 'AV' },
    { word: 'inwardness', word_class: 'N' }
  ],
  assiduity: [
    { word: 'assiduity', word_class: 'N' },
    { word: 'assiduous', word_class: 'AJ' },
    { word: 'assiduously', word_class: 'AV' },
    { word: 'assiduousness', word_class: 'N' }
  ],
  heterogeneous: [
    { word: 'heterogeneity', word_class: 'N' },
    { word: 'heterogenous', word_class: 'AJ' },
    { word: 'heterogeneous', word_class: 'AJ' },
    { word: 'heterogeneousness', word_class: 'N' }
  ],
  rattler: [
    { word: 'rattle', word_class: 'N' },
    { word: 'rattle', word_class: 'V' },
    { word: 'rattly', word_class: 'AJ' },
    { word: 'rattler', word_class: 'N' },
    { word: 'rattled', word_class: 'AJ' },
    { word: 'rattling', word_class: 'N' },
    { word: 'rattling', word_class: 'AJ' },
    { word: 'rattling', word_class: 'AV' }
  ],
  tracer: [
    { word: 'trace', word_class: 'N' },
    { word: 'trace', word_class: 'V' },
    { word: 'tracer', word_class: 'N' },
    { word: 'tracery', word_class: 'N' },
    { word: 'tracing', word_class: 'N' },
    { word: 'traced', word_class: 'AJ' },
    { word: 'tracery', word_class: 'AJ' },
    { word: 'traction', word_class: 'N' }
  ],
  malfunction: [
    { word: 'malfunction', word_class: 'N' },
    { word: 'malfunction', word_class: 'V' },
    { word: 'malfunctioning', word_class: 'AJ' }
  ],
  parch: [
    { word: 'parch', word_class: 'V' },
    { word: 'parched', word_class: 'AJ' },
    { word: 'parchment', word_class: 'N' }
  ],
  replete: [
    { word: 'replete', word_class: 'V' },
    { word: 'replete', word_class: 'AJ' },
    { word: 'repletion', word_class: 'N' }
  ],
  crocheting: [
    { word: 'crochet', word_class: 'N' },
    { word: 'crochet', word_class: 'V' },
    { word: 'crocheting', word_class: 'N' }
  ],
  toilet: [
    { word: 'toilet', word_class: 'N' },
    { word: 'toiletry', word_class: 'N' },
    { word: 'toiletries', word_class: 'N' }
  ],
  ill: [
    { word: 'ill', word_class: 'N' },
    { word: 'ill', word_class: 'AJ' },
    { word: 'ill', word_class: 'AV' },
    { word: 'illness', word_class: 'N' },
    { word: 'illative', word_class: 'AJ' }
  ],
  hemispheric: [
    { word: 'hemisphere', word_class: 'N' },
    { word: 'hemispheric', word_class: 'AJ' },
    { word: 'hemispherical', word_class: 'AJ' }
  ],
  relax: [
    { word: 'relax', word_class: 'V' },
    { word: 'relax', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'N' },
    { word: 'relaxed', word_class: 'AJ' },
    { word: 'relaxant', word_class: 'AJ' },
    { word: 'relaxing', word_class: 'AJ' },
    { word: 'relaxation', word_class: 'N' }
  ],
  incumbrance: [
    { word: 'encumber', word_class: 'V' },
    { word: 'incumber', word_class: 'V' },
    { word: 'incumbent', word_class: 'N' },
    { word: 'incumbency', word_class: 'N' },
    { word: 'incumbent', word_class: 'AJ' },
    { word: 'encumbered', word_class: 'AJ' },
    { word: 'encumbrance', word_class: 'N' },
    { word: 'incumbrance', word_class: 'N' }
  ],
  servant: [
    { word: 'serve', word_class: 'N' },
    { word: 'serve', word_class: 'V' },
    { word: 'serval', word_class: 'N' },
    { word: 'server', word_class: 'N' },
    { word: 'servant', word_class: 'N' },
    { word: 'service', word_class: 'N' },
    { word: 'service', word_class: 'V' },
    { word: 'serving', word_class: 'N' },
    { word: 'services', word_class: 'N' },
    { word: 'servicing', word_class: 'N' },
    { word: 'serviced', word_class: 'AJ' },
    { word: 'serviceman', word_class: 'N' },
    { word: 'serviceable', word_class: 'AJ' },
    { word: 'serviceability', word_class: 'N' },
    { word: 'serviceableness', word_class: 'N' }
  ],
  delocalise: [
    { word: 'delocalise', word_class: 'V' },
    { word: 'delocalize', word_class: 'V' },
    { word: 'delocalisation', word_class: 'N' },
    { word: 'delocalization', word_class: 'N' }
  ],
  abhorrer: [
    { word: 'abhor', word_class: 'V' },
    { word: 'abhorrer', word_class: 'N' },
    { word: 'abhorrence', word_class: 'N' },
    { word: 'abhorrent', word_class: 'AJ' }
  ],
  amiability: [
    { word: 'amiable', word_class: 'AJ' },
    { word: 'amiably', word_class: 'AV' },
    { word: 'amiability', word_class: 'N' },
    { word: 'amiableness', word_class: 'N' }
  ],
  sorrowing: [
    { word: 'sorrow', word_class: 'N' },
    { word: 'sorrow', word_class: 'V' },
    { word: 'sorrowful', word_class: 'AJ' },
    { word: 'sorrowing', word_class: 'AJ' },
    { word: 'sorrowfulness', word_class: 'N' }
  ],
  formulize: [
    { word: 'formulate', word_class: 'V' },
    { word: 'formulize', word_class: 'V' },
    { word: 'formulated', word_class: 'AJ' },
    { word: 'formulation', word_class: 'N' },
    { word: 'formulization', word_class: 'N' }
  ],
  dole: [
    { word: 'dole', word_class: 'N' },
    { word: 'dole', word_class: 'V' },
    { word: 'doleful', word_class: 'AJ' },
    { word: 'dolefulness', word_class: 'N' }
  ],
  outdoor: [
    { word: 'outdoor', word_class: 'AJ' },
    { word: 'outdoors', word_class: 'N' },
    { word: 'outdoors', word_class: 'AJ' },
    { word: 'outdoors', word_class: 'AV' }
  ],
  unwisely: [
    { word: 'unwise', word_class: 'AJ' },
    { word: 'unwisely', word_class: 'AV' },
    { word: 'unwiseness', word_class: 'N' }
  ],
  complication: [
    { word: 'complicate', word_class: 'V' },
    { word: 'complicity', word_class: 'N' },
    { word: 'complicated', word_class: 'AJ' },
    { word: 'complicating', word_class: 'N' },
    { word: 'complication', word_class: 'N' }
  ],
  lamented: [
    { word: 'lament', word_class: 'N' },
    { word: 'lament', word_class: 'V' },
    { word: 'lamenter', word_class: 'N' },
    { word: 'lamented', word_class: 'AJ' },
    { word: 'lamenting', word_class: 'AJ' },
    { word: 'lamentable', word_class: 'AJ' },
    { word: 'lamentably', word_class: 'AV' },
    { word: 'lamentation', word_class: 'N' }
  ],
  uninformative: [
    { word: 'uninformed', word_class: 'AJ' },
    { word: 'uninformative', word_class: 'AJ' },
    { word: 'uninformatively', word_class: 'AV' }
  ],
  paring: [
    { word: 'pare', word_class: 'V' },
    { word: 'parer', word_class: 'N' },
    { word: 'parent', word_class: 'N' },
    { word: 'parent', word_class: 'V' },
    { word: 'paring', word_class: 'N' },
    { word: 'parent', word_class: 'AJ' },
    { word: 'parings', word_class: 'N' },
    { word: 'parentage', word_class: 'N' },
    { word: 'parental', word_class: 'AJ' },
    { word: 'parented', word_class: 'AJ' },
    { word: 'parentally', word_class: 'AV' }
  ],
  processor: [
    { word: 'proceed', word_class: 'V' },
    { word: 'process', word_class: 'N' },
    { word: 'process', word_class: 'V' },
    { word: 'proceeds', word_class: 'N' },
    { word: 'procedure', word_class: 'N' },
    { word: 'processor', word_class: 'N' },
    { word: 'proceeding', word_class: 'N' },
    { word: 'processed', word_class: 'AJ' },
    { word: 'processing', word_class: 'N' },
    { word: 'procession', word_class: 'N' },
    { word: 'procedural', word_class: 'AJ' },
    { word: 'proceedings', word_class: 'N' },
    { word: 'processional', word_class: 'N' },
    { word: 'processional', word_class: 'AJ' }
  ],
  awned: [{ word: 'awn', word_class: 'N' }, { word: 'awned', word_class: 'AJ' }, { word: 'awning', word_class: 'N' }],
  inferable: [
    { word: 'infer', word_class: 'V' },
    { word: 'inferral', word_class: 'N' },
    { word: 'inference', word_class: 'N' },
    { word: 'inferable', word_class: 'AJ' },
    { word: 'inferential', word_class: 'AJ' }
  ],
  bookbinding: [
    { word: 'bookbind', word_class: 'V' },
    { word: 'bookbinder', word_class: 'N' },
    { word: 'bookbindery', word_class: 'N' },
    { word: 'bookbinding', word_class: 'N' },
    { word: 'bookbindery', word_class: 'AJ' }
  ],
  spectrographically: [
    { word: 'spectrograph', word_class: 'N' },
    { word: 'spectrographic', word_class: 'AJ' },
    { word: 'spectrographically', word_class: 'AV' }
  ],
  confirmative: [
    { word: 'confirm', word_class: 'V' },
    { word: 'confirmed', word_class: 'AJ' },
    { word: 'confirming', word_class: 'AJ' },
    { word: 'confirmation', word_class: 'N' },
    { word: 'confirmable', word_class: 'AJ' },
    { word: 'confirmative', word_class: 'AJ' },
    { word: 'confirmatory', word_class: 'AJ' }
  ],
  quelling: [
    { word: 'quell', word_class: 'V' },
    { word: 'quelled', word_class: 'AJ' },
    { word: 'quelling', word_class: 'N' }
  ],
  defensible: [
    { word: 'defend', word_class: 'V' },
    { word: 'defense', word_class: 'N' },
    { word: 'defender', word_class: 'N' },
    { word: 'defendant', word_class: 'N' },
    { word: 'defensive', word_class: 'N' },
    { word: 'defending', word_class: 'AJ' },
    { word: 'defensive', word_class: 'AJ' },
    { word: 'defendable', word_class: 'AJ' },
    { word: 'defensible', word_class: 'AJ' },
    { word: 'defensibly', word_class: 'AV' },
    { word: 'defensively', word_class: 'AV' },
    { word: 'defensibility', word_class: 'N' }
  ],
  preconditioned: [
    { word: 'precondition', word_class: 'N' },
    { word: 'precondition', word_class: 'V' },
    { word: 'preconditioned', word_class: 'AJ' }
  ],
  inauspiciousness: [
    { word: 'inauspicious', word_class: 'AJ' },
    { word: 'inauspiciously', word_class: 'AV' },
    { word: 'inauspiciousness', word_class: 'N' }
  ],
  remotely: [
    { word: 'remote', word_class: 'N' },
    { word: 'remote', word_class: 'AJ' },
    { word: 'remotion', word_class: 'N' },
    { word: 'remotely', word_class: 'AV' },
    { word: 'remoteness', word_class: 'N' }
  ],
  reassured: [
    { word: 'reassure', word_class: 'V' },
    { word: 'reassured', word_class: 'AJ' },
    { word: 'reassurance', word_class: 'N' },
    { word: 'reassuring', word_class: 'AJ' }
  ],
  subornation: [
    { word: 'suborn', word_class: 'V' },
    { word: 'suborner', word_class: 'N' },
    { word: 'subornation', word_class: 'N' }
  ],
  morphological: [
    { word: 'morphology', word_class: 'N' },
    { word: 'morphologic', word_class: 'AJ' },
    { word: 'morphological', word_class: 'AJ' },
    { word: 'morphologically', word_class: 'AV' }
  ],
  sorrowfulness: [
    { word: 'sorrow', word_class: 'N' },
    { word: 'sorrow', word_class: 'V' },
    { word: 'sorrowful', word_class: 'AJ' },
    { word: 'sorrowing', word_class: 'AJ' },
    { word: 'sorrowfulness', word_class: 'N' }
  ],
  huddled: [
    { word: 'huddle', word_class: 'N' },
    { word: 'huddle', word_class: 'V' },
    { word: 'huddled', word_class: 'AJ' }
  ],
  romanticization: [
    { word: 'romanticise', word_class: 'V' },
    { word: 'romanticist', word_class: 'N' },
    { word: 'romanticize', word_class: 'V' },
    { word: 'romanticism', word_class: 'N' },
    { word: 'romanticist', word_class: 'AJ' },
    { word: 'romanticistic', word_class: 'AJ' },
    { word: 'romanticisation', word_class: 'N' },
    { word: 'romanticization', word_class: 'N' }
  ],
  wash: [
    { word: 'wash', word_class: 'N' },
    { word: 'wash', word_class: 'V' },
    { word: 'washer', word_class: 'N' },
    { word: 'washed', word_class: 'AJ' },
    { word: 'washing', word_class: 'N' }
  ],
  rubberization: [
    { word: 'rubber', word_class: 'N' },
    { word: 'rubber', word_class: 'V' },
    { word: 'rubber', word_class: 'AJ' },
    { word: 'rubberize', word_class: 'V' },
    { word: 'rubberized', word_class: 'AJ' },
    { word: 'rubberization', word_class: 'N' }
  ],
  diversified: [
    { word: 'diversify', word_class: 'V' },
    { word: 'diversified', word_class: 'AJ' },
    { word: 'diversification', word_class: 'N' }
  ],
  shrunk: [
    { word: 'shrink', word_class: 'N' },
    { word: 'shrink', word_class: 'V' },
    { word: 'shrunk', word_class: 'AJ' },
    { word: 'shrinkage', word_class: 'N' },
    { word: 'shrinking', word_class: 'N' },
    { word: 'shrunken', word_class: 'AJ' }
  ],
  supervene: [
    { word: 'supervene', word_class: 'V' },
    { word: 'supervention', word_class: 'N' },
    { word: 'supervenience', word_class: 'N' }
  ],
  implore: [
    { word: 'implore', word_class: 'V' },
    { word: 'imploring', word_class: 'AJ' },
    { word: 'imploration', word_class: 'N' }
  ],
  crusher: [
    { word: 'crush', word_class: 'N' },
    { word: 'crush', word_class: 'V' },
    { word: 'crusher', word_class: 'N' },
    { word: 'crushed', word_class: 'AJ' },
    { word: 'crushing', word_class: 'N' },
    { word: 'crushing', word_class: 'AJ' }
  ],
  embodiment: [
    { word: 'embody', word_class: 'V' },
    { word: 'embodied', word_class: 'AJ' },
    { word: 'embodiment', word_class: 'N' }
  ],
  brew: [
    { word: 'brew', word_class: 'N' },
    { word: 'brew', word_class: 'V' },
    { word: 'brewer', word_class: 'N' },
    { word: 'brewage', word_class: 'N' },
    { word: 'brewery', word_class: 'N' },
    { word: 'brewing', word_class: 'N' },
    { word: 'brewery', word_class: 'AJ' }
  ],
  deserving: [
    { word: 'deserve', word_class: 'V' },
    { word: 'deserved', word_class: 'AJ' },
    { word: 'deserving', word_class: 'AJ' }
  ],
  pilferage: [
    { word: 'pilfer', word_class: 'V' },
    { word: 'pilferer', word_class: 'N' },
    { word: 'pilferage', word_class: 'N' }
  ],
  neutering: [
    { word: 'neuter', word_class: 'N' },
    { word: 'neuter', word_class: 'V' },
    { word: 'neuter', word_class: 'AJ' },
    { word: 'neutered', word_class: 'AJ' },
    { word: 'neutering', word_class: 'N' }
  ],
  longing: [
    { word: 'long', word_class: 'N' },
    { word: 'long', word_class: 'V' },
    { word: 'long', word_class: 'AJ' },
    { word: 'long', word_class: 'AV' },
    { word: 'length', word_class: 'N' },
    { word: 'longing', word_class: 'N' },
    { word: 'lengthen', word_class: 'V' },
    { word: 'longness', word_class: 'N' },
    { word: 'lengthened', word_class: 'AJ' },
    { word: 'lengthening', word_class: 'N' },
    { word: 'lengthening', word_class: 'AJ' }
  ],
  internship: [
    { word: 'intern', word_class: 'N' },
    { word: 'intern', word_class: 'V' },
    { word: 'interne', word_class: 'N' },
    { word: 'internee', word_class: 'N' },
    { word: 'internship', word_class: 'N' },
    { word: 'internist', word_class: 'N' },
    { word: 'internment', word_class: 'N' }
  ],
  opportuneness: [
    { word: 'opportune', word_class: 'AJ' },
    { word: 'opportunism', word_class: 'N' },
    { word: 'opportunity', word_class: 'N' },
    { word: 'opportunely', word_class: 'AV' },
    { word: 'opportuneness', word_class: 'N' }
  ],
  unseasonableness: [
    { word: 'unseasoned', word_class: 'AJ' },
    { word: 'unseasonable', word_class: 'AJ' },
    { word: 'unseasonably', word_class: 'AV' },
    { word: 'unseasonableness', word_class: 'N' }
  ],
  diagnostic: [
    { word: 'diagnose', word_class: 'V' },
    { word: 'diagnosis', word_class: 'N' },
    { word: 'diagnosing', word_class: 'N' },
    { word: 'diagnostic', word_class: 'N' },
    { word: 'diagnostic', word_class: 'AJ' },
    { word: 'diagnostics', word_class: 'N' },
    { word: 'diagnosable', word_class: 'AJ' }
  ],
  punishment: [
    { word: 'punish', word_class: 'V' },
    { word: 'punished', word_class: 'AJ' },
    { word: 'punishing', word_class: 'AJ' },
    { word: 'punishment', word_class: 'N' },
    { word: 'punishable', word_class: 'AJ' }
  ],
  confronting: [
    { word: 'confront', word_class: 'V' },
    { word: 'confronting', word_class: 'N' },
    { word: 'confrontment', word_class: 'N' },
    { word: 'confrontation', word_class: 'N' },
    { word: 'confrontational', word_class: 'AJ' }
  ],
  rewardable: [
    { word: 'reward', word_class: 'N' },
    { word: 'reward', word_class: 'V' },
    { word: 'rewardful', word_class: 'AJ' },
    { word: 'rewarding', word_class: 'AJ' },
    { word: 'rewardable', word_class: 'AJ' }
  ],
  encircled: [
    { word: 'encircle', word_class: 'V' },
    { word: 'encircled', word_class: 'AJ' },
    { word: 'encircling', word_class: 'AJ' },
    { word: 'encirclement', word_class: 'N' }
  ],
  liquidation: [
    { word: 'liquid', word_class: 'N' },
    { word: 'liquefy', word_class: 'V' },
    { word: 'liquid', word_class: 'AJ' },
    { word: 'liquify', word_class: 'V' },
    { word: 'liquidate', word_class: 'V' },
    { word: 'liquidity', word_class: 'N' },
    { word: 'liquidize', word_class: 'V' },
    { word: 'liquefied', word_class: 'AJ' },
    { word: 'liquidator', word_class: 'N' },
    { word: 'liquidizer', word_class: 'N' },
    { word: 'liquidness', word_class: 'N' },
    { word: 'liquified', word_class: 'AJ' },
    { word: 'liquidation', word_class: 'N' },
    { word: 'liquefaction', word_class: 'N' },
    { word: 'liquefiable', word_class: 'AJ' },
    { word: 'liquifaction', word_class: 'N' },
    { word: 'liquifiable', word_class: 'AJ' },
    { word: 'liquidization', word_class: 'N' }
  ],
  squelched: [
    { word: 'squelch', word_class: 'N' },
    { word: 'squelch', word_class: 'V' },
    { word: 'squelched', word_class: 'AJ' }
  ],
  inhibit: [
    { word: 'inhibit', word_class: 'V' },
    { word: 'inhibitor', word_class: 'N' },
    { word: 'inhibited', word_class: 'AJ' },
    { word: 'inhibition', word_class: 'N' },
    { word: 'inhibiting', word_class: 'AJ' }
  ],
  flip: [{ word: 'flip', word_class: 'N' }, { word: 'flip', word_class: 'V' }, { word: 'flip', word_class: 'AJ' }],
  reside: [
    { word: 'reside', word_class: 'V' },
    { word: 'resident', word_class: 'N' },
    { word: 'residence', word_class: 'N' },
    { word: 'residency', word_class: 'N' },
    { word: 'resident', word_class: 'AJ' }
  ],
  offend: [
    { word: 'offend', word_class: 'V' },
    { word: 'offense', word_class: 'N' },
    { word: 'offender', word_class: 'N' },
    { word: 'offended', word_class: 'AJ' },
    { word: 'offensive', word_class: 'N' },
    { word: 'offending', word_class: 'AJ' },
    { word: 'offensive', word_class: 'AJ' },
    { word: 'offensively', word_class: 'AV' },
    { word: 'offensiveness', word_class: 'N' }
  ],
  burster: [
    { word: 'burst', word_class: 'N' },
    { word: 'burst', word_class: 'V' },
    { word: 'burst', word_class: 'AJ' },
    { word: 'burster', word_class: 'N' },
    { word: 'bursting', word_class: 'AJ' }
  ],
  incremental: [
    { word: 'increment', word_class: 'N' },
    { word: 'increment', word_class: 'V' },
    { word: 'incremental', word_class: 'AJ' }
  ],
  beheading: [
    { word: 'behead', word_class: 'V' },
    { word: 'beheaded', word_class: 'AJ' },
    { word: 'beheading', word_class: 'N' }
  ],
  exploitive: [
    { word: 'exploit', word_class: 'N' },
    { word: 'exploit', word_class: 'V' },
    { word: 'exploiter', word_class: 'N' },
    { word: 'exploited', word_class: 'AJ' },
    { word: 'exploitive', word_class: 'AJ' },
    { word: 'exploitation', word_class: 'N' },
    { word: 'exploitative', word_class: 'AJ' }
  ],
  registrable: [
    { word: 'register', word_class: 'N' },
    { word: 'register', word_class: 'V' },
    { word: 'registry', word_class: 'N' },
    { word: 'registrar', word_class: 'N' },
    { word: 'registrant', word_class: 'N' },
    { word: 'registrate', word_class: 'V' },
    { word: 'registered', word_class: 'AJ' },
    { word: 'registrable', word_class: 'AJ' },
    { word: 'registration', word_class: 'N' }
  ],
  ordinance: [
    { word: 'ordain', word_class: 'V' },
    { word: 'ordinal', word_class: 'N' },
    { word: 'ordinate', word_class: 'N' },
    { word: 'ordinal', word_class: 'AJ' },
    { word: 'ordained', word_class: 'AJ' },
    { word: 'ordaining', word_class: 'N' },
    { word: 'ordinance', word_class: 'N' },
    { word: 'ordainment', word_class: 'N' },
    { word: 'ordination', word_class: 'N' }
  ],
  intermittence: [
    { word: 'intermittence', word_class: 'N' },
    { word: 'intermittency', word_class: 'N' },
    { word: 'intermittent', word_class: 'AJ' },
    { word: 'intermittently', word_class: 'AV' }
  ],
  nitrogenize: [
    { word: 'nitrogen', word_class: 'N' },
    { word: 'nitrogenize', word_class: 'V' },
    { word: 'nitrogenous', word_class: 'AJ' },
    { word: 'nitrogenization', word_class: 'N' }
  ],
  dissect: [
    { word: 'dissect', word_class: 'N' },
    { word: 'dissect', word_class: 'V' },
    { word: 'dissected', word_class: 'AJ' },
    { word: 'dissection', word_class: 'N' }
  ],
  imperatively: [
    { word: 'imperative', word_class: 'N' },
    { word: 'imperative', word_class: 'AJ' },
    { word: 'imperatively', word_class: 'AV' },
    { word: 'imperativeness', word_class: 'N' }
  ],
  usance: [
    { word: 'us', word_class: 'N' },
    { word: 'use', word_class: 'N' },
    { word: 'use', word_class: 'V' },
    { word: 'user', word_class: 'N' },
    { word: 'used', word_class: 'AJ' },
    { word: 'using', word_class: 'N' },
    { word: 'usance', word_class: 'N' },
    { word: 'useful', word_class: 'AJ' },
    { word: 'usefulness', word_class: 'N' }
  ],
  personalize: [
    { word: 'person', word_class: 'N' },
    { word: 'personal', word_class: 'N' },
    { word: 'personage', word_class: 'N' },
    { word: 'personal', word_class: 'AJ' },
    { word: 'personate', word_class: 'V' },
    { word: 'personable', word_class: 'AJ' },
    { word: 'personably', word_class: 'AV' },
    { word: 'personalise', word_class: 'V' },
    { word: 'personalize', word_class: 'V' },
    { word: 'personation', word_class: 'N' },
    { word: 'personality', word_class: 'N' },
    { word: 'personally', word_class: 'AV' },
    { word: 'personalized', word_class: 'AJ' },
    { word: 'personableness', word_class: 'N' },
    { word: 'personalisation', word_class: 'N' },
    { word: 'personalization', word_class: 'N' }
  ],
  repression: [
    { word: 'repress', word_class: 'N' },
    { word: 'repress', word_class: 'V' },
    { word: 'repressed', word_class: 'AJ' },
    { word: 'repression', word_class: 'N' },
    { word: 'repressing', word_class: 'AJ' },
    { word: 'repressive', word_class: 'AJ' }
  ],
  retrogression: [
    { word: 'retrogress', word_class: 'V' },
    { word: 'retrogression', word_class: 'N' },
    { word: 'retrogressive', word_class: 'AJ' }
  ],
  sequin: [
    { word: 'sequin', word_class: 'N' },
    { word: 'sequin', word_class: 'V' },
    { word: 'sequined', word_class: 'AJ' },
    { word: 'sequinned', word_class: 'AJ' }
  ],
  synthesization: [
    { word: 'synthetic', word_class: 'N' },
    { word: 'synthesise', word_class: 'V' },
    { word: 'synthesize', word_class: 'V' },
    { word: 'synthetic', word_class: 'AJ' },
    { word: 'synthetism', word_class: 'N' },
    { word: 'synthesizer', word_class: 'N' },
    { word: 'synthetical', word_class: 'AJ' },
    { word: 'synthesisation', word_class: 'N' },
    { word: 'synthesization', word_class: 'N' },
    { word: 'synthetically', word_class: 'AV' },
    { word: 'synthetisation', word_class: 'N' },
    { word: 'synthetization', word_class: 'N' }
  ],
  cabinetmaker: [
    { word: 'cabinet', word_class: 'N' },
    { word: 'cabinet', word_class: 'AJ' },
    { word: 'cabinetry', word_class: 'N' },
    { word: 'cabinetmaker', word_class: 'N' },
    { word: 'cabinetmaking', word_class: 'N' }
  ],
  longitudinal: [
    { word: 'longitude', word_class: 'N' },
    { word: 'longitudinal', word_class: 'AJ' },
    { word: 'longitudinally', word_class: 'AV' }
  ],
  balloting: [
    { word: 'ballot', word_class: 'N' },
    { word: 'ballot', word_class: 'V' },
    { word: 'balloting', word_class: 'N' }
  ],
  downwards: [
    { word: 'downward', word_class: 'AJ' },
    { word: 'downward', word_class: 'AV' },
    { word: 'downwards', word_class: 'AV' }
  ],
  demonstrativeness: [
    { word: 'demonstrate', word_class: 'V' },
    { word: 'demonstrator', word_class: 'N' },
    { word: 'demonstrable', word_class: 'AJ' },
    { word: 'demonstrably', word_class: 'AV' },
    { word: 'demonstration', word_class: 'N' },
    { word: 'demonstrative', word_class: 'N' },
    { word: 'demonstrated', word_class: 'AJ' },
    { word: 'demonstrative', word_class: 'AJ' },
    { word: 'demonstrability', word_class: 'N' },
    { word: 'demonstratively', word_class: 'AV' },
    { word: 'demonstrativeness', word_class: 'N' }
  ],
  disdainment: [
    { word: 'disdain', word_class: 'N' },
    { word: 'disdain', word_class: 'V' },
    { word: 'disdainful', word_class: 'AJ' },
    { word: 'disdainment', word_class: 'N' },
    { word: 'disdainfulness', word_class: 'N' }
  ],
  imitative: [
    { word: 'imitate', word_class: 'V' },
    { word: 'imitator', word_class: 'N' },
    { word: 'imitable', word_class: 'AJ' },
    { word: 'imitation', word_class: 'N' },
    { word: 'imitation', word_class: 'AJ' },
    { word: 'imitative', word_class: 'AJ' }
  ],
  serialisation: [
    { word: 'serial', word_class: 'N' },
    { word: 'serial', word_class: 'AJ' },
    { word: 'serialise', word_class: 'V' },
    { word: 'serialize', word_class: 'V' },
    { word: 'serialism', word_class: 'N' },
    { word: 'serially', word_class: 'AV' },
    { word: 'serialisation', word_class: 'N' },
    { word: 'serialization', word_class: 'N' }
  ],
  innumerable: [
    { word: 'innumerate', word_class: 'AJ' },
    { word: 'innumerous', word_class: 'AJ' },
    { word: 'innumerable', word_class: 'AJ' },
    { word: 'innumerableness', word_class: 'N' }
  ],
  grooming: [
    { word: 'groom', word_class: 'N' },
    { word: 'groom', word_class: 'V' },
    { word: 'groomed', word_class: 'AJ' },
    { word: 'grooming', word_class: 'N' }
  ],
  index: [
    { word: 'index', word_class: 'N' },
    { word: 'index', word_class: 'V' },
    { word: 'indexer', word_class: 'N' },
    { word: 'indexing', word_class: 'N' },
    { word: 'indexation', word_class: 'N' },
    { word: 'indexical', word_class: 'AJ' }
  ],
  resinate: [
    { word: 'resin', word_class: 'N' },
    { word: 'resin', word_class: 'V' },
    { word: 'resinate', word_class: 'V' },
    { word: 'resinous', word_class: 'AJ' },
    { word: 'resinated', word_class: 'AJ' },
    { word: 'resination', word_class: 'N' }
  ],
  seared: [{ word: 'sear', word_class: 'V' }, { word: 'sear', word_class: 'AJ' }, { word: 'seared', word_class: 'AJ' }],
  observable: [
    { word: 'observe', word_class: 'V' },
    { word: 'observer', word_class: 'N' },
    { word: 'observant', word_class: 'N' },
    { word: 'observed', word_class: 'AJ' },
    { word: 'observance', word_class: 'N' },
    { word: 'observant', word_class: 'AJ' },
    { word: 'observing', word_class: 'AJ' },
    { word: 'observation', word_class: 'N' },
    { word: 'observable', word_class: 'AJ' },
    { word: 'observatory', word_class: 'N' },
    { word: 'observably', word_class: 'AV' },
    { word: 'observational', word_class: 'AJ' }
  ],
  youthfulness: [
    { word: 'youth', word_class: 'N' },
    { word: 'youthful', word_class: 'AJ' },
    { word: 'youthfulness', word_class: 'N' }
  ],
  blastoporal: [
    { word: 'blastopore', word_class: 'N' },
    { word: 'blastoporal', word_class: 'AJ' },
    { word: 'blastoporic', word_class: 'AJ' }
  ],
  abutter: [
    { word: 'abut', word_class: 'V' },
    { word: 'abuttal', word_class: 'N' },
    { word: 'abutter', word_class: 'N' },
    { word: 'abutment', word_class: 'N' },
    { word: 'abutting', word_class: 'AJ' }
  ],
  shift: [
    { word: 'shift', word_class: 'N' },
    { word: 'shift', word_class: 'V' },
    { word: 'shifting', word_class: 'N' },
    { word: 'shifting', word_class: 'AJ' }
  ],
  lodging: [
    { word: 'lodge', word_class: 'N' },
    { word: 'lodge', word_class: 'V' },
    { word: 'lodger', word_class: 'N' },
    { word: 'lodging', word_class: 'N' },
    { word: 'lodgment', word_class: 'N' },
    { word: 'lodgings', word_class: 'N' },
    { word: 'lodgement', word_class: 'N' }
  ],
  ascomycete: [
    { word: 'ascomycete', word_class: 'N' },
    { word: 'ascomycetes', word_class: 'N' },
    { word: 'ascomycetous', word_class: 'AJ' }
  ],
  bifurcate: [
    { word: 'bifurcate', word_class: 'V' },
    { word: 'bifurcate', word_class: 'AJ' },
    { word: 'bifurcated', word_class: 'AJ' },
    { word: 'bifurcation', word_class: 'N' }
  ],
  etherize: [
    { word: 'ether', word_class: 'N' },
    { word: 'etherise', word_class: 'V' },
    { word: 'etherize', word_class: 'V' },
    { word: 'ethereal', word_class: 'AJ' },
    { word: 'etherealise', word_class: 'V' },
    { word: 'etherealize', word_class: 'V' },
    { word: 'etherisation', word_class: 'N' },
    { word: 'etherization', word_class: 'N' },
    { word: 'etherealisation', word_class: 'N' },
    { word: 'etherealization', word_class: 'N' }
  ],
  assignable: [
    { word: 'assign', word_class: 'V' },
    { word: 'assignee', word_class: 'N' },
    { word: 'assigned', word_class: 'AJ' },
    { word: 'assigning', word_class: 'N' },
    { word: 'assignment', word_class: 'N' },
    { word: 'assignable', word_class: 'AJ' },
    { word: 'assignation', word_class: 'N' }
  ],
  centrifugation: [
    { word: 'centrifuge', word_class: 'N' },
    { word: 'centrifuge', word_class: 'V' },
    { word: 'centrifugal', word_class: 'AJ' },
    { word: 'centrifugate', word_class: 'V' },
    { word: 'centrifugation', word_class: 'N' }
  ],
  compensative: [
    { word: 'compensate', word_class: 'V' },
    { word: 'compensable', word_class: 'AJ' },
    { word: 'compensated', word_class: 'AJ' },
    { word: 'compensation', word_class: 'N' },
    { word: 'compensative', word_class: 'AJ' }
  ],
  pasteurized: [
    { word: 'pasteur', word_class: 'N' },
    { word: 'pasteurize', word_class: 'V' },
    { word: 'pasteurized', word_class: 'AJ' },
    { word: 'pasteurization', word_class: 'N' }
  ],
  totalitarianism: [
    { word: 'total', word_class: 'N' },
    { word: 'total', word_class: 'V' },
    { word: 'total', word_class: 'AJ' },
    { word: 'totalise', word_class: 'V' },
    { word: 'totalism', word_class: 'N' },
    { word: 'totality', word_class: 'N' },
    { word: 'totaled', word_class: 'AJ' },
    { word: 'totalize', word_class: 'V' },
    { word: 'totally', word_class: 'AV' },
    { word: 'totaliser', word_class: 'N' },
    { word: 'totalizer', word_class: 'N' },
    { word: 'totalisator', word_class: 'N' },
    { word: 'totalisation', word_class: 'N' },
    { word: 'totalitarian', word_class: 'N' },
    { word: 'totalization', word_class: 'N' },
    { word: 'totalitarian', word_class: 'AJ' },
    { word: 'totalitarianism', word_class: 'N' }
  ],
  needful: [
    { word: 'need', word_class: 'N' },
    { word: 'need', word_class: 'V' },
    { word: 'needs', word_class: 'AV' },
    { word: 'needed', word_class: 'AJ' },
    { word: 'needful', word_class: 'AJ' }
  ],
  rail: [
    { word: 'rail', word_class: 'N' },
    { word: 'rail', word_class: 'V' },
    { word: 'rails', word_class: 'N' },
    { word: 'railing', word_class: 'N' }
  ],
  spare: [
    { word: 'spare', word_class: 'N' },
    { word: 'spare', word_class: 'V' },
    { word: 'spare', word_class: 'AJ' },
    { word: 'sparely', word_class: 'AV' },
    { word: 'sparing', word_class: 'AJ' },
    { word: 'spareness', word_class: 'N' }
  ],
  playacting: [
    { word: 'playact', word_class: 'V' },
    { word: 'playacting', word_class: 'N' },
    { word: 'playaction', word_class: 'N' }
  ],
  browse: [
    { word: 'browse', word_class: 'N' },
    { word: 'browse', word_class: 'V' },
    { word: 'browser', word_class: 'N' },
    { word: 'browsing', word_class: 'N' }
  ],
  mutualisation: [
    { word: 'mutual', word_class: 'AJ' },
    { word: 'mutualise', word_class: 'V' },
    { word: 'mutuality', word_class: 'N' },
    { word: 'mutualize', word_class: 'V' },
    { word: 'mutually', word_class: 'AV' },
    { word: 'mutualness', word_class: 'N' },
    { word: 'mutualisation', word_class: 'N' },
    { word: 'mutualization', word_class: 'N' }
  ],
  collectivise: [
    { word: 'collectivise', word_class: 'V' },
    { word: 'collectivism', word_class: 'N' },
    { word: 'collectivist', word_class: 'N' },
    { word: 'collectivize', word_class: 'V' },
    { word: 'collectivist', word_class: 'AJ' },
    { word: 'collectivized', word_class: 'AJ' },
    { word: 'collectivistic', word_class: 'AJ' },
    { word: 'collectivisation', word_class: 'N' },
    { word: 'collectivization', word_class: 'N' }
  ],
  acidic: [
    { word: 'acid', word_class: 'N' },
    { word: 'acid', word_class: 'AJ' },
    { word: 'acidic', word_class: 'AJ' },
    { word: 'acidity', word_class: 'N' }
  ],
  enervating: [
    { word: 'enervate', word_class: 'V' },
    { word: 'enervated', word_class: 'AJ' },
    { word: 'enervation', word_class: 'N' },
    { word: 'enervating', word_class: 'AJ' }
  ],
  chestful: [
    { word: 'chest', word_class: 'N' },
    { word: 'chested', word_class: 'AJ' },
    { word: 'chestful', word_class: 'N' },
    { word: 'chestful', word_class: 'AJ' }
  ],
  cussed: [{ word: 'cuss', word_class: 'N' }, { word: 'cuss', word_class: 'V' }, { word: 'cussed', word_class: 'AJ' }],
  coexistence: [
    { word: 'coexist', word_class: 'V' },
    { word: 'coexistence', word_class: 'N' },
    { word: 'coexistent', word_class: 'AJ' },
    { word: 'coexisting', word_class: 'AJ' }
  ],
  unsubstantiated: [
    { word: 'unsubstantial', word_class: 'AJ' },
    { word: 'unsubstantialize', word_class: 'V' },
    { word: 'unsubstantiated', word_class: 'AJ' }
  ],
  estranging: [
    { word: 'estrange', word_class: 'V' },
    { word: 'estranged', word_class: 'AJ' },
    { word: 'estranging', word_class: 'AJ' },
    { word: 'estrangement', word_class: 'N' }
  ]
};

export default word_list;